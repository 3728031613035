import React from 'react' 
export const dropdownValues = ["Yes", "No"];
export const directSpendDropdown = ["", "Yes", "No"];
export const scopeValues = ["Industry", "Override"];
export const goodsValues = ["Goods", "Services", "Both", "Neither", "Unclassified"]
export const requesitionValues = ["Catalog", "Adhoc (non-catalog)", "N/A"]
export const aribaBuyProcessValues = ['Requisition: Catalog, Spot-Buy or Release Contract',
    'Requisition: Non-Catalog or Tactical Sourcing',
    'Requisition: External Talent',
    'Consultancy/SOW Services: Active Mgmt, otherwise Invoice Against Contract',
    'Other Services: Active Mgmt, otherwise Invoice Against Contract',
    'Invoice Against Contract',
    'Direct/MRO: Plan Driven PO',
    'Direct/MRO: Asset Plan via Catalog',
    'Non-PO Invoice',
    'Travel and Expense']
export const workingCaptialSegmentationValues = ['Eligible Terms Rationalization',
    'Eligible Terms Rationalization  - Consulting',
    'Eligible Terms Rationalization  - Legal',
    'Eligible Terms Rationalization  - Marketing',
    'Eligible Terms Rationalization  - Unclassified',
    'Eligible Discount',
    'Eligible Discount  - Consulting',
    'Eligible Discount  - Legal',
    'Eligible Discount  - Marketing',
    'Eligible Discount  - Unclassified',
    'InEligible',
    'InEligible  - Associations',
    'InEligible  - Banking',
    'InEligible  - Corporate Cards',
    'InEligible  - Employee Payment',
    'InEligible  - Government',
    'InEligible  - HR/Benefit/Employee',
    'InEligible  - Insurance',
    'InEligible  - Real Estate',
    'InEligible  - Utilities',
    'Negative / No Spend',
    'Already Discounting',
    'Duplicate Record',
    'Intercompany Spend',
    'Low DPO/Net Terms: <25']
export const VendorLinkFormatter = (value) => {
        const vendorName = value.row['VENDOR_NAME']
        const capIQCompanyId = value.row['CAPIQ_COMPANY_ID']
        if (value.row.isCapIQLicesend && capIQCompanyId !== "" && capIQCompanyId !== undefined) {
            const val = "https://www.capitaliq.com/ciqdotnet/company.aspx?leftlink=true&companyId=" + capIQCompanyId;
            return (<div style={{ textAlign: 'center' }}><a href={val} target="_blank" style={{ textAlign: 'center', 'textDecoration': 'underline' }} title={vendorName}>{vendorName} </a></div>)
        } else {
            return (<div style={{ textAlign: 'center' }} title={vendorName}>{vendorName}</div>)
        }
    }

export const IndustryLinkFormatter = (value) => {
    const finalIndustryDesc = value.row['FINAL_INDUSTRY_DESCRIPTION']
    const finalIndustryId = value.row['FINAL_INDUSTRY_ID']
        if (value.row.isCapIQLicesend && finalIndustryId !== "" && finalIndustryId >= 1000000) {
            const val = "https://www.capitaliq.com/CIQDotNet/Lists/Constituents.aspx?subTypeId=" + finalIndustryId;
            return (<div style={{ textAlign: 'center' }}><a href={val} target="_blank" style={{ textAlign: 'center', 'textDecoration': 'underline' }} title={finalIndustryDesc}>{finalIndustryDesc} </a> </div>)
        } else {
            return (<div style={{ textAlign: 'center' }} title={finalIndustryDesc}>{finalIndustryDesc}</div>)
        }
    }

export const discountingVals = ['Eligible Discount', 'Eligible Discount  - Consulting', 'Eligible Discount  - Legal', 'Eligible Discount  - Marketing',
    'Eligible Discount  - Unclassified', 'Duplicate Record', 'Already Discounting', 'Intercompany Spend', 'Low DPO/Net Terms: <25']
export const termRationalizationVals = ['Eligible Terms Rationalization', 'Eligible Terms Rationalization  - Consulting', 'Eligible Terms Rationalization  - Legal',
            'Eligible Terms Rationalization  - Marketing', 'Eligible Terms Rationalization  - Unclassified', 'Duplicate Record', 
            'Already Discounting', 'Intercompany Spend', 'Low DPO/Net Terms: <25']
        