import React from 'react'

const SubLeverCalculationTableHeaders = (props) =>
	<thead>
		<tr>
			<th></th>
			{props.content.map((data, i) =>
				<th key={data}>{data}</th>
			)}
		</tr>
	</thead>

export default SubLeverCalculationTableHeaders
