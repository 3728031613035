import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

// Actions
import {
	toggleValueDriver,
	toggleSubLever
} from 'actions/valueDriverActions'

import {
	setEstimatedAnnualValue,
	updateSubLeverRowValue
}
	from 'actions/subLeverCalculationActions'

import { selectedValueDriver } from 'actions/valueDriverActions'

// Components
import ValueDrivers from './ValueDrivers'
import Value from './Value'
import SubLevers from './SubLevers'

// Functions
import { updateIncomeStatementAndBalanceSheet } from './BusinessValueCalculations'

// Utils
import { getSubLeverIdObject, formatThousands, round } from 'utils/Utils'

// UI
import Button from 'UI/Button'

// Settings
import { routes } from 'Constants'
import { EnableSKUsFromSublevers } from 'components/SKU/SolutionChange'
export class BusinessValueContainer extends PureComponent {

	constructor(props) {
		super(props)

		this.state = {
			render: false
		}

		this.selectValueDriver = this.selectValueDriver.bind(this)
		this.dispatchToggleValueDriver = this.dispatchToggleValueDriver.bind(this)
		this.dispatchToggleSubLever = this.dispatchToggleSubLever.bind(this)
	}

	selectValueDriver(id) {
		this.props.selectedValueDriver(id)
	}

	dispatchToggleValueDriver(id, bool) {
		// Dispatch to Redux
		this.props.toggleValueDriver(id, bool)

		// Toggle selected state of all child Sub Levers to true if
		// parent Value Driver is toggled
		this.props.userData.BusinessValueToYou[0].ValueDriver[id].SubLever.forEach((e, i) => {
			// Dispatch to store
			this.props.toggleSubLever({ BVUIdIndex: id, BVUSVDIdIndex: i }, bool)
			if (bool) {
				EnableSKUsFromSublevers(id, i);
			}
		})

		updateIncomeStatementAndBalanceSheet()
	}

	dispatchToggleSubLever(id, bool) {
		const subLeverIdObject = getSubLeverIdObject(id)
		let valueDriverSwitch

		// Dispatch to Redux
		this.props.toggleSubLever(subLeverIdObject, bool)
		if (bool) {
			EnableSKUsFromSublevers(subLeverIdObject.BVUIdIndex, subLeverIdObject.BVUSVDIdIndex);
		}

		// Check selected state of sibling Sub Levers and toggled Sub Lever
		// If none are selected, set selected state of parent Value Driver to false
		valueDriverSwitch = this.props.userData.BusinessValueToYou[0].ValueDriver[subLeverIdObject.BVUIdIndex].SubLever.some((e, i) => {
			// Return sibling Sub Lever selected state and not currently toggled Sub Lever's state
			return (e.Selected === true && i !== subLeverIdObject.BVUSVDIdIndex) || bool
		})

		// Dispatch to Redux
		// Toggle associated Value Driver to selected if any child Sub Lever is selected
		// Toggle associated Value Driver to unselected if no child Sub Lever is selected
		this.props.toggleValueDriver(subLeverIdObject.BVUIdIndex, valueDriverSwitch)

		updateIncomeStatementAndBalanceSheet()
	}

	shouldComponentUpdate(nextProps, nextState) {
		return nextState.render ? true : false
	}

	componentWillMount() {
		window.scrollTo(0, 0)
	}

	componentDidMount() {
		updateIncomeStatementAndBalanceSheet()
		this.setState({
			render: true
		})
	}

	render() {
		return (
			<div className="business-value-cont">
				<div className="grid-x">
					<div className="tab-title medium-12">{this.props.content.title}</div>
					<div className="tab-description">{this.props.content.description}</div>
				</div>
				<div className="grid-x grid-padding-x sublever-padding-top">
					<div className="medium-3">
						<div className="sublever-title">{this.props.content.valueDriversTitle}</div>
						<ValueDrivers
							content={this.props.content.valueDrivers}
							selectValueDriver={this.selectValueDriver}
							selected={this.props.activeValueDriver}
							savedData={this.props.userData.BusinessValueToYou[0].ValueDriver}
							dispatchToggleValueDriver={this.dispatchToggleValueDriver}
						/>
					</div>
					<div className="medium-9 sublevers subleversBg">
						<SubLevers
							id={this.props.activeValueDriver}
							content={this.props.content.subLevers[this.props.activeValueDriver]}
							subLeverCalculationContent={this.props.subLeverCalculationContent}
							currency={this.props.currency}
							estimatedValue={this.props.content.estimatedValue}
							savedData={this.props.userData.BusinessValueToYou[0].ValueDriver[this.props.activeValueDriver].SubLever}
							dispatchToggleSubLever={this.dispatchToggleSubLever}
						/>
						<div className="grid-x calculations grid-padding-x">
							<div className="cell medium-12">
								<div className="balance-sheet">
									<Value
										text={this.props.content.balanceSheet} className="balance-sheet"
										amount={formatThousands(round(this.props.userData.BusinessValueToYou[0].BalanceSheet, 2))}
										currency={this.props.currency}
									/>
								</div>
								<div className="float-left">
									<Value
										text={this.props.content.incomeStatement}
										amount={formatThousands(round(this.props.userData.BusinessValueToYou[0].IncomeStatement, 2))}
										currency={this.props.currency}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		content: state.content.businessValue,
		subLeverCalculationContent: state.content.subLeverCalculations.subLeverCalculation,
		userData: state.valueStory,
		currency: decodeURIComponent(state.valueStory.Currency),
		spendAsPercentageOfRevenue: state.spendAsPercentageOfRevenue,
		activeValueDriver: state.selectedValueDriver
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		selectedValueDriver: (id) => dispatch(selectedValueDriver(id)),
		toggleValueDriver: (id, bool) => dispatch(toggleValueDriver(id, bool)),
		toggleSubLever: (id, bool) => dispatch(toggleSubLever(id, bool)),
		updateSubLeverRowValue: (value, subLeverId, rowNumber, valueType) => dispatch(updateSubLeverRowValue(value, subLeverId, rowNumber, valueType)),
		setEstimatedAnnualValue: (value, subLeverId) => dispatch(setEstimatedAnnualValue(value, subLeverId))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(BusinessValueContainer)
