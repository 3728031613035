import React from 'react';
import {Tree} from 'primereact/tree';

//Overrided CSS proporties to match AVATAR look and feel in App.scss (.p-tree, .p-component, .p-treenode-content, .p-treenode-childrenn)
const TreeList = (props) => {
    return(
        <div className="grid-x content-section implementation">              
        <Tree value={props.nestedTreeData} 
        expandedKeys={props.expandedKeys}
        style={{padding:'.3em .3em'}} 
        nodeTemplate={props.nodeTemplate}/>   
        </div >
    )
}

export default TreeList;