import React from 'react'
import PropTypes from 'prop-types'

// UI
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

// Utils
import { onChangeRemoveMask } from 'utils/Utils'

const Columns = (props) => {
	const numberMask = createNumberMask({
		prefix: props.currency + ' ',
		suffix: ' M',
		allowDecimal: true,
		decimalLimit: 2,
		allowLeadingZeroes: true
	})

	const handleInputChange = (e, index, year) => props.handleInputChange(onChangeRemoveMask(e, props.currency), index, year)

	const count = 5
	let inputs = []

	for (let i = 0; i < count; i++) {

		inputs.push(
			<td key={i}>
				<MaskedInput
					className="masked-input"
					mask={numberMask}
					value={props.userData[props.index].UserInput[0]['Year' + (i + 1)]}
					onChange={(e) => handleInputChange(e, props.index, i + 1)}
					pattern="[0-9]*"
				/>
			</td>
		)
	}

	return	(
		<tr>
			<td>{props.content}</td>
			{ inputs }
		</tr>
	)
}

const Row = (props) =>
	<Columns
		index={props.index}
		content={props.content}
		userData={props.userData}
		currency={props.currency} 
		handleInputChange={props.handleInputChange}
	/>

const CalculateROITableRows = (props) =>
	<tbody>
		{props.content.map((data, i) =>
			<Row 
				key={data} 
				index={i}
				content={data}
				userData={props.userData}
				currency={props.currency}
				handleInputChange={props.handleInputChange}
			/>
		)}
	</tbody>

export default CalculateROITableRows

CalculateROITableRows.propTypes = {
	userData: PropTypes.array.isRequired,
	currency: PropTypes.string.isRequired,
	handleInputChange:PropTypes.func.isRequired
}
