const INITIAL = { isLoading: false, error: null, message: null }

export const searchText = (state = INITIAL, action) => {
    switch (action.type) {
        case 'Search-Text':
            return action.payload

        default:
            return state
    }
}

//It contains the entire result sent from procedure
export const searchData = (state = INITIAL, action) => {
    switch (action.type) {
        case 'Search-Data':
            return action.payload

        default:
            return state
    }
}

//it contains only unique data
export const searchResultData = (state = INITIAL, action) => {
    switch (action.type) {
        case 'Search-Result':
            return { searchResult: action.payload }
        case 'CRM_SEARCH_LOADING':
            return { ...state, isLoading: action.payload }
        case 'CRM_SEARCH_SUCCESS':
            return { ...state, isLoading: false, message: action.payload };
        case 'CRM_SEARCH_FAILURE':
            return { ...state, isLoading: false, error: action.payload };

        case 'CAPIQ_SEARCH_LOADING':
            return { ...state, isLoading: action.payload }
        case 'CAPIQ_SEARCH_SUCCESS':
            return { ...state, isLoading: false, message: action.payload };
        case 'CAPIQ_SEARCH_FAILURE':
            return { ...state, isLoading: false, error: action.payload };

        case 'RESET_ERROR':
            return { ...state, isLoading: false, error: null, message: null }
        default:
            return state
    }
}

export const treeData = (state = INITIAL, action) => {
    switch (action.type) {
        case 'Tree-Result':
            return action.payload

        default:
            return state
    }
}

export const companyProfileData = (state = INITIAL, action) => {
    switch (action.type) {
        case 'CompanyProfile-Data':
            return action.payload

        default:
            return state
    }
}

export const isNewValueStory = (state = false, action) => {
    switch (action.type) {
        case 'NewValueStory':
            return action.payload

        default:
            return state
    }
}