import React from 'react';
import * as d3 from 'd3';
import * as topojson from "topojson-client";
import { convertToMillion, formatThousands, convertToThousands } from 'utils/Utils';

class WorldMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mapData: [],
    }

    this.drawChart = this.drawChart.bind(this);
  }

  // componentDidMount() {
  //   this.drawChart(this.props);
  // }

  componentWillReceiveProps(nextProps) {
    if (nextProps.dValue !== this.props.dValue && nextProps.domain.length > 0) {
      //Perform some operation
      this.drawChart(nextProps);
    }
    if (nextProps.countryData.length > 0 && nextProps.countryData !== this.state.mapData && nextProps.domain.length > 0) {
      this.setState({ mapData: nextProps.countryData }, this.drawChart(nextProps))
    }
  }


  drawChart = (props) => {
    d3.select("#worldMapId").selectAll("svg").remove();
    Promise.all([
      d3.json("data/world_countries.json")
    ]).then(
      d => this.ready(null, d[0], props)
    );
  }


  ready(error, data, props) {
    let population = props.countryData

    const format = d3.format(',');
    // var colorscale = d3.schemeBlues[9];
    // var color = d3.scaleQuantize()
    // .domain(props.domain)
    // .range(colorscale);

    const color = d3.scaleThreshold()
      .domain(props.domain)
      .range([
        'rgb(15, 170, 255)',
        'rgb(0, 143, 211)',
        'rgb(0, 118, 203)',
        'rgb(15, 70, 167)',
        'rgb(0, 50, 131)',
        'rgb(0, 25, 90)'
      ]);
      // following is the vibgyor color patteren used for testing difference
      // .range([
      //   'rgb(255, 0, 0)',
      //   'rgb(255, 127, 0)',
      //   'rgb(255, 255, 0)',
      //   'rgb(0, 255, 0)',
      //   'rgb(0, 0, 255)',
      //   'rgb(75, 0, 130)'        
      // ]);
    // .range([
    //   // 'rgb(232,244,255)',
    //   'rgb(196,220,242)',
    //   'rgb(177,211,242)',
    //   'rgb(158,202,225)',
    //   'rgb(107,174,214)',
    //   'rgb(66,146,198)',
    //   'rgb(33,113,181)',
    //   'rgb(8,81,156)',
    //   'rgb(8,48,107)',
    //   'rgb(3,19,43)'
    // ]);

    // .range([
    //   'rgb(247,251,255)',
    //   'rgb(222,235,247)',
    //   'rgb(198,219,239)',
    //   'rgb(158,202,225)',
    //   'rgb(107,174,214)',
    //   'rgb(66,146,198)',
    //   'rgb(33,113,181)',
    //   'rgb(8,81,156)',
    //   'rgb(8,48,107)',
    //   'rgb(3,19,43)'
    // ]);

    const margin = { top: 0, right: 0, bottom: 0, left: 0 };
    const width = 960 - margin.left - margin.right;
    const height = 450 - margin.top - margin.bottom;

    // Define the div for the tooltip
    d3.select('#tooltipdiv').remove();
    var div = d3.select("body").on("click", () => {
      div.transition()
        .duration(100)
        .style("opacity", 0);
    }).append("div").attr("id", 'tooltipdiv')
      .attr("class", "tooltip")
      .style("opacity", 0)
      .style("max-width", "12rem");

    var svg = d3.select("#worldMapId").append("svg")
      .attr('preserveAspectRatio', 'xMinYMin meet')
      .attr(
        'viewBox',
        '0 0 ' +
        (width + margin.left + margin.right) +
        ' ' +
        (height + margin.top + margin.bottom)
      )
      .append("g")
      .attr("transform",
        "translate(" + margin.left + "," + margin.top + ")")
      .attr('class', 'map');

    const projection = d3.geoMercator()
      .scale(148)
      .rotate([352, 0, 0])
      .translate([width / 2, height / 2]);

    const path = d3.geoPath().projection(projection);

    const invoiceSpend = {};
    const country = {};
    const invoiceCount = {};
    const poSpend = {};
    const poCount = {};
    const vendorCount = {};

    // population.forEach(d => { populationById[d.ISO_COUNTRY_CODE] = +d.AGGR_INV_SPEND; country[d.ISO_COUNTRY_CODE] = d.name; count[d.ISO_COUNTRY_CODE] = +d.count; });
    // data.features.forEach(d => { d.AGGR_INV_SPEND = populationById[d.id]; d.name = country[d.id]; d.count = count[d.id] });
    population.forEach(d => {
      invoiceSpend[d.ISO_COUNTRY_CODE] = +d.AGGR_INV_SPEND;
      country[d.ISO_COUNTRY_CODE] = d.COUNTRY_NAME;
      invoiceCount[d.ISO_COUNTRY_CODE] = +d.AGGR_INV_CNT;
      poSpend[d.ISO_COUNTRY_CODE] = +d.AGGR_PO_SPEND;
      poCount[d.ISO_COUNTRY_CODE] = +d.AGGR_PO_CNT;
      vendorCount[d.ISO_COUNTRY_CODE] = +d.VENDOR_ID_CNT;
    });
    data.features.forEach(d => {
      d.AGGR_INV_SPEND = invoiceSpend[d.id] >= 0 ? invoiceSpend[d.id] : -1;
      d.name = country[d.id] != undefined && d.id !== 'FRA'? country[d.id] : d.properties.name;
      d.invoiceCount = invoiceCount[d.id] >= 0 ? invoiceCount[d.id] : -1;
      d.poSpend = poSpend[d.id] >= 0 ? poSpend[d.id] : -1;
      d.poCount = poCount[d.id] >= 0 ? poCount[d.id] : -1;
      d.vendorCount = vendorCount[d.id] >= 0 ? vendorCount[d.id] : -1;
    });

    function fillColor(d) {
      //return color(props.dValue == 'AGGR_INV_SPEND' ? invoiceSpend[d.id] : props.dValue == 'AGGR_INV_CNT'? invoiceCount[d.id] : props.dValue == 'AGGR_PO_SPEND' ? poSpend[d.id] : props.dValue == 'AGGR_PO_CNT' ? poCount[d.id] : vendorCount[d.id])
      var colorValue;
      if (props.dValue == 'AGGR_INV_SPEND') {
        colorValue = invoiceSpend[d.id] != undefined ? invoiceSpend[d.id] : -1;
      } else if (props.dValue == 'AGGR_INV_CNT') {
        colorValue = invoiceCount[d.id] != undefined ? invoiceCount[d.id] : -1;
      } else if (props.dValue == 'AGGR_PO_SPEND') {
        colorValue = poSpend[d.id] != undefined ? poSpend[d.id] : -1;
      } else if (props.dValue == 'AGGR_PO_CNT') {
        colorValue = poCount[d.id] != undefined ? poCount[d.id] : -1;
      } else {
        colorValue = vendorCount[d.id] != undefined ? vendorCount[d.id] : -1;
      }

      if (colorValue > 0) {
        return color(colorValue);
      } else {
        return 'lightgray';
      }

    }
    svg.append('g')
      .attr('class', 'countries')
      .selectAll('path')
      .data(data.features)
      .enter().append('path')
      .attr('d', path)
      .style('fill', d => fillColor(d))
      .style('stroke', 'white')
      .style('opacity', 0.8)
      .style('stroke-width', 0.3)
      .on('dblclick', function (data) {
        props.onDoubleClick(data)
      })
      // tooltips
      .on('mouseover', function (data) {
        d3.select(this)
          .style('opacity', 1)
          .style('stroke-width', 3)
        div.transition()
          .duration(200)
          .style("opacity", 1).style("cursor", 'pointer')
        // div.html("<strong>Country: </strong><span class='details'>" + data.name + "<br></span><strong>Population: </strong><span class='details'>" + format(data.population) + "</span>")
        //   .style("left", (d3.event.pageX) + "px")
        //   .style("top", (d3.event.pageY - 28) + "px")
        if (props.dValue == 'AGGR_INV_SPEND') {

          div.html("<strong>Country: </strong><span class='details'>" + data.name + "<br></span><strong>Invoice Spend: </strong><span class='details'>" + (data.AGGR_INV_SPEND > 0 ? props.currency + convertToMillion(data.AGGR_INV_SPEND, true) : 'N/A') + "</span>")
            .style("left", (d3.event.pageX) + "px")
            .style("top", (d3.event.pageY - 28) + "px")

        } else if (props.dValue == 'AGGR_INV_CNT') {

          div.html("<strong>Country: </strong><span class='details'>" + data.name + "<br></span><strong>Invoice Count: </strong><span class='details'>" + (data.invoiceCount > 0 ? format(data.invoiceCount) : 'N/A') + "</span>")
            .style("left", (d3.event.pageX) + "px")
            .style("top", (d3.event.pageY - 28) + "px")

        } else if (props.dValue == 'AGGR_PO_SPEND') {

          div.html("<strong>Country: </strong><span class='details'>" + data.name + "<br></span><strong>PO Spend: </strong><span class='details'>" + (data.poSpend > 0 ? props.currency + convertToMillion(data.poSpend, true) : 'N/A') + "</span>")
            .style("left", (d3.event.pageX) + "px")
            .style("top", (d3.event.pageY - 28) + "px")

        } else if (props.dValue == 'AGGR_PO_CNT') {

          div.html("<strong>Country: </strong><span class='details'>" + data.name + "<br></span><strong>PO Count: </strong><span class='details'>" + (data.poCount > 0 ? format(data.poCount) : 'N/A') + "</span>")
            .style("left", (d3.event.pageX) + "px")
            .style("top", (d3.event.pageY - 28) + "px")

        } else {

          div.html("<strong>Country: </strong><span class='details'>" + data.name + "<br></span><strong>Vendor Count: </strong><span class='details'>" + (data.vendorCount > 0 ? format(data.vendorCount) : 'N/A') + "</span>")
            .style("left", (d3.event.pageX) + "px")
            .style("top", (d3.event.pageY - 28) + "px")

        }

      })
      .on('mouseout', function (d) {
        // tips.hide(d);
        d3.select(this)
          .style('opacity', 0.8)
          .style('stroke-width', 0.3);
      });

    svg.append('path')
      .datum(topojson.mesh(data.features, (a, b) => a.id !== b.id))
      .attr('class', 'names')
      .attr('d', path);

    // legend({
    //   color: d3.scaleSequentialLog([1, 100], d3.interpolateBlues),
    //   title: "Energy (joules)",
    //   ticks: 10,
    //   tickFormat: ".0s"
    // })
      props.showLoading(false)
  }



  render() {
    return (
      <div id="worldMapId">
      </div>
    )
  }
}



export default WorldMap