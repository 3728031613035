import React from 'react';
import * as d3 from 'd3';

import { convertToMillion, formatThousands, convertToThousands } from 'utils/Utils'


class ColorLegend extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            summaryScopeData: [],

        }

        this.drawChart = this.drawChart.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.dValue !== this.props.dValue && nextProps.domain.length > 0) {
            //Perform some operation
            this.drawChart(nextProps);
        }

        if (nextProps.countryData !== this.state.mapData && nextProps.domain.length > 0) {
            this.setState({ mapData: nextProps.countryData }, this.drawChart(nextProps))
        }
    }


    drawChart = (props) => {
        d3.select("#colorid").selectAll("svg").remove();
        var svg = d3.select("#colorid").append("svg");

        //var colorscale = d3.schemeBlues[9];
        // var color = d3.scaleQuantize()
        //     .domain(props.range)
        //     .range(colorscale);
        // var colorscale = [
        //     // 'rgb(232,244,255)',
        //     'rgb(196,220,242)',
        //     'rgb(177,211,242)',
        //     'rgb(158,202,225)',
        //     'rgb(107,174,214)',
        //     'rgb(66,146,198)',
        //     'rgb(33,113,181)',
        //     'rgb(8,81,156)',
        //     'rgb(8,48,107)',
        //     'rgb(3,19,43)'
        // ];

        var colorscale = [
            'rgb(15, 170, 255)',
            'rgb(0, 143, 211)',
            'rgb(0, 118, 203)',
            'rgb(15, 70, 167)',
            'rgb(0, 50, 131)',
            'rgb(0, 25, 90)'
          ];
        // following is the vibgyor color patteren used for testing difference
        // var colorscale = [
        //     'rgb(255, 0, 0)',
        //     'rgb(255, 127, 0)',
        //     'rgb(255, 255, 0)',
        //     'rgb(0, 255, 0)',
        //     'rgb(0, 0, 255)',
        //     'rgb(75, 0, 130)'
        // ];
        const color = d3.scaleQuantize()
            .domain(props.range)
            .range(colorscale);


        drawColorScale();

        function drawColorScale() {
            var pallete = svg.append('g')
                .attr('id', 'pallete');

            const format = d3.format(',');
            var swatch = pallete.selectAll('rect').data(colorscale);
            swatch.enter().append('rect')
                .attr('fill', function (d) {
                    return d;
                })
                .attr('x', function (d, i) {
                    return i * 50;
                })
                .attr('y', 50)
                .attr('width', 50)
                .attr('height', 10);

            var texts = pallete.selectAll("foo")
                .data(props.domain)
                .enter()
                .append("text")
                .attr("font-size", "10px")
                .attr("text-anchor", "middle")
                .attr("y", 80)
                .attr('x', function (d, i) {
                    return i * 50 + 25;
                })
                .text(function (d, i) {
                    return props.dValue == 'AGGR_INV_SPEND' || props.dValue == 'AGGR_PO_SPEND' ? i == 0 ? convertToMillion(0) : convertToMillion(props.domain[i - 1]) : i == 0 ? format(Math.round(0)) : format(Math.round(props.domain[i - 1]))
                })
                .append("tspan")
                .attr("dy", "1.3em")
                .attr('x', function (d, i) {
                    return i * 50 + 25;
                })
                .text("to")
                .append("tspan")
                .attr("dy", "1.3em")
                .attr('x', function (d, i) {
                    return i * 50 + 25;
                })
                .text(function (d, i) {
                    return props.dValue == 'AGGR_INV_SPEND' || props.dValue == 'AGGR_PO_SPEND' ? convertToMillion(props.domain[i]) : format(Math.round(props.domain[i]))
                })

        }
    }



    render() {
        return (
            <div id="colorid">
            </div>
        )
    }
}



export default ColorLegend