import React from 'react'

// Utils
import classnames from 'classnames'
import { BreakLine } from 'utils/Utils'

const OnlineStatus = (props) =>
	<div className={
		classnames(
			'online-status',
			{
				'online-status--is-active': props.user.online !== null,
				'online-status--is-offline': props.user.online === false
			}
		)
	}>
		{ props.user.online === false ? BreakLine(props.ui.offline) : BreakLine(props.ui.online) }
	</div>

export default OnlineStatus
