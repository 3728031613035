import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Form, Control, Errors, actions } from 'react-redux-form'

// Actions
import {
	reportSubmit,
	reportSubmitSuccess,
	reportSubmitFailure,
	replacePlaceholders,
	downloadReport,
	resetError,
	resetMessage
} from 'actions/userActions'

import {
	setMutualActionPlan
} from 'actions/nextStepsActions'

import {
	valueStoryCurrentMenu,
	showValueStoryMenu
}
	from 'actions/userActions'

// Components
import NextStep from './NextStep'
import Report from 'Menu/Report'
import VideoHelp from 'components/UI/VideoHelp'

// UI
import Button from 'UI/Button'
import Modal from 'reboron/OutlineModal'

// Images
import actionPlanIcon from '../../images/create.svg'
import procurementIcon from '../../images/email.svg'
import sellerValueIcon from '../../images/explore.svg'
import benchmarkingIcon from '../../images/register.svg'

// Settings
import { modalStyle, modalContentStyle, validators } from 'Constants'

const icons = [
	actionPlanIcon,
	procurementIcon,
	sellerValueIcon,
	benchmarkingIcon
]

const autoHeightModalStyle = Object.assign({}, modalStyle)
autoHeightModalStyle.height = 'auto';
autoHeightModalStyle.width = '1675px';
autoHeightModalStyle.maxWidth = '1675px';


const customModalContentStyle = Object.assign({}, modalContentStyle)
customModalContentStyle.padding = '0'


class NextStepsContainer extends Component {

	constructor(props) {
		super(props)

		this.state = {
			reportType: '1',
			mutualActionPlanValue: this.props.userData.MutualActionPlan
		}

		this.showModal = this.showModal.bind(this)
		this.hideModal = this.hideModal.bind(this)
		this.handleChange = this.handleChange.bind(this)
		this.handleMutualActionPlanSubmit = this.handleMutualActionPlanSubmit.bind(this)
		this.handleRadioButtonClick = this.handleRadioButtonClick.bind(this)
		this.handleReportSubmit = this.handleReportSubmit.bind(this)
		this.downloadFile = this.downloadFile.bind(this)

	}

	showModal(action) {
		this.refs[action].show()
		this.props.resetError()
		this.props.resetMessage()
		this.setState({
			reportType: this.state.reportType
		})
	}

	hideModal(action) {
		this.formDispatch(actions.reset(action))
		this.props.resetError()
		this.props.resetMessage()
		this.refs[action].hide()
	}

	handleChange(e) {
		this.setState({ mutualActionPlanValue: e.target.value });
	}

	handleMutualActionPlanSubmit(formValues) {
		this.props.setMutualActionPlan(formValues.MutualActionPlan)
		this.refs['plan'].hide()
	}

	handleRadioButtonClick(value) {
		this.setState({
			reportType: value
		})

		this.props.resetMessage()
	}

	handleReportSubmit(formValues) {
		this.props.reportSubmitSuccess(this.props.reportContent.waitMessage)
		this.props.resetError()
		let values = Object.assign({}, formValues)
		values.Type = this.state.reportType
		var content = this.props.reportContent
		this.props.reportSubmit(values) //Build Report Using Template files
			.then((response) => {
				if (response.status !== undefined && response.status === 200) {
					//this.props.reportSubmitSuccess('Still generating report. Please be patient.')
					this.props.replacePlaceholders(values, response.data.Result) //Replace placeholders after generating Report Using Template files
						.then((response) => {
							if (response.status !== undefined && response.status === 200) {
								this.props.downloadReport(values, response.data.Result) //Download Report
									.then((response) => {
										if (response.status !== undefined && response.status === 200) {
											this.downloadFile(response)
											this.props.reportSubmitSuccess(content['200'])
											//document.getElementById('report.Email').blur()
											//this.formDispatch(actions.reset('report.Email'))
										}
										else {
											this.props.reportSubmitFailure(response.response.data.Message)
										}
									}).catch((error) => {
										this.props.reportSubmitFailure(error.response.data.Message)
									})
							}
							else {
								this.props.reportSubmitFailure(response.response.data.Message)
							}
						}).catch((error) => {
							this.props.reportSubmitFailure(error.response.data.Message)
						})
				}
				else if (response.response.status == 400 && response.response.data.Message == 'requestfileNotPresent') {
					this.props.reportSubmitFailure(this.props.reportContent.requestfileNotPresent)
				}
				else {
					this.props.reportSubmitFailure(response.response.data.Message)
				}
			}).catch((error) => {
				this.props.reportSubmitFailure(error.response.data.Message)
			})
	}

	downloadFile(response) {
		const byteArrays = [];
		const byteCharacters = atob(response.data);

		for (let offset = 0; offset < byteCharacters.length; offset += 512) {
			const slice = byteCharacters.slice(offset, offset + 512);
			const byteNumbers = new Array(slice.length);
			for (let i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}

			const byteArray = new Uint8Array(byteNumbers);
			byteArrays.push(byteArray);
		}

		var blob = new Blob(byteArrays);

		var [, filename] = response.headers['content-disposition'].split('filename=');
		filename = filename.replace(/ /g, "_").replace(/"/g, "")

		if (navigator.msSaveBlob) {
			navigator.msSaveBlob(blob, filename);
		}
		else {
			var link = document.createElement("a");
			link.href = URL.createObjectURL(blob);
			link.setAttribute('visibility', 'hidden');
			link.download = filename
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	}

	componentWillMount() {
		var reportTypeArray = [];
		var _applicationFeatures = this.props.user.user.applicationFeatures
		var item = this.props.allReports.reportTypes
		//if (item.reportAction != undefined && item.reportAction != null) {			
		var obj = Object.keys(item).map(function (childitem) {
			if (_applicationFeatures.findIndex(a => a.Action === (item[childitem].reportAction)) > -1) {
				reportTypeArray.push(item[childitem].reportId)
			}
		});

		let flag = localStorage.getItem('enablementInsightFlag')
		if (flag == "true") {
			this.setState({ reportType: 5 })
		}
		else if (reportTypeArray.length > 0) {
			this.setState({ reportType: reportTypeArray[0] })
		}

		this.props.valueStoryCurrentMenu('Next Steps');
		this.props.showValueStoryMenu(true);
		window.scrollTo(0, 0)
	}

	render() {
		return (
			<div className="next-steps grid-container">
				<div className="grid-x pt20">
					<span className="page-title">{this.props.content.title}</span>
					<span style={{ marginTop: '5px' }}>{<VideoHelp screen={{ module: 'Next Steps', screenName: 'Generate Custom Report' }} />}</span>
				</div>
				<div className="grid-x grid-padding-x next-step-space">
					{this.props.content.steps.map((data, i) =>
						<NextStep key={i}
							index={i}
							content={data}
							icon={icons[i]}
							color={i < 2 ? true : false}
							showModal={this.showModal}
						/>
					)}
				</div>
				<Modal
					ref="plan"
					modalStyle={autoHeightModalStyle}
					contentStyle={modalContentStyle}>
					<div className="page-title">{this.props.content.steps[0].modal.title}</div>
					<Form className="mt20"
						model="plan"
						getDispatch={(dispatch) => this.formDispatch = dispatch}
						onSubmit={(formValues) => { this.handleMutualActionPlanSubmit(formValues) }}
					>
						<Control.textarea
							model="plan.MutualActionPlan"
							id="plan.MutualActionPlan"
							value={this.state.mutualActionPlanValue}
							onChange={this.handleChange}
							className="mutual-action-plan"
							validators={{ required: validators.required }}
							validateOn="change"
						/>
						<Errors
							className="errors"
							model="plan.MutualActionPlan"
							show="touched"
							messages={{
								required: this.props.validation.required
							}}
						/>
						<div className="errors">{this.props.user.error}</div>
						<div className="success">{this.props.user.message}</div>
						<div className="grid-x">
							<div className="medium-12">
								<Button
									type="submit"
									disabled={this.props.user.loading}
									content={this.props.content.steps[0].modal.cta}
									float
									color />
							</div>
						</div>
					</Form>
					<button onClick={() => this.hideModal('plan')} className="close" />
				</Modal>
				<Modal
					ref="report"
					modalStyle={autoHeightModalStyle}
					contentStyle={customModalContentStyle}>
					<Form className="report"
						model="report"
						getDispatch={(dispatch) => this.formDispatch = dispatch}
						onSubmit={(formValues) => this.handleReportSubmit(formValues)}
					>
						<Report
							content={this.props.reportContent}
							allReports={this.props.allReports}
							handleRadioButtonClick={this.handleRadioButtonClick}
							resetMessage={this.props.resetMessage}
						/>
						<div className="errors">{this.props.user.error}</div>
						<div className="success">{this.props.user.message}</div>
						<div className="grid-x pr45">
							<div className="medium-12">
								<Button
									type="submit"
									disabled={this.props.user.loading}
									content={this.props.reportContent.cta}
									color />
							</div>
						</div>
					</Form>
					<button onClick={() => this.hideModal('report')} className="close" />
				</Modal>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		content: state.content.nextSteps,
		reportContent: state.content.menu.getReport,
		validation: state.content.validation,
		userData: state.valueStory,
		user: state.default,
		allReports: state.content.reports
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setMutualActionPlan: (mutualActionPlan) => dispatch(setMutualActionPlan(mutualActionPlan)),
		reportSubmit: (formValues) => dispatch(reportSubmit(formValues)),
		reportSubmitSuccess: (user) => dispatch(reportSubmitSuccess(user)),
		reportSubmitFailure: (error) => dispatch(reportSubmitFailure(error)),
		replacePlaceholders: (formValues, fileName) => dispatch(replacePlaceholders(formValues, fileName)),
		downloadReport: (formValues, fileName) => dispatch(downloadReport(formValues, fileName)),
		resetError: () => dispatch(resetError()),
		resetMessage: () => dispatch(resetMessage()),
		valueStoryCurrentMenu: (menuItem) => dispatch(valueStoryCurrentMenu(menuItem)),
		showValueStoryMenu: (menuItem) => dispatch(showValueStoryMenu(menuItem)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(NextStepsContainer)