import React from 'react';
import classNames from "classnames";
import { roundFix } from 'utils/Utils'

const columnStyle = {
    float: 'left',
    marginRight: 5,
    maxWidth: '100%'
  };

const badgeStyle = {
    cursor: 'help'
  };

const tooltipText = 'Input Methods: Range (x-y), Greater Than (>x), Less Than (<y)';

class NumericFilterRenderer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      filterTerm: [],
      value: ''
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.clear = this.clear.bind(this)
  }

  clear() {
      this.setState({value: ''})
  }

  handleKeyPress(event) {
    const result = /[><,0-9-.!]/.test(event.key);
    if (result === false) {
      event.preventDefault();
    }
  }

  handleChange(event) {
    const { value } = event.target;
    const filters = this.getRules(value);
    //const arr = [{value: value, label: value}]
    this.setState({value: value});
    this.props.onChange({
      filterTerm: filters.length > 0 ? filters : '',
      column: this.props.column,
      rawValue: value,
      filterValues: this.filterValues,
      clear: this.clear,
      type: NumericFilterRenderer
    });
  }

  filterValues(row, columnFilter, columnKey) {
    if (columnFilter.filterTerm == '') {
      return true;
    }
  
    // implement default filter logic
    const value = parseInt(roundFix(row[columnKey], 0));
    let valid = false;
    for (const ruleKey in columnFilter.filterTerm) {
      const rule = columnFilter.filterTerm[ruleKey];
      let include = false
      let match = true;
      var ruleValue = rule.value;
      var ruleBegin = rule.begin;

      if(ruleValue && ruleValue === -1) {
        match = false;
      } else if(ruleValue && ruleValue.startsWith('!')) {
        match = false;
        ruleValue = parseInt(ruleValue.substring(1), 10)
      } else if(ruleBegin && ruleBegin.startsWith('!')) {
        match = false;
        ruleBegin = parseInt(ruleBegin.substring(1), 10)
      } else {
        ruleValue = ruleValue & parseInt(ruleValue, 10)
        ruleBegin = ruleBegin & parseInt(ruleBegin, 10)
      }

      switch (rule.type) {
        case 1:
          if (ruleValue === value) {
            include = true;
          }
          break;
        case 3:
          if (ruleValue < value) {
            include = true;
          }
          break;
        case 4:
          if (ruleValue > value) {
            include = true;
          }
          break;
        case 2:
          if (ruleBegin <= value && rule.end >= value) {
            include = true;
          }
          break;
        
        case 5:
        if (value === '' || value === 0) {
          include = true;
        }
        break;
        default:
          break;
      }

      valid = valid || (include === match)
    }
  
    return valid;
  }

  getRules(value) {
    if (value === '') {
      return [];
    }
  
    // handle each value with comma
    return value.split(',').map((str) => {
      // handle dash
      if(str === '!') {
        return { type: 5, value: -1 };
      }
      const dashIdx = str.indexOf('-');
      if (dashIdx > 0) {
        const begin = str.slice(0, dashIdx);
        const end = str.slice(dashIdx + 1);
        return { type: 2, begin, end };
      }
  
      // handle greater then
      if (str.includes('>')) {
        const begin = str.slice(str.indexOf('>') + 1);
        return { type: 3, value: begin };
      }
  
      // handle less then
      if (str.includes('<')) {
        const end = str.slice(str.indexOf('<') + 1);
        return { type: 4, value: end };
      }
  
      // handle normal values
      const numericValue = str;
      return { type: 1, value: numericValue };
    });
  }

  render() {
    //const val = this.state.filterTerm? this.state.filterTerm.length === 0? '' : this.state.filterTerm[0].value : ''
    return ( this.props.column.filterable? 
        <div>
        <div style={columnStyle}>
          <input
            key={`header-filter-${this.props.column.key}`}
           // value={val}
            placeholder="e.g. 3,10-15,>20"
            className="form-control input-sm"
            value={this.state.value}
            onChange={this.handleChange}
            onKeyPress={this.handleKeyPress}
          />
        </div>
        <div className="input-sm">
          <span className="badge" style={badgeStyle} title={tooltipText}>?</span>
        </div>
      </div> : <div />
    );
  }
}

export default NumericFilterRenderer