import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ScrollArea from 'react-scrollbar'
// Actions
import {
    requestValueStory,
    requestValueStoryFailure,
    requestValueStorySuccess
} from 'actions/userActions'
// Utils
import moment from 'moment'
import classnames from 'classnames'
import { GoPlus, GoMail, GoDash } from "react-icons/lib/go";
import Modal from 'reboron/OutlineModal'
import { modalStyle, emailmodalContentStyle } from 'Constants'
import Button from 'UI/Button'
const autoHeightModalStyle = Object.assign({}, modalStyle)
autoHeightModalStyle.height = 'auto'
autoHeightModalStyle.width = '30%'


class ValueStoryList extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            content: false,
            index: -1,
            toggleArr: props.valueStories,
            VSID: '',
            companyName: '',
            originatorEmail: ''
        }

        this.triggerEmail = this.triggerEmail.bind(this);
    }

    // componentWillReceiveProps() {
    //     var temp = []
    //     if (this.props.valueStories.length > 0) {
    //         temp = this.props.valueStories;
    //         temp.forEach((element, i) => {
    //             element.content = false;
    //             element.index = i;
    //         });
    //         this.setState({ toggleArr: temp })
    //     } else if(this.props.valueStories.length == 0){
    //         let arr = []
    //         this.setState({ toggleArr: arr })
    //     }

    // }

    static getDerivedStateFromProps(nextProps, state) {
        if (nextProps.valueStories.length != state.toggleArr) {
            return ({ toggleArr: nextProps.valueStories })
        }
    }

    triggerEmail(originatorEmail, VSID) {
        this.refs.modal.hide()
        //this.props.EmailProgress(true, false, '')
        var emailSuccess = this.props.content.search.emailSuccess
        var emailError = this.props.content.search.emailError
        var requesterEmail = this.props.user.user.Email
        var language = this.props.language.toUpperCase();
        this.props.requestValueStory(originatorEmail, requesterEmail, VSID, language)
        // .then((response) => {
        //     this.props.requestValueStorySuccess(response)
        // }).catch((error) => {
        //     this.props.requestValueStoryFailure(response)
        // })
    }

    toggleDiv(ele) {
        var temp = this.state.toggleArr;
        temp.forEach((item, i) => {
            if (item.index == ele.currentTarget.id && item.content == false) {
                item.content = true;
            } else if (item.index == ele.currentTarget.id && item.content == true) {
                item.content = false;
            }
        })
        this.setState({ toggleArr: temp }, this.forceUpdate)
    }
    showModal(originatorEmail, VSID, companyName) {
        this.setState({ VSID: VSID, originatorEmail: originatorEmail, companyName: companyName })
        this.refs.modal.show()
    }

    hideModal() {
        this.refs.modal.hide()
    }
    render() {

        var EmailConfirmation = () => {
            var companyName = this.state.companyName
            return (
                <div className="grid-x">
                    <div style={{ padding: '10px 0px 10px 10px' }} className="medium-12">
                        <p>{this.props.content.search.emailAlert.replace("%CompanyName%", companyName)}</p>
                    </div>

                    <div className="medium-12 popupOkBtn">
                        <div className="float-right">
                            <div className="float-left">
                                <Button onClick={() => { this.triggerEmail(this.state.originatorEmail, this.state.VSID) }}
                                    content='Yes'
                                    color
                                />
                            </div>
                            <div className="float-left">
                                <Button onClick={() => this.hideModal()}
                                    content='No'
                                    color
                                />
                            </div>
                        </div>
                    </div>

                </div >
            )
        }

        return (
            <React.Fragment>
                {this.props.isLoading == 'true' ? <p>Loading...</p> :
                    this.state.toggleArr.length > 0 ?
                        (this.state.toggleArr.map((data, i) =>
                            <div id={i} className="grid-x value-story-row grey_bb">
                                <div className="large-9 medium-9">
                                    <p className="date">{moment(data.ModifiedDate).format('MMM Do/YYYY')}</p>
                                    <p className="name">{data.ValueStoryName} | {data.CompanyName} | {this.props.content.discovery.form.industries[data.Industry]} | {this.props.content.discovery.form.locations[data.Location]}</p>
                                    <p className={data.content ? 'contentShow' : 'contentHide'}>
                                        <ul className='list'>
                                            {data.Peers ? <li>Peers</li> : null}
                                            {data.Priorities ? <li>Priorities</li> : null}
                                            {data.Spend ? <li>Spend</li> : null}
                                            {data.SKUs ? <li>SAP Solutions</li> : null}
                                            {data.BusinessCase ? <li>Business case</li> : null}
                                            {data.AdoptionPlan ? <li>Adoption plan</li> : null}
                                        </ul>
                                    </p>
                                </div>
                                <div className="large-3 medium-3">
                                    <div className="float-right" style={{ 'paddingTop': '5px' }}>
                                        <div onClick={this.toggleDiv.bind(this)} className="float-left plus-icon grey-icon" id={i}>
                                            {data.content ? <img width="18" src="images/minus_active.png" /> : <img width="18" src="images/plus_active.svg" />}
                                        </div>
                                        {/* <div className="float-left mail-icon" onClick={(event) => { this.triggerEmail(data.Email, data.VSID, event) }}> */}
                                        <div className="float-left mail-icon">
                                            <img width="18" src="images/email_inactive.svg" onClick={() => this.showModal(data.Email, data.VSID, data.CompanyName)} />
                                            <Modal
                                                ref={'modal'}
                                                keyboard={() => this.callback()}
                                                modalStyle={autoHeightModalStyle}
                                                contentStyle={emailmodalContentStyle}>
                                                {EmailConfirmation()}
                                            </Modal>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        ))
                        : <p>No value stories exist with this company name.</p>
                }
            </React.Fragment >
        )
    }
}

const mapStateToProps = (state) => {
    return {
        language: state.language,
        content: state.content,
        ui: state.content.ui,
        user: state.default,
        validation: state.content.validation
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        requestValueStorySuccess: (bool) => dispatch(requestValueStorySuccess(bool)),
        requestValueStoryFailure: (error) => dispatch(requestValueStoryFailure(error)),
        requestValueStory: (originatorEmail, requesterEmail, VSID, language) => dispatch(requestValueStory(originatorEmail, requesterEmail, VSID, language))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ValueStoryList)
