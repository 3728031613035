import React, { Fragment } from 'react';
import { connect } from 'react-redux'
import Select from 'react-select'
import classNames from "classnames"
import { path } from 'Constants'
import cloneDeep from 'lodash/cloneDeep';
import { roundFix, formatThousands, getNonEmptyValue, isColumnMapped } from 'utils/Utils'
import { Formik, withFormik } from 'formik'
import { ControlTextE, SelectE, CheckboxE, RadioButtonE, ButtonE, ButtonNativeE, TextAreaE, ControlTextNativeE } from 'UI/CustomComponents/Controls';

import {
    getSupplierDrillScopeDataLoading,
    getSupplierDrillScopeDataSuccess,
    getSupplierDrillScopeDataFailure,
    getSupplierDrillScopeData,
    defineScopeLoading,
    supplierDrillDownUpdate,
    trackSupplierDrillDownChanges,
    trackSupplierDrillDownSave,
    setPopUpTabLoadingStatus
} from 'actions/spendActions'

const dropdownValues = [{ label: 'Yes', value: 'Yes' }, { label: 'No', value: 'No' }];
//const directSpendValues = [{label: 'No', value: 'No'}, {label: 'Yes', value: 'Yes'}];
const scopeValues = [{ label: 'Industry', value: 'Industry' }, { label: 'Override', value: 'Override' }];
const requisitionValues = [{ label: 'Catalog', value: 'Catalog' }, { label: 'Adhoc (non-catalog)', value: 'Adhoc (non-catalog)' }, { label: 'N/A', value: 'N/A' }]
const aribaBuyProcessValues = [{ label: 'Requisition: Catalog, Spot-Buy or Release Contract', value: 'Requisition: Catalog, Spot-Buy or Release Contract' },
{ label: 'Requisition: Non-Catalog or Tactical Sourcing', value: 'Requisition: Non-Catalog or Tactical Sourcing' },
{ label: 'Requisition: External Talent', value: 'Requisition: External Talent' },
{ label: 'Consultancy/SOW Services: Active Mgmt, otherwise Invoice Against Contract', value: 'Consultancy/SOW Services: Active Mgmt, otherwise Invoice Against Contract' },
{ label: 'Other Services: Active Mgmt, otherwise Invoice Against Contract', value: 'Other Services: Active Mgmt, otherwise Invoice Against Contract' },
{ label: 'Invoice Against Contract', value: 'Invoice Against Contract' },
{ label: 'Direct/MRO: Plan Driven PO', value: 'Direct/MRO: Plan Driven PO' },
{ label: 'Direct/MRO: Asset Plan via Catalog', value: 'Direct/MRO: Asset Plan via Catalog' },
{ label: 'Non-PO Invoice', value: 'Non-PO Invoice' },
{ label: 'Travel and Expense', value: 'Travel and Expense' }]

const validate = values => {
    const errors = {};
    if (!values.firstName) {
        errors.firstName = 'Required';
    } else if (values.firstName.length > 15) {
        errors.firstName = 'Must be 15 characters or less';
    }

    if (!values.lastName) {
        errors.lastName = 'Required';
    } else if (values.lastName.length > 20) {
        errors.lastName = 'Must be 20 characters or less';
    }

    if (!values.email) {
        errors.email = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address';
    }

    return errors;
}

class ScopeAndMatch extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            warningRecords: [],
            errorRecords: [],
            warningDiv: true,
            errorDiv: true,
            supplierScopeDataCurrent: {},
            directSpendValues: [{ label: '', value: '' }, { label: 'No', value: 'No' }, { label: 'Yes', value: 'Yes' }],
            supplierScopeData: {
                ANID_OF_MATCHED_SUPPLIER: '',
                AN_MATCH_CRITERIA: "",
                ARIBA_NETWORK_MATCH: "",
                BUYING_INVOICING_ELIGIBLE: "",
                CATALOG_PROVIDER: "",
                COMMERCE_AUTOMATION_ELIGIBLE: "",
                CONTRACT_INVOICING_ELIGIBLE: "",
                DATA_COLLECTIONS_SKEY: '',
                DIRECT_SPEND: "",
                ENTERPRISE_ACCOUNT_REASON: "",
                EXCLUSION_REASON: "",
                FG_EXTERNAL_WORKFORCE: "",
                FG_SERVICES: '',
                PAYABLES_ELIGIBLE: "",
                REQUISITIONABLE: "",
                REQUISITION_TYPE: "",
                SAP_ARIBA_BUY_PROCESS: "",
                SAP_CONCUR_ELIGIBLE: "",
                SPOT_BUY_ELIGIBLE: "",
                SAP_FIELDGLASS_ELIGIBLE: "",
                SCOPE_SOURCE: "",
                SOURCING_ELIGIBLE: "",
                STANDARD_ACCOUNT_ELIGIBLE: "",
                STANDARD_OR_ENTERPRISE_ACCOUNT: "",
                SUPPLIER_NAME_ON_AN: '',
                SUPP_CHAIN_COLLAB_ELIGIBLE: "",
                TRANSACTED_ON_AN_IN_LAST_12_MTH: '',
                VENDOR_NAME: "",
                characterCount: 150,
                isUserCommentTextboxClicked: 'No',
                warningMessage: '',
                remaingHistoricalCommentsLength: 150,
                historicalSupplierComments: '',
                isSaveInProgress: false
            }
        }

        this.handleScopeSourceChange = this.handleScopeSourceChange.bind(this)
        this.isDisabled = this.isDisabled.bind(this)
        this.onSave = this.onSave.bind(this)
        this.onUpdate = this.onUpdate.bind(this)
        this.validateForWarning = this.validateForWarning.bind(this)
        this.validateForError = this.validateForError.bind(this)
        this.warningToggle = this.warningToggle.bind(this)
        this.errorToggle = this.errorToggle.bind(this)
        this.addWarningErroredRecord = this.addWarningErroredRecord.bind(this)
        this.getValidationDiv = this.getValidationDiv.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.hasRecordErrors = this.hasRecordErrors.bind(this)
        this.hasPendingChange = this.hasPendingChange.bind(this)
        this.onChange = this.onChange.bind(this)
        this.onBlur = this.onBlur.bind(this)
        this.onFocus = this.onFocus.bind(this)
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        this.props.setPopUpTabLoadingStatus(true);
        this.setState({ isLoading: true, isError: 'No', responseMsg: '' })
        this.props.getSupplierDrillScopeData(this.props.spendDetails.currentActiveRequest.DMC_ID, this.props.spendDetails.currentActiveRequest.VS_ID, this.props.user.user.Email, this.props.spendDetails.supplierDetails["SKEY"])
            .then((response) => {
                if (response.status === 200) {
                    this.props.setPopUpTabLoadingStatus(false)
                    var scopData = response.data.Result['SP_UI_SUPPLIER_DRILL_SCOPE_DETAILS']
                    var commentData = response.data.Result['SP_UI_SUPPLIER_COMMENT']
                    this.props.getSupplierDrillScopeDataSuccess(scopData)
                    const supplierData = scopData[0];
                    const directSpendValues = this.state.directSpendValues;
                    const rowIdx = directSpendValues.findIndex(item => {
                        return item.label === supplierData.DIRECT_SPEND
                    })
                    if (rowIdx === -1) {
                        directSpendValues.splice(0, 0, { label: supplierData.DIRECT_SPEND, value: supplierData.DIRECT_SPEND });
                    }

                    if (commentData.length > 0) {
                        var historicalCommentsLength = commentData[0]['USER_COMMENT'].length
                        var totalHistoricalComments = []
                        totalHistoricalComments = commentData[0]['USER_COMMENT'].split('\r\n')
                        //Prefix Text for each new comment is ----->  mm/dd/yyyy - email: @@@@ your comment @@@@
                        var prefixTextlength = 15 + this.props.user.user.Email.length + totalHistoricalComments.length
                        var historicalCommentsLengthWithPrefix = historicalCommentsLength + prefixTextlength
                        if (2000 - historicalCommentsLengthWithPrefix > 0) {
                            this.setState({
                                isLoading: false, isError: 'No', responseMsg: '', supplierScopeData: supplierData,
                                directSpendValues: directSpendValues, supplierScopeDataCurrent: cloneDeep(supplierData),
                                historicalSupplierComments: commentData[0]['USER_COMMENT'], remaingHistoricalCommentsLength: 2000 - historicalCommentsLengthWithPrefix
                            })
                        }
                        else {
                            this.setState({
                                isLoading: false, isError: 'No', responseMsg: '', supplierScopeData: supplierData,
                                directSpendValues: directSpendValues, supplierScopeDataCurrent: cloneDeep(supplierData),
                                historicalSupplierComments: commentData[0]['USER_COMMENT'], remaingHistoricalCommentsLength: 0
                            })
                        }
                    }
                    else {
                        this.setState({
                            isLoading: false, isError: 'No', responseMsg: '', supplierScopeData: supplierData,
                            directSpendValues: directSpendValues, supplierScopeDataCurrent: cloneDeep(supplierData)
                        })
                    }
                }
                else {
                    this.props.setPopUpTabLoadingStatus(false)
                }
            }).catch((error) => {
                this.props.setPopUpTabLoadingStatus(false)
                this.setState({ responseMsg: this.props.content.error, isError: 'Yes', isLoading: false })
            })
    }

    onChange(e) {
        if (document.getElementById("userComments") !== null && document.getElementById("userComments").value.length === 0 &&
            !this.hasPendingChange()) {
            this.props.trackSupplierDrillDownChanges(false)
        }
        else if (document.getElementById("userComments") !== null && document.getElementById("userComments").value.length === 1) {
            this.props.trackSupplierDrillDownChanges(true)
        }

        if (this.state.remaingHistoricalCommentsLength < 150) {
            if (this.state.remaingHistoricalCommentsLength > 0)
                document.getElementById("userComments").maxLength = this.state.remaingHistoricalCommentsLength
            else
                document.getElementById("userComments").maxLength = 0
            this.setState({
                warningMessage: this.props.supplierComments.historicalCharactersLeft,
                characterCount: this.state.remaingHistoricalCommentsLength - e.currentTarget.value.length, isUserCommentTextboxClicked: 'Yes'
            })
        }
        else {
            this.setState({ warningMessage: this.props.supplierComments.charactersLeft, characterCount: 150 - e.currentTarget.value.length, isUserCommentTextboxClicked: 'Yes' })
        }
    }

    onBlur(e) {
        this.setState({ isUserCommentTextboxClicked: 'No' })
    }

    onFocus(e) {
        if (this.state.remaingHistoricalCommentsLength < 150) {
            document.getElementById("userComments").maxLength = this.state.remaingHistoricalCommentsLength
            this.setState({
                warningMessage: this.props.supplierComments.historicalCharactersLeft,
                characterCount: this.state.remaingHistoricalCommentsLength - e.currentTarget.value.length, isUserCommentTextboxClicked: 'Yes'
            })
        }
        else {
            this.setState({ warningMessage: this.props.supplierComments.charactersLeft, characterCount: 150 - e.currentTarget.value.length, isUserCommentTextboxClicked: 'Yes' })
        }
    }

    isDisabled(field, values) {
        if (!this.props.userData.AllowEdit) {
            return true;
        }

        const scopeSource = values.SCOPE_SOURCE;

        if (scopeSource === 'Override' && field === 'EXCLUSION_REASON')
            return false

        if (scopeSource === 'Override' && getNonEmptyValue(values.EXCLUSION_REASON) === '')
            return false

        return true;
    }

    handleChange(value, field, props) {
        //props.setFieldValue(field, value)
        this.props.trackSupplierDrillDownChanges(true)
        const supplierScopeData = this.state.supplierScopeData
        supplierScopeData[field] = value;
        props.values[field] = value;
        const validationRecords = this.onUpdate(props.values)
        this.setState({
            supplierScopeData: supplierScopeData, warningRecords: validationRecords.warningRecords,
            errorRecords: validationRecords.errorRecords
        })
    }

    onUpdate(values) {
        var warningRecords = this.state.warningRecords;
        var errorRecords = this.state.errorRecords;
        this.validateForWarning(warningRecords, values);
        this.validateForError(errorRecords, values);
        return { warningRecords: warningRecords, errorRecords: errorRecords }
    }

    validateForWarning(warningRecords, values) {
        const warningMessages = this.props.contentDetailedFile.validationMessage.warningMessage;
        const exclusionReasonWarning = warningMessages.exclusionReasonWarning;
        const buyingInvoicingFieldGlassEligibleWarning = warningMessages.buyingInvoicingFieldGlassEligibleWarning
        const buyingInvoicingSupplyChainEligibleWarning = warningMessages.buyingInvoicingSupplyChainEligibleWarning
        const buyingInvoicingConcurEligibleWarning = warningMessages.buyingInvoicingConcurEligibleWarning
        const fieldGlassSCCEligibleWarning = warningMessages.fieldGlassSCCEligibleWarning

        var exclusionReason = values.EXCLUSION_REASON
        if (getNonEmptyValue(exclusionReason) !== '') {
            this.addWarningErroredRecord(exclusionReasonWarning, warningRecords, true)
        } else {
            this.addWarningErroredRecord(exclusionReasonWarning, warningRecords, false)
        }

        var buyingInvoicing = values.BUYING_INVOICING_ELIGIBLE;
        var fieldGlassEligible = values.fieldGlassEligible
        if (getNonEmptyValue(buyingInvoicing) === 'Yes' && getNonEmptyValue(fieldGlassEligible) === 'Yes') {
            this.addWarningErroredRecord(buyingInvoicingFieldGlassEligibleWarning, warningRecords, true)
        } else {
            this.addWarningErroredRecord(buyingInvoicingFieldGlassEligibleWarning, warningRecords, false)
        }

        var supplyChainEligible = values.SUPP_CHAIN_COLLAB_ELIGIBLE
        if (getNonEmptyValue(buyingInvoicing) === 'Yes' && getNonEmptyValue(supplyChainEligible) === 'Yes') {
            this.addWarningErroredRecord(buyingInvoicingSupplyChainEligibleWarning, warningRecords, true)
        } else {
            this.addWarningErroredRecord(buyingInvoicingSupplyChainEligibleWarning, warningRecords, false)
        }

        var concurEligible = values.SAP_CONCUR_ELIGIBLE
        if (getNonEmptyValue(buyingInvoicing) === 'Yes' && getNonEmptyValue(concurEligible) === 'Yes') {
            this.addWarningErroredRecord(buyingInvoicingConcurEligibleWarning, warningRecords, true)
        } else {
            this.addWarningErroredRecord(buyingInvoicingConcurEligibleWarning, warningRecords, false)
        }

        var fieldGlassEligible = values.SAP_FIELDGLASS_ELIGIBLE
        if (getNonEmptyValue(supplyChainEligible) === 'Yes' && getNonEmptyValue(fieldGlassEligible) === 'Yes') {
            this.addWarningErroredRecord(fieldGlassSCCEligibleWarning, warningRecords, true)
        } else {
            this.addWarningErroredRecord(fieldGlassSCCEligibleWarning, warningRecords, false)
        }
    }

    validateForError(errorRecords, values) {
        const errorMessages = this.props.contentDetailedFile.validationMessage.errorMessage;
        const buyingInvoicingError = errorMessages.buyingInvoicingError;
        const requisitionableTypeError1 = errorMessages.requisitionableTypeError1;
        const requisitionableTypeError2 = errorMessages.requisitionableTypeError2;
        const contractInvoicingError = errorMessages.contractInvoicingError;
        const supplyCommerceError = errorMessages.supplyCommerceError;

        var buyingInvoicing = values.BUYING_INVOICING_ELIGIBLE;
        var requisitionable = values.REQUISITIONABLE;
        var contractInvoicing = values.CONTRACT_INVOICING_ELIGIBLE;

        if ((getNonEmptyValue(buyingInvoicing) === 'Yes' && getNonEmptyValue(requisitionable) === 'Yes' && getNonEmptyValue(contractInvoicing) === 'Yes') ||
            (getNonEmptyValue(buyingInvoicing) === 'Yes' && getNonEmptyValue(requisitionable) === 'No' && getNonEmptyValue(contractInvoicing) === 'No')) {
            this.addWarningErroredRecord(buyingInvoicingError, errorRecords, true)
        } else {
            this.addWarningErroredRecord(buyingInvoicingError, errorRecords, false)
        }

        var requisitionableType = values.REQUISITION_TYPE;
        if (getNonEmptyValue(requisitionable) === 'Yes' && getNonEmptyValue(requisitionableType) === 'N/A') {
            this.addWarningErroredRecord(requisitionableTypeError1, errorRecords, true)
        } else {
            this.addWarningErroredRecord(requisitionableTypeError1, errorRecords, false)
        }

        if (getNonEmptyValue(requisitionable) === 'No' && getNonEmptyValue(requisitionableType) !== 'N/A') {
            this.addWarningErroredRecord(requisitionableTypeError2, errorRecords, true)
        } else {
            this.addWarningErroredRecord(requisitionableTypeError2, errorRecords, false)
        }

        if (getNonEmptyValue(contractInvoicing) === 'Yes' && getNonEmptyValue(buyingInvoicing) === 'No') {
            this.addWarningErroredRecord(contractInvoicingError, errorRecords, true)
        } else {
            this.addWarningErroredRecord(contractInvoicingError, errorRecords, false)
        }

        var supplyChain = values.SUPP_CHAIN_COLLAB_ELIGIBLE;
        var commerceAutomation = values.COMMERCE_AUTOMATION_ELIGIBLE;

        if (getNonEmptyValue(supplyChain) === 'Yes' && getNonEmptyValue(commerceAutomation) === 'Yes') {
            this.addWarningErroredRecord(supplyCommerceError, errorRecords, true)
        } else {
            this.addWarningErroredRecord(supplyCommerceError, errorRecords, false)
        }
    }

    addWarningErroredRecord(reason, records, add) {
        var arr = records[reason];
        if (getNonEmptyValue(arr) === '') {
            arr = []
        }

        var eltIdx = arr.indexOf((1));
        if (eltIdx === -1 && add) {
            arr.push(1)
        } else if (eltIdx !== -1 && !add) {
            arr.splice(eltIdx, 1)
        }

        records[reason] = arr;
    }

    onSave(supplierScopeDataNew) {
        this.props.defineScopeLoading(true)
        let supplierScopeDataOld = this.state.supplierScopeDataCurrent
        this.setState({ isLoading: true, isError: 'No', isSaveInProgress: true, responseMsg: '' })
        let industryId = this.props.spendDetails.vendorDetails.overrideIndustryID ? this.props.spendDetails.vendorDetails.overrideIndustryID : null;
        const record = {
            VS_ID: this.props.spendDetails.currentActiveRequest.VS_ID,
            DMC_ID: this.props.spendDetails.currentActiveRequest.DMC_ID,
            DATA_COLLECTIONS_SKEY: supplierScopeDataNew['DATA_COLLECTIONS_SKEY'],
            USER_ID: this.props.user.user.Email,
            FLAG_SOLUTION: supplierScopeDataNew["SCOPE_SOURCE"] === "Override" ? "Yes" : "No",
            FLAG_INDUSTRY: supplierScopeDataNew["SCOPE_SOURCE"] === "Industry" ? "Yes" : "No",
            FLAG_INP: this.markFlagINP(supplierScopeDataOld, supplierScopeDataNew) ? "Yes" : "No",
            FLAG_CONFIRMED: "No",
            SCOPE_SOURCE: supplierScopeDataNew["SCOPE_SOURCE"],
            OVERRIDE_GICS: industryId,
            CONFIRMED: null,
            EXCLUSION_REASON: supplierScopeDataNew["EXCLUSION_REASON"],
            GOODS_OR_SERVICE: supplierScopeDataNew['GOODS_OR_SERVICE'],
            DIRECT_SPEND: supplierScopeDataNew["DIRECT_SPEND"],
            SAP_ARIBA_BUY_PROCESS: supplierScopeDataNew["SAP_ARIBA_BUY_PROCESS"],
            SOURCING_ELIGIBLE: supplierScopeDataNew["SOURCING_ELIGIBLE"],
            BUYING_INV_ELIGIBLE: supplierScopeDataNew["BUYING_INVOICING_ELIGIBLE"],
            REQUISITION_ABLE: supplierScopeDataNew["REQUISITIONABLE"],
            REQUISITION_TYPE: supplierScopeDataNew["REQUISITION_TYPE"],
            CONTRACT_INV_ELIGIBLE: supplierScopeDataNew["CONTRACT_INVOICING_ELIGIBLE"],
            COMMERCE_AUTOMATION_ELIGIBLE: supplierScopeDataNew["COMMERCE_AUTOMATION_ELIGIBLE"],
            SUPP_CHAIN_COLLAB_ELIGIBLE: supplierScopeDataNew["SUPP_CHAIN_COLLAB_ELIGIBLE"],
            PAYABLES_ELIGIBLE: supplierScopeDataNew["PAYABLES_ELIGIBLE"],
            SAP_FIELDGLASS_ELIGIBLE: supplierScopeDataNew["SAP_FIELDGLASS_ELIGIBLE"],
            SAP_CONCUR_ELIGIBLE: supplierScopeDataNew["SAP_CONCUR_ELIGIBLE"],
            SPOT_BUY_ELIGIBLE: supplierScopeDataNew["SPOT_BUY_ELIGIBLE"],
            USER_COMMENT: document.getElementById("userComments") != null && document.getElementById("userComments") !== undefined ? document.getElementById("userComments").value : '',
            REQUEST_NAME: "ScopeAndMatch_Tab"
        };
        this.props.setPopUpTabLoadingStatus(true)
        this.props.supplierDrillDownUpdate(record)
            .then((response) => {
                if (response.status === 200) {
                    this.props.setPopUpTabLoadingStatus(false)
                    if (response.data.Result['SP_UI_SUPPLIER_DRILL_SCOPE_DETAILS'].length === 0) {
                        this.props.defineScopeLoading(false)

                    } else {
                        this.props.defineScopeLoading(false)
                        const supplierData = response.data.Result['SP_UI_SUPPLIER_DRILL_SCOPE_DETAILS'][0];
                        var commentData = response.data.Result['SP_UI_SUPPLIER_COMMENT']
                        const directSpendValues = this.state.directSpendValues;
                        const rowIdx = directSpendValues.findIndex(item => {
                            return item.label === supplierData.DIRECT_SPEND
                        })
                        if (rowIdx === -1) {
                            directSpendValues.splice(0, 0, { label: supplierData.DIRECT_SPEND, value: supplierData.DIRECT_SPEND });
                        }
                        this.props.trackSupplierDrillDownChanges(false)
                        this.props.trackSupplierDrillDownSave(true)
                        document.getElementById("userComments").value = ''

                        if (commentData.length > 0) {
                            var historicalCommentsLength = commentData[0]['USER_COMMENT'].length
                            var totalHistoricalComments = []
                            totalHistoricalComments = commentData[0]['USER_COMMENT'].split('\r\n')
                            //Prefix Text for each new comment is ----->  mm/dd/yyyy - email: @@@@ your comment @@@@
                            var prefixTextlength = 15 + this.props.user.user.Email.length + totalHistoricalComments.length
                            var historicalCommentsLengthWithPrefix = historicalCommentsLength + prefixTextlength
                            if (2000 - historicalCommentsLengthWithPrefix > 0) {
                                this.setState({
                                    historicalSupplierComments: commentData[0]['USER_COMMENT'],
                                    directSpendValues: directSpendValues, supplierScopeData: supplierData,
                                    supplierScopeDataCurrent: cloneDeep(supplierData), responseMsg: this.props.content.success,
                                    warningRecords: [], warningDiv: false, errorRecords: [], errorDiv: false,
                                    isError: 'No', isSaveInProgress: false, isLoading: false, remaingHistoricalCommentsLength: 2000 - historicalCommentsLengthWithPrefix
                                })
                            }
                            else {
                                this.setState({
                                    historicalSupplierComments: commentData[0]['USER_COMMENT'],
                                    directSpendValues: directSpendValues, supplierScopeData: supplierData,
                                    supplierScopeDataCurrent: cloneDeep(supplierData), responseMsg: this.props.content.success,
                                    warningRecords: [], warningDiv: false, errorRecords: [], errorDiv: false,
                                    isError: 'No', isSaveInProgress: false, isLoading: false, remaingHistoricalCommentsLength: 0
                                })
                            }
                        }
                        else {
                            this.setState({
                                historicalSupplierComments: '',
                                directSpendValues: directSpendValues, supplierScopeData: supplierData,
                                supplierScopeDataCurrent: cloneDeep(supplierData), responseMsg: this.props.content.success,
                                warningRecords: [], warningDiv: false, errorRecords: [], errorDiv: false,
                                isError: 'No', isSaveInProgress: false, isLoading: false, remaingHistoricalCommentsLength: 150
                            })
                        }
                    }
                }
                else {
                    this.props.setPopUpTabLoadingStatus(false)
                }
            }).catch((error) => {
                this.props.setPopUpTabLoadingStatus(false)
                if (error.stack.includes('Network Error')) {
                    this.props.defineScopeLoading(false)
                    this.setState({ responseMsg: 'API service is down. Please contact administrator.', isError: 'Yes', isSaveInProgress: false, isLoading: false })
                }
                else {
                    this.props.defineScopeLoading(false)
                    this.setState({ responseMsg: this.props.content.error, isError: 'Yes', isSaveInProgress: false, isLoading: false })
                }
            })
    }

    markFlagINP(supplierDataOld, supplierDataNew) {
        if (supplierDataOld["EXCLUSION_REASON"] !== supplierDataNew["EXCLUSION_REASON"]
            || supplierDataOld["DIRECT_SPEND"] !== supplierDataNew["DIRECT_SPEND"]) {
            return true;
        }

        return false;
    }

    handleScopeSourceChange(data, field, props) {
        this.setState({ scopeSource: data.value })
    }

    hasRecordErrors() {
        const errorRecords = this.state.errorRecords
        var errorRecordSize = 0;
        Object.values(errorRecords).forEach((item) => {
            errorRecordSize += item.length;
        })

        return errorRecordSize > 0
    }

    hasPendingChange() {
        for (const key in this.state.supplierScopeData) {
            if (this.state.supplierScopeData[key] !== this.state.supplierScopeDataCurrent[key] ||
                (document.getElementById("userComments") !== null && document.getElementById("userComments").value.length > 0)) {
                return true;
            }
        }
        return false;
    }

    getValidationDiv() {
        const warningRecords = this.state.warningRecords;
        const errorRecords = this.state.errorRecords;

        var warningRecordSize = 0;
        var totalWarningRecords = []
        Object.values(warningRecords).forEach((item) => {
            totalWarningRecords = totalWarningRecords.concat(item);
        })
        var warningDiv = Object.keys(warningRecords).map((key, index) => (
            warningRecords[key].length !== 0 ?
                <div className="medium-12 message-sub-row hand-cursor">
                    <div className="grid-x message-sub-row hand-cursor">
                        <span>
                            <img width="10" src="images/Dot.svg" />
                        </span>
                        <div className="medium-11">
                            {key}
                        </div>
                    </div>
                </div> : ''
        ))

        var errorRecordSize = 0;
        var totalErrorRecords = []
        Object.values(errorRecords).forEach((item) => {
            totalErrorRecords = totalErrorRecords.concat(item);
        })

        var errorDiv = Object.keys(errorRecords).map((key, index) => (
            errorRecords[key].length !== 0 ?
                <div className="medium-12 message-sub-row hand-cursor">
                    <div className="grid-x message-sub-row hand-cursor">
                        <span>
                            <img width="10" src="images/Dot.svg" />
                        </span>
                        <div className="medium-11">
                            {key}
                        </div>
                    </div>
                </div> : ''
        ))

        var validationDiv = (
            <div className="validation-messages medium-12">
                <div className="grid-x">
                    {totalWarningRecords.length > 0 ?
                        <div className="grid-x medium-6 message-row">
                            <div className="medium-11 hand-cursor">
                                <span className="review-warning">
                                    <img width="15" src="images/Dot.svg" />{totalWarningRecords.length} {this.props.contentDetailedFile.validationWarningText} </span>{this.props.contentDetailedFile.warningChangeMessage}
                            </div>
                            <div onClick={this.warningToggle} className="float-right plus-icon medium-1">
                                {this.state.warningDiv ? <img width="10" src="images/minus-inactive.svg" /> : <img width="10" src="images/add.svg" />}
                            </div>
                            {this.state.warningDiv ? warningDiv : ''}
                        </div> : ''}
                    {totalErrorRecords.length > 0 ?
                        <div className="grid-x medium-6 message-row">
                            <div className="medium-11 hand-cursor">
                                <span className="review-errors">
                                    <img width="15" src="images/Dot.svg" />{totalErrorRecords.length} {this.props.contentDetailedFile.validationErrorText} </span>{this.props.contentDetailedFile.validationThatText}<span className="font-bold"> {this.props.contentDetailedFile.validationMustText} </span>{this.props.contentDetailedFile.errorChangeMessage}
                            </div>
                            <div onClick={this.errorToggle} className="float-right plus-icon medium-1">
                                {this.state.errorDiv ? <img width="10" src="images/minus-inactive.svg" /> : <img width="10" src="images/add.svg" />}
                            </div>
                            {this.state.errorDiv ? errorDiv : ''}
                        </div> : ''}
                </div>
            </div>)

        return validationDiv;
    }

    warningToggle() {
        this.setState((previousState) => { return { warningDiv: !previousState.warningDiv } })
    }

    errorToggle() {
        this.setState((previousState) => { return { errorDiv: !previousState.errorDiv } })
    }

    render() {
        const validationDiv = this.getValidationDiv();

        return (
            <div>
                <Formik
                    enableReinitialize={true}
                    initialValues={this.state.supplierScopeData}
                    onSubmit={(values, actions) => {
                        this.onSave(values)
                        actions.setSubmitting(false);
                    }}
                    render={props => (
                        <div className="grid-container font12">
                            <form onSubmit={props.handleSubmit}>
                                <div className="grid-x tabs_container">
                                    <div className="medium-12">
                                        <div className="grid-x grid-padding-x">
                                            <div className="medium-12">
                                                {(this.state.isLoading) ?
                                                    <img style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={path + `images/ajax-loader.gif`} alt="" />
                                                    : null
                                                }
                                                {(this.state.isError === 'Yes') ?
                                                    <div style={{ color: 'red', textAlign: 'center' }} className="cell">{this.state.responseMsg}</div>
                                                    : <div style={{ color: 'green', textAlign: 'center' }} className="cell">{this.state.responseMsg}</div>
                                                }
                                            </div>
                                        </div>
                                        <div className="grid-x">
                                            <div className="medium-11">
                                                {validationDiv}
                                            </div>
                                            <div className="medium-1">
                                                <button className={classNames('float-right', 'pr35',
                                                    {
                                                        'hand-cursor': this.state.isSaveInProgress ? false : !(this.hasRecordErrors() || !this.hasPendingChange()),
                                                        'gray-scale': this.state.isSaveInProgress ? true : (this.hasRecordErrors() || !this.hasPendingChange())
                                                    })}
                                                    type="submit" disabled={this.state.isSaveInProgress ? true : this.hasRecordErrors() || !this.hasPendingChange()} title={this.props.ui.save} float >
                                                    <img width="20" src="images/save_active.svg" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="medium-4 pr10">
                                        {
                                            isColumnMapped(this.props.spendDetails.mappedColumn, "SCOPE_SOURCE") &&
                                            <div className="grid-x">
                                                <div className="medium-6 font-bold grey_background min-control-height vertical-align-center">{this.props.content.scopeSource}</div>

                                                <div className="medium-6 paddingAll5">
                                                    <SelectE
                                                        className="border-with-DD dropDownContent"
                                                        name='SCOPE_SOURCE'
                                                        value={scopeValues.filter(
                                                            option => option.label == props.values.SCOPE_SOURCE
                                                        )}
                                                        onChange={(value) => this.handleChange(value.value, 'SCOPE_SOURCE', props)}
                                                        onBlur={(value) => props.setFieldTouched('SCOPE_SOURCE', true)}
                                                        options={scopeValues}
                                                        isSearchable={false}
                                                        noOptionsMessage={() => null}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        {
                                            isColumnMapped(this.props.spendDetails.mappedColumn, "EXCLUSION_REASON") &&
                                            <div className="grid-x">
                                                <div className="medium-6 font-bold grey_background min-control-height vertical-align-center">{this.props.content.exclusionReason}</div>

                                                <div className="medium-6 min-control-height vertical-align-center">
                                                    <ControlTextNativeE
                                                        name="EXCLUSION_REASON"
                                                        className="border-with-DD"
                                                        onChange={(event) => this.handleChange(event.target.value, 'EXCLUSION_REASON', props)}
                                                        onBlur={props.handleBlur}
                                                        value={props.values.EXCLUSION_REASON}
                                                        disabled={this.isDisabled('EXCLUSION_REASON', props.values)}
                                                        style={{ height: '38px', width: '100%' }}
                                                    />
                                                </div>
                                            </div>
                                        }

                                        {
                                            isColumnMapped(this.props.spendDetails.mappedColumn, "DIRECT_SPEND") &&
                                            <div className="grid-x">
                                                <div className="medium-6 font-bold grey_background min-control-height vertical-align-center">{this.props.content.directSpend}</div>
                                                <div className="medium-6 paddingAll5">
                                                    <SelectE
                                                        className="border-with-DD dropDownContent"
                                                        name='DIRECT_SPEND'
                                                        classNamePrefix="scope-match"
                                                        value={this.state.directSpendValues.filter(
                                                            option => option.label == props.values.DIRECT_SPEND
                                                        )}
                                                        onChange={(value) => this.handleChange(value.value, 'DIRECT_SPEND', props)}
                                                        onBlur={(value) => props.setFieldTouched('DIRECT_SPEND', true)}
                                                        options={this.state.directSpendValues}
                                                        isSearchable={false}
                                                        noOptionsMessage={() => null}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        {
                                            isColumnMapped(this.props.spendDetails.mappedColumn, "STANDARD_ACCOUNT_ELIGIBLE") &&
                                            <div className="grid-x">
                                                <div className="medium-6 font-bold grey_background min-control-height vertical-align-center">{this.props.content.standardAccountEligible}</div>
                                                <div className="medium-6 min-control-height vertical-align-center">{this.state.supplierScopeData.STANDARD_ACCOUNT_ELIGIBLE}</div>
                                            </div>
                                        }
                                        {
                                            isColumnMapped(this.props.spendDetails.mappedColumn, "ENTERPRISE_ACCOUNT_REASON") &&
                                            <div className="grid-x">
                                                <div className="medium-6 font-bold grey_background min-control-height vertical-align-center">{this.props.content.enterpriseAccountReason}</div>
                                                <div className="medium-6 min-control-height vertical-align-center">{this.state.supplierScopeData.ENTERPRISE_ACCOUNT_REASON}</div>
                                            </div>
                                        }
                                        <div style={{ paddingRight: '5px' }} className="medium-12 rowMargin">
                                            <div>
                                                <div className="float-left fieldTitleGeneralContent"> {this.props.supplierComments.userComment}</div>
                                                {this.state.isUserCommentTextboxClicked === 'Yes' ? <div style={{ fontSize: '0.875rem' }} className="float-right colorMessage"> {this.state.warningMessage + this.state.characterCount}</div>
                                                    : null}
                                            </div>
                                            <div className="control-wrapper fieldValue">
                                                <TextAreaE
                                                    rows="6"
                                                    cols="30"
                                                    type="text" style={{ width: '100%' }}
                                                    placeholder={this.props.supplierComments.waterText}
                                                    defaultValue={this.props.userCommentInsideCell}
                                                    maxLength="150"
                                                    onChange={e => this.onChange(e)}
                                                    onFocus={e => this.onFocus(e)}
                                                    onBlur={(e) => this.onBlur(e)}
                                                    id="userComments"
                                                />
                                            </div>
                                        </div>
                                        <div style={{ paddingRight: '5px' }} className="medium-12">
                                            <div className="fieldTitleGeneralContent"> {this.props.supplierComments.historicalComment}</div>
                                            <div className="control-wrapper fieldValue">
                                                <textarea
                                                    rows="7"
                                                    cols="30"
                                                    type="text" style={{ width: '100%' }}
                                                    disabled={true}
                                                    //defaultValue={this.state.historicalSupplierComments}
                                                    value={this.state.historicalSupplierComments}
                                                    id='historicalComments'
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/* </div> */}
                                    <div className="medium-4 pr10">
                                        <div className="grid-x">
                                            {
                                                isColumnMapped(this.props.spendDetails.mappedColumn, "SAP_ARIBA_BUY_PROCESS") &&
                                                <div className="medium-6 font-bold grey_background min-control-height vertical-align-center">{this.props.content.sapAribaBuyProcess}</div>
                                            }
                                            {
                                                isColumnMapped(this.props.spendDetails.mappedColumn, "SAP_ARIBA_BUY_PROCESS") &&
                                                <div className="medium-6 paddingAll5">
                                                    <SelectE
                                                        className="border-with-DD dropDownContent"
                                                        name='SAP_ARIBA_BUY_PROCESS'
                                                        value={aribaBuyProcessValues.filter(
                                                            option => option.label == props.values.SAP_ARIBA_BUY_PROCESS
                                                        )}
                                                        onChange={(value) => this.handleChange(value.value, 'SAP_ARIBA_BUY_PROCESS', props)}
                                                        onBlur={(value) => props.setFieldTouched('SAP_ARIBA_BUY_PROCESS', true)}
                                                        options={aribaBuyProcessValues}
                                                        isDisabled={this.isDisabled('SAP_ARIBA_BUY_PROCESS', props.values)}
                                                        isSearchable={false}
                                                        noOptionsMessage={() => null}
                                                    />
                                                </div>
                                            }
                                            {
                                                isColumnMapped(this.props.spendDetails.mappedColumn, "SOURCING_ELIGIBLE") &&
                                                <div className="medium-6 font-bold grey_background min-control-height vertical-align-center">{this.props.content.sourcingEligible}</div>
                                            }
                                            {
                                                isColumnMapped(this.props.spendDetails.mappedColumn, "SOURCING_ELIGIBLE") &&
                                                <div className="medium-6 paddingAll5">
                                                    <SelectE
                                                        className="border-with-DD dropDownContent"
                                                        name='SOURCING_ELIGIBLE'
                                                        value={dropdownValues.filter(
                                                            option => option.label == props.values.SOURCING_ELIGIBLE
                                                        )}
                                                        onChange={(value) => this.handleChange(value.value, 'SOURCING_ELIGIBLE', props)}
                                                        onBlur={(value) => props.setFieldTouched('SOURCING_ELIGIBLE', true)}
                                                        options={dropdownValues}
                                                        isDisabled={this.isDisabled('SOURCING_ELIGIBLE', props.values)}
                                                        isSearchable={false}
                                                        noOptionsMessage={() => null}
                                                    />
                                                </div>
                                            }
                                            {
                                                isColumnMapped(this.props.spendDetails.mappedColumn, "BUYING_INVOICING_ELIGIBLE") &&
                                                <div className="medium-6 font-bold grey_background min-control-height vertical-align-center">{this.props.content.buyingAndInvoicingEligible}</div>
                                            }
                                            {
                                                isColumnMapped(this.props.spendDetails.mappedColumn, "BUYING_INVOICING_ELIGIBLE") &&
                                                <div className="medium-6 paddingAll5">
                                                    <SelectE
                                                        className="border-with-DD dropDownContent"
                                                        name='BUYING_INVOICING_ELIGIBLE'
                                                        value={dropdownValues.filter(
                                                            option => option.label == props.values.BUYING_INVOICING_ELIGIBLE
                                                        )}
                                                        onChange={(value) => this.handleChange(value.value, 'BUYING_INVOICING_ELIGIBLE', props)}
                                                        onBlur={(value) => props.setFieldTouched('BUYING_INVOICING_ELIGIBLE', true)}
                                                        options={dropdownValues}
                                                        isDisabled={this.isDisabled('BUYING_INVOICING_ELIGIBLE', props.values)}
                                                        isSearchable={false}
                                                        noOptionsMessage={() => null}
                                                    />
                                                </div>
                                            }
                                            {
                                                isColumnMapped(this.props.spendDetails.mappedColumn, "REQUISITIONABLE") &&
                                                <div className="medium-6 font-bold grey_background min-control-height vertical-align-center">{this.props.content.requisitionable}</div>
                                            }
                                            {
                                                isColumnMapped(this.props.spendDetails.mappedColumn, "REQUISITIONABLE") &&
                                                <div className="medium-6 paddingAll5">
                                                    <SelectE
                                                        className="border-with-DD dropDownContent"
                                                        name='REQUISITIONABLE'
                                                        value={dropdownValues.filter(
                                                            option => option.label == props.values.REQUISITIONABLE
                                                        )}
                                                        onChange={(value) => this.handleChange(value.value, 'REQUISITIONABLE', props)}
                                                        onBlur={(value) => props.setFieldTouched('REQUISITIONABLE', true)}
                                                        options={dropdownValues}
                                                        isDisabled={this.isDisabled('REQUISITIONABLE', props.values)}
                                                        isSearchable={false}
                                                        noOptionsMessage={() => null}
                                                    />
                                                </div>
                                            }
                                            {
                                                isColumnMapped(this.props.spendDetails.mappedColumn, "REQUISITION_TYPE") &&
                                                <div className="medium-6 font-bold grey_background min-control-height vertical-align-center">{this.props.content.requisitionType}</div>
                                            }
                                            {
                                                isColumnMapped(this.props.spendDetails.mappedColumn, "REQUISITION_TYPE") &&
                                                <div className="medium-6 paddingAll5">
                                                    <SelectE
                                                        className="border-with-DD dropDownContent"
                                                        name='REQUISITION_TYPE'
                                                        value={requisitionValues.filter(
                                                            option => option.label == props.values.REQUISITION_TYPE
                                                        )}
                                                        onChange={(value) => this.handleChange(value.value, 'REQUISITION_TYPE', props)}
                                                        onBlur={(value) => props.setFieldTouched('REQUISITION_TYPE', true)}
                                                        options={requisitionValues}
                                                        isDisabled={this.isDisabled('REQUISITION_TYPE', props.values)}
                                                        isSearchable={false}
                                                        noOptionsMessage={() => null}
                                                    />
                                                </div>
                                            }
                                            {
                                                isColumnMapped(this.props.spendDetails.mappedColumn, "CONTRACT_INVOICING_ELIGIBLE") &&
                                                <div className="medium-6 font-bold grey_background min-control-height vertical-align-center">{this.props.content.contractInvoicingEligible}</div>
                                            }
                                            {
                                                isColumnMapped(this.props.spendDetails.mappedColumn, "CONTRACT_INVOICING_ELIGIBLE") &&
                                                <div className="medium-6 paddingAll5">
                                                    <SelectE
                                                        className="border-with-DD dropDownContent"
                                                        name='CONTRACT_INVOICING_ELIGIBLE'
                                                        value={dropdownValues.filter(
                                                            option => option.label == props.values.CONTRACT_INVOICING_ELIGIBLE
                                                        )}
                                                        onChange={(value) => this.handleChange(value.value, 'CONTRACT_INVOICING_ELIGIBLE', props)}
                                                        onBlur={(value) => props.setFieldTouched('CONTRACT_INVOICING_ELIGIBLE', true)}
                                                        options={dropdownValues}
                                                        isDisabled={this.isDisabled('CONTRACT_INVOICING_ELIGIBLE', props.values)}
                                                        isSearchable={false}
                                                        noOptionsMessage={() => null}
                                                    />
                                                </div>
                                            }
                                            {
                                                isColumnMapped(this.props.spendDetails.mappedColumn, "COMMERCE_AUTOMATION_ELIGIBLE") &&
                                                <div className="medium-6 font-bold grey_background min-control-height vertical-align-center">{this.props.content.commerceAutomationEligible}</div>
                                            }
                                            {
                                                isColumnMapped(this.props.spendDetails.mappedColumn, "COMMERCE_AUTOMATION_ELIGIBLE") &&
                                                <div className="medium-6 paddingAll5">
                                                    <SelectE
                                                        className="border-with-DD dropDownContent"
                                                        name='COMMERCE_AUTOMATION_ELIGIBLE'
                                                        value={dropdownValues.filter(
                                                            option => option.label == props.values.COMMERCE_AUTOMATION_ELIGIBLE
                                                        )}
                                                        onChange={(value) => this.handleChange(value.value, 'COMMERCE_AUTOMATION_ELIGIBLE', props)}
                                                        onBlur={(value) => props.setFieldTouched('COMMERCE_AUTOMATION_ELIGIBLE', true)}
                                                        options={dropdownValues}
                                                        isDisabled={this.isDisabled('COMMERCE_AUTOMATION_ELIGIBLE', props.values)}
                                                        isSearchable={false}
                                                        noOptionsMessage={() => null}
                                                    />
                                                </div>
                                            }
                                            {
                                                isColumnMapped(this.props.spendDetails.mappedColumn, "SUPP_CHAIN_COLLAB_ELIGIBLE") &&
                                                <div className="medium-6 font-bold grey_background min-control-height vertical-align-center">{this.props.content.supplyChainCollabEligible}</div>
                                            }
                                            {
                                                isColumnMapped(this.props.spendDetails.mappedColumn, "SUPP_CHAIN_COLLAB_ELIGIBLE") &&
                                                <div className="medium-6 paddingAll5">
                                                    <SelectE
                                                        className="border-with-DD dropDownContent"
                                                        name='SUPP_CHAIN_COLLAB_ELIGIBLE'
                                                        value={dropdownValues.filter(
                                                            option => option.label == props.values.SUPP_CHAIN_COLLAB_ELIGIBLE
                                                        )}
                                                        onChange={(value) => this.handleChange(value.value, 'SUPP_CHAIN_COLLAB_ELIGIBLE', props)}
                                                        onBlur={(value) => props.setFieldTouched('SUPP_CHAIN_COLLAB_ELIGIBLE', true)}
                                                        options={dropdownValues}
                                                        isDisabled={this.isDisabled('SUPP_CHAIN_COLLAB_ELIGIBLE', props.values)}
                                                        isSearchable={false}
                                                        noOptionsMessage={() => null}
                                                    />
                                                </div>
                                            }
                                            {
                                                isColumnMapped(this.props.spendDetails.mappedColumn, "PAYABLES_ELIGIBLE") &&
                                                <div className="medium-6 font-bold grey_background min-control-height vertical-align-center">{this.props.content.payablesEligible}</div>
                                            }
                                            {
                                                isColumnMapped(this.props.spendDetails.mappedColumn, "PAYABLES_ELIGIBLE") &&
                                                <div className="medium-6 paddingAll5">
                                                    <SelectE
                                                        className="border-with-DD dropDownContent"
                                                        name='PAYABLES_ELIGIBLE'
                                                        value={dropdownValues.filter(
                                                            option => option.label == props.values.PAYABLES_ELIGIBLE
                                                        )}
                                                        onChange={(value) => this.handleChange(value.value, 'PAYABLES_ELIGIBLE', props)}
                                                        onBlur={(value) => props.setFieldTouched('PAYABLES_ELIGIBLE', true)}
                                                        options={dropdownValues}
                                                        isDisabled={this.isDisabled('PAYABLES_ELIGIBLE', props.values)}
                                                        isSearchable={false}
                                                        noOptionsMessage={() => null}
                                                    />
                                                </div>
                                            }
                                            {
                                                isColumnMapped(this.props.spendDetails.mappedColumn, "SAP_FIELDGLASS_ELIGIBLE") &&
                                                <div className="medium-6 font-bold grey_background min-control-height vertical-align-center">{this.props.content.sapFieldglassEligible}</div>
                                            }
                                            {
                                                isColumnMapped(this.props.spendDetails.mappedColumn, "SAP_FIELDGLASS_ELIGIBLE") &&
                                                <div className="medium-6 paddingAll5">
                                                    <SelectE
                                                        className="border-with-DD dropDownContent"
                                                        name='SAP_FIELDGLASS_ELIGIBLE'
                                                        value={dropdownValues.filter(
                                                            option => option.label == props.values.SAP_FIELDGLASS_ELIGIBLE
                                                        )}
                                                        onChange={(value) => this.handleChange(value.value, 'SAP_FIELDGLASS_ELIGIBLE', props)}
                                                        onBlur={(value) => props.setFieldTouched('SAP_FIELDGLASS_ELIGIBLE', true)}
                                                        options={dropdownValues}
                                                        isDisabled={this.isDisabled('SAP_FIELDGLASS_ELIGIBLE', props.values)}
                                                        isSearchable={false}
                                                        noOptionsMessage={() => null}
                                                    />
                                                </div>
                                            }
                                            {
                                                isColumnMapped(this.props.spendDetails.mappedColumn, "SAP_CONCUR_ELIGIBLE") &&
                                                <div className="medium-6 font-bold grey_background min-control-height min-control-height vertical-align-center">{this.props.content.sapConcurEligible}</div>
                                            }
                                            {
                                                isColumnMapped(this.props.spendDetails.mappedColumn, "SAP_CONCUR_ELIGIBLE") &&
                                                <div className="medium-6 paddingAll5">
                                                    <SelectE
                                                        className="border-with-DD dropDownContent"
                                                        name='SAP_CONCUR_ELIGIBLE'
                                                        value={dropdownValues.filter(
                                                            option => option.label == props.values.SAP_CONCUR_ELIGIBLE
                                                        )}
                                                        onChange={(value) => this.handleChange(value.value, 'SAP_CONCUR_ELIGIBLE', props)}
                                                        onBlur={(value) => props.setFieldTouched('SAP_CONCUR_ELIGIBLE', true)}
                                                        options={dropdownValues}
                                                        isDisabled={this.isDisabled('SAP_CONCUR_ELIGIBLE', props.values)}
                                                        isSearchable={false}
                                                        noOptionsMessage={() => null}
                                                    />
                                                </div>
                                            }
                                            {
                                                isColumnMapped(this.props.spendDetails.mappedColumn, "SPOT_BUY_ELIGIBLE") &&
                                                <div className="medium-6 font-bold grey_background min-control-height min-control-height vertical-align-center">{this.props.content.sapSpotBuyEligible}</div>
                                            }
                                            {
                                                isColumnMapped(this.props.spendDetails.mappedColumn, "SPOT_BUY_ELIGIBLE") &&
                                                <div className="medium-6 paddingAll5">
                                                    <SelectE
                                                        className="border-with-DD dropDownContent"
                                                        name='SPOT_BUY_ELIGIBLE'
                                                        value={dropdownValues.filter(
                                                            option => option.label == props.values.SPOT_BUY_ELIGIBLE
                                                        )}
                                                        onChange={(value) => this.handleChange(value.value, 'SPOT_BUY_ELIGIBLE', props)}
                                                        onBlur={(value) => props.setFieldTouched('SPOT_BUY_ELIGIBLE', true)}
                                                        options={dropdownValues}
                                                        isDisabled={this.isDisabled('SPOT_BUY_ELIGIBLE', props.values)}
                                                        isSearchable={false}
                                                        noOptionsMessage={() => null}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="medium-4">

                                        {
                                            isColumnMapped(this.props.spendDetails.mappedColumn, "ARIBA_NETWORK_MATCH") &&
                                            <div className="grid-x">
                                                <div className="medium-6 font-bold grey_background min-control-height vertical-align-center">{this.props.content.networkMatch}</div>
                                                <div name="ARIBA_NETWORK_MATCH" className="medium-6 min-control-height vertical-align-center">
                                                    {this.state.supplierScopeData.ARIBA_NETWORK_MATCH}
                                                </div>
                                            </div>
                                        }
                                        {
                                            isColumnMapped(this.props.spendDetails.mappedColumn, "SUPPLIER_NAME_ON_AN") &&
                                            <div className="grid-x">
                                                <div className="medium-6 font-bold grey_background min-control-height vertical-align-center">{this.props.content.supplierNameOnAN}</div>
                                                <div name="SUPPLIER_NAME_ON_AN" className="medium-6 min-control-height vertical-align-center">
                                                    {this.state.supplierScopeData.SUPPLIER_NAME_ON_AN}
                                                </div>
                                            </div>
                                        }

                                        {
                                            isColumnMapped(this.props.spendDetails.mappedColumn, "AN_MATCH_CRITERIA") &&
                                            <div className="grid-x">
                                                <div className="medium-6 font-bold grey_background min-control-height vertical-align-center">{this.props.content.anMatchCriteria}</div>
                                                <div name="AN_MATCH_CRITERIA" className="medium-6 min-control-height vertical-align-center">
                                                    {this.state.supplierScopeData.AN_MATCH_CRITERIA}
                                                </div>
                                            </div>
                                        }
                                        {
                                            isColumnMapped(this.props.spendDetails.mappedColumn, "STANDARD_OR_ENTERPRISE_ACCOUNT") &&
                                            <div className="grid-x">
                                                <div className="medium-6 font-bold grey_background min-control-height vertical-align-center">{this.props.content.standardOrEnterpriseAccount}</div>
                                                <div name="STANDARD_OR_ENTERPRISE_ACCOUNT" className="medium-6 min-control-height vertical-align-center">
                                                    {this.state.supplierScopeData.STANDARD_OR_ENTERPRISE_ACCOUNT}
                                                </div>
                                            </div>
                                        }
                                        {
                                            isColumnMapped(this.props.spendDetails.mappedColumn, "ANID_OF_MATCHED_SUPPLIER") &&
                                            <div className="grid-x">
                                                <div className="medium-6 font-bold grey_background min-control-height vertical-align-center">{this.props.content.anIdMatchedSupplier}</div>
                                                <div name="ANID_OF_MATCHED_SUPPLIER" className="medium-6 min-control-height vertical-align-center">
                                                    <a href={'https://service.ariba.com/Discovery.aw/ad/profile?key=' + this.state.supplierScopeData.ANID_OF_MATCHED_SUPPLIER} target="_blank">{this.state.supplierScopeData.ANID_OF_MATCHED_SUPPLIER}</a>
                                                </div>
                                            </div>
                                        }
                                        {
                                            isColumnMapped(this.props.spendDetails.mappedColumn, "TRANSACTED_ON_AN_IN_LAST_12_MTH") &&
                                            <div className="grid-x">
                                                <div className="medium-6 font-bold grey_background min-control-height vertical-align-center">{this.props.content.transactedOnAN}</div>
                                                <div name="TRANSACTED_ON_AN_IN_LAST_12_MTH" className="medium-6 min-control-height vertical-align-center">
                                                    {this.state.supplierScopeData.TRANSACTED_ON_AN_IN_LAST_12_MTH}
                                                </div>
                                            </div>
                                        }
                                        {
                                            isColumnMapped(this.props.spendDetails.mappedColumn, "CATALOG_PROVIDER") &&
                                            <div className="grid-x">
                                                <div className="medium-6 font-bold grey_background min-control-height vertical-align-center">{this.props.content.catalogProvider}</div>
                                                <div name="CATALOG_PROVIDER" className="medium-6 min-control-height vertical-align-center">
                                                    {this.state.supplierScopeData.CATALOG_PROVIDER}
                                                </div>
                                            </div>
                                        }
                                        {
                                            isColumnMapped(this.props.spendDetails.mappedColumn, "FG_EXTERNAL_WORKFORCE") &&
                                            <div className="grid-x">
                                                <div className="medium-6 font-bold grey_background min-control-height vertical-align-center">{this.props.content.fgExternalWorkforce}</div>
                                                <div name="FG_EXTERNAL_WORKFORCE" className="medium-6 min-control-height vertical-align-center">
                                                    {this.state.supplierScopeData.FG_EXTERNAL_WORKFORCE}
                                                </div>
                                            </div>
                                        }
                                        {
                                            isColumnMapped(this.props.spendDetails.mappedColumn, "FG_SERVICES") &&
                                            <div className="grid-x">
                                                <div className="medium-6 font-bold grey_background min-control-height vertical-align-center">{this.props.content.fgServices}</div>
                                                <div name="FG_SERVICES" className="medium-6 min-control-height vertical-align-center">
                                                    {this.state.supplierScopeData.FG_SERVICES}
                                                </div>
                                            </div>
                                        }
                                        {/* </div>  */}
                                    </div>
                                </div>
                            </form>
                        </div >
                    )} />
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        ui: state.content.ui,
        content: state.content.spendAnalysis.supplierDrilldown.scopeandMatch,
        supplierComments: state.content.spendAnalysis.supplierDrilldown.supplierComments,
        contentDetailedFile: state.content.spendAnalysis.detailedFile,
        spendDetails: state.spendDetails,
        user: state.default,
        userData: state.valueStory
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getSupplierDrillScopeDataSuccess: message => dispatch(getSupplierDrillScopeDataSuccess(message)),
        getSupplierDrillScopeDataFailure: error => dispatch(getSupplierDrillScopeDataFailure(error)),
        getSupplierDrillScopeDataLoading: bool => dispatch(getSupplierDrillScopeDataLoading(bool)),
        getSupplierDrillScopeData: (DMCId, VSId, userEmail, SKEY) => dispatch(getSupplierDrillScopeData(DMCId, VSId, userEmail, SKEY)),
        defineScopeLoading: bool => dispatch(defineScopeLoading(bool)),
        supplierDrillDownUpdate: (record) => dispatch(supplierDrillDownUpdate(record)),
        trackSupplierDrillDownChanges: message => dispatch(trackSupplierDrillDownChanges(message)),
        trackSupplierDrillDownSave: message => dispatch(trackSupplierDrillDownSave(message)),
        setPopUpTabLoadingStatus: bool => dispatch(setPopUpTabLoadingStatus(bool)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScopeAndMatch); 