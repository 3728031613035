//
//
//
//
//       <- This code is no longer used ->
//
//
//
//
import React, { Component, PureComponent } from 'react'
import { connect } from 'react-redux'
import Modal from 'reboron/OutlineModal'
import TreeView from './TreeView'
import Popup from "reactjs-popup";
import ScrollArea from 'react-scrollbar'

import Button from 'UI/Button';

import {
    suggestedValueModalStyle,
    suggestedValueContentStyle,
    backdropStyle
} from 'Constants'
import {
    fetchCapIqResults,
    capIqSearchLoading,
    capIqSearchSuccess,
    capIqSearchFailure,
    searchResult,
    searchText,
    searchData,
    treeResult
}
    from 'actions/searchActions'
    import { formatThousands} from 'utils/Utils'
import { path } from 'Constants'

const hStyle = {
    padding: '15px 30px',
    fontWeight: 'bold'
};

class SearchResults extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            searchResults: props.search.searchResult, //assign the data recieved from props 
            companyDetails: [],
            companyObj: [],
            companyName: [],
            companyNameCopy: [],
            results: [],
            company: props.result,
            selected: false,
            index: -1,
            isCAPIQ: false,
            capiqData: [],
            //These flags maintain the visibility of respective fields based on lowest level Id associated
            peId_flag: false,
            bpId_flag: false,
            treeData: [],
            open: false,
            flag: '',
            cursor: true
        }
        this.CompanyClicked = this.CompanyClicked.bind(this)
        this.loadCompanyData = this.loadCompanyData.bind(this)
        this.loadSearchData = this.loadSearchData.bind(this)
        this.createSearchResultsArray = this.createSearchResultsArray.bind(this)
        this.createCompanyProfileArray = this.createCompanyProfileArray.bind(this)
        this.fetchDataForModal = this.fetchDataForModal.bind(this)
        this.radioButtonClicked = this.radioButtonClicked.bind(this)
        this.closePopUp = this.closePopUp.bind(this)
        this.calculateRevenue = this.calculateRevenue.bind(this)
        this.formatEmployee = this.formatEmployee.bind(this)
    }

    componentWillMount() {
        this.createSearchResultsArray();
    }

    closePopUp() {
        this.setState({ open: !this.state.open }, this.loadCompanyData)
    }
    //before show modal call this to create array with treeview data
    fetchDataForModal(e) {
        let globalId = e.currentTarget.attributes.value.nodeValue;
        let data = this.props.firstSearchResult;
        let results = data.filter(item => item.SAP_TOP_VIEW == globalId)
        const uniqueObj = [];
        const map = new Map();
        for (const item of results) {
            if (!map.has(item.PE_ID)) {
                map.set(item.PE_ID, true);    // set any value to Map
                uniqueObj.push({ item });
            }
        }
        uniqueObj.forEach(data => {
            data.flag = false;
        })
        this.props.treeResult(uniqueObj)
        this.setState({ treeData: uniqueObj })
    }

    showModal(action) {
        this.refs[action].show()
    }

    hideModal(action) {
        this.refs[action].hide()
    }

    //Render the component if the store value changes for searchResults
    componentWillReceiveProps(NextProps) {
        var emptyArr = [];
        if (NextProps.search.searchResult != this.state.searchResults) {
            this.setState({ searchResults: NextProps.search.searchResult, companyName: emptyArr, companyNameCopy: emptyArr }, this.createSearchResultsArray)
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.content != this.props.content){
            this.loadCompanyData()
        }
    }
    //This creates the array of objects for search results in specific format
    createSearchResultsArray() {
        //create an array with company names coming from company source
        var companyName = [];
        this.state.searchResults.map((data, index) => {

            //check if the data is coming from CAPIQ table
            if (data.item == undefined || data.item == null) {
                companyName.push({ 'name': data.COMPANY_NAME, 'id': data.COMPANY_ID, 'flag': 'CAPIQ', 'data': data })
                this.setState({ companyName: companyName, companyNameCopy: companyName, flag: companyName[0].flag, index: companyName[0].id, company: companyName[0].name, selected: true, isCAPIQ: true })
            } else {
                if (data.item.COMPANY_SOURCE == 'SAP_TOP_VIEW') {
                    companyName.push({ 'name': data.item.SAP_TOP_VIEW_NAME, 'id': data.item.SAP_TOP_VIEW, 'flag': 'SAP_TOP_VIEW', 'data': data.item })
                } else if (data.item.COMPANY_SOURCE == 'PE') {
                    companyName.push({ 'name': data.item.PE_NAME, 'id': data.item.SAP_TOP_VIEW, 'flag': 'PE', 'data': data.item })
                } else if (data.item.COMPANY_SOURCE == 'BP') {
                    companyName.push({ 'name': data.item.ORG_NAME, 'id': data.item.SAP_TOP_VIEW, 'flag': 'BP', 'data': data.item })
                }
                this.setState({ companyName: companyName, companyNameCopy: companyName, flag: companyName[0].flag, index: companyName[0].id, company: companyName[0].name, selected: true, isCAPIQ: false })
            }
        })

        setTimeout(() => {
			this.loadSearchData()
		}, 800)
    }

    //This creates the jsx for search results section
    loadSearchData() {
        var result = (<React.Fragment>
            {this.state.companyName.map((data, index) => (
                <li key={index} id={data.id} onClick={this.CompanyClicked} className={this.state.selected && data.id == this.state.index && data.flag == this.state.flag? 'selected' : ''}>
                    <div id={data.id} value={data.flag}>{data.name}</div>
                </li>
            ))}
        </React.Fragment>)
        this.setState({ results: result, companyNameCopy: this.state.companyName }, this.createCompanyProfileArray)
    }

    //this function takes values from CAPIQ table and returns the calculated data
    calculateRevenue(currencyCode, revenue, unit) {
        if (currencyCode == null && revenue == null && unit == null) {
            return ('-')
        }
        let value = Number((revenue * 1000000) / unit);
        let currency = this.props.content.CurrencySymbols[currencyCode]
        return (currency + '' + formatThousands(value) + ' M')
    }

    formatEmployee(emp){
        if(emp == null){
            return ('-')
        }
        return (formatThousands(emp))
    }

    //Create custom array required for company profile format
    createCompanyProfileArray() {
        let companyObj = []

        //Check if the data is coming from CAPIQ table
        if (!this.state.isCAPIQ) {
            this.setState({cursor: true})
            var crmObj = this.state.companyNameCopy.find(item => item.id == this.state.index && item.flag == this.state.flag)
            var capId = null;
            if (crmObj != null || crmObj != undefined) {
                if (crmObj.flag == 'SAP_TOP_VIEW') {
                    capId = crmObj.data.SAP_TOP_VIEW_CAPIQ_ID
                } else if (crmObj.flag == 'PE') {
                    capId = crmObj.data.PE_CAPIQ_ID
                } else if (crmObj.flag == 'BP') {
                    capId = crmObj.data.ORG_CAPIQ_ID
                }
            }

            if (capId != null) {
                this.props.fetchCapIqResults(capId)
                    .then((result) => {
                        this.props.capIqSearchSuccess(result)
                        if (result.data.Result.length != 0) {
                            let obj = result.data.Result;
                            this.setState({ capiqData: obj })

                            if (crmObj.data.COMPANY_SOURCE == 'SAP_TOP_VIEW') {
                                //CURRENCY_CODE UNIT_TYPE_VALUE
                                let revenue = this.calculateRevenue(obj[0].CURRENCY_CODE, obj[0].COMPANY_REVENUE, obj[0].UNIT_TYPE_VALUE)
                                this.setState({ bpId_flag: false, peId_flag: false })
                                companyObj.push({
                                    'name': crmObj.data.SAP_TOP_VIEW_NAME, 'location': crmObj.data.SAP_TOP_VIEW_COUNTRY,
                                    'sapIndustry': obj[0].AVATAR_INDUSTRY, 'revenue': revenue, 'currency': obj[0].CURRENCY_CODE,
                                    'capId': crmObj.data.SAP_TOP_VIEW_CAPIQ_ID, 'capIdName': obj[0].COMPANY_NAME, 'globalId': crmObj.data.SAP_TOP_VIEW,
                                    'globalIdName': crmObj.data.SAP_TOP_VIEW_NAME, 'peId': '-', 'peIdName': '-',
                                    'bpId': '-', 'bpIdName': '-', 'country': obj[0].COUNTRY_NAME, 'website': obj[0].COMPANY_WEBSITE,
                                    'industry_sector': obj[0].COMPANY_INDUSTRY_DESC,
                                    'industry_group': obj[0].COMPANY_L3_INDUSTRY_DESC, 'employees': this.formatEmployee(obj[0].COMPANY_EMP_COUNT)
                                })
                            } else if (crmObj.data.COMPANY_SOURCE == 'PE' && crmObj.data.PE_CAPIQ_ID != null) {
                                let revenue = this.calculateRevenue(obj[0].CURRENCY_CODE, obj[0].COMPANY_REVENUE, obj[0].UNIT_TYPE_VALUE)
                                this.setState({ peId_flag: true, bpId_flag: false })
                                companyObj.push({
                                    'name': crmObj.data.PE_NAME, 'location': crmObj.data.PE_ID_COUNTRY,
                                    'sapIndustry': obj[0].AVATAR_INDUSTRY, 'revenue': revenue, 'currency': obj[0].CURRENCY_CODE,
                                    'capId': crmObj.data.PE_CAPIQ_ID, 'capIdName': obj[0].COMPANY_NAME, 'globalId': crmObj.data.SAP_TOP_VIEW, 'globalIdName': crmObj.data.SAP_TOP_VIEW_NAME,
                                    'peId': crmObj.data.PE_ID,
                                    'peIdName': crmObj.data.PE_NAME, 'bpId': '-', 'bpIdName': '-',
                                    'country': obj[0].COUNTRY_NAME, 'website': obj[0].COMPANY_WEBSITE,
                                    'industry_sector': obj[0].COMPANY_INDUSTRY_DESC,
                                    'industry_group': obj[0].COMPANY_L3_INDUSTRY_DESC, 'employees': this.formatEmployee(obj[0].COMPANY_EMP_COUNT)
                                })
                            }
                            else if (crmObj.data.COMPANY_SOURCE == 'PE' && crmObj.data.PE_CAPIQ_ID == null) {
                                let revenue = this.calculateRevenue(obj[0].CURRENCY_CODE, obj[0].COMPANY_REVENUE, obj[0].UNIT_TYPE_VALUE)
                                this.setState({ peId_flag: true, bpId_flag: false })
                                companyObj.push({
                                    'name': crmObj.data.PE_NAME, 'location': crmObj.data.PE_ID_COUNTRY,
                                    'sapIndustry': obj[0].AVATAR_INDUSTRY, 'revenue': revenue, 'currency': obj[0].CURRENCY_CODE,
                                    'capId': crmObj.data.SAP_TOP_VIEW_CAPIQ_ID, 'capIdName': obj[0].COMPANY_NAME, 'globalId': crmObj.data.SAP_TOP_VIEW, 'globalIdName': crmObj.data.SAP_TOP_VIEW_NAME,
                                    'peId': crmObj.data.PE_ID,
                                    'peIdName': crmObj.data.PE_NAME, 'bpId': '-', 'bpIdName': '-',
                                    'country': obj[0].COUNTRY_NAME, 'website': obj[0].COMPANY_WEBSITE,
                                    'industry_sector': obj[0].COMPANY_INDUSTRY_DESC,
                                    'industry_group': obj[0].COMPANY_L3_INDUSTRY_DESC, 'employees': this.formatEmployee(obj[0].COMPANY_EMP_COUNT)
                                })
                            } else if (crmObj.data.COMPANY_SOURCE == 'BP' && crmObj.data.ORG_CAPIQ_ID != null) {
                                let revenue = this.calculateRevenue(obj[0].CURRENCY_CODE, obj[0].COMPANY_REVENUE, obj[0].UNIT_TYPE_VALUE)
                                this.setState({ bpId_flag: true, peId_flag: true })
                                companyObj.push({
                                    'name': crmObj.data.ORG_NAME, 'location': crmObj.data.COUNTRY,
                                    'sapIndustry': obj[0].AVATAR_INDUSTRY, 'revenue': revenue, 'currency': obj[0].CURRENCY_CODE,
                                    'capId': crmObj.data.ORG_CAPIQ_ID, 'capIdName': obj[0].COMPANY_NAME, 'globalId': crmObj.data.SAP_TOP_VIEW, 'globalIdName': crmObj.data.SAP_TOP_VIEW_NAME,
                                    'peId': crmObj.data.PE_ID, 'peIdName': crmObj.data.PE_NAME,
                                    'bpId': crmObj.data.ORG_ID, 'bpIdName': crmObj.data.ORG_NAME, 'country': obj[0].COUNTRY_NAME, 'website': obj[0].COMPANY_WEBSITE,
                                    'industry_sector': obj[0].COMPANY_INDUSTRY_DESC,
                                    'industry_group': obj[0].COMPANY_L3_INDUSTRY_DESC, 'employees': this.formatEmployee(obj[0].COMPANY_EMP_COUNT)
                                })
                            } else if (crmObj.data.COMPANY_SOURCE == 'BP' && crmObj.data.ORG_CAPIQ_ID == null && crmObj.data.PE_CAPIQ_ID != null) {
                                let revenue = this.calculateRevenue(obj[0].CURRENCY_CODE, obj[0].COMPANY_REVENUE, obj[0].UNIT_TYPE_VALUE)
                                this.setState({ bpId_flag: true, peId_flag: true })
                                companyObj.push({
                                    'name': crmObj.data.ORG_NAME, 'location': crmObj.data.COUNTRY,
                                    'sapIndustry': obj[0].AVATAR_INDUSTRY, 'revenue': revenue, 'currency': obj[0].CURRENCY_CODE,
                                    'capId': crmObj.data.PE_CAPIQ_ID, 'capIdName': obj[0].COMPANY_NAME, 'globalId': crmObj.data.SAP_TOP_VIEW, 'globalIdName': crmObj.data.SAP_TOP_VIEW_NAME,
                                    'peId': crmObj.data.PE_ID, 'peIdName': crmObj.data.PE_NAME,
                                    'bpId': crmObj.data.ORG_ID, 'bpIdName': crmObj.data.ORG_NAME, 'country': obj[0].COUNTRY_NAME, 'website': obj[0].COMPANY_WEBSITE,
                                    'industry_sector': obj[0].COMPANY_INDUSTRY_DESC,
                                    'industry_group': obj[0].COMPANY_L3_INDUSTRY_DESC, 'employees': this.formatEmployee(obj[0].COMPANY_EMP_COUNT)
                                })
                            } else if (crmObj.data.COMPANY_SOURCE == 'BP' && crmObj.data.ORG_CAPIQ_ID == null && crmObj.data.PE_CAPIQ_ID == null) {
                                let revenue = this.calculateRevenue(obj[0].CURRENCY_CODE, obj[0].COMPANY_REVENUE, obj[0].UNIT_TYPE_VALUE)
                                this.setState({ bpId_flag: true, peId_flag: true })
                                companyObj.push({
                                    'name': crmObj.data.ORG_NAME, 'location': crmObj.data.COUNTRY,
                                    'sapIndustry': obj[0].AVATAR_INDUSTRY, 'revenue': revenue, 'currency': obj[0].CURRENCY_CODE,
                                    'capId': crmObj.data.SAP_TOP_VIEW_CAPIQ_ID, 'capIdName': obj[0].COMPANY_NAME, 'globalId': crmObj.data.SAP_TOP_VIEW, 'globalIdName': crmObj.data.SAP_TOP_VIEW_NAME,
                                    'peId': crmObj.data.PE_ID, 'peIdName': crmObj.data.PE_NAME,
                                    'bpId': crmObj.data.ORG_ID, 'bpIdName': crmObj.data.ORG_NAME, 'country': obj[0].COUNTRY_NAME, 'website': obj[0].COMPANY_WEBSITE,
                                    'industry_sector': obj[0].COMPANY_INDUSTRY_DESC,
                                    'industry_group': obj[0].COMPANY_L3_INDUSTRY_DESC, 'employees': this.formatEmployee(obj[0].COMPANY_EMP_COUNT)
                                })
                            }

                            this.setState({ companyObj: companyObj }, this.loadCompanyData)
                            this.props.getSelectedData(companyObj)
                        }

                        
                    }).catch((error) => {
                        //If there is no data for the selected capiq id
                        if (error.response.status == 400) {
                            if (crmObj.data.COMPANY_SOURCE == 'SAP_TOP_VIEW') {
                                this.setState({ bpId_flag: false, peId_flag: false })
                                companyObj.push({
                                    'name': crmObj.data.SAP_TOP_VIEW_NAME, 'location': crmObj.data.SAP_TOP_VIEW_COUNTRY,
                                    'sapIndustry': crmObj.data.SAP_MASTER_CODE_TEXT, 'revenue': '-',
                                    'currency': '-',
                                    'capId': crmObj.data.SAP_TOP_VIEW_CAPIQ_ID, 'capIdName': '-', 'globalId': crmObj.data.SAP_TOP_VIEW,
                                    'globalIdName': crmObj.data.SAP_TOP_VIEW_NAME, 'peId': '-', 'peIdName': '-',
                                    'bpId': '-', 'bpIdName': '-',
                                    'country': '-', 'website': '-',
                                    'industry_sector': '-',
                                    'industry_group': '-', 'employees': '-'
                                })
                            } else if (crmObj.data.COMPANY_SOURCE == 'PE' && crmObj.data.PE_CAPIQ_ID != null) {
                                this.setState({ peId_flag: true, bpId_flag: false })
                                companyObj.push({
                                    'name': crmObj.data.PE_NAME, 'location': crmObj.data.PE_ID_COUNTRY,
                                    'sapIndustry': crmObj.data.SAP_MASTER_CODE_TEXT, 'revenue': '-',
                                    'currency': '-',
                                    'capId': crmObj.data.SAP_TOP_VIEW_CAPIQ_ID, 'capIdName': '-', 'globalId': crmObj.data.SAP_TOP_VIEW,
                                    'globalIdName': crmObj.data.SAP_TOP_VIEW_NAME, 'peId': crmObj.data.PE_ID, 'peIdName': crmObj.data.PE_NAME,
                                    'bpId': '-', 'bpIdName': '-',
                                    'country': '-', 'website': '-',
                                    'industry_sector': '-',
                                    'industry_group': '-', 'employees': '-'
                                })
                            }
                            else if (crmObj.data.COMPANY_SOURCE == 'PE' && crmObj.data.PE_CAPIQ_ID == null) {
                                this.setState({ peId_flag: true, bpId_flag: false })
                                companyObj.push({
                                    'name': crmObj.data.PE_NAME, 'location': crmObj.data.PE_ID_COUNTRY,
                                    'sapIndustry': crmObj.data.SAP_MASTER_CODE_TEXT, 'revenue': '-',
                                    'currency': '-',
                                    'capId': crmObj.data.SAP_TOP_VIEW_CAPIQ_ID, 'capIdName': '-', 'globalId': crmObj.data.SAP_TOP_VIEW,
                                    'globalIdName': crmObj.data.SAP_TOP_VIEW_NAME, 'peId': crmObj.data.PE_ID, 'peIdName': crmObj.data.PE_NAME,
                                    'bpId': '-', 'bpIdName': '-',
                                    'country': '-', 'website': '-',
                                    'industry_sector': '-',
                                    'industry_group': '-', 'employees': '-'
                                })
                            } else if (crmObj.data.COMPANY_SOURCE == 'BP' && crmObj.data.ORG_CAPIQ_ID != null) {
                                this.setState({ bpId_flag: true, peId_flag: true })
                                companyObj.push({
                                    'name': crmObj.data.ORG_NAME, 'location': crmObj.data.COUNTRY,
                                    'sapIndustry': crmObj.data.SAP_MASTER_CODE_TEXT, 'revenue': '-',
                                    'currency': '-',
                                    'capId': crmObj.data.SAP_TOP_VIEW_CAPIQ_ID, 'globalId': crmObj.data.SAP_TOP_VIEW,
                                    'globalIdName': crmObj.data.SAP_TOP_VIEW_NAME, 'peId': crmObj.data.PE_ID, 'peIdName': crmObj.data.PE_NAME,
                                    'bpId': crmObj.data.ORG_ID, 'bpIdName': crmObj.data.ORG_NAME, 'country': '-', 'website': '-',
                                    'industry_sector': '-',
                                    'industry_group': '-', 'employees': '-'
                                })
                            } else if (crmObj.data.COMPANY_SOURCE == 'BP' && crmObj.data.ORG_CAPIQ_ID == null && crmObj.data.PE_CAPIQ_ID != null) {
                                this.setState({ bpId_flag: true, peId_flag: true })
                                companyObj.push({
                                    'name': crmObj.data.ORG_NAME, 'location': crmObj.data.COUNTRY,
                                    'sapIndustry': crmObj.data.SAP_MASTER_CODE_TEXT, 'revenue': '-',
                                    'currency': obj[0].CURRENCY_CODE,
                                    'capId': crmObj.data.SAP_TOP_VIEW_CAPIQ_ID, 'capIdName': '-', 'globalId': crmObj.data.SAP_TOP_VIEW,
                                    'globalIdName': crmObj.data.SAP_TOP_VIEW_NAME, 'peId': crmObj.data.PE_ID, 'peIdName': crmObj.data.PE_NAME, 'bpIdName': crmObj.data.ORG_NAME,
                                    'bpId': crmObj.data.ORG_ID, 'country': '-', 'website': '-',
                                    'industry_sector': '-',
                                    'industry_group': '-', 'employees': '-'
                                })
                            } else if (crmObj.data.COMPANY_SOURCE == 'BP' && crmObj.data.ORG_CAPIQ_ID == null && crmObj.data.PE_CAPIQ_ID == null) {
                                this.setState({ bpId_flag: true, peId_flag: true })
                                companyObj.push({
                                    'name': crmObj.data.ORG_NAME, 'location': crmObj.data.COUNTRY,
                                    'sapIndustry': crmObj.data.SAP_MASTER_CODE_TEXT, 'revenue': '-',
                                    'currency': '-',
                                    'capId': crmObj.data.SAP_TOP_VIEW_CAPIQ_ID, 'capIdName': '-', 'globalId': crmObj.data.SAP_TOP_VIEW,
                                    'globalIdName': crmObj.data.SAP_TOP_VIEW_NAME, 'peId': crmObj.data.PE_ID, 'peIdName': crmObj.data.PE_NAME,
                                    'bpIdName': crmObj.data.ORG_NAME,
                                    'bpId': crmObj.data.ORG_ID, 'country': '-', 'website': '-',
                                    'industry_sector': '-',
                                    'industry_group': '-', 'employees': '-'
                                })
                            }

                            this.setState({ companyObj: companyObj }, this.loadCompanyData)
                            this.props.getSelectedData(companyObj)
                        }
                        else {
                            this.props.capIqSearchFailure(error.response.data.Message)//unhandled exception here
                        }

                    })
            } else {
                if (crmObj != null || crmObj != undefined) {
                    if (crmObj.data.COMPANY_SOURCE == 'SAP_TOP_VIEW') {
                        this.setState({ bpId_flag: false, peId_flag: false })
                        companyObj.push({
                            'name': crmObj.data.SAP_TOP_VIEW_NAME, 'location': crmObj.data.SAP_TOP_VIEW_COUNTRY,
                            'sapIndustry': crmObj.data.SAP_MASTER_CODE_TEXT, 'revenue': '-',
                            'currency': '-',
                            'capId': crmObj.data.SAP_TOP_VIEW_CAPIQ_ID, 'capIdName': '-', 'globalId': crmObj.data.SAP_TOP_VIEW,
                            'globalIdName': crmObj.data.SAP_TOP_VIEW_NAME, 'peId': '-', 'peIdName': '-',
                            'bpId': '-', 'bpIdName': '-',
                            'country': '-', 'website': '-',
                            'industry_sector': '-',
                            'industry_group': '-', 'employees': '-'
                        })
                    } else if (crmObj.data.COMPANY_SOURCE == 'PE' && crmObj.data.PE_CAPIQ_ID != null) {
                        this.setState({ peId_flag: true, bpId_flag: false })
                        companyObj.push({
                            'name': crmObj.data.PE_NAME, 'location': crmObj.data.PE_ID_COUNTRY,
                            'sapIndustry': crmObj.data.SAP_MASTER_CODE_TEXT, 'revenue': '-',
                            'currency': '-',
                            'capId': crmObj.data.SAP_TOP_VIEW_CAPIQ_ID, 'capIdName': '-', 'globalId': crmObj.data.SAP_TOP_VIEW,
                            'globalIdName': crmObj.data.SAP_TOP_VIEW_NAME, 'peId': crmObj.data.PE_ID, 'peIdName': crmObj.data.PE_NAME,
                            'bpId': '-', 'bpIdName': '-',
                            'country': '-', 'website': '-',
                            'industry_sector': '-',
                            'industry_group': '-', 'employees': '-'
                        })
                    }
                    else if (crmObj.data.COMPANY_SOURCE == 'PE' && crmObj.data.PE_CAPIQ_ID == null) {
                        this.setState({ peId_flag: true, bpId_flag: false })
                        companyObj.push({
                            'name': crmObj.data.PE_NAME, 'location': crmObj.data.PE_ID_COUNTRY,
                            'sapIndustry': crmObj.data.SAP_MASTER_CODE_TEXT, 'revenue': '-',
                            'currency': '-',
                            'capId': crmObj.data.SAP_TOP_VIEW_CAPIQ_ID, 'capIdName': '-', 'globalId': crmObj.data.SAP_TOP_VIEW,
                            'globalIdName': crmObj.data.SAP_TOP_VIEW_NAME, 'peId': crmObj.data.PE_ID, 'peIdName': crmObj.data.PE_NAME,
                            'bpId': '-', 'bpIdName': '-',
                            'country': '-', 'website': "-",
                            'industry_sector': '-',
                            'industry_group': '-', 'employees': '-'
                        })
                    } else if (crmObj.data.COMPANY_SOURCE == 'BP' && crmObj.data.ORG_CAPIQ_ID != null) {
                        this.setState({ bpId_flag: true, peId_flag: true })
                        companyObj.push({
                            'name': crmObj.data.ORG_NAME, 'location': crmObj.data.COUNTRY,
                            'sapIndustry': crmObj.data.SAP_MASTER_CODE_TEXT, 'revenue': '-',
                            'currency': '-',
                            'capId': crmObj.data.SAP_TOP_VIEW_CAPIQ_ID, 'capIdName': '-', 'globalId': crmObj.data.SAP_TOP_VIEW,
                            'globalIdName': crmObj.data.SAP_TOP_VIEW_NAME, 'peId': crmObj.data.PE_ID, 'peIdName': crmObj.data.PE_NAME,
                            'bpIdName': crmObj.data.ORG_NAME,
                            'bpId': crmObj.data.ORG_ID,
                            'country': '-', 'website': '-',
                            'industry_sector': '-',
                            'industry_group': '-', 'employees': '-'
                        })
                    } else if (crmObj.data.COMPANY_SOURCE == 'BP' && crmObj.data.ORG_CAPIQ_ID == null && crmObj.data.PE_CAPIQ_ID != null) {
                        this.setState({ bpId_flag: true, peId_flag: true })
                        companyObj.push({
                            'name': crmObj.data.ORG_NAME, 'location': crmObj.data.COUNTRY,
                            'sapIndustry': crmObj.data.SAP_MASTER_CODE_TEXT, 'revenue': '-',
                            'currency': '-',
                            'capId': crmObj.data.SAP_TOP_VIEW_CAPIQ_ID, 'capIdName': '-', 'globalId': crmObj.data.SAP_TOP_VIEW,
                            'globalIdName': crmObj.data.SAP_TOP_VIEW_NAME, 'peId': crmObj.data.PE_ID, 'peIdName': crmObj.data.PE_NAME,
                            'bpIdName': crmObj.data.ORG_NAME,
                            'bpId': crmObj.data.ORG_ID, 'country': '-', 'website': '-',
                            'industry_sector': '-',
                            'industry_group': '-', 'employees': '-'
                        })
                    } else if (crmObj.data.COMPANY_SOURCE == 'BP' && crmObj.data.ORG_CAPIQ_ID == null && crmObj.data.PE_CAPIQ_ID == null) {
                        this.setState({ bpId_flag: true, peId_flag: true })
                        companyObj.push({
                            'name': crmObj.data.ORG_NAME, 'location': crmObj.data.COUNTRY,
                            'sapIndustry': crmObj.data.SAP_MASTER_CODE_TEXT, 'revenue': '-',
                            'currency': '-',
                            'capId': crmObj.data.SAP_TOP_VIEW_CAPIQ_ID, 'capIdName': '-', 'globalId': crmObj.data.SAP_TOP_VIEW,
                            'globalIdName': crmObj.data.SAP_TOP_VIEW_NAME, 'peId': crmObj.data.PE_ID, 'peIdName': crmObj.data.PE_NAME,
                            'bpIdName': crmObj.data.ORG_NAME,
                            'bpId': crmObj.data.ORG_ID, 'country': '-', 'website': '-',
                            'industry_sector': '-',
                            'industry_group': '-', 'employees': '-'
                        })
                    }
                } else {
                    companyObj.push({
                        'name': this.state.company, 'location': '-',
                        'sapIndustry': '-', 'revenue': '-',
                        'capId': '-', 'capIdName': '-', 'globalId': '-', 'peId': '-',
                        'bpId': '-', 'country': '-', 'website': '-',
                        'industry_sector': '-',
                        'industry_group': '-', 'employees': '-'
                    })
                }

                this.setState({ companyObj: companyObj }, this.loadCompanyData)
                this.props.getSelectedData(companyObj)
            }
        } else {
            var obj = this.state.companyNameCopy.find(item => item.id == this.state.index && item.flag == this.state.flag)
            let revenue = this.calculateRevenue(obj.data.CURRENCY_CODE, obj.data.COMPANY_REVENUE, obj.data.UNIT_TYPE_VALUE)
            this.setState({ peId_flag: true, bpId_flag: true })
            companyObj.push({
                'name': obj.data.COMPANY_NAME, 'location': obj.data.COUNTRY_NAME,
                'sapIndustry': obj.data.AVATAR_INDUSTRY, 'revenue': revenue,
                'currency': obj.data.CURRENCY_CODE,
                'capId': obj.data.COMPANY_ID, 'capIdName': obj.data.COMPANY_NAME, 'globalId': '-', 'globalIdName': '-', 'peId': '-', 'peIdName': '-',
                'bpId': '-', 'bpIdName': '-',
                'country': obj.data.COUNTRY_NAME, 'website': obj.data.COMPANY_WEBSITE,
                'industry_sector': obj.data.COMPANY_INDUSTRY_DESC,
                'industry_group': obj.data.COMPANY_L3_INDUSTRY_DESC, 'employees': this.formatEmployee(obj.data.COMPANY_EMP_COUNT)
            })
            this.setState({ companyObj: companyObj, cursor: false }, this.loadCompanyData)
            this.props.getSelectedData(companyObj)
        }

    }

    radioButtonClicked(data) {
        this.setState({ companyNameCopy: data, index: data[0].id, flag: data[0].flag }, this.createCompanyProfileArray)
    }

    //This creates the jsx for companyprofile section
    loadCompanyData() {
        var data = this.state.companyObj[0];
        var result = (<React.Fragment>
            {/* <p>Company Name: <span>{data.name}</span></p> */}
            <div className="company-info-row">
                <div className="grid-x">
                    <div className="medium-5 large-5 title">{this.props.content.companyProfile.Company_Name}</div>
                    <div className="medium-7 large-7 value">{data.name}</div>
                </div>
                <div className="grid-x">
                    <div className="medium-5 large-5 title">{this.props.content.companyProfile.Location}</div>
                    <div className="medium-7 large-7 cell value">{data.location}</div>
                </div>
                <div className="grid-x">
                    <div className="medium-5 large-5 title">{this.props.content.companyProfile.SAP_Industry}</div>
                    <div className="medium-7 large-7 cell value">{data.sapIndustry} </div>
                </div>
                <div className="grid-x">
                    <div className="medium-5 large-5 title">{this.props.content.companyProfile.Revenue}</div>
                    <div className="medium-7 large-7 cell blue value">{data.revenue}</div>
                </div>
                <div className="grid-x">
                    <div className="medium-5 large-5 title">{this.props.content.companyProfile.CapIQ_ID}</div>
                    <div className="medium-7 large-7 cell value">
                        <div className="grid-x result-capQId">
                            <div className="medium-11 large-11">
                                {data.capId}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={this.state.peId_flag ? "grid-x" : 'grid-x hideBorder'}>
                    <div className="medium-5 large-5 title">{this.props.content.companyProfile.Global_ID}</div>
                    <div className="medium-6 large-6 cell value">{data.globalId}</div>
                    {/* <div className="medium-1 large-1">
                        <img width="10" src="/images/arrow_inactive.svg" onClick={() => this.showModal('abc')} />
                    </div> */}
                    <div className="medium-1 large-1" onClick={this.fetchDataForModal} value={data.globalId}>
                        <Popup className="large-popup"
                            trigger={ this.state.cursor ?
                                <img Style="cursor: pointer" className="right_arrow" width="10" src="/images/arrow_inactive.svg" />
                                : ''}
                            position="right center"
                            open={this.state.open}
                            onOpen={this.closePopUp}
                            closeOnDocumentClick
                        >
                            <div>
                                <TreeView
                                    data={this.state.treeData}
                                    radioButtonClicked={this.radioButtonClicked}
                                    close={this.closePopUp}
                                />
                            </div>
                        </Popup>
                    </div>
                </div>
                <div className={this.state.peId_flag ? (this.state.bpId_flag ? 'grid-x highlighted-row' : 'grid-x highlighted-row hideBorder') : 'hide'} >
                    <div className="medium-5 large-5 title">{this.props.content.companyProfile.PE_ID}</div>
                    <div className="medium-7 large-7 cell value">{data.peId}</div>
                </div>
                <div className={this.state.bpId_flag ? 'grid-x highlighted-row' : 'hide'}>
                    <div className="medium-5 large-5 title">{this.props.content.companyProfile.BP_ID}</div>
                    <div className="medium-7 large-7 cell value">{data.bpId}</div>
                </div>
            </div>
            <div className="companySpecifics">
                <div className="grid-x">
                    <div className="medium-5 large-5 title">{this.props.content.companyProfile.Country}</div>
                    <div className="medium-7 large-7 cell value">{data.country} </div>
                </div>
                <div className="grid-x">
                    <div className="medium-5 large-5 title">{this.props.content.companyProfile.Website} </div>
                    <div className="medium-7 large-7 cell value">
                        <a target="_blank" href="http://www.accenture.com">{data.website}</a>
                    </div>
                </div>
                <div className="grid-x">
                    <div className="medium-5 large-5 title">{this.props.content.companyProfile.Industry_Sector} </div>
                    <div className="medium-7 large-7 cell value">{data.industry_sector} </div>
                </div>
                <div className="grid-x">
                    <div className="medium-5 large-5 title">{this.props.content.companyProfile.Industry_Group} </div>
                    <div className="medium-7 large-7 cell value">{data.industry_group}</div>
                </div>
                {/* <div className="grid-x">
                    <div className="medium-5 large-5 title">Industry</div>
                    <div className="medium-7 large-7 cell value">IT Consulting and Other Services </div>
                </div> */}
                <div className="grid-x">
                    <div className="medium-5 large-5 title">{this.props.content.companyProfile.Employees}</div>
                    <div className="medium-7 large-7 cell value">{data.employees}</div>
                </div>
            </div>
        </React.Fragment>)

        this.setState({ companyDetails: result })
        
    }

    CompanyClicked(data) {
        //console.log(data.target.innerText)
        
        this.setState({ company: data.target.innerText, selected: true, index: data.target.id, flag: data.target.attributes.value.nodeValue }, this.loadSearchData)

    }

    render() {
        return (
            <div>
                <React.Fragment>
                    <div className="grid-x grey_bb search_titles">
                        <div className="medium-5 large-5 cell pl7">
                            {this.props.content.search.searchResult}
                        </div>
                        <div className="medium-7 large-7 cell pl30">
                            {this.props.content.search.companyDeatils}
                        </div>
                    </div>
                    <div className="grid-x">
                        <div className="medium-5 large-5">
                            <ScrollArea minScrollSize={40}
                                speed={0.8}
                                className="search-result-cont"
                                contentClassName="content"
                                horizontal={false}
                            >
                                <ul className="search_results_tabs">
                                    {this.state.results}
                                </ul>
                            </ScrollArea>
                        </div>
                        <div className="medium-7 large-7">
                            <div className="companyDetails" id='style-1'>
                                {/* <ScrollArea minScrollSize={40}
                                speed={0.8}
                                className="search-result-cont"
                                contentClassName="content"
                                horizontal={false}
                            > */}
                                {this.state.companyDetails}
                                {/* </ScrollArea> */}
                            </div>
                        </div>
                    </div>

                </React.Fragment>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        content: state.content,
        result: state.searchText,
        search: state.searchResultData,
        firstSearchResult: state.searchData
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCapIqResults: (value) => dispatch(fetchCapIqResults(value)),
        capIqSearchLoading: (bool) => dispatch(capIqSearchLoading(bool)),
        capIqSearchSuccess: (msg) => dispatch(capIqSearchSuccess(msg)),
        capIqSearchFailure: (error) => dispatch(capIqSearchFailure(error)),
        searchResult: (result) => dispatch(searchResult(result)),
        searchText: (text) => dispatch(searchText(text)),
        searchData: (data) => dispatch(searchData(data)),
        treeResult: (data) => dispatch(treeResult(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchResults)
