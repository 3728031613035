import React from "react";
import { convertToMillion, formatThousands } from 'utils/Utils'
import Popup from "reactjs-popup";
import { kpiChartTableLableIcon } from 'Constants'

class KPIChartTable extends React.Component {
    constructor(props) {
        super(props)
        {
            this.state={
            headers: ['', 'Q1', 'Q2', 'Q3', 'Q4', 'Q5', 'Q6', 'Q7', 'Q8', 'Q9', 'Q10', 'Q11', 'Q12']
            }
        }
    }

    render() {
        var currencySymbol = this.props.currencySymbols !== undefined ? this.props.currencySymbols : ''        
        var isSpendType = this.props.type === 'Spend' ? true : false;
        return (
            <div className='kpiChartTable table-scroll grid-x medium-12'>
            {this.props.data ?
                <table>
                    {this.state.headers.map((el, index) =>
                                index == 0 ?
                                    <col style={{ width: '16%' }}></col> :
                                    <col></col>
                            )}
                    <tr>
                        {this.state.headers.map(el => <th >{el}</th>)}
                    </tr>
                    {this.props.data.map((item, index) =>
                    <tbody>
                        {this.props.keyFilter == 'chart2' ? 
                        <tr>
                                    <td className='secondHeader' style={{ textAlign: 'left' }}> <span className="legend-dot kpiValue"></span>{this.props.type} Target KPI
                                        <span>
                                            <Popup className="report-tooltip"
                                                trigger={<img style={{ marginLeft: '5px', paddingBottom: '20px' }} className="info-icon" src="images/info-icon.svg" width="15" />}
                                                position="bottom left"
                                                on="hover"
                                            >
                                                <div>
                                                    {/* {this.props.type} Target KPI */}
                                                    {kpiChartTableLableIcon[this.props.type + ' Target KPI']}
                                                </div>
                                            </Popup>
                                        </span>
                                    </td>
                            <td > { isSpendType ? currencySymbol +  convertToMillion(item.KPI_VALUE_Q1,true) : formatThousands(Math.round(item.KPI_VALUE_Q1))}   </td>
                            <td > { isSpendType ? currencySymbol +  convertToMillion(item.KPI_VALUE_Q2,true) : formatThousands(Math.round(item.KPI_VALUE_Q2))}   </td>
                            <td > { isSpendType ? currencySymbol +  convertToMillion(item.KPI_VALUE_Q3,true) : formatThousands(Math.round(item.KPI_VALUE_Q3))}   </td>
                            <td > { isSpendType ? currencySymbol +  convertToMillion(item.KPI_VALUE_Q4,true) : formatThousands(Math.round(item.KPI_VALUE_Q4))}   </td>
                            <td > { isSpendType ? currencySymbol +  convertToMillion(item.KPI_VALUE_Q5,true) : formatThousands(Math.round(item.KPI_VALUE_Q5))}   </td>
                            <td > { isSpendType ? currencySymbol +  convertToMillion(item.KPI_VALUE_Q6,true) : formatThousands(Math.round(item.KPI_VALUE_Q6))}   </td>
                            <td > { isSpendType ? currencySymbol +  convertToMillion(item.KPI_VALUE_Q7,true) : formatThousands(Math.round(item.KPI_VALUE_Q7))}   </td>
                            <td > { isSpendType ? currencySymbol +  convertToMillion(item.KPI_VALUE_Q8,true) : formatThousands(Math.round(item.KPI_VALUE_Q8))}   </td>
                            <td > { isSpendType ? currencySymbol +  convertToMillion(item.KPI_VALUE_Q9,true) : formatThousands(Math.round(item.KPI_VALUE_Q9))}   </td>
                            <td > { isSpendType ? currencySymbol +  convertToMillion(item.KPI_VALUE_Q10,true) : formatThousands(Math.round(item.KPI_VALUE_Q10))}  </td>
                            <td > { isSpendType ? currencySymbol +  convertToMillion(item.KPI_VALUE_Q11,true) : formatThousands(Math.round(item.KPI_VALUE_Q11))}  </td>
                            <td > { isSpendType ? currencySymbol +  convertToMillion(item.KPI_VALUE_Q12,true) : formatThousands(Math.round(item.KPI_VALUE_Q12))}  </td>
                     </tr>
                     :null}
                     {this.props.keyFilter == 'chart1' ? 
                     <tr>
                                    <td className='secondHeader' style={{ textAlign: 'left' }}> <span className="legend-dot estimatedValue"></span>{this.props.type} KPI Estimate
                                        <span>
                                            <Popup className="report-tooltip"
                                                trigger={<img style={{ marginLeft: '5px', paddingBottom: '20px' }} className="info-icon" src="images/info-icon.svg" width="15" />}
                                                position="bottom left"
                                                on="hover"
                                            >
                                                <div>
                                                    {/* {this.props.type} KPI Estimate */}
                                                    {kpiChartTableLableIcon[this.props.type + ' KPI Estimate']}
                                                </div>
                                            </Popup>
                                        </span>
                                    </td>
                            <td > { isSpendType? currencySymbol +  convertToMillion(item.ESTIMATED_VALUE_Q1,true) :formatThousands(Math.round(item.ESTIMATED_VALUE_Q1))}   </td>
                            <td > { isSpendType? currencySymbol +  convertToMillion(item.ESTIMATED_VALUE_Q2,true) :formatThousands(Math.round(item.ESTIMATED_VALUE_Q2))}   </td>
                            <td > { isSpendType? currencySymbol +  convertToMillion(item.ESTIMATED_VALUE_Q3,true) :formatThousands(Math.round(item.ESTIMATED_VALUE_Q3))}   </td>
                            <td > { isSpendType? currencySymbol +  convertToMillion(item.ESTIMATED_VALUE_Q4,true) :formatThousands(Math.round(item.ESTIMATED_VALUE_Q4))}   </td>
                            <td > { isSpendType? currencySymbol +  convertToMillion(item.ESTIMATED_VALUE_Q5,true) :formatThousands(Math.round(item.ESTIMATED_VALUE_Q5))}   </td>
                            <td > { isSpendType? currencySymbol +  convertToMillion(item.ESTIMATED_VALUE_Q6,true) :formatThousands(Math.round(item.ESTIMATED_VALUE_Q6))}   </td>
                            <td > { isSpendType? currencySymbol +  convertToMillion(item.ESTIMATED_VALUE_Q7,true) :formatThousands(Math.round(item.ESTIMATED_VALUE_Q7))}   </td>
                            <td > { isSpendType? currencySymbol +  convertToMillion(item.ESTIMATED_VALUE_Q8,true) :formatThousands(Math.round(item.ESTIMATED_VALUE_Q8))}   </td>
                            <td > { isSpendType? currencySymbol +  convertToMillion(item.ESTIMATED_VALUE_Q9,true) :formatThousands(Math.round(item.ESTIMATED_VALUE_Q9))}   </td>
                            <td > { isSpendType? currencySymbol +  convertToMillion(item.ESTIMATED_VALUE_Q10,true) :formatThousands(Math.round(item.ESTIMATED_VALUE_Q10))} </td>
                            <td > { isSpendType? currencySymbol +  convertToMillion(item.ESTIMATED_VALUE_Q11,true) :formatThousands(Math.round(item.ESTIMATED_VALUE_Q11))} </td>
                            <td > { isSpendType? currencySymbol +  convertToMillion(item.ESTIMATED_VALUE_Q12,true) :formatThousands(Math.round(item.ESTIMATED_VALUE_Q12))} </td>
                     </tr>
                    :null}
                    {this.props.keyFilter == 'chart1' ? 
                     <tr>
                                    <td className='secondHeader' style={{ textAlign: 'left' }}> <span className="legend-dot actualValue"></span>Actual {this.props.type}
                                        <span>
                                            <Popup className="report-tooltip"
                                                trigger={<img style={{ marginLeft: '5px', paddingBottom: '20px' }} className="info-icon" src="images/info-icon.svg" width="15" />}
                                                position="bottom left"
                                                on="hover"
                                            >
                                                <div>
                                                    {/* Actual {this.props.type} */}
                                                    {kpiChartTableLableIcon['Actual ' + this.props.type]}
                                                </div>
                                            </Popup>
                                        </span>
                                    </td>
                            <td > {isSpendType? (item.ACTUAL_VALUE_Q1 !==0 ? currencySymbol +  convertToMillion(item.ACTUAL_VALUE_Q1,true)  : '-' ): ( item.ACTUAL_VALUE_Q1 !==0 ? formatThousands(Math.round(item.ACTUAL_VALUE_Q1)) : '-')}  </td>
                            <td > {isSpendType? (item.ACTUAL_VALUE_Q2 !==0 ? currencySymbol +  convertToMillion(item.ACTUAL_VALUE_Q2,true)  : '-' ): ( item.ACTUAL_VALUE_Q2 !==0 ? formatThousands(Math.round(item.ACTUAL_VALUE_Q2)) : '-')}  </td>
                            <td > {isSpendType? (item.ACTUAL_VALUE_Q3 !==0 ? currencySymbol +  convertToMillion(item.ACTUAL_VALUE_Q3,true)  : '-' ): ( item.ACTUAL_VALUE_Q3 !==0 ? formatThousands(Math.round(item.ACTUAL_VALUE_Q3)) : '-')}  </td>
                            <td > {isSpendType? (item.ACTUAL_VALUE_Q4 !==0 ? currencySymbol +  convertToMillion(item.ACTUAL_VALUE_Q4,true)  : '-' ): ( item.ACTUAL_VALUE_Q4 !==0 ? formatThousands(Math.round(item.ACTUAL_VALUE_Q4)) : '-')}  </td>
                            <td > {isSpendType? (item.ACTUAL_VALUE_Q5 !==0 ? currencySymbol +  convertToMillion(item.ACTUAL_VALUE_Q5,true)  : '-' ): ( item.ACTUAL_VALUE_Q5 !==0 ? formatThousands(Math.round(item.ACTUAL_VALUE_Q5)) : '-')}  </td>
                            <td > {isSpendType? (item.ACTUAL_VALUE_Q6 !==0 ? currencySymbol +  convertToMillion(item.ACTUAL_VALUE_Q6,true)  : '-' ): ( item.ACTUAL_VALUE_Q6 !==0 ? formatThousands(Math.round(item.ACTUAL_VALUE_Q6)) : '-')}  </td>
                            <td > {isSpendType? (item.ACTUAL_VALUE_Q7 !==0 ? currencySymbol +  convertToMillion(item.ACTUAL_VALUE_Q7,true)  : '-' ): ( item.ACTUAL_VALUE_Q7 !==0 ? formatThousands(Math.round(item.ACTUAL_VALUE_Q7)) : '-')}  </td>
                            <td > {isSpendType? (item.ACTUAL_VALUE_Q8 !==0 ? currencySymbol +  convertToMillion(item.ACTUAL_VALUE_Q8,true)  : '-' ): ( item.ACTUAL_VALUE_Q8 !==0 ? formatThousands(Math.round(item.ACTUAL_VALUE_Q8)) : '-')}  </td>
                            <td > {isSpendType? (item.ACTUAL_VALUE_Q9 !==0 ? currencySymbol +  convertToMillion(item.ACTUAL_VALUE_Q9,true)  : '-' ): ( item.ACTUAL_VALUE_Q9 !==0 ? formatThousands(Math.round(item.ACTUAL_VALUE_Q9)) : '-')}  </td>
                            <td > {isSpendType? (item.ACTUAL_VALUE_Q10 !==0 ? currencySymbol +  convertToMillion(item.ACTUAL_VALUE_Q10,true) : '-' ) :( item.ACTUAL_VALUE_Q10 !==0 ? formatThousands(Math.round(item.ACTUAL_VALUE_Q10)): '-')} </td>
                            <td > {isSpendType? (item.ACTUAL_VALUE_Q11 !==0 ? currencySymbol +  convertToMillion(item.ACTUAL_VALUE_Q11,true) : '-' ) :( item.ACTUAL_VALUE_Q11 !==0 ? formatThousands(Math.round(item.ACTUAL_VALUE_Q11)): '-')} </td>
                            <td > {isSpendType? (item.ACTUAL_VALUE_Q12 !==0 ? currencySymbol +  convertToMillion(item.ACTUAL_VALUE_Q12,true) : '-' ) :( item.ACTUAL_VALUE_Q12 !==0 ? formatThousands(Math.round(item.ACTUAL_VALUE_Q12)): '-')} </td>
                     </tr>
                    :null}
                    {this.props.keyFilter == 'chart2' ? 
                     <tr>
                            <td className='secondHeader' style={{ textAlign:'left' }}> <span className="legend-dot plannedValue"></span>{this.props.type} Planned
                                        <span>
                                            <Popup className="report-tooltip"
                                                trigger={<img style={{ marginLeft: '5px', paddingBottom: '20px' }} className="info-icon" src="images/info-icon.svg" width="15" />}
                                                position="bottom left"
                                                on="hover"
                                            >
                                                <div>
                                                    {/* {this.props.type} Planned */}
                                                    {kpiChartTableLableIcon[this.props.type + ' Planned']}
                                                </div>
                                            </Popup>
                                        </span>
                            </td>
                            <td > {isSpendType? (item.PLANNED_VALUE_Q1 !==0 ? currencySymbol +  convertToMillion(item.PLANNED_VALUE_Q1,true)  : '-' ): ( item.PLANNED_VALUE_Q1 !==0 ? formatThousands(Math.round(item.PLANNED_VALUE_Q1)) : '-')}  </td>
                            <td > {isSpendType? (item.PLANNED_VALUE_Q2 !==0 ? currencySymbol +  convertToMillion(item.PLANNED_VALUE_Q2,true)  : '-' ): ( item.PLANNED_VALUE_Q2 !==0 ? formatThousands(Math.round(item.PLANNED_VALUE_Q2)) : '-')}  </td>
                            <td > {isSpendType? (item.PLANNED_VALUE_Q3 !==0 ? currencySymbol +  convertToMillion(item.PLANNED_VALUE_Q3,true)  : '-' ): ( item.PLANNED_VALUE_Q3 !==0 ? formatThousands(Math.round(item.PLANNED_VALUE_Q3)) : '-')}  </td>
                            <td > {isSpendType? (item.PLANNED_VALUE_Q4 !==0 ? currencySymbol +  convertToMillion(item.PLANNED_VALUE_Q4,true)  : '-' ): ( item.PLANNED_VALUE_Q4 !==0 ? formatThousands(Math.round(item.PLANNED_VALUE_Q4)) : '-')}  </td>
                            <td > {isSpendType? (item.PLANNED_VALUE_Q5 !==0 ? currencySymbol +  convertToMillion(item.PLANNED_VALUE_Q5,true)  : '-' ): ( item.PLANNED_VALUE_Q5 !==0 ? formatThousands(Math.round(item.PLANNED_VALUE_Q5)) : '-')}  </td>
                            <td > {isSpendType? (item.PLANNED_VALUE_Q6 !==0 ? currencySymbol +  convertToMillion(item.PLANNED_VALUE_Q6,true)  : '-' ): ( item.PLANNED_VALUE_Q6 !==0 ? formatThousands(Math.round(item.PLANNED_VALUE_Q6)) : '-')}  </td>
                            <td > {isSpendType? (item.PLANNED_VALUE_Q7 !==0 ? currencySymbol +  convertToMillion(item.PLANNED_VALUE_Q7,true)  : '-' ): ( item.PLANNED_VALUE_Q7 !==0 ? formatThousands(Math.round(item.PLANNED_VALUE_Q7)) : '-')}  </td>
                            <td > {isSpendType? (item.PLANNED_VALUE_Q8 !==0 ? currencySymbol +  convertToMillion(item.PLANNED_VALUE_Q8,true)  : '-' ): ( item.PLANNED_VALUE_Q8 !==0 ? formatThousands(Math.round(item.PLANNED_VALUE_Q8)) : '-')}  </td>
                            <td > {isSpendType? (item.PLANNED_VALUE_Q9 !==0 ? currencySymbol +  convertToMillion(item.PLANNED_VALUE_Q9,true)  : '-' ): ( item.PLANNED_VALUE_Q9 !==0 ? formatThousands(Math.round(item.PLANNED_VALUE_Q9)) : '-')}  </td>
                            <td > {isSpendType? (item.PLANNED_VALUE_Q10 !==0 ? currencySymbol +  convertToMillion(item.PLANNED_VALUE_Q10,true) : '-' ) :( item.PLANNED_VALUE_Q10 !==0 ? formatThousands(Math.round(item.PLANNED_VALUE_Q10)): '-')} </td>
                            <td > {isSpendType? (item.PLANNED_VALUE_Q11 !==0 ? currencySymbol +  convertToMillion(item.PLANNED_VALUE_Q11,true) : '-' ) :( item.PLANNED_VALUE_Q11 !==0 ? formatThousands(Math.round(item.PLANNED_VALUE_Q11)): '-')} </td>
                            <td > {isSpendType? (item.PLANNED_VALUE_Q12 !==0 ? currencySymbol +  convertToMillion(item.PLANNED_VALUE_Q12,true) : '-' ) :( item.PLANNED_VALUE_Q12 !==0 ? formatThousands(Math.round(item.PLANNED_VALUE_Q12)): '-')} </td>
                     </tr>
                    :null}
                     </tbody>
                    )}
                </table>
                : null}
        </div>
        );
    }
}



export default KPIChartTable;