import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Control, Form, Errors, actions } from 'react-redux-form'
import moment from 'moment'
// Utils
import { BreakLine } from 'utils/Utils'
// UI
import Button from 'UI/Button'
import Confirmation from 'UI/CustomeConfirmation'
// Settings
import { validators } from 'Constants'
import Select from 'react-select'
import { getCurrancyDetails, formatThousands } from 'utils/Utils'
import {
  getYourFileSuccess,
  getYourFileLoading,
  resetError,
  getYourFileFailure,
  setResultSummaryDetails,
  setTopDetails,
  LoadRequestDetails,
  GetAllRequestsAgainstValueStory,
  getYourRequestsDetails,
  ActivetDeactiveCurrentRequest,
  CleanRequestData,
  UpdateComment,
  currentActiveRequest,
  setValueStoryDmciDetails,
  setYourFileRequestDetails,
  GetAllMappedColumn,
  setMappedColumn,
  getUserSpendSelections,
  trackUserSpendSelections,
  solutionScopeChanged
} from 'actions/spendActions'

//import AccountParameters from 'components/Spend/AccountParameters'
import VideoHelp from 'components/UI/VideoHelp'
import Screen from 'UI/CustomComponents/Screen';

import { ControlTextE, SelectE, ScreenE, RadioButtonNativeE, ButtonE, ButtonNativeE, TextAreaE } from 'UI/CustomComponents/Controls';

class YourFileContainer extends Component {
  _isMounted = false;
  constructor(props) {
    super(props)
    this.state = {
      RequestIds: [],
      selectedRequestId: '',
      fileDetails: [],
      commentsRequest: '',
      allRequestOfVSIDnDMCID: [],
      currentActiveRequest: '',
      isSharedValueStory: false,
      sharedByDetails: ''
    }
    this.handleRequestChange = this.handleRequestChange.bind(this)
    this.redirect = this.redirect.bind(this)
    this.onChange = this.onChange.bind(this)
    this.handleOptionChange = this.handleOptionChange.bind(this)
    this.handleLoadButton = this.handleLoadButton.bind(this)
    this.deleteRequest = this.deleteRequest.bind(this)
    this.showModal = this.showModal.bind(this)
    this.modelRef = React.createRef()
    this.modelDelete = []
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadData()
    window.scrollTo(0, 0);
  }
  componentWillUnmount() {
    this._isMounted = false;
    this.props.resetError();
    // update comment for current active request
    if (this.props.spendDetails.currentActiveRequest && this.props.spendDetails.currentActiveRequest.UPDATED_COMMENTS !== '') {
      this.props.UpdateComment(this.props.spendDetails.currentActiveRequest).then(res => {
        //
      })
    }
    this.formDispatch(actions.reset('yourFile'))
  }
  // To find the message from Json containt just pass the status code and find the message
  getMessage(keyToFindMessage) {
    return this.props.content[keyToFindMessage];
  }

  showModal(model) {

    model.current.show()

  }

  hideModal(model) {
    model.current.hide()
    this.props.getYourFileSuccess("");
  }


  onChange(e) {
    var requestDetails = this.state.currentActiveRequest;
    //var currentActiveIndex = requestDetails.findIndex(a => a.ACTIVE_FLAG === true);
    //if(currentActiveIndex  > -1)
    //{
    requestDetails.UPDATED_COMMENTS = e.target.value;

    this.setState({ currentActiveRequest: requestDetails })
    this.props.currentActiveRequest(requestDetails);
    //}

    //alert(date);
  }

  loadData() {
    var userId = this.props.user.user.Id;
    this.props.getYourFileLoading(true)
    if (this.props.userData.Id !== this.props.user.user.Id) {
      userId = this.props.userData.Id
      this.setState({ isSharedValueStory: true })

    }

    if (this.props.spendDetails.requestFileDetails.length === 0) {
      if (this.state.RequestIds === undefined || this.state.RequestIds.length === 0) {
        this.props.getYourRequestsDetails(this.props.user.user.Email)
          .then(response => {
            this.setState({ fileDetails: response.data.Result })
            this.props.setYourFileRequestDetails(response.data.Result);
            this.setDropDownData(response.data.Result)
          }).catch(res => {
            this.props.setYourFileRequestDetails([]);
            this.props.getYourFileFailure(this.getMessage('notFound'))
          })
      }
    }
    else {
      this.setDropDownData(this.props.spendDetails.requestFileDetails)
      this.setState({ fileDetails: this.props.spendDetails.requestFileDetails }, () => this.props.getYourFileSuccess());
    }

    if (userId !== undefined && userId !== "") {
      this.GetAllRequestsAgainstValueStory(userId);
      this.getAllMappedColumn(this.props.user.user.Id);
    }

  }

  setDropDownData(data) {
    var RequestIds = []


    // Needs to chnage location of code in above response once get data from API
    data.forEach(element => {
      var result = {
        label:
          (element.Buyer_Name + " | Request Id: " + element.DMC_ID +
            "| " + element.File_Name + " | " +
            moment(element.CREATE_TIMESTAMP).format('MMM DD,YYYY'))
        , value: element.DMC_ID
      };
      RequestIds.push(result);
    });


    this.setState({ RequestIds: RequestIds }, () => this.props.getYourFileSuccess());
  }

  GetAllRequestsAgainstValueStory(userId) {
    if (this.props.spendDetails.valueStoryRequestDetails.length === 0) {
      if (this.props.userData.VSId !== undefined && this.props.userData.VSId > 0) {
        this.props.GetAllRequestsAgainstValueStory(userId, this.props.userData.VSId).
          then(response => {
            if (response.data.StatusCode === 200) {
              this.setState({ allRequestOfVSIDnDMCID: response.data.Result }, () => this.setActiveRequest())
            }
            else {
              this.setState({ allRequestOfVSIDnDMCID: [] }, () => this.setActiveRequest())
            }
            this.props.setValueStoryDmciDetails(response.data.Result);
            // first time loaded and need not to show any message
          }).catch(res => {
            this.props.getYourFileFailure(this.getMessage('error'));
          })

        this.setActiveRequest();
      }
    } else {
      this.setState({ allRequestOfVSIDnDMCID: this.props.spendDetails.valueStoryRequestDetails },
        () => this.setActiveRequest())
    }
  }

  setActiveRequest() {
    var activeRequest = this.state.allRequestOfVSIDnDMCID.filter((element) => (element.ACTIVE_FLAG === true));
    if (activeRequest !== null) {
      this.setState({ currentActiveRequest: activeRequest[0] });
      this.props.spendDetails.currentActiveRequest = activeRequest[0]
      this.props.currentActiveRequest(activeRequest[0]);
      this.props.solutionScopeChanged(activeRequest[0]['SOLUTION_IN_SCOPE']);
      //Get dropdown values selected by users from database.
      if (activeRequest[0] !== null && activeRequest[0] !== undefined) {
        this.props.getUserSpendSelections(activeRequest[0].VS_ID, activeRequest[0].DMC_ID)
          .then(response => {
            this.props.trackUserSpendSelections(response.data.Result)
          }).catch(error => {
            this.props.getYourFileFailure(this.getMessage('error'));
          })
      }
    } else {
      this.setState({ currentActiveRequest: '' });
      this.props.spendDetails.currentActiveRequest = ''
      this.props.currentActiveRequest('');
    }
  }

  handleRequestChange(value) {
    this.props.getYourFileSuccess(''); // To remove the error/success message
    var fileProcessingDate = this.state.fileDetails.filter((element) => (element.DMC_ID === value.value));
    this.formDispatch(actions.change('yourFile.RequestId', value.value.toString()));
    this.setState({ selectedRequestId: value.value })
  }

  redirect() {
    this.props.history.push(`/subTabsContainer`)
  }

  handleLoadButton(formValues) {
    this.props.getYourFileLoading(true);
    var index = this.state.allRequestOfVSIDnDMCID.findIndex(a => a.DMC_ID === this.state.selectedRequestId);
    if (index === -1) {
      this.getRequestData(this.state.selectedRequestId, true);
    }
    else {
      this.showModal(this.modelRef)
      // if (this.showModal(this.modelRef))) {
      //   this.getRequestData(this.state.selectedRequestId, true);
      // }
      // else {
      //   this.props.getYourFileLoading(false);
      // }
    }

  }

  getRequestData(selectedRequestId, isNewRequest) {
    if (selectedRequestId !== '') {
      var selectedRequest = selectedRequestId;
      var selectedRequestDetails = this.state.fileDetails.filter((element) =>
        (element.DMC_ID === selectedRequest));
      selectedRequestDetails[0].VS_ID = this.props.userData.VSId;
      selectedRequestDetails[0].User_Id = this.props.user.user.Id;
      selectedRequestDetails[0].SAPRequester = this.props.user.user.Email
      this.props.LoadRequestDetails(selectedRequestDetails[0], isNewRequest).then((response) => {
        //console.log(response.data.StatusCode===200);
        if (response.data.StatusCode === 200) {
          this.props.setValueStoryDmciDetails(response.data.Result);
          this.setState({ allRequestOfVSIDnDMCID: response.data.Result }, () => this.setActiveRequest())
        }
        else {
          this.props.setValueStoryDmciDetails([]);
          this.setState({ allRequestOfVSIDnDMCID: '' }, () => this.setActiveRequest())
        }
        this.props.getYourFileSuccess(this.getMessage("200"));
      }).catch(res => {

        this.props.getYourFileFailure(this.getMessage('error'));
      })
    } else {
      this.props.getYourFileFailure("");
      this.formDispatch(actions.setErrors('yourFile.RequestId', true));
      this.formDispatch(actions.reset('yourFile.RequestId'));
    }
    if (isNewRequest) {
      this.modelRef.current.hide()
    }
  }

  deleteRequest(deleteRequestId) {
    var _inst = this;
    // if (window.confirm(this.getMessage("deleteConfirmation"))) {
    var index = this.state.allRequestOfVSIDnDMCID.findIndex((element) =>
      (element.APP_CNTRL_REQUEST_DATAId == deleteRequestId.currentTarget.id));
    var deleteRequestDetails = this.state.allRequestOfVSIDnDMCID.filter((element) =>
      (element.APP_CNTRL_REQUEST_DATAId == deleteRequestId.currentTarget.id));
    if (deleteRequestDetails !== undefined && deleteRequestDetails.length > 0) {
      this.props.CleanRequestData(deleteRequestDetails[0]).then((resp) => {
        if (resp.data.StatusCode === 200) {
          this.props.setValueStoryDmciDetails(resp.data.Result);
          this.setState({ allRequestOfVSIDnDMCID: resp.data.Result }, () => this.setActiveRequest())
        }
        else {
          this.props.setValueStoryDmciDetails([]);
          this.setState({ allRequestOfVSIDnDMCID: '' }, () => this.setActiveRequest())
        }

        this.props.getYourFileSuccess(this.getMessage("deleteSuccess"));
      }).catch(res => {
        this.props.getYourFileFailure(this.getMessage('error'));
      })
    } else {
      this.props.getYourFileFailure(this.getMessage('error'));
    }
    _inst.modelDelete[index].current.hide();
    //}
  }

  handleOptionChange(value) {
    this.props.getYourFileLoading(true);
    var requestDetails = this.state.allRequestOfVSIDnDMCID;
    var currentActiveIndex = requestDetails.findIndex(a => a.ACTIVE_FLAG === true);

    //Case when there is no any active request index will be -1
    if (currentActiveIndex > -1) {
      //update comment for current request and then deactivate the current request
      if (this.props.spendDetails.currentActiveRequest.UPDATED_COMMENTS !== '') {
        this.props.UpdateComment(this.props.spendDetails.currentActiveRequest).then(res => {
          //
        })
      }
      requestDetails[currentActiveIndex].ACTIVE_FLAG = false;
    }


    var newActiveIndex = requestDetails.findIndex(item => item.APP_CNTRL_REQUEST_DATAId == value.target.value)
    if (newActiveIndex > -1) {
      requestDetails[newActiveIndex].ACTIVE_FLAG = value.target.checked;

    }

    this.setState({ allRequestOfVSIDnDMCID: requestDetails }, () => this.setActiveRequest())
    this.props.ActivetDeactiveCurrentRequest(requestDetails[newActiveIndex]).then(resp => {
      this.setState({ allRequestOfVSIDnDMCID: resp.data.Result }, () => this.setActiveRequest())
      this.props.setResultSummaryDetails([]);
      this.props.setTopDetails([]);
      this.props.getYourFileSuccess();

    }).catch(res => {
      this.props.getYourFileFailure(this.getMessage('error'));
    })
    this.setState({ RequestSelect: value.target.value });
  }

  getAllMappedColumn(userId) {
    if (userId !== undefined) {
      this.props.getAllMappedColumn(userId).
        then(response => {
          this.props.setMappedColumn(response.data.Result);
        }).catch(res => {
          this.props.getYourFileFailure(this.getMessage('error'));
        })
    }
    else {
      //
    }
  }

  render() {
    var okButtonFlag = true
    //this.modelDelete = React.createRef();
    var fileDetails = (instance) => {

      var allDataToBind = this.state.allRequestOfVSIDnDMCID;
      var allRows = [];
      for (let index = 0; index < allDataToBind.length; index++) {
        const element = allDataToBind[index];
        instance.modelDelete[index] = React.createRef()
        var deleteMsg = ''
        if (element.Created_By === instance.props.user.user.Id) {
          deleteMsg = instance.getMessage("deleteConfirmation")
          okButtonFlag = true
        } else {
          deleteMsg = instance.getMessage("deleteReqOriginatorMsg")
          okButtonFlag = false
        }
        allRows.push(
          <tr className=" medium-12 requestRow" style={{ backgroundColor: element.ACTIVE_FLAG === true ? "#e9f4fd" : "white" }}>
            <td>
              <div>
                <label className="radiocontainer">
                  <input type="radio" value={element.APP_CNTRL_REQUEST_DATAId}
                    checked={element.ACTIVE_FLAG}
                    onChange={(e) => this.handleOptionChange(e)} />
                  <span class="checkmark"></span>
                </label>
              </div>
            </td>
            <td className="tableContent">
              {element.Buyer_Name}
            </td>
            <td className="tableContent" style={{ textAlign: 'right', paddingRight: '70px' }}>
              {element.DMC_ID}
            </td>
            <td className="tableContent">
              {getCurrancyDetails(this.props.currencyCodes, element.Currency)[0]}
            </td>
            <td className="tableContent">
              {moment(element.CREATE_TIMESTAMP).format('MMM DD,YYYY')}
            </td>
            <td className="tableContent" style={{ textAlign: 'right', paddingRight: '70px' }}>
              {formatThousands(element.Number_Of_Records)}
            </td>
            <td>
              {/* <label value={element.APP_CNTRL_REQUEST_DATAId} onClick={(e) => this.deleteRequest(e)} > X </label> */}
              <ButtonNativeE value={element.APP_CNTRL_REQUEST_DATAId}
                onClick={() => instance.showModal(instance.modelDelete[index])} className='suggestedValueClose' />
              <Confirmation
                confirmMessage={deleteMsg}
                OkButtonAction={(e) => instance.deleteRequest(e)}
                CancelButtonAction={() => instance.hideModal(instance.modelDelete[index])}
                id={element.APP_CNTRL_REQUEST_DATAId}
                ref={instance.modelDelete[index]} instance={instance}
                okButtonFlag={okButtonFlag}
              />
            </td>
          </tr>
        );
      }
      return allRows;
    };

    var currentActiveRequest;
    if (this.state.currentActiveRequest !== '' && this.state.currentActiveRequest !== null) {
      currentActiveRequest = this.state.currentActiveRequest
    }

    return (
      <div className="bottom-space">
        <div className='grid-x grid-padding-x'>
          <div className='medium-12'>
            {/* <AccountParameters cancelAction={() => this.loadData()} currencySymbol={currentActiveRequest !== undefined ? this.props.currencySymbols[currentActiveRequest.Currency.trim()] : ''} parametersData={currentActiveRequest} closePopup={() => this.hideModal('AccountParameters')} /> */}
            {/* <button onClick={() => this.hideModal('AccountParameters')} className='accountParameterClose' /> */}
          </div>
          <div className="medium-12 tab-title">{(this.props.content.title)}{<VideoHelp screen={{ module: 'Spend', screenName: 'Your Spend File' }} />}</div>
          <div className="medium-12 tab-description">{this.props.content.subTitle}</div>
        </div>
        <div className='grid-x pt30'>
          <Form className="medium-12"
            model='yourFile' method="post"
            getDispatch={dispatch => (this.formDispatch = dispatch)}
          //onSubmit={(formValue) => this.handleLoadButton(formValue)}
          >
            <div className='heading-with-below-border'>
              {this.props.content.header}
            </div>
            <div class='grid-x pt30'>
              <div className='medium-4'>
                <div className="fieldTitle">{this.props.content.companyName}</div>
                <div className="fieldBlueValue pt10">{this.props.userData.Organization}</div>
              </div>
              <div className='medium-8'>
                <div className='grid-x'>
                  <div className="medium-12">
                    <div className="dropDownHeader">{this.props.content.requestId}</div>
                    <div className="grid-x pt5">
                      <div className='medium-9'>
                        <SelectE
                          className="border-with-DD dropDownContent"
                          model='yourFile.RequestId'
                          id='RequestId'
                          autocomplete='true'
                          placeholder={this.props.content.requestId}
                          value={
                            this.state.RequestIds.length > 0
                              ? this.state.RequestIds.filter(
                                option =>
                                  option.value === this.state.selectedRequestId
                              )
                              : []}
                          onChange={this.handleRequestChange}
                          validators={{ required: validators.required }}
                          validateOn="change"
                          options={this.state.RequestIds}
                          isSearchable={false}
                          noOptionsMessage={() => null}
                        />
                        <Errors
                          className="errors errorsMargin"
                          model="yourFile.RequestId"
                          show="touched"
                          messages={{
                            required: this.props.validation.required
                          }}
                        />
                        <ControlTextE
                          model="yourFile.RequestId"
                          id="yourFile.RequestId1"
                          hidden
                          value={this.state.selectedRequestId}
                          defaultValue={this.state.selectedRequestId}
                          validators={{
                            required: validators.required
                          }}
                          validateOn="change"
                        />
                      </div>
                      <div class='medium-3'>
                        <ButtonE
                          type='submit'
                          content={this.props.content.loadButton}
                          //onClick={this.handleLoadButton}
                          //onClick={() => this.showModal(this.modelRef)}
                          onClick={(formValue) => this.handleLoadButton(formValue)}
                          color
                          disabled={this.props.spendDetails.isLoading}
                        />
                        <Confirmation
                          confirmMessage={this.getMessage("reImportMessage")}
                          OkButtonAction={() => this.getRequestData(this.state.selectedRequestId, true)}
                          CancelButtonAction={() => this.hideModal(this.modelRef)}
                          open={this.state.open}
                          onOpen={this.closePopUp}
                          okButtonFlag={okButtonFlag}
                          ref={this.modelRef} instance={this}
                        />
                      </div>

                    </div>
                    <div className="grid-x messages">
                      <div className="medium-12 pt5">
                        {this.props.spendDetails.error !== null && typeof this.props.spendDetails.error !== 'object' ? <div className="errors">{this.props.spendDetails.error}</div> : ''}
                        {this.props.spendDetails.message !== null && typeof this.props.spendDetails.message !== 'object' ? <div className="success">{this.props.spendDetails.message}</div> : ''}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
        <div className='grid-x pt30'>
          <div className="medium-8 medium-offset-4 section-header">
            {this.props.content.mNcTitle}
          </div>
          <div className=" backgroundColor medium-12 mt5">
            <div className="grid-x grid-padding-x">
              <div className='cell medium-4'>
                <div className="rowMargin">
                  <div className="fieldTitleGeneralContent"> {this.props.content.requester}</div>
                  <div className="fieldValueGeneralContent"> {currentActiveRequest !== undefined && currentActiveRequest.SAPRequester !== undefined ? (currentActiveRequest.SAPRequester) : ''}  </div>
                </div>
                <div className="rowMargin">
                  <div className="fieldTitleGeneralContent"> {this.props.content.processDate}</div>
                  <div className="fieldValueGeneralContent">
                    {currentActiveRequest !== undefined && currentActiveRequest.CREATE_TIMESTAMP !== undefined ? moment(currentActiveRequest.CREATE_TIMESTAMP).format('MMM DD,YYYY') : ''}
                  </div>
                </div>
                <div className="rowMargin">
                  <div className="fieldTitleGeneralContent">{this.props.content.LastEditDate}</div>
                  <div className="fieldValueGeneralContent">
                    {currentActiveRequest !== undefined && currentActiveRequest.UPDATED_TIMESTAMP !== undefined ? moment(currentActiveRequest.UPDATED_TIMESTAMP).format('MMM DD,YYYY') : ''}
                  </div>
                </div>
                <div className="rowMargin">
                  <div className="fieldTitleGeneralContent"> {this.props.content.commentsRequest}</div>
                  <div className="control-wrapper fieldValue">
                    <textarea
                      rows="5"
                      cols="30"
                      type="text" style={{ width: '100%' }}
                      //className = "label"
                      //model='yourFile.commentsRequest'
                      onChange={e => this.onChange(e)}
                      id='yourFile.commentsRequest'
                      //label={this.props.content.commentsRequest}
                      value={currentActiveRequest !== undefined && currentActiveRequest.UPDATED_COMMENTS !== undefined ? currentActiveRequest.UPDATED_COMMENTS : ''}
                    />
                  </div>
                </div>
              </div>
              <div className='cell medium-8'>
                <table className="buyer-table">
                  <thead>
                    <tr>
                      <th> </th>
                      <th className='tableHeader'>{this.props.content.tblCol1}</th>
                      <th className='tableHeader'>{this.props.content.tblCol2}</th>
                      <th className='tableHeader'>{this.props.content.tblCol3}</th>
                      <th className='tableHeader'>{this.props.content.tblCol4}</th>
                      <th className='tableHeader'>{this.props.content.tblCol5}</th>
                      <th> </th>
                    </tr>
                  </thead>

                  <tbody className=" medium-12">
                    {
                      fileDetails(this)
                    }
                  </tbody>
                </table>
                {/* <div className="cell medium-12 rowMargin float-right">
                      <Button
                        type='submit'
                        content={this.props.content.button}                        
                        onClick={(values) => this.handleNextPage(values)}
                        color
                        float
                        disabled={this.props.spendDetails.isLoading}
                      />
                    </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    // language: state.language,
    userData: state.valueStory,
    user: state.default,
    content: state.content.spendAnalysis.yourFile,
    validation: state.content.validation,
    spendDetails: state.spendDetails,
    currencyCodes: state.content.discovery.form.currencyCodes,
    currencySymbols: state.content.CurrencySymbols,
    // validation: state.content.validation
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getYourFileSuccess: message => dispatch(getYourFileSuccess(message)),
    getYourFileFailure: error => dispatch(getYourFileFailure(error)),
    LoadRequestDetails: (requestDetails, isNewRequest) => dispatch(LoadRequestDetails(requestDetails, isNewRequest)),
    getYourRequestsDetails: (email) => dispatch(getYourRequestsDetails(email)),
    getYourFileLoading: bool => dispatch(getYourFileLoading(bool)),
    resetError: error => dispatch(resetError(error)),
    GetAllRequestsAgainstValueStory: (userId, valueStoryId) => dispatch(GetAllRequestsAgainstValueStory(userId, valueStoryId)),
    ActivetDeactiveCurrentRequest: requestDetails => dispatch(ActivetDeactiveCurrentRequest(requestDetails)),
    CleanRequestData: requestDetails => dispatch(CleanRequestData(requestDetails)),
    UpdateComment: requestDetails => dispatch(UpdateComment(requestDetails)),

    currentActiveRequest: (requestDetails) => dispatch(currentActiveRequest(requestDetails)),
    setResultSummaryDetails: data => dispatch(setResultSummaryDetails(data)),
    setTopDetails: data => dispatch(setTopDetails(data)),
    setValueStoryDmciDetails: (data) => dispatch(setValueStoryDmciDetails(data)),
    setYourFileRequestDetails: (requests) => dispatch(setYourFileRequestDetails(requests)),
    getAllMappedColumn: (userId) => dispatch(GetAllMappedColumn(userId)),
    setMappedColumn: (data) => dispatch(setMappedColumn(data)),
    getUserSpendSelections: (VSId, DMCId) => dispatch(getUserSpendSelections(VSId, DMCId)),
    trackUserSpendSelections: (userSelections) => dispatch(trackUserSpendSelections(userSelections)),
    solutionScopeChanged: (val) => dispatch(solutionScopeChanged(val))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(YourFileContainer)
