import React from 'react'
import FaAngleRight from 'react-icons/lib/fa/angle-right'
import FaAngleLeft from 'react-icons/lib/fa/angle-left'
import MdClear from 'react-icons/lib/md/clear'
import classnames from 'classnames'

const ButtonContent = (props) => {
	
	return (
		<div className={classnames('button__inner', { 'button__inner--left-icon' : props.iconPosition === 'left' })}>
			{
				props.disabled && props.showLoader ? <div className="button-loader" /> : <span>{props.content}</span>
			}
		</div>
	)
}

const PopupButton = (props) =>
	<button
		type={props.type}
		onClick={(e) => props.onClick(e)}
		disabled={props.disabled}
		id={props.id}
		value={props.id}
		className={'button popupButton ' + (props.float ? 'float-right ' : '') + (props.color ? 'button--accent ' : '')}>
		<ButtonContent icon={props.icon} content={props.content} disabled={props.disabled} iconPosition={props.iconPosition} showLoader={props.showLoader}/>
	</button>

export default PopupButton

PopupButton.defaultProps = {
	type: 'button',
	onClick: () => {},
	disabled: false,
	icon: 'right-arrow',
	iconPosition: 'right',
	float: false,
	id: '',
	showLoader: true
}
