import * as contentReducers from './content'
import * as discoveryReducers from './discoveryReducers'
import * as valueStoryReducers from './valueStoryReducers'
import * as formReducers from './formReducers'
import * as userReducers from './userReducers'
import * as roleAndPermissionReducers from './roleAndPermissionReducers'
import * as spendReducers from './spendReducers'
import * as calculationReducers from './calculations'
import * as searchReducer from './searchReducer'
import * as kpiReducers from './kpiReducers'
import { combineReducers } from  'redux'

const allReducers = Object.assign({}, 
	contentReducers,
	discoveryReducers,
	valueStoryReducers,
	formReducers,
	userReducers,
	calculationReducers,
	roleAndPermissionReducers,
	spendReducers,
	searchReducer,
	kpiReducers
)
const rootReducer = combineReducers(allReducers)

export default rootReducer
