import React from "react";
import ReactDOM from "react-dom";
import { Editors } from "react-data-grid-addons";
const { SimpleTextEditor } = Editors;

export default class NumericEditor extends SimpleTextEditor {
    constructor(props) {
      super(props);
    }
  
    validate(val) {
      if(Object.values(val)[0] === '') {
        return true;
      }
      
      return !isNaN(Object.values(val)[0])
    }
}