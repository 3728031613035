import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { modalStyle, modalContentStyle, changeModalStyle, changeModalContentStyle } from 'Constants'

import Modal from 'reboron/OutlineModal'
import Button from 'UI/Button'
import TabContainer from './TabContainer';
import { AppBar } from '@material-ui/core';
import {
    pendingChanges
} from 'actions/userActions'

import { connect } from 'react-redux'


const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        borderBottom: "1px solid grey",
        boxShadow: "none"
    },
    indicator: {
        backgroundColor: "#008fd3",
    },
    textColor: {
        // color: "#008fd3",
    },
    paddingTop: {
        paddingTop: "20px"
    },
    tabs: {
        borderLeft: "1px solid grey",
        borderRight: "1px solid grey",
        padding: "10px"
    },
    borderBothSides: {
        borderLeft: "1px solid grey",
        borderRight: "1px solid grey",
    },
    borderLeftSide: {
        borderLeft: "1px solid grey",
    }
});

class TabPanelGeneric extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: props.tabId,
            toggle: true,
            nextTab: 5,
            event: null,
            activeTabCopy: props.tabId
        };
        this.showModal = this.showModal.bind(this)
        this.hideModal = this.hideModal.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }

    showModal(action) {
        this.refs[action].show()
    }

    hideModal(action) {
        this.refs[action].hide()
    }

    closeModal(action) {
        let activeTab = this.state.activeTabCopy
        this.props.pendingChanges(false)
        this.setState({ toggle: false, activeTab: activeTab }, this.refs[action].hide())
        if (this.props.dispatchTabChange) {
            if (this.props.handleNextButton) {
                this.props.handleNextButton(activeTab)
            }
            this.props.dispatchTabChange(activeTab)

        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.pendingChanges) {
            this.setState({ toggle: false })
        }
        else if (!nextProps.pendingChanges) {
            this.setState({ toggle: true })
        }
    }
    handleChange = (event, activeTab) => {
        // if (activeTab == 5) {
        //     this.setState({ toggle: true })
        // }
        if (this.props.isLoading !== undefined && this.props.isLoading === false) {
            if (this.state.toggle) {
                this.setState({ activeTab: activeTab });
                if (this.props.dispatchTabChange) {
                    if (this.props.handleNextButton) {
                        this.props.handleNextButton(activeTab)
                    }
                    this.props.dispatchTabChange(activeTab)

                }
            } else {
                this.setState({ activeTabCopy: activeTab }, this.showModal("terms"));

            }
        }

    };

    render() {
        const { classes } = this.props;
        const { activeTab } = this.state;
        const isLoading = this.props.isLoading
        return (
            <div class="tab-container bottom-space">
                <div className="grid-x">
                    <div className="medium-12 large-12">
                        <AppBar position="static" className={classes.root}>
                            <Tabs
                                value={activeTab}
                                onChange={this.handleChange}
                                classes={{ indicator: classes.indicator }}
                            >
                                {
                                    this.props.tabs.map((tab, i) => {
                                        var disabledTab = isLoading && activeTab === tab.id ? false : isLoading;
                                        return (<Tab
                                            classes={{ label: classes.textColor }}
                                            id={tab.id}
                                            key={tab.id}
                                            label={tab.label}
                                            value={tab.id}
                                            className="tabs-header"
                                            aria-controls={`wrapped-tabpanel-${i}`}
                                            disabled={disabledTab}
                                        />)
                                    })
                                }
                            </Tabs>
                        </AppBar>
                        {
                            this.props.tabs.map(tab => (
                                activeTab === tab.id
                                    ? <TabContainer classStyle={classes.paddingTop} key={tab.id}>{tab.component}</TabContainer>
                                    : null
                            ))
                        }
                    </div>
                </div>
                {this.props.tabs[this.props.tabs.length - 1].id !== activeTab ? 
                    <div className="grid-x">
                        <div className="medium-12 cell float-right">
                            <Button content={this.props.nextButtonLabel} floatMargin0
                                disabled={isLoading ? isLoading : false}
                                onClick={(event) => {
                                    var index = 0
                                    for (; index < this.props.tabs.length; index++) {
                                        if (activeTab === this.props.tabs[index].id) {
                                            break;
                                        }
                                    }
                                    if (index === this.props.tabs.length - 1) {
                                        if (this.props.dispatchLastButtonClick) {
                                            this.props.dispatchLastButtonClick(activeTab)
                                        }
                                    } else {
                                        this.handleChange(event, this.props.tabs[index + 1].id)
                                    }
                                }
                                }
                            />
                        </div>
                    </div> : '' }
                <div>
                    <Modal
                        ref="terms"
                        modalStyle={changeModalStyle}
                        contentStyle={changeModalContentStyle}
                    >
                        <div className="grid-x grid-padding-x change-popup">
                            <div className="medium-12">
                                <button onClick={() => this.hideModal("terms")} className="close" />
                            </div>
                            <div className="medium-12 pt30">
                                {this.props.tabMessage}
                            </div>
                            <div className="medium-12 popupOkBtn">
                                <div className="float-right">
                                    <div className="float-left"><Button
                                        content={"Continue"}
                                        onClick={() => this.closeModal("terms")}
                                        color /></div>
                                    <div className="float-left"><Button
                                        content={"Close"}
                                        onClick={() => this.hideModal("terms")}
                                        color /></div>
                                </div>
                            </div>
                        </div>

                    </Modal>
                </div>
            </div>
        );
    }
}

TabPanelGeneric.propTypes = {
    classes: PropTypes.object.isRequired,
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            id: PropTypes.number.isRequired,
            component: PropTypes.object.isRequired,
        }).isRequired)
        .isRequired,
};

TabPanelGeneric.defaultProps = {
	isLoading: false
}

const mapStateToProps = state => {
    return {
        
    }
}

const mapDispatchToProps = dispatch => {
    return {
        pendingChanges: changes => dispatch(pendingChanges(changes))
    }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(TabPanelGeneric));