import React from 'react'
import { connect } from 'react-redux'
import WorldMap from './WorldMap';
import ColorLegend from '../../UI/colorScale';
import Select from 'react-select'
import {path,  modalStyle, modalContentStyle, changeModalBorderStyle, changeModalBorderRectStyle, drilldownModalBorderContentStyle } from 'Constants'
import {
    getHeatMapData,
    resetError,
    getResultLoading, getResultSuccess, getResultFailure, getWorldMapSuppliersDetails
} from 'actions/spendActions'
import VideoHelp from 'components/UI/VideoHelp'
import Popup from "reactjs-popup";
import Modal from 'reboron/OutlineModal'
import WorldMapSupplierDetailsPopup from './WorldMapSupplierDetailsPopup'

function getScales(minRange, maxRange, min, max) {
    var scales = [],                  // Prepare some variables
        ranges = maxRange - minRange,   // Amount of elements to be returned.
        range = (max - min) / ranges;        // Difference between min and max
    for (var i = 0; i < ranges; i++) {
        scales.push(min + range * (i + 1))
    }
    // console.log("Scale", scales)
    return scales;
}

class WorldMapContainer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            dropDownValues: [
                { value: "AGGR_INV_SPEND", label: "Invoice Spend" },
                { value: "AGGR_INV_CNT", label: "Invoice Count" },
                { value: "AGGR_PO_SPEND", label: "PO Spend" },
                { value: "AGGR_PO_CNT", label: "PO Count" },
                { value: "VENDOR_ID_CNT", label: "Vendor Count" }
            ],
            selectedValue: 'AGGR_INV_SPEND',
            countryData: [],
            domain: [],
            range: [],
            isLoading: true,
            inputParam: null
        }
        this.loadData = this.loadData.bind(this);
        this.randomBetween = this.randomBetween.bind(this);
        this.randomBetweenRange = this.randomBetweenRange.bind(this);
        this.showLoading = this.showLoading.bind(this);        
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
    }
    showLoading(value) {
        if (!value)
            this.props.getResultSuccess()
        this.setState({ isLoading: value })
    }

    showModal(action) {
        this.refs[action].show()
    }

    hideModal(action) {
        this.refs[action].hide()
    }

    randomBetween = (a, b) => {
        return ((Math.random() * (b - a)) + a).toFixed(0);
    };

    randomBetweenRange = (num, range) => {
        const res = [];
        for (let i = 0; i < num;) {
            const random = this.randomBetween(range[0], range[1]);
            if (!res.includes(random)) {
                res.push(random);
                i++;
            };
        };
        return res;
    };

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        let dmcId = this.props.spendDetails.currentActiveRequest.DMC_ID;
        let vsID = this.props.spendDetails.currentActiveRequest.VS_ID;
        let userEmail = this.props.user.user.Email;
        this.props.getResultLoading(true);
        this.props.getHeatMapData(dmcId, vsID, userEmail).then((response) => {
            try {
                let result = response.data.Result;
                this.setState({ countryData: response.data.Result })
                //Get max and min value records
                let maxResult = result.reduce(function (prev, current) {
                    return (prev.AGGR_INV_SPEND > current.AGGR_INV_SPEND) ? prev : current
                })
                let minResult = result.reduce(function (prev, current) {
                    return (prev.AGGR_INV_SPEND < current.AGGR_INV_SPEND) ? prev : current
                })
                let max = Math.round(maxResult.AGGR_INV_SPEND);
                let min = Math.round(minResult.AGGR_INV_SPEND);

                if (min == 0 && max == 0) {
                    this.showLoading(false)
                    this.setState({ errorMessage: "The selected focus has no data", isLoading: false, domain: [0, 0], range: [0, 0] });
                } else {
                    let range = [min, max * 2];

                    //create domain range for charts
                    // let domain = this.randomBetweenRange(6, range);
                    // domain.sort(function (a, b) { return a - b });
                    let domain = getScales(0, 6, min, max);
                    this.setState({ countryData: response.data.Result, domain: domain, range: range, errorMessage: '' })
                    // console.log("random number domain", domain);
                }

            }
            catch (e) {
                this.showLoading(false)
            }
        })
            .catch((response) => {
                this.props.getResultFailure("Request data not found");
                this.setState({ isLoading: false })
            })
    }

    handleChange(data) {

        let value = data.value;
        let result = this.state.countryData;
        if (value != this.state.selectedValue) {
            this.setState({ isLoading: true })
            try {
                let maxResult = result.reduce(function (prev, current) {
                    return (prev[value] > current[value]) ? prev : current
                })
                let minResult = result.reduce(function (prev, current) {
                    return (prev[value] < current[value]) ? prev : current
                })
                let max = Math.round(maxResult[value]);
                let min = Math.round(minResult[value]);
                if (min == 0 && max == 0) {
                    this.showLoading(false)
                    // this.setState({ selectedValue: value })
                    this.setState({ errorMessage: "The selected focus has no data", selectedValue: value, domain: [0, 0], range: [0, 0] });
                } else {
                    let range = [min, max * 2];
                    // let domain = this.randomBetweenRange(6, range);
                    // domain.sort(function (a, b) { return a - b });
                    let domain = getScales(0, 6, min, max);
                    this.setState({ selectedValue: value, domain: domain, range: range, errorMessage: '' })
                }

            }
            catch (e) {
                this.setState({ isLoading: false })
            }
        }
    }

    onDoubleClick(data) {
        console.log(data)
        let dmcId = this.props.spendDetails.currentActiveRequest.DMC_ID;
        let vsID = this.props.spendDetails.currentActiveRequest.VS_ID;
        let userEmail = this.props.user.user.Email;
        let country = data.id;
        let inputData = {
            DMC_ID: dmcId, VS_ID: vsID, USER_ID: userEmail,
            ISO_COUNTRY_CODE: country
        }

        this.props.getWorldMapSuppliersDetails(inputData)
            .then((response) => {
                this.setState({ supplierData: response.data.Result, inputParam: inputData }, this.showModal("taskSuppliers"))
            }).catch((error) => {
                if (error.stack.includes('Network Error')) {
                    this.setState({ responseMsg: 'API service is down. Please contact administrator', isError: 'Yes' }, this.showModal("taskSuppliers"))
                }
                else {
                    this.setState({ responseMsg: 'An error has occurred', isError: 'Yes' }, this.showModal("taskSuppliers"))
                }
            })
    }

    render() {
        return (
            <div>
                <div className='grid-x grid-padding-x'>
                    <div className="medium-12">
                        <div className="tab-title">{this.props.content.title}{<VideoHelp screen={{ module: 'Spend', screenName: 'World Map' }} />}</div>
                        <div className="tab-description">{this.props.content.subTitle}</div>
                        <div className="tab-description">{this.props.content.note}</div>
                    </div>
                    <div style={{ marginTop: '10px' }} className='medium-5'>
                        <div className="float-left width170">
                            <div className="dropDownHeader">{this.props.focusLabel}</div>
                            <Select
                                className="border-with-DD dropDownContent"
                                autocomplete='true'
                                //placeholder={this.props.content.notConfirmed}
                                value={this.state.dropDownValues.filter(
                                    option => option.value === this.state.selectedValue
                                )}
                                onChange={value => this.handleChange(value)}
                                options={this.state.dropDownValues}
                                isSearchable={true}
                                isDisabled={this.state.isLoading}
                            />
                        </div>
                        <div style={{ width: '25px' }} className="pl5 float-left">
                            <Popup className="report-tooltip"
                                trigger={<img style={{ paddingTop: '10px' }} className="info-icon" src="images/info-icon.svg" width="15" />}
                                position="top left"
                                on="hover"
                            >
                                {this.props.content.info}
                            </Popup>
                        </div>
                    </div>
                    <div className="medium-2">
                        {(this.state.isLoading) ?
                            <img style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={path + `images/ajax-loader.gif`} alt="" />
                            : null
                        }
                    </div>
                    <div style={{ marginTop: '-40px' }} className='medium-5'>
                        {<ColorLegend className='colorLegend' dValue={this.state.selectedValue} countryData={this.state.countryData} domain={this.state.domain} range={this.state.range} />}
                    </div>
                </div>
                {/* <div className='grid-x wm-note'>{this.props.content.note}</div> */}
                <div className="errors">{this.state.errorMessage}</div>
                <WorldMap
                    dValue={this.state.selectedValue}
                    countryData={this.state.countryData}
                    domain={this.state.domain}
                    range={this.state.range}
                    currency={this.props.currencySymbols[this.props.spendDetails.currentActiveRequest.Currency !== undefined ? this.props.spendDetails.currentActiveRequest.Currency.trim() : 'USD']}
                    showLoading={this.showLoading}
                    onDoubleClick={e => this.onDoubleClick(e)} />
                <div>
                    <Modal
                        ref="taskSuppliers"
                        // modalStyle={modalStyle}
                        // contentStyle={modalContentStyle}
                        modalStyle={changeModalBorderStyle}
                        contentStyle={drilldownModalBorderContentStyle}
                        rectStyle={changeModalBorderRectStyle}>
                        <div className="grid-x grid-padding-x change-popup">
                            <div className="medium-12">
                                <button onClick={() => this.hideModal("taskSuppliers")} className="close" />
                            </div>

                            <div className="medium-12">
                                {this.state.supplierData !== undefined && this.state.supplierData !== null ?
                                    <WorldMapSupplierDetailsPopup
                                        input={this.state.inputParam}
                                        suppliersData={this.state.supplierData}
                                        content={this.props.content}
                                        // onSave={this.onSave}
                                    />
                                    : <div className="pt10">{this.props.content.noDataFound}</div>}
                            </div>
                        </div>
                    </Modal>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        userData: state.valueStory,
        user: state.default,
        content: state.content.spendAnalysis.worldMap,
        focusLabel: state.content.spendAnalysis.enablement.focusLabel,
        spendDetails: state.spendDetails,
        currencySymbols: state.content.CurrencySymbols,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getResultSuccess: message => dispatch(getResultSuccess(message)),
        getResultFailure: error => dispatch(getResultFailure(error)),
        getResultLoading: bool => dispatch(getResultLoading(bool)),
        resetError: error => dispatch(resetError(error)),
        getHeatMapData: (DMC_ID, VS_ID, User_ID) => dispatch(getHeatMapData(DMC_ID, VS_ID, User_ID)),
        getWorldMapSuppliersDetails: (data) => dispatch(getWorldMapSuppliersDetails(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorldMapContainer)
