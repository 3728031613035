import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Modal from 'reboron/OutlineModal'
import Button from 'UI/Button'
import TabContainer from './TabContainer';
import { AppBar } from '@material-ui/core';
import { connect } from 'react-redux'
import { changeModalStyle, changeModalContentStyle, backdropStyle } from 'Constants'
import { trackSupplierDrillDownChanges } from 'actions/spendActions'

const changeBackdropStyle = Object.assign({}, backdropStyle);
changeBackdropStyle.zIndex = '1050';

const alertModalStyle = Object.assign({}, changeModalStyle);
alertModalStyle.zIndex = '1060';
alertModalStyle.width = '33%';

const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        borderBottom: "1px solid grey",
        boxShadow: "none"
    },
    indicator: {
        backgroundColor: "#008fd3",
    },
    textColor: {
        // color: "#008fd3",
    },
    paddingTop: {
        paddingTop: "20px"
    },
    tabs: {
        borderLeft: "1px solid grey",
        borderRight: "1px solid grey",
        padding: "10px"
    },
    borderBothSides: {
        borderLeft: "1px solid grey",
        borderRight: "1px solid grey",
    },
    borderLeftSide: {
        borderLeft: "1px solid grey",
    }
});

class SupplierDrillDownTabPanel extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: props.tabId,
            toggle: false,
            event: null,
            activeTabCopy: props.tabId
        };
        this.showModal = this.showModal.bind(this)
        this.hideModal = this.hideModal.bind(this)
        this.continueNavigation = this.continueNavigation.bind(this)

    }

    handleChange = (event, activeTab) => {
        if (this.props.supplierDrilldownPendingChanges !== undefined && this.props.supplierDrilldownPendingChanges !== null
            && this.props.supplierDrilldownPendingChanges === true) {
            this.setState({ activeTabCopy: activeTab }, this.showModal("alert"));
        } else {
            this.setState((state) => ({ activeTab }));
            if (this.props.dispatchTabChange) {
                if (this.props.handleNextButton) {
                    this.props.handleNextButton(activeTab)
                }
                this.props.dispatchTabChange(activeTab)
            }
        }
    };

    showModal(action) {
        this.refs[action].show()
    }

    hideModal(action) {
        this.refs[action].hide()
    }

    continueNavigation(action) {
        let activeTab = this.state.activeTabCopy
        this.props.trackSupplierDrillDownChanges(false)
        this.setState({ toggle: false, activeTab: activeTab }, this.refs[action].hide())
        if (this.props.dispatchTabChange) {
            if (this.props.handleNextButton) {
                this.props.handleNextButton(activeTab)
            }
            this.props.dispatchTabChange(activeTab)
        }
    }

    render() {
        const { classes } = this.props;
        const { activeTab } = this.state;
        const _state = this.props.spendDetails
        return (
            <div class="tab-container">
                {
                    this.props.headerText ?
                        <div className="tab-container-header">{this.props.headerText}</div> : null
                }
                <div className="grid-x">
                    <div className="medium-12 large-12">
                        <AppBar position="static" className={classes.root}>
                            <Tabs
                                value={activeTab}
                                onChange={this.handleChange}
                                classes={{ indicator: classes.indicator }}
                            >
                                {
                                    this.props.tabs.map((tab, i) => {
                                        var disabledTab = _state.isPopUpTabLoading && activeTab === tab.id ? false : _state.isPopUpTabLoading;
                                        return (<Tab
                                            classes={{ label: classes.textColor }}
                                            id={tab.id}
                                            key={tab.id}
                                            label={tab.label}
                                            value={tab.id}
                                            className="tabs-header"
                                            aria-controls={`wrapped-tabpanel-${i}`}
                                            disabled={disabledTab}
                                        />)
                                    })
                                }
                            </Tabs>
                        </AppBar>
                        {
                            this.props.tabs.map(tab => (
                                activeTab === tab.id
                                    ? <TabContainer classStyle={classes.paddingTop} key={tab.id}>{tab.component}</TabContainer>
                                    : null
                            ))
                        }
                    </div>
                </div>
                {
                    this.props.nextButtonLabel && this.props.nextButtonLabel != "" ?
                        (
                            <div className="grid-x">
                                <div className="medium-12 cell float-right">
                                    <Button content={this.props.nextButtonLabel} float
                                        disabled={this.props.spendDetails !== undefined ? this.props.spendDetails.isLoading : false}
                                        onClick={(event) => {
                                            var index = 0
                                            for (; index < this.props.tabs.length; index++) {
                                                if (activeTab === this.props.tabs[index].id) {
                                                    break;
                                                }
                                            }
                                            if (index === this.props.tabs.length - 1) {
                                                if (this.props.dispatchLastButtonClick) {
                                                    this.props.dispatchLastButtonClick(activeTab)
                                                }
                                            } else {
                                                this.handleChange(event, this.props.tabs[index + 1].id)
                                            }
                                        }
                                        }
                                    />
                                </div>
                            </div>
                        ) : null
                }
                <Modal
                    ref="alert"
                    modalStyle={alertModalStyle}
                    contentStyle={changeModalContentStyle}
                    backdropStyle={changeBackdropStyle}
                >
                    <div className="grid-x grid-padding-x change-popup">
                        <div className="medium-12">
                            <button onClick={() => this.hideModal("alert")} className="close" />
                        </div>
                        <div className="medium-12 pt30">
                            {this.props.tabMessage}
                        </div>
                        <div className="medium-12 popupOkBtn">
                            <div className="float-right">
                                <div className="float-left"><Button
                                    content={"Continue"}
                                    onClick={() => this.continueNavigation("alert")}
                                    color /></div>
                                <div className="float-left"><Button
                                    content={"Close"}
                                    onClick={() => this.hideModal("alert")}
                                    color /></div>
                            </div>
                        </div>
                    </div>
                </Modal>

            </div>
        );
    }
}

SupplierDrillDownTabPanel.propTypes = {
    classes: PropTypes.object.isRequired,
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            id: PropTypes.number.isRequired,
            component: PropTypes.object.isRequired,
        }).isRequired)
        .isRequired,
};

const mapStateToProps = state => {
    return {
        supplierDrilldownPendingChanges: state.spendDetails.supplierDrilldownPendingChanges,
        tabMessage: state.content.spendAnalysis.detailedFile.toggleTabMessage,
        spendDetails: state.spendDetails,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        trackSupplierDrillDownChanges: changes => dispatch(trackSupplierDrillDownChanges(changes))
    }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(SupplierDrillDownTabPanel));