import React from 'react'
import PropTypes from 'prop-types'

// UI
import Radio from 'react-mdl/lib/Radio'
import RadioGroup from 'react-mdl/lib/RadioGroup'

const Priority = (props) => {
	const MyChildContainer = props => (<div className="medium-2 cell">{props.children}</div>)

	return (
		// <div>
		// 	<RadioGroup className="grid-x radio-buttons__row"
		// 		onChange={(e) => props.dispatchRadioButtonChange(props.subIndex, e.target.value) }
		// 		name={props.id + '.' + props.subIndex} 
		// 		value={
		// 			props.userData[parseInt(props.subIndex)].Value ? (props.userData[parseInt(props.subIndex)].Value).toString() : 0
		// 		}
		// 		childContainer={MyChildContainer}>
		// 			<Radio value="1" />
		// 			<Radio value="2" />
		// 			<Radio value="3" />
		// 			<Radio value="4" />
		// 			<Radio value="5" />
		// 	</RadioGroup>
		// </div>
		<div>
			<RadioGroup className="grid-x radio-buttons__row"
				onChange={(e) => props.dispatchRadioButtonChange(props.userData[parseInt(props.subIndex)].Item, props.userData[parseInt(props.subIndex)].SubItem, props.subIndex, e.target.value, props.jsonSubIndex)}
				name={props.id + '.' + props.subIndex}
				value={
					props.userData[parseInt(props.subIndex)].Value ? (props.userData[parseInt(props.subIndex)].Value).toString() : 0
				}
				childContainer={MyChildContainer}>
				<Radio value="1" />
				<Radio value="2" />
				<Radio value="3" />
				<Radio value="4" />
				<Radio value="5" />
			</RadioGroup>
		</div>
	)
}

export default Priority

Priority.propTypes = {
	dispatchRadioButtonChange: PropTypes.func.isRequired,
	id: PropTypes.number.isRequired,
	subIndex: PropTypes.string.isRequired,
	userData: PropTypes.array.isRequired
}
