export const contentHasErrored = (state = false, action) => {
	switch (action.type) {
		case 'CONTENT_HAS_ERRORED':
			return action.hasErrored
		
		default:
			return state
	}
}

export const contentIsLoading = (state = true, action) => {
	switch (action.type) {
		case 'CONTENT_IS_LOADING':
			// Prevent FOUC
			if(state === false) {
				setTimeout(() => {
					return action.isLoading
				}, 1000)
			} else {
				return action.isLoading
			}
			return state
			
		default:
			return state
	}
}

export const content = (state = [], action) => {
    switch (action.type) {
        case 'CONTENT_FETCH_DATA_SUCCESS':
            return action.content
			
        default:
            return state
    }
}

export const interest = (state = '', action) => {
	switch(action.type) {
		case 'ADD_INTEREST':
			return action.interest

		default:
			return state
	}
}

export const selectedValueDriver = (state = 0, action) => {
	switch(action.type) {
		case 'ADD_SELECTED_VALUEDRIVER':
			return action.id

		default:
			return state
	}
}

export const goToChoosePath = (state = false, action) => {
	switch(action.type) {
		case 'GO_TO_CHOOSE_PATH':
			return action.payload

		default:
			return state
	}
}

//Start PEs Section
export const contentPEHasErrored = (state = false, action) => {
	switch (action.type) {		
		case 'CONTENT_PE_HAS_ERRORED':
			return action.hasErrored

		default:
			return state
	}
}

export const contentPEIsLoading = (state = true, action) => {
	switch (action.type) {
		case 'CONTENT_PE_IS_LOADING':			
			if(state === false) {
				setTimeout(() => {
					return action.isLoading
				}, 1000)
			} else {
				return action.isLoading
			}
			return state
			
		default:
			return state
	}
}

export const contentPE = (state = [], action) => {
    switch (action.type) {
        case 'CONTENT_PE_FETCH_DATA_SUCCESS':
			return action.contentPE
			
        default:
            return state
    }
}
//End PEs Section

//Start File Upload Section
export const tableSchemaHasErrored = (state = false, action) => {
	switch (action.type) {		
		case 'TABLE_SCHEMA_HAS_ERRORED':
			return action.hasErrored

		default:
			return state
	}
}

export const tableSchemaIsLoading = (state = true, action) => {
	switch (action.type) {
		case 'TABLE_SCHEMA_IS_LOADING':			
			if(state === false) {
				setTimeout(() => {
					return action.isLoading
				}, 1000)
			} else {
				return action.isLoading
			}
			return state
			
		default:
			return state
	}
}

export const tableSCHEMA = (state = [], action) => { 
    switch (action.type) { 
        case 'TABLE_SCHEMA_FETCH_DATA_SUCCESS':
			return action.tableSCHEMA 
			
        default: 
            return state
    }
}
//End File Upload Section