import React from 'react'

// UI
import GoCheck from 'react-icons/lib/go/check'
import GoPlus from 'react-icons/lib/go/plus'

const AddIcon = (props) =>
	
	<div
		className={props.active ? 'check-icon add-icon-bg' : 'plus-icon add-icon-bg'}
		onClick={(e) => {
			e.preventDefault()
			props.toggle(props.id, !props.active)}
		}>
	</div>

export default AddIcon