import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

// UI
import Button from 'UI/Button'

const NextStep = (props) =>
	<div className="next-step medium-3">
		<div className="next-step__text">
			<div className="next-step-icon">
				<img src={props.icon} />
			</div>
			<div className={"next-step-cell " + (props.index < 3 ? 'border' : '')}>
				<div className="title">
					{props.content.title}
				</div>
				<div className="description">
					{props.content.description}
				</div>
				<div className="next-step-btn">
					{(props.index === 0 || props.index === 1) ? (
						<Button content={props.content.cta} onClick={() => props.showModal(props.content.action)} color={props.color} />
					) : (
							<a href={props.content.ctaURL} rel="noopener noreferrer" target="_blank">
								<Button content={props.content.cta} />
							</a>
						)
					}
				</div>
			</div>
		</div>
	</div >

export default NextStep

NextStep.propTypes = {
	icon: PropTypes.string.isRequired,
	content: PropTypes.shape({
		title: PropTypes.string.isRequired,
		description: PropTypes.string.isRequired,
		cta: PropTypes.string.isRequired,
		ctaURL: PropTypes.string,
		action: PropTypes.string
	}),
	color: PropTypes.bool.isRequired,
	index: PropTypes.number.isRequired
}