import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

// Actions
import { updateTotalBenefit, updateBenefitRealizationFactor } from 'actions/calculateROIActions'

// Components
import BenefitRealizationFactor from './BenefitRealizationFactor'
import BusinessValueFromSAPAribaTableRows from './BusinessValueFromSAPAribaTableRows'

// Utils
import { round } from 'utils/Utils'

// UI
import Button from 'UI/Button'

class BusinessValueFromSAPAribaContainer extends PureComponent {

	constructor(props) {
		super(props)

		this.state = {
			render: false,
			estimatedAnnualValues: this.getEstimatedAnnualValues(),
			benefitRealizationFactors: [],
			totalAnnualBenefits: []
		}

		this.getTotalAnnualBenefits = this.getTotalAnnualBenefits.bind(this)
		this.getBenefitRealizationFactors = this.getBenefitRealizationFactors.bind(this)
		this.handleInputChange = this.handleInputChange.bind(this)
	}

	getBenefitRealizationFactors() {
		let benefitRealizationFactors = []

		for (let i = 0; i < 5; i++) {
			benefitRealizationFactors.push(this.props.businessValueFromSAPAribaData['Year' + (i + 1)])
		}

		return benefitRealizationFactors
	}

	getEstimatedAnnualValues() {
		let estimatedAnnualValues = []

		this.props.userData.ValueDriver.forEach((data) =>
			data.SubLever.forEach((data) =>
				// return Est Annual Value if Sub Lever is selected
				data.Selected ? estimatedAnnualValues.push(round(data.EstAnnualValue, 2)) : 0
			))

		return estimatedAnnualValues
	}

	getTotalAnnualBenefits() {
		let totalAnnualBenefits = [],
			estimatedAnnualValues = this.getEstimatedAnnualValues(),
			benefitRealizationFactors = this.getBenefitRealizationFactors()

		benefitRealizationFactors.forEach((percentage, i) => {
			let totalAnnualBenefit = 0

			estimatedAnnualValues.forEach((value) => {
				totalAnnualBenefit += value
			})

			totalAnnualBenefit = totalAnnualBenefit * percentage / 100
			totalAnnualBenefits.push(totalAnnualBenefit)

			// Update Total Benefit in Redux
			this.props.updateTotalBenefit(totalAnnualBenefit, 'Year' + (i + 1))
		})

		return totalAnnualBenefits
	}

	handleInputChange(percentage, index) {
		let benefitRealizationFactors = this.state.benefitRealizationFactors
		benefitRealizationFactors[index] = percentage

		// Update Benefit Realization Factor in Redux
		this.props.updateBenefitRealizationFactor(percentage, 'Year' + (index + 1))

		setTimeout(() => {
			const totalAnnualBenefits = this.getTotalAnnualBenefits()

			this.setState({
				benefitRealizationFactors: benefitRealizationFactors,
				totalAnnualBenefits: totalAnnualBenefits
			})
		}, 1)
	}

	shouldComponentUpdate(nextProps, nextState) {
		return nextState.render ? true : false
	}

	componentWillMount() {
		window.scrollTo(0, 0)

		const totalAnnualBenefits = this.getTotalAnnualBenefits()
		const benefitRealizationFactors = this.getBenefitRealizationFactors()

		setTimeout(() => {
			this.setState({
				totalAnnualBenefits: totalAnnualBenefits,
				benefitRealizationFactors: benefitRealizationFactors
			})

			this.setState({ render: true })
		}, 1)
	}

	render() {
		return (
			<div className="business-value-sap-ariba medium-12 large-12">
				<div className="calculate-roi__table grid-x business-value-cont">
					<div className="medium-12">
						<div className="tab-title medium-12">{this.props.content.title}</div>
						<div className="tab-description">{this.props.content.description}</div>

						<BenefitRealizationFactor
							content={this.props.content.table}
							benefitRealizationFactors={this.state.benefitRealizationFactors}
							handleInputChange={this.handleInputChange}
							totalAnnualBenefits={this.state.totalAnnualBenefits}
							currency={this.props.currency}
						/>
						<BusinessValueFromSAPAribaTableRows
							content={this.props.content.table}
							valueDriversContent={this.props.businessValueContent.valueDrivers}
							subLeversContent={this.props.businessValueContent.subLevers}
							userData={this.props.userData.ValueDriver}
							benefitRealizationFactors={this.state.benefitRealizationFactors}
							totalAnnualBenefits={this.state.totalAnnualBenefits}
							currency={this.props.currency}
						/>

					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		content: state.content.businessValueFromSAPAriba,
		businessValueFromSAPAribaData: state.valueStory.BusinessValueFromSap[0],
		userData: state.valueStory.BusinessValueToYou[0],
		businessValueContent: state.content.businessValue,
		currency: decodeURIComponent(state.valueStory.Currency)
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		updateTotalBenefit: (value, year) => dispatch(updateTotalBenefit(value, year)),
		updateBenefitRealizationFactor: (value, year) => dispatch(updateBenefitRealizationFactor(value, year))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(BusinessValueFromSAPAribaContainer)
