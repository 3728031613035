// Store
import { store } from 'index'

// Actions
import {
	toggleValueDriver,
	toggleSubLever
} from 'actions/valueDriverActions'

import { getSubLeverIdObject } from 'utils/Utils'

// Settings
import { industryBusinessMatrix, LAC } from 'Constants'

export const subLeverChange = (subQuestion, arr, checkMappedPriorities = false) => {
	arr.forEach(data => {

		let state = store.getState(),
			userData = state.valueStory

		let subLeverSwitch = null,
			valueDriverSwitch = false,
			subLeverIdObject

		// // Check values of all mapped priorities levels for the Sub Lever
		// subLeverSwitch = data.mappedPriorities.some((e) => {
		// 	// Turn Sub Lever on if any mapped priority is above "High"(>= 4, index starts from 1) and the Area Of Interest it falls under has been selected by the user
		// 	if (userData.AreaOfInterest[e.prioritiesId].Selected) {
		// 		return userData.AreaOfInterest[e.prioritiesId].AOIValueDrivers[e.priorityIndex].Value >= 4
		// 	}
		// })

		// // Check values of all mapped priorities levels for the Sub Lever
		// subLeverSwitch = data.mappedPriorities.some((e) => {
		// 	// Turn Sub Lever on if any mapped priority is above "High"(>= 4, index starts from 1) and the Area Of Interest it falls under has been selected by the user

		// 	var index = 0
		// 	if (userData.AreaOfInterest[e.prioritiesId].Selected) {
		// 		if (subQuestion !== '') {
		// 			userData.AreaOfInterest[e.prioritiesId].AOIValueDrivers.map((data, x) => {
		// 				if (data.SubItem == subQuestion) {
		// 					index = x
		// 				}
		// 			})
		// 			return userData.AreaOfInterest[e.prioritiesId].AOIValueDrivers[index].Value >= 4
		// 		}
		// 		else {
		// 			return userData.AreaOfInterest[e.prioritiesId].AOIValueDrivers[e.priorityIndex].Value >= 4
		// 		}
		// 	}
		// })

		if (!checkMappedPriorities) {
			// Reset subLeverSwitch value to null if not true
			if (subLeverSwitch !== true) subLeverSwitch = null
		}

		subLeverIdObject = getSubLeverIdObject(data)

		// Do not turn on Reduce Inventory Levels,
		// Extend Days Payable Outstanding or
		// Reduce Cost Of Processing payments sub levers
		// if selected Industry/Location matches
		if ((!industryBusinessMatrix[userData.Industry].extendDaysPayableOutstanding
			&& data === '4.3'/* && subLeverSwitch != true*/) ||
			(!industryBusinessMatrix[userData.Industry].reduceInventoryLevels
				&& data === '4.4'/* && subLeverSwitch != true*/)
			/*||(LAC.indexOf(userData.Location) > -1 && data.id === '3.3')*/
		) {
			subLeverSwitch = false
		}

		// Dispatch to Redux
		if (subLeverSwitch !== null) store.dispatch(toggleSubLever(subLeverIdObject, subLeverSwitch))


		setTimeout(() => {
			// Update userData
			let state = store.getState(),
				userData = state.valueStory

			// Check selected state of sibling Sub Levers and mapped Sub Lever
			// If none are selected, set selected state of parent Value Driver to false
			let valueDriverSwitch;
			if (userData.BusinessValueToYou != undefined) {
				valueDriverSwitch = userData.BusinessValueToYou[0].ValueDriver[subLeverIdObject.BVUIdIndex].SubLever.some((e) => {
					return e.Selected === true || subLeverSwitch
				})
				// Dispatch to Redux
				store.dispatch(toggleValueDriver(subLeverIdObject.BVUIdIndex, valueDriverSwitch))
			}

		}, 1)
	})
}