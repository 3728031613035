import * as d3 from 'd3'
import { convertToMillion, formatThousands } from 'utils/Utils'

const KPILineChart = (drawXY, data, fillColor, value, element, currency, Ylabel, YMaxValue) => {
    if (data !== undefined) {
        var windowWidth = 1280;
        var windowHeight = 300;

        var margin = { top: 40, right: 0, bottom: 10, left: 70 }
        var svg = d3.select("#" + element.value)
            //.attr("viewBox", '0 0 1050 350')
            .attr("width", windowWidth)
            .attr("height", windowHeight)
            .attr('preserveAspectRatio', 'xMinYMin')
            .append("g")
            .attr("transform",
                "translate(" + margin.left + "," + margin.top + ")");

        var x = d3.scaleBand()
            .range([0, windowWidth])
            .padding(1)
            .domain(data.map(function (d) {
                return d.QUARTER_NO;
            }));
        
            // var c1= d3.max(data, function (d) { return d["KPI_VALUE"]; });            
            // var maxValue=c1;
            var maxValue=YMaxValue;
            
        var y = d3.scaleLinear()
            .domain([0,maxValue]) // Put here maximum value from all data
            .range([windowHeight, 0]).nice();

        if (drawXY) {

            svg.append("g")
                .attr("class", "axis")
                .attr("fill", "#666")
                //.style("font-weight", 600)
                .call(d3.axisLeft(y).ticks(5,"s").tickFormat(function(d){
                    return element.value === 'KPI_SPEND' ? currency + convertToMillion(d, true) : formatThousands(d);
                }))
                .append("text")
                .attr("transform", "rotate(-90)")
                .attr("y", 0 - 60)
                .attr("x", 0 - (windowHeight / 2))
                .attr("dy", "1em")
                .style("text-anchor", "middle")
                .text(Ylabel);

            svg.append("g")
                .attr("class", "axis")
                .attr("fill", "#666")
                //.style("font-weight", 600)
                .attr("transform", "translate(0," + windowHeight + ")")
                .call(d3.axisBottom(x).tickFormat(function (d) {
                    return d;
                }))

        }

        // Add the line
        svg.append("path")
            .datum(data)
            .attr("fill", "none")
            .attr("stroke", fillColor)
            .attr("stroke-width", 1.5)
            .attr("d", d3.line()            
                .x(function (d) {
                    return x(d["QUARTER_NO"])
                })
                .y(function (d) {
                    return y(d[value])
                })
                .defined(function (d) { return d[value] !== 0 && d[value] !== null; })
            )

        // create a tooltip
        var Tooltip = d3.select("#" + element.value)
        .append("div")
        .style("opacity", 1)
        .attr("class", "tooltip")
        .style("background-color", "white")
        .style("border", "solid")
        .style("border-width", "2px")
        .style("border-radius", "5px")
        .style("padding", "5px")

        // Three function that change the tooltip when user hover / move / leave a cell
        var mouseover = function(d) {
          Tooltip
            .style("opacity", 1)
        }
        var mousemove = function(d) {
          Tooltip
            .html("Exact value: " + d[value])
            .style("left", (d3.mouse(this)[0]+70) + "px")
            .style("top", (d3.mouse(this)[1]) + "px")
        }
        var mouseleave = function(d) {
          Tooltip
            .style("opacity", 0)
        }

        // Add the points
        svg.append("g")
            .selectAll("dot")
            .data(data)
            .enter()
            .append("circle")
            .attr("class", "dot")
            .attr("cx", function (d) {
                return x(d["QUARTER_NO"])
            })
            .attr("cy", function (d) {
                return y(d[value])
            })            
            .attr("r", 5)
            .attr("fill", fillColor)
            .on("mouseover", mouseover)
            .on("mousemove", mousemove)
            .on("mouseleave", mouseleave)
            .style("display", function(d) { return (d[value] === 0 || d[value] === null) ? "none" : null; })

            
        
        // Add data point text    
        // svg.append("g")
        //     .attr("fill", "#666")
        //     .selectAll("g")
        //     .data(data)
        //     .join("g")
        //     .attr("transform",
        //      function (d) {
        //          return value === 'KPI_VALUE'? `translate(${x(d["QUARTER_NO"])}, ${y(d[value]) - 10 })` 
        //          : value === 'ESTIMATED_VALUE' ? `translate(${x(d["QUARTER_NO"])}, ${y(d[value]) + 15})` : `translate(${x(d["QUARTER_NO"]) - 10}, ${y(d[value]) - 10})` })
        //     .attr("opacity", 1).append("text")
        //     .style("font-size", "0.625rem")
        //     .style("font-family", "BentonSans, Arial, Helvetica, sans-serif")
        //     .text(function (d) {
        //         return element.value === 'KPI_SPEND' ? currency + convertToMillion(d[value], true) : formatThousands(Math.round(d[value]));
        //     });

        svg.append("text")            
            .attr("x", (windowWidth / 2))             
            .attr("y", (-20))
            .attr("text-anchor", "middle")  
            .style("font-size", "0.875rem")        
            .attr("fill",'currentcolor') 
            .style("font-family", "BentonSans, Arial, Helvetica, sans-serif")
            .text(element.header);

        // For legend
        // switch (value) {            
        //     case 'ESTIMATED_VALUE':
        //         svg.append("circle").attr("cx", 260).attr("cy", windowHeight + 40).attr("r", 6).style("fill", fillColor)
        //         svg.append("text").attr("x", 280).attr("y", windowHeight + 40).attr("class", "axis").style("font-size", "0.625rem").style("font-family", "BentonSans, Arial, Helvetica, sans-serif").text(element.estLegend).attr("fill", "#666").attr("alignment-baseline", "middle")
        //         break
        //     case 'ACTUAL_VALUE':
        //         svg.append("circle").attr("cx", 380).attr("cy", windowHeight + 40).attr("r", 6).style("fill", fillColor)
        //         svg.append("text").attr("x", 400).attr("y", windowHeight + 40).attr("class", "axis").style("font-size", "0.625rem").style("font-family", "BentonSans, Arial, Helvetica, sans-serif").text(element.actualLegend).attr("fill", "#666").attr("alignment-baseline", "middle")
        //         break   
        //     case 'KPI_VALUE':
        //         svg.append("circle").attr("cx", 500).attr("cy", windowHeight + 40).attr("r", 6).style("fill", fillColor)
        //         svg.append("text").attr("x", 520).attr("y", windowHeight + 40).attr("class", "axis").style("font-size", "0.625rem").style("font-family", "BentonSans, Arial, Helvetica, sans-serif").text(element.legend).attr("fill", "#666").attr("alignment-baseline", "middle")
        //         break        
        //     default:
        //         break
        // }       
    }
    return svg;
}

export default KPILineChart

