import React, { Component } from 'react'
import { connect } from 'react-redux'
import WaveScoringParameters from "../Admin/WaveScoringParameters"
import FlightPlanningNetworkEnablementMatchTypes from '../Admin/FlightPlanningNetworkEnablementMatchTypes'
import StandardAccountYield from '../Admin/StandardAccountYield' 


class WaveScoring extends Component {
    constructor(props) {
        super(props)
        this.state = {
            readOnly: props.user.userprofile.Roles[0].indexOf("Admin") > -1 ? false : true
        }
    }


    render() {
        return (
            <div className="grid-x">
                <div className="medium-12 float-left" style={{ fontSize: '1.4rem',fontWeight:'bold', paddingBottom: '8px', widows: '100%' }}>
                    Intelligent Strategy Model
                    <hr style={{ margin: '30px -20px 20px', border: '0', borderTop: '1px solid #c9c7c7' }}></hr>
                </div>
                <div>
                    <WaveScoringParameters tab="Enterprise" Enable_Recmd_Type="Enterprise" solutionInscope="All Except SCC" readOnly={this.state.readOnly}></WaveScoringParameters>                    
                    <WaveScoringParameters tab="Standard" Enable_Recmd_Type="Standard" solutionInscope="All Except SCC" readOnly={this.state.readOnly}></WaveScoringParameters>
                    <WaveScoringParameters tab="SCC" Enable_Recmd_Type="Enterprise" solutionInscope="Supply Chain Collaboration" readOnly={this.state.readOnly}></WaveScoringParameters>
                </div>
                <br></br>
                <br></br>
                <div className="medium-12 float-left" style={{ fontSize: '1.4rem',fontWeight:'bold', paddingTop: '12px', widows: '100%' }}>
                    KPI Strategy Model
                    <hr></hr>
                </div>
                <div>
                    <WaveScoringParameters tab="KPI" Enable_Recmd_Type="KPI" solutionInscope="All" readOnly={this.state.readOnly}></WaveScoringParameters>
                </div>
                {/* <FlightPlanningNetworkEnablementMatchTypes readOnly={this.state.readOnly}></FlightPlanningNetworkEnablementMatchTypes>
                <StandardAccountYield readOnly={this.state.readOnly}></StandardAccountYield> */}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.default,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WaveScoring); 