import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Form, actions } from 'react-redux-form'

// Actions
import {
	reportSubmit,
	reportSubmitSuccess,
	reportSubmitFailure,
	replacePlaceholders,
	downloadReport,
	resetError,
	resetMessage
} from 'actions/userActions'

// Components
import SpendReport from '../Menu/SpendReport';
// UI
import Button from 'UI/Button'
import VideoHelp from 'components/UI/VideoHelp'

class NextStepsContainer extends Component {

	constructor(props) {
		super(props)

		this.state = {
			reportType: '1'
		}


		this.handleRadioButtonClick = this.handleRadioButtonClick.bind(this)
		this.handleReportSubmit = this.handleReportSubmit.bind(this)
		this.downloadFile = this.downloadFile.bind(this)

	}

	handleRadioButtonClick(value) {
		this.setState({
			reportType: value
		})

		this.props.resetMessage()
	}

	handleReportSubmit(formValues) {
		if (this.state.reportType == 2 || this.state.reportType == 3 || this.state.reportType == 6)
			this.props.reportSubmitSuccess(this.props.reportContent.waitMessage)
		this.props.resetError()
		let values = Object.assign({}, formValues)
		values.Type = this.state.reportType
		values.Language = this.props.language.toUpperCase()
		var content = this.props.reportContent
		this.props.reportSubmit(values) //Build Report Using Template files
			.then((response) => {
				if (response.status !== undefined && response.status === 200) {
					//this.props.reportSubmitSuccess('Still generating report. Please be patient.')
					this.props.replacePlaceholders(values, response.data.Result) //Replace placeholders after generating Report Using Template files
						.then((response) => {
							if (response.status !== undefined && response.status === 200) {
								this.props.downloadReport(values, response.data.Result) //Download Report
									.then((response) => {
										if (response.status !== undefined && response.status === 200) {
											this.downloadFile(response)
											this.props.reportSubmitSuccess(content['200'])
											//document.getElementById('report.Email').blur()
											//this.formDispatch(actions.reset('report.Email'))
										}
										else {
											this.props.reportSubmitFailure(response.response.data.Message)
										}
									}).catch((error) => {
										this.props.reportSubmitFailure(error.response.data.Message)
									})
							}
							else {
								this.props.reportSubmitFailure(response.response.data.Message)
							}
						}).catch((error) => {
							this.props.reportSubmitFailure(error.response.data.Message)
						})
				}
				else if (response.response.status == 400 && response.response.data.Message == 'requestfileNotPresent') {
					this.props.reportSubmitFailure(this.props.reportContent.requestfileNotPresent)
				}
				else {
					this.props.reportSubmitFailure(response.response.data.Message)
				}
			}).catch((error) => {
				this.props.reportSubmitFailure(error.response.data.Message)
			})
	}

	downloadFile(response) {
		const byteArrays = [];
		const byteCharacters = atob(response.data);

		for (let offset = 0; offset < byteCharacters.length; offset += 512) {
			const slice = byteCharacters.slice(offset, offset + 512);
			const byteNumbers = new Array(slice.length);
			for (let i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}

			const byteArray = new Uint8Array(byteNumbers);
			byteArrays.push(byteArray);
		}

		var blob = new Blob(byteArrays);

		var [, filename] = response.headers['content-disposition'].split('filename=');
		filename = filename.replace(/ /g, "_").replace(/"/g, "")

		if (navigator.msSaveBlob) {
			navigator.msSaveBlob(blob, filename);
		}
		else {
			var link = document.createElement("a");
			link.href = URL.createObjectURL(blob);
			link.setAttribute('visibility', 'hidden');
			link.download = filename
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	}

	componentWillMount() {
		var reportTypeArray = [];
		var _applicationFeatures = this.props.user.user.applicationFeatures
		var item = this.props.allReports.reportTypes
		//if (item.reportAction != undefined && item.reportAction != null) {			
		var obj = Object.keys(item).map(function (childitem) {
			if (_applicationFeatures.findIndex(a => a.Action === (item[childitem].reportAction)) > -1) {
				reportTypeArray.push(item[childitem].reportId)
			}
		});
		let flag = localStorage.getItem('enablementInsightFlag')
		if (flag == "true") {
			this.setState({ reportType: 5 })
		}
		else if (reportTypeArray.length > 0) {
			this.setState({ reportType: reportTypeArray[0] })
		}

		// this.props.valueStoryCurrentMenu('Next Steps');
		// this.props.showValueStoryMenu(true);
		window.scrollTo(0, 0)
	}

	componentWillUnmount() {
		this.props.resetError();
		this.props.resetMessage();
	}

	render() {
		return (
			<div>
				<div className="grid-x grid-padding-x">
					<div className="medium-12 tab-title">{this.props.contentSpendReport.subTitle}{<VideoHelp screen={{ module: 'Spend', screenName: 'Generate Custom Report' }} />}
					</div>
					<div className="medium-12 tab-description">{this.props.contentSpendReport.description}</div>
				</div>
				<Form className="spend-report"
					model="report"
					getDispatch={(dispatch) => this.formDispatch = dispatch}
					onSubmit={(formValues) => this.handleReportSubmit(formValues)}
				>
					<SpendReport
						content={this.props.reportContent}
						allReports={this.props.allReports}
						handleRadioButtonClick={this.handleRadioButtonClick}
						resetMessage={this.props.resetMessage}
					/>
					<div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '10px' }} className="errors">{this.props.user.error}</div>
					<div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '10px' }} className="success">{this.props.user.message}</div>
					<div className="grid-x">
						<div style={{ display: 'flex', justifyContent: 'center', paddingRight: '70px' }} className="medium-12">
							<Button
								type="submit"
								disabled={this.props.user.loading}
								content={this.props.reportContent.cta}
								color />
						</div>
					</div>
				</Form>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		content: state.content.nextSteps,
		reportContent: state.content.menu.getReport,
		validation: state.content.validation,
		userData: state.valueStory,
		user: state.default,
		allReports: state.content.reports,
		language: state.language,
		contentSpendReport: state.content.spendAnalysis.spendReport,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		//setMutualActionPlan: (mutualActionPlan) => dispatch(setMutualActionPlan(mutualActionPlan)),
		reportSubmit: (formValues) => dispatch(reportSubmit(formValues)),
		reportSubmitSuccess: (user) => dispatch(reportSubmitSuccess(user)),
		reportSubmitFailure: (error) => dispatch(reportSubmitFailure(error)),
		replacePlaceholders: (formValues, fileName) => dispatch(replacePlaceholders(formValues, fileName)),
		downloadReport: (formValues, fileName) => dispatch(downloadReport(formValues, fileName)),
		resetError: () => dispatch(resetError()),
		resetMessage: () => dispatch(resetMessage())
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(NextStepsContainer)