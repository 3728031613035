// Store
import { store } from 'index'

// Actions
import {
	setEstimatedAnnualValue,
	updateSubLeverRowValue
} from 'actions/subLeverCalculationActions'

// Formula
import { runFormulaForSuggestedValue, estimatedBenefitForSuggestedValue, estimatedBenefitForCalculatedValue, 
		 getSourceValue, runFormulaForCalculatedValue, getFormulaForCalculatedValue, getSourceValue_New } from './SubLeverFormula'

// Utils
import {
	getSubLeverIdObject,
	getCurrentLanguage
} from 'utils/Utils'

import { round } from 'utils/Utils'

export const calculateSuggestedValues = (id, state) => {
	// Retrieve state from store
	let subLeverCalculationContent = state.content.subLeverCalculations.subLeverCalculation
	let userData = state.valueStory
	let spendAsPercentageOfRevenue = state.spendAsPercentageOfRevenue

	let arr = [], suggestedValue
	let country = state.content.discovery.form.locations[state.valueStory.Location]
	let result = state.content.discovery.form.regions.find(region => region.Country.toLowerCase() == country.toLowerCase())
	let region = result.Region;
	let industry = state.content.discovery.form.industries[state.valueStory.Industry]

	// Get Lever and SubLever ID
	const subLeverIdObject = getSubLeverIdObject(id)

	// Get target item
	const target = subLeverCalculationContent.find((obj) => {
		return id === obj.id
	})

	// Populate Suggested Values array
	target.rows.forEach((data, i) => {
		// Set static value from content
		if (Number(data[1].content) !== 0) {
			suggestedValue = data[1].content
			// Else run formula to calculate value
		} else {
			const sourceValue = getSourceValue(3, country, region, industry, data[0].content)
			suggestedValue = runFormulaForSuggestedValue(
				target.id,
				i,
				null,
				round(sourceValue, 4),
				userData.AnnualRevenue,
				spendAsPercentageOfRevenue,
				arr
			)
		}
		arr.push(suggestedValue)
	})

	let itemIndex, userInputArray = []
	userInputArray = state.valueStory.BusinessValueToYou[0].ValueDriver[subLeverIdObject.BVUIdIndex].SubLever[subLeverIdObject.BVUSVDIdIndex].UserInput

	arr.forEach((data, i) => {

		target.rows.forEach((jsonData, index) => {
			if (index === i) {
				userInputArray.forEach((dbData, dbIndex) => {
					if (jsonData[0].content.toLowerCase() === dbText(dbData, state.language).toLowerCase()) {
						itemIndex = dbIndex
					}
				})
			}
		})
		// dispatch to store
		store.dispatch(updateSubLeverRowValue(arr[i], subLeverIdObject, itemIndex, 'SuggestedValues'))
		//store.dispatch(updateSubLeverItemValue(arr[i], subLeverIdObject, i, suggestedValueDescription, 'SuggestedValues'))
	})

	// console.log(arr)
	return arr
}

export const calculateSuggestedEstimatedBenefit = (state, id = false, arr = false, subLeverIdObject = false) => {
	const suggestedEstimatedBenefit = estimatedBenefitForSuggestedValue(id, arr)
	if (id) {
		let itemIndex, userInputArray = []
		userInputArray = state.valueStory.BusinessValueToYou[0].ValueDriver[subLeverIdObject.BVUIdIndex].SubLever[subLeverIdObject.BVUSVDIdIndex].UserInput

		userInputArray.forEach((dbData, dbIndex) => {
			//In below code we are checking Item i.e. English text only to get index. It will work for all languages as userInputArray contains all language items and we are checking only English text
			if ('estimated benefit' === dbData.Item.toLowerCase()) {
				itemIndex = dbIndex
			}
		})

		// Dispatch to store
		store.dispatch(updateSubLeverRowValue(suggestedEstimatedBenefit, subLeverIdObject, itemIndex, 'SuggestedValues'))
	}
	return suggestedEstimatedBenefit
}

export const getCalculatedEstimatedBenefit = (state, id, parent = false) => {
	const currency = decodeURIComponent(state.valueStory.Currency);
	const subLeverIdObject = getSubLeverIdObject(id),
		suggestedValues = calculateSuggestedValues(id, state),
		suggestedEstimatedBenefit = calculateSuggestedEstimatedBenefit(state, id, suggestedValues, getSubLeverIdObject(id)),
		userValues = getUserValues(subLeverIdObject, state),
		calculatedValues = updateAllValues(id, suggestedValues, userValues, state),
		calculatedValuesFormulae = updateAllValuesFormulae(id, suggestedValues, userValues, state, calculatedValues, currency),
		estimatedAnnualValue = estimatedBenefitForCalculatedValue(id, calculatedValues)

	if (parent) {
		parent.setState({
			render: true,
			suggestedValues: suggestedValues,
			userValues: userValues,
			calculatedValues: calculatedValues,
			estimatedBenefit: suggestedEstimatedBenefit,
			calculatedEstimatedBenefit: estimatedAnnualValue,
			calculatedValuesFormulae: calculatedValuesFormulae
		})
	}

	// Dispatch to store (for business value page)
	let itemIndex, userInputArray = []
	userInputArray = state.valueStory.BusinessValueToYou[0].ValueDriver[subLeverIdObject.BVUIdIndex].SubLever[subLeverIdObject.BVUSVDIdIndex].UserInput

	userInputArray.forEach((dbData, dbIndex) => {
		//In below code we are checking Item i.e. English text only to get index. It will work for all languages as userInputArray contains all language items and we are checking only English text
		if ('estimated benefit' === dbData.Item.toLowerCase()) {
			itemIndex = dbIndex
		}
	})

	// Dispatch to store (for business value page)
	store.dispatch(setEstimatedAnnualValue(estimatedAnnualValue, subLeverIdObject))

	store.dispatch(updateSubLeverRowValue(estimatedAnnualValue, subLeverIdObject, itemIndex, 'CalculatedValues'))
	return estimatedAnnualValue
}

export const getUserValues = (id, state) => {
	// Retrieve state from store
	let subLeverCalculationContent = state.content.subLeverCalculations.subLeverCalculation
	let userData = state.valueStory

	let arr = [],
		subLeverRowCount = subLeverCalculationContent.find((obj) => obj.id === (id.BVUIdIndex + 1) + '.' + (id.BVUSVDIdIndex + 1))
	subLeverRowCount = subLeverRowCount.rows.length

	const subLever = userData.BusinessValueToYou[0].ValueDriver[id.BVUIdIndex].SubLever[id.BVUSVDIdIndex]

	// Get target item
	const target = subLeverCalculationContent.find((obj) => {
		return (id.BVUIdIndex + 1) + '.' + (id.BVUSVDIdIndex + 1) === obj.id
	})

	let itemIndex, userInputArray = []
	userInputArray = subLever.UserInput

	// Populate User Values array
	for (let i = 0; i < subLeverRowCount; i++) {

		target.rows.forEach((jsonData, index) => {
			if (index === i) {
				userInputArray.forEach((dbData, dbIndex) => {
					if (jsonData[0].content.toLowerCase() === dbText(dbData, state.language).toLowerCase()) {
						itemIndex = dbIndex
					}
				})
			}
		})

		// Account for rows with no user input fields
		if (
			//TO DO ***** Use findRowNumber function below to find out item row number
			(id.BVUIdIndex === 0 && id.BVUSVDIdIndex === 0 && i === 6) ||
			(id.BVUIdIndex === 0 && id.BVUSVDIdIndex === 1 && i === 6) ||
			(id.BVUIdIndex === 2 && id.BVUSVDIdIndex === 0 && i === 2) ||
			(id.BVUIdIndex === 2 && id.BVUSVDIdIndex === 0 && i === 4) ||
			(id.BVUIdIndex === 2 && id.BVUSVDIdIndex === 0 && i === 5) ||
			(id.BVUIdIndex === 2 && id.BVUSVDIdIndex === 1 && i === 2) ||
			(id.BVUIdIndex === 2 && id.BVUSVDIdIndex === 1 && i === 4) ||
			(id.BVUIdIndex === 2 && id.BVUSVDIdIndex === 1 && i === 5) ||
			(id.BVUIdIndex === 2 && id.BVUSVDIdIndex === 3 && i === 2) ||
			(id.BVUIdIndex === 3 && id.BVUSVDIdIndex === 0 && i === 3) ||
			(id.BVUIdIndex === 3 && id.BVUSVDIdIndex === 1 && i === 3) ||
			(id.BVUIdIndex === 3 && id.BVUSVDIdIndex === 2 && i === 2) ||
			(id.BVUIdIndex === 3 && id.BVUSVDIdIndex === 2 && i === 8) ||
			(id.BVUIdIndex === 3 && id.BVUSVDIdIndex === 3 && i === 3) ||
			(id.BVUIdIndex === 3 && id.BVUSVDIdIndex === 3 && i === 4) ||
			(id.BVUIdIndex === 4 && id.BVUSVDIdIndex === 0 && i === 4) ||
			(id.BVUIdIndex === 4 && id.BVUSVDIdIndex === 1 && i === 2) ||
			(id.BVUIdIndex === 4 && id.BVUSVDIdIndex === 1 && i === 5)
		) {
			arr.push('n/a')
		} else {
			arr.push(subLever.UserInput[itemIndex].UserInput)
		}
	}

	// console.log(arr)
	return arr
}

export const updateAllValues = (id, suggestedValues, userValues, state) => {
	// Retrieve state from store
	let userData = state.valueStory;
	let spendAsPercentageOfRevenue = state.spendAsPercentageOfRevenue;
	let subLeverCalculationContent = state.content.subLeverCalculations.subLeverCalculation
	// Check for user inputted total spend
	var firstSubLeverTotalSpend = state.valueStory.BusinessValueToYou[0].ValueDriver[0].SubLever[0].UserInput[0].UserInput

	let arr = [], calculatedValue
	let country = state.content.discovery.form.locations[state.valueStory.Location]
	let result = state.content.discovery.form.regions.find(region => region.Country.toLowerCase() == country.toLowerCase())
	let region = result.Region;
	let industry = state.content.discovery.form.industries[state.valueStory.Industry]

	const subLeverIdObject = getSubLeverIdObject(id)

	suggestedValues.forEach((value, index) => {
		userValues[index] === null ||
			userValues[index] === '' ||
			userValues[index] === 'n/a'
			? arr.push(suggestedValues[index]) : arr.push(userValues[index])
	})

	// Get target item
	const target = subLeverCalculationContent.find((obj) => {
		return id === obj.id
	})
	let suggestedItem

	let itemIndex, userInputArray = []
	userInputArray = state.valueStory.BusinessValueToYou[0].ValueDriver[subLeverIdObject.BVUIdIndex].SubLever[subLeverIdObject.BVUSVDIdIndex].UserInput

	// Update any Calculated Values derived from formula that have blank user input
	arr.forEach((value, index) => {

		target.rows.forEach((data, i) => {
			if (i === index)
				suggestedItem = data[0].content
		})

		let sourceValue;
		let userInputValue;
		if (userValues[index] === null || userValues[index] === '' || userValues[index] === 'n/a') {
			sourceValue = getSourceValue(3, country, region, industry, suggestedItem)
		}
		else {
			userInputValue = userValues[index]
		}

		//if (userValues[index] === null || userValues[index] === '' || userValues[index] === 'n/a') {
		calculatedValue = runFormulaForCalculatedValue(
			id,
			index,
			firstSubLeverTotalSpend,
			round(sourceValue, 4),
			userInputValue,
			userData.AnnualRevenue,
			spendAsPercentageOfRevenue,
			arr
		)
		/* 
				if (id === '3.5' && index === 1 && userValues[1] !== null && userValues[1] !== '' && userValues[1] !== 'n/a') {
					var suggestedValue = runFormulaForCalculatedValue(
						id,
						1,
						null,
						sourceValue,
						userData.AnnualRevenue,
						spendAsPercentageOfRevenue,
						arr
					)
					suggestedValues[1] = suggestedValue
				}
				if (id === '3.1' && index === 0 && userValues[0] !== null && userValues[0] !== '' && userValues[0] !== 'n/a') {
					var suggestedValue = runFormulaForCalculatedValue(
						id,
						0,
						null,
						sourceValue,
						userData.AnnualRevenue,
						spendAsPercentageOfRevenue,
						arr
					)
					suggestedValues[0] = suggestedValue
				}
				if (id === '3.2' && index === 1 && userValues[0] !== null && userValues[0] !== '' && userValues[0] !== 'n/a') {
					var suggestedValue = runFormulaForCalculatedValue(
						id,
						0,
						null,
						sourceValue,
						userData.AnnualRevenue,
						spendAsPercentageOfRevenue,
						arr
					)
					suggestedValues[0] = suggestedValue
				}
		
				//TO DO ***** Use findRowNumber function below to find out item row number
				if (typeof calculatedValue === 'number' && id === '3.1' && index === 0 && calculatedValue > 150) {
					calculatedValue = 150;
				}
				//TO DO ***** Use findRowNumber function below to find out item row number
				if (typeof calculatedValue === 'number' && id === '3.2' && index === 0 && calculatedValue > 190) {
					calculatedValue = 190;
				}
				//TO DO ***** Use findRowNumber function below to find out item row number
				if (typeof calculatedValue === 'number' && id === '3.5' && index === 1 && calculatedValue > 100) {
					calculatedValue = 100;
				}
		 */
		// If number is returned, update to state
		if (typeof calculatedValue === 'number') {
			arr[index] === undefined ? arr.push(calculatedValue) : arr[index] = calculatedValue
		}
		//}

		target.rows.forEach((jsonData, i) => {
			if (index === i) {
				userInputArray.forEach((dbData, dbIndex) => {
					if (jsonData[0].content.toLowerCase() === dbText(dbData, state.language).toLowerCase()) {
						itemIndex = dbIndex
					}
				})
			}
		})

		// dispatch to store
		store.dispatch(updateSubLeverRowValue(arr[index], subLeverIdObject, itemIndex, 'CalculatedValues'))
	})

	// console.log(arr)
	return arr
}

export const updateAllValuesFormulae = (id, suggestedValues, userValues, state, calculatedValues, currency) => {
	// Retrieve state from store
	let userData = state.valueStory;
	let spendAsPercentageOfRevenue = state.spendAsPercentageOfRevenue;
	let subLeverCalculationContent = state.content.subLeverCalculations.subLeverCalculation
	// Check for user inputted total spend
	var firstSubLeverTotalSpend = state.valueStory.BusinessValueToYou[0].ValueDriver[0].SubLever[0].UserInput[0].UserInput

	let arr = [], calculatedValue
	let country = state.content.discovery.form.locations[state.valueStory.Location]
	let result = state.content.discovery.form.regions.find(region => region.Country.toLowerCase() == country.toLowerCase())
	let region = result.Region;
	let industry = state.content.discovery.form.industries[state.valueStory.Industry]
	let arr2 = []

	const subLeverIdObject = getSubLeverIdObject(id)

	suggestedValues.forEach((value, index) => {
		userValues[index] === null ||
			userValues[index] === '' ||
			userValues[index] === 'n/a'
			? arr.push(suggestedValues[index]) : arr.push(userValues[index])
	})
	calculatedValues.forEach((value, index) =>{
		arr2.push(calculatedValues[index])
	})
	// Get target item
	const target = subLeverCalculationContent.find((obj) => {
		return id === obj.id
	})
	let suggestedItem

	let itemIndex, userInputArray = []
	userInputArray = state.valueStory.BusinessValueToYou[0].ValueDriver[subLeverIdObject.BVUIdIndex].SubLever[subLeverIdObject.BVUSVDIdIndex].UserInput

	// Update any Calculated Values derived from formula that have blank user input
	arr.forEach((value, index) => {

		target.rows.forEach((data, i) => {
			if (i === index)
				suggestedItem = data[0].content
		})

		let sourceValue;
		//let source;
		let userInputValue;
		if (userValues[index] === null || userValues[index] === '' || userValues[index] === 'n/a') {
			sourceValue = getSourceValue(3, country, region, industry, suggestedItem)
			//source = getSourceValue_New(3, country, region, industry, suggestedItem)
		}
		else {
			userInputValue = userValues[index]
		}

		//if (userValues[index] === null || userValues[index] === '' || userValues[index] === 'n/a') {
		calculatedValue = getFormulaForCalculatedValue(
			id,
			index,
			firstSubLeverTotalSpend,
			round(sourceValue, 4),
			userInputValue,
			userData.AnnualRevenue,
			spendAsPercentageOfRevenue,
			arr,
			arr2,
			currency
		)
		

			arr[index] === undefined ? arr.push(calculatedValue) : arr[index] = calculatedValue

	})

	console.log(arr)
	return arr
}

export const findJSONRowNumber = (subLeverId, suggestedValueDescription) => {
	const subLeverIdObject = getSubLeverIdObject(subLeverId)
	const state = store.getState(),
		subLeverCalculationContent = state.content.subLeverCalculations.subLeverCalculation
	// Get target item
	const target = subLeverCalculationContent.find((obj) => {
		return (subLeverIdObject.BVUIdIndex + 1) + '.' + (subLeverIdObject.BVUSVDIdIndex + 1) === obj.id
	})

	let itemIndex
	target.rows.forEach((jsonData, index) => {
		if (jsonData[0].content.toLowerCase() === suggestedValueDescription.toLowerCase()) {
			itemIndex = index
		}
	})
	return itemIndex
}

export const findDBRowNumber = (userData, subLeverId, suggestedValueDescription) => {
	const subLeverIdObject = getSubLeverIdObject(subLeverId)
	const state = store.getState()
	let userInputArray = [], DBitemIndex
	userInputArray = userData.BusinessValueToYou[0].ValueDriver[subLeverIdObject.BVUIdIndex].SubLever[subLeverIdObject.BVUSVDIdIndex].UserInput

	userInputArray.forEach((dbData, dbIndex) => {
		if (suggestedValueDescription.toLowerCase() === dbText(dbData, state.language).toLowerCase()) {
			DBitemIndex = dbIndex
		}
	})
	return DBitemIndex
}

export const findCalculatedValue = (rowNumber, subLever, subLeverCalculation) => {
	const state = store.getState()
	let suggestedValueDescription, rowValue, userInputArray = []
	userInputArray = state.valueStory.BusinessValueToYou[0].ValueDriver[subLever.BVUIdIndex].SubLever[subLever.BVUSVDIdIndex].UserInput
	const target = subLeverCalculation.find((obj) => {
		return (subLever.BVUIdIndex + 1) + '.' + (subLever.BVUSVDIdIndex + 1) === obj.id
	})
	target.rows.forEach((jsonData, index) => {
		if (index === rowNumber) {
			userInputArray.forEach((dbData, dbIndex) => {
				if (jsonData[0].content.toLowerCase() === dbText(dbData, state.language).toLowerCase()) {
					suggestedValueDescription = jsonData[0].content
				}
			})
		}
	})
	userInputArray.forEach((dbData, dbIndex) => {
		if (suggestedValueDescription.toLowerCase() === dbText(dbData, state.language).toLowerCase()) {
			rowValue = dbData.CalculatedValues === null || dbData.CalculatedValues === undefined ? 0 : dbData.CalculatedValues
		}
	})
	return round(Number(rowValue), 2)
}

export const SpendValidation_1 = (subLeverId, subLeverCalculation) => {
	let isWarning = false, spend, indirectSpend, directSpend, subLever, sourceValue, suggestedItem, target, userValue, userInputArray = []
	subLever = getSubLeverIdObject(subLeverId)
	spend = findCalculatedValue(0, subLever, subLeverCalculation)// 0 is Spend rowNumber on UI

	const state = store.getState()
	let country = state.content.discovery.form.locations[state.valueStory.Location]
	let result = state.content.discovery.form.regions.find(region => region.Country.toLowerCase() == country.toLowerCase())
	let region = result.Region;
	let industry = state.content.discovery.form.industries[state.valueStory.Industry]

	subLever = getSubLeverIdObject('1.1')
	userInputArray = state.valueStory.BusinessValueToYou[0].ValueDriver[subLever.BVUIdIndex].SubLever[subLever.BVUSVDIdIndex].UserInput
	target = subLeverCalculation.find((obj) => {
		return '1.1' === obj.id
	})
	target.rows.forEach((jsonData, index) => {
		if (index === 1) { // Indirect Spend
			suggestedItem = jsonData[0].content
		}
	})
	userInputArray.forEach((dbData, dbIndex) => {
		if (suggestedItem.toLowerCase() === dbText(dbData, state.language).toLowerCase()) {
			userValue = dbData.UserInput
		}
	})
	if (userValue !== null && userValue !== undefined)
		indirectSpend = findCalculatedValue(1, getSubLeverIdObject('1.1'), subLeverCalculation)// 1 is Indirect Spend rowNumber on UI
	else {
		sourceValue = round(round(getSourceValue(3, country, region, industry, suggestedItem), 4) * 100, 0)
		indirectSpend = round((spend * (sourceValue / 100)), 2) //Do calculation as Spend dependent calcualtions doesn't happen automatically in another sub levers
	}

	subLever = getSubLeverIdObject('1.2')
	userInputArray = state.valueStory.BusinessValueToYou[0].ValueDriver[subLever.BVUIdIndex].SubLever[subLever.BVUSVDIdIndex].UserInput
	target = subLeverCalculation.find((obj) => {
		return '1.2' === obj.id
	})
	target.rows.forEach((jsonData, index) => {
		if (index === 1) { // Direct Spend
			suggestedItem = jsonData[0].content
		}
	})
	userInputArray.forEach((dbData, dbIndex) => {
		if (suggestedItem.toLowerCase() === dbText(dbData, state.language).toLowerCase()) {
			userValue = dbData.UserInput
		}
	})
	if (userValue !== null && userValue !== undefined)
		directSpend = findCalculatedValue(1, getSubLeverIdObject('1.2'), subLeverCalculation)// 1 is Direct Spend rowNumber on UI
	else {
		sourceValue = round(round(getSourceValue(3, country, region, industry, suggestedItem), 4) * 100, 0)
		directSpend = round((spend * (1 - (sourceValue / 100))), 2) //Do calculation as Spend dependent calcualtions doesn't happen automatically in another sub levers
	}
	if ((Number(indirectSpend) + Number(directSpend)) > Number(spend)) {
		isWarning = true
	}
	return isWarning
}

export const SpendValidation_2 = (rowNumber, subLeverId, subLeverCalculation) => {
	let isWarning = false, value, spendNotAddressableForExtendingPaymentTerms, spendToBeAddressedByPaymentTermExtension
	const subLever = getSubLeverIdObject(subLeverId)
	value = findCalculatedValue(rowNumber, subLever, subLeverCalculation)
	spendNotAddressableForExtendingPaymentTerms = findCalculatedValue(1, subLever, subLeverCalculation)// 1 is Spend not addressable for extending payment terms rowNumber on UI           
	spendToBeAddressedByPaymentTermExtension = findCalculatedValue(3, subLever, subLeverCalculation)// 3 is Spend to be addressed by payment term extension rowNumber on UI
	if (Number(value) < Number(spendNotAddressableForExtendingPaymentTerms) + Number(spendToBeAddressedByPaymentTermExtension)) {
		isWarning = true
	}
	return isWarning
}

export const SpendValidation_3 = (rowNumber, subLeverId, subLeverCalculation) => {
	let isWarning = false, value, externalWorkforceAndServicesSpend
	const subLever = getSubLeverIdObject(subLeverId)
	value = findCalculatedValue(rowNumber, subLever, subLeverCalculation)
	externalWorkforceAndServicesSpend = findCalculatedValue(1, subLever, subLeverCalculation)// 1 is External workforce and services spend rowNumber on UI           
	if (Number(value) < Number(externalWorkforceAndServicesSpend)) {
		isWarning = true
	}
	return isWarning
}

export const SpendValidation_4 = (rowNumber, subLeverId, subLeverCalculation) => {
	let isWarning = false, value, serviceSpend
	const subLever = getSubLeverIdObject(subLeverId)
	value = findCalculatedValue(rowNumber, subLever, subLeverCalculation)
	serviceSpend = findCalculatedValue(1, subLever, subLeverCalculation)// 1 is Service spend rowNumber on UI           
	if (Number(value) < Number(serviceSpend)) {
		isWarning = true
	}
	return isWarning
}

export const SpendValidation_5 = (rowNumber, subLeverId, subLeverCalculation) => {
	let isWarning = false, value, externalWorkforceAndServicesSpend
	const subLever = getSubLeverIdObject(subLeverId)
	value = findCalculatedValue(rowNumber, subLever, subLeverCalculation)
	externalWorkforceAndServicesSpend = findCalculatedValue(1, subLever, subLeverCalculation)// 1 is External workforce and services spend rowNumber on UI           
	if (Number(value) < Number(externalWorkforceAndServicesSpend)) {
		isWarning = true
	}
	return isWarning
}

export const SpendValidation_6 = (rowNumber, subLeverId, subLeverCalculation) => {
	let isWarning = false, value, externalWorkforceAndServicesSpend
	const subLever = getSubLeverIdObject(subLeverId)
	value = findCalculatedValue(rowNumber, subLever, subLeverCalculation)
	externalWorkforceAndServicesSpend = findCalculatedValue(1, subLever, subLeverCalculation)// 1 is External workforce and services spend rowNumber on UI           
	if (Number(value) < Number(externalWorkforceAndServicesSpend)) {
		isWarning = true
	}
	return isWarning
}

export const ServiceSpendValidation_1 = (rowNumber, subLeverId, subLeverCalculation) => {
	let isWarning = false, value, spend
	const subLever = getSubLeverIdObject(subLeverId)
	value = findCalculatedValue(rowNumber, subLever, subLeverCalculation)
	spend = findCalculatedValue(0, subLever, subLeverCalculation)// 0 is Spend rowNumber on UI
	if (Number(value) > Number(spend)) {
		isWarning = true
	}
	return isWarning
}

export const ServiceSpendWithBudgetThresholdsValidation_1 = (rowNumber, subLeverId, subLeverCalculation) => {
	let isWarning = false, value, serviceSpend
	const subLever = getSubLeverIdObject(subLeverId)
	value = findCalculatedValue(rowNumber, subLever, subLeverCalculation)
	serviceSpend = findCalculatedValue(1, subLever, subLeverCalculation)// 1 is Services Spend rowNumber on UI
	if (Number(value) > Number(serviceSpend)) {
		isWarning = true
	}
	return isWarning
}

export const ServicesSpendNotCompletedWithinBudgetValidation_1 = (rowNumber, subLeverId, subLeverCalculation) => {
	let isWarning = false, value, servicesSpendWithBudgetThresholds
	const subLever = getSubLeverIdObject(subLeverId)
	value = findCalculatedValue(rowNumber, subLever, subLeverCalculation)
	servicesSpendWithBudgetThresholds = findCalculatedValue(2, subLever, subLeverCalculation)// 2 is Services spend with budget thresholds rowNumber on UI
	if (Number(value) > Number(servicesSpendWithBudgetThresholds)) {
		isWarning = true
	}
	return isWarning
}

export const ServiceSpendWithBudgetThresholdsValidation_2 = (rowNumber, subLeverId, subLeverCalculation) => {
	let isWarning = false, value, servicesSpendNotCompletedWithinBudget
	const subLever = getSubLeverIdObject(subLeverId)
	value = findCalculatedValue(rowNumber, subLever, subLeverCalculation)
	servicesSpendNotCompletedWithinBudget = findCalculatedValue(3, subLever, subLeverCalculation)// 3 is Services spend not completed within budget rowNumber on UI
	if (Number(value) < Number(servicesSpendNotCompletedWithinBudget)) {
		isWarning = true
	}
	return isWarning
}

export const IndirectSpendValidation_1 = (rowNumber, subLeverId, subLeverCalculation) => {
	let isWarning = false, value, spend
	const subLever = getSubLeverIdObject(subLeverId)
	value = findCalculatedValue(rowNumber, subLever, subLeverCalculation)
	spend = findCalculatedValue(0, subLever, subLeverCalculation)// 0 is Spend rowNumber on UI
	if (Number(value) > Number(spend)) {
		isWarning = true
	}
	return isWarning
}

export const IndirectSpendValidation_2 = (rowNumber, subLeverId, subLeverCalculation) => {
	let isWarning = false, value, spend, directSpend, sourceValue, suggestedItem, target, userValue, subLever, userInputArray = []
	subLever = getSubLeverIdObject(subLeverId)
	value = findCalculatedValue(rowNumber, subLever, subLeverCalculation)
	spend = findCalculatedValue(0, subLever, subLeverCalculation)// 0 is Spend rowNumber on UI

	const state = store.getState()
	let country = state.content.discovery.form.locations[state.valueStory.Location]
	let result = state.content.discovery.form.regions.find(region => region.Country.toLowerCase() == country.toLowerCase())
	let region = result.Region;
	let industry = state.content.discovery.form.industries[state.valueStory.Industry]

	subLever = getSubLeverIdObject('1.2')
	userInputArray = state.valueStory.BusinessValueToYou[0].ValueDriver[subLever.BVUIdIndex].SubLever[subLever.BVUSVDIdIndex].UserInput
	target = subLeverCalculation.find((obj) => {
		return '1.2' === obj.id
	})
	target.rows.forEach((jsonData, index) => {
		if (index === 1) { // Direct Spend
			suggestedItem = jsonData[0].content
		}
	})
	userInputArray.forEach((dbData, dbIndex) => {
		if (suggestedItem.toLowerCase() === dbText(dbData, state.language).toLowerCase()) {
			userValue = dbData.UserInput
		}
	})
	if (userValue !== null && userValue !== undefined)
		directSpend = findCalculatedValue(1, getSubLeverIdObject('1.2'), subLeverCalculation)// 1 is Direct Spend rowNumber on UI
	else {
		sourceValue = round(round(getSourceValue(3, country, region, industry, suggestedItem), 4) * 100, 0)
		directSpend = round((spend * (1 - (sourceValue / 100))), 2) //Do calculation as Spend dependent calcualtions doesn't happen automatically in another sub levers
	}

	if ((Number(value) + Number(directSpend)) > Number(spend)) {
		isWarning = true
	}
	return isWarning
}

export const IndirectSpendValidation_3 = (rowNumber, subLeverId, subLeverCalculation) => {
	let isWarning = false, value, indirectSpendAddressabelByStrategicSourcing
	const subLever = getSubLeverIdObject(subLeverId)
	value = findCalculatedValue(rowNumber, subLever, subLeverCalculation)
	indirectSpendAddressabelByStrategicSourcing = findCalculatedValue(2, subLever, subLeverCalculation)// 2 is Indirect Spend addressable by strategic sourcing rowNumber
	if (Number(value) < Number(indirectSpendAddressabelByStrategicSourcing)) {
		isWarning = true
	}
	return isWarning
}

export const IndirectSpendValidation_4 = (rowNumber, subLeverId, subLeverCalculation) => {
	let isWarning = false, value, indirectSpendAddressableByTacticalSourcing
	const subLever = getSubLeverIdObject(subLeverId)
	value = findCalculatedValue(rowNumber, subLever, subLeverCalculation)
	indirectSpendAddressableByTacticalSourcing = findCalculatedValue(2, subLever, subLeverCalculation)// 2 is Indirect spend addressable by tactical sourcing rowNumber on UI
	if (Number(value) < Number(indirectSpendAddressableByTacticalSourcing)) {
		isWarning = true
	}
	return isWarning
}

export const IndirectSpendValidation_5 = (rowNumber, subLeverId, subLeverCalculation) => {
	let isWarning = false, value, indirectSpendAddressableByProcurement
	const subLever = getSubLeverIdObject(subLeverId)
	value = findCalculatedValue(rowNumber, subLever, subLeverCalculation)
	indirectSpendAddressableByProcurement = findCalculatedValue(2, subLever, subLeverCalculation)// 2 is Indirect spend addressable by procurement rowNumber on UI
	if (Number(value) < Number(indirectSpendAddressableByProcurement)) {
		isWarning = true
	}
	return isWarning
}

export const IndirectSpendValidation_6 = (rowNumber, subLeverId, subLeverCalculation) => {
	let isWarning = false, value, indirectSpendAddressableByProcurementNotViaBlanketORPurchaseOrder
	const subLever = getSubLeverIdObject(subLeverId)
	value = findCalculatedValue(rowNumber, subLever, subLeverCalculation)
	indirectSpendAddressableByProcurementNotViaBlanketORPurchaseOrder = findCalculatedValue(2, subLever, subLeverCalculation)// 2 is Indirect spend addressable by procurement not via blanket or purchase order rowNumber on UI
	if (Number(value) < Number(indirectSpendAddressableByProcurementNotViaBlanketORPurchaseOrder)) {
		isWarning = true
	}
	return isWarning
}

export const IndirectSpendAddressableByStrategicSourcingValidation_1 = (rowNumber, subLeverId, subLeverCalculation) => {
	let isWarning = false, value, indirectSpend
	const subLever = getSubLeverIdObject(subLeverId)
	value = findCalculatedValue(rowNumber, subLever, subLeverCalculation)
	indirectSpend = findCalculatedValue(1, subLever, subLeverCalculation)// 1 is Indirect Spend rowNumber on UI
	if (Number(value) > Number(indirectSpend)) {
		isWarning = true
	}
	return isWarning
}

export const IndirectSpendAddressableByStrategicSourcingValidation_2 = (rowNumber, subLeverId, subLeverCalculation) => {
	let isWarning = false, value, indirectSpendStrategicallySourced
	const subLever = getSubLeverIdObject(subLeverId)
	value = findCalculatedValue(rowNumber, subLever, subLeverCalculation)
	indirectSpendStrategicallySourced = findCalculatedValue(3, subLever, subLeverCalculation)// 3 is Indirect Spend currently strategically sourced rowNumber on UI
	if (Number(value) < Number(indirectSpendStrategicallySourced)) {
		isWarning = true
	}
	return isWarning
}

export const IndirectSpendAddressableByStrategicSourcingValidation_3 = (rowNumber, subLeverId, subLeverCalculation) => {
	let isWarning = false, value, incrementalIndirectSpendStrategicallySourced
	const subLever = getSubLeverIdObject(subLeverId)
	value = findCalculatedValue(rowNumber, subLever, subLeverCalculation)
	incrementalIndirectSpendStrategicallySourced = findCalculatedValue(3, subLever, subLeverCalculation)// 3 is Incremental indirect spend to be strategically sourced rowNumber on UI
	if (Number(value) < Number(incrementalIndirectSpendStrategicallySourced)) {
		isWarning = true
	}
	return isWarning
}

export const IndirectSpendStrategicallySourcedValidation_1 = (rowNumber, subLeverId, subLeverCalculation) => {
	let isWarning = false, value, indirectSpendAddressabelByStrategicSourcing
	const subLever = getSubLeverIdObject(subLeverId)
	value = findCalculatedValue(rowNumber, subLever, subLeverCalculation)
	indirectSpendAddressabelByStrategicSourcing = findCalculatedValue(2, subLever, subLeverCalculation)// 2 is Indirect Spend addressable by strategic sourcing rowNumber
	if (Number(value) > Number(indirectSpendAddressabelByStrategicSourcing)) {
		isWarning = true
	}
	return isWarning
}

export const DirectSpendValidation_1 = (rowNumber, subLeverId, subLeverCalculation) => {
	let isWarning = false, value, spend
	const subLever = getSubLeverIdObject(subLeverId)
	value = findCalculatedValue(rowNumber, subLever, subLeverCalculation)
	spend = findCalculatedValue(0, subLever, subLeverCalculation)// 0 is Spend rowNumber on UI
	if (Number(value) > Number(spend)) {
		isWarning = true
	}
	return isWarning
}

export const DirectSpendValidation_2 = (rowNumber, subLeverId, subLeverCalculation) => {
	let isWarning = false, value, spend, indirectSpend, sourceValue, suggestedItem, target, userValue, subLever, userInputArray = []
	subLever = getSubLeverIdObject(subLeverId)
	value = findCalculatedValue(rowNumber, subLever, subLeverCalculation)
	spend = findCalculatedValue(0, subLever, subLeverCalculation)// 0 is Spend rowNumber on UI

	const state = store.getState()
	let country = state.content.discovery.form.locations[state.valueStory.Location]
	let result = state.content.discovery.form.regions.find(region => region.Country.toLowerCase() == country.toLowerCase())
	let region = result.Region;
	let industry = state.content.discovery.form.industries[state.valueStory.Industry]

	subLever = getSubLeverIdObject('1.1')
	userInputArray = state.valueStory.BusinessValueToYou[0].ValueDriver[subLever.BVUIdIndex].SubLever[subLever.BVUSVDIdIndex].UserInput
	target = subLeverCalculation.find((obj) => {
		return '1.1' === obj.id
	})
	target.rows.forEach((jsonData, index) => {
		if (index === 1) { // Indirect Spend
			suggestedItem = jsonData[0].content
		}
	})
	userInputArray.forEach((dbData, dbIndex) => {
		if (suggestedItem.toLowerCase() === dbText(dbData, state.language).toLowerCase()) {
			userValue = dbData.UserInput
		}
	})
	if (userValue !== null && userValue !== undefined)
		indirectSpend = findCalculatedValue(1, getSubLeverIdObject('1.1'), subLeverCalculation)// 1 is Indirect Spend rowNumber on UI
	else {
		sourceValue = round(round(getSourceValue(3, country, region, industry, suggestedItem), 4) * 100, 0)
		indirectSpend = round((spend * (sourceValue / 100)), 2) //Do calculation as Spend dependent calcualtions doesn't happen automatically in another sub levers
	}
	if ((Number(indirectSpend) + Number(value)) > Number(spend)) {
		isWarning = true
	}
	return isWarning
}

export const DirectSpendValidation_3 = (rowNumber, subLeverId, subLeverCalculation) => {
	let isWarning = false, value, directSpendAddressableByStrategicSourcing
	const subLever = getSubLeverIdObject(subLeverId)
	value = findCalculatedValue(rowNumber, subLever, subLeverCalculation)
	directSpendAddressableByStrategicSourcing = findCalculatedValue(2, subLever, subLeverCalculation)// 2 is Direct spend addressable by strategic sourcing rowNumber on UI
	if (Number(value) < Number(directSpendAddressableByStrategicSourcing)) {
		isWarning = true
	}
	return isWarning
}

export const DirectSpendValidation_4 = (rowNumber, subLeverId, subLeverCalculation) => {
	let isWarning = false, value, spend
	const subLever = getSubLeverIdObject(subLeverId)
	value = findCalculatedValue(rowNumber, subLever, subLeverCalculation)
	spend = findCalculatedValue(0, getSubLeverIdObject('1.1'), subLeverCalculation)// 0 is Spend rowNumber on 1.1
	if (Number(value) > Number(spend)) {
		isWarning = true
	}
	return isWarning
}

export const DirectSpendValidation_5 = (rowNumber, subLeverId, subLeverCalculation) => {
	let isWarning = false, value, spend, indirectSpend, sourceValue, suggestedItem, target, userValue, subLever, userInputArray = []
	subLever = getSubLeverIdObject(subLeverId)
	value = findCalculatedValue(rowNumber, subLever, subLeverCalculation)
	spend = findCalculatedValue(0, getSubLeverIdObject('1.1'), subLeverCalculation)// 0 is Spend rowNumber on 1.1

	const state = store.getState()
	let country = state.content.discovery.form.locations[state.valueStory.Location]
	let result = state.content.discovery.form.regions.find(region => region.Country.toLowerCase() == country.toLowerCase())
	let region = result.Region;
	let industry = state.content.discovery.form.industries[state.valueStory.Industry]

	subLever = getSubLeverIdObject('1.1')
	userInputArray = state.valueStory.BusinessValueToYou[0].ValueDriver[subLever.BVUIdIndex].SubLever[subLever.BVUSVDIdIndex].UserInput
	target = subLeverCalculation.find((obj) => {
		return '1.1' === obj.id
	})
	target.rows.forEach((jsonData, index) => {
		if (index === 1) { // Indirect Spend
			suggestedItem = jsonData[0].content
		}
	})
	userInputArray.forEach((dbData, dbIndex) => {
		if (suggestedItem.toLowerCase() === dbText(dbData, state.language).toLowerCase()) {
			userValue = dbData.UserInput
		}
	})
	if (userValue !== null && userValue !== undefined)
		indirectSpend = findCalculatedValue(1, getSubLeverIdObject('1.1'), subLeverCalculation)// 1 is Indirect Spend rowNumber on UI
	else {
		sourceValue = round(round(getSourceValue(3, country, region, industry, suggestedItem), 4) * 100, 0)
		indirectSpend = round((spend * (sourceValue / 100)), 2)//Do calculation as Spend dependent calcualtions doesn't happen automatically in another sub levers
	}
	if ((Number(indirectSpend) + Number(value)) > Number(spend)) {
		isWarning = true
	}
	return isWarning
}

export const DirectSpendAddressableByStrategicSourcingValidation_1 = (rowNumber, subLeverId, subLeverCalculation) => {
	let isWarning = false, value, directSpend
	const subLever = getSubLeverIdObject(subLeverId)
	value = findCalculatedValue(rowNumber, subLever, subLeverCalculation)
	directSpend = findCalculatedValue(1, subLever, subLeverCalculation)// 1 is Direct Spend rowNumber on UI
	if (Number(value) > Number(directSpend)) {
		isWarning = true
	}
	return isWarning
}

export const DirectSpendAddressableByStrategicSourcingValidation_2 = (rowNumber, subLeverId, subLeverCalculation) => {
	let isWarning = false, value, directSpendStrategicallySourced
	const subLever = getSubLeverIdObject(subLeverId)
	value = findCalculatedValue(rowNumber, subLever, subLeverCalculation)
	directSpendStrategicallySourced = findCalculatedValue(3, subLever, subLeverCalculation)// 3 is Direct Spend currently strategically sourced rowNumber
	if (Number(value) < Number(directSpendStrategicallySourced)) {
		isWarning = true
	}
	return isWarning
}

export const DirectSpendStrategicallySourcedValidation_1 = (rowNumber, subLeverId, subLeverCalculation) => {
	let isWarning = false, value, directSpendAddressabelByStrategicSourcing
	const subLever = getSubLeverIdObject(subLeverId)
	value = findCalculatedValue(rowNumber, subLever, subLeverCalculation)
	directSpendAddressabelByStrategicSourcing = findCalculatedValue(2, subLever, subLeverCalculation)// 2 is Indirect Spend addressable by strategic sourcing rowNumber
	if (Number(value) > Number(directSpendAddressabelByStrategicSourcing)) {
		isWarning = true
	}
	return isWarning
}

export const IncrementalIndirectSpendStrategicallySourcedValidation_1 = (rowNumber, subLeverId, subLeverCalculation) => {
	let isWarning = false, value, indirectSpendAddressabelByStrategicSourcing
	const subLever = getSubLeverIdObject(subLeverId)
	value = findCalculatedValue(rowNumber, subLever, subLeverCalculation)
	indirectSpendAddressabelByStrategicSourcing = findCalculatedValue(2, subLever, subLeverCalculation)// 2 is Indirect Spend addressable by strategic sourcing rowNumber
	if (Number(value) > Number(indirectSpendAddressabelByStrategicSourcing)) {
		isWarning = true
	}
	return isWarning
}

export const IndirectSpendAddressableByTacticalSourcingValidation_1 = (rowNumber, subLeverId, subLeverCalculation) => {
	let isWarning = false, value, indirectSpend
	const subLever = getSubLeverIdObject(subLeverId)
	value = findCalculatedValue(rowNumber, subLever, subLeverCalculation)
	indirectSpend = findCalculatedValue(1, subLever, subLeverCalculation)// 1 is Indirect Spend rowNumber on UI
	if (Number(value) > Number(indirectSpend)) {
		isWarning = true
	}
	return isWarning
}

export const ExternalWorkforceAndServicesSpendValidation_1 = (rowNumber, subLeverId, subLeverCalculation) => {
	let isWarning = false, value, spend
	const subLever = getSubLeverIdObject(subLeverId)
	value = findCalculatedValue(rowNumber, subLever, subLeverCalculation)
	spend = findCalculatedValue(0, subLever, subLeverCalculation)// 0 is Spend rowNumber on UI
	if (Number(value) > Number(spend)) {
		isWarning = true
	}
	return isWarning
}

export const ExternalWorkforceAndServicesSpendValidation_2 = (rowNumber, subLeverId, subLeverCalculation) => {
	let isWarning = false, value, addressableExternalAndServicesSpend
	const subLever = getSubLeverIdObject(subLeverId)
	value = findCalculatedValue(rowNumber, subLever, subLeverCalculation)
	addressableExternalAndServicesSpend = findCalculatedValue(2, subLever, subLeverCalculation)// 2 is Addressable external and services spend rowNumber on UI
	if (Number(value) < Number(addressableExternalAndServicesSpend)) {
		isWarning = true
	}
	return isWarning
}

export const AddressableExternalAndServicesSpendValidation_1 = (rowNumber, subLeverId, subLeverCalculation) => {
	let isWarning = false, value, externalWorkforceAndServicesSpend
	const subLever = getSubLeverIdObject(subLeverId)
	value = findCalculatedValue(rowNumber, subLever, subLeverCalculation)
	externalWorkforceAndServicesSpend = findCalculatedValue(1, subLever, subLeverCalculation)// 1 is External workforce and services spend rowNumber on UI
	if (Number(value) > Number(externalWorkforceAndServicesSpend)) {
		isWarning = true
	}
	return isWarning
}

export const IndirectSpendAddressableByProcurementValidation_1 = (rowNumber, subLeverId, subLeverCalculation) => {
	let isWarning = false, value, indirectSpend
	const subLever = getSubLeverIdObject(subLeverId)
	value = findCalculatedValue(rowNumber, subLever, subLeverCalculation)
	indirectSpend = findCalculatedValue(1, subLever, subLeverCalculation)// 1 is Indirect Spend rowNumber on UI
	if (Number(value) > Number(indirectSpend)) {
		isWarning = true
	}
	return isWarning
}

export const IndirectSpendAddressableByProcurementValidation_2 = (rowNumber, subLeverId, subLeverCalculation) => {
	let isWarning = false, value, indirectSpendOrignatesFromUserRequisition
	const subLever = getSubLeverIdObject(subLeverId)
	value = findCalculatedValue(rowNumber, subLever, subLeverCalculation)
	indirectSpendOrignatesFromUserRequisition = findCalculatedValue(3, subLever, subLeverCalculation)// 3 is Indirect spend that orignates from a user requisition rowNumber on UI
	if (Number(value) < Number(indirectSpendOrignatesFromUserRequisition)) {
		isWarning = true
	}
	return isWarning
}

export const IndirectSpendOrignatesFromUserRequisitionValidation_1 = (rowNumber, subLeverId, subLeverCalculation) => {
	let isWarning = false, value, indirectSpendAddressableByProcurement
	const subLever = getSubLeverIdObject(subLeverId)
	value = findCalculatedValue(rowNumber, subLever, subLeverCalculation)
	indirectSpendAddressableByProcurement = findCalculatedValue(2, subLever, subLeverCalculation)// 2 is Indirect spend addressable by procurement 
	if (Number(value) > Number(indirectSpendAddressableByProcurement)) {
		isWarning = true
	}
	return isWarning
}

export const IndirectSpendAddressableByProcurementNotViaBlanketORPurchaseOrderValidation_1 = (rowNumber, subLeverId, subLeverCalculation) => {
	let isWarning = false, value, indirectSpend
	const subLever = getSubLeverIdObject(subLeverId)
	value = findCalculatedValue(rowNumber, subLever, subLeverCalculation)
	indirectSpend = findCalculatedValue(1, subLever, subLeverCalculation)// 1 is Indirect Spend rowNumber on UI
	if (Number(value) > Number(indirectSpend)) {
		isWarning = true
	}
	return isWarning
}

export const AnnualRevenueValidation_1 = (rowNumber, subLeverId, subLeverCalculation) => {
	let isWarning = false, value, revenueLossDueToStockOuts, annualRevenue
	const subLever = getSubLeverIdObject(subLeverId)
	value = findCalculatedValue(rowNumber, subLever, subLeverCalculation)
	revenueLossDueToStockOuts = findCalculatedValue(1, subLever, subLeverCalculation)// 1 is Revenue loss due to stock outs rowNumber on UI
	annualRevenue = findCalculatedValue(rowNumber, subLever, subLeverCalculation)// AnnualRevenue rowNumber on UI
	if (Number(annualRevenue) < Number(revenueLossDueToStockOuts)) {
		isWarning = true
	}
	return isWarning
}

export const AnnualRevenueValidation_2 = (rowNumber, subLeverId, subLeverCalculation) => {
	let isWarning = false, value, estimatedAnnualRevenueFromNewProducts
	const subLever = getSubLeverIdObject(subLeverId)
	value = findCalculatedValue(rowNumber, subLever, subLeverCalculation)
	estimatedAnnualRevenueFromNewProducts = findCalculatedValue(1, subLever, subLeverCalculation)// 1 is Estimated annual revenue from new products rowNumber on UI
	if (Number(value) < Number(estimatedAnnualRevenueFromNewProducts)) {
		isWarning = true
	}
	return isWarning
}

export const RevenueLossDueToStockOutsValidation = (rowNumber, subLeverId, subLeverCalculation) => {
	let isWarning = false, value, annualRevenue, revenueLossDueToStockOuts
	const subLever = getSubLeverIdObject(subLeverId)
	value = findCalculatedValue(rowNumber, subLever, subLeverCalculation)
	annualRevenue = findCalculatedValue(0, subLever, subLeverCalculation)// 0 is Annual Revenue rowNumber on UI
	revenueLossDueToStockOuts = findCalculatedValue(rowNumber, subLever, subLeverCalculation)// Annual Revenue rowNumber on UI

	if (Number(revenueLossDueToStockOuts) > Number(annualRevenue)) {
		isWarning = true
	}
	return isWarning
}

export const EstimatedAnnualRevenueFromNewProductsValidation = (rowNumber, subLeverId, subLeverCalculation) => {
	let isWarning = false, value, annualRevenue
	const subLever = getSubLeverIdObject(subLeverId)
	value = findCalculatedValue(rowNumber, subLever, subLeverCalculation)
	annualRevenue = findCalculatedValue(0, subLever, subLeverCalculation)// 0 is Annual Revenue rowNumber on UI
	if (Number(value) > Number(annualRevenue)) {
		isWarning = true
	}
	return isWarning
}

export const AnnualAuditCostValidation_1 = (rowNumber, subLeverId, subLeverCalculation) => {
	let isWarning = false, value, auditCostImpactedBySupplierRisk
	const subLever = getSubLeverIdObject(subLeverId)
	value = findCalculatedValue(rowNumber, subLever, subLeverCalculation)
	auditCostImpactedBySupplierRisk = findCalculatedValue(1, subLever, subLeverCalculation)// 1 is Audit cost impacted by supplier risk rowNumber on UI
	if (Number(value) < Number(auditCostImpactedBySupplierRisk)) {
		isWarning = true
	}
	return isWarning
}

export const AuditCostImpactedBySupplierRiskValidation_1 = (rowNumber, subLeverId, subLeverCalculation) => {
	let isWarning = false, value, annualAuditCost
	const subLever = getSubLeverIdObject(subLeverId)
	value = findCalculatedValue(rowNumber, subLever, subLeverCalculation)
	annualAuditCost = findCalculatedValue(0, subLever, subLeverCalculation)// 0 is Annual audit cost rowNumber on UI
	if (Number(value) > Number(annualAuditCost)) {
		isWarning = true
	}
	return isWarning
}

export const ExternalWorkforceAndServicesSpendValidation_3 = (rowNumber, subLeverId, subLeverCalculation) => {
	let isWarning = false, value, nonCompliantSpend
	const subLever = getSubLeverIdObject(subLeverId)
	value = findCalculatedValue(rowNumber, subLever, subLeverCalculation)
	nonCompliantSpend = findCalculatedValue(2, subLever, subLeverCalculation)// 2 is Non-compliant spend rowNumber on UI
	if (Number(value) < Number(nonCompliantSpend)) {
		isWarning = true
	}
	return isWarning
}

export const NonCompliantSpendValidation_1 = (rowNumber, subLeverId, subLeverCalculation) => {
	let isWarning = false, value, externalWorkforceAndServicesSpend
	const subLever = getSubLeverIdObject(subLeverId)
	value = findCalculatedValue(rowNumber, subLever, subLeverCalculation)
	externalWorkforceAndServicesSpend = findCalculatedValue(1, subLever, subLeverCalculation)// 1 is External workforce and services spend rowNumber on UI
	if (Number(value) > Number(externalWorkforceAndServicesSpend)) {
		isWarning = true
	}
	return isWarning
}

export const CurrentInvoicesProcessedPerAccountsPayableFullTimeEmployeeValidation_1 = (rowNumber, subLeverId, subLeverCalculation) => {
	let isWarning = false, value, futureInvoicesToBeProcessedPerAccountsPayableFullTimeEmployee
	const subLever = getSubLeverIdObject(subLeverId)
	value = findCalculatedValue(rowNumber, subLever, subLeverCalculation)
	futureInvoicesToBeProcessedPerAccountsPayableFullTimeEmployee = findCalculatedValue(3, subLever, subLeverCalculation)// 3 is Future invoices to be processed per accounts payable full time employee rowNumber on UI
	if (Number(value) > Number(futureInvoicesToBeProcessedPerAccountsPayableFullTimeEmployee)) {
		isWarning = true
	}
	return isWarning
}

export const FutureUnvoicesToBeProcessedPerAccountsPayableFullTimeEmployeeValidation_1 = (rowNumber, subLeverId, subLeverCalculation) => {
	let isWarning = false, value, currentInvoicesProcessedPerAccountsPayableFullTimeEmployee
	const subLever = getSubLeverIdObject(subLeverId)
	value = findCalculatedValue(rowNumber, subLever, subLeverCalculation)
	currentInvoicesProcessedPerAccountsPayableFullTimeEmployee = findCalculatedValue(1, subLever, subLeverCalculation)// 1 is Current invoices processed per accounts payable full time employee rowNumber on UI
	if (Number(value) < Number(currentInvoicesProcessedPerAccountsPayableFullTimeEmployee)) {
		isWarning = true
	}
	return isWarning
}

export const EstimatedTransportationSpendValidation_1 = (rowNumber, subLeverId, subLeverCalculation) => {
	let isWarning = false, value, inboundExpeditedTransportationSpend
	const subLever = getSubLeverIdObject(subLeverId)
	value = findCalculatedValue(rowNumber, subLever, subLeverCalculation)
	inboundExpeditedTransportationSpend = findCalculatedValue(1, subLever, subLeverCalculation)// 1 is Inbound expedited transportation spend rowNumber on UI
	if (Number(value) < Number(inboundExpeditedTransportationSpend)) {
		isWarning = true
	}
	return isWarning
}

export const InboundExpeditedTransportationSpendValidation_1 = (rowNumber, subLeverId, subLeverCalculation) => {
	let isWarning = false, value, estimatedTransportationSpend
	const subLever = getSubLeverIdObject(subLeverId)
	value = findCalculatedValue(rowNumber, subLever, subLeverCalculation)
	estimatedTransportationSpend = findCalculatedValue(0, subLever, subLeverCalculation)// 0 is Estimated transportation spend rowNumber on UI
	if (Number(value) > Number(estimatedTransportationSpend)) {
		isWarning = true
	}
	return isWarning
}

export const SpendWithPreNegotiatedDiscountsValidation_1 = (rowNumber, subLeverId, subLeverCalculation) => {
	let isWarning = false, value, spendWithPreNegotiatedDiscounts
	const subLever = getSubLeverIdObject(subLeverId)
	value = findCalculatedValue(rowNumber, subLever, subLeverCalculation)
	spendWithPreNegotiatedDiscounts = findCalculatedValue(1, subLever, subLeverCalculation)// 1 is Spend with pre-negotiated discounts rowNumber on UI
	if (Number(value) < Number(spendWithPreNegotiatedDiscounts)) {
		isWarning = true
	}
	return isWarning
}

export const SpendWithPreNegotiatedDiscountsValidation_2 = (rowNumber, subLeverId, subLeverCalculation) => {
	let isWarning = false, value, spendWithPreNegotiatedDiscounts
	const subLever = getSubLeverIdObject(subLeverId)
	value = findCalculatedValue(rowNumber, subLever, subLeverCalculation)
	spendWithPreNegotiatedDiscounts = findCalculatedValue(1, subLever, subLeverCalculation)// 1 is Spend with pre-negotiated discounts rowNumber on UI
	if (Number(value) < Number(spendWithPreNegotiatedDiscounts)) {
		isWarning = true
	}
	return isWarning
}

export const SpendWithPreNegotiatedDiscountsValidation_3 = (rowNumber, subLeverId, subLeverCalculation) => {
	let isWarning = false, value, spend
	const subLever = getSubLeverIdObject(subLeverId)
	value = findCalculatedValue(rowNumber, subLever, subLeverCalculation)
	spend = findCalculatedValue(0, subLever, subLeverCalculation)// 0 is Spend rowNumber on UI
	if (Number(value) > Number(spend)) {
		isWarning = true
	}
	return isWarning
}

export const SpendWithPreNegotiatedDiscountsValidation_4 = (rowNumber, subLeverId, subLeverCalculation) => {
	let isWarning = false, value, spend
	const subLever = getSubLeverIdObject(subLeverId)
	value = findCalculatedValue(rowNumber, subLever, subLeverCalculation)
	spend = findCalculatedValue(0, subLever, subLeverCalculation)// 0 is Spend rowNumber on UI
	if (Number(value) > Number(spend)) {
		isWarning = true
	}
	return isWarning
}

export const SpendNotAddressableForExtendingPaymentTermsValidation_1 = (rowNumber, subLeverId, subLeverCalculation) => {
	let isWarning = false, value, spend
	const subLever = getSubLeverIdObject(subLeverId)
	value = findCalculatedValue(rowNumber, subLever, subLeverCalculation)
	spend = findCalculatedValue(0, subLever, subLeverCalculation)// 0 is Spend rowNumber on UI
	if (Number(value) > Number(spend)) {
		isWarning = true
	}
	return isWarning
}

export const SpendNotAddressableForExtendingPaymentTermsValidation_2 = (rowNumber, subLeverId, subLeverCalculation) => {
	let isWarning = false, value, spend, spendToBeAddressedByPaymentTermExtension
	let subLever = getSubLeverIdObject(subLeverId)
	value = findCalculatedValue(rowNumber, subLever, subLeverCalculation)
	spendToBeAddressedByPaymentTermExtension = findCalculatedValue(3, subLever, subLeverCalculation)// 1 is Spend to be addressed by payment term extension rowNumber on UI
	subLever = getSubLeverIdObject(subLeverId)
	spend = findCalculatedValue(0, subLever, subLeverCalculation)// 0 is Spend rowNumber on UI
	if (Number(value) + Number(spendToBeAddressedByPaymentTermExtension) > Number(spend)) {
		isWarning = true
	}
	return isWarning
}

export const SpendToBeAddressedByPaymentTermExtensionValidation_1 = (rowNumber, subLeverId, subLeverCalculation) => {
	let isWarning = false, value, spend
	const subLever = getSubLeverIdObject(subLeverId)
	value = findCalculatedValue(rowNumber, subLever, subLeverCalculation)
	spend = findCalculatedValue(0, subLever, subLeverCalculation)// 0 is Spend rowNumber on UI
	if (Number(value) > Number(spend)) {
		isWarning = true
	}
	return isWarning
}

export const SpendToBeAddressedByPaymentTermExtensionValidation_2 = (rowNumber, subLeverId, subLeverCalculation) => {
	let isWarning = false, value, spend, spendNotAddressableForExtendingPaymentTerms
	let subLever = getSubLeverIdObject(subLeverId)
	value = findCalculatedValue(rowNumber, subLever, subLeverCalculation)
	spendNotAddressableForExtendingPaymentTerms = findCalculatedValue(1, subLever, subLeverCalculation)// 1 is Spend not addressable for extending payment terms rowNumber on UI
	subLever = getSubLeverIdObject(subLeverId)
	spend = findCalculatedValue(0, subLever, subLeverCalculation)// 0 is Spend rowNumber on UI
	if (Number(value) + Number(spendNotAddressableForExtendingPaymentTerms) > Number(spend)) {
		isWarning = true
	}
	return isWarning
}

export const dbText = (dbData, language) => {

	var itemName = language === "en" ? "Item" : "Item" + language.toUpperCase();
	return (dbData[itemName]).toLowerCase();
}
