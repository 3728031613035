import React from 'react'
import { connect } from 'react-redux'
import {
    getKPISetUpLoadData,
    getKPIDashBoardLoadData,
    updateKPISetUpTabData,
    updateKPIDashBoardTabData,
    trackKpiBenchMarkFilters
} from 'actions/kpiActions'
import {
    resetError,
    defineScopeLoading,    
    pendingChanges,    
} from 'actions/spendActions'
import Select from 'react-select'
import { round, roundFix, formatThousands, addCurrencyMask, convertToNumber, convertToMillion, onChangeRemoveMask } from 'utils/Utils'
import reactSelectCjsDev from 'react-select/dist/react-select.cjs.dev';
import { path, routes, modalStyle, modalContentStyle, changeModalBorderStyle, changeModalBorderRectStyle, drilldownModalBorderContentStyle,kpiRowCustomOrder, kpiFormatRowWithThousandMillion } from 'Constants'
import Modal from 'reboron/OutlineModal'
import classNames from "classnames";
import Button from 'UI/ButtonWithoutLoader'
import Popup from "reactjs-popup";
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import DateEditor from 'UI/DateEditor'
import CalendarDatePicker from 'UI/CalendarDatePicker'

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment'

class KPIContainer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            headers: ['KPI', 'Total', 'Eligible for Onboarding', 'Benchmark (%) Recommended', 'Benchmark (%) Adjusted', '', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
            headers2: ['KPI', 'Total', 'Eligible for Onboarding', 'Benchmark (%) Recommended', 'Benchmark (%) Adjusted', '', 'Q1', 'Q2', 'Q3', 'Q4', 'Q5', 'Q6', 'Q7', 'Q8', 'Q9', 'Q10', 'Q11', 'Q12'],
            //quaterly data may be added revisit logic 
            colGroupYearly: [{ key: 1, value: '' }, { key: 1, value: '' }, { key: 1, value: '' }, { key: 1, value: '' }, { key: 1, value: '' }, { key: 1, value: '' }, { key: 2, value: 'Year1' }, { key: 2, value: 'Year2' }, { key: 2, value: 'Year3' }],
            //below data is dummy data used for poc
            rowData: [
                { kpi: 'PO Count via Business Network', spend: '', efo: 1000000, BR1: 86.08, BR2: 0, BA1: 86.08, BA2: 0, HR: 'hr', H11: 25, H12: 0, H13: 0, H14: 0, H21: 25, H22: 0, H23: 0, H24: 0, H31: 20, H32: 0, H33: 0, H34: 0, H41: 20, H42: 0, H43: 0, H44: 0, H51: 5, H52: 0, H53: 0, H54: 0, H61: 5, H62: 0, H63: 0, H64: 0, HQ11: 12.50, HQ12: 0, HQ13: 0, HQ14: 0, HQ21: 12.50, HQ22: 0, HQ23: 0, HQ24: 0, HQ31: 12.50, HQ32: 0, HQ33: 0, HQ34: 0, HQ41: 12.50, HQ42: 0, HQ43: 0, HQ44: 0, HQ51: 10, HQ52: 0, HQ53: 0, HQ54: 0, HQ61: 10, HQ62: 0, HQ63: 0, HQ64: 0, HQ71: 10, HQ72: 0, HQ73: 0, HQ74: 0, HQ81: 10, HQ82: 0, HQ83: 0, HQ84: 0, HQ91: 2.50, HQ92: 0, HQ93: 0, HQ94: 0, HQ101: 2.50, HQ102: 0, HQ103: 0, HQ104: 0, HQ111: 2.50, HQ112: 0, HQ113: 0, HQ114: 0, HQ121: 2.50, HQ122: 0, HQ123: 0, HQ124: 0 },
                { kpi: 'PO Spend on Business Network', spend: '', efo: 1000000, BR1: 86.08, BR2: 0, BA1: 86.08, BA2: 0, HR: 'hr', H11: 25, H12: 0, H13: 0, H14: 0, H21: 25, H22: 0, H23: 0, H24: 0, H31: 20, H32: 0, H33: 0, H34: 0, H41: 20, H42: 0, H43: 0, H44: 0, H51: 5, H52: 0, H53: 0, H54: 0, H61: 5, H62: 0, H63: 0, H64: 0, HQ11: 12.50, HQ12: 0, HQ13: 0, HQ14: 0, HQ21: 12.50, HQ22: 0, HQ23: 0, HQ24: 0, HQ31: 12.50, HQ32: 0, HQ33: 0, HQ34: 0, HQ41: 12.50, HQ42: 0, HQ43: 0, HQ44: 0, HQ51: 10, HQ52: 0, HQ53: 0, HQ54: 0, HQ61: 10, HQ62: 0, HQ63: 0, HQ64: 0, HQ71: 10, HQ72: 0, HQ73: 0, HQ74: 0, HQ81: 10, HQ82: 0, HQ83: 0, HQ84: 0, HQ91: 2.50, HQ92: 0, HQ93: 0, HQ94: 0, HQ101: 2.50, HQ102: 0, HQ103: 0, HQ104: 0, HQ111: 2.50, HQ112: 0, HQ113: 0, HQ114: 0, HQ121: 2.50, HQ122: 0, HQ123: 0, HQ124: 0 },
                { kpi: 'Invoice Count via Business Network', spend: '', efo: 1000000, BR1: 86.08, BR2: 0, BA1: 86.08, BA2: 0, HR: 'hr', H11: 25, H12: 0, H13: 0, H14: 0, H21: 25, H22: 0, H23: 0, H24: 0, H31: 20, H32: 0, H33: 0, H34: 0, H41: 20, H42: 0, H43: 0, H44: 0, H51: 5, H52: 0, H53: 0, H54: 0, H61: 5, H62: 0, H63: 0, H64: 0, HQ11: 12.50, HQ12: 0, HQ13: 0, HQ14: 0, HQ21: 12.50, HQ22: 0, HQ23: 0, HQ24: 0, HQ31: 12.50, HQ32: 0, HQ33: 0, HQ34: 0, HQ41: 12.50, HQ42: 0, HQ43: 0, HQ44: 0, HQ51: 10, HQ52: 0, HQ53: 0, HQ54: 0, HQ61: 10, HQ62: 0, HQ63: 0, HQ64: 0, HQ71: 10, HQ72: 0, HQ73: 0, HQ74: 0, HQ81: 10, HQ82: 0, HQ83: 0, HQ84: 0, HQ91: 2.50, HQ92: 0, HQ93: 0, HQ94: 0, HQ101: 2.50, HQ102: 0, HQ103: 0, HQ104: 0, HQ111: 2.50, HQ112: 0, HQ113: 0, HQ114: 0, HQ121: 2.50, HQ122: 0, HQ123: 0, HQ124: 0 },
                { kpi: 'Invoice Spend on Business Network', spend: '', efo: 1000000, BR1: 86.08, BR2: 0, BA1: 86.08, BA2: 0, HR: 'hr', H11: 25, H12: 0, H13: 0, H14: 0, H21: 25, H22: 0, H23: 0, H24: 0, H31: 20, H32: 0, H33: 0, H34: 0, H41: 20, H42: 0, H43: 0, H44: 0, H51: 5, H52: 0, H53: 0, H54: 0, H61: 5, H62: 0, H63: 0, H64: 0, HQ11: 12.50, HQ12: 0, HQ13: 0, HQ14: 0, HQ21: 12.50, HQ22: 0, HQ23: 0, HQ24: 0, HQ31: 12.50, HQ32: 0, HQ33: 0, HQ34: 0, HQ41: 12.50, HQ42: 0, HQ43: 0, HQ44: 0, HQ51: 10, HQ52: 0, HQ53: 0, HQ54: 0, HQ61: 10, HQ62: 0, HQ63: 0, HQ64: 0, HQ71: 10, HQ72: 0, HQ73: 0, HQ74: 0, HQ81: 10, HQ82: 0, HQ83: 0, HQ84: 0, HQ91: 2.50, HQ92: 0, HQ93: 0, HQ94: 0, HQ101: 2.50, HQ102: 0, HQ103: 0, HQ104: 0, HQ111: 2.50, HQ112: 0, HQ113: 0, HQ114: 0, HQ121: 2.50, HQ122: 0, HQ123: 0, HQ124: 0 },
                { kpi: 'Supplier Transact in Business Network', spend: '', efo: 1000000, BR1: 86.08, BR2: 0, BA1: 86.08, BA2: 0, HR: 'hr', H11: 10, H12: 0, H13: 0, H14: 0, H21: 10, H22: 0, H23: 0, H24: 0, H31: 10, H32: 0, H33: 0, H34: 0, H41: 10, H42: 0, H43: 0, H44: 0, H51: 10, H52: 0, H53: 0, H54: 0, H61: 10, H62: 0, H63: 0, H64: 0, HQ11: 12.50, HQ12: 0, HQ13: 0, HQ14: 0, HQ21: 12.50, HQ22: 0, HQ23: 0, HQ24: 0, HQ31: 12.50, HQ32: 0, HQ33: 0, HQ34: 0, HQ41: 12.50, HQ42: 0, HQ43: 0, HQ44: 0, HQ51: 10, HQ52: 0, HQ53: 0, HQ54: 0, HQ61: 10, HQ62: 0, HQ63: 0, HQ64: 0, HQ71: 10, HQ72: 0, HQ73: 0, HQ74: 0, HQ81: 10, HQ82: 0, HQ83: 0, HQ84: 0, HQ91: 2.50, HQ92: 0, HQ93: 0, HQ94: 0, HQ101: 2.50, HQ102: 0, HQ103: 0, HQ104: 0, HQ111: 2.50, HQ112: 0, HQ113: 0, HQ114: 0, HQ121: 2.50, HQ122: 0, HQ123: 0, HQ124: 0 }
            ],
            spanValues: [
                { value: 'Half', label: 'Half' },
                { value: 'Quarter', label: 'Quarter' }
            ],
            selectedSpanValue: 'Quarter',
            spData: [],
            tableData: [],
            isLoading: false,
            benchmarkPercentileValues: [
                { value: 'Top 25%', label: 'Top 25%' },
                { value: 'Average 50%', label: 'Average 50%' },
                { value: 'Bottom 25%', label: 'Bottom 25%' }
            ],
            selectedBPValue: 'Average 50%',
            metricValues: [],
            selectedMetricValue: 'ALL',
            metricFilteredData: [],
            isSaveInProgress: false,
            selectedBPFlag: 'AVERAGE',
            metricID_Edited: [],
            keyBPSelected: 'AVG_BENCHMARK_ADJUS',
            dataLoaded: false,
            startdate: null,
            goLiveDate: null,
            kpiBenchMarkFilters: {metric: 'ALL', type: 'AVERAGE'}
        }
        this.spendChange = this.spendChange.bind(this);
        this.calcBenchMarkSpend = this.calcBenchMarkSpend.bind(this);
        this.benchMarkAdjusted = this.benchMarkAdjusted.bind(this);
        this.hPercentChanged = this.hPercentChanged.bind(this);
        this.handleSpanChange = this.handleSpanChange.bind(this);
        this.hQPercentChanged = this.hQPercentChanged.bind(this);
        this.calcBenchMarkSpendForQuater = this.calcBenchMarkSpendForQuater.bind(this);
        this.loadData = this.loadData.bind(this);
        this.handleBPChange = this.handleBPChange.bind(this);
        this.handleMetricChange = this.handleMetricChange.bind(this);
        this.onSave = this.onSave.bind(this);
        this.handleResetTable = this.handleResetTable.bind(this);
        this.setStartDate = this.setStartDate.bind(this);
        this.setGoLiveDate = this.setGoLiveDate.bind(this);
    }

    componentDidMount() {
        this.props.trackKpiBenchMarkFilters(this.state.kpiBenchMarkFilters)
        this.loadData()
        this.setState({ currencySymbol: this.props.spendDetails.currentActiveRequest !== undefined ? this.props.currencySymbols[this.props.spendDetails.currentActiveRequest.Currency.trim()] : '' })
    }


    setStartDate(date) {
        this.props.pendingChanges(true)
        this.setState({ startdate: date, isSaveInProgress: true, responseMsg: "Pending changes. Click save to update KPIs.", })
    }

    setGoLiveDate(date) {
        this.props.pendingChanges(true)
        this.setState({ goLiveDate: date, isSaveInProgress: true, responseMsg: "Pending changes. Click save to update KPIs.", })
    }

    handleResetTable() {
        this.setState({ responseMsg: '' })
        this.loadData()
    }

    loadData() {
        let dmcId = this.props.spendDetails.currentActiveRequest.DMC_ID;
        let vsId = this.props.spendDetails.currentActiveRequest.VS_ID;
        let user = this.props.user.user.Email;
        this.props.defineScopeLoading(true)
        this.setState({ isLoading: true })
        this.props.getKPISetUpLoadData(dmcId, vsId, user)
            .then((response) => {
                if (response.data.Result.length > 0) {
                    let spData = response.data.Result;
                    let unique = spData.filter(item => item.BENCHMARK_METRICS == this.state.selectedMetricValue)
                    //https://stackoverflow.com/questions/15125920/how-to-get-distinct-values-from-an-array-of-objects-in-javascript
                    // let unique = [...new Map(temp.map(item =>
                    //     [item['METRIC_ID'], item])).values()];

                    let uniqueMetrics = [...new Set(spData.map(item => item.BENCHMARK_METRICS))];
                    let metricValues = [];
                    for (let i = 0; i < uniqueMetrics.length; i++) {
                        let obj = {}
                        obj.label = uniqueMetrics[i]
                        obj.value = uniqueMetrics[i]

                        metricValues.push(obj)
                    }

                    let tableData = this.createTableData(unique, this.state.selectedBPValue)

                    let data = tableData;
                    for (let i = 0; i < data.length; i++) {
                        this.state.selectedSpanValue == 'Half' ? this.calcBenchMarkSpend(data[i], i, data) : this.calcBenchMarkSpendForQuater(data[i], i, data)
                    }
                    this.props.defineScopeLoading(false)
                    let goLiveDate = spData[0]["GO_LIVE_DATE"] !== null ? new Date(spData[0]["GO_LIVE_DATE"]) : null;
                    let projectStartDate = spData[0]["PROJECT_START_DATE"] !== null ? new Date(spData[0]["PROJECT_START_DATE"]) : null;
                    this.setState({ spData: spData, startdate: projectStartDate, goLiveDate: goLiveDate, metricFilteredData: unique, tableData: tableData, metricValues: metricValues, isLoading: false, isSaveInProgress: false, dataLoaded: true })
                } else {
                    this.props.defineScopeLoading(false)
                    this.setState({ errorMsg: 'No result found. Please try reimporting the file.', isError: 'Yes', isLoading: false })
                }

            }).catch((error) => {
                if (error.stack.includes('Network Error')) {
                    this.props.defineScopeLoading(false)
                    this.setState({ errorMsg: 'API service is down. Please contact administrator.', isError: 'Yes', isLoading: false })
                }
                else {
                    this.props.defineScopeLoading(false)
                    this.setState({ errorMsg: this.props.content.error, isError: 'Yes', isLoading: false })
                }
            })
    }

    updateKPIDashBoard() {        

        let records = []
        let dmcId = this.props.spendDetails.currentActiveRequest.DMC_ID;
        let vsId = this.props.spendDetails.currentActiveRequest.VS_ID;
        let userEmail = this.props.user.user.Email;
        
        let obj = {}
        obj["VS_ID"] = vsId;
        obj["DMC_ID"] = dmcId;
        obj["USER_ID"] = userEmail;
        obj["BENCHMARK_METRICS"] = this.state.selectedMetricValue;
        obj["BENCHMARK_TYPE"] = this.state.selectedBPFlag;
        obj["ACTUAL_FLAG"] = 1;
        records.push(obj)
       
        this.setState({ isLoading: true, isSaveInProgress: true, responseMsg: '' })
        this.props.defineScopeLoading(true)

        this.props.updateKPIDashBoardTabData(records)
            .then((response) => {
                //this.loadData()
                this.setState({ responseMsg: 'Record/s has been updated successfully', isError: 'No', isLoading: false, isSaveInProgress: false, editedRowsIds: [] })
                this.props.defineScopeLoading(false)
                this.props.pendingChanges(null)
            }).catch((error) => {
                if (error.stack.includes('Network Error')) {
                    this.props.defineScopeLoading(false)
                    this.setState({ errorMsg: 'API service is down. Please contact administrator.', isError: 'Yes', isLoading: false, isSaveInProgress: false })
                }
                else {
                    this.props.defineScopeLoading(false)
                    this.setState({ errorMsg: this.props.content.error, isError: 'Yes', isLoading: false, isSaveInProgress: false })
                }
            })
    }


    createTableData(data, selectedBPValue) {
        let tableData = []
        let key = '' //this key is used to map ui metric value with HANA col value
        if (selectedBPValue == 'Top 25%') {
            key = 'TOP_25'
        } else if (selectedBPValue == 'Average 50%') {
            key = 'AVERAGE'
        } else {
            key = 'BOTTOM_25'
        }
        //filter data to get unique arr with selected metric and industry values
        let unique = data.filter(item => item.BENCHMARK_TYPE == key)

        //use kpiArr to further filter data on granular level as HANA data has many duplicate records
        let kpiArr = ['Invoice Spend on Business Network', 'Invoice Count via Business Network', 'PO Spend on Business Network', 'PO Count via Business Network', 'Suppliers in Business Network']
        for (let i = 0; i < kpiArr.length; i++) {
            let temp = unique.filter(item => item.KPI_NAME == kpiArr[i])
            let obj = this.createTableDataObject(temp)
            tableData.push(obj)
        }
        //tableData.sort((a, b) => (a.srNo > b.srNo) ? 1 : -1)//sort the table always by sr_no
        tableData.sort((a, b) => kpiRowCustomOrder[a.srNo] - kpiRowCustomOrder[b.srNo]);
        return tableData;
    }

    //this function creates an object for each single row of kpi setup table which is used to construct table data
    createTableDataObject(kpiArr) {
        let obj = {}
        for (let i = 0; i < kpiArr.length; i++) {
            obj.METRIC_ID = kpiArr[i]['METRIC_ID']
            obj.VS_ID = kpiArr[i]['VS_ID']
            obj.DMC_ID = kpiArr[i]['DMC_ID']
            obj.kpi = kpiArr[i]['KPI_NAME']
            obj.srNo = kpiArr[i]['KPI_SR_NO']
            obj.BENCHMARK_METRICS = kpiArr[i]['BENCHMARK_METRICS']
            obj.spend = kpiArr[i]['SPEND_DATA_VALUE']
            obj.efo = kpiArr[i]['ELIGIBLE_FOR_ONBOARDING']
            obj.efo2 = kpiArr[i]['ELIGIBLE_FOR_ONBOARDING'] / 1000000 //adding this for masked input for spend data
            obj.BR1 = roundFix(kpiArr[i]['BENCHMARK_RECOMD'], 2)
            obj.BR2 = 0
            obj.BA1 = roundFix(kpiArr[i]['BENCHMARK_ADJUS'], 2)
            obj.BA2 = 0
            obj.HR = 'hr'
            if (kpiArr[i]['YEAR_NO'] == 1 && kpiArr[i]['QUARTER_NO'] == 'Q1') obj.HQ11 = kpiArr[i]['H_PERCENTAGE_VALUE']
            obj.HQ12 = 0
            obj.HQ13 = 0
            obj.HQ14 = 0
            if (kpiArr[i]['YEAR_NO'] == 1 && kpiArr[i]['QUARTER_NO'] == 'Q2') obj.HQ21 = kpiArr[i]['H_PERCENTAGE_VALUE']
            obj.HQ22 = 0
            obj.HQ23 = 0
            obj.HQ24 = 0
            if (kpiArr[i]['YEAR_NO'] == 1 && kpiArr[i]['QUARTER_NO'] == 'Q3') obj.HQ31 = kpiArr[i]['H_PERCENTAGE_VALUE']
            obj.HQ32 = 0
            obj.HQ33 = 0
            obj.HQ34 = 0
            if (kpiArr[i]['YEAR_NO'] == 1 && kpiArr[i]['QUARTER_NO'] == 'Q4') obj.HQ41 = kpiArr[i]['H_PERCENTAGE_VALUE']
            obj.HQ42 = 0
            obj.HQ43 = 0
            obj.HQ44 = 0
            if (kpiArr[i]['YEAR_NO'] == 2 && kpiArr[i]['QUARTER_NO'] == 'Q5') obj.HQ51 = kpiArr[i]['H_PERCENTAGE_VALUE']
            obj.HQ52 = 0
            obj.HQ53 = 0
            obj.HQ54 = 0
            if (kpiArr[i]['YEAR_NO'] == 2 && kpiArr[i]['QUARTER_NO'] == 'Q6') obj.HQ61 = kpiArr[i]['H_PERCENTAGE_VALUE']
            obj.HQ62 = 0
            obj.HQ63 = 0
            obj.HQ64 = 0
            if (kpiArr[i]['YEAR_NO'] == 2 && kpiArr[i]['QUARTER_NO'] == 'Q7') obj.HQ71 = kpiArr[i]['H_PERCENTAGE_VALUE']
            obj.HQ72 = 0
            obj.HQ73 = 0
            obj.HQ74 = 0
            if (kpiArr[i]['YEAR_NO'] == 2 && kpiArr[i]['QUARTER_NO'] == 'Q8') obj.HQ81 = kpiArr[i]['H_PERCENTAGE_VALUE']
            obj.HQ82 = 0
            obj.HQ83 = 0
            obj.HQ84 = 0
            if (kpiArr[i]['YEAR_NO'] == 3 && kpiArr[i]['QUARTER_NO'] == 'Q9') obj.HQ91 = kpiArr[i]['H_PERCENTAGE_VALUE']
            obj.HQ92 = 0
            obj.HQ93 = 0
            obj.HQ94 = 0
            if (kpiArr[i]['YEAR_NO'] == 3 && kpiArr[i]['QUARTER_NO'] == 'Q10') obj.HQ101 = kpiArr[i]['H_PERCENTAGE_VALUE']
            obj.HQ102 = 0
            obj.HQ103 = 0
            obj.HQ104 = 0
            if (kpiArr[i]['YEAR_NO'] == 3 && kpiArr[i]['QUARTER_NO'] == 'Q11') obj.HQ111 = kpiArr[i]['H_PERCENTAGE_VALUE']
            obj.HQ112 = 0
            obj.HQ113 = 0
            obj.HQ114 = 0
            if (kpiArr[i]['YEAR_NO'] == 3 && kpiArr[i]['QUARTER_NO'] == 'Q12') obj.HQ121 = kpiArr[i]['H_PERCENTAGE_VALUE']
            obj.HQ122 = 0
            obj.HQ123 = 0
            obj.HQ124 = 0
            obj.H11 = Number(obj.HQ11) + Number(obj.HQ21)
            obj.H12 = 0
            obj.H13 = 0
            obj.H14 = 0
            obj.H21 = Number(obj.HQ31) + Number(obj.HQ41)
            obj.H22 = 0
            obj.H23 = 0
            obj.H24 = 0
            obj.H31 = Number(obj.HQ51) + Number(obj.HQ61)
            obj.H32 = 0
            obj.H33 = 0
            obj.H34 = 0
            obj.H41 = Number(obj.HQ71) + Number(obj.HQ81)
            obj.H42 = 0
            obj.H43 = 0
            obj.H44 = 0
            obj.H51 = Number(obj.HQ91) + Number(obj.HQ101)
            obj.H52 = 0
            obj.H53 = 0
            obj.H54 = 0
            obj.H61 = Number(obj.HQ111) + Number(obj.HQ121)
            obj.H62 = 0
            obj.H63 = 0
            obj.H64 = 0


        }

        return obj;
    }

    hQPercentChanged(element) {
        let cellId = element.target.attributes.cellId.nodeValue;
        let val = onChangeRemoveMask(element, '%');
        let id = element.target.id;
        let data = this.state.tableData;
        let unique = this.state.metricFilteredData;
        let metricID_Edited = this.state.metricID_Edited;
        metricID_Edited.push(data[id]['METRIC_ID'])
        if (data[id][cellId] != val) {
            data[id][cellId] = val;
            this.calcBenchMarkSpendForQuater(data[id], id, data)
            this.props.pendingChanges(true)
            this.setState({ tableData: data, unique: unique, isSaveInProgress: true, responseMsg: "Pending changes. Click save to update KPIs.", metricID_Edited: metricID_Edited })
        }
    }

    handleSpanChange(value) {
        let unique = this.state.metricFilteredData;
        let data = this.createTableData(unique, this.state.selectedBPValue)
        for (let i = 0; i < data.length; i++) {
            value.value == 'Half' ? this.calcBenchMarkSpend(data[i], i, data) : this.calcBenchMarkSpendForQuater(data[i], i, data)
        }
        this.setState({ selectedSpanValue: value.value, tableData: data })
    }

    handleBPChange(option) {
        let value = option.value;
        let unique = this.state.metricFilteredData;
        let data = this.createTableData(unique, value)
        for (let i = 0; i < data.length; i++) {
            this.state.selectedSpanValue == 'Half' ? this.calcBenchMarkSpend(data[i], i, data) : this.calcBenchMarkSpendForQuater(data[i], i, data)
        }
        let key = ''
        let flag = ''
        if (value == 'Top 25%') {
            key = 'TOP_BENCHMARK_ADJUS'
            flag = 'TOP_25'
        } else if (value == 'Average 50%') {
            key = 'AVG_BENCHMARK_ADJUS'
            flag = 'AVERAGE'
        } else {
            key = 'BOTTOM_BENCHMARK_ADJUS'
            flag = 'BOTTOM_25'
        }
        //track filters
        let filter = this.state.kpiBenchMarkFilters;
        //filter.type = value;
        filter.type = flag;
        this.props.trackKpiBenchMarkFilters(filter)

        this.setState({ selectedBPValue: value, tableData: data, selectedBPFlag: flag, keyBPSelected: key, kpiBenchMarkFilters: filter})
    }

    handleMetricChange(option) {
        let value = option.value;
        let spData = this.state.spData;
        let unique = spData.filter(item => item.BENCHMARK_METRICS == value)
        let data = this.createTableData(unique, this.state.selectedBPValue)
        for (let i = 0; i < data.length; i++) {
            this.state.selectedSpanValue == 'Half' ? this.calcBenchMarkSpend(data[i], i, data) : this.calcBenchMarkSpendForQuater(data[i], i, data)
        }

        //track filters
        let filter = this.state.kpiBenchMarkFilters;
        filter.metric = value;
        this.props.trackKpiBenchMarkFilters(filter)

        this.setState({ selectedMetricValue: value, tableData: data, metricFilteredData: unique, kpiBenchMarkFilters: filter })
    }

    hPercentChanged(element) {
        let cellId = element.target.attributes.cellId.nodeValue;
        let val = Number(element.target.value);
        let id = element.target.id;
        let data = this.state.tableData;

        data[id][cellId] = val;
        this.calcBenchMarkSpend(data[id], id, data)
    }

    benchMarkAdjusted(element) {
        let val = onChangeRemoveMask(element, '%')
        let id = element.target.id;
        let data = this.state.tableData;
        let unique = this.state.metricFilteredData;
        let selectedBPValue = this.state.selectedBPValue
        let key = ''
        let flag = ''
        if (selectedBPValue == 'Top 25%') {
            key = 'TOP_BENCHMARK_ADJUS'
            flag = 'TOP_25'
        } else if (selectedBPValue == 'Average 50%') {
            key = 'AVG_BENCHMARK_ADJUS'
            flag = 'AVERAGE'
        } else {
            key = 'BOTTOM_BENCHMARK_ADJUS'
            flag = 'BOTTOM_25'
        }

        let metricID_Edited = this.state.metricID_Edited;
        metricID_Edited.push(data[id]['METRIC_ID'])

        if (data[id]['BA1'] != val) {
            data[id]['BA1'] = val;
            this.state.selectedSpanValue == 'Half' ? this.calcBenchMarkSpend(data[id], id, data) : this.calcBenchMarkSpendForQuater(data[id], id, data)
            this.props.pendingChanges(true)
            this.setState({ unique: unique, isSaveInProgress: true, responseMsg: "Pending changes. Click save to update KPIs.", keyBPSelected: key, selectedBPFlag: flag, metricID_Edited: metricID_Edited })
        }
    }

    spendChange(element) {
        let val = onChangeRemoveMask(element, this.state.currencySymbol);
        if(val == "") val = null;
        let id = element.target.id;
        let data = this.state.tableData;
        let unique = this.state.metricFilteredData;
        let metricID_Edited = this.state.metricID_Edited;
        metricID_Edited.push(data[id]['METRIC_ID'])
        
        if (kpiFormatRowWithThousandMillion[id] == 'Million' ? round(data[id]['efo2'], 2) != val : data[id]['efo'] != val) {
            kpiFormatRowWithThousandMillion[id] == 'Million' ? data[id]['efo'] = val * 1000000 : data[id]['efo'] = val;
            data[id]['efo2'] = val;
            this.state.selectedSpanValue == 'Half' ? this.calcBenchMarkSpend(data[id], id, data) : this.calcBenchMarkSpendForQuater(data[id], id, data)
            this.setState({ unique: unique, isSaveInProgress: true, responseMsg: "Pending changes. Click save to update KPIs.", metricID_Edited: metricID_Edited })
        }
    }

    calcBenchMarkSpend(obj, key, data) {
        //benchmark calc (BR2, BA2)
        let spend1 = round((obj.BR1 * obj.efo) / 100, 2);
        let spend2 = round((obj.BA1 * obj.efo) / 100, 2);

        data[key].BR2 = spend1;
        data[key].BA2 = spend2;

        //cumulative % calc (H12, H22, H32, H42, H52, H62)
        let element = data[key];
        element.H12 = Number(element.H11);
        element.H22 = Number(element.H11) + Number(element.H21);
        element.H32 = Number(element.H11) + Number(element.H21) + Number(element.H31);
        element.H42 = Number(element.H11) + Number(element.H21) + Number(element.H31) + Number(element.H41);
        element.H52 = Number(element.H11) + Number(element.H21) + Number(element.H31) + Number(element.H41) + Number(element.H51);
        element.H62 = Number(element.H11) + Number(element.H21) + Number(element.H31) + Number(element.H41) + Number(element.H51) + Number(element.H61);

        //Target H calc (H13, H23, H33, H43, H53, H63)
        // let sp2 = spend2 !== 0? convertToNumber(spend2) : 0;
        element.H13 = round((spend2 * element.H11) / 100, 2);
        element.H23 = round((spend2 * element.H21) / 100, 2);
        element.H33 = round((spend2 * element.H31) / 100, 2);
        element.H43 = round((spend2 * element.H41) / 100, 2);
        element.H53 = round((spend2 * element.H51) / 100, 2);
        element.H63 = round((spend2 * element.H61) / 100, 2);

        //Target Cumulative calc (H14, H24, H34, H44, H54, H64)
        element.H14 = round(element.H13, 2);
        element.H24 = round(element.H13 + element.H23, 2);
        element.H34 = round(element.H13 + element.H23 + element.H33, 2);
        element.H44 = round(element.H13 + element.H23 + element.H33 + element.H43, 2);
        element.H54 = round(element.H13 + element.H23 + element.H33 + element.H43 + element.H53, 2);
        element.H64 = round(element.H13 + element.H23 + element.H33 + element.H43 + element.H53 + element.H63, 2);

        this.setState({ tableData: data })
    }

    calcBenchMarkSpendForQuater(obj, key, data) {
        //benchmark calc (BR2, BA2)
        let spend1 = round((obj.BR1 * obj.efo) / 100, 2);
        let spend2 = round((obj.BA1 * obj.efo) / 100, 2);

        data[key].BR2 = spend1;
        data[key].BA2 = spend2;

        //cumulative % calc (H12, H22, H32, H42, H52, H62)
        let element = data[key];
        element.HQ12 = Number(element.HQ11);
        element.HQ22 = Number(element.HQ11) + Number(element.HQ21);
        element.HQ32 = Number(element.HQ11) + Number(element.HQ21) + Number(element.HQ31);
        element.HQ42 = Number(element.HQ11) + Number(element.HQ21) + Number(element.HQ31) + Number(element.HQ41);
        element.HQ52 = Number(element.HQ11) + Number(element.HQ21) + Number(element.HQ31) + Number(element.HQ41) + Number(element.HQ51);
        element.HQ62 = Number(element.HQ11) + Number(element.HQ21) + Number(element.HQ31) + Number(element.HQ41) + Number(element.HQ51) + Number(element.HQ61);
        element.HQ72 = Number(element.HQ11) + Number(element.HQ21) + Number(element.HQ31) + Number(element.HQ41) + Number(element.HQ51) + Number(element.HQ61) + Number(element.HQ71);
        element.HQ82 = Number(element.HQ11) + Number(element.HQ21) + Number(element.HQ31) + Number(element.HQ41) + Number(element.HQ51) + Number(element.HQ61) + Number(element.HQ71) + Number(element.HQ81);
        element.HQ92 = Number(element.HQ11) + Number(element.HQ21) + Number(element.HQ31) + Number(element.HQ41) + Number(element.HQ51) + Number(element.HQ61) + Number(element.HQ71) + Number(element.HQ81) + Number(element.HQ91);
        element.HQ102 = Number(element.HQ11) + Number(element.HQ21) + Number(element.HQ31) + Number(element.HQ41) + Number(element.HQ51) + Number(element.HQ61) + Number(element.HQ71) + Number(element.HQ81) + Number(element.HQ91) + Number(element.HQ101);
        element.HQ112 = Number(element.HQ11) + Number(element.HQ21) + Number(element.HQ31) + Number(element.HQ41) + Number(element.HQ51) + Number(element.HQ61) + Number(element.HQ71) + Number(element.HQ81) + Number(element.HQ91) + Number(element.HQ101) + Number(element.HQ111);
        element.HQ122 = Number(element.HQ11) + Number(element.HQ21) + Number(element.HQ31) + Number(element.HQ41) + Number(element.HQ51) + Number(element.HQ61) + Number(element.HQ71) + Number(element.HQ81) + Number(element.HQ91) + Number(element.HQ101) + Number(element.HQ111) + Number(element.HQ121);

        if (element.HQ122 !== 100) {
            this.setState({ isError: 'Yes', errorMsg: "Warning: KPI does not total 100%" })
        } else {
            this.setState({ isError: 'No', errorMsg: '' })
        }

        //Target H calc (H13, H23, H33, H43, H53, H63)
        element.HQ13 = round((spend2 * element.HQ11) / 100, 2);
        element.HQ23 = round((spend2 * element.HQ21) / 100, 2);
        element.HQ33 = round((spend2 * element.HQ31) / 100, 2);
        element.HQ43 = round((spend2 * element.HQ41) / 100, 2);
        element.HQ53 = round((spend2 * element.HQ51) / 100, 2);
        element.HQ63 = round((spend2 * element.HQ61) / 100, 2);
        element.HQ73 = round((spend2 * element.HQ71) / 100, 2);
        element.HQ83 = round((spend2 * element.HQ81) / 100, 2);
        element.HQ93 = round((spend2 * element.HQ91) / 100, 2);
        element.HQ103 = round((spend2 * element.HQ101) / 100, 2);
        element.HQ113 = round((spend2 * element.HQ111) / 100, 2);
        element.HQ123 = round((spend2 * element.HQ121) / 100, 2);

        //Target Cumulative calc (H14, H24, H34, H44, H54, H64)
        element.HQ14 = round(element.HQ13, 2);
        element.HQ24 = round(element.HQ13 + element.HQ23, 2);
        element.HQ34 = round(element.HQ13 + element.HQ23 + element.HQ33, 2);
        element.HQ44 = round(element.HQ13 + element.HQ23 + element.HQ33 + element.HQ43, 2);
        element.HQ54 = round(element.HQ13 + element.HQ23 + element.HQ33 + element.HQ43 + element.HQ53, 2);
        element.HQ64 = round(element.HQ13 + element.HQ23 + element.HQ33 + element.HQ43 + element.HQ53 + element.HQ63, 2);
        element.HQ74 = round(element.HQ13 + element.HQ23 + element.HQ33 + element.HQ43 + element.HQ53 + element.HQ63 + element.HQ73, 2);
        element.HQ84 = round(element.HQ13 + element.HQ23 + element.HQ33 + element.HQ43 + element.HQ53 + element.HQ63 + element.HQ73 + element.HQ83, 2);
        element.HQ94 = round(element.HQ13 + element.HQ23 + element.HQ33 + element.HQ43 + element.HQ53 + element.HQ63 + element.HQ73 + element.HQ83 + element.HQ93, 2);
        element.HQ104 = round(element.HQ13 + element.HQ23 + element.HQ33 + element.HQ43 + element.HQ53 + element.HQ63 + element.HQ73 + element.HQ83 + element.HQ93 + element.HQ103, 2);
        element.HQ114 = round(element.HQ13 + element.HQ23 + element.HQ33 + element.HQ43 + element.HQ53 + element.HQ63 + element.HQ73 + element.HQ83 + element.HQ93 + element.HQ103 + element.HQ113, 2);
        element.HQ124 = round(element.HQ13 + element.HQ23 + element.HQ33 + element.HQ43 + element.HQ53 + element.HQ63 + element.HQ73 + element.HQ83 + element.HQ93 + element.HQ103 + element.HQ113 + element.HQ123, 2);

        this.setState({ tableData: data })
    }

    onSave() {
        let data = [];
        let unique = this.state.tableData;
        let userEmail = this.props.user.user.Email;
        let metricID_Edited = this.state.metricID_Edited;
        let flag = this.state.selectedBPFlag;
        let key = this.state.keyBPSelected;
        data = unique.filter((item, index) => metricID_Edited.includes(item.METRIC_ID))
        let spData = this.state.spData[0];
        let records = []
        //if data is null then user only edited date fields so send date value with any row data
        if (data.length > 0) {
            for (let i = 0; i < data.length; i++) {
                for (let j = 1; j <= 12; j++) {
                    let obj = {}
                    obj["VS_ID"] = data[i].VS_ID;
                    obj["DMC_ID"] = data[i].DMC_ID;
                    obj["USER_ID"] = userEmail;
                    obj["METRIC_ID"] = data[i].METRIC_ID;
                    obj["BENCHMARK_METRICS"] = data[i].BENCHMARK_METRICS;
                    obj["BENCHMARK_TYPE"] = flag;
                    obj["ELIGIBLE_FOR_ONBOARDING"] = data[i].efo;
                    obj["BENCHMARK_ADJUS"] = data[i].BA1;
                    obj["YEAR_NO"] = j == 1 || j == 2 || j == 3 || j == 4 ? 1 : j == 5 || j == 6 || j == 7 || j == 8 ? 2 : 3;
                    obj["QUARTER_NO"] = 'Q' + j;
                    obj["H_PERCENTAGE_VALUE"] = data[i]["HQ" + j + '1']
                    obj["CUMULATIVE_VALUE"] = data[i]["HQ" + j + '2']
                    obj["TARGET_VALUE"] = data[i]["HQ" + j + '3']
                    obj["CUMULATIVE_TARGET_VALUE"] = data[i]["HQ" + j + '4']
                    obj["GO_LIVE_DATE"] = this.state.goLiveDate;
                    obj["PROJECT_START_DATE"] = this.state.startdate;

                    records.push(obj)
                }
            }
        } else {
            let obj = {}
            obj["VS_ID"] = spData.VS_ID;
            obj["DMC_ID"] = spData.DMC_ID;
            obj["USER_ID"] = userEmail;
            obj["METRIC_ID"] = spData.METRIC_ID;
            obj["BENCHMARK_METRICS"] = spData.BENCHMARK_METRICS;
            obj["BENCHMARK_TYPE"] = flag;
            obj["ELIGIBLE_FOR_ONBOARDING"] = spData.efo;
            obj["BENCHMARK_ADJUS"] = spData.BENCHMARK_ADJUS;
            obj["YEAR_NO"] = spData.YEAR_NO;
            obj["QUARTER_NO"] = spData.QUARTER_NO;
            obj["H_PERCENTAGE_VALUE"] = spData.H_PERCENTAGE_VALUE;
            obj["CUMULATIVE_VALUE"] = spData.CUMULATIVE_VALUE;
            obj["TARGET_VALUE"] = spData.TARGET_VALUE;
            obj["CUMULATIVE_TARGET_VALUE"] = spData.CUMULATIVE_TARGET_VALUE;
            obj["GO_LIVE_DATE"] = this.state.goLiveDate;
            obj["PROJECT_START_DATE"] = this.state.startdate;

            records.push(obj)
        }
        this.setState({ isLoading: true, isSaveInProgress: true })
        this.props.defineScopeLoading(true)
        //call hana procedure for save here
        this.props.updateKPISetUpTabData(records)
            .then((response) => {
                this.loadData()
                this.updateKPIDashBoard()
                this.props.pendingChanges(null)
                this.setState({ responseMsg: 'Record/s has been updated successfully', isError: 'No', isLoading: false, isSaveInProgress: false })
                this.props.defineScopeLoading(false)
            }).catch((error) => {
                if (error.stack.includes('Network Error')) {
                    this.props.defineScopeLoading(false)
                    this.setState({ errorMsg: 'API service is down. Please contact administrator.', isError: 'Yes', isLoading: false, isSaveInProgress: false })
                }
                else {
                    this.props.defineScopeLoading(false)
                    this.setState({ errorMsg: this.props.content.error, isError: 'Yes', isLoading: false, isSaveInProgress: false })
                }
            })
    }

    render() {
        let currencySymbol = this.state.currencySymbol;

        const userInputPlaceholder = currencySymbol;


        const userInputPrefix = currencySymbol;

        const userInputSuffix = 'M';

        const numberMask = createNumberMask({
            prefix: userInputPrefix + '',
            suffix: ' ' + userInputSuffix,
            allowDecimal: true
        })
        const numberMaskForThousands = createNumberMask({
            prefix: '',
            suffix: '',
            allowDecimal: false
        })
        const numberMaskPercentage = createNumberMask({
            prefix: '',
            suffix: ' ' + '%',
            allowDecimal: true
        })
        return (
            <div className="kpiTable grid-x">
                <div className="medium-12">
                    {(this.state.isLoading) ?
                        <img style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={path + `images/ajax-loader.gif`} alt="" />
                        : null
                    }
                    {(this.state.isError === 'Yes') ?
                        <div style={{ color: 'red', textAlign: 'center' }} className="cell pt5">{this.state.errorMsg}</div>
                        : <div style={{ color: 'green', textAlign: 'center' }} className="cell pt5">{this.state.responseMsg}</div>
                    }
                </div>
                <div className="medium-12">
                    <div className="medium-8">
                        <div className="datePicker float-left width170 ">
                            <div className="dropDownHeader">{this.props.kpiLables.goLiveDate}</div>
                            <DatePicker selected={this.state.goLiveDate} onChange={(date) => this.setGoLiveDate(date)} />
                        </div>
                        <div className="datePicker float-left width170 ">
                            <div className="dropDownHeader">{this.props.kpiLables.projectStartDate}</div>
                            <DatePicker selected={this.state.startdate} onChange={(date) => this.setStartDate(date)} />
                        </div>
                        <div className="float-left width170 ">
                            <div className="dropDownHeader">{this.props.kpiLables.benchmarkMetric}</div>
                            <Select
                                className="border-with-DD dropDownContent"
                                autocomplete='true'
                                //placeholder={this.props.content.notConfirmed}
                                value={this.state.metricValues.filter(
                                    option => option.value === this.state.selectedMetricValue
                                )}
                                onChange={value => this.handleMetricChange(value)}
                                options={this.state.metricValues}
                                // isSearchable={true}
                                isDisabled={this.state.isSaveInProgress}
                            />
                        </div>
                        <div className="float-left width170 pl20">
                            <div className="dropDownHeader">{this.props.kpiLables.benchmarkPercentile}</div>
                            <Select
                                className="border-with-DD dropDownContent"
                                autocomplete='true'
                                //placeholder={this.props.content.notConfirmed}
                                value={this.state.benchmarkPercentileValues.filter(
                                    option => option.value === this.state.selectedBPValue
                                )}
                                onChange={value => this.handleBPChange(value)}
                                options={this.state.benchmarkPercentileValues}
                                // isSearchable={true}
                                isDisabled={this.state.isSaveInProgress}
                            />
                        </div>
                        <div className="float-left width170 pl20">
                            <div className="dropDownHeader">{this.props.kpiLables.benchMarkSpan}</div>
                            <Select
                                className="border-with-DD dropDownContent"
                                autocomplete='true'
                                //placeholder={this.props.content.notConfirmed}
                                value={this.state.spanValues.filter(
                                    option => option.value === this.state.selectedSpanValue
                                )}
                                onChange={value => this.handleSpanChange(value)}
                                options={this.state.spanValues}
                            // isSearchable={true}
                            // isDisabled={this.state.isLoading}
                            />
                        </div>
                        <div className="float-left width170 pl20 restoreBtn">
                            <Button content={this.props.kpiLables.restoreBtn} onClick={() => this.handleResetTable()} disabled={!this.state.isSaveInProgress} />
                        </div>
                        <div className="restoreInfo float-left">
                            <Popup className="report-tooltip"
                                trigger={<img style={{ paddingTop: '10px' }} className="info-icon" src="images/info-icon.svg" width="15" />}
                                position="bottom left"
                                on="hover"
                            >
                                <div>
                                    {this.props.kpiLables.restoreMsg}
                                </div>
                            </Popup>
                        </div>
                    </div>
                    <div className="medium-4">
                        <button className={classNames('float-right', 'pr25',
                            {
                                'hand-cursor': this.state.isSaveInProgress ? true : false,
                                'gray-scale': this.state.isSaveInProgress ? false : true
                            })}
                            onClick={this.onSave} disabled={this.state.isSaveInProgress ? false : true} title={this.props.ui.save} type="submit" float >
                            <img width="20" src="images/save_active.svg" />
                        </button>
                    </div>
                </div>
                <div className="grid-x addPadding" />
                <div className='table-scroll grid-x'>
                    {this.state.dataLoaded ?
                        <table>
                            {/* {this.state.colGroupYearly.map(el => el.key == 1 ? <col></col> : <colgroup span='2'></colgroup>)} */}
                            {this.state.selectedSpanValue == 'Half' ? this.state.headers.map(el => <col></col>) : this.state.headers2.map(el => <col></col>)}
                            <tr>
                                {this.state.colGroupYearly.map(el => el.key == 1 ? <td className='noBorder'></td> : this.state.selectedSpanValue == 'Half' ? <th colspan="2" scope="colgroup">{el.value}</th> : <th colspan="4" scope="colgroup">{el.value}</th>)}
                            </tr>
                            <tr>
                                {this.state.selectedSpanValue == 'Half' ? this.state.headers.map(el => el !== '' ? <th>{el}</th> : <th >{el}</th>) : this.state.headers2.map(el => el !== '' ? <th>{el}</th> : <th >{el}</th>)}
                            </tr>
                            {this.state.tableData.map((el, index) =>
                                <tbody>
                                    <tr>
                                        <td >{el.kpi}</td>
                                        <td >{kpiFormatRowWithThousandMillion[index] == 'Million' ? currencySymbol + convertToMillion(el.spend, true) : formatThousands(round(el.spend, 0))}</td>
                                        {kpiFormatRowWithThousandMillion[index] == 'Million' ? <td>
                                            <MaskedInput
                                                className='spendNum'
                                                id={index}
                                                mask={numberMask}
                                                placeholder={userInputPlaceholder}
                                                value={el.efo2}
                                                onChange={e => this.spendChange(e)}
                                                pattern='[0-9]*'
                                            />
                                        </td> :
                                            <td >
                                                <MaskedInput
                                                    className="spendNum"
                                                    id={index}
                                                    mask={numberMaskForThousands}
                                                    placeholder={'Enter a number'}
                                                    value={el.efo}
                                                    onChange={e => this.spendChange(e)} pattern='[0-9]*' />
                                            </td>}


                                        <td >{el.BR1}% <br /> {el.BR2 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Million' ? currencySymbol + convertToMillion(el.BR2, true) : formatThousands(round(el.BR2, 0))} </td>
                                        <td > <MaskedInput className="spendNum" id={index} mask={numberMaskPercentage} placeholder={'%'}
                                            value={el.BA1} onChange={e => this.benchMarkAdjusted(e)} pattern='[0-9]*' /> <br /> {el.BA2 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Million' ? currencySymbol + convertToMillion(el.BA2, true) : formatThousands(round(el.BA2, 0))} </td>
                                        <td className='secondHeader'>(%) <br /> Cumulative (%) <br /> Target KPI <br /> Target KPI Cumulative </td>
                                        {this.state.selectedSpanValue == 'Half' ?
                                            <React.Fragment>
                                                <td ><span type='number' id={index} cellId='H11' value={el.H11}>{el.H11} %</span> <br /> {el.H12 == 0 ? '-' : el.H12} % <br /> {el.H13 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Million' ? currencySymbol + convertToMillion(el.H13, true) : formatThousands(round(el.H13, 0))} <br /> {el.H14 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Million' ? currencySymbol + convertToMillion(el.H14, true) : formatThousands(round(el.H14, 0))} </td>
                                                <td ><span type='number' id={index} cellId='H21' value={el.H21}>{el.H21} %</span> <br /> {el.H22 == 0 ? '-' : el.H22} % <br /> {el.H23 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Million' ? currencySymbol + convertToMillion(el.H23, true) : formatThousands(round(el.H23, 0))} <br /> {el.H24 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Million' ? currencySymbol + convertToMillion(el.H24, true) : formatThousands(round(el.H24, 0))} </td>
                                                <td ><span type='number' id={index} cellId='H31' value={el.H31}>{el.H31} %</span> <br /> {el.H32 == 0 ? '-' : el.H32} % <br /> {el.H33 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Million' ? currencySymbol + convertToMillion(el.H33, true) : formatThousands(round(el.H33, 0))} <br /> {el.H34 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Million' ? currencySymbol + convertToMillion(el.H34, true) : formatThousands(round(el.H34, 0))} </td>
                                                <td ><span type='number' id={index} cellId='H41' value={el.H41}>{el.H41} %</span> <br /> {el.H42 == 0 ? '-' : el.H42} % <br /> {el.H43 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Million' ? currencySymbol + convertToMillion(el.H43, true) : formatThousands(round(el.H43, 0))} <br /> {el.H44 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Million' ? currencySymbol + convertToMillion(el.H44, true) : formatThousands(round(el.H44, 0))} </td>
                                                <td ><span type='number' id={index} cellId='H51' value={el.H51}>{el.H51} %</span> <br /> {el.H52 == 0 ? '-' : el.H52} % <br /> {el.H53 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Million' ? currencySymbol + convertToMillion(el.H53, true) : formatThousands(round(el.H53, 0))} <br /> {el.H54 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Million' ? currencySymbol + convertToMillion(el.H54, true) : formatThousands(round(el.H54, 0))} </td>
                                                <td ><span type='number' id={index} cellId='H61' value={el.H61}>{el.H61} %</span> <br /> {el.H62 == 0 ? '-' : el.H62} % <br /> {el.H63 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Million' ? currencySymbol + convertToMillion(el.H63, true) : formatThousands(round(el.H63, 0))} <br /> {el.H64 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Million' ? currencySymbol + convertToMillion(el.H64, true) : formatThousands(round(el.H64, 0))} </td>
                                            </React.Fragment>
                                            : <React.Fragment>
                                                <td ><MaskedInput className="spendNum" id={index} cellId='HQ11' mask={numberMaskPercentage} placeholder={'%'}
                                                    value={el.HQ11} onChange={e => this.hQPercentChanged(e)} pattern='[0-9]*' /> <br /> {el.HQ12 == 0 ? '-' : el.HQ12} % <br /> {el.HQ13 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Million' ? currencySymbol + convertToMillion(el.HQ13, true) : formatThousands(round(el.HQ13, 0))} <br /> {el.HQ14 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Million' ? currencySymbol + convertToMillion(el.HQ14, true) : formatThousands(round(el.HQ14, 0))} </td>
                                                <td ><MaskedInput className="spendNum" id={index} cellId='HQ21' mask={numberMaskPercentage} placeholder={'%'}
                                                    value={el.HQ21} onChange={e => this.hQPercentChanged(e)} pattern='[0-9]*' /> <br /> {el.HQ22 == 0 ? '-' : el.HQ22} % <br /> {el.HQ23 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Million' ? currencySymbol + convertToMillion(el.HQ23, true) : formatThousands(round(el.HQ23, 0))} <br /> {el.HQ24 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Million' ? currencySymbol + convertToMillion(el.HQ24, true) : formatThousands(round(el.HQ24, 0))} </td>
                                                <td ><MaskedInput className="spendNum" id={index} cellId='HQ31' mask={numberMaskPercentage} placeholder={'%'}
                                                    value={el.HQ31} onChange={e => this.hQPercentChanged(e)} pattern='[0-9]*' /><br /> {el.HQ32 == 0 ? '-' : el.HQ32} % <br /> {el.HQ33 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Million' ? currencySymbol + convertToMillion(el.HQ33, true) : formatThousands(round(el.HQ33, 0))} <br /> {el.HQ34 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Million' ? currencySymbol + convertToMillion(el.HQ34, true) : formatThousands(round(el.HQ34, 0))} </td>
                                                <td ><MaskedInput className="spendNum" id={index} cellId='HQ41' mask={numberMaskPercentage} placeholder={'%'}
                                                    value={el.HQ41} onChange={e => this.hQPercentChanged(e)} pattern='[0-9]*' /> <br /> {el.HQ42 == 0 ? '-' : el.HQ42} % <br /> {el.HQ43 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Million' ? currencySymbol + convertToMillion(el.HQ43, true) : formatThousands(round(el.HQ43, 0))} <br /> {el.HQ44 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Million' ? currencySymbol + convertToMillion(el.HQ44, true) : formatThousands(round(el.HQ44, 0))} </td>
                                                <td ><MaskedInput className="spendNum" id={index} cellId='HQ51' mask={numberMaskPercentage} placeholder={'%'}
                                                    value={el.HQ51} onChange={e => this.hQPercentChanged(e)} pattern='[0-9]*' /> <br /> {el.HQ52 == 0 ? '-' : el.HQ52} % <br /> {el.HQ53 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Million' ? currencySymbol + convertToMillion(el.HQ53, true) : formatThousands(round(el.HQ53, 0))} <br /> {el.HQ54 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Million' ? currencySymbol + convertToMillion(el.HQ54, true) : formatThousands(round(el.HQ54, 0))} </td>
                                                <td ><MaskedInput className="spendNum" id={index} cellId='HQ61' mask={numberMaskPercentage} placeholder={'%'}
                                                    value={el.HQ61} onChange={e => this.hQPercentChanged(e)} pattern='[0-9]*' /> <br /> {el.HQ62 == 0 ? '-' : el.HQ62} % <br /> {el.HQ63 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Million' ? currencySymbol + convertToMillion(el.HQ63, true) : formatThousands(round(el.HQ63, 0))} <br /> {el.HQ64 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Million' ? currencySymbol + convertToMillion(el.HQ64, true) : formatThousands(round(el.HQ64, 0))} </td>
                                                <td ><MaskedInput className="spendNum" id={index} cellId='HQ71' mask={numberMaskPercentage} placeholder={'%'}
                                                    value={el.HQ71} onChange={e => this.hQPercentChanged(e)} pattern='[0-9]*' /> <br /> {el.HQ72 == 0 ? '-' : el.HQ72} % <br /> {el.HQ73 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Million' ? currencySymbol + convertToMillion(el.HQ73, true) : formatThousands(round(el.HQ73, 0))} <br /> {el.HQ74 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Million' ? currencySymbol + convertToMillion(el.HQ74, true) : formatThousands(round(el.HQ74, 0))} </td>
                                                <td ><MaskedInput className="spendNum" id={index} cellId='HQ81' mask={numberMaskPercentage} placeholder={'%'}
                                                    value={el.HQ81} onChange={e => this.hQPercentChanged(e)} pattern='[0-9]*' /> <br /> {el.HQ82 == 0 ? '-' : el.HQ82} % <br /> {el.HQ83 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Million' ? currencySymbol + convertToMillion(el.HQ83, true) : formatThousands(round(el.HQ83, 0))} <br /> {el.HQ84 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Million' ? currencySymbol + convertToMillion(el.HQ84, true) : formatThousands(round(el.HQ84, 0))} </td>
                                                <td ><MaskedInput className="spendNum" id={index} cellId='HQ91' mask={numberMaskPercentage} placeholder={'%'}
                                                    value={el.HQ91} onChange={e => this.hQPercentChanged(e)} pattern='[0-9]*' /> <br /> {el.HQ92 == 0 ? '-' : el.HQ92} % <br /> {el.HQ93 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Million' ? currencySymbol + convertToMillion(el.HQ93, true) : formatThousands(round(el.HQ93, 0))} <br /> {el.HQ94 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Million' ? currencySymbol + convertToMillion(el.HQ94, true) : formatThousands(round(el.HQ94, 0))} </td>
                                                <td ><MaskedInput className="spendNum" id={index} cellId='HQ101' mask={numberMaskPercentage} placeholder={'%'}
                                                    value={el.HQ101} onChange={e => this.hQPercentChanged(e)} pattern='[0-9]*' /> <br /> {el.HQ102 == 0 ? '-' : el.HQ102} % <br /> {el.HQ103 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Million' ? currencySymbol + convertToMillion(el.HQ103, true) : formatThousands(round(el.HQ103, 0))} <br /> {el.HQ104 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Million' ? currencySymbol + convertToMillion(el.HQ104, true) : formatThousands(round(el.HQ104, 0))} </td>
                                                <td ><MaskedInput className="spendNum" id={index} cellId='HQ111' mask={numberMaskPercentage} placeholder={'%'}
                                                    value={el.HQ111} onChange={e => this.hQPercentChanged(e)} pattern='[0-9]*' /> <br /> {el.HQ112 == 0 ? '-' : el.HQ112} % <br /> {el.HQ113 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Million' ? currencySymbol + convertToMillion(el.HQ113, true) : formatThousands(round(el.HQ113, 0))} <br /> {el.HQ114 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Million' ? currencySymbol + convertToMillion(el.HQ114, true) : formatThousands(round(el.HQ114, 0))} </td>
                                                <td ><MaskedInput className="spendNum" id={index} cellId='HQ121' mask={numberMaskPercentage} placeholder={'%'}
                                                    value={el.HQ121} onChange={e => this.hQPercentChanged(e)} pattern='[0-9]*' /> <br /> {el.HQ122 == 0 ? '-' : el.HQ122} % <br /> {el.HQ123 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Million' ? currencySymbol + convertToMillion(el.HQ123, true) : formatThousands(round(el.HQ123, 0))} <br /> {el.HQ124 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Million' ? currencySymbol + convertToMillion(el.HQ124, true) : formatThousands(round(el.HQ124, 0))} </td>
                                            </React.Fragment>
                                        }

                                    </tr></tbody>
                            )}
                        </table>
                        : null}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        ui: state.content.ui,
        userData: state.valueStory,
        user: state.default,
        content: state.content.spendAnalysis.worldMap,
        focusLabel: state.content.spendAnalysis.enablement.focusLabel,
        spendDetails: state.spendDetails,
        currencySymbols: state.content.CurrencySymbols,
        tabMessage: state.content.spendAnalysis.detailedFile.toggleTabMessage,
        kpiLables: state.content.kpiTab
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        resetError: error => dispatch(resetError(error)),
        defineScopeLoading: bool => dispatch(defineScopeLoading(bool)),
        getKPISetUpLoadData: (DMCId, VSId, userEmail) => dispatch(getKPISetUpLoadData(DMCId, VSId, userEmail)),
        getKPIDashBoardLoadData: (DMCId, VSId, userEmail, metric, type) => dispatch(getKPIDashBoardLoadData(DMCId, VSId, userEmail, metric, type)),
        updateKPISetUpTabData: (records) => dispatch(updateKPISetUpTabData(records)),
        updateKPIDashBoardTabData: (records) => dispatch(updateKPIDashBoardTabData(records)),
        pendingChanges: changes => dispatch(pendingChanges(changes)),
        trackKpiBenchMarkFilters: obj => dispatch(trackKpiBenchMarkFilters(obj)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(KPIContainer)