import React from 'react'
import PropTypes from 'prop-types'

// Components
import SubLever from './SubLever'

const SubLevers = (props) =>
	<div className="grid-x"> 
		{props.content.map((data, i) =>
			<SubLever
				key={data} 
				data={data}
				id={props.id}
				index={i}
				currency={props.currency}
				estimatedValue={props.estimatedValue}
				active={props.savedData[i].Selected}
				estimatedValueAmount={props.savedData[i].EstAnnualValue}
				dispatchToggleSubLever={props.dispatchToggleSubLever}
			/>
		)}
	</div>

export default SubLevers

SubLevers.propTypes = {
	id: PropTypes.number.isRequired,
	currency: PropTypes.string.isRequired,
	estimatedValue: PropTypes.string.isRequired,
	dispatchToggleSubLever: PropTypes.func.isRequired
}
