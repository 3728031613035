import React from "react";
import ReactDOM from "react-dom";

export default class TextEditor extends React.Component {
    constructor(props) {
      super(props);      
      //this.state = { cellValue: props.value };
      this.state = { cellValue: '' };
    }
  
    getValue() {
        let upd = {}
        upd[this.props.column.key] = this.state.cellValue
        return upd;
        /*return { fromRow: this.props.rowData, toRow: this.props.rowData, updated: upd }*/
    }
  
    getInputNode() {
      return ReactDOM.findDOMNode(this).getElementsByTagName("input")[0];
    }
  
    handleChangeComplete = event => {
      this.setState({ cellValue: event.target.value });
    };

    render() {
      return (
        <input autoFocus key="inputKey" style={{width: "150px", height: "35px"}}
          value={this.state.cellValue}
          onChange={this.handleChangeComplete}
          maxlength={this.props.max ? this.props.max : this.props.column.max ?  this.props.column.max: '32767'}
          ref={this.input}
          defaultValue={this.props.value}
          onBlur={this.props.onBlur}
        />
      );
    }
  }