import React, { Component } from 'react'
import CustomGrid from 'UI/CustomGrid'
import { connect } from 'react-redux'
import { Filters } from "react-data-grid-addons";

import {
    industrySupplierLoading,
    industrySupplierSuccess,
    industrySupplierFailure,
    getIndustrySupplierScopeDetails,
    setPopUpTabLoadingStatus,
    getSupplierDeduplicationData
} from 'actions/spendActions'
import { getNonEmptyValue, LeftAlignFormatter, formatter, formatThousands, convertToMillion } from "../../utils/Utils";


const {
    NumericFilter
} = Filters;


class SupplierDeduplication extends Component {
    constructor(props) {
        super(props)
        this.state = {
            responseMsg: '',
            industrySupplierData: {
                DMC_ID: this.props.spendDetails.currentActiveRequest.DMC_ID,
                VS_ID: this.props.spendDetails.currentActiveRequest.VS_ID,
                User_Email: '',
                Solution: 'No Filter'
            },
            data: {},
            columns: [],
            aggregatedData: { INVOICE_COUNT: 0, INVOICE_SPEND: 0.0, PO_COUNT: 0, PO_SPEND: 0.0, UNIQUE_SUPPLIERS: 0 },
            isLoading: true,
            currencySymbol: ''
        }

        this.loadData = this.loadData.bind(this)
        this.validate = this.validate.bind(this)
    }

    componentDidMount() {
        this.loadData(this.props);
    }

    loadData(nextProps) {
        
        let currencySymbol = this.props.currencySymbols[this.props.spendDetails.currentActiveRequest.Currency !== undefined && this.props.spendDetails.currentActiveRequest.Currency !== ''?
            this.props.spendDetails.currentActiveRequest.Currency.trim() : 'USD']

        this.props.setPopUpTabLoadingStatus(true)
        this.props.getSupplierDeduplicationData(this.props.spendDetails.currentActiveRequest.DMC_ID, this.props.spendDetails.currentActiveRequest.VS_ID, this.props.user.user.Email, this.props.spendDetails.supplierDetails["SKEY"])
            .then((response) => {
                if (response !== undefined && response.status === 200 && response.data.Result !== undefined) {
                    setTimeout(() => { this.props.industrySupplierSuccess('') }, 2000)
                    let columns = this.initializeColumns(response.data.Result);
                    
                    this.setState({ data: response.data.Result, columns: columns, isLoading: false, currencySymbol: currencySymbol });
                } else if (response.response && response.response.status == 400) {
                    this.setState({ data: [], columns: [], isLoading: false, isError: 'No', message: 'No Result Found' })
                } else if (response && response.stack && response.stack.includes('Network Error')) {
                    this.setState({ responseMsg: this.props.validation.networkError, isError: 'Yes', isLoading: false })
                }
                this.props.setPopUpTabLoadingStatus(false)
            }).catch((error) => {
                this.props.setPopUpTabLoadingStatus(false);
                this.setState({ responseMsg: this.props.validation.error, isError: 'Yes', isLoading: false })
            })
    }

    componentWillReceiveProps(nextProps) {
        if (
            nextProps.spendDetails.industryGICS_Details && nextProps.spendDetails.industryGICS_Details.SKEY !== (this.props.spendDetails.industryGICS_Details ? this.props.spendDetails.industryGICS_Details.SKEY : undefined)) {
            this.loadData(nextProps);
        }
    }

    initializeColumns() {
        let columns = []

        let tableColumn = {
            name: 'Ref.#',
            key: 'REF_NUMBER',
            width: 120,
            sortable: true,
            editable: false,
            resizable: true,
            filterable: true,
            screenSeq: 0,
            mandatory: 'No',
            exportFl: true,
            //editProperty: fieldConfig.is_editable,
            formatter: LeftAlignFormatter,
            helpText: this.props.supplierDeduplication.column_help_text,
            filterRenderer: NumericFilter
        }

        columns.push(tableColumn);


        tableColumn = {
            name: 'Vendor Id',
            key: 'VENDOR_ID',
            width: 120,
            sortable: true,
            editable: false,
            resizable: true,
            filterable: true,
            screenSeq: 0,
            mandatory: 'No',
            exportFl: true,
            //editProperty: fieldConfig.is_editable,
            formatter: LeftAlignFormatter,
            helpText: this.props.supplierDeduplication.column_help_text
        }

        columns.push(tableColumn);

        tableColumn = {
            name: 'Vendor Name',
            key: 'VENDOR_NAME',
            width: 250,
            sortable: true,
            editable: false,
            resizable: true,
            filterable: true,
            screenSeq: 0,
            mandatory: 'No',
            exportFl: true,
            //editProperty: fieldConfig.is_editable,
            formatter: LeftAlignFormatter,
            helpText: this.props.supplierDeduplication.column_help_text
        }

        columns.push(tableColumn);

        tableColumn = {
            name: 'ERP System',
            key: 'ERP_SYSTEM',
            width: 350,
            sortable: true,
            editable: false,
            resizable: true,
            filterable: true,
            screenSeq: 0,
            mandatory: 'No',
            exportFl: true,
            //editProperty: fieldConfig.is_editable,
            formatter: LeftAlignFormatter,
            helpText: this.props.supplierDeduplication.column_help_text
        }

        columns.push(tableColumn);

        tableColumn = {
            name: 'Business Unit',
            key: 'BUSINESS_UNIT',
            width: 120,
            sortable: true,
            editable: false,
            resizable: true,
            filterable: true,
            screenSeq: 0,
            mandatory: 'No',
            exportFl: true,
            //editProperty: fieldConfig.is_editable,
            formatter: LeftAlignFormatter,
            helpText: this.props.supplierDeduplication.column_help_text
        }
        columns.push(tableColumn);

        tableColumn = {
            name: 'ERP Invoice Spend in Standard Currency',
            key: 'ERP_INV_SPEND_STD_CURRENCY',
            width: 200,
            sortable: true,
            editable: false,
            resizable: true,
            filterable: true,
            screenSeq: 1,
            mandatory: 'No',
            exportFl: true,
            //editProperty: fieldConfig.is_editable,
            formatter: formatter('AlignCurrencyFormatter'),
            helpText: this.props.supplierDeduplication.column_help_text,
            filterRenderer: NumericFilter
        }

        columns.push(tableColumn);


        tableColumn = {
            name: 'ERP Invoice Count',
            key: 'ERP_INVOICE_COUNT',
            width: 200,
            sortable: true,
            editable: false,
            resizable: true,
            filterable: true,
            screenSeq: 2,
            mandatory: 'No',
            exportFl: true,
            //editProperty: fieldConfig.is_editable,
            formatter: formatter('AlignFormatter'),
            helpText: this.props.supplierDeduplication.column_help_text,
            filterRenderer: NumericFilter
        }

        columns.push(tableColumn);

        tableColumn = {
            name: 'ERP PO Spend',
            key: 'ERP_PO_SPEND',
            width: 200,
            sortable: true,
            editable: false,
            resizable: true,
            filterable: true,
            screenSeq: 3,
            mandatory: 'No',
            exportFl: true,
            //editProperty: fieldConfig.is_editable,
            formatter: formatter('AlignCurrencyFormatter'),
            helpText: this.props.supplierDeduplication.column_help_text,
            filterRenderer: NumericFilter
        }

        columns.push(tableColumn);

        tableColumn = {
            name: 'ERP PO Count',
            key: 'ERP_PO_COUNT',
            width: 200,
            sortable: true,
            editable: false,
            resizable: true,
            filterable: true,
            screenSeq: 3,
            mandatory: 'No',
            exportFl: true,
            //editProperty: fieldConfig.is_editable,
            formatter: formatter('AlignFormatter'),
            helpText: this.props.supplierDeduplication.column_help_text,
            filterRenderer: NumericFilter
        }

        columns.push(tableColumn);
        return columns;
    }
    
    validateRow(records) {
        var errorRecords = []
        const columns = this.state.columns

        for (let idx = 0; idx < records.length; idx++) {
            const row = records[idx]
            for (let idx = 0; idx < columns.length; idx++) {
                const column = columns[idx]
                if (getNonEmptyValue(row[column.key]) === '' && column.mandatory) {
                    errorRecords.push(row)
                    break;
                }
            }

        }

        return errorRecords;
    }

    validate(record, warningErrorRecords) {

        return warningErrorRecords
    }

    render() {
        return (
            <div className="grid-x">
                <div className="tab-description">{this.props.supplierDeduplication.tabHeader}</div>
                <div className="medium-12 pt10">
                    <div className="blue-bg">
                        <div className="grid-x grid-padding-x">
                           
                            <div className="float-left">
                                <div className="title">{this.props.supplierDeduplication.aggregateRecords + ":"}</div>
                                <div className="value"> {formatThousands(this.props.spendDetails.inputAndClassificationDetails.AGGREGATE_RECORDS)}</div>
                            </div>
                            <div className="float-left pl20">
                                <div className="title">{this.props.supplierDeduplication.aggregateInvoiceSpend + ":"}</div>
                                <div className="value"> {this.state.currencySymbol}{convertToMillion(this.props.spendDetails.inputAndClassificationDetails.AGGREGATE_INVOICE_SPEND, true)}</div>
                            </div>
                            <div className="float-left pl20">
                                <div className="title">{this.props.supplierDeduplication.aggregatePOSpend + ":"} </div>
                                <div className="value">{this.state.currencySymbol}{convertToMillion(this.props.spendDetails.inputAndClassificationDetails.AGGREGATE_PO_SPEND, true)}</div>
                            </div>
                        
                            <div className="float-left pl20">
                                <div className="title">{this.props.supplierDeduplication.aggregateInvoiceCount + ":"} </div>
                                <div className="value">{formatThousands(this.props.spendDetails.inputAndClassificationDetails.AGGREGATE_INVOICE_COUNT)}</div>
                            </div>
                            <div className="float-left pl20">
                                <div className="title">{this.props.supplierDeduplication.aggregatePOCount + ":"} </div>
                                <div className="value">{formatThousands(this.props.spendDetails.inputAndClassificationDetails.AGGREGATE_PO_COUNT)}</div>
                            </div>
                        </div>
                    </div>

                </div>
                <CustomGrid
                    columns={this.state.columns}
                    data={this.state.data}
                    keyColumn="DATA_COLLECTION_KEY"
                    validate={this.validate}
                    isEditable={false}
                    export={true}
                    exportFileName={this.props.supplierDeduplication.exportFileName}
                    apiResponse={{ message: this.state.responseMsg, isError: this.state.isError, isLoading: this.state.isLoading }}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.default,
        spendDetails: state.spendDetails,
        supplierDeduplication: state.content.spendAnalysis.supplierDrilldown.supplierDeduplication,
        validation: state.content.validation,
        currencySymbols: state.content.CurrencySymbols
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        industrySupplierLoading: (bool) => dispatch(industrySupplierLoading(bool)),
        industrySupplierSuccess: (bool) => dispatch(industrySupplierSuccess(bool)),
        industrySupplierFailure: (error) => dispatch(industrySupplierFailure(error)),
        getIndustrySupplierScopeDetails: (formValues) => dispatch(getIndustrySupplierScopeDetails(formValues)),
        setPopUpTabLoadingStatus: bool => dispatch(setPopUpTabLoadingStatus(bool)),
        getSupplierDeduplicationData: (DMCId, VSId, userEmail, SKEY) => dispatch(getSupplierDeduplicationData(DMCId, VSId, userEmail, SKEY))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SupplierDeduplication); 