import React from 'react'
import PropTypes from 'prop-types'

// Utils
import { roundFix, formatThousands } from 'utils/Utils'

const SubLeverValue = (props) => {
	let value = props.estimatedValueAmount
	value = (isNaN(value) || value === null) ? 1 : value

	return (
		<div className="sublever__value">
			<div className="title">{props.text}</div>
			<div className="value">
				<span className="value-text__currency">{props.currency}</span>
				<span className="value-text__number">{formatThousands(roundFix(value, 2))}</span>
				<span className="value-text__millions">M</span>
			</div>
		</div>
	)
}

export default SubLeverValue

SubLeverValue.propTypes = {
	currency: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired
}
