import React from 'react'

import TextfilterRenderer from 'UI/TextFilterRenderer';
import NumericFilterRenderer from 'UI/NumericFilterRenderer';
import AutoCompleteFilterAdv from 'UI/AutoCompleteFilterAdv';

import DivEditor from 'UI/DivEditor'
import DateEditor from 'UI/DateEditor'
import CalendarDatePicker from 'UI/CalendarDatePicker'
import NumericEditor from 'UI/NumericEditor'
import TextEditor from 'UI/TextEditor';

import { Editors } from "react-data-grid-addons";
const { DropDownEditor } = Editors;

/**
 * General
 */

// HTML5 Local Storage with Expiration
export const localStorage2 = {
	save: function (key, jsonData, expirationMS) {
		if (typeof (Storage) === "undefined") { return false; }
		//var expirationMS = expirationMin * 60 * 1000;
		var record = { value: JSON.stringify(jsonData), timestamp: new Date().getTime() + expirationMS }
		localStorage.setItem(key, JSON.stringify(record));
		return jsonData;
	},
	load: function (key) {
		if (typeof (Storage) === "undefined") { return false; }
		var record = JSON.parse(localStorage.getItem(key));
		if (!record) { return false; }
		return (new Date().getTime() < record.timestamp && JSON.parse(record.value));
	}
}

export const isDuplicate = (valueStories, vals, VSId) => {
	let result = true;
	if (vals.length > 0 && vals !== "") {
		if(Array.isArray(valueStories)) {
			result = (valueStories.
				find(data => data.ValueStoryName.toLowerCase().trim() === vals.toLowerCase().trim() && data.VSId !== VSId))
		} else {
			result = false
		}
	}
	else {
		result = false;
	}

	if (result) {
		return false;
	}
	else {
		return true;
	}
}

export const BreakLine = (content) =>
	content.split('\n').map((item, key) => {
		return <span key={key}>{item}<br /></span>
	})

export const addCurrencyMask = (value, currency) => {

	if (value === '' || value === null) {
		return ''
	} else {

		const regex = /\d*\.?\d{0,2}/g;
		const filteredValue = regex.exec(value)

		if (filteredValue[0] === '') {
			//console.log(currency + ' ' + formatThousands(0) + ' M')
			return currency + formatThousands(0) + ' M'
		} else {
			return currency + formatThousands(filteredValue[0]) + ' M'
			//console.log(currency + ' ' + formatThousands(filteredValue[0]) + ' M')
		}
	}
}

export const onChangeRemoveMask = (e, currency = '') => {
	let output
	const input = e.target.value;
	return removeMask(input, currency);
}

export const removeMask = (input, currency = '') => {
	let output
	const escapedCurrency = currency.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'),
		currencyRegex = new RegExp(escapedCurrency, 'g'),
		charactersRegex = new RegExp('[,$M%A-Za-z ]', 'g')

	output = input.replace(currencyRegex, '')
	output = output.replace(charactersRegex, '')
	output = output.replace(/^[\D]*/, '') // replace periods

	//console.log('input:' + e.target.value, 'output:' + output)

	return output
}

export const formatThousands = (num, isDecimal) => {
	if (num !== undefined && num !== null) {
		if (isDecimal) { return round(Math.abs(Number(num)) / 1000, 2).toFixed(2) }
		var values = num.toString().split('.');
		if (values[0] >= 1000 || values[0] <= -1000) {
			//console.log(values[0].replace(/.(?=(?:.{3})+$)/g, '$&,') + (values.length === 2 ? '.' + values[1] : ''))
			return values[0].replace(/.(?=(?:.{3})+$)/g, '$&,') + (values.length === 2 ? '.' + values[1] : '')
		} else {
			return num
		}
	}
	else { return 0 }
}

export const round = (value, decimals) => {
	return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
	//  var num = parseFloat(value).toFixed(decimals);
	//  return num;
}

export const roundFix = (value, decimals) => {
	//return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
	if (value != null && value != '') {
		var num = parseFloat(value).toFixed(decimals);
		return num;
	} else {
		return 0;
	}

}

export const getParameterByName = (name, url) => {
	if (!url) url = window.location.href;
	name = name.replace(/[\[\]]/g, "\\$&");
	var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
		results = regex.exec(url);
	if (!results) return null;
	if (!results[2]) return '';
	return results[2];
}

export const setCookie = (cname, cvalue, exdays) => {
	var d = new Date();
	d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
	var expires = "expires=" + d.toUTCString();
	document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export const getCookie = (cname) => {
	var name = cname + "=";
	var decodedCookie = decodeURIComponent(document.cookie);
	var ca = decodedCookie.split(';');
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) == ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
	}
	return "";
}

/**
 * Financial formulae
 */

// https://en.wikipedia.org/wiki/Time_value_of_money
export const times = n => f => {
	let iter = i => {
		if (i === n) return
		f(i)
		iter(i + 1)
	}
	return iter(0)
}

// https://www.investopedia.com/ask/answers/021115/what-formula-calculating-net-present-value-npv-excel.asp
export const NPV = (arr) => {
	let temp = 0,
		cashFlow = 0,
		rate = 0

	rate = Number(arr[0]) / 100

	arr.forEach((data, i) => {

		if (i > 0) {
			data === 0 ? cashFlow = 0 : cashFlow = Number(data)
			temp += cashFlow / Math.pow(1 + rate, i)
		}
	})

	temp = 100 * temp
	temp = temp / 100

	return temp
}

/**
 * App specific
 */
export const convertRouteToTitle = (route) => {
	return route.pathname.replace(/[/-]/g, ' ')
}

export const getSubLeverIdObject = (id) => {
	const subLeverId = id
	// Get Value Driver Id
	const BVUIdIndex = subLeverId.substr(0, subLeverId.indexOf('.')) - 1
	// Get Sublever Id
	const BVUSVDIdIndex = subLeverId.substr(subLeverId.indexOf('.') + 1) - 1
	return { BVUIdIndex: BVUIdIndex, BVUSVDIdIndex: BVUSVDIdIndex }
}

export const subLeverHasTotalSpend = (subLeverId) => {
	return subLeverId === '1.1' || subLeverId === '1.2' || subLeverId === '1.3' || subLeverId === '1.4' || subLeverId === '1.5' || subLeverId === '1.6' ||
		subLeverId === '2.1' || subLeverId === '2.2' || subLeverId === '2.3' || subLeverId === '3.6' ||
		subLeverId === '4.1' || subLeverId === '4.2' || subLeverId === '4.3'
}

export const clearLocalStorage = () => {
	localStorage.removeItem('jwtToken')
	localStorage.removeItem('sessionInfo')
	sessionStorage.removeItem('isCustomer')
}

export const setCurrentLanguage = (language) => {
	// Save current sublever Id to cookie

	let settings = {}

	if (getCookie('settings') !== '' && getCookie('settings') !== 'undefined') {
		settings = JSON.parse(getCookie('settings'))
		settings.language = language
	} else {
		settings.language = language
	}

	setCookie('settings', JSON.stringify(settings), 1) // 0.0138 is 20 minutes out of a day (1440 minutes)
}

export const getCurrentLanguage = () => {

	let settings = {},
		defaultValue = 'en'


	if (getCookie('settings') !== '' && getCookie('settings') !== 'undefined') {
		settings = JSON.parse(getCookie('settings'))
		if (settings.hasOwnProperty('language')) {
			return settings.language
		} else {
			return defaultValue
		}

	} else {
		return defaultValue
	}
}

export const setCurrentPriorityId = (priorityId) => {
	// Save current sublever Id to cookie

	let settings = {}

	if (getCookie('settings') !== '' && getCookie('settings') !== 'undefined') {
		settings = JSON.parse(getCookie('settings'))
		settings.priorityId = priorityId
	} else {
		settings.priorityId = priorityId
	}

	setCookie('settings', JSON.stringify(settings), 1)
}

export const getCurrentPriorityId = () => {

	let settings = {}

	if (getCookie('settings') !== '' && getCookie('settings') !== 'undefined') {
		settings = JSON.parse(getCookie('settings'))
		return settings.priorityId
	} else {
		return 1
	}
}

export const setCurrentSubLeverId = (subleverId) => {
	// Save current sublever Id to cookie

	let settings = {}

	if (getCookie('settings') !== '' && getCookie('settings') !== 'undefined') {
		settings = JSON.parse(getCookie('settings'))
		settings.subleverId = subleverId
	} else {
		settings.subleverId = subleverId
	}

	setCookie('settings', JSON.stringify(settings), 1) // 0.0138 is 20 minutes out of a day (1440 minutes)
}

export const getCurrentSubLeverId = () => {

	let settings = {}

	if (getCookie('settings') !== '' && getCookie('settings') !== 'undefined') {
		settings = JSON.parse(getCookie('settings'))
		return settings.subleverId
	} else {
		return '1.1'
	}
}


export const sortObjectArray = (key, order = 'asc') => {
	return function (a, b) {
		if (!a.hasOwnProperty(key) ||
			!b.hasOwnProperty(key)) {
			return 0;
		}

		const varA = (typeof a[key] === 'string') ?
			a[key].toUpperCase() : a[key];
		const varB = (typeof b[key] === 'string') ?
			b[key].toUpperCase() : b[key];

		let comparison = 0;
		if (varA > varB) {
			comparison = 1;
		} else if (varA < varB) {
			comparison = -1;
		}
		return (
			(order == 'desc') ?
				(comparison * -1) : comparison
		);
	};
}

export const getCurrancyDetails = (currencyCodes, filterCurrencyCode) => {
	if (filterCurrencyCode != null) {
		var _currencyDetails = currencyCodes.filter((element) => element.split(' - ')[0] === filterCurrencyCode.trim());
		// var symbol = currencySymbols[filterCurrencyCode.trim()];
		return _currencyDetails;
	}
	else { return currencyCodes.filter((element) => element.split(' - ')[0] === 'USD'); }
}

export const convertToMillion = (numberValue, isDecimal) => {
	// Six Zeroes for Millions 	
	var numberInMillion = 0;
	if (numberValue !== undefined && numberValue !== null) {
		numberInMillion = numberValue > 0 ? (Math.round((Math.abs(Number(numberValue)) / 1.0e+6) * 100) / 100).toFixed(2)
			: (Math.round((Number(numberValue) / 1.0e+6) * 100) / 100).toFixed(2)   // added for -ve value
		if (isDecimal) {
			var values = numberInMillion.toString().split('.');
			if (values[0] >= 1000 || values[0] <= -1000) {
				numberInMillion = values[0].replace(/.(?=(?:.{3})+$)/g, '$&,') + (values.length === 2 ? '.' + values[1] : '')
			} else {
				numberInMillion = numberInMillion
			}
		}
		else {
			var values = Math.round(numberInMillion).toString();
			if (values >= 1000 || values <= -1000) {
				numberInMillion = values.replace(/.(?=(?:.{3})+$)/g, '$&,') //+ (values.length === 2 ? '.' + values[1] : '')
			} else {
				numberInMillion = values
			}
		}
	}
	return numberInMillion + " M"

}

export const convertToBillion = (numberValue) => {
	// Nine Zeroes for Billions
	return round(Math.abs(Number(numberValue)) / 1.0e+9, 2) + "B"
}

export const convertToThousands = (numberValue) => {
	// Nine Zeroes for Billions
	return round(Math.abs(Number(numberValue)) / 1000, 2).toFixed(2) + " K"
}

export const clone = (items) => items.map(item => Array.isArray(item) ? clone(item) : item);

export const getNonEmptyValue = (value) => {
	return (value === null || value === undefined || value === '') ? '' : value;
}

export const formatNumber = (value) => {
	if (getNonEmptyValue(value) === '') {
		return value
	}

	return (value + '').replace(/\d(?=(\d{3})+\.)/g, '$&,')
}

export const isArrayEquals = (arr1, arr2) => {
	if (arr1.length !== arr2.length) return false;

	// Check if all items exist and are in the same order
	for (var i = 0; i < arr1.length; i++) {
		if (arr1[i] !== arr2[i]) return false;
	}

	// Otherwise, return true
	return true;
}

const totalFormatter = (style, value) => {
	if(!value.row.superHeader && (!value.row.DATA_COLLECTIONS_SKEY || value.row.DATA_COLLECTIONS_SKEY === null ) && value.row.DATA_COLLECTIONS_SKEY !== undefined) {
		const s = Object.assign({fontWeight: '700', textDecoration: 'underline'}, style)

		return s;
	}

	return style;
}

export const AlignFormatter = (value) => { const val = formatThousandsWithBlank(roundFixWithBlank(value.value, 0)); return (<div style={totalFormatter({ textAlign: 'right' }, value)} title={val}>{val}</div>) }
export const RoundFormatter = (value) => { const val = formatThousandsWithBlank(roundFixWithBlank(value.value, 4)); return (<div style={totalFormatter({ textAlign: 'right' }, value)} title={val}>{val}</div>) }
export const CenterAlignFormatter = (value) => { return (<div style={totalFormatter({ textAlign: 'center' }, value)} title={value.value}>{value.value}</div>) }
export const LeftAlignFormatter = (value) => { return (<div style={totalFormatter({ textAlign: 'left' }, value)} title={value.value}>{value.value}</div>) }
export const RightAlignFormatter = (value) => { return (<div style={totalFormatter({ textAlign: 'right' }, value)} title={value.value}>{value.value}</div>) }
export const AlignCurrencyFormatter = (value) => { const val = formatThousandsWithBlank(roundFixWithBlank(value.value, 0)); return (<div style={totalFormatter({ textAlign: 'right' }, value)} title={val}>{value.row.currencySymbol}{val}</div>) }
export const RoundCurrencyFormatter = (value) => { const val = formatThousandsWithBlank(roundFixWithBlank(value.value, 4)); return (<div style={totalFormatter({ textAlign: 'right' }, value)} title={val}>{value.row.currencySymbol}{val}</div>) }
export const LinkFormatter = (value) => { return (<div style={totalFormatter({ textAlign: 'left', cursor: 'pointer', color: '#008fd3' }, value)} title={value.value}>{value.value}</div>) }
export const RoundFormatter2 = (value) => { const val = formatThousandsWithBlank(roundFixWithBlank(value.value, 2)); return (<div style={totalFormatter({ textAlign: 'right' }, value)} title={val}>{val}</div>) }
export const IntegralpartFormatter = (value) => { const val = formatThousandsWithBlank(integralPartWithBlank(value.value)); return (<div style={totalFormatter({ textAlign: 'right' }, value)} title={val}>{val}</div>) }
export const IntegralpartPercentFormatter = (value) => { const val = formatThousandsWithBlank(integralPartWithBlank(value.value)); return (<div style={totalFormatter({ textAlign: 'right' }, value)} title={val === '' || val === undefined || val === null ? val : val + '%'}>{val === '' || val === undefined || val === null ? val : val + '%'}</div>) }

export const formatter = (value) => {
	switch (value) {
		case 'LeftAlignFormatter':
			return LeftAlignFormatter
		case 'AlignFormatter':
			return AlignFormatter
		case 'RoundFormatter':
			return RoundFormatter
		case 'CenterAlignFormatter':
			return CenterAlignFormatter
		case 'RightAlignFormatter':
			return RightAlignFormatter
		case 'AlignCurrencyFormatter':
			return AlignCurrencyFormatter
		case 'RoundCurrencyFormatter':
			return RoundCurrencyFormatter
		case 'LinkFormatter':
			return LinkFormatter
		case 'RoundFormatter2':
			return RoundFormatter2
		case 'IntegralpartFormatter':
			return IntegralpartFormatter
		case 'IntegralpartPercentFormatter':
			return IntegralpartPercentFormatter
		default:
			return undefined;
	}
}

export const filterRenderer = (filterType) => {
	switch (filterType) {
		case 'NumericFilterRenderer':
			return NumericFilterRenderer
		case 'TextfilterRenderer':
			return TextfilterRenderer
		case 'AutoCompleteFilterAdv':
			return AutoCompleteFilterAdv
		default:
			return undefined;
	}
}

export const editorComponent = (editor, values) => {
	switch (editor) {
		case 'DropDownEditor':
			return <DropDownEditor options={values.split('~')} />
		case 'TextEditor':
			return <TextEditor {...JSON.parse(values)} />
		case 'CalendarDatePicker':
			return CalendarDatePicker
		case 'DateEditor':
			return DateEditor
		case 'DivEditor':
			return DivEditor
		case 'NumericEditor':
			return NumericEditor
		default:
			return undefined;
	}
}

/*export const subLeverHasHiddenTotalSpend = (subLeverId) => {
	return subLeverId === '3.1' ||
		   subLeverId === '3.2' ||
		   subLeverId === '3.5'
}*/

export const stringContainsSearchTerms = (string, searchValue) => {
	if (string !== undefined && searchValue !== undefined) {
		const strColumnValue = string.toString();
		const strFilterTermValue = searchValue.toString();
		const checkValueIndex = strColumnValue.trim().toLowerCase().indexOf(strFilterTermValue.trim().toLowerCase());
		return checkValueIndex !== -1 || string === searchValue;
	}

	return false;
}

export const isEmptyArray = (obj) => {
	return Array.isArray(obj) && obj.length === 0;
}

//To keep blank values use these
export const roundFixWithBlank = (value, decimals) => {
	//return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
	if (value === '') {
		return '';
	}
	if (value != null) {
		var num = parseFloat(value).toFixed(decimals);
		return num;
	} else {
		return 0;
	}

}

//To keep number before the decimal point
export const integralPartWithBlank = (value) => {
	if (value === '') {
		return '';
	}
	
	if (value != null) {
		var values = value.toString().split('.');
		var num = values[0];
		return num;
	} else {
		return 0;
	}

}

export const formatThousandsWithBlank = (num, isDecimal) => {
	if (num === '') {
		return ''
	}
	if (num !== undefined && num !== null) {
		if (isDecimal) { return round(Math.abs(Number(num)) / 1000, 2).toFixed(2) }
		var values = num.toString().split('.');
		if (values[0] >= 1000 || values[0] <= -1000) {
			//console.log(values[0].replace(/.(?=(?:.{3})+$)/g, '$&,') + (values.length === 2 ? '.' + values[1] : ''))
			return values[0].replace(/.(?=(?:.{3})+$)/g, '$&,') + (values.length === 2 ? '.' + values[1] : '')
		} else {
			return num
		}
	}
	else { return 0 }
}

export const mergeArrayByKey = (arr1, arr2, key) => {
	/*if(arr1.length >= arr2.length) {
		return arr1.map(t1 => ({...t1, ...arr2.find(t2 => t2[key] === t1[key])}))
	} else {
		return arr2.map(t1 => ({...t1, ...arr1.find(t2 => t2[key] === t1[key])}))
	}*/

	var hash = new Map();
	arr1.concat(arr2).forEach(function (obj) {
		hash.set(obj[key], Object.assign(hash.get(obj[key]) || {}, obj))
	});

	return Array.from(hash.values());
}

export const handleSingleDoubleClickSameElement = (fun1, fun2, instance) => {
	if (instance.clickTimeout !== null) {
		clearTimeout(instance.clickTimeout)
		instance.clickTimeout = null
		fun2();
	} else {
		instance.clickTimeout = setTimeout(() => {
			clearTimeout(instance.clickTimeout)
			instance.clickTimeout = null
			fun1();
		}, 500)
	}
}

export const isColumnMapped = (columnData, value) => {
	let mappedColumn = columnData ? columnData : [];
	let mapped = mappedColumn.filter((item) => item.ColumnField === value)

	if (mapped.length > 0) {
		return true;
	}
	return false;
}

export const binarySearchIndex = (arr, match, key) => {
	let start = 0, end = arr.length - 1;
	while (start <= end) {
		let mid = Math.floor((start + end) / 2);
		const val = arr[mid][key]
		if (val === match) return mid;
		else if (val < match)
			start = mid + 1;
		else
			end = mid - 1;
	}

	return -1;
}

export const formatThousandsForStrings = (number, isDecimal) => {
	if (number !== undefined && number !== null) {
		let num = number;
		if (typeof (number) == 'string') {
			num = number.split(',').join('');
		}

		if (isDecimal) { return round(Math.abs(Number(num)) / 1000, 2).toFixed(2) }
		var values = num.toString().split('.');
		if (values[0] >= 1000 || values[0] <= -1000) {
			//console.log(values[0].replace(/.(?=(?:.{3})+$)/g, '$&,') + (values.length === 2 ? '.' + values[1] : ''))
			return values[0].replace(/.(?=(?:.{3})+$)/g, '$&,') + (values.length === 2 ? '.' + values[1] : '')
		} else {
			return num
		}
	}
	else { return 0 }
}

export const convertToNumber = (number) => {
	let num = parseFloat(number.split(',').join(''));
	return num;
}