
import axios from 'axios'
import { env, ROOT_URL } from 'Constants'

//user manager
export const USER_MANAGER_LOADING = 'USER_MANAGER_LOADING';
export const USER_MANAGER_SUCCESS = 'USER_MANAGER_SUCCESS';
export const USER_MANAGER_FAILURE = 'USER_MANAGER_FAILURE';
export const RESET_ERROR = 'RESET_ERROR';

//role manager
export const ROLE_MANAGER_LOADING = 'ROLE_MANAGER_LOADING';
export const ROLE_MANAGER_SUCCESS = 'ROLE_MANAGER_SUCCESS';
export const ROLE_MANAGER_FAILURE = 'ROLE_MANAGER_FAILURE';

//CapIqUser manager
export const CAPIQUSER_MANAGER_LOADING = 'CAPIQUSER_MANAGER_LOADING';
export const CAPIQUSER_MANAGER_SUCCESS = 'CAPIQUSER_MANAGER_SUCCESS';
export const CAPIQUSER_MANAGER_FAILURE = 'CAPIQUSER_MANAGER_FAILURE';

export function resetError() {
  return {
    type: RESET_ERROR,
    payload: null
  };
}

export function userManagerLoading(bool) {
  return {
    type: USER_MANAGER_LOADING,
    isLoading: bool
  }
}

export function userManagerSuccess(message) {
  return {
    type: USER_MANAGER_SUCCESS,
    payload: message
  };
}

export function userManagerFailure(error) {
  return {
    type: USER_MANAGER_FAILURE,
    payload: error
  };
}

export function roleManagerLoading(bool) {
  return {
    type: ROLE_MANAGER_LOADING,
    isLoading: bool
  }
}

export function roleManagerSuccess(message) {
  return {
    type: ROLE_MANAGER_SUCCESS,
    payload: message
  };
}

export function roleManagerFailure(error) {
  return {
    type: ROLE_MANAGER_FAILURE,
    payload: error
  };
}

export function capIqUserManagerLoading(bool) {
  return {
    type: CAPIQUSER_MANAGER_LOADING,
    isLoading: bool
  }
}

export function capIqUserManagerSuccess(message) {
  return {
    type: CAPIQUSER_MANAGER_SUCCESS,
    payload: message
  };
}

export function capIqUserManagerFailure(error) {
  return {
    type: CAPIQUSER_MANAGER_FAILURE,
    payload: error
  };
}

export function GetAllRoles() {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: `${ROOT_URL}/RoleAndPermission/GetAllRoles`,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function fetchUserRoles() {
  return (dispatch) => {
    dispatch(userManagerLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: `${ROOT_URL}/RoleAndPermission/FetchUserRoles`,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function addUserRole(formValues) {
  return (dispatch) => {
    dispatch(userManagerLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: formValues,
      url: `${ROOT_URL}/RoleAndPermission/AddUserRole`,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function addCustomerRole(formValues) {
  return (dispatch) => {
    dispatch(userManagerLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: formValues,
      url: `${ROOT_URL}/RoleAndPermission/AddUpdateCustomerRole`,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function deleteCustomerRole(formValues) {
  return (dispatch) => {
    dispatch(userManagerLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: formValues,
      url: `${ROOT_URL}/RoleAndPermission/DeleteCustomer`,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function deleteUserRole(formValues) {
  return (dispatch) => {
    dispatch(userManagerLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: formValues,
      url: `${ROOT_URL}/RoleAndPermission/DeleteUserRole`,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function updateUserRole(formValues) {
  return (dispatch) => {
    dispatch(userManagerLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: formValues,
      url: `${ROOT_URL}/RoleAndPermission/UpdateUserRole`,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function getAllFeatures() {
  return (dispatch) => {
    dispatch(userManagerLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: `${ROOT_URL}/RoleAndPermission/GetAllFeatures`,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function fetchRoleFeatures() {
  return (dispatch) => {
    dispatch(userManagerLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: `${ROOT_URL}/RoleAndPermission/FetchRoleFeatures`,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function fetchCustomerRoles() {
  return (dispatch) => {
    setTimeout(() => { dispatch(userManagerLoading(true)) }, 1500);
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: `${ROOT_URL}/RoleAndPermission/FetchCustomerRoles`,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function addUpdateRoleAndFeatures(formValues) {
  return (dispatch) => {
    dispatch(roleManagerLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: formValues,
      url: `${ROOT_URL}/RoleAndPermission/AddUpdateRoleAndFeatures`,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function deleteRoleAndFeatures(formValues) {
  return (dispatch) => {
    dispatch(roleManagerLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: formValues,
      url: `${ROOT_URL}/RoleAndPermission/DeleteRoleAndFeatures`,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}


export function getAllCapIqUsers() {
  return (dispatch) => {
    dispatch(roleManagerLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: `${ROOT_URL}/RoleAndPermission/GetAllCapIqUsers`,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}


export function addCapIqUser(formValues) {
  return (dispatch) => {
    dispatch(roleManagerLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: formValues,
      url: `${ROOT_URL}/RoleAndPermission/AddCapIqUser`,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}


export function deleteCapIqUser(formValues) {
  return (dispatch) => {
    dispatch(roleManagerLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: formValues,
      url: `${ROOT_URL}/RoleAndPermission/DeleteCapIqUser`,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function submitSpendColumnsForRole(spendRole) {
  return (dispatch) => {
    dispatch(userManagerLoading(true));
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: `${ROOT_URL}/RoleAndPermission/SubmitSpendColumnsForRole`,
      data: spendRole,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(userManagerLoading(false));
      return response
    })
  }
}


export function GetSpendColumnsForRole() {
  return (dispatch) => {
    dispatch(userManagerLoading(true));
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: `${ROOT_URL}/RoleAndPermission/GetSpendColumnsForRole`,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(userManagerLoading(false));
      return response
    })
  }
}


export function DeleteSpendColumnsForRole(spendRole) {
  return (dispatch) => {
    dispatch(userManagerLoading(true));
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: `${ROOT_URL}/RoleAndPermission/DeleteSpendColumnsForRole?RoleId=${spendRole}`,
      data: spendRole,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(userManagerLoading(false));
      return response
    })
  }
}