import React from 'react'

// Utils
import classnames from 'classnames'

// Settings
import { routes } from '../../Constants'

const Loading = (props) =>
	<div className={
		classnames(
			'loading',
			{
				'loading--is-loading' : (props.valueStoryIsLoading || (props.valueStoryListLoading && props.location.pathname === routes.start))
			}
		)
	}>
		<div className="loader" />
		{ props.valueStoryListLoading ? props.ui.loading : props.ui.loadingValueStory }
	</div>

export default Loading
