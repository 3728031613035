import React, { Fragment } from 'react';
import { connect } from 'react-redux'
import ScrollArea from 'react-scrollbar'
import Button from 'UI/Button';
import { RadioButton } from 'material-ui';

import {
    selectedEnablementWaveDetails
} from 'actions/spendActions'


class EnablementWavePopUp extends React.Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef()
        this.state = {
            designationList: ['Already Transacting', 'Relationship Exists', 'On Going Standard Account',
                'Out of Scope', 'Negative spend', 'Input'],

            designationArray: [],
            selectedValue: null,
            checked: false,
            inputValue: null
        }
        this.loadEW_Designation_Data = this.loadEW_Designation_Data.bind(this);
        this.RadioButtonChecked = this.RadioButtonChecked.bind(this);
        this.onInputValueChange = this.onInputValueChange.bind(this);
    }

    componentDidMount() {
        let flag = this.state.designationList.findIndex(x => x == this.props.enablementWaveInputValue);

        if (flag >= 0) {
            this.setState({ checked: this.props.enablementWaveInputValue !== null ? true : false, selectedValue: this.props.enablementWaveInputValue }, this.loadEW_Designation_Data)
        } else {
            this.setState({ checked: this.props.enablementWaveInputValue !== null ? true : false, selectedValue: 'Input', inputValue: this.props.enablementWaveInputValue }, this.loadEW_Designation_Data)
        }
    }

    RadioButtonChecked(event) {
        // if (event.currentTarget.value == this.state.selectedValue) {
        //     this.setState({ checked: !this.state.checked }, this.loadEW_Designation_Data)
        // } else {
        //     this.setState({ checked: true, selectedValue: event.currentTarget.value }, this.loadEW_Designation_Data)
        // }

        let value = ''
        if (event.currentTarget.value !== 'Input') {
            this.props.selectedEnablementWaveDetails(event.currentTarget.value);
            value = event.currentTarget.value;
        } else {
            value = this.state.inputValue;
        }

        this.setState({ checked: true, selectedValue: event.currentTarget.value }, this.loadEW_Designation_Data)
    }

    onInputValueChange(event) {
        //value should not cross min and max input value
        let { value, min, max } = event.target;
        if(Number.isInteger(value)){
           //value = Math.max(Number(min), Math.min(Number(max), Number(value)));
           value = Number(value) > Number(max) ? Math.min(Number(max), Number(value)) : value;
        }else{
            value = value.split('.')[0]
            value = Number(value) > Number(max) ? Math.min(Number(max), Number(value)) : value;
        }
        this.props.selectedEnablementWaveDetails(value);
        this.setState({ inputValue: value }, this.loadEW_Designation_Data);
    }

    loadEW_Designation_Data() {
        var result = (<div>
            {this.state.designationList.map((data, index) => (
                <div className="pe-div">
                    <div className="company-name-row">
                        {data !== 'Input' ?
                            <div className="fullWidth">
                                <div className="float-left collapse-expand">
                                    <div className="float-left ewRadio-btn">
                                        <RadioButton className='radioButton top-5'
                                            checked={this.state.checked && data == this.state.selectedValue}
                                            id={index}
                                            value={data}
                                            onClick={this.RadioButtonChecked}
                                            color="default"
                                        />
                                    </div>
                                </div>
                                <div className="float-left details-text top-3">
                                    <div>
                                        <span>{data}</span>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="fullWidth">
                                <div className="float-left collapse-expand">
                                    <div className="float-left ewRadio-btn">
                                        <RadioButton className='radioButton top-5'
                                            checked={this.state.checked && data == this.state.selectedValue}
                                            id={index}
                                            value={data}
                                            onClick={this.RadioButtonChecked}
                                            color="default"
                                        />
                                    </div>
                                </div>
                                <div className="float-left details-text">
                                    <div className="control-wrapper ewInput">
                                        <form>
                                            <input type='number' min="1" max="99" pattern="[0-9]" autoFocus 
                                            disabled={this.state.selectedValue == 'Input' ? false : true} placeHolder='Designate a wave by populating a number' 
                                            onChange={this.onInputValueChange} value={this.state.inputValue} />
                                        </form>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            ))}
        </div>)

        this.setState({ designationArray: result })
    }

    render() {
        return (
            <div id="capiq-search" className="treeContainer company-search-popup">
                <div className="grid-x">
                    <div className="medium-12 border-bottom">
                        <div className="float-left popHeader">{this.props.content.ewPopUpHeader}</div>
                        <div className="float-right close-icon" onClick={this.props.close}>
                            <img width="10" src="images/close_inactive.svg" />
                        </div>
                    </div>
                    <div className="ewDescription">
                        {this.props.content.ewPopUpDesc}
                    </div>
                </div>
                <div className="grid-x">
                    <div className="medium-12">
                        <ScrollArea minScrollSize={40}
                            speed={0.8}
                            className="box"
                            contentClassName="content"
                            horizontal={false} >
                            <span className="ewDescription fw600">{this.props.content.ewPopUpSelectTitle}</span>
                            <div>{this.state.designationArray}</div>
                            {/* {this.props.search.isLoading ?
                                <div className="loading loading--is-loading"><div className="loader" />Loading...</div> :
                                this.state.peArray
                            }
                            <div style={{ marginTop: '5px', marginLeft: '5px' }} className="errors">{this.props.search.error}</div> */}
                        </ScrollArea>
                    </div>
                    {/* <div className="medium-6">
                        <ScrollArea minScrollSize={40}
                            speed={0.8}
                            className="box"
                            contentClassName="content"
                            horizontal={false} >
                            <span className="ewDescription">Designate a wave by populating a number below</span>
                            <div className="control-wrapper ewInput">
                                <input type='text' onChange={this.onInputValueChange} value={this.state.inputValue} />
                            </div>
                        </ScrollArea>
                    </div> */}
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        content: state.content.enablementInsight,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        selectedEnablementWaveDetails: message => dispatch(selectedEnablementWaveDetails(message))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EnablementWavePopUp); 