import { initPrioritiesItems } from './prioritiesActions'

export const valueStoryHasErrored = (bool) => {
	return {
		type: 'VALUESTORY_HAS_ERRORED',
		hasErrored: bool
	}
}

export const valueStoryIsLoading = (bool) => {
	return {
		type: 'VALUESTORY_IS_LOADING',
		isLoading: bool
	}
}

export const valueStoryReset = () => {
	return {
		type: 'VALUESTORY_RESET'
	}
}

export const valueStoryFetchDataSuccess = (valueStory) => {
	return {
		type: 'VALUESTORY_FETCH_DATA_SUCCESS',
		valueStory
	}
}

export const errorAfterFiveSeconds = () => {
	return (dispatch) => {
		setTimeout(() => {
			dispatch(valueStoryHasErrored(true))
		}, 5000)
	}
}

export const valueStoryFetchData = (url) => {
	return (dispatch) => {
		dispatch(valueStoryIsLoading(true))

		fetch(url)
			.then((response) => {
				if(!response.ok) {
					throw new Error('Bad response from server')
				}
				return response.json()
			})
			.then((response) => dispatch(valueStoryFetchDataSuccess(response.Result)))
			.then((response) => {

				dispatch(valueStoryIsLoading(false))

				// Add indexes of selected priorities to priorities array in store
				let items = response.valueStory.AreaOfInterest.map((item, i) => item.Selected && i)
				items = items.filter((item) => item !== false)
				dispatch(initPrioritiesItems(items))
			})
			.catch(() => dispatch(valueStoryHasErrored(true)))
	}
}