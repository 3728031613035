import React from 'react'
import { connect } from 'react-redux'
import Button from 'UI/Button'
import { Form } from 'react-redux-form'
import { Link } from 'react-router-dom'
// Actions
import {
	NewsletterAcceptanceUpdate,
	userConsentSuccess,
	userConsentFailure,
	resetError,
	newsletterAcceptanceSuccess,
	newsletterAcceptanceFailure
} from 'actions/userActions'


// Utils
import { Checkbox } from 'material-ui';

var aStyle ={
	color: 'blue'
}

class NewsLetter extends React.Component {
	constructor(props) 
  {
    super(props);
    this.state = {
		chkbox: this.props.newsLetterCheck !== undefined ? this.props.newsLetterCheck : false
	}
	this.isChecked = this.isChecked.bind(this);
	this.check = this.check.bind(this);
	this.redirect = this.redirect.bind(this)
  }

  isChecked(formValue){
			const value = {
				UserID: localStorage.getItem('id'),
				Accepted: this.state.chkbox
			}
			this.props.NewsletterAcceptanceUpdate(value)
			.then((result) => {
				this.props.newsletterAcceptanceSuccess(result) 
				//this.redirect()
			}).catch((error) => {
					 this.props.newsletterAcceptanceFailure(error.response.data.Message)
			})
			this.redirect()
	}

	redirect() {
		if (localStorage.getItem('role') !== null && localStorage.getItem('role').toUpperCase() === ('TRANSLATOR')) {
				
				this.props.props.push({
					pathname: '/language-manager', validation: this.props.validation
	
				})
			}
			else {
				this.props.props.push(`/start`)
			}
		}

	check(e){
		if(e.target.checked){
			this.setState({chkbox: true})
		}		
	}

	disagreeCheck(e){
		if(e.target.checked){
			this.setState({chkbox: false})
		}
		
	}

	render(){
		return(
		<div className="popup-content">
			<div className="grid-x pt20 pl20 pr20">
				<div className="medium-12 cell">
					<div className="title">
					    <div dangerouslySetInnerHTML={{__html: this.props.content.header}}></div> 
					</div>					
				</div>
			<div className="description">
				<Form model="terms" method="post" 
                getDispatch={(dispatch) => this.formDispatch = dispatch}
                onSubmit={(formValue) => this.isChecked(formValue)}>
					
					<p>{this.props.content.description}</p>
								
					
					{ this.props.buttonVisible !== undefined && this.props.buttonVisible ?
					<div className="grid-x grid-padding-x pt13" >
					<div style={{paddingTop: '5px'}}>
					   <Checkbox checked={ this.state.chkbox } onClick = {(e) =>this.check(e)}/>
					</div>
					<div className="medium-11 cell">
					<p>{this.props.content.sendText}</p>
					</div>

					<div style={{paddingTop: '5px'}}>
					   <Checkbox checked={ this.state.chkbox === false ? true : false} onClick = {(e) =>this.disagreeCheck(e)}/>
					</div>
					<div className="medium-11 cell">
					<p>{this.props.content.dontSendText}</p>
					</div>

					<div className="medium-12 cell"></div>
					<div className="medium-2 cell pt13" >
                       <Button 
                       	type="submit" 
			            	content="Ok"
                         color 
         	             	/>
       		            </div>
					 </div>
					 : null
					}
				</Form>
			</div>
			</div>
			<div className="errors">{this.props.user.error}</div>
		</div>
		)
	}
	
}

const mapStateToProps = (state) => {
	return {
		content: state.content.NewsLetter,
		validation: state.content.validation,
		user: state.default
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		NewsletterAcceptanceUpdate: (formValues) => dispatch(NewsletterAcceptanceUpdate(formValues)),
		newsletterAcceptanceSuccess: (user) => dispatch(newsletterAcceptanceSuccess(user)),
		newsletterAcceptanceFailure: (error) => dispatch(newsletterAcceptanceFailure(error)),
		resetError: () => dispatch(resetError())
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsLetter)


