import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Button from 'UI/Button'
import TabContainer from './TabContainer';
import { AppBar } from '@material-ui/core';

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    borderBottom: "1px solid grey",
    boxShadow: "none"
  },
  indicator: {
    backgroundColor: "#008fd3",
  },
  textColor: {
    // color: "#008fd3",
  },
  paddingTop: {
    paddingTop: "20px"
  },
  tabs: {
    borderLeft: "1px solid grey",
    borderRight: "1px solid grey",
    padding: "10px"
  },
  borderBothSides: {
    borderLeft: "1px solid grey",
    borderRight: "1px solid grey",
  },
  borderLeftSide: {
    borderLeft: "1px solid grey",
  }
});

class TabPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: props.tabId,
    };
  }

  handleChange = (event, activeTab) => {
    this.setState((state) => ({ activeTab }));
    if (this.props.dispatchTabChange) {
      if (this.props.handleNextButton) {
        this.props.handleNextButton(activeTab)
      }
      this.props.dispatchTabChange(activeTab)

    }
  };

  render() {
    const { classes } = this.props;
    const { activeTab } = this.state;

    return (
      <div class="tab-container bottom-space">
        {
          this.props.headerText ?
            <div className="tab-container-header">{this.props.headerText}</div> : null
        }

        <div className="grid-x">
          <div className="medium-12 large-12">
            <AppBar position="static" className={classes.root}>
              <Tabs
                value={activeTab}
                onChange={this.handleChange}
                classes={{ indicator: classes.indicator }}
              >
                {
                  this.props.tabs.map((tab, i) => (
                    <Tab
                      classes={{ label: classes.textColor }}
                      id={tab.id}
                      key={tab.id}
                      label={tab.label}
                      value={tab.id}
                      className="tabs-header"
                      aria-controls={`wrapped-tabpanel-${i}`}
                    />
                  ))
                }
              </Tabs>
            </AppBar>
            {
              this.props.tabs.map(tab => (
                activeTab === tab.id
                  ? <TabContainer classStyle={classes.paddingTop} key={tab.id}>{tab.component}</TabContainer>
                  : null
              ))
            }
          </div>
        </div>
        {
          this.props.nextButtonLabel && this.props.nextButtonLabel != "" ?
            (
              <div className="grid-x">
                <div className="medium-12 cell float-right">
                  <Button content={this.props.nextButtonLabel} float
                    disabled={this.props.spendDetails !== undefined ? this.props.spendDetails.isLoading : false}
                    onClick={(event) => {
                      var index = 0
                      for (; index < this.props.tabs.length; index++) {
                        if (activeTab === this.props.tabs[index].id) {
                          break;
                        }
                      }
                      if (index === this.props.tabs.length - 1) {
                        if (this.props.dispatchLastButtonClick) {
                          this.props.dispatchLastButtonClick(activeTab)
                        }
                      } else {
                        this.handleChange(event, this.props.tabs[index + 1].id)
                      }
                    }
                    }
                  />
                </div>
              </div>
            ) : null
        }

      </div>
    );
  }
}

TabPanel.propTypes = {
  classes: PropTypes.object.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      component: PropTypes.object.isRequired,
    }).isRequired)
    .isRequired,
};

export default withStyles(styles)(TabPanel);