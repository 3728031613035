import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Form } from 'react-redux-form'
import classNames from "classnames";
import { path } from 'Constants'
import moment from 'moment'
import { ControlTextE } from 'UI/CustomComponents/Controls';

// Actions
import {
    fetchRampScheduleFactor,
    resetError,
    updateRampScheduleFactor
} from 'actions/spendActions'

// Utils
import { BreakLine} from 'utils/Utils'

class RampScheduleFactor extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            scheduleFactor: [],
            editedScheduleFactors: [],
            responseMessage: '',
            isError: false
        }
        this.loadData = this.loadData.bind(this)
        this.trackScheduleFactorsChanges = this.trackScheduleFactorsChanges.bind(this)
        this.onScheduleFactorsSave = this.onScheduleFactorsSave.bind(this)
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        this.getAllScheduleFactors();
    }

    trackScheduleFactorsChanges(record) {
        var updatedRecords = this.state.editedScheduleFactors
        if (this.state.editedScheduleFactors.length === 0)
            updatedRecords.push(record)
        else {
            let index = this.state.editedScheduleFactors.findIndex(x => x.QUARTER_NO === record.QUARTER_NO);
            if (index !== undefined && index !== null && index > -1)
                updatedRecords.splice(index, 1)
            updatedRecords.push(record)
        }
        this.setState({ responseMessage: '', editedScheduleFactors: updatedRecords, isError: false })
    }

    getAllScheduleFactors() {
        this.setState({ responseMessage: '', isLoading: true, isError: false });
        this.props.fetchRampScheduleFactor().then((response) => {
            if (response.status === 200) {
                if (response.data.Result.length === 0) {
                    this.setState({ responseMessage: this.props.content.reimport, isLoading: false, isError: true });
                }
                else
                    this.setState({ scheduleFactor: response.data.Result, isLoading: false, responseMessage: '', isError: false });
            }
            else {
                this.setState({ responseMessage: this.props.content.errorMessage, isLoading: false, isError: true });
            }
        }).catch((error) => {
            this.setState({ responseMessage: this.props.content.errorMessage, isLoading: false, isError: true });
        })
    }

    onScheduleFactorsSave() {
        if (this.state.editedScheduleFactors.find(a => a.QUARTER_VALUE === '') === undefined ) {
            if (this.state.editedScheduleFactors.find(a => a.QUARTER_VALUE === '-') === undefined ) {
                this.setState({ isLoading: true, responseMessage: '', isError: false });
                let updatedRecords = this.state.editedScheduleFactors
                let email = this.props.user.user.Email
                let finalObject = [];
                updatedRecords.map(function (data) {
                    let record = {
                        'FACTOR_TYPE': data.FACTOR_TYPE,
                        'QUARTER_NO': data.QUARTER_NO,
                        'QUARTER_VALUE': data.QUARTER_VALUE, 
                        'UPDATED_BY': email                    
                    }
                    finalObject.push(record)
                });

                this.props.updateRampScheduleFactor(finalObject).then((response) => {
                    if (response.status === 200) {
                        this.setState({ scheduleFactor: response.data.Result, editedScheduleFactors: [], isLoading: false, responseMessage: this.props.content.successMessage, isError: false });
                    }
                    else {
                        this.setState({ isLoading: false, responseMessage: this.props.content.errorMessage, isError: true });
                    }
                }).catch((error) => {
                    this.setState({ isLoading: false, responseMessage: this.props.content.errorMessage, isError: true });
                })
            }
            else {
                this.setState({ responseMessage: this.props.content.validNumber, isError: true });
            }
        }
        else {
            this.setState({ responseMessage: this.props.content.blankValue, isError: true });
        }
    }

    componentWillUnmount() {
        this.props.resetError()
    }

    render() {
        var _instance = this
        var schedule_factors = this.state.scheduleFactor.map(function (data, index) {
            return (<TableRows
                rampScheduleFactor={data}
                index={index}
                readOnly={_instance.props.readOnly}
                trackScheduleFactorsChanges={_instance.trackScheduleFactorsChanges}
            />
            );
        });

        return (
            <div className="grid-x" style={{ width: '100%'}}>
                <div className="medium-12">
                    {(this.state.isLoading) ?
                        <img style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
                            src={path + `images/ajax-loader.gif`} alt="" /> : null}
                </div>
                <div className="medium-12">
                    <div className="grid-x">
                        <div className="medium-3 float-left" style={{ fontSize: '1.2rem', paddingBottom: '8px' }}>{BreakLine(this.props.content.rampScheduleTitle)}</div>
                        <div className="medium-6" style={{ display: 'flex', justifyContent: 'center' }} >
                            {this.state.isError ?
                                <div style={{ margin: '0 0 0.5rem', color: 'red' }}>{this.state.responseMessage}</div> :
                                <div style={{ margin: '0 0 0.5rem', color: '#4fb81c' }}>{this.state.responseMessage}</div>
                            }
                        </div>
                        <div className="medium-3"></div>
                    </div>
                    <div className="grid-x">
                        <div className="medium-11 float-left pb10">
                        {this.props.content.rampScheduleDesc}
                        </div>
                        {this.props.user.userprofile.Roles[0].indexOf("Admin") > -1 ?
                            <div className="medium-1 pb5">
                                <button className={classNames('float-right',
                                    {
                                        'hand-cursor': this.state.isLoading ? false : (this.state.editedScheduleFactors.length > 0),
                                        'gray-scale': this.state.isLoading ? true : (this.state.editedScheduleFactors.length === 0)
                                    })}
                                    onClick={this.onScheduleFactorsSave} disabled={this.state.isLoading ? true : this.state.editedScheduleFactors.length === 0} type="submit" float >
                                    <img width="25" title={this.props.content.save} src="images/save_active.svg" />
                                </button>
                            </div> : null}
                    </div>
                </div>
                {this.state.scheduleFactor.length > 0 ?
                    <div className="medium-12">
                        <Form model="netwrokEnablementMatchTypeParameter">
                            <div className="basic-table">
                                <table>
                                    <thead>
                                        <tr className="eachRow">                                            
                                            <th>{this.props.content.quarterNo}</th>
                                            <th>{this.props.content.quarterValue}</th>
                                            <th>{this.props.content.updatedBy}</th>
                                            <th>{this.props.content.updatedDate}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {schedule_factors}
                                    </tbody>
                                </table>
                            </div>
                        </Form>
                    </div>
                    : null
                }
            </div>
        )
    }
}

class TableRows extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    onChangeEvent(val) {
        if (val.target.value === '' || val.target.value.match(/^[0-9]+$/) || !isNaN(val.target.value)) {
            var editingRow = this.props.rampScheduleFactor;
            let editedValue = val.target.value.trim()
            if (editedValue.toString() !== editingRow[val.target.id].toString()) {
                editingRow[val.target.id] = editedValue
                this.props.trackScheduleFactorsChanges(editingRow)
            }
        }
    }

    render() {
        var bindValues = this.props.rampScheduleFactor;
        return (
            <tr className="eachRow">                
                <td model="rampScheduleFactor.ACCOUNT_TYPE">{bindValues.QUARTER_NO}</td>
                    <td>
                        <ControlTextE
                            model="rampScheduleFactor.QUARTER_VALUE"
                            onChange={this.onChangeEvent.bind(this)}
                            id={'QUARTER_VALUE'}
                            defaultValue={bindValues.QUARTER_VALUE}
                            value={bindValues.QUARTER_VALUE}
                            type='name'
                            className="edit-textarea edit-width"
                            readOnly={this.props.readOnly}
                        />
                    </td>
                
                <td model="rampScheduleFactor.UpdatedBy">{bindValues.UPDATED_BY}</td>
                <td model="rampScheduleFactor.UpdatedOn">{moment(bindValues.UPDATED_ON).format('L')}</td>
            </tr>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        language: state.language,
        ui: state.content.ui,
        user: state.default,
        spendDetails: state.spendDetails,
        //content: state.content.spendAnalysis.enablementResourceParamaeters,
        content: state.content.menu.EnablementParametersManager[0].RampScheduleTab
    }
}


const mapDispatchToProps = (dispatch) => {
    return {        
        resetError: () => dispatch(resetError()),
        fetchRampScheduleFactor: () => dispatch(fetchRampScheduleFactor()),      
        updateRampScheduleFactor: (data) => dispatch(updateRampScheduleFactor(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RampScheduleFactor)


