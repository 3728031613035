import React from "react";
import ReactDOM from "react-dom";
import moment from 'moment'

import { Editors } from "react-data-grid-addons";
const { SimpleTextEditor } = Editors;

export default class DateEditor extends SimpleTextEditor {
    constructor(props) {
      super(props);
    }

    validate(val) {
      if(Object.values(val)[0] === '') {
        return true;
      }
      
      return moment(Object.values(val)[0], 'YYYY-MM-DD', true).isValid();
    }
}