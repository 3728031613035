import EnablementResourceParameters from 'components/Spend/YourSpendFile/EnablementResourceParameters'
import React, { Component } from 'react'
import EnablementStrategyWizard from './EnablementStrategyWizard'
import KPIContainer from 'components/Kpi/KPIContainer'
import { ControlTextE } from 'UI/CustomComponents/Controls';
import { connect } from 'react-redux';
import Popup from "reactjs-popup";
import Modal from 'reboron/OutlineModal'
import Button from 'UI/Button'
import Select from 'react-select'
import { BreakLine } from 'utils/Utils'
import EnablementStrategyKPI from './EnablementStrategyKPI'

import {
  pendingChanges,
  kpiModelChanged,
  //saveKPIOnRedirect
} from 'actions/spendActions'
import EnablementKPITable from './EnablementKPITable'

import { changeModalStyle, changeModalContentStyle, backdropStyle, path } from 'Constants'

const changeBackdropStyle = Object.assign({}, backdropStyle);
changeBackdropStyle.zIndex = '1050';

const alertModalStyle = Object.assign({}, changeModalStyle);
alertModalStyle.zIndex = '1060';
alertModalStyle.width = '33%';

class EnablementStrategyWizardContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      isSaveClicked: false,
      isResourceParamterSaved: false,
      selectedModel: 1,
      modelClicked : false,
      refreshResourceTable: false,
      refreshPriorityTable: false,
      shouldCallWaveCalc : false,
      model: [
        { label: 'Intelligent Strategy Model', value: 1 },
        { label: 'KPI Strategy Model', value: 2 }
      ],
      kpiSelectedButton: 3,
      enablementResourceParameters: [],
      isKPIDataLoaded: false,
      isResourceParamDataLoaded: false,
    }

  }

  componentDidUpdate(nextProps) {
    if((nextProps.isKPIPageRedirect != this.props.isKPIPageRedirect) && this.props.isKPIPageRedirect) {
        this.setState({ isSaveClicked: true, isWizardSaveClicked: true});
    }
  }

  handleStrategyWizardChanges(data) {
    this.setState({ isWizardSaveClicked: false })
  }

  handleResourceParameterChanges(data) {
    this.setState({ isSaveClicked: false })
  }

  EnablementStrategyKPIChanges(data) {
    this.setState({ isSaveClicked: false })
  }

  EnablementStrategyKPIButtonClick(data) {
    this.setState({ kpiSelectedButton: data })
  }

  handleIsResourceParamterSaved(data) {
    this.setState({ isResourceParamterSaved: data });
  }

  EnablementStrategyModelUpdate(data) {
    this.setState({ selectedModel: data })
  }

  getEnablementResourceParameters(data) {
    this.setState({ enablementResourceParameters: data })
  }

  EnablementStrategyrefreshResourceTable(data) {
    this.setState({ refreshResourceTable: data })
    if(!data)
      this.setState({isLoading : false})
  }

  EnablementStrategyrefreshPriorityTable(data) {
    this.setState({ refreshPriorityTable: data })
  }
  
  EnablementStrategyShouldCallWaveCalc(data) {
    this.setState({ shouldCallWaveCalc: data })
  }  

  UpdateKPIDataLoaded(data) {
    this.setState({ isKPIDataLoaded: data })
  }

  UpdateResourceParamDataLoaded(data) {
    this.setState({ isResourceParamDataLoaded: data })
  }  

  onSaveClicked() {
    this.setState({ isSaveClicked: true })
  }

  showModal(action) {
    this.refs[action].show()
  }

  hideModal(action) {
    this.refs[action].hide()
  }

  continueNavigation(action) {
    this.refs[action].hide();
    this.setState({ isLoading :true });
    this.props.kpiModelChanged(this.state.selectedModel);
    //var kpiSelectedButtonOldVal = this.state.kpiSelectedButton
    //this.setState({ kpiSelectedButton: 0},this.setState({ kpiSelectedButton: kpiSelectedButtonOldVal}));
    if (this.state.kpiSelectedButton == 0)
      this.setState({ kpiSelectedButton: 5 });
    this.setState({ isSaveClicked: true, isWizardSaveClicked: true });

  }

  handleModelChange(value) {
    // this.props.kpiModelChanged(value.value);
    let selectedKPIButton = value.value == 1 ? 1 : 3;
    this.setState({ modelClicked : true ,selectedModel: value.value, kpiSelectedButton: selectedKPIButton, isKPIDataLoaded: false,isResourceParamDataLoaded : false });
  }

  render() {
    return (
      <div>
        <div className='grid-x medium-12' style={{ padding: '10px' }}>
          <div className='medium-3'>
            <div className="control-wrapper">
              <label>Solution in Scope : </label>
              <ControlTextE
                model="discovery.SolutionInScope"
                id="discovery.SolutionInScope"
                value={this.props.spendDetails.solutionScope}
                disabled={true}
                defaultValue={""}
                className="edit-textarea width180"
                style={{ height: '42px' }}
              />
              <span>
                <Popup className="report-tooltip"
                  trigger={<img style={{ marginLeft: '5px', paddingBottom: '30px' }} className="info-icon" src="images/info-icon.svg" width="15" />}
                  position="bottom left"
                  on="hover"
                >
                  <div>
                    {this.props.wizard.solutionScopeHover}
                  </div>
                </Popup>
              </span>
            </div>
          </div>
          <div className='medium-3'>
            <div className="control-wrapper">
              <label>Industry : </label>
              <ControlTextE
                model="discovery.Industry"
                id="discovery.Industry"
                value={this.props.formContent.industries[this.props.userData.Industry]}
                disabled={true}
                defaultValue={""}
                className="edit-textarea width160"
                style={{ width: '310px', height: '42px' }}
              />
              <span>
                <Popup className="report-tooltip"
                  trigger={<img style={{ marginLeft: '5px', paddingBottom: '30px' }} className="info-icon" src="images/info-icon.svg" width="15" />}
                  position="bottom left"
                  on="hover"
                >
                  <div>
                    {this.props.wizard.industryHover}
                  </div>
                </Popup>
              </span>
            </div>
          </div>
          <div className='medium-4'>
            <div className="subHeader pl20 pt10 float-left">Model :</div>
            <div className="float-left pl20" style={{ width: '310px', height: '42px' }}>
              <Select
                autocomplete="true"
                value={this.state.model.filter(option => option.value === this.state.selectedModel)}
                onChange={(value) => this.handleModelChange(value)}
                options={this.state.model}
                isSearchable={false}                
                isDisabled={this.props.spendDetails.solutionScope == 'Supply Chain Collaboration' ? true : false}
              />
            </div>
          </div>

          <div className="medium-2">
            <div className="float-right">
              <button className="button button--accent"
                onClick={(e) => this.showModal("alert")} >
                <span className="button__inner">{"Save"}</span>
              </button>
            </div>
          </div>


        </div>

        {(this.state.isLoading) ?
                                <img style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={path + `images/ajax-loader.gif`} alt="" />
                                : null
        }
        <div>
          <div className='grid-x medium-12' style={{ padding: '10px' }}>
            <EnablementResourceParameters handleResourceParameterChanges={(e) => this.handleResourceParameterChanges(e)}
              isSaveClicked ={this.state.isSaveClicked}
              modelClicked ={this.state.modelClicked}
              kpiSelectedModel={this.state.selectedModel}
              isResourceParamDataLoaded ={this.state.isResourceParamDataLoaded}
              callbackEnablementResourceParameters={(e) => this.getEnablementResourceParameters(e)}
              handleIsResourceParamterSaved={(e) => this.handleIsResourceParamterSaved(e)}
              UpdateResourceParamDataLoaded={(e) => this.UpdateResourceParamDataLoaded(e)}
              EnablementStrategyrefreshPriorityTable ={(e) => this.EnablementStrategyrefreshPriorityTable(e)}
            ></EnablementResourceParameters>
          </div>
          <div className="kpiDashTable grid-x" style={{ padding: '10px' }}>
            <div className="grid-x medium-12">
              <EnablementKPITable kpiModel={this.state.selectedModel}
                kpiSelectedButton={this.state.kpiSelectedButton}
                EnablementStrategyrefreshResourceTable={(e) => this.EnablementStrategyrefreshResourceTable(e)}
                //EnablementStrategyrefreshPriorityTable ={(e) => this.EnablementStrategyrefreshPriorityTable(e)}
                isRefresh={this.state.refreshResourceTable}
                enablementResourceParameters={this.state.enablementResourceParameters}
              ></EnablementKPITable>
            </div>
          </div>
          {/* {this.state.selectedModel == 2 ? */}
          <div>
            <EnablementStrategyKPI EnablementStrategyKPIChanges={(e) => this.EnablementStrategyKPIChanges(e)}
              EnablementStrategyKPIButtonClick={(e) => this.EnablementStrategyKPIButtonClick(e)}
              EnablementStrategyModelUpdate={(e) => this.EnablementStrategyModelUpdate(e)}
              UpdateResourceParamDataLoaded={(e) => this.UpdateResourceParamDataLoaded(e)}
              EnablementStrategyrefreshResourceTable={(e) => this.EnablementStrategyrefreshResourceTable(e)}              
              // EnablementStrategyrefreshPriorityTable ={(e) => this.EnablementStrategyrefreshPriorityTable(e)}
              UpdateKPIDataLoaded={(e) => this.UpdateKPIDataLoaded(e)}
              handleIsResourceParamterSaved={(e) => this.handleIsResourceParamterSaved(e)}
              EnablementStrategyShouldCallWaveCalc={(e) => this.EnablementStrategyShouldCallWaveCalc(e)}
              isResourceParamterSaved={this.state.isResourceParamterSaved}
              isSaveClicked={this.state.isSaveClicked}
              kpiSelectedModel={this.state.selectedModel}
              kpiSelectedButton={this.state.kpiSelectedButton}
              isKPIDataLoaded={this.state.isKPIDataLoaded}
              shouldCallWaveCalc = {this.state.shouldCallWaveCalc}
            ></EnablementStrategyKPI>
          </div>
          {/* : null} */}
          <div>
            <EnablementStrategyWizard
            EnablementStrategyrefreshResourceTable={(e) => this.EnablementStrategyrefreshResourceTable(e)}              
            handleStrategyWizardChanges={(e) => this.handleStrategyWizardChanges(e)} 
            isWizardSaveClicked={this.state.isWizardSaveClicked} 
            EnablementStrategyrefreshPriorityTable ={(e) => this.EnablementStrategyrefreshPriorityTable(e)}
            isRefresh = {this.state.refreshPriorityTable}
            shouldCallWaveCalc = {this.state.shouldCallWaveCalc}
            UpdateKPIDataLoaded={(e) => this.UpdateKPIDataLoaded(e)}
            ></EnablementStrategyWizard>
          </div>

        </div>



        {/* Recast popup     */}
        <div>
          <Modal
            ref="alert"
            modalStyle={alertModalStyle}
            contentStyle={changeModalContentStyle}
            backdropStyle={changeBackdropStyle}
          >
            <div className="grid-x grid-padding-x change-popup">
              <div className="medium-12">
                <button onClick={() => this.hideModal("alert")} className="close" />
              </div>
              <div className="medium-12 pt30 pb10">
                {this.state.selectedModel == 1 ? 
                this.props.wizard.applyPriorityChanges
              : this.props.wizard.applyPriorityChangesKPI
              }
              </div>
              <div className="medium-12 popupOkBtn">
                <div className="float-right">
                  <div className="float-left"><Button
                    content={"Yes, recalculate enablement waves"}
                    onClick={() => this.continueNavigation("alert")}
                    color /></div>
                  <div className="float-left"><Button
                    content={"No, cancel"}
                    onClick={() => this.hideModal("alert")}
                    color /></div>
                </div>
              </div>
            </div>
          </Modal>
        </div>

      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    // language: state.language,
    userData: state.valueStory,
    user: state.default,
    accountParameters: state.content.spendAnalysis.accountParameters,
    wizard: state.content.spendAnalysis.prioritizationWizard,
    validation: state.content.validation,
    spendDetails: state.spendDetails,
    formContent: state.content.discovery.form,
    content: state.content.spendAnalysis.enablementResourceParamaeters,
    isKPIPageRedirect: state.saveKPIOnRedirect.changes,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    pendingChanges: (changes) => dispatch(pendingChanges(changes)),
    //saveKPIOnRedirect: changes => dispatch(saveKPIOnRedirect(changes)),
    //getUpdateEnablementKPIModel: (DMCId, VSId, userEmail, ReadWriteFlag, EnablementKPIModel) => dispatch(getUpdateEnablementKPIModel(DMCId, VSId, userEmail, ReadWriteFlag, EnablementKPIModel)),
    kpiModelChanged: (val) => dispatch(kpiModelChanged(val)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EnablementStrategyWizardContainer)