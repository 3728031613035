import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

// Actions
import { priorityLevelChange } from 'actions/prioritiesActions'
import {
	toggleValueDriver,
	toggleSubLever
} from 'actions/valueDriverActions'

import {
	valueStoryCurrentMenu,
	showValueStoryMenu
}
	from 'actions/userActions'

// Components
import Priorities from './Priorities'
import TabPanel from '../Tabs/TabPanel'

import GoArrowLeft from 'react-icons/lib/go/arrow-left'

// Common Functions
import { dispatchToggleSKU } from 'components/SKU/SolutionChange'

import { routes, mappingSKUsPriorityAndSubLever } from 'Constants'

class PrioritiesContainer extends PureComponent {

	constructor(props) {
		super(props)

		this.state = {
			render: false,
			pathname: routes.priorities,
			nextPageId: 0,
			priorityId: 0,
			count: 0,
			nextButtonLabel: props.ui.next
		}

		this.dispatchRadioButtonChange = this.dispatchRadioButtonChange.bind(this)
		this.intializePrioritiesTabs = this.intializePrioritiesTabs.bind(this)
		this.dispatchTabChange = this.dispatchTabChange.bind(this)
		this.getPriorityTabComponent = this.getPriorityTabComponent.bind(this)
		this.dispatchLastButtonClick = this.dispatchLastButtonClick.bind(this)
		this.vlmAlertToggle = this.vlmAlertToggle.bind(this)
	}

	intializePrioritiesTabs() {
		const tabs = [];
		this.props.priorities.map(priority => (
			tabs.push({
				id: priority,
				label: this.props.content.prioritySections[priority].name,
				component: this.getPriorityTabComponent(priority)
			})
		))

		this.setState({
			tabs: tabs,
			priorityId: this.props.priorities[0]
		})

		if (this.props.priorities.length === 1) {
			this.setState({
				nextButtonLabel: this.props.ui.next//this.props.content.goToSpendButton,
				//	render: true
			})
		}
	}

	dispatchTabChange(priorityId) {
		this.setState({
			priorityId: priorityId,
			nextButtonLabel: this.props.ui.next
		})

		// if(this.props.priorities[this.props.priorities.length - 1] === priorityId) {
		// 	this.setState({
		// 		nextButtonLabel: this.props.ui.next//this.props.content.goToSpendButton,
		// 		//	render: true
		// 	})
		// }
	}

	dispatchLastButtonClick(tabId) {
		if (this.props.user !== undefined && this.props.user.user.applicationFeatures !== undefined && this.props.user.user.applicationFeatures.findIndex((a) => a.Action === '/yourSpend') > -1) {
			this.props.history.push(`/yourSpend`)
		}
		else {
			this.props.history.push(`/skus`)
		}

	}

	dispatchRadioButtonChange(item, subQuestion, subIndex, value, jsonSubIndex) {
		//AreaOfInterest
		var listSKU = null;
		var _instance = this;
		var existingSKUIds = this.props.userData.AreaOfInterest[this.state.priorityId].AOIValueDrivers[subIndex].SKUId

		for (let index_SKUs = 0; index_SKUs < mappingSKUsPriorityAndSubLever.length; index_SKUs++) {
			if (mappingSKUsPriorityAndSubLever[index_SKUs].autoOn === undefined) {
				const mappedPriorities = mappingSKUsPriorityAndSubLever[index_SKUs].mappedPriorities;
				for (let index_prior = 0; index_prior < mappedPriorities.length; index_prior++) {
					const priority = mappedPriorities[index_prior];
					if (priority.prioritiesId === this.state.priorityId && priority.priorityIndex.toString() === jsonSubIndex) {
						if (value >= 4) {
							if (listSKU === null) {
								listSKU = mappingSKUsPriorityAndSubLever[index_SKUs].id;
							}
							else {
								listSKU = listSKU + ',' + mappingSKUsPriorityAndSubLever[index_SKUs].id;
							}
							dispatchToggleSKU(mappingSKUsPriorityAndSubLever[index_SKUs].id, true);
						}
						else if (existingSKUIds !== undefined && existingSKUIds !== null) {
							var splittedSKUList = existingSKUIds.split(',');
							for (let index = 0; index < splittedSKUList.length; index++) {
								const element = splittedSKUList[index];
								if (existingSKUIds.split(',').indexOf(mappingSKUsPriorityAndSubLever[index_SKUs].id) < 0) {
									if (listSKU === null) {
										listSKU = element;
									}
									else {
										listSKU = listSKU + ',' + element;
									}
									break;
								}
								else {
									dispatchToggleSKU(element, false);
								}
							}
						}
						break;
					}
				}
			}
		}

		this.props.userData.AreaOfInterest[this.state.priorityId].AOIValueDrivers[subIndex].SKUId = listSKU;
		// Dispatch to Redux
		this.props.priorityLevelChange(this.state.priorityId, subIndex, Number(value))

	}

	componentWillMount() {
		window.scrollTo(0, 0)
		this.props.valueStoryCurrentMenu('Priorities');
		this.props.showValueStoryMenu(true);
		//	this.getNextPage()
		this.intializePrioritiesTabs()
	}

	getPriorityTabComponent(priorityId) {
		return (
			<div className="grid-x priorities-tab-cont">
				<div className="grid-x">
					<p className="medium-12 tab-title">{this.props.content.prioritySections[priorityId].title}</p>
					<p className="medium-12 tab-descr">{this.props.content.prioritySections[priorityId].description}</p>
					<div className="medium-4 medium-offset-8">
						<div className="question__subheader grid-x grid-padding-x">
							<div className="medium-2 medium-offset-2">{this.props.content.priorityLevels[0]}</div>
							<div className="medium-2">{this.props.content.priorityLevels[1]}</div>
							<div className="medium-2">{this.props.content.priorityLevels[2]}</div>
							<div className="medium-2">{this.props.content.priorityLevels[3]}</div>
							<div className="medium-2">{this.props.content.priorityLevels[4]}</div>
						</div>
					</div>
				</div>
				<Priorities
					priorityId={priorityId}
					dispatchRadioButtonChange={this.dispatchRadioButtonChange}
				/>
			</div>
		)
	}

	vlmAlertToggle() {
        this.setState(prevState => {return {vlmAlertDiv: !prevState.vlmAlertDiv}})
    }

	render() {
		return (
			<div className="priorities grid-container">
				{
					<div className="grid-x">
						<div className="link-label medium-12">
							{this.props.content.title}/ {this.props.content.areaOfInterestTitle}/ <span className="font-bold">{this.props.content.prioritySections[this.state.priorityId].name}</span>
						</div>
					</div>}
				<div className="grid-x">
					<div className="page-title medium-4">
						{this.props.content.title}
					</div>
					<div onClick={this.vlmAlertToggle} className="medium-8 font-bold hand-cursor" style={{lineHeight:"3", color: "#f0434c", fontSize: "12px"}}>{this.props.discoveryContent.vlmAlertHeader}</div>
					{<div className='medium-12'>
                     {this.state.vlmAlertDiv ? <span>{this.props.discoveryContent.vlmAlertDesc1}<br /><br />{this.props.discoveryContent.vlmAlertDesc2}<a href="http://valuemanagement.sap.com/" target="_blank">{this.props.discoveryContent.vlmAlertDesc3}</a>.</span> : ''}
                    </div>}
				</div>
				<div className="grid-x tabs_container">
					{/* <div className="arrow">
						<Link to={{
							pathname: routes.areaOfInterest
						}}>
							<img src="images/back_inactive.svg" />
						</Link>
					</div> */}
					<div className="medium-12">
						<TabPanel
							tabs={this.state.tabs}
							tabId={this.props.priorities[0]}
							dispatchTabChange={this.dispatchTabChange}
							dispatchLastButtonClick={this.dispatchLastButtonClick}
							nextButtonLabel={this.state.nextButtonLabel} />
					</div>
				</div>
			</div >
		)
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.default,
		ui: state.content.ui,
		content: state.content.priorities,
		userData: state.valueStory,
		priorities: state.priorities,
		discoveryContent: state.content.discovery
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		priorityLevelChange: (priorityId, subIndex, value) => dispatch(priorityLevelChange(priorityId, subIndex, value)),
		toggleValueDriver: (id, bool) => dispatch(toggleValueDriver(id, bool)),
		toggleSubLever: (id, bool) => dispatch(toggleSubLever(id, bool)),
		valueStoryCurrentMenu: (menuItem) => dispatch(valueStoryCurrentMenu(menuItem)),
		showValueStoryMenu: (menuItem) => dispatch(showValueStoryMenu(menuItem)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PrioritiesContainer)