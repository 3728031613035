import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Control, Form, Errors, actions } from 'react-redux-form'

// Actions
import {
	autoSave,
	autoSaveSuccess,
	autoSaveFailure,
	resetError,
	saveAs,
	saveAsSuccess,
	saveAsFailure,
	openValueStory,
	openValueStoryFailure,
	saveAsLoading
}
	from 'actions/userActions'



import Modal from 'reboron/OutlineModal'

import { valueStoryFetchDataSuccess } from 'actions/fetchValueStory'

// UI
import Textfield from 'react-mdl/lib/Textfield'
import Button from 'UI/Button'

// Settings
import { validators, routes } from 'Constants'

// Utils
import { isDuplicate } from 'utils/Utils'

import {changeModalStyle, changeModalContentStyle } from 'Constants'

const confirmationMessageModalStyle = Object.assign({}, changeModalStyle);
confirmationMessageModalStyle.width = "40%";
//confirmationMessageModalStyle.height = "450px";

class SaveAs extends Component {

	constructor(props) {
		super(props)

		this.save = this.save.bind(this)
		this.saveAs = this.saveAs.bind(this)
		this.showModal = this.showModal.bind(this)
		this.hideModal = this.hideModal.bind(this)

		this.redirect = this.redirect.bind(this)
		this.actions = this.getApplicationFeatureActions();
	}

	showModal(action) {
        this.refs[action].show()
    }

    hideModal(action) {
		this.refs[action].hide()
    }

	componentDidMount() {
		this.props.resetError()
		this.formDispatch(actions.reset('saveAs.ValueStoryName'))
	}

	save(formValues) {
		var content = this.props.content;
		let token = localStorage.getItem('jwtToken')
		this.props.saveAsLoading(true);
		let values = Object.assign({}, formValues)
		values.Language = this.props.language.toUpperCase()

		if (this.props.user.user && token) {
			this.props.autoSave(this.props.userData, this.props.user.userprofile.Roles[0].indexOf("BusinessCaseRole") > -1)
				.then((result) => {
					this.props.autoSaveSuccess(result.data.Message)
					this.saveAs(values)
				})
				.catch((error) => {
					//console.log('saveAs error', error)
					var msg = content[error.response.status]
					if (msg === undefined) {
						this.props.openValueStoryFailure(error.response.data.Message)
					}
					else {
						this.props.openValueStoryFailure(msg)
					}
					//this.props.autoSaveFailure(error.response.data.Message)

					setTimeout(() => {
						this.props.resetError()
					}, 1000)
				})
		}
	}

	saveAs(values) {
		var content = this.props.content;
		this.props.saveAs(values, values.VSId)
			.then((response) => {
				if (response.status === 200) {
					// fetch newly created value story
					this.props.openValueStory(response.data.Result.VSId, this.props.user.user.Id)
						.then((response) => {
							this.props.saveAsSuccess()
							// Replace existing value story with newly created value story data in store

							this.props.valueStoryFetchDataSuccess(response.data.Result)
							// Reset form
							this.formDispatch(actions.reset('saveAs'))
							this.props.hideModal('saveAs')	
							this.redirect()	
							// Hide modal
							//this.showModal('confirmation')
						}).catch((error) => {
							//console.log('saveAs error', error)
							var msg = content[error.response.status]
							if (msg === undefined) {
								this.props.openValueStoryFailure(error.response.data.Message)
							}
							else {
								this.props.openValueStoryFailure(msg)
							}
							setTimeout(() => {
								this.props.resetError()
							}, 1000)
						})

				}
				else {
					var httpStattus = response.response.status;
					var failMsg = content[httpStattus]
					if (failMsg !== undefined) {

						this.props.openValueStoryFailure(failMsg)
					}
					else {
						this.props.openValueStoryFailure(response.response.data.Message)

					}
					setTimeout(() => {
						this.props.resetError()
					}, 1000)
				}

			}).catch((error) => {

				var msg = content[error.response.status]
				if (msg === undefined) {
					this.props.openValueStoryFailure(error.response.data.Message)
				}
				else {
					this.props.openValueStoryFailure(msg)
				}
				setTimeout(() => {
					this.props.resetError()
				}, 1000)
			})
	}

	redirect() {
		if(this.actions.indexOf(routes.yourSpend)> -1)
		{
           this.props.history.push(this.actions.indexOf(routes.businessValue)> -1 ?  `/discovery` : `/yourSpend`)		
		}
		else{
			this.props.history.push(`/discovery`)
		}
		
	}

	getApplicationFeatureActions() {
		let actions = [];
		let applicationFeatures = this.props.user && this.props.user.user && this.props.user.user.applicationFeatures ? this.props.user.user.applicationFeatures : [];
		applicationFeatures.map(applicationFeat => {
			actions.push(applicationFeat.Action);
		})
		return actions;
	}

	render() {

		return (
			<div>
				<div className="grid-x">
					<div className="medium-12 cell popup-content">
						<div className="title">{this.props.content.title}</div>
						<div className="1h">{this.props.content.description}</div>
						<Form
							model="saveAs"
							getDispatch={(dispatch) => this.formDispatch = dispatch}
							onSubmit={(values) => this.save(values)}
						>
							<Control.text
								model="saveAs.ValueStoryName"
								id="saveAs.ValueStoryName"
								component={Textfield}
								label={this.props.content.placeholder}
								floatingLabel
								validators={{ required: validators.required,
											duplicate: (vals) => isDuplicate(this.props.user.valueStories, vals, undefined) }}
								validateOn="blur"
								style={{ width: '100%' }}
							/>
							<Errors
								className="errors"
								model="saveAs.ValueStoryName"
								show="touched"
								messages={{
									required: this.props.validation.required
									, duplicate: this.props.validation.duplicateValueStoryName
								}}
							/>
							<Control.text
								model="saveAs.VSId"
								id="saveAs.VSId"
								defaultValue={this.props.userData.VSId}
								hidden
							/>
							<Control.text
								model="saveAs.Id"
								id="saveAs.Id"
								defaultValue={this.props.user.user.Id}
								hidden
							/>
							<Button
								type="submit"
								disabled={this.props.user.loading}
								content={this.props.content.title}
								color
								float
							/>
						</Form>
						<div className="errors">{this.props.user.error}</div>
						<div>
							<Modal
								ref="confirmation"
								modalStyle={confirmationMessageModalStyle}
								contentStyle={changeModalContentStyle}
							>
								<div className="grid-x grid-padding-x change-popup">
									<div className="medium-12">
										<button onClick={() => this.hideModal("confirmation")} className="close" />
									</div>
									<div className="medium-12 pt30">
										{this.props.content.confirmationMessage}
									</div>
									<div className="medium-12 popupOkBtn">
										<div className="float-right">
											<div className="float-left"><Button
												content={"Close"}
												onClick={() => this.hideModal("confirmation")}
												color /></div>
										</div>
									</div>
								</div>

							</Modal>
					</div>
				</div>
			</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		language: state.language,
		content: state.content.menu.saveAs,
		user: state.default,
		userData: state.valueStory,
		validation: state.content.validation
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		autoSave: (valueStory, isBusinessCaseAccess) => dispatch(autoSave(valueStory, isBusinessCaseAccess)),
		autoSaveSuccess: (message) => dispatch(autoSaveSuccess(message)),
		autoSaveFailure: (error) => dispatch(autoSaveFailure(error)),
		resetError: () => dispatch(resetError()),
		saveAs: (formValues, id) => dispatch(saveAs(formValues, id)),
		saveAsSuccess: () => dispatch(saveAsSuccess()),
		saveAsFailure: (error) => dispatch(saveAsFailure(error)),
		valueStoryFetchDataSuccess: (valueStory) => dispatch(valueStoryFetchDataSuccess(valueStory)),
		openValueStory: (VSId, Id) => dispatch(openValueStory(VSId, Id)),
		openValueStoryFailure: (error) => dispatch(openValueStoryFailure(error)),
		saveAsLoading: (bool) => dispatch(saveAsLoading(bool))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SaveAs)
