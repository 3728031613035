//
//
//
//
//       <- This code is no longer used ->
//
//
//
//
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Button from 'UI/Button'
import ScrollArea from 'react-scrollbar'
import SearchField from "react-search-field";
import { GoSearch } from "react-icons/lib/go";
import SearchResults from './SearchResults'
import ValueStoryList from './ValueStories'
import AutoSearch from './AutoSearch'
import { path } from 'Constants'
import { removeMask } from 'utils/Utils'
import { modalStyle, modalContentStyle, routes, validators } from 'Constants'
import { valueStoryFetchDataSuccess } from 'actions/fetchValueStory'
import {
    fetchValueStories,
    fetchValueStoriesLoading,
    fetchValueStoriesSuccess,
    fetchValueStoriesFailure
}
    from 'actions/userActions'
import {
    fetchCrmResults,
    crmSearchLoading,
    crmSearchSuccess,
    crmSearchFailure,
    searchResult,
    searchText,
    searchData,
    fetchCapIqSearchResults,
    capIqSearchSuccess,
    capIqSearchFailure,
    companyProfileData,
    isNewValueStory
}
    from 'actions/searchActions'

function compare(a, b) {
    // Use toUpperCase() to ignore character casing
    const companyA = a.item.SAP_TOP_VIEW_NAME.toUpperCase();
    const companyB = b.item.SAP_TOP_VIEW_NAME.toUpperCase();

    let comparison = 0;
    if (companyA > companyB) {
        comparison = 1;
    } else if (companyA < companyB) {
        comparison = -1;
    }
    return comparison;
}

class SearchContainer extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            valueStories: [],
            searchData: props.data.searchResult,
            value: props.result,
            isLoading: true,
            valueStoryLoaded: false,
            message: '',
            isError: false,
            selectedData: [],
            storyFlag: false,
            resultFlag: true,
            valueStoriesObj: [],
        }
        this.onSuggestionSelected = this.onSuggestionSelected.bind(this)
        this.fetchValueStories = this.fetchValueStories.bind(this);
        this.callSearchResults = this.callSearchResults.bind(this);
        this.onChange = this.onChange.bind(this);
        this.companyProfileData = this.companyProfileData.bind(this);
        this.EmailProgress = this.EmailProgress.bind(this);
        this.getSelectedData = this.getSelectedData.bind(this);
        this.isLoading = this.isLoading.bind(this);
    }

    //{company: 'Accenture', industry: 'Retail', country: 'United States', revenue: 39750, capIQId: 113756,capIQCompany:'Accenture LLP', globalId: 5456786, globalCompany: 'Accenture Global', orgId: 123456, orgCompany: 'Accenture BP', peId: 456789, peCompany: 'Accenture PE', currencyCode: 'USD'}
    companyProfileData() {
        let arr = [{
            Organization: '', Industry: '', Location: '', AnnualRevenue: '0', TotalSpend: '0', CapIQId: '',
            CapIQCompany: '', GlobalUltimateId: '', GlobalUltimateCompany: '', BPId: '', BPCompany: '',
            PEId: '', PECompany: '', CurrencyCode: 'USD'
        }]
        let temp = this.state.selectedData;
        if (temp[0].name != '-') {
            arr[0].Organization = temp[0].name
        }
        if (temp[0].sapIndustry != '-') {
            arr[0].Industry = temp[0].sapIndustry
        }
        if (temp[0].location != '-') {
            arr[0].Location = temp[0].location
        }

        if (temp[0].capId != '-') {
            arr[0].CapIQId = temp[0].capId
        }
        if (temp[0].capIdName != '-') {
            arr[0].CapIQCompany = temp[0].capIdName
        }
        if (temp[0].globalId != '-') {
            arr[0].GlobalUltimateId = temp[0].globalId
        }
        if (temp[0].globalIdName != '-') {
            arr[0].GlobalUltimateCompany = temp[0].globalIdName
        }
        if (temp[0].bpId != '-') {
            arr[0].BPId = temp[0].bpId
        }
        if (temp[0].bpIdName != '-') {
            arr[0].BPCompany = temp[0].bpIdName
        }
        if (temp[0].peId != '-') {
            arr[0].PEId = temp[0].peId
        }
        if (temp[0].peIdName != '-') {
            arr[0].PECompany = temp[0].peIdName
        }
        if (temp[0].currency != '-') {
            arr[0].CurrencyCode = temp[0].currency
        }

        if (temp[0].revenue != '-') {
            let currency = temp[0].currency != '-' ? this.props.content.CurrencySymbols[temp[0].currency] : ''
            arr[0].AnnualRevenue = removeMask(temp[0].revenue, currency);
        }

        // this.props.companyProfileData(arr)
        //companyProfileData(){
        // let arr =[{ValueStoryName: '', Organization: 'Accenture', Industry: 'Retail', Location: 'United States', AnnualRevenue: 39750, TotalSpend: 3412, CapIQId: 113756, CapIQCompany:'Accenture LLP', GlobalUltimateId: 29906280, GlobalUltimateCompany: 'Accenture Global', BPId: 123456, BPCompany: 'Accenture BP', PEId: 456789, PECompany: 'Accenture PE', CurrencyCode: 'USD'}]
        const companyData = arr[0]; 

        const industryIdx = (companyData.Industry === null || !companyData.Industry) ? -2 : this.props.content.discovery.form.industryTranslations.findIndex(item => companyData.Industry.toLowerCase() === item.toLowerCase());
        const locationIdx = (companyData.Location === null || !companyData.Location) ? -1 : this.props.content.discovery.form.locationTranslations.findIndex(item => companyData.Location.toLowerCase() === item.toLowerCase());
        companyData.Industry = industryIdx + 1;
        companyData.Location = locationIdx;

        this.props.companyProfileData(arr);

        this.props.valueStoryFetchDataSuccess(companyData);
        this.props.isNewValueStory(true);
    }

    getSelectedData(data) {
        this.setState({ selectedData: data })
    }

    fetchValueStories(searchText) {
        this.props.fetchValueStoriesLoading(true)
        this.setState({ isError: false, message: '', })
        this.props.fetchValueStories(searchText)
            .then((result) => {
                this.props.fetchValueStoriesSuccess(result)
                var obj = [];
                for (var i = 0; i < result.data.Result.length; i++) {

                    obj.push(result.data.Result[i])
                }
                let temp = obj;
                temp.forEach((element, i) => {
                    element.content = false;
                    element.index = i;
                });
                this.setState({ valueStories: temp, valueStoryLoaded: true, isError: false, message: '', storyFlag: true}, this.isLoading)
            }).catch((error) => {
                this.props.fetchValueStoriesFailure(error.response.data.Message)
                this.setState({
                    message: error.response.data.Message, isLoading: false, isError: true
                })
            })
    }

    callSearchResults(searchText) {
        this.setState({ message: '', isError: false })
        //First call the crm procedure for search data
        this.props.fetchCrmResults(searchText)
            .then((result) => {
                this.props.crmSearchSuccess(result)
                if (result.data.Result.length != 0) {
                    let obj = result.data.Result;
                    this.props.searchData(obj);
                    //this.props.history.push(`/searchContainer`)

                    //This code filters the output with unique Global ultimate Id (i.e. SAP_TOP_VIEW)
                    const uniqueObj = [];
                    const map = new Map();
                    for (const item of obj) {
                        if (!map.has(item.SAP_TOP_VIEW)) {
                            map.set(item.SAP_TOP_VIEW, true);    // set any value to Map
                            uniqueObj.push({ item });
                        }
                    }
                    let sortedArr = uniqueObj.sort(compare);
                    //this.props.searchResult(sortedArr)
                    this.setState({ searchData: sortedArr, isError: false, message: '', resultFlag: true }, this.isLoading)
                } else {

                }
            }).catch((error) => {
                if (error.response.status == 400) {
                    this.props.fetchCapIqSearchResults(searchText, 1)
                        .then((result) => {
                            this.props.capIqSearchSuccess(result)
                            this.props.searchText(this.state.value)
                            //this.props.searchResult(result.data.Result)
                            this.setState({ searchData: result.data.Result, isError: false, message: '', resultFlag: true }, this.isLoading)
                        }).catch((error) => {
                            //this.props.capIqSearchFailure(error.response.data.Message)
                            this.setState({
                                message: error.response.data.Message, isLoading: false, isError: true
                            })
                        })
                } else {
                    //this.props.crmSearchFailure(error.response.data.Message)
                    this.setState({
                        message: error.response.data.Message, isLoading: false, isError: true
                    })
                }
            })
    }

    isLoading(value) {
        //this.setState({ isLoading: value })
        let obj = []
        if(this.state.valueStories.length != 0){
            obj = this.state.valueStories
        }
        if(this.state.resultFlag == true && this.state.storyFlag == true){
            this.props.searchResult(this.state.searchData)
            this.setState({resultFlag: false, storyFlag: false, isLoading: false ,valueStoriesObj: obj})
        }
    }


    EmailProgress(isLoading, isError, message) {
        this.setState({ isLoading: isLoading, isError: isError, message: message })
    }

    componentWillMount() {
        this.fetchValueStories(this.state.value);
        //this.callSearchResults(this.props.result);
    }

    onSuggestionSelected(event) {
        event.preventDefault()
        if (this.state.value != null && this.state.value != '' && this.state.value != undefined) {
            this.setState({ isLoading: true, isError: false, message: '' }, this.forceUpdate)
            this.props.searchText(this.state.value)
            // this.callSearchResults(this.state.value)
            this.fetchValueStories(this.state.value);
            setTimeout(() => {
                this.callSearchResults(this.state.value)
            }, 5000)
            

        } else {
            //this.props.crmSearchFailure('Please enter a value to search')
            this.setState({
                isError: true, message: this.props.content.search.required
            })
        }
        //this.props.history.push(`/searchContainer`)
    }
    onChange = (e) => {
        this.props.crmSearchFailure('')
        this.setState({
            isError: false, message: '', value: e.target.value
        });
    };

    //Remove hardcoded texts 
    render() {
        return (
            <div>
                <React.Fragment>
                    <div className="grey_bb">
                        <div className="grid-container">
                            <div className="grid-x searchBar">
                                <form onSubmit={this.onSuggestionSelected}>
                                    <div className="search-bar">
                                        <input type='text' placeholder={this.props.content.search.searchPlaceholder} onChange={this.onChange} value={this.state.value} />
                                        <button type='submit'>
                                            <img width="20px" src="/images/search_big_inactive.svg" />
                                        </button>
                                    </div>
                                </form>
                                <div className="medium-12 large-12 cell">
                                    <div Style="text-align:center" className={this.state.isError ? 'errors' : 'success'}>{this.state.message}</div>
                                    <div Style="text-align:center">
                                        {(this.state.isLoading) ?
                                            <img src={path + `images/ajax-loader.gif`} alt="" />
                                            : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid-container">
                        <div className="grid-x bottom-space" style={{ 'paddingTop': '40px' }}>
                            <div className="medium-8 cell">
                                <SearchResults
                                    getSelectedData={this.getSelectedData}
                                    //isLoading={this.isLoading}
                                />
                            </div>
                            <div className="medium-4 cell existing-value-story">
                                <div className="grid-x grey_bb search_titles">
                                    <div className="medium-12 large-12 cell">
                                        <div className="float-left">{this.props.content.search.valueStoryHeading}</div>
                                        <div className="float-right">{this.state.valueStoriesObj.length}</div>
                                    </div>
                                </div>
                                <ScrollArea minScrollSize={40}
                                    speed={0.8}
                                    className="search-result-cont medium-12 large-12"
                                    contentClassName="value-story-content"
                                    horizontal={false}
                                >
                                    <ValueStoryList
                                        valueStories={this.state.valueStoriesObj}
                                        EmailProgress={this.EmailProgress}
                                        isLoading={this.isLoading}
                                    />
                                </ScrollArea>
                            </div>
                        </div>
                        <div className="grid-x float-right pb30">
                            <Link to={{
                                pathname: routes.discovery
                            }}>
                                <Button type="submit" onClick={this.companyProfileData} content={this.props.content.start.button} />
                            </Link>

                        </div>
                    </div>
                </React.Fragment>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        content: state.content,
        result: state.searchText,
        data: state.searchResultData
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchValueStories: (id) => dispatch(fetchValueStories(id)),
        fetchValueStoriesLoading: (bool) => dispatch(fetchValueStoriesLoading(bool)),
        fetchValueStoriesSuccess: (valueStories) => dispatch(fetchValueStoriesSuccess(valueStories)),
        fetchValueStoriesFailure: (error) => dispatch(fetchValueStoriesFailure(error)),
        fetchCrmResults: (value) => dispatch(fetchCrmResults(value)),
        crmSearchLoading: (bool) => dispatch(crmSearchLoading(bool)),
        crmSearchSuccess: (msg) => dispatch(crmSearchSuccess(msg)),
        crmSearchFailure: (error) => dispatch(crmSearchFailure(error)),
        searchResult: (result) => dispatch(searchResult(result)),
        searchText: (text) => dispatch(searchText(text)),
        searchData: (data) => dispatch(searchData(data)),
        fetchCapIqSearchResults: (value, type) => dispatch(fetchCapIqSearchResults(value, type)),
        capIqSearchSuccess: (msg) => dispatch(capIqSearchSuccess(msg)),
        capIqSearchFailure: (error) => dispatch(capIqSearchFailure(error)),
        companyProfileData: (data) => dispatch(companyProfileData(data)),
        isNewValueStory: (data) => dispatch(isNewValueStory(data)),
        valueStoryFetchDataSuccess: (valueStory) => dispatch(valueStoryFetchDataSuccess(valueStory))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchContainer)
