
import axios from 'axios'
import {
  ROOT_URL
}  from 'Constants'

 
export function updateTableData_AOI(allDetail) {
  return (dispatch) => {
    
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: `${ROOT_URL}/LangugaeTranslation/UpdateTableData_AOI`,
      data: { "LanguageShortName": allDetail.LanguageShortName, "CurrentValueJson": allDetail.CurrentValueJson, "UpdatedValueJson": allDetail.UpdatedValueJson },
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => { 
      return response
    })
  }
}


export function UpdateTableData_UserInputs(allDetail) {
  return (dispatch) => { 
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: `${ROOT_URL}/LangugaeTranslation/UpdateTableData_UserInputs`,
      data: { "LanguageShortName": allDetail.LanguageShortName, "CurrentValueJson": allDetail.CurrentValueJson, "UpdatedValueJson": allDetail.UpdatedValueJson },
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => { 
      return response
    })
  }
}

export function UpdateTableData_Industries(allDetail) {
  return (dispatch) => { 
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: `${ROOT_URL}/LangugaeTranslation/UpdateTableData_Industries`,
      data: { "LanguageShortName": allDetail.LanguageShortName, "CurrentValueJson": allDetail.CurrentValueJson, "UpdatedValueJson": allDetail.UpdatedValueJson },
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => { 
      return response
    })
  }
}

export function UpdatePublishDBChange_AOIValueDrivers(itemAllDetail) {
  let token = localStorage.getItem('jwtToken')
  return (dispatch) => {
    return axios({
      method: 'post',
      url: `${ROOT_URL}/LangugaeTranslation/UpdateTableData_AOIValueDrivers_Item`,
      data: { "LanguageShortName": itemAllDetail.LanguageShortName, "CurrentValueJson": itemAllDetail.CurrentValueJson, "UpdatedValueJson": itemAllDetail.UpdatedValueJson },
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      return response;
    })
  };
}

export function UpdatePublishDBChange_AOIValueDrivers_subItem(subItemAllDetail) {
  let token = localStorage.getItem('jwtToken')
  return (dispatch) => {
    return axios({
      method: 'post',
      url: `${ROOT_URL}/LangugaeTranslation/UpdateTableData_AOIValueDrivers_SubItem`,
      data: { "LanguageShortName": subItemAllDetail.LanguageShortName, "CurrentValueJson": subItemAllDetail.CurrentValueJson, "UpdatedValueJson": subItemAllDetail.UpdatedValueJson },
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      return response
    });
  };

}

export function UpdateTableData_valueDrivers(valueDriversAllDetail) {
  let token = localStorage.getItem('jwtToken')
  return (dispatch) => {
    return axios({
      method: 'post',
      url: `${ROOT_URL}/LangugaeTranslation/UpdateTableData_ValueDrivers`,
      data: { "LanguageShortName": valueDriversAllDetail.LanguageShortName, "CurrentValueJson": valueDriversAllDetail.CurrentValueJson, "UpdatedValueJson": valueDriversAllDetail.UpdatedValueJson },
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      return response
    });
  };

}

export function UpdateTableData_SubvalueDrivers(valueDriversAllDetail) {
  let token = localStorage.getItem('jwtToken')
  return (dispatch) => {
    return axios({
      method: 'post',
      url: `${ROOT_URL}/LangugaeTranslation/UpdateTableData_SubValueDrivers`,
      data: { "LanguageShortName": valueDriversAllDetail.LanguageShortName, "CurrentValueJson": valueDriversAllDetail.CurrentValueJson, "UpdatedValueJson": valueDriversAllDetail.UpdatedValueJson },
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      return response
    });
  };

}

export function UpdateTableData_BVSROIs(valueDriversAllDetail) {
  let token = localStorage.getItem('jwtToken')
  return (dispatch) => {
    return axios({
      method: 'post',
      url: `${ROOT_URL}/LangugaeTranslation/UpdateTableData_BVSROIs`,
      data: { "LanguageShortName": valueDriversAllDetail.LanguageShortName, "CurrentValueJson": valueDriversAllDetail.CurrentValueJson, "UpdatedValueJson": valueDriversAllDetail.UpdatedValueJson },
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      return response
    });
  };

}

 
export function UpdateTableData_Countries(allDetail) {
  return (dispatch) => { 
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: `${ROOT_URL}/LangugaeTranslation/UpdateTableData_Countries`,
      data: { "LanguageShortName": allDetail.LanguageShortName, "CurrentValueJson": allDetail.CurrentValueJson, "UpdatedValueJson": allDetail.UpdatedValueJson },
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => { 
      return response
    })
  }
}
