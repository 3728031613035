import axios from 'axios'
import { ROOT_URL } from 'Constants'
import {
    getDetailedFileLoading,
    getDetailedFileSuccess,
    addSelectedColumnLoading,
    addSelectedColumnSuccess,
    getSelectedColumnLoading,
    getSelectedColumnSuccess,
    getDetailedFileFileRecordsInsertedLoading,
    getDetailedFileRecordsInsertedSuccess,
    getDetailedFileFileRecordsInsertedFailure
} from'./spendActions'

const getSccLoadDataAPI = `${ROOT_URL}/SCC/GetSCCLoadData`
const addSccSelectedColumnAPI = `${ROOT_URL}/SCC/AddSCCColumnsForRequest`
const getSccSelectedColumnAPI = `${ROOT_URL}/SCC/GetSCCColumnsForRequest`
const getSCCMasterColumnsConfigurationAPI = `${ROOT_URL}/SCC/GetSCCMasterColumnsConfiguration`
const uploadSccTabDataAPI = `${ROOT_URL}/SCC/updateSCCTabData`

export function getSccLoadData(DMCId, VSId, userEmail) {
    return (dispatch) => {
      dispatch(getDetailedFileLoading(true))
      let token = localStorage.getItem('jwtToken')
      return axios({
        method: 'post',
        data: { DMCId: DMCId, VSId: VSId, Email: userEmail },
        url: getSccLoadDataAPI,
        headers: {
          'Authorization': `AribaBearer ${token}`
        }
      }).then((response) => {
        dispatch(getDetailedFileSuccess(false))
        return response
      }).catch((error) => {
        return error
      })
    }
  }
  
  export function addSelectedColumnForScc(DMCId, VSId, USER_ID, COLUMNSOrFAVId) {
    return (dispatch) => {
      dispatch(addSelectedColumnLoading(true))
      let token = localStorage.getItem('jwtToken')
      let payload = {
        DmcId: DMCId, VsId: VSId, UserId: USER_ID
      };
      payload["Columns"] = COLUMNSOrFAVId;
      return axios({
        method: 'post',
        data: payload,
        url: addSccSelectedColumnAPI,
        headers: {
          'Authorization': `AribaBearer ${token}`
        }
      }).then((response) => {
        dispatch(addSelectedColumnSuccess(false))
        return response
      }).catch((error) => {
        return error
      })
    }
  }
  
  export function getSccSelectedColumn(DMCId, VSId, USER_ID) {
    return (dispatch) => {
      dispatch(getSelectedColumnLoading(true))
      let token = localStorage.getItem('jwtToken')
      return axios({
        method: 'post',
        data: { DmcId: DMCId, VsId: VSId, UserId: USER_ID },
        url: getSccSelectedColumnAPI,
        headers: {
          'Authorization': `AribaBearer ${token}`
        }
      }).then((response) => {
        dispatch(getSelectedColumnSuccess(false))
        return response
      }).catch((error) => {
        return error
      })
    }
  }
  
  export function getSCCMasterColumnsConfiguration() {
    return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
      return axios({
        method: 'post',
        url: getSCCMasterColumnsConfigurationAPI,
        headers: {
          'Authorization': `AribaBearer ${token}`
        }
      })
    }
  }
  
  export function uploadSccTabData(records) {
    return (dispatch) => {
      dispatch(getDetailedFileFileRecordsInsertedLoading(true))
      let token = localStorage.getItem('jwtToken')
      return axios({
        method: 'post',
        data: records,
        url: uploadSccTabDataAPI,
        headers: {
          'Authorization': `AribaBearer ${token}`
        }
      }).then((response) => {
        dispatch(getDetailedFileFileRecordsInsertedLoading(false))
        dispatch(getDetailedFileRecordsInsertedSuccess(true))
        return response
      }).catch((error) => {
        dispatch(getDetailedFileFileRecordsInsertedFailure(error))
        return error
      })
    }
  }
  