import React, { useState } from 'react';
import classNames from "classnames";
import { stringContainsSearchTerms } from 'utils/Utils'

class TextFilterRenderer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      filterTerm: '',
      rawObj: []
    }

    this.handleChange = this.handleChange.bind(this)
    this.clear = this.clear.bind(this)
    this.filterValues = this.filterValues.bind(this)
  }

  clear() {
    this.setState({filterTerm: ''})
  }

  handleChange(event) {
    const { value } = event.target;
    this.setState({filterTerm: value});
    if (this.props.onChange) {
      this.props.onChange({ 
        filterTerm: value, 
        rawValue: value, 
        column: this.props.column, 
        clear: this.clear, 
        filterValues: this.filterValues, 
        type: TextFilterRenderer});
    }
  }
  
  filterValues(row, columnFilter, columnKey) {
    let include = true;
    if (columnFilter === null) {
      include = false;
    } else {
        var term = columnFilter.filterTerm;
        if(columnFilter.filterTerm === '!') {
          include = row[columnKey] !== '0' && row[columnKey] !== ''
        }
        else if(columnFilter.filterTerm === '!!') {
          include = row[columnKey] === '' || row[columnKey] === undefined || row[columnKey] === null
      }
        else if(columnFilter.filterTerm.startsWith('!')) {
          term = term.substring(1)
          include = !stringContainsSearchTerms(row[columnKey], term);
      } else {
          include = stringContainsSearchTerms(row[columnKey], term);
        }
      }

    return include;
  }

  render() {
    return ( this.props.column.filterable? 
      <div className="form-group">
        <input
          key={`header-filter-${this.props.column.key}`}
          className={classNames('form-control', 'input-sm', 'header-input-'+this.props.column.key)}
          placeholder="Search"
          value={this.state.filterTerm}
          onChange={this.handleChange}
        />
      </div> : <div />
    );
  }
}

export default TextFilterRenderer