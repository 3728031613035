import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

// Utils
import classnames from 'classnames'
import { path } from 'Constants'

const ValueMenuItemsExpanded = (props) => {
	var showMenuItem = props.user !== undefined &&
		props.user.applicationFeatures !== '' && props.user.applicationFeatures !== undefined ?
		props.user.applicationFeatures.filter(a => a.Action === props.content.link) : []
	if (showMenuItem !== null && showMenuItem.length > 0) {
		return (
<div onMouseEnter={(e)=> props.handleMouseEnter(e)} onMouseLeave={props.handleMouseLeave}>
			<Link id= { props.content.name }
				className={classnames({ 'value_menu__item--readonly': !props.enabled })}
				to={{
					pathname: props.content.link
				}}>
				
				<button 
					className={classnames('value_menu__item', 'value_menu__item--expanded', (props.menuSelected === props.content.name || props.content.name === props.hoverLink) ? 'value_menu__item--enabled' : null)}
					onClick={(e) => props.handleSelectedMenuItem(e, props.content.name) } 
				>
					
					<div className="grid-x" >
						<div className="medium-3 cell">
							<img id= { props.content.name }
								className={classnames('value_menu__logo', 'value_menu__logo--disabled', (props.menuSelected === props.content.name || props.content.name === props.hoverLink) ? null : 'value_menu__item--disabled')}
								src={path + props.content.icon} alt={props.content.name} />
						</div>
						<div className="medium-9 cell value_menu__item__name" >
							{props.content.name}
						</div>
					</div >
					<div className="grid-x" id= { props.content.name }>
						<div className="medium-offset-3 medium-9 cell value_menu__item__caption">
							{props.content.caption}
						</div>
					</div>
					
				</button>				
			</Link>
			</div>
		)
	}
	else {
		return null;
	}
}

export default ValueMenuItemsExpanded

ValueMenuItemsExpanded.defaultProps = {
	accountMenuExpanded: false,
	adminMenuExpanded: false,
	languageMenuExpanded: false
}

ValueMenuItemsExpanded.propTypes = {
	accountMenuExpanded: PropTypes.bool.isRequired,
	handleMenuAction: PropTypes.func.isRequired,
	adminMenuExpanded: PropTypes.func.isRequired,
	languageMenuExpanded: PropTypes.func.isRequired
}
