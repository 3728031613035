import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Form } from 'react-redux-form'
import classNames from "classnames";
import { path } from 'Constants'
import moment from 'moment'
import { ControlTextE } from 'UI/CustomComponents/Controls';
import Select from 'react-select';
// Actions
import {
    fetchEnablementPriorityAdmin,
    resetError,
    UpdateEnablementPriorityAdminValues,
    fetchSolutionInScopeValues
} from 'actions/spendActions'

// Utils
import { BreakLine} from 'utils/Utils'

class EnablementPriority extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            enablementParameter: [],
            editedEnablementParameter: [],
            indirectSolutionScopeOption :[],
            selectedIndirectScope : '',
            responseMessage: '',
            isError: false, 
            warning1Msg: '', 
            warning2Msg: ''        
        }
        this.loadData = this.loadData.bind(this)
        this.trackScheduleFactorsChanges = this.trackScheduleFactorsChanges.bind(this)
        this.onEnablementParameterSave = this.onEnablementParameterSave.bind(this)
        this.handleSolutionInScopeChange = this.handleSolutionInScopeChange.bind(this)
        this.setWarning1Msg = this.setWarning1Msg.bind(this)
        this.setWarning2Msg = this.setWarning2Msg.bind(this)              
    }

    componentDidMount() {
        this.loadData();
    }

    handleSolutionInScopeChange(value)
    {
        this.setState({  selectedIndirectScope :  value },()=>{this.getAllEnablementPriority(value)});
    }

    loadData() {
        var indirectScopeValues=[]; 
        // indirectScopeValues.push({ label: 'SCC', value: 'SCC' });		
        // indirectScopeValues.push({ label: 'Non SCC', value: 'Non SCC' });	
        // this.setState({ indirectSolutionScopeOption: indirectScopeValues, selectedIndirectScope : 'SCC'});

        // this.getAllEnablementPriority('SCC');

        this.props.fetchSolutionInScopeValues().then((response) => {
            if (response.status === 200) {
                //this.getAllParameters(response.data.Result[0].SOLUTION_IN_SCOPE_NAME);
                this.getAllEnablementPriority(response.data.Result[0].SOLUTION_IN_SCOPE_NAME);
                var indirectScopeValues=[];                				

                if (response.data.Result !== null) {
                    response.data.Result.forEach(item => {                       
                            indirectScopeValues.push({ label: item.SOLUTION_IN_SCOPE_NAME, value: item.SOLUTION_IN_SCOPE_NAME });							
                    });
                }   								

                this.setState({ indirectSolutionScopeOption: indirectScopeValues, selectedIndirectScope : response.data.Result[0].SOLUTION_IN_SCOPE_NAME});
            }
        });
        
    }

    trackScheduleFactorsChanges(record) {
        var updatedRecords = this.state.editedEnablementParameter
        if (this.state.editedEnablementParameter.length === 0)
            updatedRecords.push(record)
        else {
            let index = this.state.editedEnablementParameter.findIndex(x => x.ENABLE_WV_FIELD_PRIORITY_ID === record.ENABLE_WV_FIELD_PRIORITY_ID);
            if (index !== undefined && index !== null && index > -1)
                updatedRecords.splice(index, 1)
            updatedRecords.push(record)
        }
        this.setState({ responseMessage: '', editedEnablementParameter: updatedRecords, isError: false, warning1Msg: '', warning2Msg: '' })
    }
    setWarning1Msg(msg)
    {
        this.setState({warning1Msg: msg });
    }

    setWarning2Msg(msg)
    {
        this.setState({warning2Msg: msg });
    }

    getAllEnablementPriority(solutionInScope) {
        this.setState({ responseMessage: '', isLoading: true, isError: false });
        this.props.fetchEnablementPriorityAdmin(solutionInScope).then((response) => {
            if (response.status === 200) {
                if (response.data.Result.length === 0) {
                    this.setState({ enablementParameter: [] , responseMessage: this.props.content.noData, isLoading: false, isError: true , warning1Msg: '', warning2Msg: '' });
                }
                else
                    this.setState({ enablementParameter: response.data.Result, isLoading: false, responseMessage: '', isError: false, warning1Msg: '', warning2Msg: '' });
            }
            else {
                this.setState({ responseMessage: this.props.content.errorMessage, isLoading: false, isError: true, warning1Msg: '', warning2Msg: '' });
            }
        }).catch((error) => {
            this.setState({ responseMessage: this.props.content.errorMessage, isLoading: false, isError: true , warning1Msg: '', warning2Msg: '' });
        })
    }

    onEnablementParameterSave() {
        if (this.state.editedEnablementParameter.find(a => a.QUARTER_VALUE === '') === undefined ) {
            if (this.state.editedEnablementParameter.find(a => a.QUARTER_VALUE === '-') === undefined ) {
                this.setState({ isLoading: true, responseMessage: '', isError: false });
                let updatedRecords = this.state.editedEnablementParameter
                let email = this.props.user.user.Email
                let solutionInScope = this.state.selectedIndirectScope           
                let finalObject = [];
                updatedRecords.map(function (data) {
                    let record = {
                        'ENABLE_WV_FIELD_PRIORITY_ID': data.ENABLE_WV_FIELD_PRIORITY_ID,
                        'PRIORITY_NUM': data.PRIORITY_NUM,                        
                        'USER_ID': email,
                        'SolutionInScope': solutionInScope ,
                        'VISIBLE_FLAG': data.VISIBLE_FLAG        
                    }
                    finalObject.push(record)
                });

                this.props.UpdateEnablementPriorityAdminValues(finalObject).then((response) => {
                    if (response.status === 200) {
                        this.setState({ enablementParameter: response.data.Result, editedEnablementParameter: [], isLoading: false, responseMessage: this.props.content.successMessage, isError: false , warning1Msg: '', warning2Msg: '' });
                    }
                    else {
                        this.setState({ isLoading: false, responseMessage: this.props.content.errorMessage, isError: true , warning1Msg: '', warning2Msg: '' });
                    }
                }).catch((error) => {
                    this.setState({ isLoading: false, responseMessage: this.props.content.errorMessage, isError: true , warning1Msg: '', warning2Msg: '' });
                })
            }
            else {
                this.setState({ responseMessage: this.props.content.validNumber, isError: true , warning1Msg: '', warning2Msg: '' });
            }
        }
        else {
            this.setState({ responseMessage: this.props.content.blankValue, isError: true , warning1Msg: '', warning2Msg: '' });
        }
    }

    componentWillUnmount() {
        this.props.resetError()
    }

    render() {
        var _instance = this
        var enablement_Parameter = this.state.enablementParameter.map(function (data, index) {
            return (<TableRows
                enablementParameter={data}
                index={index}
                allRows={_instance.state.enablementParameter}
                readOnly={_instance.props.readOnly}
                trackScheduleFactorsChanges={_instance.trackScheduleFactorsChanges}
                wizard={_instance.props.wizard}
                setWarning1Msg={_instance.setWarning1Msg}
                setWarning2Msg={_instance.setWarning2Msg}
            />
            );
        });

        return (
            <div className="grid-x" style={{ width: '100%'}}>
                <div className="medium-12">
                    {(this.state.isLoading) ?
                        <img style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
                            src={path + `images/ajax-loader.gif`} alt="" /> : null}
                </div>
                <div className="medium-12">
                    <div className="grid-x">
                        <div className="medium-3 float-left" style={{ fontSize: '1.2rem', paddingBottom: '8px' }}>{BreakLine(this.props.content.enablementPriorityTitle)}</div>
                        <div className="medium-6" style={{ display: 'flex', justifyContent: 'center' }} >
                            {this.state.isError ?
                                <div style={{ margin: '0 0 0.5rem', color: 'red' }}>{this.state.responseMessage}</div> :
                                <div style={{ margin: '0 0 0.5rem', color: '#4fb81c' }}>{this.state.responseMessage}</div>
                            }
                            {this.state.warning1Msg !== '' && this.state.warning1Msg !== undefined ?
                                <div className="grid-x medium-12" style={{ display: 'flex', justifyContent: 'center', borderTop: '0px' }}>
                                    <div style={{ margin: '0 0 0.5rem', color: 'red' }}>{this.state.warning1Msg}</div>
                                </div>
                                : null}

                            {this.state.warning2Msg !== '' && this.state.warning2Msg !== undefined ?
                                <div className="grid-x medium-12" style={{ display: 'flex', justifyContent: 'center', borderTop: '0px' }}>
                                    <div style={{ margin: '0 0 0.5rem', color: 'red' }}>{this.state.warning2Msg}</div>
                                </div>
                                : null}
                        </div>
                        <div className="medium-3"></div>
                    </div>
                    <div className="grid-x">
                        <div className="medium-11 float-left pb10">
                        {this.props.content.enablementPriorityDesc}
                        </div>
                        {this.props.user.userprofile.Roles[0].indexOf("Admin") > -1 ?
                            <div className="medium-1 pb5">
                                <button className={classNames('float-right',
                                    {
                                        'hand-cursor': this.state.isLoading ? false : (this.state.editedEnablementParameter.length > 0),
                                        'gray-scale': this.state.isLoading ? true : (this.state.editedEnablementParameter.length === 0)
                                    })}
                                    onClick={this.onEnablementParameterSave} disabled={this.state.isLoading ? true : this.state.editedEnablementParameter.length === 0} type="submit" float >
                                    <img width="25" title={this.props.content.save} src="images/save_active.svg" />
                                </button>
                            </div> : null}
                    </div>
                </div>

                <table style={{border : 'solid',borderWidth : '1px'}}><tr>
                    <td><div>Solution in Scope : </div>
                    </td>
                    <td><div>   <Select
                        className="border-less-DD"
                        model="discovery.IndirectScope"
                        id="discovery.IndirectScope"
                        name="discovery.IndirectScope"
                        placeholder={'Solution In Scope'}
                        value={this.state.indirectSolutionScopeOption.filter(option => option.label === this.state.selectedIndirectScope)}
                        noOptionsMessage={() => null}
                        onChange={(value) => this.handleSolutionInScopeChange(value.label)}
                        options={this.state.indirectSolutionScopeOption}
                        isSearchable={false}
                        disabled={false}
                    />
                    </div></td></tr></table>

                {this.state.enablementParameter.length > 0 ?
                    <div className="medium-12">
                        <Form model="netwrokEnablementMatchTypeParameter">
                            <div className="basic-table">
                                <table>
                                    <thead>
                                        <tr className="eachRow">
                                            {/* <th style={{width:'0px'}}></th>                                         */}
                                            <th>{this.props.content.firstColumnName}</th>
                                            <th>{this.props.content.visibilityFlag}</th>
                                            <th>{this.props.content.secondColumnName}</th>
                                            <th>{this.props.content.updatedBy}</th>
                                            <th>{this.props.content.updatedDate}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {enablement_Parameter}
                                    </tbody>
                                </table>
                            </div>
                        </Form>
                    </div>
                    : null
                }
            </div>
        )
    }
}

class TableRows extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    onChangeEvent(val) {
        if (val.target.value === '' || val.target.value.match(/^[0-9]+$/) || !isNaN(val.target.value)) {
            var editingRow = this.props.enablementParameter;
            let editedValue = val.target.value.trim()
            if (editedValue.toString() !== editingRow[val.target.id].toString()) {
                editingRow[val.target.id] = editedValue
                this.props.trackScheduleFactorsChanges(editingRow)
            }
        }
    }

    onChangeVisibleFlagEvent(val) {
        var editingRow = this.props.enablementParameter;
        let editedValue = val.target.value.trim()
        if (editedValue.toString() !== editingRow[val.target.id].toString()) {
            editingRow[val.target.id] = editedValue
            this.props.trackScheduleFactorsChanges(editingRow)
        }
    }

    onAutoWaveChangeEvent(val, item) {
        //this.setState({ pendingMsg: this.props.wizard.pendingPriorityChanges, isSucess: false })//, isError: false })
        this.setState({ isError: false })
        var key = val.target.innerText
        var value = Number(val.target.value)
        let updatedRecords = this.state.editedItemPriority

        if (/^(\-(\d*))$/.test(value) || !isNaN(value) && (value <= 8) && Number.isInteger(value)) {
            var editingRow = this.props.enablementParameter;           

            if (val.target.id === 'PRIORITY_NUM' && value.toString() !== editingRow[val.target.id].toString()) {
                //editingRow[val.target.id] = value                

                var dict = this.state.dictState
                editingRow[val.target.id] = value
                this.props.trackScheduleFactorsChanges(editingRow)

                var count = 0;
                var uniqueCount = 1;
                //User will only be able to put in a number on 1 of the first 3 priorities in the Auto Wave category

                this.props.allRows.map(record => {

                    if (record.FIELD_VALUE === "L4_INDUSTRY_GICS_ID" && record.PRIORITY_NUM > 0) {
                        count = count + 1;
                    } else if (record.FIELD_VALUE === "ERP_COMMODITY_CODE1" && record.PRIORITY_NUM > 0) {
                        count = count + 1;
                    } else if (record.FIELD_VALUE === "ERP_COMMODITY_CODE2" && record.PRIORITY_NUM > 0) {
                        count = count + 1;
                    }

                    if (record.PRIORITY_NUM > 0) {
                        var recordCnt = this.props.allRows.filter(rec => rec.PRIORITY_NUM === record.PRIORITY_NUM)
                        if (recordCnt.length > 1) {
                            uniqueCount = uniqueCount + 1;
                        }
                    }

                })

                if (count > 1) {
                    //this.setState({ warning1Msg: this.props.wizard.allowedToSetOnlyOnePriority })
                    this.props.setWarning1Msg(this.props.wizard.allowedToSetOnlyOnePriority)
                } else {
                    //this.setState({ warning1Msg: '' })
                    this.props.setWarning1Msg('')
                }

                if (uniqueCount > 1) {
                    //this.setState({ warning2Msg: this.props.wizard.uniquePriority })
                    this.props.setWarning2Msg(this.props.wizard.uniquePriority)
                } else {
                    //this.setState({ warning2Msg: '' })
                    this.props.setWarning2Msg('')
                }


                // // code for unique priority value

                // if (this.state.editedItemPriority.length === 0 ||
                //     this.state.editedItemPriority.find(a => a.FIELD_VALUE === editingRow.FIELD_VALUE) === undefined ||
                //     this.state.editedItemPriority.find(a => a.PRIORITY_NUM === value) === undefined) {
                //     editingRow.FIELD_NAME = "AUTO_WAVE_HEADER"
                //     editingRow.FIELD_VALUE = item.FIELD_VALUE
                //     updatedRecords.push(editingRow)
                //     this.setState({ //responseMessage: this.props.wizard.pendingPriorityChanges, 
                //         editedItemPriority: updatedRecords,
                //         pendingAutoWaveChanges: updatedRecords.find(d => d.FIELD_NAME === "AUTO_WAVE_HEADER"),
                //         isError: false, isWarning: false
                //     })

                //     this.props.pendingChanges(true)
                // }
            }
        } else {
            val.target.value = 0

            const index = updatedRecords.indexOf(item);
            if (index > -1) {
                updatedRecords.splice(index, 1);
            }
            this.setState({ editedItemPriority: updatedRecords })
        }
        //passing edited record to container for save
        //this.props.handleStrategyWizardChanges(updatedRecords)
    }

    render() {
        var bindValues = this.props.enablementParameter;
        return (
            <tr className="eachRow">
                {/* <td style={{width:'0px'}} model="enablementParameter.ENABLE_WV_FIELD_PRIORITY_ID">{bindValues.ENABLE_WV_FIELD_PRIORITY_ID}</td>                 */}
                <td model="enablementParameter.FIELD_DESC">{bindValues.FIELD_DESC}</td>
                <td>
                    <select style={{ height: '30px', width: '100px' }}
                        id={'VISIBLE_FLAG'}
                        onChange={this.onChangeVisibleFlagEvent.bind(this)}
                        value={bindValues.VISIBLE_FLAG}
                        readOnly={this.props.readOnly}
                        disabled={this.props.readOnly}
                    >
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>
                </td>
                <td>
                    <ControlTextE
                        model="enablementParameter.PRIORITY_NUM"
                        //onChange={this.onChangeEvent.bind(this)}
                        onChange={(e) => this.onAutoWaveChangeEvent(e, bindValues)}
                        id={'PRIORITY_NUM'}
                        defaultValue={bindValues.PRIORITY_NUM}
                        value={bindValues.PRIORITY_NUM}
                        type='name'
                        className="edit-textarea edit-width"
                        readOnly={this.props.readOnly}
                    />
                </td>

                <td model="enablementParameter.USER_NAME">{bindValues.USER_NAME}</td>
                <td model="enablementParameter.UPDATED_DTM">{moment(bindValues.UPDATED_DTM).format('L')}</td>
            </tr>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        language: state.language,
        ui: state.content.ui,
        user: state.default,
        spendDetails: state.spendDetails,
        //content: state.content.spendAnalysis.enablementResourceParamaeters,
        content: state.content.menu.EnablementParametersManager[0].EnablementPriorityTab,
        wizard: state.content.spendAnalysis.prioritizationWizard 
    }
}


const mapDispatchToProps = (dispatch) => {
    return {        
        resetError: () => dispatch(resetError()),
        fetchEnablementPriorityAdmin: (solutionInScope) => dispatch(fetchEnablementPriorityAdmin(solutionInScope)),      
        UpdateEnablementPriorityAdminValues: (data) => dispatch(UpdateEnablementPriorityAdminValues(data)),
        fetchSolutionInScopeValues: () => dispatch(fetchSolutionInScopeValues()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EnablementPriority)


