import React from 'react'

// UI
import MdTabletMac from 'react-icons/lib/md/tablet-mac'

const Orientation = (props) =>
	<div className="tablet-orientation">
		<MdTabletMac className="tablet-orientation__icon" transform='rotate(-90)' />
		{props.ui.orientation}
	</div>

export default Orientation
