import React from 'react'
import { connect } from 'react-redux'
import {
  Route,
  NavLink,
  MemoryRouter
} from "react-router-dom";
import MatchAndSegmentation from "./MatchAndSegmentation";
import VisibilityAndDefault from "./VisibilityAndDefault";
import EnablementSELogicAndAnalysis from "./EnablementSELogicAndAnalysis";
import WaveScoring from "./WaveScoring"
import IndustryPrioratization from "./IndustryPrioratization"
import RampSchedule from "./RampSchedule"
import GoLiveVsKickOffWeek from "./GoLiveVsKickOffWeek"
import MarketPlaceEligible from "./MarketPlaceEligible"
import QualityAssessment from './QualityAssessment'
import WaveYields from './WaveYields';
// import SpendQualityAssessment from './SpendQualityAssessment'

class EnablementParameters extends React.Component {

  render() {
    return (
      <MemoryRouter>
        <div className="popup-content">
          <div className="title">{this.props.content.popupTitle}</div>
          <ul className="headers heading mt20">
            <li><NavLink exact to="/">{this.props.content.seLogicAndAnalysisTabName}</NavLink></li>
            <li><NavLink to="/MatchAndSegmentation">{this.props.content.matchAndSegmentationTabName}</NavLink></li>
            <li><NavLink to="/VisibilityAndDefault">{this.props.content.VisibilityAndDefaultTabName}</NavLink></li>
            <li><NavLink to="/WaveScoring">{this.props.content.waveScoring}</NavLink></li>
            <li><NavLink to="/WaveYields">{this.props.content.waveYields}</NavLink></li>
            <li><NavLink to="/IndustryPrioratization">{this.props.content.industryPrioratization}</NavLink></li>
            <li><NavLink to="/RampSchedule">{this.props.content.kpi}</NavLink></li>
            <li><NavLink to="/MarketPlaceEligible">{"Marketplace Eligible"}</NavLink></li>
            <li><NavLink to="/QualityAssessment">{"Enriched Spend Quality Assessment"}</NavLink></li>
            {/* <li><NavLink to="/SpendQualityAssessment">{"Spend Quality Assessment"}</NavLink></li> */}
          </ul>
          <div className="description">
            <Route exact path="/" component={EnablementSELogicAndAnalysis} />
            <Route path="/MatchAndSegmentation" component={MatchAndSegmentation} />
            <Route path="/VisibilityAndDefault" component={VisibilityAndDefault} />
            <Route path="/WaveScoring" component={WaveScoring} />
            <Route path="/WaveYields" component={WaveYields} />
            <Route path="/IndustryPrioratization" component={IndustryPrioratization} />
            <Route path="/RampSchedule" component={RampSchedule} />
            <Route path="/MarketPlaceEligible" component={MarketPlaceEligible} />
            <Route path="/QualityAssessment" component={QualityAssessment} />
            {/* <Route path="/SpendQualityAssessment" component={SpendQualityAssessment} /> */}
          </div>
        </div>
      </MemoryRouter>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
    content: state.content.menu.EnablementParametersManager[0]
  }
}

export default connect(mapStateToProps)(EnablementParameters)
