import React, { Component } from 'react'
import { connect } from 'react-redux'
import PopupButton from 'UI/PopupButton'
import Button from 'UI/Button'
import Popup from "reactjs-popup";
import Select from 'react-select'
import { Checkbox } from 'material-ui';
import {
    getYourFileSuccess,
    getYourFileLoading,
    resetError,
    getYourFileFailure,
    getYourFilesDetails,
    currentActiveRequest,
    UpdateRequestParameter,
    solutionScopeChanged,
    pendingChanges,
    fetchVisibleSolutionInScopeValues,
    isSpendParameterPage   ,
    saveSpendParametersOnRedirect,
    FetchUpdateFieldVisibility
} from 'actions/spendActions'
import Modal from 'reboron/OutlineModal'

import { CheckboxE, PopupButtonE } from 'UI/CustomComponents/Controls';
import { formatThousands, formatThousandsForStrings } from 'utils/Utils'
import { changeModalStyle, changeModalContentStyle, backdropStyle,path } from 'Constants'

const changeBackdropStyle = Object.assign({}, backdropStyle);
changeBackdropStyle.zIndex = '1050';

const alertModalStyle = Object.assign({}, changeModalStyle);
alertModalStyle.zIndex = '1060';
alertModalStyle.width = '33%';

const alertModalStyleSpendParameter = Object.assign({}, changeModalStyle);
alertModalStyleSpendParameter.zIndex = '1060';
alertModalStyleSpendParameter.width = '80%';


class AccountParametersContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            disableCatalogInScope : false,
            RequestIds: [],
            fileDetails: [],
            originalState: '',
            open: false,
            isActive: false,
            waveRecalcFlag : false,
            respnseMessage: '',
            isError: false,
            fieldVisibilityData:[],
            test: JSON.stringify(this.props.spendDetails.currentActiveRequest),
            cardPaymentOption: [{ label: 'Yes', value: 'Yes' },
            { label: 'No', value: 'No' }],
            selectedSolutionScope: '',
            solutionScopeOption: [],
            isLoading : false,
            indirectSolutionScopeAll: [],
            //     { label: "Buying", value: "Buying" },
            //     { label: "Buying and Invoicing", value: "Buying and Invoicing" },
            //     { label: "Commerce Automation", value: "Commerce Automation" },
            //     { label: "SCC", value: "SCC" },
            //     { label: "Supplier Choice", value: "Supplier Choice" },
            //     { label: "SNAP", value: "SNAP" },
            //     { label: "DSN", value: "DSN" },
            //     { label: "RISE", value: "RISE" },                
            // ],
            solutionInScopeFlag: false,
            modalDesc: 'Do you want to apply Solution in Scope changes to the Buyer Supplier Enablement Recommendations and Enablement Waves?'
        }
        this.handleParameterCheckChanged = this.handleParameterCheckChanged.bind(this)
        this.handleSolutionScopeChange = this.handleSolutionScopeChange.bind(this)
        this.onChange = this.onChange.bind(this)
        this.handleSaveParameter = this.handleSaveParameter.bind(this);
        this.showPopupBeforeSave = this.showPopupBeforeSave.bind(this);        
        this.showModal = this.showModal.bind(this)
        this.hideModal = this.hideModal.bind(this)
        //this.closePopUp = this.closePopUp.bind(this)
        this.newVariable = 0
    }

    // showModal() {
    //     this.refs['alert'].show()
    // }

    showModal(action) {
        this.refs[action].show()
    }

    hideModal(action) {
        this.refs[action].hide()
    }

    componentWillUnmount() {		
		setTimeout(() => {
			//this.props.pendingChanges(null);
			this.props.isSpendParameterPage(false);
		}, 1000);
	}

    componentDidMount() {
        // console.log("componentDidMount called")
        this.props.isSpendParameterPage(true);
        this.setState({
            test: JSON.stringify(this.props.spendDetails.currentActiveRequest)
        })

        /////// Load Solution in scope dropdown value ////////////////////
        this.props.fetchVisibleSolutionInScopeValues().then((response) => {
            // Fetch Admin Field Visibility
            var data=[{READ_WRITE_FLAG : "R", SCREEN_FLAG : "N", TAB_NAME: "Spend Parameters"}];    
            this.props.FetchUpdateFieldVisibility(data).then((response) => {
                if (response.status === 200) {
                    this.setState({fieldVisibilityData : response.data.Result});
                }
            });  

            if (response.status === 200) {
                if (response.data.Result.length === 0) {
                    this.setState({ responseMessage: this.props.content.noRecords, isLoading: false, isError: true });
                }
                else
                {
                    console.log("api data-----------", response.data.Result)
                    var scopeValues=[];
                    if (response.data.Result !== null) {
                        response.data.Result.filter(x=>x.SOLUTION_IN_SCOPE_VALUE == 'Yes').forEach(item => {
                            let value = Object.values(item)
                            scopeValues.push({ label: value[1], value: value[1] });
                        });
                    }                 

                    this.setState({ indirectSolutionScopeAll: response.data.Result, solutionScopeOption: scopeValues,isLoading: false, responseMessage: '', isError: false });
                }
            }
            else {
                this.setState({ responseMessage: this.props.content.errorMessage, isLoading: false, isError: true });
            }
        }).catch((error) => {
            this.setState({ responseMessage: this.props.content.errorMessage, isLoading: false, isError: true });
        })
        ///////////////////
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.saveSpendParametersAfterRedirect !== nextProps.saveSpendParametersAfterRedirect && nextProps.saveSpendParametersAfterRedirect) {
			//this.handleSaveParameter();
            this.setState({
                waveRecalcFlag: nextProps.waveRecalcAfterRedirect
            },()=>this.handleSaveParameter());
		}

        if (nextProps.spendDetails.currentActiveRequest !== undefined &&
            this.state.fileDetails !== undefined &&
            this.state.test !== undefined && this.state.test.length > 0) {
            var actualValue = JSON.parse(this.state.test);
            if (actualValue.DMC_ID !== this.state.fileDetails.DMC_ID) {
                this.setState({
                    test: JSON.stringify(nextProps.spendDetails.currentActiveRequest)
                })
            }


        }
        if (nextProps.spendDetails.currentActiveRequest !== this.props.fileDetails) {
            var disableCatalogInScope = false;
            if (nextProps.spendDetails.currentActiveRequest.SOLUTION_IN_SCOPE == "Supply Chain Collaboration") {
                disableCatalogInScope = true;
                nextProps.spendDetails.currentActiveRequest.CATEGORY_IN_SCOPE = false;
            }
            else {
                disableCatalogInScope = false;
            }

            this.setState({
                fileDetails: nextProps.spendDetails.currentActiveRequest, disableCatalogInScope: disableCatalogInScope
            })
        }
    }

    selectProductScopeAssignments(solutionInScope)
	{
		if(solutionInScope != 'Select Scope')
		{
			var data = this.state.indirectSolutionScopeAll.filter(d => d.SOLUTION_IN_SCOPE_NAME == solutionInScope);
			var disableCatalogInScope = false;
			var CATEGORY_IN_SCOPE = false; 

			// this.props.userData.TransactionEligible = (data[0].TRAN_AN_SUPP_AUTO_ELIGIBLE == null || data[0].TRAN_AN_SUPP_AUTO_ELIGIBLE == "No" || data[0].TRAN_AN_SUPP_AUTO_ELIGIBLE == false) ? "No" : "Yes";
			// this.props.userData.UnclassifiedEligible = (data[0].UNCLASS_SUPP_AUTO_ELIGIBLE == null || data[0].UNCLASS_SUPP_AUTO_ELIGIBLE == "No" || data[0].UNCLASS_SUPP_AUTO_ELIGIBLE == false) ? "No" : "Yes";
			// this.props.userData.SCCEligible = (data[0].DIRECT_YES_SUPP_SCC_ELIGIBLE == null || data[0].DIRECT_YES_SUPP_SCC_ELIGIBLE == "No" || data[0].DIRECT_YES_SUPP_SCC_ELIGIBLE == false) ? "No" : "Yes";
			// this.props.userData.Fieldglass = (data[0].FLDG_IN_SCOPE == null || data[0].FLDG_IN_SCOPE == "No" || data[0].FLDG_IN_SCOPE == false) ? "No" : "Yes";
			// this.props.userData.FieldglassEligible = (data[0].FG_IN_AUTO_ELG == null || data[0].FG_IN_AUTO_ELG == "No" || data[0].FG_IN_AUTO_ELG == false) ? "No" : "Yes";
			// this.props.userData.SuppEligible =(data[0].SUPPLIER_ELIGIBLE == null || data[0].SUPPLIER_ELIGIBLE == "No" || data[0].SUPPLIER_ELIGIBLE == false) ? "No" : "Yes";
			// this.props.userData.CatalogScope = (data[0].CATEGORY_IN_SCOPE == null || data[0].CATEGORY_IN_SCOPE == "No" || data[0].CATEGORY_IN_SCOPE == false) ? "No" : "Yes";


			if(solutionInScope == "Supply Chain Collaboration"){
				disableCatalogInScope = true;
				//this.props.userData.CatalogScope = "No"
				CATEGORY_IN_SCOPE = false;
			}
			else
			{
				disableCatalogInScope = false;
				CATEGORY_IN_SCOPE = (data[0].CATALOG_SCOPE == null || data[0].CATALOG_SCOPE == "No" || data[0].CATALOG_SCOPE == false) ? false : true
			}

            var checkBoxState = this.state.fileDetails;
            checkBoxState.TRANSACTION_ANSUPPLIERS_AUTOMATION_ELIGIBLE = (data[0].TRAN_AN_SUPP_AUTO_ELIGIBLE == null || data[0].TRAN_AN_SUPP_AUTO_ELIGIBLE == "No" || data[0].TRAN_AN_SUPP_AUTO_ELIGIBLE == false) ? false : true ;
            checkBoxState.UNCLASSIFIED_AUTOMATION_ELIGIBLE = (data[0].UNCLASS_SUPP_AUTO_ELIGIBLE == null || data[0].UNCLASS_SUPP_AUTO_ELIGIBLE == "No" || data[0].UNCLASS_SUPP_AUTO_ELIGIBLE == false) ? false : true;
            checkBoxState.WHENDIRECT_SUPPLIER_SCC_ELIGIBLE = (data[0].DIRECT_YES_SUPP_SCC_ELIGIBLE == null || data[0].DIRECT_YES_SUPP_SCC_ELIGIBLE == "No" || data[0].DIRECT_YES_SUPP_SCC_ELIGIBLE == false) ? false : true;
            checkBoxState.FIELD_GLASS_SCOPE = (data[0].FLDG_IN_SCOPE == null || data[0].FLDG_IN_SCOPE == "No" || data[0].FLDG_IN_SCOPE == false) ? false : true;
            checkBoxState.FIELD_GLASS_ELIGIBLE = (data[0].FG_IN_AUTO_ELG == null || data[0].FG_IN_AUTO_ELG == "No" || data[0].FG_IN_AUTO_ELG == false) ? false : true;
            checkBoxState.SUPPLIER_ELIGIBLE = (data[0].TRANS_SAP_BUSS_NET_SUPP_ELIGIBLE == null || data[0].TRANS_SAP_BUSS_NET_SUPP_ELIGIBLE == "No" || data[0].TRANS_SAP_BUSS_NET_SUPP_ELIGIBLE == false) ? false : true;
            checkBoxState.CATEGORY_IN_SCOPE = CATEGORY_IN_SCOPE;
            checkBoxState.OUT_SCOPE_SUSPENDED_SUPP = (data[0].OUT_SCOPE_SUSPENDED_SUPP == null || data[0].OUT_SCOPE_SUSPENDED_SUPP == "No" || data[0].OUT_SCOPE_SUSPENDED_SUPP == false) ? false : true;
            checkBoxState.DIRECT_CONNECT_WAVE = (data[0].DIRECT_CONNECT_WAVE == null || data[0].DIRECT_CONNECT_WAVE == "No" || data[0].DIRECT_CONNECT_WAVE == false) ? false : true;

			this.setState({fileDetails : checkBoxState,
				disableCatalogInScope : disableCatalogInScope
			});
		}
		else
		{
			// this.props.userData.TransactionEligible = "No";
			// this.props.userData.UnclassifiedEligible = "No";
			// this.props.userData.SCCEligible = "No";
			// this.props.userData.Fieldglass = "No";
			// this.props.userData.FieldglassEligible = "No";
			// this.props.userData.SuppEligible = "No";
			// this.props.userData.CatalogScope = "No";

            var checkBoxState = this.state.fileDetails;
            checkBoxState.TRANSACTION_ANSUPPLIERS_AUTOMATION_ELIGIBLE = false;
            checkBoxState.UNCLASSIFIED_AUTOMATION_ELIGIBLE = false;
            checkBoxState.WHENDIRECT_SUPPLIER_SCC_ELIGIBLE = false;
            checkBoxState.FIELD_GLASS_SCOPE = false;
            checkBoxState.FIELD_GLASS_ELIGIBLE = false;
            checkBoxState.SUPPLIER_ELIGIBLE = false;
            checkBoxState.CATEGORY_IN_SCOPE = false;
            checkBoxState.OUT_SCOPE_SUSPENDED_SUPP = false;
            checkBoxState.DIRECT_CONNECT_WAVE = false;

			this.setState({fileDetails : checkBoxState
			});
		}
	}


    // closePopUp(action) {
    //     if (action === "test") {
    //         var actualValue = JSON.parse(this.state.test);
    //         this.props.spendDetails.currentActiveRequest = actualValue;
    //         this.props.currentActiveRequest(actualValue);
    //         this.setState({ fileDetails: actualValue })
    //     }
    //     this.setState({ open: !this.state.open })
    //     //     }
    //     // else if (this.props.spendDetails !== undefined &&
    //     //     this.props.spendDetails.currentActiveRequest !== undefined) {
    //     //     this.setState({
    //     //         fileDetails: this.props.spendDetails.currentActiveRequest
    //     //     }, () => { this.setState({ open: !this.state.open }) })

    //     // }
    // }

    handleParameterCheckChanged(e) {

        var checkBoxState = this.state.fileDetails;


        switch (e.target.name) {
            case this.props.content.supplierEligible:
                checkBoxState.SUPPLIER_ELIGIBLE = e.target.checked;
                break;
            case this.props.content.catalogInScope:
                checkBoxState.CATEGORY_IN_SCOPE = e.target.checked;
                break;
            case this.props.content.transactionEligible:
                checkBoxState.TRANSACTION_ANSUPPLIERS_AUTOMATION_ELIGIBLE = e.target.checked;
                break;
            case this.props.content.unclassifiedEligible:
                checkBoxState.UNCLASSIFIED_AUTOMATION_ELIGIBLE = e.target.checked;
                break;
            case this.props.content.sCCEligible:
                checkBoxState.WHENDIRECT_SUPPLIER_SCC_ELIGIBLE = e.target.checked;
                break;
            case this.props.content.fieldglass:
                checkBoxState.FIELD_GLASS_SCOPE = e.target.checked;
                checkBoxState.FIELD_GLASS_ELIGIBLE = (e.target.checked === false ? false : this.state.fileDetails.FIELD_GLASS_ELIGIBLE);
                break;
            case this.props.content.fieldglassEligible:
                checkBoxState.FIELD_GLASS_ELIGIBLE = (this.state.fileDetails.FIELD_GLASS_SCOPE === false ? false : e.target.checked);
                break;
            case this.props.content.cardPaymentInScope:
                checkBoxState.CARD_PAYMENT_INSCOPE = e.target.checked;
                break;
            case this.props.content.enforcedOutScopeSuppliers:
                checkBoxState.OUT_SCOPE_SUSPENDED_SUPP = e.target.checked;
                break;
            case this.props.content.includeSearchSupplier:
                checkBoxState.DIRECT_CONNECT_WAVE = e.target.checked;
                break;
        }
        this.props.pendingChanges(true)
        this.setState({ fileDetails: checkBoxState });

    }

    onChange(e) {
        var textBoxState = this.state.fileDetails;

        textBoxState.ANNUAL_TRANSACTION_MAX = e.target.value;
        this.props.pendingChanges(true)
        this.setState({
            fileDetails: textBoxState
        });
    }

    onTermRationalizationChange(e) {
        var textBoxState = this.state.fileDetails;

        textBoxState.TERMS_RATIONALIZATION_THRESHOLD = e.target.value;
        this.props.pendingChanges(true)
        this.setState({
            fileDetails: textBoxState
        });
    }

    onSpotBuyThresholdChange(e) {
        var textBoxState = this.state.fileDetails;

        // textBoxState.SPOT_BUY_ELIGIBLE_THRESHOLD = parseFloat(e.target.value.replace(/,/g, ''));
        textBoxState.SPOT_BUY_ELIGIBLE_THRESHOLD = e.target.value;
        this.props.pendingChanges(true)
        this.setState({
            fileDetails: textBoxState
        });
    }

    onAvgInvSpendThresholdChange(e) {
        var textBoxState = this.state.fileDetails;

        textBoxState.AVG_INV_SPEND_TRANS_THRESHOLD = e.target.value;
        this.props.pendingChanges(true)
        this.setState({
            fileDetails: textBoxState
        });
    }

    onLowInvoiceCountThresholdChange(e) {
        var textBoxState = this.state.fileDetails;

        textBoxState.LOW_INVOICE_COUNT_THRESHOLD = e.target.value;
        this.props.pendingChanges(true)
        this.setState({
            fileDetails: textBoxState
        });
    }

    onCardPaymentInScopeChange(value) {
        var textBoxState = this.state.fileDetails;

        textBoxState.CARD_PAYMENT_INSCOPE = value === "Yes" ? true : false;
        this.props.pendingChanges(true)
        this.setState({
            fileDetails: textBoxState
        });
    }

    onDiscountThresholdChange(e) {
        var textBoxState = this.state.fileDetails;

        textBoxState.DISCOUNT_THRESHOLD = e.target.value;
        this.props.pendingChanges(true)
        this.setState({
            fileDetails: textBoxState
        });
    }

    showPopupBeforeSave(){
        this.showModal("SpendParameter");
    }

    saveSpendParamWithEnableStrategy(){
        this.setState({
            waveRecalcFlag: true
        },()=>this.handleSaveParameter());
    }

    saveSpendParamWithoutEnableStrategy(){
        this.setState({
            waveRecalcFlag: false
        },()=>this.handleSaveParameter());
    }

    handleSaveParameter() {
        this.hideModal("alert")
        this.hideModal("SpendParameter")
        this.setState({ respnseMessage: '', isError: false,isLoading:true })

        console.log("filedetails :", this.state.fileDetails);

        var currentUpdate = this.state.fileDetails.ANNUAL_TRANSACTION_MAX.toString().replace(/^\D+/g, '');
        this.state.fileDetails.ANNUAL_TRANSACTION_MAX = currentUpdate

        var termRationalizationValue = this.state.fileDetails.TERMS_RATIONALIZATION_THRESHOLD.toString().replace(',', '');
        this.state.fileDetails.TERMS_RATIONALIZATION_THRESHOLD = termRationalizationValue

        var discountThresholdValue = this.state.fileDetails.DISCOUNT_THRESHOLD.toString().replace(',', '');
        this.state.fileDetails.DISCOUNT_THRESHOLD = discountThresholdValue

        var lowInvoiceCountValue = this.state.fileDetails.LOW_INVOICE_COUNT_THRESHOLD.toString().replace(',', '');
        this.state.fileDetails.LOW_INVOICE_COUNT_THRESHOLD = lowInvoiceCountValue

        var cardPaymentInScopeValue = this.state.fileDetails.CARD_PAYMENT_INSCOPE;
        this.state.fileDetails.CARD_PAYMENT_INSCOPE = cardPaymentInScopeValue

        var termRationalizationValue = this.state.fileDetails.SPOT_BUY_ELIGIBLE_THRESHOLD.toString().replace(',', '');
        this.state.fileDetails.SPOT_BUY_ELIGIBLE_THRESHOLD = termRationalizationValue

        var termRationalizationValue = this.state.fileDetails.AVG_INV_SPEND_TRANS_THRESHOLD.toString().replace(',', '');
        this.state.fileDetails.AVG_INV_SPEND_TRANS_THRESHOLD = termRationalizationValue

        var solutionInScope = this.state.fileDetails.SOLUTION_IN_SCOPE;
        this.state.fileDetails.SOLUTION_IN_SCOPE = solutionInScope

        var param = {RequestData : this.state.fileDetails , WaveRecalcFlag : this.state.waveRecalcFlag};

        this.props.UpdateRequestParameter(param).then(response => {
            if (response.status === 200) {
                this.setState({
                    isError: false,
                    respnseMessage: 'Parameter/s updated successfully',
                    test: JSON.stringify(this.state.fileDetails),
                    solutionInScopeFlag: false,
                    isLoading: false
                })
                this.props.currentActiveRequest(this.state.fileDetails);
                this.props.pendingChanges(null)
                //this.setState({ open: !this.state.open })
                //this.closePopUp('close');
            }
            else {
                this.setState({
                    isError: true,
                    respnseMessage: 'An error has occured',
                    isLoading: false
                })
            }
            this.props.saveSpendParametersOnRedirect(false);
        }).catch((error) => {
            this.setState({
                isError: true,
                respnseMessage: 'An error has occured',
                isLoading: false
            })
        })
    }

    handleSolutionScopeChange(value) {
        var objFileDetails = this.state.fileDetails;

        var disableCatalogInScope = false;
        if (value.label == "Supply Chain Collaboration") {
            disableCatalogInScope = true;
            objFileDetails.CATEGORY_IN_SCOPE = false;
        }
        else {
            disableCatalogInScope = false;
        }


        objFileDetails.SOLUTION_IN_SCOPE = value.label;
        this.props.solutionScopeChanged(value.label)
        this.props.pendingChanges(true)
        this.setState({
            disableCatalogInScope: disableCatalogInScope, fileDetails: objFileDetails, isError: true, respnseMessage: 'Warning! Buyer Supplier Enablement Recommendations will be changed.', solutionInScopeFlag: true
        },()=>this.selectProductScopeAssignments(value.label));
    }

    render() {
        var displayInfoDesc = (itemDesc) => {
            return (
                <div>
                    <Popup className="report-tooltip"
                        trigger={<span className='value__information' />}
                        position="bottom right"
                        on="hover"
                    >
                        {itemDesc}
                    </Popup>
                </div>
            )
        };
        //onClick={() => this.showModal('AccountParameters')}

        return (
            this.props.spendDetails !== undefined && this.props.spendDetails.currentActiveRequest !== undefined && this.props.spendDetails.currentActiveRequest !== "" ?
                <div className="grid-x">
                    <div className="medium-12 pb20 pt20">
                        <div className="grid-x">
                            <div className="medium-1"></div>                            
                            <div style={{ display: 'flex', justifyContent: 'center' }} className="medium-10">
                                {this.state.isError ?
                                    <div style={{ margin: '0 0 0.5rem', color: 'red' }}>{this.state.respnseMessage}</div> :
                                    <div style={{ margin: '0 0 0.5rem', color: '#4fb81c' }}>{this.state.respnseMessage}</div>
                                }
                                {(this.state.isLoading) ?
                                    <img style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
                                        src={path + `images/ajax-loader.gif`} alt="" />
                                    : null
                                }
                            </div>
                            <div style={{ paddingLeft: '5px' }} className="float-left medium-1">
                                <button
                                    className='hand-cursor'
                                    type="submit"
                                    //onClick={this.state.solutionInScopeFlag ? this.showModal : this.handleSaveParameter}
                                    //onClick={this.state.solutionInScopeFlag ? this.showModal("alert") : this.showPopupBeforeSave}
                                    onClick={this.showPopupBeforeSave}
                                    content={this.props.content.saveButton}
                                    color ><img width="25" src="images/save_active.svg" /></button>
                            </div>
                        </div>
                    </div>
                    <div className="medium-1"></div>
                    <div style={{ backgroundColor: '#fafafa' }} className="medium-10">
                        <div className="grid-x">                           

                            <div style={{ fontWeight: 'bold' }} className="medium-12 title pt15 pl5">
                                {this.props.content.enablementInsight}
                            </div>
                            <div className="medium-12">
                                <div className="grid-x pt5">
                                    <div className="medium-9 box-title">
                                        <div className="grid-x grid-padding-x">
                                            <div className="medium-10">
                                                {this.props.content.inScopeSolution}
                                            </div>
                                            <div className="medium-2">
                                                <div className="float-right pr20">
                                                    {displayInfoDesc(this.props.content.inScopeSolution)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="medium-3">
                                        <Select
                                            className="border-with-DD dropDownContent"
                                            id='aribaNetworkMatched'
                                            autocomplete='true'
                                            value={this.state.solutionScopeOption.filter(
                                                option => option.value === this.state.fileDetails.SOLUTION_IN_SCOPE
                                            )}
                                            onChange={value => this.handleSolutionScopeChange(value)}
                                            options={this.state.solutionScopeOption}
                                            isSearchable={false}
                                            noOptionsMessage={() => null} />
                                    </div>
                                </div>
                            </div>

                            {(this.state.fieldVisibilityData.length > 0 && 
                                this.state.fieldVisibilityData.some(el => el.FIELD_NAME == this.props.content.includeSearchSupplier && el.VISIBLE_FLAG == 'Yes')) ?
                            <div style={{ fontWeight: 'bold' }} className="medium-12 title pt15 pl5">
                                Enablement Wave: When to enable supplier
                            </div>
                            :null}
                            {(this.state.fieldVisibilityData.length > 0 && 
                                this.state.fieldVisibilityData.some(el => el.FIELD_NAME == this.props.content.includeSearchSupplier && el.VISIBLE_FLAG == 'Yes')) ?
                            
                            <div className="medium-12">
                                <div className="grid-x">
                                    <div className="medium-9 box-title">
                                        <div className="grid-x grid-padding-x">
                                            <div className="medium-10">
                                                {this.props.content.includeSearchSupplier}
                                            </div>
                                            <div className="medium-2">
                                                <div className="float-right pr20">
                                                    {displayInfoDesc(this.props.content.includeSearchSupplierDesc)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="medium-3 product-box">
                                        <div className="pt10">
                                            <CheckboxE iconStyle={{ fill: '#008fd3' }} id={this.props.content.includeSearchSupplier} onClick={(e) => this.handleParameterCheckChanged(e)}
                                                checked={this.state.fileDetails !== undefined && this.state.fileDetails.DIRECT_CONNECT_WAVE !== undefined ? this.state.fileDetails.DIRECT_CONNECT_WAVE : false}
                                                name={this.props.content.includeSearchSupplier} value={this.props.content.includeSearchSupplier} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :null}

                            <div style={{ fontWeight: 'bold' }} className="medium-12 pt15 pl5 title">
                                Enablement Recommendations: How to enable supplier
                            </div>
                            <div className="medium-12 pt5">
                                <div className="grid-x">
                                    <div className="medium-9 box-title">
                                        <div className="grid-x grid-padding-x">
                                            <div className="medium-10">
                                                {this.props.content.supplierEligible}
                                            </div>
                                            <div className="medium-2">
                                                <div className="float-right pr20">
                                                    {displayInfoDesc(this.props.content.supplierEligible)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="medium-3 product-box">
                                        <div className="pt10">
                                            <CheckboxE iconStyle={{ fill: '#008fd3' }} id={this.props.content.supplierEligible}
                                                checked={this.state.fileDetails !== undefined && this.state.fileDetails.SUPPLIER_ELIGIBLE !== undefined ? this.state.fileDetails.SUPPLIER_ELIGIBLE : false} onClick={(e) => this.handleParameterCheckChanged(e)}
                                                name={this.props.content.supplierEligible} value={this.props.content.supplierEligible} />
                                        </div>
                                    </div>
                                </div>
                                <div className="grid-x">
                                    <div className="medium-9 box-title">
                                        <div className="grid-x grid-padding-x">
                                            <div className="medium-10">
                                                {this.props.content.catalogInScope}
                                            </div>
                                            <div className="medium-2">
                                                <div className="float-right pr20">
                                                    {displayInfoDesc(this.props.content.catalogInScope)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="medium-3 product-box">
                                        <div className="pt10">
                                            <CheckboxE disabled={this.state.disableCatalogInScope} iconStyle={{ fill: '#008fd3' }} id={this.props.content.catalogInScope}
                                                checked={this.state.fileDetails !== undefined && this.state.fileDetails.CATEGORY_IN_SCOPE !== undefined ? this.state.fileDetails.CATEGORY_IN_SCOPE : false} onClick={(e) => this.handleParameterCheckChanged(e)}
                                                name={this.props.content.catalogInScope} value={this.props.content.catalogInScope} />
                                        </div>
                                    </div>
                                </div>

                                <div className="grid-x">
                                    <div className="medium-9 box-title">
                                        <div className="grid-x grid-padding-x">
                                            <div className="medium-10">
                                                {this.props.content.transactionEligible}
                                            </div>
                                            <div className="medium-2">
                                                <div className="float-right pr20">
                                                    {displayInfoDesc(this.props.content.transactionEligibleDesc)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="medium-3 product-box">
                                        <div className="pt10">
                                            <CheckboxE iconStyle={{ fill: '#008fd3' }} id={this.props.content.transactionEligible}
                                                checked={this.state.fileDetails !== undefined && this.state.fileDetails.TRANSACTION_ANSUPPLIERS_AUTOMATION_ELIGIBLE !== undefined ? this.state.fileDetails.TRANSACTION_ANSUPPLIERS_AUTOMATION_ELIGIBLE : false} onClick={(e) => this.handleParameterCheckChanged(e)}
                                                name={this.props.content.transactionEligible} value={this.props.content.transactionEligible} />
                                        </div>
                                    </div>
                                </div>

                                <div className="grid-x">
                                    <div className="medium-9 box-title">
                                        <div className="grid-x grid-padding-x">
                                            <div className="medium-10">
                                                {this.props.content.unclassifiedEligible}
                                            </div>
                                            <div className="medium-2">
                                                <div className="float-right pr20">
                                                    {displayInfoDesc(this.props.content.unclassifiedEligible)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="medium-3 product-box">
                                        <div className="pt10">
                                            <CheckboxE iconStyle={{ fill: '#008fd3' }} id={this.props.content.unclassifiedEligible}
                                                checked={this.state.fileDetails !== undefined && this.state.fileDetails.UNCLASSIFIED_AUTOMATION_ELIGIBLE !== undefined ? this.state.fileDetails.UNCLASSIFIED_AUTOMATION_ELIGIBLE : false}
                                                onClick={(e) => this.handleParameterCheckChanged(e)}
                                                name={this.props.content.unclassifiedEligible}
                                                value={this.props.content.unclassifiedEligible} />
                                        </div>
                                    </div>
                                </div>
                                <div className="grid-x">
                                    <div className="medium-9 box-title">
                                        <div className="grid-x grid-padding-x">
                                            <div className="medium-10">
                                                {this.props.content.enforcedOutScopeSuppliers}
                                            </div>
                                            <div className="medium-2">
                                                <div className="float-right pr20">
                                                    {displayInfoDesc(this.props.content.enforcedOutScopeSuppliersDesc)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="medium-3 product-box">
                                        <div className="pt10">
                                        <CheckboxE iconStyle={{ fill: '#008fd3' }} id={this.props.content.enforcedOutScopeSuppliers} onClick={(e) => this.handleParameterCheckChanged(e)}
                                                checked={this.state.fileDetails !== undefined && this.state.fileDetails.OUT_SCOPE_SUSPENDED_SUPP !== undefined ? this.state.fileDetails.OUT_SCOPE_SUSPENDED_SUPP : false}
                                                name={this.props.content.enforcedOutScopeSuppliers} value={this.props.content.enforcedOutScopeSuppliers} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div style={{ fontWeight: 'bold' }} className="medium-12 pt15 pl5 title">
                                {this.props.content.productScope}
                            </div>
                            <div className="medium-12 pt5">
                             

                                <div className="grid-x">
                                    <div className="medium-9 box-title">
                                        <div className="grid-x grid-padding-x">
                                            <div className="medium-10">
                                                {this.props.content.sCCEligible}
                                            </div>
                                            <div className="medium-2">
                                                <div className="float-right pr20">
                                                    {displayInfoDesc(this.props.content.sCCEligibleDesc)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="medium-3 product-box">
                                        <div className="pt10">
                                            <CheckboxE iconStyle={{ fill: '#008fd3' }} id={this.props.content.sCCEligible} onClick={(e) => this.handleParameterCheckChanged(e)}
                                                checked={this.state.fileDetails !== undefined && this.state.fileDetails.WHENDIRECT_SUPPLIER_SCC_ELIGIBLE !== undefined ? this.state.fileDetails.WHENDIRECT_SUPPLIER_SCC_ELIGIBLE : false}
                                                name={this.props.content.sCCEligible} value={this.props.content.sCCEligible} />
                                        </div>
                                    </div>
                                </div>
                                <div className="grid-x">
                                    <div className="medium-9 box-title">
                                        <div className="grid-x grid-padding-x">
                                            <div className="medium-10">
                                                {this.props.content.fieldglass}
                                            </div>
                                            <div className="medium-2">
                                                <div className="float-right pr20">
                                                    {displayInfoDesc(this.props.content.fieldglassDesc)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="medium-3 product-box">
                                        <div className="pt10">
                                            <CheckboxE iconStyle={{ fill: '#008fd3' }} id={this.props.content.fieldglass} onClick={(e) => this.handleParameterCheckChanged(e)}
                                                checked={this.state.fileDetails !== undefined && this.state.fileDetails.FIELD_GLASS_SCOPE !== undefined ? this.state.fileDetails.FIELD_GLASS_SCOPE : false}
                                                name={this.props.content.fieldglass} value={this.props.content.fieldglass} />
                                        </div>
                                    </div>
                                </div>
                                <div className="grid-x">
                                    <div className="medium-9 box-title">
                                        <div className="grid-x grid-padding-x">
                                            <div className="medium-10">
                                                {this.props.content.fieldglassEligible}
                                            </div>
                                            <div className="medium-2">
                                                <div className="float-right pr20">
                                                    {displayInfoDesc(this.props.content.fieldglassEligibleDesc)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="medium-3 product-box">
                                        <div className="pt10">
                                        {/* <CheckboxE iconStyle={{ fill: this.state.fileDetails.FIELD_GLASS_ELIGIBLE === true ? '#008fd3' : '#f7f7f7' }} id={this.props.content.fieldglassEligible} onClick={(e) => this.handleParameterCheckChanged(e)}
                                                checked={this.state.fileDetails !== undefined && this.state.fileDetails.FIELD_GLASS_ELIGIBLE !== undefined ? this.state.fileDetails.FIELD_GLASS_ELIGIBLE : false}
                                                name={this.props.content.fieldglassEligible} value={this.props.content.fieldglassEligible} /> */}
                                            <CheckboxE iconStyle={{ fill: '#008fd3' }} id={this.props.content.fieldglassEligible} onClick={(e) => this.handleParameterCheckChanged(e)}
                                                checked={this.state.fileDetails !== undefined && this.state.fileDetails.FIELD_GLASS_ELIGIBLE !== undefined ? this.state.fileDetails.FIELD_GLASS_ELIGIBLE : false}
                                                name={this.props.content.fieldglassEligible} value={this.props.content.fieldglassEligible} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div style={{ fontWeight: 'bold' }} className="medium-12 title pt15 pl5">
                                {this.props.content.workingCapitalScope}
                            </div>
                            <div className="medium-12">
                                <div className="grid-x pt5">
                                    <div className="medium-9 box-title">
                                        <div className="grid-x grid-padding-x">
                                            <div className="medium-10">
                                                {this.props.content.termRationalizationThreshold}
                                            </div>
                                            <div className="medium-2">
                                                <div className="float-right pr20">
                                                    {displayInfoDesc(this.props.content.termsRationalizationDesc)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="medium-3">
                                        <input className="box-input"
                                            value={this.state.fileDetails !== undefined
                                                && this.state.fileDetails.TERMS_RATIONALIZATION_THRESHOLD !== undefined
                                                ? formatThousandsForStrings(this.state.fileDetails.TERMS_RATIONALIZATION_THRESHOLD)
                                                : 0}
                                            onChange={(e) => this.onTermRationalizationChange(e)} type="text" id="maxvalue" />
                                    </div>
                                </div>
                            </div>
                            <div className="medium-12">
                                <div className="grid-x">
                                    <div className="medium-9 box-title">
                                        <div className="grid-x grid-padding-x">
                                            <div className="medium-10">
                                                {this.props.content.discountThreshold}
                                            </div>
                                            <div className="medium-2">
                                                <div className="float-right pr20">
                                                    {displayInfoDesc(this.props.content.discountThresholdDesc)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="medium-3">
                                        <input className="box-input"
                                            value={this.state.fileDetails !== undefined
                                                && this.state.fileDetails.DISCOUNT_THRESHOLD !== undefined
                                                ? formatThousandsForStrings(this.state.fileDetails.DISCOUNT_THRESHOLD)
                                                : 0}
                                            onChange={(e) => this.onDiscountThresholdChange(e)} type="text" id="maxvalue" />
                                    </div>
                                </div>
                            </div>
                            <div className="medium-12">
                                <div className="grid-x">
                                    <div className="medium-9 box-title">
                                        <div className="grid-x grid-padding-x">
                                            <div className="medium-10">
                                                {this.props.content.lowInvoiceCountThreshold}
                                            </div>
                                            <div className="medium-2">
                                                <div className="float-right pr20">
                                                    {displayInfoDesc(this.props.content.lowInvoiceCountDesc)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="medium-3">
                                        <input className="box-input"
                                            value={this.state.fileDetails !== undefined
                                                && this.state.fileDetails.LOW_INVOICE_COUNT_THRESHOLD !== undefined
                                                ? formatThousandsForStrings(this.state.fileDetails.LOW_INVOICE_COUNT_THRESHOLD)
                                                : 0}
                                            onChange={(e) => this.onLowInvoiceCountThresholdChange(e)} type="text" id="maxvalue" />
                                    </div>
                                </div>
                            </div>
                            <div className="medium-12">
                                <div className="grid-x">
                                    <div className="medium-9 box-title">
                                        <div className="grid-x grid-padding-x">
                                            <div className="medium-10">
                                                {this.props.content.cardPaymentInScope}
                                            </div>
                                            <div className="medium-2">
                                                <div className="float-right pr20">
                                                    {displayInfoDesc(this.props.content.cardPaymentInScopeDesc)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="medium-3 product-box">
                                        <div className="pt10">
                                            <CheckboxE iconStyle={{ fill: '#008fd3' }} id={this.props.content.cardPaymentInScope}
                                                checked={this.state.fileDetails !== undefined && this.state.fileDetails.CARD_PAYMENT_INSCOPE !== undefined ? this.state.fileDetails.CARD_PAYMENT_INSCOPE : false}
                                                onClick={(e) => this.handleParameterCheckChanged(e)}
                                                name={this.props.content.cardPaymentInScope} value={this.props.content.cardPaymentInScope} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* //Spot Buy parameter */}
                            {/* <div className="grid-x pt15"> */}
                                <div style={{ fontWeight: 'bold' }} className="medium-12 title pt15 pl5">
                                    {this.props.content.spotBuy}
                                </div>
                                <div className="medium-12">
                                    <div className="grid-x pt5">
                                        <div className="medium-9 box-title">
                                            <div className="grid-x grid-padding-x">
                                                <div className="medium-10">
                                                    {this.props.content.spotBuyThreshold}
                                                </div>
                                                <div className="medium-2">
                                                    <div className="float-right pr20">
                                                        {displayInfoDesc(this.props.content.spotBuyDesc)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="medium-3">
                                            <input className="box-input"
                                                value={this.state.fileDetails !== undefined
                                                    && this.state.fileDetails.SPOT_BUY_ELIGIBLE_THRESHOLD !== undefined
                                                    ? formatThousandsForStrings(this.state.fileDetails.SPOT_BUY_ELIGIBLE_THRESHOLD)
                                                    : 0}
                                                onChange={(e) => this.onSpotBuyThresholdChange(e)} type="text" id="maxvalue" />
                                        </div>
                                    </div>
                                </div>
                                <div className="medium-12">
                                    <div className="grid-x">
                                        <div className="medium-9 box-title">
                                            <div className="grid-x grid-padding-x">
                                                <div className="medium-10">
                                                    {this.props.content.avgInvoiceThreshold}
                                                </div>
                                                <div className="medium-2">
                                                    <div className="float-right pr20">
                                                        {displayInfoDesc(this.props.content.avgInvoiceDesc)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="medium-3">
                                            <input className="box-input"
                                                value={this.state.fileDetails !== undefined
                                                    && this.state.fileDetails.AVG_INV_SPEND_TRANS_THRESHOLD !== undefined
                                                    ? formatThousandsForStrings(this.state.fileDetails.AVG_INV_SPEND_TRANS_THRESHOLD)
                                                    : 0}
                                                onChange={(e) => this.onAvgInvSpendThresholdChange(e)} type="text" id="maxvalue" />
                                        </div>
                                    </div>
                                </div>

                                {/* <div style={{ fontWeight: 'bold' }} className="medium-12 pt15 pl5 title">
                                    {this.props.content.title}
                                </div>
                                <div className="medium-12 pt5">
                                    <div className="grid-x">
                                        <div className="medium-9 box-title">
                                            <div className="grid-x grid-padding-x">
                                                <div className="medium-10">
                                                    {this.props.content.annualTransactionMax}
                                                </div>
                                                <div className="medium-2">
                                                    <div className="float-right pr20">
                                                        {displayInfoDesc(this.props.content.annualTransactionMaxDesc)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="medium-3">
                                            <input className="box-input"
                                                value={this.state.fileDetails !== undefined
                                                    && this.state.fileDetails.ANNUAL_TRANSACTION_MAX !== undefined
                                                    ? this.state.fileDetails.ANNUAL_TRANSACTION_MAX
                                                    : (this.state.fileDetails !== undefined
                                                        && this.state.fileDetails.ANNUAL_TRANSACTION_MAX !== undefined) ? this.state.fileDetails.ANNUAL_TRANSACTION_MAX : 0}
                                                onChange={(e) => this.onChange(e)} type="text" id="maxvalue" />
                                        </div>
                                    </div>
                                </div> */}


                            {/* </div> */}
                        </div>
                    </div>
                    <div className="medium-1"></div>
                    <div>
                        <Modal
                            ref="alert"
                            modalStyle={alertModalStyle}
                            contentStyle={changeModalContentStyle}
                            backdropStyle={changeBackdropStyle}
                        >
                            <div className="grid-x grid-padding-x change-popup">
                                <div className="medium-12">
                                    <button onClick={() => this.hideModal("alert")} className="close" />
                                </div>
                                <div className="medium-12 pt15">
                                    {this.state.modalDesc}
                                </div>
                                <div className="medium-12 popupOkBtn pt10">
                                    <div className="float-right">
                                        <div className="float-left"><Button
                                            content={"Yes"}
                                            onClick={this.handleSaveParameter}
                                            color /></div>
                                        <div className="float-left"><Button
                                            content={"Close"}
                                            onClick={() => this.hideModal("alert")}
                                            color /></div>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                    </div>
                    <div>
                    <Modal
                        ref="SpendParameter"
                        modalStyle={alertModalStyleSpendParameter}
                        contentStyle={changeModalContentStyle}
                        backdropStyle={changeBackdropStyle}
                    >
                        <div className="grid-x grid-padding-x change-popup">
                            <div className="medium-12">
                                <button onClick={() => this.hideModal("SpendParameter")} className="close" />
                            </div>
                            <div className="medium-12 pt30">
                            {this.props.tabMessage.toggleVSMessage}
                            </div>                            
							<div className="grid-x medium-12 mt20">
								<div>
                                    <Button
                                        className="float-left"
                                        type="submit"
                                        disabled={this.state.isLoading}
                                        content={this.props.tabMessage.toggleSpendParamYesButtonWithStrategy}
                                        //onClick={() => this.continueSpendParameterNavigation("SpendParameter")}
                                        onClick={this.saveSpendParamWithEnableStrategy.bind(this)}
                                        color />
                                </div>  
                                <div>
                                    <Button
                                        className="float-left"
                                        type="submit"
                                        disabled={this.state.isLoading}
                                        content={this.props.tabMessage.toggleSpendParamYesButton}
                                        //onClick={() => this.continueSpendParameterNavigation("SpendParameter")}
                                        onClick={this.saveSpendParamWithoutEnableStrategy.bind(this)}
                                        color />
                                </div>                                
                                {/* <div>
                                    <Button
                                        className="float-left"
                                        type="submit"
                                        disabled={this.state.isLoading}
                                        content={this.props.tabMessage.toggleVSNoButton}
                                        onClick={() => this.closeModal("SpendParameter")} 
                                        color />
                                </div> */}
								<div>
                                    <Button
                                        className="float-left"
                                        type="submit"
                                        disabled={this.state.isLoading}
                                        content={this.props.tabMessage.toggleVSRevertButton}
                                        onClick={() => this.hideModal("SpendParameter")}
                                        color />
                                </div>
                            </div> 
                        </div>
                    </Modal>
                </div>
                </div >
                :
                <div className="grid-x">
                    <div className="medium-12">
                        {this.props.content.noActiveRequestFile}
                    </div >
                </div >
        )
    }
}
const mapStateToProps = state => {
    return {
        // language: state.language,
        userData: state.valueStory,
        user: state.default,
        content: state.content.spendAnalysis.accountParameters,
        validation: state.content.validation,
        Industries: state.content.discovery.form.industries,
        spendDetails: state.spendDetails,
        saveSpendParametersAfterRedirect : state.saveSpendParametersOnRedirect.changes,
        waveRecalcAfterRedirect : state.isWaveRecalcOnSpendParamRedirect.changes,
        tabMessage: state.content.spendAnalysis.detailedFile
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getYourFileLoading: bool => dispatch(getYourFileLoading(bool)),        
        UpdateRequestParameter: requestDetails => dispatch(UpdateRequestParameter(requestDetails)),
        currentActiveRequest: (requestDetails) => dispatch(currentActiveRequest(requestDetails)),
        solutionScopeChanged: (val) => dispatch(solutionScopeChanged(val)),
        pendingChanges: (val) => dispatch(pendingChanges(val)),
        fetchVisibleSolutionInScopeValues: () => dispatch(fetchVisibleSolutionInScopeValues()),
        isSpendParameterPage: obj => dispatch(isSpendParameterPage(obj)),
        saveSpendParametersOnRedirect: changes => dispatch(saveSpendParametersOnRedirect(changes)),
        FetchUpdateFieldVisibility: (data) => dispatch(FetchUpdateFieldVisibility(data)) 
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AccountParametersContainer)
