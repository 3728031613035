import axios from 'axios'
 const url =  '/NodeApiCall/api/' // While deploying to server need this
 //const url = 'http://localhost:5000/NodeApiCall/api/' //-- for local systems 
//reportNodeActions

export function manipulateReport(reportDetails) {

  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      url: url + 'ManipulateReport',
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': `AribaBearer ${token}`
      },
       data: (reportDetails) 
    }).then((response) => {
      return response;
    }).catch((err) => {
       throw err;
    })
  }
}

 