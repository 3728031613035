import React, { Component } from 'react'

import { GICSColumns } from 'Constants'
import CustomGrid from 'UI/CustomGrid'
import { connect } from 'react-redux'
import { Filters, Editors } from "react-data-grid-addons";

import NotesEditor from 'UI/NotesEditor'

import {
    userManagerFailure,
    userManagerSuccess,
    GetSpendColumnsForRole,
    addUserRole,
    DeleteSpendColumnsForRole,
    updateUserRole,
    GetAllRoles,
    resetError,
    submitSpendColumnsForRole
} from 'actions/roleAndPermissionAction'
import { AlignFormatter, CenterAlignFormatter, getNonEmptyValue } from "../../utils/Utils";

const {
    NumericFilter,
    AutoCompleteFilter,
} = Filters;

const { DropDownEditor } = Editors;
const dropdownValues = ["Yes", "No"];
const dropDownEditor = <DropDownEditor options={dropdownValues} />

class SpendAndRoleManager extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {},
            columns: [],
            responseMsg: '',
        }

        this.loadData = this.loadData.bind(this)
        this.save = this.save.bind(this)
        this.validate = this.validate.bind(this)
        this.onCommit = this.onCommit.bind(this)
        this.formGridRow = this.formGridRow.bind(this)
    }

    componentDidMount() {
        this.loadData()
    }

    loadData() {
        this.props.GetSpendColumnsForRole().then((response) => {
            let columns = this.initializeColumns(response.data.Result);
            let preparedData = this.formGridRow(response);

            this.setState({ data: preparedData, columns: columns });
        })
    }

    formGridRow(response) {
        let preparedData = [];
        response.data.Result.RolesSpendColumnDetails.map((columnDetail) => {
            var rowData = [];
            rowData = columnDetail;

            var target = response.data.Result.AllActiveRoles.find((obj) => {
                return obj.RoleName === "Export All_Spend Details"
            })
            if (target !== null && target !== undefined) {
                var index = response.data.Result.AllActiveRoles.indexOf(target);
                response.data.Result.AllActiveRoles.splice(index, 1);
            }

            response.data.Result.AllActiveRoles.map((roles) => {
                if (rowData.Roles.length == 0) {
                    rowData[roles.RoleName] = "No"
                }
                else {
                    for (var i = 0; i < rowData.Roles.length; i++) {

                        if (roles.RoleName == rowData.Roles[i].RoleName) {
                            rowData[roles.RoleName] = "Yes"
                            break;
                        } else {
                            rowData[roles.RoleName] = "No"
                            continue;
                        }
                    }
                }
            });

            preparedData.push(rowData);
        });

        return preparedData;
    }

    initializeColumns(columnData) {
        let columns = []

        let tableColumn = {
            name: 'Area',
            key: 'ColumnCategory',
            width: 120,
            sortable: true,
            editable: false,
            resizable: true,
            filterable: true,
            screenSeq: 0,
            mandatory: 'No',
            exportFl: true,
            //editProperty: fieldConfig.is_editable,
            formatter: CenterAlignFormatter,
            helpText: '',
            filterRenderer: AutoCompleteFilter
        }

        columns.push(tableColumn);

        tableColumn = {
            name: 'Field Name',
            key: 'ColumnField',
            width: 120,
            sortable: true,
            editable: false,
            resizable: true,
            filterable: true,
            screenSeq: 1,
            mandatory: 'No',
            exportFl: true,
            //editProperty: fieldConfig.is_editable,
            formatter: CenterAlignFormatter,
            helpText: '',
            filterRenderer: AutoCompleteFilter
        }

        columns.push(tableColumn);

        for (var index = 0; index < columnData.AllActiveRoles.length; index++)
        //columnData.AllActiveRoles.forEach((element) =>
        {
            if (columnData.AllActiveRoles[index]['RoleName'] !== 'Export All_Spend Details') {
                const fieldConfig = columnData.AllActiveRoles[index];
                let tableColumn = {
                    name: fieldConfig.RoleName,
                    key: fieldConfig.RoleName,
                    width: 120,
                    sortable: true,
                    editable: true,
                    resizable: true,
                    filterable: true,
                    screenSeq: index + 2,
                    mandatory: 'No',
                    exportFl: true,
                    formatter: CenterAlignFormatter,
                    helpText: '',
                    filterRenderer: AutoCompleteFilter,
                    editor: dropDownEditor
                }
                columns.push(tableColumn);
            }
        };

        var columnIndex = columnData.AllActiveRoles.length + 1;
        tableColumn = {
            name: 'Stored Procedure',
            key: 'ProcedureName',
            width: 120,
            sortable: true,
            editable: false,
            resizable: true,
            filterable: true,
            screenSeq: columnIndex + 1,
            mandatory: 'No',
            exportFl: true,
            //editProperty: fieldConfig.is_editable,
            formatter: CenterAlignFormatter,
            helpText: '',
            filterRenderer: AutoCompleteFilter
        }

        columns.push(tableColumn);

        tableColumn = {
            name: 'Last Update',
            key: 'UpdatedDate',
            width: 120,
            sortable: true,
            editable: false,
            resizable: true,
            filterable: true,
            screenSeq: columnIndex + 2,
            mandatory: 'No',
            exportFl: true,
            //editProperty: fieldConfig.is_editable,
            formatter: CenterAlignFormatter,
            helpText: '',
            filterRenderer: AutoCompleteFilter
        }

        columns.push(tableColumn);

        tableColumn = {
            name: 'Updated By',
            key: 'UpdatedBy',
            width: 120,
            sortable: true,
            editable: false,
            resizable: true,
            filterable: true,
            screenSeq: columnIndex + 3,
            mandatory: 'No',
            exportFl: true,
            //editProperty: fieldConfig.is_editable,
            formatter: CenterAlignFormatter,
            helpText: '',
            filterRenderer: AutoCompleteFilter
        }

        columns.push(tableColumn);

        return columns;
    }

    validateRow(records) {
        var errorRecords = []
        const columns = this.state.columns

        for (let idx = 0; idx < records.length; idx++) {
            const row = records[idx]
            for (let idx = 0; idx < columns.length; idx++) {
                const column = columns[idx]
                if (getNonEmptyValue(row[column.key]) === '' && column.mandatory) {
                    errorRecords.push(row)
                    break;
                }
            }

        }

        return errorRecords;
    }

    validate(record, warningErrorRecords) {

        return warningErrorRecords
    }

    save(records) {
        const recordsToUpdate = [];

        for (let idx = 0; idx < records.length; idx++) {
            const row = records[idx]
            var roles = [];
            for (var i in row) {
                if (row[i] === "Yes" || row[i] === "No") {
                    roles.push({ ["RoleName"]: i, ["IsSelected"]: row[i] === "Yes" ? true : false });
                }
            }

            const record = {
                ID: row["ID"],
                ColumnField: row["ColumnField"],
                ProcedureName: row["ProcedureName"],
                UpdatedBy: this.props.user.user.Id,
                UpdatedDate: row["UpdatedDate"],
                Roles: roles
            };

            recordsToUpdate.push(record);
        }

        return this.props.submitSpendColumnsForRole(recordsToUpdate);
    }

    onCommit(response) {
        let preparedData = this.formGridRow(response);
        return preparedData;
    }

    render() {
        return (
            <div className="grid-x">
                <CustomGrid
                    columns={this.state.columns}
                    topMargin={true}
                    data={this.state.data}
                    keyColumn="ID"
                    save={this.save}
                    validate={this.validate}
                    onCommit={this.onCommit}
                    export={true}
                    exportFileName="Spend Column Extract"
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        content: state.content,
        ui: state.content.ui,
        user: state.default
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        userManagerSuccess: (bool) => dispatch(userManagerSuccess(bool)),
        userManagerFailure: (error) => dispatch(userManagerFailure(error)),
        GetSpendColumnsForRole: (formValues) => dispatch(GetSpendColumnsForRole(formValues)),
        submitSpendColumnsForRole: (formValues) => dispatch(submitSpendColumnsForRole(formValues)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SpendAndRoleManager); 