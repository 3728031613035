import React from 'react';
import ReactDOM from 'react-dom'
import App from './App';
import registerServiceWorker from './registerServiceWorker'

// Redux
import { Provider } from 'react-redux'
import configureStore from './store'

// UI
import './index.css'
import 'react-mdl/extra/material.js'
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'

export const store = configureStore()

ReactDOM.render(
	<MuiThemeProvider>
		<Provider store={store}>
			<App />
		</Provider>
	</MuiThemeProvider>,
	document.getElementById('root')
);

registerServiceWorker();
