import React from 'react';
import Button from 'UI/Button'
import { Checkbox } from 'material-ui';
import { connect } from 'react-redux'
import { isArrayEquals } from 'utils/Utils'
import { getFavoriteFields } from 'actions/spendActions'


import ColumnSelectFavorite from './ColumnSelectFavorite'

class ColumnFields extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            columnTree: this.getColumnTreeData(this.props.columnOptions),
            columnGroupVisibility: this.getColumnGroup(this.props.columnOptions),
            currentColumnGroupVisibility: this.getColumnGroup(this.props.columnOptions),
            enableFilter: false,
            selectAll: false,
            //showAvailableFields: this.props.scc ? true : false,
            showAvailableFields: false,
            disableOkBtn: false,
            favBtnDisabled: false,
            okBtnDisabled: false
        }
        this.templates = [];
        this.defaultColumns = [];
        this.flightPlanningColumns = [];
        this.internalEnablementInsightViewColumns = [];
        this.externalEnablementInsightViewColumns = [];
        this.categoryInsightsViewColumns = [];
        this.MandCViewColumns = [];
        this.enterpriseEnablementEstimatesViewColumns = [];
        this.sccEnableInsightViewColumns = [];
        this.sccViewColumns = [];
        this.AllSccViewColumns = [];
        this.onFilterChange = this.onFilterChange.bind(this);
        this.showAvailableField = this.showAvailableField.bind(this);
        this.addToFavorites = this.addToFavorites.bind(this);
        this.selectFavoriteTemplate = this.selectFavoriteTemplate.bind(this);
        this.selectDefaultTemplate = this.selectDefaultTemplate.bind(this);
        this.selectFlightPlanningTemplate = this.selectFlightPlanningTemplate.bind(this);
        this.selectHideEmptyColumnsTemplate = this.selectHideEmptyColumnsTemplate.bind(this)
        this.save = this.save.bind(this);
        this.toggleBothGroups = this.toggleBothGroups.bind(this)
        this.selectInternalEnablementInsightViewTemplate = this.selectInternalEnablementInsightViewTemplate.bind(this);
        this.selectExternalEnablementInsightViewTemplate = this.selectExternalEnablementInsightViewTemplate.bind(this);
        this.selectCategoryInsightsViewTemplate = this.selectCategoryInsightsViewTemplate.bind(this);
        this.selectEnterpriseEnablementEstimatesViewTemplate = this.selectEnterpriseEnablementEstimatesViewTemplate.bind(this);
        this.selectMAndCviewTemplate = this.selectMAndCviewTemplate.bind(this);
        this.selectSccEnableInsightViewTemplate = this.selectSccEnableInsightViewTemplate.bind(this);
        this.selectSccViewTemplate= this.selectSccViewTemplate.bind(this);
        this.selectAllSccViewTemplate= this.selectAllSccViewTemplate.bind(this);
    }

    getSelectedColumn(columnTree) {
        let selectedColumns = [];
        Object.values(columnTree).map((category) => {
            category.cols.map((column, index) => {
                if (column.isSelected) {
                    selectedColumns.push(column.id);
                }
            });
        });
        return selectedColumns;
    }

    componentWillMount() {
        this.getFavoriteFields();
    }

    getFavoriteFields() {
        this.props.getFavoriteFields(this.props.user.user.Id).then(
            (response) => {
                this.templates = response.data.Result;
                this.setButtonIsDisabled();
            },
            (error) => { console.log(error); }
        )
    }

    setOkBtnDisabled() {
        let okBtnDisabled = this.state.showAvailableFields ? this.selectedColumns.length < 1 : (this.favFieldId === undefined || this.favFieldId === null);
        this.setState({ okBtnDisabled: okBtnDisabled });
    }

    setButtonIsDisabled() {
        this.selectedColumns = this.getSelectedColumn(this.state.columnTree);
        this.setOkBtnDisabled();
        let favBtnDisabled = false;
        this.defaultColumns = this.defaultColumns.length > 0 ? this.defaultColumns : this.getDefaultColumns();
        this.flightPlanningColumns = this.flightPlanningColumns.length > 0 ? this.flightPlanningColumns : this.getFlightPlanningColumns();
        this.internalEnablementInsightViewColumns = this.internalEnablementInsightViewColumns.length > 0 ? this.internalEnablementInsightViewColumns : this.getinternalEnablementInsightViewColumns();
        this.externalEnablementInsightViewColumns = this.externalEnablementInsightViewColumns.length > 0 ? this.externalEnablementInsightViewColumns : this.getexternalEnablementInsightViewColumns();
        this.categoryInsightsViewColumns = this.categoryInsightsViewColumns.length > 0 ? this.categoryInsightsViewColumns : this.getcategoryInsightsViewColumns();
        this.MandCViewColumns = this.MandCViewColumns.length > 0 ? this.MandCViewColumns : this.getMandCViewColumns();
        this.enterpriseEnablementEstimatesViewColumns = this.enterpriseEnablementEstimatesViewColumns.length > 0 ? this.enterpriseEnablementEstimatesViewColumns : this.getenterpriseEnablementEstimatesViewColumns();
        this.sccEnableInsightViewColumns = this.sccEnableInsightViewColumns.length > 0 ? this.sccEnableInsightViewColumns : this.getSccEnableInsightViewColumns();
        this.sccViewColumns = this.sccViewColumns.length > 0 ? this.sccViewColumns : this.getSccViewColumns();
        this.AllSccViewColumns = this.AllSccViewColumns.length > 0 ? this.AllSccViewColumns : this.getAllSccViewColumns();
        if (this.selectedColumns.length > 0) {
            if (isArrayEquals(this.selectedColumns, this.defaultColumns))
                favBtnDisabled = true;
            else if (isArrayEquals(this.selectedColumns, this.flightPlanningColumns))
                favBtnDisabled = true;
            else if (isArrayEquals(this.selectedColumns, this.internalEnablementInsightViewColumns))
                favBtnDisabled = true;
            else if (isArrayEquals(this.selectedColumns, this.externalEnablementInsightViewColumns))
                favBtnDisabled = true;
            else if (isArrayEquals(this.selectedColumns, this.categoryInsightsViewColumns))
                favBtnDisabled = true;
            else if (isArrayEquals(this.selectedColumns, this.MandCViewColumns))
                favBtnDisabled = true;
            else if (isArrayEquals(this.selectedColumns, this.enterpriseEnablementEstimatesViewColumns))
                favBtnDisabled = true;
            else if (isArrayEquals(this.selectedColumns, this.sccEnableInsightViewColumns))
                favBtnDisabled = true;
            else if (isArrayEquals(this.selectedColumns, this.sccViewColumns))
                favBtnDisabled = true;
            else if (isArrayEquals(this.selectedColumns, this.AllSccViewColumns))
                favBtnDisabled = true;
            else {
                this.templates.forEach(template => {
                    if (isArrayEquals(this.selectedColumns, template.Columns)) {
                        favBtnDisabled = true;
                    }
                })
            }
        } else {
            favBtnDisabled = true;
        }
        this.setState({ favBtnDisabled: favBtnDisabled });
    }

    getDefaultColumns() {
        let columns = this.props.columnOptions; let defaultColumns = [];
        columns.forEach(column => {
            if (column.is_default === "Yes") {
                defaultColumns.push(column.id);
            }
        });
        return defaultColumns;
    }

    getFlightPlanningColumns() {
        let columns = this.props.columnOptions; let flightPlanningColumns = [];
        columns.forEach(column => {
            if (column.columnCategory === "Enablement Insight" || column.id === "VENDOR_ID" || column.id === "VENDOR_NAME") {
                flightPlanningColumns.push(column.id);
            }
        });
        return flightPlanningColumns;
    }

    getinternalEnablementInsightViewColumns() {
        let columns = this.props.columnOptions; let internalEnablementInsightViewColumns = [];
        columns.forEach(column => {
            if (column.internalEnablementInsightView == 'Yes') {
                internalEnablementInsightViewColumns.push(column.id);
            }
        });
        return internalEnablementInsightViewColumns;
    }

    getexternalEnablementInsightViewColumns() {
        let columns = this.props.columnOptions; let externalEnablementInsightViewColumns = [];
        columns.forEach(column => {
            if (column.internalEnablementInsightView == 'Yes') {
                externalEnablementInsightViewColumns.push(column.id);
            }
        });
        return externalEnablementInsightViewColumns;
    }

    getcategoryInsightsViewColumns() {
        let columns = this.props.columnOptions; let categoryInsightsViewColumns = [];
        columns.forEach(column => {
            if (column.internalEnablementInsightView == 'Yes') {
                categoryInsightsViewColumns.push(column.id);
            }
        });
        return categoryInsightsViewColumns;
    }

    getMandCViewColumns() {
        let columns = this.props.columnOptions; let categoryInsightsViewColumns = [];
        columns.forEach(column => {
            if (column.matchAndClassification_export == 'Yes') {
                categoryInsightsViewColumns.push(column.id);
            }
        });
        return categoryInsightsViewColumns;
    }

    getenterpriseEnablementEstimatesViewColumns() {
        let columns = this.props.columnOptions; let enterpriseEnablementEstimatesViewColumns = [];
        columns.forEach(column => {
            if (column.enterpriseEnablementEstimatesViewColumns == 'Yes') {
                enterpriseEnablementEstimatesViewColumns.push(column.id);
            }
        });
        return enterpriseEnablementEstimatesViewColumns;
    }

    getSccEnableInsightViewColumns() {
        let columns = this.props.columnOptions; let sccEnableInsightViewColumns = [];
        columns.forEach(column => {
            if (column.sccEnableInsightView == 'Yes') {
                sccEnableInsightViewColumns.push(column.id);
            }
        });
        return sccEnableInsightViewColumns;
    }

    getSccViewColumns() {
        let columns = this.props.columnOptions; let sccViewColumns = [];
        columns.forEach(column => {
            if (column.sccView == 'Yes') {
                sccViewColumns.push(column.id);
            }
        });
        return sccViewColumns;
    }

    getAllSccViewColumns() {
        let columns = this.props.columnOptions; let AllSccViewColumns = [];
        columns.forEach(column => {
            if (column.allSccView == 'Yes') {
                AllSccViewColumns.push(column.id);
            }
        });
        return AllSccViewColumns;
    }

    getColumnGroup(columns) {
        let colTree = {}
        columns.forEach((item) => {
            var colGroup = colTree[item.columnCategory]
            if (colGroup === undefined) {
                colTree[item.columnCategory] = false;
            }
        })
        return colTree;
    }

    getColumnTreeData(columns) {
        columns.sort((a,b) => a.seq - b.seq);
        let colTree = {}
        columns.forEach((item) => {
            var colGroup = colTree[item.columnCategory]
            if (!colGroup) {
                colGroup = { cols: [], isSelected: item.isSelected }
            }

            colGroup.cols.push(item)
            colGroup.isSelected = colGroup.isSelected && item.isSelected
            colTree[item.columnCategory] = colGroup;
        })
        return colTree;
    }

    groupToggle(key) {
        this.toggleBothGroups(key)
    }

    currentGroupToggle(key) {
        this.toggleBothGroups(key)
    }

    toggleBothGroups(key) {
        let columnGroupVisibility = this.state.columnGroupVisibility;
        columnGroupVisibility[key] = !columnGroupVisibility[key];

        let currentColumnGroupVisibility = this.state.currentColumnGroupVisibility;
        currentColumnGroupVisibility[key] = !currentColumnGroupVisibility[key];

        this.setState({ columnGroupVisibility: columnGroupVisibility, currentColumnGroupVisibility: currentColumnGroupVisibility });

    }

    selectColumnGroup(key) {
        let columnTree = this.state.columnTree;
        let isSelected = columnTree[key].isSelected;
        this.props.scc && key == 'Table Key' ?  columnTree[key].isSelected = true : columnTree[key].isSelected = !isSelected;
        columnTree[key].cols.map((column) => {
            if (this.state.enableFilter) {
                if (column.matchedFilter) {
                    column.isSelected = !isSelected;
                }
            } else {
                column.isSelected = !isSelected
            }
        })
        this.setButtonIsDisabled();
        this.setState({ columnTree: columnTree });
    }

    selectColumn(id, key) {
        let columnTree = this.state.columnTree;
        columnTree[key].cols.map((column, index) => {
            if (column.id === 'DATA_COLLECTIONS_SKEY' && this.props.scc) {
                column.isSelected = true;
            }else if (column.id === id) {
                column.isSelected = !column.isSelected;
            }
            if (index === 0) {
                columnTree[key].isSelected = column.isSelected;
            } else {
                columnTree[key].isSelected = columnTree[key].isSelected && column.isSelected;
            }
        });
        this.setButtonIsDisabled();
        this.setState({ columnTree: columnTree });
    }

    selectAllColumns(event) {
        var bool = event.target.checked;
        let columnTree = this.state.columnTree;
        Object.values(columnTree).forEach((category) => {
            category.isSelected = bool;
            category.cols.map((column) => {
                column.isSelected = bool;
            })
        })
        this.setButtonIsDisabled();
        this.setState({ selectAll: bool, columnTree: columnTree });
    }

    onFilterChange(e) {
        let columnTree = this.state.columnTree; let columnGroupVisibility = this.state.columnGroupVisibility;
        let enableFilter = this.state.enableFilter;
        if (e.target.value === undefined || e.target.value === '') {
            enableFilter = false;
            Object.entries(columnTree).map((values) => {
                let key = values[0];
                let category = values[1];
                columnGroupVisibility[key] = false;
                category.cols.map((column, index) => {
                    if (index === 0) {
                        category.isSelected = column.isSelected;
                    } else {
                        category.isSelected = category.isSelected && column.isSelected;
                    }
                });
            });
        } else {
            enableFilter = true;
            Object.entries(columnTree).map((values) => {
                let key = values[0];
                let category = values[1];
                columnGroupVisibility[key] = false;
                category.cols.map((column, index) => {
                    if (column.label.toLocaleLowerCase().indexOf(e.target.value.toLocaleLowerCase()) > -1) {
                        column.matchedFilter = true;
                        columnGroupVisibility[key] = true;
                    } else {
                        column.matchedFilter = false;
                    }
                    if (index === 0) {
                        category.isSelected = column.isSelected;
                    } else {
                        category.isSelected = category.isSelected && column.isSelected;
                    }
                })
            })
        }

        this.setState({ columnTree: columnTree, enableFilter: enableFilter, columnGroupVisibility: columnGroupVisibility });
    }

    save() {
        if (this.props.onColumnSelection) {
            let parameter = this.state.showAvailableFields ? this.selectedColumns : this.favFieldId;
            this.props.onColumnSelection(parameter);
        }
        this.props.closePopup();
    }

    showAvailableField(value) {
        this.columns = [];
        this.getFavoriteFields();
        this.setState({ 'showAvailableFields': value }, () => {
            this.setButtonIsDisabled();
            this.favFieldId = undefined;
        });
    }

    addToFavorites() {
        this.columns = JSON.parse(JSON.stringify(this.selectedColumns));
        this.setState({ 'showAvailableFields': false }, () => {
            this.setButtonIsDisabled();
        });
    }

    selectFavoriteTemplate(favoriteTemplate) {
        this.favFieldId = favoriteTemplate.Id;
        this.setButtonIsDisabled();
        this.selectSpecifiedColumns(favoriteTemplate.Columns);
    }

    selectDefaultTemplate() {
        this.favFieldId = "DEFAULT";
        this.setButtonIsDisabled();
        this.selectSpecifiedColumns(this.defaultColumns);
    }

    selectFlightPlanningTemplate() {
        this.favFieldId = "FlightPlanning";
        this.setButtonIsDisabled();
        this.selectSpecifiedColumns(this.flightPlanningColumns);
    }

    selectInternalEnablementInsightViewTemplate() {
        this.favFieldId = "InternalEnablementInsightView";
        this.setButtonIsDisabled();
        this.selectSpecifiedColumns(this.internalEnablementInsightViewColumns);
    }

    selectExternalEnablementInsightViewTemplate() {
        this.favFieldId = "ExternalEnablementInsightView";
        this.setButtonIsDisabled();
        this.selectSpecifiedColumns(this.externalEnablementInsightViewColumns);
    }

    selectCategoryInsightsViewTemplate() {
        this.favFieldId = "CategoryInsightsView";
        this.setButtonIsDisabled();
        this.selectSpecifiedColumns(this.categoryInsightsViewColumns);
    }

    selectMAndCviewTemplate() {
        this.favFieldId = "MandCView";
        this.setButtonIsDisabled();
        this.selectSpecifiedColumns(this.categoryInsightsViewColumns);
    }

    selectEnterpriseEnablementEstimatesViewTemplate() {
        this.favFieldId = "EnterpriseEnablementEstimatesView";
        this.setButtonIsDisabled();
        this.selectSpecifiedColumns(this.enterpriseEnablementEstimatesViewColumns);
    }

    selectSccEnableInsightViewTemplate() {
        this.favFieldId = "SccEnableInsightView";
        this.setButtonIsDisabled();
        this.selectSpecifiedColumns(this.sccEnableInsightViewColumns);
    }

    selectSccViewTemplate() {
        this.favFieldId = "SccView";
        this.setButtonIsDisabled();
        this.selectSpecifiedColumns(this.sccViewColumns);
    }

    selectAllSccViewTemplate() {
        this.favFieldId = "AllSccView"; 
        this.setButtonIsDisabled();
        this.selectSpecifiedColumns(this.AllSccViewColumns);
    }
    
    selectHideEmptyColumnsTemplate() {
        this.favFieldId = "HideEmptyColumns";
        this.setButtonIsDisabled();
    }

    selectSpecifiedColumns(columnIds) {
        let columnTree = this.state.columnTree;
        Object.values(columnTree).map((category) => {
            category.cols.map((column, index) => {
                column.isSelected = columnIds.indexOf(column.id) > -1
            });
        });
        this.setState({ "columnTree": columnTree });
    }


    render() {
        let getAvailableFieldsTemplate = () => {
            return (
                <React.Fragment>
                    <div className="search-input">
                        <div className="control-wrapper">
                            <input type='text' placeholder={this.props.content.search}
                                value={this.state.filterText}
                                onChange={this.onFilterChange} />
                            <img width="14px" className="control-image" src="/images/search_big_inactive.svg" />
                        </div>
                    </div>
                    <div className="allField">
                        <li className="all-option">
                            <div className="grid-x">
                                <div className="medium-12">
                                    <div className="float-left width30">
                                        <Checkbox checked={this.state.selectAll}
                                            onClick={(e) => this.selectAllColumns(e)} />
                                    </div>
                                    <div className="float-left line-height25">{this.props.content.all}</div>
                                </div>
                            </div>
                        </li>
                        {availableFieldDiv()}
                    </div>
                </React.Fragment>
            )
        }
        let availableSubGroups = (columns, key, isCurrentField) => {
            let columnDiv = [];
            columns.map((column, index) => {
                let columnSelect = column.isSelected;
                let label = column.label;
                let matchFound = isCurrentField ? true : (this.state.enableFilter ? column.matchedFilter : true);
                let id = column.id;
                if (matchFound) {
                    columnDiv.push(
                        <div key={index} className="grid-x">
                            <div className="medium-12" >
                                {
                                    !isCurrentField ? <div className="float-left width30">
                                        <Checkbox id={id} checked={columnSelect} name={id} value={id} onClick={(e) => { this.selectColumn(id, key) }} />
                                    </div> : null
                                }
                                {
                                    isCurrentField ?
                                        (column.isSelected ? <div className="float-left line-height25">{label}</div> : null) :
                                        <div className="float-left line-height25">{label}</div>
                                }
                            </div>
                        </div>
                    )
                }
            })
            return columnDiv;
        }
        let availableFieldDiv = () => {
            let columnTree = this.state.columnTree;
            let colGroups = Object.keys(columnTree);
            let template = [];
            colGroups.map((key, index) => {
                let groupCheck = columnTree[key].isSelected;
                let columns = columnTree[key].cols;
                template.push(<li key={index}>
                    <div className="grid-x">
                        <div onClick={(e) => this.groupToggle(key)} className="float-right plus-icon medium-1 hand-cursor">
                            {this.state.columnGroupVisibility[key] ? <img width="10" src="images/minus-inactive.svg" /> : <img width="10" src="images/add.svg" />}
                        </div>
                        <div className="medium-11">
                            <div className="float-left width30">
                                <Checkbox id={index} checked={groupCheck} onClick={(e) => { this.selectColumnGroup(key) }} name={key} value={key} /></div>
                            <div className="float-left line-height25">{key}</div>
                        </div>
                        {this.state.columnGroupVisibility[key] ?
                            <div className="medium-10 medium-offset-2">
                                {availableSubGroups(columns, key, false)}
                            </div> : null
                        }
                    </div>
                </li>)
            })
            return template
        }
        let currentFieldDiv = () => {
            let columnTree = this.state.columnTree;
            let colGroups = Object.keys(columnTree);
            let template = [];
            colGroups.map((key, index) => {
                let columns = columnTree[key].cols;
                template.push(<li key={index}>
                    <div className="grid-x">
                        <div onClick={(e) => this.currentGroupToggle(key)} className="float-right plus-icon medium-1 hand-cursor">
                            {this.state.currentColumnGroupVisibility[key] ? <img width="10" src="images/minus-inactive.svg" /> : <img width="10" src="images/add.svg" />}
                        </div>
                        <div className="medium-11">
                            {key}
                        </div>
                        {this.state.currentColumnGroupVisibility[key] ?
                            <div className="medium-10 medium-offset-2">
                                {availableSubGroups(columns, key, true)}
                            </div> : null
                        }
                    </div>
                </li>)
            })
            return template
        }

        return (
            <div className="detailedFile" >
                <div className="fieldMainDiv">
                    <div className="grid-x grid-padding-x">
                        <div className="medium-6 cell grid-padding-10 tabHeader">
                            <div className={"float-left" + (this.state.showAvailableFields ? ' active' : '')} onClick={() => this.showAvailableField(true)}>{this.props.content.availableFields}</div>
                            
                            {/* {this.props.scc ? null : <div className={"float-left pl20" + (this.state.showAvailableFields ? '' : ' active')} onClick={() => this.showAvailableField(false)}>{this.props.content.columnSelectFavorites}</div>} */}
                            <div className={"float-left pl20" + (this.state.showAvailableFields ? '' : ' active')} onClick={() => this.showAvailableField(false)}>{this.props.content.columnSelectFavorites}</div>
                        </div>
                        <div className="medium-6 cell grid-padding-10 tabHeader">
                            {this.props.content.currentFields}
                        </div>
                    </div>

                    <div className="grid-x grid-padding-x fieldContainer">
                        {
                            // this.props.scc ? <div className="medium-6 cell availableField">
                            //     {
                            //         getAvailableFieldsTemplate()
                            //     }
                            // </div> :
                                <div className="medium-6 cell availableField">
                                    {
                                        this.state.showAvailableFields ? getAvailableFieldsTemplate() :
                                            <ColumnSelectFavorite
                                                selectDefaultTemplate={this.selectDefaultTemplate}
                                                selectFlightPlanningTemplate={this.selectFlightPlanningTemplate}
                                                selectInternalEnablementInsightViewTemplate={this.selectInternalEnablementInsightViewTemplate}
                                                selectExternalEnablementInsightViewTemplate={this.selectExternalEnablementInsightViewTemplate}
                                                selectCategoryInsightsViewTemplate={this.selectCategoryInsightsViewTemplate}
                                                selectMAndCviewTemplate={this.selectMAndCviewTemplate}
                                                selectEnterpriseEnablementEstimatesViewTemplate={this.selectEnterpriseEnablementEstimatesViewTemplate}
                                                selectSccEnableInsightViewTemplate={this.selectSccEnableInsightViewTemplate}
                                                selectSccViewTemplate={this.selectSccViewTemplate}
                                                selectAllSccViewTemplate={this.selectAllSccViewTemplate}
                                                selectHideEmptyColumnsTemplate={this.selectHideEmptyColumnsTemplate}
                                                selectFavoriteTemplate={this.selectFavoriteTemplate}
                                                columns={this.columns} 
                                                isSCC = {this.props.scc}/>
                                    }</div>}
                        <div className="medium-6 cell">
                            <div className="currentField">
                                {currentFieldDiv()}
                            </div>
                        </div>
                    </div>

                    <div className="grid-x">
                        <div className="medium-12">
                            <div className="float-right popupOkBtn">
                                { !this.props.scc ?
                                    <div className="float-left">
                                        {this.state.showAvailableFields ?
                                            <button onClick={this.addToFavorites} class="button button--accent" disabled={this.state.favBtnDisabled} float >
                                                <span className="button__inner">{this.props.content.addToFavorites}</span>
                                            </button>
                                            : null}
                                    </div>
                                    : null }
                                <div className="float-left">
                                    <Button
                                        type="submit"
                                        content={this.props.ui.cancel}
                                        color
                                        onClick={this.props.closePopup}
                                    />
                                </div>
                                <div className="float-left">
                                    <button onClick={this.save} class="button button--accent" disabled={this.state.okBtnDisabled} float >
                                        <span className="button__inner">{this.props.ui.ok}</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        ui: state.content.ui,
        content: state.content.spendAnalysis.detailedFile,
        user: state.default
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getFavoriteFields: (UserId) => dispatch(getFavoriteFields(UserId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ColumnFields)