// Store
//import { store } from  'index'

// Actions
import {
	updateTotalInvestment,
	updateNetBenefit,
	updateCumulativeBenefit,
	updateROIResults
} from 'actions/calculateROIActions'

// Utils
import {
	times, NPV
} from 'utils/Utils'

// Settings
import { years } from 'Constants'

// Store
let store

// Import store if not test environment
if (process.env.NODE_ENV !== 'test') {
    import('index')
    .then((index) => {
       store = index.store
    });
}

export const getTotalInvestments = (obj) => {
	let arr = []
	/*const state = store.getState(),
		  userData = state.valueStory.BusinessValueFromSap[0].ROI*/

	// Get Total Investments for all years
	obj.forEach((data, index) => {
		if (index < 4) {
			for (let i = 0; i < years; i++) {
				let val = data.UserInput[0]['Year' + (i + 1)]
				arr[i] !== undefined ? arr[i] = Number(arr[i]) + Number(val) : arr.push(Number(val))
			}
		}
		return false
	})

	//console.log('total investments', arr)

	return arr
}

export const setTotalInvestments = (obj) => {
	const arr = getTotalInvestments(obj)

	arr.forEach((data, i) => {
		// Update Total Investments in Redux
		store.dispatch(updateTotalInvestment(data, 'Year' + (i + 1)))
	})
}

export const getTotalBenefits = (totalBenefits) => {
	let arr = []
	/*const state = store.getState(),
		  userData = state.valueStory.BusinessValueFromSap[0].ROI,
		  totalBenefits = userData[4].UserInput[0]*/

	for (let i = 0; i < years; i++) {
		arr.push( Number(totalBenefits['Year' + (i + 1)]) )
	}

	return arr
}

export const getNetBenefits = () => {
	let arr = []
	const state = store.getState(),
		  userData = state.valueStory.BusinessValueFromSap[0].ROI,
		  totalBenefits = userData[4].UserInput[0],
		  totalInvestments = userData[7].UserInput[0]

	for (let i = 0; i < years; i++) {
		arr.push(Number(totalBenefits['Year' + (i + 1)] - totalInvestments['Year' + (i + 1)]) )
	}

	return arr
}

export const getCumulativeBenefits = () => {
	const state = store.getState(),
		  userData = state.valueStory.BusinessValueFromSap[0].ROI,
		  netBenefits = userData[5].UserInput[0],
		  cumulativeBenefits = userData[6].UserInput[0]

	let prevYearBenefit,
		cumulativeBenefitsArr = []

	for (let i = 0; i < years; i++) {

		i ? prevYearBenefit = Number(cumulativeBenefits['Year' + i]) : prevYearBenefit = 0

		let cumulativeBenefit = Number(netBenefits['Year' + (i + 1)] + prevYearBenefit)

		// Update to Redux
		store.dispatch(updateCumulativeBenefit(cumulativeBenefit, 'Year' + (i + 1)))

		// push to temp array
		cumulativeBenefitsArr.push(cumulativeBenefit)
	}

	return cumulativeBenefitsArr
}

export const setNetBenefits = () => {
	const arr = getNetBenefits()

	arr.forEach((data, i) => {
		// Update Net Benefit in Redux
		store.dispatch(updateNetBenefit(data, 'Year' + (i + 1)))

		// Set Cumulative Benefits
		setTimeout(() => {
			getCumulativeBenefits()
		}, 1)
	})
}

export const setNPV = () => {
	const state = store.getState(),
		  savedWACC = state.valueStory.BusinessValueToYou[0].ValueDriver[3].SubLever[1].UserInput[4].UserInput
	/*
	Net Present Value formula
	 */
	let arr = getNetBenefits(),
		npv = 0,
		WACC = 5

	if (savedWACC !== null && savedWACC !== '') WACC = savedWACC

	arr.unshift(WACC)

	npv = NPV(arr)

	//console.log('npv', npv)

	// Update to Redux
	store.dispatch(updateROIResults(npv, 'NPV'))
}

export const setROI = (obj) => {
	/*
	Sum of Net Benefits / Sum of Total Investments
	over 5 years
	 */
	const netBenefits = getNetBenefits(),
		  totalInvestments = getTotalInvestments(obj)
	let sumNetBenefits = 0,
		sumTotalInvestments = 0,
		ROI = 0

	netBenefits.forEach((data, i) => {
		sumNetBenefits += data
		sumTotalInvestments += totalInvestments[i]
	})

	sumNetBenefits && sumTotalInvestments ?
	//ROI = sumNetBenefits / sumTotalInvestments * 100 :
	ROI = Math.round(sumNetBenefits / sumTotalInvestments * 100) :
	ROI = 0

	//console.log('ROI', ROI)

	// Update to Redux
	store.dispatch(updateROIResults(ROI, 'ROIValue'))
}

export const setCostPerMonth = () => {
	const state = store.getState(),
		  userData = state.valueStory.BusinessValueFromSap[0].ROI

	/*
	Final Year Cumulative Benefit divided by total number of months
	 */
	const costPerMonth = userData[6].UserInput[0].Year5 / (years * 12)

	// Update to Redux
	store.dispatch(updateROIResults(costPerMonth, 'ValueOfDelayedInvestment'))
}

export const getMonthlyBenefits = (totalBenefits) => {
	/*
	Get monthly benefits for total no. of years
	Benefit is not realized for the first 6 months (i.e benefit is 0)
	 */
	let arr = []

	totalBenefits.forEach((data, i) => {
		times(12) (j => {
			i === 0 ? (
				j < 6 ? arr.push(0) : arr.push(data / 6)
			) : (
				arr.push(data / 12)
			)
		})
	})

	return arr
}

export const getMonthlyCumulativeBenefits = (totalBenefits) => {
	/*
	Get cumulative monthly benefits for total no. of years
	 */
	const monthlyBenefits = getMonthlyBenefits(totalBenefits)
	let arr = []

	monthlyBenefits.forEach((data, i) => {
		i < 6 ? (
			arr.push(0)
		) : (
			arr.push(data + arr[i - 1])
		)
	})

	//console.log('cumulative benefits', arr)

	return arr
}

export const getMonthlyInvestments = (obj) => {
	/*
	Get monthly investments for total no. of years
	 */
	const totalInvestments = getTotalInvestments(obj)
	let arr = []

	totalInvestments.forEach((data) => {
		times(12) (j => {
			arr.push(data / 12)
		})
	})

	return arr
}

export const getMonthlyCumulativeInvestments = (obj) => {
	/*
	Get cumulative monthly benefits for total no. of years
	 */
	const monthlyInvestments = getMonthlyInvestments(obj)
	let arr = []

	monthlyInvestments.forEach((data, i) => {
		i < 1 ? (
			arr.push(data)
		) : (
			arr.push(data + arr[i - 1])
		)
	})

	return arr
}

export const getMonthlyNetBenefits = (totalBenefits, ROI) => {
	/*
	Get Monthly Net Benefits
	Monthly Cumulative Benefit - Monthly Cumulative Investment
	 */
	const monthlyCumulativeBenefits = getMonthlyCumulativeBenefits(totalBenefits)
	const monthlyCumulativeInvestments = getMonthlyCumulativeInvestments(ROI)
	let arr = []

	monthlyCumulativeBenefits.forEach((data, i) => {
		arr.push(data - monthlyCumulativeInvestments[i])
	})

	return arr
}

export const setPaybackPeriod = (obj) => {
	/*
	First month that shows positive Net Benefit
	 */
	let netBenefits = getMonthlyNetBenefits(getTotalBenefits(obj[4].UserInput[0]), obj),
		months = 0

	for (let i = 0; i < netBenefits.length; i++) {
		if (netBenefits[i] > 0) {
			months = i + 1
			break
		} else if (netBenefits[i] === 0) {
			break
		} else {
			months = i + 1
		}
	}

	// Update to Redux
	store.dispatch(updateROIResults(months, 'Payback'))
}
