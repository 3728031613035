export const calculationsHasErrored = (bool) => {
	return {
		type: 'CALCULATION_HAS_ERRORED',
		hasErrored: bool
	}
}

export const calculationsIsLoading = (bool) => {
	return {
		type: 'CALCULATION_IS_LOADING',
		isLoading: bool
	}
}

export const calculationsFetchDataSuccess = (benchMarksData) => {
	return {
		type: 'CALCULATION_FETCH_DATA_SUCCESS',
		benchMarksData
	}
}

export const errorAfterFiveSeconds = () => {
	return (dispatch) => {
		setTimeout(() => {
			dispatch(calculationsHasErrored(true))
		}, 5000)
	}
}

export const calculationsFetchData = (url) => {
	return (dispatch) => {
		dispatch(calculationsIsLoading(true))

		return fetch(url)
			.then((response) => {
				if(!response.ok) {
					throw new Error('Bad response from server')
				}

				return response.json()
			})
			.then((benchMarksData) => dispatch(calculationsFetchDataSuccess(benchMarksData)))
			.then(() => dispatch(dispatch(calculationsIsLoading(false))))
			.catch(() => dispatch(calculationsHasErrored(true)))
	}
}
