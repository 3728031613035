import React, { Component } from 'react'

import { EnablementInsightSupplierColumns } from 'Constants'
import CustomGrid from 'UI/CustomGrid'
import { connect } from 'react-redux'
import { Toolbar, Data, Filters, Editors, Menu } from "react-data-grid-addons";
import { path } from 'Constants'
import { ControlTextE, SelectE, CheckboxE, ScreenE, PopupE, CustomGridE } from 'UI/CustomComponents/Controls';


import {
    getEnablementInsightSuppliersDetails,
    updateFlightPlanningSupplierDetails,
    selectedEnablementWaveDetails
} from 'actions/spendActions'

import { formatter, getNonEmptyValue, binarySearchIndex } from "../../../utils/Utils";
import Button from 'UI/Button';

import TextfilterRenderer from 'UI/TextFilterRenderer';
import NumericFilterRenderer from 'UI/NumericFilterRenderer';
import AutoCompleteFilterAdv from 'UI/AutoCompleteFilterAdv';
import NumericEditor from 'UI/NumericEditor';
import EnablementWavePopUp from '../EnablementWavePopUp'
import TextEditor from 'UI/TextEditor';
import DivEditor from 'UI/DivEditor'

const { DropDownEditor } = Editors;
const dropdownValues = ["Calculated", "Override"];
const dropDownEditor = <DropDownEditor options={dropdownValues} />

const dropdownFinalSupplierValues = [
    'Already Transacting', 'Relationship Exists', 'Enterprise - Supplier Dedicated Specialist (Integration & Catalog)', "Enterprise - Integration & Catalog", "Enterprise - Integration", "Enterprise - Portal & Catalog", 'Enterprise - Portal', 'Managed Standard Account', 'On Going Standard Account', 'Out of Scope'
]
const dropDownFinalSupplierEditor = <DropDownEditor options={dropdownFinalSupplierValues} />

const dropdownOnboardingWorkstreamValues = [
    'Integration Questionnaire', 'Supplier Choice','Null']
const dropdownOnboardingWorkstreamEditor = <DropDownEditor options={dropdownOnboardingWorkstreamValues} />

class EnablementSupplierDetailsPopup extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {},
            columns: [],
            aggregatedData: { INVOICE_COUNT: 0, INVOICE_SPEND: 0.0, PO_COUNT: 0, PO_SPEND: 0.0, UNIQUE_SUPPLIERS: 0 },
            industryData: {},
            selectedIndustryName: '',
            isLoading: true,
            isError: 'No',
            responseMsg: '',
            openEnablementInsightPopUp: false,
            enablementWaveInputValue: null,
            enablementWaveOverrides: {},
            filteredRows: [],
            columnDefs: [],
            
        }

        this.customGridRef = React.createRef();

        this.loadData = this.loadData.bind(this)
        this.save = this.save.bind(this)
        this.validate = this.validate.bind(this)
        this.isCellEditable = this.isCellEditable.bind(this)
        this.closeEnablementInsightPopUp = this.closeEnablementInsightPopUp.bind(this)
        this.outsideEnablementInsightPopupClick = this.outsideEnablementInsightPopupClick.bind(this)
        this.enablementWaveInput = this.enablementWaveInput.bind(this);
        this.clearEnablementWaveOverride = this.clearEnablementWaveOverride.bind(this);
    }

    componentDidMount() {
        if (this.props.isCallfromGanntChart) {
            let columns = this.initializeColumns()
            this.setState({ columns: columns, data: this.props.ganntSuppliersData })
        } else {
            this.loadData(this.props.input);
        }

    }
    componentWillReceiveProps(nextProps) {
        if (this.props.ganntSuppliersData != nextProps.ganntSuppliersData) {
            let columns = this.initializeColumns()
            this.setState({ columns: columns, data: nextProps.ganntSuppliersData, isLoading: false })
        }
    }

    loadData(inputData) {
        let columns = this.initializeColumns()

        this.props.getEnablementInsightSuppliersDetails(inputData)
            .then((response) => {
                this.setState({ columns: columns, data: response.data.Result, isLoading: false })
            }).catch((error) => {
                if (error.stack.includes('Network Error')) {
                    this.setState({ responseMsg: 'API service is down. Please contact administrator', isError: 'Yes' })
                }
                else {
                    this.setState({ responseMsg: 'An error has occurred', isError: 'Yes' })
                }
            })
    }

    initializeColumns() {
        let columns = []
        let _self = this
        for (let index = 0; index < EnablementInsightSupplierColumns.length; index++) {
            const fieldConfig = EnablementInsightSupplierColumns[index];

            let tableColumn = {
                name: fieldConfig.screen_field_name,
                key: fieldConfig.field,
                width: 150,
                sortable: true,
                editable: fieldConfig.is_editable === 'Yes',
                resizable: true,
                filterable: true,
                screenSeq: fieldConfig.screen_seq,
                mandatory: fieldConfig.mandatory === 'Yes',
                exportFl: fieldConfig.export_fl === 'Yes',
                helpText: fieldConfig.screen_help_text
            }

            if (fieldConfig.filter_type !== 'default') {
                tableColumn['filterRenderer'] = fieldConfig.data_type === 'text' ? AutoCompleteFilterAdv : NumericFilterRenderer
            } else {
                tableColumn['filterRenderer'] = TextfilterRenderer
            }

            // if (fieldConfig.field == 'SUPPLIER_ID') {
            //     tableColumn["frozen"] = true
            // }
            
            // if (fieldConfig.field == 'SUPPLIER_NAME') {
            //     tableColumn["frozen"] = true
            // }

            if (fieldConfig.field == 'SAP_ARIBA_DOC_PROXY') {
                tableColumn["editor"] = NumericEditor
            }

            if (fieldConfig.field == 'SAP_ARIBA_SPEND_PROXY') {
                tableColumn["editor"] = NumericEditor
            }

            if (fieldConfig.field == 'FINAL_SUPPLIER_ENABLE_TYPE') {
                tableColumn["editor"] = dropDownFinalSupplierEditor
            }

            if (fieldConfig.field == 'ONBOARDING_WORKSTREAM_DETAILS') {
                tableColumn["editor"] = dropdownOnboardingWorkstreamEditor
            }            
            
            if (fieldConfig.field == 'ENABLEMENT_RECOMMENDATION_OVERRIDE') {
                tableColumn["editor"] = dropDownEditor
            }
            
            if (fieldConfig.field == 'ENABLEMENT_WAVE_OVERRIDE') {
                tableColumn["editor"] = dropDownEditor
            }

            if (fieldConfig.field == 'BUYER_SUPPLIER_RELATIONSHIP') {
                tableColumn["editor"] = TextEditor
                tableColumn["max"] = 50
            }

            if (fieldConfig.field == 'ENABLEMENT_WAVE') {
                tableColumn.editProperty = 'Yes'
                //tableColumn.editor = DivEditor
                tableColumn.events = {
                    onDoubleClick: function (ev, args) {
                        _self.setState({ openEnablementInsightPopUp: true, enablementWaveInputRowNo: args.rowIdx, enablementWaveInputValue: ev.target.innerText })
                    }
                }
            }



            tableColumn.editable = tableColumn.editable && !this.props.readOnly

            tableColumn['formatter'] = formatter(fieldConfig.alignment)

            columns.push(tableColumn)
        }

        this.setState({ columnDefs: columns })
        return columns;
    }

    enablementWaveInput() {
        if (this.props.enablementWaveInputDetails !== undefined && this.props.enablementWaveInputDetails !== null) {
            this.customGridRef.onGridRowsUpdated({ fromRow: this.state.enablementWaveInputRowNo, toRow: this.state.enablementWaveInputRowNo, updated: { 'ENABLEMENT_WAVE': this.props.enablementWaveInputDetails == '' || this.props.enablementWaveInputDetails == 0 ? 1 : this.props.enablementWaveInputDetails } })
            this.props.selectedEnablementWaveDetails(null)//Clear the previous selection
            this.setState({ openEnablementInsightPopUp: !this.state.openEnablementInsightPopUp })
        }
    }

    clearEnablementWaveOverride() {
        this.customGridRef.onGridRowsUpdated({ fromRow: this.state.enablementWaveInputRowNo, toRow: this.state.enablementWaveInputRowNo, updated: { 'ENABLEMENT_WAVE': '' } })
        this.setState({ openEnablementInsightPopUp: !this.state.openEnablementInsightPopUp })
    }

    closeEnablementInsightPopUp() {
        this.setState({ openEnablementInsightPopUp: !this.state.openEnablementInsightPopUp })
    }

    outsideEnablementInsightPopupClick() {
        this.setState({ openEnablementInsightPopUp: false })
    }

    isCellEditable(e) {

        if (e.column.key === 'ENABLEMENT_WAVE' && this.state.openEnablementInsightPopUp)
            return false;

    }
   
    save(records) {
        const recordsToUpdate = [];
        const enablementWaveOverrides = this.state.enablementWaveOverrides

        for (let idx = 0; idx < records.length; idx++) {
            const row = records[idx]
            // const enablementWaveRecord = enablementWaveOverrides[item]
            const record = {
                DMC_ID: this.props.spendDetails.currentActiveRequest.DMC_ID,
                VS_ID: this.props.spendDetails.currentActiveRequest.VS_ID,
                USER_ID: this.props.user.user.Email,
                DATA_COLLECTIONS_SKEY: row["DATA_COLLECTIONS_SKEY"],
                BUYER_SUPPLIER_RELATIONSHIP: row["BUYER_SUPPLIER_RELATIONSHIP"],
                SAP_ARIBA_DOC_PROXY: row["SAP_ARIBA_DOC_PROXY"],
                SAP_ARIBA_SPEND_PROXY: row["SAP_ARIBA_SPEND_PROXY"],
                SAP_ARIBA_BUY_ENABLE_MTHD_RECMD: row["FINAL_SUPPLIER_ENABLE_TYPE"],
                ENABLEMENT_WAVE: row["ENABLEMENT_WAVE"],
                ENABLEMENT_RECOMMENDATION_OVERRIDE: row["ENABLEMENT_RECOMMENDATION_OVERRIDE"],
                ENABLEMENT_WAVE_OVERRIDE: row["ENABLEMENT_WAVE_OVERRIDE"],
                ONBOARDING_WORKSTREAM_DETAILS: row["ONBOARDING_WORKSTREAM_DETAILS"] == "Null" ? "": row["ONBOARDING_WORKSTREAM_DETAILS"]
            }

            recordsToUpdate.push(record);
        }
        this.props.onSave(true)
        return this.props.updateFlightPlanningSupplierDetails(recordsToUpdate);
    }


    validateRow(records) {
        var errorRecords = []
        const columns = this.state.columns

        for (let idx = 0; idx < records.length; idx++) {
            const row = records[idx]
            for (let idx = 0; idx < columns.length; idx++) {
                const column = columns[idx]
                if (getNonEmptyValue(row[column.key]) === '' && column.mandatory) {
                    errorRecords.push(row)
                    break;
                }
            }

        }

        return errorRecords;
    }

    validate(record, warningErrorRecords) {
        const columns = this.state.columns

        for (let idx = 0; idx < columns.length; idx++) {
            const column = columns[idx]
            if (getNonEmptyValue(record[column.key]) === '' && column.mandatory) {
                this.addWarningErroredRecord('Mandatory Field ' + column.name + ' missing', record, warningErrorRecords.errorRecords, true)
            } else {
                this.addWarningErroredRecord('Mandatory Field ' + column.name + ' missing', record, warningErrorRecords.errorRecords, false)
            }
        }

        return warningErrorRecords
    }

    addWarningErroredRecord(reason, record, records, add) {
        var arr = records[reason];
        if (getNonEmptyValue(arr) === '') {
            arr = []
        }

        var eltIdx = arr.indexOf((record['DATA_COLLECTIONS_SKEY']));
        if (eltIdx === -1 && add) {
            arr.push(record['DATA_COLLECTIONS_SKEY'])
        } else if (eltIdx !== -1 && !add) {
            arr.splice(eltIdx, 1)
        }

        records[reason] = arr;
    }

    render() {
        return (
            <div className="medium-12">
                 <div className="grid-x grid-padding-x">
                    <div className="medium-12">
                        {this.props.isCallfromGanntChart ? null : (this.state.isLoading) ?
                            <img style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={path + `images/ajax-loader.gif`} alt="" />
                            : null
                        }
                        {(this.state.isError === 'Yes') ?
                            <div style={{ color: 'red', textAlign: 'center' }} className="cell pt5">{this.state.responseMsg}</div>
                            : <div style={{ color: 'green', textAlign: 'center' }} className="cell pt5">{this.state.responseMsg}</div>
                        }
                    </div>
                </div>
                {this.props.isCallfromGanntChart ?
                    <div className="grid-x">
                        <div className="medium-12 border-bottom">
                            <div className="float-left popHeader">{this.props.input.FirstColumn_Value}</div>
                            <div className="tab-description">{this.props.input.SecondColumn_Value != null ? this.props.input.SecondColumn_Value : ''}</div>
                        </div>
                        <div className="tab-description">
                            {this.props.ganntTexts.popUpDesc}
                        </div>
                    </div>
                    : 
                    <div className="medium-12">
                       <div className="medium-12 border-bottom">
                           <div className="float-left popHeader">{this.props.input.tableName}</div>
                           <div className="tab-description">{this.props.input.column1Value != null ? this.props.input.column1Value : ''} {this.props.input.column2Value != null ?( " : " + this.props.input.column2Value) : ''} {this.props.input.column3Value != null ?( " : " + this.props.input.column3Value) : ''}</div>
                       </div>
                       <div className="tab-description">
                           {this.props.content.enablementSupplierPopupDesc}
                       </div>
                    </div>
                }

                <CustomGridE
                    onRef={ref => (this.customGridRef = ref)}
                    columns={this.state.columns}
                    data={this.state.data}
                    keyColumn="DATA_COLLECTIONS_SKEY"
                    save={this.save}
                    validate={this.validate}
                    export={true}
                    exportFileName={"Supplier Details"}
                    isCellEditable={this.isCellEditable}
                    currentFilter={true}     
                />

                <div className="medium-1 large-1">
                    <PopupE className="override-large-popup2"
                        position="right center"
                        open={this.state.openEnablementInsightPopUp}
                        onClose={this.outsideEnablementInsightPopupClick}
                        closeOnDocumentClick
                    >
                        <div>
                            <EnablementWavePopUp
                                enablementWaveInputValue={this.state.enablementWaveInputValue}
                                close={this.closeEnablementInsightPopUp}
                            />
                        </div>
                        <div className="popupOkBtn">
                            <Button onClick={this.enablementWaveInput} float type="submit" content={this.props.ui.ok} />
                            <Button onClick={this.clearEnablementWaveOverride} type="submit" content={this.props.ui.clearOverride} />
                        </div>
                    </PopupE>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        content: state.content.enablementInsight,
        ui: state.content.ui,
        currency: decodeURIComponent(state.valueStory.Currency),
        userData: state.valueStory,
        spendAsPercentageOfRevenue: state.spendAsPercentageOfRevenue,
        benchMarkCalculations: state.benchMarksData,
        allContent: state.content,
        language: state.language,
        spendDetails: state.spendDetails,
        user: state.default,
        enablementWaveInputDetails: state.spendDetails.enablementWaveInputDetails,
        ganntTexts: state.content.ganntChartTab
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getEnablementInsightSuppliersDetails: (data) => dispatch(getEnablementInsightSuppliersDetails(data)),
        updateFlightPlanningSupplierDetails: (data) => dispatch(updateFlightPlanningSupplierDetails(data)),
        selectedEnablementWaveDetails: message => dispatch(selectedEnablementWaveDetails(message)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EnablementSupplierDetailsPopup); 