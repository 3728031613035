import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Control, Form, Errors, actions } from 'react-redux-form'
import classNames from "classnames";
import { path } from 'Constants'
import moment from 'moment'
import {ControlTextE} from 'UI/CustomComponents/Controls';
// Actions
import {
    getFlightPlanningNetworkEnablementMatchTypeParameters,
    updateFlightPlanningNetworkEnablementMatchTypeParameters,
    resetError
} from 'actions/spendActions'

// Utils
import { BreakLine } from 'utils/Utils'

class FlightPlanningNetworkEnablementMatchTypes extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            networkEnablementMatchTypes: [],
            editedNetworkEnablementMatchTypes: [],
            respnseMessage: '',
            isError: false
        }
        this.loadData = this.loadData.bind(this)
        this.trackNetworkEnablementMatchTypeChanges = this.trackNetworkEnablementMatchTypeChanges.bind(this)
        this.onNetworkEnablementMatchTypeSave = this.onNetworkEnablementMatchTypeSave.bind(this)
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        this.getAllParameters();
    }

    trackNetworkEnablementMatchTypeChanges(record) {
        if (this.state.editedNetworkEnablementMatchTypes.length === 0 || this.state.editedNetworkEnablementMatchTypes.find(a => a.ARIBA_NET_ENABLE_MATCH_ID === record.ARIBA_NET_ENABLE_MATCH_ID) === undefined) {
            var updatedRecords = this.state.editedNetworkEnablementMatchTypes
            updatedRecords.push(record)
            this.setState({ respnseMessage: '', editedNetworkEnablementMatchTypes: updatedRecords, isError: false })
        }
    }

    getAllParameters() {
        this.setState({ respnseMessage: '', isError: false });
        this.props.getFlightPlanningNetworkEnablementMatchTypeParameters().then((response) => {
            if (response.status === 200) {
                this.setState({ networkEnablementMatchTypes: response.data.Result, isLoading: false, respnseMessage: '', isError: false });
            }
            else {
                this.setState({ respnseMessage: this.props.content.errorMessage, isError: true });
            }
        }).catch((error) => {
            this.setState({ respnseMessage: this.props.content.errorMessage, isError: true });
        })
    }

    onNetworkEnablementMatchTypeSave() {
        if (this.state.editedNetworkEnablementMatchTypes.find(a => a.ENABLEMENT_YIELD_PER === '') === undefined) {
            if (this.state.editedNetworkEnablementMatchTypes.find(a => a.ENABLEMENT_YIELD_PER === '-') === undefined && this.state.editedNetworkEnablementMatchTypes.find(a => a.ENABLEMENT_YIELD_PER === '-') === undefined) {
                this.setState({ isLoading: true, respnseMessage: '', isError: false });
                let email = this.props.user.user.Email
                let updatedRecords = this.state.editedNetworkEnablementMatchTypes.map(item => ({ ...item }))
                updatedRecords.map(function (parameter) {
                    parameter['UPDATED_BY'] = email
                });
                this.props.updateFlightPlanningNetworkEnablementMatchTypeParameters(updatedRecords).then((response) => {
                    if (response.status === 200) {
                        // this.state.networkEnablementMatchTypes.map(function (parameter) {
                        //     if (updatedRecords.find(a => a.ARIBA_NET_ENABLE_MATCH_ID === parameter.ARIBA_NET_ENABLE_MATCH_ID))
                        //         parameter['UPDATED_BY'] = email
                        // });
                        this.setState({ networkEnablementMatchTypes: response.data.Result, editedNetworkEnablementMatchTypes: [], isLoading: false, respnseMessage: this.props.content.successMessage, isError: false });
                    }
                    else {
                        this.setState({ isLoading: false, respnseMessage: this.props.content.errorMessage, isError: true });
                    }
                }).catch((error) => {
                    this.setState({ isLoading: false, respnseMessage: this.props.content.errorMessage, isError: true });
                })
            }
            else {
                this.setState({ respnseMessage: this.props.content.validNumber, isError: true });
            }
        }
        else {
            this.setState({ respnseMessage: this.props.content.blankValue, isError: true });
        }
    }

    componentWillUnmount() {
        this.props.resetError()
    }

    render() {
        var _instance = this
        var enablementMatchTypeParameters = this.state.networkEnablementMatchTypes.map(function (parameter) {
            return (<TableRows
                enablementMatchTypeParameter={parameter}
                trackNetworkEnablementMatchTypeChanges={_instance.trackNetworkEnablementMatchTypeChanges}
                readOnly={_instance.props.readOnly}
            />
            );
        });

        return (
            <div>
                <div>
                    <div className="grid-x">
                        <div className="medium-12">
                            <div>
                                {(this.state.isLoading) ?
                                    <img style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
                                        src={path + `images/ajax-loader.gif`} alt="" />
                                    : null
                                }
                            </div>
                            {this.state.networkEnablementMatchTypes.length > 0 ?
                                <div>
                                    <div className="grid-x">
                                        <div className="medium-5 float-left" style={{ fontSize: '1.2rem', paddingBottom: '8px' }}>{BreakLine(this.props.content.matchType)}</div>
                                        <div className="medium-7 float-left" >
                                            {this.state.isError ?
                                                <div style={{ margin: '0 0 0.5rem', color: 'red' }}>{this.state.respnseMessage}</div> :
                                                <div style={{ margin: '0 0 0.5rem', color: '#4fb81c' }}>{this.state.respnseMessage}</div>
                                            }
                                        </div>
                                    </div>
                                    <div className="grid-x">
                                        <div className="medium-11 float-left" style={{ paddingBottom: '8px' }}>{this.props.content.matchTypeDescription}</div>
                                        {this.props.user.userprofile.Roles[0].indexOf("Admin") > -1 ?
                                            <div className="medium-1" style={{ paddingBottom: '2px' }} >
                                                <button className={classNames('float-right',
                                                    {
                                                        'hand-cursor': this.state.isLoading ? false : (this.state.editedNetworkEnablementMatchTypes.length > 0),
                                                        'gray-scale': this.state.isLoading ? true : (this.state.editedNetworkEnablementMatchTypes.length === 0)
                                                    })}
                                                    onClick={this.onNetworkEnablementMatchTypeSave} disabled={this.state.isLoading ? true : this.state.editedNetworkEnablementMatchTypes.length === 0} type="submit" float >
                                                    <img width="25" title={this.props.content.save} src="images/save_active.svg" />
                                                </button>
                                            </div> : null}
                                    </div>
                                </div>
                                : null}
                        </div>
                    </div>
                    {this.state.networkEnablementMatchTypes.length > 0 ?
                        <div className="grid-x">
                            <div className="medium-12">
                                <Form
                                    model="netwrokEnablementMatchTypeParameter"
                                >
                                    <div className="grid-x basic-table">
                                        <table>
                                            <thead>
                                                <tr className="eachRow">
                                                    <th>{this.props.content.matchTypeColumnHeader}</th>
                                                    <th>{this.props.content.enablementYeildPercentage}</th>
                                                    <th>{this.props.content.updatedBy}</th>
                                                    <th>{this.props.content.updatedOn}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {enablementMatchTypeParameters}
                                            </tbody>
                                        </table>
                                    </div>
                                </Form>
                            </div>
                        </div>
                        : null}
                </div>
            </div>
        )
    }
}

class TableRows extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //parameter: this.props.enablementMatchTypeParameter,
        };
    }

    onChangeEvent(val) {
        if (val.target.value === '' || /^(\-(\d*))$/.test(val.target.value) || !isNaN(val.target.value)) {
            var editingRow = this.props.enablementMatchTypeParameter;
            let editedValue = val.target.value.trim()
            if (editedValue.toString() !== editingRow[val.target.id].toString()) {
                editingRow[val.target.id] = editedValue
                this.props.trackNetworkEnablementMatchTypeChanges(editingRow)
            }
        }
    }

    render() {
        var bindValues = this.props.enablementMatchTypeParameter;
        return (
            <tr className="eachRow">
                <td model="netwrokEnablementMatchTypeParameter.MATCH_TYPE">{bindValues.ARIBA_NET_ENABLE_MATCH_TYPE}</td>
                <td>
                    <ControlTextE
                        model="netwrokEnablementMatchTypeParameter.ENABLEMENT_YIELD_PER"
                        onChange={this.onChangeEvent.bind(this)}
                        id={'ENABLEMENT_YIELD_PER'}
                        defaultValue={bindValues.ENABLEMENT_YIELD_PER}
                        value={bindValues.ENABLEMENT_YIELD_PER}
                        type='name'
                        className="edit-textarea"
                        readOnly={this.props.readOnly}
                    />{' %'}
                </td>
                <td model="netwrokEnablementMatchTypeParameter.UpdatedBy">{bindValues.UPDATED_BY}</td>
                <td model="netwrokEnablementMatchTypeParameter.UpdatedOn">{moment(bindValues.UPDATED_ON).format('L')}</td>
            </tr>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        language: state.language,
        ui: state.content.ui,
        user: state.default,
        content: state.content.menu.EnablementParametersManager[0].MatchAndSegmentationTab.FlightPlanningNetworkEnablementMatchTypeDetails
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        getFlightPlanningNetworkEnablementMatchTypeParameters: () => dispatch(getFlightPlanningNetworkEnablementMatchTypeParameters()),
        updateFlightPlanningNetworkEnablementMatchTypeParameters: (records, userId) => dispatch(updateFlightPlanningNetworkEnablementMatchTypeParameters(records, userId)),
        resetError: () => dispatch(resetError())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FlightPlanningNetworkEnablementMatchTypes)