import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Control, Form, Errors, actions } from 'react-redux-form'
import classNames from "classnames";
import { path } from 'Constants'
import moment from 'moment'
import { ControlTextE } from 'UI/CustomComponents/Controls';
// Actions
import {
    getEnablementResourceParameters,
    getEnablementResourceParametersAdmin,
    updateEnablementResourceParameters,
    pendingChanges,
    resetError,
    isEnablementStrategyContainerPage
} from 'actions/spendActions'

// Utils
import { BreakLine, round } from 'utils/Utils'
import Popup from "reactjs-popup";

class EnablementResourceParameters extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            enablementResourceParameters: [],
            editedEnablementResourceParameters: [],
            responseMessage: '',
            isError: false,
            isExpand: false,
            //isDataLoaded: false,
        }
        this.loadData = this.loadData.bind(this)
        this.trackEnablementResourceParametersChanges = this.trackEnablementResourceParametersChanges.bind(this)
        this.onEnablementResourceParametersSave = this.onEnablementResourceParametersSave.bind(this)
    }

    // componentDidMount() {
    //     this.loadData();
    //     this.setState({ isExpand: !this.state.isExpand });
    //     this.props.isEnablementStrategyContainerPage(true);
    // }

    componentDidMount() {
        this.loadData();
        this.setState({ isExpand: true });
        this.props.isEnablementStrategyContainerPage(true)
    }

    componentDidUpdate(nextProps) {
        if (nextProps.isSaveClicked !== this.props.isSaveClicked
            || nextProps.kpiSelectedModel !== this.props.kpiSelectedModel
            || nextProps.isResourceParamDataLoaded !== this.props.isResourceParamDataLoaded
        ) {
            setTimeout(() => {
                if(nextProps.kpiSelectedModel !== this.props.kpiSelectedModel 
                    && this.props.modelClicked
                    )
                {
                    //alert(this.props.kpiSelectedModel);
                    this.getAllDefaultParameters(this.props.kpiSelectedModel);
                }
                else{
                // //if (!this.props.isResourceParamDataLoaded) {
                //     this.loadData();
                //     this.setState({ isExpand: true });
                //     this.props.isEnablementStrategyContainerPage(true);
                // //}
                this.props.handleResourceParameterChanges()
            this.onEnablementResourceParametersSave();
                }
            }, 1000);
        }
    }

    // shouldComponentUpdate(nextProps) {
    //     // if ((nextProps.isSaveClicked !== this.props.isSaveClicked
    //     //     || nextProps.kpiSelectedModel !== this.props.kpiSelectedModel)
    //     //     && nextProps.isSaveClicked) {
    //         if (nextProps.isSaveClicked !== this.props.isSaveClicked
    //                     || nextProps.kpiSelectedModel !== this.props.kpiSelectedModel
    //                     || nextProps.isResourceParamDataLoaded !== this.props.isResourceParamDataLoaded
    //                 ) {
    //         this.props.handleResourceParameterChanges()
    //         this.onEnablementResourceParametersSave();
    //     }
    //     return true
    // }

    loadData() {
        this.setState({ isExpand: false });
        this.getAllParameters();
    }

    trackEnablementResourceParametersChanges(record) {
        var updatedRecords = this.state.editedEnablementResourceParameters
        if (this.state.editedEnablementResourceParameters.length === 0)
            updatedRecords.push(record)
        else {
            let index = this.state.editedEnablementResourceParameters.findIndex(x => x.WORK_STREAM === record.WORK_STREAM);
            if (index !== undefined && index !== null && index > -1)
                updatedRecords.splice(index, 1)
            updatedRecords.push(record)
        }
        this.setState({ responseMessage: 'Pending changes. Click save to recast waves.', editedEnablementResourceParameters: updatedRecords, isError: false })
        // this.props.handleResourceParameterChanges(updatedRecords)
    }

    getAllParameters() {
        if(this.props.isResourceParamDataLoaded)
            {
            this.setState({ responseMessage: '', isLoading: true, isError: false });
            let dmcId = this.props.spendDetails.currentActiveRequest.DMC_ID;
            let vsId = this.props.spendDetails.currentActiveRequest.VS_ID;
            let user = this.props.user.user.Email;
            this.props.getEnablementResourceParameters(dmcId, vsId, user).then((response) => {
                if (response.status === 200) {
                    this.props.UpdateResourceParamDataLoaded(false);
                    this.props.EnablementStrategyrefreshPriorityTable(true);
                    //this.setState({ isDataLoaded: true });
                    if (response.data.Result.length === 0) {
                        this.setState({ responseMessage: this.props.content.reimport, isLoading: false, isError: true });
                    }
                    else {
                        var _instance = this
                        response.data.Result.map(function (rows) {
                            if (rows.WORK_STREAM != 'Enterprise - Integration')
                                _instance.trackEnablementResourceParametersChanges(rows);
                        });
                        this.setState({ enablementResourceParameters: response.data.Result, isLoading: false, responseMessage: '', isError: false });
                        //this.props.initialState(response.data.Result)
                        this.props.callbackEnablementResourceParameters(response.data.Result);
                    }
                }
                else {
                    this.setState({ responseMessage: this.props.content.errorMessage, isLoading: false, isError: true });
                }
                this.setState({ isExpand: true });
                this.props.isEnablementStrategyContainerPage(true);
            }).catch((error) => {
                this.setState({ responseMessage: this.props.content.errorMessage, isLoading: false, isError: true });
            })
        }
    }

    getAllDefaultParameters(selectedModel) {        
            this.setState({ responseMessage: '', isLoading: true, isError: false });
            let dmcId = this.props.spendDetails.currentActiveRequest.DMC_ID;
            let vsId = this.props.spendDetails.currentActiveRequest.VS_ID;
            let user = this.props.user.user.Email;
            this.props.getEnablementResourceParametersAdmin(this.props.spendDetails.solutionScope).then((response) => {
                if (response.status === 200) {
                    //this.props.UpdateResourceParamDataLoaded(false);
                    //this.props.EnablementStrategyrefreshPriorityTable(true);
                    ////this.setState({ isDataLoaded: true });
                    if (response.data.Result.length === 0) {
                        this.setState({ responseMessage: this.props.content.reimport, isLoading: false, isError: true });
                    }
                    else {
                        this.setState({ editedEnablementResourceParameters: []});
                        response.data.Result.map(function (rows) {
                            rows.SUPPLIER_WAVE_1 = rows.WAVE_1 ;
                            rows.SUPPLIER_WAVE_N = rows.WAVE_N ;
                            rows.FTES = rows.FTE ;
                            rows.WORK_STREAM = rows.RESOURCE_TYPE;
                        });
                        var _instance = this;
                        var model = selectedModel == 1 ? 'Intelligent Strategy Model' : 'KPI Based Model' ;
                        var filteredData = response.data.Result.filter(a=> a.ENABLEMENT_WAVE_STRATEGY_MODEL == model);
                        filteredData.map(function (rows) {
                            if (rows.WORK_STREAM != 'Enterprise - Integration') 
                                _instance.trackEnablementResourceParametersChanges(rows);
                        });
                        this.setState({ enablementResourceParameters: filteredData, isLoading: false, responseMessage: '', isError: false });
                        ////this.props.initialState(response.data.Result)
                        //this.props.callbackEnablementResourceParameters(filteredData);
                    }
                }
                else {
                    this.setState({ responseMessage: this.props.content.errorMessage, isLoading: false, isError: true });
                }
                this.setState({ isExpand: true });
                //this.props.isEnablementStrategyContainerPage(true);
            }).catch((error) => {
                this.setState({ responseMessage: this.props.content.errorMessage, isLoading: false, isError: true });
            })        
    }

    onEnablementResourceParametersSave() {
        if(this.props.isResourceParamDataLoaded) {
        if (this.state.editedEnablementResourceParameters.length !== 0) {
            if (this.state.editedEnablementResourceParameters.find(a => a.FTES === '') === undefined && this.state.editedEnablementResourceParameters.find(a => a.WAVE_1 === '') === undefined
                && this.state.editedEnablementResourceParameters.find(a => a.WAVE_N === '') === undefined) {
                if (this.state.editedEnablementResourceParameters.find(a => a.FTES === '-') === undefined && this.state.editedEnablementResourceParameters.find(a => a.WAVE_1 === '-') === undefined
                    && this.state.editedEnablementResourceParameters.find(a => a.WAVE_N === '-') === undefined) {
                    if (this.state.editedEnablementResourceParameters.find(a => Number(a.FTES) > 10) === undefined) {

                        this.setState({ isLoading: true, responseMessage: '', isError: false });

                        let updatedRecords = this.state.editedEnablementResourceParameters
                        let index1 = this.state.editedEnablementResourceParameters.findIndex(x => x.WORK_STREAM === 'Enterprise - Portal');
                        if (index1 > -1) {
                            let index2 = this.state.editedEnablementResourceParameters.findIndex(x => x.WORK_STREAM === 'Managed Standard Account');
                            if (index2 > -1) {
                                if (updatedRecords[index2].FTES !== updatedRecords[index1].FTES)
                                    updatedRecords[index2].FTES = updatedRecords[index1].FTES
                            }
                            else {
                                let obj = this.state.enablementResourceParameters.find(x => x.WORK_STREAM === 'Managed Standard Account');
                                if (obj.FTES !== updatedRecords[index1].FTES) {
                                    obj.FTES = updatedRecords[index1].FTES
                                    updatedRecords.push(obj)
                                }
                            }
                        }

                        let dmcId = this.props.spendDetails.currentActiveRequest.DMC_ID
                        let vsId = this.props.spendDetails.currentActiveRequest.VS_ID
                        let email = this.props.user.user.Email
                        let finalObject = [];
                        updatedRecords.map(function (parameter) {
                            let reocrd = {
                                'VSID': vsId, 'DMCID': dmcId, 'Email': email,
                                'Resource_Type': parameter.RESOURCE_TYPE, 'Account_Type': parameter.ACCOUNT_TYPE,
                                'FTEs': parameter.FTES, 'Wave_1': parameter.WAVE_1, 'Wave_n': parameter.WAVE_N
                            }
                            finalObject.push(reocrd)
                        });

                        this.props.updateEnablementResourceParameters(finalObject, this.props.kpiSelectedModel).then((response) => {
                            if (response.status === 200) {
                                this.loadData();
                                this.props.pendingChanges(null);
                                this.props.handleIsResourceParamterSaved(true);
                                // this.props.UpdateResourceParamDataLoaded(false);
                                // this.props.EnablementStrategyrefreshPriorityTable(true);
                                this.setState({ enablementResourceParameters: response.data.Result, editedEnablementResourceParameters: [], isLoading: false, responseMessage: this.props.content.successMessage, isError: false });
                            }
                            else {
                                this.setState({ isLoading: false, responseMessage: this.props.content.errorMessage, isError: true });
                            }
                        }).catch((error) => {
                            this.setState({ isLoading: false, responseMessage: this.props.content.errorMessage, isError: true });
                        })
                    }
                    else {
                        this.setState({ responseMessage: this.props.content.maxFTE, isError: true });
                    }
                }
                else {
                    this.setState({ responseMessage: this.props.content.validNumber, isError: true });
                }
            }
            else {
                this.setState({ responseMessage: this.props.content.blankValue, isError: true });
            }
        }
        else{
        this.loadData();
        }
    }
    }

    componentWillUnmount() {
        this.props.resetError();
        setTimeout(() => {
            this.props.isEnablementStrategyContainerPage(false);
        }, 1000);        
    }

    onExpandClick(e) {
        this.setState({ isExpand: !this.state.isExpand })
    }

    render() {
        var _instance = this
        var isAdmin = this.props.user.userprofile.Roles[0].indexOf("Admin") > -1 ? true : false;
        var resourceParameters = this.state.enablementResourceParameters.map(function (parameter, index) {
            if (parameter.RESOURCE_TYPE === 'Seller Integration Lead' && parameter.WORK_STREAM === 'Enterprise - Integration') { }
            else
                return (<TableRows
                    enablementResourceParameter={parameter}
                    index={index}
                    isAdmin={isAdmin}
                    pendingChanges={_instance.props.pendingChanges}
                    trackEnablementResourceParametersChanges={_instance.trackEnablementResourceParametersChanges}
                />
                );
        });

        return (
            <div className="grid-x medium-12">
                <div className="grid-x medium-12 heading-with-below-border">
                    <div className="medium-11 float-left">{this.props.content.title}
                        <span>
                            <Popup className="report-tooltip"
                                trigger={<img style={{ marginLeft: '5px', paddingBottom: '20px' }} className="info-icon" src="images/info-icon.svg" width="15" />}
                                position="bottom left"
                                on="hover"
                            >
                                <div>
                                    {this.props.kpiSelectedModel == 1 ? this.props.content.tabDesc
                                        : this.props.content.tabDescKPI}
                                </div>
                            </Popup>
                        </span>
                    </div>
                    <div className="medium-1" style={{ textAlign: 'right', cursor: 'pointer', fontSize: '1.6rem' }} onClick={(e) => this.onExpandClick(e)} >{this.state.isExpand ? "-" : "+"}</div>
                </div>

                {this.state.isExpand ?
                    <div className="grid-x medium-12">
                        {/* <div className="medium-12 float-left pt10">{this.props.kpiSelectedModel == 1 ? this.props.content.tabDesc
                        : this.props.content.tabDescKPI
                        }</div> */}
                        <div className="medium-12 pt10">
                            <div className="grid-x">
                                <div className="medium-12" >
                                    <div className="grid-x">
                                        <div className="medium-12 flex-container">
                                            {(this.state.isLoading) ?
                                                <img style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
                                                    src={path + `images/ajax-loader.gif`} alt="" />
                                                :
                                                this.state.isError ?
                                                    <div style={{ margin: '0 0 0.5rem', color: 'red' }}>{this.state.responseMessage}</div> :
                                                    <div style={{ margin: '0 0 0.5rem', color: '#4fb81c' }}>{this.state.responseMessage}</div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="medium-2 pb5">
                            <button className={classNames('float-right',
                                {
                                    'hand-cursor': this.state.isLoading ? false : (this.state.editedEnablementResourceParameters.length > 0),
                                    'gray-scale': this.state.isLoading ? true : (this.state.editedEnablementResourceParameters.length === 0)
                                })}
                                onClick={this.onEnablementResourceParametersSave}
                                disabled={this.state.isLoading ? true : this.state.editedEnablementResourceParameters.length === 0} type="submit" float >
                                {this.state.enablementResourceParameters.length > 0 ?
                                    <img width="25" title={this.props.content.save} src="images/save_active.svg" />
                                    : null}
                            </button>
                        </div> */}
                                {/* <div className="medium-1"></div> */}
                            </div>
                        </div>
                        {this.state.enablementResourceParameters.length > 0 ?
                            <div className="medium-12">
                                <div className="grid-x ">
                                    <div className="medium-12">
                                        <Form model="netwrokEnablementMatchTypeParameter">
                                            <div className="basic-table">
                                                <table>
                                                    <thead>
                                                        <tr className="eachRow">
                                                            <th>{this.props.content.resourceType}</th>
                                                            <th>{this.props.content.accountType}</th>
                                                            <th><span style={{ paddingLeft: '1.5rem' }}></span>{''}</th>
                                                            <th style={{ cursor: 'pointer' }} title='For fractional numbers use a leading 0.  Example: 0.5'>
                                                                {this.props.content.FTEs}
                                                            </th>
                                                            <th>{this.props.content.wave_1}</th>
                                                            <th>{this.props.content.wave_n}</th>
                                                            <th>{this.props.content.suppliersPerWave_1}</th>
                                                            <th>{this.props.content.suppliersPerWave_n}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {resourceParameters}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                            : null}
                    </div>
                    : null}
            </div>
        )
    }
}

class TableRows extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    onChangeEvent(val) {
        if (val.target.value === '' || !isNaN(val.target.value)) {
            if (val.target.id === "FTES") {
                var editingRow = this.props.enablementResourceParameter;
                let editedValue = val.target.value.trim()
                if (editedValue.toString() !== editingRow[val.target.id].toString()) {
                    editingRow[val.target.id] = editedValue
                    this.props.trackEnablementResourceParametersChanges(editingRow)
                    this.props.pendingChanges(true)
                }
            }
            else {
                if (val.target.value === '' || val.target.value.match(/^[0-9]+$/)) { //No decimals
                    var editingRow = this.props.enablementResourceParameter;
                    let editedValue = val.target.value.trim()
                    if (editedValue.toString() !== editingRow[val.target.id].toString()) {
                        editingRow[val.target.id] = editedValue
                        this.props.trackEnablementResourceParametersChanges(editingRow)
                        this.props.pendingChanges(true)
                    }
                }
            }
        }
    }

    render() {
        var bindValues = this.props.enablementResourceParameter;
        return (
            <tr className="eachRow">
                {this.props.index === 0 ?
                    <td rowspan="2" model="enablementResourceParameter.RESOURCE_TYPE">{bindValues.RESOURCE_TYPE}</td>
                    :
                    this.props.index !== 1 ?
                        <td model="enablementResourceParameter.RESOURCE_TYPE">{bindValues.RESOURCE_TYPE}</td>
                        :
                        null
                }
                <td model="enablementResourceParameter.ACCOUNT_TYPE">{bindValues.ACCOUNT_TYPE}</td>
                {this.props.index === 0 ?
                    <td rowspan="2"><span style={{ paddingLeft: '1.5rem' }}></span>{''}</td>
                    :
                    this.props.index !== 1 ?
                        <td><span style={{ paddingLeft: '1.5rem' }}></span>{''}</td>
                        : null
                }
                {this.props.index === 0 ?
                    <td rowspan="2">
                        <ControlTextE
                            model="enablementResourceParameter.FTES"
                            onChange={this.onChangeEvent.bind(this)}
                            id={'FTES'}
                            defaultValue={bindValues.FTES}
                            value={bindValues.FTES}
                            type='name'
                            className="edit-textarea edit-width"
                        />
                        <span>
                            <Popup className="report-tooltip"
                                trigger={<img style={{ marginLeft: '5px', paddingBottom: '15px' }} className="info-icon" src="images/info-icon.svg" width="15" />}
                                position="bottom left"
                                on="hover"
                            >
                                <div>
                                    {"For fractional numbers use a leading 0.  Example: 0.5"}
                                </div>
                            </Popup>
                        </span>
                    </td>

                    :
                    this.props.index !== 1 ?
                        <td>
                            <ControlTextE
                                model="enablementResourceParameter.FTES"
                                onChange={this.onChangeEvent.bind(this)}
                                id={'FTES'}
                                defaultValue={bindValues.FTES}
                                value={bindValues.FTES}
                                type='name'
                                className="edit-textarea edit-width"
                            />
                            <span>
                                <Popup className="report-tooltip"
                                    trigger={<img style={{ marginLeft: '5px', paddingBottom: '15px' }} className="info-icon" src="images/info-icon.svg" width="15" />}
                                    position="bottom left"
                                    on="hover"
                                >
                                    <div>
                                        {"For fractional numbers use a leading 0.  Example: 0.5"}
                                    </div>
                                </Popup>
                            </span>
                        </td>
                        : null
                }
                {
                    // this.props.isAdmin ?
                    <td>
                        <ControlTextE
                            model="enablementResourceParameter.WAVE_1"
                            onChange={this.onChangeEvent.bind(this)}
                            id={'WAVE_1'}
                            defaultValue={bindValues.WAVE_1}
                            value={bindValues.WAVE_1}
                            type='name'
                            className="edit-textarea edit-width"
                        />
                    </td>
                    // :
                    // <td model="enablementResourceParameter.WAVE_1">{bindValues.WAVE_1}</td>
                }
                {
                    // this.props.isAdmin ?
                    <td>
                        <ControlTextE
                            model="enablementResourceParameter.WAVE_N"
                            onChange={this.onChangeEvent.bind(this)}
                            id={'WAVE_N'}
                            defaultValue={bindValues.WAVE_N}
                            value={bindValues.WAVE_N}
                            type='name'
                            className="edit-textarea edit-width"
                        />
                    </td>
                    //:
                    //<td model="enablementResourceParameter.WAVE_N">{bindValues.WAVE_N}</td>
                }
                <td model="enablementResourceParameter.SUPPLIER_WAVE_1">{bindValues.SUPPLIER_WAVE_1}</td>
                <td model="enablementResourceParameter.SUPPLIER_WAVE_N">{bindValues.SUPPLIER_WAVE_N}</td>
            </tr>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        language: state.language,
        ui: state.content.ui,
        user: state.default,
        spendDetails: state.spendDetails,
        content: state.content.spendAnalysis.enablementResourceParamaeters,
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        getEnablementResourceParameters: (DMCId, VSId, userEmail) => dispatch(getEnablementResourceParameters(DMCId, VSId, userEmail)),
        getEnablementResourceParametersAdmin: (soltionInScope) => dispatch(getEnablementResourceParametersAdmin(soltionInScope)),
        updateEnablementResourceParameters: (records, model) => dispatch(updateEnablementResourceParameters(records, model)),
        pendingChanges: (changes) => dispatch(pendingChanges(changes)),
        resetError: () => dispatch(resetError()),
        isEnablementStrategyContainerPage: obj => dispatch(isEnablementStrategyContainerPage(obj))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EnablementResourceParameters)