import React from 'react'

const SubLeverCalculationTableHeaders = (props) =>
	<tr>
		{props.content.map((data, i) =>
			data != 'Formulae' ?
				<th key={i}>{data}</th> :
				<th key={i} value={data}>
					{props.flag ? <button className='formulaePlus' onClick={props.show}>+</button> :
						<button className='formulaeMinus' onClick={props.hide}>-</button>
					}
				</th>
		)}
	</tr>

export default SubLeverCalculationTableHeaders