import React from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'
import Button from 'UI/Button'
import { MultiSelect } from 'primereact/multiselect';
import Popup from "reactjs-popup";
import {
    enablementInsightSuccess,
    enablementInsightFailure,
    enablementInsightLoading,
    resetError,
    getEnablementFiltersDetails,
    storeEnablementInsightSelectedFilter,
    storeUserSpendSelection,
    trackUserSpendSelections
} from 'actions/spendActions'

class EnablementInsightTabFilters extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

            selectedDedupeStats: '',
            selectedEnabledCustomer: '',
            selectedTransactingCustomer: '',
            dedupeStatsOption: [
                { label: "All", value: "all" },
                { label: "Unique", value: "Unique" },
                { label: "Duplicated", value: "Duplicated" }
            ],
            enabledCustomer: [
                { label: "All", value: "all" },
                { label: "Yes", value: "Y" },
                { label: "No", value: "N" }
            ],
            transactingCustomer: [
                { label: "All", value: "all" },
                { label: "Yes", value: "Y" },
                { label: "No", value: "N" }
            ],
            metricsTypeOption: [
                { label: "Invoice & PO", value: "Invoice & PO" },
                { label: "Invoice Only", value: "Invoice Only" },
                { label: "PO Only", value: "PO Only" },  
                { label: "Spend & Document Proxies", value: "Spend & Document Proxies" }               
            ],
            directSpendOptions: [
				{ label: "All", value: "all" },
				{ label: "Yes", value: "Yes" },
				{ label: "No", value: "No" }
			],
            buyerOption: [],
            businessUnitOption: [],
            erpSystemOption: [],
            l4IndustryOption: [],
            erpCodeOption: [],
            erpDescOption: [],
            sicCodeOption: [],
            sicDescOption: [],
            goodsOption: [],
            invoiceCountryOption: [],
            legalInvoiceOption: [],
            aribaNetworkEligibleOption: [],

            enablementInsightFilters: {
                DMC_ID: this.props.spendDetails !== undefined && this.props.spendDetails.currentActiveRequest !== undefined ? this.props.spendDetails.currentActiveRequest.DMC_ID : 0
                , VS_ID: this.props.spendDetails !== undefined && this.props.spendDetails.currentActiveRequest !== undefined ? this.props.spendDetails.currentActiveRequest.VS_ID : 0,
                User_Email: this.props.spendDetails.currentActiveRequest !== undefined &&
                    this.props.spendDetails.currentActiveRequest.SAPRequester === this.props.user.user.Email ? this.props.user.user.Email : this.props.spendDetails.currentActiveRequest.SAPRequester,

                dedupStats: 'Unique',
                enabledCustomer: 'all',
                transactingCustomer: 'all',
                buyerRegion: ['all'],
                businessUnit: ['all'],
                erpSystem: ['all'],
                l4IndustryName: ['all'],
                erpCode: ['all'],
                erpDesc: ['all'],
                sicCode: ['all'],
                sicDesc: ['all'],

                goods: ['all'],
                invoiceCountry: ['all'],
                legalInvoice: ['all'],
                aribaNwEligible: ['all'],
                metricsType: 'Invoice & PO',
                directSpend: 'all',

            },

            inputParam: {
                DMC_ID: this.props.spendDetails !== undefined && this.props.spendDetails.currentActiveRequest !== undefined ? this.props.spendDetails.currentActiveRequest.DMC_ID : 0
                , VS_ID: this.props.spendDetails !== undefined && this.props.spendDetails.currentActiveRequest !== undefined ? this.props.spendDetails.currentActiveRequest.VS_ID : 0,
                User_Email: this.props.spendDetails.currentActiveRequest !== undefined &&
                    this.props.spendDetails.currentActiveRequest.SAPRequester === this.props.user.user.Email ? this.props.user.user.Email : this.props.spendDetails.currentActiveRequest.SAPRequester,
                dedupStats: 'Unique',
                enabledCustomer: 'all',
                transactingCustomer: 'all',
                buyerRegion: ['all'],
                businessUnit: ['all'],
                erpSystem: ['all'],
                l4IndustryName: ['all'],
                erpCode: ['all'],
                erpDesc: ['all'],
                sicCode: ['all'],
                sicDesc: ['all'],
                goods: ['all'],
                invoiceCountry: ['all'],
                legalInvoice: ['all'],
                aribaNwEligible: ['all'],
                metricsType: 'Invoice & PO',
                directSpend: 'all',
            },
            businessUnitFilter: 'all',
            buyerRegionFilter: 'all',
            l4IndustryFilter: 'all',
            erpSystemFilter: 'all',
            erpCodeFilter: 'all',
            erpDescFilter: 'all',
            sicCodeFilter: 'all',
            sicDescFilter: 'all',
            goodsFilter: 'all',
            invoiceCountryFilter: 'all',
            legalInvoiceFilter: 'all',
            aribaNwEligibleFilter: 'all',
            isFilterChanges: false,

        }
    }

    handleDedupeStatsChange(data) {
        //this.setState({selectedDedupeStats:value.label})

        var objFilters = this.state.enablementInsightFilters
        objFilters.dedupStats = data.value
        this.setState({ enablementInsightFilters: objFilters, isFilterChanges: true })
        //console.log("EnablementFilters: ", this.state.enablementInsightFilters)
    }

    handleEnabledCustomerChange(data) {
        //this.setState({selectedEnabledCustomer: value.label})

        var objFilters = this.state.enablementInsightFilters
        objFilters.enabledCustomer = data.value
        this.setState({ enablementInsightFilters: objFilters, isFilterChanges: true })
    }

    handleTransactCustomerChange(data) {
        //this.setState({selectedTransactingCustomer: value.label})

        var objFilters = this.state.enablementInsightFilters
        objFilters.transactingCustomer = data.value
        this.setState({ enablementInsightFilters: objFilters, isFilterChanges: true })
    }

    handleMetricsTypeChange(data) {
        //this.setState({selectedTransactingCustomer: value.label})

        var objFilters = this.state.enablementInsightFilters
        objFilters.metricsType = data.value
        this.setState({ enablementInsightFilters: objFilters, isFilterChanges: true })
    }

    handleBuyerRegionChange(data) {
        var objFilters = this.state.enablementInsightFilters
        objFilters.buyerRegion = data.value.length > 0 ? data.value : ['all'];
        if (data.value.length > 1 && data.value[0] == 'all') {
            data.value.shift()
            objFilters.buyerRegion = data.value
        }
        if (data.value.length > 1 && data.value.includes('all')) {
            objFilters.buyerRegion = [];
            objFilters.buyerRegion = ['all'];
        }
        let str = objFilters.buyerRegion.toString();
        this.setState({ enablementInsightFilters: objFilters, buyerRegionFilter: str, isFilterChanges: true })
    }

    handleBusinessUnitChange(data) {
        var objFilters = this.state.enablementInsightFilters
        objFilters.businessUnit = data.value.length > 0 ? data.value : ['all'];
        if (data.value.length > 1 && data.value[0] == 'all') {
            data.value.shift()
            objFilters.businessUnit = data.value
        }
        if (data.value.length > 1 && data.value.includes('all')) {
            objFilters.businessUnit = [];
            objFilters.businessUnit = ['all'];
        }
        let str = objFilters.businessUnit.toString();
        this.setState({ enablementInsightFilters: objFilters, businessUnitFilter: str, isFilterChanges: true })
    }

    handleErpSystemChange(data) {
        var objFilters = this.state.enablementInsightFilters
        objFilters.erpSystem = data.value.length > 0 ? data.value : ['all'];
        if (data.value.length > 1 && data.value[0] == 'all') {
            data.value.shift()
            objFilters.erpSystem = data.value
        }
        if (data.value.length > 1 && data.value.includes('all')) {
            objFilters.erpSystem = [];
            objFilters.erpSystem = ['all'];
        }
        let str = objFilters.erpSystem.toString();
        this.setState({ enablementInsightFilters: objFilters, erpSystemFilter: str, isFilterChanges: true })
    }

    handleL4IndustryChange(data) {
        var objFilters = this.state.enablementInsightFilters
        objFilters.l4IndustryName = data.value.length > 0 ? data.value : ['all'];
        
        let str;
        if(data.value.length == this.state.l4IndustryOption.length)
        {
            str = 'all';
        }
        else{
            str = objFilters.l4IndustryName.toString();
        }
        this.setState({ enablementInsightFilters: objFilters, l4IndustryFilter: str, isFilterChanges: true })
    }

    handleErpCodeChange(data) {
        var objFilters = this.state.enablementInsightFilters
        objFilters.erpCode = data.value.length > 0 ? data.value : ['all'];
       
        let str;
        if(data.value.length == this.state.erpCodeOption.length)
        {
            str = 'all';
        }
        else{
            str = objFilters.erpCode.toString();
        }
        this.setState({ enablementInsightFilters: objFilters, erpCodeFilter: str, isFilterChanges: true })
    }

    handleErpDescChange(data) {
        var objFilters = this.state.enablementInsightFilters
        objFilters.erpDesc = data.value.length > 0 ? data.value : ['all'];
        
        let str;
        if(data.value.length == this.state.erpDescOption.length)
        {
            str= 'all';
        }
        else{
            str = objFilters.erpDesc.toString();
        }
        this.setState({ enablementInsightFilters: objFilters, erpDescFilter: str, isFilterChanges: true })
    }

    handleSicCodeChange(data) {
        var objFilters = this.state.enablementInsightFilters
        objFilters.sicCode = data.value.length > 0 ? data.value : ['all'];
        if (data.value.length > 1 && data.value[0] == 'all') {
            data.value.shift()
            objFilters.sicCode = data.value
        }
        if (data.value.length > 1 && data.value.includes('all')) {
            objFilters.sicCode = [];
            objFilters.sicCode = ['all'];
        }
        let str = objFilters.sicCode.toString();
        this.setState({ enablementInsightFilters: objFilters, sicCodeFilter: str, isFilterChanges: true })
    }

    handleSicDescChange(data) {
        var objFilters = this.state.enablementInsightFilters
        objFilters.sicDesc = data.value.length > 0 ? data.value : ['all'];
        if (data.value.length > 1 && data.value[0] == 'all') {
            data.value.shift()
            objFilters.sicDesc = data.value
        }
        if (data.value.length > 1 && data.value.includes('all')) {
            objFilters.sicDesc = [];
            objFilters.sicDesc = ['all'];
        }
        let str = objFilters.sicDesc.toString();
        this.setState({ enablementInsightFilters: objFilters, sicDescFilter: str, isFilterChanges: true })
    }

    handleGoodsChange(data) {
        var objFilters = this.state.enablementInsightFilters
        objFilters.goods = data.value.length > 0 ? data.value : ['all'];
        if (data.value.length > 1 && data.value[0] == 'all') {
            data.value.shift()
            objFilters.goods = data.value
        }
        if (data.value.length > 1 && data.value.includes('all')) {
            objFilters.goods = [];
            objFilters.goods = ['all'];
        }
        let str = objFilters.goods.toString();
        this.setState({ enablementInsightFilters: objFilters, goodsFilter: str, isFilterChanges: true })
    }

    handleDirectSpendChange(data) {
		var objFilters = this.state.enablementInsightFilters
		objFilters.directSpend = data.value 
        this.setState({ enablementInsightFilters: objFilters, isFilterChanges:true })                
    }

    handleLegalInvoiceChange(data) {
        var objFilters = this.state.enablementInsightFilters
        objFilters.legalInvoice = data.value.length > 0 ? data.value : ['all'];
        if (data.value.length > 1 && data.value[0] == 'all') {
            data.value.shift()
            objFilters.legalInvoice = data.value
        }
        if (data.value.length > 1 && data.value.includes('all')) {
            objFilters.legalInvoice = [];
            objFilters.legalInvoice = ['all'];
        }
        let str = objFilters.legalInvoice.toString();
        this.setState({ enablementInsightFilters: objFilters, legalInvoiceFilter: str, isFilterChanges: true })
    }

    handleInvoiceCountryChange(data) {
        var objFilters = this.state.enablementInsightFilters
        objFilters.invoiceCountry = data.value.length > 0 ? data.value : ['all'];
       
        let str;
        if(data.value.length == this.state.invoiceCountryOption.length)
        {
            str = 'all';
        }
        else{
            str = objFilters.invoiceCountry.toString();
        }
        this.setState({ enablementInsightFilters: objFilters, invoiceCountryFilter: str, isFilterChanges: true })
    }

    handleAribaNetworkEligibleChange(data) {
        var objFilters = this.state.enablementInsightFilters
        objFilters.aribaNwEligible = data.value.length > 0 ? data.value : ['all'];
        if (data.value.length > 1 && data.value[0] == 'all') {
            data.value.shift()
            objFilters.aribaNwEligible = data.value
        }
        if (data.value.length > 1 && data.value.includes('all')) {
            objFilters.aribaNwEligible = [];
            objFilters.aribaNwEligible = ['all'];
        }

        let str = objFilters.aribaNwEligible.toString();

        if (str.includes("Not B&I, CA or SCC Eligible")) {
            //replace comma (,) from (~~) in datavalue to consider it as single value 
            str = str.replace("Not B&I, CA or SCC Eligible", "Not B&I~~ CA or SCC Eligible")
        }

        this.setState({ enablementInsightFilters: objFilters, aribaNwEligibleFilter: str, isFilterChanges: true })
    }


    showAdvanceFilter() {
        var advanceFilterDiv = document.getElementById('advanceFilter').style;
        if (advanceFilterDiv.display === "none") {
            advanceFilterDiv.display = "block";
        } else {
            advanceFilterDiv.display = "none";
        }
    }

    handleApplyFilter() {
        //this.props.storeEnablementInsightSelectedFilter(this.state.enablementInsightFilters)
        var objFilters = this.state.inputParam
        objFilters.buyerRegion = this.state.buyerRegionFilter
        objFilters.businessUnit = this.state.businessUnitFilter
        objFilters.erpSystem = this.state.erpSystemFilter
        objFilters.l4IndustryName = this.state.l4IndustryFilter
        objFilters.erpCode = this.state.erpCodeFilter
        objFilters.erpDesc = this.state.erpDescFilter
        objFilters.sicCode = this.state.sicCodeFilter
        objFilters.sicDesc = this.state.sicDescFilter
        objFilters.goods = this.state.goodsFilter
        objFilters.invoiceCountry = this.state.invoiceCountryFilter
        objFilters.legalInvoice = this.state.legalInvoiceFilter
        objFilters.aribaNwEligible = this.state.aribaNwEligibleFilter
        objFilters.dedupStats = this.state.enablementInsightFilters.dedupStats
        objFilters.enabledCustomer = this.state.enablementInsightFilters.enabledCustomer
        objFilters.transactingCustomer = this.state.enablementInsightFilters.transactingCustomer
        objFilters.metricsType = this.state.enablementInsightFilters.metricsType
        objFilters.directSpend = this.state.enablementInsightFilters.directSpend

        //this.setState({ enablementInsightFilters: objFilters})
        let selectedFilter = Object.assign({}, objFilters)//this.state.enablementInsightFilters)
        this.props.setFilters(selectedFilter)
        this.setState({ isFilterChanges: false})

        var userEmail = this.props.spendDetails.currentActiveRequest.SAPRequester === this.props.user.user.Email ? this.props.user.user.Email : this.props.spendDetails.currentActiveRequest.SAPRequester;
        var VSId = this.props.spendDetails.currentActiveRequest.VS_ID
        var DMCId = this.props.spendDetails.currentActiveRequest.DMC_ID
        this.props.storeUserSpendSelection(VSId, DMCId, 'Enablement Insight', 'Enablement Insight', 'Metric Type', this.state.enablementInsightFilters.metricsType, userEmail)
            .then((response) => {
                this.props.trackUserSpendSelections(response.data.Result)
            }).catch((error) => {
                this.props.enablementInsightFailure(error.response.data.Message)
            })
    }

    handleResetFilter() {
        var objFilters = this.state.enablementInsightFilters
        objFilters.dedupStats = 'Unique'
        objFilters.enabledCustomer = 'all'
        objFilters.transactingCustomer = 'all'
        objFilters.buyerRegion = ['all']
        objFilters.businessUnit = ['all']
        objFilters.erpSystem = ['all']
        objFilters.l4IndustryName = ['all']
        objFilters.erpCode = ['all']
        objFilters.erpDesc = ['all']
        objFilters.sicCode = ['all']
        objFilters.sicDesc = ['all']
        objFilters.goods = ['all']
        objFilters.legalInvoice = ['all']
        objFilters.invoiceCountry = ['all']
        objFilters.aribaNwEligible = ['all']
        objFilters.metricsType = 'Invoice & PO'
        objFilters.directSpend = 'all'

        var objInput = this.state.inputParam
        objInput.dedupStats = 'Unique'
        objInput.enabledCustomer = 'all'
        objInput.transactingCustomer = 'all'
        objInput.buyerRegion = 'all'
        objInput.businessUnit = 'all'
        objInput.erpSystem = 'all'
        objInput.l4IndustryName = 'all'
        objInput.erpCode = 'all'
        objInput.erpDesc = 'all'
        objInput.sicCode = 'all'
        objInput.sicDesc = 'all'
        objInput.goods = 'all'
        objInput.legalInvoice = 'all'
        objInput.invoiceCountry = 'all'
        objInput.aribaNwEligible = 'all'
        objInput.metricsType = 'Invoice & PO'
        objFilters.directSpend = 'all'

        this.setState({
            enablementInsightFilters: objFilters, inputParam: objInput, isFilterChanges: false,
            buyerRegionFilter: 'all',
            businessUnitFilter: 'all',
            erpSystemFilter: 'all',
            l4IndustryFilter: 'all',
            erpCodeFilter: 'all',
            erpDescFilter: 'all',
            sicCodeFilter: 'all',
            sicDescFilter: 'all',
            goodsFilter: 'all',
            invoiceCountryFilter: 'all',
            legalInvoiceFilter: 'all',
            aribaNwEligibleFilter: 'all',
        }, () => this.props.setFilters(objInput))

        var userEmail = this.props.spendDetails.currentActiveRequest.SAPRequester === this.props.user.user.Email ? this.props.user.user.Email : this.props.spendDetails.currentActiveRequest.SAPRequester;
        var VSId = this.props.spendDetails.currentActiveRequest.VS_ID
        var DMCId = this.props.spendDetails.currentActiveRequest.DMC_ID
        this.props.storeUserSpendSelection(VSId, DMCId, 'Enablement Insight', 'Enablement Insight', 'Metric Type', 'Invoice Spend', userEmail)
            .then((response) => {
                this.props.trackUserSpendSelections(response.data.Result)
            }).catch((error) => {
                this.props.enablementInsightFailure(error.response.data.Message)
            })
    }

    componentDidMount() {
        this.loadFiltersData()
    }

    loadFiltersData() {

        this.props.getEnablementFiltersDetails(this.state.inputParam)
            .then((response) => {
                if (response !== undefined && response.status === 200 && response.data.Result !== undefined) {
                    //	setTimeout(() => { this.props.defineScopeSuccess('') }, 2000)

                    var l4IndustryData = [];// [{ label: "All", value: "all" }];
                    if (response.data.Result['L4_INDUSTRY_NAME'] !== null) {
                        response.data.Result['L4_INDUSTRY_NAME'].forEach(item => {
                            let value = Object.values(item)
                            l4IndustryData.push({ label: value[0], value: value[0] });
                        });
                    }

                    var buyerData = [{ label: "All Buyer Regions", value: "all" }];
                    if (response.data.Result['BUYER_REGION'] !== null) {
                        response.data.Result['BUYER_REGION'].forEach(item => {
                            let value = Object.values(item)
                            buyerData.push({ label: value[0], value: value[0] });
                        });
                    }

                    var businessUnitData = [{ label: "All Business Units", value: "all" }];
                    if (response.data.Result['BUSINESS_UNIT'] !== null) {
                        response.data.Result['BUSINESS_UNIT'].forEach(item => {
                            let value = Object.values(item)
                            businessUnitData.push({ label: value[0], value: value[0] });
                        });
                    }

                    var erpData = [{ label: "All", value: "all" }];
                    if (response.data.Result['ERP_SYSTEM'] !== null) {
                        response.data.Result['ERP_SYSTEM'].forEach(item => {
                            let value = Object.values(item)
                            erpData.push({ label: value[0], value: value[0] });
                        });
                    }

                    var erpCodeData = [];//[{ label: "All", value: "all" }];
                    if (response.data.Result['ERP_COMMODITY_CODE1'] !== null) {
                        response.data.Result['ERP_COMMODITY_CODE1'].forEach(item => {
                            let value = Object.values(item)
                            erpCodeData.push({ label: value[0], value: value[0] });
                        });
                    }

                    var erpDescData = [];//[{ label: "All", value: "all" }];
                    if (response.data.Result['ERP_COMMODITY_CODE2'] !== null) {
                        response.data.Result['ERP_COMMODITY_CODE2'].forEach(item => {
                            let value = Object.values(item)
                            erpDescData.push({ label: value[0], value: value[0] });
                        });
                    }

                    var sicCodeData = [{ label: "All", value: "all" }];
                    if (response.data.Result['SIC_UNSPSC_CODE'] !== null) {
                        response.data.Result['SIC_UNSPSC_CODE'].forEach(item => {
                            let value = Object.values(item)
                            sicCodeData.push({ label: value[0], value: value[0] });
                        });
                    }

                    var sicDescData = [{ label: "All", value: "all" }];
                    if (response.data.Result['SIC_UNSPSC_DESC'] !== null) {
                        response.data.Result['SIC_UNSPSC_DESC'].forEach(item => {
                            let value = Object.values(item)
                            sicDescData.push({ label: value[0], value: value[0] });
                        });
                    }

                    var goodsData = [{ label: "All", value: "all" }];
                    if (response.data.Result['GOODS_OR_SERVICE'] !== null) {
                        response.data.Result['GOODS_OR_SERVICE'].forEach(item => {
                            let value = Object.values(item)
                            goodsData.push({ label: value[0], value: value[0] });
                        });
                    }


                    var invCountryData = [];//[{ label: "All", value: "all" }];
                    if (response.data.Result['INV_ORIGINATION_CNTRY'] !== null) {
                        response.data.Result['INV_ORIGINATION_CNTRY'].forEach(item => {
                            let value = Object.values(item)
                            invCountryData.push({ label: value[0], value: value[0] });
                        });
                    }


                    var legalInvoiceData = [{ label: "All", value: "all" }];
                    if (response.data.Result['LEGAL_E_INVOICING'] !== null) {
                        response.data.Result['LEGAL_E_INVOICING'].forEach(item => {
                            let value = Object.values(item)
                            legalInvoiceData.push({ label: value[0], value: value[0] });
                        });
                    }

                    var networkEligibleData = [{ label: "All", value: "all" }];
                    if (response.data.Result['SAP_ARIBA_NETWORK_ELIGIBLE'] !== null) {
                        response.data.Result['SAP_ARIBA_NETWORK_ELIGIBLE'].forEach(item => {
                            let value = Object.values(item)
                            networkEligibleData.push({ label: value[0], value: value[0] });
                        });
                    }

                    this.setState({
                        buyerOption: buyerData,
                        businessUnitOption: businessUnitData,
                        erpSystemOption: erpData,
                        l4IndustryOption: l4IndustryData,
                        erpCodeOption: erpCodeData,
                        erpDescOption: erpDescData,
                        sicCodeOption: sicCodeData,
                        sicDescOption: sicDescData,
                        goodsOption: goodsData,
                        invoiceCountryOption: invCountryData,
                        legalInvoiceOption: legalInvoiceData,
                        aribaNetworkEligibleOption: networkEligibleData
                    });

                    var target = this.props.spendDetails.userSpendFilterSelections.find((obj) => {
                        return (obj.CriteriaName === 'Metric Type')
                    })
                    var selectedMetricType = target.UserPreference === '' ? target.DefaultValue : target.UserPreference

                    var objEnablementInsight = this.state.enablementInsightFilters;
                    objEnablementInsight.metricsType = selectedMetricType;

                    var objFilters = this.state.inputParam;
                    objFilters.buyerRegion = this.state.buyerRegionFilter
                    objFilters.businessUnit = this.state.businessUnitFilter
                    objFilters.erpSystem = this.state.erpSystemFilter
                    objFilters.l4IndustryName = this.state.l4IndustryFilter
                    objFilters.erpCode = this.state.erpCodeFilter
                    objFilters.erpDesc = this.state.erpDescFilter
                    objFilters.sicCode = this.state.sicCodeFilter
                    objFilters.sicDesc = this.state.sicDescFilter
                    objFilters.goods = this.state.goodsFilter
                    objFilters.invoiceCountry = this.state.invoiceCountryFilter
                    objFilters.legalInvoice = this.state.legalInvoiceFilter
                    objFilters.aribaNwEligible = this.state.aribaNwEligibleFilter
                    objFilters.dedupStats = this.state.enablementInsightFilters.dedupStats
                    objFilters.enabledCustomer = this.state.enablementInsightFilters.enabledCustomer
                    objFilters.transactingCustomer = this.state.enablementInsightFilters.transactingCustomer
                    objFilters.metricsType = selectedMetricType
                    objFilters.directSpend = this.state.enablementInsightFilters.directSpend


                    let selectedFilter = Object.assign({}, objFilters)//this.state.enablementInsightFilters)
                    this.props.setFilters(selectedFilter)

                    this.props.enablementInsightSuccess(true)
                }
            }).catch((error) => {
                if (error.stack.includes('Network Error')) {
                    this.props.enablementInsightFailure('API service is down. Please contact administrator.')
                }
                else {
                    this.props.enablementInsightFailure(this.props.content.error)
                }
            })
    }

    render() {
        return (
            <div className="grid-x">
                <div className="medium-12">
                    <div className="grid-x grid-padding-x">

                        <div className="float-left">
                            <div className="dropDownHeader">{this.props.content.dedupddlText}</div>
                            <Select
                                className="border-with-DD dropDownContent"
                                id='aribaNetworkMatched'
                                autocomplete='true'
                                value={this.state.dedupeStatsOption.filter(
                                    option => option.value === this.state.enablementInsightFilters.dedupStats
                                )}
                                onChange={value => this.handleDedupeStatsChange(value)}
                                options={this.state.dedupeStatsOption}
                                isSearchable={false}
                                //isDisabled={this.props.spendDetails.isLoading}
                                noOptionsMessage={() => null} />
                        </div>

                        <div className="float-left pl20">
                            <div className="dropDownHeader">{this.props.content.possiblyEnabledCustddlText}</div>
                            <Select
                                className="border-with-DD dropDownContent"
                                id='aribaNetworkMatched'
                                autocomplete='true'
                                value={this.state.enabledCustomer.filter(
                                    option => option.value === this.state.enablementInsightFilters.enabledCustomer
                                )}
                                onChange={value => this.handleEnabledCustomerChange(value)}
                                options={this.state.enabledCustomer}
                                isSearchable={false}
                                //isDisabled={this.props.spendDetails.isLoading}
                                noOptionsMessage={() => null} />
                        </div>

                        <div className="float-left pl20">
                            <div className="dropDownHeader">{this.props.content.transactingCustddlText}</div>
                            <Select
                                className="border-with-DD dropDownContent"
                                id='matched'
                                autocomplete='true'
                                value={this.state.transactingCustomer.filter(
                                    option => option.value === this.state.enablementInsightFilters.transactingCustomer
                                )}
                                onChange={value => this.handleTransactCustomerChange(value)}
                                options={this.state.transactingCustomer}
                                isSearchable={false}
                                //isDisabled={this.props.spendDetails.isLoading}
                                noOptionsMessage={() => null} />
                        </div>

                        <div className="float-left pl20 width180">
                            <div className="dropDownHeader">{this.props.content.metricsType}</div>
                            <Select
                                className="border-with-DD dropDownContent"
                                id='matched'
                                autocomplete='true'
                                value={this.state.metricsTypeOption.filter(
                                    option => JSON.stringify(option.value) === JSON.stringify(this.state.enablementInsightFilters.metricsType)
                                )}
                                onChange={value => this.handleMetricsTypeChange(value)}
                                options={this.state.metricsTypeOption}
                                isSearchable={false}
                                //isDisabled={this.props.spendDetails.isLoading}
                                noOptionsMessage={() => null} />
                        </div>
                        <div style={{ width: '25px' }} className="pl5 float-left">
                            <Popup className="report-tooltip"
                                trigger={<img style={{ paddingTop: '10px' }} className="info-icon" src="images/info-icon.svg" width="15" />}
                                position="bottom left"
                                on="hover"
                            >
                                <div>
                                    <ul>
                                    <li>{this.props.content.metricsTypeToolTip}</li>
                                    <li>{this.props.content.metricsTypeToolTipUl1}</li>
                                    <li>{this.props.content.metricsTypeToolTipUl2}</li>
                                    <li>{this.props.content.metricsTypeToolTipUl3}</li>
                                    <li>{this.props.content.metricsTypeToolTipUl4}</li></ul>
                                </div>
                            </Popup>
                        </div>

                        <div className="float-left pl20 pt20"><Button content={this.props.content.advanceFilters} onClick={() => this.showAdvanceFilter()}></Button></div>
                        <div className="float-left pl20 pt20"><Button content={this.props.content.applyFilters} onClick={() => this.handleApplyFilter()}></Button></div>
                        <div className="float-left pl20 pt20"><Button content={this.props.content.resetFilters} onClick={() => this.handleResetFilter()}></Button></div>
                    </div>

                </div>
                <div>
                    <br></br>
                </div>
                <div className="medium-12" id="advanceFilter" style={{ display: 'none', paddingTop: '15px' }}>
                    <div className="grid-x grid-padding-x">

                        <div className="float-left width160">
                            <div className="dropDownHeader">{this.props.content.buyerRegionddlText}</div>
                            <MultiSelect
                                className="border-with-DD dropDownContent multiSelectHeight multiSelectLabelPadding"
                                model='yourFile.RequestId'
                                id='RequestId'
                                autocomplete='true'
                                value={this.state.enablementInsightFilters.buyerRegion}
                                onChange={value => this.handleBuyerRegionChange(value)}
                                options={this.state.buyerOption}
                                disabled={this.props.spendDetails.isLoading}
                                display="chip"
                            />
                        </div>

                        <div className="float-left pl20 width160">
                            <div className="dropDownHeader">{this.props.content.businessUnitddlText}</div>
                            <MultiSelect
                                className="border-with-DD dropDownContent multiSelectHeight multiSelectLabelPadding"
                                model='yourFile.RequestId'
                                id='RequestId'
                                autocomplete='true' value={this.state.enablementInsightFilters.businessUnit}
                                onChange={value => this.handleBusinessUnitChange(value)}
                                options={this.state.businessUnitOption}
                                disabled={this.props.spendDetails.isLoading}
                            />
                        </div>

                        <div className="float-left pl20 width150">
                            <div className="dropDownHeader">{this.props.content.erpSystemddlText}</div>
                            <MultiSelect
                                className="border-with-DD dropDownContent multiSelectHeight multiSelectLabelPadding"
                                model='yourFile.RequestId'
                                id='RequestId'
                                autocomplete='true'
                                value={this.state.enablementInsightFilters.erpSystem}
                                onChange={value => this.handleErpSystemChange(value)}
                                options={this.state.erpSystemOption}
                                noOptionsMessage={() => null} />
                        </div>

                        <div className="float-left pl20 width200">
                            <div className="dropDownHeader">{this.props.content.l4IndustryddlText}</div>
                            <MultiSelect
                                className="border-with-DD dropDownContent multiSelectHeight multiSelectLabelPadding showAll"
                                model='yourFile.RequestId'
                                id='RequestId'
                                autocomplete='true'
                                value={this.state.enablementInsightFilters.l4IndustryName[0] == "all" ? '': this.state.enablementInsightFilters.l4IndustryName}
                                onChange={value => this.handleL4IndustryChange(value)}
                                options={this.state.l4IndustryOption}
                                disabled={this.props.spendDetails.isLoading}
                                placeholder={this.props.content.l4IndustryddlPlaceHolder}
                            />
                        </div>

                        <div className="float-left pl20 width300">
                            <div className="dropDownHeader">{this.props.content.erpTier1ddlText}</div>
                            <MultiSelect
                                className="border-with-DD dropDownContent multiSelectHeight multiSelectLabelPadding showAll"
                                model='yourFile.RequestId'
                                id='RequestId'
                                autocomplete='true'
                                value={this.state.enablementInsightFilters.erpCode[0] == "all" ? '' : this.state.enablementInsightFilters.erpCode}
                                onChange={value => this.handleErpCodeChange(value)}
                                options={this.state.erpCodeOption}
                                placeholder= {this.props.content.erpTier1ddlPlaceHolder}
                            />
                        </div>

                        <div className="float-left pl20 width300">
                            <div className="dropDownHeader">{this.props.content.erpTier2ddlText}</div>
                            <MultiSelect
                                className="border-with-DD dropDownContent multiSelectHeight multiSelectLabelPadding showAll"
                                model='yourFile.RequestId'
                                id='RequestId'
                                autocomplete='true'
                                value={this.state.enablementInsightFilters.erpDesc[0] == "all" ? '' : this.state.enablementInsightFilters.erpDesc}
                                onChange={value => this.handleErpDescChange(value)}
                                options={this.state.erpDescOption}
                                placeholder= {this.props.content.erpTier2ddlPlaceHolder}
                            />
                        </div>

                    </div>

                    <div className="grid-x grid-padding-x" style={{ paddingTop: '15px' }}>

                        <div className="float-left width150">
                            <div className="dropDownHeader">{this.props.content.sicddlText}</div>
                            <MultiSelect
                                className="border-with-DD dropDownContent multiSelectHeight multiSelectLabelPadding"
                                model='yourFile.RequestId'
                                id='RequestId'
                                autocomplete='true'
                                value={this.state.enablementInsightFilters.sicCode}
                                onChange={value => this.handleSicCodeChange(value)}
                                options={this.state.sicCodeOption}
                                noOptionsMessage={() => null} />
                        </div>

                        <div className="float-left pl20 width200">
                            <div className="dropDownHeader">{this.props.content.sicDescddlText}</div>
                            <MultiSelect
                                className="border-with-DD dropDownContent multiSelectHeight multiSelectLabelPadding"
                                model='yourFile.RequestId'
                                id='RequestId'
                                autocomplete='true'
                                value={this.state.enablementInsightFilters.sicDesc}
                                onChange={value => this.handleSicDescChange(value)}
                                options={this.state.sicDescOption}
                                noOptionsMessage={() => null} />
                        </div>


                        <div className="float-left pl20 width200">
                            <div className="dropDownHeader">{this.props.content.invOrgCountryddlText}</div>
                            <MultiSelect
                                className="border-with-DD dropDownContent multiSelectHeight multiSelectLabelPadding showAll"
                                model='yourFile.RequestId'
                                id='RequestId'
                                autocomplete='true'
                                value={this.state.enablementInsightFilters.invoiceCountry[0] == "all" ? '' : this.state.enablementInsightFilters.invoiceCountry}
                                onChange={value => this.handleInvoiceCountryChange(value)}
                                options={this.state.invoiceCountryOption}
                                noOptionsMessage={() => null}
                                placeholder= {this.props.content.invOrgCountryddlPlaceHolder}
                                />
                        </div>


                        <div className="float-left pl20 width300">
                            <div className="dropDownHeader">{this.props.content.eInvoiceddlText}</div>
                            <MultiSelect
                                className="border-with-DD dropDownContent multiSelectHeight multiSelectLabelPadding"
                                model='yourFile.RequestId'
                                id='RequestId'
                                autocomplete='true'
                                value={this.state.enablementInsightFilters.legalInvoice}
                                onChange={value => this.handleLegalInvoiceChange(value)}
                                options={this.state.legalInvoiceOption}
                                noOptionsMessage={() => null} />
                        </div>

                        <div className="float-left pl20 width150">
								<div className="dropDownHeader">{this.props.content.directSpendText}</div>
								<Select
									className="border-with-DD dropDownContent"
									model='yourFile.RequestId'
									id='RequestId'
									autocomplete='true'
									//placeholder={this.props.content.notConfirmed}
									value={this.state.directSpendOptions.filter(
										option => option.value === this.state.enablementInsightFilters.directSpend
									)}
									onChange={value => this.handleDirectSpendChange(value)}
									options={this.state.directSpendOptions}
									isDisabled={this.props.spendDetails.isLoading}
									isSearchable={false}
								/>
							</div>

                        <div className="float-left pl20 width150">
                            <div className="dropDownHeader">{this.props.content.goodsddlText}</div>
                            <MultiSelect
                                className="border-with-DD dropDownContent multiSelectHeight multiSelectLabelPadding"
                                model='yourFile.RequestId'
                                id='RequestId'
                                autocomplete='true'
                                value={this.state.enablementInsightFilters.goods}
                                onChange={value => this.handleGoodsChange(value)}
                                options={this.state.goodsOption}
                                noOptionsMessage={() => null} />
                        </div>

                        <div className="float-left pl20 width200">
                            <div className="dropDownHeader">{this.props.content.sapNwEligibleddlText}</div>
                            <MultiSelect
                                className="border-with-DD dropDownContent multiSelectHeight multiSelectLabelPadding"
                                model='yourFile.RequestId'
                                id='RequestId'
                                autocomplete='true'
                                value={this.state.enablementInsightFilters.aribaNwEligible}
                                onChange={value => this.handleAribaNetworkEligibleChange(value)}
                                options={this.state.aribaNetworkEligibleOption}
                                noOptionsMessage={() => null} />
                        </div>

                    </div>

                </div>

                <div className="pt10">
					{this.state.isFilterChanges===true ?<span className="pending-changes" style={{'color':'red'}}>{this.props.content.pendingChanges}</span>:''}
				</div>

            </div>
        )
    }

}


const mapStateToProps = state => {
    return {
        content: state.content.enablementInsight,
        //content: state.content.subLeverCalculations,
        ui: state.content.ui,
        currency: decodeURIComponent(state.valueStory.Currency),
        userData: state.valueStory,
        allContent: state.content,
        language: state.language,
        spendDetails: state.spendDetails,
        user: state.default,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        enablementInsightSuccess: message => dispatch(enablementInsightSuccess(message)),
        enablementInsightFailure: error => dispatch(enablementInsightFailure(error)),
        enablementInsightLoading: bool => dispatch(enablementInsightLoading(bool)),
        resetError: error => dispatch(resetError(error)),
        getEnablementFiltersDetails: (data) => dispatch(getEnablementFiltersDetails(data)),
        storeEnablementInsightSelectedFilter: (data) => dispatch(storeEnablementInsightSelectedFilter(data)),
        storeUserSpendSelection: (VSId, DMCId, spendModule, spendSubModule, criteriaName, selectedValue, userEmail) => dispatch(storeUserSpendSelection(VSId, DMCId, spendModule, spendSubModule, criteriaName, selectedValue, userEmail)),
        trackUserSpendSelections: (userSelections) => dispatch(trackUserSpendSelections(userSelections))

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EnablementInsightTabFilters)

