import React from 'react'
import { connect } from 'react-redux'
import { Control, Form, Errors, actions } from 'react-redux-form'
// UI
import Textfield from 'react-mdl/lib/Textfield'
import Button from 'UI/Button'
// Settings
import { validators } from 'Constants'

// Actions
import {
  fetchAllUsers,
  userDetailsSuccess,
  userDetailsFailure,
  setPassword,
  userDetailsLoading,
  resetError
}
  from 'actions/userActions'

let messageDuration = 1800

class UserEmailPasswordManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterText: "",
      filterValue: "",
      users: [], mymessage:''
    }

    this.loadData = this.loadData.bind(this)

   }

  componentWillMount(){
    this.loadData()
  }
  loadData() {
    this.props.fetchAllUsers()
      .then((response) => {
        var userData = [];
        this.props.userDetailsSuccess(response.data.Message)
        for (var i = 0; i < response.data.Result.length; i++) {
          var obj = response.data.Result[i];
          userData.push(obj);
        }
        this.setState({ users: userData })
      }).catch((error) => {
        if (error.stack.includes('Network Error')) {
          this.props.userDetailsFailure()
        }
        else {
          this.props.userDetailsFailure(error.response.data.Message)
        }
      })
  }



  handleUserInput(filterText) {
    this.setState({ filterText: filterText.target.value.toLowerCase() });
  };

  resetPassword(formValues) {
    var userDetail = {
      Email: formValues.Email,
      NewPassword: formValues.NewPassword
    }
    var msg = this.props.content
    this.props.setPassword(userDetail).then((results) => {
      if (results.status === 200) {
        var httpstatus = results.status
        var getMsgfromStatus = msg[httpstatus]
        // Reset form
        this.formDispatch(actions.reset('activateUser'))
        this.setState({ filterText: "", filterValue: "" })
        this.setState({mymessage: getMsgfromStatus})
        setTimeout(() => {          
          this.loadData()
          this.setState({mymessage: ""})
        }, messageDuration)  
        
        
      }
      
    }).catch((error) => {
      if (error.stack.includes('Network Error')) {
        this.props.userDetailsFailure('API service is down. Please contact administrator.')
      }
      else {
        var httpstatus = error.response.status
        var getMsgfromStatus = msg[httpstatus]
        if (getMsgfromStatus !== undefined) {
          this.props.userDetailsFailure(getMsgfromStatus)
        }
        else {
          this.props.userDetailsFailure(error.response.data.Message)
        }
      }
    })

  }

  handleChange(value) {
    var filter = value;
    this.setState({ filterValue: filter });
  }

  componentWillUnmount() {
    this.formDispatch(actions.reset('activateUser'))
    this.props.resetError()
  }

  render() {
    return (
      <div className="popup-content">
        <div className="medium-12 title">{this.props.content.title}</div>
        <div>
          <Form model="activateUser" method="post" 
            getDispatch={(dispatch) => this.formDispatch = dispatch}
            onSubmit={(formValue) => this.resetPassword(formValue)}>

            <div className="grid-x grid-padding-x" >

              <SearchBar props={this.props.validation} filterText={this.state.filterText} onUserInput={this.handleUserInput.bind(this)} />
              <div className="medium-4 cell" >
                <Control.text
							  model="activateUser.NewPassword"
						  	type="password"
						  	component={Textfield}
						  	label={this.props.content.newPassword}
                floatingLabel
                validators={{
                  required: validators.required,
                  validPassword: validators.validPassword
                }}
						/>
            <Errors
							className="errors"
							model="activateUser.NewPassword"
							show="touched"
							messages={{
								required: this.props.validation.required,
								validPassword: this.props.validation.validPassword
							}}
						/>
            </div>

              <div className="medium-2 cell pt13" >
                <Button 
                  type="submit" 
                  disabled={this.props.user.loading}
                  content={this.props.content.reset}
                  color 
                />
              </div>
            </div>

          </Form>
          <div className="errors">{this.props.user.error}</div>
          <div className="success">{this.state.mymessage}</div>
        </div>
        <div className="grid-x" className="medium-11 medium-offset-0">
          <UserTable

            users={this.state.users}
            filterText={this.state.filterText}
            filterValue={this.state.filterValue}
          />
        </div>
      </div>
    );
  }
}

class SearchBar extends React.Component {
  constructor(props) {
    super(props);
    }

  handleChange(event) {
    this.props.onUserInput(event);
  }

  render() {
    return (
      <div className="medium-4 cell">
        <Control.text
          model="activateUser.Email"
          id="activateUser.Email"
          component={Textfield}
          label={'Email'}
          floatingLabel
          ref="filterTextInput"
          onChange={this.handleChange.bind(this)}
          validators={{
            validEmail: validators.validEmail
          }}
          validateOn="blur"
        />
        <Errors
          className="errors"
          model="activateUser.Email"
          show="touched"
          messages={{
            validEmail: this.props.props.validEmail
          }}
        />
      </div>
    );
  }
}

class UserTable extends React.Component {
  render() {
    var filterText = this.props.filterText;
    var filterValue = this.props.filterValue;
    var user = this.props.users.map(function (user) {
      if (user.email.toLowerCase().indexOf(filterText) === -1) {
        return;
      }
      else if (user.emailConfirmed.indexOf(filterValue) === -1 && filterValue !== "") {
        return;
      }
      else return (<UserRow
        user={user}
        key={user.email} />)
    });
    return (
      <div className="basic-table">
        <table>
          <thead>
            <tr>
              <th>User Email</th>
              <th>Registered On</th>
            </tr>
          </thead>

          <tbody>
            {user}
          </tbody>
        </table>
      </div>
    );
  }
}


class UserRow extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <tr className="eachRow">
        <td>{this.props.user.email}</td>
        <td>{this.props.user.joinedDate}</td>
      </tr>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
    ui: state.content.ui,
    user: state.default,
    content: state.content.userEmailPasswordManager,
    validation: state.content.validation,
    users: state.users
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAllUsers: (formValues) => dispatch(fetchAllUsers(formValues)),
    userDetailsLoading: (bool) => dispatch(userDetailsLoading(bool)),
    userDetailsSuccess: (bool) => dispatch(userDetailsSuccess(bool)),
    userDetailsFailure: (error) => dispatch(userDetailsFailure(error)),
    setPassword: (formValues) => dispatch(setPassword(formValues)),
    resetError: () => dispatch(resetError())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserEmailPasswordManager)
