import React from 'react'
import{connect} from 'react-redux'
import Select from 'react-select'
import EnablementInsightTabTable from './EnablementInsightTabTable'
import EnablementSupplierDetailsPopup from './EnablementSupplierDetailsPopup'
import { path } from 'Constants'

// Settings
import { changeModalBorderStyle, drilldownModalBorderContentStyle, changeModalBorderRectStyle } from 'Constants'
import Modal from 'reboron/OutlineModal'

import {
    enablementInsightSuccess,
    enablementInsightFailure,
    enablementInsightLoading,    
    resetError,
    getGlobalDetails,
} from 'actions/spendActions'

class GlobalTab extends React.Component{
    constructor(props)
    {
        super(props);
        this.state={
          country: [],
          countryGrandTotal: [],
          buyerRegion: [],
          buyerRegionGrandTotal: [],
          businessUnit: [],
          businessUnitGrandTotal: [],
          erpDetails: [],
          erpGrandTotal: [],
          legalEinvoicing: [],
          legalInvoiceGrandTotal: [],
          isLoading: true,
          responseMsg: '',
          inputParam: {
            DMC_ID: this.props.spendDetails !== undefined && this.props.spendDetails.currentActiveRequest !== undefined ? this.props.spendDetails.currentActiveRequest.DMC_ID : 0
            , VS_ID: this.props.spendDetails !== undefined && this.props.spendDetails.currentActiveRequest !== undefined ? this.props.spendDetails.currentActiveRequest.VS_ID : 0,
            User_Email: this.props.spendDetails.currentActiveRequest !== undefined &&
            this.props.spendDetails.currentActiveRequest.SAPRequester === this.props.user.user.Email ? this.props.user.user.Email : this.props.spendDetails.currentActiveRequest.SAPRequester,
            dedupStats: 'Unique',
            enabledCustomer: 'all',
            transactingCustomer: 'all',
            buyerRegion: 'all',
            businessUnit: 'all',
            erpSystem: 'all',
            l4IndustryName: 'all',
            erpCode: 'all',
            erpDesc: 'all',
            sicCode: 'all',
            sicDesc: 'all',
            
            goods: 'all',
            invoiceCountry: 'all',
            legalInvoice: 'all',
            aribaNwEligible: 'all',
            directSpend: 'all',
            column1Name: '',
            column1Value: '',
            tableName: '',
            valuesUpdated: false,
          }         
         
        }
        this.showEnablementSupplierDetailsModal = this.showEnablementSupplierDetailsModal.bind(this)
        this.setVisibility = this.setVisibility.bind(this)
        this.outsidePopupClick = this.outsidePopupClick.bind(this)
        this.onSave = this.onSave.bind(this)
    }

    componentDidMount(){               
       this.GetGlobalDetails(this.state.inputParam)
    }

    showEnablementSupplierDetailsModal(tableName, columnName, columnValue) {

        var inputParameter = this.state.inputParam
        inputParameter.tableName = tableName
        inputParameter.column1Name = columnName
        inputParameter.column1Value = columnValue

       // console.log("Column Details", columnName +" "+ columnValue)
		this.setState({	openPopup: true, inputParam: inputParameter	})
    }

    onSave(value) {
        this.setState({valuesUpdated: value})
    }

    setVisibility(isVisible) {
        this.handlePoupClose()   
    }

    outsidePopupClick() {        
        this.handlePoupClose()
    }
    
    handlePoupClose() {
        this.setState({  open: false, openPopup: false }, () => {
            if(this.state.valuesUpdated) {
                this.setState({valuesUpdated: false}, this.GetGlobalDetails(this.state.inputParam))
            }
        })
    }

    GetGlobalDetails(inputParam) {		
		this.props.getGlobalDetails(inputParam)
			.then((response) => {
				if (response !== undefined && response.status === 200 && response.data.Result !== undefined) {
                var countryData; var countryTotal;
                var buyerRegionData; var buyerRegionTotal;
                var businessUnitData; var businessUnitTotal;
                var erpData; var erpTotal;
                var legalEInvoicingData; var legalEInvoicingTotal;

                    var tables = response.data.Result.map(
                        function (table) {
                            if(table['AN_SUPPLIER_COUNTRY']!== undefined){
                                countryData = table['AN_SUPPLIER_COUNTRY']
                                countryTotal = table['GrandTotal']
                            }

                            if(table['BUSINESS_UNIT']!== undefined){
                                businessUnitData = table['BUSINESS_UNIT']
                                businessUnitTotal = table['GrandTotal']
                            }

                            if(table['BUYER_REGION']!== undefined){
                                buyerRegionData = table['BUYER_REGION']
                                buyerRegionTotal = table['GrandTotal']
                            } 
                            
                            if(table['ERP_SYSTEM']!== undefined){
                                erpData = table['ERP_SYSTEM']
                                erpTotal = table['GrandTotal']
                            }

                            if(table['LEGAL_E_INVOICING']!== undefined){
                                legalEInvoicingData = table['LEGAL_E_INVOICING']
                                legalEInvoicingTotal = table['GrandTotal']
                            }
                            
                        }
                    )
                this.setState({ country : countryData, countryGrandTotal : countryTotal, buyerRegion: buyerRegionData, buyerRegionGrandTotal: buyerRegionTotal,
                     businessUnit: businessUnitData, businessUnitGrandTotal: businessUnitTotal, erpDetails: erpData, erpGrandTotal: erpTotal, 
                     legalEinvoicing: legalEInvoicingData, legalInvoiceGrandTotal: legalEInvoicingTotal, isLoading: false })
                
                this.props.enablementInsightSuccess(true)
				}
			}).catch((error) => {
				if (error.stack.includes('Network Error')) {
                    this.setState({ responseMsg: 'API service is down. Please contact administrator', isError: 'Yes' })
					this.props.enablementInsightFailure('API service is down. Please contact administrator.')
				}
				else {
                    this.setState({ responseMsg: 'An error has occurred', isError: 'Yes' })
					this.props.enablementInsightFailure(this.props.content.error)
				}
			})
    }
    
    
    componentWillReceiveProps(nextProps, nextState){
        if (this.state.Test !== nextProps.filterData && 'dedupStats' in nextProps.filterData)
        {
        var inputParameter = this.state.inputParam
        inputParameter.dedupStats = nextProps.filterData.dedupStats
        inputParameter.enabledCustomer= nextProps.filterData.enabledCustomer
        inputParameter.transactingCustomer= nextProps.filterData.transactingCustomer
        inputParameter.buyerRegion= nextProps.filterData.buyerRegion
        inputParameter.businessUnit= nextProps.filterData.businessUnit
        inputParameter.erpSystem= nextProps.filterData.erpSystem
        inputParameter.l4IndustryName= nextProps.filterData.l4IndustryName
        inputParameter.erpCode= nextProps.filterData.erpCode 
        inputParameter.erpDesc= nextProps.filterData.erpDesc 
        inputParameter.sicCode= nextProps.filterData.sicCode 
        inputParameter.sicDesc= nextProps.filterData.sicDesc
        inputParameter.goods= nextProps.filterData.goods
        inputParameter.invoiceCountry= nextProps.filterData.invoiceCountry
        inputParameter.legalInvoice= nextProps.filterData.legalInvoice
        inputParameter.aribaNwEligible= nextProps.filterData.aribaNwEligible
        inputParameter.metricsType= nextProps.filterData.metricsType
        inputParameter.directSpend= nextProps.filterData.directSpend

        this.GetGlobalDetails(inputParameter)
        this.setState({Test:nextProps.filterData , inputParam: Object.keys(nextProps.filterData).length !== 0 ? nextProps.filterData : this.state.inputParam})
        //console.log("if enablemtn details filetr props-")
        }
        else{
          //  console.log("else enablemtn details filetr props-")
        }
        
    }


    render()
    {
        return(
            <div className="grid-x" style={{ width: '100%'}}>

                  <div className="medium-12">
                        {(this.state.isLoading) ?
                            <img style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={path + `images/ajax-loader.gif`} alt="" />
                            : null
                        }
                        {(this.state.isError === 'Yes') ?
                            <div style={{ color: 'red', textAlign: 'center' }} className="cell pt5">{this.state.responseMsg}</div>
                            : <div style={{ color: 'green', textAlign: 'center' }} className="cell pt5">{this.state.responseMsg}</div>
                        }
                    </div>

                <div className="medium-12 marginTop">
                        <div className="grid-x">
                            {/* <div className="medium-6 graphDiv">                               */}

                            <div className="grid-x">
                                <div className="medium-12">
                                    {this.state.buyerRegion !== undefined && this.state.buyerRegion !== null ?
                                        <EnablementInsightTabTable
                                            data={this.state.buyerRegion}
                                            grandTotal={this.state.buyerRegionGrandTotal}
                                            content={this.props.content}
                                            tableName={this.props.content.global.buyerRegionHeader}
                                            tableSequence={"20"}
                                            showEnablementSupplierDetailsModal={this.showEnablementSupplierDetailsModal}
                                            metricsType= {this.state.inputParam.metricsType}
                                        />
                                        : <div className="pt10">{this.props.content.noDataFound}</div>}
                                        </div>
                                </div>

                                <div className="grid-x">
                                <div className="medium-12">
                                          {this.state.country !== undefined && this.state.country !== null ?
                                         <EnablementInsightTabTable
                                             data={this.state.country}
                                             grandTotal={this.state.countryGrandTotal}
                                             content={this.props.content}
                                             tableName={this.props.content.global.aribaNwSupplierCountryHeader}
                                             tableSequence={"22"}
                                             showEnablementSupplierDetailsModal={this.showEnablementSupplierDetailsModal}
                                             metricsType= {this.state.inputParam.metricsType}
                                         />
                                         : <div className="pt10">{this.props.content.noDataFound}</div>}
                                         </div>
                                </div>                              
                             

                            {/* </div>
                            <div className="medium-6" style={{paddingLeft: '25px'}}>                                */}
                             <div className="grid-x">  
                                <div className="medium-12">                                  
                                    {this.state.businessUnit !== undefined && this.state.businessUnit !== null ?
                                        <EnablementInsightTabTable
                                            data={this.state.businessUnit}
                                            grandTotal={this.state.businessUnitGrandTotal}
                                            content={this.props.content}
                                            tableName={this.props.content.global.businessUnitHeader}
                                            tableSequence={"23"}
                                            showEnablementSupplierDetailsModal={this.showEnablementSupplierDetailsModal}
                                            metricsType= {this.state.inputParam.metricsType}
                                        />
                                        : <div className="pt10">{this.props.content.noDataFound}</div>}
                                        </div>
                                </div>    

                                 <div className="grid-x">  
                                <div className="medium-12">                                  
                                    {this.state.erpDetails !== undefined && this.state.erpDetails !== null ?
                                        <EnablementInsightTabTable
                                            data={this.state.erpDetails}
                                            grandTotal={this.state.erpGrandTotal}
                                            content={this.props.content}
                                            tableName={this.props.content.global.erpDetailHeader}
                                            tableSequence={"24"}
                                            showEnablementSupplierDetailsModal={this.showEnablementSupplierDetailsModal}
                                            metricsType= {this.state.inputParam.metricsType}
                                        />
                                        : <div className="pt10">{this.props.content.noDataFound}</div>}
                                        </div>
                                </div>  

                                 <div className="grid-x">  
                                <div className="medium-12">                                  
                                    {this.state.legalEinvoicing !== undefined && this.state.legalEinvoicing !== null ?
                                        <EnablementInsightTabTable
                                            data={this.state.legalEinvoicing}
                                            grandTotal={this.state.legalInvoiceGrandTotal}
                                            content={this.props.content}
                                            tableName={this.props.content.global.legalEInvoicingHeader}
                                            tableSequence={"27"}
                                            showEnablementSupplierDetailsModal={this.showEnablementSupplierDetailsModal}
                                            metricsType= {this.state.inputParam.metricsType}
                                        />
                                        : <div className="pt10">{this.props.content.noDataFound}</div>}
                                        </div>
                                </div>                              
                               
                            {/* </div> */}
                        </div>
                    </div>
                    
                    <div>  
                  {this.refs.enablementSupplier ? this.state.openPopup ?
                    this.refs.enablementSupplier.show() :
                    this.refs.enablementSupplier.hide() : ''}        
                  <Modal
                    ref={'enablementSupplier'}
                    modalStyle={changeModalBorderStyle}
                    contentStyle={drilldownModalBorderContentStyle}
                    rectStyle={changeModalBorderRectStyle}                   
                    keyboard={false}
                    backdrop={false}
                   >
                    <div className="grid-x tabs_container supplier-tabs">                       
                        <div className="medium-12">                        
                            {
                                <EnablementSupplierDetailsPopup onSave={this.onSave} input={this.state.inputParam} outsidePopupClick={this.outsidePopupClick} ></EnablementSupplierDetailsPopup>
                            }
                        </div>
                    </div>
                    <button onClick={() => { this.setVisibility(false); this.setState({ selectedIndustryName: {} }) }} className='close' />
                </Modal>
                  </div>
                  
            </div>                   
        )
    }

}


const mapStateToProps = state => {
	return {
        content: state.content.enablementInsight,
        //content: state.content.subLeverCalculations,
		ui: state.content.ui,
		currency: decodeURIComponent(state.valueStory.Currency),
		userData: state.valueStory,
		spendAsPercentageOfRevenue: state.spendAsPercentageOfRevenue,
		benchMarkCalculations: state.benchMarksData,
		allContent: state.content,
        language: state.language,
        spendDetails: state.spendDetails,
        user: state.default
	}
}

const mapDispatchToProps = dispatch => {
	return {
        enablementInsightSuccess: message => dispatch(enablementInsightSuccess(message)),
        enablementInsightFailure: error => dispatch(enablementInsightFailure(error)),
        enablementInsightLoading: bool => dispatch(enablementInsightLoading(bool)),
        resetError: error => dispatch(resetError(error)),
        getGlobalDetails: (data) => dispatch(getGlobalDetails(data)),
        
	}
}

export default connect(mapStateToProps,mapDispatchToProps) (GlobalTab)