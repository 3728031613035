import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Form } from 'react-redux-form'
import classNames from "classnames";
import { path } from 'Constants'
import moment from 'moment'
import { ControlTextE } from 'UI/CustomComponents/Controls';
// Actions
import {
    fetchGoLiveVsKickOffWeekData,
    resetError,
    updateGoLiveVsKickOffWeekData
} from 'actions/spendActions'

// Utils
import { BreakLine} from 'utils/Utils'

class GoLiveVsKickOffWeek extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            goLiveKickoffWeek: [],
            editedGoLiveKickoffWeek: [],
            responseMessage: '',
            isError: false
        }
        this.loadData = this.loadData.bind(this)
        this.trackGoLiveKickoffWeekChanges = this.trackGoLiveKickoffWeekChanges.bind(this)
        this.onGoLiveKickoffWeekSave = this.onGoLiveKickoffWeekSave.bind(this)
        this.setValidationError= this.setValidationError.bind(this)
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        this.getAllScheduleFactors();
    }

    trackGoLiveKickoffWeekChanges(record) {
        var updatedRecords = this.state.editedGoLiveKickoffWeek;
        updatedRecords.push(record);
        // if (this.state.editedGoLiveKickoffWeek.length === 0)
        //     updatedRecords.push(record)
        // else {
        //     let index = this.state.editedGoLiveKickoffWeek.findIndex(x => x.INTELLIGENT_END_QUARTER === record.INTELLIGENT_END_QUARTER);
        //     if (index !== undefined && index !== null && index > -1)
        //         updatedRecords.splice(index, 1)
        //     updatedRecords.push(record)
        // }
        this.setState({ responseMessage: '', editedGoLiveKickoffWeek: updatedRecords, isError: false })
    }

    getAllScheduleFactors() {
        this.setState({ responseMessage: '', isLoading: true, isError: false });
        this.props.fetchGoLiveVsKickOffWeekData().then((response) => {
            if (response.status === 200) {
                if (response.data.Result.length === 0) {
                    this.setState({ responseMessage: this.props.content.reimport, isLoading: false, isError: true });
                }
                else
                {                    
                    var ordering = {}, // map for efficient lookup of sortIndex
                    sortOrder = ['Managed Standard Account','Enterprise - Portal','Enterprise - Portal & Catalog','Enterprise - Integration','Enterprise - Integration & Catalog'];
                    
                    for (var i=0; i<sortOrder.length; i++)
                        ordering[sortOrder[i]] = i;

                        var data = response.data.Result.sort( function(a, b) {
                        return (ordering[a.ONBOARDING_WORKSTREAM] - ordering[b.ONBOARDING_WORKSTREAM]);                        
                    });
                    var data = data.sort( function(a, b) {
                        return (a.ENABLEMENT_WAVE - b.ENABLEMENT_WAVE);
                    });
                    this.setState({ goLiveKickoffWeek: data, isLoading: false, responseMessage: '', isError: false });
                }
            }
            else {
                this.setState({ responseMessage: this.props.content.errorMessage, isLoading: false, isError: true });
            }
        }).catch((error) => {
            this.setState({ responseMessage: this.props.content.errorMessage, isLoading: false, isError: true });
        })
    }

    onGoLiveKickoffWeekSave() {
        if (this.state.editedGoLiveKickoffWeek.find(a => a.QUARTER_VALUE === '') === undefined ) {
            if (this.state.editedGoLiveKickoffWeek.find(a => a.QUARTER_VALUE === '-') === undefined ) {
                this.setState({ isLoading: true, responseMessage: '', isError: false });
                let updatedRecords = this.state.editedGoLiveKickoffWeek
                let email = this.props.user.user.Email
                let finalObject = [];
                updatedRecords.map(function (data) {
                    let record = {
                        'ENABLEMENT_WAVE': data.ENABLEMENT_WAVE,
                        'INTELLIGENT_END_QUARTER': data.INTELLIGENT_END_QUARTER,
                        'ONBOARDING_WORKSTREAM': data.ONBOARDING_WORKSTREAM, 
                        'WAVE_QUARTER_ASSIGN_ID': data.WAVE_QUARTER_ASSIGN_ID,
                        'USER_ID': email,
                        'MANUAL_END_QUARTER': data.MANUAL_END_QUARTER,                    
                    }
                    finalObject.push(record)
                });

                this.props.updateGoLiveVsKickOffWeekData(finalObject).then((response) => {
                    if (response.status === 200) {
                        this.loadData()
                        this.setState({ editedGoLiveKickoffWeek: [], isLoading: false, responseMessage: this.props.content.successMessage, isError: false });                        
                    }
                    else {
                        this.setState({ isLoading: false, responseMessage: this.props.content.errorMessage, isError: true });
                    }
                }).catch((error) => {
                    this.setState({ isLoading: false, responseMessage: this.props.content.errorMessage, isError: true });
                })
            }
            else {
                this.setState({ responseMessage: this.props.content.validNumber, isError: true });
            }
        }
        else {
            this.setState({ responseMessage: this.props.content.blankValue, isError: true });
        }
    }

    setValidationError()
    {
        this.setState({ responseMessage: this.props.content.validQuarterNo, isError: true });
    }

    componentWillUnmount() {
        this.props.resetError()
    }


    render() {
        var _instance = this
        var goLive_kickoffWeek = this.state.goLiveKickoffWeek.map(function (data, index) {            
            return (<TableRows
                goLiveKickoffWeekData={data}
                index={index}
                readOnly={_instance.props.readOnly}
                trackGoLiveKickoffWeekChanges={_instance.trackGoLiveKickoffWeekChanges}
                setValidationError={_instance.setValidationError}
            />
            );
        });

        return (
            <div className="grid-x" style={{ width: '100%'}}>
                <div className="medium-12">
                    {(this.state.isLoading) ?
                        <img style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
                            src={path + `images/ajax-loader.gif`} alt="" /> : null}
                </div>
                <div className="medium-12">
                    <div className="grid-x">
                        <div className="medium-7 float-left" style={{ fontSize: '1.2rem', paddingBottom: '8px' }}>{BreakLine(this.props.content.kpiQuarterTitle)}</div>
                        <div className="medium-5 float-left" style={{ display: 'flex' }} >
                            {this.state.isError ?
                                <div style={{ margin: '0 0 0.5rem', color: 'red' }}>{this.state.responseMessage}</div> :
                                <div style={{ margin: '0 0 0.5rem', color: '#4fb81c' }}>{this.state.responseMessage}</div>
                            }
                        </div>
                        <div className="medium-3"></div>
                    </div>
                    <div className="grid-x">
                        <div className="medium-11 float-left pb10">
                        {this.props.content.kpiQuarterDesc}
                        </div>
                        {this.props.user.userprofile.Roles[0].indexOf("Admin") > -1 ?
                            <div className="medium-1 pb5">
                                <button className={classNames('float-right',
                                    {
                                        'hand-cursor': this.state.isLoading ? false : (this.state.editedGoLiveKickoffWeek.length > 0),
                                        'gray-scale': this.state.isLoading ? true : (this.state.editedGoLiveKickoffWeek.length === 0)
                                    })}
                                    onClick={this.onGoLiveKickoffWeekSave} disabled={this.state.isLoading ? true : this.state.editedGoLiveKickoffWeek.length === 0} type="submit" float >
                                    <img width="25" title={this.props.content.save} src="images/save_active.svg" />
                                </button>
                            </div> : null}
                    </div>
                </div>
                {this.state.goLiveKickoffWeek.length > 0 ?
                    <div className="medium-12">
                        <Form model="netwrokEnablementMatchTypeParameter">
                            <div className="basic-table">
                                <table>
                                    <thead>
                                        <tr className="eachRow">                                            
                                            <th>{this.props.content.enablementWave}</th>
                                            <th>{this.props.content.onboardingWorkstream}</th>
                                            <th>{this.props.content.startQuarterNo}</th>
                                            <th>{this.props.content.quarterNo}</th>
                                            <th>{this.props.content.updatedBy}</th>
                                            <th>{this.props.content.updatedDate}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {goLive_kickoffWeek}
                                    </tbody>
                                </table>
                            </div>
                        </Form>
                    </div>
                    : null
                }
            </div>
        )
    }
}

class TableRows extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    onChangeEvent(val) {
        if (val.target.value !== '' && val.target.value.indexOf('Q')===0 ){// || val.target.value.match(/^[0-9]+$/) || !isNaN(val.target.value)) {
            var userInput = val.target.value.split('Q')
            if(userInput[1].match(/^[0-9]+$/) || !isNaN(userInput[1])){
                if(userInput[1] <=12){
            var editingRow = this.props.goLiveKickoffWeekData;
            let editedValue = val.target.value.trim()
            if (editedValue.toString() !== editingRow[val.target.id].toString()) {
                editingRow[val.target.id] = editedValue
                this.props.trackGoLiveKickoffWeekChanges(editingRow)
            }
        }else{
            this.props.setValidationError() 
        }

        }
        else{
            this.props.setValidationError() 
        }
        }
        else{
            this.props.setValidationError()
        }
    }

    render() {
        var bindValues = this.props.goLiveKickoffWeekData;
        return (
            <tr className="eachRow">                
                <td>{bindValues.ENABLEMENT_WAVE}</td>
                <td>{bindValues.ONBOARDING_WORKSTREAM}</td>
                <td>
                        <ControlTextE
                            model="goLiveKickoffWeek.MANUAL_END_QUARTER"
                            onChange={this.onChangeEvent.bind(this)}
                            id={'MANUAL_END_QUARTER'}
                            defaultValue={bindValues.MANUAL_END_QUARTER}
                            value={bindValues.MANUAL_END_QUARTER}
                            type='name'
                            className="edit-textarea edit-width"
                            readOnly={this.props.readOnly}
                        />
                    </td>

                    <td>
                        <ControlTextE
                            model="goLiveKickoffWeek.INTELLIGENT_END_QUARTER"
                            onChange={this.onChangeEvent.bind(this)}
                            id={'INTELLIGENT_END_QUARTER'}
                            defaultValue={bindValues.INTELLIGENT_END_QUARTER}
                            value={bindValues.INTELLIGENT_END_QUARTER}
                            type='name'
                            className="edit-textarea edit-width"
                            readOnly={this.props.readOnly}
                        />
                    </td>          
                <td>{bindValues.USER_ID}</td>
                <td>{moment(bindValues.DATA_LOAD_DATE).format('L')}</td>
            </tr>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        language: state.language,
        ui: state.content.ui,
        user: state.default,
        spendDetails: state.spendDetails,
        //content: state.content.spendAnalysis.enablementResourceParamaeters,
        content: state.content.menu.EnablementParametersManager[0].RampScheduleTab
    }
}


const mapDispatchToProps = (dispatch) => {
    return {        
        resetError: () => dispatch(resetError()),
        fetchGoLiveVsKickOffWeekData: () => dispatch(fetchGoLiveVsKickOffWeekData()),      
        updateGoLiveVsKickOffWeekData: (data) => dispatch(updateGoLiveVsKickOffWeekData(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GoLiveVsKickOffWeek)


