import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReactAvatarEditor from "react-avatar-editor";

class ProfileEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            image: "avatar.png",
            allowZoomOut: false,
            position: { x: 0.5, y: 0.5 },
            scale: 1,
            rotate: 0,
            borderRadius: 50,
            preview: null,
            width: 200,
            height: 200
        };
    }

    handleNewImage = e => {
        this.setState({ image: e.target.files[0] });
    };

    handleUpload = e => {
        if (this.editor) {
     
            // If you want the image resized to the canvas size (also a HTMLCanvasElement)
            const canvasScaled = this.editor.getImageScaledToCanvas().toDataURL();
            this.props.changeImage(canvasScaled);
          }
        //this.props.changeImage(this.state.image);
        this.props.hideModal("profileEditor")
    }
    handleScale = e => {
        const scale = parseFloat(e.target.value);
        this.setState({ scale });
    };

    handleRotateLeft = e => {
        let rotate = this.state.rotate - 90;
        this.setState({rotate})
    }

    handleRotateRight = e => {
        let rotate = this.state.rotate + 90;
        this.setState({rotate})
    }

    handlePositionChange = position => {
        this.setState({ position });
    };

    setEditorRef = (editor) => this.editor = editor

    render() {
        return (
            <div>
                <div className='profileEditor'>
                    <ReactAvatarEditor
                        ref={this.setEditorRef}
                        scale={parseFloat(this.state.scale)}
                        width={this.state.width}
                        height={this.state.height}
                        position={this.state.position}
                        onPositionChange={this.handlePositionChange}
                        rotate={parseFloat(this.state.rotate)}
                        borderRadius={this.state.width / (100 / this.state.borderRadius)}
                        image={this.state.image}
                        className="editor-canvas"
                    />
                </div>
                <br />
                <div className="grid-x rotate-button">
                    
                        <input
                            className="rotate-left"
                            name="rotate"
                            type="button"
                            value = "Left"
                            onClick={this.handleRotateLeft}
                        />
                    
                    
                        <input
                            className="rotate-right"
                            name="rotate"
                            type="button"
                            value = "Right"
                            onClick={this.handleRotateRight}
                        />
                    
                </div>
                <br />
                <div className="grid-x zoom-button">
                    <input
                        name="scale"
                        type="range"
                        onChange={this.handleScale}
                        min={this.state.allowZoomOut ? "0.1" : "1"}
                        max="2"
                        step="0.01"
                        defaultValue="1"
                    />
                </div>
                    
                <br /><br />
                <div className="grid-x">
                    <div className="cell medium-5">
                    <label id="select-file" style={{ width: '120px' }}
                    class="button-upload"> Choose File
                    <input type="file"
                        id="File"
                        onChange={this.handleNewImage}>
                    </input>
                </label>
                    </div>
                <div className="cell medium-5">
                <button onClick={this.handleUpload}>
                <label id="select-file" style={{ width: '120px' }}
                    class="button-upload"> Upload
                    
                </label>
                </button>
                </div>
                
                </div>
                                
            </div>
        );
    }
}

export default ProfileEditor;