import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Control, Form, Errors, actions } from 'react-redux-form'

// Actions
import {
	fetchUserValueStories,
	fetchValueStoriesLoading,
	fetchValueStoriesSuccess,
	fetchValueStoriesFailure,
	addValueStory,
	addValueStorySuccess,
	addValueStoryFailure,
	openValueStory,
	openValueStorySuccess,
	openValueStoryFailure,
	deleteValueStory,
	deleteValueStoryLoading,
	deleteValueStorySuccess,
	deleteValueStoryFailure,
	resetMessage,
	resetError,
	isSharedValueStoryOpen
}
	from 'actions/userActions'

import {
	initPrioritiesItems
}
	from 'actions/prioritiesActions'

import { valueStoryFetchDataSuccess } from 'actions/fetchValueStory'
import {
	setValueStoryDmciDetails,
	setResultSummaryDetails,
	setTopDetails
} from 'actions/spendActions'

// Components
import ValueStoryList from './ValueStoryList'

// UI
import Button from 'UI/Button'
import Textfield from 'react-mdl/lib/Textfield'
import Select from 'react-select'  //'react-virtualized-select'
import { BreakLine } from '../../utils/Utils'

import Modal from 'reboron/OutlineModal'
import ValueStoryShare from './ValueStoryShare'
import { valueStory_discovery_DropdowStyles } from 'react-select-css'
//CSS
//import '../../styles/value-story-select.css'

// Settings
import { modalStyle, modalContentStyle, changeModalStyle, changeModalContentStyle, backdropStyle, routes } from 'Constants';
const changeBackdropStyle = Object.assign({}, backdropStyle);
changeBackdropStyle.zIndex = '1050';
const alertModalStyle = Object.assign({}, changeModalStyle);
alertModalStyle.zIndex = '1060';
alertModalStyle.width = '45%';
const valueStoryModalContentStyle = Object.assign({}, modalContentStyle);
valueStoryModalContentStyle.padding = "20px";

const valueStoryModalStyle = Object.assign({}, modalStyle);
valueStoryModalStyle.width = "100%";
valueStoryModalStyle.maxWidth = "1250px";
valueStoryModalStyle.height = "99%";

const defaultOptions = [
	{ value: 0, label: "" }];

const BLURPENAME = '|BLURPENAME';



class ValueStoryContainer extends Component {

	constructor(props) {
		super(props)

		this.state = {
			selectedValueStory: null,
			isShared: null,
			responseMsg: '',
			isanySharedStory: false,
			isanyCreatorStory: false,
			selectedPEName: '',
			selectedPEId: '',
			filterdPENameOptions: [],
			filterdPEIdOptions: [],
			contentPE: []
		}

		this.fetchUserValueStories = this.fetchUserValueStories.bind(this)
		this.handleValueStoryClick = this.handleValueStoryClick.bind(this)
		this.openValueStory = this.openValueStory.bind(this)
		this.dispatchOpenValueStory = this.dispatchOpenValueStory.bind(this)
		this.deleteValueStory = this.deleteValueStory.bind(this)
		this.dispatchDeleteValueStory = this.dispatchDeleteValueStory.bind(this)
		this.redirect = this.redirect.bind(this)
		this.showModal = this.showModal.bind(this)
		this.showAlertModal = this.showAlertModal.bind(this)
		this.hideAlertModal = this.hideAlertModal.bind(this)
		this.actions = this.getApplicationFeatureActions();
	}

	handleValueStoryClick(VSId) {
		this.setState({
			selectedValueStory: VSId	
        })
	}

	clearSpendData() {
		this.props.setValueStoryDmciDetails([])
		this.props.setResultSummaryDetails([])
		this.props.setTopDetails([])
	}

	openValueStory() {
		var selectedVsItem = this.props.user.valueStories.filter(data => data.VSId === this.state.selectedValueStory)
					if(this.props.user.user.Id === selectedVsItem[0].Id)
					{
						this.dispatchOpenValueStory()
									this.props.hideModal('valueStory')
									this.clearSpendData()
					}else{
		              this.props.isSharedValueStoryOpen(this.state.selectedValueStory, this.props.user.user.Id)
		              	.then((response) => {
		              		if (response.status === 200) {
		              			this.dispatchOpenValueStory()
		              			this.props.hideModal('valueStory')
		              			this.clearSpendData()
		              		}
		              		else {
		              			var content = this.props.content;
		              			var httpStattus = response.response.data.Message
		              			var failMsg = content[httpStattus]
		              			if (failMsg !== undefined) {
		              				failMsg = failMsg.toString().replace("%userName%", response.response.data.Result)
		              				this.props.openValueStoryFailure(failMsg)
		              			}
		              		}
		              	}).catch((error) => {
		              		//console.log(error)
		              		//this.props.openValueStoryFailure(error.response.data.Message)
		              	})
					}
	}

	dispatchOpenValueStory() {
		var content = this.props.content;
		this.state.selectedValueStory &&
			this.props.openValueStory(this.state.selectedValueStory, this.props.user.user.Id)
				.then((response) => {
					if (response.status === 200) {
						//this.props.hideModal('valueStory')
						// Push value story data to store
						this.props.valueStoryFetchDataSuccess(response.data.Result)

						// Add indexes of selected priorities to priorities array in store
						let items = response.data.Result.AreaOfInterest.map((item, i) => item.Selected && i)
						items = items.filter((item) => item !== false)
						this.props.initPrioritiesItems(items)

						// Reset form
						//this.formDispatch(actions.reset('addValueStory'))

						// Redirect to Discovery page
						this.redirect()

						// update store status message
						// this.props.openValueStorySuccess()

						// reset store status message
						//setTimeout(() => {this.props.resetMessage()}, 100)
					}
					else {
						// setTimeout(() => {
						// 	this.props.showModal('valueStory')
						// }, 1000);

						var httpStattus = response.response.data.Message
						var failMsg = content[httpStattus]
						if (failMsg !== undefined) {
							failMsg = failMsg.toString().replace("%userName%", response.response.data.Result)
							this.props.openValueStoryFailure(failMsg)
						}
					}
				}).catch((error) => {
					//console.log(error)
					//this.props.openValueStoryFailure(error.response.data.Message)
				})
	}

	fetchUserValueStories() {
		this.props.fetchValueStoriesLoading(true)
		this.props.fetchUserValueStories(this.props.user.user.Id)
			.then((result) => {
				if (result.status === 200) {
					this.props.fetchValueStoriesSuccess(result)
					for (var i = 0; i < result.data.Result.length; i++) {
						var obj = result.data.Result[i];
						if (obj.Id !== localStorage.getItem('id')) {
							this.setState({
								isanySharedStory: true
							})
							//break;
						}
					}
					for (var i = 0; i < result.data.Result.length; i++) {
						var obj = result.data.Result[i];
						if (obj.Id === localStorage.getItem('id')) {
							this.setState({
								isanyCreatorStory: true
							})
							break;
						}
					}
				}
			}).catch((error) => {
				if (error.response !== undefined && error.response.data !== undefined && error.response.data.Message !== undefined) {
					this.props.fetchValueStoriesFailure(error.response.data.Message)
				}
				// else if (error.stack.includes('Network Error')) {
				// 	this.props.fetchValueStoriesFailure('API service is down. Please contact administrator.')
				// }

			})
	}

	deleteValueStory() {
		// Value Story must be selected
		if (this.state.selectedValueStory) {

			// If a Value Story is currently open
			if (this.props.userData) {
				// You can only delete Value Stories that are not currently opened
				if (this.state.selectedValueStory !== this.props.userData.VSId) {
					this.dispatchDeleteValueStory()
					this.setState({
						selectedValueStory: null
					})

				} else {
					this.props.deleteValueStoryFailure(this.props.content.validation.cannotDelete)
					this.hideAlertModal("alert")
				}
			} else {
				this.dispatchDeleteValueStory()
				this.setState({
					selectedValueStory: null
				})
			}
		}
	}

	dispatchDeleteValueStory() {
		this.setState({ responseMsg: "" })
		let values = {
			VSId: this.state.selectedValueStory,
			Language: this.props.language.toUpperCase()
		}
		var content = this.props.content;
		this.state.selectedValueStory &&
			this.props.deleteValueStory(values)
				.then((result) => {
					//var msg = content[result.status];
					setTimeout(() => {
						if (result.status === 200) {
							this.props.deleteValueStorySuccess(content[result.status])
							this.setState({ responseMsg: content[result.status] })
							this.fetchUserValueStories()
						}
						else {
							var failMsg = content[result.response.status]
							if (failMsg !== undefined) {
								this.props.deleteValueStoryFailure(failMsg)
							}
						}
						this.hideAlertModal("alert")
					}, 500)

				}).catch((error) => {
					this.props.deleteValueStoryFailure(content[error.response.status])
					this.hideAlertModal("alert")
				})
	}

	showModal(action, vsid, isShared) {
		this.setState({
			selectedValueStory: vsid,
			isShared: isShared
		})
		this.refs[action].show()
	}

	hideModal(action) {
		this.refs[action].hide()
	}

	showAlertModal(action) {
		this.refs[action].show()
	}

	hideAlertModal(action) {
		this.refs[action].hide()
	}

	redirect() {
		if(this.actions.indexOf(routes.yourSpend)> -1)
		{
           this.props.history.push(this.actions.indexOf(routes.businessValue)> -1 ?  `/discovery` : `/yourSpend`)		
		}
		else{
			this.props.history.push(`/discovery`)
		}	
		
	}

	componentDidMount()
	{
		this.props.resetError();
	}

	componentWillMount() {
		this.fetchUserValueStories();
		//this.storePEData();
	}

	showShareValueStory() {
		var resp = Object.values(this.props.user.user.applicationFeatures).map(feature => {
			if (this.props.content[feature.Action] !== undefined) {
				return true;
			}
		})
		if (resp.indexOf(true) > 0) {
			return true;
		}
	}

	componentWillUnmount() {
		//this.formDispatch(actions.reset('addValueStory'))
		this.props.resetError();
	}

	getApplicationFeatureActions() {
		let actions = [];
		let applicationFeatures = this.props.user && this.props.user.user && this.props.user.user.applicationFeatures ? this.props.user.user.applicationFeatures : [];
		applicationFeatures.map(applicationFeat => {
			actions.push(applicationFeat.Action);
		})
		return actions;
	}

	render() {
		return (
			<div className="valuestory popup-content medium-12">
				<div className="grid-x">
					<div className="medium-12">
						<ValueStoryList
							valueStories={this.props.user.valueStories}
							handleValueStoryClick={this.handleValueStoryClick}
							openValueStory={this.openValueStory}
							selectedValueStory={this.state.selectedValueStory}
							showModal={this.showModal}
							hideModal={this.hideModal}
							isanySharedStory={this.state.isanySharedStory}
							isanyCreatorStory={this.state.isanyCreatorStory}
							email={this.props.user.user.Email}
							enableShareValueStory={this.showShareValueStory()}
							content={this.props.content}
						/>
						{
							this.state.responseMsg !== "" ?
								<div className="success">{this.state.responseMsg}</div>
								: <div className="errors">{this.props.user.error}</div>
						}

						{
							(this.props.user.valueStories.length === 0) ?
								<div className="grid-x">
									<div className="medium-12">
										{/* <Button
											onClick={this.openValueStory}
											disabled={this.props.user.loading}
											content={this.props.content.open}
											float
											color
										/>
										<Button
											onClick={this.deleteValueStory}
											disabled={this.props.user.loading}
											content={this.props.content.delete}
											icon="x"
											float
										/> */}
									</div>
								</div>
								:
								// Remove below line once get approval to rollout sharing logic to partners as well
								(this.showShareValueStory()) ?
									<div className="grid-x grid-padding-x">
										<div className="medium-5">
											{(this.state.isanyCreatorStory === true) ?
												<div className="medium-12">
													<img width="23" src="images/share-value-story.png" />
													<span style={{ 'fontSize': '12px' }}>{this.props.content.creatorStroy}</span>
												</div>
												: null
											}
											{(this.state.isanySharedStory === true) ?
												<div className="medium-12 pt5">
													<img width="23" src="images/shared-details.png" />
													<span style={{ 'fontSize': '12px' }} >{this.props.content.sharedStroy}</span>
												</div>
												: null
											}
										</div>

									</div>
									: null}
					{this.props.user.valueStories.length > 0? 
						<div className="grid-x grid-padding-x">
							<div className="medium-12">
								<div className="float-right">
									<div className="float-left">
										<Button
											onClick={() => this.showAlertModal('alert')} //{this.deleteValueStory}
											disabled={this.props.user.loading}
											content={this.props.content.delete}
											icon="x"
										/>
									</div>
									<div className="float-left">
										<Button
											onClick={this.openValueStory}
											disabled={this.props.user.loading}
											content={this.props.content.open}
											color
										/>
									</div>
								</div>
							</div>
						</div>
					:null }

					</div>
				</div>
				<div>
					<Modal
						ref="shareValueStory"
						modalStyle={valueStoryModalStyle}
						contentStyle={valueStoryModalContentStyle}>
						{this.props.user.valueStories.constructor === Array ? 
						<ValueStoryShare
						    SelectedVSData = {this.props.user.valueStories.filter(vs=>vs.VSId == this.state.selectedValueStory)}
							VSId={this.state.selectedValueStory}
							Content={this.props.content}
							IsShared={this.state.isShared}
						/>
						:<span style={{color:'red'}}>this.props.user.valueStories.payload</span>}
						<button onClick={() => this.hideModal('shareValueStory')} className="close" />
					</Modal>
				</div>
				<div>
					<Modal
						ref="alert"
						modalStyle={alertModalStyle}
						contentStyle={changeModalContentStyle}
						backdropStyle={changeBackdropStyle}
					//keyboard={() => this.callback()}
					>
						<div className="grid-x grid-padding-x change-popup">
							<div className="medium-12">
								<button onClick={() => this.hideAlertModal("alert")} className="close" />
							</div>
							<div className="medium-12 pt10">
								{this.props.content.deleteConfirmation}
							</div>
							<div style={{ paddingRight: '25px' }} className="medium-12 popupOkBtn pt15">
								<div className="float-right">
									<div className="float-left"><Button
										content={'Yes'}
										onClick={this.deleteValueStory}
										color />
									</div>
									<div className="float-left"><Button
										content={'No'}
										onClick={() => this.hideAlertModal("alert")}
										color />
									</div>
								</div>
							</div>
						</div>
					</Modal>
				</div>
			</div>

		)
	}
}

const mapStateToProps = (state) => {
	return {
		language: state.language,
		user: state.default,
		userData: state.valueStory,
		valueStoryHasErrored: state.valueStoryHasErrored,
		valueStoryIsLoading: state.valueStoryIsLoading,
		validation: state.content.validation,
		contentPE: state.contentPE
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		fetchUserValueStories: (id) => dispatch(fetchUserValueStories(id)),
		fetchValueStoriesLoading: (bool) => dispatch(fetchValueStoriesLoading(bool)),
		fetchValueStoriesSuccess: (valueStories) => dispatch(fetchValueStoriesSuccess(valueStories)),
		fetchValueStoriesFailure: (error) => dispatch(fetchValueStoriesFailure(error)),
		initPrioritiesItems: (items) => dispatch(initPrioritiesItems(items)),
		addValueStory: (formValues) => dispatch(addValueStory(formValues)),
		addValueStorySuccess: (valueStory) => dispatch(addValueStorySuccess(valueStory)),
		addValueStoryFailure: (error) => dispatch(addValueStoryFailure(error)),
		deleteValueStory: (id) => dispatch(deleteValueStory(id)),
		deleteValueStoryLoading: (bool) => dispatch(deleteValueStoryLoading(bool)),
		deleteValueStorySuccess: (message) => dispatch(deleteValueStorySuccess(message)),
		deleteValueStoryFailure: (error) => dispatch(deleteValueStoryFailure(error)),
		openValueStory: (VSId, Id) => dispatch(openValueStory(VSId, Id)),
		openValueStorySuccess: () => dispatch(openValueStorySuccess()),
		openValueStoryFailure: (error) => dispatch(openValueStoryFailure(error)),
		isSharedValueStoryOpen: (VSId, Id) => dispatch(isSharedValueStoryOpen(VSId, Id)),
		valueStoryFetchDataSuccess: (valueStory) => dispatch(valueStoryFetchDataSuccess(valueStory)),
		resetMessage: () => dispatch(resetMessage()),
		resetError: () => dispatch(resetError()),
		setValueStoryDmciDetails: (data) => dispatch(setValueStoryDmciDetails(data)),
		setResultSummaryDetails: (data) => dispatch(setResultSummaryDetails(data)),
		setTopDetails: (data) => dispatch(setTopDetails(data))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ValueStoryContainer)
