import React, { Component } from 'react';
import { connect } from 'react-redux'
import { validators } from 'Constants';
import { Control, Form, Errors, actions } from 'react-redux-form'
// Utils
import { BreakLine } from 'utils/Utils'
// UI
import Textfield from 'react-mdl/lib/Textfield'
import Button from 'UI/Button'
import moment from 'moment'


import {
	capIqUserManagerLoading,
	capIqUserManagerSuccess,
	capIqUserManagerFailure,	
	resetError,
	getAllCapIqUsers,
	addCapIqUser,
	deleteCapIqUser
} from 'actions/roleAndPermissionAction';

class CapIQLicensedUser extends Component {
	constructor(props) {
		super(props)
		this.state = {	
			capIqUsersData : []	,
			filterText: "",
			responseMsg: ""
		}	
	}

	componentDidMount() {
		this.props.resetError()
		this.loadData();
	}

	componentWillUnmount() {
		this.formDispatch(actions.reset('capIqUserManager'))
		this.props.resetError()
	}


	loadData() {
		this.props.getAllCapIqUsers().then((response) => {
			this.props.capIqUserManagerSuccess(response.data.Message)
			this.setState({ capIqUsersData: response.data.Result });
		})		
	}

	handleUserInput(filterText) {
		this.setState({ filterText: filterText.target.value.toLowerCase() });
	  };

	handleAddCapIqUser(formValues) {

		this.props.resetError()
		var formData = { Email_Id: formValues.Email, AddedBy: this.props.user.user.Id };

		this.props.addCapIqUser(formData)
			.then((response) => {
				this.loadData()
				this.props.capIqUserManagerSuccess(response.data.Message)
				this.setState({responseMsg : this.props.content.addSuccess})

			}).catch((error) => {
				if (error.stack.includes('Network Error')) {
					this.props.capIqUserManagerFailure(this.props.validation.networkError)
				}
				else {
					this.props.capIqUserManagerFailure(error.response.data.Message)
				}
			})

	}	

	handleRowDel(userDetails) {
		
		const result = window.confirm(this.props.validation.confirmDelete);
		if (result) {
			var formValues = { Email_Id: userDetails.Email_Id, UpdatedBy: this.props.user.user.Id };
			this.props.deleteCapIqUser(formValues)
				.then((response) => {					
					this.setState({ responseMsg: this.props.content.deleteSuccess })
					this.props.capIqUserManagerSuccess(response.data.Message)
					var index = this.state.capIqUsersData.indexOf(userDetails);
					this.state.capIqUsersData.splice(index, 1);
					this.setState(this.state.capIqUsersData);					
				}).catch((error) => {
					if (error.stack.includes('Network Error')) {
						this.props.capIqUserManagerFailure(this.props.validation.networkError)
					}
					else {
						this.props.capIqUserManagerFailure(error.response.data.Message)
					}
				})
		}
	};

	render() {
		return (
			<div>
				<div>
					<div className="grid-x">
						<div className="cell"><h2>{this.props.content.title}</h2></div>	
						<div>
							<Form
								model="capIqUserManager"
								getDispatch={(dispatch) => this.formDispatch = dispatch}
								onSubmit={(values) => this.handleAddCapIqUser(values)}
							>
								<div className="grid-x grid-padding-x">
									<div className="medium-8 cell">
									<SearchBar props={this.props.validation} filterText={this.state.filterText} onUserInput={this.handleUserInput.bind(this)} />
								    </div>
									<div className="medium-2 cell pt10">
										<Button
											type="submit"
											disabled={this.props.user.isLoading}
											content={this.props.content.add}
											color
										/>
									</div>
									</div>
							</Form>
							<div className="errors">{this.props.user.error}</div>
                            <div className="success">{this.state.responseMsg}</div>
							<div>
							{ this.state.capIqUsersData !== undefined ?
				         	<CapIqUserTable
				        	    onRowDel={this.handleRowDel.bind(this)}						
								data={this.state.capIqUsersData}
								filterText={this.state.filterText}
					     	/>
					     	 :null}
			            	</div>	
							
						</div>					
					</div>
				</div>	
				
			</div>
		)
	}
}

class SearchBar extends React.Component {
	constructor(props) {
	  super(props);
	}
  
	handleChange(event) {
	  this.props.onUserInput(event);
	}
  
	render() {
	  return (
		<div className="medium-4 cell grid-padding-5">
		  <Control.text
			model="capIqUserManager.Email"
			id="capIqUserManager.Email"
			component={Textfield}
			label={'Email'}
			floatingLabel
			ref="filterTextInput"
			onChange={this.handleChange.bind(this)}
			validators={{
			  validEmail: validators.validEmail
			}}
			validateOn="blur"
		  />
		  <Errors
			className="errors"
			model="activateUser.Email"
			show="touched"
			messages={{
			  validEmail: this.props.props.validEmail
			}}
		  />
		</div>
	  );
	}
  }

class CapIqUserTable extends React.Component {
	render() {

    var filterText = this.props.filterText;
	var rowDel = this.props.onRowDel;
	
	var data = this.props.data.map(function (data) {
      if (data.Email_Id.toLowerCase().indexOf(filterText) === -1) {
        return;
      }
    
      else return (<CapIqUserRow
        data={data}
		key={data.Email_Id}
		onDelEvent={rowDel.bind(this)} 
		/>
		)
    });

		return (
			<div className="basic-table">
				<table>
					<thead>
						<tr>
							<th>Email</th>
							<th>AddedBy</th>
							<th>Added On</th>							
							<th></th>							
						</tr>
					</thead>

					<tbody>
						{data}
					</tbody>
				</table>
			</div>
		);
	}
}

class CapIqUserRow extends React.Component {
	constructor(props) {
		super(props);
		this.state = {			
		};		
	}	

	onDelEvent() {
		this.props.onDelEvent(this.props.data);
	}	

	render() {
		return (
			<tr className="eachRow">
				<td>{this.props.data.Email_Id}</td>			
				<td>{this.props.data.AddedBy}</td>
				<td>{moment(this.props.data.AddedDate).format('L')}</td>
				<td>
					<button onClick={this.onDelEvent.bind(this)} >Delete</button>						
				</td>				
			</tr>
		);
	}
}



const mapStateToProps = (state) => {
	return {
		language: state.language,
		ui: state.content.ui,
		user: state.default,
		content: state.content.capIQUserManager,
		validation: state.content.validation,
		roleManager: state.userManagerUpdates
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		capIqUserManagerLoading: (bool) => dispatch(capIqUserManagerLoading(bool)),
		capIqUserManagerSuccess: (bool) => dispatch(capIqUserManagerSuccess(bool)),
		capIqUserManagerFailure: (error) => dispatch(capIqUserManagerFailure(error)),
		resetError: () => dispatch(resetError()),		
		getAllCapIqUsers: () => dispatch(getAllCapIqUsers()),
		addCapIqUser: (formValues) => dispatch(addCapIqUser(formValues)),
		deleteCapIqUser: (formValues) => dispatch(deleteCapIqUser(formValues))
	}
}


export default connect(mapStateToProps, mapDispatchToProps) (CapIQLicensedUser)
