import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
	fetchScreens,
	resetError,
	saveScreenVideo,
	saveScreenVideoFile,
	getAllRoles
} from 'actions/videoRoleAction';

import Button from 'UI/Button'
import Select from 'react-select'
import classNames from "classnames"
import { path, changeModalStyle, changeModalContentStyle } from 'Constants'
import cloneDeep from 'lodash/cloneDeep';
import { roundFix, formatThousands, getNonEmptyValue } from 'utils/Utils'
import { Formik, withFormik } from 'formik'
import { Checkbox } from 'material-ui';

import Modal from 'reboron/OutlineModal'

class ScreenVideo extends Component {
	constructor(props) {
		super(props)
		this.state = {
			videoData: props.videoData,
			selectedVideos: [],
			modules: [],
			moduleToScreen: [],
			screens: [],
			roles: [],
			videoFile: {}
		}

		this.loadData = this.loadData.bind(this)
		this.fetchVideoScreens = this.fetchVideoScreens.bind(this)
		this.handleModuleChange = this.handleModuleChange.bind(this)
		this.handleScreenChange = this.handleScreenChange.bind(this)
		this.getRoleRenderer = this.getRoleRenderer.bind(this)
		this.handleRoleCheckChanged = this.handleRoleCheckChanged.bind(this)
		this.onSave = this.onSave.bind(this)
		this.uploadFile = this.uploadFile.bind(this)
		this.selectFile = this.selectFile.bind(this)
	}

	showModal(action) {
        this.refs[action].show()
    }

    hideModal(action) {
		this.refs[action].hide()
		this.props.close();
    }

	uploadFile() {
		document.getElementById("select-file").click();
	  }
	
	  selectFile(event) {
		let videoFile = event.target.files[0];
		this.setState({videoFile: videoFile})
	  }

	handleModuleChange(value, props) {
		const screens = this.state.moduleToScreen[value]
		const videoData = this.state.videoData
        videoData.Module = value;
		this.setState({videoData: videoData, screens: screens})
	}

	handleScreenChange(value, props) {
		const videoData = this.state.videoData
        videoData.ScreenName = value;
		this.setState({videoData: videoData})
	}

	handleRoleCheckChanged(value){
		const videoData = this.state.videoData
		var id = value.target.id;
		let isChecked = value.target.checked? 1 : 0
		let roleIdx = this.state.roles.findIndex(role => {
			return role.RoleId === id
		})

		let role = this.state.roles[roleIdx];
		role.IsActive = isChecked

		let videoRoleIdx = videoData.ScreenVideoRoles.findIndex(role => {
			return role.RoleId === id
		})

		if(videoRoleIdx === -1){
			role.VideoRoleId = -1;
			videoData.ScreenVideoRoles.push(role)
		}
		else{
			videoData.ScreenVideoRoles[videoRoleIdx].IsActive = isChecked
		}

		this.setState({videoData: videoData})
	}

	componentDidMount() {
		this.props.resetError()
		this.loadData();
		this.keyCount = 1;
	}

	loadData() {
		this.getAllRoles();
		this.fetchVideoScreens(true);
	}

	getAllRoles(){
		this.props.getAllRoles().then((response)=> {
			this.setState({ roles: response.data.Result});
		})
	}

	fetchVideoScreens(showLoading) {
		this.setState({ isLoading: true, isError: 'No', responseMsg: '' })
		this.props.fetchScreens()
			.then((response) => {
				//var t = (showLoading === true ? this.props.roleManagerSuccess(response.data.Message) : null)
				if(response.data.Result.length > 0) {
					const moduleMap = [];
					const data = response.data.Result;
					data.forEach(datum => {
						let screens = moduleMap[datum.Module]
						if(!screens) {
							screens = []
							moduleMap[datum.Module] = screens
						}

						screens.push({label: datum.ScreenName, value: datum.ScreenName})
					})

					const screens = this.state.videoData.VideoId !== -1 ? moduleMap[this.state.videoData.Module] : []

					this.setState({isLoading: false, modules: Object.keys(moduleMap).map(key => { return {label: key, value: key}}), moduleToScreen: moduleMap, screens: screens})
				}
			}).catch((error) => {
				if (error.stack.includes('Network Error')) {
				//	this.props.roleManagerFailure()
				}
				else {
					//this.props.roleManagerFailure(error.response.data.Message)
				}
			})
	}

	onSave(data) {
		this.setState({ isLoading: true, isError: 'No', responseMsg: '' })
		this.props.saveScreenVideo(data, this.state.videoFile)
			.then((response) => {
				//var t = (showLoading === true ? this.props.roleManagerSuccess(response.data.Message) : null)
				if(response.status === 200) {
					this.props.getResult(response.data.Result, this.state.videoData.VideoId === -1)
					this.showModal("terms")
				} else {
					this.setState({ isLoading: false, isError: 'No', responseMsg: 'Record has been updated successfully' })
				}
			}).catch((error) => {
				if (error.stack.includes('Network Error')) {
                    this.setState({ responseMsg: 'API service is down. Please contact administrator.', isError: 'Yes', isLoading: false })
                }
                else {
                    this.setState({ responseMsg: error.response.data.Message, isError: 'Yes', isLoading: false })
                }
			})
	}

	handleChange(value, field, props) {
        const videoData = this.state.videoData
        videoData[field] = value;
        this.setState({videoData: videoData})
    }

	getRoleRenderer() {
		var roles = Object.values(this.state.roles).map((role) =>{
			var vidIdx = (this.state.videoData.ScreenVideoRoles.findIndex(vidRole => {
				return vidRole.RoleId === role.RoleId}))
			let check = vidIdx !== -1 ? this.state.videoData.ScreenVideoRoles[vidIdx].IsActive : false
			return (
					<div className="grid-x chkContainer"> 						
						<div className="checkBox">
							<Checkbox id={role.RoleId} checked={check} onClick={(e) => this.handleRoleCheckChanged(e)}  
							name={role.RoleId} value={role.RoleName}  /> 
						</div>
						<div>
							<p id={role.RoleId} className="normalFont" onClick={(e) => this.handleRoleCheckChanged(e)} defaultValue={role.RoleId} >{role.RoleName}</p>
						</div>
					
				</div>
			)
		})

		return roles;
	}

    render() {
		return (
            <div className='grid-x'>
				<div className="medium-3">
					<div style={{ fontSize: '16px', color: '#008fd3', paddingLeft: '5px' }} >{this.props.content.newVideo}</div>
				</div>
				<Modal
                        ref="terms"
                        modalStyle={changeModalStyle}
                        contentStyle={changeModalContentStyle}
                    >
                        <div className="grid-x grid-padding-x change-popup">
                            <div className="medium-12">
                                <button onClick={() => this.hideModal("terms")} className="close" />
                            </div>
                            <div className="medium-12 pt30">
								Video has been saved successfully.
                            </div>
                            <div className="medium-12 popupOkBtn">
                                <div className="float-right">
                                    <div className="float-left"><Button
                                        content={"Close"}
                                        onClick={() => this.hideModal("terms")}
                                        color /></div>
                                </div>
                            </div>
                        </div>

                    </Modal>
				<div className='medium-12'>
					<Formik
					enableReinitialize={true}
					initialValues={this.state.videoData}
					validate={values => {
						const errors = {};
						if (!values.ScreenVideoRoles && values.ScreenVideoRoles.length === 0) {
						  errors.videoRole = 'Role is mandatory';
						} 
						return errors;
					  }}
					onSubmit={(values, actions) => {
						this.onSave(values)
						actions.setSubmitting(false);
					}}
					render={props =>  (
						<div className="grid-container font12">
							<form onSubmit={props.handleSubmit}>
							<div className="grid-x tabs_container">
								<div className="medium-12">
									<div className="grid-x grid-padding-x">
										<div className="medium-12">
											{(this.state.isLoading) ?
												<img style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={path + `images/ajax-loader.gif`} alt="" />
												: null
											}
											{(this.state.isError === 'Yes') ?
												<div style={{ color: 'red', textAlign: 'center' }} className="cell pt5">{this.state.responseMsg}</div>
												: <div style={{ color: 'green', textAlign: 'center' }} className="cell pt5">{this.state.responseMsg}</div>
											}
										</div>
									</div>
									<div className="grid-x">
										<div className="medium-12">
											<Button className={classNames('float-right', 'pt10', 'pr25')}
												disabled={this.state.isLoading}
												type="submit" title={this.props.ui.save} content='Save' float color disabled={props.isSubmitting}>
											</Button>
										</div>
									</div>
								</div>
								<div className="medium-4 pr10">  
									<div className="grid-x">
										<div className="medium-3 text-align-right font-bold min-control-height vertical-align-center">
											<span>{this.props.content.name}</span>
										</div>
										<div className="medium-9 min-control-height vertical-align-center">
											<input type="text"
												name="Name"
												key="Name"
												className="border-with-DD width-100 control-radius control-inline-start"
												onChange={(event) => this.handleChange(event.target.value, 'Name', props)}
												onBlur={props.handleBlur}
												value={props.values.Name}
												style={{height: '38px'}}
												required
											/>
										</div>
										<div className="medium-3 text-align-right font-bold min-control-height vertical-align-center">
											{this.props.content.desc}
										</div>
										<div className="medium-9 min-control-height vertical-align-center">
											<input type="text"
												name="Description"
												key="Description"
												className="border-with-DD width-100 control-radius control-inline-start"
												onChange={(event) => this.handleChange(event.target.value, 'Description', props)}
												onBlur={props.handleBlur}
												value={props.values.Description}
												style={{height: '38px'}}
												required
											/>
										</div>
										<div className="medium-3 text-align-right font-bold min-control-height vertical-align-center">
											{this.props.content.module}
										</div>
										<div className="medium-9 paddingAll5">
											<Select
											className="border-with-DD dropDownContent width-100"
											name='Module'
											key='Module'
											value={this.state.modules.filter(
												option => option.label == props.values.Module
											)}
											onChange={(value) => this.handleModuleChange(value.value, props)}
											onBlur={(value) => props.setFieldTouched('Module', true)}
											options={this.state.modules}
											isSearchable={false}
											noOptionsMessage={() => null}
											required
											/>
										</div>
										<div className="medium-3 text-align-right font-bold min-control-height vertical-align-center">
											{this.props.content.screen}
										</div>
										<div className="medium-9 paddingAll5">
										<Select
											className="border-with-DD dropDownContent width-100"
											key='ScreenName'
											name='ScreenName'
											value={this.state.screens.filter(
												option => option.label == props.values.ScreenName
											)}
											onChange={(value) => this.handleScreenChange(value.value, props)}
											onBlur={(value) => props.setFieldTouched('ScreenName', true)}
											options={this.state.screens}
											isSearchable={false}
											noOptionsMessage={() => null}
											required
											/>
										</div>
										<div className="medium-3 text-align-right font-bold min-control-height vertical-align-center">
											{this.props.content.type}
										</div>
										<div className="medium-9 min-control-height vertical-align-center">
											<input type="checkbox"
												name="SourceType"
												key="SourceType"
												className="border-with-DD"
												onChange={(event) => this.handleChange(event.target.checked, 'SourceType', props)}
												onBlur={props.handleBlur}
												checked={props.values.SourceType}
												style={{height: '38px'}}
											/>
										</div>
										<div className="medium-3 text-align-right font-bold min-control-height vertical-align-center">
											{this.props.content.source}
										</div>
										{props.values.SourceType ?
											<div className="medium-9 min-control-height vertical-align-center">
											<input id="select-file" type="file" accept="video/*"
												onChange={(e) => this.selectFile(e)} style={{display: 'block'}} />
											</div> : 
											<div className="medium-9 min-control-height vertical-align-center">
												<input type="text"
													name="VideoSource"
													key="VideoSource"
													className="border-with-DD width-100 control-radius control-inline-start"
													onChange={(event) => this.handleChange(event.target.value, 'VideoSource', props)}
													onBlur={props.handleBlur}
													value={props.values.VideoSource}
													style={{height: '38px'}}
													required
												/>
											</div>}
										<div className="medium-3 text-align-right font-bold min-control-height vertical-align-center">
											{this.props.content.order}
										</div>
										<div className="medium-9 min-control-height vertical-align-center">
											<input type="number"
												name="Order"
												key="Order"
												className="border-with-DD width-100 control-radius control-inline-start"
												onChange={(event) => this.handleChange(event.target.value, 'Order', props)}
												onBlur={props.handleBlur}
												value={props.values.Order}
												style={{height: '38px'}}
												min={0}
												required
											/>
										</div>
									</div>
								</div>
								<div className="medium-1 pr10">  
									<div className="grid-x">
										
									</div>
								</div>
								<div className="medium-6 pr10">  
									<div className="grid-x">
										{this.getRoleRenderer()}
										{props.errors.videoRole}
									</div>
								</div>
							</div>
						</form>
					</div >
					)} />
				</div>
            </div>
        );
	}
}



const mapStateToProps = (state) => {
	return {
		language: state.language,
		ui: state.content.ui,
		user: state.default,
		content: state.content.videoManager,
		validation: state.content.validation
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		resetError: () => dispatch(resetError()),
		fetchScreens: () => dispatch(fetchScreens()),
		getAllRoles: () => dispatch(getAllRoles()),
		saveScreenVideoFile: (file) => dispatch(saveScreenVideoFile(file)),
		saveScreenVideo: (formValues, file) => dispatch(saveScreenVideo(formValues, file)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ScreenVideo)