import React from 'react'
import PropTypes from 'prop-types'

//UI
import Button from 'UI/Button'

// Utils
import classnames from 'classnames'

const DiscoveryNextButton = (props) =>
	<div className={
		classnames(
			'float-right',
			{ 'discovery-next-button--hidden': props.visible }
		)}
		onClick={props.click}
	>
		<Button onClick={props.click} type="submit" content={props.content} />
	</div>

export default DiscoveryNextButton

DiscoveryNextButton.propTypes = {
	visible: PropTypes.bool.isRequired,
	click: PropTypes.func.isRequired,
	content: PropTypes.string.isRequired
}