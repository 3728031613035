import React from 'react'
import { connect } from 'react-redux'
import { path } from 'Constants'
import Select from 'react-select'
import {
    getSpendKPIBenchmark
} from 'actions/kpiActions'

class SpendKPIBenchmark extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dropDownValues: [
                { value: "All", label: "All" },
                { value: "Financial Services", label: "Financial Services" },
                { value: "Consumer", label: "Consumer" },
                { value: "Discrete Manufacturing", label: "Discrete Manufacturing" },
                { value: "Energy and Natural Resources", label: "Energy and Natural Resources" },
                { value: "Services", label: "Services" },
                { value: "Public Services", label: "Public Services" },
                { value: "EMEA", label: "EMEA" },
                { value: "LAC", label: "LAC" },
                { value: "APJ", label: "APJ" },
                { value: "NA", label: "NA" }
            ],
            selectedValue: 'All',
            spendKPIParameters: [],
            isLoading: false,
            respnseMessage: '',
            isError: false
        }
        this.loadData = this.loadData.bind(this)
    }

    loadData() {
        this.setState({ isLoading: true, respnseMessage: '', isError: false });
        this.props.getSpendKPIBenchmark(this.state.selectedValue).then((response) => {
            if (response.status === 200) {
                this.setState({ spendKPIParameters: response.data.Result, isLoading: false, respnseMessage: '', isError: false });
            }
            else {
                this.setState({ isLoading: false, respnseMessage: this.props.content.errorMessage, isError: true });
            }
        }).catch((error) => {
            this.setState({ isLoading: false, respnseMessage: this.props.content.errorMessage, isError: true });
        })
    }

    handleChange(data) {
        let value = data.value;
        this.setState({ selectedValue: value },
            () => {
                this.loadData()
            })
    }

    componentDidMount() {
        this.loadData();
    }

    render() {
        var KPI_Benchmark = this.state.spendKPIParameters.map(function (parameter) {
            return (<TableRows
                KPI_Parameter={parameter}
            />
            );
        });

        return (
            <div>
                <div className="grid-x">
                    <div cassName='medium-12'>
                        <div style={{ width: '260px' }} className="float-left">
                            <div className="dropDownHeader">Benchmark Metrics</div>
                            <Select
                                className="border-with-DD dropDownContent"
                                autocomplete='true'
                                value={this.state.dropDownValues.filter(
                                    option => option.value === this.state.selectedValue
                                )}
                                onChange={value => this.handleChange(value)}
                                options={this.state.dropDownValues}
                                isSearchable={false}
                                //isDisabled={this.state.isLoading}
                                noOptionsMessage={() => null}
                            />
                        </div>
                    </div>
                    {/* <div className="medium-12">
                        <div>
                            {(this.state.isLoading) ?
                                <img style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
                                    src={path + `images/ajax-loader.gif`} alt="" />
                                : null
                            }
                        </div>
                    </div> */}
                </div>
                <div className="grid-x">
                    <div className="medium-12">
                        <div style={{ marginTop: '20px' }} className="basic-table">
                            <table>
                                <thead>
                                    <tr className="eachRow">
                                        <th>{'Metric Id'}</th>
                                        <th>{'KPI Label'}</th>
                                        <th>{'Source'}</th>
                                        <th>{'Data Source'}</th>
                                        <th>{'Process Area'}</th>
                                        <th>{'Top 25%'}</th>
                                        <th>{'Average'}</th>
                                        <th>{'Bottom 25%'}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {KPI_Benchmark}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

class TableRows extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        var bindValues = this.props.KPI_Parameter;
        return (
            <tr className="eachRow">
                <td>{bindValues.METRIC_ID}</td>
                <td>{bindValues.KPI_LABEL}</td>
                <td>{bindValues.KPI_LABEL === 'Unique Suppliers' ? 'NA' : 'Athena (2018-2019)'}</td>
                <td>{bindValues.DATA_SOURCE}</td>
                <td>{bindValues.PROCESS_AREA}</td>
                <td>{bindValues.TOP_25}</td>
                <td>{bindValues.AVERAGE}</td>
                <td>{bindValues.BOTTOM_25}</td>
            </tr>
        );
    }
}

const mapStateToProps = (state) => {
    return {

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getSpendKPIBenchmark: (selectedValue) => dispatch(getSpendKPIBenchmark(selectedValue)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SpendKPIBenchmark)
