import {
	USER_MANAGER_LOADING, USER_MANAGER_SUCCESS,
	USER_MANAGER_FAILURE, RESET_ERROR, ROLE_MANAGER_LOADING, ROLE_MANAGER_SUCCESS,
	ROLE_MANAGER_FAILURE
} from 'actions/roleAndPermissionAction';



const INITIAL = { isLoading: false, error: null, message: null }
export const userManagerUpdates = (state = INITIAL, action) => {
	switch (action.type) {
		case USER_MANAGER_LOADING:
			return { ...state, isLoading: action.isLoading }
		case USER_MANAGER_SUCCESS:
			return { ...state, isLoading: false, message: action.payload };
		case USER_MANAGER_FAILURE:
			return { ...state, isLoading: false, error: action.payload };

		case ROLE_MANAGER_LOADING:
			return { ...state, isLoading: action.isLoading }
		case ROLE_MANAGER_SUCCESS:
			return { ...state, isLoading: false, message: action.payload };
		case ROLE_MANAGER_FAILURE:
			return { ...state, isLoading: false, error: action.payload };

		case RESET_ERROR:
			return { ...state, isLoading: false, error: null, message: null }
		default:
			return state
	}
}
 