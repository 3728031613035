import React from 'react'
import PropTypes from 'prop-types'

const Value = (props) =>
	<div className={props.className + '__value'}>
		<div className="value-title">{props.text}</div>
		<div className="value-text">
			<span className="currency">{props.currency}</span>
			<span className="amount">{props.amount}</span>
			<span className="millions">M</span>
		</div>
	</div>

export default Value

Value.propTypes = {
	currency: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired
}
