/*
 * NODE_ENV is hardcoded by create-react-app
 * and cannot be set in package.json
 * so we use a substitute
 */
export const env = process.env.NODE_ENV


export let path = '/'
/*
 * STAGING URL
 */
// const ROOT_URL = env === 'development' || env === 'staging' ? 'http://ariba.dev.local/api' : '/api';
export const ROOT_URL = env === 'development' ? 'http://localhost:62220' : '/api'
/*
 *https://avtuatapp1.phl.sap.corp/api
 *  PRODUCTION URL http://localhost:62220/api
 */

export const years = 5 // year value used across the app

export const sessionDuration = 7200000 // JWT session duration in ms

export const warnTimeout = 114 * 60 * 1000;
export const logoutTimeout = 120 * 61 * 1000;

export const routes = {
	home: '/home',
	register: '/register',
	registerThankYou: '/register-thank-you',
	activation: '/activation',
	login: '/login',
	forgotPassword: '/forgot-password',
	resetPassword: '/reset-password',
	start: '/start',
	discovery: '/discovery',
	areaOfInterest: '/area-of-interest',
	priorities: '/priorities',
	businessValue: '/business-value',
	subLeverCalculation: '/sublever-calculation',
	businessValueFromSAPAriba: '/business-value-from-sap-ariba',
	calculateROI: '/calculate-roi',
	nextSteps: '/next-steps',
	translateScreen: '/translate-screen',
	languageManager: '/language-manager',
	benchmark: '/benchmarks',
	carousel: '/carousels',
	skus: '/skus',
	userAndRoleManager: '/userAndRoleManager',
	searchContainer: '/searchContainer',
	yourFile: '/yourFile',
	subTabsContainer: '/subTabsContainer',
	defineScope: '/defineScopeContainer',
	searchBar: '/searchBar',
	detailedFile: '/detailedFileContainer',
	accountParameters: '/accountParameters',
	yourSpend: '/yourSpend',
	gicsAdmin: '/gicsAdmin',
	releaseContainer: '/releases',
	spendParameter: '/spendParameter',
	videoManager: '/videoManager',
	worldMap: '/WorldMapContainer',
	countryLookup: '/countryLookup',
	terms: '/terms',
	ganntChart: '/GanntChartContainer',
	enablementParameters: '/enablementParameters',
	anIdManager: '/anIdManager'
}

export const publicRoutes = [
	routes.home,
	routes.register,
	routes.registerThankYou,
	routes.activation,
	routes.resetPassword,
	routes.login,
	routes.forgotPassword,
	routes.terms
	// routes.start
]

export const events = [
	"load",
	//"mousemove",
	"mousedown",
	"click",
	"scroll",
	"keypress"
];

export const industryBusinessMatrix = [
	{
		reduceInventoryLevels: true,
		extendDaysPayableOutstanding: true
	},
	{
		reduceInventoryLevels: true,
		extendDaysPayableOutstanding: true
	},
	{
		reduceInventoryLevels: true,
		extendDaysPayableOutstanding: true
	},
	{
		reduceInventoryLevels: false,
		extendDaysPayableOutstanding: false
	},
	{
		reduceInventoryLevels: true,
		extendDaysPayableOutstanding: true
	},
	{
		reduceInventoryLevels: true,
		extendDaysPayableOutstanding: true
	},
	{
		reduceInventoryLevels: true,
		extendDaysPayableOutstanding: true
	},
	{
		reduceInventoryLevels: false,
		extendDaysPayableOutstanding: true
	},
	{
		reduceInventoryLevels: false,
		extendDaysPayableOutstanding: true
	},
	{
		reduceInventoryLevels: true,
		extendDaysPayableOutstanding: true
	},
	{
		reduceInventoryLevels: false,
		extendDaysPayableOutstanding: true
	},
	{
		reduceInventoryLevels: true,
		extendDaysPayableOutstanding: true
	},
	{
		reduceInventoryLevels: false,
		extendDaysPayableOutstanding: true
	},
	{
		reduceInventoryLevels: true,
		extendDaysPayableOutstanding: true
	},
	{
		reduceInventoryLevels: false,
		extendDaysPayableOutstanding: true
	},
	{
		reduceInventoryLevels: true,
		extendDaysPayableOutstanding: true
	},
	{
		reduceInventoryLevels: true,
		extendDaysPayableOutstanding: true
	},
	{
		reduceInventoryLevels: true,
		extendDaysPayableOutstanding: true
	},
	{
		reduceInventoryLevels: false,
		extendDaysPayableOutstanding: true
	},
	{
		reduceInventoryLevels: false,
		extendDaysPayableOutstanding: true
	},
	{
		reduceInventoryLevels: true,
		extendDaysPayableOutstanding: true
	},
	{
		reduceInventoryLevels: true,
		extendDaysPayableOutstanding: true
	},
	{
		reduceInventoryLevels: true,
		extendDaysPayableOutstanding: true
	},
	{
		reduceInventoryLevels: true,
		extendDaysPayableOutstanding: true
	},
	{
		reduceInventoryLevels: true,
		extendDaysPayableOutstanding: true
	},
	{
		reduceInventoryLevels: true,
		extendDaysPayableOutstanding: true

	}
]

// LAC countries index in the locations array
export const LAC = [
	8,
	9,
	10,
	12,
	16,
	19,
	22,
	25,
	28,
	30,
	39,
	42,
	46,
	51,
	54,
	59,
	60,
	61,
	63,
	68,
	73,
	84,
	85,
	87,
	90,
	91,
	93,
	104,
	131,
	135,
	141,
	149,
	152,
	164,
	166,
	167,
	172,
	194,
	195,
	197,
	198,
	200,
	214,
	218,
	225,
	229,
	231
]

export const currencies = [
	'$',
	'؋',
	'€',
	'Lek',
	'د۔ج',
	'$',
	'€',
	'Kz',
	'$',
	'$',
	'$',
	'AMD',
	'ƒ',
	'$',
	'€',
	'ман',
	'$',
	'۔د۔ب',
	'৳',
	'$',
	'Br',
	'€',
	'$',
	'CFA',
	'Nu۔',
	'$b',
	'KM',
	'P',
	'R$',
	'$',
	'$',
	'$',
	'лв',
	'CFA',
	'CFA',
	'៛',
	'CFA',
	'$',
	'$',
	'$',
	'CFA',
	'CFA',
	'$',
	'元',
	'$',
	'$',
	'$',
	'CF',
	'$',
	'CFA',
	'$',
	'₡',
	'CFA',
	'kn',
	'₱',
	'€',
	'Kč',
	'kr',
	'$',
	'$',
	'RD$',
	'$',
	'£ ',
	'$',
	'CFA',
	'Nfk',
	'€',
	'Br',
	'£',
	'kr',
	'$',
	'€',
	'€',
	'€',
	'Fr',
	'CFA',
	'D',
	'$',
	'ლ',
	'€',
	'₵',
	'£',
	'€',
	'kr',
	'$',
	'€',
	'$',
	'Q',
	'GFr',
	'$',
	'$',
	'G',
	'$',
	'L',
	'$',
	'Ft',
	'kr',
	'₹ ',
	'Rp',
	'﷼',
	'ع۔د',
	'€',
	'₪',
	'€',
	'J$',
	'¥',
	'ينار',
	'₸',
	'KSh',
	'$',
	'₩',
	'د۔ك',
	'лв',
	'₭',
	'Ls',
	'ل۔ل',
	'L',
	'$',
	'ل۔د',
	'Fr',
	'Lt',
	'€',
	'MOP$',
	'ден',
	'Ar',
	'MK',
	'RM ',
	'ރ',
	'CFA',
	'€',
	'$',
	'€',
	'UM',
	'₨',
	'€',
	'$',
	'$',
	'MDL',
	'€',
	'₮ ',
	'€',
	'$',
	'د۔م۔',
	'MT',
	'K',
	'$',
	'$',
	'ु',
	'€',
	'ƒ ',
	'Fr',
	'$',
	'C$',
	'CFA',
	'₦',
	'$',
	'$',
	'₩',
	'$',
	'kr',
	'ر۔ع',
	'₨',
	'$',
	'$',
	'B/.',
	'K',
	'Gs',
	'S/۔',
	'₱',
	'$',
	'zł ',
	'€',
	'$',
	'﷼۔',
	'€',
	'lei',
	'₽',
	'R₣',
	'T',
	'€',
	'Db',
	'ر۔س',
	'CFA',
	'РСД',
	'₨',
	'Le',
	'$',
	'€',
	'€',
	'$',
	'S',
	'R',
	'€',
	'Rs',
	'$',
	'$',
	'€',
	'$',
	'$',
	'£ ',
	'$',
	'kr',
	'L',
	'kr',
	'Fr',
	'£ ',
	'NT$',
	'SM',
	'Sh',
	'฿',
	'$',
	'CFA',
	'$',
	'T$',
	'TT$',
	'د۔ت',
	'₺ ',
	'm',
	'$',
	'$',
	'Sh',
	'₴',
	'د۔إ',
	'£',
	'$',
	'$U',
	'лв',
	'Vt',
	'€',
	'Bs',
	'₫',
	'$',
	'$',
	'Fr',
	'د۔م۔',
	'﷼',
	'ZK',
	'Z$'
]

export const validators = {
	required: (val) => val && val.trim().length > 0,
	validEmail: (val) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(val),
	validPassword: (val) => /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*()_+,.{}-]).{8,}$/.test(val),
	validSelect: (val) => {
		return val !== 0
	},
	requiredwithoutSpace: (val) => val && val.trim().length > 2,
	requiredDot: (val) => val && val.trim().indexOf('.') > 0,
	notZero: (val) => {
		return !Number.isNaN(val) && Number.parseFloat(val) != 0
	},
	validIndustry: (val) => {
		return val !== "Select Industry"
	},
	validIndirectScope: (val) => {
		return val !== "Select Scope"
	},
}

export const colors = {
	rgb: {
		accentColor: '0, 143, 211',
		orangeColor: '240, 171, 0'
	}
}

export const modalStyle = {
	width: '80%',
	maxWidth: '1325px',
	height: '85%',
	overflow: 'hidden'
}

export const modalContentStyle = {
	padding: '35px',
	height: '100%',
	overflowY: 'auto',
	background: 'white'
}

export const reportBugContentStyle = {
	position: 'fixed',
	width: '80%',
	maxWidth: '1024px',
	height: '55%',
	overflowY: 'auto',
	background: 'white',
	top: '16%',
	left: '18%',
	right: '10%',
	margin: '0px',
	outline: 'none',
	padding: '55px 20px 10px 20px'
}

export const emailmodalContentStyle = {
	padding: '20px 10px',
	height: '100%',
	overflowY: 'auto',
	background: 'white'
}

export const changeModalStyle = {
	width: '30%',
	maxWidth: '1024px',
	height: 'auto',
	overflow: 'hidden'
}

export const spendModalStyle = {
	width: '30%',
	maxWidth: '80%',
	height: 'auto',
	overflow: 'hidden'
}

export const kpiConstantModalStyle = {
	width: '90%',	
	height: 'auto',
	overflow: 'hidden'
}

export const exportOptionsModalStyle = {
	maxWidth: '1024px',
	height: 'auto',
	overflow: 'hidden'
}

export const changeModalContentStyle = {
	padding: '10px 5px 15px 15px',
	height: '100%',
	overflowY: 'auto',
	background: 'white'
}

export const suggestedValueModalStyle = {
	width: 'auto',
	maxWidth: '1024px',
	overflowY: 'auto',
	height: 'auto',
	background: 'white'
}

export const columnFieldModalStyle = {
	width: '58%',
	maxWidth: '1024px',
	overflowY: 'auto',
	height: 'auto',
	background: 'white'
}

export const suggestedValueContentStyle = {
	width: 'auto',
	height: 'auto',
	background: 'white',
	textAlign: 'left'

}
export const backdropStyle = {
	// backgroundColor: 'translucent',

	position: 'fixed',
	minHeight: '100%',
	minWidth: '100%',
	left: 0,
	top: 0,
	width: 'auto',
	height: 'auto',
	background: 'rgba(0, 0, 0, 0.3)'

}

export const changeModalBorderStyle = {
	width: '100vw',
	height: '100vh',
	overflow: 'hidden',
	border: '1px solid',
	backgroundColor: 'rgba(189,189,189, 0.5)'
}

export const changeModalBorderContentStyle = {
	position: 'fixed',
	left: '34%',
	width: '30%',
	top: '45%',
	padding: '10px 5px 15px 15px',
	overflowY: 'auto',
	background: 'white'
}

export const changeModalBorderRectStyle = {
	animationDuration: '0s',
	animationFillMode: 'none',
	strokeWidth: '0px',
	stroke: 'none',
	strokeDashArray: 'unset'
}

export const drilldownModalBorderContentStyle = {
	position: 'fixed',
	top: '5%',
	left: '7.5%',
	right: '7.5%',
	height: '90vh',
	width: '85vw',
	outline: 'none',
	padding: '10px 5px 15px 15px',
	overflowY: 'auto',
	background: 'white'
}

export const drilldownModalBorderContentStyleNewVsSecondPop = {
	position: 'fixed',
	//top: '5%',
	left: '7.5%',
	right: '7.5%',
	height: '100vh',
	width: '85vw',
	outline: 'none',
	//padding: '10px 5px 15px 15px',
	overflowY: 'auto',
	background: 'white'
}

export const drilldownModalBorderContentStyleNewVS = {
	position: 'fixed',
	top: '5%',
	left: '7.5%',
	right: '7.5%',
	height: '70vh',
	width: '85vw',
	outline: 'none',
	padding: '10px 5px 15px 15px',
	overflowY: 'auto',
	background: 'white'
}

export const mappingSKUsPriorityAndSubLever =
	[
		{
			id: '1',
			name: 'SAP Ariba Spend Analysis',
			mappedPriorities: [
				{
					prioritiesId: 1,
					priorityIndex: 3
				},
				{
					prioritiesId: 1,
					priorityIndex: 6
				}
			],
			mappedSubLever: [
				{
					subLeverId: 1.1
				},
				{
					subLeverId: 1.2
				},
				{
					subLeverId: 1.3
				}
			]
		},
		{
			id: '2',
			name: 'SAP Ariba Sourcing',
			mappedPriorities: [
				{
					prioritiesId: 1,
					priorityIndex: 0
				},
				{
					prioritiesId: 1,
					priorityIndex: 1
				},
				{
					prioritiesId: 1,
					priorityIndex: 3
				}
			],
			mappedSubLever: [
				{
					subLeverId: 1.1
				},
				{
					subLeverId: 1.2
				},
				{
					subLeverId: 1.3
				}
			]
		},
		{
			id: '3',
			name: 'SAP Ariba Strategic Sourcing',
			mappedPriorities: [
				{
					prioritiesId: 0,
					priorityIndex: 0
				},
				{
					prioritiesId: 0,
					priorityIndex: 7
				},
				{
					prioritiesId: 1,
					priorityIndex: 0
				},
				{
					prioritiesId: 1,
					priorityIndex: 1
				},
				{
					prioritiesId: 1,
					priorityIndex: 2
				},
				{
					prioritiesId: 1,
					priorityIndex: 3
				},
				{
					prioritiesId: 1,
					priorityIndex: 4
				},
				{
					prioritiesId: 1,
					priorityIndex: 5
				},
				{
					prioritiesId: 1,
					priorityIndex: 10
				},
				{
					prioritiesId: 5,
					priorityIndex: 0
				},
				{
					prioritiesId: 5,
					priorityIndex: 2
				},
				{
					prioritiesId: 5,
					priorityIndex: 11
				}
			],
			mappedSubLever: [
				{
					subLeverId: 1.1
				},
				{
					subLeverId: 1.2
				},
				{
					subLeverId: 1.3
				},
				{
					subLeverId: 5.2
				}
			]
		},
		{
			id: '4',
			name: 'SAP Ariba Buying',
			mappedPriorities: [
				{
					prioritiesId: 1,
					priorityIndex: 8
				},
				{
					prioritiesId: 2,
					priorityIndex: 0
				},
				{
					prioritiesId: 2,
					priorityIndex: 1
				},
				{
					prioritiesId: 2,
					priorityIndex: 2
				},
				{
					prioritiesId: 2,
					priorityIndex: 5
				},
				{
					prioritiesId: 2,
					priorityIndex: 6
				}
			],
			mappedSubLever: [
				{
					subLeverId: 1.4
				},
				{
					subLeverId: 2.1
				},
				{
					subLeverId: 3.1
				}
			]
		},
		{
			id: '5',
			name: 'SAP Ariba Buying and Invoicing',
			mappedPriorities: [
				{
					prioritiesId: 1,
					priorityIndex: 8
				},
				{
					prioritiesId: 2,
					priorityIndex: 0
				},
				{
					prioritiesId: 2,
					priorityIndex: 1
				},
				{
					prioritiesId: 2,
					priorityIndex: 2
				},
				{
					prioritiesId: 2,
					priorityIndex: 5
				},
				{
					prioritiesId: 2,
					priorityIndex: 6
				},
				{
					prioritiesId: 3,
					priorityIndex: 0
				},
				{
					prioritiesId: 3,
					priorityIndex: 1
				},
				{
					prioritiesId: 3,
					priorityIndex: 2
				},
				{
					prioritiesId: 3,
					priorityIndex: 6
				},
				{
					prioritiesId: 3,
					priorityIndex: 7
				},
				{
					prioritiesId: 3,
					priorityIndex: 11
				},
				{
					prioritiesId: 4,
					priorityIndex: 4
				}
			],
			mappedSubLever: [
				{
					subLeverId: 1.4
				},
				{
					subLeverId: 2.1
				},
				{
					subLeverId: 2.2
				},
				{
					subLeverId: 3.1
				},
				{
					subLeverId: 3.2
				},
				{
					subLeverId: 4.1
				}
			]
		},
		{
			id: '6',
			name: 'SAP Ariba Catalog/Spot Buy Catalogs',
			mappedPriorities: [
				{
					prioritiesId: 1,
					priorityIndex: 8
				},
				{
					prioritiesId: 2,
					priorityIndex: 1
				},
				{
					prioritiesId: 2,
					priorityIndex: 2
				},
				{
					prioritiesId: 2,
					priorityIndex: 5
				},
				{
					prioritiesId: 2,
					priorityIndex: 6
				}
			],
			mappedSubLever: [
				{
					subLeverId: 2.1
				},
				{
					subLeverId: 3.1
				}
			]
		},
		{
			id: '7',
			name: 'SAP Ariba Invoice Management',
			mappedPriorities: [
				{
					prioritiesId: 3,
					priorityIndex: 0
				},
				{
					prioritiesId: 3,
					priorityIndex: 1
				},
				{
					prioritiesId: 3,
					priorityIndex: 2
				},
				{
					prioritiesId: 3,
					priorityIndex: 6
				},
				{
					prioritiesId: 3,
					priorityIndex: 7
				},
				{
					prioritiesId: 3,
					priorityIndex: 11
				},
				{
					prioritiesId: 4,
					priorityIndex: 4
				}
			],
			mappedSubLever: [
				{
					subLeverId: 2.2
				},
				{
					subLeverId: 3.2
				},
				{
					subLeverId: 4.1
				}
			]
		},
		{
			id: '8',
			name: 'SAP Ariba Contract Invoicing',
			mappedPriorities: [
				{
					prioritiesId: 3,
					priorityIndex: 0
				},
				{
					prioritiesId: 3,
					priorityIndex: 1
				},
				{
					prioritiesId: 3,
					priorityIndex: 2
				},
				{
					prioritiesId: 3,
					priorityIndex: 6
				},
				{
					prioritiesId: 3,
					priorityIndex: 7
				},
				{
					prioritiesId: 3,
					priorityIndex: 8
				},
				{
					prioritiesId: 3,
					priorityIndex: 11
				},
				{
					prioritiesId: 4,
					priorityIndex: 4
				}
			],
			mappedSubLever: [
				{
					subLeverId: 2.2
				},
				{
					subLeverId: 3.2
				},
				{
					subLeverId: 4.1
				}
			]
		},
		{
			id: '9',
			name: 'SAP Ariba Commerce Automation',
			mappedPriorities: [
				{
					prioritiesId: 2,
					priorityIndex: 5
				},
				{
					prioritiesId: 3,
					priorityIndex: 0
				},
				{
					prioritiesId: 3,
					priorityIndex: 1
				},
				{
					prioritiesId: 3,
					priorityIndex: 2
				},
				{
					prioritiesId: 3,
					priorityIndex: 6
				},
				{
					prioritiesId: 3,
					priorityIndex: 7
				},
				{
					prioritiesId: 3,
					priorityIndex: 8
				},
				{
					prioritiesId: 3,
					priorityIndex: 11
				},
				{
					prioritiesId: 4,
					priorityIndex: 4
				}
			],
			mappedSubLever: [
				{
					subLeverId: 2.2
				},
				{
					subLeverId: 3.1
				},
				{
					subLeverId: 3.2
				},
				{
					subLeverId: 4.1
				}
			]
		},
		{
			id: '10',
			name: 'SAP Ariba Discount Management',
			mappedPriorities: [
				{
					prioritiesId: 4,
					priorityIndex: 0
				},
				{
					prioritiesId: 4,
					priorityIndex: 1
				},
				{
					prioritiesId: 4,
					priorityIndex: 2
				},
				{
					prioritiesId: 4,
					priorityIndex: 4
				}
			],
			mappedSubLever: [
				{
					subLeverId: 4.1
				},
				{
					subLeverId: 4.2
				},
				{
					subLeverId: 4.3
				}
			]
		},
		{
			id: '11',
			name: 'SAP Ariba Supplier Risk',
			mappedPriorities: [
				{
					prioritiesId: 0,
					priorityIndex: 7
				},
				{
					prioritiesId: 5,
					priorityIndex: 1
				},
				{
					prioritiesId: 5,
					priorityIndex: 2
				},
				{
					prioritiesId: 5,
					priorityIndex: 3
				},
				{
					prioritiesId: 5,
					priorityIndex: 4
				},
				{
					prioritiesId: 5,
					priorityIndex: 9
				},
				{
					prioritiesId: 5,
					priorityIndex: 10
				}
			],
			mappedSubLever: [
				{
					subLeverId: 3.3
				},
				{
					subLeverId: 5.1
				}
			]
		},
		{
			id: '12',
			name: 'SAP Ariba Supply Chain Collaboration',
			mappedPriorities: [

				{
					prioritiesId: 0,
					priorityIndex: 1
				},
				{
					prioritiesId: 0,
					priorityIndex: 2
				},
				{
					prioritiesId: 0,
					priorityIndex: 3
				},
				{
					prioritiesId: 0,
					priorityIndex: 4
				},
				{
					prioritiesId: 0,
					priorityIndex: 5
				},
				{
					prioritiesId: 0,
					priorityIndex: 6
				},
				{
					prioritiesId: 0,
					priorityIndex: 7
				},
				{
					prioritiesId: 0,
					priorityIndex: 8
				},
				{
					prioritiesId: 1,
					priorityIndex: 8
				},
				{
					prioritiesId: 4,
					priorityIndex: 3
				}

			],
			mappedSubLever: [
				{
					subLeverId: 3.4
				},
				{
					subLeverId: 3.5
				},
				{
					subLeverId: 4.1
				},
				{
					subLeverId: 4.4
				}
			]
		},
		{
			id: '13',
			name: 'SAP Ariba Supplier Information & Performance Management / Supplier Lifecycle & Performance',
			mappedPriorities: [
				{
					prioritiesId: 1,
					priorityIndex: 3
				},
				{
					prioritiesId: 1,
					priorityIndex: 10
				},
				{
					prioritiesId: 5,
					priorityIndex: 0
				},
				{
					prioritiesId: 5,
					priorityIndex: 11
				}
			],
			mappedSubLever: [
				{
					subLeverId: 1.2
				},
				{
					subLeverId: 5.2
				}
			]
		},
		{
			id: '14',
			name: 'SAP Ariba Contracts',
			mappedPriorities: [
				{
					prioritiesId: 1,
					priorityIndex: 3
				},
				{
					prioritiesId: 1,
					priorityIndex: 4
				},
				{
					prioritiesId: 1,
					priorityIndex: 5
				}
			],
			mappedSubLever: [

			]
		},
		{
			id: '15',
			name: 'SAP Ariba Sourcing & Procurement Bundle',
			autoOn: false,
			mappedPriorities: [
				{
					prioritiesId: 0,
					priorityIndex: 0
				},
				{
					prioritiesId: 0,
					priorityIndex: 7
				},
				{
					prioritiesId: 1,
					priorityIndex: 0
				},
				{
					prioritiesId: 1,
					priorityIndex: 1
				},
				{
					prioritiesId: 1,
					priorityIndex: 2
				},
				{
					prioritiesId: 1,
					priorityIndex: 3
				},
				{
					prioritiesId: 1,
					priorityIndex: 4
				},
				{
					prioritiesId: 1,
					priorityIndex: 5
				},
				{
					prioritiesId: 1,
					priorityIndex: 6
				},
				{
					prioritiesId: 1,
					priorityIndex: 8
				},
				{
					prioritiesId: 1,
					priorityIndex: 10
				},
				{
					prioritiesId: 2,
					priorityIndex: 0
				},
				{
					prioritiesId: 2,
					priorityIndex: 1
				},
				{
					prioritiesId: 2,
					priorityIndex: 2
				},
				{
					prioritiesId: 2,
					priorityIndex: 5
				},
				{
					prioritiesId: 2,
					priorityIndex: 6
				},
				{
					prioritiesId: 3,
					priorityIndex: 0
				},
				{
					prioritiesId: 3,
					priorityIndex: 1
				},
				{
					prioritiesId: 3,
					priorityIndex: 2
				},
				{
					prioritiesId: 3,
					priorityIndex: 6
				},
				{
					prioritiesId: 3,
					priorityIndex: 7
				},
				{
					prioritiesId: 3,
					priorityIndex: 8
				},
				{
					prioritiesId: 3,
					priorityIndex: 11
				},
				{
					prioritiesId: 4,
					priorityIndex: 4
				},
				{
					prioritiesId: 5,
					priorityIndex: 0
				},
				{
					prioritiesId: 5,
					priorityIndex: 2
				}
			],
			mappedSubLever: [
				{
					subLeverId: 1.1
				},
				{
					subLeverId: 1.2
				},
				{
					subLeverId: 1.3
				},
				{
					subLeverId: 1.4
				},
				{
					subLeverId: 2.1
				},
				{
					subLeverId: 2.2
				},
				{
					subLeverId: 3.1
				},
				{
					subLeverId: 3.2
				},
				{
					subLeverId: 4.1
				},
				{
					subLeverId: 5.2
				}
			]
		},
		{
			id: '16',
			name: 'SAP Fieldglass External workforce management',
			mappedPriorities: [
				{
					prioritiesId: 1,
					priorityIndex: 7
				},
				{
					prioritiesId: 1,
					priorityIndex: 9
				},
				{
					prioritiesId: 1,
					priorityIndex: 10
				},
				{
					prioritiesId: 2,
					priorityIndex: 0
				},
				{
					prioritiesId: 2,
					priorityIndex: 3
				},
				{
					prioritiesId: 2,
					priorityIndex: 4
				},
				{
					prioritiesId: 2,
					priorityIndex: 5
				},
				{
					prioritiesId: 2,
					priorityIndex: 6
				},
				{
					prioritiesId: 3,
					priorityIndex: 3
				},
				{
					prioritiesId: 3,
					priorityIndex: 4
				},
				{
					prioritiesId: 3,
					priorityIndex: 5
				},
				{
					prioritiesId: 3,
					priorityIndex: 6
				},
				{
					prioritiesId: 4,
					priorityIndex: 4
				},
				{
					prioritiesId: 5,
					priorityIndex: 5
				},
				{
					prioritiesId: 5,
					priorityIndex: 6
				},
				{
					prioritiesId: 5,
					priorityIndex: 8
				},
				{
					prioritiesId: 5,
					priorityIndex: 9
				},
				{
					prioritiesId: 5,
					priorityIndex: 11
				}
			],
			mappedSubLever: [
				{
					subLeverId: 1.4
				},
				{
					subLeverId: 1.5
				},
				{
					subLeverId: 2.3
				},
				{
					subLeverId: 3.2
				},
				{
					subLeverId: 3.3
				},
				{
					subLeverId: 3.6
				},
				{
					subLeverId: 4.1
				}
			]
		},
		{
			id: '17',
			name: 'SAP Fieldglass Services procurement',
			mappedPriorities: [
				{
					prioritiesId: 1,
					priorityIndex: 7
				},
				{
					prioritiesId: 1,
					priorityIndex: 9
				},
				{
					prioritiesId: 1,
					priorityIndex: 10
				},
				{
					prioritiesId: 2,
					priorityIndex: 0
				},
				{
					prioritiesId: 2,
					priorityIndex: 3
				},
				{
					prioritiesId: 2,
					priorityIndex: 4
				},
				{
					prioritiesId: 2,
					priorityIndex: 5
				},
				{
					prioritiesId: 2,
					priorityIndex: 6
				},
				{
					prioritiesId: 3,
					priorityIndex: 0
				},
				{
					prioritiesId: 3,
					priorityIndex: 1
				},
				{
					prioritiesId: 3,
					priorityIndex: 2
				},
				{
					prioritiesId: 3,
					priorityIndex: 3
				},
				{
					prioritiesId: 3,
					priorityIndex: 4
				},
				{
					prioritiesId: 3,
					priorityIndex: 5
				},
				{
					prioritiesId: 3,
					priorityIndex: 7
				},
				{
					prioritiesId: 4,
					priorityIndex: 4
				},
				{
					prioritiesId: 5,
					priorityIndex: 10
				},
				{
					prioritiesId: 5,
					priorityIndex: 11
				},
				{
					prioritiesId: 5,
					priorityIndex: 5
				},
				{
					prioritiesId: 5,
					priorityIndex: 6
				},
				{
					prioritiesId: 5,
					priorityIndex: 7
				},
				{
					prioritiesId: 5,
					priorityIndex: 8
				}
			],
			mappedSubLever: [
				{
					subLeverId: 1.4
				},
				{
					subLeverId: 1.5
				},
				{
					subLeverId: 1.6
				},
				{
					subLeverId: 2.2
				},
				{
					subLeverId: 2.3
				},
				{
					subLeverId: 3.1
				},
				{
					subLeverId: 3.2
				},
				{
					subLeverId: 3.3
				},
				{
					subLeverId: 3.6
				},
				{
					subLeverId: 4.1
				}
			]
		}
	]

//add spot buy after procedure change.
export const GICSColumns = [
	{
		"field": "GICS_ID",
		"screen_field_name": "GICS Industry ID",
		"screen_seq": "1",
		"screen_fl": "Y",
		"export_fl": "Y",
		"mandatory": "Yes",
		"is_editable": "No",
		"alignment": "CenterAlignFormatter",
		"data_type": "text",
		"filter_type": "default",
		"screen_help_text": "GICS code, primary key for this table, no duplicates allowed"
	},
	{
		"field": "INDUSTRY_LEVEL",
		"screen_field_name": "Industry Level",
		"screen_seq": "2",
		"screen_fl": "Y",
		"export_fl": "Y",
		"mandatory": "Yes",
		"is_editable": "No",
		"alignment": "CenterAlignFormatter",
		"data_type": "text",
		"filter_type": "default",
		"screen_help_text": "9 levels to GICS structure"
	},
	{
		"field": "INDUSTRY_COMPANY_ID",
		"screen_field_name": "Industry Company ID",
		"screen_seq": "3",
		"screen_fl": "Y",
		"export_fl": "Y",
		"mandatory": "No",
		"is_editable": "No",
		"alignment": "CenterAlignFormatter",
		"data_type": "text",
		"filter_type": "default",
		"screen_help_text": "Used for industry financial ratios"
	},
	{
		"field": "SUB_INDUSTRY_ID",
		"screen_field_name": "Sub Industry ID",
		"screen_seq": "1",
		"screen_fl": "Y",
		"export_fl": "Y",
		"mandatory": "No",
		"is_editable": "Yes",
		"alignment": "CenterAlignFormatter",
		"data_type": "text",
		"filter_type": "default",
		"screen_help_text": "Sub Industry ID"
	},
	{
		"field": "GOODS_OR_SERVICE",
		"screen_field_name": "Goods or Service",
		"screen_seq": "4",
		"screen_fl": "Y",
		"export_fl": "Y",
		"mandatory": "Yes",
		"is_editable": "Yes",
		"alignment": "CenterAlignFormatter",
		"data_type": "text",
		"filter_type": "text",
		"screen_help_text": "Maintained by administrators (not from CapIQ)"
	},
	{
		"field": "INDUSTRY_DESC",
		"screen_field_name": "Industry Description",
		"screen_seq": "5",
		"screen_fl": "Y",
		"export_fl": "Y",
		"mandatory": "Yes",
		"is_editable": "No",
		"alignment": "CenterAlignFormatter",
		"data_type": "text",
		"filter_type": "text",
		"screen_help_text": "From CapIQ"
	},
	{
		"field": "L1_INDUSTRY_DESC_DETAIL",
		"screen_field_name": "L1 Industry Description Detail",
		"screen_seq": "5",
		"screen_fl": "Y",
		"export_fl": "Y",
		"mandatory": "No",
		"is_editable": "Yes",
		"alignment": "CenterAlignFormatter",
		"data_type": "text",
		"filter_type": "default",
		"screen_help_text": "L1 Industry Description Detail"
	},
	{
		"field": "L4_INDUSTRY_DESC_DETAIL",
		"screen_field_name": "L4 Industry Detailed Description",
		"screen_seq": "5",
		"screen_fl": "Y",
		"export_fl": "Y",
		"mandatory": "No",
		"is_editable": "Yes",
		"alignment": "CenterAlignFormatter",
		"data_type": "text",
		"filter_type": "default",
		"screen_help_text": "L4 Industry Detailed Description"
	},
	{
		"field": "L1_INDUSTRY_NAME",
		"screen_field_name": "L1 Industry Description",
		"screen_seq": "6",
		"screen_fl": "Y",
		"export_fl": "Y",
		"mandatory": "Yes",
		"is_editable": "No",
		"alignment": "CenterAlignFormatter",
		"data_type": "text",
		"filter_type": "text",
		"screen_help_text": "From CapIQ"
	},
	{
		"field": "L2_INDUSTRY_NAME",
		"screen_field_name": "L2 Industry Description",
		"screen_seq": "7",
		"screen_fl": "Y",
		"export_fl": "Y",
		"mandatory": "Yes",
		"is_editable": "No",
		"alignment": "CenterAlignFormatter",
		"data_type": "text",
		"filter_type": "text",
		"screen_help_text": "From CapIQ"
	},
	{
		"field": "L3_INDUSTRY_NAME",
		"screen_field_name": "L3 Industry Description",
		"screen_seq": "8",
		"screen_fl": "Y",
		"export_fl": "Y",
		"mandatory": "Yes",
		"is_editable": "No",
		"alignment": "CenterAlignFormatter",
		"data_type": "text",
		"filter_type": "text",
		"screen_help_text": "From CapIQ"
	},
	{
		"field": "L4_INDUSTRY_NAME",
		"screen_field_name": "L4 Industry Description",
		"screen_seq": "9",
		"screen_fl": "Y",
		"export_fl": "Y",
		"mandatory": "Yes",
		"is_editable": "No",
		"alignment": "CenterAlignFormatter",
		"data_type": "text",
		"filter_type": "text",
		"screen_help_text": "From CapIQ"
	},
	{
		"field": "L5_INDUSTRY_NAME",
		"screen_field_name": "L5 Industry Description",
		"screen_seq": "10",
		"screen_fl": "Y",
		"export_fl": "Y",
		"mandatory": "Yes",
		"is_editable": "No",
		"alignment": "CenterAlignFormatter",
		"data_type": "text",
		"filter_type": "text",
		"screen_help_text": "From CapIQ"
	},
	{
		"field": "L6_INDUSTRY_NAME",
		"screen_field_name": "L6 Industry Description",
		"screen_seq": "11",
		"screen_fl": "Y",
		"export_fl": "Y",
		"mandatory": "Yes",
		"is_editable": "No",
		"alignment": "CenterAlignFormatter",
		"data_type": "text",
		"filter_type": "text",
		"screen_help_text": "From CapIQ"
	},
	{
		"field": "L7_INDUSTRY_NAME",
		"screen_field_name": "L7 Industry Description",
		"screen_seq": "12",
		"screen_fl": "Y",
		"export_fl": "Y",
		"mandatory": "Yes",
		"is_editable": "No",
		"alignment": "CenterAlignFormatter",
		"data_type": "text",
		"filter_type": "text",
		"screen_help_text": "From CapIQ"
	},
	{
		"field": "L8_INDUSTRY_NAME",
		"screen_field_name": "L8 Industry Description",
		"screen_seq": "13",
		"screen_fl": "Y",
		"export_fl": "Y",
		"mandatory": "Yes",
		"is_editable": "No",
		"alignment": "CenterAlignFormatter",
		"data_type": "text",
		"filter_type": "text",
		"screen_help_text": "From CapIQ"
	},
	{
		"field": "L9_INDUSTRY_NAME",
		"screen_field_name": "L9 Industry Description",
		"screen_seq": "14",
		"screen_fl": "Y",
		"export_fl": "Y",
		"mandatory": "Yes",
		"is_editable": "No",
		"alignment": "CenterAlignFormatter",
		"data_type": "text",
		"filter_type": "text",
		"screen_help_text": "From CapIQ"
	},
	{
		"field": "SOURCING_ELIGIBLE",
		"screen_field_name": "Sourcing Eligible",
		"screen_seq": "15",
		"screen_fl": "Y",
		"export_fl": "Y",
		"mandatory": "Yes",
		"is_editable": "Yes",
		"alignment": "CenterAlignFormatter",
		"data_type": "text",
		"filter_type": "text",
		"screen_help_text": "Solution scope field"
	},
	{
		"field": "BUYING_INVOICE_ELIGIBLE",
		"screen_field_name": "Buying and Invoicing Eligible",
		"screen_seq": "16",
		"screen_fl": "Y",
		"export_fl": "Y",
		"mandatory": "Yes",
		"is_editable": "Yes",
		"alignment": "CenterAlignFormatter",
		"data_type": "text",
		"filter_type": "text",
		"screen_help_text": "Solution scope field"
	},
	{
		"field": "REQUISTION_ABLE",
		"screen_field_name": "Requisition-able",
		"screen_seq": "17",
		"screen_fl": "Y",
		"export_fl": "Y",
		"mandatory": "Yes",
		"is_editable": "Yes",
		"alignment": "CenterAlignFormatter",
		"data_type": "text",
		"filter_type": "text",
		"screen_help_text": "Solution scope field"
	},
	{
		"field": "REQUISITION_TYPE",
		"screen_field_name": "Requisition Type",
		"screen_seq": "18",
		"screen_fl": "Y",
		"export_fl": "Y",
		"mandatory": "Yes",
		"is_editable": "Yes",
		"alignment": "CenterAlignFormatter",
		"data_type": "text",
		"filter_type": "text",
		"screen_help_text": "Solution scope field"
	},
	{
		"field": "CONTRACT_INVOICE_ELIGIBLE",
		"screen_field_name": "Contract Invoicing Eligible",
		"screen_seq": "19",
		"screen_fl": "Y",
		"export_fl": "Y",
		"mandatory": "Yes",
		"is_editable": "Yes",
		"alignment": "CenterAlignFormatter",
		"data_type": "text",
		"filter_type": "text",
		"screen_help_text": "Solution scope field"
	},
	{
		"field": "COMMERCE_AUTOMATION_ELIGIBLE",
		"screen_field_name": "Commerce Automation Eligible",
		"screen_seq": "20",
		"screen_fl": "Y",
		"export_fl": "Y",
		"mandatory": "Yes",
		"is_editable": "Yes",
		"alignment": "CenterAlignFormatter",
		"data_type": "text",
		"filter_type": "text",
		"screen_help_text": "Solution scope field"
	},
	{
		"field": "SUPP_CHAIN_COLLAB_ELIGIBLE",
		"screen_field_name": "Supply Chain Collaboration Eligible",
		"screen_seq": "21",
		"screen_fl": "Y",
		"export_fl": "Y",
		"mandatory": "Yes",
		"is_editable": "Yes",
		"alignment": "CenterAlignFormatter",
		"data_type": "text",
		"filter_type": "text",
		"screen_help_text": "Solution scope field"
	},
	{
		"field": "DISCOUNT_SCF_ELIGIBLE",
		"screen_field_name": "Discount/SCF Eligible",
		"screen_seq": "22",
		"screen_fl": "Y",
		"export_fl": "Y",
		"mandatory": "Yes",
		"is_editable": "Yes",
		"alignment": "CenterAlignFormatter",
		"data_type": "text",
		"filter_type": "text",
		"screen_help_text": "Solution scope field"
	},
	// {
	// 	"field": "TERMS_RATIONALIZATION",
	// 	"screen_field_name": "Terms Rationalization",
	// 	"screen_seq": "23",
	// 	"screen_fl": "Y",
	// 	"export_fl": "Y",
	// 	"mandatory": "Yes",
	// 	"is_editable": "Yes",
	// 	"alignment": "CenterAlignFormatter",
	// 	"data_type": "text",
	// 	"filter_type": "text",
	// 	"screen_help_text": "Solution scope field"
	// },
	// {
	// 	"field": "DISCOUNT_ELIGIBLE",
	// 	"screen_field_name": "Discount Eligible",
	// 	"screen_seq": "24",
	// 	"screen_fl": "Y",
	// 	"export_fl": "Y",
	// 	"mandatory": "Yes",
	// 	"is_editable": "Yes",
	// 	"alignment": "CenterAlignFormatter",
	// 	"data_type": "text",
	// 	"filter_type": "text",
	// 	"screen_help_text": "Solution scope field"
	// },
	{
		"field": "SAP_FIELDGLASS_ELIGIBLE",
		"screen_field_name": "SAP Fieldglass Eligible",
		"screen_seq": "23",
		"screen_fl": "Y",
		"export_fl": "Y",
		"mandatory": "Yes",
		"is_editable": "Yes",
		"alignment": "CenterAlignFormatter",
		"data_type": "text",
		"filter_type": "text",
		"screen_help_text": "Solution scope field"
	},
	{
		"field": "SAP_CONCUR_ELIGIBLE",
		"screen_field_name": "SAP Concur Eligible",
		"screen_seq": "24",
		"screen_fl": "Y",
		"export_fl": "Y",
		"mandatory": "Yes",
		"is_editable": "Yes",
		"alignment": "CenterAlignFormatter",
		"data_type": "text",
		"filter_type": "text",
		"screen_help_text": "Solution scope field"
	},
	{
		"field": "SPOT_BUY_ELIGIBLE",
		"screen_field_name": "Spot Buy Eligible",
		"screen_seq": "25",
		"screen_fl": "Y",
		"export_fl": "Y",
		"mandatory": "Yes",
		"is_editable": "Yes",
		"alignment": "CenterAlignFormatter",
		"data_type": "text",
		"filter_type": "text",
		"screen_help_text": "Solution scope field"
	},
	{
		"field": "BUY_PROCESS",
		"screen_field_name": "SAP Ariba Buy Process - Sales",
		"screen_seq": "26",
		"screen_fl": "Y",
		"export_fl": "Y",
		"mandatory": "Yes",
		"is_editable": "Yes",
		"alignment": "LeftAlignFormatter",
		"data_type": "text",
		"filter_type": "text",
		"screen_help_text": "These valid values should be a dynamic list from the current template table for buy processes"
	},
	{
		"field": "PRIMARY_BUY_PROCESS_DELIVERED",
		"screen_field_name": "Primary SAP Ariba Buy Process - Delivery",
		"screen_seq": "27",
		"screen_fl": "Y",
		"export_fl": "Y",
		"mandatory": "Yes",
		"is_editable": "Yes",
		"alignment": "LeftAlignFormatter",
		"data_type": "text",
		"filter_type": "text",
		"screen_help_text": "These valid values should be a dynamic list from the current template table for buy processes"
	},
	{
		"field": "SECONDARY_BUY_PROCESS_DELIVERED",
		"screen_field_name": "Secondary SAP Ariba Buy Process - Delivery",
		"screen_seq": "28",
		"screen_fl": "Y",
		"export_fl": "Y",
		"mandatory": "No",
		"is_editable": "Yes",
		"alignment": "LeftAlignFormatter",
		"data_type": "text",
		"filter_type": "text",
		"screen_help_text": "These valid values should be a dynamic list from the current template table for buy processes"
	},
	{
		"field": "OTHER_BUY_PROCESS_DELIVERED",
		"screen_field_name": "Other SAP Ariba Buy Process - Delivery",
		"screen_seq": "29",
		"screen_fl": "Y",
		"export_fl": "Y",
		"mandatory": "No",
		"is_editable": "Yes",
		"alignment": "LeftAlignFormatter",
		"data_type": "text",
		"filter_type": "text",
		"screen_help_text": "These valid values should be a dynamic list from the current template table for buy processes"
	},
	{
		"field": "OPERATING_MARGIN_EBIT",
		"screen_field_name": "Operating Margin (EBIT %)",
		"screen_seq": "30",
		"screen_fl": "Y",
		"export_fl": "Y",
		"mandatory": "No",
		"is_editable": "Yes",
		"alignment": "RoundFormatter2",
		"data_type": "numeric",
		"filter_type": "numeric",
		"screen_help_text": "Operating Margin (EBIT %)"
	},
	{
		"field": "DAYS_PAYABLE_OUTSTANDING",
		"screen_field_name": "Days Payable Outstanding (DPO)",
		"screen_seq": "31",
		"screen_fl": "Y",
		"export_fl": "Y",
		"mandatory": "No",
		"is_editable": "Yes",
		"alignment": "RoundFormatter2",
		"data_type": "numeric",
		"filter_type": "numeric",
		"screen_help_text": "Days Payable Outstanding (DPO)"
	},
	{
		"field": "DAYS_SALES_OUTSTANDING",
		"screen_field_name": "Days Sales Outstanding (DSO)",
		"screen_seq": "32",
		"screen_fl": "Y",
		"export_fl": "Y",
		"mandatory": "No",
		"is_editable": "Yes",
		"alignment": "RoundFormatter2",
		"data_type": "numeric",
		"filter_type": "numeric",
		"screen_help_text": "Days Sales Outstanding (DSO)"
	},
	{
		"field": "DAYS_INVENTORY_OUTSTANDING",
		"screen_field_name": "Days Inventory Outstanding (DIO)",
		"screen_seq": "33",
		"screen_fl": "Y",
		"export_fl": "Y",
		"mandatory": "No",
		"is_editable": "Yes",
		"alignment": "RoundFormatter2",
		"data_type": "numeric",
		"filter_type": "numeric",
		"screen_help_text": "Days Inventory Outstanding (DIO)"
	},
	{
		"field": "CONVERSION_CASH_CYCLE",
		"screen_field_name": "Conversion Cash Cycle",
		"screen_seq": "34",
		"screen_fl": "Y",
		"export_fl": "Y",
		"mandatory": "No",
		"is_editable": "Yes",
		"alignment": "RoundFormatter2",
		"data_type": "numeric",
		"filter_type": "numeric",
		"screen_help_text": "Conversion Cash Cycle"
	},
	{
		"field": "INVENTORY_TURNS",
		"screen_field_name": "Inventory Turns",
		"screen_seq": "35",
		"screen_fl": "Y",
		"export_fl": "Y",
		"mandatory": "No",
		"is_editable": "Yes",
		"alignment": "RoundFormatter2",
		"data_type": "numeric",
		"filter_type": "numeric",
		"screen_help_text": "Inventory Turns"
	},
	{
		"field": "WCM_SUMMARY_INSCOPE",
		"screen_field_name": "WCM Summary InScope",
		"screen_seq": "36",
		"screen_fl": "Y",
		"export_fl": "Y",
		"mandatory": "No",
		"is_editable": "Yes",
		"alignment": "CenterAlignFormatter",
		"data_type": "text",
		"filter_type": "text",
		"screen_help_text": "Solution scope field"
	},
	{
		"field": "WCM_SUMMARY_OUTSCOPE",
		"screen_field_name": "WCM Summary OutScope",
		"screen_seq": "37",
		"screen_fl": "Y",
		"export_fl": "Y",
		"mandatory": "No",
		"is_editable": "Yes",
		"alignment": "CenterAlignFormatter",
		"data_type": "text",
		"filter_type": "text",
		"screen_help_text": "Solution scope field"
	},
	{
		"field": "SCOPE_NOTES",
		"screen_field_name": "Scope Notes",
		"screen_seq": "38",
		"screen_fl": "Y",
		"export_fl": "Y",
		"mandatory": "Yes",
		"is_editable": "Yes",
		"alignment": "CenterAlignFormatter",
		"data_type": "text",
		"filter_type": "text",
		"screen_help_text": "Audit trail of changes, manually maintained by admin team"
	},
	{
		"field": "UPDATE_TIMESTAMP",
		"screen_field_name": "Record Update",
		"screen_seq": "39",
		"screen_fl": "Y",
		"export_fl": "Y",
		"mandatory": "No",
		"is_editable": "No",
		"alignment": "CenterAlignFormatter",
		"data_type": "text",
		"filter_type": "text",
		"screen_help_text": "Update date for record"
	}
]

export const dropdownColourStyles = {
	control: styles => ({ ...styles, backgroundColor: 'white' }),
	option: (styles, { data, isDisabled, isFocused, isSelected }) => {
		const color = '#FFF';
		return {
			...styles,
			backgroundColor: '#FFF',
			color: isSelected
				? '#008fd3'
				: '#4a4a4a',
			cursor: isDisabled ? 'not-allowed' : 'default',
			':hover': {
				...styles[':hover'],
				backgroundColor: '#fafafa',
			},
		};
	}
};

export const IndustrySpendColumns = [
	{
		"field": "FINAL_GICS_INDUSTRY_LEVEL",
		"screen_field_name": "Industry Level",
		"screen_seq": "1",
		"filter_type": "numeric",
		"data_type": "numeric",
		"alignment": "RightAlignFormatter",
		"is_editable": "No",
		"export_fl": "Yes",
	},
	{
		"field": "FINAL_GICS_DESC_COMB",
		"screen_field_name": "Final Industry",
		"screen_seq": "2",
		"filter_type": "default",
		"alignment": "LeftAlignFormatter",
		"is_editable": "No",
		"export_fl": "Yes",
	},
	/*{
		"field": "FINAL_GICS",
		"screen_field_name": "Final GICS",
		"screen_seq": "3",
		"data_type": "text",
		"filter_type": "default"
	},
	{
		"field": "PARENT_GICS_LEVEL",
		"screen_field_name": "GICS Parent Level",
		"screen_seq": "3",
		"filter_type": "numeric",
		"data_type": "numeric",
		"alignment": "RightAlignFormatter",
		"is_editable": "No"
	},*/
	{
		"field": "PARENT_GICS_DESC_COMB",
		"screen_field_name": "Parent Final Industry",
		"screen_seq": "4",
		"filter_type": "default",
		"alignment": "LinkFormatter",
		"is_editable": "No",
		"export_fl": "Yes",
	},
	/*{
		"field": "PARENT_GICS",
		"screen_field_name": "Parent GICS",
		"screen_seq": "3",
		"data_type": "text",
		"filter_type": "default"
	},*/
	{
		"field": "COUNT_VENDOR_ID",
		"screen_field_name": "Total Suppliers",
		"screen_seq": "5",
		"filter_type": "numeric",
		"data_type": "numeric",
		"alignment": "AlignFormatter",
		"export_fl": "Yes",
	},
	{
		"field": "INVOICE_SPEND",
		"screen_field_name": "Sum of Aggregate Invoice Spend",
		"screen_seq": "6",
		"data_type": "numeric",
		"filter_type": "numeric",
		"alignment": "AlignCurrencyFormatter",
		"export_fl": "Yes",
	},
	{
		"field": "INVOICE_COUNT",
		"screen_field_name": "Sum of Aggregate Invoice Count",
		"screen_seq": "7",
		"filter_type": "numeric",
		"data_type": "numeric",
		"alignment": "AlignFormatter",
		"is_editable": "No",
		"export_fl": "Yes",
	},
	{
		"field": "PO_SPEND",
		"screen_field_name": "Sum of Aggregate PO Spend",
		"screen_seq": "8",
		"filter_type": "numeric",
		"data_type": "numeric",
		"alignment": "AlignCurrencyFormatter",
		"is_editable": "No",
		"export_fl": "Yes",
	},
	{
		"field": "PO_COUNT",
		"screen_field_name": "Sum of Aggregate PO Count",
		"screen_seq": "9",
		"filter_type": "numeric",
		"data_type": "numeric",
		"alignment": "AlignFormatter",
		"is_editable": "No",
		"export_fl": "Yes",
	},
	{
		"field": "DIRECT_SPEND_CNT",
		"screen_field_name": "Suppliers w/ Direct Spend",
		"screen_seq": "10",
		"filter_type": "numeric",
		"data_type": "numeric",
		"alignment": "AlignFormatter",
		"is_editable": "No",
		"export_fl": "Yes",
	},
	{
		"field": "MARK_ALL_SUPPLIER_DIRECT",
		"screen_field_name": "Mark all Suppliers as Direct?",
		"screen_seq": "11",
		"filter_type": "default",
		"alignment": "LeftAlignFormatter",
		"is_editable": "Yes"
	},
	{
		"field": "EXCLUSION_REASON_CNT",
		"screen_field_name": "Excluded Suppliers",
		"screen_seq": "12",
		"filter_type": "numeric",
		"data_type": "numeric",
		"alignment": "AlignFormatter",
		"is_editable": "No",
		"export_fl": "Yes",
	},
	{
		"field": "MARK_ALL_SUPPLIER_EXCLUSION",
		"screen_field_name": "Mark all suppliers with Exclusion Reason",
		"screen_seq": "13",
		"filter_type": "default",
		"alignment": "LeftAlignFormatter",
		"is_editable": "Yes"
	},
	{
		"field": "EXCLUSION_REASON",
		"screen_field_name": "Reason",
		"screen_seq": "14",
		"filter_type": "default",
		"alignment": "LeftAlignFormatter",
		"is_editable": "Yes"
	}
]


export const CountryColumns = [
	{
		"field": "COUNTRY_NAME",
		"screen_field_name": "Country Name",
		"screen_seq": "1",
		"screen_fl": "Y",
		"export_fl": "Y",
		"mandatory": "Yes",
		"is_editable": "No",
		"alignment": "CenterAlignFormatter",
		"data_type": "text",
		"filter_type": "default",
		"screen_help_text": "Country Name"
	},
	{
		"field": "COUNTRY_CODE",
		"screen_field_name": "Country Code",
		"screen_seq": "2",
		"screen_fl": "Y",
		"export_fl": "Y",
		"mandatory": "Yes",
		"is_editable": "No",
		"alignment": "CenterAlignFormatter",
		"data_type": "text",
		"filter_type": "default",
		"screen_help_text": "Country Code"
	},
	{
		"field": "LONGITUDE",
		"screen_field_name": "Longitude",
		"screen_seq": "3",
		"screen_fl": "Y",
		"export_fl": "Y",
		"mandatory": "No",
		"is_editable": "No",
		"alignment": "CenterAlignFormatter",
		"data_type": "text",
		"filter_type": "default",
		"screen_help_text": "Longitude"
	},
	{
		"field": "LATITUDE",
		"screen_field_name": "Latitude",
		"screen_seq": "4",
		"screen_fl": "Y",
		"export_fl": "Y",
		"mandatory": "No",
		"is_editable": "No",
		"alignment": "CenterAlignFormatter",
		"data_type": "text",
		"filter_type": "default",
		"screen_help_text": "Latitude"
	},
	{
		"field": "SAP_ARIBA_PAYABLES_DISCOUNT",
		"screen_field_name": "SAP Ariba payables discount",
		"screen_seq": "5",
		"screen_fl": "Y",
		"export_fl": "Y",
		"mandatory": "Yes",
		"is_editable": "Yes",
		"alignment": "CenterAlignFormatter",
		"data_type": "text",
		"filter_type": "text",
		"screen_help_text": "SAP Ariba payables discount"
	},
	{
		"field": "TERM_RATIONALIZATION",
		"screen_field_name": "Term rationalization",
		"screen_seq": "6",
		"screen_fl": "Y",
		"export_fl": "Y",
		"mandatory": "Yes",
		"is_editable": "Yes",
		"alignment": "CenterAlignFormatter",
		"data_type": "text",
		"filter_type": "text",
		"screen_help_text": "Term rationalization"
	},
	{
		"field": "SPOT_BUY_ELIGIBLE",
		"screen_field_name": "Spot buy eligible",
		"screen_seq": "7",
		"screen_fl": "Y",
		"export_fl": "Y",
		"mandatory": "Yes",
		"is_editable": "Yes",
		"alignment": "CenterAlignFormatter",
		"data_type": "text",
		"filter_type": "text",
		"screen_help_text": "Spot buy eligible"
	},
	{
		"field": "PAYABLE_ELIGIBLE",
		"screen_field_name": "Payable eligible",
		"screen_seq": "8",
		"screen_fl": "Y",
		"export_fl": "Y",
		"mandatory": "Yes",
		"is_editable": "Yes",
		"alignment": "CenterAlignFormatter",
		"data_type": "text",
		"filter_type": "text",
		"screen_help_text": "Payable eligible"
	},
	{
		"field": "LEGAL_E_INVOICING",
		"screen_field_name": "Recommended Legal e-Invoicing Status",
		"screen_seq": "9",
		"screen_fl": "Y",
		"export_fl": "Y",
		"mandatory": "Yes",
		"is_editable": "Yes",
		"alignment": "CenterAlignFormatter",
		"data_type": "text",
		"filter_type": "text",
		"screen_help_text": "Recommended Legal e-Invoicing Status"
	}
]

export const EnablementLogicRecommendationColumns = [
	{
		"field": "SEG_MATCH_SPEND_DOC",
		"screen_field_name": "Segmentation by Enablement Match, Transacting Buyer Relationships, Documents Segment & Spend Segment",
		"screen_seq": "1",
		"screen_fl": "Y",
		"export_fl": "Y",
		"mandatory": "Yes",
		"is_editable": "No",
		"alignment": "CenterAlignFormatter",
		"data_type": "text",
		"filter_type": "default",
		"screen_help_text": "Segmentation by Enablement Match, Transacting Buyer Relationships, Documents Segment & Spend Segment",
		"frozen": "true",
	},
	{
		"field": "BUY_INV_COMM_SUPP_ENABLE_RECMD",
		"screen_field_name": "Buying & Invoicing",
		"screen_seq": "2",
		"screen_fl": "Y",
		"export_fl": "Y",
		"mandatory": "Yes",
		"is_editable": "Yes",
		"alignment": "CenterAlignFormatter",
		"data_type": "text",
		"filter_type": "default",
		"screen_help_text": "Buying & Invoicing",
		"frozen": "false",
	},
	{
		"field": "COMM_SUPP_ENABLE_RECMD",
		"screen_field_name": "Commerce Automation",
		"screen_seq": "4",
		"screen_fl": "Y",
		"export_fl": "Y",
		"mandatory": "Yes",
		"is_editable": "Yes",
		"alignment": "CenterAlignFormatter",
		"data_type": "text",
		"filter_type": "default",
		"screen_help_text": "Commerce Automation",
		"frozen": "false",
	},
	{
		"field": "PURCHASE_BUY_COMM_SUPP_ENABLE_RECMD",
		"screen_field_name": "Buying (Purchase Order Only)",
		"screen_seq": "6",
		"screen_fl": "Y",
		"export_fl": "Y",
		"mandatory": "Yes",
		"is_editable": "Yes",
		"alignment": "CenterAlignFormatter",
		"data_type": "text",
		"filter_type": "default",
		"screen_help_text": "Buying (Purchase Order Only)",
		"frozen": "false",
	},
	{
		"field": "SCC_SAP_SUPP_ENABLEMENT_RECMD",
		"screen_field_name": "Supply Chain Collaboration",
		"screen_seq": "8",
		"screen_fl": "Y",
		"export_fl": "Y",
		"mandatory": "Yes",
		"is_editable": "Yes",
		"alignment": "CenterAlignFormatter",
		"data_type": "text",
		"filter_type": "default",
		"screen_help_text": "Supply Chain Collaboration",
		"frozen": "false",
	},
	{
		"field": "SUPPLIER_CHOICE",
		"screen_field_name": "Supplier Choice",
		"screen_seq": "12",
		"screen_fl": "Y",
		"export_fl": "Y",
		"mandatory": "Yes",
		"is_editable": "Yes",
		"alignment": "CenterAlignFormatter",
		"data_type": "text",
		"filter_type": "default",
		"screen_help_text": "Supplier Choice",
		"frozen": "false",
	},
	{
		"field": "SNAP",
		"screen_field_name": "SNAP",
		"screen_seq": "9",
		"screen_fl": "Y",
		"export_fl": "Y",
		"mandatory": "Yes",
		"is_editable": "Yes",
		"alignment": "CenterAlignFormatter",
		"data_type": "text",
		"filter_type": "default",
		"screen_help_text": "SNAP",
		"frozen": "false",
	},
	{
		"field": "DSN",
		"screen_field_name": "DSN",
		"screen_seq": "10",
		"screen_fl": "Y",
		"export_fl": "Y",
		"mandatory": "Yes",
		"is_editable": "Yes",
		"alignment": "CenterAlignFormatter",
		"data_type": "text",
		"filter_type": "default",
		"screen_help_text": "DSN",
		"frozen": "false",
	},
	{
		"field": "RISE",
		"screen_field_name": "RISE",
		"screen_seq": "11",
		"screen_fl": "Y",
		"export_fl": "Y",
		"mandatory": "Yes",
		"is_editable": "Yes",
		"alignment": "CenterAlignFormatter",
		"data_type": "text",
		"filter_type": "default",
		"screen_help_text": "RISE",
		"frozen": "false",
	},
	{
		"field": "ONBOARDING_WORKSTREAM_DETAILS",
		"screen_field_name": "Onboarding Workstream Details",
		"screen_seq": "12",
		"screen_fl": "Y",
		"export_fl": "Y",
		"mandatory": "No",
		"is_editable": "Yes",
		"alignment": "CenterAlignFormatter",
		"data_type": "text",
		"filter_type": "default",
		"screen_help_text": "Onboarding Workstream Details",
		"frozen": "false",
	}
]

export const EnablementLogicReasonColumns = [
	{
		"field": "SEG_MATCH_SPEND_DOC",
		"screen_field_name": "Segmentation by Match, Spend and Documents",
		"screen_seq": "1",
		"screen_fl": "Y",
		"export_fl": "Y",
		"mandatory": "Yes",
		"is_editable": "No",
		"alignment": "CenterAlignFormatter",
		"data_type": "text",
		"filter_type": "default",
		"screen_help_text": "Segmentation by Match, Spend and Documents"
	},
	{
		"field": "BUY_INV_COMM_SUPP_ENABLE_REASON",
		"screen_field_name": "Buying & Invoicing",
		"screen_seq": "2",
		"screen_fl": "Y",
		"export_fl": "Y",
		"mandatory": "Yes",
		"is_editable": "Yes",
		"alignment": "CenterAlignFormatter",
		"data_type": "text",
		"filter_type": "default",
		"screen_help_text": "Buying & Invoicing"
	},
	{
		"field": "COMM_SUPP_ENABLE_REASON",
		"screen_field_name": "Commerce Automation",
		"screen_seq": "3",
		"screen_fl": "Y",
		"export_fl": "Y",
		"mandatory": "Yes",
		"is_editable": "Yes",
		"alignment": "CenterAlignFormatter",
		"data_type": "text",
		"filter_type": "default",
		"screen_help_text": "Commerce Automation"
	},
	{
		"field": "PURCHASE_BUY_COMM_SUPP_ENABLE_REASON",
		"screen_field_name": "Buying (Purchase Order Only)",
		"screen_seq": "4",
		"screen_fl": "Y",
		"export_fl": "Y",
		"mandatory": "Yes",
		"is_editable": "Yes",
		"alignment": "CenterAlignFormatter",
		"data_type": "text",
		"filter_type": "default",
		"screen_help_text": "Buying (Purchase Order Only)"
	},
	// {
	// 	"field": "SCC_SAP_SUPP_ENABLEMENT_REASON",
	// 	"screen_field_name": "Supply Chain Collaboration",
	// 	"screen_seq": "8",
	// 	"screen_fl": "Y",
	// 	"export_fl": "Y",
	// 	"mandatory": "Yes",
	// 	"is_editable": "Yes",
	// 	"alignment": "CenterAlignFormatter",
	// 	"data_type": "text",
	// 	"filter_type": "default",
	// 	"screen_help_text":  "Supply Chain Collaboration"
	// },
	{
		"field": "SUPPLIER_CHOICE_REASON",
		"screen_field_name": "Supplier Choice Reason",
		"screen_seq": "5",
		"screen_fl": "Y",
		"export_fl": "Y",
		"mandatory": "Yes",
		"is_editable": "Yes",
		"alignment": "CenterAlignFormatter",
		"data_type": "text",
		"filter_type": "default",
		"screen_help_text": "Supplier Choice Reason"
	},
	{
		"field": "SNAP_REASON",
		"screen_field_name": "SNAP Reason",
		"screen_seq": "6",
		"screen_fl": "Y",
		"export_fl": "Y",
		"mandatory": "Yes",
		"is_editable": "Yes",
		"alignment": "CenterAlignFormatter",
		"data_type": "text",
		"filter_type": "default",
		"screen_help_text": "SNAP Reason"
	},
	{
		"field": "DSN_REASON",
		"screen_field_name": "DSN Reason",
		"screen_seq": "7",
		"screen_fl": "Y",
		"export_fl": "Y",
		"mandatory": "Yes",
		"is_editable": "Yes",
		"alignment": "CenterAlignFormatter",
		"data_type": "text",
		"filter_type": "default",
		"screen_help_text": "DSN Reason"
	},
	{
		"field": "RISE_REASON",
		"screen_field_name": "RISE Reason",
		"screen_seq": "8",
		"screen_fl": "Y",
		"export_fl": "Y",
		"mandatory": "Yes",
		"is_editable": "Yes",
		"alignment": "CenterAlignFormatter",
		"data_type": "text",
		"filter_type": "default",
		"screen_help_text": "RISE Reason"
	}
]

export const WorldMapSupplierColumnsForPopUp = [
	{
		"field": "REF_NUM",
		"screen_field_name": "Reference Number",
		"screen_seq": "1",
		"filter_type": "default",
		"alignment": "LeftAlignFormatter",
		"is_editable": "No",
		"export_fl": "Yes",
		"screen_help_text": "Provided directly from table"
	},
	{
		"field": "SUPPLIER_ID",
		"screen_field_name": "Vendor Id",
		"screen_seq": "2",
		"filter_type": "default",
		"alignment": "LeftAlignFormatter",
		"is_editable": "No",
		"export_fl": "Yes",
		"screen_help_text": "Provided directly from table"
	},
	{
		"field": "SUPPLIER_NAME",
		"screen_field_name": "Vendor Name",
		"screen_seq": "3",
		"filter_type": "default",
		"alignment": "LeftAlignFormatter",
		"is_editable": "No",
		"export_fl": "Yes",
		"screen_help_text": "Provided directly from table"
	},
	{
		"field": "INVOICE_COUNT",
		"screen_field_name": "Invoice Count",
		"screen_seq": "4",
		"filter_type": "numeric",
		"alignment": "AlignFormatter",
		"is_editable": "No",
		"export_fl": "Yes",
		"screen_help_text": "Provided directly from table"
	},
	{
		"field": "INVOICE_SPEND",
		"screen_field_name": "Invoice Spend",
		"screen_seq": "5",
		"filter_type": "numeric",
		"alignment": "AlignCurrencyFormatter",
		"is_editable": "No",
		"export_fl": "Yes",
		"screen_help_text": "Provided directly from table"
	},
	{
		"field": "PO_SPEND",
		"screen_field_name": "PO Spend",
		"screen_seq": "6",
		"filter_type": "numeric",
		"alignment": "AlignCurrencyFormatter",
		"is_editable": "No",
		"export_fl": "Yes",
		"screen_help_text": "Provided directly from table"
	},
	{
		"field": "PO_COUNT",
		"screen_field_name": "PO Count",
		"screen_seq": "7",
		"filter_type": "numeric",
		"alignment": "AlignFormatter",
		"is_editable": "No",
		"export_fl": "Yes",
		"screen_help_text": "Provided directly from table"
	},
	{
		"field": "BUYER_REGION",
		"screen_field_name": "Buyer Region",
		"screen_seq": "8",
		"filter_type": "default",
		"alignment": "LeftAlignFormatter",
		"is_editable": "No",
		"export_fl": "Yes",
		"screen_help_text": "Provided directly from table"
	},
	{
		"field": "ISO_COUNTRY_CODE",
		"screen_field_name": "ISO Country Code",
		"screen_seq": "9",
		"filter_type": "default",
		"alignment": "LeftAlignFormatter",
		"is_editable": "No",
		"export_fl": "Yes",
		"screen_help_text": "Provided directly from table"
	}
]

export const OnboardingWorkstreamSupplierColumns = [
	{		
		"field": "ENABLEMENT_RECOMMENDATION",
		"screen_field_name": "Buyer Supplier Enablement Recommendations",
		"screen_seq": "1",
		"alignment": "CenterAlignFormatter",				
		"is_editable": "Yes",
		"mandatory": "Yes",		
		"data_type": "text",
		"filter_type": "default",
		"export_fl": "Yes",
		"screen_help_text": "SAP provides a Buyer Supplier Enablement Recommendation based a buyers Aggregate Spend and Aggregate Document with a supplier plus the suppliers Enablement Match for a given solution."
	},
	{
		"field": "INTEGRATION_MATCH_TYPE",
		"screen_field_name": "Integration Match",
		"screen_seq": "2",
		"alignment": "CenterAlignFormatter",
		"data_type": "text",
		"filter_type": "default",
		"is_editable": "Yes",
		"mandatory": "Yes",
		"export_fl": "Yes",
		"screen_help_text": "Integration Match"
	},
	{
		"field": "ONBOARDING_WORKSTREAM",
		"screen_field_name": "Onboarding Workstream",
		"screen_seq": "3",		
		"mandatory": "Yes",
		"alignment": "CenterAlignFormatter",
		"data_type": "text",
		"filter_type": "default",
		"is_editable": "Yes",
		"export_fl": "Yes",
		"screen_help_text": "Onboarding Workstreams are used by SAP to determine how a supplier should be targeted for enablement.  Enablement Recommendations are converted to Onboarding Workstreams based on logic in the Enablement Parameters Menu.",
	},
	{
		"field": "ONBOARDING_WORKSTREAM_DETAILS",
		"screen_field_name": "Onboarding Workstream Details",
		"screen_seq": "4",
		"alignment": "CenterAlignFormatter",
		"data_type": "text",
		"filter_type": "default",
		"mandatory": "Yes",
		"is_editable": "Yes",
		"export_fl": "Yes",
		"screen_help_text": "Onboarding Workstream Details provide additional information to the buyer and SAP about how a supplier should be targeted.",
	}	
]

export const OutOfScopeReasonTabColumns = [
	{
		"field": "FINAL_SUPPLIER_ENABLE_TYPE",
		"screen_field_name": "Buyer Supplier Enablement Recommendation",
		"screen_seq": "1",
		"data_type": "text",
		"filter_type": "",
		"alignment": "LeftAlignFormatter",
		"is_editable": "No",
		"export_fl": "Yes",
		"screen_help_text": "This column is for the final enablement recommendation.  It is a working column used by SAP, the partner and the customer to adjust the enablement method for each suppliers."
	},
	
	{
		"field": "ENABLE_MTHD_RECMD_OUT_SCOPE_REASON",
		"screen_field_name": "Out of Scope Reason",
		"screen_seq": "2",
		"data_type": "text",
		"filter_type": "",
		"alignment": "LeftAlignFormatter",
		"is_editable": "No",
		"export_fl": "Yes",
		"screen_help_text": "Free text field providing the reason why a supplier is out of scope."
	},
	{
		"field": "GANTT_CHART_WORKSTREAM_NAME",
		"screen_field_name": "Onboarding Workstream",
		"screen_seq": "3",
		"data_type": "text",
		"filter_type": "default",
		"alignment": "LeftAlignFormatter",
		"is_editable": "No",
		"export_fl": "Yes",
		"screen_help_text": "The Onboarding Workstream is derived from the Buyer Supplier Enablement Recommendation and the Integration Match to propose a workstream for the SAP Enablement resources and customer.  You can change this by changing the Buyer Supplier Enablement Recommendation."
	},
	{
		"field": "SMP_SUBSCRIPTION_LEVEL",
		"screen_field_name": "SMP Subscription Level",
		"screen_seq": "3",
		"data_type": "text",
		"filter_type": "default",
		"alignment": "LeftAlignFormatter",
		"is_editable": "No",
		"export_fl": "Yes",
		"screen_help_text": "Ariba Network data for the matched ANID."
	},
	{
		"field": "FINAL_INDUSTRY_ID",
		"screen_field_name": "Final Industry ID",
		"screen_seq": "3",
		"data_type": "text",
		"filter_type": "default",
		"alignment": "LeftAlignFormatter",
		"is_editable": "No",
		"export_fl": "Yes",
		"screen_help_text": "Final GICS"
	},
	{
		"field": "FINAL_INDUSTRY_DESCRIPTION",
		"screen_field_name": "Final Industry Description",
		"screen_seq": "3",
		"data_type": "text",
		"filter_type": "default",
		"alignment": "LeftAlignFormatter",
		"is_editable": "No",
		"export_fl": "Yes",
		"screen_help_text": "GICS Scope Map value for Final Industry (GICS code)"
	}
]

export const EnablementInsightSupplierColumns = [
	// {
	// 	"field": "DATA_COLLECTIONS_SKEY",
	// 	"screen_field_name": "Skey",
	// 	"screen_seq": "1",
	// 	"filter_type": "default",
	// 	"alignment": "LeftAlignFormatter",
	// 	"is_editable": "No",
	// 	"export_fl": "Yes",
	// 	"screen_help_text": "Provided directly from table"
	// },	
	{
		"field": "SUPPLIER_ID",
		"screen_field_name": "Vender Id",
		"screen_seq": "1",
		"alignment": "LeftAlignFormatter",
		"data_type": "text",
		"filter_type": "default",
		"is_editable": "No",
		"export_fl": "Yes",
		"screen_help_text": "Provided directly from customer input file"
	},
	{
		"field": "SUPPLIER_NAME",
		"screen_field_name": "Vender Name",
		"screen_seq": "2",
		"alignment": "CenterAlignFormatter",
		"data_type": "text",
		"filter_type": "default",
		"is_editable": "No",
		"export_fl": "Yes",
		"screen_help_text": "Provided directly from customer input file"
	},
	{
		"field": "SAP_SUPPLIER_ENABLE_LOG",
		"screen_field_name": "SAP Supplier Enablement Recommendation Log",
		"screen_seq": "3",
		"data_type": "text",
		"filter_type": "",
		"alignment": "LeftAlignFormatter",
		"is_editable": "No",
		"export_fl": "Yes",
		"screen_help_text": "This column is a log of the SAP Business Network data based enablement recommendation.  It is intended to display the recommended enablement method without considering if it should be enabled."
	},
	{
		"field": "FINAL_SUPPLIER_ENABLE_TYPE",
		"screen_field_name": "Buyer Supplier Enablement Recommendation",
		"screen_seq": "4",
		"data_type": "text",
		"filter_type": "",
		"alignment": "LeftAlignFormatter",
		"is_editable": "Yes",
		"export_fl": "Yes",
		"screen_help_text": "This column is for the final enablement recommendation.  It is a working column used by SAP, the partner and the customer to adjust the enablement method for each suppliers."
	},	
	{
		"field": "ONBOARDING_WORKSTREAM",
		"screen_field_name": "Onboarding Workstream",
		"screen_seq": "5",
		"data_type": "text",
		"filter_type": "default",
		"alignment": "CenterAlignFormatter",
		"is_editable": "No",
		"export_fl": "Yes",
		"screen_help_text": "Onboarding Workstreams are used by SAP to determine how a supplier should be targeted for enablement.  Enablement Recommendations are converted to Onboarding Workstreams based on logic in the Enablement Parameters Menu.",
	},
	{
		"field": "ONBOARDING_WORKSTREAM_DETAILS",
		"screen_field_name": "Onboarding Workstream Details",
		"screen_seq": "6",
		"data_type": "text",
		"filter_type": "",
		"alignment": "LeftAlignFormatter",
		"is_editable": "Yes",
		"export_fl": "Yes",
		"screen_help_text": "The Onboarding Workstream Details provided additional details about how to enable a supplier.  These include Integration Questionaire and Supplier Choice."
	},
	{
		"field": "ENABLEMENT_WAVE",
		"screen_field_name": "Enablement Wave",
		"screen_seq": "7",
		"data_type": "text",
		"filter_type": "",
		"alignment": "CenterAlignFormatter",
		"is_editable": "Yes",
		"export_fl": "Yes",
		"screen_help_text": "Use this column to define manageable enablement supplier waves.\nEnablement Waves Best Practice with 1 SEL, 1CL & 1 SIL:\n- 300 Enterprise Enablement Suppliers (Wave X)\n- 300 Standard Enablement Suppliers (Wave X)\n- 20 Catalogs (Wave 1), 40 Catalogs (Wave X+1)\n- 5 Seller Integrations (Wave 1), 10 Seller Integrations (Wave X+1)"
	},
	{
		"field": "WAVE_DESCRIPTION",
		"screen_field_name": "Enablement Wave Description",
		"screen_seq": "8",
		"data_type": "text",
		"filter_type": "",
		"alignment": "CenterAlignFormatter",
		"is_editable": "No",
		"export_fl": "Yes",
		"screen_help_text": "The Enablement Wave Description is a text field to describe each Enablement Wave."
	},	
	{
		"field": "DEDUP_STATUS",
		"screen_field_name": "Dedup Stats",
		"screen_seq": "9",
		"alignment": "CenterAlignFormatter",
		"data_type": "text",
		"filter_type": "default",
		"export_fl": "Yes",
		"screen_help_text": "- Unique: Supplier that is not duplicated in the file or the parent for a duplicated supplier where spend has been aggregated.- Duplicated: Child supplier for a duplicated supplier.  No aggregate spend for duplicated records."
	},
	{
		"field": "BUSINESS_UNIT",
		"screen_field_name": "Business Unit",
		"screen_seq": "10",
		"data_type": "text",
		"filter_type": "",
		"alignment": "LeftAlignFormatter",
		"is_editable": "No",
		"export_fl": "Yes",
		"screen_help_text": "Provided directly from customer input file"
	},
	{
		"field": "ERP_SYSTEM",
		"screen_field_name": "ERP System",
		"screen_seq": "11",
		"data_type": "text",
		"filter_type": "default",
		"alignment": "LeftAlignFormatter",
		"is_editable": "No",
		"export_fl": "Yes",
		"screen_help_text": "Provided directly from customer input file"
	},
	{
		"field": "AN_SUPPLIER_COUNTRY",
		"screen_field_name": "Supplier Country",
		"screen_seq": "12",
		"data_type": "text",
		"filter_type": "default",
		"alignment": "CenterAlignFormatter",
		"export_fl": "Yes",
		"screen_help_text": "Ariba Network data for the matched ANID."
	},
	{
		"field": "BUYER_REGION",
		"screen_field_name": "Buyer Region",
		"screen_seq": "13",
		"data_type": "text",
		"filter_type": "default",
		"alignment": "CenterAlignFormatter",
		"export_fl": "Yes",
		"screen_help_text": "Provided directly from customer input file"
	},
	{
		"field": "LEGAL_E_INVOICING",
		"screen_field_name": "Legal E-Invoicing",
		"screen_seq": "14",
		"data_type": "text",
		"filter_type": "",
		"alignment": "LeftAlignFormatter",
		"export_fl": "Yes",
		"screen_help_text": "Recommended Legal e-Invoicing Status"
	},
	{
		"field": "L2_INDUSTRY_NAME",
		"screen_field_name": "L2 Industry Name",
		"screen_seq": "15",
		"data_type": "text",
		"filter_type": "",
		"alignment": "LeftAlignFormatter",
		"is_editable": "No",
		"export_fl": "Yes",
		"screen_help_text": "Global Industry Classification Standard (GICS) master data for the Final Industry ID."
	},
	{
		"field": "L4_INDUSTRY_NAME",
		"screen_field_name": "L4 Industry Name",
		"screen_seq": "16",
		"data_type": "text",
		"filter_type": "",
		"alignment": "LeftAlignFormatter",
		"is_editable": "No",
		"export_fl": "Yes",
		"screen_help_text": "Global Industry Classification Standard (GICS) master data for the Final Industry ID."
	},
	{
		"field": "AGGREGATE_INVOICE_SPEND",
		"screen_field_name": "Aggregate Invoice Spend",
		"screen_seq": "17",
		"filter_type": "numeric",
		"data_type": "numeric",
		"alignment": "AlignFormatter",
		"is_editable": "No",
		"export_fl": "Yes",
		"screen_help_text": "Aggregated total.  For duplicated records, all records for the dedupe-key will be aggregated into the parent record.  For unique (non-duplicated) records, this value will match the input record (no aggregation necessary)."
	},
	{
		"field": "AGGREGATE_INVOICE_COUNT",
		"screen_field_name": "Aggregate Invoice Count",
		"screen_seq": "18",
		"filter_type": "numeric",
		"data_type": "numeric",
		"alignment": "AlignFormatter",
		"is_editable": "No",
		"export_fl": "Yes",
		"screen_help_text": "Aggregated total.  For duplicated records, all records for the dedupe-key will be aggregated into the parent record.  For unique (non-duplicated) records, this value will match the input record (no aggregation necessary)."
	},
	{
		"field": "AGGREGATE_PO_SPEND",
		"screen_field_name": "Aggregate PO Spend",
		"screen_seq": "19",
		"filter_type": "numeric",
		"data_type": "numeric",
		"alignment": "AlignFormatter",
		"is_editable": "No",
		"export_fl": "Yes",
		"screen_help_text": "Aggregated total.  For duplicated records, all records for the dedupe-key will be aggregated into the parent record.  For unique (non-duplicated) records, this value will match the input record (no aggregation necessary)."
	},
	{
		"field": "AGGREGATE_PO_COUNT",
		"screen_field_name": "Aggregate PO Count",
		"screen_seq": "20",
		"filter_type": "numeric",
		"data_type": "numeric",
		"alignment": "AlignFormatter",
		"is_editable": "No",
		"export_fl": "Yes",
		"screen_help_text": "Aggregated total.  For duplicated records, all records for the dedupe-key will be aggregated into the parent record.  For unique (non-duplicated) records, this value will match the input record (no aggregation necessary)."
	},
	{
		"field": "SAP_ARIBA_BUY_PROCESS",
		"screen_field_name": "SAP Ariba Buy Process",
		"screen_seq": "21",
		"data_type": "text",
		"filter_type": "",
		"alignment": "CenterAlignFormatter",
		"is_editable": "No",
		"export_fl": "Yes",
		"screen_help_text": "User update: Only when Scope Source = 'Override' and Exclusion Reason is blank - When Scope Source = 'Industry', this value is assigned based on the value for the Final Industry for this supplier.- When Scope Source = 'Override', this value can be updated based on scope and buy process decisions made with the customer."
	},
	{
		"field": "ENABLEMENT_MATCH_TYPE",
		"screen_field_name": "Enablement Match",
		"screen_seq": "22",
		"alignment": "CenterAlignFormatter",
		"data_type": "text",
		"filter_type": "",
		"is_editable": "No",
		"export_fl": "Yes",
		"screen_help_text": "The Ariba Network Enablement Match is insight derived from combining the data in five Match & Classification fields. The fields are the Account Managed Supplier, Transacted POs/ Invoices in the last 12 months, Standard or Enterprise Account, Ariba Network Subscription Level and the Ariba Network Match. By using these fields we can say that a supplier is more likely to enable because of how they are transacting with the Ariba Network today.\nAriba Network Enablement Match Types are: \nTransacting - Enterprise Ready\nTransacting - Enterprise Account Managed\nTransacting - Enterprise High Volume\nTransacting - Enterprise\nTransacting - Enterprise Buyer Funded\nTransacting - Standard\nSAP Ariba Network Match\nNo Match"
	},
	{
		"field": "CATALOG_MATCH_TYPE",
		"screen_field_name": "Catelog Match",
		"screen_seq": "23",
		"alignment": "CenterAlignFormatter",
		"data_type": "text",
		"filter_type": "",
		"is_editable": "No",
		"export_fl": "Yes",
		"screen_help_text": "The Ariba Network Catalog Match is insight derived from combining the data in four Match & Classification fields. The fields are Catalog Ready, Published Catalogs, Punchout Catalogs & SDS Supplier. By using these fields we can say that a supplier is more likely to enable catalogs because catalogs they have with other suppliers on the Ariba Network today.\nAriba Network Catalog Match Types are: \nSupplier Designated Specialist\nCatalog Ready\nMultiple Catalogs/ Punch Out Capable\nMultiple Catalogs\nCatalog/ Punch Out Capable\nCatalog Capable\nCatalog - No History"
	},
	{
		"field": "INTEGRATION_MATCH_TYPE",
		"screen_field_name": "Integration Match",
		"screen_seq": "24",
		"alignment": "CenterAlignFormatter",
		"data_type": "text",
		"filter_type": "",
		"is_editable": "No",
		"export_fl": "Yes",
		"screen_help_text": "Integration Match Type"
	},
	{
		"field": "SAP_ARIBA_NETWORK_ELIGIBLE",
		"screen_field_name": "SAP Ariba Network Eligible",
		"screen_seq": "25",
		"alignment": "CenterAlignFormatter",
		"data_type": "text",
		"filter_type": "",
		"is_editable": "No",
		"export_fl": "Yes",
		"screen_help_text": "SAP Ariba Network Eligible"
	},
	{
		"field": "SAP_ARIBA_DOC_PROXY",
		"screen_field_name": "SAP Ariba Document Proxy",
		"screen_seq": "26",
		"filter_type": "numeric",
		"data_type": "numeric",
		"alignment": "IntegralpartFormatter",
		"is_editable": "Yes",
		"export_fl": "Yes",
		"screen_help_text": "This field is a document count proxy used to assign enablement methods.  It does not represent actual documents but a value that can be used by the algorithm to assign a recommended enablement method.\nStandard Calculation:\nIf Aggregate Invoice Count>0 & Aggregate PO Count>0 then Aggregate Invoice Count + Aggregate PO Count\nIf Aggregate Invoice Count>0 & Aggregate PO Count=0 then Aggregate Invoice Count*1.5\nIf Aggregate Invoice Count=0 & Aggregate PO Count>0 then Aggregate PO Count*3"
	},
	{
		"field": "SAP_ARIBA_SPEND_PROXY",
		"screen_field_name": "SAP Ariba Spend Proxy",
		"screen_seq": "27",
		"filter_type": "numeric",
		"data_type": "numeric",
		"alignment": "IntegralpartFormatter",
		"is_editable": "Yes",
		"export_fl": "Yes",
		"screen_help_text": "This field is a spend proxy used to assign enablement methods. It does not represent actual  spend but a value that can be used by the algorithm to assign a recommended enablement method.\nStandard Calculation:\nIf Aggregate Invoice Spend is greater than Aggregate PO Spend then Aggregate Invoice Spend\nIf Aggregate PO Spend is greater than Aggregate Invoice Spend then Aggregate PO Spend "
	},
	{
		"field": "SAP_ARIBA_SPEND_PROXY_USD",
		"screen_field_name": "SAP Ariba Spend Proxy USD",
		"screen_seq": "28",
		"filter_type": "numeric",
		"data_type": "numeric",
		"alignment": "IntegralpartFormatter",
		"is_editable": "No",
		"export_fl": "Yes",
		"screen_help_text": "This field is a spend proxy used to assign enablement methods. It does not represent actual  spend but a value that can be used by the algorithm to assign a recommended enablement method.\nStandard Calculation:\nIf Aggregate Invoice Spend is greater than Aggregate PO Spend then Aggregate Invoice Spend\nIf Aggregate PO Spend is greater than Aggregate Invoice Spend then Aggregate PO Spend "
	},
	{
		"field": "DOC_COUNT_SEGMENTATION",
		"screen_field_name": "Document Proxy Segmentation",
		"screen_seq": "29",
		"data_type": "text",
		"filter_type": "",
		"alignment": "CenterAlignFormatter",
		"is_editable": "No",
		"export_fl": "Yes",
		"screen_help_text": "Document Proxy Segmentation"
	},
	{
		"field": "SPEND_SEGMENTATION",
		"screen_field_name": "Spend Proxy Segmentation",
		"screen_seq": "30",
		"data_type": "text",
		"filter_type": "",
		"alignment": "CenterAlignFormatter",
		"is_editable": "No",
		"export_fl": "Yes",
		"screen_help_text": "Spend Proxy Segmentation"
	},
	{
		"field": "BUYER_SUPPLIER_RELATIONSHIP",
		"screen_field_name": "Buyer Supplier Relationship",
		"screen_seq": "31",
		"data_type": "text",
		"filter_type": "",
		"alignment": "CenterAlignFormatter",
		"is_editable": "Yes",
		"export_fl": "Yes",
		"screen_help_text": "Buyer Supplier Relationship"
	},	

	// {
	// 	"field": "NET_SPEND_CAT_RANK_IND",
	// 	"screen_field_name": "Network Spend Category Rank",
	// 	"screen_seq": "30",
	// 	"alignment": "CenterAlignFormatter",
	// 	"data_type": "text",
	// 	"filter_type": "default",
	// 	"is_editable": "No",
	// 	"export_fl": "Yes",
	// 	"screen_help_text": "Spend rank of the suppliers assigned L4 GiCs code in the defined industry"
	// },
	// {
	// 	"field": "NET_SPEND_CAT_SEGMENTATION",
	// 	"screen_field_name": "Network Spend Category Segmentation",
	// 	"screen_seq": "31",
	// 	"data_type": "text",
	// 	"filter_type": "",
	// 	"alignment": "LeftAlignFormatter",
	// 	"is_editable": "No",
	// 	"export_fl": "Yes",
	// 	"screen_help_text": "Segmentation by Top 10, Top 25, Top 50, Top 100, Spend in Industry and Unclassified based on the defined industry"
	// },
	// {
	// 	"field": "NET_DOC_CAT_RANK_IND",
	// 	"screen_field_name": "Network Document Category Rank",
	// 	"screen_seq": "32",
	// 	"alignment": "CenterAlignFormatter",
	// 	"data_type": "text",
	// 	"filter_type": "default",
	// 	"is_editable": "No",
	// 	"export_fl": "Yes",
	// 	"screen_help_text": "Documents rank of the suppliers assigned L4 GiCs code in the defined industry"
	// },
	// {
	// 	"field": "NET_DOC_CAT_SEGMENTATION",
	// 	"screen_field_name": "Network Document Category Segmentation",
	// 	"screen_seq": "33",
	// 	"data_type": "text",
	// 	"filter_type": "",
	// 	"alignment": "LeftAlignFormatter",
	// 	"is_editable": "No",
	// 	"export_fl": "Yes",
	// 	"screen_help_text": "Segmentation by Top 10, Top 25, Top 50, Top 100, Documents in Industry and Unclassified based on the defined industry"
	// },
	// {
	// 	"field": "ENTERPRISE_ESTIM_SUPP_YIELD",
	// 	"screen_field_name": "Enterprise Estimated Supplier Yield%",
	// 	"screen_seq": "34",
	// 	"filter_type": "numeric",
	// 	"data_type": "numeric",
	// 	"alignment": "IntegralpartPercentFormatter",
	// 	"is_editable": "No",
	// 	"export_fl": "Yes",
	// 	"screen_help_text": "Estimated Yield of Suppliers based on the Enablement Match"
	// },
	// {
	// 	"field": "ENTERPRISE_ESTIM_SPEND_YIELD",
	// 	"screen_field_name": "Enterprise Estimated Spend Yield",
	// 	"screen_seq": "35",
	// 	"filter_type": "numeric",
	// 	"data_type": "numeric",
	// 	"alignment": "IntegralpartFormatter",
	// 	"is_editable": "No",
	// 	"export_fl": "Yes",
	// 	"screen_help_text": "Estimated yield for spend based on the greater of aggregate invoice spend or aggregate PO spend in standard currency"
	// },
	// {
	// 	"field": "ENTERPRISE_ESTIM_DOC_YIELD",
	// 	"screen_field_name": "Enterprise Estimated Document Yield",
	// 	"screen_seq": "36",
	// 	"filter_type": "numeric",
	// 	"data_type": "numeric",
	// 	"alignment": "IntegralpartFormatter",
	// 	"is_editable": "No",
	// 	"export_fl": "Yes",
	// 	"screen_help_text": "Estimate yield of documents based on the SAP Ariba Document Proxy"
	// },
	// {
	// 	"field": "ERP_DOCUMENT_CNT",
	// 	"screen_field_name": "ERP Document Count",
	// 	"screen_seq": "37",
	// 	"filter_type": "numeric",
	// 	"data_type": "numeric",
	// 	"alignment": "IntegralpartFormatter",
	// 	"is_editable": "No",
	// 	"export_fl": "Yes",
	// 	"screen_help_text": "ERP documents from the buyers spend file"
	// },
	// {
	// 	"field": "ERP_SPEND",
	// 	"screen_field_name": "ERP Spend",
	// 	"screen_seq": "38",
	// 	"filter_type": "numeric",
	// 	"data_type": "numeric",
	// 	"alignment": "IntegralpartFormatter",
	// 	"is_editable": "No",
	// 	"export_fl": "Yes",
	// 	"screen_help_text": "ERP spend from the buyers spend file"
	// },
	// {
	// 	"field": "ENABLEMENT_RECOMMENDATION_OVERRIDE",
	// 	"screen_field_name": "Enablement Recommendation Override",
	// 	"screen_seq": "39",
	// 	"data_type": "text",
	// 	"filter_type": "default",
	// 	"alignment": "LeftAlignFormatter",
	// 	"is_editable": "Yes",
	// 	"export_fl": "No",
	// 	"screen_help_text": "Enablement Recommendation Override"
	// },
	// {
	// 	"field": "ENABLEMENT_WAVE_OVERRIDE",
	// 	"screen_field_name": "Enablement Wave Override",
	// 	"screen_seq": "40",
	// 	"data_type": "text",
	// 	"filter_type": "default",
	// 	"alignment": "LeftAlignFormatter",
	// 	"is_editable": "Yes",
	// 	"export_fl": "No",
	// 	"screen_help_text": "Enablement Wave Override"
	// }
]

export const ExcelUploadColumns = [
	{
		"field": "DATA_COLLECTIONS_SKEY",
		"screen_field_name": "Skey",
		"screen_seq": "1",
		"filter_type": "default",
		"alignment": "LeftAlignFormatter",
		"is_editable": "No",
		"export_fl": "Yes",
		"screen_help_text": "Provided directly from table"
	},
	{
		"field": "REF_NUMBER",
		"screen_field_name": "Ref Number",
		"screen_seq": "2",
		"filter_type": "default",
		"alignment": "LeftAlignFormatter",
		"is_editable": "No",
		"export_fl": "Yes",
		"screen_help_text": "Ref Number"
	},
	{
		"field": "VENDOR_ID",
		"screen_field_name": "Vendor Id",
		"screen_seq": "3",
		"filter_type": "default",
		"alignment": "LeftAlignFormatter",
		"is_editable": "No",
		"export_fl": "Yes",
		"screen_help_text": "Vendor Id"
	},
	{
		"field": "VENDOR_NAME",
		"screen_field_name": "Vendor Name",
		"screen_seq": "4",
		"filter_type": "default",
		"alignment": "LeftAlignFormatter",
		"is_editable": "No",
		"export_fl": "Yes",
		"screen_help_text": "Vendor Name"
	},
	{
		"field": "BUYER_SUPPLIER_RELATIONSHIP",
		"screen_field_name": "Buyer Supplier Relationship",
		"screen_seq": "5",
		"data_type": "text",
		"textLength": "50",
		"filter_type": "",
		"alignment": "CenterAlignFormatter",
		"is_editable": "Yes",
		"export_fl": "Yes",
		"screen_help_text": "Buyer Supplier Relationship"
	},
	{
		"field": "SAP_ARIBA_BUY_ENABLE_MTHD_RECMD",
		"screen_field_name": "Buyer Supplier Enablement Recommendation",
		"screen_seq": "6",
		"data_type": "text",
		"filter_type": "",
		"alignment": "LeftAlignFormatter",
		"is_editable": "Yes",
		"export_fl": "Yes",
		"screen_help_text": "This column is for the final enablement recommendation.  It is a working column used by SAP, the partner and the customer to adjust the enablement method for each suppliers."
	},
	{
		"field": "ENABLE_MTHD_RECMD_OUT_SCOPE_REASON",
		"screen_field_name": "Out of Scope Reason",
		"screen_seq": "7",
		"data_type": "text",
		"filter_type": "",
		"alignment": "LeftAlignFormatter",
		"is_editable": "Yes",
		"export_fl": "Yes",
		"screen_help_text": "Free text field providing the reason why a supplier is out of scope."
	},
	{
		"field": "GANTT_CHART_WORKSTREAM_NAME",
		"screen_field_name": "Onboarding Workstream",
		"screen_seq": "8",
		"data_type": "text",
		"filter_type": "default",
		"alignment": "LeftAlignFormatter",
		"is_editable": "No",
		"export_fl": "Yes",
		"screen_help_text": "The Onboarding Workstream is derived from the Buyer Supplier Enablement Recommendation and the Integration Match to propose a workstream for the SAP Enablement resources and customer.  You can change this by changing the Buyer Supplier Enablement Recommendation."
	},
	{
		"field": "ONBOARDING_WORKSTREAM_DETAILS",
		"screen_field_name": "Onboarding Workstream Details",
		"screen_seq": "9",
		"data_type": "text",
		"filter_type": "",
		"alignment": "LeftAlignFormatter",
		"is_editable": "Yes",
		"export_fl": "Yes",
		"screen_help_text": "The Onboarding Workstream Details provided additional details about how to enable a supplier.  These include Integration Questionaire and Supplier Choice."
	},
	{
		"field": "ENABLEMENT_WAVE",
		"screen_field_name": "Enablement Wave",
		"screen_seq": "10",
		"data_type": "text",
		"filter_type": "",
		"alignment": "CenterAlignFormatter",
		"is_editable": "Yes",
		"export_fl": "Yes",
		"screen_help_text": "Use this column to define manageable enablement supplier waves.\nEnablement Waves Best Practice with 1 SEL, 1CL & 1 SIL:\n- 300 Enterprise Enablement Suppliers (Wave X)\n- 300 Standard Enablement Suppliers (Wave X)\n- 20 Catalogs (Wave 1), 40 Catalogs (Wave X+1)\n- 5 Seller Integrations (Wave 1), 10 Seller Integrations (Wave X+1)"
	},
	{
		"field": "WAVE_DESCRIPTION",
		"screen_field_name": "Wave Description",
		"screen_seq": "11",
		"data_type": "text",
		"filter_type": "",
		"alignment": "CenterAlignFormatter",
		"is_editable": "No",
		"export_fl": "Yes",
		"screen_help_text": "Wave Description"
	},
	{
		"field": "USER_DEF_TEXT_FIELD_1",
		"screen_field_name": "User Defined Text Field 1",
		"screen_seq": "12",
		"filter_type": "default",
		"data_type": "text",
		"textLength": "128",
		"alignment": "LeftAlignFormatter",
		"is_editable": "Yes",
		"export_fl": "Yes",
		"screen_help_text": "User Defined Text Field 1"
	},
	{
		"field": "USER_DEF_TEXT_FIELD_2",
		"screen_field_name": "User Defined Text Field 2",
		"screen_seq": "13",
		"filter_type": "default",
		"data_type": "text",
		"textLength": "128",
		"alignment": "LeftAlignFormatter",
		"is_editable": "Yes",
		"export_fl": "Yes",
		"screen_help_text": "User Defined Text Field 2"
	},
	{
		"field": "USER_DEF_TEXT_FIELD_3",
		"screen_field_name": "User Defined Text Field 3",
		"screen_seq": "14",
		"filter_type": "default",
		"data_type": "text",
		"textLength": "128",
		"alignment": "LeftAlignFormatter",
		"is_editable": "Yes",
		"export_fl": "Yes",
		"screen_help_text": "User Defined Text Field 3"
	},
	{
		"field": "USER_DEF_TEXT_FIELD_4",
		"screen_field_name": "User Defined Text Field 4",
		"screen_seq": "15",
		"filter_type": "default",
		"data_type": "text",
		"textLength": "128",
		"alignment": "LeftAlignFormatter",
		"is_editable": "Yes",
		"export_fl": "Yes",
		"screen_help_text": "User Defined Text Field 4"
	},
	{
		"field": "USER_DEF_TEXT_FIELD_5",
		"screen_field_name": "User Defined Text Field 5",
		"screen_seq": "16",
		"filter_type": "default",
		"data_type": "text",
		"textLength": "128",
		"alignment": "LeftAlignFormatter",
		"is_editable": "Yes",
		"export_fl": "Yes",
		"screen_help_text": "User Defined Text Field 5"
	},
	{
		"field": "USER_DEF_NUMBERIC_FIELD_1",
		"screen_field_name": "User Defined Numeric Field 1",
		"screen_seq": "17",
		"filter_type": "default",
		"data_type": "numeric",
		"alignment": "LeftAlignFormatter",
		"is_editable": "Yes",
		"export_fl": "Yes",
		"screen_help_text": "User Defined Numeric Field 1"
	},
	{
		"field": "USER_DEF_NUMBERIC_FIELD_2",
		"screen_field_name": "User Defined Numeric Field 2",
		"screen_seq": "18",
		"filter_type": "default",
		"data_type": "numeric",
		"alignment": "LeftAlignFormatter",
		"is_editable": "Yes",
		"export_fl": "Yes",
		"screen_help_text": "User Defined Numeric Field 2"
	},
	{
		"field": "USER_DEF_NUMBERIC_FIELD_3",
		"screen_field_name": "User Defined Numeric Field 3",
		"screen_seq": "19",
		"filter_type": "numeric",
		"data_type": "numeric",
		"alignment": "IntegralpartFormatter",
		"is_editable": "Yes",
		"export_fl": "Yes",
		"screen_help_text": "User Defined Numeric Field 3"
	},
	{
		"field": "USER_DEF_NUMBERIC_FIELD_4",
		"screen_field_name": "User Defined Numeric Field 4",
		"screen_seq": "20",
		"filter_type": "numeric",
		"data_type": "numeric",
		"alignment": "IntegralpartFormatter",
		"is_editable": "Yes",
		"export_fl": "Yes",
		"screen_help_text": "User Defined Numeric Field 4"
	},
	{
		"field": "USER_DEF_NUMBERIC_FIELD_5",
		"screen_field_name": "User Defined Numeric Field 5",
		"screen_seq": "21",
		"data_type": "numeric",
		"filter_type": "",
		"alignment": "CenterAlignFormatter",
		"is_editable": "Yes",
		"export_fl": "Yes",
		"screen_help_text": "User Defined Numeric Field 5"
	},
	{
		"field": "USER_DATE_FIELD",
		"screen_field_name": "User Defined Date Field 1",
		"screen_seq": "22",
		"data_type": "text",
		"filter_type": "",
		"alignment": "CenterAlignFormatter",
		"is_editable": "Yes",
		"export_fl": "Yes",
		"screen_help_text": "User Defined Date Field 1"
	},
	// {
	// 	"field": "isUserDefinedRowUpdated",
	// 	"screen_field_name": "Update Flag",
	// 	"screen_seq": "20",
	// 	"data_type": "text",
	// 	"filter_type": "",
	// 	"alignment": "CenterAlignFormatter",
	// 	"is_editable": "Yes",
	// 	"export_fl": "Yes",
	// 	"screen_help_text": "Update Flag"
	// },  
	{
		"field": "SAP_ARIBA_DOC_PROXY",
		"screen_field_name": "SAP Ariba Document Proxy",
		"screen_seq": "23",
		"data_type": "text",
		"filter_type": "",
		"alignment": "CenterAlignFormatter",
		"is_editable": "No",
		"export_fl": "Yes",
		"screen_help_text": "SAP Ariba Document Proxy"
	},
	{
		"field": "SAP_ARIBA_SPEND_PROXY",
		"screen_field_name": "SAP Ariba Spend Proxy",
		"screen_seq": "24",
		"data_type": "text",
		"filter_type": "",
		"alignment": "CenterAlignFormatter",
		"is_editable": "No",
		"export_fl": "Yes",
		"screen_help_text": "SAP Ariba Spend Proxy"
	},

]


export const kpiFormatRowWithThousandMillion = {
	0: 'Million',
	1: 'Thousand',
	2: 'Million',
	3: 'Thousand',
	4: 'Thousand',
};

export const kpiRowCustomOrder = {
	4: 0,
	3: 1,
	2: 2,
	1: 3,
	5: 4
};

export const kpiModel = {
	'Intelligent-KPI': 1,
	'12-Month-KPI': 2,
	'18-Month-KPI': 3,
	'24-Month-KPI': 4,
	'Manual-KPI': 5,
	'Per-Calculation': 6
 };

export const kpiTargetShortNames = {
	'Invoice Spend on Business Network': 'Invoice Spend',
	'Invoice Count via Business Network': 'Invoice Count',
	'PO Spend on Business Network': 'PO Spend',
	'PO Count via Business Network': 'PO Count',
	'Suppliers in Business Network': 'Suppliers'
}

export const kpiChartTableLableIcon = {
	"Invoice Spend KPI Estimate": "Estimated of the cumulative invoice spend to expect from the enablement strategy.  AVATAR estimates the invoice spend and when it will actually transact based on the Enablement Match & Onboarding Workstream by estimating what will be enabled and when the first transaction will occur.",
	"Actual Invoice Spend": "Cumulative actual invoice spend on the customers solution.",
	"Invoice Spend Target KPI": "The Target KPI is the cumulative invoice spend used to  generate the enablement strategy.  In the KPI Strategy Model this will be lower than the Planned because it is used to generate the enablement strategy.  In the Intelligent Strategy Model this will be the same as the Planned because it is calculated from the Intelligent Enablement Strategy.",
	"Invoice Spend Planned": "This value is the cumulative invoice spend targeted for enablement.  In the Intelligent Strategy Model the Planned and Target KPI values are the same because they are both calculated from with the Enablement Strategy Wizard.  In the KPI Strategy Model the Planned invoice spend is typically higher than the Target KPI because the KPI Target is used to create the KPI based Enablement Strategy.",
	"Invoice Count KPI Estimate": "This value is the cumulative value targeted for enablement.  In the Intelligent Strategy Model the Planned and Target KPI value are the same because they are both calculated from the Intelligent Enablement Strategy.  In the KPI Strategy Model the Planned value is typically be higher than the Target KPI because the KPI Target is used to create the Enablement Strategy.",
	"Actual Invoice Count": "Cumulative actual invoice count on the customers solution.",
	"Invoice Count Target KPI": "The Target KPI is the cumulative invoice count used to  generate the enablement strategy.  In the KPI Strategy Model this will be lower than the Planned because it is used to generate the enablement strategy.  In the Intelligent Strategy Model this will be the same as the Planned because it is calculated from the Intelligent Enablement Strategy.",
	"Invoice Count Planned": "This value is the cumulative invoice count targeted for enablement.  In the Intelligent Strategy Model the Planned and Target KPI values are the same because they are both calculated from with the Enablement Strategy Wizard.  In the KPI Strategy Model the Planned invoice count is typically higher than the Target KPI because the KPI Target is used to create the KPI based Enablement Strategy.",
	"PO Spend KPI Estimate": "Estimated of the cumulative PO spend to expect from the enablement strategy.  AVATAR estimates the PO spend and when it will actually transact based on the Enablement Match & Onboarding Workstream by estimating what will be enabled and when the first transaction will occur.",
	"Actual PO Spend": "Cumulative actual PO spend on the customers solution.",
	"PO Spend Target KPI": "The Target KPI is the cumulative PO spend used to  generate the enablement strategy.  In the KPI Strategy Model this will be lower than the Planned because it is used to generate the enablement strategy.  In the Intelligent Strategy Model this will be the same as the Planned because it is calculated from the Intelligent Enablement Strategy.",
	"PO Spend Planned": "This value is the cumulative PO spend targeted for enablement.  In the Intelligent Strategy Model the Planned and Target KPI values are the same because they are both calculated from with the Enablement Strategy Wizard.  In the KPI Strategy Model the Planned PO spend is typically higher than the Target KPI because the KPI Target is used to create the KPI based Enablement Strategy.",
	"PO Count KPI Estimate": "Estimated of the cumulative PO count to expect from the enablement strategy.  AVATAR estimates the PO count and when it will actually transact based on the Enablement Match & Onboarding Workstream by estimating what will be enabled and when the first transaction will occur.",
	"Actual PO Count": "Cumulative actual PO count on the customers solution.",
	"PO Count Target KPI": "The Target KPI is the cumulative PO count used to  generate the enablement strategy.  In the KPI Strategy Model this will be lower than the Planned because it is used to generate the enablement strategy.  In the Intelligent Strategy Model this will be the same as the Planned because it is calculated from the Intelligent Enablement Strategy.",
	"PO Count Planned": "This value is the cumulative PO count targeted for enablement.  In the Intelligent Strategy Model the Planned and Target KPI values are the same because they are both calculated from with the Enablement Strategy Wizard.  In the KPI Strategy Model the Planned PO count is typically higher than the Target KPI because the KPI Target is used to create the KPI based Enablement Strategy.",
	"Suppliers KPI Estimate": "Estimated of the cumulative suppliers to expect from the enablement strategy.  AVATAR estimates the number of suppliers and when it will actually transact based on the Enablement Match & Onboarding Workstream by estimating what will be enabled and when the first transaction will occur.",
	"Actual Suppliers": "Cumulative actual suppliers enabled on the customers solution.",
	"Suppliers Target KPI": "The Target KPI is the cumulative suppliers used to  generate the enablement strategy.  This is typically set to zero.  In the KPI Strategy Model this will be lower than the Planned because it is used to generate the enablement strategy.  In the Intelligent Strategy Model this will be the same as the Planned because it is calculated from the Intelligent Enablement Strategy.",
	"Suppliers Planned": "This value is the cumulative suppliers targeted for enablement.  In the Intelligent Strategy Model the Planned and Target KPI values are the same because they are both calculated from with the Enablement Strategy Wizard.  In the KPI Strategy Model the Planned PO count is typically higher than the Target KPI because the KPI Target is used to create the KPI based Enablement Strategy.",
}