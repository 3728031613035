import React from "react";
import ReactDOM from "react-dom";

export default class DivEditor extends React.Component {
    constructor(props) {
      super(props);
      this.state = { cellValue: props.value };
    }
  
    getValue() {
        let upd = {}
        upd[this.props.column.key] = 'DUMMY'
        return upd;
        /*return { fromRow: this.props.rowData, toRow: this.props.rowData, updated: upd }*/
    }
  
    getInputNode() {
      return ReactDOM.findDOMNode(this).getElementsByTagName("input")[0];
    }
  
    handleChangeComplete = event => {
      this.setState({ cellValue: event.target.value });
    };

    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.stopPropagation()
        }
      }

    render() {
      return (
        <div></div>
      );
    }
  }