import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import AllowedDomain from 'components/Admin/AllowedDomain'
import BlockedDomain from 'components/Admin/BlockedDomain'
// Components
import TabPanelGeneric from '../Tabs/TabPanelGeneric'

import {
	valueStoryCurrentMenu,
	showValueStoryMenu
} from 'actions/userActions'
import {
	pendingChanges
} from 'actions/spendActions'
import { UpdateComment } from 'actions/spendActions'
import { networkInterfaces } from 'os';

import {
	setValueStoryDmciDetails,
	setResultSummaryDetails,
	setTopDetails,
	resetError,
} from 'actions/spendActions'

import {
	getCurrentLanguage
} from 'utils/Utils'


class DomainManager extends Component {
	constructor(props) {
		super(props)

		this.state = {
			tabId: 1,
			nextButtonLabel: props.ui.next,
			language: '',
			tabs: [],
			pendingChanges: false
		}

		this.dispatchTabChange = this.dispatchTabChange.bind(this)
		this.handleNextButton = this.handleNextButton.bind(this)
		this.pendingChanges = this.pendingChanges.bind(this)
	}

	componentWillReceiveProps(nextProps) {
		//if (this.props.spendDetails.currentActiveRequest !== nextProps.spendDetails.currentActiveRequest) {
		this.intializeTabs();
		//}
		let language = getCurrentLanguage();
		if(language != this.state.language){			
			this.dispatchTabChange(this.state.tabId)
			//this.setState({language: language})
		}
	}

	componentWillUnmount(){
		this.props.resetError();
	}

	intializeTabs() {
		const tabs = [
			{
				id: 1,
				label: this.props.domainLang.allowed,
				component: (<AllowedDomain pendingChanges={this.pendingChanges}/>)
			},
			{
				id: 2,
				label: this.props.domainLang.blocked,
				component: (<BlockedDomain  pendingChanges={this.pendingChanges}/>)
			}
		];

		
		this.setState({
			tabs: tabs,
			tabId: 1
		})
	}

	dispatchTabChange(tabId) {
		if(tabId === 1) {
			this.setState({
				tabId: tabId,
				nextButtonLabel: this.props.ui.next
		})}
	}

	componentWillMount() {
		window.scrollTo(0, 0)
		this.intializeTabs()
		//	this.getNextPage()
	}

	handleNextButton(tabId) {
		
	}

	pendingChanges(pendingChange) {
		this.setResultSummaryDetails({pendingChanges: pendingChange})
	}

	render() {
		return (
			<div className="grid-x">
				<div className="medium-12">
					<div className="grid-x">
						<div className="medium-12">
							<TabPanelGeneric tabs={this.state.tabs}
								tabId={1}
								dispatchTabChange={this.dispatchTabChange}
								nextButtonLabel={this.state.nextButtonLabel}
								handleNextButton={this.handleNextButton}
								pendingChanges={this.state.pendingChanges}
							/>
						</div>
					</div>
				</div >
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		ui: state.content.ui,
		content: state.content,
		spendDetails: state.spendDetails,
		pendingChanges: state.detailedFileChanges.changes,
		userData: state.valueStory,
		newValueStory: state.isNewValueStory,
		domainLang : state.content.menu.DomainManager[0]
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		valueStoryCurrentMenu: (menuItem) => dispatch(valueStoryCurrentMenu(menuItem)),
		showValueStoryMenu: (menuItem) => dispatch(showValueStoryMenu(menuItem)),
		UpdateComment: requestDetails => dispatch(UpdateComment(requestDetails)),
		setValueStoryDmciDetails: (data) => dispatch(setValueStoryDmciDetails(data)),
		setResultSummaryDetails: (data) => dispatch(setResultSummaryDetails(data)),
		setTopDetails: (data) => dispatch(setTopDetails(data)),
		resetError: error => dispatch(resetError(error))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DomainManager)