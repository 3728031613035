import React, { Component } from 'react'
import { connect } from 'react-redux'
import { path } from 'Constants'
import {
    fetchSystemReportingDetails
}
    from 'actions/userActions'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

class SystemReporting extends Component {
    constructor(props) {
        super(props)
        this.state = {
            systemReportingData: [{ ExcelFileName: 'ValueStory Details', UIName: 'Value Story Details', Description: 'Get details of value stories created by Avatar users' },
            { ExcelFileName: 'Download Report Details', UIName: 'Custom Report Downloads', Description: 'Get details of custom PPT reports downloaded by Avatar users' },
            { ExcelFileName: 'Upload Download Report Details', UIName: 'Upload Download Report Details', Description: 'Get details of upload and download by Avatar users' }
        ],
            responseMsg: "",
            isLoading: false,
            isError: false,
            startDate: null,
            endDate: null,
        }
        this.downloadFile = this.downloadFile.bind(this)
    }

    handleDownload(data) {
        this.setState({ isLoading: true, isError: false, responseMsg: '' })
        this.props.fetchSystemReportingDetails(data.ExcelFileName,this.state.startDate,this.state.endDate)
            .then((response) => {
                if (response.status === 200) {
                    this.downloadFile(response)
                    this.setState({ isLoading: false, isError: false, responseMsg: 'Downloaded successfully' })
                }
                else
                    this.setState({ isLoading: false, isError: true, responseMsg: 'An error has occured' })
            }).catch((error) => {
                this.setState({ isLoading: false, isError: true, responseMsg: 'An error has occured' })
            })
    }

    downloadFile(response) {
        const byteArrays = [];
        const byteCharacters = atob(response.data);

        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
            const slice = byteCharacters.slice(offset, offset + 512);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        var blob = new Blob(byteArrays);

        var [, filename] = response.headers['content-disposition'].split('filename=');
        filename = filename.replace(/ /g, "_").replace(/"/g, "")

        if (navigator.msSaveBlob) {
            navigator.msSaveBlob(blob, filename);
        }
        else {
            var link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.setAttribute('visibility', 'hidden');
            link.download = filename
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    setStartDate(date) {        
        this.setState({ startDate: date})
    }

    setEndDate(date) {        
        this.setState({ endDate: date })
    }

    render() {
        return (
            <div>
                <div className="grid-x">
                    <div className="popup-content">
                        <div className="medium-12 title">{'System Reporting'}</div>
                        <div className="medium-12 pt10">{'You can extract Avatar usage details by clicking on Download link'}</div>
                    </div>
                </div>
                <br></br>
                <div className="grid-x">
                    <div className="datePicker float-left width170 ">
                        <div className="dropDownHeader">Start Date</div>
                        <DatePicker selected={this.state.startDate} onChange={(date) => this.setStartDate(date)} />
                    </div>
                    <div className="datePicker float-left width170 ">
                        <div className="dropDownHeader">End Date</div>
                        <DatePicker selected={this.state.endDate} onChange={(date) => this.setEndDate(date)} />
                    </div>
                </div>
                <div className="grid-x">
                    <div className="medium-12">
                        {(this.state.isLoading) ?
                            <img style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={path + `images/ajax-loader.gif`} alt="" />
                            : null
                        }
                        {(this.state.isError) ?
                            <div sstyle={{ display: 'flex', justifyContent: 'center' }} className="errors">{this.state.responseMsg}</div> :
                            <div style={{ display: 'flex', justifyContent: 'center' }} className="success">{this.state.responseMsg}</div>
                        }
                    </div>
                    <div className="medium-12">
                        <SystemReportingTable
                            onDownload={this.handleDownload.bind(this)}
                            data={this.state.systemReportingData}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

class SystemReportingTable extends React.Component {
    render() {
        var self = this;
        var data = self.props.data.map(function (data) {
            return (<SystemReportingRow
                data={data}
                key={data.UIName}
                onDownloadEvent={self.props.onDownload.bind(this)}
            />
            )
        });

        return (
            <div className="basic-table">
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Description</th>
                            <th>Action</th>
                        </tr>
                    </thead>

                    <tbody>
                        {data}
                    </tbody>
                </table>
            </div>
        );
    }
}

class SystemReportingRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    onDownloadEvent() {
        this.props.onDownloadEvent(this.props.data);
    }

    render() {
        return (
            <tr className="eachRow">
                <td>{this.props.data.UIName}</td>
                <td>{this.props.data.Description}</td>
                <td>
                    <button onClick={this.onDownloadEvent.bind(this)} >Download</button>
                </td>
            </tr>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        language: state.language,
        ui: state.content.ui,
        user: state.default,
        content: state.content.reports,
        validation: state.content.validation,
        reports: state.reports
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchSystemReportingDetails: (ExcelFileName,startDate,endDate) => dispatch(fetchSystemReportingDetails(ExcelFileName,startDate,endDate))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SystemReporting)
