import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Control, Form, Errors, actions } from 'react-redux-form'
import moment from 'moment'
// Actions
import {
	getSpendParameters,
	updateSpendParameters,
	resetError
} from 'actions/spendActions'


// Utils
import { BreakLine } from 'utils/Utils'
// UI
import Textfield from 'react-mdl/lib/Textfield'
import Button from 'UI/Button'
// Settings
import { validators } from 'Constants'

class SpendParametersContainer extends Component {
	constructor(props) {
		super(props)
		this.state = {
			userIsEditing: false,
			spendParameters: [],
			editId: 0,
			filterText: "",
			filterRole: "",
			users: []

		}

		this.handleRowEdit = this.handleRowEdit.bind(this)
		this.handleRowCancel = this.handleRowCancel.bind(this)
		this.handleUpdateParameter = this.handleUpdateParameter.bind(this)
		this.loadData = this.loadData.bind(this)
		this.filterTextInput = React.createRef();
	}

	componentDidMount() {

		this.loadData();
	}

	loadData() {
		this.getAllParameters();
	}
	getAllParameters() {
		this.props.getSpendParameters().then((response) => {
			this.setState({ spendParameters: response.data.Result });
		})
	}



	handleRowEdit(userDetails, userIsEditing) {
		// userDetails.isEditing = true;
		// this.setState({ editId: userDetails.Id, userIsEditing: true });
		// this.setState({
		// selectedRolesIDForUsers: userDetails.objRoles.map((rol) => {
		// return rol.RoleId
		// })
		// })
		// this.formDispatch(actions.change('userManager.Email', userDetails.Email));
		// this.formDispatch(actions.load('userManager.Email', userDetails.Email));
		// this.formDispatch(actions.setErrors('userManager.Email', false))
		// this.formDispatch(actions.setTouched('userManager.Email', true))
	}

	handleRowCancel(userIsEditing) {
		this.setState({ editId: 0 });
		this.formDispatch(actions.change('userManager.Email', ''));
		this.setState({ selectedRolesIDForUsers: [] })
		this.setState({ filterText: '' });
	};



	handleUpdateParameter(formValues) {
		formValues.UPDATED_BY = this.props.user.user.Email;
		this.props.updateSpendParameters(formValues).then((result) => {
			this.loadData();
		})
	}

	componentDidUpdate() {
		//this.formDispatch(actions.change('userManager.Role', this.state.selectedOption));
	}

	componentWillUnmount() {
		//this.formDispatch(actions.reset('userManager'))
		this.props.resetError()
	}

	updateUserData(userDetails, response) {
		var oldStateObject = this.state.users;
		userDetails = oldStateObject.filter(a => a.Email === userDetails.Email)
		var index = oldStateObject.indexOf(userDetails[0]);
		oldStateObject.splice(index, 1);
		oldStateObject.push(response.data.Result);
		oldStateObject.sort((itemA, itemB) => (itemA.Email.toLowerCase() > itemB.Email.toLowerCase()) ? 1 : (itemA.Email.toLowerCase() === itemB.Email.toLowerCase()) ? ((itemA.Email.toLowerCase() > itemB.Email.toLowerCase()) ? 1 : -1) : -1)
		this.setState({ users: oldStateObject });
	}

	render() {
		var _instance = this
		var user = this.state.spendParameters.map(function (parameter) {
			return (<SpendParameters
				spendParameter={parameter}
				onEditEvent={_instance.handleRowEdit}
				onCancelEvent={_instance.handleRowCancel}
				onUpdateEvent={_instance.handleUpdateParameter}

				content={_instance.props.content}
				key={parameter.ID}
			/>
			);
		});

		return (
			<div className="grid-container pt40">
				<div>
					<div className="grid-x">
						<div className="cell"><h2>{BreakLine(this.props.content.title)}</h2>
							<div>
								<div className="errors">{this.props.userManager.error}</div>
								<div className="success">{this.props.userManager.message}</div>
								<Form
									model="spendParameter"
									method="post"
									getDispatch={(dispatch) => this.formDispatch = dispatch}
								>
									<div className="grid-x grid-padding-x basic-table">

										{this.state.spendParameters !== undefined ?
											// <div className="">
												<table>
													<thead>
														<tr className="eachRow">
															<th width="20%">{this.props.content.thParameterName}</th>
															<th width="20%">{this.props.content.thParameterDesc}</th>
															<th width="20%">{this.props.content.thParameterValue}</th>
															<th width="10%">{this.props.content.thUpatedBy}</th>
															<th width="10%">{this.props.content.thUpdatedOn}</th>
															<th width="5%"></th>
															<th width="5%"></th>
														</tr>
													</thead>

													<tbody>
														{user}
													</tbody>
												</table>
											// </div>
											: null}

									</div>
								</Form>

							</div>
						</div>
					</div>
				</div>


			</div>
		)
	}
}


class SpendParameters extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			parameter: this.props.spendParameter,
			originalParameter: JSON.stringify(this.props.spendParameter)

		};
		//this.onChange = this.onChange.bind(this)
		this.onChangeCancel = this.onChangeCancel.bind(this)
		this.onCancelEvent = this.onCancelEvent.bind(this)
	}


	onChangeCancel(e) {

		this.setState({
			parameter: JSON.parse(this.state.originalParameter)
		})

	}



	onEditEvent(test) {

		var editingRow = this.state.parameter;
		editingRow.isEditing = !editingRow.isEditing;
		//this.props.user.isEditing = true;
		this.setState({ parameter: editingRow });
		//this.props.onEditEvent(this.props.user, this.props.userIsEditing);
	}

	onUpdateEvent(val) {
		this.onEditEvent();
		var editingRow = this.state.parameter;

		this.props.onUpdateEvent(editingRow);
	}

	onCancelEvent() {
		this.props.user.isEditing = false;
	}
	onChangeEvent(val) {
		var editingRow = this.state.parameter;
		editingRow[val.target.id] = val.target.value
		this.setState({ parameter: editingRow });
	}

	render() {
		var bindValues = this.state.parameter;
		return (
			!bindValues.isEditing ?
				<tr className="eachRow">
					<td width="20%">{bindValues.PARAMETER_NAME}</td>
					<td width="20%">{bindValues.PARAMETER_DESC}</td>
					<td width="20%">{bindValues.PARAMETER_VALUE}</td>
					<td width="10%">{bindValues.UPDATED_BY}</td>
					<td width="10%">{moment(bindValues.CREATE_TIMESTAMP).format('L')}</td>
					<td width="5%">
						<button value={bindValues.ID} onClick={test => this.onEditEvent(test)}> {this.props.content.btnEdit} </button>
					</td>
					<td width="5%">

					</td>
				</tr > :
				<tr className="eachRow">
					<td width="20%">{bindValues.PARAMETER_NAME}</td>
					<td width="20%">
						<Control.text
							model="spendParameter.ParameterDescription"
							onChange={this.onChangeEvent.bind(this)}
							id={'PARAMETER_DESC'}
							//value={bindValues.PARAMETER_DESC}
							defaultValue={bindValues.PARAMETER_DESC}
							validateOn="blur"
							type='name'
						/>
					</td>
					<td width="20%">
						<Control.text
							model="spendParameter.ParameterValue"
							onChange={this.onChangeEvent.bind(this)}
							id={'PARAMETER_VALUE'}
							defaultValue={bindValues.PARAMETER_VALUE}
							validateOn="blur"
							validators={{
								required: validators.required
							}}
							type='name'
						/>
					</td>
					<td width="10%" model="spendParameter.UpdatedBy">{bindValues.UPDATED_BY}</td>
					<td width="10%" model="spendParameter.UpdatedOn">{moment(bindValues.CREATE_TIMESTAMP).format('L')}</td>
					<td width="5%">
						<button type="submit"
							model="spendParameter.ID" onClick={(form) => this.onUpdateEvent(form)}
						>{this.props.content.btnSave}</button>

					</td>
					<td width="5%">
						<button model="spendParameter.ID" onClick={this.onChangeCancel.bind(this)}> {this.props.content.btnCancel} </button>
					</td>
				</tr>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		language: state.language,
		ui: state.content.ui,
		user: state.default,
		content: state.content.SpendParametersManager,
		validation: state.content.validation,
		userManager: state.userManagerUpdates
	}
}


const mapDispatchToProps = (dispatch) => {
	return {
		// userManagerSuccess: (bool) => dispatch(userManagerSuccess(bool)),
		// userManagerFailure: (error) => dispatch(userManagerFailure(error)),
		getSpendParameters: (formValues) => dispatch(getSpendParameters(formValues)),
		updateSpendParameters: (formValues) => dispatch(updateSpendParameters(formValues)),
		resetError: () => dispatch(resetError())
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SpendParametersContainer)