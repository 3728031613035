import React, { Component } from 'react'

import { GICSColumns } from 'Constants'
import CustomGrid from 'UI/CustomGrid'
import { connect } from 'react-redux'
import { Filters, Editors } from "react-data-grid-addons";

import NotesEditor from 'UI/NotesEditor'

import {
    getAllIndustriesGicsLoading,
    getAllIndustriesGicsSuccess,
    getAllIndustriesGicsFailure,
    getAllGICS,
    getUpdateGicsLoading,
    getUpdateGicsSuccess,
    getUpdateGicsFailure,
    updateGICS
} from 'actions/spendActions'
import { AlignFormatter, RoundFormatter, CenterAlignFormatter, getNonEmptyValue, formatter } from "../../utils/Utils";

const {
    SingleSelectFilter,
    NumericFilter,
    AutoCompleteFilter,
} = Filters;

const { DropDownEditor } = Editors;
const dropdownValues = ["Yes", "No"];
const buyProcessValues = ["Requisition: Catalog, Spot-Buy or Release Contract",
                            "Requisition: Non-Catalog or Tactical Sourcing",
                            "Requisition: External Talent",
                            "Consultancy/SOW Services: Active Mgmt, otherwise Invoice Against Contract",
                            "Other Services: Active Mgmt, otherwise Invoice Against Contract",
                            "Invoice Against Contract",
                            "Direct/MRO: Plan Driven PO",
                            "Direct/MRO: Asset Plan via Catalog",
                            "Non-PO Invoice",
                            "Travel and Expense"]
const requisitionTypeValues = ["Catalog", "Adhoc (non-catalog)", "N/A"]
const goodsOrServicesValues = ["Goods", "Services", "Both", "Neither", "Unclassified"]
const buyProcessDeliveryValues = ["Catalog/ Spot Buy - Amazon for Business Integrated Search"
                            ,"Collaborative Requisitioning"
                            ,"External Talent"
                            ,"Invoice Against Contract"
                            ,"Blanket Purchase Order"
                            ,"Non Catalog Requisition"
                            ,"Non PO Based Invoice"
                            ,"Release Contract"
                            ,"Service PO"
                            ,"Tactical Sourcing"]

const dropDownEditor = <DropDownEditor options={dropdownValues} />
const buyProcessEditor = <DropDownEditor options={buyProcessValues} />
const requisitionTypeEditor = <DropDownEditor options={requisitionTypeValues} />
const goodsOrServicesEditor = <DropDownEditor options={goodsOrServicesValues} />
const buyProcessDeliveryEditor = <DropDownEditor options={buyProcessDeliveryValues} />


class GICSAdminController extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {},
            columns: []
        }

        this.loadData = this.loadData.bind(this)
        this.save = this.save.bind(this)
        this.validate = this.validate.bind(this)
    }

    componentDidMount() {
        this.loadData()
    }

    loadData() {
        let columns = this.initializeColumns()
        this.props.getAllGICS()
            .then((response) => {
                this.setState({ columns: columns, data: response.data.Result })
            }).catch((error) => {
                if (error.stack.includes('Network Error')) {
                    this.setState({ responseMsg: 'API service is down. Please contact administrator', isError: 'Yes' })
                }
                else {
                    this.setState({ responseMsg: 'An error has occurred', isError: 'Yes' })
                }
            })
    }

    initializeColumns() {
        let columns = []
        for (let index = 0; index < GICSColumns.length; index++) {
            const fieldConfig = GICSColumns[index];
            
            let tableColumn = {
                name: fieldConfig.screen_field_name,
                key: fieldConfig.field,
                width: 120,
                sortable: true,
                editable: fieldConfig.is_editable === 'Yes', 
                resizable: true, 
                filterable: true,
                screenSeq: fieldConfig.screen_seq,
                mandatory: fieldConfig.mandatory === 'Yes',
                exportFl: fieldConfig.export_fl === 'Y',
                //editProperty: fieldConfig.is_editable,
                formatter: formatter(fieldConfig.alignment),
                helpText: fieldConfig.screen_help_text
            }

            if(fieldConfig.filter_type !== 'default') {
                tableColumn['filterRenderer'] = fieldConfig.data_type === 'text' ? AutoCompleteFilter :  NumericFilter
            }

            switch (tableColumn.key) {
                case "SOURCING_ELIGIBLE":
                case "BUYING_INVOICE_ELIGIBLE":
                case "REQUISTION_ABLE":
                case "CONTRACT_INVOICE_ELIGIBLE":
                case "COMMERCE_AUTOMATION_ELIGIBLE":
                case "SUPP_CHAIN_COLLAB_ELIGIBLE":
                case "DISCOUNT_SCF_ELIGIBLE":
                // case "TERMS_RATIONALIZATION":
                // case "DISCOUNT_ELIGIBLE":
                case "SAP_FIELDGLASS_ELIGIBLE":
                case "SAP_CONCUR_ELIGIBLE":
                case "SPOT_BUY_ELIGIBLE": tableColumn["editor"] = dropDownEditor
                    break;
                case "REQUISITION_TYPE": tableColumn["editor"] = requisitionTypeEditor
                    break;
                case "BUY_PROCESS": tableColumn["editor"] = buyProcessEditor
                    break;                
                case "PRIMARY_BUY_PROCESS_DELIVERED": tableColumn["editor"] = buyProcessDeliveryEditor
                    break;
                case "SECONDARY_BUY_PROCESS_DELIVERED": tableColumn["editor"] = buyProcessDeliveryEditor
                    break;                    
                case "OTHER_BUY_PROCESS_DELIVERED": tableColumn["editor"] = buyProcessDeliveryEditor
                break;
                case "GOODS_OR_SERVICE": tableColumn["editor"] = goodsOrServicesEditor
                    break;                
                default:
                    break;
            }

            columns.push(tableColumn)
        }

        return columns;
    }

    save(records) {
        const recordsToUpdate = []
        for(let idx = 0; idx < records.length; idx++)
        {
            const row = records[idx]
            
            const record = {
                P_CAPIQ_INDUSTRY_ID: row["GICS_ID"],
                P_SUB_TYPE_ID: row["SUB_INDUSTRY_ID"],
                P_SOL_GS: row["GOODS_OR_SERVICE"],
                P_SOL_SOURC: row["SOURCING_ELIGIBLE"],
                P_SOL_BUYINV: row["BUYING_INVOICE_ELIGIBLE"],
                P_SOL_REQBLE: row["REQUISTION_ABLE"],
                P_SOL_REQ_TYPE: row["REQUISITION_TYPE"],
                P_SOL_CONT_INV: row["CONTRACT_INVOICE_ELIGIBLE"],
                P_SOL_CAE: row["COMMERCE_AUTOMATION_ELIGIBLE"],
                P_SOL_SCC: row["SUPP_CHAIN_COLLAB_ELIGIBLE"],
                P_SOL_DIS_SCF: row["DISCOUNT_SCF_ELIGIBLE"],
                P_WCM_SUMMARY_INSCOPE: row["WCM_SUMMARY_INSCOPE"],
                P_WCM_SUMMARY_OUTSCOPE: row["WCM_SUMMARY_OUTSCOPE"],
                // P_TERMS_RATIONALIZATION: row["TERMS_RATIONALIZATION"],
                // P_DISCOUNT_ELIGIBLE: row["DISCOUNT_ELIGIBLE"],
                P_SOL_FG: row["SAP_FIELDGLASS_ELIGIBLE"],
                P_SOL_CONCUR: row["SAP_CONCUR_ELIGIBLE"],
                P_SPOT_BUY_ELIGIBLE: row["SPOT_BUY_ELIGIBLE"],
                P_SOL_BUY_PROC: row["BUY_PROCESS"],
                P_SOL_SCOPE_NOTES: row["SCOPE_NOTES"],
                L1_IND_DESC_DETLS: row["L1_INDUSTRY_DESC_DETAIL"],
                L4_IND_DESC_DETLS: row["L4_INDUSTRY_DESC_DETAIL"],
                EBIT_MARGIN: row["OPERATING_MARGIN_EBIT"] === "" ? undefined : row["OPERATING_MARGIN_EBIT"],
                DAYS_PAY_OUT: row["DAYS_PAYABLE_OUTSTANDING"],
                DAYS_SALS_OUT: row["DAYS_SALES_OUTSTANDING"],
                DAYS_INVT_OUT: row["DAYS_INVENTORY_OUTSTANDING"],
                CONV_CASH_CYC: row["CONVERSION_CASH_CYCLE"],
                INVT_TURN: row["INVENTORY_TURNS"] === "" ? undefined : row["INVENTORY_TURNS"],
                P_PRIMARY_BUY_PROCESS_DELIVERED: row["PRIMARY_BUY_PROCESS_DELIVERED"],
                P_SECONDARY_BUY_PROCESS_DELIVERED: row["SECONDARY_BUY_PROCESS_DELIVERED"],
                P_OTHER_BUY_PROCESS_DELIVERED: row["OTHER_BUY_PROCESS_DELIVERED"],
            };

            recordsToUpdate.push(record);
        }

        return this.props.updateGICS(recordsToUpdate)
            
    }

    validateRow(records) {
        var errorRecords = []
        const columns = this.state.columns 
        
        for(let idx = 0; idx < records.length; idx++)
        {
            const row = records[idx]
            for(let idx=0; idx < columns.length; idx++) {
                const column = columns[idx]
                if(getNonEmptyValue(row[column.key]) === '' && column.mandatory) {
                    errorRecords.push(row)
                    break;
                }
            }
            
        }

        return errorRecords;
    }

    validate(record, warningErrorRecords) {
        const columns = this.state.columns 
        
        for(let idx=0; idx < columns.length; idx++) {
            const column = columns[idx]
            if(getNonEmptyValue(record[column.key]) === '' && column.mandatory) {
                this.addWarningErroredRecord('Mandatory Field ' + column.name + ' missing', record, warningErrorRecords.errorRecords, true)
            } else {
                this.addWarningErroredRecord('Mandatory Field ' + column.name + ' missing', record, warningErrorRecords.errorRecords, false)
            }
        }

        return warningErrorRecords
    }

    addWarningErroredRecord(reason, record, records, add) {
        var arr = records[reason];
        if (getNonEmptyValue(arr) === '') {
            arr = []
        }

        var eltIdx = arr.indexOf((record['GICS_ID']));
        if (eltIdx === -1 && add) {
            arr.push(record['GICS_ID'])
        } else if (eltIdx !== -1 && !add) {
            arr.splice(eltIdx, 1)
        }

        records[reason] = arr;
    }

    render() {
        return (
            <div className="grid-x">
                <CustomGrid 
                    columns={this.state.columns} 
                    topMargin={true}
                    data={this.state.data} 
                    keyColumn="GICS_ID" 
                    save={this.save}
                    validate={this.validate}
                    export={true}
                    exportFileName="GICS Extract"
                
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        content: state.content,
        ui: state.content.ui,
        gicsDetail: state.gicsDetails
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAllIndustriesGicsLoading: message => dispatch(getAllIndustriesGicsLoading(message)),
        getAllIndustriesGicsFailure: error => dispatch(getAllIndustriesGicsFailure(error)),
        getAllIndustriesGicsSuccess: bool => dispatch(getAllIndustriesGicsSuccess(bool)),
        getAllGICS: () => dispatch(getAllGICS()),
        getUpdateGicsLoading: message => dispatch(getUpdateGicsLoading(message)),
        getUpdateGicsFailure: error => dispatch(getUpdateGicsFailure(error)),
        getUpdateGicsSuccess: bool => dispatch(getUpdateGicsSuccess(bool)),
        updateGICS: (records) => dispatch(updateGICS(records)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GICSAdminController); 