import React from 'react'
import PropTypes from 'prop-types'

// Utils
import { round, formatThousands } from 'utils/Utils'

const RowHeader = (props) => {
	if (props.index > 0) {
		return <tr className="row-header"><td colSpan="6">{props.content}</td></tr>
	} else {
		return (
			<tr className="row-header">
				<td>{props.content}</td>
				<td>Year 1</td>
				<td>Year 2</td>
				<td>Year 3</td>
				<td>Year 4</td>
				<td>Year 5</td>
			</tr>
		)
	}
}


const SubLeverRow = (props) => {
	const columns = [],
		count = 5

	for (let i = 0; i < count; i++) {
		let calculatedBenefit = 0,
			subLever = props.userData[props.valueDriverIndex].SubLever[props.subLeverIndex]

		// If Sub Lever is selected = Sub Lever Est. Annual Value multiplied by user input Benefit Realization Factor percentage
		if (subLever.Selected) {
			calculatedBenefit = subLever.EstAnnualValue * props.benefitRealizationFactors[i] / 100
			calculatedBenefit = round(calculatedBenefit, 2)
		}

		columns.push(<td key={i}>{props.currency} {formatThousands(Number(calculatedBenefit))} M</td>)
	}

	return (
		<tr>
			<td>{props.content}</td>
			{columns}
		</tr>
	)
}

const Row = (props) => {
	const RowElement = props.index ?
		<SubLeverRow
			content={props.content}
			valueDriverIndex={props.valueDriverIndex}
			subLeverIndex={props.index - 1}
			benefitRealizationFactors={props.benefitRealizationFactors}
			userData={props.userData}
			currency={props.currency} />
		: <RowHeader index={props.parentIdx} content={props.content} />

	return RowElement
}

const BusinessValueFromSAPAribaTableRows = (props) => {
	const getRows = () => {
		let arr = []

		props.valueDriversContent.forEach((data, i) => {
			let subArr = []

			subArr.push(data.valueDriver)

			props.subLeversContent[i].forEach((content, i) => {
				subArr.push(content)
			})

			arr.push(subArr)
		})

		return arr
	}

	const RenderRows = () => {
		const rows = getRows(),
			count = 5
		let totalAnnualBenefits = []

		for (let i = 0; i < count; i++) {
			let val = round(props.totalAnnualBenefits[i], 2)
			totalAnnualBenefits.push(<td key={i}>{props.currency} {val ? formatThousands(Number(val)) : 0} M</td>)
		}

		return (
			<div className="grid-x pt20">
				<table>
					<tbody>
						{rows.map((data, i) =>
							data.map((content, j) =>
								<Row
									key={content}
									valueDriverIndex={i}
									index={j}
									parentIdx={i}
									content={content}
									benefitRealizationFactors={props.benefitRealizationFactors}
									userData={props.userData}
									currency={props.currency}
								/>
							)
						)}
						<tr className="benefits-row">
							<td>{props.content.totalAnnualBenefits}</td>
							{totalAnnualBenefits}
						</tr>
					</tbody>
				</table>
			</div>
		)
	}

	return <RenderRows />
}

export default BusinessValueFromSAPAribaTableRows

BusinessValueFromSAPAribaTableRows.propTypes = {
	userData: PropTypes.array.isRequired,
	currency: PropTypes.string.isRequired
}
