import React from 'react'
import { connect } from 'react-redux'
import { Control, Form, Errors, actions } from 'react-redux-form'
import Button from 'UI/Button'
import CustomDropdown from 'UI/CustomDropDown'
import { validators } from 'Constants'
import { SelectField, Option } from 'react-mdl-extra'
import {
    callApiget,
    translateENtoLanguage,
    getlanguageLists,
    movetoapplication,
    GetDataOfLanguage
} from 'actions/nodeActions'

import {
    updateTableData_AOI,
    UpdateTableData_UserInputs,
    UpdateTableData_Industries,
    UpdatePublishDBChange_AOIValueDrivers,
    UpdatePublishDBChange_AOIValueDrivers_subItem,
    UpdateTableData_valueDrivers,
    UpdateTableData_SubvalueDrivers,
    UpdateTableData_BVSROIs,
    UpdateTableData_Countries
} from "actions/LanguageTranslationsApiCall";

import {
    LanguageTranslationLoading
} from "actions/userActions";

import Select from 'react-select'
import Textfield from 'react-mdl/lib/Textfield'
import { translateScreen_DropdownStyle } from 'react-select-css'

class TranslateScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            SelectedLangData: '',
            allEnglishData: '',
            targetLanguagePublishedContent: '',
            register: '',
            currentData: '',
            publishedNestedValue: '',
            screenNames: [],
            language: [],
            selectedLanvalue: null,
            global: {},
            messages: '',
            isProcessing: false,
            languageOption: [],
            selectedLanguage: '',
            screenNamesOption: [],
            selectedScreenName: '',
            innerObject: ''
        }
        this.callApi = this.callApi.bind(this)
        this.handleScreenChange = this.handleScreenChange.bind(this)
        this.handleUpdateDraftClick = this.handleUpdateDraftClick.bind(this)
        this.handleClickPublishDraft = this.handleClickPublishDraft.bind(this)
        this.handleClickRsetContent = this.handleClickRsetContent.bind(this);
        this.translateENtoLanguage = this.translateENtoLanguage.bind(this)
        this.handlelangChange = this.handlelangChange.bind(this)
        this.getformItemData = this.getformItemData.bind(this)
        this.getLanguageListFromAPI = this.getLanguageListFromAPI.bind(this)
        this.getEnglishData('en', true);
        this.duplicateRecords = [];
        this.duplicateValueId = [];
    }



    componentWillMount() {
        this.callApi()
        this.getLanguageListFromAPI();

    }
    componentWillUnmount() {
        this.formDispatch(actions.reset('lanMgr'));
    }

    componentDidMount() {
        this.formDispatch(actions.setErrors('lanMgr.LanguageName', true));
        this.formDispatch(actions.change('lanMgr.LanguageName', ''));

    }

    getEnglishData(languageName, fromPublish) {
        this.props.getDataOfLanguage(languageName, fromPublish)
            .then((response) => {
                if (response !== undefined)
                    this.setState({ allEnglishData: response.data })
            });
    }

    // most updated file will be here so read language from here
    getLanguageListFromAPI() {
        var list = this.props.getLanguageListFromAPI().then((response) => {
            this.setState({ language: response })

            //bind language

            if (response !== undefined) {
                var array = [];
                this.state.language.forEach((element) => {
                    var obj = {};
                    obj.label = element.LanguageName;
                    obj.value = element.ShortLanguageName;
                    array.push(obj);
                });
                this.setState({ languageOption: array });
            }
        });
    }

    handleScreenChange(value) {
        this.duplicateRecords = [];
        var lable = value.label;
        value = value.value;
        if (this.state.SelectedLangData === '') {
            return false;
        }
        else {

            //This is for the Column 1 English value
            var data = this.state.allEnglishData;
            var nestedElement = data[value];
            if (nestedElement === undefined) {
                Object.values(data).map((type) => {

                    if (type.hasOwnProperty(value)) {
                        nestedElement = type[value]
                    }
                })
            }

            //This is for the Column 2 Selected Language Draft value
            var languageData = this.state.SelectedLangData;
            var selectedLanNestedElements = languageData[value];
            if (selectedLanNestedElements === undefined) {
                Object.values(languageData).map((type) => {

                    if (type.hasOwnProperty(value)) {
                        selectedLanNestedElements = type[value]
                    }
                })
            }

            //This is for the Column 3 Selected Language Published value
            var PublishedLanguageData = this.state.targetLanguagePublishedContent;
            var selectedLanPublishedNestedElements = PublishedLanguageData[value];
            if (selectedLanPublishedNestedElements === undefined) {
                Object.values(PublishedLanguageData).map((type) => {

                    if (type.hasOwnProperty(value)) {
                        selectedLanPublishedNestedElements = type[value]
                    }
                })
            }

            this.setState({ messages: '' })
            this.setState({ currentData: selectedLanNestedElements });
            this.setState({ innerObject: selectedLanNestedElements });

            this.setState({ register: nestedElement, publishedNestedValue: selectedLanPublishedNestedElements });
            this.setState({ selectedScreenName: lable });
        }

        this.formDispatch(actions.change('lanMgr.LanguageShortName', value));
        this.formDispatch(actions.setErrors('lanMgr.LanguageShortName', false))
    }

    UpdatePublishDBChange_AOI(LanguageShortName) {
        var PublishedLanguageData = this.state.targetLanguagePublishedContent;
        var languageData = this.state.SelectedLangData;

        // First for discovery for all langauges 
        var published_DiscoveryData = PublishedLanguageData["discovery"]
        var updated_DiscoveryData = languageData["discovery"]

        // for AOI changes 
        var interests_published_DiscoveryData = published_DiscoveryData["interests"]
        var interests_updated_DiscoveryData = updated_DiscoveryData["interests"]
        if (interests_published_DiscoveryData !== interests_updated_DiscoveryData) {
            var allDetail = { LanguageShortName: LanguageShortName, CurrentValueJson: interests_published_DiscoveryData, UpdatedValueJson: interests_updated_DiscoveryData }
            this.props.updateTableData_AOI(allDetail)
        }


    }

    UpdatePublishDBChange_UserInputs(LanguageShortName) {
        var PublishedLanguageData = this.state.targetLanguagePublishedContent;
        var languageData = this.state.SelectedLangData;

        // First for subLeverCalculations for all langauges 
        var published_DiscoveryData = PublishedLanguageData["subLeverCalculations"]
        var updated_DiscoveryData = languageData["subLeverCalculations"]

        var subLeverCalculation_published_DiscoveryData = published_DiscoveryData["subLeverCalculation"]
        var subLeverCalculation_updated_DiscoveryData = updated_DiscoveryData["subLeverCalculation"]
        var currentValue = [];
        var updatedValue = [];

        if (subLeverCalculation_published_DiscoveryData !== undefined && subLeverCalculation_updated_DiscoveryData !== undefined) {
            for (let index = 0; index < subLeverCalculation_published_DiscoveryData.length; index++) {
                var subleverRows_published = subLeverCalculation_published_DiscoveryData[index].rows
                var subleverRows_updated = subLeverCalculation_updated_DiscoveryData[index].rows;

                if (subLeverCalculation_updated_DiscoveryData[index]['estimatedBenefit'].toLowerCase() !== subLeverCalculation_published_DiscoveryData[index]['estimatedBenefit'].toLowerCase()
                    && currentValue.indexOf(subLeverCalculation_published_DiscoveryData[index]['estimatedBenefit']) < 0
                ) {
                    currentValue.push(subLeverCalculation_published_DiscoveryData[index]['estimatedBenefit']);
                    updatedValue.push(subLeverCalculation_updated_DiscoveryData[index]['estimatedBenefit'])
                }

                for (let index = 0; index < subleverRows_published.length; index++) {
                    var published_content = subleverRows_published[index][0].content
                    var updated_content = subleverRows_updated[index][0].content
                    if (published_content !== updated_content) {
                        currentValue.push(published_content);
                        updatedValue.push(updated_content)
                    }
                }
            }
            if (currentValue.length > 0 && updatedValue.length > 0) {
                var allDetail = { LanguageShortName: LanguageShortName, CurrentValueJson: currentValue, UpdatedValueJson: updatedValue }
                this.props.UpdateTableData_UserInputs(allDetail)
            }

        }

    }


    UpdatePublishDBChange_Industries(LanguageShortName) {
        var PublishedLanguageData = this.state.targetLanguagePublishedContent;
        var languageData = this.state.SelectedLangData;

        // First for discovery for all langauges 
        var published_DiscoveryData = PublishedLanguageData["discovery"]
        var updated_DiscoveryData = languageData["discovery"]

        // for Industries changes 
        var form_published_DiscoveryData = published_DiscoveryData["form"]
        var form_updated_DiscoveryData = updated_DiscoveryData["form"]

        var industries_form_published_DiscoveryData = form_published_DiscoveryData["industries"];
        var industries_form_updated_DiscoveryData = form_updated_DiscoveryData["industries"];
        var currentValue = [];
        var updatedValue = [];

        for (let index = 0; index < industries_form_published_DiscoveryData.length; index++) {
            var published_industries = industries_form_published_DiscoveryData[index];
            var updated_industries = industries_form_updated_DiscoveryData[index];
            if (published_industries !== updated_industries) {
                currentValue.push(published_industries);
                updatedValue.push(updated_industries);
            }
        }
        if (updatedValue.length > 0 && currentValue.length > 0) {
            var allDetail = { LanguageShortName: LanguageShortName, CurrentValueJson: currentValue, UpdatedValueJson: updatedValue }
            this.props.UpdateTableData_Industries(allDetail)
        }
    }

    // prioritySections
    UpdatePublishDBChange_AOIValueDrivers(LanguageShortName) {
        var PublishedLanguageData = this.state.targetLanguagePublishedContent;
        var languageData = this.state.SelectedLangData;

        var priorities_Published = PublishedLanguageData["priorities"]
        var updated_priorities = languageData["priorities"]

        var prioritySections_Published = priorities_Published["prioritySections"];
        var prioritySections_Updated = updated_priorities["prioritySections"];
        var que_Published = [];
        var que_updated = [];
        var subQue_Published = [];
        var subQue_updated = [];

        for (let index_priority = 0; index_priority < prioritySections_Published.length; index_priority++) {
            var questions_published = prioritySections_Published[index_priority].questions;
            var questions_updated = prioritySections_Updated[index_priority].questions;

            for (let index_que = 0; index_que < questions_published.length; index_que++) {
                //// question for both as item in table 
                if (que_Published.indexOf(questions_published[index_que].question) < 0) {
                    que_Published.push(questions_published[index_que].question);
                    que_updated.push(questions_updated[index_que].question);
                }

                var sub_questions_published = questions_published[index_que].subQuestions;
                var sub_questions_updated = questions_updated[index_que].subQuestions;

                for (let index = 0; index < sub_questions_published.length; index++) {
                    var sub_que_published = sub_questions_published[index].subQuestion;
                    var sub_que_updated = sub_questions_updated[index].subQuestion;

                    //if(sub_que_published !== sub_que_updated){
                    subQue_Published.push(sub_que_published);
                    subQue_updated.push(sub_que_updated);
                    //}
                }
            }
        }

        if (subQue_Published.length > 0 && subQue_updated.length > 0) {
            var itemAllDetail = { LanguageShortName: LanguageShortName, CurrentValueJson: que_Published, UpdatedValueJson: que_updated }
            var subItemAllDetail = { LanguageShortName: LanguageShortName, CurrentValueJson: subQue_Published, UpdatedValueJson: subQue_updated }
            this.props.UpdatePublishDBChange_AOIValueDrivers(itemAllDetail).
                then((response) => {
                    if (response !== undefined) {
                        this.props.UpdatePublishDBChange_AOIValueDrivers_subItem(subItemAllDetail)
                    }
                });
        }

    }

    UpdatePublishDBChange_valueDrivers(LanguageShortName) {
        //businessValue --> valueDrivers
        var PublishedLanguageData = this.state.targetLanguagePublishedContent;
        var languageData = this.state.SelectedLangData;

        // First for businessValue for EN only 
        var published_businessValue = PublishedLanguageData["businessValue"]
        var updated_businessValue = languageData["businessValue"]

        // for valueDrivers changes 
        var valueDrivers_published_businessValue = published_businessValue["valueDrivers"]
        var valueDrivers_updated_businessValue = updated_businessValue["valueDrivers"]


        var currentValue = [];
        var updatedValue = [];

        for (let index = 0; index < valueDrivers_published_businessValue.length; index++) {
            var published_valueDrivers = valueDrivers_published_businessValue[index].valueDriver;
            var updated_valueDrivers = valueDrivers_updated_businessValue[index].valueDriver;
            if (published_valueDrivers !== updated_valueDrivers) {
                currentValue.push(published_valueDrivers);
                updatedValue.push(updated_valueDrivers);
            }
        }
        if (updatedValue.length > 0 && currentValue.length > 0) {
            var allDetail = { LanguageShortName: LanguageShortName, CurrentValueJson: currentValue, UpdatedValueJson: updatedValue }
            this.props.UpdateTableData_valueDrivers(allDetail)
        }
    }

    UpdatePublishDBChange_SubvalueDrivers(LanguageShortName) {
        var PublishedLanguageData = this.state.targetLanguagePublishedContent;
        var languageData = this.state.SelectedLangData;

        // First for businessValue for EN only 
        var published_businessValue = PublishedLanguageData["businessValue"]
        var updated_businessValue = languageData["businessValue"]

        // for valueDrivers changes 9
        var subLevers_published_businessValue = published_businessValue["subLevers"]
        var subLevers_updated_businessValue = updated_businessValue["subLevers"]

        var currentValue = [];
        var updatedValue = [];

        for (let index = 0; index < subLevers_published_businessValue.length; index++) {

            var published_SubvalueDrivers = subLevers_published_businessValue[index];
            var updated_SubvalueDrivers = subLevers_updated_businessValue[index];

            for (let index_sub = 0; index_sub < published_SubvalueDrivers.length; index_sub++) {
                if (published_SubvalueDrivers[index_sub] !== updated_SubvalueDrivers[index_sub]) {
                    currentValue.push(published_SubvalueDrivers[index_sub]);
                    updatedValue.push(updated_SubvalueDrivers[index_sub]);
                }
            }
        }
        if (updatedValue.length > 0 && currentValue.length > 0) {
            var allDetail = { LanguageShortName: LanguageShortName, CurrentValueJson: currentValue, UpdatedValueJson: updatedValue }
            this.props.UpdateTableData_SubvalueDrivers(allDetail)
        }
    }

    UpdatePublishDBChange_BVSROIs(LanguageShortName) {
        var PublishedLanguageData = this.state.targetLanguagePublishedContent;
        var languageData = this.state.SelectedLangData;

        // First for businessValue for EN only 
        var published_calculateROI = PublishedLanguageData["calculateROI"]
        var updated_calculateROI = languageData["calculateROI"]


        var table_published_calculateROI = published_calculateROI["table"]
        var table_updated_calculateROI = updated_calculateROI["table"]

        var rowHeader_table_published_calculateROI = table_published_calculateROI["rowHeaders"]
        var rowHeader_table_updated_calculateROI = table_updated_calculateROI["rowHeaders"]

        var currentValue = [];
        var updatedValue = [];

        for (let index = 0; index < rowHeader_table_published_calculateROI.length; index++) {
            var published_BVSROIs = rowHeader_table_published_calculateROI[index];
            var updated_BVSROIs = rowHeader_table_updated_calculateROI[index];
            if (published_BVSROIs !== updated_BVSROIs) {
                currentValue.push(published_BVSROIs);
                updatedValue.push(updated_BVSROIs);
            }
        }

        var rowFooter_table_published_calculateROI = table_published_calculateROI["rowFooters"]
        var rowFooter_table_updated_calculateROI = table_updated_calculateROI["rowFooters"]
        for (let index = 0; index < rowFooter_table_updated_calculateROI.length; index++) {

            var published_BVSROIs = rowFooter_table_published_calculateROI[index];
            var updated_BVSROIs = rowFooter_table_updated_calculateROI[index];
            if (published_BVSROIs !== updated_BVSROIs) {
                currentValue.push(published_BVSROIs);
                updatedValue.push(updated_BVSROIs);
            }
        }

        if (updatedValue.length > 0 && currentValue.length > 0) {
            var allDetail = { LanguageShortName: LanguageShortName, CurrentValueJson: currentValue, UpdatedValueJson: updatedValue }
            this.props.UpdateTableData_BVSROIs(allDetail)
        }
    }

    UpdatePublishDBChange_Countries(LanguageShortName) {
        var PublishedLanguageData = this.state.targetLanguagePublishedContent;
        var languageData = this.state.SelectedLangData;

        // First for discovery for all langauges 
        var published_DiscoveryData = PublishedLanguageData["discovery"]
        var updated_DiscoveryData = languageData["discovery"]

        // for locations changes 
        var form_published_DiscoveryData = published_DiscoveryData["form"]
        var form_updated_DiscoveryData = updated_DiscoveryData["form"]

        var locations_form_published_DiscoveryData = form_published_DiscoveryData["locations"];
        var locations_form_updated_DiscoveryData = form_updated_DiscoveryData["locations"];
        var currentValue = [];
        var updatedValue = [];

        for (let index = 0; index < locations_form_published_DiscoveryData.length; index++) {
            var published_locations = locations_form_published_DiscoveryData[index];
            var updated_locations = locations_form_updated_DiscoveryData[index];
            if (published_locations !== updated_locations) {
                currentValue.push(published_locations);
                updatedValue.push(updated_locations);
            }
        }
        if (updatedValue.length > 0 && currentValue.length > 0) {
            var allDetail = { LanguageShortName: LanguageShortName, CurrentValueJson: currentValue, UpdatedValueJson: updatedValue }
            this.props.UpdateTableData_Countries(allDetail)
        }
    }

    handleUpdateDraftClick(event, isReset) {

        if (this.state.selectedLanvalue !== null && this.state.selectedScreenName !== null) {
            setTimeout(() => {
                this.props.LanguageTranslationLoading(true)
            }, 20);

            var item = {};
            this.state.global = {}
            this.setState(
                this.state
            )
            var _selectedScreenName = this.state.selectedScreenName
            var selectedScreen = this.state.screenNames.filter(function (item) {
                if (item.DisplayName === _selectedScreenName) {
                    return item.NameinJson;
                }
            });
            selectedScreen = selectedScreen[0].NameinJson;
            var registerData = this.state.register
            this.getformItemData(this, registerData, "");
            item = this.state.global;
            var sendDetails = {};
            var OperationName = '';
            if (typeof (isReset) === "object" || isReset === undefined) {
                OperationName = 'UpdateDraft'
            }
            else {
                OperationName = 'ResetToPublishDraft'
            }
            sendDetails = { scree: selectedScreen, "language": this.state.selectedLanvalue, data: item, userId: this.props.user.user.Id, OperationName: OperationName }
            this.translateENtoLanguage(sendDetails, OperationName);

        }
    }

    handleClickPublishDraft() {
        this.duplicateRecords = [];
        if (this.state.selectedLanvalue !== null) {
            this.props.LanguageTranslationLoading(true);
            var LanguageShortName = this.state.selectedLanvalue
            var value = this.state.language.filter(function (item) {
                if (item.ShortLanguageName == LanguageShortName) {

                    return item.LanguageName;
                }
            })
            this.props.handleClickMovetoApplication(value[0], this.props.user.user.Id).
                then((response) => {
                    if (response !== undefined) {
                        this.formDispatch(actions.reset('lanMgr'));
                        this.setState({ register: '' });

                        this.UpdatePublishDBChange_AOI(LanguageShortName);
                        this.UpdatePublishDBChange_UserInputs(LanguageShortName);
                        this.UpdatePublishDBChange_AOIValueDrivers(LanguageShortName);
                        this.UpdatePublishDBChange_Industries(LanguageShortName);
                        this.UpdatePublishDBChange_Countries(LanguageShortName);
                        if (LanguageShortName === 'en') {
                            this.UpdatePublishDBChange_valueDrivers(LanguageShortName);
                            this.UpdatePublishDBChange_SubvalueDrivers(LanguageShortName);
                            this.UpdatePublishDBChange_BVSROIs(LanguageShortName);
                        }

                        setTimeout(() => {
                            this.setState({ messages: (response.data + " To review changes do relogin.") })
                            this.props.LanguageTranslationLoading(false)
                            //this.setState({:'Published '})
                        }, 4000);

                        setTimeout(() => {
                            this.props.location.logout()
                        }, 10000)
                    }
                });

        }
        else {
            this.props.user.error = "Language name required!"
            // this.formDispatch(actions.setErrors('lanMgr.LanguageName', true))
        }
    }

    handleClickRsetContent(event) {
        this.duplicateRecords = [];
        if (this.state.selectedScreenName !== null && this.state.selectedScreenName !== '') {  //This is for the Column 2 Selected Language Published value 
            this.props.LanguageTranslationLoading(true)
            var _selectedScreenName = this.state.selectedScreenName
            var selectedScreen = this.state.screenNames.filter(function (item) {
                if (item.DisplayName === _selectedScreenName) {
                    return item.NameinJson;
                }
            });
            selectedScreen = selectedScreen[0].NameinJson;

            var PublishedLanguageData = this.state.targetLanguagePublishedContent;
            var selectedLanPublishedNestedElements = PublishedLanguageData[selectedScreen];
            if (selectedLanPublishedNestedElements === undefined) {
                Object.values(PublishedLanguageData).map((type) => {
                    if (type.hasOwnProperty(selectedScreen)) {
                        selectedLanPublishedNestedElements = type[selectedScreen]
                    }
                })
            }

            this.setState({ messages: '' })
            this.setState({ currentData: selectedLanPublishedNestedElements });
            this.setState({ innerObject: selectedLanPublishedNestedElements })
            setTimeout(() => {
                this.handleUpdateDraftClick(event, true);
                this.props.LanguageTranslationLoading(false)
                this.setState({ messages: 'Language content reset successfully!' })
            }, 2000);

        }
    }


    getformItemData(e, datakey, name) {
        Object.keys(datakey).map(function (childitem) {
            if ((typeof datakey[childitem]) === "string") {
                var id = '';
                id = name !== '' ? name + '|' + childitem : childitem;

                if (document.getElementById(id) !== null) {
                    var item = {};
                    var nodeId = id.split("|").join("");
                    item[nodeId] = document.getElementById(id).value

                    e.state.global[nodeId] = document.getElementById(id).value
                    e.setState(e.state)
                }
            }
            else {
                e.getformItemData(e, datakey[childitem], name !== '' ? name + '|' + childitem : childitem)
            }
        }.bind(this));
    }

    translateENtoLanguage(data, OperationName) {
        //console.log('translateENtoLanguage callled..............')
        this.props.translateENtoLanguage(data)
            .then((response) => {
                setTimeout(() => {
                    this.props.LanguageTranslationLoading(false)
                }, 2000);
                if (response !== undefined && OperationName === 'UpdateDraft') {
                    this.setState({ messages: 'Transalation has been update successfully for your selected language!' })
                    this.formDispatch(actions.reset('lanMgr'));
                    this.setState({ register: '' });
                    //if (data.language === "en") { this.getEnglishData("en",true); }

                    return response;
                }

            })
            .catch((err) => {
                //console.log(err)
            });
    }


    callApi() {
        this.props.callApi()
            .then((response) => {
                if (response !== undefined) {
                    var screen = []
                    for (var i = 0; i < response.length; i++) {

                        screen.push(response[i]);
                    }
                    this.setState({ screenNames: screen })

                    //bind screenNames
                    var array = [];
                    this.state.screenNames.forEach((element) => {
                        var obj = {};
                        obj.label = element.DisplayName;
                        obj.value = element.NameinJson;
                        array.push(obj);
                    });
                    this.setState({ screenNamesOption: array });
                }
            })
            .catch((err) => {
            });
    }

    handlelangChange(value) {
        // Will get Published data for column 3 
        this.props.getDataOfLanguage(value.value, true)
            .then((response) => {
                if (response !== undefined) {
                    this.formDispatch(actions.reset('lanMgr.LanguageName'));
                    this.setState({ targetLanguagePublishedContent: response.data })
                    this.setState({ selectedLanvalue: value.value, messages: '' });
                    this.setState({ selectedLanguage: value.label });

                    // Will get draft data for column 2 
                    this.props.getDataOfLanguage(value.value, false)
                        .then((response) => {
                            this.setState({ SelectedLangData: response.data });
                            //this.formDispatch(actions.reset('lanMgr.LanguageShortName'));
                            this.setState({ currentData: '' });
                            this.setState({ register: '' });
                            this.setState({ innerObject: '' })
                        });
                }
            });
        // // validation    
        // if (value.label == '') {
        //     this.formDispatch(actions.setErrors('lanMgr.LanguageName', true))
        //     this.formDispatch(actions.change('lanMgr.LanguageName', ''));
        // } else {
        //     this.formDispatch(actions.change('lanMgr.LanguageName', value.label));
        //     this.formDispatch(actions.setErrors('lanMgr.LanguageName', false))
        // }

    }

    onTextChange(event) {
        this.duplicateRecords = [];
        var duplicateValIds = this.duplicateValueId;
        var id = event.target.id;
        var textArea = document.getElementById(id);
        var stateElement = this.state.currentData;
        var registerElement = this.state.register;
        var getElementsPosition = id.split("|");
        var i = 1;
        var inst = this;
        getElementsPosition.forEach(element => {
            if (element !== '' && stateElement !== undefined) {

                if (getElementsPosition.length === i) {
                    stateElement[element] = textArea.value;
                    this.setState(currentData => ({
                        element: textArea.value
                    }));

                } else {
                    stateElement = stateElement[element];
                }
                i++;
            }
        });

        var _instance = this;
        //subLeverCalculation|0|rows|0|0|content
        if (this.state.selectedScreenName === 'SubLever Calculations Screen' &&
            id.indexOf("subLeverCalculation") > -1 && id.indexOf("rows") > -1
            && id.indexOf("content") > -1) {
            var id_eng = document.getElementById("lbl" + event.target.id).innerText;
            var engTxt = this.state.register['subLeverCalculation'];
            var oldValue = textArea.defaultValue;
            var _NewStateObj = this.state.currentData['subLeverCalculation'];
            Object.keys(_NewStateObj).map(function (sublever) {
                var rows = _NewStateObj[sublever]['rows']
                var rows_en = engTxt[sublever]['rows']
                Object.keys(rows).map(function (row) {
                    var _change = rows[row][0]['content'];
                    var _engtxt = rows_en[row][0]['content'];
                    if (id_eng.toLowerCase() === _engtxt.toLowerCase()) {
                        _instance.setState(currentData => ({
                            _change: textArea.value
                        }));

                        rows[row][0]['content'] = textArea.value;
                    }
                });
            });
        }

        if (this.state.selectedScreenName === 'SubLever Calculations Screen' &&
            id.indexOf("subLeverCalculation") > -1 && id.indexOf("estimatedBenefit") > -1) {
            var id_eng = document.getElementById("lbl" + event.target.id).innerText;
            var engTxt = this.state.register['subLeverCalculation'];

            var oldValue = textArea.defaultValue;
            var _NewStateObj = this.state.currentData['subLeverCalculation'];
            Object.keys(_NewStateObj).map(function (sublever) {
                var estimatedBenefit = _NewStateObj[sublever]['estimatedBenefit']
                var eng_estimatedB = engTxt[sublever]['estimatedBenefit']
                if (eng_estimatedB.toLowerCase() === id_eng.toLowerCase()) {
                    _NewStateObj[sublever]['estimatedBenefit'] = textArea.value;
                }
            });
        }

    }

    showPublishLangauge() {
        if (this.props.user.user !== null && this.props.user.user.applicationFeatures !== undefined && this.props.user.user.applicationFeatures !== null) {
            var resp = Object.values(this.props.user.user.applicationFeatures).map(feature => {
                if (this.props.content.LanguageManager[0].subItems[1][feature.Action] !== undefined) {
                    return true;
                }
            })
            if (resp.indexOf(true) > 0) {
                return true;
            }
        }

        return false;



    }

    render() {

        var changeevent = this.onTextChange.bind(this);// this.onTextChange()
        var devActions = ['reportAction', 'kpi', 'topValues', 'detailedFileFields', 'caseStudy', 'collapseChild', 'contentId', 'calculatedUOM', 'userInputUOM', 'active', 'isActiveReport', 'action', 'link', 'aribaLogo', 'sapLogo', 'id', 'ctaURL', 'languageSelector', 'roles', 'noInput', 'suffix', 'reportId', 'skuList', 'regions']
        var dynamicid = "";
        var registerData = this.state.register;
        var currentDraftData = this.state.currentData;
        var publishedData = this.state.publishedNestedValue;
        var draftData_innerObject = this.state.currentData;
        var instance = this;

        var getComponent = (item, name, id, currentDraftDataitem, publishedDataItem) => {
            dynamicid = name;
            switch (typeof (item)) {
                case 'object':
                    var i = 1;
                    var k = Object.keys(item).map(function (childitem) {
                        if (item[childitem] !== "" && (typeof item[childitem]) === "string" && (devActions.find(a => a == childitem)) === undefined) {
                            return (
                                (item[childitem] !== "" && (typeof item[childitem]) === "string" && (devActions.find(a => a == childitem)) === undefined) ?

                                    <tr>
                                        <td>
                                            <p id={"lbl" + name + '|' + childitem} className="spanwidth">{item[childitem]}</p>
                                        </td>
                                        <td>
                                            <textarea
                                                onChange={changeevent}
                                                className="textareastyle"
                                                id={name + '|' + childitem}
                                                value={currentDraftDataitem[childitem]}
                                            />
                                        </td>
                                        <td>
                                            <p className="spanwidth">{publishedDataItem[childitem]}</p>
                                        </td>
                                        <td>
                                            <p className="hidecolumn">
                                                {
                                                    (Object.keys(item).length === i)
                                                        ?
                                                        dynamicid = dynamicid.toString().replace(name, '')
                                                        :
                                                        i++
                                                }</p>
                                        </td>
                                    </tr > :
                                    ((typeof item[childitem]) === "object" && (devActions.find(a => a == childitem)) === undefined) ?

                                        getComponent(item[childitem], name + '|' + childitem, childitem, currentDraftDataitem[childitem], publishedDataItem[childitem])

                                        : null
                            ) // return ends
                        }
                        else {
                            if ((typeof item[childitem]) === "object" &&
                                (devActions.find(a => a == childitem)) === undefined) {
                                return getComponent(item[childitem], name + '|' + childitem, childitem, currentDraftDataitem[childitem], publishedDataItem[childitem])
                            }
                        }
                    });//object map end
                    return k;
            }
        }

        var row =
            (registerData !== "" && currentDraftData !== "" && publishedData !== "") ?
                Object.keys(registerData).map(function (languagerow) {

                    return (
                        (registerData[languagerow] !== "" && (typeof registerData[languagerow]) === "string" && (devActions.find(a => a == languagerow)) === undefined) ?
                            <tr>
                                <td>
                                    <p id={"lbl" + languagerow} className="spanwidth">{registerData[languagerow]}</p>
                                </td>
                                <td>
                                    {
                                        <textarea
                                            onChange={changeevent}
                                            className="textareastyle"
                                            id={languagerow}
                                            value={currentDraftData[languagerow]}
                                        />
                                    }
                                </td>
                                <td>
                                    <p className="spanwidth">{publishedData[languagerow]}</p>
                                </td>
                            </tr> :
                            ((typeof registerData[languagerow]) === "object" && (devActions.find(a => a == languagerow)) === undefined) ?

                                getComponent(registerData[languagerow], languagerow, languagerow, draftData_innerObject[languagerow], publishedData[languagerow])
                                : null
                    )
                }) : ''


        return (

            <div className="grid-container popup-content">
                <div className="title pt20">{this.props.content.LanguageManager[0].subItems[1].text}</div>
                <Form model="lanMgr"
                    method="post"
                    getDispatch={(dispatch) => this.formDispatch = dispatch}>
                    <div className="languageSelect-width description">
                        <div className="medium-8 cell">
                            {
                                (this.state.selectedLanguage !== '') ?
                                    <label style={{ visibility: 'visible' }} class="css-floatingLabel css-floatingLabelMargin"
                                        for="lanMgr.LanguageName">{'Translate English To'}</label> :
                                    <label style={{ visibility: 'hidden' }} class="css-floatingLabel css-floatingLabelMargin"
                                        for="lanMgr.LanguageName">{'Translate English To'}</label>
                            }
                            <Select
                                model="lanMgr.LanguageName"
                                id="languageto"
                                styles={translateScreen_DropdownStyle}
                                autocomplete="true"
                                placeholder={'Translate English To'}
                                value={this.state.languageOption.filter(option => option.label === this.state.selectedLanguage)}
                                onChange={this.handlelangChange}
                                validators={{ required: validators.required }}
                                validateOn="change"
                                options={this.state.languageOption}
                                isSearchable={false}
                                classNamePrefix="gen-react-select"
                                className="gen-react-select-container"
                            />
                            <Errors className="errors errorsMargin"
                                model="lanMgr.LanguageName"
                                show="touched"
                                messages={{
                                    required: this.props.validation.required
                                }} />

                            <Control.text
                                model="lanMgr.LanguageName"
                                id="lanMgr.LanguageName"
                                hidden
                                component={Textfield}
                                value={this.state.selectedLanguage}
                                defaultValue={this.state.selectedLanguage}
                                validateOn="change"
                                validators={{
                                    required: validators.required
                                }}
                            />
                        </div>
                        <div>
                            {
                                (this.state.selectedScreenName !== '') ? <label style={{ visibility: 'visible' }} class="css-floatingLabel css-floatingLabelMargin"
                                    for="lanMgr.LanguageShortName">{'Select Screen'}</label> :
                                    <label style={{ visibility: 'hidden' }} class="css-floatingLabel css-floatingLabelMargin"
                                        for="lanMgr.LanguageShortName">{'Select Screen'}</label>
                            }
                            <Select
                                model="lanMgr.LanguageShortName"
                                id="screenName"
                                styles={translateScreen_DropdownStyle}
                                autocomplete="on"
                                placeholder={'Select Screen'}
                                value={this.state.screenNamesOption.filter(option => option.label === this.state.selectedScreenName)}
                                onChange={(value) => this.handleScreenChange(value)}
                                validators={{ required: validators.required }}
                                validateOn="change"
                                defaultValue="Home Screen"
                                options={this.state.screenNamesOption}
                                isSearchable={false}
                                classNamePrefix="gen-react-select"
                                className="gen-react-select-container"
                            />

                            <Errors
                                className="errors errorsMargin"
                                show="touched" model="lanMgr.LanguageShortName"
                                messages={{ required: this.props.validation.required }}
                            />

                            <Control.text
                                type="text"
                                model="lanMgr.LanguageShortName"
                                id="lanMgr.LanguageShortName"
                                hidden
                                component={Textfield}
                                value={this.state.selectedScreenName}
                                defaultValue={this.state.selectedScreenName}
                                validateOn="change"
                                validators={{
                                    required: validators.required
                                }}
                            />
                        </div>
                    </div>
                    <div className="errors">{this.props.user.error}</div>
                    <div className="success">{this.state.messages}</div>
                    <table>
                        <thead>
                            <tr>
                                <th><h6>English Value</h6> </th>
                                <th><h6>Draft Value</h6></th>
                                <th><h6>Target Language Value</h6></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                (currentDraftData !== "" && registerData !== "" && publishedData != "")
                                    ?
                                    row :
                                    null
                            }
                        </tbody>
                    </table>
                    <div className="grid-x ">
                        <div className=" medium-5 cell float-ceter" >
                            <Button
                                type="submit"
                                content={this.props.content.LanguageManager[0].subItems[1].updateDraft}
                                disabled={this.props.user.loading}
                                onClick={this.handleUpdateDraftClick}
                                color />
                        </div>
                        <div className="medium-4 cell float-right" >
                            <Button
                                type="submit"
                                content={this.props.content.LanguageManager[0].subItems[1].resetProduction}
                                disabled={this.props.user.loading}
                                onClick={this.handleClickRsetContent}
                                color />
                        </div>
                    </div>
                </Form>
                <div>
                    {this.showPublishLangauge() ?
                        <div className=" medium-5 cell pt13" >
                            <Button
                                type="submit"
                                content={this.props.content.LanguageManager[0].subItems[1].publishDraft}
                                disabled={this.props.user.loading}
                                onClick={this.handleClickPublishDraft}
                                color />
                        </div>
                        : null
                    }
                </div>
            </div>
        )
    };
}

const mapStateToProps = (state) => {
    return {
        register: state.register,
        user: state.default,
        currentData: state.currentData,
        innerObject: state.innerObject,
        SelectedLangData: state.SelectedLangData,
        validation: state.content.validation,
        content: state.content.menu
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        callApi: () => dispatch(callApiget()),
        translateENtoLanguage: (data) => dispatch(translateENtoLanguage(data)),
        getLanguageListFromAPI: () => dispatch(getlanguageLists()),
        handleClickMovetoApplication: (data, userID) => dispatch(movetoapplication(data, userID)),
        getDataOfLanguage: (language, fromPublish) => dispatch(GetDataOfLanguage(language, fromPublish)),
        updateTableData_AOI: (allDetails) => dispatch(updateTableData_AOI(allDetails)),
        UpdateTableData_UserInputs: (allDetail) => dispatch(UpdateTableData_UserInputs(allDetail)),
        UpdateTableData_Industries: (allDetail) => dispatch(UpdateTableData_Industries(allDetail)),
        UpdatePublishDBChange_AOIValueDrivers: (itemAllDetail) => dispatch(UpdatePublishDBChange_AOIValueDrivers(itemAllDetail)),
        UpdatePublishDBChange_AOIValueDrivers_subItem: (itemAllDetail) => dispatch(UpdatePublishDBChange_AOIValueDrivers_subItem(itemAllDetail)),
        UpdateTableData_valueDrivers: (itemAllDetail) => dispatch(UpdateTableData_valueDrivers(itemAllDetail)),
        UpdateTableData_SubvalueDrivers: (itemAllDetail) => dispatch(UpdateTableData_SubvalueDrivers(itemAllDetail)),
        UpdateTableData_BVSROIs: (itemAllDetail) => dispatch(UpdateTableData_BVSROIs(itemAllDetail)),

        LanguageTranslationLoading: (data) => dispatch(LanguageTranslationLoading(data)),
        UpdateTableData_Countries: (allDetail) => dispatch(UpdateTableData_Countries(allDetail)),
    }
}
//LanguageTranslationSuccess: () => dispatch(LanguageTranslationSuccess()),
export default connect(mapStateToProps, mapDispatchToProps)(TranslateScreen)