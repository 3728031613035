import React from 'react'
import FaAngleRight from 'react-icons/lib/fa/angle-right'
import FaAngleLeft from 'react-icons/lib/fa/angle-left'
import MdClear from 'react-icons/lib/md/clear'
import classnames from 'classnames'

const ButtonContent = (props) => {
	// const icon = function() {
	// 	switch (props.icon) {

	// 		case 'left-arrow':
	// 			return <span style={{ paddingLeft: '10px', position: 'absolute', left: '-10px', top: '9px' }}><FaAngleLeft className="button__icon button__icon-left-arrow"/><FaAngleLeft className="button__icon--hover button__icon-left-arrow--hover"/></span>

	// 		case 'x':
	// 			return <MdClear className="button__icon button__icon-x"/>

	// 		default:
	// 			return <span><FaAngleRight className="button__icon"/><FaAngleRight className="button__icon--hover"/></span>
	// 	}
	// }

	return (
		<div className={classnames('button__inner', { 'button__inner--left-icon' : props.iconPosition === 'left' })}>
			{
				props.disabled && props.showLoader ? <div className="button-loader" /> : <span>{props.content}</span>
			}
		</div>
	)
}

const Button = (props) =>
	<button
		type={props.type}
		onClick={() => props.onClick()}
		disabled={props.disabled}
		showLoader={props.showLoader}
		className={'button ' + (props.float ? 'float-right ' : props.floatMargin0 ? 'float-right-margin-0' : '') + (props.color ? 'button--accent ' : '')}>
		<ButtonContent icon={props.icon} content={props.content} disabled={props.disabled} iconPosition={props.iconPosition} showLoader={props.showLoader} />
	</button>

export default Button

Button.defaultProps = {
	type: 'button',
	onClick: () => {},
	disabled: false,
	icon: 'right-arrow',
	iconPosition: 'right',
	float: false,
	floatMargin0: false,
	showLoader: true,
}
