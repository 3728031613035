import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Control, Form, Errors, actions } from 'react-redux-form'
import { Checkbox } from 'material-ui';
import DatePicker from "react-datepicker";
import Modal from 'reboron/OutlineModal'

import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment'
// Actions
import {
	shareValueStorySuccess,
	shareValueStoryFailure,
	getSharedValueStories,
	shareValueStory,
	toggleValueStoryShare,
	resetError
} from 'actions/userActions'
// Utils
import { BreakLine } from 'utils/Utils'
// UI
import Textfield from 'react-mdl/lib/Textfield'
import Button from 'UI/Button'
// Settings
import { validators, changeModalStyle, changeModalContentStyle } from 'Constants'
const changeModalStyleExpanded = Object.assign({}, changeModalStyle);
changeModalStyleExpanded.width = "100%";
changeModalStyleExpanded.maxWidth = "1250px";

class ValueStoryShare extends Component {
	constructor(props) {
		super(props)
		this.state = {
			filterEmail: '',
			sharedVS: [],
			responseMsg: '',
			allowEdit: true,
			expirationDate: moment(new Date()).add(this.props.SelectedVSData[0].ValueStoryType=== 'EnablementExecution' ? 60 : 30, 'days').toDate(),
			company: '',
			shareWithCustomer: false,
			startDate: new Date(),
			onlyEnablementInsight: false,
			disableShareButton : false,
		}
		this.loadData = this.loadData.bind(this)
		this.loadData()

		this.showModal = this.showModal.bind(this)
		this.hideModal = this.hideModal.bind(this)
		this.closeModal = this.closeModal.bind(this)
		this.shareWithCustomerChange = this.shareWithCustomerChange.bind(this)
		this.shareOnlyEnablementInsight = this.shareOnlyEnablementInsight.bind(this)
	}

	loadData() {
		this.setState({ responseMsg: "" })
		this.props.user.error = "";
		this.props.getSharedValueStories(this.props.VSId)
			.then((response) => {
				var userData = [];
				this.props.shareValueStorySuccess(response.data.Message)
				for (var i = 0; i < response.data.Result.length; i++) {
					var obj = response.data.Result[i];
					userData.push(obj);
				}
				this.setState({ sharedVS: userData })
				this.setState({ responseMsg: response.data.Message })
			}).catch((error) => {
				if (error.stack.includes('Network Error')) {
					this.props.shareValueStoryFailure()
				}
				else {
					this.props.shareValueStoryFailure(error.response.data.Message)
				}
			})
	}

	handleUserInput(filterEmail) {
		this.customerValidation({ 'SharedWith': filterEmail.target.value, 'Company': this.state.company, 'ExpirationDate': this.state.expirationDate }, this.state.shareWithCustomer)
		this.setState({ filterEmail: filterEmail.target.value.toLowerCase() });
	};

	showModal(action) {
		this.refs[action].show()
	}

	hideModal(action) {
		this.refs[action].hide()
	}

	closeModal(action) {
		this.setState({ toggle: false }, this.refs[action].hide())
	}

	handleSubmit(formValues) {
		this.setState({ responseMsg: "" })
		this.props.user.error = "";
		var sharedVS = this.state.sharedVS.slice();
		var isDuplicate = false;
		sharedVS.map(function (VS) {
			for (var key in VS) {
				if (VS.SharedWith === formValues.Email/* && VS.Active*/) {
					isDuplicate = true;
					break;
				}
			}
		});
		if (isDuplicate === false) {

			if (this.props.user.user.Email === formValues.Email) {
				this.props.shareValueStoryFailure(this.props.Content.duplicateShare)
			}
			else {
				let values = Object.assign({}, formValues)
				values.VSId = this.props.VSId
				values.SharedBy = this.props.user.user.Email //Originator Email ID
				values.SharedWith = formValues.Email //Shared Email ID
				values.Language = this.props.language.toUpperCase();
				values.AllowEdit = formValues.AllowEdit
				values.Company = formValues.Company
				values.ExpirationDate = this.state.expirationDate
				values.EnablementFlag = this.state.onlyEnablementInsight

				this.customerValidation(values, this.state.shareWithCustomer)

				/*this.resetExpirationDate()
				this.resetCompany()

				*/

				var content = this.props.Content;
				this.props.shareValueStory(values)
					.then((response) => {
						if (response.data.StatusCode === 200) {
							var msg = content[response.data.Message]
							this.props.shareValueStorySuccess(msg)
							this.setState({ responseMsg: msg })
						}
						else
							this.props.shareValueStoryFailure(content[response.data.Message])

						this.state.sharedVS.push(response.data.Result)
						this.setState({ filterEmail: '' });

					}).catch((error) => {
						if (error.response.data.Message === "invalidEmail")
							this.props.shareValueStoryFailure(this.props.validation.validEmail)
						else if (error.response.data.Message === "notRegistered" || error.response.data.Message === "duplicateShare")
							this.props.shareValueStoryFailure(content[error.response.data.Message])
						else if (error.stack.includes('Network Error'))
							this.props.shareValueStoryFailure(this.props.validation.networkError)
						else
							this.props.shareValueStoryFailure(error.response.data.Message)
					})
			}
		}
		else {
			this.props.shareValueStoryFailure(this.props.Content.duplicateShare)
		}
	}

	customerValidation(formValues, shareWithCustomer) {
		if (formValues.SharedWith.toLowerCase().endsWith('@sap.com') && shareWithCustomer) {
			//this.showModal("terms")
			this.setState({ disableShareButton: true });
			this.formDispatch(actions.setErrors('valueStoryShare.Email', {
				customerValidation: true
			}))
		} else {
			this.setState({ disableShareButton: false });
			this.formDispatch(actions.setErrors('valueStoryShare.Email', {
				customerValidation: false
			}))
		}

		if (shareWithCustomer && !validators.required(formValues.Company)) {
			//this.showModal("terms")
			this.formDispatch(actions.setErrors('valueStoryShare.Company', {
				required: true
			}))
		} else {
			this.formDispatch(actions.setErrors('valueStoryShare.Company', {
				required: false
			}))
		}
		if (shareWithCustomer && !formValues.ExpirationDate) {
			this.formDispatch(actions.setErrors('valueStoryShare.ExpirationDate', {
				required: true
			}))
		} else {
			this.formDispatch(actions.setErrors('valueStoryShare.ExpirationDate', {
				required: false
			}))
		}
	}

	expirationDateOnChange(date) {
		this.resetExpirationDate()

		this.setState({ expirationDate: date });
	}

	companyOnChange(event) {
		this.resetCompany()

		this.setState({ company: event.target.value });
	}

	shareWithCustomerChange(event) {
		if (this.state.shareWithCustomer) {
			this.resetCompany();
			this.resetExpirationDate();
			this.resetShareWithCustomer()
		} else {
			this.customerValidation({ 'SharedWith': this.state.filterEmail, 'Company': this.state.company, 'ExpirationDate': this.state.expirationDate }, !this.state.shareWithCustomer)
		}

		this.setState(prevState => { return { shareWithCustomer: !prevState.shareWithCustomer } })
	}

	shareOnlyEnablementInsight(event){
		this.setState(prevState => {return { onlyEnablementInsight: !prevState.onlyEnablementInsight}})
	}

	resetShareWithCustomer() {
		this.setState({ disableShareButton: false });
		//this.formDispatch(actions.reset('valueStoryShare.Email'));
		this.formDispatch(actions.setErrors('valueStoryShare.Email', {
			customerValidation: false
		}))
	}

	resetExpirationDate() {
		//this.formDispatch(actions.reset('valueStoryShare.ExpirationDate'));
		this.formDispatch(actions.setErrors('valueStoryShare.ExpirationDate', {
			required: false
		}))
	}

	resetCompany() {
		//this.formDispatch(actions.reset('valueStoryShare.Company'));
		this.formDispatch(actions.setErrors('valueStoryShare.Company', {
			required: false
		}))
	}

	handleShareUnshare(VSId, sharedWith, sharedBy, isActive, allowEdit) {
		this.setState({ responseMsg: "" })
		this.props.user.error = "";
		var language = this.props.language.toUpperCase()
		var content = this.props.Content;
		this.props.toggleValueStoryShare(VSId, sharedWith, sharedBy, isActive, language, allowEdit)
			.then((result) => {
				//if (result.data !== undefined) {
				if (result.data.StatusCode === 200) {
					var msg = content[result.data.Message]
					this.props.shareValueStorySuccess(msg)
					this.setState({ responseMsg: msg })
					var sharedVS = this.state.sharedVS.slice();
					var updatedsharedVS = sharedVS.map(function (VS) {
						for (var key in VS) {
							if (key === "Active" && VS.SharedWith === sharedWith) {
								VS[key] = isActive
							}
						}

						if (VS.SharedWith === sharedWith) {
							VS.AllowEdit = allowEdit
						}

						return VS;
					});
					this.setState({ sharedVS: updatedsharedVS });
				}
				else {
					this.props.shareValueStorySuccess(result.response.data.Message)
					//this.setState({ responseMsg: response.data.Message })
				}
				//}
			}).catch((error) => {
				if (error.stack.includes('Network Error')) {
					this.props.shareValueStoryFailure(this.props.validation.networkError)
				}
				else {
					this.props.shareValueStoryFailure(error.response.data.Message)
				}
			})
	}

	componentWillUnmount() {
		this.formDispatch(actions.reset('valueStoryShare'))
		this.props.resetError()
	}

	render() {
		let featureCustomerAccess = this.props.user.user.applicationFeatures.findIndex(a => a.Action === 'customerAccess') > -1

		return (
			<div className="value-share-story popup-content">
				<div className="grid-x">
					<div className="medium-12">
						<div className="title">{this.props.IsShared ? BreakLine(this.props.Content.sharedHistory) : BreakLine(this.props.Content.shareStory)}</div>
					</div>
					<div className="medium-12 description">
						<Form
							model="valueStoryShare"
							getDispatch={(dispatch) => this.formDispatch = dispatch}
							onSubmit={(values) => this.handleSubmit(values)}
						>
							{(this.props.IsShared === false) ?
								<span>
									<div className="grid-x grid-padding-x">
										<div className="medium-4">
											<SearchBar filterEmail={this.state.filterEmail} onUserInput={this.handleUserInput.bind(this)} shareWithCustomer={this.state.shareWithCustomer} content={this.props.Content} validation={this.props.validation} />
										</div>
										<div className="medium-2 pt30">
											<div className="float-left width30">
												<Control.checkbox
													model="valueStoryShare.AllowEdit"
													id="valueStoryShare.AllowEdit"
													title={this.props.Content.allowEditTooltip}
													checked={this.state.allowEdit}
													onClick={(e) => this.setState(prevState => { return { allowEdit: !prevState.allowEdit } })} />
											</div>
											<div className="float-left">
												{this.props.Content.allowEdit}
												<span title={this.props.Content.allowEditTooltip}><img style={{ width: '24px', height: '24px' }} src="/images/help-icon.png" /></span>
											</div>

										</div>
										{featureCustomerAccess ?
											<div className="medium-3 pt30">
												<div className="float-left width30">
													<Control.checkbox
														model="valueStoryShare.ShareWithCustomer"
														id="valueStoryShare.ShareWithCustomer"
														checked={this.state.shareWithCustomer}
														onClick={this.shareWithCustomerChange} />
												</div>
												<div className="float-left normalFont">
													{this.props.Content.shareWithCustomer}
													<span title={this.props.Content.customerDetailsTooltip}><img style={{ width: '24px', height: '24px' }} src="/images/help-icon.png" /></span>
												</div>
											</div> : null}
										<div className="medium-2 value-share-button">
											{ !this.state.disableShareButton ?
											<Button
												type="submit"
												disabled={this.props.user.loading}
												content={this.props.Content.share}
												color
											/>
											 : null }
										</div>
									</div>
									{featureCustomerAccess && this.state.shareWithCustomer ?
										<div className="grid-x grid-padding-x">
											<div className="medium-12 pt20 customer-access-header">
												{this.props.Content.customerDetails}
											</div>
											<div className="medium-12 pt10">
												<div className="grid-x grid-padding-x">
													<div className="medium-4 cell">
														{this.props.Content.expirationDate}
													</div>
													<div className="medium-4 cell">
														{this.props.Content.company}
													</div>
												</div>
												<div className="grid-x grid-padding-x">
													<div className="medium-4 cell">
														<Control.custom
															model="valueStoryShare.ExpirationDate"
															id="valueStoryShare.ExpirationDate"
															component={DatePicker}
															validators={{
																required: (val) => !this.state.shareWithCustomer ? true : this.state.expirationDate ? true : false
															}}
															controlProps={{
																style: { width: '300px' },
																selected: this.state.expirationDate,
																minDate: this.state.startDate,
																className: "calendar-text",
																popperClassName: "calendar-popper",
																disabled: !this.state.shareWithCustomer,
																maxDate: moment(new Date()).add(this.props.SelectedVSData[0].ValueStoryType=== 'EnablementExecution' ? 180 : 30, 'days').toDate(),
																onChange: date => this.expirationDateOnChange(date)
															}}
														/>

														{/*<DatePicker
													style={{width: '300px'}}
													selected={this.state.expirationDate}
													minDate={this.state.startDate}
													className="calendar-text" 
													popperClassName="calendar"
													disabled={!this.state.shareWithCustomer}
													maxDate={moment(new Date()).add (1, 'week').toDate()}
												onChange={date => this.expirationDateOnChange(date)} />*/}
														<Errors
															className="errors"
															model="valueStoryShare.ExpirationDate"
															show="touched"
															messages={{
																required: this.props.validation.required
															}}
														/>
													</div>
													<div className="medium-4 cell">
														<Control.text
															style={{ width: '300px' }}
															className="mdl-textfield__input"
															model="valueStoryShare.Company"
															id="valueStoryShare.Company"
															disabled={!this.state.shareWithCustomer}
															onChange={(event) => this.companyOnChange(event)}
															validators={{
																required: (val) => !this.state.shareWithCustomer ? true : validators.required(this.state.company)
															}}
														/>
														<Errors
															className="errors"
															model="valueStoryShare.Company"
															show="touched"
															messages={{
																required: this.props.validation.required
															}}
														/>
													</div>
													<div className="medium-4 cell">
														<div className="float-left width30">
															<Control.checkbox
																model="valueStoryShare.EnablementFlag"
																checked={this.state.onlyEnablementInsight}
																onClick={this.shareOnlyEnablementInsight} />
														</div>
														<div className="float-left normalFont">
															Share only Enablement Insight View
															<span title={this.props.Content.shareOnlyEnablementInsight}><img style={{ width: '24px', height: '24px' }} src="/images/help-icon.png" /></span>
														</div>
													</div>
												</div>
											</div>
										</div> : null}
								</span>
								:
								null
							}
						</Form>
						<div>
						</div>
						{this.state.responseMsg !== "" ? <div className="success">{this.state.responseMsg}</div>
							: <div className="errors">{this.props.user.error}</div>
						}
					</div>
				</div>
				<div className="grid-x">
					<div className="medium-12">
						<UserTable
							OnShareUnshareTable={this.handleShareUnshare.bind(this)}
							sharedVS={this.state.sharedVS}
							filterEmail={this.state.filterEmail}
							isShared={this.props.IsShared}
							Content={this.props.Content}
							allowEdit={this.state.allowEdit}
							featureCustomerAccess={featureCustomerAccess}
						/>
					</div>
				</div>
			</div>
		)
	}
}

class SearchBar extends React.Component {
	handleChange(event) {
		this.props.onUserInput(event);
	}
	render() {
		return (
			<div className="medium-10 cell">
				<Control.text
					model="valueStoryShare.Email"
					id="valueStoryShare.Email"
					component={Textfield}
					label={'Email'}
					floatingLabel
					ref="filterEmailInput"
					onChange={this.handleChange.bind(this)}
					validators={{
						validEmail: validators.validEmail,
						customerValidation: (val) => !this.props.shareWithCustomer ? true : val !== '' && !val.toLowerCase().endsWith('sap.com')						
					}}
					validateOn="blur"
				/>
				<Errors
					className="errors"
					model="valueStoryShare.Email"
					show="touched"
					messages={{
						validEmail: this.props.validation.validEmail,
						customerValidation: this.props.content.customerValidation
					}}
				/>
			</div>
		);
	}
}

class UserTable extends React.Component {
	render() {
		var OnShareUnshare = this.props.OnShareUnshareTable
		var filterEmail = this.props.filterEmail
		var isShared = this.props.isShared
		var Content = this.props.Content
		let featureCustomerAccess = this.props.featureCustomerAccess
		var VS = this.props.sharedVS.map(function (VS) {
			if (VS.SharedWith.toLowerCase().indexOf(filterEmail) === -1) {
				return;
			}
			else return (<UserRow
				VSId={VS.VSId}
				SharedWith={VS.SharedWith}
				SharedBy={VS.SharedBy}
				Active={VS.Active}
				OnShareUnshareRow={OnShareUnshare.bind(this)}
				VS={VS}
				isShared={isShared}
				Content={Content}
				AllowEdit={VS.AllowEdit}
				featureCustomerAccess={featureCustomerAccess}
			/>)
		});
		return (
			<div className="basic-table">
				<table width="100%">
					<thead>
						<tr>
							<th>{Content.sharedby}</th>
							<th>{Content.sharedwith}</th>
							{/* <th>Shared</th> */}
							<th>{Content.dateshared}</th>
							{featureCustomerAccess ? <th>{Content.expirationDate}</th> : null}
							<th>{Content.datemodified}</th>
							{/* {(isShared === false) ? <th>{Content.share}/{Content.unshare}</th> : <th></th>} */}
							{featureCustomerAccess ? <th>{Content.company}</th> : null}
							<th title={Content.allowEditTooltip}>{Content.allowEdit}</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{VS}
					</tbody>
				</table>
			</div>
		);
	}
}

class UserRow extends React.Component {
	constructor(props) {
		super(props);
	}

	onShareUnshare() {
		this.props.OnShareUnshareRow(this.props.VSId, this.props.SharedWith, this.props.SharedBy, !this.props.Active, this.props.AllowEdit);
	}

	onAllowEditClick() {
		this.props.OnShareUnshareRow(this.props.VSId, this.props.SharedWith, this.props.SharedBy, this.props.Active, !this.props.AllowEdit);
	}

	render() {
		return (
			<tr className="eachRow">
				<td>{this.props.VS.SharedBy}</td>
				<td>{this.props.VS.SharedWith}</td>
				{/* <td >{this.props.VS.Active ? 'Yes' : 'No'}</td> */}
				<td>{this.props.VS.SharedDate === null ? '' : moment(this.props.VS.SharedDate).format('Do MMM YYYY, h:mm:ss a')}</td>
				{this.props.featureCustomerAccess ?
					<td>
						{!this.props.VS.Company || this.props.VS.ExpirationDate === null ? null : moment(this.props.VS.ExpirationDate).format('Do MMM YYYY, h:mm:ss a')}
						{/*<DatePicker
					style = {{width: '300px'}}
					selected = {this.props.VS.ExpirationDate}
					minDate = {new Date()}
					className = "calendar-text"
					popperClassName = "calendar-popper"
					disabled = {!this.state.shareWithCustomer}
					maxDate = {moment(new Date()).add (1, 'week').toDate()}
					onChange = {date => this.expirationDateOnChange(date)}
					
				/>*/}
					</td> : null}
				<td>{this.props.VS.ModifiedDate === null ? '' : moment(this.props.VS.ModifiedDate).format('Do MMM YYYY, h:mm:ss a')}</td>
				{this.props.featureCustomerAccess ? <td>{this.props.VS.Company}</td> : null}
				{(this.props.isShared === false) ?
					<td>
						<Checkbox checked={this.props.AllowEdit} onClick={this.onAllowEditClick.bind(this)} />
					</td> : <td><Checkbox checked={this.props.AllowEdit} disabled={true} /></td>}
				{(this.props.isShared === false) ?
					<td>
						{this.props.VS.Active ? <button onClick={this.onShareUnshare.bind(this)}>{this.props.Content.unshare}</button>
							: <button onClick={this.onShareUnshare.bind(this)}>{this.props.Content.share}</button>}
					</td>
					:
					<td>
					</td>
				}
			</tr>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		language: state.language,
		ui: state.content.ui,
		user: state.default,
		validation: state.content.validation,
		userData: state.valueStory,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		shareValueStorySuccess: (bool) => dispatch(shareValueStorySuccess(bool)),
		shareValueStoryFailure: (error) => dispatch(shareValueStoryFailure(error)),
		getSharedValueStories: (VSId) => dispatch(getSharedValueStories(VSId)),
		shareValueStory: (formValues) => dispatch(shareValueStory(formValues)),
		toggleValueStoryShare: (VSId, sharedWith, sharedBy, isActive, language, allowEdit) => dispatch(toggleValueStoryShare(VSId, sharedWith, sharedBy, isActive, language, allowEdit)),
		resetError: () => dispatch(resetError())
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ValueStoryShare)
