import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReactDOM from "react-dom";
import ReactPaginate from 'react-paginate';
import { path, routes } from 'Constants'
import { ExportToCsv } from 'utils/ExportToCsv';
import { getNonEmptyValue, binarySearchIndex, formatThousands } from 'utils/Utils'
import classNames from "classnames";
import cloneDeep from 'lodash/cloneDeep';

// Settings
import Select from 'react-select'
import ReactDataGrid from 'react-data-grid';
import { Toolbar, Data, Filters, Editors, Menu } from "react-data-grid-addons";
import { CSVLink, CSVDownload } from "react-csv";
import { MultiSelect } from 'primereact/multiselect';
import { PickList } from 'primereact/picklist';
import Button from 'UI/Button';
import Popup from "reactjs-popup";
import TextfilterRenderer from 'UI/TextFilterRenderer';
import NumericFilterRenderer from 'UI/NumericFilterRenderer';
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import { Workbook } from 'exceljs/dist/exceljs';
import {
    columnFieldModalStyle,
    suggestedValueContentStyle,
    backdropStyle, detailedFileFields
} from 'Constants';
import ColumnFields from '../Spend/ColumnFields';
import Modal from 'reboron/OutlineModal';

const { ContextMenu, MenuItem, SubMenu, ContextMenuTrigger } = Menu;

const selectors = Data.Selectors;
const {
    NumericFilter,
    AutoCompleteFilter,
    MultiSelectFilter,
    SingleSelectFilter
} = Filters;

class CustomGrid extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            isError: 'No',
            responseMsg: '',
            RequestIds: [],
            selectedScopes: '',
            fileDetails: [],
            scopes: [],
            pageCount: 1,
            filters: {},
            perPage: 500,
            paginatedRowFrom: 0,
            paginatedRowTo: 500,
            data: [],
            columnDefs: [],
            rowData: [],
            filteredRows: [],
            columns: [],
            excelColumns: [],
            pageCountOption: [{ label: '100', value: 100 }, { label: '250', value: 250 }, { label: '500', value: 500 }, { label: '1,000', value: 1000 }],
            selectedPageCount: 50,
            filteredRows: [],
            cellUpdateCss: 'bisque',
            open: false,
            //slectedIndustryDetails: [],
            industryOverrideRowNo: -1,
            pendingRecords: [],
            warningRecords: [],
            errorRecords: [],
            exclusionReason_directSpendUpdates: [],
            warningDiv: true,
            errorDiv: true,
            csvRows: [],
            isFilterVisible: false,
            cleanIndustryOverrides: {},
            isSaveInProgress: false,
            actualScroll: false,
            dummyScroll: false,
            exportFlag: false,
            sccSaveFlag: true
        }
        this.handlePageClick = this.handlePageClick.bind(this)
        this.getValidFilterValues = this.getValidFilterValues.bind(this)
        this.handleFilterChange = this.handleFilterChange.bind(this)
        this.handlePageCountChange = this.handlePageCountChange.bind(this)
        //this.searchRowIndex = this.searchRowIndex.bind(this)
        this.findRowIndex = this.findRowIndex.bind(this)
        this.filterValidationRecords = this.filterValidationRecords.bind(this)
        this.warningToggle = this.warningToggle.bind(this)
        this.errorToggle = this.errorToggle.bind(this)
        this.isCellEditable = this.isCellEditable.bind(this)
        this.onSave = this.onSave.bind(this)
        this.isDisabled = this.isDisabled.bind(this)
        this.filterChange = this.filterChange.bind(this)
        this.setColumnWidth = this.setColumnWidth.bind(this)
        this.setHeaderHelpText = this.setHeaderHelpText.bind(this)
        this.columnResize = this.columnResize.bind(this)
        this.exportCsv = this.exportCsv.bind(this)
        this.uploadButtonClick = this.uploadButtonClick.bind(this)
        this.onGridRowsUpdated = this.onGridRowsUpdated.bind(this)
        this.removeFilter = this.removeFilter.bind(this)
        this.expandFilter = this.expandFilter.bind(this)
        this.actions = this.getApplicationFeatureActions()
        this.closeColumnFieldPopup = this.closeColumnFieldPopup.bind(this);
    }

    showModal(action) {
        this.refs[action].show()
    }

    hideModal(action) {
        this.refs[action].hide()
    }

    closeColumnFieldPopup() {
        // this.setState({ columns: cloneDeep(this.originalColumns) });
        this.hideModal('ColumnFields');
    }

    setColumnWidth(paginatedRows, columnDefs, isFilterVisible = this.state.isFilterVisible) {
        this.timer = setInterval(() => {
            this.setHeaderHelpText(columnDefs)
        }, 2000);


        if (paginatedRows.length === 0) {
            var grid = this.myOpenGrid;
            if (grid) {
                return grid.base.props.columns
            }
        } else {
            columnDefs = columnDefs.map((col, i) => ({
                ...col,
                hash: new Date().getTime()
            }));
        }

        return columnDefs
    }

    setHeaderHelpText(columnDefs) {
        var grid = this.myOpenGrid;
        if (grid === null || grid === undefined) {
            return;
        }

        var gridCells = ReactDOM.findDOMNode(grid).querySelectorAll('.react-grid-HeaderCell');
        gridCells.forEach((item) => {
            const headerText = item.innerText;
            var col = columnDefs.findIndex((colDef) => {
                return colDef.name === headerText
            })

            if (col !== -1) {
                item.setAttribute('title', columnDefs[col].helpText)
                if (columnDefs[col].editable) {
                    item.style.backgroundColor = '#ff8c00';
                }
            }
        })
    }

    filterChange() {
        const isFilterVisible = this.state.isFilterVisible
        if (this.state.pendingRecords.length > 0) {
            this.filteredColumnValues = []
        }

        this.setState({ isFilterVisible: !isFilterVisible })
    }

    isDisabled() {
        const errorRecords = this.state.errorRecords
        var errorRecordSize = 0;
        Object.values(errorRecords).forEach((item) => {
            errorRecordSize += item.length;
        })

        return errorRecordSize > 0 || this.state.pendingRecords.length === 0
    }

    handlePageCountChange(value) {
        var result = this.state.pageCountOption.find(page => page.value === value)
        var pageCount = this.state.filteredRows.length / result.value
        this.setState({ perPage: value, paginatedRowFrom: 0, paginatedRowTo: result.value, pageCount: pageCount })

    }

    isCellEditable(e) {
        return e.column.editable;
    }

    columnResize(idx, width) {
        // console.log(idx, width)
        // console.log(this.state.rowData, this.state.columns)
        let column = this.state.columnDefs;
        column.map((value, index) => {
            if (index == (Number(idx))) {
                value.width = Number(width)
                return;
            }
        })

        this.setState({ columnDefs: column })
    }

    searchRowIndex(arr, skey) {
        let start = 0, end = arr.length - 1;
        while (start <= end) {
            let mid = Math.floor((start + end) / 2);
            const val = arr[mid][this.props.keyColumn]
            if (val === skey) return mid;
            else if (val < skey)
                start = mid + 1;
            else
                end = mid - 1;
        }

        return -1;
    }

    findRowIndex(arr, skey) {
        let start = 0, end = arr.length - 1;
        while (start <= end) {
            let mid = Math.floor((start + end) / 2);
            const val = arr[mid]
            if (val === skey) return mid;
            else if (val < skey)
                start = mid + 1;
            else
                end = mid - 1;
        }

        return -1;
    }

    onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
        if (Object.values(updated)[0] === 'DUMMY') {
            return
        }

        this.setState(state => {
            const filteredRows = state.filteredRows;
            const rowData = state.rowData;

            const pendingRecords = state.pendingRecords;
            pendingRecords.sort((a, b) => {
                return a - b;
            })

            const colIdx = state.columnDefs.findIndex((item) => {
                return item.key === Object.keys(updated)[0]
            })

            var warningRecords = this.state.warningRecords;
            var errorRecords = this.state.errorRecords;
            //var cleanIndustryOverrides = this.state.cleanIndustryOverrides

            for (var idx = state.paginatedRowFrom + fromRow, len = (state.paginatedRowFrom + toRow); idx <= len; idx++) {
                const editable = { column: state.columnDefs[colIdx], row: filteredRows[idx] }
                if (!this.isCellEditable(editable)) {
                    continue;
                }

                if (getNonEmptyValue(filteredRows[idx][editable.column.key]) === getNonEmptyValue(Object.values(updated)[0]))
                    continue;

                let skey = filteredRows[idx][this.props.keyColumn]

                if (editable.column.key === 'ENABLEMENT_WAVE') {

                    updated.ENABLEMENT_WAVE_OVERRIDE = updated.ENABLEMENT_WAVE !== "" ? 'Override' : 'Calculated'
                }

                if (editable.column.key === 'FINAL_SUPPLIER_ENABLE_TYPE') //SAP_ARIBA_BUY_ENABLE_MTHD_RECMD
                {
                    updated.ENABLEMENT_RECOMMENDATION_OVERRIDE = 'Override'
                }

                filteredRows[idx] = { ...filteredRows[idx], ...updated };

                var rowIdx = binarySearchIndex(rowData, skey, this.props.keyColumn)
                rowData[rowIdx] = { ...rowData[rowIdx], ...updated };

                if (this.props.onRowUpdate) {
                    this.props.onRowUpdate(rowData[rowIdx], updated)
                }

                var recordIdx = this.findRowIndex(pendingRecords, skey);
                if (recordIdx === -1) {
                    pendingRecords.push(skey);
                }

                var validationRecords = this.props.validate ?
                    this.props.validate(filteredRows[idx], { warningRecords: warningRecords, errorRecords: errorRecords }) :
                    undefined;
                if (validationRecords) {
                    if (validationRecords.warningRecords)
                        warningRecords = { ...validationRecords.warningRecords }
                    if (validationRecords.errorRecords)
                        errorRecords = { ...validationRecords.errorRecords }
                }
            }
            //this.props.pendingChanges(pendingRecords)
            return {
                rowData: rowData, filteredRows: filteredRows, fromRow: fromRow, toRow: toRow, updated: updated,
                pendingRecords: pendingRecords, warningRecords: warningRecords,
                errorRecords: errorRecords
            };
        });
    };

    onSaveScc() {
        const pendingRecords = this.state.pendingRecords;
        if (pendingRecords.length === 0) {
            this.props.onSave(pendingRecords);
            this.setState({ pendingRecords: [], errorRecords: [], warningRecords: [] });
        } else {
            const pendingRecords = this.state.pendingRecords;
            if (pendingRecords.length === 0) {
                return;
            }

            let rowData = this.state.rowData
            let filteredRows = this.state.filteredRows;
            const recordsToInsert = [];

            pendingRecords.forEach((item) => {
                var rowIdx = binarySearchIndex(rowData, item, this.props.keyColumn)
                const row = rowData[rowIdx]
                recordsToInsert.push(row)
            })

            this.props.onSave(recordsToInsert)
            this.setState({ pendingRecords: [], errorRecords: [], warningRecords: [] });
        }
    }

    onSave() {
        const pendingRecords = this.state.pendingRecords;
        if (pendingRecords.length === 0) {
            return;
        }

        this.setState({ isLoading: true, isError: 'No', isSaveInProgress: true, responseMsg: '' })
        let rowData = this.state.rowData
        let filteredRows = this.state.filteredRows;
        const recordsToInsert = [];

        pendingRecords.forEach((item) => {
            var rowIdx = binarySearchIndex(rowData, item, this.props.keyColumn)
            const row = rowData[rowIdx]
            recordsToInsert.push(row)
        })

        this.props.save(recordsToInsert).then((response) => {
            if (response.status !== undefined && response.status === 200) {
                if (response.data && response.data.Result) {
                    var modifiedRows = this.props.onCommit ? this.props.onCommit(response) : response.data.Result

                    for (var i = 0; i < modifiedRows.length; i++) {
                        const row = modifiedRows[i];

                        const skey = row[this.props.keyColumn];
                        var rowIdx = binarySearchIndex(rowData, skey, this.props.keyColumn)
                        rowData[rowIdx] = { ...rowData[rowIdx], ...row };


                        for (var idx = 0; idx < filteredRows.length; idx++) {
                            if (filteredRows[idx][this.props.keyColumn] === row[this.props.keyColumn]) {
                                filteredRows[idx] = { ...filteredRows[idx], ...row };
                            }
                        }
                    }

                    this.setState({ filteredRows: filteredRows, rowData: rowData, pendingRecords: [], errorRecords: [], warningRecords: [], responseMsg: 'Record/s has been updated successfully', isError: 'No', isSaveInProgress: false, isLoading: false }, this.props.setApiResponse ? this.props.setApiResponse({ message: 'Record/s has been updated successfully', isError: 'No', isSaveInProgress: false, isLoading: false }) : () => { })
                }
                else {
                    this.setState({ pendingRecords: [], errorRecords: [], warningRecords: [], responseMsg: 'Record/s has been updated successfully', isError: 'No', isSaveInProgress: false, isLoading: false }, this.props.setApiResponse ? this.props.setApiResponse({ message: 'Record/s has been updated successfully', isError: 'No', isSaveInProgress: false, isLoading: false }) : () => { })
                    return;
                }
            } else if (response && response.stack && response.stack.includes('Network Error')) {
                this.setState({ responseMsg: this.props.content.validation.networkError, isError: 'Yes', isSaveInProgress: false, isLoading: false }, this.props.setApiResponse ? this.props.setApiResponse({ message: this.props.content.validation.networkError, isError: 'Yes', isSaveInProgress: false, isLoading: false }) : () => { })
            } else {
                this.setState({ responseMsg: this.props.content.validation.error, isError: 'Yes', isSaveInProgress: false, isLoading: false }, this.props.setApiResponse ? this.props.setApiResponse({ message: this.props.content.validation.error, isError: 'Yes', isSaveInProgress: false, isLoading: false }) : () => { })
            }
        }).catch((error) => {
            this.setState({ responseMsg: this.props.content.validation.error, isError: 'Yes', isSaveInProgress: false, isLoading: false }, this.props.setApiResponse ? this.props.setApiResponse({ message: this.props.content.validation.error, isError: 'Yes', isSaveInProgress: false, isLoading: false }) : () => { })
        })
    }

    /* This method can be used to highlight the cell values */
    RowRenderer = ({ renderBaseRow, ...props }) => {
        if (this.state.pendingRecords.length === 0) {
            return renderBaseRow(props);
        }
        //let rowData = this.state.rowData
        let backgroundColor = '';
        let textAlign = 'left';

        const pendingRecords = this.state.pendingRecords;
        const warningRecords = this.state.warningRecords;
        const errorRecords = this.state.errorRecords;

        var totalPendingRecords = []
        Object.values(pendingRecords).forEach((item) => {
            totalPendingRecords = totalPendingRecords.concat(item);
        })

        var totalWarningRecords = []
        Object.values(warningRecords).forEach((item) => {
            totalWarningRecords = totalWarningRecords.concat(item);
        })

        var totalErrorRecords = []
        Object.values(errorRecords).forEach((item) => {
            totalErrorRecords = totalErrorRecords.concat(item);
        })

        totalPendingRecords.map(item => {
            if (item == props.row[this.props.keyColumn]) {
                backgroundColor = 'lightgray'
            }
        })

        totalWarningRecords.map(item => {
            if (item == props.row[this.props.keyColumn]) {
                backgroundColor = 'yellow'
            }
        })

        totalErrorRecords.map(item => {
            if (item == props.row[this.props.keyColumn]) {
                backgroundColor = 'red'
            }
        })

        return <div style={{ backgroundColor }}>{renderBaseRow(props)}</div>;

    };

    componentDidMount() {
        if (this.props.onRef)
            this.props.onRef(this)

        // const canvas = document.getElementsByClassName('react-grid-Canvas')[0]

        // const length = this.state.columnDefs.length * 120
        // const dummyScrollRatio = length / document.documentElement.clientWidth
        // canvas.scrollLeft = dummy.scrollLeft //* dummyScrollRatio
    }

    getApplicationFeatureActions() {
        let actions = [];
        let applicationFeatures = this.props.user && this.props.user.user && this.props.user.user.applicationFeatures ? this.props.user.user.applicationFeatures : [];
        applicationFeatures.map(applicationFeat => {
            actions.push(applicationFeat.Action);
        })
        return actions;
    }

    componentWillUnmount() {
        if (this.props.onRef)
            this.props.onRef(undefined)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.apiResponse && (nextProps.apiResponse.isError !== this.state.isError || nextProps.apiResponse.message != this.state.responseMsg)) {
            this.setState({ responseMsg: nextProps.apiResponse.message, isError: nextProps.apiResponse.isError, isLoading: nextProps.apiResponse.isLoading })
        }
        else if (nextProps.columns && (nextProps.data != this.state.rowData || nextProps.data.length != this.state.rowData.length)) {
            this.loadData(nextProps);
        }
        else if(nextProps.scc && nextProps.columns !== this.state.sccColumns){
            this.setState({sccColumns: nextProps.columns})
            this.loadData(nextProps);
        }

    }

    loadData(props) {
        const colConfigArr = props.columns;

        if(props.keyColumn == 'GICS_ID' && 
            (
                (props.user.userprofile && props.user.userprofile.Roles && props.user.userprofile.Roles.length > 0 && props.user.userprofile.Roles[0].includes("Admin"))
            )
        )
        {}
        else if(props.keyColumn == 'GICS_ID' && 
            (
                (props.user.userprofile && props.user.userprofile.Roles && props.user.userprofile.Roles.length > 0 && props.user.userprofile.Roles[0].includes("Standard Internal User")) || 
                (props.user.userprofile && props.user.userprofile.Roles && props.user.userprofile.Roles.length > 0 && props.user.userprofile.Roles[0].includes("Standard Partner User"))
            )
        )
            props.columns.map(e => e.editable = false);

        colConfigArr.forEach(item => {
            item.editable = item.editable && !this.props.readOnly
        })

        this.setState({ responseMsg: '', isError: 'No' })
        var self = this
        var column = [];
        for (var i = 0; i < props.data.length; i++) {
            column = Object.keys(props.data[0]);
            if (i === 0)
                break;
        }
        if (column.length === 0) {
            //this.setState({ responseMsg: this.props.content.noData, isError: 'Yes', isLoading: false,excelColumns: [], columnDefs: [], rowData: [], filteredRows: [], pageCount: 0 })
            this.setState({ isLoading: false, excelColumns: [], columnDefs: [], rowData: [], filteredRows: [], pageCount: 0 })
        }
        else {
            var excelColumns = []

            for (let index = 0; index < column.length; index++) {
                const fieldName = column[index];
                var fieldConfigIdx = colConfigArr.findIndex((item) => {
                    return item.key === fieldName
                })

                if (fieldConfigIdx !== -1) {
                    const fieldConfig = colConfigArr[fieldConfigIdx]

                    if (fieldConfig.exportFl) {
                        excelColumns.push({ label: fieldConfig.name, key: fieldName, seq: fieldConfig.screenSeq, isCellEditable: fieldConfig.editable })
                    }
                }

            }

            var finalRows = props.data;
            if (finalRows.length > 0) {
                let keys = Object.keys(finalRows[0])
                finalRows.forEach((item) => {
                    keys.forEach(key => {
                        item[key] = item[key] === null ? '' : item[key]
                    })
                })
            }

            //finalRows.sort((a, b) => { return a[props.keyColumn] - b[props.keyColumn] })
            finalRows.sort((a, b) => { return isNaN(a[props.keyColumn]) ? ('' + a[props.keyColumn]).localeCompare(b[props.keyColumn]) : a[props.keyColumn] - b[props.keyColumn] })

            excelColumns.sort((a, b) => {
                var seq1 = isNaN(a.seq) ? 9999 : a.seq;
                var seq2 = isNaN(b.seq) ? 9999 : b.seq;
                return seq1 - seq2
            })

            var filteredRows = cloneDeep(finalRows)
            if (this.props.defaultSort && this.props.defaultSort.length > 0) {
                this.props.defaultSort.forEach(item => {
                    filteredRows.sort((a, b) => { return isNaN(a[item]) ? ('' + a[item]).localeCompare(b[item]) : a[item] - b[item] })
                })
            }

            var pageCount = finalRows.length / this.state.perPage

            // const canvas = document.getElementsByClassName('react-grid-Canvas')[0]
            // const dummyScroll = document.getElementsByClassName('scroll-dummy-child')[0]
            // let width = 0;
            // colConfigArr.forEach(c => {
            //     width += c.width
            // })

            // dummyScroll.style.width = (width) + 'px'
            // const dummyElement = document.getElementsByClassName('scroll-dummy')[0]
            // dummyElement.style.width = this.props.scc ? '94vw' : '80vw';

            // canvas.onscroll = () => {
            //     if (this.state.dummyScroll) {
            //         this.setState({ dummyScroll: false })
            //         return
            //     }

            //     this.setState({ actualScroll: true }, () => {
            //         const dummy = document.getElementsByClassName('scroll-dummy')[0]
            //         let length = 0
            //         colConfigArr.forEach(c => {
            //             length += c.width
            //         })

            //         const dummyScrollRatio = document.documentElement.clientWidth / length
            //         dummy.scrollLeft = canvas.scrollLeft// * dummyScrollRatio
            //     })
            // }
            this.setState({ excelColumns: excelColumns, columnDefs: colConfigArr, rowData: finalRows, filteredRows: filteredRows, pageCount: pageCount, isError: 'No', isLoading: false })
        }
    }

    sortFilteredRows = (filteredRows) => {
        if (!this.sortedColumns) {
            return filteredRows
        }

        var comparerAsc = (sortColumn) => (a, b) => {
            return (a[sortColumn] > b[sortColumn]) ? 1 : -1;
        }

        var comparerDesc = (sortColumn) => (a, b) => {
            return (a[sortColumn] < b[sortColumn]) ? 1 : -1;
        }

        this.sortedColumns.forEach((item) => {
            let sortColumn = item.key;
            let sortDirection = item.direction
            if (sortDirection === 'NONE') {
                return
            }

            var comparer = comparerAsc(sortColumn);
            if (sortDirection === 'DESC') {
                comparer = comparerDesc(sortColumn)
            }


            filteredRows.sort(comparer);
        })

        return filteredRows;
    };

    sortRows = (sortColumn, sortDirection) => {
        if (!this.sortedColumns) {
            this.sortedColumns = []
        }

        let rowIdx = this.sortedColumns.findIndex((item) => {
            return item.key === sortColumn
        })

        if (rowIdx !== -1) {
            this.sortedColumns.splice(rowIdx, 1);
        }

        this.sortedColumns.push({ key: sortColumn, direction: sortDirection })

        if (sortDirection === 'NONE') {
            return
        }

        var comparer = function (a, b) {
            if (sortDirection === 'ASC') {
                return (a[sortColumn] > b[sortColumn]) ? 1 : -1;
            } else if (sortDirection === 'DESC') {
                return (a[sortColumn] < b[sortColumn]) ? 1 : -1;
            }
        }

        var filteredRows = this.state.filteredRows;

        var rows = filteredRows.sort(comparer);

        var pageCount = rows.length / this.state.perPage
        this.setState({ filteredRows: [] }, () => this.setState({ filteredRows: rows }))
    };

    handlePageClick = data => {
        let selected = data.selected;
        let offset = Math.ceil(selected * this.state.perPage);
        this.setState({ paginatedRowFrom: offset, paginatedRowTo: (offset + this.state.perPage) });
    };

    handleFilterChange(filter) {
        var rows = [...this.state.rowData];
        let newFilters = { ...this.state.filters };
        if (filter === null) {
            this.setState({ filters: null, filteredRows: rows }, this.getRows(rows, {}))
            return;

        } else if (filter.filterTerm) {
            newFilters[filter.column.key] = filter;
        } else {
            delete newFilters[filter.column.key];
        }

        this.setState({ filters: newFilters }, this.getRows(rows, newFilters))

        return newFilters;
    };

    getValidFilterValues(columnId) {
        var rows = this.state.rowData
        var isFilterVisible = this.state.isFilterVisible
        this.filteredColumnValues = this.filteredColumnValues ? this.filteredColumnValues : []
        if (this.filteredColumnValues[columnId] === undefined) {
            var rows_filter = [... new Set(rows.map(item => item[columnId] != null ? item[columnId] : ""))]
            rows_filter.splice(0, 0, 'Not Included')
            this.filteredColumnValues[columnId] = rows_filter
            return rows_filter
        } else {
            return this.filteredColumnValues[columnId]
        }
    }

    getRows(rows, filters) {
        let filteredRows = selectors.getRows({ rows, filters });
        filteredRows = this.sortFilteredRows(filteredRows)

        if (this.props.onFilter) {
            this.props.onFilter(filteredRows)
        }

        var pageCount = Math.ceil(filteredRows.length / this.state.perPage);
        this.setState({ filteredRows: filteredRows, paginatedRowFrom: 0, paginatedRowTo: this.state.perPage, pageCount: pageCount, filters: filters });
    }

    uploadButtonClick() {
        this.uploadFile();
        this.setState({ sccSaveFlag: false })
    }

    uploadFile() {
        document.getElementById('inputFile').click();
    }

    exportCsv() {
        const rowData = this.state.rowData;
        const excelColumns = this.props.scc ? this.props.sccExcelColumns : this.state.excelColumns;
        const csvRows = [];

        var keys = excelColumns.map((cols) => {
            return cols.key
        })

        var headers = excelColumns.map((cols) => {
            return cols.label.includes(',') ? cols.label.replaceAll(",", "ˏ") : cols.label
            //return cols.label
        })

        const options = {
            filename: this.props.exportFileName,
            decimalSeparator: '.',
            showLabels: true,
            showTitle: false,
            useTextFile: false,
            keys: keys,
            headers: headers
        };

        const csvExporter = new ExportToCsv(options);

        csvExporter.generateCsv(rowData);
        this.setState({ exportFlag: true })
    }

    exportToExcel = () => {

        let apiData = this.state.rowData;
        let fileName = 'Scc_Data';
        const excelColumns = this.state.excelColumns;
        //create new excel work book
        let workbook = new Workbook();
        // //add name to sheet
        let worksheet = workbook.addWorksheet("Scc_Data");

        //replace key label by header as workbook reads only header key
        const newArrayOfObj = excelColumns.map(({
            label: header,
            ...rest
        }) => ({
            header,
            ...rest
        }));

        worksheet.columns = newArrayOfObj;

        for (const row of this.state.rowData) {
            worksheet.addRow(row);
        }
        let fname = "Scc_Data"

        let editableColumns = ['Skey', 'Reference Number'];
        // const idCol = worksheet.getColumn('DATA_COLLECTIONS_SKEY');
        worksheet.eachRow(function (row, rowNumber) {
            row.eachCell(function (cell, colNumber) {
                let flag = editableColumns.includes(cell.value);
                if (flag)
                    row.getCell(colNumber).fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: '007ad9' }
                    };
            });
        });

        // //add data and file name and download
        workbook.xlsx.writeBuffer().then((data) => {
            let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            FileSaver.saveAs(blob, fname + '-' + new Date().valueOf() + '.xlsx');
        });

        this.setState({ exportFlag: true })
    };

    filterValidationRecords(e, skeys) {
        const rowData = this.state.rowData;
        const filteredRows = [];
        skeys.forEach((item) => {
            var rowIdx = binarySearchIndex(rowData, item, this.props.keyColumn)
            var idx = filteredRows.findIndex((item) => {
                return item[this.props.keyColumn] === rowData[rowIdx][this.props.keyColumn]
            })

            if (rowIdx !== -1 && idx === -1) {
                filteredRows.push(rowData[rowIdx]);
            }
        })

        this.setState({ filteredRows: filteredRows })
        e.stopPropagation();
    }

    getValidationDiv() {
        const pendingRecords = this.state.pendingRecords;
        const warningRecords = this.state.warningRecords;
        const errorRecords = this.state.errorRecords;
        if (pendingRecords.length === 0) {
            return '';
        }

        var warningRecordSize = 0;
        var totalWarningRecords = []
        Object.values(warningRecords).forEach((item) => {
            totalWarningRecords = totalWarningRecords.concat(item);
        })
        var warningDiv = Object.keys(warningRecords).map((key, index) => (
            warningRecords[key].length !== 0 ?
                <div className="grid-x message-sub-row hand-cursor" onClick={(e) => this.filterValidationRecords(e, warningRecords[key])}>
                    <span>
                        <img width="10" src="images/Dot.svg" />
                    </span>
                    <div className="medium-11">
                        {key} {this.props.detailedFile.validationForText}
                        <span className="review-warning"> {formatThousands(warningRecords[key].length)} {this.props.detailedFile.validationRowsText}
                        </span>
                    </div>
                </div> : ''
        ))

        var errorRecordSize = 0;
        var totalErrorRecords = []
        Object.values(errorRecords).forEach((item) => {
            totalErrorRecords = totalErrorRecords.concat(item);
        })

        var errorDiv = Object.keys(errorRecords).map((key, index) => (
            errorRecords[key].length !== 0 ?
                <div className="grid-x message-sub-row hand-cursor" onClick={(e) => this.filterValidationRecords(e, errorRecords[key])}>
                    <span>
                        <img width="10" src="images/Dot.svg" />
                    </span>
                    <div className="medium-11">
                        {key} {this.props.detailedFile.validationForText}
                        <span className="review-errors"> {formatThousands(errorRecords[key].length)} {this.props.detailedFile.validationRowsText}
                        </span>
                    </div>
                </div> : ''
        ))

        var validationDiv = (
            <div className="validation-messages medium-12">
                <div className="grid-x">
                    <div className="medium-12 message-row hand-cursor" onClick={(e) => this.filterValidationRecords(e, pendingRecords)}>
                        <span className="pending-changes">
                            <img width="15" src="images/Dot.svg" />{formatThousands(pendingRecords.length)} {this.props.detailedFile.validationRecordText} </span>
                        {this.props.detailedFile.pendingChangeMessage}
                    </div>
                    {totalWarningRecords.length > 0 ?
                        <div className="grid-x medium-12 message-row">
                            <div className="medium-11 hand-cursor" onClick={(e) => this.filterValidationRecords(e, totalWarningRecords)}>
                                <span className="review-warning">
                                    <img width="15" src="images/Dot.svg" />{formatThousands(totalWarningRecords.length)} {this.props.detailedFile.validationWarningText} </span>{this.props.detailedFile.warningChangeMessage}
                            </div>
                            <div onClick={this.warningToggle} className="float-right plus-icon medium-1">
                                {this.state.warningDiv ? <img width="10" src="images/minus-inactive.svg" /> : <img width="10" src="images/add.svg" />}
                            </div>
                            {this.state.warningDiv ? warningDiv : ''}
                        </div> : ''}
                    {totalErrorRecords.length > 0 ?
                        <div className="grid-x medium-12 message-row">
                            <div className="medium-11 hand-cursor" onClick={(e) => this.filterValidationRecords(e, totalErrorRecords)}>
                                <span className="review-errors">
                                    <img width="15" src="images/Dot.svg" />{formatThousands(totalErrorRecords.length)} {this.props.detailedFile.validationErrorText} </span>{this.props.detailedFile.validationThatText}<span className="font-bold"> {this.props.detailedFile.validationMustText} </span>{this.props.detailedFile.errorChangeMessage}
                            </div>
                            <div onClick={this.errorToggle} className="float-right plus-icon medium-1">
                                {this.state.errorDiv ? <img width="10" src="images/minus-inactive.svg" /> : <img width="10" src="images/add.svg" />}
                            </div>
                            {this.state.errorDiv ? errorDiv : ''}
                        </div> : ''}
                </div>
            </div>)

        return validationDiv;
    }

    warningToggle() {
        this.setState((previousState) => { return { warningDiv: !previousState.warningDiv } })
    }

    errorToggle() {
        this.setState((previousState) => { return { errorDiv: !previousState.errorDiv } })
    }

    expandFilter(item) {
        let filters;
        (item.type === NumericFilterRenderer || item.type === TextfilterRenderer || item.type === 'global') ?
            filters = (<div className='breadcrumbsClose float-left'
                content={item.rawValue} onClick={() => this.removeFilter(item, item.rawValue)}
                color
            >{item.rawValue}
                <img className="ml5" width="10" src="images/close_inactive.svg" />
            </div>) :
            filters = item.filterTerm.map((item1) => (
                (<div className='breadcrumbsClose float-left'
                    content={item1.value} onClick={() => this.removeFilter(item, item1.value)}
                    color
                >{item1.value}
                    <img className="ml5" width="10" src="images/close_inactive.svg" />
                </div>)
            ))

        return filters
    }


    removeFilter(filter, item1, removeAll = false) {
        var breadCrumbsArray = this.state.filters;
        if (breadCrumbsArray) {
            Object.values(breadCrumbsArray).forEach(item => {
                if (item.type !== NumericFilterRenderer && item.type !== TextfilterRenderer && item.type !== 'global') {
                    if (item.clear) {
                        item.clear()
                    }

                    if (removeAll) {
                        item.filterTerm.length = 0;
                        return;
                    }
                    var idx = item.filterTerm.findIndex(t => t.value === item1 && item.column.key === filter.column.key)
                    if (idx !== -1) {
                        if (item.clear) {
                            item.clear()
                        }
                        item.filterTerm.splice(idx, 1)

                        if (item.filterTerm.length === 0) {
                            delete breadCrumbsArray[filter.column.key]
                        }
                    }
                } else if (removeAll || (item.column.key === filter.column.key && item.rawValue === item1)) {
                    if (item.clear) {
                        item.clear()
                    }

                    if (!removeAll)
                        delete breadCrumbsArray[filter.column.key]
                }
            })
        }

        if (removeAll) {
            breadCrumbsArray = {}
        }


        var rows = [...this.state.rowData];
        this.setState({ filters: breadCrumbsArray }, () => {
            this.getRows(rows, breadCrumbsArray)
        })
    }

    listenScrollEvent() {
        if (this.state.actualScroll) {
            this.setState({ actualScroll: false })
            return
        }

        this.setState({ dummyScroll: true }, () => {
            const canvas = document.getElementsByClassName('react-grid-Canvas')[0]
            const dummy = document.getElementsByClassName('scroll-dummy')[0]
            const length = this.state.columnDefs.length * 120
            const dummyScrollRatio = length / document.documentElement.clientWidth
            canvas.scrollLeft = dummy.scrollLeft //* dummyScrollRatio
        })
    }

    // onColumnSelection(param){
    //     this.props.onColumnSelection(param)
    // }

    render() {
        const paginatedRows = this.state.filteredRows.slice(this.state.paginatedRowFrom, this.state.paginatedRowTo);
        const validationDiv = this.getValidationDiv();
        var columnDefs = this.setColumnWidth(paginatedRows, this.state.columnDefs)
        //const height = paginatedRows.length === 0 ? 700 : (paginatedRows.length * 35 + 45)
        var filter = this.state.filters ? Object.entries(cloneDeep(this.state.filters)) : []

        return (
            <div className='grid-x medium-12'>

                <div className='medium-12' >

                    {this.props.currentFilter ?
                        <div className="grid-x">
                            <div className='medium-12'>
                                {this.state.rowData.length === 0 ? '' :
                                    <div><span className="font-bold underline">{this.props.detailedFile.currentFilterRows}</span> {formatThousands(this.state.filteredRows.length)}/{formatThousands(this.state.rowData.length)} {this.props.detailedFile.totalText}</div>}
                                <div className="font-bold underline">{this.props.detailedFile.currentFilterSelection}</div>
                                <div className="defineScope">
                                    {filter.length === 0 ? 'No filter currently selected' :
                                        filter.map((item) => {
                                            return (
                                                (item.length === 0 || item[1].filterTerm.length === 0) ? '' :
                                                    <div className="grid-x"><span className="font-small font-bold float-left pr10">{item[1].column.name}: </span><div className="medium-9">{this.expandFilter(item[1])}</div></div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        : null}

                    <div className="grid-x grid-padding-x">
                        <div className="medium-12">
                            {(this.state.isLoading) ?
                                <img style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={path + `images/ajax-loader.gif`} alt="" />
                                : null
                            }
                            {(this.state.isError === 'Yes') ?
                                <div style={{ color: 'red', textAlign: 'center' }} className="cell pt5">{this.state.responseMsg}</div>
                                : <div style={{ color: 'green', textAlign: 'center' }} className="cell pt5">{this.state.responseMsg}</div>
                            }
                        </div>
                    </div>

                </div>


                <div style={{ padding: "0 15px" }} className='medium-12'>

                    <div className="grid-x">
                        {this.state.pendingRecords.length > 0 ? <div className="medium-4 medium-offset-4 pt-10">
                            {validationDiv}
                        </div> : ''}
                        <div style={{ paddingRight: '15px' }} className={classNames('legend2', 'defineScope', 'text-align-right', 'pt10', {
                            'medium-3': this.state.pendingRecords.length > 0,
                            'medium-12': this.state.pendingRecords.length === 0,
                        })}>
                            {this.props.isEditable !== false ?
                                <div><span className="key-dot outScope"></span>{this.props.detailedFile.editableColumns} </div>
                                : null}
                        </div>
                    </div>
                    <div className="medium-12">
                        <div class='clearfix'>
                            <div className="float-left pt10">
                                <Select
                                    className="border-with-DD page-number-dd"
                                    placeholder="Page Count"
                                    value={this.state.pageCountOption.filter(option => option.value === this.state.perPage)}
                                    noOptionsMessage={() => null}
                                    onChange={(value) => this.handlePageCountChange(value.value)}
                                    options={this.state.pageCountOption}
                                    isSearchable={false}
                                />
                            </div>
                            <div className="float-left pt10">
                                <ReactPaginate
                                    previousLabel={'<'}
                                    nextLabel={'>'}
                                    breakLabel={'...'}
                                    breakClassName={'break-me'}
                                    pageCount={this.state.pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={this.handlePageClick}
                                    containerClassName={'pagination'}
                                    subContainerClassName={'pages pagination'}
                                    activeClassName={'active'}
                                    pageClassName={'page-li'}
                                    previousClassName={'previousClassName'}
                                    nextClassName={'nextClassName'}
                                    pageLinkClassName={'pageLinkClassName'}
                                    activeLinkClassName={'activeLinkClassName'}
                                />
                            </div>
                            <div style={{ width: this.props.scc? '175px' : '150px' }} className="float-right pt20">
                                {this.props.export ?
                                    <button className={classNames('float-left', 'pr25',
                                        {
                                            'hand-cursor': this.state.pendingRecords.length === 0,
                                            'gray-scale': this.state.pendingRecords.length !== 0
                                        })} onClick={this.exportCsv}
                                        disabled={this.state.pendingRecords.length !== 0} title={this.props.detailedFile.export}>
                                        {<img className="rotateimg180" width="20" src="images/export_active.svg" />}
                                    </button> : ''
                                }
                                {this.props.exportFromScc ?
                                    <button className={classNames('float-left', 'pr15',
                                        {
                                            'hand-cursor': this.state.pendingRecords.length === 0,
                                            'gray-scale': this.state.pendingRecords.length !== 0
                                        })} onClick={this.exportCsv}
                                        disabled={this.state.pendingRecords.length !== 0} title={this.props.detailedFile.export}>
                                        {<img className="rotateimg180" width="20" src="images/export_active.svg" />}
                                    </button> : ''
                                }
                                {this.props.scc ?
                                    <button id='buttonid' className={classNames('float-left', 'pr15', 'hand-cursor',
                                        { 'gray-scale': !this.state.exportFlag })} onClick={this.uploadButtonClick} disabled={!this.state.exportFlag}
                                        title={'Select A file'}>
                                        <input type="file"
                                            id="inputFile"
                                            accept=".xlsx, .xls, .csv"
                                            onChange={this.props.handleChange}
                                        >
                                        </input>
                                        {<img width="20" src="images/export_active.svg" />}
                                    </button> : ''
                                }
                                {this.props.scc ?
                                    <button className={classNames('float-left', 'pr15', 'hand-cursor',
                                        { 'gray-scale': this.state.pendingRecords.length === 0 })}
                                        onClick={this.onSaveScc.bind(this)} title={this.props.ui.save} disabled={ this.state.pendingRecords.length === 0} type="submit" float >
                                        <img width="20" src="images/save_active.svg" />
                                    </button> : ''
                                }
                                {this.props.scc ?
                                <React.Fragment>
                                        <button className="float-left pr15" title={this.props.content.columnSelect} onClick={() => this.showModal('ColumnFields')} >{<img className="hand-cursor" width="20" src="images/column_active.svg" />}</button>
                                        <Modal className="column-field-modal"
                                            ref={'ColumnFields'} backdropStyle={backdropStyle}
                                            keyboard={() => this.callback()}
                                            modalStyle={columnFieldModalStyle}
                                            contentStyle={suggestedValueContentStyle}
                                        >
                                            <ColumnFields
                                                scc={true}
                                                columnOptions={this.props.columnSelect}
                                                onColumnSelection={this.props.onColumnSelection}
                                                closePopup={() => this.closeColumnFieldPopup()} />
                                            <button onClick={() => this.closeColumnFieldPopup()} className='close' />
                                        </Modal> </React.Fragment>: ''}
                                {this.props.save ?
                                    <button className={classNames('float-left', 'pr25',
                                        {
                                            'hand-cursor': this.state.isSaveInProgress ? false : !this.isDisabled(),
                                            'gray-scale': this.state.isSaveInProgress ? true : this.isDisabled()
                                        })}
                                        onClick={this.onSave} disabled={this.state.isSaveInProgress ? true : this.isDisabled()} title={this.props.ui.save} type="submit" float >
                                        <img width="20" src="images/save_active.svg" />
                                    </button> : ''
                                }
                            </div>
                        </div>
                        <div className="grid-x grid-padding-x">
                            <div
                                id="myGrid"
                                className='medium-12 react-data-grid bottom-space'>
                                {/* <div class="scroll-dummy" onScroll={this.listenScrollEvent.bind(this)}>
                                    <div class="scroll-dummy-child"></div>
                                </div> */}
                                <ReactDataGrid
                                    ref={(dataGrid) => { this.myOpenGrid = dataGrid; }}
                                    columns={columnDefs}
                                    minWidth={'100%'}
                                    rowsCount={paginatedRows.length}
                                    minHeight={this.props.minHeight ? this.props.minHeight : 700}
                                    onGridRowsUpdated={this.onGridRowsUpdated}
                                    enableCellSelect={true}
                                    onGridSort={this.sortRows}
                                    rowGetter={i => paginatedRows[i]}
                                    toolbar={<Toolbar enableFilter onToggleFilter={() => this.handleFilterChange(null)} numberOfRows={this.state.rowData.length} filterRowsButtonText={<span onClick={this.filterChange}>{<img className={this.props.topMargin ? "filter-hand-cursor" : "hand-cursor"} title={this.props.detailedFile.filter} width="20" src="images/filter_active.svg" />}</span>} />}
                                    onAddFilter={filter => this.handleFilterChange(filter)}
                                    onClearFilters={() => this.handleFilterChange(null)}
                                    onFilterChange={filter => this.handleFilterChange(filter)}
                                    getValidFilterValues={this.getValidFilterValues}
                                    RowsContainer={ContextMenuTrigger}
                                    rowRenderer={this.RowRenderer}
                                    onCheckCellIsEditable={this.isCellEditable}
                                    onColumnResize={(idx, width) => this.columnResize(`${idx}`, `${width}`)}
                                    headerRowHeight={this.props.headerRowHeight ? this.props.headerRowHeight : 50}
                                />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        // language: state.language,
        ui: state.content.ui,
        user: state.default,
        content: state.content,
        detailedFile: state.content.spendAnalysis.detailedFile,
        validation: state.content.validation,
    }
}

const mapDispatchToProps = dispatch => {
    return {

        //insertDetailedFileRecords: (records) => dispatch(insertDetailedFileRecords(records)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomGrid)
