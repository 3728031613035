import React from 'react'
import PropTypes from 'prop-types'

// Utils
import Linkify from 'react-linkify'
import { BreakLine } from 'utils/Utils'

const About = (props) =>
	<div>
		<div className="grid-x popup-content">
			<div className="medium-12 cell">
				<div className="title">{props.content.title}</div>
				<Linkify><div className="description">{BreakLine(props.content.description)}</div></Linkify>
			</div>
		</div>
	</div>

export default About

About.propTypes = {
	content: PropTypes.shape({
		title: PropTypes.string.isRequired,
		description: PropTypes.string.isRequired
	})
}
