import React, { Component } from 'react'
import { connect } from 'react-redux'
import PopupButton from 'UI/PopupButton'
import Modal from 'reboron/OutlineModal'

import {
    backdropStyle
} from 'Constants'


const suggestedValueModalStyle = {
    width: '30%',
    //maxWidth: '1024px',
    overflowY: 'auto',
    height: 'auto',
    background: 'white',
    paddingBottom:'20px',
    paddingRight:'20px',

}

const suggestedValueContentStyle = {
    width: 'auto',
    height: 'auto',
    background: 'white',
    textAlign: 'left',
    padding: '20px'
}



const Confirmation = React.forwardRef((props, ref) => {
    return (
        <div>

            <Modal
                ref={ref} backdropStyle={backdropStyle}
                keyboard={() => this.callback()}
                modalStyle={suggestedValueModalStyle}
                contentStyle={suggestedValueContentStyle}>

                <div className="medium-12">
                    <p>
                        {props.confirmMessage}
                    </p>
                    <div className="grid-x grid-padding-x float-right">
                        <div className="float-left">
                            {props.okButtonFlag && <PopupButton
                                type="submit"
                                content="OK"
                                color
                                id={props.id}
                                onClick={(e) => props.OkButtonAction(e)}
                            />}
                        </div>
                        <div className="float-left">
                            <PopupButton
                                type="submit"
                                content="Cancel"
                                color
                                onClick={() => props.CancelButtonAction()}
                            />
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
})

export default Confirmation
