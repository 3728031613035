export const TOGGLE_VALUEDRIVER = 'TOGGLE_VALUEDRIVER'
export const ADD_SELECTED_VALUEDRIVER = 'ADD_SELECTED_VALUEDRIVER'
export const TOGGLE_SUBLEVER = 'TOGGLE_SUBLEVER'
export const SET_INCOME_STATEMENT = 'SET_INCOME_STATEMENT'
export const SET_BALANCE_SHEET = 'SET_BALANCE_SHEET'
export const SET_TOGGLE_SKU ='SET_TOGGLE_SKU'

export const toggleValueDriver = (id, bool) => {
	return {
		type: TOGGLE_VALUEDRIVER,
		module: 'BusinessValueToYou',
		stateKey: 'Selected',
		id: id,
		selected: bool
	}
}

export const selectedValueDriver = (id) => {
	return {
		type: ADD_SELECTED_VALUEDRIVER,
		id
	}
}

export const toggleSubLever = (id, bool) => {
	return {
		type: TOGGLE_SUBLEVER,
		id: id,
		selected: bool
	}
}

export const setIncomeStatement = (incomeStatement) => {
	return {
		type: SET_INCOME_STATEMENT,
		incomeStatement
	}
}

export const setBalanceSheet = (balanceSheet) => {
	return {
		type: SET_BALANCE_SHEET,
		balanceSheet
	}
}

export const toggleSKU = (id, bool) => {
	return {
		type: SET_TOGGLE_SKU,
		id: id,
		selected: bool
	}
}