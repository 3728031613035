import React from 'react';
import { connect } from 'react-redux'
import { Control, Form, Errors, actions } from 'react-redux-form'
import { RadioButton } from 'material-ui';
import ScrollArea from 'react-scrollbar'
import Button from 'UI/Button';
import ButtonWithoutLoader from 'UI/ButtonWithoutLoader'
import Select from 'react-select'
//import fuzzysort from 'fuzzysort'
import ValueStoryList from 'Search/ValueStories'
import ExistingValueStoryList from 'ValueStory/ExistingValueStoryList'
import moment from 'moment'
import { validators, dropdownColourStyles, modalStyle, emailmodalContentStyle } from 'Constants'
import { formatThousands, BreakLine, round } from 'utils/Utils'
import _ from 'lodash'

import {
    userDetailsSuccess,
    userDetailsFailure,
    userDetailsLoading,
    fetchUserValueStories,
    fetchValueStories,
    fetchRequestDetails,
    requestValueStory,
    requestValueStoryLoading,
    requestValueStorySuccess,
    requestValueStoryFailure,
    fetchValueStoriesLoading,
    fetchValueStoriesSuccess,
    fetchValueStoriesFailure,
    resetError
} from 'actions/userActions'

import {
    searchResult,
    fetchCapIqSearchResults,
    capIqSearchSuccess,
    capIqSearchFailure
}
    from 'actions/searchActions'

import { capIQFetchData } from 'actions/discoveryActions'
import { valueStoryFetchDataSuccess } from 'actions/fetchValueStory'
import {
    getSPCapitalIQData,
    getSPCapitalIQLoading,
    getSPCapitalIQSuccess,
    getSPCapitalIQFailure
} from 'actions/spendActions'

import { Checkbox } from 'material-ui';
import Modal from 'reboron/OutlineModal'

const autoHeightModalStyle = Object.assign({}, modalStyle)
autoHeightModalStyle.height = 'auto'
autoHeightModalStyle.width = '50%'



class DiscoveryVsSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: props.data,
            peArray: [],
            bpArray: [],
            flag: false,
            index: -1,
            checked: false,
            checkIndex: -1,
            // companyName: [],
            companyName: '',
            capIQId: '',
            stockTicker: '',
            selectedData: null,
            searchOption: [{ label: 'Company Name', value: 'Company Name' }],
            selectedOption: 'Company Name',
            searchText: '',
            valueStories: [],
            vsLoad: '',
            valueStoriesShared: null,
            companyDetails: [],
            companyObj: [],
            isLoading: false,
            ultimateParentChecked: true,
            publicCompanyChecked: false,
            currencyCode: 'USD',
            capIQResult: [],
            firstRequestMadeOn: '-',
            secondRequestMadeOn: '-',
            thirdRequestMadeOn: '-',
            errorMsg: '',
            searchFlag: false
        }
        this.loadPE_Data = this.loadPE_Data.bind(this)
        this.loadCompanyData = this.loadCompanyData.bind(this)
        this.createCompanyProfileArray = this.createCompanyProfileArray.bind(this)
        this.RadioButtonChecked = this.RadioButtonChecked.bind(this);
        this.onSearchTextChangeEvent = this.onSearchTextChangeEvent.bind(this);
        this.search = this.search.bind(this);
        this._handleKeyDown = this._handleKeyDown.bind(this);
        this.save = this.save.bind(this)
        this.addCurrencyMask = this.addCurrencyMask.bind(this)
        //this.filterSearchData = this.filterSearchData.bind(this)
        this.clearCAPIQ = this.clearCAPIQ.bind(this);
        this.closePopUp = this.closePopUp.bind(this);
        //this.fetchUserSharedValueStories = this.fetchUserSharedValueStories.bind(this);
        this.triggerEmail = this.triggerEmail.bind(this);
        this.showModal = this.showModal.bind(this);
        this.showRequestModal = this.showRequestModal.bind(this);
        this.hideRequestModal = this.hideRequestModal.bind(this);
    }

    showModal(originatorEmail, VSID, companyName) {
        this.props.fetchRequestDetails(VSID, this.props.user.user.Email)
            .then((result) => {
                if (result.status === 200) {
                    if (result.data.Result.length !== 3) {
                        this.setState({ VSID: VSID, originatorEmail: originatorEmail, companyName: companyName, message: '', isLoading: false, isError: false })
                        this.refs.modal.show()
                    }
                    else {
                        this.setState({ message: this.props.menu.valueStory.requestValidation, isLoading: false, isError: true })
                    }
                }
                else {
                    this.setState({ message: this.props.menu.valueStory.errorOccured, isLoading: false, isError: true })
                }
            }).catch((error) => {
                this.setState({ message: this.props.menu.valueStory.errorOccured, isLoading: false, isError: true })
            })
    }

    hideModal() {
        this.refs.modal.hide()
    }

    showRequestModal(VSID) {
        this.setState({ isError: false, message: '', isLoading: false })
        this.props.fetchRequestDetails(VSID, this.props.user.user.Email)
            .then((result) => {
                if (result.data.Result.length > 0) {
                    var firstRequest = result.data.Result[0] === null || result.data.Result[0] === undefined ? '-' : moment(result.data.Result[0]['RequestedDate']).format('ddd, Do MMM YYYY, h:mm:ss a')
                    var secondRequest = result.data.Result[1] === null || result.data.Result[1] === undefined ? '-' : moment(result.data.Result[1]['RequestedDate']).format('ddd, Do MMM YYYY, h:mm:ss a')
                    var thirdRequest = result.data.Result[2] === null || result.data.Result[2] === undefined ? '-' : moment(result.data.Result[2]['RequestedDate']).format('ddd, Do MMM YYYY, h:mm:ss a')
                    this.setState({ firstRequestMadeOn: firstRequest, secondRequestMadeOn: secondRequest, thirdRequestMadeOn: thirdRequest })
                }
                else {
                    this.setState({ firstRequestMadeOn: '-', secondRequestMadeOn: '-', thirdRequestMadeOn: '-', message: '', isLoading: false, isError: false })
                }
                this.refs.requestDetails.show()
            }).catch((error) => {
                this.setState({ message: this.props.menu.valueStory.errorOccured, isLoading: false, isError: true })
            })
    }

    hideRequestModal() {
        this.refs.requestDetails.hide()
    }

    triggerEmail(originatorEmail, VSID) {
        this.refs.modal.hide()
        this.setState({ isError: false, message: '', isLoading: false })
        var emailSuccess = this.props.contentDefault.search.emailSuccess
        var emailError = this.props.contentDefault.search.emailError
        var requesterEmail = this.props.user.user.Email
        var language = this.props.language.toUpperCase();
        this.props.requestValueStory(originatorEmail, requesterEmail, VSID, language)
            .then((response) => {
                if (response.status === 200) {
                    //this.props.requestValueStorySuccess(response)
                    let arrayValueStories = this.state.valueStories
                    arrayValueStories.find(element => element.VSID === VSID).RequestStatus = true
                    this.setState({ valueStories: arrayValueStories, message: emailSuccess, isLoading: false, isError: false })
                }
                else {
                    //this.props.requestValueStoryFailure(emailError)
                    this.setState({
                        message: emailError, isLoading: false, isError: true
                    })
                }
            }).catch((error) => {
                //this.props.requestValueStoryFailure(error.response.data.Message)
                this.setState({
                    message: emailError, isLoading: false, isError: true
                })
            })
    }

    closePopUp() {
        this.props.close()
    }

    addCurrencyMask(value) {
        const regex = /\d*\.?\d{0,2}/g
        const filteredValue = regex.exec(value)
        let currencySymbol = this.props.currencySymbols[this.state.currencyCode];
        if (this.state.currencyCode === null) {
            currencySymbol = '$';
        }

        if (filteredValue[0] === '') {
            return currencySymbol + formatThousands(this.value) + ' M'
        } else {
            this.value = filteredValue[0]
            return currencySymbol + formatThousands(this.value) + ' M'
        }

    }

    /*
    fetchUserSharedValueStories() {
        this.props.fetchValueStoriesLoading(true)
        this.setState({ isError: false, message: '', })
        this.props.fetchUserSharedValueStories(this.props.user.user.Id)
            .then((result) => {
                this.props.fetchValueStoriesSuccess(result)
                var obj = [];
                for (var i = 0; i < result.data.Result.length; i++) {

                    obj.push(result.data.Result[i])
                }
                let temp = obj;
                temp.forEach((element, i) => {
                    element.content = false;
                    element.index = i;
                });
                this.setState({ valueStoriesShared: temp })
            }).catch((error) => {
                this.props.fetchValueStoriesFailure(error.response.data.Message)
                this.setState({
                    message: error.response.data.Message, isLoading: false, isError: true
                })
            })
    }
    */
    save() {
        if (this.state.checked) {
            this.props.capIqRadioButtonClicked(this.state.selectedData)
        }
        this.props.close();
    }
    clearCAPIQ() {
        const companyData = this.props.userData;
        companyData.Organization = '';
        companyData.Industry = '';
        companyData.Location = '0';
        companyData.AnnualRevenue = null;
        companyData.TotalSpend = '0';
        companyData.CapIQId = '';
        companyData.CurrencyCode = 'USD';
        this.props.valueStoryFetchDataSuccess(companyData)
        this.props.capIqDataCleared()
        this.props.close();
    }

    onSearchTextChangeEvent(event) {
        this.setState({ searchText: event.target.value !== undefined ? event.target.value : '', checked: false, searchFlag: event.target.value !== '' ? true : false });
    }

    _handleKeyDown(e) {
        if (e.key === 'Enter') {
            this.search()
        }
    }

    /*
    filterSearchData() {
        let data = this.state.capIQResult
        let filteredResult = []
        //check if the checkbox to include only ultimate parent companies checked
        if (this.state.ultimateParentChecked && this.state.publicCompanyChecked) {
            for (let i = 0; i < data.length; i++) {
                //Include only the results which have a parent id & type publi
                if (data[i].PARENT_COMPANY_ID == data[i].COMPANY_ID && data[i].COMPANY_TYPE == 'Public Company') {
                    filteredResult.push(data[i])
                }
            }
        } else if (this.state.ultimateParentChecked && !this.state.publicCompanyChecked) {
            for (let i = 0; i < data.length; i++) {
                //Include only the results which have a parent id
                if (data[i].PARENT_COMPANY_ID == data[i].COMPANY_ID) {
                    filteredResult.push(data[i])
                }
            }
        }
        else if (!this.state.ultimateParentChecked && this.state.publicCompanyChecked) {
            for (let i = 0; i < data.length; i++) {
                //Include only the results which have a company type public
                if (data[i].COMPANY_TYPE == 'Public Company') {
                    filteredResult.push(data[i])
                }
            }
        }
        else {
            filteredResult = data;
        }
        this.setState({ searchResult: filteredResult }, this.loadPE_Data)
    }
    */
    search() {
        let searchText = this.state.searchText;
        //let sharedVs = this.state.valueStoriesShared;
        // console.log("String length: ", searchText.trim().length)
        if (searchText !== null && searchText.trim().length !== 0) {
            this.props.fetchValueStoriesLoading(true)
            this.setState({ valueStories: [], isError: false, message: '', vsLoad: 'true' })
            this.props.fetchValueStories(searchText)
                .then((result) => {
                    if (result.status === 200) {
                        this.props.fetchValueStoriesSuccess(result)
                        var obj = [];
                        for (var i = 0; i < result.data.Result.length; i++) {
                            if (result.data.Result[i].Spend) {
                                obj.push(result.data.Result[i])
                            }
                        }
                        let temp = obj;
                        let vsList;
                        temp.forEach((element, i) => {
                            element.content = false;
                            element.index = i;
                        });
                        //if (sharedVs.length > 0) {
                        //vsList = temp.filter(obj => !sharedVs.filter(xobj => obj.VSID == xobj.VSId).length)
                        //} else {
                        vsList = temp
                        //}
                        if (vsList.length > 0)
                            this.setState({ valueStories: vsList, message: '', searchText: searchText, vsLoad: '', isLoading: false, isError: false })
                        else
                            this.setState({ message: this.props.menu.valueStory.noResult, isLoading: false, isError: true })
                    }
                    else {
                        this.setState({
                            message: this.props.contentDefault.search.emailError, isLoading: false, isError: true
                        })
                    }
                }).catch((error) => {
                    setTimeout(() => {
                        this.props.fetchValueStoriesFailure(error.response.data.Message)
                    })
                    this.setState({
                        message: error.response.data.Message, isLoading: false, isError: true
                    })
                })
        }
    }

    RadioButtonChecked(e) {
        var companyName = [];
        let data = this.state.searchResult[e.currentTarget.value]
        if (data.AVATAR_INDUSTRY != null) {
            const industryIdx = this.props.contentData.industries.findIndex(item => data.AVATAR_INDUSTRY.toLowerCase() === item.toLowerCase());
            const companyData = this.props.userData;
            companyData.Industry = industryIdx;
            this.props.valueStoryFetchDataSuccess(companyData);
        }

        //this.props.capIqRadioButtonClicked(data)
        //After this send the data back to search results to populate company profile..................................

        this.setState({ checked: true, checkIndex: e.currentTarget.id, selectedData: data, isLoading: true, currencyCode: data.CURRENCY_CODE }, this.callMethods)
    }

    callMethods() {
        this.loadPE_Data();
        this.createCompanyProfileArray();
    }

    createCompanyProfileArray() {
        let id = this.state.selectedData.COMPANY_ID
        this.props.getSPCapitalIQData(id)
            .then((result) => {
                this.props.getSPCapitalIQSuccess(result)
                let data = result.data.Result;
                this.setState({ companyObj: data }, this.loadCompanyData)
            }).catch((error) => {
                this.props.getSPCapitalIQFailure(error.response.data.Message)
            })
    }

    loadPE_Data() {
        var result = (<div>
            {this.state.searchResult.map((data, index) => (
                <div className="pe-div">
                    <div className="company-name-row">
                        <div className="float-left collapse-expand">
                            <div className="float-left radio-btn">
                                <RadioButton className='radioButton'
                                    checked={this.state.checked && data.COMPANY_ID == this.state.checkIndex}
                                    id={data.COMPANY_ID}
                                    value={index}
                                    onClick={this.RadioButtonChecked}
                                    color="default"
                                />
                            </div>
                        </div>
                        <div className="float-left details-text">
                            <div>
                                <span className="dark">{data.COMPANY_NAME}</span>
                                <span> |  {this.props.content.capIdSearchText}: <span className="dark">{data.COMPANY_ID}</span></span>
                            </div>
                            <div>
                                <span>{data.COUNTRY_NAME}</span>
                                <span> |  {data.EXCHANGE_SYMBOL}: {data.STOCK_TICKER}</span>
                                <span> |  {this.props.content.capIndustryText}: {data.COMPANY_INDUSTRY_DESC}</span>
                            </div>
                            <div>
                                <span>{this.props.content.capParentCompanyText}: {data.PARENT_COMPANY_NAME}</span>
                                <span> | {"Parent Id"}: {data.PARENT_COMPANY_ID}</span>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>)
        this.setState({ peArray: result })
    }

    loadCompanyData() {
        var data = this.state.companyObj[0];
        this.props.capIQFetchData(data)


        const companyData = this.props.userData;
        companyData.CurrencyCode = data.CURRENCY_CODE;
        this.props.valueStoryFetchDataSuccess(companyData);

        var result = (<React.Fragment>
            {/* <p>Company Name: <span>{data.name}</span></p> */}
            <div className="company-info-row">
                <div className="grid-x">
                    <div className="medium-5 large-5 title">{this.props.company.COMPANY_NAME}</div>
                    <div className="medium-7 large-7 value">{data.COMPANY_NAME != null ? data.COMPANY_NAME : '-'}</div>
                </div>
                <div className="grid-x">
                    <div className="medium-5 large-5 title">{this.props.company.COMPANY_ADDRESS}</div>
                    <div className="medium-7 large-7 cell value">
                        {data.COMPANY_ADDRESS != null ? data.COMPANY_ADDRESS : '-'}
                    </div>
                </div>
                <div className="grid-x">
                    <div className="medium-5 large-5 title">{this.props.company.COMPANY_TYPE}</div>
                    <div className="medium-7 large-7 cell value">
                        {data.COMPANY_TYPE != null ? data.COMPANY_TYPE : '-'}
                    </div>
                </div>
                <div className="grid-x">
                    <div className="medium-5 large-5 title">{this.props.company.COMPANY_STATUS}</div>
                    <div className="medium-7 large-7 cell  value">
                        {data.COMPANY_STATUS != null ? data.COMPANY_STATUS : '-'}
                    </div>
                </div>
                <div className="grid-x">
                    <div className="medium-5 large-5 title">{this.props.company.EMPLOYEE_COUNT}</div>
                    <div className="medium-7 large-7 cell  value">
                        {data.EMPLOYEE_COUNT != null ? formatThousands(data.EMPLOYEE_COUNT) : '-'}
                    </div>
                </div>
                <div className="grid-x">
                    <div className="medium-5 large-5 title">{this.props.company.WEBPAGE}</div>
                    <div className="medium-7 large-7 cell blue value">
                        <a target="_blank" href={'https://' + data.WEBPAGE}>
                            {data.WEBPAGE != null ? data.WEBPAGE : '-'}</a>
                    </div>
                </div>
                <div className="grid-x">
                    <div className="medium-5 large-5 title">{this.props.company.FISCAL_YEAR}</div>
                    <div className="medium-7 large-7 cell  value">
                        {data.FISCAL_YEAR != null ? data.FISCAL_YEAR : '-'}
                    </div>
                </div>
                <div className="grid-x">
                    <div className="medium-5 large-5 title">{this.props.company.TOT_REV}</div>
                    <div className="medium-7 large-7 cell  value">
                        {data.TOT_REV != null ? this.addCurrencyMask(data.TOT_REV) : '-'}
                    </div>
                </div>
                <div className="grid-x">
                    <div className="medium-5 large-5 title">{this.props.company.GRO_MARG}</div>
                    <div className="medium-7 large-7 cell  value">
                        {data.GRO_MARG != null ? round(data.GRO_MARG, 2) + ' %' : '-'}
                    </div>
                </div>
                <div className="grid-x">
                    <div className="medium-5 large-5 title">{this.props.company.EBITDA_MARG}</div>
                    <div className="medium-7 large-7 cell  value">
                        {data.EBITDA_MARG != null ? round(data.EBITDA_MARG, 2) + ' %' : '-'}
                    </div>
                </div>
                <div className="grid-x">
                    <div className="medium-5 large-5 title">{this.props.company.DSO}</div>
                    <div className="medium-7 large-7 cell  value">
                        {data.DSO != null ? round(data.DSO, 2) : '-'}
                    </div>
                </div>
                <div className="grid-x">
                    <div className="medium-5 large-5 title">{this.props.company.DPO}</div>
                    <div className="medium-7 large-7 cell  value">
                        {data.DPO != null ? round(data.DPO, 2) : '-'}
                    </div>
                </div>
                <div className="grid-x">
                    <div className="medium-5 large-5 title">{this.props.company.DIO}</div>
                    <div className="medium-7 large-7 cell  value">
                        {data.DIO != null ? round(data.DIO, 2) : '-'}
                    </div>
                </div>
                <div className="grid-x">
                    <div className="medium-5 large-5 title">{this.props.company.CCC}</div>
                    <div className="medium-7 large-7 cell  value">
                        {data.CCC != null ? round(data.CCC, 2) : '-'}
                    </div>
                </div>
                <div className="grid-x">
                    <div className="medium-5 large-5 title">{this.props.company.COGS}</div>
                    <div className="medium-7 large-7 cell  value">
                        {data.COGS != null ? this.addCurrencyMask(data.COGS) : '-'}
                    </div>
                </div>
                <div className="grid-x">
                    <div className="medium-5 large-5 title">{this.props.company.SG_A}</div>
                    <div className="medium-7 large-7 cell  value">
                        {data.SG_A != null ? this.addCurrencyMask(data.SG_A) : '-'}
                    </div>
                </div>
                <div className="grid-x">
                    <div className="medium-5 large-5 title">{this.props.company.INV}</div>
                    <div className="medium-7 large-7 cell  value">
                        {data.INV != null ? this.addCurrencyMask(data.INV) : '-'}
                    </div>
                </div>
            </div>
        </React.Fragment>)

        this.setState({ companyDetails: result, isLoading: false })

    }


    handleSelectionChange(value) {
        this.setState({ selectedOption: value });
    }

    componentWillUnmount() {
        this.props.resetError()
    }

    render() {
        var EmailConfirmation = () => {
            var companyName = this.state.companyName
            return (
                <div className="grid-x">
                    <div className="medium-12">
                        <p>{this.props.contentDefault.search.emailAlert.replace("%CompanyName%", companyName)}</p>
                    </div>

                    <div className="medium-12 popupOkBtn">
                        <div className="float-right">
                            <div className="float-left">
                                <Button onClick={() => { this.triggerEmail(this.state.originatorEmail, this.state.VSID) }}
                                    content={this.props.menu.valueStory.yes}
                                    color
                                />
                            </div>
                            <div className="float-left">
                                <Button onClick={() => this.hideModal()}
                                    content={this.props.menu.valueStory.no}
                                    color
                                />
                            </div>
                        </div>
                    </div>

                </div >
            )
        }
        var RequestDetails = () => {
            return (
                <div className="grid-x">
                    <div className="medium-12">
                        <p dangerouslySetInnerHTML={{ __html: this.props.menu.valueStory.requestMessage }}></p>
                        <ul>
                            <li>
                                <b> {this.props.menu.valueStory.firstRequest}{":"}&nbsp;&nbsp;</b>
                                <span>{this.state.firstRequestMadeOn}</span>
                            </li>
                            <li>
                                <b> {this.props.menu.valueStory.secondRequest}{":"}&nbsp;&nbsp;</b>
                                <span>{this.state.secondRequestMadeOn}</span>
                            </li>
                            <li>
                                <b> {this.props.menu.valueStory.thirdRequest}{":"}&nbsp;&nbsp;</b>
                                <span>{this.state.thirdRequestMadeOn}</span>
                            </li>
                        </ul>
                    </div>
                    <div style={{ fontSize: '12px', paddingTop: '15px' }} className="medium-12">
                        <i>*{this.props.menu.valueStory.requestValidation}</i>
                    </div>
                    <div className="medium-12 popupOkBtn">
                        <div className="float-right pr10">
                            <Button onClick={() => this.hideRequestModal()}
                                content={this.props.menu.valueStory.ok}
                                color
                            />
                        </div>
                    </div>
                </div >
            )
        }
        return (
            <div id="capiq-search" className="treeContainer existing-valuestory-search-popup" style={{ marginTop: '-16px !important', width: '100%' }}>
                <div className="grid-x">
                    <div className="medium-12 ">
                        <div className="float-left">{this.props.menu.valueStory.existingValueStories}</div>
                        {/* <div className="float-right close-icon" onClick={this.closePopUp}>
                            <img width="10" src="images/close_inactive.svg" />
                        </div> */}
                    </div>
                    <div className="medium-12">
                        {/* <div className="medium-12 large-12">
                            <div className="label-searchBy">{this.props.content.searchBy}<span className="asterisk">*</span></div>
                            <div className="control-wrapper">
                                <Select
                                    className="border"
                                    value={this.state.searchOption.filter(option => option.label === this.state.selectedOption)}
                                    noOptionsMessage={() => null}
                                    onChange={(value) => this.handleSelectionChange(value.label)}
                                    options={this.state.searchOption}
                                    isSearchable={false}
                                    validateOn="change" styles={dropdownColourStyles}
                                />
                            </div>
                        </div> */}
                    </div>
                    <div className="medium-12">
                        <div className="grid-x">
                            <div className="medium-9 search-box">
                                {/* <div className="label">{this.props.content.capCompanyNameSearchText}</div> */}
                                <div className="control-wrapper">
                                    <input type='text' onKeyDown={(e) => this._handleKeyDown(e)} onChange={this.onSearchTextChangeEvent} value={this.state.searchText} />
                                    {/* <button onClick={() => this.search()} >Search</button> */}
                                    {/* <img width="14px" onClick={() => this.search()} src={this.state.searchFlag ? "/images/search_big_active.svg" : "/images/search_big_inactive.svg"} /> */}
                                </div>
                            </div>
                            <div className="medium-3 float-left pt5">
                                <Button
                                    onClick={() => this.search()}
                                    disabled={this.props.user.loading}
                                    content={this.props.menu.valueStory.search}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid-x" style={{ paddingTop: '5px' }}>
                    <div className="medium-12">
                        {/* <ScrollArea minScrollSize={40}
                            speed={0.8}
                            className="box medium-12"
                            contentClassName="content"
                            horizontal={false} > */}
                        {//this.props.search.isLoading ?
                            //<div className="loading loading--is-loading"><div className="loader" />Loading...</div> :
                            <div><ExistingValueStoryList
                                valueStories={this.state.valueStories}
                                isLoading={this.state.vsLoad}
                                content={this.props.contentVs}
                                contentData={this.props.contentData}
                                showModal={this.showModal}
                                hideModal={this.hideModal}
                                showRequestModal={this.showRequestModal}
                                hideRequestModal={this.hideRequestModal}
                            // EmailProgress={this.EmailProgress}
                            // isLoading={this.isLoading}
                            /></div>
                        }
                        {this.state.isError ? <div style={{ paddingLeft: '5px !important' }} className="errors">{this.state.message}</div> :
                            <div style={{ paddingLeft: '5px !important' }} className="success">{this.state.message}</div>}
                        {/* </ScrollArea> */}
                    </div>
                </div>
                <div className="grid-x grid-padding-x">
                    <Modal
                        ref={'modal'}
                        keyboard={() => this.callback()}
                        modalStyle={autoHeightModalStyle}
                        contentStyle={emailmodalContentStyle}>
                        {EmailConfirmation()}
                    </Modal>
                </div>
                <div className="grid-x grid-padding-x">
                    <Modal
                        ref={'requestDetails'}
                        keyboard={() => this.callback()}
                        modalStyle={autoHeightModalStyle}
                        contentStyle={emailmodalContentStyle}>
                        {RequestDetails()}
                    </Modal>
                </div>
                {this.state.valueStories.length > 0 ?
                    <div className="grid-x grid-padding-x">
                        <div className="medium-12">
                            <img width="23" src="images/email_inactive.svg" />
                            <span style={{ 'fontSize': '12px', 'paddingLeft': '5px' }}>{this.props.menu.valueStory.envelopText}</span>
                        </div>
                        <div className="medium-12 pt5">
                            <img width="23" src="images/shared-details.png" />
                            <span style={{ 'fontSize': '12px', 'paddingLeft': '5px' }}>{this.props.menu.valueStory.requestDetailsText}</span>
                        </div>
                    </div> : null
                }
                {/* <div className="grid-x">
                    <div className="medium-12 popupOkBtn">
                        <div className="float-right">
                            <div className="float-left">
                                <Button
                                    content={this.props.ui.cancel}
                                    color
                                    onClick={this.closePopUp}
                                />
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        language: state.language,
        user: state.default,
        content: state.content.discovery.form,
        data: state.treeData,
        firstSearchResult: state.searchData,
        search: state.searchResultData,
        ui: state.content.ui,
        company: state.content.capitalDetails,
        currencySymbols: state.content.CurrencySymbols,
        userData: state.valueStory,
        contentDefault: state.content,
        menu: state.content.menu
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCapIqSearchResults: (value, type, parentFlag, publicFlag) => dispatch(fetchCapIqSearchResults(value, type, parentFlag, publicFlag)),
        capIqSearchSuccess: (msg) => dispatch(capIqSearchSuccess(msg)),
        capIqSearchFailure: (error) => dispatch(capIqSearchFailure(error)),
        searchResult: (result) => dispatch(searchResult(result)),
        getSPCapitalIQSuccess: message => dispatch(getSPCapitalIQSuccess(message)),
        getSPCapitalIQFailure: error => dispatch(getSPCapitalIQFailure(error)),
        getSPCapitalIQLoading: bool => dispatch(getSPCapitalIQLoading(bool)),
        getSPCapitalIQData: (CapIQID) => dispatch(getSPCapitalIQData(CapIQID)),
        capIQFetchData: (capIQData) => dispatch(capIQFetchData(capIQData)),
        valueStoryFetchDataSuccess: (valueStory) => dispatch(valueStoryFetchDataSuccess(valueStory)),
        resetError: () => dispatch(resetError()),
        fetchValueStoriesLoading: (bool) => dispatch(fetchValueStoriesLoading(bool)),
        fetchValueStoriesSuccess: (valueStories) => dispatch(fetchValueStoriesSuccess(valueStories)),
        fetchValueStoriesFailure: (error) => dispatch(fetchValueStoriesFailure(error)),
        requestValueStoryLoading: (bool) => dispatch(requestValueStoryLoading(bool)),
        requestValueStorySuccess: (valueStories) => dispatch(requestValueStorySuccess(valueStories)),
        requestValueStoryFailure: (error) => dispatch(requestValueStoryFailure(error)),
        fetchValueStories: (id) => dispatch(fetchValueStories(id)),
        //fetchUserSharedValueStories: (id) => dispatch(fetchUserSharedValueStories(id)),
        requestValueStory: (originatorEmail, requesterEmail, VSID, language) => dispatch(requestValueStory(originatorEmail, requesterEmail, VSID, language)),
        fetchRequestDetails: (VSID, requesterEmail) => dispatch(fetchRequestDetails(VSID, requesterEmail))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DiscoveryVsSearch);
