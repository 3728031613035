import React, { Component } from 'react'
import { connect } from 'react-redux'
import PopupButton from 'UI/PopupButton'
import Select from 'react-select'
import * as d3 from 'd3'
import StackedBarChart from 'components/UI/StackedBarChart'
import SupplierScopeDetails from './SupplierScopeDetails'
import TreeMap from './TreemapD3'
import ZoomModal from 'components/UI/ZoomModal'
import _ from 'lodash';
import Modal from 'reboron/OutlineModal'
import {
	defineScopeSuccess,
	defineScopeLoading,
	resetError,
	defineScopeFailure,
	currentActiveRequest,
	getIndustrySupplierDetails,
	getTopDetails,
	getSupplierCountryCodes,
	getSummaryScopeSolution,
	getSupplierScopeDetails,
	storeSupplierSKEY,
	storeIndustryGICS_ID,
	trackSupplierDrillDownChanges,
	trackSupplierDrillDownSave,
	trackIndustryDrillScopeChanges
	//getDefineScopesDetails
} from 'actions/spendActions'
import { convertToMillion, formatThousands } from 'utils/Utils'
import { object } from 'prop-types';
import {
	suggestedValueModalStyle,
	suggestedValueContentStyle,
	path, backdropStyle
} from 'Constants'
import { modalStyle, modalContentStyle, changeModalStyle, changeModalContentStyle } from 'Constants'
import SupplierDrillDownPopup from '../SupplierDrillDownPopup'
import SupplierIndustriesPopup from '../SupplierIndustriesPopup'
import Button from 'UI/Button'
import VideoHelp from 'components/UI/VideoHelp'
import { MultiSelect } from 'primereact/multiselect';

const changeBackdropStyle = Object.assign({}, backdropStyle);
changeBackdropStyle.zIndex = '1050';

const alertModalStyle = Object.assign({}, changeModalStyle);
alertModalStyle.zIndex = '1060';

//@withStyles(styles)
class IndustryBreakdownContainer extends Component {
	_isMounted = false;
	constructor(props) {
		super(props)
		this.state = {
			RequestIds: [],
			selectedScopes: '',
			fileDetails: [],
			scopes: [],
			currentActiveRequest: '',
			allCountries: [],
			allBuyerRegion: [],
			allBusinessUnit: [],
			topCount: [],
			summaryScopeData: [],
			supplierData: [],
			industrySupplierDetailsAllData: '',
			industrySupplierDetailsFilterData: '',
			isFilterChanges: false,
			industryLevelList: [
				{ label: '2', value: 'L2_INDUSTRY_NAME' },
				{ label: '3', value: 'L3_INDUSTRY_NAME' },
				{ label: '4', value: 'L4_INDUSTRY_NAME' },
				{ label: '5', value: 'L5_INDUSTRY_NAME' },
				{ label: '6', value: 'L6_INDUSTRY_NAME' },
				{ label: '7', value: 'L7_INDUSTRY_NAME' }
			],
			defineScopeFilters: {
				country: ['all'],
				industryLevel: "L3_INDUSTRY_NAME",
				industryName: '',
				solution: ['No Filter'],
				outSolution: ['No Filter'],
				solutionConfirmation: 'all',
				buyerRegion: ['all'],
				businessUnit: ['all'],
				goods: 'all',
				directSpend: 'all',
				metricsType: "Invoice Spend",
				aribaNetwork: "all",
				sapAribaBuyProcess: "all",
				topRecords: 20,
				GICS_ID: ''
			},

			industryLeveldrpdwn: "L3_INDUSTRY_NAME",
			defineScope: {
				DMC_ID: this.props.spendDetails !== undefined && this.props.spendDetails.currentActiveRequest !== undefined ? this.props.spendDetails.currentActiveRequest.DMC_ID : 0
				, VS_ID: this.props.spendDetails !== undefined && this.props.spendDetails.currentActiveRequest !== undefined ? this.props.spendDetails.currentActiveRequest.VS_ID : 0,
				User_Email: '',
				Solution: ['No Filter'],
				Country: ['all'],
				SolutionConfirmation: 'all',
				GICS_ID: 'all',
				Buyer_Region: ['all'],
				Business_Unit: ['all'],
				Goods: 'all',
				DirectSpend: 'all',
				MetricsType: "Invoice Spend",
				AribaNetwork: "all",
			},
			allSolutions: [
				{ label: "No Filter", value: "No Filter" },
				{ label: "Sourcing", value: "Sourcing" },
				{ label: "Commerce Automation", value: "Commerce Automation" },
				{ label: "Discount/SCF", value: "Discount/SCF" },
				{ label: "Concur", value: "Concur" },
				{ label: "Fieldglass", value: "Fieldglass" },
				{ label: "Supply Chain Collaboration", value: "Supply Chain Collaboration" },
				{ label: "Buying and Invoicing", value: "Buying and Invoicing" },
				{ label: "Contract Invoicing", value: "Contract Invoicing" },
				{ label: "Spot Buy", value: "Spot Buy" },
				{ label: "No solutions", value: "No solutions" },
				{ label: "Any solutions", value: "Any solutions" }
			],
			allSolutionsOut: [
				{ label: "No Filter", value: "No Filter" },
				{ label: "Sourcing", value: "Out Sourcing" },
				{ label: "Commerce Automation", value: "Out Commerce Automation" },
				{ label: "Discount/SCF", value: "Out Discount/SCF" },
				{ label: "Concur", value: "Out Concur" },
				{ label: "Fieldglass", value: "Out Fieldglass" },
				{ label: "Supply Chain Collaboration", value: "Out Supply Chain Collaboration" },
				{ label: "Buying and Invoicing", value: "Out Buying and Invoicing" },
				{ label: "Contract Invoicing", value: "Out Contract Invoicing" },
				{ label: "Spot Buy", value: "Out Spot Buy" },
			],
			optionSolutionConfirmation: [
				{ label: "Not Confirmed", value: "No" },
				{ label: "Confirmed", value: "Yes" },
				{ label: "All", value: "all" }
			],
			directSpendOptions: [
				{ label: "All", value: "all" },
				{ label: "Yes", value: "Yes" },
				{ label: "No", value: "No" }
			],
			goodsOptions: [
				{ label: "All", value: "all" },
				{ label: "Goods", value: "Goods" },
				{ label: "Services", value: "Services" },
				{ label: "Both", value: "Both" },
				{ label: "Unclassified", value: "Unclassified" },
			],
			metricsOptions: [
				{ label: "Invoice Spend", value: "Invoice Spend" },
				{ label: "Invoice Count", value: "Invoice Count" },
				{ label: "PO Spend", value: "PO Spend" },
				{ label: "PO Count", value: "PO Count" },
			],
			aribaNetworkOptions: [
				{ label: "All", value: "all" },
				{ label: "Matched", value: "Matched" },
				{ label: "Matched & Transacting", value: "Matched & Transacting" },
				{ label: "Not Matched", value: "Not Matched" }
			],
			sapAribaBuyProcessOptions: [
				{ label: "All", value: "all" },
				{ label: "Consultancy/SOW Services: Active Mgmt, otherwise Invoice Against Contract", value: "Consultancy/SOW Services: Active Mgmt, otherwise Invoice Against Contract" },
                { label: "Direct/MRO: Plan Driven PO", value: "Direct/MRO: Plan Driven PO" },
                { label: "Invoice Against Contract", value: "Invoice Against Contract" },
                { label: "Non-PO Invoice", value: "Non-PO Invoice" },
                { label: "Other Services: Active Mgmt, otherwise Invoice Against Contract", value: "Other Services: Active Mgmt, otherwise Invoice Against Contract" },
                { label: "Requisition: Non-Catalog or Tactical Sourcing", value: "Requisition: Non-Catalog or Tactical Sourcing" },
                { label: "Requisition: External Talent", value: "Requisition: External Talent" },
                { label: "Requisition: Catalog, Spot-Buy or Release Contract", value: "Requisition: Catalog, Spot-Buy or Release Contract" },
                { label: "Travel and Expense", value: "Travel and Expense" }
			],
			breadCrumbsArray: [],
			aggregatedAmount: {
				invoice_spend: 0,
				invoice_count: 0,
				po_spend: 0,
				po_count: 0,
				unique_supplier: 0
			},
			currencySymbol: '',
			open: false,
			openIndustryPopup: false,
			clickedIndustryDetails: [],
			showFlag: false,
			solutionFilter: 'No Filter',
			outSolutionFilter: 'No Filter',
			countryFilter: 'all',
			businessUnitFilter: 'all',
			buyerRegionFilter: 'all',
			levelDisabled: false,
			isDataExists: true
		}
		this.handlelangChange = this.handlelangChange.bind(this)
		this.filterAndGroupData = this.filterAndGroupData.bind(this)
		this.onClick_TreeMap = this.onClick_TreeMap.bind(this)
		this.onDoubleClickTreeMap = this.onDoubleClickTreeMap.bind(this)
		this.handleIndustryLevelChange = this.handleIndustryLevelChange.bind(this)
		this.handleTopCountChange = this.handleTopCountChange.bind(this)
		this.removeIndustryFilter = this.removeIndustryFilter.bind(this)
		this.isClickableTile = this.isClickableTile.bind(this);
		this.getRankColumnName = this.getRankColumnName.bind(this)
		this.showSupplierDrilldownModal = this.showSupplierDrilldownModal.bind(this)
		this.setVisibility = this.setVisibility.bind(this)
		this.outsidePopupClick = this.outsidePopupClick.bind(this)
		this.showAlertModal = this.showAlertModal.bind(this)
		this.hideAlertModal = this.hideAlertModal.bind(this)
		this.continueNavigation = this.continueNavigation.bind(this)
		this.showSupplierIndustriesModal = this.showSupplierIndustriesModal.bind(this)
		this.setFilter = this.setFilter.bind(this)
		this.toggleShow = this.toggleShow.bind(this)
		this.triggerAllMethod = this.triggerAllMethod.bind(this)
		this.clickedOutside = this.clickedOutside.bind(this)
	}

	componentDidMount() {
		this._isMounted = true;
		this.loadData()
		window.scrollTo(0, 0);
	}
	componentWillUnmount() {
		this._isMounted = false;
		this.props.resetError();
	}
	getMessage(keyToFindMessage) {
		return this.props.content[keyToFindMessage];
	}

	toggleShow() {
		this.setState({ showFlag: !this.state.showFlag })
	}

	loadData() {
		this.props.defineScopeLoading(true)

		this.bindTopValues();
		this.bindContryList();

		this.getIndustrySupplierDetails();
		this.getSummaryScopeSolution();
		this.getSupplierScopeDetails();
		if (this._isMounted) {
			this.setState({
				currencySymbol: this.props.currencySymbols[this.props.spendDetails.currentActiveRequest.Currency !== undefined 
					&& this.props.spendDetails.currentActiveRequest.Currency.trim() !== '' 
					&& this.props.spendDetails.currentActiveRequest.Currency.trim() !== "0" ?
					this.props.spendDetails.currentActiveRequest.Currency.trim() : 'USD']
			})
		}

	}

	getIndustrySupplierDetails(isTopRequest) {
		var defineScope_obj = this.state.defineScope;
		defineScope_obj.User_Email = this.props.spendDetails !== undefined &&
			this.props.spendDetails.currentActiveRequest !== undefined ? (
				this.props.spendDetails.currentActiveRequest.SAPRequester === this.props.user.user.Email ?
					this.props.user.user.Email : this.props.spendDetails.currentActiveRequest.SAPRequester) : '';
		defineScope_obj.Country = this.state.countryFilter;
		defineScope_obj.Solution = this.state.solutionFilter;
		defineScope_obj.outSolution = this.state.outSolutionFilter;
		defineScope_obj.SolutionConfirmation = this.state.defineScopeFilters.solutionConfirmation === '' ? defineScope_obj.SolutionConfirmation : this.state.defineScopeFilters.solutionConfirmation;
		defineScope_obj.DirectSpend = this.state.defineScopeFilters.directSpend === '' ? defineScope_obj.DirectSpend : this.state.defineScopeFilters.directSpend;
		defineScope_obj.Goods = this.state.defineScopeFilters.goods === '' ? defineScope_obj.Goods : this.state.defineScopeFilters.goods;
		defineScope_obj.Business_Unit = this.state.businessUnitFilter;
		defineScope_obj.Buyer_Region = this.state.buyerRegionFilter;
		defineScope_obj.MetricsType = this.state.defineScopeFilters.metricsType === '' ? defineScope_obj.MetricsType : this.state.defineScopeFilters.metricsType;
		defineScope_obj.AribaNetwork = this.state.defineScopeFilters.aribaNetwork === '' ? defineScope_obj.AribaNetwork : this.state.defineScopeFilters.aribaNetwork;
		defineScope_obj.SAPAribaBuyProcess = this.state.defineScopeFilters.sapAribaBuyProcess === '' ? defineScope_obj.SAPAribaBuyProcess : this.state.defineScopeFilters.sapAribaBuyProcess;

		this.props.getIndustrySupplierDetails(defineScope_obj)
			.then((response) => {
				if (response.status === 200 && this._isMounted) {
					this.setState({ industrySupplierDetailsAllData: response.data.Result },
						() => {
							this.filterAndGroupData(isTopRequest)
							//this.props.defineScopeSuccess('')
						})
					//this.filterAndGroupData();

				}

			})
			.catch((exception) => {
				this.props.defineScopeFailure(this.getMessage('noData'))
			})
	}

	bindTopValues() {
		var topValuesArray = this.props.content.topValues.toString().split(',')
		var listToBindTopValues = []
		for (let index = 0; index < topValuesArray.length; index++) {
			const element = topValuesArray[index]
			listToBindTopValues.push({ label: element, value: element })
		}

		this.setState({ topCount: listToBindTopValues })
	}

	handleIndustryLevelChange(value) {
		var defineScopeFilters = this.state.defineScopeFilters
		defineScopeFilters.industryLevel = value.value;

		if (defineScopeFilters.industryName !== "") {
			//pass GICS_ID to barchart and supplier grid to fecth data accordingly
			var industryData = this.state.industrySupplierDetailsAllData.
				filter((item) => item[defineScopeFilters.industryLevel] === defineScopeFilters.industryName);
			if (industryData !== undefined && industryData.length > 0) {
				defineScopeFilters.GICS_ID = defineScopeFilters.industryLevel === "L2_INDUSTRY_NAME" ? 'all'
					: defineScopeFilters.industryLevel === "L3_INDUSTRY_NAME" ? industryData[0].L2_INDUSTRY_ID
						: defineScopeFilters.industryLevel === "L4_INDUSTRY_NAME" ? industryData[0].L3_INDUSTRY_ID
							: defineScopeFilters.industryLevel === "L5_INDUSTRY_NAME" ? industryData[0].L4_INDUSTRY_ID
								: defineScopeFilters.industryLevel === "L6_INDUSTRY_NAME" ? industryData[0].L5_INDUSTRY_ID : industryData[0].L6_INDUSTRY_ID

				defineScopeFilters.industryName = defineScopeFilters.industryLevel === "L2_INDUSTRY_NAME" ? industryData[0].L2_INDUSTRY_NAME
					: defineScopeFilters.industryLevel === "L3_INDUSTRY_NAME" ? industryData[0].L3_INDUSTRY_NAME
						: defineScopeFilters.industryLevel === "L4_INDUSTRY_NAME" ? industryData[0].L4_INDUSTRY_NAME
							: defineScopeFilters.industryLevel === "L5_INDUSTRY_NAME" ? industryData[0].L5_INDUSTRY_NAME
								: defineScopeFilters.industryLevel === "L6_INDUSTRY_NAME" ? industryData[0].L6_INDUSTRY_NAME : industryData[0].L7_INDUSTRY_NAME
			}
			else {
				defineScopeFilters.GICS_ID = 'all'
				defineScopeFilters.industryName = ''
			}
		}
		var breadCrumbsArray = this.state.breadCrumbsArray
		var clickedIndustryDetails = this.state.clickedIndustryDetails
		if (value.label === "2") {
			defineScopeFilters.industryName = "";
			breadCrumbsArray = [];
			clickedIndustryDetails = []
		}
		else {

		}
		//this.state.breadCrumbsArray

		this.setState({
			breadCrumbsArray: breadCrumbsArray,
			clickedIndustryDetails: clickedIndustryDetails,
			defineScopeFilters: defineScopeFilters,
			industryLeveldrpdwn: value.value,
			isFilterChanges: true
		})//, () => this.triggerAllMethod(true, false))
	}

	handleTopCountChange(data) {
		var defineScopeFilters = this.state.defineScopeFilters
		defineScopeFilters.topRecords = data.value
		this.setState({ defineScopeFilters: defineScopeFilters }, () => this.filterAndGroupData(true))
	}

	removeIndustryFilter(item, index, refreshPagefromPopUP) {
		var breadCrumbsArray = this.state.breadCrumbsArray
		//var index = breadCrumbsArray.findIndex(industry => industry === item)
		var defineScopeFilters = this.state.defineScopeFilters

		//pass GICS_ID to barchart and supplier grid to fecth data accordingly
		var industryData = this.state.industrySupplierDetailsAllData !== null ? this.state.industrySupplierDetailsAllData.filter((item) => item[defineScopeFilters.industryLevel] === defineScopeFilters.industryName): undefined;

		if (industryData !== undefined && industryData.length > 0) {
			defineScopeFilters.GICS_ID = defineScopeFilters.industryLevel === "L2_INDUSTRY_NAME" ? 'all'
				: defineScopeFilters.industryLevel === "L3_INDUSTRY_NAME" ? industryData[0].L2_INDUSTRY_ID
					: defineScopeFilters.industryLevel === "L4_INDUSTRY_NAME" ? industryData[0].L3_INDUSTRY_ID
						: defineScopeFilters.industryLevel === "L5_INDUSTRY_NAME" ? industryData[0].L4_INDUSTRY_ID
							: defineScopeFilters.industryLevel === "L6_INDUSTRY_NAME" ? industryData[0].L5_INDUSTRY_ID : industryData[0].L6_INDUSTRY_ID
		} else {
			defineScopeFilters.GICS_ID = 'all'
		}
		var breadCrumbsArray_new = []
		var col = 'L3_INDUSTRY_NAME'
		var previousLevelColName = this.state.industryLevelList.findIndex((item) => item.value === this.state.industryLeveldrpdwn);
		col = previousLevelColName >= 1 ? this.state.industryLevelList[previousLevelColName - 1].value : this.state.industryLevelList[previousLevelColName].value
		// defineScopeFilters.industryLevel = col
		if (index > 0) {
			breadCrumbsArray_new = breadCrumbsArray.filter((a, ind) =>

				ind !== index
			);

			defineScopeFilters.industryName = breadCrumbsArray_new[breadCrumbsArray_new.length - 1].industryName
			defineScopeFilters.industryLevel = breadCrumbsArray_new[breadCrumbsArray_new.length - 1].industryLevel
			// var previousLevelColName = this.state.industryLevelList.findIndex((item) => item.value === this.state.industryLeveldrpdwn);
			// col = this.state.industryLevelList[previousLevelColName - 1].value
		}
		else {
			defineScopeFilters.industryName = ''
			defineScopeFilters.GICS_ID = 'all'
		}

		let levelFlag = breadCrumbsArray_new.length > 0 ? true : false;
		if (refreshPagefromPopUP === true) {
			this.setState({ breadCrumbsArray: breadCrumbsArray_new, defineScopeFilters: defineScopeFilters, industryLeveldrpdwn: col, levelDisabled: levelFlag })
			Object.assign(this.state, { breadCrumbsArray: breadCrumbsArray_new })
		}
		else {
			this.state.clickedIndustryDetails.splice(this.state.clickedIndustryDetails.length - 1, 1)
			var finalBreadcrumbs = this.state.clickedIndustryDetails
			this.setState({
				breadCrumbsArray: breadCrumbsArray_new, defineScopeFilters: defineScopeFilters, industryLeveldrpdwn: col,
				clickedIndustryDetails: finalBreadcrumbs, levelDisabled: levelFlag
			},
				() => this.triggerAllMethod(true, false))
			Object.assign(this.state, { breadCrumbsArray: breadCrumbsArray_new, clickedIndustryDetails: finalBreadcrumbs })
		}
	}


	bindContryList() {
		var defineScope = this.state.defineScope
		this.props.getSupplierCountryCodes(defineScope).then((response) => {
			var allCountries = [{ label: "All countries", value: "all" }];
			let allBusinessUnit = [{ label: "All Business Units", value: "all" }];
			let allBuyerRegion = [{ label: "All Buyer Regions", value: "all" }];
			if (response.status === 200 && response.data.Result !== null) {
				if (response.data.Result.Table !== null) {
					response.data.Result.Table.forEach(itemCN => {
						let value = Object.values(itemCN)
						allCountries.push({ label: value[0], value: value[0] });
					});
				}

				if (response.data.Result.Table2 !== null) {
					response.data.Result.Table2.forEach(itemBU => {
						let value = Object.values(itemBU)
						allBusinessUnit.push({ label: value[0], value: value[0] });
					});
				}

				if (response.data.Result.Table1 !== null) {
					response.data.Result.Table1.forEach(itemBR => {
						let value = Object.values(itemBR)
						allBuyerRegion.push({ label: value[0], value: value[0] });
					});
				}

			}
			this.setState({ allCountries: allCountries, allBusinessUnit: allBusinessUnit, allBuyerRegion: allBuyerRegion });


		}).catch((exception) => {
			//console.log(exception);
		})
	}

	handleCountryChange(data) {
		var defineScopeFilters = this.state.defineScopeFilters
		defineScopeFilters.country = data.value.length > 0 ? data.value : ['all'];
		if (data.value.length > 1 && data.value[0] == 'all') {
			data.value.shift()
			defineScopeFilters.country = data.value
		}
		if (data.value.length > 1 && data.value.includes('all')) {
			defineScopeFilters.country = [];
			defineScopeFilters.country = ['all'];
		}
		let str = defineScopeFilters.country.toString()
		this.setState({ defineScopeFilters: defineScopeFilters, countryFilter: str, isFilterChanges:true })
	}

	handleSolutionChange(data) {
		var defineScopeFilters = this.state.defineScopeFilters
		defineScopeFilters.solution = data.value.length > 0 ? data.value : ['No Filter'];
		if (data.value.length > 1 && data.value[0] == 'No Filter') {
			data.value.shift()
			defineScopeFilters.solution = data.value
		}
		if (data.value.length > 1 && data.value.includes('No Filter')) {
			defineScopeFilters.solution = [];
			defineScopeFilters.solution = ['No Filter'];
		}
		if (data.value.includes('No solutions') && data.value.includes('Any solutions')) {
			defineScopeFilters.solution = []
			defineScopeFilters.solution = [data.value[1]]
		} else if (data.value.includes('No solutions')) {
			defineScopeFilters.solution = []
			defineScopeFilters.solution = ['No solutions']
		}
		else if (data.value.includes('Any solutions')) {
			defineScopeFilters.solution = []
			defineScopeFilters.solution = ['Any solutions']
		}
		let str = defineScopeFilters.solution.toString()
		this.setState({ defineScopeFilters: defineScopeFilters, solutionFilter: str, isFilterChanges:true })
	}

	handleOutSolutionChange(data) {
		var defineScopeFilters = this.state.defineScopeFilters
		defineScopeFilters.outSolution = data.value.length > 0 ? data.value : ['No Filter'];
		if (data.value.length > 1 && data.value[0] == 'No Filter') {
			data.value.shift()
			defineScopeFilters.outSolution = data.value
		}
		if (data.value.length > 1 && data.value.includes('No Filter')) {
			defineScopeFilters.outSolution = [];
			defineScopeFilters.outSolution = ['No Filter'];
		}
		if (data.value.includes('No solutions') && data.value.includes('Any solutions')) {
			defineScopeFilters.outSolution = []
			defineScopeFilters.outSolution = [data.value[1]]
		} else if (data.value.includes('No solutions')) {
			defineScopeFilters.outSolution = []
			defineScopeFilters.outSolution = ['No solutions']
		}
		else if (data.value.includes('Any solutions')) {
			defineScopeFilters.outSolution = []
			defineScopeFilters.outSolution = ['Any solutions']
		}
		let str = defineScopeFilters.outSolution.toString()
		this.setState({ defineScopeFilters: defineScopeFilters, outSolutionFilter: str, isFilterChanges:true })
	}

	clickedOutside() {
		this.triggerAllMethod(true, true)
		this.setState({isFilterChanges:false})
	}


	clickedResetFilter() {
		var reset_defineScopeFilters = this.state.defineScopeFilters;
		reset_defineScopeFilters.country = ['all'];
		reset_defineScopeFilters.industryLevel = "L3_INDUSTRY_NAME";
		reset_defineScopeFilters.industryName = '';
		reset_defineScopeFilters.solution = ['No Filter'];
		reset_defineScopeFilters.outSolution = ['No Filter'];
		reset_defineScopeFilters.solutionConfirmation = 'all';
		reset_defineScopeFilters.buyerRegion = ['all'];
		reset_defineScopeFilters.businessUnit = ['all'];
		reset_defineScopeFilters.goods = 'all';
		reset_defineScopeFilters.directSpend = 'all';
		reset_defineScopeFilters.metricsType = "Invoice Spend";
		reset_defineScopeFilters.aribaNetwork = "all";
		reset_defineScopeFilters.sapAribaBuyProcess = "all";
		reset_defineScopeFilters.topRecords = 20;
		reset_defineScopeFilters.GICS_ID = ''
		this.removeIndustryFilter('all', 0, false)
		this.setState({
			defineScopeFilters: reset_defineScopeFilters, industryLeveldrpdwn: "L3_INDUSTRY_NAME",
			solutionFilter: 'No Filter', outSolutionFilter: 'No Filter', countryFilter: 'all', businessUnitFilter: 'all', buyerRegionFilter: 'all'
		}, () => this.clickedOutside());
		//this.clickedOutside();
	}

	handleSolutionConfirmationChange(data) {
		var defineScopeFilters = this.state.defineScopeFilters
		defineScopeFilters.solutionConfirmation = data.value
		this.setState({ defineScopeFilters: defineScopeFilters, isFilterChanges:true })
	}

	handleBusinessChange(data) {
		var defineScopeFilters = this.state.defineScopeFilters
		defineScopeFilters.businessUnit = data.value.length > 0 ? data.value : ['all'];
		if (data.value.length > 1 && data.value[0] == 'all') {
			data.value.shift()
			defineScopeFilters.businessUnit = data.value
		}
		if (data.value.length > 1 && data.value.includes('all')) {
			defineScopeFilters.businessUnit = [];
			defineScopeFilters.businessUnit = ['all'];
		}
		let str = defineScopeFilters.businessUnit.toString();
		this.setState({ defineScopeFilters: defineScopeFilters, businessUnitFilter: str, isFilterChanges:true })
	}

	handleBuyerChange(data) {
		var defineScopeFilters = this.state.defineScopeFilters
		defineScopeFilters.buyerRegion = data.value.length > 0 ? data.value : ['all'];
		if (data.value.length > 1 && data.value[0] == 'all') {
			data.value.shift()
			defineScopeFilters.buyerRegion = data.value
		}
		if (data.value.length > 1 && data.value.includes('all')) {
			defineScopeFilters.buyerRegion = [];
			defineScopeFilters.buyerRegion = ['all'];
		}
		let str = defineScopeFilters.buyerRegion.toString();
		this.setState({ defineScopeFilters: defineScopeFilters, buyerRegionFilter: str, isFilterChanges:true })
	}

	handleDirectSpendChange(data) {
		var defineScopeFilters = this.state.defineScopeFilters
		defineScopeFilters.directSpend = data.value == "" ? "all" : data.value;
		this.setState({ defineScopeFilters: defineScopeFilters, isFilterChanges:true })
	}

	handleGoodsChange(data) {
		var defineScopeFilters = this.state.defineScopeFilters
		defineScopeFilters.goods = data.value
		this.setState({ defineScopeFilters: defineScopeFilters, isFilterChanges:true })
	}

	handleMetricsChange(data) {
		var defineScopeFilters = this.state.defineScopeFilters
		defineScopeFilters.metricsType = data.value

		this.setState({ defineScopeFilters: defineScopeFilters, isFilterChanges:true })
	}

	handleAribaNetworkChange(data) {
		var defineScopeFilters = this.state.defineScopeFilters
		defineScopeFilters.aribaNetwork = data.value
		this.setState({ defineScopeFilters: defineScopeFilters, isFilterChanges:true })
	}

	handleSAPAribaBuyProcessChange(data) {
		var defineScopeFilters = this.state.defineScopeFilters
		defineScopeFilters.sapAribaBuyProcess = data.value
		this.setState({ defineScopeFilters: defineScopeFilters, isFilterChanges:true })
	}

	filterAndGroupData(isTopRequest) {
		var _instance = this;
		if (this.state.industrySupplierDetailsAllData !== null && this.state.industrySupplierDetailsAllData !== undefined && this._isMounted) {
			var allData = this.state.industrySupplierDetailsAllData;
			var treeFormatData = [];
			var allotherInvoiceSpend = 0
			var topRecords = [];
			var industryLeveldrpdwn = this.state.industryLeveldrpdwn
			var defineScopeFilters = this.state.defineScopeFilters;
			var breadCrumbs = this.state.breadCrumbsArray;
			Object.keys(defineScopeFilters).map((filter) => {
				switch (filter) {

					case "industryLevel": {
						var removeNullEntries = allData.filter(item => item[defineScopeFilters[filter]] !== null)
						var groups = _.groupBy(removeNullEntries, defineScopeFilters[filter]);

						if (defineScopeFilters.industryName === "") {

							Object.keys(groups).map((element) => {
								var isclick = false
								var child_INVOICE_SPEND = 0;
								var child_INVOICE_COUNT = 0;
								var child_PO_SPEND = 0;
								var child_PO_COUNT = 0;
								var child_UNIQUE_SUPPLIER = 0;
								var rank = 0
								for (let index = 0; index < groups[element].length; index++) {
									isclick = this.isClickableTile("L2_INDUSTRY_NAME", groups[element][index])
									const childElement = groups[element][index];
									child_INVOICE_SPEND = child_INVOICE_SPEND + childElement.INVOICE_SPEND;
									child_INVOICE_COUNT = child_INVOICE_COUNT + childElement.INVOICE_COUNT;
									child_PO_SPEND = child_PO_SPEND + childElement.PO_SPEND;
									child_PO_COUNT = child_PO_COUNT + childElement.PO_COUNT;
									child_UNIQUE_SUPPLIER = child_UNIQUE_SUPPLIER + childElement.UNIQUE_SUPPLIERS;
									rank = childElement[_instance.getRankColumnName(defineScopeFilters[filter])]
								}
								if (treeFormatData.findIndex((ele) => ele.name === element) < 0) {
									treeFormatData.push({
										name: element, value: child_INVOICE_SPEND, clickable: isclick,
										invoiceSpend: (child_INVOICE_SPEND), invoiceCount: (child_INVOICE_COUNT),
										poSpend: (child_PO_SPEND), poCount: (child_PO_COUNT),
										uniqueSupplier: (child_UNIQUE_SUPPLIER),
										rank: rank
									})
								}
								else {
									var existingEntry = treeFormatData.filter((ele) => ele.name === element);
									var updatedEntry = [{
										name: element,
										value: existingEntry[0].value + (child_INVOICE_SPEND),
										clickable: isclick,
										invoiceSpend: existingEntry[0].invoiceSpend + (child_INVOICE_SPEND),
										invoiceCount: existingEntry[0].invoiceCount + (child_INVOICE_COUNT),
										poSpend: existingEntry[0].poSpend + (child_PO_SPEND),
										poCount: existingEntry[0].poCount + (child_PO_COUNT),
										uniqueSupplier: existingEntry[0].uniqueSupplier + (child_UNIQUE_SUPPLIER),
										rank: rank
									}]
									var index = treeFormatData.findIndex((ele) => ele.name === element)
									treeFormatData[index] = updatedEntry;
								}
							})
						}
						else {
							treeFormatData = groups;
						}
					}
						break;
					case "industryName":
						var col = ''
						var nextLevelColName = this.state.industryLevelList.findIndex((item) => item.value === this.state.industryLeveldrpdwn);
						if (!isTopRequest) {
							col = this.state.industryLevelList[nextLevelColName + 1]
						}
						else {
							col = this.state.industryLevelList[nextLevelColName]
						}

						if (defineScopeFilters[filter] !== "" && col !== undefined) {
							var groups = treeFormatData[defineScopeFilters[filter]];
							if (groups === undefined) {
								groups = []
								Object.values(treeFormatData).map((ele) => {

									for (let index = 0; index < ele.length; index++) {
										const element = ele[index];
										if (element[breadCrumbs[breadCrumbs.length - 1].industryLevel] ===
											breadCrumbs[breadCrumbs.length - 1].industryName) {
											groups.push(element)
										}
									}

								})
							}

							if (groups !== undefined) {
								treeFormatData = [];
								industryLeveldrpdwn = col.value
								this.setState({ industryLeveldrpdwn: industryLeveldrpdwn })
								Object.keys(groups).map((element) => {
									if (groups[element][col.value] !== null && groups[element][col.value] !== undefined) {
										var _rank = groups[element][_instance.getRankColumnName(col.value)]
										if (treeFormatData.findIndex((ele) => ele.name === groups[element][col.value]) < 0) {
											treeFormatData.push({
												name: groups[element][col.value],
												value: (groups[element].INVOICE_SPEND),
												clickable: _instance.isClickableTile(col.value, groups[element]),
												invoiceSpend: (groups[element].INVOICE_SPEND), invoiceCount: (groups[element].INVOICE_COUNT),
												poSpend: (groups[element].PO_SPEND), poCount: (groups[element].PO_COUNT),
												uniqueSupplier: (groups[element].UNIQUE_SUPPLIERS),
												rank: _rank
											})
										}
										else {
											var existingEntry = treeFormatData.filter((ele) => ele.name === groups[element][col.value]);
											var updatedEntry = {
												name: groups[element][col.value],
												value: Number(existingEntry[0].value) + Number(groups[element].INVOICE_SPEND),
												clickable: _instance.isClickableTile(col.value, groups[element]),
												invoiceSpend: existingEntry[0].invoiceSpend + ((groups[element].INVOICE_SPEND)),
												invoiceCount: existingEntry[0].invoiceCount + (groups[element].INVOICE_COUNT),
												poSpend: existingEntry[0].poSpend + ((groups[element].PO_SPEND)),
												poCount: existingEntry[0].poCount + ((groups[element].PO_COUNT)),
												uniqueSupplier: existingEntry[0].uniqueSupplier + ((groups[element].UNIQUE_SUPPLIERS)),
												rank: _rank
											}
											var index = treeFormatData.findIndex((ele) => ele.name === groups[element][col.value])
											treeFormatData[index] = updatedEntry;
										}
									}
								})
							}

						}
						break;
					case "topRecords":
						var sortByColumn = this.state.defineScopeFilters.metricsType === 'Invoice Spend' ? 'invoiceSpend' :
							this.state.defineScopeFilters.metricsType === 'Invoice Count' ? 'invoiceCount' :
								this.state.defineScopeFilters.metricsType === 'PO Spend' ? 'poSpend' : 'poCount';
						var sortedByInvoice = _.map(_.orderBy(treeFormatData, sortByColumn, ['desc']));
						if (sortedByInvoice.length > defineScopeFilters[filter]) {
							topRecords = _.take(sortedByInvoice, defineScopeFilters[filter]);
						} else {
							topRecords = sortedByInvoice
						}
						var invoiceSpend = 0;
						var invoiceCount = 0;
						var poSpend = 0;
						var poCount = 0;
						var uniqueSupplier = 0;
						sortedByInvoice.slice(defineScopeFilters[filter]).map((item) => {
							allotherInvoiceSpend = parseFloat(allotherInvoiceSpend) + parseFloat(item.value);
							invoiceSpend = parseFloat(invoiceSpend) + parseFloat(item.invoiceSpend);
							invoiceCount = parseFloat(invoiceCount) + parseFloat(item.invoiceCount);
							poSpend = parseFloat(poSpend) + parseFloat(item.poSpend);
							poCount = parseFloat(poCount) + parseFloat(item.poCount);
							uniqueSupplier = parseFloat(uniqueSupplier) + parseFloat(item.uniqueSupplier)

						})

						if (allotherInvoiceSpend != 0) {
							allotherInvoiceSpend = allotherInvoiceSpend < 0 ? (allotherInvoiceSpend * -1) : allotherInvoiceSpend;
							topRecords.push({
								name: "All other suppliers",
								value: (allotherInvoiceSpend),
								clickable: true,
								invoiceSpend: (invoiceSpend), invoiceCount: (invoiceCount),
								poSpend: (poSpend),
								poCount: (poCount),
								uniqueSupplier: (uniqueSupplier),
								rank: (Number(defineScopeFilters[filter]) + 1)
							})
						}
						break;
				}

			})
			if (topRecords.length > 0) {
				var industrySupplierDetailsFilterData = { name: industryLeveldrpdwn, children: topRecords }
				this.setState({ industrySupplierDetailsFilterData: industrySupplierDetailsFilterData });
				this.calculateAggreateSpend(topRecords);
				//console.log(topRecords)
			}
			else {
				this.setState({ industrySupplierDetailsFilterData: '' })
				this.calculateAggreateSpend();
			}
		}
		else if (this._isMounted) {
			this.setState({ industrySupplierDetailsFilterData: '' })
			this.calculateAggreateSpend();
		}
	}
	getRankColumnName(levelId) {

		var columeName = ""
		switch (levelId) {
			case ("L2_INDUSTRY_NAME"):
				columeName = "L2_RANK"
				break;
			case ("L3_INDUSTRY_NAME"):
				columeName = "L3_RANK"
				break;
			case ("L4_INDUSTRY_NAME"):
				columeName = "L4_RANK"
				break;
			case ("L5_INDUSTRY_NAME"):
				columeName = "L5_RANK"
				break;
			case ("L6_INDUSTRY_NAME"):
				columeName = "L6_RANK"
				break;
			case ("L7_INDUSTRY_NAME"):
				columeName = "L7_RANK"
				break;
		}
		return columeName;
	}


	calculateAggreateSpend(topRecords) {
		var spendData = topRecords;
		var calAggregatedAmount = {
			invoice_spend: 0,
			invoice_count: 0,
			po_spend: 0,
			po_count: 0,
			unique_supplier: 0
		}

		if (spendData !== undefined && this._isMounted) {

			Object.keys(spendData).map((element) => {
				if (element !== undefined) {
					calAggregatedAmount.invoice_spend = parseFloat(calAggregatedAmount.invoice_spend) + parseFloat(spendData[element].invoiceSpend);
					calAggregatedAmount.invoice_count = parseFloat(calAggregatedAmount.invoice_count) + parseFloat(spendData[element].invoiceCount);
					calAggregatedAmount.po_spend = parseFloat(calAggregatedAmount.po_spend) + parseFloat(spendData[element].poSpend);
					calAggregatedAmount.po_count = parseFloat(calAggregatedAmount.po_count) + parseFloat(spendData[element].poCount);
					calAggregatedAmount.unique_supplier = parseFloat(calAggregatedAmount.unique_supplier) + parseFloat(spendData[element].uniqueSupplier);
				}
			})
			calAggregatedAmount.invoice_count = Math.round(calAggregatedAmount.invoice_count);
			calAggregatedAmount.po_count = Math.round(calAggregatedAmount.po_count);
		}
		else {
			calAggregatedAmount.invoice_spend = 0;
			calAggregatedAmount.invoice_count = 0;
			calAggregatedAmount.po_spend = 0;
			calAggregatedAmount.po_count = 0;
			calAggregatedAmount.unique_supplier = 0;
		}

		this.setState({ aggregatedAmount: calAggregatedAmount }, this.checkDataExists);

	}

	checkDataExists(){
		var isDataExists = true;
		switch(this.state.defineScopeFilters.metricsType){
			case "Invoice Spend" :
					isDataExists = this.state.aggregatedAmount.invoice_spend == 0 ? false : true;
			break;
			case "Invoice Count" :
					isDataExists = this.state.aggregatedAmount.invoice_count == 0 ? false : true;
			break;
			case "PO Spend" :
					isDataExists = this.state.aggregatedAmount.po_spend == 0 ? false : true;
			break;
			case "PO Count" :
					isDataExists = this.state.aggregatedAmount.po_count == 0 ? false : true;
			break;
			default:
					isDataExists = true;
		}
		this.setState({ isDataExists : isDataExists})
	}

	onClick_TreeMap(param) {
		var clickedIndustryDetails = this.state.clickedIndustryDetails
		clickedIndustryDetails.push(param)
		this.setState({ clickedIndustryDetails: clickedIndustryDetails })
		if (param.data.name !== "All other suppliers" && this._isMounted) {
			this.props.defineScopeLoading(true)
			let data = {}
			let defineScopeFilters = this.setFilter(param);
			data = {
				INVOICE_COUNT: param.data.invoiceCount,
				INVOICE_SPEND: param.data.invoiceSpend,
				PO_COUNT: param.data.poCount,
				PO_SPEND: param.data.poSpend,
				UNIQUE_SUPPLIERS: param.data.uniqueSupplier,
			}

			data[defineScopeFilters.industryLevel] = defineScopeFilters.industryName //industry name

			let indSplits = defineScopeFilters.industryLevel.split('_');
			data['INDUSTRY_LEVEL'] = indSplits[0].substr(1);
			let industryId = indSplits[0] + '_' + indSplits[1] + '_' + 'ID';
			data[industryId] = defineScopeFilters.GICS_ID;

			var breadCrumbsArray_new = this.state.breadCrumbsArray
			breadCrumbsArray_new.push({ industryName: param.data.name, industryLevel: param.parent.data.name, data: data, level: data.INDUSTRY_LEVEL })
			this.setState({
				defineScopeFilters: defineScopeFilters, breadCrumbsArray: breadCrumbsArray_new, levelDisabled: true
			}, () => {
				this.getSummaryScopeSolution();
				this.getSupplierScopeDetails();
				this.filterAndGroupData(false);
			})

		}
		else {
			this.refs.modal.show()
		}
	}

	onDoubleClickTreeMap(param) {
		if (param.data.name !== "All other suppliers" && this._isMounted) {
			let data = {}
			let defineScopeFilters = this.setFilter(param);
			data = {
				INVOICE_COUNT: param.data.invoiceCount,
				INVOICE_SPEND: param.data.invoiceSpend,
				PO_COUNT: param.data.poCount,
				PO_SPEND: param.data.poSpend,
				UNIQUE_SUPPLIERS: param.data.uniqueSupplier,
			}

			data[defineScopeFilters.industryLevel] = defineScopeFilters.industryName //industry name

			let indSplits = defineScopeFilters.industryLevel.split('_');
			data['INDUSTRY_LEVEL'] = indSplits[0].substr(1);
			let industryId = indSplits[0] + '_' + indSplits[1] + '_' + 'ID';
			data[industryId] = defineScopeFilters.GICS_ID;

			// var breadCrumbsArray_new = this.state.breadCrumbsArray
			// breadCrumbsArray_new.push({ industryName: param.data.name, industryLevel: param.parent.data.name, data: data, level: data.INDUSTRY_LEVEL })
			// this.setState({
			// 	defineScopeFilters: defineScopeFilters, breadCrumbsArray: breadCrumbsArray_new, levelDisabled: true
			// }, () => {
			// 	this.getSummaryScopeSolution();
			// 	this.getSupplierScopeDetails();
			// 	this.filterAndGroupData(false);})

			//params[defineScopeFilters.industryName] = defineScopeFilters.GICS_ID;
			this.showSupplierIndustriesModal(data, defineScopeFilters.industryName, data.INDUSTRY_LEVEL)
			//this.setState({openIndustryPopup: true})
		} else {
			this.refs.modal.show()
		}
	}

	setFilter(param) {
		var defineScopeFilters = Object.assign({}, this.state.defineScopeFilters);
		defineScopeFilters.industryName = param.data.name
		defineScopeFilters.industryLevel = param.parent.data.name

		//pass GICS_ID to barchart and supplier grid to fecth data accordingly
		var industryData = this.state.industrySupplierDetailsAllData.filter((item) => item[param.parent.data.name] === param.data.name);

		defineScopeFilters.GICS_ID = defineScopeFilters.industryLevel === "L2_INDUSTRY_NAME" ? industryData[0].L2_INDUSTRY_ID
			: defineScopeFilters.industryLevel === "L3_INDUSTRY_NAME" ? industryData[0].L3_INDUSTRY_ID
				: defineScopeFilters.industryLevel === "L4_INDUSTRY_NAME" ? industryData[0].L4_INDUSTRY_ID
					: defineScopeFilters.industryLevel === "L5_INDUSTRY_NAME" ? industryData[0].L5_INDUSTRY_ID
						: defineScopeFilters.industryLevel === "L6_INDUSTRY_NAME" ? industryData[0].L6_INDUSTRY_ID : industryData[0].L7_INDUSTRY_ID

		return defineScopeFilters;
	}

	handlelangChange(value) {
		this.setState({ selectedRequestId: value.label })
	}

	handleScopeCheckChanged(scope) {
		var id = scope.target.id;
		if (this.state.selectedScopes.indexOf(id) === -1) {
			this.setState(state => ({
				selectedScopes: [...state.selectedScopes, id]
			}));
		}
		else {
			this.setState({
				selectedScopes: this.state.selectedScopes.filter((item) => item !== id)
			});
		}
	}

	triggerAllMethod = (isTopRequest, callHana) => {
		if (this._isMounted) {

			if (callHana) {
				this.getIndustrySupplierDetails(isTopRequest);
			}
			else {
				this.filterAndGroupData(isTopRequest)
			}
			this.getSummaryScopeSolution();
			this.getSupplierScopeDetails();
		}
	};


	getSummaryScopeSolution() {
		var defineScope_obj = this.state.defineScope;
		defineScope_obj.User_Email = this.props.spendDetails !== undefined &&
			this.props.spendDetails.currentActiveRequest !== undefined ? (
				this.props.spendDetails.currentActiveRequest.SAPRequester === this.props.user.user.Email ?
					this.props.user.user.Email : this.props.spendDetails.currentActiveRequest.SAPRequester) : '';

		defineScope_obj.Country = this.state.countryFilter;
		defineScope_obj.Solution = this.state.solutionFilter;
		defineScope_obj.outSolution = this.state.outSolutionFilter;
		defineScope_obj.SolutionConfirmation = this.state.defineScopeFilters.solutionConfirmation === '' ? defineScope_obj.SolutionConfirmation : this.state.defineScopeFilters.solutionConfirmation;
		defineScope_obj.GICS_ID = this.state.defineScopeFilters.GICS_ID === '' ? defineScope_obj.GICS_ID : this.state.defineScopeFilters.GICS_ID;
		defineScope_obj.DirectSpend = this.state.defineScopeFilters.directSpend === '' ? defineScope_obj.DirectSpend : this.state.defineScopeFilters.directSpend;
		defineScope_obj.Goods = this.state.defineScopeFilters.goods === '' ? defineScope_obj.Goods : this.state.defineScopeFilters.goods;
		defineScope_obj.Business_Unit = this.state.businessUnitFilter;
		defineScope_obj.Buyer_Region = this.state.buyerRegionFilter;
		defineScope_obj.MetricsType = this.state.defineScopeFilters.metricsType === '' ? defineScope_obj.MetricsType : this.state.defineScopeFilters.metricsType;
		defineScope_obj.AribaNetwork = this.state.defineScopeFilters.aribaNetwork === '' ? defineScope_obj.AribaNetwork : this.state.defineScopeFilters.aribaNetwork;
		defineScope_obj.SAPAribaBuyProcess = this.state.defineScopeFilters.sapAribaBuyProcess === '' ? defineScope_obj.SAPAribaBuyProcess : this.state.defineScopeFilters.sapAribaBuyProcess;

		this.props.getSummaryScopeSolution(defineScope_obj)
			.then((response) => {
				//this.props.defineScopeSuccess('')
				//var isValidData = false;
				if (response.data.Result != undefined && this._isMounted) {
					// for (var i = 0; i < response.data.Result.length; i++) {
					// 	if (response.data.Result[i]['IN_SCOPE_SPEND'] === null && response.data.Result[i]['OUT_SCOPE_SPEND'] === null) {
					// 		isValidData = false;
					// 	} else {
					// 		isValidData = true;
					// 	}
					// }

					let data = response.data.Result;

					var array = [];
					data.forEach((element) => {
						if (element.IN_SCOPE_SPEND == null && element.OUT_SCOPE_SPEND == null) {
							array = [];
						}
						else {
							var obj = {};
							obj.IN_SCOPE_SPEND = element.IN_SCOPE_SPEND;
							obj.OUT_SCOPE_SPEND = element.OUT_SCOPE_SPEND;
							obj.SOLUTION_TYPE = element.SOLUTION_TYPE;
							obj["InScopeValue"] = element.IN_SCOPE_SPEND;
							obj["OutScopeValue"] = element.OUT_SCOPE_SPEND;
							array.push(obj);
						}
					});

					this.setState({ summaryScopeData: array });// (isValidData === false ? null : response.data.Result) });
				}
			}).catch((error) => {
				if (error.stack.includes('Network Error')) {
					this.props.defineScopeFailure('API service is down. Please contact administrator.')
				}
				else {
					this.props.defineScopeFailure(this.props.content.error)
				}
			})
	}


	getSupplierScopeDetails() {
		var defineScope_obj = this.state.defineScope;
		defineScope_obj.User_Email = this.props.spendDetails !== undefined &&
			this.props.spendDetails.currentActiveRequest !== undefined ? (
				this.props.spendDetails.currentActiveRequest.SAPRequester === this.props.user.user.Email ?
					this.props.user.user.Email : this.props.spendDetails.currentActiveRequest.SAPRequester) : '';

		// 	this.props.spendDetails.currentActiveRequest.SAPRequester === this.props.user.user.Email ?
		// this.props.user.user.Email : this.props.spendDetails.currentActiveRequest.SAPRequester;
		defineScope_obj.Country = this.state.countryFilter;
		defineScope_obj.Solution = this.state.solutionFilter;
		defineScope_obj.outSolution = this.state.outSolutionFilter;
		defineScope_obj.SolutionConfirmation = this.state.defineScopeFilters.solutionConfirmation === '' ? defineScope_obj.SolutionConfirmation : this.state.defineScopeFilters.solutionConfirmation;
		defineScope_obj.GICS_ID = this.state.defineScopeFilters.GICS_ID === '' ? defineScope_obj.GICS_ID : this.state.defineScopeFilters.GICS_ID;
		defineScope_obj.DirectSpend = this.state.defineScopeFilters.directSpend === '' ? defineScope_obj.DirectSpend : this.state.defineScopeFilters.directSpend;
		defineScope_obj.Goods = this.state.defineScopeFilters.goods === '' ? defineScope_obj.Goods : this.state.defineScopeFilters.goods;
		defineScope_obj.Business_Unit = this.state.businessUnitFilter;
		defineScope_obj.Buyer_Region = this.state.buyerRegionFilter;
		defineScope_obj.MetricsType = this.state.defineScopeFilters.metricsType === '' ? defineScope_obj.MetricsType : this.state.defineScopeFilters.metricsType;
		defineScope_obj.AribaNetwork = this.state.defineScopeFilters.aribaNetwork === '' ? defineScope_obj.AribaNetwork : this.state.defineScopeFilters.aribaNetwork;
		defineScope_obj.SAPAribaBuyProcess = this.state.defineScopeFilters.sapAribaBuyProcess === '' ? defineScope_obj.SAPAribaBuyProcess : this.state.defineScopeFilters.sapAribaBuyProcess;

		this.props.getSupplierScopeDetails(defineScope_obj)
			.then((response) => {

				if (response !== undefined && response.status === 200 && response.data.Result !== undefined) {
					setTimeout(() => { this.props.defineScopeSuccess('') }, 2000)

					this.setState({ supplierData: response.data.Result });
				}

			}).catch((error) => {
				if (error.stack.includes('Network Error')) {
					this.props.defineScopeFailure('API service is down. Please contact administrator.')
				}
				else {
					this.props.defineScopeFailure(this.props.content.error)
				}
			})
	}

	isClickableTile(colvalue, data) {
		if (colvalue === "L2_INDUSTRY_NAME") {
			return data["L3_INDUSTRY_NAME"] !== null ? true : false
		}
		if (colvalue === "L3_INDUSTRY_NAME") {
			return data["L4_INDUSTRY_NAME"] !== null ? true : false
		}
		if (colvalue === "L4_INDUSTRY_NAME") {
			return data["L5_INDUSTRY_NAME"] !== null ? true : false
		}
		if (colvalue === "L5_INDUSTRY_NAME") {
			return data["L6_INDUSTRY_NAME"] !== null ? true : false
		}
		if (colvalue === "L6_INDUSTRY_NAME") {
			return data["L7_INDUSTRY_NAME"] !== null ? true : false
		}
		if (colvalue !== "L7_INDUSTRY_NAME") {
			return false;
		}


	}
	showModal() {
		this.refs.modal.show()
	}

	hideModal() {
		this.refs.modal.hide()
	}

	showAlertModal(action) {
		this.refs[action].show()
	}

	hideAlertModal(action) {
		this.refs[action].hide()
		//this.setState({ open: true })
	}

	continueNavigation(action) {
		this.refs[action].hide()
		this.props.trackSupplierDrillDownChanges(false)
		this.props.trackIndustryDrillScopeChanges(false)
		if (this.props.spendDetails.supplierDrilldownSavedChanges === true) {
			this.props.trackSupplierDrillDownSave(false)
			this.setState({ open: false, openIndustryPopup: false })
			this._isMounted = true;
			this.loadData()
			window.scrollTo(0, 0);
		}
		else {
			this.setState({ open: false, openIndustryPopup: false })
		}
	}

	showSupplierDrilldownModal(SKEY, capIQId, parentCapIQId) {
		var supplierDetails = { SKEY: SKEY, capIQId: capIQId, parentCapIQId: parentCapIQId }
		this.props.storeSupplierSKEY(supplierDetails)
		this.setState({
			open: true
		})
	}

	showSupplierIndustriesModal(data, industry, level) {
		let key = 'L' + level + '_' + 'INDUSTRY_ID'
		let SKEY = data[key]
		data['name'] = industry;
		var industryGICS_Details = { SKEY: SKEY, industry: industry, level: level, industryData: { params: data, filters: this.state.defineScopeFilters } }
		this.props.storeIndustryGICS_ID(industryGICS_Details)

		//var supplierDetails = { SKEY: SKEY, capIQId: capIQId, parentCapIQId: parentCapIQId, industryData: data }
		//this.props.storeSupplierSKEY(supplierDetails)
		this.setState({
			openIndustryPopup: true
		})
	}

	setVisibility(isVisible) {
		if (this.props.supplierDrilldownPendingChanges !== undefined && this.props.supplierDrilldownPendingChanges !== null
			&& this.props.supplierDrilldownPendingChanges === true) {
			this.showAlertModal("alert")
		} else if (this.props.industryDrillScopePendingChanges !== undefined && this.props.industryDrillScopePendingChanges !== null
			&& this.props.industryDrillScopePendingChanges === true) {
			this.showAlertModal("alert")
		}
		else {
			if (this.props.spendDetails.supplierDrilldownSavedChanges === true) {
				this.props.trackSupplierDrillDownSave(false)
				this.setState({ open: false, openIndustryPopup: false })
				this._isMounted = true;
				if (this.state.breadCrumbsArray.length > 0) {
					this.removeIndustryFilter(this.state.clickedIndustryDetails[this.state.breadCrumbsArray.length - 1].data.name, this.state.breadCrumbsArray.length - 1, true)
					this.onClick_TreeMap(this.state.clickedIndustryDetails[this.state.breadCrumbsArray.length])
					this.state.clickedIndustryDetails.splice(this.state.clickedIndustryDetails.length - 1, 1)
					var finalBreadcrumbs = this.state.clickedIndustryDetails
					this.setState({ clickedIndustryDetails: finalBreadcrumbs })
					Object.assign(this.state, { clickedIndustryDetails: finalBreadcrumbs })
				}
				else {
					this.loadData()
				}
				window.scrollTo(0, 0);
			}
			else {
				this.setState({ open: false, openIndustryPopup: false })
			}
		}
	}

	outsidePopupClick() {
		this.props.trackSupplierDrillDownChanges(false)
		this.props.trackIndustryDrillScopeChanges(false)
		this.setState({ open: false, openIndustryPopup: false })
	}

	hideToolTip() {
		d3.select('#tooltipdiv').remove();
	}

	render() {
		var enableOnClick = this.state.industryLeveldrpdwn !== "L7_INDUSTRY_NAME"
		var breadCrumbsArray = this.state.breadCrumbsArray;
		var index = -1;
		return (
			<div className='defineScope'>
				<Modal
					ref={'modal'} backdropStyle={backdropStyle}
					keyboard={() => this.callback()}
					modalStyle={suggestedValueModalStyle}
					contentStyle={suggestedValueContentStyle}>
					<div className="grid-x grid-padding-x pb10 pt10">
						<div className="medium-12 cell">
							{this.props.content.allOtherMessage}
						</div>
						<div className="medium-12 cell">
							<div className="float-right">
								<PopupButton
									type="submit"
									content={this.props.content.okButton}
									color
									onClick={() => this.refs.modal.hide()}
								/>
							</div>
						</div>
					</div>
				</Modal>
				<div>
					{this.props.spendDetails.supplierDetails !== undefined ?
						<SupplierDrillDownPopup open={this.state.open} visibility={this.setVisibility} outsidePopupClick={this.outsidePopupClick} />
						:
						null}
				</div>
				<div><SupplierIndustriesPopup open={this.state.openIndustryPopup} visibility={this.setVisibility} outsidePopupClick={this.outsidePopupClick}></SupplierIndustriesPopup></div>
				<div>
					<Modal
						ref="alert"
						modalStyle={alertModalStyle}
						contentStyle={changeModalContentStyle}
						backdropStyle={changeBackdropStyle}
					//keyboard={() => this.callback()}
					>
						<div className="grid-x grid-padding-x change-popup">
							<div className="medium-12">
								<button onClick={() => this.hideAlertModal("alert")} className="close" />
							</div>
							<div className="medium-12 pt30">
								{this.props.tabMessage}
							</div>
							<div className="medium-12 popupOkBtn">
								<div className="float-right">
									<div className="float-left"><Button
										content={"Continue"}
										onClick={() => this.continueNavigation("alert")}
										color /></div>
									<div className="float-left"><Button
										content={"Close"}
										onClick={() => this.hideAlertModal("alert")}
										color /></div>
								</div>
							</div>
						</div>

					</Modal>
				</div>
				<div className="grid-x pt5">
					<div className="medium-12">
						<div className="tab-title">{(this.props.content.title)}{<VideoHelp screen={{ module: 'Spend', screenName: 'Industry Breakdown' }} />}</div>
						<div className="tab-description">{this.props.content.subTitle}</div>
						<div className="graph-title pt10">{this.props.content.summaryOfIndustryView}</div>
						<div className="grid-x pt10">
							<div className="blue-bg">
								<div className="grid-x grid-padding-x">
									<div className="float-left">
										<div className="title">{this.props.content.uniqueSuppliers + ":"}</div>
										<div className="value">   {formatThousands(this.state.aggregatedAmount.unique_supplier)}</div>
									</div>
									<div className="float-left pl20">
										<div className="title">{this.props.content.invoiceSpend + ":"}</div>
										<div className="value">  {this.state.currencySymbol}{convertToMillion(this.state.aggregatedAmount.invoice_spend, true)}</div>
									</div>
									<div className="float-left pl20">
										<div className="title">{this.props.content.poSpend + ":"}</div>
										<div className="value"> {this.state.currencySymbol}{convertToMillion(this.state.aggregatedAmount.po_spend, true)}</div>
									</div>
									<div className="float-left pl20">
										<div className="title">{this.props.content.invoiceCount + ":"} </div>
										<div className="value">{formatThousands(this.state.aggregatedAmount.invoice_count)}</div>
									</div>
									<div className="float-left pl20">
										<div className="title">{this.props.content.poCount + ":"} </div>
										<div className="value">{formatThousands(this.state.aggregatedAmount.po_count)}</div>
									</div>
								</div>
							</div>
							<div className="pt10 pl30">
								{this.state.isFilterChanges===true ?<span className="pending-changes" style={{'color':'red'}}>{this.props.content.pendingChanges}</span>:''}
							</div>

						</div>
						<div className="clearfix flexible pt10">
							{/* <div className="float-left width80">
								<div className="dropDownHeader">{this.props.content.top}</div>
								<Select
									className="border-with-DD dropDownContent"
									model='yourFile.RequestId'
									id='RequestId'
									autocomplete='true'
									value={this.state.topCount.filter(
										option => option.label == this.state.defineScopeFilters.topRecords
									)}
									onChange={value => this.handleTopCountChange(value)}
									options={this.state.topCount}
									isSearchable={false} isDisabled={this.props.spendDetails.isLoading}
									noOptionsMessage={() => null}
								/>


							</div> */}
							<div className="float-left width100 ml10">
								<div className="dropDownHeader">{this.props.content.industryLevel}</div>
								<Select
									className="border-with-DD dropDownContent"									
									model='topDetails.industryLevel'									
									id='industryLevel'
									autocomplete='true'
									value={this.state.industryLevelList.filter(
										option => option.value === this.state.industryLeveldrpdwn
									)}
									onChange={value => this.handleIndustryLevelChange(value)}
									options={this.state.industryLevelList}
									isSearchable={false} isDisabled={this.props.spendDetails.isLoading || this.state.levelDisabled}
									noOptionsMessage={() => null} />
							</div>

							<div className="float-left ml10 width130">
								<div className="dropDownHeader">{this.props.content.metricsType}</div>
								<Select
									className="border-with-DD dropDownContent"
									autocomplete='true'
									//placeholder={this.props.content.notConfirmed}
									value={this.state.metricsOptions.filter(
										option => option.value === this.state.defineScopeFilters.metricsType
									)}
									onChange={value => this.handleMetricsChange(value)}
									options={this.state.metricsOptions}
									isDisabled={this.props.spendDetails.isLoading}
									isSearchable={false}
								/>
							</div>

							<div className="float-left ml10 width130">
								<div className="dropDownHeader">{this.props.content.solutionLbl}</div>
								<MultiSelect
									className="border-with-DD dropDownContent multiSelectHeight multiSelectLabelPadding"
									model='yourFile.RequestId'
									id='RequestId'
									autocomplete='true'
									//placeholder={this.props.content.allSolutions}
									// value={this.state.allSolutions.filter(
									// 	option => option.value === this.state.defineScopeFilters.solution
									// )}
									value={this.state.defineScopeFilters.solution}
									onChange={value => this.handleSolutionChange(value)}
									options={this.state.allSolutions}
									isSearchable={false}
									noOptionsMessage={() => null}
									disabled={this.props.spendDetails.isLoading}
								/>
							</div>

							<div className="float-left ml10 width160">
								<div className="dropDownHeader">{this.props.content.solutionOutLbl}</div>
								<MultiSelect
									className="border-with-DD dropDownContent multiSelectHeight multiSelectLabelPadding"
									model='yourFile.RequestId'
									id='RequestId'
									autocomplete='true'
									//placeholder={this.props.content.allSolutions}
									// value={this.state.allSolutions.filter(
									// 	option => option.value === this.state.defineScopeFilters.solution
									// )}
									value={this.state.defineScopeFilters.outSolution}
									onChange={value => this.handleOutSolutionChange(value)}
									options={this.state.allSolutionsOut}
									isSearchable={false}
									noOptionsMessage={() => null}
									disabled={this.props.spendDetails.isLoading}
								/>
							</div>

							<div className="float-left ml10 width130">
								<div className="dropDownHeader">{this.props.content.matchFilter}</div>
								<Select
									className="border-with-DD dropDownContent"
									autocomplete='true'
									//placeholder={this.props.content.notConfirmed}
									value={this.state.aribaNetworkOptions.filter(
										option => option.value === this.state.defineScopeFilters.aribaNetwork
									)}
									onChange={value => this.handleAribaNetworkChange(value)}
									options={this.state.aribaNetworkOptions}
									isDisabled={this.props.spendDetails.isLoading}
									isSearchable={false}
								/>
							</div>

							<div className="float-left ml10 width160">
								<div className="dropDownHeader">{this.props.content.sapAribaBuyProcess}</div>
								<Select
									className="border-with-DD dropDownContent"
									autocomplete='true'
									//placeholder={this.props.content.notConfirmed}
									value={this.state.sapAribaBuyProcessOptions.filter(
										option => option.value === this.state.defineScopeFilters.sapAribaBuyProcess
									)}
									onChange={value => this.handleSAPAribaBuyProcessChange(value)}
									options={this.state.sapAribaBuyProcessOptions}
									isDisabled={this.props.spendDetails.isLoading}
									isSearchable={false}
								/>
							</div>

							<div className="float-left ml10 width130">
								<div className="dropDownHeader">{this.props.content.countrylbl}</div>
								<MultiSelect
									className="border-with-DD dropDownContent multiSelectHeight multiSelectLabelPadding"
									model='yourFile.RequestId'
									id='RequestId'
									autocomplete='true'
									noOptionsMessage={() => null}
									placeholder={"All countries"}
									// value={this.state.allCountries.filter(
									// 	option => option.label === this.state.defineScopeFilters.country
									// )}
									value={this.state.defineScopeFilters.country}
									onChange={value => this.handleCountryChange(value)}
									options={this.state.allCountries}
									disabled={this.props.spendDetails.isLoading}
								/>
							</div>
							{/* <div className="float-left ml10" >
								{!this.state.showFlag ? <span title="Show Advanced Filters" className="formulaePlus pr" onClick={this.toggleShow}>+</span> : <span title="Hide Filters" className="formulaeMinus pr" onClick={this.toggleShow}>-</span>}

							</div> */}
							{/* {this.state.showFlag ? <div> */}

							<div className="float-left ml10 width130">
								<div className="dropDownHeader">{this.props.content.buyerLbl}</div>
								<MultiSelect
									className="border-with-DD dropDownContent multiSelectHeight multiSelectLabelPadding"
									model='yourFile.RequestId'
									id='RequestId'
									autocomplete='true'
									//placeholder={this.props.content.notConfirmed}
									// value={this.state.allBuyerRegion.filter(
									// 	option => option.value === this.state.defineScopeFilters.buyerRegion
									// )}
									value={this.state.defineScopeFilters.buyerRegion}
									onChange={value => this.handleBuyerChange(value)}
									options={this.state.allBuyerRegion}
									disabled={this.props.spendDetails.isLoading}
								/>
							</div>
							<div className="float-left ml10 width150">
								<div className="dropDownHeader">{this.props.content.businessLbl}</div>
								<MultiSelect
									className="border-with-DD dropDownContent multiSelectHeight multiSelectLabelPadding"
									model='yourFile.RequestId'
									id='RequestId'
									autocomplete='true'
									//placeholder={this.props.content.notConfirmed}
									// value={this.state.allBusinessUnit.filter(
									// 	option => option.value === this.state.defineScopeFilters.businessUnit
									// )}
									value={this.state.defineScopeFilters.businessUnit}
									onChange={value => this.handleBusinessChange(value)}
									options={this.state.allBusinessUnit}
									disabled={this.props.spendDetails.isLoading}
								/>
							</div>
							<div className="float-left ml10 width100">
								<div className="dropDownHeader">{this.props.content.directSpendLbl}</div>
								<Select
									className="border-with-DD dropDownContent"
									model='yourFile.RequestId'
									id='RequestId'
									autocomplete='true'
									//placeholder={this.props.content.notConfirmed}
									value={this.state.directSpendOptions.filter(
										option => option.value === this.state.defineScopeFilters.directSpend
									)}
									onChange={value => this.handleDirectSpendChange(value)}
									options={this.state.directSpendOptions}
									isDisabled={this.props.spendDetails.isLoading}
									isSearchable={false}
								/>
							</div>
							<div className="float-left ml10 width120">
								<div className="dropDownHeader">{this.props.content.goodsLbl}</div>
								<Select
									className="border-with-DD dropDownContent"
									autocomplete='true'
									//placeholder={this.props.content.notConfirmed}
									value={this.state.goodsOptions.filter(
										option => option.value === this.state.defineScopeFilters.goods
									)}
									onChange={value => this.handleGoodsChange(value)}
									options={this.state.goodsOptions}
									isDisabled={this.props.spendDetails.isLoading}
									isSearchable={false}
								/>
							</div>

							<div className="float-left ml10 width110">
								<div className="dropDownHeader">{this.props.content.confirmedLbl}</div>
								<Select
									className="border-with-DD dropDownContent"
									model='yourFile.RequestId'
									id='RequestId'
									autocomplete='true'
									//placeholder={this.props.content.notConfirmed}
									value={this.state.optionSolutionConfirmation.filter(
										option => option.value === this.state.defineScopeFilters.solutionConfirmation
									)}
									onChange={value => this.handleSolutionConfirmationChange(value)}
									options={this.state.optionSolutionConfirmation}
									isDisabled={this.props.spendDetails.isLoading}
									isSearchable={false}
								/>
							</div>

							{/* </div> : null} */}
							<div style={{paddingTop: '20px'}} className="float-left width170">
								<Button
									content={this.props.content.applyFilters}
									onClick={this.clickedOutside}
									color />
							</div>
							<div style={{paddingTop: '20px'}} className="float-left width170">
								<Button
									content={this.props.content.resetFilters}
									onClick={e => this.clickedResetFilter(e)}
									color />
							</div>

						</div>
						<div className="grid-x grid-padding-x">
							<div className="medium-7">
								<div className="graph-title pt10">{this.props.content.supplierIndustries}</div>
								{
									this.state.industrySupplierDetailsFilterData !== '' && this.state.isDataExists ?
										(<ZoomModal classStyle="zoom-div-style"><React.Fragment>
											<div className="grid-x height25">
												{
													Object.values(breadCrumbsArray).map((item) => {
														index = index + 1
														return (
															<div className='breadcrumbsClose'
																content={item.industryName} onClick={() => this.removeIndustryFilter(item.industryName, index, false)}
																color
															>{item.industryName}
																<img className="ml5" width="10" src="images/close_inactive.svg" />
															</div>
														)
													})
												}
											</div>
											< div>
												<TreeMap root={''} data={this.state.industrySupplierDetailsFilterData}
													onClick={e => this.onClick_TreeMap(e)}
													onDoubleClick={e => this.onDoubleClickTreeMap(e)}
													enableOnClick={enableOnClick}
													currencySymbols={this.state.currencySymbol}
													sortBy={"rank"}
													totalInvoicSpend={this.state.aggregatedAmount.invoice_spend}
													totalValues={this.state.aggregatedAmount}
													selectedMetrics={this.state.defineScopeFilters.metricsType}
												/>

											</div>
											<div className="tab-description">{this.props.content.footNote}</div>
										</React.Fragment></ZoomModal>) :
										<div className="pt10">
											{this.hideToolTip()}
											{this.props.content.noDataFound}</div>
								}
								<div className="graph-title pt20 clearfix bb_cacaca">
									<div className="float-left">
										{this.props.content.scopeSummary}
									</div>
								</div>
								<div>
									{this.state.summaryScopeData !== (null && '' && undefined) && this.state.summaryScopeData.length > 0 && this.state.isDataExists ?
										<ZoomModal classStyle="zoom-chart-style">
											<StackedBarChart
												data={this.state.summaryScopeData}
												currency={this.props.currencySymbols[this.props.spendDetails.currentActiveRequest.Currency !== undefined && this.props.spendDetails.currentActiveRequest.Currency.trim() !== '' && this.props.spendDetails.currentActiveRequest.Currency.trim() !== "0" ? this.props.spendDetails.currentActiveRequest.Currency.trim() : 'USD']}
												type={"Spend"}
												selectedMetrics={this.state.defineScopeFilters.metricsType} />
										</ZoomModal>
										: <div className="pt10">{this.props.content.noDataFound}</div>
									}
								</div>
								{this.state.summaryScopeData !== '' && this.state.summaryScopeData !== null ?
									<div className="float-right-legend">
										<div class="legend2"> <span class="key-dot inScope"></span>{this.props.content.inScopeSpend} </div>
										<div class="legend2"> <span class="key-dot outScope"></span>{this.props.content.outScopeSpend}</div>
									</div>
									: null}
							</div>
							<div className="medium-5 cell">
								<div className="grid-x">
									<div className={"medium-12 graph-title pt10 clearfix"}>
										<span>{this.props.content.topSupplier}</span>

										<span className='onMouseOver' style={{ cursor: 'pointer' }} width='45%' onClick={() => this.showSupplierIndustriesModal(this.state.breadCrumbsArray[this.state.breadCrumbsArray.length - 1].data, this.state.breadCrumbsArray[this.state.breadCrumbsArray.length - 1].industryName, this.state.breadCrumbsArray[this.state.breadCrumbsArray.length - 1].level)}>
											{this.state.breadCrumbsArray.length > 0 && this.state.breadCrumbsArray[this.state.breadCrumbsArray.length - 1].industryName !== "" ? "  (" + this.state.breadCrumbsArray[this.state.breadCrumbsArray.length - 1].industryName + ")" : null}
										</span>
									</div>

									<div className="medium-12">
										{this.state.supplierData !== undefined && this.state.supplierData !== null ?
											<SupplierScopeDetails
												data={this.state.supplierData}
												content={this.props.content}
												validation={this.props.validation}
												showSupplierDrilldownModal={this.showSupplierDrilldownModal}
												showSupplierIndustriesModal={this.showSupplierIndustriesModal}
											/>
											: <div className="pt10">{this.props.content.noDataFound}</div>}
									</div>

								</div>
							</div>
						</div>
					</div>
				</div>
			</div >
		)
	}
}



const mapStateToProps = state => {
	return {
		// language: state.language,
		// ui: state.content.ui,
		user: state.default,
		content: state.content.spendAnalysis.defineScope,
		validation: state.content.validation,
		spendDetails: state.spendDetails,
		currencySymbols: state.content.CurrencySymbols,
		supplierDrilldownPendingChanges: state.spendDetails.supplierDrilldownPendingChanges,
		tabMessage: state.content.spendAnalysis.detailedFile.toggleTabMessage,
		industryDrillScopePendingChanges: state.spendDetails.industryDrillScopePendingChanges,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		defineScopeSuccess: message => dispatch(defineScopeSuccess(message)),
		defineScopeFailure: error => dispatch(defineScopeFailure(error)),
		defineScopeLoading: bool => dispatch(defineScopeLoading(bool)),
		resetError: error => dispatch(resetError(error)),
		getTopDetails: () => dispatch(getTopDetails()),
		getIndustrySupplierDetails: (data) => dispatch(getIndustrySupplierDetails(data)),
		getSupplierCountryCodes: (defineScope) => dispatch(getSupplierCountryCodes(defineScope)),
		getSummaryScopeSolution: (formValues) => dispatch(getSummaryScopeSolution(formValues)),
		getSupplierScopeDetails: (formValues) => dispatch(getSupplierScopeDetails(formValues)),
		storeSupplierSKEY: (SKEY) => dispatch(storeSupplierSKEY(SKEY)),
		trackSupplierDrillDownChanges: changes => dispatch(trackSupplierDrillDownChanges(changes)),
		trackSupplierDrillDownSave: message => dispatch(trackSupplierDrillDownSave(message)),
		trackIndustryDrillScopeChanges: bool => dispatch(trackIndustryDrillScopeChanges(bool)),
		storeIndustryGICS_ID: (GICS_ID) => dispatch(storeIndustryGICS_ID(GICS_ID))
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(IndustryBreakdownContainer)
