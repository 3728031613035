import React from "react";
import ReactDOM from "react-dom";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment'
import { store } from 'index'

export default class CalendarDatePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = { cellValue: props.value, startDate: new Date(), isDateSelected: false, isDateTyped: false };
    this.clear = this.clear.bind(this)
  }

  getValue() {
    let upd = {}
    upd[this.props.column.key] = this.state.cellValue
    return upd;
  }

  getInputNode() {
    return ReactDOM.findDOMNode(this).getElementsByTagName("input")[0];
  }

  handleChangeComplete = date => {
    if (this.state.isDateTyped === false) {
      var momentObj = moment(date, 'MM/DD/YYYY');
      var momentString = momentObj.format('MM/DD/YYYY');
      Object.assign(this.state, { startDate: date, cellValue: momentString, isDateSelected: true });
    }
    this.props.onBlur();
  };

  handleDateChangeRaw = (e) => {
    let s = document.getElementById('date_picker_id')
    s.value = moment(this.state.cellValue).format("MM/DD/YYYY");
    Object.assign(this.state, { isDateTyped: true });
    this.props.onBlur();
  }

  clear() {
    var self = this;
    Object.assign(self.state, { startDate: null, cellValue: null, isDateSelected: false });
    this.props.onBlur();
  }

  render() {
    const state = store.getState()
    var clearDate = state.content.spendAnalysis.detailedFile.clearDate;
    return (
      <div className="calendar-date-picker" style={{ width: "100px !important", height: "35px" }}>
        <DatePicker
          id={'date_picker_id'}
          selected={this.state.isDateSelected ? this.state.startDate : null}
          onChange={this.handleChangeComplete}
          placeholderText={this.state.cellValue}
          showYearDropdown
          dateFormatCalendar="MMMM"
          yearDropdownItemNumber={15}
          scrollableYearDropdown
          onChangeRaw={event =>
            this.handleDateChangeRaw(event.target.value)
          }
        />
        {this.state.cellValue !== null && this.state.cellValue !== '' ?
          <button type="button" class="react-datepicker__close-icon" onClick={this.clear}
            title={clearDate}
            aria-label="Close" tabindex="-1"></button> : null}
      </div>
    );
  }
}