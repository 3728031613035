import { Control } from 'react-redux-form'
import React from "react";
import { Checkbox } from 'material-ui';
import Popup from "reactjs-popup";
import CustomGrid from 'UI/CustomGrid'
import Button from 'UI/Button'
import PopupButton from 'UI/PopupButton'
import Select from 'react-select'
import { RadioButton } from 'material-ui';

import ReactDOM from "react-dom";
import CustomGridSCC from '../CustomGridSCC';
//import { ReadOnlyContext } from 'Constants'

export const ReadOnlyContext = React.createContext({
	readOnly: false
  })

export const ScreenE = (props) => {
    return (<ReadOnlyContext.Provider value={props.readOnly}>
                {props.children}
            </ReadOnlyContext.Provider>)
}

export const ControlTextE = (props) => {
    return (
        <ReadOnlyContext.Consumer>
            {(readOnly) => (
              <Control.text readOnly={readOnly} {...props}/>
            )}
        </ReadOnlyContext.Consumer>)
}

export const ControlTextNativeE = (props) => {
    return (
        <ReadOnlyContext.Consumer>
            {(readOnly) => (
              <input type="text" disabled={readOnly} {...deleteDisabledProps(props)} />
            )}
        </ReadOnlyContext.Consumer>)
}

export const SelectE = (props) => {
    return (
        <ReadOnlyContext.Consumer>
            {(readOnly) => (
              (readOnly? <Select isDisabled={readOnly} {...props}/> : <Select {...props}/>)
            )}
        </ReadOnlyContext.Consumer>)
}

export const CheckboxE = (props) => {
    return (
        <ReadOnlyContext.Consumer>
            {(readOnly) => (
              <Checkbox disabled={readOnly} {...props}/>
            )}
        </ReadOnlyContext.Consumer>)
}

export const PopupE = (props) => {
    return (
        <ReadOnlyContext.Consumer>
            {(readOnly) => (
              <Popup disabled={readOnly} {...props}/>
            )}
        </ReadOnlyContext.Consumer>)
}

export const CustomGridE = (props) => {
    return (
        <ReadOnlyContext.Consumer>
            {(readOnly) => (
              <CustomGrid readOnly={readOnly} {...props}/>
            )}
        </ReadOnlyContext.Consumer>)
}

export const CustomGridSccE = (props) => {
    return (
        <ReadOnlyContext.Consumer>
            {(readOnly) => (
              <CustomGridSCC readOnly={readOnly} {...props}/>
            )}
        </ReadOnlyContext.Consumer>)
}

export const RadioButtonE = (props) => {
    return (
        <ReadOnlyContext.Consumer>
            {(readOnly) => (
              <RadioButton disabled={readOnly} {...props}/>
            )}
        </ReadOnlyContext.Consumer>)
}

export const RadioButtonNativeE = (props) => {
    return (
        <ReadOnlyContext.Consumer>
            {(readOnly) => (
                <input type="radio" disabled={readOnly} {...props} />
            )}
        </ReadOnlyContext.Consumer>)
}

export const ButtonE = (props) => {
    return (
        <ReadOnlyContext.Consumer>
            {(readOnly) => (
              (readOnly? <Button showLoader={false} disabled {...deleteDisabledProps(props)}>{props.content}</Button> : <Button {...props}/>)
            )}
        </ReadOnlyContext.Consumer>)
}

export const PopupButtonE = (props) => {
    //const newProps = {...props}
    //delete newProps.disabled;

    return (
        <ReadOnlyContext.Consumer>
            {(readOnly) => (
              (readOnly? <PopupButton showLoader={false} disabled {...deleteDisabledProps(props)}>{props.content}</PopupButton> : <PopupButton {...props}/>)
            )}
        </ReadOnlyContext.Consumer>)
}

export const deleteDisabledProps = (props) => {
    const newProps = {...props}
    delete newProps.disabled;

    return newProps
}

export const ButtonNativeE = (props) => {
    return (
        <ReadOnlyContext.Consumer>
            {(readOnly) => (
              (readOnly? <button disabled {...deleteDisabledProps(props)}>{props.content}</button> : <button {...props}/>)
            )}
        </ReadOnlyContext.Consumer>)
}

export const TextAreaE = (props) => {
    const newProps = {...props}
    delete newProps.disabled;

    return (
        <ReadOnlyContext.Consumer>
            {(readOnly) => (
              (readOnly? <textarea disabled {...newProps}>{newProps.content}</textarea> : <textarea {...props}></textarea>)
            )}
        </ReadOnlyContext.Consumer>)
}