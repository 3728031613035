import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Control, Form, Errors, actions } from 'react-redux-form'

// Actions
import {
	resetPassword,
	resetPasswordSuccess,
	resetPasswordFailure,
	fetchUserDetails,
	fetchUserDetailsSuccess
}
	from 'actions/userActions'

// UI
import Textfield from 'react-mdl/lib/Textfield'
import Button from 'UI/Button'

// Utils
import { getParameterByName } from 'utils/Utils'

// Settings
import { validators } from 'Constants'

const styles = { width: '70%' }

class ResetPasswordContainer extends Component {

	constructor(props) {
		super(props)
		this.state = {
			userData: {
				FirstName: 'FirstName',
				LastName: 'LastName',
				Company: 'Company'				
			}
		}
		this.resetPassword = this.resetPassword.bind(this)
	}

	resetPassword(formValues) {
		let values = Object.assign({}, formValues)
		values.Language = this.props.language.toUpperCase()
		values.LoginUser = this.props.user.user.Id
		let content = this.props.content
		this.props.resetPassword(values)
			.then((result) => {
				if (result.status === 200) {
					this.props.resetPasswordSuccess(content[result.status])
					// Reset form
					this.formDispatch(actions.reset('resetPassword'))
				}
			}).catch((error) => {
				this.props.resetPasswordFailure(error.response.data.Message)
			})
	}

	fetchUserDetails(e)
	{	
		this.props.fetchUserDetails(e.target.defaultValue)
			.then((result) => {
				if (result.status === 200) {
					console.log(result.data.Result);
					this.setState({userData: result.data.Result})
					this.props.fetchUserDetailsSuccess(result.data.Result)					
				}
			}).catch((error) => {
				this.props.resetPasswordFailure(error.response.data.Message)
			})
	}

	render() {
		return (
			<div className="grid-container pt20 popup-content">
				<div className="medium-6 cell">
					<div className="title">{this.props.content.title}</div>
					{
						this.props.user.status !== 'password_reset' ?
							(
								<div>
									<p>{this.props.content.description}</p>
									<Form
										model="resetPassword"
										onSubmit={(values) => this.resetPassword(values)}
										getDispatch={(dispatch) => this.formDispatch = dispatch}
										validators={{
											'': { passwordsMatch: (vals) => vals.Password === vals.ConfirmPassword
												,charMatch: (vals) => (vals.Password.toLowerCase().indexOf(this.state.userData.FirstName.toLowerCase())=== -1 && vals.Password.toLowerCase().indexOf(this.state.userData.LastName.toLowerCase())=== -1 
													&& vals.Password.toLowerCase().indexOf(this.state.userData.Company.toLowerCase()) === -1)
												 }
										}}
									>
										<Control.text
											model="resetPassword.Email"
											id="resetPassword.Email"
											component={Textfield}
											style={styles}
											label={this.props.content.email}
											floatingLabel
											validators={{
												validEmail: validators.validEmail
											}}
											
											validateOn="blur"
											onBlur={(e)=>this.fetchUserDetails(e)}
										/>
										<Errors
											className="errors"
											model="resetPassword.Email"
											show="touched"
											messages={{
												validEmail: this.props.validation.validEmail
											}}
										/>
										<Control.text
											model="resetPassword.Password"
											id="resetPassword.Password"
											type="password"
											component={Textfield}
											style={styles}
											label={this.props.content.password}
											floatingLabel
											validators={{
												required: validators.required,
												validPassword: validators.validPassword
											}}
										/>
										<Errors
											className="errors"
											model="resetPassword.Password"
											show="touched"
											messages={{
												validPassword: this.props.validation.validPassword
											}}
										/>
										<Errors
					              			className="errors"
					              			model="resetPassword"
					              			show="touched"
					              			messages={{
					              				charMatch: this.props.validation.charMatch
					              			}}
					                 	/>
										<Control.text
											model="resetPassword.ConfirmPassword"
											id="resetPassword.ConfirmPassword"
											type="password"
											component={Textfield}
											style={styles}
											label={this.props.content.confirmPassword}
											floatingLabel
											validators={{
												required: validators.required,
												validPassword: validators.validPassword
											}}
										/>
										<Errors
											className="errors"
											model="resetPassword.ConfirmPassword"
											show="touched"
											messages={{
												validPassword: this.props.validation.validPassword
											}}
										/>
										<Errors
											className="errors"
											model="resetPassword"
											show="touched"
											messages={{
												passwordsMatch: this.props.validation.passwordsMatch
											}}
										/>
										<Control.text
											model="resetPassword.Code"
											id="resetPassword.Code"
											type="hidden"
											value={this.props.code}
											defaultValue={this.props.code}
										/>
										<div className="pt13">
										<Button
											type="submit"
											disabled={this.props.user.loading}
											content={this.props.content.cta}
											color
										/>
										</div>
									</Form>
									<div className="errors">{this.props.user.error}</div>
								</div>
							) :
							(
								<div>
									<p>{this.props.user.message}</p>
									<a href="/">
										<Button
											content={this.props.ui.home}
											icon="left-arrow"
											iconPosition="left"
											color
										/>
									</a>
								</div>
							)
					}
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		language: state.language,
		ui: state.content.ui,
		user: state.default,
		content: state.content.resetPassword,
		validation: state.content.validation
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		resetPassword: (email) => dispatch(resetPassword(email)),
		resetPasswordSuccess: (message) => dispatch(resetPasswordSuccess(message)),
		resetPasswordFailure: (error) => dispatch(resetPasswordFailure(error))
		,fetchUserDetails: (email) => dispatch(fetchUserDetails(email)),
		fetchUserDetailsSuccess: (message) =>dispatch(fetchUserDetailsSuccess(message))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordContainer)