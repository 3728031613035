import React from 'react';
import { connect } from 'react-redux';
import Modal from 'reboron/OutlineModal'
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';
import { modalStyle, modalContentStyle } from 'Constants'
const videoControlModalContentStyle = Object.assign({}, modalContentStyle);
videoControlModalContentStyle.padding = "15px";
const videoControlModalStyle = Object.assign({}, modalStyle);
videoControlModalStyle.width = "95vw";
videoControlModalStyle.height = "95vh";
videoControlModalStyle.maxWidth = "95vw";
//videoControlModalStyle.maxWidth = "1400px";

const styles = {
    block: {
        maxWidth: 250,
    },
    radioButton: {
        marginBottom: 16,
    },
};

class Video extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentVideo: {},
            videoDetails: { title: '', videos: [] }
        }

        this.selectVideo = this.selectVideo.bind(this)
    }

    selectVideo(item) {
        this.setState({ currentVideo: item })
    }

    render() {
        let currentVideo = this.state.currentVideo;
        if (!currentVideo.file && this.props.videoDetails.videos.length > 0) {
            currentVideo = this.props.videoDetails.videos[0]
        }
        return (
            <div>
                {this.refs.videoControl ? this.props.open ?
                    this.refs.videoControl.show() :
                    this.refs.videoControl.hide() : ''}
                <Modal
                    ref={'videoControl'}
                    modalStyle={videoControlModalStyle}
                    contentStyle={videoControlModalContentStyle}
                    onHide={(e) => {
                        if (e === 'backdrop') {
                            this.props.visibility(false)
                        }
                    }}
                >
                    <div className="grid-x popup-content">
                        <div className="medium-12">
                            <div className="title">{this.props.videoDetails.title}</div>
                        </div>
                        <div className="medium-12 pt30">
                            <div className="grid-x">
                                <div className="medium-2" style={{overflow: 'auto', height: '80vh'}}>
                                    <RadioButtonGroup name="videos" defaultSelected={currentVideo.name}>
                                        {this.props.videoDetails.videos.map((item, idx) => {
                                            return <RadioButton
                                                value={item.name}
                                                label={<span><div style={{ fontSize: '18px' }}>{item.name}</div><div className="font-small">{item.desc}</div></span>}
                                                style={styles.radioButton}
                                                onClick={(e) => this.selectVideo(item)} />
                                        })}
                                    </RadioButtonGroup>
                                </div>
                                <div className="medium-10" style={{ border: '1px solid grey' }}>
                                    {currentVideo.sourceType ? <video key={currentVideo.file} width="100%" height="100%" controls autoPlay>
                                        <source src={currentVideo.file} type="video/mp4" />
                                    Your browser does not support the video tag.
                                    </video> : <iframe width="100%" height="100%" key={currentVideo.file} src={currentVideo.file}></iframe>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <button onClick={() => this.props.visibility(false)} className='close' />
                </Modal>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        //content: state.content,
        //ui: state.content.ui,
        user: state.default,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Video); 