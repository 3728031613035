import React from 'react'
import PropTypes from 'prop-types'

// Components
import Priority from './Priority'

// Utils
import classnames from 'classnames'
import {
	getCurrentLanguage
} from 'utils/Utils'

const PrioritiesGroup = (props) => {

	var subIndex = (subQuesdata) => {
		const language = getCurrentLanguage()
		var sIndex = 0
		var obj = props.userData.map((data, i) => {
			var itemName = language === "en" ? "SubItem" : "SubItem" + language.toUpperCase()
			if (props.userData[i][itemName].trim() === subQuesdata.subQuestion.trim()) {
				sIndex = i
			};
		})
		return sIndex;
	}

	var subQuestionLength = (subQuesdata) => {
		var lenSubQtn = 0
		//var obj = props.userData.map((data, i) => {
		//if (props.userData[i].SubItem.trim() === subQuesdata.subQuestion.trim()) {
		lenSubQtn = subQuesdata.subQuestion.length//props.userData[i].SubItem.length
		//};
		//})
		return lenSubQtn;
	}

	let counter = props.rowIdx;

	return (
		<div className="grid-x grid-padding-x">
			<div className="question medium-2 cell">{props.content.question}</div>
			<div className="medium-10 cell priority-table-row">
				<div className="grid-x">
					<div className="cell">
						{props.content.subQuestions.map((data, i) => {
							counter++;
							return <div key={data.subQuestion} className={"grid-x grid-row " + (counter % 2 ? 'even' : '')}>
								<div className="medium-7 cell question-title"> {data.subQuestion}</div>
								<div className="medium-5 cell">
									<Priority
										id={props.id}
										subIndex={subIndex(data)}
										userData={props.userData}
										jsonSubIndex={data.id}
										dispatchRadioButtonChange={props.dispatchRadioButtonChange}
									/>
								</div>
							</div>
						}
						)}
					</div>
				</div>
			</div>
		</div>
	)
}

export default PrioritiesGroup

PrioritiesGroup.propTypes = {
	id: PropTypes.number.isRequired,
	content: PropTypes.shape({
		question: PropTypes.string.isRequired,
		subQuestions: PropTypes.array.isRequired
	}),
	userData: PropTypes.array.isRequired,
	dispatchRadioButtonChange: PropTypes.func.isRequired
}
