import React, { Fragment } from 'react';
import { connect } from 'react-redux'
import SpendYourFileContainer from 'components/Spend/YourSpendFile/SpendYourFileContainer'
import ClassificationSummary from 'components/Spend/ClassificationAndTopDetails/ClassificationSummary'
import TopDetailsContainer from 'components/Spend/ClassificationAndTopDetails/TopDetailsContainer'
import ProgramEnablementAnalysis from 'components/Spend/ProgramEnablementSummary/ProgramEnablementAnalysis'
import AribaNetworkEnablementAnalysis from 'components/Spend/ProgramEnablementSummary/AribaNetworkEnablementAnalysis'
import GanntChartContainer from 'components/Spend/GantChart/GanntChartContainer'
import EnablementWavesTable from 'components/Spend/EnablementInsight/EnablementWavesTable'
import AccountParameters from 'components/Spend/YourSpendFile/AccountParameters'
import SubTabsPanel from '../Tabs/SubTabsPanel'
//import EnablementStrategyWizard from 'components/Spend/YourSpendFile/EnablementStrategyWizard'
//import EnablementResourceParameters from 'components/Spend/YourSpendFile/EnablementResourceParameters'
import { redirectFromEnablementTab } from 'actions/spendActions'
import KPIContainer from 'components/Kpi/KPIContainer'
import KPIDashboard from 'components/Kpi/KPIDashboard'
import KPIDashboardV2 from 'components/Kpi/KPIDashboardV2'
import KPIDashboardManualV2 from 'components/Kpi/KPIDashboardManualV2'
import KPIChartsV2 from 'components/Kpi/KPIChartsV2'
import EnablementStrategyWizardContainer from './YourSpendFile/EnablementStrategyWizardContainer';
import CollectCustomerSpend from '../Discovery/CollectCustomerSpend';
import MatchClassificationContainer from '../Discovery/Match&ClassificationContainer';
import EnablementRecommendations from 'components/Spend/EnablementInsight/EnablementRecommendations';
import {
	setSubTabMenu
}
	from 'actions/userActions'

class SubTabsContainer extends React.Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef()
        this.state = {
            isRequestFileActive: true,
            tabId: 1,
            tabs: [],
            filterValues: {
                DMC_ID: this.props.spendDetails !== undefined && this.props.spendDetails.currentActiveRequest !== undefined ? this.props.spendDetails.currentActiveRequest.DMC_ID : 0
                , VS_ID: this.props.spendDetails !== undefined && this.props.spendDetails.currentActiveRequest !== undefined ? this.props.spendDetails.currentActiveRequest.VS_ID : 0,
                User_Email: this.props.spendDetails.currentActiveRequest !== undefined &&
                    this.props.spendDetails.currentActiveRequest.SAPRequester === this.props.user.user.Email ? this.props.user.user.Email : this.props.spendDetails.currentActiveRequest.SAPRequester,

                dedupStats: 'all', 
                enabledCustomer: 'all',
                transactingCustomer: 'all',
                buyerRegion: 'all',
                businessUnit: 'all',
                erpSystem: 'all',
                l4IndustryName: 'all',
                erpCode: 'all',
                erpDesc: 'all',
                sicCode: 'all',
                sicDesc: 'all',

                goods: 'all',
                invoiceCountry: 'all',
                legalInvoice: 'all',
                aribaNwEligible: 'all',
                metricsType: 'Invoice & PO',
                directSpend: 'all',

            },
        }
        this.dispatchTabChange = this.dispatchTabChange.bind(this)
        this.intializeSupplierTabs = this.intializeSupplierTabs.bind(this)
    }
    componentWillReceiveProps(nextProps) {
        if (this.props !== nextProps) {
            if (this.props.param === this.props.content.yourFile.title) {
                if (this.props.spendDetails !== undefined && this.props.spendDetails.currentActiveRequest !== undefined && this.props.spendDetails.currentActiveRequest !== "")
                    this.setState({ isRequestFileActive: true })
                else
                    this.setState({ isRequestFileActive: false })
                this.intializeSupplierTabs();
            }
        }
    }

    componentDidMount() {
        this.intializeSupplierTabs();
    }

    intializeSupplierTabs() {
        let tabs = []
        if (this.props.param === this.props.content.yourFile.title) {
            if (this.state.isRequestFileActive) {
                tabs = [{
                    id: 1,
                    label: "Value Story",//this.props.content.yourFile.companyTitle,
                    component: (<SpendYourFileContainer history={this.props.history} />)
                },
                // {
                //     id: 2,
                //     label: "Enriched Spend",//this.props.content.accountParameters.spendParameters,
                //     component: (<MatchClassificationContainer/>) //(<AccountParameters />)
                // },
                {
                    id: 2,
                    label: this.props.content.accountParameters.spendParameters,
                    component: (<AccountParameters />)
                },
                {
                    id: 3,
                    label: this.props.content.prioritizationWizard.title,
                    component: (<EnablementStrategyWizardContainer history={this.props.history} />)
                },
                {
                    id: 4,
                    label: "Enablement Recommendations",
                    component: (<div className="grid-x"><EnablementRecommendations filterData={this.state.filterValues} /></div>)
                },
                ];
            }
            else {
                tabs = [{
                    id: 1,
                    label: "Value Story",
                    component: (<SpendYourFileContainer history={this.props.history}/>)
                }
                // ,{
                //     id: 2,
                //     label: "Enriched Spend",
                //     component: (<MatchClassificationContainer/>) 
                // }
                ];
            }
        }
        else if (this.props.param === this.props.content.results.title) {
            tabs = [{
                id: 1,
                label: this.props.content.results.classificationSummary,
                component: (<ClassificationSummary />)
            },
            {
                id: 2,
                label: this.props.content.topDetails.title,
                component: (<TopDetailsContainer />)
            }
            ];
        }
        else if (this.props.param === this.props.content.enablement.title) {
            tabs = [{
                id: 1,
                label: this.props.content.enablement.title,
                component: (<ProgramEnablementAnalysis />)
            },
            {
                id: 2,
                label: this.props.content.enablement.aribaNetworkMatch,
                component: (<AribaNetworkEnablementAnalysis />)
            }
            ];
        }
        else if (this.props.param === this.props.ganntTexts.title) {
            tabs = [{
                id: 1,
                label: this.props.ganntTexts.title,
                component: (<GanntChartContainer />)
            },
            {
                id: 2,
                label: this.props.ganntTexts.enablementStrategy,
                component: (<EnablementWavesTable />)
            }
            ];
        }
        else if (this.props.param === 'KPI') {
            tabs = [
            // {
            //     id: 1,
            //     label: 'KPI Setup',
            //     component: (<KPIContainer />)
            // },
            {
                id: 1,
                label: 'KPI Dashboard',
                component: (<KPIDashboardV2 />)
            },
            {
                id: 3,
                label: 'KPI Charts',
                component: (<KPIChartsV2 />)
            }
            ];
        }
        else if (this.props.param === 'KPI-V2') {
            tabs = [            
            {
                id: 1,
                label: 'KPI Dashboard',
                component: (<KPIDashboardManualV2 />)
            },
            {
                id: 3,
                label: 'KPI Charts',
                component: (<KPIChartsV2 />)
            }        
            ];
        }

        this.setState({
            tabs: tabs,
            tabId: 1
        })
    }

    dispatchTabChange(tabId) {
        this.props.setSubTabMenu(tabId);
        this.setState({
            tabId: tabId,
        })
    }

    componentWillUnmount() {
        this.props.redirectFromEnablementTab(false);
    }

    render() {
        return (
            <div className="grid-x">
                <div className="medium-12">
                    {<SubTabsPanel
                        tabs={this.state.tabs}
                        tabId={this.props.redirectFlag ? 3 : 1}
                        dispatchTabChange={this.dispatchTabChange}
                        dispatchLastButtonClick={this.dispatchLastButtonClick}
                    />}
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        spendDetails: state.spendDetails,
        content: state.content.spendAnalysis,
        user: state.default,
        ganntTexts: state.content.ganntChartTab,
        redirectFlag: state.spendDetails.isRedirected,
        subTabMenu: state.setSubTabMenu
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        redirectFromEnablementTab: (flag) => dispatch(redirectFromEnablementTab(flag)),
        setSubTabMenu:(val)=>dispatch(setSubTabMenu(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SubTabsContainer); 