import React from 'react'
import PrioritiesGroup from './PrioritiesGroup'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
// Utils
import { getCurrentPriorityId } from 'utils/Utils'

class Priorities extends React.Component {
	constructor(props) {
		super(props);
	}

	componentWillMount() {
		window.scrollTo({top: 0, behavior: 'smooth'})
	}
	
	render() {
		let questions = this.props.content.prioritySections[this.props.priorityId].questions;
		let ctr;
		return (
			<div className="grid-x bottom-space">
				<div className="questions cell">
					{questions.map((data, i) => {
						ctr = i > 0 ? ctr + questions[i - 1].subQuestions.length : 0;
						return (<PrioritiesGroup
							key={i}
							rowIdx={ctr}
							content={data}
							id={i} 
							userData={this.props.userData.AreaOfInterest[this.props.priorityId].AOIValueDrivers}
							dispatchRadioButtonChange={this.props.dispatchRadioButtonChange}
						/>)
					})
					}
				</div>
			</div>
		)
	}
	

}




const mapStateToProps = (state) => {
	return {
		content: state.content.priorities,
		userData: state.valueStory
	}
}

export default connect(mapStateToProps)(Priorities)

Priorities.propTypes = {
	content: PropTypes.array.isRequired,
	userData: PropTypes.array.isRequired,
	dispatchRadioButtonChange: PropTypes.func.isRequired
}