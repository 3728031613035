import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import SupplierIndustriesTabPanel from '../Tabs/SupplierIndustriesTabPanel'
import IndustriesScopeAndMatch from 'components/Spend/IndustriesScopeAndMatch'
import Modal from 'reboron/OutlineModal'
import { storeIndustryGICS_ID, storeIndustryDetails } from 'actions/spendActions'
import { changeModalBorderStyle, drilldownModalBorderContentStyle, changeModalBorderRectStyle } from 'Constants'
import IndustrySpend from './IndustrySpend'
import IndustrySupplier from './IndustrySupplier'
import IndustryNetworkStatsHistogram from './IndustryNetworkStatsHistogram'
import IndustryScopeSummary from './IndustryScopeSummaryGraph'
import IndustryCapIQDetails from './IndustryCapIQDetails'
import IndustryNetworkStatsDetails from './IndustryNetworkStatsDetails'
import IndustryNetworkStats from './IndustryNetworkStats'

class SupplierIndustriesPopup extends React.Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef()
        this.state = {
            tabId: 1,
            tabs: [],
            tabsWithoutCapIQ: [],
            tabsWithoutParentCapIQ: [],
            tabsWithoutCapIQAndParentCapIQ: [],
            selectedIndustryName: '',
            isCapIQLicesend: false
        }

        this.dispatchTabChange = this.dispatchTabChange.bind(this)
        this.intializeSupplierTabs = this.intializeSupplierTabs.bind(this)
        this.changeIndustry = this.changeIndustry.bind(this)
    }

    componentDidMount() {
       // this.intializeSupplierTabs();
       this.setState({ isCapIQLicesend: this.props.user.user.applicationFeatures.findIndex(a => a.Action === this.props.capIQ.capIQAction) > -1 ? true : false })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.spendDetails.industryGICS_Details) {
            this.intializeSupplierTabs(nextProps);
        }
    }

    intializeSupplierTabs(nextProps) {
        let filters = nextProps.spendDetails.industryGICS_Details.industryData.filters;
        const tabs = this.state.isCapIQLicesend ? [
            {
                id: 1,
                label: this.props.content.industryScopeandMatch,
                component: (<div className="grid-x"><IndustriesScopeAndMatch filters={filters} /></div>)
            },
            {
                id: 2,
                label: this.props.content.industrySpend,
                component: (<IndustrySpend filters={filters} />)
            },
            {
                id: 3,
                label: this.props.content.industrySuppliers,
                component: (<IndustrySupplier filters={filters} />)
            },
            {
                id: 4,
                label: this.props.content.industryScopeSummary,
                component: (<IndustryScopeSummary />)
            },
            // {
            //     id: 5,
            //     label: this.props.content.industryNetworkStatsHistogram,
            //     component: (<IndustryNetworkStatsHistogram />)
            // },

            {
                id: 6,
                label: this.props.content.industryCapIQDetails,
                component: (<IndustryCapIQDetails filters={filters} />)
            },
            // {
            //     id: 7,
            //     label: this.props.content.industryNetworkStatsDetails,
            //     component: (<IndustryNetworkStatsDetails />)
            // },
            {
                id: 8,
                label: this.props.content.industryNetworkStats,
                component: (<IndustryNetworkStats />)
            }] :
            [
                {
                    id: 1,
                    label: this.props.content.industryScopeandMatch,
                    component: (<div className="grid-x"><IndustriesScopeAndMatch filters={filters} /></div>)
                },
                {
                    id: 2,
                    label: this.props.content.industrySpend,
                    component: (<IndustrySpend filters={filters} />)
                },
                {
                    id: 3,
                    label: this.props.content.industrySuppliers,
                    component: (<IndustrySupplier filters={filters} />)
                },
                {
                    id: 4,
                    label: this.props.content.industryScopeSummary,
                    component: (<IndustryScopeSummary />)
                },
                // {
                //     id: 5,
                //     label: this.props.content.industryNetworkStatsHistogram,
                //     component: (<IndustryNetworkStatsHistogram />)
                // },
                // {
                //     id: 7,
                //     label: this.props.content.industryNetworkStatsDetails,
                //     component: (<IndustryNetworkStatsDetails />)
                // },
                {
                    id: 8,
                    label: this.props.content.industryNetworkStats,
                    component: (<IndustryNetworkStats />)
                }
        ];

        this.setState({
            tabs: tabs,
            tabId: 1,
        })
    }

    dispatchTabChange(tabId) {
        this.setState({
            tabId: tabId,
        })
    }

    changeIndustry(param) {
        let industryData = this.props.spendDetails.industryGICS_Details.industryData
        industryData.params.name = param.name;
        var industryGICS_Details = { SKEY: param.gicsId, industry: param.name, level: param.level, industryData: industryData }
        this.props.storeIndustryGICS_ID(industryGICS_Details)

        let header = param.gicsId + ': ' + param.name;
        let subHeader = this.props.spendDetails.industryDetails.subHeader;

        let industryDetails = { header: header, subHeader: subHeader }
        this.props.storeIndustryDetails(industryDetails)
    }

    render() {
        return (
            <div>
                {this.refs.supplierIndustries ? this.props.open ?
                    this.refs.supplierIndustries.show() :
                    this.refs.supplierIndustries.hide() : ''}
                <Modal
                    ref={'supplierIndustries'}
                    modalStyle={changeModalBorderStyle}
                    contentStyle={drilldownModalBorderContentStyle}
                    rectStyle={changeModalBorderRectStyle}
                    keyboard={false}
                    backdrop={false}
                >
                    <div className="grid-x tabs_container supplier-tabs">
                        {this.props.spendDetails.industryDetails !== undefined ?
                            <div className="medium-12">
                                <div style={{ fontSize: '16px', color: '#008fd3', paddingLeft: '5px' }} >{this.props.spendDetails.industryDetails["header"]}</div>
                                {this.props.spendDetails.industryDetails.subHeader.map((item, index) => {
                                    let level = Number.parseInt(this.props.spendDetails.industryGICS_Details.level)
                                    if (level >= (index + 1))
                                        return <span className='hand-cursor' onClick={e => this.changeIndustry(item)} style={{ fontSize: '15px', paddingTop: '3px', paddingLeft: '5px' }}>{item.name} {level !== index + 1 ? '|' : ''}</span>

                                    return ''
                                })
                                }
                            </div>
                            :
                            null}
                        <div className="medium-12">
                            {<SupplierIndustriesTabPanel
                                //tabs={this.state.tabs}
                                tabs={(this.props.user.userprofile.Roles[0].indexOf("Standard Internal User") > -1
                                    || this.props.user.userprofile.Roles[0].indexOf("Admin") > -1) ?
                                    this.state.tabs :
                                    this.state.tabs.filter(function (value) {
                                        return (value.id !== 8) //Industry network Stats
                                    })}
                                tabId={1}
                                dispatchTabChange={this.dispatchTabChange}
                                dispatchLastButtonClick={this.dispatchLastButtonClick}
                            />}
                        </div>
                    </div>
                    <button onClick={() => { this.props.visibility(false); this.setState({ selectedIndustryName: {} }) }} className='close' />
                </Modal>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        content: state.content.spendAnalysis.industryDrilldown.tabNames,
        spendDetails: state.spendDetails,
        user: state.default,
        capIQ: state.content.spendAnalysis.detailedFile
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        storeIndustryGICS_ID: (GICS_ID) => dispatch(storeIndustryGICS_ID(GICS_ID)),
        storeIndustryDetails: (data) => dispatch(storeIndustryDetails(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SupplierIndustriesPopup); 