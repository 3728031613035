import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import ScrollArea from 'react-scrollbar'

import { valueStoryReset } from 'actions/fetchValueStory'

// Utils
import classnames from 'classnames'

// Components
import ValueMenuItems from './ValueMenuItems'

// UI
import { modalStyle, path, routes, changeModalStyle, changeModalContentStyle } from 'Constants'
import ValueMenuItemsExpanded from './ValueMenuItemsExpanded';

// Actions
import {
  valueStoryCurrentMenu,
  syncMenuAndButton
}
  from 'actions/userActions'
import {
  pendingChanges
} from 'actions/spendActions'

import Modal from 'reboron/OutlineModal'
import Button from 'UI/Button'

// Settings
const autoHeightModalStyle = Object.assign({}, modalStyle)

const valueStoryModalStyle = Object.assign({}, modalStyle)
autoHeightModalStyle.height = 'auto'
valueStoryModalStyle.maxWidth = '1024px'
valueStoryModalStyle.height = 'auto'

const modalContentStyle = {
  padding: '70px',
  height: '50%',
  overflowY: 'auto',
  background: 'white'
}

class ValueMenuContainer extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      expanded: false,
      menuItem: ''
    }

    this.showMenu = this.showMenu.bind(this)
    this.handleSelectedMenuItem = this.handleSelectedMenuItem.bind(this)
    this.showModal = this.showModal.bind(this)
    this.hideModal = this.hideModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
  }

  handleSelectedMenuItem(e, menuItem) {
    window.scrollTo({top: 0, behavior: 'smooth'})
    if (this.props.change == null) {
      this.props.valueStoryCurrentMenu(menuItem);
    }
    else {
      e.preventDefault()
      switch (menuItem) {
        case 'Company':
          this.setState({ menuItem: routes.discovery })
          break
        case 'Priorities':
          this.setState({ menuItem: routes.areaOfInterest })
          break
        case 'Solutions':
          this.setState({ menuItem: routes.skus })
          break
        case 'Business Case':
          this.setState({ menuItem: routes.businessValue })
          break
        case 'Next Steps':
          this.setState({ menuItem: routes.nextSteps })
          break
        default:
          break
      }
      this.showModal("terms")
    }
  }

  showModal(action) {
    this.refs[action].show()
  }

  hideModal(action) {
    this.refs[action].hide()
  }

  closeModal(action) {
    this.props.pendingChanges(null)
    this.refs[action].hide()
    this.props.history.push(this.state.menuItem)
  }

  handleMenuExpand() {
    this.setState({
      expanded: !this.state.expanded
    })
  }

  showMenu() {
    return this.props.user !== null &&
      this.props.user.user !== null &&
      this.props.user.user.applicationFeatures !== null &&
      this.props.user.user.applicationFeatures !== undefined &&
      this.props.userData !== null
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  /**
   * Set the wrapper ref
   */
  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({
        expanded: false
      })
    }
  }

  handleMouseEnter=(e)=>{
    const selectedButton = e.target.innerText;    
		this.props.syncMenuAndButton(e.target.parentNode.id == '' ? selectedButton : e.target.parentNode.id);		
	}

	handleMouseLeave=()=>{
		this.props.syncMenuAndButton('')		
	}

  componentWillMount() {
    this.setState({
      selectedMenuItem: this.props.currentMenuItem //this.props.content.menuItems[0].name
    })
  }

  render() {
    var instance = this

    return this.props.displayValueStoryMenu ? (
      <div ref={this.setWrapperRef}>
        <div
          className={classnames('value-menu',
            'value-menu--visible', {
              'value-menu--expanded': instance.state.expanded
            }
          )}
        >
          <ScrollArea
            speed={0.8}
            className="navigation-area"
            contentClassName="content"
            horizontal={false}
          >
            {
              this.props.content.menuItems.map(function (childMenuitem) {
                return (
                  <div>

                    {instance.state.expanded ?
                      <ValueMenuItemsExpanded
                        content={childMenuitem}
                        menuSelected={instance.props.currentMenuItem}
                        handleSelectedMenuItem={instance.handleSelectedMenuItem}
                        enabled={instance.props.form.valid && !instance.props.newValueStory}
                        user={instance.props.user.user}
                        userData={instance.props.userData}
                        hoverLink ={instance.props.hoverLink}
                        handleMouseEnter={instance.handleMouseEnter}
                        handleMouseLeave={instance.handleMouseLeave}
                      /> :
                      <ValueMenuItems
                        content={childMenuitem}
                        menuSelected={instance.props.currentMenuItem}
                        handleSelectedMenuItem={instance.handleSelectedMenuItem}
                        enabled={instance.props.form.valid && !instance.props.newValueStory}
                        user={instance.props.user.user}
                        userData={instance.props.userData}
                        hoverLink ={instance.props.hoverLink}
                        handleMouseEnter={instance.handleMouseEnter}
                        handleMouseLeave={instance.handleMouseLeave}
                      />}
                  </div>
                )
              })}
            <div className="value-menu__footer-logo"
              onClick={this.handleMenuExpand.bind(this)}
            >
              {instance.state.expanded ?
                <div className="grid-x">
                  <div className="medium-3"><img src={path + this.props.content.collapse} alt="collapse" /></div>
                  <div className="medium-9 side-bar-caption">{this.props.content.collapseSideBar}</div>
                </div> :
                <img src={path + this.props.content.expand} alt="expand" />
              }
            </div>
          </ScrollArea>

        </div>
        <div>
          <Modal
            ref="terms"
            modalStyle={changeModalStyle}
            contentStyle={changeModalContentStyle}
          >
            <div className="grid-x grid-padding-x change-popup">
              <div className="medium-12">
                <button onClick={() => this.hideModal("terms")} className="close" />
              </div>
              <div className="medium-12 pt30">
                {this.props.tabMessage}
              </div>
              <div className="medium-12 popupOkBtn">
                <div className="float-right">
                  <div className="float-left"><Button
                    content={"Continue"}
                    onClick={() => this.closeModal("terms")}
                    color /></div>
                  <div className="float-left"><Button
                    content={"Close"}
                    onClick={() => this.hideModal("terms")}
                    color /></div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </div >
    ) : null
  }
}
const mapStateToProps = state => {
  return {
    content: state.content.valueStoryMenu,
    form: state.discoveryForm.$form,
    validation: state.content.validation,
    user: state.default,
    userData: state.valueStory,
    newValueStory: state.isNewValueStory,
    currentMenuItem: state.valueStoryCurrentMenu,
    displayValueStoryMenu: state.showValueStoryMenu,
    change: state.detailedFileChanges.changes,
    tabMessage: state.content.spendAnalysis.detailedFile.toggleTabMessage,
    hoverLink : state.syncMenuAndButton
  }
}

const mapDispatchToProps = dispatch => {
  return {
    resetValueStory: () => dispatch(valueStoryReset()),
    valueStoryCurrentMenu: (menuItem) => dispatch(valueStoryCurrentMenu(menuItem)),
    pendingChanges: changes => dispatch(pendingChanges(changes)),
    syncMenuAndButton: (selectedLink) => dispatch(syncMenuAndButton(selectedLink))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ValueMenuContainer)
