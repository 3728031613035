import React, { Fragment } from 'react';
import { connect } from 'react-redux'
import { formatThousands } from 'utils/Utils'
import { path } from 'Constants'
import { Tree } from 'primereact/tree';
import { RadioButton } from 'material-ui';
import classNames from "classnames";
import {
    getSPCapitalIQData,
    getSPCapitalIQLoading,
    getSPCapitalIQSuccess,
    getSPCapitalIQFailure,
    getCapIQClassificationIndustries,
    trackSupplierDrillDownChanges,
    trackSupplierDrillDownSave,
    supplierDrillDownUpdate,
    storeFinalIndustryID,
    setPopUpTabLoadingStatus
} from 'actions/spendActions'

import { RadioButtonE } from 'UI/CustomComponents/Controls';

class SPCapitalIQData extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            SPCapitalIQData: [],
            hierarchyIndustryTree: [],
            expandedKeys: {},
            isCapIQLicesend: false,
            checkedIndustries: [],
            isError: 'No',
            isLoading: true,
            responseMsg: '',
            isSaveInProgress: false
        }

        this.getNestedChildren = this.getNestedChildren.bind(this)
        this.LoadData = this.LoadData.bind(this)
        this.nodeTemplate = this.nodeTemplate.bind(this)
        this.handleIndustrySelect = this.handleIndustrySelect.bind(this)
        this.handleSave = this.handleSave.bind(this)
    }

    //Convert parent-child data into nested JSON object
    getNestedChildren(arr, parent) {
        var out = []
        for (var i in arr) {
            if (arr[i].parent == parent) {
                var children = this.getNestedChildren(arr, arr[i].key)
                if (children.length) {
                    arr[i].children = children
                }
                out.push(arr[i])
            }
        }
        return out
    }

    LoadData() {
        var self = this;
        this.props.setPopUpTabLoadingStatus(true);
        this.props.getSPCapitalIQData(this.props.spendDetails.supplierDetails["capIQId"])
            .then((capIQResponse) => {
                if (capIQResponse.status === 200) {
                    if (capIQResponse.data.Result.length > 0) {
                        this.setState({
                            SPCapitalIQData: capIQResponse.data.Result[0],
                            isLoading: false, isError: 'No', responseMsg: '',
                            isCapIQLicesend: self.props.user.user.applicationFeatures.findIndex(a => a.Action === 'CapIQLinkAccess') > -1 ? true : false
                        });
                    }
                    else {
                        this.setState({
                            isLoading: false, isError: 'Yes', responseMsg: this.props.content.noData
                        })
                    }
                }
                else {
                    this.setState({ isError: 'Yes', isLoading: 'false', responseMsg: this.props.content.error })
                }
            }).catch((error) => {
                this.setState({ isError: 'Yes', isLoading: 'false', responseMsg: this.props.content.error })
            })

        this.props.getCapIQClassificationIndustries(this.props.spendDetails.supplierDetails["capIQId"])
            .then((response) => {
                if (response.status === 200) {
                    this.props.setPopUpTabLoadingStatus(false);
                    var hierarchyTree = []
                    var checkedIndustries = []
                    response.data.Result.map(function (row, index) {
                        var obj = { 'key': row["INDUSTRY_ID"], 'label': row["INDUSTRY_DESC"], 'parent': row["SUB_PARENT_ID"], 'industryLevel': row["INDUSTRY_LEVEL"], 'primaryFlag': row["PRIMARY_FLAG"] }
                        hierarchyTree.push(obj)
                        var industryCheckedFlags = { 'key': row["INDUSTRY_ID"], 'checkedFlag': self.props.spendDetails.L5IndustryID === row["INDUSTRY_ID"] ? true : false }
                        checkedIndustries.push(industryCheckedFlags)
                    })

                    var objectData = []
                    try {
                        objectData = this.getNestedChildren(hierarchyTree, null)//Start with null as parent
                    }
                    catch (e) { }

                    let expandedKeys = { ...this.state.expandedKeys };
                    hierarchyTree.map(function (item) {
                        expandedKeys[item.key] = true
                    });
                    this.setState({
                        hierarchyIndustryTree: objectData,
                        expandedKeys: expandedKeys, checkedIndustries: checkedIndustries,
                    })
                }
                else {
                    this.props.setPopUpTabLoadingStatus(false)
                    this.setState({ isError: 'Yes', isLoading: 'false', responseMsg: '' })
                }
            }).catch((error) => {
                this.props.setPopUpTabLoadingStatus(false)
                this.setState({ isError: 'Yes', isLoading: 'false', responseMsg: '' })
            })
    }

    nodeTemplate(node) {
        if (node.industryLevel === 4 && node.primaryFlag === 1) {
            return (
                <div className="grid-x ">
                    <div className="radioButton">
                        <RadioButtonE id={node.key} checked={this.state.checkedIndustries.find(x => x.key === node.key).checkedFlag} onClick={() => this.handleIndustrySelect(node.key)}
                            name={node.key} value={node.label} />
                    </div>
                    <div
                        style={{ color: '#4a4a4a !important', cursor: 'pointer' }} className="font12 font-bold" id={node.key} onClick={() => this.handleIndustrySelect(node.key)} defaultValue={node.key} >{node.label}
                    </div>
                </div>
            )
        }
        else {
            if (node.industryLevel >= 3) {
                return (
                    <div className="grid-x ">
                        <div className="radioButton">
                            <RadioButtonE id={node.key} checked={this.state.checkedIndustries.find(x => x.key === node.key).checkedFlag} onClick={() => this.handleIndustrySelect(node.key)}
                                name={node.key} value={node.label} />
                        </div>
                        <div
                            style={{ cursor: 'pointer' }} className="font12" id={node.key} onClick={() => this.handleIndustrySelect(node.key)} defaultValue={node.key} >{node.label}
                        </div>
                    </div>
                )
            }
            else {
                return (node.label)
            }
        }
    }

    handleIndustrySelect(key) {
        let updatedCheckedIndustries = []
        this.state.checkedIndustries.map(function (row) {
            if (key !== row['key'])
                row["checkedFlag"] = false
            else
                row["checkedFlag"] = true
            updatedCheckedIndustries.push(row)
        });

        if (this.props.spendDetails.L5IndustryID === key)
            this.props.trackSupplierDrillDownChanges(false)
        else
            this.props.trackSupplierDrillDownChanges(true)

        this.setState({ checkedIndustries: updatedCheckedIndustries })
    }

    handleSave() {
        var self = this;
        let GICSID = this.state.checkedIndustries.find(x => x.checkedFlag === true)
        if (GICSID !== undefined && GICSID !== null && GICSID["key"] !== this.props.spendDetails.L5IndustryID) {
            this.setState({ responseMsg: '', isError: 'No', isSaveInProgress: true, isLoading: true })
            const record = {
                VS_ID: this.props.spendDetails.currentActiveRequest.VS_ID,
                DMC_ID: this.props.spendDetails.currentActiveRequest.DMC_ID,
                DATA_COLLECTIONS_SKEY: this.props.spendDetails.supplierDetails["SKEY"],
                USER_ID: this.props.user.user.Email,
                FLAG_SOLUTION: "No",
                FLAG_INDUSTRY: "Yes",
                FLAG_INP: "No",
                FLAG_CONFIRMED: "No",
                SCOPE_SOURCE: this.props.spendDetails.vendorDetails["scopeSource"],
                OVERRIDE_GICS: GICSID["key"],
                CONFIRMED: null,
                EXCLUSION_REASON: null,
                GOODS_OR_SERVICE: null,
                DIRECT_SPEND: null,
                SAP_ARIBA_BUY_PROCESS: null,
                SOURCING_ELIGIBLE: null,
                BUYING_INV_ELIGIBLE: null,
                REQUISITION_ABLE: null,
                REQUISITION_TYPE: null,
                CONTRACT_INV_ELIGIBLE: null,
                COMMERCE_AUTOMATION_ELIGIBLE: null,
                SUPP_CHAIN_COLLAB_ELIGIBLE: null,
                PAYABLES_ELIGIBLE: null,
                SAP_FIELDGLASS_ELIGIBLE: null,
                SAP_CONCUR_ELIGIBLE: null,
                REQUEST_NAME: "CAPIQ_Tab"
            };
            this.props.setPopUpTabLoadingStatus(true)
            this.props.supplierDrillDownUpdate(record)
                .then((response) => {
                    if (response.status == 200) {
                        this.props.setPopUpTabLoadingStatus(false)
                        this.props.trackSupplierDrillDownChanges(false)
                        this.props.trackSupplierDrillDownSave(true)
                        this.props.storeFinalIndustryID(GICSID["key"])
                        this.setState({ responseMsg: this.props.content.overrideMessage, isError: 'No', isSaveInProgress: false, isLoading: false })
                    }
                }).catch((error) => {
                    this.props.setPopUpTabLoadingStatus(false)
                    if (error.stack.includes('Network Error')) {
                        this.setState({ responseMsg: 'API service is down. Please contact administrator.', isError: 'Yes', isSaveInProgress: false, isLoading: false })
                    }
                    else {
                        this.setState({ responseMsg: this.props.content.error, isError: 'Yes', isSaveInProgress: false, isLoading: false })
                    }
                })
        }
    }

    componentDidMount() {
        this.LoadData();
    }

    render() {
        var address = []
        address.push(this.state.SPCapitalIQData["COMPANY_NAME"])
        var addressDetails = this.state.SPCapitalIQData["COMPANY_ADDRESS"] !== undefined ? this.state.SPCapitalIQData["COMPANY_ADDRESS"].split("|").map(item => item.trim()) : undefined;
        var finalAddress = this.state.SPCapitalIQData["COMPANY_ADDRESS"] !== undefined ? addressDetails.filter(Boolean).join(' | ') : undefined;
        address.push(finalAddress)
        var companyDetails = address.filter(Boolean).join(' | ');
        var val = "https://www.capitaliq.com/ciqdotnet/company.aspx?leftlink=true&companyId=" + this.state.SPCapitalIQData["COMPANY_ID"]
        return (
            <div className="grid-container">
                <div className="grid-x tabs_container">
                    <div className="medium-12">
                        <div className="grid-x">
                            <div className="medium-11">
                                {(this.state.isLoading) ?
                                    <img style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={path + `images/ajax-loader.gif`} alt="" />
                                    : null
                                }
                                {(this.state.isError === 'Yes') ?
                                    <div style={{ color: 'red', textAlign: 'center' }} className="cell">{this.state.responseMsg}</div>
                                    : <div style={{ color: 'green', textAlign: 'center' }} className="cell">{this.state.responseMsg}</div>
                                }
                            </div>
                            <div className="medium-1">
                                <button className={classNames('float-right', 'pr35',
                                    {
                                        'hand-cursor': this.state.isSaveInProgress ? false : this.state.checkedIndustries.find(x => x.checkedFlag === true && x.key !== this.props.spendDetails.L5IndustryID) !== undefined ? true : false,
                                        'gray-scale': this.state.isSaveInProgress ? true : this.state.checkedIndustries.find(x => x.checkedFlag === true && x.key !== this.props.spendDetails.L5IndustryID) === undefined ? true : false,
                                    })}
                                    onClick={this.handleSave} disabled={this.state.isSaveInProgress ? true : this.state.checkedIndustries.find(x => x.checkedFlag === true && x.key !== this.props.spendDetails.L5IndustryID) === undefined ? true : false} title={this.props.content.saveOverrideIndustry} float >
                                    <img width="20" src="images/save_active.svg" />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div style={{ paddingLeft: '5px' }} className="medium-12">
                        <div className="grid-x">
                            <div style={{ fontSize: '14px' }} className="medium-12 font-bold">{this.props.content.companyNameAndAddress}</div>
                            <div style={{ paddingTop: '5px' }} className="medium-12 font12">{companyDetails}</div>
                        </div>
                    </div>
                    <div style={{ fontSize: '14px', paddingLeft: '5px' }} className="medium-12 font-bold pt20">{this.props.content.companyDetails}</div>
                    <div className="medium-12">
                        <div className="seven-cols font12 font-bold align-left grey_background paddingAll5">
                            <div className="column">{this.props.content.CapIQID}</div>
                            <div className="column">{this.props.content.type}</div>
                            <div className="column">{this.props.content.companyStatus}</div>
                            <div className="column">{this.props.content.employees}</div>
                            <div className="column">{this.props.content.website}</div>
                            <div className="column"></div>
                            <div className="column"></div>
                        </div>
                        <div className="seven-cols font12 pt10 align-left paddingAll5">
                            {this.state.isCapIQLicesend && this.state.SPCapitalIQData["COMPANY_ID"] !== undefined && this.state.SPCapitalIQData["COMPANY_ID"] !== null ?
                                <div className="column">
                                    <a href={val} target="_blank">{this.state.SPCapitalIQData["COMPANY_ID"]}</a>
                                </div>
                                :
                                <div className="column">{this.state.SPCapitalIQData["COMPANY_ID"]}</div>
                            }
                            <div className="column">{this.state.SPCapitalIQData["COMPANY_TYPE"]}</div>
                            <div className="column">{this.state.SPCapitalIQData["COMPANY_STATUS"]}</div>
                            <div className="column">{this.state.SPCapitalIQData["EMPLOYEE_COUNT"] !== undefined && this.state.SPCapitalIQData["EMPLOYEE_COUNT"] !== null ? formatThousands(this.state.SPCapitalIQData["EMPLOYEE_COUNT"]) : ''}</div>
                            <div className="column">
                                {this.state.SPCapitalIQData["WEBPAGE"] !== null ?
                                    <a href={'//' + this.state.SPCapitalIQData["WEBPAGE"]} target="_blank">{this.state.SPCapitalIQData["WEBPAGE"]}</a>
                                    : ''}
                            </div>
                            <div className="column"></div>
                            <div className="column"></div>
                        </div>
                    </div>
                    <div style={{ fontSize: '14px', paddingLeft: '5px' }} className="medium-12 font-bold pt20">{this.props.content.financialInformation}</div>
                    <div className="medium-12">
                        <div className="seven-cols font12 font-bold align-left grey_background paddingAll5">
                            <div className="column">{this.props.content.currentFiscalYear}</div>
                            <div className="column">{this.props.content.currency}</div>
                            <div className="column">{this.props.content.totalRevenue}</div>
                            <div className="column">{this.props.content.grossMargin}</div>
                            <div className="column">{this.props.content.EBITDAMargin}</div>
                            <div className="column">{this.props.content.daysSalesOutstanding}</div>
                            <div className="column"></div>
                        </div>
                        <div className="seven-cols font12 pt10 align-left paddingAll5">
                            <div className="column">{this.state.SPCapitalIQData["FISCAL_YEAR"]}</div>
                            <div className="column">{this.state.SPCapitalIQData["CURRENCY_CODE"]}</div>
                            <div className="column">
                                {this.state.SPCapitalIQData["TOT_REV"] !== undefined && this.state.SPCapitalIQData["TOT_REV"] !== null ?
                                    this.state.SPCapitalIQData["REV_UNIT_TYPE_VALUE"] !== undefined && this.state.SPCapitalIQData["REV_UNIT_TYPE_VALUE"] !== null ?
                                        formatThousands(((this.state.SPCapitalIQData["TOT_REV"] * this.state.SPCapitalIQData["REV_UNIT_TYPE_VALUE"]) / 1000000).toFixed(2)) + " M" :
                                        formatThousands(this.state.SPCapitalIQData["TOT_REV"].toFixed(2)) : ''}
                            </div>
                            <div className="column">{this.state.SPCapitalIQData["GRO_MARG"] !== undefined && this.state.SPCapitalIQData["GRO_MARG"] !== null ? formatThousands(this.state.SPCapitalIQData["GRO_MARG"].toFixed(2)) + ' % ' : ''}</div>
                            <div className="column">{this.state.SPCapitalIQData["EBITDA_MARG"] !== undefined && this.state.SPCapitalIQData["EBITDA_MARG"] !== null ? formatThousands(this.state.SPCapitalIQData["EBITDA_MARG"].toFixed(2)) + ' % ' : ''}</div>
                            <div className="column">{this.state.SPCapitalIQData["DSO"] !== undefined && this.state.SPCapitalIQData["DSO"] !== null ? formatThousands(this.state.SPCapitalIQData["DSO"].toFixed(2)) : ''}</div>
                            <div className="column"></div>
                        </div>
                    </div>
                    {this.state.hierarchyIndustryTree.length > 0 ?
                        <div>
                            <div className="medium-12 pt20">
                                <div className="grid-x">
                                    <div style={{ fontSize: '14px' }} className="medium-12 font-bold paddingAll5">{this.props.content.industryClassification}</div>
                                    <div className="medium-8"></div>
                                </div>
                            </div>
                            <div className="medium-12 font12 paddingAll5">{this.props.content.industryOverrideMessage}</div>
                            {/* <div className="medium-12">
                        <div className="grid-x">
                            <div style={{ marginLeft: '31.5%' }} className="medium-4">
                                <button className={classNames(
                                    {
                                        'hand-cursor': this.state.checkedIndustries.find(x => x.checkedFlag === true && x.key !== this.props.spendDetails.L5IndustryID) !== undefined ? true : false,
                                        'gray-scale': this.state.checkedIndustries.find(x => x.checkedFlag === true && x.key !== this.props.spendDetails.L5IndustryID) === undefined ? true : false,
                                    })}
                                    onClick={this.handleSave} title={this.props.content.saveOverrideIndustry} float >
                                    <img width="20" src="images/save_active.svg" />
                                </button>
                            </div>
                            <div className="medium-8"></div>
                        </div>
                    </div> */}
                            <div style={{ paddingTop: '5px' }} className="medium-12">
                                <div className="grid-x">
                                    {Object.keys(this.state.expandedKeys).length > 0 ?
                                        <div className="medium-12">
                                            <Tree value={this.state.hierarchyIndustryTree}
                                                expandedKeys={this.state.expandedKeys}
                                                style={{ padding: '.3em .3em' }}
                                                nodeTemplate={this.nodeTemplate}
                                            />
                                        </div>
                                        : null
                                    }
                                </div>
                            </div>
                        </div>
                        : null
                    }
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        content: state.content.spendAnalysis.supplierDrilldown.SPCapitalIQ,
        spendDetails: state.spendDetails,
        currencySymbols: state.content.CurrencySymbols,
        user: state.default,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getSPCapitalIQSuccess: message => dispatch(getSPCapitalIQSuccess(message)),
        getSPCapitalIQFailure: error => dispatch(getSPCapitalIQFailure(error)),
        getSPCapitalIQLoading: bool => dispatch(getSPCapitalIQLoading(bool)),
        getSPCapitalIQData: (CapIQID) => dispatch(getSPCapitalIQData(CapIQID)),
        getCapIQClassificationIndustries: (CapIQID) => dispatch(getCapIQClassificationIndustries(CapIQID)),
        trackSupplierDrillDownChanges: message => dispatch(trackSupplierDrillDownChanges(message)),
        trackSupplierDrillDownSave: message => dispatch(trackSupplierDrillDownSave(message)),
        supplierDrillDownUpdate: (record) => dispatch(supplierDrillDownUpdate(record)),
        storeFinalIndustryID: (L5Industry) => dispatch(storeFinalIndustryID(L5Industry)),
        setPopUpTabLoadingStatus: bool => dispatch(setPopUpTabLoadingStatus(bool)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SPCapitalIQData); 