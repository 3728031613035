import React from 'react';
import { Filters } from "react-data-grid-addons";
import { stringContainsSearchTerms, isEmptyArray } from 'utils/Utils'

const {
    AutoCompleteFilter
} = Filters;

export default class AutoCompleteFilterAdv extends AutoCompleteFilter {
    constructor(props) {
        super(props)
    }

    columnValueContainsSearchTerms(columnValue, filterTermValue) {
        if (columnValue !== undefined && filterTermValue !== undefined) {
          const strColumnValue = columnValue.toString();
          const strFilterTermValue = filterTermValue.toString();
          const checkValueIndex = strColumnValue.trim().toLowerCase().indexOf(strFilterTermValue.trim().toLowerCase());
          return checkValueIndex !== -1 && (checkValueIndex !== 0 || strColumnValue === strFilterTermValue);
        }
        return false;
      }
    
      filterValues(row, columnFilter, columnKey) {
        let include = true;
        if (columnFilter === null) {
          include = false;
        } else if (columnFilter.filterTerm && !isEmptyArray(columnFilter.filterTerm)) {
          if (columnFilter.filterTerm.length) {
              let match = columnFilter.filterTerm.findIndex(item => {
                return item.value === 'Not Included'
              })  === -1
            include = columnFilter.filterTerm.some(filterTerm => {
              return row[columnKey] === filterTerm.value
            });

            if(!include && !match)
                include = true
            else if(include && !match)
                include = false
            
          } else {
              let match = true;
              if(columnFilter.filterTerm.value.startsWith('!')) {
                match = false;
              }
            include = row[columnKey] === columnFilter.filterTerm.value === match;
          }
        }
        return include;
      }
}