import React from 'react'
import{connect} from 'react-redux'
import EnablementInsightTabTable from './EnablementInsightTabTable'
import EnablementSupplierDetailsPopup from './EnablementSupplierDetailsPopup'
import ExpandableTable from './ExpandableTable'
import { path, dropdownColourStyles } from 'Constants'
import { ControlTextE, ScreenE, SelectE } from 'UI/CustomComponents/Controls';

// Settings
import { changeModalBorderStyle, drilldownModalBorderContentStyle, changeModalBorderRectStyle } from 'Constants'
import Modal from 'reboron/OutlineModal'

import {
    enablementInsightSuccess,
    enablementInsightFailure,
    enablementInsightLoading,    
    resetError,
    getEnablementDetails,
    getEnablementStrategyDetails,
    getEnablementFiltersDetails,
    currentActiveRequest,
    updateEnablementStrategyCurrentFocus
} from 'actions/spendActions'

class EnablementDetails extends React.Component{
    constructor(props)
    {
        super(props);
        this.state={
          enablementWaveData: [],
          enablementWaveTotal: [],
          enablementWaveJson: [],
          enablementTypesJson: [],
          enablementTypes: [],  
          grandTotal: [],
          isLoading: true,
          responseMsg: '',
          inputParam: {
            DMC_ID: this.props.spendDetails !== undefined && this.props.spendDetails.currentActiveRequest !== undefined ? this.props.spendDetails.currentActiveRequest.DMC_ID : 0
            , VS_ID: this.props.spendDetails !== undefined && this.props.spendDetails.currentActiveRequest !== undefined ? this.props.spendDetails.currentActiveRequest.VS_ID : 0,
            User_Email: this.props.spendDetails.currentActiveRequest !== undefined &&
                        this.props.spendDetails.currentActiveRequest.SAPRequester === this.props.user.user.Email ? this.props.user.user.Email : this.props.spendDetails.currentActiveRequest.SAPRequester,
            dedupStats: 'Unique',
            enabledCustomer: 'all',
            transactingCustomer: 'all',
            buyerRegion: 'all',
            businessUnit: 'all',
            erpSystem: 'all',
            l4IndustryName: 'all',
            erpCode: 'all',
            erpDesc: 'all',
            sicCode: 'all',
            sicDesc: 'all',
           
            goods: 'all',
            invoiceCountry: 'all',
            legalInvoice: 'all',
            aribaNwEligible: 'all',
            column1Name: 'SAP_ARIBA_BUY_ENABLE_MTHD_RECMD',
            column1Value: '',
            tableName: '',
            valuesUpdated: false,
            metricsType: 'Invoice Spend',
            directSpend: 'all' 
          }, 
          isL4Expand: true,
          isCountryExpand: true,
          isOnboardWrkExpand: true,
          isRECMDExpand: true,
          tableNameOption:[
              { value: "l4Industry", label: "Enablement Strategy by Onboarding Workstream with L4 Industry Name"},
              { value: "isoCountry", label: "Enablement Strategy by Onboarding Workstream with ISO Country Code"},
              { value: "workstreamDetails", label: "Enablement Strategy by Onboarding Workstream with Details"},
              { value: "workstreamRecommendation", label: "Enablement Strategy by Onboarding Workstream with Enablement Recommendation"},
        ],
        selectedTableView: this.props.spendDetails !== undefined && this.props.spendDetails.currentActiveRequest !== undefined ? this.props.spendDetails.currentActiveRequest.ENABLEMENT_STRATEGY_FOCUS : "l4Industry",
        activeRequest: this.props.spendDetails.currentActiveRequest
    }
        this.showEnablementSupplierDetailsModal = this.showEnablementSupplierDetailsModal.bind(this)
        this.setVisibility = this.setVisibility.bind(this)
        this.outsidePopupClick = this.outsidePopupClick.bind(this)
        this.onSave = this.onSave.bind(this)
        this.reloadEnablementWave= this.reloadEnablementWave.bind(this)
    }

    componentDidMount(){       
        ////this.convertResponseToJson()
        this.GetEnablementDetails(this.state.inputParam)
    }

    reloadEnablementWave(){
        this.GetEnablementDetails(this.state.inputParam)
    }

    showEnablementSupplierDetailsModal(tableName, columnName, columnValue, columnValue2, columnValue3) {

        var inputParameter = this.state.inputParam
        inputParameter.tableName = tableName
        inputParameter.column1Name = columnName
        inputParameter.column1Value = columnValue

        inputParameter.column2Name = this.props.content.enablementWaveSecCol  
        inputParameter.column2Value = columnValue2

        inputParameter.column3Value = columnValue3

        var thirdColumnName;
        switch(tableName){
            case this.props.content.enablementWaveDetails.enablementStrategyByL4Industry :
                thirdColumnName = this.props.content.enablementWaveL4IndustryCol
                break;
            case this.props.content.enablementWaveDetails.enablementStrategyByISOCountry :
                thirdColumnName = this.props.content.enablementWaveISOCountryCol
                break;
            case this.props.content.enablementWaveDetails.enablementStrategyByOnboardingWorkstream :
                thirdColumnName = this.props.content.enablementWaveWorkStreamDetailCol
                break;
            case this.props.content.enablementWaveDetails.enablementStrategyByRecommendation :
                thirdColumnName = this.props.content.enablementWaveThirdCol
                break;                                
        }

        inputParameter.column3Name = thirdColumnName;
        
        this.setState({	openPopup: true, inputParam: inputParameter	})
    }

    onSave(value) {
        this.setState({valuesUpdated: value})
    }

    setVisibility(isVisible) {
        this.handlePoupClose()   
    }

    outsidePopupClick() {        
        this.handlePoupClose()
    }
    
    handlePoupClose() {
        this.setState({  open: false, openPopup: false }, () => {
            if(this.state.valuesUpdated) {
                this.setState({valuesUpdated: false}, this.GetEnablementDetails(this.state.inputParam))
            }
        })
    }

    showAdvanceFilter(){
        var advanceFilterDiv = document.getElementById('advanceFilter').style;        
        if (advanceFilterDiv.display === "none") {
            advanceFilterDiv.display = "block";
          } else {
            advanceFilterDiv.display = "none";
          }
    }

    GetEnablementDetails(inputParam) {
		
		this.props.getEnablementStrategyDetails(inputParam)
			.then((response) => {
				if (response !== undefined && response.status === 200 && response.data.Result !== undefined) {
                //	setTimeout(() => { this.props.defineScopeSuccess('') }, 2000)
                var enablementWaveData; var enablementWaveTotal;
                var l4IndustryData; var l4IndustryDataTotal;
                var onWorkStreamData; var onWorkStreamDataTotal;
                var isoCountryCodeData; var isoCountryCodeDataTotal;
                var enablementWaveANIDsData; var headerLevelGrandTotal; var ganttChartWorkStreamData;
                

                var tables = response.data.Result.map(
                    function (table) {
                        // if(table['ENABLEMENT_WAVE']!== undefined){
                            
                        //     enablementWaveData = table['ENABLEMENT_WAVE']
                        //     enablementWaveTotal = table['GrandTotal']
                        // }
                      
                        if(table['ENABLEMENT_WAVE']!== undefined){
                            
                            enablementWaveData = table['ENABLEMENT_WAVE']
                            enablementWaveTotal = table['GrandTotal']
                        }

                        if(table['L4_INDUSTRY_GICS_ID']!== undefined){
                            
                            l4IndustryData = table['L4_INDUSTRY_GICS_ID']
                            l4IndustryDataTotal = table['GrandTotal']
                        }
                        
                        if(table['ISO_COUNTRY_CODE']!== undefined){
                            
                            isoCountryCodeData = table['ISO_COUNTRY_CODE']
                            isoCountryCodeDataTotal = table['GrandTotal']
                        }

                        if(table['ONBOARDING_WORKSTREAM_DETAILS']!== undefined){
                            
                            onWorkStreamData = table['ONBOARDING_WORKSTREAM_DETAILS']
                            onWorkStreamDataTotal = table['GrandTotal']
                        }
                        
                        if(table['ENABLEMENT_WAVE_ANIDs']!== undefined){
                            enablementWaveANIDsData = table['ENABLEMENT_WAVE_ANIDs']   
                            headerLevelGrandTotal = table['GrandTotal']                         
                        }

                        if(table['GANTT_CHART_WORKSTREAM_NAME']!== undefined){
                            ganttChartWorkStreamData = table['GANTT_CHART_WORKSTREAM_NAME'] 
                        }
                    }
                )

                const l4IndustryDataTemp = this.convertResponseToJson(l4IndustryData,enablementWaveANIDsData, ganttChartWorkStreamData, "FIELD_DESC")

                const isoCountryDataTemp = this.convertResponseToJson(isoCountryCodeData,enablementWaveANIDsData, ganttChartWorkStreamData, "ISO_COUNTRY_CODE")

                const onWorkStreamDataTemp = this.convertResponseToJson(onWorkStreamData,enablementWaveANIDsData, ganttChartWorkStreamData, "ONBOARDING_WORKSTREAM_DETAILS")

                const enablementWaveDataTemp = this.convertResponseToJson(enablementWaveData,enablementWaveANIDsData, ganttChartWorkStreamData, "SAP_ARIBA_BUY_ENABLE_MTHD_RECMD")
                
                //------------------- Rerrange all arrays . Moving Direct Connect wave at the top Start
                l4IndustryDataTemp.rows.forEach(function (item, i) {
                    if (item.content === "Direct Connect") {
                        l4IndustryDataTemp.rows.splice(i, 1);
                        l4IndustryDataTemp.rows.unshift(item);
                    }
                }.bind(this));
                isoCountryDataTemp.rows.forEach(function (item, i) {
                    if (item.content === "Direct Connect") {
                        isoCountryDataTemp.rows.splice(i, 1);
                        isoCountryDataTemp.rows.unshift(item);
                    }
                }.bind(this));
                onWorkStreamDataTemp.rows.forEach(function (item, i) {
                    if (item.content === "Direct Connect") {
                        onWorkStreamDataTemp.rows.splice(i, 1);
                        onWorkStreamDataTemp.rows.unshift(item);
                    }
                }.bind(this));
                enablementWaveDataTemp.rows.forEach(function (item, i) {
                    if (item.content === "Direct Connect") {
                        enablementWaveDataTemp.rows.splice(i, 1);
                        enablementWaveDataTemp.rows.unshift(item);
                    }
                }.bind(this));

                //------------------- End

                this.setState({ enablementWaveJson: enablementWaveDataTemp, enablementWaveTotal: headerLevelGrandTotal,  isLoading: false 
                  ,  l4IndustryDataJson: l4IndustryDataTemp, isoCountryDataJson: isoCountryDataTemp, onWorkStreamDataJson: onWorkStreamDataTemp
                });
              
                this.props.enablementInsightSuccess(true)
				}
			}).catch((error) => {
				if (error.stack.includes('Network Error')) {
                    this.setState({ responseMsg: 'API service is down. Please contact administrator', isError: 'Yes' })
					this.props.enablementInsightFailure('API service is down. Please contact administrator.')
				}
				else {
                    this.setState({ responseMsg: 'An error has occurred', isError: 'Yes' })
					this.props.enablementInsightFailure(this.props.content.error)
				}
			})
    }
    
    convertResponseToJson(enablementData, aggregatedData, ganttChartWorkStreamData, thirdLevel)
    {
        let data = []
        let total = []
        let waveToItem = []
        let ganttChartTotal = []
        let ganttChartToItem = []
        //const enablementWaveData = this.state.enablementWaveData
        enablementData.forEach(item => {
            let datum = {
                ENABLEMENT_WAVE: item.ENABLEMENT_WAVE, 
                GANTT_CHART_WORKSTREAM_NAME: item.GANTT_CHART_WORKSTREAM_NAME, 
                [thirdLevel]: item[thirdLevel],//.SAP_ARIBA_BUY_ENABLE_MTHD_RECMD,
                CNT_VENDOR_NAME: isNaN(item.CNT_VENDOR_NAME)? 0 : item.CNT_VENDOR_NAME,
                CNT_VENDOR_PER: isNaN(item.CNT_VENDOR_PER)? 0 :  Number.parseFloat(item.CNT_VENDOR_PER),
                CNT_UNIQ_VENDOR_AN_ID: isNaN(item.CNT_UNIQ_VENDOR_AN_ID)? 0 : Number.parseFloat(item.CNT_UNIQ_VENDOR_AN_ID),
                SUM_AGGR_SPEND: isNaN(item.SUM_AGGR_SPEND)? 0 : item.SUM_AGGR_SPEND,
                SUM_AGGR_SPEND_PER: isNaN(item.SUM_AGGR_SPEND_PER)? 0 : Number.parseFloat(item.SUM_AGGR_SPEND_PER),
               
                SUM_SAP_ARIBA_DOC_PROXY: isNaN(item.SUM_SAP_ARIBA_DOC_PROXY)? 0 : item.SUM_SAP_ARIBA_DOC_PROXY,
                SAP_ARIBA_DOC_PROXY_PER: isNaN(item.SAP_ARIBA_DOC_PROXY_PER)? 0 : Number.parseFloat(item.SAP_ARIBA_DOC_PROXY_PER)
            }

            data.push(datum)

            let datumCopy = {...datum}
            let datumCopy1 = {...datum}
             
            if(item.ENABLEMENT_WAVE in total) {
                waveToItem[item.ENABLEMENT_WAVE].push(datumCopy1)
                
                let partialTotal = total[item.ENABLEMENT_WAVE]
                partialTotal.CNT_VENDOR_NAME = 0
                partialTotal.CNT_VENDOR_PER = 0
                partialTotal.CNT_UNIQ_VENDOR_AN_ID = 0
                partialTotal.SUM_AGGR_SPEND = 0
                partialTotal.SUM_AGGR_SPEND_PER = 0

                partialTotal.SUM_AGGR_PO_SPEND = 0
                partialTotal.SUM_AGGR_PO_SPEND_PER = 0

                partialTotal.SUM_SAP_ARIBA_DOC_PROXY = 0
                partialTotal.SAP_ARIBA_DOC_PROXY_PER = 0

            } else {
                
                total[item.ENABLEMENT_WAVE] = datumCopy
                waveToItem[item.ENABLEMENT_WAVE] = [datumCopy1]
            }

            if(item.GANTT_CHART_WORKSTREAM_NAME in ganttChartTotal) {
                ganttChartToItem[item.GANTT_CHART_WORKSTREAM_NAME].push(datumCopy1)
                
                let partialTotal = ganttChartTotal[item.GANTT_CHART_WORKSTREAM_NAME]
                partialTotal.CNT_VENDOR_NAME = 0
                partialTotal.CNT_VENDOR_PER = 0
                partialTotal.CNT_UNIQ_VENDOR_AN_ID = 0
                partialTotal.SUM_AGGR_SPEND = 0
                partialTotal.SUM_AGGR_SPEND_PER = 0

                partialTotal.SUM_AGGR_PO_SPEND = 0
                partialTotal.SUM_AGGR_PO_SPEND_PER = 0

                partialTotal.SUM_SAP_ARIBA_DOC_PROXY = 0
                partialTotal.SAP_ARIBA_DOC_PROXY_PER = 0

            } else {
                
                ganttChartTotal[item.GANTT_CHART_WORKSTREAM_NAME] = datumCopy
                ganttChartToItem[item.GANTT_CHART_WORKSTREAM_NAME] = [datumCopy1]
            }

        })

         let enablementWaveDetails = {
             rows: []
         }
            
        //  console.log("waveToItem.....", waveToItem)
        //  console.log("ganttChartToItem......", ganttChartToItem)

         for (const [key, value] of Object.entries(waveToItem)) {

            let recommendationChild = []

            
            for (const [recKey, recValue] of Object.entries(ganttChartToItem)) {            
            
               let tempData = recValue.filter(item=>item.ENABLEMENT_WAVE === key && item.GANTT_CHART_WORKSTREAM_NAME === recKey)

               tempData.forEach((item, index) => {
                     recommendationChild.push({
                               childContentId: index, 
                               content: item[thirdLevel],//.SAP_ARIBA_BUY_ENABLE_MTHD_RECMD, 
                               parent: item.GANTT_CHART_WORKSTREAM_NAME,
                               enablementWave: item.ENABLEMENT_WAVE, 
                               data: item,
                               expand: false
                         })
                  })                 
               }
               
            let collapseChild = []             
            let idx = 0
            value.forEach((item, index) => {               
              if(!collapseChild.some(i=>i.content === item.GANTT_CHART_WORKSTREAM_NAME)){                
           
                collapseChild.push({
                    childContentId: index, 
                    content: item.GANTT_CHART_WORKSTREAM_NAME, 
                    enablementWave: key, 
                    data: ganttChartWorkStreamData.filter(a=> a.ENABLEMENT_WAVE===key && a.GANTT_CHART_WORKSTREAM_NAME === item.GANTT_CHART_WORKSTREAM_NAME )[0], 
                    expand: false
                   ,collapseChild: recommendationChild.filter(rec=> rec.enablementWave === key && rec.parent === item.GANTT_CHART_WORKSTREAM_NAME)
                })
            }
            })

            enablementWaveDetails.rows.push({ 
                "content" : key,
                "contentId"  : idx++,
                "data": aggregatedData.filter(item=> item.ENABLEMENT_WAVE===key )[0],                
                expand: false,
                collapseChild : collapseChild
            });
          }

        //console.log("jsonData", enablementWaveDetails)
        return enablementWaveDetails
    }

    componentWillReceiveProps(nextProps, nextState){
        if (this.state.Test !== nextProps.filterData && 'dedupStats' in nextProps.filterData)
        {
        var inputParameter = this.state.inputParam
        inputParameter.dedupStats = nextProps.filterData.dedupStats
        inputParameter.enabledCustomer= nextProps.filterData.enabledCustomer
        inputParameter.transactingCustomer= nextProps.filterData.transactingCustomer
        inputParameter.buyerRegion= nextProps.filterData.buyerRegion
        inputParameter.businessUnit= nextProps.filterData.businessUnit
        inputParameter.erpSystem= nextProps.filterData.erpSystem
        inputParameter.l4IndustryName= nextProps.filterData.l4IndustryName
        inputParameter.erpCode= nextProps.filterData.erpCode 
        inputParameter.erpDesc= nextProps.filterData.erpDesc 
        inputParameter.sicCode= nextProps.filterData.sicCode 
        inputParameter.sicDesc= nextProps.filterData.sicDesc
        inputParameter.goods= nextProps.filterData.goods
        inputParameter.invoiceCountry= nextProps.filterData.invoiceCountry
        inputParameter.legalInvoice= nextProps.filterData.legalInvoice
        inputParameter.aribaNwEligible= nextProps.filterData.aribaNwEligible
        inputParameter.metricsType= nextProps.filterData.metricsType
        inputParameter.directSpend= nextProps.filterData.directSpend        
        
        setTimeout(() => {
            this.GetEnablementDetails(inputParameter);
        }, 1000);
        
        this.setState({Test:nextProps.filterData , inputParam: Object.keys(nextProps.filterData).length !== 0 ? nextProps.filterData : this.state.inputParam})
        //console.log("if enablemtn details filetr props-")
        }
        else{
          //  console.log("else enablemtn details filetr props-")
        }
        
    }

    onExpandClick(e){
        switch(e.target.id){
            case "l4Industry":
               this.setState({ isL4Expand: !this.state.isL4Expand})
               break;
            
            case "country":
                this.setState({ isCountryExpand : !this.state.isCountryExpand})
                break;
                   
            case "onboardWrk":
                this.setState({ isOnboardWrkExpand: !this.state.isOnboardWrkExpand})
                break;

            case "recmd":
            this.setState({ isRECMDExpand: !this.state.isRECMDExpand})
            break;                    

        }      
        this.GetEnablementDetails(this.state.inputParam)  
    }

    handleFocusChange(data){        
        var activeRequestData= this.state.activeRequest
        activeRequestData.ENABLEMENT_STRATEGY_FOCUS = data.value
        this.setState({selectedTableView : data.value, isLoading : true})
        
        var requestParam={
            VS_ID: this.state.inputParam.VS_ID,
            DMC_ID: this.state.inputParam.DMC_ID,
            ENABLEMENT_STRATEGY_FOCUS: data.value
        }
      
        this.updateEnablementStrategyFocus(requestParam)
        this.props.currentActiveRequest(activeRequestData)
        this.GetEnablementDetails(this.state.inputParam)  
      }

    updateEnablementStrategyFocus(requestParam) {
		
		this.props.updateEnablementStrategyCurrentFocus(requestParam.VS_ID, requestParam.DMC_ID, requestParam.ENABLEMENT_STRATEGY_FOCUS)
			.then((response) => {
				if (response !== undefined && response.status === 200 && response.data.Result !== undefined) {
                
				}
			}).catch((error) => {
				if (error.stack.includes('Network Error')) {
                    this.setState({ responseMsg: 'API service is down. Please contact administrator', isError: 'Yes' })
					this.props.enablementInsightFailure('API service is down. Please contact administrator.')
				}
				else {
                    this.setState({ responseMsg: 'An error has occurred', isError: 'Yes' })
					this.props.enablementInsightFailure(this.props.content.error)
				}
			})
    }

    render()
    {
        return(
            <div className="grid-x" style={{ width: '100%'}}>
                
                    <div className="medium-12">
                        {(this.state.isLoading) ?
                            <img style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={path + `images/ajax-loader.gif`} alt="" />
                            : null
                        }
                        {(this.state.isError === 'Yes') ?
                            <div style={{ color: 'red', textAlign: 'center' }} className="cell pt5">{this.state.responseMsg}</div>
                            : <div style={{ color: 'green', textAlign: 'center' }} className="cell pt5">{this.state.responseMsg}</div>
                        }
                    </div>

                    <div className="grid-x medium-12 pr15">
                        <div className="medium-6">
									<div className="dropDownHeader">{"Focus"} </div>
									<div className="control-wrapper" style={{width:'80%'}}>
									<SelectE
                                        className="border-with-DD"
                                        autocomplete='true'
                                        //placeholder={this.props.content.notConfirmed}
                                        value={this.state.tableNameOption.filter(
                                            option => option.value === this.state.selectedTableView
                                        )}
										noOptionsMessage={() => null}
                                        onChange={value => this.handleFocusChange(value)}
                                        options={this.state.tableNameOption}
                                        isSearchable={false} 
										styles={dropdownColourStyles}
                                        isDisabled={this.state.isLoading}
                                    />										
									</div>
                                    </div>
					</div>

                    <div className="grid-x medium-12 pt10"></div>
                    <br></br>
               {this.state.selectedTableView==="l4Industry" ? 

                // <div className="grid-x medium-12 pb10 pt10 tab-title ">
                // <div className="grid-x medium-12 heading-with-below-border">
                //     <div className="medium-11" style={{ fontWeight: '500'}}>{"Enablement Strategy by Onboarding Workstream with L4 Industry Name"}</div>
                //     <div className="medium-1" id="l4Industry" style={{ textAlign:'right', cursor:'pointer', fontSize: '1.6rem' }}
                //      onClick={(e)=> this.onExpandClick(e)} >{this.state.isL4Expand ? "-": "+"}</div>                    
                // </div>
                // </div>    

                 
                <div className="medium-12">
                        <div className="grid-x medium-12">
                                          {this.state.l4IndustryDataJson !== undefined && this.state.l4IndustryDataJson !== null ?
                                         <ExpandableTable
                                             data={this.state.l4IndustryDataJson}    
                                             grandTotal = {this.state.enablementWaveTotal}                                         
                                             content={this.props.content.enablementWaveDetails} 
                                             //content ={this.state.enablementWaveJson}
                                             tableName={this.props.content.enablementWaveDetails.enablementStrategyByL4Industry}
                                             tableSequence={"26"}
                                             showEnablementSupplierDetailsModal={this.showEnablementSupplierDetailsModal}
                                             metricsType= {this.state.inputParam.metricsType}
                                             reloadEnablementWave= {this.reloadEnablementWave}
                                             tabName = {'Enablement Strategy'}
                                         />
                                         : <div className="pt10">{this.props.content.noDataFound}</div>}                                                            
                        </div>
                </div>
                
               : null}

                {/* <div className="grid-x medium-12 pb10 tab-title ">
                <div className="grid-x medium-12 heading-with-below-border">
                    <div className="medium-11" style={{ fontWeight: '500'}}>{"Enablement Strategy by ISO Country Code"}</div>
                    <div className="medium-1" id="country" style={{ textAlign:'right', cursor:'pointer', fontSize: '1.6rem' }}
                     onClick={(e)=> this.onExpandClick(e)} >{this.state.isCountryExpand ? "-": "+"}</div>                    
                </div>
                </div>    */}

                {this.state.selectedTableView === "isoCountry"? 
                <div className="medium-12">
                        <div className="grid-x medium-12">
                                          {this.state.isoCountryDataJson !== undefined && this.state.isoCountryDataJson !== null ?
                                         <ExpandableTable
                                             data={this.state.isoCountryDataJson}    
                                             grandTotal = {this.state.enablementWaveTotal}                                         
                                             content={this.props.content.enablementWaveDetails} 
                                             //content ={this.state.enablementWaveJson}
                                             tableName={this.props.content.enablementWaveDetails.enablementStrategyByISOCountry}
                                             tableSequence={"26"}
                                             showEnablementSupplierDetailsModal={this.showEnablementSupplierDetailsModal}
                                             metricsType= {this.state.inputParam.metricsType}
                                             reloadEnablementWave= {this.reloadEnablementWave}
                                             tabName = {'Enablement Strategy'}
                                         />
                                         : <div className="pt10">{this.props.content.noDataFound}</div>}                                                            
                        </div>
                </div>
                : null }

                {/* <div className="grid-x medium-12 pb10 tab-title ">
                <div className="grid-x medium-12 heading-with-below-border">
                    <div className="medium-11" style={{ fontWeight: '500'}}>{"Enablement Strategy by Onboarding Workstream with Details"}</div>
                    <div className="medium-1" id="onboardWrk" style={{ textAlign:'right', cursor:'pointer', fontSize: '1.6rem' }}
                     onClick={(e)=> this.onExpandClick(e)} >{this.state.isOnboardWrkExpand ? "-": "+"}</div>                    
                </div>
                </div>    */}

                {this.state.selectedTableView === "workstreamDetails" ? 
                <div className="medium-12">
                        <div className="grid-x medium-12">
                                          {this.state.onWorkStreamDataJson !== undefined && this.state.onWorkStreamDataJson !== null ?
                                         <ExpandableTable
                                             data={this.state.onWorkStreamDataJson}    
                                             grandTotal = {this.state.enablementWaveTotal}                                         
                                             content={this.props.content.enablementWaveDetails} 
                                             //content ={this.state.enablementWaveJson}
                                             tableName={this.props.content.enablementWaveDetails.enablementStrategyByOnboardingWorkstream}
                                             tableSequence={"26"}
                                             showEnablementSupplierDetailsModal={this.showEnablementSupplierDetailsModal}
                                             metricsType= {this.state.inputParam.metricsType}
                                             reloadEnablementWave= {this.reloadEnablementWave}
                                             tabName = {'Enablement Strategy'}
                                         />
                                         : <div className="pt10">{this.props.content.noDataFound}</div>}                                                            
                        </div>
                </div>
                : null }

                {/* <div className="grid-x medium-12 pb10 tab-title ">
                <div className="grid-x medium-12 heading-with-below-border">
                    <div className="medium-11" style={{ fontWeight: '500'}}>{"Enablement Strategy by Onboarding Workstream with Enablement Recommendation"}</div>
                    <div className="medium-1" id="recmd" style={{ textAlign:'right', cursor:'pointer', fontSize: '1.6rem' }}
                     onClick={(e)=> this.onExpandClick(e)} >{this.state.isRECMDExpand ? "-": "+"}</div>                    
                </div>
                </div>    */}

                {this.state.selectedTableView === "workstreamRecommendation"? 
                <div className="medium-12">
                        <div className="grid-x medium-12">
                                          {this.state.enablementWaveJson !== undefined && this.state.enablementWaveJson !== null ?
                                         <ExpandableTable
                                             data={this.state.enablementWaveJson}    
                                             grandTotal = {this.state.enablementWaveTotal}                                         
                                             content={this.props.content.enablementWaveDetails} 
                                             //content ={this.state.enablementWaveJson}
                                             tableName={this.props.content.enablementWaveDetails.enablementStrategyByRecommendation}
                                             tableSequence={"26"}
                                             showEnablementSupplierDetailsModal={this.showEnablementSupplierDetailsModal}
                                             metricsType= {this.state.inputParam.metricsType}
                                             reloadEnablementWave= {this.reloadEnablementWave}
                                             tabName = {'Enablement Strategy'}
                                         />
                                         : <div className="pt10">{this.props.content.noDataFound}</div>}                                                            
                        </div>
                </div>
                :
                null }                           

                  <div>  
                  {this.refs.enablementSupplier ? this.state.openPopup ?
                    this.refs.enablementSupplier.show() :
                    this.refs.enablementSupplier.hide() : ''}        
                  <Modal
                    ref={'enablementSupplier'}
                    modalStyle={changeModalBorderStyle}
                    contentStyle={drilldownModalBorderContentStyle}
                    rectStyle={changeModalBorderRectStyle}                   
                    keyboard={false}
                    backdrop={false}
                   >
                    <div className="grid-x tabs_container supplier-tabs">                       
                        <div className="medium-12">                        
                            {
                                <EnablementSupplierDetailsPopup onSave={this.onSave} input={this.state.inputParam} outsidePopupClick={this.outsidePopupClick} ></EnablementSupplierDetailsPopup>
                            }
                        </div>
                    </div>
                    <button onClick={() => { this.setVisibility(false); this.setState({ selectedIndustryName: {} }) }} className='close' />
                </Modal>
                  </div>
                    {/* <div><EnablementSupplierPopup open={this.state.openPopup} visibility={this.setVisibility} outsidePopupClick={this.outsidePopupClick} ></EnablementSupplierPopup></div> */}
            </div>                   
        )
    }

}


const mapStateToProps = state => {
	return {
        content: state.content.enablementInsight,
        //content: state.content.subLeverCalculations,
		ui: state.content.ui,
		currency: decodeURIComponent(state.valueStory.Currency),
		userData: state.valueStory,
		spendAsPercentageOfRevenue: state.spendAsPercentageOfRevenue,
		benchMarkCalculations: state.benchMarksData,
		allContent: state.content,
        language: state.language,
        spendDetails: state.spendDetails,
        user: state.default        
	}
}

const mapDispatchToProps = dispatch => {
	return {
        enablementInsightSuccess: message => dispatch(enablementInsightSuccess(message)),
        enablementInsightFailure: error => dispatch(enablementInsightFailure(error)),
        enablementInsightLoading: bool => dispatch(enablementInsightLoading(bool)),
        resetError: error => dispatch(resetError(error)),
        getEnablementDetails: (data) => dispatch(getEnablementDetails(data)),
        getEnablementStrategyDetails: (data) => dispatch(getEnablementStrategyDetails(data)),
        getEnablementFiltersDetails: (data) => dispatch(getEnablementFiltersDetails(data)),
        currentActiveRequest: (requestDetails) => dispatch(currentActiveRequest(requestDetails)),
        updateEnablementStrategyCurrentFocus: (VS_ID, DMC_ID, ENABLEMENT_STRATEGY_FOCUS) => dispatch(updateEnablementStrategyCurrentFocus(VS_ID, DMC_ID, ENABLEMENT_STRATEGY_FOCUS))
	}
}

export default connect(	mapStateToProps, mapDispatchToProps)(EnablementDetails)

