export const UPDATE_CALCULATE_ROI_BENEFIT_REALIZATION_FACTOR = 'UPDATE_CALCULATE_ROI_BENEFIT_REALIZATION_FACTOR'
export const UPDATE_CALCULATE_ROI_USER_INPUT = 'UPDATE_CALCULATE_ROI_USER_INPUT'
export const UPDATE_CALCULATE_ROI_TOTAL_INVESTMENT = 'UPDATE_CALCULATE_ROI_TOTAL_INVESTMENT'
export const UPDATE_CALCULATE_ROI_TOTAL_BENEFIT = 'UPDATE_CALCULATE_ROI_TOTAL_BENEFIT'
export const UPDATE_CALCULATE_ROI_NET_BENEFIT = 'UPDATE_CALCULATE_ROI_NET_BENEFIT'
export const UPDATE_CALCULATE_ROI_CUMULATIVE_BENEFIT = 'UPDATE_CALCULATE_ROI_CUMULATIVE_BENEFIT'
export const UPDATE_ROI_RESULTS = 'UPDATE_ROI_RESULTS'

export const updateBenefitRealizationFactor = (value, year) => {
	return {
		type: UPDATE_CALCULATE_ROI_BENEFIT_REALIZATION_FACTOR,
		value: value,
		year: year
	}
}

export const updateUserInput = (value, row, year) => {
	return {
		type: UPDATE_CALCULATE_ROI_USER_INPUT,
		value: value,
		row: row,
		year: year
	}
}

export const updateTotalInvestment = (value, year) => {
	return {
		type: UPDATE_CALCULATE_ROI_TOTAL_INVESTMENT,
		value: value,
		year: year
	}
}

export const updateTotalBenefit = (value, year) => {
	return {
		type: UPDATE_CALCULATE_ROI_TOTAL_BENEFIT,
		value: value,
		year: year
	}
}

export const updateNetBenefit = (value, year) => {
	return {
		type: UPDATE_CALCULATE_ROI_NET_BENEFIT,
		value: value,
		year: year
	}
}

export const updateCumulativeBenefit = (value, year) => {
	return {
		type: UPDATE_CALCULATE_ROI_CUMULATIVE_BENEFIT,
		value: value,
		year: year
	}
}

export const updateROIResults = (value, module) => {
	return {
		type: UPDATE_ROI_RESULTS,
		value,
		module
	}
}
