import React from 'react'
import classnames from 'classnames'

const InterestButton = (props) => {
	let classes = classnames('interest-button', 'interest-button-' + props.id)
	let parentClasses = classnames('interest-button-container', { 'active': props.state });

	return (
		<div className={parentClasses}>
			<button className={classes}
				onClick={() => {
					props.dispatchToggleAreaOfInterest(props.id, !props.state)
				}
				}
			>
				<img src={props.icon} width="50" alt={props.content.text} />
			</button>
			<span>{props.content.text}</span>
		</div>
	)
}

export default InterestButton
