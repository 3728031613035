import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

// Components
import TabPanel from '../Tabs/TabPanel'

import { routes } from 'Constants'
import BusinessValueContainer from '../BusinessValue/BusinessValueContainer';
import BusinessValueFromSAPAribaContainer from '../BusinessValueFromSAPAriba/BusinessValueFromSAPAribaContainer';
import CalculateROIContainer from '../CalculateROI/CalculateROIContainer';

import {
	valueStoryCurrentMenu,
	showValueStoryMenu
}
	from 'actions/userActions'

class BusinessCaseContainer extends PureComponent {

	constructor(props) {
		super(props)

		this.state = {
			tabId: 1,
			nextButtonLabel: props.ui.next,
            vlmAlertDiv: false
		}

		this.dispatchTabChange = this.dispatchTabChange.bind(this)
		this.intializePrioritiesTabs = this.intializePrioritiesTabs.bind(this)
		this.dispatchLastButtonClick = this.dispatchLastButtonClick.bind(this)
		this.vlmAlertToggle = this.vlmAlertToggle.bind(this)
	}

	intializePrioritiesTabs() {
		const tabs = [
			{
				id: 1,
				label: this.props.content.businessValue.title,
				component: (<div className="grid-x"><BusinessValueContainer /></div>)
			},
			{
				id: 2,
				label: this.props.content.businessValueFromSAPAriba.title,
				component: (<div className="grid-x"><BusinessValueFromSAPAribaContainer /></div>)
			},
			{
				id: 3,
				label: this.props.content.calculateROI.title,
				component: (<div className="grid-x"><CalculateROIContainer /></div>)
			}
		];

		this.setState({
			tabs: tabs,
			tabId: 1
		})
	}

	dispatchTabChange(tabId) {
		this.setState({
			tabId: tabId,
			nextButtonLabel: this.props.ui.next
		})


		if (3 === tabId) {
			this.setState({
				nextButtonLabel: this.props.content.calculateROI.goToNextSteps,
				//	render: true
			})
		}
	}

	dispatchLastButtonClick(tabId) {
		this.props.history.push(`/next-steps`)
	}

	componentWillMount() {
		this.props.valueStoryCurrentMenu('Business Case');
		this.props.showValueStoryMenu(true);
		window.scrollTo(0, 0)
		//	this.getNextPage()
		this.intializePrioritiesTabs()
	}

	vlmAlertToggle() {
        this.setState(prevState => {return {vlmAlertDiv: !prevState.vlmAlertDiv}})
    }

	render() {
		return (
			<div className="grid-container priorities">
				{/* <div className="grid-x">
					<div className="link-label medium-12">
						{this.props.content.businessCase.title}/ <span className="font-bold">{this.state.tabs[this.state.tabId - 1].label}</span>
					</div>
				</div> */}
				<div className="grid-x">
					<div className="page-title medium-4">
						{this.props.content.businessCase.title}
					</div>
					<div onClick={this.vlmAlertToggle} className="medium-8 font-bold hand-cursor" style={{lineHeight:"3", color: "#f0434c", fontSize: "12px"}}>{this.props.discoveryContent.vlmAlertHeader}</div>
					{<div className='medium-12'>
						{this.state.vlmAlertDiv ? <span>{this.props.discoveryContent.vlmAlertDesc1}<br /><br />{this.props.discoveryContent.vlmAlertDesc2}<a href="http://valuemanagement.sap.com/" target="_blank">{this.props.discoveryContent.vlmAlertDesc3}</a>.</span> : ''}
					</div>}
				</div>
				<div className="grid-x business_tabs">
					<div className="medium-12">
						<TabPanel tabs={this.state.tabs}
							tabId={1}
							dispatchTabChange={this.dispatchTabChange}
							dispatchLastButtonClick={this.dispatchLastButtonClick}
							nextButtonLabel={this.state.tabId === 3 ? this.props.content.calculateROI.goToNextSteps : this.props.ui.next} />
					</div>
				</div>
			</div >
		)
	}
}

const mapStateToProps = (state) => {
	return {
		ui: state.content.ui,
		content: state.content,
		discoveryContent: state.content.discovery
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		valueStoryCurrentMenu: (menuItem) => dispatch(valueStoryCurrentMenu(menuItem)),
		showValueStoryMenu: (menuItem) => dispatch(showValueStoryMenu(menuItem)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(BusinessCaseContainer)