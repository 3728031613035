import axios from 'axios'
import { env, ROOT_URL } from 'Constants'
import { func } from 'prop-types';
import { RootRef } from '@material-ui/core';

//user manager
export const TOP_DETAILS_LOADING = 'TOP_DETAILS_LOADING';
export const TOP_DETAILS_SUCCESS = 'TOP_DETAILS_SUCCESS';
export const TOP_DETAILS_FAILURE = 'TOP_DETAILS_FAILURE';
export const RESET_ERROR = 'RESET_ERROR';
export const GET_YOURFILE_DETAILS_SUCCESS = 'GET_YOURFILE_DETAILS_SUCCESS';
export const GET_YOURFILE_DETAILS_FAILURE = 'GET_YOURFILE_DETAILS_FAILURE';
export const GET_YOURFILE_DETAILS_LOADING = 'GET_YOURFILE_DETAILS_LOADING';
export const DEFINESCOPE_FAILURE = 'DEFINESCOPE_FAILURE';
export const DEFINESCOPE_SUCCESS = 'DEFINESCOPE_SUCCESS';
export const DEFINESCOPE_LOADING = 'DEFINESCOPE_LOADING';
export const GET_DETAILEDFILE_FAILURE = 'GET_DETAILEDFILE_FAILURE';
export const GET_DETAILEDFILE_SUCCESS = 'GET_DETAILEDFILE_SUCCESS';
export const GET_DETAILEDFILE_LOADING = 'GET_DETAILEDFILE_LOADING';
export const GET_RESULT_FAILURE = 'GET_RESULT_FAILURE';
export const GET_RESULT_SUCCESS = 'GET_RESULT_SUCCESS';
export const GET_RESULT_LOADING = 'GET_RESULT_LOADING';
export const SET_POPUP_TAB_LOADING_STATUS = 'SET_POPUP_TAB_LOADING_STATUS';
export const CURRENT_ACTIVE_REQUEST = 'CURRENT_ACTIVE_REQUEST'
export const OLD_ACTIVE_REQUEST = 'OLD_ACTIVE_REQUEST'
export const GET_DETAILEDFILEROWINSERTED_FAILURE = 'GET_DETAILEDFILEROWINSERTED_FAILURE';
export const GET_DETAILEDFILEROWINSERTED_SUCCESS = 'GET_DETAILEDFILEROWINSERTED_SUCCESS';
export const GET_DETAILEDFILEROWINSERTED_LOADING = 'GET_DETAILEDFILEROWINSERTED_LOADING';
export const SCOPESUMMARY_FAILURE = 'SCOPESUMMARY_FAILURE';
export const SCOPESUMMARY_SUCCESS = 'SCOPESUMMARY_SUCCESS';
export const SCOPESUMMARY_LOADING = 'SCOPESUMMARY_LOADING';
export const SCOPESUMMARYBUYPROCESS_FAILURE = 'SCOPESUMMARYBUYPROCESS_FAILURE';
export const SCOPESUMMARYBUYPROCESS_SUCCESS = 'SCOPESUMMARYBUYPROCESS_SUCCESS';
export const SCOPESUMMARYBUYPROCESS_LOADING = 'SCOPESUMMARYBUYPROCESS_LOADING';
export const GET_SELECTED_COLUMNS_FAILURE = 'GET_SELECTED_COLUMNS_FAILURE';
export const GET_SELECTED_COLUMNS_SUCCESS = 'GET_SELECTED_COLUMNS_SUCCESS';
export const GET_SELECTED_COLUMNS_LOADING = 'GET_SELECTED_COLUMNS_LOADING';
export const ADD_SELECTED_COLUMNS_FAILURE = 'ADD_SELECTED_COLUMNS_FAILURE';
export const ADD_SELECTED_COLUMNS_SUCCESS = 'ADD_SELECTED_COLUMNS_SUCCESS';
export const ADD_SELECTED_COLUMNS_LOADING = 'ADD_SELECTED_COLUMNS_LOADING';
export const ADD_FAVORITE_FIELD_LOADING = 'ADD_FAVORITE_FIELD_LOADING';
export const ADD_FAVORITE_FIELD_SUCCESS = 'ADD_FAVORITE_FIELD_SUCCESS';
export const GET_FAVORITE_FIELD_LOADING = 'GET_FAVORITE_FIELD_LOADING';
export const GET_FAVORITE_FIELD_SUCCESS = 'GET_FAVORITE_FIELD_SUCCESS';
export const EDIT_FAVORITE_FIELD_LOADING = 'EDIT_FAVORITE_FIELD_LOADING';
export const EDIT_FAVORITE_FIELD_SUCCESS = 'EDIT_FAVORITE_FIELD_SUCCESS';
export const DELETE_FAVORITE_FIELD_LOADING = 'DELETE_FAVORITE_FIELD_LOADING';
export const DELETE_FAVORITE_FIELD_SUCCESS = 'DELETE_FAVORITE_FIELD_SUCCESS';
export const REMOVE_COLUMNS_LOADING = 'REMOVE_COLUMNS_LOADING';
export const REMOVE_COLUMNS_SUCCESS = 'REMOVE_COLUMNS_SUCCESS';
export const GET_INPUTANDCLASSIFICATION_LOADING = 'GET_INPUTANDCLASSIFICATION_LOADING';
export const GET_INPUTANDCLASSIFICATION_SUCCESS = 'GET_INPUTANDCLASSIFICATION_SUCCESS';
export const GET_INPUTANDCLASSIFICATION_FAILURE = 'GET_INPUTANDCLASSIFICATION_FAILURE';
export const GET_CAPIQDATA_BLANK = 'GET_CAPIQDATA_BLANK';
export const GET_SPCAPITALIQ_LOADING = 'GET_SPCAPITALIQ_LOADING';
export const GET_SPCAPITALIQ_SUCCESS = 'GET_SPCAPITALIQ_SUCCESS';
export const GET_SPCAPITALIQ_FAILURE = 'GET_SPCAPITALIQ_FAILURE';
export const GET_SPCAPITALIQULTIMATEPARENT_LOADING = 'GET_SPCAPITALIQULTIMATEPARENT_LOADING';
export const GET_SPCAPITALIQULTIMATEPARENT_SUCCESS = 'GET_SPCAPITALIQULTIMATEPARENT_SUCCESS';
export const GET_SPCAPITALIQULTIMATEPARENT_FAILURE = 'GET_SPCAPITALIQULTIMATEPARENT_FAILURE';
export const STORE_SUPPLIER_SKEY = 'STORE_SUPPLIER_SKEY';
export const STORE_VENDOR_DETAILS = 'STORE_VENDOR_DETAILS';
export const STORE_INPUTANDCLASSIFICATION_DETAILS = 'STORE_INPUTANDCLASSIFICATION_DETAILS';
export const STORE_INDUSTRY_ID = 'STORE_INDUSTRY_ID';
export const GET_SUPPLIERDRILLSCOPE_LOADING = 'GET_SUPPLIERDRILLSCOPE_LOADING';
export const GET_SUPPLIERDRILLSCOPE_SUCCESS = 'GET_SUPPLIERDRILLSCOPE_SUCCESS';
export const GET_SUPPLIERDRILLSCOPE_FAILURE = 'GET_SUPPLIERDRILLSCOPE_FAILURE';
export const TRACK_SUPPLIERDRILLDOWN_PENDING_CHANGES = 'TRACK_SUPPLIERDRILLDOWN_PENDING_CHANGES';
export const TRACK_SUPPLIERDRILLDOWN_SAVE_CHANGES = 'TRACK_SUPPLIERDRILLDOWN_SAVE_CHANGES';
export const GET_SUPPLIERDRILLDOWNUPDATED_FAILURE = 'GET_SUPPLIERDRILLDOWNUPDATED_FAILURE';
export const GET_SUPPLIERDRILLDOWNUPDATED_SUCCESS = 'GET_SUPPLIERDRILLDOWNUPDATED_SUCCESS';
export const GET_SUPPLIERDRILLDOWNUPDATED_LOADING = 'GET_SUPPLIERDRILLDOWNUPDATED_LOADING';
export const GET_INDUSTRYDRILLSCOPE_LOADING = 'GET_INDUSTRYDRILLSCOPE_LOADING';
export const GET_INDUSTRYDRILLSCOPE_SUCCESS = 'GET_INDUSTRYDRILLSCOPE_SUCCESS';
export const GET_INDUSTRYDRILLSCOPE_FAILURE = 'GET_INDUSTRYDRILLSCOPE_FAILURE';
export const STORE_GICS_ID = 'STORE_GICS_ID';
export const STORE_INDUSTRY_DETAILS = 'STORE_INDUSTRY_DETAILS';
export const GET_INDUSTRYSPEND_FAILURE = 'GET_INDUSTRYSPEND_FAILURE';
export const GET_INDUSTRYSPEND_SUCCESS = 'GET_INDUSTRYSPEND_SUCCESS';
export const GET_INDUSTRYSPEND_LOADING = 'GET_INDUSTRYSPEND_LOADING';
export const INDUSTRYDRILLSCOPEUPDATE_FAILURE = 'INDUSTRYDRILLSCOPEUPDATE_FAILURE';
export const INDUSTRYDRILLSCOPEUPDATE_LOADING = 'INDUSTRYDRILLSCOPEUPDATE_LOADING';
export const INDUSTRYDRILLSCOPEUPDATE_SUCCESS = 'INDUSTRYDRILLSCOPEUPDATE_SUCCESS';
export const GET_INDUSTRYSPENDUPDATED_FAILURE = 'GET_INDUSTRYSPENDUPDATED_FAILURE';
export const GET_INDUSTRYSPENDUPDATED_SUCCESS = 'GET_INDUSTRYSPENDUPDATED_SUCCESS';
export const GET_INDUSTRYSPENDUPDATED_LOADING = 'GET_INDUSTRYSPENDUPDATED_LOADING';
export const TRACK_INDUSTRYDRILLSCOPE_PENDING_CHANGES = 'TRACK_INDUSTRYDRILLSCOPE_PENDING_CHANGES';
export const INDUSTRYSUPPLIER_FAILURE = 'INDUSTRYSUPPLIER_FAILURE';
export const INDUSTRYSUPPLIER_SUCCESS = 'INDUSTRYSUPPLIER_SUCCESS';
export const INDUSTRYSUPPLIER_LOADING = 'INDUSTRYSUPPLIER_LOADING';
export const GET_INDUSTRYCAPIQ_FAILURE = 'GET_INDUSTRYCAPIQ_FAILURE';
export const GET_INDUSTRYCAPIQ_SUCCESS = 'GET_INDUSTRYCAPIQ_SUCCESS';
export const GET_INDUSTRYCAPIQ_LOADING = 'GET_INDUSTRYCAPIQ_LOADING';
export const TRACK_USER_SPEND_SELECTIONS = 'TRACK_USER_SPEND_SELECTIONS';
export const CLEAR_CURRENT_ACTIVE_REQUEST = 'CLEAR_CURRENT_ACTIVE_REQUEST';
export const ENABLEMENTINSIGHT_FAILURE = 'ENABLEMENTINSIGHT_FAILURE';
export const ENABLEMENTINSIGHT_SUCCESS = 'ENABLEMENTINSIGHT_SUCCESS';
export const ENABLEMENTINSIGHT_LOADING = 'ENABLEMENTINSIGHT_LOADING';
export const REDIRECT_FROM_ENABLEMENT_TAB = 'REDIRECT_FROM_ENABLEMENT_TAB';
export const REDIRECT_FROM_WIZARD = 'REDIRECT_FROM_WIZARD';

export const GET_ALLINDUSTRIES_LOADING = 'GET_ALLINDUSTRIES_LOADING';
export const GET_ALLINDUSTRIES_SUCCESS = 'GET_ALLINDUSTRIES_SUCCESS';
export const GET_ALLINDUSTRIES_FAILURE = 'GET_ALLINDUSTRIES_FAILURE';
export const GET_ALLINDUSTRIESTREESTRUCTURE_SUCCESS = 'GET_ALLINDUSTRIESTREESTRUCTURE_SUCCESS'
export const SET_SEARCHCLEAR_FLAG = 'SET_SEARCHCLEAR_FLAG'
export const STORE_OVERRIDED_INDUSTRY = 'STORE_OVERRIDED_INDUSTRY'
export const STORE_ENABLEMENT_WAVE_VALUE = 'STORE_ENABLEMENT_WAVE_VALUE'
export const STORE_OVERRIDED_CAPIQID = 'STORE_OVERRIDED_CAPIQID'

export const ENABLEMENT_FAILURE = 'ENABLEMENT_FAILURE';
export const ENABLEMENT_SUCCESS = 'ENABLEMENT_SUCCESS';
export const ENABLEMENT_LOADING = 'ENABLEMENT_LOADING';
export const STORE_ENABLEMENTINSIGHT_FILTER = 'STORE_ENABLEMENTINSIGHT_FILTER'

export const GET_YOURFILE_VALUESTORYREQUESTDETAILS = 'GET_YOURFILE_VALUESTORYREQUESTDETAILS'
export const GET_YOURFILE_REQUESTDETAILS = 'GET_YOURFILE_REQUESTDETAILS'

export const GET_RESULTSUMMARY_DETAILS = 'GET_RESULTSUMMARY_DETAILS'
export const GET_TOP_DETAILS = 'GET_TOP_DETAILS'

export const GET_MAPPEDCOLUMN = 'GET_MAPPEDCOLUMN'

export const GET_ALLINDUSTRIES_GICS_LOADING = 'GET_ALLINDUSTRIES_LOADING'
export const GET_ALLINDUSTRIES_GICS_SUCCESS = 'GET_ALLINDUSTRIES_SUCCESS'
export const GET_ALLINDUSTRIES_GICS_FAILURE = 'GET_ALLINDUSTRIES_FAILURE'

export const GET_UPDATE_GICS_LOADING = 'GET_UPDATEGICS_LOADING'
export const GET_UPDATE_GICS_SUCCESS = 'GET_UPDATEGICS_SUCCESS'
export const GET_UPDATE_GICS_FAILURE = 'GET_UPDATEGICS_FAILURE'

export const GET_COUNTRYDETAILS_LOADING = 'GET_COUNTRYDETAILS_LOADING'
export const GET_COUNTRYDETAILS_SUCCESS = 'GET_COUNTRYDETAILS_SUCCESS'
export const GET_COUNTRYDETAILS_FAILURE = 'GET_COUNTRYDETAILS_FAILURE'

export const UPDATE_COUNTRYDETAILS_LOADING = 'UPDATE_COUNTRYDETAILS_LOADING'
export const UPDATE_COUNTRYDETAILS_SUCCESS = 'UPDATE_COUNTRYDETAILS_SUCCESS'
export const UPDATE_COUNTRYDETAILS_FAILURE = 'UPDATE_COUNTRYDETAILS_FAILURE'

export const USER_DETAILS_LOADING = 'USER_DETAILS_LOADING';
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS';
export const USER_DETAILS_FAILURE = 'USER_DETAILS_FAILURE';

//Your Spend File
const getYourRequestDetailsAPI = `${ROOT_URL}/YourSpendFile/GetYourRequestsDetails`
const loadRequestDetailsAPI = `${ROOT_URL}/YourSpendFile/LoadRequestDetails`
const getAllRequestsAgainstValueStoryAPI = `${ROOT_URL}/YourSpendFile/GetAllRequestsAgainstValueStory`
const getAllMappedColumnAPI = `${ROOT_URL}/YourSpendFile/GetAllMappedSpendColumn`
const activetDeactiveCurrentRequestAPI = `${ROOT_URL}/YourSpendFile/ActivetDeactiveCurrentRequest`
const cleanRequestDataAPI = `${ROOT_URL}/YourSpendFile/CleanRequestData`
const updateCommentAPI = `${ROOT_URL}/YourSpendFile/UpdateComment`
const getUserSpendSelectionsAPI = `${ROOT_URL}/YourSpendFile/GetUserSpendSelections`
const updateRequestParameterAPI = `${ROOT_URL}/YourSpendFile/UpdateRequestParameter`
const updateEnablementParameterPriorityAPI = `${ROOT_URL}/YourSpendFile/UpdateEnablementParameterPriority`
const getAllEnablementParameterPriorityAPI = `${ROOT_URL}/YourSpendFile/GetAllEnablementParameterPriority`
const getSpendFilesDataAPI = `${ROOT_URL}/YourSpendFile/GetSpendFilesData`
const getQualityAssessmentDataAPI = `${ROOT_URL}/YourSpendFile/GetQualityAssessmentData`
const updateQualityAssessmentDataAPI = `${ROOT_URL}/YourSpendFile/UpdateQualityAssessmentData`
const uploadSpendCollectionDataAPI = `${ROOT_URL}/YourSpendFile/UploadSpendCollectionData`
const fetchSpendCollectionColumnAPI = `${ROOT_URL}/YourSpendFile/GetSpendCollectionColumnDetails`
const saveCollectCustomerSpendAPI = `${ROOT_URL}/YourSpendFile/saveCollectCustomerSpend`
const getCollectCustomerSpendDetailsAPI = `${ROOT_URL}/YourSpendFile/GetCollectCustomerSpendDetails`
const getSpendQualityAssessmentDataAPI = `${ROOT_URL}/YourSpendFile/GetSpendQualityAssessmentData`
const updateSpendQualityAssessmentDataAPI = `${ROOT_URL}/YourSpendFile/UpdateSpendQualityAssessmentData`


//Classification & Top Details
const getTopDetailsAPI = `${ROOT_URL}/ClassificationAndTopDetails/GetTopDetails`
const getResultClassSummaryAPI = `${ROOT_URL}/ClassificationAndTopDetails/GetClassSummary`
const getResultMatchSummaryAPI = `${ROOT_URL}/ClassificationAndTopDetails/GetMatchSummary`

//Industry Breakdown
const getSupplierDrilldownUpdateAPI = `${ROOT_URL}/IndustryBreakdown/SupplierDrilldownUpdate`
const getInputAndClassificationDataAPI = `${ROOT_URL}/IndustryBreakdown/GetInputAndClassificationData`
const getSPCapitalIQDataAPI = `${ROOT_URL}/IndustryBreakdown/GetSPCapitalIQData`
const getSPCapitalIQUltimateParentDataAPI = `${ROOT_URL}/IndustryBreakdown/GetSPCapitalIQUltimateParentData`
const getSupplierDrillScopeDataAPI = `${ROOT_URL}/IndustryBreakdown/GetSupplierDrillScopeData`
const getCapIQClassificationIndustriesAPI = `${ROOT_URL}/IndustryBreakdown/GetCapIQClassificationIndustries`
const getSupplierDeduplicationDataAPI = `${ROOT_URL}/IndustryBreakdown/GetSupplierDeduplicationData`
const getIndustryDrillScopeDetailsAPI = `${ROOT_URL}/IndustryBreakdown/GetIndustryDrillScopeDetails`
const getIndustryForSpendAPI = `${ROOT_URL}/IndustryBreakdown/GetIndustryForSpend`
const getIndustryDrillScopeUpdateAPI = `${ROOT_URL}/IndustryBreakdown/IndustryDrillScopeUpdate`
const getIndustrySpendAPI = `${ROOT_URL}/IndustryBreakdown/IndustrySpendUpdate`
const getIndustryDrillScopeSummaryDataAPI = `${ROOT_URL}/IndustryBreakdown/GetIndustryScopeSummaryData`
const getIndustryCapIQDataAPI = `${ROOT_URL}/IndustryBreakdown/GetIndustryCapIQData`
const getNetworkStatsDataPAI = `${ROOT_URL}/IndustryBreakdown/GetNetworkStatsData`
const getSummaryScopeSolutionAPI = `${ROOT_URL}/IndustryBreakdown/GetSummaryScopeSolution`
const getIndustrySupplierDetailsAPI = `${ROOT_URL}/IndustryBreakdown/GetIndustrySupplierDetails`
const getSupplierCountryCodesAPI = `${ROOT_URL}/IndustryBreakdown/GetSupplierCountryCodes`
const getSupplierScopeDetailsAPI = `${ROOT_URL}/IndustryBreakdown/GetSupplierScopeDetails`
const getIndustrySupplierScopeDetailsAPI = `${ROOT_URL}/IndustryBreakdown/GetIndustrySupplierScopeDetails`

//Spend File Details
const getDetailedFileSpendDataAPI = `${ROOT_URL}/SpendFileDetails/GetDetailedFileSpendData`
const getSelectedColumnAPI = `${ROOT_URL}/SpendFileDetails/GetColumnsForRequest`
const addSelectedColumnAPI = `${ROOT_URL}/SpendFileDetails/AddColumnsForRequest`
const addFavoriteFieldAPI = `${ROOT_URL}/SpendFileDetails/AddFavoriteFields`
const getFavoriteFieldAPI = `${ROOT_URL}/SpendFileDetails/GetFavoriteFields`
const editFavoriteFieldAPI = `${ROOT_URL}/SpendFileDetails/EditFavoriteField`
const deleteFavoriteFieldAPI = `${ROOT_URL}/SpendFileDetails/DeleteFavoriteField`
const removeColumnsForRequestAPI = `${ROOT_URL}/SpendFileDetails/RemoveColumnsForRequest`
const getAllIndustriesAPI = `${ROOT_URL}/SpendFileDetails/getAllIndustries`
const getDetailedFileRowsToInsertAPI = `${ROOT_URL}/SpendFileDetails/UpdateSpendDetails`
const getSpendColumnsConfigurationsAPI = `${ROOT_URL}/SpendFileDetails/GetSpendColumnsConfiguration`
const getNdlColumnsConfigurationsAPI = `${ROOT_URL}/SpendFileDetails/GetNdlColumnsConfiguration`
const getNdlReportDataAPI = `${ROOT_URL}/SpendFileDetails/GetNdlReportData`
const uploadUserDefinedFieldsAPI = `${ROOT_URL}/SpendFileDetails/UpdateUserDefinedFields`
const getSupplierCommentsAPI = `${ROOT_URL}/SpendFileDetails/GetSupplierComments`
const fetchIndirectVisibleSolutionInScopeValuesAPI = `${ROOT_URL}/AdminSpendManager/GetIndirectVisibleSolutionInScopeValues`

//Enablement Insight
const getEnablementStrategyDetailsAPI = `${ROOT_URL}/EnablementInsight/GetEnablementStrategyDetails`
const getOnboardingWorkstreamDetailsAPI = `${ROOT_URL}/EnablementInsight/GetOnboardingWorkstreamDetails`
const getEnablementDetailsAPI = `${ROOT_URL}/EnablementInsight/GetEnablementDetails`
const getEnablementDetailsForGanntChartAPI = `${ROOT_URL}/EnablementInsight/GetGanntChartEnablementDetails`
const getNetworkMatchDetailsAPI = `${ROOT_URL}/EnablementInsight/GetNetworkMatchDetails`
const getSpendDocBuyProcessDetailsAPI = `${ROOT_URL}/EnablementInsight/GetSpendDocBuyProcessDetails`
const getCategoryInsightDetailsAPI = `${ROOT_URL}/EnablementInsight/GetCategoryInsightDetails`
const getGlobalDetailsAPI = `${ROOT_URL}/EnablementInsight/GetGlobalDetails`
const getEnablementFiltersDetailsAPI = `${ROOT_URL}/EnablementInsight/GetEnablementInsightFiltersDetails`
const getEnablementInsightSuppliersDetailsAPI = `${ROOT_URL}/EnablementInsight/GetEnablementInsightSuppliersDetails`
const updateFlightPlanningSupplierDetailsAPI = `${ROOT_URL}/EnablementInsight/UpdateFlightPlanningSupplierDetails`
const updateWaveDescriptionAPI = `${ROOT_URL}/EnablementInsight/UpdateWaveDescription`
const getGanntChartEnablementWavesLookupAPI = `${ROOT_URL}/EnablementInsight/GetGanntChartEnablementWavesLookup`
const updateEnablementStrategyCurrentFocusAPI = `${ROOT_URL}/EnablementInsight/UpdateEnablementStrategyFocus`

//Gantt Chart
const getGanntChartDataAPI = `${ROOT_URL}/GanttChart/GetGanntChartData`
const getGanntChartSuppliersDetailsAPI = `${ROOT_URL}/GanttChart/GetGanntChartSuppliersPopUpData`

//Scope Summary
const getScopeSummarySolutionAPI = `${ROOT_URL}/ScopeSummary/GetScopeSummarySolution`
const getScopeSummaryBuyProcessAPI = `${ROOT_URL}/ScopeSummary/GetScopeSummaryBuyProcess`

//Program Enablement Summary
const getConcentrationAnalysisAPI = `${ROOT_URL}/ProgramEnablementSummary/GetConcentrationAnalysis`
const getMatchedSupplierAPI = `${ROOT_URL}/ProgramEnablementSummary/GetMatchedSupplier`
const getMatchedSummaryAPI = `${ROOT_URL}/ProgramEnablementSummary/GetMatchedSummary`
const getSupplierByReasonAPI = `${ROOT_URL}/ProgramEnablementSummary/GetSupplierByReason`
const getBuyProcessSummaryAPI = `${ROOT_URL}/ProgramEnablementSummary/GetBuyProcessSummary`
const storeUserSpendSelectionAPI = `${ROOT_URL}/ProgramEnablementSummary/StoreUserSpendSelection`

//World Map
const getHeatMapDataAPI = `${ROOT_URL}/WorldMap/GetHeatMapData`
const getWorldMapSuppliersDetailsAPI = `${ROOT_URL}/WorldMap/GetWorldMapSuppliersPopUpData`

//Vendor Files
const getVendorFilesAPI = `${ROOT_URL}/SpendFileDetails/GetVendorFiles`

//Spend Manager - Admin section under Menu
const getEnablementResourceParametersAPI = `${ROOT_URL}/AdminSpendManager/GetEnablementResourceParameters`
const updateEnablementResourceParametersAPI = `${ROOT_URL}/AdminSpendManager/UpdateEnablementResourceParameters`
const getEnablementResourceParametersAdminAPI = `${ROOT_URL}/AdminSpendManager/GetEnablementResourceParametersAdmin`
const updateEnablementResourceParametersAdminAPI = `${ROOT_URL}/AdminSpendManager/UpdateEnablementResourceParametersAdmin`
const getFlightPlanningDocParametersAPI = `${ROOT_URL}/AdminSpendManager/GetFlightPlanningDocParameters`
const updateFlightPlanningDocParametersAPI = `${ROOT_URL}/AdminSpendManager/UpdateFlightPlanningDocParameters`
const getFlightPlanningSpendParametersAPI = `${ROOT_URL}/AdminSpendManager/GetFlightPlanningSpendParameters`
const getFlightPlanningTransactingBuyRelationshipAPI = `${ROOT_URL}/AdminSpendManager/GetFlightPlanningTransactingBuyRelationshipParameters`
const updateFlightPlanningSpendParametersAPI = `${ROOT_URL}/AdminSpendManager/UpdateFlightPlanningSpendParameters`
const updateFlightPlanningTransactingBuyRelationshipAPI = `${ROOT_URL}/AdminSpendManager/UpdateFlightPlanningTransactingBuyRelationshipParameters`
const getFlightPlanningNetworkEnablementMatchTypeParametersAPI = `${ROOT_URL}/AdminSpendManager/GetFlightPlanningNetworkEnablementMatchTypeParameters`
const updateFlightPlanningNetworkEnablementMatchTypeParametersAPI = `${ROOT_URL}/AdminSpendManager/UpdateFlightPlanningNetworkEnablementMatchTypeParameters`
const getFlightPlanningRecommendationDetailsAPI = `${ROOT_URL}/AdminSpendManager/GetFlightPlanningRecommendationDetails`
const getSupplierChoiceEnablementParametersAPI  = `${ROOT_URL}/AdminSpendManager/GetSupplierChoiceEnablementParameters`
const updateFlightPlanningRecommendationDetailsAPI = `${ROOT_URL}/AdminSpendManager/UpdateFlightPlanningRecommendationDetails`
const updateOnboardingworkstreamDetailsAPI = `${ROOT_URL}/AdminSpendManager/UpdateOnboardingworkstreamDetails`
const getWaveWeightCategoryDetailsAPI = `${ROOT_URL}/AdminSpendManager/GetWaveWeightCategoryDetails`
const updateWaveWeightCategoryDetailsAPI = `${ROOT_URL}/AdminSpendManager/UpdateWaveWeightCategoryDetails`
const fetchRampScheduleFactorAPI = `${ROOT_URL}/AdminSpendManager/GetScheduleFactor`
const updateRampScheduleFactorAPI = `${ROOT_URL}/AdminSpendManager/UpdateScheduleFactorValues`
const fetchEnablementPriorityAdminAPI = `${ROOT_URL}/AdminSpendManager/GetEnablementPriorityAdmin`
const UpdateEnablementPriorityAdminValuesAPI = `${ROOT_URL}/AdminSpendManager/UpdateEnablementPriorityAdminValues`
const fetchUpdateFieldVisibilityAPI = `${ROOT_URL}/AdminSpendManager/FetchUpdateFieldVisibility`
const fetchSupplyChainProcessAdminAPI = `${ROOT_URL}/AdminSpendManager/GetSupplyChainProcessAdmin`
const UpdateSupplyChainProcessAdminAPI = `${ROOT_URL}/AdminSpendManager/UpdateSupplyChainProcessAdmin`
const fetchGoLiveVsKickOffWeekDataAPI = `${ROOT_URL}/AdminSpendManager/GetGoLiveVsKickOffWeekData`
const fetchStandardAccountYieldFactorAPI = `${ROOT_URL}/AdminSpendManager/GetStandardAccountYield`
const updateStandardAccountYieldAPI = `${ROOT_URL}/AdminSpendManager/UpdateStandardAccountYield`
const updateGoLiveVsKickOffWeekDataAPI = `${ROOT_URL}/AdminSpendManager/UpdateGoLiveVsKickOffValues`
const getAllGICSAPI = `${ROOT_URL}/AdminSpendManager/getAllGICS`
const updateGICSAPI = `${ROOT_URL}/AdminSpendManager/UpdateGICS`
const getCountryDetailsAPI = `${ROOT_URL}/AdminSpendManager/GetCountryDetails`
const updateCountryDetailsAPI = `${ROOT_URL}/AdminSpendManager/UpdateCountryDetails`
const fetchL4IndustryValuesAPI = `${ROOT_URL}/AdminSpendManager/GetL4IndustryPriorityValues`
const uploadL4IndustryPriorityDataAPI = `${ROOT_URL}/AdminSpendManager/updateL4IndustryPriorityValues`
const getSpendParametersAPI = `${ROOT_URL}/AdminSpendManager/GetSpendParameters`
const updateSpendParametersAPI = `${ROOT_URL}/AdminSpendManager/UpdateSpendParameters`
const fetchMarketPlaceEligibleAPI = `${ROOT_URL}/AdminSpendManager/GetMarketPlaceEligibleValues`
const updateMarketPlaceEligibleValuesAPI = `${ROOT_URL}/AdminSpendManager/UpdateMarketPlaceEligibleValues`
const fetchSolutionInScopeValuesAPI = `${ROOT_URL}/AdminSpendManager/GetSolutionInScopeValues`
const updateSolutionInScopeValuesAPI = `${ROOT_URL}/AdminSpendManager/UpdateSolutionInScopeValue`
const fetchVisibleSolutionInScopeValuesAPI = `${ROOT_URL}/AdminSpendManager/GetVisibleSolutionInScopeValues`
const fetchANIdBPIdDetailsAPI = `${ROOT_URL}/AdminSpendManager/fetchANIdBPIdDetails`
const addANIDDetailsAPI = `${ROOT_URL}/AdminSpendManager/AddUpdateANIDDetails`
const insertUpldDownldRptLogAPI = `${ROOT_URL}/SpendFileDetails/InsertReportLogDetails`


export function resetError() {
  return {
    type: RESET_ERROR,
    payload: null
  };
}

export function topDetailsLoading(bool) {
  return {
    type: TOP_DETAILS_LOADING,
    payload: bool
  }
}

export function topDetailsSuccess(message) {
  return {
    type: TOP_DETAILS_SUCCESS,
    payload: message
  };
}

export function topDetailsFailure(error) {
  return {
    type: TOP_DETAILS_FAILURE,
    payload: error
  };
}

export function getYourFileLoading(bool) {
  return {
    type: GET_YOURFILE_DETAILS_LOADING,
    payload: bool
  }
}

export function getYourFileSuccess(message) {
  return {
    type: GET_YOURFILE_DETAILS_SUCCESS,
    payload: message
  }
}

export function getYourFileFailure(error) {
  return {
    type: GET_YOURFILE_DETAILS_FAILURE,
    payload: error
  }
}

export function currentActiveRequest(requestDetails) {
  return {
    type: CURRENT_ACTIVE_REQUEST,
    payload: requestDetails
  }
}

export function oldActiveRequest(requestDetails) {
  return {
    type: OLD_ACTIVE_REQUEST,
    payload: requestDetails
  }
}

//Your Spend Files
export function getYourRequestsDetails(Email) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: { Email: Email },
      url: getYourRequestDetailsAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function LoadRequestDetails(requestDetails, isNewRequest) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: { RequestData: requestDetails, IsNewRequest: isNewRequest },
      url: loadRequestDetailsAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function GetAllRequestsAgainstValueStory(userId, valueStoryId) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: { UserID: userId, VSID: valueStoryId },
      url: getAllRequestsAgainstValueStoryAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function GetAllMappedColumn(userId) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: { UserID: userId },
      url: getAllMappedColumnAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function ActivetDeactiveCurrentRequest(requestDetails) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: requestDetails,
      url: activetDeactiveCurrentRequestAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function CleanRequestData(requestDetails) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: requestDetails,
      url: cleanRequestDataAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function UpdateComment(requestDetails) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: requestDetails,
      url: updateCommentAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function getUserSpendSelections(VSId, DMCId) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: { VSID: VSId, DMCID: DMCId },
      url: getUserSpendSelectionsAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function UpdateRequestParameter(requestDetails) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: requestDetails,
      url: updateRequestParameterAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function updateEnablementParameterPriority(records) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: records,
      url: updateEnablementParameterPriorityAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function getAllEnablementParameterPriority(param) {
  return (dispatch) => {
    dispatch(getDetailedFileLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: { DMCID: param.DMC_ID, VSID: param.VS_ID, Email: param.User_Email },
      url: getAllEnablementParameterPriorityAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(getDetailedFileSuccess(false))
      return response
    }).catch((error) => {
      return error
    })
  }
}

//Classification & Top Details
export function getTopDetails(RequestFileId, ValueStoryId, UserId) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: { DMCID: RequestFileId, VSID: ValueStoryId, Email: UserId },
      url: getTopDetailsAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function getResultClassSummary(RequestFileId, ValueStoryId, UserId) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: { DMCID: RequestFileId, VSID: ValueStoryId, Email: UserId },
      url: getResultClassSummaryAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function getResultMatchSummary(RequestFileId, ValueStoryId, UserId) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: { DMCID: RequestFileId, VSID: ValueStoryId, Email: UserId },
      url: getResultMatchSummaryAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}


//Industry Breakdown
export function supplierDrillDownUpdate(record) {
  return (dispatch) => {
    dispatch(getSupplierDrilldownUpdatedLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: record,
      url: getSupplierDrilldownUpdateAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(getSupplierDrilldownUpdatedLoading(false))
      dispatch(getSupplierDrilldownUpdatedSuccess(true))
      return response
    }).catch((error) => {
      dispatch(getSupplierDrilldownUpdatedFailure(error))
      return error
    })
  }
}

export function getInputAndClassificationData(DMCId, VSId, userEmail, SKEY) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: { DMCId: DMCId, VSId: VSId, Email: userEmail, DATA_COLLECTIONS_SKEY: SKEY },
      url: getInputAndClassificationDataAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function getSPCapitalIQData(CapIQID) {
  return (dispatch) => {
    //dispatch(getSPCapitalIQLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: { CapIQID: CapIQID },
      url: getSPCapitalIQDataAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function getSPCapitalIQUltimateParentData(CapIQID) {
  return (dispatch) => {
    //dispatch(getSPCapitalIQUltimateParentLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: { CapIQID: CapIQID },
      url: getSPCapitalIQUltimateParentDataAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function getSupplierDrillScopeData(DMCId, VSId, userEmail, SKEY) {
  return (dispatch) => {
    //dispatch(getSupplierDrillScopeDataLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: { DMCId: DMCId, VSId: VSId, Email: userEmail, DATA_COLLECTIONS_SKEY: SKEY },
      url: getSupplierDrillScopeDataAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function getCapIQClassificationIndustries(CapIQID) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: { CapIQID: CapIQID },
      url: getCapIQClassificationIndustriesAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function getSupplierDeduplicationData(DMCId, VSId, userEmail, SKEY) {
  return (dispatch) => {
    //dispatch(getInputAndClassificationDataLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: { DMCId: DMCId, VSId: VSId, Email: userEmail, DATA_COLLECTIONS_SKEY: SKEY },
      url: getSupplierDeduplicationDataAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function getIndustryDrillScopeDetails(DMCId, VSId, userEmail, Id) {
  return (dispatch) => {
    //dispatch(getSupplierDrillScopeDataLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: { DMCId: DMCId, VSId: VSId, Email: userEmail, CAPIQ_INDUSTRY_ID: Id },
      url: getIndustryDrillScopeDetailsAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function getIndustrySpend(params) {
  return (dispatch) => {
    dispatch(getIndustrySpendLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: params,
      url: getIndustryForSpendAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(getIndustrySpendLoading(false))
      dispatch(getIndustrySpendSuccess(response))
      return response
    }).catch((error) => {
      return error
    })
  }
}

export function industryDrillScopeUpdate(record) {
  return (dispatch) => {
    dispatch(industryDrillScopeUpdateLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: record,
      url: getIndustryDrillScopeUpdateAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(industryDrillScopeUpdateLoading(false))
      dispatch(industryDrillScopeUpdateSuccess(true))
      return response
    }).catch((error) => {
      dispatch(industryDrillScopeUpdateFailure(error))
      return error
    })
  }
}

export function industrySpendUpdate(record) {
  return (dispatch) => {
    dispatch(getIndustrySpendUpdatedLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: record,
      url: getIndustrySpendAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(getIndustrySpendUpdatedLoading(false))
      dispatch(getIndustrySpendUpdatedSuccess(true))
      return response
    }).catch((error) => {
      dispatch(getIndustrySpendUpdatedFailure(error))
      return error
    })
  }
}

export function getIndustryDrillScopeSummaryData(DMCId, VSId, userEmail, Id) {
  return (dispatch) => {
    //dispatch(getSupplierDrillScopeDataLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: { DMCId: DMCId, VSId: VSId, Email: userEmail, CAPIQ_INDUSTRY_ID: Id },
      url: getIndustryDrillScopeSummaryDataAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function getIndustryCapIQ(params) {
  return (dispatch) => {
    dispatch(getIndustryCapIQLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: params,
      url: getIndustryCapIQDataAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(getIndustryCapIQLoading(false))
      dispatch(getIndustryCapIQSuccess(response))
      return response
    }).catch((error) => {
      return error
    })
  }
}

export function getNetworkStatsData(GICSID) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: { GICSID: GICSID },
      url: getNetworkStatsDataPAI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function getSummaryScopeSolution(data) {
  return (dispatch) => {
    dispatch(defineScopeLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: getSummaryScopeSolutionAPI,
      data: {
        DMC_ID: data.DMC_ID, VS_ID: data.VS_ID, USER_EMAIL: data.User_Email, Country: data.Country,
        SolutionConfirmation: data.SolutionConfirmation, GISC_ID: data.GICS_ID, Solution: data.Solution, OutSolution: data.outSolution,
        Buyer_Region: data.Buyer_Region, Business_Unit: data.Business_Unit, Goods: data.Goods, DirectSpend: data.DirectSpend,
        MetricsType: data.MetricsType, AribaNetwork: data.AribaNetwork, SAPAribaBuyProcess: data.SAPAribaBuyProcess
      },
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      //dispatch(defineScopeSuccess(response))
      return response
    })
  }
}

export function getIndustrySupplierDetails(defineScope) {
  return (dispatch) => {

    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: getIndustrySupplierDetailsAPI,
      data: defineScope,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function getSupplierCountryCodes(defineScope) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: getSupplierCountryCodesAPI,
      data: defineScope,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function getSupplierScopeDetails(data) {
  return (dispatch) => {
    dispatch(defineScopeLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: getSupplierScopeDetailsAPI,
      data: {
        DMC_ID: data.DMC_ID, VS_ID: data.VS_ID, USER_EMAIL: data.User_Email, Country: data.Country,
        SolutionConfirmation: data.SolutionConfirmation, GISC_ID: data.GICS_ID, Solution: data.Solution, OutSolution: data.outSolution,
        Buyer_Region: data.Buyer_Region, Business_Unit: data.Business_Unit, Goods: data.Goods, DirectSpend: data.DirectSpend,
        MetricsType: data.MetricsType, AribaNetwork: data.AribaNetwork, SAPAribaBuyProcess: data.SAPAribaBuyProcess
      },
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      //dispatch(defineScopeSuccess(response))
      return response
    })
  }
}

export function getIndustrySupplierScopeDetails(data) {
  return (dispatch) => {
    dispatch(defineScopeLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: getIndustrySupplierScopeDetailsAPI,
      data: {
        DMC_ID: data.DMC_ID, VS_ID: data.VS_ID, USER_EMAIL: data.User_Email, Country: data.Country,
        SolutionConfirmation: data.SolutionConfirmation, GISC_ID: data.GICS_ID, Solution: data.Solution
      },
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      return response
    })
  }
}

//Spend File Details
export function getDetailedFileSpendData(DMCId, VSId, userEmail, requestFileRecordCount, roundTripNumber) {
  return (dispatch) => {
    dispatch(getDetailedFileLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: { DMCId: DMCId, VSId: VSId, Email: userEmail, NumberOfRecords: requestFileRecordCount, RoundTripNumber: roundTripNumber },
      url: getDetailedFileSpendDataAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(getDetailedFileSuccess(false))
      return response
    }).catch((error) => {
      return error
    })
  }
}

export function getSelectedColumn(DMCId, VSId, USER_ID) {
  return (dispatch) => {
    dispatch(getSelectedColumnLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: { DmcId: DMCId, VsId: VSId, UserId: USER_ID },
      url: getSelectedColumnAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(getSelectedColumnSuccess(false))
      return response
    }).catch((error) => {
      return error
    })
  }
}

export function addSelectedColumn(DMCId, VSId, USER_ID, COLUMNSOrFAVId) {
  return (dispatch) => {
    dispatch(addSelectedColumnLoading(true))
    let token = localStorage.getItem('jwtToken')
    let payload = {
      DmcId: DMCId, VsId: VSId, UserId: USER_ID
    };
    if (Array.isArray(COLUMNSOrFAVId)) {
      payload["Columns"] = COLUMNSOrFAVId;
    } else {
      payload["FavoriteFieldsId"] = COLUMNSOrFAVId;
    }
    return axios({
      method: 'post',
      data: payload,
      url: addSelectedColumnAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(addSelectedColumnSuccess(false))
      return response
    }).catch((error) => {
      return error
    })
  }
}

export function addFavoriteFields(USER_ID, TEMPLATE_NAME, COLUMNS) {
  return (dispatch) => {
    dispatch(addFavoriteFieldsLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: { UserId: USER_ID, TemplateName: TEMPLATE_NAME, Columns: COLUMNS },
      url: addFavoriteFieldAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(addFavoriteFieldsSuccess(false))
      return response
    }).catch((error) => {
      return error
    })
  }
}

export function getFavoriteFields(USER_ID) {
  return (dispatch) => {
    dispatch(getFavoriteFieldsLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: { UserId: USER_ID },
      url: getFavoriteFieldAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(getFavoriteFieldsSuccess(false))
      return response
    }).catch((error) => {
      return error
    })
  }
}

export function editFavoriteField(ID, TEMPLATE_NAME) {
  return (dispatch) => {
    dispatch(editFavoriteFieldLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: { Id: ID, TemplateName: TEMPLATE_NAME },
      url: editFavoriteFieldAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(editFavoriteFieldSuccess(false))
      return response
    }).catch((error) => {
      return error
    })
  }
}

export function deleteFavoriteField(UserId, FavFieldId) {
  return (dispatch) => {
    dispatch(deleteFavoriteFieldLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: { UserId: UserId, FavoriteFieldsId: FavFieldId },
      url: deleteFavoriteFieldAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(deleteFavoriteFieldSuccess(false))
      return response
    }).catch((error) => {
      return error
    })
  }
}

export function removeColumnsForRequest(UserId, VsId, DmcId) {
  return (dispatch) => {
    dispatch(removeColumnsLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: { UserId: UserId, VsId: VsId, DmcId: DmcId },
      url: removeColumnsForRequestAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(removeColumnsSuccess(false))
      return response
    }).catch((error) => {
      return error
    })
  }
}

export function getAllIndustries() {
  return (dispatch) => {
    dispatch(getAllIndustriesLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: getAllIndustriesAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      //dispatch(getAllIndustriesSuccess(response.data.Result))
      return response
    })
  }
}

export function insertDetailedFileRecords(records) {
  return (dispatch) => {
    dispatch(getDetailedFileFileRecordsInsertedLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: records,
      url: getDetailedFileRowsToInsertAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(getDetailedFileFileRecordsInsertedLoading(false))
      dispatch(getDetailedFileRecordsInsertedSuccess(true))
      return response
    }).catch((error) => {
      dispatch(getDetailedFileFileRecordsInsertedFailure(error))
      return error
    })
  }
}

export function getSpendColumnsConfiguration() {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: getSpendColumnsConfigurationsAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function getNdlColumnsConfiguration() {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: getNdlColumnsConfigurationsAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function getNdlReportData(detail) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: detail,
      url: getNdlReportDataAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function uploadUserDefinedFields(records) {
  return (dispatch) => {
    dispatch(getDetailedFileFileRecordsInsertedLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: records,
      url: uploadUserDefinedFieldsAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(getDetailedFileFileRecordsInsertedLoading(false))
      dispatch(getDetailedFileRecordsInsertedSuccess(true))
      return response
    }).catch((error) => {
      dispatch(getDetailedFileFileRecordsInsertedFailure(error))
      return error
    })
  }
}

export function getSupplierComments(DMCId, VSId, SKEY, userEmail) {
  return (dispatch) => {
    //dispatch(getSupplierDrillScopeDataLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: { DMCId: DMCId, VSId: VSId, Email: userEmail, SKEY: SKEY },
      url: getSupplierCommentsAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

//Enablement Insight
export function getEnablementStrategyDetails(data) {
  return (dispatch) => {
    dispatch(defineScopeLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: getEnablementStrategyDetailsAPI,
      data: {
        DMC_ID: data.DMC_ID, VS_ID: data.VS_ID, USER_EMAIL: data.User_Email,
        Dedup_Status: data.dedupStats, Customer_Possibly_Enabled: data.enabledCustomer, Transacting_Customer: data.transactingCustomer, Buyer_Region: data.buyerRegion,
        Business_Unit: data.businessUnit, ERP_System: data.erpSystem, L4_Industry_Name: data.l4IndustryName, ERP_Commodity_Code1: data.erpCode, ERP_Commodity_Code2: data.erpDesc, SIC_UNSPSC_CODE: data.sicCode, SIC_UNSPSC_DESC: data.sicDesc,
        Goods_Or_Service: data.goods, Invoice_Origination_Country: data.invoiceCountry, Legal_Invoicing: data.legalInvoice, SAP_Network_Eligible: data.aribaNwEligible, Direct_Spend: data.directSpend, Metric_Type: data.metricsType
      },
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      return response
    })
  }
}

//Onboarding Workstream
export function getOnboardingWorkstreamDetails(data) {
  return (dispatch) => {
    dispatch(defineScopeLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: getOnboardingWorkstreamDetailsAPI,
      data: {
        DMC_ID: data.DMC_ID, VS_ID: data.VS_ID, USER_EMAIL: data.User_Email,
        Dedup_Status: data.dedupStats, Customer_Possibly_Enabled: data.enabledCustomer, Transacting_Customer: data.transactingCustomer, Buyer_Region: data.buyerRegion,
        Business_Unit: data.businessUnit, ERP_System: data.erpSystem, L4_Industry_Name: data.l4IndustryName, ERP_Commodity_Code1: data.erpCode, ERP_Commodity_Code2: data.erpDesc, SIC_UNSPSC_CODE: data.sicCode, SIC_UNSPSC_DESC: data.sicDesc,
        Goods_Or_Service: data.goods, Invoice_Origination_Country: data.invoiceCountry, Legal_Invoicing: data.legalInvoice, SAP_Network_Eligible: data.aribaNwEligible, Direct_Spend: data.directSpend, Metric_Type: data.metricsType
      },
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      return response
    })
  }
}

export function getEnablementDetails(data) {
  return (dispatch) => {
    dispatch(defineScopeLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: getEnablementDetailsAPI,
      data: {
        DMC_ID: data.DMC_ID, VS_ID: data.VS_ID, USER_EMAIL: data.User_Email,
        Dedup_Status: data.dedupStats, Customer_Possibly_Enabled: data.enabledCustomer, Transacting_Customer: data.transactingCustomer, Buyer_Region: data.buyerRegion,
        Business_Unit: data.businessUnit, ERP_System: data.erpSystem, L4_Industry_Name: data.l4IndustryName, ERP_Commodity_Code1: data.erpCode, ERP_Commodity_Code2: data.erpDesc, SIC_UNSPSC_CODE: data.sicCode, SIC_UNSPSC_DESC: data.sicDesc,
        Goods_Or_Service: data.goods, Invoice_Origination_Country: data.invoiceCountry, Legal_Invoicing: data.legalInvoice, SAP_Network_Eligible: data.aribaNwEligible, Direct_Spend: data.directSpend, Metric_Type: data.metricsType
      },
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      return response
    })
  }
}

export function getEnablementDetailsForGanntChart(data) {
  return (dispatch) => {
    dispatch(defineScopeLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: getEnablementDetailsForGanntChartAPI,
      data: {
        DMC_ID: data.DMC_ID, VS_ID: data.VS_ID, USER_EMAIL: data.User_Email,
        Dedup_Status: data.dedupStats, Customer_Possibly_Enabled: data.enabledCustomer, Transacting_Customer: data.transactingCustomer, Buyer_Region: data.buyerRegion,
        Business_Unit: data.businessUnit, ERP_System: data.erpSystem, L4_Industry_Name: data.l4IndustryName, ERP_Commodity_Code1: data.erpCode, ERP_Commodity_Code2: data.erpDesc, SIC_UNSPSC_CODE: data.sicCode, SIC_UNSPSC_DESC: data.sicDesc,
        Goods_Or_Service: data.goods, Invoice_Origination_Country: data.invoiceCountry, Legal_Invoicing: data.legalInvoice, SAP_Network_Eligible: data.aribaNwEligible, Direct_Spend: data.directSpend
      },
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      return response
    })
  }
}

export function getNetworkMatchDetails(data) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: getNetworkMatchDetailsAPI,
      data: {
        DMC_ID: data.DMC_ID, VS_ID: data.VS_ID, USER_EMAIL: data.User_Email,
        Dedup_Status: data.dedupStats, Customer_Possibly_Enabled: data.enabledCustomer, Transacting_Customer: data.transactingCustomer, Buyer_Region: data.buyerRegion,
        Business_Unit: data.businessUnit, ERP_System: data.erpSystem, L4_Industry_Name: data.l4IndustryName, ERP_Commodity_Code1: data.erpCode, ERP_Commodity_Code2: data.erpDesc, SIC_UNSPSC_CODE: data.sicCode, SIC_UNSPSC_DESC: data.sicDesc,
        Goods_Or_Service: data.goods, Invoice_Origination_Country: data.invoiceCountry, Legal_Invoicing: data.legalInvoice, SAP_Network_Eligible: data.aribaNwEligible, Direct_Spend: data.directSpend, Metric_Type: data.metricsType
      },
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      return response
    })
  }
}

export function getSpendDocBuyProcessDetails(data) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: getSpendDocBuyProcessDetailsAPI,
      data: {
        DMC_ID: data.DMC_ID, VS_ID: data.VS_ID, USER_EMAIL: data.User_Email,
        Dedup_Status: data.dedupStats, Customer_Possibly_Enabled: data.enabledCustomer, Transacting_Customer: data.transactingCustomer, Buyer_Region: data.buyerRegion,
        Business_Unit: data.businessUnit, ERP_System: data.erpSystem, L4_Industry_Name: data.l4IndustryName, ERP_Commodity_Code1: data.erpCode, ERP_Commodity_Code2: data.erpDesc, SIC_UNSPSC_CODE: data.sicCode, SIC_UNSPSC_DESC: data.sicDesc,
        Goods_Or_Service: data.goods, Invoice_Origination_Country: data.invoiceCountry, Legal_Invoicing: data.legalInvoice, SAP_Network_Eligible: data.aribaNwEligible, Direct_Spend: data.directSpend, Metric_Type: data.metricsType
      },
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      return response
    })
  }
}

export function getCategoryInsightDetails(data) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: getCategoryInsightDetailsAPI,
      data: {
        DMC_ID: data.DMC_ID, VS_ID: data.VS_ID, USER_EMAIL: data.User_Email,
        Dedup_Status: data.dedupStats, Customer_Possibly_Enabled: data.enabledCustomer, Transacting_Customer: data.transactingCustomer, Buyer_Region: data.buyerRegion,
        Business_Unit: data.businessUnit, ERP_System: data.erpSystem, L4_Industry_Name: data.l4IndustryName, ERP_Commodity_Code1: data.erpCode, ERP_Commodity_Code2: data.erpDesc, SIC_UNSPSC_CODE: data.sicCode, SIC_UNSPSC_DESC: data.sicDesc,
        Goods_Or_Service: data.goods, Invoice_Origination_Country: data.invoiceCountry, Legal_Invoicing: data.legalInvoice, SAP_Network_Eligible: data.aribaNwEligible, Direct_Spend: data.directSpend, Metric_Type: data.metricsType,
        Onboard_Work_Stream:data.onboardWorkStream

      },
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      return response
    })
  }
}

export function getGlobalDetails(data) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: getGlobalDetailsAPI,
      data: {
        DMC_ID: data.DMC_ID, VS_ID: data.VS_ID, USER_EMAIL: data.User_Email,
        Dedup_Status: data.dedupStats, Customer_Possibly_Enabled: data.enabledCustomer, Transacting_Customer: data.transactingCustomer, Buyer_Region: data.buyerRegion,
        Business_Unit: data.businessUnit, ERP_System: data.erpSystem, L4_Industry_Name: data.l4IndustryName, ERP_Commodity_Code1: data.erpCode, ERP_Commodity_Code2: data.erpDesc, SIC_UNSPSC_CODE: data.sicCode, SIC_UNSPSC_DESC: data.sicDesc,
        Goods_Or_Service: data.goods, Invoice_Origination_Country: data.invoiceCountry, Legal_Invoicing: data.legalInvoice, SAP_Network_Eligible: data.aribaNwEligible, Direct_Spend: data.directSpend, Metric_Type: data.metricsType
      },
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      return response
    })
  }
}

export function getEnablementFiltersDetails(data) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: getEnablementFiltersDetailsAPI,
      data: {
        DMC_ID: data.DMC_ID, VS_ID: data.VS_ID, USER_EMAIL: data.User_Email
      },
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      return response
    })
  }
}

export function getEnablementInsightSuppliersDetails(data) {
  let dataTemp = {
    DMC_ID: data.DMC_ID, VS_ID: data.VS_ID, USER_EMAIL: data.User_Email,
    FirstColumn_Name: data.column1Name, FirstColumn_Value: data.column1Value,
    SecondColumn_Name: data.column2Name, ThirdColumn_Name: data.column3Name,
    Dedup_Status: data.dedupStats, Customer_Possibly_Enabled: data.enabledCustomer, Transacting_Customer: data.transactingCustomer, Buyer_Region: data.buyerRegion,
    Business_Unit: data.businessUnit, ERP_System: data.erpSystem, L4_Industry_Name: data.l4IndustryName, ERP_Commodity_Code1: data.erpCode, ERP_Commodity_Code2: data.erpDesc, SIC_UNSPSC_CODE: data.sicCode, SIC_UNSPSC_DESC: data.sicDesc,
    Goods_Or_Service: data.goods, Invoice_Origination_Country: data.invoiceCountry, Legal_Invoicing: data.legalInvoice, SAP_Network_Eligible: data.aribaNwEligible, Direct_Spend: data.directSpend,
    Onboard_Work_Stream:data.onboardWorkStream
  }

  if (data.column2Value) {
    dataTemp.SecondColumn_Value = data.column2Value
    dataTemp.ThirdColumn_Value = data.column3Value
  }

  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: getEnablementInsightSuppliersDetailsAPI,
      data: dataTemp,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      return response
    })
  }
}

export function updateFlightPlanningSupplierDetails(data) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: updateFlightPlanningSupplierDetailsAPI,
      data: data,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      return response
    })
  }
}

export function updateWaveDescription(data) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: updateWaveDescriptionAPI,
      data: data,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      return response
    })
  }
}

export function getGanntChartEnablementWavesLookup(dmcId, vsID, userEmail) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: { DMCID: dmcId, VSID: vsID, Email: userEmail },
      url: getGanntChartEnablementWavesLookupAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

//Gantt Chart
export function getGanntChartData(RequestFileId, ValueStoryId, UserId) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: { DMCID: RequestFileId, VSID: ValueStoryId, Email: UserId },
      url: getGanntChartDataAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function getGanntChartSuppliersDetails(data) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: getGanntChartSuppliersDetailsAPI,
      data: data,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      return response
    })
  }
}

//Scope Summary
export function getScopeSummarySolution(data) {
  return (dispatch) => {
    dispatch(scopeSummaryLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: getScopeSummarySolutionAPI,
      data: {
        DMC_ID: data.DMC_ID, VS_ID: data.VS_ID, USER_EMAIL: data.USER_ID
      },
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      //dispatch(scopeSummarySuccess(response))
      return response
    })
  }
}

export function getScopeSummaryBuyProcess(data) {
  return (dispatch) => {
    // dispatch(scopeSummaryBuyProcessLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: getScopeSummaryBuyProcessAPI,
      data: {
        DMC_ID: data.DMC_ID, VS_ID: data.VS_ID, USER_EMAIL: data.USER_ID, SOLUTION: data.SOLUTION,
        BUY_PROCESS: data.BUY_PROCESS, INDUSTRY_LEVEL: data.INDUSTRY_LEVEL
      },
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      //dispatch(scopeSummaryBuyProcessSuccess(response))
      return response
    })
  }
}

//Program Enablement Summary
//Program Enablement Summary
export function getConcentrationAnalysis(data) {
  return (dispatch) => {
    dispatch(enablementLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: getConcentrationAnalysisAPI,
      data: {
        DMC_ID: data.DMC_ID, VS_ID: data.VS_ID, USER_EMAIL: data.User_Email, SOLUTION: data.Solution, BUY_PROCESS: data.BuyProcess,
        METRIC_TYPE: data.MetricType, MATCH_FILTER: data.MatchFilter
      },
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      return response
    })
  }
}

export function getMatchedSupplier(objEnablement) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: getMatchedSupplierAPI,
      data: {
        DMC_ID: objEnablement.DMC_ID, VS_ID: objEnablement.VS_ID, USER_EMAIL: objEnablement.User_Email, MATCH_FILTER: objEnablement.MatchFilter
      },
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      return response
    })
  }
}

export function getMatchedSummary(objEnablement) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: getMatchedSummaryAPI,
      data: {
        DMC_ID: objEnablement.DMC_ID, VS_ID: objEnablement.VS_ID, USER_EMAIL: objEnablement.User_Email, MATCH_FILTER: objEnablement.MatchFilter
      },
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      return response
    })
  }
}

export function getSupplierByReason(objEnablement) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: getSupplierByReasonAPI,
      data: {
        DMC_ID: objEnablement.DMC_ID, VS_ID: objEnablement.VS_ID, USER_EMAIL: objEnablement.User_Email
      },
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      return response
    })
  }
}

export function getBuyProcessSummary(data) {
  return (dispatch) => {
    dispatch(scopeSummaryLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: getBuyProcessSummaryAPI,
      data: {
        DMC_ID: data.DMC_ID, VS_ID: data.VS_ID, USER_EMAIL: data.USER_ID
      },
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      //dispatch(scopeSummarySuccess(response))
      return response
    })
  }
}

export function storeUserSpendSelection(VSId, DMCId, spendModule, spendSubModule, criteriaName, selectedValue, userEmail) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: { VSId: VSId, DMCId: DMCId, SpendModule: spendModule, SpendSubModule: spendSubModule, CriteriaName: criteriaName, UserPreference: selectedValue, Email: userEmail },
      url: storeUserSpendSelectionAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

//World Map
export function getHeatMapData(RequestFileId, ValueStoryId, UserId) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: { DMCID: RequestFileId, VSID: ValueStoryId, Email: UserId },
      url: getHeatMapDataAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function getWorldMapSuppliersDetails(data) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: getWorldMapSuppliersDetailsAPI,
      data: data,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      return response
    })
  }
}

//Spend Manager - Admin section under Menu
export function getEnablementResourceParameters(DMCId, VSId, userEmail) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: { DMC_ID: DMCId, VS_ID: VSId, USER_EMAIL: userEmail },
      url: getEnablementResourceParametersAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function updateEnablementResourceParameters(records, model) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: { rowData : records, model : model },
      url: updateEnablementResourceParametersAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function getEnablementResourceParametersAdmin(soltionInScope) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: {SolutionInScope:soltionInScope},
      url: getEnablementResourceParametersAdminAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function updateEnablementResourceParametersAdmin(records) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: records,
      url: updateEnablementResourceParametersAdminAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function getFlightPlanningDocParameters() {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: getFlightPlanningDocParametersAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function updateFlightPlanningDocParameters(records) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: records,
      url: updateFlightPlanningDocParametersAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function getFlightPlanningSpendParameters() {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: getFlightPlanningSpendParametersAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function updateFlightPlanningSpendParameters(records) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: records,
      url: updateFlightPlanningSpendParametersAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function getFlightPlanningTransactingBuyRelationship() {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: getFlightPlanningTransactingBuyRelationshipAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function updateFlightPlanningTransactingBuyRelationship(records) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: records,
      url: updateFlightPlanningTransactingBuyRelationshipAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function getFlightPlanningNetworkEnablementMatchTypeParameters() {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: getFlightPlanningNetworkEnablementMatchTypeParametersAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function updateFlightPlanningNetworkEnablementMatchTypeParameters(records) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: records,
      url: updateFlightPlanningNetworkEnablementMatchTypeParametersAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function getSupplierChoiceEnablementParametersDetails() {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: getSupplierChoiceEnablementParametersAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function getFlightPlanningRecommendationDetails() {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: getFlightPlanningRecommendationDetailsAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function updateFlightPlanningRecommendationDetails(records) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: records,
      url: updateFlightPlanningRecommendationDetailsAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function updateOnboardingworkstreamDetails(records) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: records,
      url: updateOnboardingworkstreamDetailsAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function getWaveWeightCategoryDetails() {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: getWaveWeightCategoryDetailsAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function updateWaveWeightCategoryDetails(records) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: records,
      url: updateWaveWeightCategoryDetailsAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function fetchEnablementPriorityAdmin(solutionInScope) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: {SolutionInScope : solutionInScope},
      url: fetchEnablementPriorityAdminAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      return response
    })
  }
}

export function UpdateEnablementPriorityAdminValues(data) {

  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: data,
      url: UpdateEnablementPriorityAdminValuesAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      return response
    })
  }
}


export function FetchUpdateFieldVisibility(data) {

  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: data,
      url: fetchUpdateFieldVisibilityAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      return response
    })
  }
}

export function fetchSupplyChainProcessAdmin() {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',      
      url: fetchSupplyChainProcessAdminAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      return response
    })
  }
}

export function UpdateSupplyChainProcessAdminValues(data) {

  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: data,
      url: UpdateSupplyChainProcessAdminAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      return response
    })
  }
}

export function fetchRampScheduleFactor() {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: fetchRampScheduleFactorAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      return response
    })
  }
}

export function updateRampScheduleFactor(data) {

  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: data,
      url: updateRampScheduleFactorAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      return response
    })
  }
}

export function fetchGoLiveVsKickOffWeekData() {

  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: fetchGoLiveVsKickOffWeekDataAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      return response
    })
  }
}

export function fetchStandardAccountYieldFactor() {

  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: fetchStandardAccountYieldFactorAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      return response
    })
  }
}

export function updateStandardAccountYield(data) {

  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: data,
      url: updateStandardAccountYieldAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      return response
    })
  }
}

export function updateGoLiveVsKickOffWeekData(data) {

  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: data,
      url: updateGoLiveVsKickOffWeekDataAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      return response
    })
  }
}

export function getAllGICS() {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    dispatch(getAllIndustriesGicsLoading(true))
    return axios({
      method: 'get',
      url: getAllGICSAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(getAllIndustriesGicsLoading(false))
      dispatch(getAllIndustriesGicsSuccess(true))
      return response
    }).catch((error) => {
      dispatch(getAllIndustriesGicsFailure(error))
      return error
    })
  }
}

export function updateGICS(records) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    dispatch(getUpdateGicsLoading(true))
    return axios({
      method: 'post',
      data: records,
      url: updateGICSAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(getUpdateGicsLoading(false))
      dispatch(getUpdateGicsSuccess(true))
      return response
    }).catch((error) => {
      dispatch(getUpdateGicsFailure(error))
      return error
    })
  }
}


export function getCountryDetails() {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    dispatch(getCountryDetailsLoading(true))
    return axios({
      method: 'get',
      url: getCountryDetailsAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      return response
    })
  }
}

export function updateCountryDetails(records) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    dispatch(updateCountryDetailsLoading(true))
    return axios({
      method: 'post',
      data: records,
      url: updateCountryDetailsAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(updateCountryDetailsLoading(false))
      dispatch(updateCountryDetailsSuccess(true))
      return response
    }).catch((error) => {
      dispatch(updateCountryDetailsFailure(error))
      return error
    })
  }
}

export function fetchL4IndustryValues() {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: fetchL4IndustryValuesAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      return response
    })
  }
}

export function uploadL4IndustryPriorityData(records) {
  return (dispatch) => {
    dispatch(getDetailedFileFileRecordsInsertedLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: records,
      url: uploadL4IndustryPriorityDataAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(getDetailedFileFileRecordsInsertedLoading(false))
      dispatch(getDetailedFileRecordsInsertedSuccess(true))
      return response
    }).catch((error) => {
      dispatch(getDetailedFileFileRecordsInsertedFailure(error))
      return error
    })
  }
}

export function getSpendParameters(userId) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: getSpendParametersAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      return response
    })
  }
}

export function updateSpendParameters(parameters) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: updateSpendParametersAPI,
      data: parameters,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      return response
    })
  }
}

export function defineScopeSuccess(message) {
  return {
    type: DEFINESCOPE_SUCCESS,
    payload: message
  }
}

export function defineScopeLoading(bool) {
  return {
    type: DEFINESCOPE_LOADING,
    payload: bool
  }
}

export function defineScopeFailure(error) {
  return {
    type: DEFINESCOPE_FAILURE,
    payload: error
  }
}

export function getDetailedFileSuccess(message) {
  return {
    type: GET_DETAILEDFILE_SUCCESS,
    payload: message
  }
}

export function getDetailedFileLoading(bool) {
  return {
    type: GET_DETAILEDFILE_LOADING,
    payload: bool
  }
}

export function addSelectedColumnFailure(error) {
  return {
    type: ADD_SELECTED_COLUMNS_FAILURE,
    payload: error
  }
}

export function addSelectedColumnSuccess(message) {
  return {
    type: ADD_SELECTED_COLUMNS_SUCCESS,
    payload: message
  }
}

export function addSelectedColumnLoading(bool) {
  return {
    type: ADD_SELECTED_COLUMNS_LOADING,
    payload: bool
  }
}

export function getSelectedColumnFailure(error) {
  return {
    type: GET_SELECTED_COLUMNS_FAILURE,
    payload: error
  }
}

export function getSelectedColumnSuccess(message) {
  return {
    type: GET_SELECTED_COLUMNS_SUCCESS,
    payload: message
  }
}

export function getSelectedColumnLoading(bool) {
  return {
    type: GET_SELECTED_COLUMNS_LOADING,
    payload: bool
  }
}

export function getDetailedFileFailure(error) {
  return {
    type: GET_DETAILEDFILE_FAILURE,
    payload: error
  }
}

export function addFavoriteFieldsLoading(bool) {
  return {
    type: ADD_FAVORITE_FIELD_LOADING,
    payload: bool
  }
}

export function addFavoriteFieldsSuccess(message) {
  return {
    type: ADD_FAVORITE_FIELD_SUCCESS,
    payload: message
  }
}

export function getFavoriteFieldsLoading(bool) {
  return {
    type: GET_FAVORITE_FIELD_LOADING,
    payload: bool
  }
}

export function getFavoriteFieldsSuccess(message) {
  return {
    type: GET_FAVORITE_FIELD_SUCCESS,
    payload: message
  }
}

export function editFavoriteFieldLoading(bool) {
  return {
    type: EDIT_FAVORITE_FIELD_LOADING,
    payload: bool
  }
}

export function editFavoriteFieldSuccess(message) {
  return {
    type: EDIT_FAVORITE_FIELD_SUCCESS,
    payload: message
  }
}

export function deleteFavoriteFieldLoading(bool) {
  return {
    type: DELETE_FAVORITE_FIELD_LOADING,
    payload: bool
  }
}

export function deleteFavoriteFieldSuccess(message) {
  return {
    type: DELETE_FAVORITE_FIELD_SUCCESS,
    payload: message
  }
}

export function removeColumnsLoading(bool) {
  return {
    type: REMOVE_COLUMNS_LOADING,
    payload: bool
  }
}

export function removeColumnsSuccess(message) {
  return {
    type: REMOVE_COLUMNS_SUCCESS,
    payload: message
  }
}


export function enablementInsightSuccess(message) {
  return {
    type: ENABLEMENTINSIGHT_SUCCESS,
    payload: message
  }
}

export function enablementInsightLoading(bool) {
  return {
    type: ENABLEMENTINSIGHT_LOADING,
    payload: bool
  }
}

export function enablementInsightFailure(error) {
  return {
    type: ENABLEMENTINSIGHT_FAILURE,
    payload: error
  }
}

export function getResultLoading(bool) {
  return {
    type: GET_RESULT_LOADING,
    payload: bool
  }
}

export function getResultSuccess(message) {
  return {
    type: GET_RESULT_SUCCESS,
    payload: message
  };
}

export function getResultFailure(error) {
  return {
    type: GET_RESULT_FAILURE,
    payload: error
  };
}

export function setPopUpTabLoadingStatus(bool) {
  return {
    type: SET_POPUP_TAB_LOADING_STATUS,
    payload: bool
  }
}

export function detailedFileFilters(filters) {
  return {
    type: 'Deatiled-Filters',
    payload: filters
  };
}

export function detailedFileFilterText(filter) {
  return {
    type: 'Deatiled-FilterText',
    payload: filter
  };
}

export function getAllIndustriesLoading(bool) {
  return {
    type: GET_ALLINDUSTRIES_LOADING,
    payload: bool
  }
}

export function getAllIndustriesSuccess(message) {
  return {
    type: GET_ALLINDUSTRIES_SUCCESS,
    payload: message
  };
}

export function getAllIndustriesFailure(error) {
  return {
    type: GET_ALLINDUSTRIES_FAILURE,
    payload: error
  };
}

export function getAllGICSLoading(bool) {
  return {
    type: GET_ALLINDUSTRIES_LOADING,
    payload: bool
  }
}

export function getAllIndustriesTreeStructure(message) {
  return {
    type: GET_ALLINDUSTRIESTREESTRUCTURE_SUCCESS,
    payload: message
  };
}

export function selectedIndustryDetails(message) {
  return {
    type: STORE_OVERRIDED_INDUSTRY,
    payload: message
  };
}

export function selectedEnablementWaveDetails(message) {
  return {
    type: STORE_ENABLEMENT_WAVE_VALUE,
    payload: message
  };
}

export function setSearchClearFlag(message) {
  return {
    type: SET_SEARCHCLEAR_FLAG,
    payload: message
  };
}

export function getDetailedFileRecordsInsertedSuccess(message) {
  return {
    type: GET_DETAILEDFILEROWINSERTED_SUCCESS,
    payload: message
  }
}

export function getDetailedFileFileRecordsInsertedLoading(bool) {
  return {
    type: GET_DETAILEDFILEROWINSERTED_LOADING,
    payload: bool
  }
}

export function getDetailedFileFileRecordsInsertedFailure(error) {
  return {
    type: GET_DETAILEDFILEROWINSERTED_FAILURE,
    payload: error
  }
}

export function pendingChanges(changes) {
  return {
    type: 'DeatiledFile-Changes',
    payload: changes
  };
}

export function saveKPIOnRedirect(changes) {
  return {
    type: 'SaveKPIOnRedirect-Changes',
    payload: changes
  };
}

export function saveSpendParametersOnRedirect(changes) {
  return {
    type: 'SaveSpendParametersOnRedirect-Changes',
    payload: changes
  };
}
export function isWaveRecalcOnSpendParamRedirect(changes) {
  return {
    type: 'isWaveRecalcOnSpendParamRedirect',
    payload: changes
  };
}

export function deleteIntermediateKPIOnRedirect(changes) {
  return {
    type: 'DeleteKPIOnRedirect-Changes',
    payload: changes
  };
}

export function scopeSummarySuccess(message) {
  return {
    type: SCOPESUMMARY_SUCCESS,
    payload: message
  }
}

export function scopeSummaryLoading(bool) {
  return {
    type: SCOPESUMMARY_LOADING,
    payload: bool
  }
}

export function scopeSummaryFailure(error) {
  return {
    type: SCOPESUMMARY_FAILURE,
    payload: error
  }
}

export function scopeSummaryBuyProcessSuccess(message) {
  return {
    type: SCOPESUMMARYBUYPROCESS_SUCCESS,
    payload: message
  }
}

export function scopeSummaryBuyProcessLoading(bool) {
  return {
    type: SCOPESUMMARYBUYPROCESS_LOADING,
    payload: bool
  }
}

export function scopeSummaryBuyProcessFailure(error) {
  return {
    type: SCOPESUMMARYBUYPROCESS_FAILURE,
    payload: error
  }
}

export function enablementSuccess(message) {
  return {
    type: ENABLEMENT_SUCCESS,
    payload: message
  }
}

export function enablementLoading(bool) {
  return {
    type: ENABLEMENT_LOADING,
    payload: bool
  }
}

export function enablementFailure(error) {
  return {
    type: ENABLEMENT_FAILURE,
    payload: error
  }
}

export function storeEnablementInsightSelectedFilter(message) {
  return {
    type: STORE_ENABLEMENTINSIGHT_FILTER,
    payload: message
  }
}

export function setYourFileRequestDetails(requestDetails) {
  return {
    type: GET_YOURFILE_REQUESTDETAILS,
    payload: requestDetails
  }
}

export function setValueStoryDmciDetails(valueStoryDMCIDRequests) {
  return {
    type: GET_YOURFILE_VALUESTORYREQUESTDETAILS,
    payload: valueStoryDMCIDRequests
  }
}

export function clearCurrentActiveRequest() {
  return {
    type: CLEAR_CURRENT_ACTIVE_REQUEST,
    payload: ''
  }
}

export function setMappedColumn(columnData) {
  return {
    type: GET_MAPPEDCOLUMN,
    payload: columnData
  }
}

export function setResultSummaryDetails(requestDetails) {
  return {
    type: GET_RESULTSUMMARY_DETAILS,
    payload: requestDetails
  }
}

export function setTopDetails(requestTopDetails) {
  return {
    type: GET_TOP_DETAILS,
    payload: requestTopDetails
  }
}

export function storeSupplierSKEY(message) {
  return {
    type: STORE_SUPPLIER_SKEY,
    payload: message
  };
}

export function storeVendorDetails(message) {
  return {
    type: STORE_VENDOR_DETAILS,
    payload: message
  };
}

export function storeFinalIndustryID(message) {
  return {
    type: STORE_INDUSTRY_ID,
    payload: message
  };
}

export function storeInputAndClassificationDetails(message) {
  return {
    type: STORE_INPUTANDCLASSIFICATION_DETAILS,
    payload: message
  };
}

export function getInputAndClassificationDataLoading(bool) {
  return {
    type: GET_INPUTANDCLASSIFICATION_LOADING,
    payload: bool
  }
}

export function getInputAndClassificationDataSuccess(message) {
  return {
    type: GET_INPUTANDCLASSIFICATION_SUCCESS,
    payload: message
  }
}

export function getInputAndClassificationDataFailure(error) {
  return {
    type: GET_INPUTANDCLASSIFICATION_FAILURE,
    payload: error
  }
}

export function getCapIQDataBlank(bool) {
  return {
    type: GET_CAPIQDATA_BLANK,
    payload: bool
  }
}

export function getSPCapitalIQLoading(bool) {
  return {
    type: GET_SPCAPITALIQ_LOADING,
    payload: bool
  }
}

export function getSPCapitalIQSuccess(message) {
  return {
    type: GET_SPCAPITALIQ_SUCCESS,
    payload: message
  }
}

export function getSPCapitalIQFailure(error) {
  return {
    type: GET_SPCAPITALIQ_FAILURE,
    payload: error
  }
}

export function getSPCapitalIQUltimateParentLoading(bool) {
  return {
    type: GET_SPCAPITALIQULTIMATEPARENT_LOADING,
    payload: bool
  }
}

export function getSPCapitalIQUltimateParentSuccess(message) {
  return {
    type: GET_SPCAPITALIQULTIMATEPARENT_SUCCESS,
    payload: message
  }
}

export function getSPCapitalIQUltimateParentFailure(error) {
  return {
    type: GET_SPCAPITALIQULTIMATEPARENT_FAILURE,
    payload: error
  }
}

export function getSupplierDrillScopeDataLoading(bool) {
  return {
    type: GET_SUPPLIERDRILLSCOPE_LOADING,
    payload: bool
  }
}

export function getSupplierDrillScopeDataSuccess(message) {
  return {
    type: GET_SUPPLIERDRILLSCOPE_SUCCESS,
    payload: message
  }
}

export function getSupplierDrillScopeDataFailure(error) {
  return {
    type: GET_SUPPLIERDRILLSCOPE_FAILURE,
    payload: error
  }
}

export function trackSupplierDrillDownChanges(message) {
  return {
    type: TRACK_SUPPLIERDRILLDOWN_PENDING_CHANGES,
    payload: message
  };
}

export function trackSupplierDrillDownSave(message) {
  return {
    type: TRACK_SUPPLIERDRILLDOWN_SAVE_CHANGES,
    payload: message
  };
}

export function getSupplierDrilldownUpdatedSuccess(message) {
  return {
    type: GET_SUPPLIERDRILLDOWNUPDATED_SUCCESS,
    payload: message
  }
}

export function getSupplierDrilldownUpdatedLoading(bool) {
  return {
    type: GET_SUPPLIERDRILLDOWNUPDATED_LOADING,
    payload: bool
  }
}

export function getSupplierDrilldownUpdatedFailure(error) {
  return {
    type: GET_SUPPLIERDRILLDOWNUPDATED_FAILURE,
    payload: error
  }
}

export function getIndustryDrillScopeDetailsLoading(bool) {
  return {
    type: GET_INDUSTRYDRILLSCOPE_LOADING,
    payload: bool
  }
}

export function getIndustryDrillScopeDetailsSuccess(message) {
  return {
    type: GET_INDUSTRYDRILLSCOPE_SUCCESS,
    payload: message
  }
}

export function getIndustryDrillScopeDetailsFailure(error) {
  return {
    type: GET_INDUSTRYDRILLSCOPE_FAILURE,
    payload: error
  }
}

export function storeIndustryGICS_ID(message) {
  return {
    type: STORE_GICS_ID,
    payload: message
  };
}

export function storeIndustryDetails(message) {
  return {
    type: STORE_INDUSTRY_DETAILS,
    payload: message
  };
}

export function getIndustrySpendSuccess(message) {
  return {
    type: GET_INDUSTRYSPEND_SUCCESS,
    payload: message
  }
}

export function getIndustrySpendLoading(bool) {
  return {
    type: GET_INDUSTRYSPEND_LOADING,
    payload: bool
  }
}

export function getIndustrySpendUpdatedSuccess(message) {
  return {
    type: GET_INDUSTRYSPEND_SUCCESS,
    payload: message
  }
}

export function getIndustrySpendUpdatedLoading(bool) {
  return {
    type: GET_INDUSTRYSPEND_LOADING,
    payload: bool
  }
}

export function getIndustrySpendUpdatedFailure(error) {
  return {
    type: GET_INDUSTRYSPEND_FAILURE,
    payload: error
  }
}

export function industryDrillScopeUpdateSuccess(message) {
  return {
    type: INDUSTRYDRILLSCOPEUPDATE_SUCCESS,
    payload: message
  }
}

export function industryDrillScopeUpdateLoading(bool) {
  return {
    type: INDUSTRYDRILLSCOPEUPDATE_LOADING,
    payload: bool
  }
}

export function industryDrillScopeUpdateFailure(error) {
  return {
    type: INDUSTRYDRILLSCOPEUPDATE_FAILURE,
    payload: error
  }
}

export function trackIndustryDrillScopeChanges(message) {
  return {
    type: TRACK_INDUSTRYDRILLSCOPE_PENDING_CHANGES,
    payload: message
  };
}

export function industrySupplierSuccess(message) {
  return {
    type: INDUSTRYSUPPLIER_SUCCESS,
    payload: message
  }
}

export function industrySupplierLoading(bool) {
  return {
    type: INDUSTRYSUPPLIER_LOADING,
    payload: bool
  }
}

export function industrySupplierFailure(error) {
  return {
    type: INDUSTRYSUPPLIER_FAILURE,
    payload: error
  }
}

export function getIndustryCapIQSuccess(message) {
  return {
    type: GET_INDUSTRYCAPIQ_SUCCESS,
    payload: message
  }
}

export function getIndustryCapIQLoading(bool) {
  return {
    type: GET_INDUSTRYCAPIQ_LOADING,
    payload: bool
  }
}

export function trackUserSpendSelections(message) {
  return {
    type: TRACK_USER_SPEND_SELECTIONS,
    payload: message
  };
}

export function selectedCapIqIdDetails(message) {
  return {
    type: STORE_OVERRIDED_CAPIQID,
    payload: message
  };
}

export function redirectFromEnablementTab(flag) {
  return {
    type: REDIRECT_FROM_ENABLEMENT_TAB,
    payload: flag
  }
}

export function redirectFromWizard(flag) {
  return {
    type: REDIRECT_FROM_WIZARD,
    payload: flag
  }
}

export function getAllIndustriesGicsLoading(bool) {
  return {
    type: GET_ALLINDUSTRIES_GICS_LOADING,
    payload: bool
  }
}

export function getAllIndustriesGicsSuccess(message) {
  return {
    type: GET_ALLINDUSTRIES_GICS_SUCCESS,
    payload: message
  };
}

export function getAllIndustriesGicsFailure(error) {
  return {
    type: GET_ALLINDUSTRIES_GICS_FAILURE,
    payload: error
  };
}


export function getUpdateGicsLoading(bool) {
  return {
    type: GET_UPDATE_GICS_LOADING,
    payload: bool
  }
}

export function getUpdateGicsSuccess(message) {
  return {
    type: GET_UPDATE_GICS_SUCCESS,
    payload: message
  };
}

export function getUpdateGicsFailure(error) {
  return {
    type: GET_UPDATE_GICS_FAILURE,
    payload: error
  };
}


export function getCountryDetailsLoading(bool) {
  return {
    type: GET_COUNTRYDETAILS_LOADING,
    payload: bool
  }
}

export function getCountryDetailsSuccess(message) {
  return {
    type: GET_COUNTRYDETAILS_SUCCESS,
    payload: message
  };
}

export function getCountryDetailsFailure(error) {
  return {
    type: GET_COUNTRYDETAILS_FAILURE,
    payload: error
  };
}

export function updateCountryDetailsLoading(bool) {
  return {
    type: UPDATE_COUNTRYDETAILS_LOADING,
    payload: bool
  }
}

export function updateCountryDetailsSuccess(message) {
  return {
    type: UPDATE_COUNTRYDETAILS_SUCCESS,
    payload: message
  };
}

export function updateCountryDetailsFailure(error) {
  return {
    type: UPDATE_COUNTRYDETAILS_FAILURE,
    payload: error
  };
}

export function userDetailsLoading(bool) {
  return {
    type: USER_DETAILS_LOADING,
    payload: bool
  }
}

export function userDetailsSuccess(response) {
  return {
    type: USER_DETAILS_SUCCESS,
    payload: response
  };
}

export function userDetailsFailure(error) {
  return {
    type: USER_DETAILS_FAILURE,
    payload: error
  };
}

//vendor files
export function getVendorFiles(DMCId, VSId, userEmail) {
  return (dispatch) => {
    dispatch(getDetailedFileLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: { DMCId: DMCId, VSId: VSId, Email: userEmail },
      url: getVendorFilesAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(getDetailedFileSuccess(false))
      return response
    }).catch((error) => {
      return error
    })
  }
}

//AccountParameters solution scope change

export function solutionScopeChanged(val) {
  return {
    type: 'SOLUTION_SCOPE_CHANGED',
    payload: val
  }
}

export function kpiModelChanged(val) {
  return {
    type: 'KPI_MODEL_CHANGED',
    payload: val
  }
}

//AV-877
export function getSpendFilesData(DMCId, VSId, userEmail) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: { DMCId: DMCId, VSId: VSId, Email: userEmail },
      url: getSpendFilesDataAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      return response
    }).catch((error) => {
      return error
    })
  }
}

export function fetchMarketPlaceEligibleValues() {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: fetchMarketPlaceEligibleAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      return response
    })
  }
}

export function updateMarketPlaceEligibleValues(records) {
  return (dispatch) => {
    dispatch(getDetailedFileFileRecordsInsertedLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: records,
      url: updateMarketPlaceEligibleValuesAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(getDetailedFileFileRecordsInsertedLoading(false))
      dispatch(getDetailedFileRecordsInsertedSuccess(true))
      return response
    }).catch((error) => {
      dispatch(getDetailedFileFileRecordsInsertedFailure(error))
      return error
    })
  }
}

//AV-880
export function getQualityAssessmentData() {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: getQualityAssessmentDataAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      return response
    }).catch((error) => {
      return error
    })
  }
}

export function updateQualityAssessmentData(data) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: data,
      url: updateQualityAssessmentDataAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      return response
    }).catch((error) => {
      return error
    })
  }
}


export function getSpendQualityAssessmentData() {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: getSpendQualityAssessmentDataAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      return response
    }).catch((error) => {
      return error
    })
  }
}

export function updateSpendQualityAssessmentData(data) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: data,
      url: updateSpendQualityAssessmentDataAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      return response
    }).catch((error) => {
      return error
    })
  }
}

export function fetchIndirectVisibleSolutionInScopeValues() {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: fetchVisibleSolutionInScopeValuesAPI,
      url: fetchIndirectVisibleSolutionInScopeValuesAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      return response
    })
  }
}

export function fetchSolutionInScopeValues() {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: fetchSolutionInScopeValuesAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      return response
    })
  }
}

export function fetchVisibleSolutionInScopeValues() {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: fetchVisibleSolutionInScopeValuesAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      return response
    })
  }
}

export function updateSolutionInScopeValues(data) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: data,
      url: updateSolutionInScopeValuesAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      return response
    }).catch((error) => {
      return error
    })
  }
}

export function fetchANIdBPIdDetails() {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: fetchANIdBPIdDetailsAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      return response
    })
  }
}

export function addANIDDetails(data) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: data,
      url: addANIDDetailsAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      return response
    }).catch((error) => {
      return error
    })
  }
}


export function uploadSpendCollectionData(records) {
  return (dispatch) => {
    dispatch(getDetailedFileFileRecordsInsertedLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: records,
      url: uploadSpendCollectionDataAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(getDetailedFileFileRecordsInsertedLoading(false))
      dispatch(getDetailedFileRecordsInsertedSuccess(true))
      return response
    }).catch((error) => {
      dispatch(getDetailedFileFileRecordsInsertedFailure(error))
      return error
    })
  }
}

export function fetchSpendCollectionColumnDetails() {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: fetchSpendCollectionColumnAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      return response
    })
  }
}

export function getCollectCustomerSpendDetails(records) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: records,
      url: getCollectCustomerSpendDetailsAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      return response
    })
  }
}

export function saveCollectCustomerSpend(records) {
  return (dispatch) => {
    dispatch(getDetailedFileFileRecordsInsertedLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: records,
      url: saveCollectCustomerSpendAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(getDetailedFileFileRecordsInsertedLoading(false))
      dispatch(getDetailedFileRecordsInsertedSuccess(true))
      return response
    }).catch((error) => {
      dispatch(getDetailedFileFileRecordsInsertedFailure(error))
      return error
    })
  }
}

export function isEnablementStrategyContainerPage(obj) {
  return {
    type: 'GET_IS_KPI_STRATEGY_PAGE',
    payload: obj
  };
}

export function isSpendParameterPage(obj) {
  return {
    type: 'GET_IS_SPEND_PARAMETER_PAGE',
    payload: obj
  };
}

export function updateEnablementStrategyCurrentFocus(VS_ID, DMC_ID, ENABLEMENT_STRATEGY_FOCUS) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: updateEnablementStrategyCurrentFocusAPI,
      data: { VS_ID: VS_ID, DMC_ID: DMC_ID, ENABLEMENT_STRATEGY_FOCUS: ENABLEMENT_STRATEGY_FOCUS },
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      return response
    }).catch((error) => {
      return error
    })
  }
}

export function insertReportLogDetails(DMC_ID, VS_ID, USER_ID, LOG_TYPE, FILE_NAME) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: insertUpldDownldRptLogAPI,
      data: { DMCId: DMC_ID, VSID: VS_ID, UserID: USER_ID, LogType: LOG_TYPE, FileName: FILE_NAME },
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      return response
    }).catch((error) => {
      return error
    })
  }
}


