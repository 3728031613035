import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import {
    storeVendorDetails,
    getIndustryCapIQ,
    storeIndustryGICS_ID,
    storeIndustryDetails,
    setPopUpTabLoadingStatus
} from 'actions/spendActions'

import { path } from 'Constants'

import { formatThousands, roundFix } from "../../utils/Utils";

class IndustryCapIQDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {
                OPERATING_MARGIN_EBIT: 0, DAYS_INVENTORY_OUTSTANDING: 0, DAYS_PAYABLE_OUTSTANDING: 0,
                CONVERSION_CASH_CYCLE: 0, DAYS_SALES_OUTSTANDING: 0, INVENTORY_TURNS: 0, UPDATE_TIMESTAMP: undefined
            },
            columns: [],
            aggregatedData: { INVOICE_COUNT: 0, INVOICE_SPEND: 0.0, PO_COUNT: 0, PO_SPEND: 0.0, UNIQUE_SUPPLIERS: 0 },
            industryData: {},
            selectedIndustryName: ''
        }

        this.loadData = this.loadData.bind(this)
    }

    componentDidMount() {
        this.loadData(this.props);
    }

    componentWillReceiveProps(nextProps) {
        if (
            nextProps.spendDetails.industryGICS_Details && nextProps.spendDetails.industryGICS_Details.SKEY !== (this.props.spendDetails.industryGICS_Details ? this.props.spendDetails.industryGICS_Details.SKEY : undefined)) {
            this.loadData(nextProps);
        }
    }

    loadData(nextProps) {
        let currencySymbol = this.props.spendDetails.currentActiveRequest !== undefined ?
            this.props.currencySymbols[this.props.spendDetails.currentActiveRequest.Currency.trim()] : ''
        this.props.setPopUpTabLoadingStatus(true);
        this.props.getIndustryCapIQ({
            DMCID: this.props.spendDetails.currentActiveRequest.DMC_ID,
            VSID: this.props.spendDetails.currentActiveRequest.VS_ID, EMAIL: this.props.user.user.Email,
            GICSID: nextProps.spendDetails.industryGICS_Details.SKEY,
            Solution: 'No Filter'
        }).then((response) => {
            this.props.setPopUpTabLoadingStatus(false)
            if (response.status !== undefined && response.status === 200) {
                this.setState({ data: response.data.Result[0], currencySymbol: currencySymbol });
            } else if (response.response && response.response.status == 400) {
                this.setState({ data: {}, currencySymbol: currencySymbol, isLoading: false, isError: false, message: 'No Result Found' })
            } else if (response && response.stack && response.stack.includes('Network Error')) {
                this.setState({ responseMsg: this.props.content.validation.networkError, isError: 'Yes', isLoading: false })
            } else {
                this.setState({ responseMsg: this.props.content.validation.error, isError: 'Yes' })
            }
        }).catch((error) => {
            this.props.setPopUpTabLoadingStatus(false)
            this.setState({ responseMsg: this.props.content.validation.error, isError: 'Yes' })
        })

    }

    render() {
        return (
            <div className="grid-x">
                <div className="medium-12">
                    <div className="grid-x grid-padding-x">
                        <div className="medium-12">
                            {(this.state.isLoading) ?
                                <img style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={path + `images/ajax-loader.gif`} alt="" />
                                : null
                            }
                            {(this.state.isError === 'Yes') ?
                                <div style={{ color: 'red', textAlign: 'center' }} className="cell pt5">{this.state.responseMsg}</div>
                                : <div style={{ color: 'green', textAlign: 'center' }} className="cell pt5">{this.state.responseMsg}</div>
                            }
                        </div>
                    </div>
                    <div className="grid-x grid-padding-x">
                        <div className="medium-12">
                            {this.props.industryCapIQLang.industryTitle}
                        </div>
                        <div className="medium-12 pt20">
                            <div className="grid-x">
                                <div className="medium-12 font-bold font14">{this.props.industryCapIQLang.l1Industry}: {this.props.spendDetails.industryDetails.subHeader[0].name}</div>
                                <div className="medium-12 font12">{this.state.data.L1_INDUSTRY_DESC_DETAIL}</div>
                            </div>
                        </div>
                        {Number.parseInt(this.props.spendDetails.industryGICS_Details.level) >= 4 ?
                            <div className="medium-12 pt20">
                                <div className="grid-x">
                                    <div className="medium-12 font-bold font14">{this.props.industryCapIQLang.l4Industry}: {this.props.spendDetails.industryDetails.subHeader[3].name}</div>
                                    <div className="medium-12 font12">{this.state.data.L4_INDUSTRY_DESC_DETAIL}</div>
                                </div>
                            </div> : ''}
                    </div>
                </div>
                <div className="medium-12 pt20">
                    <div className="grid-x grid-padding-x">
                        <div className="medium-12">
                            <div className="font-bold font14 pb10">{this.props.industryCapIQLang.industry}: {this.props.spendDetails.industryGICS_Details.industry}<span>*{this.state.data.UPDATE_TIMESTAMP ? <span>&nbsp;**</span> : ''}</span></div>
                            <div className="grid-x font-bold font14 align-left grey_background paddingAll5">
                                <div className="medium-4">
                                    {this.props.industryCapIQLang.operatingMargin}
                                </div>
                                <div className="medium-4">
                                    {this.props.industryCapIQLang.daysInventoryOutstanding}
                                </div>
                                <div className="medium-4">
                                    {this.props.industryCapIQLang.daysPayableOutstanding}
                                </div>
                            </div>
                            <div className="grid-x font12 pt10 align-left paddingAll5">
                                <div className="medium-4">
                                    {formatThousands(roundFix(this.state.data.OPERATING_MARGIN_EBIT, 2))}%
                                </div>
                                <div className="medium-4">
                                    {formatThousands(roundFix(this.state.data.DAYS_INVENTORY_OUTSTANDING, 2))}
                                </div>
                                <div className="medium-4">
                                    {formatThousands(roundFix(this.state.data.DAYS_PAYABLE_OUTSTANDING, 2))}
                                </div>
                            </div>
                            <div className="grid-x font14 font-bold align-left grey_background paddingAll5">
                                <div className="medium-4">
                                    {this.props.industryCapIQLang.conversionCashCycle}
                                </div>
                                <div className="medium-4">
                                    {this.props.industryCapIQLang.daysSalesOutstanding}
                                </div>
                                <div className="medium-4">
                                    {this.props.industryCapIQLang.inventoryTurns}
                                </div>
                            </div>
                            <div className="grid-x font12 pt10 align-left paddingAll5">
                                <div className="medium-4">
                                    {formatThousands(roundFix(this.state.data.CONVERSION_CASH_CYCLE, 2))}
                                </div>
                                <div className="medium-4">
                                    {formatThousands(roundFix(this.state.data.DAYS_SALES_OUTSTANDING, 2))}
                                </div>
                                <div className="medium-4">
                                    {formatThousands(roundFix(this.state.data.INVENTORY_TURNS, 2))}
                                </div>
                            </div>
                            <div className="grid-x font12 pt10 align-left paddingAll5 pt20">
                                <div className="medium-12 font12 pt10 align-left" style={{ fontStyle: 'italic' }}>
                                    *{this.props.industryCapIQLang.industryFooter}
                                </div>
                                {this.state.data.UPDATE_TIMESTAMP ? <div className="medium-12 font12 pt10 align-left" style={{ fontStyle: 'italic' }}>
                                    **{this.props.industryCapIQLang.dataLastRefreshedOn}{moment(this.state.data.UPDATE_TIMESTAMP).format("MM-DD-YYYY")}
                                </div> : ''}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        content: state.content,
        ui: state.content.ui,
        user: state.default,
        spendDetails: state.spendDetails,
        currencySymbols: state.content.CurrencySymbols,
        industryCapIQLang: state.content.spendAnalysis.industryDrilldown.industryCapIQ,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        storeIndustryGICS_ID: (GICS_ID) => dispatch(storeIndustryGICS_ID(GICS_ID)),
        storeVendorDetails: (vendorDetails) => dispatch(storeVendorDetails(vendorDetails)),
        getIndustryCapIQ: (params) => dispatch(getIndustryCapIQ(params)),
        storeIndustryDetails: (data) => dispatch(storeIndustryDetails(data)),
        setPopUpTabLoadingStatus: bool => dispatch(setPopUpTabLoadingStatus(bool)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(IndustryCapIQDetails); 