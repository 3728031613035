
import axios from 'axios'
import { env, ROOT_URL } from 'Constants'

//video manager
export const VIDEO_MANAGER_LOADING = 'VIDEO_MANAGER_LOADING';
export const VIDEO_MANAGER_SUCCESS = 'VIDEO_MANAGER_SUCCESS';
export const VIDEO_MANAGER_FAILURE = 'VIDEO_MANAGER_FAILURE';
export const RESET_ERROR = 'RESET_ERROR';

//videoRole manager
export const VIDEO_ROLE_MANAGER_LOADING = 'VIDEO_ROLE_MANAGER_LOADING';
export const VIDEO_ROLE_MANAGER_SUCCESS = 'VIDEO_ROLE_MANAGER_SUCCESS';
export const VIDEO_ROLE_MANAGER_FAILURE = 'VIDEO_ROLE_MANAGER_FAILURE';

export function resetError() {
  return {
    type: RESET_ERROR,
    payload: null
  };
}

export function videoManagerLoading(bool) {
  return {
    type: VIDEO_MANAGER_LOADING,
    isLoading: bool
  }
}

export function videoManagerSuccess(message) {
  return {
    type: VIDEO_MANAGER_SUCCESS,
    payload: message
  };
}

export function videoManagerFailure(error) {
  return {
    type: VIDEO_MANAGER_FAILURE,
    payload: error
  };
}

export function fetchScreens() {
  return (dispatch) => {
    setTimeout(() => { dispatch(videoManagerLoading(true)) }, 1500);
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: `${ROOT_URL}/RoleAndPermission/FetchScreens`,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function fetchScreenVideos() {
  return (dispatch) => {
    setTimeout(() => { dispatch(videoManagerLoading(true)) }, 1500);
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: `${ROOT_URL}/RoleAndPermission/FetchScreenVideos`,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function saveScreenVideo(formValues, file) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    dispatch(videoManagerLoading(true))
    const formData = new FormData();
    formData.append('File', file);    
    formData.append('FileName', JSON.stringify(formValues));     
    
    return axios({
      method: 'post',
      url: `${ROOT_URL}/RoleAndPermission/SaveScreenVideo`,
      data: formData,
      dataType: "JSON",
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function saveScreenVideoFile(file) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    dispatch(videoManagerLoading(true))
    const formData = new FormData();
    formData.append('File', file);    
    formData.append('FileName', file.name);    
    
    return axios({
      method: 'post',
      url: `${ROOT_URL}/RoleAndPermission/SaveVideoFile`,
      data: formData,
      dataType: "JSON",
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function deleteScreenVideo(formValues) {
  return (dispatch) => {
    dispatch(videoManagerLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: formValues,
      url: `${ROOT_URL}/RoleAndPermission/DeleteScreenVideo`,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function fetchScreenVideosForUser(user) {
  return (dispatch) => {
    setTimeout(() => { dispatch(videoManagerLoading(true)) }, 1500);
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: {id: user},
      url: `${ROOT_URL}/RoleAndPermission/FetchScreenVideosForUser`,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function getAllRoles() {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: `${ROOT_URL}/RoleAndPermission/GetAllRolesUnFiltered`,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function saveScreenVideosOrder(values) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: values,
      url: `${ROOT_URL}/RoleAndPermission/SaveScreenVideosOrder`,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}