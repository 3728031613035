
import React from 'react'
import PropTypes from 'prop-types'

// Utils
import moment from 'moment'
import classnames from 'classnames'
import ScrollArea from 'react-scrollbar'

const ValueStoryList = (props) =>
	<div className="valuestory-list" style={{marginTop : '0'}}>
		<div className="grid-x grid-padding-x pt10 pb5">
			<div className="medium-11 cell">
				<div className="grid-x grid-padding-x valuestory-headers">
					<div className="medium-4 cell">{props.content.valueStoryNameHeader}</div>
					<div className="medium-3 cell">{props.content.companyNameHeader}</div> 
					<div className="medium-5 cell">{props.content.dateHeader}</div>
				</div>
			</div>
		</div>
		<ScrollArea minScrollSize={40}
			speed={0.8} className="valueStory-scrollarea"
			contentClassName="content"
			horizontal={false}
		>
			{
				props.isanyCreatorStory === true ?
					(Array.from(props.valueStories).map((data, i) =>
						(localStorage.getItem('id') === data.Id) ?
							<div className="grid-x grid-padding-x" key={i} onClick={() => props.handleValueStoryClick(data.VSId)}
								onDoubleClick={() => props.openValueStory(data.VSId)}>
								<div className={
									classnames(
										//Remove below line once get approval to rollout sharing logic to partners as well
										'valuestory-item medium-11 cell',
										{ 'valuestory-item--selected': data.VSId === props.selectedValueStory }
									)
								}>
									<div className="grid-x grid-padding-x">
										<div className="medium-4 cell valuestory-item__title">{data.ValueStoryName}</div>
										<div className="medium-3 cell valuestory-item__date">{data.Organization}</div>
										<div className="medium-5 cell valuestory-item__date">{moment(data.ModifiedDate).format('ddd, Do MMM YYYY, h:mm:ss a')}</div>
									</div>
								</div>
								{
									props.enableShareValueStory ?
										<div className="medium-1 cell">
											<button key={i} className="originator__help" onClick={() => props.showModal('shareValueStory', data.VSId, false)}></button>
										</div> : null
								}
							</div>
							: null
					)) : null
			}			
		</ScrollArea>
	</div>
export default ValueStoryList

ValueStoryList.propTypes = {
	valueStories: PropTypes.array,
	selectedValueStory: PropTypes.number,
	handleValueStoryClick: PropTypes.func.isRequired,
	openValueStory: PropTypes.func.isRequired
}