import React from 'react'
import { connect } from 'react-redux'
import Button from 'UI/Button'
import {
    shareFileOnJumphost,
    shareFilesOnJumphostLoading,
    shareFilesOnJumphostSuccess,
    shareFilesOnJumphostFailure
} from 'actions/userActions'
import { BreakLine } from 'utils/Utils'

class ShareFilesOnJumphost extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedFile: null,
            fileError: '',
            successMsg: '',
            errorMsg: '',
        }
        this.fileTypeValidation = this.fileTypeValidation.bind(this)
    }

    fileTypeValidation(filePath) {
        let allowedExtensions = /(\.zip)$/i
        if (!allowedExtensions.exec(filePath)) {
            return false;
        } else return true
    }

    handleFileAttachment(e) {
        if (e.target.files.length > 0) {
            let file = e.target.files[0]
            var filePath = e.target.value;
            if (this.fileTypeValidation(filePath)) {
                let fileSize = file.size / 1024 / 1024; // in MB
                if (fileSize < 20) { //Max size to be uploaded
                    let reader = new FileReader()
                    reader.readAsDataURL(file)
                    reader.onload = (e) => {
                        var base64result = e.target.result.split(',')[1];
                        this.setState({ selectedFile: file, fileError: '', fileData: base64result, errorMsg: '', successMsg: '' })
                    }
                }
                else {
                    this.setState({ selectedFile: file, fileError: this.props.content.fileSizeErrorMsg, successMsg: '', errorMsg: '', fileData: undefined })
                }
            } else {
                this.setState({ selectedFile: file, fileError: this.props.content.fileTypeErrorMsg, successMsg: '', errorMsg: '', fileData: undefined })
            }
        }
    }

    validateAttachedFile() {
        if (this.state.fileData === undefined) {
            this.setState({ fileError: this.props.content.attachFile, successMsg: '', errorMsg: '', selectedFile: null })
            return false
        }
        else
            return true
    }

    handleSubmit() {
        if (this.validateAttachedFile()) {
            this.props.shareFilesOnJumphostLoading(true)
            const data = {
                fileDetails: {
                    fileData: this.state.fileData !== undefined ? this.state.fileData : '',
                    fileName: this.state.selectedFile !== null ? this.state.selectedFile.name : ''
                },
                createdBy: this.props.user.user.Id,
                language: this.props.language.toUpperCase(),
                userEmail: localStorage.getItem('loggedinUser')  //email
            }
            var content = this.props.content;
            this.props.shareFileOnJumphost(data)
                .then((result) => {
                    if (result.status === 200) {
                        this.props.shareFilesOnJumphostSuccess(false)
                        this.setState({ fileData: undefined, selectedFile: null, fileError: '', successMsg: this.props.content.fileUploadSuccessMsg, errorMsg: '' })
                    }
                    else {
                        this.props.shareFilesOnJumphostFailure(false)
                        this.setState({ errorMsg: this.props.content.errorMessage, successMsg: '', fileError: '' })
                    }

                }).catch((error) => {
                    this.props.shareFilesOnJumphostFailure(false)
                    this.setState({ errorMsg: this.props.content.errorMessage, successMsg: '', fileError: '' })
                })
        }
    }

    render() {
        return (
            <div>
                <div className="popup-content">
                    <div className="medium-12 title">{this.props.content.title}</div>
                    <div className="pt10">{BreakLine(this.props.content.description)}</div>
                    <div className="cell medium-7 grid-x pt10">
                        <label id="attachFile" className="button-upload pt10 chooseFileMargin">
                            {this.props.content.chooseFile}
                            <input
                                type="file"
                                id="File"
                                accept=".zip"
                                onChange={(e) => this.handleFileAttachment(e)}>
                            </input>
                        </label>
                        <div className="grid-x">
                            <div className="fileSelection">{this.state.selectedFile !== null ? this.state.selectedFile.name : ''}</div>
                        </div>
                        <div className="medium-2 marginL" >
                            <Button
                                disabled={this.props.user.loading}
                                content={this.props.content.submit}
                                onClick={this.handleSubmit.bind(this)}
                                color />
                        </div>
                    </div>
                </div>
                <div>
                    <div className="errors">{this.state.errorMsg}</div>
                    <div className="success">{this.state.successMsg}</div>
                    <div className="errors">{this.state.fileError}</div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        language: state.language,
        ui: state.content.ui,
        user: state.default,
        content: state.content.menu.Developer[0].ShareFiles
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        shareFileOnJumphost: (data) => dispatch(shareFileOnJumphost(data)),
        shareFilesOnJumphostLoading: (bool) => dispatch(shareFilesOnJumphostLoading(bool)),
        shareFilesOnJumphostSuccess: (message) => dispatch(shareFilesOnJumphostSuccess(message)),
        shareFilesOnJumphostFailure: (error) => dispatch(shareFilesOnJumphostFailure(error))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShareFilesOnJumphost)
