import React from 'react'
import PropTypes from 'prop-types'

// UI
import FaAngleRight from 'react-icons/lib/fa/angle-right'

// Utils
import classnames from 'classnames'
import { BreakLine } from 'utils/Utils'

const DiscoveryButton = (props) =>
	<div className="big-button" onClick={ () => { props.click !== undefined && props.click() } }>
		{ BreakLine(props.content) }
		<FaAngleRight className={
			classnames(
				'big-button__icon',
				{'big-button__icon-dropdown': props.dropdown },
				{'big-button__icon-dropdown--active': props.active }
			)}
		/>
		{ props.dropdown ? null : <FaAngleRight className="big-button__icon--hover" /> }
	</div>

export default DiscoveryButton

DiscoveryButton.defaultProps = {
	dropdown: false
}

DiscoveryButton.propTypes = {
	click: PropTypes.func,
	content: PropTypes.string.isRequired,
	dropdown: PropTypes.bool,
	active: PropTypes.bool
}
