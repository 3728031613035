import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Control, Form, Errors, actions } from 'react-redux-form'
import moment from 'moment'
// Actions
import {
	userManagerFailure,
	userManagerSuccess,
	fetchUserRoles,
	addUserRole,
	deleteUserRole,
	updateUserRole,	
	GetAllRoles,
	resetError
} from 'actions/roleAndPermissionAction'
 

// Utils
import { BreakLine } from 'utils/Utils'
// UI
import Textfield from 'react-mdl/lib/Textfield'
import Button from 'UI/Button'
// Settings
import { validators } from 'Constants'
import { SelectField, Option } from 'react-mdl-extra'
import Select from 'react-select';
import { dropdownStyles } from 'react-select-css'
import { Checkbox } from 'material-ui';
import FormControlLabel from '@material-ui/core/FormControlLabel';

class UserManagerContainer extends Component {
	constructor(props) {
		super(props)
		this.state = {
			userIsEditing: false,
			editId: 0,
			filterText: "",
			filterRole: "",
			users: [],
			roleState: [],
			selectedOption: '',
			isRoleSelected: '',
			rolesForUsers: [],
			selectedRolesIDForUsers:[]
		}
		this.handleRoleChange = this.handleRoleChange.bind(this)
		this.loadData = this.loadData.bind(this)
		 this.filterTextInput = React.createRef();
	}

	componentDidMount(){		
		
		this.loadData();
	}

	loadData() {
		this.getAllRoles();
		this.fetchUsersAndRoleDetails();
	}
	getAllRoles(){
		this.props.GetAllRoles().then((response)=>
		{
			this.setState({ rolesForUsers: response.data.Result});
		})
	}

	fetchUsersAndRoleDetails(){
			this.props.fetchUserRoles()
			.then((response) => { 
				this.setState({ users: response.data.Result })
				this.props.userManagerSuccess(response.data.Message)
			}).catch((error) => {
				if (error.stack.includes('Network Error')) {
					this.props.userManagerFailure(this.props.validation.networkError)
				}
				else {
					this.props.userManagerFailure(error.response.data.Message)
				}
			})
	}
	

	handleUserInput(filterText) {
	 
		
		var selectedUser = 	this.state.users.filter(us=>us.Email.toLowerCase().includes(filterText.target.value.toLowerCase()));
		if(selectedUser.length===1){
			this.handleRowEdit(selectedUser[0],null);
			filterText.target.value = selectedUser[0].Email;
		}
		else{
				this.setState({ editId: 0, userIsEditing: false });
				this.setState({selectedRolesIDForUsers: []	})
		}
		this.setState({ filterText: filterText.target.value.toLowerCase() });
	};

	onDDLRoleClickEvent = () => {
		this.setState({ isRoleSelected: 'Selected' });
	}

	onDDLRoleBlurEvent = () => {
		this.setState({ isRoleSelected: '' });
	}

	handleRowDel(userDetails) {
		const result = window.confirm(this.props.validation.confirmDelete);
		if (result) {
			var formValues = { Email: userDetails.Email, UserRole: userDetails.objRoles, User: this.props.user.user.Id  };
			this.props.deleteUserRole(formValues)
				.then((response) => {
					this.updateUserData(userDetails,response)
					this.props.userManagerSuccess(response.data.Message)
					
				}).catch((error) => {
					if (error.stack.includes('Network Error')) {
						this.props.userManagerFailure(this.props.validation.networkError)
					}
					else {
						this.props.userManagerFailure(error.response.data.Message)
					}
				})
		}
	};

	handleRowEdit(userDetails, userIsEditing) {
		 userDetails.isEditing = true;
		this.setState({ editId: userDetails.Id, userIsEditing: true });
		this.setState({selectedRolesIDForUsers: userDetails.objRoles.map((rol)=>{
				return rol.RoleId	
				}) })
		 
	   this.formDispatch(actions.change('userManager.Email', userDetails.Email)); 
	   this.formDispatch(actions.load('userManager.Email', userDetails.Email));      
	   this.formDispatch(actions.setErrors('userManager.Email', false)) 
	   this.formDispatch(actions.setTouched('userManager.Email', true)) 
	   
	}

	 

	handleRowCancel(userIsEditing) {
		 this.setState({ editId: 0 });
		 this.formDispatch(actions.change('userManager.Email', ''));
		 this.setState({selectedRolesIDForUsers: [] })
		 this.setState({ filterText: '' });
		 
	};

	handleRoleChange(value) {
		var role = value;
		this.setState({ filterRole: role });
		this.setState({ selectedOption: value });
		this.formDispatch(actions.setErrors('userManager.Role', false))
	}

	handleAddUserRole(formValues) {

		if(this.state.selectedRolesIDForUsers.length < 1){
			return this.props.userManagerFailure(this.props.validation.requiredRole)
		}
		
		this.props.resetError()
		var userRole = [];
		Object.values(this.state.selectedRolesIDForUsers).map((roleid)=>{
			userRole.push({RoleName : '' ,RoleId: roleid});
		})
		var formData = { Email: formValues.Email, UserRole: userRole, User: this.props.user.user.Id };
		 
		this.props.updateUserRole(formData)
			.then((response) => {
				 
				this.updateUserData(formValues,response)
				this.props.userManagerSuccess(response.data.Message)
				this.setState({selectedRolesIDForUsers: []});
				this.setState({ filterText: "",  filterRole: "" });
				this.formDispatch(actions.change('userManager.Email',''));
					
			}).catch((error) => {
				if (error.stack.includes('Network Error')) {
					this.props.userManagerFailure(this.props.validation.networkError)
				}
				else {
					this.props.userManagerFailure(error.response.data.Message)
				}
			})
			 
	}

	componentDidUpdate() {
		this.formDispatch(actions.change('userManager.Role', this.state.selectedOption));
	}

	componentWillUnmount() {
		this.formDispatch(actions.reset('userManager'))
		this.props.resetError()
	}

	updateUserData(userDetails,response){
		var oldStateObject  = this.state.users;
		userDetails =  oldStateObject.filter(a=>a.Email === userDetails.Email)
		var index = oldStateObject.indexOf(userDetails[0]);
		oldStateObject.splice(index, 1);
		oldStateObject.push(response.data.Result);
		oldStateObject.sort((itemA, itemB) => (itemA.Email.toLowerCase() > itemB.Email.toLowerCase()) ? 1 : (itemA.Email.toLowerCase() === itemB.Email.toLowerCase()) ? ((itemA.Email.toLowerCase() > itemB.Email.toLowerCase()) ? 1 : -1) : -1 )
		this.setState({users : oldStateObject});
	}



	handleFeatureCheckChanged(value){
		var id = value.target.id;
		if(this.state.selectedRolesIDForUsers.indexOf(id) < 0){
			this.setState(state => ({
      			selectedRolesIDForUsers: [...state.selectedRolesIDForUsers, id]}));
		}
		else{
			 this.setState({
				selectedRolesIDForUsers: this.state.selectedRolesIDForUsers.filter((item) => item !== id)				
			});
		}
		
	}

	render() {

		var getRoleComponent = (instance) => {
			var row = Object.values(instance.state.rolesForUsers).map((role) => {
				var check = (instance.state.selectedRolesIDForUsers.indexOf(role.RoleId) > -1)
				//if(instance.state.selectedRolesIDForUsers.indexOf(5) && role.RoleName == 'Delivery Internal User') 
				var domain = instance.state.editId == '0' ? '' : instance.state.users.filter(x => x.Id == instance.state.editId)[0].Email;
				var isInternalUser = instance.state.selectedRolesIDForUsers.indexOf("5") > -1;
				var isPartnerUser = instance.state.selectedRolesIDForUsers.indexOf("6") > -1;
				//if((isPartnerUser && !role.RoleName == "Delivery Internal User") || (isInternalUser && !role.RoleName == "Delivery Partner User"))
				if (isPartnerUser && role.RoleName == "Delivery Internal User")
					return null;
				if (isInternalUser && role.RoleName == "Delivery Partner User")
					return null;
				else
					return (
						<div className="grid-x chkContainer">
							<div className="checkBox">
								<Checkbox id={role.RoleId} checked={check} onClick={(e) => instance.handleFeatureCheckChanged(e)}
									name={role.RoleId} value={role.RoleName} />
							</div>
							<div>
								<p id={role.RoleId} onClick={(e) => instance.handleFeatureCheckChanged(e)} defaultValue={role.RoleId} >{role.RoleName}</p>
							</div>

						</div>
					)
			})
			return row;
		}

		return (
			<div>
				<div>
					<div className="grid-x">
						<div className="cell"><h2>{BreakLine(this.props.content.title)}</h2></div>
						<div>
							<Form
								model="userManager"
								getDispatch={(dispatch) => this.formDispatch = dispatch}
								onSubmit={(values) => this.handleAddUserRole(values)}
							 
							>
								<div className="grid-x grid-padding-x">
									<SearchBar filterText={this.filterTextInput} validation={this.props.validation} onUserInput={this.handleUserInput.bind(this)} />
									<div className="medium-7 cell">
										<span>{this.props.content.rolesLabel}</span>
										<div className="grid-x listBox">
											{getRoleComponent(this)}
										</div> 
									</div>
									<div className="medium-2 cell buttonPostion">
										<Button
											type="submit"
											disabled={this.props.userManager.isLoading}
											content={this.props.content.add}
											color
										/>
									</div>
								</div>
							</Form>
							<div className="errors">{this.props.userManager.error}</div>
							<div className="success">{this.props.userManager.message}</div>
						</div>
					</div>
				</div>
				<div>
				{ this.state.users !== undefined ?
					<UserTable
						onRowDel={this.handleRowDel.bind(this)}
						onRowEdit={this.handleRowEdit.bind(this)}
					  
						onRowCancel={this.handleRowCancel.bind(this)}
						userEditing={this.state.userIsEditing}
						editId={this.state.editId}
						users={this.state.users}
						roles={this.props.content.roles}
						filterText={this.state.filterText}
						filterRole={this.state.filterRole}
						allRoles={this.state.rolesForUsers}
				 /> : null}
				</div>
				
			</div>
		)
	}
}

 

class SearchBar extends React.Component {
	handleChange(event) {
		this.props.onUserInput(event);
	}
	render() {
		return (
			<div className="medium-5 cell">
				<Control.text
					model="userManager.Email"
					id="userManager.Email"
					component={Textfield}
					label={'Email'}
					floatingLabel					 
					ref={this.props.filterText}
					onChange={this.handleChange.bind(this)}
					validators={{
						validEmail:validators.validEmail
					}}
					//autoFocus 
					validateOn="blur"
					 
				/>
				<Errors
					className="errors"
					model="userManager.Email"
					show="touched"
					messages={{
						validEmail: this.props.validation.validEmail		
					}}
				/>
			</div>
		);
	}
}

class UserTable extends React.Component {
	render() {
		var rowDel = this.props.onRowDel;
		var rowEdit = this.props.onRowEdit;
		 
		var rowCancel = this.props.onRowCancel;
		var filterText = this.props.filterText;
		var filterRole = this.props.filterRole;
		var userEditing = this.props.userEditing;
		var editId = this.props.editId;
		var roles = this.props.roles;
		var instance = this;
		var user = this.props.users.map(function (user) {
			  if (user.Email.toLowerCase().indexOf(filterText) === -1) {
				return;
			}
			else return (<UserRow
					user={user}
					roles={roles}
					onDelEvent={rowDel.bind(this)}
					onEditEvent={rowEdit.bind(this)}
					onCancelEvent={rowCancel.bind(this)}
					userIsEditing={userEditing}
					editId={editId}
					key={user.id}
					allRoles={instance.props.allRoles} 
					 />			
						);
			});
		return (
			<div className="basic-table">
				<table>
					<thead>
						<tr>
							<th>Email</th>
							<th>First Name</th>
							<th>Last Name</th>
							<th>Role</th>
							<th>User Type</th>
							<th>Updated By</th>
							<th>Updated On</th>
							<th></th>
							<th></th>
						</tr>
					</thead>

					<tbody>
						{user}
					</tbody>
				</table>
			</div>
		);
	}
}

class UserRow extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			role: this.props.user.role,
			originalRole: this.props.user.role,
			roles: this.props.roles
		};
		this.onChange = this.onChange.bind(this)
		this.onChangeCancel = this.onChangeCancel.bind(this)
		this.onCancelEvent = this.onCancelEvent.bind(this)
	}

	onChange(e) {
		this.setState({
			role: e.target.value
		})
	}
	onChangeCancel(e) {
		this.setState({
			role: this.state.originalRole
		})
		this.props.onCancelEvent()
	}

	onDelEvent() {
		this.props.onDelEvent(this.props.user);
	}

	onEditEvent() {
		this.props.user.isEditing = true;
		
		this.props.onEditEvent(this.props.user, this.props.userIsEditing);
	}

	onSaveEvent() {
		this.setState({
			originalRole: this.state.role
		})
		this.props.onSaveEvent(this.props.user, this.props.userIsEditing, this.state.role);
	}

	onCancelEvent() {
		this.props.user.isEditing = false; 
	}
	showDeleteLink(){
		if((this.props.user.objRoles.length === 1 && this.props.allRoles.indexOf(this.props.user.objRoles[0].RoleName) === -1) || this.props.user.Customer){
            return false;
        }
        else{
            return true;
        }

	}
	render() {
		return (
			
			<tr className="eachRow">
			
				<td width="10%">{this.props.user.Email}</td>
				<td width="10%">{this.props.user.FirstName 	}</td>
				<td width="10%">{this.props.user.LastName 	}</td>
				<td width="30%">
					{ 
						Object.values(this.props.user.objRoles).map((rol,index)=>
						{

							if(index=== (this.props.user.objRoles.length-1)){
								return rol.RoleName
							}
							else{ return rol.RoleName+", "}
						})
					}
				</td>
				<td width="10%">{this.props.user.UserType}</td>
				<td width="10%">{this.props.user.UpdatedBy}</td>
				<td width="15%">{this.props.user.UpdatedOn !== null ? moment(this.props.user.UpdatedOn).format('L') : null}</td>
				
				
				<td width="10%">		
				{ 
					this.showDeleteLink() ?  <button onClick={this.onDelEvent.bind(this)}>Delete	</button> 
					: null
				}
				 
				</td>
				{
					!this.props.user.Customer? this.props.user.isEditing && this.props.editId === this.props.user.Id ?
						(
							<td width="5%">
								<button onClick={this.onChangeCancel}> Cancel </button> 
							</td>
						)
						:
						(
							<td width="5%">
								<button onClick={this.onEditEvent.bind(this)}> Edit </button> 
							</td>
						) : <td width="5%"></td>
				}
			
			</tr>  
		);
	}
}

const mapStateToProps = (state) => {
	return {
		language: state.language,
		ui: state.content.ui,
		user: state.default,
		content: state.content.userManager,
		validation: state.content.validation,
		userManager : state.userManagerUpdates
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		userManagerSuccess: (bool) => dispatch(userManagerSuccess(bool)),
		userManagerFailure: (error) => dispatch(userManagerFailure(error)),
		fetchUserRoles: (formValues) => dispatch(fetchUserRoles(formValues)),
		addUserRole: (formValues) => dispatch(addUserRole(formValues)),
		deleteUserRole: (formValues) => dispatch(deleteUserRole(formValues)),
		updateUserRole: (formValues) => dispatch(updateUserRole(formValues)),
		resetError: () => dispatch(resetError()),
		GetAllRoles : () => dispatch(GetAllRoles())
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(UserManagerContainer)
