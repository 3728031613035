import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Control, Form, Errors, actions } from 'react-redux-form'
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "./CustomerAccessManager.scss";
import moment from 'moment'
// Actions
import {
	userManagerFailure,
	userManagerSuccess,
	fetchCustomerRoles,
	addUserRole,
	deleteCustomerRole,
	addCustomerRole,
	GetAllRoles,
	resetError
} from 'actions/roleAndPermissionAction'


// Utils
import { BreakLine } from 'utils/Utils'
// UI
import Textfield from 'react-mdl/lib/Textfield'
import Button from 'UI/Button'
// Settings
import { validators } from 'Constants'
import { SelectField, Option } from 'react-mdl-extra'
import Select from 'react-select';
import { dropdownStyles } from 'react-select-css'
import { Checkbox } from 'material-ui';
import FormControlLabel from '@material-ui/core/FormControlLabel';

class CustomerAccessManager extends Component {
	constructor(props) {
		super(props)
		this.state = {
			userIsEditing: false,
			editId: null,
			filterText: "",
			filterRole: "",
			users: [],
			roleState: [],
			selectedOption: '',
			isRoleSelected: '',
			rolesForUsers: [],
			customerEmail: "",
			selectedRolesIDForUsers: [],
			startDate: new Date(),
			expirationDate: moment(new Date()).add(30, 'days').toDate(),
			dateValidationError: "",
			company: ""
		}
		this.handleRoleChange = this.handleRoleChange.bind(this)
		this.loadData = this.loadData.bind(this)
		this.filterTextInput = React.createRef();
	}

	componentDidMount() {
		this.loadData();
	}

	loadData() {
		this.getAllRoles();
		this.fetchCustomerAccessDetails();
	}
	getAllRoles() {
		this.props.GetAllRoles().then((response) => {
			this.setState({ rolesForUsers: response.data.Result });
		})
	}

	fetchCustomerAccessDetails() {
		this.props.fetchCustomerRoles()
			.then((response) => {
				this.setState({ users: response.data.Result })
				this.props.userManagerSuccess(response.data.Message)
			}).catch((error) => {
				if (error.stack.includes('Network Error')) {
					this.props.userManagerFailure(this.props.validation.networkError)
				}
				else {
					this.props.userManagerFailure(error.response.data.Message)
				}
			})
	}


	handleUserInput(filterText) {
		var selectedUser = this.state.users.filter(us =>
			us.CustomerEmail.toLowerCase().includes(filterText.target.value.toLowerCase())
		);
		this.setState({ customerEmail: filterText.target.value.toLowerCase(), filterText: filterText.target.value.toLowerCase() });
	};

	onDDLRoleClickEvent = () => {
		this.setState({ isRoleSelected: 'Selected' });
	}

	onDDLRoleBlurEvent = () => {
		this.setState({ isRoleSelected: '' });
	}

	handleRowDel(userDetails) {
		const result = window.confirm(this.props.validation.confirmDelete);
		if (result) {
			var payload = { CustomerEmail: userDetails.CustomerEmail, UpdatedBy: this.props.user.user.Id };
			this.props.deleteCustomerRole(payload)
				.then((response) => {
					this.props.userManagerSuccess(response.data.Message)
					this.fetchCustomerAccessDetails();
				}).catch((error) => {
					if (error.stack.includes('Network Error')) {
						this.props.userManagerFailure(this.props.validation.networkError)
					}
					else {
						this.props.userManagerFailure(error.response.data.Message)
					}
				})
		}
	};

	handleRowEdit(userDetails) {
		this.setState({ editId: userDetails.ID });
		this.setState({
			selectedRolesIDForUsers: userDetails.customerRoles.map((rol) => {
				return rol.roleId
			}), customerEmail: userDetails.CustomerEmail, company: userDetails.CompanyName
		})
		this.formDispatch(actions.load('userManager.Email', userDetails.CustomerEmail));
		this.formDispatch(actions.change('userManager.Email', userDetails.CustomerEmail));
		this.formDispatch(actions.setErrors('userManager.Email', false))
		this.formDispatch(actions.setTouched('userManager.Email', true))
		this.formDispatch(actions.load('userManager.company', userDetails.CompanyName));

		this.setState({
			startDate: moment(userDetails.StartDate).toDate(),
			expirationDate: moment(userDetails.ExpirationDate).toDate()
		})
	}



	handleRowCancel(userIsEditing) {
		this.setState({ editId: null });
		this.formDispatch(actions.load('userManager.Email', null));
		this.formDispatch(actions.setErrors('userManager.Email', false))
		this.formDispatch(actions.setTouched('userManager.Email', false))
		this.formDispatch(actions.load('userManager.company', null));

		this.setState({
			startDate: new Date(),
			expirationDate: moment(new Date()).add(30, 'days').toDate()
		})
		this.setState({ filterText: "" });
		this.setState({ selectedRolesIDForUsers: [] });
	};

	handleRoleChange(value) {
		var role = value;
		this.setState({ filterRole: role });
		this.setState({ selectedOption: value });
		this.formDispatch(actions.setErrors('userManager.Role', false))
	}

	handleAddUserRole(formValues) {
		var msg = this.props.content

		let roles = [];
		this.state.selectedRolesIDForUsers.map(roleId => {
			roles.push({ "RoleId": roleId });
		})

		this.props.resetError()

		var payload = {
			CustomerEmail: formValues.Email,
			CurrentLoginUser: this.props.user.user.Id,
			CompanyName: formValues.company,
			CustomerRole: roles,
			StartDate: this.state.startDate,
			ExpirationDate: this.state.expirationDate
		};

		this.props.addCustomerRole(payload)
			.then((response) => {

				this.handleRowCancel();
				this.fetchCustomerAccessDetails();
			}).catch((error) => {
				if (error.stack.includes('Network Error')) {
					this.props.userManagerFailure(this.props.validation.networkError)
				}
				else {
					this.props.userManagerFailure(error.response.data.Message)
				}
			})

	}

	componentDidUpdate() {
		this.formDispatch(actions.change('userManager.Role', this.state.selectedOption));
	}

	componentWillUnmount() {
		this.formDispatch(actions.reset('userManager'))
		this.props.resetError()
	}

	updateUserData(userDetails, response) {
		var oldStateObject = this.state.users;
		userDetails = oldStateObject.filter(a => a.Email === userDetails.Email)
		var index = oldStateObject.indexOf(userDetails[0]);
		oldStateObject.splice(index, 1);
		oldStateObject.push(response.data.Result);
		oldStateObject.sort((itemA, itemB) => (itemA.Email.toLowerCase() > itemB.Email.toLowerCase()) ? 1 : (itemA.Email.toLowerCase() === itemB.Email.toLowerCase()) ? ((itemA.Email.toLowerCase() > itemB.Email.toLowerCase()) ? 1 : -1) : -1)
		this.setState({ users: oldStateObject });
	}



	handleFeatureCheckChanged(value) {
		var id = value.target.id;
		if (this.state.selectedRolesIDForUsers.indexOf(id) < 0) {
			this.setState(state => ({
				selectedRolesIDForUsers: [...state.selectedRolesIDForUsers, id]
			}));
		}
		else {
			this.setState({
				selectedRolesIDForUsers: this.state.selectedRolesIDForUsers.filter((item) => item !== id)
			});
		}
	}

	startDateOnChange(date) {
		if (date != null) {
			if (this.state.expirationDate != null) {
				if (moment(date).isBefore(moment(this.state.expirationDate))) {
					this.setState({ dateValidationError: null, startDate: date });
				} else {
					this.setState({ dateValidationError: "Start Date should be less than Expiration Date" });
				}
			} else {
				this.setState({ dateValidationError: null, startDate: date });
			}
		}
	}

	expirationDateOnChange(date) {
		this.resetExpirationDate()
		this.setState({ expirationDate: date });
	}

	resetExpirationDate() {
		//this.formDispatch(actions.reset('valueStoryShare.ExpirationDate'));
		this.formDispatch(actions.setErrors('valueStoryShare.ExpirationDate', {
			required: false
		}))
	}

	companyOnChange(event) {
		this.setState({ company: event.target.value });
	}

	isDisabled() {
		return !(this.state.customerEmail != "" && this.state.company != "" && this.state.startDate != null && this.state.expirationDate != null);
	}

	render() {
		const required = (val) => val && val.length;
		var getRoleComponent = (instance) => {
			var row = Object.values(instance.state.rolesForUsers).map((role) => {
				var check = (instance.state.selectedRolesIDForUsers.indexOf(role.RoleId) > -1)
				if (role.RoleName == "Delivery Partner User" || role.RoleName == "Delivery Internal User")
					return null;
				else
				return (
					<div className="grid-x chkContainer">
						<div className="checkBox">
							<Checkbox id={role.RoleId} checked={check} onClick={(e) => instance.handleFeatureCheckChanged(e)}
								name={role.RoleId} value={role.RoleName} />
						</div>
						<div>
							<p id={role.RoleId} onClick={(e) => instance.handleFeatureCheckChanged(e)} defaultValue={role.RoleId} >{role.RoleName}</p>
						</div>

					</div>
				)
			})
			return row;
		}
		return (
			<div className="customerAccessManager">
				<div>
					<div className="grid-x">
						<div className="cell"><h2>{BreakLine(this.props.content.title)}</h2></div>
						<div>
							<Form
								model="userManager"
								getDispatch={(dispatch) => this.formDispatch = dispatch}
								onSubmit={(values) => this.handleAddUserRole(values)}
							>
								<div className="grid-x grid-padding-x">
									<div className="medium-6">
										<div>
											<SearchBar filterText={this.filterTextInput} validation={this.props.validation} onUserInput={this.handleUserInput.bind(this)} isEdit={this.state.editId !== null ? true:false} />
										</div>
										<div className="pt10">
											<Control.text
												model="userManager.company"
												id="userManager.company"
												component={Textfield}
												label={this.props.content.company}
												floatingLabel
												validators={{ required: validators.required }}
												onChange={(event) => this.companyOnChange(event)}
											/>
										</div>
										<div className="pt20">
											<div className="grid-x grid-padding-x">
												<div className="medium-6 cell">
													{<DatePicker selected={this.state.startDate}
														placeholderText={this.props.startDateLabel}
														className="calendar-text" popperClassName="calendar"
														onChange={date => this.startDateOnChange(date)} />}
												</div>
												<div className="medium-6 cell">
													{/* {<DatePicker selected={this.state.expirationDate}
														placeholderText={this.props.content.expirationDateLabel} minDate={this.state.startDate}
														className="calendar-text" popperClassName="calendar"
														onChange={date => this.expirationDateOnChange(date)} />} */}

                                                        <Control.custom
															model="valueStoryShare.ExpirationDate"
															id="valueStoryShare.ExpirationDate"
															component={DatePicker}
															validators={{
																required: (val) => this.state.expirationDate ? true : false
															}}
															controlProps={{
																style: { width: '300px' },
																selected: this.state.expirationDate,
																minDate: this.state.startDate,
																className: "calendar-text",
																popperClassName: "calendar-popper",
																//disabled: !this.state.shareWithCustomer,
																maxDate: moment(new Date()).add(30, 'days').toDate(),
																onChange: date => this.expirationDateOnChange(date)
															}}
														/>
														<Errors
															className="errors"
															model="valueStoryShare.ExpirationDate"
															show="touched"
															messages={{
																required: this.props.validation.required
															}}
														/>
												</div>
											</div>
											<div className="date-errors">
												{this.state.dateValidationError}
											</div>
										</div>
										<div className="pt30">
											<button type="submit" className="button button--accent" disabled={this.isDisabled()}>
												<span className="button__inner">
													{this.state.editId === null ? this.props.content.add : this.props.content.update}
												</span>
											</button>
										</div>
									</div>
									<div className="medium-6">
										<span>{this.props.content.rolesLabel}</span>
										<div className="grid-x listBox">
											{getRoleComponent(this)}
										</div>
									</div>

								</div>
							</Form>
							<div className="errors">{this.props.userManager.error}</div>
							<div className="success">{this.props.userManager.message}</div>
						</div>
					</div>
				</div>
				<div>
					{this.state.users !== undefined ?
						<UserTable
							onRowDel={this.handleRowDel.bind(this)}
							onRowEdit={this.handleRowEdit.bind(this)}
							editBtnLabel={this.props.content.edit}
							deleteBtnLabel={this.props.content.delete}
							cancelBtnLabel={this.props.content.cancel}
							onRowCancel={this.handleRowCancel.bind(this)}
							userEditing={this.state.userIsEditing}
							editId={this.state.editId}
							users={this.state.users}
							roles={this.props.content.roles}
							filterText={this.state.filterText}
							filterRole={this.state.filterRole}
							allRoles={this.state.rolesForUsers}
						/> : null}
				</div>

			</div>
		)
	}
}



class SearchBar extends React.Component {
	handleChange(event) {
		this.props.onUserInput(event);
	}
	render() {
		return (
			<div className="medium-5 cell">
				<Control.text
					model="userManager.Email"
					id="userManager.Email"
					component={Textfield}
					label={'Email'}
					floatingLabel
					ref={this.props.filterText}
					onChange={this.handleChange.bind(this)}
					validators={{
						validEmail: validators.validEmail,
						required: validators.required,
						customerValidation: (val) => val !=='' && val !== null && !val.endsWith('sap.com')
					}}
					validateOn="blur"
					readOnly={this.props.isEdit ? true : false}
				/>
				<Errors
					className="errors"
					model="userManager.Email"
					show="touched"
					messages={{
						validEmail: this.props.validation.validEmail,
						required: this.props.validation.required,
						customerValidation: 'Email address contains "@sap"'
					}}
				/>
			</div>
		);
	}
}

class UserTable extends React.Component {
	render() {
		var rowDel = this.props.onRowDel;
		var rowEdit = this.props.onRowEdit;

		var rowCancel = this.props.onRowCancel;
		var filterText = this.props.filterText;
		var filterRole = this.props.filterRole;
		var userEditing = this.props.userEditing;
		var editId = this.props.editId;
		var roles = this.props.roles;
		var instance = this;
		var editBtnLabel = this.props.editBtnLabel;
		var deleteBtnLabel = this.props.deleteBtnLabel;
		var cancelBtnLabel = this.props.cancelBtnLabel;
		var user = this.props.users.map(function (user) {
			if (user.CustomerEmail.toLowerCase().indexOf(filterText) === -1) {
				return;
			}
			else return (<UserRow
				user={user} editBtnLabel={editBtnLabel} deleteBtnLabel={deleteBtnLabel} cancelBtnLabel={cancelBtnLabel}
				roles={roles}
				onDelEvent={rowDel.bind(this)}
				onEditEvent={rowEdit.bind(this)}
				onCancelEvent={rowCancel.bind(this)}
				userIsEditing={userEditing}
				editId={editId}
				key={user.id}
				allRoles={instance.props.allRoles}
			/>
			);
		});
		return (
			<div className="basic-table">
				<table>
					<thead>
						<tr>
							<th>Email</th>
							<th>Role</th>
							<th>Company</th>
							<th>Start Date</th>
							<th>Expiration Date</th>
							<th>Updated By</th>
							<th>Updated On</th>
							<th></th>
							<th></th>
						</tr>
					</thead>

					<tbody>
						{user}
					</tbody>
				</table>
			</div>
		);
	}
}

class UserRow extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			role: this.props.user.role,
			originalRole: this.props.user.role,
			roles: this.props.roles
		};
		this.onChange = this.onChange.bind(this)
		this.onChangeCancel = this.onChangeCancel.bind(this)
		this.onCancelEvent = this.onCancelEvent.bind(this)
	}

	onChange(e) {
		this.setState({
			role: e.target.value
		})
	}
	onChangeCancel(e) {
		this.setState({
			role: this.state.originalRole
		})
		this.props.onCancelEvent()
	}

	onDelEvent() {
		this.props.onDelEvent(this.props.user);
	}

	onEditEvent() {
		this.props.user.isEditing = true;

		this.props.onEditEvent(this.props.user, this.props.userIsEditing);
	}

	onSaveEvent() {
		this.setState({
			originalRole: this.state.role
		})
		this.props.onSaveEvent(this.props.user, this.props.userIsEditing, this.state.role);
	}

	onCancelEvent() {
		this.props.onCancelEvent();
	}
	showDeleteLink() {
		if (this.props.user.objRoles.length === 1 && this.props.allRoles.indexOf(this.props.user.objRoles[0].RoleName) === -1) {
			return false;
		}
		else {
			return true;
		}

	}
	render() {
		let roles = "";
		this.props.user.customerRoles.map((element, index) => {
			roles = roles + (index > 0 ? ", " : "") + element.roleName;
		});

		return (

			<tr className="eachRow">
				<td width="10%">{this.props.user.CustomerEmail}</td>
				<td width="25%">
					{roles}
				</td>
				<td width="10%">{this.props.user.CompanyName}</td>
				<td width="10%">
					{this.props.user.StartDate !== null ? moment(this.props.user.StartDate).format('Do MMM YYYY, h:mm:ss a') : null}
				</td>
				<td width="10%">
					{this.props.user.ExpirationDate !== null ? moment(this.props.user.ExpirationDate).format('Do MMM YYYY, h:mm:ss a') : null}
				</td>
				<td width="15%">{this.props.user.UpdatedBy}</td>
				<td width="10%">
					{this.props.user.UpdatedDate !== null ? moment(this.props.user.UpdatedDate).format('Do MMM YYYY, h:mm:ss a') : null}
				</td>
				<td width="5%">
					{
						this.props.editId === this.props.user.ID ?
							<button onClick={this.onCancelEvent.bind(this)}>{this.props.cancelBtnLabel}</button>
							:
							<button onClick={this.onEditEvent.bind(this)}>{this.props.editBtnLabel}</button>
					}

				</td>
				<td width="5%">
					<button onClick={this.onDelEvent.bind(this)}>{this.props.deleteBtnLabel}</button>
				</td>
			</tr>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		language: state.language,
		ui: state.content.ui,
		user: state.default,
		content: state.content.customerAccessManager,
		validation: state.content.validation,
		userManager: state.userManagerUpdates
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		userManagerSuccess: (bool) => dispatch(userManagerSuccess(bool)),
		userManagerFailure: (error) => dispatch(userManagerFailure(error)),
		fetchCustomerRoles: (formValues) => dispatch(fetchCustomerRoles(formValues)),
		addCustomerRole: (formValues) => dispatch(addCustomerRole(formValues)),
		deleteCustomerRole: (formValues) => dispatch(deleteCustomerRole(formValues)),
		resetError: () => dispatch(resetError()),
		GetAllRoles: () => dispatch(GetAllRoles())
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerAccessManager)
