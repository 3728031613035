import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

// Components
import SubLeverValue from './SubLeverValue'

// UI
import AddIcon from 'UI/AddIcon'

// Utils
import { BreakLine } from 'utils/Utils'
import classnames from 'classnames'

const SubLever = (props) => {
	const SubLeverContent = () => {
		return (
			<div className="sublever_bg">
				<div className="sublever__text">{BreakLine(props.data)}</div>
				<SubLeverValue
					text={props.estimatedValue}
					currency={props.currency}
					estimatedValueAmount={isNaN(props.estimatedValueAmount) ? 0 : props.estimatedValueAmount} />
				<AddIcon
					toggle={props.dispatchToggleSubLever}
					active={props.active}
					id={(props.id + 1) + '.' + (props.index + 1)}
					subIndex={props.index}
				/>
			</div>

		)
	}

	if (props.active) {
		return (
			<Link
				className={ classnames('cell sublever', { 'sublever--active': props.active }) }
				to={{
					pathname: '/sublever-calculation',
					state: { subleverId: (props.id + 1) + '.' + (props.index + 1) }
				}}
			>
				<SubLeverContent />
			</Link>
		)
	}

	return <div className="medium-4 cell sublever"><SubLeverContent /></div>
}

export default SubLever

SubLever.propTypes = {
	data: PropTypes.string.isRequired,
	estimatedValue: PropTypes.string,
	currency: PropTypes.string.isRequired,
	estimatedValueAmount: PropTypes.string.isRequired,
	dispatchToggleSubLever: PropTypes.func.isRequired,
	active: PropTypes.bool.isRequired,
	id: PropTypes.number.isRequired,
	index: PropTypes.number.isRequired
}
