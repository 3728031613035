import React, { Component } from 'react'

import { CountryColumns } from 'Constants'
import CustomGrid from 'UI/CustomGrid'
import { connect } from 'react-redux'
import { Filters, Editors } from "react-data-grid-addons";

import NotesEditor from 'UI/NotesEditor'

import {
    getCountryDetailsLoading,
    getCountryDetailsSuccess,
    getCountryDetailsFailure,
    getCountryDetails,
    updateCountryDetailsLoading,
    updateCountryDetailsSuccess,
    updateCountryDetailsFailure,
    updateCountryDetails
} from 'actions/spendActions'
import { AlignFormatter, RoundFormatter, CenterAlignFormatter, getNonEmptyValue, formatter } from "../../utils/Utils";

const {
    SingleSelectFilter,
    NumericFilter,
    AutoCompleteFilter,
} = Filters;

const { DropDownEditor } = Editors;

const dropdownValues = ["Yes", "No"];
const dropDownEditor = <DropDownEditor options={dropdownValues} />

const dropdownInvoiceValues = ["Yes", "No", "Unknown"];
const dropDownInvoiceEditor = <DropDownEditor options={dropdownInvoiceValues} />

class CountryLookup extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {},
            columns: []
        }

        this.loadData = this.loadData.bind(this)
        this.save = this.save.bind(this)
        this.validate = this.validate.bind(this)
    }

    componentDidMount() {
        this.loadData()
    }

    loadData() {
        let columns = this.initializeColumns()
        this.props.getCountryDetails()
            .then((response) => {
                this.setState({ columns: columns, data: response.data.Result })
            }).catch((error) => {
                if (error.stack.includes('Network Error')) {
                    this.setState({ responseMsg: 'API service is down. Please contact administrator', isError: 'Yes' })
                }
                else {
                    this.setState({ responseMsg: 'An error has occurred', isError: 'Yes' })
                }
            })
    }

    initializeColumns() {
        let columns = []
        for (let index = 0; index < CountryColumns.length; index++) {
            const fieldConfig = CountryColumns[index];
            
            let tableColumn = {
                name: fieldConfig.screen_field_name,
                key: fieldConfig.field,
                width: 120,
                sortable: true,
                editable: fieldConfig.is_editable === 'Yes', 
                resizable: true, 
                filterable: true,
                screenSeq: fieldConfig.screen_seq,
                mandatory: fieldConfig.mandatory === 'Yes',
                exportFl: fieldConfig.export_fl === 'Y',
                //editProperty: fieldConfig.is_editable,
                formatter: formatter(fieldConfig.alignment),
                helpText: fieldConfig.screen_help_text
            }

            if(fieldConfig.filter_type !== 'default') {
                tableColumn['filterRenderer'] = fieldConfig.data_type === 'text' ? AutoCompleteFilter :  NumericFilter
            }

            switch (tableColumn.key) {
                // case "COUNTRY_NAME":
                // case "COUNTRY_CODE":
                // case "LONGITUDE":
                // case "LATITUDE":
                case "SAP_ARIBA_PAYABLES_DISCOUNT":
                case "TERM_RATIONALIZATION":
                case "SPOT_BUY_ELIGIBLE":
                case "PAYABLE_ELIGIBLE": tableColumn["editor"] = dropDownEditor
                case "LEGAL_E_INVOICING": tableColumn["editor"] = dropDownInvoiceEditor
                    break;               
                default:
                    break;
            }

            columns.push(tableColumn)
        }

        return columns;
    }

    save(records) {
        const recordsToUpdate = []
        for(let idx = 0; idx < records.length; idx++)
        {
            const row = records[idx]
            
            const record = {
                P_COUNTRY_CODE: row["COUNTRY_CODE"],
                P_SAP_ARIBA_PAYABLES_DISCOUNT: row["SAP_ARIBA_PAYABLES_DISCOUNT"],
                P_TERM_RATIONALIZATION: row["TERM_RATIONALIZATION"],
                P_SPOT_BUY_ELIGIBLE: row["SPOT_BUY_ELIGIBLE"],
                P_PAYABLE_ELIGIBLE: row["PAYABLE_ELIGIBLE"],
                P_LEGAL_E_INVOICING: row["LEGAL_E_INVOICING"]
            };

            recordsToUpdate.push(record);
        }

        return this.props.updateCountryDetails(recordsToUpdate)
            
    }

    validateRow(records) {
        var errorRecords = []
        const columns = this.state.columns 
        
        for(let idx = 0; idx < records.length; idx++)
        {
            const row = records[idx]
            for(let idx=0; idx < columns.length; idx++) {
                const column = columns[idx]
                if(getNonEmptyValue(row[column.key]) === '' && column.mandatory) {
                    errorRecords.push(row)
                    break;
                }
            }
            
        }

        return errorRecords;
    }

    validate(record, warningErrorRecords) {
        const columns = this.state.columns 
        
        for(let idx=0; idx < columns.length; idx++) {
            const column = columns[idx]
            if(getNonEmptyValue(record[column.key]) === '' && column.mandatory) {
                this.addWarningErroredRecord('Mandatory Field ' + column.name + ' missing', record, warningErrorRecords.errorRecords, true)
            } else {
                this.addWarningErroredRecord('Mandatory Field ' + column.name + ' missing', record, warningErrorRecords.errorRecords, false)
            }
        }

        return warningErrorRecords
    }

    addWarningErroredRecord(reason, record, records, add) {
        var arr = records[reason];
        if (getNonEmptyValue(arr) === '') {
            arr = []
        }

        var eltIdx = arr.indexOf((record['COUNTRY_CODE']));
        if (eltIdx === -1 && add) {
            arr.push(record['COUNTRY_CODE'])
        } else if (eltIdx !== -1 && !add) {
            arr.splice(eltIdx, 1)
        }

        records[reason] = arr;
    }

    render() {
        return (
            <div className="grid-x">
                <CustomGrid 
                    columns={this.state.columns} 
                    data={this.state.data} 
                    keyColumn="COUNTRY_CODE" 
                    save={this.save}
                    validate={this.validate}
                    export={true}
                    exportFileName="Country Details Extract"
                
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        content: state.content,
        ui: state.content.ui,
        gicsDetail: state.gicsDetails
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getCountryDetailsLoading: message => dispatch(getCountryDetailsLoading(message)),
        getCountryDetailsFailure: error =>   dispatch(getCountryDetailsFailure(error)),
        getCountryDetailsSuccess: bool =>    dispatch(getCountryDetailsSuccess(bool)),
        getCountryDetails: () => dispatch(getCountryDetails()),
        updateCountryDetailsLoading: message => dispatch(updateCountryDetailsLoading(message)),
        updateCountryDetailsFailure: error =>   dispatch(updateCountryDetailsFailure(error)),
        updateCountryDetailsSuccess: bool =>    dispatch(updateCountryDetailsSuccess(bool)),
        updateCountryDetails: (records) => dispatch(updateCountryDetails(records)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CountryLookup); 