import React from 'react'
import{connect} from 'react-redux'
import EnablementInsightTabTable from './EnablementInsightTabTable'
import EnablementSupplierDetailsPopup from './EnablementSupplierDetailsPopup'
import { path } from 'Constants'

// Settings
import { changeModalBorderStyle, drilldownModalBorderContentStyle, changeModalBorderRectStyle } from 'Constants'
import Modal from 'reboron/OutlineModal'

import {
    enablementInsightSuccess,
    enablementInsightFailure,
    enablementInsightLoading,    
    resetError,
    getCategoryInsightDetails,
} from 'actions/spendActions'

class CategoryOutOfScopeTab extends React.Component{
    constructor(props)
    {
        super(props);
        this.state={
          topSpendCategory: [],
          topSpendGrandTotal: [],
          topDocumentCategory: [],
          topDocGrandTotal: [],
          outOfScopeL4IndustryCategory: [],
          l4IndustryGrandTotal: [],
          l2IndustryCategory: [],
          l2IndustryGrandTotal: [],
          valuesUpdated: false,
          isLoading: true,
          responseMsg: '',
          inputParam: {
            DMC_ID: this.props.spendDetails !== undefined && this.props.spendDetails.currentActiveRequest !== undefined ? this.props.spendDetails.currentActiveRequest.DMC_ID : 0
            , VS_ID: this.props.spendDetails !== undefined && this.props.spendDetails.currentActiveRequest !== undefined ? this.props.spendDetails.currentActiveRequest.VS_ID : 0,
            User_Email: this.props.spendDetails.currentActiveRequest !== undefined &&
            this.props.spendDetails.currentActiveRequest.SAPRequester === this.props.user.user.Email ? this.props.user.user.Email : this.props.spendDetails.currentActiveRequest.SAPRequester,
            dedupStats: 'Unique',
            enabledCustomer: 'all',
            transactingCustomer: 'all',
            buyerRegion: 'all',
            businessUnit: 'all',
            erpSystem: 'all',
            l4IndustryName: 'all',
            erpCode: 'all',
            erpDesc: 'all',
            sicCode: 'all',
            sicDesc: 'all',
            
            goods: 'all',
            invoiceCountry: 'all',
            legalInvoice: 'all',
            aribaNwEligible: 'all',
            directSpend: 'all',
            column1Name: '',
            column1Value: '',
            tableName: '',
            metricsType: 'Invoice Spend'                
          },  
        
        }
        this.showEnablementSupplierDetailsModal = this.showEnablementSupplierDetailsModal.bind(this)
        this.setVisibility = this.setVisibility.bind(this)
        this.outsidePopupClick = this.outsidePopupClick.bind(this)
        this.onSave = this.onSave.bind(this)
    }

    componentDidMount(){
        this.GetCategoryInsightDetails(this.state.inputParam)        
    }

    showEnablementSupplierDetailsModal(tableName, columnName, columnValue) {

        var inputParameter = this.state.inputParam
        inputParameter.tableName = tableName
        inputParameter.column1Name = columnName
        inputParameter.column1Value = columnValue

        console.log("Column Details", columnName +" "+ columnValue)
		this.setState({	openPopup: true, inputParam: inputParameter	})
    }

    onSave(value) {
        this.setState({valuesUpdated: value})
    }

    setVisibility(isVisible) {
        this.handlePoupClose()   
    }

    outsidePopupClick() {        
        this.handlePoupClose()
    }
    
    handlePoupClose() {
        this.setState({  open: false, openPopup: false }, () => {
            if(this.state.valuesUpdated) {
                this.setState({valuesUpdated: false}, this.GetCategoryInsightDetails(this.state.inputParam))
            }
        })
    }
    
    GetCategoryInsightDetails(inputParam) {		
		this.props.getCategoryInsightDetails(inputParam)
			.then((response) => {
				if (response !== undefined && response.status === 200 && response.data.Result !== undefined) {
                var topSpendData; var topSpendTotal;
                var topDocData; var topDocTotal;
                var outOfScopeL4Data; var outOfScopeL4Total;
                var l2IndustryData; var l2IndustryTotal;
                    var tables = response.data.Result.map(
                        function (table) {
                            if(table['NET_SPEND_CAT_SEGMENTATION']!== undefined){
                                topSpendData = table['NET_SPEND_CAT_SEGMENTATION']
                                topSpendTotal = table['GrandTotal']
                            }

                            if(table['NET_DOC_CAT_SEGMENTATION']!== undefined){
                                topDocData = table['NET_DOC_CAT_SEGMENTATION']
                                topDocTotal=table['GrandTotal']
                            }

                            if(table['L2_INDUSTRY_NAME']!== undefined){
                                l2IndustryData = table['L2_INDUSTRY_NAME']
                                l2IndustryTotal=table['GrandTotal']
                            } 
                            
                            if(table['L4_INDUSTRY_NAME']!== undefined){
                                outOfScopeL4Data = table['L4_INDUSTRY_NAME']
                                outOfScopeL4Total = table['GrandTotal']
                            }
                        }
                    )
               
                this.setState({ topSpendCategory : topSpendData, topSpendGrandTotal: topSpendTotal, topDocumentCategory: topDocData, topDocGrandTotal: topDocTotal,
                     outOfScopeL4IndustryCategory: outOfScopeL4Data, l4IndustryGrandTotal: outOfScopeL4Total, l2IndustryCategory: l2IndustryData,
                     l2IndustryGrandTotal: l2IndustryTotal, isLoading:false  })
                this.props.enablementInsightSuccess(true)
				}
			}).catch((error) => {
				if (error.stack.includes('Network Error')) {
                    this.setState({ responseMsg: 'API service is down. Please contact administrator', isError: 'Yes' })
					this.props.enablementInsightFailure('API service is down. Please contact administrator.')
				}
				else {
                    this.setState({ responseMsg: 'An error has occurred', isError: 'Yes' })
					this.props.enablementInsightFailure(this.props.content.error)
				}
			})
    }
    
    componentWillReceiveProps(nextProps, nextState){
        if (this.state.Test !== nextProps.filterData && 'dedupStats' in nextProps.filterData)
        {
        var inputParameter = this.state.inputParam
        inputParameter.dedupStats = nextProps.filterData.dedupStats
        inputParameter.enabledCustomer= nextProps.filterData.enabledCustomer
        inputParameter.transactingCustomer= nextProps.filterData.transactingCustomer
        inputParameter.buyerRegion= nextProps.filterData.buyerRegion
        inputParameter.businessUnit= nextProps.filterData.businessUnit
        inputParameter.erpSystem= nextProps.filterData.erpSystem
        inputParameter.l4IndustryName= nextProps.filterData.l4IndustryName
        inputParameter.erpCode= nextProps.filterData.erpCode 
        inputParameter.erpDesc= nextProps.filterData.erpDesc 
        inputParameter.sicCode= nextProps.filterData.sicCode 
        inputParameter.sicDesc= nextProps.filterData.sicDesc
        inputParameter.goods= nextProps.filterData.goods
        inputParameter.invoiceCountry= nextProps.filterData.invoiceCountry
        inputParameter.legalInvoice= nextProps.filterData.legalInvoice
        inputParameter.aribaNwEligible= nextProps.filterData.aribaNwEligible
        inputParameter.metricsType= nextProps.filterData.metricsType
        inputParameter.directSpend= nextProps.filterData.directSpend

        this.GetCategoryInsightDetails(inputParameter)
        this.setState({Test:nextProps.filterData , inputParam: Object.keys(nextProps.filterData).length !== 0 ? nextProps.filterData : this.state.inputParam})
        //console.log("if enablemtn details filetr props-")
        }
        else{
          //  console.log("else enablemtn details filetr props-")
        }
        
    }

    render()
    {
        return(
            <div className="grid-x" style={{ width: '100%'}}>

                    <div className="medium-12">
                        {(this.state.isLoading) ?
                            <img style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={path + `images/ajax-loader.gif`} alt="" />
                            : null
                        }
                        {(this.state.isError === 'Yes') ?
                            <div style={{ color: 'red', textAlign: 'center' }} className="cell pt5">{this.state.responseMsg}</div>
                            : <div style={{ color: 'green', textAlign: 'center' }} className="cell pt5">{this.state.responseMsg}</div>
                        }
                    </div>

                <div className="medium-12 marginTop">
                        <div className="grid-x"> 
                                <div className="grid-x">
                                <div className="medium-12">
                                    {this.state.outOfScopeL4IndustryCategory !== undefined && this.state.outOfScopeL4IndustryCategory !== null ?
                                        <EnablementInsightTabTable
                                            data={this.state.outOfScopeL4IndustryCategory}
                                            grandTotal={this.state.l4IndustryGrandTotal}
                                            content={this.props.content}
                                            tableName={this.props.content.categoryInsight.l4IndustryCategoryHeader}
                                            tableSequence={"19"}
                                            showEnablementSupplierDetailsModal={this.showEnablementSupplierDetailsModal}
                                            metricsType= {this.state.inputParam.metricsType}
                                        />
                                        : <div className="pt10">{this.props.content.noDataFound}</div>}
                                        </div>
                                </div> 
                        </div>
                    </div>
      
        <div>  
                  {this.refs.enablementSupplier ? this.state.openPopup ?
                    this.refs.enablementSupplier.show() :
                    this.refs.enablementSupplier.hide() : ''}        
                  <Modal
                    ref={'enablementSupplier'}
                    modalStyle={changeModalBorderStyle}
                    contentStyle={drilldownModalBorderContentStyle}
                    rectStyle={changeModalBorderRectStyle}                   
                    keyboard={false}
                    backdrop={false}
                   >
                    <div className="grid-x tabs_container supplier-tabs">                       
                        <div className="medium-12">                        
                            {
                                <EnablementSupplierDetailsPopup onSave={this.onSave} input={this.state.inputParam} outsidePopupClick={this.outsidePopupClick} ></EnablementSupplierDetailsPopup>
                            }
                        </div>
                    </div>
                    <button onClick={() => { this.setVisibility(false); this.setState({ selectedIndustryName: {} }) }} className='close' />
                </Modal>
                  </div>
                    
                    {/* <div><EnablementSupplierPopup open={this.state.openPopup} visibility={this.setVisibility} outsidePopupClick={this.outsidePopupClick} ></EnablementSupplierPopup></div> */}
            </div>                   
        )
    }

}



const mapStateToProps = state => {
	return {
        content: state.content.enablementInsight,
        //content: state.content.subLeverCalculations,
		ui: state.content.ui,
		currency: decodeURIComponent(state.valueStory.Currency),
		userData: state.valueStory,
		spendAsPercentageOfRevenue: state.spendAsPercentageOfRevenue,
		benchMarkCalculations: state.benchMarksData,
		allContent: state.content,
        language: state.language,
        spendDetails: state.spendDetails,
        user: state.default
	}
}

const mapDispatchToProps = dispatch => {
	return {
        enablementInsightSuccess: message => dispatch(enablementInsightSuccess(message)),
        enablementInsightFailure: error => dispatch(enablementInsightFailure(error)),
        enablementInsightLoading: bool => dispatch(enablementInsightLoading(bool)),
        resetError: error => dispatch(resetError(error)),
        getCategoryInsightDetails: (data) => dispatch(getCategoryInsightDetails(data)),
        
	}
}

export default connect(mapStateToProps,mapDispatchToProps) (CategoryOutOfScopeTab)