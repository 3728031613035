//
//
//
//
//       <- This code is no longer used ->
//
//
//
//
import React from 'react';
import { connect } from 'react-redux'
import CheckboxTree from 'react-checkbox-tree';
import Button from 'UI/Button'
import { GoPlus, GoMail, GoDash } from "react-icons/lib/go";
import { RadioButton } from 'material-ui';
import ScrollArea from 'react-scrollbar'

class TreeView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: props.data,
            peArray: [],
            bpArray: [],
            flag: false,
            index: -1,
            peChecked: false,
            bpChecked: false,
            peCheckIndex: -1,
            bpCheckIndex: -1,
            companyName: []
        }
        this.loadPE_Data = this.loadPE_Data.bind(this)
        this.fetchDataForBP_ID = this.fetchDataForBP_ID.bind(this);
        this.PE_RadioButtonChecked = this.PE_RadioButtonChecked.bind(this);
        this.BP_RadioButtonChecked = this.BP_RadioButtonChecked.bind(this);
        this.save = this.save.bind(this)
    }

    componentWillReceiveProps(NextProps) {
        if (NextProps.data != this.state.data) {
            this.setState({ data: NextProps.data }, this.loadPE_Data)
        }
    }

    save() {
        if (this.state.peChecked || this.state.bpChecked) {
            this.props.radioButtonClicked(this.state.companyName)
        }

        this.props.close();
    }

    componentWillMount() {
        this.loadPE_Data()
        //this.fetchDataForBP_ID()
    }

    //still need to think on this 
    fetchDataForBP_ID(e) {
        let peId = e.currentTarget.attributes.value.nodeValue;
        let data = this.props.firstSearchResult;
        let results = data.filter(item => item.PE_ID == peId)
        const uniqueObj = [];
        const map = new Map();
        for (const item of results) {
            if (!map.has(item.ORG_ID)) {
                map.set(item.ORG_ID, true);    // set any value to Map
                uniqueObj.push({ item });
            }
        }
        let temp = this.state.data;

        temp.forEach(item => {
            if (item.item.PE_ID == peId && item.flag == false) {
                item.flag = true
            } else {
                item.flag = false
            }
        })
        this.setState({ bpArray: uniqueObj, data: temp, flag: !this.state.flag, index: e.currentTarget.id }, this.loadPE_Data)
    }

    PE_RadioButtonChecked(e) {
        var companyName = [];
        var peData = this.state.data.find(item => item.item.PE_ID == e.currentTarget.id)
        peData.item.COMPANY_SOURCE = 'PE';
        companyName.push({ 'name': peData.item.PE_NAME, 'id': peData.item.PE_ID, 'flag': 'PE', 'data': peData.item })
        //After this send the data back to search results to populate company profile..................................

        this.setState({ peChecked: true, peCheckIndex: e.currentTarget.id, companyName: companyName, bpCheckIndex: -1, bpChecked: false }, this.loadPE_Data)
    }

    BP_RadioButtonChecked(e) {
        var companyName = [];
        var bpData = this.state.bpArray.find(item => item.item.ORG_ID == e.currentTarget.id)
        bpData.item.COMPANY_SOURCE = 'BP'
        companyName.push({ 'name': bpData.item.PE_NAME, 'id': bpData.item.PE_ID, 'flag': 'BP', 'data': bpData.item })
        //After this send the data back to search results to populate company profile..................................

        this.setState({ bpChecked: true, bpCheckIndex: e.currentTarget.id, companyName: companyName, peChecked: false, peCheckIndex: -1 }, this.loadPE_Data)
    }

    loadPE_Data() {
        var result = (<React.Fragment>
            {this.state.data.map((data, index) => (
                <div className="pe-div">
                    <div className="company-name-row">
                        <div className="float-left collapse-expand">
                            <div onClick={this.fetchDataForBP_ID} value={data.item.PE_ID} id={index} className="float-left plus-icon">
                                {data.flag ? <img width="10" src="images/minus-inactive.svg" /> : <img width="10" src="images/add.svg" />}
                            </div>
                            <div className="float-left radio-btn">
                                <RadioButton className='radioButton'
                                    checked={this.state.peChecked && data.item.PE_ID == this.state.peCheckIndex}
                                    id={data.item.PE_ID}
                                    value={'PE_ID'}
                                    onClick={this.PE_RadioButtonChecked}
                                    color="default"
                                />
                            </div>
                        </div>
                        <div className="float-left details-text">
                            <div>
                                <span className="dark">{data.item.PE_NAME}</span>
                                <span> |  {data.item.PE_ID_CITY}</span>
                                <span> |  {data.item.PE_ID_COUNTRY}</span>
                            </div>
                            <div id={data.item.PE_ID}>
                                <span>PE ID: <span className="dark">{data.item.PE_ID}</span></span>
                                <span> | CapIQ ID: <span className="dark">{data.item.PE_CAPIQ_ID}</span></span>
                            </div>
                        </div>
                    </div>
                    <div className={data.flag ? 'contentShow' : 'contentHide'}>
                        {this.state.bpArray.map((bpData, bpIndex) => (
                            <div className="aligned-row">
                                <div className="radio-btn">
                                    <RadioButton className='radioButton'
                                        checked={this.state.bpChecked && bpData.item.ORG_ID == this.state.bpCheckIndex}
                                        id={bpData.item.ORG_ID}
                                        value={'ORG_ID'}
                                        peID={data.item.PE_ID} color="default"
                                        onClick={this.BP_RadioButtonChecked} />
                                </div>
                                <div className="bp-row">
                                    <div>
                                        <span className="dark">{bpData.item.ORG_NAME}</span>
                                        <span> |  {bpData.item.CITY}</span>
                                        <span> |  {bpData.item.COUNTRY}</span>
                                    </div>
                                    <div id={bpData.item.ORG_ID}>
                                        <span>BP ID: <span className="dark">{bpData.item.ORG_ID}</span></span>
                                        <span> | CapIQ ID: <span className="dark">{bpData.item.ORG_CAPIQ_ID}</span></span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </React.Fragment>)
        this.setState({ peArray: result })
    }

    render() {
        return (
            <div className="treeContainer">
                <div>
                    <div className="title float-left">PE and BP IDs associated to this Global Ultimate ID</div>
                    <div className="float-right close-icon" onClick={this.props.close}>
                        <img width="10" src="images/close_inactive.svg" />
                    </div>
                </div>
                <ScrollArea minScrollSize={40}
                    speed={0.8}
                    className="box"
                    contentClassName="content"
                    horizontal={false} >
                    {this.state.peArray}
                </ScrollArea>
                <div className="grid-x popupOkBtn" Style={{'margin':'0'}}>
                    <div className="medium-12">
                        <div className="float-right">
                            <div className="float-left">
                                <Button
                                    content={this.props.ui.cancel}
                                    color
                                    onClick={this.props.close}
                                />
                            </div>
                            <div className="float-left">
                                <Button
                                    content={this.props.ui.ok}
                                    color
                                    float
                                    onClick={this.save}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        data: state.treeData,
        firstSearchResult: state.searchData,
        ui: state.content.ui
    }
}
export default connect(mapStateToProps, null)(TreeView);
