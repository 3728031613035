import {
  modelReducer,
  formReducer
} from 'react-redux-form'

// Login Form
const initialsignInFormState = {
  Email: '',
  Password: ''
}

export const signIn = modelReducer('signIn', initialsignInFormState)
export const signInForm = formReducer('signIn', initialsignInFormState)

// Register Form
const initialRegisterFormState = {
  FirstName: '',
  LastName: '',
  Company: '',
  Role: '',
  Email: '',
  Password: '',
  ConfirmPassword: '',
  Language: 'EN'
}

export const register = modelReducer('register', initialRegisterFormState)
export const registerForm = formReducer('register', initialRegisterFormState)

// Value Story Form
const initialAddValueStoryFormState = {
  Id: '',
  ValueStoryName: '',
  Language: 'EN'
}

export const addValueStory = modelReducer('addValueStory', initialAddValueStoryFormState)
export const addValueStoryForm = formReducer('addValueStory', initialAddValueStoryFormState)

// Discovery Form
const initialDiscoveryFormState = {
  Id: '',
  ValueStoryName: '',
  Language: 'EN',
  PEId: 0,
  Organization: '',
  Industry: '',
  Location: 0,
  AnnualRevenue: '',
  TotalSpend: '',
  ValueStoryName: '',
  CapIQId: '',
  GlobalUltimateId: '',
  BPId: '',
  Currency: '$',
  CurrencyCode: 'USD',
  CapIQCompany: '',
  PECompany: '',
  GlobalUltimateCompany: '',
  BPCompany: '',
  Test: 'false',
  AllowEdit: true,
  ValueStoryType: '',
  transaction_ANsuppliers_automation_eligible: false,
  unclassified_automation_eligible: false,
  whenDirect_supplier_SCC_eligible: false,
  field_glass_scope: false,
  fieldglasseligible: false,
}

export const discovery = modelReducer('discovery', initialDiscoveryFormState)
export const discoveryForm = formReducer('discovery', initialDiscoveryFormState)

// Forgot Password Form
const initialForgotPasswordFormState = {
  Email: '',
  Language: 'EN'
}

export const forgotPassword = modelReducer('forgotPassword', initialForgotPasswordFormState)
export const forgotPasswordForm = formReducer('forgotPassword', initialForgotPasswordFormState)

// User Manager Form
const initialUserManagerFormState = {
  Email: '',
  Role: '',
  Language: 'EN'
}

export const userManager = modelReducer('userManager', initialUserManagerFormState)
export const userManagerForm = formReducer('userManager', initialUserManagerFormState)

// User Activation Form
const initialActivateUserFormState = {
  Email: '',
  Password: '',
  Activated: '',
  NewReport: '',
  Language: 'EN'
}

export const activateUser = modelReducer('activateUser', initialActivateUserFormState)
export const activateUserForm = formReducer('activateUser', initialActivateUserFormState)

// Reset Password Form
const initialResetPasswordFormState = {
  Email: '',
  Password: '',
  ConfirmPassword: '',
  Code: ''
}

export const resetPassword = modelReducer('resetPassword', initialResetPasswordFormState)
export const resetPasswordForm = formReducer('resetPassword', initialResetPasswordFormState)

// Change Password Form
const initialChangePasswordFormState = {
  UserId: '',
  OldPassword: '',
  NewPassword: '',
  ConfirmPassword: ''
}

export const changePassword = modelReducer('changePassword', initialChangePasswordFormState)
export const changePasswordForm = formReducer('changePassword', initialChangePasswordFormState)

// Save As
const initialSaveAsState = {
  VSId: '',
  Id: '',
  ValueStoryName: '',
  Organization: '',
  PEId: 0,
  Language: 'EN'
}

export const saveAs = modelReducer('saveAs', initialSaveAsState)
export const saveAsForm = formReducer('saveAs', initialSaveAsState)

// Mutual Plan
const initialPlanState = {
  MutualActionPlan: ''
}

export const plan = modelReducer('plan', initialPlanState)
export const planForm = formReducer('plan', initialPlanState)

// Report
const initialReportState = {
  Email: '',
  Language: 'EN',
  id: ''
}

export const report = modelReducer('report', initialReportState)
export const reportForm = formReducer('report', initialReportState)

// AllowedListed Domain Form
const initialAddDomainFormState = {
  Id: '',
  DomainName: ''
}

export const addDomain = modelReducer('addDomain', initialAddDomainFormState)
export const addDomainForm = formReducer('addDomain', initialAddDomainFormState)

//Edit AllowedListed Domain Form
const initialEditDomainFormState = {

  DomainName: '',
  Description: ''
}

export const editDomain = modelReducer('editDomain', initialEditDomainFormState)
export const editDomainForm = formReducer('editDomain', initialEditDomainFormState)
// Blocked Domain Form
const initialAddBlockedDomainFormState = {
  Id: '',
  DomainName: ''
}

export const addBlockedDomain = modelReducer('addBlockedDomain', initialAddBlockedDomainFormState)
export const addBlockedDomainForm = formReducer('addBlockedDomain', initialAddBlockedDomainFormState)

//Edit Blockeded Domain Form
const initialEditBlockedDomainFormState = {

  DomainName: '',
  Description: ''
}

export const editBlockedDomain = modelReducer('editBlockedDomain', initialEditBlockedDomainFormState)
export const editBlockedDomainForm = formReducer('editBlockedDomain', initialEditBlockedDomainFormState)

// BlackListed Domain Form
const initialAddBlackListDomainFormState = {
  Id: '',
  DomainName: ''
}

export const addBlackListDomain = modelReducer('addBlackListDomain', initialAddBlackListDomainFormState)
export const addBlackListDomainForm = formReducer('addBlackListDomain', initialAddBlackListDomainFormState)

//Edit BlackListed Domain Form
const initialEditBlackListDomainFormState = {

  DomainName: '',
  Description: ''
}

export const editBlackListDomain = modelReducer('editBlackListDomain', initialEditBlackListDomainFormState)
export const editBlackListDomainForm = formReducer('editBlackListDomain', initialEditBlackListDomainFormState)

// Slide Manager Form
const initialSlideManagerFormState = {
  SlideName: '',
  SectionName: '',
  FileName: '',
  Language: 'EN'
}

export const slideManager = modelReducer('slideManager', initialSlideManagerFormState)
export const slideManagerForm = formReducer('slideManager', initialSlideManagerFormState)

// Section Manager Form
const initialSectionManagerFormState = {
  FileName: '',
  SectionName: '',
  Description: '',
  Lan: '',
  Language: 'EN'
}

export const sectionManager = modelReducer('sectionManager', initialSectionManagerFormState)
export const sectionManagerForm = formReducer('sectionManager', initialSectionManagerFormState)

//Language form
const initialLanFormState = {
  LanguageName: '',
  LanguageShortName: ''
}

export const lanMgr = modelReducer('lanMgr', initialLanFormState)
export const lanMgrForm = formReducer('lanMgr', initialLanFormState)

// Terms and Conditions Form
const initialTermsFormState = {
  UserID: '',
  Checked: false,
  Language: 'EN'
}

export const terms = modelReducer('terms', initialTermsFormState)
export const termsForm = formReducer('terms', initialTermsFormState)

// Report Manager Form
const initialReportManagerFormState = {
  ReportName: '',
  Description: '',
  Layout: '',
  Size: '',
  Language: 'EN'
}

export const reportsManager = modelReducer('reportsManager', initialReportManagerFormState)
export const reportsManagerForm = formReducer('reportsManager', initialReportManagerFormState)

const initialReportLayoutManagerFormState = {
  Layout: '',
  Size: '',
  Language: 'EN'
}

export const reportsLayoutManager = modelReducer('reportsLayoutManager', initialReportLayoutManagerFormState)
export const reportsLayoutManagerForm = formReducer('reportsLayoutManager', initialReportLayoutManagerFormState)

const initialReportLayoutSizeManagerFormState = {
  Layout: '',
  Size: '',
  Language: 'EN'
}

export const reportsLayoutSizeManager = modelReducer('reportsLayoutSizeManager', initialReportLayoutSizeManagerFormState)
export const reportsLayoutSizeManagerForm = formReducer('reportsLayoutSizeManager', initialReportLayoutSizeManagerFormState)

const initialEditReportFormState = {

  NewReport: '',
  Description: ''
}

export const editReport = modelReducer('editReport', initialEditReportFormState)
export const editReportForm = formReducer('editReport', initialEditReportFormState)

// Release Notification Manager Form
const initialNotificationManagerFormState = {
  ReleaseVersionID: '',
  ReleaseFeatureID: '',
  ReleaseVersionName: '',
  ReleaseFeatureName: '',
  ReleaseFeatureDescription: '',
  Active: '',
  AddedBy: '',
  UpdatedBy: ''
}

export const notificationManager = modelReducer('notificationManager', initialNotificationManagerFormState)
export const notificationManagerForm = formReducer('notificationManager', initialNotificationManagerFormState)


//Edit  Release Notification Manager
const initialEditReleaseNotificationFormState = {

  ReleaseFeatureName: '',
  ReleaseFeatureDescription: ''
}

export const editReleaseNotification = modelReducer('editReleaseNotification', initialEditReleaseNotificationFormState)
export const editReleaseNotificationForm = formReducer('editReleaseNotification', initialEditReleaseNotificationFormState)

// Value Story Share
const initialValueStoryShareFormState = {
  VSId: '',
  SharedWith: '',
  SharedBy: '',
  Active: null,
  AllowEdit: true,
  Company: '',
  ExpirationDate: '',
  EnablementFlag: false
}

export const valueStoryShare = modelReducer('valueStoryShare', initialValueStoryShareFormState)
export const valueStoryShareForm = formReducer('valueStoryShare', initialValueStoryShareFormState)

// File Upload Manager
const initialFileUploadManagerFormState = {
  TableSchema: '',
  FileUploadData: '',
  FileUploadName: ''
}

export const fileUploadManager = modelReducer('fileUploadManager', initialFileUploadManagerFormState)
export const fileUploadManagerForm = formReducer('fileUploadManager', initialFileUploadManagerFormState)

// Role Manager Form
const initialRoleManagerFormState = {
  Role: '',
  Features: '',
  Language: 'EN'
}

export const roleManager = modelReducer('roleManager', initialRoleManagerFormState)
export const roleManagerForm = formReducer('roleManager', initialRoleManagerFormState)

// CapIQ Admin screen Form
const initialCapIqUserManagerFormState = {
  Email: ''
}

export const capIqUserManager = modelReducer('capIqUserManager', initialCapIqUserManagerFormState)
export const capIqUserManagerForm = formReducer('capIqUserManager', initialCapIqUserManagerFormState)


//Your file from Spend analysis
const initialYourFileFormState = {
  RequestId: '',
  Requester: '',
  ProcessingDate: ''

}

export const yourFile = modelReducer('yourFile', initialYourFileFormState)
export const yourFileForm = formReducer('yourFile', initialYourFileFormState)

// Top Details from Spend Analysis
const initialTopDetailsFormState = {
  Dimension: ''
}

export const TopDetails = modelReducer('topDetails', initialTopDetailsFormState)
export const TopDetailsForm = formReducer('topDetails', initialTopDetailsFormState)


// spend parameter form Admin Screen 
const initialSpendParameterFormState = {
  ID : 0,
  ParameterName :'',
  ParameterDescription :'',
  ParameterValue :'',   
  UpdatedBy :'',
  UpdatedDate :null 
  
}

export const spendParameter = modelReducer('spendParameter', initialSpendParameterFormState)
export const spendParameterForm = formReducer('spendParameter', initialSpendParameterFormState)

// Flight Planning Doc parameter form Admin Screen 
const initialFlightPlanningDocParameterFormState = {
  MIN_DOC :'',
  MAX_DOC :'',
  DOC_SEGMENTATION :''  
}

export const flightPlanningDocParameter = modelReducer('flightPlanningDocParameter', initialFlightPlanningDocParameterFormState)
export const flightPlanningDocParameterForm = formReducer('flightPlanningDocParameter', initialFlightPlanningDocParameterFormState)

// Flight Planning Spend parameter form Admin Screen 
const initialFlightPlanningSpendParameterFormState = {
  MIN_SPEND :'',
  MAX_SPEND :'',
  SPEND_SEGMENTATION :''  
}

export const flightPlanningSpendParameter = modelReducer('flightPlanningSpendParameter', initialFlightPlanningSpendParameterFormState)
export const flightPlanningSpendParameterForm = formReducer('flightPlanningSpendParameter', initialFlightPlanningSpendParameterFormState)

// Ariba Network Enablement Match Type parameter form Admin Screen 
const initialNetwrokEnablementMatchTypeParameterFormState = {
  MATCH_TYPE :'',
  ENABLEMENT_YIELD_PER: ''
}

export const netwrokEnablementMatchTypeParameter = modelReducer('netwrokEnablementMatchTypeParameter', initialNetwrokEnablementMatchTypeParameterFormState)
export const netwrokEnablementMatchTypeParameterForm = formReducer('netwrokEnablementMatchTypeParameter', initialNetwrokEnablementMatchTypeParameterFormState)

const initialEnablementResourceParameterFormState = {
  RESOURCE_TYPE :'',
  ACCOUNT_TYPE:'',
  FTES:'',
  WAVE_1:'',
  WAVE_N:'',
  SUPPLIER_WAVE_1:'',
  SUPPLIER_WAVE_N:''
}

export const enablementResourceParameter = modelReducer('enablementResourceParameter', initialEnablementResourceParameterFormState)
export const enablementResourceParameterForm = formReducer('enablementResourceParameter', initialEnablementResourceParameterFormState)

// ANID manager form Admin Screen 
const initialANIDManagerFormState = {
  Customer :'',
  BPID :'',
  ANID :'',
  Region: '',
  Country: ''
}

export const anIdManager = modelReducer('anIdManager', initialANIDManagerFormState)
export const anIdManagerForm = formReducer('anIdManager', initialANIDManagerFormState)


//CollectCustomerSpend Form
const initialCollectCustomerSpendFormState = {
  RequestorName: '',
  CustomerEmail: '',
  NeedDate: ''

}

export const collectCustomerSpend = modelReducer('collectCustomerSpend', initialCollectCustomerSpendFormState)
export const collectCustomerSpendForm = formReducer('collectCustomerSpend', initialCollectCustomerSpendFormState)