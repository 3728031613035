import React from 'react'
import { connect } from 'react-redux'
import GanntChart from './GanntChart';
import Select from 'react-select';
import EnablementSupplierDetailsPopup from 'components/Spend/EnablementInsight/EnablementSupplierDetailsPopup'
import VideoHelp from 'components/UI/VideoHelp';
import cloneDeep from 'lodash/cloneDeep';

// Settings
import { modalStyle, modalContentStyle, changeModalBorderStyle, changeModalBorderRectStyle, drilldownModalBorderContentStyle } from 'Constants'

import Modal from 'reboron/OutlineModal'

import {
    getGanntChartData,
    getGanntChartSuppliersDetails,
    resetError,
    getResultLoading, getResultSuccess, getResultFailure
} from 'actions/spendActions'

import moment from 'moment'

class GanntChartContainer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            valuesUpdated: false,
            previousTaskArray: [

            ],
            taskArray: [
                {
                    task: "Ramp-Up",
                    type: "0",
                    startTime1: "2021-01-01",
                    endTime1: "2021-02-14",
                    startTime2: "2021-01-01",
                    endTime2: "2021-02-14",
                    showStar: false
                }
            ],
            taskArray2: [
                {
                    task: "Ramp-Up",
                    type: "0",
                    startTime1: "2021-01-01",
                    endTime1: "2021-02-14",
                    startTime2: "2021-01-01",
                    endTime2: "2021-02-14",
                    showStar: false
                }
            ],
            tasksList: ['Ramp-Up', 'Enterprise - Portal', 'Enterprise - Integration & Catalog', 'Enterprise - Integration', 'Enterprise - Portal & Catalog', 'Managed Standard Account', 'Ongoing Standard Enablement'],
            arrWithoutTasks: [],
            chartArr: [],
            cloneChartArr: [],
            legendList1: ['Set up', 'Enterprise - Portal', 'Enterprise - Integration & Catalog', 'Enterprise - Integration', 'Enterprise - Portal & Catalog', 'Managed Standard Account', 'Ongoing Standard Enablement', 'Go live'],
            legendList2: ['Set up', 'Ongoing Standard Enablement', 'Go live'],
            startYear: null,
            selectedEffort: 'Conservative',
            supplierData: null,
            tasksChecked: false,
            dropDownValues: [
                { value: 'default', label: 'Dynamic view (default)' },
                { value: 12, label: '12 month view' },
                { value: 24, label: '24 month view' }
            ],
            selectedValue: null
        }
        this.onDoubleClickGanntChart = this.onDoubleClickGanntChart.bind(this);
        this.loadData = this.loadData.bind(this);
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.onSave = this.onSave.bind(this)
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        let dmcId = this.props.spendDetails.currentActiveRequest.DMC_ID;
        let vsID = this.props.spendDetails.currentActiveRequest.VS_ID;
        let userEmail = this.props.user.user.Email;
        this.props.getResultLoading(true);
        this.props.getGanntChartData(dmcId, vsID, userEmail).then((response) => {
            try {
                if (response.data.Result.length > 0) {
                    let result = response.data.Result;
                    let ele = result[0];
                    let date = moment(ele.WK_START_ENT_AGGR)
                    let year = date.year();
                    //In below code we should always get date as 15th of feb for current year
                    //let day = moment(date).subtract(14, "days").format("YYYY-MM-DD");
                    this.props.getResultSuccess();
                    let data = [
                        {
                            task: "Ramp-Up",
                            type: "0",
                            startTime1: `${year}-01-01`,//ram-up always start at 1st of current year
                            endTime1: moment(date).subtract(1, "days").format("YYYY-MM-DD"),
                            startTime2: `${year}-01-01`,
                            endTime2: moment(date).subtract(1, "days").format("YYYY-MM-DD"),
                            showStar: false
                        }
                    ];
                    result.forEach((element, index) => {
                        let temp = {
                            task: element.GANTT_CHART_WORKSTREAM_NAME,
                            type: element.ENABLE_WAVE,
                            startTime1: moment(element.WK_START_ENT_AGGR).format('YYYY-MM-DD'),//moment(element.WK_END_CONSR).format('YYYY-MM-DD');
                            endTime1: moment(element.WK_END_AGGR).format('YYYY-MM-DD'),
                            startTime2: moment(element.WK_START_ENT_CONR).format('YYYY-MM-DD'),
                            endTime2: moment(element.WK_END_CONSR).format('YYYY-MM-DD'),
                            showStar: false,
                            supplierCount_Off: element.UNIQUE_SUPPLIER_WV_CNT,
                            supplierCount_On: element.UNIQUE_SUPPIER_DETL_CNT,
                            anIdCount_Off: element.UNIQUE_ANID_WV_CNT,
                            anIdCount_On: element.UNIQUE_AN_ID_DETL_CNT,
                            poSpend_Off: element.AGGR_PO_SPEND_WV_CNT,
                            poSpend_On: element.AGGR_PO_SPEND_DETL_CNT,
                            invSpend_Off: element.AGGR_INV_SPEND_WV_CNT,
                            invSpend_On: element.AGGR_INV_SPEND_DETL_CNT,
                            docCount_Off: element.DOC_PROXY_WV_CNT,
                            docCount_On: element.DOC_PROXY_DETL_CNT,
                            label: element.WAVE_DESCRIPTION
                        }
                        data.push(temp)
                    });

                    let isOnePresent = data.filter(el => el.type == 1)
                    if (isOnePresent.length == 0) {
                        this.setState({ errorMessage: 'Wave no. 1 should be present to generate Gantt chart.' })
                    }

                    let maxWaveNo = Math.max.apply(Math, data.map(function (o) { return o.type; }))
                    if (maxWaveNo > 2) {
                        let firstWaveEnd1 = null;
                        let firstWaveEnd2 = null;
                        let defaultArr = [{ task: "Ramp-Up", type: "0", startTime1: `${year}-01-01`, endTime1: moment(date).subtract(1, "days").format("YYYY-MM-DD"), startTime2: `${year}-01-01`, endTime2: moment(date).subtract(1, "days").format("YYYY-MM-DD") }];
                        for (let i = 0; i < maxWaveNo; i++) {
                            let typeArr = data.filter(function (el) {
                                return el.type == i + 1;
                            })
                            if (typeArr.length > 0) {
                                let startTime1 = typeArr[0].startTime1;
                                let startTime2 = typeArr[0].startTime2;
                                let maxEndTime1 = new Date(Math.max.apply(null, typeArr.map(function (o) { return new Date(o.endTime1); })))
                                maxEndTime1.setDate(maxEndTime1.getDate() + 1)// adding a day as in coversion its getting removed
                                let maxEndTime2 = new Date(Math.max.apply(null, typeArr.map(function (o) { return new Date(o.endTime2); })))
                                maxEndTime2.setDate(maxEndTime2.getDate() + 1)
                                let defaultTemp = {
                                    task: '', type: i + 1, label: typeArr[0].label, startTime1: startTime1, endTime1: moment(maxEndTime1).format('YYYY-MM-DD'),
                                    startTime2: startTime2, endTime2: moment(maxEndTime2).format('YYYY-MM-DD'), supplierCount_Off: typeArr[0].supplierCount_Off, supplierCount_On: typeArr[0].supplierCount_On,
                                    poSpend_Off: typeArr[0].poSpend_Off, poSpend_On: typeArr[0].poSpend_On, invSpend_Off: typeArr[0].invSpend_Off, invSpend_On: typeArr[0].invSpend_On, docCount_Off: typeArr[0].docCount_Off, docCount_On: typeArr[0].docCount_On,
                                    anIdCount_Off: typeArr[0].anIdCount_Off, anIdCount_On: typeArr[0].anIdCount_On
                                }
                                if (i == 0) {
                                    firstWaveEnd1 = moment(maxEndTime1).format('YYYY-MM-DD');
                                    firstWaveEnd2 = moment(maxEndTime2).format('YYYY-MM-DD');
                                }
                                defaultArr.push(defaultTemp)
                            }
                        }
                        //adding slice(-1)[0] ongoing std block at the end
                        // {
                        //     task: "Ongoing Standard Enablement",
                        //     type: "No",
                        //     startTime1: "2021-05-01",
                        //     endTime1: "2022-01-01",
                        //     startTime2: "2021-04-01",
                        //     endTime2: "2021-12-01",
                        //     showStar: false
                        // }

                        let lastElement = defaultArr.slice(-1)[0];
                        let d1 = moment(lastElement.endTime1)
                        let d2 = moment(lastElement.endTime2)
                        let lastYear1;
                        let lastYear2;
                        let lastMonth1;
                        let lastMonth2;
                        lastYear1 = d1.year()
                        if (lastYear1 == year) {
                            lastMonth1 = d1.month() + 1;
                        } else {
                            lastMonth1 = 12
                        }
                        lastYear2 = d2.year();
                        if (lastYear2 == year) {
                            lastMonth2 = d2.month() + 1;
                        } else {
                            lastMonth2 = 12
                        }
                        let lastObj = { task: "Ongoing Standard Enablement", type: "No", startTime1: firstWaveEnd1, endTime1: lastElement.endTime1, startTime2: firstWaveEnd2, endTime2: lastElement.endTime2, supplierCount_Off: '', supplierCount_On: '', poSpend_Off: '', poSpend_On: '', invSpend_Off: '', invSpend_On: '', docCount_Off: '', docCount_On: '', anIdCount_Off: '', anIdCount_On: '' }
                        defaultArr.push(lastObj);
                        data.push(lastObj);
                        this.state.tasksChecked ? this.setState({ chartArr: data, cloneChartArr: data, isDefault: false }) : this.setState({ chartArr: defaultArr, cloneChartArr: defaultArr, isDefault: true })
                        this.setState({ taskArray: data, taskArray2: data, previousTaskArray: defaultArr, arrWithoutTasks: defaultArr, firstWaveEnd1: firstWaveEnd1, firstWaveEnd2: firstWaveEnd2, startYear: year, lastMonth1: lastMonth1, lastMonth2: lastMonth2 })
                    } else {
                        this.props.getResultLoading(false);
                        this.setState({ errorMessage: "There are 2 or fewer waves in this spend file and therefore the Gantt Chart will not render on this screen. Please use the Enablement Insights tab to see wave details.", errorFlag: true })
                    }

                } else {
                    this.props.getResultLoading(false);
                    this.setState({ errorMessage: "There are 2 or fewer waves in this spend file and therefore the Gantt Chart will not render on this screen. Please use the Enablement Insights tab to see wave details.", errorFlag: true })
                }
            }
            catch (e) {
                this.props.getResultFailure("An error has occurred");
            }
        })
            .catch((response) => {
                this.props.getResultFailure("Request data not found");
                this.setState({ isLoading: false })
            })
    }

    componentWillUnmount() {
        //reset the error
        this.props.getResultFailure("");
    }


    onDoubleClickGanntChart(e) {
        // console.log("Data after double click", e)
        if (e.type == 'No') {
            this.setState({ supplierData: null })
        } else {
            let dmcId = this.props.spendDetails.currentActiveRequest.DMC_ID;
            let vsID = this.props.spendDetails.currentActiveRequest.VS_ID;
            let userEmail = this.props.user.user.Email;
            let waveValue = e.type;
            let waveColName = 'ENABLEMENT_WAVE';
            let taskValue = e.task != '' ? e.task : null;
            let taskColName = 'GANTT_CHART_WORKSTREAM_NAME';

            let inputData = {
                DMC_ID: dmcId, VS_ID: vsID, USER_EMAIL: userEmail,
                FirstColumn_Name: waveColName, FirstColumn_Value: waveValue, SecondColumn_Name: taskColName, SecondColumn_Value: taskValue
            }

            this.props.getGanntChartSuppliersDetails(inputData)
                .then((response) => {
                    this.setState({ supplierData: response.data.Result, inputParam: inputData }, this.showModal("taskSuppliers"))
                }).catch((error) => {
                    if (error.stack.includes('Network Error')) {
                        this.setState({ responseMsg: 'API service is down. Please contact administrator', isError: 'Yes' }, this.showModal("taskSuppliers"))
                    }
                    else {
                        this.setState({ responseMsg: 'An error has occurred', isError: 'Yes' }, this.showModal("taskSuppliers"))
                    }
                })
        }
    }

    showModal(action) {
        this.refs[action].show()
    }

    hideModal(action) {
        this.refs[action].hide()
        if (this.state.valuesUpdated) {
            this.setState({ valuesUpdated: false }, this.loadData())
        }
        // this.props.close();
    }

    onSave(value) {
        this.setState({ valuesUpdated: value })
    }

    tasksToggled(event) {
        this.setState({ tasksChecked: !this.state.tasksChecked }, this.tasksDataLoad)
    }

    tasksDataLoad() {
        let year = this.state.startYear;
        //`12-31-${year}` 
        if (this.state.tasksChecked) {
            if (this.state.selectedValue == 12) {
                let chartArray = this.state.taskArray2;
                let lastElement = chartArray.slice(-1).pop();
                lastElement.endTime1 = `${year}-12-31`;
                lastElement.endTime2 = `${year}-12-31`;
                let tempArr = chartArray.filter(function (element) {
                    return element.endTime1.includes(year) && element.endTime2.includes(year)
                })
                this.setState({ chartArr: tempArr, cloneChartArr: this.state.taskArray2, isDefault: false })
            } else if (this.state.selectedValue == 24) {
                let chartArray = this.state.taskArray2;
                let lastElement = chartArray.slice(-1).pop();
                lastElement.endTime1 = `${Number(year) + 1}-12-31`;
                lastElement.endTime2 = `${Number(year) + 1}-12-31`;
                let tempArr = chartArray.filter(function (element) {
                    return (element.endTime1.includes(year) && element.endTime2.includes(year) || element.endTime1.includes(year) && element.endTime2.includes(`${Number(year) + 1}`) || element.endTime1.includes(`${Number(year) + 1}`) && element.endTime2.includes(`${Number(year) + 1}`))
                })
                this.setState({ chartArr: tempArr, cloneChartArr: this.state.taskArray2, isDefault: false })
            } else {
                let date = this.state.taskArray2[0].startTime1;
                let year = date.substring(0, 4);
                this.setState({ chartArr: this.state.taskArray2, cloneChartArr: this.state.taskArray2, startYear: year, isDefault: false })
            }

        } else {
            if (this.state.selectedValue == 12) {
                let chartArray = this.state.previousTaskArray;
                let lastElement = chartArray.slice(-1).pop();
                lastElement.endTime1 = `${year}-12-31`;
                lastElement.endTime2 = `${year}-12-31`;
                let tempArr = chartArray.filter(function (element) {
                    return element.endTime1.includes(year) && element.endTime2.includes(year)
                })
                this.setState({ chartArr: tempArr, cloneChartArr: this.state.previousTaskArray, isDefault: true })
            } else if (this.state.selectedValue == 24) {
                let chartArray = this.state.previousTaskArray;
                let lastElement = chartArray.slice(-1).pop();
                lastElement.endTime1 = `${Number(year) + 1}-12-31`;
                lastElement.endTime2 = `${Number(year) + 1}-12-31`;
                let tempArr = chartArray.filter(function (element) {
                    return (element.endTime1.includes(year) && element.endTime2.includes(year) || element.endTime1.includes(year) && element.endTime2.includes(`${Number(year) + 1}`) || element.endTime1.includes(`${Number(year) + 1}`) && element.endTime2.includes(`${Number(year) + 1}`))
                })
                this.setState({ chartArr: tempArr, cloneChartArr: this.state.previousTaskArray, isDefault: true })
            } else {
                let date = this.state.previousTaskArray[0].startTime1;
                let year = date.substring(0, 4);
                this.setState({ chartArr: this.state.previousTaskArray, cloneChartArr: this.state.previousTaskArray, startYear: year, isDefault: true })
            }
        }
    }

    toggleEfforts(event) {
        if (event.target.checked) {
            this.setState({ selectedEffort: 'Aggressive' })
        } else {
            this.setState({ selectedEffort: 'Conservative' })
        }

    }

    handleChange(data) {
        let year = this.state.startYear;
        if (data.value == 12) {
            let chartArray = this.state.chartArr;
            let lastElement = chartArray.slice(-1).pop();
            lastElement.endTime1 = `${year}-12-31`;
            lastElement.endTime2 = `${year}-12-31`;
            let tempArr = chartArray.filter(function (element) {
                return element.endTime1.includes(year) && element.endTime2.includes(year)
            })
            this.setState({ chartArr: tempArr })
        } else if (data.value == 24) {
            let chartArray = this.state.cloneChartArr;
            let lastElement = chartArray.slice(-1).pop();
            lastElement.endTime1 = `${Number(year) + 1}-12-31`;
            lastElement.endTime2 = `${Number(year) + 1}-12-31`;
            let tempArr = chartArray.filter(function (element) {
                return (element.endTime1.includes(year) && element.endTime2.includes(year) || element.endTime1.includes(year) && element.endTime2.includes(`${Number(year) + 1}`) || element.endTime1.includes(`${Number(year) + 1}`) && element.endTime2.includes(`${Number(year) + 1}`))
            })
            this.setState({ chartArr: tempArr })
        } else {
            let temp = this.state.cloneChartArr;
            let lastItem = temp.slice(-1).pop();
            let secondLastItem = temp.slice(-2, -1).pop();
            lastItem.endTime1 = secondLastItem.endTime1;
            lastItem.endTime2 = secondLastItem.endTime2;

            this.setState({ chartArr: this.state.cloneChartArr })
        }
        this.setState({ selectedValue: data.value })
    }

    render() {
        return (
            <div className='grid-x'>
                <div className="medium-12">
                    <div className="tab-title">{this.props.ganntTexts.title}{<VideoHelp screen={{ module: 'Spend', screenName: 'Gantt Chart' }} />}</div>
                    <div className="tab-description">{this.props.ganntTexts.tabDesc}</div>
                </div>
                <div className='errors'>{this.state.errorMessage}</div>
                {this.state.errorFlag ? <div /> :
                    <div className="medium-12">
                        <div className="grid-x">
                            <div className='medium-8'>
                                <label class="switch" title={this.props.ganntTexts.emButtonTitle}>
                                    <input type="checkbox" checked={this.state.tasksChecked} onChange={e => this.tasksToggled(e)} />
                                    <div class="slider round">
                                        <span class="on">{this.props.ganntTexts.emButtonLabel}</span>
                                        <span class="off">{this.props.ganntTexts.emButtonLabel}</span>
                                    </div>
                                </label>
                                <label class="switch two">
                                    <input type="checkbox" onChange={e => this.toggleEfforts(e)} />
                                    <div class="slider round">
                                        <span class="on">{this.props.ganntTexts.aggrButtonLabel}</span>
                                        <span class="off">{this.props.ganntTexts.concrButtonLabel}</span>
                                    </div>
                                </label>
                                <div className="switch left35">
                                    <Select
                                        className="border-with-DD dropDownContent"
                                        options={this.state.dropDownValues}
                                        value={this.state.dropDownValues.filter(
                                            option => { if (option.value === this.state.selectedValue) return (option.label) }
                                        )}
                                        onChange={value => this.handleChange(value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="medium-12">
                            <div className="fullWidth">
                                <GanntChart
                                    taskArray={this.state.chartArr}
                                    year={this.state.startYear}
                                    effort={this.state.selectedEffort}
                                    enablementOn={this.state.tasksChecked}
                                    legendList={this.state.legendList}
                                    firstWaveEnd1={this.state.firstWaveEnd1}
                                    firstWaveEnd2={this.state.firstWaveEnd2}
                                    lastMonth1={this.state.lastMonth1}
                                    lastMonth2={this.state.lastMonth2}
                                    enablementMethodsInOrder={this.state.tasksList}
                                    //arrWithoutTasks
                                    taskLength={this.state.chartArr.length}
                                    isDefault={this.state.isDefault}
                                    onDoubleClick={e => this.onDoubleClickGanntChart(e)}
                                    xAxisVal={this.state.selectedValue}
                                    currency={this.props.currencySymbols[this.props.spendDetails.currentActiveRequest.Currency !== undefined && this.props.spendDetails.currentActiveRequest.Currency.trim() !== '' && this.props.spendDetails.currentActiveRequest.Currency.trim() !== "0" ? this.props.spendDetails.currentActiveRequest.Currency.trim() : 'USD']}
                                />
                            </div>
                            <div>{this.props.ganntTexts.note}</div>
                            <div className="fullWidth">
                                {/* <div class="legend" style={{ paddingTop: this.state.height }}> */}
                                <div class="legend grid-x">
                                    {this.state.isDefault ? this.state.legendList2.map((val, index) => {
                                        return (<div class="legend1"> <p class="task-name"><span class={"key-dot" + " " + "dfTask" + index}></span>{val}</p> </div>)
                                    }) : this.state.legendList1.map((val, index) => {
                                        return (<div class="legend1"> <p class="task-name"><span class={"key-dot" + " " + "task" + index}></span>{val}</p> </div>)
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>}
                <div>
                    <Modal
                        ref="taskSuppliers"
                        // modalStyle={modalStyle}
                        // contentStyle={modalContentStyle}
                        modalStyle={changeModalBorderStyle}
                        contentStyle={drilldownModalBorderContentStyle}
                        rectStyle={changeModalBorderRectStyle}>
                        <div className="grid-x grid-padding-x change-popup">
                            <div className="medium-12">
                                <button onClick={() => this.hideModal("taskSuppliers")} className="close" />
                            </div>

                            <div className="medium-12">
                                {this.state.supplierData !== undefined && this.state.supplierData !== null ?
                                    <EnablementSupplierDetailsPopup
                                        input={this.state.inputParam}
                                        ganntSuppliersData={this.state.supplierData}
                                        isCallfromGanntChart={true}
                                        content={this.props.content}
                                        onSave={this.onSave}
                                    />
                                    : <div className="pt10">{this.props.content.noDataFound}</div>}
                            </div>
                        </div>
                    </Modal>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.default,
        content: state.content.spendAnalysis.defineScope,
        spendDetails: state.spendDetails,
        ganntTexts: state.content.ganntChartTab,
        currencySymbols: state.content.CurrencySymbols,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getResultSuccess: message => dispatch(getResultSuccess(message)),
        getResultFailure: error => dispatch(getResultFailure(error)),
        getResultLoading: bool => dispatch(getResultLoading(bool)),
        resetError: error => dispatch(resetError(error)),
        getGanntChartData: (DMC_ID, VS_ID, User_ID) => dispatch(getGanntChartData(DMC_ID, VS_ID, User_ID)),
        getGanntChartSuppliersDetails: (data) => dispatch(getGanntChartSuppliersDetails(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GanntChartContainer)


