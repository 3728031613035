import React, { Component } from 'react'

import { OutOfScopeReasonTabColumns } from 'Constants'
import CustomGrid from 'UI/CustomGrid'
import { connect } from 'react-redux'
import { Toolbar, Data, Filters, Editors, Menu } from "react-data-grid-addons";
import { path } from 'Constants'
import { ControlTextE, SelectE, CheckboxE, ScreenE, PopupE, CustomGridE } from 'UI/CustomComponents/Controls';
import EnablementInsightTabTable from './EnablementInsightTabTable'
import { changeModalBorderStyle, drilldownModalBorderContentStyle, changeModalBorderRectStyle } from 'Constants'
import Modal from 'reboron/OutlineModal'
import EnablementSupplierDetailsPopup from './EnablementSupplierDetailsPopup'

import {
    getEnablementInsightSuppliersDetails,
    updateFlightPlanningSupplierDetails,
    selectedEnablementWaveDetails,
    getCategoryInsightDetails,
    enablementInsightSuccess,
    enablementInsightFailure,
    enablementInsightLoading,    
    resetError
} from 'actions/spendActions'

import { formatter, getNonEmptyValue, binarySearchIndex } from "../../../utils/Utils";
import Button from 'UI/Button';

import TextfilterRenderer from 'UI/TextFilterRenderer';
import NumericFilterRenderer from 'UI/NumericFilterRenderer';
import AutoCompleteFilterAdv from 'UI/AutoCompleteFilterAdv';
import NumericEditor from 'UI/NumericEditor';
import EnablementWavePopUp from '../EnablementWavePopUp'
import TextEditor from 'UI/TextEditor';
import DivEditor from 'UI/DivEditor'

const { DropDownEditor } = Editors;
const dropdownValues = ["Calculated", "Override"];
const dropDownEditor = <DropDownEditor options={dropdownValues} />

const dropdownFinalSupplierValues = [
    'Already Transacting', 'Relationship Exists', 'Enterprise - Supplier Dedicated Specialist (Integration & Catalog)', "Enterprise - Integration & Catalog", "Enterprise - Integration", "Enterprise - Portal & Catalog", 'Enterprise - Portal', 'Managed Standard Account', 'On Going Standard Account', 'Out of Scope'
]
const dropDownFinalSupplierEditor = <DropDownEditor options={dropdownFinalSupplierValues} />

const dropdownOnboardingWorkstreamValues = [
    'Integration Questionnaire', 'Supplier Choice','Null']
const dropdownOnboardingWorkstreamEditor = <DropDownEditor options={dropdownOnboardingWorkstreamValues} />

class OutOfScopeReason extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {},
            columns: [],
            aggregatedData: { INVOICE_COUNT: 0, INVOICE_SPEND: 0.0, PO_COUNT: 0, PO_SPEND: 0.0, UNIQUE_SUPPLIERS: 0 },
            industryData: {},
            selectedIndustryName: '',
            isLoading: true,
            isError: 'No',
            responseMsg: '',
            openEnablementInsightPopUp: false,
            enablementWaveInputValue: null,
            enablementWaveOverrides: {},
            filteredRows: [],
            columnDefs: [],
            outOfScopeL4IndustryCategory: [],
            l4IndustryGrandTotal: [],
            l2IndustryCategory: [],
            l2IndustryGrandTotal: [],
            inputParam: {
                DMC_ID: this.props.spendDetails !== undefined && this.props.spendDetails.currentActiveRequest !== undefined ? this.props.spendDetails.currentActiveRequest.DMC_ID : 0
                , VS_ID: this.props.spendDetails !== undefined && this.props.spendDetails.currentActiveRequest !== undefined ? this.props.spendDetails.currentActiveRequest.VS_ID : 0,
                User_Email: this.props.spendDetails.currentActiveRequest !== undefined &&
                this.props.spendDetails.currentActiveRequest.SAPRequester === this.props.user.user.Email ? this.props.user.user.Email : this.props.spendDetails.currentActiveRequest.SAPRequester,
                dedupStats: 'Unique',
                enabledCustomer: 'all',
                transactingCustomer: 'all',
                buyerRegion: 'all',
                businessUnit: 'all',
                erpSystem: 'all',
                l4IndustryName: 'all',
                erpCode: 'all',
                erpDesc: 'all',
                sicCode: 'all',
                sicDesc: 'all',
                
                goods: 'all',
                invoiceCountry: 'all',
                legalInvoice: 'all',
                aribaNwEligible: 'all',
                directSpend: 'all',
                column1Name: '',
                column1Value: '',
                tableName: '',
                metricsType: 'Invoice Spend',
                onboardWorkStream:1,              
              }, 
        }

        this.customGridRef = React.createRef();

        this.loadData = this.loadData.bind(this)
        //this.save = this.save.bind(this)
        //this.validate = this.validate.bind(this)
        this.isCellEditable = this.isCellEditable.bind(this)
        this.closeEnablementInsightPopUp = this.closeEnablementInsightPopUp.bind(this)
        this.outsideEnablementInsightPopupClick = this.outsideEnablementInsightPopupClick.bind(this)
        this.enablementWaveInput = this.enablementWaveInput.bind(this);
        this.clearEnablementWaveOverride = this.clearEnablementWaveOverride.bind(this);
        this.GetCategoryInsightDetails = this.GetCategoryInsightDetails.bind(this);
        this.showEnablementSupplierDetailsModal = this.showEnablementSupplierDetailsModal.bind(this)
        this.setVisibility = this.setVisibility.bind(this)
        this.outsidePopupClick = this.outsidePopupClick.bind(this)
        this.onSave = this.onSave.bind(this)
        
    }

    showEnablementSupplierDetailsModal(tableName, columnName, columnValue) {

        var inputParameter = this.state.inputParam
        inputParameter.tableName = tableName
        inputParameter.column1Name = columnName
        inputParameter.column1Value = columnValue

        console.log("Column Details", columnName +" "+ columnValue)
		this.setState({	openPopup: true, inputParam: inputParameter	})
    }

    onSave(value) {
        this.setState({valuesUpdated: value})
    }

    setVisibility(isVisible) {
        this.handlePoupClose()   
    }

    outsidePopupClick() {        
        this.handlePoupClose()
    }
    
    handlePoupClose() {
        this.setState({  open: false, openPopup: false }, () => {
            if(this.state.valuesUpdated) {
                this.setState({valuesUpdated: false}, this.GetCategoryInsightDetails(this.state.inputParam))
            }
        })
    }

    GetCategoryInsightDetails(inputParam) {		
		this.props.getCategoryInsightDetails(inputParam)
			.then((response) => {
				if (response !== undefined && response.status === 200 && response.data.Result !== undefined) {
                
                var outOfScopeL4Data; var outOfScopeL4Total;
                var l2IndustryData; var l2IndustryTotal;
                    var tables = response.data.Result.map(
                        function (table) {                          

                            if(table['L2_INDUSTRY_NAME']!== undefined){
                                l2IndustryData = table['L2_INDUSTRY_NAME']
                                l2IndustryTotal=table['GrandTotal']
                            } 
                            
                            if(table['L4_INDUSTRY_NAME']!== undefined){
                                outOfScopeL4Data = table['L4_INDUSTRY_NAME']
                                outOfScopeL4Total = table['GrandTotal']
                            }
                        }
                    )
               
                this.setState({ 
                     outOfScopeL4IndustryCategory: outOfScopeL4Data, l4IndustryGrandTotal: outOfScopeL4Total, l2IndustryCategory: l2IndustryData,
                     l2IndustryGrandTotal: l2IndustryTotal, isLoading:false  })
                this.props.enablementInsightSuccess(true)
				}
			}).catch((error) => {
				if (error.stack.includes('Network Error')) {
                    this.setState({ responseMsg: 'API service is down. Please contact administrator', isError: 'Yes' })
					this.props.enablementInsightFailure('API service is down. Please contact administrator.')
				}
				else {
                    this.setState({ responseMsg: 'An error has occurred', isError: 'Yes' })
					this.props.enablementInsightFailure(this.props.content.error)
				}
			})
    }

    componentDidMount() {
        // if (this.props.isCallfromGanntChart) {
        //     let columns = this.initializeColumns()
        //     this.setState({ columns: columns, data: this.props.ganntSuppliersData })
        // } else {
            this.loadData(this.props.input);
        //}
    }
    
    componentWillReceiveProps(nextProps) {
        //     if (this.props.ganntSuppliersData != nextProps.ganntSuppliersData) {
        //         let columns = this.initializeColumns()
        //         this.setState({ columns: columns, data: nextProps.ganntSuppliersData, isLoading: false })
        //    }


        if (this.state.Test !== nextProps.filterData && 'dedupStats' in nextProps.filterData) {
            var inputParameter = this.state.inputParam
            inputParameter.dedupStats = nextProps.filterData.dedupStats
            inputParameter.enabledCustomer = nextProps.filterData.enabledCustomer
            inputParameter.transactingCustomer = nextProps.filterData.transactingCustomer
            inputParameter.buyerRegion = nextProps.filterData.buyerRegion
            inputParameter.businessUnit = nextProps.filterData.businessUnit
            inputParameter.erpSystem = nextProps.filterData.erpSystem
            inputParameter.l4IndustryName = nextProps.filterData.l4IndustryName
            inputParameter.erpCode = nextProps.filterData.erpCode
            inputParameter.erpDesc = nextProps.filterData.erpDesc
            inputParameter.sicCode = nextProps.filterData.sicCode
            inputParameter.sicDesc = nextProps.filterData.sicDesc
            inputParameter.goods = nextProps.filterData.goods
            inputParameter.invoiceCountry = nextProps.filterData.invoiceCountry
            inputParameter.legalInvoice = nextProps.filterData.legalInvoice
            inputParameter.aribaNwEligible = nextProps.filterData.aribaNwEligible
            inputParameter.metricsType = nextProps.filterData.metricsType
            inputParameter.directSpend = nextProps.filterData.directSpend            
            
            var inputParams = Object.keys(nextProps.filterData).length !== 0 ? {...nextProps.filterData} : {...this.state.inputParam};
            inputParams.onboardWorkStream = 1;
            
            this.GetCategoryInsightDetails(inputParameter)
            this.setState({ Test: nextProps.filterData, inputParam:inputParams })
            //console.log("if enablemtn details filetr props-")
        }
        else {
            //  console.log("else enablemtn details filetr props-")
        }

    }

    loadData(inputData) {
        // let columns = this.initializeColumns()

        // this.props.getEnablementInsightSuppliersDetails(inputData)
        //     .then((response) => {
        //         this.setState({ columns: columns, data: response.data.Result, isLoading: false })
        //     }).catch((error) => {
        //         if (error.stack.includes('Network Error')) {
        //             this.setState({ responseMsg: 'API service is down. Please contact administrator', isError: 'Yes' })
        //         }
        //         else {
        //             this.setState({ responseMsg: 'An error has occurred', isError: 'Yes' })
        //         }
        //     })
        this.GetCategoryInsightDetails(this.state.inputParam);
    }

    initializeColumns() {
        let columns = []
        let _self = this
        for (let index = 0; index < OutOfScopeReasonTabColumns.length; index++) {
            const fieldConfig = OutOfScopeReasonTabColumns[index];

            let tableColumn = {
                name: fieldConfig.screen_field_name,
                key: fieldConfig.field,
                width: 150,
                sortable: true,
                editable: fieldConfig.is_editable === 'Yes',
                resizable: true,
                filterable: true,
                screenSeq: fieldConfig.screen_seq,
                mandatory: fieldConfig.mandatory === 'Yes',
                exportFl: fieldConfig.export_fl === 'Yes',
                helpText: fieldConfig.screen_help_text
            }

            if (fieldConfig.filter_type !== 'default') {
                tableColumn['filterRenderer'] = fieldConfig.data_type === 'text' ? AutoCompleteFilterAdv : NumericFilterRenderer
            } else {
                tableColumn['filterRenderer'] = TextfilterRenderer
            }

            // if (fieldConfig.field == 'SUPPLIER_ID') {
            //     tableColumn["frozen"] = true
            // }
            
            // if (fieldConfig.field == 'SUPPLIER_NAME') {
            //     tableColumn["frozen"] = true
            // }

            if (fieldConfig.field == 'SAP_ARIBA_DOC_PROXY') {
                tableColumn["editor"] = NumericEditor
            }

            if (fieldConfig.field == 'SAP_ARIBA_SPEND_PROXY') {
                tableColumn["editor"] = NumericEditor
            }

            if (fieldConfig.field == 'FINAL_SUPPLIER_ENABLE_TYPE') {
                tableColumn["editor"] = dropDownFinalSupplierEditor
            }

            if (fieldConfig.field == 'ONBOARDING_WORKSTREAM_DETAILS') {
                tableColumn["editor"] = dropdownOnboardingWorkstreamEditor
            }            
            
            if (fieldConfig.field == 'ENABLEMENT_RECOMMENDATION_OVERRIDE') {
                tableColumn["editor"] = dropDownEditor
            }
            
            if (fieldConfig.field == 'ENABLEMENT_WAVE_OVERRIDE') {
                tableColumn["editor"] = dropDownEditor
            }

            if (fieldConfig.field == 'BUYER_SUPPLIER_RELATIONSHIP') {
                tableColumn["editor"] = TextEditor
                tableColumn["max"] = 50
            }

            if (fieldConfig.field == 'ENABLEMENT_WAVE') {
                tableColumn.editProperty = 'Yes'
                //tableColumn.editor = DivEditor
                tableColumn.events = {
                    onDoubleClick: function (ev, args) {
                        _self.setState({ openEnablementInsightPopUp: true, enablementWaveInputRowNo: args.rowIdx, enablementWaveInputValue: ev.target.innerText })
                    }
                }
            }



            tableColumn.editable = tableColumn.editable && !this.props.readOnly

            tableColumn['formatter'] = formatter(fieldConfig.alignment)

            columns.push(tableColumn)
        }

        this.setState({ columnDefs: columns })
        return columns;
    }

    enablementWaveInput() {
        if (this.props.enablementWaveInputDetails !== undefined && this.props.enablementWaveInputDetails !== null) {
            this.customGridRef.onGridRowsUpdated({ fromRow: this.state.enablementWaveInputRowNo, toRow: this.state.enablementWaveInputRowNo, updated: { 'ENABLEMENT_WAVE': this.props.enablementWaveInputDetails == '' || this.props.enablementWaveInputDetails == 0 ? 1 : this.props.enablementWaveInputDetails } })
            this.props.selectedEnablementWaveDetails(null)//Clear the previous selection
            this.setState({ openEnablementInsightPopUp: !this.state.openEnablementInsightPopUp })
        }
    }

    clearEnablementWaveOverride() {
        this.customGridRef.onGridRowsUpdated({ fromRow: this.state.enablementWaveInputRowNo, toRow: this.state.enablementWaveInputRowNo, updated: { 'ENABLEMENT_WAVE': '' } })
        this.setState({ openEnablementInsightPopUp: !this.state.openEnablementInsightPopUp })
    }

    closeEnablementInsightPopUp() {
        this.setState({ openEnablementInsightPopUp: !this.state.openEnablementInsightPopUp })
    }

    outsideEnablementInsightPopupClick() {
        this.setState({ openEnablementInsightPopUp: false })
    }

    isCellEditable(e) {

        if (e.column.key === 'ENABLEMENT_WAVE' && this.state.openEnablementInsightPopUp)
            return false;

    }
   
    // save(records) {
    //     const recordsToUpdate = [];
    //     const enablementWaveOverrides = this.state.enablementWaveOverrides

    //     for (let idx = 0; idx < records.length; idx++) {
    //         const row = records[idx]
    //         // const enablementWaveRecord = enablementWaveOverrides[item]
    //         const record = {
    //             DMC_ID: this.props.spendDetails.currentActiveRequest.DMC_ID,
    //             VS_ID: this.props.spendDetails.currentActiveRequest.VS_ID,
    //             USER_ID: this.props.user.user.Email,
    //             DATA_COLLECTIONS_SKEY: row["DATA_COLLECTIONS_SKEY"],
    //             BUYER_SUPPLIER_RELATIONSHIP: row["BUYER_SUPPLIER_RELATIONSHIP"],
    //             SAP_ARIBA_DOC_PROXY: row["SAP_ARIBA_DOC_PROXY"],
    //             SAP_ARIBA_SPEND_PROXY: row["SAP_ARIBA_SPEND_PROXY"],
    //             SAP_ARIBA_BUY_ENABLE_MTHD_RECMD: row["FINAL_SUPPLIER_ENABLE_TYPE"],
    //             ENABLEMENT_WAVE: row["ENABLEMENT_WAVE"],
    //             ENABLEMENT_RECOMMENDATION_OVERRIDE: row["ENABLEMENT_RECOMMENDATION_OVERRIDE"],
    //             ENABLEMENT_WAVE_OVERRIDE: row["ENABLEMENT_WAVE_OVERRIDE"],
    //             ONBOARDING_WORKSTREAM_DETAILS: row["ONBOARDING_WORKSTREAM_DETAILS"] == "Null" ? "": row["ONBOARDING_WORKSTREAM_DETAILS"]
    //         }

    //         recordsToUpdate.push(record);
    //     }
    //     this.props.onSave(true)
    //     return this.props.updateFlightPlanningSupplierDetails(recordsToUpdate);
    // }


    // validateRow(records) {
    //     var errorRecords = []
    //     const columns = this.state.columns

    //     for (let idx = 0; idx < records.length; idx++) {
    //         const row = records[idx]
    //         for (let idx = 0; idx < columns.length; idx++) {
    //             const column = columns[idx]
    //             if (getNonEmptyValue(row[column.key]) === '' && column.mandatory) {
    //                 errorRecords.push(row)
    //                 break;
    //             }
    //         }

    //     }

    //     return errorRecords;
    // }

    // validate(record, warningErrorRecords) {
    //     const columns = this.state.columns

    //     for (let idx = 0; idx < columns.length; idx++) {
    //         const column = columns[idx]
    //         if (getNonEmptyValue(record[column.key]) === '' && column.mandatory) {
    //             this.addWarningErroredRecord('Mandatory Field ' + column.name + ' missing', record, warningErrorRecords.errorRecords, true)
    //         } else {
    //             this.addWarningErroredRecord('Mandatory Field ' + column.name + ' missing', record, warningErrorRecords.errorRecords, false)
    //         }
    //     }

    //     return warningErrorRecords
    // }

    // addWarningErroredRecord(reason, record, records, add) {
    //     var arr = records[reason];
    //     if (getNonEmptyValue(arr) === '') {
    //         arr = []
    //     }

    //     var eltIdx = arr.indexOf((record['DATA_COLLECTIONS_SKEY']));
    //     if (eltIdx === -1 && add) {
    //         arr.push(record['DATA_COLLECTIONS_SKEY'])
    //     } else if (eltIdx !== -1 && !add) {
    //         arr.splice(eltIdx, 1)
    //     }

    //     records[reason] = arr;
    // }

    render() {
        return (
            // <div className="medium-12">
            //      <div className="grid-x grid-padding-x">
            //         <div className="medium-12">
            //             {this.props.isCallfromGanntChart ? null : (this.state.isLoading) ?
            //                 <img style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={path + `images/ajax-loader.gif`} alt="" />
            //                 : null
            //             }
            //             {(this.state.isError === 'Yes') ?
            //                 <div style={{ color: 'red', textAlign: 'center' }} className="cell pt5">{this.state.responseMsg}</div>
            //                 : <div style={{ color: 'green', textAlign: 'center' }} className="cell pt5">{this.state.responseMsg}</div>
            //             }
            //         </div>
            //     </div>                

            //     <CustomGridE
            //         onRef={ref => (this.customGridRef = ref)}
            //         columns={this.state.columns}
            //         data={this.state.data}
            //         keyColumn="DATA_COLLECTIONS_SKEY"
            //         save={this.save}
            //         validate={this.validate}
            //         export={true}
            //         exportFileName={"Supplier Details"}
            //         isCellEditable={this.isCellEditable}
            //         currentFilter={true}     
            //     />

            //     <div className="medium-1 large-1">
            //         <PopupE className="override-large-popup2"
            //             position="right center"
            //             open={this.state.openEnablementInsightPopUp}
            //             onClose={this.outsideEnablementInsightPopupClick}
            //             closeOnDocumentClick
            //         >
            //             <div>
            //                 <EnablementWavePopUp
            //                     enablementWaveInputValue={this.state.enablementWaveInputValue}
            //                     close={this.closeEnablementInsightPopUp}
            //                 />
            //             </div>
            //             <div className="popupOkBtn">
            //                 <Button onClick={this.enablementWaveInput} float type="submit" content={this.props.ui.ok} />
            //                 <Button onClick={this.clearEnablementWaveOverride} type="submit" content={this.props.ui.clearOverride} />
            //             </div>
            //         </PopupE>
            //     </div>
            // </div>

            <div className="medium-12 marginTop">
                        <div className="grid-x">                            
                              
                                <div className="grid-x">  
                                <div className="medium-12">                                  
                                    {this.state.l2IndustryCategory !== undefined && this.state.l2IndustryCategory !== null ?
                                        <EnablementInsightTabTable
                                            data={this.state.l2IndustryCategory}
                                            grandTotal={this.state.l2IndustryGrandTotal}
                                            content={this.props.content}
                                            tableName={this.props.content.outOfScopeCategory.l2IndustryCategoryHeader}
                                            tableSequence={"28"}
                                            showEnablementSupplierDetailsModal={this.showEnablementSupplierDetailsModal}
                                            metricsType= {this.state.inputParam.metricsType}
                                        />
                                        : <div className="pt10">{this.props.content.noDataFound}</div>}
                                        </div>
                                </div> 

                            {/* </div>
                            <div className="medium-6" style={{paddingLeft: '25px'}}>                                */}  
                             
                               
                                <div className="grid-x">
                                <div className="medium-12">
                                    {this.state.outOfScopeL4IndustryCategory !== undefined && this.state.outOfScopeL4IndustryCategory !== null ?
                                        <EnablementInsightTabTable
                                            data={this.state.outOfScopeL4IndustryCategory}
                                            grandTotal={this.state.l4IndustryGrandTotal}
                                            content={this.props.content}
                                            tableName={this.props.content.outOfScopeCategory.l4IndustryCategoryHeader}
                                            tableSequence={"28"}
                                            showEnablementSupplierDetailsModal={this.showEnablementSupplierDetailsModal}
                                            metricsType= {this.state.inputParam.metricsType}
                                        />
                                        : <div className="pt10">{this.props.content.noDataFound}</div>}
                                        </div>
                                </div>
                        </div>

                        <div className="medium-12 marginTop">

                        <div>  
                  {this.refs.enablementSupplier ? this.state.openPopup ?
                    this.refs.enablementSupplier.show() :
                    this.refs.enablementSupplier.hide() : ''}        
                  <Modal
                    ref={'enablementSupplier'}
                    modalStyle={changeModalBorderStyle}
                    contentStyle={drilldownModalBorderContentStyle}
                    rectStyle={changeModalBorderRectStyle}                   
                    keyboard={false}
                    backdrop={false}
                   >
                    <div className="grid-x tabs_container supplier-tabs">                       
                        <div className="medium-12">                        
                            {
                                <EnablementSupplierDetailsPopup onSave={this.onSave} input={this.state.inputParam} outsidePopupClick={this.outsidePopupClick} ></EnablementSupplierDetailsPopup>
                            }
                        </div>
                    </div>
                    <button onClick={() => { this.setVisibility(false); this.setState({ selectedIndustryName: {} }) }} className='close' />
                </Modal>
                  </div>
                    
                    {/* <div><EnablementSupplierPopup open={this.state.openPopup} visibility={this.setVisibility} outsidePopupClick={this.outsidePopupClick} ></EnablementSupplierPopup></div> */}
            </div>                       
                    </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        content: state.content.enablementInsight,
        ui: state.content.ui,
        currency: decodeURIComponent(state.valueStory.Currency),
        userData: state.valueStory,
        spendAsPercentageOfRevenue: state.spendAsPercentageOfRevenue,
        benchMarkCalculations: state.benchMarksData,
        allContent: state.content,
        language: state.language,
        spendDetails: state.spendDetails,
        user: state.default,
        enablementWaveInputDetails: state.spendDetails.enablementWaveInputDetails,
        ganntTexts: state.content.ganntChartTab
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getEnablementInsightSuppliersDetails: (data) => dispatch(getEnablementInsightSuppliersDetails(data)),
        updateFlightPlanningSupplierDetails: (data) => dispatch(updateFlightPlanningSupplierDetails(data)),
        selectedEnablementWaveDetails: message => dispatch(selectedEnablementWaveDetails(message)),        
        getCategoryInsightDetails: (data) => dispatch(getCategoryInsightDetails(data)),
        enablementInsightSuccess: message => dispatch(enablementInsightSuccess(message)),
        enablementInsightFailure: error => dispatch(enablementInsightFailure(error)),
        enablementInsightLoading: bool => dispatch(enablementInsightLoading(bool)),
        resetError: error => dispatch(resetError(error)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OutOfScopeReason); 