import React from 'react'
import { connect } from 'react-redux'
import Button from 'UI/Button'
import { Form } from 'react-redux-form'
import { Link } from 'react-router-dom'
// Actions
import {
	updateConsent,
	userConsentSuccess,
	userConsentFailure,
	resetError
} from 'actions/userActions'

import { modalStyle, modalContentStyle, routes, validators } from 'Constants'

// Utils
import { Checkbox } from 'material-ui';

var aStyle ={
	color: 'blue'
}

class Terms extends React.Component {
	constructor(props) 
  {
    super(props);
    this.state = {
		chkbox: false
	}
	this.isChecked = this.isChecked.bind(this);
	this.check = this.check.bind(this);
	this.redirect = this.redirect.bind(this)
  }

  isChecked(formValue){
		if(this.state.chkbox){
			const value = {
				UserID: localStorage.getItem('id'),
				Accepted: true
			}
			this.props.updateConsent(value)
			.then((result) => {
				this.props.userConsentSuccess(result) 
				if(!localStorage.getItem('loggedinUser').includes('sap.com') && sessionStorage.getItem('isCustomer') !== 'true')
			    {
				this.props.checkNewsLetterAcceptance()
				}else{
				this.redirect()
				}
			}).catch((error) => {
					 this.props.userConsentFailure(error.response.data.Message)
			})
		}
		else{
			this.props.userConsentFailure("Please select the checkbox")
		}
	}

	redirect() {
		if (localStorage.getItem('role') !== null && localStorage.getItem('role').toUpperCase() === ('TRANSLATOR')) {
				
				this.props.props.push({
					pathname: '/language-manager', validation: this.props.validation
	
				})
			}
			else {
				this.props.props.push(`/start`)
			}
		}

	check(e){
		if(e.target.checked){
			this.setState({chkbox: true})
		}
		else{
			this.setState({chkbox: false})
		}
	}

	render(){
		return(
		<div className="popup-content">
			<div className="grid-x pt20 pl20 pr20">
				<div className="medium-12 cell">
					<div className="title">
					    <div dangerouslySetInnerHTML={{__html: this.props.content.title}}></div> 
					</div>					
				</div>
			<div className="description">
				<Form model="terms" method="post" 
                getDispatch={(dispatch) => this.formDispatch = dispatch}
                onSubmit={(formValue) => this.isChecked(formValue)}>
					
					{/* <p>Access to this system is only for active SAP sales and consulting partners who have been authorized for use.  This system contains proprietary and confidential information of SAP and access to this system may be revoked by SAP at anytime.</p> */}
					
					<div dangerouslySetInnerHTML={{__html: this.props.content.header1}}></div> 
                  <br></br>
                  <div dangerouslySetInnerHTML={{__html: this.props.content.header1Desc}}></div> 
                  <br></br>

                  <div dangerouslySetInnerHTML={{__html: this.props.content.header2}}></div> 
                  <br></br>
                  <div dangerouslySetInnerHTML={{__html: this.props.content.header2Desc.para1}}></div> 
                  <br></br>
                  <div dangerouslySetInnerHTML={{__html: this.props.content.header2Desc.para2}}></div> 
                  <br></br>
                  <div dangerouslySetInnerHTML={{__html: this.props.content.header2Desc.para3}}></div> 
                  <br></br>
                  <div dangerouslySetInnerHTML={{__html: this.props.content.header2Desc.para4}}></div> 
                  <br></br>

                  <div dangerouslySetInnerHTML={{__html: this.props.content.header3}}></div> 
                  <br></br>
                  <div dangerouslySetInnerHTML={{__html: this.props.content.header3Desc.para1}}></div> 
                  <br></br>
                  <div dangerouslySetInnerHTML={{__html: this.props.content.header3Desc.para2}}></div> 
                  <br></br>

                  <div dangerouslySetInnerHTML={{__html: this.props.content.header4}}></div> 
                  <br></br>
                  <div dangerouslySetInnerHTML={{__html: this.props.content.header4Desc.para1}}></div> 
                  <br></br>

                  <div dangerouslySetInnerHTML={{__html: this.props.content.header5}}></div> 
                  <br></br>
                  <div dangerouslySetInnerHTML={{__html: this.props.content.header5Desc.para1}}></div> 
                  <br></br>
                  <div dangerouslySetInnerHTML={{__html: this.props.content.header5Desc.para2}}></div> 
                  <br></br>
                  <div dangerouslySetInnerHTML={{__html: this.props.content.header5Desc.para3}}></div> 
                  <br></br>
                  <div dangerouslySetInnerHTML={{__html: this.props.content.header5Desc.para4}}></div> 
                  <br></br>

                  <div dangerouslySetInnerHTML={{__html: this.props.content.header6}}></div> 
                  <br></br>
                  <div dangerouslySetInnerHTML={{__html: this.props.content.header6Desc.para1}}></div> 
                  <br></br>
                  <div dangerouslySetInnerHTML={{__html: this.props.content.header6Desc.para2}}></div> 
                  <br></br>

                  <div dangerouslySetInnerHTML={{__html: this.props.content.header7}}></div> 
                  <br></br>
                  <div dangerouslySetInnerHTML={{__html: this.props.content.header7Desc.para1}}></div> 
                  <br></br>

                  <div dangerouslySetInnerHTML={{__html: this.props.content.header8}}></div> 
                  <br></br>
                  <div dangerouslySetInnerHTML={{__html: this.props.content.header8Desc.para1}}></div> 
                  <br></br>
                  <div dangerouslySetInnerHTML={{__html: this.props.content.header8Desc.para2}}></div> 
                  <br></br>

                  <div dangerouslySetInnerHTML={{__html: this.props.content.header9}}></div> 
                  <br></br>
                  <div dangerouslySetInnerHTML={{__html: this.props.content.header9Desc.para1}}></div> 
                  <br></br>
                  <div dangerouslySetInnerHTML={{__html: this.props.content.header9Desc.para2}}></div> 
                  <br></br>
                  <div dangerouslySetInnerHTML={{__html: this.props.content.header9Desc.para3}}></div> 
                  <br></br>

                  <div dangerouslySetInnerHTML={{__html: this.props.content.header10}}></div> 
                  <br></br>
                  <div dangerouslySetInnerHTML={{__html: this.props.content.header10Desc.para1}}></div> 
                  <br></br>
                  <div dangerouslySetInnerHTML={{__html: this.props.content.header10Desc.para2}}></div> 
                  <br></br>
                  <div dangerouslySetInnerHTML={{__html: this.props.content.header10Desc.para3.header}}></div> 
                  <br></br>
				  
				  <div>
					  <ul style={{paddingLeft:"20px"}}>
					   <li style={{listStyle:'disc'}}><div dangerouslySetInnerHTML={{__html: this.props.content.header10Desc.para3.subHeader1}}></div></li>
                  
			     	   <li style={{listStyle:'disc'}}><div dangerouslySetInnerHTML={{__html: this.props.content.header10Desc.para3.subHeader2}}></div></li>
                  
				       <li style={{listStyle:'disc'}}><div dangerouslySetInnerHTML={{__html: this.props.content.header10Desc.para3.subHeader3}}></div></li>
                  
				       <li style={{listStyle:'disc'}}><div dangerouslySetInnerHTML={{__html: this.props.content.header10Desc.para3.subHeader4}}></div></li>
                  
				       <li style={{listStyle:'disc'}}><div dangerouslySetInnerHTML={{__html: this.props.content.header10Desc.para3.subHeader5}}></div></li>
                  
				       <li style={{listStyle:'disc'}}><div dangerouslySetInnerHTML={{__html: this.props.content.header10Desc.para3.subHeader6}}></div></li>
                  
				       <li style={{listStyle:'disc'}}><div dangerouslySetInnerHTML={{__html: this.props.content.header10Desc.para3.subHeader7}}></div></li>
					  </ul>
				  </div>
				  <br></br>
				 

                  <div dangerouslySetInnerHTML={{__html: this.props.content.header11}}></div> 
                  <br></br>
                  <div dangerouslySetInnerHTML={{__html: this.props.content.header11Desc.para1}}></div> 
                  <br></br>

                  <div dangerouslySetInnerHTML={{__html: this.props.content.header12}}></div> 
                  <br></br>
                  <div dangerouslySetInnerHTML={{__html: this.props.content.header12Desc.para1}}></div> 
                  <br></br>
                  <div dangerouslySetInnerHTML={{__html: this.props.content.header12Desc.para2}}></div> 
                  <br></br>

                  <div dangerouslySetInnerHTML={{__html: this.props.content.header13}}></div> 
                  <br></br>
                  <div dangerouslySetInnerHTML={{__html: this.props.content.header13Desc.para1}}></div> 
                  <br></br>

                  <div dangerouslySetInnerHTML={{__html: this.props.content.header14}}></div> 
                  <br></br>
                  <div dangerouslySetInnerHTML={{__html: this.props.content.header14Desc.para1}}></div> 
                  <br></br>

                  <div dangerouslySetInnerHTML={{__html: this.props.content.header15}}></div> 
                  <br></br>
                  <div dangerouslySetInnerHTML={{__html: this.props.content.header15Desc.para1}}></div> 
                  <br></br>

                  <div dangerouslySetInnerHTML={{__html: this.props.content.header16}}></div> 
                  <br></br>
                  <div dangerouslySetInnerHTML={{__html: this.props.content.header16Desc.para1}}></div> 
                  <br></br>
					
					{ this.props.buttonVisible !== undefined && this.props.buttonVisible ?
					<div className="grid-x grid-padding-x pt13" >
					<div style={{paddingTop: '5px'}}>
					   <Checkbox  onClick = {(e) =>this.check(e)}/>
					</div>
					<div className="medium-11 cell">
					<p><span style={{color:'red'}}>* </span>I have read and agree to the Terms of Use for SAP Ariba Connect, <a href="https://www.sap.com/about/legal/copyright.html" target="_blank" style = {aStyle}>Copyright</a> and processing of my personal data according to the <a href="https://www.ariba.com/legal/ariba-privacy-statement-05-18-2018" target="_blank" style = {aStyle}>SAP Ariba Privacy Statement</a></p>
					</div>
					<div className="medium-12 cell"></div>
					<div className="medium-2 cell pt13" >
                       <Button 
                       	type="submit" 
			            	content="I Agree"
                         color 
         	             	/>
       		            </div>
					 </div>
					 : null
					}
				</Form>
			</div>
			</div>
			<div className="errors">{this.props.user.error}</div>
		</div>
		)
	}
	
}

const mapStateToProps = (state) => {
	return {
		content: state.content.TermsAndConditions,
		validation: state.content.validation,
		user: state.default
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		updateConsent: (formValues) => dispatch(updateConsent(formValues)),
		userConsentSuccess: (user) => dispatch(userConsentSuccess(user)),
		userConsentFailure: (error) => dispatch(userConsentFailure(error)),
		resetError: () => dispatch(resetError())
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Terms)


