import React, { Fragment } from 'react';
import { connect } from 'react-redux'
import { formatThousands, convertToMillion } from 'utils/Utils'
import Popup from "reactjs-popup";
import Button from 'UI/Button';
import { path } from 'Constants'
import IndustryOverridePopUp from './IndustryOverridePopUp'
import { selectedIndustryDetails, trackSupplierDrillDownChanges, trackSupplierDrillDownSave } from 'actions/spendActions'
import TreeList from './TreeList'
import classNames from "classnames";
import {
    getInputAndClassificationData,
    getInputAndClassificationDataLoading,
    getInputAndClassificationDataSuccess,
    getInputAndClassificationDataFailure,
    storeVendorDetails,
    storeFinalIndustryID,
    storeInputAndClassificationDetails,
    supplierDrillDownUpdate,
    setPopUpTabLoadingStatus
} from 'actions/spendActions'

import { PopupE } from 'UI/CustomComponents/Controls';

class InputandClassification extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            classificationData: [],
            originalOverrideIndustryID: null,
            overrideIndustryID: null,
            hierarchyPathTree: [],
            expandedKeys: {},
            isCapIQLicesend: false,
            isError: 'No',
            isLoading: true,
            responseMsg: '',
            isSaveInProgress: false
        }
        this.LoadData = this.LoadData.bind(this)
        this.outsidePopupClick = this.outsidePopupClick.bind(this)
        this.togglePopUp = this.togglePopUp.bind(this)
        this.industryOverride = this.industryOverride.bind(this)
        this.clearIndustryOverride = this.clearIndustryOverride.bind(this)
        this.handleSave = this.handleSave.bind(this)
        this.getNestedChildren = this.getNestedChildren.bind(this)
        //this.getWaterFallData = this.getWaterFallData.bind(this)
    }

    LoadData() {
        var self = this;
        this.props.setPopUpTabLoadingStatus(true);
        this.props.getInputAndClassificationData(this.props.spendDetails.currentActiveRequest.DMC_ID, this.props.spendDetails.currentActiveRequest.VS_ID, this.props.user.user.Email, this.props.spendDetails.supplierDetails["SKEY"])
            .then((response) => {
                if (response.status === 200) {
                    this.props.setPopUpTabLoadingStatus(false);
                    if (response.data.Result.length > 0) {
                        var addressDetails = response.data.Result[0]["VENDOR_ADDRESS"].split("|").map(item => item.trim());
                        var address = addressDetails.filter(Boolean).join(' | ');
                        var vendorDetails = { vendorName: response.data.Result[0]["VENDOR_NAME"], vendorAddress: address, scopeSource: response.data.Result[0]["SCOPE_SOURCE"], overrideIndustryID: response.data.Result[0]["OVERRIDE_INDUSTRY_ID"] }
                        this.props.storeVendorDetails(vendorDetails)
                        this.props.storeFinalIndustryID(response.data.Result[0]["FINAL_INDUSTRY_ID"])
                        this.props.storeInputAndClassificationDetails(response.data.Result[0])

                        var hierarchyTree = []
                        var hierarcyPath = response.data.Result[0]["FINAL_INDUSTRY_DESCRIPTION_PATH"].split(' | ')
                        hierarcyPath.map(function (item, index) {
                            var obj = { 'key': index + 1, 'label': item, 'parent': index === 0 ? null : index }
                            hierarchyTree.push(obj)
                        })
                        var objectData = []
                        try {
                            objectData = this.getNestedChildren(hierarchyTree, null)//Start with null as parent
                        }
                        catch (e) { }
                        let expandedKeys = { ...this.state.expandedKeys };
                        hierarchyTree.map(function (item) {
                            expandedKeys[item.key] = true
                        });
                        this.setState({
                            classificationData: response.data.Result[0], hierarchyPathTree: objectData, expandedKeys: expandedKeys,
                            isLoading: false, isError: 'No', responseMsg: '', overrideIndustryID: response.data.Result[0]["OVERRIDE_INDUSTRY_ID"],
                            originalOverrideIndustryID: response.data.Result[0]["OVERRIDE_INDUSTRY_ID"],
                            isCapIQLicesend: self.props.user.user.applicationFeatures.findIndex(a => a.Action === 'CapIQLinkAccess') > -1 ? true : false
                        })
                    }
                    else {
                        this.setState({
                            isLoading: false, isError: 'Yes', responseMsg: this.props.content.noData
                        })
                    }
                }
                else {
                    this.props.setPopUpTabLoadingStatus(false)
                    this.setState({ isError: 'Yes', isLoading: 'false', responseMsg: this.props.content.error })
                }
            }).catch((error) => {
                this.props.setPopUpTabLoadingStatus(false)
                this.setState({ isError: 'Yes', isLoading: 'false', responseMsg: this.props.content.error })
            })
    }

    togglePopUp() {
        this.setState({ open: !this.state.open })
    }

    outsidePopupClick() {
        this.setState({ open: false })
    }

    industryOverride() {
        if (this.props.overridedIndustryDetails !== undefined && this.props.overridedIndustryDetails !== null
            && this.props.overridedIndustryDetails.INDUSTRY_ID !== undefined && this.props.overridedIndustryDetails.INDUSTRY_ID !== null) {
            this.setState({ overrideIndustryID: this.props.overridedIndustryDetails.INDUSTRY_ID })
            if (this.props.overridedIndustryDetails.INDUSTRY_ID !== this.state.originalOverrideIndustryID)
                this.props.trackSupplierDrillDownChanges(true)
            else
                this.props.trackSupplierDrillDownChanges(false)
            this.props.selectedIndustryDetails(null)//Clear the previous selection
            this.togglePopUp();
        }
    }

    clearIndustryOverride() {
        if (this.state.originalOverrideIndustryID !== null)
            this.props.trackSupplierDrillDownChanges(true)
        else
            this.props.trackSupplierDrillDownChanges(false)
        this.setState({ overrideIndustryID: null, open: false })
    }

    handleSave() {
        if (this.state.overrideIndustryID !== this.state.originalOverrideIndustryID) {
            this.setState({ responseMsg: '', isError: 'No', isLoading: true, isSaveInProgress: true })
            const record = {
                VS_ID: this.props.spendDetails.currentActiveRequest.VS_ID,
                DMC_ID: this.props.spendDetails.currentActiveRequest.DMC_ID,
                DATA_COLLECTIONS_SKEY: this.props.spendDetails.supplierDetails["SKEY"],
                USER_ID: this.props.user.user.Email,
                FLAG_SOLUTION: "No",
                FLAG_INDUSTRY: "Yes",
                FLAG_INP: "No",
                FLAG_CONFIRMED: "No",
                SCOPE_SOURCE: this.props.spendDetails.vendorDetails["scopeSource"],
                OVERRIDE_GICS: this.state.overrideIndustryID,
                CONFIRMED: null,
                EXCLUSION_REASON: null,
                GOODS_OR_SERVICE: null,
                DIRECT_SPEND: null,
                SAP_ARIBA_BUY_PROCESS: null,
                SOURCING_ELIGIBLE: null,
                BUYING_INV_ELIGIBLE: null,
                REQUISITION_ABLE: null,
                REQUISITION_TYPE: null,
                CONTRACT_INV_ELIGIBLE: null,
                COMMERCE_AUTOMATION_ELIGIBLE: null,
                SUPP_CHAIN_COLLAB_ELIGIBLE: null,
                PAYABLES_ELIGIBLE: null,
                SAP_FIELDGLASS_ELIGIBLE: null,
                SAP_CONCUR_ELIGIBLE: null,
                REQUEST_NAME: "InputAndClassification_Tab"
            };
            this.props.setPopUpTabLoadingStatus(true);
            this.props.supplierDrillDownUpdate(record)
                .then((response) => {
                    if (response.status == 200) {
                        this.props.setPopUpTabLoadingStatus(false);
                        if (response.data.Result.length > 0) {
                            var addressDetails = response.data.Result[0]["VENDOR_ADDRESS"].split("|").map(item => item.trim());
                            var address = addressDetails.filter(Boolean).join(' | ');
                            var vendorDetails = { vendorName: response.data.Result[0]["VENDOR_NAME"], vendorAddress: address, scopeSource: response.data.Result[0]["SCOPE_SOURCE"], overrideIndustryID: response.data.Result[0]["OVERRIDE_INDUSTRY_ID"] }
                            this.props.storeVendorDetails(vendorDetails)
                            this.props.storeFinalIndustryID(response.data.Result[0]["FINAL_INDUSTRY_ID"])
                            this.props.storeInputAndClassificationDetails(response.data.Result[0])
                            
                            var hierarchyTree = []
                            var hierarcyPath = response.data.Result[0]["FINAL_INDUSTRY_DESCRIPTION_PATH"].split(' | ')
                            hierarcyPath.map(function (item, index) {
                                var obj = { 'key': index + 1, 'label': item, 'parent': index === 0 ? null : index }
                                hierarchyTree.push(obj)
                            })
                            var objectData = this.getNestedChildren(hierarchyTree, null)//Start with null as parent
                            let expandedKeys = { ...this.state.expandedKeys };
                            hierarchyTree.map(function (item) {
                                expandedKeys[item.key] = true
                            });
                            this.props.trackSupplierDrillDownChanges(false)
                            this.props.trackSupplierDrillDownSave(true)
                            this.props.setPopUpTabLoadingStatus(false)
                            this.setState({
                                classificationData: response.data.Result[0], hierarchyPathTree: objectData, expandedKeys: expandedKeys,
                                isLoading: false, isError: 'No', isSaveInProgress: false, responseMsg: this.props.content.overrideMessage, overrideIndustryID: response.data.Result[0]["OVERRIDE_INDUSTRY_ID"],
                                originalOverrideIndustryID: response.data.Result[0]["OVERRIDE_INDUSTRY_ID"],
                                isCapIQLicesend: this.props.user.user.applicationFeatures.findIndex(a => a.Action === 'CapIQLinkAccess') > -1 ? true : false
                            })
                        }
                        else {
                            this.setState({
                                isLoading: false, isError: 'Yes', isSaveInProgress: false, responseMsg: this.props.content.noData
                            })
                        }
                    }
                    else {
                        this.props.setPopUpTabLoadingStatus(false)
                    }
                }).catch((error) => {
                    this.props.setPopUpTabLoadingStatus(false)
                    if (error.stack.includes('Network Error')) {
                        this.setState({ responseMsg: 'API service is down. Please contact administrator.', isError: 'Yes', isLoading: false })
                    }
                    else {
                        this.setState({ responseMsg: this.props.content.error, isError: 'Yes', isSaveInProgress: false, isLoading: false })
                    }
                })
        }
    }

    //Convert parent-child data into nested JSON object
    getNestedChildren(arr, parent) {
        var out = []
        for (var i in arr) {
            if (arr[i].parent == parent) {
                var children = this.getNestedChildren(arr, arr[i].key)
                if (children.length) {
                    arr[i].children = children
                }
                out.push(arr[i])
            }
        }
        return out
    }

    // getWaterFallData(arr, key) {
    //     var out = []
    //     while (key !== null)
    //         {
    //             let obj = this.state.nestedData.find(function(item) {
    //                 return (item.key === key);
    //               });

    //             out.unshift(obj.label)
    //             key = obj.parent;
    //         }                
    //     return out.filter(Boolean).join("<b> | </b>");
    // }

    componentDidMount() {
        this.LoadData();
        //START - Verticle Line Spereator Logic
        // var _this = this
        // let parentsWithChildren = this.state.nestedData.map(function(item) {
        //     return item.parent;
        //   });
        // let parentsWithoutChildren = this.state.nestedData.filter(function(value) { 
        // return (parentsWithChildren.indexOf(value.key) === -1)
        // });
        // let finalData = [];
        // parentsWithoutChildren.map(function(value) { 
        //     finalData.push(_this.getWaterFallData(_this.state.nestedData, value.key))
        // });
        // this.setState({ nestedVerticleData : finalData });
        //END - Verticle Line Spereator Logic

        //START - Nested Tree List Logic
        //var objectData = this.getNestedChildren(this.state.nestedData, null)//Start with null as parent
        //this.setState({ nestedTreeData : objectData });
        //End - Nested Tree List Logic
    }

    render() {
        //var _this = this
        //Concatinate Vendor and their address     
        // var address = []
        // address.push(this.state.classificationData["VENDOR_NAME"])
        // address.push(this.state.classificationData["VENDOR_ADDRESS"])
        // var vendorDetails = address.filter(Boolean).join(' | ');
        var val = "https://www.capitaliq.com/CIQDotNet/Lists/Constituents.aspx?subTypeId=" + this.state.classificationData["FINAL_INDUSTRY_ID"]
        return (
            <div className="grid-container">
                <div className="grid-x tabs_container">
                    <div className="medium-12">
                        <div className="grid-x">
                            <div className="medium-11">
                                {(this.state.isLoading) ?
                                    <img style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={path + `images/ajax-loader.gif`} alt="" />
                                    : null
                                }
                                {(this.state.isError === 'Yes') ?
                                    <div style={{ color: 'red', textAlign: 'center' }} className="cell">{this.state.responseMsg}</div>
                                    : <div style={{ color: 'green', textAlign: 'center' }} className="cell">{this.state.responseMsg}</div>
                                }
                            </div>
                            <div className="medium-1">
                                <button className={classNames('float-right', 'pr35',
                                    {
                                        'hand-cursor': this.state.isSaveInProgress ? false : this.state.originalOverrideIndustryID !== this.state.overrideIndustryID,
                                        'gray-scale': this.state.isSaveInProgress ? true : this.state.originalOverrideIndustryID === this.state.overrideIndustryID,
                                    })}
                                    onClick={this.handleSave} disabled={this.state.isSaveInProgress ? true : this.state.originalOverrideIndustryID === this.state.overrideIndustryID} title={this.props.content.save} float >
                                    <img width="20" src="images/save_active.svg" />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div style={{ fontSize: '14px', paddingLeft: '5px' }} className="medium-12 font-bold">{this.props.content.userInformation}</div>
                    <div className="medium-12">
                        <div className="seven-cols font12 font-bold align-left grey_background paddingAll5">
                            <div className="column">{this.props.content.ref}</div>
                            <div className="column">{this.props.content.vendorID}</div>
                            <div className="column">{this.props.content.ERP}</div>
                            <div className="column">{this.props.content.buyerRegion}</div>
                            <div className="column">{this.props.content.businessUnit}</div>
                            <div className="column"></div>
                            <div className="column"></div>
                        </div>
                        <div className="seven-cols font12 pt10 align-left paddingAll5">
                            <div className="column">{this.state.classificationData["REF_NUMBER"] !== null ? this.state.classificationData["REF_NUMBER"] : ''}</div>
                            <div className="column">{this.state.classificationData["VENDOR_ID"]}</div>
                            <div className="column">{this.state.classificationData["ERP_SYSTEM"]}</div>
                            <div className="column">{this.state.classificationData["BUYER_REGION"]}</div>
                            <div className="column">{this.state.classificationData["BUSINESS_UNIT"]}</div>
                            <div className="column"></div>
                            <div className="column"></div>
                        </div>
                    </div>
                    <div style={{ fontSize: '14px', paddingLeft: '5px' }} className="medium-12 font-bold pt20">{this.props.content.deduplication}</div>
                    <div className="medium-12">
                        <div className="seven-cols font12 font-bold align-left grey_background paddingAll5">
                            <div className="column">{this.props.content.dedupeKey}</div>
                            <div className="column">{this.props.content.aggregatedRecords}</div>
                            <div className="column">{this.props.content.aggregatedInvoiceSpend}</div>
                            <div className="column">{this.props.content.aggregatedPOSpend}</div>
                            <div className="column">{this.props.content.aggregatedInvoiceCount}</div>
                            <div className="column">{this.props.content.aggregatedPOCount}</div>
                            <div className="column">{this.props.content.aggregatedPaymentCount}</div>
                        </div>
                        <div className="seven-cols font12 pt10 align-left paddingAll5">
                            <div className="column">{this.state.classificationData["DEDUP_KEY"]}</div>
                            <div className="column">{this.state.classificationData["AGGREGATE_RECORDS"] !== null ? formatThousands(this.state.classificationData["AGGREGATE_RECORDS"]) : ''}</div>
                            <div className="column">{this.state.classificationData["AGGREGATE_INVOICE_SPEND"] !== null ? this.props.currencySymbols[this.props.spendDetails.currentActiveRequest.Currency.trim()] + formatThousands(convertToMillion(this.state.classificationData["AGGREGATE_INVOICE_SPEND"], true)) : ''}</div>
                            <div className="column">{this.state.classificationData["AGGREGATE_PO_SPEND"] !== null ? this.props.currencySymbols[this.props.spendDetails.currentActiveRequest.Currency.trim()] + formatThousands(convertToMillion(this.state.classificationData["AGGREGATE_PO_SPEND"], true)) : ''}</div>
                            <div className="column">{this.state.classificationData["AGGREGATE_INVOICE_COUNT"] !== null ? formatThousands(this.state.classificationData["AGGREGATE_INVOICE_COUNT"]) : ''}</div>
                            <div className="column">{this.state.classificationData["AGGREGATE_PO_COUNT"] !== null ? formatThousands(this.state.classificationData["AGGREGATE_PO_COUNT"]) : ''}</div>
                            <div className="column">{this.state.classificationData["AGGREGATE_PAYMENT_COUNT"] !== null ? formatThousands(this.state.classificationData["AGGREGATE_PAYMENT_COUNT"]) : ''}</div>
                        </div>
                    </div>
                    <div style={{ fontSize: '14px', paddingLeft: '5px' }} className="medium-12 font-bold pt20">{this.props.content.classification}</div>
                    <div className="medium-12">
                        <div className="seven-cols font12 font-bold align-left grey_background paddingAll5">
                            <div className="column">{this.props.content.DMCCategorySource}</div>
                            <div className="column">{this.props.content.DMCIndustryID}</div>
                            <div className="column">{this.props.content.overrideIndustry}</div>
                            <div className="column">{this.props.content.finalIndustryID}</div>
                            <div className="column">{this.props.content.descriptionLevel}</div>
                            {/* <div className="column">{this.props.content.saveOverrideIndustry}</div> */}
                            <div className="column"></div>
                            <div className="column"></div>
                        </div>
                        <div className="seven-cols font12 pt10 align-left paddingAll5">
                            <div className="column">{this.state.classificationData["DMC_CATEGORY_SOURCE"]}</div>
                            <div className="column">{this.state.classificationData["DMC_INDUSTRY_ID"]}</div>
                            <div className="column">
                                <input type="text"
                                    Style="margin-right: 5px"
                                    className="border-with-DD"
                                    value={this.state.overrideIndustryID !== null ? this.state.overrideIndustryID : ''}
                                    defaultValue={this.state.classificationData["OVERRIDE_INDUSTRY_ID"]}
                                    //onChange={(event) => this.setState({overrideIndustryID: event.target.value})}
                                    size="13"
                                    style={{ fontSize: '0.75rem', height: '25px', textAlign: 'left' }}
                                    disabled
                                /><img Style="cursor: pointer" onClick={this.togglePopUp} width="15" src="/images/search_big_inactive.svg" />
                            </div>
                            {this.state.isCapIQLicesend && this.state.classificationData["FINAL_INDUSTRY_ID"] !== undefined && this.state.classificationData["FINAL_INDUSTRY_ID"] !== null ?
                                <div className="column">
                                    <a href={val} target="_blank">{this.state.classificationData["FINAL_INDUSTRY_ID"]}</a>
                                </div>
                                :
                                <div className="column">{this.state.classificationData["FINAL_INDUSTRY_ID"]}</div>
                            }
                            <div className="column">{this.state.classificationData["FINAL_INDUSTRY_DESCRIPTION"]}</div>
                            <div className="column">
                                {/* <button className={classNames('float-left',
                                    {
                                        'hand-cursor': this.state.overrideIndustryID !== undefined && this.state.overrideIndustryID !== null && this.state.overrideIndustryID !== '',
                                        'gray-scale': this.state.overrideIndustryID === undefined || this.state.overrideIndustryID === null || this.state.overrideIndustryID === '',
                                    })}
                                    onClick={this.handleSave} title={this.props.content.save} float >
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <img width="20" src="images/save_active.svg" />
                                </button> */}
                                <PopupE className="override-large-popup"
                                    position="right center"
                                    open={this.state.open}
                                    onClose={this.outsidePopupClick}
                                    closeOnDocumentClick
                                >
                                    <div>
                                        <IndustryOverridePopUp
                                            close={this.togglePopUp}
                                        />
                                    </div>
                                    <div className="popupOkBtn">
                                        <Button onClick={this.industryOverride} float type="submit" content={this.props.content.ok} />
                                        <Button onClick={this.clearIndustryOverride} type="submit" content={this.props.content.clearOverridenIndustry} />
                                    </div>
                                </PopupE>
                            </div>
                            <div className="column"></div>
                        </div>
                    </div>
                    {/* <div style={{marginTop: '30px'}} className="medium-12">
                    <Button
							onClick={this.handleSave}
							content={this.props.content.save}
							color
							float
						/>
                    </div> */}
                    {this.state.hierarchyPathTree.length > 0 ?
                        <div className="medium-12 pt20">
                            <div className="grid-x">
                                <div style={{ fontSize: '14px', paddingLeft: '5px' }} className="medium-12 font-bold">{this.props.content.hierarchyPath}</div>
                                <div className="medium-12 pt10">
                                    <div className="grid-x">
                                        {
                                            Object.keys(this.state.expandedKeys).length > 0 ?
                                                <div className="medium-5">
                                                    <TreeList nestedTreeData={this.state.hierarchyPathTree}
                                                        expandedKeys={this.state.expandedKeys} />
                                                </div>
                                                : null
                                        }
                                        {/* <div style={{borderStyle:'solid', borderWidth:'1px', borderColor:'#c8c8c8', borderRadius:'4px'}} className="medium-7 paddingAll5">
                                        <React.Fragment>
                                            <ul>
                                                {_this.state.nestedVerticleData.map((text, j) =>
                                                <li style={{marginLeft:'4px'}} key={j}>
                                                <div dangerouslySetInnerHTML={{ __html: (j+1) + ". " + text}} />
                                                </li>
					                            )}
                                            </ul>
                                        </React.Fragment>
                                    </div> */}
                                    </div>
                                </div>
                            </div>
                        </div> : null
                    }
                </div>
            </div >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        content: state.content.spendAnalysis.supplierDrilldown.inputandClassification,
        overridedIndustryDetails: state.spendDetails.overridedIndustryDetails,
        spendDetails: state.spendDetails,
        user: state.default,
        currencySymbols: state.content.CurrencySymbols,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getInputAndClassificationDataSuccess: message => dispatch(getInputAndClassificationDataSuccess(message)),
        getInputAndClassificationDataFailure: error => dispatch(getInputAndClassificationDataFailure(error)),
        getInputAndClassificationDataLoading: bool => dispatch(getInputAndClassificationDataLoading(bool)),
        getInputAndClassificationData: (DMCId, VSId, userEmail, SKEY) => dispatch(getInputAndClassificationData(DMCId, VSId, userEmail, SKEY)),
        selectedIndustryDetails: message => dispatch(selectedIndustryDetails(message)),
        trackSupplierDrillDownChanges: message => dispatch(trackSupplierDrillDownChanges(message)),
        trackSupplierDrillDownSave: message => dispatch(trackSupplierDrillDownSave(message)),
        storeVendorDetails: (vendorDetails) => dispatch(storeVendorDetails(vendorDetails)),
        storeFinalIndustryID: (L5Industry) => dispatch(storeFinalIndustryID(L5Industry)),
        storeInputAndClassificationDetails: (deduplicationDetails) => dispatch(storeInputAndClassificationDetails(deduplicationDetails)),
        supplierDrillDownUpdate: (record) => dispatch(supplierDrillDownUpdate(record)),
        setPopUpTabLoadingStatus: bool => dispatch(setPopUpTabLoadingStatus(bool)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InputandClassification); 