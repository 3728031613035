import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Control, Form, Errors, actions } from 'react-redux-form'
import { Link } from 'react-router-dom'

// Actions
import {
	signUpUser,
	signUpUserFailure,
	resetError
} from 'actions/userActions'

// Components
import Textfield from 'react-mdl/lib/Textfield'
import Button from 'UI/Button'

// Settings
import { validators, routes } from 'Constants'

class RegisterContainer extends Component {

	constructor(props) {
		super(props)

		this.handleSignUpUser = this.handleSignUpUser.bind(this)
		this.redirect = this.redirect.bind(this)
	}

	handleSignUpUser(formValues) {
		let values = Object.assign({}, formValues)
		values.Language = this.props.language.toUpperCase()
		var msg = this.props.content
		this.props.signUpUser(values).then((result) => {

			if (result.status === 200) {
				this.formDispatch(actions.reset('register'))

				// Redirect to thank you page
				this.redirect()
			}
			else {
				var httpstatus = result.response.status
				var getMsgfromStatus = msg[httpstatus]
				if (getMsgfromStatus !== undefined) {
					this.props.signUpUserFailure(getMsgfromStatus)
				}
				else {
					this.props.signUpUserFailure(result.response.data.message)
				}
			}
			// Reset form


		}).catch((error) => {
			if (error.response === undefined) {
				this.props.signUpUserFailure(error.message)
			}
			else {
				var httpstatus = error.response.status
				var getMsgfromStatus = msg[httpstatus]
				this.props.signUpUserFailure(getMsgfromStatus)
			}
		})
	}

	redirect() {
		this.props.history.push(`/register-thank-you`)
	}

	componentWillUnmount() {
		this.formDispatch(actions.reset('register'))
		this.props.resetError()
	}

	render() {
		return (
			<div className="grid-container">
				<div className="register grid-x">
					<div className="cell">
						<div className="page-title">{this.props.content.title}</div>
					</div>
					<div className="medium-6 cell">
						<Form
							model="register"
							onSubmit={(values) => this.handleSignUpUser(values)}
							getDispatch={(dispatch) => this.formDispatch = dispatch}
							validators={{
								'': {
									passwordsMatch: (vals) => vals.Password === vals.ConfirmPassword,
									charMatch: (vals) => (vals.Password.toLowerCase().indexOf(vals.FirstName.toLowerCase())=== -1 && vals.Password.toLowerCase().indexOf(vals.LastName.toLowerCase())=== -1 
									&& vals.Password.toLowerCase().indexOf(vals.Company.toLowerCase()) === -1)
								}
							}}
						>
							<Control.text
								model="register.FirstName"
								id="register.FirstName"
								component={Textfield}
								label={this.props.content.fname}
								floatingLabel
								validators={{ required: validators.required }}
								validateOn="blur"
							/>
							<Errors
								className="errors"
								model="register.FirstName"
								show="touched"
								messages={{
									required: this.props.validation.required
								}}
							/>
							<Control.text
								model="register.LastName"
								id="register.LastName"
								component={Textfield}
								label={this.props.content.lname}
								floatingLabel
								validators={{ required: validators.required }}
								validateOn="blur"
							/>
							<Errors
								className="errors"
								model="register.LastName"
								show="touched"
								messages={{
									required: this.props.validation.required
								}}
							/>
							<Control.text
								model="register.Company"
								id="register.Company"
								component={Textfield}
								label={this.props.content.company}
								floatingLabel
								validators={{ required: validators.required }}
								validateOn="blur"
							/>
							<Errors
								className="errors"
								model="register.Company"
								show="touched"
								messages={{
									required: this.props.validation.required
								}}
							/>
							<Control.text
								model="register.Email"
								id="register.Email"
								component={Textfield}
								label={this.props.content.email}
								floatingLabel
								validators={{ validEmail: validators.validEmail }}
								validateOn="blur"
							/>
							<Errors
								className="errors"
								model="register.Email"
								show="touched"
								messages={{
									validEmail: this.props.validation.validEmail
								}}
							/>
							<Control.text
								model="register.Password"
								id="register.Password"
								type="password"
								component={Textfield}
								label={this.props.content.password}
								floatingLabel
								validators={{
									required: validators.required,
									validPassword: validators.validPassword
								}}
							/>
							<Errors
								className="errors"
								model="register.Password"
								show="touched"
								messages={{
									validPassword: this.props.validation.validPassword
								}}
							/>
							<Errors
								className="errors"
								model="register"
								show="touched"
								messages={{
									charMatch: this.props.validation.charMatch
								}}
							/>
							<Control.text
								model="register.ConfirmPassword"
								id="register.ConfirmPassword"
								type="password"
								component={Textfield}
								label={this.props.content.confirmPassword}
								floatingLabel
								validators={{
									required: validators.required,
									validPassword: validators.validPassword
								}}
							/>
							<Errors
								className="errors"
								model="register.ConfirmPassword"
								show="touched"
								messages={{
									validPassword: this.props.validation.validPassword
								}}
							/>
							<Errors
								className="errors"
								model="register"
								show="touched"
								messages={{
									passwordsMatch: this.props.validation.passwordsMatch
								}}
							/>
							<Button
								type="submit"
								disabled={this.props.user.loading}
								content={this.props.content.register}
								color
							/>
						</Form>
						<div className="errors">{this.props.user.error}</div>
						<Link to={routes.login} style={{ marginTop: '20px', display: 'inline-block' }}>{this.props.ui.login}</Link>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		language: state.language,
		ui: state.content.ui,
		content: state.content.register,
		validation: state.content.validation,
		user: state.default
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		signUpUser: (values) => { return dispatch(signUpUser(values)) },
		signUpUserFailure: (error) => dispatch(signUpUserFailure(error)),
		resetError: () => dispatch(resetError())
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterContainer)