import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Control, Form, Errors, actions } from 'react-redux-form'
import { Checkbox } from 'material-ui';
// Utils
import { BreakLine } from 'utils/Utils'
// UI
import Textfield from 'react-mdl/lib/Textfield'
import Button from 'UI/Button'
import moment from 'moment'
// Settings
import { validators } from 'Constants'
import {
	fetchANIdBPIdDetails,
	addANIDDetails,
	userDetailsSuccess,
    userDetailsFailure,
    userDetailsLoading,
    resetError,
} from 'actions/spendActions'

import CustomGrid from 'UI/CustomGrid'
import { AlignFormatter, CenterAlignFormatter, getNonEmptyValue } from "../../utils/Utils";
import { Filters, Editors } from "react-data-grid-addons";


const {
    NumericFilter,
    AutoCompleteFilter,
} = Filters;

const { DropDownEditor } = Editors;
const dropdownValues = ["Y", "N"];
const dropDownEditor = <DropDownEditor options={dropdownValues} />

class ANIDManager extends Component {
	constructor(props) {
		super(props)
		this.state = {
			roleFeatures: [],
			selectedFeaturesIDForUsers: [],
			filterText: '',
			apiData: [],
			roleIsEditing: false,
			rowId: null
		}
		this.save = this.save.bind(this)
	}

	componentDidMount() {
		this.props.resetError()
		this.loadData();
	}

	componentWillUnmount() {
		this.formDispatch(actions.reset('anIdManager'))
		this.props.resetError()
	}


	loadData() {
		//this.fetchRolesAndFeatures(true);
        var columns = this.initializeColumns();
        this.props.fetchANIdBPIdDetails()
			.then((response) => {
				this.setState({ apiData: response.data.Result , columns: columns })
                console.log("ANID DAta...", response.data.Result)
			}).catch((error) => {
				if (error.stack.includes('Network Error')) {
					this.props.userDetailsFailure()
				}
				else {
					this.props.userDetailsFailure(error.response.data.Message)
				}
			})
	}

    initializeColumns() {
        let columns = []

        let tableColumn = {
            name: 'Customer Name',
            key: 'CUSTOMER_NAME',
            width: 250,
            sortable: true,
            editable: true,
            resizable: true,
            filterable: true,
            screenSeq: 0,
            mandatory: 'No',
            exportFl: true,
            //editProperty: fieldConfig.is_editable,
            formatter: CenterAlignFormatter,
            helpText: '',
            filterRenderer: AutoCompleteFilter
        }

        columns.push(tableColumn);

        tableColumn = {
            name: 'BPID',
            key: 'BPID',
            width: 250,
            sortable: true,
            editable: true,
            resizable: true,
            filterable: true,
            screenSeq: 1,
            mandatory: 'No',
            exportFl: true,
            //editProperty: fieldConfig.is_editable,
            formatter: CenterAlignFormatter,
            helpText: '',
            filterRenderer: AutoCompleteFilter
        }

        columns.push(tableColumn);

        tableColumn = {
            name: 'Buyer ANID',
            key: 'BUYER_ANID',
            width: 250,
            sortable: true,
            editable: true,
            resizable: true,
            filterable: true,
            screenSeq: 1,
            mandatory: 'No',
            exportFl: true,
            //editProperty: fieldConfig.is_editable,
            formatter: CenterAlignFormatter,
            helpText: '',
            filterRenderer: AutoCompleteFilter
        }

        columns.push(tableColumn);

        tableColumn = {
            name: 'SAP Region',
            key: 'SAP_REGION',
            width: 250,
            sortable: true,
            editable: true,
            resizable: true,
            filterable: true,
            screenSeq: 1,
            mandatory: 'No',
            exportFl: true,
            //editProperty: fieldConfig.is_editable,
            formatter: CenterAlignFormatter,
            helpText: '',
            filterRenderer: AutoCompleteFilter
        }

        columns.push(tableColumn);

        tableColumn = {
            name: 'MU / Country',
            key: 'COUNTRY',
            width: 300,
            sortable: true,
            editable: true,
            resizable: true,
            filterable: true,
            screenSeq: 1,
            mandatory: 'No',
            exportFl: true,
            //editProperty: fieldConfig.is_editable,
            formatter: CenterAlignFormatter,
            helpText: '',
            filterRenderer: AutoCompleteFilter
        }

        columns.push(tableColumn);     
		
		tableColumn = {
            name: 'Deleted',
            key: 'RECORD_FLAG',
            width: 200,
            sortable: true,
            editable: true,
            resizable: true,
            filterable: true,
            screenSeq: 1,
            mandatory: 'No',
            exportFl: true,
            //editProperty: fieldConfig.is_editable,
            formatter: CenterAlignFormatter,
            helpText: '',
            filterRenderer: AutoCompleteFilter,
			editor: dropDownEditor
        }

        columns.push(tableColumn);   

        return columns;
    }

	resetForm() {
		this.formDispatch(actions.change('anIdManager.Customer', ''));
		this.formDispatch(actions.change('anIdManager.BPID', ''));
		this.formDispatch(actions.change('anIdManager.ANID', ''));
		this.formDispatch(actions.change('anIdManager.Region', ''));
		this.formDispatch(actions.change('anIdManager.Country', ''));
		setTimeout(() => {this.props.userDetailsSuccess() }, 1500);		
	}

	handleAddUpdateANID(values){
		console.log("on save click.........", this.props)
        var inputParam =[]
		let obj={}
		obj.SRID = values.SRID
		obj.CUSTOMER_NAME = values.Customer
		obj.BPID = values.BPID
		obj.BUYER_ANID = values.ANID
		obj.SAP_REGION = values.Region
		obj.COUNTRY = values.Country
		obj.RECORD_STATUS = "Inserted"
        obj.RECORD_FLAG ='Y'
		obj.USER_ID = this.props.user.user.Email;
		inputParam.push(obj)

		this.props.addANIDDetails(inputParam)
		.then((response) => {
			this.loadData()
			this.props.userDetailsSuccess("Record save successfully.")
		    this.resetForm()

		}).catch((error) => {
			if (error.stack.includes('Network Error')) {
			this.props.userDetailsFailure(this.props.validation.networkError)
			}
			else {
			this.props.userDetailsFailure(error.response.data.Message)
			}
		})

	}

	save(data) {
        this.setState({ responseMsg: '' })
        let rowData = data.length > 0 ? data : this.state.data;
		
		if(rowData[0].RECORD_FLAG==="N")
		{
			rowData[0].RECORD_STATUS = "Deleted"	
		}
		else{
			rowData[0].RECORD_STATUS = "Updated"
		}
        
		rowData[0].USER_ID = this.props.user.user.Email;

		console.log(rowData)
        //call hana procedure for save here
	    return	this.props.addANIDDetails(rowData)	
    }
	

	render() {
		
		return (
			<div className="grid-x medium-12" style={{ padding: '20px'}}>
					<div className="grid-x medium-12">
						<div className="medium-12"><h2>{BreakLine("ANID Manager")}</h2></div>
						<div className="grid-x medium-12">
							<Form
                                className="grid-x medium-12"
								model="anIdManager"
								getDispatch={(dispatch) => this.formDispatch = dispatch}
								onSubmit={(values) => this.handleAddUpdateANID(values)}
							>
								<div className="grid-x medium-12">
									{/* <SearchBar validation={this.props.validation} filterText={this.state.filterText} isDuplicate={this.isDuplicate.bind(this)} onUserInput={this.handleUserInput.bind(this)} /> */}
									<div className="medium-4">
											<Control.text
												model="anIdManager.Customer"
												id="anIdManager.Customer"
												component={Textfield}
												label={"Customer"}
												floatingLabel
												validators={{ required: validators.required }}
											/>
										</div>

                                    <div className="medium-4">
											<Control.text
												model="anIdManager.BPID"
												id="anIdManager.BPID"
												component={Textfield}
												label={"BPID"}
												floatingLabel
												validators={{ required: validators.required }}
											/>
										</div>
                                    </div>

                                    <div className="grid-x medium-12">
                                        <div className="medium-4">
											<Control.text
												model="anIdManager.ANID"
												id="anIdManager.ANID"
												component={Textfield}
												label={"ANID"}
												floatingLabel
												validators={{ required: validators.required }}
											/>
										</div>

                                        <div className="medium-4">
											<Control.text
												model="anIdManager.Region"
												id="anIdManager.Region"
												component={Textfield}
												label={"SAP Region"}
												floatingLabel
												validators={{ required: validators.required }}
											/>
										</div>
                                    </div>

                                    <div className="grid-x medium-12">
                                        <div className="medium-4">
											<Control.text
												model="anIdManager.Country"
												id="anIdManager.Country"
												component={Textfield}
												label={"MU/Country"}
												floatingLabel
												validators={{ required: validators.required }}
											/>
										</div>

									<div className="medium-4 pt10">
										<Button
											type="submit"
											disabled={this.props.anIDManager.isLoading}
											content={this.props.content.add}
											color
										/>
									</div>
								</div>
							</Form>
							<div className="errors">{this.props.anIDManager.error}</div>
							<div className="success">{this.props.anIDManager.message}</div>

						</div>
					</div>
				
				<div className="grid-x">
                    <CustomGrid
                        columns={this.state.columns}
                        topMargin={true}
                        data={this.state.apiData}
                        keyColumn="SRID"
					    save={this.save }
                    />
				</div>

			</div>
		)
	}

}

const mapStateToProps = (state) => {
	return {
		language: state.language,
		ui: state.content.ui,
		user: state.default,
		content: state.content.roleManager,
		validation: state.content.validation,
		anIDManager: state.spendDetails

	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		userDetailsSuccess: (bool) => dispatch(userDetailsSuccess(bool)),
		userDetailsFailure: (error) => dispatch(userDetailsFailure(error)),
		resetError: () => dispatch(resetError()),
		fetchANIdBPIdDetails: (formValues) => dispatch(fetchANIdBPIdDetails(formValues)),
		addANIDDetails: (formValues) => dispatch(addANIDDetails(formValues))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ANIDManager)