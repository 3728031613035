import React, { Fragment } from 'react';
import { connect } from 'react-redux'
import SupplierDrillDownTabPanel from '../Tabs/SupplierDrillDownTabPanel'
import InputandClassification from 'components/Spend/InputandClassification'
import SPCapitalIQ from 'components/Spend/SPCapitalIQ'
import SPCapitalIQUltimateParent from 'components/Spend/SPCapitalIQUltimateParent'
import ScopeAndMatch from 'components/Spend/ScopeAndMatch'
import SupplierDeduplication from 'components/Spend/SupplierDeduplication'
import Modal from 'reboron/OutlineModal'

import { modalStyle, modalContentStyle, changeModalBorderStyle, drilldownModalBorderContentStyle, changeModalBorderRectStyle } from 'Constants'
// const supplierDrilldownModalContentStyle = Object.assign({}, drilldownModalBorderContentStyle);
// supplierDrilldownModalContentStyle.padding = "20px";
// //supplierDrilldownModalContentStyle.border = "1px solid";
// supplierDrilldownModalContentStyle.overflowY = "hidden";
// const supplierDrilldownModalStyle = Object.assign({}, changeModalBorderStyle);
// supplierDrilldownModalStyle.width = "90%";
// supplierDrilldownModalStyle.height = "750px";
// supplierDrilldownModalStyle.maxWidth = "1400px";

class SupplierDrillDownPopup extends React.Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef()
        this.state = {
            tabId: 1,
            tabs: [],
            tabsWithoutCapIQ: [],
            tabsWithoutParentCapIQ: [],
            tabsWithoutCapIQAndParentCapIQ: [],
            isCapIQLicesend: false,
            classificationData: []
        }

        this.dispatchTabChange = this.dispatchTabChange.bind(this)
        this.intializeSupplierTabs = this.intializeSupplierTabs.bind(this)
    }

    componentDidMount() {
        //this.checkDedupAggregatedRecord();
        this.intializeSupplierTabs();
        this.setState({ isCapIQLicesend: this.props.user.user.applicationFeatures.findIndex(a => a.Action === this.props.capIQ.capIQAction) > -1 ? true : false })
    }

    intializeSupplierTabs() {

        const tabs = [{
            id: 1,
            label: this.props.content.inputandClassification,
            component: (<div className="grid-x"><InputandClassification /></div>)
        },
        {
            id: 2,
            label: this.props.content.SPCapitalIQ,
            component: (<div className="grid-x"><SPCapitalIQ /></div>)
        },
        {
            id: 3,
            label: this.props.content.SPCapitalIQUltimateParent,
            component: (<div className="grid-x"><SPCapitalIQUltimateParent /></div>)
        },
        {
            id: 4,
            label: this.props.content.scopeandMatch,
            component: (<div className="grid-x"><ScopeAndMatch /></div>)
        }
        ,{
            id: 5,
            label: this.props.content.supplierDeduplication,
            component: (<div> <SupplierDeduplication /> </div>)
         }
    ];

        const tabsWithoutParentCapIQ = [{
            id: 1,
            label: this.props.content.inputandClassification,
            component: (<div className="grid-x"><InputandClassification /></div>)
        },
        {
            id: 2,
            label: this.props.content.SPCapitalIQ,
            component: (<div className="grid-x"><SPCapitalIQ /></div>)
        },
        {
            id: 3,
            label: this.props.content.scopeandMatch,
            component: (<div className="grid-x"><ScopeAndMatch /></div>)
        }
        ,{
            id: 4,
            label: this.props.content.supplierDeduplication,
            component: (<div> <SupplierDeduplication /> </div>)
         }
    ];

        const tabsWithoutCapIQ = [{
            id: 1,
            label: this.props.content.inputandClassification,
            component: (<div className="grid-x"><InputandClassification /></div>)
        },
        {
            id: 2,
            label: this.props.content.SPCapitalIQUltimateParent,
            component: (<div className="grid-x"><SPCapitalIQUltimateParent /></div>)
        },
        {
            id: 3,
            label: this.props.content.scopeandMatch,
            component: (<div className="grid-x"><ScopeAndMatch /></div>)
        }
        ,{
            id: 4,
            label: this.props.content.supplierDeduplication,
            component: (<div> <SupplierDeduplication /> </div>)
         }
    ];

        const tabsWithoutCapIQAndParentCapIQ = [{
            id: 1,
            label: this.props.content.inputandClassification,
            component: (<div className="grid-x"><InputandClassification /></div>)
        },
        {
            id: 2,
            label: this.props.content.scopeandMatch,
            component: (<div className="grid-x"><ScopeAndMatch /></div>)
        }
        ,{
            id: 3,
            label: this.props.content.supplierDeduplication,
            component: (<div> <SupplierDeduplication /> </div>)
         }
    ];

        this.setState({
            tabs: tabs,
            tabsWithoutCapIQ: tabsWithoutCapIQ,
            tabsWithoutParentCapIQ: tabsWithoutParentCapIQ,
            tabsWithoutCapIQAndParentCapIQ: tabsWithoutCapIQAndParentCapIQ,
            tabId: 1
        })
    }

    dispatchTabChange(tabId) {
        this.setState({
            tabId: tabId,
        })
    }

    render() {
        return (
            <div>
                {this.refs.supplierDrilldown ? this.props.open ?
                    this.refs.supplierDrilldown.show() :
                    this.refs.supplierDrilldown.hide() : ''}
                <Modal
                    ref={'supplierDrilldown'}
                    modalStyle={changeModalBorderStyle}
                    contentStyle={drilldownModalBorderContentStyle}
                    rectStyle={changeModalBorderRectStyle}
                    keyboard={false}
                    backdrop={false} 
                    // onHide={(e) => {
                    //     if (e === 'backdrop') {
                    //         this.props.outsidePopupClick()
                    //     }
                    // }}
                >
                    <div className="grid-x tabs_container supplier-tabs">
                        {this.props.spendDetails.vendorDetails !== undefined ?
                            <div className="medium-12">
                                <div style={{ fontSize: '16px', color: '#008fd3', paddingLeft: '5px' }} >{this.props.spendDetails.vendorDetails["vendorName"]}<a href={"https://www.google.com/search?q=" + encodeURIComponent((this.props.spendDetails.vendorDetails["vendorName"] || '') + " " + (this.props.spendDetails.vendorDetails["vendorNameAddress"] || ''))} target="_blank"><img className="ml5" style={{width:"16px"}} src="images/google_logo.svg" /></a></div>
                                <div style={{ fontSize: '15px', paddingTop: '3px', paddingLeft: '5px' }}>{this.props.spendDetails.vendorDetails["vendorAddress"]}</div>
                            </div>
                            :
                            null}
                        <div className="medium-12">
                            {<SupplierDrillDownTabPanel
                                tabs={ !this.state.isCapIQLicesend ? this.state.tabsWithoutCapIQAndParentCapIQ : this.props.user.userprofile.Roles[0].indexOf("Standard Partner User") > -1 ? this.state.tabsWithoutCapIQAndParentCapIQ : ((this.props.spendDetails.supplierDetails["capIQId"] && this.props.spendDetails.supplierDetails["parentCapIQId"]) &&
                                    (this.props.spendDetails.supplierDetails["capIQId"] !== this.props.spendDetails.supplierDetails["parentCapIQId"]) ?
                                    this.state.tabs : (this.props.spendDetails.supplierDetails["capIQId"] && this.props.spendDetails.supplierDetails["parentCapIQId"]) &&
                                        (this.props.spendDetails.supplierDetails["capIQId"] === this.props.spendDetails.supplierDetails["parentCapIQId"]) ?
                                        this.state.tabsWithoutParentCapIQ : this.props.spendDetails.supplierDetails["capIQId"] && !this.props.spendDetails.supplierDetails["parentCapIQId"] ?
                                            this.state.tabsWithoutParentCapIQ : !this.props.spendDetails.supplierDetails["capIQId"] && this.props.spendDetails.supplierDetails["parentCapIQId"] ?
                                                this.state.tabsWithoutCapIQ : this.state.tabsWithoutCapIQAndParentCapIQ)}
                                tabId={1}
                                dispatchTabChange={this.dispatchTabChange}
                                dispatchLastButtonClick={this.dispatchLastButtonClick}
                            />}
                        </div>
                    </div>
                    <button onClick={() => this.props.visibility(false)} className='close' />
                </Modal>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        //content: state.content,
        //ui: state.content.ui,
        content: state.content.spendAnalysis.supplierDrilldown.tabNames,
        spendDetails: state.spendDetails,
        user: state.default,
        capIQ: state.content.spendAnalysis.detailedFile
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SupplierDrillDownPopup); 