import React, { Component } from 'react'
import { connect } from 'react-redux'
import FlightPlanningDocParameters from '../Admin/FlightPlanningDocParameters'
import FlightPlanningSpendParameters from '../Admin/FlightPlanningSpendParameters'
import FlightPlanningTransactingBuyRelationship from '../Admin/FlightPlanningTransactingBuyRelationship'


class MatchAndSegmentation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            readOnly : props.user.userprofile.Roles[0].indexOf("Admin") > -1 ? false : true
        }
    }

    render() {
        return (
            
            <div>                
                <FlightPlanningDocParameters readOnly={this.state.readOnly}></FlightPlanningDocParameters>
                <FlightPlanningSpendParameters readOnly={this.state.readOnly}></FlightPlanningSpendParameters>
                <br></br>
                <FlightPlanningTransactingBuyRelationship readOnly={this.state.readOnly}></FlightPlanningTransactingBuyRelationship>                
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.default
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MatchAndSegmentation)