import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabContainer from './TabContainer';
import { AppBar } from '@material-ui/core';
import { connect } from 'react-redux'
import Modal from 'reboron/OutlineModal'
import Button from 'UI/Button'

import {
    pendingChanges,
    //defineScopeLoading,
    saveKPIOnRedirect,
    isEnablementStrategyContainerPage,
    isSpendParameterPage,
    deleteIntermediateKPIOnRedirect,
    saveSpendParametersOnRedirect,
    isWaveRecalcOnSpendParamRedirect
} from 'actions/spendActions'

import {
    saveValueStoryOnRedirect,
    isValueStoryPage
}  from 'actions/discoveryActions'

import { changeModalStyle, changeModalContentStyle,spendModalStyle, backdropStyle, path } from 'Constants'

const changeBackdropStyle = Object.assign({}, backdropStyle);
changeBackdropStyle.zIndex = '1050';

const alertModalStyle = Object.assign({}, changeModalStyle);
alertModalStyle.zIndex = '1060';
alertModalStyle.width = '50%';

const alertModalStyleSpendParameter = Object.assign({}, spendModalStyle);
alertModalStyleSpendParameter.zIndex = '1060';
alertModalStyleSpendParameter.width = '85%';

const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        borderBottom: "1px solid grey",
        boxShadow: "none"
    },
    indicator: {
        backgroundColor: "#008fd3",
    },
    textColor: {
        // color: "#008fd3",
    },
    paddingTop: {
        paddingTop: "5px"
    },
    tabs: {
        borderLeft: "1px solid grey",
        borderRight: "1px solid grey",
        padding: "10px"
    },
    borderBothSides: {
        borderLeft: "1px solid grey",
        borderRight: "1px solid grey",
    },
    borderLeftSide: {
        borderLeft: "1px solid grey",
    }
});

class SubTabsPanel extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: props.tabId,
            toggle: false,
            event: null,
            activeTabCopy: props.tabId,
            isLoading: false,
        };
        this.showModal = this.showModal.bind(this)
        this.hideModal = this.hideModal.bind(this)
        this.continueNavigation = this.continueNavigation.bind(this)

    }

    handleChange = (event, activeTab) => {
        if (this.props.change !== undefined && this.props.change !== null
            && this.props.change === true &&
            this.props.spendDetails.isKpiStrategy !== undefined && this.props.spendDetails.isKpiStrategy !== null
            && this.props.spendDetails.isKpiStrategy === true) {
            this.setState({ activeTabCopy: activeTab }, this.showModal("KPI"));
        }
        else if (this.props.change !== undefined && this.props.change !== null
			&& this.props.change === true &&
			this.props.valueStory.isValueStoryPage !== undefined && this.props.valueStory.isValueStoryPage !== null
			&& this.props.valueStory.isValueStoryPage === true) {
            this.setState({ activeTabCopy: activeTab }, this.showModal("VS"));
		}
        else if (this.props.change !== undefined && this.props.change !== null
			&& this.props.change === true &&
			this.props.spendDetails.isSpendParameterPage !== undefined && this.props.spendDetails.isSpendParameterPage !== null
			&& this.props.spendDetails.isSpendParameterPage === true) {
            this.setState({ activeTabCopy: activeTab }, this.showModal("SpendParameter"));
		}        
        else if (this.props.change !== undefined && this.props.change !== null
            && this.props.change === true) {
            this.setState({ activeTabCopy: activeTab }, this.showModal("alert"));
        } else {
            this.setState((state) => ({ activeTab }));
            if (this.props.dispatchTabChange) {
                if (this.props.handleNextButton) {
                    this.props.handleNextButton(activeTab)
                }
                this.props.dispatchTabChange(activeTab)
            }
        }
    };

    showModal(action) {
        this.refs[action].show()
    }

    hideModal(action) {
        this.refs[action].hide()
    }

    continueNavigation(action) {
        let activeTab = this.state.activeTabCopy
        this.props.pendingChanges(null)
        if(action == 'SpendParameter')
        this.props.spendDetails.currentActiveRequest = Object.assign({}, this.props.spendDetails.oldActiveRequest);
        //this.props.spendDetails.currentActiveRequest = {...this.props.spendDetails.oldActiveRequest};
        this.setState({ toggle: false, activeTab: activeTab }, this.refs[action].hide())
        if (this.props.dispatchTabChange) {
            if (this.props.handleNextButton) {
                this.props.handleNextButton(activeTab)
            }
            this.props.dispatchTabChange(activeTab)
        }
    }

    continueKPINavigation(action,isIntermediateDataDelete) {
        this.setState({ isLoading: true });
        if(isIntermediateDataDelete)
            this.props.deleteIntermediateKPIOnRedirect(true);
        else
            this.props.saveKPIOnRedirect(true);
        setTimeout(() => {
            this.props.isEnablementStrategyContainerPage(false);
            this.continueNavigation(action);
            this.setState({ isLoading: false });
        }, 10000);
    }

    continueValueStoryNavigation(action) {
        this.setState({ isLoading: true });
        this.props.saveValueStoryOnRedirect(true);
        setTimeout(() => {
            this.props.isValueStoryPage(false);
            this.continueNavigation(action);
            this.setState({ isLoading: false });
        }, 4000);
    }

    continueSpendParameterNavigation(action) {
        this.setState({ isLoading: true });
        this.props.saveSpendParametersOnRedirect(true);
        this.props.isWaveRecalcOnSpendParamRedirect(false);  
        setTimeout(() => {
            this.props.isSpendParameterPage(false);
            this.props.saveSpendParametersOnRedirect(false);
            this.continueNavigation(action);
            this.setState({ isLoading: false });
        }, 6000);
    }
    continueSpendParameterNavigationWithWaveRecalc(action) {
        this.setState({ isLoading: true });
        this.props.saveSpendParametersOnRedirect(true);        
        this.props.isWaveRecalcOnSpendParamRedirect(true);         
        setTimeout(() => {
            this.props.isSpendParameterPage(false);
            this.props.saveSpendParametersOnRedirect(false); 
            this.continueNavigation(action);
            this.setState({ isLoading: false });
        }, 6000);
    }

    render() {
        const { classes } = this.props;
        const { activeTab } = this.state;
        const _state = this.props.spendDetails
        return (
            <div class="tab-container bottom-space">
                <div className="grid-x">
                    <div className="medium-12 large-12">
                        <AppBar position="static" className={classes.root}>
                            <Tabs
                                value={activeTab}
                                onChange={this.handleChange}
                                classes={{ indicator: classes.indicator }}
                            >
                                {
                                    this.props.tabs.map((tab, i) => {
                                        var disabledTab = _state.isPopUpTabLoading && activeTab === tab.id ? false : _state.isPopUpTabLoading;
                                        return (<Tab
                                            classes={{ label: classes.textColor }}
                                            id={tab.id}
                                            key={tab.id}
                                            label={tab.label}
                                            value={tab.id}
                                            className="tabs-header"
                                            aria-controls={`wrapped-tabpanel-${i}`}
                                            disabled={disabledTab}
                                        />)
                                    })
                                }
                            </Tabs>
                        </AppBar>
                        {
                            this.props.tabs.map(tab => (
                                activeTab === tab.id
                                    ? <TabContainer classStyle={classes.paddingTop} key={tab.id}>{tab.component}</TabContainer>
                                    : null
                            ))
                        }
                    </div>
                </div>
                {
                    // this.props.nextButtonLabel && this.props.nextButtonLabel != "" ?
                    //     (
                    //         <div className="grid-x">
                    //             <div className="medium-12 cell float-right">
                    //                 <Button content={this.props.nextButtonLabel} float
                    //                     disabled={this.props.spendDetails !== undefined ? this.props.spendDetails.isLoading : false}
                    //                     onClick={(event) => {
                    //                         var index = 0
                    //                         for (; index < this.props.tabs.length; index++) {
                    //                             if (activeTab === this.props.tabs[index].id) {
                    //                                 break;
                    //                             }
                    //                         }
                    //                         if (index === this.props.tabs.length - 1) {
                    //                             if (this.props.dispatchLastButtonClick) {
                    //                                 this.props.dispatchLastButtonClick(activeTab)
                    //                             }
                    //                         } else {
                    //                             this.handleChange(event, this.props.tabs[index + 1].id)
                    //                         }
                    //                     }
                    //                     }
                    //                 />
                    //             </div>
                    //         </div>
                    //     ) : null
                }
                <div>
                    <Modal
                        ref="alert"
                        modalStyle={alertModalStyle}
                        contentStyle={changeModalContentStyle}
                        backdropStyle={changeBackdropStyle}
                    >
                        <div className="grid-x grid-padding-x change-popup">
                            <div className="medium-12">
                                <button onClick={() => this.hideModal("alert")} className="close" />
                            </div>
                            <div className="medium-12 pt30">
                                {this.props.tabMessage.toggleTabMessage}
                            </div>
                            <div className="medium-12 popupOkBtn">
                                <div className="float-right mt5">
                                    <div className="float-left"><Button
                                        content={"Continue without saving changes"}
                                        onClick={() => this.continueNavigation("alert")}
                                        color /></div>
                                    <div className="float-left"><Button
                                        content={"Cancel to return to current page"}
                                        onClick={() => this.hideModal("alert")}
                                        color /></div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </div>

                <div>
                    <Modal
                        ref="KPI"
                        modalStyle={alertModalStyle}
                        contentStyle={changeModalContentStyle}
                        backdropStyle={changeBackdropStyle}
                    >
                        <div className="grid-x grid-padding-x change-popup">
                            <div className="medium-12">
                                <button onClick={() => this.hideModal("KPI")} className="close" />
                            </div>
                            <div className="medium-12 pt30">
                                {this.props.tabMessage.toggleKPITabMessage}
                            </div>                            
                            <div className="grid-x medium-12 mt20">
                                <div>
                                    <Button
                                        className="float-left"
                                        type="submit"
                                        disabled={this.state.isLoading}
                                        content={this.props.tabMessage.toggleKPITabYesButton}
                                        onClick={() => this.continueKPINavigation("KPI",false)}
                                        color />
                                </div>                                
                                <div>
                                    <Button
                                        className="float-left"
                                        type="submit"
                                        disabled={this.state.isLoading}
                                        content={this.props.tabMessage.toggleKPITabNoButton}
                                        onClick={() => this.hideModal("KPI")}
                                        color />
                                </div>
                                <div>
                                    <Button
                                        className="float-left"
                                        type="submit"
                                        disabled={this.state.isLoading}
                                        content={this.props.tabMessage.toggleKPITabRevertButton}
                                        onClick={() => this.continueKPINavigation("KPI",true)}
                                        color />
                                </div>
                            </div>                            
                        </div>
                    </Modal>
                </div>
                <div>
                    <Modal
                        ref="VS"
                        modalStyle={alertModalStyle}
                        contentStyle={changeModalContentStyle}
                        backdropStyle={changeBackdropStyle}
                    >
                        <div className="grid-x grid-padding-x change-popup">
                            <div className="medium-12">
                                <button onClick={() => this.hideModal("VS")} className="close" />
                            </div>
                            <div className="medium-12 pt30">
                            {this.props.tabMessage.toggleVSMessage}
                            </div>                            
							<div className="grid-x medium-12 mt20">
                                <div>
                                    <Button
                                        className="float-left"
                                        type="submit"
                                        disabled={this.state.isLoading}
                                        content={this.props.tabMessage.toggleVSYesButton}
                                        onClick={() => this.continueValueStoryNavigation("VS")}
                                        color />
                                </div>                                
                                <div>
                                    <Button
                                        className="float-left"
                                        type="submit"
                                        disabled={this.state.isLoading}
                                        content={this.props.tabMessage.toggleVSNoButton}
                                        onClick={() => this.continueNavigation("VS")}
                                        color />
                                </div>
								<div>
                                    <Button
                                        className="float-left"
                                        type="submit"
                                        disabled={this.state.isLoading}
                                        content={this.props.tabMessage.toggleVSRevertButton}
                                        onClick={() => this.hideModal("VS")}
                                        color />
                                </div>
                            </div> 
                        </div>
                    </Modal>
                </div>
                <div>
                    <Modal
                        ref="SpendParameter"
                        modalStyle={alertModalStyleSpendParameter}
                        contentStyle={changeModalContentStyle}
                        backdropStyle={changeBackdropStyle}
                    >
                        <div className="grid-x grid-padding-x change-popup">
                            <div className="medium-12">
                                <button onClick={() => this.hideModal("SpendParameter")} className="close" />
                            </div>
                            <div className="medium-12 pt30">
                            {this.props.tabMessage.toggleVSMessage}
                            </div>                            
							<div className="grid-x medium-12 mt20">
                                <div>
                                    <Button
                                        className="float-left"
                                        type="submit"
                                        disabled={this.state.isLoading}
                                        content={this.props.tabMessage.toggleSpendParamYesButtonWithStrategy}
                                        onClick={() => this.continueSpendParameterNavigationWithWaveRecalc("SpendParameter")}
                                        color />
                                </div>
                                <div>
                                    <Button
                                        className="float-left"
                                        type="submit"
                                        disabled={this.state.isLoading}
                                        content={this.props.tabMessage.toggleSpendParamYesButton}
                                        onClick={() => this.continueSpendParameterNavigation("SpendParameter")}
                                        color />
                                </div>                                
                                <div>
                                    <Button
                                        className="float-left"
                                        type="submit"
                                        disabled={this.state.isLoading}
                                        content={this.props.tabMessage.toggleVSNoButton}
                                        onClick={() => this.continueNavigation("SpendParameter")}
                                        color />
                                </div>
								<div>
                                    <Button
                                        className="float-left"
                                        type="submit"
                                        disabled={this.state.isLoading}
                                        content={this.props.tabMessage.toggleVSRevertButton}
                                        onClick={() => this.hideModal("SpendParameter")}
                                        color />
                                </div>
                            </div> 
                        </div>
                    </Modal>
                </div>
            </div>
        );
    }
}

SubTabsPanel.propTypes = {
    classes: PropTypes.object.isRequired,
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            id: PropTypes.number.isRequired,
            component: PropTypes.object.isRequired,
        }).isRequired)
        .isRequired,
};

const mapStateToProps = state => {
    return {
        supplierDrilldownPendingChanges: state.spendDetails.supplierDrilldownPendingChanges,
        tabMessage: state.content.spendAnalysis.detailedFile,
        spendDetails: state.spendDetails,
        change: state.detailedFileChanges.changes,
        valueStory : state.valueStory,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        pendingChanges: changes => dispatch(pendingChanges(changes)),
        //defineScopeLoading: bool => dispatch(defineScopeLoading(bool)),
        saveKPIOnRedirect: changes => dispatch(saveKPIOnRedirect(changes)),
        saveValueStoryOnRedirect: changes => dispatch(saveValueStoryOnRedirect(changes)),
        deleteIntermediateKPIOnRedirect: changes => dispatch(deleteIntermediateKPIOnRedirect(changes)),
        isEnablementStrategyContainerPage: obj => dispatch(isEnablementStrategyContainerPage(obj)),
        isValueStoryPage:(obj)=>dispatch(isValueStoryPage(obj)),
        isSpendParameterPage: obj => dispatch(isSpendParameterPage(obj)),
        saveSpendParametersOnRedirect: changes => dispatch(saveSpendParametersOnRedirect(changes)),
        isWaveRecalcOnSpendParamRedirect: changes => dispatch(isWaveRecalcOnSpendParamRedirect(changes)),
    }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(SubTabsPanel));