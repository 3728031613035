import {
    INITIAL
} from './spendReducers'

export const kpis = (state = INITIAL, action) => {
	switch (action.type) {
        case 'TRACK_KPI_BENCHMARK_FILTERS':
			return { ...state, isLoading: false, kpiBenchMarkFilters: action.payload };

		default:
			return state
	}
}