import React, { Component } from 'react'
import { connect } from 'react-redux'
import Button from 'UI/Button'
import {
    reportBug,
    reportBugLoading,
    reportBugSuccess,
    reportBugFailure
} from 'actions/userActions'

class ReportBugContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedFile: null,
            addReportDataMsg: '',
            successMsg: '',
            errorMsg: '',
            bugDescription: ''
        }
        this.validateForReportData = this.validateForReportData.bind(this)
        this.handleRemoveSelectedFile = this.handleRemoveSelectedFile.bind(this)
        this.fileTypeValidation = this.fileTypeValidation.bind(this)
    }

    fileTypeValidation(filePath) {
        let allowedExtensions = /(\.jpeg|\.png|\.mp4|\.docx)$/i
        if (!allowedExtensions.exec(filePath)) {
            return false;
        } else return true
    }

    handleFileAttachment(e) {
        if (e.target.files.length > 0) {
            let file = e.target.files[0]
            var filePath = e.target.value;
            if (this.fileTypeValidation(filePath)) {
                let fileSize = file.size / 1024 / 1024; // in MB
                if (fileSize < 20) { //Max size to be uploaded
                    this.setState({ selectedFile: file })
                    //File upload 
                    let reader = new FileReader()
                    reader.readAsDataURL(file)
                    reader.onload = (e) => {
                        var base64result = e.target.result.split(',')[1];
                        this.setState({ addReportDataMsg: '', fileData: base64result, successMsg: this.props.content.fileUploadSuccessMsg, errorMsg: '' })
                    }
                }
                else {
                    this.setState({ errorMsg: this.props.content.fileSizeErrorMsg, successMsg: '' })
                }
            } else {
                this.setState({ errorMsg: this.props.content.fileTypeErrorMsg, successMsg: '' })
            }
        }
    }

    handleRemoveSelectedFile() {
        this.setState({ selectedFile: null, fileData: undefined, successMsg: '', errorMsg: '' })
    }

    onChange(e) {
        var descri = e.target.value
        this.setState({ bugDescription: e.target.value })
        descri.length > 0 && this.setState({ addReportDataMsg: '' })
    }

    // validates that user should either add description or attach file
    validateForReportData() {
        if (this.state.bugDescription === '' && this.state.fileData === undefined) {
            this.setState({ addReportDataMsg: this.props.content.reportDataMsg })
            return false
        } else
            return true
    }

    handleSubmit() {
        if (this.validateForReportData()) {
            const data = {
                bugDescription: this.state.bugDescription.replace(/\r\n|\r|\n/g, '<br />'),
                fileDetails: {
                    fileData: this.state.fileData !== undefined ? this.state.fileData : '',
                    fileName: this.state.selectedFile !== null ? this.state.selectedFile.name : ''
                },
                createdBy: this.props.user.user.Id,
                language: this.props.language.toUpperCase(),
                userEmail: localStorage.getItem('loggedinUser')  //email
            }
            var content = this.props.content;
            this.props.reportBug(data)
                .then((result) => {
                    setTimeout(() => {
                        if (result.status === 200) {
                            this.props.reportBugSuccess(content[result.status])
                            this.props.hideModal('reportBug')
                            this.props.setReportBugAlertVisibility(content[result.status])
                        }
                        else {
                            var failMsg = this.props.content.error
                            if (failMsg !== undefined) {
                                this.props.reportBugFailure(failMsg)
                            }
                            this.props.hideModal('reportBug')
                            this.props.setReportBugAlertVisibility(failMsg)
                        }
                    }, 500)

                }).catch((error) => {
                    this.props.reportBugFailure(content[error.response.status])
                })
        }
    }
    render() {
        return (
            <div>
                <div className="popup-content">
                    <div className="medium-12 title">{this.props.content.title}</div>
                    <div className="pt10">{this.props.content.bugDescInfo}</div>
                    <div className="pt10">
                        <textarea
                            rows="12"
                            cols="30"
                            type="text"
                            className="bugTextArea"
                            onChange={e => this.onChange(e)}
                            placeholder="Type bug description..."
                        />
                    </div>
                    <div>{this.props.content.informativeText}</div>
                    <div className="cell medium-7 grid-x pt10">
                        <label id="attachFile" className="button-upload pt10 chooseFileMargin">
                            {this.props.content.chooseFile}
                            <input
                                type="file"
                                id="File"
                                accept=".jpeg, .png, .mp4, .docx"
                                onChange={(e) => this.handleFileAttachment(e)}>
                            </input>
                        </label>
                        {this.state.selectedFile !== null ?
                            <div className="grid-x">
                                <div className="fileSelection">{this.state.selectedFile.name}</div>
                                <button
                                    onClick={this.handleRemoveSelectedFile}
                                    className='suggestedValueClose closeFileSelection'
                                />
                            </div> :
                            <span className="noFile">{this.props.content.noFile}</span>}
                        <div className="medium-2 marginL" >
                            <Button
                                type="submit"
                                content={this.props.content.submit}
                                disabled={this.props.user.loading}
                                onClick={this.handleSubmit.bind(this)}
                                color />
                        </div>
                    </div>
                    <div>
                        <div className="errors dispFileMsg">{this.state.errorMsg}</div>
                        <div className="success dispFileMsg">{this.state.successMsg}</div>
                        <div className="errors dispReportMsg">{this.state.addReportDataMsg}</div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        validation: state.content.validation,
        user: state.default,
        content: state.content.menu.ReportBug[0].ReportBugDetails,
        language: state.language
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        reportBug: (data) => dispatch(reportBug(data)),
        reportBugLoading: (bool) => dispatch(reportBugLoading(bool)),
        reportBugSuccess: (message) => dispatch(reportBugSuccess(message)),
        reportBugFailure: (error) => dispatch(reportBugFailure(error))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ReportBugContainer)