import React from 'react'
import { connect } from 'react-redux'
import { getQualityAssessmentData, updateQualityAssessmentData } from 'actions/spendActions'
import classNames from "classnames";
import { path } from 'Constants'
import { Control, Form, Errors, actions } from 'react-redux-form'

class QualityAssessment extends React.Component {
    constructor(props) {
        super()
        this.state = {
            tableData: [],
            tableHeaders: ['Field Name', 'Color', 'Warning Bottom Range', 'Warning Top Range', 'Recommendations'],
            updateData: [],
            isError: 'No',
            responseMsg: '',
            isSuccess: false,
            readOnly : props.user.userprofile.Roles[0].indexOf("Admin") > -1 ? false : true
        }
        this.onQualityAssessmentSave = this.onQualityAssessmentSave.bind(this)
    }

    componentDidMount() {
        this.loadData()
    }

    loadData() {
        this.props.getQualityAssessmentData()
            .then((response) => {
                if (response.data.Result.length > 0) {
                    this.setState({ tableData: response.data.Result })
                } else {
                    this.setState({ responseMsg: 'No result found. Please try reimporting the file.', isError: 'Yes', isLoading: false })
                }

            }).catch((error) => {
                if (error.stack.includes('Network Error')) {
                    this.setState({ responseMsg: 'API service is down. Please contact administrator.', isError: 'Yes', isLoading: false })
                }
                else {
                    this.setState({ responseMsg: this.props.content.error, isError: 'Yes', isLoading: false })
                }
            })
    }

    bottomRangeChanged(event) {
        let tableData = this.state.tableData;
        let id = event.target.id;
        tableData.map(item => {
            if (item.SORT_ORDER_ID == id) {
                item.WARNING_BOTTOM_RANGE = event.target.value
            }
        })
        let updateArr = this.state.updateData
        let temp = tableData.filter(item => item.SORT_ORDER_ID == id)
        updateArr.length > 0 ? updateArr.map((item, index) => item.SORT_ORDER_ID == temp[0].SORT_ORDER_ID ? updateArr[index] = temp[0] : updateArr.push(temp[0])) : updateArr.push(temp[0])
        this.setState({ tableData: tableData, updateData: updateArr })
    }

    topRangeChanged(event) {
        let tableData = this.state.tableData;
        let id = event.target.id;
        tableData.map(item => {
            if (item.SORT_ORDER_ID == id) {
                item.WARNING_TOP_RANGE = event.target.value
            }
        })
        let updateArr = this.state.updateData
        let temp = tableData.filter(item => item.SORT_ORDER_ID == id)
        updateArr.length > 0 ? updateArr.map((item, index) => item.SORT_ORDER_ID == temp[0].SORT_ORDER_ID ? updateArr[index] = temp[0] : updateArr.push(temp[0])) : updateArr.push(temp[0])
        this.setState({ tableData: tableData, updateData: updateArr })
    }

    recommendationChange(event) {
        let tableData = this.state.tableData;
        let id = event.target.id;
        tableData.map(item => {
            if (item.SORT_ORDER_ID == id) {
                item.RECOMMENDATION_TEXT = event.target.value
            }
        })
        let updateArr = this.state.updateData
        let temp = tableData.filter(item => item.SORT_ORDER_ID == id)
        updateArr.length > 0 ? updateArr.map((item, index) => item.SORT_ORDER_ID == temp[0].SORT_ORDER_ID ? updateArr[index] = temp[0] : updateArr.push(temp[0])) : updateArr.push(temp[0])
        this.setState({ tableData: tableData, updateData: updateArr })
    }

    onQualityAssessmentSave() {
        let uppdateArr = this.state.updateData
        this.setState({isLoading: true, responseMsg: ''})
        this.props.updateQualityAssessmentData(uppdateArr)
            .then((response) => {
                if (response.data.StatusCode == 200) {
                    this.setState({ updateData: [], isSuccess: true, responseMsg: 'Table Updated Successfully.', isLoading: false })
                } else {
                    this.setState({ responseMsg: 'No result found. ', isError: 'Yes', isLoading: false })
                }

            }).catch((error) => {
                if (error.stack.includes('Network Error')) {
                    this.setState({ responseMsg: 'API service is down. Please contact administrator.', isError: 'Yes', isLoading: false })
                }
                else {
                    this.setState({ responseMsg: this.props.content.error, isError: 'Yes', isLoading: false })
                }
            })
    }

    render() {
        return (
            <div className='grid-x grid-padding-x'>
                {(this.state.isLoading) ?
                    <img style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={path + `images/ajax-loader.gif`} alt="" />
                    : null}
                <div className="medium-12 pt5">
                    {this.state.isError == 'Yes' ? <div className="errors">{this.state.responseMsg}</div> : ''}
                    {this.state.isSuccess ? <div className="success">{this.state.responseMsg}</div> : ''}
                </div>
                {this.props.user.userprofile.Roles[0].indexOf("Admin") > -1 ?
                    <div className="medium-12" style={{ paddingBottom: '2px' }} >
                        <button className={classNames('float-right',
                            {
                                'hand-cursor': this.state.isLoading ? false : true,
                                'gray-scale': this.state.isLoading ? true : false
                            })}
                            onClick={this.onQualityAssessmentSave} disabled={this.state.isLoading ? true : false} type="submit" float >
                            <img width="25" title={'Save'} src="images/save_active.svg" />
                        </button>
                    </div> : null}
                <div className="medium-12">
                    <div className="grid-x basic-table">
                        <table>
                            <thead>
                                <tr className="eachRow">
                                    {this.state.tableHeaders.map(header => <th>{header}</th>)}
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.tableData.map(rowData => {
                                    return (<tr className="eachRow">
                                        <td>{rowData.FIELD_NAME}</td>
                                        <td>{rowData.WARNING_COLOR}</td>
                                        
                                        <td>
                                        <input id={rowData.SORT_ORDER_ID} 
                                        value={rowData.WARNING_BOTTOM_RANGE} 
                                        onChange={e => this.bottomRangeChanged(e)}
                                        readOnly= {this.state.readOnly} />
                                        </td>

                                        <td>
                                        <input id={rowData.SORT_ORDER_ID} 
                                        value={rowData.WARNING_TOP_RANGE} 
                                        onChange={e => this.topRangeChanged(e)}
                                        readOnly= {this.state.readOnly} />
                                        </td>

                                        <td><textarea
                                            id={rowData.SORT_ORDER_ID} rows="4"
                                            cols="30"
                                            type="text" value={rowData.RECOMMENDATION_TEXT}
                                            onChange={e => this.recommendationChange(e)}
                                            readOnly= {this.state.readOnly} /></td>
                                    </tr>)
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.default,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getQualityAssessmentData: () => dispatch(getQualityAssessmentData()),
        updateQualityAssessmentData: data => dispatch(updateQualityAssessmentData(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(QualityAssessment)