import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Control, Form, Errors, actions } from 'react-redux-form'
import moment from 'moment'
import Confirmation from 'UI/CustomeConfirmation'
// Settings
import { validators, path } from 'Constants'
import Popup from "reactjs-popup";
import { getCurrancyDetails, formatThousands } from 'utils/Utils'
import {
  getYourFileSuccess,
  getYourFileLoading,
  resetError,
  getYourFileFailure,
  setResultSummaryDetails,
  setTopDetails,
  LoadRequestDetails,
  GetAllRequestsAgainstValueStory,
  getYourRequestsDetails,
  ActivetDeactiveCurrentRequest,
  CleanRequestData,
  UpdateComment,
  currentActiveRequest,
  oldActiveRequest,
  setValueStoryDmciDetails,
  setYourFileRequestDetails,
  GetAllMappedColumn,
  setMappedColumn,
  getUserSpendSelections,
  trackUserSpendSelections,
  getCapIQDataBlank,
  solutionScopeChanged,
  getSpendFilesData,
  getQualityAssessmentData,
  kpiModelChanged
} from 'actions/spendActions'

import {updateIndustryDataInHana} from 'actions/userActions'

import {
  getUpdateEnablementKPIModel,
} from 'actions/kpiActions'

import { ControlTextE, SelectE, ScreenE, ButtonNativeE } from 'UI/CustomComponents/Controls';
import ButtonWithoutLoader from 'UI/ButtonWithoutLoader'

class LoadRequest extends Component {
  _isMounted = false;
  constructor(props) {
    super(props)
    this.state = {
      RequestIds: [],
      selectedRequestId: '',
      fileDetails: [],
      commentsRequest: '',
      allRequestOfVSIDnDMCID: [],
      currentActiveRequest: '',
      isSharedValueStory: false,
      sharedByDetails: '',
      btnDisabled: this.props.userData.VSId === undefined || this.props.userData.VSId === 0,
      isRequestLoaded: false,
      errorMessagesArr: [],
      warningMessageArr: [],
      spendFilesData: [],
      duplicateError: '',
      duplicateWarning: '',
      qualityAssessmentData: [],
      isExpand: false,
      isParamExpand: false,
      isCustomerSpendExpand: false
    }
    this.handleRequestChange = this.handleRequestChange.bind(this)
    this.redirect = this.redirect.bind(this)
    this.onChange = this.onChange.bind(this)
    this.handleOptionChange = this.handleOptionChange.bind(this)
    this.handleLoadButton = this.handleLoadButton.bind(this)
    this.deleteRequest = this.deleteRequest.bind(this)
    this.showModal = this.showModal.bind(this)
    this.modelRef = React.createRef()
    this.modelDelete = []
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadData()
    this.getQualityAssessmentData()

    if (this.state.currentActiveRequest.SAPRequester === undefined || this.state.currentActiveRequest.SAPRequester === this.props.user.user.Email) {
      this.setState({ isRequestLoaded: false })
    } else {
      this.props.getYourFileSuccess(this.getMessage('importAllowed'));
      this.setState({ isRequestLoaded: true })
    }

    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.props.resetError();
    // update comment for current active request
    if (this.props.spendDetails.currentActiveRequest && this.props.spendDetails.currentActiveRequest.UPDATED_COMMENTS !== '') {
      this.props.UpdateComment(this.props.spendDetails.currentActiveRequest).then(res => {
        //
      })
    }
   // this.formDispatch(actions.reset('yourFile'))
  }
  // To find the message from Json containt just pass the status code and find the message
  getMessage(keyToFindMessage) {
    return this.props.content[keyToFindMessage];
  }

  componentWillReceiveProps(nextProps, nextState) {

    if (this.props.userData !== nextProps.userData) {
      this.setState({ btnDisabled: nextProps.userData.VSId === undefined || nextProps.userData.VSId === 0 })
    }
  }

  componentDidUpdate(nextProps, nextState) {
    if (this.state.currentActiveRequest.DMC_ID !== nextState.currentActiveRequest.DMC_ID && this.state.currentActiveRequest.DMC_ID !== '') {
      this.setState({ isLoading: true, errorMessagesArr: [], warningMessageArr: [], duplicateError: '', duplicateWarning: '' })
      this.loadSpendFilesData()
    }
  }

  showModal(model) {

    model.current.show()

  }

  hideModal(model) {
    model.current.hide()
    this.props.getYourFileSuccess("");
  }

  getQualityAssessmentData() {
    this.props.getQualityAssessmentData()
      .then((response) => {
        if (response.data.Result.length > 0) {
          this.setState({ qualityAssessmentData: response.data.Result })
        } else {
          this.setState({ responseMsg: 'No result found. Please try reimporting the file.', isError: 'Yes', isLoading: false })
        }

      }).catch((error) => {
        if (error.stack.includes('Network Error')) {
          this.setState({ responseMsg: 'API service is down. Please contact administrator.', isError: 'Yes', isLoading: false })
        }
        else {
          this.setState({ responseMsg: this.props.content.error, isError: 'Yes', isLoading: false })
        }
      })
  }

  onChange(e) {
    var requestDetails = this.state.currentActiveRequest;
    //var currentActiveIndex = requestDetails.findIndex(a => a.ACTIVE_FLAG === true);
    //if(currentActiveIndex  > -1)
    //{
    requestDetails.UPDATED_COMMENTS = e.target.value;

    this.setState({ currentActiveRequest: requestDetails })
    this.props.currentActiveRequest(requestDetails);
    this.props.oldActiveRequest({...requestDetails});
    //}

    //alert(date);
  }

  loadData() {
    var userId = this.props.user.user.Id;
    this.props.getYourFileLoading(true)

    if (this.props.userData.Industry !== 0) {
      this.props.getCapIQDataBlank(false)
    }
    else {
      this.props.getCapIQDataBlank(true)
    }

    if (this.props.userData.Id !== this.props.user.user.Id) {
      userId = this.props.userData.Id
      this.setState({ isSharedValueStory: true })

    }

    if (this.props.spendDetails.requestFileDetails.length === 0) {
      if (this.state.RequestIds === undefined || this.state.RequestIds.length === 0) {
        this.props.getYourRequestsDetails(this.props.user.user.Email)
          .then(response => {
            this.setState({ fileDetails: response.data.Result })
            this.props.setYourFileRequestDetails(response.data.Result);
            this.setDropDownData(response.data.Result)
          }).catch(res => {
            this.props.setYourFileRequestDetails([]);
            //this.props.getYourFileFailure(this.getMessage('notFound'))
          })
      }
    }
    else {
      this.setDropDownData(this.props.spendDetails.requestFileDetails)
      this.setState({ fileDetails: this.props.spendDetails.requestFileDetails }, () => this.props.getYourFileSuccess());
    }

    if (userId !== undefined && userId !== "") {
      this.GetAllRequestsAgainstValueStory(userId);
      this.getAllMappedColumn(this.props.user.user.Id);
    }

  }

  setKPIMenuLabel(DMC_Id, VS_ID) {
    let dmcId = DMC_Id;
    let vsId = VS_ID;
    let user = this.props.user.user.Email;
    this.props.getUpdateEnablementKPIModel(dmcId, vsId, user, 'R', 1)
      .then((response) => {
        var data = response.data.Result[0];
        this.props.kpiModelChanged(data.KPI_MODEL);
      });
  }

  loadSpendFilesData() {
    let dmcId = this.props.spendDetails.currentActiveRequest.DMC_ID;
    let vsId = this.props.spendDetails.currentActiveRequest.VS_ID;
    let user = this.props.user.user.Email;
    //this.props.defineScopeLoading(true)
    this.props.getSpendFilesData(dmcId, vsId, user)
      .then((response) => {
        if (response.data.Result.length > 0) {
          let spendFilesData = response.data.Result
          //let spendFilesQualityFields = this.state.spendFilesQualityFields;
          let errorMessagesArr = []
          let warningMessageArr = []
          let total = spendFilesData.length;
          let mappedColumns = this.state.columnMap;
          let qualityArr = this.state.qualityAssessmentData;

          //DEDUP_STATUS validation check
          let duplicateError = ''
          let duplicateWarning = ''
          let count = 0;
          let warningDedupobj = qualityArr.filter(item => item.WARNING_COLOR == 'Yellow' && item.UI_HANA_FIELD_NAME == 'DEDUP_STATUS')
          let errorDedupobj = qualityArr.filter(item => item.WARNING_COLOR == 'Red' && item.UI_HANA_FIELD_NAME == 'DEDUP_STATUS')
          spendFilesData.map(item => {
            if (item['DEDUP_STATUS'] == 'Duplicated') count++;
          })
          let dedupPercentage = ((count / total) * 100).toFixed(2);
          if (dedupPercentage >= warningDedupobj.WARNING_BOTTOM_RANGE && dedupPercentage <= warningDedupobj.WARNING_TOP_RANGE) {
            duplicateWarning = `Your spend file has has ${dedupPercentage}% duplicate records. Please consider resubmitting your spend file after duplicates have been addressed. ${warningDedupobj.RECOMMENDATION_TEXT}`
          } else if (dedupPercentage >= errorDedupobj.WARNING_BOTTOM_RANGE && dedupPercentage <= errorDedupobj.WARNING_TOP_RANGE) {
            duplicateError = `Your spend file has ${dedupPercentage}% duplicate records. Quality of the analysis in AVATAR will be significantly impacted unless you address the duplicates and resubmit your spend file. ${errorDedupobj.RECOMMENDATION_TEXT}`
          }

          //quality assessment check 
          qualityArr.splice(0,2)         
          for (let i = 0; i < qualityArr.length; i++) {
            //let key = qualityArr[i]
            //let fieldName = mappedColumns.filter(item => item.ColumnField == key)
            let count = 0;
            spendFilesData.map(item => {
              if (item[qualityArr[i].UI_HANA_FIELD_NAME] == 0 || item[qualityArr[i].UI_HANA_FIELD_NAME] == null || item[qualityArr[i].UI_HANA_FIELD_NAME] == '') {
                count++;
              }
            })
            let percent = ((count / total) * 100).toFixed(2);
            if (percent >= qualityArr[i].WARNING_BOTTOM_RANGE && percent <= qualityArr[i].WARNING_TOP_RANGE) {

              let msg = `Your spend file is missing ${percent}% of ${qualityArr[i].FIELD_NAME}. ${qualityArr[i].RECOMMENDATION_TEXT}`
              //errorMessagesArr.push(msg)
              if (qualityArr[i].WARNING_COLOR == 'Yellow') warningMessageArr.push(msg)
              if (qualityArr[i].WARNING_COLOR == 'Red') {
                let errMsg = `Your spend file is missing ${percent}% of ${qualityArr[i].FIELD_NAME}. ${qualityArr[i].RECOMMENDATION_TEXT}`
                errorMessagesArr.push(errMsg)
              }
            }
          }

          this.setState({ isLoading: false, spendFilesData: spendFilesData, errorMessagesArr: errorMessagesArr, duplicateError: duplicateError, warningMessageArr: warningMessageArr, duplicateWarning: duplicateWarning })
        } else {
          //this.props.defineScopeLoading(false)
          this.setState({ responseMsg: 'No result found. Please try reimporting the file.', isError: 'Yes', isLoading: false })
        }

      }).catch((error) => {
        if (error.stack.includes('Network Error')) {
          //this.props.defineScopeLoading(false)
          this.setState({ responseMsg: 'API service is down. Please contact administrator.', isError: 'Yes', isLoading: false })
        }
        else {
          //this.props.defineScopeLoading(false)
          this.setState({ responseMsg: this.props.content.error, isError: 'Yes', isLoading: false })
        }
      })
  }

  setDropDownData(data) {
    var RequestIds = []


    // Needs to chnage location of code in above response once get data from API
    data.forEach(element => {
      var result = {
        label:
          (element.Buyer_Name + " | Request Id: " + element.DMC_ID +
            "| " + element.File_Name + " | " +
            moment(element.CREATE_TIMESTAMP).format('MMM DD,YYYY'))
        , value: element.DMC_ID
      };
      RequestIds.push(result);
    });


    this.setState({ RequestIds: RequestIds }, () => this.props.getYourFileSuccess());
  }

  GetAllRequestsAgainstValueStory(userId) {
    // if (this.props.spendDetails.valueStoryRequestDetails.length === 0) {
    if (this.props.userData.VSId !== undefined && this.props.userData.VSId > 0) {
      this.props.GetAllRequestsAgainstValueStory(userId, this.props.userData.VSId).
        then(response => {
          if (response.data.StatusCode === 200) {
            if (response.data.Result.length > 0) {
              var selectedRequest = response.data.Result.filter(item => item.ACTIVE_FLAG == true);
              if(selectedRequest.length > 0) 
              {
                this.setKPIMenuLabel(selectedRequest[0].DMC_ID, selectedRequest[0].VS_ID);
              }
            }
            this.setState({ allRequestOfVSIDnDMCID: response.data.Result }, () => this.setActiveRequest());
          }
          else {
            this.setState({ allRequestOfVSIDnDMCID: [] }, () => this.setActiveRequest())
          }
          this.props.setValueStoryDmciDetails(response.data.Result);
          // first time loaded and need not to show any message
        }).catch(res => {
          this.props.getYourFileFailure(this.getMessage('error'));
        })

      this.setActiveRequest();
    }
    //} 
    // else {
    //   this.setState({ allRequestOfVSIDnDMCID: this.props.spendDetails.valueStoryRequestDetails },
    //     () => this.setActiveRequest())
    // }
  }

  setActiveRequest() {
    var activeRequest = this.state.allRequestOfVSIDnDMCID.filter((element) => (element.ACTIVE_FLAG === true));
    if (activeRequest !== null && activeRequest.length > 0) {
      this.setState({ currentActiveRequest: activeRequest[0] });
      this.props.spendDetails.currentActiveRequest = activeRequest[0]
      this.props.currentActiveRequest(activeRequest[0]);
      this.props.oldActiveRequest({...activeRequest[0]});
      this.props.solutionScopeChanged(activeRequest[0]['SOLUTION_IN_SCOPE']);
      //Get dropdown values selected by users from database.
      if (activeRequest[0] !== null && activeRequest[0] !== undefined) {
        this.props.getUserSpendSelections(activeRequest[0].VS_ID, activeRequest[0].DMC_ID)
          .then(response => {
            this.props.trackUserSpendSelections(response.data.Result)
          }).catch(error => {
            this.props.getYourFileFailure(this.getMessage('error'));
          })
      }
    } else {
      this.setState({ currentActiveRequest: '' });
      this.props.spendDetails.currentActiveRequest = ''
      this.props.currentActiveRequest('');
      this.props.oldActiveRequest('');
    }
  }

  handleRequestChange(value) {
    this.props.getYourFileSuccess(''); // To remove the error/success message
    if (this.props.user.user.Id === this.props.userData.Id) {
      this.setState({ isRequestLoaded: false })
      var fileProcessingDate = this.state.fileDetails.filter((element) => (element.DMC_ID === value.value));
      this.formDispatch(actions.change('yourFile.RequestId', value.value.toString()));
      this.setState({ selectedRequestId: value.value })
    }
    else {
      this.setState({ isRequestLoaded: true })
      this.props.getYourFileFailure(this.getMessage('importAllowed'));
    }

  }

  redirect() {
    this.props.history.push(`/subTabsContainer`)
  }

  handleLoadButton(formValues) {
    // this.setState({ isRequestLoaded: true }) // to disable the load button
    // this.props.getYourFileLoading(true);
    // var index = this.state.allRequestOfVSIDnDMCID.findIndex(a => a.DMC_ID === this.state.selectedRequestId);
    // if (index === -1) {
    //   this.getRequestData(this.state.selectedRequestId, true);
    // }
    // else {
    //   this.showModal(this.modelRef)
    //   // if (this.showModal(this.modelRef))) {
    //   //   this.getRequestData(this.state.selectedRequestId, true);
    //   // }
    //   // else {
    //   //   this.props.getYourFileLoading(false);
    //   // }
    // }
    // this.setState({ isRequestLoaded: false }) // to unable the load button
    let dmcId = this.state.selectedRequestId;
    // this.props.updateIndustryDataInHana(this.props.userData, dmcId).then((result) => {
    //   if (result.status === 200) {
        this.props.getYourFileFailure("");
        this.props.getYourFileSuccess("");
        this.setState({ isRequestLoaded: true }) // to disable the load button
        this.props.getYourFileLoading(true);
        //var index = this.state.allRequestOfVSIDnDMCID.findIndex(a => a.DMC_ID === this.state.selectedRequestId);

        var index = this.state.allRequestOfVSIDnDMCID.findIndex(a => a.DMC_ID == this.state.selectedRequestId && a.IS_DELETED == false);

        if (this.props.user.userprofile.Roles[0].indexOf("Admin") > -1) {
          if (index === -1) {
            this.getRequestData(this.state.selectedRequestId, true);
          }
          else {
            this.showModal(this.modelRef)
          }
        }
        else {
          if (this.state.allRequestOfVSIDnDMCID.length == 0) {
            this.getRequestData(this.state.selectedRequestId, true);
          }
          else if (this.state.allRequestOfVSIDnDMCID.length > 0 && index == 0) {
            this.showModal(this.modelRef)
          }
          else {
            this.props.getYourFileFailure('To change your spend file please delete the current spend file.  Note that all information will be lost when you delete the current spend file.')
          }
        }

        this.setState({ isRequestLoaded: false }) // to unable the load button
    //   }         
    // })  
  }

  getRequestData(selectedRequestId, isNewRequest) {
    if (selectedRequestId !== '') {
      var selectedRequest = selectedRequestId;
      var selectedRequestDetails = this.state.fileDetails.filter((element) =>
        (element.DMC_ID === selectedRequest));
      selectedRequestDetails[0].VS_ID = this.props.userData.VSId;
      selectedRequestDetails[0].User_Id = this.props.user.user.Id;
      selectedRequestDetails[0].SAPRequester = this.props.user.user.Email
      this.props.LoadRequestDetails(selectedRequestDetails[0], isNewRequest).then((response) => {
        //console.log(response.data.StatusCode===200);
        if (response.data.StatusCode === 200) {
          this.props.setValueStoryDmciDetails(response.data.Result);
          this.setState({ allRequestOfVSIDnDMCID: response.data.Result }, () => this.setActiveRequest())
        }
        else {
          this.props.setValueStoryDmciDetails([]);
          this.setState({ allRequestOfVSIDnDMCID: '' }, () => this.setActiveRequest())
        }
        this.props.getYourFileSuccess(this.getMessage("200"));
      }).catch(res => {

        this.props.getYourFileFailure(this.getMessage('error'));
      })
    } else {
      this.props.getYourFileFailure("");
      this.formDispatch(actions.setErrors('yourFile.RequestId', true));
      this.formDispatch(actions.reset('yourFile.RequestId'));
    }
    if (isNewRequest) {
      this.modelRef.current.hide()
    }
  }

  deleteRequest(deleteRequestId) {
    var _inst = this;
    // if (window.confirm(this.getMessage("deleteConfirmation"))) {
    var index = this.state.allRequestOfVSIDnDMCID.findIndex((element) =>
      (element.APP_CNTRL_REQUEST_DATAId == deleteRequestId.currentTarget.id));
    var deleteRequestDetails = this.state.allRequestOfVSIDnDMCID.filter((element) =>
      (element.APP_CNTRL_REQUEST_DATAId == deleteRequestId.currentTarget.id));
    if (deleteRequestDetails !== undefined && deleteRequestDetails.length > 0) {
      this.props.CleanRequestData(deleteRequestDetails[0]).then((resp) => {
        if (resp.data.StatusCode === 200) {
          this.props.setValueStoryDmciDetails(resp.data.Result);
          let currentActiveRequest = this.state.currentActiveRequest;
          if(currentActiveRequest != "")
            currentActiveRequest.DMC_ID = ''
          this.setState({ allRequestOfVSIDnDMCID: resp.data.Result, currentActiveRequest: currentActiveRequest, errorMessagesArr: [], warningMessageArr: [], duplicateError: '', duplicateWarning: '' }, () => this.setActiveRequest())
        }
        else {
          this.props.setValueStoryDmciDetails([]);
          this.setState({ allRequestOfVSIDnDMCID: '', currentActiveRequest: currentActiveRequest, errorMessagesArr: [], warningMessageArr: [], duplicateError: '', duplicateWarning: '' }, () => this.setActiveRequest())
        }

        this.props.getYourFileSuccess(this.getMessage("deleteSuccess"));
      }).catch(res => {
        this.props.getYourFileFailure(this.getMessage('error'));
      })
    } else {
      this.props.getYourFileFailure(this.getMessage('error'));
    }
    _inst.modelDelete[index].current.hide();
    //}
  }

  handleOptionChange(value, row) {
    this.props.getYourFileLoading(true);
    var requestDetails = this.state.allRequestOfVSIDnDMCID;
    this.setKPIMenuLabel(row.DMC_ID, requestDetails[0].VS_ID);
    var currentActiveIndex = requestDetails.findIndex(a => a.ACTIVE_FLAG === true);

    //Case when there is no any active request index will be -1
    if (currentActiveIndex > -1) {
      //update comment for current request and then deactivate the current request
      if (this.props.spendDetails.currentActiveRequest.UPDATED_COMMENTS !== '') {
        this.props.UpdateComment(this.props.spendDetails.currentActiveRequest).then(res => {
          //
        })
      }
      requestDetails[currentActiveIndex].ACTIVE_FLAG = false;
    }


    var newActiveIndex = requestDetails.findIndex(item => item.APP_CNTRL_REQUEST_DATAId == value.target.value)
    if (newActiveIndex > -1) {
      requestDetails[newActiveIndex].ACTIVE_FLAG = value.target.checked;

    }

    this.setState({ allRequestOfVSIDnDMCID: requestDetails }, () => this.setActiveRequest())
    this.props.ActivetDeactiveCurrentRequest(requestDetails[newActiveIndex]).then(resp => {
      this.setState({ allRequestOfVSIDnDMCID: resp.data.Result }, () => this.setActiveRequest())
      this.props.setResultSummaryDetails([]);
      this.props.setTopDetails([]);
      this.props.getYourFileSuccess();

    }).catch(res => {
      this.props.getYourFileFailure(this.getMessage('error'));
    })
    this.setState({ RequestSelect: value.target.value });
  }

  getAllMappedColumn(userId) {
    if (userId !== undefined) {
      this.props.getAllMappedColumn(userId).
        then(response => {
          let columnMap = response.data.Result;
          console.log(columnMap)
          this.setState({ columnMap: response.data.Result })
          this.props.setMappedColumn(response.data.Result);
        }).catch(res => {
          this.props.getYourFileFailure(this.getMessage('error'));
        })
    }
    else {
      //
    }
  }

  onExpandClick(e) {
    switch (e.target.id) {
      case "loadReq":
        this.setState({ isExpand: !this.state.isExpand })
        break;
      case "spendParam":
        this.setState({ isParamExpand: !this.state.isParamExpand })
        break;
      case "customerSpend":
        this.setState({ isCustomerSpendExpand: !this.state.isCustomerSpendExpand })
        break;
    }
  }

  render() {
    var okButtonFlag = true
    //this.modelDelete = React.createRef();
    var fileDetails = (instance) => {

      var allDataToBind = this.state.allRequestOfVSIDnDMCID;
      if (allDataToBind.length > 0) {
        var allRows = [];
        for (let index = 0; index < allDataToBind.length; index++) {
          const element = allDataToBind[index];
          instance.modelDelete[index] = React.createRef()
          var deleteMsg = ''
          if (element.Created_By === instance.props.user.user.Id) {
            deleteMsg = instance.getMessage("deleteConfirmation")
            okButtonFlag = true
          } else {
            deleteMsg = instance.getMessage("deleteReqOriginatorMsg")
            okButtonFlag = false
          }
          allRows.push(
            <tr className=" medium-12 requestRow" style={{ backgroundColor: element.ACTIVE_FLAG === true ? "#e9f4fd" : "white" }}>
              <td>
                <div>
                  <label className="radiocontainer">
                    <input type="radio" value={element.APP_CNTRL_REQUEST_DATAId}
                      checked={element.ACTIVE_FLAG}
                      onChange={(e) => this.handleOptionChange(e, element)} />
                    <span class="checkmark"></span>
                  </label>
                </div>
              </td>
              <td className="tableContent">
                {element.Buyer_Name}
              </td>
              <td>
                {currentActiveRequest !== undefined && currentActiveRequest.SAPRequester !== undefined ? (currentActiveRequest.SAPRequester) : element.SAPRequester !== undefined ? element.SAPRequester : ''}
              </td>
              <td className="tableContent" style={{ textAlign: 'right', paddingRight: '70px' }}>
                {element.DMC_ID}
              </td>
              <td className="tableContent">
                {getCurrancyDetails(this.props.currencyCodes, element.Currency)[0]}
              </td>
              <td className="tableContent">
                {moment(element.CREATE_TIMESTAMP).format('MMM DD,YYYY')}
              </td>
              <td>
                {currentActiveRequest !== undefined && currentActiveRequest.UPDATED_TIMESTAMP !== undefined ? moment(currentActiveRequest.UPDATED_TIMESTAMP).format('MMM DD,YYYY') : ''}
              </td>
              <td className="tableContent" style={{ textAlign: 'right', paddingRight: '70px' }}>
                {formatThousands(element.Number_Of_Records)}
              </td>
              <td>
                {/* <label value={element.APP_CNTRL_REQUEST_DATAId} onClick={(e) => this.deleteRequest(e)} > X </label> */}
                <ButtonNativeE value={element.APP_CNTRL_REQUEST_DATAId}
                  onClick={() => instance.showModal(instance.modelDelete[index])} className='suggestedValueClose' />
                <Confirmation
                  confirmMessage={deleteMsg}
                  OkButtonAction={(e) => instance.deleteRequest(e)}
                  CancelButtonAction={() => instance.hideModal(instance.modelDelete[index])}
                  id={element.APP_CNTRL_REQUEST_DATAId}
                  ref={instance.modelDelete[index]} instance={instance}
                  okButtonFlag={okButtonFlag}
                />
              </td>
            </tr>
          );
        }
        return allRows;
      }
      else {
        return null;
      }
    };

    var currentActiveRequest;
    if (this.state.currentActiveRequest !== '' && this.state.currentActiveRequest !== null) {
      currentActiveRequest = this.state.currentActiveRequest
    }

    return (
      <ScreenE readOnly={!this.props.userData.AllowEdit}> 

        <div className="grid-x grid-padding-x">
          {/* <div className='grid-x pt30'> */}


          <div className="grid-x medium-12 pl30 pr30">
            <Form
              className="grid-x medium-12"
              model='yourFile' method="post"
              getDispatch={dispatch => (this.formDispatch = dispatch)}
            //onSubmit={(formValue) => this.handleLoadButton(formValue)}
            >
              <div class='grid-x medium-12 pt20'>
                <div className='medium-12'>
                  <div className='grid-x'>
                    <div className="medium-12">
                      {(this.props.spendDetails.isLoading) ?
                        <img style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={path + `images/ajax-loader.gif`} alt="" />
                        : null
                      }
                    </div>
                    <div className="medium-12 hoverDiv" >
                      <div className="dropDownHeader">{this.props.content.requestId}</div>

                      <Popup className="report-tooltip"
                        trigger={<div className="grid-x pt5">
                          <div className='medium-9'>
                            <SelectE
                              className="border-with-DD dropDownContent"
                              model='yourFile.RequestId'
                              id='RequestId'
                              autocomplete='true'
                              placeholder={this.props.content.requestId}
                              value={
                                this.state.RequestIds.length > 0
                                  ? this.state.RequestIds.filter(
                                    option =>
                                      option.value === this.state.selectedRequestId
                                  )
                                  : []}
                              onChange={this.handleRequestChange}
                              validators={{ required: validators.required }}
                              validateOn="change"
                              options={this.state.RequestIds}
                              isSearchable={false}
                              noOptionsMessage={() => null}
                              isDisabled={this.state.btnDisabled || !this.props.userData.AllowEdit}
                            />
                            <Errors
                              className="errors errorsMargin"
                              model="yourFile.RequestId"
                              show="touched"
                              messages={{
                                required: this.props.validation.required
                              }}
                            />
                            <ControlTextE
                              model="yourFile.RequestId"
                              id="yourFile.RequestId1"
                              hidden
                              value={this.state.selectedRequestId}
                              defaultValue={this.state.selectedRequestId}
                              validators={{
                                required: validators.required
                              }}
                              disabled={this.props.spendDetails.isLoading}
                              validateOn="change"
                            />
                          </div>
                          <div class='medium-3'>
                            <ButtonWithoutLoader
                              type='submit'
                              content={this.props.content.loadButton}
                              //onClick={this.handleLoadButton}
                              //onClick={() => this.showModal(this.modelRef)}
                              onClick={(formValue) => this.handleLoadButton(formValue)}
                              color
                              disabled={this.props.spendDetails.isLoading || this.state.btnDisabled || this.state.isRequestLoaded || !this.props.userData.AllowEdit}
                            />
                            <Confirmation
                              confirmMessage={this.getMessage("reImportMessage")}
                              OkButtonAction={() => this.getRequestData(this.state.selectedRequestId, true)}
                              CancelButtonAction={() => this.hideModal(this.modelRef)}
                              open={this.state.open}
                              onOpen={this.closePopUp}
                              okButtonFlag={okButtonFlag}
                              ref={this.modelRef} instance={this}
                            />
                          </div>

                        </div>} 
                        position="center center"
                        on="hover"
                        {...(!this.state.btnDisabled && { disabled: true })}
                      >
                        {this.props.content.hoverText}
                      </Popup>

                      <div className="grid-x messages">
                        <div className="medium-12 pt5">
                          {this.props.spendDetails.error !== null && typeof this.props.spendDetails.error !== 'object' ? <div className="errors">{this.props.spendDetails.error}</div> : ''}
                          {this.props.spendDetails.message !== null && typeof this.props.spendDetails.message !== 'object' ? <div className="success">{this.props.spendDetails.message}</div> : ''}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>

            <div className='grid-x medium-12 pt30'>
              <div className="medium-8 medium-offset-4 section-header">
                {this.props.content.mNcTitle}
              </div>
              <div className=" backgroundColor medium-12 mt5">
                <div className="grid-x grid-padding-x">
                  <div className='cell medium-12'>
                    <table className="buyer-table">
                      <thead>
                        <tr>
                          <th> </th>
                          <th className='tableHeader'>{this.props.content.tblCol1}</th>
                          <th className='tableHeader'>{this.props.content.tblSAPRequester}</th>
                          <th className='tableHeader'>{this.props.content.tblCol2}</th>
                          <th className='tableHeader'>{this.props.content.tblCol3}</th>
                          <th className='tableHeader'>{this.props.content.tblCol4}</th>
                          <th className='tableHeader'>{this.props.content.tblLastEditDate}</th>
                          <th className='tableHeader'>{this.props.content.tblCol5}</th>
                          <th> </th>
                        </tr>
                      </thead>

                      <tbody className=" medium-12">
                        {
                          fileDetails(this)
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div>
              {(this.state.isLoading) ?
                <img style={{ display: 'inline-block', marginLeft: 'auto', marginRight: 'auto' }} src={path + `images/ajax-loader.gif`} alt="" />
                : this.state.duplicateWarning !== '' ? <div className="warnings">
                  <div>Duplicate Assessment</div>
                  <div className='pl20'>{this.state.duplicateWarning}</div>
                </div> : ''}
              {this.state.warningMessageArr.length > 0 ? <div className="warnings">
                <div>Field Assessment</div>
                <ul>{this.state.warningMessageArr.map(item => <li className='pl20'>{item}</li>)}</ul>
              </div> : ''}
              {this.state.duplicateError !== '' ? <div className="errors">
                <div>Duplicate Assessment</div>
                <div className='pl20'>{this.state.duplicateError}</div>
              </div> : ''}
              {this.state.errorMessagesArr.length > 0 ? <div className="errors">
                <div>Field Assessment</div>
                <ul>{this.state.errorMessagesArr.map(item => <li className='pl20'>{item}</li>)}</ul>
              </div> : ''}
            </div>
          </div>
        </div>
      </ScreenE>
    )
  }
}

const mapStateToProps = state => {
  return {
    // language: state.language,
    userData: state.valueStory,
    user: state.default,
    content: state.content.spendAnalysis.yourFile,
    validation: state.content.validation,
    spendDetails: state.spendDetails,
    currencyCodes: state.content.discovery.form.currencyCodes,
    currencySymbols: state.content.CurrencySymbols,
    // validation: state.content.validation
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getYourFileSuccess: message => dispatch(getYourFileSuccess(message)),
    getYourFileFailure: error => dispatch(getYourFileFailure(error)),
    LoadRequestDetails: (requestDetails, isNewRequest) => dispatch(LoadRequestDetails(requestDetails, isNewRequest)),
    getYourRequestsDetails: (email) => dispatch(getYourRequestsDetails(email)),
    getYourFileLoading: bool => dispatch(getYourFileLoading(bool)),
    resetError: error => dispatch(resetError(error)),
    GetAllRequestsAgainstValueStory: (userId, valueStoryId) => dispatch(GetAllRequestsAgainstValueStory(userId, valueStoryId)),
    ActivetDeactiveCurrentRequest: requestDetails => dispatch(ActivetDeactiveCurrentRequest(requestDetails)),
    CleanRequestData: requestDetails => dispatch(CleanRequestData(requestDetails)),
    UpdateComment: requestDetails => dispatch(UpdateComment(requestDetails)),

    currentActiveRequest: (requestDetails) => dispatch(currentActiveRequest(requestDetails)),
    oldActiveRequest: (requestDetails) => dispatch(oldActiveRequest(requestDetails)),
    setResultSummaryDetails: data => dispatch(setResultSummaryDetails(data)),
    setTopDetails: data => dispatch(setTopDetails(data)),
    setValueStoryDmciDetails: (data) => dispatch(setValueStoryDmciDetails(data)),
    setYourFileRequestDetails: (requests) => dispatch(setYourFileRequestDetails(requests)),
    getAllMappedColumn: (userId) => dispatch(GetAllMappedColumn(userId)),
    setMappedColumn: (data) => dispatch(setMappedColumn(data)),
    getUserSpendSelections: (VSId, DMCId) => dispatch(getUserSpendSelections(VSId, DMCId)),
    trackUserSpendSelections: (userSelections) => dispatch(trackUserSpendSelections(userSelections)),
    getCapIQDataBlank: bool => dispatch(getCapIQDataBlank(bool)),
    solutionScopeChanged: (val) => dispatch(solutionScopeChanged(val)),
    getSpendFilesData: (dmcid, vsid, userid) => dispatch(getSpendFilesData(dmcid, vsid, userid)),
    getQualityAssessmentData: () => dispatch(getQualityAssessmentData()),
    kpiModelChanged: (val) => dispatch(kpiModelChanged(val)),
    getUpdateEnablementKPIModel: (DMCId, VSId, userEmail, ReadWriteFlag, EnablementKPIModel) => dispatch(getUpdateEnablementKPIModel(DMCId, VSId, userEmail, ReadWriteFlag, EnablementKPIModel)),
    updateIndustryDataInHana: (formValues,DMCID) => dispatch(updateIndustryDataInHana(formValues,DMCID))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoadRequest)
