import React, { Component } from 'react'
import { connect } from 'react-redux'
import VerticleBarChart from 'components/UI/VerticleBarChart'
import * as d3 from 'd3'
import { convertToMillion, round, formatThousands } from 'utils/Utils'
import {
    getNetworkStatsData,
    setPopUpTabLoadingStatus
} from 'actions/spendActions'

class IndustryNetworkStats extends Component {
    constructor(props) {
        super(props)
        this.state = {
            documentSpendData: [],
            documentCountData: [],
            supplierRelationshipCountData: []
        }
        this.drawChart = this.drawChart.bind(this);
    }

    componentDidMount() {
        this.props.setPopUpTabLoadingStatus(true);
        this.props.getNetworkStatsData(this.props.spendDetails.industryGICS_Details.SKEY)
            .then((response) => {
                this.props.setPopUpTabLoadingStatus(false)
                if (response.status === 200) {
                    let documentData = response.data.Result['SP_UI_INDUSTRY_DRILL_AN_DOC_TYPE']
                    let spendData = response.data.Result['SP_UI_INDUSTRY_DRILL_AN_INV_PO_SPEND']
                    let supplierRelationshipData = response.data.Result['SP_UI_INDUSTRY_DRILL_AN_SUPP_REL_COUNT']

                    let reorderDocumentData = [];
                    let reorderSpendData = [];
                    let formatDocumentData = [];
                    let formatSpendData = [];
                    let formatCountData = [];

                    let poSpend = spendData.find(item => item['SPEND_TYPE'] === 'PO Spend')
                    if (poSpend !== null && poSpend !== undefined)
                        reorderSpendData.push(poSpend)
                    let invoiceSpend = spendData.find(item => item['SPEND_TYPE'] === 'Invoice Spend')
                    if (invoiceSpend !== null && invoiceSpend !== undefined)
                        reorderSpendData.push(invoiceSpend)
                    let purchaseOrder = documentData.find(item => item['DOCUMENT_TYPE'] === 'PurchaseOrder')
                    if (purchaseOrder !== null && purchaseOrder !== undefined)
                        reorderDocumentData.push(purchaseOrder)
                    let POConfirmation = documentData.find(item => item['DOCUMENT_TYPE'] === 'ConfirmationDocument')
                    if (POConfirmation !== null && POConfirmation !== undefined)
                        reorderDocumentData.push(POConfirmation)
                    let shipNotices = documentData.find(item => item['DOCUMENT_TYPE'] === 'ShipNoticeDocument')
                    if (shipNotices !== null && shipNotices !== undefined)
                        reorderDocumentData.push(shipNotices)
                    let invoices = documentData.find(item => item['DOCUMENT_TYPE'] === 'InvoiceDetailRequest')
                    if (invoices !== null && invoices !== undefined)
                        reorderDocumentData.push(invoices)

                    reorderSpendData.map(function (item, index) {
                        let rank = item['DOC_COUNT_RANK'];
                        let objSpend = {
                            'name': item['SPEND_TYPE'], 'value': item['DOCUMENT_SPEND_AMT'], 'rank': rank !== null ? rank + ' of ' + item['GICS_LEVEL_COUNT'] : 'NA'
                        }
                        formatSpendData.push(objSpend)
                    })
                    reorderDocumentData.map(function (item, index) {
                        let documentName = item['DOCUMENT_TYPE']
                        switch (item['DOCUMENT_TYPE']) {
                            case 'ConfirmationDocument':
                                documentName = 'PO Confirmation'
                                break;
                            case 'InvoiceDetailRequest':
                                documentName = 'Invoice'
                                break;
                            case 'PurchaseOrder':
                                documentName = 'Purchase Order'
                                break;
                            case 'ShipNoticeDocument':
                                documentName = 'Ship Notice'
                                break;
                            default:
                                break
                        }
                        let rank = item['DOC_COUNT_RANK']
                        let objDocCount = {
                            'name': documentName, 'value': item['DOCUMENT_COUNT'], 'rank': rank !== null ? rank + ' of ' + item['GICS_LEVEL_COUNT'] : 'NA'
                        }
                        formatDocumentData.push(objDocCount)
                    })
                    supplierRelationshipData.map(function (item, index) {
                        let objCount = {
                            'supplierCount': item['Total Supplier Count'], 'relationshipCount': item['Total Relationship Count']
                        }
                        formatCountData.push(objCount)
                    })
                    //Object.assign(this.state, { documentCountData: formatDocumentData, documentSpendData: formatSpendData });
                    this.setState({ documentCountData: formatDocumentData, documentSpendData: formatSpendData, supplierRelationshipCountData: formatCountData }, () => this.drawChart())
                }
            }).catch((error) => {
                this.props.setPopUpTabLoadingStatus(false)
            })
    }

    drawChart = () => {
        d3.select("#SpendChart").selectAll("svg").remove();
        d3.select("#CountChart").selectAll("svg").remove();
        //Currency is always in $ - AV-443
        VerticleBarChart(this.state.documentSpendData, '$', 'SpendChart', this.props.industryNetworkStats.spendChartName, this.props.industryNetworkStats.documentSpend, 304, 325);
        VerticleBarChart(this.state.documentCountData, '$', 'CountChart', this.props.industryNetworkStats.countChartName, this.props.industryNetworkStats.documentCount, 504, 325)
    }

    render() {
        return (
            <div>
                <div className="grid-x">
                    <div className="tab-description">{this.props.industryNetworkStats.tabHeader}</div>
                </div>
                <div className="grid-x medium-12 pt10">
                    <div className="blue-bg">
                        <div className="grid-x grid-padding-x">
                            <div className="float-left">
                                <div className="title">{this.props.industryNetworkStats.supplierCount}</div>
                                <div className="value">{this.state.supplierRelationshipCountData.length === 0 ? null : formatThousands(round(Number(this.state.supplierRelationshipCountData[0]['supplierCount']), 2).toFixed(0))}</div>
                            </div>
                            <div className="float-left pl20">
                                <div className="title">{this.props.industryNetworkStats.relationshipCount}</div>
                                <div className="value">{this.state.supplierRelationshipCountData.length === 0 ? null : formatThousands(round(Number(this.state.supplierRelationshipCountData[0]['relationshipCount']), 2).toFixed(0))}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid-x medium-12">
                    <div className="medium-1"></div>
                    <div style={{ marginLeft: '-20px' }} id="SpendChart" className="medium-4"></div>
                    <div className="medium-1"></div>
                    <div id="CountChart" className="medium-6"></div>
                </div>
                <div className="grid-x medium-12">
                    <div className="medium-1"></div>
                    {/* <div style={{ marginLeft: '-20px', marginTop: '25px', marginBottom: '-40px' }} className="medium-4"> */}
                    <div style={{ marginLeft: '70px', marginTop: '25px', marginBottom: '-40px' }} className="medium-4">
                        <UserTable
                            gridData={this.state.documentSpendData}
                            documentType="spend"
                            currencySymbols={this.props.currencySymbols}
                            spendDetails={this.props.spendDetails}
                            document={this.props.industryNetworkStats.document}
                            documentSpend={this.props.industryNetworkStats.documentSpend}
                            l4Rank={this.props.industryNetworkStats.l4Rank}
                        />
                    </div>
                    <div className="medium-1"></div>
                    <div style={{ marginTop: '25px', marginBottom: '-40px' }} className="medium-4">
                        <UserTable
                            gridData={this.state.documentCountData}
                            documentType="count"
                            currencySymbols={this.props.currencySymbols}
                            spendDetails={this.props.spendDetails}
                            document={this.props.industryNetworkStats.document}
                            documentCount={this.props.industryNetworkStats.documentCount}
                            l4Rank={this.props.industryNetworkStats.l4Rank}
                        />
                    </div>
                    <div style={{ marginTop: '20px', paddingLeft: '30px' }} className="grid-x font12 pt10 align-left pt20">
                        <div className="medium-12 font12 pt10 align-left" style={{ fontStyle: 'italic' }}>
                            *{this.props.industryNetworkStats.statsFooter}
                        </div>
                        <div className="medium-12 font12 pt10 align-left" style={{ fontStyle: 'italic' }}>
                            **{this.props.industryNetworkStats.currencyNote}
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}

class UserTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    render() {
        let self = this;
        let documentData = this.props.gridData.map(function (data) {
            return (<UserRow
                documentType={self.props.documentType}
                data={data}
                currencySymbols={self.props.currencySymbols}
                spendDetails={self.props.spendDetails}
            />
            );
        });
        return (
            <div className="basic-table">
                <table>
                    <thead>
                        <tr style={{ backgroundColor: '#c2c2c2' }}>
                            <th>{self.props.document}</th>
                            <th style={{ textAlign: 'right', paddingRight: '75px' }}>
                                {self.props.documentType === "count" ? self.props.documentCount : self.props.documentSpend}</th>
                            {/* {self.props.documentType === "count" ?
                                <th style={{ textAlign: 'right' }}>Supplier Count</th> : null}
                            {self.props.documentType === "count" ?
                                <th style={{ textAlign: 'right' }}>Relationship Count</th> : null} */}
                            <th style={{ textAlign: 'right' }}>{self.props.l4Rank}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {documentData}
                    </tbody>
                </table>
            </div>
        );
    }
}

class UserRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    render() {
        return (
            <tr className="eachRow">
                <td>{this.props.data.name}</td>
                <td style={{ textAlign: 'right', paddingRight: '75px' }}>{this.props.documentType === "spend" ?
                    //this.props.currencySymbols[this.props.spendDetails.currentActiveRequest.Currency.trim()] + convertToMillion(this.props.data.value, true) : formatThousands(round(Math.abs(Number(this.props.data.value)) / 1000, 2).toFixed(2)) + " K"}</td>
                    //Currency is always in $ as this currency doen't depend on Spend file currency - AV-443
                    '$' + convertToMillion(this.props.data.value, true) : formatThousands(round(Number(this.props.data.value), 2).toFixed(0))}</td>
                {/* {this.props.documentType === "count" ?
                    <td style={{ textAlign: 'right' }}>{formatThousands(this.props.data.supplierCount)}</td> : null}
                {this.props.documentType === "count" ?
                    <td style={{ textAlign: 'right' }}>{formatThousands(this.props.data.relationshipCount)}</td> : null} */}
                <td style={{ textAlign: 'right' }}>{this.props.data.rank}</td>
            </tr>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        content: state.content,
        ui: state.content.ui,
        user: state.default,
        industryNetworkStats: state.content.spendAnalysis.industryDrilldown.industryNetworkStats,
        spendDetails: state.spendDetails,
        currencySymbols: state.content.CurrencySymbols,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getNetworkStatsData: (GICSID) => dispatch(getNetworkStatsData(GICSID)),
        setPopUpTabLoadingStatus: bool => dispatch(setPopUpTabLoadingStatus(bool)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(IndustryNetworkStats); 