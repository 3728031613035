
import React, { Component } from 'react'
import * as d3 from 'd3'
import { convertToMillion, formatThousands } from 'utils/Utils'
import { uid } from 'react-uid'
import { connect } from 'react-redux'

import groupbarchart from './GroupBarChart'
import lineChart from './ScatterPlotChart'
import {
    enablementSuccess,
    enablementLoading,
    enablementFailure,
    resetError,
    getConcentrationAnalysis
} from 'actions/spendActions'

class GroupBarScatterPlotChart extends Component {
    _isMounted = false;
    constructor(props) {
        super(props)
        this.state = {
            grpbar: '',
        }

    }

    componentDidMount() {
        this._isMounted = true;
    }

    checkedColumn(data, columnName) {
        var isColumnValid
        for (var i = 0; i < data.length; i++) {
            if (data[i][columnName] !== 0 && data[i][columnName] !== null) {
                return true;
            } else {
                isColumnValid = false;
            }
        }
        return isColumnValid
    }

    componentWillReceiveProps(nextProps) {
        //if (nextProps.data !== this.props.data) {
        d3.select("#groupbarchart").selectAll("g").remove();
        d3.select("#groupbarchart").selectAll("path").remove();


        var isColumn1Valid = this.checkedColumn(nextProps.data, nextProps.column1)
        var isColumn2Valid = this.checkedColumn(nextProps.data, nextProps.column2)

        if (nextProps.isShowMatchedData) {
            var groupBarSVG = groupbarchart(nextProps.column1, nextProps.column2, "PERCENTAGE_OF_TRANSACTIONS",
                ["SUPPLIERS_COUNT", "MATCHED_SUPPLIERS_COUNT"],
                nextProps.data, "groupbarchart", nextProps.leftAxisText)


            if (isColumn1Valid) {
                lineChart(false, "PERCENTAGE_OF_TRANSACTIONS", nextProps.column1,
                    nextProps.data, 'lineChart', groupBarSVG, '#f07e37', nextProps.column1, nextProps.leftAxisText)
            }

            if (isColumn2Valid) {
                lineChart(false, "PERCENTAGE_OF_TRANSACTIONS", nextProps.column2,
                    nextProps.data, 'lineChart', groupBarSVG, '#f0d737', nextProps.column1, nextProps.leftAxisText)
            }

        }
        else {
            var groupBarSVG = groupbarchart(nextProps.column1, "", "PERCENTAGE_OF_TRANSACTIONS",
                ["SUPPLIERS_COUNT"],
                nextProps.data, "groupbarchart", nextProps.leftAxisText)

            lineChart(false, "PERCENTAGE_OF_TRANSACTIONS", nextProps.column1, nextProps.data,
                '', groupBarSVG, '#f07e37', nextProps.column1, nextProps.leftAxisText)

        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        return (
            <div id="chart" className="medium-12 cell">                
                <svg id="groupbarchart" preserveAspectRatio="xMinYMin meet" style={{ 'overflow': 'inherit' }}>
                </svg>
            </div>
        )
    }
}


const mapStateToProps = state => {
    return {
        user: state.default,
        content: state.content.spendAnalysis.enablement,
        validation: state.content.validation,
        spendDetails: state.spendDetails,
        currencySymbols: state.content.CurrencySymbols,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        enablementSuccess: message => dispatch(enablementSuccess(message)),
        enablementFailure: error => dispatch(enablementFailure(error)),
        enablementLoading: bool => dispatch(enablementLoading(bool)),
        getConcentrationAnalysis: (data) => dispatch(getConcentrationAnalysis(data)),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupBarScatterPlotChart)
