import React from 'react'
import PropTypes from 'prop-types'

// Utils
import classnames from 'classnames'

// UI
import FaAngleRight from 'react-icons/lib/fa/angle-right'

const SubItemsDisplay = (props) =>
	<ul className={
		classnames(
			'menu__admin-accordion',
			{ 'menu__admin-accordion--expanded': props.menuExpanded   })
	}>

	{       
		props.content.map((data, i) =>
		  (props.user !== null && props.user.applicationFeatures !== undefined && (Object.values(props.user.applicationFeatures).map((feature)=> feature.Action === data.action).indexOf(true) > -1) ) ?
			<li key={i}>
				<button onClick={(e) => props.handleMenuAction(e, data.action)}>{data.text }</button>
			</li>
			: null
	)}
	</ul>

 

const isSubMenuEnable = (data, user, userData) => {
    var showMenu = false
    if (user !== null  && typeof(data) === 'object') {
	 
			if(data.action!== undefined ){
				showMenu = (user.indexOf(data.action)> -1)
			}
 
			if(data.subItems !== undefined)
			{
				data.subItems.map(subitem => {
					if (user.indexOf(subitem.action) > -1) {
						  showMenu = true; 
					}
				})
			}
		 	if(data.action === "saveAs" && (userData===null || userData===undefined || userData.VSId===null||userData.VSId===undefined)){
				 	showMenu = false; 
			}
    }
    return showMenu;
  }

var showMenuOption =(actionName)=>{
      if(actionName === "saveAs" && this.props.userData !== null){
        return true;
      }
      else if(actionName === "saveAs" && this.props.userData === null){
        return false;
      }

      if(actionName !== "saveAs"){
        return true;
      }
      
       
    }  

const MenuItems = (props) =>
{
	if(props.user !== null && props.user.applicationFeatures !== undefined)
	{
	var userFeatures = Object.values(props.user.applicationFeatures).map((data)=> data.Action)
	return  (
		<ul className="menu__items">
			{props.content.map((data, i) =>
				<li key={i}>
					{
						props.disabled && data.action === 'report' && userFeatures.indexOf(data.action) > -1 ?
								<button className="menu__item--disabled">{data.text}</button> 
							:
							 isSubMenuEnable(data ,userFeatures, props.userData) ? 
								<button onClick={(e) => props.handleMenuAction(e,data.action)}>{data.text}</button>  
								: null
					 }
					 { data.subItems !== undefined ? 
							<div>
								<SubItemsDisplay user={props.user} content={data.subItems} handleMenuAction={props.handleMenuAction} menuExpanded={props.menuExpanded} />
								<FaAngleRight className="menu__admin-icon" />
							</div> : null
					}
 
				</li>
			
			)}
		</ul> 
	)
	}
	 
}
export default MenuItems

MenuItems.defaultProps = {
	accountMenuExpanded: false,
	adminMenuExpanded: false,
	languageMenuExpanded: false
}

MenuItems.propTypes = {
	accountMenuExpanded: PropTypes.bool.isRequired,
	handleMenuAction: PropTypes.func.isRequired,
	adminMenuExpanded: PropTypes.func.isRequired,
	languageMenuExpanded: PropTypes.func.isRequired
}
