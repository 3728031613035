import React, { Component } from 'react'
import { connect } from 'react-redux'

// Actions
import {
    fetchUserProfileData,
    fetchUserProfileLoading,
    fetchUserProfileSuccess,
    fetchUserProfileFailure,
    fetchUserProfileRoles,
    uploadUserProfilePic,
    updateUserName,
    fetchUserPercentileData,
    resetError,
    resetMessage,
    logoutUser,
    resetToken
}
    from 'actions/userActions'

// UI
import Modal from 'reboron/OutlineModal'
import Popup from "reactjs-popup"
import Button from 'UI/Button'
import classnames from 'classnames'
import FaCamera from 'react-icons/lib/fa/camera'
import { clearLocalStorage } from '../../utils/Utils'

// Settings
import { routes, modalStyle, modalContentStyle, path, changeModalStyle, changeModalContentStyle } from 'Constants'
import ProfileEditor from './ProfileEditor'
import ChangePassword from '../Menu/ChangePassword'

class UserPopUp extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            vsCount: 0, //Value story count
            vssCount: 0, //Value story shared count
            date: null,
            flag: false,
            name: "John Doe",
            updatedName: "John Doe",
            image: null,
            disabled: true,
            roles: [],
            percentileRank: 0,
            powerFlag: false
        };
        this.openPopUp = this.openPopUp.bind(this);
        this.closePopUp = this.closePopUp.bind(this);
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.openModal = this.openModal.bind(this);
        this.fetchUserProfileData = this.fetchUserProfileData.bind(this);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.handleEditName = this.handleEditName.bind(this);
        this.handleUpdateName = this.handleUpdateName.bind(this);
        this.handleUpdateNameCancel = this.handleUpdateNameCancel.bind(this);
        this.fetchUserPercentileData = this.fetchUserPercentileData.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
    }

    openPopUp() {
        //this.setState({ open: true }, this.fetchUserProfileData);
        //this.showModal("profile")
        this.setState({ flag: true }, this.fetchUserProfileData)
    }

    closePopUp() {
        //this.setState({ open: false });
    }

    openModal() {
        this.showModal("profileEditor")
    }

    showModal(action) {
        this.refs[action].show()
    }

    hideModal(action) {
        this.refs[action].hide()
        this.setState({ flag: true })
    }

    handleNameChange = e => {
        this.setState({ updatedName: e.target.value })
    }

    handleEditName = e => {
        this.setState({ disabled: !this.state.disabled })
    }

    handleUpdateName = e => {
        var obj = {
            email: this.props.user.user.Email,
            name: this.state.updatedName
        }
        this.props.updateUserName(obj)
            .then((result) => {
                if (result.status === 200) {
                    this.props.fetchUserProfileSuccess(result.data.Message)
                    this.setState({ name: this.state.updatedName, disabled: !this.state.disabled })
                }
            }).catch((error) => {
                if (error.response !== undefined && error.response.data !== undefined && error.response.data.Message !== undefined) {
                    this.props.fetchUserProfileFailure(error.response.data.Message)
                }
                //this.props.fetchUserProfileFailure('API service is down. Please contact administrator.')

            })
        //this.setState({name: this.state.updatedName, disabled: !this.state.disabled})
    }

    handleUpdateNameCancel = e => {
        this.setState({ updatedName: this.state.name, disabled: !this.state.disabled })
    }

    changeImage = e => {
        //this.setState({image: e})
        let callProfileDataFetch = this.fetchUserProfileData;
        var source;
        var _this = this;
        source = btoa(e);
        var obj = {
            email: _this.props.user.user.Email,
            imgSrc: source
        }
        _this.props.uploadUserProfilePic(obj)
            .then((result) => {
                if (result.status === 200) {
                    _this.props.fetchUserProfileSuccess(result.data.Message)
                    //code pending...
                    callProfileDataFetch()
                }
            }).catch((error) => {
                if (error.response !== undefined && error.response.data !== undefined && error.response.data.Message !== undefined) {
                    _this.props.fetchUserProfileFailure(error.response.data.Message)
                }
                //_this.props.fetchUserProfileFailure('API service is down. Please contact administrator.')

            })

    }
    componentWillMount() {
        this.fetchUserProfileData()
        this.fetchUserPercentileData()
    }
    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        //this.props.setClick(this.openPopUp);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);

        this.props.resetError();
        this.props.resetMessage();
    }

    /**
     * Set the wrapper ref
     */
    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    /**
     * Alert if clicked on outside of element
     */
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({
                flag: false
            })
        }
    }

    fetchUserPercentileData() {
        if (this.props.user.user != null) {
            this.props.fetchUserPercentileData(this.props.user.user.Id, this.props.user.user.Email)
                .then((result) => {
                    if (result.status === 200) {
                        this.props.fetchUserProfileSuccess(result.data.Message)
                        let data = result.data;
                        let user;
                        if (data != null) {
                            user = data.find(item => item.id == this.props.user.user.Id)
                        }
                        let percentileRank = user.Percent_Rank;
                        let flag = false;
                        if (percentileRank >= 0.9) {
                            flag = true;
                        }
                        this.setState({ percentileRank: percentileRank, powerFlag: flag })
                    }
                }).catch((error) => {
                    if (error.response !== undefined && error.response.data !== undefined && error.response.data.Message !== undefined) {
                        this.props.fetchUserProfileFailure(error.response.data.Message)
                    }
                    //this.props.fetchUserProfileFailure('API service is down. Please contact administrator.')

                })
        }
    }

    fetchUserProfileData() {
        if (this.props.user.user != null) {
            this.props.fetchUserProfileData(this.props.user.user.Id, this.props.user.user.Email)
                .then((result) => {
                    if (result.status === 200) {
                        this.props.fetchUserProfileSuccess(result.data.Message)
                        this.props.fetchUserProfileRoles(result.data)
                        let vsCount = result.data.Result.VsCount;
                        let vssCount = result.data.Result.VssCount;
                        let name = result.data.Result.Name;
                        let Roles = result.data.Result.Roles['0'].join(", ");
                        let image = null;
                        if (result.data.Result.ProfileImage != null) {
                            image = atob(result.data.Result.ProfileImage);
                        }

                        const d = new Date(result.data.Result.JoinedDate)
                        const dtf = new Intl.DateTimeFormat('en', { year: 'numeric', month: 'long', day: '2-digit' })
                        const [{ value: mo }, , { value: da }, , { value: ye }] = dtf.formatToParts(d)
                        let date = `${mo} ${da}, ${ye}`
                        //console.log(`${da}-${mo}-${ye}`)
                        //'current_user.png',
                        this.setState({ vsCount: vsCount, vssCount: vssCount, date: date, image: image, name: name, roles: Roles, updatedName: name }, this.fetchUserPercentileData)
                    }
                }).catch((error) => {
                    if (error.response !== undefined && error.response.data !== undefined && error.response.data.Message !== undefined) {
                        this.props.fetchUserProfileFailure(error.response.data.Message)
                    }
                    //this.props.fetchUserProfileFailure('API service is down. Please contact administrator.')

                })
        }
    }

    handleLogout() {
        this.props
            .logoutUser()
            .then(() => {
                this.props.resetToken()
                clearLocalStorage()

                // Redirect to home page
                window.location.href = path

                // this.props.resetValueStory()
            })
            .catch(() => {
                clearLocalStorage();
                // Redirect to home page
                window.location.href = path;
            })
    }

    render() {
        const applicationFeatures = this.props.user && this.props.user.user && this.props.user.user.applicationFeatures ? this.props.user.user.applicationFeatures : [];
        let showChangePassword = false;
        let showLogout = false;
        applicationFeatures.map((feature) => {
            if (feature["Action"] === "password") showChangePassword = true;
            if (feature["Action"] === "logout") showLogout = true;
        });
        return (
            <div>
                <div onClick={this.openPopUp}>
                    <div id='col1'><img
                        className="nav__user-profile"
                        src={this.state.image != null ? this.state.image : 'images/PersonMale.png'}

                    >
                    </img></div>
                    <div id='col2'><button className="nav__userProfile"> {this.props.content.userProfile.Profile} </button></div>
                </div>
                <ul ref={this.setWrapperRef} className={classnames('nav__user-profile-dropdown', { 'nav__user-profile-dropdown--active': this.state.flag })}>
                    <div>
                        <div className={this.state.powerFlag ? 'power-user-show' : 'power-user-hide'}>
                            <img className={'thumbs-up'} src={'images/thumbsUp.png'} alt='star' />
                            <span>{this.props.content.userProfile.Power_User}</span>
                        </div>
                        <div className='upload-image'>
                            <img width="170px" height="170px" src={this.state.image != null ? this.state.image : 'images/PersonMale.png'} onClick={this.openModal} />
                            <button type='submit' onClick={this.openModal}>
                                <img src="/images/camera.jpg" />
                            </button>
                        </div>
                        {this.state.disabled ?
                            <div className='user-name-hide'>
                                <p>{this.state.name}
                                    <button type='submit' onClick={this.handleEditName}>
                                        <img width="18px" src={'/images/edit.jpg'} />
                                    </button>
                                </p>

                            </div> :
                            <div className='user-name-show'>
                                <input
                                    type='text'
                                    value={this.state.updatedName}
                                    onChange={this.handleNameChange}
                                />
                                <button className='save' type='submit' onClick={this.handleUpdateName}>
                                    <img width="18px" src="/images/icon-select-selected.png" />
                                </button>
                                <button className='cancel' type='submit' onClick={this.handleUpdateNameCancel}>
                                    <img width="16px" src="/images/cancel.png" />
                                </button>
                            </div>
                        }

                        <div className='profile-list'>
                            <ul className='user-lists'>
                                <li><b>{this.props.content.userProfile.Vs_Count}</b> {this.state.vsCount}</li>
                                <li><b>{this.props.content.userProfile.Vss_Count}</b> {this.state.vssCount} </li>
                                <li><b>{this.props.content.userProfile.User_Since}</b> {this.state.date}</li>
                            </ul>
                        </div>
                        <div className='user-roles'>
                            <li><b>{this.props.content.userProfile.Roles}</b><span>{this.state.roles}</span></li>
                        </div>
                        <div className='pt10'>
                            <ul className='user-lists'>
                                {
                                    showChangePassword ? <li style={{ cursor: 'pointer' }} onClick={() => this.showModal('changePassword')}><b>{this.props.content.userProfile.changePassword}</b></li> : null
                                }
                                {
                                    showLogout ? <li style={{ cursor: 'pointer' }} onClick={this.handleLogout}><b>{this.props.content.userProfile.logout}</b></li> : null
                                }
                            </ul>
                        </div>
                    </div>
                </ul>


                <div>
                    <Modal ref="changePassword"
                        modalStyle={modalStyle} contentStyle={modalContentStyle}>
                        <ChangePassword hideModal={() => this.hideModal("changePassword")} />
                        <button onClick={() => this.hideModal("changePassword")} className='close' />
                    </Modal>
                    <Modal
                        ref="profileEditor"
                        modalStyle={changeModalStyle}
                        contentStyle={changeModalContentStyle}
                    >
                        <div className="grid-x grid-padding-x change-popup">
                            <div className="medium-12">
                                <button onClick={() => this.hideModal("profileEditor")} className="close" />
                            </div>
                            <div className="medium-12 pt30">
                                <ProfileEditor
                                    changeImage={this.changeImage}
                                    hideModal={this.hideModal}
                                />
                            </div>
                            {/* <div className="medium-12 popupOkBtn">
                                <div className="float-right">
                                    <div className="float-left">
                                        <Button
                                            content={"close"}
                                            onClick={() => this.hideModal("profileEditor")}
                                            color />
                                    </div>

                                </div>
                            </div> */}
                        </div>
                    </Modal>
                </div>

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        language: state.language,
        user: state.default,
        content: state.content
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchUserProfileData: (id, email) => dispatch(fetchUserProfileData(id, email)),
        fetchUserProfileLoading: (bool) => dispatch(fetchUserProfileLoading(bool)),
        fetchUserProfileSuccess: (success) => dispatch(fetchUserProfileSuccess(success)),
        fetchUserProfileFailure: (error) => dispatch(fetchUserProfileFailure(error)),
        fetchUserProfileRoles: (success) => dispatch(fetchUserProfileRoles(success)),
        uploadUserProfilePic: (image) => dispatch(uploadUserProfilePic(image)),
        updateUserName: (obj) => dispatch(updateUserName(obj)),
        fetchUserPercentileData: (id, email) => dispatch(fetchUserPercentileData(id, email)),
        resetError: () => dispatch(resetError()),
        resetMessage: () => dispatch(resetMessage()),
        logoutUser: () => dispatch(logoutUser()),
        resetToken: () => dispatch(resetToken())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserPopUp);




