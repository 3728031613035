import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

// UI
import Button from 'UI/Button'

// Utils
import { BreakLine } from 'utils/Utils'
import Linkify from 'react-linkify'

// Settings
import { routes } from 'Constants'

class RegisterThankYou extends Component {

	render() {
		return (			
			<div className="grid-container">				
				<div className="grid-x">
					<div className="cell mt20">
						<div className="page-title">{BreakLine(this.props.content.title)}</div>
					</div>
					<div className="cell mt20" style={{marginBottom:'20px'}}>
						<Linkify>
							<p>{BreakLine(this.props.content.description)}</p>
							{this.props.content.contactDescri} <a href="mailto:sap_ism_value_experience@sap.com">Intelligent Spend Management Value Experience Tools</a>

						</Linkify>
					</div>
					<Link to={routes.home}>
						<Button
							content={this.props.content.cta}
							icon="left-arrow"
							iconPosition="left"
							color
						/>
					</Link>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		content: state.content.register.thankYou
	}
}

export default connect(mapStateToProps)(RegisterThankYou)
