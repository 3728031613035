import React from 'react'
import { connect } from 'react-redux'
// UI
import Select from 'react-select'
import Button from 'UI/Button'
// Settings
import {
    enablementSuccess,
    enablementLoading,
    enablementFailure,
    resetError,
    getMatchedSupplier,
    getMatchedSummary,
    getSupplierByReason,
    storeUserSpendSelection,
    trackUserSpendSelections
} from 'actions/spendActions'
import Popup from "reactjs-popup";
import { convertToMillion, formatThousands } from 'utils/Utils'
import ZoomModal from 'components/UI/ZoomModal'

class AribaNetworkEnablementAnalysis extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            focusOption: [],
            selectedFocus: '',
            enablementSummary: [],
            targetEnterpriseSummary: [],
            profileTilesData: [],
            supplierCount: [],
            aribaNetworkMatched: [
                { label: "Total Invoice Count", value: ["AGG_INV_COUNT_IN_SCOPE", "AGG_INV_COUNT_MATCH_SUPPLIERS", formatThousands, "Invoice Count"] },
                { label: "Total Invoice Spend", value: ["AGG_INV_SPEND_IN_SCOPE", "AGG_INV_SPEND_MATCH_SUPPLIERS", convertToMillion, "Invoice Spend"] },
                { label: "Total Purchase Order Count", value: ["AGG_PO_COUNT_IN_SCOPE", "AGG_PO_COUNT_MATCH_SUPPLIERS", formatThousands, "PO Count"] },
                { label: "Total Purchase Order Spend", value: ["AGG_PO_SPEND_IN_SCOPE", "AGG_PO_SPEND_MATCH_SUPPLIERS", convertToMillion, "PO Spend"] },
                { label: "Total Supplier Count", value: ["SUPPLIER_COUNT_IN_SCOPE", "SUPPLIER_COUNT_MATCH_SUPPLIERS", formatThousands, "Supplier Count"] }
            ],
            selectedAribaNetworkMatched: ["AGG_INV_COUNT_IN_SCOPE", "AGG_INV_COUNT_MATCH_SUPPLIERS", formatThousands, "Invoice Count"],
            enablement: {
                DMC_ID: this.props.spendDetails.currentActiveRequest.DMC_ID,
                VS_ID: this.props.spendDetails.currentActiveRequest.VS_ID,
                User_Email: this.props.spendDetails.currentActiveRequest.SAPRequester === this.props.user.user.Email ? this.props.user.user.Email : this.props.spendDetails.currentActiveRequest.SAPRequester,
                Solution: 'No Filter',
                BuyProcess: 'No Filter',
                MatchFilter: ''
            },
            aribaNetworkMatchedData: '',
            enablementSummary: [],
            supplierByReason: [],
            showMatchedSuppliers: true,
            legendText: {
                inScopeText: 'In-Scope Suppliers',
                matchSupplierText: 'Matched Suppliers'
            },
            currencySymbol: '',
            matchedFilter: [],
            selectedMatchedFilter: '',
            spendModule: 'Program Enablement Analysis',
            spendSubModule: 'Ariba Network enablement analysis'
        }
        this.handleAribaNetworkMatchedChange = this.handleAribaNetworkMatchedChange.bind(this)
        this.showMatchedSuppliers = this.showMatchedSuppliers.bind(this)
    }

    componentDidMount() {
        var matchFilterarray = [
            { label: 'Matched', value: 'Matched' },
            { label: 'Matched & Transacting', value: 'Matched & Transacting' },
        ]

        var target = this.props.spendDetails.userSpendFilterSelections.find((obj) => {
            return (obj.SpendModule === this.state.spendModule
                && obj.SpendSubModule === this.state.spendSubModule &&
                obj.CriteriaName === 'Match Filter')
        })
        var matchFilter = target.UserPreference === '' ? target.DefaultValue : target.UserPreference

        var enablementInstance = this.state.enablement;
        enablementInstance.MatchFilter = matchFilter;

        this.loadData();
        this.setState({
            selectedAribaNetworkMatched: ["AGG_INV_COUNT_IN_SCOPE", "AGG_INV_COUNT_MATCH_SUPPLIERS", formatThousands, "Invoice Count"],
            currencySymbol: this.props.spendDetails.currentActiveRequest !== undefined ? this.props.currencySymbols[this.props.spendDetails.currentActiveRequest.Currency.trim()] : '',
            matchedFilter: matchFilterarray, selectedMatchedFilter: matchFilter
        })
    }

    componentWillUnmount() {
        this.props.resetError();
    }


    handleAribaNetworkMatchedChange(value) {
        var dynamicLegend = this.state.legendText;
        switch (value.label) {
            case "Total Invoice Count":
                dynamicLegend.inScopeText = "In-Scope Invoice Count"
                dynamicLegend.matchSupplierText = "Matched Invoice Count"
                break;
            case "Total Invoice Spend":
                dynamicLegend.inScopeText = "In-Scope Invoice Spend"
                dynamicLegend.matchSupplierText = "Matched Invoice Spend"
                break;
            case "Total Purchase Order Count":
                dynamicLegend.inScopeText = "In-Scope PO Count"
                dynamicLegend.matchSupplierText = "Matched PO Count"
                break;
            case "Total Purchase Order Spend":
                dynamicLegend.inScopeText = "In-Scope PO Spend"
                dynamicLegend.matchSupplierText = "Matched PO Spend"
                break;
            case "Total Supplier Count":
                dynamicLegend.inScopeText = "In-Scope Suppliers"
                dynamicLegend.matchSupplierText = "Matched Suppliers"
                break;
        }
        this.setState({ selectedAribaNetworkMatched: value.value, legendText: dynamicLegend });
    }


    loadData() {
        this.props.enablementLoading(true)
        var objEnablement = this.state.enablement;


        this.props.getMatchedSupplier(objEnablement)
            .then((response) => {
                if (response.status === 200) {
                    var matchedSupplier = response.data.Result;
                    var new_ele = response.data.Result.reduce((pre, curr) => {
                        return {
                            SOLUTION_TYPE: 'Total',
                            SUPPLIER_COUNT_IN_SCOPE: pre.SUPPLIER_COUNT_IN_SCOPE + curr.SUPPLIER_COUNT_IN_SCOPE,
                            SUPPLIER_COUNT_MATCH_SUPPLIERS: pre.SUPPLIER_COUNT_MATCH_SUPPLIERS + curr.SUPPLIER_COUNT_MATCH_SUPPLIERS,
                            AGG_INV_SPEND_IN_SCOPE: pre.AGG_INV_SPEND_IN_SCOPE + curr.AGG_INV_SPEND_IN_SCOPE,
                            AGG_INV_SPEND_MATCH_SUPPLIERS: pre.AGG_INV_SPEND_MATCH_SUPPLIERS + curr.AGG_INV_SPEND_MATCH_SUPPLIERS,
                            AGG_INV_COUNT_IN_SCOPE: pre.AGG_INV_COUNT_IN_SCOPE + curr.AGG_INV_COUNT_IN_SCOPE,
                            AGG_INV_COUNT_MATCH_SUPPLIERS: pre.AGG_INV_COUNT_MATCH_SUPPLIERS + curr.AGG_INV_COUNT_MATCH_SUPPLIERS,
                            AGG_PO_SPEND_IN_SCOPE: pre.AGG_PO_SPEND_IN_SCOPE + curr.AGG_PO_SPEND_IN_SCOPE,
                            AGG_PO_SPEND_MATCH_SUPPLIERS: pre.AGG_PO_SPEND_MATCH_SUPPLIERS + curr.AGG_PO_SPEND_MATCH_SUPPLIERS,
                            AGG_PO_COUNT_IN_SCOPE: pre.AGG_PO_COUNT_IN_SCOPE + curr.AGG_PO_COUNT_IN_SCOPE,
                            AGG_PO_COUNT_MATCH_SUPPLIERS: pre.AGG_PO_COUNT_MATCH_SUPPLIERS + curr.AGG_PO_COUNT_MATCH_SUPPLIERS
                        }
                    })
                    matchedSupplier.push(new_ele)
                    this.setState({ aribaNetworkMatchedData: matchedSupplier },
                        () => {
                            this.props.enablementSuccess('')
                        })
                }
            })
            .catch((exception) => {
                this.props.enablementFailure('')
            })

        this.props.getMatchedSummary(objEnablement)
            .then((response) => {
                if (response.status === 200) {
                    this.setState({ enablementSummary: response.data.Result })
                }
            })
            .catch((exception) => {
                this.props.enablementFailure('')
            })

        this.props.getSupplierByReason(objEnablement)
            .then((response) => {
                if (response.status === 200) {
                    var reasons = response.data.Result;
                    var new_ele = response.data.Result.reduce((pre, curr) => {
                        return {
                            REASON: 'Total',
                            SUPPLIER_COUNT: pre.SUPPLIER_COUNT + curr.SUPPLIER_COUNT,
                            SPEND: pre.SPEND + curr.SPEND,
                            INVOICES: pre.INVOICES + curr.INVOICES
                        }
                    })
                    reasons.push(new_ele)
                    this.setState({ targetEnterpriseSummary: reasons })
                }
            })
            .catch((exception) => {
                this.props.enablementFailure('')
            })

    }

    handleDimensionChange(value) {
        // To do fetch data on the of selected dimension
        this.setState({ dimension: value.label });
    }

    showMatchedSuppliers() {
        this.setState({ showMatchedSuppliers: !this.state.showMatchedSuppliers })
    }

    handleMatchedFilterChange(value) {
        var userEmail = this.props.spendDetails.currentActiveRequest.SAPRequester === this.props.user.user.Email ? this.props.user.user.Email : this.props.spendDetails.currentActiveRequest.SAPRequester;
        var VSId = this.props.spendDetails.currentActiveRequest.VS_ID
        var DMCId = this.props.spendDetails.currentActiveRequest.DMC_ID
        this.props.storeUserSpendSelection(VSId, DMCId, this.state.spendModule, this.state.spendSubModule, 'Match Filter', value.value, userEmail)
            .then((response) => {
                this.props.trackUserSpendSelections(response.data.Result)
                var enablementInstance = this.state.enablement;
                enablementInstance.MatchFilter = value.value;
                this.setState({ selectedMatchedFilter: value.value }, () => this.loadData());
            }).catch((error) => {
                this.props.enablementFailure(error.response.data.Message)
            })
    }

    render() {
        var _this = this;
        return (
            <div className="grid-x">
                <div className="medium-12 cell">
                    <div className="medium-12 cell tab-title">
                        {this.props.content.aribaNetworkTitle}
                    </div>
                    <div className="medium-12 cell tab-description pb10">
                        {this.props.content.aribaNetworkSubTitle}
                    </div>
                    <div className="medium-12 cell">
                        <div className="grid-x">
                            <div className="medium-12">
                                <div className="grid-x grid-padding-x">
                                    <div className="subHeader float-left">{this.props.content.aribaNetworkMatch}</div>
                                    <div className="float-left pl20 width225">
                                        <Select
                                            className="border-with-DD dropDownContent"
                                            id='aribaNetworkMatched'
                                            autocomplete='true'
                                            value={this.state.aribaNetworkMatched.filter(
                                                option => JSON.stringify(option.value) === JSON.stringify(_this.state.selectedAribaNetworkMatched)
                                            )}
                                            onChange={value => this.handleAribaNetworkMatchedChange(value)}
                                            options={this.state.aribaNetworkMatched}
                                            isSearchable={false}
                                            isDisabled={this.props.spendDetails.isLoading}
                                            noOptionsMessage={() => null} />
                                    </div>
                                    <div className="subHeader pl20 float-left">{this.props.content.matchFilter}</div>
                                    <div className="float-left pl20 width225">
                                        <Select
                                            className="border-with-DD dropDownContent"
                                            id='matched'
                                            autocomplete='true'
                                            value={this.state.matchedFilter.filter(
                                                option => JSON.stringify(option.value) === JSON.stringify(_this.state.selectedMatchedFilter)
                                            )}
                                            onChange={value => this.handleMatchedFilterChange(value)}
                                            options={this.state.matchedFilter}
                                            isSearchable={false}
                                            isDisabled={this.props.spendDetails.isLoading}
                                            noOptionsMessage={() => null} />
                                    </div>
                                    <div style={{ width: '25px' }} className="pl5 float-left">
                                        <Popup className="report-tooltip"
                                            trigger={<img style={{ paddingBottom: '5px' }} className="info-icon" src="images/info-icon.svg" width="15" />}
                                            position="top left"
                                            on="hover"
                                        >
                                            {this.props.content.matchFilterToolTip}
                                        </Popup>
                                    </div>
                                    <div className="pl20 float-left pt5 hand-cursor">
                                        <div onClick={() => this.showMatchedSuppliers()}>
                                            {this.state.showMatchedSuppliers ?
                                                <img src="images/show_active.svg" width="30" />
                                                : <img src="images/show_inactive.svg" width="30" />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="medium-12">
                        <div className="grid-x">
                            <div className="medium-6 graphDiv">
                                <div className="grid-x">
                                    <div className="medium-12">
                                        {this.state.aribaNetworkMatchedData !== '' ?
                                            <ZoomModal classStyle="zoom-div-style">
                                                <SupplierCountTable
                                                    showMatchedSuppliers={this.state.showMatchedSuppliers}
                                                    resultData={this.state.aribaNetworkMatchedData}
                                                    selectedAribaNetworkMatched={this.state.selectedAribaNetworkMatched}
                                                    legendText={this.state.legendText}
                                                /></ZoomModal> : null}
                                    </div>
                                </div>
                            </div>
                            <div style={{ paddingLeft: '25px', paddingTop: '23px' }} className="medium-6">
                                <div className="grid-x">
                                    {this.state.enablementSummary.length !== 0 ?
                                        <div className="medium-12">
                                            <EnablementTable
                                                resultData={this.state.enablementSummary}
                                                currencySymbol={this.state.currencySymbol} />
                                        </div>
                                        :
                                        <div className="pt10">{this.props.content.noDataFound}</div>}
                                </div>
                                <br /><br />
                                <div className="grid-x">
                                    <div className="medium-12">
                                        <p className="bold-header">{this.props.content.targetEnterpriseHeader}</p>
                                    </div>
                                    {this.state.targetEnterpriseSummary.length !== 0 ?
                                        <div className="medium-12">
                                            <EnablementTable
                                                resultData={this.state.targetEnterpriseSummary}
                                                isTargertEnterpriseTable={true}
                                                currencySymbol={this.state.currencySymbol} />
                                        </div>
                                        :
                                        <div className="pt10">{this.props.content.noDataFound}</div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}


class EnablementTable extends React.Component {
    constructor(props) {
        super(props)
        {
        }
    }

    compare(a, b) {
        if (a.REASON !== undefined) {
            const bandA = a.REASON.toUpperCase();
            const bandB = b.REASON.toUpperCase();

            let comparison = 0;
            if (bandA > bandB) {
                comparison = 1;
            } else if (bandA < bandB) {
                comparison = -1;
            }
            return comparison;
        }
        return null;
    }

    render() {
        var currencySymbol = this.props.currencySymbol !== undefined ? this.props.currencySymbol : ''
        var isReason = this.props.isTargertEnterpriseTable
        var rows = this.props.resultData.sort(this.compare).map(
            function (row) {
                return (
                    <tr className="eachRow">
                        <td>
                            {isReason ? row.REASON : row.SUMMARY_TYPE}
                        </td>
                        <td style={{ textAlign: 'right' }}>
                            {isReason ? (!isNaN(row.SUPPLIER_COUNT) ? (row.SUMMARY_TYPE !== undefined && row.SUMMARY_TYPE.includes('%')) ? row.SUPPLIER_COUNT + "%" : formatThousands(row.SUPPLIER_COUNT) : 0)
                                : (!isNaN(row.SUPPLIERS) ? (row.SUMMARY_TYPE !== undefined && row.SUMMARY_TYPE.includes('%')) ? (row.SUPPLIERS !== null ? row.SUPPLIERS : 0) + "%" : formatThousands(row.SUPPLIERS) : 0)}
                        </td>
                        <td style={{ textAlign: 'right' }}>
                            {!isNaN(row.SPEND) ? (row.SUMMARY_TYPE !== undefined && row.SUMMARY_TYPE.includes('%')) ? (row.SPEND !== null ? row.SPEND : 0) + "%" : (currencySymbol !== null && currencySymbol !== undefined) ? currencySymbol + convertToMillion(row.SPEND, true) : '' + convertToMillion(row.SPEND, true) : 0}
                        </td>
                        <td style={{ textAlign: 'right' }}>
                            {!isNaN(row.INVOICES) ? (row.SUMMARY_TYPE !== undefined && row.SUMMARY_TYPE.includes('%')) ? (row.INVOICES !== null ? row.INVOICES : 0) + "%" : formatThousands(row.INVOICES) : 0}
                        </td>
                    </tr>
                )
            }
        );
        return (
            <div className="enablement-table-evaluation table">
                <table>
                    <thead>
                        <tr>
                            <th width="40%" className='tableHeader' >{this.props.isTargertEnterpriseTable === true ? 'Reason' : ''}</th>
                            <th width="20%" className='tableHeader' style={{ textAlign: 'right', paddingRight: '9px' }}>{'Suppliers'}</th>
                            <th width="20%" className='tableHeader' style={{ textAlign: 'right', paddingRight: '9px' }}>{'Spend'}</th>
                            <th width="20%" className='tableHeader' style={{ textAlign: 'right', paddingRight: '9px' }}>{'Invoices'}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows}
                    </tbody>
                </table>
            </div>
        );
    }
}




class SupplierCountTable extends React.Component {
    constructor(props) {
        super(props)
        {
        }
        this.calculatePercentage = this.calculatePercentage.bind(this)
    }

    calculatePercentage(value, columnName, type) {
        if (value !== null && value > 0) {
            var supplierData = this.props.resultData.filter((a) => a.SOLUTION_TYPE === "Total");
            var scalePercent = (value / supplierData[0][columnName]) * 100

            var solutionTypeData = this.props.resultData.filter((a) => a.SOLUTION_TYPE === type);
            var matchedPercent = (value / solutionTypeData[0][columnName]) * 100;

            return [scalePercent.toFixed(0), matchedPercent.toFixed(0)]
        }
        else {
            return [0, 0];
        }
    }

    render() {
        var i = 0;
        var selectedAribaNetworkMatched = this.props.selectedAribaNetworkMatched
        var showMatchedSuppliers = this.props.showMatchedSuppliers
        var supplierLegend = this.props.legendText
        var _this = this
        return (
            <div className="enablement-table table" >

                {this.props.resultData.map(
                    function (row) {
                        i++;
                        var format = selectedAribaNetworkMatched[2]
                        return <table>
                            <thead>
                                <tr>
                                    <th width="75%" className='tableHeader'>{row.SOLUTION_TYPE}</th>
                                    <th width="25%" className='tableHeader'>{i === 1 ? selectedAribaNetworkMatched[3] : ''}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="eachRow">
                                    <td width="80%">
                                        <div style={{ backgroundColor: "#f7f7f7" }} class="medium-11">
                                            <div style={{ width: _this.calculatePercentage(row[selectedAribaNetworkMatched[0]], selectedAribaNetworkMatched[0], row.SOLUTION_TYPE)[0] + '%', color: "rgba(51, 195, 255, 0)", backgroundColor: "#33C3FF" }}>
                                                {!isNaN(row[selectedAribaNetworkMatched[0]]) ? 0 : row[selectedAribaNetworkMatched[0]]}
                                            </div>
                                        </div>

                                    </td>
                                    <td width="20%">
                                        {format(row[selectedAribaNetworkMatched[0]])}
                                    </td>
                                </tr>
                                {showMatchedSuppliers ?
                                    <tr className="eachRow">
                                        <td width="80%">
                                            <div style={{ backgroundColor: "#f7f7f7" }} class="medium-11">
                                                <div style={{
                                                    //paddingLeft: (_this.calculatePercentage(row[selectedAribaNetworkMatched[1]], selectedAribaNetworkMatched[0],row.SOLUTION_TYPE)[0] ) + '%',
                                                    width: (_this.calculatePercentage(row[selectedAribaNetworkMatched[1]], selectedAribaNetworkMatched[0], row.SOLUTION_TYPE)[0]) + '%',
                                                    color: "black", backgroundColor: "#dcdcdc"
                                                }}>
                                                    {_this.calculatePercentage(row[selectedAribaNetworkMatched[1]], selectedAribaNetworkMatched[0], row.SOLUTION_TYPE)[1] + '%'}
                                                </div>
                                            </div>
                                        </td>
                                        <td width="20%">
                                            {format(row[selectedAribaNetworkMatched[1]])}
                                        </td>
                                    </tr>
                                    : null}
                            </tbody>
                        </table>
                    })}

                <div className="pt5">
                    <div style={{ fontSize: '12px' }} class="legend2 float-left">
                        <span class="key-dot suppliers"></span>
                        {supplierLegend.inScopeText}
                    </div>
                    {showMatchedSuppliers ? <div style={{ fontSize: '12px' }} class="legend2 float-left pl20">
                        <span class="key-dot matchedSuppliers"></span>
                        {supplierLegend.matchSupplierText}
                    </div> : null}
                </div>
            </div>
        );

    }
}



const mapStateToProps = (state) => {
    return {
        // language: state.language,
        // ui: state.content.ui,
        user: state.default,
        content: state.content.spendAnalysis.enablement,
        validation: state.content.validation,
        spendDetails: state.spendDetails,
        currencySymbols: state.content.CurrencySymbols
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        enablementSuccess: message => dispatch(enablementSuccess(message)),
        enablementFailure: error => dispatch(enablementFailure(error)),
        enablementLoading: bool => dispatch(enablementLoading(bool)),
        resetError: error => dispatch(resetError(error)),
        getMatchedSupplier: (data) => dispatch(getMatchedSupplier(data)),
        getMatchedSummary: (data) => dispatch(getMatchedSummary(data)),
        getSupplierByReason: (data) => dispatch(getSupplierByReason(data)),
        storeUserSpendSelection: (VSId, DMCId, spendModule, spendSubModule, criteriaName, selectedValue, userEmail) => dispatch(storeUserSpendSelection(VSId, DMCId, spendModule, spendSubModule, criteriaName, selectedValue, userEmail)),
        trackUserSpendSelections: (userSelections) => dispatch(trackUserSpendSelections(userSelections))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AribaNetworkEnablementAnalysis)
