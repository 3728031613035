import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import { Control, Form, Errors, actions } from 'react-redux-form'
// UI
import Textfield from 'react-mdl/lib/Textfield'
import Button from 'UI/Button'
import {
    getAlllanguageList, callApi, DeleteLanguage
} from 'actions/nodeActions'
import { validators } from 'Constants'
import {
    getCurrentLanguage
} from 'utils/Utils'

class LanguageContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = { languageList: [], response: '' }
        this.writefile = this.writefile.bind(this)
        this.getLanguageListFromAPI = this.getLanguageListFromAPI.bind(this)
        this.getLanguageListFromAPI();
        this.handleRowDel = this.handleRowDel.bind(this);
        this.resetAllMessages()
    }

    componentWillUnmount() {
        this.resetAllMessages()
        this.formDispatch(actions.reset('lanMgr'));
    }

    writefile(formValues) {
        if (formValues.LanguageName.length > 0 && formValues.LanguageShortName.length > 0) {

            this.props.writefile(formValues, this.props.user.user.Id)
                .then((res) => {
                    this.formDispatch(actions.reset('lanMgr'))
                    this.setState({ response: res });
                    this.getLanguageListFromAPI();
                })
                .catch(err => {
                    if (err.stack.includes('Network Error')) {
                        this.props.domainDetailsFailure('API service is down. Please contact administrator.')
                    }
                    this.resetAllMessages();
                });

            setTimeout(() => {
                this.resetAllMessages()
            }, 50000);
        }
    }

    // most updated file will be here so read language from here
    getLanguageListFromAPI() {
        this.props.getLanguageListFromAPI().
            then((response) => {
                this.setState({ languageList: response })
            }).
            catch(err => {
                if (err.stack.includes('Network Error')) {
                    this.props.domainDetailsFailure('API service is down. Please contact administrator.')
                }
                this.resetAllMessages();
            });
    }

    isValidString(vals) {
        return isNaN(vals);
    }

    isDuplicate(vals) {
        var result = true

        if (this.state.languageList !== undefined) {
            for (var item = 0; item < this.state.languageList.length; item++) {
                if (this.state.languageList[item].ShortLanguageName === vals.trim() || this.state.languageList[item].LanguageName === vals.trim()) {
                    result = false;
                    break;
                }
            }
        }
        return result;
    }

    handleRowDel(data) {
        var currentBrowserLanguage = getCurrentLanguage();

        if (currentBrowserLanguage !== data.ShortLanguageName) {
            const result = window.confirm('Do you want to delete the Language ' + data.LanguageName + ' ? ');
            if (result) {
                this.props.handleRowDel(data, this.props.user.user.Id).
                    then((res) => {
                        this.setState({ response: res.data })
                        this.getLanguageListFromAPI()
                    });
                setTimeout(() => {
                    this.resetAllMessages()
                }, 50000);
            }
        }
        else {
            const result = window.alert('Application language should not be same as deleted languge ' + data.LanguageName + ' Please chnage application language.');
        }
    }

    resetAllMessages() {
        this.setState({ response: '' })
        this.props.user.error = '';
    }

    PropTypes = {
        inputType: PropTypes.string.isRequired
    };
    
    render() {
        var rowDel = this.handleRowDel
        var languagerow = (this.state.languageList !== undefined) ?
            this.state.languageList.map(function (itemLanguage) {
                return (<AddLanguageRow
                    itemLanguage={itemLanguage}
                    onDelEvent={rowDel.bind(this)} />)
            }) : null


        return (
            <div className="grid-container popup-content">
                <div className="medium-12 title mt20">{this.props.content.LanguageManager[0].subItems[0].text}</div>

                <Form model="lanMgr" method="post" onSubmit={(formValue) => this.writefile(formValue)  } getDispatch={(dispatch) => this.formDispatch = dispatch}
                     >
                    <div className="grid-x grid-padding-x description" >
                        <div className="medium-4 cell" >
                            <Control.text
                                model="lanMgr.LanguageName"
                                id="lanMgr.LanguageName"
                                component={Textfield}
                                label={'Language name'}
                                floatingLabel
                                validateOn="blur"
                                validators={{
                                    required: validators.required,
                                    duplicate: (vals) => this.isDuplicate(vals),
                                    validString: (vals) => this.isValidString(vals),
                                }}
                            />
                            <Errors
                                className="errors"
                                model="lanMgr.LanguageName"
                                show="touched"
                                messages={{
                                    required: this.props.validation.required,
                                    duplicate: 'Name already exists!',
                                    validString: 'Invalid language name.'
                                }} />
                        </div>
                        <div className="medium-4 cell grid-padding-5">
                            <Control.text
                                type="text"
                                model="lanMgr.LanguageShortName"
                                id="lanMgr.LanguageShortName"
                                component={Textfield}
                                label={'Language in short'}
                                floatingLabel
                                validateOn="blur"
                                validators={{
                                    required: validators.required,
                                    validString: (vals) => this.isValidString(vals),
                                    duplicate: (vals) => this.isDuplicate(vals)
                                }}
                            />
                            <Errors
                                className="errors"
                                model="lanMgr.LanguageShortName"
                                show="touched"
                                messages={{
                                    required: this.props.validation.required,
                                    duplicate: 'Short name already exists.',
                                    validString: 'Invalid short name.'
                                }} />
                        </div>

                        <div className="medium-2 cell grid-padding-1 pt13">
                            <Button
                                type="submit" 
                                content={this.props.content.LanguageManager[0].subItems[0].saveLanguge} color />
                        </div>
                    </div>
                    <div className="errors">{this.props.user.error}</div>
                    <div className="success">{this.state.response}</div>

                    <div>
                        <table className="basic-table table">
                            <thead>
                                <tr>
                                    <th> Language Name</th>
                                    <th> Short Name</th>
                                    <th> Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {languagerow}
                            </tbody>
                        </table>
                    </div>
                </Form>
            </div>
        )
    }

}

class AddLanguageRow extends React.Component {
    constructor(props) {
        super(props);

    }
    onDelEvent() {
        this.props.onDelEvent(this.props.itemLanguage);
    }

    render() {

        return (
            <tr>
                <td> <span>{this.props.itemLanguage.LanguageName}</span> </td>
                <td> <span>{this.props.itemLanguage.ShortLanguageName}</span> </td>
                <td>
                    {
                        this.props.itemLanguage.IsLanguageDeleted ? <span>Deleted</span> :
                            <button onClick={this.onDelEvent.bind(this)}>Delete</button>
                    }
                </td>
            </tr>
        )

    }
}

const mapStateToProps = (state) => {
    return {
        user: state.default,
        languageList: state.languageList,
        validation: state.content.validation,
        content : state.content.menu
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        writefile: (formValues, userId) => dispatch(callApi(formValues, userId)),
        getLanguageListFromAPI: () => dispatch(getAlllanguageList()),
        handleRowDel: (data, userId) => dispatch(DeleteLanguage(data, userId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageContainer)