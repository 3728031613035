import React from 'react'
import { connect } from 'react-redux'
import MaterialTable from 'material-table'
import moment from 'moment'
import { path } from 'Constants'

// Actions
import {
    benchMarkSuccess,
    benchMarkFailure,
    getBenchMarkDetails,
    addBenchMark,
    updateBenchMark,
    deActiveBenchMark,
    generateBenchMarkJSON
} from 'actions/userActions'

class BusinessCaseBenchmark extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            responseMsg: '',
            isError: 'No',
            isLoading: true,
            data: [],
            columns: [
                { title: 'Suggested Value Description', field: 'Suggested_Value_Description', editable: 'onAdd' },
                {
                    title: 'Region/Country', field: 'Region_Country', editable: 'onAdd', lookup: {
                        'X-Region': 'X-Region',
                        'NAMER': 'NAMER',
                        'APJ': 'APJ',
                        'LAC': 'LAC',
                        'EMEA': 'EMEA',
                        'United States': 'United States',
                        'Afghanistan': 'Afghanistan',
                        'Aland Islands': 'Aland Islands',
                        'Albania': 'Albania',
                        'Algeria': 'Algeria',
                        'American Samoa': 'American Samoa',
                        'Andorra': 'Andorra',
                        'Angola': 'Angola',
                        'Anguilla': 'Anguilla',
                        'Antigua & Barbuda': 'Antigua & Barbuda',
                        'Argentina': 'Argentina',
                        'Armenia': 'Armenia',
                        'Aruba': 'Aruba',
                        'Australia': 'Australia',
                        'Austria': 'Austria',
                        'Azerbaijan': 'Azerbaijan',
                        'Bahamas': 'Bahamas',
                        'Bahrain': 'Bahrain',
                        'Bangladesh': 'Bangladesh',
                        'Barbados': 'Barbados',
                        'Belarus': 'Belarus',
                        'Belgium': 'Belgium',
                        'Belize': 'Belize',
                        'Benin': 'Benin',
                        'Bhutan': 'Bhutan',
                        'Bolivia': 'Bolivia',
                        'Bosnia and Herzegovina': 'Bosnia and Herzegovina',
                        'Botswana': 'Botswana',
                        'Brazil': 'Brazil',
                        'British Indian Ocean Territory': 'British Indian Ocean Territory',
                        'British Virgin Islands': 'British Virgin Islands',
                        'Brunei': 'Brunei',
                        'Bulgaria': 'Bulgaria',
                        'Burkina Faso': 'Burkina Faso',
                        'Burundi': 'Burundi',
                        'Cambodia': 'Cambodia',
                        'Cameroon': 'Cameroon',
                        'Canada': 'Canada',
                        'Cape Verde Island': 'Cape Verde Island',
                        'Cayman Islands': 'Cayman Islands',
                        'Central African Republic': 'Central African Republic',
                        'Chad': 'Chad',
                        'Chile': 'Chile',
                        'China': 'China',
                        'Christmas Island': 'Christmas Island',
                        'Cocos (Keeling) Islands': 'Cocos (Keeling) Islands',
                        'Colombia': 'Colombia',
                        'Comoros': 'Comoros',
                        'Congo': 'Congo',
                        'Congo (DRC)': 'Congo (DRC)',
                        'Cook Islands': 'Cook Islands',
                        'Costa Rica': 'Costa Rica',
                        'Cote dIvoire': 'Cote dIvoire',
                        'Croatia': 'Croatia',
                        'Cuba': 'Cuba',
                        'Cyprus': 'Cyprus',
                        'Czech Republic': 'Czech Republic',
                        'Denmark': 'Denmark',
                        'Djibouti': 'Djibouti',
                        'Dominica': 'Dominica',
                        'Dominican Republic': 'Dominican Republic',
                        'Ecuador': 'Ecuador',
                        'Egypt': 'Egypt',
                        'El Salvador': 'El Salvador',
                        'Equatorial Guinea': 'Equatorial Guinea',
                        'Eritrea': 'Eritrea',
                        'Estonia': 'Estonia',
                        'Ethiopia': 'Ethiopia',
                        'Falkland Islands': 'Falkland Islands',
                        'Faroe Islands': 'Faroe Islands',
                        'Fiji Islands': 'Fiji Islands',
                        'Finland': 'Finland',
                        'France': 'France',
                        'French Guiana': 'French Guiana',
                        'French Polynesia': 'French Polynesia',
                        'Gabon': 'Gabon',
                        'Gambia': 'Gambia',
                        'Gaza Strip': 'Gaza Strip',
                        'Georgia': 'Georgia',
                        'Germany': 'Germany',
                        'Ghana': 'Ghana',
                        'Gibraltar': 'Gibraltar',
                        'Greece': 'Greece',
                        'Greenland': 'Greenland',
                        'Grenada': 'Grenada',
                        'Guadeloupe': 'Guadeloupe',
                        'Guam': 'Guam',
                        'Guatemala': 'Guatemala',
                        'Guinea': 'Guinea',
                        'Guinea-Bissau': 'Guinea-Bissau',
                        'Guyana': 'Guyana',
                        'Haiti': 'Haiti',
                        'Heard Is & McDonald Is': 'Heard Is & McDonald Is',
                        'Honduras': 'Honduras',
                        'Hong Kong': 'Hong Kong',
                        'Hungary': 'Hungary',
                        'Iceland': 'Iceland',
                        'India': 'India',
                        'Indonesia': 'Indonesia',
                        'Iran': 'Iran',
                        'Iraq': 'Iraq',
                        'Ireland': 'Ireland',
                        'Israel': 'Israel',
                        'Italy': 'Italy',
                        'Jamaica': 'Jamaica',
                        'Japan': 'Japan',
                        'Jordan': 'Jordan',
                        'Kazakhstan': 'Kazakhstan',
                        'Kenya': 'Kenya',
                        'Kiribati': 'Kiribati',
                        'Korea': 'Korea',
                        'Kuwait': 'Kuwait',
                        'Kyrgyzstan': 'Kyrgyzstan',
                        'Laos': 'Laos',
                        'Latvia': 'Latvia',
                        'Lebanon': 'Lebanon',
                        'Lesotho': 'Lesotho',
                        'Liberia': 'Liberia',
                        'Libya': 'Libya',
                        'Liechtenstein': 'Liechtenstein',
                        'Lithuania': 'Lithuania',
                        'Luxembourg': 'Luxembourg',
                        'Macao SAR': 'Macao SAR',
                        'Macedonia (FYROM)': 'Macedonia (FYROM)',
                        'Madagascar': 'Madagascar',
                        'Malawi': 'Malawi',
                        'Malaysia': 'Malaysia',
                        'Maldives': 'Maldives',
                        'Mali': 'Mali',
                        'Malta': 'Malta',
                        'Marshall Islands': 'Marshall Islands',
                        'Martinique': 'Martinique',
                        'Mauritania': 'Mauritania',
                        'Mauritius': 'Mauritius',
                        'Mayotte': 'Mayotte',
                        'Mexico': 'Mexico',
                        'Micronesia': 'Micronesia',
                        'Moldova': 'Moldova',
                        'Monaco': 'Monaco',
                        'Mongolia': 'Mongolia',
                        'Montenegro': 'Montenegro',
                        'Montserrat': 'Montserrat',
                        'Morocco': 'Morocco',
                        'Mozambique': 'Mozambique',
                        'Myanmar': 'Myanmar',
                        'Namibia': 'Namibia',
                        'Nauru': 'Nauru',
                        'Nepal': 'Nepal',
                        'Netherlands': 'Netherlands',
                        'Netherlands Antilles': 'Netherlands Antilles',
                        'New Caledonia': 'New Caledonia',
                        'New Zealand': 'New Zealand',
                        'Nicaragua': 'Nicaragua',
                        'Niger': 'Niger',
                        'Nigeria': 'Nigeria',
                        'Niue': 'Niue',
                        'Norfolk Island': 'Norfolk Island',
                        'North Korea': 'North Korea',
                        'Northern Mariana Islands': 'Northern Mariana Islands',
                        'Norway': 'Norway',
                        'Oman': 'Oman',
                        'Pakistan': 'Pakistan',
                        'Palau': 'Palau',
                        'Palestinian Authority': 'Palestinian Authority',
                        'Panama': 'Panama',
                        'Papua New Guinea': 'Papua New Guinea',
                        'Paraguay': 'Paraguay',
                        'Peru': 'Peru',
                        'Philippines': 'Philippines',
                        'Pitcairn Island': 'Pitcairn Island',
                        'Poland': 'Poland',
                        'Portugal': 'Portugal',
                        'Puerto Rico': 'Puerto Rico',
                        'Qatar': 'Qatar',
                        'Reunion': 'Reunion',
                        'Romania': 'Romania',
                        'Russia': 'Russia',
                        'Rwanda': 'Rwanda',
                        'Samoa': 'Samoa',
                        'San Marino': 'San Marino',
                        'Sao Tome & Principe': 'Sao Tome & Principe',
                        'Saudi Arabia': 'Saudi Arabia',
                        'Senegal': 'Senegal',
                        'Serbia': 'Serbia',
                        'Seychelles': 'Seychelles',
                        'Sierra Leone': 'Sierra Leone',
                        'Singapore': 'Singapore',
                        'Slovakia': 'Slovakia',
                        'Slovenia': 'Slovenia',
                        'Solomon Island': 'Solomon Island',
                        'Somalia': 'Somalia',
                        'South Africa': 'South Africa',
                        'Spain': 'Spain',
                        'Sri Lanka': 'Sri Lanka',
                        'St. Kitts & Nevis': 'St. Kitts & Nevis',
                        'St. Lucia': 'St. Lucia',
                        'Saint-Pierre Et Miquelon': 'Saint-Pierre Et Miquelon',
                        'St. Vincent & the Grenadines': 'St. Vincent & the Grenadines',
                        'Sth Georgia & Sandwich Isles': 'Sth Georgia & Sandwich Isles',
                        'Sudan': 'Sudan',
                        'Suriname': 'Suriname',
                        'Svalbard & Jan Mayen Islands': 'Svalbard & Jan Mayen Islands',
                        'Swaziland': 'Swaziland',
                        'Sweden': 'Sweden',
                        'Switzerland': 'Switzerland',
                        'Syria': 'Syria',
                        'Taiwan': 'Taiwan',
                        'Tajikistan': 'Tajikistan',
                        'Tanzania': 'Tanzania',
                        'Thailand': 'Thailand',
                        'Timor-Leste': 'Timor-Leste',
                        'Togo': 'Togo',
                        'Tokelau': 'Tokelau',
                        'Tonga': 'Tonga',
                        'Trinidad & Tobago': 'Trinidad & Tobago',
                        'Tunisia': 'Tunisia',
                        'Turkey': 'Turkey',
                        'Turkmenistan': 'Turkmenistan',
                        'Turks & Caicos Islands': 'Turks & Caicos Islands',
                        'Tuvalu': 'Tuvalu',
                        'Uganda': 'Uganda',
                        'Ukraine': 'Ukraine',
                        'United Arab Emirates': 'United Arab Emirates',
                        'United Kingdom': 'United Kingdom',
                        'United States Minor Outlying Islands': 'United States Minor Outlying Islands',
                        'Uruguay': 'Uruguay',
                        'Uzbekistan': 'Uzbekistan',
                        'Vanuatu': 'Vanuatu',
                        'Vatican City': 'Vatican City',
                        'Venezuela': 'Venezuela',
                        'Vietnam': 'Vietnam',
                        'Virgin Islands': 'Virgin Islands',
                        'Wake Island': 'Wake Island',
                        'Wallis & Futuna Islands': 'Wallis & Futuna Islands',
                        'Western Sahara': 'Western Sahara',
                        'Yemen': 'Yemen',
                        'Zambia': 'Zambia',
                        'Zimbabwe': 'Zimbabwe'

                    }
                },
                {
                    title: 'Industry', field: 'Industry', editable: 'onAdd', lookup:
                    {
                        'X-Industry': 'X-Industry',
                        'Aerospace and Defense': 'Aerospace and Defense',
                        'Automotive': 'Automotive',
                        'Banking': 'Banking',
                        'Chemicals': 'Chemicals',
                        'Consumer Products': 'Consumer Products',
                        'Defense and Security': 'Defense and Security',
                        'Engineering, Construction and Operations': 'Engineering, Construction and Operations',
                        'Healthcare': 'Healthcare',
                        'High Tech': 'High Tech',
                        'Higher Education and Research': 'Higher Education and Research',
                        'Industrial Machinery and Components': 'Industrial Machinery and Components',
                        'Insurance': 'Insurance',
                        'Life Sciences': 'Life Sciences',
                        'Media': 'Media',
                        'Mill Products': 'Mill Products',
                        'Mining': 'Mining',
                        'Oil and Gas': 'Oil and Gas',
                        'Professional Services': 'Professional Services',
                        'Public Sector': 'Public Sector',
                        'Retail': 'Retail',
                        'Sports and Entertainment': 'Sports and Entertainment',
                        'Telecommunications': 'Telecommunications',
                        'Travel and Transportation': 'Travel and Transportation',
                        'Utilities': 'Utilities',
                        'Wholesale Distribution': 'Wholesale Distribution'
                    }
                },
                { title: 'Laggard Value', field: 'Laggard_Value', type: 'numeric', filtering: false },
                { title: 'Average Value', field: 'Average_Value', type: 'numeric', filtering: false },
                { title: 'Best in Class Value', field: 'Best_Value', type: 'numeric', filtering: false },
                { title: 'UOM', field: 'UOM', filtering: false },
                { title: 'SourceID', field: 'Source_ID' },
                { title: 'Source Description', field: 'Source_Description', editable: 'onAdd' },
                { title: 'Source', field: 'Source' },
                { title: 'Suggested Popup', field: 'IsSuggestedPopup', lookup: { 'Active': 'Active', 'Deactive': 'Deactive' } },
                { title: 'Notes', field: 'Notes', filtering: false },
                { title: 'Application', field: 'Application', filtering: false },
                { title: 'Effective Date', field: 'Last Updated', editable: 'never' },
                { title: 'Active/Deactive', field: 'Active', lookup: { 'Active': 'Active', 'Deactive': 'Deactive' } }
            ]
        }
        this.generateJSON = this.generateJSON.bind(this)
        this.loadData = this.loadData.bind(this)
        this.loadData()
    }

    loadData() {
        this.setState({ responseMsg: '', isError: 'No' })
        setTimeout(() => {
            this.props.getBenchMarkDetails()
                .then((response) => {
                    var benchMarkData = [];
                    for (var i = 0; i < response.data.Result.length; i++) {
                        response.data.Result[i].Effective_Date = moment(response.data.Result[i].Effective_Date).format('Do MMM YYYY, h:mm:ss a');
                        var obj = response.data.Result[i];
                        benchMarkData.push(obj);
                    }
                    this.setState({ data: benchMarkData, isLoading: false })
                }).catch((error) => {
                    if (error.stack.includes('Network Error')) {
                        this.setState({ responseMsg: 'API service is down. Please contact administrator.', isError: 'Yes', isLoading: false })
                    }
                    else {
                        if (error.Message !== undefined)
                            this.setState({ responseMsg: error.Message, isError: 'Yes', isLoading: false })
                        else
                            this.setState({ responseMsg: error.response.data.Message, isError: 'Yes', isLoading: false })
                    }
                })
        }, 1000)
    }

    handleonRowAdd(value) {
        if ((Object.keys(value).length) === 0) {
            this.setState({ responseMsg: 'You can not save empty benchmark.', isError: 'Yes' })
            return false;
        }
        this.setState({ responseMsg: '', isError: 'No', isLoading: true })
        var newBenchMark =
        {
            Suggested_Value_Description: '',
            Region_Country: '',
            Industry: '',
            Laggard_Value: '',
            Average_Value: '',
            Best_Value: '',
            UOM: '',
            Source_ID: '',
            Source_Description: '',
            Source: '',
            Notes: '',
            Application: '',
            UpdatedBy: localStorage.getItem('loggedinUser')
        }

        if (value.Suggested_Value_Description !== undefined) {
            newBenchMark.Suggested_Value_Description = value.Suggested_Value_Description
        }
        else {
            this.setState({ responseMsg: 'You can not have empty Suggested_Value_Description.', isError: 'Yes', isLoading: false })
            return false;
        }
        if (value.Region_Country !== undefined) {
            newBenchMark.Region_Country = value.Region_Country
        }
        else {
            this.setState({ responseMsg: 'You can not have empty Region_Country.', isError: 'Yes', isLoading: false })
            return false;
        }
        if (value.Industry !== undefined) {
            newBenchMark.Industry = value.Industry
        }
        else {
            this.setState({ responseMsg: 'You can not have empty Industry.', isError: 'Yes', isLoading: false })
            return false;
        }
        if (value.Laggard_Value !== undefined) {
            newBenchMark.Laggard_Value = value.Laggard_Value
        }
        else {
            this.setState({ responseMsg: 'You can not have empty Laggard_Value.', isError: 'Yes', isLoading: false })
            return false;
        }
        if (value.Average_Value !== undefined) {
            newBenchMark.Average_Value = value.Average_Value
        }
        else {
            this.setState({ responseMsg: 'You can not have empty Average_Value.', isError: 'Yes', isLoading: false })
            return false;
        }
        if (value.Best_Value !== undefined) {
            newBenchMark.Best_Value = value.Best_Value
        }
        else {
            this.setState({ responseMsg: 'You can not have empty Best_Value.', isError: 'Yes', isLoading: false })
            return false;
        }
        if (value.UOM !== undefined) {
            newBenchMark.UOM = value.UOM
        }
        if (value.Source_ID !== undefined) {
            newBenchMark.Source_ID = value.Source_ID
        }
        if (value.Source_Description !== undefined) {
            newBenchMark.Source_Description = value.Source_Description
        }
        if (value.Source !== undefined) {
            newBenchMark.Source = value.Source
        }
        if (value.IsSuggestedPopup !== undefined) {
            if (value.IsSuggestedPopup === 'Active')
                newBenchMark.IsSuggestedPopup = true;
            else
                newBenchMark.IsSuggestedPopup = false
        }
        else {
            this.setState({ responseMsg: 'You can not have empty Suggested Popup.', isError: 'Yes', isLoading: false })
            return false;
        }
        if (value.Notes !== undefined) {
            newBenchMark.Notes = value.Notes
        }
        if (value.Application !== undefined) {
            newBenchMark.Application = value.Application
        }
        if (value.Active !== undefined) {
            if (value.Active === 'Active')
                newBenchMark.Active = true;
            else
                newBenchMark.Active = false
        }
        else {
            this.setState({ responseMsg: 'You can not have empty Active.', isError: 'Yes', isLoading: false })
            return false;
        }
        if (this.state.isError === 'No') {
            this.props.addBenchMark(newBenchMark)
                .then((response) => {
                    this.setState({ responseMsg: 'Successfully added new benchmark!', isError: 'No' })
                    var benchMarkData = [];
                    for (var i = 0; i < response.data.Result.length; i++) {
                        response.data.Result[i].Effective_Date = moment(response.data.Result[i].Effective_Date).format('Do MMM YYYY, h:mm:ss a');
                        var obj = response.data.Result[i];
                        benchMarkData.push(obj);
                    }
                    this.setState({ data: benchMarkData, isLoading: false })
                }).catch((error) => {
                    if (error.stack.includes('Network Error')) {
                        this.setState({ responseMsg: 'API service is down. Please contact administrator.', isError: 'Yes', isLoading: false })
                    }
                    else {
                        if (error.Message !== undefined)
                            this.setState({ responseMsg: error.Message, isError: 'Yes', isLoading: false })
                        else
                            this.setState({ responseMsg: error.response.data.Message, isError: 'Yes', isLoading: false })
                    }
                    return false;
                })
            return true;
        } else {
            return false;
        }
    }

    handleonRowUpdate(value) {
        this.setState({ responseMsg: '', isError: 'No', isLoading: true })
        value.UpdatedBy = localStorage.getItem('loggedinUser')
        if (value.Active === 'Active')
            value.Active = true;
        else
            value.Active = false
        if (value.Laggard_Value !== "" && value.Average_Value !== "" && value.Best_Value !== "") {
            this.props.updateBenchMark(value)
                .then((response) => {
                    this.setState({ responseMsg: 'Successfully updated benchmark!', isError: 'No' })
                    var benchMarkData = [];
                    for (var i = 0; i < response.data.Result.length; i++) {
                        response.data.Result[i].Effective_Date = moment(response.data.Result[i].Effective_Date).format('Do MMM YYYY, h:mm:ss a');
                        var obj = response.data.Result[i];
                        benchMarkData.push(obj);
                    }
                    this.setState({ data: benchMarkData, isLoading: false })
                }).catch((error) => {
                    if (error.stack.includes('Network Error')) {
                        this.setState({ responseMsg: 'API service is down. Please contact administrator.', isError: 'Yes', isLoading: false })
                    }
                    else {
                        if (error.Message !== undefined)
                            this.setState({ responseMsg: error.Message, isError: 'Yes', isLoading: false })
                        else
                            this.setState({ responseMsg: error.response.data.Message, isError: 'Yes', isLoading: false })
                    }
                    return false;
                })
            return true;
        }
        else {
            this.setState({ responseMsg: 'You can not have empty Values.', isError: 'Yes', isLoading: false })
            return false;
        }
    }

    generateJSON() {
        this.setState({ responseMsg: '', isError: 'No', isLoading: true })
        // this.props.generateBenchMarkJSON()
        //     .then(() => {
        //         this.setState({ responseMsg: 'Successfully saved data in JSON format!', isError: 'No', isLoading: false })
        //     }).catch((error) => {
        //         if (error.stack.includes('Network Error')) {
        //             this.setState({ responseMsg: 'API service is down. Please contact administrator.', isError: 'Yes', isLoading: false })
        //         }
        //         else {
        //             if (error.Message !== undefined)
        //                 this.setState({ responseMsg: error.Message, isError: 'Yes', isLoading: false })
        //             else
        //                 this.setState({ responseMsg: error.response.data.Message, isError: 'Yes', isLoading: false })
        //         }
        //     })
        this.props.generateBenchMarkJSON("en")
            .then((response) => {
                if (response.status === 200) {
                    let msg = this.props.content.msgEnglish
                    this.setState({ responseMsg: msg });
                    this.props.generateBenchMarkJSON("cn")
                        .then((response) => {
                            if (response.status === 200) {
                                let msg = this.props.content.msgChinese
                                this.setState({ responseMsg: msg });
                                this.props.generateBenchMarkJSON("jp")
                                    .then((response) => {
                                        if (response.status === 200) {
                                            let msg = this.props.content.msgJapanese
                                            this.setState({ responseMsg: msg });
                                            this.props.generateBenchMarkJSON("pt")
                                                .then((response) => {
                                                    if (response.status === 200) {
                                                        let msg = this.props.content.msgPortuguese
                                                        this.setState({ responseMsg: msg });
                                                        this.props.generateBenchMarkJSON("es")
                                                            .then((response) => {
                                                                if (response.status === 200) {
                                                                    let msg = this.props.content.msgSpanish
                                                                    this.setState({ responseMsg: msg, isLoading: false });
                                                                }
                                                            }).catch((error) => { this.setState({ responseMsg: this.props.content.msgErrorSpanish, isError: 'Yes', isLoading: false }) })
                                                    }
                                                }).catch((error) => { this.setState({ responseMsg: this.props.content.msgErrorPortuguese, isError: 'Yes', isLoading: false }) })
                                        }
                                    }).catch((error) => { this.setState({ responseMsg: this.props.content.msgErrorJapanese, isError: 'Yes', isLoading: false }) })
                            }
                        }).catch((error) => { this.setState({ responseMsg: this.props.content.msgErrorChinese, isError: 'Yes', isLoading: false }) })
                }
            }).catch((error) => { this.setState({ responseMsg: this.props.content.msgErrorEnglish, isError: 'Yes', isLoading: false }) })
    }

    render() {
        return (
            <div className="pt20 benchmark-container">
                <div>
                    {(this.state.isLoading) ?
                        <img style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={path + `images/ajax-loader.gif`} alt="" />
                        : null
                    }
                    {(this.state.isError === 'Yes') ?
                        <div style={{ color: 'red' }} className="cell">{this.state.responseMsg}</div>
                        : <div style={{ color: 'green' }} className="cell">{this.state.responseMsg}</div>
                    }
                </div>
                <br></br>
                <div>
                    <MaterialTable

                        title="Benchmarks Table"
                        columns={this.state.columns}
                        data={this.state.data}
                        actions={[
                            {
                                icon: 'save',
                                tooltip: 'Save to JSON',
                                isFreeAction: true,
                                onClick: (event) => this.generateJSON()
                            }
                        ]}
                        options={{
                            exportButton: true,
                            exportAllData: true,
                            filtering: true,
                            headerStyle: {
                                backgroundColor: '#008fd3',
                                color: '#FFF'
                            }
                        }}
                        editable={{
                            onRowAdd: newData =>
                                new Promise((resolve, reject) => {
                                    setTimeout(() => {
                                        {
                                            const flag = this.handleonRowAdd(newData);
                                            if (flag === false) {
                                                reject()
                                            }
                                            else {
                                                resolve()
                                            }
                                        }
                                        resolve()
                                    }, 100)
                                }),
                            onRowUpdate: (newData) =>
                                new Promise((resolve, reject) => {
                                    setTimeout(() => {
                                        {
                                            const flag = this.handleonRowUpdate(newData);
                                            if (flag === false) {
                                                reject()
                                            }
                                            else {
                                                resolve()
                                            }
                                        }
                                        resolve()
                                    }, 100)
                                })
                        }}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        content: state.content.menu.adminMenuItems[0].fileUploadManager
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        benchMarkSuccess: (bool) => dispatch(benchMarkSuccess(bool)),
        benchMarkFailure: (error) => dispatch(benchMarkFailure(error)),
        getBenchMarkDetails: () => dispatch(getBenchMarkDetails()),
        addBenchMark: (value) => dispatch(addBenchMark(value)),
        updateBenchMark: (value) => dispatch(updateBenchMark(value)),
        deActiveBenchMark: (value) => dispatch(deActiveBenchMark(value)),
        generateBenchMarkJSON: (lang) => dispatch(generateBenchMarkJSON(lang)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BusinessCaseBenchmark)//Component
