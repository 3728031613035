import React, { Fragment } from 'react';
import { connect } from 'react-redux'
import ScrollArea from 'react-scrollbar'
import Button from 'UI/Button';
import { RadioButton } from 'material-ui';

import {
    getAllIndustriesSuccess,
    getAllIndustriesLoading,
    getAllIndustriesFailure,
    getAllIndustries,
    getAllIndustriesTreeStructure,
    selectedIndustryDetails,
    setSearchClearFlag
} from 'actions/spendActions'

class IndustryOverridePopUp extends React.Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef()
        this.state = {
            industries: [],
            searchText: '',
            isIndustrySelected: false,
            error: '',
            isLoading: true,
            industriesInitial: [],
            isError: false
        }
        this.loadIndustires = this.loadIndustries.bind(this)
        this.search = this.search.bind(this)
        this.clear = this.clear.bind(this)
        this.handleKeyDown = this.handleKeyDown.bind(this)
        this.radioButtonChecked = this.radioButtonChecked.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }

    loadIndustries() {
        let L1Industries = [], L2Industries = [], L3Industries = [], L4Industries = [], L5Industries = [], L6Industries = [], L7Industries = [], L8Industries = [], L9Industries = [], filteredArray = [], industryMapping = []
        let key = 'children';
        let counter = 0
        this.props.getAllIndustries()
            .then((response) => {
                this.industryList = []
                this.processIndustries(response.data.Result, undefined, 1);
                this.props.getAllIndustriesLoading(false);
                this.props.getAllIndustriesTreeStructure(this.industryList.L1Industries)
                this.setState({ industries: this.industryList.L1Industries, industriesInitial: response.data.Result })
            }).catch((error) => {
                if (error.stack.includes('Network Error')) {
                    this.setState({ responseMsg: 'API service is down. Please contact administrator', isError: true })
                }
                else {
                    this.setState({ responseMsg: 'An error has occurred', isError: true })
                }
            })
    }
    
    processIndustries(filteredRows, topIndustryName, order) {
        var columnName = 'L'+order+'_INDUSTRY_NAME';
        var arrayName = 'L'+order+'Industries';
        var children = 'L'+(order + 1)+'Industries';
        var counter = 0;
        
        if(order === 10 || ((topIndustryName === 'Non-Procured Spend' || topIndustryName === 'Unclassified') && order === 3)) {
            return
        }

        this.industryList[arrayName] = []

        var uniqueIndustryNames = [... new Set(filteredRows.map((item) => {return item[columnName]}))]
        uniqueIndustryNames.forEach((industryName) => {
            if (!industryName.includes('(L'+(order-1)+')')) {
                if(order === 1) {
                    topIndustryName = industryName
                } 
                this.industryList[arrayName].push({
                    'industryCount': counter++, 'industry': industryName, 'level': order, 'topmostIndustry': topIndustryName, 'children': [], visible: false, checked: false, show: true
                });
                
                var filteredRowsTemp = filteredRows.filter(function (obj) {
                    return obj[columnName] === industryName;
                });
                
    
                this.processIndustries(filteredRowsTemp, topIndustryName, order + 1)
                if (this.industryList[children] && this.industryList[children].length > 0) {
                    if(!((topIndustryName === 'Non-Procured Spend' || topIndustryName === 'Unclassified') && order >= 2))
                        this.industryList[arrayName][this.industryList[arrayName].length - 1]['children'] = this.industryList[children]
                }
            }
        })
    }
	
	componentDidMount() {
        this.loadIndustries();
	}
	
	groupToggle(e, industry, level) {
		var industries = this.state.industries;
		for(var idx = 0; idx < industries.length; idx++) {
            var element = industries[idx]
            var currentVisibility = element.visible
            this.setVisibleFlag( element, industry, level)
			if(element.visible !== currentVisibility) {
				break
			}
		}
		
		e.stopPropagation()
		this.setState({industries: industries})
	}

	setVisibleFlag(elt, industry, level){
		if(elt.children === undefined || elt.children.length === 0) {
			if(industry === elt.industry && level === elt.level) {
				elt.visible = !elt.visible
			}

			return;
		}
		
		if(industry === elt.industry && level === elt.level) {
			elt.visible = !elt.visible
			return
		} else {
			elt.children.forEach((child) => {
                this.setVisibleFlag(child, industry, level)
                elt.visible = elt.visible || child.visible
			})
		}
	}

	radioButtonChecked(e, industry, level) {
        var industries = this.state.industries;
		for(var idx = 0; idx < industries.length; idx++) {
			var element = industries[idx]
			this.setCheckedFlag( element, industry, level)
        }
        
        let selectedIndustryDetails = this.state.industriesInitial.find((obj) => {
            let name = 'L'+this.selectedIndustry.level+'_INDUSTRY_NAME'
			return this.selectedIndustry.industry === obj[name] && this.selectedIndustry.level == obj.INDUSTRY_LEVEL && this.selectedIndustry.topmostIndustry == obj.L1_INDUSTRY_NAME
        })
        
        this.props.selectedIndustryDetails(selectedIndustryDetails)

		
		e.stopPropagation()
		this.setState({industries: industries})
    }
    
    setCheckedFlag(elt, industry, level){
		if(industry === elt.industry && level === elt.level) {
            elt.checked = true
            this.selectedIndustry = elt;
		} else {
            elt.checked = false	
        }
        
        if(elt.children === undefined || elt.children.length === 0) {
            return;
        }
        
        elt.children.forEach((child) => {
            this.setCheckedFlag(child, industry, level)
        })
    }
    
    handleChange(e) {
        this.setState({searchText: e.target.value})
    }

    handleKeyDown(e) {
        if (e.key === 'Enter') {
            this.search()
        }
    }

    search() {
        let searchText = this.state.searchText
        var industries = this.state.industries;
		for(var idx = 0; idx < industries.length; idx++) {
			var element = industries[idx]
			this.setVisibleFlagForSearch( element, searchText)
        }
        
        this.setState({industries: industries})
    }

    setVisibleFlagForSearch(elt, searchText){
        if(searchText.trim().length === 0) {
            elt.visible = false
            elt.show = true
        } else if(elt.industry.toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase()) > -1) {
            elt.visible = true
            elt.show = true
            return
		} else {
            elt.visible = false
            elt.show = false
        }

        if(elt.children === undefined || elt.children.length === 0) {
            return;
        }
        
        for(var idx = 0; idx < elt.children.length; idx++) {
            var child = elt.children[idx]
            
            this.setVisibleFlagForSearch(child, searchText)
            elt.visible = elt.visible || child.visible
            elt.show = elt.show || child.show
        }
	}

    clear() {
        this.setState({searchText: ''}, this.search)
    }

    getChildrenElement(childrenTemp) {
		if(childrenTemp === undefined || childrenTemp.length === 0) {
			return ''
		} else {
			var children = childrenTemp.map((item) => {
				return item.show ? <li>
				<div class="grid-x">
                    {item.children.length > 0 ?
                        <div onClick={(e) => this.groupToggle(e, item.industry, item.level)} style={{paddingLeft:"5px", marginTop: "3px"}} className="float-right plus-icon hand-cursor">
						{item.visible ? <img width="10" src="images/minus-inactive.svg" /> : <img width="10" src="images/add.svg" />}
					</div> : ''}
					
					<div style={{paddingLeft:"12px", marginTop: "4px"}} class="medium-11">
                        { !this.props.readOnly ?
						<div className="float-left width30">
                            <RadioButton className='radioButton'
                            id={item.industry} 
                            checked={item.checked} 
                            onClick={(e) => this.radioButtonChecked(e, item.industry, item.level)}
                            name={item.industry} 
                            value={item.industry} /></div>
                            : null }
						<div style={{marginTop: "1px"}} className="float-left">{item.industry}</div>
					</div>
					<div style={{fontSize: "0.75rem"}} class="medium-11 medium-offset-1">
					    {item.visible ? this.getChildrenElement(item.children) : ''}
				    </div>
				</div>
		</li> : ''
			})

			return children;
		}
	}

    render() {
        return (
            <div id="capiq-search" className="treeContainer company-search-popup">
                <div className="grid-x">
                    <div className="medium-12 border-bottom">
                        <div className="title float-left">{this.props.content.spendAnalysis.detailedFile.industry}</div>
                        <div className="float-right close-icon" onClick={this.props.close}>
                            <img width="10" src="images/close_inactive.svg" />
                        </div>
                    </div>
                </div>
                {!this.state.isError && this.props.loading ?
                <div style={{width:'auto'}} className="loading loading--is-loading"><div className="loader" />Loading...</div> :
                <Fragment>
                <div className="grid-x">
                    <div className="medium-10 large-10 search-input">
                        <div className="search-bar">
                            <input ref={this.myRef} key="searchKey" autoFocus style={{ width: '100%', height: '3.125rem', paddingRight: '35px' }} type='text' value={this.state.searchText} onKeyDown={(e) => this.handleKeyDown(e)} onChange={this.handleChange} placeholder='Search' />
                            <img className="hand-cursor" style={{ position: 'absolute', top: '12px', right: '12px' }} width="20px" onClick={this.search} src="/images/search_big_inactive.svg" />
                        </div>
                    </div>
                    <div className="medium-2 large-2" style={{ paddingTop: '13px' }}>
                        <div className="popupOkBtn">
                            <Button onClick={this.clear} float type="submit" content={this.props.ui.clear} />
                        </div>
                    </div>
                </div>
                <ScrollArea minScrollSize={40}
                    speed={0.8}
                    className="box"
                    contentClassName="content"
                    horizontal={false}
                >
                    {this.state.isError? '' : 
                        this.state.industries.map((item) => {
                            return (
                                item.show ? <li>
                                    <div class="grid-x">
                                    {item.children.length > 0 ?
                                        <div onClick={(e) => this.groupToggle(e, item.industry, item.level)} style={{paddingLeft:"5px", marginTop: "3px"}} className="float-right plus-icon hand-cursor">
                                            {item.visible ? <img width="10" src="images/minus-inactive.svg" /> : <img width="10" src="images/add.svg" />}
                                        </div> : ''}
                                        
                                        <div style={{paddingLeft:"12px", marginTop: "4px"}} class="medium-11">
                                          { (item.industry !== 'Non-Procured Spend' && item.industry !== 'Unclassified' && !this.props.readOnly)? <div className="float-left width30">
                                                <RadioButton className='radioButton'
                                                id={item.industry} 
                                                checked={item.checked} 
                                                onClick={(e) => this.radioButtonChecked(e, item.industry, item.level)}
                                                name={item.industry} value={item.industry} />
                                            </div> : ''}
                                            <div style={{fontSize: "0.75rem", marginTop: "1px"}} className="float-left">{item.industry}</div>
                                        </div>
                                        <div style={{fontSize: "0.75rem"}} class="medium-11 medium-offset-1">
                                            {item.visible ? this.getChildrenElement(item.children) : ''}
                                        </div>
                                    </div>
                                </li> : ''
                            )}
                        )
                    }
                    {this.state.isError? <div style={{ marginTop: '5px', marginLeft: '5px' }} className="errors">{this.state.responseMsg}</div> : ''}
                    
                </ScrollArea>
                </Fragment>}
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        industryTree: state.spendDetails.industryTree,
        isIndustryTreeLoading: state.spendDetails.isIndustryTreeLoading,
        loading: state.spendDetails.isLoading,
        content: state.content,
        ui: state.content.ui
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAllIndustriesSuccess: message => dispatch(getAllIndustriesSuccess(message)),
        getAllIndustriesFailure: error => dispatch(getAllIndustriesFailure(error)),
        getAllIndustriesLoading: bool => dispatch(getAllIndustriesLoading(bool)),
        getAllIndustries: () => dispatch(getAllIndustries()),
        getAllIndustriesTreeStructure: message => dispatch(getAllIndustriesTreeStructure(message)),
        setSearchClearFlag: message => dispatch(setSearchClearFlag(message)),
        selectedIndustryDetails: message => dispatch(selectedIndustryDetails(message))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(IndustryOverridePopUp); 