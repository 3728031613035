import React from 'react'
import { connect } from 'react-redux'
import {
  Route,
  NavLink,
  MemoryRouter
} from "react-router-dom";
import SectionManager from "./SectionManager";
import ReportManager from "./ReportManager";
import LayoutManager from "./LayoutManager"
import FileManager from "./FileManager";
import SlideManager from "./SlideManager"
import SequenceManager from "./SequenceManager"
import Indexx from "./Index"


class ReportContainer extends React.Component {
  
  render() {
    return (
      <MemoryRouter>
      <div className="popup-content">
        <div className="medium-12 title">{this.props.content.title}</div>
        <ul className="headers heading">
            <li><NavLink exact to="/">{this.props.content.report}</NavLink></li>
            <li><NavLink to="/LayoutManager">{this.props.content.layout}</NavLink></li>
            <li><NavLink to="/FileManager">{this.props.content.file}</NavLink></li>
            <li><NavLink to="/SectionManager">{this.props.content.section}</NavLink></li>
            <li><NavLink to="/SlideManager">{this.props.content.slide}</NavLink></li>
            <li><NavLink to="/SequenceManager">{this.props.content.sequence}</NavLink></li>
            {/* <li><NavLink to="/Indexx">{this.props.content.index}</NavLink></li> */}
        </ul>
        <div className="content">
            <Route exact path="/" component={ReportManager}/>
            <Route path="/LayoutManager" component={LayoutManager}/>
            <Route path="/FileManager" component={FileManager}/>
            <Route path="/SectionManager" component={SectionManager}/>
            <Route path="/SlideManager" component={SlideManager}/>
            <Route path="/SequenceManager" component={SequenceManager}/>
            {/* <Route path="/Indexx" component={Indexx}/> */}
        </div>         
      </div>
      </MemoryRouter>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    language: state.language,
    content: state.content.reportManager
  }
}

export default connect(mapStateToProps)(ReportContainer)
