import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { actions } from 'react-redux-form'
import ScrollArea from 'react-scrollbar'
import Button from 'UI/Button'

// Actions
import {
  logoutUser,
  resetToken,
  reportSubmit,
  reportSubmitSuccess,
  reportSubmitFailure,
  resetError,
  resetMessage,
  showValueStoryMenu
} from 'actions/userActions'

import { valueStoryReset } from 'actions/fetchValueStory'

// Utils
import { clearLocalStorage, BreakLine } from '../../utils/Utils'
import classnames from 'classnames'

// Components
import MenuItems from './MenuItems'
import MenuCheckList from './MenuChecklist'
import ValueStoryContainer from 'components/ValueStory/ValueStoryContainer'
import ChangePassword from './ChangePassword'
import Report from './Report'
import About from './About'
import SaveAs from './SaveAs'

// UI
import Modal from 'reboron/OutlineModal'

import { modalStyle, modalContentStyle, path ,reportBugContentStyle } from 'Constants'
import DomainDetails from 'components/Admin/DomainManager'

import UserManagerContainer from 'components/Admin/UserManagerContainer'
import EmailManager from 'components/Admin/EmailManager'
import ReportContainer from 'components/Admin/ReportContainer'
import NotificationManager from 'components/Admin/NotificationManager' 
import ReportBugContainer from 'components/ReportBug/ReportBugContainer'
import FileUploadManager from 'components/Admin/FileUploadManager'
import UserAndRoleManager from 'components/Admin/UserAndRoleManagerContainer'
// import DefineScopeContainer from 'components/Spend/DefineScopeContainer'
// import DetailedFileContainer from 'components/Spend/DetailedFileContainer'
//import YourFileContainer from 'components/Spend/YourFileContainer'
import GICSAdminController from 'components/GICSAdmin/GICSAdminController'
import ReleaseContainer from 'components/ReleaseNotification/ReleaseContainer'
import SpendParameter from 'components/Admin/SpendParametersContainer'
import { backdropStyle, changeModalStyle, changeModalBorderRectStyle, changeModalContentStyle, changeModalBorderStyle } from 'Constants'
import VideoManager from 'components/Admin/VideoManagerContainer'
import WithdrawConsent from './WithdrawConsent'
import CountryLookup from 'components/Admin/CountryLookup'
import EnablementParameters from 'components/Admin/EnablementParameters'
import ShareFilesOnJumphost from 'components/Admin/ShareFilesOnJumphost'
import SystemReporting from 'components/Admin/SystemReporting' 
import ANIDManager from 'components/Admin/ANIDManager'

const changeBackdropStyle = Object.assign({}, backdropStyle);
changeBackdropStyle.zIndex = '1050';

const alertModalStyle = Object.assign({}, changeModalStyle);
alertModalStyle.zIndex = '1060';

// Settings
const autoHeightModalStyle = Object.assign({}, modalStyle)

const valueStoryModalStyle = Object.assign({}, modalStyle)
autoHeightModalStyle.height = 'auto'
valueStoryModalStyle.maxWidth = '1024px'
valueStoryModalStyle.height = 'auto'

const components = {
  emailManager: EmailManager,
  valueStory: ValueStoryContainer,
  reportManager: ReportContainer,
  allowedDomains: DomainDetails,
  reportBug: ReportBugContainer,
  notificationManager: NotificationManager,
  about: About,
  fileUploadManager: FileUploadManager,
  userManagerContainer: UserManagerContainer,
  password: ChangePassword,
  report: Report,
  saveAs: SaveAs,
  userAndRoleManager: UserAndRoleManager,
  // defineScopeContainer: DefineScopeContainer,
  // detailedFileContainer: DetailedFileContainer,
  // yourFileContainer : YourFileContainer,
  gicsAdmin: GICSAdminController,
  releaseContainer: ReleaseContainer,
  SpendParameter : SpendParameter,
  videoManager : VideoManager,
  withdrawConsent: WithdrawConsent,
  countryLookup: CountryLookup,
  enablementParameters: EnablementParameters,
  shareFilesonJumohost: ShareFilesOnJumphost,
  systemReporting: SystemReporting,
  ANIDManager: ANIDManager
};

class MenuContainer extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      reportType: 'Summary',
      VSId: null
    }

    this.showModal = this.showModal.bind(this)
    this.hideModal = this.hideModal.bind(this)
    this.handleMenuAction = this.handleMenuAction.bind(this)
    this.handleLogout = this.handleLogout.bind(this)
    this.handleRadioButtonClick = this.handleRadioButtonClick.bind(this)
    this.handleReportSubmit = this.handleReportSubmit.bind(this)
    this.disableReport = this.disableReport.bind(this)
    this.handleMenuAccordion = this.handleMenuAccordion.bind(this)
    this.handleValueStoryMenu = this.handleValueStoryMenu.bind(this)
    this.setReportBugAlertVisibility = this.setReportBugAlertVisibility.bind(this)
    this.hideReportBugAlertModal = this.hideReportBugAlertModal.bind(this)
    this.showReportBugAlertModal = this.showReportBugAlertModal.bind(this)
    this.reportDisabled = false
  }

  handleValueStoryMenu(action) {
    switch (action) {
      case 'valueStory':
      case 'languagemanager':
      case 'transaltescreen':
      case 'benchmark':
      case 'carousel':
      case 'userAndRoleManager':
      case 'subTabsContainer':
      case 'yourFile':
      case 'topDetails':
      case 'defineScope':
      case 'enablement':
      case 'gicsAdmin':
      case 'releases':
      case 'spendParameter':
      case 'videoManager':
      case 'systemReporting':
      case 'countryLookup':
      case 'anIdManager':
        this.props.showValueStoryMenu(false);
        break;
      case 'detailedFile':

      default:
        break
    }
  }

  handleMenuAction(e, action) {
    window.scrollTo({top: 0, behavior: 'smooth'})
    this.handleValueStoryMenu(action);

    switch (action) {
      case 'valueStory':
      case 'report':
      case 'about':
      case 'saveAs':
      case 'userManagerContainer':
        this.showModal(action)
        break
      case 'allowedDomains':
        this.showModal(action)
        break
      case 'reportBug':
        this.showModal(action)
        break
      case 'emailManager':
        this.showModal(action)
        break
      case 'reportManager':
        this.showModal(action)
        break
      case 'password':
        this.props.resetError()
        this.showModal(action)
        break
      case 'account':
        e.stopPropagation()
        this.handleMenuAccordion(action)
        break
      case 'admin':
        e.stopPropagation()
        this.handleMenuAccordion(action)
        break
      case 'languagemenu':
        e.stopPropagation()
        this.handleMenuAccordion(action)
        break
      case 'logout':
        this.handleLogout()
        break
      case 'languagemanager':
        this.props.history.push({
          pathname: '/language-manager',
          nav: this.props.nav,
          validation: this.props.validation,
          content: this.props.content
        })
        break
      case 'transaltescreen':
        this.props.history.push({
          pathname: '/translate-screen',
          nav: this.props.nav,
          validation: this.props.validation,
          content: this.props.content,
          logout: this.handleLogout
        })

        break

      case 'notificationManager':
        this.showModal(action)
        break

      case 'fileUploadManager':
        this.showModal(action)
        break

      case 'benchmark':
        this.props.history.push({
          pathname: '/benchmarks',
          content: this.props.content
        })
        break

      case 'carousel':
        this.props.history.push({
          pathname: '/carousels',
          content: this.props.content
        })
        break

      case 'userAndRoleManager':
        this.props.history.push({
          pathname: '/userAndRoleManager'
        })
        break

      case 'subTabsContainer':
        this.props.history.push({
          pathname: '/subTabsContainer'
        })
        break

      case 'yourFile':
        this.props.history.push({
          pathname: '/yourFile'
        })
        break

      // case 'defineScope':
      //   this.props.history.push({
      //     pathname: '/defineScopeContainer'
      //   })
      //   break

      case 'enablement':
        this.props.history.push({
          pathname: '/enablementContainer'
        })
        break

      // case 'detailedFile':
      //   this.props.history.push({
      //     pathname: '/detailedFileContainer'
      //   })
      //   break

      case 'gicsAdmin':
        this.props.history.push({
          pathname: '/gicsAdmin'
        })
        break
      case 'spendParameter':
        this.props.history.push({
          pathname: '/spendParameter'
        })
        break

      case 'releases':
        this.props.history.push({
          pathname: '/releases',
          content: this.props.content
        })
        break
      case 'videoManager':
        this.props.history.push({
          pathname: '/videoManager'
        })
          break

      case 'countryLookup':
        this.props.history.push({
          pathname: '/countryLookup'
        })
          break
      case 'systemReporting':
        this.showModal(action)
        break
      case 'enablementParameters':
        this.showModal(action)
          break
      case 'shareFilesonJumohost':
        this.showModal(action)
          break
      case 'withdrawConsent':
        this.showModal(action)
        break
      case 'anIdManager':
          this.props.history.push({
            pathname: '/anIdManager'
          })
            break  
      default:
        break
    }
  }

  handleRadioButtonClick(value) {
    this.setState({
      reportType: value
    })

    this.props.resetMessage()
  }

  handleReportSubmit(formValues) {
    this.props.resetError()
    let values = Object.assign({}, formValues)
    values.Type = this.state.reportType

    this.props
      .reportSubmit(values)
      .then(response => {
        this.props.reportSubmitSuccess(response.data.Message)

        // Reset form
        document.getElementById('report.Email').blur()
        this.formDispatch(actions.reset('report.Email'))
      })
      .catch(error => {
        if (error.response) {
          this.props.reportSubmitFailure(error.response.data.Message)
        }
      })
  }



  handleMenuAccordion(action) {
    this.setState({
      [action]: !this.state[action]
    })
  }



  handleLogout() {
    this.props
      .logoutUser()
      .then(() => {
        this.props.resetToken()
        clearLocalStorage()

        // Redirect to home page
        window.location.href = path

        // this.props.resetValueStory()
      })
      .catch(() => {
        clearLocalStorage();
        // Redirect to home page
        window.location.href = path;
      })
  }

  showModal(action) {
    this.refs[action].show()
  }

  hideModal(action) {
    // this.refs[action].hide()

    if (action === 'report') {
      this.formDispatch(actions.reset('report'))
      this.props.resetError()
      this.props.resetMessage()
      // this.refs[action].hide()
    }
    if(action === "reportBug"){
      this.props.resetError()
      this.props.resetMessage()}
    if (this.refs[action] !== undefined) {
      this.refs[action].hide()
    }

  }

  disableReport() {
    const valueDriversSelected =
      this.props.userData &&
      this.props.userData.BusinessValueToYou &&
      this.props.userData.BusinessValueToYou[0].ValueDriver.filter(
        valueDriver => valueDriver.Selected === true
      )

    if (
      this.props.userData && // value story loaded
      (!valueDriversSelected ||
        valueDriversSelected.length === 0 || // no value drivers selected
        this.props.form.valid === false || // form is invalid
        this.props.userData.BusinessValueFromSap[0].NPV === 0) // no calculated NPV Value
    ) {
      return true
    } else {
      return false
    }
  }

  componentWillReceiveProps() {
    // Hide Value Story modal if new Value Story is opened
    if (this.props.userData !== null) {
      if (this.props.userData.VSId !== this.state.VSId) {
        this.hideModal(this.props.content.menuItems[0].action)

        this.setState({
          VSId: this.props.userData.VSId
        })
      }
    }

    if (!this.props.menuVisible) {
      //   this.setState({
      //     accountMenuExpanded: false,
      //     adminMenuExpanded: false,
      //     languageMenuExpanded: false
      //   })
    }
  }

  isParentMenuEnable = (data, user) => {
    var actionName = null
    if (user !== null && typeof (data) === 'object') {
      var userFeatures = Object.values(user).map(data => data.Action)
      Object.values(data).map(item => {
        if (
          item.action !== undefined &&
          userFeatures.indexOf(item.action) > -1
        ) {
          actionName = item.action
          if (this.state[item.action] === undefined) {
            this.setState({ [item.action]: false })
          }
        }

        if (item.subItems !== undefined) {
          item.subItems.map(subitem => {
            if (userFeatures.indexOf(subitem.action) > -1) {
              actionName = item.action
              if (this.state[item.action] === undefined) {
                this.setState({ [item.action]: false })
              }
            }
          })
        }
      })
    }
    return actionName
  }

  hideReportBugAlertModal(action) {
    this.refs[action].hide()
  }

  showReportBugAlertModal(action) {
    this.refs[action].show()
  }

  setReportBugAlertVisibility(reportBugResMsg) {
    this.setState({reportBugResMsg})
    this.showReportBugAlertModal("reportBugAlert")
  }

  render() {
    var onlyOpenNewStory = []
    var instance = this
    var showCheckList = 0
    onlyOpenNewStory.push(this.props.content.menuItems[0]) // Added for To open the value story only if Role is admin.
    return this.props.user !== null &&
      this.props.user.user !== null &&
      this.props.user.user.applicationFeatures !== null &&
      this.props.user.user.applicationFeatures !== undefined ? (
        <div>
          <div>
            <Modal
              ref="reportBugAlert"
              modalStyle={alertModalStyle}
              contentStyle={changeModalContentStyle}
              backdropStyle={changeBackdropStyle}
            >
              <div className="grid-x grid-padding-x change-popup">
                <div className="medium-12">
                  <button onClick={() => this.hideReportBugAlertModal("reportBugAlert")} className="close" />
                </div>
                <div className="medium-12 pt30">
                  {this.state.reportBugResMsg}
                </div>
                <div className="medium-12 popupOkBtn">
                  <div className="float-right">
                    <div className="float-left"><Button
                      content={"Close"}
                      onClick={() => this.hideReportBugAlertModal("reportBugAlert")}
                      color /></div>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
          <div
            className={classnames('menu', {
              'menu--visible': this.props.menuVisible
            })}
          >
            <ScrollArea minScrollSize={40}
              speed={0.8}
              className="menu-cont"
              contentClassName="content"
              horizontal={false}
            >
              {
                Object.values(this.props.content).map(function (childMenuitem) {
                  var actionName = instance.isParentMenuEnable(childMenuitem, instance.props.user.user.applicationFeatures)
                  if (actionName !== null) {
                    if (instance.props.userData !== null && (actionName === "valueStory" || actionName === "saveAs") && showCheckList === 0) {
                      showCheckList = showCheckList + 1;
                    }
                    return (
                      <div className="menu-items-wrapper">
                        <MenuItems
                          content={childMenuitem}
                          handleMenuAction={instance.handleMenuAction}
                          handleMenuAccordion={instance.handleMenuAccordion}
                          disabled={instance.disableReport()}
                          menuExpanded={instance.state[actionName]}
                          user={instance.props.user.user}
                          userData={instance.props.userData}
                        />
                        {/* {
                          (showCheckList === 1 && (actionName === "valueStory" || actionName === "saveAs")) ?
                            <MenuCheckList
                              content={instance.props.content.menuChecklist}
                              interests={instance.props.interests}
                              priorities={instance.props.priorities}
                              disabled={instance.props.form.valid}
                            />
                            : null
                        } */}
                      </div>
                    )
                  }
                })}
              {/* <div className="menu__footer-logo">
                <img src={path + this.props.content.sapLogo} alt="SAP" />
              </div> */}
            </ScrollArea>
          </div>
          {
            Object.values(instance.props.user.user.applicationFeatures).map(function (roledetails) {
              if (roledetails.IsPopup) {
                const SpecificStory = components[roledetails.Action];
                return (
                  <div>
                    {SpecificStory !== undefined ?
                      <div>
                        {roledetails.Action === 'reportBug' ?
                          <Modal ref={roledetails.Action}
                            backdrop={false}
                            keyboard={false}
                            modalStyle={changeModalBorderStyle}
                            contentStyle={reportBugContentStyle}
                            rectStyle={changeModalBorderRectStyle}
                          >
                            <SpecificStory
                              history={instance.props.history}
                              hideModal={instance.hideModal}
                              setReportBugAlertVisibility={instance.setReportBugAlertVisibility}
                              content={instance.props.content[roledetails.Action]} />
                            <button onClick={() => instance.hideModal(roledetails.Action)} className='close' />
                          </Modal>
                          :
                          <Modal ref={roledetails.Action}
                            modalStyle={modalStyle}
                            contentStyle={modalContentStyle}
                          >
                            <SpecificStory history={instance.props.history}
                              hideModal={instance.hideModal}
                              content={instance.props.content[roledetails.Action]} />
                            <button onClick={() => instance.hideModal(roledetails.Action)} className='close' />
                          </Modal>
                        }
                      </div>
                      : null}
                  </div>

                )
              }
            })}

        </div>
      ) : null
  }
}
const mapStateToProps = state => {
  return {
    content: state.content.menu,
    interests: state.content.discovery.interests,
    priorities: state.priorities,
    form: state.discoveryForm.$form,
    validation: state.content.validation,
    user: state.default,
    userData: state.valueStory
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logoutUser: () => dispatch(logoutUser()),
    resetValueStory: () => dispatch(valueStoryReset()),
    resetToken: () => dispatch(resetToken()),
    reportSubmit: formValues => dispatch(reportSubmit(formValues)),
    reportSubmitSuccess: user => dispatch(reportSubmitSuccess(user)),
    reportSubmitFailure: error => dispatch(reportSubmitFailure(error)),
    resetError: () => dispatch(resetError()),
    resetMessage: () => dispatch(resetMessage()),
    showValueStoryMenu: (menuItem) => dispatch(showValueStoryMenu(menuItem))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuContainer)
