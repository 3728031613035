import React from 'react';
import { connect } from 'react-redux'
import { Control, Form, Errors, actions } from 'react-redux-form'
import { RadioButton } from 'material-ui';
import ScrollArea from 'react-scrollbar'

import Button from 'UI/Button';
import ButtonWithoutLoader from 'UI/ButtonWithoutLoader'
import Select from 'react-select'

import { validators, dropdownColourStyles } from 'Constants'
import { formatThousands, BreakLine, round, mergeArrayByKey } from 'utils/Utils'

import {
    resetError
} from 'actions/userActions'

import {
    searchResult,
    fetchCapIqSearchResults,
    capIqSearchSuccess,
    capIqSearchFailure
}
    from 'actions/searchActions'

    import{
        selectedCapIqIdDetails
    }from 'actions/spendActions'

import { capIQFetchData } from 'actions/discoveryActions'
import { valueStoryFetchDataSuccess } from 'actions/fetchValueStory'
import {
    getSPCapitalIQData,
    getSPCapitalIQLoading,
    getSPCapitalIQSuccess,
    getSPCapitalIQFailure,
    getCapIQDataBlank
} from 'actions/spendActions'

import { Checkbox } from 'material-ui';

class CapIQSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: props.data,
            peArray: [],
            bpArray: [],
            flag: false,
            index: -1,
            checked: false,
            checkIndex: -1,
            // companyName: [],
            //companyName: '',
            capIQId: '',
            stockTicker: '',
            selectedData: null,
            searchOption: [{ label: 'CapIQ ID', value: 'CapIQ ID' }, { label: 'Company Name', value: 'Company Name' }, { label: 'Stock Ticker', value: 'Stock Ticker' }],
            selectedOption: 'Company Name',
            searchText: '',
            companyDetails: [],
            companyObj: [],
            isLoading: false,
            ultimateParentChecked: true,
            publicCompanyChecked: false,
            currencyCode: 'USD',
            capIQResult: [],
            errorMsg: '',
            searchFlag: false,
            searchMessage: '',
            countryArray: [],
            selectedCountry: 'All Countries'
        }
        this.loadPE_Data = this.loadPE_Data.bind(this)
        this.loadCompanyData = this.loadCompanyData.bind(this)
        this.createCompanyProfileArray = this.createCompanyProfileArray.bind(this)
        this.changeUltimateParentCheck = this.changeUltimateParentCheck.bind(this)
        this.changePublicCompanyCheck = this.changePublicCompanyCheck.bind(this)
        this.RadioButtonChecked = this.RadioButtonChecked.bind(this);
        this.onCapIQIdChangeEvent = this.onCapIQIdChangeEvent.bind(this);
        this.onSearchTextChangeEvent = this.onSearchTextChangeEvent.bind(this);
        this.onStockTickerChangeEvent = this.onStockTickerChangeEvent.bind(this);
        this.search = this.search.bind(this);
        this._handleKeyDown = this._handleKeyDown.bind(this);
        this.save = this.save.bind(this)
        this.addCurrencyMask = this.addCurrencyMask.bind(this)
        this.filterSearchData = this.filterSearchData.bind(this)
        this.clearCAPIQ = this.clearCAPIQ.bind(this);
    }

    addCurrencyMask(value) {
        const regex = /\d*\.?\d{0,2}/g
        const filteredValue = regex.exec(value)
        let currencySymbol = this.props.currencySymbols[this.state.currencyCode];
        if (this.state.currencyCode === null) {
            currencySymbol = '$';
        }

        if (filteredValue[0] === '') {
            return currencySymbol + formatThousands(this.value) + ' M'
        } else {
            this.value = filteredValue[0]
            return currencySymbol + formatThousands(this.value) + ' M'
        }

    }


    save() {
        if (this.state.checked) {
            this.props.capIqRadioButtonClicked(this.state.selectedData)            
        }
        this.props.close();
    }
    clearCAPIQ() {
        // let arr = [{
        // 	Organization: '', Industry: '', Location: '0', AnnualRevenue: null, TotalSpend: '0', CapIQId: '',
        // 	CapIQCompany: '', GlobalUltimateId: '', GlobalUltimateCompany: '', BPId: '', BPCompany: '',
        // 	PEId: '', PECompany: '', CurrencyCode: 'USD'
        // }]
        const companyData = this.props.userData;
        companyData.Organization = '';
        companyData.Industry = '';
        companyData.Location = '0';
        companyData.AnnualRevenue = null;
        companyData.TotalSpend = '0';
        companyData.CapIQId = '';
        companyData.CurrencyCode = 'USD';
        this.props.valueStoryFetchDataSuccess(companyData)
        this.props.capIqDataCleared()
        this.props.getCapIQDataBlank(true)
        this.props.close();       
        
    }

    changeUltimateParentCheck() {
        this.setState({ ultimateParentChecked: !this.state.ultimateParentChecked })
        //this.setState({ ultimateParentChecked: !this.state.ultimateParentChecked }, this.filterSearchData)
    }

    changePublicCompanyCheck() {
        this.setState({ publicCompanyChecked: !this.state.publicCompanyChecked })
        //this.setState({ publicCompanyChecked: !this.state.publicCompanyChecked }, this.filterSearchData)
    }

    onCapIQIdChangeEvent(event) {
        this.setState({ capIQId: event.target.value !== undefined ? event.target.value : '' });
    }

    onSearchTextChangeEvent(event) {
        this.setState({ searchText: event.target.value !== undefined ? event.target.value : '', checked: false, searchFlag: event.target.value !== '' ? true : false });
    }

    onStockTickerChangeEvent(event) {
        this.setState({ stockTicker: event.target.value !== undefined ? event.target.value : '' });
    }

    _handleKeyDown(e) {
        if (e.key === 'Enter') {
            this.search()
        }
    }

    filterSearchData() {
        let data = this.state.capIQResult
        let filteredResult = []
        //check if the checkbox to include only ultimate parent companies checked
        if (this.state.ultimateParentChecked && this.state.publicCompanyChecked) {
            for (let i = 0; i < data.length; i++) {
                //Include only the results which have a parent id & type publi
                if (data[i].PARENT_COMPANY_ID == data[i].COMPANY_ID && data[i].COMPANY_TYPE == 'Public Company') {
                    filteredResult.push(data[i])
                }
            }
        } else if (this.state.ultimateParentChecked && !this.state.publicCompanyChecked) {
            for (let i = 0; i < data.length; i++) {
                //Include only the results which have a parent id
                if (data[i].PARENT_COMPANY_ID == data[i].COMPANY_ID) {
                    filteredResult.push(data[i])
                }
            }
        }
        else if (!this.state.ultimateParentChecked && this.state.publicCompanyChecked) {
            for (let i = 0; i < data.length; i++) {
                //Include only the results which have a company type public
                if (data[i].COMPANY_TYPE == 'Public Company') {
                    filteredResult.push(data[i])
                }
            }
        }
        else {
            filteredResult = data;
        }
        this.setState({ searchResult: filteredResult }, this.loadPE_Data)
    }

    search() {
        let searchText = this.state.searchText;
        let searchType = 1;
        if (this.state.selectedOption == 'Stock Ticker') {
            searchType = 2;
        }

        if (searchText.trim().length === 0) {
            return;
        }
        let parentFlag = 1;
        if (this.state.ultimateParentChecked) {
            parentFlag = 2;
        }
        let publicFlag = 1;
        if (this.state.publicCompanyChecked) {
            publicFlag = 2;
        }
        let country = this.state.selectedCountry == 'All Countries' ? 'all' : this.state.selectedCountry;
        this.props.searchResult([])
        this.setState({ searchMessage: '', searchResult: [], companyDetails: [] }, this.loadPE_Data)
        this.props.fetchCapIqSearchResults(searchText, searchType, parentFlag, publicFlag, country)
            .then((result) => {
                this.props.capIqSearchSuccess(result)
                this.props.searchResult(result.data.Result)
                let searchResult = result.data.Result.SearchResult;
                let scoreResult = result.data.Result.ScoreResult;

                let data = mergeArrayByKey(scoreResult, searchResult, 'COMPANY_ID')
                let length = 0
                let error = ''
                let message = 'Search results: '
                if (data.length > 500) {
                    length = 500
                } else {
                    length = data.length
                    this.setState({ errorMsg: error })
                }
                // let filteredResult = []
                // //check if the checkbox to include only ultimate parent companies checked
                // if (this.state.ultimateParentChecked && this.state.publicCompanyChecked) {
                //     for (let i = 0; i < length; i++) {
                //         //Include only the results which have a parent id & type publi
                //         if (data[i].PARENT_COMPANY_ID == data[i].COMPANY_ID && data[i].COMPANY_TYPE == 'Public Company') {
                //             filteredResult.push(data[i])
                //         }
                //     }
                // } else if (this.state.ultimateParentChecked && !this.state.publicCompanyChecked) {
                //     for (let i = 0; i < length; i++) {
                //         //Include only the results which have a parent id
                //         if (data[i].PARENT_COMPANY_ID == data[i].COMPANY_ID) {
                //             filteredResult.push(data[i])
                //         }
                //     }
                // }
                // else if (!this.state.ultimateParentChecked && this.state.publicCompanyChecked) {
                //     for (let i = 0; i < length; i++) {
                //         //Include only the results which have a company type public
                //         if (data[i].COMPANY_TYPE == 'Public Company') {
                //             filteredResult.push(data[i])
                //         }
                //     }
                // }
                // else {
                //     filteredResult = data;
                // }

                if (data.length >= 500) {
                    let error1 = 'Your search has returned 500+ records. Please refine and re-search.'
                    this.setState({ errorMsg: error1, searchMessage: '' })
                } else {
                    if (data.length == 0) {
                        this.setState({ searchMessage: '' })
                    }
                    let msg = message + data.length
                    this.setState({ searchMessage: msg })
                }
                this.setState({ searchResult: data, capIQResult: data }, this.loadPE_Data)
                //this.setState({ capIQResult: result.data.Result }, this.filterSearchData)
            }).catch((error) => {
                this.props.capIqSearchFailure(error.response.data.Message)
                this.setState({ searchMessage: '', errorMsg: '' })
            })
    }



    RadioButtonChecked(e) {
        var companyName = [];
        let data = this.state.searchResult[e.currentTarget.value]
        console.log("capIQ data: ", data)
        if (data.AVATAR_INDUSTRY != null) {
            const industryIdx = this.props.contentData.industries.findIndex(item => data.AVATAR_INDUSTRY.toLowerCase() === item.toLowerCase());
            const companyData = this.props.userData;
            companyData.Industry = industryIdx;
            this.props.valueStoryFetchDataSuccess(companyData);
        }

        //this.props.capIqRadioButtonClicked(data)
        //After this send the data back to search results to populate company profile..................................
        this.props.selectedCapIqIdDetails(data)
        this.setState({ checked: true, checkIndex: e.currentTarget.id, selectedData: data, isLoading: true, currencyCode: data.CURRENCY_CODE }, this.callMethods)
    }

    callMethods() {
        this.loadPE_Data();
        this.createCompanyProfileArray(null);
    }

    createCompanyProfileArray(id) {
        let capIQ = id != null && id != '' ? id : this.state.selectedData != null ? this.state.selectedData.COMPANY_ID : null;
        if(capIQ != null && capIQ != '' && capIQ != undefined)
        {
            this.props.getSPCapitalIQData(capIQ)
            .then((result) => {
                this.props.getSPCapitalIQSuccess(result)
                let data = result.data.Result;
                this.setState({ companyObj: data, capIQId: capIQ }, this.loadCompanyData)
            }).catch((error) => {
                this.props.getSPCapitalIQFailure(error.response.data.Message)
            })
        }        
    }

    loadPE_Data() {
        var result = (<div>
            {this.state.searchResult.map((data, index) => (
                <div className="pe-div">
                    <div className="company-name-row">
                        <div className="float-left collapse-expand">
                            <div className="float-left radio-btn">
                                <RadioButton className='radioButton'
                                    checked={this.state.checked && data.COMPANY_ID == this.state.checkIndex}
                                    id={data.COMPANY_ID}
                                    value={index}
                                    onClick={this.RadioButtonChecked}
                                    color="default"
                                />
                            </div>
                        </div>
                        <div className="float-left details-text">
                            <div>
                                <span className="dark">{data.COMPANY_NAME}</span>
                                <span> |  {this.props.content.capIdSearchText}: <span className="dark">{data.COMPANY_ID}</span></span>
                            </div>
                            <div>
                                <span>{data.COUNTRY_NAME}</span>
                                <span> |  {data.EXCHANGE_SYMBOL}: {data.STOCK_TICKER}</span>
                                <span> |  {this.props.content.capIndustryText}: {data.COMPANY_INDUSTRY_DESC}</span>
                            </div>
                            <div>
                                <span>{this.props.content.capParentCompanyText}: {data.PARENT_COMPANY_NAME}</span>
                                <span> | {"Parent Id"}: {data.PARENT_COMPANY_ID}</span>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>)

        this.setState({ peArray: result })
    }

    loadCompanyData() {
        var data = this.state.companyObj[0];
        this.props.capIQFetchData(data)


        const companyData = this.props.userData;
        companyData.CurrencyCode = data.CURRENCY_CODE;
        this.props.valueStoryFetchDataSuccess(companyData);

        var result = (<React.Fragment>
            {/* <p>Company Name: <span>{data.name}</span></p> */}
            <div className="company-info-row">
                <div className="grid-x">
                    <div className="medium-5 large-5 title">{this.props.company.COMPANY_NAME}</div>
                    <div className="medium-7 large-7 value">{data.COMPANY_NAME != null ? data.COMPANY_NAME : '-'}</div>
                </div>
                <div className="grid-x">
                    <div className="medium-5 large-5 title">{this.props.company.COMPANY_ADDRESS}</div>
                    <div className="medium-7 large-7 cell value">
                        {data.COMPANY_ADDRESS != null ? data.COMPANY_ADDRESS : '-'}
                    </div>
                </div>
                <div className="grid-x">
                    <div className="medium-5 large-5 title">{"Industry Description"}</div>
                    <div className="medium-7 large-7 cell value">
                        {data.INDUSTRY_DESCRIPTION != null ? data.INDUSTRY_DESCRIPTION : '-'}
                    </div>
                </div>
                <div className="grid-x">
                    <div className="medium-5 large-5 title">{this.props.company.COMPANY_TYPE}</div>
                    <div className="medium-7 large-7 cell value">
                        {data.COMPANY_TYPE != null ? data.COMPANY_TYPE : '-'}
                    </div>
                </div>
                <div className="grid-x">
                    <div className="medium-5 large-5 title">{this.props.company.COMPANY_STATUS}</div>
                    <div className="medium-7 large-7 cell  value">
                        {data.COMPANY_STATUS != null ? data.COMPANY_STATUS : '-'}
                    </div>
                </div>
                <div className="grid-x">
                    <div className="medium-5 large-5 title">{this.props.company.EMPLOYEE_COUNT}</div>
                    <div className="medium-7 large-7 cell  value">
                        {data.EMPLOYEE_COUNT != null ? formatThousands(data.EMPLOYEE_COUNT) : '-'}
                    </div>
                </div>
                <div className="grid-x">
                    <div className="medium-5 large-5 title">{this.props.company.WEBPAGE}</div>
                    <div className="medium-7 large-7 cell blue value">
                        <a target="_blank" href={'https://' + data.WEBPAGE}>
                            {data.WEBPAGE != null ? data.WEBPAGE : '-'}</a>
                    </div>
                </div>
                <div className="grid-x">
                    <div className="medium-5 large-5 title">{this.props.company.FISCAL_YEAR}</div>
                    <div className="medium-7 large-7 cell  value">
                        {data.FISCAL_YEAR != null ? data.FISCAL_YEAR : '-'}
                    </div>
                </div>
                <div className="grid-x">
                    <div className="medium-5 large-5 title">{this.props.company.TOT_REV}</div>
                    <div className="medium-7 large-7 cell  value">
                        {data.TOT_REV != null ? this.addCurrencyMask(data.TOT_REV) : '-'}
                    </div>
                </div>
                <div className="grid-x">
                    <div className="medium-5 large-5 title">{this.props.company.GRO_MARG}</div>
                    <div className="medium-7 large-7 cell  value">
                        {data.GRO_MARG != null ? round(data.GRO_MARG, 2) + ' %' : '-'}
                    </div>
                </div>
                <div className="grid-x">
                    <div className="medium-5 large-5 title">{this.props.company.EBITDA_MARG}</div>
                    <div className="medium-7 large-7 cell  value">
                        {data.EBITDA_MARG != null ? round(data.EBITDA_MARG, 2) + ' %' : '-'}
                    </div>
                </div>
                <div className="grid-x">
                    <div className="medium-5 large-5 title">{this.props.company.DSO}</div>
                    <div className="medium-7 large-7 cell  value">
                        {data.DSO != null ? round(data.DSO, 2) : '-'}
                    </div>
                </div>
                <div className="grid-x">
                    <div className="medium-5 large-5 title">{this.props.company.DPO}</div>
                    <div className="medium-7 large-7 cell  value">
                        {data.DPO != null ? round(data.DPO, 2) : '-'}
                    </div>
                </div>
                <div className="grid-x">
                    <div className="medium-5 large-5 title">{this.props.company.DIO}</div>
                    <div className="medium-7 large-7 cell  value">
                        {data.DIO != null ? round(data.DIO, 2) : '-'}
                    </div>
                </div>
                <div className="grid-x">
                    <div className="medium-5 large-5 title">{this.props.company.CCC}</div>
                    <div className="medium-7 large-7 cell  value">
                        {data.CCC != null ? round(data.CCC, 2) : '-'}
                    </div>
                </div>
                <div className="grid-x">
                    <div className="medium-5 large-5 title">{this.props.company.COGS}</div>
                    <div className="medium-7 large-7 cell  value">
                        {data.COGS != null ? this.addCurrencyMask(data.COGS) : '-'}
                    </div>
                </div>
                <div className="grid-x">
                    <div className="medium-5 large-5 title">{this.props.company.SG_A}</div>
                    <div className="medium-7 large-7 cell  value">
                        {data.SG_A != null ? this.addCurrencyMask(data.SG_A) : '-'}
                    </div>
                </div>
                <div className="grid-x">
                    <div className="medium-5 large-5 title">{this.props.company.INV}</div>
                    <div className="medium-7 large-7 cell  value">
                        {data.INV != null ? this.addCurrencyMask(data.INV) : '-'}
                    </div>
                </div>
            </div>
        </React.Fragment>)
        //this.props.capIQSearchTree(result)
        this.setState({ companyDetails: result, isLoading: false })

    }


    handleSelectionChange(value) {
        this.setState({ selectedOption: value });
    }

    handleCountryChange(value) {
        this.setState({ selectedCountry: value });
    }

    componentDidMount() {
        console.log('Discovery Form Props: ', this.props.contentData);
        var array1 = [];
        this.props.contentData.capIQCountryList.forEach((element) => {
            var obj = {};
            obj.label = element;
            obj.value = element;
            array1.push(obj);
        });
        console.log(this.props.doubleCapIqId)
        this.createCompanyProfileArray(this.props.doubleCapIqId);
        this.setState({ countryArray: array1, capIQId: this.props.doubleCapIqId })
    }
    
    componentWillUnmount() {
        this.props.resetError()
    }

    render() {
        return (
            <div id="capiq-search" className="treeContainer company-search-popup">
                <div className="grid-x">
                    <div className="medium-12 border-bottom">
                        <div className="float-left popHeader">{this.props.content.capIqSearchTitle}</div>
                        <div className="float-right close-icon" onClick={this.props.close}>
                            <img width="10" src="images/close_inactive.svg" />
                        </div>
                    </div>
                    <div className="medium-6">
                        <div className="medium-12 large-12">
                            <div className="label-searchBy">{this.props.content.searchBy}<span className="asterisk">*</span></div>
                            <div className="control-wrapper pr">
                                <Select
                                    className="border"
                                    value={this.state.searchOption.filter(option => option.label === this.state.selectedOption)}
                                    noOptionsMessage={() => null}
                                    onChange={(value) => this.handleSelectionChange(value.label)}
                                    options={this.state.searchOption}
                                    isSearchable={false}
                                    validateOn="change" styles={dropdownColourStyles}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="medium-6" style={{ position: 'relative'}}>
                        <div className="label-filterBy" >{'Filter By'}<span className="asterisk">*</span></div>
                    </div>
                    <div className="medium-6">
                        <div className="medium-8 search-box">
                            {/* <div className="label">{this.props.content.capCompanyNameSearchText}</div> */}
                            <div className="control-wrapper">
                                <input type='text' onKeyDown={(e) => this._handleKeyDown(e)} onChange={this.onSearchTextChangeEvent} value={this.state.searchText} />
                                <button onClick={() => this.search()} >Search</button>
                                {/* <img width="14px" onClick={() => this.search()}  src={this.state.searchFlag ? "/images/search_big_active.svg":"/images/search_big_inactive.svg"} /> */}
                            </div>

                        </div>
                    </div>
                    <div className="medium-6">
                        <div className="medium-12 large-12">


                            <div className="control-wrapper pt10">
                                <Select
                                    className="border"
                                    value={this.state.countryArray.filter(option => option.label === this.state.selectedCountry)}
                                    noOptionsMessage={() => null}
                                    onChange={(value) => this.handleCountryChange(value.label)}
                                    options={this.state.countryArray}
                                    isSearchable={false}
                                    validateOn="change" styles={dropdownColourStyles}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="medium-12 large-12">{this.state.searchMessage}</div>
                    <div className="medium-12 large-12">
                        <div className='errors'>{this.state.errorMsg}</div>
                    </div>
                    <div className="medium-12 large-12 dsf">
                        <div className="ultimateParentCheckbox">
                            <Checkbox checked={this.state.ultimateParentChecked} onClick={this.changeUltimateParentCheck}
                            />
                        </div>
                        <div className="ultimateParentCheckText">
                            {this.props.content.ultimateParentCheckbox}
                        </div>
                        <div className="ultimateParentCheckbox">
                            <Checkbox checked={this.state.publicCompanyChecked} onClick={this.changePublicCompanyCheck}
                            />
                        </div>
                        <div className="ultimateParentCheckText">
                            {this.props.content.publicCheckbox}
                        </div>
                    </div>
                </div>
                <div className="grid-x">
                    <div className="medium-6">
                        <ScrollArea minScrollSize={40}
                            speed={0.8}
                            className="box"
                            contentClassName="content"
                            horizontal={false} >
                            {this.props.search.isLoading ?
                                <div className="loading loading--is-loading"><div className="loader" />Loading...</div> :
                                this.state.peArray
                            }
                            <div style={{ marginTop: '5px', marginLeft: '5px' }} className="errors">{this.props.search.error}</div>
                        </ScrollArea>
                    </div>
                    <div className="medium-6">
                        <ScrollArea minScrollSize={40}
                            speed={0.8}
                            className="box"
                            contentClassName="content"
                            horizontal={false} >
                            {this.state.isLoading ?
                                <div className="loading loading--is-loading"><div className="loader" />Loading...</div> :
                                this.state.companyDetails
                            }
                            <div style={{ marginTop: '5px', marginLeft: '5px' }} className="errors">{this.props.search.error}</div>
                        </ScrollArea>
                    </div>
                </div>

                <div className="grid-x">                    
                    <div className="medium-12 popupOkBtn">
                        <div className="float-right">
                            <div className="float-left">
                                <Button
                                    content={this.props.ui.cancel}
                                    color
                                    onClick={this.props.close}
                                />
                            </div>
                            <div className="float-left">
                                <ButtonWithoutLoader
                                    content={this.props.ui.ok}
                                    disabled={!this.state.checked}
                                    color
                                    float
                                    //onClick={this.save}
                                    onClick={this.props.capIqIdOverride}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        content: state.content.discovery.form,
        data: state.treeData,
        firstSearchResult: state.searchData,
        search: state.searchResultData,
        ui: state.content.ui,
        company: state.content.capitalDetails,
        currencySymbols: state.content.CurrencySymbols,
        userData: state.valueStory,
        countryList: state.content.CountryList,
        capIQSearchData: state.capIQSearchTree,
        spendDetails: state.spendDetails
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCapIqSearchResults: (value, type, parentFlag, publicFlag, country) => dispatch(fetchCapIqSearchResults(value, type, parentFlag, publicFlag, country)),
        capIqSearchSuccess: (msg) => dispatch(capIqSearchSuccess(msg)),
        capIqSearchFailure: (error) => dispatch(capIqSearchFailure(error)),
        searchResult: (result) => dispatch(searchResult(result)),
        getSPCapitalIQSuccess: message => dispatch(getSPCapitalIQSuccess(message)),
        getSPCapitalIQFailure: error => dispatch(getSPCapitalIQFailure(error)),
        getSPCapitalIQLoading: bool => dispatch(getSPCapitalIQLoading(bool)),
        getSPCapitalIQData: (CapIQID) => dispatch(getSPCapitalIQData(CapIQID)),
        capIQFetchData: (capIQData) => dispatch(capIQFetchData(capIQData)),
        // capIQSearchTree: (capIQTreeData) => dispatch(capIQSearchTree(capIQTreeData)),
        valueStoryFetchDataSuccess: (valueStory) => dispatch(valueStoryFetchDataSuccess(valueStory)),
        resetError: () => dispatch(resetError()),
        getCapIQDataBlank:  bool => dispatch(getCapIQDataBlank(bool)),
        selectedCapIqIdDetails: message => dispatch(selectedCapIqIdDetails(message))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CapIQSearch);
