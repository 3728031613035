import React from 'react'
import { connect } from 'react-redux'
import EnablementInsightTabContainer from 'components/Spend/EnablementInsight/EnablementInsightTabContainer'

class EnablementInsightContainer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {         
        }
        this.redirect = this.redirect.bind(this)
    }
 
    redirect(){
        this.props.dispatchTabChange(1)
    }

    render() {
        return (
            <div>
               <EnablementInsightTabContainer reDir = {this.redirect} />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        content: state.content.spendAnalysis.defineScope
    }
}

const mapDispatchToProps = (dispatch) => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EnablementInsightContainer)


