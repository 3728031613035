import React from 'react'
import ExpandableRow from './ExpandableRow'
//import RecommedationRowContainer from '../RecommedationRowContainer'
import { convertToMillion, formatThousands } from 'utils/Utils'
import classNames from "classnames";
import { connect } from 'react-redux'

class ExpandableTableRowContainer extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			rows: []
		}

		this.handleCollapseChange = this.handleCollapseChange.bind(this)
	}

	componentDidMount() {
		if(this.props.data.rows) {
			this.setState({rows: this.props.data.rows})
		}
	}

	componentWillReceiveProps(nextProps) {
		if(this.props.data.rows) {
			this.setState({rows: nextProps.data.rows})
		}
	}

	handleCollapseChange(e, row, rows) {
		row.expand = !row.expand

		this.setState({rows: rows})
	}

	render() {
			return(<tbody>
			{this.state.rows && this.state.rows.map((row, i) =>
				{
					var header = <tr onClick={(e) => this.handleCollapseChange(e, row, this.state.rows)} 
								className={classNames({'collapsible': !row.expand, 'active': row.expand})} >
									<td className='tableContent boldRow ' width='25%'>
									<span className='onMouseOver' style={{ cursor: 'pointer' }} width='45%'
									 onClick={() => this.props.showEnablementSupplierDetailsModal(this.props.tableName, this.props.columnDetails.firstColumn, row.data[this.props.columnDetails.firstColumn], null, null)}>
									{row.content !== undefined ? row.content : null }  
									</span>                                                  
									</td>
									
									{this.props.tabName ===  this.props.content.onboardingWSTabName || this.props.tabName ===  this.props.content.enablementRecomTabName?
									<td className="tableContent boldRow column-align-right" width='15%'></td>
									: null }
									
									<td className="tableContent boldRow column-align-right" width='15%'>{row.data !== undefined && row.data.CNT_VENDOR_NAME !== undefined ? formatThousands(row.data.CNT_VENDOR_NAME): null}</td>
									<td className="tableContent boldRow column-align-right" width='10%'>{row.data !== undefined && row.data.CNT_VENDOR_PER !== undefined ? row.data.CNT_VENDOR_PER.toFixed(2) + "%": null }</td>
									
									{ this.props.tableName === this.props.content.networkMatch.catalogMatchHeader || this.props.tableName === this.props.content.networkMatch.integrationMatchHeader ?
                                    <td className="tableContent boldRow column-align-right" width='10%'>{ row.data !== undefined && row.data.CNT_UNIQ_VENDOR_AN_ID !== undefined ? formatThousands(row.data.CNT_UNIQ_VENDOR_AN_ID) : null }</td>  
                                    : null
                                    }
                                    
                                   
									<td className="tableContent boldRow column-align-right" width='10%'>{row.data !== undefined && row.data.SUM_AGGR_SPEND !==undefined ? this.props.currencySymbol + convertToMillion(row.data.SUM_AGGR_SPEND, true): null }</td>
									<td className="tableContent boldRow column-align-right" width='10%'>{row.data !== undefined && row.data.SUM_AGGR_SPEND_PER !== undefined ? row.data.SUM_AGGR_SPEND_PER.toFixed(2)+"%" : null }</td>
                                   
                                    
									<td className="tableContent boldRow column-align-right" width='10%'>{row.data !== undefined && row.data.SUM_SAP_ARIBA_DOC_PROXY !== undefined ? formatThousands(row.data.SUM_SAP_ARIBA_DOC_PROXY) : null }</td>
									<td className="tableContent boldRow column-align-right" width='10%'>{row.data !== undefined && row.data.SAP_ARIBA_DOC_PROXY_PER !== undefined ? row.data.SAP_ARIBA_DOC_PROXY_PER.toFixed(2)+"%" : null }</td>
									
								</tr> 
					let rows = []
					if(row.expand) {
						rows = row.collapseChild.map(value => {
							if(value.content === row.content) {
								return <React.Fragment></React.Fragment>
							}
							return <ExpandableRow					
								data = {value}
								showEnablementSupplierDetailsModal={this.props.showEnablementSupplierDetailsModal}
								columnDetails={this.props.columnDetails}
								parentColumnValue={row.data[this.props.columnDetails.firstColumn]}
								secondParentColumnValue={row.data[this.props.columnDetails.secondColumn]}		
								tableName={this.props.tableName}
								tabName={this.props.tabName}	
								metricsType = {this.props.metricsType}	
								content = {this.props.content}	
								fromGanntChart = {this.props.fromGanntChart}
								currencySymbol = {this.props.currencySymbol}
							/>
						})
					}
					
				return <React.Fragment>{header}{rows}</React.Fragment>
				
			}
			)}
		</tbody>
		);
	}
}

const mapStateToProps = state => {
    return {          
        content: state.content.enablementInsight,       
    }
}

export default connect(mapStateToProps) (ExpandableTableRowContainer)
