import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as XLSX from 'xlsx';
import TextfilterRenderer from 'UI/TextFilterRenderer';
import ReactDataGrid from 'react-data-grid';
import { path } from 'Constants'
import _, { cloneDeep } from 'lodash';


import {
    valueStoryCurrentMenu,
    showValueStoryMenu
} from 'actions/userActions'

import {
    getSccLoadData,
    getSCCMasterColumnsConfiguration,
    uploadSccTabData,
    addSelectedColumnForScc,
    getSccSelectedColumn
} from 'actions/sccActions'

import {
    setValueStoryDmciDetails,
    setResultSummaryDetails,
    setTopDetails,
    resetError,
    clearCurrentActiveRequest,
    getCapIQDataBlank,
    defineScopeLoading,
    selectedEnablementWaveDetails,
    UpdateComment
} from 'actions/spendActions'

import {
    formatThousands
} from 'utils/Utils'
import Button from 'UI/Button';


import { CustomGridSccE, PopupE } from 'UI/CustomComponents/Controls';
import EnablementWavePopUp from '../Spend/EnablementWavePopUp'
import { Toolbar, Data, Filters, Editors, Menu } from "react-data-grid-addons";
const selectors = Data.Selectors;
const { DropDownEditor } = Editors;
// const dropDownEnablementRmdValues = ["Already Transacting", "Relationship Exists", "Enterprise – Supplier Dedicated Specialist", "Enterprise – Integration Candidate",
//     "Enterprise – Integration Ready Candidate", "Enterprise – Integration", "Enterprise – Portal", "Out of Scope – Category Not Eligible"]
//updated with spend files dropdown values
const dropDownEnablementRmdValues = ['Already Transacting','Relationship Exists','Enterprise - Supplier Dedicated Specialist (Integration & Catalog)',
    'Enterprise - Integration Ready & Catalog Candidate','Enterprise - Integration Ready Candidate','Enterprise - Integration & Catalog Candidate',
    'Enterprise - Integration & Catalog,Enterprise - Integration Candidate','Enterprise - Integration','Enterprise - Portal & Catalog Candidate',
    'Enterprise - Portal & Catalog','Enterprise - Portal','Managed Standard Account','On Going Standard Account',
    'Out of Scope - Category Not Eligible']
const dropDownEnablementRmdEditor = <DropDownEditor options={dropDownEnablementRmdValues} />

export const make_cols = refstr => {
    let o = [], C = XLSX.utils.decode_range(refstr).e.c + 1;
    for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i }
    return o;
};

//this method replaces key values with hana keys for column headers
const changeKeyObjects = (arr, replaceKeys) => {
    return arr.map(item => {
        const newItem = {};
        Object.keys(item).forEach(key => {
            //where ever you have used the symbol ˏ in excel below condition needs to be used as while converting file to it might not understand that symbol
            if (key == 'If Global Supplierˏ what regions?') newItem[replaceKeys['If Global Supplierˏ what regions?']] = item[['If Global Supplierˏ what regions?']];
            newItem[replaceKeys[key]] = item[[key]];
        });
        return newItem;
    });
};

class SccComponent extends Component {

    constructor(props) {
        super(props)

        this.state = {
            tabId: 1,
            nextButtonLabel: props.ui.next,
            language: '',
            columns: [],
            colConfig: [],
            data: [],
            file: null,
            fileLoaded: false,
            isLoading: true,
            keys: [],
            columnSelect: [],
            isSaveInProgress: false,
            sccExcelColumns: [],
            headers: [],
            openEnablementInsightPopUp: false,
            enablementWaveInputValue: null,
            headerToColumn: {},
            filteredSummationArray: {},
        }
        this.customGridRef = React.createRef();

        this.handleChange = this.handleChange.bind(this);
        this.handleFile = this.handleFile.bind(this);
        this.loadData = this.loadData.bind(this);
        this.saveData = this.saveData.bind(this);
        this.onColumnSelection = this.onColumnSelection.bind(this);
        this.filterByColumnSelection = this.filterByColumnSelection.bind(this);
        this.checkForErrors = this.checkForErrors.bind(this);
        this.outsideEnablementInsightPopupClick = this.outsideEnablementInsightPopupClick.bind(this);
        this.closeEnablementInsightPopUp = this.closeEnablementInsightPopUp.bind(this);
        this.enablementWaveInput = this.enablementWaveInput.bind(this);
        this.clearEnablementWaveOverride = this.clearEnablementWaveOverride.bind(this);
        this.getHeaderGroups = this.getHeaderGroups.bind(this)
    }

    filterByColumnSelection(cols) {
        this.props.getSccSelectedColumn(this.props.spendDetails.currentActiveRequest.DMC_ID, this.props.spendDetails.currentActiveRequest.VS_ID, this.props.user.user.Id).then((response) => {
            let selectedCols = response.data.Result;
            if (selectedCols.length > 0) {
                let columnSelect = this.state.columnSelect;
                let columns = cols;
                let columnDefs = [];
                columns.map((column) => {
                    if (selectedCols.indexOf(column.key) > -1) {
                        columnDefs.push(column);
                    }
                })
                // this.originalColumns = cloneDeep(columns);
                columnSelect.map((column) => {
                    if (selectedCols.indexOf(column.id) > -1) {
                        column.isSelected = true;
                    } else if (column.id == 'DATA_COLLECTIONS_SKEY') {
                        column.isSelected = true;
                    } else {
                        column.isSelected = false;
                    }
                })

                columnDefs.sort((a, b) => { return a.screenSeq - b.screenSeq });
                const headerToColumn = this.getHeaderGroups(columnDefs);                
                this.setState({
                    headerToColumn: headerToColumn, columns: columnDefs.length === 0 ? [] : columnDefs.sort((a, b) => { return a.screenSeq - b.screenSeq }), colConfig: cols, columnSelect: columnSelect, isLoading: this.state.isSaveInProgress ? true : false
                })
                this.props.defineScopeLoading(false)
            } else {
                cols.sort((a, b) => { return a.screenSeq - b.screenSeq });
                const headerToColumn = this.getHeaderGroups(cols);

                this.setState({
                    headerToColumn: headerToColumn, columns: cols.length === 0 ? [] : cols.sort((a, b) => { return a.screenSeq - b.screenSeq }), colConfig: cols, isLoading: this.state.isSaveInProgress ? true : false
                })
                this.props.defineScopeLoading(false)
            }
        });
    }

    sum(finalRows, colDefs = this.state.columnDefs) {
        if (finalRows.length >= 1) {
            let arr2 = cloneDeep(finalRows[0]);
            for (const key in arr2) {
                arr2[key] = ''
            }

            arr2['DATA_COLLECTIONS_SKEY'] = 'Total :'

            let summationArrayKeys = ['AGGR_INV_CNT', 'AGGR_INV_SPEND', 'AGGR_PO_CNT', 'AGGR_PO_SPEND', 'AGREEGATE_PAYMENT_COUNT','AGGR_OC_CNT','AGG_ASN_CNT',
                'ERP_INV_CNT', 'ERP_INVOICE_SPEND', 'ERP_PO_CNT', 'ERP_PO_SPEND', 'PAYMENT_COUNT', "SAP_ARIBA_DOC_PROXY", "SAP_ARIBA_SPEND_PROXY", "SAP_ARIBA_SPEND_PROXY_USD","AN_SMP_FEE_EST_BUYER_CURR","ERP_INV_SPEND_IN_STD_CURR","ENTERPRISE_ESTIM_SUPP_YIELD","ENTERPRISE_ESTIM_SPEND_YIELD","ENTERPRISE_ESTIM_DOC_YIELD","ERP_DOCUMENT_CNT","ERP_SPEND","ESTI_CAPTURABLE_NET_TRNS_FEE_REVENUE"]

            summationArrayKeys.forEach(key => arr2[key] = 0)

            finalRows.forEach(item => {
                summationArrayKeys.forEach(key => {
                    arr2[key] += ((item[key] === '' || item[key] === undefined) ? 0 : item[key])
                })
            })

            arr2['ENTERPRISE_ESTIM_SUPP_YIELD'] = arr2['ENTERPRISE_ESTIM_SUPP_YIELD'] / 100;
            //arr2['ERP_INV_CNT'] =(Math.round(parseFloat(arr2['ERP_INV_CNT']) * 100) / 100).toFixed(2); 
            arr2['ERP_INV_CNT'] =(parseFloat(arr2['ERP_INV_CNT'])).toFixed(2);
            arr2['ERP_PO_SPEND'] =(parseFloat(arr2['ERP_PO_SPEND'])).toFixed(2);  
            arr2['AGGR_INV_SPEND'] =(parseFloat(arr2['AGGR_INV_SPEND'])).toFixed(2);
            arr2['ERP_INV_SPEND_IN_STD_CURR'] =(parseFloat(arr2['ERP_INV_SPEND_IN_STD_CURR'])).toFixed(2);  
            arr2['AGGR_PO_SPEND'] =(parseFloat(arr2['AGGR_PO_SPEND'])).toFixed(2);      
            return arr2;
        }
    }

    getHeaderGroups(columnDefs) {
        let headerToColumn = {}
        columnDefs.forEach(item => {
            if (item.headerGroup in headerToColumn) {
                let columns = headerToColumn[item.headerGroup]
                columns.push({ name: item.name, width: item.width })
            } else {
                if (item.headerGroup === null) {
                    headerToColumn[item.name] = { name: null, width: item.width }
                } else {
                    headerToColumn[item.headerGroup] = [{ name: item.name, width: item.width }]
                }
            }
        })

        return headerToColumn
    }


    onColumnSelection(param) {
        if ((Array.isArray(param) && param.length > 0) || !isNaN(parseInt(param))) {
        }
        else if (param === "DEFAULT") {
            param = [];
            let columns = this.state.columnSelect;
            columns.forEach(column => {
                if (column.is_default == 'Yes') {
                    param.push(column.id);
                }
            });
        }
        else if (param === "InternalEnablementInsightView") {
            param = [];
            let columns = this.state.columnSelect;
            columns.forEach(column => {
                if (column.internalEnablementInsightView == 'Yes') {
                    param.push(column.id);
                }
            });
        }

        //if ((Array.isArray(param) && param.length > 0) || !isNaN(parseInt(param))) {
            this.props.addSelectedColumnForScc(this.props.spendDetails.currentActiveRequest.DMC_ID, this.props.spendDetails.currentActiveRequest.VS_ID, this.props.user.user.Id, param).then((response) => {
                let selectedCols = response.data.Result;
                let columns = this.state.colConfig;

                let columnSelect = this.state.columnSelect;
                let columnDefs = [];
                columns.map((column) => {
                    if (selectedCols.indexOf(column.key) > -1) {
                        columnDefs.push(column);
                    }
                })
                // this.originalColumns = cloneDeep(columns);
                columnSelect.map((column) => {
                    if (selectedCols.indexOf(column.id) > -1) {
                        column.isSelected = true;
                    } else if (column.id == 'DATA_COLLECTIONS_SKEY') {
                        column.isSelected = true;
                    } else {
                        column.isSelected = false;
                    }
                })

                columnDefs.sort((a, b) => { return a.screenSeq - b.screenSeq });
                const headerToColumn = this.getHeaderGroups(columnDefs);
                this.setState({
                    headerToColumn: headerToColumn, columns: columnDefs.length === 0 ? [] : columnDefs.sort((a, b) => { return a.screenSeq - b.screenSeq }), columnSelect: columnSelect
                })
            });
        //}       
    }

    componentDidMount() {
        let sccColumns = null;
        this.props.getSCCMasterColumnsConfiguration()
            .then((response) => {
                sccColumns = response.data.Result;

                //By default scc tab should always export all columns
                let sccExcelColumns = []
                sccColumns.map(key => {
                    let temp = []
                    temp['key'] = key['field'];
                    temp['label'] = key['screen_field_name'];
                    temp['seq'] = key['screen_seq'];
                    temp['isCellEditable'] = key['is_editable'];

                    sccExcelColumns.push(temp)
                })

                let keys = []
                sccColumns.map(key => {
                    // let temp = { key: key['field'], label: key['excel_col_name'] }
                    // keys.push(temp)
                    keys[key['excel_col_name']] = key['field']
                })

                let columnSelect = []
                sccColumns.map(key => {
                    let temp = []
                    temp['id'] = key['field'];
                    temp['label'] = key['screen_field_name'];
                    temp['seq'] = key['screen_seq'];
                    temp['columnCategory'] = key['columnCategory'];
                    temp['isSelected'] = true;
                    temp['internalEnablementInsightView'] = key['internalEnablementInsightView'];
                    temp['is_default'] = key['is_default'];
                    columnSelect.push(temp)
                })
                //maintain a headers array to check for edits made to excel headers by user
                let headers = []
                sccColumns.map(key => {
                    headers.push(key['excel_col_name'])
                });
                this.setState({ keys: keys, columnSelect: columnSelect, sccExcelColumns: sccExcelColumns, headers: headers })
            })

        this.loadData();
    }

    loadData() {
        let dmcId = this.props.spendDetails.currentActiveRequest.DMC_ID;
        let vsId = this.props.spendDetails.currentActiveRequest.VS_ID;
        let user = this.props.user.user.Email;
        this.props.defineScopeLoading(true)
        this.props.getSccLoadData(dmcId, vsId, user)
            .then((response) => {
                if (response.data.Result.length > 0) {
                    this.setState({ data: response.data.Result }, this.initializeColumns)
                } else {
                    this.props.defineScopeLoading(false)
                    this.setState({ responseMsg: 'No result found. Please try reimporting the file.', isError: 'Yes', isLoading: false })
                }

            }).catch((error) => {
                if (error.stack.includes('Network Error')) {
                    this.props.defineScopeLoading(false)
                    this.setState({ responseMsg: 'API service is down. Please contact administrator.', isError: 'Yes', isLoading: false })
                }
                else {
                    this.props.defineScopeLoading(false)
                    this.setState({ responseMsg: this.props.content.error, isError: 'Yes', isLoading: false })
                }
            })
    }

    handleChange(e) {
        const files = e.target.files;
        if (files && files[0]) this.setState({ file: files[0], uploadButtonClicked: true });
    };

    componentDidUpdate(prevProps, prevState) {
        let file = this.state.file;
        if (prevState.file !== file && file !== undefined) this.handleFile(this.state.file)
    }

    saveData(data) {
        this.setState({ responseMsg: '' })
        let rowData = data.length > 0 ? data : this.state.data;
        const recordsToInsert = [];
        rowData.forEach((item) => {
            const record = item;
            record.VS_ID = this.props.spendDetails.currentActiveRequest.VS_ID;
            record.DMC_ID = this.props.spendDetails.currentActiveRequest.DMC_ID;
            record.USER_ID = this.props.user.user.Email;
            recordsToInsert.push(record);
        })
        this.setState({ isLoading: true, isSaveInProgress: true })
        this.props.defineScopeLoading(true)
        //call hana procedure for save here
        this.props.uploadSccTabData(recordsToInsert)
            .then((response) => {
                this.setState({ responseMsg: 'Record/s has been updated successfully', isError: 'No', isLoading: false, isSaveInProgress: false })
                this.props.defineScopeLoading(false)
            }).catch((error) => {
                if (error.stack.includes('Network Error')) {
                    this.props.defineScopeLoading(false)
                    this.setState({ responseMsg: 'API service is down. Please contact administrator.', isError: 'Yes', isLoading: false, isSaveInProgress: false })
                }
                else {
                    this.props.defineScopeLoading(false)
                    this.setState({ responseMsg: this.props.content.error, isError: 'Yes', isLoading: false, isSaveInProgress: false })
                }
            })
    }

    handleFile(file) {
        this.setState({ isLoading: true, responseMsg: '' })
        /* Boilerplate to set up FileReader */
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;

        reader.onload = (e) => {
            /* Parse data */
            const bstr = e.target.result;
            // console.log('csv data ', bstr)
            const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA: true });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_json(ws, { defval: "" });
            let temp = cloneDeep(data)
            //check if uploded file has same headers or not
            let isSame = this.checkForErrors(temp)

            if (isSame) {
                let keys = this.state.keys;
                let dummyData = changeKeyObjects(data, keys)

                if (this.state.uploadButtonClicked) this.saveData(dummyData)
                /* Update state */
                this.setState({ data: dummyData, fileLoaded: true, cols: make_cols(ws['!ref']) }, () => {
                    this.initializeColumns()
                });
            }

        };

        if (rABS) {
            reader.readAsBinaryString(file);
        } else {
            reader.readAsArrayBuffer(file);
        };
    }

    checkForErrors(data) {
        let uploadedHeaders = Object.keys(data[0]);
        let excelHeaders = this.state.headers;
        let flag = _.xor(uploadedHeaders, excelHeaders)
        //here we are checking for length 6 because there are 3 headers with special symbol 'ˏ' so _xor creates an array of length 6
        if (flag.length == 0 || flag.length == 6) {
            return true;
        } else {
            this.setState({ isError: 'Yes', isLoading: false, responseMsg: this.props.sccTabJsonFile.headerEditError })
            return false;
        }
    }

    initializeColumns() {
        let columns = []
        let _self = this
        let keys = Object.keys(this.state.data[0]).slice()
        this.props.getSCCMasterColumnsConfiguration()
            .then((response) => {
                let sccColumns = response.data.Result;
                const data = []
                this.state.data.forEach(datum => {
                    const row = {}
                    keys.forEach(key => {
                        row[key] = datum[key]
                    })

                    data.push(row)
                })

                for (let index = 0; index < sccColumns.length; index++) {
                    const fieldConfig = sccColumns[index];

                    let tableColumn = {
                        name: fieldConfig.screen_field_name,
                        key: fieldConfig.field,
                        width: 150,
                        sortable: true,
                        editable: fieldConfig.is_editable === 'Yes',
                        resizable: true,
                        filterable: true,
                        screenSeq: fieldConfig.screen_seq,
                        mandatory: false,
                        exportFl: fieldConfig.export_fl === 'Yes',
                        helpText: fieldConfig.screen_help_text,
                        filterRenderer: TextfilterRenderer,
                        headerGroup: fieldConfig.columnCategory,
                        internalEnablementInsightView: fieldConfig.internalEnablementInsightView,
                        is_default: fieldConfig.is_default,
                    }

                    if (fieldConfig.field == 'SAP_SUPP_ENABLEMENT_RECMD') {
                        tableColumn["editor"] = dropDownEnablementRmdEditor
                    }

                    if (fieldConfig.field == 'ENABLEMENT_WAVE') {
                        tableColumn.editProperty = 'Yes'
                        //tableColumn.editor = DivEditor
                        tableColumn.events = {
                            onDoubleClick: function (ev, args) {
                                _self.setState({ openEnablementInsightPopUp: true, enablementWaveInputRowNo: args.rowIdx, enablementWaveInputValue: ev.target.innerText })
                            }
                        }
                    }

                    columns.push(tableColumn)
                }

                this.filterByColumnSelection(columns)
                let summationArray = this.sum(data, columns);
                this.setState({ data: data , filteredSummationArray : summationArray})

            }).catch((error) => {
                if (error.stack.includes('Network Error')) {
                    this.props.defineScopeLoading(false)
                    this.setState({ responseMsg: 'API service is down. Please contact administrator.', isError: 'Yes', isLoading: false })
                }
                else {
                    this.props.defineScopeLoading(false)
                    this.setState({ responseMsg: this.props.content.error, isError: 'Yes', isLoading: false })
                }
            })

        // if (this.state.fileLoaded) {
        //     let dummyKey = Object.keys(this.state.data[0]).slice()
        //     dummyKey.forEach(datum => {
        //         let row = sccColumns.filter(val => val.screen_field_name === datum)
        //         keys.push(row[0].field)
        //     })
        // } else {
        //     keys = Object.keys(this.state.data[0]).slice()
        // }

        // console.log("file data:", this.state.data)
    }

    outsideEnablementInsightPopupClick() {
        this.setState({ openEnablementInsightPopUp: false })
    }

    closeEnablementInsightPopUp() {
        this.setState({ openEnablementInsightPopUp: !this.state.openEnablementInsightPopUp })
    }

    enablementWaveInput() {
        if (this.props.enablementWaveInputDetails !== undefined && this.props.enablementWaveInputDetails !== null) {
            this.customGridRef.onGridRowsUpdated({ fromRow: this.state.enablementWaveInputRowNo, toRow: this.state.enablementWaveInputRowNo, updated: { 'ENABLEMENT_WAVE': this.props.enablementWaveInputDetails == '' || this.props.enablementWaveInputDetails == 0 ? 1 : this.props.enablementWaveInputDetails } })
            this.props.selectedEnablementWaveDetails(null)//Clear the previous selection
            this.setState({ openEnablementInsightPopUp: !this.state.openEnablementInsightPopUp })
        }
    }

    clearEnablementWaveOverride() {
        this.customGridRef.onGridRowsUpdated({ fromRow: this.state.enablementWaveInputRowNo, toRow: this.state.enablementWaveInputRowNo, updated: { 'ENABLEMENT_WAVE': '' } })
        this.setState({ openEnablementInsightPopUp: !this.state.openEnablementInsightPopUp })
    }

    render() {
        return (
            <React.Fragment>
                <div className="grid-x grid-padding-x">
                    <div className="medium-12">
                        {(this.state.isLoading) ?
                            <img style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', paddingTop: '5px' }} src={path + `images/ajax-loader.gif`} alt="" />
                            : null
                        }
                        {(this.state.isError === 'Yes') ?
                            <div style={{ color: 'red', textAlign: 'center' }} className="cell pt5">{this.state.responseMsg}</div>
                            : <div style={{ color: 'green', textAlign: 'center' }} className="cell pt5">{this.state.responseMsg}</div>
                        }
                    </div>
                    <div className="medium-12">
                        <div className="tab-title">{this.props.sccTabJsonFile.subTitle}</div>
                        <div className="tab-description width-auto">{this.props.sccTabJsonFile.subHeader}</div>
                    </div>
                </div>
                <div className="grid-x">
                    <CustomGridSccE
                        onRef={ref => (this.customGridRef = ref)}
                        columns={this.state.columns}
                        columnSelect={this.state.columnSelect}
                        sccExcelColumns={this.state.sccExcelColumns}
                        headerToColumn = {this.state.headerToColumn}
                        filteredSummationArray = {this.state.filteredSummationArray}
                        data={this.state.data}
                        keyColumn={"REF_NUM"}
                        minHeight={500}
                        exportFromScc={true}
                        scc={true}
                        exportFileName='scc'
                        currentFilter={true}
                        handleChange={this.handleChange}
                        headerRowHeight={70}
                        onSave={this.saveData}
                        onColumnSelection={this.onColumnSelection}
                    />
                    <div className="medium-1 large-1">
                        <PopupE className="override-large-popup2"
                            position="right center"
                            open={this.state.openEnablementInsightPopUp}
                            onClose={this.outsideEnablementInsightPopupClick}
                            closeOnDocumentClick
                        >
                            <div>
                                <EnablementWavePopUp
                                    enablementWaveInputValue={this.state.enablementWaveInputValue}
                                    close={this.closeEnablementInsightPopUp}
                                />
                            </div>
                            <div className="popupOkBtn">
                                <Button onClick={this.enablementWaveInput} float type="submit" content={this.props.ui.ok} />
                                <Button onClick={this.clearEnablementWaveOverride} type="submit" content={this.props.ui.clearOverride} />
                            </div>
                        </PopupE>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        ui: state.content.ui,
        content: state.content,
        sccTabJsonFile: state.content.sccTab,
        spendDetails: state.spendDetails,
        pendingChanges: state.detailedFileChanges.changes,
        userData: state.valueStory,
        user: state.default,
        ganntTexts: state.content.ganntChartTab,
        enablementInsightTexts: state.content.enablementInsight,
        enablementWaveInputDetails: state.spendDetails.enablementWaveInputDetails,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        valueStoryCurrentMenu: (menuItem) => dispatch(valueStoryCurrentMenu(menuItem)),
        showValueStoryMenu: (menuItem) => dispatch(showValueStoryMenu(menuItem)),
        UpdateComment: requestDetails => dispatch(UpdateComment(requestDetails)),
        setValueStoryDmciDetails: (data) => dispatch(setValueStoryDmciDetails(data)),
        setResultSummaryDetails: (data) => dispatch(setResultSummaryDetails(data)),
        setTopDetails: (data) => dispatch(setTopDetails(data)),
        resetError: error => dispatch(resetError(error)),
        clearCurrentActiveRequest: () => dispatch(clearCurrentActiveRequest()),
        getCapIQDataBlank: bool => dispatch(getCapIQDataBlank(bool)),
        getSccLoadData: (DMCId, VSId, userEmail) => dispatch(getSccLoadData(DMCId, VSId, userEmail)),
        defineScopeLoading: bool => dispatch(defineScopeLoading(bool)),
        getSCCMasterColumnsConfiguration: () => dispatch(getSCCMasterColumnsConfiguration()),
        uploadSccTabData: (data) => dispatch(uploadSccTabData(data)),
        addSelectedColumnForScc: (DMCId, VSId, userId, Columns) => dispatch(addSelectedColumnForScc(DMCId, VSId, userId, Columns)),
        getSccSelectedColumn: (DMCId, VSId, userId) => dispatch(getSccSelectedColumn(DMCId, VSId, userId)),
        selectedEnablementWaveDetails: message => dispatch(selectedEnablementWaveDetails(message)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SccComponent)