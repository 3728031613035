import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

// Components
import CalculateROITableHeaders from './CalculateROITableHeaders'
import CalculateROITableRows from './CalculateROITableRows'
import CalculateROITableFooter from './CalculateROITableFooter'

// Actions
import { updateUserInput } from 'actions/calculateROIActions'
import { updateTotalBenefit } from 'actions/calculateROIActions'

// Functions
import * as ROICalculations from './CalculateROICalculations'

// UI
import Modal from 'reboron/FadeModal'
import { Line } from 'react-chartjs-2'
import Button from 'UI/Button'
import { colors, modalStyle, modalContentStyle } from 'Constants'

// Utils
import classnames from 'classnames'
import { BreakLine, round, formatThousands } from 'utils/Utils'

// Settings
const resultsModalStyle = Object.assign({}, modalStyle)
resultsModalStyle.width = '100%'
resultsModalStyle.maxWidth = '100%'
resultsModalStyle.height = '280px'
resultsModalStyle.top = 'auto'
resultsModalStyle.bottom = '0px'
resultsModalStyle.transform = 'translate3d(-50%, 0%, 0px)'
//resultsModalStyle.backgroundColor = 'white'

const resultsModalContentStyle = Object.assign({}, modalContentStyle)
resultsModalContentStyle.maxWidth = '100%'
resultsModalContentStyle.padding = '20px 30px'
resultsModalContentStyle.margin = '0 auto'
resultsModalContentStyle.overflow = 'hidden'

let dataSetOpts = {
	fill: true,
	lineTension: 0.1,
	borderCapStyle: 'butt',
	borderDash: [],
	borderDashOffset: 0.0,
	borderJoinStyle: 'miter',
	pointBackgroundColor: '#fff',
	pointBorderWidth: 1,
	pointHoverRadius: 5,
	pointHoverBorderColor: 'rgba(220,220,220,1)',
	pointHoverBorderWidth: 2,
	pointRadius: 1,
	pointHitRadius: 10
}

let benefitsDataSetOpts = Object.assign({}, dataSetOpts),
	benefitsDataSetColor = 'rgba(' + colors.rgb.orangeColor + ',1)'
benefitsDataSetOpts.backgroundColor = benefitsDataSetColor
benefitsDataSetOpts.borderColor = benefitsDataSetColor
benefitsDataSetOpts.pointBorderColor = benefitsDataSetColor
benefitsDataSetOpts.pointHoverBackgroundColor = benefitsDataSetColor

let investmentsDataSetOpts = Object.assign({}, dataSetOpts),
	investmentsDataSetColor = 'rgba(' + colors.rgb.accentColor + ',1)'
investmentsDataSetOpts.backgroundColor = investmentsDataSetColor
investmentsDataSetOpts.borderColor = investmentsDataSetColor
investmentsDataSetOpts.pointBorderColor = investmentsDataSetColor
investmentsDataSetOpts.pointHoverBackgroundColor = investmentsDataSetColor

const legendOpts = {
	display: true,
	position: 'bottom',
	fullWidth: true,
	reverse: false
}

class CalculateROIContainer extends PureComponent {

	constructor(props) {
		super(props)

		// Chart Copy
		benefitsDataSetOpts.label = this.props.content.ROIResults.benefit
		investmentsDataSetOpts.label = this.props.content.ROIResults.investment

		this.state = {
			render: false,
			backdropActive: false,
			chartData: {
				datasets: [investmentsDataSetOpts, benefitsDataSetOpts]
			}
		}

		this.handleInputChange = this.handleInputChange.bind(this)
		this.handleResultsModal = this.handleResultsModal.bind(this)
		this.updateModalResults = this.updateModalResults.bind(this)
		this.hideModal = this.hideModal.bind(this)
		this.getTotalAnnualBenefits = this.getTotalAnnualBenefits.bind(this)
		this.getBenefitRealizationFactors = this.getBenefitRealizationFactors.bind(this)
	}

	getChartData() {
		benefitsDataSetOpts.data = ROICalculations.getTotalBenefits(this.props.userData[4].UserInput[0])
		investmentsDataSetOpts.data = ROICalculations.getTotalInvestments(this.props.userData)

		const chartData = {
			datasets: [investmentsDataSetOpts, benefitsDataSetOpts],
			labels: this.props.content.table.tableHeaders
		}

		this.setState({
			chartData: chartData
		})
	}

	handleInputChange(value, i, year) {
		if (value === '') value = null

		// Update to Redux
		this.props.updateUserInput(value, i, 'Year' + year)

		setTimeout(() => {
			this.setState({ render: false })
			ROICalculations.setTotalInvestments(this.props.userData)
			ROICalculations.setNetBenefits()
			this.updateModalResults()
			this.setState({ render: true })
		}, 1)
	}

	handleResultsModal() {
		this.setState({
			backdropActive: true
		})
		this.updateModalResults()
		this.getChartData()
		this.refs.results.show()
	}

	updateModalResults() {
		ROICalculations.setNPV()
		ROICalculations.setROI(this.props.userData)
		ROICalculations.setCostPerMonth()
		ROICalculations.setPaybackPeriod(this.props.userData)
	}

	hideModal() {
		this.setState({
			backdropActive: false
		})
		this.refs.results.hide()
	}

	shouldComponentUpdate(nextProps, nextState) {
		return nextState.render ? true : false
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.content !== nextProps.content) {
			// Update Chart Copy
			benefitsDataSetOpts.label = nextProps.content.ROIResults.benefit
			investmentsDataSetOpts.label = nextProps.content.ROIResults.investment

			this.setState({
				chartData: {
					datasets: [investmentsDataSetOpts, benefitsDataSetOpts]
				}
			})
		}
	}

	componentWillMount() {
		window.scrollTo(0, 0)
		this.getTotalAnnualBenefits();
	}

	componentDidMount() {
		setTimeout(() => {
			ROICalculations.setTotalInvestments(this.props.userData)
			ROICalculations.setNetBenefits()
			this.updateModalResults()
			this.setState({ render: true })
		}, 800)
	}

	getBenefitRealizationFactors() {
		let benefitRealizationFactors = []

		for (let i = 0; i < 5; i++) {
			benefitRealizationFactors.push(this.props.businessValueFromSAPAribaData['Year' + (i + 1)])
		}

		return benefitRealizationFactors
	}

	getEstimatedAnnualValues() {
		let estimatedAnnualValues = []

		this.props.valueStory.BusinessValueToYou[0].ValueDriver.forEach((data) =>
			data.SubLever.forEach((data) =>
				// return Est Annual Value if Sub Lever is selected
				data.Selected ? estimatedAnnualValues.push(round(data.EstAnnualValue, 2)) : 0
			))

		return estimatedAnnualValues
	}

	getTotalAnnualBenefits() {
		let totalAnnualBenefits = [],
			estimatedAnnualValues = this.getEstimatedAnnualValues(),
			benefitRealizationFactors = this.getBenefitRealizationFactors()

		benefitRealizationFactors.forEach((percentage, i) => {
			let totalAnnualBenefit = 0

			estimatedAnnualValues.forEach((value) => {
				totalAnnualBenefit += value
			})

			totalAnnualBenefit = totalAnnualBenefit * percentage / 100
			totalAnnualBenefits.push(totalAnnualBenefit)

			// Update Total Benefit in Redux
			this.props.updateTotalBenefit(totalAnnualBenefit, 'Year' + (i + 1))
		})

		return totalAnnualBenefits
	}

	render() {
		return (
			<div className="calculate-roi">
				<div className={classnames('backdrop', { 'backdrop--active': this.state.backdropActive })} onClick={() => this.hideModal()}></div>
				<div className="calculate-roi__table grid-x">
					<div className="medium-12 business-value-cont">
						<div className="tab-title">{this.props.content.title}</div>
						<div className="tab-description">{this.props.content.description}</div>
						<table className="mt20">
							<CalculateROITableHeaders content={this.props.content.table.tableHeaders} />
							<CalculateROITableRows
								content={this.props.content.table.rowHeaders}
								userData={this.props.userData}
								currency={this.props.currency}
								handleInputChange={this.handleInputChange}
							/>
							<CalculateROITableFooter
								content={this.props.content.table.rowFooters}
								userData={this.props.userData}
								currency={this.props.currency}
								totalInvestments={this.props.userData[7].UserInput[0]}
								totalBenefits={this.props.userData[4].UserInput[0]}
								netBenefits={this.props.userData[5].UserInput[0]}
								cumulativeBenefits={this.props.userData[6].UserInput[0]}
							/>
						</table>
					</div>
					<div className="medium-12 pb10">
						<Button
							onClick={this.handleResultsModal}
							content={this.props.content.calculate}
							color
							float
						/>
					</div>
				</div>
				<Modal
					ref="results"
					backdrop={false}
					modalStyle={resultsModalStyle}
					contentStyle={resultsModalContentStyle}
				>
					<div className="calculate-roi__results grid-x">
						<div className="medium-5 cell">
							<h2>{this.props.content.ROIResults.title}</h2>
							<Line
								data={this.state.chartData}
								legend={legendOpts}
								height={120}
							/>
						</div>
						<div className="roi-quadrant medium-6 medium-offset-1 cell">
							<div className="grid-x grid-margin-x small-up-2 medium-up-2 large-up-2">
								<div class="cell">
									<div className="roi-quadrant__label">
										{this.props.content.ROIResults.npv}
									</div>
									<div className="roi-quadrant__value">
										{this.props.currency} {formatThousands(round(this.props.NPV, 2))} M
									</div>
								</div>
								<div class="cell">
									<div className="roi-quadrant__label">
										{this.props.content.ROIResults.ROI}
									</div>
									<div className="roi-quadrant__value">
										{formatThousands(round(this.props.ROI, 2))} %
									</div>
								</div>
								<div class="cell">
									<div className="roi-quadrant__label">
										{BreakLine(this.props.content.ROIResults.value)}
									</div>
									<div className="roi-quadrant__value">
										{this.props.currency} {formatThousands(round(this.props.valueOfDelayedInvestment, 2))} M
									</div>
								</div>
								<div class="cell">
									<div className="roi-quadrant__label">
										{this.props.content.ROIResults.payback}
									</div>
									<div className="roi-quadrant__value">
										{this.props.paybackPeriod} {this.props.content.ROIResults.months}
									</div>
								</div>
							</div>
						</div>
					</div>
					<button onClick={() => this.hideModal('results')} className="close" />
				</Modal>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		ui: state.content.ui,
		content: state.content.calculateROI,
		userData: state.valueStory.BusinessValueFromSap[0].ROI,
		WACC: state.valueStory.BusinessValueToYou[0].ValueDriver[3].SubLever[1].UserInput[4].UserInput,
		NPV: state.valueStory.BusinessValueFromSap[0].NPV,
		ROI: state.valueStory.BusinessValueFromSap[0].ROIValue,
		valueOfDelayedInvestment: state.valueStory.BusinessValueFromSap[0].ValueOfDelayedInvestment,
		paybackPeriod: state.valueStory.BusinessValueFromSap[0].Payback,
		currency: decodeURIComponent(state.valueStory.Currency),
		businessValueFromSAPAribaData: state.valueStory.BusinessValueFromSap[0],
		valueStory: state.valueStory
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		updateTotalBenefit: (value, year) => dispatch(updateTotalBenefit(value, year)), 
		updateUserInput: (value, row, year) => dispatch(updateUserInput(value, row, year)) 
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CalculateROIContainer)
