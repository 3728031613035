import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
// Utils
import {
    sortObjectArray, round, formatThousands, convertToMillion
} from 'utils/Utils'
// UI
import Button from 'UI/Button'
import Modal from 'reboron/OutlineModal'
import PopupButton from 'UI/PopupButton'
// Settings
import { validators } from 'Constants'
import Select from 'react-select';
import SupplierDrillDownPopup from '../SupplierDrillDownPopup'
import SupplierIndustriesPopup from '../SupplierIndustriesPopup'
import { dropdownStyles } from 'react-select-css'
import {
    suggestedValueModalStyle,
    suggestedValueContentStyle,
    path, backdropStyle, changeModalStyle, changeModalContentStyle
} from 'Constants'
import {
    topDetailsSuccess,
    topDetailsFailure,
    topDetailsLoading,
    setTopDetails,
    resetError,
    getTopDetails,
    storeSupplierSKEY,
    storeIndustryGICS_ID,
    trackSupplierDrillDownChanges,
    trackSupplierDrillDownSave,
    trackIndustryDrillScopeChanges
} from 'actions/spendActions'

import VideoHelp from 'components/UI/VideoHelp'

//import {Dropdown} from 'react-bootstrap'

const changeBackdropStyle = Object.assign({}, backdropStyle);
changeBackdropStyle.zIndex = '1050';

const alertModalStyle = Object.assign({}, changeModalStyle);
alertModalStyle.zIndex = '1060';

class TopDetailsContainer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            topDetails: '',
            topDetailsData: '',
            dimension: '',
            dimensionOption: [],
            total5Industry: '',
            total25Classified: '',
            total25UnClassified: '',
            currencySymbol: '',
            open: false,
            openIndustryPopup: false
        }
        this.showSupplierDrilldownModal = this.showSupplierDrilldownModal.bind(this)
        this.setVisibility = this.setVisibility.bind(this)
        this.showAlertModal = this.showAlertModal.bind(this)
        this.hideAlertModal = this.hideAlertModal.bind(this)
        this.continueNavigation = this.continueNavigation.bind(this)
        this.outsidePopupClick = this.outsidePopupClick.bind(this)
        this.showSupplierIndustriesModal = this.showSupplierIndustriesModal.bind(this)
    }

    componentDidMount() {
        this.loadData();
        window.scrollTo(0, 0);
    }

    componentWillUnmount() {
        this.props.resetError();
    }

    loadData() {

        var array = [
            { label: 'Total invoice spend', value: 'Total invoice spend' },
            { label: 'Total invoice count', value: 'Total invoice count' },
            { label: 'Total purchase order spend', value: 'Total purchase order spend' },
            { label: 'Total purchase order count', value: 'Total purchase order count' },
        ]

        this.setState({
            dimensionOption: array, currencySymbol: this.props.spendDetails.currentActiveRequest !== undefined ?
                this.props.currencySymbols[this.props.spendDetails.currentActiveRequest.Currency.trim()] : ''
        })
        if (this.props.spendDetails.currentActiveRequest !== undefined && this.props.spendDetails.currentActiveRequest !== '') {
            this.getTopDetailsData();
        }


    }

    getMessage(keyToFindMessage) {
        return this.props.content[keyToFindMessage];
    }

    getTopDetailsData() {
        //hardcoded id's for testing purpose. need to replace it with actual data
        //if (this.props.spendDetails.resultTopDetails.length === 0) {
        this.props.topDetailsLoading(true)
        var userEmail = this.props.spendDetails.currentActiveRequest !== undefined &&
            this.props.spendDetails.currentActiveRequest.SAPRequester === this.props.user.user.Email ? this.props.user.user.Email : this.props.spendDetails.currentActiveRequest.SAPRequester;
        this.props.getTopDetails(this.props.spendDetails.currentActiveRequest.DMC_ID, this.props.spendDetails.currentActiveRequest.VS_ID, userEmail)
            .then((response) => {
                this.setState({ topDetailsData: response.data.Result, dimension: 'Total invoice spend' });
                this.filterTopDetailsData(response.data.Result, 'Total invoice spend')
                this.props.topDetailsSuccess();
                this.props.setTopDetails(response.data.Result);

            })
            .catch((response) => {
                this.props.topDetailsFailure(this.getMessage("notFound"));
                this.props.topDetailsLoading(false)
            })
        // } else {
        //     this.setState({ topDetailsData: this.props.spendDetails.resultTopDetails, dimension: 'Total invoice spend' });
        //     this.filterTopDetailsData(this.props.spendDetails.resultTopDetails, 'Total invoice spend')
        // }
    }

    handleDimensionChange(value) {
        // To do fetch data on the of selected dimension
        this.setState({ dimension: value.label });
        if (this.state.topDetailsData !== undefined && this.state.topDetailsData !== '') {
            this.filterTopDetailsData(this.state.topDetailsData, value.label)
        }
    }

    filterTopDetailsData(detailsData, dimensionType) {
        var requiredData = [];

        switch (dimensionType.toLowerCase()) {
            case '':
            case 'total invoice spend':
                var top5 = detailsData.filter(a => a.KPI.toLowerCase() === this.props.content.kpi[0].toLowerCase())
                var topValues = top5.sort((a, b) => a.RANK_INVOICE_SPEND - b.RANK_INVOICE_SPEND).slice(0, 5);
                var sum5Industry = 0;
                for (let index = 0; index < topValues.length; index++) {
                    sum5Industry = sum5Industry + topValues[index].INVOICE_SPEND;
                }
                requiredData.push(topValues.sort(sortObjectArray('RANK_INVOICE_SPEND', 'asc')));

                var top25Classified = detailsData.filter(a => a.KPI.toLowerCase() === this.props.content.kpi[1].toLowerCase())
                var top25Values = top25Classified.sort((a, b) => a.RANK_INVOICE_SPEND - b.RANK_INVOICE_SPEND).slice(0, 25);
                var sum25Supplier = 0;
                for (let index = 0; index < top25Values.length; index++) {
                    sum25Supplier = sum25Supplier + top25Values[index].INVOICE_SPEND;
                }
                requiredData.push(top25Values.sort(sortObjectArray('RANK_INVOICE_SPEND', 'asc')));

                var top25UnClassified = detailsData.filter(a => a.KPI.toLowerCase() === this.props.content.kpi[2].toLowerCase())
                var top25UnCValues = top25UnClassified.sort((a, b) => a.RANK_INVOICE_SPEND - b.RANK_INVOICE_SPEND).slice(0, 25);
                var sum25UnCSupplier = 0;
                for (let index = 0; index < top25UnCValues.length; index++) {
                    sum25UnCSupplier = sum25UnCSupplier + top25UnCValues[index].INVOICE_SPEND;
                }
                requiredData.push(top25UnCValues.sort(sortObjectArray('RANK_INVOICE_SPEND', 'asc')));

                this.setState({ total5Industry: convertToMillion(sum5Industry), total25Classified: convertToMillion(sum25Supplier), total25UnClassified: convertToMillion(sum25UnCSupplier) })
                break

            case 'total invoice count':
                var top5 = detailsData.filter(a => a.KPI.toLowerCase() === this.props.content.kpi[0].toLowerCase())
                var topValues = top5.sort((a, b) => a.RANK_INVOICE_COUNT - b.RANK_INVOICE_COUNT).slice(0, 5);
                var sum5Industry = 0;
                for (let index = 0; index < topValues.length; index++) {
                    sum5Industry = sum5Industry + topValues[index].INVOICE_COUNT;
                }
                requiredData.push(topValues.sort(sortObjectArray('RANK_INVOICE_COUNT', 'asc')));

                var top25Classified = detailsData.filter(a => a.KPI.toLowerCase() === this.props.content.kpi[1].toLowerCase())
                var top25Values = top25Classified.sort((a, b) => a.RANK_INVOICE_COUNT - b.RANK_INVOICE_COUNT).slice(0, 25);
                var sum25Supplier = 0;
                for (let index = 0; index < top25Values.length; index++) {
                    sum25Supplier = sum25Supplier + top25Values[index].INVOICE_COUNT;
                }
                requiredData.push(top25Values.sort(sortObjectArray('RANK_INVOICE_COUNT', 'asc')));

                var top25UnClassified = detailsData.filter(a => a.KPI.toLowerCase() === this.props.content.kpi[2].toLowerCase())
                var top25UnCValues = top25UnClassified.sort((a, b) => a.RANK_INVOICE_COUNT - b.RANK_INVOICE_COUNT).slice(0, 25);
                var sum25UnCSupplier = 0;
                for (let index = 0; index < top25UnCValues.length; index++) {
                    sum25UnCSupplier = sum25UnCSupplier + top25UnCValues[index].INVOICE_COUNT;
                }
                requiredData.push(top25UnCValues.sort(sortObjectArray('RANK_INVOICE_COUNT', 'asc')));

                this.setState({ total5Industry: round(sum5Industry, 2), total25Classified: round(sum25Supplier, 2), total25UnClassified: round(sum25UnCSupplier, 2) })
                break

            case 'total purchase order spend':
                var top5 = detailsData.filter(a => a.KPI.toLowerCase() === this.props.content.kpi[0].toLowerCase())
                var topValues = top5.sort((a, b) => a.RANK_PO_SPEND - b.RANK_PO_SPEND).slice(0, 5);
                var sum5Industry = 0;
                for (let index = 0; index < topValues.length; index++) {
                    sum5Industry = sum5Industry + topValues[index].PO_SPEND;
                }
                requiredData.push(topValues.sort(sortObjectArray('RANK_PO_SPEND', 'asc')));

                var top25Classified = detailsData.filter(a => a.KPI.toLowerCase() === this.props.content.kpi[1].toLowerCase())
                var top25Values = top25Classified.sort((a, b) => a.RANK_PO_SPEND - b.RANK_PO_SPEND).slice(0, 25);
                var sum25Supplier = 0;
                for (let index = 0; index < top25Values.length; index++) {
                    sum25Supplier = sum25Supplier + top25Values[index].PO_SPEND;
                }
                requiredData.push(top25Values.sort(sortObjectArray('RANK_PO_SPEND', 'asc')));

                var top25UnClassified = detailsData.filter(a => a.KPI.toLowerCase() === this.props.content.kpi[2].toLowerCase())
                var top25UnCValues = top25UnClassified.sort((a, b) => a.RANK_PO_SPEND - b.RANK_PO_SPEND).slice(0, 25);
                var sum25UnCSupplier = 0;
                for (let index = 0; index < top25UnCValues.length; index++) {
                    sum25UnCSupplier = sum25UnCSupplier + top25UnCValues[index].PO_SPEND;
                }
                requiredData.push(top25UnCValues.sort(sortObjectArray('RANK_PO_SPEND', 'asc')));
                this.setState({ total5Industry: convertToMillion(sum5Industry), total25Classified: convertToMillion(sum25Supplier), total25UnClassified: convertToMillion(sum25UnCSupplier) })
                break

            case 'total purchase order count':
                var top5 = detailsData.filter(a => a.KPI.toLowerCase() === this.props.content.kpi[0].toLowerCase())
                var topValues = top5.sort((a, b) => a.RANK_PO_COUNT - b.RANK_PO_COUNT).slice(0, 5);
                var sum5Industry = 0;
                for (let index = 0; index < topValues.length; index++) {
                    sum5Industry = sum5Industry + topValues[index].PO_COUNT;
                }
                requiredData.push(topValues.sort(sortObjectArray('RANK_PO_COUNT', 'asc')));

                var top25Classified = detailsData.filter(a => a.KPI.toLowerCase() === this.props.content.kpi[1].toLowerCase())
                var top25Values = top25Classified.sort((a, b) => a.RANK_PO_COUNT - b.RANK_PO_COUNT).slice(0, 25);
                var sum25Supplier = 0;
                for (let index = 0; index < top25Values.length; index++) {
                    sum25Supplier = sum25Supplier + top25Values[index].PO_COUNT;
                }
                requiredData.push(top25Values.sort(sortObjectArray('RANK_PO_COUNT', 'asc')));

                var top25UnClassified = detailsData.filter(a => a.KPI.toLowerCase() === this.props.content.kpi[2].toLowerCase())
                var top25UnCValues = top25UnClassified.sort((a, b) => a.RANK_PO_COUNT - b.RANK_PO_COUNT).slice(0, 25);
                var sum25UnCSupplier = 0;
                for (let index = 0; index < top25UnCValues.length; index++) {
                    sum25UnCSupplier = sum25UnCSupplier + top25UnCValues[index].PO_COUNT;
                }
                requiredData.push(top25UnCValues.sort(sortObjectArray('RANK_PO_COUNT', 'asc')));
                this.setState({ total5Industry: round(sum5Industry, 2), total25Classified: round(sum25Supplier, 2), total25UnClassified: round(sum25UnCSupplier, 2) })
                break
        }
        this.setState({ topDetails: requiredData });
    }

    toggleDiv(ele) {
        this.setState({ currentKPI: ele.target.id });
        if (ele.target.className === "collaps") {
            ele.target.className = "expand"
        } else if (ele.target.className === "expand") {
            ele.target.className = "collaps"
            this.setState({ currentKPI: '' });
        }
    }

    showAlertModal(action) {
        this.refs[action].show()
    }

    hideAlertModal(action) {
        this.refs[action].hide()
    }

    continueNavigation(action) {
        this.refs[action].hide()
        this.props.trackSupplierDrillDownChanges(false)
        this.props.trackIndustryDrillScopeChanges(false)
        if (this.props.spendDetails.supplierDrilldownSavedChanges === true) {
            this.props.trackSupplierDrillDownSave(false)
            this.setState({  open: false, openIndustryPopup: false })
            this.loadData()
            window.scrollTo(0, 0);
        }
        else {
            this.setState({  open: false, openIndustryPopup: false })
        }
    }

    showSupplierDrilldownModal(SKEY, capIQId, parentCapIQId) {
        var supplierDetails = { SKEY: SKEY, capIQId: capIQId, parentCapIQId: parentCapIQId }
        this.props.storeSupplierSKEY(supplierDetails)
        this.setState({
            open: true
        })
    }

    showSupplierIndustriesModal(data, SKEY, industry, level) {
        // var industryGICS_Details = { SKEY: SKEY, industry: industry, level: level }
        // this.props.storeIndustryGICS_ID(industryGICS_Details)
        
		data['name'] = industry;
		var industryGICS_Details = { SKEY: SKEY, industry: industry, level: level, industryData: { params: data, filters: {country: "", solution: "No Filter", solutionConfirmation: "all"} }}
		this.props.storeIndustryGICS_ID(industryGICS_Details)
        this.setState({
            openIndustryPopup: true
        })
    }

    setVisibility(isVisible) {
        if (this.props.supplierDrilldownPendingChanges !== undefined && this.props.supplierDrilldownPendingChanges !== null
            && this.props.supplierDrilldownPendingChanges === true) {
            this.showAlertModal("alert")
        } else if (this.props.industryDrillScopePendingChanges !== undefined && this.props.industryDrillScopePendingChanges !== null
			&& this.props.industryDrillScopePendingChanges === true) {
			this.showAlertModal("alert")
		}
        else {
            if (this.props.spendDetails.supplierDrilldownSavedChanges === true) {
                this.props.trackSupplierDrillDownSave(false)
                this.setState({ open: false, openIndustryPopup: false })
                this.loadData()
                window.scrollTo(0, 0);
            }
            else {
                this.setState({  open: false, openIndustryPopup: false })
            }
        }
    }

    outsidePopupClick() {
        this.props.trackSupplierDrillDownChanges(false)
        this.props.trackIndustryDrillScopeChanges(false)
        this.setState({ open: false, openIndustryPopup: false })
    }

    render() {
        var row = (instance) => {
            let indexCnt = 0;
            var temp = Object.values(instance.state.topDetails).map((data) => {
                indexCnt++;
                return (
                    <div className='defineScope'>
                        <Modal
                            ref={'modal'} backdropStyle={backdropStyle}
                            keyboard={() => this.callback()}
                            modalStyle={suggestedValueModalStyle}
                            contentStyle={suggestedValueContentStyle}>
                            <div className="grid-x grid-padding-x pb10 pt10">
                                <div className="medium-12 cell">
                                    {this.props.content.allOtherMessage}
                                </div>
                                <div className="medium-12 cell">
                                    <div className="float-right">
                                        <PopupButton
                                            type="submit"
                                            content={this.props.content.okButton}
                                            color
                                            onClick={() => this.refs.modal.hide()}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Modal>
                        <div>
                            {this.props.spendDetails.supplierDetails !== undefined ?
                                <SupplierDrillDownPopup open={this.state.open} visibility={this.setVisibility} outsidePopupClick={this.outsidePopupClick} />
                                :
                                null}
                        </div>
                        <div>
                            <SupplierIndustriesPopup open={this.state.openIndustryPopup} visibility={this.setVisibility} outsidePopupClick={this.outsidePopupClick} />
                        </div>
                        <div>
                            <Modal
                                ref="alert"
                                modalStyle={alertModalStyle}
                                contentStyle={changeModalContentStyle}
                                backdropStyle={changeBackdropStyle}
                            //keyboard={() => this.callback()}
                            >
                                <div className="grid-x grid-padding-x change-popup">
                                    <div className="medium-12">
                                        <button onClick={() => this.hideAlertModal("alert")} className="close" />
                                    </div>
                                    <div className="medium-12 pt30">
                                        {this.props.tabMessage}
                                    </div>
                                    <div className="medium-12 popupOkBtn">
                                        <div className="float-right">
                                            <div className="float-left"><Button
                                                content={"Continue"}
                                                onClick={() => this.continueNavigation("alert")}
                                                color /></div>
                                            <div className="float-left"><Button
                                                content={"Close"}
                                                onClick={() => this.hideAlertModal("alert")}
                                                color /></div>
                                        </div>
                                    </div>
                                </div>

                            </Modal>
                        </div>
                        <div className="grid-x">
                            <div className="medium-12 basic-table">
                                <table className="mainTable">
                                    <thead>
                                        <tr></tr>
                                    </thead>
                                    <tbody>

                                        <tr className="eachRow">
                                            <td className="leftTd bigFont" width="15%">
                                                {this.props.content.total + " : "}
                                                {this.state.dimension.toLowerCase() === this.props.content.dimension[1].toLowerCase() ? null
                                                    : this.state.dimension.toLowerCase() === this.props.content.dimension[3].toLowerCase() ? null
                                                        : this.state.currencySymbol}
                                                {data[0] === undefined ? 0 + (this.state.dimension.toLowerCase().includes('spend') ? 'M' : null)
                                                    : data[0].KPI === this.props.content.kpi[0] ? formatThousands(this.state.total5Industry) :
                                                        (data[0].KPI === this.props.content.kpi[1] ? formatThousands(this.state.total25Classified) : formatThousands(this.state.total25UnClassified))}

                                            </td>
                                            <td className="leftTd" width="15%"><b>{data[0] === undefined ? this.props.content.kpi[indexCnt - 1] : (data[0].KPI)}</b></td>
                                            <td className="leftTd" width="5%">
                                                <span id={data[0] === undefined ? null : data[0].KPI}
                                                    onClick={this.toggleDiv.bind(this)} className='collaps' />
                                            </td>
                                            <td className="tdTable" width="65%">
                                                <table className="innerTable">
                                                    {subItems(data, instance)}
                                                </table>
                                            </td>
                                        </tr>


                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                )
            })
            return temp;
        }

        var subItems = (data, instance) => {
            if (data[0] !== undefined) {
                var a = [];
                var lenIndex = 1;
                var prevExpandeEle = document.getElementById(data[0].KPI);
                if (data[0].KPI === instance.state.currentKPI) {
                    lenIndex = data.length;
                } else if (prevExpandeEle !== null && prevExpandeEle.className === "expand") {
                    lenIndex = data.length;
                }

                for (let index = 0; index < lenIndex; index++) {
                    a.push(
                        <tr style={{ borderTop: '0rem !important', borderTopWidth: '0px !important' }}>
                            {/* <td width="5%">
                            <span className='service_info' ></span>
                        </td> */}

                            {data[index].KPI === 'Top 25 Classified Suppliers' || data[index].KPI === 'Top 25 Unclassified Suppliers' ?

                                <td >
                                    <span className='onMouseOver' style={{ cursor: 'pointer' }} width='55%' onClick={() => this.showSupplierDrilldownModal(data[index].DATA_COLLECTIONS_SKEY, data[index].CAPIQ_ID, data[index].PARENT_CAPIQ_ID)}>{data[index].NAME}</span>
                                    <span className='onMouseOver' style={{ cursor: 'pointer' }} width="55%" onClick={() => this.showSupplierIndustriesModal(data[index], data[index].INDUSTRY_ID, data[index].L4_INDUSTRY_NAME, 4)}>{data[index].L4_INDUSTRY_NAME !== "" && data[index].L4_INDUSTRY_NAME !== undefined ? " :  " + data[index].L4_INDUSTRY_NAME : null}</span>
                                </td> :
                                <td>
                                    <span className='onMouseOver' style={{ cursor: 'pointer' }} width="55%" onClick={() => this.showSupplierIndustriesModal(data[index], data[index].INDUSTRY_ID, data[index].NAME, 4)}>{data[index].NAME}</span>
                                    <span>{data[index].L4_INDUSTRY_NAME !== "" && data[index].L4_INDUSTRY_NAME !== undefined ? " :  " + data[index].L4_INDUSTRY_NAME : null}</span>
                                </td>
                            }
                            <td width="10%" className='tlr'>
                                {this.state.dimension.toLowerCase() === this.props.content.dimension[1].toLowerCase() ? null
                                    : this.state.dimension.toLowerCase() === this.props.content.dimension[3].toLowerCase() ? null
                                        : this.state.currencySymbol}

                                {this.state.dimension.toLowerCase() === this.props.content.dimension[0].toLowerCase() ? convertToMillion(data[index].INVOICE_SPEND, true)
                                    : this.state.dimension.toLowerCase() === this.props.content.dimension[1].toLowerCase() ? formatThousands(data[index].INVOICE_COUNT, false)
                                        : this.state.dimension.toLowerCase() === this.props.content.dimension[2].toLowerCase() ? convertToMillion(data[index].PO_SPEND, true)
                                            : this.state.dimension.toLowerCase() === this.props.content.dimension[3].toLowerCase() ? formatThousands(data[index].PO_COUNT, false)
                                                : convertToMillion(data[index].INVOICE_SPEND, true)}

                            </td>
                        </tr>
                    )
                }
                return a;
            }
        }

        return (
            <div className="topDetails mainDiv bottom-space">
                <div className="grid-x">
                    <div className="medium-12">
                        <div className="tab-title">{(this.props.content.title)}{<VideoHelp screen={{ module: 'Spend', screenName: 'Top Details' }} />}</div>
                        <div className="tab-description">{this.props.content.subTitle}</div>
                    </div>
                    <div className="medium-3 pt20">
                        <Select
                            className="border-with-DD dropDownContent"
                            model='topDetails.Dimension'
                            id='Dimension'
                            autocomplete='true'
                            placeholder={this.props.content.requestId}
                            value={this.state.dimensionOption.filter(option => option.label === this.state.dimension)}
                            onChange={(value) => this.handleDimensionChange(value)}
                            validators={{ required: validators.required }}
                            validateOn='change'
                            options={this.state.dimensionOption}
                            isSearchable={false}
                            noOptionsMessage={() => null}
                        />
                    </div>
                </div>
                <div className="pt5 bb_ccc">
                    {
                        row(this)
                    }
                </div>


                <div className="grid-x medium-12">
                    <div className="errors">{this.props.spendDetails.error}</div>
                </div>
                {/* <div className="grid-x">
                        <div className="medium-12 cell">
                            <Button
                                type="submit"
                                content="Next"
                                color
                                float
                            />
                        </div>
                    </div> */}
            </div >
        )

    }
}

const mapStateToProps = (state) => {
    return {
        // language: state.language,
        // ui: state.content.ui,
        userData: state.valueStory,
        user: state.default,
        content: state.content.spendAnalysis.topDetails,
        validation: state.content.validation,
        spendDetails: state.spendDetails,
        currencySymbols: state.content.CurrencySymbols,
        supplierDrilldownPendingChanges: state.spendDetails.supplierDrilldownPendingChanges,
        industryDrillScopePendingChanges: state.spendDetails.industryDrillScopePendingChanges,
        tabMessage: state.content.spendAnalysis.detailedFile.toggleTabMessage

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        topDetailsSuccess: message => dispatch(topDetailsSuccess(message)),
        topDetailsFailure: error => dispatch(topDetailsFailure(error)),
        topDetailsLoading: bool => dispatch(topDetailsLoading(bool)),
        resetError: error => dispatch(resetError(error)),
        getTopDetails: (DMC_ID, VS_ID, User_ID) => dispatch(getTopDetails(DMC_ID, VS_ID, User_ID)),
        setTopDetails: data => dispatch(setTopDetails(data)),
        storeSupplierSKEY: (SKEY) => dispatch(storeSupplierSKEY(SKEY)),
        trackSupplierDrillDownChanges: changes => dispatch(trackSupplierDrillDownChanges(changes)),
        trackSupplierDrillDownSave: message => dispatch(trackSupplierDrillDownSave(message)),
        storeIndustryGICS_ID: (GICS_ID) => dispatch(storeIndustryGICS_ID(GICS_ID)),
        trackIndustryDrillScopeChanges: bool => dispatch(trackIndustryDrillScopeChanges(bool))        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TopDetailsContainer)
