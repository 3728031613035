import React from 'react'
import { connect } from 'react-redux'
import Button from 'UI/Button'
import moment from 'moment'
import { Checkbox } from 'material-ui';

// Actions
import {
	getLatestPublishedReleaseFeatures,
	updateUserConfirmation,	
	userConsentSuccess,
	userConsentFailure,
	resetError
} from 'actions/userActions'


class NewUpdate extends React.Component {
	constructor(props) 
  {
	super(props);
	this.state = {
		description: [],
		chkbox: false
	}
	this.redirect = this.redirect.bind(this)
	this.getData = this.getData.bind(this)	
	this.hideModal=this.hideModal.bind(this);
	
  }

  componentDidMount(){
	  this.getData()
  }	

  getData(){
	  this.props.getLatestPublishedReleaseFeatures()
	  .then((result) => {
		  this.setState({description: result.data.Result})
	  }).catch((error) =>{
		  this.props.userConsentFailure(error)
	  })
  }

  hideModal()
  {
	this.props.hideModal('newupdate');
  }

  
	redirect() {
		if (localStorage.getItem('role') !== null && localStorage.getItem('role').toUpperCase() === ('TRANSLATOR')) {
				
				this.props.props.push({
					pathname: '/language-manager', validation: this.props.validation
	
				})
			}
			else {
				this.props.props.push(`/start`)
			}
		}

		check(e){
			if(e.target.checked){
				this.props.onChecked(true);
			}
			else{
				this.props.onChecked(false);
			}
		}

	render(){
		return(
		
		<div className="next-steps popup-content">
			<div className="report">
				
					<div className="title">What you need to know</div> 
					<p>{this.props.validation.publishedDate} {this.state.description.length>0 ? moment(this.state.description[0].VersionPublishedDate).format('L') : null}</p>
				
				
					{this.state.description.map((data)=>
						<ul className="description">
							<li className="bold">{data.ReleaseFeatureName}</li>
							<ul className="dot">
							      <li>{data.ReleaseFeatureDescription}</li>
						    </ul>
						</ul>
					)}
				
				
				<br>
				</br>
				
                       <div className="grid-x">
							<div className="grid-x grid-padding-x">
							 <div>
					           <Checkbox  onClick = {(e) =>this.check(e)}/>
					         </div>
					           <div>
				            	<p>Do not show me this again</p>
				         	   </div>
							</div>

							<div className="medium-12">
								<Button
									content={this.props.content.ok}
									onClick= {this.hideModal}	
									checked={this.state.chkbox}
									float
									color />
							</div>
						</div>
				
			<div className="errors">{this.props.user.error}</div>
		</div>
		</div>
		)
	}
	
}

const mapStateToProps = (state) => {
	return {
		content: state.content.login,
		validation: state.content.validation,
		user: state.default
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		updateUserConfirmation: (formValues)=> dispatch(updateUserConfirmation(formValues)),
		getLatestPublishedReleaseFeatures: (formValues) => dispatch(getLatestPublishedReleaseFeatures(formValues)),		
		userConsentSuccess: (user) => dispatch(userConsentSuccess(user)),
		userConsentFailure: (error) => dispatch(userConsentFailure(error)),
		resetError: () => dispatch(resetError())
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(NewUpdate)


