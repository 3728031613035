import React, { Component } from 'react'
import { connect } from 'react-redux'
import FlightPlanningNetworkEnablementMatchTypes from '../Admin/FlightPlanningNetworkEnablementMatchTypes'
import StandardAccountYield from '../Admin/StandardAccountYield' 


class WaveYields extends Component {
    constructor(props) {
        super(props)
        this.state = {
            readOnly: props.user.userprofile.Roles[0].indexOf("Admin") > -1 ? false : true
        }
    }


    render() {
        return (
            <div className="grid-x">
                <FlightPlanningNetworkEnablementMatchTypes readOnly={this.state.readOnly}></FlightPlanningNetworkEnablementMatchTypes>
                <StandardAccountYield readOnly={this.state.readOnly}></StandardAccountYield>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.default,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WaveYields); 