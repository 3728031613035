import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as d3 from 'd3'
import lineChart from 'components/UI/LineChart'

class ANIndustryTransactionStats extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
        this.loadData = this.loadData.bind(this)
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        d3.select("#industryTransactionStats").selectAll("g").remove();
        d3.select("#industryTransactionStats").selectAll("path").remove();

        var data = [{
            "stats": "1000",
            "year": "Jan-2020"
        }, {
            "stats": "1050",
            "year": "Feb-2020"
        }, {
            "stats": "1103",
            "year": "Mar-2020"
        }, {
            "stats": "1158",
            "year": "Apr-2020"
        }, {
            "stats": "1216",
            "year": "May-2020"
        }, {
            "stats": "1216",
            "year": "Jun-2020"
        }, {
            "stats": "1216",
            "year": "Jul-2020"
        }, {
            "stats": "1276",
            "year": "Aug-2020"
        }, {
            "stats": "1340",
            "year": "Sep-2020"
        }, {
            "stats": "1407",
            "year": "Oct-2020"
        }, {
            "stats": "1477",
            "year": "Nov-2020"
        }, {
            "stats": "1551",
            "year": "Dec-2020"
        }];
        lineChart(true, data, '#4472c4', 'Purchase Orders', this.props.industryNetworkStats.purchaseOrders, 2316, 'industryTransactionStats', this.props.currencySymbols[this.props.spendDetails.currentActiveRequest.Currency.trim()], this.props.industryNetworkStats.transactionYaxisLabel)

        var data1 = [{
            "stats": "750",
            "year": "Jan-2020"
        }, {
            "stats": "788",
            "year": "Feb-2020"
        }, {
            "stats": "827",
            "year": "Mar-2020"
        }, {
            "stats": "868",
            "year": "Apr-2020"
        }, {
            "stats": "912",
            "year": "May-2020"
        }, {
            "stats": "912",
            "year": "Jun-2020"
        }, {
            "stats": "912",
            "year": "Jul-2020"
        }, {
            "stats": "957",
            "year": "Aug-2020"
        }, {
            "stats": "1005",
            "year": "Sep-2020"
        }, {
            "stats": "1055",
            "year": "Oct-2020"
        }, {
            "stats": "1108",
            "year": "Nov-2020"
        }, {
            "stats": "1163",
            "year": "Dec-2020"
        }];

        lineChart(false, data1, '#ed7d31', 'Order Confirmations', this.props.industryNetworkStats.orderConfirmations, 2316, 'industryTransactionStats', this.props.currencySymbols[this.props.spendDetails.currentActiveRequest.Currency.trim()], this.props.industryNetworkStats.transactionYaxisLabel)

        var data2 = [{
            "stats": "250",
            "year": "Jan-2020"
        }, {
            "stats": "263",
            "year": "Feb-2020"
        }, {
            "stats": "276",
            "year": "Mar-2020"
        }, {
            "stats": "289",
            "year": "Apr-2020"
        }, {
            "stats": "304",
            "year": "May-2020"
        }, {
            "stats": "304",
            "year": "Jun-2020"
        }, {
            "stats": "304",
            "year": "Jul-2020"
        }, {
            "stats": "319",
            "year": "Aug-2020"
        }, {
            "stats": "335",
            "year": "Sep-2020"
        }, {
            "stats": "352",
            "year": "Oct-2020"
        }, {
            "stats": "369",
            "year": "Nov-2020"
        }, {
            "stats": "388",
            "year": "Dec-2020"
        }];

        lineChart(false, data2, '#a5a5a5', 'Advanced Ship Notices (ASNs)', this.props.industryNetworkStats.ASNs, 2316, 'industryTransactionStats', this.props.currencySymbols[this.props.spendDetails.currentActiveRequest.Currency.trim()], this.props.industryNetworkStats.transactionYaxisLabel)

        var data3 = [{
            "stats": "850",
            "year": "Jan-2020"
        }, {
            "stats": "893",
            "year": "Feb-2020"
        }, {
            "stats": "937",
            "year": "Mar-2020"
        }, {
            "stats": "984",
            "year": "Apr-2020"
        }, {
            "stats": "1033",
            "year": "May-2020"
        }, {
            "stats": "1033",
            "year": "Jun-2020"
        }, {
            "stats": "1033",
            "year": "Jul-2020"
        }, {
            "stats": "1085",
            "year": "Aug-2020"
        }, {
            "stats": "1139",
            "year": "Sep-2020"
        }, {
            "stats": "1196",
            "year": "Oct-2020"
        }, {
            "stats": "1256",
            "year": "Nov-2020"
        }, {
            "stats": "1319",
            "year": "Dec-2020"
        }];

        lineChart(false, data3, '#ffc000', 'PO Invoices', this.props.industryNetworkStats.POInvoices, 2316, 'industryTransactionStats', this.props.currencySymbols[this.props.spendDetails.currentActiveRequest.Currency.trim()], this.props.industryNetworkStats.transactionYaxisLabel)

        var data4 = [{
            "stats": "1500",
            "year": "Jan-2020"
        }, {
            "stats": "1575",
            "year": "Feb-2020"
        }, {
            "stats": "1654",
            "year": "Mar-2020"
        }, {
            "stats": "1736",
            "year": "Apr-2020"
        }, {
            "stats": "1823",
            "year": "May-2020"
        }, {
            "stats": "1823",
            "year": "Jun-2020"
        }, {
            "stats": "1823",
            "year": "Jul-2020"
        }, {
            "stats": "1914",
            "year": "Aug-2020"
        }, {
            "stats": "2010",
            "year": "Sep-2020"
        }, {
            "stats": "2111",
            "year": "Oct-2020"
        }, {
            "stats": "2216",
            "year": "Nov-2020"
        }, {
            "stats": "2327",
            "year": "Dec-2020"
        }];

        lineChart(false, data4, '#5b9bd5', 'Non-PO Invoices', this.props.industryNetworkStats.NonPOInvoices, 2316, 'industryTransactionStats', this.props.currencySymbols[this.props.spendDetails.currentActiveRequest.Currency.trim()], this.props.industryNetworkStats.transactionYaxisLabel)
    }

    render() {
        return (
            <div style={{ marginTop: '-60px' }} className="grid-x">
                <div id="chart" className="medium-12 cell">
                    <div className="grid-x">
                        <div className="medium-11">
                            <svg id="industryTransactionStats" style={{ 'overflow': 'inherit' }}>
                            </svg>
                        </div>
                        <div className="medium-1"></div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        content: state.content,
        ui: state.content.ui,
        user: state.default,
        industryNetworkStats: state.content.spendAnalysis.industryDrilldown.industryNetworkStats,
        spendDetails: state.spendDetails,
        currencySymbols: state.content.CurrencySymbols,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ANIndustryTransactionStats); 