import React from 'react'
import { connect } from 'react-redux'
import {
    defineScopeLoading,
    defineScopeSuccess,
    defineScopeFailure
} from 'actions/spendActions'
import { convertToMillion, formatThousands } from 'utils/Utils'

class EnablementInsightTabTable extends React.Component {
    constructor(props) {
        super(props)
        {
            this.state = {
                industry: '',
                industryOption: [],
                supplierData: [],
                supplierDetails: [],
                grandTotal: [],
                currencySymbol: '',
                firstColumnName: '',
                firstColumn: '',
                helpText: ''
            }
            this.loadInitialData = this.loadInitialData.bind(this)
        }
    }

    componentDidMount() {
        this.loadInitialData()
        window.scrollTo(0, 0);
        this.setState({
            currencySymbol: this.props.currencySymbols[this.props.spendDetails.currentActiveRequest.Currency !== undefined
                && this.props.spendDetails.currentActiveRequest.Currency.trim() !== ''
                && this.props.spendDetails.currentActiveRequest.Currency.trim() !== "0" ?
                this.props.spendDetails.currentActiveRequest.Currency.trim() : 'USD']
        });
    }

    componentWillReceiveProps(nextProps) {
        //if (nextProps.data !== this.props.data) {
        //Perform some operation
        this.setState({ supplierDetails: nextProps.data });
        //}
    }

    loadInitialData() {
        this.getFirstColumnName()
    }

    getFirstColumnName() {
        var firstColumnName;
        var firstColumn;
        var helpText;
        switch (this.props.tableName) {
            case 'Enablement Recommendation':
                firstColumnName = this.props.content.enablementRecommFstColHeader;
                firstColumn = this.props.content.enablementRecommFstCol;
                helpText = this.props.content.enablementRecommHelpText
                break;

            case 'Enablement Match':
                firstColumnName = this.props.content.enablementMatchFstColHeader;
                firstColumn = this.props.content.enablementMatchFstCol;
                helpText = this.props.content.enablementMatchHelpText
                break;

            case 'SAP Business Network Eligibility':
                firstColumnName = this.props.content.aribaNetworkEliFstColHeader;
                firstColumn = this.props.content.aribaNetworkEliFstCol;
                helpText = this.props.content.aribaNetworkEliHelpText
                break;

            case 'Segmentation by Documents':
                firstColumnName = this.props.content.docSegFstColHeader;
                firstColumn = this.props.content.docSegFstCol;
                helpText = this.props.content.docSegHelpText;
                break;

            case 'Segmentation by Spend':
                firstColumnName = this.props.content.spendSegFstColHeader;
                firstColumn = this.props.content.spendSegFstCol;
                helpText = this.props.content.spendSegHelpText
                break;

            case 'Segmentation by Buy Process – Sales':
                firstColumnName = this.props.content.buyProcessSegFstColHeader;
                firstColumn = this.props.content.buyProcessSegFstCol;
                helpText = this.props.content.buyProcessSegHelpText
                break;
            
            case 'Segmentation by Buy Process – Delivery':
                firstColumnName = this.props.content.buyProcessSegDeliveryFstColHeader;
                firstColumn = this.props.content.buyProcessSegDeliveryFstCol;
                helpText = this.props.content.buyProcessSegHelpText
                break;

            case 'Buyer Supplier Relationship':
                firstColumnName = this.props.content.buyerSuppRelationshipFstColHeader;
                firstColumn = this.props.content.buyerSuppRelationshipFstCol;
                helpText = this.props.content.buyerSuppRelationshipHelpText
                break;

            case 'Top Spend Category Segmentation':
                firstColumnName = this.props.content.spendCategorySegFstColHeader;
                firstColumn = this.props.content.spendCategorySegFstCol;
                helpText = this.props.content.spendCategorySegHelpText;
                break;

            case 'Top Document Category Segmentation':
                firstColumnName = this.props.content.docCategorySegFstColHeader;
                firstColumn = this.props.content.docCategorySegFstCol;
                helpText = this.props.content.docCategorySegHelpText
                break;

            case 'L4 Industry Categories of Spend':
                firstColumnName = this.props.content.l4IndustryCategoryFstColHeader;
                firstColumn = this.props.content.l4IndustryCategoryFstCol;
                helpText = this.props.content.l4IndustryCategoryHelpText
                break;

            case 'Out of Scope L2 Industry Categories of Spend':
                firstColumnName = this.props.content.l2IndustryCategoryFstColHeader;
                firstColumn = this.props.content.l2IndustryCategoryFstCol;
                helpText = this.props.content.l2IndustryCategoryHelpText
                break;
            case 'Out of Scope L4 Industry Categories of Spend':
                firstColumnName = this.props.content.l4IndustryCategoryFstColHeader;
                firstColumn = this.props.content.l4IndustryCategoryFstCol;
                helpText = this.props.content.l4IndustryCategoryHelpText
                break;

            case 'L2 Industry Categories of Spend':
                firstColumnName = this.props.content.l2IndustryCategoryFstColHeader;
                firstColumn = this.props.content.l2IndustryCategoryFstCol;
                helpText = this.props.content.l2IndustryCategoryHelpText
                break;

            case 'Ariba Network Supplier Country':
                firstColumnName = this.props.content.aribaNwSupplierCountryFstColHeader;
                firstColumn = this.props.content.aribaNwSupplierCountryFstCol;
                helpText = this.props.content.aribaNwSupplierCountryHelpText
                break;

            case 'Buyer Region':
                firstColumnName = this.props.content.buyerRegionFstColHeader;
                firstColumn = this.props.content.buyerRegionFstCol;
                helpText = this.props.content.buyerRegionHelpText
                break;

            case 'Business Unit':
                firstColumnName = this.props.content.businessUnitFstColHeader;
                firstColumn = this.props.content.businessUnitFstCol;
                helpText = this.props.content.businessUnitHelpText
                break;

            case 'ERP':
                firstColumnName = this.props.content.erpDetailsFstColHeader;
                firstColumn = this.props.content.erpDetailsFstCol;
                helpText = this.props.content.erpDetailsHelpText
                break;

            case 'Legal E-invoicing':
                firstColumnName = this.props.content.legalEInvoicingFstColHeader;
                firstColumn = this.props.content.legalEInvoicingFstCol;
                helpText = this.props.content.legalEInvoicingHelpText
                break;
        }

        this.setState({ firstColumnName: firstColumnName, firstColumn: firstColumn, helpText: helpText });
        return firstColumnName;
    }

    getBackgroundColor() {
        let bgColor;
        switch (this.props.tableSequence) {
            case "1":
                bgColor = '#00BFD3'//'#0f46a7';
                break;

            case "2":
                bgColor = '#BE008C'//'#760a85'
                break;

            case "3":
                bgColor = '#FA9100'//'#e35500'
                break;
            case "4":
                if (this.props.tableName === "L4 Industry Categories") {
                    bgColor = '#e35500'
                } else {
                    bgColor = '#93C939'//'#348026'
                }
                break;
            case "5":
                bgColor = '#999999'//'#7f7f7f'
                break;
            case "6":
                bgColor = '#b90c0d';//'#0f46a7';
                break;
            case "7":
                bgColor = '#367dc4'//'#760a85'
                break;
            case "8":
                bgColor = '#69767c'//'#e35500'
                break;
            case "9":
                bgColor = '#de890d'//'#348026'
                break;
            case "10":
                bgColor = '#f29b1d'
                break;
            case "11":
                bgColor = '#f5b04d'
                break;
            case "12":
                bgColor = '#848f94'
                break;
            case "13":
                bgColor = '#9ea8ad'
                break;
            case "14":
                bgColor = '#bac1c4'
                break;
            case "15":
                bgColor = '#d5dadc'
                break;
            case "16":
                bgColor = '#358a4d'
                break;
            case "17":
                bgColor = '#3fa45b'
                break;
            case "18":
                bgColor = '#4cba6b'
                break;
            case "19":
                bgColor = '#bac1c4'
                break;
            case "20":
                bgColor = '#2a6d3c'
                break;
            case "21":
                bgColor = '#358a4d'
                break;
            case "22":
                bgColor = '#3fa45b'
                break;
            case "23":
                bgColor = '#4cba6b'
                break;
            case "24":
                bgColor = '#71c989'
                break;
            case "25":
                bgColor = '#f8cc8c'
                break;
            case "26":
                bgColor = '#f0ab00'
                break;
            case "27":
                bgColor = '#a1dbb1'
                break;
            case "28":
                bgColor = '#dc0d0e'
                break;
        }

        return bgColor;
    }

    render() {
        var _this = this;
        if (this.state.supplierDetails.length > 0) {
            var currencySymbol = this.state.currencySymbol
            var firstColumn = this.state.firstColumn
            var rows = this.state.supplierDetails.map(function (row) {
                return (
                    <Rows
                        currencySymbol={currencySymbol}
                        user={_this.props.user.user}
                        content={_this.props.content}
                        rowData={row}
                        tableName={_this.props.tableName}
                        firstColumn={firstColumn}
                        metricsType={_this.props.metricsType}
                        showEnablementSupplierDetailsModal={_this.props.showEnablementSupplierDetailsModal}
                    />
                )
            })
        }

        return (
            <div className='basic-table'>
                <table className='enablementInsightTable custScroll'>
                    {/* <caption color="Red">{this.props.tableName}</caption>      */}
                    <thead><tr ><th colSpan={this.props.tableName === "Enablement Recommendation" ? '7' : '7'} style={{ textAlign: 'center', backgroundColor: this.getBackgroundColor(), color: '#ffffff' }} >{this.props.tableName}</th></tr></thead>
                    <thead>
                        <tr>
                            <th className="tableHeader bgcn" width='25%' title={this.state.helpText}>{this.state.firstColumnName}</th>

                            <th className="tableHeader bgcn column-align-right" width='15%' title={this.props.content.supplierHelpText}>{this.props.content.supplierColumn}</th>
                            <th className="tableHeader bgcn column-align-right" width='10%' title={this.props.content.supplierPerHelpText}>{this.props.content.supplierPerColumn}</th>

                            {/* {this.props.tableName === "Enablement Recommendation" ?
                                <th className="tableHeader bgcn column-align-right" width='10%' title={this.props.content.uniqueANIDsHelpText}>{this.props.content.uniqueANIDsColumn}</th>
                                : null} */}

                            {this.props.metricsType === "Invoice & PO" ?
                                <th className="tableHeader bgcn column-align-right" width='10%' title={this.props.content.invoiceSpendHelpText}>{this.props.content.invoicePoSpendColumn}</th>
                                : this.props.metricsType === "Invoice Only" ?
                                    <th className="tableHeader bgcn column-align-right" width='10%' title={this.props.content.invoiceSpendHelpText}>{this.props.content.spendColumn}</th>
                                    : this.props.metricsType === "PO Only" ?
                                        <th className="tableHeader bgcn column-align-right" width='10%' title={this.props.content.poSpendHelpText}>{this.props.content.poSpendColumn}</th>
                                        : <th className="tableHeader bgcn column-align-right" width='10%' title={this.props.content.poSpendHelpText}>{this.props.content.spendProxyColumn}</th>
                            }

                            {this.props.metricsType === "Invoice & PO" ?
                                <th className="tableHeader bgcn column-align-right" width='10%' title={this.props.content.invoicePerHelpText}>{this.props.content.invoicePoSpendPerColumn}</th>
                                : this.props.metricsType === "Invoice Only" ?
                                    <th className="tableHeader bgcn column-align-right" width='10%' title={this.props.content.invoicePerHelpText}>{this.props.content.spendPerColumn}</th>
                                    : this.props.metricsType === "PO Only" ?
                                        <th className="tableHeader bgcn column-align-right" width='10%' title={this.props.content.invoicePerHelpText}>{this.props.content.poSpendPerColumn}</th>
                                        : <th className="tableHeader bgcn column-align-right" width='10%' title={this.props.content.invoicePerHelpText}>{this.props.content.spendProxyPerColumn}</th>
                            }

                            {this.props.metricsType === "Invoice & PO" ?
                                <th className="tableHeader bgcn column-align-right" width='10%' title={this.props.content.invoicePerHelpText}>{this.props.content.invoicePoDocumentColumn}</th>
                                : this.props.metricsType === "Invoice Only" ?
                                    <th className="tableHeader bgcn column-align-right" width='10%' title={this.props.content.documentHelpText}>{this.props.content.invoiceDocumentColumn}</th>
                                    : this.props.metricsType === "PO Only" ?
                                        <th className="tableHeader bgcn column-align-right" width='10%' title={this.props.content.documentHelpText}>{this.props.content.poDocumentColumn}</th>
                                        : <th className="tableHeader bgcn column-align-right" width='10%' title={this.props.content.documentHelpText}>{this.props.content.documentColumn}</th>
                            }

                            {this.props.metricsType === "Invoice & PO" ?
                                <th className="tableHeader bgcn column-align-right" width='10%' title={this.props.content.invoicePerHelpText}>{this.props.content.invoicePoDocumentPerColumn}</th>
                                :this.props.metricsType === "Invoice Only" ?
                                <th className="tableHeader bgcn column-align-right" width='10%' title={this.props.content.documentPerHelpText}>{this.props.content.invoiceDocumentPerColumn}</th>
                                : this.props.metricsType === "PO Only" ?
                                    <th className="tableHeader bgcn column-align-right" width='10%' title={this.props.content.documentPerHelpText}>{this.props.content.poDocumentPerColumn}</th>
                                    : <th className="tableHeader bgcn column-align-right" width='10%' title={this.props.content.documentPerHelpText}>{this.props.content.documentPerColumn}</th>
                            }
                            {/* <th className="column-align-right pr20 tableHeader" width='18%'>{this.props.content.poCount}</th> */}
                        </tr>
                    </thead>
                    <tbody className='medium-12'>{rows}</tbody>
                    <tfoot>
                        <tr>
                            <td>{this.props.content.grandTotal}</td>
                            <td className="column-align-right">{this.props.grandTotal[0] !== undefined ? formatThousands(this.props.grandTotal[0].TOTAL_VENDOR_CNT) : null}</td>
                            <td className="column-align-right">{this.props.grandTotal[0] !== undefined ? this.props.grandTotal[0].VENDOR_PER + "%" : null}</td>

                            {/* {this.props.tableName === "Enablement Recommendation" ?
                                <td className="column-align-right" width='10%'>{this.props.grandTotal && this.props.grandTotal[0] !== undefined ? formatThousands(this.props.grandTotal[0].TOTAL_UNIQUE_VENDOR_AN_ID) : null}</td>
                                : null} */}

                            <td className="column-align-right">{this.props.grandTotal[0] !== undefined ? this.state.currencySymbol + convertToMillion(this.props.grandTotal[0].TOTAL_AGGR_SPEND, true) : null}</td>
                            <td className="column-align-right">{this.props.grandTotal[0] !== undefined ? this.props.grandTotal[0].AGGR_SPEND_PER + "%" : null}</td>

                            <td className="column-align-right">{this.props.grandTotal[0] !== undefined ? formatThousands(this.props.grandTotal[0].TOTAL_DOC_PROXY) : null}</td>
                            <td className="column-align-right">{this.props.grandTotal[0] !== undefined ? this.props.grandTotal[0].DOC_PROXY_PER + "%" : null}</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        )
    }
}

class Rows extends React.Component {
    constructor(props) {
        super(props)
        {
            this.state = {
                helpText: '',
            }
        }
        this.getHelpText = this.getHelpText.bind(this);

    }

    getHelpText(value) {
        var hoverText = "";
        switch (value.target.innerText) {
            case this.props.content.enablementWaveDetails.entSupplierDedicatedSpecialist:
                hoverText = this.props.content.enablementWaveDetails.entSupplierDedicatedSpecialistHelpText
                break;

            case this.props.content.enablementWaveDetails.entIntegraReadyCataCandidate:
                hoverText = this.props.content.enablementWaveDetails.entIntegraReadyCataCandidateHelpText
                break;

            case this.props.content.enablementWaveDetails.entIntegraCataCandidate:
                hoverText = this.props.content.enablementWaveDetails.entIntegraCataCandidateHelpText
                break;

            case this.props.content.enablementWaveDetails.entIntegraReadyCandidate:
                hoverText = this.props.content.enablementWaveDetails.entIntegraReadyCandidateHelpText
                break;

            case this.props.content.enablementWaveDetails.entIntegraCandidate:
                hoverText = this.props.content.enablementWaveDetails.entIntegraCandidateHelpText
                break;

            case this.props.content.enablementWaveDetails.entPortalCataCandidate:
                hoverText = this.props.content.enablementWaveDetails.entPortalCataCandidateHelpText
                break;

            case this.props.content.enablementWaveDetails.entPortal:
                hoverText = this.props.content.enablementWaveDetails.entPortalHelpText
                break;

            case this.props.content.enablementWaveDetails.managedStandardInteractiveEmail:
                hoverText = this.props.content.enablementWaveDetails.managedStandardInteractiveEmailHelpText
                break;

            case this.props.content.enablementWaveDetails.onGoingStandardInteractiveEmail:
                hoverText = this.props.content.enablementWaveDetails.onGoingStandardInteractiveEmailHelpText
                break;

        }
        this.setState({ helpText: hoverText })
        return hoverText;
    }

    render() {
        return (
            <tr className='eachRow'>
                <td className='tableContent' width='25%' title={this.state.helpText} onMouseOver={(e) => this.getHelpText(e)}>
                    <span className='onMouseOver' style={{ cursor: 'pointer' }} width='45%' onClick={() => this.props.showEnablementSupplierDetailsModal(this.props.tableName, this.props.firstColumn, this.props.rowData[this.props.firstColumn])}>
                        {this.props.rowData[this.props.firstColumn]}
                    </span>
                </td>
                <td className="tableContent column-align-right" width='15%'>{formatThousands(this.props.rowData.CNT_VENDOR_NAME)}</td>
                <td className="tableContent column-align-right" width='10%'>{this.props.rowData.CNT_VENDOR_PER}{"%"}</td>

                {/* {this.props.tableName === "Enablement Recommendation" ?
                    <td className="tableContent column-align-right" width='10%'>{formatThousands(this.props.rowData.CNT_UNIQ_VENDOR_AN_ID)}</td>
                    : null} */}

                <td className="tableContent column-align-right" width='10%'>{this.props.currencySymbol}
                    {convertToMillion(this.props.rowData.SUM_AGGR_SPEND, true)}</td>
                <td className="tableContent column-align-right" width='10%'>{this.props.rowData.SUM_AGGR_SPEND_PER}{"%"}</td>

                <td className="tableContent column-align-right" width='10%'>{formatThousands(this.props.rowData.SUM_SAP_ARIBA_DOC_PROXY)}</td>
                <td className="tableContent column-align-right" width='10%'>{this.props.rowData.SAP_ARIBA_DOC_PROXY_PER}{"%"}</td>
            </tr>
        )
    }
}


const mapStateToProps = state => {
    return {
        user: state.default,
        spendDetails: state.spendDetails,
        content: state.content.enablementInsight,
        validation: state.content.validation,
        currencySymbols: state.content.CurrencySymbols
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        defineScopeLoading: (bool) => dispatch(defineScopeLoading(bool)),
        defineScopeSuccess: (bool) => dispatch(defineScopeSuccess(bool)),
        defineScopeFailure: (error) => dispatch(defineScopeFailure(error))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EnablementInsightTabTable)