import React, { PureComponent } from 'react'
import { MultiSelect } from 'primereact/multiselect';
import Button from 'UI/Button';

export default class VendorUploadFilesModalContainer extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            rowData: props.rowData,
            wave: [],
            wavesSelected: null,
            filteredData: []
        }
    }

    componentDidMount() {
        let dummy = this.props.waves
        //extract unique | distinct values from array
        let unique = [...new Set(dummy)];
        unique = unique.length > 0 ? unique.sort((a, b) => Number(a) - Number(b)) : unique;
        //multiselect expects an array of objects
        let tempWaveObj = []
        unique.forEach(item => {
            let obj = {}
            obj.label = item;
            obj.value = item;

            tempWaveObj.push(obj)
        })
        this.setState({ wave: tempWaveObj })
    }

    handleWaveChange(data) {
        let waves = this.state.wavesSelected;
        waves = data.value
        //Filter rowData by waves
        let rowData = this.state.rowData

        let filteredData = rowData.filter((item, index) => {
            if (waves.includes(item.Wave)) {
                return item;
            }
        })

        this.setState({ wavesSelected: waves, filteredData: filteredData })
    }

    exportData() {
        let exportData = this.state.filteredData.length > 0 ? this.state.filteredData : this.state.rowData;
        this.props.exportCsvForVUF(exportData);
    }

    render() {
        return (
            <div className="grid-x">
                <div className="medium-12 popHeader vufHeader">{'Download Vendor Upload Files'}</div>
                <div className="medium-6 vufMultiSelect">
                    <div className="dropDownHeader">{'Select a wave'}</div>
                    <MultiSelect
                        className="border-with-DD dropDownContent multiSelectHeight multiSelectLabelPadding"
                        model='yourFile.RequestId'
                        id='RequestId'
                        value={this.state.wavesSelected}
                        onChange={value => this.handleWaveChange(value)}
                        options={this.state.wave}
                        display="chip"
                    />
                </div>
                <div className="medium-6 vufDownloadBtn">
                    <div className="float-right"><Button
                        content={this.props.content}
                        onClick={this.exportData.bind(this)}
                        color /></div>

                </div>
            </div>
        )
    }
}