import React, { Component } from 'react'
import * as d3 from 'd3'
import { convertToMillion, convertToThousands } from 'utils/Utils'
import { uid } from 'react-uid'

const lineChart = (drawXY, xaxiscolumnkey, yaxiscolumnkey, data, elementId, svg, fillColor, yKey, leftAxisText) => {
    if (data !== undefined) {
        d3.select("#chart").selectAll("groupbarchart").remove();
        var xaxiscolumnkey = xaxiscolumnkey
        var yaxiscolumnkey = yaxiscolumnkey

        var parentNode = document.getElementById("chart").parentNode;
        var windowWidth = parentNode.clientWidth;
        var windowHeight = parentNode.clientHeight;

        var margin = { top: 20, right: 40, bottom: 30, left: 40 },
            width = (windowWidth - margin.left - margin.right) + 57,
            height = (windowHeight - margin.top - margin.bottom)
            
        if (svg === undefined) {
            // append the svg object to the body of the page
            var svg = d3.select("#" + elementId)
                .append("svg")

                .attr("width", width)
                .attr("height", height)
                .append("g")
                .attr("transform",
                    "translate(" + margin.left + "," + margin.top + ")");

        } else {
            svg.attr('height', windowHeight);
            svg.attr('width', windowWidth);
        }



        // Add X axis  
        var x = d3.scaleBand()
            .domain(data.map(function (d) { return d[xaxiscolumnkey]; }))
            .rangeRound([margin.left + 45, width])
            .paddingInner(0.1);



        // Add Y axis
        var y = d3.scaleLinear()
            .domain([0, d3.max(data, function (d) { return d[yKey]; })]).nice()
            .range([height, 0]);
        // svg.append("g")
        //       .call(d3.axisRight(y0).ticks(10)).attr("margin-Left", 40)         ;

        if (drawXY) {
            svg.append("g")
                .attr("class", "axis")
                .call(d3.axisRight(y).ticks(null, "s"))
                .call(g => g.selectAll(".domain").remove())
                .append("text")
                .attr("x", 0)
                .attr("y", 10)
                .attr("fill", "#000")
                .attr("font-size", "0.625rem")
                .attr("text-anchor", "start")
                .text(leftAxisText);
        }
        // Add the line
        svg.append("path")
            .datum(data)
            .attr("fill", "none")
            .attr("stroke", fillColor)
            .attr("stroke-width", 1.5)
            .attr("d", d3.line()
                .x(function (d) {
                    return x(d[xaxiscolumnkey])//+10
                })
                .y(function (d) {
                    return y(d[yaxiscolumnkey])+ 15
                })
            )

        // Add the points
        svg.append("g")
            .selectAll("dot")
            .data(data)
            .enter()
            .append("circle")
            .attr("class", "dot")
            .attr("cx", function (d) {
                return x(d[xaxiscolumnkey])//+10
            })
            .attr("cy", function (d) {
                return y(d[yaxiscolumnkey]) + 15
            })
            .attr("r", 5)
            .attr("fill", fillColor)

        const label = svg.append("g")
            .attr("fill", "currentcolor")
            .selectAll("g")
            .data(data)
            .join("g")
            .attr("transform", d => yaxiscolumnkey.toLowerCase().includes('matched') ?
                `translate(${x(d[xaxiscolumnkey]) + 10},${y(d[yaxiscolumnkey]) + 40})`
                : `translate(${x(d[xaxiscolumnkey]) + 10},${y(d[yaxiscolumnkey]) - 20})`)
            .attr("opacity", 1).append("text")
            .style("font-size", "0.625rem")
            .style("font-family", "BentonSans, Arial, Helvetica, sans-serif")
            .text(function (d) {
                return yaxiscolumnkey.toLowerCase().includes('spend') ?
                    convertToMillion(d[yaxiscolumnkey], true)
                    : convertToThousands(d[yaxiscolumnkey]);
            });


        return svg;
    }
}

export default lineChart

