import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Control, Form, Errors, actions } from 'react-redux-form'
import classNames from "classnames";
import { path } from 'Constants'
import moment from 'moment'
import {ControlTextE} from 'UI/CustomComponents/Controls';
// Actions
import {
    getWaveWeightCategoryDetails,
    updateWaveWeightCategoryDetails,
    resetError
} from 'actions/spendActions'

// Utils
import { BreakLine } from 'utils/Utils'

class WaveScoringParameters extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            waveScoringData: [],
            editedEnterpriseParameters: [],
            responseMsg: '',
            isError: false
        }
        this.loadData = this.loadData.bind(this)
        this.trackSpendParameterChanges = this.trackSpendParameterChanges.bind(this)
        this.onSpendParameterSave = this.onSpendParameterSave.bind(this)
    }

    componentDidMount() {
        //this.setState({ waveScoringData: this.props.data })
        this.loadData();
    }

    loadData() {
        this.getAllParameters();
    }

    trackSpendParameterChanges(record) {
        if (this.state.editedEnterpriseParameters.length === 0 || this.state.editedEnterpriseParameters.find(a => a.WEIGHT_CATEGORY_ID === record.WEIGHT_CATEGORY_ID) === undefined) {
            var updatedRecords = this.state.editedEnterpriseParameters
            updatedRecords.push(record)
            this.setState({ responseMsg: '', editedEnterpriseParameters: updatedRecords, isError: false })
        }
    }

    getAllParameters() {
        this.setState({ responseMsg: '', isError: false });

        this.props.getWaveWeightCategoryDetails()
            .then((response) => {
                if (response.status === 200) {
                            
                            this.setState({ waveScoringData: response.data.Result.filter(d=>d.ENABLE_RECMD_TYPE=== this.props.Enable_Recmd_Type && d.SOLUTION_IN_SCOPE === this.props.solutionInscope), isLoading: false, responseMsg: '', isError: false });
                        }
                        else {
                            this.setState({ responseMsg: this.props.content.errorMessage, isError: true });
                        }
            }).catch((error) => {
                if (error.stack.includes('Network Error')) {
                    this.setState({ responseMsg: 'API service is down. Please contact administrator', isError: 'Yes' })
                }
                else {
                    this.setState({ responseMsg: this.props.content.errorMessage, isError: 'Yes' })
                }
            })
    }

    onSpendParameterSave() {
        this.setState({ isLoading: true, responseMsg: '', isError: false });                    
        let updatedRecords = this.state.editedEnterpriseParameters;
        
        this.props.updateWaveWeightCategoryDetails(updatedRecords).then((response) => {
            if (response.status === 200) {
                this.setState({ editedEnterpriseParameters: [], isLoading: false, responseMsg: this.props.content.successMessage, isError: false });               
            }
            else {
                this.setState({ isLoading: false, responseMsg: this.props.content.errorMessage, isError: true });
            }
        }).catch((error) => {
            this.setState({ isLoading: false, responseMsg: this.props.content.errorMessage, isError: true });
        })
    }

    componentWillUnmount() {
        this.props.resetError()
    }

    render() {
        var _instance = this
        var enterpriseParameters = this.state.waveScoringData.map(function (parameter) {
            return (<TableRows
                enterpriseParameter={parameter}
                trackSpendParameterChanges={_instance.trackSpendParameterChanges}
                readOnly={_instance.props.readOnly}
            />
            );
        });

        return (
            <div style={{ width : '100%' }}>
                <div>
                    <div className="grid-x">
                        <div className="medium-12">
                            <div>
                                {(this.state.isLoading) ?
                                    <img style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
                                        src={path + `images/ajax-loader.gif`} alt="" />
                                    : null
                                }
                            </div>
                            {this.state.waveScoringData.length > 0 ?
                                <div>
                                    <div className="grid-x">                                        
                                        <div className="medium-6 float-left" style={{ fontSize: '1.2rem', paddingBottom: '8px' }}>                                        
                                        {BreakLine(this.props.tab === 'Enterprise' ? this.props.content.enterpriseParameter : this.props.tab === 'Standard' ? this.props.content.standardParameter : this.props.tab === 'SCC' ? this.props.content.sccParameter : this.props.content.kpiParameter)} 
                                        </div>
                                        <div className="medium-6" style={{ display: 'flex', justifyContent: 'center' }} >
                                            {this.state.isError ?
                                                <div style={{ margin: '0 0 0.5rem', color: 'red' }}>{this.state.responseMsg}</div> :
                                                <div style={{ margin: '0 0 0.5rem', color: '#4fb81c' }}>{this.state.responseMsg}</div>
                                            }
                                        </div>
                                        <div className="medium-3"></div>
                                    </div>
                                    <div className="grid-x">
                                        <div className="medium-11 float-left" style={{ paddingBottom: '8px' }}>
                                        { this.props.tab === 'Enterprise' ? this.props.content.enterpriseTabDesc : this.props.tab === 'Standard' ? this.props.content.standardTabDesc : this.props.tab === 'SCC' ? this.props.content.sccTabDesc : this.props.content.kpiTabDesc }
                                        </div>
                                        {this.props.user.userprofile.Roles[0].indexOf("Admin") > -1 ?
                                            <div className="medium-1" style={{ paddingBottom: '2px' }} >
                                                <button className={classNames('float-right',
                                                    {
                                                        'hand-cursor': this.state.isLoading ? false : (this.state.editedEnterpriseParameters.length > 0),
                                                        'gray-scale': this.state.isLoading ? true : (this.state.editedEnterpriseParameters.length === 0)
                                                    })}
                                                    onClick={this.onSpendParameterSave} disabled={this.state.isLoading ? true : this.state.editedEnterpriseParameters.length === 0} type="submit" float >
                                                    <img width="25" title={this.props.content.save} src="images/save_active.svg" />
                                                </button>
                                            </div> : null}
                                    </div>
                                </div>
                                : null}
                        </div>
                    </div>
                    {this.state.waveScoringData.length > 0 ?
                        <div className="grid-x">
                            <div className="medium-12">
                                <Form
                                    model="flightPlanningSpendParameter"
                                >
                                    <div className="grid-x basic-table">
                                        <table>
                                            <thead>
                                                <tr className="eachRow">
                                                    <th>{this.props.content.enablementMatchType}</th>
                                                    <th>{this.props.content.weight}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {enterpriseParameters}
                                            </tbody>
                                        </table>
                                    </div>
                                </Form>
                            </div>
                        </div>
                        : null}
                </div>
            </div>
        )
    }
}

class TableRows extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            parameter: this.props.enterpriseParameter,
        };
    }

    onChangeEvent(val) {
        if (val.target.value === '' ||
            /^(\-(\d*))$/.test(val.target.value) || !isNaN(val.target.value)) {
            var editingRow = this.props.enterpriseParameter;
            if (val.target.id === 'WEIGHT') {
                editingRow[val.target.id] = val.target.value
                this.props.trackSpendParameterChanges(editingRow)
            }
           
            }
        }
    

    render() {
        var bindValues = this.props.enterpriseParameter;
        return (
            <tr className="eachRow">             
                
                <td model="flightPlanningSpendParameter.EnablementMatchType">{bindValues.ENABLEMENT_MATCH_TYPE}</td>                
                <td>
                    <ControlTextE
                        model="flightPlanningSpendParameter.WEIGHT"
                        onChange={this.onChangeEvent.bind(this)}
                        id={'WEIGHT'}
                        defaultValue={bindValues.WEIGHT}
                        value={bindValues.WEIGHT}
                        type='name'
                        className="edit-textarea"
                        readOnly={this.props.readOnly}
                    />
                </td>
            </tr>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        language: state.language,
        ui: state.content.ui,
        user: state.default,
        content: state.content.menu.EnablementParametersManager[0].WaveScoringTab
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        getWaveWeightCategoryDetails: () => dispatch(getWaveWeightCategoryDetails()),
        updateWaveWeightCategoryDetails: (records) => dispatch(updateWaveWeightCategoryDetails(records)),
        resetError: () => dispatch(resetError())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WaveScoringParameters)