import React from 'react';
import { connect } from 'react-redux'
import { RadioButton } from 'material-ui';
import ScrollArea from 'react-scrollbar'

import Button from 'UI/Button';
import ButtonWithoutLoader from 'UI/ButtonWithoutLoader'
import Select from 'react-select'

import { validators, dropdownColourStyles } from 'Constants'

import {
    resetError
} from 'actions/userActions'

import {
    searchResult,
    fetchCapIqSearchResults,
    capIqSearchSuccess,
    capIqSearchFailure
}
from 'actions/searchActions'

import {
	fetchANIdBPIdDetails
} from 'actions/spendActions'

import { capIQFetchData } from 'actions/discoveryActions'

class BPIdPicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            anidData: '',
            data: props.data,
            peArray: [],
            bpArray: [],
            flag: false,
            index: -1,
            checked: false,
            checkIndex: -1,          
           
            selectedData: null,
            searchOption: [{ label: 'Customer Name', value: 'Customer Name' }],
            selectedOption: 'Customer Name',
            searchText: '',
            companyDetails: [],
            anidObj: [],
            isLoading: false,
           
            errorMsg: '',
            searchFlag: false,
            searchMessage: '',
            countryArray: [],
            selectedCountry: 'All Countries'
        }
        this.loadSearchedANID = this.loadSearchedANID.bind(this)
        this.loadSelectedANID = this.loadSelectedANID.bind(this)
        this.RadioButtonChecked = this.RadioButtonChecked.bind(this);
        this.onSearchTextChangeEvent = this.onSearchTextChangeEvent.bind(this);
        this.search = this.search.bind(this);
        this._handleKeyDown = this._handleKeyDown.bind(this);
        this.save = this.save.bind(this)
        this.clearBPID = this.clearBPID.bind(this);
    }

    save() {
        if (this.state.checked) {
            this.props.bpIdRadioButtonClicked(this.state.selectedData)            
        }
        this.props.close();
    }

    clearBPID() {
       this.props.clearBPID()        
    }

    onSearchTextChangeEvent(event) {
        this.setState({ searchText: event.target.value !== undefined ? event.target.value : '', checked: false });
    }

    _handleKeyDown(e) {
        if (e.key === 'Enter') {
            this.search()
        }
    }   

    search() {
      var data = this.state.bpidData.filter(d=>d.CUSTOMER_NAME.toLowerCase().includes(this.state.searchText.toLowerCase()))
      this.setState({ searchResult : data }, this.loadSearchedANID)
      console.log("Search data......", data)
    }

    RadioButtonChecked(e) {
        let data = this.state.searchResult[e.currentTarget.value]
        
        //After this send the data back to search results to populate company profile..................................

        this.setState({ checked: true, checkIndex: e.currentTarget.id, selectedData: data, isLoading: true, bpidObj: data }, this.callMethods)
    }

    callMethods() {
        this.loadSearchedANID();
        this.loadSelectedANID();
    }

    loadSearchedANID() {
        var result = (<div>
            {this.state.searchResult.map((data, index) => (
                <div className="pe-div">
                    <div className="company-name-row">
                        <div className="float-left collapse-expand">
                            <div className="float-left radio-btn">
                                <RadioButton className='radioButton'
                                    checked={this.state.checked && data.BPID == this.state.checkIndex}
                                    id={data.BPID}
                                    value={index}
                                    onClick={this.RadioButtonChecked}
                                    color="default"
                                />
                            </div>
                        </div>
                        <div className="float-left details-text">
                            <div>
                                <span className="dark">{data.CUSTOMER_NAME}</span>
                                <span> | <span className="dark">{data.BPID}</span></span>
                            </div>                           
                        </div>
                    </div>
                </div>
            ))}
        </div>)

        this.setState({ peArray: result })
    }

    loadSelectedANID() {
        var data = this.state.bpidObj;

        var result = (<React.Fragment>
            <div className="company-info-row">
                <div className="grid-x">
                    <div className="medium-5 large-5 title">Customer Name</div>
                    <div className="medium-7 large-7 value">{data.CUSTOMER_NAME != null ? data.CUSTOMER_NAME : '-'}</div>
                </div>
                <div className="grid-x">
                    <div className="medium-5 large-5 title">BPID</div>
                    <div className="medium-7 large-7 cell value">
                        {data.BPID != null ? data.BPID : '-'}
                    </div>
                </div>
                <div className="grid-x">
                    <div className="medium-5 large-5 title">Buyer ANID</div>
                    <div className="medium-7 large-7 cell value">
                        {data.BUYER_ANID != null ? data.BUYER_ANID : '-'}
                    </div>
                </div>               
                <div className="grid-x">
                    <div className="medium-5 large-5 title">SAP Region</div>
                    <div className="medium-7 large-7 cell  value">
                        {data.SAP_REGION != null ? data.SAP_REGION : '-'}
                    </div>
                </div>
               
                <div className="grid-x">
                    <div className="medium-5 large-5 title">Country</div>
                    <div className="medium-7 large-7 cell value">
                            {data.COUNTRY != null ? data.COUNTRY : '-'}
                    </div>
                </div>               
            </div>
        </React.Fragment>)
        //this.props.capIQSearchTree(result)
        this.setState({ companyDetails: result, isLoading: false })

    }


    handleSelectionChange(value) {
        this.setState({ selectedOption: value });
    }

    componentDidMount() {
        this.props.fetchANIdBPIdDetails()
        .then((response) => {
            this.setState({ bpidData: response.data.Result })
            console.log("ANID DAta...", response.data.Result)
        }).catch((error) => {
            if (error.stack.includes('Network Error')) {
                this.props.userDetailsFailure()
            }
            else {
                this.props.userDetailsFailure(error.response.data.Message)
            }
        })
    }
    
    componentWillUnmount() {
        this.props.resetError()
    }

    render() {
        return (
            <div id="capiq-search" className="treeContainer company-search-popup">
                <div className="grid-x">
                    <div className="medium-12 border-bottom">
                        <div className="float-left popHeader">Search by customer name</div>
                        <div className="float-right close-icon" onClick={this.props.close}>
                            <img width="10" src="images/close_inactive.svg" />
                        </div>
                    </div>
                    <div className="medium-6">
                        <div className="medium-12 large-12">
                            <div className="label-searchBy">{this.props.content.searchBy}<span className="asterisk">*</span></div>
                            <div className="control-wrapper pr">
                                <Select
                                    className="border"
                                    value={this.state.searchOption.filter(option => option.label === this.state.selectedOption)}
                                    noOptionsMessage={() => null}
                                    onChange={(value) => this.handleSelectionChange(value.label)}
                                    options={this.state.searchOption}
                                    isSearchable={false}
                                    validateOn="change" styles={dropdownColourStyles}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="medium-6">
                        {/* <div className="label-filterBy">{'Filter By'}<span className="asterisk">*</span></div> */}
                    </div>
                    <div className="medium-6">
                        <div className="medium-8 search-box">
                            <div className="control-wrapper">
                                <input type='text' onKeyDown={(e) => this._handleKeyDown(e)} onChange={this.onSearchTextChangeEvent} value={this.state.searchText} />
                                <button onClick={() => this.search()} >Search</button>
                            </div>

                        </div>
                    </div>
                    {/* <div className="medium-6">
                        <div className="medium-12 large-12">


                            <div className="control-wrapper pt10">
                                <Select
                                    className="border"
                                    value={this.state.countryArray.filter(option => option.label === this.state.selectedCountry)}
                                    noOptionsMessage={() => null}
                                    onChange={(value) => this.handleCountryChange(value.label)}
                                    options={this.state.countryArray}
                                    isSearchable={false}
                                    validateOn="change" styles={dropdownColourStyles}
                                />
                            </div>
                        </div>
                    </div> */}
                    <div className="medium-12 large-12">{this.state.searchMessage}</div>
                    <div className="medium-12 large-12">
                        <div className='errors'>{this.state.errorMsg}</div>
                    </div>
                  
                </div>
                <div className="grid-x">
                    <div className="medium-6">
                        <ScrollArea minScrollSize={40}
                            speed={0.8}
                            className="box"
                            contentClassName="content"
                            horizontal={false} >
                            {this.props.search.isLoading ?
                                <div className="loading loading--is-loading"><div className="loader" />Loading...</div> :
                                this.state.peArray
                            }
                            <div style={{ marginTop: '5px', marginLeft: '5px' }} className="errors">{this.props.search.error}</div>
                        </ScrollArea>
                    </div>
                    <div className="medium-6">
                        <ScrollArea minScrollSize={40}
                            speed={0.8}
                            className="box"
                            contentClassName="content"
                            horizontal={false} >
                            {this.state.isLoading ?
                                <div className="loading loading--is-loading"><div className="loader" />Loading...</div> :
                                this.state.companyDetails
                            }
                            <div style={{ marginTop: '5px', marginLeft: '5px' }} className="errors">{this.props.search.error}</div>
                        </ScrollArea>
                    </div>
                </div>

                <div className="grid-x">
                    <div className="medium-6 popupOkBtn">
                        <div className="float-left">
                            <Button
                                content={this.props.content.clearId}
                                color
                                onClick={this.clearBPID}
                            />
                        </div>
                    </div>
                    <div className="medium-6 popupOkBtn">
                        <div className="float-right">
                            <div className="float-left">
                                <Button
                                    content={this.props.ui.cancel}
                                    color
                                    onClick={this.props.close}
                                />
                            </div>
                            <div className="float-left">
                                <ButtonWithoutLoader
                                    content={this.props.ui.ok}
                                    disabled={!this.state.checked}
                                    color
                                    float
                                    onClick={this.save}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        content: state.content.discovery.form,
        data: state.treeData,
        firstSearchResult: state.searchData,
        search: state.searchResultData,
        ui: state.content.ui,
        company: state.content.capitalDetails,
        currencySymbols: state.content.CurrencySymbols,
        userData: state.valueStory,
        countryList: state.content.CountryList,
        capIQSearchData: state.capIQSearchTree,
        spendDetails: state.spendDetails
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCapIqSearchResults: (value, type, parentFlag, publicFlag, country) => dispatch(fetchCapIqSearchResults(value, type, parentFlag, publicFlag, country)),
        capIqSearchSuccess: (msg) => dispatch(capIqSearchSuccess(msg)),
        capIqSearchFailure: (error) => dispatch(capIqSearchFailure(error)),
        searchResult: (result) => dispatch(searchResult(result)),
        capIQFetchData: (capIQData) => dispatch(capIQFetchData(capIQData)),
        resetError: () => dispatch(resetError()),
        fetchANIdBPIdDetails: (formValues) => dispatch(fetchANIdBPIdDetails(formValues))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BPIdPicker);
