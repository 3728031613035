import React from 'react'
import { connect } from 'react-redux'
import {
  Route,
  NavLink,
  MemoryRouter
} from "react-router-dom";
import UserManagerContainer from "./UserManagerContainer";
import RoleManager from "./RoleManager";
import CapIQLicensedUser from "./CapIQLicensedUser";
import SpendRoleManager from "./SpendRoleManager";
import CustomerAccessManager from "./CustomerAccessManager";

class UserAndRoleManagerContainer extends React.Component {
  
  render() {
    return (
      <MemoryRouter>
      {/* <div className="grid-container pt40"> */}
      <div className="pt40" style={{ paddingLeft:'30px'}}>
        <div className="medium-12 page-title">{this.props.content.title}</div>
        <ul className="headers mt20">
            <li><NavLink exact to="/">{this.props.content.userManagerTitle}</NavLink></li>
            <li><NavLink to="/RoleManager">{this.props.content.roleManagerTitle}</NavLink></li>
            <li><NavLink to="/CapIQLicensedUser">{this.props.content.capIqLicensedUserTitle}</NavLink></li>            
            <li><NavLink to="/SpendRoleManager">{this.props.content.spendRoleTitle}</NavLink></li>
            <li><NavLink to="/CustomerAccessManager">{this.props.content.customerAccessTitle}</NavLink></li>
        </ul>
        <div className="content">
            <Route exact path="/" component={UserManagerContainer}/>
            <Route path="/RoleManager" component={RoleManager}/>
            <Route path="/CapIQLicensedUser" component={CapIQLicensedUser}/>
            <Route path="/SpendRoleManager" component={SpendRoleManager}/>
            <Route path="/CustomerAccessManager" component={CustomerAccessManager}/>
        </div>         
      </div>
      </MemoryRouter>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    language: state.language,
    content: state.content.userManagerContainer
  }
}

export default connect(mapStateToProps)(UserAndRoleManagerContainer)
