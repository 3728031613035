import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

// Actions
import { toggleAreaOfInterest, addPrioritiesItem, deletePrioritiesItem, initPrioritiesItems } from 'actions/prioritiesActions'
import {
	valueStoryCurrentMenu,
	showValueStoryMenu
}
	from 'actions/userActions'

// UI
import Button from 'UI/Button'

import { routes } from 'Constants'

import DiscoveryInterests from '../Discovery/DiscoveryInterests'

class AreaOfInterestContainer extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            pathname: routes.priorities,
            priorityId: 0,
            vlmAlertDiv: false
        }

        this.dispatchToggleAreaOfInterest = this.dispatchToggleAreaOfInterest.bind(this)
        this.vlmAlertToggle = this.vlmAlertToggle.bind(this)
        
    }

    dispatchToggleAreaOfInterest(id, bool) {
        this.props.toggleAreaOfInterest(id, bool)

        // iPad doesn't like multiple dispatches to store in quick succession
        // and will not re-render the component
        // setTimeout to fix the bug
        setTimeout(() => {
            if (bool) {
                this.props.addPrioritiesItem(id)
            } else {
                this.props.deletePrioritiesItem(id)
            }

        }, 500)
    }

    componentWillMount() {
        this.props.valueStoryCurrentMenu('Priorities');	
		this.props.showValueStoryMenu(true);		        	
        window.scrollTo(0, 0)
    }

    vlmAlertToggle() {
        this.setState(prevState => {return {vlmAlertDiv: !prevState.vlmAlertDiv}})
    }

    render() {
        return (
            <div className="grid-container priorities">
                {/* <div className="grid-x">
                    <div className="link-label cell">
                        {this.props.content.title}/ <span className="font-bold">{this.props.content.areaOfInterestTitle}</span>
                    </div>
                </div> */}
                <div className="grid-x">
                    <div className="medium-12 large-12 bb_e1e1e1">
                        <div className="grid-x">
                            <div className="page-title medium-3">{this.props.content.title}</div>
                            <div onClick={this.vlmAlertToggle} className="medium-8 font-bold hand-cursor" style={{lineHeight:"3", color: "#f0434c", fontSize: "12px"}}>{this.props.discoveryContent.vlmAlertHeader}</div>
                            {<div className='medium-12'>
                                {this.state.vlmAlertDiv ? <span>{this.props.discoveryContent.vlmAlertDesc1}<br /><br />{this.props.discoveryContent.vlmAlertDesc2}<a href="http://valuemanagement.sap.com/" target="_blank">{this.props.discoveryContent.vlmAlertDesc3}</a>.</span> : ''}
                            </div>}
                        </div>
                        <div className="page-sub-title">{this.props.content.subhead}</div>
                    </div>
                </div>
                <div className="grid-x">
                    <div className="medium-12 large-12">
                        <div className="interest_title">{this.props.content.areaOfInterestTitle}</div>
                        <div className="interest_subtitle">{this.props.content.areaOfInterestTitleSubhead}</div>
                    </div>
                </div>
                <div className="grid-x">
                    <div className="medium-12 large-12">
                        <DiscoveryInterests
                            active={true}
                            dispatchToggleAreaOfInterest={this.dispatchToggleAreaOfInterest}
                            content={this.props.discoveryContent.interests}
                            userData={this.props.userData.AreaOfInterest}
                        />
                    </div>
                </div>
                <div className="grid-x">
                    <div className="medium-12">
                        {
                            <Link to={{
                                pathname: this.props.priorities.length ? routes.priorities : '',
                                state: {
                                    priorityId: this.props.priorities.length ? this.props.priorities[0] : false,
                                    firstPriority: true
                                }
                            }}>
                                <button class="button float-right" disabled={!this.props.priorities.length || this.props.priorities.length === 0}  title="Please select at least 1 Area of Interest" type="submit" float >
                                {this.props.ui.next}
                                </button>
                            </Link>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        ui: state.content.ui,
        content: state.content.priorities,
        discoveryContent: state.content.discovery,
        userData: state.valueStory,
        priorities: state.priorities
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggleAreaOfInterest: (id, bool) => dispatch(toggleAreaOfInterest(id, bool)),
        addPrioritiesItem: (id) => dispatch(addPrioritiesItem(id)),
        deletePrioritiesItem: (id) => dispatch(deletePrioritiesItem(id)),
        initPrioritiesItems: (items) => dispatch(initPrioritiesItems(items)),
        valueStoryCurrentMenu: (menuItem) => dispatch(valueStoryCurrentMenu(menuItem)),
        showValueStoryMenu: (menuItem) => dispatch(showValueStoryMenu(menuItem)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AreaOfInterestContainer)