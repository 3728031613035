import React, { Component } from 'react'
import { connect } from 'react-redux'
import Button from 'UI/Button'
import { Control, Form, Errors, actions } from 'react-redux-form'
import moment from 'moment'
import { SelectField, Option } from 'react-mdl-extra'
import { BreakLine } from 'utils/Utils'
import { validators } from 'Constants'
import Textfield from 'react-mdl/lib/Textfield'
import Select from 'react-select';


import {
  getReleaseFeatures,
  addReleaseFeature,
  deleteReleaseFeature,
  updateReleaseFeature,
  publishUnpublishReleaseVersion,
  getLatestPublishedReleaseFeatures,
  getReleaseVersions,
  notificationManagerLoading,
  notificationManagerSuccess,
  notificationManagerFailure,
  resetError
} from 'actions/userActions'

import { dropdownStyles } from 'react-select-css'

var options = [];

class NotificationManager extends Component {
  constructor(props) {
    super(props);

    this.state = {
      releaseData: [],
      isPublish: false,
      releaseIsEditing: false,
      editId: 0,
      textData: '',
      responseMsg: '',
      currentPublishVersion: '',
      dbreleaseVersion: [],
      optionData: [],
      selectedOption: '',
      tempFloating: '',
      isReleaseVersionSelected: ''
    }
    this.loadData = this.loadData.bind(this)
    this.loadData();
    this.loadReleaseVersion = this.loadReleaseVersion.bind(this);
    this.loadReleaseVersion();
    this.OnPublishEvent = this.OnPublishEvent.bind(this);
  }



  componentWillMount() {
    this.loadReleaseVersion();
    this.loadData();
  }

  loadReleaseVersion() {
    this.props.getReleaseVersions()
      .then((response) => {
        var versionData = [];
        this.props.notificationManagerSuccess(response.data.Message)
        for (var i = 0; i < response.data.Result.length; i++) {
          var obj = response.data.Result[i];
          versionData.push(obj);
        }
        this.setState({ dbreleaseVersion: versionData })

        var array = [];
        versionData.forEach((element) => {
          var obj = {};
          obj.label = element.VersionName;
          obj.value = element.ID;
          // obj.className= "option-style"
          array.push(obj);
        });
        options = array;

      }).catch((error) => {
        if (error.stack.includes('Network Error')) {
          this.props.notificationManagerFailure()
        }
      })
  }

  loadData() {
    this.props.getReleaseFeatures()
      .then((response) => {
        var notificationData = [];
        this.props.notificationManagerSuccess(response.data.Message)
        for (var i = 0; i < response.data.Result.length; i++) {
          var obj = response.data.Result[i];
          notificationData.push(obj);
        }
        this.setState({ releaseData: notificationData });
        this.getLatestPublish();
      }).catch((error) => {
        if (error.stack.includes('Network Error')) {
          this.props.notificationManagerFailure()
        }
        else {
          this.props.notificationManagerFailure(error.response.data.Message)
        }
      })
  }

  getLatestPublish(versionName) {
    this.props.getLatestPublishedReleaseFeatures()
      .then((response) => {
        this.props.notificationManagerSuccess(response.data.Message)
        for (var i = 0; i < response.data.Result.length; i++) {
          var obj = response.data.Result[i];
          this.setState({ currentPublishVersion: obj.ReleaseVersionName })

          if (obj.ReleaseVersionName == versionName) {
            this.setState({ isPublish: true })
          } else {
            this.setState({ isPublish: false })
          }
        }
      }).catch((error) => {
        if (error.stack.includes('Network Error')) {
          this.props.notificationManagerFailure(error)
        }
        else {
          // this.props.notificationManagerFailure(error.response.data.Message)
        }
      })
  }

  addReleaseFeatureHandler(formValues) {
    this.props.resetError();
    this.setState({ responseMsg: "" })
    this.props.user.error = "";
    var formData = { ReleaseVersionID: 0, ReleaseFeatureID: 0, ReleaseVersionName: formValues.ReleaseVersionName, ReleaseFeatureName: formValues.ReleaseFeatureName, ReleaseFeatureDescription: formValues.ReleaseFeatureDescription, AddedBy: localStorage.getItem('loggedinUser'), UpdatedBy: localStorage.getItem('loggedinUser') };

    if (formValues.ReleaseVersionName != null && formValues.ReleaseFeatureName != null) {
      this.props.addReleaseFeature(formData)
        .then((response) => {
          this.props.notificationManagerSuccess(response.data.Message)
          this.setState({ responseMsg: response.data.Message })
          this.state.releaseData.push(response.data.Result);
          this.setState(this.state.releaseData);
          this.setState({ responseMsg: response.data.Message })
          this.formDispatch(actions.reset('notificationManager'))
          this.loadData();
          this.loadReleaseVersion();
        }).catch((error) => {
          if (error.stack.includes('Network Error')) {
            this.props.notificationManagerFailure(this.props.validation.networkError)
          }
          else {
            this.props.notificationManagerFailure(error.response.data.Message)
            this.setState({ responseMsg: "" })
          }
        })
    }
    else {
      this.props.notificationManagerFailure(formValues.ReleaseFeatureName + " " + this.props.validation.duplicateFeatureName + " " + formValues.ReleaseVersionName + ".")
    }
  }

  OnPublishEvent() {
    this.props.resetError();
    this.setState({ responseMsg: "" })
    this.props.user.error = "";
    var releaseVersion = this.state.textData.slice();
    if (releaseVersion != null && releaseVersion != "") {
      const result = this.state.isPublish == true ? window.confirm(this.props.validation.confirmUnpublish) : window.confirm(this.props.validation.confirmPublish);
      if (result) {
        var formValues1 = { ReleaseVersionName: releaseVersion, UpdatedBy: localStorage.getItem('loggedinUser'), VersionPublishedBy: localStorage.getItem('loggedinUser'), VersionUnpublishedBy: localStorage.getItem('loggedinUser') };
        this.props.publishUnpublishReleaseVersion(formValues1)
          .then((response) => {
            this.props.notificationManagerSuccess(response.data.Message)
            this.setState({ responseMsg: response.data.Message })
            this.formDispatch(actions.reset('notificationManager'))
            this.loadData();
            this.setState({ currentPublishVersion: "" })
            this.getLatestPublish();
            formValues1 = "";
            this.setState({ textData: "" })
            this.setState({ isPublish: false });
          }).catch((error) => {
            if (error.stack.includes('Network Error')) {
              this.props.notificationManagerFailure(this.props.validation.networkError)
            }
            else {
              this.props.notificationManagerFailure(error.response.data.Message)
              this.setState({ responseMsg: "" })
            }
          })
      }
    }
    else {
      this.props.notificationManagerFailure(this.props.validation.requiredVersion)
      this.setState({ responseMsg: "" })
    }
  };

  rowDeleteHandler(releaseDetails) {
    const result = window.confirm(this.props.validation.confirmDelete);
    this.setState({ responseMsg: "" })
    this.props.user.error = "";
    if (result) {
      var formValues = { ReleaseVersionID: releaseDetails.vid, ReleaseFeatureID: releaseDetails.id, UpdatedBy: localStorage.getItem('loggedinUser') };
      this.props.deleteReleaseFeature(formValues)
        .then((response) => {
          this.props.notificationManagerSuccess(response.data.Message)

          var index = this.state.releaseData.indexOf(releaseDetails);
          this.state.releaseData.splice(index, 1);
          this.setState(this.state.releaseData);
          this.setState({ responseMsg: response.data.Message })
          this.loadData();
        }).catch((error) => {
          if (error.stack.includes('Network Error')) {
            this.props.notificationManagerFailure(this.props.validation.networkError)
          }
          else {
            this.props.notificationManagerFailure(error.response.data.Message)
            this.setState({ responseMsg: "" })
          }
        })
    }
  };


  rowEditHandler(evt) {
    this.setState({ responseMsg: "" })
    this.props.user.error = "";
    var item = {
      name: evt.name,
      desc: evt.desc,
      id: evt.id,
      IsEdit: evt.IsEdit
    };
    var old_relFeatureDetails = this.state.releaseData.slice();
    var newrelFeatureDetails = old_relFeatureDetails.map(function (testRelFeatureDetails) {
      for (var key in testRelFeatureDetails) {
        if (testRelFeatureDetails.ReleaseFeatureID == item.id) {
          testRelFeatureDetails.FeatureName = item.name;
          testRelFeatureDetails.IsEdit = !item.IsEdit;

          if (evt.UpdatedBy !== undefined) {
            testRelFeatureDetails.UpdatedBy = evt.UpdatedBy;
            testRelFeatureDetails.UpdatedOn = evt.UpdatedOn;
          }
          break;
        }
      }
      return testRelFeatureDetails;
    });

    this.setState({ releaseData: newrelFeatureDetails });
  }

  handleUpdateEvent(updateDetails) {
    this.setState({ responseMsg: "" })
    this.props.user.error = "";
    if (updateDetails.length > 0 && (this.isDescDuplicate(updateDetails[0].desc) || this.isDuplicate(updateDetails[0].name))) {
      var item = {

        name: updateDetails[0].name,
        desc: updateDetails[0].desc,
        id: updateDetails[0].id,
        vid: updateDetails[0].vid,
        IsEdit: true,
        UpdatedBy: localStorage.getItem('loggedinUser'),
        UpdatedOn: Date.now
      };

      this.props.updateReleaseFeature(item)
        .then((response) => {
          this.props.notificationManagerSuccess(response.data.Message)
          this.setState({ mymessage: response.data.Message })
          if (response.status === 200) {
            item.UpdatedBy = response.data.Result.UpdatedBy;
            item.UpdatedOn = response.data.Result.UpdatedOn;
            this.rowEditHandler(item)
          }
          this.setState({ releaseIsEditing: false });
          this.setState({ responseMsg: response.data.Message })
          this.loadData();
        }).catch((error) => {
          if (error.stack.includes('Network Error')) {
            this.props.notificationManagerFailure('API service is down. Please contact administrator.')
          }
          else {
            this.props.notificationManagerFailure(error.response.data.Message)
            this.setState({ responseMsg: "" })
          }
        })
    }
  }


  handleCancelEvent(originalDetails) {
    this.setState({ responseMsg: "" })
    this.props.user.error = "";
    var item = {
      id: originalDetails[0].id,
      name: originalDetails[0].name,
      desc: originalDetails[0].desc
    };

    var old_relFeatureDetails = this.state.releaseData.slice();
    var newrelFeaturetDetails = old_relFeatureDetails.map(function (testRelFeatureDetails) {
      for (var key in testRelFeatureDetails) {
        if (testRelFeatureDetails.ReleaseFeatureID === item.id) {
          testRelFeatureDetails["id"] = item.id;
          testRelFeatureDetails["desc"] = item.desc;
          testRelFeatureDetails["IsEdit"] = false;
          break;
        }
      }
      return testRelFeatureDetails;
    });
    this.setState({ releaseData: newrelFeaturetDetails });
  };

  componentWillUnmount() {
    this.formDispatch(actions.reset('notificationManager'))
    this.props.resetError()
  }

  componentDidUpdate() {
    this.formDispatch(actions.change('notificationManager.ReleaseVersionName', this.state.selectedOption));
  }

  isDuplicate(vals) {
    var result = this.state.releaseData.find(data => data.ReleaseFeatureName === vals.trim()) === undefined
    if (result) {
      return result;
    }
    else {
      return result;
    }
  }

  isDescDuplicate(vals) {
    var result = this.state.releaseData.find(data => data.ReleaseFeatureDescription === vals.trim()) === undefined
    if (result) {
      return result;
    }
    else {
      return result;
    }
  }

  onTextChangeEvent(event) {
    this.formDispatch(actions.setErrors('notificationManager.ReleaseVersionName', true))
    this.setState({ textData: event.target.value });
    this.getLatestPublish(event.target.value);
    this.setState({ selectedOption: event.target.value });
    this.setState({ responseMsg: "" });

    var objSelectedOption = options.filter(option => option.label === event.target.value);
    if (objSelectedOption.length > 0) {
      this.setState({ isReleaseVersionSelected: "Selected" });
    }
    else {
      this.setState({ isReleaseVersionSelected: "" });
    }
  }

  onDDLChangeEvent(value) {
    this.setState({ textData: value.label });
    this.getLatestPublish(value.label);
    this.setState({ selectedOption: value.label });
    this.setState({ responseMsg: "" })
    this.setState({ tempFloating: value.label });
    this.setState({ isReleaseVersionSelected: "" });
    //this.setState({ tempFloating: document.getElementById("notificationManager.ReleaseVersionNameDDL").text });
    //this.formDispatch(actions.reset('notificationManager.ReleaseVersionName'))
    this.formDispatch(actions.setErrors('notificationManager.ReleaseVersionName', false))
  }

  onDDLClickEvent = () => {
    this.setState({ isReleaseVersionSelected: 'Selected' });
  }

  onDDLBlurEvent = () => {
    this.setState({ isReleaseVersionSelected: '' });
  }

  handleReportDetailTable(evt) {
    var item = {
      name: evt.target.name,
      id: evt.target.id,
      value: evt.target.value
    };

    var old_reportDetails = this.state.old_reportDetails.slice();
    if (old_reportDetails.length === 0) {
      old_reportDetails = this.state.releaseData.slice();
      this.setState({ old_reportDetails: old_reportDetails });
    }
    let testOfObjects = old_reportDetails;
    var newreportDetails = testOfObjects.map(function (testReportDetails) {
      for (var key in testReportDetails) {
        if (testReportDetails.id === item.id) {
          testReportDetails[key] = item.name;
          testReportDetails["IsEdit"] = false;
          break;
        }
      }
      return testReportDetails;
    });

    this.setState({ releaseData: newreportDetails });

  };



  render() {
    return (
      <div className="popup-content">
        <div className="medium-12 title">{this.props.content.title}</div>
        <Form model="notificationManager" method="post"
          getDispatch={(dispatch) => this.formDispatch = dispatch}
          onSubmit={(formValue) => this.addReleaseFeatureHandler(formValue)}>
          <div className="grid-x grid-padding-x">

            <div className="medium-12 cell success description" style={{ margin: 'auto' }} >
              {this.state.currentPublishVersion != '' ? this.props.validation.currentPublish : ''}  {this.state.currentPublishVersion != '' ? this.state.currentPublishVersion : ''}
            </div>

            <div className="medium-5 cell grid-padding-5" >
              <Control.text
                model="notificationManager.ReleaseVersionName"
                id="notificationManager.ReleaseVersionName"
                validateOn="blur"
                component={Textfield}
                label={this.props.content.releaseversion}
                onChange={this.onTextChangeEvent.bind(this)}
                value={this.state.selectedOption}
                floatingLabel
                validators={{
                  required: validators.required,
                  duplicate: (vals) => this.isDuplicate(vals)
                }} />

              <Errors
                className="errors"
                model="notificationManager.ReleaseVersionName"
                show="touched"
                messages={{
                  required: this.props.validation.required,
                  duplicate: this.props.validation.duplicateReleaseVersion
                }}
              />
            </div>

            <div className="medium-5 cell grid-padding-5" key="test" onClick={this.onDDLClickEvent.bind(this)} onBlur={this.onDDLBlurEvent.bind(this)}>

              {/* <Control.select
                className="role__select"
                model="notificationManager.ReleaseVersionName"
                id="notificationManager.ReleaseVersionName"
                component={SelectField}
                label={this.props.content.selectReleaseVersion}
                onChange={(value) => this.onDDLChangeEvent(value)}
                //validateOn="change" 
                floatingLabel
              >

                {
                  this.state.dbreleaseVersion.map((filter) =>
                    <Option
                      key={filter.ID}
                      value= {filter.VersionName}
                    >{filter.VersionName}</Option>
                  )
                }

              </Control.select> */}
              {
                ((this.state.isReleaseVersionSelected === 'Selected')
                  &&
                  ((this.state.selectedOption.trim().length === this.state.tempFloating.trim().length)
                    &&
                    (this.state.selectedOption !== ''))) ? <label style={{ visibility: 'visible' }} class="css-floatingLabel"
                      for="notificationManager.ReleaseVersionName">{this.props.content.selectReleaseVersion}</label> :
                  <label style={{ visibility: 'hidden' }} class="css-floatingLabel"
                    for="notificationManager.ReleaseVersionName">{this.props.content.selectReleaseVersion}</label>
              }
              <Select
                model="notificationManager.ReleaseVersionName"
                id="notificationManager.ReleaseVersionNameDDL"
                name="notificationManager.ReleaseVersionNameddl"
                styles={dropdownStyles}
                placeholder={this.props.content.selectReleaseVersion}
                value={options.filter(option => option.label === this.state.selectedOption)}
                noOptionsMessage={() => null}
                onChange={(value) => this.onDDLChangeEvent(value)}
                options={options}
                isSearchable={false}
                classNamePrefix="gen-react-select"
                className="gen-react-select-container"
              />

              {/* <Errors
                    className="errors"
                    model="notificationManager.ReleaseVersionName"
                    show="touched"
                    messages={{
                      required: this.props.validation.required
                    }}
                  /> */}

            </div>

            <div className="medium-2 cell pt13" >
              <Button
                onClick={this.OnPublishEvent}
                content={this.state.isPublish == true ? this.props.content.unpublish : this.props.content.publish}
                disabled={this.props.user.loading}
                color />
            </div>

          </div>


          <div className="grid-x grid-padding-x" >

            <div className="medium-5 cell grid-padding-5" >
              <Control.text
                model="notificationManager.ReleaseFeatureName"
                id="notificationManager.ReleaseFeatureName"
                validateOn="blur"
                component={Textfield}
                label={this.props.content.shortdescription}
                disabled={this.props.releaseIsEditing === true
                  && this.props.editId === this.props.data.ID ? false : "disabled"}
                floatingLabel
                validators={{
                  required: validators.required,
                  duplicate: (vals) => this.isDuplicate(vals)
                }} />

              <Errors
                className="errors"
                model="notificationManager.ReleaseFeatureName"
                show="touched"
                messages={{
                  required: this.props.validation.required,
                  duplicate: this.props.validation.duplicateFeaturename
                }}
              />

            </div>

            <div className="medium-5 cell grid-padding-5" >
              <Control.text
                model="notificationManager.ReleaseFeatureDescription"
                id="notificationManager.ReleaseFeatureDescription"
                validateOn="blur"
                component={Textfield}
                label={this.props.content.description}
                disabled={this.props.releaseIsEditing === true
                  && this.props.editId === this.props.data.ID ? false : "disabled"}
                floatingLabel
                validators={{
                  required: validators.required,
                  duplicate: (vals) => this.isDuplicate(vals)
                }} />

              <Errors
                className="errors"
                model="notificationManager.ReleaseFeatureDescription"
                show="touched"
                messages={{
                  required: this.props.validation.required,
                  duplicate: this.props.validation.duplicateFeatureDescription
                }}
              />

            </div>
            <div className="medium-2 cell grid-padding-1 pt13" ><Button
              type="submit"
              content={this.props.content.cta}
              disabled={this.props.user.loading}
              color />
            </div>


          </div>

        </Form>

        <div className="success">{this.state.responseMsg}</div>
        <div className="errors">{this.props.user.error}</div>


        <div className="grid-x" className="medium-11 medium-offset-0">
          <ReleaseFeatureTable
            onReportUpdate={this.handleReportDetailTable.bind(this)}
            onRowDel={this.rowDeleteHandler.bind(this)}
            onRowEdit={this.rowEditHandler.bind(this)}
            onRowUpdate={this.handleUpdateEvent.bind(this)}
            onRowCancel={this.handleCancelEvent.bind(this)}
            releaseEditing={this.state.releaseIsEditing}
            editId={this.state.editId}
            releaseData={this.state.releaseData}
            //updateReleaseData= {this.state.updateReleaseData}
            validation={this.props.validation}
            isDuplicate={this.isDuplicate.bind(this)}
            isDescDuplicate={this.isDescDuplicate.bind(this)}
          />
        </div>

      </div>
    );
  }
}


class ReleaseFeatureTable extends React.Component {
  render() {
    var onReportUpdate = this.props.onReportUpdate;
    var rowDel = this.props.onRowDel;
    var rowEdit = this.props.onRowEdit;
    var rowUpdate = this.props.onRowUpdate;
    // var rowSave = this.props.onRowSave;
    var rowCancel = this.props.onRowCancel;
    var releaseEditing = this.props.releaseEditing;
    var editId = this.props.editId;
    var validation = this.props.validation;
    var isDuplicate = this.props.isDuplicate;
    var isDescDuplicate = this.props.isDescDuplicate;

    var data = this.props.releaseData.map(function (data) {
      return (<ReleaseFeatureRow
        data={data}
        onReportUpdate={onReportUpdate}
        onDelEvent={rowDel.bind(this)}
        onEditEvent={rowEdit.bind(this)}
        onUpdateEvent={rowUpdate.bind(this)}
        onCancelEvent={rowCancel.bind(this)}
        validation={validation}
        isDuplicate={isDuplicate}
        isDescDuplicate={isDescDuplicate}
        releaseIsEditing={releaseEditing}
        editId={editId}
        key={data.id} />)
    });
    return (
      <div className="basic-table">
        <table>
          <thead>
            <tr>
              <th>Version</th>
              <th>Short Description</th>
              <th>Feature Description</th>
              <th>Created By/On</th>
              <th>Last Updated By/On</th>
              <th>  </th>
              <th>  </th>
            </tr>
          </thead>

          <tbody>
            {data} {onReportUpdate}
          </tbody>
        </table>
      </div>
    );
  }
}

class ReleaseFeatureRow extends React.Component {
  constructor(props) {
    super(props);
  }

  onReportUpdate() {
    this.props.onReportUpdate()
  }

  render() {
    return (
      this.props.data.IsEdit ?
        <EditableCell onDomainTableUpdate={this.props.onReportUpdate}
          data={this.props.data}
          cellData={{
            name: this.props.data.ReleaseFeatureName,
            desc: this.props.data.ReleaseFeatureDescription,
            id: this.props.data.ReleaseFeatureID,
            vid: this.props.data.ReleaseVersionID,
            IsEdit: this.props.data.IsEdit

          }}
          onCancelEvent={this.props.onCancelEvent}
          onUpdateEvent={this.props.onUpdateEvent}
          IsEdit={this.props.data.IsEdit}
          validation={this.props.validation}
          isDuplicate={this.props.isDuplicate}
          isDescDuplicate={this.props.isDescDuplicate}
        />
        :
        <NonEditableRow
          data={this.props.data}
          cellData={{
            name: this.props.data.ReleaseFeatureName,
            desc: this.props.data.ReleaseFeatureDescription,
            id: this.props.data.ReleaseFeatureID,
            vid: this.props.data.ReleaseVersionID,
            IsEdit: this.props.data.IsEdit
          }}
          onDelEvent={this.props.onDelEvent}
          onEditEvent={this.props.onEditEvent}
          IsEdit={this.props.data.IsEdit}
        />
    );
  }
}


class EditableCell extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      releaseFeatureDetails: this.props.cellData,
      originalreleaseDetails: this.props.cellData
    };
  }

  onDomainTableUpdate() {
    this.props.onReportUpdate();
  }

  onCancelEvent() {

    this.state.releaseFeatureDetails = [
      {
        id: this.state.originalreleaseDetails.id,
        name: this.state.originalreleaseDetails.name,
        desc: this.state.originalreleaseDetails.desc
      }]
    this.setState({ IsEdit: false });
    this.props.onCancelEvent(this.state.releaseFeatureDetails);
  }

  onUpdateEvent() {
    this.props.onUpdateEvent(this.state.releaseFeatureDetails);
  }

  onChangeEvent(event) {
    var changeItem = [
      {
        id: event.target.id,
        vid: this.props.data.ReleaseVersionID,
        name: event.target.value,
        IsEdit: true,
        desc: this.state.releaseFeatureDetails.desc != null ? this.state.releaseFeatureDetails.desc : this.state.releaseFeatureDetails[0].desc,
      }]

    this.setState({ releaseFeatureDetails: changeItem });
  }

  onDescChangeEvent(event) {
    var changeItem = [
      {
        id: event.target.id,
        vid: this.props.data.ReleaseVersionID,
        name: this.state.releaseFeatureDetails.name != null ? this.state.releaseFeatureDetails.name : this.state.releaseFeatureDetails[0].name,
        IsEdit: true,
        desc: event.target.value
      }]

    this.setState({ releaseFeatureDetails: changeItem });

  }


  isDuplicate(vals) {
    if (vals === undefined) {
      return this.props.isDuplicate("");
    }
    else {
      return this.props.isDuplicate(vals);
    }
  }

  isDescDuplicate(vals) {
    if (vals === undefined) {
      return this.props.isDescDuplicate("");
    }
    else {
      return this.props.isDescDuplicate(vals);
    }
  }



  render() {
    return (
      <tr>
        <td>{this.props.data.ReleaseVersionName}</td>
        <td>
          <Control.text
            model="editReleaseNotification.ReleaseFeatureName"
            component={Textfield}
            defaultValue={this.props.cellData.name}
            id={this.props.cellData.id}
            // value={this.state.releaseFeatureDetails.name}
            onChange={this.onChangeEvent.bind(this)}
            //IsEdit={this.props.cellData.IsEdit}
            validateOn="change"
            validators={{
              required: validators.required,
              duplicate: (vals) => this.isDuplicate(vals)
            }}
            type='name' />
          <Errors
            className="errors"
            model="editReleaseNotification.ReleaseFeatureName"
            show={(field) => field.focus}
            messages={{
              required: this.props.validation.required,
              duplicate: this.props.validation.duplicateFeaturename
            }} />
        </td>

        <td>
          <Control.text
            model="editReleaseNotification.ReleaseFeatureDescription"
            component={Textfield}
            defaultValue={this.props.cellData.desc}
            id={this.props.cellData.id}
            accesskey={this.props.cellData.vid}
            title={this.props.cellData.name}
            // value={this.state.releaseFeatureDetails.desc}
            onChange={this.onDescChangeEvent.bind(this)}
            //IsEdit={this.props.cellData.IsEdit}
            validateOn="change"
            validators={{
              required: validators.required,
              duplicate: (vals) => this.isDescDuplicate(vals)
            }}
            type='desc' />
          <Errors
            className="errors"
            model="editReleaseNotification.ReleaseFeatureDescription"
            show={(field) => field.focus}
            messages={{
              required: this.props.validation.required,
              duplicate: this.props.validation.duplicateFeatureDescription
            }} />
        </td>

        <td>
          <span>{this.props.data.AddedBy}</span><br />
          <span>{moment(this.props.data.AddedDate).format('L')}</span>
        </td>
        <td>
          <span> {this.props.data.UpdatedBy}</span> <br />
          <span>{moment(this.props.data.UpdatedDate).format('L')} </span>
        </td>
        <td>
          <button onClick={this.onUpdateEvent.bind(this)}>Update</button>
        </td>
        <td >
          <button onClick={this.onCancelEvent.bind(this)}>Cancel</button>
        </td>
      </tr>
    );
  }
}


class NonEditableRow extends React.Component {

  onDelEvent() {
    this.props.onDelEvent(this.props.cellData);
  }

  onEditEvent() {
    this.props.onEditEvent(this.props.cellData);
  }

  render() {
    return (
      <tr>
        <td>{this.props.data.ReleaseVersionName}</td>
        <td>
          <span id={this.props.cellData.id}
            name={this.props.cellData.name}
            IsEdit={this.props.cellData.IsEdit}>{this.props.cellData.name}</span>
        </td>
        <td>{this.props.data.FeatureDescription}
          <span id={this.props.cellData.id}
            name={this.props.cellData.desc}
            IsEdit={this.props.cellData.IsEdit}>{this.props.cellData.desc}</span>
        </td>
        <td>
          <span>{this.props.data.AddedBy}</span><br />
          <span>{moment(this.props.data.AddedDate).format('L')}</span>
        </td>
        <td>
          <span> {this.props.data.UpdatedBy}</span> <br />
          <span>{moment(this.props.data.UpdatedDate).format('L')} </span>
        </td>
        <td>
          <button onClick={this.onEditEvent.bind(this)}>Edit</button>
        </td>
        <td>
          <button onClick={this.onDelEvent.bind(this)}>Delete</button>
        </td>
      </tr>
    )
  }
}


const mapStateToProps = (state) => {
  return {
    language: state.language,
    validation: state.content.validation,
    releaseData: state.releaseData,
    user: state.default,
    content : state.content.menu.adminMenuItems[0].notificationManager
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    notificationManagerLoading: (bool) => dispatch(notificationManagerLoading(bool)),
    notificationManagerSuccess: (bool) => dispatch(notificationManagerSuccess(bool)),
    notificationManagerFailure: (error) => dispatch(notificationManagerFailure(error)),
    getReleaseFeatures: (formValues) => dispatch(getReleaseFeatures(formValues)),
    addReleaseFeature: (formValues) => dispatch(addReleaseFeature(formValues)),
    deleteReleaseFeature: (formValues) => dispatch(deleteReleaseFeature(formValues)),
    updateReleaseFeature: (formValues) => dispatch(updateReleaseFeature(formValues)),
    publishUnpublishReleaseVersion: (formValues) => dispatch(publishUnpublishReleaseVersion(formValues)),
    getLatestPublishedReleaseFeatures: (formValues) => dispatch(getLatestPublishedReleaseFeatures(formValues)),
    getReleaseVersions: (formValues) => dispatch(getReleaseVersions(formValues)),
    resetError: () => dispatch(resetError())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationManager)
