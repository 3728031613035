import React from 'react'
import { store } from 'index'
import { convertToMillion, formatThousands } from 'utils/Utils'
import { connect } from 'react-redux'

class ExpandableRow extends React.Component {
    constructor(props) {
        super(props)
        {
            this.state = {
               
            }
            this.onLoad = this.onLoad.bind(this)
            this.handleCollapseChange = this.handleCollapseChange.bind(this)
        }
    }    

    onLoad() {
		const state = store.getState(), 
            enablementWaveData = this.props.tabData // state.content.enablementInsight.enablementWaveDetails

        const target = enablementWaveData[0]    
            
		var collapsibleElement = document.getElementsByClassName("collapsible");

        let childCount, item, childTarget, content
        target.rows.map((data, index) => {
			if (data[0].collapseChild !== undefined) {
				childCount = data[0].collapseChild.length
				item = data[0].content
				let eleIndex, cntIndex
				for (eleIndex = 0; eleIndex < collapsibleElement.length; eleIndex++) {
					var childItem = collapsibleElement[eleIndex].firstChild.innerText;
					if (childItem.toLowerCase() === item.toLowerCase()) {
						for (cntIndex = 0; cntIndex < childCount; cntIndex++) {
							if (cntIndex === 0) { content = collapsibleElement[eleIndex].nextSibling; }
							else {
								content = childTarget.nextSibling;
							}
							if (content && content.style !== undefined) {
								content.style.visibility = 'collapse';
								//content.style.backgroundColor = '#e2e0e040';
								childTarget = content
							}
						}
					}
				}
			}
		})
    }
    
    handleCollapseChange(e) {
		if (e.target.className === 'tableContent') {
			e.currentTarget.classList.toggle("active");
			var target = e.currentTarget;
			if (this.props.content[0].collapseChild !== undefined) {
				this.props.content[0].collapseChild.map(() => {
					var content = target.nextSibling;
					if (content && content.style) {
						if (content.style.visibility === 'collapse' || content.style.visibility === '') {
							content.style.visibility = 'visible';
							content.style.backgroundColor = '#e2e0e040';
						} else {
							content.style.visibility = 'collapse';
						}
						target = target.nextSibling;
					}
				});
			}
		}
	}

    render() {
        return (
            <tr className='contentVisible'>
				{this.props.tabName ===  this.props.content.onboardingWSTabName || this.props.tabName ===  this.props.content.enablementRecomTabName?
				<td className="tableContent column-align-right" width='10%'></td>
				: null }
				
                <td className='tableContent onMouseOver' style={{ cursor: 'pointer' }} width='25%' onClick={() => this.props.showEnablementSupplierDetailsModal(this.props.tableName, this.props.columnDetails.firstColumn, this.props.parentColumnValue, (this.props.tabName === this.props.content.enablementTabName && !this.props.fromGanntChart ? this.props.secondParentColumnValue : this.props.data.content),
					(this.props.tabName === this.props.content.enablementTabName ? this.props.data.content : null) )}>
				<span width='45%' >
				{/* <span className='onMouseOver' style={{ cursor: 'pointer' }} width='45%' > */}
				{this.props.data.content == '*' ? '' : this.props.data.content }
                </span>                                                  
                </td>

				{/* {this.props.tabName ===  this.props.content.onboardingWSTabName ?
				<td className="tableContent column-align-right" width='10%'>aaaaaaaa</td>
				: null } */}

                {this.props.tabName === this.props.content.enablementTabName && !this.props.fromGanntChart ? <td></td> : null}
				<td className="tableContent column-align-right" width='10%'>{formatThousands(this.props.data.data.CNT_VENDOR_NAME)}</td>
				<td className="tableContent column-align-right" width='10%'>{this.props.data.data.CNT_VENDOR_PER.toFixed(2)}%</td>               
				
				{ this.props.tableName === this.props.content.networkMatch.catalogMatchHeader || this.props.tableName === this.props.content.networkMatch.integrationMatchHeader ?
                                    <td className="tableContent column-align-right" width='10%'>{ this.props.data.data.CNT_UNIQ_VENDOR_AN_ID }</td> 
                                    : null
                                    }
                				

				<td className="tableContent column-align-right" width='10%'>{ this.props.currencySymbol} {convertToMillion(this.props.data.data.SUM_AGGR_SPEND, true)}</td>					
                <td className="tableContent column-align-right" width='10%'>{this.props.data.data.SUM_AGGR_SPEND_PER.toFixed(2)}%</td>
                              
                
                <td className="tableContent column-align-right" width='10%'>{formatThousands(this.props.data.data.SUM_SAP_ARIBA_DOC_PROXY)}</td>
                <td className="tableContent column-align-right" width='10%'>{this.props.data.data.SAP_ARIBA_DOC_PROXY_PER.toFixed(2)}%</td>
                
                </tr>
        )
    }
}

const mapStateToProps = state => {
    return {          
        content: state.content.enablementInsight,       
    }
}

export default connect(mapStateToProps) (ExpandableRow)