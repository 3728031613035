import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Control, Form, Errors, actions } from 'react-redux-form'
import Popup from "reactjs-popup";
//import Screen from 'UI/CustomComponents/Screen';

// Actions
import { updateOrganization, addRevenue, updateCurrency, updateCurrencyCode, capIQFetchData, updateBPID, updateANID, updateValueStoryType } from 'actions/discoveryActions'

import {
	getSPCapitalIQData,
	getSPCapitalIQLoading,
	getSPCapitalIQSuccess,
	getSPCapitalIQFailure,
	getCapIQDataBlank,
	fetchIndirectVisibleSolutionInScopeValues,
	FetchUpdateFieldVisibility	
} from 'actions/spendActions'
import {
	fetchUserValueStories,
	fetchValueStories,
	fetchUserSharedValueStories,
	fetchValueStoriesLoading,
	fetchValueStoriesSuccess,
	fetchValueStoriesFailure,
} from 'actions/userActions'
import {
	toggleValueDriver,
	toggleSubLever
} from 'actions/valueDriverActions'
import { updateUserInput } from 'actions/subLeverCalculationActions'

// Common Functions
import { subLeverChange } from 'components/BusinessValue/SubLeverChange'

// Utils
import { round, onChangeRemoveMask, formatThousands, getSubLeverIdObject, subLeverHasTotalSpend, removeMask, isDuplicate } from 'utils/Utils'

// Settings
import { validators, dropdownColourStyles } from 'Constants'

import DiscoveryCapIQSearch from './DiscoveryCapIQSearch';
import DiscoveryVsSearch from './DiscoveryVsSearch'
import { modalStyle, emailmodalContentStyle } from 'Constants'
import { ControlTextE, SelectE, CheckboxE, ScreenE, PopupE } from 'UI/CustomComponents/Controls';
import ANIdPicker from './ANIdPicker'
import BPIdPicker from './BPIdPicker'

const autoHeightModalStyle = Object.assign({}, modalStyle)
autoHeightModalStyle.height = 'auto'
autoHeightModalStyle.width = '30%'

const defaultOptions = [
	{ value: 0, label: "" }];

export class NewValueStory extends PureComponent {

	constructor(props) {
		super(props)

		this.fetchValueStories = this.fetchValueStories.bind(this);
		this.fetchUserSharedValueStories = this.fetchUserSharedValueStories.bind(this);
		this.addCurrencyMask = this.addCurrencyMask.bind(this)
		this.calculateTotalSpendFromRevenue = this.calculateTotalSpendFromRevenue.bind(this)
		this.dispatchAddRevenue = this.dispatchAddRevenue.bind(this)
		this.getTotalSpend = this.getTotalSpend.bind(this)
		this.handleIndustryChange = this.handleIndustryChange.bind(this)
		this.handleLocationChange = this.handleLocationChange.bind(this)
		this.handleCurrencyChange = this.handleCurrencyChange.bind(this)
		this.handleTotalSpendBlur = this.handleTotalSpendBlur.bind(this)
		this.repositionInputCaret = this.repositionInputCaret.bind(this)
		this.capIqRadioButtonClicked = this.capIqRadioButtonClicked.bind(this)
		this.anIdRadioButtonClicked = this.anIdRadioButtonClicked.bind(this)
		this.bpIdRadioButtonClicked = this.bpIdRadioButtonClicked.bind(this)
		this.capIqDataCleared = this.capIqDataCleared.bind(this)
		this.anIdDataCleared = this.anIdDataCleared.bind(this)
		this.bpIdDataCleared = this.bpIdDataCleared.bind(this)
		this.closeCapIqPopUp = this.closeCapIqPopUp.bind(this)
		this.closeANIDPopUp = this.closeANIDPopUp.bind(this)
		this.closeBPIDPopUp = this.closeBPIDPopUp.bind(this)
		this.closeVsListPopUp = this.closeVsListPopUp.bind(this)
		this.selectProductScopeAssignments = this.selectProductScopeAssignments.bind(this)
		this.isZero = this.notZero.bind(this)
		this.value = 0
		this.totalSpendTouched = false
		this.annualRevenueCaret = 0
		this.annualRevenueThousandsSeparatorCount = 0
		this.annualRevenueValue = null
		this.totalSpendCaret = 0
		this.totalSpendThousandsSeparftorCount = 0
		this.totalSpendValue = null
		this.state = {
			industryOption: [],
			selectedIndustry: '',
			selectedIndirectScope: '',
			fieldVisibilityData:[],
			selectedLocation: '',
			locationOption: [],
			currencyOption: [],
			selectedCurrency: '',
			selectedCurrencySymbol: '',
			showFloatingLabelFlag: false,
			selectedRegion: '',
			valueStory: '',
			isValueStoryNameEdit: false,
			organization: '',
			selectedCapIQCompanyId: '',
			open: false,
			closeCapIqPopUp: false,
			test: false,
			transactionEligible : false,
			supplierEligible : false,
			catalogInScope  : false,
			disableCatalogInScope : false,
			unclassifiedEligible: false,
			sCCEligible: false,
			fieldglass: false,
			fieldglassEligible: false,
			OutScopeSuspendedSup: false,
			SearchSupplierWave: false,
			openVSList: false,		
			valueStories: [],
			searchText: '',
			valueStoriesShared: [],
			isLoading: false,
			vsLoad: '',
			isCapIQLicesend: false,
			userValueStoryData: '',
			bpId: '',
			anId: '',
			indirectSolutionScopeOption: [],
			indirectSolutionScopeAll: [],
			directSolutionScopeOption: [],
			valueStoryType: [
				{ value: 'Sales', label: 'Sales Values Story'},
		        {value: 'EnablementExecution', label: 'Enablement Execution Value Story'}
			],
			selectedValueStoryType: '',
			openBPID: false,
			enabledANID_BPID: false,
		}
		this.onTextChangeEvent = this.onTextChangeEvent.bind(this);
		this.onOrganizationChangeEvent = this.onOrganizationChangeEvent.bind(this);
		this.handleCapIQIdChange = this.handleCapIQIdChange.bind(this);
		this.onBPIDChangeEvent = this.onBPIDChangeEvent.bind(this);
		this.onANIDChangeEvent = this.onANIDChangeEvent.bind(this);
	}

	componentWillMount() {
		this.fetchUserValueStories();
		//if (this.props.newValueStory) {
		//this.dispatchAddRevenue(this.props.userData.AnnualRevenue)
		//}

		// Check for user inputted total spend
		const firstSubLeverTotalSpend = this.props.userData.BusinessValueToYou ? this.props.userData.BusinessValueToYou[0].ValueDriver[0].SubLever[0].UserInput[0].UserInput : null

		if (firstSubLeverTotalSpend !== '' && firstSubLeverTotalSpend !== null) this.totalSpendTouched = true

		
		this.setState({ valueStory: this.props.userData.ValueStoryName, organization: this.props.userData.Organization ,
			 bpId: this.props.userData.BPId, anId: this.props.userData.ANId });

		if (this.props.userData.CapIQCompany && this.props.userData.CapIQId) {
			this.setState({ selectedCapIQCompanyId: this.props.userData.CapIQCompany + ' | ' + this.props.userData.CapIQId }, this.callCapIQProcedure);
		}

		if (this.props.userData.Test !== undefined && this.props.userData.Test !== null) {
			this.setState({ test: this.props.userData.Test });
		}

		if ((this.props.userData.Industry !== "" || this.props.userData.Industry !== 0) && this.props.userData.Organization !== "" && this.props.userData.ValueStoryName !== "") {
			this.props.getCapIQDataBlank(false)
		} else {
			this.props.getCapIQDataBlank(true)
		}
	}

	componentDidMount() {
		this.loadData();
		//this.initialFieldValues();
		this.setState({ isCapIQLicesend: this.props.user.user.applicationFeatures.findIndex(a => a.Action === this.props.capIQ.capIQAction) > -1 ? true : false,
		enabledANID_BPID : this.props.user.userprofile !== undefined ? !this.props.user.userprofile.Roles[0].includes("Standard Internal User") : false })
		this.fetchUserSharedValueStories()
		//this.annualRevenueElement = document.getElementById('discovery.AnnualRevenue')
		//this.totalSpendInMillionsElement = document.getElementById('discovery.TotalSpend')

		this.bindIndustryAndLocation();


		if (this.props.content.industries.indexOf(this.props.content.industries[0]) === 0) {
			this.formDispatch(actions.setErrors('discovery.Industry', true))
		} else {
			this.formDispatch(actions.change('discovery.Industry', this.props.content.industries[this.props.userData.Industry]));
			this.formDispatch(actions.setErrors('discovery.Industry', false))
		}

		this.props.userData.TransactionEligible = false;
		this.props.userData.UnclassifiedEligible = false;
		this.props.userData.SCCEligible = false;
		this.props.userData.Fieldglass = false;
		this.props.userData.FieldglassEligible = false;
		this.props.userData.SuppEligible = "No";
		this.props.userData.CatalogScope = "No";
		this.props.userData.OutScopeSuspendedSup = false;
		this.props.userData.SearchSupplierWave = false;

		// set value story type default value 
		this.setState({ selectedValueStoryType: this.state.selectedValueStoryType !== undefined && this.state.selectedValueStoryType !== "" ? this.state.selectedValueStoryType : this.props.userData.ValueStoryType })


		// if (!validators.notZero(this.props.userData.AnnualRevenue) || this.props.userData.AnnualRevenue === '' || this.props.userData.AnnualRevenue === null) {
		// 	this.formDispatch(actions.setErrors('discovery.AnnualRevenue', {
		// 		required: validators.required(this.props.userData.AnnualRevenue) && this.props.validation.required,
		// 		isZero: (vals) => this.notZero(this.props.userData.AnnualRevenue) && this.props.validation.isZero
		// 	}))
		// } else {
		// 	this.formDispatch(actions.setErrors('discovery.AnnualRevenue', false))
		// }
	}

	componentWillUnmount() {

		//Rollback value story updates if user doesn't click on Save button and jump to another screen.
		//This is required for Spend module NOT for Business Case because Save button is present for Spend module only.
		if (!this.props.isNewValueStory) {

			if ((this.props.originalUserData.VSId === this.props.userData.VSId) 
			&& !this.props.compareKeyValuesInObject(this.props.originalUserData, this.props.userData)) {

				if (this.props.userData.Industry !== this.props.originalUserData.Industry)
					this.handleIndustryChange(this.props.content.industries[this.props.originalUserData.Industry])

				if (this.props.userData.Location !== this.props.originalUserData.Location)
					this.handleLocationChange(this.props.content.locations[this.props.originalUserData.Location])

				if (this.props.userData.CapIQId !== this.props.originalUserData.CapIQId)
					this.props.dispatchUpdateCapIQId(this.props.originalUserData.CapIQId)

				if (this.props.userData.Organization !== this.props.originalUserData.Organization)
					this.props.dispatchUpdateOrganization(this.props.originalUserData.Organization)

				if (this.props.userData.ValueStoryName !== this.props.originalUserData.ValueStoryName)
					this.props.dispatchUpdateValueStoryName(this.props.originalUserData.ValueStoryName)

				if (this.props.userData.BPId !== this.props.originalUserData.BPId)
				this.props.dispatchUpdateBPID(this.props.originalUserData.BPId)
				
				if (this.props.userData.ANId !== this.props.originalUserData.ANId)
				this.props.dispatchUpdateANID(this.props.originalUserData.ANId)

				if (this.props.userData.ValueStoryType !== this.props.originalUserData.ValueStoryType)
					this.props.dispatchUpdateValueStoryType(this.props.originalUserData.ValueStoryType)
			}
		}
	}

	fetchUserValueStories() {
		this.props.fetchValueStoriesLoading(true)
		this.props.fetchUserValueStories(this.props.user.user.Id)
			.then((result) => {
				this.setState({userValueStoryData : result.data.Result})
				this.props.fetchValueStoriesSuccess(result)
				for (var i = 0; i < result.data.Result.length; i++) {
					var obj = result.data.Result[i];
					if (obj.Id !== localStorage.getItem('id')) {
						break;
					}
				}
				for (var i = 0; i < result.data.Result.length; i++) {
					var obj = result.data.Result[i];
					if (obj.Id === localStorage.getItem('id')) {
						break;
					}
				}
			}).catch((error) => {
				this.props.fetchValueStoriesFailure(error.response.data.Message)
			})
	} 

	selectProductScopeAssignments(solutionInScope)
	{
		var OutScopeSuspendedSup =  this.state.OutScopeSuspendedSup == false ? "No" : "Yes";
		var SearchSupplierWave = this.state.SearchSupplierWave == false ? "No" : "Yes";
		if(solutionInScope != 'Select Scope')
		{
			var data = this.state.indirectSolutionScopeAll.filter(d => d.SOLUTION_IN_SCOPE_NAME == solutionInScope);
			var disableCatalogInScope = false;
			var CATALOG_SCOPE = false; 

			this.props.userData.TransactionEligible = (data[0].TRAN_AN_SUPP_AUTO_ELIGIBLE == null || data[0].TRAN_AN_SUPP_AUTO_ELIGIBLE == "No" || data[0].TRAN_AN_SUPP_AUTO_ELIGIBLE == false) ? "No" : "Yes";
			this.props.userData.UnclassifiedEligible = (data[0].UNCLASS_SUPP_AUTO_ELIGIBLE == null || data[0].UNCLASS_SUPP_AUTO_ELIGIBLE == "No" || data[0].UNCLASS_SUPP_AUTO_ELIGIBLE == false) ? "No" : "Yes";
			this.props.userData.SCCEligible = (data[0].DIRECT_YES_SUPP_SCC_ELIGIBLE == null || data[0].DIRECT_YES_SUPP_SCC_ELIGIBLE == "No" || data[0].DIRECT_YES_SUPP_SCC_ELIGIBLE == false) ? "No" : "Yes";
			this.props.userData.Fieldglass = (data[0].FLDG_IN_SCOPE == null || data[0].FLDG_IN_SCOPE == "No" || data[0].FLDG_IN_SCOPE == false) ? "No" : "Yes";
			this.props.userData.FieldglassEligible = (data[0].FG_IN_AUTO_ELG == null || data[0].FG_IN_AUTO_ELG == "No" || data[0].FG_IN_AUTO_ELG == false) ? "No" : "Yes";
			this.props.userData.SuppEligible =(data[0].TRANS_SAP_BUSS_NET_SUPP_ELIGIBLE == null || data[0].TRANS_SAP_BUSS_NET_SUPP_ELIGIBLE == "No" || data[0].TRANS_SAP_BUSS_NET_SUPP_ELIGIBLE == false) ? "No" : "Yes";
			this.props.userData.CatalogScope = (data[0].CATALOG_SCOPE == null || data[0].CATALOG_SCOPE == "No" || data[0].CATALOG_SCOPE == false) ? "No" : "Yes";
			this.props.userData.OutScopeSuspendedSup = (data[0].OUT_SCOPE_SUSPENDED_SUPP == null || data[0].OUT_SCOPE_SUSPENDED_SUPP == "No" || data[0].OUT_SCOPE_SUSPENDED_SUPP == false) ? "No" : "Yes";
			this.props.userData.SearchSupplierWave = (data[0].DIRECT_CONNECT_WAVE == null || data[0].DIRECT_CONNECT_WAVE == "No" || data[0].DIRECT_CONNECT_WAVE == false) ? "No" : "Yes";;

			if(solutionInScope == "Supply Chain Collaboration"){
				disableCatalogInScope = true;
				this.props.userData.CatalogScope = "No"
				CATALOG_SCOPE = false;
			}
			else
			{
				disableCatalogInScope = false;
				CATALOG_SCOPE = (data[0].CATALOG_SCOPE == null || data[0].CATALOG_SCOPE == "No" || data[0].CATALOG_SCOPE == false) ? false : true
			}

			this.setState({transactionEligible : (data[0].TRAN_AN_SUPP_AUTO_ELIGIBLE == null || data[0].TRAN_AN_SUPP_AUTO_ELIGIBLE == "No" || data[0].TRAN_AN_SUPP_AUTO_ELIGIBLE == false) ? false : true ,
				unclassifiedEligible: (data[0].UNCLASS_SUPP_AUTO_ELIGIBLE == null || data[0].UNCLASS_SUPP_AUTO_ELIGIBLE == "No" || data[0].UNCLASS_SUPP_AUTO_ELIGIBLE == false) ? false : true,
				sCCEligible: (data[0].DIRECT_YES_SUPP_SCC_ELIGIBLE == null || data[0].DIRECT_YES_SUPP_SCC_ELIGIBLE == "No" || data[0].DIRECT_YES_SUPP_SCC_ELIGIBLE == false) ? false : true,
				fieldglass: (data[0].FLDG_IN_SCOPE == null || data[0].FLDG_IN_SCOPE == "No" || data[0].FLDG_IN_SCOPE == false) ? false : true,
				fieldglassEligible: (data[0].FG_IN_AUTO_ELG == null || data[0].FG_IN_AUTO_ELG == "No" || data[0].FG_IN_AUTO_ELG == false) ? false : true,
				supplierEligible: (data[0].TRANS_SAP_BUSS_NET_SUPP_ELIGIBLE == null || data[0].TRANS_SAP_BUSS_NET_SUPP_ELIGIBLE == "No" || data[0].TRANS_SAP_BUSS_NET_SUPP_ELIGIBLE == false) ? false : true,
				catalogInScope: CATALOG_SCOPE,
				disableCatalogInScope : disableCatalogInScope,
				OutScopeSuspendedSup: (data[0].OUT_SCOPE_SUSPENDED_SUPP == null || data[0].OUT_SCOPE_SUSPENDED_SUPP == "No" || data[0].OUT_SCOPE_SUSPENDED_SUPP == false) ? false : true,
				SearchSupplierWave: (data[0].DIRECT_CONNECT_WAVE == null || data[0].DIRECT_CONNECT_WAVE == "No" || data[0].DIRECT_CONNECT_WAVE == false) ? false : true,
			});
		}
		else
		{
			this.props.userData.TransactionEligible = "No";
			this.props.userData.UnclassifiedEligible = "No";
			this.props.userData.SCCEligible = "No";
			this.props.userData.Fieldglass = "No";
			this.props.userData.FieldglassEligible = "No";
			this.props.userData.SuppEligible = "No";
			this.props.userData.CatalogScope = "No";
			this.props.userData.OutScopeSuspendedSup = OutScopeSuspendedSup;
			this.props.userData.SearchSupplierWave = SearchSupplierWave;

			this.setState({transactionEligible : false ,
				unclassifiedEligible: false,
				sCCEligible: false,
				fieldglass: false,
				fieldglassEligible:false,
				supplierEligible:false,
				catalogInScope:false,
				OutScopeSuspendedSup: OutScopeSuspendedSup,
				SearchSupplierWave: SearchSupplierWave
			});
		}
	}

	loadData() {

		var array = [
			{ label: 'Total Invoice spend', value: 'Total Invoice spend' },
			{ label: 'Total Invoice Count', value: 'Total Invoice Count' },
			{ label: 'Total Purchase Order Spend', value: 'Total Purchase Order Spend' },
			{ label: 'Total Purchase Order Count', value: 'Total Purchase Order Count' },
		]

		const group = { defaultValue: undefined, groupLabel: 'CapIqId', groupName: 'CapIqId' }


		/////// Load Indirect Solution in scope dropdown value ////////////////////
        this.props.fetchIndirectVisibleSolutionInScopeValues().then((response) => {
			// Fetch Admin Field Visibility
            var data=[{READ_WRITE_FLAG : "R", SCREEN_FLAG : "N", TAB_NAME: "Spend Parameters"}];    
            this.props.FetchUpdateFieldVisibility(data).then((response) => {
                if (response.status === 200) {
                    this.setState({fieldVisibilityData : response.data.Result});
                }
            });

            if (response.status === 200) {
                if (response.data.Result.length > 0) {
                    
                    var indirectScopeValues=[];
					indirectScopeValues.push({ label: 'Select Scope', value: 'Select Scope' });					

                    if (response.data.Result !== null) {
                        response.data.Result.forEach(item => {
                            let value = Object.values(item)
							if(item.SOLUTION_IN_SCOPE_VALUE == 'Yes')
                            	indirectScopeValues.push({ label: item.SOLUTION_IN_SCOPE_NAME, value: item.SOLUTION_IN_SCOPE_NAME });							
                        });
                    }   
					
					let indirectScope = this.props.userData.IndirectSolutionScope//'Commerce Automation';
					let directScope = this.props.userData.DirectSolutionScope;								

                    this.setState({ indirectSolutionScopeOption: indirectScopeValues,selectedIndirectScope : indirectScope,indirectSolutionScopeAll : response.data.Result },()=>{this.handleIndirectScopeChange(indirectScope)});
                }
			}
            
        }).catch((error) => {
            //this.setState({ responseMessage: this.props.content.errorMessage, isLoading: false, isError: true });
        })
        ///////////////////

		this.setState({
			group: group,
			items: array
		})

	}
	
	handleIndirectScopeChange(value) {
		this.selectProductScopeAssignments(value);
		this.props.dispatchIndirectScopeChange(this.props.content.indirectScopes.indexOf(value));
		this.props.userData.indirectScopeValues = value;
		this.setState({ selectedIndirectScope : value });
		this.formDispatch(actions.change('discovery.IndirectScope', value));
		if (value === 'Select Scope') {
			this.formDispatch(actions.setErrors('discovery.IndirectScope', true))
			this.formDispatch(actions.change('discovery.IndirectScope', ''));
		} else {
			this.formDispatch(actions.setErrors('discovery.IndirectScope', false))
		}	
	}	
	
	handleIndustryChange(value) {
		this.props.dispatchIndustryChange(this.props.content.industries.indexOf(value))
		const self = this;
		setTimeout(() => {
			if (!self.props.newValueStory) {
				let arr = []
				arr.push('4.3')
				arr.push('4.4')
				subLeverChange('', arr)
			}
			if (!this.totalSpendTouched) this.props.dispatchUpdateTotalSpend(this.calculateTotalSpendFromRevenue())
		}, 1)

		this.setState({ selectedIndustry : value });
		this.formDispatch(actions.change('discovery.Industry', value));


		if (this.props.content.industries.indexOf(this.props.content.industries[0]) === 0) {
			this.formDispatch(actions.setErrors('discovery.Industry', true))
			this.formDispatch(actions.change('discovery.Industry', ''));
		} else {
			this.formDispatch(actions.setErrors('discovery.Industry', false))
		}

		// added to make other spend tab disabled when this field are empty
		if (value !== this.props.content.industries[0] && this.props.userData.Organization !== "" && this.props.userData.ValueStoryName !== "") {
			this.props.getCapIQDataBlank(false)
		} else {
			this.props.getCapIQDataBlank(true)
		}

	}

	handleLocationChange(value) {
		var result = this.props.content.regions.find(region => region.Country.toLowerCase() == value.toLowerCase())
		this.props.dispatchLocationChange(this.props.content.locations.indexOf(value), result.Region)
		//this.getCurrency(this.props.content.locations.indexOf(value))

		setTimeout(() => {
			if (!this.totalSpendTouched) this.props.dispatchUpdateTotalSpend(this.calculateTotalSpendFromRevenue())
		}, 1)

		this.setState({ selectedLocation: value });


	}

	handleCurrencyChange(value) {
		var result = this.state.currencyOption.find(currency => currency.value == value.value)
		//this.props.userData.CurrencyCode = value.value;
		//this.props.userData.Currency = this.props.currencySymbols[value.value];

		this.setState({ selectedCurrency: value });
		this.setState({ selectedCurrencySymbol: this.props.currencySymbols[value.value] });
		this.getCurrency(value);

		this.showModal("currencychange");
	}

	dispatchAddRevenue(revenue) {

		this.props.addRevenue(revenue)

		setTimeout(() => {
			if ((this.props.userData.TotalSpend === '' || this.props.userData.TotalSpend === null) || !this.totalSpendTouched)
				this.props.dispatchUpdateTotalSpend(this.calculateTotalSpendFromRevenue())

			this.repositionInputCaret('discovery.AnnualRevenue', this.annualRevenueCaret, this.annualRevenueThousandsSeparatorCount, this.props.userData.AnnualRevenue)
		}, 1)
	}

	calculateTotalSpendFromRevenue() {
		// Calculate Total Spend based on
		// Annual Revenue and Spend as Percentage of Revenue
		// if Annual Revenue is not empty
		if (this.props.userData.AnnualRevenue === '' || this.props.userData.AnnualRevenue === null) {
			return ''
		} else {
			//const totalSpend = round(this.props.spendAsPercentageOfRevenue / 100 * this.props.userData.AnnualRevenue, 2)
			const totalSpend = round(this.props.spendAsPercentageOfRevenue * this.props.userData.AnnualRevenue, 2)
			return totalSpend
		}
	}

	getTotalSpend(e) {
		this.totalSpendTouched = true
		const handleChange = this.props.handleChange

		let value = onChangeRemoveMask(e, this.state.selectedCurrencySymbol)

		// Total Spend user input is empty string or null
		if (!this.props.newValueStory && (value !== '' || value !== null)) {
			// Update Total Spend rows for all sub levers
			this.props.subLeverCalculationContent.forEach(function (obj) {

				const subLever = getSubLeverIdObject(obj.id)

				if (subLeverHasTotalSpend(obj.id)) {
					handleChange(value, subLever, 0)
				}
			})
		}

		// Update Total Spend in state
		this.props.dispatchUpdateTotalSpend(value)

		// Update controlled input
		this.props.dispatchTotalSpendChange(value)

		// add is-dirty state
		let el = document.getElementById("discovery.TotalSpend"),
			parent = el.parentNode
		parent.className += ' is-dirty'

		setTimeout(() => {
			// Update caret position
			if (this.totalSpendInMillionsElement === document.activeElement) {
				this.repositionInputCaret('discovery.TotalSpend', this.totalSpendCaret, this.totalSpendThousandsSeparatorCount, this.props.userData.TotalSpend)
			}
		}, 1)
	}

	handleTotalSpendBlur(e) {
		const handleChange = this.props.handleChange
		let value = onChangeRemoveMask(e, this.state.selectedCurrencySymbol)

		// Total Spend user input is empty string or null
		if (value === '' || value === null) {
			// Use default calculated Total Spend (Revenue * Industry BM percentage)
			value = this.calculateTotalSpendFromRevenue()


			// Clear Total Spend rows for all sub levers
			if (!this.props.newValueStory) {
				this.props.subLeverCalculationContent.forEach(function (obj) {

					const subLever = getSubLeverIdObject(obj.id)

					if (subLeverHasTotalSpend(obj.id)) {
						handleChange(null, subLever, 0)
					}
				})
			}
		}

		// Update Total Spend in state
		this.props.dispatchUpdateTotalSpend(value)

		// Update controlled input
		this.props.dispatchTotalSpendChange(value)
	}

	dispatchUpdateCurrency(currency) {
		let encodedCurrency = encodeURIComponent(currency)
		this.props.updateCurrency(encodedCurrency)
	}

	getCurrency(value) {
		this.dispatchUpdateCurrency(this.props.currencySymbols[value.value])
		this.props.updateCurrencyCode(value.value)
	}

	addCurrencyMask(value) {
		if (value === '' || value === null || value === '0') {

			if (this.formDispatch !== undefined) {
				this.formDispatch(actions.setErrors('discovery.AnnualRevenue', {
					required: validators.required(this.props.userData.AnnualRevenue) && this.props.validation.required,
					isZero: (vals) => this.notZero(this.props.userData.AnnualRevenue) && this.props.validation.isZero
				}))
				//this.formDispatch(actions.reset('discovery.AnnualRevenue'))
			}
			return ''
		} else {
			var temp = this.formDispatch !== undefined ? this.formDispatch(actions.setErrors('discovery.AnnualRevenue', false)) : null
			const regex = /\d*\.?\d{0,2}/g
			const filteredValue = regex.exec(value)

			if (filteredValue[0] === '') {
				return this.state.selectedCurrencySymbol + formatThousands(this.value) + ' M'
			} else {
				this.value = filteredValue[0]
				return this.state.selectedCurrencySymbol + formatThousands(this.value) + ' M'
			}
		}
	}

	repositionInputCaret(selector, lastCaretPosition, lastThousandsSeparatorCount, lastValue) {
		// Reposition Annual Revenue cursor after currency mask has been applied
		const el = document.getElementById(selector)
		if (!el) {
			return;
		}
		let val = el.value
		let thousandsSeparatorCount = val.split(",").length - 1
		let length = val.length

		let caretPosition = 0

		// Don't let caret go past Millions postfix
		if (lastCaretPosition >= val.length - 2) {
			el.selectionStart = length - 2
			el.selectionEnd = length - 2
			// If input is not empty
		} else if (el === document.activeElement && (lastValue.indexOf('M') > -1)) {
			if (thousandsSeparatorCount > lastThousandsSeparatorCount) {
				caretPosition = lastCaretPosition + 1
			} else if (thousandsSeparatorCount < lastThousandsSeparatorCount) {
				caretPosition = lastCaretPosition - 1
			} else {
				caretPosition = lastCaretPosition
			}

			el.selectionStart = caretPosition
			el.selectionEnd = caretPosition
			// If input is empty
		} else {
			el.selectionStart = length - 2
			el.selectionEnd = length - 2
		}

		//console.log('component did update', thousandsSeparatorCount, val, el.selectionStart, el.selectionEnd)
	}


	componentWillReceiveProps(nextProps) {
		/*
		Update Caret positions on user input
		 */
		// const val = this.annualRevenueElement.value
		// this.annualRevenueCaret = this.annualRevenueElement.selectionStart
		// this.annualRevenueThousandsSeparatorCount = val.split(",").length - 1
		// this.annualRevenueValue = val

		// const val2 = this.totalSpendInMillionsElement.value
		// this.totalSpendCaret = this.totalSpendInMillionsElement.selectionStart
		// this.totalSpendThousandsSeparatorCount = val2.split(",").length - 1
		// this.totalSpendValue = val2

		//console.log('component will receive props', this.annualRevenueThousandsSeparatorCount, el.value, el.selectionStart, el.selectionEnd)


		//bind industry option
		if (this.props.content !== nextProps.content) {
			this.props = nextProps;

			this.bindIndustryAndLocation();
		}
	}

	componentDidUpdate(nextProps, nextState) {
		// if (this.annualRevenueElement === document.activeElement) {
		// 	this.repositionInputCaret('discovery.AnnualRevenue', this.annualRevenueCaret, this.annualRevenueThousandsSeparatorCount, this.annualRevenueValue)
		// }

		// if (this.totalSpendInMillionsElement === document.activeElement) {
		// 	this.repositionInputCaret('discovery.TotalSpend', this.totalSpendCaret, this.totalSpendThousandsSeparatorCount, this.totalSpendValue)
		// }

		if (this.props !== undefined) {
			if (this.props.content.industries.indexOf(this.props.content.industries[this.props.userData.Industry]) > 0) {
				this.formDispatch(actions.change('discovery.Industry', this.props.content.industries[this.props.userData.Industry]));
			} else {
				this.formDispatch(actions.setErrors('discovery.Industry', true));
				this.formDispatch(actions.reset('discovery.Industry'));
			}

			// if (this.props.userData.AnnualRevenue === '' || this.props.userData.AnnualRevenue === null || this.props.userData.AnnualRevenue === '0') {

			// 	if (this.formDispatch !== undefined) {
			// 		this.formDispatch(actions.setErrors('discovery.AnnualRevenue', {
			// 			required: validators.required(this.props.userData.AnnualRevenue) && this.props.validation.required,
			// 			isZero: (vals) => this.notZero(this.props.userData.AnnualRevenue) && this.props.validation.isZero
			// 		}))
			// 		//this.formDispatch(actions.reset('discovery.AnnualRevenue'))
			// 	}
			// 	return ''
			// }
		}
	}

	bindIndustryAndLocation() {
		//bind industry option
		var array = [];
		this.props.content.industries.forEach((element) => {
			var obj = {};
			obj.label = element;
			obj.value = element;
			array.push(obj);
		});
		this.setState({ industryOption: array });

		// set default value for industry
		this.setState({ selectedIndustry: this.props.content.industries[this.props.userData.Industry] });

		//bind location option		
		var array1 = [];
		this.props.content.locations.forEach((element) => {
			var obj = {};
			obj.label = element;
			obj.value = element;
			array1.push(obj);
		});
		this.setState({ locationOption: array1 });

		//bind currency option		
		var array2 = [];
		let currencyCodes = this.props.content.currencyCodes;
		for (let i = 1; i < currencyCodes.length; i++) {
			let obj = { label: currencyCodes[i], value: currencyCodes[i].split(' - ')[0] }
			array2.push(obj);
		}
		this.setState({ currencyOption: array2 });

		// set default value for location 
		this.setState({ selectedLocation: this.props.content.locations[this.props.userData.Location] });

		var idx = array2.findIndex((element) => {
			return element.value === this.props.userData.CurrencyCode
		})

		if (idx === -1) {
			idx = array2.findIndex((element) => {
				return element.value === 'USD'
			})
		}

		let selectedCurrencySym = this.props.currencySymbols[array2[idx].value];

		this.getCurrency({ label: selectedCurrencySym, value: array2[idx].value })


		//this.dispatchUpdateCurrency(selectedCurrencySym);

		this.setState({ selectedCurrencySymbol: selectedCurrencySym });
		// set default value for currency 
		this.setState({ selectedCurrency: array2[idx] });
	}



	onTextChangeEvent(event) {
		this.setState({ valueStory: event.target.value !== undefined ? event.target.value : this.props.userData.ValueStoryName });
		this.props.userData.ValueStoryName = event.target.value;
		this.setState({ isValueStoryNameEdit: true });

		if ((event.target.value !== undefined && event.target.value !== "") && this.props.userData.Industry !== this.props.content.industries[0]
			&& this.props.userData.Organization !== "") {
			this.props.getCapIQDataBlank(false)
		} else {
			this.props.getCapIQDataBlank(true)
		}
	}

	onOrganizationChangeEvent(event) {
		this.setState({ organization: event.target.value !== undefined ? event.target.value : this.props.userData.Organization });
		this.props.userData.Organization = event.target.value;
		this.props.updateOrganization(this.props.userData.Organization);
		this.setState({ isOrganizationEdit: true });

		if ((event.target.value !== undefined && event.target.value !== "") && this.props.userData.Industry !== this.props.content.industries[0]
			&& this.props.userData.ValueStoryName !== "") {
			this.props.getCapIQDataBlank(false)
		} else {
			this.props.getCapIQDataBlank(true)
		}
	}

	onBPIDChangeEvent(event){
        this.setState({ bpId: event.target.value !== undefined ? event.target.value : this.props.userData.BPId });
		this.props.userData.BPId = event.target.value;
		this.props.updateBPID(this.props.userData.BPId);
	}

	onANIDChangeEvent(event){
		this.setState({ anId: event.target.value !== undefined ? event.target.value : this.props.userData.ANId });
		this.props.userData.ANId = event.target.value;
		this.props.updateANID(this.props.userData.ANId);
	}

	handleCapIQIdChange(event) {
		this.setState({ capIQId: event.target.value !== undefined ? event.target.value : this.props.userData.CapIQId });
		this.props.userData.CapIQId = event.target.value;
		this.setState({ isCapIQIdEdit: true });
	}

	handleValueStoryTypeChange(data){
		this.props.userData.ValueStoryType = data.value
		this.setState({ selectedValueStoryType : data.value})
		this.props.updateValueStoryType(this.props.userData.ValueStoryType)
	}

	notZero(vals) {
		if ((vals !== null && vals !== '' && Number.parseFloat(removeMask(vals, this.state.selectedCurrencySymbol)) === 0)) {
			return false;
		}

		return true;
	}

	closeCapIqPopUp() {
		this.setState({ openCapIq: !this.state.openCapIq })
	}

	closeANIDPopUp() {
		this.setState({ openANID: !this.state.openANID })
	}

	closeBPIDPopUp() {
		this.setState({ openBPID: !this.state.openBPID })
	}

	closeVsListPopUp() {
		this.setState({ openVSList: !this.state.openVSList, vsLoad: '' })
	}

	callCapIQProcedure() {
		let str = this.state.selectedCapIQCompanyId
		let id = str.split('|').pop().trim()
		this.props.dispatchCapIQChange(id)
		// this.props.getSPCapitalIQData(id)
		//     .then((result) => {
		//         this.props.getSPCapitalIQSuccess(result)
		// 		this.props.capIQFetchData(result.data.Result[0])
		//     }).catch((error) => {
		//         this.props.getSPCapitalIQFailure(error.response.data.Message)
		//     })
	}

	capIqDataCleared() {
		var array2 = [];
		let currencyCodes = this.props.content.currencyCodes;
		for (let i = 1; i < currencyCodes.length; i++) {
			let obj = { label: currencyCodes[i], value: currencyCodes[i].split(' - ')[0] }
			array2.push(obj);
		}
		this.setState({ currencyOption: array2 });
		let idx = array2.findIndex((element) => {
			return element.value === 'USD'
		})


		let selectedCurrencySym = this.props.currencySymbols[array2[idx].value];

		this.getCurrency({ label: selectedCurrencySym, value: array2[idx].value })


		//this.dispatchUpdateCurrency(selectedCurrencySym);

		this.setState({ selectedCurrencySymbol: selectedCurrencySym });
		// set default value for currency 
		this.setState({ selectedCurrency: array2[idx] });
		this.props.dispatchCapIQChange('clear')
		this.setState({ capIQId: '', selectedCapIQCompanyId: '', selectedLocation: this.props.content.locations[0], selectedIndustry: this.props.content.industries[0] })
	}

	capIqRadioButtonClicked(data) {
		this.props.userData.CapIQId = data.COMPANY_ID
		this.props.userData.CapIQCompany = data.COMPANY_NAME
		this.props.userData.Organization = data.COMPANY_NAME
		this.bindIndustryAndLocation()
		this.formDispatch(actions.change('discovery.Organization', data.COMPANY_NAME));
		if (this.props.userData.CapIQCompany) {
			this.setState({ selectedCapIQCompanyId: this.props.userData.CapIQCompany + ' | ' + this.props.userData.CapIQId, organization: data.COMPANY_NAME }, this.callCapIQProcedure);
		} else {
			this.setState({ selectedCapIQCompanyId: '' })
		}

		this.props.userData.AnnualRevenue = data.COMPANY_REVENUE ? data.COMPANY_REVENUE + '' : null;
		this.dispatchAddRevenue(this.props.userData.AnnualRevenue)
		//this.props.userData.Location = data.COUNTRY_NAME
		let idx = this.props.content.locations.findIndex(item => data.COUNTRY_NAME.toLowerCase() === item.toLowerCase())
		if (idx !== -1) {
			this.props.userData.Location = idx;
			var result = this.props.content.regions.find(region => region.Country.toLowerCase() == data.COUNTRY_NAME.toLowerCase())
			this.props.dispatchLocationChange(idx, result.Region)
			this.setState({ selectedLocation: this.props.content.locations[idx] });
		} else {
			this.setState({ selectedLocation: this.props.content.locations[0] })
		}

		if (data.AVATAR_INDUSTRY) {
			const industryIdx = this.props.content.industries.findIndex(item => data.AVATAR_INDUSTRY.toLowerCase() === item.toLowerCase());
			if (industryIdx !== -1) {
				this.props.userData.Industry = industryIdx;
				this.formDispatch(actions.change('discovery.Industry', this.props.content.industries[industryIdx]));
				//this.props.dispatchIndustryChange(this.props.content.industries[industryIdx])
				this.setState({ selectedIndustry: this.props.content.industries[industryIdx] });
			} else {
				this.setState({ selectedIndustry: this.props.content.industries[0] });

			}
		}

		if (this.state.organization !== "" && this.props.content.industries[this.props.userData.Industry] !== this.props.content.industries[0]) {
			this.props.getCapIQDataBlank(false)
		} else {
			this.props.getCapIQDataBlank(true)
		}
	}

	anIdRadioButtonClicked(data) {
		this.props.userData.ANId = data.BUYER_ANID	
		this.props.userData.BPId = this.props.userData.BPId === undefined || this.props.userData.BPId === "" ? data.BPID : this.props.userData.BPId	
		this.formDispatch(actions.change('discovery.ANID', data.BUYER_ANID));
		this.formDispatch(actions.change('discovery.BPID', data.BPID));
		this.props.getCapIQDataBlank(false)
		this.setState({ openANID: !this.state.openANID })
	}

	anIdDataCleared() {	
		this.formDispatch(actions.change('discovery.ANID', ''));
		this.props.userData.ANId = ''	
		this.closeANIDPopUp()
	}

	bpIdRadioButtonClicked(data) {
		this.props.userData.BPId = data.BPID
		this.formDispatch(actions.change('discovery.BPID', data.BPID));
		this.props.getCapIQDataBlank(false)
		this.setState({ openBPID: !this.state.openBPID })
	}

	bpIdDataCleared() {	
		this.formDispatch(actions.change('discovery.BPID', ''));
		this.props.userData.BPId = ''	
		this.closeBPIDPopUp()
	}

	showModal(action) {
		this.refs[action].show()
	}

	hideModal(action) {
		this.refs[action].hide()
	}

	selectIsTestChk(e) {
		this.setState({ test: !this.state.test }, () => {
			this.props.userData.Test = this.state.test;
		});
	}

	selectIsSupplierEligibleChk(e) {
		this.setState({ supplierEligible: !this.state.supplierEligible }, () => {
			this.props.userData.SuppEligible = (this.state.supplierEligible == null || this.state.supplierEligible == false) ? "No" : "Yes";
		});
	}
	selectIsCatalogInScopeChk(e) {
		this.setState({ catalogInScope: !this.state.catalogInScope }, () => {
			this.props.userData.CatalogScope = (this.state.catalogInScope == null || this.state.catalogInScope == false) ? "No" : "Yes";
		});
	}

	selectIstransactionEligibleChk(e) {
		this.setState({ transactionEligible: !this.state.transactionEligible }, () => {
			this.props.userData.TransactionEligible = (this.state.transactionEligible == null || this.state.transactionEligible == false) ? "No" : "Yes";
		});
	}

	selectIsunclassifiedEligibleChk(e) {
		this.setState({ unclassifiedEligible: !this.state.unclassifiedEligible }, () => {
			this.props.userData.UnclassifiedEligible = (this.state.unclassifiedEligible == null || this.state.unclassifiedEligible == false) ? "No" : "Yes";
			
		});
	}

	selectIssCCEligibleChk(e) {
		this.setState({ sCCEligible: !this.state.sCCEligible }, () => {
			this.props.userData.SCCEligible = (this.state.sCCEligible == null || this.state.sCCEligible == false) ? "No" : "Yes";
		});
	}

	selectIsfieldglassChk(e) {
		this.setState({ fieldglass: !this.state.fieldglass }, () => {
			this.props.userData.Fieldglass = (this.state.fieldglass == null || this.state.fieldglass == false) ? "No" : "Yes";
		});
	}

	selectIsfieldglassEligibleChk(e) {
		this.setState({ fieldglassEligible: !this.state.fieldglassEligible  }, () => {
			this.props.userData.FieldglassEligible  = (this.state.fieldglassEligible == null || this.state.fieldglassEligible == false) ? "No" : "Yes";
		});
	}

	selectIsOutScopeSupplierChk(e) {
		this.setState({ OutScopeSuspendedSup: !this.state.OutScopeSuspendedSup  }, () => {
			this.props.userData.OutScopeSuspendedSup  = (this.state.OutScopeSuspendedSup == null || this.state.OutScopeSuspendedSup == false) ? "No" : "Yes";
		});
	}
	selectIsincludeSearchSupplierChk(e) {
		this.setState({ SearchSupplierWave: !this.state.SearchSupplierWave  }, () => {
			this.props.userData.SearchSupplierWave  = (this.state.SearchSupplierWave == null || this.state.SearchSupplierWave == false) ? "No" : "Yes";
		});
	}


	fetchUserSharedValueStories() {
		this.props.fetchValueStoriesLoading(true)
		this.setState({ isError: false, message: '', })
		this.props.fetchUserSharedValueStories(this.props.user.user.Id)
			.then((result) => {
				this.props.fetchValueStoriesSuccess(result)
				var obj = [];
				for (var i = 0; i < result.data.Result.length; i++) {

					obj.push(result.data.Result[i])
				}
				let temp = obj;
				temp.forEach((element, i) => {
					element.content = false;
					element.index = i;
				});
				this.setState({ valueStoriesShared: temp })
			}).catch((error) => {
				this.props.fetchValueStoriesFailure(error.response.data.Message)
				this.setState({
					message: error.response.data.Message, isLoading: false, isError: true
				})
			})
	}
	fetchValueStories() {
		let searchText = this.state.organization;
		let prevText = this.state.searchText;
		let sharedVs = this.state.valueStoriesShared;
		if (searchText != prevText) {
			this.props.fetchValueStoriesLoading(true)
			this.setState({ isError: false, message: '', vsLoad: 'true' })
			this.props.fetchValueStories(searchText)
				.then((result) => {
					this.props.fetchValueStoriesSuccess(result)
					var obj = [];
					for (var i = 0; i < result.data.Result.length; i++) {

						obj.push(result.data.Result[i])
					}
					let temp = obj;
					let vsList;
					temp.forEach((element, i) => {
						element.content = false;
						element.index = i;
					});
					if (sharedVs.length > 0) {
						vsList = temp.filter(obj => !sharedVs.filter(xobj => obj.VSID == xobj.VSId).length)
					}
					this.setState({ valueStories: vsList, searchText: searchText, vsLoad: '' })
				}).catch((error) => {
					this.props.fetchValueStoriesFailure(error.response.data.Message)
					this.setState({
						message: error.response.data.Message, isLoading: false, isError: true
					})
				})
		}
	}

	render() {
		var displayInfoDesc = (itemDesc) => {
            return (
                <div>
                    <Popup className="report-tooltip"
                        trigger={<span className='value__information' />}
                        position="bottom right"
                        on="hover"
                    >
                        {itemDesc}
                    </Popup>
                </div>
            )
        };

		return (
			<ScreenE readOnly={!this.props.userData.AllowEdit} lastUpdated={new Date().getTime()}>
				<Form model="discovery" style={{position : 'unset'}} method="post" getDispatch={(dispatch) => this.formDispatch = dispatch}>
				<div className="grid-x medium-12 grid-padding-x">
					<div className="medium-6 cell">
						<div className="grid-x">
							<div className="medium-4 medium-offset-8" style={{ textAlign: 'right', height: '20px' }}>
								{
									this.props.userData.VSId !== undefined ? this.props.content.vsId + ": " + this.props.userData.VSId : null
								}
							</div>
						</div>
						<div className="grid-x">
							<div className="medium-7">
								{this.state.organization}
							</div>
							<div className="medium-5">
								<div className="float-right">
									<div className="float-left width30">
										<CheckboxE checked={this.state.test} onClick={(e) => this.selectIsTestChk(e)} />
									</div>
									<div className="float-left line-height25 test-label">{this.props.content.testValueStory}</div>
								</div>
							</div>
						</div>
					
					<div className="formBg">
						{/* <Form model="discovery" method="post" getDispatch={(dispatch) => this.formDispatch = dispatch}> */}
							<div className="grid-x" key="1">
								<div className="mandatory-text medium-12 large-12">
									<span className="asterisk">*</span> {this.props.content.mandatoryFields}
								</div>
							</div>
							<div className="grid-x" key="2">
								<div className="medium-12 large-12" key="5">
									<div className="label" key="">{this.props.content.valueStoryName} <span className="asterisk">*</span></div>
									<div className="control-wrapper">
										<ControlTextE
											model="discovery.ValueStoryName"
											id="discovery.ValueStoryName"
											value={this.state.valueStory}
											disabled = {this.props.userData.Id !== undefined && this.props.userData.Id !== null && this.props.userData.Id !== this.props.user.user.Id}
											defaultValue={this.state.valueStory}
											onChange={this.onTextChangeEvent}
											validators={{
												required: validators.required
												, duplicate: (vals) => isDuplicate(this.state.userValueStoryData, vals, this.props.userData.VSId)
											}}
											validateOn="change"
										/>
										<Errors
											className="errors"
											model="discovery.ValueStoryName"
											show="touched"
											messages={{
												required: this.props.validation.required
												, duplicate: this.props.validation.duplicateValueStoryName
											}}
										/>
									</div>
								</div>
							</div>
							<div className="grid-x row" key="3">
							{this.state.isCapIQLicesend ?								
								<div className="medium-12 large-12">
									{/* <div className="label">{this.props.content.capIQId}</div>
									<div>
                                       <Popup className="report-tooltip"
                                           trigger={<span className='value__information' />}
                                           position="bottom right"
                                           on="hover"
                                       >
                                           {this.props.content.capIQId}
                                       </Popup>
                                   </div> */}
										<div className="grid-x">
											<div className="label" style={{ paddingTop: '4px' }}>
												{this.props.content.capIQId}
											</div>
											<div >
												<div className="float-left">
													<Popup className="report-tooltip"
														trigger={<span className='value__information' />}
														position="bottom left"
														on="hover"
													>
														{this.props.content.capIQId_helpText}
													</Popup>
												</div>
											</div>
										</div>
										<div className="control-wrapper capIq-popup">
											<ControlTextE
												model="discovery.CapIQId"
												id="discovery.CapIQId"
												value={this.state.selectedCapIQCompanyId}
												defaultValue={this.state.selectedCapIQCompanyId}
												title={this.state.selectedCapIQCompanyId}
												onChange={this.handleCapIQIdChange}
												readOnly={true}
											/>
											{
												<PopupE className="large-popup"
													trigger={
														<img Style="cursor: pointer" className="control-image" width="15" src="/images/search_big_inactive.svg" />}
													position="right center"
													open={this.state.openCapIq}
													onOpen={this.closeCapIqPopUp}
													closeOnDocumentClick
												>
													<div>
														<DiscoveryCapIQSearch
															contentData={this.props.content}
															capIqRadioButtonClicked={this.capIqRadioButtonClicked}
															capIqDataCleared={this.capIqDataCleared}
															close={this.closeCapIqPopUp}
														/>
													</div>
												</PopupE>
											}
										</div>
								</div> : null}
							</div>
							<div className="grid-x row" key="4">
								<div className="medium-12 large-12">
									<div className="label">{this.props.content.company} <span className="asterisk">*</span></div>
									<div className="control-wrapper">
										<ControlTextE
											model="discovery.Organization"
											id="discovery.Organization"
											value={this.props.userData.Organization}
											// defaultValue={this.props.userData.Organization}
											onChange={this.onOrganizationChangeEvent}
											validators={{
												required: validators.required
											}}
											validateOn="change"
										/>
										<Errors
											className="errors"
											model="discovery.Organization"
											show="touched"
											messages={{
												required: this.props.validation.required
											}}
										/>
									</div>
								</div>
							</div>                           

							<div className="grid-x row" key="5">
								<div className="medium-12 large-12">
									<div className="label">{"ANID"} </div>
									<div className="control-wrapper capIq-popup">
									    <ControlTextE
											model="discovery.ANID"
											id="discovery.ANID"
											value={this.props.userData.ANId}
											onChange={this.onANIDChangeEvent}
											disabled = {this.state.enabledANID_BPID} 
											defaultValue={this.props.userData.ANId}										
										/>
											{
												<PopupE className="large-popup" style={{ height:'400px'}}
													trigger={
														<img Style="cursor: pointer" className="control-image" width="15" src="/images/search_big_inactive.svg" />}
													position="right center"
													open={this.state.openANID}
													onOpen={this.closeANIDPopUp}
													closeOnDocumentClick
													disabled= {this.state.enabledANID_BPID}
												>
													<div>
														<ANIdPicker
															contentData={this.props.content}
															anIdRadioButtonClicked={this.anIdRadioButtonClicked}
															clearANID={this.anIdDataCleared}
															close={this.closeANIDPopUp}
														/>
													</div>
												</PopupE>
											}
										
									</div>
								</div>
							</div>

							<div className="grid-x row" key="6">
								<div className="medium-12 large-12">
									<div className="label">{"BPID"} </div>
									<div className="control-wrapper capIq-popup">
									    <ControlTextE
											model="discovery.BPID"
											id="discovery.BPID"
											value={this.props.userData.BPId}
											onChange={this.onBPIDChangeEvent}
											disabled = {this.state.enabledANID_BPID} 
											defaultValue={this.props.userData.ValueStoryCreator}										
										/>
											{
												<PopupE className="large-popup" style={{ height:'400px'}}
													trigger={
														<img Style="cursor: pointer" className="control-image" width="15" src="/images/search_big_inactive.svg" />}
													position="right center"
													open={this.state.openBPID}
													onOpen={this.closeBPIDPopUp}
													closeOnDocumentClick
													disabled= {this.state.enabledANID_BPID}
												>
													<div>
														<BPIdPicker
															contentData={this.props.content}
															bpIdRadioButtonClicked={this.bpIdRadioButtonClicked}
															clearBPID={this.bpIdDataCleared}
															close={this.closeBPIDPopUp}
														/>
													</div>
												</PopupE>
											}
										
									</div>
								</div>
							</div>
											 
							{/* <div className="grid-x row" key="11">
								<div className="medium-12 large-12">
									<div className="label">{this.props.content.indirectScopeLabel}<span className="asterisk">*</span></div>
									<div className="control-wrapper">
										<SelectE
											className="border-less-DD"
											model="discovery.IndirectScope"
											id="discovery.IndirectScope"
											name="discovery.IndirectScope"
											placeholder={this.props.content.indirectScopeLabel}
											value={this.state.indirectSolutionScopeOption.filter(option => option.label === this.state.selectedIndirectScope)}
											noOptionsMessage={() => null}
											onChange={(value) => this.handleIndirectScopeChange(value.label)}
											validators={{ required: (value) => validators.validSelect(this.props.content.indirectScopes.indexOf(value.label)) }}
											validateOn="change"
											options={this.state.indirectSolutionScopeOption}
											isSearchable={false} styles={dropdownColourStyles}
										/>
										<Errors
											className="errors errorsMargin"
											model="discovery.IndirectScope"
											//show="touched"
											messages={{
												required: this.props.validation.required
											}}
										/>
										<ControlTextE
											model="discovery.IndirectScope"
											id="discovery.IndirectScope1"
											hidden
											value={this.state.selectedIndirectScope}
											defaultValue={this.state.selectedIndirectScope}
											validators={{
												required: (value) => validators.validIndirectScope(this.state.selectedIndirectScope)
											}}
											validateOn="change"
										/>
									</div>
								</div>
							</div>							 */}

							<div className="grid-x row" key="7">
								<div className="medium-12 large-12">
									<div className="label">{this.props.content.industriesLabel} <span className="asterisk">*</span></div>
									<div className="control-wrapper">
										<SelectE
											className="border-less-DD"
											model="discovery.Industry"
											id="discovery.Industry"
											name="discovery.Industry"
											placeholder={this.props.content.industriesLabel}
											value={this.state.industryOption.filter(option => option.label === this.state.selectedIndustry)}
											noOptionsMessage={() => null}
											onChange={(value) => this.handleIndustryChange(value.label)}
											validators={{ required: (value) => validators.validSelect(this.props.content.industries.indexOf(value.label)) }}
											validateOn="change"
											options={this.state.industryOption}
											isSearchable={false} styles={dropdownColourStyles}
										/>
										<Errors
											className="errors errorsMargin"
											model="discovery.Industry"
											//show="touched"
											messages={{
												required: this.props.validation.required
											}}
										/>
										<ControlTextE
											model="discovery.Industry"
											id="discovery.Industry1"
											hidden
											value={this.state.selectedIndustry}
											defaultValue={this.state.selectedIndustry}
											validators={{
												required: (value) => validators.validIndustry(this.state.selectedIndustry)
											}}
											validateOn="change"
										/>
									</div>
								</div>
							</div>
							<div className="grid-x row" key="8">
								<div className="medium-12 large-12">
									<div className="label">{this.props.content.locationsLabel} <span className="asterisk">*</span></div>
									<div className="control-wrapper">
										<SelectE
											className="border-less-DD"
											model="discovery.Location"
											id="discovery.Location"
											placeholder={this.props.content.locationsLabel}
											value={this.state.locationOption.filter(option => option.label === this.state.selectedLocation)}
											noOptionsMessage={() => null}
											onChange={(value) => this.handleLocationChange(value.label)}
											options={this.state.locationOption}
											isSearchable={false}
											validators={{
												required: validators.required
											}}
											validateOn="change" styles={dropdownColourStyles}
										/>
										<Errors
											className="errors errorsMargin"
											model="discovery.Location"
											show="touched"
											messages={{
												required: this.props.validation.required
											}}
										/>
									</div>
								</div>
							</div>

							<div  className="grid-x row" key="9">
								<div className="medium-12 large-12">
								<div className="label">{"Value Story Type"} <span className="asterisk">*</span></div>
									<div className="control-wrapper">
										<SelectE
											className="border-less-DD"
											model="discovery.ValueStoryType"
											id="discovery.ValueStoryType"
											placeholder={"Select Value Story Type"}
											value={this.state.valueStoryType.filter(option => option.value === this.state.selectedValueStoryType)}
											noOptionsMessage={() => null}
											onChange={(value) => this.handleValueStoryTypeChange(value)}
											options={this.state.valueStoryType}
											isSearchable={false}
											validators={{
												required: validators.required
											}}
											validateOn="change" styles={dropdownColourStyles}
										/>
										<Errors
											className="errors errorsMargin"
											model="discovery.ValueStoryType"
											show="touched"
											messages={{
												required: this.props.validation.required
											}}
										/>
									</div>
									</div>
								</div>

                            { this.props.userData.VSId !== undefined ?
							<div className="grid-x row" key="10">
								<div className="medium-12 large-12">
									<div className="label">{"Owner"} </div>
									<div className="control-wrapper">
									    <ControlTextE
											model="discovery.ValueStoryCreator"
											id="discovery.ValueStoryCreator"
											value={this.props.userData.ValueStoryCreator}
											disabled = {true} 
											defaultValue={this.props.userData.ValueStoryCreator}										
										/>
										
									</div>
								</div>
							</div>
							: null }							
						{/* </Form> */}
					</div>
					</div>
					
					<div className="medium-6 cell" style={{marginTop : '45px' }}>
					<div className="formBg">
						{/* <div className="grid-x row" key="11">
								<div className="medium-12 large-12">
									<div className="label">{this.props.content.indirectScopeLabel}<span className="asterisk">*</span></div>
									<div className="control-wrapper">
										<SelectE
											className="border-less-DD"
											model="discovery.IndirectScope"
											id="discovery.IndirectScope"
											name="discovery.IndirectScope"
											placeholder={this.props.content.indirectScopeLabel}
											value={this.state.indirectSolutionScopeOption.filter(option => option.label === this.state.selectedIndirectScope)}
											noOptionsMessage={() => null}
											onChange={(value) => this.handleIndirectScopeChange(value.label)}
											validators={{ required: (value) => validators.validSelect(this.props.content.indirectScopes.indexOf(value.label)) }}
											validateOn="change"
											options={this.state.indirectSolutionScopeOption}
											isSearchable={false} styles={dropdownColourStyles}
										/>
										<Errors
											className="errors errorsMargin"
											model="discovery.IndirectScope"
											//show="touched"
											messages={{
												required: this.props.validation.required
											}}
										/>
										<ControlTextE
											model="discovery.IndirectScope"
											id="discovery.IndirectScope1"
											hidden
											value={this.state.selectedIndirectScope}
											defaultValue={this.state.selectedIndirectScope}
											validators={{
												required: (value) => validators.validIndirectScope(this.state.selectedIndirectScope)
											}}
											validateOn="change"
										/>
									</div>
								</div>
							</div> */}

								<div style={{ fontWeight: 'bold' }} className="medium-12 pt15 pl5 title">
									{this.props.contentAP.inScopeSolution}
								</div>
								<div className="medium-12 pt5">
									<div className="grid-x">
										<div className="medium-6 box-title">
											<div className="grid-x grid-padding-x">
												<div className="medium-10">
													{this.props.content.indirectScopeLabel}<span className="asterisk">*</span>
													<Errors
														className="errors errorsMargin"
														model="discovery.IndirectScope"
														//show="touched"
														messages={{
															required: this.props.validation.required
														}}
													/>
													<ControlTextE
														model="discovery.IndirectScope"
														id="discovery.IndirectScope1"
														hidden
														value={this.state.selectedIndirectScope}
														defaultValue={this.state.selectedIndirectScope}
														validators={{
															required: (value) => validators.validIndirectScope(this.state.selectedIndirectScope)
														}}
														validateOn="change"
													/>
												</div>
											</div>
										</div>
										<div className="medium-6 product-box">
											
											<div className="control-wrapper" style={{width : '100%'}}>
												<SelectE
													className="border-less-DD"
													model="discovery.IndirectScope"
													id="discovery.IndirectScope"
													name="discovery.IndirectScope"
													placeholder={this.props.content.indirectScopeLabel}
													value={this.state.indirectSolutionScopeOption.filter(option => option.label === this.state.selectedIndirectScope)}
													noOptionsMessage={() => null}
													onChange={(value) => this.handleIndirectScopeChange(value.label)}
													validators={{ required: (value) => validators.validSelect(this.props.content.indirectScopes.indexOf(value.label)) }}
													validateOn="change"
													options={this.state.indirectSolutionScopeOption}
													isSearchable={false} styles={dropdownColourStyles}
												/>
												</div>
											
										</div>
									</div>
								</div>

								{(this.state.fieldVisibilityData.length > 0 && 
                                this.state.fieldVisibilityData.some(el => el.FIELD_NAME == this.props.contentAP.includeSearchSupplier && el.VISIBLE_FLAG == 'Yes')) ?
                            	<div style={{ fontWeight: 'bold' }} className="medium-12 pt15 pl5 title">
									Enablement Wave: When to enable supplier
								</div>
								:null}
								{(this.state.fieldVisibilityData.length > 0 && 
                                this.state.fieldVisibilityData.some(el => el.FIELD_NAME == this.props.contentAP.includeSearchSupplier && el.VISIBLE_FLAG == 'Yes')) ?                            	
								<div className="medium-12 pt5">
									<div className="grid-x">
										<div className="medium-9 box-title">
											<div className="grid-x grid-padding-x">
												<div className="medium-10">
													{this.props.contentAP.includeSearchSupplier}
												</div>
												<div className="medium-2">
													<div className="float-right pr20">
														{displayInfoDesc(this.props.contentAP.includeSearchSupplierDesc)}
													</div>
												</div>
											</div>
										</div>
										<div className="medium-3 product-box">
											<div className="pt10">
												<CheckboxE onClick={(e) => this.selectIsincludeSearchSupplierChk(e)}
													checked={this.state.SearchSupplierWave} />
											</div>
										</div>
									</div>
								</div>
								:null}

								<div style={{ fontWeight: 'bold' }} className="medium-12 pt15 pl5 title">
									Enablement Recommendations: How to enable supplier
								</div>
								<div className="medium-12 pt5">
									<div className="grid-x">
										<div className="medium-9 box-title">
											<div className="grid-x grid-padding-x">
												<div className="medium-10">
													{this.props.contentAP.supplierEligible}
												</div>
												<div className="medium-2">
													<div className="float-right pr20">
														{displayInfoDesc(this.props.contentAP.supplierEligible)}
													</div>
												</div>
											</div>
										</div>
										<div className="medium-3 product-box">
											<div className="pt10">
												<CheckboxE checked={this.state.supplierEligible} onClick={(e) => this.selectIsSupplierEligibleChk(e)} />
											</div>
										</div>
									</div>

									<div className="grid-x">
										<div className="medium-9 box-title">
											<div className="grid-x grid-padding-x">
												<div className="medium-10">
													{this.props.contentAP.catalogInScope}
												</div>
												<div className="medium-2">
													<div className="float-right pr20">
														{displayInfoDesc(this.props.contentAP.catalogInScope)}
													</div>
												</div>
											</div>
										</div>
										<div className="medium-3 product-box">
											<div className="pt10">
												<CheckboxE disabled={this.state.disableCatalogInScope} checked={this.state.catalogInScope} onClick={(e) => this.selectIsCatalogInScopeChk(e)} />
											</div>
										</div>
									</div>

									<div className="grid-x">
										<div className="medium-9 box-title">
											<div className="grid-x grid-padding-x">
												<div className="medium-10">
													{this.props.contentAP.transactionEligible}
												</div>
												<div className="medium-2">
													<div className="float-right pr20">
														{displayInfoDesc(this.props.contentAP.transactionEligibleDesc)}
													</div>
												</div>
											</div>
										</div>
										<div className="medium-3 product-box">
											<div className="pt10">
												{/* <CheckboxE iconStyle={{ fill: '#008fd3' }} id={this.props.content.transactionEligible}
                                                checked={this.state.fileDetails !== undefined && this.state.fileDetails.transactionEligible !== undefined ? this.state.fileDetails.transactionEligible : false} onClick={(e) => this.handleParameterCheckChanged(e)}
                                                name={this.props.content.transactionEligible} value={this.props.content.transactionEligible} /> */}
												<CheckboxE checked={this.state.transactionEligible} onClick={(e) => this.selectIstransactionEligibleChk(e)} />
											</div>
										</div>
									</div>

									<div className="grid-x">
										<div className="medium-9 box-title">
											<div className="grid-x grid-padding-x">
												<div className="medium-10">
													{this.props.contentAP.unclassifiedEligible}
												</div>
												<div className="medium-2">
													<div className="float-right pr20">
														{displayInfoDesc(this.props.contentAP.unclassifiedEligible)}
													</div>
												</div>
											</div>
										</div>
										<div className="medium-3 product-box">
											<div className="pt10">
												{/* <CheckboxE iconStyle={{ fill: '#008fd3' }} id={this.props.content.unclassifiedEligible}
                                                checked={this.state.fileDetails !== undefined && this.state.fileDetails.unclassifiedEligible !== undefined ? this.state.fileDetails.unclassifiedEligible : false} 
                                                onClick={(e) => this.handleParameterCheckChanged(e)}
                                                name={this.props.content.unclassifiedEligible} 
                                                value={this.props.content.unclassifiedEligible} /> */}
												<CheckboxE checked={this.state.unclassifiedEligible} onClick={(e) => this.selectIsunclassifiedEligibleChk(e)} />
											</div>
										</div>
									</div>
									<div className="grid-x">
										<div className="medium-9 box-title">
											<div className="grid-x grid-padding-x">
												<div className="medium-10">
													{this.props.contentAP.enforcedOutScopeSuppliers}
												</div>
												<div className="medium-2">
													<div className="float-right pr20">
														{displayInfoDesc(this.props.contentAP.enforcedOutScopeSuppliersDesc)}
													</div>
												</div>
											</div>
										</div>
										<div className="medium-3 product-box">
											<div className="pt10">
												<CheckboxE onClick={(e) => this.selectIsOutScopeSupplierChk(e)}
													checked={this.state.OutScopeSuspendedSup} />
											</div>
										</div>
									</div>
								</div>

								{/* ---------------------- */}

								<div style={{ fontWeight: 'bold' }} className="medium-12 pt15 pl5 title">
									{this.props.contentAP.productScope}
								</div>
								<div className="medium-12 pt5">

									<div className="grid-x">
										<div className="medium-9 box-title">
											<div className="grid-x grid-padding-x">
												<div className="medium-10">
													{this.props.contentAP.sCCEligible}
												</div>
												<div className="medium-2">
													<div className="float-right pr20">
														{displayInfoDesc(this.props.contentAP.sCCEligibleDesc)}
													</div>
												</div>
											</div>
										</div>
										<div className="medium-3 product-box">
											<div className="pt10">
												{/* <CheckboxE iconStyle={{ fill: '#008fd3' }} id={this.props.content.sCCEligible} onClick={(e) => this.handleParameterCheckChanged(e)}
                                                checked={this.state.fileDetails !== undefined && this.state.fileDetails.sCCEligible !== undefined ? this.state.fileDetails.sCCEligible : false}
                                                name={this.props.content.sCCEligible} value={this.props.content.sCCEligible} /> */}
												<CheckboxE checked={this.state.sCCEligible} onClick={(e) => this.selectIssCCEligibleChk(e)} />
											</div>
										</div>
									</div>
									<div className="grid-x">
										<div className="medium-9 box-title">
											<div className="grid-x grid-padding-x">
												<div className="medium-10">
													{this.props.contentAP.fieldglass}
												</div>
												<div className="medium-2">
													<div className="float-right pr20">
														{displayInfoDesc(this.props.contentAP.fieldglassDesc)}
													</div>
												</div>
											</div>
										</div>
										<div className="medium-3 product-box">
											<div className="pt10">
												{/* <CheckboxE iconStyle={{ fill: '#008fd3' }} id={this.props.content.fieldglass} onClick={(e) => this.handleParameterCheckChanged(e)}
                                                checked={this.state.fileDetails !== undefined && this.state.fileDetails.fieldglass !== undefined ? this.state.fileDetails.fieldglass : false}
                                                name={this.props.content.fieldglass} value={this.props.content.fieldglass} /> */}
												<CheckboxE checked={this.state.fieldglass} onClick={(e) => this.selectIsfieldglassChk(e)} />
											</div>
										</div>
									</div>
									<div className="grid-x">
										<div className="medium-9 box-title">
											<div className="grid-x grid-padding-x">
												<div className="medium-10">
													{this.props.contentAP.fieldglassEligible}
												</div>
												<div className="medium-2">
													<div className="float-right pr20">
														{displayInfoDesc(this.props.contentAP.fieldglassEligibleDesc)}
													</div>
												</div>
											</div>
										</div>
										<div className="medium-3 product-box">
											<div className="pt10">
												{/* <CheckboxE iconStyle={{ fill: this.state.fileDetails.fieldglass === true ? '#008fd3' : '#f7f7f7' }} id={this.props.content.fieldglassEligible} onClick={(e) => this.handleParameterCheckChanged(e)}
                                                checked={this.state.fileDetails !== undefined && this.state.fileDetails.FIELD_GLASS_ELIGIBLE !== undefined ? this.state.fileDetails.FIELD_GLASS_ELIGIBLE : false}
                                                name={this.props.content.	} value={this.props.content.fieldglassEligible} /> */}
												<CheckboxE checked={this.state.fieldglassEligible} onClick={(e) => this.selectIsfieldglassEligibleChk(e)} />
											</div>
										</div>
									</div>


								</div>

							{/* ----------------------- */}
							</div>						
					</div>
				</div>
				</Form>
			</ScreenE>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.default,
		currency: decodeURIComponent(state.valueStory ? state.valueStory.Currency : state.companyProfileData[0].currencyCode),
		spendAsPercentageOfRevenue: state.spendAsPercentageOfRevenue,
		validation: state.content.validation,
		contentPE: state.contentPE,
		contentAP: state.content.spendAnalysis.accountParameters,
		companyData: state.companyProfileData,
		selectedValueStory: state.selectedValueStory,
		currencySymbols: state.content.CurrencySymbols,
		userData: state.valueStory,
		newValueStory: state.isNewValueStory,
		searchResultData: state.searchResultData,
		capIQ: state.content.spendAnalysis.detailedFile,
		spendDetails: state.spendDetails
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		fetchUserValueStories: (id) => dispatch(fetchUserValueStories(id)),
		fetchValueStoriesLoading: (bool) => dispatch(fetchValueStoriesLoading(bool)),
		fetchValueStoriesSuccess: (valueStories) => dispatch(fetchValueStoriesSuccess(valueStories)),
		fetchValueStoriesFailure: (error) => dispatch(fetchValueStoriesFailure(error)),
		updateOrganization: (revenue) => dispatch(updateOrganization(revenue)),
		addRevenue: (revenue) => dispatch(addRevenue(revenue)),
		updateCurrency: (currency) => dispatch(updateCurrency(currency)),
		updateCurrencyCode: (currencyCode) => dispatch(updateCurrencyCode(currencyCode)),
		toggleValueDriver: (id, bool) => dispatch(toggleValueDriver(id, bool)),
		toggleSubLever: (id, bool) => dispatch(toggleSubLever(id, bool)),
		handleChange: (value, subLeverId, rowNumber) => dispatch(updateUserInput(value, subLeverId, rowNumber)),
		fetchValueStories: (id) => dispatch(fetchValueStories(id)),
		fetchUserSharedValueStories: (id) => dispatch(fetchUserSharedValueStories(id)),
		getSPCapitalIQSuccess: message => dispatch(getSPCapitalIQSuccess(message)),
		getSPCapitalIQFailure: error => dispatch(getSPCapitalIQFailure(error)),
		getSPCapitalIQLoading: bool => dispatch(getSPCapitalIQLoading(bool)),
		getSPCapitalIQData: (CapIQID) => dispatch(getSPCapitalIQData(CapIQID)),
		capIQFetchData: (capIQData) => dispatch(capIQFetchData(capIQData)),
		getCapIQDataBlank: bool => dispatch(getCapIQDataBlank(bool)),
		updateBPID: (data) => dispatch(updateBPID(data)),
		updateANID: (data) => dispatch(updateANID(data)),
		updateValueStoryType: (data) => dispatch(updateValueStoryType(data)),
		fetchIndirectVisibleSolutionInScopeValues: () => dispatch(fetchIndirectVisibleSolutionInScopeValues()),
		FetchUpdateFieldVisibility: (data) => dispatch(FetchUpdateFieldVisibility(data))	
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(NewValueStory)