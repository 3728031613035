import axios from 'axios'
import { ROOT_URL } from 'Constants'

export function searchText(searchText) {
  return {
    type: 'Search-Text',
    payload: searchText
  };
}

export function searchData(searchData) {
  return {
    type: 'Search-Data',
    payload: searchData
  };
}

export function searchResult(searchResult) {
  return {
    type: 'Search-Result',
    payload: searchResult
  };
}

export function crmSearchLoading(bool) {
  return {
    type: 'CRM_SEARCH_LOADING',
    payload: bool
  }
}

export function crmSearchSuccess(message) {
  return {
    type: 'CRM_SEARCH_SUCCESS',
    payload: message
  };
}

export function crmSearchFailure(error) {
  return {
    type: 'CRM_SEARCH_FAILURE',
    payload: error
  };
}
export function fetchCrmResults(value) {
  return (dispatch) => {
    dispatch(crmSearchLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: `${ROOT_URL}/YourSpendFile/AutoSearchResults`,
      data: { "Value": value },
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(crmSearchSuccess(response))
      return response
    })
  }
}

export function capIqSearchLoading(bool) {
  return {
    type: 'CAPIQ_SEARCH_LOADING',
    payload: bool
  }
}

export function capIqSearchSuccess(message) {
  return {
    type: 'CAPIQ_SEARCH_SUCCESS',
    payload: message
  };
}

export function capIqSearchFailure(error) {
  return {
    type: 'CAPIQ_SEARCH_FAILURE',
    payload: error
  };
}
export function fetchCapIqResults(value) {
  return (dispatch) => {
    dispatch(capIqSearchLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: `${ROOT_URL}/YourSpendFile/CapIqIdResults`,
      data: { "Value": value },
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(capIqSearchSuccess(response))
      return response
    })
  }
}

export function fetchCapIqSearchResults(value, type, parentFlag, publicFlag, country) {
  return (dispatch) => {
    dispatch(capIqSearchLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: `${ROOT_URL}/YourSpendFile/CapIqSearchResults`,
      data: { "Value": value, "Type": type, "ParentFlag": parentFlag, "PublicFlag": publicFlag, "Country": country },
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(capIqSearchSuccess(response))
      return response
    })
  }
}

export function treeResult(treeData) {
  return {
    type: 'Tree-Result',
    payload: treeData
  };
}

export function companyProfileData(cpd) {
  return {
    type: 'CompanyProfile-Data',
    payload: cpd
  };
}

export function isNewValueStory(newValueStory) {
  return {
    type: 'NewValueStory',
    payload: newValueStory
  };
}