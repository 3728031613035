import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Form, actions } from 'react-redux-form'


import {
	userDetailsSuccess,
	userDetailsFailure,
	userDetailsLoading
} from 'actions/userActions'

import { updateOrganization, addRevenue, updateCurrency, updateCurrencyCode, capIQFetchData } from 'actions/discoveryActions'

import {
	getSPCapitalIQData,
	getSPCapitalIQLoading,
	getSPCapitalIQSuccess,
	getSPCapitalIQFailure
} from 'actions/spendActions'

// Utils
import { formatThousands, BreakLine, round } from 'utils/Utils'
import { stat } from 'fs';
import VideoHelp from 'components/UI/VideoHelp'

export class DiscoveryCompanyForm extends PureComponent {

	constructor(props) {
		super(props)

		this.addCurrencyMask = this.addCurrencyMask.bind(this)
		this.value = 0
		this.state = {
			valueStory: '',
			isValueStoryNameEdit: false,
			annualRevenue: 0,
			totalSpend: 0,
			currencyCode: '$',
			organization: '',
			showCapIQ: false,
			capIQData: props.capIQData,
			capIQId: ''
		}
	}

	addCurrencyMask(value) {
		const regex = /\d*\.?\d{0,2}/g
		const filteredValue = regex.exec(value)
		let currencySymbol = this.props.currencySymbols[this.props.userData.CurrencyCode];
		if (this.props.userData.CurrencyCode === null) {
			currencySymbol = '$';
		}

		if (filteredValue[0] === '') {
			return currencySymbol + formatThousands(this.value) + ' M'
		}else{
			//this.value = filteredValue[0]
			return currencySymbol + formatThousands(filteredValue[0]) + ' M'
		}

	}

	callCapIQProcedure() {

		let id = this.state.capIQId
		if (id == 'clear') {
			this.setState({ capIQData: [] })
		}

		this.props.getSPCapitalIQData(id)
			.then((result) => {
				this.props.getSPCapitalIQSuccess(result)
				this.props.capIQFetchData(result.data.Result[0])
				this.setState({ capIQData: result.data.Result[0] })
			}).catch((error) => {
				this.props.getSPCapitalIQFailure(error.response.data.Message)
			})
	}

	componentDidMount() {
		if (this.props.newValueStory) {
			let nullArr = [];
			this.setState({ capIQData: nullArr })
		}
		/*const companyData = this.props.companyData ? this.props.companyData[0] : undefined;
		const newValueStory = this.props.selectedValueStory === null || this.props.selectedValueStory === undefined || this.props.selectedValueStory === 0;
		this.setState({
			annualRevenue: newValueStory ? companyData.revenue : this.props.userData.AnnualRevenue,
			totalSpend: newValueStory ? companyData.spend : this.props.userData.totalSpend,
			currencyCode: newValueStory ? companyData.currencyCode : this.props.userData.Currency,
			organization: newValueStory ? companyData.company : this.props.userData.Organization,
		});*/
	}

	// static getDerivedStateFromProps(nextProps, state) {
	//     if (state.capIQData != undefined && nextProps.capIQData.COMPANY_ID != state.capIQData.COMPANY_ID) {
	//         return ({ capIQData: nextProps.capIQData })
	//     }
	// }

	componentWillReceiveProps(nextProps) {
		if (nextProps.capIQId != this.state.capIQId) {
			this.setState({ capIQId: nextProps.capIQId }, this.callCapIQProcedure)
		}
	}

	render() {
		return (
			<div className="discovery-company-form ">

				<Form model="discovery" method="post"
					getDispatch={(dispatch) => this.formDispatch = dispatch}>
					{}

					<div className="form-row discoverySelect-top">
						<div className="grey_bb2 pb10 pb42">
							<span className="page-title">{this.props.content.title}</span>							
							<span style={{ marginTop: '5px' }}>{<VideoHelp screen={{ module: 'Company', screenName: 'Company' }} />}</span>
						</div>
						<p className="lh">{BreakLine(this.props.content.description)}</p>
						<div className="company_name">{BreakLine(this.props.organization)}</div>
						<div className="grid-x grid-padding-x">
							<div className="medium-6 cell react-select-discovery__control">
								<b><p className="headings-new lh">{this.props.content.revenue}</p></b>
								<p className="">{this.addCurrencyMask(this.props.userData.AnnualRevenue)}</p>
							</div>
							<div className="medium-6 cell react-select-discovery__control">
								<b><p className="headings-new lh">{this.props.content.spend}</p></b>
								<p className="">{this.addCurrencyMask(this.props.userData.TotalSpend)}</p>
							</div>
						</div>
						<div className="grid-x grid-padding-x ">
							<div className="capIQ-details">
								<div className="page-title grey_bb2"></div>
								<p className="lh">{this.props.company.sub_header}</p>
								{/* {this.state.showCapIQ ? */}
								{/* <React.Fragment>  */}

								<div className="company_name lh">{this.props.company.header1}</div>

								<div className="grid-x lh">
									<span>{this.state.capIQData.COMPANY_NAME != null ? this.state.capIQData.COMPANY_NAME : '-'}</span><br />
									<span>{this.state.capIQData.COMPANY_ADDRESS != null ? this.state.capIQData.COMPANY_ADDRESS : '-'}</span><br />
									<span>
										<a target="_blank" href={'https://' + this.state.capIQData.WEBPAGE}>
											{this.state.capIQData.WEBPAGE != null ? this.state.capIQData.WEBPAGE : '-'}
										</a>
									</span>
								</div>
								<div className="grid-x lh pt">
									<div className="medium-4 large-4 title"><b>{this.props.company.COMPANY_STATUS}</b><br />
										{this.state.capIQData.COMPANY_STATUS != null ? this.state.capIQData.COMPANY_STATUS : '-'}
									</div>
									<div className="medium-4 large-4 title"><b>{this.props.company.EMPLOYEE_COUNT}</b><br />
										{this.state.capIQData.EMPLOYEE_COUNT != null ? formatThousands(this.state.capIQData.EMPLOYEE_COUNT) : '-'}
									</div>
									<div className="medium-4 large-4 title"><b>{this.props.company.COMPANY_TYPE}</b><br />
										{this.state.capIQData.COMPANY_TYPE != null ? this.state.capIQData.COMPANY_TYPE : '-'}
									</div>
									{/* <div className="medium-4 large-4 title"><b>{this.props.company.WEBPAGE}</b><br />
										<a target="_blank" href={'https://' + this.state.capIQData.WEBPAGE}>
											{this.state.capIQData.WEBPAGE != null ? this.state.capIQData.WEBPAGE : '-'}
										</a>
									</div> */}
								</div>

								<div className="grid-x">
									<div className="medium-12 large-12 company_name lh pt2">{this.props.company.header2}</div>
								</div>
								<div className="grid-x lh">
									{/*  */}
									<div className="medium-4 large-4 title"><b>{this.props.company.TOT_REV}</b><br />
										{this.state.capIQData.TOT_REV != null ? this.addCurrencyMask(this.state.capIQData.TOT_REV) : '-'}
									</div>
									<div className="medium-4 large-4 title"><b>{this.props.company.COGS}</b><br />
										{this.state.capIQData.COGS != null ? this.addCurrencyMask(this.state.capIQData.COGS) : '-'}
									</div>
									<div className="medium-4 large-4 title"><b>{this.props.company.SG_A}</b><br />
										{this.state.capIQData.SG_A != null ? this.addCurrencyMask(this.state.capIQData.SG_A) : '-'}
									</div>
									{/*  */}
								</div>
								<div className="grid-x lh">
									<div className="medium-4 large-4 title"><b>{this.props.company.FISCAL_YEAR}</b><br />
										{this.state.capIQData.FISCAL_YEAR != null ? this.state.capIQData.FISCAL_YEAR : '-'}
									</div>
									<div className="medium-4 large-4 title"><b>{this.props.company.GRO_MARG}</b><br />
										{this.state.capIQData.GRO_MARG != null ? round(this.state.capIQData.GRO_MARG, 2) + ' %' : '-'}
									</div>
									<div className="medium-4 large-4 title"><b>{this.props.company.EBITDA_MARG}</b><br />
										{this.state.capIQData.EBITDA_MARG != null ? round(this.state.capIQData.EBITDA_MARG, 2) + ' %' : '-'}
									</div>

								</div>

								<div className="grid-x lh">
									<div className="medium-4 large-4 title"><b>{this.props.company.INV}</b><br />
										{this.state.capIQData.INV != null ? this.addCurrencyMask(this.state.capIQData.INV) : '-'}
									</div>
									<div className="medium-4 large-4 title"><b>{this.props.company.DIO}</b><br />
										{this.state.capIQData.DIO != null ? round(this.state.capIQData.DIO, 2) : '-'}
									</div>
									<div className="medium-4 large-4 title"><b>{this.props.company.DPO}</b><br />
										{this.state.capIQData.DPO != null ? round(this.state.capIQData.DPO, 2) : '-'}
									</div>

								</div>
								<div className="grid-x lh">
									<div className="medium-4 large-4 title"><b>{this.props.company.DSO}</b><br />
										{this.state.capIQData.DSO != null ? round(this.state.capIQData.DSO, 2) : '-'}
									</div>
									<div className="medium-4 large-4 title"><b>{this.props.company.CCC}</b><br />
										{this.state.capIQData.CCC != null ? round(this.state.capIQData.CCC, 2) : '-'}
									</div>

								</div>


								{/* </React.Fragment> */}
								{/* : <div className="medium-5 large-5 title selectMsg">{'Select a CapIQ ID to display the data'}</div>
							} */}
							</div>

						</div>


					</div>



				</Form>

			</div >
		)
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.default,
		currency: decodeURIComponent('$'),
		validation: state.content.validation,
		companyData: state.companyProfileData,
		selectedValueStory: state.selectedValueStory,
		currencySymbols: state.content.CurrencySymbols,
		userData: state.valueStory,
		organization: state.valueStory.Organization,
		capIQData: state.capIQData,
		newValueStory: state.isNewValueStory,
		company: state.content.capitalDetails,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		userDetailsLoading: (bool) => dispatch(userDetailsLoading(bool)),
		userDetailsSuccess: (bool) => dispatch(userDetailsSuccess(bool)),
		userDetailsFailure: (error) => dispatch(userDetailsFailure(error)),
		getSPCapitalIQSuccess: message => dispatch(getSPCapitalIQSuccess(message)),
		getSPCapitalIQFailure: error => dispatch(getSPCapitalIQFailure(error)),
		getSPCapitalIQLoading: bool => dispatch(getSPCapitalIQLoading(bool)),
		getSPCapitalIQData: (CapIQID) => dispatch(getSPCapitalIQData(CapIQID)),
		capIQFetchData: (capIQData) => dispatch(capIQFetchData(capIQData))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DiscoveryCompanyForm)
