export const translateScreen_DropdownStyle = {
    control: styles => ({ ...styles, boxShadow: "none", 'padding-bottom': '1px', border: 'none', 'border-bottom': '1px solid #008fd3 !important', 'border-radius': '0', backgroundColor: '#f7f7f7 !important' }),
    option: (styles, base, state) => {
        return {
            ...styles,
            cursor: 'pointer',
            backgroundColor: '#fff',
            color: 'black',
            ':hover': {
                backgroundColor: 'lightGrey',
            },
            ':checked': {
                color: 'red !important'
            },
            color: base.isSelected ? '#008fd3!important' : '#666'
        };
    },

};

export const dropdownStyles = {
    control: styles => ({ ...styles, boxShadow: "none", 'padding-bottom': '1px', border: 'none', 'border-bottom': '1px solid #008fd3 !important', 'border-radius': '0', backgroundColor: '#fff' }),
    option: (styles, base, state) => {
      return {
        ...styles,
        cursor: 'pointer',
        backgroundColor: '#fff',
        ':hover': {
          backgroundColor: '#EAEAEA',
        },
        ':checked': {
          color: 'red !important'
        },
        color: base.isSelected ? '#008fd3!important' : '#666!important'
  
      };
    },
  };
  
export const valueStory_discovery_DropdowStyles = {
	control: styles => ({ ...styles, boxShadow: "none", 'padding-bottom': '1px', border: 'none', 'border-bottom': '1px solid #008fd3 !important', 'border-radius': '0', backgroundColor: '#f7f7f7' }),
	option: (styles, base, state) => {
		return {
			...styles,
			cursor: 'pointer',
			top: 'none', //*
			backgroundColor: '#fff',
			color: 'black',
			':hover': {
				backgroundColor: 'lightGrey',
			},
			':checked': {
				color: 'red !important'
			},
			color: base.isSelected ? '#008fd3!important' : '#666'
		};
	},

};
