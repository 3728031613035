import React, { Component } from 'react'
import PropTypes from 'prop-types'

// UI
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

// Utils
import { onChangeRemoveMask, formatThousands, roundFix, round } from 'utils/Utils'
import Modal from 'reboron/OutlineModal'
import { store } from 'index'
import { getSubLeverIdObject } from 'utils/Utils'

import {
	suggestedValueModalStyle,
	suggestedValueContentStyle,
	path, backdropStyle
} from 'Constants'

// Formula
import {
	getSourceValue_New
} from './SubLeverFormula'


const valueStoryModalStyle = Object.assign({}, suggestedValueModalStyle)

class SubLeverCalculationTableRow extends Component {
	constructor(props) {
		super(props)

		this.state = {
			benchMarkSuggestedValueDetails: []
		}

		this.handleInputChange = this.handleInputChange.bind(this)
		this.filterSuggestedValueDetailData = this.filterSuggestedValueDetailData.bind(this)
		this.onLoad = this.onLoad.bind(this)
		this.handleCollapseChange = this.handleCollapseChange.bind(this)
		this.getPlaceholder = this.getPlaceholder.bind(this)
		this.getSuffix = this.getSuffix.bind(this)
	}

	handleInputChange(e) {
		let index = this.props.rowNumber

		this.props.dispatchHandleChange(
			onChangeRemoveMask(e, this.props.currency),
			this.props.subleverId,
			index
		)
	}
	showModal() {
		this.refs.modal.show()
	}

	hideModal() {
		this.refs.modal.hide()
	}
	componentWillMount() {
		//this.props.userData
		this.filterSuggestedValueDetailData(this.props)
	}

	shouldComponentUpdate(nextProps, nextState) {

		if (nextProps !== this.props) {
			this.filterSuggestedValueDetailData(nextProps)
			return true;
		}
		return false;
	}

	filterSuggestedValueDetailData(props) {

		// location is 0 and industry is 3
		var suggestedValuesData = getSourceValue_New(
			3,
			props.country,
			props.country,
			props.industry,
			props.content[0].content
		)
		this.setState({ benchMarkSuggestedValueDetails: suggestedValuesData })

	}

	onLoad() {
		const subLeverIdObject = getSubLeverIdObject(this.props.subleverId)
		const state = store.getState(),
			subLeverCalculationContent = state.content.subLeverCalculations.subLeverCalculation
		// Get target item
		const target = subLeverCalculationContent.find((obj) => {
			return (subLeverIdObject.BVUIdIndex + 1) + '.' + (subLeverIdObject.BVUSVDIdIndex + 1) === obj.id
		})

		var collapsibleElement = document.getElementsByClassName("collapsible");

		let childCount, item, childTarget, content
		target.rows.map((data, index) => {
			if (data[0].collapseChild !== undefined) {
				childCount = data[0].collapseChild.length
				item = data[0].content
				let eleIndex, cntIndex
				for (eleIndex = 0; eleIndex < collapsibleElement.length; eleIndex++) {
					var childItem = collapsibleElement[eleIndex].firstChild.innerText;
					if (childItem.toLowerCase() === item.toLowerCase()) {
						for (cntIndex = 0; cntIndex < childCount; cntIndex++) {
							if (cntIndex === 0) { content = collapsibleElement[eleIndex].previousSibling; }
							else {
								content = childTarget.previousSibling;
							}
							if (content !== undefined && content.style !== undefined) {
								content.style.visibility = 'collapse';
								//content.style.backgroundColor = '#e2e0e040';
								childTarget = content
							}
						}
					}
				}
			}
		})


	}

	handleCollapseChange(e) {
		if (e.target.className === 'content') {
			e.currentTarget.classList.toggle("active");
			var target = e.currentTarget;
			if (this.props.content[0].collapseChild !== undefined) {
				this.props.content[0].collapseChild.map(() => {
					var content = target.previousSibling;
					if (content !== undefined) {
						if (content.style !== undefined) {
							if (content.style.visibility === 'collapse' || content.style.visibility === '') {
								content.style.visibility = 'visible';
								content.style.backgroundColor = '#e2e0e040';
							} else {
								content.style.visibility = 'collapse';
							}
							target = target.previousSibling;
						}
					}
				});
			}
		}
	}

	getPlaceholder() {
		if (this.props.content[1].userInputUOM == 'M') {
			return (this.props.currency)
		}
		else if (this.props.content[1].userInputUOM == '%') {
			return ('%')
		}
		else {
			return ('')
		}
	}

	getSuffix() {
		if (this.props.content[1].userInputUOM == 'M') {
			return ('M')
		}
		else if (this.props.content[1].userInputUOM == '%') {
			return ('%')
		}
		else {
			return ('')
		}
	}

	componentDidMount() {
		this.onLoad()
	}

	render() {
		let calculatedValuesFormulae = this.props.calculatedValuesFormulae;
		let userInput = false;
		if (calculatedValuesFormulae != undefined) { userInput = calculatedValuesFormulae.includes("User Input Value") }
		const prefix =
			(this.props.content[1] &&
				(this.props.content[1].suffix !== '' &&
					this.props.content[1].suffix !== '%')) ||
				this.props.content[1].currency
				? this.props.currency
				: ''

		const cPrefix =
			this.props.content[1].calculatedUOM == 'M'
				? this.props.currency
				: ''
		const cSuffix =
			this.props.content[1].calculatedUOM == 'M'
				? 'M'
				: ''
		const suffix = this.props.content[1] ? this.props.content[1].suffix : ''
		const allowDecimal = !this.props.content[1].integer

		const userInputPlaceholder = this.getPlaceholder();


		const userInputPrefix =
			this.props.content[1].userInputUOM == 'M'
				? this.props.currency
				: ''

		const userInputSuffix = this.getSuffix();

		const numberMask = createNumberMask({
			prefix: userInputPrefix + ' ',
			suffix: ' ' + userInputSuffix,
			allowDecimal: allowDecimal
		})
		const listParametersToShow = this.props.suggestedValuePopup[0]

		var suggestedValueDetails = (props, benchMarkSuggestedValueDetails) => {
			return (
				<div className='suggestedValuePopUp'>
					<button
						onClick={() => this.hideModal()}
						className='suggestedValueClose'
					/>
					<div className="page-title">{props.content[0].content}</div>
					<ul>
						{Object.keys(listParametersToShow).map(function (element) {
							var valueRow = (
								<li>
									<b> {listParametersToShow[element]}{":"}&nbsp;&nbsp;</b>
									<span>
										{
											isNaN(benchMarkSuggestedValueDetails[element]) ? benchMarkSuggestedValueDetails[element]
												: (benchMarkSuggestedValueDetails["UOM"] === "Percentage") ? round(benchMarkSuggestedValueDetails[element] * 100, 2) + " %"
													: round(benchMarkSuggestedValueDetails[element], 2)
										}
									</span>
								</li>
							)
							return valueRow
						})}
					</ul>
				</div>
			)
		}

		return (
			<tr onClick={this.props.content[0].collapseChild !== undefined ? this.handleCollapseChange : null} className={this.props.content[0].collapseChild !== undefined ? 'collapsible' : 'contentVisible'} >
				<td className='content'>
					{this.props.content[0].content}</td>
				<td>
					{
						this.state.benchMarkSuggestedValueDetails.Show_Popup !== undefined && this.state.benchMarkSuggestedValueDetails.Show_Popup === true ?
							<div>
								{prefix}
								&nbsp;
          				  {this.props.suggestedValue
									? formatThousands(round(this.props.suggestedValue, 2))
									: 0}
								&nbsp;
          			   {this.props.content[1] ? this.props.content[1].suffix : ''}
								<span className='value__information' onClick={() => this.showModal()} />
								<Modal
									ref={'modal'} backdropStyle={backdropStyle}
									keyboard={() => this.callback()}
									modalStyle={suggestedValueModalStyle}
									contentStyle={suggestedValueContentStyle}>
									{suggestedValueDetails(this.props, this.state.benchMarkSuggestedValueDetails)}
								</Modal>
							</div>
							:
							<span >
								{prefix}

								{this.props.suggestedValue
									? formatThousands(round(this.props.suggestedValue, 2))
									: 0}
								&nbsp;
								{this.props.content[1] ? this.props.content[1].suffix : ''}
							</span>
					}
				</td>
				<td className={this.props.content[1].noInput && 'no-input'}>
					{!this.props.content[1].noInput && (
						<MaskedInput
							className='masked-input'
							mask={numberMask}
							placeholder={userInputPlaceholder}
							value={this.props.savedData[this.props.rowNumber]}
							onChange={e => this.handleInputChange(e)}
							pattern='[0-9]*'
						/>
					)}
				</td>
				<td>
					{cPrefix}
					{this.props.calculatedValue ? formatThousands(round(this.props.calculatedValue, 2)) : 0}
					&nbsp;
          			{cSuffix}
				</td>
				{this.props.flag ?
					<div className='hideRows'>
						<td />
					</div> :
					<div className='showRows'>
						<td>{userInput ? calculatedValuesFormulae
							:
							this.state.benchMarkSuggestedValueDetails.Show_Popup !== undefined && this.state.benchMarkSuggestedValueDetails.Show_Popup === true || this.state.benchMarkSuggestedValueDetails.Suggested_Value_Description == 'Spend' ?
								calculatedValuesFormulae + ' (' + this.state.benchMarkSuggestedValueDetails.Source + ')' : calculatedValuesFormulae}

						</td>
					</div>

				}
			</tr>
		)
	}
}

SubLeverCalculationTableRow.propTypes = {
	rowNumber: PropTypes.number.isRequired,
	dispatchHandleChange: PropTypes.func.isRequired,
	currency: PropTypes.string.isRequired,
	subleverId: PropTypes.string.isRequired,
	content: PropTypes.array.isRequired,
	suggestedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	calculatedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default SubLeverCalculationTableRow
