import React from 'react'
import { connect } from 'react-redux'
import { Control, Form, Errors, actions } from 'react-redux-form'
// UI
import Textfield from 'react-mdl/lib/Textfield'
import Button from 'UI/Button'
// Settings
import { validators } from 'Constants'
import { SelectField, Option } from 'react-mdl-extra'
import Select from 'react-select';


// Actions
import {
  fetchReports,
  fetchMappings,
  fetchLayoutFiles,
  fetchSizeFiles,
  addReport,
  deleteReport,
  activateReport,
  updateReport,
  userDetailsSuccess,
  userDetailsFailure,
  userDetailsLoading,
  resetError
}
  from 'actions/userActions'

import { dropdownStyles } from 'react-select-css'

var Layoutoptions = [];
var SizeOptions = [];

class ReportManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userIsEditing: false,
      editId: 0,
      options: [],
      sizes: [],
      layout: '',
      size: '',
      reports: [],
      reportId: 0,
      sequence: [], mymessage: '',
      layoutOption: '',
      sizeOption: '',
      isReportLayoutSelected: '',
      isReportSizeFileSelected: ''
    }

    this.loadData = this.loadData.bind(this)
    this.handleLayoutChange = this.handleLayoutChange.bind(this)
    this.handleSizeChange = this.handleSizeChange.bind(this)
  }

  componentWillMount() {
    this.loadData()
    this.loadtemplates()
  }
  loadData() {
    this.props.fetchReports()
      .then((response) => {
        var reportData = [];
        this.props.userDetailsSuccess(response.data.Message)
        for (var i = 0; i < response.data.Result.length; i++) {
          var obj = response.data.Result[i];
          if (obj.Name === "Default") {
            continue;
          }
          else {
            reportData.push(obj);
          }
        }
        this.setState({ reports: reportData })
      }).catch((error) => {
        if (error.stack.includes('Network Error')) {
          this.props.userDetailsFailure()
        }
      })
  }
  loadtemplates() {
    this.props.fetchLayoutFiles()
      .then((response) => {
        var layouts = [''];
        this.props.userDetailsSuccess(response.data.Message)
        for (var i = 0; i < response.data.Result.length; i++) {
          var obj = response.data.Result[i];
          if (obj.Name === "Default") {
            continue;
          }
          else {
            layouts.push(obj.Name);
          }
        }
        this.setState({ options: layouts })
        var array = [];
        this.state.options.forEach((element) => {
          var obj = {};
          obj.label = element;
          obj.value = element;
          array.push(obj);
        });
        if (array.length > 0 && array[0].label === "") array.splice(0, 1);
        Layoutoptions = array;
      }).catch((error) => {
        if (error.stack.includes('Network Error')) {
          this.props.userDetailsFailure()
        }
      })

    this.props.fetchSizeFiles()
      .then((response) => {
        var sizes = [''];
        this.props.userDetailsSuccess(response.data.Message)
        for (var i = 0; i < response.data.Result.length; i++) {
          var obj = response.data.Result[i];
          if (obj.Name === "Default") {
            continue;
          }
          else {
            sizes.push(obj.Name);
          }
        }
        this.setState({ sizes: sizes })
        var array_s = [];
        this.state.sizes.forEach((element) => {
          var obj = {};
          obj.label = element;
          obj.value = element;
          array_s.push(obj);
        });
        if (array_s.length > 0 && array_s[0].label === "") array_s.splice(0, 1);
        SizeOptions = array_s;
      }).catch((error) => {
        if (error.stack.includes('Network Error')) {
          this.props.userDetailsFailure()
        }
      })
  }

  handleRowDel(reportDetails) {
    // const result = window.confirm(this.props.validation.confirmDelete);
    // if (result) {
    var formValues = { Name: reportDetails.name, ID: reportDetails.id };
    var msg = this.props.content.rowDel[0];
    this.setState({ mymessage: "" })
    this.props.deleteReport(formValues)
      .then((response) => {
        this.props.userDetailsSuccess(response.data.Message)

        if (response.status === 200) {
          var httpstatus = response.status
          var getMsgfromStatus = msg[httpstatus]
          this.loadData()
          this.setState({ mymessage: getMsgfromStatus })
        }
      }).catch((error) => {
        if (error.stack.includes('Network Error')) {
          this.setState({ mymessage: "" })
          this.props.userDetailsFailure(this.props.validation.networkError)
        }
        else {
          var httpstatus = error.response.status
          var getMsgfromStatus = msg[httpstatus]
          if (getMsgfromStatus !== undefined) {
            this.setState({ mymessage: "" })
            this.props.userDetailsFailure(getMsgfromStatus)
          }
          else {
            this.setState({ mymessage: "" })
            this.props.userDetailsFailure(error.response.data.Message)
          }
        }
      })
    // }
  };

  handleActivate(reportDetails) {
    var formValues = { UpdatedBy: localStorage.getItem('loggedinUser'), Name: reportDetails.name, ID: reportDetails.id, Active: true, Description: reportDetails.description };
    var msg = this.props.content.rowAct[0];
    this.setState({ mymessage: "", reportId: formValues.ID })
    this.props.fetchMappings()
      .then((response) => {
        var sectionData = [];
        this.props.userDetailsSuccess(response.data.Message)
        for (var i = 0; i < response.data.Result.length; i++) {
          var obj = response.data.Result[i];
          if (obj.ReportID == this.state.reportId) {
            sectionData.push(obj);
          }
        }
        if (sectionData.length > 0) {
          this.props.activateReport(formValues)
            .then((response) => {
              this.props.userDetailsSuccess(response.data.Message)

              if (response.status === 200) {
                var httpstatus = response.status
                var getMsgfromStatus = msg[httpstatus]
                this.loadData()
                this.setState({ mymessage: getMsgfromStatus })
              }
            }).catch((error) => {
              if (error.stack.includes('Network Error')) {
                this.setState({ mymessage: "" })
                this.props.userDetailsFailure(this.props.validation.networkError)
              }
              else {
                var httpstatus = error.response.status
                var getMsgfromStatus = msg[httpstatus]
                if (getMsgfromStatus !== undefined) {
                  this.setState({ mymessage: "" })
                  this.props.userDetailsFailure(getMsgfromStatus)
                }
                else {
                  this.setState({ mymessage: "" })
                  this.props.userDetailsFailure(error.response.data.Message)
                }
              }
            })
        }
        else {
          this.props.userDetailsFailure("Please Add Sequence to this report to activate")
        }
      })


  }

  handleReportDetailTable(evt) {
    var item = {
      name: evt.target.name,
      id: evt.target.id,
      value: evt.target.value
    };

    var old_reportDetails = this.state.old_reportDetails.slice();
    if (old_reportDetails.length === 0) {
      old_reportDetails = this.state.reports.slice();
      this.setState({ old_reportDetails: old_reportDetails });
    }
    let testOfObjects = old_reportDetails;
    var newreportDetails = testOfObjects.map(function (testReportDetails) {
      for (var key in testReportDetails) {
        if (testReportDetails.id === item.id) {
          testReportDetails[key] = item.name;
          testReportDetails["isEditing"] = false;
          break;
        }
      }
      return testReportDetails;
    });

    this.setState({ reports: newreportDetails });

  };

  handleEditEvent(evt) {
    var item = {
      name: evt.name,
      id: evt.id,
      isEditing: evt.isEditing
    };
    var old_reportDetails = this.state.reports.slice();
    var newreportDetails = old_reportDetails.map(function (testReportDetails) {
      for (var key in testReportDetails) {
        if (testReportDetails.Id == item.id) {
          testReportDetails.Name = item.name;
          testReportDetails.isEditing = !item.isEditing;

          if (evt.UpdatedBy !== undefined) {
            testReportDetails.UpdatedBy = evt.UpdatedBy;
            testReportDetails.UpdatedOn = evt.UpdatedOn;
          }
          break;
        }
      }
      return testReportDetails;
    });

    this.setState({ reports: newreportDetails });

  }

  handleCancelEvent(originalDetails) {
    var item = {
      id: originalDetails.id,
      name: originalDetails.name
    };

    var old_reportDetails = this.state.reports.slice();
    var newreportDetails = old_reportDetails.map(function (testReportDetails) {
      for (var key in testReportDetails) {
        if (testReportDetails.Id === item.id) {
          testReportDetails[key] = item.name;
          testReportDetails["isEditing"] = false;
          break;
        }
      }
      return testReportDetails;
    });
    this.setState({ reports: newreportDetails });

  };

  handleUpdateEvent(updateDetails) {
    if (updateDetails) {
      var item = {
        id: updateDetails.id,
        name: updateDetails.name,
        description: updateDetails.description,
        layout: updateDetails.layout,
        size: updateDetails.size,
        isEditing: true,
        UpdatedBy: localStorage.getItem('loggedinUser'),
        UpdatedOn: Date.now,
        oldReportName: updateDetails.oldReportName
      };

      this.props.updateReport(item)
        .then((results) => {
          var msg = this.props.content.rowUpdate[0];
          if (results.status === 200) {
            var httpstatus = results.status
            var getMsgfromStatus = msg[httpstatus]

            this.props.userDetailsSuccess(getMsgfromStatus)
            this.setState({ mymessage: getMsgfromStatus })
            // item.UpdatedBy = results.data.Result.UpdatedBy;
            // item.UpdatedOn = results.data.Result.UpdatedOn;
            this.handleEditEvent(item)
          }
          this.loadData()
          this.setState({ userIsEditing: false })

        }).catch((error) => {
          if (error.stack.includes('Network Error')) {
            this.props.userDetailsFailure('API service is down. Please contact administrator.')
          }
          else {
            this.props.userDetailsFailure(error.response.data.Message)
          }
        })
    }
  }

  addReport(formValue) {
    this.props.resetError();
    var reportDetail = {
      name: formValue.ReportName.trim(),
      description: formValue.Description.trim(),
      User: localStorage.getItem('loggedinUser'),
      layout: formValue.Layout,
      size: formValue.Size,
      active: false
    }
    var msg = this.props.content;
    var result = this.state.reports.find(report => report.Name.toLowerCase() == reportDetail.name.toLowerCase()) === undefined
    if (!result) {
      this.props.userDetailsFailure('This report already exists!')
    }
    else {
      this.setState({ mymessage: "" })
      this.props.addReport(reportDetail).then((results) => {

        if (results.status === 200) {
          var httpstatus = results.status
          var getMsgfromStatus = msg[httpstatus]
          // Reset form
          this.formDispatch(actions.reset('reportsManager'))
          this.loadData()
          this.setState({ mymessage: getMsgfromStatus, layoutOption: "", sizeOption: "" })
          // setTimeout(() => {          
          //   this.loadData()
          //   this.setState({mymessage: ""})
          // }, messageDuration)  
        }
      }).catch((error) => {
        if (error.stack.includes('Network Error')) {
          this.setState({ mymessage: "" })
          this.props.userDetailsFailure('API service is down. Please contact administrator.')
        }
        else {
          this.setState({ mymessage: "" })
          this.props.userDetailsFailure(error.response.data.Message)
        }
      })
    }

  }
  handleLayoutChange(value) {
    var filter = value.label;
    this.setState({ layoutOption: filter })
    this.formDispatch(actions.setErrors('reportsManager.Layout', false))

  }

  onDDLReportLayoutClickEvent = () => {
    this.setState({ isReportLayoutSelected: 'Selected' });
  }
  onDDLReportLayoutBlurEvent = () => {
    this.setState({ isReportLayoutSelected: '' });
  }

  onDDLReportSizeFileClickEvent = () => {
    this.setState({ isReportSizeFileSelected: 'Selected' });
  }
  onDDLReportSizeFileBlurEvent = () => {
    this.setState({ isReportSizeFileSelected: '' });
  }

  handleSizeChange(value) {
    var filter = value.label;
    this.setState({ sizeOption: filter })
    this.formDispatch(actions.setErrors('reportsManager.Size', false))
  }

  isDuplicate(vals) {
    var result = this.state.reports.find(report => report.Name === vals.trim()) === undefined
    if (result) {
      return result;
    }
    else {
      return result;
    }
  }

  componentDidUpdate() {
    this.formDispatch(actions.change('reportsManager.Layout', this.state.layoutOption))
    this.formDispatch(actions.change('reportsManager.Size', this.state.sizeOption))
  }
  componentWillUnmount() {
    this.formDispatch(actions.reset('reportsManager'))
    this.props.resetError();
  }

  render() {
    return (
      <div>
        <div className="medium-12 title">{this.props.content.title}</div>
        <div>
          <Form model="reportsManager" method="post"
            getDispatch={(dispatch) => this.formDispatch = dispatch}
            onSubmit={(formValue) => this.addReport(formValue)}>

            <div className="grid-x grid-padding-x" >
              <div className="medium-4 cell" >
                <Control.text
                  model="reportsManager.ReportName"
                  component={Textfield}
                  validateOn="blur"
                  label={this.props.content.newReport}
                  floatingLabel
                  validators={{
                    required: validators.required,
                    duplicate: (vals) => this.isDuplicate(vals)
                  }}
                />
                <Errors
                  className="errors"
                  model="reportsManager.ReportName"
                  show="touched"
                  messages={{
                    required: this.props.validation.required,
                    duplicate: this.props.validation.duplicateReport
                  }}
                />
              </div>

              <div className="medium-6 cell" >
                <Control.text
                  model="reportsManager.Description"
                  component={Textfield}
                  validateOn="blur"
                  label={this.props.content.description}
                  floatingLabel
                  validators={{
                    required: validators.required,
                    duplicate: (vals) => this.isDuplicate(vals)
                  }}
                />
                <Errors
                  className="errors"
                  model="reportsManager.Description"
                  show="touched"
                  messages={{
                    required: this.props.validation.required,
                    duplicate: this.props.validation.duplicateReport
                  }}
                />
              </div>
              <div className="medium-4 cell" onClick={this.onDDLReportLayoutClickEvent.bind(this)} onBlur={this.onDDLReportLayoutBlurEvent.bind(this)}>
                {
                  ((this.state.isReportLayoutSelected === 'Selected' && this.state.layoutOption !== '')
                    || (this.state.isReportLayoutSelected === '' && this.state.layoutOption !== ''))
                    ? <label style={{ visibility: 'visible' }} class="css-floatingLabel"
                      for="reportsManager.Layout">{this.props.content.layout}</label>
                    : <label style={{ visibility: 'hidden' }} class="css-floatingLabel"
                      for="reportsManager.Layout">{this.props.content.layout}</label>
                }
                <Select
                  //className="role__select"
                  model="reportsManager.Layout"
                  id="reportsManager.Layout"
                  styles={dropdownStyles}
                  placeholder={this.props.content.layout}
                  value={Layoutoptions.filter(option => option.label === this.state.layoutOption)}
                  noOptionsMessage={() => null}
                  onChange={(value) => this.handleLayoutChange(value)}
                  options={Layoutoptions}
                  isSearchable={false}
                  classNamePrefix="gen-react-select"
                  className="gen-react-select-container"
                />

                <Control.text
                  model="reportsManager.Layout"
                  id="reportsManager.Layout"
                  value={this.state.layoutOption}
                  hidden
                  validateOn="change"
                  component={Textfield}
                  validators={{
                    required: validators.required
                  }} />
                <Errors
                  className="errors"
                  style={{ margin: '-37px 0px 10px' }}
                  model="reportsManager.Layout"
                  show="touched"
                  messages={{
                    required: this.props.validation.required
                  }}
                />
              </div>
              <div className="medium-4 cell" style={{ width: 'auto' }} onClick={this.onDDLReportSizeFileClickEvent.bind(this)} onBlur={this.onDDLReportSizeFileBlurEvent.bind(this)}>
                {
                  ((this.state.isReportSizeFileSelected === 'Selected' && this.state.sizeOption !== '')
                    || (this.state.isReportSizeFileSelected === '' && this.state.sizeOption !== ''))
                    ? <label style={{ visibility: 'visible' }} class="css-floatingLabel"
                      for="reportsManager.Size">{this.props.content.size}</label>
                    : <label style={{ visibility: 'hidden' }} class="css-floatingLabel"
                      for="reportsManager.Size">{this.props.content.size}</label>
                }
                <Select
                  //className="role__select"
                  model="reportsManager.Size"
                  id="reportsManager.Size"
                  styles={dropdownStyles}
                  placeholder={this.props.content.size}
                  value={SizeOptions.filter(option => option.label === this.state.sizeOption)}
                  noOptionsMessage={() => null}
                  onChange={(value) => this.handleSizeChange(value)}
                  options={SizeOptions}
                  isSearchable={false}
                  classNamePrefix="gen-react-select"
                  className="gen-react-select-container"
                />

                <Control.text
                  model="reportsManager.Size"
                  id="reportsManager.Size"
                  value={this.state.sizeOption}
                  hidden
                  validateOn="change"
                  component={Textfield}
                  validators={{
                    required: validators.required
                  }} />
                <Errors
                  className="errors"
                  style={{ margin: '-37px 0px 10px' }}
                  model="reportsManager.Size"
                  show="touched"
                  messages={{
                    required: this.props.validation.required
                  }}
                />
              </div>

              <div className="medium-2 cell pt13" >
                <Button
                  type="submit"
                  disabled={this.props.user.loading}
                  content={this.props.content.cta}
                  color
                />
              </div>
            </div>

          </Form>
          <div className="errors">{this.props.user.error}</div>
          <div className="success">{this.state.mymessage}</div>
        </div>
        <div className="grid-x" className="medium-11 medium-offset-0">
          <UserTable
            onReportUpdate={this.handleReportDetailTable.bind(this)}
            onRowDel={this.handleRowDel.bind(this)}
            onRowAct={this.handleActivate.bind(this)}
            onRowEdit={this.handleEditEvent.bind(this)}
            onRowCancel={this.handleCancelEvent.bind(this)}
            onRowUpdate={this.handleUpdateEvent.bind(this)}
            userEditing={this.state.userIsEditing}
            editId={this.state.editId}
            validation={this.props.validation}
            reports={this.state.reports}
            layouts={this.state.options}
            sizes={this.state.sizes}
            isDuplicate={this.isDuplicate.bind(this)}
          />
        </div>
      </div>
    );
  }
}

class UserTable extends React.Component {
  render() {
    var onReportUpdate = this.props.onReportUpdate;
    var rowDel = this.props.onRowDel;
    var rowAct = this.props.onRowAct;
    var rowEdit = this.props.onRowEdit;
    var rowCancel = this.props.onRowCancel;
    var rowUpdate = this.props.onRowUpdate;
    var userEditing = this.props.userEditing;
    var isDuplicate = this.props.isDuplicate;
    var editId = this.props.editId;
    var layouts = this.props.layouts;
    var sizeFiles = this.props.sizes;
    var validation = this.props.validation;
    var report = this.props.reports.map(function (report) {
      return (<UserRow
        onDelEvent={rowDel.bind(this)}
        onActivate={rowAct.bind(this)}
        onEditEvent={rowEdit.bind(this)}
        onCancelEvent={rowCancel.bind(this)}
        onUpdateEvent={rowUpdate.bind(this)}
        userIsEditing={userEditing}
        isDuplicate={isDuplicate}
        editId={editId}
        validation={validation}
        layouts={layouts}
        sizeFiles={sizeFiles}
        report={report}
        onReportUpdate={onReportUpdate}
        key={report.Id} />)
    });
    return (
      <div className="basic-table">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Active</th>
              <th>Description</th>
              <th>Layout FileName</th>
              <th>Size FileName</th>
              {/* <th>Added By/On</th>
              <th>Updated By/On</th> */}
              <th></th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {report}{onReportUpdate}
          </tbody>
        </table>
      </div>
    );
  }
}



class UserRow extends React.Component {
  constructor(props) {
    super(props);
  }

  // onDelEvent() {
  // 	this.props.onDelEvent(this.props.report);
  // }

  // onEditEvent() {
  //   this.props.onEditEvent(this.props.report);
  // }

  onReportUpdate() {
    this.props.onReportUpdate()
  }

  render() {
    return (
      this.props.report.isEditing ?
        <EditableCell onDomainTableUpdate={this.props.onReportUpdate}
          report={this.props.report}
          layouts={this.props.layouts}
          sizeFiles={this.props.sizeFiles}
          cellData={{
            name: this.props.report.Name,
            description: this.props.report.Description,
            id: this.props.report.Id,
            layout: this.props.report.Layout,
            size: this.props.report.Size,
            isEditing: this.props.report.isEditing

          }}
          onCancelEvent={this.props.onCancelEvent}
          onUpdateEvent={this.props.onUpdateEvent}
          isEditing={this.props.report.isEditing}
          validation={this.props.validation}
          isDuplicate={this.props.isDuplicate}
        />
        :
        <NonEditableRow
          report={this.props.report}
          cellData={{
            name: this.props.report.Name,
            description: this.props.report.Description, // added by sunita as need description while adding into language json files 
            id: this.props.report.Id,
            layout: this.props.report.Layout,
            size: this.props.report.Size,
            isEditing: this.props.report.isEditing
          }}
          onDelEvent={this.props.onDelEvent}
          onActivate={this.props.onActivate}
          onEditEvent={this.props.onEditEvent}
          isEditing={this.props.report.isEditing}
        />
    );
  }
}

class EditableCell extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reportDetails: Object.assign({}, this.props.cellData),
      originalreportDetails: Object.assign({}, this.props.cellData),
      flag1: false,
      flag2: false,
      layouts: this.props.layouts,
      sizeFiles: this.props.sizeFiles
    };
    this.onChangeEvent = this.onChangeEvent.bind(this);
  }

  onDomainTableUpdate() {
    this.props.onReportUpdate();
  }

  onCancelEvent() {
    this.setState({ isEditing: false });
    this.props.onCancelEvent(this.state.originalreportDetails);
  }

  onUpdateEvent() {
    this.props.onUpdateEvent(this.state.reportDetails);
  }

  onChangeEvent(event) {
    if (event.target.value !== null && event.target.value !== "") {      
      var changeItem = this.state.reportDetails;
      changeItem['oldReportName'] = this.state.originalreportDetails.name // Added by Sunita for the update in JSON using old report name
      changeItem[event.target.name] = event.target.value;
      this.setState({ reportDetails: changeItem });
    }
  }


  isDuplicate(vals) {
    if (vals === undefined) {
      return this.props.isDuplicate("");
    }
    else {
      return this.props.isDuplicate(vals);
    }

  }

  render() {
    return (
      <tr>
        <td>
          <Form model="editReport"
            getDispatch={(dispatch) => this.formDispatch = dispatch}
          >
            <Control.text
              model="editReport.NewReport"
              component={Textfield}
              defaultValue={this.props.cellData.name}
              name={"name"}
              id={this.props.cellData.id}
              value={this.state.reportDetails.name}
              onChange={this.onChangeEvent}
              validateOn="change"
              validators={{
                required: validators.required,
                duplicate: (vals) => this.isDuplicate(vals)
              }}
              type='name' />
            <Errors
              className="errors"
              model="editReport.NewReport"
              show={(field) => field.focus}
              messages={{
                required: this.props.validation.required,
                duplicate: this.props.validation.duplicateReport
              }} />
          </Form>
        </td>
        <td>
          <span>{this.props.report.Active ? 'Yes' : 'No'}</span><br />
        </td>
        <td>
          {/* <span>{this.props.report.Description}</span><br /> */}
          <Form model="editDomain"
            getDispatch={(dispatch) => this.formDispatch = dispatch}
          >
            <Control.text
              model="editDomain.Description"
              component={Textfield}
              defaultValue={this.props.cellData.description}
              name={"description"}
              id={this.props.cellData.id}
              onChange={this.onChangeEvent.bind(this)}
              isEditing={this.props.cellData.isEditing}
              validateOn="change"
              validators={{
                required: validators.required
              }}
              type='name' />
            <Errors
              className="errors"
              model="editDomain.Description"
              show={(field) => field.focus}
              messages={{
                required: this.props.validation.required
              }} />
          </Form>
        </td>
        <td>
          {/* <span>{this.props.report.Layout}</span><br /> */}
          <select className="custom-select"
            name="layout"
            defaultValue=''
            value={this.state.reportDetails.layout}
            onChange={this.onChangeEvent}>
            {this.props.layouts.map((role) =>
              <option
                key={role}
                value={role}
              >{role}</option>
            )}
          </select>
        </td>
        <td>
          <select className="custom-select"
            name="size"
            defaultValue=''
            value={this.state.reportDetails.size}
            onChange={this.onChangeEvent}>
            {this.props.sizeFiles.map((role) =>
              <option
                key={role}
                value={role}
              >{role}</option>
            )}
          </select>
        </td>
        {/* <td>
          <span>{this.props.report.AddedBy}</span><br />
          <span>{moment(this.props.report.AddedOn).format('L')}</span>
        </td>
        <td>
          <span> {this.props.report.UpdatedBy}</span> <br />
          <span>{moment(this.props.report.UpdatedOn).format('L')} </span>
        </td> */}
        <td>
          <button onClick={this.onUpdateEvent.bind(this)}>Update</button>
        </td>
        <td >
          <button onClick={this.onCancelEvent.bind(this)}>Cancel</button>
        </td>
      </tr>
    );
  }
}

class NonEditableRow extends React.Component {

  onDelEvent() {
    this.props.onDelEvent(this.props.cellData);
  }
  onActivate() {
    this.props.onActivate(this.props.cellData);
  }

  onEditEvent() {
    this.props.onEditEvent(this.props.cellData);
  }

  render() {
    return (
      <tr>
        <td>
          <span id={this.props.cellData.id}
            name={this.props.cellData.name}
          >{this.props.cellData.name}</span>
        </td>
        <td>
          <span>{this.props.report.Active ? 'Yes' : 'No'}</span><br />
        </td>
        <td>
          <span>{this.props.report.Description}</span><br />
        </td>
        <td>
          <span>{this.props.report.Layout}</span><br />
        </td>
        <td>
          <span>{this.props.report.Size}</span><br />
        </td>
        {/* <td>
          <span>{this.props.report.AddedBy}</span><br />
          <span>{moment(this.props.report.AddedOn).format('L')}</span>
        </td>
        <td>
          <span> {this.props.report.UpdatedBy}</span> <br />
          <span>{moment(this.props.report.UpdatedOn).format('L')} </span>
        </td> */}
        <td>
          <button onClick={this.onEditEvent.bind(this)}>Edit</button>
        </td>
        <td>
          {this.props.report.Active ? <button onClick={this.onDelEvent.bind(this)}>Deactivate</button>
            : <button onClick={this.onActivate.bind(this)}>Activate</button>}

        </td>
      </tr>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
    ui: state.content.ui,
    user: state.default,
    content: state.content.reports,
    validation: state.content.validation,
    reports: state.reports
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchReports: (formValues) => dispatch(fetchReports(formValues)),
    fetchMappings: (formValues) => dispatch(fetchMappings(formValues)),
    fetchLayoutFiles: (formValues) => dispatch(fetchLayoutFiles(formValues)),
    fetchSizeFiles: (formValues) => dispatch(fetchSizeFiles(formValues)),
    addReport: (formValues) => dispatch(addReport(formValues)),
    deleteReport: (formValues) => dispatch(deleteReport(formValues)),
    activateReport: (formValues) => dispatch(activateReport(formValues)),
    updateReport: (formValues) => dispatch(updateReport(formValues)),
    userDetailsLoading: (bool) => dispatch(userDetailsLoading(bool)),
    userDetailsSuccess: (bool) => dispatch(userDetailsSuccess(bool)),
    userDetailsFailure: (error) => dispatch(userDetailsFailure(error)),
    resetError: () => dispatch(resetError())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportManager)
