import React, { Component } from 'react'
import { connect } from 'react-redux'
import FaAngleRight from 'react-icons/lib/fa/angle-right'
import classNames from "classnames";
import Popup from "reactjs-popup";
import Modal from 'reboron/OutlineModal'
import Button from 'UI/Button'

import IndustryOverridePopUp from '../IndustryOverridePopUp'
import { PopupE } from 'UI/CustomComponents/Controls';

import {
    getYourFileSuccess,
    getYourFileLoading,
    resetError,
    getYourFileFailure,
    getYourFilesDetails,
    currentActiveRequest,
    UpdateRequestParameter,
    getAllEnablementParameterPriority,
    updateEnablementParameterPriority,
    pendingChanges,
    getEnablementDetails
    , redirectFromWizard
} from 'actions/spendActions'

import {
    updateEnablementStrategyWaveData
} from 'actions/kpiActions'

import { changeModalStyle, changeModalContentStyle, backdropStyle, path } from 'Constants'
import { Checkbox } from 'material-ui';

const changeBackdropStyle = Object.assign({}, backdropStyle);
changeBackdropStyle.zIndex = '1050';

const alertModalStyle = Object.assign({}, changeModalStyle);
alertModalStyle.zIndex = '1060';
alertModalStyle.width = '33%';

class EnablementStrategyWizard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            respnseMessage: '',
            isError: false,
            popupErrorMessage : '',
            test: JSON.stringify(this.props.spendDetails.currentActiveRequest),
            isActive: '',
            selectedCategory: '',
            data: [],
            inputParam: {
                DMC_ID: this.props.spendDetails !== undefined && this.props.spendDetails.currentActiveRequest !== undefined ? this.props.spendDetails.currentActiveRequest.DMC_ID : 0
                , VS_ID: this.props.spendDetails !== undefined && this.props.spendDetails.currentActiveRequest !== undefined ? this.props.spendDetails.currentActiveRequest.VS_ID : 0,
                User_Email: this.props.spendDetails.currentActiveRequest !== undefined &&
                    this.props.spendDetails.currentActiveRequest.SAPRequester === this.props.user.user.Email ? this.props.user.user.Email : this.props.spendDetails.currentActiveRequest.SAPRequester,
            },
            apiResponse: [],
            editedItemPriority: [],
            isPopupOpen: false,
            pendingAutoWaveChanges: false,
            autoWavePriority: [],
            uniquePriority: [],
            initialData: [],
            agreeToRecast: false,
            isReadOnly: false,
            isWarning: false,
            isSucess: false,
            cntToSetWarning: 0,
            isAllowedToEdit: true,
            isExpand: false,
            sccSelectedDropdown : '',
            select1:'',
            arrSelect1:[],
            select2:'',
            arrSelect2:[],
            select3:'',
            arrSelect3:[],
            select4:'',
            arrSelect4:[],
            select5:'',
            arrSelect5:[],
            select6:'',
            arrSelect6:[],
            select7:'',
            arrSelect7:[],
            select8:'',
            arrSelect8:[],
            select9:'',
            arrSelect9:[],
            select10:'',
            arrSelect10:[],
            currentSelect:'',
            currentArrSelect:[],
            isCheck1: false,
            isCheck2: false,
            isCheck3: false,
            isCheck4: false,
            isCheck5: false,
            isCheck6: false,
            isCheck7: false,
            isCheck8: false,
            isCheck9: false,
            isCheck10: false,
            count: 0
        }

        this.onLoad = this.onLoad.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.showModal = this.showModal.bind(this)
        this.redirect = this.redirect.bind(this)
        this.openSccDropdown = this.openSccDropdown.bind(this) 
        this.sccCheckboxClicked = this.sccCheckboxClicked.bind(this)
        this.closeSccDropdownPopup = this.closeSccDropdownPopup.bind(this)
        this.saveSccDropdown = this.saveSccDropdown.bind(this)
        this.sccOnSaveValidation = this.sccOnSaveValidation.bind(this)        
        this.setSuppierChoiceProcess = this.setSuppierChoiceProcess.bind(this)                  
        this.newVariable = 0
    }

    setSuppierChoiceProcess(scProcessData) {
        scProcessData.map((item) => {
            if (item.CHILD_FIELD_NAME != null) {
                if (item.CHILD_FIELD_NAME != "") {
                    var arr_child_field_name = [];
                    arr_child_field_name = item.CHILD_FIELD_NAME.split(',');

                    switch (item.FIELD_VALUE) {
                        case 'SELECT1':
                            this.setState({ select1: item.CHILD_FIELD_NAME, arrSelect1: arr_child_field_name });
                            break;
                        case 'SELECT2':
                            this.setState({ select2: item.CHILD_FIELD_NAME, arrSelect2: arr_child_field_name });
                            break;
                        case 'SELECT3':
                            this.setState({ select3: item.CHILD_FIELD_NAME, arrSelect3: arr_child_field_name });
                            break;
                        case 'SELECT4':
                            this.setState({ select4: item.CHILD_FIELD_NAME, arrSelect4: arr_child_field_name });
                            break;
                        case 'SELECT5':
                            this.setState({ select5: item.CHILD_FIELD_NAME, arrSelect5: arr_child_field_name });
                            break;
                        case 'SELECT6':
                            this.setState({ select6: item.CHILD_FIELD_NAME, arrSelect6: arr_child_field_name });
                            break;
                        case 'SELECT7':
                            this.setState({ select7: item.CHILD_FIELD_NAME, arrSelect7: arr_child_field_name });
                            break;
                        case 'SELECT8':
                            this.setState({ select8: item.CHILD_FIELD_NAME, arrSelect8: arr_child_field_name });
                            break;
                        case 'SELECT9':
                            this.setState({ select9: item.CHILD_FIELD_NAME, arrSelect9: arr_child_field_name });
                            break;
                        case 'SELECT10':
                            this.setState({ select10: item.CHILD_FIELD_NAME, arrSelect10: arr_child_field_name });
                            break;
                        default:
                            break;
                    }
                }
            }
        })
    }

    onLoad() {

        this.props.getAllEnablementParameterPriority(this.state.inputParam).then(response => {
            if (response.status === 200) {
                if (response.data.Result.length === 0) {
                    this.setState({ responseMessage: this.props.wizard.reimport, isLoading: false, isError: true });
                    this.props.getYourFileLoading(false)
                }

                this.setSuppierChoiceProcess(response.data.Result.filter(d => d.FIELD_NAME === "SUPPLY_CHAIN_PROCESS" && d.CHILD_FIELD_NAME != null));
                var autoWaveData = response.data.Result.filter(d => d.FIELD_NAME === "AUTO_WAVE_HEADER");  // right side table data
                ////console.log("autowaveData--",autoWaveData)
                // autoWaveData.map((item)=>
                // {                  
                //    if(item.FIELD_VALUE === "L4_INDUSTRY_GICS_ID")
                //    {
                //        item.PRIORITY_NUM = 1  // set default 1 for L4_INDUSTRY
                //    }
                // })


                var existingPriorityData = response.data.Result.filter(d => d.FIELD_NAME === "AUTO_WAVE_HEADER" && d.PRIORITY_NUM > 0); // left side table default data

                let activeCategory;
                let activeCategoryDesc;
                let existingData;
                //console.log("active state: ", this.state.isActive)
                if (this.state.isActive !== '') {
                    activeCategory = this.state.isActive
                    activeCategoryDesc = this.state.selectedCategory
                    existingData = response.data.Result.filter(d => d.FIELD_NAME === this.state.isActive); // left side table default data 
                }
                else if (existingPriorityData.length !== 0) {
                    activeCategory = existingPriorityData[0].FIELD_VALUE
                    activeCategoryDesc = existingPriorityData[0].FIELD_DESC
                    existingData = response.data.Result.filter(d => d.FIELD_NAME === existingPriorityData[0].FIELD_VALUE); // left side table default data
                } else {
                    activeCategory = "L4_INDUSTRY_GICS_ID"
                    activeCategoryDesc = "L4 Industry Name(L1-L4 GICS)"
                    existingData = response.data.Result.filter(d => d.FIELD_NAME === "L4_INDUSTRY_GICS_ID")
                }

                var dict = {}

                let isRestrict = false;
                let uniquePriority = this.state.uniquePriority
                autoWaveData.map((item) => {
                    if ((item.FIELD_VALUE === "L4_INDUSTRY_GICS_ID" || item.FIELD_VALUE === "ERP_COMMODITY_CODE1" || item.FIELD_VALUE === "ERP_COMMODITY_CODE2")
                        && item.PRIORITY_NUM > 0) {
                        uniquePriority.push(item.PRIORITY_NUM)
                        dict[item.FIELD_DESC] = item.PRIORITY_NUM;
                        isRestrict = true;
                        return;
                    }
                    else if (item.PRIORITY_NUM > 0) {
                        uniquePriority.push(item.PRIORITY_NUM)
                        dict[item.FIELD_DESC] = item.PRIORITY_NUM;
                    }
                })
                // console.log("jsdgkjdhfgdshfgdsh", existingPriorityData)
                this.setState({
                    apiResponse: response.data.Result,
                    data: existingData,
                    initialData: existingData,
                    isActive: !!this.state.isActive ? this.state.isActive : activeCategory,
                    selectedCategory: !!this.state.selectedCategory ? this.state.selectedCategory : activeCategoryDesc,
                    autoWavePriority: autoWaveData,
                    editedItemPriority: response.data.Result,
                    uniquePriority: uniquePriority, dictState: dict
                })
                //console.log("default data-------", existingData)     
                this.props.EnablementStrategyrefreshPriorityTable(false);
                this.props.handleStrategyWizardChanges();
                this.setState({ isExpand: true })

                let dmcId = this.props.spendDetails.currentActiveRequest.DMC_ID;
                let vsId = this.props.spendDetails.currentActiveRequest.VS_ID;
                let user = this.props.user.user.Email;
                if(this.props.shouldCallWaveCalc)
                {
                    this.props.updateEnablementStrategyWaveData(dmcId, vsId, user)
                        .then((response) => {
                            //this.props.EnablementStrategyrefreshResourceTable(true);
                            this.props.UpdateKPIDataLoaded(false);
                        });
                }
                else{
                    this.props.EnablementStrategyrefreshResourceTable(true);
                }
            }
            else {
                this.setState({
                    isLoading: false,
                    isError: true,
                    respnseMessage: 'An error has occured'
                })
            }
        }).catch((error) => {
            this.setState({
                isLoading: false,
                isError: true,
                respnseMessage: 'An error has occured'
            })
        })
    }

    GetEnablementDetails(inputParam) {

        this.props.getEnablementDetails(inputParam)
            .then((response) => {
                if (response !== undefined && response.status === 200 && response.data.Result !== undefined) {
                    this.props.getYourFileLoading(false)
                    var enablementWaveData;

                    var tables = response.data.Result.map(
                        function (table) {
                            if (table['ENABLEMENT_WAVE'] !== undefined) {
                                enablementWaveData = table['ENABLEMENT_WAVE']
                            }
                        }
                    )

                    // if (enablementWaveData.filter(d => d.WAVE_DESCRIPTION !== null).length !== 0) {
                    //     this.setState({
                    //         isWaveDescHaveValue: true,
                    //         responseMessage: this.props.wizard.checkWaveDesc,
                    //         isAllowedToEdit: false,
                    //         isReadOnly: true,
                    //         isError: true
                    //     })
                    // } else {
                    //     this.setState({
                    //         isWaveDescHaveValue: false,
                    //         responseMessage: '',
                    //         isAllowedToEdit: true,
                    //         isReadOnly: false,
                    //         isError: false
                    //     })
                    // }
                    // console.log("1")
                    // //console.log("Enablement Wave Data: ", enablementWaveData )                
                }
            }).catch((error) => {
                if (error.stack.includes('Network Error')) {
                    this.setState({ responseMessage: 'API service is down. Please contact administrator', isError: true })
                }
                else {
                    this.setState({ responseMessage: 'An error has occurred', isError: true })
                }
            })
    }

    // componentDidMount() {
    //     this.setState({ isExpand: !this.state.isExpand })
    //     this.GetEnablementDetails(this.state.inputParam)
    //     setTimeout(() => {
    //         this.onLoad()
    //     }, 1000)
    // }

    componentDidUpdate(nextProps) {
        if ((nextProps.isWizardSaveClicked !== this.props.isWizardSaveClicked
            || nextProps.isRefresh != this.props.isRefresh)
            && this.props.isRefresh
            //&& !nextProps.isWizardSaveClicked
        ) {
            this.setState({ isExpand: !this.state.isExpand })
            this.GetEnablementDetails(this.state.inputParam)
            setTimeout(() => {
                //this.onLoad();
                this.handleSave();
            }, 1000)
        }

    }

    // shouldComponentUpdate(nextProps) {
    //     // if (nextProps.isWizardSaveClicked !== this.props.isWizardSaveClicked && nextProps.isWizardSaveClicked
    //     //     && this.props.isRefresh
    //     if ((nextProps.isWizardSaveClicked !== this.props.isWizardSaveClicked
    //         || nextProps.isRefresh != this.props.isRefresh)
    //         && this.props.isRefresh
    //         && nextProps.isWizardSaveClicked
    //         ) {
    //         // this.props.handleStrategyWizardChanges()
    //         setTimeout(() => {
    //             this.handleSave();
    //         }, 2000)
    //     }
    //     return true
    // }  

    handleCategoryChange(e) {
        var value = e.target.title === "L4_INDUSTRY_GICS_ID" ? e.target.title : e.target.value;
        this.setState({
            isReadOnly: this.state.autoWavePriority.find(d => d.FIELD_VALUE === value && d.PRIORITY_NUM > 0) ? false : true,
            data: this.state.apiResponse.filter(d => d.FIELD_NAME === value),
            initialData: this.state.apiResponse.filter(d => d.FIELD_NAME === value),
            isActive: value,
            selectedCategory: e.target.title === "L4_INDUSTRY_GICS_ID" ? "L4 Industry Name(L1-L4 GICS)" : e.target.innerText,
            isError: false,
            //responseMessage: '',
            pendingMsg: this.state.pendingAutoWaveChanges ? this.props.wizard.pendingPriorityChanges : ''
        })
    }

    onAutoWaveChangeEvent(val, item) {
        this.setState({ pendingMsg: this.props.wizard.pendingPriorityChanges, isSucess: false })//, isError: false })
        var key = val.target.innerText
        var value = Number(val.target.value)
        let updatedRecords = this.state.editedItemPriority

        if (/^(\-(\d*))$/.test(value) || !isNaN(value) && (value <= 8) && Number.isInteger(value)) {
            var editingRow = item;
            editingRow.USER_ID = this.state.inputParam.User_Email
            editingRow.DMC_ID = this.state.inputParam.DMC_ID
            editingRow.VS_ID = this.state.inputParam.VS_ID

            if (val.target.id === 'PRIORITY_NUM') {
                //editingRow[val.target.id] = value                

                var dict = this.state.dictState
                editingRow[val.target.id] = value

                var count = 0;
                var uniqueCount = 1;
                //User will only be able to put in a number on 1 of the first 3 priorities in the Auto Wave category

                this.state.autoWavePriority.map(record => {

                    if (record.FIELD_VALUE === "L4_INDUSTRY_GICS_ID" && record.PRIORITY_NUM > 0) {
                        count = count + 1;
                    } else if (record.FIELD_VALUE === "ERP_COMMODITY_CODE1" && record.PRIORITY_NUM > 0) {
                        count = count + 1;
                    } else if (record.FIELD_VALUE === "ERP_COMMODITY_CODE2" && record.PRIORITY_NUM > 0) {
                        count = count + 1;
                    }

                    if (record.PRIORITY_NUM > 0) {
                        var recordCnt = this.state.autoWavePriority.filter(rec => rec.PRIORITY_NUM === record.PRIORITY_NUM)
                        if (recordCnt.length > 1) {
                            uniqueCount = uniqueCount + 1;
                        }
                    }

                })

                if (count > 1) {
                    this.setState({ warning1Msg: this.props.wizard.allowedToSetOnlyOnePriority })
                } else {
                    this.setState({ warning1Msg: '' })
                }

                if (uniqueCount > 1) {
                    this.setState({ warning2Msg: this.props.wizard.uniquePriority })
                } else {
                    this.setState({ warning2Msg: '' })
                }


                // code for unique priority value

                if (this.state.editedItemPriority.length === 0 ||
                    this.state.editedItemPriority.find(a => a.FIELD_VALUE === editingRow.FIELD_VALUE) === undefined ||
                    this.state.editedItemPriority.find(a => a.PRIORITY_NUM === value) === undefined) {
                    editingRow.FIELD_NAME = "AUTO_WAVE_HEADER"
                    editingRow.FIELD_VALUE = item.FIELD_VALUE
                    updatedRecords.push(editingRow)
                    this.setState({ //responseMessage: this.props.wizard.pendingPriorityChanges, 
                        editedItemPriority: updatedRecords,
                        pendingAutoWaveChanges: updatedRecords.find(d => d.FIELD_NAME === "AUTO_WAVE_HEADER"),
                        isError: false, isWarning: false
                    })

                    this.props.pendingChanges(true)
                }
            }
        } else {
            val.target.value = 0

            const index = updatedRecords.indexOf(item);
            if (index > -1) {
                updatedRecords.splice(index, 1);
            }
            this.setState({ editedItemPriority: updatedRecords })
        }
        //passing edited record to container for save
        //this.props.handleStrategyWizardChanges(updatedRecords)
    }

    onChangeEvent(val, item) {
        if (item.FIELD_NAME == "SUPPLY_CHAIN_PROCESS" && item.CHILD_FIELD_NAME == "" && Number(val.target.value) > 0) // if no checkbox selected and priority is >0 then make it 0 again
        { 
            this.state.data.filter(x => x.FIELD_VALUE == item.FIELD_VALUE)[0].PRIORITY_NUM = 0;
            val.target.value = 0;
        }
        else {
            this.setState({ isError: false, isSucess: false, isWarning: false, pendingMsg: this.props.wizard.pendingPriorityChanges })
            var value = Number(val.target.value)

            let updatedRecords = this.state.editedItemPriority
            //    if (val.target.value === '' ||
            if (/^(\-(\d*))$/.test(value) || !isNaN(value) && (value <= 9) && Number.isInteger(value)) {
                var editingRow = item;
                editingRow.USER_ID = this.state.inputParam.User_Email
                if (val.target.id === 'PRIORITY_NUM') {
                    //editingRow[val.target.id] = value

                    let apiData = this.state.data
                    let updatedRecords = this.state.editedItemPriority

                    // set new value in editingRow priority number
                    editingRow[val.target.id] = value
                    if (this.state.editedItemPriority.length === 0 ||
                        this.state.editedItemPriority.find(a => a.FIELD_NAME === editingRow.FIELD_NAME && a.FIELD_VALUE === editingRow.FIELD_VALUE) === undefined) {

                        updatedRecords.push(editingRow)

                        var t = apiData.filter(d => d.FIELD_VALUE === item.FIELD_VALUE)
                        if (t.FIELD_VALUE === apiData.FIELD_VALUE)
                            apiData.PRIORITY_NUM = Number(value)

                        this.setState({
                            pendingMsg: this.props.wizard.pendingPriorityChanges,
                            editedItemPriority: updatedRecords,
                            data: apiData,
                            pendingAutoWaveChanges: updatedRecords.find(d => d.FIELD_NAME === "AUTO_WAVE_HEADER"),
                            isError: false
                        })

                        this.props.pendingChanges(true)
                    }
                }
            }
            else {
                val.target.value = 0
                const index = updatedRecords.indexOf(item);
                if (index > -1) {
                    updatedRecords.splice(index, 1);
                }
                this.setState({ editedItemPriority: updatedRecords })
            }

            //passing edited record to container for save
            //this.props.handleStrategyWizardChanges(updatedRecords)
        }
    }

    handleSave() {
        if (this.state.editedItemPriority.length !== 0) {
            this.setState({ isLoading: true, responseMessage: '', pendingMsg: '', warning1Msg: '', warning2Msg: '', isError: false, isSucess: false });
            let updatedRecords = this.state.editedItemPriority;
            let isAutoWaveEdit = updatedRecords[0].FIELD_NAME === "AUTO_WAVE_HEADER" ? true : false;
            updatedRecords[0].USER_NAME = this.state.inputParam.User_Email;
            updatedRecords[0].USER_ID = this.state.inputParam.User_Email;            

            this.props.updateEnablementParameterPriority(updatedRecords).then((response) => {
                if (response.status === 200) {
                    
                    this.setState({
                        editedItemPriority: [],
                        data: this.state.apiResponse.filter(d => d.FIELD_NAME === isAutoWaveEdit ? updatedRecords[0].FIELD_VALUE : this.state.isActive),
                        isActive: isAutoWaveEdit ? updatedRecords[0].FIELD_VALUE : this.state.isActive,
                        selectedCategory: isAutoWaveEdit ? updatedRecords[0].FIELD_DESC : this.state.selectedCategory,
                        isReadOnly: this.state.autoWavePriority.find(d => d.FIELD_VALUE === (isAutoWaveEdit ? updatedRecords[0].FIELD_VALUE : this.state.isActive) && d.PRIORITY_NUM > 0) ? false : true,
                        isLoading: false,
                        responseMessage: this.props.wizard.successMessage,
                        pendingAutoWaveChanges: false,
                        isError: false,
                        isSucess: true
                    }, this.onLoad());
                //});
                    this.props.pendingChanges(null)
                }
                else {
                    this.setState({ isLoading: false, responseMessage: this.props.wizard.errorMessage, isError: true, isSucess: false });
                }
            }).catch((error) => {
                this.setState({ isLoading: false, responseMessage: this.props.wizard.errorMessage, isError: true, isSucess: false });
            })
        }
        else
        {
            this.onLoad();
        }
    }

    togglePopUp(e) {
        this.setState({
            isPopupOpen: !this.state.isPopupOpen,
            data: this.state.initialData.filter(d => d.FIELD_NAME === "L4_INDUSTRY_GICS_ID"), selectedCategory: "L4_INDUSTRY_GICS_ID"
        })
    }

    outsidePopupClick() {
        this.setState({ isPopupOpen: false })
    }

    handleUserInput(filterText) {
        var searchData = this.state.initialData.filter(d => d.FIELD_NAME === "L4_INDUSTRY_GICS_ID" ? d.FIELD_DESC.toLowerCase().includes(filterText.target.value.toLowerCase())
            : d.FIELD_VALUE.toLowerCase().includes(filterText.target.value.toLowerCase()));
        if (searchData.length > 1) {
            this.setState({ data: searchData })
        }
        else {
            this.setState({ data: searchData });
        }
    };

    showModal(action) {
        this.refs[action].show()
    }

    hideModal(action) {
        this.setState({ agreeToRecast: false })
        this.refs[action].hide()
    }

    continueNavigation(action) {
        this.refs[action].hide()
        this.setState({ agreeToRecast: true }, this.handleSave())
    }

    onFocusOut(e) {
        if (e.target.value === '') {
            e.target.value = 0;
        }
    }

    redirect() {
        this.props.history.push('/yourSpend');
        this.props.redirectFromWizard(true);
    }

    onExpandClick(e) {
        this.setState({ isExpand: !this.state.isExpand })
    }

    openSccDropdown(value)
    {
        this.setState({ currentSelect: '' ,popupErrorMessage : '',isCheck1: false,isCheck2: false,isCheck3: false,isCheck4: false,isCheck5: false,isCheck6: false,isCheck7: false,isCheck8: false,isCheck9: false,isCheck10: false });
        var checkedArray = [];
        switch(value) {
            case 'SELECT1':
                checkedArray = this.state.arrSelect1;           
                break;
            case 'SELECT2':
                checkedArray = this.state.arrSelect2;     
                break;
            case 'SELECT3':
                checkedArray = this.state.arrSelect3;   
                break;
            case 'SELECT4':
                checkedArray = this.state.arrSelect4; 
                break;
            case 'SELECT5':
                checkedArray = this.state.arrSelect5; 
                break;
            case 'SELECT6':
                checkedArray = this.state.arrSelect6; 
                break;
            case 'SELECT7':
                checkedArray = this.state.arrSelect7;  
                break;
            case 'SELECT8':
                checkedArray = this.state.arrSelect8;  
                break;
            case 'SELECT9':
                checkedArray = this.state.arrSelect9;    
                break;
            case 'SELECT10':
                checkedArray = this.state.arrSelect10; 
                break;
            default:                
                break;
        }

        checkedArray.map((item) => {
            if (item == 'Forecast Collaboration')
                this.setState({ isCheck1: true });
            else if (item == 'Supplier Managed Inventory')
                this.setState({ isCheck2: true });
            else if (item == 'Standard Order Process')
                this.setState({ isCheck3: true });
            else if (item == 'Consignment Collaboration')
                this.setState({ isCheck4: true });
            else if (item == 'Scheduling Agreement')
                this.setState({ isCheck5: true });
            else if (item == 'Subcontracting')
                this.setState({ isCheck6: true });
            else if (item == 'Quality Notifications')
                this.setState({ isCheck7: true });
            else if (item == 'Quality Inspection')
                this.setState({ isCheck8: true });
            else if (item == 'Quality Review')
                this.setState({ isCheck9: true });
            else if (item == 'Returns')
                this.setState({ isCheck10: true });
        })

        this.setState({ sccSelectedDropdown: value,currentArrSelect : checkedArray }, this.showModal("sccDropdown"));
        //this.showModal("sccDropdown")
    }

    saveSccDropdown()
    {
        var validationResult = this.sccOnSaveValidation();
        if(validationResult){

        var checkedValue = '';
        switch(this.state.sccSelectedDropdown) {
            case 'SELECT1':
                checkedValue = this.state.currentSelect;
                if(checkedValue == ""){this.state.data.filter(x => x.FIELD_VALUE == 'SELECT1')[0].PRIORITY_NUM = 0;}
                this.setState({ select1: checkedValue, arrSelect1 :this.state.currentArrSelect, popupErrorMessage : ''  });        
                break;
            case 'SELECT2':
                checkedValue = this.state.currentSelect;
                if(checkedValue == ""){this.state.data.filter(x => x.FIELD_VALUE == 'SELECT2')[0].PRIORITY_NUM = 0;} 
                this.setState({ select2: checkedValue, arrSelect2 :this.state.currentArrSelect, popupErrorMessage : ''  });   
                break;
            case 'SELECT3':
                checkedValue = this.state.currentSelect;
                if(checkedValue == ""){this.state.data.filter(x => x.FIELD_VALUE == 'SELECT3')[0].PRIORITY_NUM = 0;} 
                this.setState({ select3: checkedValue, arrSelect3 :this.state.currentArrSelect, popupErrorMessage : ''  });   
                break;
            case 'SELECT4':
                checkedValue = this.state.currentSelect;
                if(checkedValue == ""){this.state.data.filter(x => x.FIELD_VALUE == 'SELECT4')[0].PRIORITY_NUM = 0;}  
                this.setState({ select4: checkedValue, arrSelect4 :this.state.currentArrSelect, popupErrorMessage : ''  });   
                break;
            case 'SELECT5':
                checkedValue = this.state.currentSelect; 
                if(checkedValue == ""){this.state.data.filter(x => x.FIELD_VALUE == 'SELECT5')[0].PRIORITY_NUM = 0;}
                this.setState({ select5: checkedValue, arrSelect5 :this.state.currentArrSelect, popupErrorMessage : ''  });    
                break;
            case 'SELECT6':
                checkedValue = this.state.currentSelect;
                if(checkedValue == ""){this.state.data.filter(x => x.FIELD_VALUE == 'SELECT6')[0].PRIORITY_NUM = 0;}  
                this.setState({ select6: checkedValue, arrSelect6 :this.state.currentArrSelect , popupErrorMessage : '' });     
                break;
            case 'SELECT7':
                checkedValue = this.state.currentSelect;  
                if(checkedValue == ""){this.state.data.filter(x => x.FIELD_VALUE == 'SELECT7')[0].PRIORITY_NUM = 0;}
                this.setState({ select7: checkedValue, arrSelect7 :this.state.currentArrSelect, popupErrorMessage : ''  });   
                break;
            case 'SELECT8':
                checkedValue = this.state.currentSelect;  
                if(checkedValue == ""){this.state.data.filter(x => x.FIELD_VALUE == 'SELECT8')[0].PRIORITY_NUM = 0;}
                this.setState({ select8: checkedValue, arrSelect8 :this.state.currentArrSelect, popupErrorMessage : ''  });   
                break;
            case 'SELECT9':
                checkedValue = this.state.currentSelect;  
                if(checkedValue == ""){this.state.data.filter(x => x.FIELD_VALUE == 'SELECT9')[0].PRIORITY_NUM = 0;}
                this.setState({ select9: checkedValue, arrSelect9 :this.state.currentArrSelect, popupErrorMessage : ''  });   
                break;
            case 'SELECT10':
                checkedValue = this.state.currentSelect;  
                if(checkedValue == ""){this.state.data.filter(x => x.FIELD_VALUE == 'SELECT10')[0].PRIORITY_NUM = 0;}
                this.setState({ select10: checkedValue, arrSelect10 :this.state.currentArrSelect , popupErrorMessage : '' });    
                break;
            default:                
                break;
          }
        this.state.data.filter(x => x.FIELD_VALUE == this.state.sccSelectedDropdown)[0].CHILD_FIELD_NAME = checkedValue;
        this.hideModal("sccDropdown");
        }
        else
        {
            this.setState({ popupErrorMessage : 'Duplicate Combination' });
        }
    }

    closeSccDropdownPopup(){
        this.setState({ isCheck1: false,isCheck2: false,isCheck3: false,isCheck4: false,isCheck5: false,isCheck6: false,isCheck7: false,isCheck8: false,isCheck9: false,isCheck10: false });
        var length = this.state.data.filter(x => x.FIELD_VALUE == this.state.sccSelectedDropdown)[0].CHILD_FIELD_NAME.length;
        if(length == 0 )
        {
        switch(this.state.sccSelectedDropdown) {
            case 'SELECT1':
                this.setState({ select1: '' ,arrSelect1: [] });                
                break;
            case 'SELECT2':
                this.setState({ select2: '' ,arrSelect2: [] });      
                break;
            case 'SELECT3':
                this.setState({ select3: '' ,arrSelect3: [] });      
                break;
            case 'SELECT4':
                this.setState({ select4: '' ,arrSelect4: [] });   
                break;
            case 'SELECT5':
                this.setState({ select5: '' ,arrSelect5: [] });   
                break;
            case 'SELECT6':
                this.setState({ select6: '' ,arrSelect6: [] });   
                break;
            case 'SELECT7':
                this.setState({ select7: '' ,arrSelect7: [] });   
                break;
            case 'SELECT8':
                this.setState({ select8: '' ,arrSelect8: [] });   
                break;
            case 'SELECT9':
                this.setState({ select9: '' ,arrSelect9: [] });   
                break;
            case 'SELECT10':
                this.setState({ select10: '' ,arrSelect10: [] });   
                break;
            default:                
                break;
          }
        }
        this.hideModal("sccDropdown");
    }

    sccOnSaveValidation() {
        if (this.state.currentArrSelect.length > 0) {
            if (this.state.currentArrSelect.sort().join(',') === this.state.arrSelect1.sort().join(',') && this.state.sccSelectedDropdown != 'SELECT1')
                return false;
            else if (this.state.currentArrSelect.sort().join(',') === this.state.arrSelect2.sort().join(',') && this.state.sccSelectedDropdown != 'SELECT2')
                return false;
            else if (this.state.currentArrSelect.sort().join(',') === this.state.arrSelect3.sort().join(',') && this.state.sccSelectedDropdown != 'SELECT3')
                return false;
            else if (this.state.currentArrSelect.sort().join(',') === this.state.arrSelect4.sort().join(',') && this.state.sccSelectedDropdown != 'SELECT4')
                return false;
            else if (this.state.currentArrSelect.sort().join(',') === this.state.arrSelect5.sort().join(',') && this.state.sccSelectedDropdown != 'SELECT5')
                return false;
            else if (this.state.currentArrSelect.sort().join(',') === this.state.arrSelect6.sort().join(',') && this.state.sccSelectedDropdown != 'SELECT6')
                return false;
            else if (this.state.currentArrSelect.sort().join(',') === this.state.arrSelect7.sort().join(',') && this.state.sccSelectedDropdown != 'SELECT7')
                return false;
            else if (this.state.currentArrSelect.sort().join(',') === this.state.arrSelect8.sort().join(',') && this.state.sccSelectedDropdown != 'SELECT8')
                return false;
            else if (this.state.currentArrSelect.sort().join(',') === this.state.arrSelect9.sort().join(',') && this.state.sccSelectedDropdown != 'SELECT9')
                return false;
            else if (this.state.currentArrSelect.sort().join(',') === this.state.arrSelect10.sort().join(',') && this.state.sccSelectedDropdown != 'SELECT10')
                return false;
            else
                return true;
        }
        else
            return true;
    }

    sccCheckboxClicked(e)  
    {
        var currentArrChecked = [...this.state.currentArrSelect] ;
        if(e.target.checked)
            currentArrChecked.push(e.target.value)
        else
        {
            const index = currentArrChecked.indexOf(e.target.value);
            currentArrChecked.splice(index, 1);        
        }
        
        var currentSelected = currentArrChecked.length == 0 ? '' : currentArrChecked.toString();
        switch(e.target.name) {
            case 'Check1':
                this.setState({ isCheck1: !this.state.isCheck1 });                
                break;
            case 'Check2':
                this.setState({ isCheck2: !this.state.isCheck2 });
                break;
            case 'Check3':
                this.setState({ isCheck3: !this.state.isCheck3 });
                break;
            case 'Check4':
                this.setState({ isCheck4: !this.state.isCheck4 });
                break;
            case 'Check5':
                this.setState({ isCheck5: !this.state.isCheck5  });
                break;
            case 'Check6':
                this.setState({ isCheck6: !this.state.isCheck6 });
                break;
            case 'Check7':
                this.setState({ isCheck7: !this.state.isCheck7 });
                break;
            case 'Check8':
                this.setState({ isCheck8: !this.state.isCheck8 });
                break;
            case 'Check9':
                this.setState({ isCheck9: !this.state.isCheck9 });
                break;
            case 'Check10':
                this.setState({ isCheck10: !this.state.isCheck10 });
                break;
            default:               
                break;
          }

        //   switch(this.state.sccSelectedDropdown) {
        //     case 'SELECT1':
        //         this.setState({ arrSelect1: currentArrChecked });                
        //         break;
        //     case 'SELECT2':
        //         this.setState({ arrSelect2: currentArrChecked });      
        //         break;
        //     case 'SELECT3':
        //         this.setState({ arrSelect3: currentArrChecked });      
        //         break;
        //     case 'SELECT4':
        //         this.setState({ arrSelect4: currentArrChecked });   
        //         break;
        //     case 'SELECT5':
        //         this.setState({ arrSelect5: currentArrChecked });   
        //         break;
        //     case 'SELECT6':
        //         this.setState({ arrSelect6: currentArrChecked });   
        //         break;
        //     case 'SELECT7':
        //         this.setState({ arrSelect7: currentArrChecked });   
        //         break;
        //     case 'SELECT8':
        //         this.setState({ arrSelect8: currentArrChecked });   
        //         break;
        //     case 'SELECT9':
        //         this.setState({ arrSelect9: currentArrChecked });   
        //         break;
        //     case 'SELECT10':
        //         this.setState({ arrSelect10: currentArrChecked });   
        //         break;
        //     default:                
        //         break;
        //   }

          this.setState({ currentArrSelect: currentArrChecked, currentSelect: currentSelected })
    }             


    render() {
        var priority = this.state.autoWavePriority.map(item => {
            if (item.FIELD_NAME === "AUTO_WAVE_HEADER") {
                return (
                    <li>
                        <div className="grid-x medium-12">
                            <div className="medium-4" >
                                <input className="box-input"
                                    id="PRIORITY_NUM"
                                    key={item.FIELD_VALUE}
                                    name="PRIORITY_NUM"
                                    defaultValue={item.PRIORITY_NUM}
                                    onChange={(e) => this.state.isAllowedToEdit && this.onAutoWaveChangeEvent(e, item)}
                                    onBlur={(e) => this.onFocusOut(e, item)}
                                    type="Number"
                                    style={{ borderLeft: '1px solid #e1e1e1', backgroundColor: !this.state.isAllowedToEdit && '#fafafa' }}
                                    readOnly={!this.state.isAllowedToEdit}
                                //style= {{ backgroundColor: this.state.isReadOnly && '#fafafa'}}                           
                                />
                            </div>
                            <div className={item.FIELD_VALUE === this.state.isActive ? "medium-8 box-title lst-button active" : "medium-8 box-title lst-button"}>

                                <span className={item.PRIORITY_NUM > 0 && "checkBg iconBg"}></span>
                                <button value={item.FIELD_VALUE}
                                    className={item.FIELD_VALUE === this.state.isActive && 'active-button'}
                                >
                                    {item.FIELD_DESC}
                                    {item.FIELD_VALUE === "L4_INDUSTRY_GICS_ID" ? <img width="25" value={item.FIELD_VALUE} title={item.FIELD_VALUE} style={{ marginLeft: '10px', cursor: 'pointer' }}
                                        onClick={(e) => this.togglePopUp(e)} src="images/icon-help-over.png" /> : null}
                                    {item.PRIORITY_NUM > 0 ? <div>
                                        <FaAngleRight className="lst-button__icon" />
                                        <FaAngleRight className="lst-button__icon--hover" />
                                    </div>
                                        : null}
                                </button>
                            </div>
                        </div>
                    </li>
                )
            }
        })

        var createLink = () => {
            return (                
                <a onClick={this.handleClick} style={{cursor: 'pointer'}}>Select</a>
            );
          };

        var rowItem = this.state.data.map((item) => {
            return (
                item.FIELD_NAME !== "AUTO_WAVE_HEADER" ?
                    <div className="grid-x">
                        <div className="medium-12 pl20">
                            <div className="grid-x">
                                <div className="medium-8 box-title">
                                    <div className="grid-x grid-padding-x">
                                        <div className="medium-12">
                                            {item.FIELD_VALUE === "SELECT1" || item.FIELD_VALUE === "SELECT2" || item.FIELD_VALUE === "SELECT3" ||
                                                item.FIELD_VALUE === "SELECT4" || item.FIELD_VALUE === "SELECT5" || item.FIELD_VALUE === "SELECT6" ||
                                                item.FIELD_VALUE === "SELECT7" || item.FIELD_VALUE === "SELECT8" || item.FIELD_VALUE === "SELECT9" || item.FIELD_VALUE === "SELECT10"
                                                // ? <a onClick={() => this.openSccDropdown(item.FIELD_VALUE)} style={{cursor: 'pointer'}}>Select</a> :
                                                ? <div className="float-left" style={{display:'ruby',lineHeight:'2'}}><button onClick={() => this.openSccDropdown(item.FIELD_VALUE)} id="sccProcessButton" className="button sccProcessButton"><span className="button__inner">{"Select"}</span></button>
                                                    &nbsp;&nbsp;
                                                    {item.FIELD_VALUE === "SELECT1" ? this.state.select1 :
                                                        item.FIELD_VALUE === "SELECT2" ? this.state.select2 :
                                                            item.FIELD_VALUE === "SELECT3" ? this.state.select3 :
                                                                item.FIELD_VALUE === "SELECT4" ? this.state.select4 :
                                                                    item.FIELD_VALUE === "SELECT5" ? this.state.select5 :
                                                                        item.FIELD_VALUE === "SELECT6" ? this.state.select6 :
                                                                            item.FIELD_VALUE === "SELECT7" ? this.state.select7 :
                                                                                item.FIELD_VALUE === "SELECT8" ? this.state.select8 :
                                                                                    item.FIELD_VALUE === "SELECT9" ? this.state.select9 :
                                                                                        item.FIELD_VALUE === "SELECT10" ? this.state.select10 :
                                                                                            null}
                                                </div> :
                                                item.FIELD_NAME === "L4_INDUSTRY_GICS_ID" ? item.FIELD_DESC : item.FIELD_VALUE}
                                        </div>
                                    </div>
                                </div>
                                <div className="medium-3">
                                    <input className="box-input"
                                        id="PRIORITY_NUM"
                                        key={item.FIELD_VALUE}
                                        name="PRIORITY_NUM"
                                        defaultValue={item.PRIORITY_NUM}
                                        onChange={(e) => this.state.isAllowedToEdit && this.onChangeEvent(e, item , this)}
                                        onBlur={(e) => this.onFocusOut(e, item)}
                                        type="Number"
                                        readOnly={this.state.isReadOnly}
                                        style={{ backgroundColor: this.state.isReadOnly && '#fafafa' }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    : null
            )
        })



        return (
            this.props.spendDetails !== undefined && this.props.spendDetails.currentActiveRequest !== undefined && this.props.spendDetails.currentActiveRequest !== "" ?
                <div className="grid-x medium-12" style={{ backgroundColor: '#fafafa', padding: '10px' }}>
                    {(this.state.isLoading) ?
                        <img style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
                            src={path + `images/ajax-loader.gif`} alt="" />
                        :
                        this.state.isError ?
                            <div className="grid-x medium-12" style={{ margin: '0 0 0.5rem', color: 'red', display: 'flex', justifyContent: 'center', borderTop: '0px' }}>{this.state.responseMessage}{this.state.isWaveDescHaveValue ? <a className="redirectButton" style={{ paddingTop: '0px' }} onClick={this.redirect}>{this.props.wizard.clearWaveDesc}</a> : null}</div>
                            : this.state.isSucess ?
                                <div className="grid-x medium-12" style={{ margin: '0 0 0.5rem', color: '#4fb81c', display: 'flex', justifyContent: 'center', borderTop: '0px' }}>{this.state.responseMessage}</div>
                                : null
                    }

                    {this.state.pendingMsg !== '' && this.state.pendingMsg !== undefined ?
                        <div className="grid-x medium-12" style={{ display: 'flex', justifyContent: 'center', borderTop: '0px' }}>
                            <div style={{ margin: '0 0 0.5rem', color: 'green' }}>{this.state.pendingMsg}</div>
                        </div>
                        : null}

                    {this.state.warning1Msg !== '' && this.state.warning1Msg !== undefined ?
                        <div className="grid-x medium-12" style={{ display: 'flex', justifyContent: 'center', borderTop: '0px' }}>
                            <div style={{ margin: '0 0 0.5rem', color: 'red' }}>{this.state.warning1Msg}</div>
                        </div>
                        : null}

                    {this.state.warning2Msg !== '' && this.state.warning2Msg !== undefined ?
                        <div className="grid-x medium-12" style={{ display: 'flex', justifyContent: 'center', borderTop: '0px' }}>
                            <div style={{ margin: '0 0 0.5rem', color: 'red' }}>{this.state.warning2Msg}</div>
                        </div>
                        : null}

                    <div className="grid-x medium-12 pb10 tab-title ">
                        <div className="grid-x medium-12 heading-with-below-border">
                            <div className="medium-11">{this.props.wizard.tabHeader}

                                <span>
                                    <Popup className="report-tooltip"
                                        trigger={<img style={{ marginLeft: '5px', paddingBottom: '20px' }} className="info-icon" src="images/info-icon.svg" width="15" />}
                                        position="bottom left"
                                        on="hover"
                                    >
                                        <div>
                                            {this.props.wizard.tabDesc}
                                        </div>
                                    </Popup>
                                </span>
                            </div>
                            <div className="medium-1" style={{ textAlign: 'right', cursor: 'pointer', fontSize: '1.6rem' }}
                                onClick={(e) => this.onExpandClick(e)} >{this.state.isExpand ? "-" : "+"}</div>
                        </div>
                    </div>

                    {this.state.isExpand ?
                        <div className="grid-x medium-12">
                            {/* <div className="grid-x medium-12 pb10 pt10">
                                {this.props.wizard.tabDesc}
                            </div> */}

                            <div className="grid-x medium-12">
                                <div className="medium-6"></div>

                                <div className="medium-6 search-input pb10" style={{ paddingLeft: '40px' }}>
                                    <div className="grid-x medium-12 pl20">

                                        <div className="medium-12">
                                            <div className="search-bar">
                                                <input type="text" placeholder="Search" style={{ width: '100%', height: '2.75rem', paddingRight: '35px' }}
                                                    onChange={(e) => this.state.isAllowedToEdit && this.handleUserInput(e)}
                                                />
                                            </div>
                                        </div>


                                        {/* <div className="medium-1 float-right">                                                                            
                              <button className= {classNames('float-right',
                                                    {
                                                        'hand-cursor': this.state.isLoading ? false : (this.state.editedItemPriority.length > 0),
                                                        'gray-scale': this.state.isLoading ? true : (this.state.editedItemPriority.length === 0)
                                                    })}
                                                    onClick={(e) => this.showModal("alert")} 
                                                    disabled={this.state.isLoading ? true : this.state.editedItemPriority.length === 0} 
                                                    type="submit" float >
                                                    <img width="25" title={this.props.content.save} src="images/save_active.svg" />
                                                </button>                         
                         </div>   */}
                                    </div>
                                </div >
                            </div>
                            <div className="grid-x medium-12">

                                <div className="medium-6">
                                    <div className="grid-x medium-12" style={{ marginBottom: '5px' }}>
                                        <div className="medium-4" style={{ fontWeight: 'bold' }}>{this.props.wizard.priority}
                                            <Popup className="report-tooltip"
                                                trigger={<img style={{ marginLeft: '5px' }} className="info-icon" src="images/info-icon.svg" width="15" />}
                                                position="bottom left"
                                                on="hover"
                                            >
                                                <div>
                                                    {this.props.wizard.enbalementPriorityHoverText}
                                                </div>
                                            </Popup>
                                        </div>

                                        <div className="medium-8" style={{ fontWeight: 'bold' }}>{this.props.wizard.prioritizationMetric}
                                            <Popup className="report-tooltip"
                                                trigger={<img style={{ marginLeft: '5px' }} className="info-icon" src="images/info-icon.svg" width="15" />}
                                                position="bottom left"
                                                on="hover"
                                            >
                                                <div>
                                                    {this.props.wizard.enablementMetricHoverText}
                                                </div>
                                            </Popup>
                                        </div>
                                    </div>
                                    <div className="grid-x">
                                        <div className="medium-12" onClick={(e) => this.state.isAllowedToEdit && this.handleCategoryChange(e)}>
                                            <ul>
                                                {priority}
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                                <div className="medium-6" style={{ paddingLeft: '40px' }}>



                                    {!!this.state.selectedCategory && this.state.data.length !== 0 ? //&& !this.state.isError?


                                        <div className="grid-x medium-12 pl20" style={{ marginBottom: '5px' }}>
                                            <div className="medium-8" style={{ fontWeight: 'bold' }}>{this.state.selectedCategory}
                                                <Popup className="report-tooltip"
                                                    trigger={<img style={{ marginLeft: '5px' }} className="info-icon" src="images/info-icon.svg" width="15" />}
                                                    position="bottom left"
                                                    on="hover"
                                                >
                                                    <div>
                                                        {this.state.selectedCategory}
                                                    </div>
                                                </Popup>
                                            </div>

                                            <div className="medium-3" style={{ fontWeight: 'bold' }}>{this.props.wizard.metricPriority}
                                                <Popup className="report-tooltip"
                                                    trigger={<img style={{ marginLeft: '5px' }} className="info-icon" src="images/info-icon.svg" width="15" />}
                                                    position="bottom left"
                                                    on="hover"
                                                >
                                                    <div>
                                                        {this.props.wizard.metricPriorityHoverText}
                                                    </div>
                                                </Popup>
                                            </div>
                                        </div>
                                        : <div className="grid-x medium-12 pl20"> </div>}

                                    <div className="grid-x">
                                        <div className="medium-12">
                                            {this.state.data.length !== 0 ? rowItem : <div className="pt10 pl20">{this.props.wizard.noDataFound}</div>}
                                        </div >
                                    </div >

                                </div>

                            </div>

                            <div>
                                <PopupE className="override-large-popup"
                                    position="right center"
                                    open={this.state.isPopupOpen}
                                    onClose={(e) => this.outsidePopupClick(e)}
                                    closeOnDocumentClick
                                >
                                    <div>
                                        <IndustryOverridePopUp
                                            close={(e) => this.togglePopUp(e)}
                                            readOnly={true}               // hide radio button                            
                                        />
                                    </div>
                                </PopupE>
                            </div>

                            <div>
                                <Modal
                                    ref="alert"
                                    modalStyle={alertModalStyle}
                                    contentStyle={changeModalContentStyle}
                                    backdropStyle={changeBackdropStyle}
                                >
                                    <div className="grid-x grid-padding-x change-popup">
                                        <div className="medium-12">
                                            <button onClick={() => this.hideModal("alert")} className="close" />
                                        </div>
                                        <div className="medium-12 pt30 pb10">
                                            {this.props.wizard.applyPriorityChanges}
                                        </div>
                                        <div className="medium-12 popupOkBtn">
                                            <div className="float-right">
                                                <div className="float-left"><Button
                                                    content={"Yes, recast enablement waves"}
                                                    onClick={() => this.continueNavigation("alert")}
                                                    color /></div>
                                                <div className="float-left"><Button
                                                    content={"No, cancel"}
                                                    onClick={() => this.hideModal("alert")}
                                                    color /></div>
                                            </div>
                                        </div>
                                    </div>
                                </Modal>
                            </div>

                            <div>
                                <Modal
                                    ref="sccDropdown"
                                    modalStyle={alertModalStyle}
                                    contentStyle={changeModalContentStyle}
                                    backdropStyle={changeBackdropStyle}
                                >
                                    <div className="grid-x grid-padding-x change-popup">
                                        <div className="medium-12">
                                            <button onClick={this.closeSccDropdownPopup} className="close" />
                                        </div>
                                        <div className="medium-12 pt30 pb10">
                                            <table>
                                                <tr>
                                                    <td>
                                                        <Checkbox id="Forecast Collaboration" checked={this.state.isCheck1} onClick={(e) => this.sccCheckboxClicked(e)} name="Check1" value="Forecast Collaboration" />
                                                    </td>
                                                    <td>
                                                        Forecast Collaboration
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <Checkbox id="Supplier Managed Inventory" checked={this.state.isCheck2} onClick={(e) => this.sccCheckboxClicked(e)} name="Check2" value="Supplier Managed Inventory" />
                                                    </td>
                                                    <td>
                                                        Supplier Managed Inventory
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <Checkbox id="Standard Order Process" checked={this.state.isCheck3} onClick={(e) => this.sccCheckboxClicked(e)} name="Check3" value="Standard Order Process" />
                                                    </td>
                                                    <td>
                                                        Standard Order Process
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <Checkbox id="Consignment Collaboration" checked={this.state.isCheck4} onClick={(e) => this.sccCheckboxClicked(e)} name="Check4" value="Consignment Collaboration" />
                                                    </td>
                                                    <td>
                                                        Consignment Collaboration
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <Checkbox id="Scheduling Agreement" checked={this.state.isCheck5} onClick={(e) => this.sccCheckboxClicked(e)} name="Check5" value="Scheduling Agreement" />
                                                    </td>
                                                    <td>
                                                        Scheduling Agreement
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <Checkbox id="Subcontracting" checked={this.state.isCheck6} onClick={(e) => this.sccCheckboxClicked(e)} name="Check6" value="Subcontracting" />
                                                    </td>
                                                    <td>
                                                        Subcontracting
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <Checkbox id="Quality Notifications" checked={this.state.isCheck7} onClick={(e) => this.sccCheckboxClicked(e)} name="Check7" value="Quality Notifications" />
                                                    </td>
                                                    <td>
                                                        Quality Notifications
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <Checkbox id="Quality Inspection" checked={this.state.isCheck8} onClick={(e) => this.sccCheckboxClicked(e)} name="Check8" value="Quality Inspection" />
                                                    </td>
                                                    <td>
                                                        Quality Inspection
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <Checkbox id="Quality Review" checked={this.state.isCheck9} onClick={(e) => this.sccCheckboxClicked(e)} name="Check9" value="Quality Review" />
                                                    </td>
                                                    <td>
                                                        Quality Review
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <Checkbox id="Returns" checked={this.state.isCheck10} onClick={(e) => this.sccCheckboxClicked(e)} name="Check10" value="Returns" />
                                                    </td>
                                                    <td>
                                                        Returns
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={2}>
                                                        <div style={{ color: 'red', textAlign: 'center' }} className="cell pt5">{this.state.popupErrorMessage}</div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                        
                                        <div className="medium-12 popupOkBtn">
                                            <div className="float-right">
                                                <div className="float-left"><Button
                                                    content={"Save"}
                                                    onClick={() => this.saveSccDropdown()}
                                                    color /></div>
                                                <div className="float-left"><Button
                                                    content={"cancel"}
                                                    onClick={this.closeSccDropdownPopup}
                                                    color /></div>
                                            </div>
                                        </div>
                                    </div>
                                </Modal>
                            </div>
                        </div>
                        : null}
                </div>
                :
                <div className="grid-x">
                    <div className="medium-12">
                        {this.props.content.noActiveRequestFile}
                    </div >
                </div >
        )
    }
}
const mapStateToProps = state => {
    return {
        // language: state.language,
        userData: state.valueStory,
        user: state.default,
        content: state.content.spendAnalysis.accountParameters,
        wizard: state.content.spendAnalysis.prioritizationWizard,
        validation: state.content.validation,
        spendDetails: state.spendDetails
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getYourFileLoading: bool => dispatch(getYourFileLoading(bool)),
        currentActiveRequest: (requestDetails) => dispatch(currentActiveRequest(requestDetails)),
        UpdateRequestParameter: requestDetails => dispatch(UpdateRequestParameter(requestDetails)),
        currentActiveRequest: (requestDetails) => dispatch(currentActiveRequest(requestDetails)),
        getAllEnablementParameterPriority: (requestDetails) => dispatch(getAllEnablementParameterPriority(requestDetails)),
        updateEnablementParameterPriority: (data) => dispatch(updateEnablementParameterPriority(data)),
        pendingChanges: changes => dispatch(pendingChanges(changes)),
        getEnablementDetails: (data) => dispatch(getEnablementDetails(data))
        , redirectFromWizard: (flag) => dispatch(redirectFromWizard(flag)),
        updateEnablementStrategyWaveData: (DMCId, VSId, userEmail) => dispatch(updateEnablementStrategyWaveData(DMCId, VSId, userEmail)),

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EnablementStrategyWizard)
