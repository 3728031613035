import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

// Components
import SpendTabPanel from '../../Tabs/SpendTabPanel'

import SubTabsContainer from 'components/Spend/SubTabsContainer'
import IndustryBreakdownContainer from 'components/Spend/IndustryBreakdown/IndustryBreakdownContainer'
import SpendFilesContainer from 'components/Spend/SpendDetails/SpendFilesContainer'
import ScopeSummaryContainer from 'components/Spend/ScopeSummary/ScopeSummaryContainer'
import { ScreenE, ReadOnlyContext } from 'UI/CustomComponents/Controls';
import WorldMapContainer from 'components/Spend/WorldMap/WorldMapContainer'
import EnablementInsightContainer from 'components/Spend/EnablementInsight/EnablementInsightContainer'

import {
	valueStoryCurrentMenu,
	showValueStoryMenu
} from 'actions/userActions'

import { UpdateComment } from 'actions/spendActions'

import {
	setValueStoryDmciDetails,
	setResultSummaryDetails,
	setTopDetails,
	resetError,
	clearCurrentActiveRequest,
	getCapIQDataBlank,
	redirectFromEnablementTab
} from 'actions/spendActions'

import {
	getCurrentLanguage
} from 'utils/Utils'

import { routes } from 'Constants'
import SpendNextStepsContainer from '../../NextSteps/SpendNextStepsContainer';
import classNames from "classnames";
import SccComponent from '../../Scc/SccComponent';



class DiscoveryAndYourSpendContainer extends Component {

	constructor(props) {
		super(props)

		this.state = {
			tabId: 1,
			nextButtonLabel: props.ui.next,
			language: '',
			btnDisabled: this.props.userData.VSId === undefined || this.props.userData.VSId === 0
		}

		this.dispatchTabChange = this.dispatchTabChange.bind(this)
		this.intializePrioritiesTabs = this.intializePrioritiesTabs.bind(this)
		this.dispatchLastButtonClick = this.dispatchLastButtonClick.bind(this)
		this.handleNextButton = this.handleNextButton.bind(this)
		this.actions = this.getApplicationFeatureActions()
		//this.redirectFromWizard = this.redirectFromWizard.bind()

	}



	// shouldComponentUpdate() {
	// 	return true;
	// }
	componentWillReceiveProps(nextProps) {
		//if (this.props.spendDetails.currentActiveRequest !== nextProps.spendDetails.currentActiveRequest) {
		this.intializePrioritiesTabs();
		//}
		let language = getCurrentLanguage();
		if (language != this.state.language) {
			this.dispatchTabChange(this.state.tabId)
			//this.setState({language: language})
		}

		if (this.props !== nextProps) { //&& this.props.spendDetails !== nextProps.spendDetails){
			if (nextProps.userData.VSId === undefined || nextProps.userData.VSId === 0 || nextProps.userData.ValueStoryName === ""
				|| nextProps.userData.Industry === 0 || nextProps.userData.Industry === "" || nextProps.userData.Organization === ""
				|| nextProps.spendDetails.currentActiveRequest === undefined || nextProps.spendDetails.currentActiveRequest === "") {
				this.setState({ btnDisabled: true })
			}
		}
	}

	componentWillUnmount() {
		this.props.resetError();
		if (this.props.userData.VSId === undefined || this.props.userData.VSId === 0) {
			this.props.clearCurrentActiveRequest();
		}
	}

	componentDidMount() {
		//calling this to reset tab value to 1 after redirect
		this.props.redirectFromEnablementTab(false)
		let flag = localStorage.getItem('enablementInsightFlag')
		if (flag == "true") {
			this.setState({ enablementInsightFlag: true })
		} else {
			this.setState({ enablementInsightFlag: false })
		}
	}

	intializePrioritiesTabs() {
		const tabs = this.state.enablementInsightFlag ? [
			{
				id: 1,
				label: this.props.content.spendAnalysis.yourFile.title,
				component: (<SubTabsContainer param={this.props.content.spendAnalysis.yourFile.title} />)
			},
			{
				id: 2,
				label: this.props.content.spendAnalysis.detailedFile.title,
				component:
					<ReadOnlyContext.Consumer>
						{(readOnly) => (
							<SpendFilesContainer readOnly={readOnly} />
						)}
					</ReadOnlyContext.Consumer>
			},
			{
				id: 3,
				label: this.props.enablementInsightTexts.title,
				component: (<EnablementInsightContainer />)
			},
			{
				id: 4,
				label: this.props.ganntTexts.title,
				component: (<SubTabsContainer param={this.props.ganntTexts.title} />)
			},
			{
				id: 5,
				label: this.props.content.spendAnalysis.spendReport.title,
				component: (<SpendNextStepsContainer />)
			}
		] : [
			{
				id: 1,
				label: this.props.content.spendAnalysis.yourFile.title,
				component: (<SubTabsContainer param={this.props.content.spendAnalysis.yourFile.title} history={this.props.history} />)
			},
			{
				id: 2, //Classification & Top Details
				label: this.props.content.spendAnalysis.results.title,
				component: (<SubTabsContainer param={this.props.content.spendAnalysis.results.title} />)
			},
			{
				id: 3,
				label: this.props.content.spendAnalysis.defineScope.title,
				component: (<IndustryBreakdownContainer />)
			},
			{
				id: 4,
				label: this.props.content.spendAnalysis.detailedFile.title,
				component:
					<ReadOnlyContext.Consumer>
						{(readOnly) => (
							<SpendFilesContainer readOnly={readOnly} />
						)}
					</ReadOnlyContext.Consumer>
			},
			{
				id: 5,
				label: this.props.enablementInsightTexts.title,
				component: (<EnablementInsightContainer />)
			},
			{
				id: 6,
				label: this.props.ganntTexts.title,
				component: (<SubTabsContainer param={this.props.ganntTexts.title} />)
			},
			{
				id: 7,
				label: this.props.content.spendAnalysis.scopeSummary.title,
				component: (<ScopeSummaryContainer />)
			}
			,
			{
				id: 8, //Program Enablement Summary
				label: this.props.content.spendAnalysis.enablement.title,
				component: (<SubTabsContainer param={this.props.content.spendAnalysis.enablement.title} />)
			}
			, {
				id: 10,
				label: this.props.content.spendAnalysis.worldMap.title,
				component: (<WorldMapContainer />)
			}
			// , {
			// 	id: 11,
			// 	label: 'KPI',
			// 	component: (<SubTabsContainer param={'KPI'} />)
			// }
			// , {
			// 	id: 11,
			// 	label: this.props.spendDetails.kpi_Model == 1 ? 'Intelligent KPIs' : 'KPI based KPIs',
			// 	component: (<SubTabsContainer param={'KPI-V2'} />)
			// }
			, {
				id: 12,
				label: this.props.content.spendAnalysis.spendReport.title,
				component: (<SpendNextStepsContainer />)
			},

		];

		// if (this.props.spendDetails.solutionScope == 'Supply Chain Collaboration') {
		// 	tabs.push({
		// 		id: 9,
		// 		label: 'SCC Spend Details ',
		// 		component: (<SccComponent />)
		// 	})

		// 	tabs.sort(function (a, b) {
		// 		return a.id - b.id;
		// 	})
		// }

		tabs.sort(function (a, b) {
			return a.id - b.id;
		})

		if (this.props.spendDetails.kpi_Model == 1) {
			tabs.push({
				id: 11,
				label: 'Intelligent KPIs',
				component: (<SubTabsContainer param={'KPI'} />)
			})

			tabs.sort(function (a, b) {
				return a.id - b.id;
			})
		}
		else if (this.props.spendDetails.kpi_Model == 2) {
			tabs.push({
				id: 11,
				label: 'KPI based KPIs',
				component: (<SubTabsContainer param={'KPI-V2'} />)
			})

			tabs.sort(function (a, b) {
				return a.id - b.id;
			})
		}

		if (this.props.spendDetails.currentActiveRequest === undefined || this.props.spendDetails.currentActiveRequest === "") {
			var newArray = [];
			newArray.push(tabs[0])
			this.setState({
				tabs: newArray,
				tabId: 1
			})
		}
		else {
			this.setState({
				tabs: tabs,
				tabId: this.state.tabId
			})
		}

	}

	dispatchTabChange(tabId) {
		if (this.props.spendDetails.isLoading === false) {
			if (tabId >= 1 && this.props.spendDetails.currentActiveRequest !== undefined && this.props.spendDetails.currentActiveRequest !== "") {
				// this.props.redirectFromEnablementTab(false)
				this.setState({
					tabId: tabId,
					nextButtonLabel: this.props.ui.next,
					btnDisabled: false
				})

				var maxValue = Math.max.apply(null, this.state.tabs.map(function (val) { return val.id; }));

				if (tabId === maxValue) {
					this.setState({
						tabId: tabId,
						nextButtonLabel: this.props.ui.next,
						btnDisabled: true
						//this.props.content.priorities.goToSolutionsButton
						//	render: true
					})
				}
			}
			else { return false; }
		}
	}

	dispatchLastButtonClick(tabId) {
		//this.props.history.push(`/skus`)	

		if (this.actions.indexOf(routes.areaOfInterest) > -1) {
			this.props.history.push(`/skus`)
		}
		else {
			if (this.actions.indexOf(routes.businessValue) > -1) {
				this.props.history.push(`/next-steps`)
			}
			else {
				this.props.history.push(`/yourSpend`)
			}
		}
	}
	clearSpendData() {
		this.props.setValueStoryDmciDetails([])
		this.props.setResultSummaryDetails([])
		this.props.setTopDetails([])
	}
	componentWillMount() {
		this.props.valueStoryCurrentMenu('Spend');
		this.props.showValueStoryMenu(true);
		window.scrollTo(0, 0)
		//	this.getNextPage()
		this.intializePrioritiesTabs()
		this.clearSpendData()
	}

	handleNextButton(tabId) {
		if (tabId === 2) {
			if (this.props.spendDetails.currentActiveRequest.UPDATED_COMMENTS !== '') {
				this.props.UpdateComment(this.props.spendDetails.currentActiveRequest).then(res => {
					//
				})
			}
		}
	}

	getApplicationFeatureActions() {
		let actions = [];
		let applicationFeatures = this.props.user && this.props.user.user && this.props.user.user.applicationFeatures ? this.props.user.user.applicationFeatures : [];
		applicationFeatures.map(applicationFeat => {
			actions.push(applicationFeat.Action);
		})
		return actions;
	}

	render() {
		return (
			<ScreenE readOnly={!this.props.userData.AllowEdit}>
				<div className="grid-x">
					<div className={classNames("spend", { "spend-width-8": this.actions.indexOf(routes.businessValue) > -1, "spend-width-2": this.actions.indexOf(routes.businessValue) === -1 })}>
						{/* Hiding this as per ticket AV-709
						<div className="grid-x">
							<div className="page-title">
								{this.props.content.spendAnalysis.title}
							</div>
						</div> */}
						<div className="grid-x business_tabs">
							<div className="medium-12">
								<SpendTabPanel tabs={this.state.tabs}
									tabId={this.props.spendDetails.redirectedFromWizard ? 5 : 1}
									dispatchTabChange={this.dispatchTabChange}
									dispatchLastButtonClick={this.dispatchLastButtonClick}
									nextButtonLabel={this.state.nextButtonLabel}
									handleNextButton={this.handleNextButton}
									spendDetails={this.props.spendDetails}
									toggle={this.props.pendingChanges}
									btnDisabled={this.state.btnDisabled}
								/>
							</div>
						</div>
					</div >
				</div>
			</ScreenE>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		ui: state.content.ui,
		content: state.content,
		spendDetails: state.spendDetails,
		pendingChanges: state.detailedFileChanges.changes,
		userData: state.valueStory,
		user: state.default,
		ganntTexts: state.content.ganntChartTab,
		enablementInsightTexts: state.content.enablementInsight
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		valueStoryCurrentMenu: (menuItem) => dispatch(valueStoryCurrentMenu(menuItem)),
		showValueStoryMenu: (menuItem) => dispatch(showValueStoryMenu(menuItem)),
		UpdateComment: requestDetails => dispatch(UpdateComment(requestDetails)),
		setValueStoryDmciDetails: (data) => dispatch(setValueStoryDmciDetails(data)),
		setResultSummaryDetails: (data) => dispatch(setResultSummaryDetails(data)),
		setTopDetails: (data) => dispatch(setTopDetails(data)),
		resetError: error => dispatch(resetError(error)),
		clearCurrentActiveRequest: () => dispatch(clearCurrentActiveRequest()),
		getCapIQDataBlank: bool => dispatch(getCapIQDataBlank(bool)),
		redirectFromEnablementTab: (flag) => dispatch(redirectFromEnablementTab(flag)),
		//redirectFromWizard: (flag) => dispatch(redirectFromWizard(flag))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DiscoveryAndYourSpendContainer)