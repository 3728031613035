import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
 
import Popup from "reactjs-popup";

export default class ZoomModal extends React.Component
{
  constructor(props) {
    super(props);

    this.state = {
      open: false
    }

    this.open = this.open.bind(this)
    this.closePopUp = this.closePopUp.bind(this)
    this.outsidePopupClick = this.outsidePopupClick.bind(this)
  }

  render() {
    return (
      <span>
      <div className="text-align-right">
        <span onClick={this.open} style={{paddingRight:'10px'}} className="hand-cursor font-bold bigFont">&#43;</span>
      </div>
      {!this.state.open? 
      <div>
          {this.props.children}
        </div>
        :
      <Popup className="large-popup-100"
        position="right center"
        open={this.state.open}
        onClose={this.outsidePopupClick}
        closeOnDocumentClick
        >
          <a className="close" onClick={this.outsidePopupClick}>
        </a>
        <div className={this.props.classStyle? this.props.classStyle : ''}>
          {this.props.children}
        </div>
      </Popup>
      }
      </span>
    );
  }

  open() {
    if(this.props.mode) {
      this.props.mode('zoomed')
    }

    this.setState({ open: true })
  }

  closePopUp() {
    this.setState({ open: false })
  }

  outsidePopupClick() {
    if(this.props.mode) {
      this.props.mode('normal')
    }

    this.setState({ open: false })
  }
}

ZoomModal.propTypes = {
  children: PropTypes.node.isRequired,
};