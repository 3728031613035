import React from 'react';
import { connect } from 'react-redux'
import { Control, Form, Errors, actions } from 'react-redux-form'
// UI
import Button from 'UI/Button'
import Select from 'react-select'
import Textfield from 'react-mdl/lib/Textfield'
// Settings
import { SelectField, Option } from 'react-mdl-extra'
import { validators } from 'Constants'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  fetchReports,
  fetchFiles,
  fetchSections,
  fetchMappings,
  updateSequence,
  userDetailsSuccess,
  userDetailsFailure,
  userDetailsLoading,
  resetError
}
  from 'actions/userActions'

import { dropdownStyles } from 'react-select-css'

// // fake data generator
// const getItems = count =>
//   Array.from({ length: count }, (v, k) => k).map(k => ({
//     id: `item-${k}`,
//     content: `item ${k}`,
//   }));


// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 4;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  cursor: 'pointer',
  padding: grid,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : 'white',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getItemSelectedStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  cursor: 'pointer',
  padding: grid,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: 'rgb(101, 189, 248)',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'lightblue' : ' #c7def3',
  padding: grid,
  width: 250,
});

var options = [];

class SequenceManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reports: [],
      reportId: 0,
      reportSelected: false,
      items: [],
      newItems: [],
      sections: [],
      switch: false,
      mymessage: "",
      selectedOption: '',
      isSequenceReportSelected: ''
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  componentWillMount() {
    document.addEventListener('mousedown', this.handleClick, false);
    this.loadReports();
    this.loadSections();
  }
  //this function is called to populate the report dropdown
  loadReports() {
    this.props.fetchReports()
      .then((response) => {
        var reportData = [];
        this.props.userDetailsSuccess(response.data.Message)
        for (var i = 0; i < response.data.Result.length; i++) {
          var obj = response.data.Result[i];
          if (obj.Name === "Default") {
            continue;
          }
          reportData.push(obj);
        }
        this.setState({ reports: reportData })
        var array = [];
        this.state.reports.forEach((element) => {
          var obj = {};
          obj.label = element.Name;
          obj.value = element.Id;
          array.push(obj);
        });
        options = array;
      }).catch((error) => {
        if (error.stack.includes('Network Error')) {
          this.props.userDetailsFailure()
        }
      })
  }
  //this function is called to populate sections on sequence manager
  loadSections() {
    this.props.fetchSections()
      .then((response) => {
        var sectionData = [];
        this.props.userDetailsSuccess(response.data.Message)
        for (var i = 0; i < response.data.Result.length; i++) {
          var obj = response.data.Result[i];
          sectionData.push(obj);
        }
        this.setState({ sections: sectionData })
      }).catch((error) => {
        if (error.stack.includes('Network Error')) {
          this.props.userDetailsFailure()
        }
      })

  }
  // this function loads the preexisting sequence of reports from db
  loadSequence() {
    this.props.fetchMappings()
      .then((response) => {
        var sectionData = [];
        this.props.userDetailsSuccess(response.data.Message)
        for (var i = 0; i < response.data.Result.length; i++) {
          var obj = response.data.Result[i];
          if (obj.ReportID == this.state.reportId) {
            sectionData.push(obj);
          }
        }
        this.setState({ items: sectionData, newItems: sectionData })
      }).catch((error) => {
        if (error.stack.includes('Network Error')) {
          this.props.userDetailsFailure()
        }
      })
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    );

    this.setState({
      items,
    });

    this.setState({ newItems: items });
  }
  //this function is used for highlighting the entries in section table
  isSelected(e) {
    let sec = [];
    this.state.sections.map(section => {
      var concat = section.FileName + '->' + section.SectionName;
      if (e.target.textContent == concat) {
        if (section.isEditing) {
          section.isEditing = false;
        }
        else {
          section.isEditing = true;
          this.setState({ switch: true })
        }
      }
      sec.push(section)
    })
    this.setState({ sections: sec })
  }
  //this function is used for removing highlight from selected entries
  deselect() {
    this.props.resetError();
    this.setState({ mymessage: "" });
    let secqn = [];
    this.state.sections.map(section => {
      section.isEditing = false;
      secqn.push(section);
    })

    let slide = [];
    this.state.items.map(item => {
      item.highlight = false;
      slide.push(item);
    })
    this.setState({ sections: secqn, items: slide })
  }
  //this function is used for highlighting the entries in sequence table
  selectedSequence(e) {
    let sec = [];
    this.state.items.map(section => {
      if (e.target.textContent == section.SectionName) {
        if (section.highlight) {
          section.highlight = false;
        }
        else {
          section.highlight = true;
          this.setState({ switch: true })
        }
      }
      sec.push(section)
    })
    this.setState({ items: sec })
  }

  handleReportChange(value) {
    this.setState({ mymessage: "" })
    this.state.reports.map(report => {
      if (value.label == report.Name) {
        this.setState({ reportId: report.Id, reportSelected: true, selectedOption: value.label })
      }
    })
    this.loadSequence();
  }

  onDDLSequenceReportClickEvent = () => {
    this.setState({ isSequenceReportSelected: 'Selected' });
  }

  onDDLSequenceReportBlurEvent = () => {
    this.setState({ isSequenceReportSelected: '' });
  }

  //this function is only used for adding the selected sections to sequence list
  add() {
    this.props.resetError();
    this.setState({ mymessage: "" });
    if (this.state.reportId > 0) {
      if (this.state.switch) {
        this.state.sections.map(secn => {
          if (secn.isEditing) {
            var result = this.state.items.find(item => secn.SectionName == item.SectionName) === undefined
            if (result) {
              this.state.newItems.push(secn);
              secn.isEditing = false;
            }
            else {
              this.props.userDetailsFailure("This section/sections are already present")
            }

          }
          //  seqn.push(secn);
        })
      }
      this.setState({ items: this.state.newItems })
    }
    else {
      this.props.userDetailsFailure("Please Select the report from dropdown")
    }

  }

  //this function is used for removing specific sections from the sequence list
  remove() {
    this.props.resetError();
    this.setState({ mymessage: "" });
    let seqn = [];
    this.state.items.map(item => {
      seqn.push(item)
    })
    if (this.state.switch) {
      this.state.items.map(secn => {
        if (secn.highlight) {
          seqn.splice(seqn.indexOf(secn), 1)
          secn.highlight = false;
        }
      })
    }
    this.setState({ items: seqn, newItems: seqn })
  }

  // submits the sequence provided by user to db
  submit() {
    this.props.resetError();
    if (this.state.reportId > 0) {
      var updateSequence = [];
      var SlideSequence = 0;
      this.state.items.map(slides => {
        var reportDetail = {
          SectionId: slides.SectionID,
          AddedBy: localStorage.getItem('loggedinUser'),
          ReportID: this.state.reportId,
          SlideSequence: ++SlideSequence
        }
        updateSequence.push(reportDetail)
      })
      if (updateSequence.length > 0) {
        this.setState({ mymessage: "" });
        this.props.updateSequence(updateSequence).then((results) => {
          this.setState({ mymessage: results.data.Message });
        }).catch((error) => {
          if (error.stack.includes('Network Error')) {
            this.props.userDetailsFailure('API service is down. Please contact administrator.')
          }
          else {
            this.props.userDetailsFailure(error.response.data.Message)
          }
        })
      }
      else {
        this.props.userDetailsFailure("Report Sequence cannot be empty")
      }
    }
    else {
      this.props.userDetailsFailure("Please Select the report before submitting the sequence")
    }

  }
  handleClick = (e) => {
    if (this.node.contains(e.target)) {
      return;
    }
  }
  componentWillUnmount() {
    //reset error message
    document.removeEventListener('mousedown', this.handleClick, false);
    this.formDispatch(actions.reset('slideManager'))
    this.props.resetError();
  }

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  render() {
    return (
      <div className="grid-x grid-padding-x popup-content" ref={node => this.node = node}>
        <div className="medium-12 title">{this.props.content.title}</div>
        <div className="medium-4 cell grid-padding-5">
          <Form model="slideManager"
            getDispatch={(dispatch) => this.formDispatch = dispatch}>
            <div onClick={this.onDDLSequenceReportClickEvent.bind(this)} onBlur={this.onDDLSequenceReportBlurEvent.bind(this)}>
              {/* <Control.select
              className="role__select"
              model="slideManager.Files"
              id="slideManager.Files"
              component={SelectField}
              label={this.props.content.report}
              onChange={(value) => this.handleReportChange(value)}
              // validators={{ required: (value) => validators.validSelect(this.props.content.selector.indexOf(value)) }}
              validateOn="change"
              floatingLabel
            >
            {
              this.state.reports.map((filter) =>
              <Option
                key={filter.Id}
                value={filter.Name}
              >{filter.Name}</Option>)
            }        
                  </Control.select> */}
              {
                ((this.state.isSequenceReportSelected === 'Selected' && this.state.selectedOption !== '')
                  ||
                  (this.state.isSequenceReportSelected === '' && this.state.selectedOption !== ''))
                  ? <label style={{ visibility: 'visible' }} class="css-floatingLabel-left"
                    for="slideManager.Files">{this.props.content.report}</label>
                  : <label style={{ visibility: 'hidden' }} class="css-floatingLabel-left"
                    for="slideManager.Files">{this.props.content.report}</label>
              }

              <Select
                //className="role__select"
                model="slideManager.Files"
                id="slideManager.Files"
                styles={dropdownStyles}
                placeholder={this.props.content.report}
                value={options.filter(option => option.label === this.state.selectedOption)}
                noOptionsMessage={() => null}
                onChange={(value) => this.handleReportChange(value)}
                options={options}
                isSearchable={false}
                classNamePrefix="gen-react-select"
                className="gen-react-select-container"
              />

              <Control.text
                //className="role__select"
                model="slideManager.Files"
                id="slideManager.Files"
                value={this.state.selectedOption}
                hidden
                validateOn="blur"
                component={Textfield}
                validators={{
                  required: validators.required
                }} />
              <Errors
                className="errors"
                style={{ margin: '-37px 0px' }}
                model="slideManager.Files"
                show="touched"
                messages={{
                  required: this.props.validation.required
                }}
              />
            </div>
          </Form>
        </div>
        <div className="medium-1 cell grid-padding-5"></div>
        <div className="medium-2 cell pt13" >
          <Button
            type="submit"
            disabled={this.props.user.loading}
            content="Submit"
            onClick={this.submit.bind(this)}
            color
          />
        </div>
        <div className="medium-5 cell grid-padding-5"></div>
        <div className="medium-12 cell grid-padding-5">
          <div className="errors" style={{ margin: '-30px 0px', fontSize: '0.8rem', fontFamily: 'BentonSans, Arial, Helvetica, sans-serif' }}>{this.props.user.error}</div>
          <div className="success">{this.state.mymessage}</div>
        </div>
        <div className="medium-3 cell grid-padding-5">
          <Button
            type="submit"
            disabled={this.props.user.loading}
            content="Add"
            onClick={this.add.bind(this)}
            color
          />
        </div>
        <div className="medium-3 cell grid-padding-5">
          <Button
            type="submit"
            disabled={this.props.user.loading}
            content="Reset Selection"
            onClick={this.deselect.bind(this)}
            color
          />
        </div>
        <div className="medium-1"></div>
        <div className="medium-3 cell grid-padding-5">
          <Button
            type="submit"
            disabled={this.props.user.loading}
            content="Remove"
            onClick={this.remove.bind(this)}
            color
          />
        </div>
        <div className="medium-12 cell grid-padding-5">
          <br />
        </div>
        {/* <div className="medium-5 cell grid-padding-5" onClick={this.deselect.bind(this)}></div> */}
        {/* <div className="medium-12 cell grid-padding-5" onClick={this.deselect.bind(this)}> */}

        <div className="medium-6 cell">
          <div className="container"><span className="section">Section Name</span>
            {this.state.sections.map((secn) => (
              <div className={secn.isEditing ? "highlight" : "innerContainer"} value={secn.FileName + secn.SectionName} onClick={this.isSelected.bind(this)}>
                {secn.FileName + '->' + secn.SectionName}
              </div>
            ))}
          </div>
        </div>
        <div className="medium-1 cell grid-padding-5" onClick={this.deselect.bind(this)}>
        </div>
        <div className="medium-5 cell">
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                ><span className="section">Section Sequence</span>
                  {this.state.items.map((item, index) => (
                    <Draggable key={item.Id} draggableId={item.Id} index={index}>
                      {(provided, snapshot) => (
                        <div className="section-drop-row"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={item.highlight ? getItemSelectedStyle(snapshot.isDragging,
                            provided.draggableProps.style) : getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          value={item.SectionName}
                          onClick={this.selectedSequence.bind(this)}
                        >
                          {item.SectionName}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
    ui: state.content.ui,
    user: state.default,
    content: state.content.sequenceManager,
    validation: state.content.validation
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchReports: (formValues) => dispatch(fetchReports(formValues)),
    fetchFiles: (formValues) => dispatch(fetchFiles(formValues)),
    fetchSections: (formValues) => dispatch(fetchSections(formValues)),
    fetchMappings: (formValues) => dispatch(fetchMappings(formValues)),
    updateSequence: (formValues) => dispatch(updateSequence(formValues)),
    userDetailsLoading: (bool) => dispatch(userDetailsLoading(bool)),
    userDetailsSuccess: (bool) => dispatch(userDetailsSuccess(bool)),
    userDetailsFailure: (error) => dispatch(userDetailsFailure(error)),
    resetError: () => dispatch(resetError())
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(SequenceManager)