export const calculationHasErrored = (state = false, action) => {
	switch (action.type) {
		case 'CALCULATION_HAS_ERRORED':
			return action.hasErrored

		default:
			return state
	}
}

export const calculationIsLoading = (state = true, action) => {
	switch (action.type) {
		case 'CALCULATION_IS_LOADING':
			// Prevent FOUC
			if(state === false) {
				setTimeout(() => {
					return action.isLoading
				}, 1000)
			} else {
				return action.isLoading
			}
			return state
			
		default:
			return state
	}
}

export const benchMarksData = (state = [], action) => {
    switch (action.type) {
        case 'CALCULATION_FETCH_DATA_SUCCESS':
            return action.benchMarksData

        default:
            return state
    }
}