import React, { Component } from 'react'
import { connect } from 'react-redux'
import Button from 'UI/Button'
import { getSupplierComments } from 'actions/spendActions'
import moment from 'moment'

class UserCommentsPopUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            characterCount: 150,
            isUserCommentTextboxClicked: 'No',
            warningMessage: '',
            remaingHistoricalCommentsLength: 150,
            historicalSupplierComments: ''
        }
        this.loadData = this.loadData.bind(this)
        this.addUserComments = this.addUserComments.bind(this)
        this.clearUserComments = this.clearUserComments.bind(this)
        this.onChange = this.onChange.bind(this)
        this.onBlur = this.onBlur.bind(this)
        this.onFocus = this.onFocus.bind(this)
    }

    loadData() {
        this.props.getSupplierComments(this.props.DMC_ID, this.props.VS_ID, this.props.SKEY, this.props.user.user.Email)
            .then((response) => {
                if (response.data.StatusCode === 200) {
                    if (response.data.Result.length > 0) {
                        var historicalCommentsLength = response.data.Result[0]['USER_COMMENT'].length
                        var totalHistoricalComments = []
                        totalHistoricalComments = response.data.Result[0]['USER_COMMENT'].split('\r\n')
                        //Prefix Text for each new comment is ----->  mm/dd/yyyy - email: @@@@ your comment @@@@
                        var prefixTextlength = 15 + this.props.user.user.Email.length + totalHistoricalComments.length
                        var historicalCommentsLengthWithPrefix = historicalCommentsLength + prefixTextlength
                        if (2000 - historicalCommentsLengthWithPrefix > 0) {
                            this.setState({
                                historicalSupplierComments: response.data.Result[0]['USER_COMMENT'],
                                remaingHistoricalCommentsLength: 2000 - historicalCommentsLengthWithPrefix
                            })
                        }
                        else {
                            this.setState({
                                historicalSupplierComments: response.data.Result[0]['USER_COMMENT'],
                                remaingHistoricalCommentsLength: 0
                            })
                        }
                    }
                }
            })
    }

    onChange(e) {
        if (this.state.remaingHistoricalCommentsLength < 150) {
            if (this.state.remaingHistoricalCommentsLength > 0)
                document.getElementById("userComments").maxLength = this.state.remaingHistoricalCommentsLength
            else
                document.getElementById("userComments").maxLength = 0

            this.setState({
                warningMessage: this.props.supplierComments.historicalCharactersLeft,
                characterCount: this.state.remaingHistoricalCommentsLength - e.currentTarget.value.length, isUserCommentTextboxClicked: 'Yes'
            })
        }
        else {
            this.setState({ warningMessage: this.props.supplierComments.charactersLeft, characterCount: 150 - e.currentTarget.value.length, isUserCommentTextboxClicked: 'Yes' })
        }
    }

    onBlur(e) {
        this.setState({ isUserCommentTextboxClicked: 'No' })
    }

    onFocus(e) {
        if (this.state.remaingHistoricalCommentsLength < 150) {
            document.getElementById("userComments").maxLength = this.state.remaingHistoricalCommentsLength
            this.setState({
                warningMessage: this.props.supplierComments.historicalCharactersLeft,
                characterCount: this.state.remaingHistoricalCommentsLength - e.currentTarget.value.length, isUserCommentTextboxClicked: 'Yes'
            })
        }
        else {
            this.setState({ warningMessage: this.props.supplierComments.charactersLeft, characterCount: 150 - e.currentTarget.value.length, isUserCommentTextboxClicked: 'Yes' })
        }
    }

    addUserComments() {
        var comments = document.getElementById("userComments").value
        if (comments !== '')
            this.props.addUserComments(comments)
    }

    clearUserComments() {
        this.props.clearUserComments()
    }

    componentDidMount() {
        this.loadData()
    }

    render() {
        return (
            <div style={{ padding: '10px' }} className="grid-x">
                <div className="medium-12">
                    <div style={{ cursor: 'pointer' }} className="float-right close-icon" onClick={this.props.close}>
                        <img width="20" src="images/close_inactive.svg" />
                    </div>
                    <div style={{ paddingTop: '35px' }}>
                        <div className="float-left fieldTitleGeneralContent"> {this.props.supplierComments.userComment}</div>
                        {this.state.isUserCommentTextboxClicked === 'Yes' ? <div style={{ fontSize: '0.875rem' }} className="float-right colorMessage"> {this.state.warningMessage + this.state.characterCount}</div>
                            : null}
                    </div>
                    <div className="control-wrapper fieldValue">
                        <textarea
                            rows="5"
                            cols="30"
                            type="text" style={{ width: '100%' }}
                            placeholder={this.props.supplierComments.waterText}
                            defaultValue={moment(this.props.userCommentInsideCell, 'MM/DD/YYYY', true).isValid() === true ? '' : this.props.userCommentInsideCell}
                            maxLength="150"
                            onChange={e => this.onChange(e)}
                            onFocus={e => this.onFocus(e)}
                            onBlur={(e) => this.onBlur(e)}
                            id="userComments"
                        />
                    </div>
                </div>
                <div style={{ paddingTop: '10px' }} className="medium-12">
                    <div className="fieldTitleGeneralContent"> {this.props.supplierComments.historicalComment}</div>
                    <div className="control-wrapper fieldValue">
                        <textarea
                            rows="10"
                            cols="30"
                            type="text" style={{ width: '100%' }}
                            disabled={true}
                            //defaultValue={this.state.historicalSupplierComments}
                            value={this.state.historicalSupplierComments}
                            id='historicalComments'
                        />
                    </div>
                </div>
                <div style={{ paddingTop: '10px' }} className="popupOkBtn medium-12">
                    <div className="float-right">
                        <div className="float-left">
                            <Button onClick={this.clearUserComments} type="submit" content={this.props.supplierComments.removeComment} />
                        </div>
                        <div className="float-left">
                            <Button onClick={this.addUserComments} type="submit" content={this.props.supplierComments.ok} />
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.default,
        supplierComments: state.content.spendAnalysis.supplierDrilldown.supplierComments
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getSupplierComments: (DMCId, VSId, SKEY, userEmail) => dispatch(getSupplierComments(DMCId, VSId, SKEY, userEmail)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(UserCommentsPopUp)