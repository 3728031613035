import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import {
  getReleaseFeatures,
  getLatestPublishedReleaseFeatures,
  getReleaseVersions,
  notificationManagerLoading,
  notificationManagerSuccess,
  notificationManagerFailure,
  resetError
} from 'actions/userActions'

class ReleaseContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      releaseData: [],
      isPublish: false,
      editId: 0,
      textData: '',
      responseMsg: '',
      currentPublishVersion: '',
      dbreleaseVersion: [],
      optionData: [],
      selectedOption: '',
      tempFloating: '',
      isReleaseVersionSelected: ''
    }
    this.loadData = this.loadData.bind(this)
  }
  
  componentWillMount() {
    this.loadData();
  }

  loadData() {
    this.props.getReleaseFeatures()
      .then((response) => {
        var notificationData = [];
        this.props.notificationManagerSuccess(response.data.Message)
        for (var i = 0; i < response.data.Result.length; i++) {
          var obj = response.data.Result[i];
          if(obj.IsPublishedVersion)
            notificationData.push(obj);
        }
        this.setState({ releaseData: notificationData });
        this.getLatestPublish();
      }).catch((error) => {
        if (error.stack.includes('Network Error')) {
          this.props.notificationManagerFailure()
        }
        else {
          this.props.notificationManagerFailure(error.response.data.Message)
        }
      })
  }

  getLatestPublish(versionName) {
    this.props.getLatestPublishedReleaseFeatures()
      .then((response) => {
        this.props.notificationManagerSuccess(response.data.Message)
        for (var i = 0; i < response.data.Result.length; i++) {
          var obj = response.data.Result[i];
          this.setState({ currentPublishVersion: obj.ReleaseVersionName })

          if (obj.ReleaseVersionName == versionName) {
            this.setState({ isPublish: true })
          } else {
            this.setState({ isPublish: false })
          }
        }
      }).catch((error) => {
        if (error.stack.includes('Network Error')) {
          this.props.notificationManagerFailure(error)
        }
      })
  }


  handleReportDetailTable(evt) {
    var item = {
      name: evt.target.name,
      id: evt.target.id,
      value: evt.target.value
    };

    var old_reportDetails = this.state.old_reportDetails.slice();
    if (old_reportDetails.length === 0) {
      old_reportDetails = this.state.releaseData.slice();
      this.setState({ old_reportDetails: old_reportDetails });
    }
    let testOfObjects = old_reportDetails;
    var newreportDetails = testOfObjects.map(function (testReportDetails) {
      for (var key in testReportDetails) {
        if (testReportDetails.id === item.id) {
          testReportDetails[key] = item.name;
          break;
        }
      }
      return testReportDetails;
    });

    this.setState({ releaseData: newreportDetails });

  };

  render() {
    return (
      <div className="popup-content paddingAll15">
        <div className="medium-12 title">{this.props.content.title}</div>
          <div className="grid-x grid-padding-x">
            <div className="medium-12 cell success description" style={{ margin: 'auto' }} >
              {this.state.currentPublishVersion != '' ? this.props.validation.currentPublish : ''}  {this.state.currentPublishVersion != '' ? this.state.currentPublishVersion : ''}
            </div>
          </div>
        <div className="success">{this.state.responseMsg}</div>
        <div className="errors">{this.props.user.error}</div>
        <div className="grid-x" className="medium-11 medium-offset-0">
          <ReleaseFeatureTable
            editId={this.state.editId}
            releaseData={this.state.releaseData}
          />
        </div>
      </div>
    );
  }
}


class ReleaseFeatureTable extends React.Component {
  render() {
    var editId = this.props.editId;
   
    var data = this.props.releaseData.map(function (data) {
      return (<ReleaseFeatureRow
        data={data}
        editId={editId}
        key={data.id} />)
    });
    return (
      <div className="basic-table">
        <table>
          <thead>
            <tr>
              <th>Version</th>
              <th>Last Updated On</th>
              <th>Short Description</th>
              <th>Feature Description</th>
            </tr>
          </thead>

          <tbody>
            {data}
          </tbody>
        </table>
      </div>
    );
  }
}

class ReleaseFeatureRow extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
        <NonEditableRow
          data={this.props.data}
          cellData={{
            name: this.props.data.ReleaseFeatureName,
            desc: this.props.data.ReleaseFeatureDescription,
            id: this.props.data.ReleaseFeatureID,
            vid: this.props.data.ReleaseVersionID
          }}
        />
    );
  }
}

class NonEditableRow extends React.Component {
  render() {
    return (
      <tr>
        <td>{this.props.data.ReleaseVersionName}</td>
        <td>
          <span>{moment(this.props.data.UpdatedOn).format('L')} </span>
        </td>
        <td>
          <span id={this.props.cellData.id}
            name={this.props.cellData.name}>{this.props.cellData.name}</span>
        </td>
        <td>{this.props.data.FeatureDescription}
          <span id={this.props.cellData.id}
            name={this.props.cellData.desc}>{this.props.cellData.desc}</span>
        </td>
      </tr>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
    validation: state.content.validation,
    releaseData: state.releaseData,
    user: state.default,
    content : state.content.menu.Release[0].releaseDetails
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    notificationManagerLoading: (bool) => dispatch(notificationManagerLoading(bool)),
    notificationManagerSuccess: (bool) => dispatch(notificationManagerSuccess(bool)),
    notificationManagerFailure: (error) => dispatch(notificationManagerFailure(error)),
    getReleaseFeatures: (formValues) => dispatch(getReleaseFeatures(formValues)),
    getLatestPublishedReleaseFeatures: (formValues) => dispatch(getLatestPublishedReleaseFeatures(formValues)),
    getReleaseVersions: (formValues) => dispatch(getReleaseVersions(formValues)),
    resetError: () => dispatch(resetError())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReleaseContainer)