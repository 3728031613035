// @flow

import React, { Component } from 'react'
import {
	MemoryRouter,
	Route,
	Switch,
	Redirect,
	withRouter,
	HashRouter,
	Link,
	Router
} from 'react-router-dom'
import createBrowserHistory from 'history/createBrowserHistory'
import { connect } from 'react-redux'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'

// Actions
import { contentFetchData, contentPEsFetchData } from './actions/fetchContent'
import { valueStoryFetchData } from './actions/fetchValueStory'
import {
	updateLanguage,
	openValueStory,
	openValueStorySuccess,
	openValueStoryFailure,
	autoSave,
	valueStoryIsSaving,
	autoSaveSuccess,
	autoSaveFailure,
	resetError,
	validateSession,
	signInUserSuccess,
	loadUserFromToken,
	updateOnlineStatus,
	resetToken,
	fetchToken,
	fetchTokenLoading,
	fetchTokenSuccess,
	fetchTokenFailure
} from './actions/userActions'
import { valueStoryFetchDataSuccess } from 'actions/fetchValueStory'
import { initPrioritiesItems } from 'actions/prioritiesActions'
import dotenv from 'dotenv'
//import CacheBuster from 'CacheBuster'
 
// UI
import './App.css'
import 'material-components-web/dist/material-components-web.min.css'
import { changeModalBorderStyle, changeModalBorderContentStyle, changeModalBorderRectStyle, modalStyle, modalContentStyle } from 'Constants'
import Modal from 'reboron/OutlineModal'
import Button from 'UI/Button'

// Components
import NavContainer from 'Nav/NavContainer'
import MenuContainer from 'Menu/MenuContainer'
import HomeContainer from 'Home/HomeContainer'
import RegisterContainer from 'Register/RegisterContainer'
import RegisterThankYou from 'Register/RegisterThankYou'
import LoginContainer from 'Login/LoginContainer'
import ActivationContainer from 'Activation/ActivationContainer'
import ForgotPasswordContainer from 'ForgotPassword/ForgotPasswordContainer'
import ResetPasswordContainer from 'ResetPassword/ResetPasswordContainer'
import StartContainer from 'Start/StartContainer'
import DiscoveryContainer from 'Discovery/DiscoveryContainer'
import AreaOfInterestContainer from './components/AreaOfInterest/AreaOfInterestContainer'
import PrioritiesContainer from 'Priorities/PrioritiesContainer'
import BusinessCaseContainer from 'BusinessCase/BusinessCaseContainer'
import SubLeverCalculationContainer from 'SubLeverCalculation/SubLeverCalculationContainer'
import CalculateROIContainer from 'CalculateROI/CalculateROIContainer'
import NextStepsContainer from 'NextSteps/NextStepsContainer'
 
import BusinessValueFromSAPAribaContainer from 'BusinessValueFromSAPAriba/BusinessValueFromSAPAribaContainer'
import Loading from 'UI/Loading'
import AutoSave from 'UI/AutoSave'
import OnlineStatus from 'UI/OnlineStatus'
import Orientation from 'UI/Orientation'
import TranslateScreen from 'components/Language/TranslateScreen'
import LanguageContainer from 'components/Language/LanguageContainer'
import BenchmarkContainer from 'components/Benchmark/BenchmarkContainer'
import Carousel from 'components/Start/StartContainer'
import SkuContainer from 'components/SKU/SkuContainer'
import UserAndRoleManagerContainer from 'components/Admin/UserAndRoleManagerContainer'
import SearchContainer from 'components/Search/SearchContainer'
import SubTabsContainer from 'components/Spend/SubTabsContainer'
import YourFileContainer from 'components/Spend/YourFileContainer'
import IndustryBreakdownContainer from 'components/Spend/IndustryBreakdown/IndustryBreakdownContainer'
import SearchBar from 'components/Search/SearchBar'
import SpendFilesContainer from 'components/Spend/SpendDetails/SpendFilesContainer'
import YourSpendContainer from 'components/Spend/YourSpendFile/YourSpendContainer'
import DiscoveryAndYourSpendContainer from 'components/Spend/YourSpendFile/DiscoveryAndYourSpendContainer'
import ValueMenuContainer from './components/ValueStoryMenu/ValueMenuContainer';
import GICSAdminController from './components/GICSAdmin/GICSAdminController';
import ReleaseContainer from './components/ReleaseNotification/ReleaseContainer';
import SpendParameter from 'components/Admin/SpendParametersContainer'
import VideoManagerContainer from 'components/Admin/VideoManagerContainer'
import CountryLookup from 'components/Admin/CountryLookup'
import EnablementParameters from 'components/Admin/EnablementParameters'
import Terms from 'components/Login/Terms'
import ANIDManager from 'components/Admin/ANIDManager'


// Utils
import 'babel-polyfill'
import {
	setCookie,
	getCookie,
	clearLocalStorage,
	setCurrentLanguage,
	getCurrentLanguage,
	getParameterByName
} from 'utils/Utils'
import classnames from 'classnames'

// Needed for 
//import injectTapEventPlugin from 'react-tap-event-plugin'

// Settings
import {
	routes,
	publicRoutes,
	path, 
	env,
	events,
	warnTimeout,
	logoutTimeout
} from './Constants'
import WorldMapContainer from 'components/Spend/WorldMap/WorldMapContainer'

const noHelpRoutes = [
	routes.areaOfInterest,
	routes.priorities,
	routes.businessValueFromSAPAriba,
	routes.calculateROI,
	routes.nextSteps,
	routes.start,
	routes.skus,
	routes.translateScreen,
	routes.languageManager,
	routes.userAndRoleManager,
	routes.benchmark,
	routes.carousel,
	routes.yourFile,
	routes.defineScope,
	routes.detailedFile,
	routes.searchBar,
	routes.searchContainer,
	routes.yourSpend,
	routes.gicsAdmin,
	routes.releaseContainer,
	routes.spendParameter,
	routes.videoManager,
	routes.enablementParameters,
	routes.systemReporting,
	routes.anIdManager
]

const noAutoSave = [
	routes.translateScreen,
	routes.languageManager,
	routes.userAndRoleManager,
	//routes.yourSpend,
	routes.searchBar,
	routes.searchContainer,
	routes.gicsAdmin,
	routes.releaseContainer,
	routes.spendParameter,
	routes.videoManager,
	routes.enablementParameters,
	routes.systemReporting,
	routes.anIdManager
]

let customHistory = createBrowserHistory()
const publicRoutes_new = publicRoutes;
const MenuWithRouter = withRouter(MenuContainer)
const ValueMenuWithRouter = withRouter(ValueMenuContainer)
const NavWithRouter = withRouter(NavContainer)

let autoSaveTimer,
	autoSaveInterval = 30000,
	messageDuration = 2000,
	online = null

var bredCrumbVisible = false;

export class App extends Component {

	constructor(props) {
		super(props)
		//this.location = ''
		this.showMenu = this.showMenu.bind(this)
		this.hideMenu = this.hideMenu.bind(this)
		this.showLanguageSelector = this.showLanguageSelector.bind(this)
		this.hideLanguageSelector = this.hideLanguageSelector.bind(this)
		this.handleLanguageSelection = this.handleLanguageSelection.bind(this)
		this.autoSave = this.autoSave.bind(this)
		this.startInterval = this.startInterval.bind(this)
		this.stopInterval = this.stopInterval.bind(this)
		this.createSessionInfo = this.createSessionInfo.bind(this)
		this.validateSession = this.validateSession.bind(this)
		this.setSessionState = this.setSessionState.bind(this)
		this.dispatchOpenValueStory = this.dispatchOpenValueStory.bind(this)
		this.setCurrentPath = this.setCurrentPath.bind(this)
		this.getCurrentPath = this.getCurrentPath.bind(this)
		this.updateIndicator = this.updateIndicator.bind(this)
		this.loadContent = this.loadContent.bind(this)
		this.publicRoutes = this.publicRoutes;
		this.loadPEsContent = this.loadPEsContent.bind(this)
		this.showModal = this.showModal.bind(this)
		this.expiredAlertOnOk = this.expiredAlertOnOk.bind(this)
		this.renewSession = this.renewSession.bind(this)
		this.logoutUser = this.logoutUser.bind(this)
		this.warn = this.warn.bind(this);
		this.resetTimeout = this.resetTimeout.bind(this);
		this.tokenScheduler = this.tokenScheduler.bind(this)
		this.userClock = this.userClock.bind(this)
		this.renewalFunc = this.renewalFunc.bind(this)
		this.setActivityMonitor = this.setActivityMonitor.bind(this)
		this.clearActivityMonitor = this.clearActivityMonitor.bind(this)
		this.actions = this.getApplicationFeatureActions.bind(this)

		this.state = {
			menuVisible: false,
			languageSelectorVisible: false,
			currentLang: 'ENGLISH',
			session: false,
			sessionChecked: false,
			code: null,
			href: window.location.href,
			timeoutCounter: 300,
			renewalCounter: 0,
		}

		this.tokenScheduler();
	}

	tokenScheduler() {
		setInterval(()=> 
			{
				const pathname = this.getCurrentPath();
				var init = pathname !== routes.home && pathname !== routes.login
				if(init && !this.initialIterationStart) {
					events.forEach(event => window.addEventListener(event, this.resetTimeout))
					this.setAppTimeout();
					this.setActivityMonitor()
					this.initialIterationStart = true;
					this.initialIterationEnd = false;
				} else if((!this.props.user.user && !this.initialIterationEnd)) {
					this.clearAppTimeout();
					this.clearActivityMonitor()
					events.forEach(event => window.removeEventListener(event, this.resetTimeout))
					this.initialIterationStart = false;
					this.initialIterationEnd = true
				}
			}
		, 1000)
	}

	setActivityMonitor() {
		this.renewalCnt = setInterval(this.renewalFunc, 1000)
	}

	clearActivityMonitor() {
		this.setState({renewalCounter: 0})
		if(this.renewalCnt) clearInterval(this.renewalCnt)
	}

	renewalFunc() {
		if(this.state.renewalCounter * 1000 === (warnTimeout + 5 * 60 * 1000)) {
			this.renewToken('renew');
			this.setState({renewalCounter: 0})
		} else if(this.state.renewalCounter <= logoutTimeout){
			this.setState((previousState) => { return {renewalCounter: previousState.renewalCounter + 1}})
		}
	}

	setAppTimeout() {
		this.warnTimeout = setTimeout(this.warn, warnTimeout);
		this.logoutTimeout = setTimeout(this.logout, logoutTimeout);
	}

	clearAppTimeout() {
		if (this.warnTimeout) clearTimeout(this.warnTimeout);
		if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
	}

	resetTimeout() {
		this.clearAppTimeout();
		this.setAppTimeout();
	}
	
	warn() {
		this.clearActivityMonitor();
		this.showModal("expiredAlert");
		this.setClock();
	}

	setClock() {
		this.intervalClock = setInterval(this.userClock
		, 1000)
	}

	userClock() {
		this.setState((previousState) =>  { 
			return {timeoutCounter: previousState.timeoutCounter === 0 ? previousState.timeoutCounter: previousState.timeoutCounter - 1}}, 
			()=> {
			if(this.state.timeoutCounter === 0) {
				this.clearClock();
				this.expiredAlertOnOk('expiredAlert');
			}
		})
	}

	clearClock() {
		if (this.intervalClock) {
			clearInterval(this.intervalClock);
			this.setState({timeoutCounter: 300})
		}
	}

	showModal(action) {
		if(this.refs[action])
        	this.refs[action].show()
	}
	
    expiredAlertOnOk(action) {
		this.refs[action].hide()
		this.clearClock()
		this.logoutUser();
	}

	renewSession(action) {
		this.refs[action].hide()
		this.clearClock()
		this.setActivityMonitor();
		this.renewToken('renew');
	}

	renewToken(e) {
		if(!(e === 'renew' || e === 'backdrop')) {
			return;
		}
		//var content = this.props.content;
		const email = this.props.user.user.Email
		const token = localStorage.getItem('jwtToken')

		if (email && token) {
			let formValues = {
				Token: token,
				Email: email
			}

		this.props.fetchToken(formValues)
			.then((result) => {
				this.props.fetchTokenSuccess(result);
				// Store JWT Token
				localStorage.setItem('jwtToken', result.data.Result)
			}).catch((error) => {
				if (error.message === "Network Error") {
					this.props.fetchTokenFailure("API service is down. Please contact administrator.")
				}
				else {
					this.props.fetchTokenFailure(error.response.data.Message);
				}

				//console.log(error.message)
				alert('Failed to renew User Session.');
				this.logoutUser();
			})
		}
	}
	
	logoutUser() {
		this.clearAppTimeout();
		events.forEach(event => window.removeEventListener(event, this.resetTimeout))
		this.props.resetToken()
		// Redirect to home page
		window.location.href = path
	}

	showMenu() {
		this.setState({ menuVisible: true })
	}

	hideMenu() {
		this.state.menuVisible && this.setState({ menuVisible: false })
	}

	showLanguageSelector() {
		this.setState({ languageSelectorVisible: true })

	}

	hideLanguageSelector() {
		this.state.languageSelectorVisible && this.setState({ languageSelectorVisible: false })
	}

	handleLanguageSelection(text, lang) {
		setCurrentLanguage(lang)
		this.props.fetchData('data/' + lang + '.json', 'data/jsonDBData/BenchMarkDetails/' + 'benchmarkdetails_' + lang.toLowerCase() + '.json')
			.then(() => {
				this.setState({
					currentLang: text
				})

				setCurrentLanguage(lang)

				this.props.updateLanguage(lang)
				//window.location.reload();
			})
	}

	autoSave() {
		let token = localStorage.getItem('jwtToken')
		var path = this.getCurrentPath();

		if (noAutoSave.indexOf(path) === -1 && this.props.userData.BusinessValueToYou !== null && this.props.userData.BusinessValueToYou !== undefined) {
			if (this.props.user.user && token) {
				if(sessionStorage.getItem('isCustomer') === 'true') {
					return
				}
				
				this.validateSession()
					.then((response) => {
						if (response) {
							if (this.props.user.userprofile.Roles[0].indexOf("BusinessCaseRole") > -1) {
								this.props.valueStoryIsSaving(true)
							}
							else {
								//NOTE: Uncomment below line to show autosave banner on top of the screen for all roles
								//this.props.valueStoryIsSaving(true)
							}
							this.props.autoSave(this.props.userData, this.props.user.userprofile.Roles[0].indexOf("BusinessCaseRole") > -1)
								.then(() => {
									setTimeout(() => {
										this.props.autoSaveSuccess()
									}, messageDuration)
								})
								.catch(() => {
									this.props.autoSaveFailure(this.props.ui.expired)
									this.stopInterval()
									clearLocalStorage()
									//this.showModal("expiredAlert")
								})
						}
					})
					.catch(() => {
						//console.log('auto save session expired')
						this.props.autoSaveFailure(this.props.ui.expired)
						this.stopInterval()
						clearLocalStorage()
						//this.showModal("expiredAlert")
					})

			} else {
				//console.log('auto save session expired')
				this.props.autoSaveFailure(this.props.ui.expired)
				this.stopInterval()
				clearLocalStorage()
				//this.showModal("expiredAlert")
			}
		}

	}

	startInterval(user) {
		autoSaveTimer = setInterval(this.autoSave, autoSaveInterval)
		this.createSessionInfo(user)
	}

	stopInterval() {
		clearInterval(autoSaveTimer)
	}

	createSessionInfo(nextProps) {
		const sessionValues = {
			Email: nextProps.user.user.Email,
			VSId: nextProps.userData.VSId
		}

		localStorage.setItem('sessionInfo', JSON.stringify(sessionValues))
	}

	validateSession(onLoad = false) {
		// Check for valid session and login user
		const sessionInfo = JSON.parse(localStorage.getItem('sessionInfo'))
		const token = localStorage.getItem('jwtToken')

		if (sessionInfo && token) {
			let formValues = {
				Token: token,
				Email: sessionInfo.Email
			}

			return this.props.validateSession(formValues)
				.then((response) => {

					// Session valid
					if (response.data.Result) {

						if (onLoad) {
							this.props.signInUserSuccess(response)
							if (sessionInfo.VSId) {
								this.dispatchOpenValueStory(sessionInfo.VSId)
							} else {
								this.props.history.push(routes.home)
							}
						}
						return true
					}

				}).catch((error) => {

					// Session expired
					if (this.props.ui) {
						let message = ''
						if(!error.response) {
							message = this.props.ui.expired;
						} else {
							switch (error.response.data.Result) {
								case 1:
									message = this.props.ui.invalidEmail
									break;
								case 2:
									message = this.props.ui.invalidToken
									break;
								case 3:
									message = this.props.ui.duplicateSession
									break;
								case 4:
									message = this.props.ui.sessionVerificationError
									break;

								default:
									message = this.props.ui.expired
									break;
							}
						}
						this.props.autoSaveFailure(message)
						this.stopInterval()
					}
					
					this.setState({ sessionChecked: true })
					clearLocalStorage()
					return false
				})
		} else {
			this.setState({ sessionChecked: true })
			clearLocalStorage()
			return false
		}
	}

	setSessionState(state) {
		this.setState({ session: state, sessionChecked: true })
	}

	dispatchOpenValueStory(VSId) {
		this.props.openValueStory(VSId, this.props.user.user.Id)
			.then((response) => {
				// Push value story data to store
				this.props.valueStoryFetchDataSuccess(response.data.Result)

				// Add indexes of selected priorities to priorities array in store
				let items = response.data.Result.AreaOfInterest.map((item, i) => item.Selected && i)
				items = items.filter((item) => item !== false)
				this.props.initPrioritiesItems(items)

				// Render
				this.setSessionState(true)
			}).catch((error) => {
				if (error.response !== undefined) {
					this.props.openValueStoryFailure(error.response.data.Message)
				}

			})
	}

	sessionExpiredAlert() {
		this.props.resetToken()
						// Redirect to home page
		window.location.href = path
	}

	setCurrentPath(location) {
		let settings = {}

		if (getCookie('settings') !== '' && getCookie('settings') !== 'undefined') {
			settings = JSON.parse(getCookie('settings'))
			settings.location = location
			if (location === '/start') {
				bredCrumbVisible = true;
			}
		} else {
			settings.location = location
		}


		// 0.0138 is 20 minutes out of a day (1440 minutes)
		setCookie('settings', JSON.stringify(settings), 1)
	}

	getCurrentPath() {
		let settings = {}
		const pathname = window.location.pathname.split('/')[1]
		const pathname_new = '/' + pathname;
		let _publicPath = publicRoutes_new.indexOf('/' + pathname) > -1
		if (_publicPath) {
			customHistory.push(pathname_new);
			this.setCurrentPath(pathname_new);
		}
		if (getCookie('settings') !== '' && getCookie('settings') !== 'undefined') {
			settings = JSON.parse(getCookie('settings'))
			return settings.location
		} else {
			return routes.home
		}
	}

	updateIndicator() {
		if (!navigator.onLine) {
			this.props.updateOnlineStatus(navigator.onLine)
			this.stopInterval()
			online = false
		} else if (online === false) {
			// Indicate online
			online = true
			this.props.updateOnlineStatus(navigator.onLine)

			// Hide online indicator
			setTimeout(() => {
				this.props.updateOnlineStatus(null)
				online = null
			}, messageDuration)

			// if  logged in
			if (this.props.userData) this.startInterval(this.props)
		}
	}

	onlineStatus() {
		window.addEventListener('online', this.updateIndicator)
		window.addEventListener('offline', this.updateIndicator)
		this.updateIndicator()
	}

	loadContent() {
		let language = getCurrentLanguage()

		if (language === undefined || language === 'undefined' || language === '') {
			language = 'en'
		}
		this.props.fetchData(path + 'data/' + language + '.json', path + 'data/jsonDBData/BenchMarkDetails/' + 'benchmarkdetails_' + language.toLowerCase() + '.json')
	}

	loadPEsContent() {
		this.props.fetchPEsData(path + 'data/jsonDBData/PEs/PEs.json');
	}


	componentWillReceiveProps(nextProps) {
		if (this.props.content !== nextProps.content) {

			// Get language from cookie
			const languageCode = getCurrentLanguage()
			const language = nextProps.content.nav.languageSelector.find((obj) => {
				return languageCode === obj.lang
			})

			// Update local state
			this.setState({
				currentLang: language.text
			})

			// Update store
			this.props.updateLanguage(language.lang)
		}

		// Restart auto save interval
		// if  new value story is loaded
		if (nextProps.userData) {
			if (this.props.userData === null || (this.props.userData && this.props.userData.VSId !== nextProps.userData.VSId)) {
				this.stopInterval()
				this.startInterval(nextProps)
			}
		}
	}


	componentDidMount() {
		//injectTapEventPlugin()

		// Validate existing session (if  any)
		this.validateSession(true);

		this.onlineStatus();

		this.loadContent();

		//this.loadPEsContent();

		let code = getParameterByName('code', this.state.href);
		this.setState({ code: code })
	}

	getApplicationFeatureActions() {
		let actions = [];
		let applicationFeatures = this.props.user && this.props.user.user && this.props.user.user.applicationFeatures ? this.props.user.user.applicationFeatures : [];
		applicationFeatures.map(applicationFeat => {
			actions.push(applicationFeat.Action);
		})
		return actions;
	}

	render() {
		const pathname = window.location.pathname.split('/')[1]
		const pathname_new = '/' + pathname;
		let _publicPath = publicRoutes_new.indexOf('/' + pathname) > -1
		if (_publicPath) {
			customHistory.push(pathname_new);
			this.setCurrentPath(pathname_new);
		}
		if (this.props.hasErrored) {
			return <p>Sorry! There was an error loading the content.</p>
		}
		var publictest = (path) => {
			this.setCurrentPath(`/${path}`)
			return <Redirect to={this.getCurrentPath()} />
		}

		if ((this.props.isLoading ||
			!this.state.sessionChecked) //||
			//(env === 'development' && this.props.valueStoryIsLoading)
		) {
			return <div className="loading loading--is-loading"><div className="loader" />Loading...</div>
		} else {
			return (
				<div id="appDiv" >
				<MemoryRouter history={customHistory}>
					<Route render={({ location }) => (
						<div
							className={this.props.language}
							onClick={() => {
								this.hideMenu(this.state.menuVisible)
								this.hideLanguageSelector(this.state.languageSelectorVisible)
							}
							}>
							<Loading valueStoryIsLoading={this.props.valueStoryIsLoading} valueStoryListIsLoading={this.props.user.valueStoryListIsLoading} ui={this.props.ui} location={location} />
							<Orientation ui={this.props.ui} />

							<div >
								<MenuWithRouter history={customHistory} menuVisible={this.state.menuVisible} nav={this.props.nav} />
								{
									_publicPath ?
										(
											<Redirect history={customHistory} to={{ pathname: pathname_new }}   ></Redirect>
										) : (
											this.state.session ?
												(
													// Continue existing session
													<Route exact path="/" render={() => (
														<Redirect to={this.getCurrentPath()} />
													)} />
												) : (
													// Default to home
													<Route exact path="/" render={() => (
														<Redirect to={routes.home} />
													)} />
												)
										)
								}
								
								{ this.actions().indexOf(routes.businessValue)> -1 ?(									
								<ValueMenuWithRouter history={customHistory} menuVisible={this.state.menuVisible} nav={this.props.nav} />
								): 								
								null
								}

								{									
									_publicPath ?
										(
											<Redirect history={customHistory} to={{ pathname: pathname_new }}   ></Redirect>
										) : (
											this.state.session ?
												(
													// Continue existing session
													<Route exact path="/" render={() => (
														<Redirect to={this.getCurrentPath()} />
													)} />
												) : (
													// Default to home
													<Route exact path="/" render={() => (
														<Redirect to={routes.home} />
													)} />
												)
										)									
								}

								{location.pathname !== '/' ? this.setCurrentPath(location.pathname) : null}
                                    
								<AutoSave user={this.props.user} ui={this.props.ui} />
								<OnlineStatus user={this.props.user} ui={this.props.ui} />

								<main id="main">
									<NavWithRouter
										showMenu={this.showMenu}
										showLanguageSelector={this.showLanguageSelector}
										languageSelectorVisible={this.state.languageSelectorVisible}
										handleLanguageSelection={this.handleLanguageSelection}
										currentLang={this.state.currentLang}
										currentRoute={location}
										publicRoutes={publicRoutes}
										noHelpRoutes={noHelpRoutes}
										history={customHistory}
									/>
									<ReactCSSTransitionGroup
										transitionName="fade"
										transitionEnterTimeout={800}
										transitionLeaveTimeout={800}
									>
										<Switch key={location.key} location={location} className="switch">
											<Route
												path={routes.home}
												component={HomeContainer}
											/>
											<Route
												path={routes.login}
												component={LoginContainer}
											/>
											<Route
												path={routes.register}
												component={RegisterContainer}
											/>
											<Route
												path={routes.registerThankYou}
												component={RegisterThankYou}
											/>
											<Route
												path={routes.activation}
												component={ActivationContainer}
											/>
											<Route
												path={routes.forgotPassword}
												component={ForgotPasswordContainer}
											/>
											<Route
												path={routes.resetPassword}
												//component={ResetPasswordContainer}
												render={(props) => <ResetPasswordContainer {...props} code={this.state.code} />}
											/>
											<Route
												path={routes.start}
												component={StartContainer}
											/>
											<Route
												path={routes.discovery}
												component={DiscoveryContainer}
											/>
											<Route
												path={routes.areaOfInterest}
												component={AreaOfInterestContainer}
											/>
											<Route
												path={routes.priorities}
												component={PrioritiesContainer}
											/>
											<Route
												path={routes.businessValue}
												component={BusinessCaseContainer}
											/>
											<Route
												path={routes.subLeverCalculation}
												component={SubLeverCalculationContainer}
											/>
											<Route
												path={routes.businessValueFromSAPAriba}
												component={BusinessValueFromSAPAribaContainer}
											/>
											<Route
												path={routes.calculateROI}
												component={CalculateROIContainer}
											/>
											<Route
												path={routes.nextSteps}
												component={NextStepsContainer}
											/>
											<Route
												path={routes.translateScreen}
												component={TranslateScreen}
												nav={this.props.nav}
												validation={this.props.validation}
												content={this.props.content}
											/>
											<Route
												path={routes.languageManager}
												component={LanguageContainer} />
											<Route
												path={routes.benchmark}
												component={BenchmarkContainer} />
											<Route
												path={routes.carousel}
												component={Carousel} />

											<Route
												path={routes.skus}
												component={SkuContainer}
												content={this.props.content.SKUs}
											/>
											<Route
												path={routes.userAndRoleManager}
												component={UserAndRoleManagerContainer}
											/>
											<Route
												path={routes.searchContainer}
												component={SearchContainer}
											/>

											<Route
												path={routes.subTabsContainer}
												component={SubTabsContainer}
											/>
											<Route
												path={routes.yourFile}
												component={YourFileContainer}
											/>

											<Route
												path={routes.defineScope}
												component={IndustryBreakdownContainer}
											/>										
											<Route
												path={routes.worldMap}
												component={WorldMapContainer}
											/>
											<Route
												path={routes.searchBar}
												component={SearchBar}
											/>
											<Route
												path={routes.detailedFile}
												component={SpendFilesContainer}
											/>
											<Route
												path={routes.yourSpend}
												component={ this.actions().indexOf(routes.businessValue)> -1 ? YourSpendContainer : DiscoveryAndYourSpendContainer}
											/>
											<Route
												path={routes.gicsAdmin}
												component={GICSAdminController}
											/>
											<Route
												path={routes.releaseContainer}
												component={ReleaseContainer}
											/>
											<Route
												path={routes.spendParameter}
												component={SpendParameter}
											/>
											<Route
												path={routes.videoManager}
												component={VideoManagerContainer}
											/>
											<Route
												path={routes.countryLookup}
												component={CountryLookup}
											/>
											<Route
												path={routes.terms}
												component={Terms}
											/>	
											<Route
												path={routes.enablementParameters}
												component={EnablementParameters}
											/>
											<Route
												path={routes.anIdManager}
												component={ANIDManager}
											/>
										</Switch>
									</ReactCSSTransitionGroup>
								
								</main>
								<div className={
									classnames(
										'footer-logo',
										{ 'footer-logo--hidden': publicRoutes.indexOf(location.pathname) === -1 || location.pathname === routes.terms },
										{ 'footer-logo--inline': location.pathname === routes.register }
									)
								}
								>
									<img src={path + `images/logo-sap.png`} alt="SAP" />									
								</div>

								<div className={
									classnames(
										'footer',
										{'footer--hidden': location.pathname === routes.terms}
									)
								}>					
								<a href="https://www.ariba.com/legal/ariba-privacy-statement-05-18-2018" target="_blank" >Privacy</a> &nbsp;<Link to={routes.terms} target="_blank">Terms of Use</Link> &nbsp; <a href="https://www.sap.com/about/legal/copyright.html" target="_blank">Copyright</a> 
							    </div>

							</div>
						
						</div>						
					)} />
				</MemoryRouter>
				<Modal
				ref="expiredAlert"
				modalStyle={changeModalBorderStyle}
				contentStyle={changeModalBorderContentStyle}
				rectStyle={changeModalBorderRectStyle}
				keyboard={false}
				backdrop={false}
				>
				<div className="grid-x grid-padding-x change-popup">
					<div className="medium-12">
						<button onClick={() => this.renewSession("expiredAlert")} className="close" />
					</div>
					<div className="medium-12 pt30 pb10">
						Logout in {(Math.floor(this.state.timeoutCounter/60)).toString().padStart(2, '0')}:{(this.state.timeoutCounter - Math.floor(this.state.timeoutCounter/60) * 60).toString().padStart(2, '0')}
					</div>
					<div className="medium-12 popupOkBtn">
					<div className="float-left">
							<div className="float-left"><Button
								content='Logout'
								onClick={() => this.expiredAlertOnOk("expiredAlert")}
								color />
							</div>
						</div>
						<div className="float-right">
							<div className="float-left"><Button
								content='Renew'
								onClick={() => this.renewSession("expiredAlert")}
								color />
							</div>
						</div>
					</div>
				</div>

			</Modal>
			</div>
			)
		}
	}
}

const mapStateToProps = (state) => {
	return {
		language: state.language,
		ui: state.content.ui,
		user: state.default,
		userData: state.valueStory,
		content: state.content,
		hasErrored: state.contentHasErrored,
		isLoading: state.contentIsLoading,
		valueStoryHasErrored: state.valueStoryHasErrored,
		valueStoryIsLoading: state.valueStoryIsLoading,
		autoSaveSuccess: state.autoSaveSuccess,
		nav: state.content,
		contentPE: state.contentPE
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		updateLanguage: (lang) => dispatch(updateLanguage(lang)),
		openValueStory: (VSId, Id) => dispatch(openValueStory(VSId, Id)),
		openValueStorySuccess: () => dispatch(openValueStorySuccess()),
		openValueStoryFailure: (error) => dispatch(openValueStoryFailure(error)),
		fetchData: (url, curl) => dispatch(contentFetchData(url, curl)),
		fetchValueStoryData: (url) => dispatch(valueStoryFetchData(url)),
		valueStoryFetchDataSuccess: (valueStory) => dispatch(valueStoryFetchDataSuccess(valueStory)),
		autoSave: (ValueStory, isBusinessCaseAccess) => dispatch(autoSave(ValueStory, isBusinessCaseAccess)),
		valueStoryIsSaving: (bool) => dispatch(valueStoryIsSaving(bool)),
		autoSaveSuccess: (message) => dispatch(autoSaveSuccess(message)),
		autoSaveFailure: (error) => dispatch(autoSaveFailure(error)),
		resetError: () => dispatch(resetError()),
		validateSession: (formValues) => dispatch(validateSession(formValues)),
		loadUserFromToken: (user) => dispatch(loadUserFromToken(user)),
		signInUserSuccess: (user) => dispatch(signInUserSuccess(user)),
		initPrioritiesItems: (items) => dispatch(initPrioritiesItems(items)),
		updateOnlineStatus: (status) => dispatch(updateOnlineStatus(status)),
    	resetToken: () => dispatch(resetToken()),
    	fetchToken: (values) => dispatch(fetchToken(values)),
    	fetchTokenLoading: (bool) => dispatch(fetchTokenLoading(bool)),
    	fetchTokenSuccess: (token) => dispatch(fetchTokenSuccess(token)),
    	fetchTokenFailure: (error) => dispatch(fetchTokenFailure(error)),
	//fetchPEsData: (url) => dispatch(contentPEsFetchData(url))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
