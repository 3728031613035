import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Form, actions } from 'react-redux-form'
import { getSourceValue } from '../SubLeverCalculation/SubLeverFormula'
import ScrollArea from 'react-scrollbar'
// Actions

import {
	updateOrganization,
	changeIndustry,
	changeLocation,
	updateTotalSpend,
	goToChoosePath
} from 'actions/discoveryActions'

import {
	toggleValueDriver,
	toggleSubLever
} from 'actions/valueDriverActions'

import {
	addValueStory,
	addValueStorySuccess,
	addValueStoryFailure,
	openValueStory,
	openValueStorySuccess,
	openValueStoryFailure,
	valueStoryCurrentMenu,
	showValueStoryMenu,
	syncMenuAndButton
}
	from 'actions/userActions'

import {
	isNewValueStory
}
	from 'actions/searchActions'

import {
	initPrioritiesItems
}
	from 'actions/prioritiesActions'

import { valueStoryFetchDataSuccess } from 'actions/fetchValueStory'

// Common Functions
import { subLeverChange } from 'components/BusinessValue/SubLeverChange'

// Components
import DiscoveryForm from './DiscoveryForm'
import DiscoveryCompanyForm from './DiscoveryCompanyForm'
import DiscoveryButton from './DiscoveryButton'
import DiscoveryNextButton from './DiscoveryNextButton'

// UI
import Button from 'UI/Button'

// Utils
import classnames from 'classnames'
// Utils
import { removeMask, getSubLeverIdObject, subLeverHasTotalSpend, round } from 'utils/Utils'
import { updateUserInput } from 'actions/subLeverCalculationActions'

// Settings
import { routes, LAC } from 'Constants'



export class DiscoveryContainer extends PureComponent {

	constructor(props) {
		super(props)

		this.state = {
			interests: false,
			count: 0,
			regions: [],
			default: '',
			AnnualRevenue: null,
			TotalSpend: 0,
			Currency: '$',
			CurrencyCode: 'USD',
			Organization: '',
			Industry: '',
			Location: '',
			CapIQId: '',
			CapIQCompany: '',
			PEId: '',
			PECompany: '',
			GlobalUltimateId: '',
			GlobalUltimateCompany: '',
			BPId: '',
			BPCompany: '',
			userData: undefined,
			btnDisabled: !this.props.form.valid || Number.parseFloat(this.props.userData.AnnualRevenue) === 0
		}
		this.dispatchCapIQChange = this.dispatchCapIQChange.bind(this)
		this.dispatchUpdateOrganization = this.dispatchUpdateOrganization.bind(this)
		this.dispatchLocationChange = this.dispatchLocationChange.bind(this)
		this.dispatchIndustryChange = this.dispatchIndustryChange.bind(this)
		this.dispatchTotalSpendChange = this.dispatchTotalSpendChange.bind(this)
		this.slideToInterests = this.slideToInterests.bind(this)
		this.toggleInterestsVisibility = this.toggleInterestsVisibility.bind(this)
		this.initialFieldValues = this.initialFieldValues.bind(this)
		this.addValueStory = this.addValueStory.bind(this)
		this.openValueStory = this.openValueStory.bind(this)
		this.dispatchOpenValueStory = this.dispatchOpenValueStory.bind(this)
		this.redirect = this.redirect.bind(this)
		this.goToRoute = this.goToRoute.bind(this);

		this.actions = this.getApplicationFeatureActions();
	}

	

	getApplicationFeatureActions() {
		let actions = [];
		let applicationFeatures = this.props.user && this.props.user.user && this.props.user.user.applicationFeatures ? this.props.user.user.applicationFeatures : [];
		applicationFeatures.map(applicationFeat => {
			actions.push(applicationFeat.Action);
		})
		return actions;
	}

	slideToInterests() {
		if (this.props.form.valid) {
			this.props.goToChoosePath(true)
		}
	}

	toggleInterestsVisibility() {
		const that = this
		that.setState({
			interests: !that.state.interests
		})
	}

	dispatchUpdateOrganization(organization) {
		this.props.updateOrganization(organization)
	}

	dispatchCapIQChange(id) {
		this.setState({ capIQId: id })
	}

	dispatchLocationChange(location, region) {
		//New Logic to play with json values
		var industry = this.props.content.form.industries[this.props.userData.Industry]
		var country = this.props.content.form.locations[location]
		var spend = this.props.subLeverCalculationContent.subLeverCalculation[0].rows[0][0].content
		var spendSourceValue = getSourceValue(3, country, region, industry, spend)
		this.props.changeLocation(location, spendSourceValue)
		// Turn off Reduce Cost of Processing Payments sub lever
		// if selected country is Latin American Country (LAC)
		if (LAC.indexOf(this.props.content.form.locations[this.props.userData.Location]) > -1) {
			const subLeverIdObject = {
				BVUIdIndex: 2,
				BVUSVDIdIndex: 2
			}
			// Dispatch to Redux
			this.props.toggleSubLever(subLeverIdObject, false)
		}
	}

	dispatchIndustryChange(index) {
		var industry = this.props.content.form.industries[index]
		var country = this.props.content.form.locations[this.props.userData.Location]
		var result = this.props.content.form.regions.find(region => region.Country.toLowerCase() == country.toLowerCase())
		var region = result.Region;
		var spend = this.props.subLeverCalculationContent.subLeverCalculation[0].rows[0][0].content
		var spendSourceValue = getSourceValue(3, country, region, industry, spend)
		this.props.changeIndustry(index, spendSourceValue)
	}

	dispatchTotalSpendChange(value) {
		this.formDispatch(actions.change('discovery.TotalSpend', value))
	}

	componentWillReceiveProps(nextProps) {
		// Update controlled inputs on language, industry or total spend change
		if (this.props.content !== nextProps.content) {
			this.formDispatch(actions.change('discovery.Industry', nextProps.content.form.industries[this.props.userData.Industry]))

			this.formDispatch(actions.change('discovery.Location', nextProps.content.form.locations[this.props.userData.Location]))
			this.formDispatch(actions.change('discovery.Organization', this.props.userData.Organization));
		}
		if (this.props.form.valid !== nextProps.form.valid || this.props.userData.AnnualRevenue !== nextProps.userData.AnnualRevenue) {
			this.setState({ btnDisabled: !nextProps.form.valid || Number.parseFloat(nextProps.userData.AnnualRevenue) === 0 || nextProps.userData.Industry === "" })
		}
	}

	componentWillMount() {
		this.props.valueStoryCurrentMenu('Company');
		this.props.showValueStoryMenu(true);
		this.initialFieldValues();

	}

	componentDidMount() {

		// Update Industry and spendAsPercentage
		//this.props.changeIndustry(this.props.userData.Industry, industryBusinessMatrix[this.props.userData.Industry].spendPercentage)
		var industry = this.props.content.form.industries[this.props.userData.Industry]
		var country = this.props.content.form.locations[this.props.userData.Location]
		if (industry && country) {
			var result = this.props.content.form.regions.find(region => region.Country.toLowerCase() == country.toLowerCase())
			var region = result.Region;
			var spend = this.props.subLeverCalculationContent.subLeverCalculation[0].rows[0][0].content
			var spendSourceValue = getSourceValue(3, country, region, industry, spend)
			this.props.changeIndustry(this.props.userData.Industry, spendSourceValue)
		}
		// Navigation
		this.props.goToChoosePath(false)
		//Pass organization value to reducer forms
		this.formDispatch(actions.change('discovery.Organization', this.props.userData.Organization));
		this.formDispatch(actions.change('discovery.Industry', industry));
	}

	initialFieldValues() {
		/*const companyData = this.props.companyData ? this.props.companyData[0] : undefined;
		if(companyData) {
			const industryIdx = this.props.content.form.industries.indexOf(companyData.Industry);
			const locationIdx = this.props.content.form.locations.indexOf(companyData.Location);
			companyData.Industry = industryIdx;
			companyData.Location = locationIdx;
		}*/
	}

	redirect(routeName) {
		this.props.history.push(routeName);
	}

	openValueStory(routeName) {
		this.dispatchOpenValueStory(routeName)
	}

	dispatchOpenValueStory(routeName) {
		var content = this.props.content;
		this.state.selectedValueStory &&
			this.props.openValueStory(this.state.selectedValueStory, this.props.user.user.Id)
				.then((response) => {
					if (response.status === 200) {
						// Push value story data to store
						this.props.valueStoryFetchDataSuccess(response.data.Result)

						// Add indexes of selected priorities to priorities array in store
						let items = response.data.Result.AreaOfInterest.map((item, i) => item.Selected && i)
						items = items.filter((item) => item !== false)
						this.props.initPrioritiesItems(items)
						this.props.isNewValueStory(false)
						// Reset form
						this.formDispatch(actions.reset('addValueStory'))

						var self = this;
						const calculatedTotalSpend = round(self.props.spendAsPercentageOfRevenue * response.data.Result["AnnualRevenue"], 2)
						if (response.data.Result["TotalSpend"] != calculatedTotalSpend) {
							const handleChange = this.props.handleChange
							this.props.subLeverCalculationContent.subLeverCalculation.forEach(function (obj) {

								const subLever = getSubLeverIdObject(obj.id)

								if (subLeverHasTotalSpend(obj.id)) {
									handleChange(response.data.Result["TotalSpend"], subLever, 0)
								}
							})
						}
						// Update Total Spend in state
						this.props.updateTotalSpend(response.data.Result["TotalSpend"])

						// Update controlled input
						this.dispatchTotalSpendChange(response.data.Result["TotalSpend"])


						// Redirect to Discovery page
						this.redirect(routeName)

						// update store status message
						// this.props.openValueStorySuccess()

						// reset store status message
						//setTimeout(() => {this.props.resetMessage()}, 100)
					}
					else {
						var httpStattus = response.response.data.Message
						var failMsg = content[httpStattus]
						if (failMsg !== undefined) {
							failMsg = failMsg.toString().replace("%userName%", response.response.data.Result)
							this.props.openValueStoryFailure(failMsg)
						}
					}
				}).catch((error) => {
					//this.props.openValueStoryFailure(error.response.data.Message)
				})
	}

	addValueStory(routeName) {
		var content = this.props.content;
		//let values = Object.assign({}, formValues)
		this.props.userData.Language = this.props.language.toUpperCase()
		this.props.userData.Id = this.props.user.user.Id

		//values.TotalSpend = removeMask(values.TotalSpend, values.Currency)
		//values.AnnualRevenue = removeMask(values.AnnualRevenue, values.Currency)

		//Organization have taken from state
		//values.Organization = this.state.selectedPEName
		//values.PEId = this.state.selectedPEId
		this.props.addValueStory(this.props.userData)
			.then((result) => {
				if (result.status === 200) {
					// Open Value Story
					this.setState({
						selectedValueStory: result.data.Result.VSId
					}, () => {
						this.openValueStory(routeName);
					})
				}
				else {
					var httpStattus = result.response.status;
					var failMsg = content[httpStattus]
					if (failMsg !== undefined) {
						failMsg = failMsg.toString().replace("%ValueStoryName%", this.props.userData.ValueStoryName)
						failMsg = failMsg.toString().replace("%Organization%", this.props.userData.Organization)
						//formValues.valueStoryName
						this.props.addValueStoryFailure(failMsg)
					}
					else {
						this.props.addValueStoryFailure(result.response.data.Message)

					}
				}

			}).catch((error) => {
				var httpStattus = error.response.status;
				var failMsg = content[httpStattus]
				if (failMsg !== undefined) {
					failMsg = failMsg.toString().replace("%ValueStoryName%", this.props.userData.ValueStoryName)
					failMsg = failMsg.toString().replace("%Organization%", this.props.userData.Organization)
					//formValues.valueStoryName
					this.props.addValueStoryFailure(failMsg)
				}
				else {
					this.props.addValueStoryFailure(error.response.data.Message)

				}
			})
	}

	goToRoute(routeName) {
		if (this.props.newValueStory) {
			this.addValueStory(routeName);
		} else {
			if (this.props.form.valid && Number.parseFloat(this.props.userData.AnnualRevenue) !== 0) {
				this.props.history.push(routeName);
				window.scrollTo({
					top: 0,
					behavior: "smooth"
				});
			}
		}
	}

	handleMouseEnter(e){
		const selectedButton = e.target.innerText;
		this.props.syncMenuAndButton(selectedButton)		
	}

	handleMouseLeave=()=>{
		this.props.syncMenuAndButton('')		
	}

	render() {
		let slideClasses = classnames('discovery__slide')

		if (this.props.valueStoryIsLoading) {
			return <p>Loading...</p>
		} else {
			return (
				<div className="discovery grid-container">
					<div className={slideClasses}>
						<Form
							model="discovery"
							getDispatch={(dispatch) => this.formDispatch = dispatch}
						>
							<div className="grid-x grid-padding-x">
							
								<div className="medium-6 cell ">
									
										<DiscoveryCompanyForm
											content={this.props.content}
											capIQId={this.state.capIQId}
										/>
								</div>
								<div className="medium-5 cell medium-offset-1">
									<DiscoveryForm
										content={this.props.content.form}
										subLeverCalculationContent={this.props.subLeverCalculationContent.subLeverCalculation}
										dispatchLocationChange={this.dispatchLocationChange}
										dispatchIndustryChange={this.dispatchIndustryChange}
										dispatchTotalSpendChange={this.dispatchTotalSpendChange}
										dispatchUpdateTotalSpend={this.props.updateTotalSpend}
										dispatchUpdateOrganization={this.dispatchUpdateOrganization}
										dispatchCapIQChange={this.dispatchCapIQChange}
									/>
									<div className="formBg profile-pg">
										<div className="grid-x">
											{this.actions.indexOf(routes.areaOfInterest) > -1 ?
												<div className="medium-6 cell">
													<button className={classnames('button button--accent',
													(this.props.hoverButton === this.props.content.buttons.goToPriorities ) ? 'hoverStyle' : null)}													 
												    disabled={this.state.btnDisabled} onClick={(e) => { this.goToRoute(routes.areaOfInterest) }} onMouseEnter={(e)=> this.handleMouseEnter(e)} onMouseLeave={this.handleMouseLeave}>
													<span className="button__inner">{this.props.content.buttons.goToPriorities}</span>
												</button></div> : null
											}
											{this.actions.indexOf(routes.skus) > -1 ?
												<div className="medium-6 cell">
													<button className={classnames('button button--accent',
													(this.props.hoverButton === this.props.content.buttons.goToSolutions ) ? 'hoverStyle' : null)}
													disabled={this.state.btnDisabled} onClick={(e) => { this.goToRoute(routes.skus) }} onMouseEnter={(e)=> this.handleMouseEnter(e)} onMouseLeave={this.handleMouseLeave}>
													<span className="button__inner">{this.props.content.buttons.goToSolutions}</span>
												</button></div> : null
											}
										</div>
										<div className="grid-x pt10">
											{this.actions.indexOf(routes.yourSpend) > -1 ?
												<div className="medium-6 cell">
													<button className={classnames('button button--accent',
													(this.props.hoverButton === this.props.content.buttons.goToSpend ) ? 'hoverStyle' : null)}
													disabled={this.state.btnDisabled} onClick={(e) => { this.goToRoute(routes.yourSpend) }} onMouseEnter={(e)=> this.handleMouseEnter(e)} onMouseLeave={this.handleMouseLeave}>
														<span className="button__inner">{this.props.content.buttons.goToSpend}</span>
													</button>
												</div> : null
											}
											{this.actions.indexOf(routes.businessValue) > -1 ?
												<div className="medium-6 cell">
													<button className={classnames('button button--accent',
													(this.props.hoverButton === this.props.content.buttons.goToBusinessCase ) ? 'hoverStyle' : null)} 
													disabled={this.state.btnDisabled} onClick={(e) => { this.goToRoute(routes.businessValue) }} onMouseEnter={(e)=> this.handleMouseEnter(e)} onMouseLeave={this.handleMouseLeave} >
														<span className="button__inner">{this.props.content.buttons.goToBusinessCase}</span>
													</button>
												</div> : null
											}
										</div>
									</div>
								</div>
							</div>
						</Form>
					</div>
				</div >
			)
		}
	}
}

const mapStateToProps = (state) => {
	return {
		language: state.language,
		ui: state.content.ui,
		content: state.content.discovery,
		subLeverCalculationContent: state.content.subLeverCalculations,
		user: state.default,
		userData: state.valueStory,
		spendAsPercentageOfRevenue: state.spendAsPercentageOfRevenue,
		form: state.discoveryForm.$form,
		slide: state.goToChoosePath,
		priorities: state.priorities,
		valueStoryHasErrored: state.valueStoryHasErrored,
		valueStoryIsLoading: state.valueStoryIsLoading,
		benchMarksData: state.benchMarksData,
		regions: state.regions,
		companyData: state.companyProfileData,
		selectedValueStory: state.selectedValueStory,
		newValueStory: state.isNewValueStory,
		hoverButton: state.syncMenuAndButton
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		updateOrganization: (organization) => dispatch(updateOrganization(organization)),
		changeLocation: (location, spendAsPercentageOfRevenue) => dispatch(changeLocation(location, spendAsPercentageOfRevenue)),
		changeIndustry: (industry, spendAsPercentageOfRevenue) => dispatch(changeIndustry(industry, spendAsPercentageOfRevenue)),
		updateTotalSpend: (spend) => dispatch(updateTotalSpend(spend)),
		goToChoosePath: (bool) => dispatch(goToChoosePath(bool)),
		toggleValueDriver: (id, bool) => dispatch(toggleValueDriver(id, bool)),
		toggleSubLever: (id, bool) => dispatch(toggleSubLever(id, bool)),
		handleChange: (value, subLeverId, rowNumber) => dispatch(updateUserInput(value, subLeverId, rowNumber)),
		addValueStory: (formValues) => dispatch(addValueStory(formValues)),
		addValueStorySuccess: (valueStory) => dispatch(addValueStorySuccess(valueStory)),
		addValueStoryFailure: (error) => dispatch(addValueStoryFailure(error)),
		openValueStory: (VSId, Id) => dispatch(openValueStory(VSId, Id)),
		openValueStorySuccess: () => dispatch(openValueStorySuccess()),
		openValueStoryFailure: (error) => dispatch(openValueStoryFailure(error)),
		valueStoryFetchDataSuccess: (valueStory) => dispatch(valueStoryFetchDataSuccess(valueStory)),
		initPrioritiesItems: (items) => dispatch(initPrioritiesItems(items)),
		isNewValueStory: (data) => dispatch(isNewValueStory(data)),
		valueStoryCurrentMenu: (menuItem) => dispatch(valueStoryCurrentMenu(menuItem)),
		showValueStoryMenu: (menuItem) => dispatch(showValueStoryMenu(menuItem)),
		syncMenuAndButton:(selectedButton)=>dispatch(syncMenuAndButton(selectedButton))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DiscoveryContainer)
