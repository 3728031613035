import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Control, Errors } from 'react-redux-form'
import Popup from "reactjs-popup";

// Actions
import {
	autoSave,
	resetMessage,
	resetError
} from 'actions/userActions'

// UI
import Radio from 'react-mdl/lib/Radio'
import Textfield from 'react-mdl/lib/Textfield'

class SpendReport extends Component {

	constructor(props) {
		super(props)

		this.state = { render: false }

		this.save = this.save.bind(this)

	}

	// Save state to backend before user sends report
	save() {
		let token = localStorage.getItem('jwtToken')

		if (this.props.user.user && token) {
			this.props.autoSave(this.props.userData, this.props.user.userprofile.Roles[0].indexOf("BusinessCaseRole") > -1)
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		return nextState.render ? true : false
	}

	componentWillMount() {
		this.props.resetMessage()
		this.props.resetError()

		let flag = localStorage.getItem('enablementInsightFlag')
		if(flag == "true"){
			this.setState({ enablementInsightFlag: true })
		}else{
			this.setState({ enablementInsightFlag: false })
		}
	}

	componentWillUnmount() {
		this.props.resetMessage()
		this.props.resetError()
	}

	componentDidMount() {
		//this.updateAllCalculations()
		this.save()
		this.setState({
			render: true
		})
	}

	render() {
		var _applicationFeatures = this.props.user.user.applicationFeatures
		var displayAllReports = (item, _props) => {
			if (item.reportAction === undefined && item.reportAction === null) {
				return null;
			}

			var Checked = true;
			var obj = Object.keys(item).map(function (childitem) {
				if (_applicationFeatures.findIndex(a => a.Action === (item[childitem].reportAction)) > -1) {
					let iconpath = "images/" + item[childitem].icon;
					return (
						(item[childitem].reportId == 9 && !_props.user.userprofile.Roles[0].includes("Standard Internal User")) ? null :
						(item[childitem].reportId == 1 && _props.user.userprofile.Roles[0].includes("Standard Customer User")) ? null :
						(item[childitem].reportId == 2 && _props.user.userprofile.Roles[0].includes("Standard Customer User")) ? null :
						(item[childitem].reportId == 5 && _props.user.userprofile.Roles[0].includes("Standard Customer User")) ? null :
						(item[childitem].reportId == 6 && _props.user.userprofile.Roles[0].includes("Standard Customer User")) ? null :
						(item[childitem].reportId == 7 && _props.user.userprofile.Roles[0].includes("Standard Customer User")) ? null :
						(item[childitem].reportId == 8 && _props.user.userprofile.Roles[0].includes("Standard Customer User")) ? null :
						(item[childitem].reportId == 9 && _props.user.userprofile.Roles[0].includes("Standard Customer User")) ? null :
						item[childitem].isActiveReport !== undefined &&
							item[childitem].isActiveReport === true ?							
							<div style={{ display: 'inline-block' }}>
								<div className="report-thumbnail">
									<div className="grid-x">
										<div className="title medium-10">{item[childitem].title}</div>
										<div className="medium-2">
											<Popup className="report-tooltip"
												trigger={<img className="info-icon" src="images/info-icon.svg" width="15" />}
												position="bottom center"
												on="hover"
											>
												{item[childitem].reportDescription}
											</Popup>

										</div>
									</div>
									<div className="grid-x height180">
										<img width="100" className="icon-img" src={iconpath} />
									</div>
									<div className="grid-x report-radio-btn-wrapper">
										<Radio
											value={item[childitem].reportId}
											name="summaryReport"
											defaultChecked={Checked}
											disabled = {item[childitem].reportId === -1 ? true : false }
											onClick={(e) => _props.handleRadioButtonClick(e.target.value)}>
										</Radio>
										{Checked = false}
									</div>
								</div>
							</div>
							: <div />

					) // return ends
				}
				else {
					return null;
				}
			});//object map end
			return obj;


		};

		return (
			<div>
				<div>
					<Control.text
						model="report.Email"
						id="report.Email"
						component={Textfield}
						value={this.props.user.user.Email}
						defaultValue={this.props.user.user.Email}
						hidden
					/>
					<Control.text
						model="report.id"
						id="report.id"
						value={this.props.userData.VSId}
						defaultValue={this.props.userData.VSId}
						hidden
					/>
					<Control.text
						model="report.Language"
						id="report.Language"
						value={this.props.language.toUpperCase()}
						defaultValue={this.props.language.toUpperCase()}
						hidden
					/>
				</div>
				<div className="medium-12">
					<div className="grid-x">
						<div className="medium-12 blue-bg-customReport">
							<div className="centered-icon">
								{this.state.enablementInsightFlag ? displayAllReports(this.props.allReports.enablementReportTypes, this.props) : displayAllReports(this.props.allReports.reportTypes, this.props)}
							</div>
						</div>
					</div>
				</div>
				{/* <div className="medium-6 blue-bg">
					<div className="centered-icon">
						{displayAllReports(this.props.allReports.reportTypes, this.props)}
					</div>
				</div> */}
			</div>

		)
	}
}

const mapStateToProps = (state) => {
	return {
		language: state.language,
		user: state.default,
		validation: state.content.validation,
		subLeverCalculationContent: state.content.subLeverCalculations.subLeverCalculation,
		spendAsPercentageOfRevenue: state.spendAsPercentageOfRevenue,
		WACC: state.valueStory.BusinessValueToYou[0].ValueDriver[3].SubLever[1].UserInput[4].UserInput,
		NPV: state.valueStory.BusinessValueFromSap[0].NPV,
		ROI: state.valueStory.BusinessValueFromSap[0].ROIValue,
		ROIData: state.valueStory.BusinessValueFromSap[0].ROI,
		valueOfDelayedInvestment: state.valueStory.BusinessValueFromSap[0].ValueOfDelayedInvestment,
		paybackPeriod: state.valueStory.BusinessValueFromSap[0].Payback,
		userData: state.valueStory,
		businessValueFromSAPAribaData: state.valueStory.BusinessValueFromSap[0],
		calculateROIData: state.valueStory.BusinessValueFromSap[0].ROI,
		content: state.content.spendAnalysis.spendReport,
		allReports: state.content.reports

	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		autoSave: (ValueStory, isBusinessCaseAccess) => dispatch(autoSave(ValueStory, isBusinessCaseAccess)),
		resetMessage: () => dispatch(resetMessage()),
		resetError: () => dispatch(resetError())
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SpendReport)


