import React, { Component } from 'react'
import { connect } from 'react-redux'
import Popup from "reactjs-popup";
import Button from 'UI/Button'

// Actions
import {
	userMask,
	logoutUser,
	resetToken
} from 'actions/userActions'

import { clearLocalStorage } from '../../utils/Utils'
// UI
import Modal from 'reboron/OutlineModal'

import { changeModalStyle, changeModalContentStyle, path } from 'Constants'

class WithdrawConsent extends Component {
	constructor(props) {
		super(props)

		this.state = { render: false, isError: false, responseMsg: '' }
		this.withdrawConsent = this.withdrawConsent.bind(this)
	}

	showModal(action) {
        this.refs[action].show()
    }

    hideModal(action) {
		this.refs[action].hide()
		this.handleLogout()
	}
	
	handleLogout() {
		this.props
		  .logoutUser()
		  .then(() => {
			this.props.resetToken()
			clearLocalStorage()
	
			// Redirect to home page
			window.location.href = path
	
			// this.props.resetValueStory()
		  })
		  .catch(() => {
			clearLocalStorage();
			// Redirect to home page
			window.location.href = path;
		  })
	  }
	
	withdrawConsent() {
		this.setState({isError: false, responseMsg: ''})
		this.props.userMask(this.props.user.user.Email)
		.then(response => {
			if(response.status === 200) {
				this.showModal('terms')
			} else {
				this.setState({isError: true, responseMsg: 'Failed to remove user'})
			}
		})
		.catch((error) => {
			this.setState({isError: true, responseMsg: 'Failed to remove user'})
		})
	}

	render() {
		return (
			<div className="grid-x">
				<div className="medium-11 pt30">
					{this.props.content.description}
				</div>
				<div className="medium-11">
					<span className="medium-11 float-right pt10">
						<Button
						content={this.props.content.title}
						onClick={() => this.withdrawConsent()}
						color />
					</span>
					{(this.state.isError === 'Yes') ?
                            <div style={{ color: 'red', textAlign: 'center' }} className="cell pt5">{this.state.responseMsg}</div>
                            : <div style={{ color: 'green', textAlign: 'center' }} className="cell pt5">{this.state.responseMsg}</div>
                        }
				</div>
				<div>
                    <Modal
                        ref="terms"
                        modalStyle={changeModalStyle}
                        contentStyle={changeModalContentStyle}
                    >
                        <div className="grid-x grid-padding-x change-popup">
                            <div className="medium-12 pt30">
                                {this.props.content.acknowledgement}
                            </div>
                            <div className="medium-12 popupOkBtn">
                                <div className="float-right">
                                    <div className="float-right"><Button
                                        content={"Close"}
                                        onClick={() => this.hideModal("terms")}
                                        color /></div>
                                </div>
                            </div>
                        </div>

                    </Modal>
                </div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		language: state.language,
		user: state.default,
		validation: state.content.validation,
		content : state.content.menu.WithdrawConsent[0].withdrawConsentDetails
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		userMask: (email) => dispatch(userMask(email)),
		logoutUser: () => dispatch(logoutUser()),
		resetToken: () => dispatch(resetToken()),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(WithdrawConsent)


