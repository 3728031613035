import {
	TOP_DETAILS_LOADING, TOP_DETAILS_SUCCESS,
	TOP_DETAILS_FAILURE, RESET_ERROR, GET_YOURFILE_DETAILS_LOADING,
	GET_YOURFILE_DETAILS_SUCCESS, GET_YOURFILE_DETAILS_FAILURE, DEFINESCOPE_FAILURE,
	DEFINESCOPE_LOADING, DEFINESCOPE_SUCCESS, GET_RESULT_LOADING, GET_RESULT_SUCCESS, GET_RESULT_FAILURE, SET_POPUP_TAB_LOADING_STATUS, CURRENT_ACTIVE_REQUEST,OLD_ACTIVE_REQUEST,
	GET_ALLINDUSTRIES_LOADING, GET_ALLINDUSTRIES_SUCCESS, GET_ALLINDUSTRIESTREESTRUCTURE_SUCCESS,
	GET_ALLINDUSTRIES_FAILURE, STORE_OVERRIDED_INDUSTRY, STORE_ENABLEMENT_WAVE_VALUE, SET_SEARCHCLEAR_FLAG, SCOPESUMMARY_SUCCESS, SCOPESUMMARY_LOADING,
	SCOPESUMMARY_FAILURE, SCOPESUMMARYBUYPROCESS_SUCCESS, SCOPESUMMARYBUYPROCESS_LOADING, SCOPESUMMARYBUYPROCESS_FAILURE
	, ENABLEMENT_SUCCESS, ENABLEMENT_LOADING, ENABLEMENT_FAILURE,
	GET_YOURFILE_REQUESTDETAILS, GET_YOURFILE_VALUESTORYREQUESTDETAILS,
	GET_RESULTSUMMARY_DETAILS, GET_TOP_DETAILS, GET_INPUTANDCLASSIFICATION_LOADING, GET_INPUTANDCLASSIFICATION_SUCCESS, GET_INPUTANDCLASSIFICATION_FAILURE,
	GET_SPCAPITALIQ_SUCCESS, GET_SPCAPITALIQ_FAILURE, GET_SPCAPITALIQ_LOADING,
	GET_SPCAPITALIQULTIMATEPARENT_LOADING, GET_SPCAPITALIQULTIMATEPARENT_SUCCESS, GET_SPCAPITALIQULTIMATEPARENT_FAILURE, STORE_SUPPLIER_SKEY, STORE_GICS_ID, STORE_INDUSTRY_DETAILS,
	GET_SUPPLIERDRILLSCOPE_LOADING, GET_SUPPLIERDRILLSCOPE_SUCCESS, GET_SUPPLIERDRILLSCOPE_FAILURE, STORE_VENDOR_DETAILS,
	STORE_INDUSTRY_ID, TRACK_SUPPLIERDRILLDOWN_PENDING_CHANGES, TRACK_SUPPLIERDRILLDOWN_SAVE_CHANGES,
	GET_SUPPLIERDRILLDOWNUPDATED_FAILURE, GET_SUPPLIERDRILLDOWNUPDATED_SUCCESS, GET_SUPPLIERDRILLDOWNUPDATED_LOADING, GET_INDUSTRYDRILLSCOPE_LOADING, GET_INDUSTRYDRILLSCOPE_SUCCESS, GET_INDUSTRYDRILLSCOPE_FAILURE,
	INDUSTRYDRILLSCOPEUPDATE_FAILURE, INDUSTRYDRILLSCOPEUPDATE_LOADING, INDUSTRYDRILLSCOPEUPDATE_SUCCESS,
	GET_INDUSTRYSPEND_LOADING, GET_INDUSTRYSPEND_SUCCESS, GET_INDUSTRYSPEND_FAILURE, GET_MAPPEDCOLUMN, GET_INDUSTRYSPENDUPDATED_FAILURE, GET_INDUSTRYSPENDUPDATED_SUCCESS, GET_INDUSTRYSPENDUPDATED_LOADING,
	TRACK_INDUSTRYDRILLSCOPE_PENDING_CHANGES, INDUSTRYSUPPLIER_LOADING, INDUSTRYSUPPLIER_SUCCESS, INDUSTRYSUPPLIER_FAILURE,
	GET_INDUSTRYCAPIQ_LOADING, GET_INDUSTRYCAPIQ_SUCCESS, TRACK_USER_SPEND_SELECTIONS, CLEAR_CURRENT_ACTIVE_REQUEST, GET_CAPIQDATA_BLANK, STORE_INPUTANDCLASSIFICATION_DETAILS, STORE_OVERRIDED_CAPIQID,
	ENABLEMENTINSIGHT_FAILURE, ENABLEMENTINSIGHT_LOADING, ENABLEMENTINSIGHT_SUCCESS, STORE_ENABLEMENTINSIGHT_FILTER, REDIRECT_FROM_ENABLEMENT_TAB, REDIRECT_FROM_WIZARD,
	GET_ALLINDUSTRIES_GICS_LOADING, GET_ALLINDUSTRIES_GICS_SUCCESS, GET_ALLINDUSTRIES_GICS_FAILURE, GET_UPDATE_GICS_LOADING, GET_UPDATE_GICS_SUCCESS,
	GET_UPDATE_GICS_FAILURE, USER_DETAILS_FAILURE, USER_DETAILS_SUCCESS, USER_DETAILS_LOADING
} from 'actions/spendActions';

export const INITIAL = { valueStoryRequestDetails: [], requestFileDetails: [], isLoading: false, error: null, message: null, currentActiveRequest: '', filter: {}, changes: null, resultDetails: [], resultTopDetails: [], mappedColumn: [], isBlank: false, isRedirected: false, solutionScope: 'Buying and Invoicing' }

export const spendDetails = (state = INITIAL, action) => {
	switch (action.type) {
		case TOP_DETAILS_LOADING:
			return { ...state, isLoading: action.payload }
		case TOP_DETAILS_SUCCESS:
			return { ...state, isLoading: false, message: action.payload, error: '' };
		case TOP_DETAILS_FAILURE:
			return { ...state, isLoading: false, error: action.payload };
		case GET_TOP_DETAILS:
			return { ...state, resultTopDetails: action.payload }

		case GET_YOURFILE_DETAILS_LOADING:
			return { ...state, isLoading: action.payload };
		case GET_YOURFILE_DETAILS_SUCCESS:
			return { ...state, isLoading: false, message: action.payload };
		case GET_YOURFILE_DETAILS_FAILURE:
			return { ...state, isLoading: false, error: action.payload };
		case GET_YOURFILE_REQUESTDETAILS:
			return { ...state, requestFileDetails: action.payload };
		case GET_YOURFILE_VALUESTORYREQUESTDETAILS:
			return { ...state, valueStoryRequestDetails: action.payload };

		case GET_MAPPEDCOLUMN:
			return { ...state, mappedColumn: action.payload };

		case GET_RESULT_LOADING:
			return { ...state, isLoading: action.payload };
		case GET_RESULT_SUCCESS:
			return { ...state, isLoading: false, message: action.payload };
		case GET_RESULT_FAILURE:
			return { ...state, isLoading: false, error: action.payload };

		case SET_POPUP_TAB_LOADING_STATUS:
			return { ...state, isPopUpTabLoading: action.payload };

		case GET_RESULTSUMMARY_DETAILS:
			return { ...state, resultDetails: action.payload }

		case DEFINESCOPE_LOADING:
			return { ...state, isLoading: action.payload };
		case DEFINESCOPE_SUCCESS:
			return { ...state, isLoading: false, message: action.payload };
		case DEFINESCOPE_FAILURE:
			return { ...state, isLoading: false, error: action.payload };


		case RESET_ERROR:
			return { ...state, isLoading: false, error: null, message: null }

		case CURRENT_ACTIVE_REQUEST:
			return { ...state, currentActiveRequest: action.payload }

		case OLD_ACTIVE_REQUEST:
			return { ...state, oldActiveRequest: action.payload }

		case CLEAR_CURRENT_ACTIVE_REQUEST:
			return { ...state, currentActiveRequest: '' }

		case GET_ALLINDUSTRIES_LOADING:
			return { ...state, isLoading: action.payload }
		case GET_ALLINDUSTRIES_SUCCESS:
			return { ...state, isLoading: false, industryCache: action.payload };
		case GET_ALLINDUSTRIES_FAILURE:
			return { ...state, isLoading: false, error: action.payload };
		case GET_ALLINDUSTRIESTREESTRUCTURE_SUCCESS:
			return { ...state, isLoading: false, industryTree: action.payload };
		case STORE_OVERRIDED_INDUSTRY:
			return { ...state, overridedIndustryDetails: action.payload };
		case STORE_ENABLEMENT_WAVE_VALUE:
			return { ...state, enablementWaveInputDetails: action.payload };
		case STORE_OVERRIDED_CAPIQID:
			return { ...state, overridedCapIqIdDetails: action.payload };
		case SET_SEARCHCLEAR_FLAG:
			return { ...state, isLoading: false, isSearchORClearIndustry: action.payload };

		case SCOPESUMMARY_LOADING:
			return { ...state, isLoading: action.payload };
		case SCOPESUMMARY_SUCCESS:
			return { ...state, isLoading: false, message: action.payload };
		case SCOPESUMMARY_FAILURE:
			return { ...state, isLoading: false, error: action.payload };

		case SCOPESUMMARYBUYPROCESS_LOADING:
			return { ...state, isLoading: action.payload };
		case SCOPESUMMARYBUYPROCESS_SUCCESS:
			return { ...state, isLoading: false, message: action.payload };
		case SCOPESUMMARYBUYPROCESS_FAILURE:
			return { ...state, isLoading: false, error: action.payload };

		case ENABLEMENT_LOADING:
			return { ...state, isLoading: action.payload };
		case ENABLEMENT_SUCCESS:
			return { ...state, isLoading: false, message: action.payload };
		case ENABLEMENT_FAILURE:
			return { ...state, isLoading: false, error: action.payload };
		case STORE_ENABLEMENTINSIGHT_FILTER:
			return { ...state, enablementInsightFilters: action.payload };

		case STORE_SUPPLIER_SKEY:
			return { ...state, isLoading: false, supplierDetails: action.payload };
		case STORE_VENDOR_DETAILS:
			return { ...state, isLoading: false, vendorDetails: action.payload };
		case STORE_INDUSTRY_ID:
			return { ...state, isLoading: false, L5IndustryID: action.payload };
		case STORE_INPUTANDCLASSIFICATION_DETAILS:
			return { ...state, isLoading: false, inputAndClassificationDetails: action.payload };
		case GET_INPUTANDCLASSIFICATION_LOADING:
			return { ...state, isLoading: action.payload };
		case GET_INPUTANDCLASSIFICATION_SUCCESS:
			return { ...state, isLoading: false, classificationData: action.payload };
		case GET_INPUTANDCLASSIFICATION_FAILURE:
			return { ...state, isLoading: false, error: action.payload };

		case GET_CAPIQDATA_BLANK:
			return { ...state, isBlank: action.payload };
		case GET_SPCAPITALIQ_LOADING:
			return { ...state, isLoading: action.payload };
		case GET_SPCAPITALIQ_SUCCESS:
			return { ...state, isLoading: false, SPCapitalIQData: action.payload };
		case GET_SPCAPITALIQ_FAILURE:
			return { ...state, isLoading: false, error: action.payload };

		case GET_SPCAPITALIQULTIMATEPARENT_LOADING:
			return { ...state, isLoading: action.payload };
		case GET_SPCAPITALIQULTIMATEPARENT_SUCCESS:
			return { ...state, isLoading: false, SPCapitalIQUltimateParentData: action.payload };
		case GET_SPCAPITALIQULTIMATEPARENT_FAILURE:
			return { ...state, isLoading: false, error: action.payload };

		case GET_SUPPLIERDRILLSCOPE_LOADING:
			return { ...state, isLoading: action.payload };
		case GET_SUPPLIERDRILLSCOPE_SUCCESS:
			return { ...state, isLoading: false, supplierDrillScopeData: action.payload };
		case GET_SUPPLIERDRILLSCOPE_FAILURE:
			return { ...state, isLoading: false, error: action.payload };
		case TRACK_SUPPLIERDRILLDOWN_PENDING_CHANGES:
			return { ...state, isLoading: false, supplierDrilldownPendingChanges: action.payload };
		case TRACK_SUPPLIERDRILLDOWN_SAVE_CHANGES:
			return { ...state, isLoading: false, supplierDrilldownSavedChanges: action.payload };
		case GET_SUPPLIERDRILLDOWNUPDATED_LOADING:
			return { ...state, isLoading: action.payload };
		case GET_SUPPLIERDRILLDOWNUPDATED_SUCCESS:
			return { ...state, isLoading: false, message: action.payload };
		case GET_SUPPLIERDRILLDOWNUPDATED_FAILURE:
			return { ...state, isLoading: false, error: action.payload };
		case GET_INDUSTRYSPEND_LOADING:
			return { ...state, isLoading: action.payload };
		case GET_INDUSTRYSPEND_SUCCESS:
			return { ...state, isLoading: false, message: action.payload };
		case GET_INDUSTRYSPEND_FAILURE:
			return { ...state, isLoading: false, error: action.payload };

		case GET_INDUSTRYDRILLSCOPE_LOADING:
			return { ...state, isLoading: action.payload };
		case GET_INDUSTRYDRILLSCOPE_SUCCESS:
			return { ...state, isLoading: false, industryDrillScopeDetails: action.payload };
		case GET_INDUSTRYDRILLSCOPE_FAILURE:
			return { ...state, isLoading: false, error: action.payload };

		case STORE_GICS_ID:
			return { ...state, isLoading: false, industryGICS_Details: action.payload };
		case STORE_INDUSTRY_DETAILS:
			return { ...state, isLoading: false, industryDetails: action.payload };

		case GET_INDUSTRYSPENDUPDATED_LOADING:
			return { ...state, isLoading: action.payload };
		case GET_INDUSTRYSPENDUPDATED_SUCCESS:
			return { ...state, isLoading: false, message: action.payload };
		case GET_INDUSTRYSPENDUPDATED_FAILURE:
			return { ...state, isLoading: false, error: action.payload };

		case INDUSTRYDRILLSCOPEUPDATE_LOADING:
			return { ...state, isLoading: action.payload };
		case INDUSTRYDRILLSCOPEUPDATE_SUCCESS:
			return { ...state, isLoading: false, message: action.payload };
		case INDUSTRYDRILLSCOPEUPDATE_FAILURE:
			return { ...state, isLoading: false, error: action.payload };

		case TRACK_INDUSTRYDRILLSCOPE_PENDING_CHANGES:
			return { ...state, isLoading: false, industryDrillScopePendingChanges: action.payload };

		case INDUSTRYSUPPLIER_LOADING:
			return { ...state, isLoading: action.payload };
		case INDUSTRYSUPPLIER_SUCCESS:
			return { ...state, isLoading: false, message: action.payload };
		case INDUSTRYSUPPLIER_FAILURE:
			return { ...state, isLoading: false, error: action.payload };
		case GET_INDUSTRYCAPIQ_SUCCESS:
			return { ...state, isLoading: false, message: action.payload };
		case GET_INDUSTRYCAPIQ_LOADING:
			return { ...state, isLoading: false, error: action.payload };
		case TRACK_USER_SPEND_SELECTIONS:
			return { ...state, isLoading: false, userSpendFilterSelections: action.payload };

		case ENABLEMENTINSIGHT_LOADING:
			return { ...state, isLoading: action.payload };
		case ENABLEMENTINSIGHT_SUCCESS:
			return { ...state, isLoading: false, message: action.payload };
		case ENABLEMENTINSIGHT_FAILURE:
			return { ...state, isLoading: false, error: action.payload };

		case USER_DETAILS_LOADING:
			return { ...state, error: null, loading: action.payload };
		case USER_DETAILS_SUCCESS:
			let msg = action.payload //|| {msg: action.payload.message};
			return { ...state, error: null, loading: false, message: msg };
		case USER_DETAILS_FAILURE:
			let error = action.payload || { message: action.payload.message };
			return { ...state, error: action.payload, loading: false };

		case REDIRECT_FROM_ENABLEMENT_TAB:
			return { ...state, isLoading: false, isRedirected: action.payload };
		case REDIRECT_FROM_WIZARD:
			return { ...state, isLoading: false, redirectedFromWizard: action.payload };

		case 'SOLUTION_SCOPE_CHANGED':
			return { ...state, solutionScope: action.payload }
		case 'GET_IS_KPI_STRATEGY_PAGE':
			return { ...state, isLoading: false, isKpiStrategy: action.payload };
		case 'GET_IS_SPEND_PARAMETER_PAGE':
			return { ...state, isLoading: false, isSpendParameterPage: action.payload };
		case 'KPI_MODEL_CHANGED':
			return { ...state, kpi_Model: action.payload }
		default:
			return state
	}
}

export const detailedFileFilters = (state = INITIAL, action) => {
	switch (action.type) {
		case 'Deatiled-Filters':
			return { ...state, filter: action.payload }

		default:
			return state
	}
}

export const detailedFileFilterText = (state = null, action) => {
	switch (action.type) {
		case 'Deatiled-FilterText':
			return action.payload

		default:
			return state
	}
}

export const detailedFileChanges = (state = INITIAL, action) => {
	switch (action.type) {
		case 'DeatiledFile-Changes':
			return { ...state, changes: action.payload }

		default:
			return state
	}
}

export const saveKPIOnRedirect = (state = INITIAL, action) => {
	switch (action.type) {
		case 'SaveKPIOnRedirect-Changes':
			return { ...state, changes: action.payload }

		default:
			return state
	}
}

export const saveSpendParametersOnRedirect = (state = INITIAL, action) => {
	switch (action.type) {
		case 'SaveSpendParametersOnRedirect-Changes':
			return { ...state, changes: action.payload }

		default:
			return state
	}
}

export const isWaveRecalcOnSpendParamRedirect = (state = INITIAL, action) => {
	switch (action.type) {
		case 'isWaveRecalcOnSpendParamRedirect':
			return { ...state, changes: action.payload }

		default:
			return state
	}
}

export const deleteIntermediateKPIOnRedirect = (state = INITIAL, action) => {
	switch (action.type) {
		case 'DeleteKPIOnRedirect-Changes':
			return { ...state, changes: action.payload }

		default:
			return state
	}
}


export const gicsDetails = (state = INITIAL, action) => {
	switch (action.type) {
		case GET_ALLINDUSTRIES_GICS_LOADING:
			return { ...state, isLoading: action.payload }
		case GET_ALLINDUSTRIES_GICS_SUCCESS:
			return { ...state, isLoading: false, message: action.payload, error: '' };
		case GET_ALLINDUSTRIES_GICS_FAILURE:
			return { ...state, isLoading: false, error: action.payload };

		case GET_UPDATE_GICS_LOADING:
			return { ...state, isLoading: action.payload };
		case GET_UPDATE_GICS_SUCCESS:
			return { ...state, isLoading: false, message: action.payload };
		case GET_UPDATE_GICS_FAILURE:
			return { ...state, isLoading: false, error: action.payload };

		default:
			return state
	}
}

