import React from 'react'

// Utils
import classnames from 'classnames'

const AutoSave = (props) =>
	<div className={
		classnames(
			'autosave',
			{
				'autosave--is-active': props.user.status === 'auto_save',
				'autosave--is-error': props.user.status === 'auto_save_error'
			}
		)
	}>
		{ props.user.status === 'auto_save_error' ? props.user.error : props.ui.autosave.saving }
	</div>

export default AutoSave


