import update from 'immutability-helper'

export const valueStoryHasErrored = (state = false, action) => {
    switch (action.type) {
        case 'VALUESTORY_HAS_ERRORED':
            return action.hasErrored

        default:
            return state
    }
}

export const valueStoryCurrentMenu = (state = 'Company', action) => {
    switch (action.type) {
        case 'VALUESTORY_MENU_ITEM':
            return action.payload

        default:
            return state
    }
}

export const valueStoryIsLoading = (state = false, action) => {
    switch (action.type) {
        case 'VALUESTORY_IS_LOADING':
            return action.isLoading

        default:
            return state
    }
}

export const valueStory = (state = null, action) => {

    switch (action.type) {
        case 'VALUESTORY_FETCH_DATA_SUCCESS':
            //return action.payload
            return action.valueStory

        case 'VALUESTORY_RESET':
            return null

        case 'TOGGLE':
            return update(state, {
                [action.module]: {
                    [action.id]: {
                        [action.stateKey]: { $set: action.selected }
                    }
                }
            })

        case 'TOGGLE_VALUEDRIVER':
            return update(state, {
                [action.module]: {
                    0: {
                        ValueDriver: {
                            [action.id]: {
                                [action.stateKey]: { $set: action.selected }
                            }
                        }
                    }
                }
            })

        case 'TOGGLE_SUBLEVER':
            return update(state, {
                BusinessValueToYou: {
                    0: {
                        ValueDriver: {
                            [action.id.BVUIdIndex]: {
                                SubLever: {
                                    [action.id.BVUSVDIdIndex]: {
                                        Selected: { $set: action.selected }
                                    }
                                }
                            }
                        }
                    }
                }
            })

        case 'UPDATE_VALUESTORYNAME':
            return update(state, {
                ValueStoryName: { $set: action.valuestoryName }
            })

        case 'UPDATE_VALUESTORYTYPE':
                return update(state, {
                    ValueStoryType: { $set: action.valueStoryType }
                })    

        case 'UPDATE_CAPIQID':
            return update(state, {
                CapIQId: { $set: action.capiqid }
            })
            
        case 'UPDATE_ORGANIZATION':
            return update(state, {
                Organization: { $set: action.organization }
            })

        case 'UPDATE_BPID':
            return update(state, {
                BPId: { $set: action.bpId }
            }) 
            
        case 'UPDATE_ANID':
            return update(state, {
                ANId: { $set: action.anId }
            })     

        case 'CHANGE_LOCATION':
            return update(state, {
                Location: { $set: action.location }
            })

        case 'CHANGE_INDIRECTSCOPE':
            return update(state, {
                IndirectSolutionScope: { $set: action.indirectSolutionScope }
            })

        case 'CHANGE_INDUSTRY':
            return update(state, {
                Industry: { $set: action.industry }
            })

        case 'UPDATE_CURRENCY':
            return update(state, {
                Currency: { $set: action.currency }
            })

        case 'UPDATE_CURRENCY_CODE':
            return update(state, {
                CurrencyCode: { $set: action.currencyCode }
            })

        case 'ADD_REVENUE':
            return update(state, {
                AnnualRevenue: { $set: action.revenue }
            })

        case 'UPDATE_TOTAL_SPEND':
            return update(state, {
                TotalSpend: { $set: action.spend }
            })



        case 'SET_INCOME_STATEMENT':
            return update(state, {
                BusinessValueToYou: {
                    0: {
                        IncomeStatement: { $set: action.incomeStatement }
                    }
                }
            })

        case 'SET_BALANCE_SHEET':
            return update(state, {
                BusinessValueToYou: {
                    0: {
                        BalanceSheet: { $set: action.balanceSheet }
                    }
                }
            })

        case 'PRIORITY_LEVEL_CHANGE':
            return update(state, {
                AreaOfInterest: {
                    [action.id]: {
                        AOIValueDrivers: {
                            [action.subIndex]: {
                                Value: { $set: action.value }
                            }
                        }
                    }
                }
            })

        case 'UPDATE_SUBLEVER_CALCULATION_USER_INPUT':
            return update(state, {
                BusinessValueToYou: {
                    0: {
                        ValueDriver: {
                            [action.BVUIdIndex]: {
                                SubLever: {
                                    [action.BVUSVDIdIndex]: {
                                        UserInput: {
                                            [action.rowNumber]: {
                                                UserInput: { $set: action.value }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            })

        case 'UPDATE_SUBLEVER_ROW_VALUE':
            return update(state, {
                BusinessValueToYou: {
                    0: {
                        ValueDriver: {
                            [action.BVUIdIndex]: {
                                SubLever: {
                                    [action.BVUSVDIdIndex]: {
                                        UserInput: {
                                            [action.rowNumber]: {
                                                [action.valueType]: { $set: action.value }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            })

        case 'SET_SUBLEVER_ESTIMATED_ANNUAL_VALUE':
            return update(state, {
                BusinessValueToYou: {
                    0: {
                        ValueDriver: {
                            [action.BVUIdIndex]: {
                                SubLever: {
                                    [action.BVUSVDIdIndex]: {
                                        EstAnnualValue: { $set: action.value }
                                    }
                                }
                            }
                        }
                    }
                }
            })

        case 'UPDATE_CALCULATE_ROI_BENEFIT_REALIZATION_FACTOR':
            return update(state, {
                BusinessValueFromSap: {
                    0: {
                        [action.year]: { $set: action.value }
                    }
                }
            })

        case 'UPDATE_CALCULATE_ROI_USER_INPUT':
            return update(state, {
                BusinessValueFromSap: {
                    0: {
                        ROI: {
                            [action.row]: {
                                UserInput: {
                                    0: {
                                        [action.year]: { $set: action.value }
                                    }
                                }
                            }
                        }
                    }
                }
            })

        case 'UPDATE_CALCULATE_ROI_TOTAL_INVESTMENT':
            return update(state, {
                BusinessValueFromSap: {
                    0: {
                        ROI: {
                            7: {
                                UserInput: {
                                    0: {
                                        [action.year]: { $set: action.value }
                                    }
                                }
                            }
                        }
                    }
                }
            })

        case 'UPDATE_CALCULATE_ROI_TOTAL_BENEFIT':
            return update(state, {
                BusinessValueFromSap: {
                    0: {
                        ROI: {
                            4: {
                                UserInput: {
                                    0: {
                                        [action.year]: { $set: action.value }
                                    }
                                }
                            }
                        }
                    }
                }
            })

        case 'UPDATE_CALCULATE_ROI_NET_BENEFIT':
            return update(state, {
                BusinessValueFromSap: {
                    0: {
                        ROI: {
                            5: {
                                UserInput: {
                                    0: {
                                        [action.year]: { $set: action.value }
                                    }
                                }
                            }
                        }
                    }
                }
            })

        case 'UPDATE_CALCULATE_ROI_CUMULATIVE_BENEFIT':
            return update(state, {
                BusinessValueFromSap: {
                    0: {
                        ROI: {
                            6: {
                                UserInput: {
                                    0: {
                                        [action.year]: { $set: action.value }
                                    }
                                }
                            }
                        }
                    }
                }
            })

        case 'UPDATE_ROI_RESULTS':
            return update(state, {
                BusinessValueFromSap: {
                    0: {
                        [action.module]: { $set: action.value }
                    }
                }
            })

        case 'SET_MUTUAL_ACTION_PLAN':
            return update(state, {
                MutualActionPlan: { $set: action.MutualActionPlan }
            })

        case 'SET_TOGGLE_SKU':
            return update(
                state, {
                    Sku: { $set: action.id }
                }
            )        
        case 'GET_IS_VALUE_STORY_PAGE':
            return { ...state, isLoading: false, isValueStoryPage: action.payload };    
        

        default:
            return state
    } 
}

export const saveValueStoryOnRedirect = (state = false, action) => {
	switch (action.type) {
		case 'SaveValueStoryOnRedirect-Changes':
			return { ...state, changes: action.payload }

		default:
			return state
	}
}
