import React from 'react'
import Parser from 'html-react-parser'
import PropTypes from 'prop-types'

const CaseStudyContainer = (props) =>
	<div className="case-study">
		<div className="grid-x">
			<div className="medium-8 cell">
				<h1>{Parser(props.content.title)}</h1>
				<dl>
					<dt>Company</dt>
					<dd>{props.content.company}</dd>
					<dt>Industry</dt>
					<dd>{props.content.industry}</dd>
					<dt>Employees</dt>
					<dd>{props.content.employees}</dd>
					<dt>Objectives</dt>
					<dd>
						<ul>
							{props.content.objectives.map((data) =>
								<li key={data}>{Parser(data)}</li>
							)}
						</ul>
					</dd>
					<dt>Solutions</dt>
					<dd>
						<ul>
							{props.content.solution.map((data) =>
								<li key={data}>{Parser(data)}</li>
							)}
						</ul>
					</dd>
				</dl>
				<div className="case-study__stats grid-x">
					{props.content.stats.map((data) =>
						<div key={data.title} className="case-study__stat medium-4 cell">
							<div className="grid-x">
								<div className="case-study__stats-value cell">{data.value}</div>
								<div className="case-study__stats-title cell">{data.title}</div>
							</div>
						</div>
					)}
				</div>
			</div>
			<div className="case-study__quote medium-3 medium-offset-1 cell">
				<blockquote>{Parser(props.content.quote.description)}</blockquote>
				<address>
					<strong>{props.content.quote.source}</strong><br />
					{props.content.quote.position}<br />
					{props.content.quote.company}
				</address>
			</div>
		</div>
	</div>


export default CaseStudyContainer

CaseStudyContainer.propTypes = {
	content: PropTypes.shape({
		title: PropTypes.string.isRequired,
		company: PropTypes.string.isRequired,
		industry: PropTypes.string.isRequired,
		employees: PropTypes.string.isRequired,
		objectives: PropTypes.arrayOf(PropTypes.string).isRequired,
		solution: PropTypes.arrayOf(PropTypes.string).isRequired,
		stats: PropTypes.arrayOf(PropTypes.object).isRequired,
		quote: PropTypes.shape({
			source: PropTypes.string.isRequired,
			position: PropTypes.string.isRequired,
			company: PropTypes.string.isRequired
		})
	})
}
