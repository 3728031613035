import axios from 'axios'
import { ROOT_URL } from 'Constants'
import {
    getDetailedFileLoading,
    getDetailedFileSuccess,
    getDetailedFileFileRecordsInsertedLoading,
    getDetailedFileRecordsInsertedSuccess,
    getDetailedFileFileRecordsInsertedFailure
} from './spendActions'

const getSpendKPIBenchmarkAPI = `${ROOT_URL}/KPI/GetSpendKPIBenchmark`
const getKPISetUpDataAPI = `${ROOT_URL}/KPI/GetKPISetUpData`
const updateKPISetUpDataAPI = `${ROOT_URL}/KPI/updateKPISetUpTabData`
const getKPIDashBoardDataAPI = `${ROOT_URL}/KPI/GetKPIDashBoardData`
const getKPIDashBoardDataAPIV2 = `${ROOT_URL}/KPI/GetKPIDashBoardDataV2`
const getKPIDashBoardFinalDataAPIV2 = `${ROOT_URL}/KPI/GetKPIDashBoardFinalDataV2`
const updateKPIDashBoardDataAPI = `${ROOT_URL}/KPI/updateKPIDashBoardTabData`
const updateKPIDashBoardDataAPIV2 = `${ROOT_URL}/KPI/updateKPIDashBoardTabDataV2`
const updateEnablementKPIDashBoard = `${ROOT_URL}/KPI/updateEnablementKPIDashBoard`
const saveIntermediateEnablementKPIDashBoardAPI = `${ROOT_URL}/KPI/SaveIntermediateEnablementKPIDashBoard`
const deleteIntermediateEnablementKPIDashBoardAPI = `${ROOT_URL}/KPI/DeleteIntermediateEnablementKPIDashBoard`
const getKPIChartDataAPI = `${ROOT_URL}/KPI/GetKPIChartData`
const getKPIChartDataAPIV2 = `${ROOT_URL}/KPI/GetKPIChartDataV2`
const getUpdateEnablementKPIModelAPI = `${ROOT_URL}/KPI/GetUpdateEnablementKPIModel`
const getUpdateKpiGoliveProjectStartDateAPI = `${ROOT_URL}/KPI/GetUpdateKpiGoliveProjectStartDate`
const getManualKPIDashBoardDataAPI = `${ROOT_URL}/KPI/GetManualKPIDashBoardData`
const GetManualKPIDashBoardDataPercentCalcAPI = `${ROOT_URL}/KPI/GetManualKPIDashBoardDataPercentCalc`
const getManualKPIResourceType = `${ROOT_URL}/KPI/GetManualKPIResourceType`
const updateEnablementStrategyWave = `${ROOT_URL}/KPI/UpdateEnablementStrategyWave`
//const GetManualKPIManualTargetFetchAPI =  `${ROOT_URL}/KPI/GetManualKPIManualTargetFetch`


export function trackKpiBenchMarkFilters(obj) {
    return {
      type: 'TRACK_KPI_BENCHMARK_FILTERS',
      payload: obj
    };
  }

export function getSpendKPIBenchmark(param) {
    return (dispatch) => {
      let token = localStorage.getItem('jwtToken')
      return axios({
        method: 'post',
        data: { FilterValue: param },
        url: getSpendKPIBenchmarkAPI,
        headers: {
          'Authorization': `AribaBearer ${token}`  
        }    
      })
    }
  }
  
  export function getKPISetUpLoadData(DMCId, VSId, userEmail) {
    return (dispatch) => {
      dispatch(getDetailedFileLoading(true))
      let token = localStorage.getItem('jwtToken')
      return axios({
        method: 'post',
        data: { DMC_ID: DMCId, VS_ID: VSId, USER_EMAIL: userEmail },
        url: getKPISetUpDataAPI,
        headers: {
          'Authorization': `AribaBearer ${token}`
        }
      }).then((response) => {
        dispatch(getDetailedFileSuccess(false))
        return response
      }).catch((error) => {
        return error
      })
    }
  }
  
  export function updateKPISetUpTabData(records) {
    return (dispatch) => {
      dispatch(getDetailedFileFileRecordsInsertedLoading(true))
      let token = localStorage.getItem('jwtToken')
      return axios({
        method: 'post',
        data: records,
        url: updateKPISetUpDataAPI,
        headers: {
          'Authorization': `AribaBearer ${token}`
        }
      }).then((response) => {
        dispatch(getDetailedFileFileRecordsInsertedLoading(false))
        dispatch(getDetailedFileRecordsInsertedSuccess(true))
        return response
      }).catch((error) => {
        dispatch(getDetailedFileFileRecordsInsertedFailure(error))
        return error
      })
    }
  }
  
  export function getKPIDashBoardLoadData(DMCId, VSId, userEmail, metric, type) {
    return (dispatch) => {
      let token = localStorage.getItem('jwtToken')
      return axios({
        method: 'post',
        data: { DMC_ID: DMCId, VS_ID: VSId, USER_EMAIL: userEmail, BENCHMARK_METRICS: metric, BENCHMARK_TYPE: type },
        url: getKPIDashBoardDataAPI,
        headers: {
          'Authorization': `AribaBearer ${token}`
        }
      }).then((response) => {
        return response
      }).catch((error) => {
        return error
      })
    }
  }

  export function getKPIDashBoardLoadDataV2(DMCId, VSId, userEmail) {
    return (dispatch) => {
      let token = localStorage.getItem('jwtToken')
      return axios({
        method: 'post',
        data: { DMC_ID: DMCId, VS_ID: VSId, USER_EMAIL: userEmail },
        url: getKPIDashBoardDataAPIV2,
        headers: {
          'Authorization': `AribaBearer ${token}`
        }
      }).then((response) => {
        return response
      }).catch((error) => {
        return error
      })
    }
  }

  export function getKPIDashBoardLoadFinalDataV2(DMCId, VSId, userEmail) {
    return (dispatch) => {
      let token = localStorage.getItem('jwtToken')
      return axios({
        method: 'post',
        data: { DMC_ID: DMCId, VS_ID: VSId, USER_EMAIL: userEmail },
        url: getKPIDashBoardFinalDataAPIV2,
        headers: {
          'Authorization': `AribaBearer ${token}`
        }
      }).then((response) => {
        return response
      }).catch((error) => {
        return error
      })
    }
  }  

  export function getManualKPIDashBoardLoadData(DMCId, VSId, userEmail, kpi_strategy_model, model) {
    return (dispatch) => {
      let token = localStorage.getItem('jwtToken')
      return axios({
        method: 'post',
        data: { DMC_ID: DMCId, VS_ID: VSId, USER_EMAIL: userEmail,KPI_STRATEGY_MODEL:kpi_strategy_model,MODEL:model },
        url: getManualKPIDashBoardDataAPI,
        headers: {
          'Authorization': `AribaBearer ${token}`
        }
      }).then((response) => {
        return response
      }).catch((error) => {
        return error
      })
    }
  }  

  export function GetManualKPIDashBoardLoadDataPercentCalc(DMCId, VSId, userEmail) {
    return (dispatch) => {
      let token = localStorage.getItem('jwtToken')
      return axios({
        method: 'post',
        data: { DMC_ID: DMCId, VS_ID: VSId, USER_EMAIL: userEmail},
        url: GetManualKPIDashBoardDataPercentCalcAPI,
        headers: {
          'Authorization': `AribaBearer ${token}`
        }
      }).then((response) => {
        return response
      }).catch((error) => {
        return error
      })
    }
  } 

  export function getManualKPIResourceTypeLoadData(DMCId, VSId, userEmail,model) {
    return (dispatch) => {
      let token = localStorage.getItem('jwtToken')
      return axios({
        method: 'post',
        data: { DMC_ID: DMCId, VS_ID: VSId, USER_EMAIL: userEmail,MODEL:model },
        url: getManualKPIResourceType,
        headers: {
          'Authorization': `AribaBearer ${token}`
        }
      }).then((response) => {
        return response
      }).catch((error) => {
        return error
      })
    }
  }  
  

  export function getUpdateEnablementKPIModel(DMCId, VSId, userEmail, ReadWriteFlag, EnablementKPIModel) {
    return (dispatch) => {
      let token = localStorage.getItem('jwtToken')
      return axios({
        method: 'post',
        data: { DMC_ID: DMCId, VS_ID: VSId, USER_EMAIL: userEmail, READ_WRITE_FLAG: ReadWriteFlag, KPI_MODEL: EnablementKPIModel },
        url: getUpdateEnablementKPIModelAPI,
        headers: {
          'Authorization': `AribaBearer ${token}`
        }
      }).then((response) => {
        return response
      }).catch((error) => {
        return error
      })
    }
  }

  export function getUpdateKpiGoliveProjectStartDate(DMCId, VSId, userEmail, ReadWriteFlag, goLiveDate, projectStartDate) {
    return (dispatch) => {
      let token = localStorage.getItem('jwtToken')
      return axios({
        method: 'post',
        data: { DMC_ID: DMCId, VS_ID: VSId, USER_EMAIL: userEmail, READ_WRITE_FLAG: ReadWriteFlag, GO_LIVE_DATE: goLiveDate, PROJECT_START_DATE: projectStartDate },
        url: getUpdateKpiGoliveProjectStartDateAPI,
        headers: {
          'Authorization': `AribaBearer ${token}`
        }
      }).then((response) => {
        return response
      }).catch((error) => {
        return error
      })
    }
  }  

  // export function getManualKPIManualTargetFetch(DMCId, VSId, userEmail, model, kpiModel) {
  //   return (dispatch) => {
  //     let token = localStorage.getItem('jwtToken')
  //     return axios({
  //       method: 'post',
  //       data: { DMC_ID: DMCId, VS_ID: VSId, USER_EMAIL: userEmail, MODEL: model, KPI_MODEL: kpiModel },
  //       url: GetManualKPIManualTargetFetchAPI,
  //       headers: {
  //         'Authorization': `AribaBearer ${token}`
  //       }
  //     }).then((response) => {
  //       return response
  //     }).catch((error) => {
  //       return error
  //     })
  //   }
  //}  
  
  export function updateKPIDashBoardTabData(records) {
    return (dispatch) => {
      let token = localStorage.getItem('jwtToken')
      return axios({
        method: 'post',
        data: records,
        url: updateKPIDashBoardDataAPI,
        headers: {
          'Authorization': `AribaBearer ${token}`
        }
      }).then((response) => {
        return response
      }).catch((error) => {
        return error
      })
    }
  }

  export function updateKPIDashBoardTabDataV2(records) {
    return (dispatch) => {
      let token = localStorage.getItem('jwtToken')
      return axios({
        method: 'post',
        data: records,
        url: updateKPIDashBoardDataAPIV2,
        headers: {
          'Authorization': `AribaBearer ${token}`
        }
      }).then((response) => {
        return response
      }).catch((error) => {
        return error
      })
    }
  }

  export function updateEnablementKPIDashBoardTabData(records) {
    return (dispatch) => {
      let token = localStorage.getItem('jwtToken')
      return axios({
        method: 'post',
        data: records,
        url: updateEnablementKPIDashBoard,
        headers: {
          'Authorization': `AribaBearer ${token}`
        }
      }).then((response) => {
        return response
      }).catch((error) => {
        return error
      })
    }
  }

  export function saveIntermediateEnablementKPIDashBoardTabData(records) {
    return (dispatch) => {
      let token = localStorage.getItem('jwtToken')
      return axios({
        method: 'post',
        data: records,
        url: saveIntermediateEnablementKPIDashBoardAPI,
        headers: {
          'Authorization': `AribaBearer ${token}`
        }
      }).then((response) => {
        return response
      }).catch((error) => {
        return error
      })
    }
  }  

  export function deleteIntermediateEnablementKPIDashBoardTabData(DMCId, VSId, userEmail) {
    return (dispatch) => {
      let token = localStorage.getItem('jwtToken')
      return axios({
        method: 'post',
        data: { DMC_ID: DMCId, VS_ID: VSId, USER_EMAIL: userEmail},
        url: deleteIntermediateEnablementKPIDashBoardAPI,
        headers: {
          'Authorization': `AribaBearer ${token}`
        }
      }).then((response) => {
        return response
      }).catch((error) => {
        return error
      })
    }
  } 

  export function updateEnablementStrategyWaveData(DMCId, VSId, userEmail) {
    return (dispatch) => {
      let token = localStorage.getItem('jwtToken')
      return axios({
        method: 'post',
        data: { DMC_ID: DMCId, VS_ID: VSId, USER_EMAIL: userEmail},
        url: updateEnablementStrategyWave,
        headers: {
          'Authorization': `AribaBearer ${token}`
        }
      }).then((response) => {
        return response
      }).catch((error) => {
        return error
      })
    }
  }

  
  
  export function getKPIChartData(DMCId, VSId, userEmail, metric, type, metricType) {
    return (dispatch) => {
      let token = localStorage.getItem('jwtToken')
      return axios({
        method: 'post',
        data: { DMC_ID: DMCId, VS_ID: VSId, USER_EMAIL: userEmail, BENCHMARK_METRICS: metric, BENCHMARK_TYPE: type, METRIC_TYPE: metricType },
        url: getKPIChartDataAPI,
        headers: {
          'Authorization': `AribaBearer ${token}`
        }
      }).then((response) => {
        return response
      }).catch((error) => {
        return error
      })
    }
  }
  

  export function getKPIChartDataV2(DMCId, VSId, userEmail, metricType) {
    return (dispatch) => {
      let token = localStorage.getItem('jwtToken')
      return axios({
        method: 'post',
        data: { DMC_ID: DMCId, VS_ID: VSId, USER_EMAIL: userEmail, METRIC_TYPE: metricType },
        url: getKPIChartDataAPIV2,
        headers: {
          'Authorization': `AribaBearer ${token}`
        }
      }).then((response) => {
        return response
      }).catch((error) => {
        return error
      })
    }
  }