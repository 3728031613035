import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Control, Form, Errors, actions } from 'react-redux-form'
import classNames from "classnames";
import Select from 'react-select'
import { path } from 'Constants'
import moment from 'moment'

import {
	fetchSpendCollectionColumnDetails,
	uploadSpendCollectionData,
	getCollectCustomerSpendDetails,
	saveCollectCustomerSpend,
	getSpendFilesData,
	getSpendQualityAssessmentData
} from 'actions/spendActions'

import classnames from 'classnames'
// Utils
import { isDuplicate } from 'utils/Utils'
import DatePicker from 'react-datepicker';
//import "react-datepicker/dist/react-datepicker.css";

// Settings

import { validators, modalStyle, dropdownColourStyles } from 'Constants'
import { ControlTextE, ScreenE, SelectE } from 'UI/CustomComponents/Controls';
import dataCollectionTemplate from '../../spendDataCollection-Template/Data_Collection_Template.xlsx'

import * as FileSaver from "file-saver";
import * as XLSX from 'xlsx';
import { Workbook } from 'exceljs/dist/exceljs';

const autoHeightModalStyle = Object.assign({}, modalStyle)
autoHeightModalStyle.height = 'auto'
autoHeightModalStyle.width = '30%'

const defaultOptions = [
	{ value: 0, label: "" }];

export class CollectCustomerSpend extends PureComponent {

	constructor(props) {
		super(props)
		this.state = {
			valueStory: '',
			isLoading: false,
			customerPhaseValues: [
                { value: "SALES", label: "Sales" },
                { value: "POST_SALES", label: "Post Sale Spend Analysis and Enablement Planning(Flight Plan)" },
                { value: "WAVE_EXECUTION", label: "Wave Execution" }
            ],
			selectedCustomerPhaseValue: 'WAVE_EXECUTION',
			requestTypeValues:[
				{ value: "NETWORK_MATCH_CLASSIFICATION", label: "Network Match & Classification" },
                { value: "NETWORK_MATCH", label: "Network Match Only" },
                { value: "SUPPLIER_CLASSIFICATION", label: "Supplier Classification Only" }
				],
			selectedRequestType: 'NETWORK_MATCH_CLASSIFICATION',
			requestorGroupValues:[
				{ value: "ACOUNT_EXECUTIVE", label: "Account Executive" },
                { value: "CEE", label: "CEE" },
                { value: "FIELDGLASS", label: "Fieldglass" },
				{ value: "NETWORK_DEP_LEAD", label: "Network Deployment Lead" },
				{ value: "NETWORK_DIRECTOR", label: "Network Director" },
                { value: "NETWORK_ENABLE_LEAD", label: "Network Enablement Lead" },
                { value: "PAYABLES", label: "Payables" },
				{ value: "SNAP", label: "SNAP" },
                { value: "SOLUTION_CONSULTANT", label: "Solution Consultant" },
                { value: "SPOT_BUY", label: "Spot Buy" },
				{ value: "VALUE_ADVISORY", label: "Value Advisory" },
				{ value: "SCC", label: "SCC" },
			],
			selectedRequestorGroup: 'FIELDGLASS',
			urgencyValues:[
				{ value: "LOW", label: "Low" },
                { value: "NORMAL", label: "Normal" },
                { value: "URGENT", label: "Urgent" },
			],
			selectedUrgency: 'NORMAL',
			outputFormatValues:[
				{ value: "RUNDMC_OUTPUT", label: "Run DMC Spend Analysis Output" },
                { value: "SUPPLIERENABLEMENT_OUTPUT", label: "Supplier Enablement Output" },
                { value: "BOTH", label: "Both Run DMC Spend Analysis Output & Supplier Enablement Output" },
			],
			selectedOutputFormat: 'RUNDMC_OUTPUT',
			excelUploadColumns: [],
			customerSpend:{
				VS_ID: this.props.userData.VSId,
				REQUESTOR_NAME: this.props.userData.ValueStoryCreator,
				OUTPUT_FORMAT: 'RUNDMC_OUTPUT',
				CUSTOMER_EMAIL: this.props.user.user.Email,
				REQUESTOR_GROUP: 'FIELDGLASS',
				CUSTOMER_PROSPECT_PHASE: 'WAVE_EXECUTION',
				NEED_BY_DATE: new Date(),
				REQUEST_TYPE: 'NETWORK_MATCH_CLASSIFICATION',
				URGENCY: 'NORMAL',
				USER_ID : this.props.user.user.Email
			},
			isDataSave: false,
			errorMessagesArr: [],
			warningMessageArr: [],
			spendFilesData: [],
			duplicateWarning: '',
			qualityAssessmentData: [],
			needByDate: new Date(),
			originalUserData: Object.assign({}, this.props.userData),
			fileName: ''
    	}
		
	this.onRequestorChangeEvent = this.onRequestorChangeEvent.bind(this);
	this.onCustomerEmailChangeEvent = this.onCustomerEmailChangeEvent.bind(this);
	this.handleDateChange = this.handleDateChange.bind(this);
	this.compareKeyValuesInObject = this.compareKeyValuesInObject.bind(this)
	}

	componentDidMount(){		
		this.getCollectCustomerSpendDetails()
		this.fetchSpendCollectionColumnDetails()
	}

	componentWillReceiveProps(){
		var customerSpend_obj = this.state.customerSpend
		customerSpend_obj.REQUESTOR_NAME = this.props.userData.ValueStoryCreator	
		this.setState({ customerSpend: customerSpend_obj})
	}

	getCollectCustomerSpendDetails() {
		if(this.props.userData.VSId !==undefined && this.props.userData.VSId !== null)
		{
		var param = {
			VS_ID: this.props.userData.VSId,
			USER_ID: this.props.user.user.Email
		}
		this.props.getCollectCustomerSpendDetails(param)
		  .then((response) => {
			if (response.data.Result.length > 0) {
			var objState = this.state.customerSpend
			
			// var date = new Date(response.data.Result[0].NEED_BY_DATE);    
	        // objState.NEED_BY_DATE =((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear();
	        objState.REQUESTOR_NAME = response.data.Result[0].REQUESTOR_NAME
			objState.OUTPUT_FORMAT = response.data.Result[0].OUTPUT_FORMAT
			objState.CUSTOMER_EMAIL = response.data.Result[0].CUSTOMER_EMAIL
			objState.REQUESTOR_GROUP = response.data.Result[0].REQUESTOR_GROUP
			objState.CUSTOMER_PROSPECT_PHASE = response.data.Result[0].CUSTOMER_PROSPECT_PHASE
			objState.REQUEST_TYPE = response.data.Result[0].REQUEST_TYPE
			objState.URGENCY = response.data.Result[0].URGENCY

	        this.setState({ savedData:response.data.Result, customerSpend: objState, selectedCustomerPhaseValue: response.data.Result[0].CUSTOMER_PROSPECT_PHASE,
			selectedOutputFormat: response.data.Result[0].OUTPUT_FORMAT, selectedRequestType: response.data.Result[0].REQUEST_TYPE, 
			selectedRequestorGroup: response.data.Result[0].REQUESTOR_GROUP, selectedUrgency: response.data.Result[0].URGENCY,
		    needByDate: response.data.Result[0].NEED_BY_DATE, isDataSave: true
	    	})
			} else {
			  //this.setState({ responseMsg: 'No result found.', isError: 'Yes', isLoading: false })
			}
	
		  }).catch((error) => {
			if (error.stack.includes('Network Error')) {
			  this.setState({ responseMsg: 'API service is down. Please contact administrator.', isError: 'Yes', isLoading: false })
			}
			else {
			  this.setState({ responseMsg: this.props.content.error, isError: 'Yes', isLoading: false })
			}
		  })
		}
	  }

	fetchSpendCollectionColumnDetails() {
		this.props.fetchSpendCollectionColumnDetails()
		  .then((response) => {
			if (response.data.Result.length > 0) {
			  this.setState({ excelUploadColumns: response.data.Result })
			} else {
			  this.setState({ responseMsg: 'No result found.', isError: 'Yes', isLoading: false })
			}
	
		  }).catch((error) => {
			if (error.stack.includes('Network Error')) {
			  this.setState({ responseMsg: 'API service is down. Please contact administrator.', isError: 'Yes', isLoading: false })
			}
			else {
			  this.setState({ responseMsg: this.props.content.error, isError: 'Yes', isLoading: false })
			}
		  })
	  }

	handleOuputFormatChange(data)
	{
	  var customerSpend_obj = this.state.customerSpend
	  customerSpend_obj.OUTPUT_FORMAT = data.value	
      this.setState({ selectedOutputFormat: data.value, customerSpend: customerSpend_obj})
	}

	handleUrgencyChange(data){
	  var customerSpend_obj = this.state.customerSpend
	  customerSpend_obj.URGENCY = data.value	
      this.setState({selectedUrgency:data.value, customerSpend: customerSpend_obj})
	}

	handleRequestTypeChange(data){
		var customerSpend_obj = this.state.customerSpend
	    customerSpend_obj.REQUEST_TYPE = data.value	
		this.setState({selectedRequestType : data.value, customerSpend: customerSpend_obj })
	}

	handleRequestorGroupChange(data){
		var customerSpend_obj = this.state.customerSpend
	    customerSpend_obj.REQUESTOR_GROUP = data.value	
		var fileName= ""
		switch(data.value){
			case 'NETWORK_DEP_LEAD':
                fileName = "Executive_Template.xlsx"  
				break;
			case 'ACOUNT_EXECUTIVE':
				fileName = "Sales_Template.xlsx"  
				break;		
			case '':
				break;	
				default:
					fileName = "Executive_Template.xlsx"  	
		}

		this.setState({selectedRequestorGroup : data.value, customerSpend: customerSpend_obj, templateFileName: fileName})
	}

	handleCustomerPhaseChange(data){
		var customerSpend_obj = this.state.customerSpend
	    customerSpend_obj.CUSTOMER_PROSPECT_PHASE = data.value	
		this.setState({selectedCustomerPhaseValue : data.value, customerSpend: customerSpend_obj})
	}

	handleDateChange(date)
	{
		var customerSpend_obj = this.state.customerSpend
	    customerSpend_obj.NEED_BY_DATE = date	
		this.setState({ customerSpend: customerSpend_obj, needByDate: date})
	}

	onExpandClick(e){
		switch(e.target.id){
		  case "loadReq":
			this.setState({ isExpand: ! this.state.isExpand})
			break;
		 
		}
	}

	  handleChange(e){
		const files = e.target.files;
			if (files && files[0]) this.setState({ file: files[0], fileName: files[0].name, responseMsg: '', isError: 'No', isLoading: false, isSaveInProgress: false }, this.handleFile);        
	  }
	
	  handleFile() {
		this.setState({ responseMsg: "",  isLoading: true, isSaveInProgress: true})
		const reader = new FileReader();
		const rABS = !!reader.readAsBinaryString;
	
		reader.onload = (e) => {
			/* Parse data */
			const bstr = e.target.result;
			const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA: true });

			/* Get first worksheet */
			const wsname = wb.SheetNames[2];
			const ws = wb.Sheets[wsname];
			var excelCellAddress = ws['!ref'].split(':')
			var startCellAddress = "A3"
			var endCellAddress = excelCellAddress[1]
			/* Convert array of arrays */
			ws['!ref'] = startCellAddress + ":" + endCellAddress

			//console.log("excel cell address: ", ws['!ref'])
			const data = XLSX.utils.sheet_to_json(ws, { defval: "" });       
			this.setState({excelData: data})       
			
			//let excelData = data.filter(d=>d["Update Flag"]) 
			let excelData = data
			//console.log("excel data: ", data)
			
			//validate invalid copy/paste data
			// excelData.map((item)=>{
			// //Validation Code goes here
			// }
			//)  
	
			if(excelData.length == 0){
			 this.setState({ responseMsg: this.props.content.invalidFileSelect, isError: 'Yes', isLoading: false, isSaveInProgress: false })
			 return; 
			}else{
				this.handleSubmit()
			}
		};
	 
		if (rABS) {
			reader.readAsBinaryString(this.state.file);
		} else {
			reader.readAsArrayBuffer(this.state.file);
		};
	
		//e.target.files = this.state.invalid && null	
	  }  

	  handleSubmit = (e) => {
		//let rowData = this.state.excelData.filter(d=>d["Update Flag"])		
		let rowData = this.state.excelData
	     
		if(rowData.length == 0){
		this.setState({ responseMsg: this.props.content.noRecordForUpdate, isError: 'Yes', isLoading: false, isSaveInProgress: false })
		return; 
		}
		
		// this.setState({isLoading: true, isSaveInProgress: true})
		const columnDetails = this.state.excelUploadColumns;
		
		const recordsToInsert = [];
		   rowData.forEach((item) => {
				//  var excelDate = item[columnDetails.filter(d=>d.field === "USER_DATE_FIELD")[0].UI_FIELD_NAME]; 
				//  var converted_date;
				 
				//  if(isNaN(excelDate) || excelDate === ""){
				//    converted_date = excelDate              
				//  }else{
				//  var date = new Date(Math.round((item[columnDetails.filter(d=>d.field === "USER_DATE_FIELD")[0].UI_FIELD_NAME] - (25567 + 1)) * 86400 * 1000));            
				//  var year = date.getFullYear();
				//  var month = (1 + date.getMonth()).toString();
				//  month = month.length > 1 ? month : '0' + month;
	 
				//  var day = date.getDate().toString();
				//  day = day.length > 1 ? day : '0' + day;
				//  converted_date = month + '/' + day + '/' + year;
				//  }       
			   
			   const record = {
				   VS_ID: this.props.userData.VSId, 
				   DMC_ID: this.props.spendDetails.currentActiveRequest.DMC_ID,               
				   USER_ID: this.props.user.user.Email,
	 
				   TEMPLATE_NAME: "SPEND_DATA_COLLECTION_TEMPLATE",				  
				   VENDOR_ID: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "VENDOR_ID")[0].UI_FIELD_NAME],
				   VENDOR_NAME: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "VENDOR_NAME")[0].UI_FIELD_NAME],
				   ERP_SYSTEM: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "ERP_SYSTEM")[0].UI_FIELD_NAME],
				   ERP_INVOICE_SPEND: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "ERP_INVOICE_SPEND")[0].UI_FIELD_NAME],
				   ERP_INVOICE_SPEND_CURRENCY: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "ERP_INVOICE_SPEND_CURRENCY")[0].UI_FIELD_NAME],
				   EXCHANGE_RATE: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "EXCHANGE_RATE")[0].UI_FIELD_NAME],
				   ERP_INVOICE_SPEND_IN_STANDARD_CURRENCY: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "ERP_INVOICE_SPEND_IN_STANDARD_CURRENCY")[0].UI_FIELD_NAME],
				   STANDARD_ERP_INVOICE_SPEND_CURRENCY: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "STANDARD_ERP_INVOICE_SPEND_CURRENCY")[0].UI_FIELD_NAME],
				   ERP_INVOICE_COUNT: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "ERP_INVOICE_COUNT")[0].UI_FIELD_NAME],
				   INVOICE_METHOD: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "INVOICE_METHOD")[0].UI_FIELD_NAME],
				   INVOICE_ORIGINATION_COUNTRY: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "INVOICE_ORIGINATION_COUNTRY")[0].UI_FIELD_NAME],
				   ERP_PO_SPEND: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "ERP_PO_SPEND")[0].UI_FIELD_NAME],
				   ERP_PO_SPEND_CURRENCY: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "ERP_PO_SPEND_CURRENCY")[0].UI_FIELD_NAME],
				   ERP_PO_COUNT: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "ERP_PO_COUNT")[0].UI_FIELD_NAME],
				   BUYER_REGION: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "BUYER_REGION")[0].UI_FIELD_NAME],
				   BUSINESS_UNIT: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "BUSINESS_UNIT")[0].UI_FIELD_NAME],
				   ERP_COMMODITY_CODE_OR_DESCRIPTION_TIER_1: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "ERP_COMMODITY_CODE_OR_DESCRIPTION_TIER_1")[0].UI_FIELD_NAME],
				   ERP_COMMODITY_CODE_OR_DESCRIPTION_TIER_2: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "ERP_COMMODITY_CODE_OR_DESCRIPTION_TIER_2")[0].UI_FIELD_NAME],

				   VENDOR_TAX_ID: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "VENDOR_TAX_ID")[0].UI_FIELD_NAME],
				   VENDOR_VAT_ID: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "VENDOR_VAT_ID")[0].UI_FIELD_NAME],
				   VENDOR_DUNS: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "VENDOR_DUNS")[0].UI_FIELD_NAME],
				   VENDOR_AN_ID: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "VENDOR_AN_ID")[0].UI_FIELD_NAME],
				   VENDOR_ADDRESS_LINE_1: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "VENDOR_ADDRESS_LINE_1")[0].UI_FIELD_NAME],
				   VENDOR_ADDRESS_LINE_2: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "VENDOR_ADDRESS_LINE_2")[0].UI_FIELD_NAME],
				   VENDOR_CITY: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "VENDOR_CITY")[0].UI_FIELD_NAME],
				   VENDOR_STATE_PROVINCE: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "VENDOR_STATE_PROVINCE")[0].UI_FIELD_NAME],
				   VENDOR_POSTAL_CODE: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "VENDOR_POSTAL_CODE")[0].UI_FIELD_NAME],
				   VENDOR_COUNTRY_CODE: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "VENDOR_COUNTRY_CODE")[0].UI_FIELD_NAME],
				   FISCAL_YEAR: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "FISCAL_YEAR")[0].UI_FIELD_NAME],
				   SITE_ID: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "SITE_ID")[0].UI_FIELD_NAME],
				   SITE_AUXILIARY_ID: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "SITE_AUXILIARY_ID")[0].UI_FIELD_NAME],
				   VENDOR_REMIT_NAME: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "VENDOR_REMIT_NAME")[0].UI_FIELD_NAME],
				   VENDOR_REMIT_ADDRESS_LINE_1: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "VENDOR_REMIT_ADDRESS_LINE_1")[0].UI_FIELD_NAME],
				   VENDOR_REMIT_ADDRESS_LINE_2: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "VENDOR_REMIT_ADDRESS_LINE_2")[0].UI_FIELD_NAME],
				   VENDOR_REMIT_ADDRESS_LINE_3: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "VENDOR_REMIT_ADDRESS_LINE_3")[0].UI_FIELD_NAME],
				   VENDOR_REMIT_CITY: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "VENDOR_REMIT_CITY")[0].UI_FIELD_NAME],
				   VENDOR_REMIT_PROVINCE_STATE_REGION: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "VENDOR_REMIT_PROVINCE_STATE_REGION")[0].UI_FIELD_NAME],
				   VENDOR_REMIT_POSTAL_CODE: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "VENDOR_REMIT_POSTAL_CODE")[0].UI_FIELD_NAME],
				   REMITTANCE_ID: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "REMITTANCE_ID")[0].UI_FIELD_NAME],
				   SYSTEM_ID: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "SYSTEM_ID")[0].UI_FIELD_NAME],
				   WAVE_WAVE: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "WAVE_WAVE")[0].UI_FIELD_NAME],
				   RELATIONSHIP_REQUEST_EMAIL_ADDRESS: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "RELATIONSHIP_REQUEST_EMAIL_ADDRESS")[0].UI_FIELD_NAME],
				   VENDOR_EMAIL_ADDRESS: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "VENDOR_EMAIL_ADDRESS")[0].UI_FIELD_NAME],
				   VENDOR_PHONE_COUNTRY_CODE: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "VENDOR_PHONE_COUNTRY_CODE")[0].UI_FIELD_NAME],
				   VENDOR_PHONE_AREA_CODE: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "VENDOR_PHONE_AREA_CODE")[0].UI_FIELD_NAME],
				   VENDOR_PHONE_NUMBER: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "VENDOR_PHONE_NUMBER")[0].UI_FIELD_NAME],
				   VENDOR_PHONE: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "VENDOR_PHONE")[0].UI_FIELD_NAME],

				   CONTACT_FIRST_NAME: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "CONTACT_FIRST_NAME")[0].UI_FIELD_NAME],
				   CONTACT_LAST_NAME: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "CONTACT_LAST_NAME")[0].UI_FIELD_NAME],
				   CONTACT_PHONE_COUNTRY_CODE: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "CONTACT_PHONE_COUNTRY_CODE")[0].UI_FIELD_NAME],
				   CONTACT_PHONE_AREA_CODE: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "CONTACT_PHONE_AREA_CODE")[0].UI_FIELD_NAME],
				   CONTACT_PHONE_NUMBER: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "CONTACT_PHONE_NUMBER")[0].UI_FIELD_NAME],
				   CONTACT_PHONE_EXTENSION: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "CONTACT_PHONE_EXTENSION")[0].UI_FIELD_NAME],
				   CONTACT_PHONE: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "CONTACT_PHONE")[0].UI_FIELD_NAME],
				   PREFERRED_LANGUAGE: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "PREFERRED_LANGUAGE")[0].UI_FIELD_NAME],
				   BUYING_ORGANIZATION_ANID: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "BUYING_ORGANIZATION_ANID")[0].UI_FIELD_NAME],
				   LEGACY_VENDOR_ID: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "LEGACY_VENDOR_ID")[0].UI_FIELD_NAME],
				   SPOT_QUOTE: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "SPOT_QUOTE")[0].UI_FIELD_NAME],
				   PROPOSED_ENABLEMENT_MODE: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "PROPOSED_ENABLEMENT_MODE")[0].UI_FIELD_NAME],
				   AN_SUPPLIER_GROUP: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "AN_SUPPLIER_GROUP")[0].UI_FIELD_NAME],
				   SEGMENT_SEGMENT: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "SEGMENT_SEGMENT")[0].UI_FIELD_NAME],
				   QUOTE_GROUP: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "QUOTE_GROUP")[0].UI_FIELD_NAME],


				   USER_DEFINED_TEXT_FIELD_1: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "USER_DEFINED_TEXT_FIELD_1")[0].UI_FIELD_NAME],
				   USER_DEFINED_TEXT_FIELD_2: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "USER_DEFINED_TEXT_FIELD_2")[0].UI_FIELD_NAME],
				   USER_DEFINED_TEXT_FIELD_3: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "USER_DEFINED_TEXT_FIELD_3")[0].UI_FIELD_NAME],
				   USER_DEFINED_TEXT_FIELD_4: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "USER_DEFINED_TEXT_FIELD_4")[0].UI_FIELD_NAME],
				   USER_DEFINED_TEXT_FIELD_5: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "USER_DEFINED_TEXT_FIELD_5")[0].UI_FIELD_NAME],
				   USER_DEFINED_NUMERIC_FIELD_1: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "USER_DEFINED_NUMERIC_FIELD_1")[0].UI_FIELD_NAME],
				   USER_DEFINED_NUMERIC_FIELD_2: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "USER_DEFINED_NUMERIC_FIELD_2")[0].UI_FIELD_NAME],
				   USER_DEFINED_NUMERIC_FIELD_3: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "USER_DEFINED_NUMERIC_FIELD_3")[0].UI_FIELD_NAME],
				   USER_DEFINED_NUMERIC_FIELD_4: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "USER_DEFINED_NUMERIC_FIELD_4")[0].UI_FIELD_NAME],
				   USER_DEFINED_NUMERIC_FIELD_5: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "USER_DEFINED_NUMERIC_FIELD_5")[0].UI_FIELD_NAME],	 
				   USER_DEFINED_DATE_FIELD_1: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "USER_DEFINED_DATE_FIELD_1")[0].UI_FIELD_NAME],

				   DIRECT_SPEND: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "DIRECT_SPEND")[0].UI_FIELD_NAME],
				   EXCLUSION_REASON: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "EXCLUSION_REASON")[0].UI_FIELD_NAME],
				   ACT_CODE: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "ACT_CODE")[0].UI_FIELD_NAME],
				   UNSPSC_CODE: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "UNSPSC_CODE")[0].UI_FIELD_NAME],
				   CAPIQ_CODE: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "CAPIQ_CODE")[0].UI_FIELD_NAME],
				   SLA_FLAG: item[columnDetails.filter(d=>d.HANA_FIELD_NAME === "SLA_FLAG")[0].UI_FIELD_NAME],

				   
				  // USER_DATE_FIELD: converted_date
				   
			   };
			   recordsToInsert.push(record);        
		   })
		   this.setState({ recordInserted: recordsToInsert})
		   this.props.uploadSpendCollectionData(recordsToInsert)
			   .then((response) => {
				   this.setState({ responseMsg: 'Record/s has been uploaded successfully', isError: 'No', isLoading: false, isSaveInProgress: false })
				   //document.getElementById("excelFile").value = "";
				   this.getSpendQualityAssessmentData()	
			   }).catch((error) => {
				   if (error.stack.includes('Network Error')) {
					   //this.props.defineScopeLoading(false)
					   this.setState({ responseMsg: 'API service is down. Please contact administrator.', isError: 'Yes', isLoading: false, isSaveInProgress: false })
				   }
				   else {
					   //this.props.defineScopeLoading(false)
					  // this.setState({ responseMsg: this.props.content.error, isError: 'Yes', isLoading: false, isSaveInProgress: false })
				   }
			   })
		}

		onRequestorChangeEvent(event) {
			var customerSpend_obj = this.state.customerSpend
			customerSpend_obj.REQUESTOR_NAME = event.target.value	
			this.setState({  customerSpend: customerSpend_obj})						
		}

		onCustomerEmailChangeEvent(event) {
			this.setState({ customerEail: event.target.value });
			//this.props.userData.ValueStoryName = event.target.value;				
		}

		handleSave(){
			this.props.saveCollectCustomerSpend(this.state.customerSpend)
			.then((response) => {
				this.setState({ responseMsg: 'Record/s has been save successfully', isError: 'No', isLoading: false, isSaveInProgress: false })		
				this.setState({isDataSave: true})					
			}).catch((error) => {
				if (error.stack.includes('Network Error')) {
					//this.props.defineScopeLoading(false)
					this.setState({ responseMsg: 'API service is down. Please contact administrator.', isError: 'Yes', isLoading: false, isSaveInProgress: false })
				}
				else {
					//this.props.defineScopeLoading(false)
				   // this.setState({ responseMsg: this.props.content.error, isError: 'Yes', isLoading: false, isSaveInProgress: false })
				}
			})
			
		}

		getSpendQualityAssessmentData() {
			this.props.getSpendQualityAssessmentData()
			  .then((response) => {
				if (response.data.Result.length > 0) {
				  this.setState({ qualityAssessmentData: response.data.Result })
				  this.doQualityAssesment()
				} else {
				  this.setState({ responseMsg: 'No result found. Please try reimporting the file.', isError: 'Yes', isLoading: false })
				}
		
			  }).catch((error) => {
				if (error.stack.includes('Network Error')) {
				  this.setState({ responseMsg: 'API service is down. Please contact administrator.', isError: 'Yes', isLoading: false })
				}
				else {
				  this.setState({ responseMsg: this.props.content.error, isError: 'Yes', isLoading: false })
				}
			  })
		  }

		  doQualityAssesment() {
			 // Do quality assesment check on uploaded data.   
			 let uploadedExcelData = this.state.recordInserted //response.data.Result
			 //let spendFilesQualityFields = this.state.spendFilesQualityFields;
			 let errorMessagesArr = []
			 let warningMessageArr = []
			 let total = uploadedExcelData.length;
			 let mappedColumns = this.state.columnMap;
			 let qualityArr = this.state.qualityAssessmentData;
									 
			//quality assessment check 			       
			for (let i = 0; i < qualityArr.length; i++) {
			//let key = qualityArr[i]
			//let fieldName = mappedColumns.filter(item => item.ColumnField == key)
			let count = 0;
			uploadedExcelData.map(item => {
			  if (item[qualityArr[i].UI_HANA_FIELD_NAME] == 0 || item[qualityArr[i].UI_HANA_FIELD_NAME] == null || item[qualityArr[i].UI_HANA_FIELD_NAME] == '') {
				count++;
			  }
			})
			let percent = ((count / total) * 100).toFixed(2);
			if (percent >= qualityArr[i].WARNING_BOTTOM_RANGE && percent <= qualityArr[i].WARNING_TOP_RANGE) {
				  let msg = `Your spend file is missing ${percent}% of ${qualityArr[i].FIELD_NAME}. ${qualityArr[i].RECOMMENDATION_TEXT}`
			  //errorMessagesArr.push(msg)
			  if(qualityArr[i].WARNING_COLOR == 'Yellow') warningMessageArr.push(msg)
			  if (qualityArr[i].WARNING_COLOR == 'Red') {
				let errMsg = `Your spend file is missing ${percent}% of ${qualityArr[i].FIELD_NAME}. ${qualityArr[i].RECOMMENDATION_TEXT}`
				errorMessagesArr.push(errMsg)
			  }
			} 
			 }
			this.setState({ isLoading: false, uploadedExcelData: uploadedExcelData, errorMessagesArr: errorMessagesArr, warningMessageArr: warningMessageArr })
			
		  }

		compareKeyValuesInObject(originalUserData, userData) {
			if (originalUserData !== undefined && originalUserData !== null && userData !== undefined) {
				if (
				originalUserData.REQUESTOR_NAME == userData[0].REQUESTOR_NAME
				&& originalUserData.OUTPUT_FORMAT == userData[0].OUTPUT_FORMAT
				&& originalUserData.CUSTOMER_EMAIL == userData[0].CUSTOMER_EMAIL
				&& originalUserData.REQUESTOR_GROUP == userData[0].REQUESTOR_GROUP
				&& originalUserData.CUSTOMER_PROSPECT_PHASE == userData[0].CUSTOMER_PROSPECT_PHASE
				&& originalUserData.REQUEST_TYPE == userData[0].REQUEST_TYPE
				&& originalUserData.URGENCY == userData[0].URGENCY
				//&& originalUserData.NEED_BY_DATE == new Date(userData[0].NEED_BY_DATE)
				) {
					return true;
				}
				else {
						return false;
				}
			}
			else {
				return false;
			}
		}	  

	render() {
		return (
			<ScreenE readOnly={!this.props.userData.AllowEdit} lastUpdated={new Date().getTime()}>
				<div className="grid-x medium-12">	
				    <div className="grid-x medium-12">
                                {(this.state.isSaveInProgress) ?
                                    <img style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
                                        src={path + `images/ajax-loader.gif`} alt="" />
                                    : null
                                }
                     </div>	
					 <div className="grid-x medium-12" style={{ display: 'flex', justifyContent: 'center' }} >                         
                              <div style={{ margin: '0 0 0.5rem', color: '#4fb81c' }}>{this.state.responseMsg}</div>                          
                      </div>		
					
                    <div className="grid-x medium-12 pl30 pr30">
						{/* <Form className="grid-x medium-12" model="collectCustomerSpend" method="post"
							getDispatch={(dispatch) => this.formDispatch = dispatch}> */}
							<div className="grid-x medium-12" >
							<div className="grid-x medium-12">
								<div className="medium-4 pr15" >
									<div className="label" >{"Requestor Name"} <span className="asterisk">*</span></div>
									<div className="control-wrapper" style={{width:'80%'}}>
										<ControlTextE
											model="collectCustomerSpend.RequestorName"
											id="collectCustomerSpend.RequestorName"
											value={this.state.customerSpend.REQUESTOR_NAME}
											//disabled = {true}
											//defaultValue={this.state.valueStory}
											onChange={this.onRequestorChangeEvent}
											// validators={{
											// 	required: validators.required
											// 	, duplicate: (vals) => isDuplicate(this.state.userValueStoryData, vals, this.props.userData.VSId)
											// }}
											validateOn="change"
										/>
										{/* <Errors
											className="errors"
											model="discovery.RequestorName"
											show="touched"
											messages={{
												required: this.props.validation.required
												, duplicate: this.props.validation.duplicateValueStoryName
											}}
										/> */}
									</div>
								</div>

								<div className="medium-4 pr15">
									<div className="label" >{"Output Format"} <span className="asterisk">*</span></div>
									<div className="control-wrapper" style={{width:'80%'}}>
									<SelectE
                                        className="border-less-DD"
                                        autocomplete='true'
                                        //placeholder={this.props.content.notConfirmed}
                                        value={this.state.outputFormatValues.filter(
                                            option => option.value === this.state.selectedOutputFormat
                                        )}
                                        onChange={value => this.handleOuputFormatChange(value)}
                                        options={this.state.outputFormatValues}
                                        isSearchable={false} 
										styles={dropdownColourStyles}
                                        isDisabled={this.state.isLoading}
                                    />
									</div>
								</div>

                                <div className="medium-4">
									<div className="label">{"Customer Email"} <span className="asterisk">*</span></div>
									<div className="control-wrapper" style={{width:'80%'}}>
										<ControlTextE
											model="collectCustomerSpend.CustomerEmail"
											id="collectCustomerSpend.CustomerEmail"
											value={this.state.customerSpend.CUSTOMER_EMAIL}
											// defaultValue={this.props.userData.Organization}
											onChange={this.onCustomerEmailChangeEvent}
											validators={{
												required: validators.required
											}}
											validateOn="change"
											//disabled={true}
										/>
										{/* <Errors
											className="errors"
											model="discovery.CustomerEmail"
											show="touched"
											messages={{
												required: this.props.validation.required
											}}
										/> */}
									</div>
								</div>

							</div>
                            {/* first row */}

                            <div className="grid-x medium-12">
								<div className="medium-4 pr15">
									<div className="label">{"Requestor Group"} </div>
									<div className="control-wrapper" style={{width:'80%'}}>
									<SelectE
                                        className="border-less-DD"
                                        autocomplete='true'
                                        value={this.state.requestorGroupValues.filter(
                                            option => option.value === this.state.selectedRequestorGroup
                                        )}
                                        onChange={value => this.handleRequestorGroupChange(value)}
                                        options={this.state.requestorGroupValues}
                                        isSearchable={false} 
										styles={dropdownColourStyles}
                                        isDisabled={this.state.isLoading}
                                    />
										
									</div>
								</div>

                                <div className="medium-4 pr15">
								<div className="label">{"Customer/Prospect Phase"} </div>
									<div className="control-wrapper" style={{width:'80%'}}>
							        <SelectE
                                        className="border-less-DD"
                                        autocomplete='true'
                                        value={this.state.customerPhaseValues.filter(
                                            option => option.value === this.state.selectedCustomerPhaseValue
                                        )}
                                        onChange={value => this.handleCustomerPhaseChange(value)}
                                        options={this.state.customerPhaseValues}
                                        isSearchable={false} 
										styles={dropdownColourStyles}
                                        isDisabled={this.state.isLoading}
                                    />
									</div>
								</div>

                                <div className="medium-4">
									<div className="label">{"Need by date"} </div>
									<div className="control-wrapper" style={{width:'80%'}} >
										<DatePicker selected={this.state.needByDate !== null ? new Date(this.state.needByDate) : null} 
										onChange={(date) => this.handleDateChange(date)}
										dateFormat="MM/dd/yyyy"
										style={{width:'100%'}} />										
									</div>
								</div>
                                
							</div>

							<div className="grid-x medium-12">
								<div className="medium-4 pr15">
									<div className="label">{"Request Type"} </div>
									<div className="control-wrapper" style={{width:'80%'}}>
									<SelectE
                                        className="border-less-DD"
                                        autocomplete='true'
                                        //placeholder={this.props.content.notConfirmed}
                                        value={this.state.requestTypeValues.filter(
                                            option => option.value === this.state.selectedRequestType
                                        )}
                                        onChange={value => this.handleRequestTypeChange(value)}
                                        options={this.state.requestTypeValues}
                                        isSearchable={false} 
										styles={dropdownColourStyles}
                                        isDisabled={this.state.isLoading}
                                    />
										
									</div>
								</div>

                                <div className="medium-4 pr15">
									<div className="label">{"Urgency"} </div>
									<div className="control-wrapper capIq-popup" style={{width:'80%'}}>
									<SelectE
                                        className="border-less-DD"
                                        autocomplete='true'
                                        //placeholder={this.props.content.notConfirmed}
                                        value={this.state.urgencyValues.filter(
                                            option => option.value === this.state.selectedUrgency
                                        )}
										noOptionsMessage={() => null}
                                        onChange={value => this.handleUrgencyChange(value)}
                                        options={this.state.urgencyValues}
                                        isSearchable={false} 
										styles={dropdownColourStyles}
                                        isDisabled={this.state.isLoading}
                                    />										
									</div>
								</div>

                                <div className="medium-4">
								<div className="label">{""} </div>
								 {/* <div className="control-wrapper">
									    <ControlTextE
											model="discovery.BPID"
											id="discovery.BPID"
											value={""}
											disabled = {false} 									
										/>										
									</div> */}
								</div>
                                
							</div>
							</div>

							<div className="grid-x medium-12">
								<div className="medium-12" style={{paddingRight:'70px'}}>
								<div className="float-right">
								<button className="button button--accent"
								    disabled={this.props.userData.VSId !== undefined ? false : true	}
									onClick={(e) =>  this.handleSave() }															
									>
									<span className="button__inner">{"Save"}</span>
								</button>
								</div>
								</div>							    
							</div>
							{/* "../../spendDataCollection-Template/" + this.state.templateFileName   */}
							<div className="grid-x medium-12">
								<div className="medium-4 pr15">		
								<div className="label">{"Data Collection Template"} </div>
									<div className="grid-x medium-12" style={{ padding:'40px'}}>
									{this.state.isDataSave ?   
									<a href={dataCollectionTemplate} download="Data_Collection_Template"><img width={"100px"} height={"120px"} src="images/excelIcon.PNG" /></a>										
									: <a download="Data_Collection_Template"><img width={"100px"} height={"120px"} src="images/excelIcon.PNG" style={{ opacity: '0.5', cursor:'initial'}} /></a>	}
									</div>				
                                </div>

								<div className="medium-4 pr15" >		
								  <div className="grid-x medium-12" style={{padding:'40px'}}>
							     	
								  </div>								
								<div className="grid-x pt10">									
									<div className="medium-12">
                                      {this.state.isDataSave ?   
										<label id="lblUpload" style={{ width: '290px' }}										
											class="button button--accent"> {"Upload Spend File"}											
											<input type="file"
												id="File"
												accept=".xlsx, .xls"												
												onChange= {this.handleChange.bind(this)}>
											</input>
										</label>	: 
									    <label id="lblUpload" style={{ width: '290px', cursor: 'initial', opacity: '0.5' }}
								     	disabled={this.state.isDataSave}
										class="button button--accent"> {"Upload Spend File"}</label>
										}
									</div>										
									</div>
									<div className="grid-x pt10 pl20">{this.state.fileName}</div>
								</div>

                                <div className="medium-4">
									<div className="grid-x medium-12" style={{padding:'40px'}}></div>									

									<div className="grid-x pt10">
									<div className="medium-12">
										{this.state.isDataSave ?   
										<label id="lblUpload" style={{ width: '290px' }}										
											class="button button--accent"> {"Submit Spend file to SDMC"}											
											<input type="file"
												id="File"
												accept=".xlsx, .xls"												
												onChange= {this.handleSubmit.bind(this)}>
											</input>
										</label>	: 
									    <label id="lblUpload" style={{ width: '290px', cursor: 'initial', opacity: '0.5' }}
								     	disabled={this.state.isDataSave}
										class="button button--accent"> {"Submit Spend file to SDMC"}</label>
										}
									</div>										
									</div>

								</div>

							</div>
						{/* </Form> */}
					</div>	
	                
					<div className="grid-x medium-12">
                {(this.state.isLoading) ?
                  <div className="grid-x medium-12">
					  <img style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={path + `images/ajax-loader.gif`} alt="" />
					</div>
                  : this.state.warningMessageArr.length > 0 ? <div className="grid-x medium-12 warnings">
                  <div className="grid-x medium-12">Field Assessment</div>
                  <ul>{this.state.warningMessageArr.map(item => <li className='pl20'>{item}</li>)}</ul>
                </div> : ''}               
                {this.state.errorMessagesArr.length > 0 ? <div className="grid-x medium-12 errors">
                  <div className="grid-x medium-12">Field Assessment</div>
                  <ul>{this.state.errorMessagesArr.map(item => <li className='pl20'>{item}</li>)}</ul>
                </div> : ''}
              </div>
				</div>
			</ScreenE>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.default,
		currency: decodeURIComponent(state.valueStory ? state.valueStory.Currency : state.companyProfileData[0].currencyCode),
		spendAsPercentageOfRevenue: state.spendAsPercentageOfRevenue,
		validation: state.content.validation,
		contentPE: state.contentPE,
		companyData: state.companyProfileData,
		selectedValueStory: state.selectedValueStory,
		currencySymbols: state.content.CurrencySymbols,
		userData: state.valueStory,
		newValueStory: state.isNewValueStory,
		searchResultData: state.searchResultData,
		capIQ: state.content.spendAnalysis.detailedFile,
		spendDetails: state.spendDetails
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		fetchSpendCollectionColumnDetails: () => dispatch(fetchSpendCollectionColumnDetails()),
		uploadSpendCollectionData: (records) => dispatch(uploadSpendCollectionData(records)),
		getCollectCustomerSpendDetails: (records) => dispatch(getCollectCustomerSpendDetails(records)),
		saveCollectCustomerSpend: (records) => dispatch(saveCollectCustomerSpend(records)),
		getSpendFilesData: (dmcid, vsid, userid) => dispatch(getSpendFilesData(dmcid, vsid, userid)),
		getSpendQualityAssessmentData: () => dispatch(getSpendQualityAssessmentData())
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CollectCustomerSpend)
