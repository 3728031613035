import React from 'react'
import FaAngleRight from 'react-icons/lib/fa/angle-right'
import { BreakLine } from 'utils/Utils'

const BigButton = (props) =>
	<button type={props.type} className="big-button" disabled={props.disabled}>
		{BreakLine(props.content)}
		<FaAngleRight className="big-button__icon" />
		<FaAngleRight className="big-button__icon--hover"/>
	</button>

export default BigButton

BigButton.defaultProps = {
	type: 'button',
	onClick: () => {},
	disabled: false
}
