// Store
import { store } from 'index'
import { round, roundFix, formatThousands, addCurrencyMask } from 'utils/Utils'

export const getSourceValue = (maturity, country, region, industry, suggestedValueDescription) => {
	const state = store.getState()
	var arr = state.benchMarksData;
	var found = false;
	//1)First check for Country and Industry
	for (let i = 0; i < arr.length; i++) {
		var RegionArr = arr[i].BenchMarks.filter(e => {
			return (e.Region_Country === country && e.Industry === industry)
		})
		if (RegionArr.length != 0) {
			var Benchmark = RegionArr[0].BenchMarkSuggestedValues.find(report => report.Suggested_Value_Description.toLowerCase() === suggestedValueDescription.toLowerCase())
			if (Benchmark != undefined) {
				found = true;
				if (maturity === 1 || maturity === 2)
					return Benchmark.Laggard_Value;
				else if (maturity === 3) {
					return Benchmark.Average_Value;
				}
				else {
					return Benchmark.Best_Value;
				}
			}
		}
	}
	//2)Search for Country AND X-Industry match.
	if (!found) {
		for (let j = 0; j < arr.length; j++) {
			var RegionArr = arr[j].BenchMarks.filter(e => {
				return (e.Region_Country === country && e.Industry === "X-Industry")
			})
			//First check for Country 
			if (RegionArr.length != 0) {
				var Benchmark = RegionArr[0].BenchMarkSuggestedValues.find(report => report.Suggested_Value_Description.toLowerCase() === suggestedValueDescription.toLowerCase())
				if (Benchmark != undefined) {
					found = true;
					if (maturity === 1 || maturity === 2)
						return Benchmark.Laggard_Value;
					else if (maturity === 3) {
						return Benchmark.Average_Value;
					}
					else {
						return Benchmark.Best_Value;
					}
				}
			}
		}
	}
	//3)Search for Region AND Industry match.
	if (!found) {
		for (let k = 0; k < arr.length; k++) {
			var RegionArr = arr[k].BenchMarks.filter(e => {
				return (e.Region_Country === region && e.Industry === industry)
			})
			if (RegionArr.length != 0) {
				var Benchmark = RegionArr[0].BenchMarkSuggestedValues.find(report => report.Suggested_Value_Description.toLowerCase() === suggestedValueDescription.toLowerCase())
				if (Benchmark != undefined) {
					found = true;
					if (maturity === 1 || maturity === 2)
						return Benchmark.Laggard_Value;
					else if (maturity === 3) {
						return Benchmark.Average_Value;
					}
					else {
						return Benchmark.Best_Value;
					}
				}
			}
		}
	}
	//4)Search for X-Region AND Industry match
	if (!found) {
		for (let l = 0; l < arr.length; l++) {
			var RegionArr = arr[l].BenchMarks.filter(e => {
				return (e.Region_Country === "X-Region" && e.Industry === industry)
			})
			if (RegionArr.length != 0) {
				var Benchmark = RegionArr[0].BenchMarkSuggestedValues.find(report => report.Suggested_Value_Description.toLowerCase() === suggestedValueDescription.toLowerCase())
				if (Benchmark != undefined) {
					found = true;
					if (maturity === 1 || maturity === 2)
						return Benchmark.Laggard_Value;
					else if (maturity === 3) {
						return Benchmark.Average_Value;
					}
					else {
						return Benchmark.Best_Value;
					}
				}
			}
		}
	}
	//5)Search for Region AND X-Industry match
	if (!found) {
		for (let m = 0; m < arr.length; m++) {
			var RegionArr = arr[m].BenchMarks.filter(e => {
				return (e.Region_Country === region && e.Industry === "X-Industry")
			})
			if (RegionArr.length != 0) {
				var Benchmark = RegionArr[0].BenchMarkSuggestedValues.find(report => report.Suggested_Value_Description.toLowerCase() === suggestedValueDescription.toLowerCase())
				if (Benchmark != undefined) {
					found = true;
					if (maturity === 1 || maturity === 2)
						return Benchmark.Laggard_Value;
					else if (maturity === 3) {
						return Benchmark.Average_Value;
					}
					else {
						return Benchmark.Best_Value;
					}
				}
			}
		}
	}
	//6)Search for X-Region AND X-Industry match
	if (!found) {
		for (let n = 0; n < arr.length; n++) {
			var RegionArr = arr[n].BenchMarks.filter(e => {
				return (e.Region_Country === "X-Region" && e.Industry === "X-Industry")
			})
			if (RegionArr.length != 0) {
				var Benchmark = RegionArr[0].BenchMarkSuggestedValues.find(report => report.Suggested_Value_Description.toLowerCase() === suggestedValueDescription.toLowerCase())
				if (Benchmark != undefined) {
					found = true;
					if (maturity === 1 || maturity === 2)
						return Benchmark.Laggard_Value;
					else if (maturity === 3) {
						return Benchmark.Average_Value;
					}
					else {
						return Benchmark.Best_Value;
					}
				}
			}
		}
	}
	if (!found) {
		return '0'
	}
}

export const totalSpend = (revenue, spendAsPercentageOfRevenue) => {
	// Revenue x Spend as % of Revenue based on Industry
	// Rounded to 2 decimal places
	//return roundFix((revenue * (spendAsPercentageOfRevenue / 100)), 2)

	return roundFix((revenue * spendAsPercentageOfRevenue), 2);
}

export const incrementalSourceSavings = (FSSavings, currentAvgSourceSavings) => {
	return (FSSavings - currentAvgSourceSavings)
}

export const spendNotStrategicallySourced = (totalSpend) => {
	// totalSpend x 32.2% 
	//Chnaged as per jira https://product-jira.ariba.com/browse/ITS-4567 
	//(Q24*0.0819)+(Q24*0.2218)
	return (totalSpend * 0.0819) + (totalSpend * 0.2218)
}

export const requisitionableSpend = (totalSpend) => {
	// totalSpend x 15%
	//Chnaged as per jira https://product-jira.ariba.com/browse/ITS-4567 
	// =Q48*0.13919
	return (totalSpend * 0.13919)
}

export const currentNumOfOPFTEs = (totalSpend) => {
	// totalSpend / 1000 * 11
	//(Q59/10^3)*11.3
	return roundFix((totalSpend / 1000) * 11.3, 0)
}

export const percentReductionFTEs = (currentPO, futurePO) => {
	// 100% - currentPO/futurePO
	return (1 - currentPO / futurePO) * 100
}

export const FTEReduction = (currentNumOfPFTEs, percentReductionFTEs) => {
	// ROUNDDOWN(currentNumOfPFTEs*percentReductionFTEs)
	return Math.floor(currentNumOfPFTEs * (percentReductionFTEs / 100))
}

export const currentNumberOfAccountsPayableFTEs = (totalSpend) => {
	// totalSpend / 1000 * 15
	//(Q59/10^3)*14.68
	return roundFix((totalSpend / 1000) * 14.68, 0)
}

export const balanceSheetImpact = (totalSpend, percentOfSpendExtended, NumDaysPaymentTermsExtended) => {
	// totalSpend * percentOfSpendExtended * NumDaysPaymentTermsExtended) / 365
	return (totalSpend * percentOfSpendExtended / 100) * NumDaysPaymentTermsExtended / 365
}

export const reduceInventoryLevelsBenefit = (numberOfDaysInventoryReduced, costOfEachInventoryDay) => {
	return numberOfDaysInventoryReduced * costOfEachInventoryDay
}

export const averageAnnualAuditCost = (revenue) => {
	return revenue * 0.0007
}

export const averageNumOfPlanner = (spend) => {
	return Math.floor(spend / 1000 * 38)
}

export const totalPlannerFTECost = (averagePlannerFTECost, averageNumOfPlanner) => {
	return averagePlannerFTECost * averageNumOfPlanner / Math.pow(10, 6)
}

export const estimatedTotalComplianceAndRiskManagementFTECost = (revenue) => {
	return revenue * 0.00049
}

export const totalTransportationSpend = (revenue) => {
	return revenue * 0.026
}

export const inboundExpeditedTransportationSpend = (totalTransportationSpend) => {
	return 0.0531 * totalTransportationSpend
}

export const FTECostPerHour = (operationalProcurementFTECost) => {
	return operationalProcurementFTECost / (40 * 48)
}

export const totalCostOfOnboardingSuppliersManually = (FTECostPerHour, hoursSpentOnboardingSupplier, newSuppliersOnboardedPerYear) => {
	return FTECostPerHour * hoursSpentOnboardingSupplier * newSuppliersOnboardedPerYear
}

export const estimatedRevenueLossDueToStockOuts = (revenue) => {
	return revenue
}

export const spendThatIsInventoried = (totalSpend, directMaterialSpend) => {
	return totalSpend * directMaterialSpend / 100
}

export const numberOfDaysInventoryReduced = (daysInventoryOutstanding, DIOReduction) => {
	return daysInventoryOutstanding * DIOReduction / 100
}

export const costOfEachInventoryDay = (spendThatIsInventoried) => {
	return spendThatIsInventoried / 365
}

export const revenuePerMonth = (revenue) => {
	return revenue / 12
}

export const increaseinRevenueduetoImprovedTimetoMarket = (revenuePerMonth, avgMonthstoBringNewProducttoMarket, improvedTimetoMarket) => {
	return revenuePerMonth * avgMonthstoBringNewProducttoMarket * improvedTimetoMarket / 100
}

export const averageAnnualRevenuefromNewProducts = (revenue, newProductRevenue) => {
	return revenue * newProductRevenue / 100
}

export const savingsLostDuetoOverpayment = (totalSpend) => {
	return (totalSpend / 0.02)
}

export const totalPayments = (totalSpend) => {
	//return roundFix((totalSpend / 8900 * 0.6 * 1000000), 0) commented on Oct 04 2018 SK 
	return roundFix((totalSpend), 0)
}


export const estimatedBenefitForSuggestedValue = (id, arr) => {
	let estimatedBenefit = 0
	// Values derived from 0 based index
	// correlating to each table row
	switch (id) {
		case '1.1':
			estimatedBenefit = roundFix(arr[0] * (arr[1] / 100) * (arr[2] / 100) * (arr[3] / 100) * (arr[6] / 100) * (1 - (arr[7] / 100)), 2)
			break

		case '1.2':
			estimatedBenefit = roundFix(arr[0] * (arr[1] / 100) * (arr[2] / 100) * (arr[3] / 100) * (arr[6] / 100), 2)
			break
		case '1.3':
			estimatedBenefit = (arr[0] * (arr[1] / 100) * (arr[2] / 100) * (arr[3] / 100) * (arr[4] / 100) * (1 - (arr[5] / 100)))
			break

		case '1.4':
			estimatedBenefit = roundFix(arr[0] * (arr[1] / 100) * (arr[2] / 100) * (arr[3] / 100), 2)
			break
		case '1.5':
			estimatedBenefit = roundFix(arr[0] * (arr[1] / 100) * (arr[2] / 100) * (arr[3] / 100), 2)
			break

		case '1.6':
			estimatedBenefit = roundFix(arr[0] * (arr[1] / 100) * (arr[2] / 100) * (arr[3] / 100) * (arr[4] / 100), 2)
			break

		case '2.1':
			estimatedBenefit = roundFix(arr[0] * (arr[1] / 100) * (arr[2] / 100) * (arr[3] / 100) * (arr[4] / 100) * ((arr[5] / 100)), 2)
			break

		case '2.2':
			estimatedBenefit = roundFix((arr[0] * (arr[1] / 100) * (arr[2] / 100) * (arr[3] / 100)), 2)
			break

		case '2.3':
			estimatedBenefit = roundFix((arr[0] * (arr[1] / 100)* (arr[2] / 100) * (arr[3] / 100)), 2)
			break

		case '3.1':
			estimatedBenefit = roundFix(arr[5] * arr[6] / Math.pow(10, 6), 2)
			break

		case '3.2':
			estimatedBenefit = roundFix(arr[6] * arr[5] / Math.pow(10, 6), 2)
			break

		case '3.3':
			estimatedBenefit = roundFix(arr[0] * (arr[1] / 100) * (arr[2] / 100), 2)
			break

		case '3.4':
			estimatedBenefit = roundFix(arr[2] * (arr[3] / 100), 2)
			break

		case '3.5':
			estimatedBenefit = roundFix(arr[0] * (arr[1] / 100) * (arr[2] / 100), 2)
			break

		case '3.6':
			estimatedBenefit = roundFix(arr[0] * (arr[1] / 100) * (arr[2] / 100) * (arr[3] / 100) * (arr[4] / 100) * (arr[5] / 100), 2)
			break

		case '4.1':
			estimatedBenefit = roundFix((arr[3]) * (arr[4] / 100), 2)
			break

		case '4.2':
			estimatedBenefit = roundFix((arr[3]) * (arr[4] / 100) * (arr[5] / 100), 2)
			break

		case '4.3':
			estimatedBenefit = roundFix(arr[8] * (arr[9] / 100), 2)
			break

		case '4.4':
			estimatedBenefit = roundFix(arr[4] * ((arr[5] / 100) + (arr[6] / 100)), 2)
			break

		case '5.1':
			estimatedBenefit = roundFix((arr[4] * (arr[5] / 100)), 2)
			break

		case '5.2':
			estimatedBenefit = arr[5] * arr[6] / 100
			break

		default:

	}

	estimatedBenefit = Number(estimatedBenefit).toFixed(2)
	return estimatedBenefit
}

export const estimatedBenefitForCalculatedValue = (id, arr) => {
	let estimatedBenefit = 0
	// Values derived from 0 based index
	// correlating to each table row
	switch (id) {
		case '1.1':
			estimatedBenefit = roundFix(arr[6] - arr[7], 2)
			break

		case '1.2':
			estimatedBenefit = roundFix((arr[6]), 2)
			break

		case '1.3':
			estimatedBenefit = roundFix((arr[4] - arr[5]), 2)
			break

		case '1.4':
			estimatedBenefit = roundFix(arr[3], 2)
			break

		case '1.5':
			estimatedBenefit = roundFix(arr[3], 2)
			break

		case '1.6':
			estimatedBenefit = roundFix(arr[4], 2)
			break

		case '2.1':
			estimatedBenefit = roundFix((arr[5]), 2)
			break

		case '2.2':
			estimatedBenefit = roundFix(arr[3], 2)
			break

		case '2.3':
			estimatedBenefit = roundFix(arr[3], 2)
			break

		case '3.1':
			estimatedBenefit = roundFix(arr[5] * arr[6] / Math.pow(10, 6), 2)
			break

		case '3.2':
			estimatedBenefit = roundFix(arr[6] * arr[5] / Math.pow(10, 6), 2)
			break

		case '3.3':
			estimatedBenefit = roundFix(arr[2], 2)
			break

		case '3.4':
			estimatedBenefit = roundFix(arr[3], 2)
			break

		case '3.5':
			estimatedBenefit = roundFix(arr[2], 2)
			break

		case '3.6':
			estimatedBenefit = roundFix(arr[5], 2)
			break

		case '4.1':
			estimatedBenefit = roundFix(arr[4], 2)
			break

		case '4.2':
			estimatedBenefit = roundFix(arr[5], 2)
			break

		case '4.3':
			estimatedBenefit = roundFix(arr[9], 2)
			break
		case '4.4':
			estimatedBenefit = roundFix(arr[6] + arr[5], 2)
			break

		case '5.1':
			estimatedBenefit = roundFix(arr[5], 2)
			break

		case '5.2':
			estimatedBenefit = roundFix(arr[6], 2)
			break

		default:

	}

	estimatedBenefit = Number(estimatedBenefit).toFixed(2)
	return estimatedBenefit
}
export const runFormulaForSuggestedValue = (subleverId, rowNumber, userTotalSpend, sourceValue, revenue, spendAsPercentageOfRevenue, arr) => {

	/**
	 * Use last user input of Total Spend in any sub lever with Total Spend row, as the value for all sub lever calculations. 
	 * If there is no user input for total spend, the default is the calculated Total Spend value from Let's Get Started section
	 */

	let totalSpendValue
	(userTotalSpend !== null && userTotalSpend !== '') ? totalSpendValue = userTotalSpend : totalSpendValue = totalSpend(revenue, spendAsPercentageOfRevenue)

	switch (subleverId) {
		case '1.1':
			switch (rowNumber) {
				case 0:
					return roundFix(totalSpendValue, 2)
				case 1:
					return roundFix((100 * sourceValue), 0)
				case 2:
					return roundFix((100 * sourceValue), 0)
				case 3:
					return roundFix((100 * sourceValue), 0)
				case 4:
					return roundFix(sourceValue * 100, 1)
				case 5:
					return roundFix(sourceValue * 100, 1)
				case 6:
					return roundFix((arr[5] - arr[4]), 1)
				case 7:
					return roundFix(sourceValue * 100, 0)

				default:
			}
			break

		case '1.2':
			switch (rowNumber) {
				case 0:
					return roundFix(totalSpendValue, 2)
				case 1:
					return roundFix((100 * (1 - sourceValue)), 0)
				case 2:
					return roundFix((100 * (sourceValue)), 0)
				case 3:
					return roundFix(((sourceValue) * 100), 0)
				case 4:
					return roundFix((sourceValue * 100), 1)
				case 5:
					return roundFix(sourceValue * 100, 1)
				case 6:
					return roundFix((arr[5] - arr[4]), 1)
				default:
			}
			break

		case '1.3':
			switch (rowNumber) {
				case 0:
					return roundFix(totalSpendValue, 2)
				case 1:
					return roundFix((100 * sourceValue), 0)
				case 2:
					return roundFix((100 * sourceValue), 0)
				case 3:
					return roundFix(((1 - sourceValue) * 100), 0)
				case 4:
					return roundFix((sourceValue * 100), 1)
				case 5:
					return roundFix(sourceValue * 100, 0)
				default:
			}
			break

		case '1.4':
			switch (rowNumber) {
				case 0:
					return roundFix(totalSpendValue, 2)

				case 1:
					return roundFix((100 * sourceValue), 0)

				case 2:
					return roundFix((100 * (1 - sourceValue)), 0)

				case 3:
					return roundFix((100 * sourceValue), 1)

				default:

			}
			break

		case '1.5':
			switch (rowNumber) {
				case 0:
					return roundFix(totalSpendValue, 2)

				case 1:
					return roundFix(((revenue * sourceValue) / totalSpendValue) * 100, 0)

				case 2:
					return roundFix((100 * (sourceValue)), 2)

				case 3:
					return roundFix((100 * sourceValue), 2)

				default:

			}
			break

		case '1.6':
			switch (rowNumber) {
				case 0:
					return roundFix(totalSpendValue, 2)

				case 1:
					return roundFix(((revenue * sourceValue) / totalSpendValue) * 100, 0)

				case 2:
					return roundFix((100 * (sourceValue)), 2)

				case 3:
					return roundFix((100 * sourceValue), 2)

				case 4:
					return roundFix((100 * sourceValue), 2)

				default:

			}
			break

		case '2.1':
			switch (rowNumber) {
				case 0:
					return roundFix(totalSpendValue, 2)
				case 1:
					return roundFix((100 * sourceValue), 0)
				case 2:
					return roundFix((100 * sourceValue), 0)
				case 3:
					return roundFix((sourceValue * 100), 0)
				case 4:
					return roundFix((sourceValue * 100), 1)
				case 5:
					return roundFix(sourceValue * 100, 0)
				default:

			}
			break

		case '2.2':
			switch (rowNumber) {
				case 0:
					return roundFix(totalSpendValue, 2)

				case 1:
					return roundFix((100 * sourceValue), 0)

				case 2:
					return roundFix((100 * sourceValue), 0)

				case 3:
					return roundFix((100 * sourceValue), 2)
				default:

			}
			break

		case '2.3':
			switch (rowNumber) {
				case 0:
					return roundFix(totalSpendValue, 2)
				case 1:
					return roundFix(((revenue * sourceValue) / totalSpendValue) * 100, 0)
				case 2:
					return roundFix((100 * sourceValue), 2)
				case 3:
					return roundFix((sourceValue * 100), 2)
				default:

			}
			break

		case '3.1':
			switch (rowNumber) {
				case 0:
					return round((totalSpendValue / 1000) * sourceValue, 0)
				case 1:
					return round(sourceValue, 0)
				case 2:
					return round(arr[1] * arr[0], 0)
				case 3:
					return round(sourceValue, 0)
				case 4:
					if (arr[1] > arr[3]) {
						return arr[0]
					}
					else {
						return round(arr[2] / arr[3], 0)
					}
				case 5:
					return round(arr[0] - arr[4], 0)
				case 6:
					return round(sourceValue, 2)

				default:

			}
			break

		case '3.2':
			switch (rowNumber) {
				case 0:
					return round((totalSpendValue / 1000) * sourceValue, 0)
				case 1:
					return round(sourceValue, 0)
				case 2:
					return round(arr[1] * arr[0], 0)
				case 3:
					return round(sourceValue, 0)
				case 4:
					return round(arr[2] / arr[3], 0)
				case 5:
					return round(arr[0] - arr[4], 0)
				case 6:
					return round(sourceValue, 2)

				default:

			}
			break

		case '3.3':
			switch (rowNumber) {
				case 0:
					return roundFix(sourceValue * revenue, 2)
				case 1:
					if (arr[0] >= 28) {
						return 0.05 * 100
					} else if (arr[0] >= 14) {
						return 0.1 * 100
					} else if (arr[0] >= 6) {
						return 0.25 * 100
					} else if (arr[0] >= 3) {
						return 0.5 * 100
					} else {
						return 0.25 * 100
					}
				case 2:
					return roundFix(sourceValue * 100, 0)

				default:
				//=Q59/8900*0.6*10^6
				// No calculations needed
			}
			break

		case '3.4':
			switch (rowNumber) {
				case 0:
					return roundFix(sourceValue, 2)
				case 1:
					return roundFix(sourceValue, 0)
				case 2:
					return roundFix((arr[0] * arr[1]) / Math.pow(10, 6), 2)
				case 3:
					return roundFix(sourceValue * 100, 0)

				default:
			}
			break

		case '3.5':
			switch (rowNumber) {
				case 0:
					return roundFix(sourceValue * revenue, 2)
				case 1:
					return roundFix(sourceValue * 100, 0)
				case 2:
					return roundFix(sourceValue * 100, 0)

				default:
			}
			break

		case '3.6':
			switch (rowNumber) {
				case 0:
					return roundFix(totalSpendValue, 2)
				case 1:
					return roundFix(((sourceValue * revenue) / totalSpendValue) * 100, 1)
				case 2:
					return roundFix((1 - sourceValue) * 100, 2)
				case 3:
					return roundFix(sourceValue * 100, 2)
				case 4:
					return roundFix(sourceValue * 100, 2)
				case 5:
					return roundFix(sourceValue * 100, 2)

				default:
			}
			break

		case '4.1':
			switch (rowNumber) {
				case 0:
					return roundFix(totalSpendValue, 2)
				case 1:
					return roundFix(sourceValue * 100, 0)
				case 2:
					return roundFix((1 - sourceValue) * 100, 0)
				case 3:
					return roundFix((1 - (arr[2] / 100)) * (arr[1] / 100) * arr[0], 2)
				case 4:
					return roundFix(sourceValue * 100, 2)
				default:
			}
			break

		case '4.2':
			switch (rowNumber) {
				case 0:
					return roundFix(totalSpendValue, 2)
				case 1:
					return roundFix(100 * sourceValue, 0)
				case 2:
					return roundFix(100 * sourceValue, 0)
				case 3:
					return roundFix(arr[0] - (totalSpendValue * (arr[1] / 100)) -(totalSpendValue * (arr[2] / 100)), 2)
				case 4:
					return roundFix(sourceValue * 100, 2)
				case 5:
					return roundFix(sourceValue * 100, 2)
				default:
			}
			break

		case '4.3':
			switch (rowNumber) {
				case 0:
					return roundFix(totalSpendValue, 1)
				case 1:
					return roundFix(arr[0] * sourceValue, 2)
				case 2:
					return roundFix(arr[0] - arr[1], 2)
				case 3:
					return roundFix(100 * sourceValue, 0)
				case 4:
					return roundFix(sourceValue, 2)
				case 5:
					return roundFix(sourceValue * arr[4], 2)
				case 6:
					return roundFix(100 * sourceValue, 0)
				case 7:
					return roundFix(arr[4] * sourceValue, 2)
				case 8:
					return roundFix(((((arr[3] / 100) * arr[2]) / 365) * arr[5]) + ((((arr[6] / 100) * arr[2]) / 365) * arr[7]), 2)
				case 9:
					return roundFix(sourceValue * 100, 2)

				default:
			}
			break
		case '4.4':
			switch (rowNumber) {
				case 0:
					return roundFix(sourceValue, 0)
				case 1:
					return roundFix(sourceValue * arr[0], 0)
				case 2:
					return roundFix(sourceValue * totalSpendValue, 2)
				case 3:
					return roundFix(arr[2] / 365, 2)
				case 4:
					return roundFix((arr[1]) * arr[3], 2)
				case 5:
					return roundFix(sourceValue * 100, 1)
				case 6:
					return roundFix(sourceValue * 100, 1)
				default:
			}
			break

		case '5.1':
			switch (rowNumber) {

				case 0:
					return roundFix(revenue, 2)
				case 1:
					return roundFix((100 * sourceValue), 2)
				case 2:
					return roundFix((100 * sourceValue), 2)
				case 3:
					return roundFix((sourceValue * 100), 2)
				case 4:
					return roundFix((arr[0] * (arr[1] / 100) * (arr[2] / 100) * (arr[3] / 100)), 2)
				case 5:
					return roundFix(sourceValue * 100, 1)
				default:
			}
			break

		case '5.2':
			switch (rowNumber) {

				case 0:
					return roundFix(revenue, 2)
				case 1:
					return roundFix(100 * sourceValue, 2)
				case 2:
					return roundFix((arr[0] * (arr[1] / 100)) / 12, 2)
				case 3:
					return roundFix(sourceValue, 1)
				case 4:
					return round(sourceValue, 0)
				case 5:
					return roundFix(arr[4] * arr[2], 2)
				case 6:
					return roundFix(sourceValue * 100, 2)
				default:
			}
			break

		default:
	}
}

export const runFormulaForCalculatedValue = (subleverId, rowNumber, userTotalSpend, sourceValue, userInputValue, revenue, spendAsPercentageOfRevenue, arr) => {

	/**
	 * Use last user input of Total Spend in any sub lever with Total Spend row, as the value for all sub lever calculations. 
	 * If there is no user input for total spend, the default is the calculated Total Spend value from Let's Get Started section
	 */

	let totalSpendValue
	(userTotalSpend !== null && userTotalSpend !== '') ? totalSpendValue = userTotalSpend : totalSpendValue = totalSpend(revenue, spendAsPercentageOfRevenue)

	switch (subleverId) {
		case '1.1':
			switch (rowNumber) {
				case 0:
					if (userInputValue != undefined) {
						return (userInputValue)
					}
					return (totalSpendValue)

				case 1:
					if (userInputValue != undefined) {
						return (userInputValue)
					}
					return ((arr[0] * (arr[1] / 100)))
				case 2:
					if (userInputValue != undefined) {
						return (userInputValue)
					}
					return ((arr[1] * (arr[2] / 100)))
				case 3:
					if (userInputValue != undefined) {
						return (userInputValue)
					}
					return ((arr[2] * (arr[3] / 100)))
				case 4:
					if (userInputValue != undefined) {
						return ((userInputValue / 100) * arr[3])
					}
					return (((arr[4] / 100) * arr[3]))
				case 5:
					if (userInputValue != undefined) {
						return ((userInputValue / 100) * arr[3])
					}
					return (((arr[5] / 100) * arr[3]))
				case 6:
					if (userInputValue != undefined) {
						return (userInputValue)
					}
					return ((arr[5] - arr[4]))
				case 7:
					if (userInputValue != undefined) {
						return ((userInputValue / 100) * arr[6])
					}
					return (((arr[7] / 100) * arr[6]))

				default:
			}
			break

		case '1.2':
			switch (rowNumber) {
				case 0:
					if (userInputValue != undefined) {
						return (userInputValue)
					}
					return (totalSpendValue)
				case 1:
					if (userInputValue != undefined) {
						return (userInputValue)
					}
					return ((arr[0] * (arr[1] / 100)))
				case 2:
					if (userInputValue != undefined) {
						return (userInputValue)
					}
					return ((arr[1] * (arr[2] / 100)))
				case 3:
					if (userInputValue != undefined) {
						return (userInputValue)
					}
					return ((arr[2] * (arr[3] / 100)))
				case 4:
					if (userInputValue != undefined) {
						return ((userInputValue / 100) * arr[3])
					}
					return (arr[3] * (arr[4] / 100))
				case 5:
					if (userInputValue != undefined) {
						return ((userInputValue / 100) * arr[3])
					}
					return (arr[3] * (arr[5] / 100))

				case 6:
					return (arr[5] - arr[4])
				default:
			}
			break

		case '1.3':
			switch (rowNumber) {
				case 0:
					if (userInputValue != undefined) {
						return (userInputValue)
					}
					return (totalSpendValue)
				case 1:
					if (userInputValue != undefined) {
						return (userInputValue)
					}
					return ((arr[0] * (arr[1] / 100)))
				case 2:
					if (userInputValue != undefined) {
						return (userInputValue)
					}
					return ((arr[1] * (arr[2] / 100)))
				case 3:
					if (userInputValue != undefined) {
						return (userInputValue)
					}
					return ((arr[2] * (arr[3] / 100)))
				case 4:
					if (userInputValue != undefined) {
						return ((userInputValue / 100) * arr[3])
					}
					return (arr[3] * (arr[4] / 100))
				case 5:
					if (userInputValue != undefined) {
						return ((userInputValue / 100) * arr[4])
					}
					return (arr[4] * (arr[5] / 100))
				default:
			}
			break

		case '1.4':
			switch (rowNumber) {
				case 0:
					if (userInputValue != undefined) {
						return (userInputValue)
					}
					return (totalSpendValue)

				case 1:
					if (userInputValue != undefined) {
						return (userInputValue)
					}
					return ((arr[0] * (arr[1] / 100)))

				case 2:
					if (userInputValue != undefined) {
						return (userInputValue)
					}
					return ((arr[1] * (arr[2] / 100)))

				case 3:
					if (userInputValue != undefined) {
						return ((userInputValue / 100) * arr[2])
					}
					return ((arr[2] * (arr[3] / 100)))

				default:

			}
			break

		case '1.5':
			switch (rowNumber) {
				case 0:
					if (userInputValue != undefined) {
						return (userInputValue)
					}
					return (totalSpendValue)

				case 1:
					if (userInputValue != undefined) {
						return (userInputValue)
					}
					return ((arr[1] / 100) * arr[0])

				case 2:
					if (userInputValue != undefined) {
						return (userInputValue)
					}
					return (sourceValue * arr[1])

				case 3:
					if (userInputValue != undefined) {
						return ((userInputValue / 100) * arr[2])
					}
					return ((arr[2] * sourceValue))

				default:

			}
			break

		case '1.6':
			switch (rowNumber) {
				case 0:
					if (userInputValue != undefined) {
						return (userInputValue)
					}
					return (totalSpendValue)

				case 1:
					if (userInputValue != undefined) {
						return (userInputValue)
					}
					return ((arr[1] / 100) * arr[0])

				case 2:
					if (userInputValue != undefined) {
						return (userInputValue)
					}
					return (sourceValue * arr[1])

				case 3:
					if (userInputValue != undefined) {
						return (userInputValue)
					}
					return ((sourceValue * arr[2]))

				case 4:
					if (userInputValue != undefined) {
						return ((userInputValue / 100) * arr[3])
					}
					return ((arr[3] * sourceValue))

				default:

			}
			break

		case '2.1':
			switch (rowNumber) {
				case 0:
					if (userInputValue != undefined) {
						return (userInputValue)
					}
					return (totalSpendValue)
				case 1:
					if (userInputValue != undefined) {
						return (userInputValue)
					}
					return ((arr[0] * (arr[1] / 100)))
				case 2:
					if (userInputValue != undefined) {
						return (userInputValue)
					}
					return ((arr[1] * (arr[2] / 100)))
				case 3:
					if (userInputValue != undefined) {
						return (userInputValue)
					}
					return ((arr[2] * (arr[3] / 100)))
				case 4:
					if (userInputValue != undefined) {
						return ((userInputValue / 100) * arr[3])
					}
					return (arr[3] * (arr[4] / 100))
				case 5:
					if (userInputValue != undefined) {
						return ((userInputValue / 100) * arr[4])
					}
					return (arr[4] * (arr[5] / 100))
				default:

			}
			break

		case '2.2':
			switch (rowNumber) {
				case 0:
					if (userInputValue != undefined) {
						return (userInputValue)
					}
					return (totalSpendValue)

				case 1:
					if (userInputValue != undefined) {
						return (userInputValue)
					}
					return ((arr[0] * (arr[1] / 100)))

				case 2:
					if (userInputValue != undefined) {
						return (userInputValue)
					}
					return ((arr[1] * (arr[2] / 100)))

				case 3:
					if (userInputValue != undefined) {
						return ((userInputValue / 100) * arr[2])
					}
					return (arr[2] * (arr[3] / 100))
				default:

			}
			break

		case '2.3':
			switch (rowNumber) {
				case 0:
					if (userInputValue != undefined) {
						return (userInputValue)
					}
					return (totalSpendValue)
				case 1:
					if (userInputValue != undefined) {
						return (userInputValue)
					}
					return ((arr[1] / 100) * arr[0])
				case 2:
					if (userInputValue != undefined) {
						return (userInputValue)
					}
					return ((arr[1] * sourceValue))
				case 3:
					if (userInputValue != undefined) {
						return ((userInputValue / 100) * arr[2])
					}
					return ((sourceValue * arr[2]))
				default:

			}
			break

		case '3.1':
			switch (rowNumber) {
				case 0:
					if (userInputValue != undefined) {
						return round(userInputValue, 0)
					}
					return round((totalSpendValue / 1000) * sourceValue, 0)
				case 1:
					if (userInputValue != undefined) {
						return round(userInputValue, 0)
					}
					return round(sourceValue, 0)
				case 2:
					return round(arr[1] * arr[0], 0)
				case 3:
					if (userInputValue != undefined) {
						return round(userInputValue, 0)
					}
					return round(sourceValue, 0)
				case 4:
					if (arr[1] > arr[3]) {
						return arr[0]
					}
					else {
						return round(arr[2] / arr[3], 0)
					}
				case 5:
					return round(arr[0] - arr[4], 0)
				case 6:
					if (userInputValue != undefined) {
						return round(userInputValue, 2)
					}
					return round(sourceValue, 2)

				default:

			}
			break

		case '3.2':
			switch (rowNumber) {
				case 0:
					if (userInputValue != undefined) {
						return round(userInputValue, 0)
					}
					return round((totalSpendValue / 1000) * sourceValue, 0)
				case 1:
					if (userInputValue != undefined) {
						return round(userInputValue, 0)
					}
					return round(sourceValue, 0)
				case 2:
					return round(arr[1] * arr[0], 0)
				case 3:
					if (userInputValue != undefined) {
						return round(userInputValue, 0)
					}
					return round(sourceValue, 0)
				case 4:
					return round(arr[2] / arr[3], 0)
				case 5:
					return round(arr[0] - arr[4], 0)
				case 6:
					if (userInputValue != undefined) {
						return round(userInputValue, 2)
					}
					return round(sourceValue, 2)

				default:

			}
			break

		case '3.3':
			switch (rowNumber) {
				case 0:
					if (userInputValue != undefined) {
						return (userInputValue)
					}
					return (sourceValue * revenue)
				case 1:
					if (userInputValue != undefined) {
						return (userInputValue)
					}
					return ((arr[1] / 100) * arr[0])
				case 2:
					if (userInputValue != undefined) {
						return (userInputValue)
					}
					return (sourceValue * arr[1])

				default:
			}
			break

		case '3.4':
			switch (rowNumber) {
				case 0:
					if (userInputValue != undefined) {
						return (userInputValue)
					}
					return (sourceValue)
				case 1:
					if (userInputValue != undefined) {
						return (userInputValue)
					}
					return (sourceValue)
				case 2:
					return (arr[0] * arr[1] / Math.pow(10, 6))
				case 3:
					if (userInputValue != undefined) {
						return (userInputValue)
					}
					return (sourceValue * arr[2] )

				default:
			}
			break

		case '3.5':
			switch (rowNumber) {
				case 0:
					if (userInputValue != undefined) {
						return (userInputValue)
					}
					return (arr[0])
				case 1:
					if (userInputValue != undefined) {
						return (userInputValue)
					}
					return ((arr[1] / 100) * arr[0])
				case 2:
					if (userInputValue != undefined) {
						return (userInputValue)
					}
					return ((arr[2] / 100) * arr[1])

				default:
			}
			break

		case '3.6':
			switch (rowNumber) {
				case 0:
					if (userInputValue != undefined) {
						return (userInputValue)
					}
					return (totalSpendValue)
				case 1:
					if (userInputValue != undefined) {
						return (userInputValue)
					}
					return ((arr[1] / 100) * arr[0])
				case 2:
					if (userInputValue != undefined) {
						return ((userInputValue / 100) * arr[1])
					}
					return ((arr[2] / 100) * arr[1])
				case 3:
					if (userInputValue != undefined) {
						return ((userInputValue / 100) * arr[2])
					}
					return ((arr[3] / 100) * arr[2])
				case 4:
					if (userInputValue != undefined) {
						return ((userInputValue / 100) * arr[3])
					}
					return ((arr[4] / 100) * arr[3])
				case 5:
					if (userInputValue != undefined) {
						return ((userInputValue / 100) * arr[4])
					}
					return ((arr[5] / 100) * arr[4])

				default:
			}
			break

		case '4.1':
			switch (rowNumber) {
				case 0:
					if (userInputValue != undefined) {
						return (userInputValue)
					}
					return (totalSpendValue)
				case 1:
					if (userInputValue != undefined) {
						return (userInputValue)
					}
					return ((arr[1] / 100) * arr[0])
				case 2:
					if (userInputValue != undefined) {
						return (userInputValue)
					}
					return ((arr[2] / 100) * arr[1])
				case 3:
					return (arr[1] - arr[2])
				case 4:
					if (userInputValue != undefined) {
						return ((userInputValue / 100) * arr[3])
					}
					return ((arr[4] / 100) * arr[3])
				default:
			}
			break

		case '4.2':
			switch (rowNumber) {
				case 0:
					if (userInputValue != undefined) {
						return (userInputValue)
					}
					return (totalSpendValue)
				case 1:
					if (userInputValue != undefined) {
						return (userInputValue)
					}
					return (arr[0] * (arr[1] / 100))
				case 2:
					if (userInputValue != undefined) {
						return (userInputValue)
					}
					return (arr[0] * (arr[2] / 100))
				case 3:
					return Number(roundFix(arr[0] - arr[1] - arr[2], 2))
				case 4:
					if (userInputValue != undefined) {
						return ((userInputValue / 100) * arr[3])
					}
					return ((arr[4] / 100) * arr[3])
				case 5:
					if (userInputValue != undefined) {
						return ((userInputValue / 100) * arr[4])
					}
					return ((arr[5] / 100) * arr[4])
				default:
			}
			break
		case '4.3':
			switch (rowNumber) {
				case 0:
					if (userInputValue != undefined) {
						return (userInputValue)
					}
					return (totalSpendValue)
				case 1:
					if (userInputValue != undefined) {
						return (userInputValue)
					}
					return (arr[1])
				case 2:
					return Number(roundFix(arr[0] - arr[1], 2))
				case 3:
					if (userInputValue != undefined) {
						return (userInputValue)
					}
					return (arr[2] * (arr[3] / 100))
				case 4:
					if (userInputValue != undefined) {
						return (userInputValue)
					}
					return (arr[4])
				case 5:
					if (userInputValue != undefined) {
						return (userInputValue)
					}
					return (arr[5])
				case 6:
					if (userInputValue != undefined) {
						return (userInputValue)
					}
					return (arr[3] * (arr[6] / 100))
				case 7:
					if (userInputValue != undefined) {
						return (userInputValue)
					}
					return (arr[7])
				case 8:
					return Number(roundFix(((arr[3] / 365) * arr[5]) + ((arr[6] / 365) * arr[7]), 2))
				case 9:
					if (userInputValue != undefined) {
						return ((userInputValue / 100) * arr[8])
					}
					return ((arr[9] / 100) * arr[8])

				default:
			}
			break

		case '4.4':
			switch (rowNumber) {
				case 0:
					if (userInputValue != undefined) {
						return round(userInputValue, 0)
					}
					return round(sourceValue, 0)
				case 1:
					if (userInputValue != undefined) {
						return (userInputValue)
					}
					return (arr[1])
				case 2:
					if (userInputValue != undefined) {
						return (userInputValue)
					}
					return (arr[2])
				case 3:
					return (arr[2] / 365)
				case 4:
					return Number(roundFix(arr[1] * arr[3], 2))
				case 5:
					if (userInputValue != undefined) {
						return ((userInputValue / 100) * arr[4])
					}
					return ((arr[5] / 100) * arr[4])
				case 6:
					if (userInputValue != undefined) {
						return ((userInputValue / 100) * arr[4])
					}
					return ((arr[6] / 100) * arr[4])
				default:
			}
			break

		case '5.1':
			switch (rowNumber) {

				case 0:
					if (userInputValue != undefined) {
						return (userInputValue)
					}
					return (revenue)

				case 1:
					if (userInputValue != undefined) {
						return (userInputValue)
					}
					return ((arr[0] * (arr[1] / 100)))

				case 2:
					if (userInputValue != undefined) {
						return (userInputValue)
					}
					return ((arr[1] * (arr[2] / 100)))

				case 3:
					if (userInputValue != undefined) {
						return Number(userInputValue)
					}
					return ((arr[2] * (arr[3] / 100)))
				case 4:
					return (arr[3])
				case 5:
					if (userInputValue != undefined) {
						return ((userInputValue / 100) * arr[4])
					}
					return ((arr[4] * (arr[5] / 100)))
				default:
			}
			break

		case '5.2':
			switch (rowNumber) {

				case 0:
					if (userInputValue != undefined) {
						return (userInputValue)
					}
					return (revenue)
				case 1:
					if (userInputValue != undefined) {
						return (userInputValue)
					}
					return (arr[0] * (arr[1] / 100))
				case 2:
					return Number(roundFix(arr[1] / 12, 2))
				case 3:
					if (userInputValue != undefined) {
						return (userInputValue * arr[2])
					}
					return (arr[3] * arr[2])
				case 4:
					if (userInputValue != undefined) {
						return (userInputValue * arr[2])
					}
					return (arr[4] * arr[2])
				case 5:
					return (arr[4])
				case 6:
					if (userInputValue != undefined) {
						return ((userInputValue / 100) * arr[4])
					}
					return ((arr[6] / 100) * arr[4])
				default:
			}
			break

		default:
	}
}


export const getSourceValue_New = (maturity, country, region, industry, suggestedValueDescription) => {
	const state = store.getState()
	var arr = state.benchMarksData;
	var found = false;
	var finalOutPut = '';
	var Benchmark;
	//1)First check for Country and Industry
	for (let i = 0; i < arr.length; i++) {
		var RegionArr = arr[i].BenchMarks.filter(e => {
			return (e.Region_Country === country && e.Industry === industry)
		})
		if (RegionArr.length != 0) {
			Benchmark = RegionArr[0].BenchMarkSuggestedValues.find(report => report.Suggested_Value_Description.toLowerCase() === suggestedValueDescription.toLowerCase())
			found = Benchmark !== undefined ? true : false;
		}
	}
	//2)Search for Country AND X-Industry match.
	if (!found) {
		for (let i = 0; i < arr.length; i++) {
			var RegionArr = arr[i].BenchMarks.filter(e => {
				return (e.Region_Country === country && e.Industry === "X-Industry")
			})
			//First check for Country 
			if (RegionArr.length != 0) {
				Benchmark = RegionArr[0].BenchMarkSuggestedValues.find(report => report.Suggested_Value_Description.toLowerCase() === suggestedValueDescription.toLowerCase())
				found = Benchmark !== undefined ? true : false;
			}
		}
	}
	//3)Search for Region AND Industry match.
	if (!found) {
		for (let i = 0; i < arr.length; i++) {
			var RegionArr = arr[i].BenchMarks.filter(e => {
				return (e.Region_Country === region && e.Industry === industry)
			})
			if (RegionArr.length != 0) {
				Benchmark = RegionArr[0].BenchMarkSuggestedValues.find(report => report.Suggested_Value_Description.toLowerCase() === suggestedValueDescription.toLowerCase())
				found = Benchmark !== undefined ? true : false;
			}
		}
	}
	//4)Search for X-Region AND Industry match
	if (!found) {
		for (let i = 0; i < arr.length; i++) {
			var RegionArr = arr[i].BenchMarks.filter(e => {
				return (e.Region_Country === "X-Region" && e.Industry === industry)
			})
			if (RegionArr.length != 0) {
				Benchmark = RegionArr[0].BenchMarkSuggestedValues.find(report => report.Suggested_Value_Description.toLowerCase() === suggestedValueDescription.toLowerCase())
				found = Benchmark !== undefined ? true : false
			}
		}
	}
	//5)Search for Region AND X-Industry match
	if (!found) {
		for (let i = 0; i < arr.length; i++) {
			var RegionArr = arr[i].BenchMarks.filter(e => {
				return (e.Region_Country === region && e.Industry === "X-Industry")
			})
			if (RegionArr.length != 0) {
				Benchmark = RegionArr[0].BenchMarkSuggestedValues.find(report => report.Suggested_Value_Description.toLowerCase() === suggestedValueDescription.toLowerCase())
				found = Benchmark !== undefined ? true : false
			}
		}
	}
	//6)Search for X-Region AND X-Industry match
	if (!found) {
		for (let i = 0; i < arr.length; i++) {
			var RegionArr = arr[i].BenchMarks.filter(e => {
				return (e.Region_Country === "X-Region" && e.Industry === "X-Industry")
			})
			if (RegionArr.length != 0) {
				Benchmark = RegionArr[0].BenchMarkSuggestedValues.find(report => report.Suggested_Value_Description.toLowerCase() === suggestedValueDescription.toLowerCase())
				found = Benchmark !== undefined ? true : false
			}
		}
	}
	if (!found) {
		return '0'
	}
	if (Benchmark != undefined && found) {
		finalOutPut = Benchmark;
		if (maturity === 1 || maturity === 2)
			finalOutPut.display_Value = Benchmark.Laggard_Value;
		else if (maturity === 3) {
			finalOutPut.display_Value = Benchmark.Average_Value;
		}
		else {
			finalOutPut.display_Value = Benchmark.Best_Value;
		}
		if(RegionArr[0]!== null || RegionArr[0]!== undefined){
			finalOutPut.country = RegionArr[0].Region_Country;
			finalOutPut.industry = RegionArr[0].Industry;
		}
		else{
			finalOutPut.country = country
			finalOutPut.industry = industry
		}
	}
	return finalOutPut;
}


export const getFormulaForCalculatedValue = (subleverId, rowNumber, userTotalSpend, sourceValue, userInputValue, revenue, spendAsPercentageOfRevenue, arr, arr2, currency) => {

	/**
	 * Use last user input of Total Spend in any sub lever with Total Spend row, as the value for all sub lever calculations. 
	 * If there is no user input for total spend, the default is the calculated Total Spend value from Let's Get Started section
	 */

	let totalSpendValue
	(userTotalSpend !== null && userTotalSpend !== '') ? totalSpendValue = userTotalSpend : totalSpendValue = totalSpend(revenue, spendAsPercentageOfRevenue)

	switch (subleverId) {
		case '1.1':
			switch (rowNumber) {
				case 0:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return ('= ' + 'Revenue' + ' x ' + round(spendAsPercentageOfRevenue * 100, 2) + '%')

				case 1:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return('= ' + addCurrencyMask(totalSpendValue, currency) + ' x ' + arr[1] + '%')
				case 2:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					//return ((arr[1] * (arr[2] / 100)))
					return('= '+ addCurrencyMask((round(arr2[1], 2)), currency) + ' x ' + arr[2] + '%')
				case 3:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					//return ((arr[2] * (arr[3] / 100)))
					return('= '+addCurrencyMask((round(arr2[2], 2)), currency)+' x '+arr[3]+'%')
				case 4:
					if (userInputValue != undefined) {
						return ('= '+ userInputValue + '%'  + ' x ' + addCurrencyMask((round(arr2[3], 2)), currency))
					}
					return ('= '+ addCurrencyMask((round(arr2[3], 2)), currency) + ' x ' + arr[4] + '%' )
				case 5:
					if (userInputValue != undefined) {
						return ('= ' + userInputValue + '%' + ' (' + 'User Input Value'+ ')'+ ' x ' + addCurrencyMask((round(arr2[3], 2)), currency))
					}
					return ('= ' + addCurrencyMask((round(arr2[3], 2)), currency)+ ' x '+ arr[5]+'%')
				case 6:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return ('= '+ addCurrencyMask((round(arr2[5], 2)), currency) + ' - '+ addCurrencyMask((round(arr2[4], 2)), currency))
				case 7:
					if (userInputValue != undefined) {
						return ('= '+ userInputValue + '%' + ' (' + 'User Input Value'+ ')'+ ' x ' + addCurrencyMask((round(arr2[6], 2)), currency))
					}
					return ('= ' +  addCurrencyMask((round(arr2[6], 2)), currency)+ ' x ' + arr[7] + '%' )

				default:
			}
			break

		case '1.2':
			switch (rowNumber) {
				case 0:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return ('= ' + 'Revenue' + ' x ' + round(spendAsPercentageOfRevenue * 100, 2) + '%')
				case 1:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return ('= ' +  addCurrencyMask((round(arr2[0], 2)), currency) + ' x '+ arr[1] + '%' )
				case 2:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return ('= ' +  addCurrencyMask((round(arr2[1], 2)), currency) + ' x ' + arr[2] + '%')
				case 3:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return ('= ' +  addCurrencyMask((round(arr2[2], 2)), currency) + ' x '+ arr[3] + '%' )
				case 4:
					if (userInputValue != undefined) {
						return ('= '+ userInputValue + '%' + ' x ' + addCurrencyMask((round(arr2[3], 2)), currency))
					}
					return ('= '+  addCurrencyMask((round(arr2[3], 2)), currency)  + ' x ' + arr[4] + '%')
				case 5:
					if (userInputValue != undefined) {
						return ('= '+ userInputValue + '%' + ' x ' + addCurrencyMask((round(arr2[3], 2)), currency))
					}
					return ('= '+  addCurrencyMask((round(arr2[3], 2)), currency) + ' x ' + arr[5] + '%' )

				case 6:
					return ('= ' +  addCurrencyMask((round(arr2[5], 2)), currency) + ' - ' +  addCurrencyMask((round(arr2[4], 2)), currency))
				default:
			}
			break

		case '1.3':
			switch (rowNumber) {
				case 0:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return ('= ' + 'Revenue' + ' x ' + round(spendAsPercentageOfRevenue * 100, 2) + '%')
				case 1:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return ('= '  +  addCurrencyMask((round(arr2[0], 2)), currency) + ' x ' + arr[1] + '%' )
				case 2:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return ('= '  +  addCurrencyMask((round(arr2[1], 2)), currency) + ' x ' + arr[2] + '%' )
				case 3:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return ('= '  +  addCurrencyMask((round(arr2[2], 2)), currency) + ' x ' + arr[3] + '%' )
				case 4:
					if (userInputValue != undefined) {
						return ('= '+ userInputValue + '%' + ' x ' + addCurrencyMask((round(arr2[3], 2)), currency))
					}
					return ('= '  +  addCurrencyMask((round(arr2[3], 2)), currency) + ' x ' + arr[4] + '%' )
				case 5:
					if (userInputValue != undefined) {
						return ('= '+ userInputValue + '%' + ' x ' + addCurrencyMask((round(arr2[4], 2)), currency))
					}
					return ('= '  +  addCurrencyMask((round(arr2[4], 2)), currency) + ' x ' + arr[5] + '%' )
				default:
			}
			break

		case '1.4':
			switch (rowNumber) {
				case 0:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return ('= ' + 'Revenue' + ' x ' + round(spendAsPercentageOfRevenue * 100, 2) + '%')

				case 1:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return ('= '  +  addCurrencyMask((round(arr2[0], 2)), currency) + ' x ' + arr[1] + '%' )

				case 2:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return ('= '  +  addCurrencyMask((round(arr2[1], 2)), currency) + ' x ' + arr[2] + '%' )

				case 3:
					if (userInputValue != undefined) {
						return ('= '+ userInputValue + '%' + ' (' + 'User Input Value'+ ')'+ ' x ' + addCurrencyMask((round(arr2[2], 2)), currency))
					}
					return ('= '  +  addCurrencyMask((round(arr2[2], 2)), currency) + ' x ' + arr[3] + '%' )

				default:

			}
			break

		case '1.5':
			switch (rowNumber) {
				case 0:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return ('= ' + 'Revenue' + ' x ' + round(spendAsPercentageOfRevenue * 100, 2) + '%')

				case 1:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return ('= '  +  addCurrencyMask((round(arr2[0], 2)), currency) + ' x ' + arr[1] + '%' )

				case 2:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return ('= '  +  addCurrencyMask((round(arr2[1], 2)), currency) + ' x ' + arr[2] + '%' )

				case 3:
					if (userInputValue != undefined) {
						return ('= '+ userInputValue + '%' + ' (' + 'User Input Value'+ ')'+ ' x ' + addCurrencyMask((round(arr2[2], 2)), currency))
					}
					return ('= '  +  addCurrencyMask((round(arr2[2], 2)), currency) + ' x ' + arr[3] + '%' )

				default:

			}
			break

		case '1.6':
			switch (rowNumber) {
				case 0:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return ('= ' + 'Revenue' + ' x ' + round(spendAsPercentageOfRevenue * 100, 2) + '%')

				case 1:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return ('= '  +  addCurrencyMask((round(arr2[0], 2)), currency) + ' x ' + arr[1] + '%' )

				case 2:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return ('= '  +  addCurrencyMask((round(arr2[1], 2)), currency) + ' x ' + arr[2] + '%' )

				case 3:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return ('= '  +  addCurrencyMask((round(arr2[2], 2)), currency) + ' x ' + arr[3] + '%' )

				case 4:
					if (userInputValue != undefined) {
						return ('= '+ userInputValue + '%' + ' (' + 'User Input Value'+ ')'+ ' x ' + addCurrencyMask((round(arr2[3], 2)), currency))
					}
					return ('= '  +  addCurrencyMask((round(arr2[3], 2)), currency) + ' x ' + arr[4] + '%' )

				default:

			}
			break

		case '2.1':
			switch (rowNumber) {
				case 0:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return ('= ' + 'Revenue' + ' x ' + round(spendAsPercentageOfRevenue * 100, 2) + '%')
				case 1:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return ('= '  +  addCurrencyMask((round(arr2[0], 2)), currency) + ' x ' + arr[1] + '%' )
				case 2:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return ('= '  +  addCurrencyMask((round(arr2[1], 2)), currency) + ' x ' + arr[2] + '%' )
				case 3:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return ('= '  +  addCurrencyMask((round(arr2[2], 2)), currency) + ' x ' + arr[3] + '%' )
				case 4:
					if (userInputValue != undefined) {
						return ('= '+ userInputValue + '%' + ' (' + 'User Input Value'+ ')'+ ' x ' + addCurrencyMask((round(arr2[3], 2)), currency))
					}
					return ('= '  +  addCurrencyMask((round(arr2[3], 2)), currency) + ' x ' + arr[4] + '%' )
				case 5:
					if (userInputValue != undefined) {
						return ('= '+ userInputValue + '%' + ' (' + 'User Input Value'+ ')'+ ' x ' + addCurrencyMask((round(arr2[4], 2)), currency))
					}
					return ('= '  +  addCurrencyMask((round(arr2[4], 2)), currency) + ' x ' + arr[5] + '%' )
				default:

			}
			break

		case '2.2':
			switch (rowNumber) {
				case 0:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return ('= ' + 'Revenue' + ' x ' + round(spendAsPercentageOfRevenue * 100, 2) + '%')

				case 1:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return ('= '  +  addCurrencyMask((round(arr2[0], 2)), currency) + ' x ' + arr[1] + '%' )

				case 2:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return ('= '  +  addCurrencyMask((round(arr2[1], 2)), currency) + ' x ' + arr[2] + '%' )

				case 3:
					if (userInputValue != undefined) {
						return ('= '+ userInputValue + '%' + ' (' + 'User Input Value'+ ')'+ ' x ' + addCurrencyMask((round(arr2[2], 2)), currency))
					}
					return ('= '  +  addCurrencyMask((round(arr2[2], 2)), currency) + ' x ' + arr[3] + '%' )
				default:

			}
			break

		case '2.3':
			switch (rowNumber) {
				case 0:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return ('= ' + 'Revenue' + ' x ' + round(spendAsPercentageOfRevenue * 100, 2) + '%')
				case 1:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return ('= '  +  addCurrencyMask((round(arr2[0], 2)), currency) + ' x ' + arr[1] + '%' )
				case 2:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return ('= '  +  addCurrencyMask((round(arr2[1], 2)), currency) + ' x ' + arr[2] + '%' )
				case 3:
					if (userInputValue != undefined) {
						return ('= '+ userInputValue + '%' + ' (' + 'User Input Value'+ ')'+ ' x ' + addCurrencyMask((round(arr2[2], 2)), currency))
					}
					return ('= '  +  addCurrencyMask((round(arr2[2], 2)), currency) + ' x ' + arr[3] + '%' )
				default:

			}
			break

		case '3.1':
			switch (rowNumber) {
				case 0:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return ('= ' + 'Spend' + ' / ' + 1000 + ' x ' + sourceValue)
				case 1:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return ('= ' + formatThousands(round(sourceValue, 0)))
				case 2:
					return ('= ' + formatThousands(arr2[1]) + ' x ' + formatThousands(arr2[0]))
				case 3:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return ('= ' + formatThousands(round(sourceValue, 0)))
				case 4:
					if (arr2[1] > arr2[3]) {
						return ('= ' + formatThousands(arr2[0]))
					}
					else {
						return ('= ' + formatThousands(arr2[2]) + ' / ' + formatThousands(arr2[3]))
					}
				case 5:
					return ('= ' + formatThousands(arr2[0]) + ' - ' + formatThousands(arr2[4]))
				case 6:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return ('= ' + formatThousands(round(sourceValue, 2)))

				default:

			}
			break

		case '3.2':
			switch (rowNumber) {
				case 0:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return ('= ' + '(' + 'Spend' + ' / ' + 1000+ ')' + ' x ' + sourceValue)
				case 1:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return ('= ' + formatThousands(round(sourceValue, 0)))
				case 2:
					return ('= ' + formatThousands(arr2[1]) + ' x ' + formatThousands(arr2[0]))
				case 3:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return ('= ' + formatThousands(round(sourceValue, 0)))
				case 4:
					return ('= ' + formatThousands(arr2[2]) + ' / ' + formatThousands(arr2[3]))
				case 5:
					return ('= ' + formatThousands(arr2[0]) + ' - ' + formatThousands(arr2[4]))
				case 6:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return ('= ' + formatThousands(round(sourceValue, 2)))

				default:

			}
			break

		case '3.3':
			switch (rowNumber) {
				case 0:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return ('= ' + arr[0] + '%' + ' x ' + 'Revenue')
				case 1:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return ('= '  +  addCurrencyMask((round(arr2[0], 2)), currency) + ' x ' + arr[1] + '%')
				case 2:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return ('= '  +  addCurrencyMask((round(arr2[1], 2)), currency) + ' x ' + arr[2] + '%')

				default:
			}
			break

		case '3.4':
			switch (rowNumber) {
				case 0:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return ('= ' + formatThousands(sourceValue))
				case 1:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return ('= ' + formatThousands(sourceValue))
				case 2:
					return ('= ' + formatThousands(arr2[0]) + ' x ' + formatThousands(arr2[1]) + ' / ' + formatThousands(Math.pow(10, 6)))
				case 3:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return ('= '  +  addCurrencyMask((round(arr2[2], 2)), currency) + ' x ' + arr[3] + '%')

				default:
			}
			break

		case '3.5':
			switch (rowNumber) {
				case 0:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return ('= '  +  addCurrencyMask((round(arr2[0], 2)), currency))
				case 1:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return ('= '  +  addCurrencyMask((round(arr2[0], 2)), currency) + ' x ' + arr[1] + '%')
				case 2:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return ('= '  +  addCurrencyMask((round(arr2[1], 2)), currency) + ' x ' + arr[2] + '%')

				default:
			}
			break

		case '3.6':
			switch (rowNumber) {
				case 0:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return ('= ' + 'Revenue' + ' x ' + round(spendAsPercentageOfRevenue * 100, 2) + '%')
				case 1:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return ('= '  +  addCurrencyMask((round(arr2[0], 2)), currency) + ' x ' + arr[1] + '%')
				case 2:
					if (userInputValue != undefined) {
						return ('= '+ userInputValue + '%' + ' x ' + addCurrencyMask((round(arr2[1], 2)), currency))
					}
					return ('= '  +  addCurrencyMask((round(arr2[1], 2)), currency) + ' x ' + arr[2] + '%')
				case 3:
					if (userInputValue != undefined) {
						return ('= '+ userInputValue + '%' + ' x ' + addCurrencyMask((round(arr2[2], 2)), currency))
					}
					return ('= '  +  addCurrencyMask((round(arr2[2], 2)), currency) + ' x ' + arr[3] + '%')
				case 4:
					if (userInputValue != undefined) {
						return ('= '+ userInputValue + '%' + ' (' + 'User Input Value'+ ')'+ ' x ' + addCurrencyMask((round(arr2[3], 2)), currency))
					}
					return ('= '  +  addCurrencyMask((round(arr2[3], 2)), currency) + ' x ' + arr[4] + '%')
				case 5:
					if (userInputValue != undefined) {
						return ('= '+ userInputValue + '%' + ' (' + 'User Input Value'+ ')'+ ' x ' + addCurrencyMask((round(arr2[4], 2)), currency))
					}
					return ('= '  +  addCurrencyMask((round(arr2[4], 2)), currency) + ' x ' + arr[5] + '%')

				default:
			}
			break

		case '4.1':
			switch (rowNumber) {
				case 0:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return ('= ' + 'Revenue' + ' x ' + round(spendAsPercentageOfRevenue * 100, 2) + '%')
				case 1:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return ('= '  +  addCurrencyMask((round(arr2[0], 2)), currency) + ' x ' + arr[1] + '%')
				case 2:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return ('= '  +  addCurrencyMask((round(arr2[1], 2)), currency) + ' x ' + arr[2] + '%')
				case 3:
					return ('= ' + addCurrencyMask((round(arr2[1], 2)), currency)+ ' - ' + addCurrencyMask((round(arr2[2], 2)), currency))
				case 4:
					if (userInputValue != undefined) {
						return ('= '+ userInputValue + '%' + ' (' + 'User Input Value'+ ')'+ ' x ' + addCurrencyMask((round(arr2[3], 2)), currency))
					}
					return ('= '  +  addCurrencyMask((round(arr2[3], 2)), currency) + ' x ' + arr[4] + '%')
				default:
			}
			break

		case '4.2':
			switch (rowNumber) {
				case 0:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return ('= ' + 'Revenue' + ' x ' + round(spendAsPercentageOfRevenue * 100, 2) + '%')
				case 1:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return ('= '  +  addCurrencyMask((round(arr2[0], 2)), currency) + ' x ' + arr[1] + '%')
				case 2:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return ('= '  +  addCurrencyMask((round(arr2[0], 2)), currency) + ' x ' + arr[2] + '%')
				case 3:
					return ('= ' + addCurrencyMask((round(arr2[0], 2)), currency) + ' - '+ addCurrencyMask((round(arr2[1], 2)), currency) + ' - '+ addCurrencyMask((round(arr2[2], 2)), currency))
				case 4:
					if (userInputValue != undefined) {
						return ('= '+ userInputValue + '%' + ' x ' + addCurrencyMask((round(arr2[3], 2)), currency))
					}
					return ('= '  +  addCurrencyMask((round(arr2[3], 2)), currency) + ' x ' + arr[4] + '%')
				case 5:
					if (userInputValue != undefined) {
						return ('= '+ userInputValue + '%' + ' (' + 'User Input Value'+ ')'+ ' x ' + addCurrencyMask((round(arr2[4], 2)), currency))
					}
					return ('= '  +  addCurrencyMask((round(arr2[4], 2)), currency) + ' x ' + arr[5] + '%')
				default:
			}
			break
		case '4.3':
			switch (rowNumber) {
				case 0:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return ('= ' + 'Revenue' + ' x ' + round(spendAsPercentageOfRevenue * 100, 2) + '%')
				case 1:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return ('= ' + addCurrencyMask((round(arr[1], 2)), currency))
				case 2:
					return ('= ' + addCurrencyMask((round(arr2[0], 2)), currency) + ' - ' + addCurrencyMask((round(arr2[1], 2)), currency))
				case 3:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return ('= '  +  addCurrencyMask((round(arr2[2], 2)), currency) + ' x ' + arr[3] + '%')
				case 4:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return ('= ' + arr[4])
				case 5:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return ('= ' + arr[5])
				case 6:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return ('= '  +  addCurrencyMask((round(arr2[3], 2)), currency) + ' x ' + arr[6] + '%')
				case 7:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return ('= ' + arr[7])
				case 8:
					//return Number(roundFix(((arr[3] / 365) * arr[5]) + ((arr[6] / 365) * arr[7]), 2))
					return('= '+ '(' + addCurrencyMask((round(arr2[3], 2)), currency) + '/' + 365 + ')'
							+ ' x ' + arr2[5] + ' + ' + '(' + '(' + addCurrencyMask((round(arr2[6], 2)), currency) + '/' + 365 + ')' + ' x ' + arr2[7]+ ')')
				case 9:
					if (userInputValue != undefined) {
						return ('= '+ userInputValue + '%' + ' x ' + addCurrencyMask((round(arr2[8], 2)), currency))
					}
					return ('= '  +  addCurrencyMask((round(arr2[8], 2)), currency) + ' x ' + arr[9] + '%')

				default:
			}
			break

		case '4.4':
			switch (rowNumber) {
				case 0:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return ('= ' + round(sourceValue, 0))
				case 1:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return ('= ' + arr[1])
				case 2:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return ('= ' + addCurrencyMask((round(arr[2], 2)), currency))
				case 3:
					return ('= ' + addCurrencyMask((round(arr2[2], 2)), currency) + ' / ' + 365)
				case 4:
					return ('= ' + arr2[1] + ' x ' + addCurrencyMask((round(arr2[3], 2)), currency))
				case 5:
					if (userInputValue != undefined) {
						return ('= '+ userInputValue + '%' + ' x ' + addCurrencyMask((round(arr2[4], 2)), currency))
					}
					return ('= '  +  addCurrencyMask((round(arr2[4], 2)), currency) + ' x ' + arr[5] + '%')
				case 6:
					if (userInputValue != undefined) {
						return ('= '+ userInputValue + '%' + ' x ' + addCurrencyMask((round(arr2[4], 2)), currency))
					}
					return ('= '  +  addCurrencyMask((round(arr2[4], 2)), currency) + ' x ' + arr[6] + '%')
				default:
			}
			break

		case '5.1':
			switch (rowNumber) {

				case 0:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return ('= ' + 'Revenue')

				case 1:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return ('= '  +  addCurrencyMask((round(arr2[0], 2)), currency) + ' x ' + arr[1] + '%')

				case 2:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return ('= '  +  addCurrencyMask((round(arr2[1], 2)), currency) + ' x ' + arr[2] + '%')

				case 3:
					if (userInputValue != undefined) {
						return Number('= User Input Value')
					}
					return ('= '  +  addCurrencyMask((round(arr2[2], 2)), currency) + ' x ' + arr[3] + '%')
				case 4:
					return ('= '  +  addCurrencyMask((round(arr2[3], 2)), currency))
				case 5:
					if (userInputValue != undefined) {
						return ('= '+ userInputValue + '%' + ' (' + 'User Input Value'+ ')'+ ' x ' + addCurrencyMask((round(arr2[4], 2)), currency))
					}
					return ('= '  +  addCurrencyMask((round(arr2[4], 2)), currency) + ' x ' + arr[5] + '%')
				default:
			}
			break

		case '5.2':
			switch (rowNumber) {

				case 0:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return ('= '+'Revenue')
				case 1:
					if (userInputValue != undefined) {
						return ('= User Input Value')
					}
					return ('= '  +  addCurrencyMask((round(revenue, 2)), currency) + ' x ' + arr[1] + '%')
				case 2:
					return ('= ' + addCurrencyMask((round(arr2[1], 2)), currency) + ' / ' + 12)
				case 3:
					if (userInputValue != undefined) {
						return ('= '+ userInputValue  + ' x ' + addCurrencyMask((round(arr2[2], 2)), currency))
					}
					return ('= ' + (round(arr[3], 2)) + ' x ' + addCurrencyMask((round(arr2[2], 2)), currency))
				case 4:
					if (userInputValue != undefined) {
						return ('= '+ userInputValue + ' x ' + addCurrencyMask((round(arr2[2], 2)), currency))
					}
					return ('= ' + round(arr[4], 2) + ' x ' + addCurrencyMask((round(arr2[2], 2)), currency))
				case 5:
					return ('= ' + addCurrencyMask((round(arr2[4], 2)), currency))
				case 6:
					if (userInputValue != undefined) {
						return ('= '+ userInputValue + '%' + ' (' + 'User Input Value'+ ')'+ ' x ' + addCurrencyMask((round(arr2[4], 2)), currency))
					}
					return ('= '  +  addCurrencyMask((round(arr2[4], 2)), currency) + ' x ' + arr[6] + '%')
				default:
			}
			break

		default:
	}
}
