//
//
//
//
//       <- This code is no longer used ->
//
//
//
//
import React, { Component } from 'react'
import { connect } from 'react-redux'
import SearchField from "react-search-field";
import { GoSearch } from "react-icons/lib/go";
import { searchResult, searchText, searchData } from '../../actions/searchActions'
import {
    fetchCrmResults,
    crmSearchLoading,
    crmSearchSuccess,
    crmSearchFailure,
    fetchCapIqSearchResults,
    capIqSearchSuccess,
    capIqSearchFailure
}
    from 'actions/searchActions'

function compare(a, b) {
    // Use toUpperCase() to ignore character casing
    const companyA = a.item.SAP_TOP_VIEW_NAME.toUpperCase();
    const companyB = b.item.SAP_TOP_VIEW_NAME.toUpperCase();

    let comparison = 0;
    if (companyA > companyB) {
        comparison = 1;
    } else if (companyA < companyB) {
        comparison = -1;
    }
    return comparison;
}

class SearchBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: '',
            searchResult: []
        };

        this.onSuggestionSelected = this.onSuggestionSelected.bind(this)
        this.onChange = this.onChange.bind(this)
        this.callSearchResults = this.callSearchResults.bind(this);
    }

    onChange = (e) => {
        this.props.crmSearchFailure('')
        this.setState({
            value: e.target.value
        });
    };

    callSearchResults(searchText) {
        //First call the crm procedure for search data
        this.props.fetchCrmResults(searchText)
            .then((result) => {
                this.props.crmSearchSuccess(result)
                if (result.data.Result.length != 0) {
                    let obj = result.data.Result;
                    this.props.searchData(obj);
                    //this.props.history.push(`/searchContainer`)

                    //This code filters the output with unique Global ultimate Id (i.e. SAP_TOP_VIEW)
                    const uniqueObj = [];
                    const map = new Map();
                    for (const item of obj) {
                        if (!map.has(item.SAP_TOP_VIEW)) {
                            map.set(item.SAP_TOP_VIEW, true);    // set any value to Map
                            uniqueObj.push({ item });
                        }
                    }
                    this.props.searchText(this.state.value)
                    let sortedArr = uniqueObj.sort(compare);
                    this.props.searchResult(sortedArr)
                    this.setState({ searchResult: sortedArr }, this.props.history.push(`/searchContainer`))
                } else {

                }
            }).catch((error) => {
                if (error.response.status == 400) {
                    this.props.fetchCapIqSearchResults(searchText, 1)
                        .then((result) => {
                            this.props.capIqSearchSuccess(result)
                            this.props.searchText(this.state.value)
                            this.props.searchResult(result.data.Result)
                            this.setState({ searchResult: result.data.Result }, this.props.history.push(`/searchContainer`))
                        }).catch((error) => {
                            this.props.capIqSearchFailure(error.response.data.Message)
                        })
                } else {
                    this.props.crmSearchFailure(error.response.data.Message)
                }
            })
    }

    onSuggestionSelected(event) {
        event.preventDefault()
        if (this.state.value != null && this.state.value != '' && this.state.value != undefined) {
            this.props.searchText(this.state.value)
            this.callSearchResults(this.state.value)
        } else {
            this.props.crmSearchFailure(this.props.content.search.required)
        }
        //this.props.history.push(`/searchContainer`)
    }

    render() {
        if ((this.props.search.isLoading)
        ) {
            return <div className="loading loading--is-loading"><div className="loader" />Loading...</div>
        }
        else {
            return (
                <div className='grid-x grid-container'>
                    <p className="search_label">{this.props.content.search.searchBarLabel}</p>
                    <div className="autoSearch">
                        <form onSubmit={this.onSuggestionSelected}>
                            <div className="search-bar">
                                <input type='text' placeholder={this.props.content.search.searchPlaceholder} onChange={this.onChange} value={this.state.value} />
                                <button type='submit'>
                                    <img width="20px" src="/images/search_big_inactive.svg" />
                                </button>
                            </div>
                        </form>
                        <div className="errors">{this.props.search.error}</div>
                    </div>
                </div>
            )
        }

    }
}

const mapStateToProps = (state) => {
    return {
        content: state.content,
        search: state.searchResultData
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        searchResult: (result) => dispatch(searchResult(result)),
        searchText: (text) => dispatch(searchText(text)),
        searchData: (data) => dispatch(searchData(data)),
        fetchCrmResults: (value) => dispatch(fetchCrmResults(value)),
        crmSearchLoading: (bool) => dispatch(crmSearchLoading(bool)),
        crmSearchSuccess: (msg) => dispatch(crmSearchSuccess(msg)),
        crmSearchFailure: (error) => dispatch(crmSearchFailure(error)),
        fetchCapIqSearchResults: (value, type) => dispatch(fetchCapIqSearchResults(value, type)),
        capIqSearchSuccess: (msg) => dispatch(capIqSearchSuccess(msg)),
        capIqSearchFailure: (error) => dispatch(capIqSearchFailure(error))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar)

