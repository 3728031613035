import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { store } from 'index'

// Actions
import {
	updateUserInput,
	setEstimatedAnnualValue,
	updateSubLeverRowValue,
} from 'actions/subLeverCalculationActions'

import { changeLocation, updateTotalSpend } from 'actions/discoveryActions'
import { getSourceValue } from '../SubLeverCalculation/SubLeverFormula'
// Components
import SubLeverCalculationTableHeaders from './SubLeverCalculationTableHeaders'
import SubLeverCalculationTableRows from './SubLeverCalculationTableRows'

// Functions
import {
	calculateSuggestedEstimatedBenefit,
	getCalculatedEstimatedBenefit,
	findDBRowNumber,
	SpendValidation_1,
	SpendValidation_2,
	SpendValidation_3,
	SpendValidation_4,
	SpendValidation_5,
	SpendValidation_6,
	ServiceSpendValidation_1,
	ServiceSpendWithBudgetThresholdsValidation_1,
	ServiceSpendWithBudgetThresholdsValidation_2,
	ServicesSpendNotCompletedWithinBudgetValidation_1,
	IndirectSpendValidation_1,
	IndirectSpendValidation_2,
	IndirectSpendValidation_3,
	IndirectSpendValidation_4,
	IndirectSpendValidation_5,
	IndirectSpendValidation_6,
	IndirectSpendAddressableByStrategicSourcingValidation_1,
	IndirectSpendAddressableByStrategicSourcingValidation_2,
	IndirectSpendAddressableByStrategicSourcingValidation_3,
	IndirectSpendStrategicallySourcedValidation_1,
	DirectSpendValidation_1,
	DirectSpendValidation_2,
	DirectSpendValidation_3,
	DirectSpendValidation_4,
	DirectSpendValidation_5,
	DirectSpendAddressableByStrategicSourcingValidation_1,
	DirectSpendAddressableByStrategicSourcingValidation_2,
	DirectSpendStrategicallySourcedValidation_1,
	IncrementalIndirectSpendStrategicallySourcedValidation_1,
	IndirectSpendAddressableByTacticalSourcingValidation_1,
	IndirectSpendAddressableByProcurementValidation_1,
	IndirectSpendAddressableByProcurementValidation_2,
	IndirectSpendOrignatesFromUserRequisitionValidation_1,
	IndirectSpendAddressableByProcurementNotViaBlanketORPurchaseOrderValidation_1,
	CurrentInvoicesProcessedPerAccountsPayableFullTimeEmployeeValidation_1,
	FutureUnvoicesToBeProcessedPerAccountsPayableFullTimeEmployeeValidation_1,
	AuditCostImpactedBySupplierRiskValidation_1,
	EstimatedTransportationSpendValidation_1,
	InboundExpeditedTransportationSpendValidation_1,
	ExternalWorkforceAndServicesSpendValidation_1,
	ExternalWorkforceAndServicesSpendValidation_2,
	ExternalWorkforceAndServicesSpendValidation_3,
	NonCompliantSpendValidation_1,
	AddressableExternalAndServicesSpendValidation_1,
	SpendWithPreNegotiatedDiscountsValidation_1,
	SpendWithPreNegotiatedDiscountsValidation_2,
	SpendWithPreNegotiatedDiscountsValidation_3,
	SpendWithPreNegotiatedDiscountsValidation_4,
	SpendNotAddressableForExtendingPaymentTermsValidation_1,
	SpendNotAddressableForExtendingPaymentTermsValidation_2,
	SpendToBeAddressedByPaymentTermExtensionValidation_1,
	SpendToBeAddressedByPaymentTermExtensionValidation_2,
	AnnualRevenueValidation_1,
	AnnualRevenueValidation_2,
	AnnualAuditCostValidation_1,
	RevenueLossDueToStockOutsValidation,
	EstimatedAnnualRevenueFromNewProductsValidation,
	dbText
} from './SubLeverCalculations'

// Utils
import {
	round,
	roundFix,
	BreakLine,
	getSubLeverIdObject,
	formatThousands,
	setCurrentSubLeverId,
	getCurrentSubLeverId,
	subLeverHasTotalSpend
} from 'utils/Utils'

// UI
import Button from 'UI/Button'

class SubLeverCalculation extends PureComponent {
	constructor(props) {
		super(props)

		this.state = {
			content: null,
			subleverId: null,
			estimatedBenefit: 0,
			calculatedEstimatedBenefit: 0,
			suggestedValues: [],
			userValues: [],
			calculatedValues: [],
			country: '',
			industry: '',
			responseMsg: '',
			toggleFlag: false,
			calculatedValuesFormulae: []
		}

		this.getPageContent = this.getPageContent.bind(this)
		this.setUserValue = this.setUserValue.bind(this)
		this.setCalculatedValue = this.setCalculatedValue.bind(this)
		this.dispatchHandleChange = this.dispatchHandleChange.bind(this)
		this.updateCalculatedValuesAndEstimatedBenefit = this.updateCalculatedValuesAndEstimatedBenefit.bind(
			this
		)
		this.showFormulae = this.showFormulae.bind(this)
		this.hideFormulae = this.hideFormulae.bind(this)
	}

	getPageContent(content) {
		// Get sub-lever Id from react-router
		// or default to cookie value
		let subleverId = null
		this.props.location.state
			? (subleverId = this.props.location.state.subleverId)
			: (subleverId = getCurrentSubLeverId())

		setCurrentSubLeverId(subleverId)

		// Get sublever content using sub-lever Id
		const data = content.subLeverCalculation
		const index = data.map((i) => i.id).indexOf(subleverId)
		const subleverContent = data[index]
		const storeState = store.getState()
		this.setState({
			content: subleverContent,
			subleverId: subleverId
		}, () => getCalculatedEstimatedBenefit(storeState, subleverId, this))
	}

	setUserValue(index, value) {
		const userValue = value
		let userValues = this.state.userValues
		userValues[index] === undefined ? userValues.push(userValue) : userValues[index] = userValue

		this.setState(
			{
				userValues: userValues
			},
			this.updateCalculatedValuesAndEstimatedBenefit(index, value)
		)
	}

	updateCalculatedValuesAndEstimatedBenefit(index, value) {
		// Updated Calculated Value state
		value === '' || value === null
			? this.setCalculatedValue(index, this.state.suggestedValues[index])
			: this.setCalculatedValue(index, value)

		// Update Calculated Estimated Benefit
		const storeState = store.getState();
		getCalculatedEstimatedBenefit(storeState, this.state.subleverId, this)
	}

	setCalculatedValue(index, value) {
		const calculatedValue = Number(value).toFixed(2)
		let calculatedValues = this.state.calculatedValues
		calculatedValues[index] === undefined
			? calculatedValues.push(calculatedValue)
			: (calculatedValues[index] = calculatedValue)

		this.setState({
			calculatedValues: calculatedValues
		})
	}

	dispatchHandleChange(value, subLeverId, rowNumber) {
		this.setState({ responseMsg: '' })
		const subLeverIdObject = getSubLeverIdObject(subLeverId),
			handleChange = this.props.handleChange

		const subLeverCalculationContent = this.props.content.subLeverCalculation,
			userData = this.props.userData

		// Convert empty string to null
		// Backend has no error handling for empty string
		if (value === '') value = null

		let userInputArray = []
		userInputArray = userData.BusinessValueToYou[0].ValueDriver[subLeverIdObject.BVUIdIndex].SubLever[subLeverIdObject.BVUSVDIdIndex].UserInput
		const target = subLeverCalculationContent.find((obj) => {
			return (subLeverIdObject.BVUIdIndex + 1) + '.' + (subLeverIdObject.BVUSVDIdIndex + 1) === obj.id
		})

		let suggestedValueDescription
		target.rows.forEach((jsonData, index) => {
			if (index === rowNumber) {
				userInputArray.forEach((dbData, dbIndex) => {
					if (jsonData[0].content.toLowerCase() === dbText(dbData, this.props.language).toLowerCase()) {
						suggestedValueDescription = jsonData[0].content
					}
				})
			}
		})

		if (
			(subLeverId === '1.1' && rowNumber === 5) ||
			(subLeverId === '1.3' && rowNumber === 4) ||
			(subLeverId === '2.1' && rowNumber === 4)
		) {
			handleChange(value, getSubLeverIdObject('1.1'), findDBRowNumber(userData, '1.1', suggestedValueDescription))
			handleChange(value, getSubLeverIdObject('1.3'), findDBRowNumber(userData, '1.3', suggestedValueDescription))
			handleChange(value, getSubLeverIdObject('2.1'), findDBRowNumber(userData, '2.1', suggestedValueDescription))
		}
		else if (
			(subLeverId === '1.1' && rowNumber === 1) ||
			(subLeverId === '1.3' && rowNumber === 1) ||
			(subLeverId === '1.4' && rowNumber === 1) ||
			(subLeverId === '2.1' && rowNumber === 1) ||
			(subLeverId === '2.2' && rowNumber === 1)
		) {
			handleChange(value, getSubLeverIdObject('1.1'), findDBRowNumber(userData, '1.1', suggestedValueDescription))
			handleChange(value, getSubLeverIdObject('1.3'), findDBRowNumber(userData, '1.3', suggestedValueDescription))
			handleChange(value, getSubLeverIdObject('1.4'), findDBRowNumber(userData, '1.4', suggestedValueDescription))
			handleChange(value, getSubLeverIdObject('2.1'), findDBRowNumber(userData, '2.1', suggestedValueDescription))
			handleChange(value, getSubLeverIdObject('2.2'), findDBRowNumber(userData, '2.2', suggestedValueDescription))
		}
		else if (
			(subLeverId === '1.1' && rowNumber === 2) ||
			(subLeverId === '1.3' && rowNumber === 2)
		) {
			handleChange(value, getSubLeverIdObject('1.1'), findDBRowNumber(userData, '1.1', suggestedValueDescription))
			handleChange(value, getSubLeverIdObject('1.3'), findDBRowNumber(userData, '1.3', suggestedValueDescription))
		}
		else if (
			(subLeverId === '1.1' && rowNumber === 7) ||
			(subLeverId === '1.3' && rowNumber === 5) ||
			(subLeverId === '2.1' && rowNumber === 5)
		) {
			handleChange(value, getSubLeverIdObject('1.1'), findDBRowNumber(userData, '1.1', suggestedValueDescription))
			handleChange(value, getSubLeverIdObject('1.3'), findDBRowNumber(userData, '1.3', suggestedValueDescription))
			handleChange(value, getSubLeverIdObject('2.1'), findDBRowNumber(userData, '2.1', suggestedValueDescription))
		}
		else if (
			(subLeverId === '1.2' && rowNumber === 1) ||
			(subLeverId === '4.4' && rowNumber === 2)
		) {
			handleChange(value, getSubLeverIdObject('1.2'), findDBRowNumber(userData, '1.2', suggestedValueDescription))
			handleChange(value, getSubLeverIdObject('4.4'), findDBRowNumber(userData, '4.4', suggestedValueDescription))
		}
		else if (
			(subLeverId === '1.5' && rowNumber === 1) ||
			(subLeverId === '2.3' && rowNumber === 1) ||
			(subLeverId === '3.6' && rowNumber === 1)
		) {
			handleChange(value, getSubLeverIdObject('1.5'), findDBRowNumber(userData, '1.5', suggestedValueDescription))
			handleChange(value, getSubLeverIdObject('2.3'), findDBRowNumber(userData, '2.3', suggestedValueDescription))
			handleChange(value, getSubLeverIdObject('3.6'), findDBRowNumber(userData, '3.6', suggestedValueDescription))
		}
		else if (
			(subLeverId === '4.3' && rowNumber === 9) ||
			(subLeverId === '4.4' && rowNumber === 5)
		) {
			handleChange(value, getSubLeverIdObject('4.3'), findDBRowNumber(userData, '4.3', suggestedValueDescription))
			handleChange(value, getSubLeverIdObject('4.4'), findDBRowNumber(userData, '4.4', suggestedValueDescription))
		}
		else if (
			(subLeverId === '5.1' && rowNumber === 0) ||
			(subLeverId === '5.2' && rowNumber === 0)
		) {
			handleChange(value, getSubLeverIdObject('5.1'), findDBRowNumber(userData, '5.1', suggestedValueDescription))
			handleChange(value, getSubLeverIdObject('5.2'), findDBRowNumber(userData, '5.2', suggestedValueDescription))
		}
		else if (
			(subLeverId === '5.1' && rowNumber === 5) ||
			(subLeverId === '5.2' && rowNumber === 6)
		) {
			handleChange(value, getSubLeverIdObject('5.1'), findDBRowNumber(userData, '5.1', suggestedValueDescription))
			handleChange(value, getSubLeverIdObject('5.2'), findDBRowNumber(userData, '5.2', suggestedValueDescription))
		}
		else {
			handleChange(value, getSubLeverIdObject(subLeverId), findDBRowNumber(userData, subLeverId, suggestedValueDescription))
		}

		/*
		 * Use last user input of Total Spend in any sub lever with Total Spend row, as the value for all sub lever calculations.
		 * If there is no user input for total spend, the default is the calculated Total Spend value from Let's Get Started section
		 */
		if (rowNumber === 0 && subLeverHasTotalSpend(subLeverId)) {
			this.setState({ render: false })

			// Update Total Spend rows for all sub levers
			this.props.content.subLeverCalculation.forEach(function (obj) {

				const subLever = getSubLeverIdObject(obj.id)

				if (((subLeverIdObject.BVUIdIndex !== subLever.BVUIdIndex) || (subLeverIdObject.BVUSVDIdIndex !== subLever.BVUSVDIdIndex))
					&& subLeverHasTotalSpend(obj.id)) {
					handleChange(value, subLever, 0)
				}
			})

			// Update Total Spend in state
			// if value is input is not empty
			if (value !== '' && value !== null) {
				this.props.updateTotalSpend(value)
			} else {
				// Update Total Spend in state
				// with default calculated value (Revenue * Industry BM percentage)
				const totalSpend = round(this.props.spendAsPercentageOfRevenue * this.props.userData.AnnualRevenue, 2)
				this.props.updateTotalSpend(totalSpend)
			}

			this.setState({ render: true })
		}
		// Updated User Input Value in local state
		this.setUserValue(rowNumber, value)

		/* ***************************************** Validations: Negotiate better pricing and conditions ************************************************* */

		if (rowNumber === 0 && subLeverHasTotalSpend(subLeverId)) {
			if (SpendValidation_1(subLeverId, subLeverCalculationContent)) {
				this.setState({ responseMsg: this.props.content.suggestedWarnings.warning1 })
			}
		}
		if (rowNumber === 1 && (subLeverId === '1.1' || subLeverId === '1.3' || subLeverId === '1.4' || subLeverId === '2.1' || subLeverId === '2.2')) {
			if (IndirectSpendValidation_1(rowNumber, subLeverId, subLeverCalculationContent)) {
				this.setState({ responseMsg: this.props.content.suggestedWarnings.warning2 })
			}
			else if (IndirectSpendValidation_2(rowNumber, subLeverId, subLeverCalculationContent)) {
				this.setState({ responseMsg: this.props.content.suggestedWarnings.warning1 })
			}
		}
		if (rowNumber === 1 && (subLeverId === '1.1' || subLeverId === '1.3')) {
			if (IndirectSpendValidation_3(rowNumber, subLeverId, subLeverCalculationContent)) {
				this.setState({ responseMsg: this.props.content.suggestedWarnings.warning3 })
			}
		}
		if (rowNumber === 1 && subLeverId === '1.4') {
			if (IndirectSpendValidation_4(rowNumber, subLeverId, subLeverCalculationContent)) {
				this.setState({ responseMsg: this.props.content.suggestedWarnings.warning4 })
			}
		}
		if (rowNumber === 1 && subLeverId === '2.1') {
			if (IndirectSpendValidation_5(rowNumber, subLeverId, subLeverCalculationContent)) {
				this.setState({ responseMsg: this.props.content.suggestedWarnings.warning5 })
			}
		}
		if (rowNumber === 1 && subLeverId === '2.2') {
			if (IndirectSpendValidation_6(rowNumber, subLeverId, subLeverCalculationContent)) {
				this.setState({ responseMsg: this.props.content.suggestedWarnings.warning6 })
			}
		}
		if (rowNumber === 2 && (subLeverId === '1.1' || subLeverId === '1.3')) {
			if (IndirectSpendAddressableByStrategicSourcingValidation_1(rowNumber, subLeverId, subLeverCalculationContent)) {
				this.setState({ responseMsg: this.props.content.suggestedWarnings.warning3 })
			}
		}
		if (rowNumber === 2 && subLeverId === '1.1') {
			if (IndirectSpendAddressableByStrategicSourcingValidation_2(rowNumber, subLeverId, subLeverCalculationContent)) {
				this.setState({ responseMsg: this.props.content.suggestedWarnings.warning7 })
			}
		}
		if (rowNumber === 2 && subLeverId === '1.3') {
			if (IndirectSpendAddressableByStrategicSourcingValidation_3(rowNumber, subLeverId, subLeverCalculationContent)) {
				this.setState({ responseMsg: this.props.content.suggestedWarnings.warning8 })
			}
		}
		if (rowNumber === 3 && subLeverId === '1.1') {
			if (IndirectSpendStrategicallySourcedValidation_1(rowNumber, subLeverId, subLeverCalculationContent)) {
				this.setState({ responseMsg: this.props.content.suggestedWarnings.warning7 })
			}
		}
		if (rowNumber === 1 && subLeverId === '1.2') {
			if (DirectSpendValidation_1(rowNumber, subLeverId, subLeverCalculationContent)) {
				this.setState({ responseMsg: this.props.content.suggestedWarnings.warning9 })
			}
			else if (DirectSpendValidation_2(rowNumber, subLeverId, subLeverCalculationContent)) {
				this.setState({ responseMsg: this.props.content.suggestedWarnings.warning1 })
			}
			else if (DirectSpendValidation_3(rowNumber, subLeverId, subLeverCalculationContent)) {
				this.setState({ responseMsg: this.props.content.suggestedWarnings.warning10 })
			}
		}
		if (rowNumber === 2 && subLeverId === '1.2') {
			if (DirectSpendAddressableByStrategicSourcingValidation_1(rowNumber, subLeverId, subLeverCalculationContent)) {
				this.setState({ responseMsg: this.props.content.suggestedWarnings.warning10 })
			}
			else if (DirectSpendAddressableByStrategicSourcingValidation_2(rowNumber, subLeverId, subLeverCalculationContent)) {
				this.setState({ responseMsg: this.props.content.suggestedWarnings.warning11 })
			}
		}
		if (rowNumber === 3 && subLeverId === '1.2') {
			if (DirectSpendStrategicallySourcedValidation_1(rowNumber, subLeverId, subLeverCalculationContent)) {
				this.setState({ responseMsg: this.props.content.suggestedWarnings.warning11 })
			}
		}
		if (rowNumber === 3 && subLeverId === '1.3') {
			if (IncrementalIndirectSpendStrategicallySourcedValidation_1(rowNumber, subLeverId, subLeverCalculationContent)) {
				this.setState({ responseMsg: this.props.content.suggestedWarnings.warning8 })
			}
		}
		if (rowNumber === 2 && subLeverId === '1.4') {
			if (IndirectSpendAddressableByTacticalSourcingValidation_1(rowNumber, subLeverId, subLeverCalculationContent)) {
				this.setState({ responseMsg: this.props.content.suggestedWarnings.warning4 })
			}
		}
		if (rowNumber === 0 && subLeverId === '1.5') {
			if (SpendValidation_3(rowNumber, subLeverId, subLeverCalculationContent)) {
				this.setState({ responseMsg: this.props.content.suggestedWarnings.warning22 })
			}
		}
		if (rowNumber === 1 && subLeverId === '1.5') {
			if (ExternalWorkforceAndServicesSpendValidation_1(rowNumber, subLeverId, subLeverCalculationContent)) {
				this.setState({ responseMsg: this.props.content.suggestedWarnings.warning22 })
			}
			else if (ExternalWorkforceAndServicesSpendValidation_2(rowNumber, subLeverId, subLeverCalculationContent)) {
				this.setState({ responseMsg: this.props.content.suggestedWarnings.warning23 })
			}
		}
		if (rowNumber === 2 && subLeverId === '1.5') {
			if (AddressableExternalAndServicesSpendValidation_1(rowNumber, subLeverId, subLeverCalculationContent)) {
				this.setState({ responseMsg: this.props.content.suggestedWarnings.warning23 })
			}
		}
		if (rowNumber === 0 && subLeverId === '1.6') {
			if (SpendValidation_4(rowNumber, subLeverId, subLeverCalculationContent)) {
				this.setState({ responseMsg: this.props.content.suggestedWarnings.warning24 })
			}
		}
		if (rowNumber === 1 && subLeverId === '1.6') {
			if (ServiceSpendValidation_1(rowNumber, subLeverId, subLeverCalculationContent)) {
				this.setState({ responseMsg: this.props.content.suggestedWarnings.warning24 })
			}
		}
		if (rowNumber === 2 && subLeverId === '1.6') {
			if (ServiceSpendWithBudgetThresholdsValidation_1(rowNumber, subLeverId, subLeverCalculationContent)) {
				this.setState({ responseMsg: this.props.content.suggestedWarnings.warning25 })
			}
			else if (ServiceSpendWithBudgetThresholdsValidation_2(rowNumber, subLeverId, subLeverCalculationContent)) {
				this.setState({ responseMsg: this.props.content.suggestedWarnings.warning26 })
			}
		}
		if (rowNumber === 3 && subLeverId === '1.6') {
			if (ServicesSpendNotCompletedWithinBudgetValidation_1(rowNumber, subLeverId, subLeverCalculationContent)) {
				this.setState({ responseMsg: this.props.content.suggestedWarnings.warning26 })
			}
		}

		/* ***************************************** Validations: Buy and pay at the negotiated savings rate ************************************************* */

		if (rowNumber === 2 && subLeverId === '2.1') {
			if (IndirectSpendAddressableByProcurementValidation_1(rowNumber, subLeverId, subLeverCalculationContent)) {
				this.setState({ responseMsg: this.props.content.suggestedWarnings.warning5 })
			}
			else if (IndirectSpendAddressableByProcurementValidation_2(rowNumber, subLeverId, subLeverCalculationContent)) {
				this.setState({ responseMsg: this.props.content.suggestedWarnings.warning12 })
			}
		}
		if (rowNumber === 3 && subLeverId === '2.1') {
			if (IndirectSpendOrignatesFromUserRequisitionValidation_1(rowNumber, subLeverId, subLeverCalculationContent)) {
				this.setState({ responseMsg: this.props.content.suggestedWarnings.warning12 })
			}
		}
		if (rowNumber === 2 && subLeverId === '2.2') {
			if (IndirectSpendAddressableByProcurementNotViaBlanketORPurchaseOrderValidation_1(rowNumber, subLeverId, subLeverCalculationContent)) {
				this.setState({ responseMsg: this.props.content.suggestedWarnings.warning6 })
			}
		}
		if (rowNumber === 0 && subLeverId === '2.3') {
			if (SpendValidation_5(rowNumber, subLeverId, subLeverCalculationContent)) {
				this.setState({ responseMsg: this.props.content.suggestedWarnings.warning22 })
			}
		}
		if (rowNumber === 1 && subLeverId === '2.3') {
			if (ExternalWorkforceAndServicesSpendValidation_1(rowNumber, subLeverId, subLeverCalculationContent)) {
				this.setState({ responseMsg: this.props.content.suggestedWarnings.warning22 })
			}
			else if (ExternalWorkforceAndServicesSpendValidation_3(rowNumber, subLeverId, subLeverCalculationContent)) {
				this.setState({ responseMsg: this.props.content.suggestedWarnings.warning27 })
			}
		}
		if (rowNumber === 2 && subLeverId === '2.3') {
			if (NonCompliantSpendValidation_1(rowNumber, subLeverId, subLeverCalculationContent)) {
				this.setState({ responseMsg: this.props.content.suggestedWarnings.warning27 })
			}
		}

		/* ***************************************** Validations: Lower Costs ************************************************* */

		if (rowNumber === 1 && subLeverId === '3.2') {
			if (CurrentInvoicesProcessedPerAccountsPayableFullTimeEmployeeValidation_1(rowNumber, subLeverId, subLeverCalculationContent)) {
				this.setState({ responseMsg: this.props.content.suggestedWarnings.warning13 })
			}
		}
		if (rowNumber === 3 && subLeverId === '3.2') {
			if (FutureUnvoicesToBeProcessedPerAccountsPayableFullTimeEmployeeValidation_1(rowNumber, subLeverId, subLeverCalculationContent)) {
				this.setState({ responseMsg: this.props.content.suggestedWarnings.warning13 })
			}
		}
		if (rowNumber === 0 && subLeverId === '3.3') {
			if (AnnualAuditCostValidation_1(rowNumber, subLeverId, subLeverCalculationContent)) {
				this.setState({ responseMsg: this.props.content.suggestedWarnings.warning14 })
			}
		}
		if (rowNumber === 1 && subLeverId === '3.3') {
			if (AuditCostImpactedBySupplierRiskValidation_1(rowNumber, subLeverId, subLeverCalculationContent)) {
				this.setState({ responseMsg: this.props.content.suggestedWarnings.warning14 })
			}
		}
		if (rowNumber === 0 && subLeverId === '3.5') {
			if (EstimatedTransportationSpendValidation_1(rowNumber, subLeverId, subLeverCalculationContent)) {
				this.setState({ responseMsg: this.props.content.suggestedWarnings.warning15 })
			}
		}
		if (rowNumber === 1 && subLeverId === '3.5') {
			if (InboundExpeditedTransportationSpendValidation_1(rowNumber, subLeverId, subLeverCalculationContent)) {
				this.setState({ responseMsg: this.props.content.suggestedWarnings.warning15 })
			}
		}
		if (rowNumber === 0 && subLeverId === '3.6') {
			if (SpendValidation_6(rowNumber, subLeverId, subLeverCalculationContent)) {
				this.setState({ responseMsg: this.props.content.suggestedWarnings.warning22 })
			}
		}
		if (rowNumber === 1 && subLeverId === '3.6') {
			if (ExternalWorkforceAndServicesSpendValidation_1(rowNumber, subLeverId, subLeverCalculationContent)) {
				this.setState({ responseMsg: this.props.content.suggestedWarnings.warning22 })
			}
		}

		/* ***************************************** Validations: Free up working capital ************************************************* */

		if (rowNumber === 0 && subLeverId === '4.1') {
			if (SpendWithPreNegotiatedDiscountsValidation_1(rowNumber, subLeverId, subLeverCalculationContent)) {
				this.setState({ responseMsg: this.props.content.suggestedWarnings.warning16 })
			}
		}
		if (rowNumber === 0 && subLeverId === '4.2') {
			if (SpendWithPreNegotiatedDiscountsValidation_2(rowNumber, subLeverId, subLeverCalculationContent)) {
				this.setState({ responseMsg: this.props.content.suggestedWarnings.warning16 })
			}
		}
		if (rowNumber === 1 && subLeverId === '4.1') {
			if (SpendWithPreNegotiatedDiscountsValidation_3(rowNumber, subLeverId, subLeverCalculationContent)) {
				this.setState({ responseMsg: this.props.content.suggestedWarnings.warning16 })
			}
		}
		if (rowNumber === 1 && subLeverId === '4.2') {
			if (SpendWithPreNegotiatedDiscountsValidation_4(rowNumber, subLeverId, subLeverCalculationContent)) {
				this.setState({ responseMsg: this.props.content.suggestedWarnings.warning16 })
			}
		}
		if (rowNumber === 0 && subLeverId === '4.3') {
			if (SpendValidation_2(rowNumber, subLeverId, subLeverCalculationContent)) {
				this.setState({ responseMsg: this.props.content.suggestedWarnings.warning17 })
			}
		}
		if (rowNumber === 1 && subLeverId === '4.3') {
			if (SpendNotAddressableForExtendingPaymentTermsValidation_1(rowNumber, subLeverId, subLeverCalculationContent)) {
				this.setState({ responseMsg: this.props.content.suggestedWarnings.warning18 })
			}
			else if (SpendNotAddressableForExtendingPaymentTermsValidation_2(rowNumber, subLeverId, subLeverCalculationContent)) {
				this.setState({ responseMsg: this.props.content.suggestedWarnings.warning17 })
			}
		}
		if (rowNumber === 2 && subLeverId === '4.4') {
			if (DirectSpendValidation_4(rowNumber, subLeverId, subLeverCalculationContent)) {
				this.setState({ responseMsg: this.props.content.suggestedWarnings.warning9 })
			}
			else if (DirectSpendValidation_5(rowNumber, subLeverId, subLeverCalculationContent)) {
				this.setState({ responseMsg: this.props.content.suggestedWarnings.warning1 })
			}
		}
		if (rowNumber === 3 && subLeverId === '4.3') {
			if (SpendToBeAddressedByPaymentTermExtensionValidation_1(rowNumber, subLeverId, subLeverCalculationContent)) {
				this.setState({ responseMsg: this.props.content.suggestedWarnings.warning19 })
			}
			else if (SpendToBeAddressedByPaymentTermExtensionValidation_2(rowNumber, subLeverId, subLeverCalculationContent)) {
				this.setState({ responseMsg: this.props.content.suggestedWarnings.warning17 })
			}
		}
		/* ***************************************** Validations: Protect your Revenue ************************************************* */

		if (rowNumber === 0 && subLeverId === '5.1') {
			if (AnnualRevenueValidation_1(rowNumber, subLeverId, subLeverCalculationContent)) {
				this.setState({ responseMsg: this.props.content.suggestedWarnings.warning20 })
			}
		}
		if (rowNumber === 1 && subLeverId === '5.1') {
			if (RevenueLossDueToStockOutsValidation(rowNumber, subLeverId, subLeverCalculationContent)) {
				this.setState({ responseMsg: this.props.content.suggestedWarnings.warning20 })
			}
		}
		if (rowNumber === 0 && subLeverId === '5.2') {
			if (AnnualRevenueValidation_2(rowNumber, subLeverId, subLeverCalculationContent)) {
				this.setState({ responseMsg: this.props.content.suggestedWarnings.warning21 })
			}
		}
		if (rowNumber === 1 && subLeverId === '5.2') {
			if (EstimatedAnnualRevenueFromNewProductsValidation(rowNumber, subLeverId, subLeverCalculationContent)) {
				this.setState({ responseMsg: this.props.content.suggestedWarnings.warning21 })
			}
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		return nextState.render
	}

	componentWillReceiveProps(nextProps) {
		// Update content and benchmarksData on language change
		if (this.props.content !== nextProps.content || this.props.benchMarkCalculations!== nextProps.benchMarkCalculations || this.props.spendAsPercentageOfRevenue !== nextProps.spendAsPercentageOfRevenue) {
			this.getPageContent(nextProps.content)
			this.getCountryIndustry(nextProps)
		}
	}

	componentWillMount() {
		window.scrollTo(0, 0)
		this.getPageContent(this.props.content)
		this.getCountryIndustry(this.props)
	}

	getCountryIndustry(props) {

		var country = props.allContent.discovery.form.locations[props.userData.Location]
		var industry = props.allContent.discovery.form.industries[props.userData.Industry]
		var spend = props.allContent.subLeverCalculations.subLeverCalculation[0].rows[0][0].content
		var result = props.allContent.discovery.form.regions.find(region => region.Country.toLowerCase() == country.toLowerCase())
		var region = result.Region;
		this.setState({ country: country })
		this.setState({ industry: industry })
		var spendSourceValue = getSourceValue(3, country, region, industry, spend)
		this.props.changeLocation(props.userData.Location, spendSourceValue)
	}

	componentDidMount() {
		const storeState = store.getState()
		calculateSuggestedEstimatedBenefit(storeState)
	}

	showFormulae(){
		this.setState((prevState) => { return { toggleFlag: !prevState.toggleFlag}})
	}

	hideFormulae(){
		this.setState((prevState) => { return { toggleFlag: !prevState.toggleFlag}})
	}

	render() {

		// const subLeverIdObject = getSubLeverIdObject(this.state.content.id)

		// const userInputArray = this.props.userData.BusinessValueToYou[0].ValueDriver[subLeverIdObject.BVUIdIndex].SubLever[subLeverIdObject.BVUSVDIdIndex].UserInput

		// let suggestedValArr = []
		// this.state.content.rows.forEach((jsonData, index) => {
		// 	userInputArray.forEach((dbData, dbIndex) => {
		// 		if (jsonData[0].content.toLowerCase() === dbData.Item.toLowerCase()) {
		// 			suggestedValArr.push(this.state.suggestedValues[dbIndex])
		// 		}
		// 	})
		// })

		return (
			<div className="sublever-calculation grid-container">
				<div className="grid-x pt20">
					<div className="medium-12 cell">
						<div className="page-title">{BreakLine(this.state.content.title)}</div>
						{this.state.responseMsg !== '' ?
							<span className="errors warning_help">{this.state.responseMsg}</span>
							: null}
						<table>
							<thead>
								<SubLeverCalculationTableHeaders 
									content={this.state.content.tableHeaders} 
									flag ={this.state.toggleFlag}
									hide ={this.hideFormulae}
									show ={this.showFormulae} 
								/>
							</thead>
							<SubLeverCalculationTableRows
								subleverId={this.state.subleverId}
								content={this.state.content.rows}
								currency={this.props.currency}
								savedData={this.state.userValues}
								estimatedBenefit={this.state.content.estimatedBenefit}
								revenue={this.props.userData.AnnualRevenue}
								suggestedValues={this.state.suggestedValues}
								calculatedValues={this.state.calculatedValues}
								calculatedValuesFormulae={this.state.calculatedValuesFormulae}
								spendAsPercentageOfRevenue={this.props.spendAsPercentageOfRevenue}
								dispatchHandleChange={this.dispatchHandleChange}
								country={this.state.country}
								industry={this.state.industry}
								suggestedValuePopup={this.props.content.suggestedValuePopup}
								benchMarkCalculations={this.props.benchMarkCalculations}
								flag={this.state.toggleFlag}
							/>
							<tfoot>
								<tr className="estimated-benefit text-orange">
									<td>{this.state.content.estimatedBenefit}</td>
									<td>
										<span>
											{this.props.currency}
											{formatThousands(roundFix(this.state.estimatedBenefit, 2))} M
										</span>
									</td>
									<td>&nbsp;</td>
									<td>
										{this.props.currency}
										{formatThousands(roundFix(this.state.calculatedEstimatedBenefit, 2))} M
									</td>
								</tr>
							</tfoot>
						</table>
					</div>
				</div>
				<div className="grid-x grid">
					<div className="medium-12 cell">
						<Link to='/business-value'>
							<Button content={this.props.content.back} float />
						</Link>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		content: state.content.subLeverCalculations,
		ui: state.content.ui,
		currency: decodeURIComponent(state.valueStory.Currency),
		userData: state.valueStory,
		spendAsPercentageOfRevenue: state.spendAsPercentageOfRevenue,
		benchMarkCalculations: state.benchMarksData,
		allContent: state.content,
		language: state.language
	}
}

const mapDispatchToProps = dispatch => {
	return {
		handleChange: (value, subLeverId, rowNumber) =>
			dispatch(updateUserInput(value, subLeverId, rowNumber)),
		updateSubLeverRowValue: (value, subLeverId, rowNumber, valueType) =>
			dispatch(updateSubLeverRowValue(value, subLeverId, rowNumber, valueType)),
		updateTotalSpend: spend => dispatch(updateTotalSpend(spend)),
		setEstimatedAnnualValue: (value, subLeverId) => dispatch(setEstimatedAnnualValue(value, subLeverId)),
		changeLocation: (location, spendAsPercentageOfRevenue) => dispatch(changeLocation(location, spendAsPercentageOfRevenue)),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SubLeverCalculation)
