

import React from 'react'
import { connect } from 'react-redux'
import {
  defineScopeLoading,
  defineScopeSuccess,
  defineScopeFailure,
  getTopDetails
  //, getSupplierScopeDetails
} from 'actions/spendActions'
import Select from 'react-select'
import { convertToMillion, formatThousands } from 'utils/Utils'

class SupplierScopeDetails extends React.Component {
  constructor(props) {
    super(props)
    {
      this.state = {
        industry: '',
        industryOption: [],
        supplierData: [],
        supplierDetails: [],
        currencySymbol: ''

      }
    }
  }

  componentDidMount() {
    //this.loadInitialData()
    window.scrollTo(0, 0);
    this.setState({
      currencySymbol: this.props.spendDetails.currentActiveRequest !== undefined ?
        this.props.currencySymbols[this.props.spendDetails.currentActiveRequest.Currency.trim()] : ''
    });
  }

  componentWillReceiveProps(nextProps) {
    //if (nextProps.data !== this.props.data) {
    //Perform some operation
    this.setState({ supplierData: nextProps.data, supplierDetails: nextProps.data.sort((a, b) => a.RANK - b.RANK).slice(0, 20) });
    //}
  }

  // loadInitialData () {
  //   var array = [
  //     { label: 'Top 10 Supplier', value: '10' },
  //     { label: 'Top 20 Supplier', value: '20' }
  //   ]
  //   this.setState({ industryOption: array, industry: array[1].label })
  // }

  // handleIndustryChange (event) {
  //   var sortData = this.props.data.sort((a,b) => a.RANK-b.RANK).slice(0, event.value);   

  //   this.setState({ industry: event.label, supplierDetails: sortData })
  // }

  render() {
    var _this = this;
    if (this.state.supplierDetails != undefined) {
      var currencySymbol = this.state.currencySymbol
      var rows = this.state.supplierDetails.map(function (row) {
        return (
          <Rows
            currencySymbol={currencySymbol}
            user={_this.props.user.user}
            content={_this.props.content}
            rowData={row}
            showSupplierDrilldownModal={_this.props.showSupplierDrilldownModal}
            showSupplierIndustriesModal={_this.props.showSupplierIndustriesModal}
          />
        )
      })
    }

    return (
      <div className='basic-table'>
        <table className='custScroll'>
          <thead>
            <tr>
              <th width='45%' className="tableHeader">{this.props.content.suppliers}</th>
              <th className="column-align-right pr8 tableHeader" width='18%'>{this.props.content.invoiceSpend}</th>
              <th className="column-align-right pr12 tableHeader" width='18%'>{this.props.content.invoiceCount}</th>
              <th className="column-align-right pr20 tableHeader" width='18%'>{this.props.content.poCount}</th>
            </tr>
          </thead>
          <tbody className='medium-12'>{rows}</tbody>
        </table>
      </div>
    )
  }
}

class Rows extends React.Component {
  constructor(props) {
    super(props)
    {
    }
  }
  render() {
    return (
      <tr className='eachRow'>
        <td className='tableContent'>
          <span className='onMouseOver' style={{ cursor: 'pointer' }} width='45%' onClick={() => this.props.showSupplierDrilldownModal(this.props.rowData.DATA_COLLECTIONS_SKEY, this.props.rowData.CAPIQ_ID, this.props.rowData.PARENT_CAPIQ_ID)}>{this.props.rowData.SUPPLIER_NAME}</span>
          {" : "}
          <span className='onMouseOver' style={{ cursor: 'pointer' }} width='45%' onClick={() => this.props.showSupplierIndustriesModal(this.props.rowData, this.props.rowData.INDUSTRY_DESC, this.props.rowData.INDUSTRY_LEVEL)}>{(this.props.rowData.INDUSTRY_DESC !== null && this.props.rowData.INDUSTRY_DESC !== undefined) ? this.props.rowData.INDUSTRY_DESC : null}
            {(this.props.rowData.INDUSTRY_LEVEL !== null && this.props.rowData.INDUSTRY_LEVEL !== undefined) ? " (L" + this.props.rowData.INDUSTRY_LEVEL + ")" : null}
          </span>
        </td>
        <td className="column-align-right pr36 tableContent" width='18%'>{this.props.currencySymbol}{convertToMillion(this.props.rowData.INVOICE_SPEND, true)}</td>
        <td className="column-align-right pr12 tableContent" width='18%'>{formatThousands(Math.round(this.props.rowData.INVOICE_COUNT))}</td>
        <td className="column-align-right pr20 tableContent" width='16%'>{formatThousands(Math.round(this.props.rowData.PO_COUNT))}</td>
      </tr>
    )
  }
}


const mapStateToProps = state => {
  return {
    // language: state.language,
    // ui: state.content.ui,
    user: state.default,
    spendDetails: state.spendDetails,
    content: state.content.spendAnalysis.defineScope,
    validation: state.content.validation,
    currencySymbols: state.content.CurrencySymbols
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    defineScopeLoading: (bool) => dispatch(defineScopeLoading(bool)),
    defineScopeSuccess: (bool) => dispatch(defineScopeSuccess(bool)),
    defineScopeFailure: (error) => dispatch(defineScopeFailure(error))
    //,getSupplierScopeDetailsgetSupplierScopeDetails: (formValues) => dispatch(getSupplierScopeDetails(formValues))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SupplierScopeDetails)