export const UPDATE_VALUESTORYNAME = 'UPDATE_VALUESTORYNAME'
export const UPDATE_CAPIQID = 'UPDATE_CAPIQID'
export const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION'
export const CHANGE_LOCATION = 'CHANGE_LOCATION'
export const CHANGE_INDUSTRY = 'CHANGE_INDUSTRY'
export const UPDATE_CURRENCY = 'UPDATE_CURRENCY'
export const ADD_REVENUE = 'ADD_REVENUE'
export const UPDATE_TOTAL_SPEND = 'UPDATE_TOTAL_SPEND'
export const GO_TO_CHOOSE_PATH = 'GO_TO_CHOOSE_PATH'
export const UPDATE_BPID = 'UPDATE_BPID'
export const UPDATE_ANID = 'UPDATE_ANID'
export const UPDATE_VALUESTORYTYPE = 'UPDATE_VALUESTORYTYPE'

export const updateValueStoryName = (valuestoryName) => {
	return {
		type: 'UPDATE_VALUESTORYNAME',
		valuestoryName
	}
}

export const updateCapIQId = (capiqid) => {
	return {
		type: 'UPDATE_CAPIQID',
		capiqid
	}
}

export const updateOrganization = (organization) => {
	return {
		type: 'UPDATE_ORGANIZATION',
		organization
	}
}

export const changeLocation = (location, spendAsPercentageOfRevenue) => {
	return {
		type: 'CHANGE_LOCATION',
		location,
		spendAsPercentageOfRevenue
	}
}

export const changeIndustry = (industry, spendAsPercentageOfRevenue) => {
	return {
		type: 'CHANGE_INDUSTRY',
		industry: industry,
		spendAsPercentageOfRevenue: spendAsPercentageOfRevenue
	}
}

export const updateCurrency = (currency) => {
	return {
		type: 'UPDATE_CURRENCY',
		currency
	}
}

export const updateCurrencyCode = (currencyCode) => {
	return {
		type: 'UPDATE_CURRENCY_CODE',
		currencyCode
	}
}

export const addRevenue = (revenue) => {
	return {
		type: 'ADD_REVENUE',
		revenue
	}
}

export const updateTotalSpend = (spend) => {
	return {
		type: 'UPDATE_TOTAL_SPEND',
		spend
	}
}

export const goToChoosePath = (bool) => {
	return {
		type: 'GO_TO_CHOOSE_PATH',
		payload: bool
	}
}

export const capIQFetchData = (capIQData) => {
	return {
		type: 'CAPIQ_FETCH_DATA',
		capIQData
	}
}

export const updateBPID = (bpId) => {
	return {
		type: 'UPDATE_BPID',
		bpId
	}
}

export const updateANID = (anId) => {
	return {
		type: 'UPDATE_ANID',
		anId
	}
}

export const changeIndirectSolutionScope = (value) => {
	return {
		type: 'CHANGE_INDIRECTSCOPE',
		indirectSolutionScope: value		
	}
}

export const updateValueStoryType = (valueStoryType) => {
	return {
		type: 'UPDATE_VALUESTORYTYPE',
		valueStoryType
	}
}

export function isValueStoryPage(obj) {
	return {
	  type: 'GET_IS_VALUE_STORY_PAGE',
	  payload: obj
	};
  }

  export function saveValueStoryOnRedirect(changes) {
	return {
	  type: 'SaveValueStoryOnRedirect-Changes',
	  payload: changes
	};
  }