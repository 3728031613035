'use strict'
import { Link } from 'react-router-dom'
import React from 'react'
class Marquee extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
        totalDisplays:10,
        display1:'a',
        display2:'b',
        display3:'c',
        display4:'d',
        display5:'e',
        display6:'f',
        display7:'g',
        display8:'h',
        display9:'i',
        display10:'j',
        changeTime:1000,
        crossTime:10000,
        randomDisplayChange:false,
        htmlTag:'h1',
        color:'purple',
        currentlyDisplayedIndex: 1,
        currentlyDisplayedData: 'loading...',
        actionOnTextClick: ''
      };
      this.marqueeDisplayChange = this.marqueeDisplayChange.bind(this);
  }

  componentDidMount(){
    if(this.props.IsRandom === "false"){
      var rand = false
    }else{
      var rand = true
    }
    this.setState({
      currentlyDisplayed: this.props.Index0,
      totalDisplays: this.props.NumberOfOptions || 1,
      changeTime: this.props.TimeToChange || 2000,
      crossTime: this.props.TimeToCross || 8000,
      randomDisplayChange:  rand || false,
      htmlTag: this.props.Size || 'h3',
      color: this.props.Color || 'purple',
      actionOnTextClick : this.props.Action || ''
    })
    this.props.Index0 ? this.setState({display1: this.props.Index0}) : delete this.state.display1
    this.props.Index1 ? this.setState({display2: this.props.Index1}) : delete this.state.display2
    this.props.Index2 ? this.setState({display3: this.props.Index2}) : delete this.state.display3
    this.props.Index3 ? this.setState({display4: this.props.Index3}) : delete this.state.display4
    this.props.Index4 ? this.setState({display5: this.props.Index4}) : delete this.state.display5
    this.props.Index5 ? this.setState({display6: this.props.Index5}) : delete this.state.display6
    this.props.Index6 ? this.setState({display7: this.props.Index6}) : delete this.state.display7
    this.props.Index7 ? this.setState({display8: this.props.Index7}) : delete this.state.display8
    this.props.Index8 ? this.setState({display9: this.props.Index8}) : delete this.state.display9
    this.props.Index9 ? this.setState({display10: this.props.Index9}) : delete this.state.display10

    setTimeout(function() { this.marqueeDisplayChange() }.bind(this), 50);
  }

    marqueeDisplayChange () {
      if(this.state.randomDisplayChange){

        var that = this
        var intervalTime = this.state.changeTime

        setInterval(function() {
          var randomIndex = Math.floor((Math.random() * that.state.totalDisplays) + 1)
          that.setState({
            currentlyDisplayedData: that.state["display"+randomIndex]
          })
        }, intervalTime);

      }else{

        var that = this
        var intervalTime = this.state.changeTime

        setInterval(function() {
          var nextIndex = that.state.currentlyDisplayedIndex
          var updateStateWith = nextIndex + 1
          if(updateStateWith == (Number(that.state.totalDisplays)+1)){
            updateStateWith = 1
          }else{
            updateStateWith = nextIndex + 1
          }
          that.setState({currentlyDisplayedData: that.state["display"+nextIndex],currentlyDisplayedIndex: updateStateWith})
        }, intervalTime);

      }
    }

    render() {
      var marquee = this.state.currentlyDisplayedData
      //this.props.user.error ="test error from nav";
      // let style1 =  {margin: '0 auto',whiteSpace: 'nowrap',overflow: 'hidden'}
      // let style2 = {display: 'inline-block',paddingLeft: '100%',animation: 'marquee '+Number(this.state.crossTime)+'ms linear infinite',color: this.state.color}

      return (

        <div id="marquee" class="marquee">
           {this.state.actionOnTextClick === '' ? 
            
            <h3 class="marquee"  href={this.state.actionOnTextClick}>{ marquee } 
             
            </h3>
           : 
           
            <Link class="marquee" to={{ pathname: '/login', state: { foo: 'bar'} }}  onClick={this.props.Action}>{ marquee } </Link>
           }
           
         
        </div>

      );
    }
}

export default Marquee;
