import React from 'react'
import { connect } from 'react-redux'
import * as d3 from 'd3'
import Select from 'react-select'
import { path } from 'Constants'
import lineChart from './KPILineChart'
import ChartTable from './KPIChartTable'
import {
    resetError,
    defineScopeLoading    
} from 'actions/spendActions'

import { getKPIChartDataV2,
    getKPIDashBoardLoadDataV2,
    trackKpiBenchMarkFilters,
    getKPISetUpLoadData
} from 'actions/kpiActions'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';


class KPIChartsV2 extends React.Component {
    constructor(props) {
        super(props)
        this.state = { 
            selectedMetric: 'Invoice Only',           
            currencySymbols : this.props.currencySymbols[this.props.spendDetails.currentActiveRequest.Currency.trim()],
            isLoading: false,
            tableDataActual_Estimate: [],
            tableDataPlanned: [],
            focusOption :  [
                { key: 'KPI SUPPLIER', value: 'KPI1', legend: 'KPI Suppliers', estLegend: 'SE Estimated', actualLegend: 'SE Targeted', header: 'Suppliers' },
                { key: 'KPI SPEND', value: 'KPI1', legend: 'KPI Spend', estLegend: 'SE Estimated', actualLegend: 'SE Targeted', header: 'Spend' },
                { key: 'KPI DOCUMENT', value: 'KPI1', legend: 'KPI Documents', estLegend: 'SE Estimated', actualLegend: 'SE Targeted', header: 'Documents' }
            ],
            metricOption : [            
                { label: 'Invoice Only', value: 'Invoice Only' },            
                { label: 'PO Only', value: 'PO Only' },
                { label: 'Invoice & PO', value: 'Invoice & PO' }
            ],
            benchmarkPercentileValues: [
                { value: 'Top 25%', label: 'Top 25%' },
                { value: 'Average 50%', label: 'Average 50%' },
                { value: 'Bottom 25%', label: 'Bottom 25%' }
            ],
            tabs: [
                { label : 'Invoice Spend'},
                { label : 'Invoice Count'},
                { label : 'PO Spend'     },
                { label : 'PO Count'     },
                { label : 'Suppliers'    }
            ],
            selectedBPValue: 'Average 50%',
            metricValues: [],
            selectedMetricValue: 'ALL',    
            selectedBPFlag: 'AVERAGE',
            currentTabIndex : 0,
            kpiBenchMarkFilters: {metric: 'ALL', type: 'AVERAGE'},
            chart_label : '',
            svg_label : '',
            selectedTabLabel : 'Invoice Spend'   
        }
        // this.handleBPChange = this.handleBPChange.bind(this);
        // this.handleBenchMarkMetricChange = this.handleBenchMarkMetricChange.bind(this);
    }
     
    handleTabChange = (e, tabIndex) => {
      //console.log(tabIndex); 
      var metric = (tabIndex == 0 || tabIndex == 1) ? 'Invoice Only' : (tabIndex == 2 || tabIndex == 3) ? 'PO Only' : 'Invoice Only';
      var tabLabel = this.state.tabs[tabIndex].label;
      this.setState({currentTabIndex: tabIndex ,selectedMetric: metric,selectedTabLabel:tabLabel}, this.loadKPIChart);      
    };

    componentDidMount() {
        // To load KPI chart we need to first call KPI setup and KPI dashboard
        //this.loadKPISetup();
        //this.loadKPIDashboard(); 
        this.loadKPIChart();       
    }

    loadKPISetup() {
        let dmcId = this.props.spendDetails.currentActiveRequest.DMC_ID;
        let vsId = this.props.spendDetails.currentActiveRequest.VS_ID;
        let user = this.props.user.user.Email;
        this.props.defineScopeLoading(true)
        this.setState({ isLoading: true })
        this.props.getKPISetUpLoadData(dmcId, vsId, user)
            .then((response) => {
                if (response.data.Result.length > 0) {
                    let spData = response.data.Result;
                    let unique = spData.filter(item => item.BENCHMARK_METRICS == this.state.selectedMetricValue)
                    //https://stackoverflow.com/questions/15125920/how-to-get-distinct-values-from-an-array-of-objects-in-javascript
                    // let unique = [...new Map(temp.map(item =>
                    //     [item['METRIC_ID'], item])).values()];

                    let uniqueMetrics = [...new Set(spData.map(item => item.BENCHMARK_METRICS))];
                    let metricValues = [];
                    for (let i = 0; i < uniqueMetrics.length; i++) {
                        let obj = {}
                        obj.label = uniqueMetrics[i]
                        obj.value = uniqueMetrics[i]

                        metricValues.push(obj)
                    }
                    this.setState({ spData: spData, metricValues: metricValues })

                } else {
                    this.props.defineScopeLoading(false)
                    this.setState({ errorMsg: 'No Records Found. Please navigate to the Your Spend File Tab then Enablement Strategy Wizard Sub-tab then navigate back to this page. The data will appear if the data does not appear email sap_ism_value_experience@sap.com.', isError: 'Yes', isLoading: false })
                }

            }).catch((error) => {
                if (error.stack.includes('Network Error')) {
                    this.props.defineScopeLoading(false)
                    this.setState({ errorMsg: 'API service is down. Please contact administrator.', isError: 'Yes', isLoading: false })
                }
                else {
                    this.props.defineScopeLoading(false)
                    this.setState({ errorMsg: this.props.content.error, isError: 'Yes', isLoading: false })
                }
            })
    }

    loadKPIDashboard(){
        let dmcId = this.props.spendDetails.currentActiveRequest.DMC_ID;
        let vsId = this.props.spendDetails.currentActiveRequest.VS_ID;
        let user = this.props.user.user.Email;

        this.props.defineScopeLoading(true)
        this.setState({ isLoading: true })
        this.props.getKPIDashBoardLoadDataV2(dmcId, vsId, user)
            .then((response) => {
                if (response.data.Result.length > 0) {
                    let spData = response.data.Result;
                    //let tableData = this.createTableData(spData);
                    this.props.defineScopeLoading(false)
                    this.setState({ isLoading: false }, this.loadKPIChart)
                } else {
                    this.props.defineScopeLoading(false)
                    this.setState({ errorMsg: 'No Records Found. Please navigate to the Your Spend File Tab then Enablement Strategy Wizard Sub-tab then navigate back to this page. The data will appear if the data does not appear email sap_ism_value_experience@sap.com.', isError: 'Yes', isLoading: false })
                }

            }).catch((error) => {
                if (error.stack.includes('Network Error')) {
                    this.props.defineScopeLoading(false)
                    this.setState({ errorMsg: 'API service is down. Please contact administrator.', isError: 'Yes', isLoading: false })
                }
                else {
                    this.props.defineScopeLoading(false)
                    this.setState({ errorMsg: this.props.content.error, isError: 'Yes', isLoading: false })
                }
            })
    }

    loadKPIChart() {
        let dmcId = this.props.spendDetails.currentActiveRequest.DMC_ID;
        let vsId = this.props.spendDetails.currentActiveRequest.VS_ID;
        let user = this.props.user.user.Email;
        let metric = this.props.kpiFilters !== undefined ? this.props.kpiFilters.metric : this.state.selectedMetricValue; 
        let type = this.props.kpiFilters !== undefined ? this.props.kpiFilters.type : this.state.selectedBPFlag; 
        let metricType = this.state.selectedMetric ;

        this.props.defineScopeLoading(true)
        this.setState({ isLoading: true })
        this.props.getKPIChartDataV2(dmcId, vsId, user, metricType)
            .then((response) => {
                if (response.data.Result.length > 0) {
                    var tab = this.state.currentTabIndex;
                    var focus_opt = (tab == 0 || tab == 2) ? this.state.focusOption[1] : (tab == 1 || tab == 3) ? this.state.focusOption[2]: this.state.focusOption[0];
                    var svg_label = (tab == 0 || tab == 2) ? '"KPI_SPEND"' : (tab == 1 || tab == 3) ? '"KPI_DOCUMENT"' : '"KPI_SUPPLIER"';
                    //let spData = response.data.Result.filter(x=>x.CHART_LABEL == focus_opt.key); 
                    let spData = response.data.Result;      
                    let tableDataActual_Estimate = this.createTableData(spData);
                    this.setState({tableDataActual_Estimate: tableDataActual_Estimate,svg_label:svg_label})
                    
                    // draw chart for each
                    
                    // focus_opt.forEach(element => {
                    //     this.drawLineChart(element, spData); 
                    // });
                    this.drawLineChart(focus_opt, spData,'chart1'); 
                    this.drawLineChart(focus_opt, spData,'chart2'); 
                    
                    this.setState({ isLoading: false})
                    this.props.defineScopeLoading(false)
                } else {
                    this.props.defineScopeLoading(false)
                    this.setState({ errorMsg: 'No Records Found. Please navigate to the Your Spend File Tab then Enablement Strategy Wizard Sub-tab then navigate back to this page. The data will appear if the data does not appear email sap_ism_value_experience@sap.com.', isError: 'Yes', isLoading: false })
                }

            }).catch((error) => {
                if (error.stack.includes('Network Error')) {
                    this.props.defineScopeLoading(false)
                    this.setState({ errorMsg: 'API service is down. Please contact administrator.', isError: 'Yes', isLoading: false })
                }
                else {
                    this.props.defineScopeLoading(false)
                    this.setState({ errorMsg: this.props.content.error, isError: 'Yes', isLoading: false })
                }
            })
    }

    createTableData(spData) {
        let tableData = [];
        var tab = this.state.currentTabIndex;

        //create an array with unique kpi names from sp data
        let kpiArr = [...new Set(spData.map(item => item.CHART_LABEL))];

        var chart_label = (tab == 0 || tab == 2) ? 'KPI SPEND' : (tab == 1 || tab == 3) ? 'KPI DOCUMENT': 'KPI SUPPLIER';
                    

        // //for every unique kpi name create table data for 12 quaters
        // for (let i = 0; i < kpiArr.length; i++) {
        //     let temp = spData.filter(item => item.CHART_LABEL == kpiArr[i])
        //     let obj = this.createTableDataObject(temp)
        //     tableData.push(obj)
        // }
        let temp = spData.filter(item => item.CHART_LABEL == chart_label)
        let obj = this.createTableDataObject(temp)
        tableData.push(obj)

        //tableData.sort((a, b) => (a.srNo > b.srNo) ? 1 : -1)//sort the table always by sr_no        
        return tableData;
    }

    createTableDataObject(kpiArr) {
        let obj = {}
        
        for (let i = 0; i < kpiArr.length; i++) {
            obj.VS_ID = kpiArr[i]['VS_ID']
            obj.DMC_ID = kpiArr[i]['DMC_ID']
            //obj.kpi = kpiArr[i]['KPI_NAME']
            obj.QUARTER_NO = kpiArr[i]['QUARTER_NO']
            obj.CHART_LABEL = kpiArr[i]['CHART_LABEL']
            obj.HR = 'hr'
           
            for (let j = 1; j <= 12; j++) {    
                if (kpiArr[i]['QUARTER_NO'] == 'Q' + j) obj['KPI_VALUE_Q' + j] = kpiArr[i]['KPI_VALUE']
                if (kpiArr[i]['QUARTER_NO'] == 'Q' + j) obj['ESTIMATED_VALUE_Q' + j] = kpiArr[i]['ESTIMATED_VALUE']
                if (kpiArr[i]['QUARTER_NO'] == 'Q' + j) obj['ACTUAL_VALUE_Q' + j] = kpiArr[i]['ACTUAL_VALUE']
                if (kpiArr[i]['QUARTER_NO'] == 'Q' + j) obj['PLANNED_VALUE_Q' + j] = kpiArr[i]['PLANNED_VALUE']                 
              }
            }
        //}
        return obj;
    }

    drawLineChart(element, spData, chart)
    {
        element.value = chart == 'chart1' ? 'KPI1' : 'KPI2';
        element.header = this.state.selectedTabLabel + (chart == 'chart1' ? ' Actual & Estimate' : ' Planned for Enablement');
        var lineChartData = spData.filter(d=>d.CHART_LABEL=== element.key)        
        //this.setState({ chartData: lineChartData })                    
        d3.select("#"+ element.value +"").selectAll("g").remove();
        d3.select("#"+ element.value +"").selectAll("path").remove();

        var kpiMaxValue = lineChartData.reduce((max, p) => p.KPI_VALUE > max ? p.KPI_VALUE : max, lineChartData[0].KPI_VALUE);
        var actualMaxValue = lineChartData.reduce((max, p) => p.ACTUAL_VALUE > max ? p.ACTUAL_VALUE : max, lineChartData[0].ACTUAL_VALUE);
        var estimatedMaxValue = lineChartData.reduce((max, p) => p.ESTIMATED_VALUE > max ? p.ESTIMATED_VALUE : max, lineChartData[0].ESTIMATED_VALUE);
        var plannedMaxValue = lineChartData.reduce((max, p) => p.PLANNED_VALUE > max ? p.PLANNED_VALUE : max, lineChartData[0].PLANNED_VALUE);

        //var YMaxValue = (kpiMaxValue > actualMaxValue) ? (kpiMaxValue > estimatedMaxValue ? kpiMaxValue : estimatedMaxValue) : (actualMaxValue > estimatedMaxValue ? actualMaxValue : estimatedMaxValue);
        var YMaxValueChart1 = (estimatedMaxValue > actualMaxValue) ? estimatedMaxValue : actualMaxValue;
        var YMaxValueChart2 = (kpiMaxValue > plannedMaxValue) ?  kpiMaxValue : plannedMaxValue;

        if (chart == 'chart1') {
            lineChart(true, lineChartData, '#f0ab00', 'ACTUAL_VALUE', element, this.state.currencySymbols, "", YMaxValueChart1) //#f0ab00 //#f0d737
            lineChart(false, lineChartData, '#008fd3', 'ESTIMATED_VALUE', element, this.state.currencySymbols, "", YMaxValueChart1)
        }
        else {
            lineChart(true, lineChartData, '#000000', 'KPI_VALUE', element, this.state.currencySymbols, "", YMaxValueChart2) //#4a4a4a //#f07e37
            lineChart(false, lineChartData, '#c7c7c7', 'PLANNED_VALUE', element, this.state.currencySymbols, "", YMaxValueChart2)
        }
    }

    handleMetricChange(value)
    {
        this.setState({selectedMetric: value.label}, this.loadKPIChart);
    }

    render() {
        return (
            <div className="grid-x">
                  <div className="medium-12">
                    {(this.state.isLoading) ?
                        <img style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={path + `images/ajax-loader.gif`} alt="" />
                        : null
                    }
                    {(this.state.isError === 'Yes') ?
                        <div style={{ color: 'red', textAlign: 'center' }} className="cell pt5">{this.state.errorMsg}</div>
                        : <div style={{ color: 'green', textAlign: 'center' }} className="cell pt5">{this.state.responseMsg}</div>
                    }
                </div>

                <Tabs value={this.state.currentTabIndex} onChange={this.handleTabChange}>
                {
                                    this.state.tabs.map((tab, i) => {
                                        var disabledTab = this.state.selectedTabLabel === tab.label ? true : false;
                                        return (<Tab
                                            label={tab.label}
                                            disabled={disabledTab}                          
                                        />)
                                    })
                                }
                    
                </Tabs>

                    {/* <div className="medium-12">
                           
                            <div className="float-left width170 ">
                            <div className="dropDownHeader">{this.props.kpiLables.metricType}</div>
                                <Select
                                        autocomplete="true"
                                        value={this.state.metricOption.filter(option => option.label === this.state.selectedMetric)}
                                        onChange={(value) => this.handleMetricChange(value)}
                                        options={this.state.metricOption}
                                        isSearchable={false}
                                        className="border-with-DD dropDownContent"
                                    />
                            </div>
                    </div>          */}

                { this.state.errorMsg === undefined ?
                <div id="chart" className="medium-12 cell"> 
                    <div className="grid-x medium-12 pb20">                    
                        {/* <div className="medium-12" style={{ textAlign: 'center', fontWeight: 'bold'}}>Suppliers</div>                   */}
                        <div className="grid-x medium-12" style={{ paddingLeft: '45px' }}>
                            {/* <svg id="KPI_SUPPLIER" style={{ 'overflow': 'inherit' }}> */}
                            
                            <svg id="KPI1" style={{ 'overflow': 'inherit' }}>
                            </svg>
                        </div>
                        <div className="grid-x medium-11" style={{ paddingTop: '80px', width:'1350px' }}> 
                            {/* <ChartTable data={this.state.tableData.filter(d=>d.CHART_LABEL === 'KPI SUPPLIER')} type={"Suppliers"} currencySymbols={this.state.currencySymbols}></ChartTable> */}
                            <ChartTable data={this.state.tableDataActual_Estimate} keyFilter={'chart1'}  type={this.state.selectedTabLabel} currencySymbols={this.state.currencySymbols}></ChartTable>
                        </div> 
                    </div>


                    <div className="grid-x medium-12 pb20">                    
                        {/* <div className="medium-12" style={{ textAlign: 'center', fontWeight: 'bold'}}>Suppliers</div>                   */}
                        <div className="grid-x medium-12" style={{ paddingLeft: '45px' }}>
                            {/* <svg id="KPI_SUPPLIER" style={{ 'overflow': 'inherit' }}> */}
                            
                            <svg id="KPI2" style={{ 'overflow': 'inherit' }}>
                            </svg>
                        </div>
                        <div className="grid-x medium-11" style={{ paddingTop: '80px', width:'1350px' }}> 
                            {/* <ChartTable data={this.state.tableData.filter(d=>d.CHART_LABEL === 'KPI SUPPLIER')} type={"Suppliers"} currencySymbols={this.state.currencySymbols}></ChartTable> */}
                            <ChartTable data={this.state.tableDataActual_Estimate} keyFilter={'chart2'} type={this.state.selectedTabLabel} currencySymbols={this.state.currencySymbols}></ChartTable>
                        </div> 
                    </div>
{/* 
                    <div className="grid-x medium-12 pb20">                    
                        <div className="medium-12" style={{ paddingLeft: '45px' }}>
                            <svg id="KPI_SPEND" style={{ 'overflow': 'inherit' }}>
                            </svg>
                        </div>
                        
                        <div className="medium-11" style={{ paddingTop: '80px', width:'1350px' }}>
                            <ChartTable data={this.state.tableData.filter(d=>d.CHART_LABEL === 'KPI SPEND')} type={"Spend"} currencySymbols={this.state.currencySymbols}></ChartTable>
                        </div>
                        
                    </div>

                    
                    <div className="grid-x medium-12">                        
                        <div className="medium-12" style={{ paddingLeft: '45px' }}>
                            <svg id="KPI_DOCUMENT" style={{ 'overflow': 'inherit' }}>
                            </svg>
                        </div>
                        <div className="medium-11" style={{ paddingTop: '80px', width:'1350px' }}>
                            <ChartTable data={this.state.tableData.filter(d=>d.CHART_LABEL === 'KPI DOCUMENT')} type={"Documents"} currencySymbols={this.state.currencySymbols}></ChartTable>
                        </div>                    
                    </div> */}
                </div>
                : null }

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        userData: state.valueStory,
        user: state.default,
        focusLabel: state.content.spendAnalysis.enablement.focusLabel,
        spendDetails: state.spendDetails,
        currencySymbols: state.content.CurrencySymbols,
        content: state.content,
        ui: state.content.ui,
        industryNetworkStats: state.content.spendAnalysis.industryDrilldown.industryNetworkStats,
        kpiFilters: state.kpis.kpiBenchMarkFilters,
        kpiLables: state.content.kpiTab
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        resetError: error => dispatch(resetError(error)),
        defineScopeLoading: bool => dispatch(defineScopeLoading(bool)),
        getKPIChartDataV2: (DMCId, VSId, userEmail, metricType) => dispatch(getKPIChartDataV2(DMCId, VSId, userEmail, metricType)),
        getKPIDashBoardLoadDataV2: (DMCId, VSId, userEmail) => dispatch(getKPIDashBoardLoadDataV2(DMCId, VSId, userEmail)),
        trackKpiBenchMarkFilters: obj => dispatch(trackKpiBenchMarkFilters(obj)),
        getKPISetUpLoadData: (DMCId, VSId, userEmail) => dispatch(getKPISetUpLoadData(DMCId, VSId, userEmail)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(KPIChartsV2)