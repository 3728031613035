import React from 'react'
import SubLeverCalculationTableRow from './SubLeverCalculationTableRow'
import ExpansionPanel from '@material-ui/core/ExpansionPanel';


const SubLeverCalculationTableRows = (props) =>
	
		<tbody>
			{props.content.map((data, i) =>

				<SubLeverCalculationTableRow
					key={i}
					subleverId={props.subleverId}
					rowNumber={i}
					content={data}
					currency={props.currency}
					savedData={props.savedData}
					revenue={props.revenue}
					suggestedValue={props.suggestedValues[i]}
					calculatedValue={props.calculatedValues[i]}
					calculatedValuesFormulae={props.calculatedValuesFormulae[i]}
					spendAsPercentageOfRevenue={props.spendAsPercentageOfRevenue}
					dispatchHandleChange={props.dispatchHandleChange}
					country={props.country}
					industry={props.industry}
					suggestedValuePopup={props.suggestedValuePopup}
					benchMarkCalculations = { props.benchMarkCalculations}
					flag={props.flag}
				/>
			)}
		</tbody>
	

export default SubLeverCalculationTableRows
