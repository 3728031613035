import React, { Fragment } from 'react';
import { connect } from 'react-redux'
import SubTabsPanel from '../Tabs/SubTabsPanel'
import BusinessCaseBenchmark from 'components/Benchmark/BusinessCaseBenchmark'
import SpendKPIBenchmark from 'components/Benchmark/SpendKPIBenchmark'

class BenchmarkContainer extends React.Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef()
        this.state = {
            tabId: 1,
            tabs: []
        }
        this.dispatchTabChange = this.dispatchTabChange.bind(this)
        this.intializeSupplierTabs = this.intializeSupplierTabs.bind(this)
    }

    componentDidMount() {
        this.intializeSupplierTabs();
    }

    intializeSupplierTabs() {
        let tabs = [];
        tabs = [{
            id: 1,
            label: "KPI",
            component: (<SpendKPIBenchmark />)
        },
        {
            id: 2,
            label: "Business Case",
            component: (<BusinessCaseBenchmark />)
        }
        ];
        this.setState({
            tabs: tabs,
            tabId: 1
        })
    }

    dispatchTabChange(tabId) {
        this.setState({
            tabId: tabId,
        })
    }

    render() {
        return (
            <div className="grid-x">
                <div className="medium-12">
                    <div className="pt20 benchmark-container">
                        {<SubTabsPanel
                            tabs={this.state.tabs}
                            tabId={1}
                            dispatchTabChange={this.dispatchTabChange}
                            dispatchLastButtonClick={this.dispatchLastButtonClick}
                        />}
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BenchmarkContainer); 