import React, { Component } from 'react'
import * as d3 from 'd3'
import { convertToMillion, formatThousands, handleSingleDoubleClickSameElement } from 'utils/Utils'
import { uid } from 'react-uid'



class TreeMap extends Component {
  _isMounted =  false;
  constructor(props) {
    super(props)
    this.state = {}
    // this.data = this.data.bind(this)
    this.onClickRect = this.onClickRect.bind(this)

  }

  componentWillMount = props => {
    this.clickTimeout = null
  }
  
  componentDidMount() {
    this._isMounted = true;
    this.chart(this.props)
  }

  componentWillReceiveProps(nextProps ) {
    if (nextProps.data !== this.props.data && this._isMounted  ) {
      //Perform some operation
      this.chart(nextProps);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  treemap = data => {
    var sortBy = this.props.sortBy
    return d3
      .treemap()
      .tile(d3.treemapSquarify)
      .size([this.width(), this.height()])
      .padding(1)
      .round(true)(
        d3
          .hierarchy(data)
          .sum(d => this.getMetricsTypeDetails(d))
          .sort((a, b) => {
            d3.ascending(parseFloat(a.data[sortBy]), parseFloat(b.data[sortBy]))
              ||
              d3.ascending(a.data[sortBy], b.data[sortBy]);
          }
          )
        //.sort((a, b) => b.value - a.value)
      )
  }

  tile = () => {
    return 20
  }
  width = () => {
    return 1060
  }
  height = () => {
    return 500
  }
  format = (value, withDecimal = false) => {
    //return d3.format(value, ',d')
    //console.log(this.props.currencySymbols + (value) + " M")
    
    if(this.props.selectedMetrics === "Invoice Count" || this.props.selectedMetrics==="PO Count"){
      return formatThousands(value)+''
    }

    if (!withDecimal) {
      return this.props.currencySymbols + convertToMillion(value)
    }
    else {
      return this.props.currencySymbols + convertToMillion(value, true)
    }
  }

  onClickRect(param) {
    this.props.onClick(param);
    //console.log('onClickRect-->', param)
  }

  getPercentageValue(invoiceSpend, totalInvoiceSpend, decimalcount) {
    return Math.round((invoiceSpend * 100) / totalInvoiceSpend, decimalcount)
  }

  getMetricsTypeDetails(data)
  {
    
    var metricsData = ""    
    switch(this.props.selectedMetrics){
          case("Invoice Spend"):
              metricsData = data.invoiceSpend;
             // selectedMetricsTotal = data.
          break;

          case("Invoice Count"):
              metricsData = data.invoiceCount
          break;

          case("PO Spend"):
              metricsData = data.poSpend
          break;

          case("PO Count"):
              metricsData = data.poCount
          break;
    }
   return metricsData;
  }

  
  getSelectedMetricsTotalValue(data)
  {
    var selectedMetricsTotal =""
    switch(this.props.selectedMetrics){
          case("Invoice Spend"):
          selectedMetricsTotal = data.invoice_spend;
             // selectedMetricsTotal = data.
          break;

          case("Invoice Count"):
          selectedMetricsTotal = data.invoice_count
          break;

          case("PO Spend"):
          selectedMetricsTotal = data.po_spend
          break;

          case("PO Count"):
          selectedMetricsTotal = data.po_count
          break;
    }
   return selectedMetricsTotal;
  }

  chart = (props) => {
    var instance = this
    d3.select("#treeMap").selectAll("svg").remove();
    const root = instance.treemap(props.data)
    var color = d3.scaleOrdinal(d3.schemeCategory10)

    // Define the div for the tooltip
    d3.select('#tooltipdiv').remove();
    var div = d3.select("body").on("click", () => {
      div.transition()
        .duration(100)
        .style("opacity", 0);
    }).append("div").attr("id", 'tooltipdiv')
      .attr("class", "tooltip")
      .style("opacity", 0);

    const svg = d3
      .select('#treeMap')
      .append('svg')
      .attr('viewBox', [0, 0, instance.width(), instance.height()])
      .style('font', 'sans-serif')


    const leaf = svg
      .selectAll('g')
      .data(root.leaves())
      .join('g')
      .attr('transform', d => `translate(${d.x0},${d.y0})`)

    leaf.on('mouseover', (param, index) => {
      leaf.style('opacity', '0.5');
      leaf._groups[0][index].setAttribute('style', { 'opacity': 1 });
    })

    leaf.on('mouseout', (param, index) => {
      leaf.style('opacity', '1');
    })

    /*if (props.enableOnClick) {
      leaf.on('click', param => {
        if (param.data.clickable) {
          instance.onClickRect(param)
        }
        div.transition()
          .duration(100)
          .style("opacity", 0);
      })
        .style('cursor', 'pointer')
    }*/

    leaf.on('click', param => handleSingleDoubleClickSameElement(() => {
      if (props.enableOnClick && param.data.clickable) {
        instance.onClickRect(param)
      }
      div.transition()
        .duration(100)
        .style("opacity", 0);
    }, () => {
      props.onDoubleClick(param);
    }, instance))
      .style('cursor', 'pointer')

    // leaf.append("title").text(
    //   d =>
    //     `${d
    //       .ancestors()[0].data.name
    //     //.reverse()
    //     //.map(d => d.data.name)
    //     //.join('/')
    //     }\n${instance.format(d.value)}`
    // ).attr("fill", "pink").attr("class", "tip") 

    leaf
      .append('rect')
      .attr('id', d => (d.leafUid = uid('leaf')).id)
      .attr('fill', d => {
        return d.children ? null : color(d.data.name + instance.getMetricsTypeDetails(d.data))
        //while (d.depth > 1) d = d.parent
      })
      .attr('fill-opacity', 0.8)
      .attr('width', d => (d.x1 - d.x0))
      .attr('height', d => (d.y1 - d.y0))
      .on("mouseover", function (d) {
        //d3.select('#tooltipdiv').remove();
        div.transition()
          .duration(200)
          .style("opacity", 1).style("cursor", 'pointer').style("display", 'block');
        // div.html(d.data.name + "<br/>" + instance.format(instance.getMetricsTypeDetails(d.data), true).concat(" (",
        //   instance.getPercentageValue(d.data.invoiceSpend < 0 ? 0 : d.value, props.totalInvoicSpend)).concat("%)"))
          div.html(d.data.name + "<br/>" + instance.format(instance.getMetricsTypeDetails(d.data), true).concat(" (",
          instance.getPercentageValue(instance.getMetricsTypeDetails(d.data) < 0 ? 0 : instance.getMetricsTypeDetails(d.data), instance.getSelectedMetricsTotalValue(props.totalValues))).concat("%)"))
          .style("left", (d3.event.pageX) + "px")
          .style("top", (d3.event.pageY - 28) + "px")
          .style("z-index", 1);
      })
      .on("mouseout", function (d) {
        div.transition()
          .duration(100)
          .style("opacity", 0);
      });


    leaf
      .append('clipPath')
      .attr('id', d => (d.clipUid = uid('clip')).id)
      .append('use')
      .attr('xlink:href', d => d.leafUid.href).attr("fill", "pink")

    leaf
      .append('text')
      .style('font-size', function (d) {
        let width = d.x1 - d.x0;
        let height = d.y1 - d.y0;
        if (width < 100 || height < 100) return "0.8rem"
        else return "1.5rem"
      })
      .attr('clip-path', d => d.clipUid)

      .selectAll('tspan')
      .data(d =>
        d.data.name.split(" ").concat(instance.format(instance.getMetricsTypeDetails(d.data)).
          concat(" (",
            instance.getPercentageValue(instance.getMetricsTypeDetails(d.data) < 0 ? 0 : instance.getMetricsTypeDetails(d.data), instance.getSelectedMetricsTotalValue(props.totalValues))).concat("%)"))
      )
      .join('tspan')
      .attr('x', 3)
      .attr(
        'y',
        (d, i, nodes) => `${(i === nodes.length - 1) * 0.3 + 1.1 + i * 0.9}em`
      )
      .attr('fill-opacity', (d, i, nodes) =>
        i === nodes.length - 1 ? 0.7 : null
      ).text(d => d).style('cursor','pointer')

    var p = d3.selectAll("tspan");
    p.style("fill", "white");

    var txt = d3.selectAll("text")
      .on("mouseover", function (d) {
        div.transition()
          .duration(200)
          .style("opacity", 1);
        if (d.data !== undefined && d.data.name !== undefined) {
          div.html(d.data.name + "<br/>" + instance.format(instance.getMetricsTypeDetails(d.data), true).concat(" (",
          //instance.getPercentageValue(d.value, props.totalInvoicSpend)).concat("%)"))
          instance.getPercentageValue(instance.getMetricsTypeDetails(d.data) < 0 ? 0 :
          instance.getMetricsTypeDetails(d.data), instance.getSelectedMetricsTotalValue(props.totalValues))).concat("%)"))
            .style("left", (d3.event.pageX) + "px")
            .style("top", (d3.event.pageY - 28) + "px");
        }

      })
      .on("mouseout", function (d) {
        div.transition()
          .duration(100)
          .style("opacity", 0);
      })
    //console.log(svg.node())

    return svg.node()
  }

  render() {
    return <div id='treeMap' />
  }
}

export default TreeMap
