import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Control, Errors } from 'react-redux-form'
import Popup from "reactjs-popup";

// Actions
import {
	autoSave,
	resetMessage,
	resetError
} from 'actions/userActions'

import {
	setEstimatedAnnualValue,
	updateSubLeverRowValue
} from 'actions/subLeverCalculationActions'

import {
	updateTotalBenefit
} from 'actions/calculateROIActions'

// Functions
import {
	updateIncomeStatementAndBalanceSheet
}
	from 'BusinessValue/BusinessValueCalculations'
import * as ROICalculations from 'CalculateROI/CalculateROICalculations'

// Utils
import Parser from 'html-react-parser'
import { getSubLeverIdObject, round } from 'utils/Utils'

// UI
import Radio from 'react-mdl/lib/Radio'
import RadioGroup from 'react-mdl/lib/RadioGroup'
import Textfield from 'react-mdl/lib/Textfield'

// Settings
import { validators } from 'Constants'

class Report extends Component {

	constructor(props) {
		super(props)

		this.state = { render: false }

		this.save = this.save.bind(this)

		// Business Value From SAP Ariba calculations
		this.getEstimatedAnnualValues = this.getEstimatedAnnualValues.bind(this)
		this.getTotalAnnualBenefits = this.getTotalAnnualBenefits.bind(this)
		this.getBenefitRealizationFactors = this.getBenefitRealizationFactors.bind(this)
		this.updateAllCalculations = this.updateAllCalculations.bind(this)
	}

	getEstimatedAnnualValues() {
		let estimatedAnnualValues = []

		this.props.userData.BusinessValueToYou[0].ValueDriver.forEach((data) =>
			data.SubLever.forEach((data) =>
				// return Est Annual Value if Sub Lever is selected
				data.Selected ? estimatedAnnualValues.push(round(data.EstAnnualValue, 2)) : 0
			))

		return estimatedAnnualValues
	}

	getBenefitRealizationFactors() {
		let benefitRealizationFactors = []

		for (let i = 0; i < 5; i++) {
			benefitRealizationFactors.push(this.props.businessValueFromSAPAribaData['Year' + (i + 1)])
		}

		return benefitRealizationFactors
	}

	getTotalAnnualBenefits() {
		let totalAnnualBenefits = [],
			estimatedAnnualValues = this.getEstimatedAnnualValues(),
			benefitRealizationFactors = this.getBenefitRealizationFactors()

		benefitRealizationFactors.forEach((percentage, i) => {
			let totalAnnualBenefit = 0

			estimatedAnnualValues.forEach((value) => {
				totalAnnualBenefit += value
			})

			totalAnnualBenefit = totalAnnualBenefit * percentage / 100
			totalAnnualBenefits.push(totalAnnualBenefit)

			// Update Total Benefit in Redux
			this.props.updateTotalBenefit(totalAnnualBenefit, 'Year' + (i + 1))
		})

		return totalAnnualBenefits
	}

	setCalculateROIValues() {
		ROICalculations.setTotalInvestments(this.props.ROIData)
		ROICalculations.setNetBenefits()
		ROICalculations.setNPV()
		ROICalculations.setROI(this.props.ROIData)
		ROICalculations.setCostPerMonth()
		ROICalculations.setPaybackPeriod(this.props.ROIData)
	}

	dispatchSetEstimatedAnnualValue(estimatedAnnualValue, subLeverId) {
		this.props.setEstimatedAnnualValue(estimatedAnnualValue, getSubLeverIdObject(subLeverId))
	}

	updateAllCalculations() {
		setTimeout(() => {
			updateIncomeStatementAndBalanceSheet()

			setTimeout(() => {
				this.getTotalAnnualBenefits()
				setTimeout(() => {
					this.setCalculateROIValues()
				}, 1)
			}, 1)
		}, 1)
	}

	// Save state to backend before user sends report
	save() {
		let token = localStorage.getItem('jwtToken')

		if (this.props.user.user && token) {
			this.props.autoSave(this.props.userData, this.props.user.userprofile.Roles[0].indexOf("BusinessCaseRole") > -1)
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		return nextState.render ? true : false
	}

	componentWillMount() {
		this.props.resetMessage()
		this.props.resetError()

		let flag = localStorage.getItem('enablementInsightFlag')
		if(flag == "true"){
			this.setState({ enablementInsightFlag: true })
		}else{
			this.setState({ enablementInsightFlag: false })
		}
	}

	componentDidMount() {
		this.updateAllCalculations()
		this.save()
		this.setState({
			render: true
		})
	}

	render() {
		var _applicationFeatures = this.props.user.user.applicationFeatures
		var displayAllReports = (item, _props) => {
			if (item.reportAction === undefined && item.reportAction === null) {
				return null;
			}

			var Checked = true;
			var obj = Object.keys(item).map(function (childitem) {
				if (_applicationFeatures.findIndex(a => a.Action === (item[childitem].reportAction)) > -1) {
					let iconpath = "images/" + item[childitem].icon;
					return (
						item[childitem].isActiveReport !== undefined &&
							item[childitem].isActiveReport === true ?
							<div style={{ display: 'inline-block' }}>
								<div className="businessvalue-report-thumbnail">
									<div className="grid-x">
										<div className="title medium-10">{item[childitem].title}</div>
										<div className="medium-2">
											<Popup className="report-tooltip"
												trigger={<img className="info-icon" src="images/info-icon.svg" width="15" />}
												position="bottom center"
												on="hover"
											>
												{item[childitem].reportDescription}
											</Popup>

										</div>
									</div>
									<div className="grid-x height180">
										<img width="100" className="icon-img" src={iconpath} />
									</div>
									<div className="grid-x radio-btn-wrapper">
										<Radio
											value={item[childitem].reportId}
											name="summaryReport"
											defaultChecked={Checked}
											disabled = {item[childitem].reportId === -1 ? true : false }
											onClick={(e) => _props.handleRadioButtonClick(e.target.value)}>
										</Radio>
										{Checked = false}
									</div>
								</div>
							</div>
							: <div />

					) // return ends
				}
				else {
					return null;
				}
			});//object map end
			return obj;


		};

		return (
			<div className="pl70 pt70 pr70 popup-content">
				<div className="title">{this.props.content.title}</div>
				<div className="description">{this.props.content.description}</div>
				<div className="email-wrapper">
					<div className="medium-12">
						<Control.text
							model="report.Email"
							id="report.Email"
							component={Textfield}
							value={this.props.user.user.Email}
							defaultValue={this.props.user.user.Email}
							style={{ width: '100%' }}
							hidden
						/>
					</div>
					<Control.text
						model="report.id"
						id="report.id"
						value={this.props.userData.VSId}
						defaultValue={this.props.userData.VSId}
						hidden
					/>
					<Control.text
						model="report.Language"
						id="report.Language"
						value={this.props.language.toUpperCase()}
						defaultValue={this.props.language.toUpperCase()}
						hidden
					/>
				</div>
				<div className="blue-bg">
					<div className="centered-icon">
					{this.state.enablementInsightFlag ? displayAllReports(this.props.allReports.enablementReportTypes, this.props) : displayAllReports(this.props.allReports.reportTypes, this.props)}
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		language: state.language,
		user: state.default,
		validation: state.content.validation,
		subLeverCalculationContent: state.content.subLeverCalculations.subLeverCalculation,
		spendAsPercentageOfRevenue: state.spendAsPercentageOfRevenue,
		WACC: state.valueStory.BusinessValueToYou[0].ValueDriver[3].SubLever[1].UserInput[4].UserInput,
		NPV: state.valueStory.BusinessValueFromSap[0].NPV,
		ROI: state.valueStory.BusinessValueFromSap[0].ROIValue,
		ROIData: state.valueStory.BusinessValueFromSap[0].ROI,
		valueOfDelayedInvestment: state.valueStory.BusinessValueFromSap[0].ValueOfDelayedInvestment,
		paybackPeriod: state.valueStory.BusinessValueFromSap[0].Payback,
		userData: state.valueStory,
		businessValueFromSAPAribaData: state.valueStory.BusinessValueFromSap[0],
		calculateROIData: state.valueStory.BusinessValueFromSap[0].ROI
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		updateSubLeverRowValue: (value, subLeverId, rowNumber, valueType) => dispatch(updateSubLeverRowValue(value, subLeverId, rowNumber, valueType)),
		setEstimatedAnnualValue: (value, subLeverId) => dispatch(setEstimatedAnnualValue(value, subLeverId)),
		updateTotalBenefit: (value, year) => dispatch(updateTotalBenefit(value, year)),
		autoSave: (ValueStory, isBusinessCaseAccess) => dispatch(autoSave(ValueStory, isBusinessCaseAccess)),
		resetMessage: () => dispatch(resetMessage()),
		resetError: () => dispatch(resetError())
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Report)


