import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Control, Form, Errors, actions } from 'react-redux-form'

// Actions
import {
	changePassword,
	changePasswordSuccess,
	changePasswordFailure
}
	from 'actions/userActions'

// UI
import Textfield from 'react-mdl/lib/Textfield'
import Button from 'UI/Button'

// Settings
import { validators } from 'Constants'

const styles = { width: '100%' }

class ChangePassword extends Component {

	constructor(props) {
		super(props)
		this.state = {
			SuccessMessage:''
		}

		this.changePassword = this.changePassword.bind(this)
		this.redirect = this.redirect.bind(this)
	}

	changePassword(formValues) {
		let values = Object.assign({}, formValues)
		values.Language = this.props.language.toUpperCase()
		var content = this.props.content
		this.props.changePassword(values)
			.then((result) => {

				if (result.status === 200) {
					//this.props.changePasswordSuccess(content['200'])
					this.props.changePasswordSuccess('success')
                    this.setState({SuccessMessage:result.data.Message})
					// Reset form
					this.formDispatch(actions.reset('changePassword'))

					// Close modal
					setTimeout(() => {
						this.props.hideModal('changePassword')
					}, 500)
					
					//Redirect to login page
					this.redirect()
				}
				else {
					var failmsg = content[result.response.status]
					if (failmsg === undefined) {
						this.props.changePasswordFailure(result.response.data.Message)
					}
					else {
						this.props.changePasswordFailure(failmsg)
					}
				}

			}).catch((error) => {
				this.props.changePasswordFailure(error.response.data.Message)
			})
	}

	redirect() {
		if(this.props.props !=undefined)
		{
			this.props.props.push(`/login`)
		}else{
		this.props.history.push(`/login`)
		}
	}

	render() {
		return (
			<div className="grid-x popup-content">
				<div className="medium-12 cell">
					<div className="title">{this.props.content.title}</div>
					<Form
						model="changePassword"
						onSubmit={(formValues) => this.changePassword(formValues)}
						getDispatch={(dispatch) => this.formDispatch = dispatch}
						validators={{
							'': {
								passwordsMatch: (vals) => vals.NewPassword === vals.ConfirmPassword,
								newPasswordsMatch : (vals) => vals.OldPassword !== vals.NewPassword,
								charMatch: (vals) => (vals.NewPassword.toLowerCase().indexOf(this.props.user.user.FirstName.toLowerCase())=== -1 && vals.NewPassword.toLowerCase().indexOf(this.props.user.user.LastName.toLowerCase())=== -1 
									&& vals.NewPassword.toLowerCase().indexOf(this.props.user.user.Company.toLowerCase()) === -1)
							}
						}}
					>
						<Control.text
							model="changePassword.OldPassword"
							id="changePassword.OldPassword"
							type="password"
							component={Textfield}
							style={styles}
							label={this.props.content.currentPassword}
							floatingLabel
							validators={{
								required: validators.required
							}}
						/>
						<Errors
							className="errors"
							model="changePassword.OldPassword"
							show="touched"
							messages={{
								required: this.props.validation.required
							}}
						/>
						<Control.text
							model="changePassword.NewPassword"
							id="changePassword.NewPassword"
							type="password"
							component={Textfield}
							style={styles}
							label={this.props.content.newPassword}
							floatingLabel
							validators={{
								required: validators.required,
								validPassword: validators.validPassword
							}}
						/>
						<Errors
							className="errors"
							model="changePassword.NewPassword"
							show="touched"
							messages={{
								required: this.props.validation.required,
								validPassword: this.props.validation.validPassword
							}}
						/>
							<Errors
							className="errors"
							model="changePassword"
							show="touched"
							messages={{
								newPasswordsMatch: this.props.validation.newPasswordsMatch
							}}
						/>
						<Errors
								className="errors"
								model="changePassword"
								show="touched"
								messages={{
									charMatch: this.props.validation.charMatch
								}}
						/>
						<Control.text
							model="changePassword.ConfirmPassword"
							id="changePassword.ConfirmPassword"
							type="password"
							component={Textfield}
							style={styles}
							label={this.props.content.confirmPassword}
							floatingLabel
							validators={{
								required: validators.required,
								validPassword: validators.validPassword
							}}
						/>
						<Errors
							className="errors"
							model="changePassword.ConfirmPassword"
							show="touched"
							messages={{
								required: this.props.validation.required,
								validPassword: this.props.validation.validPassword
							}}
						/>
						<Errors
							className="errors"
							model="changePassword"
							show="touched"
							messages={{
								passwordsMatch: this.props.validation.passwordsMatch
							}}
						/>
						<Control.text
							model="changePassword.UserId"
							id="changePassword.UserId"
							type="hidden"
							value={this.props.user.user.Id}
							defaultValue={this.props.user.user.Id}
						/>
						<div className="description">{this.props.content.description}</div>
						<Button
							type="submit"
							disabled={this.props.user.loading}
							content={this.props.content.cta}
							color
						/>
					</Form>
					<div className="errors">{this.props.user.error}</div>
					<div className="success">{this.state.SuccessMessage}</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.default,
		validation: state.content.validation,
		content: state.content.menu.changePassword,
		language: state.language
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		changePassword: (formValues) => dispatch(changePassword(formValues)),
		changePasswordSuccess: (bool) => dispatch(changePasswordSuccess(bool)),
		changePasswordFailure: (error) => dispatch(changePasswordFailure(error))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword)
