import React, { Component } from 'react'
import { connect } from 'react-redux'
import { formatThousands, convertToMillion } from 'utils/Utils'

class IndustryNetworkStatsDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
        this.loadData = this.loadData.bind(this)
    }

    loadData() {

    }

    componentDidMount() {
        this.loadData()
    }

    render() {
        return (
            <div>
                <div className="grid-x">
                    <div className="tab-description">{this.props.industryNetworkStats.tabHeader}</div>
                    <div style={{ fontSize: '14px', paddingLeft: '5px', marginTop: '20px' }} className="medium-12 font-bold">{this.props.industryNetworkStats.ANStats}</div>
                    <div className="medium-12">
                        <div className="seven-cols font12 font-bold align-left grey_background paddingAll5">
                            <div className="column">{this.props.industryNetworkStats.totalRelationships}</div>
                            <div className="column">{this.props.industryNetworkStats.totalSuppliers}</div>
                            <div className="column">{this.props.industryNetworkStats.totalDocCount}</div>
                            <div className="column">{this.props.industryNetworkStats.totalInvoiceSpend}</div>
                            <div className="column">{this.props.industryNetworkStats.totalInvoiceCount}</div>
                            <div className="column">{this.props.industryNetworkStats.totalPOSpend}</div>
                            <div className="column">{this.props.industryNetworkStats.totalPOCount}</div>
                        </div>
                        <div className="seven-cols font12 pt10 align-left paddingAll5">
                            <div className="column">{1197 !== null ? formatThousands(1197) : ''}</div>
                            <div className="column">{883723 !== null ? formatThousands(883723) : ''}</div>
                            <div className="column">{123456 !== null ? formatThousands(123456) : ''}</div>
                            <div className="column">{1234567 !== null ? this.props.currencySymbols[this.props.spendDetails.currentActiveRequest.Currency.trim()] + formatThousands(convertToMillion(1234567)) : ''}</div>
                            <div className="column">{123456 !== null ? formatThousands(123456) : ''}</div>
                            <div className="column">{1234567 !== null ? this.props.currencySymbols[this.props.spendDetails.currentActiveRequest.Currency.trim()] + formatThousands(convertToMillion(1234567)) : ''}</div>
                            <div className="column">{123456 !== null ? formatThousands(123456) : ''}</div>
                        </div>
                    </div>
                    <div style={{ fontSize: '14px', paddingLeft: '5px' }} className="medium-12 font-bold pt20">{this.props.industryNetworkStats.ANStatsRank}</div>
                    <div className="medium-12">
                        <div className="seven-cols font12 font-bold align-left grey_background paddingAll5">
                            <div className="column">{this.props.industryNetworkStats.relationshipRank}</div>
                            <div className="column">{this.props.industryNetworkStats.supplierRank}</div>
                            <div className="column">{this.props.industryNetworkStats.docCountRank}</div>
                            <div className="column">{this.props.industryNetworkStats.invoiceSpendRank}</div>
                            <div className="column">{this.props.industryNetworkStats.invoiceCountRank}</div>
                            <div className="column">{this.props.industryNetworkStats.POSpendRank}</div>
                            <div className="column">{this.props.industryNetworkStats.POCountRank}</div>
                        </div>
                        <div className="seven-cols font12 pt10 align-left paddingAll5">
                            <div className="column">{'1/13'}</div>
                            <div className="column">{'2/13'}</div>
                            <div className="column">{'3/13'}</div>
                            <div className="column">{'4/13'}</div>
                            <div className="column">{'5/13'}</div>
                            <div className="column">{'6/13'}</div>
                            <div className="column">{'7/13'}</div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}

const mapStateToProps = (state) => {
    return {
        content: state.content,
        ui: state.content.ui,
        user: state.default,
        industryNetworkStats: state.content.spendAnalysis.industryDrilldown.industryNetworkStatsDetails,
        spendDetails: state.spendDetails,
        currencySymbols: state.content.CurrencySymbols,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(IndustryNetworkStatsDetails); 