import React, { Component } from 'react'
import { connect } from 'react-redux'
import Modal from 'reboron/OutlineModal'
import Button from 'UI/Button'
import { BreakLine } from 'utils/Utils'
import classNames from 'classnames'

import {   
    getYourFileLoading,
    getAllEnablementParameterPriority,
    UpdateSupplyChainProcessAdminValues,
    fetchSupplyChainProcessAdmin
} from 'actions/spendActions'

import { changeModalStyle, changeModalContentStyle, backdropStyle, path } from 'Constants'
import { Checkbox } from 'material-ui';

const changeBackdropStyle = Object.assign({}, backdropStyle);
changeBackdropStyle.zIndex = '1050';

const alertModalStyle = Object.assign({}, changeModalStyle);
alertModalStyle.zIndex = '1060';
alertModalStyle.width = '33%';

class SupplyChainProcess extends Component {
    constructor(props) {
        super(props)
        this.state = {
            responseMessage: '',
            isError: false,
            popupErrorMessage : '',           
            isActive: '',
            selectedCategory: '',
            data: [],
            apiResponse: [],
            editedItemPriority: [],
            isPopupOpen: false,
            autoWavePriority: [],
            uniquePriority: [],
            initialData: [],
            agreeToRecast: false,
            isReadOnly: false,            
            isSucess: false,
            cntToSetWarning: 0,
            isAllowedToEdit: true,
            sccSelectedDropdown : '',
            select1:'',
            arrSelect1:[],
            select2:'',
            arrSelect2:[],
            select3:'',
            arrSelect3:[],
            select4:'',
            arrSelect4:[],
            select5:'',
            arrSelect5:[],
            select6:'',
            arrSelect6:[],
            select7:'',
            arrSelect7:[],
            select8:'',
            arrSelect8:[],
            select9:'',
            arrSelect9:[],
            select10:'',
            arrSelect10:[],
            currentSelect:'',
            currentArrSelect:[],
            isCheck1: false,
            isCheck2: false,
            isCheck3: false,
            isCheck4: false,
            isCheck5: false,
            isCheck6: false,
            isCheck7: false,
            isCheck8: false,
            isCheck9: false,
            isCheck10: false,
            count: 0
        }

        this.onLoad = this.onLoad.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.showModal = this.showModal.bind(this)
        this.openSccDropdown = this.openSccDropdown.bind(this) 
        this.sccCheckboxClicked = this.sccCheckboxClicked.bind(this)
        this.closeSccDropdownPopup = this.closeSccDropdownPopup.bind(this)
        this.saveSccDropdown = this.saveSccDropdown.bind(this)
        this.sccOnSaveValidation = this.sccOnSaveValidation.bind(this)        
        this.setSuppierChoiceProcess = this.setSuppierChoiceProcess.bind(this)                  
        this.newVariable = 0
    }

    componentDidMount() {
		this.onLoad();
    }

    setSuppierChoiceProcess(scProcessData) {
        scProcessData.map((item) => {
            if (item.CHILD_FIELD_NAME != null) {
                if (item.CHILD_FIELD_NAME != "") {
                    var arr_child_field_name = [];
                    arr_child_field_name = item.CHILD_FIELD_NAME.split(',');

                    switch (item.PARENT_FIELD_VALUE) {
                        case 'SELECT1':
                            this.setState({ select1: item.CHILD_FIELD_NAME, arrSelect1: arr_child_field_name });
                            break;
                        case 'SELECT2':
                            this.setState({ select2: item.CHILD_FIELD_NAME, arrSelect2: arr_child_field_name });
                            break;
                        case 'SELECT3':
                            this.setState({ select3: item.CHILD_FIELD_NAME, arrSelect3: arr_child_field_name });
                            break;
                        case 'SELECT4':
                            this.setState({ select4: item.CHILD_FIELD_NAME, arrSelect4: arr_child_field_name });
                            break;
                        case 'SELECT5':
                            this.setState({ select5: item.CHILD_FIELD_NAME, arrSelect5: arr_child_field_name });
                            break;
                        case 'SELECT6':
                            this.setState({ select6: item.CHILD_FIELD_NAME, arrSelect6: arr_child_field_name });
                            break;
                        case 'SELECT7':
                            this.setState({ select7: item.CHILD_FIELD_NAME, arrSelect7: arr_child_field_name });
                            break;
                        case 'SELECT8':
                            this.setState({ select8: item.CHILD_FIELD_NAME, arrSelect8: arr_child_field_name });
                            break;
                        case 'SELECT9':
                            this.setState({ select9: item.CHILD_FIELD_NAME, arrSelect9: arr_child_field_name });
                            break;
                        case 'SELECT10':
                            this.setState({ select10: item.CHILD_FIELD_NAME, arrSelect10: arr_child_field_name });
                            break;
                        default:
                            break;
                    }
                }
            }
        })
    }

    onLoad() {

        this.props.fetchSupplyChainProcessAdmin().then(response => {
            if (response.status === 200) {

                this.setSuppierChoiceProcess(response.data.Result);                
                this.setState({
                    data: response.data.Result                    
                },this.forceUpdate);
            }
            else {
                this.setState({
                    isLoading: false,
                    isError: true,
                    responseMessage: 'An error has occured'
                })
            }
        }).catch((error) => {
            this.setState({
                isLoading: false,
                isError: true,
                responseMessage: 'An error has occured'
            })
        })
    }   
    

    onChangeEvent(val, item) {
        if (item.CHILD_FIELD_NAME == "" && Number(val.target.value) > 0) // if no checkbox selected and priority is >0 then make it 0 again
        {
            this.state.data.filter(x => x.PARENT_FIELD_VALUE == item.PARENT_FIELD_VALUE)[0].PRIORITY_NUM = 0;
            val.target.value = 0;
        }
        else {
            this.setState({ isError: false, isSucess: false, pendingMsg: this.props.wizard.pendingPriorityChanges })
            var value = Number(val.target.value)
            
            if (/^(\-(\d*))$/.test(value) || !isNaN(value) && (value <= 9) && Number.isInteger(value)) {
                if (val.target.id === 'PRIORITY_NUM') {

                    let apiData = this.state.data

                    apiData.filter(d => d.PARENT_FIELD_VALUE === item.PARENT_FIELD_VALUE)[0].PRIORITY_NUM = Number(value); 

                    this.setState({
                        pendingMsg: this.props.wizard.pendingPriorityChanges,
                        data: apiData,
                        isError: false
                    })
                }
            }
        }
    }

    handleSave() {
        
        this.state.data.map((item)=>{
            item.USER_NAME = this.props.user.user.Email;
        })
        this.props.UpdateSupplyChainProcessAdminValues(this.state.data).then((response) => {
            if (response.status === 200) {

                this.setState({
                    data: response.data.Result,
                    isLoading: false,
                    responseMessage: this.props.wizard.successMessage,
                    isError: false,
                    isSucess: true
                });
            }
            else {
                this.setState({ isLoading: false, responseMessage: this.props.wizard.errorMessage, isError: true, isSucess: false });
            }
        }).catch((error) => {
            this.setState({ isLoading: false, responseMessage: this.props.wizard.errorMessage, isError: true, isSucess: false });
        })
    }

    showModal(action) {
        this.refs[action].show()
    }

    hideModal(action) {
        this.setState({ agreeToRecast: false })
        this.refs[action].hide()
    }   

    onFocusOut(e) {
        if (e.target.value === '') {
            e.target.value = 0;
        }
    }

    openSccDropdown(value)
    {
        this.setState({ responseMessage:'',currentSelect: '' ,popupErrorMessage : '',isCheck1: false,isCheck2: false,isCheck3: false,isCheck4: false,isCheck5: false,isCheck6: false,isCheck7: false,isCheck8: false,isCheck9: false,isCheck10: false });
        var checkedArray = [];
        switch(value) {
            case 'SELECT1':
                checkedArray = this.state.arrSelect1;           
                break;
            case 'SELECT2':
                checkedArray = this.state.arrSelect2;     
                break;
            case 'SELECT3':
                checkedArray = this.state.arrSelect3;   
                break;
            case 'SELECT4':
                checkedArray = this.state.arrSelect4; 
                break;
            case 'SELECT5':
                checkedArray = this.state.arrSelect5; 
                break;
            case 'SELECT6':
                checkedArray = this.state.arrSelect6; 
                break;
            case 'SELECT7':
                checkedArray = this.state.arrSelect7;  
                break;
            case 'SELECT8':
                checkedArray = this.state.arrSelect8;  
                break;
            case 'SELECT9':
                checkedArray = this.state.arrSelect9;    
                break;
            case 'SELECT10':
                checkedArray = this.state.arrSelect10; 
                break;
            default:                
                break;
        }

        checkedArray.map((item) => {
            if (item == 'Forecast Collaboration')
                this.setState({ isCheck1: true });
            else if (item == 'Supplier Managed Inventory')
                this.setState({ isCheck2: true });
            else if (item == 'Standard Order Process')
                this.setState({ isCheck3: true });
            else if (item == 'Consignment Collaboration')
                this.setState({ isCheck4: true });
            else if (item == 'Scheduling Agreement')
                this.setState({ isCheck5: true });
            else if (item == 'Subcontracting')
                this.setState({ isCheck6: true });
            else if (item == 'Quality Notifications')
                this.setState({ isCheck7: true });
            else if (item == 'Quality Inspection')
                this.setState({ isCheck8: true });
            else if (item == 'Quality Review')
                this.setState({ isCheck9: true });
            else if (item == 'Returns')
                this.setState({ isCheck10: true });
        })

        this.setState({ sccSelectedDropdown: value,currentArrSelect : checkedArray }, this.showModal("sccDropdown"));
        //this.showModal("sccDropdown")
    }

    saveSccDropdown()
    {
        var validationResult = this.sccOnSaveValidation();
        if(validationResult){

        var checkedValue = '';
        switch(this.state.sccSelectedDropdown) {
            case 'SELECT1':
                checkedValue = this.state.currentSelect;
                if(checkedValue == ""){this.state.data.filter(x => x.PARENT_FIELD_VALUE == 'SELECT1')[0].PRIORITY_NUM = 0;}
                this.setState({ select1: checkedValue, arrSelect1 :this.state.currentArrSelect, popupErrorMessage : ''  });        
                break;
            case 'SELECT2':
                checkedValue = this.state.currentSelect;
                if(checkedValue == ""){this.state.data.filter(x => x.PARENT_FIELD_VALUE == 'SELECT2')[0].PRIORITY_NUM = 0;} 
                this.setState({ select2: checkedValue, arrSelect2 :this.state.currentArrSelect, popupErrorMessage : ''  });   
                break;
            case 'SELECT3':
                checkedValue = this.state.currentSelect;
                if(checkedValue == ""){this.state.data.filter(x => x.PARENT_FIELD_VALUE == 'SELECT3')[0].PRIORITY_NUM = 0;} 
                this.setState({ select3: checkedValue, arrSelect3 :this.state.currentArrSelect, popupErrorMessage : ''  });   
                break;
            case 'SELECT4':
                checkedValue = this.state.currentSelect;
                if(checkedValue == ""){this.state.data.filter(x => x.PARENT_FIELD_VALUE == 'SELECT4')[0].PRIORITY_NUM = 0;}  
                this.setState({ select4: checkedValue, arrSelect4 :this.state.currentArrSelect, popupErrorMessage : ''  });   
                break;
            case 'SELECT5':
                checkedValue = this.state.currentSelect; 
                if(checkedValue == ""){this.state.data.filter(x => x.PARENT_FIELD_VALUE == 'SELECT5')[0].PRIORITY_NUM = 0;}
                this.setState({ select5: checkedValue, arrSelect5 :this.state.currentArrSelect, popupErrorMessage : ''  });    
                break;
            case 'SELECT6':
                checkedValue = this.state.currentSelect;
                if(checkedValue == ""){this.state.data.filter(x => x.PARENT_FIELD_VALUE == 'SELECT6')[0].PRIORITY_NUM = 0;}  
                this.setState({ select6: checkedValue, arrSelect6 :this.state.currentArrSelect , popupErrorMessage : '' });     
                break;
            case 'SELECT7':
                checkedValue = this.state.currentSelect;  
                if(checkedValue == ""){this.state.data.filter(x => x.PARENT_FIELD_VALUE == 'SELECT7')[0].PRIORITY_NUM = 0;}
                this.setState({ select7: checkedValue, arrSelect7 :this.state.currentArrSelect, popupErrorMessage : ''  });   
                break;
            case 'SELECT8':
                checkedValue = this.state.currentSelect;  
                if(checkedValue == ""){this.state.data.filter(x => x.PARENT_FIELD_VALUE == 'SELECT8')[0].PRIORITY_NUM = 0;}
                this.setState({ select8: checkedValue, arrSelect8 :this.state.currentArrSelect, popupErrorMessage : ''  });   
                break;
            case 'SELECT9':
                checkedValue = this.state.currentSelect;  
                if(checkedValue == ""){this.state.data.filter(x => x.PARENT_FIELD_VALUE == 'SELECT9')[0].PRIORITY_NUM = 0;}
                this.setState({ select9: checkedValue, arrSelect9 :this.state.currentArrSelect, popupErrorMessage : ''  });   
                break;
            case 'SELECT10':
                checkedValue = this.state.currentSelect;  
                if(checkedValue == ""){this.state.data.filter(x => x.PARENT_FIELD_VALUE == 'SELECT10')[0].PRIORITY_NUM = 0;}
                this.setState({ select10: checkedValue, arrSelect10 :this.state.currentArrSelect , popupErrorMessage : '' });    
                break;
            default:                
                break;
          }
        this.state.data.filter(x => x.PARENT_FIELD_VALUE == this.state.sccSelectedDropdown)[0].CHILD_FIELD_NAME = checkedValue;
        this.setState({ responseMessage:'Pending changes. Click save to update'});
        this.hideModal("sccDropdown");
        }
        else
        {
            this.setState({ popupErrorMessage : 'Duplicate Combination'});
        }
    }

    closeSccDropdownPopup(){
        this.setState({ isCheck1: false,isCheck2: false,isCheck3: false,isCheck4: false,isCheck5: false,isCheck6: false,isCheck7: false,isCheck8: false,isCheck9: false,isCheck10: false });
        var length = this.state.data.filter(x => x.PARENT_FIELD_VALUE == this.state.sccSelectedDropdown)[0].CHILD_FIELD_NAME.length;
        if(length == 0 )
        {
        switch(this.state.sccSelectedDropdown) {
            case 'SELECT1':
                this.setState({ select1: '' ,arrSelect1: [] });                
                break;
            case 'SELECT2':
                this.setState({ select2: '' ,arrSelect2: [] });      
                break;
            case 'SELECT3':
                this.setState({ select3: '' ,arrSelect3: [] });      
                break;
            case 'SELECT4':
                this.setState({ select4: '' ,arrSelect4: [] });   
                break;
            case 'SELECT5':
                this.setState({ select5: '' ,arrSelect5: [] });   
                break;
            case 'SELECT6':
                this.setState({ select6: '' ,arrSelect6: [] });   
                break;
            case 'SELECT7':
                this.setState({ select7: '' ,arrSelect7: [] });   
                break;
            case 'SELECT8':
                this.setState({ select8: '' ,arrSelect8: [] });   
                break;
            case 'SELECT9':
                this.setState({ select9: '' ,arrSelect9: [] });   
                break;
            case 'SELECT10':
                this.setState({ select10: '' ,arrSelect10: [] });   
                break;
            default:                
                break;
          }
        }
        this.hideModal("sccDropdown");
    }

    sccOnSaveValidation() {
        if (this.state.currentArrSelect.length > 0) {
            if (this.state.currentArrSelect.sort().join(',') === this.state.arrSelect1.sort().join(',') && this.state.sccSelectedDropdown != 'SELECT1')
                return false;
            else if (this.state.currentArrSelect.sort().join(',') === this.state.arrSelect2.sort().join(',') && this.state.sccSelectedDropdown != 'SELECT2')
                return false;
            else if (this.state.currentArrSelect.sort().join(',') === this.state.arrSelect3.sort().join(',') && this.state.sccSelectedDropdown != 'SELECT3')
                return false;
            else if (this.state.currentArrSelect.sort().join(',') === this.state.arrSelect4.sort().join(',') && this.state.sccSelectedDropdown != 'SELECT4')
                return false;
            else if (this.state.currentArrSelect.sort().join(',') === this.state.arrSelect5.sort().join(',') && this.state.sccSelectedDropdown != 'SELECT5')
                return false;
            else if (this.state.currentArrSelect.sort().join(',') === this.state.arrSelect6.sort().join(',') && this.state.sccSelectedDropdown != 'SELECT6')
                return false;
            else if (this.state.currentArrSelect.sort().join(',') === this.state.arrSelect7.sort().join(',') && this.state.sccSelectedDropdown != 'SELECT7')
                return false;
            else if (this.state.currentArrSelect.sort().join(',') === this.state.arrSelect8.sort().join(',') && this.state.sccSelectedDropdown != 'SELECT8')
                return false;
            else if (this.state.currentArrSelect.sort().join(',') === this.state.arrSelect9.sort().join(',') && this.state.sccSelectedDropdown != 'SELECT9')
                return false;
            else if (this.state.currentArrSelect.sort().join(',') === this.state.arrSelect10.sort().join(',') && this.state.sccSelectedDropdown != 'SELECT10')
                return false;
            else
                return true;
        }
        else
            return true;
    }

    sccCheckboxClicked(e)  
    {
        var currentArrChecked = [...this.state.currentArrSelect] ;
        if(e.target.checked)
            currentArrChecked.push(e.target.value)
        else
        {
            const index = currentArrChecked.indexOf(e.target.value);
            currentArrChecked.splice(index, 1);        
        }

        var currentSelected = currentArrChecked.length == 0 ? '' : currentArrChecked.toString();
        switch(e.target.name) {
            case 'Check1':
                this.setState({ isCheck1: !this.state.isCheck1 });                
                break;
            case 'Check2':
                this.setState({ isCheck2: !this.state.isCheck2 });
                break;
            case 'Check3':
                this.setState({ isCheck3: !this.state.isCheck3 });
                break;
            case 'Check4':
                this.setState({ isCheck4: !this.state.isCheck4 });
                break;
            case 'Check5':
                this.setState({ isCheck5: !this.state.isCheck5  });
                break;
            case 'Check6':
                this.setState({ isCheck6: !this.state.isCheck6 });
                break;
            case 'Check7':
                this.setState({ isCheck7: !this.state.isCheck7 });
                break;
            case 'Check8':
                this.setState({ isCheck8: !this.state.isCheck8 });
                break;
            case 'Check9':
                this.setState({ isCheck9: !this.state.isCheck9 });
                break;
            case 'Check10':
                this.setState({ isCheck10: !this.state.isCheck10 });
                break;
            default:               
                break;
          }
          this.setState({ currentArrSelect: currentArrChecked, currentSelect: currentSelected })
    }             


    render() {

        var rowItem = this.state.data.map((item) => {
            return (                
                    <div className="grid-x">
                        <div className="medium-12 pl20">
                            <div className="grid-x">
                                <div className="medium-11 box-title">
                                    <div className="grid-x grid-padding-x">
                                        <div className="medium-12">
                                            {item.PARENT_FIELD_VALUE === "SELECT1" || item.PARENT_FIELD_VALUE === "SELECT2" || item.PARENT_FIELD_VALUE === "SELECT3" ||
                                                item.PARENT_FIELD_VALUE === "SELECT4" || item.PARENT_FIELD_VALUE === "SELECT5" || item.PARENT_FIELD_VALUE === "SELECT6" ||
                                                item.PARENT_FIELD_VALUE === "SELECT7" || item.PARENT_FIELD_VALUE === "SELECT8" || item.PARENT_FIELD_VALUE === "SELECT9" || item.PARENT_FIELD_VALUE === "SELECT10"
                                                // ? <a onClick={() => this.openSccDropdown(item.PARENT_FIELD_VALUE)} style={{cursor: 'pointer'}}>Select</a> :
                                                ? <div className="float-left" style={{display:'ruby',lineHeight:'2'}}><button onClick={() => this.openSccDropdown(item.PARENT_FIELD_VALUE)} id="sccProcessButton" className="button sccProcessButton"><span className="button__inner">{"Select"}</span></button>
                                                    &nbsp;&nbsp;
                                                    {item.PARENT_FIELD_VALUE === "SELECT1" ? this.state.select1 :
                                                        item.PARENT_FIELD_VALUE === "SELECT2" ? this.state.select2 :
                                                            item.PARENT_FIELD_VALUE === "SELECT3" ? this.state.select3 :
                                                                item.PARENT_FIELD_VALUE === "SELECT4" ? this.state.select4 :
                                                                    item.PARENT_FIELD_VALUE === "SELECT5" ? this.state.select5 :
                                                                        item.PARENT_FIELD_VALUE === "SELECT6" ? this.state.select6 :
                                                                            item.PARENT_FIELD_VALUE === "SELECT7" ? this.state.select7 :
                                                                                item.PARENT_FIELD_VALUE === "SELECT8" ? this.state.select8 :
                                                                                    item.PARENT_FIELD_VALUE === "SELECT9" ? this.state.select9 :
                                                                                        item.PARENT_FIELD_VALUE === "SELECT10" ? this.state.select10 :
                                                                                            null}
                                                </div> : null
                                                }
                                        </div>
                                    </div>
                                </div>
                                <div className="medium-1">
                                    <input className="box-input"
                                        id="PRIORITY_NUM"
                                        key={item.PARENT_FIELD_VALUE}
                                        name="PRIORITY_NUM"
                                        defaultValue={item.PRIORITY_NUM}
                                        onChange={(e) => this.state.isAllowedToEdit && this.onChangeEvent(e, item , this)}
                                        onBlur={(e) => this.onFocusOut(e, item)}
                                        type="Number"
                                        readOnly={this.state.isReadOnly}
                                        style={{ backgroundColor: this.state.isReadOnly && '#fafafa' }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>                    
            )
        })

        return (     
            <div className="grid-x medium-12" style={{ backgroundColor: '#fafafa', padding: '10px' }}>
                  
                <div className="medium-12">
                    <div className="grid-x">
                        <div className="medium-6 float-left" style={{ fontSize: '1.2rem', paddingBottom: '8px' }}>{BreakLine("Supply Chain Process")}</div>
                        <div className="medium-6" style={{ display: 'flex', justifyContent: 'center' }} >
                            {this.state.isError ?
                                <div style={{ margin: '0 0 0.5rem', color: 'red' }}>{this.state.responseMessage}</div> :
                                <div style={{ margin: '0 0 0.5rem', color: '#4fb81c' }}>{this.state.responseMessage}</div>
                            }
                        </div>
                        <div className="medium-3"></div>
                    </div>
                    <div className="grid-x">
                        <div className="medium-11 float-left pb10">{"Supply Chain Process"}</div>
                        {this.props.user.userprofile.Roles[0].indexOf("Admin") > -1 ?
                            <div className="medium-1 pb5">
                                <button 
                                className={classNames('float-right',
                                    {
                                        'hand-cursor': true,                                        
                                    })}
                                    onClick={this.handleSave} 
                                    // disabled={this.state.isLoading ? true : this.state.editedEnablementResourceParameters.length === 0} 
                                    type="submit" float >
                                    <img width="25" title={"Save"} src="images/save_active.svg" />
                                </button>
                            </div> : null}
                    </div>
                    <div className="grid-x">
                        <div className="medium-12 pl20">
                            <div className="grid-x">
                                <div className="medium-11 box-title highlight-button">
                                    Supply Chain Process
                                </div>
                                <div className="medium-1 box-title highlight-button">
                                    Metric Priority
                                </div>
                            </div>
                        </div>
                        <div className="medium-12">
                            {this.state.data.length !== 0 ? rowItem : <div className="pt10 pl20">{this.props.wizard.noDataFound}</div>}
                        </div >
                    </div>
                    
                </div>

                   
                        <div className="grid-x medium-12">
                            <div>
                                <Modal
                                    ref="sccDropdown"
                                    modalStyle={alertModalStyle}
                                    contentStyle={changeModalContentStyle}
                                    backdropStyle={changeBackdropStyle}
                                >
                                    <div className="grid-x grid-padding-x change-popup">
                                        <div className="medium-12">
                                            <button onClick={this.closeSccDropdownPopup} className="close" />
                                        </div>
                                        <div className="medium-12 pt30 pb10">
                                            <table>
                                                <tr>
                                                    <td>
                                                        <Checkbox id="Forecast Collaboration" checked={this.state.isCheck1} onClick={(e) => this.sccCheckboxClicked(e)} name="Check1" value="Forecast Collaboration" />
                                                    </td>
                                                    <td>
                                                        Forecast Collaboration
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <Checkbox id="Supplier Managed Inventory" checked={this.state.isCheck2} onClick={(e) => this.sccCheckboxClicked(e)} name="Check2" value="Supplier Managed Inventory" />
                                                    </td>
                                                    <td>
                                                        Supplier Managed Inventory
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <Checkbox id="Standard Order Process" checked={this.state.isCheck3} onClick={(e) => this.sccCheckboxClicked(e)} name="Check3" value="Standard Order Process" />
                                                    </td>
                                                    <td>
                                                        Standard Order Process
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <Checkbox id="Consignment Collaboration" checked={this.state.isCheck4} onClick={(e) => this.sccCheckboxClicked(e)} name="Check4" value="Consignment Collaboration" />
                                                    </td>
                                                    <td>
                                                        Consignment Collaboration
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <Checkbox id="Scheduling Agreement" checked={this.state.isCheck5} onClick={(e) => this.sccCheckboxClicked(e)} name="Check5" value="Scheduling Agreement" />
                                                    </td>
                                                    <td>
                                                        Scheduling Agreement
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <Checkbox id="Subcontracting" checked={this.state.isCheck6} onClick={(e) => this.sccCheckboxClicked(e)} name="Check6" value="Subcontracting" />
                                                    </td>
                                                    <td>
                                                        Subcontracting
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <Checkbox id="Quality Notifications" checked={this.state.isCheck7} onClick={(e) => this.sccCheckboxClicked(e)} name="Check7" value="Quality Notifications" />
                                                    </td>
                                                    <td>
                                                        Quality Notifications
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <Checkbox id="Quality Inspection" checked={this.state.isCheck8} onClick={(e) => this.sccCheckboxClicked(e)} name="Check8" value="Quality Inspection" />
                                                    </td>
                                                    <td>
                                                        Quality Inspection
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <Checkbox id="Quality Review" checked={this.state.isCheck9} onClick={(e) => this.sccCheckboxClicked(e)} name="Check9" value="Quality Review" />
                                                    </td>
                                                    <td>
                                                        Quality Review
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <Checkbox id="Returns" checked={this.state.isCheck10} onClick={(e) => this.sccCheckboxClicked(e)} name="Check10" value="Returns" />
                                                    </td>
                                                    <td>
                                                        Returns
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={2}>
                                                        <div style={{ color: 'red', textAlign: 'center' }} className="cell pt5">{this.state.popupErrorMessage}</div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                        
                                        <div className="medium-12 popupOkBtn">
                                            <div className="float-right">
                                                <div className="float-left"><Button
                                                    content={"Save"}
                                                    onClick={() => this.saveSccDropdown()}
                                                    color /></div>
                                                <div className="float-left"><Button
                                                    content={"cancel"}
                                                    onClick={this.closeSccDropdownPopup}
                                                    color /></div>
                                            </div>
                                        </div>
                                    </div>
                                </Modal>
                            </div>
                        </div>
                        
                </div>                
        )
    }
}
const mapStateToProps = state => {
    return {
        // language: state.language,
        userData: state.valueStory,
        user: state.default,
        content: state.content.spendAnalysis.accountParameters,
        wizard: state.content.spendAnalysis.prioritizationWizard,
        validation: state.content.validation,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getYourFileLoading: bool => dispatch(getYourFileLoading(bool)),
        getAllEnablementParameterPriority: (requestDetails) => dispatch(getAllEnablementParameterPriority(requestDetails)),
        UpdateSupplyChainProcessAdminValues: (data) => dispatch(UpdateSupplyChainProcessAdminValues(data)),
        fetchSupplyChainProcessAdmin: () => dispatch(fetchSupplyChainProcessAdmin())        
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SupplyChainProcess)
