import React from 'react';
import { connect } from 'react-redux';
import Video from 'components/UI/Video'
import classNames from "classnames";
import {
    fetchScreenVideosForUser
} from 'actions/videoRoleAction';

class VideoHelp extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            counter: 1,
            videoDetails: { title: '', videos: [] }
        }

        this.hideVideo = this.hideVideo.bind(this)
        this.showVideo = this.showVideo.bind(this)
        this.blink = this.blink.bind(this)
        this.clearBlink = this.clearBlink.bind(this)

       this.blinkInterval = setInterval(this.blink, 1000);
       setTimeout(this.clearBlink, 10 * 1000)
    }

    componentDidMount() {
        this.props.fetchScreenVideosForUser(this.props.user.user.Id).then((response) => {
            if (response.data.Result.length > 0) {
                const videos = []
                const filter = response.data.Result.filter(element => {
                    return element.ScreenVideoRoles.length != 0 &&
                        (element.Module === this.props.screen.module && element.ScreenName === this.props.screen.screenName)
                })

                filter.forEach(item => {
                    videos.push({
                        name: item.Name,
                        desc: item.Description,
                        file: item.SourceType ? "/videos/" + item.VideoSource : item.VideoSource,
                        sourceType: item.SourceType,
                        order: item.Order
                    })
                })

                videos.sort((a, b) => {
                    return a.order > b.order ? 1 : a.order < b.order ? -1 : 0;
                })

                this.setState({ videoDetails: { title: this.props.screen.screenName, videos: videos } });
            }
        })
    }

    blink() {
        this.setState(prevState => {
            return {counter: prevState.counter + 1}
        })
    }

    clearBlink() {
        this.setState({counter: 1})
        clearInterval(this.blinkInterval);
    }

    hideVideo() {
		this.setState({open: false})
	}

	showVideo() {
		this.setState({open: true})
	}

    render() {
        return (
            <span>
                <span onClick={e => {this.showVideo()}} className={classNames('hand-cursor', 'img-32', 'video-help',
                    {
                        'opacity0': this.state.counter % 2 === 0,
                        'display-none': this.state.videoDetails.videos.length === 0,

                    })}
                    ><img src="/images/help-icon.png" />{this.props.ui.needHelp}</span>
                <Video open={this.state.open} visibility={this.hideVideo} videoDetails={this.state.videoDetails }/>            
            </span>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.default,
        ui: state.content.ui,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchScreenVideosForUser: (user) => dispatch(fetchScreenVideosForUser(user)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoHelp); 