import React from 'react'
import PropTypes from 'prop-types'
import Popup from "reactjs-popup";
// Utils
import moment from 'moment'
import classnames from 'classnames'
import ScrollArea from 'react-scrollbar'

const ExistingValueStoryList = (props) =>
    <div className="existing-valuestory-list" style={{ marginTop: '0' }}>
        <div className="grid-x grid-padding-x pt10 pb5">
            <div className="medium-11 cell">
                <div className="grid-x grid-padding-x valuestory-headers">
                    <div className="medium-3 cell">{props.content.valueStoryNameHeader}</div>
                    <div className="medium-3 cell">{props.content.ownerHeader}</div>
                    <div className="medium-3 cell">{props.content.companyNameHeader}</div>
                    {/* <div className="medium-2 cell">{props.content.industry}</div> */}
                    {/* <div className="medium-2 cell">{props.content.country}</div> */}
                    {/* <div className="medium-2 cell">{props.content.dateHeader}</div> */}
                    <div className="medium-3 cell">{props.content.requestStatus}</div>
                </div>
            </div>
        </div>
        <ScrollArea minScrollSize={40}
            speed={0.8} className="valueStory-scrollarea"
            contentClassName="content"
            horizontal={false}
        >
            {//props.isLoading ? <div className="loading loading--is-loading" style={{ width: '100%' }}><div className="loader" />Loading...</div> :
                // <div className="loading loading--is-loading"><div className="loader" />Loading...</div> :
                (props.valueStories.map((data, i) =>
                    <div className="grid-x grid-padding-x" key={i} >
                        <div className={
                            classnames(
                                'valuestory-item medium-11 cell'
                            )
                        }>
                            <div className="grid-x grid-padding-x">
                                <div className="medium-3 cell valuestory-item__title">{data.ValueStoryName}</div>
                                <div className="medium-3 cell valuestory-item__title">{data.Email}</div>
                                <div className="medium-3 cell valuestory-item__title">{data.CompanyName}</div>
                                {/* <div className="medium-2 cell valuestory-item__title">{props.contentData.industries[data.Industry]}</div> */}
                                {/* <div className="medium-2 cell valuestory-item__title">{props.contentData.locations[data.Location]}</div> */}
                                {/* <div className="medium-2 cell valuestory-item__title">{moment(data.ModifiedDate).format('MMM d, YYYY')}</div> */}
                                <div className="medium-3 cell valuestory-item__title">{data.RequestStatus ? props.content.pending : props.content.notRequested}</div>
                            </div>
                        </div>
                        {
                            <div className="medium-1 pl10">
                                <div className="grid-x medium-12">
                                    <div className="medium-4">
                                        <button key={i} className="email__help" onClick={() => props.showModal(data.Email, data.VSID, data.CompanyName)}></button>
                                    </div>
                                    <div className="medium-2 pl5">
                                        <div className="medium-4">
                                            <button key={"key" + i} className="request__help" onClick={() => props.showRequestModal(data.VSID)}></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                ))
            }
        </ScrollArea>
    </div>
export default ExistingValueStoryList

ExistingValueStoryList.propTypes = {
    valueStories: PropTypes.array,
    selectedValueStory: PropTypes.number,
    handleValueStoryClick: PropTypes.func.isRequired,
    openValueStory: PropTypes.func.isRequired
}