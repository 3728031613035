import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Form } from 'react-redux-form'
import classNames from "classnames";
import { path } from 'Constants'
import moment from 'moment'
import { ControlTextE } from 'UI/CustomComponents/Controls';
import Select from 'react-select';
// Actions
import {
    resetError,
    FetchUpdateFieldVisibility
} from 'actions/spendActions'

// Utils
import { BreakLine} from 'utils/Utils'

class FieldVisibility extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            fetchUpdateFieldVisibility: [],
            editedfetchUpdateFieldVisibility: [],
            fieldVisibilityData :[],
            selectedIndirectScope : '',
            responseMessage: '',
            isError: false,                  
        }
        this.loadData = this.loadData.bind(this)
        this.trackScheduleFactorsChanges = this.trackScheduleFactorsChanges.bind(this)
        this.onfetchUpdateFieldVisibilitySave = this.onfetchUpdateFieldVisibilitySave.bind(this)
        // this.setWarning1Msg = this.setWarning1Msg.bind(this)
        // this.setWarning2Msg = this.setWarning2Msg.bind(this)              
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {   
        var data=[{READ_WRITE_FLAG : "R", SCREEN_FLAG : "A"}];    
        this.props.FetchUpdateFieldVisibility(data).then((response) => {
            if (response.status === 200) {
                this.setState({fieldVisibilityData : response.data.Result});
            }
        });        
    }

    trackScheduleFactorsChanges(record) {
        var updatedRecords = this.state.editedfetchUpdateFieldVisibility
        if (this.state.editedfetchUpdateFieldVisibility.length === 0)
            updatedRecords.push(record)
        else {
            let index = this.state.editedfetchUpdateFieldVisibility.findIndex(x => x.ENABLE_WV_FIELD_PRIORITY_ID === record.ENABLE_WV_FIELD_PRIORITY_ID);
            if (index !== undefined && index !== null && index > -1)
                updatedRecords.splice(index, 1)
            updatedRecords.push(record)
        }
        this.setState({ responseMessage: '', editedfetchUpdateFieldVisibility: updatedRecords, isError: false })
    }
    

    onfetchUpdateFieldVisibilitySave() {
        this.setState({ isLoading: true, responseMessage: '', isError: false });
        let updatedRecords = this.state.editedfetchUpdateFieldVisibility
        let email = this.props.user.user.Email
        let solutionInScope = this.state.selectedIndirectScope
        let finalObject = [];
        updatedRecords.map(function (data) {
            let record = {
                'READ_WRITE_FLAG': 'W',
                'SCREEN_FLAG': 'A',
                'FIELD_VISIBILITY_ID': data.FIELD_VISIBILITY_ID,           
                'VISIBLE_FLAG': data.VISIBLE_FLAG,
                'USER_NAME':email,
                'TAB_NAME':data.TAB_NAME
            }
            finalObject.push(record)
        });

        this.props.FetchUpdateFieldVisibility(finalObject).then((response) => {
            if (response.status === 200) {
                this.setState({ fetchUpdateFieldVisibility: response.data.Result, editedfetchUpdateFieldVisibility: [], isLoading: false, responseMessage: this.props.content.successMessage, isError: false});
            }
            else {
                this.setState({ isLoading: false, responseMessage: this.props.content.errorMessage, isError: true});
            }
        }).catch((error) => {
            this.setState({ isLoading: false, responseMessage: this.props.content.errorMessage, isError: true });
        })
    }

    componentWillUnmount() {
        this.props.resetError()
    }

    render() {
        var _instance = this
        var fieldVisibility_Parameter = this.state.fieldVisibilityData.map(function (data, index) {
            return (<TableRows
                fieldVisibilityData={data}
                index={index}
                allRows={_instance.state.fieldVisibilityData}
                readOnly={_instance.props.readOnly}
                trackScheduleFactorsChanges={_instance.trackScheduleFactorsChanges}
                wizard={_instance.props.wizard}               
            />
            );
        });

        return (
            <div className="grid-x" style={{ width: '100%'}}>
                <div className="medium-12">
                    {(this.state.isLoading) ?
                        <img style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
                            src={path + `images/ajax-loader.gif`} alt="" /> : null}
                </div>
                <div className="medium-12">
                    <div className="grid-x">
                        <div className="medium-3 float-left" style={{ fontSize: '1.2rem', paddingBottom: '8px' }}>{BreakLine(this.props.content.fieldVisibilityTitle)}</div>
                        <div className="medium-6" style={{ display: 'flex', justifyContent: 'center' }} >
                            {this.state.isError ?
                                <div style={{ margin: '0 0 0.5rem', color: 'red' }}>{this.state.responseMessage}</div> :
                                <div style={{ margin: '0 0 0.5rem', color: '#4fb81c' }}>{this.state.responseMessage}</div>
                            }
                            {/* {this.state.warning1Msg !== '' && this.state.warning1Msg !== undefined ?
                                <div className="grid-x medium-12" style={{ display: 'flex', justifyContent: 'center', borderTop: '0px' }}>
                                    <div style={{ margin: '0 0 0.5rem', color: 'red' }}>{this.state.warning1Msg}</div>
                                </div>
                                : null}

                            {this.state.warning2Msg !== '' && this.state.warning2Msg !== undefined ?
                                <div className="grid-x medium-12" style={{ display: 'flex', justifyContent: 'center', borderTop: '0px' }}>
                                    <div style={{ margin: '0 0 0.5rem', color: 'red' }}>{this.state.warning2Msg}</div>
                                </div>
                                : null} */}
                        </div>
                        <div className="medium-3"></div>
                    </div>
                    <div className="grid-x">
                        <div className="medium-11 float-left pb10">
                        {this.props.content.fieldVisibilityDesc}
                        </div>
                        {this.props.user.userprofile.Roles[0].indexOf("Admin") > -1 ?
                            <div className="medium-1 pb5">
                                <button className={classNames('float-right',
                                    {
                                        'hand-cursor': this.state.isLoading ? false : (this.state.editedfetchUpdateFieldVisibility.length > 0),
                                        'gray-scale': this.state.isLoading ? true : (this.state.editedfetchUpdateFieldVisibility.length === 0)
                                    })}
                                    onClick={this.onfetchUpdateFieldVisibilitySave} disabled={this.state.isLoading ? true : this.state.editedfetchUpdateFieldVisibility.length === 0} type="submit" float >
                                    <img width="25" title={this.props.content.save} src="images/save_active.svg" />
                                </button>
                            </div> : null}
                    </div>
                </div>                

                {this.state.fieldVisibilityData.length > 0 ?
                    <div className="medium-12">
                        <Form model="netwrokEnablementMatchTypeParameter">
                            <div className="basic-table">
                                <table>
                                    <thead>
                                        <tr className="eachRow">
                                            {/* <th style={{width:'0px'}}></th>                                         */}
                                            <th>{this.props.content.firstColumnName}</th>
                                            <th>{this.props.content.visibilityFlag}</th>                                           
                                            <th>{this.props.content.updatedBy}</th>
                                            <th>{this.props.content.updatedDate}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {fieldVisibility_Parameter}
                                    </tbody>
                                </table>
                            </div>
                        </Form>
                    </div>
                    : null
                }
            </div>
        )
    }
}

class TableRows extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    onChangeVisibleFlagEvent(val) {
        var editingRow = this.props.fieldVisibilityData;
        let editedValue = val.target.value.trim();
        //if (editedValue.toString() !== editingRow[val.target.id].toString()) {
            editingRow[val.target.id] = editedValue
            this.props.trackScheduleFactorsChanges(editingRow)
        //}
    }
    
    render() {
        var bindValues = this.props.fieldVisibilityData;
        return (
            <tr className="eachRow">
                {/* <td style={{width:'0px'}} model="fetchUpdateFieldVisibility.ENABLE_WV_FIELD_PRIORITY_ID">{bindValues.ENABLE_WV_FIELD_PRIORITY_ID}</td>                 */}
                <td model="fetchUpdateFieldVisibility.FIELD_NAME">{bindValues.FIELD_NAME}</td>
                <td>
                    <select style={{ height: '30px', width: '100px' }}
                        id={'VISIBLE_FLAG'}
                        onChange={this.onChangeVisibleFlagEvent.bind(this)}
                        value={bindValues.VISIBLE_FLAG}
                        readOnly={this.props.readOnly}
                        disabled={this.props.readOnly}
                    >
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>
                </td>              

                <td model="fetchUpdateFieldVisibility.USER_NAME">{bindValues.USER_NAME}</td>
                <td model="fetchUpdateFieldVisibility.UPDATED_DTM">{moment(bindValues.UPDATED_DTM).format('L')}</td>
            </tr>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        language: state.language,
        ui: state.content.ui,
        user: state.default,
        spendDetails: state.spendDetails,
        //content: state.content.spendAnalysis.enablementResourceParamaeters,
        content: state.content.menu.EnablementParametersManager[0].FieldVisibilityTab,
        wizard: state.content.spendAnalysis.prioritizationWizard 
    }
}


const mapDispatchToProps = (dispatch) => {
    return {        
        resetError: () => dispatch(resetError()),             
        FetchUpdateFieldVisibility: (data) => dispatch(FetchUpdateFieldVisibility(data))        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FieldVisibility)


