import React from 'react'
import { connect } from 'react-redux'
import {
    getKPIDashBoardLoadData,
    updateKPIDashBoardTabData,
    getManualKPIDashBoardLoadData,
    updateEnablementStrategyWaveData,
    updateEnablementKPIDashBoardTabData,
    getUpdateEnablementKPIModel,
    GetManualKPIDashBoardLoadDataPercentCalc,
    saveIntermediateEnablementKPIDashBoardTabData,
    deleteIntermediateEnablementKPIDashBoardTabData
    //getManualKPIManualTargetFetch
} from 'actions/kpiActions'
import {
    resetError,
    defineScopeLoading,
    pendingChanges,
    saveKPIOnRedirect,
    deleteIntermediateKPIOnRedirect,
    kpiModelChanged
} from 'actions/spendActions'
import Select from 'react-select'
import { roundFix, BreakLine, round, formatThousands, addCurrencyMask, convertToNumber, convertToMillion, onChangeRemoveMask } from 'utils/Utils'
import reactSelectCjsDev from 'react-select/dist/react-select.cjs.dev';
import { path, routes, kpiRowCustomOrder, kpiFormatRowWithThousandMillion, kpiModel, kpiTargetShortNames } from 'Constants'
import classNames from "classnames";
import Button from 'UI/ButtonWithoutLoader'
import Popup from "reactjs-popup";
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import EnablementKPITable from './EnablementKPITable'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Modal from 'reboron/OutlineModal'
import { changeModalStyle, changeModalContentStyle, backdropStyle, ROOT_URL, kpiConstantModalStyle } from 'Constants'

const changeBackdropStyle = Object.assign({}, backdropStyle);
changeBackdropStyle.zIndex = '1050';

const alertModalStyle = Object.assign({}, changeModalStyle);
alertModalStyle.zIndex = '1060';
alertModalStyle.width = '33%';

const kpiModalStyle = Object.assign({}, kpiConstantModalStyle);
kpiModalStyle.zIndex = '1060';
//kpiModalStyle.width = '1000px'

class EnablementStrategyKPI extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            //resourceHeaders: ['Resource Type', 'Q1', 'Q2', 'Q3', 'Q4', 'Q5', 'Q6', 'Q7', 'Q8', 'Q9', 'Q10', 'Q11', 'Q12'],
            headers: ['KPI Target', 'Metric', 'Q1', 'Q2', 'Q3', 'Q4', 'Q5', 'Q6', 'Q7', 'Q8', 'Q9', 'Q10', 'Q11', 'Q12', 'On Going Standard'],
            //quaterly data may be added revisit logic 
            colGroupYearly: [{ key: 1, value: '' }, { key: 1, value: '' }, { key: 1, value: '' }, { key: 1, value: '' }, { key: 1, value: '' }, { key: 1, value: '' }, { key: 2, value: 'Year1' }, { key: 2, value: 'Year2' }, { key: 2, value: 'Year3' }],
            tableData: [],
            editedRowsIds: [],
            selectedBPValue: 'Average 50%',
            metricValues: [],
            selectedMetricValue: 'ALL',
            selectedBPFlag: 'AVERAGE',
            kpiBenchMarkFilters: { metric: 'ALL', type: 'AVERAGE' },
            isExpand: true,
            selectedKPIButton: 3,
            selectedKPIButtonBeforeAlert: 3,
            // resourceTypeData: [],
            goLiveDate: null,
            startdate: null,
            didComponentMount: false,
            KPIModelDB: 1,
            //isDataLoaded : false,
            KPIheaders2: ['KPI Target', 'Addressable Totals', 'Metrics', 'Q1', 'Q2', 'Q3', 'Q4', 'Q5', 'Q6', 'Q7', 'Q8', 'Q9', 'Q10', 'Q11', 'Q12', 'On Going Standard'],
            KPItableData: [],
            metricID_Edited: [],
            kpiPopupError: [],
        }
        this.loadData = this.loadData.bind(this);
        this.createTableData = this.createTableData.bind(this);
        this.createTableDataObject = this.createTableDataObject.bind(this);
        this.handleActualValChange = this.handleActualValChange.bind(this);
        this.handleTargetInQuarterValChange = this.handleTargetInQuarterValChange.bind(this);
        this.onSave = this.onSave.bind(this);
        this.setStartDate = this.setStartDate.bind(this);
        this.setGoLiveDate = this.setGoLiveDate.bind(this);
        //this.loadResponsetype = this.loadResponsetype.bind(this);
    }

    setStartDate(date) {
        this.props.pendingChanges(true)
        this.setState({ startdate: date, isSaveInProgress: true, responseMsg: "Pending changes. Click save to update KPIs.", })
    }

    setGoLiveDate(date) {
        this.props.pendingChanges(true);
        this.setState({ goLiveDate: date, isSaveInProgress: true, responseMsg: "Pending changes. Click save to update KPIs.", })
    }

    componentDidMount() {
        this.loadData(true)
        this.setState({ currencySymbol: this.props.spendDetails.currentActiveRequest !== undefined ? this.props.currencySymbols[this.props.spendDetails.currentActiveRequest.Currency.trim()] : '' })
    }

    componentDidUpdate(nextProps) {
        if (
            (nextProps.kpiSelectedModel != this.props.kpiSelectedModel
                || nextProps.kpiSelectedButton != this.props.kpiSelectedButton
                || nextProps.isSaveClicked != this.props.isSaveClicked
                || nextProps.isKPIDataLoaded != this.props.isKPIDataLoaded
                //|| nextProps.isKPIDataLoaded != this.props.isKPIDataLoaded
                || nextProps.isResourceParamterSaved !== this.props.isResourceParamterSaved
            ) &&
            this.props.isKPIDataLoaded == false
            //&& prevState.didComponentMount != this.state.didComponentMount
        ) {
            setTimeout(() => {
                if (this.props.isKPIDataLoaded == false) {
                    this.loadData(false);
                }
            }, 1000)
        }

        if ((nextProps.isDeleteKPITable != this.props.isDeleteKPITable) && this.props.isDeleteKPITable) {
            this.deleteIntermediateKPITable();
        }

    }

    deleteIntermediateKPITable() {
        let dmcId = this.props.spendDetails.currentActiveRequest.DMC_ID;
        let vsId = this.props.spendDetails.currentActiveRequest.VS_ID;
        let user = this.props.user.user.Email;
        this.props.deleteIntermediateEnablementKPIDashBoardTabData(dmcId, vsId, user)
            .then((response) => {
            })
    }

    loadData(isLoad) {
        if (!isLoad)
            this.props.pendingChanges(true);
        let dmcId = this.props.spendDetails.currentActiveRequest.DMC_ID;
        let vsId = this.props.spendDetails.currentActiveRequest.VS_ID;
        let user = this.props.user.user.Email;
        let model = this.props.kpiSelectedModel == 1 ? 1 : this.state.selectedKPIButton;
        //let model = this.state.selectedKPIButton;

        this.props.defineScopeLoading(true)
        this.setState({ isLoading: true, isExpand : this.props.spendDetails.kpi_Model == 1 ? false : true })

        this.props.getUpdateEnablementKPIModel(dmcId, vsId, user, 'R', 1)
            .then((response) => {
                var data = response.data.Result[0];
                this.setState({ KPIModelDB: data.KPI_MODEL });
                //this.setState({ selectedModel: data.KPI_MODEL == null ? 1 : data.KPI_MODEL ,kpiSelectedButton: data.KPI_BUTTON == null ? 3 : data.KPI_BUTTON});
                //model = data.KPI_MODEL == null ? 1 : data.KPI_MODEL;
                if (isLoad) {
                    this.setState({ selectedKPIButton: data.KPI_BUTTON == null ? 3 : data.KPI_BUTTON });
                    model = this.state.selectedKPIButton;
                    this.props.EnablementStrategyModelUpdate(data.KPI_MODEL == null ? 1 : data.KPI_MODEL);
                }

                if (this.props.kpiSelectedModel == 2 && model == 1) {
                    model = 3;
                    this.setState({ selectedKPIButton: 3 });
                }
                else if (this.props.kpiSelectedModel == 1)
                    this.setState({ selectedKPIButton: 1 });

                this.props.getManualKPIDashBoardLoadData(dmcId, vsId, user, this.props.kpiSelectedModel, model)
                    .then((response) => {
                        if (response.data.Result.length > 0) {
                            let spData = response.data.Result;
                            let tableData = this.createTableData(spData);
                            this.props.defineScopeLoading(false);
                            let goLiveDate = spData[0]["GO_LIVE_DATE"] !== null ? new Date(spData[0]["GO_LIVE_DATE"]) : null;
                            let projectStartDate = spData[0]["PROJECT_START_DATE"] !== null ? new Date(spData[0]["PROJECT_START_DATE"]) : null;
                            this.setState({ tableData: tableData, isError: 'No', errorMsg: '', responseMsg: '', isLoading: false, goLiveDate: goLiveDate, startdate: projectStartDate })
                            let kpiButtonCallBackVal = this.state.selectedKPIButton;
                            if (this.state.selectedKPIButton == 5)
                                kpiButtonCallBackVal = 0;

                            this.props.EnablementStrategyKPIButtonClick(kpiButtonCallBackVal);
                            this.props.UpdateKPIDataLoaded(true);
                            //this.props.UpdateResourceParamDataLoaded(true);
                            if (this.state.selectedKPIButton == 6) {
                                this.loadKPIData(isLoad);
                            }
                            if (isLoad) {
                                this.saveEnabalementStrategyWave(true);
                                //this.props.UpdateKPIDataLoaded(true);
                                this.setState({ didComponentMount: true });
                            }
                            else if(!isLoad && this.props.shouldCallWaveCalc){
                                this.props.EnablementStrategyrefreshResourceTable(true);
                                this.props.pendingChanges(null);
                            }
                            else{
                                this.setState({ responseMsg: "Pending changes. Click save to update KPI Dashboard." });

                            }//this.props.EnablementStrategyrefreshResourceTable(true);
                            //this.loadResponsetype();
                        } else {
                            this.props.defineScopeLoading(false)
                            this.setState({ errorMsg: 'No result found. Please try reimporting the file.', isError: 'Yes', isLoading: false })
                        }

                    }).catch((error) => {
                        if (error.stack.includes('Network Error')) {
                            this.props.defineScopeLoading(false)
                            this.setState({ errorMsg: 'API service is down. Please contact administrator.', isError: 'Yes', isLoading: false })
                        }
                        else {
                            this.props.defineScopeLoading(false)
                            this.setState({ errorMsg: this.props.content.error, isError: 'Yes', isLoading: false })
                        }
                    });
            });
    }

    // benchMarkAdjusted(element) {
    //     let val = onChangeRemoveMask(element, '%')
    //     let id = element.target.id;
    //     let data = this.state.tableData;
    //     let unique = this.state.metricFilteredData;
    //     let selectedBPValue = this.state.selectedBPValue
    //     let key = ''
    //     let flag = ''
    //     if (selectedBPValue == 'Top 25%') {
    //         key = 'TOP_BENCHMARK_ADJUS'
    //         flag = 'TOP_25'
    //     } else if (selectedBPValue == 'Average 50%') {
    //         key = 'AVG_BENCHMARK_ADJUS'
    //         flag = 'AVERAGE'
    //     } else {
    //         key = 'BOTTOM_BENCHMARK_ADJUS'
    //         flag = 'BOTTOM_25'
    //     }

    //     let metricID_Edited = this.state.metricID_Edited;
    //     metricID_Edited.push(data[id]['METRIC_ID'])

    //     if (data[id]['BA1'] != val) {
    //         data[id]['BA1'] = val;
    //         this.state.selectedSpanValue == 'Half' ? this.calcBenchMarkSpend(data[id], id, data) : this.calcBenchMarkSpendForQuater(data[id], id, data)
    //         this.props.pendingChanges(true)
    //         this.setState({ unique: unique, isSaveInProgress: true, responseMsg: "Pending changes. Click save to update KPIs.", keyBPSelected: key, selectedBPFlag: flag, metricID_Edited: metricID_Edited })
    //     }
    // }

    hQPercentChanged(element) {
        let cellId = element.target.attributes.cellId.nodeValue;
        let val = onChangeRemoveMask(element, '%');
        let id = element.target.id;
        let data = this.state.KPItableData;
        let unique = this.state.metricFilteredData;
        let metricID_Edited = this.state.metricID_Edited;
        metricID_Edited.push(data[id]['METRIC_ID'])
        if (data[id][cellId] != val) {
            data[id][cellId] = val;
            this.calcBenchMarkSpendForQuater(data[id], id, data)
            this.props.pendingChanges(true)
            this.setState({ KPItableData: data, unique: unique, isSaveInProgress: true, responseMsg: "Pending changes. Click save to update KPIs.", metricID_Edited: metricID_Edited })
        }
    }

    //createKPITableData(data, selectedBPValue) {
    createKPITableData(data) {
        let tableData = []
        // let key = '' //this key is used to map ui metric value with HANA col value
        // if (selectedBPValue == 'Top 25%') {
        //     key = 'TOP_25'
        // } else if (selectedBPValue == 'Average 50%') {
        //     key = 'AVERAGE'
        // } else {
        //     key = 'BOTTOM_25'
        // }
        // //filter data to get unique arr with selected metric and industry values
        // let unique = data.filter(item => item.BENCHMARK_TYPE == key)

        let unique = data;

        //use kpiArr to further filter data on granular level as HANA data has many duplicate records
        let kpiArr = ['Invoice Spend on Business Network', 'Invoice Count via Business Network', 'PO Spend on Business Network', 'PO Count via Business Network', 'Suppliers in Business Network']
        for (let i = 0; i < kpiArr.length; i++) {
            let temp = unique.filter(item => item.KPI_NAME == kpiArr[i])
            let obj = this.createKPITableDataObject(temp)
            tableData.push(obj)
        }
        //tableData.sort((a, b) => (a.srNo > b.srNo) ? 1 : -1)//sort the table always by sr_no
        tableData.sort((a, b) => kpiRowCustomOrder[a.srNo] - kpiRowCustomOrder[b.srNo]);
        return tableData;
    }

    remove(array, key, value) {
        const index = array.findIndex(obj => obj[key] === value);
        if (index >= 0)
            array.splice(index, 1);
    }

    calcBenchMarkSpendForQuater(obj, key, data) {
        // //benchmark calc (BR2, BA2)
        // let spend1 = round((obj.BR1 * obj.efo) / 100, 2);
        // let spend2 = round((obj.BA1 * obj.efo) / 100, 2);

        // data[key].BR2 = spend1;
        // data[key].BA2 = spend2;

        //cumulative % calc (H12, H22, H32, H42, H52, H62)
        let element = data[key];
        element.HQ12 = Number(element.HQ11);
        element.HQ22 = Number(element.HQ11) + Number(element.HQ21);
        element.HQ32 = Number(element.HQ11) + Number(element.HQ21) + Number(element.HQ31);
        element.HQ42 = Number(element.HQ11) + Number(element.HQ21) + Number(element.HQ31) + Number(element.HQ41);
        element.HQ52 = Number(element.HQ11) + Number(element.HQ21) + Number(element.HQ31) + Number(element.HQ41) + Number(element.HQ51);
        element.HQ62 = Number(element.HQ11) + Number(element.HQ21) + Number(element.HQ31) + Number(element.HQ41) + Number(element.HQ51) + Number(element.HQ61);
        element.HQ72 = Number(element.HQ11) + Number(element.HQ21) + Number(element.HQ31) + Number(element.HQ41) + Number(element.HQ51) + Number(element.HQ61) + Number(element.HQ71);
        element.HQ82 = Number(element.HQ11) + Number(element.HQ21) + Number(element.HQ31) + Number(element.HQ41) + Number(element.HQ51) + Number(element.HQ61) + Number(element.HQ71) + Number(element.HQ81);
        element.HQ92 = Number(element.HQ11) + Number(element.HQ21) + Number(element.HQ31) + Number(element.HQ41) + Number(element.HQ51) + Number(element.HQ61) + Number(element.HQ71) + Number(element.HQ81) + Number(element.HQ91);
        element.HQ102 = Number(element.HQ11) + Number(element.HQ21) + Number(element.HQ31) + Number(element.HQ41) + Number(element.HQ51) + Number(element.HQ61) + Number(element.HQ71) + Number(element.HQ81) + Number(element.HQ91) + Number(element.HQ101);
        element.HQ112 = Number(element.HQ11) + Number(element.HQ21) + Number(element.HQ31) + Number(element.HQ41) + Number(element.HQ51) + Number(element.HQ61) + Number(element.HQ71) + Number(element.HQ81) + Number(element.HQ91) + Number(element.HQ101) + Number(element.HQ111);
        element.HQ122 = Number(element.HQ11) + Number(element.HQ21) + Number(element.HQ31) + Number(element.HQ41) + Number(element.HQ51) + Number(element.HQ61) + Number(element.HQ71) + Number(element.HQ81) + Number(element.HQ91) + Number(element.HQ101) + Number(element.HQ111) + Number(element.HQ121);


        this.remove(this.state.kpiPopupError, "name", kpiTargetShortNames[element.kpi]);

        if (element.HQ122 == 0) {
            this.setState({ isPopupError: 'No', popupErrorMsg: '' })
        }
        else if (element.HQ122 > 100) {
            let obj = {};
            obj.name = kpiTargetShortNames[element.kpi];
            obj.isPopupError = 'Yes';
            obj.popupErrorMsg = "Error: " + kpiTargetShortNames[element.kpi] + " KPI is greater than 100%.";
            this.state.kpiPopupError.push(obj);
            this.setState({ isPopupError: 'Yes', popupErrorMsg: "Error: " + kpiTargetShortNames[element.kpi] + " KPI is greater than 100%." })
        }
        else if (element.HQ122 < 100) {
            let obj = {};
            obj.name = kpiTargetShortNames[element.kpi];
            obj.isPopupError = 'Warning';
            obj.popupErrorMsg = "Warning: " + kpiTargetShortNames[element.kpi] + " KPI is less than 100%.";
            this.state.kpiPopupError.push(obj);
            this.setState({ isPopupError: 'Warning', popupErrorMsg: "Error: " + kpiTargetShortNames[element.kpi] + " KPI is less than 100%." })
        }
        else {
            this.setState({ isPopupError: 'No', popupErrorMsg: '' })
        }

        //Target H calc (H13, H23, H33, H43, H53, H63)
        element.HQ13 = round((element.spend * element.HQ11) / 100, 2);
        element.HQ23 = round((element.spend * element.HQ21) / 100, 2);
        element.HQ33 = round((element.spend * element.HQ31) / 100, 2);
        element.HQ43 = round((element.spend * element.HQ41) / 100, 2);
        element.HQ53 = round((element.spend * element.HQ51) / 100, 2);
        element.HQ63 = round((element.spend * element.HQ61) / 100, 2);
        element.HQ73 = round((element.spend * element.HQ71) / 100, 2);
        element.HQ83 = round((element.spend * element.HQ81) / 100, 2);
        element.HQ93 = round((element.spend * element.HQ91) / 100, 2);
        element.HQ103 = round((element.spend * element.HQ101) / 100, 2);
        element.HQ113 = round((element.spend * element.HQ111) / 100, 2);
        element.HQ123 = round((element.spend * element.HQ121) / 100, 2);

        //Target Cumulative calc (H14, H24, H34, H44, H54, H64)
        element.HQ14 = round(element.HQ13, 2);
        element.HQ24 = round(element.HQ13 + element.HQ23, 2);
        element.HQ34 = round(element.HQ13 + element.HQ23 + element.HQ33, 2);
        element.HQ44 = round(element.HQ13 + element.HQ23 + element.HQ33 + element.HQ43, 2);
        element.HQ54 = round(element.HQ13 + element.HQ23 + element.HQ33 + element.HQ43 + element.HQ53, 2);
        element.HQ64 = round(element.HQ13 + element.HQ23 + element.HQ33 + element.HQ43 + element.HQ53 + element.HQ63, 2);
        element.HQ74 = round(element.HQ13 + element.HQ23 + element.HQ33 + element.HQ43 + element.HQ53 + element.HQ63 + element.HQ73, 2);
        element.HQ84 = round(element.HQ13 + element.HQ23 + element.HQ33 + element.HQ43 + element.HQ53 + element.HQ63 + element.HQ73 + element.HQ83, 2);
        element.HQ94 = round(element.HQ13 + element.HQ23 + element.HQ33 + element.HQ43 + element.HQ53 + element.HQ63 + element.HQ73 + element.HQ83 + element.HQ93, 2);
        element.HQ104 = round(element.HQ13 + element.HQ23 + element.HQ33 + element.HQ43 + element.HQ53 + element.HQ63 + element.HQ73 + element.HQ83 + element.HQ93 + element.HQ103, 2);
        element.HQ114 = round(element.HQ13 + element.HQ23 + element.HQ33 + element.HQ43 + element.HQ53 + element.HQ63 + element.HQ73 + element.HQ83 + element.HQ93 + element.HQ103 + element.HQ113, 2);
        element.HQ124 = round(element.HQ13 + element.HQ23 + element.HQ33 + element.HQ43 + element.HQ53 + element.HQ63 + element.HQ73 + element.HQ83 + element.HQ93 + element.HQ103 + element.HQ113 + element.HQ123, 2);

        this.setState({ KPItableData: data })
    }

    //this function creates an object for each single row of kpi setup table which is used to construct table data
    createKPITableDataObject(kpiArr) {
        let obj = {};
        for (let i = 0; i < kpiArr.length; i++) {
            obj.METRIC_ID = kpiArr[i]['METRIC_ID']
            obj.VS_ID = kpiArr[i]['VS_ID']
            obj.DMC_ID = kpiArr[i]['DMC_ID']
            obj.kpi = kpiArr[i]['KPI_NAME']
            obj.srNo = kpiArr[i]['KPI_SR_NO']
            obj.BENCHMARK_METRICS = kpiArr[i]['BENCHMARK_METRICS']
            if (i == 0) obj.spend = kpiArr[i]['SPEND_DATA_VALUE']
            obj.efo = kpiArr[i]['ELIGIBLE_FOR_ONBOARDING']
            obj.efo2 = kpiArr[i]['ELIGIBLE_FOR_ONBOARDING'] / 1000000 //adding this for masked input for spend data
            obj.BR1 = roundFix(kpiArr[i]['BENCHMARK_RECOMD'], 2)
            obj.BR2 = 0
            obj.BA1 = roundFix(kpiArr[i]['BENCHMARK_ADJUS'], 2)
            obj.BA2 = 0
            obj.HR = 'hr'
            if (kpiArr[i]['QUARTER_NO'] == 'Q1') obj.HQ11 = kpiArr[i]['H_PERCENTAGE_VALUE']
            obj.HQ12 = 0
            obj.HQ13 = 0
            obj.HQ14 = 0
            if (kpiArr[i]['QUARTER_NO'] == 'Q2') obj.HQ21 = kpiArr[i]['H_PERCENTAGE_VALUE']
            obj.HQ22 = 0
            obj.HQ23 = 0
            obj.HQ24 = 0
            if (kpiArr[i]['QUARTER_NO'] == 'Q3') obj.HQ31 = kpiArr[i]['H_PERCENTAGE_VALUE']
            obj.HQ32 = 0
            obj.HQ33 = 0
            obj.HQ34 = 0
            if (kpiArr[i]['QUARTER_NO'] == 'Q4') obj.HQ41 = kpiArr[i]['H_PERCENTAGE_VALUE']
            obj.HQ42 = 0
            obj.HQ43 = 0
            obj.HQ44 = 0
            if (kpiArr[i]['QUARTER_NO'] == 'Q5') obj.HQ51 = kpiArr[i]['H_PERCENTAGE_VALUE']
            obj.HQ52 = 0
            obj.HQ53 = 0
            obj.HQ54 = 0
            if (kpiArr[i]['QUARTER_NO'] == 'Q6') obj.HQ61 = kpiArr[i]['H_PERCENTAGE_VALUE']
            obj.HQ62 = 0
            obj.HQ63 = 0
            obj.HQ64 = 0
            if (kpiArr[i]['QUARTER_NO'] == 'Q7') obj.HQ71 = kpiArr[i]['H_PERCENTAGE_VALUE']
            obj.HQ72 = 0
            obj.HQ73 = 0
            obj.HQ74 = 0
            if (kpiArr[i]['QUARTER_NO'] == 'Q8') obj.HQ81 = kpiArr[i]['H_PERCENTAGE_VALUE']
            obj.HQ82 = 0
            obj.HQ83 = 0
            obj.HQ84 = 0
            if (kpiArr[i]['QUARTER_NO'] == 'Q9') obj.HQ91 = kpiArr[i]['H_PERCENTAGE_VALUE']
            obj.HQ92 = 0
            obj.HQ93 = 0
            obj.HQ94 = 0
            if (kpiArr[i]['QUARTER_NO'] == 'Q10') obj.HQ101 = kpiArr[i]['H_PERCENTAGE_VALUE']
            obj.HQ102 = 0
            obj.HQ103 = 0
            obj.HQ104 = 0
            if (kpiArr[i]['QUARTER_NO'] == 'Q11') obj.HQ111 = kpiArr[i]['H_PERCENTAGE_VALUE']
            obj.HQ112 = 0
            obj.HQ113 = 0
            obj.HQ114 = 0
            if (kpiArr[i]['QUARTER_NO'] == 'Q12') obj.HQ121 = kpiArr[i]['H_PERCENTAGE_VALUE']
            obj.HQ122 = 0
            obj.HQ123 = 0
            obj.HQ124 = 0
            if (kpiArr[i]['QUARTER_NO'] == 'Q13') {
                obj.HQ131 = kpiArr[i]['H_PERCENTAGE_VALUE']
                obj.HQ132 = kpiArr[i]['H_PERCENTAGE_VALUE']
                obj.HQ133 = kpiArr[i]['TARGET_VALUE']
                obj.HQ134 = kpiArr[i]['CUMULATIVE_TARGET_VALUE']
            }
            obj.H11 = Number(obj.HQ11) + Number(obj.HQ21)
            obj.H12 = 0
            obj.H13 = 0
            obj.H14 = 0
            obj.H21 = Number(obj.HQ31) + Number(obj.HQ41)
            obj.H22 = 0
            obj.H23 = 0
            obj.H24 = 0
            obj.H31 = Number(obj.HQ51) + Number(obj.HQ61)
            obj.H32 = 0
            obj.H33 = 0
            obj.H34 = 0
            obj.H41 = Number(obj.HQ71) + Number(obj.HQ81)
            obj.H42 = 0
            obj.H43 = 0
            obj.H44 = 0
            obj.H51 = Number(obj.HQ91) + Number(obj.HQ101)
            obj.H52 = 0
            obj.H53 = 0
            obj.H54 = 0
            obj.H61 = Number(obj.HQ111) + Number(obj.HQ121)
            obj.H62 = 0
            obj.H63 = 0
            obj.H64 = 0


        }

        return obj;
    }

    loadKPIData(isLoad) {
        let dmcId = this.props.spendDetails.currentActiveRequest.DMC_ID;
        let vsId = this.props.spendDetails.currentActiveRequest.VS_ID;
        let user = this.props.user.user.Email;
        this.props.defineScopeLoading(true)
        this.setState({ isLoading: true })
        this.props.GetManualKPIDashBoardLoadDataPercentCalc(dmcId, vsId, user)
            .then((response) => {
                if (response.data.Result.length > 0) {
                    let spData = response.data.Result;
                    // let unique = spData.filter(item => item.BENCHMARK_METRICS == this.state.selectedMetricValue)
                    // //https://stackoverflow.com/questions/15125920/how-to-get-distinct-values-from-an-array-of-objects-in-javascript
                    // // let unique = [...new Map(temp.map(item =>
                    // //     [item['METRIC_ID'], item])).values()];

                    // let uniqueMetrics = [...new Set(spData.map(item => item.BENCHMARK_METRICS))];
                    // let metricValues = [];
                    // for (let i = 0; i < uniqueMetrics.length; i++) {
                    //     let obj = {}
                    //     obj.label = uniqueMetrics[i]
                    //     obj.value = uniqueMetrics[i]

                    //     metricValues.push(obj)
                    // }

                    //let KPItableData = this.createKPITableData(unique, this.state.selectedBPValue)
                    let KPItableData = this.createKPITableData(spData);

                    let data = KPItableData;
                    for (let i = 0; i < data.length; i++) {
                        this.calcBenchMarkSpendForQuater(data[i], i, data)
                    }
                    this.props.defineScopeLoading(false)
                    this.setState({ KPItableData: KPItableData, isLoading: false, isSaveInProgress: false, dataLoaded: true })
                    if (!isLoad)
                        this.showModal("KPI");
                } else {
                    this.props.defineScopeLoading(false)
                    this.setState({ errorMsg: 'No result found. Please try reimporting the file.', isError: 'Yes', isLoading: false })
                }

            }).catch((error) => {
                if (error.stack.includes('Network Error')) {
                    this.props.defineScopeLoading(false)
                    this.setState({ errorMsg: 'API service is down. Please contact administrator.', isError: 'Yes', isLoading: false })
                }
                else {
                    this.props.defineScopeLoading(false)
                    this.setState({ errorMsg: this.props.content.error, isError: 'Yes', isLoading: false })
                }
            })
    }


    createTableData(spData) {
        let tableData = [];

        //create an array with unique kpi names from sp data
        let kpiArr = [...new Set(spData.map(item => item.KPI_NAME))];

        //for every unique kpi name create table data for 12 quaters
        for (let i = 0; i < kpiArr.length; i++) {
            let temp = spData.filter(item => item.KPI_NAME == kpiArr[i])
            let obj = this.createTableDataObject(temp)
            tableData.push(obj)
        }
        //tableData.sort((a, b) => (a.srNo > b.srNo) ? 1 : -1)//sort the table always by sr_no


        tableData.sort((a, b) => kpiRowCustomOrder[a.srNo] - kpiRowCustomOrder[b.srNo]);

        return tableData;
    }



    createTableDataObject(kpiArr) {
        let obj = {}
        for (let i = 0; i < kpiArr.length; i++) {
            obj.VS_ID = kpiArr[i]['VS_ID']
            obj.DMC_ID = kpiArr[i]['DMC_ID']
            obj.kpi = kpiArr[i]['KPI_NAME']
            obj.QUARTER_NO = kpiArr[i]['QUARTER_NO']
            obj.srNo = kpiArr[i]['SR_NO']
            obj.HR = 'hr'
            for (let j = 1; j <= 13; j++) {
                // if (kpiArr[i]['QUARTER_NO'] == 'Q' + j) obj['TagetKpi_Q' + j] = kpiArr[i]['TARGET_KPI_CUMULATIVE']
                // if (kpiArr[i]['QUARTER_NO'] == 'Q' + j) obj['SE_Targeted_Q' + j] = kpiArr[i]['SE_TARGETED']
                // if (kpiArr[i]['QUARTER_NO'] == 'Q' + j) obj['SE_Estimated_Q' + j] = kpiArr[i]['SE_ESTIMATED']
                // if (kpiArr[i]['QUARTER_NO'] == 'Q' + j) obj['Actual_Q' + j] = kpiArr[i]['SR_NO'] % 2 == 0 ? kpiArr[i]['ACTUAL_VALUE'] / 1000000 : kpiArr[i]['ACTUAL_VALUE']
                // if (kpiArr[i]['QUARTER_NO'] == 'Q' + j) obj['Gap_Q' + j] = kpiArr[i]['GAP_VALUE']
                // if (kpiArr[i]['QUARTER_NO'] == 'Q' + j) obj['Attainment_Q' + j] = kpiArr[i]['ATTAINMENT_VALUE']
                if (kpiArr[i]['QUARTER_NO'] == 'Q' + j) obj['TagetKpi_Q' + j] = kpiArr[i]['SE_TARGETED_CUMULATIVE']
                if (kpiArr[i]['QUARTER_NO'] == 'Q' + j) obj['SE_Targeted_Q' + j] = kpiArr[i]['SE_TARGETED']
            }
        }
        return obj;
    }

    handleActualValChange(element) {
        let id = element.target.id;
        let editedRowsIds = this.state.editedRowsIds;
        let cellId = element.target.attributes.cellId.nodeValue;
        //let val = id % 2 == 0 ? onChangeRemoveMask(element, '') : onChangeRemoveMask(element, this.state.currencySymbol);
        let val = kpiFormatRowWithThousandMillion[id] == 'Million' ? onChangeRemoveMask(element, '') : onChangeRemoveMask(element, this.state.currencySymbol);
        if (val == "") val = null;
        let data = this.state.tableData;
        if (data[id][cellId] != val) {
            data[id][cellId] = val;
            //save the edited row id number
            if (!editedRowsIds.includes(id)) editedRowsIds.push(id);
            this.runCalcFormulae(data[id], id, data)
            this.props.pendingChanges(true);
            this.setState({ editedRowsIds: editedRowsIds, isSaveInProgress: true, isError: 'No', responseMsg: "Pending changes. Click save to update KPI Dashboard." })
        }
    }

    handleTargetInQuarterValChange(element) {
        let id = element.target.id;
        let editedRowsIds = this.state.editedRowsIds;
        let cellId = element.target.attributes.cellId.nodeValue;
        //let val = id % 2 == 0 ? onChangeRemoveMask(element, '') : onChangeRemoveMask(element, this.state.currencySymbol);
        let val = kpiFormatRowWithThousandMillion[id] == 'Million' ? onChangeRemoveMask(element, '') : onChangeRemoveMask(element, this.state.currencySymbol);
        if (val == "") val = null;
        let data = this.state.tableData;
        if (data[id][cellId] != val) {
            data[id][cellId] = Number(val);
            //save the edited row id number
            if (!editedRowsIds.includes(id)) editedRowsIds.push(id);
            this.runCalcFormulae(data[id], id, data)
            this.props.pendingChanges(true);
            this.setState({ editedRowsIds: editedRowsIds, isSaveInProgress: true, isError: 'No', responseMsg: "Pending changes. Click save to update KPI Dashboard." })
        }
    }

    runCalcFormulae(obj, id, data) {
        let element = data[id];
        // Targeted Cumulative calculation       
        element.TagetKpi_Q1 = element.SE_Targeted_Q1;
        element.TagetKpi_Q2 = (element.TagetKpi_Q1 == undefined ? 0 : element.TagetKpi_Q1) + (element.SE_Targeted_Q2 == undefined ? 0 : element.SE_Targeted_Q2);
        element.TagetKpi_Q3 = (element.TagetKpi_Q2 == undefined ? 0 : element.TagetKpi_Q2) + (element.SE_Targeted_Q3 == undefined ? 0 : element.SE_Targeted_Q3);
        element.TagetKpi_Q4 = (element.TagetKpi_Q3 == undefined ? 0 : element.TagetKpi_Q3) + (element.SE_Targeted_Q4 == undefined ? 0 : element.SE_Targeted_Q4);
        element.TagetKpi_Q5 = (element.TagetKpi_Q4 == undefined ? 0 : element.TagetKpi_Q4) + (element.SE_Targeted_Q5 == undefined ? 0 : element.SE_Targeted_Q5);
        element.TagetKpi_Q6 = (element.TagetKpi_Q5 == undefined ? 0 : element.TagetKpi_Q5) + (element.SE_Targeted_Q6 == undefined ? 0 : element.SE_Targeted_Q6);
        element.TagetKpi_Q7 = (element.TagetKpi_Q6 == undefined ? 0 : element.TagetKpi_Q6) + (element.SE_Targeted_Q7 == undefined ? 0 : element.SE_Targeted_Q7);
        element.TagetKpi_Q8 = (element.TagetKpi_Q7 == undefined ? 0 : element.TagetKpi_Q7) + (element.SE_Targeted_Q8 == undefined ? 0 : element.SE_Targeted_Q8);
        element.TagetKpi_Q9 = (element.TagetKpi_Q8 == undefined ? 0 : element.TagetKpi_Q8) + (element.SE_Targeted_Q9 == undefined ? 0 : element.SE_Targeted_Q9);
        element.TagetKpi_Q10 = (element.TagetKpi_Q9 == undefined ? 0 : element.TagetKpi_Q9) + (element.SE_Targeted_Q10 == undefined ? 0 : element.SE_Targeted_Q10);
        element.TagetKpi_Q11 = (element.TagetKpi_Q10 == undefined ? 0 : element.TagetKpi_Q10) + (element.SE_Targeted_Q11 == undefined ? 0 : element.SE_Targeted_Q11);
        element.TagetKpi_Q12 = (element.TagetKpi_Q11 == undefined ? 0 : element.TagetKpi_Q11) + (element.SE_Targeted_Q12 == undefined ? 0 : element.SE_Targeted_Q12);

        this.setState({ tableData: data })
    }


    shouldComponentUpdate(nextProps) {
        if ((nextProps.isSaveClicked !== this.props.isSaveClicked
            || nextProps.isResourceParamterSaved !== this.props.isResourceParamterSaved)
            //||  this.nextProps.saveKPIOnRedirect !== this.props.saveKPIOnRedirect
            && nextProps.isSaveClicked
            && nextProps.isResourceParamterSaved
        ) {
            setTimeout(() => {
                this.props.EnablementStrategyKPIChanges();
                this.onSave();
                // if (this.state.selectedKPIButton == 5)
                //     this.onKPIManualDashboardSave();
                // else
                //     this.saveEnabalementStrategyWave();
            }, 2000)
        }
        return true
    }


    saveEnabalementStrategyWave(isLoad) {
        // let dmcId = this.props.spendDetails.currentActiveRequest.DMC_ID;
        // let vsId = this.props.spendDetails.currentActiveRequest.VS_ID;
        // let user = this.props.user.user.Email;

        // this.props.updateEnablementStrategyWaveData(dmcId, vsId, user)
        //     .then((response) => {
        //             if (response.data.Result.length > 0) { 
        //                 let spData = response.data.Result;
        //                 let tableData = this.createTableData(spData);
        //                 this.setState({ tableData: tableData, isError: 'No', errorMsg: '', responseMsg: '', isLoading: false })
        //             }

        let kpiButtonCallBackVal = this.state.selectedKPIButton;
        if (this.state.selectedKPIButton == 5)
            kpiButtonCallBackVal = 0;
        this.props.EnablementStrategyKPIButtonClick(kpiButtonCallBackVal);
        //this.props.EnablementStrategyrefreshResourceTable(true);
        this.props.UpdateResourceParamDataLoaded(true);
        //this.props.EnablementStrategyrefreshPriorityTable(true);
        this.props.pendingChanges(false);
        this.props.handleIsResourceParamterSaved(true);
        this.props.saveKPIOnRedirect(null);
        this.props.deleteIntermediateKPIOnRedirect(null);
        if(!isLoad)
            this.props.EnablementStrategyShouldCallWaveCalc(true);

        this.setState({ errorMsg: '', isError: 'No', responseMsg: '' });
        // }).catch((error) => {
        //     if (error.stack.includes('Network Error')) {
        //         this.props.defineScopeLoading(false)
        //         this.setState({ errorMsg: 'API service is down. Please contact administrator.', isError: 'Yes', isLoading: false, isSaveInProgress: false })
        //     }
        //     else {
        //         this.props.defineScopeLoading(false)
        //         this.setState({ errorMsg: this.props.content.error, isError: 'Yes', isLoading: false, isSaveInProgress: false })
        //     }
        //     this.props.handleIsResourceParamterSaved(true);
        // })
    }

    onKPIManualDashboardSave() {
        let data = []
        //let editedRowsIds = this.state.editedRowsIds;
        let editedRowsIds = [0, 1, 2, 3, 4];
        let tableData = this.state.tableData;
        for (let i = 0; i < editedRowsIds.length; i++) {
            data.push(tableData[editedRowsIds[i]])
        }

        let records = []
        let userEmail = this.props.user.user.Email;
        let BENCHMARK_METRICS = this.props.kpiFilters !== undefined ? this.props.kpiFilters.metric : this.state.kpiBenchMarkFilters.metric; //this.props.kpiFilters.metric;
        let BENCHMARK_TYPE = this.props.kpiFilters !== undefined ? this.props.kpiFilters.type : this.state.kpiBenchMarkFilters.type; //this.props.kpiFilters.type;
        let ACTUAL_FLAG = 2;
        for (let i = 0; i < data.length; i++) {
            for (let j = 1; j <= 13; j++) {
                let obj = {}
                obj["VS_ID"] = data[i].VS_ID;
                obj["DMC_ID"] = data[i].DMC_ID;
                obj["USER_ID"] = userEmail;
                obj["BENCHMARK_METRICS"] = BENCHMARK_METRICS;
                obj["BENCHMARK_TYPE"] = BENCHMARK_TYPE;
                obj["ACTUAL_FLAG"] = ACTUAL_FLAG;
                obj["QUARTER_NO"] = 'Q' + j;
                //obj["METRIC_ID"] = kpiNameMetricLookup[data[i].kpi];
                obj["KPI_NAME"] = data[i].kpi;
                //obj["ACTUAL_VALUE"] = data[i]['srNo'] % 2 == 0 ? data[i]['Actual_Q' + j] * 1000000 : data[i]['Actual_Q' + j];
                //obj["ACTUAL_VALUE"] = kpiFormatRowWithThousandMillion[kpiRowCustomOrder[data[i]['srNo']]] == 'Million' ? data[i]['Actual_Q' + j] * 1000000 : data[i]['Actual_Q' + j];
                obj["SE_TARGETED"] = kpiFormatRowWithThousandMillion[kpiRowCustomOrder[data[i]['srNo']]] == 'Million' ? data[i]['SE_Targeted_Q' + j] : data[i]['SE_Targeted_Q' + j];
                obj["SE_TARGETED_CUMULATIVE"] = kpiFormatRowWithThousandMillion[kpiRowCustomOrder[data[i]['srNo']]] == 'Million' ? data[i]['TagetKpi_Q' + j] : data[i]['TagetKpi_Q' + j];
                obj["KPI_MODEL"] = this.state.selectedKPIButton;
                obj["GO_LIVE_DATE"] = this.state.goLiveDate;
                obj["PROJECT_START_DATE"] = this.state.startdate;

                records.push(obj)
            }
        }
        this.setState({ isLoading: true, isSaveInProgress: true, responseMsg: '' })
        // this.props.defineScopeLoading(true)

        this.props.updateEnablementKPIDashBoardTabData(records)
            .then((response) => {
                //this.loadData()
                let spData = response.data.Result.Table1;
                let tableData = this.createTableData(spData);

                let goLiveDate = spData[0]["GO_LIVE_DATE"] !== null ? new Date(spData[0]["GO_LIVE_DATE"]) : null;
                let projectStartDate = spData[0]["PROJECT_START_DATE"] !== null ? new Date(spData[0]["PROJECT_START_DATE"]) : null;

                this.setState({ tableData: tableData, isLoading: false, selectedKPIButton: kpiModel[spData[0].KPI_MODEL], goLiveDate: goLiveDate, startdate: projectStartDate })
                this.setState({ responseMsg: 'Record/s has been updated successfully', isError: 'No', isLoading: false, isSaveInProgress: false, editedRowsIds: [] })
                //this.props.defineScopeLoading(false)
                this.props.pendingChanges(null);
                // let kpiButtonCallBackVal = this.state.selectedKPIButton;
                this.saveEnabalementStrategyWave(false);

                // if (this.state.selectedKPIButton == 5)
                //     kpiButtonCallBackVal = 0;
                // this.props.EnablementStrategyKPIButtonClick(kpiButtonCallBackVal);
                // this.props.EnablementStrategyrefreshResourceTable(true);
            }).catch((error) => {
                if (error.stack.includes('Network Error')) {
                    this.props.defineScopeLoading(false)
                    this.setState({ errorMsg: 'API service is down. Please contact administrator.', isError: 'Yes', isLoading: false, isSaveInProgress: false })
                }
                else {
                    this.props.defineScopeLoading(false)
                    this.setState({ errorMsg: this.props.content.error, isError: 'Yes', isLoading: false, isSaveInProgress: false })
                }
            })
    }

    onSave() {
        let dmcId = this.props.spendDetails.currentActiveRequest.DMC_ID;
        let vsId = this.props.spendDetails.currentActiveRequest.VS_ID;
        let user = this.props.user.user.Email;
        let kpiSelectedButton = 1;
        let flag = this.state.KPIModelDB != this.props.kpiSelectedModel ? 'W' : 'R';
        this.props.defineScopeLoading(true);
        this.props.getUpdateEnablementKPIModel(dmcId, vsId, user, flag, this.props.kpiSelectedModel)
            .then((response) => {
                this.props.kpiModelChanged(response.data.Result[0].KPI_MODEL);
                this.setState({ KPIModelDB: response.data.Result[0].KPI_MODEL });
                kpiSelectedButton = this.props.kpiSelectedModel == 1 ? 1 : response.data.Result[0].KPI_BUTTON;

                //if (this.state.selectedKPIButton == 5)
                this.onKPIManualDashboardSave();
                //else
                this.saveEnabalementStrategyWave(false);
                //this.onKPIManualDashboardSave();
                //this.setState({ selectedModel: response.data.Result[0].KPI_MODEL, kpiSelectedButton: kpiSelectedButton });
            });

    }

    onExpandClick(e) {
        this.setState({ isExpand: !this.state.isExpand })
    }

    onKPIButtonsClick(value) {
        if (this.state.selectedKPIButton == 5 && value != 5) {
            this.showModal("alert");
            this.setState({ selectedKPIButtonBeforeAlert: value });
        }
        else
            this.setState({ selectedKPIButton: value }, function () {
                this.afterKPIButtonsClick();
            });
    }

    afterKPIButtonsClick() {
        this.loadData(false);
    }

    showModal(action) {
        this.refs[action].show()
    }

    hideModal(action) {
        this.refs[action].hide();
        if (action == 'alert')
            this.setState({ selectedKPIButton: 5 });
    }

    continueNavigation(action) {
        this.setState({ selectedKPIButton: this.state.selectedKPIButtonBeforeAlert }, function () {
            this.afterKPIButtonsClick(); this.refs[action].hide();
        });
    }

    onKPIPercentCalcSave() {

        let tableData = this.state.tableData;
        this.state.KPItableData.map((el, index) => {
            tableData[index].SE_Targeted_Q1 = el.HQ13
            tableData[index].SE_Targeted_Q2 = el.HQ23
            tableData[index].SE_Targeted_Q3 = el.HQ33
            tableData[index].SE_Targeted_Q4 = el.HQ43
            tableData[index].SE_Targeted_Q5 = el.HQ53
            tableData[index].SE_Targeted_Q6 = el.HQ63
            tableData[index].SE_Targeted_Q7 = el.HQ73
            tableData[index].SE_Targeted_Q8 = el.HQ83
            tableData[index].SE_Targeted_Q9 = el.HQ93
            tableData[index].SE_Targeted_Q10 = el.HQ103
            tableData[index].SE_Targeted_Q11 = el.HQ113
            tableData[index].SE_Targeted_Q12 = el.HQ123
            tableData[index].SE_Targeted_Q13 = el.HQ133
            tableData[index].TagetKpi_Q1 = el.HQ14
            tableData[index].TagetKpi_Q2 = el.HQ24
            tableData[index].TagetKpi_Q3 = el.HQ34
            tableData[index].TagetKpi_Q4 = el.HQ44
            tableData[index].TagetKpi_Q5 = el.HQ54
            tableData[index].TagetKpi_Q6 = el.HQ64
            tableData[index].TagetKpi_Q7 = el.HQ74
            tableData[index].TagetKpi_Q8 = el.HQ84
            tableData[index].TagetKpi_Q9 = el.HQ94
            tableData[index].TagetKpi_Q10 = el.HQ104
            tableData[index].TagetKpi_Q11 = el.HQ114
            tableData[index].TagetKpi_Q12 = el.HQ124
            tableData[index].TagetKpi_Q13 = el.HQ134

            tableData[index].PER_VAL_Q1 = el.HQ11
            tableData[index].PER_VAL_Q2 = el.HQ21
            tableData[index].PER_VAL_Q3 = el.HQ31
            tableData[index].PER_VAL_Q4 = el.HQ41
            tableData[index].PER_VAL_Q5 = el.HQ51
            tableData[index].PER_VAL_Q6 = el.HQ61
            tableData[index].PER_VAL_Q7 = el.HQ71
            tableData[index].PER_VAL_Q8 = el.HQ81
            tableData[index].PER_VAL_Q9 = el.HQ91
            tableData[index].PER_VAL_Q10 = el.HQ101
            tableData[index].PER_VAL_Q11 = el.HQ111
            tableData[index].PER_VAL_Q12 = el.HQ121
            tableData[index].PER_VAL_Q13 = el.HQ131

            tableData[index].CUMU_PER_VAL_Q1 = el.HQ12
            tableData[index].CUMU_PER_VAL_Q2 = el.HQ22
            tableData[index].CUMU_PER_VAL_Q3 = el.HQ32
            tableData[index].CUMU_PER_VAL_Q4 = el.HQ42
            tableData[index].CUMU_PER_VAL_Q5 = el.HQ52
            tableData[index].CUMU_PER_VAL_Q6 = el.HQ62
            tableData[index].CUMU_PER_VAL_Q7 = el.HQ72
            tableData[index].CUMU_PER_VAL_Q8 = el.HQ82
            tableData[index].CUMU_PER_VAL_Q9 = el.HQ92
            tableData[index].CUMU_PER_VAL_Q10 = el.HQ102
            tableData[index].CUMU_PER_VAL_Q11 = el.HQ112
            tableData[index].CUMU_PER_VAL_Q12 = el.HQ122

            tableData[index].AGG_INV_PO_SUPP_TOTAL = el.spend

            //this.setState({ selectedKPIButton: 6 });
        })

        this.setState({ tableData: tableData });
        this.hideModal("KPI")


        let data = []
        //let editedRowsIds = this.state.editedRowsIds;
        let editedRowsIds = [0, 1, 2, 3, 4];
        for (let i = 0; i < editedRowsIds.length; i++) {
            data.push(tableData[editedRowsIds[i]])
        }

        let records = []
        let userEmail = this.props.user.user.Email;
        let ACTUAL_FLAG = 2;
        for (let i = 0; i < data.length; i++) {
            for (let j = 1; j <= 13; j++) {
                let obj = {}
                obj["VS_ID"] = data[i].VS_ID;
                obj["DMC_ID"] = data[i].DMC_ID;
                obj["USER_ID"] = userEmail;
                // obj["ACTUAL_FLAG"] = ACTUAL_FLAG;
                obj["QUARTER_NO"] = 'Q' + j;
                obj["KPI_NAME"] = data[i].kpi;
                obj["SE_TARGETED"] = kpiFormatRowWithThousandMillion[kpiRowCustomOrder[data[i]['srNo']]] == 'Million' ? data[i]['SE_Targeted_Q' + j] : data[i]['SE_Targeted_Q' + j];
                obj["SE_TARGETED_CUMULATIVE"] = kpiFormatRowWithThousandMillion[kpiRowCustomOrder[data[i]['srNo']]] == 'Million' ? data[i]['TagetKpi_Q' + j] : data[i]['TagetKpi_Q' + j];
                obj["KPI_MODEL"] = this.state.selectedKPIButton;
                obj["PER_VAL"] = data[i]['PER_VAL_Q' + j]
                obj["CUMU_PER_VAL"] = data[i]['CUMU_PER_VAL_Q' + j]
                obj["AGG_INV_PO_SUPP_TOTAL"] = data[i]['AGG_INV_PO_SUPP_TOTAL']
                records.push(obj)
            }
        }

        this.props.saveIntermediateEnablementKPIDashBoardTabData(records)
            .then((response) => {


            });
    }

    render() {
        let currencySymbol = this.state.currencySymbol;
        const userInputPrefix = currencySymbol;

        const userInputSuffix = 'M';

        const numberMask = createNumberMask({
            prefix: userInputPrefix + '',
            //suffix: ' ' + userInputSuffix,
            suffix: '',
            allowDecimal: false
        })
        const numberMaskForThousands = createNumberMask({
            prefix: '',
            suffix: '',
            allowDecimal: false
        })
        const numberMaskPercentage = createNumberMask({
            prefix: '',
            suffix: ' ' + '%',
            allowDecimal: true
        })

        return (
            <div style={{ padding: '10px' }}>
                <div className="grid-x medium-12 heading-with-below-border">
                    {/* <div className="medium-11 float-left">{BreakLine(this.props.content.title)}</div> */}
                    <div className="medium-11 float-left">
                        {this.props.kpiSelectedModel == 2 ?
                            this.props.kpiLables.kpiEnablementHeader :
                            this.props.kpiLables.intelligentEnablementHeader}

                        <span>
                            <Popup className="report-tooltip"
                                trigger={<img style={{ marginLeft: '5px', paddingBottom: '20px' }} className="info-icon" src="images/info-icon.svg" width="15" />}
                                position="bottom left"
                                on="hover"
                            >
                                <div>
                                    {this.props.kpiSelectedModel == 2 ?
                                        this.props.kpiLables.kpiEnablementHeaderIcon
                                        : this.props.kpiLables.inteliEnablementHeaderIcon
                                    }
                                </div>
                            </Popup>
                        </span>

                    </div>
                    <div className="medium-1" style={{ textAlign: 'right', cursor: 'pointer', fontSize: '1.6rem' }} onClick={(e) => this.onExpandClick(e)} >{this.state.isExpand ? "-" : "+"}</div>
                </div>

                {this.state.isExpand ?
                    <div className="kpiDashTable grid-x">
                        <div className="medium-12">
                            {(this.state.isLoading) ?
                                <img style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={path + `images/ajax-loader.gif`} alt="" />
                                : null
                            }
                            {(this.state.isError === 'Yes') ?
                                <div style={{ color: 'red', textAlign: 'center' }} className="cell pt5">{this.state.errorMsg}</div>
                                : <div style={{ color: 'green', textAlign: 'center' }} className="cell pt5">{this.state.responseMsg}</div>
                            }
                        </div>
                        <div className="grid-x medium-12">


                        </div>
                        {/* <div className="medium-12">
                            <button className={classNames('float-right', 'pr25',
                                {
                                    'hand-cursor': this.state.isSaveInProgress ? true : false,
                                    'gray-scale': this.state.isSaveInProgress ? false : true
                                })}
                                onClick={this.onSave}
                                disabled={this.state.isSaveInProgress ? false : true}
                                title={this.props.ui.save} type="submit" float >
                                <img width="20" src="images/save_active.svg" />
                            </button>
                        </div> */}

                        {this.props.kpiSelectedModel == 2 ?
                            <div className="grid-x medium-12 mt20">

                                {/* <div className="medium-2">
                                <Button
                                    content={this.props.kpiLables.enablementButton1}
                                    {...(this.state.selectedKPIButton == 1 ? { highlight: 1 } : { color: 1 })}
                                    onClick={() => this.onKPIButtonsClick(1)}
                                />
                            </div> */}
                                <div className="medium-2">
                                    <Button
                                        content={this.props.kpiLables.enablementButton2}
                                        {...(this.state.selectedKPIButton == 2 ? { highlight: 1 } : { color: 1 })}
                                        onClick={() => this.onKPIButtonsClick(2)}
                                    />
                                </div>
                                <div className="medium-2">
                                    <Button
                                        content={this.props.kpiLables.enablementButton3}
                                        {...(this.state.selectedKPIButton == 3 ? { highlight: 1 } : { color: 1 })}
                                        onClick={() => this.onKPIButtonsClick(3)}
                                    />
                                </div>
                                <div className="medium-2">
                                    <Button
                                        content={this.props.kpiLables.enablementButton4}
                                        {...(this.state.selectedKPIButton == 4 ? { highlight: 1 } : { color: 1 })}
                                        onClick={() => this.onKPIButtonsClick(4)}
                                    />
                                </div>
                                <div className="medium-2">
                                    <Button
                                        content={this.props.kpiLables.enablementButton5}
                                        {...(this.state.selectedKPIButton == 5 ? { highlight: 1 } : { color: 1 })}
                                        onClick={() => this.onKPIButtonsClick(5)}
                                    />
                                </div>
                                <div className="medium-2">
                                    <Button
                                        content={this.props.kpiLables.enablementButton6}
                                        {...(this.state.selectedKPIButton == 6 ? { highlight: 1 } : { color: 1 })}
                                        onClick={() => this.onKPIButtonsClick(6)}
                                    />
                                </div>
                            </div>
                            : null}
                        <div className="grid-x addPadding" />

                        <div className='table-scroll grid-x'>
                            {this.state.tableData ?
                                <table>
                                    {this.state.headers.map((el, index) =>
                                        index == 0 ?
                                            <col style={{ width: '8.4%' }}></col> :
                                            index == 1 ?
                                                <col style={{ width: '10.4%' }}></col> :
                                                <col style={{ width: '6.8%' }}></col>
                                    )}
                                    <tr>
                                        {this.state.headers.map(el => <th >{el}</th>)}
                                    </tr>
                                    {this.state.tableData.map((el, index) =>
                                        <tbody>
                                            <tr>
                                                <td >{kpiTargetShortNames[el.kpi]}</td>
                                                <td className='secondHeader'>Target KPI Cumulative <br />Target KPI in Quarter </td>
                                                <td > {kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.TagetKpi_Q1, 0)) : currencySymbol + formatThousands(roundFix(el.TagetKpi_Q1, 0))} <br /> 	{this.state.selectedKPIButton == 5 ? <MaskedInput className="spendNum" id={index} cellId='SE_Targeted_Q1' mask={kpiFormatRowWithThousandMillion[index] == 'Thousand' ? numberMaskForThousands : numberMask} placeholder={'-'} value={roundFix(el.SE_Targeted_Q1, 0)} onChange={e => this.handleTargetInQuarterValChange(e)} pattern='[0-9]*' /> : el.SE_Targeted_Q1 == 0 ? '-' : el.SE_Targeted_Q1 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.SE_Targeted_Q1, 0)) : currencySymbol + formatThousands(roundFix(el.SE_Targeted_Q1, 0))} </td>
                                                <td > {kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.TagetKpi_Q2, 0)) : currencySymbol + formatThousands(roundFix(el.TagetKpi_Q2, 0))} <br /> 	{this.state.selectedKPIButton == 5 ? <MaskedInput className="spendNum" id={index} cellId='SE_Targeted_Q2' mask={kpiFormatRowWithThousandMillion[index] == 'Thousand' ? numberMaskForThousands : numberMask} placeholder={'-'} value={roundFix(el.SE_Targeted_Q2, 0)} onChange={e => this.handleTargetInQuarterValChange(e)} pattern='[0-9]*' /> : el.SE_Targeted_Q2 == 0 ? '-' : el.SE_Targeted_Q2 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.SE_Targeted_Q2, 0)) : currencySymbol + formatThousands(roundFix(el.SE_Targeted_Q2, 0))} </td>
                                                <td > {kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.TagetKpi_Q3, 0)) : currencySymbol + formatThousands(roundFix(el.TagetKpi_Q3, 0))} <br /> 	{this.state.selectedKPIButton == 5 ? <MaskedInput className="spendNum" id={index} cellId='SE_Targeted_Q3' mask={kpiFormatRowWithThousandMillion[index] == 'Thousand' ? numberMaskForThousands : numberMask} placeholder={'-'} value={roundFix(el.SE_Targeted_Q3, 0)} onChange={e => this.handleTargetInQuarterValChange(e)} pattern='[0-9]*' /> : el.SE_Targeted_Q3 == 0 ? '-' : el.SE_Targeted_Q3 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.SE_Targeted_Q3, 0)) : currencySymbol + formatThousands(roundFix(el.SE_Targeted_Q3, 0))} </td>
                                                <td > {kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.TagetKpi_Q4, 0)) : currencySymbol + formatThousands(roundFix(el.TagetKpi_Q4, 0))} <br /> 	{this.state.selectedKPIButton == 5 ? <MaskedInput className="spendNum" id={index} cellId='SE_Targeted_Q4' mask={kpiFormatRowWithThousandMillion[index] == 'Thousand' ? numberMaskForThousands : numberMask} placeholder={'-'} value={roundFix(el.SE_Targeted_Q4, 0)} onChange={e => this.handleTargetInQuarterValChange(e)} pattern='[0-9]*' /> : el.SE_Targeted_Q4 == 0 ? '-' : el.SE_Targeted_Q4 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.SE_Targeted_Q4, 0)) : currencySymbol + formatThousands(roundFix(el.SE_Targeted_Q4, 0))} </td>
                                                <td > {kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.TagetKpi_Q5, 0)) : currencySymbol + formatThousands(roundFix(el.TagetKpi_Q5, 0))} <br /> 	{this.state.selectedKPIButton == 5 ? <MaskedInput className="spendNum" id={index} cellId='SE_Targeted_Q5' mask={kpiFormatRowWithThousandMillion[index] == 'Thousand' ? numberMaskForThousands : numberMask} placeholder={'-'} value={roundFix(el.SE_Targeted_Q5, 0)} onChange={e => this.handleTargetInQuarterValChange(e)} pattern='[0-9]*' /> : el.SE_Targeted_Q5 == 0 ? '-' : el.SE_Targeted_Q5 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.SE_Targeted_Q5, 0)) : currencySymbol + formatThousands(roundFix(el.SE_Targeted_Q5, 0))} </td>
                                                <td > {kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.TagetKpi_Q6, 0)) : currencySymbol + formatThousands(roundFix(el.TagetKpi_Q6, 0))} <br /> 	{this.state.selectedKPIButton == 5 ? <MaskedInput className="spendNum" id={index} cellId='SE_Targeted_Q6' mask={kpiFormatRowWithThousandMillion[index] == 'Thousand' ? numberMaskForThousands : numberMask} placeholder={'-'} value={roundFix(el.SE_Targeted_Q6, 0)} onChange={e => this.handleTargetInQuarterValChange(e)} pattern='[0-9]*' /> : el.SE_Targeted_Q6 == 0 ? '-' : el.SE_Targeted_Q6 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.SE_Targeted_Q6, 0)) : currencySymbol + formatThousands(roundFix(el.SE_Targeted_Q6, 0))} </td>
                                                <td > {kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.TagetKpi_Q7, 0)) : currencySymbol + formatThousands(roundFix(el.TagetKpi_Q7, 0))} <br /> 	{this.state.selectedKPIButton == 5 ? <MaskedInput className="spendNum" id={index} cellId='SE_Targeted_Q7' mask={kpiFormatRowWithThousandMillion[index] == 'Thousand' ? numberMaskForThousands : numberMask} placeholder={'-'} value={roundFix(el.SE_Targeted_Q7, 0)} onChange={e => this.handleTargetInQuarterValChange(e)} pattern='[0-9]*' /> : el.SE_Targeted_Q7 == 0 ? '-' : el.SE_Targeted_Q7 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.SE_Targeted_Q7, 0)) : currencySymbol + formatThousands(roundFix(el.SE_Targeted_Q7, 0))} </td>
                                                <td > {kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.TagetKpi_Q8, 0)) : currencySymbol + formatThousands(roundFix(el.TagetKpi_Q8, 0))} <br /> 	{this.state.selectedKPIButton == 5 ? <MaskedInput className="spendNum" id={index} cellId='SE_Targeted_Q8' mask={kpiFormatRowWithThousandMillion[index] == 'Thousand' ? numberMaskForThousands : numberMask} placeholder={'-'} value={roundFix(el.SE_Targeted_Q8, 0)} onChange={e => this.handleTargetInQuarterValChange(e)} pattern='[0-9]*' /> : el.SE_Targeted_Q8 == 0 ? '-' : el.SE_Targeted_Q8 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.SE_Targeted_Q8, 0)) : currencySymbol + formatThousands(roundFix(el.SE_Targeted_Q8, 0))} </td>
                                                <td > {kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.TagetKpi_Q9, 0)) : currencySymbol + formatThousands(roundFix(el.TagetKpi_Q9, 0))} <br /> 	{this.state.selectedKPIButton == 5 ? <MaskedInput className="spendNum" id={index} cellId='SE_Targeted_Q9' mask={kpiFormatRowWithThousandMillion[index] == 'Thousand' ? numberMaskForThousands : numberMask} placeholder={'-'} value={roundFix(el.SE_Targeted_Q9, 0)} onChange={e => this.handleTargetInQuarterValChange(e)} pattern='[0-9]*' /> : el.SE_Targeted_Q9 == 0 ? '-' : el.SE_Targeted_Q9 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.SE_Targeted_Q9, 0)) : currencySymbol + formatThousands(roundFix(el.SE_Targeted_Q9, 0))} </td>
                                                <td > {kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.TagetKpi_Q10, 0)) : currencySymbol + formatThousands(roundFix(el.TagetKpi_Q10, 0))} <br /> {this.state.selectedKPIButton == 5 ? <MaskedInput className="spendNum" id={index} cellId='SE_Targeted_Q10' mask={kpiFormatRowWithThousandMillion[index] == 'Thousand' ? numberMaskForThousands : numberMask} placeholder={'-'} value={roundFix(el.SE_Targeted_Q10, 0)} onChange={e => this.handleTargetInQuarterValChange(e)} pattern='[0-9]*' /> : el.SE_Targeted_Q10 == 0 ? '-' : el.SE_Targeted_Q10 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.SE_Targeted_Q10, 0)) : currencySymbol + formatThousands(roundFix(el.SE_Targeted_Q10, 0))} </td>
                                                <td > {kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.TagetKpi_Q11, 0)) : currencySymbol + formatThousands(roundFix(el.TagetKpi_Q11, 0))} <br /> {this.state.selectedKPIButton == 5 ? <MaskedInput className="spendNum" id={index} cellId='SE_Targeted_Q11' mask={kpiFormatRowWithThousandMillion[index] == 'Thousand' ? numberMaskForThousands : numberMask} placeholder={'-'} value={roundFix(el.SE_Targeted_Q11, 0)} onChange={e => this.handleTargetInQuarterValChange(e)} pattern='[0-9]*' /> : el.SE_Targeted_Q11 == 0 ? '-' : el.SE_Targeted_Q11 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.SE_Targeted_Q11, 0)) : currencySymbol + formatThousands(roundFix(el.SE_Targeted_Q11, 0))} </td>
                                                <td > {kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.TagetKpi_Q12, 0)) : currencySymbol + formatThousands(roundFix(el.TagetKpi_Q12, 0))} <br /> {this.state.selectedKPIButton == 5 ? <MaskedInput className="spendNum" id={index} cellId='SE_Targeted_Q12' mask={kpiFormatRowWithThousandMillion[index] == 'Thousand' ? numberMaskForThousands : numberMask} placeholder={'-'} value={roundFix(el.SE_Targeted_Q12, 0)} onChange={e => this.handleTargetInQuarterValChange(e)} pattern='[0-9]*' /> : el.SE_Targeted_Q12 == 0 ? '-' : el.SE_Targeted_Q12 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.SE_Targeted_Q12, 0)) : currencySymbol + formatThousands(roundFix(el.SE_Targeted_Q12, 0))} </td>
                                                <td > {el.TagetKpi_Q13 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.TagetKpi_Q13, 0)) : currencySymbol + formatThousands(roundFix(el.TagetKpi_Q13, 0))} <br />  {el.SE_Targeted_Q13 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.SE_Targeted_Q13, 0)) : currencySymbol + formatThousands(roundFix(el.SE_Targeted_Q13, 0))} </td>
                                            </tr></tbody>
                                    )}
                                </table>
                                : null}
                        </div>
                    </div>
                    : null}

                <div>
                    <Modal
                        ref="alert"
                        modalStyle={alertModalStyle}
                        contentStyle={changeModalContentStyle}
                        backdropStyle={changeBackdropStyle}
                    >
                        <div className="grid-x grid-padding-x change-popup">
                            <div className="medium-12">
                                <button onClick={() => this.hideModal("alert")} className="close" />
                            </div>
                            <div className="medium-12 pt30">
                                All your changes will be lost
                            </div>
                            <div className="medium-12 popupOkBtn">
                                <div className="float-right">
                                    <div className="float-left"><Button
                                        content={"Continue"}
                                        onClick={() => this.continueNavigation("alert")}
                                        color /></div>
                                    <div className="float-left"><Button
                                        content={"Close"}
                                        onClick={() => this.hideModal("alert")}
                                        color /></div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </div>

                <div>
                    <Modal
                        ref="KPI"
                        modalStyle={kpiModalStyle}
                        contentStyle={changeModalContentStyle}
                    >
                        <div className="kpiDashTable grid-x">
                            <div className='table-scroll grid-x'>
                                {this.state.dataLoaded ?
                                    <>
                                        <div className="grid-x grid-padding-x change-popup">
                                            <div className="medium-12">
                                                <button onClick={() => this.hideModal("KPI")} className="close" />
                                            </div>



                                            {this.state.kpiPopupError.length > 0 ?
                                                <>
                                                    {
                                                        this.state.kpiPopupError.map((el, index) =>
                                                            (el.isPopupError === 'Warning') ?
                                                                <div style={{ color: 'Orange', textAlign: 'center' }} className="cell pt5">{el.popupErrorMsg}</div>
                                                                : null
                                                        )
                                                    }

                                                    {
                                                        this.state.kpiPopupError.map((el, index) =>
                                                            (el.isPopupError === 'Yes') ?
                                                                <div style={{ color: 'red', textAlign: 'center' }} className="cell pt5">{el.popupErrorMsg}</div>
                                                                : null
                                                        )
                                                    }
                                                </>
                                                : null}

                                            <table style={{ marginTop: '2%' }}>
                                                {/* {this.state.KPIheaders2.map(el => <col></col>)} */}
                                                {this.state.KPIheaders2.map((el, index) => index == 1 ?
                                                    <col style={{ width: '8%' }}></col> :
                                                    index == 2 ?
                                                        <col style={{ width: '12%' }}></col> :
                                                        <col style={{ width: '5.7%' }}></col>
                                                )}
                                                {/* <tr>
{this.state.colGroupYearly.map(el => el.key == 1 ? <td className='noBorder'></td> : this.state.selectedSpanValue == 'Half' ? <th colspan="2" scope="colgroup">{el.value}</th> : <th colspan="4" scope="colgroup">{el.value}</th>)}
</tr> */}
                                                <tr>
                                                    {this.state.KPIheaders2.map(el => el !== '' ? <th>{el}</th> : <th>{el}</th>)}
                                                </tr>
                                                {this.state.KPItableData.map((el, index) => <tbody>
                                                    <tr>
                                                        <td>{kpiTargetShortNames[el.kpi]}</td>
                                                        <td>{kpiFormatRowWithThousandMillion[index] == 'Million' ? currencySymbol + convertToMillion(el.spend, true) : formatThousands(round(el.spend, 0))}</td>
                                                        <td className='secondHeader'>(%) <br /> Cumulative (%) <br /> Target KPI Cumulative <br /> Target KPI in Quarter </td>
                                                        {<React.Fragment>
                                                            <td><MaskedInput className="spendNum" id={index} cellId='HQ11' mask={numberMaskPercentage} placeholder={'%'}
                                                                value={el.HQ11} onChange={e => this.hQPercentChanged(e)} pattern='[0-9]*' /> <br /> {el.HQ12 == 0 ? '0' : el.HQ12} % <br />   {el.HQ14 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Million' ? currencySymbol + convertToMillion(el.HQ14, true) : formatThousands(round(el.HQ14, 0))}       <br /> {el.HQ13 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Million' ? currencySymbol + convertToMillion(el.HQ13, true) : formatThousands(round(el.HQ13, 0))}           </td>
                                                            <td><MaskedInput className="spendNum" id={index} cellId='HQ21' mask={numberMaskPercentage} placeholder={'%'}
                                                                value={el.HQ21} onChange={e => this.hQPercentChanged(e)} pattern='[0-9]*' /> <br /> {el.HQ22 == 0 ? '0' : el.HQ22} % <br />   {el.HQ24 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Million' ? currencySymbol + convertToMillion(el.HQ24, true) : formatThousands(round(el.HQ24, 0))}       <br /> {el.HQ23 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Million' ? currencySymbol + convertToMillion(el.HQ23, true) : formatThousands(round(el.HQ23, 0))}           </td>
                                                            <td><MaskedInput className="spendNum" id={index} cellId='HQ31' mask={numberMaskPercentage} placeholder={'%'}
                                                                value={el.HQ31} onChange={e => this.hQPercentChanged(e)} pattern='[0-9]*' /><br /> {el.HQ32 == 0 ? '0' : el.HQ32} % <br />    {el.HQ34 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Million' ? currencySymbol + convertToMillion(el.HQ34, true) : formatThousands(round(el.HQ34, 0))}       <br /> {el.HQ33 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Million' ? currencySymbol + convertToMillion(el.HQ33, true) : formatThousands(round(el.HQ33, 0))}            </td>
                                                            <td><MaskedInput className="spendNum" id={index} cellId='HQ41' mask={numberMaskPercentage} placeholder={'%'}
                                                                value={el.HQ41} onChange={e => this.hQPercentChanged(e)} pattern='[0-9]*' /> <br /> {el.HQ42 == 0 ? '0' : el.HQ42} % <br />   {el.HQ44 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Million' ? currencySymbol + convertToMillion(el.HQ44, true) : formatThousands(round(el.HQ44, 0))}       <br /> {el.HQ43 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Million' ? currencySymbol + convertToMillion(el.HQ43, true) : formatThousands(round(el.HQ43, 0))}           </td>
                                                            <td><MaskedInput className="spendNum" id={index} cellId='HQ51' mask={numberMaskPercentage} placeholder={'%'}
                                                                value={el.HQ51} onChange={e => this.hQPercentChanged(e)} pattern='[0-9]*' /> <br /> {el.HQ52 == 0 ? '0' : el.HQ52} % <br />   {el.HQ54 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Million' ? currencySymbol + convertToMillion(el.HQ54, true) : formatThousands(round(el.HQ54, 0))}       <br /> {el.HQ53 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Million' ? currencySymbol + convertToMillion(el.HQ53, true) : formatThousands(round(el.HQ53, 0))}           </td>
                                                            <td><MaskedInput className="spendNum" id={index} cellId='HQ61' mask={numberMaskPercentage} placeholder={'%'}
                                                                value={el.HQ61} onChange={e => this.hQPercentChanged(e)} pattern='[0-9]*' /> <br /> {el.HQ62 == 0 ? '0' : el.HQ62} % <br />   {el.HQ64 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Million' ? currencySymbol + convertToMillion(el.HQ64, true) : formatThousands(round(el.HQ64, 0))}       <br /> {el.HQ63 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Million' ? currencySymbol + convertToMillion(el.HQ63, true) : formatThousands(round(el.HQ63, 0))}           </td>
                                                            <td><MaskedInput className="spendNum" id={index} cellId='HQ71' mask={numberMaskPercentage} placeholder={'%'}
                                                                value={el.HQ71} onChange={e => this.hQPercentChanged(e)} pattern='[0-9]*' /> <br /> {el.HQ72 == 0 ? '0' : el.HQ72} % <br />   {el.HQ74 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Million' ? currencySymbol + convertToMillion(el.HQ74, true) : formatThousands(round(el.HQ74, 0))}       <br /> {el.HQ73 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Million' ? currencySymbol + convertToMillion(el.HQ73, true) : formatThousands(round(el.HQ73, 0))}           </td>
                                                            <td><MaskedInput className="spendNum" id={index} cellId='HQ81' mask={numberMaskPercentage} placeholder={'%'}
                                                                value={el.HQ81} onChange={e => this.hQPercentChanged(e)} pattern='[0-9]*' /> <br /> {el.HQ82 == 0 ? '0' : el.HQ82} % <br />   {el.HQ84 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Million' ? currencySymbol + convertToMillion(el.HQ84, true) : formatThousands(round(el.HQ84, 0))}       <br /> {el.HQ83 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Million' ? currencySymbol + convertToMillion(el.HQ83, true) : formatThousands(round(el.HQ83, 0))}           </td>
                                                            <td><MaskedInput className="spendNum" id={index} cellId='HQ91' mask={numberMaskPercentage} placeholder={'%'}
                                                                value={el.HQ91} onChange={e => this.hQPercentChanged(e)} pattern='[0-9]*' /> <br /> {el.HQ92 == 0 ? '0' : el.HQ92} % <br />   {el.HQ94 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Million' ? currencySymbol + convertToMillion(el.HQ94, true) : formatThousands(round(el.HQ94, 0))}       <br /> {el.HQ93 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Million' ? currencySymbol + convertToMillion(el.HQ93, true) : formatThousands(round(el.HQ93, 0))}           </td>
                                                            <td><MaskedInput className="spendNum" id={index} cellId='HQ101' mask={numberMaskPercentage} placeholder={'%'}
                                                                value={el.HQ101} onChange={e => this.hQPercentChanged(e)} pattern='[0-9]*' /> <br /> {el.HQ102 == 0 ? '0' : el.HQ102} % <br />{el.HQ104 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Million' ? currencySymbol + convertToMillion(el.HQ104, true) : formatThousands(round(el.HQ104, 0))}    <br /> {el.HQ103 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Million' ? currencySymbol + convertToMillion(el.HQ103, true) : formatThousands(round(el.HQ103, 0))}  </td>
                                                            <td><MaskedInput className="spendNum" id={index} cellId='HQ111' mask={numberMaskPercentage} placeholder={'%'}
                                                                value={el.HQ111} onChange={e => this.hQPercentChanged(e)} pattern='[0-9]*' /> <br /> {el.HQ112 == 0 ? '0' : el.HQ112} % <br />{el.HQ114 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Million' ? currencySymbol + convertToMillion(el.HQ114, true) : formatThousands(round(el.HQ114, 0))}    <br /> {el.HQ113 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Million' ? currencySymbol + convertToMillion(el.HQ113, true) : formatThousands(round(el.HQ113, 0))}  </td>
                                                            <td><MaskedInput className="spendNum" id={index} cellId='HQ121' mask={numberMaskPercentage} placeholder={'%'}
                                                                value={el.HQ121} onChange={e => this.hQPercentChanged(e)} pattern='[0-9]*' /> <br /> {el.HQ122 == 0 ? '0' : el.HQ122} % <br />{el.HQ124 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Million' ? currencySymbol + convertToMillion(el.HQ124, true) : formatThousands(round(el.HQ124, 0))}    <br /> {el.HQ123 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Million' ? currencySymbol + convertToMillion(el.HQ123, true) : formatThousands(round(el.HQ123, 0))}  </td>
                                                            <td>
                                                                {/* <MaskedInput className="spendNum" id={index} cellId='HQ131' mask={numberMaskPercentage} placeholder={'%'}
                                                            value={el.HQ131} onChange={e => this.hQPercentChanged(e)} pattern='[0-9]*' />  */}
                                                                <br />
                                                                {/* {el.HQ122 == 0 ? '-' : el.HQ132} %  */}
                                                                <br />
                                                                {el.HQ134 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Million' ? currencySymbol + convertToMillion(el.HQ134, true) : formatThousands(round(el.HQ134, 0))} <br /> {el.HQ133 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Million' ? currencySymbol + convertToMillion(el.HQ133, true) : formatThousands(round(el.HQ133, 0))} </td>
                                                        </React.Fragment>}

                                                    </tr>
                                                </tbody>
                                                )}
                                                {/* <tr colspan='14'>
                                                <td>
                                                    <div className="medium-2">
                                                        <div className="float-right">
                                                            <button className="button button--accent"
                                                                onClick={(e) => this.onKPIPercentCalcSave()}
                                                            >
                                                                <span className="button__inner">{"Save"}</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr> */}
                                            </table>
                                            <div className="medium-12">
                                                <div className="float-right">
                                                    <button className="button button--accent"
                                                        onClick={(e) => this.onKPIPercentCalcSave()}
                                                        //disabled={this.state.isPopupError == 'Yes' ? true : false}
                                                        disabled={this.state.kpiPopupError.some(function (o) { return o["isPopupError"] === "Yes"; }) ? true : false}
                                                    //
                                                    >
                                                        <span className="button__inner">{"Save KPI Target Values"}</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    : null}
                            </div>
                        </div>
                    </Modal>
                </div>

            </div>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        ui: state.content.ui,
        userData: state.valueStory,
        user: state.default,
        content: state.content.spendAnalysis.worldMap,
        focusLabel: state.content.spendAnalysis.enablement.focusLabel,
        spendDetails: state.spendDetails,
        currencySymbols: state.content.CurrencySymbols,
        kpiFilters: state.kpis.kpiBenchMarkFilters,
        kpiLables: state.content.kpiTab,
        isDeleteKPITable: state.deleteIntermediateKPIOnRedirect.changes,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        resetError: error => dispatch(resetError(error)),
        defineScopeLoading: bool => dispatch(defineScopeLoading(bool)),
        getKPIDashBoardLoadData: (DMCId, VSId, userEmail, metric, type) => dispatch(getKPIDashBoardLoadData(DMCId, VSId, userEmail, metric, type)),
        updateEnablementKPIDashBoardTabData: (records) => dispatch(updateEnablementKPIDashBoardTabData(records)),
        pendingChanges: changes => dispatch(pendingChanges(changes)),
        getManualKPIDashBoardLoadData: (DMCId, VSId, userEmail, kpi_strategy_model, model) => dispatch(getManualKPIDashBoardLoadData(DMCId, VSId, userEmail, kpi_strategy_model, model)),
        //getManualKPIResourceTypeLoadData: (DMCId, VSId, userEmail) => dispatch(getManualKPIResourceTypeLoadData(DMCId, VSId, userEmail)),
        updateEnablementStrategyWaveData: (DMCId, VSId, userEmail) => dispatch(updateEnablementStrategyWaveData(DMCId, VSId, userEmail)),
        getUpdateEnablementKPIModel: (DMCId, VSId, userEmail, ReadWriteFlag, EnablementKPIModel) => dispatch(getUpdateEnablementKPIModel(DMCId, VSId, userEmail, ReadWriteFlag, EnablementKPIModel)),
        deleteIntermediateEnablementKPIDashBoardTabData: (DMCId, VSId, userEmail) => dispatch(deleteIntermediateEnablementKPIDashBoardTabData(DMCId, VSId, userEmail)),
        saveKPIOnRedirect: changes => dispatch(saveKPIOnRedirect(changes)),
        deleteIntermediateKPIOnRedirect: changes => dispatch(deleteIntermediateKPIOnRedirect(changes)),
        //getManualKPIManualTargetFetch: (DMCId, VSId, userEmail, model, kpiModel) => dispatch(getManualKPIManualTargetFetch(DMCId, VSId, userEmail, model, kpiModel)),
        GetManualKPIDashBoardLoadDataPercentCalc: (DMCId, VSId, userEmail) => dispatch(GetManualKPIDashBoardLoadDataPercentCalc(DMCId, VSId, userEmail)),
        saveIntermediateEnablementKPIDashBoardTabData: (records) => dispatch(saveIntermediateEnablementKPIDashBoardTabData(records)),
        kpiModelChanged: (val) => dispatch(kpiModelChanged(val)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EnablementStrategyKPI)