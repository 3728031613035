import React from 'react'
import { connect } from 'react-redux'
import { Control, Form, Errors, actions } from 'react-redux-form'
// UI
import Textfield from 'react-mdl/lib/Textfield'
import Button from 'UI/Button'
import moment from 'moment'
// Settings
import { validators } from 'Constants'
import { SelectField, Option } from 'react-mdl-extra'

// Actions
import {
  fetchLayoutFiles,
  fetchSizeFiles,
  addLayoutFiles,
  addSizeFiles,
  deleteLayoutFiles,
  deleteSizeFiles,
  userDetailsSuccess,
  userDetailsFailure,
  userDetailsLoading,
  resetError
}
  from 'actions/userActions'

class LayoutManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userIsEditing: false,
      editId: 0,
      options: [],
      sizes: [],
      layout: '',
      size: '',
      layouts: [],
      tableContents: [],
      mymessage: ''
    }

    this.loadLayoutFiles = this.loadLayoutFiles.bind(this)
    this.loadSizeFiles = this.loadSizeFiles.bind(this)
    this.mergeFiles = this.mergeFiles.bind(this)
  }

  componentWillMount() {
    this.loadLayoutFiles()
  }
  loadLayoutFiles() {
    this.props.fetchLayoutFiles()
      .then((response) => {
        var layoutFilesData = [];
        this.props.userDetailsSuccess(response.data.Message)
        for (var i = 0; i < response.data.Result.length; i++) {
          var obj = response.data.Result[i];
          if (obj.Name === "Default") {
            continue;
          }
          else {
            layoutFilesData.push(obj);
          }
        }
        this.setState({ layouts: layoutFilesData })
        this.loadSizeFiles()
      }).catch((error) => {
        if (error.stack.includes('Network Error')) {
          this.props.userDetailsFailure()
        }
      })
  }

  loadSizeFiles() {
    this.props.fetchSizeFiles()
      .then((response) => {
        var sizeFilesData = [];
        this.props.userDetailsSuccess(response.data.Message)
        for (var i = 0; i < response.data.Result.length; i++) {
          var obj = response.data.Result[i];
          if (obj.Name === "Default") {
            continue;
          }
          else {
            sizeFilesData.push(obj);
          }
        }
        this.setState({ sizes: sizeFilesData })
        this.mergeFiles()
      }).catch((error) => {
        if (error.stack.includes('Network Error')) {
          this.props.userDetailsFailure()
        }
      })
  }

  mergeFiles() {
    var mergeFilesData = [];
    this.state.layouts.map(layout => {
      mergeFilesData.push(layout);
    })
    this.state.sizes.map(size => {
      mergeFilesData.push(size);
    })

    this.setState({ tableContents: mergeFilesData })
  }

  addLayoutFile(formValue) {
    this.props.resetError();
    var layoutDetail = {
      name: formValue.Layout.trim(),
      User: localStorage.getItem('loggedinUser'),
      active: true
    }
    var msg = this.props.content;
    var flag = layoutDetail.name.slice((layoutDetail.name.lastIndexOf(".") - 1 >>> 0) + 2);
    var result = this.state.layouts.find(report => report.Name.toLowerCase() == layoutDetail.name.toLowerCase()) === undefined
    if (flag.toLowerCase() === "pptx") {
      if (!result) {
        this.props.userDetailsFailure('This layoutfile already exists!')
      }
      else {
        this.setState({ mymessage: "" })
        this.props.addLayoutFiles(layoutDetail).then((results) => {

          if (results.status === 200) {
            var httpstatus = results.status
            var getMsgfromStatus = msg[httpstatus]
            // Reset form
            this.formDispatch(actions.reset('reportsLayoutManager'))
            this.loadLayoutFiles()
            this.setState({ mymessage: getMsgfromStatus })
          }
        }).catch((error) => {
          if (error.stack.includes('Network Error')) {
            this.setState({ mymessage: "" })
            this.props.userDetailsFailure('API service is down. Please contact administrator.')
          }
          else {
            this.setState({ mymessage: "" })
            this.props.userDetailsFailure(error.response.data.Message)
          }
        })
      }
    }
    else {
      this.setState({ mymessage: "" })
      this.props.userDetailsFailure("You can only add pptx files!!")
    }

  }
  addSizeFile(formValue) {
    this.props.resetError();
    var sizeDetail = {
      name: formValue.Size.trim(),
      User: localStorage.getItem('loggedinUser'),
      active: true
    }
    var msg = this.props.content;
    var flag = sizeDetail.name.slice((sizeDetail.name.lastIndexOf(".") - 1 >>> 0) + 2);
    var result = this.state.sizes.find(report => report.Name.toLowerCase() == sizeDetail.name.toLowerCase()) === undefined
    if (flag.toLowerCase() === "pptx") {
      if (!result) {
        this.props.userDetailsFailure('This layoutfile already exists!')
      }
      else {
        this.setState({ mymessage: "" })
        this.props.addSizeFiles(sizeDetail).then((results) => {
          if (results.status === 200) {
            var httpstatus = results.status
            var getMsgfromStatus = msg[httpstatus]
            // Reset form
            this.formDispatch(actions.reset('reportsLayoutSizeManager'))
            this.loadLayoutFiles()
            this.setState({ mymessage: getMsgfromStatus })
          }
        }).catch((error) => {
          if (error.stack.includes('Network Error')) {
            this.setState({ mymessage: "" })
            this.props.userDetailsFailure('API service is down. Please contact administrator.')
          }
          else {
            this.setState({ mymessage: "" })
            this.props.userDetailsFailure(error.response.data.Message)
          }
        })
      }
    }else {
      this.setState({ mymessage: "" })
      this.props.userDetailsFailure("You can only add pptx files!!")
    }

  }

  handleRowDel(data) {
    const result = window.confirm(this.props.validation.confirmDelete);
    if (result) {
      var formValues = { Name: data.Name };
      var msg = this.props.content.rowDel[0];
      this.setState({ mymessage: "" })
      if (data.Type == 'Layout') {
        this.props.deleteLayoutFiles(formValues)
          .then((response) => {
            this.props.userDetailsSuccess(response.data.Message)

            if (response.status === 200) {
              var httpstatus = response.status
              var getMsgfromStatus = msg[httpstatus]
              this.loadLayoutFiles()
              this.setState({ mymessage: getMsgfromStatus })

            }
          }).catch((error) => {
            if (error.stack.includes('Network Error')) {
              this.setState({ mymessage: "" })
              this.props.userDetailsFailure(this.props.validation.networkError)
            }
            else {
              var httpstatus = error.response.status
              var getMsgfromStatus = msg[httpstatus]
              if (getMsgfromStatus !== undefined) {
                this.setState({ mymessage: "" })
                this.props.userDetailsFailure(getMsgfromStatus)
              }
              else {
                this.setState({ mymessage: "" })
                this.props.userDetailsFailure(error.response.data.Message)
              }
            }
          })
      }
      else {
        this.props.deleteSizeFiles(formValues)
          .then((response) => {
            this.props.userDetailsSuccess(response.data.Message)

            if (response.status === 200) {
              var httpstatus = response.status
              var getMsgfromStatus = msg[httpstatus]
              this.loadLayoutFiles()
              this.setState({ mymessage: getMsgfromStatus })
            }
          }).catch((error) => {
            if (error.stack.includes('Network Error')) {
              this.setState({ mymessage: "" })
              this.props.userDetailsFailure(this.props.validation.networkError)
            }
            // else {
            //   var httpstatus = error.response.status
            //   var getMsgfromStatus = msg[httpstatus]
            //   if (getMsgfromStatus !== undefined) {
            //     this.setState({ mymessage: "" })
            //     this.props.userDetailsFailure(getMsgfromStatus)
            //   }
              else {
                this.setState({ mymessage: "" })
                this.props.userDetailsFailure(error.response.data.Message)
              }
            // }
          })
      }
    }
  };


  componentWillUnmount() {
    this.formDispatch(actions.reset('reportsLayoutManager'))
    this.formDispatch(actions.reset('reportsLayoutSizeManager'))
    this.props.resetError();
  }

  render() {
    return (
      <div className="popup-content">
        <div className="medium-12 title">{this.props.content.title}</div>
        <div>
          <Form model="reportsLayoutManager" method="post"
            getDispatch={(dispatch) => this.formDispatch = dispatch}
            onSubmit={(formValue) => this.addLayoutFile(formValue)}>
            <div className="grid-x grid-padding-x" >
              <div className="medium-4 cell" >
                <Control.text
                  model="reportsLayoutManager.Layout"
                  component={Textfield}
                  validateOn="blur"
                  label={this.props.content.layout}
                  floatingLabel
                  validators={{
                    required: validators.required
                  }}
                />
                <Errors
                  className="errors"
                  model="reportsLayoutManager.Layout"
                  show="touched"
                  messages={{
                    required: this.props.validation.required,
                    duplicate: this.props.validation.duplicateReport
                  }}
                />
              </div>
              <div className="medium-2 cell pt13" >
                <Button
                  type="submit"
                  disabled={this.props.user.loading}
                  content={this.props.content.cta}
                  color
                />
              </div>
            </div>
          </Form>
        </div>
        <div>
          <Form model="reportsLayoutSizeManager" method="post"
            getDispatch={(dispatch) => this.formDispatch = dispatch}
            onSubmit={(formValue) => this.addSizeFile(formValue)}>
            <div className="grid-x grid-padding-x" >
              <div className="medium-4 cell" >
                <Control.text
                  model="reportsLayoutSizeManager.Size"
                  component={Textfield}
                  validateOn="blur"
                  label={this.props.content.size}
                  floatingLabel
                  validators={{
                    required: validators.required
                  }}
                />
                <Errors
                  className="errors"
                  model="reportsLayoutSizeManager.Size"
                  show="touched"
                  messages={{
                    required: this.props.validation.required,
                    duplicate: this.props.validation.duplicateReport
                  }}
                />
              </div>
              <div className="medium-2 cell pt13" >
                <Button
                  type="submit"
                  disabled={this.props.user.loading}
                  content={this.props.content.cta}
                  color
                />
              </div>
            </div>
          </Form >
          <div className="errors">{this.props.user.error}</div>
          <div className="success">{this.state.mymessage}</div>
        </div >
        <div className="grid-x" className="medium-11 medium-offset-0">
          <LayoutsTable
            onRowDel={this.handleRowDel.bind(this)}
            tableContents={this.state.tableContents}
          />
        </div>
      </div >
    );
  }
}

class LayoutsTable extends React.Component {
  render() {
    var rowDel = this.props.onRowDel;
    var tableContents = this.props.tableContents.map(function (tableContent) {
      return (<UserRow
        tableContent={tableContent}
        onDelEvent={rowDel.bind(this)}
      />)
    });
    return (
      <div className="basic-table">
        <table>
          <thead>
            <tr>
              <th>FileName</th>
              <th>Type</th>
              <th>Added By</th>
              <th>Added Date</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {tableContents}
          </tbody>
        </table>
      </div>
    );
  }
}

class UserRow extends React.Component {
  constructor(props) {
    super(props);
  }

  onDelEvent() {
    this.props.onDelEvent(this.props.tableContent);
  }

  render() {
    return (
      <tr className="eachRow">
        <td>{this.props.tableContent.Name}</td>
        <td>{this.props.tableContent.Type}</td>
        <td>{this.props.tableContent.AddedBy}</td>
        <td><span>{moment(this.props.tableContent.AddedDate).format('L')}</span></td>
        <td>
          {
            (
              <button onClick={this.onDelEvent.bind(this)}>Delete	</button>
            )
          }
        </td>
      </tr>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
    ui: state.content.ui,
    user: state.default,
    content: state.content.layouts,
    validation: state.content.validation
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchLayoutFiles: (formValues) => dispatch(fetchLayoutFiles(formValues)),
    fetchSizeFiles: (formValues) => dispatch(fetchSizeFiles(formValues)),
    addSizeFiles: (formValues) => dispatch(addSizeFiles(formValues)),
    addLayoutFiles: (formValues) => dispatch(addLayoutFiles(formValues)),
    deleteLayoutFiles: (formValues) => dispatch(deleteLayoutFiles(formValues)),
    deleteSizeFiles: (formValues) => dispatch(deleteSizeFiles(formValues)),
    userDetailsLoading: (bool) => dispatch(userDetailsLoading(bool)),
    userDetailsSuccess: (bool) => dispatch(userDetailsSuccess(bool)),
    userDetailsFailure: (error) => dispatch(userDetailsFailure(error)),
    resetError: () => dispatch(resetError())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutManager)
