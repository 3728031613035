import React from 'react'
import { connect } from 'react-redux'
import {
    getKPIDashBoardLoadDataV2,
    getKPIDashBoardLoadFinalDataV2,
    updateKPIDashBoardTabDataV2,
    getUpdateKpiGoliveProjectStartDate
} from 'actions/kpiActions'
import {
    resetError,
    defineScopeLoading,
    pendingChanges
} from 'actions/spendActions'
import Select from 'react-select'
import { roundFix, round, formatThousands, addCurrencyMask, convertToNumber, convertToMillion, onChangeRemoveMask } from 'utils/Utils'
import reactSelectCjsDev from 'react-select/dist/react-select.cjs.dev';
import { path, routes, kpiRowCustomOrder, kpiFormatRowWithThousandMillion, kpiTargetShortNames } from 'Constants'
import classNames from "classnames";
import Button from 'UI/ButtonWithoutLoader'
import Popup from "reactjs-popup";
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

class KPIDashboardManualV2 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            headers: ['KPI', 'Metric', 'Q1', 'Q2', 'Q3', 'Q4', 'Q5', 'Q6', 'Q7', 'Q8', 'Q9', 'Q10', 'Q11', 'Q12'],
            //quaterly data may be added revisit logic 
            colGroupYearly: [{ key: 1, value: '' }, { key: 1, value: '' }, { key: 1, value: '' }, { key: 1, value: '' }, { key: 1, value: '' }, { key: 1, value: '' }, { key: 2, value: 'Year1' }, { key: 2, value: 'Year2' }, { key: 2, value: 'Year3' }],
            tableData: [],
            editedRowsIds: [],
            selectedBPValue: 'Average 50%',
            metricValues: [],
            selectedMetricValue: 'ALL',
            selectedBPFlag: 'AVERAGE',
            kpiBenchMarkFilters: { metric: 'ALL', type: 'AVERAGE' },
            startdate: null,
            goLiveDate: null,
        }
        this.loadData = this.loadData.bind(this);
        this.createTableData = this.createTableData.bind(this);
        this.createTableDataObject = this.createTableDataObject.bind(this);
        this.handleActualValChange = this.handleActualValChange.bind(this);
        this.onSave = this.onSave.bind(this);
        this.setStartDate = this.setStartDate.bind(this);
        this.setGoLiveDate = this.setGoLiveDate.bind(this);
    }

    componentDidMount() {
        this.loadData()
        this.setState({ currencySymbol: this.props.spendDetails.currentActiveRequest !== undefined ? this.props.currencySymbols[this.props.spendDetails.currentActiveRequest.Currency.trim()] : '' })
    }

    setStartDate(date) {
        this.props.pendingChanges(true)
        var formattedDate = this.convertToServerTimeZone(date);
        this.setState({ startdate: formattedDate, isSaveInProgress: true, responseMsg: "Pending changes. Click save to update KPIs.", })
    }

    setGoLiveDate(date) {
        this.props.pendingChanges(true)
        var formattedDate = this.convertToServerTimeZone(date);
        //alert(formattedDate);
        this.setState({ goLiveDate: formattedDate, isSaveInProgress: true, responseMsg: "Pending changes. Click save to update KPIs.", })
    }

    convertToServerTimeZone(date) {
        var newDate = new Date(date);
        var tt = new Date();
        
        if (Intl.DateTimeFormat().resolvedOptions().timeZone == 'America/New_York')
            return date;

        var dt = new Date(Date.UTC(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), tt.getHours(), tt.getMinutes(), tt.getSeconds()));//new Date(1458619200000);
        //console.log(dt); // Gives Tue Mar 22 2016 09:30:00 GMT+0530 (IST)

        dt.setTime(dt.getTime() + dt.getTimezoneOffset() * 60 * 1000);
        //console.log(dt); // Gives Tue Mar 22 2016 04:00:00 GMT+0530 (IST)

        var offset = -300; //Timezone offset for EST in minutes.
        var estDate = new Date(dt.getTime() + offset * 60 * 1000);
        //console.log(estDate);
        
        return estDate;
    }

    loadData() {
        let dmcId = this.props.spendDetails.currentActiveRequest.DMC_ID;
        let vsId = this.props.spendDetails.currentActiveRequest.VS_ID;
        let user = this.props.user.user.Email;

        this.props.defineScopeLoading(true)
        this.setState({ isLoading: true })
        this.props.getKPIDashBoardLoadFinalDataV2(dmcId, vsId, user)
            .then((response) => {
                if (response.data.Result.length > 0) {
                    let spData = response.data.Result;
                    let tableData = this.createTableData(spData);
                    this.props.defineScopeLoading(false);
                    // let goLiveDate = spData[0]["GO_LIVE_DATE"] !== null ? new Date(spData[0]["GO_LIVE_DATE"]) : null;
                    // let projectStartDate = spData[0]["PROJECT_START_DATE"] !== null ? new Date(spData[0]["PROJECT_START_DATE"]) : null;
                    this.props.getUpdateKpiGoliveProjectStartDate(dmcId, vsId, user, 'R', null, null)
                        .then((response) => {
                            var data = response.data.Result[0];
                            let goLiveDate = data.GO_LIVE_DATE !== null ? this.convertToServerTimeZone(new Date(data.GO_LIVE_DATE)) : null;
                            let projectStartDate = data.PROJECT_START_DATE !== null ? this.convertToServerTimeZone(new Date(data.PROJECT_START_DATE)) : null;
                            this.setState({ tableData: tableData, isLoading: false, startdate: projectStartDate, goLiveDate: goLiveDate })
                        });
                } else {
                    this.props.defineScopeLoading(false)
                    this.setState({ errorMsg: 'No Records Found. Please navigate to the Your Spend File Tab then Enablement Strategy Wizard Sub-tab then navigate back to this page. The data will appear if the data does not appear email sap_ism_value_experience@sap.com.', isError: 'Yes', isLoading: false })
                }

            }).catch((error) => {
                if (error.stack.includes('Network Error')) {
                    this.props.defineScopeLoading(false)
                    this.setState({ errorMsg: 'API service is down. Please contact administrator.', isError: 'Yes', isLoading: false })
                }
                else {
                    this.props.defineScopeLoading(false)
                    this.setState({ errorMsg: this.props.content.error, isError: 'Yes', isLoading: false })
                }
            })
    }

    createTableData(spData) {
        let tableData = [];

        //create an array with unique kpi names from sp data
        let kpiArr = [...new Set(spData.map(item => item.KPI_NAME))];

        //for every unique kpi name create table data for 12 quaters
        for (let i = 0; i < kpiArr.length; i++) {
            let temp = spData.filter(item => item.KPI_NAME == kpiArr[i])
            let obj = this.createTableDataObject(temp)
            tableData.push(obj)
        }
        //tableData.sort((a, b) => (a.srNo > b.srNo) ? 1 : -1)//sort the table always by sr_no


        tableData.sort((a, b) => kpiRowCustomOrder[a.srNo] - kpiRowCustomOrder[b.srNo]);

        return tableData;
    }

    createTableDataObject(kpiArr) {
        let obj = {}
        for (let i = 0; i < kpiArr.length; i++) {
            obj.VS_ID = kpiArr[i]['VS_ID']
            obj.DMC_ID = kpiArr[i]['DMC_ID']
            obj.kpi = kpiArr[i]['KPI_NAME']
            obj.QUARTER_NO = kpiArr[i]['QUARTER_NO']
            obj.srNo = kpiArr[i]['SR_NO']
            obj.HR = 'hr'
            for (let j = 1; j <= 12; j++) {
                if (kpiArr[i]['QUARTER_NO'] == 'Q' + j) obj['TagetKpi_Q' + j] = kpiArr[i]['TARGET_KPI_CUMULATIVE']
                if (kpiArr[i]['QUARTER_NO'] == 'Q' + j) obj['PLANNED_Q' + j] = kpiArr[i]['PLANNED']
                if (kpiArr[i]['QUARTER_NO'] == 'Q' + j) obj['SE_Estimated_Q' + j] = kpiArr[i]['SE_ESTIMATED']
                //if (kpiArr[i]['QUARTER_NO'] == 'Q' + j) obj['Actual_Q' + j] = kpiArr[i]['SR_NO'] % 2 == 0 ? kpiArr[i]['ACTUAL_VALUE'] / 1000000 : kpiArr[i]['ACTUAL_VALUE']
                if (kpiArr[i]['QUARTER_NO'] == 'Q' + j) obj['Actual_Q' + j] = kpiArr[i]['ACTUAL_VALUE']
                if (kpiArr[i]['QUARTER_NO'] == 'Q' + j) obj['Gap_Q' + j] = kpiArr[i]['GAP_VALUE']
                if (kpiArr[i]['QUARTER_NO'] == 'Q' + j) obj['Attainment_Q' + j] = kpiArr[i]['ATTAINMENT_VALUE']
            }
        }
        return obj;
    }

    handleActualValChange(element) {
        let id = element.target.id;
        let editedRowsIds = this.state.editedRowsIds;
        let cellId = element.target.attributes.cellId.nodeValue;
        //let val = kpiFormatRowWithThousandMillion[id] == 'Million' ? onChangeRemoveMask(element, '') : onChangeRemoveMask(element, this.state.currencySymbol);
        let val = onChangeRemoveMask(element, this.state.currencySymbol);
        if (val == "") val = null;
        let data = this.state.tableData;
        if (data[id][cellId] != val) {
            data[id][cellId] = val;
            //save the edited row id number
            if (!editedRowsIds.includes(id)) editedRowsIds.push(id);
            this.runCalcFormulae(data[id], id, data)
            this.props.pendingChanges(true)
            this.setState({ editedRowsIds: editedRowsIds, isSaveInProgress: true, isError: 'No', responseMsg: "Pending changes. Click save to update KPI Dashboard." })
        }
    }

    runCalcFormulae(obj, id, data) {
        let element = data[id];

        //Gap calculation formulae kpi - actual
        //if (id % 2 == 0) {

        //if (kpiFormatRowWithThousandMillion[id] == 'Thousand') {
            element.Gap_Q1 = element.TagetKpi_Q1 - element.Actual_Q1;
            element.Gap_Q2 = element.TagetKpi_Q2 - element.Actual_Q2;
            element.Gap_Q3 = element.TagetKpi_Q3 - element.Actual_Q3;
            element.Gap_Q4 = element.TagetKpi_Q4 - element.Actual_Q4;
            element.Gap_Q5 = element.TagetKpi_Q5 - element.Actual_Q5;
            element.Gap_Q6 = element.TagetKpi_Q6 - element.Actual_Q6;
            element.Gap_Q7 = element.TagetKpi_Q7 - element.Actual_Q7;
            element.Gap_Q8 = element.TagetKpi_Q8 - element.Actual_Q8;
            element.Gap_Q9 = element.TagetKpi_Q9 - element.Actual_Q9;
            element.Gap_Q10 = element.TagetKpi_Q10 - element.Actual_Q10;
            element.Gap_Q11 = element.TagetKpi_Q11 - element.Actual_Q11;
            element.Gap_Q12 = element.TagetKpi_Q12 - element.Actual_Q12;
        // } else {
        //     element.Gap_Q1 = element.TagetKpi_Q1 - element.Actual_Q1 * 1000000;//in odd rows the values are in millions so mutiply the value by 10^6
        //     element.Gap_Q2 = element.TagetKpi_Q2 - element.Actual_Q2 * 1000000;
        //     element.Gap_Q3 = element.TagetKpi_Q3 - element.Actual_Q3 * 1000000;
        //     element.Gap_Q4 = element.TagetKpi_Q4 - element.Actual_Q4 * 1000000;
        //     element.Gap_Q5 = element.TagetKpi_Q5 - element.Actual_Q5 * 1000000;
        //     element.Gap_Q6 = element.TagetKpi_Q6 - element.Actual_Q6 * 1000000;
        //     element.Gap_Q7 = element.TagetKpi_Q7 - element.Actual_Q7 * 1000000;
        //     element.Gap_Q8 = element.TagetKpi_Q8 - element.Actual_Q8 * 1000000;
        //     element.Gap_Q9 = element.TagetKpi_Q9 - element.Actual_Q9 * 1000000;
        //     element.Gap_Q10 = element.TagetKpi_Q10 - element.Actual_Q10 * 1000000;
        //     element.Gap_Q11 = element.TagetKpi_Q11 - element.Actual_Q11 * 1000000;
        //     element.Gap_Q12 = element.TagetKpi_Q12 - element.Actual_Q12 * 1000000;
        // }


        //Attainment clac formula Actual/kpi
        //if (id % 2 == 0) {
        
        
        // if (kpiFormatRowWithThousandMillion[id] == 'Thousand') {
            element.Attainment_Q1 = roundFix((element.Actual_Q1 / element.TagetKpi_Q1) * 100, 2);//we multiply the result with 100 and roundFix it off to 2 decimal bcs its % value
            element.Attainment_Q2 = roundFix((element.Actual_Q2 / element.TagetKpi_Q2) * 100, 2);
            element.Attainment_Q3 = roundFix((element.Actual_Q3 / element.TagetKpi_Q3) * 100, 2);
            element.Attainment_Q4 = roundFix((element.Actual_Q4 / element.TagetKpi_Q4) * 100, 2);
            element.Attainment_Q5 = roundFix((element.Actual_Q5 / element.TagetKpi_Q5) * 100, 2);
            element.Attainment_Q6 = roundFix((element.Actual_Q6 / element.TagetKpi_Q6) * 100, 2);
            element.Attainment_Q7 = roundFix((element.Actual_Q7 / element.TagetKpi_Q7) * 100, 2);
            element.Attainment_Q8 = roundFix((element.Actual_Q8 / element.TagetKpi_Q8) * 100, 2);
            element.Attainment_Q9 = roundFix((element.Actual_Q9 / element.TagetKpi_Q9) * 100, 2);
            element.Attainment_Q10 = roundFix((element.Actual_Q10 / element.TagetKpi_Q10) * 100, 2);
            element.Attainment_Q11 = roundFix((element.Actual_Q11 / element.TagetKpi_Q11) * 100, 2);
            element.Attainment_Q12 = roundFix((element.Actual_Q12 / element.TagetKpi_Q12) * 100, 2);
        // } else {
        //     element.Attainment_Q1 = roundFix((1000000 * element.Actual_Q1 / element.TagetKpi_Q1) * 100, 2);//we multiply the result with 100 and roundFix it off to 2 decimal bcs its % value
        //     element.Attainment_Q2 = roundFix((1000000 * element.Actual_Q2 / element.TagetKpi_Q2) * 100, 2);
        //     element.Attainment_Q3 = roundFix((1000000 * element.Actual_Q3 / element.TagetKpi_Q3) * 100, 2);
        //     element.Attainment_Q4 = roundFix((1000000 * element.Actual_Q4 / element.TagetKpi_Q4) * 100, 2);
        //     element.Attainment_Q5 = roundFix((1000000 * element.Actual_Q5 / element.TagetKpi_Q5) * 100, 2);
        //     element.Attainment_Q6 = roundFix((1000000 * element.Actual_Q6 / element.TagetKpi_Q6) * 100, 2);
        //     element.Attainment_Q7 = roundFix((1000000 * element.Actual_Q7 / element.TagetKpi_Q7) * 100, 2);
        //     element.Attainment_Q8 = roundFix((1000000 * element.Actual_Q8 / element.TagetKpi_Q8) * 100, 2);
        //     element.Attainment_Q9 = roundFix((1000000 * element.Actual_Q9 / element.TagetKpi_Q9) * 100, 2);
        //     element.Attainment_Q10 = roundFix((1000000 * element.Actual_Q10 / element.TagetKpi_Q10) * 100, 2);
        //     element.Attainment_Q11 = roundFix((1000000 * element.Actual_Q11 / element.TagetKpi_Q11) * 100, 2);
        //     element.Attainment_Q12 = roundFix((1000000 * element.Actual_Q12 / element.TagetKpi_Q12) * 100, 2);
        // }

        this.setState({ tableData: data })
    }

    onSave() {

        let dmcId = this.props.spendDetails.currentActiveRequest.DMC_ID;
        let vsId = this.props.spendDetails.currentActiveRequest.VS_ID;        

        let data = []
        //let editedRowsIds = this.state.editedRowsIds;
        let editedRowsIds = [0, 1, 2, 3, 4];
        let tableData = this.state.tableData;
        for (let i = 0; i < editedRowsIds.length; i++) {
            data.push(tableData[editedRowsIds[i]])
        }

        let records = []
        let userEmail = this.props.user.user.Email;
        let BENCHMARK_METRICS = this.props.kpiFilters !== undefined ? this.props.kpiFilters.metric : this.state.kpiBenchMarkFilters.metric; //this.props.kpiFilters.metric;
        let BENCHMARK_TYPE = this.props.kpiFilters !== undefined ? this.props.kpiFilters.type : this.state.kpiBenchMarkFilters.type; //this.props.kpiFilters.type;
        let ACTUAL_FLAG = 2;
        for (let i = 0; i < data.length; i++) {
            for (let j = 1; j <= 12; j++) {
                let obj = {}
                obj["VS_ID"] = data[i].VS_ID;
                obj["DMC_ID"] = data[i].DMC_ID;
                obj["USER_ID"] = userEmail;
                obj["BENCHMARK_METRICS"] = BENCHMARK_METRICS;
                obj["BENCHMARK_TYPE"] = BENCHMARK_TYPE;
                obj["ACTUAL_FLAG"] = ACTUAL_FLAG;
                obj["QUARTER_NO"] = 'Q' + j;
                obj["KPI_NAME"] = data[i].kpi;
                //obj["ACTUAL_VALUE"] = data[i]['srNo'] % 2 == 0 ? data[i]['Actual_Q' + j] * 1000000 : data[i]['Actual_Q' + j];
                //obj["ACTUAL_VALUE"] = kpiFormatRowWithThousandMillion[kpiRowCustomOrder[data[i]['srNo']]] == 'Million' ? data[i]['Actual_Q' + j] * 1000000 : data[i]['Actual_Q' + j];
                obj["ACTUAL_VALUE"] = data[i]['Actual_Q' + j];
                obj["GAP_VALUE"] = data[i]['Gap_Q' + j];
                obj["ATTAINMENT_VALUE"] = data[i]['Attainment_Q' + j];
                obj["MODEL_FLAG"] = this.props.spendDetails.kpi_Model;                
                records.push(obj)
            }
        }

        this.setState({ isLoading: true, isSaveInProgress: true, responseMsg: '' })
        this.props.defineScopeLoading(true)

        this.props.updateKPIDashBoardTabDataV2(records)
            .then((response) => {
                this.props.getUpdateKpiGoliveProjectStartDate(dmcId, vsId, userEmail, 'W', this.state.goLiveDate, this.state.startdate)
                    .then((response) => {
                        this.loadData()
                        this.setState({ responseMsg: 'Record/s has been updated successfully', isError: 'No', isLoading: false, isSaveInProgress: false, editedRowsIds: [] })
                        this.props.defineScopeLoading(false)
                        this.props.pendingChanges(null)
                    });
            }).catch((error) => {
                if (error.stack.includes('Network Error')) {
                    this.props.defineScopeLoading(false)
                    this.setState({ errorMsg: 'API service is down. Please contact administrator.', isError: 'Yes', isLoading: false, isSaveInProgress: false })
                }
                else {
                    this.props.defineScopeLoading(false)
                    this.setState({ errorMsg: this.props.content.error, isError: 'Yes', isLoading: false, isSaveInProgress: false })
                }
            })
    }

    handleResetTable() {
        this.setState({ responseMsg: '' })
        this.loadData()
    }

    render() {
        let currencySymbol = this.state.currencySymbol;
        const userInputPrefix = currencySymbol;

        const userInputSuffix = 'M';

        const numberMask = createNumberMask({
            prefix: userInputPrefix + '',
            //suffix: ' ' + userInputSuffix,
            allowDecimal: true
        })
        const numberMaskForThousands = createNumberMask({
            prefix: '',
            suffix: '',
            allowDecimal: false
        })

        return (
            <div className="kpiDashTable grid-x">
                <div className="medium-12">
                    {(this.state.isLoading) ?
                        <img style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={path + `images/ajax-loader.gif`} alt="" />
                        : null
                    }
                    {(this.state.isError === 'Yes') ?
                        <div style={{ color: 'red', textAlign: 'center' }} className="cell pt5">{this.state.errorMsg}</div>
                        : <div style={{ color: 'green', textAlign: 'center' }} className="cell pt5">{this.state.responseMsg}</div>
                    }
                </div>
                <div className="medium-12">
                    <button className={classNames('float-right', 'pr25',
                        {
                            'hand-cursor': this.state.isSaveInProgress ? true : false,
                            'gray-scale': this.state.isSaveInProgress ? false : true
                        })}
                        onClick={this.onSave} disabled={this.state.isSaveInProgress ? false : true} title={this.props.ui.save} type="submit" float >
                        <img width="20" src="images/save_active.svg" />
                    </button>
                </div>

                <div className="medium-12">
                    <div className="datePicker float-left width170 ">
                        <div className="dropDownHeader">{this.props.kpiLables.goLiveDate}</div>
                        <DatePicker selected={this.state.goLiveDate} onChange={(date) => this.setGoLiveDate(date)} />
                    </div>
                    <div className="datePicker float-left width170 ">
                        <div className="dropDownHeader">{this.props.kpiLables.projectStartDate}</div>
                        <DatePicker selected={this.state.startdate} onChange={(date) => this.setStartDate(date)} />
                    </div>
                    <div className="float-left width170 pl20 restoreBtn">
                        <Button content={this.props.kpiLables.restoreBtn} onClick={() => this.handleResetTable()} disabled={!this.state.isSaveInProgress} />
                    </div>
                </div>

                <div className="grid-x addPadding" />
                <div className='table-scroll grid-x'>
                    {this.state.tableData ?
                        <table>
                            {this.state.headers.map(el => <col></col>)}
                            <tr>
                                {this.state.headers.map(el => <th >{el}</th>)}
                            </tr>
                            {this.state.tableData.map((el, index) =>
                                <tbody>
                                    <tr>
                                        <td >{kpiTargetShortNames[el.kpi]}</td>
                                        <td className='secondHeader'> Planned  <br /> Target KPI <br /> KPI Estimate <br /> Actual <br /> Gap <br /> Attainment </td>
                                        <td > {el.PLANNED_Q1 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.PLANNED_Q1, 0)) : currencySymbol + formatThousands(roundFix(el.PLANNED_Q1, 0))} <br /> {el.TagetKpi_Q1 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.TagetKpi_Q1, 0)) : currencySymbol + formatThousands(roundFix(el.TagetKpi_Q1, 0))} <br />{el.SE_Estimated_Q1 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.SE_Estimated_Q1, 0)) : currencySymbol + formatThousands(roundFix(el.SE_Estimated_Q1, 0))}
                                            <br /><MaskedInput className="spendNum" id={index} cellId='Actual_Q1' mask={kpiFormatRowWithThousandMillion[index] == 'Thousand' ? numberMaskForThousands : numberMask} placeholder={'-'} value={el.Actual_Q1} onChange={e => this.handleActualValChange(e)} pattern='[0-9]*' /> <br />{el.Gap_Q1 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.Gap_Q1, 0)) : currencySymbol + formatThousands(roundFix(el.Gap_Q1, 0))} <br />{el.Attainment_Q1 == 0 ? '-' : roundFix(el.Attainment_Q1, 2) + '%'} </td>
                                        <td > {el.PLANNED_Q2 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.PLANNED_Q2, 0)) : currencySymbol + formatThousands(roundFix(el.PLANNED_Q2, 0))} <br /> {el.TagetKpi_Q2 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.TagetKpi_Q2, 0)) : currencySymbol + formatThousands(roundFix(el.TagetKpi_Q2, 0))} <br />{el.SE_Estimated_Q2 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.SE_Estimated_Q2, 0)) : currencySymbol + formatThousands(roundFix(el.SE_Estimated_Q2, 0))}
                                            <br /><MaskedInput className="spendNum" id={index} cellId='Actual_Q2' mask={kpiFormatRowWithThousandMillion[index] == 'Thousand' ? numberMaskForThousands : numberMask} placeholder={'-'} value={el.Actual_Q2} onChange={e => this.handleActualValChange(e)} pattern='[0-9]*' /> <br />{el.Gap_Q2 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.Gap_Q2, 0)) : currencySymbol + formatThousands(roundFix(el.Gap_Q2, 0))} <br />{el.Attainment_Q2 == 0 ? '-' : roundFix(el.Attainment_Q2, 2) + '%'} </td>
                                        <td > {el.PLANNED_Q3 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.PLANNED_Q3, 0)) : currencySymbol + formatThousands(roundFix(el.PLANNED_Q3, 0))} <br />  {el.TagetKpi_Q3 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.TagetKpi_Q3, 0)) : currencySymbol + formatThousands(roundFix(el.TagetKpi_Q3, 0))} <br />{el.SE_Estimated_Q3 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.SE_Estimated_Q3, 0)) : currencySymbol + formatThousands(roundFix(el.SE_Estimated_Q3, 0))}
                                            <br /><MaskedInput className="spendNum" id={index} cellId='Actual_Q3' mask={kpiFormatRowWithThousandMillion[index] == 'Thousand' ? numberMaskForThousands : numberMask} placeholder={'-'} value={el.Actual_Q3} onChange={e => this.handleActualValChange(e)} pattern='[0-9]*' /> <br />{el.Gap_Q3 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.Gap_Q3, 0)) : currencySymbol + formatThousands(roundFix(el.Gap_Q3, 0))} <br />{el.Attainment_Q3 == 0 ? '-' : roundFix(el.Attainment_Q3, 2) + '%'} </td>
                                        <td > {el.PLANNED_Q4 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.PLANNED_Q4, 0)) : currencySymbol + formatThousands(roundFix(el.PLANNED_Q4, 0))} <br /> {el.TagetKpi_Q4 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.TagetKpi_Q4, 0)) : currencySymbol + formatThousands(roundFix(el.TagetKpi_Q4, 0))}   <br />{el.SE_Estimated_Q4 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.SE_Estimated_Q4, 0)) : currencySymbol + formatThousands(roundFix(el.SE_Estimated_Q4, 0))}
                                            <br /><MaskedInput className="spendNum" id={index} cellId='Actual_Q4' mask={kpiFormatRowWithThousandMillion[index] == 'Thousand' ? numberMaskForThousands : numberMask} placeholder={'-'} value={el.Actual_Q4} onChange={e => this.handleActualValChange(e)} pattern='[0-9]*' /> <br />{el.Gap_Q4 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.Gap_Q4, 0)) : currencySymbol + formatThousands(roundFix(el.Gap_Q4, 0))} <br />{el.Attainment_Q4 == 0 ? '-' : roundFix(el.Attainment_Q4, 2) + '%'} </td>
                                        <td > {el.PLANNED_Q5 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.PLANNED_Q5, 0)) : currencySymbol + formatThousands(roundFix(el.PLANNED_Q5, 0))} <br /> {el.TagetKpi_Q5 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.TagetKpi_Q5, 0)) : currencySymbol + formatThousands(roundFix(el.TagetKpi_Q5, 0))}    <br />{el.SE_Estimated_Q5 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.SE_Estimated_Q5, 0)) : currencySymbol + formatThousands(roundFix(el.SE_Estimated_Q5, 0))}
                                            <br /><MaskedInput className="spendNum" id={index} cellId='Actual_Q5' mask={kpiFormatRowWithThousandMillion[index] == 'Thousand' ? numberMaskForThousands : numberMask} placeholder={'-'} value={el.Actual_Q5} onChange={e => this.handleActualValChange(e)} pattern='[0-9]*' /> <br />{el.Gap_Q5 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.Gap_Q5, 0)) : currencySymbol + formatThousands(roundFix(el.Gap_Q5, 0))} <br />{el.Attainment_Q5 == 0 ? '-' : roundFix(el.Attainment_Q5, 2) + '%'} </td>
                                        <td >  {el.PLANNED_Q6 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.PLANNED_Q6, 0)) : currencySymbol + formatThousands(roundFix(el.PLANNED_Q6, 0))} <br /> {el.TagetKpi_Q6 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.TagetKpi_Q6, 0)) : currencySymbol + formatThousands(roundFix(el.TagetKpi_Q6, 0))}  <br />{el.SE_Estimated_Q6 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.SE_Estimated_Q6, 0)) : currencySymbol + formatThousands(roundFix(el.SE_Estimated_Q6, 0))}
                                            <br /><MaskedInput className="spendNum" id={index} cellId='Actual_Q6' mask={kpiFormatRowWithThousandMillion[index] == 'Thousand' ? numberMaskForThousands : numberMask} placeholder={'-'} value={el.Actual_Q6} onChange={e => this.handleActualValChange(e)} pattern='[0-9]*' /> <br />{el.Gap_Q6 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.Gap_Q6, 0)) : currencySymbol + formatThousands(roundFix(el.Gap_Q6, 0))} <br />{el.Attainment_Q6 == 0 ? '-' : roundFix(el.Attainment_Q6, 2) + '%'} </td>
                                        <td >  {el.PLANNED_Q7 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.PLANNED_Q7, 0)) : currencySymbol + formatThousands(roundFix(el.PLANNED_Q7, 0))}  <br /> {el.TagetKpi_Q7 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.TagetKpi_Q7, 0)) : currencySymbol + formatThousands(roundFix(el.TagetKpi_Q7, 0))}  <br />{el.SE_Estimated_Q7 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.SE_Estimated_Q7, 0)) : currencySymbol + formatThousands(roundFix(el.SE_Estimated_Q7, 0))}
                                            <br /><MaskedInput className="spendNum" id={index} cellId='Actual_Q7' mask={kpiFormatRowWithThousandMillion[index] == 'Thousand' ? numberMaskForThousands : numberMask} placeholder={'-'} value={el.Actual_Q7} onChange={e => this.handleActualValChange(e)} pattern='[0-9]*' /> <br />{el.Gap_Q7 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.Gap_Q7, 0)) : currencySymbol + formatThousands(roundFix(el.Gap_Q7, 0))} <br />{el.Attainment_Q7 == 0 ? '-' : roundFix(el.Attainment_Q7, 2) + '%'} </td>
                                        <td > {el.PLANNED_Q8 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.PLANNED_Q8, 0)) : currencySymbol + formatThousands(roundFix(el.PLANNED_Q8, 0))} <br /> {el.TagetKpi_Q8 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.TagetKpi_Q8, 0)) : currencySymbol + formatThousands(roundFix(el.TagetKpi_Q8, 0))}    <br />{el.SE_Estimated_Q8 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.SE_Estimated_Q8, 0)) : currencySymbol + formatThousands(roundFix(el.SE_Estimated_Q8, 0))}
                                            <br /><MaskedInput className="spendNum" id={index} cellId='Actual_Q8' mask={kpiFormatRowWithThousandMillion[index] == 'Thousand' ? numberMaskForThousands : numberMask} placeholder={'-'} value={el.Actual_Q8} onChange={e => this.handleActualValChange(e)} pattern='[0-9]*' /> <br />{el.Gap_Q8 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.Gap_Q8, 0)) : currencySymbol + formatThousands(roundFix(el.Gap_Q8, 0))} <br />{el.Attainment_Q8 == 0 ? '-' : roundFix(el.Attainment_Q8, 2) + '%'} </td>
                                        <td > {el.PLANNED_Q9 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.PLANNED_Q9, 0)) : currencySymbol + formatThousands(roundFix(el.PLANNED_Q9, 0))} <br /> {el.TagetKpi_Q9 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.TagetKpi_Q9, 0)) : currencySymbol + formatThousands(roundFix(el.TagetKpi_Q9, 0))}   <br />{el.SE_Estimated_Q9 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.SE_Estimated_Q9, 0)) : currencySymbol + formatThousands(roundFix(el.SE_Estimated_Q9, 0))}
                                            <br /><MaskedInput className="spendNum" id={index} cellId='Actual_Q9' mask={kpiFormatRowWithThousandMillion[index] == 'Thousand' ? numberMaskForThousands : numberMask} placeholder={'-'} value={el.Actual_Q9} onChange={e => this.handleActualValChange(e)} pattern='[0-9]*' /> <br />{el.Gap_Q9 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.Gap_Q9, 0)) : currencySymbol + formatThousands(roundFix(el.Gap_Q9, 0))} <br />{el.Attainment_Q9 == 0 ? '-' : roundFix(el.Attainment_Q9, 2) + '%'} </td>
                                        <td > {el.PLANNED_Q10 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.PLANNED_Q10, 0)) : currencySymbol + formatThousands(roundFix(el.PLANNED_Q10, 0))} <br /> {el.TagetKpi_Q10 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.TagetKpi_Q10, 0)) : currencySymbol + formatThousands(roundFix(el.TagetKpi_Q10, 0))}   <br />{el.SE_Estimated_Q10 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.SE_Estimated_Q10, 0)) : currencySymbol + formatThousands(roundFix(el.SE_Estimated_Q10, 0))}
                                            <br /><MaskedInput className="spendNum" id={index} cellId='Actual_Q10' mask={kpiFormatRowWithThousandMillion[index] == 'Thousand' ? numberMaskForThousands : numberMask} placeholder={'-'} value={el.Actual_Q10} onChange={e => this.handleActualValChange(e)} pattern='[0-9]*' /> <br />{el.Gap_Q10 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.Gap_Q10, 0)) : currencySymbol + formatThousands(roundFix(el.Gap_Q10, 0))} <br />{el.Attainment_Q10 == 0 ? '-' : roundFix(el.Attainment_Q10, 2) + '%'} </td>
                                        <td > {el.PLANNED_Q11 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.PLANNED_Q11, 0)) : currencySymbol + formatThousands(roundFix(el.PLANNED_Q11, 0))} <br /> {el.TagetKpi_Q11 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.TagetKpi_Q11, 0)) : currencySymbol + formatThousands(roundFix(el.TagetKpi_Q11, 0))}   <br />{el.SE_Estimated_Q11 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.SE_Estimated_Q11, 0)) : currencySymbol + formatThousands(roundFix(el.SE_Estimated_Q11, 0))}
                                            <br /><MaskedInput className="spendNum" id={index} cellId='Actual_Q11' mask={kpiFormatRowWithThousandMillion[index] == 'Thousand' ? numberMaskForThousands : numberMask} placeholder={'-'} value={el.Actual_Q11} onChange={e => this.handleActualValChange(e)} pattern='[0-9]*' /> <br />{el.Gap_Q11 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.Gap_Q11, 0)) : currencySymbol + formatThousands(roundFix(el.Gap_Q11, 0))} <br />{el.Attainment_Q11 == 0 ? '-' : roundFix(el.Attainment_Q11, 2) + '%'} </td>
                                        <td > {el.PLANNED_Q12 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.PLANNED_Q12, 0)) : currencySymbol + formatThousands(roundFix(el.PLANNED_Q12, 0))} <br /> {el.TagetKpi_Q12 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.TagetKpi_Q12, 0)) : currencySymbol + formatThousands(roundFix(el.TagetKpi_Q12, 0))}   <br />{el.SE_Estimated_Q12 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.SE_Estimated_Q12, 0)) : currencySymbol + formatThousands(roundFix(el.SE_Estimated_Q12, 0))}
                                            <br /><MaskedInput className="spendNum" id={index} cellId='Actual_Q12' mask={kpiFormatRowWithThousandMillion[index] == 'Thousand' ? numberMaskForThousands : numberMask} placeholder={'-'} value={el.Actual_Q12} onChange={e => this.handleActualValChange(e)} pattern='[0-9]*' /> <br />{el.Gap_Q12 == 0 ? '-' : kpiFormatRowWithThousandMillion[index] == 'Thousand' ? formatThousands(roundFix(el.Gap_Q12, 0)) : currencySymbol + formatThousands(roundFix(el.Gap_Q12, 0))} <br />{el.Attainment_Q12 == 0 ? '-' : roundFix(el.Attainment_Q12, 2) + '%'} </td>


                                    </tr></tbody>
                            )}
                        </table>
                        : null}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        ui: state.content.ui,
        userData: state.valueStory,
        user: state.default,
        content: state.content.spendAnalysis.worldMap,
        focusLabel: state.content.spendAnalysis.enablement.focusLabel,
        spendDetails: state.spendDetails,
        currencySymbols: state.content.CurrencySymbols,
        kpiFilters: state.kpis.kpiBenchMarkFilters,
        kpiLables: state.content.kpiTab
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        resetError: error => dispatch(resetError(error)),
        defineScopeLoading: bool => dispatch(defineScopeLoading(bool)),
        getKPIDashBoardLoadDataV2: (DMCId, VSId, userEmail) => dispatch(getKPIDashBoardLoadDataV2(DMCId, VSId, userEmail)),
        getKPIDashBoardLoadFinalDataV2: (DMCId, VSId, userEmail) => dispatch(getKPIDashBoardLoadFinalDataV2(DMCId, VSId, userEmail)),
        
        updateKPIDashBoardTabDataV2: (records) => dispatch(updateKPIDashBoardTabDataV2(records)),
        pendingChanges: changes => dispatch(pendingChanges(changes)),
        getUpdateKpiGoliveProjectStartDate: (DMCId, VSId, userEmail, ReadWriteFlag, goLiveDate, projectStartDate) => dispatch(getUpdateKpiGoliveProjectStartDate(DMCId, VSId, userEmail, ReadWriteFlag, goLiveDate, projectStartDate)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(KPIDashboardManualV2)