import React, { Component } from 'react'
import { connect } from 'react-redux'
import ButtonWithoutLoader from 'UI/ButtonWithoutLoader'

import { ExcelUploadColumns } from 'Constants'
import {uploadUserDefinedFields,insertReportLogDetails} from 'actions/spendActions'

import * as FileSaver from "file-saver";
import * as XLSX from 'xlsx';
import { Workbook } from 'exceljs/dist/exceljs';
import classNames from "classnames";
import { path } from 'Constants'


const recommendationData= ['Already Transacting', 'Relationship Exists', 'Enterprise - Supplier Dedicated Specialist (Integration & Catalog)', "Enterprise - Integration & Catalog", "Enterprise - Integration",  "Enterprise - Portal & Catalog", 'Enterprise - Portal', 'Managed Standard Account', 'On Going Standard Account', 'Out of Scope']

const instructions=[
'1. Download the excel upload template.',
'2. Make edits or add data in excel for the desired suppliers.',
'3. Choose your file and upload the updated template.'
]

const notes=['- Upload template excel sheet contain editable(orange) columns and non-editable(blue) columns.',
  '- Validation on editable columns ensure data quality.  Your file will error if the data is not in the format expected.',
  '- Non-editable columns will not be uploaded and are for reference only',
  '- Buyer Supplier Enablement Recommendation column only allows input from dropdown selection',
  '- Enablement Wave column only allows input from dropdown selection',
  '- Buyer Supplier Relationship column allows input text length up to 50 characters',
  '- User Defined Text Field Columns allows input text length up to 128 characters',
  '- User Defined Number Field Columns allows only numbers'
  ]

class UploadUserDefinedFields extends Component{
  constructor(props)
  {
  super(props)
  this.state={
    csvFile: '',
    columns: [],
    data: [],
    responseMsg: '',
    excelData: [],
    isSaveInProgress: false,
  }

  }

  insertReportAudit(logType,fileName) {
    let dmcId = this.props.spendDetails.currentActiveRequest.DMC_ID;
    let vsId = this.props.spendDetails.currentActiveRequest.VS_ID;
    let user = this.props.user.user.Email;
    this.props.insertReportLogDetails(dmcId, vsId, user,logType,fileName)
        .then((response) => {
            this.setState({ isLoading: false });
        }).catch((error) => {
            if (error.stack.includes('Network Error')) {
                this.setState({ responseMsg: 'API service is down. Please contact administrator.', isError: 'Yes', isLoading: false })
            }
            else {
                this.setState({ responseMsg: this.props.content.error, isError: 'Yes', isLoading: false })
            }
        })
}

  handleChange(e){
    const files = e.target.files;
        if (files && files[0]) this.setState({ file: files[0], responseMsg: '', isError: 'No', isLoading: false, isSaveInProgress: false }, this.handleFile);        
  }

  handleFile() {
    this.setState({ responseMsg: ""})
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = (e) => {
        /* Parse data */
        const bstr = e.target.result;
        const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA: true });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_json(ws, { defval: "" });       
        this.setState({excelData: data})       
        
        //let excelData = data.filter(d=>d["Update Flag"]) 
        let excelData = data
        
        //validate invalid copy/paste data
        excelData.map((item)=>{
         if( isNaN(item["User Defined Numeric Field 1"]) || isNaN(item["User Defined Numeric Field 2"]) || isNaN(item["User Defined Numeric Field 3"]) || isNaN(item["User Defined Numeric Field 4"])
         || isNaN(item["User Defined Numeric Field 5"]) || item["User Defined Text Field 1"].length > 128 || item["User Defined Text Field 2"].length > 128 || item["User Defined Text Field 3"].length > 128
         || item["User Defined Text Field 4"].length > 128 || item["User Defined Text Field 5"].length > 128 || item["Buyer Supplier Relationship"].length > 50){            
            document.getElementById('csvFile').value = null;
            this.setState({ excelData: '', responseMsg: this.props.content.invalidDataInFile, isError: 'Yes', isLoading: true, isSaveInProgress: false })
            return; 
         }
        })  

        if(excelData.length == 0){
         this.setState({ responseMsg: this.props.content.invalidFileSelect, isError: 'Yes', isLoading: true, isSaveInProgress: false })
         return; 
        }
    };
 
    if (rABS) {
        reader.readAsBinaryString(this.state.file);
    } else {
        reader.readAsArrayBuffer(this.state.file);
    };

    //e.target.files = this.state.invalid && null

  }
  

  initializeColumns() {
    let columns = []
    for (let index = 0; index < ExcelUploadColumns.length; index++) {
        const fieldConfig = ExcelUploadColumns[index];

        let tableColumn = {
            header: fieldConfig.screen_field_name,
            key: fieldConfig.field,
            width: 30,
            sortable: true,
            editable: fieldConfig.is_editable === 'Yes',
            isNumeric : fieldConfig.data_type === 'numeric',
            isTextWithLengtLimit : fieldConfig.data_type === 'text' && (!!fieldConfig.textLength),
            textLength : fieldConfig.textLength,
            // resizable: true,
            // filterable: true,
            screenSeq: fieldConfig.screen_seq,
            mandatory: fieldConfig.mandatory === 'Yes',
            exportFl: fieldConfig.export_fl === 'Y',
            editProperty: fieldConfig.is_editable,
            //formatter: formatter(fieldConfig.alignment),
            helpText: fieldConfig.screen_help_text
        }
        columns.push(tableColumn)
    }

    return columns;
}

handleDownload(){
  this.setState({ isSaveInProgress : true}, this.downloadUserDefinedFields)
}

  downloadUserDefinedFields(){
    this.setState({ responseMsg: ""})
    //create new excel work book
    let workbook = new Workbook();
    // //add name to sheet
    let worksheet = workbook.addWorksheet(this.props.content.uploadTemplateFile); 

    const columnDetails = this.initializeColumns();
    worksheet.columns = columnDetails;

   for (const row of this.props.rowData) {
       worksheet.addRow(row);
   }
   
   let fname= this.props.content.uploadTemplateFile
  
   const nonEditableHeader = columnDetails.filter(i=>i.editable===false) 
   const editableHeader = columnDetails.filter(i=>i.editable===true) 
  
   //worksheet.protect('123@ariba', null);

     for(var i=0; i<recommendationData.length; i++){
       let cellIndex = i+2;
        worksheet.getCell('Y'+ cellIndex).value= recommendationData[i];
     } 

     editableHeader.map(item => {
      const currentColumn = worksheet.getColumn(item.key);
       switch(item.key){
        //  case 'isUserDefinedRowUpdated':
        //   currentColumn.eachCell(function(cell, rowNumber) {     
        //     cell.dataValidation={ 
        //       type: 'list',
        //       allowBlank: false,
        //       value: 'No',
        //       formulae: ['"Yes,No"'],
        //       showErrorMessage: true,
        //       errorStyle: 'error',
        //       errorTitle: 'User Selection',
        //       error: 'Please select value from dropdown list.',
        //  }
        //  });
        //  break;

        //  case 'BUYER_SUPPLIER_RELATIONSHIP':
        //     currentColumn.eachCell(function(cell, rowNumber) {     
        //       cell.dataValidation={ 
        //         type: 'textLength',
        //         operator: 'lessThan',
        //         showErrorMessage: true,
        //         allowBlank: true,
        //         formulae: [50],
        //         errorStyle: 'error',
        //         errorTitle: 'User Input',
        //         error: 'Input text length must be less than 50',
        //         showInputMessage: true,          
        //         promptTitle: 'User Input',
        //         prompt: 'Input text length must be less than 50'
        //    }
        //    });
        //  break;
         case 'SAP_ARIBA_BUY_ENABLE_MTHD_RECMD':
            currentColumn.eachCell(function(cell, rowNumber) {     
              cell.dataValidation={ 
                type: 'list',
                operator: 'equal',
                allowBlank: false,         
                showErrorMessage: true,
                errorStyle: 'error',
                errorTitle: 'User Selection',
                error: 'Please select value from dropdown list.',
                formulae: ['$Y$2:$Y$11'],
                //formulae: ['"Already Transacting,Relationship Exists,Enterprise - Supplier Dedicated Specialist (Integration & Catalog),Enterprise - Integration Ready & Catalog Candidate,Enterprise - Integration Ready Candidate,Enterprise - Integration & Catalog Candidate,Enterprise - Integration & Catalog,Enterprise - Integration Candidate,Enterprise - Integration,Enterprise - Portal & Catalog Candidate,Enterprise - Portal & Catalog,Enterprise - Portal,Managed Standard Account,On Going Standard Account,Out of Scope - Category Not Eligible"'],
           }
           });
         break;
         case 'ONBOARDING_WORKSTREAM_DETAILS':
            currentColumn.eachCell(function(cell, rowNumber) {     
              cell.dataValidation={ 
                type: 'list',
                operator: 'equal',
                allowBlank: false,
                showErrorMessage: true,
                errorStyle: 'error',
                errorTitle: 'User Selection',
                error: 'Please select value from dropdown list.',                    
                formulae: ['"Integration Questionnaire, Supplier Choice, Null"'],
           }
           });
         break;
         case 'ENABLEMENT_WAVE':
            currentColumn.eachCell(function(cell, rowNumber) {     
              cell.dataValidation={ 
                type: 'list',
                operator: 'equal',
                allowBlank: false,
                showErrorMessage: true,
                errorStyle: 'error',
                errorTitle: 'User Selection',
                error: 'Please select value from dropdown list.',                    
                formulae: ['"1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, Already Transacting, Relationship Exists, On Going Standard Account, Out of Scope, Negative spend"'],
           }
           });
         break;
       }   
       
       
       // Numeric column validation
       const numericColumn =  editableHeader.filter(d=>d.isNumeric === true);
       numericColumn.map(item => {
        const currentNumColumn = worksheet.getColumn(item.key);
        currentNumColumn.eachCell(function(cell, rowNumber) {     
           cell.dataValidation={ type: 'decimal',
            operator: 'greaterThan',
            formulae: [0],
            allowBlank: true,
            showErrorMessage: true,
            showInputMessage: true,
            promptTitle: 'Number',
            prompt: `Note: Accept only number.`,
            error: 'Please provide numeric value.',
        }
        });
      })

       // text with length limit column validation
       const textColumn =  editableHeader.filter(d=>d.isTextWithLengtLimit === true);
       textColumn.map(item => {
        const currentTextColumn = worksheet.getColumn(item.key);
        currentTextColumn.eachCell(function(cell, rowNumber) {     
           cell.dataValidation={ 
            type: 'textLength',
            operator: 'lessThan',
            showErrorMessage: true,
            allowBlank: true,
            formulae: [Number(item.textLength) + 1],  
            errorStyle: 'error',
            errorTitle: 'User Input',
            error: 'Input text length must be less than or equal to '+ item.textLength,
            showInputMessage: true,          
            promptTitle: 'User Input',
            prompt: 'Input text length must be less than or equal to '+ item.textLength
        }
        });
      })

       // DATA_COLLECTIONS_SKEY validation
       const skeyColumn =  nonEditableHeader.filter(d=>d.key === "DATA_COLLECTIONS_SKEY");
       skeyColumn.map(item => {
        const currentTextColumn = worksheet.getColumn(item.key);
        currentTextColumn.eachCell(function(cell, rowNumber) {     
           cell.dataValidation={ 
            type: 'textLength',
            operator: 'equal',
            showErrorMessage: true,
            allowBlank: true,
            formulae: [cell.value],  
            errorStyle: 'error',
            errorTitle: 'User Input',
            error: 'Not allowed to change Skey column value.',
            showInputMessage: true,          
            //promptTitle: 'User Input',
            prompt: `Note: Not allowed to change Skey column value.`,
        }
        });
      })

      // unlock editable column
      worksheet.getColumn(item.key).protection = {
        locked: false,
        hidden: false,
      };  

      })
    

   nonEditableHeader.map(item => {
       worksheet.getRow(1).getCell(item.key).fill = {
           type: 'pattern',
           pattern:'solid',
           fgColor:{ argb:'007ad9' }
       };
       worksheet.getRow(1).getCell(item.key).font = {   
           color: { argb: 'FFFFFF' }    
         }; 
       });

       editableHeader.map(item => {
       worksheet.getRow(1).getCell(item.key).fill = {
           type: 'pattern',
           pattern:'solid',
           fgColor:{ argb:'ff8c00' }
       };
       worksheet.getRow(1).getCell(item.key).font = {   
           color: { argb: 'FFFFFF' }    
         }; 

       // make header row readonly 
       worksheet.getRow(1).getCell(item.key).protection = {
           locked: false,
           hidden: false,
         };

         // Add autofilter to all column
   worksheet.autoFilter = 'A1:V1';

       }
       );
          
     // to make header row frozen
     worksheet.views = [
       {state: 'frozen', ySplit: 1}
       ];

    // below column used internally 		
    worksheet.getColumn('SAP_ARIBA_DOC_PROXY').hidden = true;    
    worksheet.getColumn('SAP_ARIBA_SPEND_PROXY').hidden = true;     
    worksheet.getColumn('V').hidden = true;   

    // add notes sheet
    let worksheet1 = workbook.addWorksheet("Read Me");
    let columns = [];
    let tableColumn = {
      header: "Instructions:",      
      width: 100
     }
     columns.push(tableColumn);
    worksheet1.columns = columns;

    for(var i=0; i<=instructions.length; i++){
      let cellIndex = i+2;
       worksheet1.getCell('A'+ cellIndex).value= instructions[i];
    } 

    // add notes header
    worksheet1.getCell('A7').value= "Notes:";
    for(var i=0; i<=notes.length; i++){
      let cellIndex = i+8;
       worksheet1.getCell('A'+ cellIndex).value= notes[i];
    } 
    
    //add data and file name and download
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });      
      FileSaver.saveAs(blob, fname+'.xlsx');
      this.setState({isSaveInProgress: false})
    });
    
    
}  

   handleSubmit = (e) => {
   //let rowData = this.state.excelData.filter(d=>d["Update Flag"])
   let rowData = this.state.excelData

  //  if(rowData.length == 0){
  //   this.setState({ responseMsg: this.props.content.invalidFileSelect, isError: 'Yes', isLoading: false, isSaveInProgress: false })
  //   return; 
  //  }else{
  //  rowData = this.state.excelData.filter(d=>d["Update Flag"].toString().toLowerCase() === "yes");
  //  if(rowData.length == 0){
  //   this.setState({ responseMsg: this.props.content.noRecordForUpdate, isError: 'Yes', isLoading: false, isSaveInProgress: false })
  //   return; 
  //   }
  //  }

   if(rowData.length == 0){
    this.setState({ responseMsg: this.props.content.noRecordForUpdate, isError: 'Yes', isLoading: false, isSaveInProgress: false })
    return; 
    }
   
   this.setState({isLoading: true, isSaveInProgress: true})
   const columnDetails = ExcelUploadColumns;
   
   const recordsToInsert = [];
      rowData.forEach((item) => {
            var excelDate = item[columnDetails.filter(d=>d.field === "USER_DATE_FIELD")[0].screen_field_name]; 
            var converted_date;
            
            if(isNaN(excelDate) || excelDate === ""){
              converted_date = excelDate              
            }else{
            var date = new Date(Math.round((item[columnDetails.filter(d=>d.field === "USER_DATE_FIELD")[0].screen_field_name] - (25567 + 1)) * 86400 * 1000));            
            var year = date.getFullYear();
            var month = (1 + date.getMonth()).toString();
            month = month.length > 1 ? month : '0' + month;

            var day = date.getDate().toString();
            day = day.length > 1 ? day : '0' + day;
            converted_date = month + '/' + day + '/' + year;
            }       
          
          const record = {
              VS_ID: this.props.spendDetails.currentActiveRequest.VS_ID, 
              DMC_ID: this.props.spendDetails.currentActiveRequest.DMC_ID,               
              USER_ID: this.props.user.user.Email,

              DATA_COLLECTIONS_SKEY: item[columnDetails.filter(d=>d.field === "DATA_COLLECTIONS_SKEY")[0].screen_field_name],
              USER_DEF_TEXT_FIELD_1: item[columnDetails.filter(d=>d.field === "USER_DEF_TEXT_FIELD_1")[0].screen_field_name],
              USER_DEF_TEXT_FIELD_2: item[columnDetails.filter(d=>d.field === "USER_DEF_TEXT_FIELD_2")[0].screen_field_name],
              USER_DEF_TEXT_FIELD_3: item[columnDetails.filter(d=>d.field === "USER_DEF_TEXT_FIELD_3")[0].screen_field_name],
              USER_DEF_TEXT_FIELD_4: item[columnDetails.filter(d=>d.field === "USER_DEF_TEXT_FIELD_4")[0].screen_field_name],
              USER_DEF_TEXT_FIELD_5: item[columnDetails.filter(d=>d.field === "USER_DEF_TEXT_FIELD_5")[0].screen_field_name],
              USER_DEF_NUMBERIC_FIELD_1: item[columnDetails.filter(d=>d.field === "USER_DEF_NUMBERIC_FIELD_1")[0].screen_field_name],
              USER_DEF_NUMBERIC_FIELD_2: item[columnDetails.filter(d=>d.field === "USER_DEF_NUMBERIC_FIELD_2")[0].screen_field_name],
              USER_DEF_NUMBERIC_FIELD_3: item[columnDetails.filter(d=>d.field === "USER_DEF_NUMBERIC_FIELD_3")[0].screen_field_name],
              USER_DEF_NUMBERIC_FIELD_4: item[columnDetails.filter(d=>d.field === "USER_DEF_NUMBERIC_FIELD_4")[0].screen_field_name],
              USER_DEF_NUMBERIC_FIELD_5: item[columnDetails.filter(d=>d.field === "USER_DEF_NUMBERIC_FIELD_5")[0].screen_field_name],

              BUYER_SUPPLIER_RELATIONSHIP: item[columnDetails.filter(d=>d.field === "BUYER_SUPPLIER_RELATIONSHIP")[0].screen_field_name],
              SAP_ARIBA_BUY_ENABLE_MTHD_RECMD: item[columnDetails.filter(d=>d.field === "SAP_ARIBA_BUY_ENABLE_MTHD_RECMD")[0].screen_field_name],
              ENABLEMENT_WAVE: item[columnDetails.filter(d=>d.field === "ENABLEMENT_WAVE")[0].screen_field_name],
              SAP_ARIBA_DOC_PROXY: item[columnDetails.filter(d=>d.field === "SAP_ARIBA_DOC_PROXY")[0].screen_field_name],
              SAP_ARIBA_SPEND_PROXY: item[columnDetails.filter(d=>d.field === "SAP_ARIBA_SPEND_PROXY")[0].screen_field_name],
              ENABLEMENT_WAVE_OVERRIDE : "Override",
              ENABLEMENT_RECOMMENDATION_OVERRIDE: "Override",
              
              USER_DATE_FIELD: converted_date,
              ENABLE_MTHD_RECMD_OUT_SCOPE_REASON: item[columnDetails.filter(d=>d.field === "ENABLE_MTHD_RECMD_OUT_SCOPE_REASON")[0].screen_field_name],
              ONBOARDING_WORKSTREAM_DETAILS: item[columnDetails.filter(d=>d.field === "ONBOARDING_WORKSTREAM_DETAILS")[0].screen_field_name]
          };
          recordsToInsert.push(record);        
      })

      this.props.uploadUserDefinedFields(recordsToInsert)
          .then((response) => {
              this.props.closePopup();
              this.setState({ responseMsg: 'Record/s has been updated successfully', isError: 'No', isLoading: false, isSaveInProgress: false })
              this.props.reloadGrid();
              this.insertReportAudit('Upload',this.state.file.name);
          }).catch((error) => {
              if (error.stack.includes('Network Error')) {
                  //this.props.defineScopeLoading(false)
                  this.setState({ responseMsg: 'API service is down. Please contact administrator.', isError: 'Yes', isLoading: false, isSaveInProgress: false })
              }
              else {
                  //this.props.defineScopeLoading(false)
                  this.setState({ responseMsg: this.props.content.error, isError: 'Yes', isLoading: false, isSaveInProgress: false })
              }
          })
   }

    render(){
        return(
            <div style={{padding:'30px'}}>
                            <div>
                                {(this.state.isSaveInProgress) ?
                                    <img style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
                                        src={path + `images/ajax-loader.gif`} alt="" />
                                    : null
                                }
                            </div>
                                   
                <div className="grid-x grid-padding-x">
                    <div className="medium-12 popHeader">{this.props.content.uploadPopupTitle}</div>
                    <div className="medium-12 tab-description">{this.props.content.uploadStep1}</div>
                    <div className="medium-12 tab-description">{this.props.content.uploadStep2}</div>
                    <div className="medium-12 tab-description">{this.props.content.uploadStep3}</div>                     
                </div>
                <br></br>
                <div className="grid-x grid-padding-x pt10">

                              <div className="medium-4"><ButtonWithoutLoader 
                                type='submit'
                                content={this.props.content.downloadBtn}  
                                onClick={(e)=>this.handleDownload(e)}                              
                                color
                                /></div>

                              <div className="medium-5 pt10" style={{ float: 'right' }}>
                                <input
                                  type="file"
                                  style={{display: 'block'}}
                                  accept='.xlsx, .xls'
                                  id='csvFile'
                                  onChange={(e)=>this.handleChange(e)}                                  
                                />
                              </div>
                              
                              <div className="medium-3" style={{ float: 'left' }}>
                               <ButtonWithoutLoader
                                type='submit'
                                content={this.props.content.uploadBtn}                          
                                color
                                onClick={this.handleSubmit.bind(this)}
                                className={classNames('float-right',
                                                    {
                                                        'hand-cursor': this.state.isLoading ? false : true,
                                                        'gray-scale': this.state.isLoading ? true : false
                                                    })}
                                disabled={this.state.isLoading ? true : false}
                                />
                              </div>

                 </div>
               
                <br></br>
                <div className="grid-x grid-padding-x" style={{ color: (this.state.isError === "Yes") ? 'red' : 'green' }}>{this.state.responseMsg}</div>            
            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
      userData: state.valueStory,
      user: state.default,
      validation: state.content.validation,
      spendDetails: state.spendDetails,
      content: state.content.spendAnalysis.detailedFile
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
      uploadUserDefinedFields: (records) => dispatch(uploadUserDefinedFields(records)),
      insertReportLogDetails:(DMC_ID, VS_ID, USER_ID, LOG_TYPE, FILE_NAME) => dispatch(insertReportLogDetails(DMC_ID, VS_ID, USER_ID, LOG_TYPE, FILE_NAME))
    }
  }
  
  export default connect(mapStateToProps,  mapDispatchToProps)(UploadUserDefinedFields)