import React, { Component } from 'react'
import { connect } from 'react-redux'

import {
    getIndustryDrillScopeDetailsLoading,
    getIndustryDrillScopeDetailsSuccess,
    getIndustryDrillScopeDetailsFailure,
    getIndustryDrillScopeSummaryData,
    setPopUpTabLoadingStatus
} from 'actions/spendActions'

import { path } from 'Constants'
import StackedBarChart from 'components/UI/StackedBarChart'

class IndustryScopeSummary extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {},
            skey: null,
            scopeSummaryData: []
        }
    }

    componentDidMount() {
        this.loadData(this.props.spendDetails.industryGICS_Details["SKEY"]);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.spendDetails.industryGICS_Details["SKEY"] != this.state.skey) {
            this.loadData(nextProps.spendDetails.industryGICS_Details["SKEY"]);
            this.setState({ skey: nextProps.spendDetails.industryGICS_Details["SKEY"] })
        }
    }

    loadData(gicsID) {
        this.props.setPopUpTabLoadingStatus(true)
        this.props.getIndustryDrillScopeSummaryData(
            this.props.spendDetails.currentActiveRequest.DMC_ID,
            this.props.spendDetails.currentActiveRequest.VS_ID, this.props.user.user.Email,
            gicsID
        ).then((response) => {
            this.props.setPopUpTabLoadingStatus(false)
            if (response.status !== undefined && response.status === 200) {
                // var isValidData = false;
                if (response.data.Result != undefined) {
                    // for (var i = 0; i < response.data.Result.length; i++) {
                    // 	if (response.data.Result[i]['IN_SCOPE_SPEND'] === null && response.data.Result[i]['OUT_SCOPE_SPEND'] === null) {
                    // 		isValidData = false;
                    // 	} else {
                    // 		isValidData = true;
                    // 	}
                    // }
                    this.setState({ scopeSummaryData: response.data.Result, isError: 'No', isLoading: false });
                }
            } else if (response.response && response.response.status == 400) {
                this.setState({ isLoading: false, isError: 'No', responseMsg: 'No Result Found' })
            } else if (response && response.stack && response.stack.includes('Network Error')) {
                this.setState({ responseMsg: this.props.content.validation.networkError, isError: 'Yes', isLoading: false })
            }
        }).catch((error) => {
            this.props.setPopUpTabLoadingStatus(false)
            this.setState({ responseMsg: this.props.content.validation.error, isError: 'Yes', isLoading: false })
        })

    }

    render() {
        return (
            <div className="grid-x">
                <div className="medium-12">
                    {(this.state.isLoading) ?
                        <img style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={path + `images/ajax-loader.gif`} alt="" />
                        : null
                    }
                    {(this.state.isError === 'Yes') ?
                        <div style={{ color: 'red', textAlign: 'center' }} className="cell">{this.state.responseMsg}</div>
                        : <div style={{ color: 'green', textAlign: 'center' }} className="cell">{this.state.responseMsg}</div>
                    }
                </div>

                <div className="medium-12">{this.props.industryScopeContent.header}</div>
                <div className="medium-12">
                    {this.state.scopeSummaryData !== (null && '' && undefined) && this.state.scopeSummaryData.length > 0 ?
                        <StackedBarChart data={this.state.scopeSummaryData} currency={this.props.currencySymbols[this.props.spendDetails.currentActiveRequest.Currency.trim()]} type={"Spend"} />
                        : <div className="pt10">{this.props.noData.noDataFound}</div>
                    }
                </div>
                {this.state.scopeSummaryData !== '' && this.state.scopeSummaryData !== null ?
                    <div className="medium-12 float-right-legend">
                        <div class="legend2"> <span class="key-dot inScope"></span>{this.props.content.spendAnalysis.defineScope.inScopeSpend} </div>
                        <div class="legend2"> <span class="key-dot outScope"></span>{this.props.content.spendAnalysis.defineScope.outScopeSpend}</div>
                    </div>
                    : null}

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        content: state.content,
        ui: state.content.ui,
        spendDetails: state.spendDetails,
        currencySymbols: state.content.CurrencySymbols,
        user: state.default,
        noData: state.content.spendAnalysis.defineScope,
        industryScopeContent: state.content.spendAnalysis.industryDrilldown.industryScopeSummaryGraph
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getIndustryDrillScopeDetailsSuccess: message => dispatch(getIndustryDrillScopeDetailsSuccess(message)),
        getIndustryDrillScopeDetailsFailure: error => dispatch(getIndustryDrillScopeDetailsFailure(error)),
        getIndustryDrillScopeDetailsLoading: bool => dispatch(getIndustryDrillScopeDetailsLoading(bool)),
        getIndustryDrillScopeSummaryData: (DMCId, VSId, userEmail, Id) => dispatch(getIndustryDrillScopeSummaryData(DMCId, VSId, userEmail, Id)),
        setPopUpTabLoadingStatus: bool => dispatch(setPopUpTabLoadingStatus(bool)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(IndustryScopeSummary);