import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
    updateIncomeStatementAndBalanceSheet
} from 'BusinessValue/BusinessValueCalculations'
// UI
import AddIcon from 'UI/AddIcon'

// Utils
import { BreakLine } from 'utils/Utils'
import classnames from 'classnames'
import {
    toggleValueDriver,
    toggleSubLever,
    toggleSKU
} from 'actions/valueDriverActions'

import {
	valueStoryCurrentMenu,
	showValueStoryMenu
}
    from 'actions/userActions'
    
import { store } from 'index'
// Settings
import { validators, currencies, mappingSKUsPriorityAndSubLever, routes } from 'Constants'
// Utils
import {
    getSubLeverIdObject, sortObjectArray
} from 'utils/Utils'
import Button from 'UI/Button'
import { dispatchToggleSKU } from 'components/SKU/SolutionChange'

class SkuContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            _selectedSkulist: [],
            _SelectAll: false,
            vlmAlertDiv: false
        }

        this.dispatchToggleSKU_local = this.dispatchToggleSKU_local.bind(this)
        this.updateSelectAllState = this.updateSelectAllState.bind(this)
        this.select_deSelect_AllSKU = this.select_deSelect_AllSKU.bind(this)
        this.vlmAlertToggle = this.vlmAlertToggle.bind(this)
    }
    
    componentWillMount() {
		window.scrollTo({top: 0, behavior: 'smooth'})
	}

    componentDidMount() {
        this.props.valueStoryCurrentMenu('Solutions');
        this.props.showValueStoryMenu(true);
        
        var toAvoidDuplicate = [];
        let _instance = this;
        this.props.userData.AreaOfInterest.forEach(element => {
            element.AOIValueDrivers.forEach(element_ValueDriver => {

                if (element_ValueDriver.SKUId !== null && element_ValueDriver.SKUId !== undefined) {
                    var splittedSKUList = element_ValueDriver.SKUId.toString().split(',');
                    for (let index = 0; index < splittedSKUList.length; index++) {
                        const element = splittedSKUList[index];
                        if (element != null && toAvoidDuplicate.indexOf(element.toString()) === -1) {
                            //console.log(`element aaded into state list`, element.toString(),_instance.state._selectedSkulist.indexOf(element.toString()) );

                            toAvoidDuplicate.push(element.toString());
                            this.setState(state => {
                                const _selectedSkulist = [...state._selectedSkulist, element.toString()];
                                return {
                                    _selectedSkulist
                                };
                            });
                        }
                    }
                }
            });
        });

        setTimeout(() => {
            this.updateSelectAllState(true);
        }, 10);
    }

    updateSelectAllState = (bool) => {
        if (this.state._selectedSkulist.length === (this.props.skus.skuList.length - 1) && bool) {
            this.setState({ _SelectAll: true });
        }
        else {
            this.setState({ _SelectAll: false });
        }
    }

    select_deSelect_AllSKU = (bool) => {
        if (bool) {
            var all_SKUs = this.props.skus.skuList.filter(item => item.id > 0);
            all_SKUs.forEach(element => {
                this.dispatchToggleSKU_local(element.id, bool);
            });
        }
        else {
            this.state._selectedSkulist.forEach(element => {
                this.dispatchToggleSKU_local(element, bool);
            });
        }
        this.setState({ _SelectAll: bool });
    }

    dispatchToggleSKU_local = (id, bool) => {

        if (id === "-1") // Condition for the Select All
        {
            this.select_deSelect_AllSKU(bool);
        }
        else {
            if (bool) {
                this.setState(state => {
                    if (state._selectedSkulist.indexOf(id) < 0) {
                        const _selectedSkulist = [...state._selectedSkulist, id];
                        return {
                            _selectedSkulist
                        };
                    }
                });
            }
            else {
                this.setState(state => {
                    const _selectedSkulist = state._selectedSkulist.filter((item, j) => id !== item);
                    return {
                        _selectedSkulist,
                    };
                });
            }
            dispatchToggleSKU(id, bool);

            setTimeout(() => {
                this.updateSelectAllState(bool);
            }, 100);
        }

    }

    vlmAlertToggle() {
        this.setState(prevState => {return {vlmAlertDiv: !prevState.vlmAlertDiv}})
    }

    render() {
        var withOutSort_SKU = this.props.skus.skuList;
        var _localSkus = withOutSort_SKU.sort(sortObjectArray('name', 'asc'));
        var _localSkus_new = _localSkus.sort(sortObjectArray('sortOrder', 'asc'));
        const _instance = this;
        var _selectedSkulist_fromState = this.state._selectedSkulist;
        var Skuss =
            Object.keys(_localSkus_new).map(function (sku) {
                var _isActive = _selectedSkulist_fromState.indexOf(_localSkus_new[sku].id) >= 0
                if (_localSkus_new[sku].id === "-1") {
                    _isActive = _instance.state._SelectAll
                    return (
                        <div className={classnames('medium-2 cell sku select-all', { 'sku--active': _isActive })} >
                            <div className="sku__text">{BreakLine(_localSkus_new[sku].name)}</div>
                            <AddIcon
                                toggle={_instance.dispatchToggleSKU_local}
                                active={_isActive}
                                id={_localSkus_new[sku].id}
                                subIndex={sku} />
                        </div>
                    )
                }
                return (
                    <div className={classnames('medium-2 cell sku', { 'sku--active': _isActive })} >
                        <div className="sku__text">{BreakLine(_localSkus_new[sku].name)}</div>
                        <AddIcon
                            toggle={_instance.dispatchToggleSKU_local}
                            active={_isActive}
                            id={_localSkus_new[sku].id}
                            subIndex={sku} />
                    </div>
                )
            });

        return (
            <div>
            <React.Fragment>
                <div className="bb_cacaca">
                    <div className="grid-container">
                        <div className="grid-x">
                            <div className="medium-12 large-12 solutions_title">
                                <div className="grid-x">
                                    <div className="page-title medium-3">{this.props.content.SKUs.title}</div>
                                    <div onClick={this.vlmAlertToggle} className="medium-8 font-bold hand-cursor" style={{lineHeight:"3", color: "#f0434c", fontSize: "12px"}}>{this.props.discoveryContent.vlmAlertHeader}</div>
                                    {<div className='medium-12'>
                                        {this.state.vlmAlertDiv ? <span>{this.props.discoveryContent.vlmAlertDesc1}<br /><br />{this.props.discoveryContent.vlmAlertDesc2}<a href="http://valuemanagement.sap.com/" target="_blank">{this.props.discoveryContent.vlmAlertDesc3}</a>.</span> : ''}
                                    </div>}
                                </div>
                                <div className="page-sub-title">Specify your solutions of interest</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="formBg">
                    <div className="grid-container bottom-space">
                        <div className="grid-x description">
                            <p><b>If you completed the areas of interest section,</b> solutions will automatically be turned on that could help you address your high and top business priorities. </p>
                            <p><b>If you do not yet know your solution scope,</b> click select all which will turn on all the value drivers related to our solutions. </p>
                            <p class="small">Once you have ﬁnalized your selections, click next to see the value levers that are aligned to your selected solutions, and make updates to what is in scope for your program.</p>
                        </div>
                        <div className="grid-x">
                            <div className="medium-12 large-12">
                                {Skuss}
                            </div>
                        </div>
                        <div className="grid-x grid" style={{ 'margin-top': '20px' }}>
                            <div className="medium-12 cell">
                                <Link to={routes.businessValue}>
                                    <Button content={this.props.ui.next} float />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
            </div>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        skus: state.content.SKUs,
        content: state.content,
        userData: state.valueStory,
        ui: state.content.ui,
        discoveryContent: state.content.discovery,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        toggleSKU: (id, bool) => dispatch(toggleSKU(id, bool)),
		valueStoryCurrentMenu: (menuItem) => dispatch(valueStoryCurrentMenu(menuItem)),
        showValueStoryMenu: (menuItem) => dispatch(showValueStoryMenu(menuItem)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SkuContainer)

