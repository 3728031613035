import React from 'react';
import * as d3 from 'd3';

// Utils
import { convertToMillion, formatThousands, convertToThousands } from 'utils/Utils'


class StackedBarChart extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            summaryScopeData: [],
        }

        this.drawChart = this.drawChart.bind(this);
    }

    componentDidMount() {
        this.drawChart(this.props);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.data !== this.props.data) {
            //Perform some operation
            this.drawChart(nextProps);
        }
    }

    componentWillUnmount(){
        // d3.select("#chart").selectAll("svg").remove();
        d3.selectAll('.tooltip').style('display', 'none');
    }

    formatAsPerMetrics = (data, withDecimal = false) => {
       var formattedValue = ""          
       if(this.props.selectedMetrics !=undefined)
       {
       switch(this.props.selectedMetrics){
             case("Invoice Spend"):
             case("PO Spend"):
             formattedValue = this.props.currency + convertToMillion(data,withDecimal)
             break;
   
             case("Invoice Count"):
             case("PO Count"):
             formattedValue = formatThousands(data)
             break;
       }
    }else{
        formattedValue = this.props.currency + convertToMillion(data,withDecimal)
    }
       return formattedValue;
    }

    drawChart = (props) => {
        var instance = this;
        if (props.data.length !== 0) {
            d3.select("#chart").selectAll("svg").remove();
            d3.selectAll('.tooltip').style('display', 'none');
            var data = props.data;
            var height = 550;
            var width = 1060;
            var margin = ({ top: 100, right: 100, bottom: 50, left: 200 });

            const series = d3.stack().keys(["IN_SCOPE_SPEND", "OUT_SCOPE_SPEND"])(data)
            //.offset(d3.stackOffsetExpand)(data)


            const xScale = d3.scaleLinear()
                .domain([0, d3.max(series, d => d3.max(d, d => d[1]))])
                .range([0, width - margin.right])

            const yScale = d3.scaleBand()
                .domain(data.map(d => d.SOLUTION_TYPE))
                .range([0, height - margin.bottom])
                .padding(0.5)
            //.style("padding-left", "100px");
            
            const rScale = d3.scaleBand()
                //.domain(data.map(d => props.type == 'Spend' ? (props.currency + convertToMillion(d.IN_SCOPE_SPEND)) : formatThousands(d.IN_SCOPE_SPEND)))
                .domain(data.map(d => props.type == 'Spend' ? instance.formatAsPerMetrics(d.IN_SCOPE_SPEND) : formatThousands(d.IN_SCOPE_SPEND)))
                .range([0, height - margin.bottom])
                .padding(0.5)

            const color = d3.scaleOrdinal()
                .domain(series.map(d => d.key))
                .range(["#33C3FF", "#ff8c00"])
                .unknown("#ccc")

            const siFormat = d3.formatPrefix(",.0", 1e6);
            const siFormat2 = d3.formatPrefix(",.0", 1e3)
            function tickFormat(num) {
                return siFormat(num);//.replace("G", "B");
            };
            function tickFormat2(num) {
                return siFormat2(num);//.replace("G", "B");
            };

            const xAxis = g => g
                .attr("transform", `translate(0,${height - margin.bottom})`)
                .call(d3.axisBottom(xScale).ticks(7))
                //.call(d3.axisBottom(xScale).tickSize(-365))
                .call(props.type == 'Spend' ? d3.axisBottom(xScale).tickFormat(tickFormat) : props.type == 'Count' ? d3.axisBottom(xScale) : d3.axisBottom(xScale).tickFormat(tickFormat2))
                .call(g => g.selectAll(".domain").remove())
                .call(g => g.selectAll(".tick text")
                    .attr("font-size", "15"))
            // .attr("class", "below")
            // .attr("font-family", "BentonSans, Arial, Helvetica, sans-serif"))


            const yAxis = g => g
                .attr("transform", `translate(0,-32)`)
                .call(d3.axisRight(yScale).tickSize(0))
                .call(g => g.selectAll(".domain").remove())
                .call(g => g.selectAll(".tick text")
                    .style("font-size", "15")
                    // .style("font-family", "BentonSans, Arial, Helvetica, sans-serif")
                    .style("font-weight", "600")
                )


            // const rAxis = g => g
            //     .attr("transform", `translate(970,0)`)
            //     .call(d3.axisRight(rScale).tickSize(0))
            //     .call(g => g.selectAll(".domain").remove())
            //     .call(g=>g.selectAll(".tick text")
            //     .attr("font-size","15")
            //     .attr("font-weight","700")
            //     .attr("fill","#33C3FF"))

            const svg = d3.select("#chart")
                .append("svg")
                .attr("viewBox", [0, 0, width, height])
                .style("overflow", "visible");

            // svg.append("g")
            //     .selectAll("g")
            //     .data(series)
            //     .enter().append("g")
            //     .attr("fill", d => color(d.key))
            //     .selectAll("rect")
            //     .data(d => d)
            //     .join("rect")
            //     .attr("x", d => xScale(d[0]))
            //     .attr("y", (d, i) => yScale(d.data.SOLUTION_TYPE))
            //     .attr("width", d => xScale(d[1]) - xScale(d[0]))
            //     .attr("height", 47);// yScale.bandwidth());
            //d3.select('#tooltipdiv').remove();
            var tooltip = d3.select("body").append("div").attr("class", "tooltip");
            // Create groups for each series, rects for each segment 
            var groups = svg.selectAll("g.cost")
                .data(series)
                .enter().append("g")
                .attr("class", "cost")
                .style("fill", d => color(d.key));

            var rect = groups.selectAll("rect")
                .data(function (d) { return d; })
                .enter()
                .append("rect")
                .attr("x", d => xScale(d[0]))
                .attr("y", (d, i) => yScale(d.data.SOLUTION_TYPE))
                .attr("width", d => xScale(d[1]) - xScale(d[0]))
                .attr("height", 47)
                // .on("mouseover", function () { tooltip.style("display", "none"); })
                // .on("mouseout", function () { tooltip.style("display", "none"); })
                // .on("mousemove", function (d) {
                //     var xPosition = d3.mouse(this)[0] - 15;
                //     var yPosition = d3.mouse(this)[1] - 25;
                //     tooltip.attr("transform", "translate(" + xPosition + "," + yPosition + ")");
                //     tooltip.select("text").text(d[0]);
                .on("mousemove", function (d) {
                    tooltip
                        .style("left", d3.event.pageX - 50 + "px")
                        .style("top", d3.event.pageY - 70 + "px")
                        .style("display", "inline-block")
                        .html(props.type == 'Spend' ?
                            //"Total: " + props.currency + convertToMillion(d.data.IN_SCOPE_SPEND + d.data.OUT_SCOPE_SPEND, true) + "<br>" + "In Scope: " + props.currency + (d.data.IN_SCOPE_SPEND == null ? 0 : convertToMillion(d.data.InScopeValue, true)) + "<br>" + "Out Of Scope: " + props.currency + (d.data.OUT_SCOPE_SPEND == null ? 0 : convertToMillion(d.data.OUT_SCOPE_SPEND, true))
                            "Total: " + instance.formatAsPerMetrics(d.data.IN_SCOPE_SPEND + d.data.OUT_SCOPE_SPEND, true) + "<br>" + "In Scope: " + (d.data.IN_SCOPE_SPEND == null ? 0 : instance.formatAsPerMetrics(d.data.InScopeValue, true)) + "<br>" + "Out Of Scope: " + (d.data.OUT_SCOPE_SPEND == null ? 0 :instance.formatAsPerMetrics(d.data.OutScopeValue, true))
                            : props.type == 'Count' ? "Total :" + formatThousands(d.data.IN_SCOPE_SPEND + d.data.OUT_SCOPE_SPEND) + "<br>" + "In Scope: " + (d.data.IN_SCOPE_SPEND == null ? 0 : formatThousands(d.data.InScopeValue)) + "<br>" + "Out Of Scope: " + (d.data.OUT_SCOPE_SPEND == null ? 0 : formatThousands(d.data.OutScopeValue))
                                : "Total: " + convertToThousands(d.data.IN_SCOPE_SPEND + d.data.OUT_SCOPE_SPEND) + "<br>" + "In Scope: " + (d.data.IN_SCOPE_SPEND == null ? 0 : convertToThousands(d.data.InScopeValue)) + "<br>" + "Out Of Scope: " + (d.data.OUT_SCOPE_SPEND == null ? 0 : convertToThousands(d.data.OutScopeValue))
                        );
                })
                .on("mouseout", function (d) {
                    tooltip.style("display", "none");
                })
            // d3.select(window).on('mouseout', () => {
            //     d3.selectAll('.tooltip').style('opacity', '0');
            // });

            // // Prep the tooltip bits, initial display is hidden
            // var tooltip = svg.append("g")
            //     .attr("class", "tooltip")
            //     .style("display", "none");

            // tooltip.append("rect")
            //     .attr("width", 30)
            //     .attr("height", 20)
            //     .attr("fill", "white")
            //     .style("opacity", 0.5);

            // tooltip.append("text")
            //     .attr("x", 15)
            //     .attr("dy", "1.2em")
            //     .style("text-anchor", "middle")
            //     .attr("font-size", "12px")
            //     .attr("font-weight", "bold");

            //set the label
            svg.selectAll("text")
                .data(data)
                .enter()
                .append("text")
                .attr("class", "below")
                .attr("x", d => 970)
                .attr("y", (d, i) => yScale(d.SOLUTION_TYPE) + 30)
                .attr("text-anchor", "left")
                //.text(function (d) { return (props.type == 'Spend' ? (props.currency + convertToMillion(d.IN_SCOPE_SPEND)) : props.type == 'Count' ? formatThousands(d.IN_SCOPE_SPEND) : convertToThousands(d.IN_SCOPE_SPEND)) })// convertToMillion(d.IN_SCOPE_SPEND, true);         
                .text(function (d) { return (props.type == 'Spend' ? instance.formatAsPerMetrics(d.IN_SCOPE_SPEND) : props.type == 'Count' ? formatThousands(d.IN_SCOPE_SPEND) : convertToThousands(d.IN_SCOPE_SPEND)) })
                .style("font-size", "15")
                // .style("font-family", "BentonSans, Arial, Helvetica, sans-serif")
                .style("font-weight", "700")
                .style("fill", "#33C3FF");

            //Uncomment below lines to add x axis
            // svg.append("g")
            //     .call(xAxis);

            svg.append("g")
                .call(yAxis);

            // svg.append("g")
            //     .call(rAxis);

            return svg.node();
        }
    }


    render() {
        return (
            <div id="chart" className="stackChartMarginTop">
            </div>
        )
    }
}


export default StackedBarChart