import React, { Component } from 'react'
import { connect } from 'react-redux'
import ANIndustryTransactionStats from 'components/Spend/ANIndustryTransactionStats'
import ANIndustrySpendStats from 'components/Spend/ANIndustrySpendStats'
import { RadioButton } from 'material-ui';

class IndustryNetworkStatsHistogram extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showANindustrySpendStats: false,
            stats: this.props.industryNetworkStats.industryTransaction
        }
        this.toggleComponent = this.toggleComponent.bind(this)
        this.radioButtonChecked = this.radioButtonChecked.bind(this)
    }

    toggleComponent() {
        this.setState((prevState) => { return { showANindustrySpendStats: !prevState.showANindustrySpendStats } })
    }

    radioButtonChecked(value) {
        if (value.target.value == 'industryTransaction') {
            this.setState({ stats: this.props.industryNetworkStats.industryTransaction })
        }
        else {
            this.setState({ stats: this.props.industryNetworkStats.industrySpend })
        }
    }

    render() {
        return (
            <div>
                <div className="grid-x">
                    <div className="tab-description">{this.props.industryNetworkStats.tabHeader}</div>
                    <div className="medium-12 mt20">
                        <div className="grid-x justifyStatsContent">
                            <div className="statsContentWidth"><RadioButton className='radioButton'
                                checked={this.state.stats === this.props.industryNetworkStats.industryTransaction}
                                id={'industryTransaction'}
                                value='industryTransaction'
                                onClick={value => this.radioButtonChecked(value)}
                                color="default"
                            ></RadioButton></div>
                            <div className="statsLabelAlign">{this.props.industryNetworkStats.industryTransaction}</div>
                            <div className="statsRadioButtons"><RadioButton className='radioButton'
                                checked={this.state.stats === this.props.industryNetworkStats.industrySpend}
                                id={'industrySpend'}
                                value='industrySpend'
                                onClick={value => this.radioButtonChecked(value)}
                                color="default"
                            ></RadioButton></div>
                            <div className="statsLabelAlign">{this.props.industryNetworkStats.industrySpend}</div>
                        </div>
                    </div>
                    <div className="medium-12 pb5">
                        <hr className="line" ></hr>
                    </div>
                    <div className="medium-12">
                        <div className="grid-x justifyStatsContent">
                            {/* <div className="grid-x"> */}
                            <div className="topSuppliers" > {this.props.industryNetworkStats.topSuppliers + ': ' + 'XX'} </div>
                            <div className="topRelations" >{this.props.industryNetworkStats.topSupplierRelationships + ': ' + 'YY'} </div>
                        </div>
                    </div>
                    <div className="medium-12">
                        {this.state.stats === this.props.industryNetworkStats.industryTransaction ?
                            <div className="medium-12" id="transactionStats">
                                <ANIndustryTransactionStats />
                            </div> : <div className="medium-12" id="transactionStats">
                                <ANIndustrySpendStats />
                            </div>}
                    </div>
                </div>
            </div >
        )
    }
}

const mapStateToProps = (state) => {
    return {
        content: state.content,
        ui: state.content.ui,
        user: state.default,
        industryNetworkStats: state.content.spendAnalysis.industryDrilldown.industryNetworkStatsHistogram,
        currencySymbols: state.content.CurrencySymbols,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(IndustryNetworkStatsHistogram); 