import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Control, Form, Errors, actions } from 'react-redux-form'
import { Checkbox } from 'material-ui';
// Utils
import { BreakLine } from 'utils/Utils'
// UI
import Textfield from 'react-mdl/lib/Textfield'
import Button from 'UI/Button'
import moment from 'moment'
// Settings
import { validators } from 'Constants'
import {
	roleManagerSuccess,
	roleManagerFailure,
	getAllFeatures,
	fetchRoleFeatures,
	addUpdateRoleAndFeatures,
	deleteRoleAndFeatures,
	resetError
} from 'actions/roleAndPermissionAction'

class RoleManager extends Component {
	constructor(props) {
		super(props)
		this.state = {
			roleFeatures: [],
			selectedFeaturesIDForUsers: [],
			filterText: '',
			roles: [],
			roleIsEditing: false,
			roleId: null
		}
	}

	componentDidMount() {
		this.props.resetError()
		this.loadData();
	}

	componentWillUnmount() {
		this.formDispatch(actions.reset('roleManager'))
		this.props.resetError()
	}


	loadData() {
		this.props.getAllFeatures().then((response) => {
			// this.props.roleManagerSuccess(response.data.Message)
			this.setState({ roleFeatures: response.data.Result });
		})

		this.fetchRolesAndFeatures(true);
	}

	fetchRolesAndFeatures(showLoading) {
		this.props.fetchRoleFeatures()
			.then((response) => {
				var t = (showLoading === true ? this.props.roleManagerSuccess(response.data.Message) : null)
				this.setState({ roles: response.data.Result })
			}).catch((error) => {
				if (error.stack.includes('Network Error')) {
					this.props.roleManagerFailure()
				}
				else {
					this.props.roleManagerFailure(error.response.data.Message)
				}
			})

	}

	handleFeatureCheckChanged(value) {
		var id = value.target.id;
		if (this.state.selectedFeaturesIDForUsers.indexOf(id) < 0) {
			this.setState(state => ({
				selectedFeaturesIDForUsers: [...state.selectedFeaturesIDForUsers, id]
			}));
		}
		else {
			this.setState({
				selectedFeaturesIDForUsers: this.state.selectedFeaturesIDForUsers.filter((item) => item !== id)
			});
		}

	}

	handleAddUpdateRoleAndFeatures(formValues) {
		if (this.state.selectedFeaturesIDForUsers.length < 1) {
			return this.props.roleManagerFailure(this.props.validation.requiredFeature)
		}

		this.props.resetError()

		var userRoleFeatures = [];
		Object.values(this.state.selectedFeaturesIDForUsers).map((featureId) => {
			userRoleFeatures.push({ FeatureId: featureId, IsMapped: true, AddedBy: this.props.user.user.Id, UpdatedBy: this.props.user.user.Id });
		})

		var editRoleId = this.state.roleId != null ? this.state.roleId : null
		var formData = { RoleName: formValues.Role, RoleId: editRoleId, RoleFeatures: userRoleFeatures, CurrentUser: this.props.user.user.Id };

		this.props.addUpdateRoleAndFeatures(formData)
			.then((response) => {
				this.fetchRolesAndFeatures()
				this.props.roleManagerSuccess(response.data.Message)
				this.resetSelection()
				this.setState({ filterText: "", filterRole: "", roleIsEditing: false, roleId: null })

			}).catch((error) => {
				if (error.stack.includes('Network Error')) {
					this.props.roleManagerFailure(this.props.validation.networkError)
				}
				else {
					this.props.roleManagerFailure(error.response.data.Message)
				}
			})

	}

	handleRowEdit(roleDetails, roleIsEditing) {
		this.props.roleManager.message = ''
		this.setState({
			selectedFeaturesIDForUsers: roleDetails.ApplicationFeatures.map((feature) => {
				return feature.ID.toString()
			}), roleIsEditing: !roleIsEditing, roleId: roleDetails.RoleId, editId: roleDetails.RoleId, roleName: roleDetails.RoleName
		})
		this.formDispatch(actions.change('roleManager.Role', roleDetails.RoleName));
		this.formDispatch(actions.setErrors('roleManager.Role', false));
	};

	handleRowDel(roleDetails) {
		this.props.roleManager.message = ''
		const result = window.confirm(this.props.validation.confirmDelete);
		if (result) {
			var formValues = { RoleName: roleDetails.RoleName, RoleId: roleDetails.RoleId, CurrentUser: this.props.user.user.Id };
			this.props.deleteRoleAndFeatures(formValues)
				.then((response) => {
					this.props.roleManagerSuccess(response.data.Message)
					var index = this.state.roles.indexOf(roleDetails);
					this.state.roles.splice(index, 1);
					this.setState(this.state.roles);
					this.resetSelection();
					this.setState({ roleIsEditing: false });
				}).catch((error) => {
					if (error.stack.includes('Network Error')) {
						this.props.roleManagerFailure(this.props.validation.networkError)
					}
					else {
						this.props.roleManagerFailure(error.response.data.Message)
					}
				})
		}
	};

	handleRowCancel() {
		var isRoleEditing = !this.state.roleIsEditing
		this.setState({
			roleIsEditing: isRoleEditing,
			selectedFeaturesIDForUsers: ''
		})
		this.formDispatch(actions.change('roleManager.Role', ''));
		this.setState({ filterText: '' });
		this.props.roleManager.message = ''
	};

	// handleUserInput(filterText) {
	// 	var selectedRole = this.state.roles.filter(r => r.RoleName.toLowerCase() === (filterText.target.value.toLowerCase()));
	// 	if (selectedRole.length === 1) {
	// 		this.handleRowEdit(selectedRole[0], null);
	// 		filterText.target.value = selectedRole[0].RoleName;
	// 		this.setState({ filterText: filterText.target.value.toLowerCase() });
	// 	}
	// 	else {
	// 		this.fetchRolesAndFeatures();
	// 		this.setState({ selectedFeaturesIDForUsers: "", roleIsEditing: false });
	// 	}

	// };

	handleUserInput(filterText) {
		this.setState({ filterText: filterText.target.value.toLowerCase() });
		this.props.roleManager.message = '';

	};

	resetSelection() {
		this.formDispatch(actions.change('roleManager.Role', ''));
		this.setState({ selectedFeaturesIDForUsers: '' });
	}

	isDuplicate(vals) {
		var response = true;
		var result = this.state.roles.filter((obj) => {
			if (obj.RoleName.toLowerCase() === vals.toLowerCase() && this.state.roleId !== obj.RoleId) {
				return true;
			}
		});
		result.length > 0 ? response = false : response = true;
		return response;
	}

	render() {
		var getFeatureComponent = (instance) => {
			var row = Object.values(instance.state.roleFeatures).map((features) => {
				var check = (instance.state.selectedFeaturesIDForUsers.indexOf(features.ID.toString()) > -1)

				return (
					<div className="grid-x chkContainer">
						<div className="checkBox">
							<Checkbox id={features.ID} checked={check} onClick={(e) => instance.handleFeatureCheckChanged(e)}
								name={features.ID} value={features.FeatureName} />
						</div>
						<div>
							<p id={features.ID} onClick={(e) => instance.handleFeatureCheckChanged(e)} defaultValue={features.ID} >{features.FeatureName}</p>
						</div>

					</div>
				)
			})
			return row;
		}
		return (
			<div>
				<div>
					<div className="grid-x">
						<div className="cell"><h2>{BreakLine(this.props.content.title)}</h2></div>
						<div>
							<Form
								model="roleManager"
								getDispatch={(dispatch) => this.formDispatch = dispatch}
								onSubmit={(values) => this.handleAddUpdateRoleAndFeatures(values)}
							>
								<div className="grid-x grid-padding-x">
									<SearchBar validation={this.props.validation} filterText={this.state.filterText} isDuplicate={this.isDuplicate.bind(this)} onUserInput={this.handleUserInput.bind(this)} />
									<div className="medium-7 cell">
										<span>{this.props.content.featureLabel}</span>
										<div className="grid-x listBox">
											{getFeatureComponent(this)}
										</div>
									</div>
									<div className="medium-2 cell buttonPostion">
										<Button
											type="submit"
											disabled={this.props.roleManager.isLoading}
											content={this.state.roleIsEditing ? this.props.content.update : this.props.content.add}
											color
										/>
									</div>
								</div>
							</Form>
							<div className="errors">{this.props.roleManager.error}</div>
							<div className="success">{this.props.roleManager.message}</div>

						</div>
					</div>
				</div>
				<div>
					<RoleFeatureTable
						onRowDel={this.handleRowDel.bind(this)}
						onRowEdit={this.handleRowEdit.bind(this)}
						// onRowSave={this.handleRowSave.bind(this)}
						onRowCancel={this.handleRowCancel.bind(this)}
						roleEditing={this.state.roleIsEditing}
						editId={this.state.editId}
						roles={this.state.roles}
						//roles={this.props.content.roles}
						filterText={this.state.filterText}
						filterRole={this.state.filterRole} />
				</div>

			</div>
		)
	}

}

class SearchBar extends React.Component {
	constructor(props) {
		super(props);
	}
	handleChange(event) {
		this.props.onUserInput(event);
	}
	render() {
		return (
			<div className="medium-5 cell">
				<Control.text
					model="roleManager.Role"
					id="roleManager.Role"
					component={Textfield}
					label={'Role'}
					floatingLabel
					onChange={this.handleChange.bind(this)}
					validators={{
						required: validators.required,
						duplicate: (vals) => this.props.isDuplicate(vals)
					}}
					validateOn="blur"
				/>
				<Errors
					className="errors"
					model="roleManager.Role"
					show="touched"
					messages={{
						required: this.props.validation.required,
						duplicate: this.props.validation.required + ' duplicate'
					}}
				/>
			</div>
		);
	}
}

class RoleFeatureTable extends React.Component {
	render() {
		var rowDel = this.props.onRowDel;
		var rowEdit = this.props.onRowEdit;
		// var rowSave = this.props.onRowSave;
		var rowCancel = this.props.onRowCancel;
		var filterText = this.props.filterText;
		var filterRole = this.props.filterRole;
		var roleEditing = this.props.roleEditing;
		var editId = this.props.editId;

		var role = this.props.roles.map(function (role) {
			if (role.RoleName.toLowerCase().indexOf(filterText) === -1) {
				return;
			}
			else return (<RoleFeatureRow
				role={role}
				//roles={roles}
				onDelEvent={rowDel.bind(this)}
				onEditEvent={rowEdit.bind(this)}
				onCancelEvent={rowCancel.bind(this)}
				roleIsEditing={roleEditing}
				editId={editId}
				key={role.RoleId} />)
		});
		return (
			<div className="basic-table">
				<table>
					<thead>
						<tr>
							<th>Role Name</th>
							<th>Features</th>
							<th>Updated By</th>
							<th>Updated On</th>
							<th></th>
							<th></th>
						</tr>
					</thead>

					<tbody>
						{role}
					</tbody>
				</table>
			</div>
		);
	}
}

class RoleFeatureRow extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			features: this.props.role.features,
			originalFeatures: this.props.role.features,
		};
		this.onChangeCancel = this.onChangeCancel.bind(this)
		this.onCancelEvent = this.onCancelEvent.bind(this)
	}

	onChangeCancel(e) {
		this.setState({
			features: this.state.originalFeatures
		})
		this.props.onCancelEvent()
	}

	onDelEvent() {
		this.props.onDelEvent(this.props.role);
	}

	onEditEvent() {
		this.props.onEditEvent(this.props.role, this.props.roleIsEditing);
	}

	onCancelEvent() {
		this.props.onCancelEvent(this.props.roleIsEditing);
	}

	render() {
		return (
			<tr className="eachRow">
				<td>{this.props.role.RoleName}</td>

				<td width="36%">
					{
						Object.values(this.props.role.ApplicationFeatures).map((appFeature, index) => {
							if (index === (this.props.role.ApplicationFeatures.length - 1)) {
								return appFeature.FeatureName
							}
							else { return appFeature.FeatureName + ", " }
						})
					}
				</td>


				<td>{this.props.role.UpdatedBy}</td>
				<td>{moment(this.props.role.UpdatedOn).format('L')}</td>
				<td>
					{
						!this.props.role.IsDefault && this.props.role.RoleName.toLowerCase() != "admin" ?
							<button onClick={this.onDelEvent.bind(this)} >Delete	</button>
							: null
					}
				</td>
				{

					this.props.roleIsEditing && this.props.editId === this.props.role.RoleId ?
						(
							<td >
								<button onClick={this.onChangeCancel}> Cancel </button>
							</td>
						)
						:
						(
							<td>
								<button onClick={this.onEditEvent.bind(this)}> Edit </button>
							</td>
						)
				}
			</tr>
		);
	}
}



const mapStateToProps = (state) => {
	return {
		language: state.language,
		ui: state.content.ui,
		user: state.default,
		content: state.content.roleManager,
		validation: state.content.validation,
		roleManager: state.userManagerUpdates
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		roleManagerSuccess: (bool) => dispatch(roleManagerSuccess(bool)),
		roleManagerFailure: (error) => dispatch(roleManagerFailure(error)),
		resetError: () => dispatch(resetError()),
		getAllFeatures: () => dispatch(getAllFeatures()),
		fetchRoleFeatures: (formValues) => dispatch(fetchRoleFeatures(formValues)),
		addUpdateRoleAndFeatures: (formValues) => dispatch(addUpdateRoleAndFeatures(formValues)),
		deleteRoleAndFeatures: (formValues) => dispatch(deleteRoleAndFeatures(formValues))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(RoleManager)