import React, { Component } from 'react'
import { connect } from 'react-redux'
import SolutionInScopeValues from './SolutionInScopeValues'
import EnablementResourceParametersAdmin from "../Spend/EnablementResourceParametersAdmin"
import EnablementPriority from "./EnablementPriority"
import SupplyChainProcess from "./SupplyChainProcess"
import FieldVisibility from "./FieldVisibility"

class VisibilityAndDefault extends Component {
    constructor(props) {
        super(props)
        this.state = {
            readOnly : props.user.userprofile.Roles[0].indexOf("Admin") > -1 ? false : true
        }
    }

    render() {
        return (            
            <div>
                <EnablementPriority readOnly={this.state.readOnly}></EnablementPriority>
                <br></br>
                <FieldVisibility></FieldVisibility>
                <br></br>    
                <SolutionInScopeValues></SolutionInScopeValues>
                <br></br>                
                <EnablementResourceParametersAdmin tab="Intelligent Strategy Model" readOnly={this.state.readOnly}></EnablementResourceParametersAdmin>
                <br></br>
                <EnablementResourceParametersAdmin tab="KPI Based Model" readOnly={this.state.readOnly}></EnablementResourceParametersAdmin>
                <br></br>
                <SupplyChainProcess></SupplyChainProcess>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.default
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VisibilityAndDefault)