import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Control, Form, Errors, actions } from 'react-redux-form'
import classNames from "classnames";
import { path } from 'Constants'
import moment from 'moment'
import {ControlTextE} from 'UI/CustomComponents/Controls';
// Actions
import {
    getFlightPlanningSpendParameters,
    updateFlightPlanningSpendParameters,
    resetError
} from 'actions/spendActions'

// Utils
import { BreakLine } from 'utils/Utils'

class FlightPlanningSpendParameters extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            flightPlanningSpendParameters: [],
            editedSpendParameters: [],
            respnseMessage: '',
            isError: false
        }
        this.loadData = this.loadData.bind(this)
        this.trackSpendParameterChanges = this.trackSpendParameterChanges.bind(this)
        this.onSpendParameterSave = this.onSpendParameterSave.bind(this)
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        this.getAllParameters();
    }

    trackSpendParameterChanges(record) {
        if (this.state.editedSpendParameters.length === 0 || this.state.editedSpendParameters.find(a => a.SPEND_ID === record.SPEND_ID) === undefined) {
            var updatedRecords = this.state.editedSpendParameters
            updatedRecords.push(record)
            this.setState({ respnseMessage: '', editedSpendParameters: updatedRecords, isError: false })
        }
    }

    getAllParameters() {
        this.setState({ respnseMessage: '', isError: false });
        this.props.getFlightPlanningSpendParameters().then((response) => {
            if (response.status === 200) {
                this.setState({ flightPlanningSpendParameters: response.data.Result, isLoading: false, respnseMessage: '', isError: false });
            }
            else {
                this.setState({ respnseMessage: this.props.content.errorMessage, isError: true });
            }
        }).catch((error) => {
            this.setState({ respnseMessage: this.props.content.errorMessage, isError: true });
        })
    }

    onSpendParameterSave() {
        if (this.state.editedSpendParameters.find(a => a.MIN_SPEND === '') === undefined && this.state.editedSpendParameters.find(a => a.MAX_SPEND === '') === undefined && this.state.editedSpendParameters.find(a => a.SPEND_SEGMENTATION === '') === undefined) {
            if (this.state.editedSpendParameters.find(a => a.MIN_SPEND === '-') === undefined && this.state.editedSpendParameters.find(a => a.MAX_SPEND === '-') === undefined) {
                if (this.state.editedSpendParameters.find(a => Number(a.MAX_SPEND) > 9000000000000000000) !== undefined) {
                    this.setState({ respnseMessage: this.props.content.maxSpendLimit, isError: true });
                }
                else if (this.state.editedSpendParameters.find(a => Number(a.MIN_SPEND) < -9000000000000000000) !== undefined) {
                    this.setState({ respnseMessage: this.props.content.minSpendLimit, isError: true });
                }
                else {
                    this.setState({ isLoading: true, respnseMessage: '', isError: false });
                    let email = this.props.user.user.Email
                    let updatedRecords = this.state.editedSpendParameters.map(item => ({ ...item }))
                    updatedRecords.map(function (parameter) {
                        parameter['UPDATED_BY'] = email
                    });
                    this.props.updateFlightPlanningSpendParameters(updatedRecords).then((response) => {
                        if (response.status === 200) {
                            // this.state.flightPlanningSpendParameters.map(function (parameter) {
                            //     if (updatedRecords.find(a => a.SPEND_ID === parameter.SPEND_ID))
                            //         parameter['UPDATED_BY'] = email
                            // });
                            this.setState({ flightPlanningSpendParameters: response.data.Result, editedSpendParameters: [], isLoading: false, respnseMessage: this.props.content.successMessage, isError: false });
                        }
                        else {
                            this.setState({ isLoading: false, respnseMessage: this.props.content.errorMessage, isError: true });
                        }
                    }).catch((error) => {
                        this.setState({ isLoading: false, respnseMessage: this.props.content.errorMessage, isError: true });
                    })
                }
            }
            else {
                this.setState({ respnseMessage: this.props.content.validNumber, isError: true });
            }
        }
        else {
            this.setState({ respnseMessage: this.props.content.blankValue, isError: true });
        }
    }

    componentWillUnmount() {
        this.props.resetError()
    }

    render() {
        var _instance = this
        var spendParameters = this.state.flightPlanningSpendParameters.map(function (parameter) {
            return (<TableRows
                spendParameter={parameter}
                trackSpendParameterChanges={_instance.trackSpendParameterChanges}
                readOnly={_instance.props.readOnly}
            />
            );
        });

        return (
            <div>
                <div>
                    <div className="grid-x">
                        <div className="medium-12">
                            <div>
                                {(this.state.isLoading) ?
                                    <img style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
                                        src={path + `images/ajax-loader.gif`} alt="" />
                                    : null
                                }
                            </div>
                            {this.state.flightPlanningSpendParameters.length > 0 ?
                                <div>
                                    <div className="grid-x">
                                        <div className="medium-3 float-left" style={{ fontSize: '1.2rem', paddingBottom: '8px' }}>{BreakLine(this.props.content.spendParameters)}</div>
                                        <div className="medium-6" style={{ display: 'flex', justifyContent: 'center' }} >
                                            {this.state.isError ?
                                                <div style={{ margin: '0 0 0.5rem', color: 'red' }}>{this.state.respnseMessage}</div> :
                                                <div style={{ margin: '0 0 0.5rem', color: '#4fb81c' }}>{this.state.respnseMessage}</div>
                                            }
                                        </div>
                                        <div className="medium-3"></div>
                                    </div>
                                    <div className="grid-x">
                                        <div className="medium-11 float-left" style={{ paddingBottom: '8px' }}>{this.props.content.spendDescription}</div>
                                        {this.props.user.userprofile.Roles[0].indexOf("Admin") > -1 ?
                                            <div className="medium-1" style={{ paddingBottom: '2px' }} >
                                                <button className={classNames('float-right',
                                                    {
                                                        'hand-cursor': this.state.isLoading ? false : (this.state.editedSpendParameters.length > 0),
                                                        'gray-scale': this.state.isLoading ? true : (this.state.editedSpendParameters.length === 0)
                                                    })}
                                                    onClick={this.onSpendParameterSave} disabled={this.state.isLoading ? true : this.state.editedSpendParameters.length === 0} type="submit" float >
                                                    <img width="25" title={this.props.content.save} src="images/save_active.svg" />
                                                </button>
                                            </div> : null}
                                    </div>
                                </div>
                                : null}
                        </div>
                    </div>
                    {this.state.flightPlanningSpendParameters.length > 0 ?
                        <div className="grid-x">
                            <div className="medium-12">
                                <Form
                                    model="flightPlanningSpendParameter"
                                >
                                    <div className="grid-x basic-table">
                                        <table>
                                            <thead>
                                                <tr className="eachRow">
                                                    <th>{this.props.content.minSpend}</th>
                                                    <th>{this.props.content.maxSpend}</th>
                                                    <th>{this.props.content.spendSegmentation}</th>
                                                    <th>{this.props.content.updatedBy}</th>
                                                    <th>{this.props.content.updatedOn}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {spendParameters}
                                            </tbody>
                                        </table>
                                    </div>
                                </Form>
                            </div>
                        </div>
                        : null}
                </div>
            </div>
        )
    }
}

class TableRows extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //parameter: this.props.spendParameter,
        };
    }

    onChangeEvent(val) {
        if (val.target.id === 'SPEND_SEGMENTATION' || val.target.value === '' ||
            /^(\-(\d*))$/.test(val.target.value) || !isNaN(val.target.value)) {
            var editingRow = this.props.spendParameter;
            if (val.target.id === 'SPEND_SEGMENTATION') {
                editingRow[val.target.id] = val.target.value
                this.props.trackSpendParameterChanges(editingRow)
            }
            else {
                let editedValue = val.target.value.trim()
                if (editedValue.toString() !== editingRow[val.target.id].toString()) {
                    editingRow[val.target.id] = editedValue
                    this.props.trackSpendParameterChanges(editingRow)
                }
            }
        }
    }

    render() {
        var bindValues = this.props.spendParameter;
        return (
            <tr className="eachRow">
                <td>
                    <ControlTextE
                        model="flightPlanningSpendParameter.MIN_SPEND"
                        onChange={this.onChangeEvent.bind(this)}
                        id={'MIN_SPEND'}
                        defaultValue={bindValues.MIN_SPEND}
                        value={bindValues.MIN_SPEND}
                        type='name'
                        className="edit-textarea"
                        readOnly={this.props.readOnly}
                    />
                </td>
                <td>
                    <ControlTextE
                        model="flightPlanningSpendParameter.MAX_SPEND"
                        onChange={this.onChangeEvent.bind(this)}
                        id={'MAX_SPEND'}
                        defaultValue={bindValues.MAX_SPEND}
                        value={bindValues.MAX_SPEND}
                        type='name'
                        className="edit-textarea"
                        readOnly={this.props.readOnly}
                    />
                </td>
                <td>
                    <ControlTextE
                        model="flightPlanningSpendParameter.SPEND_SEGMENTATION"
                        onChange={this.onChangeEvent.bind(this)}
                        id={'SPEND_SEGMENTATION'}
                        defaultValue={bindValues.SPEND_SEGMENTATION}
                        value={bindValues.SPEND_SEGMENTATION}
                        type='name'
                        className="edit-textarea"
                        readOnly={this.props.readOnly}
                    />
                </td>
                <td model="flightPlanningSpendParameter.UpdatedBy">{bindValues.UPDATED_BY}</td>
                <td model="flightPlanningSpendParameter.UpdatedOn">{moment(bindValues.UPDATED_ON).format('L')}</td>
            </tr>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        language: state.language,
        ui: state.content.ui,
        user: state.default,
        content: state.content.menu.EnablementParametersManager[0].MatchAndSegmentationTab.FlightPlanningSpendDetails
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        getFlightPlanningSpendParameters: () => dispatch(getFlightPlanningSpendParameters()),
        updateFlightPlanningSpendParameters: (records, userId) => dispatch(updateFlightPlanningSpendParameters(records, userId)),
        resetError: () => dispatch(resetError())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FlightPlanningSpendParameters)