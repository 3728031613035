import React from 'react'
import { connect } from 'react-redux'

import classnames from 'classnames'

import Select from 'react-select'

import {
    enablementSuccess,
    enablementLoading,
    enablementFailure,
    resetError,
    getConcentrationAnalysis,
    getBuyProcessSummary,
    storeUserSpendSelection,
    trackUserSpendSelections
} from 'actions/spendActions'
import Popup from "reactjs-popup";
import GroupBarScatterPlotChart from './GroupBarScatterPlotChart'
import ZoomModal from 'components/UI/ZoomModal'
import VideoHelp from 'components/UI/VideoHelp'

class ProgramEnablementAnalysis extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            focusOption: [],
            solutionOption: [],
            selectedFocus: '',
            selectedSolution: 'Automation Eligible',
            showPopup: false,
            enablementData: [],
            enablement: {
                DMC_ID: this.props.spendDetails.currentActiveRequest.DMC_ID,
                VS_ID: this.props.spendDetails.currentActiveRequest.VS_ID,
                User_Email: '',
                Solution: 'No Filter',
                BuyProcess: 'No Filter'
            },
            metricSpecificCol1: 'INVOICE_COUNT',
            metricSpecificCol2: 'MATCHED_INVOICES_COUNT',
            selectedBuyProcess: 'All',
            buyProcessOptions: [],
            showMatchedSuppliers: true,
            mode: 'normal',
            legendText: {
                invoiceText: 'Invoice Count',
                matchInvoiceText: 'Matched Invoices',
                supplierText: 'Suppliers',
                matchSupplierText: 'Matched Suppliers'
            },
            matchedFilter: [],
            selectedMatchedFilter: '',
            spendModule: 'Program Enablement Analysis',
            spendSubModule: 'Concentration analysis',
        }

        this.handleShowMatchSupplier = this.handleShowMatchSupplier.bind(this);
        this.zoomMode = this.zoomMode.bind(this)

    }

    componentDidMount() {
        this.loadData();
    }

    componentWillMount() {
        window.scrollTo(0, 0)
    }

    componentWillUnmount() {
        this.props.resetError();
    }

    loadData() {

        var array = [
            { label: 'Total Invoice Spend', value: 'Total Invoice Spend' },
            { label: 'Total Invoice Count', value: 'Total Invoice Count' },
            { label: 'Total Purchase Order Spend', value: 'Total Purchase Order Spend' },
            { label: 'Total Purchase Order Count', value: 'Total Purchase Order Count' },
        ]

        var solutionData = [
            { label: 'Sourcing', value: 'Sourcing' },
            { label: 'Buying and Invoicing', value: 'Buying and Invoicing' },
            { label: 'Fieldglass', value: 'Fieldglass' },
            { label: 'Commerce Automation (CA)', value: 'Commerce Automation' },
            { label: 'Supply Chain Collaboration (SCC)', value: 'Supply Chain Collaboration' },
            { label: 'Automation Eligible (CA & SCC)', value: 'Automation Eligible' },
            { label: 'Discount/SCF', value: 'Discount/SCF' },
            { label: 'Concur', value: 'Concur' },
        ]

        var matchFilterarray = [
            { label: 'Matched', value: 'Matched' },
            { label: 'Matched & Transacting', value: 'Matched & Transacting' },
        ]
        var target = this.props.spendDetails.userSpendFilterSelections.find((obj) => {
            return (obj.SpendModule === this.state.spendModule
                && obj.SpendSubModule === this.state.spendSubModule &&
                obj.CriteriaName === 'Match Filter')
        })
        var matchFilter = target.UserPreference === '' ? target.DefaultValue : target.UserPreference
        this.setState({
            focusOption: array, solutionOption: solutionData, selectedFocus: 'Total Invoice Count', selectedSolution: 'Automation Eligible', selectedBuyProcess: 'All',
            matchedFilter: matchFilterarray, selectedMatchedFilter: matchFilter
        })
        this.getBuyProcessSummary();
        this.groupScatterPlot_Data('Total Invoice Count');
    }

    groupScatterPlot_Data(metricType) {

        var objEnablement = this.state.enablement;
        objEnablement.User_Email = this.props.spendDetails.currentActiveRequest.SAPRequester === this.props.user.user.Email ? this.props.user.user.Email : this.props.spendDetails.currentActiveRequest.SAPRequester;
        objEnablement.Solution = this.state.selectedSolution;
        objEnablement.BuyProcess = this.state.selectedBuyProcess !== "All" ? this.state.selectedBuyProcess : objEnablement.BuyProcess;
        objEnablement.MetricType = metricType;
        objEnablement.MatchFilter = this.state.selectedMatchedFilter;

        this.props.getConcentrationAnalysis(objEnablement)
            .then((response) => {
                if (response.status === 200) {

                    this.setState({ enablementData: response.data.Result },
                        () => {
                            this.props.enablementSuccess('')
                        })
                }
            })
            .catch((exception) => {
                this.props.enablementFailure('')
            })

    }

    getBuyProcessSummary() {
        var objEnablement = {
            VS_ID: this.props.spendDetails.currentActiveRequest.VS_ID,
            DMC_ID: this.props.spendDetails.currentActiveRequest.DMC_ID,
            USER_ID: this.props.user.user.Email,
        }

        this.props.getBuyProcessSummary(objEnablement)
            .then((response) => {
                this.props.enablementSuccess(response.data.Message)
                let data = response.data.Result;
                var array = [];
                var buyProcessOptions = [{ label: 'All', value: 'No Filter' }]
                data.forEach((element) => {
                    var obj = {};
                    obj.Value = element.AGGR_INV_SPEND;
                    obj.KEY = element.SAP_ARIBA_BUY_PROCESS;
                    array.push(obj);

                    buyProcessOptions.push({ label: obj.KEY, value: obj.KEY })
                });
                if (response.data.Result != undefined) {
                    buyProcessOptions.sort()
                    this.setState({ buyProcessOptions: buyProcessOptions });
                }
            }).catch((error) => {
                if (error.stack.includes('Network Error')) {
                    this.props.enablementFailure()
                }
                else {
                    this.props.enablementFailure(error.response.data.Message)
                }
            })
    }



    handleMetricChange(value) {
        // To do fetch data on the of selected dimension          
        var col1 = '';
        var col2 = '';
        var dynamicLegend = this.state.legendText;


        switch (value.label) {
            case 'Total Invoice Spend':
                col1 = "INVOICE_SPEND_AMT";
                col2 = "MATCHED_INVOICE_SPEND_AMT";
                dynamicLegend.invoiceText = 'Invoice Spend';
                dynamicLegend.matchInvoiceText = 'Matched Invoice Spend'
                break;

            case 'Total Invoice Count':
                col1 = "INVOICE_COUNT";
                col2 = "MATCHED_INVOICES_COUNT";
                dynamicLegend.invoiceText = 'Invoice Count';
                dynamicLegend.matchInvoiceText = 'Matched Invoices'
                break;
            case 'Total Purchase Order Count':
                col1 = "PO_COUNT";
                col2 = "MATCHED_PO_COUNT";
                dynamicLegend.invoiceText = 'PO Count';
                dynamicLegend.matchInvoiceText = 'Matched PO Count'
                break;
            case 'Total Purchase Order Spend':
                col1 = "PO_SPEND_AMT";
                col2 = "MATCHED_PO_SPEND_AMT";
                dynamicLegend.invoiceText = 'PO Spend';
                dynamicLegend.matchInvoiceText = 'Matched PO Spend'
                break;

            default:
                col1 = "INVOICE_COUNT";
                col2 = "MATCHED_INVOICES_COUNT";
                break;
        }
        this.groupScatterPlot_Data(value.label)
        this.setState({ selectedFocus: value.label, metricSpecificCol1: col1, metricSpecificCol2: col2, legendText: dynamicLegend });
    }

    handleBuyProcessChange(value) {
        this.setState({ selectedBuyProcess: value.label === "All" ? value.value : value.label }, () => this.groupScatterPlot_Data(this.state.selectedFocus))
    }

    handleSolutionChange(value) {
        this.setState({ selectedSolution: value.value }, () => this.groupScatterPlot_Data(this.state.selectedFocus))
    }

    handleShowMatchSupplier() {
        this.setState({ showMatchedSuppliers: !this.state.showMatchedSuppliers })
    }

    showModal(action) {
        this.refs[action].show()
    }

    hideModal(action) {
        this.refs[action].hide()
    }

    zoomMode(mode) {
        this.setState({ mode: mode, showMatchedSuppliers: !this.state.showMatchedSuppliers }, () => this.setState({ showMatchedSuppliers: !this.state.showMatchedSuppliers }))
    }

    handleMatchedFilterChange(value) {
        var userEmail = this.props.spendDetails.currentActiveRequest.SAPRequester === this.props.user.user.Email ? this.props.user.user.Email : this.props.spendDetails.currentActiveRequest.SAPRequester;
        var VSId = this.props.spendDetails.currentActiveRequest.VS_ID
        var DMCId = this.props.spendDetails.currentActiveRequest.DMC_ID
        this.props.storeUserSpendSelection(VSId, DMCId, this.state.spendModule, this.state.spendSubModule, 'Match Filter', value.value, userEmail)
            .then((response) => {
                this.props.trackUserSpendSelections(response.data.Result)
                this.setState({ selectedMatchedFilter: value.value }, () => this.groupScatterPlot_Data(this.state.selectedFocus));
            }).catch((error) => {
                this.props.enablementFailure(error.response.data.Message)
            })
    }

    render() {
        return (
            <React.Fragment>
                <div className="enablement">
                    <div className="grid-x">
                        <div className="medium-12">
                            <div className="tab-title">{this.props.content.title}{<VideoHelp screen={{ module: 'Spend', screenName: 'Program Enablement Summary' }} />}</div>
                            <div className="tab-description pb10">{this.props.content.subTitle}</div>
                            <div className="grid-x grid-padding-x">
                                <div className="medium-2">
                                    <label className="labelSize">{this.props.content.focusLabel}</label>
                                    <Select
                                        autocomplete="true"
                                        value={this.state.focusOption.filter(option => option.label === this.state.selectedFocus)}
                                        onChange={(value) => this.handleMetricChange(value)}
                                        options={this.state.focusOption}
                                        isSearchable={false}
                                        className="border-with-DD dropDownContent"
                                    />
                                </div>
                                <div className="medium-2 cell">
                                    <label className="labelSize">{this.props.content.solutionLabel}</label>
                                    <Select
                                        autocomplete="true"
                                        value={this.state.solutionOption.filter(option => option.value === this.state.selectedSolution)}
                                        onChange={(value) => this.handleSolutionChange(value)}
                                        options={this.state.solutionOption}
                                        isSearchable={false}
                                        className="border-with-DD dropDownContent"
                                    />
                                </div>
                                <div className="medium-2 cell">
                                    <label className="labelSize">{this.props.content.buyProcessLabel}</label>
                                    <Select
                                        autocomplete="true"
                                        value={this.state.buyProcessOptions.filter(option => option.label === this.state.selectedBuyProcess || option.value === this.state.selectedBuyProcess)}
                                        onChange={(value) => this.handleBuyProcessChange(value)}
                                        options={this.state.buyProcessOptions}
                                        isSearchable={false}
                                        className="border-with-DD dropDownContent"
                                    />
                                </div>
                                <div className="medium-2 cell">
                                    <label className="labelSize">{this.props.content.matchFilter}</label>
                                    <div className="grid-x medium-12">
                                        <div className="medium-11">
                                            <Select
                                                className="border-with-DD dropDownContent"
                                                id='matchFilter'
                                                autocomplete='true'
                                                value={this.state.matchedFilter.filter(
                                                    option => JSON.stringify(option.value) === JSON.stringify(this.state.selectedMatchedFilter)
                                                )}
                                                onChange={value => this.handleMatchedFilterChange(value)}
                                                options={this.state.matchedFilter}
                                                isSearchable={false}
                                                isDisabled={this.props.spendDetails.isLoading}
                                                noOptionsMessage={() => null} />
                                        </div>
                                        <div className="medium-1">
                                            <Popup className="report-tooltip"
                                                trigger={<img style={{ paddingBottom: '5px' }} className="info-icon float-right" src="images/info-icon.svg" width="15" />}
                                                position="top left"
                                                on="hover"
                                            >
                                                {this.props.content.matchFilterToolTip}
                                            </Popup>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ paddingLeft: '10px' }} className="medium-2 pt26 hand-cursor" onClick={this.handleShowMatchSupplier}>
                                    {this.state.showMatchedSuppliers ?
                                        <img src="images/show_active.svg" width="30" />
                                        : <img src="images/show_inactive.svg" width="30" />
                                    }
                                </div>
                            </div>
                            <ZoomModal
                                mode={this.zoomMode}
                                classStyle="zoom-div-style">
                                <div className="grid-x pt10">
                                    <div className={classnames({ 'pt20': true, 'medium-10': true, 'graphDiv': (this.state.mode === 'normal'), 'graphExpandedDiv': (this.state.mode === 'zoomed') })}>
                                        {
                                            this.state.enablementData !== null && this.state.enablementData !== undefined && this.state.enablementData.length > 0 ?
                                                <GroupBarScatterPlotChart data={this.state.enablementData} column1={this.state.metricSpecificCol1} column2={this.state.metricSpecificCol2}
                                                    isShowMatchedData={this.state.showMatchedSuppliers} leftAxisText={this.state.legendText.invoiceText} />
                                                : <div className="pt10">{this.props.content.noDataFound}</div>}
                                    </div>
                                    {this.state.enablementData !== null && this.state.enablementData !== undefined && this.state.enablementData.length > 0 ?
                                        <div className="medium-2 pt10" style={{ 'position': 'relative', 'marginBottom': '30px' }}>
                                            <div className="legendbox">
                                                <div class="legend1"> <p class="country-name"><span class="key-dot invoices border-radius-5"></span>{this.state.legendText.invoiceText}</p> </div>
                                                {this.state.showMatchedSuppliers ?
                                                    <div class="legend1"> <p class="country-name"><span class="key-dot matchedInvoices border-radius-5"></span>{this.state.legendText.matchInvoiceText}</p> </div>
                                                    : null}
                                                <div class="legend1"> <p class="country-name"><span class="key-dot suppliers"></span>{this.state.legendText.supplierText}</p> </div>
                                                {this.state.showMatchedSuppliers ?
                                                    <div class="legend1"> <p class="country-name"><span class="key-dot matchedSuppliers"></span>{this.state.legendText.matchSupplierText}</p> </div>
                                                    : null}
                                            </div>
                                        </div>
                                        : null}
                                </div>
                            </ZoomModal>
                        </div>
                    </div>
                </div>
            </React.Fragment >
        )

    }
}


const mapStateToProps = (state) => {
    return {
        // language: state.language,
        // ui: state.content.ui,
        // user: state.default,
        user: state.default,
        content: state.content.spendAnalysis.enablement,
        validation: state.content.validation,
        spendDetails: state.spendDetails,
        currencySymbols: state.content.CurrencySymbols,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        enablementSuccess: message => dispatch(enablementSuccess(message)),
        enablementFailure: error => dispatch(enablementFailure(error)),
        enablementLoading: bool => dispatch(enablementLoading(bool)),
        resetError: error => dispatch(resetError(error)),
        getConcentrationAnalysis: (data) => dispatch(getConcentrationAnalysis(data)),
        getBuyProcessSummary: (data) => dispatch(getBuyProcessSummary(data)),
        storeUserSpendSelection: (VSId, DMCId, spendModule, spendSubModule, criteriaName, selectedValue, userEmail) => dispatch(storeUserSpendSelection(VSId, DMCId, spendModule, spendSubModule, criteriaName, selectedValue, userEmail)),
        trackUserSpendSelections: (userSelections) => dispatch(trackUserSpendSelections(userSelections))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProgramEnablementAnalysis)
