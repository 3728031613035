import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Control, Form, Errors, actions } from 'react-redux-form'
import { Link } from 'react-router-dom'
//import Marquee from 'grand-marquee-react';
import Parser from 'html-react-parser'
// Actions
import {
	signInUser,
	checkConsent,
	signInUserSuccess,
	signInUserFailure,
	userConsentSuccess,
	userConsentFailure,
	UpdateAcknowledgement,
	getToken,
	resetError,
	PeriodicValidationPartnersUsers,
	checkPasswordValidity,
	checkNewsLetterAcceptance,
	newsletterAcceptanceSuccess,
	newsletterAcceptanceFailure
} from 'actions/userActions'

import ChangePassword from 'components/Menu/ChangePassword'
import moment from 'moment'

// Components
import Textfield from 'react-mdl/lib/Textfield'
import Button from 'UI/Button'

// Utils
import { BreakLine, getCurrentLanguage } from 'utils/Utils'

// Settings
import { modalStyle, modalContentStyle, routes, validators } from 'Constants'

import Modal from 'reboron/OutlineModal'
import Terms from './Terms'
import NewUpdate from './NewUpdate'
import NewsLetter from './NewsLetter'
import {
	periodicEmailConfirmation,
	periodicEmailConfirmationFailure,
	periodicEmailConfirmationSuccess
} from 'actions/userActions'

class LoginContainer extends Component {
	constructor(props) {
		super(props)

		this.handleSignInUser = this.handleSignInUser.bind(this)
		this.redirect = this.redirect.bind(this)
		this.checkConsent = this.checkConsent.bind(this)
		this.showModal = this.showModal.bind(this)
		this.hideModal = this.hideModal.bind(this)
		this.checkNewsLetterAcceptance = this.checkNewsLetterAcceptance.bind(this)
		this.state = {
			enablePeriodicValidation: false,
			email: '',
			counter: 2,
			lockUser: false,
			isPasswordValid: true,
			isCustomer: false
		}
		this.PeriodicEmailConfirmation = this.PeriodicEmailConfirmation.bind(this)
		this.checkPasswordValidity = this.checkPasswordValidity.bind(this)
	}

	handleSignInUser(values) {
		var data = {};
		var content = this.props.content;
		this.setState({ email: values.Email })
		if (this.state.counter <= 0) {
			this.setState({ lockUser: true })
			data = {
				Email: values.Email,
				Password: values.Password,
				LockUser: true
			}
		} else {
			data = {
				Email: values.Email,
				Password: values.Password,
				LockUser: this.state.lockUser
			}
		}

		this.checkPasswordValidity(data)
		this.props.signInUser(data)
			.then((result) => {
				// var userResponseForActivateion = false;
				// // if(result.data.Result.NextPeriodicValidationInDays !== null ){
				// // 	  userResponseForActivateion = window.confirm('Account activation will expires in 10 days, Would you like to reactivate now?');
				// // 	  // + result.data.Result.NextPeriodicValidationInDays + ' && result.data.Result.NextPeriodicValidationInDays > 0
				// // }

				// if (userResponseForActivateion) {
				// 	this.props.PeriodicValidationPartnersUsers(values.Email)
				// 		.then((res) => {
				// 			this.props.signInUserFailure("Kindly check registered email address for reactivation.");
				// 		});
				// }
				// else
				// {
				
				if(result.data.Result.applicationUser) {
					result.data.Result.applicationUser.isCustomer = result.data.Result.isCustomer
				}
				this.props.signInUserSuccess(result);
				// Reset form
				this.formDispatch(actions.reset('signIn'))

				// Store JWT Token
				localStorage.setItem('jwtToken', result.data.Result.applicationUser.Token)
				localStorage.setItem('role', result.data.Result.applicationUser.Role)
				localStorage.setItem('loggedinUser', result.data.Result.applicationUser.Email)
				localStorage.setItem('id', result.data.Result.applicationUser.Id)
				localStorage.setItem('daysRemainforReactivation', result.data.Result.NextPeriodicValidationInDays)
				sessionStorage.setItem('isCustomer', result.data.Result.isCustomer)
				localStorage.setItem('enablementInsightFlag', result.data.Result.enablementFlag)
				// const sessionValues = {
				// Email: result.data.Result.applicationUser.Email,
				// VSId: null
				// }

				// localStorage.setItem('sessionInfo', JSON.stringify(sessionValues))

				//check consent of user for terms and conditions
				//this.checkConsent()
				if(!this.state.isPasswordValid)
                {
					this.props.resetError()
					this.showModal("changePassword")					            
				}	
				else{
					this.checkConsent()	            
				}				
				// // Redirect to Start page
				//this.redirect()
				//}

			}).catch((error) => {
				if (error.message === "Network Error") {
					this.props.signInUserFailure("API service is down. Please contact administrator.")
				}
				else {
					var errorMsg = content[error.response.data.Message];
					this.setState((prevState, props) => ({
						counter: prevState.counter - 1
					}));
					if (this.state.counter < 0) {
						if(error.response.status == "501"){
							var errMsg = content[error.response.status]	
							if(error.response.data.Message !== undefined){						
							localStorage.setItem('LockoutEndDate', error.response.data.Message)
							}
							var lockDate = localStorage.getItem('LockoutEndDate').split("-");	
							this.props.signInUserFailure(errMsg.replace('%Date%', lockDate[1] ))
							// moment(new Date().toLocaleString()).format("YYYY-MM-DD HH:mm:ss")))						
						}
						else{
						this.props.signInUserFailure(content[error.response.status]);
						}
					} else if (content[error.response.status] !== undefined && error.response.status !== "501" && errorMsg !== undefined) {
						this.setState({ enablePeriodicValidation: true });
						//this.props.signInUserFailure(errorMsg)
						if(error.response.status == "501"){
							var errMsg = content[error.response.status]		
							if(error.response.data.Message !== undefined){						
							localStorage.setItem('LockoutEndDate', error.response.data.Message)
							}
							var lockDate = localStorage.getItem('LockoutEndDate').split("-");
							
							this.props.signInUserFailure(errMsg.replace('%Date%', lockDate[1] ))
							// moment(new Date().toLocaleString()).format("YYYY-MM-DD HH:mm:ss")))
						}else{
							this.props.signInUserFailure(content[error.response.status])	
						}
					} else if (content[error.response.status] !== undefined) {
						this.setState({ enablePeriodicValidation: false });
						this.props.signInUserFailure(content[error.response.status])
					}
					else {
						this.props.signInUserFailure(error.response.data.Message);
					}
				}

			})
	}

	checkConsent() {
		var content = this.props.content;
		const value = {
			UserID: localStorage.getItem('id')
		}		
		this.props.checkConsent(value)
			.then((result) => {
				this.props.userConsentSuccess(result)
				if(!localStorage.getItem('loggedinUser').includes('sap.com') && sessionStorage.getItem('isCustomer') !== 'true')
		     	{
				this.checkNewsLetterAcceptance()
				}
				else{
					this.redirect()
				}
				//this.redirect()
				
			}).catch((error) => {
				if (error.response.status == 404) {
					this.showModal("terms")
				}
				this.props.userConsentFailure(error.response.data.Message)
			})
	}

	checkNewsLetterAcceptance(){
		const value = {
			UserID: localStorage.getItem('id')
		}		
		this.props.checkNewsLetterAcceptance(value)
			.then((result) => {
				this.setState({ newsLetterCheck : result.data.Result })
				this.props.newsletterAcceptanceSuccess(result)
				this.redirect()
			}).catch((error) => {
				if (error.response.status == 404) {
					this.showModal("newsLetter")
				}
				this.props.newsletterAcceptanceFailure(error.response.data.Message)
			})
	}

	showModal(action) {
		this.refs[action].show()
		}

	hideModal(action) {
		const value = {
			UserID: localStorage.getItem('id'),
			Accepted: true
		}
		this.props.UpdateAcknowledgement(value)
			.then((result) => {
				this.props.userConsentSuccess(result)
				this.redirect()
			}).catch((error) => {
				this.props.userConsentFailure(error.response.data.Message)
			})
		this.refs[action].hide()
	}

	checkPasswordValidity(param){
		const value = {
			UserID: param.Email
		}
		this.props.checkPasswordValidity(value)
			.then((result) => {
				this.props.userConsentSuccess(result)

				this.setState({isPasswordValid : result.data.Result})
				
			}).catch((error) => {				
				this.props.userConsentFailure(error.response.data.Message)
			})
	}

	redirect() {
		this.props.history.push(`/start`)
		
		/// To show start page as Translator will be the SAP user and will show everything to that user 

		// if (localStorage.getItem('role') !== null && localStorage.getItem('role').toUpperCase() === ('TRANSLATOR')) {

		// 		this.props.history.push({
		// 			pathname: '/language-manager', validation: this.props.validation

		// 		})
		// 	}
		// 	else {

		// 	}
	}

	componentWillUnmount() {
		this.formDispatch(actions.reset('signIn'))
		this.props.resetError()
	}

	PeriodicEmailConfirmation() {
		let language = getCurrentLanguage()

		if (language === undefined || language === 'undefined' || language === '') {
			language = 'en'
		}
		let values = { Email: this.state.email, Language: language };

		var content = this.props.content

		this.props.periodicEmailConfirmation(values)
			.then((response) => {
				this.setState({ enablePeriodicValidation: false })
				this.props.periodicEmailConfirmationSuccess(content[response.status])
			}).catch((error) => {
				this.props.periodicEmailConfirmationFailure(content[error.response.status])
			})
	}

	render() {
		return (
			<div className="grid-container pt20">
				<div className="grid-x">
					<div className="cell"><div className="page-title">{BreakLine(this.props.content.title)}</div></div>
					<div className="medium-6 cell">
						<Form
							model="signIn"
							getDispatch={(dispatch) => this.formDispatch = dispatch}
							method="post" onSubmit={(values) => this.handleSignInUser(values)}
						>
							<Control.text
								model="signIn.Email"
								id="signIn.Email"
								component={Textfield}
								label={this.props.content.email}
								floatingLabel
								validators={{
									required: validators.required,
									validEmail: validators.validEmail
								}}
								validateOn="blur"
							/>
							<Errors
								className="errors"
								model="signIn.Email"
								show="touched"
								messages={{
									validEmail: this.props.validation.validEmail
								}}
							/>
							<Control.text
								model="signIn.Password"
								id="signIn.Password"
								type="password"
								component={Textfield}
								label={this.props.content.password}
								floatingLabel
								validators={{
									required: validators.required
								}}
							/>
							<Errors
								className="errors"
								model="signIn.Password"
								show="touched"
								messages={{
									required: this.props.validation.required
								}}
							/>
							<ul>
								<li><Link to={routes.register}>{this.props.content.register}</Link></li>
								<li><Link to={routes.forgotPassword}>{this.props.content.forgotPassword}</Link></li>
								<li><a href="mailto:sap_ism_value_experience@sap.com">{this.props.content.help}</a></li>
							</ul>
							<br />
							<br />
							<Button
								type="submit"
								disabled={this.props.user.loading}
								content={this.props.content.login}
								color />
							<br />
							<div className="errors">
								{
									(this.state.enablePeriodicValidation) ?
										<div className="errors">


											{this.props.content.periodicErrorMessage}
											<ul>
												<li>
													<a className="errors" href="#" onClick={(event) => this.PeriodicEmailConfirmation(event)}> {Parser(this.props.content.periodicValidationLink)} </a>
												</li>
											</ul>
										</div>
										: <div> {this.props.user.error} </div>
								}
							</div>
						</Form>
					</div>
				</div>

				<div>
					<Modal
						ref="terms"
						modalStyle={modalStyle}
						contentStyle={modalContentStyle}>
						<Terms props={this.props.history} buttonVisible ={true} checkNewsLetterAcceptance = {this.checkNewsLetterAcceptance} />
					</Modal>
				</div>

				<div>
					<Modal
						ref="newsLetter"
						modalStyle={modalStyle}
						contentStyle={modalContentStyle}>
						<NewsLetter props={this.props.history} buttonVisible ={true} newsLetterCheck ={ this.state.newsLetterCheck} />
					</Modal>
				</div>

				<div>
					<Modal
						ref="changePassword"
						modalStyle={modalStyle}
						contentStyle={modalContentStyle}>
						<ChangePassword props={this.props.history} />
					</Modal>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		content: state.content.login,
		validation: state.content.validation,
		user: state.default
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		signInUser: (formValues) => dispatch(signInUser(formValues)),
		checkConsent: (formValues) => dispatch(checkConsent(formValues)),
		UpdateAcknowledgement: (formValues) => dispatch(UpdateAcknowledgement(formValues)),
		signInUserSuccess: (user) => dispatch(signInUserSuccess(user)),
		signInUserFailure: (error) => dispatch(signInUserFailure(error)),
		userConsentSuccess: (user) => dispatch(userConsentSuccess(user)),
		userConsentFailure: (error) => dispatch(userConsentFailure(error)),
		getToken: (username) => dispatch(getToken(username)),
		resetError: () => dispatch(resetError()),
		PeriodicValidationPartnersUsers: (email) => dispatch(PeriodicValidationPartnersUsers(email)),
		periodicEmailConfirmation: (email) => dispatch(periodicEmailConfirmation(email)),
		periodicEmailConfirmationSuccess: (bool) => dispatch(periodicEmailConfirmationSuccess(bool)),
		periodicEmailConfirmationFailure: (error) => dispatch(periodicEmailConfirmationFailure(error)),
		checkPasswordValidity: (formValues) => dispatch(checkPasswordValidity(formValues)),
		checkNewsLetterAcceptance: (formValues) => dispatch(checkNewsLetterAcceptance(formValues)),
		newsletterAcceptanceSuccess: (user) => dispatch(newsletterAcceptanceSuccess(user)),
		newsletterAcceptanceFailure: (error) => dispatch(newsletterAcceptanceFailure(error))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer)