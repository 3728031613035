import React, { Component } from "react";
import { connect } from 'react-redux'
import Button from 'UI/Button'

import { round, formatThousands, convertToMillion } from 'utils/Utils'
import {
    getResultClassSummary,
    //getResultMatchSummary,
    resetError,
    getResultLoading, getResultSuccess, getResultFailure, setResultSummaryDetails
} from 'actions/spendActions'

import VideoHelp from 'components/UI/VideoHelp'

class ClassificationSummary extends Component {
    constructor(props) {
        super(props)
        this.state = {
            classificationSummary: [],
            matchedSummary: [],
            isMatchedDataPresent: false,
            currencySymbol: ''
        }
    }

    getMessage(keyToFindMessage) {
        return this.props.content[keyToFindMessage];
    }

    getResultClassSummary() {

        if(this.props.spendDetails.resultDetails.length === 0)
        {
        this.props.getResultLoading(true);

        var userEmail = this.props.spendDetails.currentActiveRequest.SAPRequester === this.props.user.user.Email ? this.props.user.user.Email : this.props.spendDetails.currentActiveRequest.SAPRequester;

        this.props.getResultClassSummary(this.props.spendDetails.currentActiveRequest.DMC_ID, this.props.spendDetails.currentActiveRequest.VS_ID, userEmail).then((response) => {
            this.setState({ classificationSummary: response.data.Result.sort(), isMatchedDataPresent: false });
            this.props.setResultSummaryDetails(response.data.Result);
            this.props.getResultSuccess();
        })
            .catch((response) => {
                this.props.getResultFailure(this.getMessage("notfound"));
            })
        }
        else{
            this.setState({ classificationSummary: this.props.spendDetails.resultDetails.sort()});
        }
    }

    // getResultMatchSummary() {
    //     this.props.getResultLoading(true);
    //     this.props.getResultMatchSummary(this.props.spendDetails.currentActiveRequest.DMC_ID, this.props.spendDetails.currentActiveRequest.VS_ID, this.props.user.user.Email).then((response) => {
    //         this.setState({ matchedSummary: response.data.Result.sort(), isMatchedDataPresent: true })
    //         this.props.getResultSuccess();
    //     })
    //         .catch((response) => {
    //             this.props.topDetailsFailure(this.getMessage("notfound"));
    //         })
    // }

    toggleDiv() {
        var classDiv = document.getElementById("classDiv");
        var matchDiv = document.getElementById("matchDiv")
        if (classDiv !== null && matchDiv !== null) {
            if (matchDiv.style.display === "none") {
                classDiv.style.display = "none";
                matchDiv.style.display = "block";
            }
            else {
                classDiv.style.display = "block";
                matchDiv.style.display = "none";
            }
        }

    }

    componentDidMount() {
        this.setState({ currencySymbol: this.props.spendDetails.currentActiveRequest !== undefined ? this.props.currencySymbols[this.props.spendDetails.currentActiveRequest.Currency.trim()] : '' });
      
        this.getResultClassSummary()
       
        //this.getResultMatchSummary()
    }

    componentWillUnmount(){
        this.props.resetError();
    }

    componentWillMount() {
		window.scrollTo({top: 0, behavior: 'smooth'})
    }

    render() {
        return (
            <div className="result">
                <div className='grid-x'>
                    <div className="medium-12">
                        <div className="tab-title">{this.props.content.name}{<VideoHelp screen={{module: 'Spend', screenName: 'Classification Summary'}}/>}</div>
                        <div className="tab-description">{this.props.content.subTitle}</div>
                    </div>
                </div>
                <div className="grid-x pt30">
                    <div className="medium-4">
                        <div className="bold-header align-center">{this.props.content.recordCount}</div>
                        <div className="circleStyle">
                            <h5>{this.props.spendDetails.currentActiveRequest !== ('' && undefined && null) && this.props.spendDetails.currentActiveRequest.Number_Of_Records !== undefined ? formatThousands(this.props.spendDetails.currentActiveRequest.Number_Of_Records) : 0}</h5>
                        </div>
                    </div>
                    <div className="medium-8">
                        <div className="bold-header">{this.props.content.classificationSummary}</div>
                        <div id="classDiv" className="classSumary clearfix">
                            <SummaryTable
                                resultData={this.state.classificationSummary}
                                //isMatchSummary={false}
                                content={this.props.content}
                                currencySymbol={this.state.currencySymbol}
                            />
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}

class SummaryTable extends React.Component {
    constructor(props) {
        super(props)
        {
        }
    }
    render() {
        // var isMatchSummary = this.props.isMatchSummary
        var currencySymbol = this.props.currencySymbol
        var sortedData = this.props.resultData.sort((a, b) => (a.TEXT_ID > b.TEXT_ID) ? 1 : ((b.TEXT_ID > a.TEXT_ID) ? -1 : 0))
        var rows = sortedData.map(
            function (row) {
                return (
                    <Rows
                        name={row.TEXT}
                        total={row.VALUE}
                        classifiedPercent={row.VALUE_PERCENT}
                        //isMatchSummary={isMatchSummary}
                        currencySymbol={currencySymbol}
                    />
                )
            }
        );
        return (
            <div className="basic-table">
                <table>
                    <thead>
                        <tr>
                            <th className='tableHeader'>{this.props.content.total}</th>
                            <th></th>
                            <th className='tableHeader'>{this.props.content.classified}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows}
                    </tbody>
                </table>
            </div>
        );
    }
}

class Rows extends React.Component {
    constructor(props) {
        super(props)
        {
        }
    }
    render() {
        var percent = Math.round(this.props.classifiedPercent * 100);
        //var isMatchSummary = this.props.isMatchSummary;
        return (
            <tr className="eachRow">
                <td className="tableContent" width="30%">
                    {this.props.name}
                </td>
                <td className="tableContent" width="20%" style={{textAlign:'right', paddingRight:'100px'}}>
                    {this.props.name.includes('Spend') ? (this.props.currencySymbol !== undefined ? this.props.currencySymbol : '') + convertToMillion(this.props.total) : formatThousands(Math.round(this.props.total))}
                </td>
                <td className="tableContent" width="40%" style={{ padding: 'none' }} >
                    <div style={{ background: 'gainsboro' }}>
                        {/* </div><div style={{ width: percent, background: (isMatchSummary ? '#5bd45b' : '#4da1f7'), height: '20%' }} className="cell"> */}
                        <div style={{ width: percent + '%', background: ('#4da1f7'), height: '20%' }} className="cell">
                            <h5 style={{ paddingLeft: '10px' }}>&nbsp;</h5>
                        </div>
                    </div>
                </td>
                <td className="tableContent" style={{ textAlign: 'right', padding: 'none', color: '#008fd3' }} >
                    {percent + '%'}
                </td>
            </tr >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userData: state.valueStory,
        user: state.default,
        content: state.content.spendAnalysis.results,
        spendDetails: state.spendDetails,
        currencySymbols: state.content.CurrencySymbols,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getResultSuccess: message => dispatch(getResultSuccess(message)),
        getResultFailure: error => dispatch(getResultFailure(error)),
        getResultLoading: bool => dispatch(getResultLoading(bool)),
        resetError: error => dispatch(resetError(error)),
        setResultSummaryDetails : data => dispatch(setResultSummaryDetails(data)),
        getResultClassSummary: (DMC_ID, VS_ID, User_ID) => dispatch(getResultClassSummary(DMC_ID, VS_ID, User_ID))
        //,getResultMatchSummary: (DMC_ID, VS_ID, User_ID) => dispatch(getResultMatchSummary(DMC_ID, VS_ID, User_ID))

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClassificationSummary)


