import React, { Fragment } from 'react';
import { connect } from 'react-redux'
import Select from 'react-select'
import classNames from "classnames"
import { path } from 'Constants'
import cloneDeep from 'lodash/cloneDeep';
import { roundFix, formatThousands, getNonEmptyValue, isColumnMapped, convertToMillion } from 'utils/Utils'
import { Formik, withFormik } from 'formik'
import {
    getIndustryDrillScopeDetailsLoading,
    getIndustryDrillScopeDetailsSuccess,
    getIndustryDrillScopeDetailsFailure,
    getIndustryDrillScopeDetails,
    industryDrillScopeUpdateLoading,
    trackSupplierDrillDownSave,
    trackIndustryDrillScopeChanges,
    storeIndustryDetails,
    industryDrillScopeUpdate,
    getIndustrySpend,
    setPopUpTabLoadingStatus
} from 'actions/spendActions'

//UI Components
import { RadioButton } from 'material-ui';
import { ControlTextE, SelectE, CheckboxE, RadioButtonE, ButtonE, ButtonNativeE, TextAreaE } from 'UI/CustomComponents/Controls';

const dropdownValues = [{ label: 'Yes', value: 'Yes' }, { label: 'No', value: 'No' }];
//const directSpendValues = [{label: 'No', value: 'No'}, {label: 'Yes', value: 'Yes'}];
const scopeValues = [{ label: 'Industry', value: 'Industry' }, { label: 'Override', value: 'Override' }];
const requisitionValues = [{ label: 'Catalog', value: 'Catalog' }, { label: 'Adhoc (non-catalog)', value: 'Adhoc (non-catalog)' }, { label: 'N/A', value: 'N/A' }]
const aribaBuyProcessValues = [{ label: 'Requisition: Catalog, Spot-Buy or Release Contract', value: 'Requisition: Catalog, Spot-Buy or Release Contract' },
{ label: 'Requisition: Non-Catalog or Tactical Sourcing', value: 'Requisition: Non-Catalog or Tactical Sourcing' },
{ label: 'Requisition: External Talent', value: 'Requisition: External Talent' },
{ label: 'Consultancy/SOW Services: Active Mgmt, otherwise Invoice Against Contract', value: 'Consultancy/SOW Services: Active Mgmt, otherwise Invoice Against Contract' },
{ label: 'Other Services: Active Mgmt, otherwise Invoice Against Contract', value: 'Other Services: Active Mgmt, otherwise Invoice Against Contract' },
{ label: 'Invoice Against Contract', value: 'Invoice Against Contract' },
{ label: 'Direct/MRO: Plan Driven PO', value: 'Direct/MRO: Plan Driven PO' },
{ label: 'Direct/MRO: Asset Plan via Catalog', value: 'Direct/MRO: Asset Plan via Catalog' },
{ label: 'Non-PO Invoice', value: 'Non-PO Invoice' },
{ label: 'Travel and Expense', value: 'Travel and Expense' }]

const validate = values => {
    const errors = {};
    if (!values.firstName) {
        errors.firstName = 'Required';
    } else if (values.firstName.length > 15) {
        errors.firstName = 'Must be 15 characters or less';
    }

    if (!values.lastName) {
        errors.lastName = 'Required';
    } else if (values.lastName.length > 20) {
        errors.lastName = 'Must be 20 characters or less';
    }

    if (!values.email) {
        errors.email = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address';
    }

    return errors;
};

class IndustriesScopeAndMatch extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            warningRecords: [],
            errorRecords: [],
            warningDiv: true,
            errorDiv: true,
            scopeNotes: false,
            industryScopeDataCurrent: {},
            directSpendValues: [{ label: 'No', value: 'No' }, { label: 'Yes', value: 'Yes' }],
            industryScopeData: {},
            userComment: '',
            isUserCommentTextboxClicked: 'No',
            scopeChildFlag: 'NO',
            commentChildFlag: 'NO',
            skey: null,
            characterCount: 150,
            warningMessage: '',
            remaingHistoricalCommentsLength: 150,
            historicalSupplierComments: '',
            totalAggregatedData: { INVOICE_COUNT: 0, INVOICE_SPEND: 0.0, PO_COUNT: 0, PO_SPEND: 0.0, UNIQUE_SUPPLIERS: 0 },
            currentAggregatedData: { INVOICE_COUNT: 0, INVOICE_SPEND: 0.0, PO_COUNT: 0, PO_SPEND: 0.0, UNIQUE_SUPPLIERS: 0 },
            childAggregatedData: { INVOICE_COUNT: 0, INVOICE_SPEND: 0.0, PO_COUNT: 0, PO_SPEND: 0.0, UNIQUE_SUPPLIERS: 0 },
            showFlag: false,
            isSaveInProgress: false
        }

        this.handleScopeSourceChange = this.handleScopeSourceChange.bind(this)
        this.isDisabled = this.isDisabled.bind(this)
        this.onSave = this.onSave.bind(this)
        this.onUpdate = this.onUpdate.bind(this)
        this.validateForWarning = this.validateForWarning.bind(this)
        this.validateForError = this.validateForError.bind(this)
        this.warningToggle = this.warningToggle.bind(this)
        this.errorToggle = this.errorToggle.bind(this)
        this.addWarningErroredRecord = this.addWarningErroredRecord.bind(this)
        this.getValidationDiv = this.getValidationDiv.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.hasRecordErrors = this.hasRecordErrors.bind(this)
        this.hasPendingChange = this.hasPendingChange.bind(this)
        this.toggleScopeNotes = this.toggleScopeNotes.bind(this)
        this.RadioButtonChecked = this.RadioButtonChecked.bind(this)
        this.toggleShow = this.toggleShow.bind(this)
    }

    componentDidMount() {
        this.loadData(this.props.spendDetails.industryGICS_Details["SKEY"]);
        this.loadSpendData(this.props.spendDetails.industryGICS_Details["SKEY"])
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.spendDetails.industryGICS_Details["SKEY"] != this.state.skey) {
            this.loadData(nextProps.spendDetails.industryGICS_Details["SKEY"]);
            this.loadSpendData(nextProps.spendDetails.industryGICS_Details["SKEY"])
            this.setState({ skey: nextProps.spendDetails.industryGICS_Details["SKEY"] })
        }
    }

    toggleScopeNotes() {
        this.setState({ scopeNotes: !this.state.scopeNotes })
    }

    toggleShow() {
        this.setState({ showFlag: !this.state.showFlag })
    }

    RadioButtonChecked(value) {
        if (value.target.value == 'scopeChildYes') {
            let data = this.state.industryScopeData
            data.scopeChildFlag = 'YES'
            this.setState({ scopeChildFlag: 'YES', industryScopeData: data })
        } else if (value.target.value == 'commentChildYes') {
            this.setState({ commentChildFlag: 'YES' })
        } else if (value.target.value == 'commentChildNo') {
            this.setState({ commentChildFlag: 'NO' })
        } else {
            let data = this.state.industryScopeData
            data.scopeChildFlag = 'NO'
            this.setState({ scopeChildFlag: 'NO', industryScopeData: data })
        }
    }

    loadSpendData(key) {

        let currencySymbol = this.props.spendDetails.currentActiveRequest !== undefined ?
            this.props.currencySymbols[this.props.spendDetails.currentActiveRequest.Currency.trim()] : ''
        this.props.setPopUpTabLoadingStatus(true)
        this.props.getIndustrySpend({
            DMCID: this.props.spendDetails.currentActiveRequest.DMC_ID,
            VSID: this.props.spendDetails.currentActiveRequest.VS_ID, EMAIL: this.props.user.user.Email, GICSID: key,
            Country: 'all', Confirmed: 'all',
            Solution: 'No Filter'
        }).then((response) => {
            this.props.setPopUpTabLoadingStatus(false)
            if (response.status !== undefined && response.status === 200) {
                let obj = this.getTotalAggregateData(response.data.Result, currencySymbol)
                let currObj = this.getCurrentAggregateData(response.data.Result, currencySymbol)
                let childObj = this.getChildAggregateData(response.data.Result, currencySymbol)
                this.setState({ data: obj, totalAggregatedData: obj.totalAggregatedData, currentAggregatedData: currObj.currentAggregatedData, childAggregatedData: childObj.childAggregatedData, currencySymbol: currencySymbol, isError: 'No', isLoading: false });
            } else if (response.response && response.response.status == 400) {
                this.setState({ data: [], totalAggregatedData: this.state.totalAggregatedData, currentAggregatedData: this.state.currentAggregatedData, childAggregatedData: this.state.childAggregatedData, currencySymbol: currencySymbol, isLoading: false, isError: 'No', message: 'No Result Found' })
            } else if (response && response.stack && response.stack.includes('Network Error')) {
                this.setState({ responseMsg: 'API service is down. Please contact administrator.', isError: 'Yes', isLoading: false })
            }
        }).catch((error) => {
            this.props.setPopUpTabLoadingStatus(false)
            this.setState({ responseMsg: this.props.content.error, isError: 'Yes', isLoading: false })
        })

    }

    getTotalAggregateData(result, currencySymbol) {
        let totalAggregatedData = { INVOICE_COUNT: 0, INVOICE_SPEND: 0.0, PO_COUNT: 0, PO_SPEND: 0.0, UNIQUE_SUPPLIERS: 0 };
        result.forEach(item => {
            totalAggregatedData.INVOICE_COUNT += item.INVOICE_COUNT
            totalAggregatedData.INVOICE_SPEND += item.INVOICE_SPEND
            totalAggregatedData.PO_COUNT += item.PO_COUNT
            totalAggregatedData.PO_SPEND += item.PO_SPEND
            totalAggregatedData.UNIQUE_SUPPLIERS += item.COUNT_VENDOR_ID

            item['FINAL_GICS_DESC_COMB'] = item['FINAL_GICS_DESC'] + ' (' + item['FINAL_GICS'] + ')'
            item['PARENT_GICS_DESC_COMB'] = item['PARENT_GICS_DESC'] + ' (' + item['PARENT_GICS'] + ')'
            item['currencySymbol'] = currencySymbol
        })

        result.sort((a, b) => { return a.FINAL_GICS - b.FINAL_GICS })
        return { totalAggregatedData: totalAggregatedData, rows: result }
    }

    getCurrentAggregateData(result, currencySymbol) {
        let currentAggregatedData = { INVOICE_COUNT: 0, INVOICE_SPEND: 0.0, PO_COUNT: 0, PO_SPEND: 0.0, UNIQUE_SUPPLIERS: 0 };
        let currentGICS = this.props.spendDetails.industryGICS_Details["SKEY"];
        //FINAL_GICS
        //PARENT_GICS_LEVEL
        //FINAL_GICS_INDUSTRY_LEVEL
        result.forEach(item => {
            if (item.FINAL_GICS == currentGICS) {
                currentAggregatedData.INVOICE_COUNT += item.INVOICE_COUNT
                currentAggregatedData.INVOICE_SPEND += item.INVOICE_SPEND
                currentAggregatedData.PO_COUNT += item.PO_COUNT
                currentAggregatedData.PO_SPEND += item.PO_SPEND
                currentAggregatedData.UNIQUE_SUPPLIERS += item.COUNT_VENDOR_ID

                item['FINAL_GICS_DESC_COMB'] = item['FINAL_GICS_DESC'] + ' (' + item['FINAL_GICS'] + ')'
                item['PARENT_GICS_DESC_COMB'] = item['PARENT_GICS_DESC'] + ' (' + item['PARENT_GICS'] + ')'
                item['currencySymbol'] = currencySymbol
            }

        })

        result.sort((a, b) => { return a.FINAL_GICS - b.FINAL_GICS })
        return { currentAggregatedData: currentAggregatedData, rows: result }
    }

    getChildAggregateData(result, currencySymbol) {
        let childAggregatedData = { INVOICE_COUNT: 0, INVOICE_SPEND: 0.0, PO_COUNT: 0, PO_SPEND: 0.0, UNIQUE_SUPPLIERS: 0 };
        let level = this.props.spendDetails.industryGICS_Details["level"];
        result.forEach(item => {
            if (item.PARENT_GICS_LEVEL == level || item.PARENT_GICS_LEVEL > level) {
                childAggregatedData.INVOICE_COUNT += item.INVOICE_COUNT
                childAggregatedData.INVOICE_SPEND += item.INVOICE_SPEND
                childAggregatedData.PO_COUNT += item.PO_COUNT
                childAggregatedData.PO_SPEND += item.PO_SPEND
                childAggregatedData.UNIQUE_SUPPLIERS += item.COUNT_VENDOR_ID

                item['FINAL_GICS_DESC_COMB'] = item['FINAL_GICS_DESC'] + ' (' + item['FINAL_GICS'] + ')'
                item['PARENT_GICS_DESC_COMB'] = item['PARENT_GICS_DESC'] + ' (' + item['PARENT_GICS'] + ')'
                item['currencySymbol'] = currencySymbol
            }
        })

        result.sort((a, b) => { return a.FINAL_GICS - b.FINAL_GICS })
        return { childAggregatedData: childAggregatedData, rows: result }
    }

    loadData(key) {
        this.props.setPopUpTabLoadingStatus(true)
        this.setState({ isLoading: true, isError: 'No' })
        let industryGICS_Id = key;
        this.props.getIndustryDrillScopeDetails(this.props.spendDetails.currentActiveRequest.DMC_ID, this.props.spendDetails.currentActiveRequest.VS_ID, this.props.user.user.Email, industryGICS_Id)
            .then((response) => {
                this.props.setPopUpTabLoadingStatus(false)
                if (response.status === 200) {
                    this.props.getIndustryDrillScopeDetailsSuccess(response.data.Result)
                    let skey = this.props.spendDetails.industryGICS_Details["SKEY"];
                    const supplierData = response.data.Result[0];
                    let commentData = supplierData.USER_COMMENT;

                    if (commentData != null && commentData.length > 0) {
                        var historicalCommentsLength = commentData.length
                        var totalHistoricalComments = []
                        totalHistoricalComments = commentData.split('\r\n')
                        //Prefix Text for each new comment is ----->  mm/dd/yyyy - email: @@@@ your comment @@@@
                        var prefixTextlength = 15 + this.props.user.user.Email.length + totalHistoricalComments.length
                        var historicalCommentsLengthWithPrefix = historicalCommentsLength + prefixTextlength
                        if (2000 - historicalCommentsLengthWithPrefix > 0) {
                            this.setState({
                                historicalSupplierComments: commentData, remaingHistoricalCommentsLength: 2000 - historicalCommentsLengthWithPrefix
                            })
                        }
                        else {
                            this.setState({
                                historicalSupplierComments: commentData[0]['USER_COMMENT'], remaingHistoricalCommentsLength: 0
                            })
                        }
                    }

                    supplierData.scopeChildFlag = this.state.scopeChildFlag;
                    supplierData.userComment = this.state.userComment;
                    //set headers for popup
                    //storeIndustryDetails: (data) => dispatch(storeIndustryDetails(data))
                    let industryName = this.props.spendDetails.industryGICS_Details["industry"];
                    let industryGICS = this.props.spendDetails.industryGICS_Details["SKEY"];
                    let level = this.props.spendDetails.industryGICS_Details["level"];
                    let header = industryGICS + ': ' + industryName;
                    let subHeader = [];

                    for (let i = 1; i <= level; i++) {
                        let industry = 'L' + i + '_' + 'INDUSTRY_NAME'
                        let industryId = 'L' + i + '_' + 'INDUSTRY_ID'
                        subHeader.push({ name: supplierData[industry], gicsId: supplierData[industryId], level: i })
                    }
                    let industryDetails = { header: header, subHeader: subHeader }
                    this.props.storeIndustryDetails(industryDetails)
                    this.setState({ skey: skey, isLoading: false, isError: 'No', industryScopeData: supplierData, industryScopeDataCurrent: cloneDeep(supplierData) })
                }
                else {
                }
            }).catch((error) => {
                this.props.setPopUpTabLoadingStatus(false)
                this.setState({ responseMsg: this.props.content.error, isError: 'Yes', isLoading: false })
            })
    }

    isDisabled(field, values) {
        return true;
    }

    onBlur(e) {
        this.setState({ isUserCommentTextboxClicked: 'No' })
    }

    onFocus(e) {
        if (this.state.remaingHistoricalCommentsLength < 150) {
            document.getElementById("userComments").maxLength = this.state.remaingHistoricalCommentsLength
            this.setState({
                warningMessage: this.props.supplierComments.historicalCharactersLeft,
                characterCount: this.state.remaingHistoricalCommentsLength - e.currentTarget.value.length, isUserCommentTextboxClicked: 'Yes'
            })
        }
        else {
            this.setState({ warningMessage: this.props.supplierComments.charactersLeft, characterCount: 150 - e.currentTarget.value.length, isUserCommentTextboxClicked: 'Yes' })
        }
    }

    handleChange(value, field, props) {
        //props.setFieldValue(field, value)
        this.props.trackIndustryDrillScopeChanges(true)
        this.setState({ responseMsg: '' })
        const industryScopeData = this.state.industryScopeData
        if (field == 'USER_COMMENT') {
            industryScopeData.userComment = value;
            if (this.state.remaingHistoricalCommentsLength < 150) {
                if (this.state.remaingHistoricalCommentsLength > 0)
                    document.getElementById("userComments").maxLength = this.state.remaingHistoricalCommentsLength
                else
                    document.getElementById("userComments").maxLength = 0
                this.setState({
                    warningMessage: this.props.supplierComments.historicalCharactersLeft,
                    characterCount: this.state.remaingHistoricalCommentsLength - value.length, isUserCommentTextboxClicked: 'Yes'
                })
            }
            else {
                this.setState({ warningMessage: this.props.supplierComments.charactersLeft, characterCount: 150 - value.length, isUserCommentTextboxClicked: 'Yes' })
            }
            this.setState({ userComment: value, industryScopeData: industryScopeData })
        } else {
            industryScopeData[field] = value;
            props.values[field] = value;
            const validationRecords = this.onUpdate(props.values)
            this.setState({
                industryScopeData: industryScopeData, warningRecords: validationRecords.warningRecords,
                errorRecords: validationRecords.errorRecords
            })
        }
    }

    onUpdate(values) {
        var warningRecords = this.state.warningRecords;
        var errorRecords = this.state.errorRecords;
        this.validateForWarning(warningRecords, values);
        this.validateForError(errorRecords, values);
        return { warningRecords: warningRecords, errorRecords: errorRecords }
    }

    validateForWarning(warningRecords, values) {
        const warningMessages = this.props.contentDetailedFile.validationMessage.warningMessage;
        const exclusionReasonWarning = warningMessages.exclusionReasonWarning;
        const buyingInvoicingFieldGlassEligibleWarning = warningMessages.buyingInvoicingFieldGlassEligibleWarning
        const buyingInvoicingSupplyChainEligibleWarning = warningMessages.buyingInvoicingSupplyChainEligibleWarning
        const buyingInvoicingConcurEligibleWarning = warningMessages.buyingInvoicingConcurEligibleWarning
        const fieldGlassSCCEligibleWarning = warningMessages.fieldGlassSCCEligibleWarning

        var exclusionReason = values.EXCLUSION_REASON
        if (getNonEmptyValue(exclusionReason) !== '') {
            this.addWarningErroredRecord(exclusionReasonWarning, warningRecords, true)
        } else {
            this.addWarningErroredRecord(exclusionReasonWarning, warningRecords, false)
        }

        var buyingInvoicing = values.BUYING_INVOICING_ELIGIBLE;
        var fieldGlassEligible = values.SAP_FIELDGLASS_ELIGIBLE
        if (getNonEmptyValue(buyingInvoicing) === 'Yes' && getNonEmptyValue(fieldGlassEligible) === 'Yes') {
            this.addWarningErroredRecord(buyingInvoicingFieldGlassEligibleWarning, warningRecords, true)
        } else {
            this.addWarningErroredRecord(buyingInvoicingFieldGlassEligibleWarning, warningRecords, false)
        }

        var supplyChainEligible = values.SUPP_CHAIN_COLLAB_ELIGIBLE
        if (getNonEmptyValue(buyingInvoicing) === 'Yes' && getNonEmptyValue(supplyChainEligible) === 'Yes') {
            this.addWarningErroredRecord(buyingInvoicingSupplyChainEligibleWarning, warningRecords, true)
        } else {
            this.addWarningErroredRecord(buyingInvoicingSupplyChainEligibleWarning, warningRecords, false)
        }

        var concurEligible = values.SAP_CONCUR_ELIGIBLE
        if (getNonEmptyValue(buyingInvoicing) === 'Yes' && getNonEmptyValue(concurEligible) === 'Yes') {
            this.addWarningErroredRecord(buyingInvoicingConcurEligibleWarning, warningRecords, true)
        } else {
            this.addWarningErroredRecord(buyingInvoicingConcurEligibleWarning, warningRecords, false)
        }

        if (getNonEmptyValue(fieldGlassEligible) === 'Yes' && getNonEmptyValue(supplyChainEligible) === 'Yes') {
            this.addWarningErroredRecord(fieldGlassSCCEligibleWarning, warningRecords, true)
        } else {
            this.addWarningErroredRecord(fieldGlassSCCEligibleWarning, warningRecords, false)
        }
    }

    validateForError(errorRecords, values) {
        const errorMessages = this.props.contentDetailedFile.validationMessage.errorMessage;
        const buyingInvoicingError = errorMessages.buyingInvoicingError;
        const requisitionableTypeError1 = errorMessages.requisitionableTypeError1;
        const requisitionableTypeError2 = errorMessages.requisitionableTypeError2;
        const requisitionableTypeError3 = errorMessages.requisitionableTypeError3;
        const contractInvoicingError = errorMessages.contractInvoicingError;
        const supplyCommerceError = errorMessages.supplyCommerceError;

        var buyingInvoicing = values.BUYING_INVOICING_ELIGIBLE;
        var requisitionable = values.REQUISITIONABLE;
        var contractInvoicing = values.CONTRACT_INVOICING_ELIGIBLE;

        if ((getNonEmptyValue(buyingInvoicing) === 'Yes' && getNonEmptyValue(requisitionable) === 'Yes' && getNonEmptyValue(contractInvoicing) === 'Yes') ||
            (getNonEmptyValue(buyingInvoicing) === 'Yes' && getNonEmptyValue(requisitionable) === 'No' && getNonEmptyValue(contractInvoicing) === 'No')) {
            this.addWarningErroredRecord(buyingInvoicingError, errorRecords, true)
        } else {
            this.addWarningErroredRecord(buyingInvoicingError, errorRecords, false)
        }

        var requisitionableType = values.REQUISITION_TYPE;
        if (getNonEmptyValue(requisitionable) === 'Yes' && getNonEmptyValue(requisitionableType) === 'N/A') {
            this.addWarningErroredRecord(requisitionableTypeError1, errorRecords, true)
        } else {
            this.addWarningErroredRecord(requisitionableTypeError1, errorRecords, false)
        }

        if (getNonEmptyValue(requisitionable) === 'No' && getNonEmptyValue(requisitionableType) !== 'N/A') {
            this.addWarningErroredRecord(requisitionableTypeError2, errorRecords, true)
        } else {
            this.addWarningErroredRecord(requisitionableTypeError2, errorRecords, false)
        }

        if (getNonEmptyValue(requisitionable) === 'Yes' && getNonEmptyValue(buyingInvoicing) === 'No') {
            this.addWarningErroredRecord(requisitionableTypeError3, errorRecords, true)
        } else {
            this.addWarningErroredRecord(requisitionableTypeError3, errorRecords, false)
        }

        if (getNonEmptyValue(contractInvoicing) === 'Yes' && getNonEmptyValue(buyingInvoicing) === 'No') {
            this.addWarningErroredRecord(contractInvoicingError, errorRecords, true)
        } else {
            this.addWarningErroredRecord(contractInvoicingError, errorRecords, false)
        }

        var supplyChain = values.SUPP_CHAIN_COLLAB_ELIGIBLE;
        var commerceAutomation = values.COMMERCE_AUTOMATION_ELIGIBLE;

        if (getNonEmptyValue(supplyChain) === 'Yes' && getNonEmptyValue(commerceAutomation) === 'Yes') {
            this.addWarningErroredRecord(supplyCommerceError, errorRecords, true)
        } else {
            this.addWarningErroredRecord(supplyCommerceError, errorRecords, false)
        }
    }

    addWarningErroredRecord(reason, records, add) {
        var arr = records[reason];
        if (getNonEmptyValue(arr) === '') {
            arr = []
        }

        var eltIdx = arr.indexOf((1));
        if (eltIdx === -1 && add) {
            arr.push(1)
        } else if (eltIdx !== -1 && !add) {
            arr.splice(eltIdx, 1)
        }

        records[reason] = arr;
    }

    onSave(industryScopeDataNew) {
        this.props.industryDrillScopeUpdateLoading(true)
        let industryScopeDataOld = this.state.industryScopeDataCurrent
        this.setState({ isLoading: true, isError: 'No', isSaveInProgress: true, responseMsg: '' })

        const record = {
            VS_ID: this.props.spendDetails.currentActiveRequest.VS_ID,
            DMC_ID: this.props.spendDetails.currentActiveRequest.DMC_ID,
            USER_ID: this.props.user.user.Email,
            CAPIQ_INDUSTRY_ID: industryScopeDataNew["CAPIQ_INDUSTRY_ID"],
            SAP_ARIBA_BUY_PROCESS: industryScopeDataNew["SAP_ARIBA_BUY_PROCESS"],
            SOURCING_ELIGIBLE: industryScopeDataNew["SOURCING_ELIGIBLE"],
            BUYING_INV_ELIGIBLE: industryScopeDataNew["BUYING_INVOICING_ELIGIBLE"],
            REQUISITION_ABLE: industryScopeDataNew["REQUISITIONABLE"],
            REQUISITION_TYPE: industryScopeDataNew["REQUISITION_TYPE"],
            CONTRACT_INV_ELIGIBLE: industryScopeDataNew["CONTRACT_INVOICING_ELIGIBLE"],
            COMMERCE_AUTOMATION_ELIGIBLE: industryScopeDataNew["COMMERCE_AUTOMATION_ELIGIBLE"],
            SUPP_CHAIN_COLLAB_ELIGIBLE: industryScopeDataNew["SUPP_CHAIN_COLLAB_ELIGIBLE"],
            PAYABLES_ELIGIBLE: industryScopeDataNew["PAYABLES_ELIGIBLE"],
            SAP_FIELDGLASS_ELIGIBLE: industryScopeDataNew["SAP_FIELDGLASS_ELIGIBLE"],
            SAP_CONCUR_ELIGIBLE: industryScopeDataNew["SAP_CONCUR_ELIGIBLE"],
            SPOT_BUY_ELIGIBLE: industryScopeDataNew["SPOT_BUY_ELIGIBLE"],
            USER_COMMENT: this.state.userComment,
            SCOPE_CHILD_FLAG: this.state.scopeChildFlag,
            COMMENT_CHILD_FLAG: this.state.commentChildFlag
        };
        this.props.setPopUpTabLoadingStatus(true)
        this.props.industryDrillScopeUpdate(record)
            .then((response) => {
                this.props.setPopUpTabLoadingStatus(false)
                this.props.trackIndustryDrillScopeChanges(false)
                if (response.data.Result.length === 0) {
                    this.props.industryDrillScopeUpdateLoading(false)
                } else {
                    this.props.industryDrillScopeUpdateLoading(false)

                    const supplierData = response.data.Result;

                    this.setState({ industryScopeData: supplierData, industryScopeDataCurrent: cloneDeep(supplierData), responseMsg: 'Record has been updated successfully', isError: 'No', isLoading: false, isSaveInProgress: false, userComment: '', warningRecords: [] }, this.loadData(record.CAPIQ_INDUSTRY_ID))
                }
            }).catch((error) => {
                this.props.setPopUpTabLoadingStatus(false)
                if (error.stack.includes('Network Error')) {
                    this.props.industryDrillScopeUpdateLoading(false)
                    this.setState({ responseMsg: 'API service is down. Please contact administrator.', isError: 'Yes', isSaveInProgress: false, isLoading: false })
                }
                else {
                    this.props.industryDrillScopeUpdateLoading(false)
                    this.setState({ responseMsg: this.props.content.error, isError: 'Yes', isSaveInProgress: false, isLoading: false })
                }
            })
    }

    markFlagINP(supplierDataOld, supplierDataNew) {
        if (supplierDataOld["EXCLUSION_REASON"] !== supplierDataNew["EXCLUSION_REASON"]
            || supplierDataOld["DIRECT_SPEND"] !== supplierDataNew["DIRECT_SPEND"]) {
            return true;
        }

        return false;
    }

    handleScopeSourceChange(data, field, props) {
        this.setState({ scopeSource: data.value })
    }

    hasRecordErrors() {
        const errorRecords = this.state.errorRecords
        var errorRecordSize = 0;
        Object.values(errorRecords).forEach((item) => {
            errorRecordSize += item.length;
        })

        return errorRecordSize > 0
    }

    hasPendingChange() {
        for (const key in this.state.industryScopeData) {
            if (this.state.industryScopeData[key] !== this.state.industryScopeDataCurrent[key]) {
                return true;
            }
        }

        return false;
    }


    getValidationDiv() {
        const warningRecords = this.state.warningRecords;
        const errorRecords = this.state.errorRecords;

        var warningRecordSize = 0;
        var totalWarningRecords = []
        Object.values(warningRecords).forEach((item) => {
            totalWarningRecords = totalWarningRecords.concat(item);
        })
        var warningDiv = Object.keys(warningRecords).map((key, index) => (
            warningRecords[key].length !== 0 ?
                <div className="medium-12 message-sub-row hand-cursor">
                    <div className="grid-x message-sub-row hand-cursor">
                        <span>
                            <img width="10" src="images/Dot.svg" />
                        </span>
                        <div className="medium-11">
                            {key}
                        </div>
                    </div>
                </div> : ''
        ))

        var errorRecordSize = 0;
        var totalErrorRecords = []
        Object.values(errorRecords).forEach((item) => {
            totalErrorRecords = totalErrorRecords.concat(item);
        })

        var errorDiv = Object.keys(errorRecords).map((key, index) => (
            errorRecords[key].length !== 0 ?
                <div className="medium-12 message-sub-row hand-cursor">
                    <div className="grid-x message-sub-row hand-cursor">
                        <span>
                            <img width="10" src="images/Dot.svg" />
                        </span>
                        <div className="medium-11">
                            {key}
                        </div>
                    </div>
                </div> : ''
        ))

        var validationDiv = (
            <div className="validation-messages medium-12">
                <div className="grid-x">
                    {totalWarningRecords.length > 0 ?
                        <div className="grid-x medium-6 message-row">
                            <div className="medium-11 hand-cursor">
                                <span className="review-warning">
                                    <img width="15" src="images/Dot.svg" />{totalWarningRecords.length} {this.props.contentDetailedFile.validationWarningText} </span>{this.props.contentDetailedFile.warningChangeMessage}
                            </div>
                            <div onClick={this.warningToggle} className="float-right plus-icon medium-1">
                                {this.state.warningDiv ? <img width="10" src="images/minus-inactive.svg" /> : <img width="10" src="images/add.svg" />}
                            </div>
                            {this.state.warningDiv ? warningDiv : ''}
                        </div> : ''}
                    {totalErrorRecords.length > 0 ?
                        <div className="grid-x medium-6 message-row">
                            <div className="medium-11 hand-cursor">
                                <span className="review-errors">
                                    <img width="15" src="images/Dot.svg" />{totalErrorRecords.length} {this.props.contentDetailedFile.validationErrorText} </span>{this.props.contentDetailedFile.validationThatText}<span className="font-bold"> {this.props.contentDetailedFile.validationMustText} </span>{this.props.contentDetailedFile.errorChangeMessage}
                            </div>
                            <div onClick={this.errorToggle} className="float-right plus-icon medium-1">
                                {this.state.errorDiv ? <img width="10" src="images/minus-inactive.svg" /> : <img width="10" src="images/add.svg" />}
                            </div>
                            {this.state.errorDiv ? errorDiv : ''}
                        </div> : ''}
                </div>
            </div>)

        return validationDiv;
    }

    warningToggle() {
        this.setState((previousState) => { return { warningDiv: !previousState.warningDiv } })
    }

    errorToggle() {
        this.setState((previousState) => { return { errorDiv: !previousState.errorDiv } })
    }
    //.formulaePlus .formulaeMinus
    render() {
        const validationDiv = this.getValidationDiv();

        return (
            <div className="grid-container font12">
                <div className="tab-description">{this.props.industryDrill.tabHeader}</div>
                <div>
                    {!this.state.showFlag ? <span className="formulaePlus pr" onClick={this.toggleShow}>+</span> : <span className="formulaeMinus pr" onClick={this.toggleShow}>-</span>}
                    <span className="tab-description">Expand for spend and transaction details</span>
                </div>
                {this.state.showFlag ? <div>
                    <div className="medium-12 pt10">
                        <div className="blue-bg">
                            <div className="grid-x grid-padding-x">
                                <div className="float-left">
                                    <div className="title">{this.props.industryDrill.uniqueSuppliersTotal + ":"}</div>
                                    <div className="value">   {formatThousands(this.state.totalAggregatedData.UNIQUE_SUPPLIERS)}</div>
                                </div>
                                <div className="float-left pl20">
                                    <div className="title">{this.props.industryDrill.invoiceSpendTotal + ":"}</div>
                                    <div className="value">  {this.state.currencySymbol}{convertToMillion(this.state.totalAggregatedData.INVOICE_SPEND, true)}</div>
                                </div>
                                <div className="float-left pl20">
                                    <div className="title">{this.props.industryDrill.poSpendTotal + ":"}</div>
                                    <div className="value"> {this.state.currencySymbol}{convertToMillion(this.state.totalAggregatedData.PO_SPEND, true)}</div>
                                </div>
                                <div className="float-left pl20">
                                    <div className="title">{this.props.industryDrill.invoiceCountTotal + ":"} </div>
                                    <div className="value">{formatThousands(this.state.totalAggregatedData.INVOICE_COUNT)}</div>
                                </div>
                                <div className="float-left pl20">
                                    <div className="title">{this.props.industryDrill.poCountTotal + ":"} </div>
                                    <div className="value">{formatThousands(this.state.totalAggregatedData.PO_COUNT)}</div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="medium-12 pt10">
                        <div className="blue-bg">
                            <div className="grid-x grid-padding-x">
                                <div className="float-left">
                                    <div className="title">{this.props.industryDrill.uniqueSuppliersCurrent + ":"}</div>
                                    <div className="value">   {formatThousands(this.state.currentAggregatedData.UNIQUE_SUPPLIERS)}</div>
                                </div>
                                <div className="float-left pl20">
                                    <div className="title">{this.props.industryDrill.invoiceSpendCurrent + ":"}</div>
                                    <div className="value">  {this.state.currencySymbol}{convertToMillion(this.state.currentAggregatedData.INVOICE_SPEND, true)}</div>
                                </div>
                                <div className="float-left pl20">
                                    <div className="title">{this.props.industryDrill.poSpendCurrent + ":"}</div>
                                    <div className="value"> {this.state.currencySymbol}{convertToMillion(this.state.currentAggregatedData.PO_SPEND, true)}</div>
                                </div>
                                <div className="float-left pl20">
                                    <div className="title">{this.props.industryDrill.invoiceCountCurrent + ":"} </div>
                                    <div className="value">{formatThousands(this.state.currentAggregatedData.INVOICE_COUNT)}</div>
                                </div>
                                <div className="float-left pl20">
                                    <div className="title">{this.props.industryDrill.poCountCurrent + ":"} </div>
                                    <div className="value">{formatThousands(this.state.currentAggregatedData.PO_COUNT)}</div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="medium-12 pt10">
                        <div className="blue-bg">
                            <div className="grid-x grid-padding-x">
                                <div className="float-left">
                                    <div className="title">{this.props.industryDrill.uniqueSuppliersChild + ":"}</div>
                                    <div className="value">   {formatThousands(this.state.childAggregatedData.UNIQUE_SUPPLIERS)}</div>
                                </div>
                                <div className="float-left pl20">
                                    <div className="title">{this.props.industryDrill.invoiceSpendChild + ":"}</div>
                                    <div className="value">  {this.state.currencySymbol}{convertToMillion(this.state.childAggregatedData.INVOICE_SPEND, true)}</div>
                                </div>
                                <div className="float-left pl20">
                                    <div className="title">{this.props.industryDrill.poSpendChild + ":"}</div>
                                    <div className="value"> {this.state.currencySymbol}{convertToMillion(this.state.childAggregatedData.PO_SPEND, true)}</div>
                                </div>
                                <div className="float-left pl20">
                                    <div className="title">{this.props.industryDrill.invoiceCountChild + ":"} </div>
                                    <div className="value">{formatThousands(this.state.childAggregatedData.INVOICE_COUNT)}</div>
                                </div>
                                <div className="float-left pl20">
                                    <div className="title">{this.props.industryDrill.poCountChild + ":"} </div>
                                    <div className="value">{formatThousands(this.state.childAggregatedData.PO_COUNT)}</div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                    : null}

                <div className="medium-12 pt10"></div>
                <Formik
                    enableReinitialize={true}
                    initialValues={this.state.industryScopeData}
                    onSubmit={(values, actions) => {
                        this.onSave(values)
                        actions.setSubmitting(false);
                    }}
                    render={props => (
                        <div className="grid-container font12">
                            <form onSubmit={props.handleSubmit}>
                                <div className="grid-x tabs_container">
                                    <div className="medium-12">
                                        <div className="grid-x grid-padding-x">
                                            <div className="medium-12">
                                                {(this.state.isLoading) ?
                                                    <img style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={path + `images/ajax-loader.gif`} alt="" />
                                                    : null
                                                }
                                                {(this.state.isError === 'Yes') ?
                                                    <div style={{ color: 'red', textAlign: 'center' }} className="cell">{this.state.responseMsg}</div>
                                                    : <div style={{ color: 'green', textAlign: 'center' }} className="cell">{this.state.responseMsg}</div>
                                                }
                                            </div>
                                        </div>
                                        <div className="grid-x">
                                            <div className="medium-11">
                                                {validationDiv}
                                            </div>
                                            <div className="medium-1">
                                                <button className={classNames('float-right', 'pr35',
                                                    {
                                                        'hand-cursor': this.state.isSaveInProgress ? false : !(this.hasRecordErrors() || !this.hasPendingChange()),
                                                        'gray-scale': this.state.isSaveInProgress ? true : (this.hasRecordErrors() || !this.hasPendingChange())
                                                    })}
                                                    type="submit" disabled={this.state.isSaveInProgress ? true : this.hasRecordErrors() || !this.hasPendingChange()} title={this.props.ui.save} float >
                                                    <img width="20" src="images/save_active.svg" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="medium-4 pr10">

                                        {
                                            isColumnMapped(this.props.spendDetails.mappedColumn, "SAP_ARIBA_BUY_PROCESS") &&
                                            <div className="grid-x">
                                                <div className="medium-6 font-bold grey_background min-control-height vertical-align-center">{this.props.content.sapAribaBuyProcess}</div>
                                                <div className="medium-6 paddingAll5">
                                                    <SelectE
                                                        className="border-with-DD dropDownContent"
                                                        name='SAP_ARIBA_BUY_PROCESS'
                                                        value={aribaBuyProcessValues.filter(
                                                            option => option.label == props.values.SAP_ARIBA_BUY_PROCESS
                                                        )}
                                                        onChange={(value) => this.handleChange(value.value, 'SAP_ARIBA_BUY_PROCESS', props)}
                                                        onBlur={(value) => props.setFieldTouched('SAP_ARIBA_BUY_PROCESS', true)}
                                                        options={aribaBuyProcessValues}
                                                        // isDisabled={this.isDisabled('SAP_ARIBA_BUY_PROCESS', props.values)}
                                                        isSearchable={false}
                                                        noOptionsMessage={() => null}
                                                    />
                                                </div>
                                            </div>
                                        }

                                        {
                                            isColumnMapped(this.props.spendDetails.mappedColumn, "SOURCING_ELIGIBLE") &&
                                            <div className="grid-x">
                                                <div className="medium-6 font-bold grey_background min-control-height vertical-align-center">{this.props.content.sourcingEligible}</div>
                                                <div className="medium-6 paddingAll5">
                                                    <SelectE
                                                        className="border-with-DD dropDownContent"
                                                        name='SOURCING_ELIGIBLE'
                                                        value={dropdownValues.filter(
                                                            option => option.label == props.values.SOURCING_ELIGIBLE
                                                        )}
                                                        onChange={(value) => this.handleChange(value.value, 'SOURCING_ELIGIBLE', props)}
                                                        onBlur={(value) => props.setFieldTouched('SOURCING_ELIGIBLE', true)}
                                                        options={dropdownValues}
                                                        // isDisabled={this.isDisabled('SOURCING_ELIGIBLE', props.values)}
                                                        isSearchable={false}
                                                        noOptionsMessage={() => null}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        {
                                            isColumnMapped(this.props.spendDetails.mappedColumn, "BUYING_INVOICING_ELIGIBLE") &&
                                            <div className="grid-x">
                                                <div className="medium-6 font-bold grey_background min-control-height vertical-align-center">{this.props.content.buyingAndInvoicingEligible}</div>
                                                <div className="medium-6 paddingAll5">
                                                    <SelectE
                                                        className="border-with-DD dropDownContent"
                                                        name='BUYING_INVOICING_ELIGIBLE'
                                                        value={dropdownValues.filter(
                                                            option => option.label == props.values.BUYING_INVOICING_ELIGIBLE
                                                        )}
                                                        onChange={(value) => this.handleChange(value.value, 'BUYING_INVOICING_ELIGIBLE', props)}
                                                        onBlur={(value) => props.setFieldTouched('BUYING_INVOICING_ELIGIBLE', true)}
                                                        options={dropdownValues}
                                                        // isDisabled={this.isDisabled('BUYING_INVOICING_ELIGIBLE', props.values)}
                                                        isSearchable={false}
                                                        noOptionsMessage={() => null}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        {
                                            isColumnMapped(this.props.spendDetails.mappedColumn, "REQUISITIONABLE") &&
                                            <div className="grid-x">
                                                <div className="medium-6 font-bold grey_background min-control-height vertical-align-center">{this.props.content.requisitionable}</div>
                                                <div className="medium-6 paddingAll5">
                                                    <SelectE
                                                        className="border-with-DD dropDownContent"
                                                        name='REQUISITIONABLE'
                                                        value={dropdownValues.filter(
                                                            option => option.label == props.values.REQUISITIONABLE
                                                        )}
                                                        onChange={(value) => this.handleChange(value.value, 'REQUISITIONABLE', props)}
                                                        onBlur={(value) => props.setFieldTouched('REQUISITIONABLE', true)}
                                                        options={dropdownValues}
                                                        // isDisabled={this.isDisabled('REQUISITIONABLE', props.values)}
                                                        isSearchable={false}
                                                        noOptionsMessage={() => null}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        {
                                            isColumnMapped(this.props.spendDetails.mappedColumn, "REQUISITION_TYPE") &&
                                            <div className="grid-x">
                                                <div className="medium-6 font-bold grey_background min-control-height vertical-align-center">{this.props.content.requisitionType}</div>
                                                <div className="medium-6 paddingAll5">
                                                    <SelectE
                                                        className="border-with-DD dropDownContent"
                                                        name='REQUISITION_TYPE'
                                                        value={requisitionValues.filter(
                                                            option => option.label == props.values.REQUISITION_TYPE
                                                        )}
                                                        onChange={(value) => this.handleChange(value.value, 'REQUISITION_TYPE', props)}
                                                        onBlur={(value) => props.setFieldTouched('REQUISITION_TYPE', true)}
                                                        options={requisitionValues}
                                                        // isDisabled={this.isDisabled('REQUISITION_TYPE', props.values)}
                                                        isSearchable={false}
                                                        noOptionsMessage={() => null}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        {
                                            isColumnMapped(this.props.spendDetails.mappedColumn, "CONTRACT_INVOICING_ELIGIBLE") &&
                                            <div className="grid-x">
                                                <div className="medium-6 font-bold grey_background min-control-height vertical-align-center">{this.props.content.contractInvoicingEligible}</div>
                                                <div className="medium-6 paddingAll5">
                                                    <SelectE
                                                        className="border-with-DD dropDownContent"
                                                        name='CONTRACT_INVOICING_ELIGIBLE'
                                                        value={dropdownValues.filter(
                                                            option => option.label == props.values.CONTRACT_INVOICING_ELIGIBLE
                                                        )}
                                                        onChange={(value) => this.handleChange(value.value, 'CONTRACT_INVOICING_ELIGIBLE', props)}
                                                        onBlur={(value) => props.setFieldTouched('CONTRACT_INVOICING_ELIGIBLE', true)}
                                                        options={dropdownValues}
                                                        // isDisabled={this.isDisabled('CONTRACT_INVOICING_ELIGIBLE', props.values)}
                                                        isSearchable={false}
                                                        noOptionsMessage={() => null}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        {
                                            isColumnMapped(this.props.spendDetails.mappedColumn, "COMMERCE_AUTOMATION_ELIGIBLE") &&
                                            <div className="grid-x">
                                                <div className="medium-6 font-bold grey_background min-control-height vertical-align-center">{this.props.content.commerceAutomationEligible}</div>
                                                <div className="medium-6 paddingAll5">
                                                    <SelectE
                                                        className="border-with-DD dropDownContent"
                                                        name='COMMERCE_AUTOMATION_ELIGIBLE'
                                                        value={dropdownValues.filter(
                                                            option => option.label == props.values.COMMERCE_AUTOMATION_ELIGIBLE
                                                        )}
                                                        onChange={(value) => this.handleChange(value.value, 'COMMERCE_AUTOMATION_ELIGIBLE', props)}
                                                        onBlur={(value) => props.setFieldTouched('COMMERCE_AUTOMATION_ELIGIBLE', true)}
                                                        options={dropdownValues}
                                                        // isDisabled={this.isDisabled('COMMERCE_AUTOMATION_ELIGIBLE', props.values)}
                                                        isSearchable={false}
                                                        noOptionsMessage={() => null}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        {
                                            isColumnMapped(this.props.spendDetails.mappedColumn, "SUPP_CHAIN_COLLAB_ELIGIBLE") &&
                                            <div className="grid-x">
                                                <div className="medium-6 font-bold grey_background min-control-height vertical-align-center">{this.props.content.supplyChainCollabEligible}</div>
                                                <div className="medium-6 paddingAll5">
                                                    <SelectE
                                                        className="border-with-DD dropDownContent"
                                                        name='SUPP_CHAIN_COLLAB_ELIGIBLE'
                                                        value={dropdownValues.filter(
                                                            option => option.label == props.values.SUPP_CHAIN_COLLAB_ELIGIBLE
                                                        )}
                                                        onChange={(value) => this.handleChange(value.value, 'SUPP_CHAIN_COLLAB_ELIGIBLE', props)}
                                                        onBlur={(value) => props.setFieldTouched('SUPP_CHAIN_COLLAB_ELIGIBLE', true)}
                                                        options={dropdownValues}
                                                        // isDisabled={this.isDisabled('SUPP_CHAIN_COLLAB_ELIGIBLE', props.values)}
                                                        isSearchable={false}
                                                        noOptionsMessage={() => null}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        {
                                            isColumnMapped(this.props.spendDetails.mappedColumn, "PAYABLES_ELIGIBLE") &&
                                            <div className="grid-x">
                                                <div className="medium-6 font-bold grey_background min-control-height vertical-align-center">{this.props.content.payablesEligible}</div>
                                                <div className="medium-6 paddingAll5">
                                                    <SelectE
                                                        className="border-with-DD dropDownContent"
                                                        name='PAYABLES_ELIGIBLE'
                                                        value={dropdownValues.filter(
                                                            option => option.label == props.values.PAYABLES_ELIGIBLE
                                                        )}
                                                        onChange={(value) => this.handleChange(value.value, 'PAYABLES_ELIGIBLE', props)}
                                                        onBlur={(value) => props.setFieldTouched('PAYABLES_ELIGIBLE', true)}
                                                        options={dropdownValues}
                                                        // isDisabled={this.isDisabled('PAYABLES_ELIGIBLE', props.values)}
                                                        isSearchable={false}
                                                        noOptionsMessage={() => null}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        {
                                            isColumnMapped(this.props.spendDetails.mappedColumn, "SAP_FIELDGLASS_ELIGIBLE") &&
                                            <div className="grid-x">
                                                <div className="medium-6 font-bold grey_background min-control-height vertical-align-center">{this.props.content.sapFieldglassEligible}</div>
                                                <div className="medium-6 paddingAll5">
                                                    <SelectE
                                                        className="border-with-DD dropDownContent"
                                                        name='SAP_FIELDGLASS_ELIGIBLE'
                                                        value={dropdownValues.filter(
                                                            option => option.label == props.values.SAP_FIELDGLASS_ELIGIBLE
                                                        )}
                                                        onChange={(value) => this.handleChange(value.value, 'SAP_FIELDGLASS_ELIGIBLE', props)}
                                                        onBlur={(value) => props.setFieldTouched('SAP_FIELDGLASS_ELIGIBLE', true)}
                                                        options={dropdownValues}
                                                        // isDisabled={this.isDisabled('SAP_FIELDGLASS_ELIGIBLE', props.values)}
                                                        isSearchable={false}
                                                        noOptionsMessage={() => null}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        {
                                            isColumnMapped(this.props.spendDetails.mappedColumn, "SAP_CONCUR_ELIGIBLE") &&
                                            <div className="grid-x">
                                                <div className="medium-6 font-bold grey_background min-control-height min-control-height vertical-align-center">{this.props.content.sapConcurEligible}</div>
                                                <div className="medium-6 paddingAll5">
                                                    <SelectE
                                                        className="border-with-DD dropDownContent"
                                                        name='SAP_CONCUR_ELIGIBLE'
                                                        value={dropdownValues.filter(
                                                            option => option.label == props.values.SAP_CONCUR_ELIGIBLE
                                                        )}
                                                        onChange={(value) => this.handleChange(value.value, 'SAP_CONCUR_ELIGIBLE', props)}
                                                        onBlur={(value) => props.setFieldTouched('SAP_CONCUR_ELIGIBLE', true)}
                                                        options={dropdownValues}
                                                        // isDisabled={this.isDisabled('SAP_CONCUR_ELIGIBLE', props.values)}
                                                        isSearchable={false}
                                                        noOptionsMessage={() => null}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        {
                                            isColumnMapped(this.props.spendDetails.mappedColumn, "SPOT_BUY_ELIGIBLE") &&
                                            <div className="grid-x">
                                                <div className="medium-6 font-bold grey_background min-control-height min-control-height vertical-align-center">{this.props.content.sapSpotBuyEligible}</div>
                                                <div className="medium-6 paddingAll5">
                                                    <SelectE
                                                        className="border-with-DD dropDownContent"
                                                        name='SPOT_BUY_ELIGIBLE'
                                                        value={dropdownValues.filter(
                                                            option => option.label == props.values.SPOT_BUY_ELIGIBLE
                                                        )}
                                                        onChange={(value) => this.handleChange(value.value, 'SPOT_BUY_ELIGIBLE', props)}
                                                        onBlur={(value) => props.setFieldTouched('SPOT_BUY_ELIGIBLE', true)}
                                                        options={dropdownValues}
                                                        // isDisabled={this.isDisabled('SPOT_BUY_ELIGIBLE', props.values)}
                                                        isSearchable={false}
                                                        noOptionsMessage={() => null}
                                                    />
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <div className="medium-1 pr10"></div>
                                    <div className="medium-5 pr10">
                                        <div className="grid-x">
                                            <div className="medium-6 font-bold grey_background min-control-height vertical-align-center">{this.props.industryDrill.scopeText}</div>
                                            {/* <div className="medium-6 paddingAll5"> */}
                                            <div className="medium-2 plt"><span><RadioButtonE className='radioButton'
                                                checked={this.state.scopeChildFlag == 'YES'}
                                                id={'scopeChildYes'}
                                                value={'scopeChildYes'}
                                                onClick={value => this.RadioButtonChecked(value)}
                                                color="default"
                                            /></span></div>
                                            <div className="medium-1 tl">{this.props.industryDrill.yes}</div>
                                            <div className="medium-2 plt"><span><RadioButtonE className='radioButton'
                                                checked={this.state.scopeChildFlag == 'NO'}
                                                id={'scopeChildNo'}
                                                value={'scopeChildNo'}
                                                onClick={value => this.RadioButtonChecked(value)}
                                                color="default"
                                            /></span></div>
                                            <div className="medium-1 tl">{this.props.industryDrill.no}</div>
                                            {/* </div> */}
                                            <div className="medium-6 font-bold grey_background min-control-height vertical-align-center">{this.props.industryDrill.commentText}</div>
                                            <div className="medium-2 plt"><span><RadioButtonE className='radioButton'
                                                checked={this.state.commentChildFlag == 'YES'}
                                                id={'commentChildYes'}
                                                value={'commentChildYes'}
                                                onClick={value => this.RadioButtonChecked(value)}
                                                color="default"
                                            /></span></div>
                                            <div className="medium-1 tl">{this.props.industryDrill.yes}</div>
                                            <div className="medium-2 plt"><span><RadioButtonE className='radioButton'
                                                checked={this.state.commentChildFlag == 'NO'}
                                                id={'commentChildNo'}
                                                value={'commentChildNo'}
                                                onClick={value => this.RadioButtonChecked(value)}
                                                color="default"
                                            /></span></div>
                                            <div className="medium-1 tl">{this.props.industryDrill.no}</div>
                                            <div className="medium-12 grey_background ">
                                                <div className="font-bold pl5">{this.props.industryDrill.note}</div>
                                                <div className="min-control-height vertical-align-center">{this.props.industryDrill.noteText}</div>
                                            </div>
                                        </div>
                                        <div className="rowMargin">
                                            <div className="fieldTitleGeneralContent"> {this.props.industryDrill.userComment}</div>
                                            {this.state.isUserCommentTextboxClicked === 'Yes' ? <div style={{ fontSize: '0.875rem' }} className="float-right colorMessage"> {this.state.warningMessage + this.state.characterCount}</div>
                                                : null}
                                            <div className="control-wrapper fieldValue">
                                                <TextAreaE
                                                    rows="5"
                                                    cols="30"
                                                    type="text" style={{ width: '100%' }}
                                                    onChange={(value) => this.handleChange(value.target.value, 'USER_COMMENT', props)}
                                                    onFocus={e => this.onFocus(e)}
                                                    onBlur={(e) => this.onBlur(e)}
                                                    id='userComments'
                                                    placeholder={this.props.supplierComments.waterText}
                                                    maxLength="150"
                                                    value={this.state.userComment}
                                                />
                                            </div>
                                        </div>
                                        <div className="rowMargin">
                                            <div className="fieldTitleGeneralContent"> {this.props.industryDrill.historicalComments}</div>
                                            <div className="control-wrapper fieldValue">
                                                <textarea
                                                    rows="8"
                                                    cols="30"
                                                    type="text" style={{ width: '100%' }}
                                                    disabled={true}
                                                    value={props.values.USER_COMMENT}
                                                    //className = "label"
                                                    //model='yourFile.commentsRequest'
                                                    // onChange={e => this.onChange(e)}
                                                    id='yourFile.commentsRequest'
                                                //label={this.props.content.commentsRequest}
                                                // value={currentActiveRequest !== undefined && currentActiveRequest.UPDATED_COMMENTS !== undefined ? currentActiveRequest.UPDATED_COMMENTS : ''}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div className="fieldTitleGeneralContent rowMargin"> {"Scope Notes"}
                                <img className="pl5" onClick={this.toggleScopeNotes} src={this.state.scopeNotes ? "images/show_active.svg" : "images/show_inactive.svg"} width="30"></img>
                            </div>
                            {this.state.scopeNotes ?
                                <div className="rowMargin">

                                    <div className="control-wrapper fieldValue">
                                        <textarea
                                            rows="5"
                                            cols="30"
                                            type="text" style={{ width: '100%' }}
                                            value={props.values.SCOPE_NOTES}
                                            disabled={true}
                                            //className = "label"
                                            //model='yourFile.commentsRequest'
                                            // onChange={e => this.onChange(e)}
                                            id='yourFile.commentsRequest'
                                        //label={this.props.content.commentsRequest}
                                        // value={currentActiveRequest !== undefined && currentActiveRequest.UPDATED_COMMENTS !== undefined ? currentActiveRequest.UPDATED_COMMENTS : ''}
                                        />
                                    </div>
                                </div> : null}
                        </div >
                    )} />
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        ui: state.content.ui,
        content: state.content.spendAnalysis.supplierDrilldown.scopeandMatch,
        contentDetailedFile: state.content.spendAnalysis.detailedFile,
        spendDetails: state.spendDetails,
        user: state.default,
        supplierComments: state.content.spendAnalysis.supplierDrilldown.supplierComments,
        industryDrill: state.content.spendAnalysis.industryDrilldown.industryScopeAndMatch,
        currencySymbols: state.content.CurrencySymbols
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getIndustryDrillScopeDetailsSuccess: message => dispatch(getIndustryDrillScopeDetailsSuccess(message)),
        getIndustryDrillScopeDetailsFailure: error => dispatch(getIndustryDrillScopeDetailsFailure(error)),
        getIndustryDrillScopeDetailsLoading: bool => dispatch(getIndustryDrillScopeDetailsLoading(bool)),
        getIndustryDrillScopeDetails: (DMCId, VSId, userEmail, Id) => dispatch(getIndustryDrillScopeDetails(DMCId, VSId, userEmail, Id)),
        industryDrillScopeUpdateLoading: bool => dispatch(industryDrillScopeUpdateLoading(bool)),
        industryDrillScopeUpdate: (record) => dispatch(industryDrillScopeUpdate(record)),
        trackIndustryDrillScopeChanges: message => dispatch(trackIndustryDrillScopeChanges(message)),
        trackSupplierDrillDownSave: message => dispatch(trackSupplierDrillDownSave(message)),
        storeIndustryDetails: (data) => dispatch(storeIndustryDetails(data)),
        getIndustrySpend: (params) => dispatch(getIndustrySpend(params)),
        setPopUpTabLoadingStatus: bool => dispatch(setPopUpTabLoadingStatus(bool)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(IndustriesScopeAndMatch); 