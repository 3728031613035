import React from 'react';
import * as d3 from 'd3';
import * as topojson from "topojson-client";
import { convertToMillion, formatThousands, convertToThousands } from 'utils/Utils'
import { withTheme } from '@material-ui/core';
import { color } from 'd3-color';

class GanntChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            taskArray: props.taskArray
        }

        this.drawChart = this.drawChart.bind(this);
    }

    componentDidMount() {
        this.drawChart(this.props);
    }

    componentWillReceiveProps(nextProps) {
        let height = 450;
        if (nextProps.taskArray.length > 0) {
            //Perform some operation
            if (nextProps.taskLength > 0) {
                height = 35 * nextProps.taskLength;
            }
            console.log('height: ', height)
            this.setState({ taskArray: nextProps.taskArray, height: height + 100 })
            this.drawChart(nextProps, height > 450 ? height : 450);
        }
    }

    //tooltipGantt code from stackedBarChart
    componentWillUnmount() {
        d3.selectAll('.tooltipGantt').style('display', 'none');
    }


    drawChart = (props, height) => {
        d3.select("#chartId").selectAll("svg").remove();
        d3.selectAll('.tooltipGantt').style('display', 'none');//tooltipGantt code from stackedBarChart
        var w = 1240;
        var h = height;


        var svg = d3.selectAll("#chartId")
            //.selectAll("svg")
            .append("svg")
            .attr("width", "100%")
            // .attr("height", h)
            // Responsive SVG needs these 2 attributes and no width and height attr.
            .attr("preserveAspectRatio", "xMinYMin meet")
            .attr("viewBox", [0, 0, w, h])
            .style("overflow", "visible")
            .attr("class", "svg");

        var taskArray = props.taskArray;

        var dateFormat = d3.timeParse("%Y-%m-%d");

        var timeScale = d3.scaleTime()
            .domain([d3.min(taskArray, function (d) { return props.effort == 'Conservative' ? dateFormat(d.startTime2) : dateFormat(d.startTime1) }),
            d3.max(taskArray, function (d) { return props.effort == 'Conservative' ? dateFormat(d.endTime2) : dateFormat(d.endTime1) })])
            .range([0, w - 150]);

        var categories = new Array();
        var taskCategories = new Array();
        var categories_label = new Array();

        for (var i = 0; i < taskArray.length; i++) {
            categories.push(taskArray[i].type);
            taskCategories.push(taskArray[i].task);
            categories_label.push(taskArray[i].label);
        }

        var catsUnfiltered = categories; //for vert labels

        categories = checkUnique(categories);
        taskCategories = checkUnique(taskCategories);
        //taskCategories.sort();

        makeGant(taskArray, w, h);

        // var title = svg.append("text")
        //     .text("Gantt Chart Process")
        //     // .attr("x", w / 2)
        //     .attr("x", 5)
        //     .attr("y", 25)
        //     .attr("text-anchor", "left")
        //     .attr("font-size", 14)
        //     .attr("fill", "#009FFC");
        //tooltipGantt code from stackedBarChart
        var tooltipGantt = d3.select("body").append("div").attr("class", "tooltipGantt").style('display', 'none');


        function makeGant(tasks, pageWidth, pageHeight) {

            var barHeight = 20;
            var gap = barHeight + 4;
            var topPadding = 75;
            var sidePadding = 75;

            var colorScale1 = d3.scaleOrdinal()
                .domain(props.enablementMethodsInOrder)
                .range(["#808080", "#0FAAFF", "#0076CB", "#0F46A7", "#00195A", "#F0AB00", "#E35500"]);
            var colorScale2 = d3.scaleOrdinal()
                .domain(taskCategories)
                .range(["#808080", "#0FAAFF", "#E35500"]);
            var colorScaleTint1 = d3.scaleOrdinal()
                .domain(props.enablementMethodsInOrder)
                .range(["#808080", "#0e9ae6", "#006ab7", "#0e4096", "#001751", "#c08a00", "#b64200"]);
            var colorScaleTint2 = d3.scaleOrdinal()
                .domain(taskCategories)
                .range(["#808080", "#0e9ae6", "#b64200"]);
            // .range(["#808080", "#008FD3", "#F0AB00", "#4FB81C", "#E35500", "#970A82"]);
            // .interpolate(d3.interpolateHcl);

            makeGrid(sidePadding, topPadding, pageWidth, pageHeight);
            drawRects(tasks, gap, topPadding, sidePadding, barHeight, colorScale1, colorScale2, colorScaleTint1, colorScaleTint2, pageWidth, pageHeight);
            vertLabels(gap, topPadding, sidePadding, barHeight, colorScale1);
        }


        function drawRects(theArray, theGap, theTopPad, theSidePad, theBarHeight, color1, color2, tint1, tint2, w, h) {
            let x_value = 0; let w_value1 = 0; let w_value2 = 0;
            if (theArray.length > 0) {
                let data = theArray[0];
                x_value = props.effort == 'Conservative' ? dateFormat(data.startTime2) : dateFormat(data.startTime1);
                w_value1 = props.effort == 'Conservative' ? dateFormat(data.endTime2) : dateFormat(data.endTime1);
                w_value2 = props.effort == 'Conservative' ? dateFormat(data.startTime2) : dateFormat(data.startTime1);
            }


            var line = svg.append("line")
                .data(theArray)
                .attr("x1", function (d) {
                    return timeScale(props.effort == 'Conservative' ? dateFormat(props.firstWaveEnd2) : dateFormat(props.firstWaveEnd1)) + theSidePad + 2;
                })
                .attr("y1", 50)
                .attr("x2", function (d) {
                    return timeScale(props.effort == 'Conservative' ? dateFormat(props.firstWaveEnd2) : dateFormat(props.firstWaveEnd1)) + theSidePad + 2;
                })
                .attr("y2", h - 50)
                .style("stroke-width", 2)
                .style("stroke", "red")
                .style("fill", "none");

            var bigVerticalRects = svg.append("g")
                .selectAll("rect")
                .data(theArray)
                .enter()
                .append("rect")
                // .attr("x", 75)
                // .attr("y", function (d, i) {
                //     return i * theGap + theTopPad - 2;
                // })
                // .attr("width", function (d) {
                //     return 134;
                // })
                .attr("x", function (d) {
                    return timeScale(x_value) + theSidePad;
                })
                .attr("y", function (d, i) {
                    return i * theGap + theTopPad;
                })
                .attr("width", function (d) {
                    return (timeScale(w_value1) - timeScale(w_value2));
                })
                .attr("height", theGap)
                .attr("stroke", "none")
                .attr("fill", "#878686");
            // .attr("opacity", 0.1);

            // bigVerticalRects.append("svg:title")
            //     .text("Ramp up period");

            var bigRects = svg.append("g")
                .selectAll("rect")
                .data(theArray)
                .enter()
                .append("rect")
                .attr("x", 0)
                .attr("y", function (d, i) {
                    return i * theGap + theTopPad - 2;
                })
                .attr("width", function (d) {
                    return w - theSidePad / 2;
                })
                .attr("height", theGap)
                .attr("stroke", "none")
                .attr("fill", function (d) {
                    for (var i = 0; i < categories.length; i++) {
                        if (d.type == categories[i]) {
                            // return d3.rgb(theColorScale(i));
                            if (i % 2 == 0) {
                                return d3.rgb('white');
                            }
                            return d3.rgb('lightGrey');
                        }
                    }
                })
                .attr("opacity", 0.2);


            var rectangles = svg.append('g')
                .selectAll("rect")
                .data(theArray)
                .enter();


            var innerRects = rectangles.append("rect")
                .attr("rx", 3)
                .attr("ry", 3)
                .attr("x", function (d) {
                    return timeScale(props.effort == 'Conservative' ? dateFormat(d.startTime2) : dateFormat(d.startTime1)) + theSidePad;
                })
                .attr("y", function (d, i) {
                    return i * theGap + theTopPad;
                })
                .attr("width", function (d) {
                    return (timeScale(props.effort == 'Conservative' ? dateFormat(d.endTime2) : dateFormat(d.endTime1)) - timeScale(props.effort == 'Conservative' ? dateFormat(d.startTime2) : dateFormat(d.startTime1)));
                })
                .attr("height", theBarHeight)
                .attr("stroke", "none")
                .style("opacity", 1)
                .attr("fill", function (d) {
                    for (var i = 0; i < taskCategories.length; i++) {
                        if (d.task == taskCategories[i] && d.task == 'Ramp-Up') {
                            return 'none';
                        } else if (d.task == taskCategories[i]) {
                            return props.isDefault ? color2(taskCategories[i]) : color1(taskCategories[i]);
                        }
                    }
                    // var i = categories.indexOf(d.type);
                    // return color(i);
                })


            var rectText = rectangles.append("text")
                // .text(function (d) {
                //     return d.showStar ? '*' : '';
                // })
                .attr("x", function (d) {
                    return (timeScale(props.effort == 'Conservative' ? dateFormat(d.endTime2) : dateFormat(d.endTime1)) - timeScale(props.effort == 'Conservative' ? dateFormat(d.startTime2) : dateFormat(d.startTime1))) / 2 + timeScale(props.effort == 'Conservative' ? dateFormat(d.startTime2) : dateFormat(d.startTime1)) + theSidePad;
                })
                .attr("y", function (d, i) {
                    return i * theGap + theTopPad;
                })

                //start at end
                // .attr("x", function (d) {
                //     return (timeScale(props.effort == 'Conservative' ? dateFormat(d.endTime1) : dateFormat(d.endTime2)) - timeScale(props.effort == 'Conservative' ? dateFormat(d.startTime1) : dateFormat(d.startTime2)))  + timeScale(props.effort == 'Conservative' ? dateFormat(d.startTime1) : dateFormat(d.startTime2)) + theSidePad + 5;
                // })
                // .attr("y", function (d, i) {
                //     return i * theGap + 14 + theTopPad;
                // })
                .attr("font-size", 16)
                .attr("font-weight", 600)
                .attr("text-anchor", "middle")
                .attr("text-height", theBarHeight)
                .attr("fill", "red");


            // rectText.on('mouseover', function (datum,index,elements) {
            //     var tag = "";

            //     if (d3.select(this).data()[0] != undefined && props.effort == 'Conservative') {
            //         tag = "Task: " + d3.select(this).data()[0].task + "<br/>" +
            //             "Type: " + d3.select(this).data()[0].type + "<br/>" +
            //             "Starts: " + d3.select(this).data()[0].startTime1 + "<br/>" +
            //             "Ends: " + d3.select(this).data()[0].endTime1 + "<br/>";
            //     } else if (d3.select(this).data()[0] != undefined) {
            //         tag = "Task: " + d3.select(this).data()[0].task + "<br/>" +
            //             "Type: " + d3.select(this).data()[0].type + "<br/>" +
            //             "Starts: " + d3.select(this).data()[0].startTime2 + "<br/>" +
            //             "Ends: " + d3.select(this).data()[0].endTime2 + "<br/>";
            //     }
            //     var output = document.getElementById("tag");

            //     var x = this.x.animVal.getItem(this) + 25 + "px";
            //     var y = d3.mouse(elements[index])[0] + 50 + "px";
            //     console.log("top", d3.mouse(elements[index])[0])
            //     // var y = this.y.animVal.getItem(this) + 200 + "px";
            //     // var y = d3.mouse(this)[1] + "px";

            //     output.innerHTML = tag;
            //     output.style.top = y;
            //     output.style.left = x;
            //     output.style.display = "block";
            // }).on('mouseout', function () {
            //     var output = document.getElementById("tag");
            //     output.style.display = "none";
            // });

            innerRects.on('dblclick', function (e) {
                props.onDoubleClick(d3.select(this).data()[0])
            });

            //tooltipGantt code form stackedBarchart
            innerRects.on("mousemove", function (d) {
                if (d3.select(this).data()[0].type !== 'No') {
                    d3.select(this).style("fill", '#4acfee');
                    tooltipGantt
                        .style("left", d3.event.pageX - 50 + "px")
                        .style("top", d3.event.pageY - 70 + "px")
                        .style("display", "inline-block")
                        .html(
                            props.enablementOn ? "Wave: " + d3.select(this).data()[0].type + "<br>" + "Enablement Recommendation: " + d3.select(this).data()[0].task + "<br>" + "Suppliers: " + d3.select(this).data()[0].supplierCount_On + "<br>" + "Unique ANIDs: " + d3.select(this).data()[0].anIdCount_On + "<br>" + "PO spend: " + props.currency + convertToMillion(d3.select(this).data()[0].poSpend_On) + "<br>" + "Invoice spend: " + props.currency + convertToMillion(d3.select(this).data()[0].invSpend_On) + "<br>" + "Documents: " + formatThousands(d3.select(this).data()[0].docCount_On) + "<br>"
                                : "Wave: " + d3.select(this).data()[0].type + "<br>" + "Enablement Recommendation: " + d3.select(this).data()[0].task + "<br>" + "Suppliers: " + d3.select(this).data()[0].supplierCount_Off + "<br>" + "Unique ANIDs: " + d3.select(this).data()[0].anIdCount_Off + "<br>" + "PO spend: " + props.currency + convertToMillion(d3.select(this).data()[0].poSpend_Off) + "<br>" + "Invoice spend: " + props.currency + convertToMillion(d3.select(this).data()[0].invSpend_Off) + "<br>" + "Documents: " + formatThousands(d3.select(this).data()[0].docCount_Off) + "<br>"
                        );
                }
            })
                .on("mouseout", function (d) {
                    d3.select(this).style("fill", props.isDefault ? color2(d3.select(this).data()[0].task) : color1(d3.select(this).data()[0].task));
                    tooltipGantt
                        .style("display", "none");
                });

            // .on("mouseover", function (d) {
            //     d3.select(this).style("opacity", "0.5");
            // })
            // .on("mouseout", function (d) {
            //     d3.select(this).style("opacity", "1");
            // })

            // innerRects.on('mouseover', function (datum,index,elements) {
            //     //console.log(this);
            //     var tag = "";

            //     if (d3.select(this).data()[0] != undefined && props.effort == 'Conservative') {
            //         tag = "Task: " + d3.select(this).data()[0].task + "<br/>" +
            //             "Type: " + d3.select(this).data()[0].type + "<br/>" +
            //             "Starts: " + d3.select(this).data()[0].startTime1 + "<br/>" +
            //             "Ends: " + d3.select(this).data()[0].endTime1 + "<br/>";
            //     } else if (d3.select(this).data()[0] != undefined) {
            //         tag = "Task: " + d3.select(this).data()[0].task + "<br/>" +
            //             "Type: " + d3.select(this).data()[0].type + "<br/>" +
            //             "Starts: " + d3.select(this).data()[0].startTime2 + "<br/>" +
            //             "Ends: " + d3.select(this).data()[0].endTime2 + "<br/>";
            //     }
            //     var output = document.getElementById("tag");

            //     var x = (this.x.animVal.value + this.width.animVal.value / 2) + "px";
            //     var y = this.y.animVal.value + 25 + "px";
            //     //var y = d3.mouse(this)[1] + "px";
            //     // var y = d3.mouse(elements[index])[0] - 85 + "px";

            //     output.innerHTML = tag;
            //     output.style.top = y;
            //     output.style.left = x;
            //     output.style.display = "block";
            // }).on('mouseout', function () {
            //     var output = document.getElementById("tag");
            //     output.style.display = "none";

            // });
        }


        function makeGrid(theSidePad, theTopPad, w, h) {
            function tickFormat(date) {
                let month = date.getMonth();
                let yyyy = date.getFullYear();
                let year = props.year;//take current year provided by sp
                // if (month == 4) {
                //     drawLine(date)
                // }
                //assuming that gannt chart will not spread over more than 10 years taking limit as 10 for below loop
                for (let i = 1; i < 10; i++) {
                    if (yyyy == year && month == 0) {
                        return ('Kick off')
                    } else if (yyyy == year && month > 0) {
                        return ('month ' + (month));
                    } else if (yyyy == Number(year) + i) {
                        return ('month ' + (month + 12 * i));
                    }
                }
            };

            // function drawLine(date) {
            //     var line = svg.append("line")
            //         .attr("x1", date)
            //         .attr("y1", 0)
            //         .attr("x2", date)
            //         .attr("y2", h)
            //         .style("stroke-width", 2)
            //         .style("stroke", "red")
            //         .style("fill", "none");
            // }

            var xAxis = d3.axisBottom(timeScale)
                // .ticks(d3.timeDay, 1)
                .ticks(props.xAxisVal != null && props.xAxisVal != 'default' ? props.xAxisVal : props.effort == 'Conservative' ? props.lastMonth2 > 6 ? 12 : 6 : props.lastMonth1 > 6 ? 12 : 6)
                .tickSize(-h + theTopPad + 20, 0, 0)
                // .tickFormat(d3.timeFormat('%d %b'));
                // .tickFormat(d3.timeFormat('%b %Y'));
                .tickFormat(tickFormat);

            var grid = svg.append('g')
                .attr('class', 'grid')
                .attr('transform', 'translate(' + theSidePad + ', ' + (h - 50) + ')')
                .call(xAxis)
                .selectAll("text")
                .style("text-anchor", "middle")
                .attr("fill", "#000")
                .attr("stroke", "none")
                .attr("font-size", 12)
                .attr("dy", "1em")
                .attr("transform", "rotate(35)")
                .style("text-anchor", "start");

            // text label for the y axis
            svg.append("text")
                .attr("transform", "rotate(-90)")
                .attr("y", -20)
                .attr("x", 0 - (h / 2))
                .attr("dy", "1em")
                .style("text-anchor", "middle")
                .attr("font-size", 14)
                .text("Waves");
        }

        function vertLabels(theGap, theTopPad, theSidePad, theBarHeight, theColorScale) {
            var numOccurances = new Array();
            var prevGap = 0;

            for (var i = 0; i < categories.length; i++) {
                numOccurances[i] = [categories[i], getCount(categories[i], catsUnfiltered), categories_label[i]];
            }

            var axisText = svg.append("g") //without doing this, impossible to put grid lines behind text
                .selectAll("text")
                .data(numOccurances)
                .enter()
                .append("text")
                .text(function (d) {
                    return d[0] != 'No' && d[0] != 0 ? d[0] : '';
                })
                .attr("x", 10)
                .attr("y", function (d, i) {
                    if (i > 0) {
                        for (var j = 0; j < i; j++) {
                            prevGap += numOccurances[i - 1][1];
                            // console.log(prevGap);
                            return d[1] * theGap / 2 + prevGap * theGap + theTopPad;
                        }
                    } else {
                        return d[1] * theGap / 2 + theTopPad;
                    }
                })
                .attr("font-size", 11)
                .attr("text-anchor", "start")
                .attr("cursor", "pointer")
                .attr("text-height", 14)
                .append("svg:title")
                .text(function (d) { return d[2] != null ? "Wave Description: " + d[2] : "Wave Description: "});
            // .attr("fill", function (d) {
            //     for (var i = 0; i < categories.length; i++) {
            //         if (d[0] == categories[i]) {
            //             //  console.log("true!");
            //             return d3.rgb(theColorScale(i)).darker();
            //         }
            //     }
            // });

        }



        //from this stackexchange question: http://stackoverflow.com/questions/1890203/unique-for-arrays-in-javascript
        function checkUnique(arr) {
            var hash = {}, result = [];
            for (var i = 0, l = arr.length; i < l; ++i) {
                if (!hash.hasOwnProperty(arr[i])) { //it works with objects! in FF, at least
                    hash[arr[i]] = true;
                    result.push(arr[i]);
                }
            }
            return result;
        }

        //from this stackexchange question: http://stackoverflow.com/questions/14227981/count-how-many-strings-in-an-array-have-duplicates-in-the-same-array
        function getCounts(arr) {
            var i = arr.length, // var to loop over
                obj = {}; // obj to store results
            while (i) obj[arr[--i]] = (obj[arr[i]] || 0) + 1; // count occurrences
            return obj;
        }

        // get specific from everything
        function getCount(word, arr) {
            return getCounts(arr)[word] || 0;
        }
    }


    render() {
        return (
            <div id="chartContainer">
                <div id="chartId"></div>
                {/* <div id="tag"></div>                 */}
            </div>
        )
    }
}



export default GanntChart