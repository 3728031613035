import {calculationsFetchData} from './fetchCalculations'

export const contentHasErrored = (bool) => {
	return {
		type: 'CONTENT_HAS_ERRORED',
		hasErrored: bool
	}
}

export const contentIsLoading = (bool) => {
	return {
		type: 'CONTENT_IS_LOADING',
		isLoading: bool
	}
}

export const contentFetchDataSuccess = (content) => {
	return {
		type: 'CONTENT_FETCH_DATA_SUCCESS',
		content
	}
}

export const errorAfterFiveSeconds = () => {
	return (dispatch) => {
		setTimeout(() => {
			dispatch(contentHasErrored(true))
		}, 5000)
	}
}

export const contentFetchData = (url, curl) => {
	return (dispatch) => {
		dispatch(contentIsLoading(true))

		return fetch(url)
			.then((response) => {
				if(!response.ok) {
					throw new Error('Bad response from server')
				}

				return response.json()
			})
			.then((content) => dispatch(contentFetchDataSuccess(content)))
			.then(() => dispatch(calculationsFetchData(curl)))
			.then((content) => dispatch(dispatch(contentIsLoading(false))))
			.catch(() => dispatch(contentHasErrored(true)))
	}
}

export const addInterest = (interest) => {
	return {
		type: 'ADD_INTEREST',
		interest
	}
}

export const getInterest = (interest) => {
	return {
		type: 'GET_INTEREST',
		interest
	}
}

//Start PEs Section
export const contentPEHasErrored = (bool) => {
	return {
		type: 'CONTENT_PE_HAS_ERRORED',
		hasErrored: bool
	}
}

export const contentPEIsLoading = (bool) => {
	return {
		type: 'CONTENT_PE_IS_LOADING',
		isLoading: bool
	}
}

export const contentPEFetchDataSuccess = (contentPE) => {
	return {
		type: 'CONTENT_PE_FETCH_DATA_SUCCESS',
		contentPE
	}
}

export const errorPEAfterFiveSeconds = () => {
	return (dispatch) => {
		setTimeout(() => {
			dispatch(contentPEHasErrored(true))
		}, 5000)
	}
}

export const contentPEsFetchData = (url) => {
	return (dispatch) => {
		dispatch(contentPEIsLoading(true))

		return fetch(url)
			.then((response) => {
				if(!response.ok) {
					throw new Error('Bad response from server')
				}
				return response.json();				
			})
			.then(function(parsedData) {
				// data here
				var array = [];
				parsedData.forEach((element) => {
					var obj = {};
					obj.label = element.PEName;
					obj.value = element.PEID;
					array.push(obj);
				});
				return array
			})
			.then((contentPE) => dispatch(contentPEFetchDataSuccess(contentPE)))
			.then((contentPE) => dispatch(dispatch(contentPEIsLoading(false))))
			.catch(() => dispatch(contentPEHasErrored(true)))
	}
}
//End PEs Section

//Start Table Schema

export const tableSchemaHasErrored = (bool) => {
	return {
		type: 'TABLE_SCHEMA_HAS_ERRORED',
		hasErrored: bool
	}
}

export const tableSchemaIsLoading = (bool) => {
	return {
		type: 'TABLE_SCHEMA_IS_LOADING',
		isLoading: bool
	}
}

export const tableSchemaFetchDataSuccess = (tableSCHEMA) => {
	return {
		type: 'TABLE_SCHEMA_FETCH_DATA_SUCCESS',
		tableSCHEMA
	}
}

export const errortableSchemaAfterFiveSeconds = () => {
	return (dispatch) => {
		setTimeout(() => {
			dispatch(tableSchemaHasErrored(true))
		}, 5000)
	}
}

export const tableSchemaFetchData = (url) => {
	return (dispatch) => {
		dispatch(tableSchemaIsLoading(true))

		return fetch(url)
			.then((response) => {
				if(!response.ok) {
					throw new Error('Bad response from server')
				}
				return response.json();				
			})
			.then(function(parsedData) {
				// data here
				var array = [];
				parsedData.forEach((element) => {
					var obj = {};
					obj.label = element.DisplayName;
					obj.value = element.TableName;
					array.push(obj);
				});
				return array
			})
			.then((tableSCHEMA) => dispatch(tableSchemaFetchDataSuccess(tableSCHEMA)))
			.then((tableSCHEMA) => dispatch(dispatch(tableSchemaIsLoading(false))))
			.catch(() => dispatch(tableSchemaHasErrored(true)))
	}
}

//End Table Schema

