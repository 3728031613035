import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Control, Form, Errors, actions } from 'react-redux-form'
import classNames from "classnames";
import { path } from 'Constants'
import moment from 'moment'
import { ControlTextE, SelectE} from 'UI/CustomComponents/Controls';
import Select from 'react-select';
// Actions
import {
    getEnablementResourceParametersAdmin,
    updateEnablementResourceParametersAdmin,
    resetError,
    fetchSolutionInScopeValues
} from 'actions/spendActions'

// Utils
import { BreakLine, round } from 'utils/Utils'

class EnablementResourceParametersAdmin extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            enablementResourceParameters: [],
            editedEnablementResourceParameters: [],
            responseMessage: '',
            isError: false,
            indirectSolutionScopeOption :[],
            selectedIndirectScope : '',
        }
        this.loadData = this.loadData.bind(this)
        this.trackEnablementResourceParametersChanges = this.trackEnablementResourceParametersChanges.bind(this)
        this.onEnablementResourceParametersSave = this.onEnablementResourceParametersSave.bind(this)
        this.handleSolutionInScopeChange = this.handleSolutionInScopeChange.bind(this)        
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        this.props.fetchSolutionInScopeValues().then((response) => {
            if (response.status === 200) {
                this.getAllParameters(response.data.Result[0].SOLUTION_IN_SCOPE_NAME);
                var indirectScopeValues=[];                				

                if (response.data.Result !== null) {
                    response.data.Result.forEach(item => {                       
                            indirectScopeValues.push({ label: item.SOLUTION_IN_SCOPE_NAME, value: item.SOLUTION_IN_SCOPE_NAME });							
                    });
                }   								

                this.setState({ indirectSolutionScopeOption: indirectScopeValues, selectedIndirectScope : response.data.Result[0].SOLUTION_IN_SCOPE_NAME});
            }
        });
    }

    trackEnablementResourceParametersChanges(record) {
        var updatedRecords = this.state.editedEnablementResourceParameters
        if (this.state.editedEnablementResourceParameters.length === 0)
            updatedRecords.push(record)
        else {
            let index = this.state.editedEnablementResourceParameters.findIndex(x => x.RESOURCE_PARAMETER_ID === record.RESOURCE_PARAMETER_ID);
            if (index !== undefined && index !== null && index > -1)
                updatedRecords.splice(index, 1)
            updatedRecords.push(record)
        }
        this.setState({ responseMessage: '', editedEnablementResourceParameters: updatedRecords, isError: false })
    }

    getAllParameters(solutionInScope) {
        this.setState({ responseMessage: '', isLoading: true, isError: false });
        this.props.getEnablementResourceParametersAdmin(solutionInScope).then((response) => {
            if (response.status === 200) {
                if (response.data.Result.length === 0) {
                    this.setState({ responseMessage: this.props.content.reimport, isLoading: false, isError: true });
                }
                else
                {
                    const ids = response.data.Result.map(({ RESOURCE_PARAMETER_ID }) => RESOURCE_PARAMETER_ID);
                    const filtered = response.data.Result.filter(({ RESOURCE_PARAMETER_ID }, index) =>
                    !ids.includes(RESOURCE_PARAMETER_ID, index + 1));
                    this.setState({ enablementResourceParameters: filtered.filter(d=>d.ENABLEMENT_WAVE_STRATEGY_MODEL=== this.props.tab), isLoading: false, responseMessage: '', isError: false });
                    
                }
            }
            else {
                this.setState({ responseMessage: this.props.content.errorMessage, isLoading: false, isError: true });
            }
        }).catch((error) => {
            console.log(error);
            this.setState({ responseMessage: this.props.content.errorMessage, isLoading: false, isError: true });
        })
    }

    onEnablementResourceParametersSave() {
        var enterpriseSuppEnableLeadFTE = this.state.editedEnablementResourceParameters.find(a => a.ACCOUNT_TYPE == 'Enterprise' && a.RESOURCE_TYPE == 'Supplier Enablement Lead') === undefined ? this.state.enablementResourceParameters.find(a => a.ACCOUNT_TYPE == 'Enterprise' && a.RESOURCE_TYPE == 'Supplier Enablement Lead').FTE : this.state.editedEnablementResourceParameters.find(a => a.ACCOUNT_TYPE == 'Enterprise' && a.RESOURCE_TYPE == 'Supplier Enablement Lead').FTE;
        var standardSuppEnableLeadFTE = this.state.editedEnablementResourceParameters.find(a => a.ACCOUNT_TYPE == 'Standard' && a.RESOURCE_TYPE == 'Supplier Enablement Lead') === undefined ? this.state.enablementResourceParameters.find(a => a.ACCOUNT_TYPE == 'Standard' && a.RESOURCE_TYPE == 'Supplier Enablement Lead').FTE : this.state.editedEnablementResourceParameters.find(a => a.ACCOUNT_TYPE == 'Standard' && a.RESOURCE_TYPE == 'Supplier Enablement Lead').FTE;

        if (parseInt(enterpriseSuppEnableLeadFTE) != parseInt(standardSuppEnableLeadFTE)) {
            this.setState({ responseMessage: 'Standard & Enterprise FTE for Supplier Enablement Lead should be equal', isError: true });
        }
        else {

            if (this.state.editedEnablementResourceParameters.find(a => a.WAVE_1 === '') === undefined && this.state.editedEnablementResourceParameters.find(a => a.WAVE_N === '') === undefined) {
                if (this.state.editedEnablementResourceParameters.find(a => a.WAVE_1 === '-') === undefined && this.state.editedEnablementResourceParameters.find(a => a.WAVE_N === '-') === undefined) {
                    this.setState({ isLoading: true, responseMessage: '', isError: false });
                    let updatedRecords = this.state.editedEnablementResourceParameters
                    let email = this.props.user.user.Email
                    let finalObject = [];
                    updatedRecords.map(function (parameter) {
                        let reocrd = {
                            'Email': email, 'Resource_Parameter_Id': parameter.RESOURCE_PARAMETER_ID,
                            'FTEs': parameter.FTE, 'SolutionInScope': parameter.SOLUTION_IN_SCOPE,
                            'Wave_1': parameter.WAVE_1, 'Wave_n': parameter.WAVE_N
                        }
                        finalObject.push(reocrd)
                    });

                    this.props.updateEnablementResourceParametersAdmin(finalObject).then((response) => {
                        if (response.status === 200) {
                            const ids = response.data.Result.map(({ RESOURCE_PARAMETER_ID }) => RESOURCE_PARAMETER_ID);
                            const filtered = response.data.Result.filter(({ RESOURCE_PARAMETER_ID }, index) =>
                                !ids.includes(RESOURCE_PARAMETER_ID, index + 1));

                            this.setState({ enablementResourceParameters: filtered.filter(d => d.ENABLEMENT_WAVE_STRATEGY_MODEL === this.props.tab), editedEnablementResourceParameters: [], isLoading: false, responseMessage: this.props.content.successMessage, isError: false });
                        }
                        else {
                            this.setState({ isLoading: false, responseMessage: this.props.content.errorMessage, isError: true });
                        }
                    }).catch((error) => {
                        this.setState({ isLoading: false, responseMessage: this.props.content.errorMessage, isError: true });
                    })
                }
                else {
                    this.setState({ responseMessage: this.props.content.validNumber, isError: true });
                }
            }
            else {
                this.setState({ responseMessage: this.props.content.blankValue, isError: true });
            }
        }
    }

    componentWillUnmount() {
        this.props.resetError()
    }

    handleSolutionInScopeChange(value)
    {
        this.setState({  selectedIndirectScope :  value },()=>{this.getAllParameters(value)});
    }

    render() {
        var _instance = this
        var isAdmin = this.props.user.userprofile.Roles[0].indexOf("Admin") > -1 ? true : false;
        var resourceParameters = this.state.enablementResourceParameters.map(function (parameter, index) {
            return (<TableRows
                enablementResourceParameter={parameter}
                index={index}
                isAdmin={isAdmin}
                trackEnablementResourceParametersChanges={_instance.trackEnablementResourceParametersChanges}
            />
            );
        });

        return (
            <div className="grid-x">
                <div className="medium-12">
                    {(this.state.isLoading) ?
                        <img style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
                            src={path + `images/ajax-loader.gif`} alt="" /> : null}
                </div>
                <div className="medium-12">
                    <div className="grid-x">
                        <div className="medium-6 float-left" style={{ fontSize: '1.2rem', paddingBottom: '8px' }}>{BreakLine(this.props.tab == 'KPI Based Model' ? this.props.content.titleKPI : this.props.content.title)}</div>
                        <div className="medium-6" style={{ display: 'flex', justifyContent: 'center' }} >
                            {this.state.isError ?
                                <div style={{ margin: '0 0 0.5rem', color: 'red' }}>{this.state.responseMessage}</div> :
                                <div style={{ margin: '0 0 0.5rem', color: '#4fb81c' }}>{this.state.responseMessage}</div>
                            }
                        </div>
                        <div className="medium-3"></div>
                    </div>
                    <div className="grid-x">
                        <div className="medium-11 float-left pb10">{this.props.tab == 'KPI Based Model' ? this.props.content.tabDescKPIAdmin : this.props.content.tabDescAdmin }</div>
                        {this.props.user.userprofile.Roles[0].indexOf("Admin") > -1 ?
                            <div className="medium-1 pb5">
                                <button className={classNames('float-right',
                                    {
                                        'hand-cursor': this.state.isLoading ? false : (this.state.editedEnablementResourceParameters.length > 0),
                                        'gray-scale': this.state.isLoading ? true : (this.state.editedEnablementResourceParameters.length === 0)
                                    })}
                                    onClick={this.onEnablementResourceParametersSave} disabled={this.state.isLoading ? true : this.state.editedEnablementResourceParameters.length === 0} type="submit" float >
                                    <img width="25" title={this.props.content.save} src="images/save_active.svg" />
                                </button>
                            </div> : null}
                    </div>
                    
                </div>
                
                {/* <div  className="medium-12" style={{ width: '50%' }}> */}
                <table style={{border : 'solid',borderWidth : '1px'}}><tr>
                    <td><div>Solution in Scope : </div>
                    </td>
                    <td><div>   <Select
                        className="border-less-DD"
                        model="discovery.IndirectScope"
                        id="discovery.IndirectScope"
                        name="discovery.IndirectScope"
                        placeholder={'Solution In Scope'}
                        value={this.state.indirectSolutionScopeOption.filter(option => option.label === this.state.selectedIndirectScope)}
                        noOptionsMessage={() => null}
                        onChange={(value) => this.handleSolutionInScopeChange(value.label)}
                        options={this.state.indirectSolutionScopeOption}
                        isSearchable={false}
                        disabled={false}
                    />
                    </div></td></tr></table>
                    
                    
                {/* </div> */}
              
                {this.state.enablementResourceParameters.length > 0 ?
                    <div className="medium-12">
                        <Form model="netwrokEnablementMatchTypeParameter">
                            <div className="basic-table">
                                <table>
                                    <thead>
                                        <tr className="eachRow">
                                            <th>{this.props.content.resourceType}</th>
                                            <th>{this.props.content.accountType}</th>
                                            <th>{this.props.content.FTEs}</th>
                                            <th>{this.props.content.wave_1}</th>
                                            <th>{this.props.content.wave_n}</th>
                                            <th>{this.props.content.updatedBy}</th>
                                            <th>{this.props.content.updatedDate}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {resourceParameters}
                                    </tbody>
                                </table>
                            </div>
                        </Form>
                    </div>
                    : null
                }
            </div>
        )
    }
}

class TableRows extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    onChangeEvent(val) {
        if (val.target.value === '' || val.target.value.match(/^[0-9]+$/) || !isNaN(val.target.value)) {
            var editingRow = this.props.enablementResourceParameter;
            let editedValue = val.target.value.trim()
            if (editedValue.toString() !== editingRow[val.target.id].toString()) {
                editingRow[val.target.id] = editedValue
                this.props.trackEnablementResourceParametersChanges(editingRow)
            }
        }
    }

    render() {
        var bindValues = this.props.enablementResourceParameter;
        return (
            <tr className="eachRow">
                {this.props.index === 0 ?
                    <td rowspan="2" model="enablementResourceParameter.RESOURCE_TYPE">{bindValues.RESOURCE_TYPE}</td>
                    :
                    this.props.index !== 1 ?
                        <td model="enablementResourceParameter.RESOURCE_TYPE">{bindValues.RESOURCE_TYPE}</td>
                        :
                        null
                }
                <td model="enablementResourceParameter.ACCOUNT_TYPE">{bindValues.ACCOUNT_TYPE}</td>
                {this.props.isAdmin ?
                    <td>
                        <ControlTextE
                            model="enablementResourceParameter.FTE"
                            onChange={this.onChangeEvent.bind(this)}
                            id={'FTE'}
                            defaultValue={bindValues.FTE}
                            value={bindValues.FTE}
                            type='name'
                            className="edit-textarea edit-width"
                            readOnly={this.props.readOnly}
                        />
                    </td> :
                    <td model="enablementResourceParameter.WAVE_1">{bindValues.WAVE_1}</td>
                }
                {this.props.isAdmin ?
                    <td>
                        <ControlTextE
                            model="enablementResourceParameter.WAVE_1"
                            onChange={this.onChangeEvent.bind(this)}
                            id={'WAVE_1'}
                            defaultValue={bindValues.WAVE_1}
                            value={bindValues.WAVE_1}
                            type='name'
                            className="edit-textarea edit-width"
                            readOnly={this.props.readOnly}
                        />
                    </td> :
                    <td model="enablementResourceParameter.WAVE_1">{bindValues.WAVE_1}</td>
                }
                {this.props.isAdmin ?
                    <td>
                        <ControlTextE
                            model="enablementResourceParameter.WAVE_N"
                            onChange={this.onChangeEvent.bind(this)}
                            id={'WAVE_N'}
                            defaultValue={bindValues.WAVE_N}
                            value={bindValues.WAVE_N}
                            type='name'
                            className="edit-textarea edit-width"
                            readOnly={this.props.readOnly}
                        />
                    </td> :
                    <td model="enablementResourceParameter.WAVE_N">{bindValues.WAVE_N}</td>
                }
                <td model="flightPlanningDocParameter.UpdatedBy">{bindValues.USER_ID}</td>
                <td model="flightPlanningDocParameter.UpdatedOn">{moment(bindValues.DATA_LOAD_DATE).format('L')}</td>
            </tr>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        language: state.language,
        ui: state.content.ui,
        user: state.default,
        spendDetails: state.spendDetails,
        content: state.content.spendAnalysis.enablementResourceParamaeters,
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        getEnablementResourceParametersAdmin: (soltionInScope) => dispatch(getEnablementResourceParametersAdmin(soltionInScope)),
        updateEnablementResourceParametersAdmin: (records) => dispatch(updateEnablementResourceParametersAdmin(records)),
        fetchSolutionInScopeValues: () => dispatch(fetchSolutionInScopeValues()),
        resetError: () => dispatch(resetError())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EnablementResourceParametersAdmin)