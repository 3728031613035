import axios from 'axios'

import {
  valueStoryIsLoading
} from './fetchValueStory'

import {
  isNewValueStory
}
  from 'actions/searchActions'

// Settings
import { env, ROOT_URL } from 'Constants'
import {
  manipulateReport
} from './reportNodeActions'

//Get current user(me) from token in localStorage
export const ME_FROM_TOKEN = 'ME_FROM_TOKEN';
export const ME_FROM_TOKEN_SUCCESS = 'ME_FROM_TOKEN_SUCCESS';
export const ME_FROM_TOKEN_FAILURE = 'ME_FROM_TOKEN_FAILURE';
export const RESET_TOKEN = 'RESET_TOKEN';

//Sign Up User
export const SIGNUP_USER_LOADING = 'SIGNUP_USER_LOADING';

export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNUP_USER_FAILURE = 'SIGNUP_USER_FAILURE';
export const RESET_USER = 'RESET_USER';

//Sign In User
export const SIGNIN_USER_LOADING = 'SIGNIN_USER_LOADING';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNIN_USER_SUCCESS_SSO = 'SIGNIN_USER_SUCCESS_SSO';
export const SIGNIN_USER_FAILURE = 'SIGNIN_USER_FAILURE';

//Fetch Token
export const FETCH_TOKEN_LOADING = 'FETCH_TOKEN_LOADING';
export const FETCH_TOKEN_SUCCESS = 'FETCH_TOKEN_SUCCESS';
export const FETCH_TOKEN_FAILURE = 'FETCH_TOKEN_FAILURE';

//validate email, if success, then load user and login
export const VALIDATE_EMAIL = 'VALIDATE_EMAIL';
export const VALIDATE_EMAIL_SUCCESS = 'VALIDATE_EMAIL_SUCCESS';
export const VALIDATE_EMAIL_FAILURE = 'VALIDATE_EMAIL_FAILURE';

//called when email is updated in profile to update main user's email state
export const UPDATE_USER_EMAIL = 'UPDATE_USER_EMAIL';

//log out user
export const LOGOUT_USER = 'LOGOUT_USER';

//fetch value story list
export const FETCH_VALUE_STORIES = 'FETCH_VALUE_STORIES';
export const FETCH_VALUE_STORIES_LOADING = 'FETCH_VALUE_STORIES_LOADING';
export const FETCH_VALUE_STORIES_SUCCESS = 'FETCH_VALUE_STORIES_SUCCESS';
export const FETCH_VALUE_STORIES_FAILURE = 'FETCH_VALUE_STORIES_FAILURE';

//add new value story
export const ADD_VALUE_STORY = 'ADD_VALUE_STORY';
export const ADD_VALUE_STORY_LOADING = 'ADD_VALUE_STORY_LOADING';
export const ADD_VALUE_STORY_SUCCESS = 'ADD_VALUE_STORY_SUCCESS';
export const ADD_VALUE_STORY_FAILURE = 'ADD_VALUE_STORY_FAILURE';

//update new value story
export const UPDATE_VALUE_STORY_LOADING = 'UPDATE_VALUE_STORY_LOADING';
export const UPDATE_VALUE_STORY_SUCCESS = 'UPDATE_VALUE_STORY_SUCCESS';
export const UPDATE_VALUE_STORY_FAILURE = 'UPDATE_VALUE_STORY_FAILURE';

//open value story
export const OPEN_VALUE_STORY = 'OPEN_VALUE_STORY';
export const OPEN_VALUE_STORY_SUCCESS = 'OPEN_VALUE_STORY_SUCCESS';
export const OPEN_VALUE_STORY_FAILURE = 'OPEN_VALUE_STORY_FAILURE';

//delete value story
export const DELETE_VALUE_STORY_LOADING = 'DELETE_VALUE_STORY_LOADING';
export const DELETE_VALUE_STORY_SUCCESS = 'DELETE_VALUE_STORY_SUCCESS';
export const DELETE_VALUE_STORY_FAILURE = 'DELETE_VALUE_STORY_FAILURE';

//save as
export const SAVE_AS_LOADING = 'SAVE_AS_LOADING';
export const SAVE_AS_SUCCESS = 'SAVE_AS_SUCCESS';
export const SAVE_AS_FAILURE = 'SAVE_AS_FAILURE';

//forgot password
export const FORGOT_PASSWORD_LOADING = 'FORGOT_PASSWORD_LOADING';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';



//reset password
export const RESET_PASSWORD_LOADING = 'RESET_PASSWORD_LOADING';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';
export const FETCH_USER_DETAILS_SUCCESS = 'FETCH_USER_DETAILS_SUCCESS'

//change password
export const CHANGE_PASSWORD_LOADING = 'CHANGE_PASSWORD_LOADING';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';

//Save
export const VALUE_STORY_IS_SAVING = 'VALUE_STORY_IS_SAVING';
export const AUTO_SAVE_SUCCESS = 'AUTO_SAVE_SUCCESS';
export const AUTO_SAVE_FAILURE = 'AUTO_SAVE_FAILURE';

//report
export const REPORT_SUBMIT_LOADING = 'REPORT_SUBMIT_LOADING';
export const REPORT_SUBMIT_SUCCESS = 'REPORT_SUBMIT_SUCCESS';
export const REPORT_SUBMIT_FAILURE = 'REPORT_SUBMIT_FAILURE';

//reset error message
export const RESET_ERROR = 'RESET_ERROR';

//reset message
export const RESET_MESSAGE = 'RESET_MESSAGE';

// update language
export const UPDATE_LANGUAGE = 'UPDATE_LANGUAGE';

// offline/online status
export const UPDATE_ONLINE_STATUS = 'UPDATE_ONLINE_STATUS';


//DOMAIN DETAILS
export const DOMAIN_DETAILS_LOADING = 'DOMAIN_DETAILS_LOADING';
export const DOMAIN_DETAILS_SUCCESS = 'DOMAIN_DETAILS_SUCCESS';
export const DOMAIN_DETAILS_FAILURE = 'DOMAIN_DETAILS_FAILURE';

export const BLOCKED_DOMAIN_DETAILS_LOADING = 'BLOCKED_DOMAIN_DETAILS_LOADING';
export const BLOCKED_DOMAIN_DETAILS_SUCCESS = 'BLOCKED_DOMAIN_DETAILS_SUCCESS';
export const BLOCKED_DOMAIN_DETAILS_FAILURE = 'BLOCKED_DOMAIN_DETAILS_FAILURE';

//User DETAILS
export const USER_DETAILS_LOADING = 'USER_DETAILS_LOADING';
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS';
export const USER_DETAILS_FAILURE = 'USER_DETAILS_FAILURE';

//User Consent
export const USER_CONSENT_LOADING = 'USER_CONSENT_LOADING';
export const USER_CONSENT_SUCCESS = 'USER_CONSENT_SUCCESS';
export const USER_CONSENT_FAILURE = 'USER_CONSENT_FAILURE';

//Newsletter Acceptance
export const NEWSLETTER_ACCEPTANCE_LOADING = 'NEWSLETTER_ACCEPTANCE_LOADING';
export const NEWSLETTER_ACCEPTANCE_SUCCESS = 'NEWSLETTER_ACCEPTANCE_SUCCESS';
export const NEWSLETTER_ACCEPTANCE_FAILURE = 'NEWSLETTER_ACCEPTANCE_FAILURE';


export const LANGUAGE_TRANSLATION_CONSENT_LOADING = 'LANGUAGE_TRANSLATION_CONSENT_LOADING'
//change role

export const CHANGE_ROLE = 'CHANGE_ROLE';

//Notification manager
export const NOTIFICATION_MANAGER_LOADING = 'NOTIFICATION_MANAGER_LOADING';
export const NOTIFICATION_MANAGER_SUCCESS = 'NOTIFICATION_MANAGER_SUCCESS';
export const NOTIFICATION_MANAGER_FAILURE = 'NOTIFICATION_MANAGER_FAILURE';

//share value story
export const SHARE_VALUESTORY_LOADING = 'SHARE_VALUESTORY_LOADING';
export const SHARE_VALUESTORY_SUCCESS = 'SHARE_VALUESTORY_SUCCESS';
export const SHARE_VALUESTORY_FAILURE = 'SHARE_VALUESTORY_FAILURE';

//Upload File Manager
export const UPLOAD_FILE_LOADING = 'UPLOAD_FILE_LOADING';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_FAILURE = 'UPLOAD_FILE_FAILURE';
//benchmark
export const BENCHMARK_LOADING = 'BENCHMARK_LOADING';
export const BENCHMARK_SUCCESS = 'BENCHMARK_SUCCESS';
export const BENCHMARK_FAILURE = 'BENCHMARK_FAILURE';

//regions
export const REGIONS_LOADING = 'REGIONS_LOADING';
export const REGIONS_SUCCESS = 'REGIONS_SUCCESS';
export const REGIONS_FAILURE = 'REGIONS_FAILURE';

//request value story
export const REQUEST_VALUESTORY_LOADING = 'REQUEST_VALUESTORY_LOADING';
export const REQUEST_VALUESTORY_SUCCESS = 'REQUEST_VALUESTORY_SUCCESS';
export const REQUEST_VALUESTORY_FAILURE = 'REQUEST_VALUESTORY_FAILURE';

//user profile story list
export const FETCH_USER_PROFILE_LOADING = 'FETCH_USER_PROFILE_LOADING';
export const FETCH_USER_PROFILE_SUCCESS = 'FETCH_USER_PROFILE_SUCCESS';
export const FETCH_USER_PROFILE_FAILURE = 'FETCH_USER_PROFILE_FAILURE';
export const FETCH_USER_PROFILE_ROLES = 'FETCH_USER_PROFILE_ROLES';

//report bug
export const REPORT_BUG_LOADING = 'REPORT_BUG_LOADING';
export const REPORT_BUG_SUCCESS = 'REPORT_BUG_SUCCESS';
export const REPORT_BUG_FAILURE = 'REPORT_BUG_FAILURE';

export const SHAREFILESONJUMPHOST_LOADING = 'SHAREFILESONJUMPHOST_LOADING';
export const SHAREFILESONJUMPHOST_SUCCESS = 'SHAREFILESONJUMPHOST_SUCCESS';
export const SHAREFILESONJUMPHOST_FAILURE = 'SHAREFILESONJUMPHOST_FAILURE';

export const GENERIC_PENDING_CHANGES = 'GENERIC_PENDING_CHANGES'

export const VALUESTORY_MENU_ITEM = 'VALUESTORY_MENU_ITEM'

export const FETCH_USER_MASK_LOADING = 'FETCH_USER_MASK_LOADING';
export const FETCH_USER_MASK_SUCCESS = 'FETCH_USER_MASK_SUCCESS';
export const FETCH_USER_MASK_FAILURE = 'FETCH_USER_MASK_FAILURE';
export const FETCH_USER_MASK_ROLES = 'FETCH_USER_MASK_ROLES';

const SIGNATURE = 'fgj4UVkqwXd4rPrvGH6z2mbF9Tu2vttAUPVdd9gSus8EGe8Bg5gGPMQ5hufE5GSysxv2pG5uBNP8tZgNhhuPqush5xUWTbHstGsu'

// APIs
const registerAPI = `${ROOT_URL}/Account/Register`
const SSOAPI = `${ROOT_URL}/Account/SSO`
const loginAPI = `${ROOT_URL}/Account/Login`
const logoutAPI = `${ROOT_URL}/Account/LogOut`
const tokenAPI = `${ROOT_URL}/Account/RegenerateToken`
const forgotPasswordAPI = `${ROOT_URL}/Account/ForgotPassword`
const consentAPI = `${ROOT_URL}/Account/Consent`
const updateConsentAPI = `${ROOT_URL}/Account/ConsentUpdate`
//const checkForUpdateAPI = `${ROOT_URL}/Account/NewUpdate`
const UpdateAcknowledgementAPI = `${ROOT_URL}/Account/UpdateAcknowledgement`
const checkNewsletterAcceptanceAPI = `${ROOT_URL}/Account/CheckNewsletterAcceptance`
const newsletterAcceptanceAPI = `${ROOT_URL}/Account/NewsletterAcceptanceUpdate`
const resetPasswordAPI = `${ROOT_URL}/Account/ResetPassword`
const changePasswordAPI = `${ROOT_URL}/Account/ChangePassword`
const userMaskAPI = `${ROOT_URL}/Account/MaskUser`
const validateSessionAPI = `${ROOT_URL}/Account/SessionCheck`
const userProfileAPI = `${ROOT_URL}/Account/UserProfileDetails`
const uploadUserProfilePicAPI = `${ROOT_URL}/Account/UserProfilePicUpload`
const updateUserNameAPI = `${ROOT_URL}/Account/UpdateUserName`
const userPercentileAPI = `${ROOT_URL}/Account/UserPercentileDetails`
const checkPasswordAPI = `${ROOT_URL}/Account/checkPasswordValidity`
const userDetailsAPI = `${ROOT_URL}/Account/UserDetails`
const periodicValidationPartnersUsersAPI = `${ROOT_URL}/Account/PeriodicValidationPartnersUsers`

const fetchUserValueStoriesAPI = `${ROOT_URL}/UserValueStories`
const addValueStoryAPI = `${ROOT_URL}/ValueStories`
const updateValueStoryAPI = `${ROOT_URL}/UpdateValueStory`
//const valueStoryAPI = `${ROOT_URL}/ValueStories/`
const getValueStoryAPI = `${ROOT_URL}/GetValueStory`
const isSharedValueStoryOpenAPI = `${ROOT_URL}/IsSharedValueStoryOpen`
const saveValueStoryAPI = `${ROOT_URL}/SaveValueStory`
const trackLastModifiedDateTimeAPI = `${ROOT_URL}/TrackLastModifiedDateTime`
const saveAsAPI = `${ROOT_URL}/SaveAsValueStory/`
const deleteValueStoryAPI = `${ROOT_URL}/DeleteValueStory/`
//const reportAPI = `${ROOT_URL}/SendPPTReport`
const reportAPI = `${ROOT_URL}/BuildPPTReport`
const replacePlaceHoldersAPI = `${ROOT_URL}/ReplacePlaceHolders/`
const downloadReportAPI = `${ROOT_URL}/DownloadReport/`
const getTokenAPI = `${ROOT_URL}/token?arskey=${SIGNATURE}&username=`
const fetchValueStoriesAPI = `${ROOT_URL}/OrgValueStories`
const requestValueStoryAPI = `${ROOT_URL}/RequestValueStory`
const fetchRequestDetailsAPI = `${ROOT_URL}/FetchRequestDetails`
const fetchUserSharedValueStoriesAPI = `${ROOT_URL}/SharedValueStories`
const getSharedValueStoriesAPI = `${ROOT_URL}/GetSharedValueStories`
const shareValueStoryAPI = `${ROOT_URL}/ShareValueStory`
const toggleValueStoryShareAPI = `${ROOT_URL}/ToggleValueStoryShare`

const fetchAllUsersAPI = `${ROOT_URL}/Admin/GetAllUsers`
const activateUserAPI = `${ROOT_URL}/Admin/ActivateUser`
const domainDetailsAPI = `${ROOT_URL}/Admin/GetAllowedDomains`
const deleteDomainAPI = `${ROOT_URL}/Admin/DeleteAllowedDomains`
const addDomainAPI = `${ROOT_URL}/Admin/AddAllowedDomains`
const updateDomainAPI = `${ROOT_URL}/Admin/UpdateAllowedDomain`
const blockedDomainDetailsAPI = `${ROOT_URL}/Admin/GetBlockedDomains`
const deleteBlockedDomainAPI = `${ROOT_URL}/Admin/DeleteBlockedDomains`
const addBlockedDomainAPI = `${ROOT_URL}/Admin/AddBlockedDomains`
const updateBlockedDomainAPI = `${ROOT_URL}/Admin/UpdateBlockedDomain`
const setPasswordAPI = `${ROOT_URL}/Admin/ResetPassword`
const reportsAPI = `${ROOT_URL}/Admin/GetReports`
const filesAPI = `${ROOT_URL}/Admin/GetFiles`
const sectionsAPI = `${ROOT_URL}/Admin/GetSections`
const slidesAPI = `${ROOT_URL}/Admin/GetSlides`
const slideNamesAPI = `${ROOT_URL}/Admin/GetSlideNames`
const mappingsAPI = `${ROOT_URL}/Admin/GetReportMappings`
const addReportAPI = `${ROOT_URL}/Admin/AddReports`
const addFileAPI = `${ROOT_URL}/Admin/AddFiles`
const addSectionAPI = `${ROOT_URL}/Admin/AddSections`
const addSlideAPI = `${ROOT_URL}/Admin/AddSlides`
const deleteReportAPI = `${ROOT_URL}/Admin/DeleteReports`
const deleteFileAPI = `${ROOT_URL}/Admin/DeleteFiles`
const deleteSectionAPI = `${ROOT_URL}/Admin/DeleteSections`
const deleteSlideAPI = `${ROOT_URL}/Admin/DeleteSlides`
const updateReportAPI = `${ROOT_URL}/Admin/UpdateReports`
const updateFileAPI = `${ROOT_URL}/Admin/UpdateFiles`
const updateSectionAPI = `${ROOT_URL}/Admin/UpdateSections`
const updateSlideAPI = `${ROOT_URL}/Admin/UpdateSlides`
const validateAllAPI = `${ROOT_URL}/Admin/ValidateAll`
const addSequenceAPI = `${ROOT_URL}/Admin/UpdateReportMappings`
const delSequenceAPI = `${ROOT_URL}/Admin/DeleteSequenceState`
const addDescriptionAPI = `${ROOT_URL}/Admin/AddIndexDescription`
const updateDescriptionAPI = `${ROOT_URL}/Admin/UpdateIndexDescription`
const layoutAPI = `${ROOT_URL}/Admin/GetLayoutFiles`
const sizeAPI = `${ROOT_URL}/Admin/GetSizeFiles`
const addLayoutFilesAPI = `${ROOT_URL}/Admin/AddLayoutFiles`
const addSizeFilesAPI = `${ROOT_URL}/Admin/AddSizeFiles`
const deleteLayoutAPI = `${ROOT_URL}/Admin/DeleteLayoutFiles`
const deleteSizeAPI = `${ROOT_URL}/Admin/DeleteSizeFiles`
const activateReportAPI = `${ROOT_URL}/Admin/ActivateReport`
//const GetUpdateDescriptionsAPI = `${ROOT_URL}/Admin/GetUpdateDescriptions`
const getReleaseFeaturesAPI = `${ROOT_URL}/Admin/GetReleaseFeatures`
const uploadFileAPI = `${ROOT_URL}/Admin/UploadFile`
const getBenchMarkDetailsAPI = `${ROOT_URL}/Admin/GetBenchMarkDetails`
const addBenchMarkAPI = `${ROOT_URL}/Admin/AddBenchMark`
const updateBenchMarkAPI = `${ROOT_URL}/Admin/UpdateBenchMark`
const deActiveBenchMarkAPI = `${ROOT_URL}/Admin/DeactiveBenchMark`
const generateBenchMarkJSONAPI = `${ROOT_URL}/Admin/GenerateBenchMarkJSON`
const reportBugAPI = `${ROOT_URL}/Admin/ReportBug`
const shareFileOnJumphostAPI  = `${ROOT_URL}/Admin/ShareFileOnJumphost`
const fetchSystemReportingDetailsAPI = `${ROOT_URL}/Admin/FetchSystemReportingDetails`
const updateIndustryDataInHanaAPI = `${ROOT_URL}/UpdateIndustryDataInHana`

export const changeRole = (location) => {
  return {
    type: 'CHANGE_ROLE',
    location
  }
}

export function validateSession(formValues) {

  return (dispatch) => {

    return axios({
      method: 'post',
      url: validateSessionAPI,
      data: formValues,
      headers: {
        'Authorization': `AribaBearer ${formValues.Token}`
      }
    })
  }
}

export function validateEmail(validateEmailToken) {
  //check if token from welcome email is valid, if so, update email as verified and login the user from response
  const request = axios.get(`${ROOT_URL}/validateEmail/${validateEmailToken}`);

  return {
    type: VALIDATE_EMAIL,
    payload: request
  };
}

export function validateEmailSuccess(currentUser) {
  return {
    type: VALIDATE_EMAIL_SUCCESS,
    payload: currentUser
  };
}

export function validateEmailFailure(error) {
  return {
    type: VALIDATE_EMAIL_FAILURE,
    payload: error
  };
}

export function meFromToken(tokenFromStorage, username) {
  //check if the token is still valid, if so, get me from the server

  return (dispatch) => {
    //const request = axios({
    axios({
      method: 'get',
      url: getTokenAPI + username,
      headers: {
        'Authorization': `AribaBearer ${tokenFromStorage}`
      }
    }).then((response) => {
      if (response.status === 200) {
        //store token 
        sessionStorage.setItem('jwtToken', response.data)
        dispatch(meFromTokenSuccess(response.data))
      } else {
        //remove token from storage
        sessionStorage.removeItem('jwtToken')
        dispatch(meFromTokenFailure(response.data));
      }
    })
  }

  /*return {
    type: ME_FROM_TOKEN,
    payload: request
  };*/
}

export function meFromTokenSuccess(currentUser) {
  return {
    type: ME_FROM_TOKEN_SUCCESS,
    payload: currentUser
  };
}

export function meFromTokenFailure(error) {
  return {
    type: ME_FROM_TOKEN_FAILURE,
    payload: error
  };
}


export function resetToken() { //used for logout
  return {
    type: RESET_TOKEN
  };
}

export function signUpUser(formValues) {
  return (dispatch) => {
    dispatch(signUpUserLoading(true))

    return axios.post(registerAPI, formValues).then((response) => {
      dispatch(signUpUserSuccess(response))
      return response
    }).catch((error) => {
      return error;
    })
  }
}

export function signUpUserLoading(bool) {
  return {
    type: SIGNUP_USER_LOADING,
    payload: bool
  };
}

export function signUpUserSuccess(user) {
  return {
    type: SIGNUP_USER_SUCCESS,
    payload: user
  };
}

export function signUpUserFailure(error) {
  return {
    type: SIGNUP_USER_FAILURE,
    payload: error
  };
}

export function signInSSO(formValues) {
  return (dispatch) => {
    //dispatch(signInUserLoading(true))

    return axios.post(SSOAPI, formValues)
      .then((response) => {
        if (response.status !== 200) {
          //dispatch(signInUserFailure(response))
        }

        //dispatch(signInUserSuccess(response))
        return response
      })
  }
}

export function signInUser(formValues) {
  return (dispatch) => {
    dispatch(signInUserLoading(true))

    return axios.post(loginAPI, formValues)
      .then((response) => {
        if (response.status !== 200) {
          dispatch(signInUserFailure(response))
        }

        dispatch(signInUserSuccess(response))
        return response
      })
  }
}

export function signInUserLoading(bool) {
  return {
    type: SIGNIN_USER_LOADING,
    payload: bool
  }
}


export function signInUserSuccess(user) {
  return {
    type: SIGNIN_USER_SUCCESS,
    payload: user
  };
}

export function signInUserSuccess_SSO(user) {
  return {
    type: SIGNIN_USER_SUCCESS_SSO,
    payload: user
  };
}

export function signInUserFailure(error) {
  return {
    type: SIGNIN_USER_FAILURE,
    payload: error
  };
}

/*export function logoutUser() {
  return {
    type: LOGOUT_USER
  };
}*/

export function logoutUser() {
  return (dispatch) => {

    let token = localStorage.getItem('jwtToken')

    return axios({
      url: logoutAPI,
      method: 'post',
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function fetchToken(formValues) {
  return (dispatch) => {
    dispatch(fetchTokenLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: tokenAPI,
      data: formValues,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      if (response.status !== 200) {
        dispatch(fetchTokenFailure(response))
      }
      dispatch(fetchTokenSuccess(response))
      return response
    })
  }
}

export function fetchTokenLoading(bool) {
  return {
    type: FETCH_TOKEN_LOADING,
    payload: bool
  }
}

export function fetchTokenFailure(error) {
  return {
    type: FETCH_TOKEN_LOADING,
    payload: error
  }
}


export function fetchTokenSuccess(user) {
  return {
    type: FETCH_TOKEN_SUCCESS,
    payload: user
  };
}

export function fetchUserValueStories(id) {
  return (dispatch) => {
    dispatch(fetchValueStoriesLoading(true))

    let token = localStorage.getItem('jwtToken')

    return axios({
      method: 'post',
      url: fetchUserValueStoriesAPI,
      data: { Id: id },
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function fetchValueStoriesLoading(bool) {
  return {
    type: FETCH_VALUE_STORIES_LOADING,
    payload: bool
  }
}

export function fetchValueStoriesSuccess(response) {
  return {
    type: FETCH_VALUE_STORIES_SUCCESS,
    payload: response
  };
}

export function fetchValueStoriesFailure(response) {
  return {
    type: FETCH_VALUE_STORIES_FAILURE,
    payload: response
  };
}

export function addValueStory(formValues) {

  return (dispatch) => {

    dispatch(addValueStoryLoading(true))

    let token = localStorage.getItem('jwtToken')

    return axios({
      method: 'post',
      url: addValueStoryAPI,
      data: formValues,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(addValueStorySuccess())
      return response
    }).catch((error) => {
      //dispatch(valueStoryIsLoading(false))
      return error
    })
  }
}

export function addValueStoryLoading(bool) {
  return {
    type: ADD_VALUE_STORY_LOADING,
    payload: bool
  }
}

export function addValueStorySuccess(valueStory) {
  return {
    type: ADD_VALUE_STORY_SUCCESS,
    payload: valueStory
  };
}

export function addValueStoryFailure(valueStory) {
  return {
    type: ADD_VALUE_STORY_FAILURE,
    payload: valueStory
  };
}

export function updateValueStory(formValues) {

  return (dispatch) => {

    dispatch(updateValueStoryLoading(true))

    let token = localStorage.getItem('jwtToken')

    return axios({
      method: 'post',
      url: updateValueStoryAPI,
      data: formValues,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(updateValueStorySuccess())
      return response
    }).catch((error) => {
      //dispatch(valueStoryIsLoading(false))
      return error
    })
  }
}

export function updateValueStoryLoading(bool) {
  return {
    type: UPDATE_VALUE_STORY_LOADING,
    payload: bool
  }
}

export function updateValueStorySuccess(valueStory) {
  return {
    type: UPDATE_VALUE_STORY_SUCCESS,
    payload: valueStory
  };
}

export function updateValueStoryFailure(valueStory) {
  return {
    type: UPDATE_VALUE_STORY_FAILURE,
    payload: valueStory
  };
}

export function openValueStory(VSId, Id) {
  return (dispatch) => {
    dispatch(valueStoryIsLoading(true))

    let token = localStorage.getItem('jwtToken')

    return axios({
      method: 'post',
      data: { VSId: VSId },
      url: getValueStoryAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(valueStoryIsLoading(false))
      dispatch(isNewValueStory(false))
      return response
    }).catch((error) => {
      dispatch(valueStoryIsLoading(false))
      return error
    })
  }
}

export function openValueStorySuccess() {
  return {
    type: OPEN_VALUE_STORY_SUCCESS,
    payload: null
  };
}

export function isSharedValueStoryOpen(VSId, Id) {
  return (dispatch) => {
    dispatch(valueStoryIsLoading(true))

    let token = localStorage.getItem('jwtToken')

    return axios({
      method: 'post',
      data: { VSId: VSId },
      url: isSharedValueStoryOpenAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(valueStoryIsLoading(false))
      return response
    }).catch((error) => {
      dispatch(valueStoryIsLoading(false))
      return error
    })
  }
}

export function openValueStoryFailure(error) {
  return {
    type: OPEN_VALUE_STORY_FAILURE,
    payload: error
  };
}

export function deleteValueStory(formValues) {
  return (dispatch) => {

    dispatch(deleteValueStoryLoading(true))

    let token = localStorage.getItem('jwtToken')

    return axios({
      method: 'post',
      data: formValues,
      url: deleteValueStoryAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(deleteValueStoryLoading(false))
      return response
    }).catch((error) => {
      dispatch(deleteValueStoryLoading(false))
      return error
    })
  }
}

export function deleteValueStoryLoading(bool) {
  return {
    type: DELETE_VALUE_STORY_LOADING,
    payload: bool
  }
}

export function deleteValueStorySuccess(message) {
  return {
    type: DELETE_VALUE_STORY_SUCCESS,
    payload: message
  };
}

export function deleteValueStoryFailure(error) {
  return {
    type: DELETE_VALUE_STORY_FAILURE,
    payload: error
  };
}

export function saveAs(formValues, id) {

  return (dispatch) => {
    dispatch(saveAsLoading(true))

    let token = localStorage.getItem('jwtToken')

    return axios({
      method: 'post',
      url: saveAsAPI + id,
      data: formValues,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).catch((error) => {
      return error
    })
  }
}

export function saveAsLoading(bool) {
  return {
    type: SAVE_AS_LOADING,
    payload: bool
  }
}

export function saveAsSuccess(valueStory) {
  return {
    type: SAVE_AS_SUCCESS,
    payload: valueStory
  };
}

export function saveAsFailure(error) {
  return {
    type: DELETE_VALUE_STORY_FAILURE,
    payload: error
  };
}

export function forgotPassword(formValues) {
  return (dispatch) => {
    dispatch(forgotPasswordLoading(true))

    return axios.post(forgotPasswordAPI, formValues)
  }
}

export function forgotPasswordLoading(bool) {
  return {
    type: FORGOT_PASSWORD_LOADING,
    payload: bool
  }
}

export function forgotPasswordSuccess(message) {
  return {
    type: FORGOT_PASSWORD_SUCCESS,
    payload: message
  };
}

export function forgotPasswordFailure(error) {
  return {
    type: FORGOT_PASSWORD_FAILURE,
    payload: error
  };
}



export function resetPassword(email) {
  return (dispatch) => {
    dispatch(resetPasswordLoading(true))

    return axios.post(resetPasswordAPI, email)
  }
}

export function fetchUserDetails(email) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: { "Email": email},
      url: userDetailsAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function resetPasswordLoading(bool) {
  return {
    type: RESET_PASSWORD_LOADING,
    payload: bool
  }
}

export function resetPasswordSuccess(message) {
  return {
    type: RESET_PASSWORD_SUCCESS,
    payload: message
  };
}

export function resetPasswordFailure(error) {
  return {
    type: RESET_PASSWORD_FAILURE,
    payload: error
  };
}

export function fetchUserDetailsSuccess(user) {
  return {
    type: FETCH_USER_DETAILS_SUCCESS,
    payload: user
  };
}

export function changePassword(formValues) {
  return (dispatch) => {
    dispatch(changePasswordLoading(true))

    let token = localStorage.getItem('jwtToken')

    return axios({
      method: 'post',
      url: changePasswordAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      },
      data: formValues
    }).catch((error) => {
      //console.log('resetPassword',error)
      return error;
    })
  }
}

export function changePasswordLoading(bool) {
  return {
    type: CHANGE_PASSWORD_LOADING,
    payload: bool
  }
}

export function changePasswordSuccess(bool) {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
    payload: bool
  };
}

export function changePasswordFailure(error) {
  return {
    type: CHANGE_PASSWORD_FAILURE,
    payload: error
  };
}

export function autoSave(ValueStory, isBusinessCaseAccess) {
  return (dispatch) => {

    let token = localStorage.getItem('jwtToken')

    return axios({
      method: 'post',
      url: isBusinessCaseAccess ? saveValueStoryAPI: trackLastModifiedDateTimeAPI,
      data: ValueStory,
      headers: {
        'Authorization': `AribaBearer ${token}`,
        'Access-Control-Allow-Origin': `${ROOT_URL}`
      }
    }).catch((error) => {
      return error
    })
  }
}

export function valueStoryIsSaving(bool) {
  return {
    type: VALUE_STORY_IS_SAVING,
    payload: bool
  }
}

export function autoSaveSuccess(message) {
  return {
    type: AUTO_SAVE_SUCCESS,
    payload: message
  };
}

export function autoSaveFailure(error) {
  return {
    type: AUTO_SAVE_FAILURE,
    payload: error
  };
}

export function updateOnlineStatus(status) {
  return {
    type: UPDATE_ONLINE_STATUS,
    payload: status
  };
}

export function reportSubmit(formValues) {
  return (dispatch) => {

    dispatch(reportSubmitLoading())

    let token = localStorage.getItem('jwtToken')

    return axios({
      method: 'post',
      url: reportAPI,
      data: formValues,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      //console.log('User action response',response)   
      return response
    }).catch((error) => {
      //console.log('reportSubmit',error)   
      return error
    })
  }
}

export function replacePlaceholders(formValues, fileName) {
  return (dispatch) => {

    dispatch(reportSubmitLoading())

    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: replacePlaceHoldersAPI + fileName,
      data: formValues,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      //console.log('User action response',response)   
      return response
    }).catch((error) => {
      //console.log('reportSubmit',error)   
      return error
    })
  }
}

export function downloadReport(formValues, fileName) {
  return (dispatch) => {

    dispatch(reportSubmitLoading())

    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: downloadReportAPI + fileName,
      data: formValues,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      //console.log('User action response',response)   
      return response
    }).catch((error) => {
      //console.log('reportSubmit',error)   
      return error
    })
  }
}

export function reportSubmitLoading() {
  return {
    type: REPORT_SUBMIT_LOADING,
    payload: null
  };
}

export function reportSubmitSuccess(message) {
  return {
    type: REPORT_SUBMIT_SUCCESS,
    payload: message
  }
}

export function reportSubmitFailure(error) {
  return {
    type: REPORT_SUBMIT_FAILURE,
    payload: error
  };
}

export function getToken(username) {
  return (dispatch) => {
    // dispatch(forgotPasswordLoading(true))
    return axios.get(getTokenAPI + username)
  }
}

export function loadUserFromToken(username) {
  let token = localStorage.getItem('jwtToken')
  if (!token || token === '') { //if there is no token, dont bother
    return
  }

  //fetch user from token (if server deems it’s valid token)
  return (dispatch) => {
    dispatch(meFromToken(token, username))
  }
}

export function resetError() {
  return {
    type: RESET_ERROR,
    payload: null
  };
}

export function resetMessage() {
  return {
    type: RESET_MESSAGE,
    payload: null
  };
}

export function updateLanguage(lang) {
  return {
    type: UPDATE_LANGUAGE,
    lang
  };
}

export function userDetailsLoading(bool) {
  return {
    type: USER_DETAILS_LOADING,
    payload: bool
  }
}

export function userDetailsSuccess(response) {
  return {
    type: USER_DETAILS_SUCCESS,
    payload: response
  };
}

export function userDetailsFailure(error) {
  return {
    type: USER_DETAILS_FAILURE,
    payload: error
  };
}

export function userConsentLoading(bool) {
  return {
    type: USER_CONSENT_LOADING,
    payload: bool
  }
}

export function userConsentSuccess(response) {
  return {
    type: USER_CONSENT_SUCCESS,
    payload: response
  };
}

export function userConsentFailure(error) {
  return {
    type: USER_CONSENT_FAILURE,
    payload: error
  };
}



export function newsletterAcceptanceLoading(bool) {
  return {
    type: NEWSLETTER_ACCEPTANCE_LOADING,
    payload: bool
  }
}

export function newsletterAcceptanceSuccess(response) {
  return {
    type: NEWSLETTER_ACCEPTANCE_SUCCESS,
    payload: response
  };
}

export function newsletterAcceptanceFailure(error) {
  return {
    type: NEWSLETTER_ACCEPTANCE_FAILURE,
    payload: error
  };
}

// export function fetchAllUsers() {
//   return (dispatch) => {
//     dispatch(userDetailsLoading(true))
//     return axios({
//       method: 'post',
//       url: fetchAllUsersAPI
//     }).then((response) => {
//       return response
//     })
//   }

// }

export function fetchAllUsers() {
  return (dispatch) => {
    dispatch(userDetailsLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: fetchAllUsersAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function activateUser(formValues) {
  return (dispatch) => {
    dispatch(userDetailsLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: activateUserAPI,
      data: formValues,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function domainDetails() {
  return (dispatch) => {
    dispatch(domainDetailsLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: domainDetailsAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(domainDetailsSuccess(response))
      return response
    })
  }
}

export function domainDetailsLoading(bool) {
  return {
    type: DOMAIN_DETAILS_LOADING,
    payload: bool
  }
}

export function domainDetailsSuccess(response) {
  return {
    type: DOMAIN_DETAILS_SUCCESS,
    payload: response
  };
}

export function domainDetailsFailure(error) {
  return {
    type: DOMAIN_DETAILS_FAILURE,
    payload: error
  };
}

export function deleteDomain(id) {
  let token = localStorage.getItem('jwtToken')
  return (dispatch) => {
    dispatch(domainDetailsLoading(true))
    return axios({
      method: 'post',
      url: deleteDomainAPI,
      data: { "WDId": id },
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(domainDetailsSuccess(response))
      return response
    })
  }
}

export function addDomain(domainName) {
  return (dispatch) => {
    dispatch(domainDetailsLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: addDomainAPI,
      data: { "WDomains": domainName.name },
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(domainDetailsSuccess(response))
      return response
    })
  }
}

export function updateDomain(doaminDetail) {
  return (dispatch) => {
    dispatch(domainDetailsLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: updateDomainAPI,
      data: { "WDId": doaminDetail.id, "WDomains": doaminDetail.name },
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(domainDetailsSuccess(response))
      return response
    })
  }
}

export function blockedDomainDetails() {
  return (dispatch) => {
    dispatch(blockedDomainDetailsLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: blockedDomainDetailsAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(blockedDomainDetailsSuccess(response))
      return response
    })
  }
}

export function blockedDomainDetailsLoading(bool) {
  return {
    type: BLOCKED_DOMAIN_DETAILS_LOADING,
    payload: bool
  }
}

export function blockedDomainDetailsSuccess(response) {
  return {
    type: BLOCKED_DOMAIN_DETAILS_SUCCESS,
    payload: response
  };
}

export function blockedDomainDetailsFailure(error) {
  return {
    type: BLOCKED_DOMAIN_DETAILS_FAILURE,
    payload: error
  };
}

export function deleteBlockedDomain(id) {
  return (dispatch) => {
    dispatch(blockedDomainDetailsLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: deleteBlockedDomainAPI,
      data: { "BDId": id },
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(blockedDomainDetailsSuccess(response))
      return response
    })
  }
}

export function addBlockedDomain(domainName) {
  return (dispatch) => {
    dispatch(blockedDomainDetailsLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: addBlockedDomainAPI,
      data: { "BDomains": domainName.name },
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(blockedDomainDetailsSuccess(response))
      return response
    })
  }
}

export function updateBlockedDomain(doaminDetail) {
  return (dispatch) => {
    dispatch(blockedDomainDetailsLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: updateBlockedDomainAPI,
      data: { "BDId": doaminDetail.id, "BDomains": doaminDetail.name },
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(blockedDomainDetailsSuccess(response))
      return response
    })
  }
}

export function setPassword(formValues) {
  return (dispatch) => {
    dispatch(userDetailsLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: formValues,
      url: setPasswordAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function fetchReports() {
  return (dispatch) => {
    dispatch(userDetailsLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: reportsAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(userDetailsSuccess(response))
      return response
    })
  }
}

export function fetchFiles() {
  return (dispatch) => {
    dispatch(userDetailsLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: filesAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(userDetailsSuccess(response))
      return response
    })
  }
}

export function fetchSections() {
  return (dispatch) => {
    dispatch(userDetailsLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: sectionsAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(userDetailsSuccess(response))
      return response
    })
  }
}

export function fetchSlides() {
  return (dispatch) => {
    dispatch(userDetailsLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: slidesAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(userDetailsSuccess(response))
      return response
    })
  }
}

// export function addNewReportAsyn(reportDetails){
//    addReport(reportDetails);
// }

// async function nodeApiAddreport(reportDetails){
//  const _res =  await (manipulateReport(reportDetails)) 
//  return _res;
// };

export function addReport(reportDetails) {
  return (dispatch) => {
    dispatch(userDetailsLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: addReportAPI,
      data: { "Name": reportDetails.name, "AddedBy": reportDetails.User, "Description": reportDetails.description, "LayoutFile": reportDetails.layout, "SizeFile": reportDetails.size, "Active": reportDetails.active },
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      reportDetails.Id = response.data.Result.Id;
      reportDetails.Name = reportDetails.name;
      reportDetails.User = localStorage.id;
      reportDetails.Active = response.data.Result.Active;
      reportDetails.OperationName = "NewReport";
      return dispatch(manipulateReport(reportDetails));

      dispatch(userDetailsSuccess(response))
    })
  }
}

export function addFile(reportName) {
  return (dispatch) => {
    dispatch(userDetailsLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: addFileAPI,
      data: { "FileName": reportName.name, "AddedBy": reportName.User },
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(userDetailsSuccess(response))
      return response
    })
  }
}

export function addSection(section) {
  return (dispatch) => {
    dispatch(userDetailsLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: addSectionAPI,
      data: { "SectionName": section.name, "Description": section.desc, "Language": section.Language, "AddedBy": section.User, "FileID": section.FileID },
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(userDetailsSuccess(response))
      return response
    })
  }
}

export function addSlide(slide) {
  return (dispatch) => {
    dispatch(userDetailsLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: addSlideAPI,
      data: { "SlideName": slide.name, "AddedBy": slide.User, "FileID": slide.FileParentId, "SectionID": slide.SectionParentId },
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(userDetailsSuccess(response))
      return response
    })
  }
}

export function deleteReport(formValues) {
  return (dispatch) => {
    dispatch(userDetailsLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: deleteReportAPI,
      data: formValues,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      formValues.User = localStorage.id;
      formValues.OperationName = "ActiveInactiveReport";
      formValues.Id = formValues.ID;
      formValues.Active = false;
      return dispatch(manipulateReport(formValues));
      dispatch(userDetailsSuccess(response))
    });
  }
}

export function deleteFile(formValues) {
  return (dispatch) => {
    dispatch(userDetailsLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: formValues,
      url: deleteFileAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function deleteSection(formValues) {
  return (dispatch) => {
    dispatch(userDetailsLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: formValues,
      url: deleteSectionAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function deleteSlide(formValues) {
  return (dispatch) => {
    dispatch(userDetailsLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: formValues,
      url: deleteSlideAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function updateReport(formValues) {
  return (dispatch) => {
    dispatch(userDetailsLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: updateReportAPI,
      data: {
        "Id": formValues.id, "Name": formValues.name, "UpdatedBy": formValues.UpdatedBy,
        "Description": formValues.description, LayoutFile: formValues.layout, SizeFile: formValues.size
      },
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {

      if (formValues.oldReportName === undefined) {
        formValues.oldReportName = formValues.name;
      }
      formValues.Id = formValues.id;
      formValues.Name = formValues.name;
      formValues.User = localStorage.id;
      formValues.OperationName = "UpdateReport";
      return dispatch(manipulateReport(formValues));

      dispatch(userDetailsSuccess(response))
      return response
    })
  }
}

export function updateFile(formValues) {
  return (dispatch) => {
    dispatch(userDetailsLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: updateFileAPI,
      data: { "ID": formValues.id, "FileName": formValues.name, "UpdatedBy": formValues.UpdatedBy },
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(userDetailsSuccess(response))
      return response
    })
  }
}

export function updateSection(formValues) {
  return (dispatch) => {
    dispatch(userDetailsLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: updateSectionAPI,
      data: { "ID": formValues.Id, "SectionName": formValues.SectionName, "Description": formValues.Description, "Language": formValues.Language, "FileID": formValues.ParentId, "UpdatedBy": formValues.UpdatedBy },
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(userDetailsSuccess(response))
      return response
    })
  }
}

export function updateSlide(formValues) {
  return (dispatch) => {
    dispatch(userDetailsLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: updateSlideAPI,
      data: { "ID": formValues.Id, "SlideName": formValues.SlideName, "UpdatedBy": formValues.UpdatedBy, "SectionID": formValues.SectionParentId },
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(userDetailsSuccess(response))
      return response
    })
  }
}

export function fetchSlideNames() {
  return (dispatch) => {
    dispatch(userDetailsLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: slideNamesAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(userDetailsSuccess(response))
      return response
    })
  }
}

export function fetchMappings() {
  return (dispatch) => {
    dispatch(userDetailsLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: mappingsAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(userDetailsSuccess(response))
      return response
    })
  }
}

export function updateSequence(reportName) {
  return (dispatch) => {
    dispatch(userDetailsLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: addSequenceAPI,
      data: { ParentId: reportName[0].ReportID, AddedBy: reportName[0].User, obj: reportName },
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(userDetailsSuccess(response))
      return response
    })
  }
}

export function delSequence(reportName) {
  return (dispatch) => {
    dispatch(userDetailsLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: delSequenceAPI,
      data: { "Id": reportName.Id, "UpdatedBy": reportName.User, "ParentId": reportName.filterId },
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(userDetailsSuccess(response))
      return response
    })
  }
}

export function validateAll() {
  return (dispatch) => {
    dispatch(userDetailsLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: validateAllAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

//this was used for index manager page which is no longer in use.
export function addDescription(section) {
  return (dispatch) => {
    dispatch(userDetailsLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: addDescriptionAPI,
      data: { "SectionName": section.name, "Description": section.desc, "Language": section.Language, "AddedBy": section.User, "FileID": section.FileID, Id: section.Id },
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(userDetailsSuccess(response))
      return response
    })
  }
}

export function updateDescription(section) {
  return (dispatch) => {
    dispatch(userDetailsLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: updateDescriptionAPI,
      data: { "SectionName": section.name, "Description": section.desc, "Language": section.Language, "AddedBy": section.User, "FileID": section.FileID, Id: section.Id },
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(userDetailsSuccess(response))
      return response
    })
  }
}

export function checkConsent(formValues) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: consentAPI,
      data: formValues,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      if (response.status !== 200) {
        dispatch(userConsentFailure(response))
      }
      dispatch(userConsentSuccess(response))
      return response
    })
  }
}

export function checkNewsLetterAcceptance(formValues){
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: checkNewsletterAcceptanceAPI,
      data: formValues,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      if (response.status !== 200) {
        dispatch(newsletterAcceptanceFailure(response))
      }
      dispatch(newsletterAcceptanceSuccess(response))
      return response
    })
  }
}

export function NewsletterAcceptanceUpdate(formValues) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: newsletterAcceptanceAPI,
      data: formValues,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      if (response.status !== 200) {
        dispatch(newsletterAcceptanceFailure(response))
      }
      dispatch(newsletterAcceptanceSuccess(response))
      return response
    })
  }
}

export function updateConsent(formValues) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: updateConsentAPI,
      data: formValues,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      if (response.status !== 200) {
        dispatch(userConsentFailure(response))
      }
      dispatch(userConsentSuccess(response))
      return response
    })
  }
}

export function UpdateAcknowledgement(formValues) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: UpdateAcknowledgementAPI,
      data: formValues,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      if (response.status !== 200) {
        dispatch(userConsentFailure(response))
      }
      dispatch(userConsentSuccess(response))
      return response
    })
  }
}


export function notificationManagerLoading(bool) {
  return {
    type: NOTIFICATION_MANAGER_LOADING,
    payload: bool
  }
}


export function notificationManagerSuccess(message) {
  return {
    type: NOTIFICATION_MANAGER_SUCCESS,
    payload: message
  };
}

export function notificationManagerFailure(error) {
  return {
    type: NOTIFICATION_MANAGER_FAILURE,
    payload: error
  };
}

export function getReleaseFeatures() {
  return (dispatch) => {
    dispatch(notificationManagerLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'get',
      url: getReleaseFeaturesAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function addReleaseFeature(data) {
  return (dispatch) => {
    dispatch(notificationManagerLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: `${ROOT_URL}/Admin/AddReleaseFeature`,
      data: { ReleaseVersionID: 0, ReleaseFeatureID: 0, ReleaseVersionName: data.ReleaseVersionName, ReleaseFeatureName: data.ReleaseFeatureName, ReleaseFeatureDescription: data.ReleaseFeatureDescription, AddedBy: data.AddedBy, UpdatedBy: data.UpdatedBy },
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(notificationManagerSuccess(response))
      return response
    })
  }
}

export function deleteReleaseFeature(data) {
  return (dispatch) => {
    dispatch(notificationManagerLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: `${ROOT_URL}/Admin/DeleteReleaseFeature`,
      data: { ReleaseVersionID: data.ReleaseVersionID, ReleaseFeatureID: data.ReleaseFeatureID, UpdatedBy: data.UpdatedBy },
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(notificationManagerSuccess(response))
      return response
    })
  }
}

export function updateReleaseFeature(data) {
  return (dispatch) => {
    dispatch(notificationManagerLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: `${ROOT_URL}/Admin/UpdateReleaseFeature`,
      data: { ReleaseVersionID: data.vid, ReleaseFeatureID: data.id, ReleaseFeatureName: data.name, ReleaseFeatureDescription: data.desc, UpdatedBy: data.UpdatedBy },
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(notificationManagerSuccess(response))
      return response
    })
  }
}


//PublishUnpublishReleaseVersion
export function publishUnpublishReleaseVersion(data) {
  return (dispatch) => {
    dispatch(notificationManagerLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: `${ROOT_URL}/Admin/PublishUnpublishReleaseVersion`,
      data: { ReleaseVersionName: data.ReleaseVersionName, UpdatedBy: data.UpdatedBy, VersionPublishedBy: data.VersionPublishedBy, VersionUnpublishedBy: data.VersionUnpublishedBy },
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(notificationManagerSuccess(response))
      return response
    })
  }
}

//GetLatestPublishedReleaseFeatures

export function getLatestPublishedReleaseFeatures() {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    dispatch(notificationManagerLoading(true))
    return axios({
      method: 'get',
      url: `${ROOT_URL}/Admin/GetLatestPublishedReleaseFeatures`,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      //dispatch(notificationManagerSuccess(response))
      return response
    })
  }
}

//CheckUserConfirmation
export function checkUserConfirmation(data) {
  return (dispatch) => {
    dispatch(notificationManagerLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: `${ROOT_URL}/Admin/CheckUserConfirmation`,
      data: { ReleaseVersionID: data.ReleaseVersionId, UserID: data.UserID },
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(notificationManagerSuccess(response))
      return response
    })
  }
}

//UpdateUserConfirmation
export function updateUserConfirmation(data) {
  return (dispatch) => {
    dispatch(notificationManagerLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: `${ROOT_URL}/Admin/UpdateUserConfirmation`,
      data: { ReleaseVersionID: data.ReleaseVersionId, UserID: data.UserID, IsConfirmed: data.IsConfirmed },
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(notificationManagerSuccess(response))
      return response
    })
  }
}
export function LanguageTranslationLoading(response) {
  return {
    type: LANGUAGE_TRANSLATION_CONSENT_LOADING,
    payload: response
  };
}

export function fetchLayoutFiles() {
  return (dispatch) => {
    dispatch(userDetailsLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: layoutAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(userDetailsSuccess(response))
      return response
    })
  }
}

export function fetchSizeFiles() {
  return (dispatch) => {
    dispatch(userDetailsLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: sizeAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(userDetailsSuccess(response))
      return response
    })
  }
}

export function addLayoutFiles(layoutFileName) {
  return (dispatch) => {
    dispatch(userDetailsLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: addLayoutFilesAPI,
      data: { "Name": layoutFileName.name, "AddedBy": layoutFileName.User, "Active": layoutFileName.active },
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(userDetailsSuccess(response))
      return response
    })
  }
}

export function addSizeFiles(sizeFileName) {
  return (dispatch) => {
    dispatch(userDetailsLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: addSizeFilesAPI,
      data: { "Name": sizeFileName.name, "AddedBy": sizeFileName.User, "Active": sizeFileName.active },
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(userDetailsSuccess(response))
      return response
    })
  }
}

export function deleteLayoutFiles(formValues) {
  return (dispatch) => {
    dispatch(userDetailsLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: formValues,
      url: deleteLayoutAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function deleteSizeFiles(formValues) {
  return (dispatch) => {
    dispatch(userDetailsLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: formValues,
      url: deleteSizeAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function activateReport(formValues) {
  return (dispatch) => {
    dispatch(userDetailsLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: formValues,
      url: activateReportAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      formValues.User = localStorage.id;
      formValues.OperationName = "ActiveInactiveReport";
      formValues.description = formValues.Description;
      formValues.Id = formValues.ID;
      formValues.Active = formValues.Active;
      return dispatch(manipulateReport(formValues));
      dispatch(userDetailsSuccess(response))
    });
  }
}
//GetReleaseVersions
export function getReleaseVersions() {
  return (dispatch) => {
    dispatch(notificationManagerLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'get',
      url: `${ROOT_URL}/Admin/GetReleaseVersions`,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(notificationManagerSuccess(response))
      return response
    })
  }
}

export function shareValueStoryLoading(bool) {
  return {
    type: SHARE_VALUESTORY_LOADING,
    payload: bool
  }
}

export function shareValueStorySuccess(message) {
  return {
    type: SHARE_VALUESTORY_SUCCESS,
    payload: message
  };
}

export function shareValueStoryFailure(error) {
  return {
    type: SHARE_VALUESTORY_FAILURE,
    payload: error
  };
}

export function shareValueStory(formValues) {
  return (dispatch) => {
    dispatch(shareValueStoryLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: shareValueStoryAPI,
      data: formValues,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      return response
    })
  }
}

export function getSharedValueStories(VSId) {
  return (dispatch) => {
    dispatch(shareValueStoryLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: { "VSId": VSId },
      url: getSharedValueStoriesAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(shareValueStorySuccess(false))
      return response
    }).catch((error) => {
      return error
    })
  }
}

export function periodicEmailConfirmation(formValues) {
  return (dispatch) => {
    dispatch(periodicEmailConfirmationLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: { "Email": formValues.Email, "Language": formValues.Language},
      url: periodicValidationPartnersUsersAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      return response
    }).catch((error) => {
    })
  }
}

export function periodicEmailConfirmationLoading(bool) {
  return {
    type: FORGOT_PASSWORD_LOADING,
    payload: bool
  }
}

export function periodicEmailConfirmationSuccess(message) {
  return {
    type: FORGOT_PASSWORD_SUCCESS,
    payload: message
  };
}

export function periodicEmailConfirmationFailure(error) {
  return {
    type: FORGOT_PASSWORD_FAILURE,
    payload: error
  };
}

export function PeriodicValidationPartnersUsers(formValues) {
  return (dispatch) => {
    dispatch(periodicEmailConfirmationLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: formValues,
      url: periodicValidationPartnersUsersAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    })
  }
}
export function toggleValueStoryShare(VSId, sharedWith, sharedBy, isActive, language, allowEdit) {
  return (dispatch) => {
    dispatch(shareValueStoryLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: { VSId: VSId, SharedWith: sharedWith, SharedBy: sharedBy, Active: isActive, Language: language, AllowEdit: allowEdit },
      url: toggleValueStoryShareAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(shareValueStorySuccess(false))
      return response
    })
    // .catch((error) => {
    //   return error
    // })
  }
}

//Start File Upload
export function uploadFileLoading(bool) {
  return {
    type: 'UPLOAD_FILE_LOADING',
    payload: bool
  }
}

export function benchMarkLoading(bool) {
  return {
    type: BENCHMARK_LOADING,
    payload: bool
  }
}
export function uploadFileSuccess({ message }) {
  return {
    type: 'UPLOAD_FILE_SUCCESS',
    message,
  };
}

export function uploadFileFailure(error) {
  return {
    type: 'UPLOAD_FILE_FAILURE',
    error,
  };
}

export function uploadExcelFileRequestToServer(file, name, tableName, userName) {
  return (dispatch) => {
    dispatch(uploadFileLoading(true));
    let token = localStorage.getItem('jwtToken');

    let formData = new FormData();
    formData.append('File', file);
    formData.append('FileName', name);
    formData.append('TableName', tableName);
    formData.append('UserName', userName);
    const options = {
      'Authorization': `AribaBearer ${token}`
    }
    return axios({
      method: 'post',
      data: formData,
      dataType: "JSON",
      url: uploadFileAPI,
      headers: options
    }).then((response) => {
      dispatch(uploadFileLoading(false));
      return response
    }).catch((error) => {
      return error
    })
  }
}
//End File Upload
export function benchMarkSuccess(message) {
  return {
    type: BENCHMARK_SUCCESS,
    payload: message
  };
}

export function benchMarkFailure(error) {
  return {
    type: BENCHMARK_FAILURE,
    payload: error
  };
}

export function getBenchMarkDetails() {
  return (dispatch) => {
    dispatch(benchMarkLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      url: getBenchMarkDetailsAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(benchMarkSuccess(response))
      return response
    })
  }
}

export function addBenchMark(benchMarkDetails) {
  return (dispatch) => {
    dispatch(benchMarkLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: {
        Suggested_Value_Description: benchMarkDetails.Suggested_Value_Description,
        Region_Country: benchMarkDetails.Region_Country,
        Industry: benchMarkDetails.Industry,
        Laggard_Value: benchMarkDetails.Laggard_Value,
        Average_Value: benchMarkDetails.Average_Value,
        Best_Value: benchMarkDetails.Best_Value,
        UOM: benchMarkDetails.UOM,
        Source_ID: benchMarkDetails.Source_ID,
        Source_Description: benchMarkDetails.Source_Description,
        Source: benchMarkDetails.Source,
        Notes: benchMarkDetails.Notes,
        Application: benchMarkDetails.Application,
        UpdatedBy: benchMarkDetails.UpdatedBy
      },
      url: addBenchMarkAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(benchMarkSuccess(response))
      return response
    })
  }
}

export function updateBenchMark(benchMarkDetails) {
  return (dispatch) => {
    dispatch(benchMarkLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: {
        ID: benchMarkDetails.ID,
        Suggested_Value_Description: benchMarkDetails.Suggested_Value_Description,
        Region_Country: benchMarkDetails.Region_Country,
        Industry: benchMarkDetails.Industry,
        Laggard_Value: benchMarkDetails.Laggard_Value,
        Average_Value: benchMarkDetails.Average_Value,
        Best_Value: benchMarkDetails.Best_Value,
        UOM: benchMarkDetails.UOM,
        Source_ID: benchMarkDetails.Source_ID,
        Source_Description: benchMarkDetails.Source_Description,
        Source: benchMarkDetails.Source,
        Notes: benchMarkDetails.Notes,
        Application: benchMarkDetails.Application,
        Active: benchMarkDetails.Active,
        UpdatedBy: benchMarkDetails.UpdatedBy
      },
      url: updateBenchMarkAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(benchMarkSuccess(response))
      return response
    })
  }
}

export function deActiveBenchMark(benchMarkDetails) {
  return (dispatch) => {
    dispatch(benchMarkLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: {
        ID: benchMarkDetails.ID,
        UpdatedBy: benchMarkDetails.UpdatedBy
      },
      url: deActiveBenchMarkAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(benchMarkSuccess(response))
      return response
    })
  }
}

export function generateBenchMarkJSON(lang) {
  return (dispatch) => {
    dispatch(benchMarkLoading(true))
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: { "lang": lang },
      url: generateBenchMarkJSONAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(benchMarkSuccess(response))
      return response
    })
  }
}

export function fetchValueStories(value) {
  return (dispatch) => {
    dispatch(fetchValueStoriesLoading(true))

    let token = localStorage.getItem('jwtToken')

    return axios({
      method: 'post',
      url: fetchValueStoriesAPI,
      data: { Organization: value },
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function requestValueStoryLoading(bool) {
  return {
    type: REQUEST_VALUESTORY_LOADING,
    payload: bool
  }
}

export function requestValueStorySuccess(message) {
  return {
    type: REQUEST_VALUESTORY_SUCCESS,
    payload: message
  };
}

export function requestValueStoryFailure(error) {
  return {
    type: REQUEST_VALUESTORY_FAILURE,
    payload: error
  };
}

export function valueStoryCurrentMenu(menuItem) {
  return {
    type: VALUESTORY_MENU_ITEM,
    payload: menuItem
  };
}

export function showValueStoryMenu(showMenu) {
  return {
    type: 'SHOW_VALUE_STORY_MENU',
    payload: showMenu
  };
}

export function requestValueStory(originatorEmail, requesterEmail, VSID, language) {
  return (dispatch) => {
    //dispatch(requestValueStoryLoading(true))

    let token = localStorage.getItem('jwtToken')

    return axios({
      method: 'post',
      url: requestValueStoryAPI,
      data: {
        OriginatorEmail: originatorEmail,
        RequesterEmail: requesterEmail,
        VSID: VSID,
        Language: language
      },
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}


export function fetchRequestDetails(VSID, requesterEmail) {
  return (dispatch) => {
    //dispatch(requestValueStoryLoading(true))

    let token = localStorage.getItem('jwtToken')

    return axios({
      method: 'post',
      url: fetchRequestDetailsAPI,
      data: {
        VSID: VSID,
        RequesterEmail: requesterEmail
      },
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function fetchUserProfileLoading(bool) {
  return {
    type: FETCH_USER_PROFILE_LOADING,
    payload: bool
  }
}

export function fetchUserProfileSuccess(response) {
  return {
    type: FETCH_USER_PROFILE_SUCCESS,
    payload: response
  };
}

export function fetchUserProfileFailure(response) {
  return {
    type: FETCH_USER_PROFILE_FAILURE,
    payload: response
  };
}

export function fetchUserProfileRoles(response) {
  return {
    type: FETCH_USER_PROFILE_ROLES,
    payload: response
  }
}

export function fetchUserProfileData(id, email) {
  return (dispatch) => {
    dispatch(fetchValueStoriesLoading(true))

    let token = localStorage.getItem('jwtToken')

    return axios({
      method: 'post',
      url: userProfileAPI,
      data: { Id: id, EmailId: email },
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function uploadUserProfilePic(obj) {
  return (dispatch) => {
    dispatch(fetchValueStoriesLoading(true))

    let token = localStorage.getItem('jwtToken')

    return axios({
      method: 'post',
      url: uploadUserProfilePicAPI,
      data: { EmailId: obj.email, ProfileImage: obj.imgSrc },
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function updateUserName(obj) {
  return (dispatch) => {
    dispatch(fetchValueStoriesLoading(true))

    let token = localStorage.getItem('jwtToken')

    return axios({
      method: 'post',
      url: updateUserNameAPI,
      data: { EmailId: obj.email, Name: obj.name },
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function fetchUserPercentileData(id, email) {
  return (dispatch) => {
    dispatch(fetchValueStoriesLoading(true))

    let token = localStorage.getItem('jwtToken')

    return axios({
      method: 'post',
      url: userPercentileAPI,
      data: { Id: id, EmailId: email },
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

export function fetchUserSharedValueStories(id) {
  return (dispatch) => {
    dispatch(fetchValueStoriesLoading(true))

    let token = localStorage.getItem('jwtToken')

    return axios({
      method: 'post',
      url: fetchUserSharedValueStoriesAPI,
      data: { Id: id },
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    })
  }
}

//report bug
export function reportBug(data) {
  return (dispatch) => {

    dispatch(reportBugLoading(true))

    let token = localStorage.getItem('jwtToken')

    return axios({
      method: 'post',
      data: data,
      url: reportBugAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(reportBugLoading(false))
      return response
    }).catch((error) => {
      dispatch(reportBugLoading(false))
      return error
    })
  }
}

export function reportBugLoading(bool) {
  return {
    type: REPORT_BUG_LOADING,
    payload: bool
  }
}

export function reportBugSuccess(message) {
  return {
    type: REPORT_BUG_SUCCESS,
    payload: message
  };
}

export function reportBugFailure(error) {
  return {
    type: REPORT_BUG_FAILURE,
    payload: error
  };
}

export function shareFileOnJumphost(data) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: data,
      url: shareFileOnJumphostAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      return response
    })
  }
}

export function shareFilesOnJumphostLoading(bool) {
  return {
    type: SHAREFILESONJUMPHOST_LOADING,
    payload: bool
  }
}

export function shareFilesOnJumphostSuccess(message) {
  return {
    type: SHAREFILESONJUMPHOST_SUCCESS,
    payload: message
  };
}

export function shareFilesOnJumphostFailure(error) {
  return {
    type: SHAREFILESONJUMPHOST_FAILURE,
    payload: error
  };
}

export function pendingChanges(changes) {
  return {
    type: GENERIC_PENDING_CHANGES,
    payload: changes
  };
}

export function syncMenuAndButton(showMenu) {
  return {
    type: 'SYNC_MENU_AND_BUTTON',
    payload: showMenu
  };
}

export function setTabMenu(menu) {
  return {
    type: 'setTabMenu',
    payload: menu
  };
}

export function setSubTabMenu(menu) {
  return {
    type: 'setSubTabMenu',
    payload: menu
  };
}

export function checkPasswordValidity(formValues) {
  return (dispatch) => {
    return axios.post(checkPasswordAPI, formValues)
      .then((response) => {
        if (response.status !== 200) {
          dispatch(userConsentFailure(response))
        }

        dispatch(userConsentSuccess(response))
        return response
      })
  }
}

export function userMask(email) {
  return (dispatch) => {

    dispatch(userMaskLoading(true))

    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: { email: email },
      url: userMaskAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(userMaskLoading(false))
      return response
    }).catch((error) => {
      dispatch(userMaskLoading(false))
      return error
    })
  }
}

export function userMaskLoading(bool) {
  return {
    type: FETCH_USER_MASK_LOADING,
    payload: bool
  }
}

export function userMaskSuccess(message) {
  return {
    type: FETCH_USER_MASK_SUCCESS,
    payload: message
  };
}

export function userMaskFailure(error) {
  return {
    type: FETCH_USER_MASK_SUCCESS,
    payload: error
  };
}


export function fetchSystemReportingDetails(ExcelFileName,startDate,endDate) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      method: 'post',
      data: { ExcelFileName: ExcelFileName,StartDate : startDate , EndDate : endDate },
      url: fetchSystemReportingDetailsAPI,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      return response
    })
  }
}


export function updateIndustryDataInHana(formValues, DMCID) {

  return (dispatch) => {

    dispatch(updateValueStoryLoading(true))

    let token = localStorage.getItem('jwtToken')

    let UpdateIndutry = {ValueStoryDetails : formValues , DMC_Id : DMCID}

    return axios({
      method: 'post',
      url: updateIndustryDataInHanaAPI,
      data: UpdateIndutry,
      headers: {
        'Authorization': `AribaBearer ${token}`
      }
    }).then((response) => {
      dispatch(updateValueStorySuccess())
      return response
    }).catch((error) => {
      //dispatch(valueStoryIsLoading(false))
      return error
    })
  }
}
