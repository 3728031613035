import React from 'react'
import PropTypes from 'prop-types'

// Utils
import classnames from 'classnames'
import { round, formatThousands } from 'utils/Utils'

const Row = (props) => {
	let columns,
		arr = [],
		obj

	switch (props.index) {
		case 0:
		obj = props.totalInvestments
		break

		case 1:
		obj = props.totalBenefits
		break

		case 2:
		obj = props.netBenefits
		break

		case 3:
		obj = props.cumulativeBenefits
		break

		default:
		obj = [0, 0, 0, 0, 0]
		break
	}

	for (let i = 0; i < 5; i++) {
		arr.push(obj["Year" + (i + 1)])
	}

	columns = arr.map((data, i) => <td className={classnames({ 'text-orange': (props.index === 1), 'text-accent': (props.index === 0) })} key={i}>{props.currency} {data ? formatThousands(round(data, 2)) : 0} M</td>)

	return (
		<tr>
			<td className={classnames({ 'text-orange' : (props.index === 1), 'text-accent' : (props.index === 0) })}>{props.content}</td>
			{columns}
		</tr>
	)
}

const RowContainer = (props) => {
	return (
		<Row
			index={props.index}
			content={props.content}
			userData={props.userData}
			currency={props.currency}
			totalInvestments={props.totalInvestments}
			totalBenefits={props.totalBenefits}
			netBenefits={props.netBenefits}
			cumulativeBenefits={props.cumulativeBenefits}
		/>
	)
}

const SubLeverCalculationTableFooter = (props) => {
	return (
		<tfoot className="summary">
			{props.content.map((data, i) =>
				<RowContainer
					key={data}
					index={i}
					content={data}
					userData={props.userData}
					currency={props.currency}
					totalInvestments={props.totalInvestments}
					totalBenefits={props.totalBenefits}
					netBenefits={props.netBenefits}
					cumulativeBenefits={props.cumulativeBenefits}
				/>
			)}
		</tfoot>
	)
}

export default SubLeverCalculationTableFooter

SubLeverCalculationTableFooter.propTypes = {
	userData: PropTypes.array.isRequired,
	currency: PropTypes.string.isRequired,
	totalInvestments: PropTypes.object.isRequired,
	totalBenefits: PropTypes.object.isRequired,
	cumulativeBenefits: PropTypes.object.isRequired
}
