import React from 'react'
import { connect } from 'react-redux'
import { Control, Form, Errors, actions } from 'react-redux-form'
// UI
import Textfield from 'react-mdl/lib/Textfield'
import Button from 'UI/Button'
//import moment from 'moment'
import Select from 'react-select'
// Settings
import { validators } from 'Constants'
import { SelectField, Option } from 'react-mdl-extra'

import {
  fetchReports,
  fetchFiles,
  fetchSections,
  fetchSlides,
  addSlide,
  deleteSlide,
  updateSlide,
  validateAll,
  userDetailsSuccess,
  userDetailsFailure,
  userDetailsLoading,
  resetError
}
  from 'actions/userActions'

import { dropdownStyles } from 'react-select-css'

var options = [];
var section_options = [];


class SlideManager extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      reports: [],
      fileFilter: [],
      sections: [],
      slides: [],
      fileId: 0,
      sectionId: 0,
      mymessage: "",
      selectedOption: '',
      sectionOption: '',
      isSlideFileSelected: '',
      isSlideSectionSelected: ''
    }
  }

  addSection() { }

  componentWillMount() {
    section_options = [];
    this.loadFiles();
    this.loadSlides();
  }

  loadFiles() {
    this.props.fetchFiles()
      .then((response) => {
        var fileData = [];
        this.props.userDetailsSuccess(response.data.Message)
        for (var i = 0; i < response.data.Result.length; i++) {
          var obj = response.data.Result[i];
          fileData.push(obj);
        }
        this.setState({ fileFilter: fileData })

        var array = [];
        this.state.fileFilter.forEach((element) => {
          var obj = {};
          obj.label = element.Name;
          obj.value = element.Id;
          array.push(obj);
        });
        options = array;
      }).catch((error) => {
        if (error.stack.includes('Network Error')) {
          this.props.userDetailsFailure()
        }
      })

  }
  loadSections() {

    this.props.fetchSections()
      .then((response) => {
        var sectionData = [];
        this.props.userDetailsSuccess(response.data.Message)
        for (var i = 0; i < response.data.Result.length; i++) {
          var obj = response.data.Result[i];
          if (obj.ParentId == this.state.fileId) {
            sectionData.push(obj);
          }

        }
        this.setState({ sections: sectionData })
        var array = [];
        this.state.sections.forEach((element) => {
          var obj = {};
          obj.label = element.SectionName;
          obj.value = element.Id;
          array.push(obj);
        });
        section_options = array;
      }).catch((error) => {
        if (error.stack.includes('Network Error')) {
          this.props.userDetailsFailure()
        }
      })

  }

  loadSlides() {
    this.props.fetchSlides()
      .then((response) => {
        var slideData = [];
        this.props.userDetailsSuccess(response.data.Message)
        for (var i = 0; i < response.data.Result.length; i++) {
          var obj = response.data.Result[i];
          slideData.push(obj);
        }
        this.setState({ slides: slideData })
      }).catch((error) => {
        if (error.stack.includes('Network Error')) {
          this.props.userDetailsFailure()
        }
      })

  }

  changeSections() {
    this.props.fetchSlides()
      .then((response) => {
        var slideData = [];
        this.props.userDetailsSuccess(response.data.Message)
        for (var i = 0; i < response.data.Result.length; i++) {
          var obj = response.data.Result[i];
          if (obj.FileParentId == this.state.fileId) {
            slideData.push(obj);
          }
        }
        this.setState({ slides: slideData })
      }).catch((error) => {
        if (error.stack.includes('Network Error')) {
          this.props.userDetailsFailure()
        }
      })

  }

  changeSlides() {
    this.props.fetchSlides()
      .then((response) => {
        var slideData = [];
        this.props.userDetailsSuccess(response.data.Message)
        for (var i = 0; i < response.data.Result.length; i++) {
          var obj = response.data.Result[i];
          if (obj.FileParentId == this.state.fileId && obj.SectionParentId == this.state.sectionId) {
            slideData.push(obj);
          }
        }
        this.setState({ slides: slideData })
      }).catch((error) => {
        if (error.stack.includes('Network Error')) {
          this.props.userDetailsFailure()
        }
      })

  }
  handleChange(value) {
    var filter = value.label;
    this.state.fileFilter.map((file) => {
      if (filter == file.Name) {
        this.setState({ fileId: file.Id, selectedOption: filter })
      }
    })
    this.setState({ sectionId: 0 })
    this.loadSections();
    this.formDispatch(actions.reset('slideManager'))
    this.formDispatch(actions.reset('userManager'))
    this.changeSections();
  }

  handleSectionChange(value) {
    var filter = value.label;
    this.state.sections.map((secn) => {
      if (filter == secn.SectionName) {
        this.setState({ sectionId: secn.Id, sectionOption: filter })
        // this.loadSections();
      }
    })
    this.changeSlides();
  }

  onDDLSlideFileClickEvent = () => {
    this.setState({ isSlideFileSelected: 'Selected' });
    this.setState({ isSlideSectionSelected: '' });
  }

  onDDLSlideFileBlurEvent = () => {
    this.setState({ isSlideFileSelected: '' });
    this.setState({ isSlideSectionSelected: '' });
  }

  onDDLSlideSectionClickEvent = () => {
    this.setState({ isSlideSectionSelected: 'Selected' });
  }

  onDDLSlideSectionBlurEvent = () => {
    this.setState({ isSlideSectionSelected: '' });
  }

  addSlide(formValue) {
    this.props.resetError();
    var slideDetail = {
      name: formValue.SlideName,
      User: localStorage.getItem('loggedinUser'),
      FileParentId: this.state.fileId,
      SectionParentId: this.state.sectionId
    }
    var msg = this.props.content;
    //   var result = this.state.slides.find(slide => slide.SlideName == slideDetail.name && slide.FileParentId == slideDetail.FileParentId && slide.SectionParentId == slideDetail.SectionParentId) === undefined
    //  if(!result){
    //    this.props.userDetailsFailure('This section already exists in the file!')
    //  }
    //  else{
    if (slideDetail.FileParentId > 0 && slideDetail.SectionParentId > 0) {
      if (slideDetail.name !== "") {
        this.setState({ mymessage: "" })
        this.props.addSlide(slideDetail).then((results) => {
          if (results.status === 200) {
            var httpstatus = results.status
            var getMsgfromStatus = msg[httpstatus]
            // Reset form
            this.loadSlides();
            this.formDispatch(actions.reset('slideManager'))
            this.setState({ mymessage: getMsgfromStatus, selectedOption: "", sectionOption: "" })

          }
        }).catch((error) => {
          if (error.stack.includes('Network Error')) {
            this.setState({ mymessage: "" })
            this.props.userDetailsFailure('API service is down. Please contact administrator.')
          }
          else {
            this.setState({ mymessage: "" })
            this.props.userDetailsFailure(error.response.data.Message)
          }
        })
      } else {
        this.setState({ mymessage: "" })
        this.props.userDetailsFailure("Slide name must be provided!")
      }
    } else {
      this.setState({ mymessage: "" })
      this.props.userDetailsFailure("Please select the File & Section from dropdown in which you want to add slide")
    }
  }

  handleRowDel(sectionDetails) {
    const result = window.confirm(this.props.validation.confirmDelete);
    if (result) {
      var formValues = { SlideName: sectionDetails.slideName, SectionID: sectionDetails.sectionId };
      var msg = this.props.content.rowDel[0];
      this.setState({ mymessage: "" })
      this.props.deleteSlide(formValues)
        .then((response) => {
          this.props.userDetailsSuccess(response.data.Message)
          if (response.status === 200) {
            var httpstatus = response.status
            var getMsgfromStatus = msg[httpstatus]
            this.setState({ mymessage: getMsgfromStatus })
          }
          this.loadSlides();
          this.formDispatch(actions.reset('slideManager'))
        }).catch((error) => {
          if (error.stack.includes('Network Error')) {
            this.setState({ mymessage: "" })
            this.props.userDetailsFailure(this.props.validation.networkError)
          }
          else {
            var httpstatus = error.response.status
            var getMsgfromStatus = msg[httpstatus]
            if (getMsgfromStatus !== undefined) {
              this.setState({ mymessage: "" })
              this.props.userDetailsFailure(getMsgfromStatus)
            }
            else {
              this.setState({ mymessage: "" })
              this.props.userDetailsFailure(error.response.data.Message)
            }
          }
        })
    }
  };

  handleEditEvent(evt) {
    var item = {
      name: evt.slideName,
      id: evt.id,
      isEditing: evt.isEditing
    };
    var old_sectionDetails = this.state.slides.slice();
    var newsectionDetails = old_sectionDetails.map(function (testsectionDetails) {
      for (var key in testsectionDetails) {
        if (testsectionDetails.Id == item.id) {
          testsectionDetails.SlideName = item.name;
          testsectionDetails.isEditing = !item.isEditing;

          if (evt.UpdatedBy !== undefined) {
            testsectionDetails.UpdatedBy = evt.UpdatedBy;
            testsectionDetails.UpdatedOn = evt.UpdatedOn;
          }
          break;
        }
      }
      return testsectionDetails;
    });

    this.setState({ slides: newsectionDetails });

  }

  handleCancelEvent(originalDetails) {
    var item = {
      id: originalDetails.id,
      slideName: originalDetails.slideName
    };

    var old_sectionDetails = this.state.slides.slice();
    var newsectionDetails = old_sectionDetails.map(function (testsectionDetails) {
      for (var key in testsectionDetails) {
        if (testsectionDetails.Id === item.id) {
          testsectionDetails.SlideName = item.slideName;
          testsectionDetails["isEditing"] = false;
          break;
        }
      }
      return testsectionDetails;
    });
    this.setState({ slides: newsectionDetails });

  };

  handleSectionsDetailTable(evt) {
    var item = {
      name: evt.target.name,
      id: evt.target.id,
      value: evt.target.value
    };

    var old_sectionDetails = this.state.old_sectionDetails.slice();
    if (old_sectionDetails.length === 0) {
      old_sectionDetails = this.state.slides.slice();
      this.setState({ old_sectionDetails: old_sectionDetails });
    }
    let testOfObjects = old_sectionDetails;
    var newsectionDetails = testOfObjects.map(function (testdomainDetails) {
      for (var key in testdomainDetails) {
        if (testdomainDetails.id === item.id) {
          testdomainDetails[key] = item.name;
          testdomainDetails["isEditing"] = false;
          break;
        }
      }
      return testdomainDetails;
    });

    this.setState({ slides: newsectionDetails });

  };

  validateAll() {
    this.props.validateAll()
      .then((results) => {
        this.props.userDetailsSuccess(results.data.Message)
        this.setState({ mymessage: results.data.Message })
      }).catch((error) => {
        if (error.stack.includes('Network Error')) {
          this.setState({ mymessage: "" })
          this.props.userDetailsFailure('API service is down. Please contact administrator.')
        }
        else {
          this.setState({ mymessage: "" })
          this.props.userDetailsFailure(error.response.data.Message)
        }
      })
  }

  handleUpdateEvent(updateDetails) {
    if (updateDetails.length > 0) {
      var item = {
        Id: updateDetails[0].id,
        SlideName: updateDetails[0].slideName.trim(),
        FileParentId: updateDetails[0].fileId,
        SectionParentId: updateDetails[0].sectionId,
        isEditing: true,
        UpdatedBy: localStorage.getItem('loggedinUser'),
        UpdatedOn: Date.now
      };
      if (item.SlideName != null && item.SlideName != "") {
        this.setState({ mymessage: "" })
        this.props.updateSlide(item)
          .then((results) => {
            this.props.userDetailsSuccess(results.data.Message)
            this.setState({ mymessage: results.data.Message })
            if (results.status === 200) {
              item.UpdatedBy = results.data.Result.UpdatedBy;
              item.UpdatedOn = results.data.Result.UpdatedOn;
              this.handleEditEvent(item)
            }
            this.loadSlides();
            this.formDispatch(actions.reset('slideManager'))

          }).catch((error) => {
            if (error.stack.includes('Network Error')) {
              this.setState({ mymessage: "" })
              this.props.userDetailsFailure('API service is down. Please contact administrator.')
            }
            else {
              this.setState({ mymessage: "" })
              this.props.userDetailsFailure(error.response.data.Message)
            }
          })
      }
      else {
        this.setState({ mymessage: "" })
        this.props.userDetailsFailure("Slide Name cannot be blank")
      }
    }
  }

  componentWillUnmount() {
    this.formDispatch(actions.reset('slideManager'))
    this.props.resetError()
  }

  render() {
    return (
      <div className="popup-content">
        <h2 className="medium-12 title">{this.props.content.title}</h2>
        <div>
          <Form model="slideManager" method="post"
            getDispatch={(dispatch) => this.formDispatch = dispatch}
            onSubmit={(formValue) => this.addSlide(formValue)}>

            <div className="grid-x grid-padding-x" >
              <div className="medium-4 cell grid-padding-5" onClick={this.onDDLSlideFileClickEvent.bind(this)} onBlur={this.onDDLSlideFileBlurEvent.bind(this)}>
                {/* <Control.select
                    className="role__select"
                    model="slideManager.FileName"
                    id="slideManager.FileName"
                    component={SelectField}
                    label={this.props.content.file}
                    onChange={(value) => this.handleChange(value)}
                    // validators={{ required: (value) => validators.validSelect(this.props.content.selector.indexOf(value)) }}
                    validateOn="change"
                    floatingLabel
                  >
                    {
                      this.state.fileFilter.map((filter) =>
                    <Option
                      key={filter.Id}
                      value={filter.Name}
                    >{filter.Name}</Option>)
                    }
                  </Control.select> */}
                {
                  ((this.state.isSlideFileSelected === 'Selected' && this.state.selectedOption !== '')
                    || (this.state.isSlideFileSelected === '' && this.state.selectedOption !== ''))
                    ? <label style={{ visibility: 'visible' }} class="css-floatingLabel"
                      for="slideManager.FileName">{this.props.content.file}</label>
                    : <label style={{ visibility: 'hidden' }} class="css-floatingLabel"
                      for="slideManager.FileName">{this.props.content.file}</label>
                }

                <Select
                  //className="role__select"
                  model="slideManager.FileName"
                  id="slideManager.FileName"
                  styles={dropdownStyles}
                  placeholder={this.props.content.file}
                  value={options.filter(option => option.label === this.state.selectedOption)}
                  noOptionsMessage={() => null}
                  onChange={(value) => this.handleChange(value)}
                  options={options}
                  isSearchable={false}
                  classNamePrefix="gen-react-select"
                  className="gen-react-select-container"
                />

                <Control.text
                  //className="role__select"
                  model="slideManager.FileName"
                  id="slideManager.FileName"
                  value={this.state.selectedOption}
                  hidden
                  validateOn="blur"
                  component={Textfield}
                  validators={{
                    required: validators.required
                  }} />
                <Errors
                  className="errors slideManagerDDLError"
                  //style={{ margin: '-37px 0px' }}
                  model="slideManager.FileName"
                  show="touched"
                  messages={{
                    required: this.props.validation.required
                  }}
                />
              </div>
              <div className="medium-4 cell grid-padding-5" onClick={this.onDDLSlideSectionClickEvent.bind(this)} onBlur={this.onDDLSlideSectionBlurEvent.bind(this)}>
                {/* <Control.select
                    className="role__select"
                    model="userManager.Files"
                    id="userManager.Files"
                    component={SelectField}
                    label={this.props.content.section}
                    onChange={(value) => this.handleSectionChange(value)}
                    // validators={{ required: (value) => validators.validSelect(this.props.content.selector.indexOf(value)) }}
                    validateOn="change"
                    floatingLabel
                  >
                    {
                      this.state.sections.map((filter) =>
                    <Option
                      key={filter.Id}
                      value={filter.SectionName}
                    >{filter.SectionName}</Option>)
                    }
                  </Control.select> */}
                {
                  ((this.state.isSlideSectionSelected === 'Selected' && this.state.sectionOption !== '')
                    || (this.state.isSlideSectionSelected === '' && this.state.sectionOption !== ''))
                    ? <label style={{ visibility: 'visible' }} class="css-floatingLabel"
                      for="slideManager.Files">{this.props.content.section}</label>
                    : <label style={{ visibility: 'hidden' }} class="css-floatingLabel"
                      for="slideManager.Files">{this.props.content.section}</label>
                }

                <Select
                  //className="role__select"
                  model="slideManager.Files"
                  id="slideManager.Files"
                  styles={dropdownStyles}
                  placeholder={this.props.content.section}
                  value={section_options.filter(option => option.label === this.state.sectionOption)}
                  noOptionsMessage={() => null}
                  onChange={(value) => this.handleSectionChange(value)}
                  options={section_options}
                  isSearchable={false}
                  classNamePrefix="gen-react-select"
                  className="gen-react-select-container"
                />

                <Control.text
                  //className="role__select"
                  model="slideManager.Files"
                  id="slideManager.Files"
                  value={this.state.sectionOption}
                  hidden
                  validateOn="blur"
                  component={Textfield}
                  validators={{
                    required: validators.required
                  }} />
                <Errors
                  className="errors slideManagerDDLError"
                  //style={{ margin: '-37px 0px' }}
                  model="slideManager.Files"
                  show="touched"
                  messages={{
                    required: this.props.validation.required
                  }}
                />
              </div>
              <div className="medium-4 cell" >
                <Control.text
                  model="slideManager.SlideName"
                  component={Textfield}
                  validateOn="blur"
                  label={this.props.content.newSection}
                  floatingLabel
                  validators={{
                    required: validators.required
                  }}
                />
                <Errors
                  className="errors"
                  model="slideManager.SlideName"
                  show="touched"
                  messages={{
                    required: this.props.validation.required
                  }}
                />
              </div>

              <div className="medium-2 cell" style={{ marginTop: '-3%', position: 'inherit' }}>
                <Button
                  type="submit"
                  disabled={this.props.user.loading}
                  content={this.props.content.cta}
                  color
                />
              </div>
              <div className="medium-6 cell" ></div>
              <div className="medium-3 cell" style={{ marginTop: '-3%', position: 'inherit' }}>
                <Button
                  type="submit"
                  disabled={this.props.user.loading}
                  content="Validate All"
                  onClick={this.validateAll.bind(this)}
                  color
                />
              </div>
            </div>

          </Form>
          <div className="errors">{this.props.user.error}</div>
          <div className="success">{this.state.mymessage}</div>
        </div>
        <div className="medium-11 medium-offset-0">
          <UserTable
            sections={this.state.slides}
            onRowDel={this.handleRowDel.bind(this)}
            onRowEdit={this.handleEditEvent.bind(this)}
            onRowCancel={this.handleCancelEvent.bind(this)}
            onSectionsTableUpdate={this.handleSectionsDetailTable.bind(this)}
            validation={this.props.validation}
            onRowUpdate={this.handleUpdateEvent.bind(this)}
          />

        </div>
      </div>
    );
  }
}

class UserTable extends React.Component {
  render() {
    var rowDel = this.props.onRowDel;
    var onSectionDetailTableUpdate = this.props.onSectionsTableUpdate;
    var rowEdit = this.props.onRowEdit;
    var rowCancel = this.props.onRowCancel;
    var rowUpdate = this.props.onRowUpdate;
    var validation = this.props.validation;
    var slide = this.props.sections.map(function (slide) {
      return (<UserRow
        onDelEvent={rowDel.bind(this)}
        onEditEvent={rowEdit.bind(this)}
        onCancelEvent={rowCancel.bind(this)}
        onUpdateEvent={rowUpdate.bind(this)}
        onSectionDetailTableUpdate={onSectionDetailTableUpdate}
        validation={validation}
        slide={slide}
        key={slide.Id} />)

    });
    return (
      <div className="basic-table">
        <table>
          <thead>
            <tr>
              {/* <th>Report Name</th> */}
              <th>File Name</th>
              <th>Section Name</th>
              <th>Slide Name</th>
              <th></th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {slide}
          </tbody>
        </table>
      </div>
    );
  }
}

class UserRow extends React.Component {

  onSectionDetailTableUpdate() {
    this.props.onSectionDetailTableUpdate()
  }

  render() {
    return (
      this.props.slide.isEditing ?
        <EditableCell
          onSectionDetailTableUpdate={this.props.onSectionDetailTableUpdate}
          slide={this.props.slide}
          cellData={{
            slideName: this.props.slide.SlideName,
            id: this.props.slide.Id,
            isEditing: this.props.slide.isEditing,
            fileId: this.props.slide.FileParentId,
            sectionId: this.props.slide.SectionParentId

          }}
          onCancelEvent={this.props.onCancelEvent}
          onUpdateEvent={this.props.onUpdateEvent}
          validation={this.props.validation}
        />
        :
        <NonEditableRow
          slide={this.props.slide}
          cellData={{
            slideName: this.props.slide.SlideName,
            id: this.props.slide.Id,
            isEditing: this.props.slide.isEditing,
            fileId: this.props.slide.FileParentId,
            sectionId: this.props.slide.SectionParentId

          }}
          onDelEvent={this.props.onDelEvent}
          onEditEvent={this.props.onEditEvent}
        />
    );
  }
}

class EditableCell extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sectionDetails: this.props.cellData,
      originalsectionDetails: this.props.cellData
    };
  }

  onSectionDetailTableUpdate() {
    this.props.onSectionDetailTableUpdate();
  }

  onCancelEvent() {
    this.props.onCancelEvent(this.state.originalsectionDetails);
  }

  onUpdateEvent() {
    this.props.onUpdateEvent(this.state.sectionDetails);
  }

  onChangeEvent(event) {
    var changeItem = [
      {
        id: event.target.id,
        slideName: event.target.value,
        fileId: this.props.cellData.fileId,
        sectionId: this.props.cellData.sectionId,
        isEditing: true
      }]
    this.setState({ sectionDetails: changeItem });
  }

  isDuplicate(vals) {

  }

  render() {
    return (
      <tr>
        <td>
          <span>{this.props.slide.FileName}</span><br />
        </td>
        <td>
          <span>{this.props.slide.SectionName}</span><br />
        </td>
        <td>
          <Control.text
            model="editDomain.NewReport"
            component={Textfield}
            defaultValue={this.props.cellData.slideName}
            id={this.props.cellData.id}
            value={this.state.sectionDetails.slideName}
            onChange={this.onChangeEvent.bind(this)}
            isEditing={this.props.cellData.isEditing}
            validateOn="blur"
            validators={{
              required: validators.required
            }}
            type='name' />
          <Errors
            className="errors"
            model="editDomain.NewReport"
            show="touched"
            messages={{

            }} />
        </td>
        {/* <td>
            <span>{this.props.slide.AddedBy}</span><br />
            <span>{moment(this.props.slide.AddedOn).format('L')}</span>
          </td> */}
        {/* <td>
            <span> {this.props.slide.UpdatedBy}</span> <br />
            <span>{moment(this.props.slide.UpdatedOn).format('L')} </span>
          </td> */}
        <td>
          <button onClick={this.onUpdateEvent.bind(this)}>Update</button>
        </td>
        <td >
          <button onClick={this.onCancelEvent.bind(this)}>Cancel</button>
        </td>
      </tr>
    );
  }
}

class NonEditableRow extends React.Component {

  onDelEvent() {
    this.props.onDelEvent(this.props.cellData);
  }

  onEditEvent() {
    this.props.onEditEvent(this.props.cellData);
  }

  render() {
    return (
      <tr>
        <td>
          <span>{this.props.slide.FileName}</span>
        </td>
        <td>
          <span>{this.props.slide.SectionName}</span>
        </td>
        <td>
          <span id={this.props.cellData.id}
            sectionName={this.props.cellData.slideName}
            isEditing={this.props.cellData.isEditing}>{this.props.cellData.slideName}</span>
        </td>
        {/* <td>
            <span> {this.props.slide.UpdatedBy}</span> <br />
            <span>{moment(this.props.slide.UpdatedOn).format('L')} </span>
          </td> */}
        <td>
          <button onClick={this.onEditEvent.bind(this)}>Edit</button>
        </td>
        <td>
          <button onClick={this.onDelEvent.bind(this)}>Delete</button>
        </td>
      </tr>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
    ui: state.content.ui,
    user: state.default,
    content: state.content.slideManager,
    validation: state.content.validation
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchReports: (formValues) => dispatch(fetchReports(formValues)),
    fetchFiles: (formValues) => dispatch(fetchFiles(formValues)),
    fetchSections: (formValues) => dispatch(fetchSections(formValues)),
    fetchSlides: (formValues) => dispatch(fetchSlides(formValues)),
    addSlide: (formValues) => dispatch(addSlide(formValues)),
    deleteSlide: (formValues) => dispatch(deleteSlide(formValues)),
    updateSlide: (formValues) => dispatch(updateSlide(formValues)),
    userDetailsLoading: (bool) => dispatch(userDetailsLoading(bool)),
    userDetailsSuccess: (bool) => dispatch(userDetailsSuccess(bool)),
    userDetailsFailure: (error) => dispatch(userDetailsFailure(error)),
    resetError: () => dispatch(resetError()),
    validateAll: () => dispatch(validateAll())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SlideManager)
