import axios from 'axios'

const url =  '/NodeApiCall/api/'
//const url = 'http://localhost/NodeApiCall/api/'
//const url = 'https://avtuatapp1.phl.sap.corp/NodeApiCall/api/'
export const LANGUAGE_MANAGER_FAILURE = 'LANGUAGE_MANAGER_FAILURE';
//const url = 'http://localhost:5000/NodeApiCall/api/'
export function LanguageManagerFailure(error) {
  return {
    type: LANGUAGE_MANAGER_FAILURE,
    payload: error
  };
}

export function getAlllanguageList() {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      url: url + 'AlllanguageList',
      method: 'get',
      headers: {
        'Authorization': `AribaBearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded'
      },

    }).then((response) => {
      return response.data.languagelist;
    }).catch((err) => {
      dispatch(LanguageManagerFailure('API service is down. Please contact administrator.'))
    })
  }
}


export function getlanguageLists() {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      url: url + 'languageList',
      method: 'get',
      headers: {
        'Authorization': `AribaBearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },

    }).then((response) => {
      return response.data.languagelist;
    }).catch((err) => {
      dispatch(LanguageManagerFailure('API service is down. Please contact administrator.'))
    })
  }
}

export function callApi(formValue, userId) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    var newJson = {
      LanguageName: formValue.LanguageName,
      LanguageShortName: formValue.LanguageShortName,
      userId : userId
    }

    return axios({
      url: url + 'AddNewLanguage',
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': `AribaBearer ${token}`
      },
      data: (newJson),
    }).then((response) => {
      return response.data;
    }).catch((err) => {
      dispatch(LanguageManagerFailure('API service is down. Please contact administrator.'))
    });
  }
}

export function callApiget() {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      url: url + 'getScreens',
      method: 'get',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': `AribaBearer ${token}`
      },

    }).then((response) => {
      return response.data.screen;
    }).catch((err) => {
      dispatch(LanguageManagerFailure('API service is down. Please contact administrator.'))
    })  // if (response.status !== 200) throw Error(body.message);
  }
}

export function translateENtoLanguage(NewData) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    return axios({
      url: url + 'updateLangFile',
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': `AribaBearer ${token}`
      },
      data: (NewData),
    }).then((response) => {
      console.log('useraction', response)
      return response.data;
    }).catch((err) => {
      dispatch(LanguageManagerFailure('API service is down. Please contact administrator.'))
    })
  }
}

export function movetoapplication(formValue, userId) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    var newJson = {
      LanguageName: formValue.LanguageName,
      LanguageShortName: formValue.ShortLanguageName,
      userId: userId
    }
    return axios({
      url: url + 'movetoapplication',
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': `AribaBearer ${token}`
      },
      data: (newJson),
    }).then((response) => {

      return response;
    }).catch((err) => {
      console.log(err);
      dispatch(LanguageManagerFailure('API service is down. Please contact administrator.'))
    })
  }
}

export function GetDataOfLanguage(languageshortName, fromPublish) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    var lngJson = {
      LanguageShortName: languageshortName,
      fromPublish: fromPublish
    }

    return axios({
      url: url + 'GetDataOfLanguage',
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': `AribaBearer ${token}`
      },
      data: (lngJson),
    }).then((response) => {
      return response;
    }).catch((err) => {
      console.log(err);
      dispatch(LanguageManagerFailure('API service is down. Please contact administrator.'))
    })
  }
}

export function DeleteLanguage(_data, userId) {
  return (dispatch) => {
    let token = localStorage.getItem('jwtToken')
    var deleteLanguage = {
      LanguageName: _data.LanguageName,
      LanguageShortName: _data.ShortLanguageName,
      userId : userId
    }
    return axios({
      url: url + 'DeleteExistingLanguage',
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': `AribaBearer ${token}`
      },
      data: (deleteLanguage),
    }).then((response) => {
      return response;
    }).catch((err) => {
      console.log(err);
      dispatch(LanguageManagerFailure('API service is down. Please contact administrator.'))
    })
  }
}
