import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Control, Form, Errors, actions } from 'react-redux-form'

// Actions
import {
	forgotPassword,
	forgotPasswordFailure,
	forgotPasswordSuccess,
	resetError
}
	from 'actions/userActions'

// Utils
import { BreakLine } from 'utils/Utils'

// UI
import Textfield from 'react-mdl/lib/Textfield'
import Button from 'UI/Button'

// Settings
import { validators, routes } from 'Constants'

class ForgotPasswordContainer extends Component {

	constructor(props) {
		super(props)

		this.state = {
			success: false
		}

		this.forgotPassword = this.forgotPassword.bind(this)
	}

	forgotPassword(formValues) {
		let values = Object.assign({}, formValues)
		values.Language = this.props.language.toUpperCase()
		var content = this.props.content

		this.props.forgotPassword(values)
			.then((response) => {
				this.props.forgotPasswordFailure(content[response.status])
				//this.setState({ success: true })
			}).catch((error) => {
				if (error.response.status == 401) {
					this.props.forgotPasswordSuccess(content[error.response.status])
				}
				this.props.forgotPasswordFailure(content[error.response.data.Message])
			})
	}

	componentWillUnmount() {
		this.formDispatch(actions.reset('forgotPassword'))
		this.props.resetError()
	}

	render() {
		return (
			<div className="grid-container pt20 forgot-password-container">
				{
					this.state.success ?
						(
							<div className="grid-x">
								<div className="medium-6 cell">
									<div className="page-title">{BreakLine(this.props.content.success.title)}</div>
									<p>{this.props.content.success.description}</p>
									<Link to={routes.home}>
										<Button
											content={this.props.content.success.cta}
											icon="left-arrow"
											iconPosition="left"
											color
										/>
									</Link>
								</div>
							</div>
						) : (
							<div className="grid-x">
								<div className="medium-6 cell">
									<div className="page-title">{this.props.content.title}</div>
									<p className="mt20">{BreakLine(this.props.content.description)}</p>
									<Form
										model="forgotPassword"
										getDispatch={(dispatch) => this.formDispatch = dispatch}
										onSubmit={(values) => this.forgotPassword(values)}
									>
										<Control.text
											model="forgotPassword.Email"
											id="forgotPassword.Email"
											component={Textfield}
											label={this.props.content.email}
											floatingLabel
											validators={{
												validEmail: validators.validEmail
											}}
											validateOn="blur"
										/>
										<Errors
											className="errors"
											model="forgotPassword.Email"
											show="touched"
											messages={{
												validEmail: this.props.validation.validEmail
											}}
										/>
										<Button
											type="submit"
											disabled={this.props.user.loading}
											content={this.props.content.resetPassword}
											color
										/>
									</Form>
									<div className="errors">{this.props.user.error}</div>
									<Link to={routes.login} style={{ marginTop: '20px', display: 'inline-block' }}>{this.props.ui.login}</Link>
								</div>
							</div>
						)
				}
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		language: state.language,
		ui: state.content.ui,
		user: state.default,
		content: state.content.forgotPassword,
		validation: state.content.validation
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		forgotPassword: (email) => dispatch(forgotPassword(email)),
		forgotPasswordSuccess: (bool) => dispatch(forgotPasswordSuccess(bool)),
		forgotPasswordFailure: (error) => dispatch(forgotPasswordFailure(error)),
		resetError: () => dispatch(resetError())
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordContainer)
