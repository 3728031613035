//
//
//
//
//       <- This code is no longer used ->
//
//
//
//
import React, { Component } from 'react'
import { connect } from 'react-redux'
import SearchField from "react-search-field";
import Autosuggest from 'react-autosuggest';
import { GoSearch } from "react-icons/lib/go";
import { searchResult } from '../../actions/searchActions'

// Imagine you have a list of languages that you'd like to autosuggest.
const languages = [
    {
        name: 'Accenture 1',
        desc: 'Technology Consulting'
    },
    {
        name: 'Accenture 2',
        desc: 'Technology Consulting'
    },
    {
        name: 'Accenture 3',
        desc: 'Technology Consulting'
    },
    {
        name: 'Accenture 4',
        desc: 'Technology Consulting'
    },
    {
        name: 'Accenture 5',
        desc: 'Technology Consulting'
    },
    {
        name: 'C',
        year: 1972
    },
    {
        name: 'C++',
        year: 2012
    },
    {
        name: 'C#',
        year: 2017
    }
];

// Teach Autosuggest how to calculate suggestions for any given input value.
const getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0 ? [] : languages.filter(lang =>
        lang.name.toLowerCase().slice(0, inputLength) === inputValue
    );
};

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = suggestion => suggestion.name;

// Use your imagination to render suggestions.
const renderSuggestion = suggestion => (
    <div>
        {suggestion.name}
    </div>
);

class AutoSearch extends Component {
    constructor(props) {
        super(props);

        // Autosuggest is a controlled component.
        // This means that you need to provide an input value
        // and an onChange handler that updates this value (see below).
        // Suggestions also need to be provided to the Autosuggest,
        // and they are initially empty because the Autosuggest is closed.
        this.state = {
            value: props.value,
            suggestions: []
        };

        this.onSuggestionSelected = this.onSuggestionSelected.bind(this)
    }

    onChange = (event, { newValue }) => {
        this.setState({
            value: newValue
        });
    };

    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: getSuggestions(value)
        });
    };

    // Autosuggest will call this function every time you need to clear suggestions.
    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    onSuggestionSelected(event) {
        this.props.searchResult(event.target.textContent)
    }

    render() {
        const { value, suggestions } = this.state;

        // Autosuggest will pass through all these props to the input.
        const inputProps = {
            placeholder: 'Search by Company',
            value,
            onChange: this.onChange
        };

        // Finally, render it!
        return (
            <div className='grid-x grid-container'>
                <div className="auto-search-result-bar">
                    <div className="search-result-bar">
                        <input type='text' placeholder='Search by Company, CapIQ ID, PE ID, Global Ultimate ID or Keywords' onChange={this.onChange} value={this.state.value} />
                        <button type='submit'>
                            <img width="20px" src="/images/search_big_inactive.svg" />
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        content: state.content
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        searchResult: (result) => dispatch(searchResult(result))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AutoSearch)

