import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Form } from 'react-redux-form'
import classNames from "classnames";
import { path } from 'Constants'
import moment from 'moment'
import { ControlTextE } from 'UI/CustomComponents/Controls';

// Actions
import {
    fetchStandardAccountYieldFactor,
    resetError,
    updateStandardAccountYield
} from 'actions/spendActions'

// Utils
import { BreakLine} from 'utils/Utils'

class StandardAccountYield extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            accountYield: [],
            editedAccountYield: [],
            responseMessage: '',
            isError: false
        }
        this.loadData = this.loadData.bind(this)
        this.trackAccountYieldChanges = this.trackAccountYieldChanges.bind(this)
        this.onAccountYieldSave = this.onAccountYieldSave.bind(this)
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        this.getStandardAccountYield();
    }

    trackAccountYieldChanges(record) {
        var updatedRecords = this.state.editedAccountYield
        if (this.state.editedAccountYield.length === 0)
            updatedRecords.push(record)
        else {
            let index = this.state.editedAccountYield.findIndex(x => x.QUARTER_NO === record.QUARTER_NO);
            if (index !== undefined && index !== null && index > -1)
                updatedRecords.splice(index, 1)
            updatedRecords.push(record)
        }
        this.setState({ responseMessage: '', editedAccountYield: updatedRecords, isError: false })
    }

    getStandardAccountYield() {
        this.setState({ responseMessage: '', isLoading: true, isError: false });
        this.props.fetchStandardAccountYieldFactor().then((response) => {
            if (response.status === 200) {
                if (response.data.Result.length === 0) {
                    this.setState({ responseMessage: this.props.content.noRecords, isLoading: false, isError: true });
                }
                else
                    this.setState({ accountYield: response.data.Result, isLoading: false, responseMessage: '', isError: false });
            }
            else {
                this.setState({ responseMessage: this.props.content.errorMessage, isLoading: false, isError: true });
            }
        }).catch((error) => {
            this.setState({ responseMessage: this.props.content.errorMessage, isLoading: false, isError: true });
        })
    }

    onAccountYieldSave() {
        if (this.state.editedAccountYield.find(a => a.ESTIMATED_YIELD_VALUE === '') === undefined ) {
            if (this.state.editedAccountYield.find(a => a.ESTIMATED_YIELD_VALUE === '-') === undefined ) {
                this.setState({ isLoading: true, responseMessage: '', isError: false });
                let updatedRecords = this.state.editedAccountYield
                let email = this.props.user.user.Email
                let finalObject = [];
                updatedRecords.map(function (data) {
                    let record = {
                        'FACTOR_TYPE': data.FACTOR_TYPE,
                        'TYPE_NAME': data.TYPE_NAME,
                        'ESTIMATED_YIELD_VALUE': data.ESTIMATED_YIELD_VALUE, 
                        'UPDATED_BY': email                    
                    }
                    finalObject.push(record)
                });

                this.props.updateStandardAccountYield(finalObject).then((response) => {
                    if (response.status === 200) {
                        this.setState({ accountYield: response.data.Result, editedAccountYield: [], isLoading: false, responseMessage: this.props.content.successMessage, isError: false });
                    }
                    else {
                        this.setState({ isLoading: false, responseMessage: this.props.content.errorMessage, isError: true });
                    }
                }).catch((error) => {
                    this.setState({ isLoading: false, responseMessage: this.props.content.errorMessage, isError: true });
                })
            }
            else {
                this.setState({ responseMessage: this.props.content.validNumber, isError: true });
            }
        }
        else {
            this.setState({ responseMessage: this.props.content.blankValue, isError: true });
        }
    }

    componentWillUnmount() {
        this.props.resetError()
    }

    render() {
        var _instance = this
        var account_yield = this.state.accountYield.map(function (data, index) {
            return (<TableRows
                standardAccountYield={data}
                index={index}
                readOnly={_instance.props.readOnly}
                trackAccountYieldChanges={_instance.trackAccountYieldChanges}
            />
            );
        });

        return (
            <div className="grid-x" style={{ width: '100%'}}>
                <div className="medium-12">
                    {(this.state.isLoading) ?
                        <img style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
                            src={path + `images/ajax-loader.gif`} alt="" /> : null}
                </div>
                <div className="medium-12">
                    <div className="grid-x">
                        <div className="medium-5 float-left" style={{ fontSize: '1.2rem', paddingBottom: '8px' }}>{BreakLine(this.props.content.accountYieldHeader)}</div>
                        <div className="medium-7 float-left" style={{ display: 'flex' }} >
                            {this.state.isError ?
                                <div style={{ margin: '0 0 0.5rem', color: 'red' }}>{this.state.responseMessage}</div> :
                                <div style={{ margin: '0 0 0.5rem', color: '#4fb81c' }}>{this.state.responseMessage}</div>
                            }
                        </div>
                        <div className="medium-3"></div>
                    </div>
                    <div className="grid-x">
                        <div className="medium-11 float-left pb10">
                        {this.props.content.accountYieldDesc}
                        </div>
                        {this.props.user.userprofile.Roles[0].indexOf("Admin") > -1 ?
                            <div className="medium-1 pb5">
                                <button className={classNames('float-right',
                                    {
                                        'hand-cursor': this.state.isLoading ? false : (this.state.editedAccountYield.length > 0),
                                        'gray-scale': this.state.isLoading ? true : (this.state.editedAccountYield.length === 0)
                                    })}
                                    onClick={this.onAccountYieldSave} disabled={this.state.isLoading ? true : this.state.editedAccountYield.length === 0} type="submit" float >
                                    <img width="25" title={this.props.content.save} src="images/save_active.svg" />
                                </button>
                            </div> : null}
                    </div>
                </div>
                {this.state.accountYield.length > 0 ?
                    <div className="medium-12">
                        <Form model="netwrokEnablementMatchTypeParameter">
                            <div className="basic-table">
                                <table>
                                    <thead>
                                        <tr className="eachRow">                                            
                                            <th>{this.props.content.typeName}</th>
                                            <th>{this.props.content.standardAccountYield}</th>
                                            <th>{this.props.content.updatedBy}</th>
                                            <th>{this.props.content.updatedOn}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {account_yield}
                                    </tbody>
                                </table>
                            </div>
                        </Form>
                    </div>
                    : null
                }
            </div>
        )
    }
}

class TableRows extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    onChangeEvent(val) {
        if (val.target.value === '' || val.target.value.match(/^[0-9]+$/) || !isNaN(val.target.value)) {
            var editingRow = this.props.standardAccountYield;
            let editedValue = val.target.value.trim()
            if (editedValue.toString() !== editingRow[val.target.id].toString()) {
                editingRow[val.target.id] = editedValue
                this.props.trackAccountYieldChanges(editingRow)
            }
        }
    }

    render() {
        var bindValues = this.props.standardAccountYield;
        return (
            <tr className="eachRow">                
                {/* <td model="standardAccountYield.ACCOUNT_TYPE">{bindValues.FACTOR_TYPE}</td> */}
                <td model="standardAccountYield.ACCOUNT_TYPE">{bindValues.TYPE_NAME}</td>
                {/* {this.props.readOnly ? */}
                    <td>
                        <ControlTextE
                            model="standardAccountYield.ESTIMATED_YIELD_VALUE"
                            onChange={this.onChangeEvent.bind(this)}
                            id={'ESTIMATED_YIELD_VALUE'}
                            defaultValue={bindValues.ESTIMATED_YIELD_VALUE}
                            value={bindValues.ESTIMATED_YIELD_VALUE}
                            type='name'
                            className="edit-textarea edit-width"
                            readOnly={this.props.readOnly}
                        />{' %'}
                    </td> 
                    {/* // :<td model="standardAccountYield.QUARTER_VALUE">{bindValues.QUARTER_VALUE}</td>} */}
                              
                <td model="standardAccountYield.UpdatedBy">{bindValues.UPDATED_BY}</td>
                <td model="standardAccountYield.UpdatedOn">{moment(bindValues.UPDATED_ON).format('L')}</td>
            </tr>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        language: state.language,
        ui: state.content.ui,
        user: state.default,
        spendDetails: state.spendDetails,
        //content: state.content.spendAnalysis.enablementResourceParamaeters,
        content: state.content.menu.EnablementParametersManager[0].MatchAndSegmentationTab.StandardAccountYield
    }
}


const mapDispatchToProps = (dispatch) => {
    return {        
        resetError: () => dispatch(resetError()),
        fetchStandardAccountYieldFactor: () => dispatch(fetchStandardAccountYieldFactor()),      
        updateStandardAccountYield: (data) => dispatch(updateStandardAccountYield(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StandardAccountYield)


