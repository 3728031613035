import React from 'react'
import { connect } from 'react-redux'
import { ControlTextE, SelectE, CheckboxE, ScreenE, PopupE, CustomGridE } from 'UI/CustomComponents/Controls';
import { WorldMapSupplierColumnsForPopUp } from 'Constants'
import TextfilterRenderer from 'UI/TextFilterRenderer';
import NumericFilterRenderer from 'UI/NumericFilterRenderer';
import AutoCompleteFilterAdv from 'UI/AutoCompleteFilterAdv';
import NumericEditor from 'UI/NumericEditor';
import { formatter, getNonEmptyValue, binarySearchIndex } from "utils/Utils";
import {
    resetError,
    getResultLoading, getResultSuccess, getResultFailure
} from 'actions/spendActions'

class WorldMapSupplierDetailsPopup extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: props.suppliersData,
            columns: []
        }
    }
    componentDidMount() {
        let columns = this.initializeColumns()
        this.setState({ columns: columns })
    }

    // static getDerivedStateFromProps(nextProps, state) {
    //     if (nextProps.suppliersData != state.data) {
    //         return ({ data: nextProps.suppliersData })
    //     }
    // }

    componentWillReceiveProps(nextProps) {
        if (nextProps.suppliersData != this.state.data) {
            this.setState({ data: nextProps.suppliersData })
        }
    }

    initializeColumns() {
        let columns = []
        let _self = this
        for (let index = 0; index < WorldMapSupplierColumnsForPopUp.length; index++) {
            const fieldConfig = WorldMapSupplierColumnsForPopUp[index];

            let tableColumn = {
                name: fieldConfig.screen_field_name,
                key: fieldConfig.field,
                width: 150,
                sortable: true,
                editable: fieldConfig.is_editable === 'Yes',
                resizable: true,
                filterable: true,
                screenSeq: fieldConfig.screen_seq,
                mandatory: fieldConfig.mandatory === 'Yes',
                exportFl: fieldConfig.export_fl === 'Yes',
                helpText: fieldConfig.screen_help_text
            }

            if (fieldConfig.filter_type !== 'default') {
                tableColumn['filterRenderer'] = fieldConfig.data_type === 'text' ? AutoCompleteFilterAdv : NumericFilterRenderer
            } else {
                tableColumn['filterRenderer'] = TextfilterRenderer
            }

            tableColumn.editable = tableColumn.editable && !this.props.readOnly

            tableColumn['formatter'] = formatter(fieldConfig.alignment)

            columns.push(tableColumn)
        }

        this.setState({ columnDefs: columns })
        return columns;
    }

    render() {
        return (
            <div className="medium-12">
                <div style={{ fontSize: '16px', color: '#008fd3', paddingLeft: '5px' }} >{this.props.input !== null ? this.props.input.ISO_COUNTRY_CODE : null}</div>
                <div className="tab-description">{this.props.content.tabHeader}</div>
                <CustomGridE
                    onRef={ref => (this.customGridRef = ref)}
                    columns={this.state.columns}
                    data={this.state.data}
                    keyColumn="REF_NUM"
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        userData: state.valueStory,
        user: state.default,
        content: state.content.spendAnalysis.worldMap,
        focusLabel: state.content.spendAnalysis.enablement.focusLabel,
        spendDetails: state.spendDetails,
        currencySymbols: state.content.CurrencySymbols,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getResultSuccess: message => dispatch(getResultSuccess(message)),
        getResultFailure: error => dispatch(getResultFailure(error)),
        getResultLoading: bool => dispatch(getResultLoading(bool)),
        resetError: error => dispatch(resetError(error))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorldMapSupplierDetailsPopup)