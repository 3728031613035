import React, { Component } from 'react'

import { IndustrySpendColumns } from 'Constants'
import CustomGrid from 'UI/CustomGrid'
import { connect } from 'react-redux'
import { Filters, Editors } from "react-data-grid-addons";
import { ControlTextE, SelectE, CheckboxE, ScreenE, PopupE, CustomGridE } from 'UI/CustomComponents/Controls';

import {
    storeVendorDetails,
    getIndustrySpend,
    getIndustryDrillScopeDetails,
    getIndustryDrillScopeDetailsSuccess,
    getIndustryDrillScopeDetailsFailure,
    getIndustryDrillScopeDetailsLoading,
    storeIndustryGICS_ID,
    storeIndustryDetails,
    industrySpendUpdate,
    trackIndustryDrillScopeChanges,
    setPopUpTabLoadingStatus
} from 'actions/spendActions'

import { formatter, getNonEmptyValue, formatThousands, convertToMillion, binarySearchIndex } from "../../utils/Utils";

import TextfilterRenderer from 'UI/TextFilterRenderer';
import NumericFilterRenderer from 'UI/NumericFilterRenderer';
import AutoCompleteFilterAdv from 'UI/AutoCompleteFilterAdv';
import TextEditor from 'UI/TextEditor';
import Screen from 'UI/CustomComponents/Screen';

const { DropDownEditor } = Editors;
const dropdownValues = ["Yes", "No"];
const dropDownEditor = <DropDownEditor options={dropdownValues} />

class IndustrySpend extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {},
            columns: [],
            aggregatedData: { INVOICE_COUNT: 0, INVOICE_SPEND: 0.0, PO_COUNT: 0, PO_SPEND: 0.0, UNIQUE_SUPPLIERS: 0 },
            industryData: {},
            selectedIndustryName: '',
            isLoading: true,
            isError: 'No',
            responseMsg: '',
        }

        this.loadData = this.loadData.bind(this)
        this.validate = this.validate.bind(this)
        this.getGridRows = this.getGridRowsAndAggregateData.bind(this)
        this.onParentClick = this.onParentClick.bind(this)
        this.onCommit = this.onCommit.bind(this)
        this.onRowUpdate = this.onRowUpdate.bind(this)
        this.save = this.save.bind(this)
        this.onFilter = this.onFilter.bind(this)
        this.setApiResponse = this.setApiResponse.bind(this)
    }

    componentDidMount() {
        this.loadData(this.props);
    }

    componentWillReceiveProps(nextProps) {
        if (
            nextProps.spendDetails.industryGICS_Details && nextProps.spendDetails.industryGICS_Details.SKEY !== (this.props.spendDetails.industryGICS_Details ? this.props.spendDetails.industryGICS_Details.SKEY : undefined)) {
            this.loadData(nextProps);
        }
    }

    loadData(nextProps) {
        let columns = this.initializeColumns();

        let currencySymbol = this.props.spendDetails.currentActiveRequest !== undefined ?
            this.props.currencySymbols[this.props.spendDetails.currentActiveRequest.Currency.trim()] : ''
        this.props.setPopUpTabLoadingStatus(true)
        this.props.getIndustrySpend({
            DMCID: this.props.spendDetails.currentActiveRequest.DMC_ID,
            VSID: this.props.spendDetails.currentActiveRequest.VS_ID, EMAIL: this.props.user.user.Email, GICSID: nextProps.spendDetails.industryGICS_Details.SKEY,
            Country: 'all', Confirmed: 'all',
            Solution: 'No Filter'
        }).then((response) => {
            this.props.setPopUpTabLoadingStatus(false)
            if (response.status !== undefined && response.status === 200) {
                let obj = this.getGridRowsAndAggregateData(response.data.Result, currencySymbol)
                this.setState({ data: obj.rows, aggregatedData: obj.aggregatedData, columns: columns, currencySymbol: currencySymbol, isError: 'No', isLoading: false });
            } else if (response.response && response.response.status == 400) {
                this.setState({ data: [], aggregatedData: this.state.aggregatedData, columns: columns, currencySymbol: currencySymbol, isLoading: false, isError: 'No', message: 'No Result Found' })
            } else if (response && response.stack && response.stack.includes('Network Error')) {
                this.setState({ responseMsg: this.props.content.validation.networkError, isError: 'Yes', isLoading: false })
            }
        }).catch((error) => {
            this.props.setPopUpTabLoadingStatus(false)
            this.setState({ responseMsg: this.props.content.validation.error, isError: 'Yes', isLoading: false })
        })

    }

    onFilter(rows) {
        let aggregatedData = { INVOICE_COUNT: 0, INVOICE_SPEND: 0.0, PO_COUNT: 0, PO_SPEND: 0.0, UNIQUE_SUPPLIERS: 0 };
        rows.forEach(item => {
            aggregatedData.INVOICE_COUNT += item.INVOICE_COUNT
            aggregatedData.INVOICE_SPEND += item.INVOICE_SPEND
            aggregatedData.PO_COUNT += item.PO_COUNT
            aggregatedData.PO_SPEND += item.PO_SPEND
            aggregatedData.UNIQUE_SUPPLIERS += item.COUNT_VENDOR_ID
        })

        this.setState({ aggregatedData: aggregatedData })
    }

    getGridRowsAndAggregateData(result, currencySymbol) {
        let aggregatedData = { INVOICE_COUNT: 0, INVOICE_SPEND: 0.0, PO_COUNT: 0, PO_SPEND: 0.0, UNIQUE_SUPPLIERS: 0 };
        result.forEach(item => {
            aggregatedData.INVOICE_COUNT += item.INVOICE_COUNT
            aggregatedData.INVOICE_SPEND += item.INVOICE_SPEND
            aggregatedData.PO_COUNT += item.PO_COUNT
            aggregatedData.PO_SPEND += item.PO_SPEND
            aggregatedData.UNIQUE_SUPPLIERS += item.COUNT_VENDOR_ID

            item['FINAL_GICS_DESC_COMB'] = item['FINAL_GICS_DESC'] + ' (' + item['FINAL_GICS'] + ')'
            item['PARENT_GICS_DESC_COMB'] = item['PARENT_GICS_DESC'] + ' (' + item['PARENT_GICS'] + ')'
            item['currencySymbol'] = currencySymbol
        })

        result.sort((a, b) => { return a.FINAL_GICS - b.FINAL_GICS })
        return { aggregatedData: aggregatedData, rows: result }
    }

    initializeColumns() {
        let columns = []
        let _self = this
        for (let index = 0; index < IndustrySpendColumns.length; index++) {
            const fieldConfig = IndustrySpendColumns[index];

            let tableColumn = {
                name: fieldConfig.screen_field_name,
                key: fieldConfig.field,
                width: 150,
                sortable: true,
                editable: fieldConfig.is_editable === 'Yes',
                resizable: true,
                filterable: true,
                screenSeq: fieldConfig.screen_seq,
                mandatory: fieldConfig.mandatory === 'Yes',
                exportFl: fieldConfig.export_fl === 'Yes',
                helpText: ''//fieldConfig.screen_help_text
            }

            if (fieldConfig.filter_type !== 'default') {
                tableColumn['filterRenderer'] = fieldConfig.data_type === 'text' ? AutoCompleteFilterAdv : NumericFilterRenderer
            } else {
                tableColumn['filterRenderer'] = TextfilterRenderer
            }

            if (fieldConfig.field === 'EXCLUSION_REASON') {
                tableColumn['editor'] = <TextEditor max="50" />
            }

            if (fieldConfig.field === 'PARENT_GICS_DESC_COMB') {
                tableColumn['events'] = {
                    onClick: function (ev, args) {
                        let row = _self.state.data[args.rowIdx]
                        _self.onParentClick(row)
                    }
                }
            }

            if (fieldConfig.field === 'MARK_ALL_SUPPLIER_DIRECT' || fieldConfig.field === 'MARK_ALL_SUPPLIER_EXCLUSION') {
                tableColumn["editor"] = dropDownEditor
            }

            tableColumn['formatter'] = formatter(fieldConfig.alignment)

            columns.push(tableColumn)
        }

        return columns;
    }

    onParentClick(row) {
        this.setState({ isLoading: true, isError: 'No', responseMsg: '' })
        this.props.getIndustryDrillScopeDetails(this.props.spendDetails.currentActiveRequest.DMC_ID, this.props.spendDetails.currentActiveRequest.VS_ID, this.props.user.user.Email, row.PARENT_GICS)
            .then((response) => {
                if (response.status === 200) {
                    this.props.getIndustryDrillScopeDetailsSuccess(response.data.Result)

                    const supplierData = response.data.Result[0];

                    let industryName = row.PARENT_GICS_DESC;
                    let industryGICS = row.PARENT_GICS;
                    let level = row.PARENT_GICS_LEVEL;
                    let header = industryGICS + ': ' + industryName;
                    let subHeader = [];

                    for (let i = 1; i <= 9; i++) {
                        let industry = 'L' + i + '_' + 'INDUSTRY_NAME'
                        let industryId = 'L' + i + '_' + 'INDUSTRY_ID'
                        subHeader.push({ name: supplierData[industry], gicsId: supplierData[industryId], level: i })
                    }
                    let industryDetails = { header: header, subHeader: subHeader }
                    this.props.storeIndustryDetails(industryDetails)

                    // this should be called second as based on new gics id, the refresh is called
                    let industryData = this.props.spendDetails.industryGICS_Details.industryData
                    industryData.params.name = row.PARENT_GICS_DESC;
                    var industryGICS_Details = { SKEY: row.PARENT_GICS, industry: row.PARENT_GICS_DESC, level: row.PARENT_GICS_LEVEL, industryData: industryData }
                    this.props.storeIndustryGICS_ID(industryGICS_Details)
                }
                else {
                }
            }).catch((error) => {
                this.setState({ responseMsg: this.props.content.validation.error, isError: 'Yes', isLoading: false })
            })
    }

    validate(record, warningErrorRecords) {
        this.props.trackIndustryDrillScopeChanges(true)
        const exclusionReasonMandatory = this.props.industrySpendLang.exclusionReasonMandatory
        const exclusionReasonEmpty = this.props.industrySpendLang.exclusionReasonEmpty

        if (getNonEmptyValue(record['MARK_ALL_SUPPLIER_EXCLUSION']) === 'Yes' && getNonEmptyValue(record['EXCLUSION_REASON']) === '') {
            this.addWarningErroredRecord(exclusionReasonMandatory, record, warningErrorRecords.errorRecords, true)
        } else {
            this.addWarningErroredRecord(exclusionReasonMandatory, record, warningErrorRecords.errorRecords, false)
        }

        if (getNonEmptyValue(record['MARK_ALL_SUPPLIER_EXCLUSION']) !== 'Yes' && getNonEmptyValue(record['EXCLUSION_REASON']) !== '') {
            this.addWarningErroredRecord(exclusionReasonEmpty, record, warningErrorRecords.errorRecords, true)
        } else {
            this.addWarningErroredRecord(exclusionReasonEmpty, record, warningErrorRecords.errorRecords, false)
        }

        return warningErrorRecords
    }

    addWarningErroredRecord(reason, record, records, add) {
        var arr = records[reason];
        if (getNonEmptyValue(arr) === '') {
            arr = []
        }

        var eltIdx = arr.indexOf((record['FINAL_GICS']));
        if (eltIdx === -1 && add) {
            arr.push(record['FINAL_GICS'])
        } else if (eltIdx !== -1 && !add) {
            arr.splice(eltIdx, 1)
        }

        records[reason] = arr;
    }

    onRowUpdate(rowData, updated) {
        const data = this.state.data
        const rowIdx = binarySearchIndex(data, rowData["FINAL_GICS"], 'FINAL_GICS')
        if (rowIdx !== -1) {
            data[rowIdx] = { ...data[rowIdx], ...updated };
        }

        this.setState({ data: data })
    }

    save(records) {
        const recordsToUpdate = [];

        for (let idx = 0; idx < records.length; idx++) {
            const row = records[idx]

            const record = {
                DMCID: this.props.spendDetails.currentActiveRequest.DMC_ID,
                VSID: this.props.spendDetails.currentActiveRequest.VS_ID,
                EMAIL: this.props.user.user.Email,
                CAPIQ_INDUSTRY_ID: row["FINAL_GICS"],
                Country: 'all',
                Confirmed: 'all',
                Solution: 'No Filter',
                SupplierDirectFl: row["MARK_ALL_SUPPLIER_DIRECT"],
                SupplierExclusionFl: row["MARK_ALL_SUPPLIER_EXCLUSION"],
                ExclusionReason: row["EXCLUSION_REASON"],
                GICSID: this.props.spendDetails.industryGICS_Details.SKEY
            }

            recordsToUpdate.push(record);
        }
        this.props.setPopUpTabLoadingStatus(true)
        return this.props.industrySpendUpdate(recordsToUpdate);
    }

    onCommit(response) {
        let obj = this.getGridRowsAndAggregateData(response.data.Result, this.state.currencySymbol)
        return obj.rows;
    }

    setApiResponse(reponseMessage) {
        if (reponseMessage.isError === 'No') {
            this.props.trackIndustryDrillScopeChanges(false)
            this.props.setPopUpTabLoadingStatus(false)
        }
        else {
            this.props.setPopUpTabLoadingStatus(false)
        }
        this.setState({ responseMsg: reponseMessage.message, isError: reponseMessage.isError, isLoading: reponseMessage.isLoading })
    }

    render() {
        return (
            <div className="grid-x">
                <div className="tab-description">{this.props.industrySpendLang.tabHeader}</div>
                <div className="medium-12 pt10">
                    <div className="blue-bg">
                        <div className="grid-x grid-padding-x">
                            <div className="float-left">
                                <div className="title">{this.props.industrySpendLang.uniqueSuppliers + ":"}</div>
                                <div className="value">   {formatThousands(this.state.aggregatedData.UNIQUE_SUPPLIERS)}</div>
                            </div>
                            <div className="float-left pl20">
                                <div className="title">{this.props.industrySpendLang.invoiceSpend + ":"}</div>
                                <div className="value">  {this.state.currencySymbol}{convertToMillion(this.state.aggregatedData.INVOICE_SPEND, true)}</div>
                            </div>
                            <div className="float-left pl20">
                                <div className="title">{this.props.industrySpendLang.poSpend + ":"}</div>
                                <div className="value"> {this.state.currencySymbol}{convertToMillion(this.state.aggregatedData.PO_SPEND, true)}</div>
                            </div>
                            <div className="float-left pl20">
                                <div className="title">{this.props.industrySpendLang.invoiceCount + ":"} </div>
                                <div className="value">{formatThousands(this.state.aggregatedData.INVOICE_COUNT)}</div>
                            </div>
                            <div className="float-left pl20">
                                <div className="title">{this.props.industrySpendLang.poCount + ":"} </div>
                                <div className="value">{formatThousands(this.state.aggregatedData.PO_COUNT)}</div>
                            </div>
                        </div>
                    </div>

                </div>
                <CustomGridE
                    columns={this.state.columns}
                    data={this.state.data}
                    keyColumn="FINAL_GICS"
                    save={this.save}
                    validate={this.validate}
                    onCommit={this.onCommit}
                    defaultSort={['PARENT_GICS_LEVEL', 'FINAL_GICS_INDUSTRY_LEVEL']}
                    onFilter={this.onFilter}
                    minHeight={500}
                    export={true}
                    exportFileName={this.props.industrySpendLang.exportFileName}
                    apiResponse={{ message: this.state.responseMsg, isError: this.state.isError, isLoading: this.state.isLoading }}
                    setApiResponse={this.setApiResponse}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        content: state.content,
        ui: state.content.ui,
        user: state.default,
        spendDetails: state.spendDetails,
        industrySpendLang: state.content.spendAnalysis.industryDrilldown.industrySpend,
        currencySymbols: state.content.CurrencySymbols,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        storeIndustryGICS_ID: (GICS_ID) => dispatch(storeIndustryGICS_ID(GICS_ID)),
        getIndustryDrillScopeDetailsSuccess: message => dispatch(getIndustryDrillScopeDetailsSuccess(message)),
        getIndustryDrillScopeDetailsFailure: error => dispatch(getIndustryDrillScopeDetailsFailure(error)),
        getIndustryDrillScopeDetailsLoading: bool => dispatch(getIndustryDrillScopeDetailsLoading(bool)),
        getIndustryDrillScopeDetails: (DMCId, VSId, userEmail, Id) => dispatch(getIndustryDrillScopeDetails(DMCId, VSId, userEmail, Id)),
        storeVendorDetails: (vendorDetails) => dispatch(storeVendorDetails(vendorDetails)),
        getIndustrySpend: (params) => dispatch(getIndustrySpend(params)),
        storeIndustryDetails: (data) => dispatch(storeIndustryDetails(data)),
        industrySpendUpdate: (data) => dispatch(industrySpendUpdate(data)),
        trackIndustryDrillScopeChanges: message => dispatch(trackIndustryDrillScopeChanges(message)),
        setPopUpTabLoadingStatus: bool => dispatch(setPopUpTabLoadingStatus(bool)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(IndustrySpend); 