import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

// Components
import ValueStoryContainer from 'ValueStory/ValueStoryContainer'
import { modalStyle, modalContentStyle, routes, validators } from 'Constants'
import Button from 'UI/Button'

import Modal from 'reboron/OutlineModal'
import NewUpdate from '../Login/NewUpdate'
import MyWorkContainer from '../ValueStory/MyWorkContainer'
import SharedValueStories from 'ValueStory/SharedValueStories';
import classNames from "classnames";

// Actions
import {
	checkUserConfirmation,
	getLatestPublishedReleaseFeatures,
	userConsentSuccess,
	userConsentFailure,
	updateUserConfirmation,
	resetError,
	resetMessage
} from 'actions/userActions'
import { valueStoryFetchDataSuccess } from 'actions/fetchValueStory'
import { isNewValueStory } from 'actions/searchActions'
import VideoHelp from 'components/UI/VideoHelp'

const autoHeightModalStyle = Object.assign({}, modalStyle)
autoHeightModalStyle.height = 'auto'

const valueStoryModalStyle = Object.assign({}, modalStyle);
valueStoryModalStyle.width = "60%";
valueStoryModalStyle.maxWidth = "1250px";
valueStoryModalStyle.height = "515px";

const valueStoryContentModalStyle = Object.assign({}, modalContentStyle);
valueStoryContentModalStyle.paddingTop = "20px";
valueStoryContentModalStyle.paddingLeft = "30px";
valueStoryContentModalStyle.paddingRight = "30px";
valueStoryContentModalStyle.paddingBottom = "10px";


class StartContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			releaseVersionId: '',
			checkCheckbox: false,
			open: false
		}

		//this.checkUserConfirmation = this.checkUserConfirmation.bind(this)
		//this.getLatestPublishedReleaseFeatures = this.getLatestPublishedReleaseFeatures.bind(this)
		this.showModal = this.showModal.bind(this)
		this.hideModal = this.hideModal.bind(this)
		this.actions = this.getApplicationFeatureActions();
	}
	componentDidMount() {
		this.getLatestPublishedReleaseFeatures();
		let initialData = []
		if (this.props.userData !== null && this.props.userData !== undefined) {
			let arr = [{
				Organization: '', Industry: '', Location: '0', AnnualRevenue: null, TotalSpend: '0', CapIQId: '',
				CapIQCompany: '', GlobalUltimateId: '', GlobalUltimateCompany: '', BPId: '', BPCompany: '',
				PEId: '', PECompany: '', CurrencyCode: 'USD', BusinessValueFromSap: this.props.userData.BusinessValueFromSap,
				BusinessValueToYou: this.props.userData.BusinessValueToYou, AreaOfInterest: this.props.userData.AreaOfInterest,
				AllowEdit: this.props.userData.AllowEdit, ValueStoryType: ''
			}]
			initialData = {...arr}
		}
		else {
			let arr = [{
				Organization: '', Industry: '', Location: '0', AnnualRevenue: null, TotalSpend: '0', CapIQId: '',
				CapIQCompany: '', GlobalUltimateId: '', GlobalUltimateCompany: '', BPId: '', BPCompany: '',
				PEId: '', PECompany: '', CurrencyCode: 'USD', AllowEdit: true, ValueStoryType: ''
			}]
			initialData = {...arr}
		}
		const companyData = initialData[0];
		this.props.valueStoryFetchDataSuccess(companyData);
		this.props.isNewValueStory(true);
	}

	checkUserConfirmation() {
		const value = {
			UserID: localStorage.getItem('id'),
			ReleaseVersionId: this.state.releaseVersionId
		}
		this.props.checkUserConfirmation(value)
			.then((response) => {
				this.props.userConsentSuccess(response)
			}).catch((error) => {
				if (error.response.status == 404) {
					this.showModal("newupdate")
				}
			})
	}

	handleCheckbox = (checkboxValue) => {
		this.setState({ checkCheckbox: checkboxValue });
	}

	getLatestPublishedReleaseFeatures() {
		this.props.getLatestPublishedReleaseFeatures()
			.then((response) => {
				this.props.userConsentSuccess(response.data.Message)
				for (var i = 0; i < response.data.Result.length; i++) {
					var obj = response.data.Result[i];

					if (obj != null) {
						this.setState({ releaseVersionId: obj.ReleaseVersionID })
					}
				}

				this.checkUserConfirmation();

			}).catch((error) => {
				if (error.stack.includes('Network Error')) {
					this.props.userConsentFailure()
				}
				else {
					//this.props.userConsentFailure(error.response.data.Message)   
				}
			})
	}


	showModal(action) {
		this.refs[action].show()
	}

	hideModal(action) {
		if (this.state.checkCheckbox) {
			const value = {
				UserID: localStorage.getItem('id'),
				ReleaseVersionId: this.state.releaseVersionId,
				IsConfirmed: true
			}
			this.props.updateUserConfirmation(value)
				.then((result) => {
					this.props.userConsentSuccess(result)
				}).catch((error) => {
					this.props.userConsentFailure(error.response.data.Message)
				})
			this.refs[action].hide()
		}
		else {
			this.refs[action].hide()
		}
	}

	componentWillUnmount(){
		this.props.resetError();
		this.props.resetMessage();		
	}

	getApplicationFeatureActions() {
		let actions = [];
		let applicationFeatures = this.props.user && this.props.user.user && this.props.user.user.applicationFeatures ? this.props.user.user.applicationFeatures : [];
		applicationFeatures.map(applicationFeat => {
			actions.push(applicationFeat.Action);
		})
		return actions;
	}

	render() {
		const isCustomer = sessionStorage.getItem('isCustomer') === 'true'
		return (
			isCustomer ? 
			<SharedValueStories isCustomer={isCustomer} content={this.props.menu.valueStory} history={this.props.history} /> :
			<div style={{ width: '100%' }}>
				<div className="landingPg_Bg">
					<div className="grid-container">
						<div className="grid-x flex-container">
							<div className="define-bg">
								<div className="banner_text">{!isCustomer ? this.props.content.bannerTitle : this.props.content.sharedBannerTitle}</div>
								<div className="pt40">
									<div className="float-left">
										<Link to={{
											//render it on businessCase role condition base 
											//pathname: routes.discovery
											pathname: this.actions.indexOf(routes.yourSpend)> -1 ? (this.actions.indexOf(routes.businessValue)> -1 ?  routes.discovery : routes.yourSpend) : routes.discovery
										}}>
											<Button type="submit" content={this.props.content.startHereButton} />
										</Link>
									</div>
									<div className="float-right">
										<Button onClick={() => { this.showModal('valueStory') }} type="submit" content={this.props.content.myWorkButton} />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="grid-container">
					<div className="grid-x home-text">
						<div className="medium-5 medium-offset-1">
							<div className="heading">{this.props.content.newFeature}</div>
							<div className="description">
								{this.props.content.featureDesc}
							</div>
						</div>
						<div className="medium-5 medium-offset-1">
							<div className="heading">{this.props.content.heading}</div>
							<div className="description">
								<div style={{marginLeft: '-7px'}}>{<VideoHelp screen={{module: 'Landing Page', screenName: 'Landing Page'}}/>}</div>
								<div><a href="https://sapvideoa35699dc5.hana.ondemand.com/?entry_id=1_dvz00zgb" target='_blank'>{this.props.content.videoCommercial}</a></div>
								<div><a href="mailto:sap_ism_value_experience@sap.com" >{this.props.content.contactUs}</a></div>
								<div>
									{this.props.content.headingDesc}
									&nbsp;<a href="https://jam4.sapjam.com/wiki/show/k3gXFV6BtGB9m5xuFaFQs1?_lightbox=true" target="_blank">{this.props.content.jamSite}</a>&nbsp;
									{this.props.content.sapUsersOnly}
								</div>
							</div>
						</div>
					</div>
					<div className="grid-x">
						<Modal
							ref="newupdate"
							modalStyle={autoHeightModalStyle}
							contentStyle={modalContentStyle}>
							<NewUpdate props={this.props.history} hideModal={() => this.hideModal("newupdate")} onChecked={this.handleCheckbox} />
							<button onClick={() => this.hideModal("newupdate")} className="close" />
						</Modal>
						<Modal
							ref="valueStory"
							modalStyle={valueStoryModalStyle}
							contentStyle={valueStoryContentModalStyle} >
							<MyWorkContainer history={this.props.history} hideModal={() => this.hideModal("valueStory")} />
							<button onClick={() => this.hideModal('valueStory')} className="close" />
						</Modal>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.default,
		content: state.content.start,
		userData: state.valueStory,
		menu: state.content.menu
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		updateUserConfirmation: (formValues) => dispatch(updateUserConfirmation(formValues)),
		checkUserConfirmation: (formValues) => dispatch(checkUserConfirmation(formValues)),
		getLatestPublishedReleaseFeatures: (formValues) => dispatch(getLatestPublishedReleaseFeatures(formValues)),
		userConsentSuccess: (user) => dispatch(userConsentSuccess(user)),
		userConsentFailure: (error) => dispatch(userConsentFailure(error)),
		resetError: () => dispatch(resetError()),
		valueStoryFetchDataSuccess: (valueStory) => dispatch(valueStoryFetchDataSuccess(valueStory)),
		isNewValueStory: (bool) => dispatch(isNewValueStory(bool)),
		resetMessage: () => dispatch(resetMessage())
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(StartContainer)
