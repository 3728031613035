import {
  VALIDATE_EMAIL, VALIDATE_EMAIL_SUCCESS, VALIDATE_EMAIL_FAILURE,
  ME_FROM_TOKEN, ME_FROM_TOKEN_SUCCESS, ME_FROM_TOKEN_FAILURE, RESET_TOKEN,
  SIGNUP_USER_LOADING, SIGNUP_USER_SUCCESS, SIGNUP_USER_FAILURE, RESET_USER,
  SIGNIN_USER_LOADING, SIGNIN_USER_SUCCESS, SIGNIN_USER_FAILURE,
  FETCH_TOKEN_LOADING, FETCH_TOKEN_SUCCESS, FETCH_TOKEN_FAILURE,
  LOGOUT_USER, FETCH_VALUE_STORIES, FETCH_VALUE_STORIES_LOADING, FETCH_VALUE_STORIES_SUCCESS,
  FETCH_VALUE_STORIES_FAILURE, ADD_VALUE_STORY_LOADING, ADD_VALUE_STORY_SUCCESS,
  ADD_VALUE_STORY_FAILURE, UPDATE_VALUE_STORY_LOADING, UPDATE_VALUE_STORY_SUCCESS,
  UPDATE_VALUE_STORY_FAILURE, OPEN_VALUE_STORY, OPEN_VALUE_STORY_SUCCESS,
  OPEN_VALUE_STORY_FAILURE, DELETE_VALUE_STORY_LOADING, DELETE_VALUE_STORY_SUCCESS,
  DELETE_VALUE_STORY_FAILURE, FORGOT_PASSWORD_LOADING, FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE, SAVE_AS_LOADING, SAVE_AS_SUCCESS, SAVE_AS_FAILURE,
  RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAILURE, CHANGE_PASSWORD_LOADING,
  CHANGE_PASSWORD_SUCCESS, CHANGE_PASSWORD_FAILURE, RESET_ERROR,
  VALUE_STORY_IS_SAVING, AUTO_SAVE_SUCCESS, AUTO_SAVE_FAILURE,
  REPORT_SUBMIT_LOADING, REPORT_SUBMIT_SUCCESS, REPORT_SUBMIT_FAILURE,
  RESET_MESSAGE, UPDATE_LANGUAGE, UPDATE_ONLINE_STATUS, DOMAIN_DETAILS_LOADING,
  DOMAIN_DETAILS_SUCCESS, DOMAIN_DETAILS_FAILURE, BLOCKED_DOMAIN_DETAILS_LOADING,
  BLOCKED_DOMAIN_DETAILS_SUCCESS, BLOCKED_DOMAIN_DETAILS_FAILURE,
  SIGNIN_USER_SUCCESS_SSO,
  USER_DETAILS_FAILURE, USER_DETAILS_SUCCESS, USER_DETAILS_LOADING, USER_CONSENT_LOADING, USER_CONSENT_SUCCESS, USER_CONSENT_FAILURE,
  NOTIFICATION_MANAGER_LOADING, NOTIFICATION_MANAGER_SUCCESS, NOTIFICATION_MANAGER_FAILURE,
  LANGUAGE_TRANSLATION_CONSENT_LOADING,
  LANGUAGE_TRANSLATION_CONSENT_SUCCESS,
  SHARE_VALUESTORY_LOADING, SHARE_VALUESTORY_SUCCESS, SHARE_VALUESTORY_FAILURE,
  UPLOAD_FILE_LOADING, UPLOAD_FILE_SUCCESS, UPLOAD_FILE_FAILURE, BENCHMARK_LOADING, BENCHMARK_SUCCESS,
  BENCHMARK_FAILURE, REQUEST_VALUESTORY_LOADING, REQUEST_VALUESTORY_SUCCESS, REQUEST_VALUESTORY_FAILURE, FETCH_USER_PROFILE_LOADING,
  FETCH_USER_PROFILE_SUCCESS, FETCH_USER_PROFILE_FAILURE, REPORT_BUG_LOADING, REPORT_BUG_SUCCESS, REPORT_BUG_FAILURE, FETCH_USER_PROFILE_ROLES,
  FETCH_USER_MASK_LOADING, FETCH_USER_MASK_SUCCESS, FETCH_USER_MASK_FAILURE, FETCH_USER_DETAILS_SUCCESS, GENERIC_PENDING_CHANGES,
  NEWSLETTER_ACCEPTANCE_LOADING, NEWSLETTER_ACCEPTANCE_SUCCESS, NEWSLETTER_ACCEPTANCE_FAILURE, SHAREFILESONJUMPHOST_LOADING, SHAREFILESONJUMPHOST_SUCCESS, SHAREFILESONJUMPHOST_FAILURE
} from 'actions/userActions';

import {
  LANGUAGE_MANAGER_FAILURE
} from 'actions/nodeActions';
//user = userobj, 
// status can be: 
// 1. 'storage' ie. localstorage / sessionstorage)
// 2. 'signup' (signing up) 
// 3. 'signin' (signing in)
// 4. 'validate'(validate fields)
// 5. 'validate_email' (validating email token)
// 5. 'authenticated'(after signin) 
// 6. 'logout' (after logout)

const INITIAL_STATE = { user: null, valueStories: [], valueStoryListLoading: false, valueStory: null, status: null, error: null, loading: false, message: '', online: null };

export default function (state = INITIAL_STATE, action) {
  let error, status, message
  switch (action.type) {

    case VALIDATE_EMAIL://check email verification token
      return { ...state, user: null, status: 'validate_email', error: null, loading: true };
    case VALIDATE_EMAIL_SUCCESS:
      return { ...state, user: action.payload.data.user, status: 'authenticated', error: null, loading: false }; //<-- authenticated & email verified
    case VALIDATE_EMAIL_FAILURE:
      error = action.payload.data || { message: action.payload.message };//2nd one is network or server down errors       
      return { ...state, user: null, status: 'validate_email', error: error, loading: false }; //<-- authenticated

    case ME_FROM_TOKEN:// loading currentUser("me") from jwttoken in local/session storage storage,
      return { ...state, user: null, status: 'storage', error: null, loading: true };
    case ME_FROM_TOKEN_SUCCESS://return user, status = authenticated and make loading = false
      return { ...state, user: action.payload.data.user, status: 'authenticated', error: null, loading: false }; //<-- authenticated
    case ME_FROM_TOKEN_FAILURE:// return error and make loading = false
      error = action.payload.data || { message: action.payload.message };//2nd one is network or server down errors   
      return { ...state, user: null, status: 'storage', error: error, loading: false };
    case RESET_TOKEN:// remove token from storage make loading = false
      localStorage.removeItem('jwtToken')
      return { ...state, user: null, status: 'storage', error: null, loading: false };

    case SIGNUP_USER_LOADING:// sign up user, set loading = true and status = signup
      return { ...state, user: null, status: 'signup', error: null, loading: true };
    case SIGNUP_USER_SUCCESS://return user, status = authenticated and make loading = false
      return { ...state, user: action.payload, status: 'authenticated', error: null, loading: false }; //<-- authenticated
    case SIGNUP_USER_FAILURE:// return error and make loading = false
      error = action.payload //|| {message: action.payload.message};//2nd one is network or server down errors      
      return { ...state, user: null, status: 'signup', error: error, loading: false };


    case SIGNIN_USER_LOADING:// sign in user,  set loading = true and status = signin
      return { ...state, user: null, status: 'signin', error: null, loading: true };
    case SIGNIN_USER_SUCCESS://return authenticated user,  make loading = false and status = authenticated
      return { ...state, user: action.payload.data.Result.applicationUser === undefined ? action.payload.data.Result : action.payload.data.Result.applicationUser, status: 'authenticated', error: null, loading: false }; //<-- authenticated
    case SIGNIN_USER_SUCCESS_SSO://return authenticated user,  make loading = false and status = authenticated
      return { ...state, user: action.payload.data.Result, status: 'authenticated', error: null, loading: false }; //<-- authenticated
    case SIGNIN_USER_FAILURE:// return error and make loading = false
      error = action.payload //|| {message: action.payload.message};//2nd one is network or server down errors      
      return { ...state, user: null, status: 'signin', error: error, loading: false };

    case FETCH_TOKEN_LOADING:// sign in user,  set loading = true and status = signin
      return { ...state, error: null, loading: true };
    case FETCH_TOKEN_SUCCESS://return authenticated user,  make loading = false and status = authenticated
      return { ...state, token: action.payload.data.Result, loading: false }; //<-- authenticated
    case FETCH_TOKEN_FAILURE:// return error and make loading = false
      error = action.payload //|| {message: action.payload.message};//2nd one is network or server down errors      
      return { ...state, error: error, loading: false };


    case LOGOUT_USER:
      return { ...state, user: null, status: 'logout', error: null, loading: false };

    case RESET_USER:// reset authenticated user to initial state
      return { ...state, user: null, status: null, error: null, loading: false };


    case FETCH_VALUE_STORIES:
      return { ...state, valueStories: action, status: 'fetch_value_stories', loading: true };

    case FETCH_VALUE_STORIES_LOADING:
      return { ...state, status: 'fetch_value_stories_loading', valueStoryListLoading: action.payload, loading: true };

    case FETCH_VALUE_STORIES_SUCCESS:
      return { ...state, valueStories: action.payload.data.Result, status: 'fetch_value_stories_success', loading: false };

    case FETCH_VALUE_STORIES_FAILURE:
      return { ...state, valueStories: action, error: action.payload, loading: false, valueStoryListLoading: false };


    case ADD_VALUE_STORY_LOADING:
      return { ...state, error: null, loading: true };
    case ADD_VALUE_STORY_SUCCESS:
      return { ...state, error: null, loading: false };
    case ADD_VALUE_STORY_FAILURE:
      error = action.payload // || {message: action.payload.message};
      return { ...state, error: error, loading: false };

    case UPDATE_VALUE_STORY_LOADING:
      return { ...state, error: null, loading: true };
    case UPDATE_VALUE_STORY_SUCCESS:
      return { ...state, error: null, loading: false };
    case UPDATE_VALUE_STORY_FAILURE:
      error = action.payload // || {message: action.payload.message};
      return { ...state, error: error, loading: false };

    case OPEN_VALUE_STORY:
      return { ...state, valueStory: action, error: null, loading: true };

    case OPEN_VALUE_STORY_SUCCESS:
      return { ...state, valueStory: action.payload.data, status: 'open_value_story_success', error: null, loading: false };

    case OPEN_VALUE_STORY_FAILURE:
      error = action.payload
      return { ...state, error: error, loading: false };

    case DELETE_VALUE_STORY_LOADING:
      return { ...state, error: null, loading: true };
    case DELETE_VALUE_STORY_SUCCESS:
      error = action.payload
      return { ...state, error: error, loading: false };
    case DELETE_VALUE_STORY_FAILURE:
      error = action.payload
      return { ...state, error: error, loading: false };

    //Report bug 
    case REPORT_BUG_LOADING:
      return { ...state, error: null, loading: true };
    case REPORT_BUG_SUCCESS:
      error = action.payload
      return { ...state, error: error, loading: false };
    case REPORT_BUG_FAILURE:
      error = action.payload
      return { ...state, error: error, loading: false };

    //Share Files
    case SHAREFILESONJUMPHOST_LOADING:
      return { ...state, error: null, loading: true };
    case SHAREFILESONJUMPHOST_SUCCESS:
      error = action.payload
      return { ...state, error: null, loading: false };
    case SHAREFILESONJUMPHOST_FAILURE:
      error = action.payload
      return { ...state, error: error, loading: false };

    case SAVE_AS_LOADING:
      return { ...state, error: null, loading: true };
    case SAVE_AS_SUCCESS:
      return { ...state, error: null, loading: false };
    case SAVE_AS_FAILURE:
      error = action.payload
      return { ...state, error: error, loading: false };


    case FORGOT_PASSWORD_LOADING:
      return { ...state, error: null, loading: true };
    case FORGOT_PASSWORD_SUCCESS:
      return { ...state, error: action.payload, loading: false };
    case FORGOT_PASSWORD_FAILURE:
      error = action.payload || { message: action.payload.message };
      return { ...state, error: error, loading: false };


    case CHANGE_PASSWORD_LOADING:
      return { ...state, error: null, loading: true };
    case CHANGE_PASSWORD_SUCCESS:
      return { ...state, error: null, loading: false };
    case CHANGE_PASSWORD_FAILURE:
      error = action.payload //|| {message: action.payload.message};
      return { ...state, error: error, loading: false };


    case RESET_PASSWORD_SUCCESS:
      return { ...state, error: null, status: 'password_reset', loading: false, message: action.payload };
    case RESET_PASSWORD_FAILURE:
      error = action.payload //|| {message: action.payload.message};
      return { ...state, error: error, loading: false };


    case VALUE_STORY_IS_SAVING:
      return { ...state, status: 'auto_save' };
    case AUTO_SAVE_SUCCESS:
      return { ...state, status: null };
    case AUTO_SAVE_FAILURE:
      error = action.payload //|| {message: action.payload.message};
      return { ...state, error: error, status: 'auto_save_error' };


    case UPDATE_ONLINE_STATUS:
      status = action.payload //|| {message: action.payload.message};
      return { ...state, online: status };


    case REPORT_SUBMIT_LOADING:
      return { ...state, error: null, loading: true };
    case REPORT_SUBMIT_SUCCESS:
      return { ...state, error: null, loading: false, message: action.payload };
    case REPORT_SUBMIT_FAILURE:
      error = action.payload //|| {message: action.payload.message};
      return { ...state, error: error, loading: false };


    case RESET_ERROR:
      return { ...state, error: null, status: null }

    case RESET_MESSAGE:
      return { ...state, status: null, message: null }

    case DOMAIN_DETAILS_LOADING:
      return { ...state, error: null, loading: true };
    case DOMAIN_DETAILS_SUCCESS:
      let msg_1 = action.payload
      return { ...state, error: null, loading: false, message: msg_1 };
    case DOMAIN_DETAILS_FAILURE:
      error = action.payload || { message: action.payload.message };
      return { ...state, error: error, loading: false };

    case BLOCKED_DOMAIN_DETAILS_LOADING:
      return { ...state, error: null, loading: true };
    case BLOCKED_DOMAIN_DETAILS_SUCCESS:
      return { ...state, error: null, loading: false, message: action.payload };
    case BLOCKED_DOMAIN_DETAILS_FAILURE:
      error = action.payload || { message: action.payload.message };
      return { ...state, error: error, loading: false };

    case LANGUAGE_MANAGER_FAILURE:
      error = action.payload || { message: action.payload.message };
      return { ...state, error: error, loading: false };

    case USER_DETAILS_LOADING:
      return { ...state, error: null, loading: action.payload };
    case USER_DETAILS_SUCCESS:
      let msg = action.payload //|| {msg: action.payload.message};
      return { ...state, error: null, loading: false, message: msg };
    case USER_DETAILS_FAILURE:
      error = action.payload || { message: action.payload.message };
      return { ...state, error: action.payload, loading: false };

    case USER_CONSENT_LOADING:
      return { ...state, error: null, loading: action.payload };
    case USER_CONSENT_SUCCESS:
      return { ...state, error: null, loading: false, message: action.payload };
    case USER_CONSENT_FAILURE:
      return { ...state, error: action.payload, loading: false };
    case NOTIFICATION_MANAGER_LOADING:
      return { ...state, error: null, loading: action.payload };
    case NOTIFICATION_MANAGER_SUCCESS:
      let msgn = action.payload //|| {msg: action.payload.message};
      return { ...state, error: null, loading: false, message: msgn };
    case NOTIFICATION_MANAGER_FAILURE:
      error = action.payload || { message: action.payload.message };
      return { ...state, error: action.payload, loading: false };
    case LANGUAGE_TRANSLATION_CONSENT_LOADING:
      return { ...state, loading: action.payload };
    // case LANGUAGE_TRANSLATION_CONSENT_SUCCESS:
    //   return { ...state,   loading: false  };

    case SHARE_VALUESTORY_LOADING:
      return { ...state, error: null, loading: true };
    case SHARE_VALUESTORY_SUCCESS:
      return { ...state, error: null, loading: false };
    case SHARE_VALUESTORY_FAILURE:
      error = action.payload
      return { ...state, error: error, loading: false };

    case UPLOAD_FILE_LOADING:
      return { ...state, error: null, loading: action.payload };
    case UPLOAD_FILE_SUCCESS:
      return { ...state, error: action.payload, loading: false };
    case UPLOAD_FILE_FAILURE:
      return { ...state, error: action.payload, loading: false };
    case BENCHMARK_LOADING:
      return { ...state, error: null, loading: true };
    case BENCHMARK_SUCCESS:
      return { ...state, error: null, loading: false };
    case BENCHMARK_FAILURE:
      error = action.payload
      return { ...state, error: error, loading: false };

    case REQUEST_VALUESTORY_LOADING:
      return { ...state, error: null, loading: true };
    case REQUEST_VALUESTORY_SUCCESS:
      return { ...state, error: null, loading: false };
    case REQUEST_VALUESTORY_FAILURE:
      error = action.payload
      return { ...state, error: error, loading: false };

    case FETCH_USER_PROFILE_LOADING:
      return { ...state, error: null, loading: true };
    case FETCH_USER_PROFILE_SUCCESS:
      return { ...state, error: null, loading: false };
    case FETCH_USER_PROFILE_FAILURE:
      error = action.payload
      return { ...state, error: error, loading: false };
    case FETCH_USER_PROFILE_ROLES:
      return { ...state, userprofile: action.payload.Result != undefined ? action.payload.Result : '', error: null, loading: false };
    case GENERIC_PENDING_CHANGES:
      return { ...state, changes: action.payload }
    case FETCH_USER_MASK_LOADING:
      return { ...state, error: null, loading: true };
    case FETCH_USER_MASK_SUCCESS:
      return { ...state, error: action.payload, loading: false };
    case FETCH_USER_MASK_FAILURE:
      error = action.payload
      return { ...state, error: error, loading: false };

    case GENERIC_PENDING_CHANGES:
      return { ...state, changes: action.payload }

    case FETCH_USER_DETAILS_SUCCESS:
      return { ...state, user: action.payload, status: 'authenticated', error: null, loading: false };

    case NEWSLETTER_ACCEPTANCE_LOADING:
      return { ...state, error: null, loading: action.payload };
    case NEWSLETTER_ACCEPTANCE_SUCCESS:
      return { ...state, error: null, loading: false, message: action.payload };
    case NEWSLETTER_ACCEPTANCE_FAILURE:
      return { ...state, error: action.payload, loading: false };

    default:
      return state;
  }
}

export const language = (state = 'EN', action) => {
  if (action.type === UPDATE_LANGUAGE) {
    return action.lang
  } else {
    return state;
  }
}

export const showValueStoryMenu = (state = false, action) => {
  switch (action.type) {
    case 'SHOW_VALUE_STORY_MENU':
      return action.payload

    default:
      return state
  }
}

export const syncMenuAndButton = (state = false, action) => {
  switch (action.type) {
    case 'SYNC_MENU_AND_BUTTON':
      return action.payload

    default:
      return state
  }
}

export const setTabMenu = (state = false, action) => {
  switch (action.type) {
    case 'setTabMenu':
      return action.payload

    default:
      return state
  }
}

export const setSubTabMenu = (state = false, action) => {
  switch (action.type) {
    case 'setSubTabMenu':
      return action.payload

    default:
      return state
  }
}