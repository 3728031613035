import React, { Component } from 'react'
import { EnablementLogicRecommendationColumns, EnablementLogicReasonColumns,OnboardingWorkstreamSupplierColumns } from 'Constants'
import CustomGrid from 'UI/CustomGrid'
import { connect } from 'react-redux'
import { Editors,Filters } from "react-data-grid-addons";
import {
    getFlightPlanningRecommendationDetails,
    updateFlightPlanningRecommendationDetails,
    updateOnboardingworkstreamDetails,
    getSupplierChoiceEnablementParametersDetails
} from 'actions/spendActions'
import { getNonEmptyValue, formatter } from "../../utils/Utils";
const { DropDownEditor } = Editors;

// const dropdownEnablementRecommendationValues = [
//     'Enterprise - Integration','Enterprise - Integration & Catalog','Enterprise - Integration & Catalog Candidate','Enterprise - Integration Candidate'
// ,'Enterprise - Portal','Enterprise - Portal & Catalog']

const dropdownEnablementRecommendationValues = [
'Enterprise - Supplier Dedicated Specialist (Integration & Catalog)'
,'Enterprise - Integration Ready & Catalog Candidate'
,'Enterprise - Integration Ready Candidate'
,'Enterprise - Integration'
,'Enterprise - Integration & Catalog'
,'Enterprise - Integration & Catalog Candidate'
,'Enterprise - Integration Candidate'
,'Enterprise - Portal & Catalog'
,'Enterprise - Portal & Catalog Candidate'
,'Enterprise - Portal'
,'Managed Standard Account'
,'On Going Standard Account'
,'Out of Scope'
]
const dropdownEnablementRecommendationEditor = <DropDownEditor options={dropdownEnablementRecommendationValues} />

const dropdownIntegrationMatchTypeValues = [
    'Integration - Current','Integration - History','Integration - No History','Integration - Ready','Supplier Dedicated Specialist']
const dropdownIntegrationMatchTypeEditor = <DropDownEditor options={dropdownIntegrationMatchTypeValues} />

// const dropdownOnboardingWorkstreamValues = [
//     'Enterprise - Integration','Enterprise - Integration & Catalog','Enterprise - Portal','Enterprise - Portal & Catalog']
const dropdownOnboardingWorkstreamValues = [
'Enterprise - Integration & Catalog'
,'Enterprise - Integration'
,'Enterprise - Portal & Catalog'
,'Enterprise - Portal'
,'Managed Standard Account'
,'On Going Standard Account'
]
const dropdownOnboardingWorkstreamEditor = <DropDownEditor options={dropdownOnboardingWorkstreamValues} />

const dropdownCommonValues = [
    'Enterprise - Integration Candidate'
    ,'Enterprise - Portal'
    ,'Managed Standard Account'
    ,'On Going Standard Account'
    ]
const dropdownCommonEditor = <DropDownEditor options={dropdownCommonValues} />

// const dropdownOnboardingWorkstreamDetailsValues = [
//     'Integration Discussion','Integration Handover']
const dropdownOnboardingWorkstreamDetailsValues = [
    'Supplier Choice','Integration Discussion','Integration Handover']
const dropdownOnboardingWorkstreamDetailsEditor = <DropDownEditor options={dropdownOnboardingWorkstreamDetailsValues} />

const {
        AutoCompleteFilter,
} = Filters;

class EnablementSELogicAndAnalysis extends Component {
    
    constructor(props) {
        super(props)
        this.state = {
            data: {},
            columns: [],
            onboardingData :{},
            onboardingColumns :[],
        }

        this.loadData = this.loadData.bind(this)
        this.save = this.save.bind(this)
        this.saveReason = this.saveReason.bind(this)
        this.saveOnboardingParameter = this.saveOnboardingParameter.bind(this)
        this.validate = this.validate.bind(this)
        this.validateReasonData = this.validateReasonData.bind(this)
    }

    componentDidMount() {
        this.loadData()
    }

    loadData() {
        let columns = this.initializeColumns("RECMD")
        let reasonColumns = this.initializeColumns("REASON")
        this.props.getFlightPlanningRecommendationDetails()
            .then((response) => {
                this.setState({ columns: columns, reasonColumns: reasonColumns, 
                    data: response.data.Result["RECMD"], 
                    reasonData: response.data.Result["REASON"] })

                    //----------------------
                    this.props.getSupplierChoiceEnablementParametersDetails()
                    .then((response) => {
                        let onboardingColumns = this.initializeColumns("Onboarding")
                        this.setState({ onboardingColumns : onboardingColumns, onboardingData : response.data.Result });
                        //this.setState({ onboardingData : response.data });
                    }).catch((error) => {
                        if (error.stack.includes('Network Error')) {
                            this.setState({ responseMsg: 'API service is down. Please contact administrator', isError: 'Yes' })
                        }
                        else {
                            this.setState({ responseMsg: this.props.content.errorMessage, isError: 'Yes' })
                        }
                    })
                    //-----------------------

            }).catch((error) => {
                if (error.stack.includes('Network Error')) {
                    this.setState({ responseMsg: 'API service is down. Please contact administrator', isError: 'Yes' })
                }
                else {
                    this.setState({ responseMsg: this.props.content.errorMessage, isError: 'Yes' })
                }
            })

            
    }

    initializeColumns(columnType) {
        let columns = []

        if(columnType==="Onboarding"){
            for (let index = 0; index < OnboardingWorkstreamSupplierColumns.length; index++) {
                const fieldConfig = OnboardingWorkstreamSupplierColumns[index];
    
                let tableColumn = {
                    name: fieldConfig.screen_field_name,
                    key: fieldConfig.field,
                    width: 250,
                    sortable: true,
                    editable: this.props.user.userprofile.Roles[0].indexOf("Admin") > -1 ? fieldConfig.is_editable === 'Yes' : false,
                    resizable: true,
                    filterable: true,
                    screenSeq: fieldConfig.screen_seq,
                    mandatory: fieldConfig.mandatory === 'Yes',
                    exportFl: fieldConfig.export_fl === 'Y',
                    editProperty: this.props.user.userprofile.Roles[0].indexOf("Admin") > -1 ? fieldConfig.is_editable : 'No',
                    formatter: formatter(fieldConfig.alignment),
                    helpText: fieldConfig.screen_help_text,
                    filterRenderer: AutoCompleteFilter
                } 
                
                switch (tableColumn.key) {
                    case "ONBOARDING_WORKSTREAM_DETAILS":
                        tableColumn["editor"] = dropdownOnboardingWorkstreamDetailsEditor;
                        break;
                    case "ONBOARDING_WORKSTREAM":
                        tableColumn["editor"] = dropdownOnboardingWorkstreamEditor;
                        break;
                    case "INTEGRATION_MATCH_TYPE":
                        tableColumn["editor"] = dropdownIntegrationMatchTypeEditor;
                        break;
                    case "ENABLEMENT_RECOMMENDATION":
                        tableColumn["editor"] = dropdownEnablementRecommendationEditor;
                        break;
                    default:
                        break;
                }
    
                columns.push(tableColumn)
            }
        }

        else if(columnType==="RECMD"){
        for (let index = 0; index < EnablementLogicRecommendationColumns.length; index++) {
            const fieldConfig = EnablementLogicRecommendationColumns[index];

            let tableColumn = {
                name: fieldConfig.screen_field_name,
                key: fieldConfig.field,
                width: 150,
                sortable: true,
                editable: this.props.user.userprofile.Roles[0].indexOf("Admin") > -1 ? fieldConfig.is_editable === 'Yes' : false,
                resizable: true,
                filterable: true,
                screenSeq: fieldConfig.screen_seq,
                mandatory: fieldConfig.mandatory === 'Yes',
                exportFl: fieldConfig.export_fl === 'Y',
                editProperty: this.props.user.userprofile.Roles[0].indexOf("Admin") > -1 ? fieldConfig.is_editable : 'No',
                formatter: formatter(fieldConfig.alignment),
                helpText: fieldConfig.screen_help_text,
                frozen: fieldConfig.frozen == 'true' ? true : false,
            }

            switch (tableColumn.key) {
                case "SEG_MATCH_SPEND_DOC":
                    tableColumn.width = 300;
                    break;
                case "ONBOARDING_WORKSTREAM_DETAILS" :
                    //tableColumn["editor"] = dropdownOnboardingWorkstreamEditor; 
                    break;
                case "BUY_INV_COMM_SUPP_ENABLE_RECMD":
                    tableColumn["editor"] = dropdownCommonEditor;
                    break;
                case "COMM_SUPP_ENABLE_RECMD":
                    tableColumn["editor"] = dropdownCommonEditor;
                    break;
                case "PURCHASE_BUY_COMM_SUPP_ENABLE_RECMD":
                    tableColumn["editor"] = dropdownCommonEditor;
                    break;
                case "SCC_SAP_SUPP_ENABLEMENT_RECMD": 
                    tableColumn["editor"] = dropdownCommonEditor;
                    break; 
                case "SUPPLIER_CHOICE":
                    tableColumn["editor"] = dropdownCommonEditor;
                    break;
                case "SNAP":
                    tableColumn["editor"] = dropdownCommonEditor;
                    break;
                case "DSN":
                    tableColumn["editor"] = dropdownCommonEditor;
                    break;
                case "RISE":               
                    tableColumn["editor"] = dropdownCommonEditor;
                    break;
                default:
                    break;
            }

            columns.push(tableColumn)
        }
    }
    else{
        for (let index = 0; index < EnablementLogicReasonColumns.length; index++) {
            const fieldConfig = EnablementLogicReasonColumns[index];

            let tableColumn = {
                name: fieldConfig.screen_field_name,
                key: fieldConfig.field,
                width: 150,
                sortable: true,
                editable: this.props.user.userprofile.Roles[0].indexOf("Admin") > -1 ? fieldConfig.is_editable === 'Yes' : false,
                resizable: true,
                filterable: true,
                screenSeq: fieldConfig.screen_seq,
                mandatory: fieldConfig.mandatory === 'Yes',
                exportFl: fieldConfig.export_fl === 'Y',
                editProperty: this.props.user.userprofile.Roles[0].indexOf("Admin") > -1 ? fieldConfig.is_editable : 'No',
                formatter: formatter(fieldConfig.alignment),
                helpText: fieldConfig.screen_help_text
            }

            switch (tableColumn.key) {
                case "SEG_MATCH_SPEND_DOC":
                    tableColumn.width = 300;
                    break;
                case "BUY_INV_COMM_SUPP_ENABLE_REASON":
                case "COMM_SUPP_ENABLE_REASON":
                case "PURCHASE_BUY_COMM_SUPP_ENABLE_REASON":
                case "SUPPLIER_CHOICE_REASON":
                case "SNAP_REASON":
                case "DSN_REASON":
                case "RISE_REASON":               
                    break;
                default:
                    break;
            }

            columns.push(tableColumn)
        }
    }

        return columns;
    }

    save(records) {
        const recordsToUpdate = []
        let userEmail = this.props.user.user.Email
        for (let idx = 0; idx < records.length; idx++) {
            const row = records[idx]

            const record = {
                SEG_MATCH_SPEND_DOC_ID: row["SEG_MATCH_SPEND_DOC_ID"],
                SEG_MATCH_SPEND_DOC: row["SEG_MATCH_SPEND_DOC"],
                BUY_INV_COMM_SUPP_ENABLE_RECMD: row["BUY_INV_COMM_SUPP_ENABLE_RECMD"],
                PURCHASE_BUY_COMM_SUPP_ENABLE_RECMD: row["PURCHASE_BUY_COMM_SUPP_ENABLE_RECMD"],
                SCC_SAP_SUPP_ENABLEMENT_RECMD: row["SCC_SAP_SUPP_ENABLEMENT_RECMD"],
                COMM_SUPP_ENABLE_RECMD: row["COMM_SUPP_ENABLE_RECMD"],
                SUPPLIER_CHOICE: row["SUPPLIER_CHOICE"],
                SNAP: row["SNAP"],
                DSN: row["DSN"],
                RISE: row["RISE"],
                RECMD_REASON_FLAG: 1,                
                USER_ID: userEmail,
                ONBOARDING_WORKSTREAM_DETAILS: row["ONBOARDING_WORKSTREAM_DETAILS"] == "Null" ? "": row["ONBOARDING_WORKSTREAM_DETAILS"]
            };

            recordsToUpdate.push(record);
        }

        return this.props.updateFlightPlanningRecommendationDetails(recordsToUpdate)

    }

    saveReason(records) {
        const recordsToUpdate = []
        let userEmail = this.props.user.user.Email
        for (let idx = 0; idx < records.length; idx++) {
            const row = records[idx]

            const record = {
                SEG_MATCH_SPEND_DOC_ID: row["SEG_MATCH_SPEND_DOC_RSN_ID"],
                SEG_MATCH_SPEND_DOC: row["SEG_MATCH_SPEND_DOC"],
                BUY_INV_COMM_SUPP_ENABLE_REASON: row["BUY_INV_COMM_SUPP_ENABLE_REASON"],
                PURCHASE_BUY_COMM_SUPP_ENABLE_REASON: row["PURCHASE_BUY_COMM_SUPP_ENABLE_REASON"],
                SCC_SAP_SUPP_ENABLEMENT_REASON: row["SCC_SAP_SUPP_ENABLEMENT_REASON"],
                COMM_SUPP_ENABLE_REASON: row["COMM_SUPP_ENABLE_REASON"],
                SUPPLIER_CHOICE_REASON: row["SUPPLIER_CHOICE_REASON"],
               // COMM_SUPP_ENABLE_REASON: row["COMM_SUPP_ENABLE_REASON"],
                SNAP_REASON: row["SNAP_REASON"],
                DSN_REASON: row["DSN_REASON"],
                RISE_REASON: row["RISE_REASON"],
                RECMD_REASON_FLAG: 2,                
                USER_ID: userEmail
            };

            recordsToUpdate.push(record);
        }

        return this.props.updateFlightPlanningRecommendationDetails(recordsToUpdate)

    }

    saveOnboardingParameter(records) {
        const recordsToUpdate = []
        let userEmail = this.props.user.user.Email
        for (let idx = 0; idx < records.length; idx++) {
            const row = records[idx];
            const record = {
                ONBOARDING_WORKSTREAM_DETAILS_INTEG_ID: row["ONBOARDING_WORKSTREAM_DETAILS_INTEG_ID"],
                ENABLEMENT_RECOMMENDATION: row["ENABLEMENT_RECOMMENDATION"],
                INTEGRATION_MATCH_TYPE: row["INTEGRATION_MATCH_TYPE"],
                ONBOARDING_WORKSTREAM: row["ONBOARDING_WORKSTREAM"],
                ONBOARDING_WORKSTREAM_DETAILS: row["ONBOARDING_WORKSTREAM_DETAILS"],                          
                USER_ID: userEmail
            };

            recordsToUpdate.push(record);
        }

        return this.props.updateOnboardingworkstreamDetails(recordsToUpdate)

    }

    validateRow(records) {
        var errorRecords = []
        const columns = this.state.columns

        for (let idx = 0; idx < records.length; idx++) {
            const row = records[idx]
            for (let idx = 0; idx < columns.length; idx++) {
                const column = columns[idx]
                if (getNonEmptyValue(row[column.key]) === '' && column.mandatory) {
                    errorRecords.push(row)
                    break;
                }
            }

        }

        return errorRecords;
    }

    validate(record, warningErrorRecords) {
        const columns = this.state.columns

        for (let idx = 0; idx < columns.length; idx++) {
            const column = columns[idx]
            if (getNonEmptyValue(record[column.key]) === '' && column.mandatory) {
                this.addWarningErroredRecord(this.props.content.mandatoryField + ' ' + column.name + ' ' + this.props.content.missing, record, warningErrorRecords.errorRecords, true)
            } else {
                this.addWarningErroredRecord(this.props.content.mandatoryField + ' ' + column.name + ' ' + this.props.content.missing, record, warningErrorRecords.errorRecords, false)
            }
        }

        return warningErrorRecords
    }

  

    validateReasonData(record, warningErrorRecords) {
        const columns = this.state.reasonColumns

        for (let idx = 0; idx < columns.length; idx++) {
            const column = columns[idx]
            if (getNonEmptyValue(record[column.key]) === '' && column.mandatory) {
                this.addWarningErroredRecord(this.props.content.mandatoryField + ' ' + column.name + ' ' + this.props.content.missing, record, warningErrorRecords.errorRecords, true)
            } else {
                this.addWarningErroredRecord(this.props.content.mandatoryField + ' ' + column.name + ' ' + this.props.content.missing, record, warningErrorRecords.errorRecords, false)
            }
        }

        return warningErrorRecords
    }

    addWarningErroredRecord(reason, record, records, add) {
        var arr = records[reason];
        if (getNonEmptyValue(arr) === '') {
            arr = []
        }

        var eltIdx = arr.indexOf((record['SEG_MATCH_SPEND_DOC_ID']));
        if (eltIdx === -1 && add) {
            arr.push(record['SEG_MATCH_SPEND_DOC_ID'])
        } else if (eltIdx !== -1 && !add) {
            arr.splice(eltIdx, 1)
        }

        records[reason] = arr;
    }

    render() {
        return (
            <div className="grid-x">
                <div className="tab-title" style={{ fontSize: '1.2rem', paddingBottom: '8px' }}>{this.props.content.recmdTabHeader}</div>
                <div className="tab-description">{this.props.content.recmdTabDescription}</div>
                <CustomGrid
                    columns={this.state.columns}
                    minHeight={475}
                    topMargin={true}
                    data={this.state.data}
                    keyColumn="SEG_MATCH_SPEND_DOC_ID"
                    isEditable= {this.props.user.userprofile.Roles[0].indexOf("Admin") > -1 ? true : false}
                    save={this.props.user.userprofile.Roles[0].indexOf("Admin") > -1 ? this.save : null}
                    validate={this.validate}
                    export={true}
                    exportFileName="Enablement Recommendation Extract"
                    headerRowHeight={65}
                />
                
                <br></br>
                <div className="tab-title" style={{ fontSize: '1.2rem', paddingBottom: '8px' }}>{this.props.content.reasonTabHeader}</div>
                <div className="tab-description">{this.props.content.reasonTabDescription}</div>
                <CustomGrid
                    columns={this.state.reasonColumns}
                    minHeight={475}
                    topMargin={true}
                    data={this.state.reasonData}
                    keyColumn="SEG_MATCH_SPEND_DOC_RSN_ID"
                    isEditable= {this.props.user.userprofile.Roles[0].indexOf("Admin") > -1 ? true : false}
                    save={this.props.user.userprofile.Roles[0].indexOf("Admin") > -1 ? this.saveReason : null}
                    validate={this.validateReasonData}
                    export={true}
                    exportFileName="Enablement Recommendation Reason Extract"
                    headerRowHeight={65}
                />
                
                <br></br>
                <div className="tab-title" style={{ fontSize: '1.2rem', paddingBottom: '8px' }}>{this.props.content.onboardingTabHeader}</div>
                <div className="tab-description">{this.props.content.onboardingTabDescription}</div>
                <CustomGrid
                    columns={this.state.onboardingColumns}
                    minHeight={475}
                    topMargin={true}
                    data={this.state.onboardingData}
                    keyColumn="ONBOARDING_WORKSTREAM_DETAILS_INTEG_ID"
                    // isEditable= {this.props.user.userprofile.Roles[0].indexOf("Admin") > -1 ? true : false}
                    save={this.props.user.userprofile.Roles[0].indexOf("Admin") > -1 ? this.saveOnboardingParameter : null}
                    // /validate={this.validateReasonData}
                    export={true}
                    exportFileName="Enablement Recommendation Reason Extract"
                    headerRowHeight={65}
                />

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        content: state.content,
        ui: state.content.ui,
        user: state.default,
        content: state.content.menu.EnablementParametersManager[0].SELogicAndAnalysisTab
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getFlightPlanningRecommendationDetails: () => dispatch(getFlightPlanningRecommendationDetails()),
        updateFlightPlanningRecommendationDetails: (records) => dispatch(updateFlightPlanningRecommendationDetails(records)),
        getSupplierChoiceEnablementParametersDetails: () => dispatch(getSupplierChoiceEnablementParametersDetails()),
        updateOnboardingworkstreamDetails: (records) => dispatch(updateOnboardingworkstreamDetails(records)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EnablementSELogicAndAnalysis); 