import React from 'react'
import{connect} from 'react-redux'
import EnablementInsightTabTable from './EnablementInsightTabTable'
import EnablementSupplierDetailsPopup from './EnablementSupplierDetailsPopup'
import { path } from 'Constants'
// Settings
import { changeModalBorderStyle, drilldownModalBorderContentStyle, changeModalBorderRectStyle } from 'Constants'
import Modal from 'reboron/OutlineModal'

import {
    enablementInsightSuccess,
    enablementInsightFailure,
    enablementInsightLoading,    
    resetError,
    getSpendDocBuyProcessDetails,
} from 'actions/spendActions'

class SpendDocumentBuyProcessTab extends React.Component{
    constructor(props)
    {
        super(props);
        this.state={
          segmentationByDocument: [],
          segDocGrandTotal: [],
          segmentationBySpend: [],
          segSpendGrandTotal: [],
          segmentationByBuyProcess: [],
          segBuyProceeGrandTotal:[],
          segmentationByBuyProcessDelivered: [],
          segBuyProceeDeliveredGrandTotal:[],
          buyerSupplierRelationship: [],
          buyerSupplierRelationshipGrandTotal: [],
          isLoading: true,
          responseMsg: '',
          inputParam: {
            DMC_ID: this.props.spendDetails !== undefined && this.props.spendDetails.currentActiveRequest !== undefined ? this.props.spendDetails.currentActiveRequest.DMC_ID : 0
            , VS_ID: this.props.spendDetails !== undefined && this.props.spendDetails.currentActiveRequest !== undefined ? this.props.spendDetails.currentActiveRequest.VS_ID : 0,
            User_Email: this.props.spendDetails.currentActiveRequest !== undefined &&
                        this.props.spendDetails.currentActiveRequest.SAPRequester === this.props.user.user.Email ? this.props.user.user.Email : this.props.spendDetails.currentActiveRequest.SAPRequester,
            dedupStats: 'Unique',
            enabledCustomer: 'all',
            transactingCustomer: 'all',
            buyerRegion: 'all',
            businessUnit: 'all',
            erpSystem: 'all',
            l4IndustryName: 'all',
            erpCode: 'all',
            erpDesc: 'all',
            sicCode: 'all',
            sicDesc: 'all',           
            goods: 'all',
            invoiceCountry: 'all',
            legalInvoice: 'all',
            aribaNwEligible: 'all',
            directSpend: 'all',
            column1Name: '',
            column1Value: '',
            tableName: '',
            valuesUpdated: false           
        },       

        }
        this.showEnablementSupplierDetailsModal = this.showEnablementSupplierDetailsModal.bind(this)
        this.setVisibility = this.setVisibility.bind(this)
        this.outsidePopupClick = this.outsidePopupClick.bind(this)
        this.onSave = this.onSave.bind(this)
    }

    componentDidMount(){
        this.GetSpendDocBuyProcessDetails(this.state.inputParam)
    }

    showEnablementSupplierDetailsModal(tableName, columnName, columnValue) {

        var inputParameter = this.state.inputParam
        inputParameter.tableName = tableName
        inputParameter.column1Name = columnName
        inputParameter.column1Value = columnValue

        this.setState({	openPopup: true, inputParam: inputParameter	})
    }

    onSave(value) {
        this.setState({valuesUpdated: value})
    }

    setVisibility(isVisible) {
        this.handlePoupClose()   
    }

    outsidePopupClick() {        
        this.handlePoupClose()
    }
    
    handlePoupClose() {
        this.setState({  open: false, openPopup: false }, () => {
            if(this.state.valuesUpdated) {
                this.setState({valuesUpdated: false}, this.GetSpendDocBuyProcessDetails(this.state.inputParam))
            }
        })
    }
    
    GetSpendDocBuyProcessDetails(inputParam) {		
		this.props.getSpendDocBuyProcessDetails(inputParam)
			.then((response) => {
				if (response !== undefined && response.status === 200 && response.data.Result !== undefined) {
                var docSegmentation; var docSegTotal;
                var spendSegmentation; var spendSegTotal;
                var aribaBuyProcess; var aribaBuyProcessTotal;
                var aribaBuyProcessDelivered; var aribaBuyProcessTotalDelivered;
                var buySupplierRelationship; var buySupplierRelationshipTotal;
                    var tables = response.data.Result.map(
                        function (table) {
                            if(table['DOC_COUNT_SEGMENTATION']!== undefined){
                                docSegmentation = table['DOC_COUNT_SEGMENTATION']
                                docSegTotal=table['GrandTotal']
                            }

                            if(table['SPEND_SEGMENTATION']!== undefined){
                                spendSegmentation = table['SPEND_SEGMENTATION']
                                spendSegTotal=table['GrandTotal']
                            }

                            if(table['SAP_ARIBA_BUY_PROCESS']!== undefined){
                                aribaBuyProcess = table['SAP_ARIBA_BUY_PROCESS']
                                aribaBuyProcessTotal=table['GrandTotal']
                            }

                            if(table['PRIMARY_BUY_PROCESS_DELIVERED']!== undefined){
                                aribaBuyProcessDelivered = table['PRIMARY_BUY_PROCESS_DELIVERED']
                                aribaBuyProcessTotalDelivered = table['GrandTotal']
                            }

                            if(table['BUYER_SUPPLIER_RELATIONSHIP']!== undefined){
                                buySupplierRelationship = table['BUYER_SUPPLIER_RELATIONSHIP']
                                buySupplierRelationshipTotal = table['GrandTotal']
                            }
                        }
                    )
               
                this.setState({ segmentationByDocument: docSegmentation, segDocGrandTotal: docSegTotal, segmentationByBuyProcess: aribaBuyProcess, 
                    segBuyProceeGrandTotal: aribaBuyProcessTotal, segmentationByBuyProcessDelivered:aribaBuyProcessDelivered , segBuyProceeDeliveredGrandTotal:aribaBuyProcessTotalDelivered
                    ,  segmentationBySpend: spendSegmentation, segSpendGrandTotal: spendSegTotal, buyerSupplierRelationship: buySupplierRelationship,
                    buyerSupplierRelationshipGrandTotal: buySupplierRelationshipTotal, isLoading:false });
                
                this.props.enablementInsightSuccess(true)
				}
			}).catch((error) => {
				if (error.stack.includes('Network Error')) {
                    this.setState({ responseMsg: 'API service is down. Please contact administrator', isError: 'Yes' })
					this.props.enablementInsightFailure('API service is down. Please contact administrator.')
				}
				else {
                    this.setState({ responseMsg: 'An error has occurred', isError: 'Yes' })
					this.props.enablementInsightFailure(this.props.content.error)
				}
			})
    }
    
    componentWillReceiveProps(nextProps, nextState){
        if (this.state.Test !== nextProps.filterData && 'dedupStats' in nextProps.filterData)
        {
        var inputParameter = this.state.inputParam
        inputParameter.dedupStats = nextProps.filterData.dedupStats
        inputParameter.enabledCustomer= nextProps.filterData.enabledCustomer
        inputParameter.transactingCustomer= nextProps.filterData.transactingCustomer
        inputParameter.buyerRegion= nextProps.filterData.buyerRegion
        inputParameter.businessUnit= nextProps.filterData.businessUnit
        inputParameter.erpSystem= nextProps.filterData.erpSystem
        inputParameter.l4IndustryName= nextProps.filterData.l4IndustryName
        inputParameter.erpCode= nextProps.filterData.erpCode 
        inputParameter.erpDesc= nextProps.filterData.erpDesc 
        inputParameter.sicCode= nextProps.filterData.sicCode 
        inputParameter.sicDesc= nextProps.filterData.sicDesc
        inputParameter.goods= nextProps.filterData.goods
        inputParameter.invoiceCountry= nextProps.filterData.invoiceCountry
        inputParameter.legalInvoice= nextProps.filterData.legalInvoice
        inputParameter.aribaNwEligible= nextProps.filterData.aribaNwEligible
        inputParameter.metricsType= nextProps.filterData.metricsType
        inputParameter.directSpend= nextProps.filterData.directSpend

        this.GetSpendDocBuyProcessDetails(inputParameter)
        this.setState({Test:nextProps.filterData , inputParam: Object.keys(nextProps.filterData).length !== 0 ? nextProps.filterData : this.state.inputParam})
        //console.log("if enablemtn details filetr props-")
        }
        else{
          //  console.log("else enablemtn details filetr props-")
        }
        
    }

    render()
    {
        return(
            <div className="grid-x" style={{ width: '100%'}}>
                
                <div className="medium-12">
                        {(this.state.isLoading) ?
                            <img style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={path + `images/ajax-loader.gif`} alt="" />
                            : null
                        }
                        {(this.state.isError === 'Yes') ?
                            <div style={{ color: 'red', textAlign: 'center' }} className="cell pt5">{this.state.responseMsg}</div>
                            : <div style={{ color: 'green', textAlign: 'center' }} className="cell pt5">{this.state.responseMsg}</div>
                        }
                </div>

                <div className="medium-12 marginTop">
                        <div className="grid-x">
                            {/* <div className="medium-6 graphDiv">                               */}

                                                      
                               
                            {/* </div>
                            <div className="medium-6" style={{paddingLeft: '25px'}}>                                */}                            
                             

                                <div className="grid-x">
                                <div className="medium-12">
                                    {this.state.segmentationByBuyProcess !== undefined && this.state.segmentationByBuyProcess !== null ?
                                        <EnablementInsightTabTable
                                            data={this.state.segmentationByBuyProcess}
                                            grandTotal={this.state.segBuyProceeGrandTotal}
                                            content={this.props.content}
                                            tableName={this.props.content.buyerValueBuyProcess.buyProcessSegmentationHeader}
                                            tableSequence={"12"}
                                            showEnablementSupplierDetailsModal={this.showEnablementSupplierDetailsModal}
                                            metricsType= {this.state.inputParam.metricsType}
                                        />
                                        : <div className="pt10">{this.props.content.noDataFound}</div>}
                                        </div>
                                </div> 

                                <div className="grid-x">
                                <div className="medium-12">
                                    {this.state.segmentationByBuyProcessDelivered !== undefined && this.state.segmentationByBuyProcessDelivered !== null ?
                                        <EnablementInsightTabTable
                                            data={this.state.segmentationByBuyProcessDelivered}
                                            grandTotal={this.state.segBuyProceeDeliveredGrandTotal}
                                            content={this.props.content}
                                            tableName={this.props.content.buyerValueBuyProcess.buyProcessSegmentationDeliveryHeader}
                                            tableSequence={"12"}
                                            showEnablementSupplierDetailsModal={this.showEnablementSupplierDetailsModal}
                                            metricsType= {this.state.inputParam.metricsType}
                                        />
                                        : <div className="pt10">{this.props.content.noDataFound}</div>}
                                        </div>
                                </div>   

                                <div className="grid-x">
                                <div className="medium-12">
                                          {this.state.segmentationByDocument !== undefined && this.state.segmentationByDocument !== null ?
                                         <EnablementInsightTabTable
                                             data={this.state.segmentationByDocument}
                                             grandTotal={this.state.segDocGrandTotal}
                                             content={this.props.content}
                                             tableName={this.props.content.buyerValueBuyProcess.docSegmentationHeader}
                                             tableSequence={"13"}
                                             showEnablementSupplierDetailsModal={this.showEnablementSupplierDetailsModal}
                                             metricsType= {this.state.inputParam.metricsType}
                                         />
                                         : <div className="pt10">{this.props.content.noDataFound}</div>}
                                         </div>
                             </div>     
                             
                             <div className="grid-x">  
                                <div className="medium-12">                                  
                                    {this.state.segmentationBySpend !== undefined && this.state.segmentationBySpend !== null ?
                                        <EnablementInsightTabTable
                                            data={this.state.segmentationBySpend}
                                            grandTotal={this.state.segSpendGrandTotal}
                                            content={this.props.content}
                                            tableName={this.props.content.buyerValueBuyProcess.spendSegmentationHeader}
                                            tableSequence={"14"}
                                            showEnablementSupplierDetailsModal={this.showEnablementSupplierDetailsModal}
                                            metricsType= {this.state.inputParam.metricsType}
                                        />
                                        : <div className="pt10">{this.props.content.noDataFound}</div>}
                                        </div>
                                </div>  

                                <div className="grid-x">  
                                <div className="medium-12">                                  
                                    {this.state.buyerSupplierRelationship !== undefined && this.state.buyerSupplierRelationship !== null ?
                                        <EnablementInsightTabTable
                                            data={this.state.buyerSupplierRelationship}
                                            grandTotal={this.state.buyerSupplierRelationshipGrandTotal}
                                            content={this.props.content}
                                            tableName={this.props.content.buyerValueBuyProcess.buyerSupplierRelationshipHeader}
                                            tableSequence={"15"}
                                            showEnablementSupplierDetailsModal={this.showEnablementSupplierDetailsModal}
                                            metricsType= {this.state.inputParam.metricsType}
                                        />
                                        : <div className="pt10">{this.props.content.noDataFound}</div>}
                                        </div>
                                </div>                            
                               
                            {/* </div> */}
                        </div>
                    </div>
                 
                 <div>  
                  {this.refs.enablementSupplier ? this.state.openPopup ?
                    this.refs.enablementSupplier.show() :
                    this.refs.enablementSupplier.hide() : ''}        
                  <Modal
                    ref={'enablementSupplier'}
                    modalStyle={changeModalBorderStyle}
                    contentStyle={drilldownModalBorderContentStyle}
                    rectStyle={changeModalBorderRectStyle}                   
                    keyboard={false}
                    backdrop={false}
                   >
                    <div className="grid-x tabs_container supplier-tabs">                       
                        <div className="medium-12">                        
                            {
                                <EnablementSupplierDetailsPopup onSave={this.onSave} input={this.state.inputParam} outsidePopupClick={this.outsidePopupClick} ></EnablementSupplierDetailsPopup>
                            }
                        </div>
                    </div>
                    <button onClick={() => { this.setVisibility(false); this.setState({ selectedIndustryName: {} }) }} className='close' />
                </Modal>
                  </div>
                  
               </div>                   
        )
    }

}


const mapStateToProps = state => {
	return {
        content: state.content.enablementInsight,
        //content: state.content.subLeverCalculations,
		ui: state.content.ui,
		currency: decodeURIComponent(state.valueStory.Currency),
		userData: state.valueStory,
		spendAsPercentageOfRevenue: state.spendAsPercentageOfRevenue,
		benchMarkCalculations: state.benchMarksData,
		allContent: state.content,
        language: state.language,
        spendDetails: state.spendDetails,
        user: state.default
	}
}

const mapDispatchToProps = dispatch => {
	return {
        enablementInsightSuccess: message => dispatch(enablementInsightSuccess(message)),
        enablementInsightFailure: error => dispatch(enablementInsightFailure(error)),
        enablementInsightLoading: bool => dispatch(enablementInsightLoading(bool)),
        resetError: error => dispatch(resetError(error)),
        getSpendDocBuyProcessDetails: (data) => dispatch(getSpendDocBuyProcessDetails(data)),
        
	}
}

export default connect(mapStateToProps, mapDispatchToProps) (SpendDocumentBuyProcessTab)