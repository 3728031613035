import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

// Components
import TabPanel from '../Tabs/TabPanel'

import ValueStoryContainer from 'ValueStory/ValueStoryContainer'

import {
	fetchValueStoriesLoading,
	fetchValueStoriesSuccess,
	fetchValueStoriesFailure,
} from 'actions/userActions'

import SharedValueStories from './SharedValueStories';
import DiscoveryVsSearch from '../Discovery/DiscoveryVsSearch'

class MyWorkContainer extends PureComponent {

	constructor(props) {
		super(props)

		this.state = {
			tabId: 1,
			sharedValueStory: []
		}

		this.dispatchTabChange = this.dispatchTabChange.bind(this)
		this.intializePrioritiesTabs = this.intializePrioritiesTabs.bind(this)
		this.closeVsListPopUp = this.closeVsListPopUp.bind(this)

		this.hideModal = this.hideModal.bind(this)
	}

	hideModal() {
		this.props.hideModal('valueStory')
	}

	intializePrioritiesTabs() {
		const tabs = [
			{
				id: 1,
				label: this.props.menu.valueStory.yourValueStories,
				component: (<div className="grid-x"><ValueStoryContainer content={this.props.menu.valueStory} history={this.props.history} hideModal={this.hideModal} /></div>)
			},
			{
				id: 2,
				label: this.props.menu.valueStory.sharedValueStories,
				component: (<div className="grid-x"><SharedValueStories content={this.props.menu.valueStory} history={this.props.history} hideModal={this.hideModal} /></div>)
			}
			,
			{
				id: 3,
				label: this.props.menu.valueStory.existingValueStoriesHeader,
				component: (<div className="grid-x">
					<DiscoveryVsSearch
						// valueStoriesShared={this.state.valueStoriesShared}
						contentData={this.props.contentData}
						// capIqRadioButtonClicked={this.capIqRadioButtonClicked}
						// capIqDataCleared={this.capIqDataCleared}
						close={this.closeVsListPopUp}
						contentVs={this.props.menu.valueStory} history={this.props.history} hideModal={this.hideModal} />
				</div>)
			}
		];

		this.setState({
			tabs: tabs,
			tabId: 1
		})
	}

	closeVsListPopUp() {
		this.setState({ openVSList: !this.state.openVSList, vsLoad: '' })
	}

	dispatchTabChange(tabId) {
		this.setState({
			tabId: tabId,
			nextButtonLabel: this.props.ui.next
		})
	}

	dispatchLastButtonClick(tabId) {
		this.props.history.push(`/next-steps`)
	}

	componentWillMount() {
		this.intializePrioritiesTabs()
	}

	render() {
		return (
			<div className="myWorkTabPanel">
				<TabPanel tabs={this.state.tabs}
					tabId={1}
					dispatchTabChange={this.dispatchTabChange} />
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		ui: state.content.ui,
		content: state.content,
		menu: state.content.menu,
		user: state.default,
		contentData: state.content.discovery.form,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		fetchValueStoriesLoading: (bool) => dispatch(fetchValueStoriesLoading(bool)),
		fetchValueStoriesSuccess: (valueStories) => dispatch(fetchValueStoriesSuccess(valueStories)),
		fetchValueStoriesFailure: (error) => dispatch(fetchValueStoriesFailure(error)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MyWorkContainer)