import React from 'react'
import { connect } from 'react-redux'
import CustomGrid from 'UI/CustomGrid'
import { Filters, Editors } from "react-data-grid-addons";
import { AlignFormatter, CenterAlignFormatter, getNonEmptyValue } from "../../utils/Utils";

// Actions
import {
    fetchL4IndustryValues,
    uploadL4IndustryPriorityData,
    userDetailsSuccess,
    userDetailsFailure,
    userDetailsLoading,
    resetError,
}
    from 'actions/spendActions'

const {
    NumericFilter,
    AutoCompleteFilter,
} = Filters;

class IndustryPrioratization extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rawData: [],
            l4Industries: [],
            industryList: [],
            data: []
        }

        this.loadData = this.loadData.bind(this)
        this.save = this.save.bind(this)
    }

    componentWillMount() {
        this.loadData()
    }

    loadData() {
        this.props.fetchL4IndustryValues()
            .then((response) => {
                this.props.userDetailsSuccess(response.data.Message)
                let data = response.data.Result;

                let tempIndustryList = []
                let tempL4Industry = []
                for (let i = 0; i < data.length; i++) {
                    tempIndustryList.push(data[i]['AVATAR_INDUSTRY_NAME'])
                    tempL4Industry.push(data[i]['L4_INDUSTRY_NAME'])
                }
                //let uniqueItems = [...new Set(items)]
                let industryList = [...new Set(tempIndustryList)]

                let l4Industries = [...new Set(tempL4Industry)]
                let columns = this.initializeColumns(industryList);

                let tableData = []

                for (let j = 0; j < l4Industries.length; j++) {
                    let level = data.filter(el => el.L4_INDUSTRY_NAME == l4Industries[j])
                    let obj = {}
                    industryList.map(el => obj[el] = '')

                    obj.INDUSTRY_LEVEL = level[0]['INDUSTRY_LEVEL']
                    obj.L4_INDUSTRY_GICS_ID = level[0]['L4_INDUSTRY_GICS_ID']
                    obj.L4_INDUSTRY_NAME = level[0]['L4_INDUSTRY_NAME']
                    for (let k = 0; k < industryList.length; k++) {
                        if (industryList[k] == level[k]['AVATAR_INDUSTRY_NAME']) obj[industryList[k]] = level[k]['PRIORITY_NUM']
                    }
                    tableData.push(obj)
                }
                this.setState({ rawData: data, industryList: industryList, l4Industries: l4Industries, columns: columns, data: tableData })
            }).catch((error) => {
                if (error.stack.includes('Network Error')) {
                    this.props.userDetailsFailure()
                }
            })
    }

    initializeColumns(industryList) {
        let columns = []

        let tableColumn = {
            name: 'Industry Level',
            key: 'INDUSTRY_LEVEL',
            width: 120,
            sortable: true,
            editable: false,
            resizable: true,
            filterable: true,
            screenSeq: 0,
            mandatory: 'No',
            exportFl: true,
            //editProperty: fieldConfig.is_editable,
            formatter: CenterAlignFormatter,
            helpText: '',
            filterRenderer: AutoCompleteFilter
        }

        columns.push(tableColumn);

        tableColumn = {
            name: 'L4 GICS Code',
            key: 'L4_INDUSTRY_GICS_ID',
            width: 120,
            sortable: true,
            editable: false,
            resizable: true,
            filterable: true,
            screenSeq: 1,
            mandatory: 'No',
            exportFl: true,
            //editProperty: fieldConfig.is_editable,
            formatter: CenterAlignFormatter,
            helpText: '',
            filterRenderer: AutoCompleteFilter
        }

        columns.push(tableColumn);

        tableColumn = {
            name: 'L4 Industry Name',
            key: 'L4_INDUSTRY_NAME',
            width: 120,
            sortable: true,
            editable: false,
            resizable: true,
            filterable: true,
            screenSeq: 1,
            mandatory: 'No',
            exportFl: true,
            //editProperty: fieldConfig.is_editable,
            formatter: CenterAlignFormatter,
            helpText: '',
            filterRenderer: AutoCompleteFilter
        }

        columns.push(tableColumn);

        for (let i = 0; i < industryList.length; i++) {
            //const fieldConfig = industryList[i];

            tableColumn = {
                name: industryList[i],
                key: industryList[i],
                width: 120,
                sortable: true,
                editable: this.props.user.userprofile.Roles[0].indexOf("Admin") > -1 ? true : false,
                resizable: true,
                filterable: true,
                screenSeq: 1,
                mandatory: 'No',
                exportFl: true,
                //editProperty: fieldConfig.is_editable,
                formatter: CenterAlignFormatter,
                helpText: '',
                filterRenderer: AutoCompleteFilter
            }

            columns.push(tableColumn);
        }

        return columns;
    }

    convertDataForSave(data) {
        const d = new Date();
        let industryList = this.state.industryList;
        let finalData = []
        for (let i = 0; i < data.length; i++) {

            for (var key in data[i]) {
                if (key !== 'INDUSTRY_LEVEL' && key !== 'L4_INDUSTRY_GICS_ID' && key !== 'L4_INDUSTRY_NAME') {

                    let obj = {}
                    obj.INDUSTRY_LEVEL = data[i]['INDUSTRY_LEVEL']
                    obj.L4_INDUSTRY_GICS_ID = data[i]['L4_INDUSTRY_GICS_ID']
                    obj.L4_INDUSTRY_NAME = data[i]['L4_INDUSTRY_NAME']
                    obj.USER_ID = this.props.user.user.Email;
                    obj.DATA_LOAD_DATE = d.getDate()
                    obj.AVATAR_INDUSTRY_NAME = key
                    obj.PRIORITY_NUM = data[i][key]

                    finalData.push(obj)
                }
            }
        }
        return finalData;
    }

    save(data) {
        this.setState({ responseMsg: '' })
        let rowData = data.length > 0 ? data : this.state.data;
        const recordsToInsert = this.convertDataForSave(rowData);
        this.setState({ isLoading: true, isSaveInProgress: true })
        // this.props.defineScopeLoading(true)
        //call hana procedure for save here
        return this.props.uploadL4IndustryPriorityData(recordsToInsert)
            // .then((response) => {
            //     if (response.status !== undefined && response.status === 200) this.loadData()
            //     // this.props.defineScopeLoading(false)
            // })
    }

    render() {
        return (
            <div>
                <CustomGrid
                    columns={this.state.columns}
                    topMargin={true}
                    data={this.state.data}
                    keyColumn="L4_INDUSTRY_GICS_ID"
                    isEditable= {this.props.user.userprofile.Roles[0].indexOf("Admin") > -1 ? true : false}
                    save={this.props.user.userprofile.Roles[0].indexOf("Admin") > -1 ? this.save : null}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.default,
        language: state.language,
        content: state.content.menu.EnablementParametersManager[0]
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchL4IndustryValues: () => dispatch(fetchL4IndustryValues()),
        userDetailsLoading: (bool) => dispatch(userDetailsLoading(bool)),
        userDetailsSuccess: (bool) => dispatch(userDetailsSuccess(bool)),
        userDetailsFailure: (error) => dispatch(userDetailsFailure(error)),
        resetError: () => dispatch(resetError()),
        uploadL4IndustryPriorityData: (data) => dispatch(uploadL4IndustryPriorityData(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(IndustryPrioratization)