import React from "react";
import { BreakLine, roundFix } from 'utils/Utils'
import { connect } from 'react-redux'
import {
    getManualKPIResourceTypeLoadData,
} from 'actions/kpiActions'
import {
    resetError,
    defineScopeLoading,
    pendingChanges
} from 'actions/spendActions'
import cloneDeep from 'lodash/cloneDeep';
import Popup from "reactjs-popup";

class EnablementKPITable extends React.Component {
    constructor(props) {
        super(props)
        {
            this.state = {
                resourceHeaders: ['Resource Type', 'Account Type', 'Wave 1', 'Wave 2', 'Wave 3', 'Wave 4', 'Wave 5', 'Wave 6', 'Wave 7', 'Wave 8', 'Wave 9', 'Wave 10', 'Wave 11', 'Wave 12'],
                resourceTypeData: [],
                resourceTypeDataCopy: [],
                expandRowOrder: [1, 2, 0, 3, 4],
                collapseRowOrder: [0, 1, 2, 3, 4],
                isError: 'No',
                errorMsg: '',
                responseMsg: '',
                isExpand: false,
                showHideRow: true,
                errorCL: '',
                errorSEL: '',
                errorSIL: '',
            }
        }
        this.loadResponsetype = this.loadResponsetype.bind(this);
        this.createResourceTypeData = this.createResourceTypeData.bind(this);
        this.createResourceTypeObject = this.createResourceTypeObject.bind(this);
        this.showHideRow = this.showHideRow.bind(this);
        this.sortResourceTypeGrid = this.sortResourceTypeGrid.bind(this);
    }

    onExpandClick(e) {
        this.setState({ isExpand: !this.state.isExpand });
    }

    sortResourceTypeGrid(order) {
        let data = [];
        for (let i = 0; i < this.state.resourceTypeDataCopy.length; i++) {
            data.push(this.state.resourceTypeDataCopy[order[i]]);
        }
        this.setState({ resourceTypeData: data });
    }

    componentDidUpdate(nextProps) {
        if (
            // nextProps.kpiSelectedButton != this.props.kpiSelectedButton || 
            (nextProps.kpiModel != this.props.kpiModel ||
                nextProps.isRefresh != this.props.isRefresh ||
                nextProps.enablementResourceParameters != this.props.enablementResourceParameters)
            && this.props.isRefresh
        ) {
            this.setState({ isExpand: false })
            this.loadResponsetype();
        }

    }


    loadResponsetype() {
        this.setState({ isError: 'No', errorMsg: '', responseMessage: '', showHideRow: true, errorSEL: '', errorCL: '', errorSIL: '' })
        let dmcId = this.props.spendDetails.currentActiveRequest.DMC_ID;
        let vsId = this.props.spendDetails.currentActiveRequest.VS_ID;
        let user = this.props.user.user.Email;
        let model = this.props.kpiModel;

        this.props.defineScopeLoading(true)
        //this.setState({ isLoading: true })
        this.props.getManualKPIResourceTypeLoadData(dmcId, vsId, user, model)
            .then((response) => {
                if (response.data.Result.length > 0) {
                    let spData = response.data.Result;
                    let tableData = this.createResourceTypeData(spData);
                    this.props.defineScopeLoading(false);
                    //this.setState({ resourceTypeData: tableData, isLoading: false });
                    // // let data = [];

                    // // data.push(tableData[1]);
                    // // data.push(tableData[2]);
                    // // data.push(tableData[0]);
                    // // data.push(tableData[3]);
                    // // data.push(tableData[4]);


                    this.setState({ resourceTypeDataCopy: tableData, showHideRow: false });
                    //this.sortResourceTypeGrid(this.state.expandRowOrder);
                    if (this.state.showHideRow)
                        this.sortResourceTypeGrid(this.state.expandRowOrder);
                    else
                        this.sortResourceTypeGrid(this.state.collapseRowOrder);
                    this.props.EnablementStrategyrefreshResourceTable(false);
                    //this.props.EnablementStrategyrefreshPriorityTable(true);
                } else {
                    this.props.defineScopeLoading(false)
                    //this.setState({ errorMsg: 'No result found. Please try reimporting the file.', isError: 'Yes', isLoading: false })
                }
                this.setState({ isExpand: this.props.spendDetails.kpi_Model == 1 ? false : true })

            }).catch((error) => {
                if (error.stack.includes('Network Error')) {
                    this.props.defineScopeLoading(false)
                    //this.setState({ errorMsg: 'API service is down. Please contact administrator.', isError: 'Yes', isLoading: false })
                }
                else {
                    this.props.defineScopeLoading(false)
                    //this.setState({ errorMsg: this.props.content.error, isError: 'Yes', isLoading: false })
                }
            })
    }

    createResourceTypeData(spData) {
        let tableData = [];

        let kpiArr = [...new Set(spData.map(item => item.RESOURCE_TYPE))];
        let kpiArr_Acc_Type = [...new Set(spData.map(item => item.ACCOUNT_TYPE))];

        //for every unique kpi name create table data for 12 quaters
        for (let i = 0; i < kpiArr.length; i++) {
            let temp = spData.filter(item => item.RESOURCE_TYPE == kpiArr[i])
            for (let i = 0; i < kpiArr_Acc_Type.length; i++) {
                let tempNew = temp.filter(item => item.ACCOUNT_TYPE == kpiArr_Acc_Type[i])
                if (tempNew.length > 0) {
                    let obj = this.createResourceTypeObject(tempNew);
                    tableData.push(obj)
                }
            }
        }

        //Sum the Supplier Enablement Lead Enterprise + Supplier Enablement Lead Standard and add it in first row position

        tableData.unshift(cloneDeep(tableData[0]));

        tableData[0].ACCOUNT_TYPE = 'Total';
        tableData[0].RESOURCE_VALUE_Q1 = tableData[1].RESOURCE_VALUE_Q1 + tableData[2].RESOURCE_VALUE_Q1;
        tableData[0].RESOURCE_VALUE_Q2 = tableData[1].RESOURCE_VALUE_Q2 + tableData[2].RESOURCE_VALUE_Q2;
        tableData[0].RESOURCE_VALUE_Q3 = tableData[1].RESOURCE_VALUE_Q3 + tableData[2].RESOURCE_VALUE_Q3;
        tableData[0].RESOURCE_VALUE_Q4 = tableData[1].RESOURCE_VALUE_Q4 + tableData[2].RESOURCE_VALUE_Q4;
        tableData[0].RESOURCE_VALUE_Q5 = tableData[1].RESOURCE_VALUE_Q5 + tableData[2].RESOURCE_VALUE_Q5;
        tableData[0].RESOURCE_VALUE_Q6 = tableData[1].RESOURCE_VALUE_Q6 + tableData[2].RESOURCE_VALUE_Q6;
        tableData[0].RESOURCE_VALUE_Q7 = tableData[1].RESOURCE_VALUE_Q7 + tableData[2].RESOURCE_VALUE_Q7;
        tableData[0].RESOURCE_VALUE_Q8 = tableData[1].RESOURCE_VALUE_Q8 + tableData[2].RESOURCE_VALUE_Q8;
        tableData[0].RESOURCE_VALUE_Q9 = tableData[1].RESOURCE_VALUE_Q9 + tableData[2].RESOURCE_VALUE_Q9;
        tableData[0].RESOURCE_VALUE_Q10 = tableData[1].RESOURCE_VALUE_Q10 + tableData[2].RESOURCE_VALUE_Q10;
        tableData[0].RESOURCE_VALUE_Q11 = tableData[1].RESOURCE_VALUE_Q11 + tableData[2].RESOURCE_VALUE_Q11;
        tableData[0].RESOURCE_VALUE_Q12 = tableData[1].RESOURCE_VALUE_Q12 + tableData[2].RESOURCE_VALUE_Q12;

        var enterpriseStandardFTE = this.props.enablementResourceParameters[0].FTES;
        tableData[0].RESOURCE_COLOR_Q1 = tableData[0].RESOURCE_VALUE_Q1 > enterpriseStandardFTE ? 'red' : 'black';
        tableData[0].RESOURCE_COLOR_Q2 = tableData[0].RESOURCE_VALUE_Q2 > enterpriseStandardFTE ? 'red' : 'black';
        tableData[0].RESOURCE_COLOR_Q3 = tableData[0].RESOURCE_VALUE_Q3 > enterpriseStandardFTE ? 'red' : 'black';
        tableData[0].RESOURCE_COLOR_Q4 = tableData[0].RESOURCE_VALUE_Q4 > enterpriseStandardFTE ? 'red' : 'black';
        tableData[0].RESOURCE_COLOR_Q5 = tableData[0].RESOURCE_VALUE_Q5 > enterpriseStandardFTE ? 'red' : 'black';
        tableData[0].RESOURCE_COLOR_Q6 = tableData[0].RESOURCE_VALUE_Q6 > enterpriseStandardFTE ? 'red' : 'black';
        tableData[0].RESOURCE_COLOR_Q7 = tableData[0].RESOURCE_VALUE_Q7 > enterpriseStandardFTE ? 'red' : 'black';
        tableData[0].RESOURCE_COLOR_Q8 = tableData[0].RESOURCE_VALUE_Q8 > enterpriseStandardFTE ? 'red' : 'black';
        tableData[0].RESOURCE_COLOR_Q9 = tableData[0].RESOURCE_VALUE_Q9 > enterpriseStandardFTE ? 'red' : 'black';
        tableData[0].RESOURCE_COLOR_Q10 = tableData[0].RESOURCE_VALUE_Q10 > enterpriseStandardFTE ? 'red' : 'black';
        tableData[0].RESOURCE_COLOR_Q11 = tableData[0].RESOURCE_VALUE_Q11 > enterpriseStandardFTE ? 'red' : 'black';
        tableData[0].RESOURCE_COLOR_Q12 = tableData[0].RESOURCE_VALUE_Q12 > enterpriseStandardFTE ? 'red' : 'black';

        for (let j = 1; j <= 12; j++) {
            if (this.state.errorSEL === '' && tableData[0]['RESOURCE_VALUE_Q' + j] > enterpriseStandardFTE)
                this.setState({ errorSEL: 'Supplier Enablement Lead' });
        }

        let err = '';
        err += this.state.errorSEL != '' ? ',' + this.state.errorSEL : '';
        err += this.state.errorCL != '' ? ',' + this.state.errorCL : '';
        err += this.state.errorSIL != '' ? ',' + this.state.errorSIL : '';

        err = err.substring(1, err.length);

        if (err != '')
            this.setErrorMsg('This Enablement Strategy exceeds the FTEs defined in the Enablement Resources per wave for ' + err + '.', '');

        return tableData;
    }

    createResourceTypeObject(kpiArr) {
        let obj = {}
        for (let i = 0; i < kpiArr.length; i++) {
            obj.VS_ID = kpiArr[i]['VS_ID']
            obj.DMC_ID = kpiArr[i]['DMC_ID']
            obj.RESOURCE_TYPE = kpiArr[i]['RESOURCE_TYPE']
            obj.ACCOUNT_TYPE = kpiArr[i]['ACCOUNT_TYPE']
            for (let j = 1; j <= 12; j++) {
                if (kpiArr[i]['QUARTER_NO'] == 'Q' + j) obj['RESOURCE_VALUE_Q' + j] = kpiArr[i]['RESOURCE_VALUE'];
                // if (obj.RESOURCE_TYPE == 'Supplier Enablement Lead' && obj.ACCOUNT_TYPE == 'Enterprise' && kpiArr[i]['QUARTER_NO'] == 'Q' + j) {
                //     obj['RESOURCE_COLOR_Q' + j] = kpiArr[i]['RESOURCE_VALUE'] > this.props.enablementResourceParameters[1].FTES ? 'red' : 'black';
                //     if (this.state.isError === 'No' && kpiArr[i]['RESOURCE_VALUE'] > this.props.enablementResourceParameters[1].FTES)
                //         this.setErrorMsg('This E-Strategy exceeds SAP Best Practices for FTEs per wave.', '');
                // } 
                // else if (obj.RESOURCE_TYPE == 'Supplier Enablement Lead' && obj.ACCOUNT_TYPE == 'Standard' && kpiArr[i]['QUARTER_NO'] == 'Q' + j) {
                //     obj['RESOURCE_COLOR_Q' + j] = kpiArr[i]['RESOURCE_VALUE'] > this.props.enablementResourceParameters[1].FTES ? 'red' : 'black';
                //     if (this.state.isError === 'No' && kpiArr[i]['RESOURCE_VALUE'] > this.props.enablementResourceParameters[1].FTES)
                //         this.setErrorMsg('This E-Strategy exceeds SAP Best Practices for FTEs per wave.', '');
                // } 
                // else 
                if (obj.RESOURCE_TYPE == 'Catalog Lead' && kpiArr[i]['QUARTER_NO'] == 'Q' + j) {
                    obj['RESOURCE_COLOR_Q' + j] = kpiArr[i]['RESOURCE_VALUE'] > this.props.enablementResourceParameters[2].FTES ? 'red' : 'black';
                    if (this.state.errorCL === '' && kpiArr[i]['RESOURCE_VALUE'] > this.props.enablementResourceParameters[2].FTES)
                        this.setState({ errorCL: 'Catalog Lead' });
                    // if (this.state.isError === 'No' && kpiArr[i]['RESOURCE_VALUE'] > this.props.enablementResourceParameters[2].FTES)
                    //     this.setErrorMsg('This E-Strategy exceeds SAP Best Practices for FTEs per wave.', '');
                } else if (obj.RESOURCE_TYPE == 'Seller Integration Lead' && kpiArr[i]['QUARTER_NO'] == 'Q' + j) {
                    obj['RESOURCE_COLOR_Q' + j] = kpiArr[i]['RESOURCE_VALUE'] > this.props.enablementResourceParameters[3].FTES ? 'red' : 'black';
                    // if (this.state.isError === 'No' && kpiArr[i]['RESOURCE_VALUE'] > this.props.enablementResourceParameters[3].FTES)
                    //     this.setErrorMsg('This E-Strategy exceeds SAP Best Practices for FTEs per wave.', '');
                    if (this.state.errorSIL === '' && kpiArr[i]['RESOURCE_VALUE'] > this.props.enablementResourceParameters[3].FTES)
                        this.setState({ errorSIL: 'Seller Integration Lead' });
                }
            }
        }
        return obj;
    }

    setErrorMsg(ErrorMessage, ResponseMessage) {
        this.setState({ isError: 'Yes', errorMsg: ErrorMessage, responseMessage: ResponseMessage })
    }
    showHideRow() {
        this.setState({ showHideRow: !this.state.showHideRow });
        if (this.state.showHideRow)
            this.sortResourceTypeGrid(this.state.collapseRowOrder);
        else
            this.sortResourceTypeGrid(this.state.expandRowOrder);
    }

    render() {

        return (
            <div className="grid-x medium-12">
                <div className="grid-x medium-12 heading-with-below-border">
                    <div className="medium-11 float-left">{this.props.content.subTitle}

                        <Popup className="report-tooltip"
                            trigger={<img style={{ marginLeft: '5px', paddingBottom: '20px' }} className="info-icon" src="images/info-icon.svg" width="15" />}
                            position="bottom left"
                            on="hover"
                        >
                            <div>
                                {this.props.content.headerIcon}
                            </div>
                        </Popup>

                    </div>
                    <div className="medium-1" style={{ textAlign: 'right', cursor: 'pointer', fontSize: '1.6rem' }} onClick={(e) => this.onExpandClick(e)} >{this.state.isExpand ? "-" : "+"}</div>
                </div>
                {this.state.isExpand ?
                    <div className="grid-x medium-12">
                        <div className="medium-12 float-left pt10">
                            {(this.state.isError === 'Yes') ?
                                <div style={{ color: 'red', textAlign: 'center' }} className="cell pt5">{this.state.errorMsg}</div>
                                : <div style={{ color: 'green', textAlign: 'center' }} className="cell pt5">{this.state.responseMsg}</div>
                            }
                        </div>
                        <table className='kpiResourceTable'>
                            {this.state.resourceHeaders.map((el, index) =>
                                index == 0 ?
                                    <col style={{ width: '11.6%' }}></col> :
                                    <col style={{ width: '6.8%' }}></col>
                            )}
                            <tr>
                                {this.state.resourceHeaders.map(el => <th >{el}</th>)}
                            </tr>
                            <tbody>
                                {this.state.resourceTypeData.map((el, index) =>
                                    (this.state.showHideRow || (!this.state.showHideRow && index != 1 && index != 2)) ?
                                        <tr key={index}>
                                            {/* {index == 0 ? <td onClick={(e) => this.showHideRow()}>{el.RESOURCE_TYPE} <span style={{ textAlign: 'right', cursor: 'pointer', fontSize: '1.6rem', float: 'right' }}>{this.state.showHideRow ? "-" : "+"}</span></td>
                                                : index == 1 ? <td rowspan='2'>{el.RESOURCE_TYPE}</td>
                                                    : index == 2 ? null
                                                        : <td>{el.RESOURCE_TYPE}</td>
                                            } */}

                                            {(index == 0 && this.state.showHideRow) ? <td style={{ verticalAlign: 'bottom' }} rowspan='3' onClick={(e) => this.showHideRow()}>{el.RESOURCE_TYPE} <span style={{ textAlign: 'right', cursor: 'pointer' }}>{this.state.showHideRow ? "-" : "+"}</span></td>
                                                : (index == 0 && !this.state.showHideRow) ? <td onClick={(e) => this.showHideRow()}>{el.RESOURCE_TYPE} <span style={{ textAlign: 'right', cursor: 'pointer' }}>{this.state.showHideRow ? "-" : "+"}</span></td>
                                                    : ((index == 1 || index == 2) && this.state.showHideRow) ? null
                                                        //: index == 2 ? 
                                                        : <td>{el.RESOURCE_TYPE}</td>
                                            }
                                            <td style={{ width: '15% !important', color: (this.state.showHideRow && (index == 0 || index == 1)) ? '#B9B7B7' : 'black' }}>
                                                {
                                                    el.ACCOUNT_TYPE
                                                }
                                                {(!this.state.showHideRow && (index == 0 || index == 1)) || (this.state.showHideRow && index == 2) ?
                                                    <Popup className="report-tooltip"
                                                        trigger={<img style={{ marginLeft: '5px' }} className="info-icon" src="images/info-icon.svg" width="15" />}
                                                        position="bottom left"
                                                        on="hover"
                                                    >
                                                        <div>
                                                            {this.props.content.kpiTotalHeaderIcon}
                                                        </div>
                                                    </Popup>
                                                    : null}
                                            </td>
                                            <td style={{ color: (this.state.showHideRow && (index == 0 || index == 1)) ? '#B9B7B7' : el.RESOURCE_COLOR_Q1 }}> {roundFix(el.RESOURCE_VALUE_Q1, 3)} </td>
                                            <td style={{ color: (this.state.showHideRow && (index == 0 || index == 1)) ? '#B9B7B7' : el.RESOURCE_COLOR_Q2 }}> {roundFix(el.RESOURCE_VALUE_Q2, 3)} </td>
                                            <td style={{ color: (this.state.showHideRow && (index == 0 || index == 1)) ? '#B9B7B7' : el.RESOURCE_COLOR_Q3 }}> {roundFix(el.RESOURCE_VALUE_Q3, 3)} </td>
                                            <td style={{ color: (this.state.showHideRow && (index == 0 || index == 1)) ? '#B9B7B7' : el.RESOURCE_COLOR_Q4 }}> {roundFix(el.RESOURCE_VALUE_Q4, 3)} </td>
                                            <td style={{ color: (this.state.showHideRow && (index == 0 || index == 1)) ? '#B9B7B7' : el.RESOURCE_COLOR_Q5 }}> {roundFix(el.RESOURCE_VALUE_Q5, 3)} </td>
                                            <td style={{ color: (this.state.showHideRow && (index == 0 || index == 1)) ? '#B9B7B7' : el.RESOURCE_COLOR_Q6 }}> {roundFix(el.RESOURCE_VALUE_Q6, 3)} </td>
                                            <td style={{ color: (this.state.showHideRow && (index == 0 || index == 1)) ? '#B9B7B7' : el.RESOURCE_COLOR_Q7 }}> {roundFix(el.RESOURCE_VALUE_Q7, 3)} </td>
                                            <td style={{ color: (this.state.showHideRow && (index == 0 || index == 1)) ? '#B9B7B7' : el.RESOURCE_COLOR_Q8 }}> {roundFix(el.RESOURCE_VALUE_Q8, 3)} </td>
                                            <td style={{ color: (this.state.showHideRow && (index == 0 || index == 1)) ? '#B9B7B7' : el.RESOURCE_COLOR_Q9 }}> {roundFix(el.RESOURCE_VALUE_Q9, 3)} </td>
                                            <td style={{ color: (this.state.showHideRow && (index == 0 || index == 1)) ? '#B9B7B7' : el.RESOURCE_COLOR_Q10 }}> {roundFix(el.RESOURCE_VALUE_Q10, 3)}</td>
                                            <td style={{ color: (this.state.showHideRow && (index == 0 || index == 1)) ? '#B9B7B7' : el.RESOURCE_COLOR_Q11 }}> {roundFix(el.RESOURCE_VALUE_Q11, 3)}</td>
                                            <td style={{ color: (this.state.showHideRow && (index == 0 || index == 1)) ? '#B9B7B7' : el.RESOURCE_COLOR_Q12 }}> {roundFix(el.RESOURCE_VALUE_Q12, 3)}</td>

                                        </tr>
                                        : null

                                )}
                            </tbody>
                        </table>
                    </div>
                    : null}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.default,
        spendDetails: state.spendDetails,
        content: state.content.spendAnalysis.enablementResourceParamaeters,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        defineScopeLoading: bool => dispatch(defineScopeLoading(bool)),
        getManualKPIResourceTypeLoadData: (DMCId, VSId, userEmail, model) => dispatch(getManualKPIResourceTypeLoadData(DMCId, VSId, userEmail, model))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EnablementKPITable);