import React from 'react'
import { connect } from 'react-redux'
import {
  Route,
  NavLink,
  MemoryRouter
} from "react-router-dom";
import UserEmailPasswordManager from "./UserEmailPasswordManager";
import UserActivationManager from "./UserActivationManager";

class EmailManager extends React.Component {

  render() {
    return (
      <MemoryRouter>
        <div className="popup-content">
          <div className="title">{this.props.content.title}</div>
          <ul className="headers heading mt20">
            <li><NavLink exact to="/">Reset Password</NavLink></li>
            <li><NavLink to="/userActivationManager">UserActivationManager</NavLink></li>
          </ul>
          <div className="content description">
            <Route exact path="/" component={UserEmailPasswordManager} />
            <Route path="/userActivationManager" component={UserActivationManager} />
          </div>
        </div>
      </MemoryRouter>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    language: state.language,
    content: state.content.emailManager
  }
}

export default connect(mapStateToProps)(EmailManager)
