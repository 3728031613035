import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

// Actions
import { updateOrganization, addRevenue, updateCurrency, updateCurrencyCode, capIQFetchData, updateBPID, updateANID } from 'actions/discoveryActions'

import {
	getSPCapitalIQData,
	getSPCapitalIQLoading,
	getSPCapitalIQSuccess,
	getSPCapitalIQFailure,
	getCapIQDataBlank
} from 'actions/spendActions'
import {
	fetchUserValueStories,
	fetchValueStories,
	fetchUserSharedValueStories,
	fetchValueStoriesLoading,
	fetchValueStoriesSuccess,
	fetchValueStoriesFailure,
} from 'actions/userActions'
import {
	toggleValueDriver,
	toggleSubLever
} from 'actions/valueDriverActions'
import { updateUserInput } from 'actions/subLeverCalculationActions'

// Settings

import { modalStyle } from 'Constants'
import {  ScreenE } from 'UI/CustomComponents/Controls';

import LoadRequest from '../Spend/YourSpendFile/LoadRequest';
import AccountParameters from 'components/Spend/YourSpendFile/AccountParameters'

const autoHeightModalStyle = Object.assign({}, modalStyle)
autoHeightModalStyle.height = 'auto'
autoHeightModalStyle.width = '30%'

const defaultOptions = [
	{ value: 0, label: "" }];

export class MatchAndClassificationContainer extends PureComponent {

	constructor(props) {
		super(props)
		this.state = {
			isParamExpand: true
        }
    }

    onExpandClick(e){
        switch(e.target.id){
          case "loadReq":
            this.setState({ isExpand: ! this.state.isExpand})
            break;
          case "spendParam":
            this.setState({ isParamExpand: ! this.state.isParamExpand})
            break;
            case "customerSpend":
              this.setState({ isCustomerSpendExpand: ! this.state.isCustomerSpendExpand})
              break;
        }
      }

	render() {
		return (
			<ScreenE readOnly={!this.props.userData.AllowEdit} lastUpdated={new Date().getTime()}>
				<div className="grid-x medium-12">
{/* 
				<div className="grid-x medium-12 pb10 pt10 tab-title ">
                <div className="grid-x medium-12 heading-with-below-border">
                    <div className="medium-11">{"Collect Customer Spend"}</div>
                    <div className="medium-1" id="customerSpend" style={{ textAlign:'right', cursor:'pointer', fontSize: '1.6rem' }}>
					</div>                    
                </div>
                </div>  */}
				

			<div className="grid-x medium-12 pb10 pt10 tab-title ">
               <div className="grid-x medium-12 heading-with-below-border">
                   <div className="medium-11">{"Match & Classification File"}</div>
                   {/* <div className="medium-1" id="loadReq" style={{ textAlign:'right', cursor:'pointer', fontSize: '1.6rem' }}
                    onClick={(e)=> this.onExpandClick(e)} >{this.state.isExpand ? "-": "+"}</div>                     */}
               </div>
            </div> 
            
            
            <div className="grid-x medium-12">
            <LoadRequest></LoadRequest>
            </div>

            {/* <div className="grid-x medium-12 pb10 pt10 tab-title ">
                <div className="grid-x medium-12 heading-with-below-border">
                    <div className="medium-11">{"Spend Parameter"}</div>
                    <div className="medium-1" id="spendParam" style={{ textAlign:'right', cursor:'pointer', fontSize: '1.6rem' }}
                     onClick={(e)=> this.onExpandClick(e)} >{this.state.isParamExpand ? "-": "+"}</div>                    
                </div>
            </div> 
            
            {this.state.isParamExpand ?
            <div className="grid-x medium-12">              
            <AccountParameters />
            </div>
            : null } */}

			</div>
			</ScreenE>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.default,
		currency: decodeURIComponent(state.valueStory ? state.valueStory.Currency : state.companyProfileData[0].currencyCode),
		spendAsPercentageOfRevenue: state.spendAsPercentageOfRevenue,
		validation: state.content.validation,
		contentPE: state.contentPE,
		companyData: state.companyProfileData,
		selectedValueStory: state.selectedValueStory,
		currencySymbols: state.content.CurrencySymbols,
		userData: state.valueStory,
		newValueStory: state.isNewValueStory,
		searchResultData: state.searchResultData,
		capIQ: state.content.spendAnalysis.detailedFile,
		spendDetails: state.spendDetails
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		fetchUserValueStories: (id) => dispatch(fetchUserValueStories(id)),
		fetchValueStoriesLoading: (bool) => dispatch(fetchValueStoriesLoading(bool)),
		fetchValueStoriesSuccess: (valueStories) => dispatch(fetchValueStoriesSuccess(valueStories)),
		fetchValueStoriesFailure: (error) => dispatch(fetchValueStoriesFailure(error)),
		updateOrganization: (revenue) => dispatch(updateOrganization(revenue)),
		addRevenue: (revenue) => dispatch(addRevenue(revenue)),
		updateCurrency: (currency) => dispatch(updateCurrency(currency)),
		updateCurrencyCode: (currencyCode) => dispatch(updateCurrencyCode(currencyCode)),
		toggleValueDriver: (id, bool) => dispatch(toggleValueDriver(id, bool)),
		toggleSubLever: (id, bool) => dispatch(toggleSubLever(id, bool)),
		handleChange: (value, subLeverId, rowNumber) => dispatch(updateUserInput(value, subLeverId, rowNumber)),
		fetchValueStories: (id) => dispatch(fetchValueStories(id)),
		fetchUserSharedValueStories: (id) => dispatch(fetchUserSharedValueStories(id)),
		getSPCapitalIQSuccess: message => dispatch(getSPCapitalIQSuccess(message)),
		getSPCapitalIQFailure: error => dispatch(getSPCapitalIQFailure(error)),
		getSPCapitalIQLoading: bool => dispatch(getSPCapitalIQLoading(bool)),
		getSPCapitalIQData: (CapIQID) => dispatch(getSPCapitalIQData(CapIQID)),
		capIQFetchData: (capIQData) => dispatch(capIQFetchData(capIQData)),
		getCapIQDataBlank: bool => dispatch(getCapIQDataBlank(bool)),
		updateBPID: (data) => dispatch(updateBPID(data)),
		updateANID: (data) => dispatch(updateANID(data)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MatchAndClassificationContainer)
