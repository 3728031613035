import React from 'react'
import { connect } from 'react-redux'
import { Control, Form, Errors, actions } from 'react-redux-form'
// UI
import Textfield from 'react-mdl/lib/Textfield'
import Button from 'UI/Button'
import moment from 'moment'
// Settings
import { validators } from 'Constants'
import { SelectField, Option } from 'react-mdl-extra'

import {
  fetchFiles,
  fetchSections,
  addDescription,
  deleteSection,
  updateDescription,
  userDetailsSuccess,
  userDetailsFailure,
  userDetailsLoading,
  resetError,
  resetMessage
}
  from 'actions/userActions'

let messageDuration = 10// this is used for the seState async behavior for language dropdown

class Indexx extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      Language: [{ id: 'EN', name: 'English' }, { id: 'CN', name: 'Chinese' },
      { id: 'JP', name: 'Japanese' }, { id: 'PT', name: 'Portugues' }, { id: 'ES', name: 'Espanol' }],
      Files: [],
      Sections: [],
      fileId: 0,
      sectionId: 0,
      languageName: 'EN',
      flag1: false,
      flag2: false,
      flag3: false
    }
  }

  componentWillMount() {
    this.loadFiles();
    this.loadSections();
  }

  loadFiles() {
    this.props.fetchFiles()
      .then((response) => {
        var fileData = [];
        this.props.userDetailsSuccess(response.data.Message)
        for (var i = 0; i < response.data.Result.length; i++) {
          var obj = response.data.Result[i];
          fileData.push(obj);
        }
        this.setState({ Files: fileData })
      }).catch((error) => {
        if (error.stack.includes('Network Error')) {
          this.props.userDetailsFailure()
        }
      })

  }

  loadSections() {
    this.props.fetchSections()
      .then((response) => {
        var sectionData = [];
        this.props.userDetailsSuccess(response.data.Message)
        for (var i = 0; i < response.data.Result.length; i++) {
          var obj = response.data.Result[i];
          sectionData.push(obj);
        }
        this.setState({ Sections: sectionData })
      }).catch((error) => {
        if (error.stack.includes('Network Error')) {
          this.props.userDetailsFailure()
        }
      })

  }

  changeSections() {
    this.props.fetchSections()
      .then((response) => {
        var sectionData = [];
        this.props.userDetailsSuccess(response.data.Message)
        for (var i = 0; i < response.data.Result.length; i++) {
          var obj = response.data.Result[i];
          if (this.state.fileId == obj.ParentId) {
            sectionData.push(obj);
          }
        }
        this.setState({ Sections: sectionData })
      }).catch((error) => {
        if (error.stack.includes('Network Error')) {
          this.props.userDetailsFailure()
        }
      })
  }

  filesChange(value) {
    var filter = value;
    this.state.Files.map((file) => {
      if (filter == file.Name) {
        this.setState({ fileId: file.Id, flag1: true })
      }
    })
    this.formDispatch(actions.reset('sectionManager'))
    this.setState({ flag2: false, flag3: false })
    this.changeSections()

  }

  changeSection(value) {
    if (this.state.flag1) {
      var filter = value;
      this.state.Sections.map((sec) => {
        if (filter == sec.SectionName) {
          this.setState({ sectionId: sec.Id, flag2: true })
        }
      })
    }
    else {
      this.props.userDetailsFailure("Please select the File first")
    }
  }

  changeLang(value) {
    if (this.state.flag1 && this.state.flag2) {
      var filter = value;
      var result = this.state.Language.find(report => report.name.toLowerCase() == filter.toLowerCase())
      this.setState({languageName: result.id, flag3: true})
      
      // this.state.Language.map((lan) => {
      //   if (filter == lan.name) {
      //     this.setState({ languageName: lan.id})
      //     this.setState({flag3: true})
      //     console.log(this.state)
      //   }
      // })
      // this.props.fetchSections()
      // .then((response) => {
      //   var sectionData = [];
      //   this.props.userDetailsSuccess(response.data.Message)
      //   for (var i = 0; i < response.data.Result.length; i++) {
      //     var obj = response.data.Result[i];
      //     if(obj.ParentId == this.state.fileId || this.state.language == 'EN'){
      //       sectionData.push(obj); 
      //     }
      //     if(obj.ParentId == this.state.fileId || this.state.language == 'ES'){
      //       obj.Description = obj.DescriptionES;
      //       sectionData.push(obj); 
      //     } 
      //     if(obj.ParentId == this.state.fileId || this.state.language == 'CN'){
      //       obj.Description = obj.DescriptionCN;
      //       sectionData.push(obj); 
      //     } 
      //     if(obj.ParentId == this.state.fileId || this.state.language == 'JP'){
      //       obj.Description = obj.DescriptionJP;
      //       sectionData.push(obj); 
      //     }  
      //     if(obj.ParentId == this.state.fileId || this.state.language == 'PT'){
      //       obj.Description = obj.DescriptionPT;
      //       sectionData.push(obj); 
      //     }                     
      //   }
      //   this.setState({ Sections: sectionData})
       setTimeout(() => {          
        this.filterTable();
          }, messageDuration)
      
      // }).catch((error) => {
      //   if (error.stack.includes('Network Error')) {
      //     this.props.userDetailsFailure()
      //   }
      // })
    }
    else {
      this.props.userDetailsFailure("Please select the other two dropdowns first")
    }
  }

  //filters the table according to the drop down values
  filterTable() {
    if (this.state.flag1 && this.state.flag2 && this.state.flag3) {
      this.props.fetchSections()
        .then((response) => {
          var sectionData = [];
          this.props.userDetailsSuccess(response.data.Message)
          for (var i = 0; i < response.data.Result.length; i++) {
            var obj = response.data.Result[i];
            if (obj.ParentId == this.state.fileId && this.state.languageName == 'EN') {
              sectionData.push(obj);
            }
            if (obj.ParentId == this.state.fileId && this.state.languageName == 'ES') {
              obj.Description = obj.DescriptionES;
              sectionData.push(obj);
            }
            if (obj.ParentId == this.state.fileId && this.state.languageName == 'CN') {
              obj.Description = obj.DescriptionCN;
              sectionData.push(obj);
            }
            if (obj.ParentId == this.state.fileId && this.state.languageName == 'JP') {
              obj.Description = obj.DescriptionJP;
              sectionData.push(obj);
            }
            if (obj.ParentId == this.state.fileId && this.state.languageName == 'PT') {
              obj.Description = obj.DescriptionPT;
              sectionData.push(obj);
            }
          }
          this.setState({ Sections: sectionData })
        }).catch((error) => {
          if (error.stack.includes('Network Error')) {
            this.props.userDetailsFailure()
          }
        })
    }
  }

  addDescription(formValue) {
    if (this.state.flag1 && this.state.flag2 && this.state.flag3) {
      var sectionDetail = {
        name: formValue.SectionName.trim(),
        desc: formValue.Description.trim(),
        User: localStorage.getItem('loggedinUser'),
        FileID: this.state.fileId,
        Id: this.state.sectionId,
        Language: this.state.languageName
      }
      var msg = this.props.content;
      if (sectionDetail.FileID > 0 && sectionDetail.Id > 0 && sectionDetail.Language) {
        if (sectionDetail.desc != "") {
          this.setState({ mymessage: "" })
          this.props.addDescription(sectionDetail).then((results) => {
            if (results.status === 200) {
              var httpstatus = results.status
              var getMsgfromStatus = msg[httpstatus]
              // Reset form
              this.formDispatch(actions.reset('sectionManager'))
              this.loadSections()
              this.setState({ mymessage: getMsgfromStatus })
              // setTimeout(() => {          
              //   this.loadData()
              //   this.setState({mymessage: ""})
              // }, messageDuration)       

            }
          }).catch((error) => {
            if (error.stack.includes('Network Error')) {
              this.setState({ mymessage: "" })
              this.props.userDetailsFailure('API service is down. Please contact administrator.')
            }
            else {
              this.setState({ mymessage: "" })
              this.props.userDetailsFailure(error.response.data.Message)
            }
          })
        }
        else {
          this.setState({ mymessage: "" })
          this.props.userDetailsFailure("All the fields are not filled")
        }
      }
      else {
        this.setState({ mymessage: "" })
        this.props.userDetailsFailure("Please select all the respective dropdown in which you want to add description")
      }
    }
    else {
      this.setState({ mymessage: "" })
      this.props.userDetailsFailure("Please make sure that all fields are filled")
    }
  }

  handleEditEvent(evt) {
    if (this.state.flag3) {
      var item = {
        name: evt.sectionName,
        desc: evt.description,
        id: evt.id,
        pid: evt.parentId,
        isEditing: evt.isEditing
      };
      var old_sectionDetails = this.state.Sections.slice();
      // if(this.state.filterId > 0){
      var newsectionDetails = old_sectionDetails.map(function (testsectionDetails) {
        for (var key in testsectionDetails) {
          if (testsectionDetails.Id == item.id) {
            testsectionDetails.SectionName = item.name;
            testsectionDetails.Description = item.desc;
            testsectionDetails.isEditing = !item.isEditing;

            if (evt.UpdatedBy !== undefined) {
              testsectionDetails.UpdatedBy = evt.UpdatedBy;
              testsectionDetails.UpdatedOn = evt.UpdatedOn;
            }
            break;
          }
        }
        return testsectionDetails;
      });

      this.setState({ Sections: newsectionDetails });
      // }
      // else{
      //   this.props.userDetailsFailure("Please Select the Respective File from dropdown first!!")
      // }
    }
    else {
      this.props.userDetailsFailure("Please select All the Dropdowns to Edit");
    }
  }

  handleCancelEvent(originalDetails) {
    var item = {
      id: originalDetails[0].id,
      sectionName: originalDetails[0].sectionName,
      description: originalDetails[0].description
    };

    var old_sectionDetails = this.state.Sections.slice();
    var newsectionDetails = old_sectionDetails.map(function (testsectionDetails) {
      for (var key in testsectionDetails) {
        if (testsectionDetails.Id === item.id) {
          testsectionDetails.SectionName = item.sectionName;
          testsectionDetails.Description = item.description;
          testsectionDetails["isEditing"] = false;
          break;
        }
      }
      return testsectionDetails;
    });
    this.setState({ Sections: newsectionDetails });

  };

  handleUpdateEvent(updateDetails) {
    if (updateDetails.length > 0 && this.isDuplicate(updateDetails[0].description.trim())) {
      var item = {
        Id: updateDetails[0].id,
        name: updateDetails[0].sectionName.trim(),
        FileID: updateDetails[0].parentId,
        desc: updateDetails[0].description.trim(),
        Language: this.state.languageName,
        isEditing: true,
        User: localStorage.getItem('loggedinUser'),
        UpdatedOn: Date.now
      };
      if (item.desc != null && item.desc != "") {
        this.props.updateDescription(item)
          .then((results) => {
            this.props.userDetailsSuccess(results.data.Message)
            this.setState({ mymessage: results.data.Message })
            if (results.status === 200) {
              item.UpdatedBy = results.data.Result.UpdatedBy;
              item.UpdatedOn = results.data.Result.UpdatedOn;
              this.handleEditEvent(item)
            }
            this.loadSections()
            this.formDispatch(actions.reset('sectionManager'))
          }).catch((error) => {
            if (error.stack.includes('Network Error')) {
              this.props.userDetailsFailure('API service is down. Please contact administrator.')
            }
            else {
              this.props.userDetailsFailure(error.response.data.Message)
            }
          })
      }
      else {
        this.props.userDetailsFailure("Description cannot be blank")
      }
    }
  }

  isDuplicate(vals) {
    var result = this.state.Sections.find(sec => sec.Description === vals.trim()) === undefined
    if (result) {
      return result;
    }
    else {
      return result;
    }
  }

  componentWillUnmount() {
    this.formDispatch(actions.reset('sectionManager'))
    this.formDispatch(actions.reset('editDomain'))
    this.props.resetError();
  }

  render() {
    return (
      <div>
        <h2 className="medium-12">{this.props.content.title}</h2>
        <div>
          <Form model="sectionManager" method="post"
            getDispatch={(dispatch) => this.formDispatch = dispatch}
            onSubmit={(formValue) => this.addDescription(formValue)}>

            <div className="grid-x grid-padding-x" >
              <div className="medium-6 cell grid-padding-5">
                <Control.select
                  className="role__select"
                  model="sectionManager.FileName"
                  id="sectionManager.FileName"
                  component={SelectField}
                  label={this.props.content.filter}
                  onChange={(value) => this.filesChange(value)}
                  validators={{ required: (value) => validators.validSelect(this.state.Files.indexOf(value)) }}
                  validateOn="change"
                  floatingLabel
                >
                  {
                    this.state.Files.map((filter) =>
                      <Option
                        key={filter.Id}
                        value={filter.Name}
                      >{filter.Name}</Option>)
                  }
                </Control.select>
                <Errors
                  className="errors"
                  model="sectionManager.FileName"
                  show="touched"
                  messages={{
                    required: this.props.validation.required
                  }}
                />
              </div>
              <div className="medium-6 cell grid-padding-5">
                <Control.select
                  className="role__select"
                  model="sectionManager.SectionName"
                  id="sectionManager.SectionName"
                  component={SelectField}
                  label="Sections"
                  onChange={(value) => this.changeSection(value)}
                  validators={{ required: (value) => validators.validSelect(this.state.Sections.indexOf(value)) }}
                  validateOn="change"
                  floatingLabel
                >
                  {
                    this.state.Sections.map((filter) =>
                      <Option
                        key={filter.ID}
                        value={filter.SectionName}
                        defaultValue='English'
                      >{filter.SectionName}</Option>)
                  }
                </Control.select>
                <Errors
                  className="errors"
                  model="sectionManager.SectionName"
                  show="touched"
                  messages={{
                    required: this.props.validation.required
                  }}
                />
              </div>
              <div className="medium-6 cell grid-padding-5">
                <Control.select
                  className="role__select"
                  model="sectionManager.Lan"
                  id="sectionManager.Lan"
                  component={SelectField}
                  label="Language"
                  onChange={(value) => this.changeLang(value)}
                  validators={{ required: (value) => validators.validSelect(this.state.Language.indexOf(value)) }}
                  validateOn="change"
                  floatingLabel
                >
                  {
                    this.state.Language.map((filter) =>
                      <Option
                        key={filter.id}
                        value={filter.name}
                        defaultValue='English'
                      >{filter.name}</Option>)
                  }
                </Control.select>
                <Errors
                  className="errors"
                  model="sectionManager.Lan"
                  show="touched"
                  messages={{
                    required: this.props.validation.required
                  }}
                />
              </div>
              <div className="medium-4 cell" >
                <Control.text
                  model="sectionManager.Description"
                  component={Textfield}
                  validateOn="blur"
                  label="Description"
                  floatingLabel
                  validators={{
                    required: validators.required,
                    duplicate: (vals) => this.isDuplicate(vals)
                  }}
                />
                <Errors
                  className="errors"
                  model="sectionManager.Description"
                  show="touched"
                  messages={{
                    required: this.props.validation.required,
                    duplicate: this.props.validation.duplicateDesc
                  }}
                />
              </div>

              <div className="medium-2 cell" >
                <Button
                  type="submit"
                  disabled={this.props.user.loading}
                  content={this.props.content.cta}
                  color
                />
              </div>
            </div>

          </Form>
          <div className="errors">{this.props.user.error}</div>
          <div className="errors">{this.state.mymessage}</div>
        </div>
        <div className="grid-x" className="medium-11 medium-offset-0">
          <UserTable
            sections={this.state.Sections}
            validation={this.props.validation}
            onRowEdit={this.handleEditEvent.bind(this)}
            onRowCancel={this.handleCancelEvent.bind(this)}
            onRowUpdate={this.handleUpdateEvent.bind(this)}
            isDuplicate={this.isDuplicate.bind(this)}
          />

        </div>
      </div>
    );
  }
}

class UserTable extends React.Component {
  render() {
    var rowEdit = this.props.onRowEdit;
    var rowCancel = this.props.onRowCancel;
    var rowUpdate = this.props.onRowUpdate;
    var validation = this.props.validation;
    var isDuplicate = this.props.isDuplicate;
    var slide = this.props.sections.map(function (slide) {
      return (<UserRow
        onEditEvent={rowEdit.bind(this)}
        onCancelEvent={rowCancel.bind(this)}
        onUpdateEvent={rowUpdate.bind(this)}
        validation={validation}
        isDuplicate={isDuplicate}
        slide={slide}
        key={slide.Id} />)

    });
    return (
      <div className="basic-table">
        <table>
          <thead>
            <tr>
              <th>File Name</th>
              <th>Section Name</th>
              <th>Description</th>
              <th>Added By/On</th>
              <th></th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {slide}
          </tbody>
        </table>
      </div>
    );
  }
}

class UserRow extends React.Component {
  constructor(props) {
    super(props);
  }

  // onDelEvent() {
  // 	this.props.onDelEvent(this.props.report);
  // }

  // onEditEvent() {
  //   this.props.onEditEvent(this.props.report);
  // }

  render() {
    return (
      this.props.slide.isEditing ?
        <EditableCell
          slide={this.props.slide}
          cellData={{
            sectionName: this.props.slide.SectionName,
            id: this.props.slide.Id,
            description: this.props.slide.Description,
            isEditing: this.props.slide.isEditing,
            parentId: this.props.slide.ParentId

          }}
          onCancelEvent={this.props.onCancelEvent}
          onUpdateEvent={this.props.onUpdateEvent}
          validation={this.props.validation}
          isDuplicate={this.props.isDuplicate}
        />
        :
        <NonEditableRow
          slide={this.props.slide}
          cellData={{
            sectionName: this.props.slide.SectionName,
            id: this.props.slide.Id,
            description: this.props.slide.Description,
            isEditing: this.props.slide.isEditing,
            parentId: this.props.slide.ParentId

          }}
          onEditEvent={this.props.onEditEvent}
        />
    );
  }
}

class EditableCell extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sectionDetails: this.props.cellData,
      originalsectionDetails: this.props.cellData,
      updateData: []
    };
  }

  onCancelEvent() {
    var sectionDetails = [
      {
        id: this.state.originalsectionDetails.id,
        parentId: this.state.originalsectionDetails.parentId,
        sectionName: this.state.originalsectionDetails.sectionName,
        description: this.state.originalsectionDetails.description,
        isEditing: false
      }]
    this.props.onCancelEvent(sectionDetails);
  }

  onUpdateEvent() {
    this.props.onUpdateEvent(this.state.sectionDetails);
  }

  onChangeLang(event) {
    var changeItem = [
      {
        id: event.target.id,
        parentId: this.props.cellData.parentId,
        sectionName: this.props.cellData.sectionName,
        description: event.target.value,
        isEditing: true
      }]
    this.setState({ sectionDetails: changeItem })
  }

  isDuplicate(vals) {
    if (vals === undefined) {
      return this.props.isDuplicate("");
    }
    else {
      return this.props.isDuplicate(vals);
    }

  }

  render() {
    return (
      <tr>
        <td>
          <span>{this.props.slide.FileName}</span><br />
        </td>
        <td>
          <span>{this.props.slide.SectionName}</span><br />
        </td>
        <td>
          <Control.text
            model="editDomain.Desc"
            component={Textfield}
            defaultValue={this.props.cellData.description}
            id={this.props.cellData.id}
            parentId={this.props.cellData.parentId}
            value={this.state.sectionDetails.description}
            onChange={this.onChangeLang.bind(this)}
            isEditing={this.props.cellData.isEditing}
            validateOn="blur"
            validators={{
              required: validators.required,
              duplicate: (vals) => this.isDuplicate(vals)
            }}
            type='name' />
          <Errors
            className="errors"
            model="editDomain.Desc"
            show="touched"
            messages={{
              required: this.props.validation.required,
              duplicate: this.props.validation.duplicateDesc
            }} />
        </td>
        <td>
          <span>{this.props.slide.AddedBy}</span><br />
          <span>{moment(this.props.slide.AddedOn).format('L')}</span>
        </td>
        {/* <td>
            <span> {this.props.slide.UpdatedBy}</span> <br />
            <span>{moment(this.props.slide.UpdatedOn).format('L')} </span>
          </td> */}
        <td>
          <button onClick={this.onUpdateEvent.bind(this)}>Update</button>
        </td>
        <td >
          <button onClick={this.onCancelEvent.bind(this)}>Cancel</button>
        </td>
      </tr>
    );
  }
}

class NonEditableRow extends React.Component {

  onEditEvent() {
    this.props.onEditEvent(this.props.cellData);
  }

  render() {
    return (
      <tr>
        <td>
          <span>{this.props.slide.FileName}</span>
        </td>
        <td>
          <span>{this.props.slide.SectionName}</span>
        </td>
        <td>
          <span id={this.props.cellData.id}
            description={this.props.cellData.description}
            isEditing={this.props.cellData.isEditing}>{this.props.cellData.description}</span>
        </td>
        <td>
          <span>{this.props.slide.AddedBy}</span><br />
          <span>{moment(this.props.slide.AddedOn).format('L')}</span>
        </td>
        {/* <td>
            <span> {this.props.slide.UpdatedBy}</span> <br />
            <span>{moment(this.props.slide.UpdatedOn).format('L')} </span>
          </td> */}
        <td>
          <button onClick={this.onEditEvent.bind(this)}>Edit</button>
        </td>
        <td>
        </td>
      </tr>
    )
  }
}


const mapStateToProps = (state) => {
  return {
    language: state.language,
    ui: state.content.ui,
    user: state.default,
    content: state.content.indexManager,
    validation: state.content.validation
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchFiles: (formValues) => dispatch(fetchFiles(formValues)),
    fetchSections: (formValues) => dispatch(fetchSections(formValues)),
    addDescription: (formValues) => dispatch(addDescription(formValues)),
    deleteSection: (formValues) => dispatch(deleteSection(formValues)),
    updateDescription: (formValues) => dispatch(updateDescription(formValues)),
    userDetailsLoading: (bool) => dispatch(userDetailsLoading(bool)),
    userDetailsSuccess: (bool) => dispatch(userDetailsSuccess(bool)),
    userDetailsFailure: (error) => dispatch(userDetailsFailure(error)),
    resetError: () => dispatch(resetError()),
    resetMessage: () => dispatch(resetMessage())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Indexx)