import React from 'react'
import PropTypes from 'prop-types'
import { round, formatThousands } from 'utils/Utils'

// UI
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

// Utils
import { onChangeRemoveMask } from 'utils/Utils'

const BenefitRealizationFactor = (props) => {
	const count = 5
	let inputs = []

	const numberMask = createNumberMask({
		prefix: '',
		suffix: ' %',
		allowDecimal: true,
		decimalLimit: 2
	})

	const handleInputChange = (e, i) => {
		props.handleInputChange(onChangeRemoveMask(e, props.currency), i)
	}

	for (let i = 0; i < count; i++) {
		inputs.push(
			<th key={i}>
				<MaskedInput
					className="masked-input"
					mask={numberMask}
					value={props.benefitRealizationFactors[i]}
					onChange={(e) => handleInputChange(e, i)}
					pattern="[0-9]*"
				/>
			</th>
		)
	}

	let totalAnnualBenefits = [];
	props.totalAnnualBenefits.map((value) => {
		let val = round(value, 2);
		totalAnnualBenefits.push(<div className="cell total-benefit-value">{props.currency} {val ? formatThousands(Number(val)) : 0} M</div>)
	})

	return (
		<div>
			<div className="medium-12 large-12 benefit-factor-title">
				{props.content.benefitRealizationFactor}
			</div>
			<div className="grid-x grid-margin-x medium-up-5 large-up-5">
				{props.content.tableHeaders.map((data, i) =>
					<div className="cell year_block">
						<div className="title" key={data} >{data}</div>
						<MaskedInput
							className="masked-input value"
							mask={numberMask}
							value={props.benefitRealizationFactors[i]}
							onChange={(e) => handleInputChange(e, i)}
							pattern="[0-9]*"
						/>
					</div>
				)}
			</div>
			<div className="medium-12 large-12 total-benefit-title font-bold">
				{props.content.totalAnnualBenefits}
			</div>
			<div className="grid-x grid-margin-x medium-up-5 large-up-5">
				{totalAnnualBenefits}
			</div>
		</div>
	)
}

export default BenefitRealizationFactor

BenefitRealizationFactor.propTypes = {
	handleInputChange: PropTypes.func.isRequired,
	currency: PropTypes.string,
	benefitRealizationFactors: PropTypes.arrayOf(PropTypes.number).isRequired,
	content: PropTypes.shape({
		tableHeaders: PropTypes.arrayOf(PropTypes.string).isRequired,
		benefitRealizationFactor: PropTypes.string.isRequired
	})
}
