import React from 'react'
import InterestButton from './InterestButton'
import classnames from 'classnames'

// Images
import supplyChainIcon from  '../../images/plan-forecast.svg'
import sourcingIcon from '../../images/source-contract.svg'
import procurementIcon from '../../images/buy-deliver.svg'
import accountsPayableIcon from '../../images/invoice-pay.svg'
import workingCapitalIcon from '../../images/working-capital.svg'
import riskComplianceIcon from '../../images/supplier.svg'

const icons = [
	supplyChainIcon,
	sourcingIcon,
	procurementIcon,
	accountsPayableIcon,
	workingCapitalIcon,
	riskComplianceIcon
]

const DiscoveryInterests = (props) => {
	let classes = classnames('grid-container', 'discovery-interests', { active: props.active })

	return (
		<div className={classes}>
			<div className="grid-x">
				{props.content.map((data, i) =>
					<InterestButton
						content={data}
						icon={icons[i]}
						key={data.id}
						id={i}
						state={props.userData[i].Selected}
						dispatchToggleAreaOfInterest={props.dispatchToggleAreaOfInterest}
					/>
				)}
			</div>
		</div>
	)
}

export default DiscoveryInterests
