import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

// Utils
import classnames from 'classnames'
import { path } from 'Constants'

const ValueMenuItems = (props) => {
	var showMenuItem = props.user !== null &&
						props.user !== undefined &&
						props.user.applicationFeatures !== null &&
						props.user.applicationFeatures !== '' &&
						props.user.applicationFeatures !== undefined ?
		props.user.applicationFeatures.filter(a => a.Action === props.content.link) : []
	if (showMenuItem !== null && showMenuItem.length > 0) {
		return (

			<Link
				className={classnames({ 'value_menu__item--readonly': !props.enabled })}
				onClick={(e) => props.disabled ? e.stopPropagation() : true}
				to={{
					pathname: props.content.link
				}}>
				<button
					className={classnames('value_menu__item', (props.menuSelected === props.content.name || props.content.name === props.hoverLink) ? 'value_menu__item--enabled' : null)}
					onClick={(e) => props.handleSelectedMenuItem(e, props.content.name)} onMouseEnter={(e)=> props.handleMouseEnter(e)} onMouseLeave={props.handleMouseLeave}>
					<figure className={classnames((props.menuSelected === props.content.name || props.content.name === props.hoverLink) ? null : 'value_menu__item--disabled')}>
						<img className='value_menu__logo value_menu__logo--disabled' src={path + props.content.icon} alt={props.content.name} />
						<figcaption className="value-image-caption">{props.content.name}</figcaption>
					</figure>
				</button>
			</Link>

		)
	}
	else {
		return null;
	}
}

export default ValueMenuItems

ValueMenuItems.defaultProps = {
	accountMenuExpanded: false,
	adminMenuExpanded: false,
	languageMenuExpanded: false
}

ValueMenuItems.propTypes = {
	accountMenuExpanded: PropTypes.bool.isRequired,
	handleMenuAction: PropTypes.func.isRequired,
	adminMenuExpanded: PropTypes.func.isRequired,
	languageMenuExpanded: PropTypes.func.isRequired
}
