
export const UPDATE_SUBLEVER_CALCULATION_USER_INPUT = 'UPDATE_SUBLEVER_CALCULATION_USER_INPUT'
export const UPDATE_SUBLEVER_ROW_VALUE = 'UPDATE_SUBLEVER_ROW_VALUE'
export const SET_SUBLEVER_ESTIMATED_ANNUAL_VALUE = 'SET_SUBLEVER_ESTIMATED_ANNUAL_VALUE'

export const updateUserInput = (value, subLeverId, rowNumber) => {
	return {
		type: UPDATE_SUBLEVER_CALCULATION_USER_INPUT,
		value: value,
		BVUIdIndex: subLeverId.BVUIdIndex,
		BVUSVDIdIndex: subLeverId.BVUSVDIdIndex,
		rowNumber: rowNumber
	}
}

export const updateSubLeverRowValue = (value, subLeverId, rowNumber, valueType) => {
	return {
		type: UPDATE_SUBLEVER_ROW_VALUE,
		value: value,
		BVUIdIndex: subLeverId.BVUIdIndex,
		BVUSVDIdIndex: subLeverId.BVUSVDIdIndex,
		rowNumber: rowNumber,
		valueType: valueType
	}
}

export const setEstimatedAnnualValue = (value, subLeverId) => {
	return {
		type: SET_SUBLEVER_ESTIMATED_ANNUAL_VALUE,
		value: value,
		BVUIdIndex: subLeverId.BVUIdIndex,
		BVUSVDIdIndex: subLeverId.BVUSVDIdIndex
	}
}