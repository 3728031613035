import React from 'react'
import FaAngleRight from 'react-icons/lib/fa/angle-right'
import FaAngleLeft from 'react-icons/lib/fa/angle-left'
import MdClear from 'react-icons/lib/md/clear'
import classnames from 'classnames'
import Select from 'react-select'
import Textfield from 'react-mdl/lib/Textfield'
import { Control, Form, Errors, actions } from 'react-redux-form'

const CustomDropdown = (props) =>{
 return(	<div>
  {
            (props.value.length === 0) ? 
                <label style={{ visibility: 'visible' }} class="css-floatingLabel css-floatingLabelMargin"
                  for={props.model}>{props.placeholder}</label> :
                <label style={{ visibility: 'hidden' }} class="css-floatingLabel css-floatingLabelMargin"
                    for={props.model}>{props.placeholder}</label>
	       }
	 		<Select
				id={props.model}
				styles={props.styles}
				autocomplete="true"
				placeholder={props.placeholder}
				value={props.value}
				onChange={props.onChange}
				validators={ props.required }
				validateOn="change"
				options={props.options}
				isSearchable={props.isSearchable}
				classNamePrefix={props.classNamePrefix}
				className={props.className}
			/>
			{/* <Errors className={props.errorclassName}
				model={props.model}
				show="touched"
				messages={{
					required: props.required
				}} /> */}
				{ props.value.length === 0 ?
             <label className={props.errorclassName}
              tabIndex={-1}
			   
              autoComplete="off"
              //style={{ opacity: 0, height: 0 }}
              value={props.required}
              required={props.required}
            >{props.required}</label> : null}
			{/* <Control.text
				model={props.model}
				id={props.model}
				hidden
				component={Textfield}				 
				defaultValue={props.value}
				validateOn="change"
				validators={{
					required: props.required 
				}}
			/> */}
	</div>
 )};

export default CustomDropdown

CustomDropdown.defaultProps = {
	type: 'select',
	onChange: () => {},
	disabled: false,
	float: true
}