import React from 'react'
import { connect } from 'react-redux'
import { Control, Form, Errors, actions } from 'react-redux-form'
// UI
import Textfield from 'react-mdl/lib/Textfield'
import Button from 'UI/Button'
import Select from 'react-select'
// Settings
import { validators } from 'Constants'
import { SelectField, Option } from 'react-mdl-extra'

// Actions
import {
  fetchAllUsers,
  userDetailsSuccess,
  userDetailsFailure,
  activateUser,
  userDetailsLoading,
  resetError
}
  from 'actions/userActions'

import { dropdownStyles } from 'react-select-css'

let messageDuration = 1800
var options = [];

class UserActivationManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterText: "",
      filterValue: "",
      users: [], mymessage: '',
      selectedOption: '',
      isActivateUserSelected: ''
    }

    this.loadData = this.loadData.bind(this)

  }

  componentWillMount() {
    this.loadData()
  }
  loadData() {
    this.props.fetchAllUsers()
      .then((response) => {
        var userData = [];
        this.props.userDetailsSuccess(response.data.Message)
        for (var i = 0; i < response.data.Result.length; i++) {
          var obj = response.data.Result[i];
          userData.push(obj);
        }
        this.setState({ users: userData })
        var array = [];
        this.props.content.selector.forEach((element) => {
          var obj = {};
          obj.label = element;
          obj.value = element;
          array.push(obj);
        });
        if (array.length > 0 && array[0].label === "") array.splice(0, 1);
        options = array;
      }).catch((error) => {
        if (error.stack.includes('Network Error')) {
          this.props.userDetailsFailure()
        }
        else {
          this.props.userDetailsFailure(error.response.data.Message)
        }
      })
  }



  handleUserInput(filterText) {
    this.setState({ filterText: filterText.target.value.toLowerCase() });
  };

  activateUsers(formValues) {
    this.props.resetError();
    var userDetail = {
      Email: formValues.Email
    }
    var msg = this.props.content
    this.props.activateUser(userDetail).then((results) => {
      if (results.status === 200) {
        var httpstatus = results.status
        var getMsgfromStatus = msg[httpstatus]
        // Reset form
        this.formDispatch(actions.reset('activateUser'))
        this.setState({ filterText: "", filterValue: "" })
        this.setState({ mymessage: getMsgfromStatus })
        setTimeout(() => {
          this.loadData()
          this.setState({ mymessage: "" })
        }, messageDuration)


      }

    }).catch((error) => {
      if (error.stack.includes('Network Error')) {
        this.props.userDetailsFailure('API service is down. Please contact administrator.')
      }
      else {
        var httpstatus = error.response.status
        var getMsgfromStatus = msg[httpstatus]
        if (getMsgfromStatus !== undefined) {
          this.props.userDetailsFailure(getMsgfromStatus)
        }
        else {
          this.props.userDetailsFailure(error.response.data.Message)
        }
      }
    })

  }

  handleChange(value) {
    var filter = value.label;
    this.setState({ filterValue: filter, selectedOption: filter });
    this.formDispatch(actions.setErrors('activateUser.Activated', false))
  }

  onDDLActivateUserClickEvent = () => {
    this.setState({ isActivateUserSelected: 'Selected' });
  }

  onDDLActivateUserBlurEvent = () => {
    this.setState({ isActivateUserSelected: '' });
  }

  componentWillUnmount() {
    this.formDispatch(actions.reset('activateUser'))
    this.props.resetError()
  }

  render() {
    return (
      <div>
        <div className="medium-12 title">{this.props.content.title}</div>
        <div>
          <Form model="activateUser" method="post"
            getDispatch={(dispatch) => this.formDispatch = dispatch}
            onSubmit={(formValue) => this.activateUsers(formValue)}>

            <div className="grid-x grid-padding-x" >
              <div className="medium-4">
                <SearchBar props={this.props.validation} filterText={this.state.filterText} onUserInput={this.handleUserInput.bind(this)} />
              </div>

              <div className="medium-4 cell pt13" >
                <Button
                  type="submit"
                  disabled={this.props.user.loading}
                  content={this.props.content.cta}
                  color
                />
              </div>


              <div className="medium-4 cell" onClick={this.onDDLActivateUserClickEvent.bind(this)} onBlur={this.onDDLActivateUserBlurEvent.bind(this)}>
                {/* <Control.select
                  className="role__select"
                  model="activateUser.Activated"
                  id="activateUser.Activated"
                  component={SelectField}
                  label={this.props.content.activate}
                  onChange={(value) => this.handleChange(value)}
                  // validators={{ required: (value) => validators.validSelect(this.props.content.selector.indexOf(value)) }}
                  validateOn="change"
                  floatingLabel
                >
                  {this.props.content.selector.map((activate) =>
                    <Option
                      key={activate}
                      value={activate}
                    >{activate}</Option>
                  )}
                </Control.select> */}
                {
                  ((this.state.isActivateUserSelected === 'Selected' && this.state.selectedOption !== '')
                    ||
                    (this.state.isActivateUserSelected === '' && this.state.selectedOption !== ''))
                    ? <label style={{ visibility: 'visible' }} class="css-floatingLabel"
                      for="activateUser.Activated">{this.props.content.activate}</label>
                    : <label style={{ visibility: 'hidden' }} class="css-floatingLabel"
                      for="activateUser.Activated">{this.props.content.activate}</label>
                }
                <Select
                  //className="role__select"
                  model="activateUser.Activated"
                  id="activateUser.Activated"
                  styles={dropdownStyles}
                  placeholder={this.props.content.activate}
                  value={options.filter(option => option.label === this.state.selectedOption)}
                  noOptionsMessage={() => null}
                  onChange={(value) => this.handleChange(value)}
                  options={options}
                  isSearchable={false}
                  classNamePrefix="gen-react-select"
                  className="gen-react-select-container"
                />

                <Control.text
                  //className="role__select"
                  model="activateUser.Activated"
                  id="activateUser.Activated"
                  value={this.state.selectedOption}
                  hidden
                  validateOn="blur"
                  component={Textfield}
                  validators={{
                    required: validators.required
                  }} />
                <Errors
                  className="errors"
                  style={{ margin: '-37px 0px' }}
                  model="activateUser.Activated"
                  show="touched"
                  messages={{
                    required: this.props.validation.required
                  }}
                />
              </div>

            </div>

          </Form>
          <div className="errors">{this.props.user.error}</div>
          <div className="success">{this.state.mymessage}</div>
        </div>
        <div className="grid-x" className="medium-11 medium-offset-0">
          <UserTable

            users={this.state.users}
            filterText={this.state.filterText}
            filterValue={this.state.filterValue}
          />
        </div>
      </div>
    );
  }
}

class SearchBar extends React.Component {
  constructor(props) {
    super(props);
  }

  handleChange(event) {
    this.props.onUserInput(event);
  }

  render() {
    return (
      <div className="medium-4 cell grid-padding-5">
        <Control.text
          model="activateUser.Email"
          id="activateUser.Email"
          component={Textfield}
          label={'Email'}
          floatingLabel
          ref="filterTextInput"
          onChange={this.handleChange.bind(this)}
          validators={{
            validEmail: validators.validEmail
          }}
          validateOn="blur"
        />
        <Errors
          className="errors"
          model="activateUser.Email"
          show="touched"
          messages={{
            validEmail: this.props.props.validEmail
          }}
        />
      </div>
    );
  }
}

class UserTable extends React.Component {
  render() {
    var filterText = this.props.filterText;
    var filterValue = this.props.filterValue;
    var user = this.props.users.map(function (user) {
      if (user.email.toLowerCase().indexOf(filterText) === -1) {
        return;
      }
      else if (user.emailConfirmed.indexOf(filterValue) === -1 && filterValue !== "") {
        return;
      }
      else return (<UserRow
        user={user}
        key={user.email} />)
    });
    return (
      <div className="basic-table">
        <table>
          <thead>
            <tr>
              <th>User Email</th>
              <th>Activated</th>
              <th>Registered On</th>
            </tr>
          </thead>

          <tbody>
            {user}
          </tbody>
        </table>
      </div>
    );
  }
}


class UserRow extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <tr className="eachRow">
        <td>{this.props.user.email}</td>
        <td>{this.props.user.emailConfirmed}</td>
        <td>{this.props.user.joinedDate}</td>
      </tr>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
    ui: state.content.ui,
    user: state.default,
    content: state.content.activateUser,
    validation: state.content.validation,
    users: state.users
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAllUsers: (formValues) => dispatch(fetchAllUsers(formValues)),
    userDetailsLoading: (bool) => dispatch(userDetailsLoading(bool)),
    userDetailsSuccess: (bool) => dispatch(userDetailsSuccess(bool)),
    userDetailsFailure: (error) => dispatch(userDetailsFailure(error)),
    activateUser: (formValues) => dispatch(activateUser(formValues)),
    resetError: () => dispatch(resetError())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserActivationManager)
