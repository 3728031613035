export const PRIORITY_LEVEL_CHANGE = 'PRIORITY_LEVEL_CHANGE'

export const TOGGLE = 'TOGGLE'
export const INIT_PRIORITIES_ITEMS = 'INIT_PRIORITIES_ITEMS'
export const ADD_PRIORITIES_ITEM = 'ADD_PRIORITIES_ITEM'
export const DELETE_PRIORITIES_ITEM = 'DELETE_PRIORITIES_ITEM'

export const priorityLevelChange = (id, subIndex, value) => {

	return {
		type: PRIORITY_LEVEL_CHANGE,
		id: id,
		subIndex: subIndex,
		value: value
	}
}

export const toggleAreaOfInterest = (id, bool) => {

	return {
		type: 'TOGGLE',
		module: 'AreaOfInterest',
		stateKey: 'Selected',
		id: id,
		selected: bool
	}
}

export const initPrioritiesItems = (items) => {
	return {
		type: 'INIT_PRIORITIES_ITEMS',
		items
	}
}

export const addPrioritiesItem = (id) => {
	return {
		type: 'ADD_PRIORITIES_ITEM',
		item: id
	}
}

export const deletePrioritiesItem = (id) => {
	return {
		type: 'DELETE_PRIORITIES_ITEM',
		item: id
	}
}
