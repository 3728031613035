import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Form, actions } from 'react-redux-form'
import { getSourceValue } from '../SubLeverCalculation/SubLeverFormula'
import ScrollArea from 'react-scrollbar'
import Modal from 'reboron/OutlineModal'
import AccountParametersNewValueStory from 'components/Spend/YourSpendFile/AccountParametersNewValueStory'
// Actions

import {
	updateValueStoryName,
	updateCapIQId,
	updateOrganization,
	changeIndustry,
	changeLocation,
	updateTotalSpend,
	goToChoosePath,
	updateBPID, 
	updateANID,
	updateValueStoryType,
	isValueStoryPage,
	saveValueStoryOnRedirect,
	changeIndirectSolutionScope		
} from 'actions/discoveryActions'

import {
	toggleValueDriver,
	toggleSubLever
} from 'actions/valueDriverActions'

import {
	addValueStory,
	addValueStorySuccess,
	addValueStoryFailure,
	updateValueStory,
	updateValueStorySuccess,
	updateValueStoryFailure,
	openValueStory,
	openValueStorySuccess,
	openValueStoryFailure,
	valueStoryCurrentMenu,
	showValueStoryMenu,
	syncMenuAndButton,
	//resetError,
	setSubTabMenu,
	setTabMenu
}
	from 'actions/userActions'

import {
	isNewValueStory
} from 'actions/searchActions'

import { getCapIQDataBlank,pendingChanges,defineScopeLoading
	} from 'actions/spendActions'

import {
	initPrioritiesItems
}
	from 'actions/prioritiesActions'

import { valueStoryFetchDataSuccess } from 'actions/fetchValueStory'

// Common Functions
import { subLeverChange } from 'components/BusinessValue/SubLeverChange'

// Components
import DiscoveryForm from './DiscoveryForm'
import DiscoveryCompanyForm from './DiscoveryCompanyForm'
import DiscoveryButton from './DiscoveryButton'
import DiscoveryNextButton from './DiscoveryNextButton'
import SpendDiscoveryForm from './SpendDiscoveryForm'
import NewValueStory from './NewValueStory'
import NewValueStoryCreation from './NewValueStoryCreation'
import ValueStoryCreationForm from './ValueStoryCreationForm'
// UI
import Button from 'UI/Button'

// Utils
import classnames from 'classnames'
// Utils
import { removeMask, getSubLeverIdObject, subLeverHasTotalSpend, round } from 'utils/Utils'
import { updateUserInput } from 'actions/subLeverCalculationActions'

// Settings
import { routes, LAC, changeModalBorderStyle,drilldownModalBorderContentStyleNewVS, drilldownModalBorderContentStyleNewVsSecondPop, changeModalBorderRectStyle } from 'Constants'


export class SpendDiscoveryContainer extends PureComponent {

	constructor(props) {
		super(props)

		this.state = {
			interests: false,
			count: 0,
			regions: [],
			default: '',
			AnnualRevenue: 1,
			TotalSpend: 0,
			Currency: '$',
			CurrencyCode: 'USD',
			Organization: '',
			Industry: '',
			Location: '',
			CapIQId: '',
			CapIQCompany: '',
			PEId: '',
			PECompany: '',
			GlobalUltimateId: '',
			GlobalUltimateCompany: '',
			BPId: '',			
			BPCompany: '',
			userData: undefined,
			btnCreateValueStoryDisabled: !this.props.form.valid, //|| Number.parseFloat(this.props.userData.AnnualRevenue) === 0
			btnCreateValueStoryDisabledNewVS: !this.props.form.valid, 
			reponseMessage: '',
			originalUserData: Object.assign({}, this.props.userData)
		}
		this.dispatchUpdateValueStoryName = this.dispatchUpdateValueStoryName.bind(this)
		this.dispatchUpdateCapIQId = this.dispatchUpdateCapIQId.bind(this)
		this.dispatchCapIQChange = this.dispatchCapIQChange.bind(this)
		this.dispatchUpdateOrganization = this.dispatchUpdateOrganization.bind(this)
		this.dispatchLocationChange = this.dispatchLocationChange.bind(this)
		this.dispatchIndirectScopeChange = this.dispatchIndirectScopeChange.bind(this)
		
		this.dispatchIndustryChange = this.dispatchIndustryChange.bind(this)
		this.dispatchTotalSpendChange = this.dispatchTotalSpendChange.bind(this)
		this.slideToInterests = this.slideToInterests.bind(this)
		this.toggleInterestsVisibility = this.toggleInterestsVisibility.bind(this)
		this.initialFieldValues = this.initialFieldValues.bind(this)
		this.addValueStory = this.addValueStory.bind(this)
		this.openValueStory = this.openValueStory.bind(this)
		this.dispatchOpenValueStory = this.dispatchOpenValueStory.bind(this)
		this.redirect = this.redirect.bind(this)
		this.goToRoute = this.goToRoute.bind(this);
		this.handleValueStoryUpdate = this.handleValueStoryUpdate.bind(this)
		this.compareKeyValuesInObject = this.compareKeyValuesInObject.bind(this)
		this.compareKeyValuesInObjectNewVS = this.compareKeyValuesInObjectNewVS.bind(this)		
		this.actions = this.getApplicationFeatureActions();
		this.dispatchUpdateBPID = this.dispatchUpdateBPID.bind(this)
		this.dispatchUpdateANID = this.dispatchUpdateANID.bind(this)
		this.dispatchUpdateValueStoryType = this.dispatchUpdateValueStoryType.bind(this)
		this.generateValueStoryObject = this.generateValueStoryObject.bind(this)
		this.hideModal = this.hideModal.bind(this)
		this.routeToNewVSPopup = this.routeToNewVSPopup.bind(this)
		this.hideNewVSCreationPopup = this.hideNewVSCreationPopup.bind(this)	
	}



	getApplicationFeatureActions() {
		let actions = [];
		let applicationFeatures = this.props.user && this.props.user.user && this.props.user.user.applicationFeatures ? this.props.user.user.applicationFeatures : [];
		applicationFeatures.map(applicationFeat => {
			actions.push(applicationFeat.Action);
		})
		return actions;
	}

	slideToInterests() {
		if (this.props.form.valid) {
			this.props.goToChoosePath(true)
		}
	}

	toggleInterestsVisibility() {
		const that = this
		that.setState({
			interests: !that.state.interests
		})
	}

	dispatchUpdateValueStoryName(valuestoryName) {
		this.props.updateValueStoryName(valuestoryName)
	}

	dispatchUpdateCapIQId(capiqid) {
		this.props.updateCapIQId(capiqid)
	}

	dispatchUpdateOrganization(organization) {
		this.props.updateOrganization(organization)
	}

	dispatchUpdateBPID(bpId){
		this.props.updateBPID(bpId)
	}

	dispatchUpdateANID(anId){
		this.props.updateANID(anId)
	}

	dispatchUpdateValueStoryType(valueStoryType){
        this.props.updateValueStoryType(valueStoryType)
	}

	dispatchCapIQChange(id) {
		this.setState({ capIQId: id })
	}
	dispatchIndirectScopeChange(index) {
		var industry = this.props.content.form.industries[index]
		var indirectScope = this.props.content.form.indirectScopes[index]
		var country = this.props.content.form.locations[this.props.userData.Location]
		var result = this.props.content.form.regions.find(region => region.Country.toLowerCase() == country.toLowerCase())
		var region = result.Region;
		var spend = this.props.subLeverCalculationContent.subLeverCalculation[0].rows[0][0].content
		var spendSourceValue = getSourceValue(3, country, region, industry, spend)		
		this.props.changeIndirectSolutionScope(indirectScope)
		
	}

	dispatchLocationChange(location, region) {
		//New Logic to play with json values
		var industry = this.props.content.form.industries[this.props.userData.Industry]
		var country = this.props.content.form.locations[location]
		var spend = this.props.subLeverCalculationContent.subLeverCalculation[0].rows[0][0].content
		var spendSourceValue = getSourceValue(3, country, region, industry, spend)
		this.props.changeLocation(location, spendSourceValue)
		// Turn off Reduce Cost of Processing Payments sub lever
		// if selected country is Latin American Country (LAC)
		if (LAC.indexOf(this.props.content.form.locations[this.props.userData.Location]) > -1) {
			const subLeverIdObject = {
				BVUIdIndex: 2,
				BVUSVDIdIndex: 2
			}
			// Dispatch to Redux
			this.props.toggleSubLever(subLeverIdObject, false)
		}
	}

	dispatchIndustryChange(index) {
		var industry = this.props.content.form.industries[index]
		var country = this.props.content.form.locations[this.props.userData.Location]
		var result = this.props.content.form.regions.find(region => region.Country.toLowerCase() == country.toLowerCase())
		var region = result.Region;
		var spend = this.props.subLeverCalculationContent.subLeverCalculation[0].rows[0][0].content
		var spendSourceValue = getSourceValue(3, country, region, industry, spend)
		this.props.changeIndustry(index, spendSourceValue)
	}

	dispatchTotalSpendChange(value) {
		this.formDispatch(actions.change('discovery.TotalSpend', value))
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.saveValueStoryAfterRedirect !== nextProps.saveValueStoryAfterRedirect && nextProps.saveValueStoryAfterRedirect) {
			this.handleValueStoryUpdate();
		}

		// Update controlled inputs on language, industry or total spend change
		if (this.props.content !== nextProps.content) {
			this.formDispatch(actions.change('discovery.Industry', nextProps.content.form.industries[this.props.userData.Industry]))

			this.formDispatch(actions.change('discovery.Location', nextProps.content.form.locations[this.props.userData.Location]))
			this.formDispatch(actions.change('discovery.Organization', this.props.userData.Organization));
		}

		if (nextProps.userData.ValueStoryName === "" || nextProps.userData.Industry === 0 || nextProps.userData.Industry === ""
			|| nextProps.userData.Organization === "" || !this.props.form.valid || nextProps.userData.ValueStoryType === "") {
			this.setState({ btnCreateValueStoryDisabled: true })
			//this.formDispatch(actions.change('discovery.Industry', nextProps.content.form.industries[this.props.userData.Industry]))
			//this.props.getCapIQDataBlank(true)
		} else {
			this.setState({ btnCreateValueStoryDisabled: false })
			//this.props.getCapIQDataBlank(false)
		}

		if (nextProps.userData.Organization === "" || nextProps.userData.IndirectSolutionScope === undefined) {
			this.setState({ btnCreateValueStoryDisabledNewVS: true })			
		} else {
			this.setState({ btnCreateValueStoryDisabledNewVS: false })			
		}
	}

	componentWillMount() {
		this.props.setTabMenu(1);
        this.props.setSubTabMenu(1);
		this.props.valueStoryCurrentMenu('Company');
		this.props.showValueStoryMenu(true);
		this.initialFieldValues();

	}

	componentDidMount() {

		// Update Industry and spendAsPercentage
		//this.props.changeIndustry(this.props.userData.Industry, industryBusinessMatrix[this.props.userData.Industry].spendPercentage)
		var industry = this.props.content.form.industries[this.props.userData.Industry]
		var country = this.props.content.form.locations[this.props.userData.Location]
		if (industry && country) {
			var result = this.props.content.form.regions.find(region => region.Country.toLowerCase() == country.toLowerCase())
			var region = result.Region;
			var spend = this.props.subLeverCalculationContent.subLeverCalculation[0].rows[0][0].content
			var spendSourceValue = getSourceValue(3, country, region, industry, spend)
			this.props.changeIndustry(this.props.userData.Industry, spendSourceValue)
		}
		this.props.isValueStoryPage(true);
		// Navigation
		this.props.goToChoosePath(false);
		
		//Pass organization value to reducer forms
		this.formDispatch(actions.change('discovery.Organization', this.props.userData.Organization));
		this.formDispatch(actions.change('discovery.Industry', industry));
		
		if (this.props.newValueStory)
			this.refs.newValueStoryCreation.show();
	}

	initialFieldValues() {
		/*const companyData = this.props.companyData ? this.props.companyData[0] : undefined;
		if(companyData) {
			const industryIdx = this.props.content.form.industries.indexOf(companyData.Industry);
			const locationIdx = this.props.content.form.locations.indexOf(companyData.Location);
			companyData.Industry = industryIdx;
			companyData.Location = locationIdx;
		}*/
	}

	redirect(routeName) {
		this.props.history.push(routeName);
	}

	openValueStory(routeName) {
		this.dispatchOpenValueStory(routeName);
	}

	dispatchOpenValueStory(routeName) {
		var content = this.props.content;
		this.state.selectedValueStory &&
			this.props.openValueStory(this.state.selectedValueStory, this.props.user.user.Id)
				.then((response) => {
					if (response.status === 200) {
						// Push value story data to store
						this.props.valueStoryFetchDataSuccess(response.data.Result)

						// Add indexes of selected priorities to priorities array in store
						let items = response.data.Result.AreaOfInterest.map((item, i) => item.Selected && i)
						items = items.filter((item) => item !== false)
						this.props.initPrioritiesItems(items)
						this.props.isNewValueStory(false)
						// Reset form
						this.formDispatch(actions.reset('addValueStory'))

						var self = this;
						const calculatedTotalSpend = round(self.props.spendAsPercentageOfRevenue * response.data.Result["AnnualRevenue"], 2)
						if (response.data.Result["TotalSpend"] != calculatedTotalSpend) {
							const handleChange = this.props.handleChange
							this.props.subLeverCalculationContent.subLeverCalculation.forEach(function (obj) {

								const subLever = getSubLeverIdObject(obj.id)

								if (subLeverHasTotalSpend(obj.id)) {
									handleChange(response.data.Result["TotalSpend"], subLever, 0)
								}
							})
						}
						// Update Total Spend in state
						this.props.updateTotalSpend(response.data.Result["TotalSpend"])

						// Update controlled input
						this.dispatchTotalSpendChange(response.data.Result["TotalSpend"])

						this.setState({ originalUserData: Object.assign({}, this.props.userData) })

						// Redirect to Discovery page
						this.redirect(routeName)

						// update store status message
						// this.props.openValueStorySuccess()

						// reset store status message
						//setTimeout(() => {this.props.resetMessage()}, 100)
					}
					else {
						var httpStattus = response.response.data.Message
						var failMsg = content[httpStattus]
						if (failMsg !== undefined) {
							failMsg = failMsg.toString().replace("%userName%", response.response.data.Result)
							this.props.openValueStoryFailure(failMsg)
						}
					}
				}).catch((error) => {
					//this.props.openValueStoryFailure(error.response.data.Message)
				})
	}

	generateValueStoryObject() {
		return {
			ValueStoryDetails:
			{
				ValueStoryName: this.props.userData.ValueStoryName
				, Organization: this.props.userData.Organization
				, Industry: this.props.userData.Industry
				, Location: this.props.userData.Location
				, Currency: this.props.userData.Currency
				, AnnualRevenue: this.props.userData.AnnualRevenue
				, CreatedDate: this.props.userData.CreatedDate
				, ModifiedDate: this.props.userData.ModifiedDate
				, Id: this.props.userData.Id
				, MutualActionPlan: this.props.userData.MutualActionPlan
				, TotalSpend: this.props.userData.TotalSpend
				, PEId: this.props.userData.PEId
				, PECompany: this.props.userData.PECompany
				, BPId_old: this.props.userData.BPId_old
				, BPCompany: this.props.userData.BPCompany
				, CapIQId: this.props.userData.CapIQId
				, CapIQCompany: this.props.userData.CapIQCompany
				, GlobalUltimateId: this.props.userData.GlobalUltimateId
				, GlobalUltimateCompany: this.props.userData.GlobalUltimateCompany
				, CurrencyCode: this.props.userData.CurrencyCode
				, Test: this.props.userData.Test
				, ANID: this.props.userData.ANId
				, BPID: this.props.userData.BPId
				, ValueStoryType: this.props.userData.ValueStoryType
				, IndirectSolutionScope: this.props.userData.IndirectSolutionScope
			}
			, TransactionEligible: this.props.userData.TransactionEligible
			, UnclassifiedEligible: this.props.userData.UnclassifiedEligible
			, SCCEligible: this.props.userData.SCCEligible
			, Fieldglass: this.props.userData.Fieldglass
			, FieldglassEligible: this.props.userData.FieldglassEligible
			, SuppEligible: this.props.userData.SuppEligible
			, CatalogScope: this.props.userData.CatalogScope
			, OutScopeSuspendedSup: this.props.userData.OutScopeSuspendedSup
			, SearchSupplierWave: this.props.userData.SearchSupplierWave

		}
	}

	addValueStory(routeName) {
		var content = this.props.content;
		//let values = Object.assign({}, formValues)
		this.props.userData.Language = this.props.language.toUpperCase()
		this.props.userData.Id = this.props.user.user.Id
		if (this.props.userData.AnnualRevenue === 0 || this.props.userData.AnnualRevenue === null) {
			this.props.userData.AnnualRevenue = this.state.AnnualRevenue;
			this.props.userData.TotalSpend = round(this.props.spendAsPercentageOfRevenue * this.state.AnnualRevenue, 2)
		}
		//values.TotalSpend = removeMask(values.TotalSpend, values.Currency)
		//values.AnnualRevenue = removeMask(values.AnnualRevenue, values.Currency)

		//Organization have taken from state
		//values.Organization = this.state.selectedPEName
		//values.PEId = this.state.selectedPEId
		var vsObject = this.generateValueStoryObject();
		this.props.addValueStory(vsObject)
			.then((result) => {
				if (result.status === 200) {
					// Open Value Story
					this.setState({
						selectedValueStory: result.data.Result.VSId,
						reponseMessage: this.props.content.createValueStorySuccess
					}, () => {
						this.openValueStory(routeName);
					})
				}
				else {
					var httpStattus = result.response.status;
					var failMsg = content[httpStattus]
					if (failMsg !== undefined) {
						failMsg = failMsg.toString().replace("%ValueStoryName%", this.props.userData.ValueStoryName)
						failMsg = failMsg.toString().replace("%Organization%", this.props.userData.Organization)
						//formValues.valueStoryName
						this.props.addValueStoryFailure(failMsg)
					}
					else {
						this.props.addValueStoryFailure(result.response.data.Message)

					}
				}

			}).catch((error) => {
				var httpStattus = error.response.status;
				var failMsg = content[httpStattus]
				if (failMsg !== undefined) {
					failMsg = failMsg.toString().replace("%ValueStoryName%", this.props.userData.ValueStoryName)
					failMsg = failMsg.toString().replace("%Organization%", this.props.userData.Organization)
					//formValues.valueStoryName
					this.props.addValueStoryFailure(failMsg)
				}
				else {
					this.props.addValueStoryFailure(error.response.data.Message)

				}
			})
	}

	goToRoute(routeName) {
		if (this.props.newValueStory) {
			this.addValueStory(routeName);
		} else {
			if (this.props.form.valid && Number.parseFloat(this.props.userData.AnnualRevenue) !== 0) {
				this.props.history.push(routeName);
				window.scrollTo({
					top: 0,
					behavior: "smooth"
				});
			}
		}
	}

	compareKeyValuesInObject(originalUserData, userData) {
		this.props.pendingChanges(null);		
		if (originalUserData !== undefined && originalUserData !== null) {
			if (originalUserData['Industry'] === userData['Industry']
				&& originalUserData['Location'] === userData['Location']
				&& originalUserData['ValueStoryName'] === userData['ValueStoryName']
				&& originalUserData['CapIQId'] === userData['CapIQId']
				&& originalUserData['Organization'] === userData['Organization']
				&& originalUserData['BPId'] === userData['BPId']
				&& originalUserData['ANId'] === userData['ANId']
				&& originalUserData['ValueStoryType'] === userData['ValueStoryType']
				&& originalUserData['IndirectSolutionScope'] === userData['IndirectSolutionScope']
				) {
				return true;
			}
			else {
				if (!this.props.form.valid)
					return true;
				else
				{
					this.props.pendingChanges(true);
					return false;
				}
			}
		}
		else {
			return true;
		}
	}

	compareKeyValuesInObjectNewVS(originalUserData, userData) {
		this.props.pendingChanges(null);		
		if (originalUserData !== undefined && originalUserData !== null) {
			if (
				originalUserData['Organization'] === userData['Organization']				
				&& originalUserData['IndirectSolutionScope'] === userData['IndirectSolutionScope']
				) {
				return true;
			}
			else {
				if (!this.props.form.valid)
					return true;
				else
				{
					this.props.pendingChanges(true);
					return false;
				}
			}
		}
		else {
			return true;
		}
	}

	componentWillUnmount() {
		//this.props.defineScopeLoading(false)
		setTimeout(() => {
			this.props.pendingChanges(null);
			this.props.isValueStoryPage(false);
		}, 1000);
		
		//this.props.resetError();
	}

	handleValueStoryUpdate() {
		let val = this.props.userData;
		this.props.updateValueStory(this.props.userData)
			.then((result) => {
				if (result.status === 200) {					
					this.setState({ reponseMessage: this.props.content.updateValueStorySuccess })
				}
				else {
					this.props.updateValueStoryFailure(this.props.content.updatealueStoryFailure)
				}
				this.props.saveValueStoryOnRedirect(false);
			}).catch((error) => {
				this.props.updateValueStoryFailure(this.props.content.updatealueStoryFailure)
			})

		this.setState({ originalUserData: Object.assign({}, this.props.userData) })
	}

	handleMouseEnter(e) {
		const selectedButton = e.target.innerText;
		this.props.syncMenuAndButton(selectedButton)
	}

	handleMouseLeave = () => {
		this.props.syncMenuAndButton('')
	}

	hideModal()
	{
		this.refs.newValueStory.hide();
		this.props.history.push(`/start`)
		////this.props.history.push(`/start`)
	}

	hideNewVSCreationPopup()
	{
		this.refs.newValueStoryCreation.hide();
		this.props.history.push(`/start`)
	}

	routeToNewVSPopup()
	{
		this.props.userData.ValueStoryName =  this.props.userData.Organization + ' - ' + this.props.userData.IndirectSolutionScope;
		this.refs.newValueStoryCreation.hide();
		this.refs.newValueStory.show();
		////this.props.history.push(`/start`)
	}

	render() {
		
		//let slideClasses = classnames('discovery__slide')

		if (this.props.valueStoryIsLoading) {
			return <p>Loading...</p>
		} else {
			return (
				<div className="discovery">
					<div >
						<Form
						    //className="grid-x medium-12" 
							model="discovery"
							getDispatch={(dispatch) => this.formDispatch = dispatch}
						>
							<div className="grid-x medium-12 grid-padding-x">

								<div className="medium-12 cell">
									<SpendDiscoveryForm  //ValueStoryCreationForm
										content={this.props.content.form}
										subLeverCalculationContent={this.props.subLeverCalculationContent.subLeverCalculation}
										dispatchLocationChange={this.dispatchLocationChange}
										dispatchIndirectScopeChange={this.dispatchIndirectScopeChange}
										dispatchIndustryChange={this.dispatchIndustryChange}
										dispatchTotalSpendChange={this.dispatchTotalSpendChange}
										dispatchUpdateTotalSpend={this.props.updateTotalSpend}
										dispatchUpdateValueStoryName={this.dispatchUpdateValueStoryName}
										dispatchUpdateCapIQId={this.dispatchUpdateCapIQId}
										dispatchUpdateOrganization={this.dispatchUpdateOrganization}
										dispatchCapIQChange={this.dispatchCapIQChange}
										dispatchUpdateBPID={this.dispatchUpdateBPID}
										dispatchUpdateANID={this.dispatchUpdateANID}
										dispatchUpdateValueStoryType={this.dispatchUpdateValueStoryType}
										isNewValueStory={this.props.newValueStory}										
										originalUserData={this.state.originalUserData}
										compareKeyValuesInObject={this.compareKeyValuesInObject}
										responseMessage={this.state.reponseMessage}
									/>
									<div className="formBg profile-pg">
										<div className="grid-x">
											<div className={
												classnames(
													'medium-8',
													{ 'errors': !this.compareKeyValuesInObject(this.state.originalUserData, this.props.userData) },
													{ 'success': this.compareKeyValuesInObject(this.state.originalUserData, this.props.userData) }
												)}>
												{this.compareKeyValuesInObject(this.state.originalUserData, this.props.userData) ?
													this.state.reponseMessage : this.props.newValueStory ? '' : this.props.content.pendingUpdates}
											</div>
											<div className="medium-12 cell" style={{ paddingLeft: '233px' }}>
												{this.props.newValueStory ?
													<div className="float-right" >
														<button className={classnames('button button--accent',
															(this.props.hoverButton === this.props.content.buttons.goToPriorities) ? 'hoverStyle' : null)}
															disabled={this.state.btnCreateValueStoryDisabled}
															onClick={(e) => { this.goToRoute(routes.yourSpend) }}
															onMouseEnter={(e) => this.handleMouseEnter(e)} onMouseLeave={this.handleMouseLeave}>
															<span className="button__inner">{this.props.content.buttons.createValueStory}</span>
														</button>
													</div> :
													<div className="float-right" >
														<button className={classnames('button button--accent')}
															disabled={this.compareKeyValuesInObject(this.state.originalUserData, this.props.userData)}
															//disabled={_.isEqual(this.state.originalUserData, this.props.userData)}
															onClick={(e) => { this.handleValueStoryUpdate() }}>
															<span className="button__inner">{this.props.content.buttons.btnSaveValuesStory}</span>
														</button>
													</div>}
											</div>
										</div>
									</div>
								</div>
							</div>
						</Form>
					</div>

					<Modal
                    ref={'newValueStory'}
                    modalStyle={changeModalBorderStyle}
                    contentStyle={drilldownModalBorderContentStyleNewVsSecondPop}
                    rectStyle={changeModalBorderRectStyle}                   
                    keyboard={false}
                    backdrop={false}
                   >
                    <div >
						<Form
						    //className="grid-x medium-12" 
							model="discovery"
							getDispatch={(dispatch) => this.formDispatch = dispatch}
						>
														
							 <div>
									<div className="medium-6">
									<div style={{marginLeft:"550px",fontWeight:"bold"}}>
											Create a New Value Story
										</div>
									</div>
									<div className="medium-6 popupOkBtn">
										<div className="float-right">
											<div className="float-left"><Button
												content={"X"}
												onClick={() => this.hideModal()}
												color /></div>
										</div>
									</div>
								{/*<div className="medium-12 cell"> */}
									<NewValueStory  //ValueStoryCreationForm
										content={this.props.content.form}
										subLeverCalculationContent={this.props.subLeverCalculationContent.subLeverCalculation}
										dispatchLocationChange={this.dispatchLocationChange}
										dispatchIndirectScopeChange={this.dispatchIndirectScopeChange}
										dispatchIndustryChange={this.dispatchIndustryChange}
										dispatchTotalSpendChange={this.dispatchTotalSpendChange}
										dispatchUpdateTotalSpend={this.props.updateTotalSpend}
										dispatchUpdateValueStoryName={this.dispatchUpdateValueStoryName}
										dispatchUpdateCapIQId={this.dispatchUpdateCapIQId}
										dispatchUpdateOrganization={this.dispatchUpdateOrganization}
										dispatchCapIQChange={this.dispatchCapIQChange}
										dispatchUpdateBPID={this.dispatchUpdateBPID}
										dispatchUpdateANID={this.dispatchUpdateANID}
										dispatchUpdateValueStoryType={this.dispatchUpdateValueStoryType}
										isNewValueStory={this.props.newValueStory}										
										originalUserData={this.state.originalUserData}
										compareKeyValuesInObject={this.compareKeyValuesInObject}
										responseMessage={this.state.reponseMessage}
									/>
									<div className="formBg profile-pg">
										<div className="grid-x">
											<div className={
												classnames(
													'medium-8',
													{ 'errors': !this.compareKeyValuesInObject(this.state.originalUserData, this.props.userData) },
													{ 'success': this.compareKeyValuesInObject(this.state.originalUserData, this.props.userData) }
												)}>
												{this.compareKeyValuesInObject(this.state.originalUserData, this.props.userData) ?
													this.state.reponseMessage : this.props.newValueStory ? '' : this.props.content.pendingUpdates}
											</div>
											<div className="medium-12 cell" style={{ paddingLeft: '233px' }}>
												{this.props.newValueStory ?
													<div className="float-right" >
														<button className={classnames('button button--accent',
															(this.props.hoverButton === this.props.content.buttons.goToPriorities) ? 'hoverStyle' : null)}
															disabled={this.state.btnCreateValueStoryDisabled}
															onClick={(e) => { this.goToRoute(routes.yourSpend) }}
															onMouseEnter={(e) => this.handleMouseEnter(e)} onMouseLeave={this.handleMouseLeave}>
															<span className="button__inner">{this.props.content.buttons.createValueStory}</span>
														</button>
													</div> :
													<div className="float-right" >
														<button className={classnames('button button--accent')}
															disabled={this.compareKeyValuesInObject(this.state.originalUserData, this.props.userData)}
															//disabled={_.isEqual(this.state.originalUserData, this.props.userData)}
															onClick={(e) => { this.handleValueStoryUpdate() }}>
															<span className="button__inner">{this.props.content.buttons.btnSaveValuesStory}</span>
														</button>
													</div>}
											</div>
										</div>
									</div>
								{/* </div> */}
								{/* <div className="medium-6 cell">
									<AccountParametersNewValueStory />
								</div> */}
							</div>
						</Form>
					</div>
					</Modal>

					<Modal
                    ref={'newValueStoryCreation'}
                    modalStyle={changeModalBorderStyle}
                    contentStyle={drilldownModalBorderContentStyleNewVS}
                    rectStyle={changeModalBorderRectStyle}                   
                    keyboard={false}
                    backdrop={false}
                   >
                    <div >
						<Form
						    //className="grid-x medium-12" 
							model="discovery"
							getDispatch={(dispatch) => this.formDispatch = dispatch}
						>
														
							 <div>
									<div className="medium-6">
										<div style={{marginLeft:"550px",fontWeight:"bold"}}>
											Create a New Value Story
										</div>
									</div>
									<div className="medium-6 popupOkBtn">
										<div className="float-right">
											<div className="float-left"><Button
												content={"X"}
												onClick={() => this.hideNewVSCreationPopup()}
												color /></div>
										</div>
									</div>
								{/*<div className="medium-12 cell"> */}
									<NewValueStoryCreation  //ValueStoryCreationForm
										content={this.props.content.form}
										subLeverCalculationContent={this.props.subLeverCalculationContent.subLeverCalculation}
										dispatchLocationChange={this.dispatchLocationChange}
										dispatchIndirectScopeChange={this.dispatchIndirectScopeChange}
										dispatchIndustryChange={this.dispatchIndustryChange}
										dispatchTotalSpendChange={this.dispatchTotalSpendChange}
										dispatchUpdateTotalSpend={this.props.updateTotalSpend}
										dispatchUpdateValueStoryName={this.dispatchUpdateValueStoryName}
										dispatchUpdateCapIQId={this.dispatchUpdateCapIQId}
										dispatchUpdateOrganization={this.dispatchUpdateOrganization}
										dispatchCapIQChange={this.dispatchCapIQChange}
										dispatchUpdateBPID={this.dispatchUpdateBPID}
										dispatchUpdateANID={this.dispatchUpdateANID}
										dispatchUpdateValueStoryType={this.dispatchUpdateValueStoryType}
										isNewValueStory={this.props.newValueStory}										
										originalUserData={this.state.originalUserData}
										compareKeyValuesInObjectNewVS={this.compareKeyValuesInObjectNewVS}
										responseMessage={this.state.reponseMessage}
									/>
									<div className="formBg profile-pg">
										<div className="grid-x">
											<div className="medium-12 cell" style={{ paddingLeft: '233px' }}>												
													<div className="float-right" >
														<button className={classnames('button button--accent',
															(this.props.hoverButton === this.props.content.buttons.goToPriorities) ? 'hoverStyle' : null)}
															disabled={this.state.btnCreateValueStoryDisabledNewVS}
															onClick={(e) => { this.routeToNewVSPopup() }}
															onMouseEnter={(e) => this.handleMouseEnter(e)} onMouseLeave={this.handleMouseLeave}>
															<span className="button__inner">{'Next'}</span>
														</button>
													</div> 													
											</div>
										</div>
									</div>
								{/* </div> */}
								{/* <div className="medium-6 cell">
									<AccountParametersNewValueStory />
								</div> */}
							</div>
						</Form>
					</div>
					</Modal>
				</div >
			)
		}
	}
}

const mapStateToProps = (state) => {
	return {
		language: state.language,
		ui: state.content.ui,
		content: state.content.discovery,
		subLeverCalculationContent: state.content.subLeverCalculations,
		user: state.default,
		userData: state.valueStory,
		spendAsPercentageOfRevenue: state.spendAsPercentageOfRevenue,
		form: state.discoveryForm.$form,
		slide: state.goToChoosePath,
		priorities: state.priorities,
		valueStoryHasErrored: state.valueStoryHasErrored,
		valueStoryIsLoading: state.valueStoryIsLoading,
		benchMarksData: state.benchMarksData,
		regions: state.regions,
		companyData: state.companyProfileData,
		selectedValueStory: state.selectedValueStory,
		newValueStory: state.isNewValueStory,
		hoverButton: state.syncMenuAndButton,
		saveValueStoryAfterRedirect : state.saveValueStoryOnRedirect.changes
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		updateValueStoryName: (valuestoryName) => dispatch(updateValueStoryName(valuestoryName)),
		updateCapIQId: (capiqid) => dispatch(updateCapIQId(capiqid)),
		updateOrganization: (organization) => dispatch(updateOrganization(organization)),
		changeLocation: (location, spendAsPercentageOfRevenue) => dispatch(changeLocation(location, spendAsPercentageOfRevenue)),
		changeIndustry: (industry, spendAsPercentageOfRevenue) => dispatch(changeIndustry(industry, spendAsPercentageOfRevenue)),
		updateTotalSpend: (spend) => dispatch(updateTotalSpend(spend)),
		goToChoosePath: (bool) => dispatch(goToChoosePath(bool)),
		toggleValueDriver: (id, bool) => dispatch(toggleValueDriver(id, bool)),
		toggleSubLever: (id, bool) => dispatch(toggleSubLever(id, bool)),
		handleChange: (value, subLeverId, rowNumber) => dispatch(updateUserInput(value, subLeverId, rowNumber)),
		addValueStory: (formValues) => dispatch(addValueStory(formValues)),
		addValueStorySuccess: (valueStory) => dispatch(addValueStorySuccess(valueStory)),
		addValueStoryFailure: (error) => dispatch(addValueStoryFailure(error)),
		updateValueStory: (formValues) => dispatch(updateValueStory(formValues)),
		updateValueStorySuccess: (valueStory) => dispatch(updateValueStorySuccess(valueStory)),
		updateValueStoryFailure: (error) => dispatch(updateValueStoryFailure(error)),
		openValueStory: (VSId, Id) => dispatch(openValueStory(VSId, Id)),
		openValueStorySuccess: () => dispatch(openValueStorySuccess()),
		openValueStoryFailure: (error) => dispatch(openValueStoryFailure(error)),
		valueStoryFetchDataSuccess: (valueStory) => dispatch(valueStoryFetchDataSuccess(valueStory)),
		initPrioritiesItems: (items) => dispatch(initPrioritiesItems(items)),
		isNewValueStory: (data) => dispatch(isNewValueStory(data)),
		valueStoryCurrentMenu: (menuItem) => dispatch(valueStoryCurrentMenu(menuItem)),
		showValueStoryMenu: (menuItem) => dispatch(showValueStoryMenu(menuItem)),
		syncMenuAndButton: (selectedButton) => dispatch(syncMenuAndButton(selectedButton)),
		getCapIQDataBlank: bool => dispatch(getCapIQDataBlank(bool)),
		updateBPID: (data) => dispatch(updateBPID(data)),
		updateANID: (data) => dispatch(updateANID(data)),
		updateValueStoryType: (data) => dispatch(updateValueStoryType(data)),
		pendingChanges: changes => dispatch(pendingChanges(changes)),
		//resetError: () => dispatch(resetError()),
		defineScopeLoading: bool => dispatch(defineScopeLoading(bool)),
		setSubTabMenu:(val)=>dispatch(setSubTabMenu(val)),
        setTabMenu:(val)=>dispatch(setTabMenu(val)),
		isValueStoryPage:(obj)=>dispatch(isValueStoryPage(obj)),
		saveValueStoryOnRedirect: changes => dispatch(saveValueStoryOnRedirect(changes)),
		changeIndirectSolutionScope: (value) => dispatch(changeIndirectSolutionScope(value)),
		}
}

export default connect(mapStateToProps, mapDispatchToProps)(SpendDiscoveryContainer)