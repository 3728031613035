import React, { Component, PureComponent, Fragment } from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'
import StackedBarChart from 'components/UI/StackedBarChart'
import ZoomModal from 'components/UI/ZoomModal'
import HorizontalBarChart from 'components/UI/HorizontalBarChart'
import _ from 'lodash';
import {
    scopeSummarySuccess,
    scopeSummaryLoading,
    resetError,
    scopeSummaryFailure,
    getScopeSummarySolution,
    getBuyProcessSummary,
    getScopeSummaryBuyProcess,
    scopeSummaryBuyProcessSuccess,
    scopeSummaryBuyProcessLoading,
    scopeSummaryBuyProcessFailure,
    //getDefineScopesDetails
} from 'actions/spendActions'
import { formatThousands } from 'utils/Utils'

import { validators } from 'Constants'
import VideoHelp from 'components/UI/VideoHelp'

class ScopeSummaryContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            initialData: [],
            scopeSummaryData: [],
            buyProcessData: [],
            initialBuyProcessData: [],
            scopeSolution: [
                { label: "Total Invoice Spend", value: "Total Invoice Spend" },
                { label: "Total Supplier Count", value: "Total Supplier Count" },
                { label: "Total Invoice Count", value: "Total Invoice Count" }
            ],
            scopeSolutionFilter: 'Total Invoice Spend',
            type: 'Spend',
            currencySymbol: '',
            scopeSummaryData: [],
            scopeSummaryBuyProcessData: [],
            buyProcessSolutionSelected: 'All',
            solutionsOptions: [{ label: 'All', value: 'All' },
            { label: 'Sourcing', value: 'Sourcing' },
            { label: 'Commerce Automation', value: 'Commerce Automation' },
            { label: 'Buying and Invoicing', value: 'Buying and Invoicing' },
            { label: 'Fieldglass', value: 'Fieldglass' },
            { label: 'Discount/Terms Rationalization', value: 'Discount/Terms Rationalization' },
            { label: 'Supply Chain Collaboration', value: 'Supply Chain Collaboration' }],
            buyProcessSelected: 'All',
            buyProcessOptions: [],
            industryLevelSelected: '3',
            industryLevelOptions: [{ label: '3', value: 3 },
            { label: '4', value: 4 },
            { label: '5', value: 5 }],
            isError: false,
            errorMessage: ''
        }

        this.aggregateInvoiceSpend = this.aggregateSupplierValues.bind(this)
    }

    componentDidMount() {
        this.loadData()
        window.scrollTo(0, 0);
    }

    componentWillUnmount(){
        this.props.resetError();
    }

    loadData() {
        this.getScopeSummarySolution();
        this.getBuyProcessSummary();
        this.getScopeSummaryBuyProcess()
    }

    getScopeSummarySolution() {
        var scopeRequest_obj = {
            VS_ID: this.props.spendDetails.currentActiveRequest.VS_ID,
            DMC_ID: this.props.spendDetails.currentActiveRequest.DMC_ID,
            USER_ID: this.props.user.user.Email,
        }

        this.props.getScopeSummarySolution(scopeRequest_obj)
            .then((response) => {
                //this.props.scopeSummarySuccess(response.data.Message)
                let data = response.data.Result;
                //.filter(item => item == 'IN_SCOPE_AGG_SPEND' || item == 'OUT_SCOPE_AGG_SPEND')
                // IN_SCOPE_SPEND:null
                // OUT_SCOPE_SPEND:23846
                // SOLUTION_TYPE:"Sourcing"
                var array = [];
                data.forEach((element) => {
                    if (element.IN_SCOPE_AGG_SPEND == null && element.OUT_SCOPE_AGG_SPEND == null) {
                        array = [];
                    }
                    else {
                        var obj = {};
                        obj.IN_SCOPE_SPEND = element.IN_SCOPE_AGG_SPEND;
                        obj.OUT_SCOPE_SPEND = element.OUT_SCOPE_AGG_SPEND;
                        obj.SOLUTION_TYPE = element.SOLUTION_TYPE;
                        obj["InScopeValue"] = element.IN_SCOPE_AGG_SPEND;
                        obj["OutScopeValue"] = element.OUT_SCOPE_AGG_SPEND;
                        array.push(obj);
                    }
                });
                if (response.data.Result != undefined) {
                    this.setState({ scopeSummaryData: array, initialData: response.data.Result });
                }
            }).catch((error) => {
                if (error.stack.includes('Network Error')) {
                    this.props.scopeSummaryFailure()
                }
                else {
                    this.props.scopeSummaryFailure(error.response.data.Message)
                }
            })
    }

    getBuyProcessSummary() {
        var scopeRequest_obj = {
            VS_ID: this.props.spendDetails.currentActiveRequest.VS_ID,
            DMC_ID: this.props.spendDetails.currentActiveRequest.DMC_ID,
            USER_ID: this.props.user.user.Email,
        }

        this.props.getBuyProcessSummary(scopeRequest_obj)
            .then((response) => {
                this.props.scopeSummarySuccess(response.data.Message)
                let data = response.data.Result;
                var array = [];
                var buyProcessOptions = [{ label: 'All', value: 'All' }]
                data.forEach((element) => {
                    var obj = {};
                    obj.value = element.AGGR_INV_SPEND;
                    obj.name = element.SAP_ARIBA_BUY_PROCESS;
                    array.push(obj);

                    buyProcessOptions.push({ label: obj.name, value: obj.name })
                });
                if (response.data.Result != undefined) {
                    buyProcessOptions.sort()
                    this.setState({ buyProcessData: array, initialBuyProcessData: response.data.Result, buyProcessOptions: buyProcessOptions });
                }
            }).catch((error) => {
                if (error.stack.includes('Network Error')) {
                    this.props.scopeSummaryFailure()
                }
                else {
                    this.props.scopeSummaryFailure(error.response.data.Message)
                }
            })
    }

    getScopeSummaryBuyProcess() {
        this.props.scopeSummaryBuyProcessLoading(true)
        this.setState({isError: false, errorMessage: ''})
        var buyProcessRequestObj = {
            VS_ID: this.props.spendDetails.currentActiveRequest.VS_ID,
            DMC_ID: this.props.spendDetails.currentActiveRequest.DMC_ID,
            USER_ID: this.props.user.user.Email,
            SOLUTION: this.state.buyProcessSolutionSelected === 'All' ? 'No Filter' : this.state.buyProcessSolutionSelected,
            BUY_PROCESS: this.state.buyProcessSelected === 'All' ? 'No Filter' : this.state.buyProcessSelected,
            INDUSTRY_LEVEL: Number.parseInt(this.state.industryLevelSelected)
        }

        this.props.getScopeSummaryBuyProcess(buyProcessRequestObj)
            .then((response) => {
                
                let data = response.data.Result;

                var array = [];
                data.forEach((element) => {
                    var obj = {};
                    obj.industryLevel = element.INDUSTRY_LEVEL;
                    obj.industryDesc = element.INDUSTRY_DESC;
                    obj.aggrInvSpend = element.AGG_INVOICE_SPEND;
                    obj.aggrInvCount = element.AGG_INVOICE_COUNT;
                    obj.supplierCount = element.SUPPLIER_COUNT;
                    obj.aggrInvSpendPerc = element.AGG_INVOICE_SPEND_PERC;
                    obj.aggrInvCountPerc = element.AGG_INVOICE_COUNT_PERC;
                    obj.aggSupplierCountPerc = element.AGG_SUPPLIER_COUNT_PERC;
                    obj.invSpendRank = element.INVOICE_SPEND_RANK
                    obj.invCountRank = element.INVOICE_COUNT_RANK
                    obj.supplierCountRank = element.SUPPLIER_COUNT_RANK

                    array.push(obj);
                });

                if (response.data.Result != undefined) {
                    this.setState({
                        scopeSummaryBuyProcessData: array,
                        currencySymbol: this.props.spendDetails.currentActiveRequest !== undefined && this.props.spendDetails.currentActiveRequest !== ''?
                            this.props.currencySymbols[this.props.spendDetails.currentActiveRequest.Currency.trim()] : ''
                    });
                }
                this.props.scopeSummaryBuyProcessSuccess(response.data.Message)
            }).catch((error) => {
                //console.log(error)
                var errorMessage = ''
                if (error.stack.includes('Network Error')) {
                    errorMessage = 'Network Error.'
                    this.props.scopeSummaryBuyProcessFailure('API Service is down.Kindly contact administrator.')
                }
                else {
                    this.props.scopeSummaryBuyProcessFailure(error.response.data.Message)
                    errorMessage = this.props.content.noDataFound
                }

                this.setState({isError: true, errorMessage: errorMessage})

            })
    }

    handleSolutionChange(scope) {
        this.setState({ scopeSolutionFilter: scope.value }, this.filterChange)
    }

    filterChange() {
        this.props.scopeSummaryBuyProcessLoading(true)
        let data = this.state.initialData;
        
        var array = [];
        data.forEach((element) => {
            var obj = {};
            if (this.state.scopeSolutionFilter == 'Total Invoice Spend') {
                if (element.IN_SCOPE_AGG_SPEND == null && element.OUT_SCOPE_AGG_SPEND == null) {
                    array = [];
                }
                else {
                    obj.IN_SCOPE_SPEND = element.IN_SCOPE_AGG_SPEND;
                    obj.OUT_SCOPE_SPEND = element.OUT_SCOPE_AGG_SPEND;
                    obj.SOLUTION_TYPE = element.SOLUTION_TYPE;
                    obj["InScopeValue"] = element.IN_SCOPE_AGG_SPEND;
                    obj["OutScopeValue"] = element.OUT_SCOPE_AGG_SPEND;
                    array.push(obj);
                }
            } else if (this.state.scopeSolutionFilter == 'Total Supplier Count') {
                if (element.IN_SCOPE_SUPPLIER_COUNT == null && element.OUT_SCOPE_SUPPLIER_COUNT == null) {
                    array = [];
                }
                else {
                    obj.IN_SCOPE_SPEND = element.IN_SCOPE_SUPPLIER_COUNT;
                    obj.OUT_SCOPE_SPEND = element.OUT_SCOPE_SUPPLIER_COUNT;
                    obj.SOLUTION_TYPE = element.SOLUTION_TYPE;
                    obj["InScopeValue"] = element.IN_SCOPE_SUPPLIER_COUNT;
                    obj["OutScopeValue"] = element.OUT_SCOPE_SUPPLIER_COUNT;
                    array.push(obj);
                }
            } else if (this.state.scopeSolutionFilter == 'Total Invoice Count') {
                if (element.IN_SCOPE_AGG_COUNT == null && element.OUT_SCOPE_AGG_COUNT == null) {
                    array = [];
                }
                else {
                    obj.IN_SCOPE_SPEND = element.IN_SCOPE_AGG_COUNT;
                    obj.OUT_SCOPE_SPEND = element.OUT_SCOPE_AGG_COUNT;
                    obj.SOLUTION_TYPE = element.SOLUTION_TYPE;
                    obj["InScopeValue"] = element.IN_SCOPE_AGG_COUNT;
                    obj["OutScopeValue"] = element.OUT_SCOPE_AGG_COUNT;
                    array.push(obj);
                }
            }
        });

        let data2 = this.state.initialBuyProcessData;
        var array2 = [];
        data2.forEach((element) => {
            var obj2 = {};
            if (this.state.scopeSolutionFilter == 'Total Invoice Spend') {
                obj2.value = element.AGGR_INV_SPEND;
                obj2.name = element.SAP_ARIBA_BUY_PROCESS;
                array2.push(obj2);
            } else if (this.state.scopeSolutionFilter == 'Total Supplier Count') {
                obj2.value = element.SUPPLIER_COUNT;
                obj2.name = element.SAP_ARIBA_BUY_PROCESS;
                array2.push(obj2);
            } else if (this.state.scopeSolutionFilter == 'Total Invoice Count') {
                obj2.value = element.AGGR_INV_COUNT;
                obj2.name = element.SAP_ARIBA_BUY_PROCESS;
                array2.push(obj2);
            }
        });

        let type = '';
        if (this.state.scopeSolutionFilter == 'Total Invoice Spend') {
            type = 'Spend'
        } else if(this.state.scopeSolutionFilter == 'Total Supplier Count') {
            type = 'Count'
        }else{
            type = 'Invoice_Count'
        }

        this.setState({ scopeSummaryData: array, type: type, buyProcessData: array2 });
        this.props.scopeSummaryBuyProcessSuccess('')
    }

    shouldComponentUpdate() {
        return true
    }

    getBuyProcessArray() {
        var array = this.state.scopeSummaryBuyProcessData

        if (this.state.scopeSolutionFilter == 'Total Invoice Spend') {
            array.sort((item1, item2) => {
                return item1.invSpendRank - item2.invSpendRank
            })
        } else if (this.state.scopeSolutionFilter == 'Total Supplier Count') {
            array.sort((item1, item2) => {
                return item1.supplierCountRank - item2.supplierCountRank
            })
        } else if (this.state.scopeSolutionFilter == 'Total Invoice Count') {
            array.sort((item1, item2) => {
                return item1.invCountRank - item2.invCountRank
            })
        }

        var arrayFinal = array.slice(0, 10);
        var obj = {
            industryLevel: 0,
            industryDesc: 'Other Industries',
            aggrInvSpend: 0,
            aggrInvCount: 0,
            supplierCount: 0,
            aggrInvSpendPerc: 0,
            aggrInvCountPerc: 0,
            aggSupplierCountPerc: 0,
            invSpendRank: 0,
            invCountRank: 0,
            supplierCountRank: 0,
        }

        array.slice(0, 10).forEach((item) => {
            obj.aggrInvSpendPerc += Number(item.aggrInvSpendPerc === null? 100 : item.aggrInvSpendPerc.toFixed(1))
            obj.aggrInvCountPerc += Number(item.aggrInvCountPerc === null? 100 : item.aggrInvCountPerc.toFixed(1))
            obj.aggSupplierCountPerc += Number(item.aggSupplierCountPerc === null? 100 : item.aggSupplierCountPerc.toFixed(1))
        })

        var foundOtherIndustries = false
        array.slice(10).forEach((item) => {
            foundOtherIndustries = true
            obj.aggrInvSpend += Number(item.aggrInvSpend === null? 0 : item.aggrInvSpend.toFixed(2))
            obj.aggrInvCount += item.aggrInvCount
            obj.supplierCount += item.supplierCount
        })

        if (foundOtherIndustries) {
            obj.aggrInvSpendPerc = 100 - obj.aggrInvSpendPerc
            obj.aggrInvCountPerc = 100 - obj.aggrInvCountPerc
            obj.aggSupplierCountPerc = 100 - obj.aggSupplierCountPerc

            arrayFinal.push(obj)
        }

        return arrayFinal
    }

    buyProcessSolutionChange(value) {
        this.setState({ buyProcessSolutionSelected: value.label }, this.getScopeSummaryBuyProcess);
    }

    buyProcessChange(value) {
        this.setState({ buyProcessSelected: value.label }, this.getScopeSummaryBuyProcess);
    }

    industryLevelChange(value) {
        this.setState({ industryLevelSelected: value.label }, this.getScopeSummaryBuyProcess);
    }

    aggregateSupplierValues() {
        var total = 0;
        this.state.scopeSummaryBuyProcessData.forEach((item) => {
            if (this.state.scopeSolutionFilter == 'Total Invoice Spend') {
                total +=  Number(this.getRoundedValue(item.aggrInvSpend, 0, 2))              
            } else if (this.state.scopeSolutionFilter == 'Total Supplier Count') {
                total +=  Number(this.getRoundedValue(item.supplierCount, 0, 0))
            } else if (this.state.scopeSolutionFilter == 'Total Invoice Count') {
                total +=  Number(this.getRoundedValue(item.aggrInvCount, 0, 0))
            }
            
        })

        if (this.state.scopeSolutionFilter == 'Total Invoice Spend') {
            return this.state.currencySymbol+formatThousands(this.getRoundedValue(total / 1000000.0, 0, 2))+' M'
        } else if (this.state.scopeSolutionFilter == 'Total Supplier Count') {
            return formatThousands(total);
        } else if (this.state.scopeSolutionFilter == 'Total Invoice Count') {
            return formatThousands(this.getRoundedValue(total / 1000.0, 0, 2))+' K'
        }
    }

    getRoundedValue(value, defaultValue, roundDigits) {
        return (value === null || isNaN(value))? defaultValue : value.toFixed(roundDigits)
    }

    supplierPercentage(item) {
        if (this.state.scopeSolutionFilter == 'Total Invoice Spend') {
            return this.getRoundedValue(item.aggrInvSpendPerc, 100, 1)
        } else if (this.state.scopeSolutionFilter == 'Total Supplier Count') {
            return this.getRoundedValue(item.aggSupplierCountPerc, 100, 1)
        } else if (this.state.scopeSolutionFilter == 'Total Invoice Count') {
            return this.getRoundedValue(item.aggrInvCountPerc, 100, 1)
        }
    }

    supplierNumbers(item) {
        if (this.state.scopeSolutionFilter == 'Total Invoice Spend') {
            return this.state.currencySymbol+formatThousands(this.getRoundedValue(item.aggrInvSpend/1000000.00, 0, 2))+' M'
        } else if (this.state.scopeSolutionFilter == 'Total Supplier Count') {
            return formatThousands(this.getRoundedValue(item.supplierCount, 0, 0))
        } else if (this.state.scopeSolutionFilter == 'Total Invoice Count') {
            return formatThousands(this.getRoundedValue(item.aggrInvCount/1000, 0, 2))+' K'
        }
    }

    render() {
        return (
            <div className='defineScope'>
                <div className="grid-x pt5">
                    <div className="medium-12">
                        <div className="tab-title">{(this.props.content.title)}{<VideoHelp screen={{module: 'Spend', screenName: 'Scope Summary'}}/>}</div>
                        <div className="tab-description pb10">{this.props.content.subTitle}</div>
                        <div className="width170">
                                        <Select
                                            className="border-with-DD dropDownContent"
                                            model='yourFile.RequestId'
                                            id='RequestId'
                                            autocomplete='true'
                                            //placeholder={this.props.content.notConfirmed}
                                            value={this.state.scopeSolution.filter(
                                                option => option.value === this.state.scopeSolutionFilter
                                            )}
                                            onChange={value => this.handleSolutionChange(value)}
                                            options={this.state.scopeSolution}
                                            isSearchable={false}
                                        />
                        </div>
                        <div className="clearfix pt30">
                            <div className="grid-x grid-padding-x">
                                <div className="medium-6 brcacaca">
                                    <div className="graph-title pt10 clearfix bb_cacaca">
                                        <div className="float-left">
                                            {this.props.content.scopeTitle}
                                        </div>
                                    </div>
                                    <div>
                                        {this.state.scopeSummaryData !== (null && '' && undefined) && this.state.scopeSummaryData.length > 0 ?
                                           <ZoomModal classStyle="zoom-chart-style"><StackedBarChart data={this.state.scopeSummaryData} currency = {this.props.currencySymbols[this.props.spendDetails.currentActiveRequest.Currency !== undefined ? this.props.spendDetails.currentActiveRequest.Currency.trim() : 'USD']} type={this.state.type} /></ZoomModal> 
                                            : <div className="pt10">{this.props.noData.noDataFound}</div>
                                        }
                                        
                                    </div>
                                    <div className="clearfix">
                                        <div className="float-right-legend">
                                            <div class="legend2"> <span class="key-dot inScope"></span>{this.state.type == 'Spend'? this.props.content.inScopeSpend : this.props.content.inScopeCount} </div>
                                            <div class="legend2"> <span class="key-dot outScope"></span>{this.state.type == 'Spend'? this.props.content.outScopeSpend : this.props.content.outScopeCount}</div>
                                        </div>
                                    </div>
                                    <div className="graph-title clearfix">
                                        <div className="float-left">
                                            {this.props.content.buyProcessTitle}
                                        </div>
                                    </div>
                                    <div>
                                        {this.state.buyProcessData !== (null && '' && undefined) && this.state.buyProcessData.length > 0 ?
                                            <ZoomModal classStyle="zoom-chart-style"><HorizontalBarChart data={this.state.buyProcessData} currency = {this.props.currencySymbols[this.props.spendDetails.currentActiveRequest.Currency !== undefined ? this.props.spendDetails.currentActiveRequest.Currency.trim() : 'USD']} type={this.state.type} yLabel={this.state.scopeSolutionFilter} /></ZoomModal>
                                            : <div className="pt10">{this.props.noData.noDataFound}</div>
                                        }
                                    </div>
                                </div>
                                <div className="medium-6 pt10 bb_cacaca cell">
                                    <div className="grid-x grid-padding-x scope-sum-DD">
                                        
                                            <div className="medium-6 cell">
                                                <div className="float-left graph-title">{this.props.content.top10SupplierIndustries}</div>
                                            </div>
                                            <div className="medium-6 cell">
                                                <div className="float-right in-scope-title">{this.props.content.inScope}:
                                                    <span className="in-scope-value"> {this.aggregateSupplierValues()}</span>
                                                </div>
                                            </div>
                                       
                                        <div className="medium-4 pt10 cell">
                                            <label>{this.props.content.solution}</label>
                                            <Select
                                                className="border-with-DD filterContent"
                                                model='buyProcess.Solution'
                                                id='buyProcessSolution'
                                                value={this.state.solutionsOptions.filter(option => option.label === this.state.buyProcessSolutionSelected)}
                                                onChange={(value) => this.buyProcessSolutionChange(value)}
                                                validators={{ required: validators.required }}
                                                validateOn='change'
                                                options={this.state.solutionsOptions}
                                                isSearchable={false}
                                                noOptionsMessage={() => null}
                                            />
                                        </div>
                                        <div className="medium-4 pt10 cell">
                                            <label>{this.props.content.buyProcess}</label>
                                            <Select
                                                className="border-with-DD filterContent"
                                                model='buyProcess.buyProcess'
                                                id='buyProcess'
                                                value={this.state.buyProcessOptions.filter(option => option.label === this.state.buyProcessSelected)}
                                                onChange={(value) => this.buyProcessChange(value)}
                                                validators={{ required: validators.required }}
                                                validateOn='change'
                                                options={this.state.buyProcessOptions}
                                                isSearchable={false}
                                                noOptionsMessage={() => null}
                                            />
                                        </div>
                                        <div className="medium-4 pt10 cell">
                                            <label>{this.props.content.industryLevel}</label>
                                            <Select
                                                className="border-with-DD filterContent"
                                                model='buyProcess.industryLevel'
                                                id='industryLevel'
                                                value={this.state.industryLevelOptions.filter(option => option.label === this.state.industryLevelSelected)}
                                                onChange={(value) => this.industryLevelChange(value)}
                                                validators={{ required: validators.required }}
                                                validateOn='change'
                                                options={this.state.industryLevelOptions}
                                                isSearchable={false}
                                                noOptionsMessage={() => null}
                                            />
                                        </div>
                                    </div>
                                    <div className="scope-summary pt30">
                                    {!this.state.isError ? <ZoomModal classStyle="zoom-div-style">{this.getBuyProcessArray().map((item, index) => {
                                            return (<div className="grid-x grid-padding-x pt5 pb5 bb_cacaca">
                                                <div className="medium-1 cell align-center">{index + 1}</div>
                                                <div className="medium-4 cell">{item.industryDesc}</div>
                                                <div className="medium-1 cell align-right" style={{textAlign:'right'}}>{this.supplierPercentage(item)}%</div>
                                                <div className="medium-4 cell">
                                                    <div style={{ backgroundColor: "#f7f7f7" }} class="medium-11">
                                                        <div style={{ width: this.supplierPercentage(item) + '%', color: "rgba(51, 195, 255, 0)", backgroundColor: "#33C3FF" }}>
                                                            {this.supplierPercentage(item)}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="medium-2 cell in-scope-value" style={{textAlign:'right', paddingRight:'30px'}}>
                                                    {this.supplierNumbers(item)}
                                                </div>
                                            </div>)
                                        })}</ZoomModal>  : <div style={{ color: 'red', textAlign: 'center' }} className="cell pt5">{this.state.errorMessage}</div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}



const mapStateToProps = state => {
    return {
        // language: state.language,
        // ui: state.content.ui,
        user: state.default,
        content: state.content.spendAnalysis.scopeSummary,
        spendDetails: state.spendDetails,
        currencySymbols: state.content.CurrencySymbols,
        noData: state.content.spendAnalysis.defineScope,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        scopeSummarySuccess: message => dispatch(scopeSummarySuccess(message)),
        scopeSummaryFailure: error => dispatch(scopeSummaryFailure(error)),
        resetError: error => dispatch(resetError(error)),
        scopeSummaryLoading: bool => dispatch(scopeSummaryLoading(bool)),
        getScopeSummarySolution: (formValues) => dispatch(getScopeSummarySolution(formValues)),
        getBuyProcessSummary: (values) => dispatch(getBuyProcessSummary(values)),
        getScopeSummaryBuyProcess: (formValues) => dispatch(getScopeSummaryBuyProcess(formValues)),
        scopeSummaryBuyProcessSuccess: message => dispatch(scopeSummaryBuyProcessSuccess(message)),
        scopeSummaryBuyProcessFailure: error => dispatch(scopeSummaryBuyProcessFailure(error)),
        scopeSummaryBuyProcessLoading: bool => dispatch(scopeSummaryBuyProcessLoading(bool)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ScopeSummaryContainer)
