import React from 'react'
import { connect } from 'react-redux'

import {
    enablementInsightSuccess,
    enablementInsightFailure,
    enablementInsightLoading,
    resetError, updateWaveDescription
} from 'actions/spendActions'

import { convertToMillion, formatThousands } from 'utils/Utils'
import { store } from 'index'
import ExpandableTableRowContainer from './ExpandableTableRowContainer';
import EnablementWaveRowContainer from './EnablementWaveRowContainer';
import WaveDescriptionPopup from './WaveDescriptionPopup';
import { PopupE } from 'UI/CustomComponents/Controls';
import EnablementMappingKeysTable from 'components/Spend/EnablementMappingKeysTable'

class ExpandableTable extends React.Component {
    constructor(props) {
        super(props)
        {
            this.state = {
                industry: '',
                industryOption: [],
                supplierData: [],
                supplierDetails: [],
                currencySymbol: '',
                data: [],
                columnDetails: {},
                helpText: '',
                openWaveDesc: false,
                waveDescriptionData: '',
                showEnablementRecWarning : false,
                openEnablementRecWarningPopup : false,
                inputParam: {
                    DMC_ID: this.props.spendDetails !== undefined && this.props.spendDetails.currentActiveRequest !== undefined ? this.props.spendDetails.currentActiveRequest.DMC_ID : 0
                    , VS_ID: this.props.spendDetails !== undefined && this.props.spendDetails.currentActiveRequest !== undefined ? this.props.spendDetails.currentActiveRequest.VS_ID : 0,
                    User_Email: this.props.spendDetails.currentActiveRequest !== undefined &&
                        this.props.spendDetails.currentActiveRequest.SAPRequester === this.props.user.user.Email ? this.props.user.user.Email : this.props.spendDetails.currentActiveRequest.SAPRequester,
                    Wave_Description: '',
                    Enablement_Wave: ''
                },
            }

            this.handleWaveDescriptionChange = this.handleWaveDescriptionChange.bind(this)
            this.outsideWaveDescPopupClick = this.outsideWaveDescPopupClick.bind(this)
            this.closeWaveDescPopUp = this.closeWaveDescPopUp.bind(this)
            this.addWaveDescription = this.addWaveDescription.bind(this)
            // this.openGanttChartEnablementStrategy =this.openGanttChartEnablementStrategy(this)
            // this.closeGanttChartEnablementStrategy =this.closeGanttChartEnablementStrategy(this)
        }
    }

    componentDidMount() {
        //this.loadInitialData()
        window.scrollTo(0, 0);
        let columnDetails = this.getFirstColumnName()
        this.setState({
            currencySymbol: this.props.currencySymbols[this.props.spendDetails.currentActiveRequest.Currency !== undefined
                && this.props.spendDetails.currentActiveRequest.Currency.trim() !== ''
                && this.props.spendDetails.currentActiveRequest.Currency.trim() !== "0" ?
                this.props.spendDetails.currentActiveRequest.Currency.trim() : 'USD']
            , columnDetails: columnDetails
        });
    }

    componentWillReceiveProps(nextProps) {
        let showWarning = false;
        if (this.props.tableName == this.props.content.enablementWaveDetails.enablementRecommendationHeader) {
            nextProps.data.rows.some(function(row , i) {
                if (row.content.toLowerCase().includes('candidate')) {
                    showWarning = true;
                    return true
                }
              });
        }

        this.setState({ data: nextProps.data , showEnablementRecWarning : showWarning})
    }


    getFirstColumnName() {
        var firstColumnName;
        var firstColumn;

        var secondColumnName;
        var secondColumn;
        var thirdColumn;
        var helpText;

        switch (this.props.tableName) {
            case 'Enablement Strategy':
                firstColumnName = this.props.content.enablementWaveFstColHeader;
                firstColumn = this.props.content.enablementWaveFstCol;
                helpText = this.props.content.enablementWaveHelpText;

                secondColumnName = this.props.content.enablementWaveSecColHeader;
                secondColumn = this.props.content.enablementWaveSecCol;
                break;

            case 'Enablement Recommendation':
                firstColumnName = this.props.content.enablementRecmdFstColHeader;
                firstColumn = this.props.content.enablementRecmdFstCol;
                helpText = this.props.content.enablementRecmdHelpText;
                secondColumnName = this.props.content.enablementRecmdSecColHeader;
                secondColumn = this.props.content.enablementRecmdSecCol;
                thirdColumn = this.props.content.enablementRecmdThirdCol;
                break;    

            case 'Enablement Strategy by Onboarding Workstream with Enablement Recommendation':
                firstColumnName = this.props.content.enablementWaveFstColHeader;
                firstColumn = this.props.content.enablementWaveFstCol;
                helpText = this.props.content.enablementWaveHelpText;

                secondColumnName = this.props.content.enablementWaveSecColHeader;
                secondColumn = this.props.content.enablementWaveSecCol;
                thirdColumn = this.props.content.enablementWaveThirdCol;
                break;    

            case 'Enablement Strategy by Onboarding Workstream with L4 Industry Name':
                firstColumnName = this.props.content.enablementWaveFstColHeader;
                firstColumn = this.props.content.enablementWaveFstCol;
                helpText = this.props.content.enablementWaveHelpText;

                secondColumnName = this.props.content.enablementWaveSecColHeader;
                secondColumn = this.props.content.enablementWaveSecCol;
                thirdColumn = this.props.content.enablementWaveL4IndustryCol;               
                break;

            case 'Enablement Strategy by Onboarding Workstream with ISO Country Code':
                firstColumnName = this.props.content.enablementWaveFstColHeader;
                firstColumn = this.props.content.enablementWaveFstCol;
                helpText = this.props.content.enablementWaveHelpText;
                secondColumnName = this.props.content.enablementWaveSecColHeader;
                secondColumn = this.props.content.enablementWaveSecCol;
                thirdColumn = this.props.content.enablementWaveISOCountryCol;               
                break;    

            case 'Enablement Strategy by Onboarding Workstream with Details':
                firstColumnName = this.props.content.enablementWaveFstColHeader;
                firstColumn = this.props.content.enablementWaveFstCol;
                helpText = this.props.content.enablementWaveHelpText;

                secondColumnName = this.props.content.enablementWaveSecColHeader;
                secondColumn = this.props.content.enablementWaveSecCol;
                thirdColumn = this.props.content.enablementWaveWorkStreamDetailCol;
                break;    

            case 'Catalog Match':
                firstColumnName = this.props.content.catalogMatchFstColHeader;
                firstColumn = this.props.content.catalogMatchFstCol;
                helpText = this.props.content.catalogMatchHelpText;

                secondColumnName = this.props.content.catalogMatchSecColHeader;
                secondColumn = this.props.content.catalogMatchSecCol
                break;


            case 'Integration Match':
                firstColumnName = this.props.content.integrationMatchFstColHeader;
                firstColumn = this.props.content.integrationMatchFstCol;
                helpText = this.props.content.integrationMatchHelpText;

                secondColumnName = this.props.content.integrationMatchSecColHeader;
                secondColumn = this.props.content.integrationMatchSecCol;
                break;

            case 'Onboarding Workstreams':
                firstColumn = this.props.content.enablementWaveSecCol;
                secondColumn = this.props.content.enablementWaveWorkStreamDetailCol;
                thirdColumn = 'all';
                break;
        }

        let columnDetails = { firstColumnName: firstColumnName, firstColumn: firstColumn, secondColumnName: secondColumnName, secondColumn: secondColumn, thirdColumn: thirdColumn, helpText: helpText }
        return columnDetails;
    }

    getBackgroundColor() {
        let bgColor;
        switch (this.props.tableSequence) {
            case "1":
                bgColor = '#00BFD3';//'#0f46a7';
                break;
            case "2":
                bgColor = '#BE008C'//'#760a85'
                break;
            case "3":
                bgColor = '#FA9100'//'#e35500'
                break;
            case "4":
                bgColor = '#93C939'//'#348026'
                break;
            case "5":
                bgColor = '#999999'//'#7f7f7f'
                break;
            case "6":
                bgColor = '#b90c0d';//'#0f46a7';
                break;
            case "7":
                bgColor = '#367dc4'//'#760a85'
                break;
            case "8":
                bgColor = '#69767c'//'#e35500'
                break;
            case "9":
                bgColor = '#de890d'//'#348026'
                break;
            case "10":
                bgColor = '#f29b1d'
                break;
            case "11":
                bgColor = '#f5b04d'
                break;
            case "12":
                bgColor = '#848f94'
                break;
            case "13":
                bgColor = '#9ea8ad'
                break;
            case "14":
                bgColor = '#bac1c4'
                break;
            case "15":
                bgColor = '#d5dadc'
                break;
            case "16":
                bgColor = '#358a4d'
                break;
            case "17":
                bgColor = '#3fa45b'
                break;
            case "18":
                bgColor = '#4cba6b'
                break;
            case "19":
                bgColor = '#dc0d0e'
                break;
            case "20":
                bgColor = '#2a6d3c'
                break;
            case "21":
                bgColor = '#358a4d'
                break;
            case "22":
                bgColor = '#3fa45b'
                break;
            case "23":
                bgColor = '#4cba6b'
                break;
            case "24":
                bgColor = '#71c989'
                break;
            case "25":
                bgColor = '#f8cc8c'
                break;
            case "26":
                bgColor = '#f0ab00'
                break;
            case "27":
                bgColor = '#a1dbb1'
                break;
        }

        return bgColor;
    }

    handleWaveDescriptionChange(selectedRows) {
        // console.log("control parent method..................",selectedRows)
        this.setState({ openWaveDesc: true, waveDescriptionData: selectedRows.data })
    }

    closeWaveDescPopUp() {
        this.setState({ openWaveDesc: !this.state.openWaveDesc })
    }

    outsideWaveDescPopupClick() {
        this.setState({ openWaveDesc: false })
    }

    addWaveDescription(waveDesc) {
        var selectedWaveRecord = this.state.inputParam
        selectedWaveRecord.Wave_Description = waveDesc
        selectedWaveRecord.Enablement_Wave = this.state.waveDescriptionData.ENABLEMENT_WAVE

        this.props.updateWaveDescription(selectedWaveRecord)
            .then((response) => {
                if (response !== undefined && response.status === 200 && response.data.Result !== undefined) {
                    this.props.enablementInsightSuccess(true)
                    this.setState({ openWaveDesc: !this.state.openWaveDesc })
                    this.props.reloadEnablementWave(true)
                }
            }).catch((error) => {
                if (error.stack.includes('Network Error')) {
                    this.setState({ responseMsg: 'API service is down. Please contact administrator', isError: 'Yes' })
                    this.props.enablementInsightFailure('API service is down. Please contact administrator.')
                }
                else {
                    this.setState({ responseMsg: 'An error has occurred', isError: 'Yes' })
                    this.props.enablementInsightFailure(this.props.content.error)
                }
            })
    }

    openGanttChartEnablementStrategy()
    {
        this.setState({ openEnablementRecWarningPopup : true });
    }

    closeGanttChartEnablementStrategy()
    {
        this.setState({ openEnablementRecWarningPopup : false });
    }

    render() {

        return (
            <div>
                <div className='basic-table'>
                    {this.state.showEnablementRecWarning && this.props.tableName == this.props.content.enablementWaveDetails.enablementRecommendationHeader ?
                        <div style={{ paddingBottom: '15px'}}>
                            <span style={{ color: 'red'}}>When "Candidate" Enablement Recommendations are present the system assigns the Onboarding Workstream using the following <a style={{cursor: 'pointer' }} onClick={this.openGanttChartEnablementStrategy.bind(this)}>matrix</a>. Users executing supplier enablement should manually assign the proper Onboarding Workstream for each supplier in collaboration with the buyer.</span>
                        </div>
                    : null }
                    <table className='enablementInsightTable custScroll'>
                        {/* <caption color="Red">{this.props.tableName}</caption>      */}
                        <thead><tr>
                            <th colSpan={(this.props.tabName === this.props.content.enablementTabName
                            || this.props.tableName === this.props.content.networkMatch.catalogMatchHeader
                            || this.props.tabName ===  this.props.content.onboardingWSTabName
                            || this.props.tabName ===  this.props.content.enablementRecomTabName
                            || this.props.tableName === this.props.content.networkMatch.integrationMatchHeader) && !this.props.fromGanntChart ? '8' : '7'} 
                            style={{ textAlign: 'center', backgroundColor: this.getBackgroundColor(), color: '#ffffff' }} >
                                {this.props.tableName}</th>
                        </tr></thead>
                        <thead>
                            <tr>
                            {
                                this.props.tabName ===  this.props.content.onboardingWSTabName ? 
                                    <th width='25%' className="tableHeader bgcn" title={this.props.content.onboardingWorkstreamDetails.firstColumnNameDesc}>{this.props.content.onboardingWorkstreamDetails.firstColumnName}</th>
                                        :<th width='25%' className="tableHeader bgcn" title={this.state.columnDetails.helpText}>{this.state.columnDetails.firstColumnName}</th>                                
                            }
                            {
                                    this.props.tabName === this.props.content.onboardingWSTabName ?
                                        <th width='25%' className="tableHeader bgcn" title={this.props.content.onboardingWorkstreamDetails.waveDescriptionDesc}>{this.props.content.onboardingWorkstreamDetails.waveDescription}</th> :
                                        this.props.tabName === this.props.content.enablementRecomTabName ?
                                            <th width='25%' className="tableHeader bgcn">{this.props.content.enablementRecommSecColHeader}</th> :
                                            this.props.tabName === this.props.content.enablementRecomTabName ?
                                                <th width='25%' className="tableHeader bgcn">{this.props.content.enablementRecommSecColHeader}</th> :
                                                this.props.tabName === this.props.content.enablementTabName && !this.props.fromGanntChart ?
                                                    <th width='25%' className="tableHeader bgcn" title={this.state.columnDetails.helpText}>{this.props.content.waveDescription}</th>
                                                    : null}

                                <th className="tableHeader bgcn column-align-right" width='10%' title={this.props.content.supplierHelpText}>{this.props.content.supplierColumn}</th>
                                <th className="tableHeader bgcn column-align-right" width='10%' title={this.props.content.supplierPerHelpText}>{this.props.content.supplierPerColumn}</th>
                               
                               
                                { this.props.tableName === this.props.content.networkMatch.catalogMatchHeader || this.props.tableName === this.props.content.networkMatch.integrationMatchHeader ?
                                    <th className="tableHeader bgcn column-align-right" width='10%' title={this.props.content.uniqueANIDsHelpText}>{this.props.content.uniqueANIDsColumn}</th>
                                    : null
                                }
                                

                                {this.props.metricsType === "Invoice & PO" || this.props.fromGanntChart ?
                                    <th className="tableHeader bgcn column-align-right" width='10%' title={this.props.content.invoiceSpendHelpText}>{this.props.content.invoicePoSpendColumn}</th>
                                    : this.props.metricsType === "Invoice Only" ?
                                        <th className="tableHeader bgcn column-align-right" width='10%' title={this.props.content.invoiceSpendHelpText}>{this.props.content.spendColumn}</th>
                                        : this.props.metricsType === "PO Only" ? <th className="tableHeader bgcn column-align-right" width='10%' title={this.props.content.poSpendHelpText}>{this.props.content.poSpendColumn}</th>
                                            : <th className="tableHeader bgcn column-align-right" width='10%' title={this.props.content.poSpendHelpText}>{this.props.content.spendProxyColumn}</th>
                                }

                                {this.props.metricsType === "Invoice & PO" || this.props.fromGanntChart ?
                                    <th className="tableHeader bgcn column-align-right" width='10%' title={this.props.content.invoicePerHelpText}>{this.props.content.invoicePoSpendPerColumn}</th>
                                    : this.props.metricsType === "Invoice Only" ?
                                        <th className="tableHeader bgcn column-align-right" width='10%' title={this.props.content.invoicePerHelpText}>{this.props.content.spendPerColumn}</th>
                                        : this.props.metricsType === "PO Only" ? <th className="tableHeader bgcn column-align-right" width='10%' title={this.props.content.invoicePerHelpText}>{this.props.content.poSpendPerColumn}</th>
                                            : <th className="tableHeader bgcn column-align-right" width='10%' title={this.props.content.invoicePerHelpText}>{this.props.content.spendProxyPerColumn}</th>
                                }

                                {this.props.metricsType === "Invoice & PO" && (this.props.tabName ===  this.props.content.onboardingWSTabName || this.props.tabName === this.props.content.enablementRecomTabName || this.props.tabName === this.props.content.enablementTabName || this.props.tableName === this.props.content.networkMatch.integrationMatchHeader || this.props.tableName === this.props.content.networkMatch.catalogMatchHeader) ?
                                    <th className="tableHeader bgcn column-align-right" width='10%' title={this.props.content.documentHelpText}>{this.props.content.onboardingWorkstreamDetails.invoicePoDocumentColumn}</th>
                                        :this.props.metricsType === "Invoice Only" && (this.props.tabName ===  this.props.content.onboardingWSTabName || this.props.tabName === this.props.content.enablementRecomTabName || this.props.tabName === this.props.content.enablementTabName || this.props.tableName === this.props.content.networkMatch.integrationMatchHeader || this.props.tableName === this.props.content.networkMatch.catalogMatchHeader) ?
                                            <th className="tableHeader bgcn column-align-right" width='10%' title={this.props.content.documentHelpText}>{this.props.content.onboardingWorkstreamDetails.invoiceDocumentColumn}</th>
                                                : this.props.metricsType === "Invoice & PO" || this.props.fromGanntChart ?
                                                    <th className="tableHeader bgcn column-align-right" width='10%' title={this.props.content.documentHelpText}>{this.props.content.invoiceDocumentColumn}</th>
                                                        : this.props.metricsType === "PO Only" ?
                                                            <th className="tableHeader bgcn column-align-right" width='10%' title={this.props.content.documentHelpText}>{this.props.content.poDocumentColumn}</th>
                                                            : this.props.metricsType === "Spend & Document Proxies" && (this.props.tabName ===  this.props.content.onboardingWSTabName || this.props.tabName === this.props.content.enablementRecomTabName || this.props.tabName === this.props.content.enablementTabName || this.props.tableName === this.props.content.networkMatch.integrationMatchHeader || this.props.tableName === this.props.content.networkMatch.catalogMatchHeader) ?
                                                                <th className="tableHeader bgcn column-align-right" width='10%' title={this.props.content.documentHelpText}>{this.props.content.onboardingWorkstreamDetails.documentColumn}</th>                                    
                                                                    : <th className="tableHeader bgcn column-align-right" width='10%' title={this.props.content.documentHelpText}>{this.props.content.documentColumn}</th>
                                }

                                {this.props.metricsType === "Invoice & PO" && (this.props.tabName ===  this.props.content.onboardingWSTabName || this.props.tabName === this.props.content.enablementRecomTabName || this.props.tabName === this.props.content.enablementTabName || this.props.tableName === this.props.content.networkMatch.integrationMatchHeader || this.props.tableName === this.props.content.networkMatch.catalogMatchHeader) ?
                                    <th className="tableHeader bgcn column-align-right" width='10%' title={this.props.content.onboardingWorkstreamDetails.invoicePoDocumentPerColumnDesc}>{this.props.content.onboardingWorkstreamDetails.invoicePoDocumentPerColumn}</th>                                
                                        :this.props.metricsType === "Invoice Only" && (this.props.tabName ===  this.props.content.onboardingWSTabName || this.props.tabName === this.props.content.enablementRecomTabName || this.props.tabName === this.props.content.enablementTabName || this.props.tableName === this.props.content.networkMatch.integrationMatchHeader || this.props.tableName === this.props.content.networkMatch.catalogMatchHeader) ?
                                            <th className="tableHeader bgcn column-align-right" width='10%' title={this.props.content.onboardingWorkstreamDetails.invoiceDocumentPerColumnDesc}>{this.props.content.onboardingWorkstreamDetails.invoiceDocumentPerColumn}</th>                                
                                                :this.props.metricsType === "Spend & Document Proxies" && (this.props.tabName ===  this.props.content.onboardingWSTabName || this.props.tabName === this.props.content.enablementRecomTabName || this.props.tabName === this.props.content.enablementTabName || this.props.tableName === this.props.content.networkMatch.integrationMatchHeader || this.props.tableName === this.props.content.networkMatch.catalogMatchHeader) ?
                                                    <th className="tableHeader bgcn column-align-right" width='10%' title={this.props.content.onboardingWorkstreamDetails.documentPerColumnDesc}>{this.props.content.onboardingWorkstreamDetails.documentPerColumn}</th>                                
                                                        :this.props.metricsType === "Invoice Only" || this.props.fromGanntChart ?
                                                            <th className="tableHeader bgcn column-align-right" width='10%' title={this.props.content.documentPerHelpText}>{this.props.content.invoiceDocumentPerColumn}</th>
                                                            : this.props.metricsType === "PO Only" ?
                                                                <th className="tableHeader bgcn column-align-right" width='10%' title={this.props.content.onboardingWorkstreamDetails.poDocumentPerColumnDesc}>{this.props.content.poDocumentPerColumn}</th>
                                                                : <th className="tableHeader bgcn column-align-right" width='10%' title={this.props.content.documentPerHelpText}>{this.props.content.documentPerColumn}</th>
                                }

                            </tr>
                        </thead>


                        {/* <tbody className='medium-12'>{rows}</tbody> */}
                        {this.props.tabName === this.props.content.enablementTabName && !this.props.fromGanntChart ?
                            <EnablementWaveRowContainer content={this.props.content}
                                data={this.state.data}
                                showEnablementSupplierDetailsModal={this.props.showEnablementSupplierDetailsModal}
                                columnDetails={this.state.columnDetails}
                                tableName={this.props.tableName}
                                tabName={this.props.tabName}
                                anIDsDATA={this.props.anIDsData}
                                metricsType={this.props.metricsType}
                                handleWaveDescriptionChange={this.handleWaveDescriptionChange}
                                currencySymbol={this.state.currencySymbol}
                            >
                            </EnablementWaveRowContainer>
                            :
                            <ExpandableTableRowContainer content={this.props.content}
                                data={this.state.data}
                                showEnablementSupplierDetailsModal={this.props.showEnablementSupplierDetailsModal}
                                columnDetails={this.state.columnDetails}
                                tableName={this.props.tableName}
                                tabName={this.props.tabName}
                                anIDsDATA={this.props.anIDsData}
                                metricsType={this.props.metricsType}
                                fromGanntChart={this.props.fromGanntChart}
                                currencySymbol={this.state.currencySymbol}
                            >
                            </ExpandableTableRowContainer>
                        }
                        <tfoot>
                            <tr>
                                <td>{this.props.content.grandTotal}</td>
                                {
                                    (this.props.tabName === this.props.content.enablementTabName && !this.props.fromGanntChart)
                                        || this.props.tabName === this.props.content.onboardingWSTabName
                                        || this.props.tabName === this.props.content.enablementRecomTabName
                                        ? <td></td> : null
                                }
                                <td className="column-align-right">{this.props.grandTotal && this.props.grandTotal[0] !== undefined ? formatThousands(this.props.grandTotal[0].TOTAL_VENDOR_CNT) : null}</td>
                                <td className="column-align-right">{this.props.grandTotal && this.props.grandTotal[0] !== undefined ? this.props.grandTotal[0].VENDOR_PER + "%" : null}</td>

                                { this.props.tableName === this.props.content.networkMatch.catalogMatchHeader || this.props.tableName === this.props.content.networkMatch.integrationMatchHeader ?
                                   <td className="column-align-right" width='10%'>{this.props.grandTotal && this.props.grandTotal[0] !== undefined ? formatThousands(this.props.grandTotal[0].TOTAL_UNIQUE_VENDOR_AN_ID) : null}</td>
                                    : null
                                }
                                


                                <td className="column-align-right">{this.props.grandTotal && this.props.grandTotal[0] !== undefined ? this.state.currencySymbol + convertToMillion(this.props.grandTotal[0].TOTAL_AGGR_SPEND, true) : null}</td>
                                <td className="column-align-right">{this.props.grandTotal && this.props.grandTotal[0] !== undefined ? this.props.grandTotal[0].AGGR_SPEND_PER + "%" : null}</td>


                                <td className="column-align-right">{this.props.grandTotal && this.props.grandTotal[0] !== undefined ? formatThousands(this.props.grandTotal[0].TOTAL_DOC_PROXY) : null}</td>
                                <td className="column-align-right">{this.props.grandTotal && this.props.grandTotal[0] !== undefined ? this.props.grandTotal[0].DOC_PROXY_PER + "%" : null}</td>

                            </tr>
                        </tfoot>
                    </table>
                </div>

                <div className="medium-1 large-1">
                    <PopupE className="override-large-popup3"
                        position="right center"
                        open={this.state.openWaveDesc}
                        onClose={this.outsideWaveDescPopupClick}
                        closeOnDocumentClick
                    >
                        <div>
                            <WaveDescriptionPopup
                                close={this.closeWaveDescPopUp}
                                addWaveDescription={this.addWaveDescription}
                                clearWaveDescription={this.clearWaveDescription}
                                data={this.state.waveDescriptionData}
                            />
                        </div>
                    </PopupE>
                </div>

                <div className="medium-1 large-1">
                    <PopupE className="override-large-popup4"
                        position="right center"
                        open={this.state.openEnablementRecWarningPopup}
                        onClose={this.closeGanttChartEnablementStrategy.bind(this)}
                        closeOnDocumentClick
                    >
                        <div className="grid-x">
                            <div onClick = {this.closeGanttChartEnablementStrategy.bind(this)} style={{marginLeft : '99%'}}>X</div>
                                <div className="medium-12">
                                    <EnablementMappingKeysTable isPopup = {true}/>
                                </div>
                            </div>
                    </PopupE>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.default,
        spendDetails: state.spendDetails,
        content: state.content.enablementInsight,
        validation: state.content.validation,
        currencySymbols: state.content.CurrencySymbols
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        enablementInsightSuccess: message => dispatch(enablementInsightSuccess(message)),
        enablementInsightFailure: error => dispatch(enablementInsightFailure(error)),
        enablementInsightLoading: bool => dispatch(enablementInsightLoading(bool)),
        resetError: error => dispatch(resetError(error)),
        updateWaveDescription: (data) => dispatch(updateWaveDescription(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExpandableTable)