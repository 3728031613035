import { mappingSKUsPriorityAndSubLever } from 'Constants'

import {
    updateIncomeStatementAndBalanceSheet
} from 'BusinessValue/BusinessValueCalculations'
import {
    toggleValueDriver,
    toggleSubLever,
    toggleSKU
} from 'actions/valueDriverActions'
import { store } from 'index'
import {
    getSubLeverIdObject
} from 'utils/Utils'
import {
    LanguageTranslationLoading
} from "actions/userActions";



export const EnableSKUsFromSublevers = (BVUIdIndex, BVUSVDIdIndex) => {
    var subLeverId = (BVUIdIndex + 1) + '.' + (BVUSVDIdIndex + 1);
    var allSKUid = mappingSKUsPriorityAndSubLever.forEach(sku => {
        if(sku.autoOn === undefined){
            for (let index_subLever = 0; index_subLever < sku.mappedSubLever.length; index_subLever++) 
            {
                const element = sku.mappedSubLever[index_subLever];
                if (element.subLeverId.toString() === subLeverId) {
                    dispatchToggleSKU(sku.id, true, BVUIdIndex, BVUSVDIdIndex);
                    break;
                }
            }
        }
    });
    return allSKUid;
}

export const dispatchToggleSKU = (id, bool, BVUIdIndex, BVUSVDIdIndex) => {

    //debugger;
    // Dispatch to Redux Need to update 
    let state = store.getState(),
        userData = state.valueStory;
    var _priorities = state.content.priorities === undefined ? state.content : state.content.priorities;
    
    var _PrioritiesForSKU = [];
    _PrioritiesForSKU = mappingSKUsPriorityAndSubLever[id - 1].mappedPriorities;
   
    for (let index_Priority = 0; index_Priority < _PrioritiesForSKU.length; index_Priority++) {
        var priorityElement = _PrioritiesForSKU[index_Priority];
        var _prioritySections = _priorities.prioritySections[priorityElement.prioritiesId];
        var QuestionInJson = _prioritySections.questions
        for (let index_Que = 0; index_Que < QuestionInJson.length; index_Que++) {
            const subQuestionsJSON = QuestionInJson[index_Que].subQuestions;
            var needToBreak = false;
            for (let index_SubQue = 0; index_SubQue < subQuestionsJSON.length; index_SubQue++) {
                const element_subQue = subQuestionsJSON[index_SubQue];
                if (priorityElement.priorityIndex.toString() === element_subQue.id) {
                    var AOIValueDrivers = userData.AreaOfInterest[priorityElement.prioritiesId].AOIValueDrivers;
                    for (let index_AOI = 0; index_AOI < AOIValueDrivers.length; index_AOI++) {
                        const aoiValuDriver_userDate = AOIValueDrivers[index_AOI];
                        if (aoiValuDriver_userDate.SubItem === element_subQue.subQuestion) {

                            if ((aoiValuDriver_userDate.SKUId == null || aoiValuDriver_userDate.SKUId == '') && bool) {
                                aoiValuDriver_userDate.SKUId = id 
                            }
                            else if (bool) {
                                var existingSkuId = aoiValuDriver_userDate.SKUId;
                                if (existingSkuId.split(',').indexOf(id) < 0) {
                                    aoiValuDriver_userDate.SKUId = existingSkuId + ',' + id
                                }
                               
                            }

                            if (!bool) {
                                var existingSkuId = aoiValuDriver_userDate.SKUId;
                                if (existingSkuId !== null && existingSkuId.split(',').indexOf(id) > -1) {
                                    var sepratedSkuID = existingSkuId.split(',');
                                    var newSkuIDs = null;
                                    for (let index = 0; index < sepratedSkuID.length; index++) {
                                        const elementSKU = sepratedSkuID[index];
                                        if (elementSKU !== id) {
                                            if (newSkuIDs === null) {
                                                newSkuIDs = elementSKU;
                                            }
                                            else {
                                                newSkuIDs = newSkuIDs + ',' + elementSKU
                                            }
                                        }
                                    }
                                    aoiValuDriver_userDate.SKUId = newSkuIDs;
                                     
                                }

                            }
                            userData.AreaOfInterest[priorityElement.prioritiesId].AOIValueDrivers[index_AOI] = aoiValuDriver_userDate;
                        }
                    };
                     
                }
            }
            

        }
    };
 

    enableCorrospondingSublever(id, bool, BVUIdIndex, BVUSVDIdIndex);

    updateIncomeStatementAndBalanceSheet()

}

export const enableCorrospondingSublever = (id, bool, BVUIdIndex, BVUSVDIdIndex) => {
    if (BVUIdIndex === undefined && BVUSVDIdIndex === undefined) {

        if (!bool) {

            var _subLeversForSKU = mappingSKUsPriorityAndSubLever[id - 1].mappedSubLever;
            var ValueDriversId = [];
            //SKU to Sublever 


            var existingSelectedSKUs = getExistingSelectedSKU();
            var subLeverForExistingSKU = [];
            for (let index = 0; index < existingSelectedSKUs.length; index++) {
                const skuId = existingSelectedSKUs[index];
                if (subLeverForExistingSKU.indexOf(skuId - 1) < 0) {
                    var mappedSubLevers = mappingSKUsPriorityAndSubLever[skuId - 1].mappedSubLever.forEach(element_sub =>{
                        if(subLeverForExistingSKU.indexOf(element_sub.subLeverId) <0){
                        subLeverForExistingSKU.push(element_sub.subLeverId);
                        }
                    })
                   
                }
            }

            var innerIndex = 0;
            _subLeversForSKU.forEach(element => {
                if (element !== null) {
                    var ids = getSubLeverIdObject(element.subLeverId.toString());              
                     var isSubLeverExist = subLeverForExistingSKU.indexOf(element.subLeverId) < 0 ? store.dispatch(toggleSubLever(ids, bool)) : null;

                    if (isSubLeverExist != null && ValueDriversId.indexOf(ids.BVUIdIndex) < 0) {
                        ValueDriversId.push(ids.BVUIdIndex)
                    }
                }
                innerIndex++;
            });

        }
        else {
            var _subLeversForSKU = mappingSKUsPriorityAndSubLever[id - 1].mappedSubLever;
            var ValueDriversId = [];
            //SKU to Sublever 
            _subLeversForSKU.forEach(element => {
                if (element !== null) {
                    var ids = getSubLeverIdObject(element.subLeverId.toString());
                    store.dispatch(toggleSubLever(ids, bool));
                    if (ValueDriversId.indexOf(ids.BVUIdIndex) < 0) {
                        ValueDriversId.push(ids.BVUIdIndex)
                    }

                }
            });
        }


        let state = store.getState();
        let userData = state.valueStory;
        ValueDriversId.forEach(element => {
            if (bool) {
                store.dispatch(toggleValueDriver(element, bool));
            }
            else {
                var checkdValuDriver = false;
                var test = userData.BusinessValueToYou[0].ValueDriver[element].
                    SubLever.forEach(function loop(subelement) {
                        if (subelement.Selected) {
                            checkdValuDriver = subelement.Selected;
                            loop.stop = true;
                        }
                    });
                store.dispatch(toggleValueDriver(element, checkdValuDriver));
            }
        })
    }
    else {
        store.dispatch(toggleSubLever({ BVUIdIndex, BVUSVDIdIndex }, bool));
    }

}

export const getExistingSelectedSKU = () => {
    var existingSelectedSKUs = [];
    let _instance = this;
    let state = store.getState();
    let userData = state.valueStory;
    userData.AreaOfInterest.forEach(element => {
        element.AOIValueDrivers.forEach(element_ValueDriver => {

            if (element_ValueDriver.SKUId !== null && element_ValueDriver.SKUId !== undefined) {
                var splittedSKUList = element_ValueDriver.SKUId.toString().split(',');
                for (let index = 0; index < splittedSKUList.length; index++) {
                    const element = splittedSKUList[index];
                    if (element != null && existingSelectedSKUs.indexOf(element.toString()) === -1) {
                        existingSelectedSKUs.push(element.toString());
                    }
                }
            }
        });
    });
    return existingSelectedSKUs;
}