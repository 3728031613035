import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
	fetchScreenVideos,
	deleteScreenVideo,
	resetError,
} from 'actions/videoRoleAction';

import ScreenVideo from './ScreenVideo';
import ScreenVideoReorder from './ScreenVideoReorder';

import Button from 'UI/Button'
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import classNames from 'classnames';
import Modal from 'reboron/OutlineModal'
import { path } from 'Constants'
import moment from 'moment'

import { modalStyle, modalContentStyle, changeModalStyle, changeModalContentStyle } from 'Constants'

const videoEditorModalContentStyle = Object.assign({}, modalContentStyle);
videoEditorModalContentStyle.padding = "20px";
const videoEditorModalStyle = Object.assign({}, modalStyle);
videoEditorModalStyle.width = "90%";
videoEditorModalStyle.height = "700px";
videoEditorModalStyle.maxWidth = "1200px";


const videoReorderModalStyle = Object.assign({}, modalStyle);
videoReorderModalStyle.width = "800px";
videoReorderModalStyle.height = "600px";
videoReorderModalStyle.maxWidth = "800px";

class VideoManagerContainer extends Component {
	constructor(props) {
		super(props)
		this.state = {
			data: [],
			selectedVideos: [],
			videoData: {}
		}

		this.loadData = this.loadData.bind(this)
		this.fetchRolesAndFeatures = this.fetchRolesAndFeatures.bind(this)
		this.formParentRecords = this.formParentRecords.bind(this)
		this.newVideo = this.newVideo.bind(this)
		this.editVideo = this.editVideo.bind(this)
		this.deleteVideo = this.deleteVideo.bind(this)
		this.getResult = this.getResult.bind(this)
		this.showModal = this.showModal.bind(this)
		this.hideModal = this.hideModal.bind(this)
		this.getOrderedResult = this.getOrderedResult.bind(this)
	}

	componentDidMount() {
		this.props.resetError()
		this.loadData();
		this.keyCount = 1;
	}

	loadData() {
		this.fetchRolesAndFeatures(true);
	}

	fetchRolesAndFeatures(showLoading) {
		this.props.fetchScreenVideos()
			.then((response) => {
				//var t = (showLoading === true ? this.props.roleManagerSuccess(response.data.Message) : null)
				this.setState({ data: this.formParentRecords(response.data.Result) })
			}).catch((error) => {
				if (error.stack.includes('Network Error')) {
				//	this.props.roleManagerFailure()
				}
				else {
					//this.props.roleManagerFailure(error.response.data.Message)
				}
			})
	}

	formParentRecords(data) {
		data.forEach(datum => {
			datum.CurrentLoginUser = this.props.user.user.Id
			datum.role = datum.ScreenVideoRoles.reduce((total, curr) => {
				return total ? total + ',' + curr.RoleName : curr.RoleName
			}, '');

			datum.UpdatedDate = moment(datum.UpdatedDate).format('YYYY-MM-DD');
		})

		return data;
	}

	getResult(data, isNew) {
		const records = this.state.data;
		if(!isNew) {
			const idx = records.findIndex(item => {
				return item.VideoId === data.VideoId
			})

			if(idx !== -1) {
				records.splice(idx, 1);	
			}
		}

		records.splice(0, 0, this.formParentRecords([data])[0])

		this.setState({data: records, selectedVideos: []});
	}

	getOrderedResult(data) {
		const records = this.state.data;
		data.forEach(datum => {
			const idx = records.findIndex(item => {
				return item.VideoId === datum.VideoId
			})
	
			if(idx !== -1) {
				records[idx].Order = datum.Order;	
			}
		})

		this.setState({data: records});
	}

	getVideoObj() {
		return {
			VideoId: -1,
			Module: '',
			ScreenName: '',
			ScreenId: '',
			Name: '',
			Description: '',
			SourceType: true,
			VideoSource: '',
			Order: 0,
			CurrentLoginUser: this.props.user.user.Id,
			ScreenVideoRoles: []
		}
	}

	newVideo() {
		this.setState({videoData: this.getVideoObj()}, this.refs.screenVideoEditor.show())
	}

	reorderVideo() {
		this.refs.screenVideoReorder.show()
	}

	editVideo() {
		const videoData = this.state.selectedVideos[0]
		this.setState({videoData: videoData}, this.refs.screenVideoEditor.show())
	}

	deleteVideo() {
		this.setState({ isLoading: true, isError: 'No', responseMsg: '' })
		this.props.deleteScreenVideo(this.state.selectedVideos)
			.then((response) => {
				//var t = (showLoading === true ? this.props.roleManagerSuccess(response.data.Message) : null)
				if(response.status === 200) {
					const records = this.state.data;
				
					this.state.selectedVideos.forEach(data => {
						const idx = records.findIndex(item => {
							return item.VideoId === data.VideoId
						})
	
						if(idx !== -1) {
							records.splice(idx, 1);	
						}
					})
					this.showModal("deleteVideo")
					this.setState({data: records, selectedVideos: [], responseMsg: '', isError: 'No', isLoading: false});
				}
			}).catch((error) => {
				if (error.stack.includes('Network Error')) {
                    this.setState({ responseMsg: 'API service is down. Please contact administrator.', isError: 'Yes', isLoading: false })
                }
                else {
                    this.setState({ responseMsg: this.props.content.error, isError: 'Yes', isLoading: false })
                }
			})
	}

	showModal(action) {
        this.refs[action].show()
    }

    hideModal(action) {
		this.refs[action].hide()
    }

    render() {
		return (
			<div className="grid-x">
				<div className="medium-12 rowMargin">
					<div className="grid-x">
						<div className="medium-12">
								{(this.state.isLoading) ?
									<img style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={path + `images/ajax-loader.gif`} alt="" />
									: null
								}
								{(this.state.isError === 'Yes') ?
									<div style={{ color: 'red', textAlign: 'center' }} className="cell">{this.state.responseMsg}</div>
									: <div style={{ color: 'green', textAlign: 'center' }} className="cell">{this.state.responseMsg}</div>
								}
						</div>
					</div>
					<div className='grid-x'>
						<div className="medium-3 cell buttonPostion">
							<Button
								content={this.props.content.new}
								color
								onClick={() => this.newVideo()}
							/>
						</div>
						<div className="medium-3 cell buttonPostion">
							<Button
								content={this.props.content.reorder}
								color
								onClick={() => this.reorderVideo()}
							/>
						</div>
						{this.state.selectedVideos.length === 1 ? <div className="medium-3 cell buttonPostion">
							<Button
								content={this.props.content.edit}
								color
								onClick={() => this.editVideo()}
							/>
						</div> : ''}
						{this.state.selectedVideos.length === 0 ? '' : <div className="medium-3 cell buttonPostion">
							<Button
								content={this.props.content.delete}
								color
								onClick={() => this.deleteVideo()}
							/>
						</div>}
					</div>
				</div>
				<div className="medium-12">
					<DataTable style={{wordWrap: 'break-word'}} ref={(el) => this.dt = el} value={this.state.data}
						responsive className="p-datatable-videos" dataKey="VideoId" rowHover
						selection={this.state.selectedVideos} onSelectionChange={e => this.setState({selectedVideos: e.value})}
						paginator rows={50} emptyMessage={this.props.content.noData}
						paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" rowsPerPageOptions={[10,25,50]}>
						<Column selectionMode="multiple" style={{width:'3em'}}/>
						<Column field="Module" header={this.props.content.module} sortable filter filterPlaceholder={[this.props.content.searchBy, this.props.content.module].join(' ')} />
						<Column field="ScreenName" header={this.props.content.screen} sortable filter filterPlaceholder={[this.props.content.searchBy, this.props.content.screen].join(' ')} />
						<Column field="Name" header={this.props.content.name} sortable filter filterPlaceholder={[this.props.content.searchBy, this.props.content.name].join(' ')} />
						<Column field="Description" header={this.props.content.description} sortable filter filterPlaceholder={[this.props.content.searchBy, this.props.content.description].join(' ')} />
						<Column field="VideoSource" header="Video" sortable filter filterPlaceholder={[this.props.content.searchBy, this.props.content.video].join(' ')} />
						<Column field="role" header={this.props.content.role} sortable filter filterPlaceholder={[this.props.content.searchBy, this.props.content.role].join(' ')} />
						<Column field="Order" header={this.props.content.order} sortable filter filterPlaceholder={[this.props.content.searchBy, this.props.content.order].join(' ')} />
						<Column field="UpdatedDate" header={this.props.content.updatedBy} sortable filter filterPlaceholder={[this.props.content.searchBy, this.props.content.updatedBy].join(' ')} />
					</DataTable>
				</div>
				<div className="medium-12">
					<Modal
						ref={'screenVideoEditor'}
						modalStyle={videoEditorModalStyle}
						contentStyle={videoEditorModalContentStyle}
						//rectStyle={changeModalBorderRectStyle}
						keyboard={false}
						//backdrop={false}
						onHide={(e) => {
							if (e === 'backdrop') {
								//this.props.outsidePopupClick()
							}
						}}
					>
						<div className="medium-12">
							<button onClick={() => this.refs.screenVideoEditor.hide()} className="close" />
						</div>
						<ScreenVideo videoData={this.state.videoData} getResult={this.getResult} close={() => {this.refs.screenVideoEditor.hide()}}/>
					</Modal>
					<Modal
                        ref="deleteVideo"
                        modalStyle={changeModalStyle}
                        contentStyle={changeModalContentStyle}
                    >
                        <div className="grid-x grid-padding-x change-popup">
                            <div className="medium-12">
                                <button onClick={() => this.hideModal("deleteVideo")} className="close" />
                            </div>
                            <div className="medium-12 pt30">
								{this.props.content.deletionSuccess}
                            </div>
                            <div className="medium-12 popupOkBtn">
                                <div className="float-right">
                                    <div className="float-left"><Button
                                        content={"Close"}
                                        onClick={() => this.hideModal("deleteVideo")}
                                        color /></div>
                                </div>
                            </div>
                        </div>
                    </Modal>
					<Modal
						ref={'screenVideoReorder'}
						modalStyle={videoReorderModalStyle}
						contentStyle={videoEditorModalContentStyle}
						//rectStyle={changeModalBorderRectStyle}
						keyboard={false}
						//backdrop={false}
						onHide={(e) => {
							if (e === 'backdrop') {
								//this.props.outsidePopupClick()
							}
						}}
					>
						<div className="medium-12">
							<button onClick={() => this.refs.screenVideoReorder.hide()} className="close" />
						</div>
						<ScreenVideoReorder getOrderedResult={this.getOrderedResult} videoData={this.state.data} close={() => {this.refs.screenVideoReorder.hide()}}/>
					</Modal>
				</div>
			</div>
		)
    }
}



const mapStateToProps = (state) => {
	return {
		language: state.language,
		ui: state.content.ui,
		user: state.default,
		content: state.content.videoManagerContainer,
		validation: state.content.validation
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		resetError: () => dispatch(resetError()),
		fetchScreenVideos: () => dispatch(fetchScreenVideos()),
		deleteScreenVideo: (values) => dispatch(deleteScreenVideo(values)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoManagerContainer)