import React from "react";
import ReactDOM from "react-dom";
import Children from 'react-children-utilities';
import { Control } from 'react-redux-form'
import { Checkbox } from 'material-ui';
import Popup from "reactjs-popup";
import CustomGrid from 'UI/CustomGrid'
import Button from 'UI/Button'
import PopupButton from 'UI/PopupButton'
import Select from 'react-select'
import { RadioButton } from 'material-ui';

export default class Screen extends React.Component {
    constructor(props) {
      super(props);
      this.state = { readOnly: false, children: this.props.children };
      this.recursiveMap = this.recursiveMap.bind(this)
    }

    componentWillReceiveProps(nextProps) {
      if (nextProps.lastUpdated !== this.props.lastUpdated || this.state.readOnly !== nextProps.readOnly)
      {
          if(nextProps.readOnly) {
              const children = this.mapChild(nextProps.children);
              this.setState({children: children, readOnly: nextProps.readOnly})
          } else {
              this.setState({children: nextProps.children, readOnly: false})
          }
      }
    }

    mapChild(children) {
      var newChildren = Children.deepMap(children, (child) => {
          if (!React.isValidElement(child)) {
              return child;
          }

          let props = this.deleteDisabledProps(child.props);

          if(child.type === Control.text || child.type === CustomGrid) {
              props.readOnly = this.state.readOnly
          } else if(child.type === Checkbox || child.type === Popup || child.type === RadioButton) {
              props.disabled = this.state.readOnly
          } else if(child.type === Select) {
              props.isDisabled = this.state.readOnly
          } else if(child.type === Button || child.type === PopupButton) {
              props.disabled = this.state.readOnly
              props.showLoader = false
          }
          else if(child.type === 'input'){
              props.disabled = this.state.readOnly
          }

          return React.cloneElement(child, props);
      });

      return newChildren;
    }

    deleteDisabledProps = (props) => {
      const newProps = {...props}
      delete newProps.disabled;
  
      return newProps
    }

    render() {
      return (
        <React.Fragment>
            {this.state.children}
        </React.Fragment>
      );
    }
  }