import React from 'react'
import { connect } from 'react-redux'
import { Control, Form, Errors, actions } from 'react-redux-form'
// UI
import Textfield from 'react-mdl/lib/Textfield'
import Button from 'UI/Button'
import moment from 'moment'
// Settings
import { validators } from 'Constants'

// Actions
import {
  fetchFiles,
  addFile,
  deleteFile,
  updateFile,
  userDetailsSuccess,
  userDetailsFailure,
  userDetailsLoading,
  resetError,
}
  from 'actions/userActions'

class FileManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userIsEditing: false,
			editId: 0,
      files: [], mymessage:''
    }

    this.loadData = this.loadData.bind(this)

   }

  componentWillMount(){
    this.loadData()
  }
  loadData() {
    this.props.fetchFiles()
      .then((response) => {
        var reportData = [];
        this.props.userDetailsSuccess(response.data.Message)
        for (var i = 0; i < response.data.Result.length; i++) {
          var obj = response.data.Result[i];
          if(obj.Name==="Default")
          {
            continue;            
          }
          else{
            reportData.push(obj);
          }
        }
        this.setState({ files: reportData })
      }).catch((error) => {
        if (error.stack.includes('Network Error')) {
          this.props.userDetailsFailure()
        }
      })
  }

  handleRowDel(reportDetails) {
		const result = window.confirm(this.props.validation.confirmDelete);
		if (result) {
      var formValues = { FileName: reportDetails.name, ID: reportDetails.id };
      var msg = this.props.content.rowDel[0];
      this.setState({mymessage: ""})
			this.props.deleteFile(formValues)
				.then((response) => {
          this.props.userDetailsSuccess(response.data.Message)
          if (response.status === 200) {
            var httpstatus = response.status
            var getMsgfromStatus = msg[httpstatus]
            this.loadData()
            this.setState({mymessage: getMsgfromStatus})            
          }
          // this.setState({  mymessage: response.data.Message })
          // this.formDispatch(actions.reset('activateUser'))
          // setTimeout(() => {          
          //   this.loadData()
          //   this.setState({mymessage: ""})
          // }, messageDuration)
				}).catch((error) => {
					if (error.stack.includes('Network Error')) {
            this.setState({mymessage: ""})
						this.props.userDetailsFailure(this.props.validation.networkError)
					}
					else {
            var httpstatus = error.response.status
            var getMsgfromStatus = msg[httpstatus]
            if (getMsgfromStatus !== undefined) {
              this.setState({mymessage: ""})
              this.props.userDetailsFailure(getMsgfromStatus)
            }
            else {
              this.setState({mymessage: ""})
              this.props.userDetailsFailure(error.response.data.Message)
            }
					}
				})
		}
  };
  
  handleReportDetailTable(evt) {
    var item = {
      name: evt.target.name,
      id: evt.target.id,
      value: evt.target.value
    };

    var old_reportDetails = this.state.old_reportDetails.slice();
    if (old_reportDetails.length === 0) {
      old_reportDetails = this.state.files.slice();
      this.setState({ old_reportDetails: old_reportDetails });
    }
    let testOfObjects = old_reportDetails;
    var newreportDetails = testOfObjects.map(function (testReportDetails) {
      for (var key in testReportDetails) {
        if (testReportDetails.id === item.id) {
          testReportDetails[key] = item.name;
          testReportDetails["isEditing"] = false;
          break;
        }
      }
      return testReportDetails;
    });

    this.setState({ files: newreportDetails });

  };

  handleEditEvent(evt) {
    var item = {
      name: evt.name,
      id: evt.id,
      isEditing: evt.isEditing
    };
    var old_reportDetails = this.state.files.slice();
    var newreportDetails = old_reportDetails.map(function (testReportDetails) {
      for (var key in testReportDetails) {
        if (testReportDetails.Id == item.id) {
          testReportDetails.Name = item.name;
          testReportDetails.isEditing = !item.isEditing;

          if (evt.UpdatedBy !== undefined) {
            testReportDetails.UpdatedBy = evt.UpdatedBy;
            testReportDetails.UpdatedOn = evt.UpdatedOn;
          }
          break;
        }
      }
      return testReportDetails;
    });

    this.setState({ files: newreportDetails});

  }

   handleCancelEvent(originalDetails) {
    var item = {
      id: originalDetails.id,
      name: originalDetails.name
    };

    var old_reportDetails = this.state.files.slice();
    var newreportDetails = old_reportDetails.map(function (testReportDetails) {
      for (var key in testReportDetails) {
        if (testReportDetails.Id === item.id) {
          testReportDetails[key] = item.name;
          testReportDetails["isEditing"] = false;
          break;
        }
      }
      return testReportDetails;
    });
    this.setState({ files: newreportDetails});

  };

  handleUpdateEvent(updateDetails) {
    if (updateDetails.length > 0 && this.isDuplicate(updateDetails[0].name)) {
      var item = {
        id: updateDetails[0].id,
        name: updateDetails[0].name.trim(),
        isEditing: true,
        UpdatedBy: localStorage.getItem('loggedinUser'),
        UpdatedOn: Date.now
      };
      this.setState({mymessage: ""})
      this.props.updateFile(item)
        .then((results) => {
          this.props.userDetailsSuccess(results.data.Message)
          this.setState({mymessage: results.data.Message})
          if (results.status === 200) {
            item.UpdatedBy = results.data.Result.UpdatedBy;
            item.UpdatedOn = results.data.Result.UpdatedOn;
            this.handleEditEvent(item)
          }
          this.setState({userIsEditing: false})

        }).catch((error) => {
          if (error.stack.includes('Network Error')) {
            this.setState({mymessage: ""})
            this.props.userDetailsFailure('API service is down. Please contact administrator.')
          }
          else {
            this.setState({mymessage: ""})
            this.props.userDetailsFailure(error.response.data.Message)
          }
        })
    }
  }

  addFile(formValue) {
    this.props.resetError();
    var fileDetail = {
      name: formValue.NewReport.trim(),
      User: localStorage.getItem('loggedinUser')
    }
    var msg = this.props.content;
    var result = fileDetail.name.slice((fileDetail.name.lastIndexOf(".") - 1 >>> 0) + 2);
    var flag = this.state.files.find(file => file.Name.toLowerCase() == fileDetail.name.toLowerCase()) === undefined
    if(result.toLowerCase() === "pptx"){
      if(!flag){
        this.props.userDetailsFailure('This File already exists in the folder!')
      }
      else{
        this.setState({mymessage: ""})
        this.props.addFile(fileDetail).then((results) => {
          if (results.status === 200) {
            var httpstatus = results.status
            var getMsgfromStatus = msg[httpstatus]
            // Reset form
            this.formDispatch(actions.reset('activateUser'))
            this.loadData()
            this.setState({mymessage: getMsgfromStatus})  
          }
        }).catch((error) => {
          if (error.stack.includes('Network Error')) {
            this.setState({mymessage: ""})
            this.props.userDetailsFailure('API service is down. Please contact administrator.')
          }
          else{
            this.setState({mymessage: ""})
            this.props.userDetailsFailure(error.response.data.Message)
          }
        })
      }
     
    }
    else{
      this.setState({mymessage: ""})
      this.props.userDetailsFailure("You can only add pptx files!!")
    }
    
  }

  isDuplicate(vals) {
    var result = this.state.files.find(report => report.Name === vals.trim()) === undefined
    if (result) {
      return result;
    }
    else {
      return result;
    }
  }

  componentWillUnmount() {
    this.formDispatch(actions.reset('activateUser'))
    this.props.resetError();
  }

  render() {
    return (
      <div className="popup-content">
        <h2 className="medium-12 title">{this.props.content.title}</h2>
        <div>
          <Form model="activateUser" method="post" 
            getDispatch={(dispatch) => this.formDispatch = dispatch}
            onSubmit={(formValue) => this.addFile(formValue)}>

            <div className="grid-x grid-padding-x" >
              <div className="medium-4 cell" >
                <Control.text
							  model="activateUser.NewReport"
                component={Textfield}
                validateOn="blur"
						  	label={this.props.content.newReport}
                floatingLabel
                validators={{
                  required: validators.required,
                  duplicate: (vals) => this.isDuplicate(vals)
                }}
						/>
            <Errors
							className="errors"
							model="activateUser.NewReport"
							show="touched"
							messages={{
								required: this.props.validation.required,
								duplicate: this.props.validation.duplicateFile
							}}
						/>
            </div>

              <div className="medium-2 cell pt13" >
                <Button 
                  type="submit" 
                  disabled={this.props.user.loading}
                  content={this.props.content.cta}
                  color 
                />
              </div>
            </div>

          </Form>
          <div className="errors">{this.props.user.error}</div>
          <div className="success">{this.state.mymessage}</div>
        </div>
        <div className="grid-x" className="medium-11 medium-offset-0">
          <UserTable
            onReportUpdate={this.handleReportDetailTable.bind(this)}
            onRowDel={this.handleRowDel.bind(this)}
            onRowEdit={this.handleEditEvent.bind(this)}
            onRowCancel={this.handleCancelEvent.bind(this)}
            onRowUpdate={this.handleUpdateEvent.bind(this)}
						userEditing={this.state.userIsEditing}
            editId={this.state.editId}
            validation={this.props.validation}
            files={this.state.files}
            isDuplicate={this.isDuplicate.bind(this)}
          />
        </div>
      </div>
    );
  }
}

class UserTable extends React.Component {
  render() {
    var onReportUpdate = this.props.onReportUpdate;
    var rowDel = this.props.onRowDel;
    var rowEdit = this.props.onRowEdit;
    var rowCancel = this.props.onRowCancel;
    var rowUpdate = this.props.onRowUpdate;
    var userEditing = this.props.userEditing;
    var isDuplicate = this.props.isDuplicate;
    var editId = this.props.editId;
    var validation = this.props.validation;
    var report = this.props.files.map(function (report) {
      return (<UserRow
        onDelEvent={rowDel.bind(this)}
        onEditEvent={rowEdit.bind(this)}
        onCancelEvent={rowCancel.bind(this)}
        onUpdateEvent={rowUpdate.bind(this)}
        userIsEditing={userEditing}
        isDuplicate={isDuplicate}
        editId={editId}
        validation={validation}
        report={report}
        onReportUpdate={onReportUpdate}
        key={report.Id} />)
    });
    return (
      <div className="basic-table">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Added By/On</th>
              <th>Updated By/On</th>
              <th></th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {report}{onReportUpdate}
          </tbody>
        </table>
      </div>
    );
  }
}



class UserRow extends React.Component {
  constructor(props) {
    super(props);
  }

  // onDelEvent() {
	// 	this.props.onDelEvent(this.props.report);
  // }

  // onEditEvent() {
  //   this.props.onEditEvent(this.props.report);
  // }

  onReportUpdate() {
    this.props.onReportUpdate()
  }

  render() {
    return (
      this.props.report.isEditing ?
        <EditableCell onDomainTableUpdate={this.props.onReportUpdate}
          report={this.props.report}
          cellData={{
            name: this.props.report.Name,
            id: this.props.report.Id,
            isEditing: this.props.report.isEditing
            
          }}
          onCancelEvent={this.props.onCancelEvent}
          onUpdateEvent={this.props.onUpdateEvent}
          isEditing = {this.props.report.isEditing}
          validation={this.props.validation}
          isDuplicate={this.props.isDuplicate}
        />
        :
        <NonEditableRow
          report={this.props.report}
          cellData={{
            name: this.props.report.Name,
            id: this.props.report.Id,
            isEditing: this.props.report.isEditing
          }}
          onDelEvent={this.props.onDelEvent}
          onEditEvent={this.props.onEditEvent}
          isEditing = {this.props.report.isEditing}
        />
    );
  }
}

class EditableCell extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reportDetails: this.props.cellData,
      originalreportDetails: this.props.cellData
    };
  }

  onDomainTableUpdate() {
    this.props.onReportUpdate();
  }

  onCancelEvent() {
    this.setState({isEditing: false});
    this.props.onCancelEvent(this.state.originalreportDetails);
  }

  onUpdateEvent() {
    this.props.onUpdateEvent(this.state.reportDetails);
  }

  onChangeEvent(event) {
    var changeItem = [
      {
        id: event.target.id,
        name: event.target.value,
        isEditing: true
      }]
      
    this.setState({ reportDetails: changeItem });
  }

  isDuplicate(vals) {
    if(vals === undefined){
      return this.props.isDuplicate("");
    }
    else{
      return this.props.isDuplicate(vals);
    }
    
  }
  
  render() {
    return (
      <tr>
        <td>
          <Control.text
            model="editDomain.NewReport"
            component={Textfield}
            defaultValue={this.props.cellData.name}
            id={this.props.cellData.id}
            value={this.state.reportDetails.name}
            onChange={this.onChangeEvent.bind(this)}
            isEditing={this.props.cellData.isEditing}
            validateOn="change"
            validators={{
              required: validators.required,
              duplicate: (vals) => this.isDuplicate(vals)
            }}
            type='name' />
          <Errors
            className="errors"
            model="editDomain.NewReport"
            show={(field) => field.focus}
            messages={{
              required: this.props.validation.required,
              duplicate: this.props.validation.duplicateFile
            }} />
        </td>
        <td>
          <span>{this.props.report.AddedBy}</span><br />
          <span>{moment(this.props.report.AddedOn).format('L')}</span>
        </td>
        <td>
          <span> {this.props.report.UpdatedBy}</span> <br />
          <span>{moment(this.props.report.UpdatedOn).format('L')} </span>
        </td>
        <td>
          <button onClick={this.onUpdateEvent.bind(this)}>Update</button>
        </td>
        <td >
          <button onClick={this.onCancelEvent.bind(this)}>Cancel</button>
        </td>
      </tr>
    );
  }
}

class NonEditableRow extends React.Component {

  onDelEvent() {
    this.props.onDelEvent(this.props.cellData);
  }

  onEditEvent() {
    this.props.onEditEvent(this.props.cellData);
  }

  render() {
    return (
      <tr>
        <td>
          <span id={this.props.cellData.id}
            name={this.props.cellData.name}
          >{this.props.cellData.name}</span>
        </td>
        <td>
          <span>{this.props.report.AddedBy}</span><br />
          <span>{moment(this.props.report.AddedOn).format('L')}</span>
        </td>
        <td>
          <span> {this.props.report.UpdatedBy}</span> <br />
          <span>{moment(this.props.report.UpdatedOn).format('L')} </span>
        </td>
        <td>
          <button onClick={this.onEditEvent.bind(this)}>Edit</button>
        </td>
        <td>
          <button onClick={this.onDelEvent.bind(this)}>Delete</button>
        </td>
      </tr>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
    ui: state.content.ui,
    user: state.default,
    content: state.content.files,
    validation: state.content.validation,
    reports: state.reports
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchFiles: (formValues) => dispatch(fetchFiles(formValues)),
    addFile: (formValues) => dispatch(addFile(formValues)),
    deleteFile: (formValues) => dispatch(deleteFile(formValues)),
    updateFile: (formValues) => dispatch(updateFile(formValues)),
    userDetailsLoading: (bool) => dispatch(userDetailsLoading(bool)),
    userDetailsSuccess: (bool) => dispatch(userDetailsSuccess(bool)),
    userDetailsFailure: (error) => dispatch(userDetailsFailure(error)),
    resetError: () => dispatch(resetError())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FileManager)
