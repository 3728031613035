//import update from 'immutability-helper'

export const priorities = (state = [], action) => {
    switch (action.type) {
        case 'INIT_PRIORITIES_ITEMS':
            return action.items

        case 'ADD_PRIORITIES_ITEM':
            return [...state, action.item].sort()


        case 'DELETE_PRIORITIES_ITEM':
            let arr = state.filter((i) =>
                i !== action.item
            ).sort()

            return arr

        default:
            return state
    }
}

export const spendAsPercentageOfRevenue = (state = 0.4411, action) => {
    switch (action.type) {
        case 'CHANGE_INDUSTRY':
            return action.spendAsPercentageOfRevenue

        case 'CHANGE_LOCATION':
            return action.spendAsPercentageOfRevenue
        default:
            return state
    }
}

export const regions = (state = [], action) => {
	switch (action.type) {
		case 'REGIONS_SUCCESS':
			return action.regions
		default:
			return state
	}
}

export const capIQData = (state = [], action) => {
	switch (action.type) {
		case 'CAPIQ_FETCH_DATA':
			return action.capIQData
		default:
			return state
	}
}
