import React from 'react'
import { connect } from 'react-redux'
import { Control, Form, Errors, actions } from 'react-redux-form'
// UI
import Textfield from 'react-mdl/lib/Textfield'
import Button from 'UI/Button'
import moment from 'moment'
// Settings
import { validators } from 'Constants'
import { SelectField, Option } from 'react-mdl-extra'
import Select from 'react-select'

import {
  fetchFiles,
  fetchSections,
  addSection,
  deleteSection,
  updateSection,
  userDetailsSuccess,
  userDetailsFailure,
  userDetailsLoading,
  resetError
}
  from 'actions/userActions'

import { dropdownStyles } from 'react-select-css'  

var options = [];

const mySelect = props => <Select
  styles={dropdownStyles}
  {...props}
/>

class SectionManager extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      fileFilter: [],
      sections: [],
      sections_copy: [],
      old_sectionDetails: [],
      filterId: 0,
      lanId: '',
      mymessage: "",
      flag: false,// to set change section
      flag2: false,// to be set for change language
      selectedOption: '',
      isSectionSelectFileSelected: ''
    }
  }

  componentWillMount() {
    this.loadFiles();
    this.loadSections();
  }

  loadFiles() {
    this.props.fetchFiles()
      .then((response) => {
        var fileData = [];
        this.props.userDetailsSuccess(response.data.Message)
        for (var i = 0; i < response.data.Result.length; i++) {
          var obj = response.data.Result[i];
          var data = {};
          data.label = obj.Name;
          data.value = obj.Id;
          fileData.push(data);
        }
        this.setState({ fileFilter: fileData })


      }).catch((error) => {
        if (error.stack.includes('Network Error')) {
          this.props.userDetailsFailure()
        }
      })

  }

  loadSections() {
    this.props.fetchSections()
      .then((response) => {
        var sectionData = [];
        this.props.userDetailsSuccess(response.data.Message)
        for (var i = 0; i < response.data.Result.length; i++) {
          var obj = response.data.Result[i];
          sectionData.push(obj);
        }
        this.setState({ sections: sectionData, sections_copy: sectionData })
      }).catch((error) => {
        if (error.stack.includes('Network Error')) {
          this.props.userDetailsFailure()
        }
      })

  }

  changeSection() {
    this.props.fetchSections()
      .then((response) => {
        var sectionData = [];
        this.props.userDetailsSuccess(response.data.Message)
        for (var i = 0; i < response.data.Result.length; i++) {
          var obj = response.data.Result[i];
          if (obj.ParentId == this.state.filterId) {
            sectionData.push(obj);
          }

        }
        this.setState({ sections: sectionData, flag: true })
        this.changeBoth();
      }).catch((error) => {
        if (error.stack.includes('Network Error')) {
          this.props.userDetailsFailure()
        }
      })
  }

  onDDLSectionSelectFileClickEvent = () => {
    this.setState({ isSectionSelectFileSelected: 'Selected' });
  }

  onDDLSectionSelectFileBlurEvent = () => {
    this.setState({ isSectionSelectFileSelected: '' });
  }

  handleChange(value) {
    var filter = value;
    this.state.fileFilter.map((slide) => {
      if (filter == slide.label) {
        this.setState({ filterId: slide.value })
      }
    })
    this.setState({ selectedOption: value });
    this.props.resetError();
    this.changeSection();
  }

  addSection(formValue) {
    this.props.resetError();
    var sectionDetail = {
      name: formValue.SectionName.trim(),
      User: localStorage.getItem('loggedinUser'),
      FileID: this.state.filterId,
      Language: this.state.lanId
    }
    var msg = this.props.content;
    if (sectionDetail.FileID > 0) {
      var result = this.state.sections.find(report => report.SectionName.toLowerCase() == sectionDetail.name.toLowerCase()) === undefined
      if (!result) {
        this.setState({ mymessage: "" })
        this.props.userDetailsFailure('This Section already exists in the file')
      }
      else {
        this.setState({ mymessage: "" })
        this.props.addSection(sectionDetail).then((results) => {
          if (results.status === 200) {
            var httpstatus = results.status
            var getMsgfromStatus = msg[httpstatus]
            // Reset form
            this.formDispatch(actions.reset('sectionManager'))
            this.loadSections()
            this.setState({ mymessage: getMsgfromStatus, selectedOption: "" })

          }
        }).catch((error) => {
          if (error.stack.includes('Network Error')) {
            this.setState({ mymessage: "" })
            this.props.userDetailsFailure('API service is down. Please contact administrator.')
          }
          else {
            this.setState({ mymessage: "" })
            this.props.userDetailsFailure(error.response.data.Message)
          }
        })
      }

    }
    else {
      this.setState({ mymessage: "" })
      this.props.userDetailsFailure("Please select the file from dropdown in which you want to add section")
    }

  }

  handleRowDel(sectionDetails) {
    const result = window.confirm(this.props.validation.confirmDelete);
    if (result) {
      var formValues = { SectionName: sectionDetails.sectionName, FileID: sectionDetails.parentId };
      var msg = this.props.content.rowDel[0];
      this.setState({ mymessage: "" })
      this.props.deleteSection(formValues)
        .then((response) => {
          this.props.userDetailsSuccess(response.data.Message)
          if (response.status === 200) {
            var httpstatus = response.status
            var getMsgfromStatus = msg[httpstatus]
            this.loadSections()
            this.setState({ mymessage: getMsgfromStatus })
          }
        }).catch((error) => {
          if (error.stack.includes('Network Error')) {
            this.setState({ mymessage: "" })
            this.props.userDetailsFailure(this.props.validation.networkError)
          }
          else {
            var httpstatus = error.response.status
            var getMsgfromStatus = msg[httpstatus]
            if (getMsgfromStatus !== undefined) {
              this.setState({ mymessage: "" })
              this.props.userDetailsFailure(getMsgfromStatus)
            }
            else {
              this.setState({ mymessage: "" })
              this.props.userDetailsFailure(error.response.data.Message)
            }
          }
        })
    }
  };

  handleEditEvent(evt) {
    var item = {
      name: evt.sectionName,
      id: evt.id,
      isEditing: evt.isEditing
    };
    var old_sectionDetails = this.state.sections.slice();
    // if(this.state.filterId > 0){
    var newsectionDetails = old_sectionDetails.map(function (testsectionDetails) {
      for (var key in testsectionDetails) {
        if (testsectionDetails.Id == item.id) {
          testsectionDetails.SectionName = item.name;
          testsectionDetails.isEditing = !item.isEditing;

          if (evt.UpdatedBy !== undefined) {
            testsectionDetails.UpdatedBy = evt.UpdatedBy;
            testsectionDetails.UpdatedOn = evt.UpdatedOn;
          }
          break;
        }
      }
      return testsectionDetails;
    });

    this.setState({ sections: newsectionDetails });
    // }
    // else{
    //   this.props.userDetailsFailure("Please Select the Respective File from dropdown first!!")
    // }

  }

  handleCancelEvent(originalDetails) {
    var item = {
      id: originalDetails[0].id,
      sectionName: originalDetails[0].sectionName
    };

    var old_sectionDetails = this.state.sections.slice();
    var newsectionDetails = old_sectionDetails.map(function (testsectionDetails) {
      for (var key in testsectionDetails) {
        if (testsectionDetails.Id === item.id) {
          // testsectionDetails.SectionName = item.sectionName;
          // testsectionDetails.Description = item.description;
          testsectionDetails["isEditing"] = false;
          break;
        }
      }
      return testsectionDetails;
    });
    this.setState({ sections: newsectionDetails });

  };

  handleSectionsDetailTable(evt) {
    var item = {
      name: evt.target.name,
      id: evt.target.id,
      value: evt.target.value
    };

    var old_sectionDetails = this.state.old_sectionDetails.slice();
    if (old_sectionDetails.length === 0) {
      old_sectionDetails = this.state.sections.slice();
      this.setState({ old_sectionDetails: old_sectionDetails });
    }
    let testOfObjects = old_sectionDetails;
    var newsectionDetails = testOfObjects.map(function (testdomainDetails) {
      for (var key in testdomainDetails) {
        if (testdomainDetails.id === item.id) {
          testdomainDetails[key] = item.name;
          testdomainDetails["isEditing"] = false;
          break;
        }
      }
      return testdomainDetails;
    });

    this.setState({ sections: newsectionDetails });

  };

  handleUpdateEvent(updateDetails) {
    if (updateDetails.length > 0) {
      var item = {
        Id: updateDetails[0].id,
        SectionName: updateDetails[0].sectionName.trim(),
        ParentId: updateDetails[0].parentId,
        Language: this.state.lanId,
        isEditing: true,
        UpdatedBy: localStorage.getItem('loggedinUser'),
        UpdatedOn: Date.now
      };
      if (item.SectionName != null && item.SectionName != "") {
        this.setState({ mymessage: "" })
        this.props.updateSection(item)
          .then((results) => {
            this.props.userDetailsSuccess(results.data.Message)
            this.setState({ mymessage: results.data.Message })
            if (results.status === 200) {
              item.UpdatedBy = results.data.Result.UpdatedBy;
              item.UpdatedOn = results.data.Result.UpdatedOn;
              this.handleEditEvent(item)
            }
            this.loadSections()

          }).catch((error) => {
            if (error.stack.includes('Network Error')) {
              this.setState({ mymessage: "" })
              this.props.userDetailsFailure('API service is down. Please contact administrator.')
            }
            else {
              this.setState({ mymessage: "" })
              this.props.userDetailsFailure(error.response.data.Message)
            }
          })
      }
      else {
        this.setState({ mymessage: "" })
        this.props.userDetailsFailure("Section name cannot be blank")
      }
    }


  }



  componentWillUnmount() {
    this.formDispatch(actions.reset('sectionManager'))
    this.formDispatch(actions.reset('editDomain'))
    this.props.resetError();
  }

  render() {
    return (
      <div className="popup-content">
        <div className="medium-12 title">{this.props.content.title}</div>
        <div>
          <Form model="sectionManager" method="post"
            getDispatch={(dispatch) => this.formDispatch = dispatch}
            onSubmit={(formValue) => this.addSection(formValue)}>

            <div className="grid-x grid-padding-x" >
              <div className="medium-4 cell grid-padding-5" onClick={this.onDDLSectionSelectFileClickEvent.bind(this)} onBlur={this.onDDLSectionSelectFileBlurEvent.bind(this)}>
                {/* <Control.select
                  className="role__select"
                  model="sectionManager.FileName"
                  id="sectionManager.FileName"
                  component={SelectField}
                  label={this.props.content.filter}
                  onChange={(value) => this.handleChange(value)}
                  // validators={{ required: (value) => validators.validSelect(this.props.content.selector.indexOf(value)) }}
                  validateOn="change"
                  floatingLabel
                >
                  {
                    this.state.fileFilter.map((filter) =>
                      <Option
                        key={filter.Id}
                        value={filter.Name}
                      >{filter.Name}</Option>)
                  }
                </Control.select> */}
                {
                  ((this.state.isSectionSelectFileSelected === 'Selected' && this.state.selectedOption !== '')
                    ||
                    (this.state.isSectionSelectFileSelected === '' && this.state.selectedOption !== ''))
                    ? <label style={{ visibility: 'visible' }} class="css-floatingLabel"
                      for="sectionManager.FileName">{this.props.content.filter}</label>
                    : <label style={{ visibility: 'hidden' }} class="css-floatingLabel"
                      for="sectionManager.FileName">{this.props.content.filter}</label>
                }

                <Control
                  model="sectionManager.FileName"
                  id="sectionManager.FileName"
                  component={mySelect}
                  placeholder={this.props.content.filter}
                  value={this.state.fileFilter.filter(option => option.label === this.state.selectedOption)}
                  noOptionsMessage={() => null}
                  onChange={(value) => this.handleChange(value.label)}
                  options={this.state.fileFilter}
                  validateOn="change"
                  isSearchable={false}
                  classNamePrefix="gen-react-select"
                  className="gen-react-select-container"
                />
                <Control.text
                  model="sectionManager.FileName"
                  id="sectionManager.FileName"
                  value={this.state.selectedOption}
                  hidden
                  validateOn="blur"
                  component={Textfield}
                  validators={{
                    required: validators.required
                  }} />

                <Errors
                  className="errors"
                  style={{ margin: '-37px 0px' }}
                  model="sectionManager.FileName"
                  show="touched"
                  messages={{
                    required: this.props.validation.required
                  }}
                />
              </div>
              <div className="medium-4 cell" >
                <Control.text
                  model="sectionManager.SectionName"
                  component={Textfield}
                  validateOn="blur"
                  label={this.props.content.newSection}
                  floatingLabel
                  validators={{
                    required: validators.required
                  }}
                />
                <Errors
                  className="errors"
                  model="sectionManager.SectionName"
                  show="touched"
                  messages={{
                    required: this.props.validation.required
                  }}
                />
              </div>

              <div className="medium-2 cell pt13" >
                <Button
                  type="submit"
                  disabled={this.props.user.loading}
                  content={this.props.content.cta}
                  color
                />
              </div>
            </div>

          </Form>
          <div className="errors">{this.props.user.error}</div>
          <div className="success">{this.state.mymessage}</div>
        </div>
        <div className="grid-x" className="medium-11 medium-offset-0">
          <UserTable
            sections={this.state.sections}
            onRowDel={this.handleRowDel.bind(this)}
            onRowEdit={this.handleEditEvent.bind(this)}
            onRowCancel={this.handleCancelEvent.bind(this)}
            onSectionsTableUpdate={this.handleSectionsDetailTable.bind(this)}
            validation={this.props.validation}
            onRowUpdate={this.handleUpdateEvent.bind(this)} />

        </div>
      </div>
    );
  }
}

class UserTable extends React.Component {
  render() {
    var rowDel = this.props.onRowDel;
    var onSectionDetailTableUpdate = this.props.onSectionsTableUpdate;
    var rowEdit = this.props.onRowEdit;
    var rowCancel = this.props.onRowCancel;
    var rowUpdate = this.props.onRowUpdate;
    var validation = this.props.validation;
    var slide = this.props.sections.map(function (slide) {
      return (<UserRow
        onDelEvent={rowDel.bind(this)}
        onEditEvent={rowEdit.bind(this)}
        onCancelEvent={rowCancel.bind(this)}
        onUpdateEvent={rowUpdate.bind(this)}
        onSectionDetailTableUpdate={onSectionDetailTableUpdate}
        validation={validation}
        slide={slide}
        key={slide.Id} />)

    });
    return (
      <div className="basic-table">
        <table>
          <thead>
            <tr>
              <th>File Name</th>
              <th>Section Name</th>
              <th>Added By/On</th>
              <th></th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {slide}{onSectionDetailTableUpdate}
          </tbody>
        </table>
      </div>
    );
  }
}

class UserRow extends React.Component {
  constructor(props) {
    super(props);
  }

  // onDelEvent() {
  // 	this.props.onDelEvent(this.props.report);
  // }

  // onEditEvent() {
  //   this.props.onEditEvent(this.props.report);
  // }

  onSectionDetailTableUpdate() {
    this.props.onSectionDetailTableUpdate()
  }

  render() {
    return (
      this.props.slide.isEditing ?
        <EditableCell
          onSectionDetailTableUpdate={this.props.onSectionDetailTableUpdate}
          slide={this.props.slide}
          cellData={{
            sectionName: this.props.slide.SectionName,
            id: this.props.slide.Id,
            isEditing: this.props.slide.isEditing,
            parentId: this.props.slide.ParentId

          }}
          onCancelEvent={this.props.onCancelEvent}
          onUpdateEvent={this.props.onUpdateEvent}
          validation={this.props.validation}
        />
        :
        <NonEditableRow
          slide={this.props.slide}
          cellData={{
            sectionName: this.props.slide.SectionName,
            id: this.props.slide.Id,
            isEditing: this.props.slide.isEditing,
            parentId: this.props.slide.ParentId

          }}
          onDelEvent={this.props.onDelEvent}
          onEditEvent={this.props.onEditEvent}
        />
    );
  }
}

class EditableCell extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sectionDetails: this.props.cellData,
      originalsectionDetails: this.props.cellData,
      updateData: []
    };
  }

  onSectionDetailTableUpdate() {
    this.props.onSectionDetailTableUpdate();
  }

  onCancelEvent() {
    var sectionDetails = [
      {
        id: this.state.originalsectionDetails.id,
        parentId: this.state.originalsectionDetails.parentId,
        sectionName: this.state.originalsectionDetails.sectionName,
        isEditing: false
      }]
    this.props.onCancelEvent(sectionDetails);
  }

  onUpdateEvent() {
    this.props.onUpdateEvent(this.state.sectionDetails);
  }

  onChangeEvent(event) {
    var changeItem = [
      {
        id: event.target.id,
        parentId: this.props.cellData.parentId,
        sectionName: event.target.value,
        isEditing: true
      }]
    this.setState({ sectionDetails: changeItem })
  }

  isDuplicate(vals) {

  }

  render() {
    return (
      <tr>
        <td>
          <span>{this.props.slide.FileName}</span><br />
        </td>
        <td>
          <Control.text
            model="sectionManager.sec"
            component={Textfield}
            defaultValue={this.props.cellData.sectionName}
            id={this.props.cellData.id}
            parentId={this.props.cellData.parentId}
            value={this.state.sectionDetails.sectionName}
            onChange={this.onChangeEvent.bind(this)}
            isEditing={this.props.cellData.isEditing}
            validateOn="blur"
            validators={{
              required: validators.required
            }}
            type='name' />
          <Errors
            className="errors"
            model="sectionManager.sec"
            show="touched"
            messages={{

            }} />
        </td>
        <td>
          <span>{this.props.slide.AddedBy}</span><br />
          <span>{moment(this.props.slide.AddedOn).format('L')}</span>
        </td>
        {/* <td>
          <span> {this.props.slide.UpdatedBy}</span> <br />
          <span>{moment(this.props.slide.UpdatedOn).format('L')} </span>
        </td> */}
        <td>
          <button onClick={this.onUpdateEvent.bind(this)}>Update</button>
        </td>
        <td >
          <button onClick={this.onCancelEvent.bind(this)}>Cancel</button>
        </td>
      </tr>
    );
  }
}

class NonEditableRow extends React.Component {

  onDelEvent() {
    this.props.onDelEvent(this.props.cellData);
  }

  onEditEvent() {
    this.props.onEditEvent(this.props.cellData);
  }

  render() {
    return (
      <tr>
        <td>
          <span>{this.props.slide.FileName}</span>
        </td>
        <td>
          <span id={this.props.cellData.id}
            name={this.props.cellData.sectionName}
          >{this.props.cellData.sectionName}</span>
        </td>
        <td>
          <span>{this.props.slide.AddedBy}</span><br />
          <span>{moment(this.props.slide.AddedOn).format('L')}</span>
        </td>
        {/* <td>
          <span> {this.props.slide.UpdatedBy}</span> <br />
          <span>{moment(this.props.slide.UpdatedOn).format('L')} </span>
        </td> */}
        <td>
          <button onClick={this.onEditEvent.bind(this)}>Edit</button>
        </td>
        <td>
          <button onClick={this.onDelEvent.bind(this)}>Delete</button>
        </td>
      </tr>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.language,
    ui: state.content.ui,
    user: state.default,
    content: state.content.sectionManager,
    validation: state.content.validation
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchFiles: (formValues) => dispatch(fetchFiles(formValues)),
    fetchSections: (formValues) => dispatch(fetchSections(formValues)),
    addSection: (formValues) => dispatch(addSection(formValues)),
    deleteSection: (formValues) => dispatch(deleteSection(formValues)),
    updateSection: (formValues) => dispatch(updateSection(formValues)),
    userDetailsLoading: (bool) => dispatch(userDetailsLoading(bool)),
    userDetailsSuccess: (bool) => dispatch(userDetailsSuccess(bool)),
    userDetailsFailure: (error) => dispatch(userDetailsFailure(error)),
    resetError: () => dispatch(resetError())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SectionManager)