import React, { Component } from 'react'
import { path } from 'Constants'
import CustomGrid from 'UI/CustomGrid'
import { connect } from 'react-redux'
import { Filters } from "react-data-grid-addons";

import {
    industrySupplierLoading,
    industrySupplierSuccess,
    industrySupplierFailure,
    getIndustrySupplierScopeDetails,
    setPopUpTabLoadingStatus
} from 'actions/spendActions'
import { getNonEmptyValue, LeftAlignFormatter, formatter, convertToMillion, formatThousands } from "../../utils/Utils";


const {
    NumericFilter,
    AutoCompleteFilter,
} = Filters;


class IndustrySupplier extends Component {
    constructor(props) {
        super(props)
        this.state = {
            responseMsg: '',
            industrySupplierData: {
                DMC_ID: this.props.spendDetails.currentActiveRequest.DMC_ID,
                VS_ID: this.props.spendDetails.currentActiveRequest.VS_ID,
                User_Email: '',
                Solution: 'No Filter'
            },
            data: {},
            columns: [],
            aggregatedData: { INVOICE_COUNT: 0, INVOICE_SPEND: 0.0, PO_COUNT: 0, PO_SPEND: 0.0, UNIQUE_SUPPLIERS: 0 },
            isLoading: true
        }

        this.loadData = this.loadData.bind(this)
        this.validate = this.validate.bind(this)
        //this.formGridRow = this.formGridRow.bind(this)
        this.getGridRows = this.getGridRowsAndAggregateData.bind(this)
        this.onCommit = this.onCommit.bind(this)
        this.onFilter = this.onFilter.bind(this)
    }

    componentDidMount() {
        this.loadData(this.props);
    }

    loadData(nextProps) {
        var defineScope_obj = this.state.industrySupplierData;
        // defineScope_obj.User_Email = this.props.spendDetails !== undefined &&
        //     this.props.spendDetails.currentActiveRequest !== undefined ? (
        //         this.props.spendDetails.currentActiveRequest.SAPRequester === this.props.user.user.Email ?
        //             this.props.user.user.Email : this.props.spendDetails.currentActiveRequest.SAPRequester) : '';
        defineScope_obj.DMC_ID = this.props.spendDetails.currentActiveRequest.DMC_ID;
        defineScope_obj.VS_ID = this.props.spendDetails.currentActiveRequest.VS_ID;
        defineScope_obj.User_Email = this.props.user.user.Email;
        defineScope_obj.GICS_ID = nextProps.spendDetails.industryGICS_Details.SKEY;
        defineScope_obj.Country = 'all';
        defineScope_obj.Solution = 'No Filter';
        defineScope_obj.SolutionConfirmation = 'all';


        let currencySymbol = this.props.spendDetails.currentActiveRequest !== undefined ?
            this.props.currencySymbols[this.props.spendDetails.currentActiveRequest.Currency.trim()] : ''

        this.props.setPopUpTabLoadingStatus(true)
        this.props.getIndustrySupplierScopeDetails(defineScope_obj)
            .then((response) => {
                if (response !== undefined && response.status === 200 && response.data.Result !== undefined) {
                    setTimeout(() => { this.props.industrySupplierSuccess('') }, 2000)
                    let columns = this.initializeColumns(response.data.Result);
                    //let preparedData = this.formGridRow(response);

                    let obj = this.getGridRowsAndAggregateData(response.data.Result, currencySymbol)
                    this.setState({ data: obj.rows, columns: columns, aggregatedData: obj.aggregatedData, currencySymbol: currencySymbol, isLoading: false });
                } else if (response.response && response.response.status == 400) {
                    this.setState({ data: [], aggregatedData: { INVOICE_COUNT: 0, INVOICE_SPEND: 0.0, PO_COUNT: 0, PO_SPEND: 0.0, UNIQUE_SUPPLIERS: 0 }, columns: [], currencySymbol: currencySymbol, isLoading: false, isError: 'No', message: 'No Result Found' })
                } else if (response && response.stack && response.stack.includes('Network Error')) {
                    this.setState({ responseMsg: this.props.validation.networkError, isError: 'Yes', isLoading: false })
                }
                this.props.setPopUpTabLoadingStatus(false)
            }).catch((error) => {
                this.props.setPopUpTabLoadingStatus(false);
                this.setState({ responseMsg: this.props.validation.error, isError: 'Yes', isLoading: false })
            })
    }

    componentWillReceiveProps(nextProps) {
        if (
            nextProps.spendDetails.industryGICS_Details && nextProps.spendDetails.industryGICS_Details.SKEY !== (this.props.spendDetails.industryGICS_Details ? this.props.spendDetails.industryGICS_Details.SKEY : undefined)) {
            this.loadData(nextProps);
        }
    }

    initializeColumns() {
        let columns = []

        let tableColumn = {
            name: 'Ref.#',
            key: 'REF_NUMBER',
            width: 120,
            sortable: true,
            editable: false,
            resizable: true,
            filterable: true,
            screenSeq: 0,
            mandatory: 'No',
            exportFl: true,
            //editProperty: fieldConfig.is_editable,
            formatter: LeftAlignFormatter,
            helpText: '',
            filterRenderer: NumericFilter
        }

        columns.push(tableColumn);


        tableColumn = {
            name: 'Vendor Id',
            key: 'VENDOR_ID',
            width: 120,
            sortable: true,
            editable: false,
            resizable: true,
            filterable: true,
            screenSeq: 0,
            mandatory: 'No',
            exportFl: true,
            //editProperty: fieldConfig.is_editable,
            formatter: LeftAlignFormatter,
            helpText: ''
        }

        columns.push(tableColumn);

        tableColumn = {
            name: 'Vendor Name',
            key: 'SUPPLIER_NAME',
            width: 250,
            sortable: true,
            editable: false,
            resizable: true,
            filterable: true,
            screenSeq: 0,
            mandatory: 'No',
            exportFl: true,
            //editProperty: fieldConfig.is_editable,
            formatter: LeftAlignFormatter,
            helpText: ''
        }

        columns.push(tableColumn);

        tableColumn = {
            name: 'Final Industry Description',
            key: 'INDUSTRY_DESC',
            width: 350,
            sortable: true,
            editable: false,
            resizable: true,
            filterable: true,
            screenSeq: 0,
            mandatory: 'No',
            exportFl: true,
            //editProperty: fieldConfig.is_editable,
            formatter: LeftAlignFormatter,
            helpText: ''
        }

        columns.push(tableColumn);

        tableColumn = {
            name: 'Industry Level',
            key: 'INDUSTRY_LEVEL',
            width: 120,
            sortable: true,
            editable: false,
            resizable: true,
            filterable: true,
            screenSeq: 0,
            mandatory: 'No',
            exportFl: true,
            //editProperty: fieldConfig.is_editable,
            formatter: formatter('AlignFormatter'),
            helpText: ''
        }
        columns.push(tableColumn);

        tableColumn = {
            name: 'Invoice Spend',
            key: 'INVOICE_SPEND',
            width: 200,
            sortable: true,
            editable: false,
            resizable: true,
            filterable: true,
            screenSeq: 1,
            mandatory: 'No',
            exportFl: true,
            //editProperty: fieldConfig.is_editable,
            formatter: formatter('AlignCurrencyFormatter'),
            helpText: '',
            filterRenderer: NumericFilter
        }

        columns.push(tableColumn);


        tableColumn = {
            name: 'Invoice Count',
            key: 'INVOICE_COUNT',
            width: 200,
            sortable: true,
            editable: false,
            resizable: true,
            filterable: true,
            screenSeq: 2,
            mandatory: 'No',
            exportFl: true,
            //editProperty: fieldConfig.is_editable,
            formatter: formatter('AlignFormatter'),
            helpText: '',
            filterRenderer: NumericFilter
        }

        columns.push(tableColumn);

        tableColumn = {
            name: 'PO Spend',
            key: 'PO_SPEND',
            width: 200,
            sortable: true,
            editable: false,
            resizable: true,
            filterable: true,
            screenSeq: 3,
            mandatory: 'No',
            exportFl: true,
            //editProperty: fieldConfig.is_editable,
            formatter: formatter('AlignCurrencyFormatter'),
            helpText: '',
            filterRenderer: NumericFilter
        }

        columns.push(tableColumn);

        tableColumn = {
            name: 'PO Count',
            key: 'PO_COUNT',
            width: 200,
            sortable: true,
            editable: false,
            resizable: true,
            filterable: true,
            screenSeq: 3,
            mandatory: 'No',
            exportFl: true,
            //editProperty: fieldConfig.is_editable,
            formatter: formatter('AlignFormatter'),
            helpText: '',
            filterRenderer: NumericFilter
        }

        columns.push(tableColumn);
        return columns;
    }

    // formGridRow(response) {
    //     let preparedData = [];
    //     if(response.data.Result!=null){
    //     response.data.Result.map((columnDetail)=>{
    //         var rowData = [];
    //         rowData= columnDetail;

    //         //rowData["SUPPLIER_NAME"] = rowData.SUPPLIER_NAME +":" + rowData.INDUSTRY_DESC+"(L"+ rowData.INDUSTRY_LEVEL+")";

    //         //rowData['INDUSTRY_DESC'] = rowData['INDUSTRY_DESC'] + ' (' + rowData['FINAL_GICS'] + ')'
    //         preparedData.push(rowData);                  
    //     });
    // }
    //     return preparedData;
    // }

    validateRow(records) {
        var errorRecords = []
        const columns = this.state.columns

        for (let idx = 0; idx < records.length; idx++) {
            const row = records[idx]
            for (let idx = 0; idx < columns.length; idx++) {
                const column = columns[idx]
                if (getNonEmptyValue(row[column.key]) === '' && column.mandatory) {
                    errorRecords.push(row)
                    break;
                }
            }

        }

        return errorRecords;
    }

    validate(record, warningErrorRecords) {

        return warningErrorRecords
    }

    onCommit(response) {
        let obj = this.getGridRowsAndAggregateData(response.data.Result, this.state.currencySymbol)
        return obj.rows;
    }

    onFilter(rows) {
        let aggregatedData = { INVOICE_COUNT: 0, INVOICE_SPEND: 0.0, PO_COUNT: 0, PO_SPEND: 0.0, UNIQUE_SUPPLIERS: 0 };
        rows.forEach(item => {
            aggregatedData.INVOICE_COUNT += item.INVOICE_COUNT
            aggregatedData.INVOICE_SPEND += item.INVOICE_SPEND
            aggregatedData.PO_SPEND += item.PO_SPEND
            aggregatedData.PO_COUNT += item.PO_COUNT
        })
        aggregatedData.UNIQUE_SUPPLIERS = rows.length;

        this.setState({ aggregatedData: aggregatedData })
    }


    getGridRowsAndAggregateData(result, currencySymbol) {
        let aggregatedData = { INVOICE_COUNT: 0, INVOICE_SPEND: 0.0, PO_COUNT: 0, PO_SPEND: 0.0, UNIQUE_SUPPLIERS: 0 };
        result.forEach(item => {
            aggregatedData.INVOICE_COUNT += item.INVOICE_COUNT
            aggregatedData.INVOICE_SPEND += item.INVOICE_SPEND
            aggregatedData.PO_SPEND += item.PO_SPEND
            aggregatedData.PO_COUNT += item.PO_COUNT

            item['INDUSTRY_DESC'] = item['INDUSTRY_DESC'] + ' (' + item['FINAL_GICS'] + ')'
            item['currencySymbol'] = currencySymbol
        })
        aggregatedData.UNIQUE_SUPPLIERS = result.length;

        return { aggregatedData: aggregatedData, rows: result }
    }


    render() {
        return (
            <div className="grid-x">
                <div className="tab-description">{this.props.industrySuppliers.tabHeader}</div>
                <div className="medium-12 pt10">
                    <div className="blue-bg">
                        <div className="grid-x grid-padding-x">
                            <div className="float-left">
                                <div className="title">{this.props.industrySuppliers.uniqueSuppliers + ":"}</div>
                                <div className="value">   {formatThousands(this.state.aggregatedData.UNIQUE_SUPPLIERS)}</div>
                            </div>
                            <div className="float-left pl20">
                                <div className="title">{this.props.industrySuppliers.invoiceSpend + ":"}</div>
                                <div className="value">  {this.state.currencySymbol}{convertToMillion(this.state.aggregatedData.INVOICE_SPEND, true)}</div>
                            </div>
                            <div className="float-left pl20">
                                <div className="title">{this.props.industrySuppliers.poSpend + ":"}</div>
                                <div className="value">  {this.state.currencySymbol}{convertToMillion(this.state.aggregatedData.PO_SPEND, true)}</div>
                            </div>
                            <div className="float-left pl20">
                                <div className="title">{this.props.industrySuppliers.invoiceCount + ":"} </div>
                                <div className="value">{formatThousands(this.state.aggregatedData.INVOICE_COUNT)}</div>
                            </div>
                            <div className="float-left pl20">
                                <div className="title">{this.props.industrySuppliers.poCount + ":"} </div>
                                <div className="value">{formatThousands(this.state.aggregatedData.PO_COUNT)}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <CustomGrid
                    columns={this.state.columns}
                    data={this.state.data}
                    keyColumn="DATA_COLLECTION_KEY"
                    validate={this.validate}
                    isEditable={false}
                    onCommit={this.onCommit}
                    onFilter={this.onFilter}
                    export={true}
                    exportFileName={this.props.industrySuppliers.exportFileName}
                    apiResponse={{ message: this.state.responseMsg, isError: this.state.isError, isLoading: this.state.isLoading }}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.default,
        spendDetails: state.spendDetails,
        //content: state.content.spendAnalysis.defineScope,
        industrySuppliers: state.content.spendAnalysis.industryDrilldown.industrySuppliers,
        validation: state.content.validation,
        currencySymbols: state.content.CurrencySymbols
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        industrySupplierLoading: (bool) => dispatch(industrySupplierLoading(bool)),
        industrySupplierSuccess: (bool) => dispatch(industrySupplierSuccess(bool)),
        industrySupplierFailure: (error) => dispatch(industrySupplierFailure(error)),
        getIndustrySupplierScopeDetails: (formValues) => dispatch(getIndustrySupplierScopeDetails(formValues)),
        setPopUpTabLoadingStatus: bool => dispatch(setPopUpTabLoadingStatus(bool)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(IndustrySupplier); 