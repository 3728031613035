import React, { Component } from 'react'
import { connect } from 'react-redux'
import Button from 'UI/Button'
import { enablementInsightSuccess,
    enablementInsightFailure,
    enablementInsightLoading,    
	resetError, updateWaveDescription } from 'actions/spendActions'
import moment from 'moment'

class WaveDescriptionPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {            
            waveDescription: ''    
        }
         this.clearWaveDescription = this.clearWaveDescription.bind(this)
   
    }

    clearWaveDescription() {
        this.setState({waveDescription: ''})
    }

    componentDidMount() {
       this.setState({ enablementWave: this.props.enablementWave, waveDescription: this.props.data.WAVE_DESCRIPTION })
    }

    addWaveDescription(){
        var waveDescription = document.getElementById("waveDesc").value                     
        this.props.addWaveDescription(waveDescription)
    }

    onChange(e) {
        this.setState({ waveDescription : e.currentTarget.value })
    }

    render() {
        return (           
            <div style={{ padding: '10px' }} className="grid-x">
              
               <div className="grid-x medium-12" style={{ paddingBottom: '5px',borderBottom: '1px solid #e1e1e1' }}>                
                     <div className="medium-11 float-left popHeader" >
                         {this.props.content.waveDescriptionHeader}
                     </div>
                     <div style={{ cursor: 'pointer' }} className="medium-1 float-right close-icon" onClick={this.props.close}>
                         <img width="20" src="images/close_inactive.svg" />
                     </div>
                </div>

                <div className="grid-x medium-12" style={{ paddingTop: '5px' }}>
                    <div className="medium-4 float-left fieldTitleGeneralContent"> {this.props.content.enablementWaveLabel}{": "}</div>
                    <div className="medium-8 float-left fieldTitleGeneralContent" style={{paddingLeft: '15px'}}> {this.props.data.ENABLEMENT_WAVE}</div>                    
                </div>

                
                <div className="grid-x medium-12" style={{ paddingTop: '5px' }}>
                    <div className="medium-4 float-left fieldTitleGeneralContent"> {this.props.content.waveDescriptionText}{": "}</div>
                    <div className="medium-8 control-wrapper fieldValue" >
                        <textarea
                            rows="3"
                            cols="30"
                            type="text" style={{ padding: '5px' }}
                            placeholder={this.props.content.waveDescriptionWaterText}                           
                            value={this.state.waveDescription}
                            maxLength="25"
                            onChange={e => this.onChange(e)}
                            id="waveDesc"
                        />
                    </div>
               </div>
            
            <div style={{ paddingTop: '10px' }} className="popupOkBtn grid-x medium-12">
                <div className="medium-4">
                </div>

                <div className="medium-8 float-right">
                    <div className="float-left">
                        <Button onClick={this.clearWaveDescription} type="submit" content={this.props.content.clearWaveDescription} />
                    </div>
                    <div className="float-left">
                        <Button onClick={(e) => this.addWaveDescription()} type="submit" content={this.props.ui.save} />
                    </div>
                </div>
            </div>
        </div >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ui: state.content.ui,
        user: state.default,
        supplierComments: state.content.spendAnalysis.supplierDrilldown.supplierComments,
        spendDetails: state.spendDetails,
        content: state.content.enablementInsight.enablementWaveDetails
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
       enablementInsightSuccess: message => dispatch(enablementInsightSuccess(message)),
       enablementInsightFailure: error => dispatch(enablementInsightFailure(error)),
       enablementInsightLoading: bool => dispatch(enablementInsightLoading(bool)),
       resetError: error => dispatch(resetError(error)),
       updateWaveDescription: (data) => dispatch(updateWaveDescription(data))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(WaveDescriptionPopup)