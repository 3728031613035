import * as d3 from 'd3'
import { convertToMillion, round, formatThousands } from 'utils/Utils'

const VerticleBarChart = (data, currency, element, chartName, yAxisName, width, height) => {

    var margin = { top: 30, right: 20, bottom: 20, left: 70 }

    var chart = d3.select("#" + element).append("svg")
        .attr('viewBox',
            '-10 0 ' +
            (width + margin.left + margin.right) +
            ' ' +
            (height + margin.top + margin.bottom)
        )
        .attr('preserveAspectRatio', 'xMinYMin')
        .append("g")
        .attr("transform",
            "translate(" + margin.left + "," + margin.top + ")");

    var maxValue = d3.max(data, function (d) {
        return d.value;
    })

    const yScale = d3.scaleLinear()
        .range([height, 0]).nice()
        .domain([0, maxValue + Math.round(maxValue / 5)]);

    chart.append("g")
        .attr("class", "axisChart")
        .call(d3.axisLeft(yScale).ticks(5, "s").tickFormat(function (d) {
            return element === 'SpendChart' ? convertToMillion(d) : formatThousands(round(Number(d), 2).toFixed(0));
        }))
        .append("text")
        .attr("transform", "rotate(-90)")
        .attr("y", -80)
        .attr("x", 0 - (height / 2))
        .attr("dy", "1em")
        .style("text-anchor", "middle")
        .text(yAxisName);

    const xScale = d3.scaleBand()
        .range([0, element === "SpendChart" ? width - 40 : width])
        .domain(data.map((s) => s.name))
        .padding(element === "SpendChart" ? 0.1 : 0.2)
    chart.append('g')
        .attr('transform', `translate(0, ${height})`)
        .call(d3.axisBottom(xScale));

    chart.selectAll()
        .data(data)
        .enter()
        .append('rect')
        .attr("x", (d) => element === "SpendChart" ? xScale(d.name) + 17 : xScale(d.name) + 10)
        .attr("y", (d) => yScale(d.value))
        .attr("height", (d) => height - yScale(d.value))
        .attr("width", element === "SpendChart" ? xScale.bandwidth() - 34 : xScale.bandwidth() - 20)
        .attr("fill", "#33C3FF")

    chart.selectAll()
        .data(data)
        .enter()
        .append("text")
        .style("font-size", "12px")
        .style("font-weight", "600")
        .attr("class", "label")
        .attr("fill", "#33C3FF")
        .attr('text-anchor', 'middle')
        .attr("x", (d) => xScale(d.name) + xScale.bandwidth() / 2)
        .attr('dx', 0)
        .attr("y", (d) => yScale(d.value))
        .attr('dy', -6)
        .text(function (d) {
            return element === "SpendChart" ? currency + convertToMillion(d.value, true) : formatThousands(round(Number(d.value), 2).toFixed(0))
        });

    chart.append("text")
        .attr("x", width / 2)
        .attr("y", -5)
        .attr('text-anchor', 'middle')
        .text(chartName)

    return chart;
}
export default VerticleBarChart