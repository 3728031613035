import React from 'react';
import Button from 'UI/Button'
import { Checkbox } from 'material-ui';
import { connect } from 'react-redux'
import Radio from 'react-mdl/lib/Radio'
import {ButtonE, CheckboxE, RadioButtonE, ButtonNativeE} from 'UI/CustomComponents/Controls';

import { addFavoriteFields, getFavoriteFields, editFavoriteField, deleteFavoriteField } from 'actions/spendActions'

class ColumnSelectFavorite extends React.Component {
    constructor(props) {
        super(props);
        this.addFavoriteFields = this.addFavoriteFields.bind(this);
        this.state = {
            "favouriteTemplates": [],
            "favoriteTemplatesCopy": [],
            "defaultTemplate": false,
            "flightPlanningTemplate": false,
            "supplyChainCollaborationTemplate": false,
            "internalEnablementInsightViewTemplate": false,
            //"externalEnablementInsightViewTemplate": false,
            "categoryInsightsViewTemplate": false,
            "enterpriseEnablementEstimatesViewTemplate": false,
            "mAndCviewTemplate": false,
            "duplicateNameError": false,
            "sccView": false,
            "sccEnableInsightView": false,
            "AllSccView": false,
            hideEmptyColumns: false
        }
        this.setFavoriteFields = this.setFavoriteFields.bind(this);
        this.addNewColumns = this.props.columns && this.props.columns.length > 0;
        this.selectHideEmptyColumnsTemplate = this.selectHideEmptyColumnsTemplate.bind(this)
    }

    componentWillMount() {
        this.setFavoriteFields();
    }

    setFavoriteFields() {
        let templates = []; this.templateNames = [];
        this.props.getFavoriteFields(this.props.user.user.Id).then(
            (response) => {
                templates = response.data.Result;
                templates.forEach(template => {
                    template.isSelected = false;
                    template.disabled = false;
                    this.templateNames.push(template.TemplateName.trim().toLocaleLowerCase());
                })
                if (this.addNewColumns) {
                    templates.push({ "TemplateName": "", "Columns": this.props.columns, "isSelected": false, disabled: true });
                }
                this.setState({ "favouriteTemplates": templates, favoriteTemplatesCopy: JSON.parse(JSON.stringify(templates)) }, () => {
                    if (this.addNewColumns) {
                        this.nameInput.focus();
                    }
                });
            },
            () => { }
        )
    }

    addFavoriteFields(index) {
        let favouriteTemplates = this.state.favouriteTemplates;
        let field = favouriteTemplates[index];
        if (this.templateNames.indexOf(field.TemplateName.trim().toLocaleLowerCase()) > -1) {
            this.setState({ duplicateNameError: true });
        } else {
            this.setState({ duplicateNameError: false });
            if (field.edit) {
                this.props.editFavoriteField(field.Id, field.TemplateName).then(
                    (response) => {
                        let field = response.data.Result;
                        favouriteTemplates[index].TemplateName = field.Name;
                        favouriteTemplates[index].edit = false;
                        this.templateNames[index] = field.Name.trim().toLocaleLowerCase();
                        this.setState({ favouriteTemplates: favouriteTemplates, favoriteTemplatesCopy: JSON.parse(JSON.stringify(favouriteTemplates)) });
                    },
                    (error) => {
                        //console.log(error)
                    }
                )
            } else {
                this.props.addFavoriteFields(this.props.user.user.Id, field.TemplateName, field.Columns).then(
                    (response) => {
                        favouriteTemplates[index] = response.data.Result;
                        this.templateNames.push(favouriteTemplates[index].TemplateName.trim().toLocaleLowerCase());
                        this.setState({ favouriteTemplates: favouriteTemplates, favoriteTemplatesCopy: JSON.parse(JSON.stringify(favouriteTemplates)) });
                        this.addNewColumns = false;
                    },
                    (error) => {
                        //console.log(error)
                    }
                )
            }
        }
    }

    templateNameChange(index, value) {
        let favouriteTemplates = this.state.favouriteTemplates;
        favouriteTemplates[index].TemplateName = value;
        favouriteTemplates[index].disabled = value === "";
        this.setState({ favouriteTemplates: favouriteTemplates });
    }

    selectFavTemplate(element, index) {
        let favouriteTemplates = this.state.favouriteTemplates;
        favouriteTemplates[index].isSelected = !favouriteTemplates[index].isSelected;
        this.setState({ favouriteTemplates: favouriteTemplates });
        this.props.selectFavoriteTemplate(favouriteTemplates[index]);
    }

    cancel(idx) {
        let favouriteTemplates = this.state.favouriteTemplates;
        let favoriteTemplatesCopy = this.state.favoriteTemplatesCopy;
        let field = favouriteTemplates[idx];
        let fieldCopy = favoriteTemplatesCopy[idx];
        if (field.edit) {
            field.edit = false;
            field.TemplateName = fieldCopy.TemplateName;
        } else {
            favouriteTemplates.splice(idx, 1);
        }
        this.setState({ favouriteTemplates: favouriteTemplates, duplicateNameError: false });
    }

    editFavoriteField(idx) {
        let favouriteTemplates = this.state.favouriteTemplates;
        favouriteTemplates[idx].edit = true;
        favouriteTemplates[idx].disabled = favouriteTemplates[idx].TemplateName === "";
        this.setState({ favouriteTemplates: favouriteTemplates });
    }

    delete(idx) {
        let favouriteTemplates = this.state.favouriteTemplates;
        let field = favouriteTemplates[idx];
        this.props.deleteFavoriteField(this.props.user.user.Id, field.Id).then(
            (response) => { this.setFavoriteFields() },
            (error) => { console.log(error); }
        )
    }

    selectDefaultTemplate() {
        let defaultTemplate = !this.state.defaultTemplate;
        this.setState({ defaultTemplate: defaultTemplate });
        this.props.selectDefaultTemplate();
    }

    selectFlightPlanningTemplate() {
        let flightPlanningTemplate = !this.state.flightPlanningTemplate;
        this.setState({ flightPlanningTemplate: flightPlanningTemplate });
        this.props.selectFlightPlanningTemplate();
    }

    selectInternalEnablementInsightViewTemplate() {
        let internalEnablementInsightViewTemplate = !this.state.internalEnablementInsightViewTemplate;
        this.setState({ internalEnablementInsightViewTemplate: internalEnablementInsightViewTemplate });
        this.props.selectInternalEnablementInsightViewTemplate();
    }

    // selectExternalEnablementInsightViewTemplate() {
    //     let externalEnablementInsightViewTemplate = !this.state.externalEnablementInsightViewTemplate;
    //     this.setState({ externalEnablementInsightViewTemplate: externalEnablementInsightViewTemplate });
    //     this.props.selectExternalEnablementInsightViewTemplate();
    // }

    selectCategoryInsightsViewTemplate() {
        let categoryInsightsViewTemplate = !this.state.categoryInsightsViewTemplate;
        this.setState({ categoryInsightsViewTemplate: categoryInsightsViewTemplate });
        this.props.selectCategoryInsightsViewTemplate();
    }

    selectEnterpriseEnablementEstimatesViewTemplate() {
        let enterpriseEnablementEstimatesViewTemplate = !this.state.enterpriseEnablementEstimatesViewTemplate;
        this.setState({ enterpriseEnablementEstimatesViewTemplate: enterpriseEnablementEstimatesViewTemplate });
        this.props.selectEnterpriseEnablementEstimatesViewTemplate();
    }

    selectSccEnableInsightViewTemplate() {
        let sccEnableInsightView = !this.state.sccEnableInsightView;
        this.setState({ sccEnableInsightView: sccEnableInsightView });
        this.props.selectSccEnableInsightViewTemplate();
    }

    selectAllSccViewTemplate() {
        let allSCCView = !this.state.allSCCView;
        this.setState({ allSCCView: allSCCView });
        this.props.selectAllSccViewTemplate();
    }

    selectSccViewTemplate() {
        let sccView = !this.state.sccView;
        this.setState({ sccView: sccView });
        this.props.selectSccViewTemplate();
    }

    selectMAndCviewTemplate() {
        let mAndCviewTemplate = !this.state.mAndCviewTemplate;
        this.setState({ mAndCviewTemplate: mAndCviewTemplate });
        this.props.selectMAndCviewTemplate();
    }    

    selectHideEmptyColumnsTemplate() {
        let hideEmptyColumns = !this.state.hideEmptyColumns;
        this.setState({ hideEmptyColumns: hideEmptyColumns });
        this.props.selectHideEmptyColumnsTemplate();
    }

    render() {
        let templatesDiv = (templates) => {
            let div = [];
            templates.forEach((element, index) => {
                div.push(
                    <div className="medium-12" key={index}>
                        <div className="grid-x pt10">
                            <div className="medium-7">
                                <div className="grid-x">
                                    <div className="medium-2">
                                        {element.Id ?
                                            <Radio value={String(element.Id)}
                                                name="favTemplate"
                                                defaultChecked={element.isSelected}
                                                onClick={(e) => this.selectFavTemplate(element, index)}>
                                            </Radio>
                                            : <div>&nbsp;</div>
                                        }
                                    </div>
                                    <div className="medium-10">
                                        {
                                            element.Id && !element.edit ? element.TemplateName :
                                                <div>
                                                    <input ref={(input) => { this.nameInput = input; }} type="text" value={element.TemplateName} onChange={(event) => { this.templateNameChange(index, event.target.value) }} />
                                                    {this.state.duplicateNameError ? <div className="red">{this.props.validation.duplicateFavoriteField}</div> : null}
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="medium-5 pt1">
                                <div className="float-right pr20">
                                    <div className="float-left">
                                        {
                                            element.Id && !element.edit ?
                                                <button className="blue-hover hand-cursor" onClick={(e) => this.editFavoriteField(index)} float >
                                                    {this.props.content.editBtn}
                                                </button> :
                                                <button disabled={element.disabled} className="save-btn hand-cursor" onClick={(e) => this.addFavoriteFields(index)} float >
                                                    {this.props.content.saveBtn}
                                                </button>
                                        }
                                    </div>
                                    <div className="float-left pl20">
                                        {
                                            element.Id && !element.edit ?
                                                <button className="blue-hover hand-cursor" onClick={(e) => this.delete(index)} float >
                                                    {this.props.content.deleteBtn}
                                                </button> :
                                                <button className="blue-hover hand-cursor" onClick={(e) => this.cancel(index)} float >
                                                    {this.props.content.cancelBtn}
                                                </button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            });
            return div;
        }
        return (
            <div className="column-select-favorite">
                <div className="tabHeader">{this.props.content.yourSavedFavoriteViews}</div>
                <div className="grid-x">
                {/* {!this.props.isSCC && this.props.spendDetails.currentActiveRequest.SOLUTION_IN_SCOPE && this.props.spendDetails.currentActiveRequest.SOLUTION_IN_SCOPE == 'Supply Chain Collaboration'?  */}
                {this.props.spendDetails.currentActiveRequest.SOLUTION_IN_SCOPE && this.props.spendDetails.currentActiveRequest.SOLUTION_IN_SCOPE == 'Supply Chain Collaboration'?     
                    <div className="medium-12 pt10 bb_ccc pb5">
                        <div className="float-left width30">
                            <Radio value={String(this.state.allSCCView)}
                                name="favTemplate"
                                defaultChecked={this.state.allSCCView}
                                onClick={(e) => { this.selectAllSccViewTemplate() }}>
                            </Radio>
                        </div>
                        <div className="float-left line-height25">{this.props.content.allSCCView}</div>
                    </div>
                    :null}                

                {this.props.spendDetails.currentActiveRequest.SOLUTION_IN_SCOPE && this.props.spendDetails.currentActiveRequest.SOLUTION_IN_SCOPE == 'Supply Chain Collaboration'? 
                    <div className="medium-12 pt10 bb_ccc pb5">
                        <div className="float-left width30">
                            <Radio value={String(this.state.sccView)}
                                name="favTemplate"
                                defaultChecked={this.state.sccView}
                                onClick={(e) => { this.selectSccViewTemplate() }}>
                            </Radio>
                        </div>
                        <div className="float-left line-height25">{this.props.content.supplyChainCollaborationView}</div>
                    </div>
                     :null}
                     
                {this.props.spendDetails.currentActiveRequest.SOLUTION_IN_SCOPE && this.props.spendDetails.currentActiveRequest.SOLUTION_IN_SCOPE == 'Supply Chain Collaboration'? 
                    <div className="medium-12 pt10 bb_ccc pb5">
                        <div className="float-left width30">
                            <Radio value={String(this.state.sccEnableInsightView)}
                                name="favTemplate"
                                defaultChecked={this.state.sccEnableInsightView}
                                onClick={(e) => { this.selectSccEnableInsightViewTemplate() }}>
                            </Radio>
                        </div>
                        <div className="float-left line-height25">{this.props.content.internalEnablementInsightSCC}</div>
                    </div>
                     :null} 
                     
                    {this.props.spendDetails.currentActiveRequest.SOLUTION_IN_SCOPE && this.props.spendDetails.currentActiveRequest.SOLUTION_IN_SCOPE != 'Supply Chain Collaboration'? 
                    <div className="medium-12 pt10 bb_ccc pb5">
                        <div className="float-left width30">
                            <Radio value={String(this.state.defaultTemplate)}
                                name="favTemplate"
                                defaultChecked={this.state.defaultTemplate}
                                onClick={(e) => { this.selectDefaultTemplate() }}>
                            </Radio>
                        </div>
                        <div className="float-left line-height25">{this.props.content.defaultView}</div>
                    </div>
                    :null}
                    {/* 
                        Ticket AV-700 Requirement
                        <div className="medium-12 pt10 bb_ccc pb5">
                            <div className="float-left width30">
                                <Radio value={String(this.state.flightPlanningTemplate)} 
                                    name="favTemplate"
                                    defaultChecked={this.state.flightPlanningTemplate}
                                    onClick={(e) => { this.selectFlightPlanningTemplate() }}>
                                </Radio>
                            </div>
                            <div className="float-left line-height25">{this.props.content.enablementInsight}</div>
                        </div> 
                    */}

                    {/* {this.props.spendDetails.currentActiveRequest.SOLUTION_IN_SCOPE && this.props.spendDetails.currentActiveRequest.SOLUTION_IN_SCOPE != 'Supply Chain Collaboration'? 
                    <div className="medium-12 pt10 bb_ccc pb5">
                        <div className="float-left width30">
                            <Radio value={String(this.state.supplyChainCollaborationTemplate)}
                                name="favTemplate"
                                defaultChecked={this.state.supplyChainCollaborationTemplate}
                                onClick={(e) => { this.selectSccView() }}>
                            </Radio>
                        </div>
                        <div className="float-left line-height25">{this.props.content.supplyChainCollaborationView}</div>
                    </div>
                    :null} */}

                    {!this.props.isSCC ? 
                    <div className="medium-12 pt10 bb_ccc pb5">
                        <div className="float-left width30">
                            <Radio value={String(this.state.internalEnablementInsightViewTemplate)} 
                                name="favTemplate"
                                defaultChecked={this.state.internalEnablementInsightViewTemplate}
                                onClick={(e) => { this.selectInternalEnablementInsightViewTemplate() }}>
                            </Radio>
                        </div>
                        <div className="float-left line-height25">{this.props.content.enablementInsight}</div>
                    </div>
                    :null}
                    {/* <div className="medium-12 pt10 bb_ccc pb5">
                        <div className="float-left width30">
                            <Radio value={String(this.state.externalEnablementInsightViewTemplate)} 
                                name="favTemplate"
                                defaultChecked={this.state.externalEnablementInsightViewTemplate}
                                onClick={(e) => { this.selectExternalEnablementInsightViewTemplate() }}>
                            </Radio>
                        </div>
                        <div className="float-left line-height25">{this.props.content.externalEnablementInsight}</div>
                    </div> */}
                    {this.props.spendDetails.currentActiveRequest.SOLUTION_IN_SCOPE && this.props.spendDetails.currentActiveRequest.SOLUTION_IN_SCOPE != 'Supply Chain Collaboration'? 
                    <div className="medium-12 pt10 bb_ccc pb5">
                        <div className="float-left width30">
                            <Radio value={String(this.state.categoryInsightsViewTemplate)} 
                                name="favTemplate"
                                defaultChecked={this.state.categoryInsightsViewTemplate}
                                onClick={(e) => { this.selectCategoryInsightsViewTemplate() }}>
                            </Radio>
                        </div>
                        <div className="float-left line-height25">{this.props.content.categoryInsights}</div>
                    </div>
                    :null}

                    <div className="medium-12 pt10 bb_ccc pb5">
                        <div className="float-left width30">
                            <Radio value={String(this.state.mAndCviewTemplate)} 
                                name="favTemplate"
                                defaultChecked={this.state.mAndCviewTemplate}
                                onClick={(e) => { this.selectMAndCviewTemplate() }}>
                            </Radio>
                        </div>
                        <div className="float-left line-height25">{this.props.content.mAndCView}</div>
                    </div>
                    
                    {this.props.spendDetails.currentActiveRequest.SOLUTION_IN_SCOPE && this.props.spendDetails.currentActiveRequest.SOLUTION_IN_SCOPE != 'Supply Chain Collaboration'? 
                    <div className="medium-12 pt10 bb_ccc pb5">
                        <div className="float-left width30">
                            <Radio value={String(this.state.enterpriseEnablementEstimatesViewTemplate)} 
                                name="favTemplate"
                                defaultChecked={this.state.enterpriseEnablementEstimatesViewTemplate}
                                onClick={(e) => { this.selectEnterpriseEnablementEstimatesViewTemplate() }}>
                            </Radio>
                        </div>
                        <div className="float-left line-height25">{this.props.content.enterpriseEnablementView}</div>
                    </div>
                    :null}

                    {this.props.spendDetails.currentActiveRequest.SOLUTION_IN_SCOPE && this.props.spendDetails.currentActiveRequest.SOLUTION_IN_SCOPE != 'Supply Chain Collaboration'? 
                    <div className="medium-12 pt10 bb_ccc pb5">
                        <div className="float-left width30">
                            <Radio value={String(this.state.hideEmptyColumns)}
                                name="favTemplate"
                                defaultChecked={this.state.hideEmptyColumns}
                                onClick={(e) => { this.selectHideEmptyColumnsTemplate() }}>
                            </Radio>
                        </div>
                        <div className="float-left line-height25">{this.props.content.hideEmptyColumnView}</div>
                    </div>
                    :null}                

                    <div className="favoriteTemplateScrollArea">
                        {templatesDiv(this.state.favouriteTemplates)}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        ui: state.content.ui,
        content: state.content.spendAnalysis.detailedFile,
        user: state.default,
        spendDetails: state.spendDetails,
        validation: state.content.validation,
        userData: state.valueStory,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addFavoriteFields: (UserId, TemplateName, Columns) => dispatch(addFavoriteFields(UserId, TemplateName, Columns)),
        getFavoriteFields: (UserId) => dispatch(getFavoriteFields(UserId)),
        editFavoriteField: (Id, templateName) => dispatch(editFavoriteField(Id, templateName)),
        deleteFavoriteField: (UserId, FavFieldId) => dispatch(deleteFavoriteField(UserId, FavFieldId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ColumnSelectFavorite)