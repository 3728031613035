import React, { Component } from 'react'
import * as d3 from 'd3'
import { convertToMillion, formatThousands, convertToThousands } from 'utils/Utils'
import { uid } from 'react-uid'

const groupbarchart = (yaxiscolumnkey,yaxiscolumnkey2, xaxiscolumnkey, keys, data, elementId, leftAxisText) => {

    var xaxiscolumnkey = xaxiscolumnkey
    var yaxiscolumnkey = yaxiscolumnkey
    //var xaxiscolumnkey = xaxiscolumnkey
    var parentNode = document.getElementById("chart").parentNode;
    var windowWidth = parentNode.clientWidth;
    var windowHeight = parentNode.clientHeight;
    var svg = d3.select("#" + elementId),
    margin = { top: 40, right: 40, bottom: 30, left: 40 },
        //margin = { top: 20, right: 20, bottom: 30, left: 40 },
        width = windowWidth - margin.left - margin.right - margin.right,
        height = windowHeight - margin.left - margin.bottom,
        g = svg.append("g").attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    var x0 = d3.scaleBand()
        .rangeRound([0, width])
        .paddingInner(0.1);

    var x1 = d3.scaleBand()
        .padding(0.05);

    var y = d3.scaleLinear()
        .rangeRound([height, 0]);

        var y0 = d3.scaleLinear()
        .rangeRound([height, 0]);    

    const rScale = d3.scaleLinear()
            .range([height, 0])
           

    var z = d3.scaleOrdinal()
        .range(["#33c3ff", "#d6d4d4"]);

    var data = data;
    var keys = keys;

    x0.domain(data.map(function (d) { return d[xaxiscolumnkey]; }));
    x1.domain(keys).rangeRound([0, x0.bandwidth()]);
    rScale.domain([0, d3.max(data, d=>d.SUPPLIERS_COUNT)]) ;

    y0.domain([0,d3.max(data, function(d){
        return d[yaxiscolumnkey2] !==""? (d[yaxiscolumnkey] < d[yaxiscolumnkey2]) ? d[yaxiscolumnkey2] : d[yaxiscolumnkey] : d[yaxiscolumnkey];
    })])

    //y0.domain([0, d3.max(data, d=> d[yaxiscolumnkey])]) ; working
    //y0.domain([0, d3.max(data, d=> yaxiscolumnkey.toLowerCase().includes('spend')? convertToMillion(d[yaxiscolumnkey]) : formatThousands(d[yaxiscolumnkey]) )]) ;
      
    y.domain([0, d3.max(data, function (d) { return d3.max(keys, function (key) { return d[key]; }); })]).nice();
    //rScale.domain([0, d3.max(data, function (d) { return d3.max(keys, function (key) { return d[key]; }); })]).nice();

    svg.attr('height', windowHeight);
    svg.attr('width', windowWidth);

    g.append("g")
        .selectAll("g")
        .data(data)
        .enter().append("g")
        .attr("transform", function (d) { return "translate(" + x0(d[xaxiscolumnkey]) + ",0)"; })
        .selectAll("rect")
        .data(function (d) { return keys.map(function (key) { return { key: key, value: d[key] }; }); })
        .enter().append("rect")
        .attr("x", function (d) { 
            return x1(d.key);
         })
        .attr("y", function (d) { return rScale(d.value); })
        .attr("width", x1.bandwidth())
        .attr("height", function (d) { return height - rScale(d.value); })
        .attr("fill", function (d) { return z(d.key); });

        g.append("g")
        .selectAll("g")
        .data(data)
        .enter().append("g")
        .attr("transform", function (d) { return "translate(" + x0(d[xaxiscolumnkey]) + ",0)"; })
        .selectAll("text")
        .data(function (d) { return keys.map(function (key) { return { key: key, value: d[key] }; }); })
        .enter().append("text")
        .style("font-size", "0.625rem")
        .attr('fill','currentcolor')
        .style("font-family", "BentonSans, Arial, Helvetica, sans-serif")
        .attr("text-anchor", "middle")
        .attr("x", function (d) { 
            return (x1(d.key) + x1.bandwidth() / 2);
         })
        .attr("y", function (d) { 
            return rScale(d.value) - 10; 
        })
        .text(function(d){return formatThousands(d.value)})
       

    g.append("g")
        .attr("class", "axis")
        .attr("transform", "translate(0," + height + ")")
        .style("font-size", "0.75rem")
        .style("font-family", "BentonSans, Arial, Helvetica, sans-serif")
        .call(d3.axisBottom(x0).tickFormat(function(d){
            return d + "%";
        }));

          
    g.append("g")
        .attr("class", "axis")
        .style("font-size", "0.75rem")
        .style("font-family", "BentonSans, Arial, Helvetica, sans-serif")
        .call(d3.axisLeft(y0).tickFormat(function(d){
            return yaxiscolumnkey.toLowerCase().includes('spend')?  convertToMillion(d) : convertToThousands(d);
        }))        
        .append("text")
        .attr("x", -74)
        .attr("y", -25)
        .attr("dy", "0.32em")
        .attr("fill", "currentcolor")
        .style("font-size", "0.875rem")
        .style("font-family", "BentonSans, Arial, Helvetica, sans-serif")        
        .attr("text-anchor", "start")
        .text(leftAxisText);

        g.append("g")
        .attr("class", "axis")
        .style("font-size", "0.75rem")
        .style("font-family", "BentonSans, Arial, Helvetica, sans-serif")
        .attr("transform", "translate(" + width + " ,0)")	
        .call(d3.axisRight(rScale))
        .append("text")  
        .attr("x", 8)
        .attr("y", -25)      
        .attr("fill", "currentcolor")
        .style("font-size", "0.875rem")
        .style("font-family", "BentonSans, Arial, Helvetica, sans-serif")
        .attr("text-anchor", "start")
        .text("Supplier Count");  
        
        g.append("text")
        .attr("x", (width / 2))             
        .attr("y", height + 40)
        .attr("text-anchor", "middle")  
        .style("font-size", "0.875rem")        
        .attr("fill",'currentcolor') 
        .style("font-family", "BentonSans, Arial, Helvetica, sans-serif")
        .text("% of Total In-Scope " + leftAxisText);

    return svg;
}

export default groupbarchart
