import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'

// Components
import Button from 'UI/Button'

// Utils
import { BreakLine } from 'utils/Utils'

import { routes } from 'Constants'

export class ActivationContainer extends Component {
	constructor(props) {
		super(props)
	}

	render() {
		return (
			<div className="grid-container">
				<div className="grid-x"> 
					<div className="cell pt20">
						<div className="page-title">{BreakLine(this.props.content.title)}</div>
					</div>
					<div className="medium-6 cell pt20">
						<p>{BreakLine(this.props.content.description)}</p>
						<a href="/">
							<Button
								content={this.props.content.cta}
							/>
						</a>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		content: state.content.activation
	}
}

export default connect(mapStateToProps)(ActivationContainer)

ActivationContainer.propTypes = {
	content: PropTypes.shape({
		title: PropTypes.string,
		description: PropTypes.string
	})
}
