import React from 'react';
import * as d3 from 'd3';

// Utils
import { convertToMillion, formatThousands, convertToThousands } from 'utils/Utils'


class HorizontalBarChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            summaryScopeData: [],
        }

        this.drawChart = this.drawChart.bind(this);
    }

    componentDidMount() {
        this.drawChart(this.props);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.data !== this.props.data) {
            //Perform some operation
            this.drawChart(nextProps);
        }
    }

    drawChart = (props) => {
        if (props.data.length !== 0) {
            d3.select("#barChart").selectAll("svg").remove();
            // var data = [{KEY: 'Bob', Value: 2500},{KEY: 'Rob', Value: 3500},{KEY: 'Cob', Value: 5500},
            // {KEY: 'Pob', Value: 500},{KEY: 'BGob', Value: 250},{KEY: 'Sob', Value: 200},{KEY: 'Xob', Value: 20}]
            var data = props.data;

            // var data = [{
            //     "name": "Apples",
            //     "value": 20,
            // },
            // {
            //     "name": "Bananas",
            //     "value": 12,
            // },
            // {
            //     "name": "Grapes",
            //     "value": 19,
            // },
            // {
            //     "name": "Lemons",
            //     "value": 5,
            // },
            // {
            //     "name": "Limes",
            //     "value": 16,
            // },
            // {
            //     "name": "Oranges",
            //     "value": 26,
            // },
            // {
            //     "name": "Pears",
            //     "value": 30,
            // }];

            //sort bars based on value
            data = data.sort(function (a, b) {
                return d3.ascending(a.value, b.value);
            })

            //set up svg using margin conventions - we'll need plenty of room on the left for labels
            var margin = {
                top: 15,
                right: 100,
                bottom: 15,
                left: 0
            };

            var width = 960 - margin.left - margin.right,
                height = 570 - margin.top - margin.bottom;

            var svg = d3.select("#barChart").append("svg")
                .attr('preserveAspectRatio', 'xMinYMin meet')
                .attr(
                    'viewBox',
                    '0 0 ' +
                    (width + margin.left + margin.right) +
                    ' ' +
                    (height + margin.top + margin.bottom)
                )
                .style("overflow", "visible")
                // .attr("width", width + margin.left + margin.right)
                // .attr("height", height + margin.top + margin.bottom)
                .append("g")
                .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

            var x = d3.scaleLinear()
                .range([0, width])
                .domain([0, d3.max(data, function (d) {
                    return d.value;
                })]);

            var y = d3.scaleBand()
                .range([height, 0])
                .padding(0.5)
                .domain(data.map(function (d) {
                    return d.name;
                }));

            const siFormat = d3.formatPrefix(",.0", 1e6);
            const siFormat2 = d3.formatPrefix(",.0", 1e3)
            function tickFormat(num) {
                return siFormat(num);//.replace("G", "B");
            };
            function tickFormat2(num) {
                return siFormat2(num);//.replace("G", "B");
            };

            const xAxis = g => g
                .attr("transform", `translate(0,${height - margin.bottom})`)
                //.call(d3.axisBottom(x).ticks(7))
                //.call(d3.axisBottom(xScale).tickSize(-365))
                .call(props.type == 'Spend' ? d3.axisBottom(x).ticks(7).tickFormat(tickFormat) : props.type == 'Count' ? d3.axisBottom(x).ticks(7) : d3.axisBottom(x).ticks(7).tickFormat(tickFormat2))
                .call(g => g.selectAll(".domain").remove())
                .call(g => g.selectAll(".tick text")
                    .attr("font-size", "15"))
                    // .attr("font-family", "BentonSans, Arial, Helvetica, sans-serif"))

            //make y axis to show bar names
            var yAxis = d3.axisRight()
                .scale(y)

                //no tick marks
                .tickSize(0);

            // var gy = svg.append("g")
            //     .attr("class", "y axis")
            //     .call(yAxis)

            //Uncomment below line to add x axis
            // svg.append("g")
            //     //.attr("transform", "translate(0," + height + ")")
            //     // .call(d3.axisBottom(x));
            //     .call(xAxis);

            var bars = svg.selectAll(".bar")
                .data(data)
                .enter()
                .append("g")
                .attr("class", "bars")


            //append rects
            bars.append("rect")
                .attr("class", "bar")
                .attr("y", function (d) {
                    return y(d.name);
                })
                .attr("height", y.bandwidth())
                .attr("x", 0)
                .attr("width", function (d) {
                    return x(d.value);
                });

            //add a value label to the right of each bar
            bars.append("text")
                .style("font-size", "15px")
                .style("font-weight", "600")
                .attr("class", "label")
                .attr("fill", "#33C3FF")
                //y position of the label is halfway down the bar
                .attr("y", function (d) {
                    return y(d.name) + y.bandwidth() / 2 + 4;
                })
                //x position is 3 pixels to the right of the bar
                .attr("x", function (d) {
                    return x(d.value) + 3;
                })
                .text(function (d) {
                    return (props.type == 'Spend' ? (props.currency + convertToMillion(d.value)) : props.type == 'Count' ? formatThousands(d.value) : convertToThousands(d.value))
                });


            bars.append("text")
                .attr("class", "labels")
                .style("font-size", "1rem")
                .style("font-family", "BentonSans, Arial, Helvetica, sans-serif")
                .style("font-weight", "600")
                .style("fill", "currentColor")
                .attr("y", function (d) {
                    return y(d.name) + y.bandwidth() / 2 - y.bandwidth() / 2 - 5;
                })
                .attr("x", 0)
                .text(function (d) {
                    return d.name;
                });

        }
    }


    render() {
        return (
            <div id="barChart" className="horiChartMarginTop">
            </div>
        )
    }
}


export default HorizontalBarChart


//Other code to try
/*
            // //var data = [{ "salesperson": "Bob", "sales": 33 }, { "salesperson": "Robin", "sales": 12 }, { "salesperson": "Anne", "sales": 41 }, { "salesperson": "Mark", "sales": 16 }, { "salesperson": "Joe", "sales": 59 }, { "salesperson": "Eve", "sales": 38 }, { "salesperson": "Karen", "sales": 21 }, { "salesperson": "Kirsty", "sales": 25 }, { "salesperson": "Chris", "sales": 30 }, { "salesperson": "Lisa", "sales": 47 }, { "salesperson": "Tom", "sales": 5 }, { "salesperson": "Stacy", "sales": 20 }, { "salesperson": "Charles", "sales": 13 }, { "salesperson": "Mary", "sales": 29 }];

            // set the dimensions and margins of the graph
            var margin = { top: 20, right: 80, bottom: 30, left: 20 },
                width = 960 - margin.left - margin.right,
                height = 500 - margin.top - margin.bottom;

            // set the ranges
            var y = d3.scaleBand()
                .range([height, 0])
                .padding(0.1);

            var x = d3.scaleLinear()
                .range([0, width]);

            // append the svg object to the body of the page
            // append a 'group' element to 'svg'
            // moves the 'group' element to the top left margin
            var svg = d3.select("#barChart").append("svg")
                .attr('preserveAspectRatio', 'xMinYMin meet')
                .attr(
                    'viewBox',
                    '0 0 ' +
                    (width + margin.left + margin.right) +
                    ' ' +
                    (height + margin.top + margin.bottom)
                )
                .append("g")
                .attr("transform",
                    "translate(" + margin.left + "," + margin.top + ")");

            // format the data
            data.forEach(function (d) {
                d.Value = +d.Value;
            });

            // Scale the range of the data in the domains
            x.domain([0, d3.max(data, function (d) { return d.Value; })])
            y.domain(data.map(function (d) { return d.KEY; }));
            //y.domain([0, d3.max(data, function(d) { return d.Value; })]);

            const siFormat = d3.formatPrefix(".1", 1e6);
            const siFormat2 = d3.formatPrefix(".1", 1e3)
            function tickFormat(num) {
                return siFormat(num);//.replace("G", "B");
            };
            function tickFormat2(num) {
                return siFormat2(num);//.replace("G", "B");
            };

            const xAxis = g => g
                .attr("transform", `translate(0,${height - margin.bottom})`)
                .call(d3.axisBottom(x).ticks(7))
                //.call(d3.axisBottom(xScale).tickSize(-365))
                .call(props.type == 'Spend' ? d3.axisBottom(x).tickFormat(tickFormat) : props.type == 'Count' ? d3.axisBottom(x) : d3.axisBottom(x).tickFormat(tickFormat2))
                .call(g => g.selectAll(".domain").remove())
                .call(g => g.selectAll(".tick text")
                    .attr("font-size", "15"))

            const yAxis = g => g
                .attr("transform", `translate(0,-32)`)
                .call(d3.axisRight(y).tickSize(0))
                .call(g => g.selectAll(".domain").remove())
                .call(g => g.selectAll(".tick text")
                    .attr("font-size", "15")
                    .attr("font-weight", "700")
                    .attr("fill", "#4a4a4a"))

            // append the rectangles for the bar chart
            svg.selectAll(".bar")
                .data(data)
                .enter().append("rect")
                .attr("class", "bar")
                //.attr("x", function(d) { return x(d.Value); })
                .attr("width", function (d) { return x(d.Value); })
                .attr("y", function (d) { return y(d.KEY); })
                .attr("height", y.bandwidth());

            svg.selectAll("text")
                .data(data)
                .enter()
                .append("text")
                .attr("class", "below")
                .attr("x", d => 970)
                .attr("y", (d, i) => y(d.KEY) + 30)
                .attr("text-anchor", "left")
                .text(function (d) { return (props.type == 'Spend' ? (props.currency + convertToMillion(d.value)) : props.type == 'Count' ? formatThousands(d.value) : convertToThousands(d.value)) })// convertToMillion(d.value, true);
                .attr("font-size", "15")
                .attr("font-weight", "700")
                .attr("fill", "#33C3FF");

            // add the x Axis
            svg.append("g")
                //.attr("transform", "translate(0," + height + ")")
                // .call(d3.axisBottom(x));
                .call(xAxis);

            // add the y Axis
            svg.append("g")
                .call(yAxis);
            //     .call(d3.axisLeft(y));
            */