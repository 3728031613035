// Store
import { store } from 'index'

// Actions
import {
	setIncomeStatement,
	setBalanceSheet
} from 'actions/valueDriverActions'

// Functions
import {
	getCalculatedEstimatedBenefit
} from 'SubLeverCalculation/SubLeverCalculations'

// Utils
import {
	getSubLeverIdObject
} from 'utils/Utils'


export const calculateIncomeStatement = () => {
	// Retrieve state from store
	const state = store.getState(),
	  	  subLeverCalculationContent = state.content.subLeverCalculations.subLeverCalculation,
	  	  userData = state.valueStory

	let incomeStatement = 0

	// Income statement is the sum of each Sub Lever's estimated Annual Value(Estimated Benefit)
	// and Balance Sheet
	subLeverCalculationContent.forEach((data, i) => {
		const subLeverId = getSubLeverIdObject(data.id),
			  isLeverSelected = userData.BusinessValueToYou[0].ValueDriver[subLeverId.BVUIdIndex].SubLever[subLeverId.BVUSVDIdIndex].Selected

		let estimatedAnnualValue = Number(getCalculatedEstimatedBenefit(state, data.id))

		// Only add Estimated Annual Value to total sum if
		// Sub Lever is selected
		if (isLeverSelected) {
			incomeStatement = !isNaN(estimatedAnnualValue) ?  Number(incomeStatement + estimatedAnnualValue) : 0
		}
	})

	return incomeStatement
}

export const calculateBalanceSheet = () => {
	// Retrieve state from store
	const state = store.getState(),
	  	  userData = state.valueStory

	let balanceSheet = 0,
		// Extend days payable outstanding to increase free cash flow
		subLever1 = userData.BusinessValueToYou[0].ValueDriver[3].SubLever[2], 
		// Reduce inventory levels
		subLever2 = userData.BusinessValueToYou[0].ValueDriver[3].SubLever[3]

	balanceSheet = 
		(subLever1.Selected && Number(subLever1.UserInput[8].CalculatedValues)) +
		(subLever2.Selected && Number(subLever2.UserInput[4].CalculatedValues))

	return balanceSheet
}

export const updateIncomeStatementAndBalanceSheet = () => {
	// Update Income Statement & Balance Sheet
	const incomeStatement = calculateIncomeStatement(),
		  balanceSheet = calculateBalanceSheet()

	// Dispatch to store
	store.dispatch(setIncomeStatement(incomeStatement))
	store.dispatch(setBalanceSheet(balanceSheet))
}
