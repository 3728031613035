import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Control, Form, Errors, actions } from 'react-redux-form'

// Actions
import { tableSchemaFetchData } from '../../actions/fetchContent'
import {
	uploadExcelFileRequestToServer,
	generateBenchMarkJSON,
	uploadFileFailure,
	uploadFileSuccess,
	uploadFileLoading,
	resetError
} from 'actions/userActions'

// Utils
import { BreakLine } from 'utils/Utils'

// UI
import Textfield from 'react-mdl/lib/Textfield'
import Button from 'UI/Button'

// Settings
import { validators, path } from 'Constants'
import { SelectField, Option } from 'react-mdl-extra'
import Select from 'react-select';
import { inherits } from 'util';

//Style
import { dropdownStyles } from 'react-select-css'

class FileUploadManager extends Component {
	constructor(props) {
		super(props)
		this.state = {
			responseMsg: '',
			selectedFileName: '',
			selectedTableSchemaName: '',
			selectedTableSchemaDisplayName: '',
			isTableSchemaNameSelected: false,
			tableSCHEMA: [],
			languages: ["en", "jp", "cn", "pt", "es"]
		}
		//this.handleTableSchemaChange = this.handleTableSchemaChange.bind(this);
		//this.fetchTableSchemaData = this.fetchTableSchemaData.bind(this);
		//this.uploadExcelFileRequest = this.uploadExcelFileRequest.bind(this);
		this.loadData = this.loadData.bind(this);
	}

	componentDidUpdate() {

	}

	componentDidMount() {
		this.loadData();
		this.setState({ selectedTableSchemaName: '' });
		this.setState({ selectedTableSchemaDisplayName: '' });
	}

	componentWillMount() {

	}

	componentWillUnmount() {
		this.setState({ selectedTableSchemaName: '' });
		this.setState({ selectedTableSchemaDisplayName: '' });
		this.formDispatch(actions.reset('fileUploadManager'));
		this.props.resetError();
	}

	loadData() {
		this.props.fetchTableSchemaData(path + 'data/sqlTableSchema/tableSchema.json');
		this.setState({ tableSCHEMA: this.props.tableSCHEMA });

		//Set default value
		if (this.props.tableSCHEMA.length > 0) {
			this.setState({ selectedTableSchemaName: this.props.tableSCHEMA[0].value });
			this.setState({ selectedTableSchemaDisplayName: this.props.tableSCHEMA[0].label });
		}
	}

	onTableSchemaClickEvent = () => {
		this.setState({ isTableSchemaNameSelected: 'Selected' });
	}

	onTableSchemaBlurEvent = () => {
		this.setState({ isTableSchemaNameSelected: '' });
	}

	handleTableSchemaChange(tableSchema) {
		//Set Selected Table Schema Name
		this.setState({ selectedTableSchemaName: tableSchema.value });
		this.setState({ selectedTableSchemaDisplayName: tableSchema.label });
		this.formDispatch(actions.reset('fileUploadManager'));
		this.props.resetError();
		this.setState({ responseMsg: "" });
		this.props.user.error = "";
	}

	handleUploadFile(event) {
		this.props.resetError();
		this.setState({ responseMsg: "" });
		this.props.user.error = "";

		var propsContent = this.props.content;

		if (this.state.selectedTableSchemaName.length > 0 && event.target.files[0] != null) {
			this.formDispatch(actions.setErrors('fileUploadManager.TableSchema', false));

			const file = event.target.files[0];
			const fileName = this.state.selectedTableSchemaName + '.' + file.name.split('.').pop();
			const tableName = this.state.selectedTableSchemaName;
			const userName = localStorage.getItem('loggedinUser');
			this.setState({ selectedFileName: fileName });

			this.props.uploadExcelFileRequestToServer(file, fileName, tableName, userName)
				.then((responseFromAPI) => {
					var response = responseFromAPI.response === undefined ? responseFromAPI : responseFromAPI.response;
					if (response.status === 201) {
						if (response.data.Message === "msgUploadSuccess") {
							this.props.uploadFileSuccess(propsContent.msgUploadSuccess);
							this.setState({ responseMsg: propsContent.msgUploadSuccess }, this.createJsonFiles);
							this.props.user.error = "";
						}
					}
					else if (response.status === 404 || response.status === 417) {
						var responseMessage = '';
						switch (response.data.Message) {
							case "msgNotAbleToProcess":
								responseMessage = propsContent.msgNotAbleToProcess;
							case "msgDataNotFound":
								responseMessage = propsContent.msgDataNotFound;
							case "msgInvalidExtension":
								responseMessage = propsContent.msgInvalidExtension;
							case "msgEntityNotFound":
								responseMessage = propsContent.msgEntityNotFound;
							case "msgUploadFailed":
								responseMessage = propsContent.msgUploadFailed;
							default:
								responseMessage = propsContent.msgErrorUpload;
						}

						this.props.uploadFileFailure(responseMessage);
						this.props.user.error = responseMessage;
						this.setState({ responseMsg: "" });
					}
				}).catch((error) => {
					if (error.stack.includes('Network Error')) {
						this.props.uploadFileFailure(propsContent.msgNetworkError);
						this.props.user.error = propsContent.msgErrorUpload;
						this.setState({ responseMsg: "" });
					}
					else {
						this.props.uploadFileFailure(propsContent.msgErrorUpload) //error.response.data.Message
						this.props.user.error = propsContent.msgErrorUpload;
						this.setState({ responseMsg: "" });
					}
				});
		}
		else {
			this.props.uploadFileFailure(propsContent.msgErrorUpload);
			this.props.user.error = propsContent.msgErrorUpload;
			this.setState({ responseMsg: "" });
		}
	}

	createJsonFiles() {
		let language = this.state.languages;
		this.props.generateBenchMarkJSON("en")
			.then((response) => {
				if (response.status === 200) {
					let msg = this.props.content.msgEnglish
					this.props.uploadFileSuccess(msg);
					this.setState({ responseMsg: msg });
					this.props.user.error = "";
					this.props.generateBenchMarkJSON("cn")
						.then((response) => {
							if (response.status === 200) {
								let msg = this.props.content.msgChinese;
								this.props.uploadFileSuccess(msg);
								this.setState({ responseMsg: msg });
								this.props.user.error = "";
								this.props.generateBenchMarkJSON("jp")
									.then((response) => {
										if (response.status === 200) {
											let msg = this.props.content.msgJapanese;
											this.props.uploadFileSuccess(msg);
											this.setState({ responseMsg: msg });
											this.props.user.error = "";
											this.props.generateBenchMarkJSON("pt")
												.then((response) => {
													if (response.status === 200) {
														let msg = this.props.content.msgPortuguese;
														this.props.uploadFileSuccess(msg);
														this.setState({ responseMsg: msg });
														this.props.user.error = "";
														this.props.generateBenchMarkJSON("es")
															.then((response) => {
																if (response.status === 200) {
																	let msg = this.props.content.msgSpanish;
																	this.props.uploadFileSuccess(msg);
																	this.setState({ responseMsg: msg });
																	this.props.user.error = "";
																}
															}).catch((error) => {this.props.uploadFileFailure(this.props.content.msgErrorSpanish)})
													}
												}).catch((error) => { this.props.uploadFileFailure(this.props.content.msgErrorPortuguese)})
										}
									}).catch((error) => { this.props.uploadFileFailure(this.props.content.msgErrorJapanese)})
							}
						}).catch((error) => { this.props.uploadFileFailure(this.props.content.msgErrorChinese)})
				}
			}).catch((error) => {this.props.uploadFileFailure(this.props.content.msgErrorEnglish) })
	}

	render() {
		return (
			<div className="popup-content">
				<div>
					<div className="grid-x">
						<div className="cell">
							<div className="title">{BreakLine(this.props.content.title)}</div>
						</div>
						<div >
							<Form
								model="fileUploadManager"
								getDispatch={(dispatch) => this.formDispatch = dispatch}
								enctype="multipart/form-data"
							>
								<div className="grid-x grid-padding-x">
									<div className="medium-6 cell" onClick={this.onTableSchemaClickEvent.bind(this)} onBlur={this.onTableSchemaBlurEvent.bind(this)}>
										{
											((this.state.isTableSchemaNameSelected === 'Selected' && this.state.selectedTableSchemaDisplayName !== '')
												||
												(this.state.isTableSchemaNameSelected === '' && this.state.selectedTableSchemaDisplayName !== ''))
												? <label style={{ visibility: 'visible' }} class="css-floatingLabel"
													for="fileUploadManager.TableSchema">{"Table Name"}</label>
												: <label style={{ visibility: 'hidden' }} class="css-floatingLabel"
													for="fileUploadManager.TableSchema">{"Table Name"}</label>
										}
										<Select
											//model="fileUploadManager.TableSchema"
											id="fileUploadManager.TableSchemaDDL"
											name="fileUploadManager.TableSchemaDDL"
											styles={dropdownStyles}
											value={this.props.tableSCHEMA.filter(option => option.label === this.state.selectedTableSchemaDisplayName)}
											//defaultValue={this.state.selectedTableSchemaDisplayName.length === 0 && this.props.tableSCHEMA.length > 0 ?  this.props.tableSCHEMA[0].label : ''}
											noOptionsMessage={() => null}
											onChange={(value) => this.handleTableSchemaChange(value)}
											validators={{ required: (value) => validators.validSelect(this.props.tableSCHEMA.indexOf(value.label)) }}
											validateOn="change"
											options={this.props.tableSCHEMA}
											placeholder={this.props.content.tableName}
											isSearchable={false}
											classNamePrefix="gen-react-select"
											className="gen-react-select-container"
										/>
										<Errors
											className="errors fileUploadManagerDDLError"
											model="fileUploadManager.TableSchema"
											show="touched"
											messages={{
												required: this.props.validation.required
											}}
										/>
										<Control.text
											model="fileUploadManager.TableSchema"
											id="fileUploadManager.TableSchema"
											value={this.state.selectedTableSchemaDisplayName}
											hidden
											component={Textfield}
											validateOn="change"
											validators={{ required: validators.required }}
										/>
									</div>
									<div className="medium-6 cell pt13">
										{
											(this.state.selectedTableSchemaName.length > 0 && !this.props.user.loading)
												?
												<label id="lblUpload" style={{ width: '290px' }}
													class="button button--accent"> {this.props.content.selectFileToUpload}
													<input type="file"
														id="File"
														accept=".xlsx, .xls"
														onChange={this.handleUploadFile.bind(this)}>
													</input>
												</label>
												: (this.state.selectedTableSchemaName.length > 0 && this.props.user.loading)
													?
													<label id="lblUpload" style={{ width: '290px' }}
														class="button button--accent">
														<div class="button__inner"><div class="button-loader"></div></div>
													</label>
													:
													<label id="lblUpload" style={{ width: '290px' }} class="button disabled"> {this.props.content.selectTableNameToUpload} </label>
										}
									</div>
									<div className="medium-6 cell"></div>
									{/* <div className="medium-4 cell">
									<Button
										type="submit"
										disabled={this.props.user.loading}
										content={this.props.content.uploadFile}
										color
										onClick ={this.handleUploadFile.bind(this)}
									/>
								</div> */}
								</div>
							</Form>
							<div className="success" style={{ paddingLeft: '14px' }}>{this.state.responseMsg}</div>
							<div className="errors" style={{ paddingLeft: '14px' }}>{this.props.user.error}</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		language: state.language,
		ui: state.content.ui,
		user: state.default,
		tableSCHEMA: state.tableSCHEMA,
		validation: state.content.validation,
		content: state.content.menu.adminMenuItems[0].fileUploadManager
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		fetchTableSchemaData: (url) => dispatch(tableSchemaFetchData(url)),
		uploadExcelFileRequestToServer: (file, name, tableName, userName) => dispatch(uploadExcelFileRequestToServer(file, name, tableName, userName)),
		generateBenchMarkJSON: (lang) => dispatch(generateBenchMarkJSON(lang)),
		uploadFileLoading: (bool) => dispatch(uploadFileLoading(bool)),
		uploadFileSuccess: (bool) => dispatch(uploadFileSuccess(bool)),
		uploadFileFailure: (error) => dispatch(uploadFileFailure(error)),
		resetError: () => dispatch(resetError())
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(FileUploadManager)
