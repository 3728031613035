import React from 'react'

// Utils
import classnames from 'classnames'

// UI
import GoCheck from 'react-icons/lib/go/check'
import GoPlus from 'react-icons/lib/go/plus'

const ValueDriver = (props) =>
	<li className={classnames('value-driver', { active: props.selected === props.id })}
		onClick={() =>
			props.selectValueDriver(props.id)
		}>
		<div className={!props.state ? 'plusBg iconBg' : 'checkBg iconBg'}
			onClick={(e) => {
				e.stopPropagation()
				props.dispatchToggleValueDriver(props.id, !props.state)
			}
			}>
		</div>
		<button>{props.content}</button>
	</li >

export default ValueDriver
