import React from 'react'
import { connect } from 'react-redux'
import {
    defineScopeLoading,
    defineScopeSuccess,
    defineScopeFailure,
    getGanntChartEnablementWavesLookup
} from 'actions/spendActions'
import { convertToMillion, formatThousands } from 'utils/Utils'

class EnablementMappingKeysTable extends React.Component {
    constructor(props) {
        super(props)
        {
            this.state = {
                //keeping this data as reference from AV-732 attached excel
                tableData: [
                    { key: 'Enterprise - Supplier Dedicated Specialist', 'Gantt_Chart_Name': 'Enterprise - Integration', 'Aggressive': 8, 'Conservative': 12 },
                    { key: 'Enterprise - Integration Ready Candidate', 'Gantt_Chart_Name': 'Enterprise - Integration', 'Aggressive': 8, 'Conservative': 12 },
                    { key: 'Enterprise - Integration Ready & Catalog Candidate', 'Gantt_Chart_Name': 'Enterprise - Integration & Catalog', 'Aggressive': 8, 'Conservative': 12 },
                    { key: 'Enterprise - Portal', 'Gantt_Chart_Name': 'Enterprise - Portal', 'Aggressive': 6, 'Conservative': 8 },
                    { key: 'Enterprise Integration Candidate', 'Gantt_Chart_Name': 'Enterprise - Portal', 'Aggressive': 6, 'Conservative': 8 },
                    { key: 'Enterprise - Integration & Catalog Candidate', 'Gantt_Chart_Name': 'Enterprise - Portal & Catalog', 'Aggressive': 6, 'Conservative': 12 },
                    { key: 'Enterprise - Portal & Catalog Candidate', 'Gantt_Chart_Name': 'Enterprise - Portal & Catalog', 'Aggressive': 6, 'Conservative': 12 },
                    { key: 'Managed Standard - Interactive Email', 'Gantt_Chart_Name': 'Managed Standard', 'Aggressive': 6, 'Conservative': 8 },
                    { key: 'On Going Standard - Interactive Email', 'Gantt_Chart_Name': 'On Going Standard Account', 'Aggressive': 'NA', 'Conservative': 'NA' },
                    { key: 'Already Transacting', 'Gantt_Chart_Name': 'NA', 'Aggressive': 8, 'Conservative': 12 },
                    { key: 'Out of Scope - Category Not Eligible', 'Gantt_Chart_Name': 'NA', 'Aggressive': 8, 'Conservative': 12 },
                    { key: 'Relationship Exists', 'Gantt_Chart_Name': 'NA', 'Aggressive': 8, 'Conservative': 12 },
                    { key: 'Set up', 'Gantt_Chart_Name': 'NA', 'Aggressive': 6, 'Conservative': 6 }

                ],
                tableData2: [],
                helpText: [
                    { key: 'Enterprise - Supplier Dedicated Specialist', txt : 'Suppliers utilizing this enablement type have high transaction volumes with the buyer and typically utilize a catalog-based buy process.  With sufficient transaction volumes to maximize efficiency and compliance, the supplier’s systems are integrated with the Ariba Network to exchange procurement documents including purchase orders, advanced ship notices, order confirmations and invoices.  They are always Enterprise enabled on the Ariba Network, typically use catalogs and have extensive experience integrating with other buyers.'},
                    { key: 'Enterprise - Integration Ready Candidate', txt : 'Suppliers utilizing this enablement type have high transaction volumes with the buyer. To maximize efficiency and compliance, their systems are integrated with the Ariba Network to exchange procurement documents including purchase orders, advanced ship notices, order confirmations and invoices.   Suppliers have extensive experience integrating with other buyers.'},
                    { key: 'Enterprise - Integration Ready & Catalog Candidate', txt : 'Suppliers utilizing this enablement type have high transaction volumes with the buyer and utilize a catalog-based buy process.  To maximize efficiency and compliance, the supplier’s systems are integrated with the Ariba Network to exchange procurement documents including purchase orders, advanced ship notices, order confirmations and invoices.  Suppliers have extensive experience integrating with other buyers.'},
                    { key: 'Enterprise - Portal', txt : 'Suppliers utilizing this enablement type typically have under 500 documents annually with the buyer and receive and deliver their procurement documents through their SAP Ariba Network online user interface.'},
                    { key: 'Enterprise - Integration Candidate', txt : 'Suppliers utilizing this enablement type have high transaction volumes with the buyer. To maximize efficiency and compliance, their systems are integrated with the Ariba Network to exchange procurement documents including purchase orders, advanced ship notices, order confirmations and invoices.'},
                    { key: 'Enterprise - Integration & Catalog Candidate', txt : 'Suppliers utilizing this enablement type have high transaction volumes with the buyer and utilize a catalog-based buy process.  To maximize efficiency and compliance, the supplier’s systems are integrated with the Ariba Network to exchange procurement documents including purchase orders, advanced ship notices, order confirmations and invoices.'},
                    { key: 'Enterprise - Portal & Catalog Candidate', txt : 'Suppliers utilizing this enablement type utilize a catalog-based buy process. The supplier typically has under 500 documents annually with the buyer and receives and delivers their procurement documents through the SAP Ariba Network online user interface..' },
                    { key: 'Managed Standard - Interactive Email', txt : 'Suppliers utilizing this enablement type typically have <75 documents but can have up to 200 documents annually with the buyer. The supplier sends and receives procurement documents via interactive email and through the SAP Ariba Network online user interface.  Enablement of these supplier is managed.'},
                    { key: 'On Going Standard - Interactive Email', txt : 'Suppliers utilizing this method have low spend and document volume and transactions are sent and received through channels outside of the Ariba Network (fax, email, mail, etc.) initially.  These suppliers should be targeted for Standard Enablement once contact information is available.'}
                ]
            }
        }
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        let dmcId = this.props.spendDetails.currentActiveRequest.DMC_ID;
        let vsID = this.props.spendDetails.currentActiveRequest.VS_ID;
        let userEmail = this.props.user.user.Email;

        let inputData = {
            DMC_ID: dmcId, VS_ID: vsID, USER_EMAIL: userEmail
        }

        this.props.getGanntChartEnablementWavesLookup(dmcId, vsID, userEmail)
            .then((response) => {
                let data = response.data.Result;                
                //help text are added to hana db
                // let helpTxt = this.state.helpText;
                // for(let i = 0; i < helpTxt.length; i++){
                //     for(let j = 0; j < data.length; j++){
                //         if(data[j].SAP_SUPPLIER_ENABLE_LOG == helpTxt[i].key){
                //             data[j].helpTxt = helpTxt[i].txt;
                //         }
                //     }
                // }

                if (this.props.isPopup) {
                    data = [];
                    response.data.Result.forEach(function (v, index) {
                        delete v.AGGRESSIVE_DURATION;
                        delete v.CONSERVATIVE_DURATION;
                        if(v.SAP_SUPPLIER_ENABLE_LOG == '0 spend and 0 document' || v.SAP_SUPPLIER_ENABLE_LOG == 'Setup')
                        {}
                        else data.push(v);
                    });
                }   

                this.setState({ tableData2: data })
            }).catch((error) => {
                if (error.stack.includes('Network Error')) {
                    this.setState({ responseMsg: 'API service is down. Please contact administrator', isError: 'Yes' })
                }
                else {
                    this.setState({ responseMsg: 'An error has occurred', isError: 'Yes' })
                }
            })
    }

    getBackgroundColor() {
        let bgColor = '#808080';

        return bgColor;
    }

    render() {
        var _this = this;
        var rows = this.state.tableData2.map(function (row) {
            return (
                <Rows
                    rowData={row}
                />
            )
        })

        return (
            <div className='basic-table'>
                <table className='custScroll'>
                    {/* <caption color="Red">{this.props.tableName}</caption>   INTEGRATION_MATCH_TYPE   */}
                    <thead><tr ><th colSpan={'5'} style={{ textAlign: 'center', backgroundColor: this.getBackgroundColor(), color: '#ffffff' }} >{this.props.isPopup ? "Enablement Recommendation to Onboarding Workstream Mapping Key" : "Mapping Key - Groupings and Durations"}</th></tr></thead>
                    
                    <thead>
                        <tr>
                            <th width='50%' className="tableHeader bgcn ">{this.props.content.ewCol1Name}</th>
                            <th width='20%' className="tableHeader bgcn ">{this.props.content.integrationMatchType}</th>
                            <th width='20%' className="tableHeader bgcn ">{this.props.content.ewCol2Name}</th>
                            {!this.props.isPopup?
                                <th width='5%' className="tableHeader bgcn column-align-right">{this.props.content.ewCol3Name}</th>
                            :null}
                            {!this.props.isPopup?
                                <th width='5%' className="tableHeader bgcn column-align-right">{this.props.content.ewCol4Name}</th>
                            :null}
                        </tr>
                    </thead>
                    <tbody className='medium-12'>{rows}</tbody>
                </table>
            </div>
        )
    }
}

class Rows extends React.Component {
    constructor(props) {
        super(props)
        {
        }

    }

    render() {
        return (
            <tr className='eachRow'>
                <td width='50%' className="tableContent column-align-left" title={this.props.rowData.DEFINITION} style={{'cursor': 'pointer', width: '40%'}}>{this.props.rowData.SAP_SUPPLIER_ENABLE_LOG}</td>
                <td width='20%' className="tableContent column-align-left" >{this.props.rowData.INTEGRATION_MATCH_TYPE}</td>
                <td width='20%' className="tableContent column-align-left" >{this.props.rowData.WORK_STREAM}</td>
                <td width='5%' className="tableContent column-align-right" >{this.props.rowData.AGGRESSIVE_DURATION}</td>
                <td width='5%' className="tableContent column-align-right" >{this.props.rowData.CONSERVATIVE_DURATION}</td>
            </tr>
        )
    }
}


const mapStateToProps = state => {
    return {
        user: state.default,
        spendDetails: state.spendDetails,
        content: state.content.ganntChartTab,
        validation: state.content.validation,
        currencySymbols: state.content.CurrencySymbols
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        defineScopeLoading: (bool) => dispatch(defineScopeLoading(bool)),
        defineScopeSuccess: (bool) => dispatch(defineScopeSuccess(bool)),
        defineScopeFailure: (error) => dispatch(defineScopeFailure(error)),
        getGanntChartEnablementWavesLookup: (dmcId, vsID, userEmail) => dispatch(getGanntChartEnablementWavesLookup(dmcId, vsID, userEmail))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EnablementMappingKeysTable)