import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as d3 from 'd3'
import lineChart from 'components/UI/LineChart'

class ANIndustrySpendStats extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
        this.loadData = this.loadData.bind(this)
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        d3.select("#industrySpendStats").selectAll("g").remove();
        d3.select("#industrySpendStats").selectAll("path").remove();

        var data = [{
            "stats": "1200000.00",
            "year": "Jan-2020"
        }, {
            "stats": "1260000.00",
            "year": "Feb-2020"
        }, {
            "stats": "1323000.00",
            "year": "Mar-2020"
        }, {
            "stats": "1389150.00",
            "year": "Apr-2020"
        }, {
            "stats": "1458607.50",
            "year": "May-2020"
        }, {
            "stats": "1458607.50",
            "year": "Jun-2020"
        }, {
            "stats": "2000000.00",
            "year": "Jul-2020"
        }, {
            "stats": "1531537.88",
            "year": "Aug-2020"
        }, {
            "stats": "1608114.77",
            "year": "Sep-2020"
        }, {
            "stats": "1688520.51",
            "year": "Oct-2020"
        }, {
            "stats": "1772946.53",
            "year": "Nov-2020"
        }, {
            "stats": "1861593.86",
            "year": "Dec-2020"
        }];
        lineChart(true, data, '#4472c4', 'PO Spend', this.props.industryNetworkStats.POSpend, 4304935.80, 'industrySpendStats', this.props.currencySymbols[this.props.spendDetails.currentActiveRequest.Currency.trim()], this.props.industryNetworkStats.spendYaxisLabel)

        var data1 = [{
            "stats": "1487500.00",
            "year": "Jan-2020"
        }, {
            "stats": "1561875.00",
            "year": "Feb-2020"
        }, {
            "stats": "1639968.75",
            "year": "Mar-2020"
        }, {
            "stats": "1721967.19",
            "year": "Apr-2020"
        }, {
            "stats": "2000000.00",
            "year": "May-2020"
        }, {
            "stats": "1808065.55",
            "year": "Jun-2020"
        }, {
            "stats": "1808065.55",
            "year": "Jul-2020"
        }, {
            "stats": "1898468.82",
            "year": "Aug-2020"
        }, {
            "stats": "1993392.27",
            "year": "Sep-2020"
        }, {
            "stats": "2093061.88",
            "year": "Oct-2020"
        }, {
            "stats": "2197714.97",
            "year": "Nov-2020"
        }, {
            "stats": "2307600.72",
            "year": "Dec-2020"
        }];

        lineChart(false, data1, '#ed7d31', 'PO Invoice Spend', this.props.industryNetworkStats.POInvoiceSpend, 4304935.80, 'industrySpendStats', this.props.currencySymbols[this.props.spendDetails.currentActiveRequest.Currency.trim()], this.props.industryNetworkStats.spendYaxisLabel)

        var data2 = [{
            "stats": "2775000.00",
            "year": "Jan-2020"
        }, {
            "stats": "2913750.00",
            "year": "Feb-2020"
        }, {
            "stats": "3059437.50",
            "year": "Mar-2020"
        }, {
            "stats": "2000000.00",
            "year": "Apr-2020"
        }, {
            "stats": "3373029.84",
            "year": "May-2020"
        }, {
            "stats": "3373029.84",
            "year": "Jun-2020"
        }, {
            "stats": "3373029.84",
            "year": "Jul-2020"
        }, {
            "stats": "3541681.34",
            "year": "Aug-2020"
        }, {
            "stats": "3718765.40",
            "year": "Sep-2020"
        }, {
            "stats": "3904703.67",
            "year": "Oct-2020"
        }, {
            "stats": "4099938.86",
            "year": "Nov-2020"
        }, {
            "stats": "4304935.80",
            "year": "Dec-2020"
        }];

        lineChart(false, data2, '#a5a5a5', 'Non-PO Invoice Spend', this.props.industryNetworkStats.NonPOInvoiceSpend, 4304935.80, 'industrySpendStats', this.props.currencySymbols[this.props.spendDetails.currentActiveRequest.Currency.trim()], this.props.industryNetworkStats.spendYaxisLabel)
    }

    render() {
        return (
            <div style={{ marginTop: '-60px' }} className="grid-x">
                <div id="chart" className="medium-12 cell"> 
                    <div className="grid-x">
                        <div className="medium-11">
                            <svg id="industrySpendStats" style={{ 'overflow': 'inherit' }}>
                            </svg>
                        </div>
                        <div className="medium-1"></div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        content: state.content,
        ui: state.content.ui,
        user: state.default,
        industryNetworkStats: state.content.spendAnalysis.industryDrilldown.industryNetworkStats,
        spendDetails: state.spendDetails,
        currencySymbols: state.content.CurrencySymbols,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ANIndustrySpendStats); 