import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
	fetchScreens,
	resetError,
	saveScreenVideo,
	saveScreenVideoFile,
	getAllRoles,
	saveScreenVideosOrder
} from 'actions/videoRoleAction';

import {OrderList} from 'primereact/orderlist';

import Button from 'UI/Button'
import Select from 'react-select'
import classNames from "classnames"
import { path, changeModalStyle, changeModalContentStyle } from 'Constants'
import cloneDeep from 'lodash/cloneDeep';
import { Checkbox } from 'material-ui';

import Modal from 'reboron/OutlineModal'


const videoChangeModalStyle = Object.assign({}, changeModalStyle);
videoChangeModalStyle.width = "375px";

class ScreenVideoReorder extends Component {
	constructor(props) {
		super(props)
		this.state = {
			videoData: props.videoData,
			selectedVideos: [],
			modules: [],
			moduleToScreen: [],
			screens: [],
			roles: [],
			videoFile: {},
			module: '',
			screenName: ''
		}

		this.loadData = this.loadData.bind(this)
		this.fetchVideoScreens = this.fetchVideoScreens.bind(this)
		this.handleModuleChange = this.handleModuleChange.bind(this)
		this.handleScreenChange = this.handleScreenChange.bind(this)
		this.onSave = this.onSave.bind(this)
	}

	showModal(action) {
        this.refs[action].show()
    }

    hideModal(action) {
		this.refs[action].hide()
		this.props.close();
	}
	
	handleModuleChange(value) {
		const screens = this.state.moduleToScreen[value]
		this.setState({module: value, screens: screens})
	}

	handleScreenChange(value) {
		const filtered = this.state.videoData.filter(item => {
			return item.Module === this.state.module && item.ScreenName === value;
		})

		filtered.sort((a, b) => {
			return a.Order > b.Order ? 1 : a.Order < b.Order ? -1 : 0 
		})
		this.setState({screenName: value, selectedVideos: filtered})
	}

	componentDidMount() {
		this.props.resetError()
		this.loadData();
		this.keyCount = 1;
	}

	loadData() {
		this.fetchVideoScreens();
	}

	fetchVideoScreens() {
		this.setState({ isLoading: true, isError: 'No', responseMsg: '' })
		this.props.fetchScreens()
			.then((response) => {
				//var t = (showLoading === true ? this.props.roleManagerSuccess(response.data.Message) : null)
				if(response.data.Result.length > 0) {
					const moduleMap = [];
					const data = response.data.Result;
					data.forEach(datum => {
						let screens = moduleMap[datum.Module]
						if(!screens) {
							screens = []
							moduleMap[datum.Module] = screens
						}

						screens.push({label: datum.ScreenName, value: datum.ScreenName})
					})

					this.setState({isLoading: false, modules: Object.keys(moduleMap).map(key => { return {label: key, value: key}}), moduleToScreen: moduleMap})
				}
			}).catch((error) => {
				if (error.stack.includes('Network Error')) {
				//	this.props.roleManagerFailure()
				}
				else {
					//this.props.roleManagerFailure(error.response.data.Message)
				}
			})
	}

	onSave() {
		this.setState({ isLoading: true, isError: 'No', responseMsg: '' })
		this.props.saveScreenVideosOrder(this.state.selectedVideos)
			.then((response) => {
				//var t = (showLoading === true ? this.props.roleManagerSuccess(response.data.Message) : null)
				if(response.status === 200) {
					this.props.getOrderedResult(response.data.Result)
					this.showModal("terms")
				} else {
					this.setState({ isLoading: false, isError: 'No', responseMsg: 'Record has been updated successfully' })
				}
			}).catch((error) => {
				if (error.stack.includes('Network Error')) {
                    this.setState({ responseMsg: 'API service is down. Please contact administrator.', isError: 'Yes', isLoading: false })
                }
                else {
                    this.setState({ responseMsg: error.response.data.Message, isError: 'Yes', isLoading: false })
                }
			})
	}

	videoTemplate(video) {
        return (
            <div className="p-clearfix">
                <div style={{ float: 'left', margin: '15px 5px 0 0' }}>{video.Name}</div>
            </div>
        );
    }

    render() {
		return (
            <div className='grid-x'>
				<div className="medium-3">
					<div style={{ fontSize: '16px', color: '#008fd3', paddingLeft: '5px' }} >{this.props.content.reorderVideo}</div>
				</div>
				<Modal
					ref="terms"
					modalStyle={videoChangeModalStyle}
					contentStyle={changeModalContentStyle}
				>
					<div className="grid-x grid-padding-x change-popup">
						<div className="medium-12">
							<button onClick={() => this.hideModal("terms")} className="close" />
						</div>
						<div className="medium-12 pt30">
							Video order has been saved successfully.
						</div>
						<div className="medium-12 popupOkBtn">
							<div className="float-right">
								<div className="float-left"><Button
									content={"Close"}
									onClick={() => this.hideModal("terms")}
									color /></div>
							</div>
						</div>
					</div>

				</Modal>
						<div className="pt20 grid-container font12">
							<div className="grid-x tabs_container">
								<div className="medium-12">
									<div className="grid-x grid-padding-x">
										<div className="medium-12">
											{(this.state.isLoading) ?
												<img style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }} src={path + `images/ajax-loader.gif`} alt="" />
												: null
											}
											{(this.state.isError === 'Yes') ?
												<div style={{ color: 'red', textAlign: 'center' }} className="cell pt5">{this.state.responseMsg}</div>
												: <div style={{ color: 'green', textAlign: 'center' }} className="cell pt5">{this.state.responseMsg}</div>
											}
										</div>
									</div>
								</div>
								<div className="medium-5 pr10">  
									<div className="grid-x">
										<div className="medium-3 text-align-right font-bold min-control-height vertical-align-center">
											<span>{this.props.content.name}</span>
										</div>
										<div className="medium-9 paddingAll5">
											<Select
											className="border-with-DD dropDownContent width-100"
											name='Module'
											key='Module'
											value={this.state.modules.filter(
												option => option.label == this.state.module
											)}
											onChange={(value) => this.handleModuleChange(value.value)}
											options={this.state.modules}
											isSearchable={false}
											noOptionsMessage={() => null}
											required
											/>
										</div>
										<div className="medium-3 text-align-right font-bold min-control-height vertical-align-center">
											{this.props.content.screen}
										</div>
										<div className="medium-9 paddingAll5">
										<Select
											className="border-with-DD dropDownContent width-100"
											key='ScreenName'
											name='ScreenName'
											value={this.state.screens.filter(
												option => option.label == this.state.screenName
											)}
											onChange={(value) => this.handleScreenChange(value.value)}
											options={this.state.screens}
											isSearchable={false}
											noOptionsMessage={() => null}
											required
											/>
										</div>
										<div className="medium-9 paddingAll5">
											<Button className={classNames('float-right', 'pt10', 'pr25')}
												disabled={this.state.isLoading}
												type="button" onClick={() => this.onSave()} title={this.props.ui.save} content='Save' float color>
											</Button>
										</div>
									</div>
								</div>
								<div className="medium-7 pr10">  
									<OrderList listStyle={{height: '300px'}} responsive={true} value={this.state.selectedVideos} itemTemplate={this.videoTemplate} header="Reorder" onChange={(e) => this.setState({selectedVideos: e.value})}></OrderList>
								</div>
							</div>
						</div>
            		</div>
        		);
		}
}



const mapStateToProps = (state) => {
	return {
		language: state.language,
		ui: state.content.ui,
		user: state.default,
		content: state.content.videoManager,
		validation: state.content.validation
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		resetError: () => dispatch(resetError()),
		fetchScreens: () => dispatch(fetchScreens()),
		getAllRoles: () => dispatch(getAllRoles()),
		saveScreenVideoFile: (file) => dispatch(saveScreenVideoFile(file)),
		saveScreenVideo: (formValues, file) => dispatch(saveScreenVideo(formValues, file)),
		saveScreenVideosOrder: (values) => dispatch(saveScreenVideosOrder(values)),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ScreenVideoReorder)