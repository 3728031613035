import React from 'react'
import { connect } from 'react-redux'
import { Control, Form, Errors, actions } from 'react-redux-form'
import moment from 'moment'
// UI
import Textfield from 'react-mdl/lib/Textfield'
import Button from 'UI/Button'
// Settings
import { validators } from 'Constants'

// Actions
import {
  domainDetails,
  domainDetailsSuccess,
  domainDetailsFailure,
  deleteDomain,
  addDomain,
  updateDomain
}
  from 'actions/userActions'



class DomainDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = { filterText: "", message: "" }
    this.state.domainDetails = [
      {
        id: 0,
        name: '',
        isEditing: false
      }]
    this.domainDetails = this.domainDetails.bind(this)
    this.addNewDomain = this.addNewDomain.bind(this)
    this.handleUserInput = this.handleUserInput.bind(this)

  }

  domainDetails() {
    this.props.domainDetails()
      .then((results) => {
        this.setState({ domainDetails: results.data.Result });

      }).catch((error) => {
        if (error.stack.includes('Network Error')) {
          this.props.domainDetailsFailure('API service is down. Please contact administrator.')
        }
        else {
          this.props.domainDetailsFailure(error.response.data.Message)
        }
      })
  }

  componentWillMount() {
    this.domainDetails()
  }

  handleUserInput(filterText) {
    this.setState({ filterText: filterText.target.value.toLowerCase() });
  };

  handleRowDel(domainDetail) {
    const result = window.confirm('Do you want to delete the domain ' + domainDetail.name + ' ? ');
    if (result) {
      var index = this.state.domainDetails.map(function (x) { return x.id; }).indexOf(domainDetail.id);
      if (index === -1) {
        return false;
      }
      this.props.deleteDomain(domainDetail.id)
        .then((results) => {
          this.setState({ message: results.data.Message });
          this.state.domainDetails.splice(index, 1);
          this.setState(this.state.domainDetails);
        }).catch((error) => {
          if (error.stack.includes('Network Error')) {
            this.props.domainDetailsFailure('API service is down. Please contact administrator.')
          }
          else {
            this.props.domainDetailsFailure(error.response.data.Message)
          }
        })
    }
  };

  handleAddEvent(evt) {
    var domainDetail = {
      name: evt
    }
    this.state.domainDetails.push(domainDetail);
    this.setState(this.state.domainDetails);
  }

  handleEditEvent(evt) {
    var item = {
      name: evt.name,
      id: evt.id,
      isEditing: evt.isEditing
    };
    var old_domainDetails = this.state.domainDetails.slice();
    var newdomainDetails = old_domainDetails.map(function (testdomainDetails) {
      for (var key in testdomainDetails) {
        if (testdomainDetails.id == item.id) {
          testdomainDetails.name = item.name;
          testdomainDetails.isEditing = !item.isEditing;

          if (evt.UpdatedBy !== undefined) {
            testdomainDetails.UpdatedBy = evt.UpdatedBy;
            testdomainDetails.UpdatedOn = evt.UpdatedOn;
          }
          break;
        }
      }
      return testdomainDetails;
    });

    this.setState({ domainDetails: newdomainDetails });

  }

  handleCancelEvent(originalDetails) {
    var item = {
      id: originalDetails[0].id,
      name: originalDetails[0].name
    };

    var old_domainDetails = this.state.domainDetails.slice();
    var newdomainDetails = old_domainDetails.map(function (testdomainDetails) {
      for (var key in testdomainDetails) {
        if (testdomainDetails.id === item.id) {
          testdomainDetails[key] = item.name;
          testdomainDetails["isEditing"] = false;
          break;
        }
      }
      return testdomainDetails;
    });
    this.setState({ domainDetails: newdomainDetails });

  };

  addNewDomain(formValue) {
    //this.props.resetError();
    var domainDetail = {
      name: formValue.DomainName.trim()
    }

    this.props.addDomain(domainDetail).then((results) => {
      var item = {
        name: results.data.Result.WDomains,
        id: results.data.Result.WDId,
        isEditing: false,
        AddedBy: results.data.Result.AddedBy,
        AddedOn: results.data.Result.AddedOn

      };
      this.setState({ message: results.data.Message }); //this.state.message  
      // Reset form
      this.formDispatch(actions.reset('addDomain'))
      this.state.domainDetails.push(item)
      this.setState(this.state.domainDetails)
    }).catch((error) => {
      if (error.stack.includes('Network Error')) {
        this.props.domainDetailsFailure('API service is down. Please contact administrator.')
      }
      else {
        this.props.domainDetailsFailure(error.response.data.Message)
      }
    })
  }

  handleDomainDetailTable(evt) {
    var item = {
      name: evt.target.name,
      id: evt.target.id,
      value: evt.target.value
    };

    var old_domainDetails = this.state.old_domainDetails.slice();
    if (old_domainDetails.length === 0) {
      old_domainDetails = this.state.domainDetails.slice();
      this.setState({ old_domainDetails: old_domainDetails });
    }
    let testOfObjects = old_domainDetails;
    var newdomainDetails = testOfObjects.map(function (testdomainDetails) {
      for (var key in testdomainDetails) {
        if (testdomainDetails.id === item.id) {
          testdomainDetails[key] = item.name;
          testdomainDetails["isEditing"] = false;
          break;
        }
      }
      return testdomainDetails;
    });

    this.setState({ domainDetails: newdomainDetails });

  };

  handleUpdateEvent(updateDetails) {
    if (updateDetails.length > 0 && this.isDuplicate(updateDetails[0].name)) {
      var item = {
        id: updateDetails[0].id,
        name: updateDetails[0].name,
        isEditing: true,
        UpdatedBy: '',
        UpdatedOn: Date.now
      };

      this.props.updateDomain(item)
        .then((results) => {
          if (results.status === 200) {
            item.UpdatedBy = results.data.Result.UpdatedBy;
            item.UpdatedOn = results.data.Result.UpdatedOn;
            this.setState({ message: results.data.Message });
            this.handleEditEvent(item)
          }

        }).catch((error) => {
          if (error.stack.includes('Network Error')) {
            this.props.domainDetailsFailure('API service is down. Please contact administrator.')
          }
          else {
            this.props.domainDetailsFailure(error.response.data.Message)
          }
        })
    }
  }

  isDuplicate(vals) {
    var result = this.state.domainDetails.find(domain => domain.name === vals.trim()) === undefined
    if (result) {
      return result;
    }
    else {
      return result;
    }
  }
  
  componentWillUnmount() {
    this.formDispatch(actions.reset('addDomain'))
  }

  render() {
    return (
      <div className="popup-content">
        <div className="medium-12 title">{this.props.content.title}</div>
        <Form model="addDomain" method="post" getDispatch={(dispatch) => this.formDispatch = dispatch}
          onSubmit={(formValue) => this.addNewDomain(formValue)}>
          <div className="grid-x grid-padding-0" >
            <div className="medium-5 cell" ><Control.text
              model="addDomain.DomainName"
              id="addDomain.DomainName"
              validateOn="blur"
              component={Textfield}
              label={(this.props.content.newDomain)}
              floatingLabel
              validators={{
                required: validators.required,
                domainName: validators.requiredwithoutSpace,
                validDomainName: validators.requiredDot,
                duplicate: (vals) => this.isDuplicate(vals)
              }} /> </div>
            <div className="medium-2 cell grid-padding-1 pt13" ><Button
              type="submit"
              content={this.props.content.cta}
              disabled={this.props.user.loading}
              color />  </div>
            <div className="medium-4 cell" >
              <Errors
                className="errors"
                model="addDomain.DomainName"
                show="touched"
                messages={{
                  required: this.props.validation.required,
                  domainName: this.props.validation.domainName,
                  validDomainName: this.props.validation.validDomainName,
                  duplicate: this.props.validation.duplicateDomain
                }} />
            </div>
          </div>

        </Form>
        <div className="errors">{this.state.message}</div>
        {(this.state.domainDetails.length > 1) ?
          <div className="grid-x" className="medium-11 medium-offset-0">
            <SearchBar filterText={this.state.filterText} onUserInput={this.handleUserInput.bind(this)} />

            <DomainDetailTable
              onDomainTableUpdate={this.handleDomainDetailTable.bind(this)}
              onRowAdd={this.handleAddEvent.bind(this)}
              onRowDel={this.handleRowDel.bind(this)}
              domainDetails={this.state.domainDetails}
              filterText={this.state.filterText}
              onRowEdit={this.handleEditEvent.bind(this)}
              onRowCancel={this.handleCancelEvent.bind(this)}
              onRowUpdate={this.handleUpdateEvent.bind(this)}
              validation={this.props.validation}
              isDuplicate={this.isDuplicate.bind(this)}
            />
          </div> : ""}

      </div>
    );
  }
}


class SearchBar extends React.Component {
  handleChange(event) {
    this.props.onUserInput(event);
  }
  render() {
    return (
      <div >
        <Control.text
          model="DomainName"
          id="DomainName"
          component={Textfield}
          label={'Search...'}
          floatingLabel
          ref="filterTextInput"
          onChange={this.handleChange.bind(this)}
        />
      </div>
    );
  }
}

class DomainDetailTable extends React.Component {
  constructor(props) {
    super(props);

  }
  render() {
    var onDomainDetailTableUpdate = this.props.onDomainTableUpdate;
    var rowDel = this.props.onRowDel;
    var rowEdit = this.props.onRowEdit;
    var rowCancel = this.props.onRowCancel;
    var rowUpdate = this.props.onRowUpdate;
    var filterText = this.props.filterText;
    var isEditing = this.props.isEditing;
    var validation = this.props.validation;
    var isDuplicate = this.props.isDuplicate;
    var domainDetail = this.props.domainDetails.map(function (domainDetail) {
      if (domainDetail.name.toLowerCase().indexOf(filterText) === -1) {
        return;
      }
      return (<DomainDetailRow
        onDomainDetailTableUpdate={onDomainDetailTableUpdate}
        domainDetail={domainDetail}
        onDelEvent={rowDel.bind(this)}
        onEditEvent={rowEdit.bind(this)}
        onCancelEvent={rowCancel.bind(this)}
        onUpdateEvent={rowUpdate.bind(this)}
        key={domainDetail.id}
        validation={validation}
        isDuplicate={isDuplicate}
      />)
    });
    return (
      <div    >
        <table className="basic-table table">
          <thead>
            <tr>
              <th>Domain Name</th>
              <th>Added By/On</th>
              <th>Updated By/On</th>
              <th>  </th>
              <th>  </th>
            </tr>
          </thead>
          <tbody>
            {domainDetail}  {onDomainDetailTableUpdate}
          </tbody>
        </table>
      </div>
    );
  }
}

class DomainDetailRow extends React.Component {
  constructor(props) {
    super(props);

  }
  onDelEvent() {
    this.props.onDelEvent(this.props.domainDetail);
  }
  onEditEvent() {
    this.props.onEditEvent(this.props.domainDetail);
  }

  onDomainDetailTableUpdate() {
    this.props.onDomainDetailTableUpdate()
  }

  render() {
    return (
      this.props.domainDetail.isEditing ?
        <EditableCell onDomainTableUpdate={this.props.onDomainDetailTableUpdate}
          domainDetail={this.props.domainDetail}
          cellData={{
            name: this.props.domainDetail.name,
            id: this.props.domainDetail.id,
            isEditing: this.props.domainDetail.isEditing
          }}
          onCancelEvent={this.props.onCancelEvent}
          onUpdateEvent={this.props.onUpdateEvent}
          validation={this.props.validation}
          isDuplicate={this.props.isDuplicate}
        />
        :
        <NonEditableRow
          domainDetail={this.props.domainDetail}
          cellData={{
            name: this.props.domainDetail.name,
            id: this.props.domainDetail.id,
            isEditing: this.props.domainDetail.isEditing
          }}
          onDelEvent={this.props.onDelEvent}
          onEditEvent={this.props.onEditEvent}
        />
    );
  }
}

class EditableCell extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      domainDetails: this.props.cellData,
      originaldomainDetails: this.props.cellData
    };
  }

  onDomainTableUpdate() {
    this.props.onDomainDetailTableUpdate();
  }

  onCancelEvent() {

    this.state.domainDetails = [
      {
        id: this.state.originaldomainDetails.id,
        name: this.state.originaldomainDetails.name,
        isEditing: false
      }]
    this.props.onCancelEvent(this.state.domainDetails);
  }

  onUpdateEvent() {
    this.props.onUpdateEvent(this.state.domainDetails);
  }

  onChangeEvent(event) {
    var changeItem = [
      {
        id: event.target.id,
        name: event.target.value,
        isEditing: true
      }]
    this.setState({ domainDetails: changeItem });
  }

  isDuplicate(vals) {
    return this.props.isDuplicate(vals);
  }

  render() {
    return (
      <tr>
        <td>
          <Control.text
            model="editDomain.DomainName"
            component={Textfield}
            defaultValue={this.props.cellData.name}
            id={this.props.cellData.id}
            value={this.state.domainDetails.name}
            onChange={this.onChangeEvent.bind(this)}
            isEditing={this.props.cellData.isEditing}
            validateOn="blur"
            validators={{
              required: validators.required,
              domainName: validators.requiredwithoutSpace,
              validDomainName: validators.requiredDot,
              duplicate: (vals) => this.isDuplicate(vals)
            }}
            type='name' />
          <Errors
            className="errors"
            model="editDomain.DomainName"
            show="touched"
            messages={{
              required: this.props.validation.required,
              domainName: this.props.validation.domainName,
              validDomainName: this.props.validation.validDomainName,
              duplicate: this.props.validation.duplicateDomain
            }} />
        </td>
        <td>
          <span>{this.props.domainDetail.AddedBy}</span><br />
          <span>{moment(this.props.domainDetail.AddedOn).format('L')}</span>
        </td>
        <td>
          <span> {this.props.domainDetail.UpdatedBy}</span> <br />
          <span>{moment(this.props.domainDetail.UpdatedOn).format('L')} </span>
        </td>
        <td>
          <button onClick={this.onUpdateEvent.bind(this)}>Update</button>
        </td>
        <td >
          <button onClick={this.onCancelEvent.bind(this)}>Cancel</button>
        </td>
      </tr>
    );
  }
}

class NonEditableRow extends React.Component {

  onDelEvent() {
    this.props.onDelEvent(this.props.cellData);
  }

  onEditEvent() {
    this.props.onEditEvent(this.props.cellData);
  }

  render() {
    return (
      <tr>
        <td>
          <span id={this.props.cellData.id}
            name={this.props.cellData.name}
            isEditing={this.props.cellData.isEditing}>{this.props.cellData.name}</span>
        </td>
        <td>
          <span>{this.props.domainDetail.AddedBy}</span><br />
          <span>{moment(this.props.domainDetail.AddedOn).format('L')}</span>
        </td>
        <td>
          <span> {this.props.domainDetail.UpdatedBy}</span> <br />
          <span>{moment(this.props.domainDetail.UpdatedOn).format('L')} </span>
        </td>
        <td>
          <button onClick={this.onEditEvent.bind(this)}>Edit</button>
        </td>
        <td>
          <button onClick={this.onDelEvent.bind(this)}>Delete</button>
        </td>
      </tr>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    domainDetails: state.domainDetails,
    validation: state.content.validation,
    user: state.default,
    content : state.content.menu.DomainManager[0].domainDetails
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    domainDetails: (formValues) => dispatch(domainDetails(formValues)),
    domainDetailsSuccess: (bool) => dispatch(domainDetailsSuccess(bool)),
    domainDetailsFailure: (error) => dispatch(domainDetailsFailure(error)),
    deleteDomain: (id) => dispatch(deleteDomain(id)),
    addDomain: (formValues) => dispatch(addDomain(formValues)),
    updateDomain: (formValues) => dispatch(updateDomain(formValues))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DomainDetails)
