import * as d3 from 'd3'
import { convertToMillion, convertToThousands, formatThousands } from 'utils/Utils'

const lineChart = (drawXY, data, fillColor, legendName, translatedLegendName, maxValue, element, currency, Ylabel) => {
    if (data !== undefined) {
        var windowWidth = 1050;
        var windowHeight = 420;

        var margin = { top: 40, right: 0, bottom: 10, left: 70 }
        var svg = d3.select("#" + element)
            .attr("viewBox", '0 0 1050 460')
            .attr('preserveAspectRatio', 'xMinYMin')
            .append("g")
            .attr("transform",
                "translate(" + margin.left + "," + margin.top + ")");

        var x = d3.scaleBand()
            .range([0, windowWidth])
            .padding(1)
            .domain(data.map(function (d) {
                return d.year;
            }));

        var y = d3.scaleLinear()
            .domain([0, maxValue + Math.round(maxValue / 5)]) // Put here maximum value from all data
            .range([windowHeight, 0]).nice();

        if (drawXY) {

            svg.append("g")
                .attr("class", "axisChart")
                //.call(d3.axisLeft(y).ticks(5, "s"))
                .call(d3.axisLeft(y).ticks(5,"s").tickFormat(function(d){
                    return element === 'industryTransactionStats' ? convertToThousands((d)) : convertToMillion(d);
                }))
                .append("text")
                .attr("transform", "rotate(-90)")
                .attr("y", 0 - 60)
                .attr("x", 0 - (windowHeight / 2))
                .attr("dy", "1em")
                .style("text-anchor", "middle")
                .text(Ylabel);

            // svg.append("g")
            //     .attr("class", "axisChart")
            //     .call(d3.axisLeft(y).ticks(5, "s"))
            //     .append("text")
            //     .attr("x", -30)
            //     .attr("y", -10)
            //     .attr("text-anchor", "start")
            //     .text(element === 'industryTransactionStats' ? 'Transaction' : 'Spend');

            svg.append("g")
                .attr("class", "axisChart")
                .attr("transform", "translate(0," + windowHeight + ")")
                .call(d3.axisBottom(x).tickFormat(function (d) {
                    return d;
                }))

            //X-Axis Legend
            // svg.append("g")
            //     .attr("class", "axisChart")
            //     .append("text").attr("x", (windowWidth / 2))
            //     .attr("y", windowHeight + 70)
            //     .attr("text-anchor", "middle")
            //     .text("Trailing 12 Months");

        }

        // Add the line
        svg.append("path")
            .datum(data)
            .attr("fill", "none")
            .attr("stroke", fillColor)
            .attr("stroke-width", 1.5)
            .attr("d", d3.line()
                .x(function (d) {
                    return x(d["year"])
                })
                .y(function (d) {
                    return y(d["stats"])
                })
            )

        // Add the points
        svg.append("g")
            .selectAll("dot")
            .data(data)
            .enter()
            .append("circle")
            .attr("class", "dot")
            .attr("cx", function (d) {
                return x(d["year"])
            })
            .attr("cy", function (d) {
                return y(d["stats"])
            })
            .attr("r", 2)
            .attr("fill", fillColor)

        svg.append("g")
            .attr("fill", "#000")
            .selectAll("g")
            .data(data)
            .join("g")
            .attr("transform", function (d) { return `translate(${x(d["year"])},${y(d["stats"]) - 5})` })
            .attr("opacity", 1).append("text")
            .style("font-size", "0.625rem")
            .style("font-family", "BentonSans, Arial, Helvetica, sans-serif")
            .text(function (d) {
                return element === 'industrySpendStats' ? currency + convertToMillion(d["stats"], true) : formatThousands(Math.round(d["stats"]));
            });

        switch (legendName) {
            case 'Purchase Orders':
                svg.append("circle").attr("cx", 170).attr("cy", windowHeight + 40).attr("r", 6).style("fill", fillColor)
                svg.append("text").attr("x", 190).attr("y", windowHeight + 40).attr("class", "axis").style("font-size", "0.675rem").style("font-family", "BentonSans, Arial, Helvetica, sans-serif").text(translatedLegendName).attr("fill", "#000").attr("alignment-baseline", "middle")
                break
            case 'Order Confirmations':
                svg.append("circle").attr("cx", 310).attr("cy", windowHeight + 40).attr("r", 6).style("fill", fillColor)
                svg.append("text").attr("x", 330).attr("y", windowHeight + 40).attr("class", "axis").style("font-size", "0.675rem").style("font-family", "BentonSans, Arial, Helvetica, sans-serif").text(translatedLegendName).attr("fill", "#000").attr("alignment-baseline", "middle")
                break
            case 'PO Invoices':
                svg.append("circle").attr("cx", 470).attr("cy", windowHeight + 40).attr("r", 6).style("fill", fillColor)
                svg.append("text").attr("x", 490).attr("y", windowHeight + 40).attr("class", "axis").style("font-size", "0.675rem").style("font-family", "BentonSans, Arial, Helvetica, sans-serif").text(translatedLegendName).attr("fill", "#000").attr("alignment-baseline", "middle")
                break
            case 'Non-PO Invoices':
                svg.append("circle").attr("cx", 590).attr("cy", windowHeight + 40).attr("r", 6).style("fill", fillColor)
                svg.append("text").attr("x", 610).attr("y", windowHeight + 40).attr("class", "axis").style("font-size", "0.675rem").style("font-family", "BentonSans, Arial, Helvetica, sans-serif").text(translatedLegendName).attr("fill", "#000").attr("alignment-baseline", "middle")
                break
            case 'Advanced Ship Notices (ASNs)':
                svg.append("circle").attr("cx", 730).attr("cy", windowHeight + 40).attr("r", 6).style("fill", fillColor)
                svg.append("text").attr("x", 750).attr("y", windowHeight + 40).attr("class", "axis").style("font-size", "0.675rem").style("font-family", "BentonSans, Arial, Helvetica, sans-serif").text(translatedLegendName).attr("fill", "#000").attr("alignment-baseline", "middle")
                break
            case 'PO Spend':
                svg.append("circle").attr("cx", 350).attr("cy", windowHeight + 40).attr("r", 6).style("fill", fillColor)
                svg.append("text").attr("x", 370).attr("y", windowHeight + 40).attr("class", "axis").style("font-size", "0.675rem").style("font-family", "BentonSans, Arial, Helvetica, sans-serif").text(translatedLegendName).attr("fill", "#000").attr("alignment-baseline", "middle")
                break
            case 'PO Invoice Spend':
                svg.append("circle").attr("cx", 460).attr("cy", windowHeight + 40).attr("r", 6).style("fill", fillColor)
                svg.append("text").attr("x", 480).attr("y", windowHeight + 40).attr("class", "axis").style("font-size", "0.675rem").style("font-family", "BentonSans, Arial, Helvetica, sans-serif").text(translatedLegendName).attr("fill", "#000").attr("alignment-baseline", "middle")
                break
            case 'Non-PO Invoice Spend':
                svg.append("circle").attr("cx", 600).attr("cy", windowHeight + 40).attr("r", 6).style("fill", fillColor)
                svg.append("text").attr("x", 620).attr("y", windowHeight + 40).attr("class", "axis").style("font-size", "0.675rem").style("font-family", "BentonSans, Arial, Helvetica, sans-serif").text(translatedLegendName).attr("fill", "#000").attr("alignment-baseline", "middle")
                break
            default:
                break
        }
    }
    return svg;
}

export default lineChart

