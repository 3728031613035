import React, { Component } from 'react'
import ValueDriver from './ValueDriver'
import PropTypes from 'prop-types'

class ValueDrivers extends Component {

	constructor(props) {
		super(props)

		this.state = {
			valueDrivers: [
				false,
				false,
				false,
				false,
				false
			],
			selected: null
		}

		this.dispatchActivateValueDriver = this.dispatchActivateValueDriver.bind(this)
	}

	dispatchActivateValueDriver(id, state) {

		let valueDrivers = this.state.valueDrivers
		valueDrivers[id] = state

		this.setState({
			valueDrivers: valueDrivers
		})
	}

	render() {
		return (
			<ul>
				{this.props.content.map((data, i) =>
					<ValueDriver key={i}
								 id={i}
								 content={data.valueDriver}
								 dispatchToggleValueDriver={this.props.dispatchToggleValueDriver}
								 activateValueDriver={this.dispatchActivateValueDriver}
								 selectValueDriver={this.props.selectValueDriver}
								 state={this.props.savedData[i].Selected}
								 selected={this.props.selected}
								 />
				)}
			</ul>
		)
	}
}

export default ValueDrivers

ValueDrivers.propTypes = {
	dispatchToggleValueDriver: PropTypes.func.isRequired,
	selectValueDriver: PropTypes.func.isRequired,
	selected: PropTypes.number.isRequired
}
